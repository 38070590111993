angular.module('sfx.api.v2').service('credentialV2Service', [
    '$http',
    'API_URL',
    'currentUser',
    '$q',
    '$log',
    'slackChannelService',
    function ($http, API_URL, currentUser, $q, $log, slackChannelService) {
        const resourceEndpoint = API_URL + '/v2/integration';
        const FETCH_ALL_LIMIT = 10000;

        // for this cache, null means, nothing found at all, but a query was made
        // true for active now, false for none are active
        const serviceCache = {};

        function populateCache(orgId, itm) {
            if (!serviceCache[orgId]) {
                serviceCache[orgId] = {};
            }
            if (itm.enabled) {
                serviceCache[orgId][itm.type] = true;
            } else if (!serviceCache[orgId][itm.type]) {
                serviceCache[orgId][itm.type] = false;
            }
        }

        function clearCache() {
            Object.keys(serviceCache).forEach(function (orgId) {
                delete serviceCache[orgId];
            });
        }

        function clearCacheByOrgAndType(orgId, type) {
            serviceCache[orgId][type] = undefined;
        }

        function clearCacheByOrg(orgId) {
            serviceCache[orgId] = {};
        }

        function create(params) {
            return $http.post(resourceEndpoint, params).then((response) => response.data);
        }

        function get(id) {
            return $http.get(`${resourceEndpoint}/${id}`).then((response) => response.data);
        }

        function getAll() {
            return $q
                .all({
                    response: $http.get(`${resourceEndpoint}/?limit=` + FETCH_ALL_LIMIT),
                    orgId: currentUser.orgId(),
                })
                .then(function (result) {
                    const response = result.response;
                    const orgId = result.orgId;
                    if (response && response.data && response.data.results) {
                        if (response.data.count > FETCH_ALL_LIMIT) {
                            $log.error(
                                'More credentials exist than were fetched by ' +
                                    (response.data.count - FETCH_ALL_LIMIT)
                            );
                        }
                        clearCacheByOrg(orgId);
                        response.data.results.forEach(function (itm) {
                            populateCache(orgId, itm);
                        });
                        return response.data.results;
                    } else {
                        return [];
                    }
                });
        }

        // for caching purposes only to retrieve whether an integration type is enabled or not
        function getCachedType(
            type,
            offset = 0,
            limit = 200,
            orderBy = '-lastUpdated',
            searchByName = null
        ) {
            return currentUser.orgId().then(function (orgId) {
                if (!serviceCache[orgId] || typeof serviceCache[orgId][type] === 'undefined') {
                    let endPoint = `${resourceEndpoint}/?type=${type}&offset=${offset}&limit=${limit}&orderBy=${orderBy}`;
                    if (searchByName) {
                        endPoint = endPoint + `&name=${searchByName}`;
                    }
                    return $http.get(endPoint).then(function (response) {
                        if (response && response.data) {
                            if (!serviceCache[orgId]) {
                                serviceCache[orgId] = {};
                            }
                            clearCacheByOrgAndType(orgId, type);
                            if (response.data.count) {
                                response.data.results.forEach(function (itm) {
                                    populateCache(orgId, itm);
                                });
                            }
                            return serviceCache[orgId][type];
                        } else {
                            // failure scenario. dont mess with cache
                            return null;
                        }
                    });
                } else {
                    return $q.when(serviceCache[orgId][type]);
                }
            });
        }

        function getType(
            type,
            offset = 0,
            limit = 200,
            orderBy = '-lastUpdated',
            searchByName = null
        ) {
            let endPoint = `${resourceEndpoint}/?type=${type}&offset=${offset}&limit=${limit}&orderBy=${orderBy}`;
            if (searchByName) {
                endPoint = endPoint + `&name=${searchByName}`;
            }
            return $http.get(endPoint).then((response) => ({
                credentials: response.data.results,
                count: response.data.count,
            }));
        }

        function update(params) {
            return $http
                .put(resourceEndpoint + '/' + params.id, params)
                .then((response) => response.data);
        }

        function deleteIntegration(id) {
            return $http.delete(resourceEndpoint + '/' + id).then((response) => response.data);
        }

        function validate(id) {
            return $http
                .get(`${resourceEndpoint}/validate/${id}`)
                .then((response) => response.data);
        }

        function getSlackOAuthUrl(id) {
            const url = `${resourceEndpoint}/_/slack/oauth-url`;

            return $http
                .get(url, {
                    params: {
                        integrationId: id,
                    },
                })
                .then((response) => response.data);
        }

        function getActiveIntegrations() {
            return $http.get(`${resourceEndpoint}/_/info`).then((response) => response.data);
        }

        // TODO: Can be removed, function is moved to @splunk/olly-services
        // Keeping it for now to support +1/-1
        function transformSignalFlow(signalflowList) {
            return $http
                .post(`${resourceEndpoint}/_/parse-metric-filter-signalflow`, signalflowList)
                .then((response) => response.data);
        }

        // TODO: Can be removed, function is moved to @splunk/olly-services
        // Keeping it for now to support +1/-1
        function getLargeVolumeAWSIntegration() {
            return $http
                .get(`${resourceEndpoint}/_/aws-large-volume-integration`)
                .then((response) => response.data);
        }

        return {
            create,
            get,
            getAll,
            getCachedType,
            clearCache,
            getType,
            update,
            validate,
            delete: deleteIntegration,
            slackOAuthUrl: getSlackOAuthUrl,
            slackChannels: slackChannelService.searchSlackChannels.bind(slackChannelService),
            getActiveIntegrations,
            transformSignalFlow,
            getLargeVolumeAWSIntegration,
        };
    },
]);
