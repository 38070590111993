import React from 'react';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import PropTypes from 'prop-types';

export default function AccessControlPermissionTypeIcon({ typeId, iconClass }) {
    const icon = iconClass ? iconClass : getPermissionIconClassName(typeId);
    return <span className={icon} />;

    function getPermissionIconClassName(typeId) {
        switch (typeId) {
            case AccessControlPermissionTypes.INHERIT.id:
                return 'icon-control_inherited';
            case AccessControlPermissionTypes.PUBLIC.id:
                return 'icon-control_everyone';
            case AccessControlPermissionTypes.RESTRICTED_WRITE.id:
                return 'icon-control_readonly';
            case AccessControlPermissionTypes.RESTRICTED.id:
            default:
                return 'icon-control_admin';
        }
    }
}

AccessControlPermissionTypeIcon.propTypes = {
    typeId: PropTypes.string,
    iconClass: PropTypes.string,
};
