export default [
    'timeZoneService',
    function (timeZoneService) {
        return function (timestamp, isDailyReport, isHourlyReport, reportTimezone) {
            let timestampFormat = 'MMMM YYYY';
            if (isDailyReport) {
                timestampFormat = 'ddd DD MMMM YYYY';
            } else if (isHourlyReport) {
                timestampFormat = 'ddd DD MMMM YYYY, ha';
            }
            return timestamp
                ? timeZoneService.moment(timestamp, reportTimezone).format(timestampFormat)
                : '';
        };
    },
];
