import templateUrl from './office365Integration.html';

angular.module('sfx.ui').component('sfxOffice365Integration', {
    templateUrl,
    bindings: {
        credential: '<',
        sharedCredentialState: '<',
        integrationForm: '<',
        editable: '<',
        orgId: '<',
        obfuscatedText: '<',
    },
    controller: [
        '$scope',
        'notificationService',
        function ($scope, notificationService) {
            const $ctrl = this;

            $ctrl.$onInit = reset;

            function reset() {
                if (!$ctrl.credential.name) {
                    $ctrl.credential.name = 'Microsoft Teams';
                }
                $ctrl.sharedCredentialState.updateCredentials = false;
                $ctrl.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                    $ctrl.credential.lastUpdated
                );
            }

            $scope.$on('reset', reset);
            $scope.$watch('$ctrl.credential.webhookUrl', function () {
                $ctrl.sharedCredentialState.updateCredentials = true;
            });
        },
    ],
});
