'use strict';
import templateUrl from './customSamlIntegration.tpl.html';
import samlShowOnLoginPageTooltipTemplateUrl from './samlShowOnLoginPageTooltip.tpl.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('sfxCustomSamlIntegration', [
    'PRODUCT_NAME',
    function (PRODUCT_NAME) {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.samlShowOnLoginPageTooltipTemplateUrl =
                    samlShowOnLoginPageTooltipTemplateUrl;
                function reset() {
                    const credential = $scope.credential;

                    if (!credential.name) {
                        credential.name = 'SAML SSO';
                    }

                    $scope.publicKey = credential.publicKey;
                    $scope.issuerUrl = credential.issuerUrl;
                    $scope.metadataUrl = credential.metadataUrl;
                    $scope.metadata = credential.metadata;
                    $scope.metadataFrom = $scope.metadata ? 'content' : 'url';

                    $scope.sharedCredentialState.updateCredentials = false;
                }

                $scope.$on('reset', reset);
                reset();

                function updateCredentials() {
                    if (
                        !$scope.publicKey ||
                        !$scope.issuerUrl ||
                        (!$scope.metadataUrl && !$scope.metadata)
                    ) {
                        $scope.credential.publicKey = null;
                        $scope.credential.issuerUrl = null;
                        $scope.credential.metadataUrl = null;
                        $scope.credential.metadata = null;
                    } else {
                        $scope.credential.publicKey = $scope.publicKey;
                        $scope.credential.issuerUrl = $scope.issuerUrl;
                        $scope.credential.metadataUrl = $scope.metadataUrl;
                        $scope.credential.metadata = $scope.metadata;
                        $scope.sharedCredentialState.updateCredentials = true;
                    }
                }

                $scope.$watch('metadataFrom', (nval) => {
                    if (nval === 'content') {
                        $scope.metadataUrl = null;
                    } else if (nval === 'url') {
                        $scope.metadata = null;
                    }
                });

                $scope.$watchGroup(
                    ['publicKey', 'issuerUrl', 'metadataUrl', 'metadata'],
                    updateCredentials
                );
            },
        };
    },
]);
