import CancelToken from '../../../../common/sfUtil/CancelToken';
import { AngularInjector } from '../../../../common/AngularUtils';

class AclDashboardGroupService {
    constructor(dashboardGroupService, currentUser) {
        this.currentUser = currentUser;
        this.dashboardGroupService = dashboardGroupService;
        this._lastRequestToken = null;
    }

    get(dashboardGroupId) {
        return this.dashboardGroupService.get(dashboardGroupId);
    }

    create(dashboardGroupData) {
        return this.dashboardGroupService.create(dashboardGroupData).then((dashboardGroup) => {
            // v1 dashboard group is currently required in post creation handler
            return this.dashboardGroupService.convertToV1(dashboardGroup);
        });
    }

    addDashboardToGroup(groupId, dashboardId, config) {
        return this.dashboardGroupService.addDashboardToGroup(groupId, dashboardId, config);
    }

    update(dashboardGroup) {
        return this.dashboardGroupService.update(dashboardGroup);
    }

    getDashboardGroupsForDashboard(dashboardId) {
        if (!dashboardId) {
            return Promise.resolve([]);
        }

        return this.dashboardGroupService
            .searchByDashboardId(dashboardId)
            .then((response) => response.results);
    }

    search(term) {
        return this.dashboardGroupService
            .searchWritableByAny(term, this._getNewRequestToken())
            .then(({ results }) => results);
    }

    _getNewRequestToken() {
        if (this._lastRequestToken instanceof CancelToken) {
            this._lastRequestToken.cancel();
        }
        return (this._lastRequestToken = new CancelToken());
    }
}

AclDashboardGroupService.$inject = ['dashboardGroupService', 'currentUser'];
AclDashboardGroupService.useInstance = () =>
    AngularInjector.useInjectedClass(AclDashboardGroupService);

export default AclDashboardGroupService;
