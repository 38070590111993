import { convertStringToMS, safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.detector').directive('detectorChart', [
    '$timeout',
    '$document',
    '$window',
    'urlOverridesService',
    'modelToStartEnd',
    'currentUser',
    'CHART_DISPLAY_EVENTS',
    'programTextUtils',
    'preflightEstimation',
    '$q',
    'detectorUtils',
    'resolutionToChartRangeService',
    'timepickerUtils',
    function (
        $timeout,
        $document,
        $window,
        urlOverridesService,
        modelToStartEnd,
        currentUser,
        CHART_DISPLAY_EVENTS,
        programTextUtils,
        preflightEstimation,
        $q,
        detectorUtils,
        resolutionToChartRangeService,
        timepickerUtils
    ) {
        return {
            link: function ($scope, element, attr) {
                $scope.chartConfigOverride = {};
                $scope.detailPercent = 40; // default
                $scope.resetChartConfigOverride = function () {
                    setChartConfigOverride(getViewTime(), getGlobalTimePicker());
                };
                $scope.$on('set chart config override', function (ev, newViewTime, timepicker) {
                    setChartConfigOverride(newViewTime, timepicker);
                });
                $scope.setResolutionOverride = function (res) {
                    const previousResolution = $scope.chartConfigOverride.resolution;
                    $scope.chartConfigOverride.resolution = res;
                    if (previousResolution !== res) {
                        $scope.resetChartConfigOverride();
                    }
                };

                let needDetailResolutionFromPlot = true;
                // A v2 detector has labelresolution and if it exists use it, otherwise its a v1.5 detector
                const labelResolutions =
                    safeLookup($scope, 'detector.labelResolutions') ||
                    safeLookup($scope, 'model.sf_labelResolutions') ||
                    {};
                const labelResolutionKeys = Object.keys(labelResolutions);
                // just pick the resolution of the first label since we could have only one job right now
                // so they are the same anyway, if there are more than one, then we are screwed.
                const resolution = labelResolutions[labelResolutionKeys[0]];

                if (resolution) {
                    $scope.setResolutionOverride(resolution);
                    needDetailResolutionFromPlot = false;
                }

                $scope.$on(
                    CHART_DISPLAY_EVENTS.JOB_RESOLUTION_DETERMINED,
                    function (ev, res, identifier) {
                        if (identifier === 'main') {
                            $scope.viewResolution = res;
                        }
                    }
                );

                function calculateResolution() {
                    if (needDetailResolutionFromPlot) {
                        // check for program text on detector which only exists in v2, otherwise detector is v1 so the program text is from the plots
                        const programText =
                            $scope.detector.programText ||
                            programTextUtils.getV2ProgramText($scope.model.sf_uiModel, true);
                        if (programText) {
                            return preflightEstimation(programText).then(function (response) {
                                $scope.setResolutionOverride(response.resolution);
                                return response.resolution;
                            });
                        }
                    }
                    return $q.when();
                }

                // wait for chart to instantiate then call it
                $timeout(updateSelectedTimeOverlay, 2000);

                function updateSelectedTimeOverlay() {
                    $scope.$broadcast('chart selected time overlay', $scope.chartConfigOverride);
                }

                function updateVolumeSelectedTime() {
                    let start, end;
                    if ($scope.chartConfigOverride.absoluteStart) {
                        // only show selected time when it's absolute time.
                        start = $scope.chartConfigOverride.absoluteStart;
                        end = $scope.chartConfigOverride.absoluteEnd;
                    }
                    $scope.$broadcast('volume time overlay', start, end);
                }

                function setChartConfigOverride(newViewTime, timepicker) {
                    // set the chart config override to time range center around view time
                    // with the resolution specified in override resolution.
                    if ($scope.chartConfigOverride.resolution) {
                        const resolutionOverrideWindow = getResolutionOverrideWindow();
                        if (newViewTime) {
                            // absolute view time
                            const halfWindow = Math.floor(resolutionOverrideWindow / 2);
                            $scope.chartConfigOverride.absoluteStart = newViewTime - halfWindow;
                            $scope.chartConfigOverride.absoluteEnd = newViewTime + halfWindow;
                            delete $scope.chartConfigOverride.range;
                            delete $scope.chartConfigOverride.rangeEnd;
                        } else {
                            // no view time, use the time from time picker
                            if (timepicker) {
                                // Show the window at the end of time picker
                                if (timepicker.relative) {
                                    if (timepicker.start) {
                                        $scope.chartConfigOverride.range = resolutionOverrideWindow;
                                    }
                                    if (timepicker.end && timepicker.end !== 'Now') {
                                        $scope.chartConfigOverride.rangeEnd = convertStringToMS(
                                            timepicker.end
                                        );
                                    } else {
                                        $scope.chartConfigOverride.rangeEnd = 0;
                                    }
                                    delete $scope.chartConfigOverride.absoluteStart;
                                    delete $scope.chartConfigOverride.absoluteEnd;
                                } else {
                                    if (timepicker.end) {
                                        $scope.chartConfigOverride.absoluteEnd = timepicker.end;
                                        $scope.chartConfigOverride.absoluteStart =
                                            $scope.chartConfigOverride.absoluteEnd -
                                            resolutionOverrideWindow;
                                    }
                                    delete $scope.chartConfigOverride.range;
                                    delete $scope.chartConfigOverride.rangeEnd;
                                }
                            } else {
                                delete $scope.chartConfigOverride.absoluteStart;
                                delete $scope.chartConfigOverride.absoluteEnd;

                                $scope.chartConfigOverride.range = resolutionOverrideWindow;
                                $scope.chartConfigOverride.rangeEnd = 0;
                            }
                        }
                    } else {
                        // no resolution override, clear everything.
                        delete $scope.chartConfigOverride.absoluteStart;
                        delete $scope.chartConfigOverride.absoluteEnd;
                        delete $scope.chartConfigOverride.range;
                        delete $scope.chartConfigOverride.rangeEnd;
                    }
                    updateVolumeSelectedTime();
                    updateSelectedTimeOverlay();
                }

                $scope.$on(
                    CHART_DISPLAY_EVENTS.CHART_CLICK_PIN,
                    function chartClickPinHandler(ev, time, isEvent) {
                        $scope.$broadcast('chart pin after load', time, isEvent);
                        setViewTime(time);
                    }
                );

                $scope.$on(CHART_DISPLAY_EVENTS.RESET_LEGEND_PIN, function resetLegendPin() {
                    if (!$scope.model.sf_uiModel.chartconfig.absoluteEnd) {
                        // if it's relative (streaming), when unpinning, reset the view time so it goes back to streaming.
                        clearViewTime();
                    }
                });

                $scope.$on('volume time click', function (ev, time) {
                    // todo: still need to be able to pin when switching to advance.
                    setViewTime(time);
                });

                angular.element($window).on('resize', onResize);

                $scope.$on('$destroy', function () {
                    angular.element($window).off('resize', onResize);
                });

                const originalNumMetricPlots = $scope.numMetricPlots;
                let initialTimeRangeSet = false;
                let timeRangeRequestCount = 0;
                $scope.$watch('sharedChartState.lastStreamedSignalflow', function (nval, oval) {
                    // after more than one change when the chart is loading
                    // ensure the flag requiring resolution to be recalculated is flipped back
                    if (timeRangeRequestCount > 1) {
                        needDetailResolutionFromPlot = true;
                    }
                    if (angular.equals(nval, oval)) {
                        return;
                    }
                    timeRangeRequestCount++;
                    const thisTimeRangeRequest = timeRangeRequestCount;
                    calculateResolution().then(function (resolution) {
                        const thisIsFirstMetric =
                            !originalNumMetricPlots &&
                            detectorUtils.getNumMetricPlots($scope.uiModel);
                        const canSetTimeRange =
                            timeRangeRequestCount === thisTimeRangeRequest && !initialTimeRangeSet;
                        if (resolution && canSetTimeRange && thisIsFirstMetric) {
                            initialTimeRangeSet = true;
                            setGlobalTimePicker(
                                resolutionToChartRangeService(resolution),
                                'Now',
                                true
                            );
                        }
                    });
                });

                $scope.$watch(
                    'uiModel.allPlots',
                    function (nval, oval) {
                        if (angular.equals(nval, oval)) {
                            return;
                        }
                        setNumMetricPlots();
                    },
                    true
                );

                function setNumMetricPlots() {
                    $scope.numMetricPlots = detectorUtils.getNumMetricPlots($scope.uiModel);
                }

                setNumMetricPlots();

                function getResolutionOverrideWindow() {
                    // not rocket science, show number of data point as (percent * 1.5) so, 40% would have 60 data points on the chart.
                    return (
                        $scope.chartConfigOverride.resolution *
                        Math.ceil($scope.detailPercent * 1.5)
                    );
                }

                function makeSureChartTimeIsInRange() {
                    // no point of adjusting view time if there's no native resolution to display.
                    if ($scope.chartConfigOverride.resolution) {
                        const modelStartEnd = modelToStartEnd($scope.model.sf_uiModel.chartconfig);
                        const nativeStartEnd = modelToStartEnd($scope.chartConfigOverride);
                        // add 5 resolution buffer for the case when range was set and we are in between digest loops
                        if (
                            nativeStartEnd.start < modelStartEnd.start ||
                            nativeStartEnd.end > modelStartEnd.end
                        ) {
                            // need to adjust view time to the time at the end
                            const viewTime =
                                modelStartEnd.end - Math.floor(getResolutionOverrideWindow() / 2);
                            setViewTime(viewTime);
                        }
                    }
                }

                $scope.$on('timePickerChanged', function () {
                    makeSureChartTimeIsInRange();
                    $scope.$broadcast('setGlobalTimeRanges');
                });

                $scope.$on(
                    CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED,
                    function (ev, start, end) {
                        $scope.$broadcast('setNewGlobalTimeRange', start, end);
                        if (attr.detectorChartDisableUrl) {
                            // also init the time picker since we don't have URL params, it will not refresh by itself.
                            initializeTimePicker({
                                start: Math.round(start),
                                end: Math.round(end),
                                relative: false,
                            });
                        }
                    }
                );

                $scope.$on(
                    CHART_DISPLAY_EVENTS.DETECTOR_NATIVE_RANGE_SELECTED,
                    function (ev, start, end) {
                        if (!start || !end) {
                            clearViewTime();
                            urlOverridesService.clearViewTime();
                        } else {
                            const time = parseInt((end - start) / 2) + parseInt(start);
                            setViewTime(time);
                            $scope.pinAfterLoad(time);
                        }
                    }
                );

                $scope.detectorNativeRollupMessage = 'determining rollup...';
                $scope.detectorMainRollupMessage = 'determining rollup...';

                $scope.$on(
                    CHART_DISPLAY_EVENTS.CHART_ROLLUP_DETERMINED,
                    function (evt, rollupMessage, identifier) {
                        if (identifier === 'native') {
                            $scope.detectorNativeRollupMessage = rollupMessage;
                        } else if (identifier === 'main') {
                            $scope.detectorMainRollupMessage = rollupMessage;
                        }
                    }
                );

                function initializeTimePicker(obj) {
                    $scope.$broadcast('initializeTimePicker', obj);
                }

                $scope.$on(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, function (ev, obj) {
                    initializeTimePicker(obj);
                });

                function fakeRuleModel(rule) {
                    rule.name = rule.name || 'untitled'; // may be something else
                    rule.invalid = false;
                }

                function createPreview(rule) {
                    return {
                        rules: [rule],
                    };
                }

                // Don't override detectorPreviewConfig if already given
                if (!$scope.detectorPreviewConfig) {
                    $scope.detectorPreviewConfig = {
                        isPreflight: false,
                    };
                }

                if ($scope.rule) {
                    $scope.detectorPreviewConfig.detectLabel = $scope.rule.detectLabel; //only used for v2 detectors
                }

                $scope.$on('rule publish preview', function (ev, rule, preflight) {
                    if (rule) {
                        rule = angular.copy(rule);
                        fakeRuleModel(rule);

                        $scope.detectorPreviewConfig.preview = createPreview(rule);
                        $scope.detectorPreviewConfig.isPreflight = preflight;
                        togglePreflight(preflight);

                        // backward compatible
                        $scope.model.sf_jobResolution = $scope.model.sf_jobResolution || 1000;
                        $scope.model.sf_jobMaxDelay = $scope.model.sf_jobMaxDelay || '';
                    } else {
                        delete $scope.detectorPreviewConfig.preview;
                        $scope.detectorPreviewConfig.isPreflight = false;
                        togglePreflight(false);
                    }
                });

                let preflightEligible;
                function togglePreflight(eligible) {
                    if (preflightEligible === eligible) return;
                    $scope.$broadcast('preflight eligible', eligible);
                    preflightEligible = eligible;
                }

                function setDetailPercent(percent) {
                    if (!percent) {
                        return;
                    }
                    if ($scope.sharedChartState && $scope.sharedChartState.pinnedTimeStamp) {
                        $scope.pinAfterLoad($scope.sharedChartState.pinnedTimeStamp);
                    }
                    $scope.detailPercent = percent;
                    $scope.resetChartConfigOverride();
                    function refreshPercent() {
                        detailChartContainer.css('width', percent + '%');
                        $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, true);
                    }
                    if (detailChartContainer) {
                        refreshPercent();
                    } else {
                        $timeout(refreshPercent, 0);
                    }
                }

                let preferences;
                function savePreference() {
                    currentUser.updatePreferences({
                        sf_id: preferences.sf_id,
                        sf_detectorDetailPercentWidth: $scope.detailPercent,
                    });
                }

                let localViewTime;

                if (attr.detectorChartInitialLocalViewtime) {
                    localViewTime = parseInt(attr.detectorChartInitialLocalViewtime, 10);
                }

                function setViewTime(viewTime) {
                    if (attr.detectorChartDisableUrl) {
                        setLocalViewTime(viewTime);
                    } else {
                        urlOverridesService.setViewTime(viewTime);
                    }
                }

                function clearViewTime() {
                    if (attr.detectorChartDisableUrl) {
                        setLocalViewTime(null);
                    } else {
                        urlOverridesService.clearViewTime();
                    }
                }

                function setLocalViewTime(viewTime) {
                    localViewTime = viewTime;
                    setChartConfigOverride(localViewTime, getGlobalTimePicker());
                }

                function getViewTime() {
                    if (attr.detectorChartDisableUrl) {
                        return localViewTime;
                    } else {
                        return urlOverridesService.getViewTime();
                    }
                }

                function getGlobalTimePicker() {
                    if (attr.detectorChartDisableUrl) {
                        return timepickerUtils.chartConfigToTimePickerObj(
                            $scope.model.sf_uiModel.chartconfig
                        );
                    } else {
                        return urlOverridesService.getGlobalTimePicker();
                    }
                }

                function setGlobalTimePicker(startTime, endTime, isRelativeTime) {
                    if (attr.detectorChartDisableUrl) {
                        const timepickerObj = {
                            start: startTime,
                            end: endTime,
                            relative: isRelativeTime,
                        };
                        const timeRange =
                            timepickerUtils.getChartConfigParametersFromURLTimeObject(
                                timepickerObj
                            );
                        angular.extend($scope.model.sf_uiModel.chartconfig, timeRange);
                        initializeTimePicker(timepickerObj);
                    } else {
                        return urlOverridesService.setGlobalTimePicker(
                            startTime,
                            endTime,
                            isRelativeTime
                        );
                    }
                }

                function processDetailWidth() {
                    $scope.resetChartConfigOverride();
                    $timeout(function () {
                        $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, true);
                    }, 0);
                }

                function onResize() {
                    $scope.$broadcast('window-resized');
                }

                let detailChartContainer;
                const minWidthPx = 430;
                const maxWidthPercent = 60;
                $timeout(function () {
                    detailChartContainer = angular.element(
                        '.detector-at-resolution-chart',
                        element
                    );

                    if (!attr.detectorChartDisableUrl) {
                        const chartView = angular.element('.chart-view', element);
                        const scrollContainer = chartView.scrollParent();

                        const top = chartView.position().top;
                        let floating = false;
                        scrollContainer.scroll(function () {
                            const scrollTop = scrollContainer.scrollTop();
                            if (scrollTop > top) {
                                if (!floating) {
                                    scrollContainer.addClass('preflight-floating');
                                    floating = true;
                                }
                            } else {
                                if (floating) {
                                    scrollContainer.removeClass('preflight-floating');
                                    floating = false;
                                }
                            }
                        });
                    }
                }, 0);

                currentUser.preferences().then(function (prefs) {
                    preferences = prefs;
                    setDetailPercent(prefs.sf_detectorDetailPercentWidth);
                });
                processDetailWidth();

                $scope.resizerMouseDown = function (ev) {
                    const resizerMouse = angular.element('.detector-resizer-mouse', element);
                    const actualDetailChart = angular.element(
                        '.detector-native-chart-container',
                        detailChartContainer
                    );
                    const originalX = ev.pageX;
                    resizerMouse.show();
                    resizerMouse.css('left', '0px');
                    resizerMouse.css('width', '0px');

                    const overallWidth = resizerMouse.parent().parent().width();
                    const containerLeft = resizerMouse.parent().position().left;

                    const chartOffset =
                        actualDetailChart.offset().top - detailChartContainer.offset().top;
                    const chartHeight = actualDetailChart.outerHeight();
                    resizerMouse.css('top', chartOffset + 'px');
                    resizerMouse.css('height', chartHeight + 'px');

                    const minDiff =
                        Math.floor(((100 - maxWidthPercent) * overallWidth) / 100) - containerLeft;
                    const maxDiff = detailChartContainer.width() - minWidthPx;

                    const rootNode = angular.element('.sf-ui');
                    rootNode.addClass('sfx-no-select');

                    const onMouseMove = function (e) {
                        e.preventDefault();
                        const diff = Math.max(minDiff, Math.min(maxDiff, e.pageX - originalX));
                        if (diff < 0) {
                            resizerMouse.css('left', diff + 'px');
                        }
                        resizerMouse.css('width', Math.abs(diff) + 3 + 'px');
                    };
                    const onMouseUp = function (e) {
                        $document.off('mousemove', onMouseMove);
                        $document.off('mouseup', onMouseUp);
                        rootNode.removeClass('sfx-no-select');
                        const xoffset = Math.max(minDiff, Math.min(maxDiff, e.pageX - originalX));
                        const newLeftOffset = containerLeft + xoffset;

                        // needs to hide only after getting the position otherwise we don't have it.
                        resizerMouse.hide();

                        const percent = Math.floor(
                            ((overallWidth - newLeftOffset) * 100) / overallWidth
                        );
                        setDetailPercent(percent);
                        savePreference();
                    };
                    $document.on('mousemove', onMouseMove);
                    $document.on('mouseup', onMouseUp);
                };
            },
        };
    },
]);
