import templateUrl from './detectorWizardTypeOption.tpl.html';

angular.module('signalview.detector.wizard').component('detectorWizardTypePickerOption', {
    bindings: {
        title: '@',
        imagePath: '@',
        selected: '<',
        enabled: '<',
        notAvailableForMetrics: '<',
        recommended: '<',
        cannotChange: '<',
        onClick: '&',
    },
    transclude: true,
    templateUrl,
    controller: [
        function () {
            this.handleClick = () => {
                if (this.notAvailableForMetrics || this.cannotChange) {
                    return;
                }

                this.onClick();
            };
        },
    ],
});
