'use strict';
import templateUrl from './chartInfoPane.tpl.html';

export default {
    templateUrl,
    bindings: {
        params: '=',
        editable: '=',
    },
    controller: [
        '$scope',
        function ($scope) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            function $onInit() {
                const params = $ctrl.params;

                $ctrl.jobId = params.backingJob;
                $ctrl.viewJobId = params.viewJob;
                // downstream usage requires sf_type in objectinfo.tpl.html
                $ctrl.chart = angular.copy(params.chartObj);
                $ctrl.chart.sf_type = 'chart';
                $ctrl.feedback = params.feedback;
                $ctrl.jobAttempts = params.jobAttempts;
                $ctrl.traceId = params.traceId;
                $ctrl.snapshot = params.snapshot;
                $ctrl.jobHistory = params.jobHistory;
                $ctrl.logsTimeRange = params.logsTimeRange;
                $ctrl.logsFilters = params.logsFilters;
                $ctrl.logsIndexes = params.logsIndexes;
                $ctrl.isLogsView =
                    $ctrl.chart?.options?.type === 'LogsChart' ||
                    $ctrl.chart?.options?.type === 'LogsTimeSeriesChart';
                $ctrl.defaultConnection = $ctrl.chart.options?.defaultConnection
                    ? $ctrl.chart.options?.defaultConnection + ':'
                    : $ctrl.isLogsView
                    ? 'Observability'
                    : '';
            }

            $ctrl.cancel = function () {
                $scope.$emit('dismiss modal');
            };
        },
    ],
};
