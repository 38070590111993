export function getEventChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Pod Events',
        type: 'Event',
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'Pod Alerts',
            streamLabel: 'POD_ALERTS',
            programText: `POD_ALERTS = alerts(detector_name='*', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_pod_name', '*') ${filterString}).publish(label='POD_ALERTS')`,
        },
        {
            displayName: 'Pod Events',
            streamLabel: 'POD_EVENTS',
            programText: `POD_EVENTS = events(eventType='*', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_pod_name', '*') ${filterString}).publish(label='POD_EVENTS')`,
        },
    ];

    return { chartOptions, publishLabelOptions };
}
