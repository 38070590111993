'use strict';
import templateUrl from './aclDashboardPermissions.tpl.html';
import ExitGuardService from '../../../../app/dashboardGroup/aclDashboardGroup/ExitGuardService';
import AclDashboardService from './AclDashboardService';

export default {
    templateUrl,
    bindings: {
        dashboardModel: '<',
        dashboardGroup: '<',
        isMirror: '<',
        readOnly: '<',
        missingUpdateDashboardCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$injector',
        '$scope',
        'themeService',
        'currentUser',
        'exitGuardModal',
        function ($injector, $scope, themeService, currentUser, exitGuardModal) {
            const $ctrl = this;
            const aclDashboardService = $injector.instantiate(AclDashboardService);

            $ctrl.$onInit = () => {
                $scope.exitGuardService = new ExitGuardService(
                    $ctrl.tabby.enableExitGuard,
                    $ctrl.tabby.disableExitGuard,
                    exitGuardModal
                );

                $scope.onClose = (dashboard) => $scope.$emit('close modal', dashboard);
                $scope.onDismiss = () => $scope.$emit('dismiss modal');
                $scope.save = (permissions) =>
                    aclDashboardService.save({ ...$ctrl.dashboardModel, permissions });

                $scope.themeKey = themeService.getColorScheme();
                $scope.userData = null;

                Promise.all([currentUser.getOrgMember(), currentUser.orgId()]).then(
                    ([user, orgId]) => {
                        $scope.userData = { userId: user.id, isAdmin: user.admin, orgId };
                        $scope.isMirror = $ctrl.isMirror;
                        $scope.dashboardId = $ctrl.dashboardModel?.id;
                        $scope.currentDashboardGroupId = $ctrl.dashboardGroup?.id;
                        $scope.$applyAsync();
                    }
                );
            };
        },
    ],
};
