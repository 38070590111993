import React, { useState } from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import { react2angular } from 'react2angular';
import P from '@splunk/react-ui/Paragraph';
import List from '@splunk/react-ui/List';
import { ConfirmationModal } from '@splunk/olly-common/ConfirmationModal';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

const StyledListItem = styled(List.Item)`
    font-weight: ${variables.fontWeightBold};
    word-break: break-all;
`;

const MODAL_WIDTH = '600px';

const DeleteConfirmationModal = ({
    title,
    description,
    listTitle,
    listElements,
    callToAction = 'Delete',
    typeConfirmation,
    isOpen,
    onCancel,
    onDelete,
    isLoading,
}) => {
    const [typedText, setTypedText] = useState('');

    const handleCancel = () => {
        setTypedText('');
        onCancel();
    };

    const themeKey = useLegacyThemeServiceKey();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <ConfirmationModal
                confirmationButtonMessage={callToAction}
                onConfirm={onDelete}
                onCancel={handleCancel}
                isOpen={isOpen}
                title={title}
                disableButton={(typeConfirmation && typedText !== 'delete') || isLoading}
            >
                {isLoading ? (
                    <div style={{ width: MODAL_WIDTH, textAlign: 'center' }}>
                        <i className={`busy-spinner-${themeKey}`}></i>
                    </div>
                ) : (
                    <div style={{ width: MODAL_WIDTH }}>
                        <P>{description}</P>
                        {listTitle && <P>{listTitle}</P>}
                        {!!listElements?.length && (
                            <List>
                                {listElements.map((element) => (
                                    <StyledListItem key={`list-item-${element}`}>
                                        <a href={element.link} target="_blank" rel="noreferrer">
                                            {element.name}
                                        </a>
                                    </StyledListItem>
                                ))}
                            </List>
                        )}
                        {typeConfirmation && (
                            <P>
                                <span>
                                    Type the word &apos;delete&apos; to confirm deletion request
                                </span>
                                &nbsp;
                                <input
                                    type="text"
                                    value={typedText}
                                    onChange={(e) => {
                                        setTypedText(e.target.value);
                                    }}
                                    autoFocus
                                />
                            </P>
                        )}
                    </div>
                )}
            </ConfirmationModal>
        </ThemeProvider>
    );
};

export const deleteConfirmationModal = react2angular(DeleteConfirmationModal, [
    'title',
    'description',
    'listTitle',
    'listElements',
    'callToAction',
    'typeConfirmation',
    'isOpen',
    'onCancel',
    'onDelete',
    'isLoading',
]);
