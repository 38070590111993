export default {
    BASE_METRIC_TEMPLATES: [
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_workload',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_workload_name',
                    type: 'property',
                },
            ],
            template:
                "POD_PHASE = data('kubernetes.pod_phase'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_name',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_workload',
                    type: 'property',
                },
            ],
            template:
                "DESIRED = data('kubernetes.*.desired*'{{#workloadTranslatedFilters}}, filter={{{workloadTranslatedFilters}}}{{/workloadTranslatedFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'kubernetes_name'})",
        },
        {
            template:
                "SF_SERVICE_POD_PHASE = POD_PHASE.count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'sf_service']).dimensions(aliases={'sf_k8s_service': 'sf_service'})",
        },
        {
            template:
                "K8S_SERVICE_POD_PHASE = POD_PHASE.count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'service']).dimensions(aliases={'sf_k8s_service': 'service'})",
        },
        {
            template:
                "K8S_SERVICE_DESIRED = DESIRED.count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'service']).dimensions(aliases={'sf_k8s_service': 'service'})",
        },
    ],
    METRICS: [
        {
            displayName: 'Desired',
            job: {
                varName: 'DESIRED',
            },
        },
        {
            displayName: 'Pending',
            job: {
                template:
                    "POD_STATUS_PENDING = POD_PHASE.equals(1).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_PENDING',
            },
        },
        {
            displayName: 'Running',
            job: {
                template:
                    "POD_STATUS_RUNNING = POD_PHASE.equals(2).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_RUNNING',
            },
        },
        {
            displayName: 'Succeeded',
            job: {
                template:
                    "POD_STATUS_SUCCEEDED = POD_PHASE.equals(3).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_SUCCEEDED',
            },
        },
        {
            displayName: 'Failed',
            job: {
                template:
                    "POD_STATUS_FAILED = POD_PHASE.equals(4).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_FAILED',
            },
        },
        {
            displayName: 'Unknown',
            job: {
                template:
                    "POD_STATUS_UNKNOWN = POD_PHASE.equals(5).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_UNKNOWN',
            },
        },
        {
            displayName: 'SF_K8s_Service',
            job: {
                template:
                    'SF_K8S_SERVICE = union(SF_SERVICE_POD_PHASE, K8S_SERVICE_POD_PHASE, K8S_SERVICE_DESIRED)',
                varName: 'SF_K8S_SERVICE',
            },
        },
    ],
    COLUMNS: [
        {
            aggregate: null,
            displayName: 'Workload',
            format: 'link',
            metric: null,
            property: 'kubernetes_workload_name',
            propertiesToLinkOn: [
                'kubernetes_cluster',
                'kubernetes_workload_name',
                'kubernetes_namespace',
            ],
            panelName: 'kubernetes_workload_name',
        },
        {
            aggregate: null,
            displayName: 'Workload Type',
            format: null,
            metric: null,
            property: 'kubernetes_workload',
        },
        {
            aggregate: null,
            displayName: 'Namespace',
            format: null,
            metric: null,
            property: 'kubernetes_namespace',
        },
        {
            aggregate: null,
            displayName: 'Desired Pods',
            format: 'Number',
            metric: null,
            property: 'Desired',
        },
        {
            aggregate: null,
            displayName: 'Pending Pods',
            format: 'Number',
            metric: null,
            property: 'Pending',
        },
        {
            aggregate: null,
            displayName: 'Running Pods',
            format: 'Number',
            metric: null,
            property: 'Running',
        },
        {
            aggregate: null,
            displayName: 'Succeeded Pods',
            format: 'Number',
            metric: null,
            property: 'Succeeded',
        },
        {
            aggregate: null,
            displayName: 'Failed Pods',
            format: 'Number',
            metric: null,
            property: 'Failed',
        },
        {
            aggregate: null,
            displayName: 'Unknown Pods',
            format: 'Number',
            metric: null,
            property: 'Unknown',
        },
    ],
    UNIQUE_ID: '{{ kubernetes_workload_name }}:{{ kubernetes_workload }}:{{ kubernetes_cluster }}',
    // used in order to completely remove data for a row when a certain condition is met
    FILTER_OUT_CLAUSE: {
        metric: 'Desired',
        operator: '=== 0',
    },
};
