import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Multiselect from '@splunk/react-ui/Multiselect';
import Select from '@splunk/react-ui/Select';
import { takeFocus } from '@splunk/ui-utils/focus';

class OutdatedSearchResultsError extends Error {}

function skipOutdatedResults(searchProvider) {
    let lastCallId = null;
    return (...args) => {
        const id = Date.now() + Math.round(Math.random() * 100);
        lastCallId = id;
        return searchProvider(...args).then((results) => {
            if (lastCallId && lastCallId !== id) {
                throw new OutdatedSearchResultsError();
            }

            return results;
        });
    };
}

export default function SimpleSuggestion({
    placeholder,
    onSelectCallback,
    onChangeCallback,
    searchProvider,
    mode,
}) {
    const [options, setOptions] = useState([]);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const elementRef = useRef();
    const searchMethod = useRef(skipOutdatedResults((term) => searchProvider(term)));

    const UIComponent = mode === 'selectAndDestroy' ? Multiselect : Select;

    const componentSpecificProps = {};

    if (mode === 'selectOne') {
        componentSpecificProps.filter = true;
    }

    useEffect(() => {
        if (mode === 'selectAndDestroy') {
            takeFocus(elementRef.current);
        }
        search('');
    }, [mode]);

    return (
        <UIComponent
            elementRef={elementRef}
            controlledFilter
            inline={true}
            placeholder={placeholder}
            onChange={onSelect}
            onFilterChange={(e, { keyword }) => onChange(keyword)}
            isLoadingOptions={searchInProgress}
            style={{ minWidth: 314, width: '100%' }}
            {...componentSpecificProps}
        >
            {options.map((option) => (
                <UIComponent.Option label={option.label} value={option.id} key={option.id} />
            ))}
        </UIComponent>
    );

    function getSelectedId(selected) {
        if (mode === 'selectAndDestroy') {
            return selected.values?.[0];
        }

        return selected.value;
    }

    function onChange(keyword) {
        search(keyword);
        onChangeCallback(keyword);
    }

    function onSelect(e, selected) {
        const selectedId = getSelectedId(selected);
        const selectedOption = options.find((option) => option.id === selectedId);
        onSelectCallback(selectedOption);
    }

    function search(phrase) {
        setOptions([]);
        setSearchInProgress(true);
        return searchMethod
            .current(phrase)
            .then((results) => {
                setOptions(results || []);
                setSearchInProgress(false);
            })
            .catch(() => {});
    }
}

SimpleSuggestion.propTypes = {
    searchProvider: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    onSelectCallback: PropTypes.func,
    onChangeCallback: PropTypes.func,
    mode: PropTypes.oneOf(['selectOne', 'selectAndDestroy']),
};

SimpleSuggestion.defaultProps = {
    onSelectCallback: () => {},
    onChangeCallback: () => {},
    mode: 'selectOne',
};
