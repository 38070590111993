import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BEMHelper from 'react-bem-helper';
import _ from 'lodash';

const className = new BEMHelper('information-durationinput');

const VALID_DURATION_PATTERN = /^([0-9]+)([mhd]){1}$/i;
const DEFAULT_UNIT = 'd';
const DEFAULT_NUMBER = 1;

function getDurationString(number, unit) {
    return `${number}${unit}`;
}

function isValidDuration(duration) {
    return !!duration.match(VALID_DURATION_PATTERN);
}

export default function InformationDurationInput({ label, onChange, value, additionalInfo }) {
    const [checked, setChecked] = useState(false);
    const [number, setNumber] = useState(DEFAULT_NUMBER);
    const [unit, setUnit] = useState(DEFAULT_UNIT);

    useEffect(() => {
        if (value && isValidDuration(value)) {
            const matches = value.match(VALID_DURATION_PATTERN);
            if (matches && matches.length >= 3) {
                setChecked(true);
                setNumber(matches[1]);
                setUnit(matches[2]);
            }
        }
    }, [value]);

    const onChangeCheckbox = () => {
        const newValue = !checked;
        if (newValue) {
            const newDurationString = getDurationString(number, unit);
            onChange(newDurationString, isValidDuration(newDurationString));
        } else {
            // Saving as an empty string instead of null as the API gives 502 on saving null.
            onChange('', true);
        }
        setChecked(newValue);
    };

    const onChangeNumber = (e) => {
        const newValue = e.target.value;
        const newDurationString = getDurationString(newValue, unit);
        onChange(newDurationString, isValidDuration(newDurationString));
        setNumber(newValue);
    };

    const onChangeUnit = (e) => {
        const newValue = e.target.value;
        const newDurationString = getDurationString(number, newValue);
        onChange(newDurationString, isValidDuration(newDurationString));
        setUnit(newValue);
    };

    const additionalInfoList = _.map(additionalInfo, (infoStr, index) => {
        return <div key={index}>{infoStr}</div>;
    });

    return (
        <div {...className()}>
            <label>
                <input
                    type="checkbox"
                    name={label}
                    checked={checked}
                    onClick={onChangeCheckbox}
                    readOnly
                />
                <span {...className('input-label')}>{label}</span>
                {checked && (
                    <>
                        <input
                            {...className(
                                'duration-number',
                                isValidDuration(value) ? null : 'invalid'
                            )}
                            type="text"
                            name={label}
                            value={number}
                            onChange={onChangeNumber}
                        />
                        <select
                            className="chart-builder-select"
                            onChange={onChangeUnit}
                            value={unit}
                        >
                            <option value="m" selected="selected">
                                minutes
                            </option>
                            <option value="h">hours</option>
                            <option value="d">days</option>
                        </select>
                    </>
                )}
            </label>
            <div {...className('additional-information')}>{additionalInfoList}</div>
        </div>
    );
}

InformationDurationInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    additionalInfo: PropTypes.array,
};
