import { ClusterMapLayoutCalculationError } from '../../../../app/kubeNavigator/clusterMapErrors';

export default [
    'clusterMapVizConfigUtil',
    'clusterMapUtil',
    '_',
    function (clusterMapVizConfigUtil, clusterMapUtil, _) {
        const configTypes = clusterMapVizConfigUtil.configTypes;
        const SQUARE_ALIGNMENT_RATIO = 1;

        return function (config) {
            return {
                getLayoutForResource,
                getDetailedChildContainedDimension,
                getCompactChildContainedDimension,
            };

            // Generates Overview layout if root data depth is 0 otherwise generates resource layout.
            function getLayoutForResource(data, canvasSize, detailingMaxDepth) {
                let availableSpace = clusterMapUtil.expandCoords({
                    x0: 0,
                    y0: 0,
                    width: canvasSize.width,
                    height: canvasSize.height,
                    margin: { x: 0, y: 0 },
                });

                if (!data || !data.children || !data.children.length) {
                    return { canvasSize: availableSpace };
                }

                const largestSizes = getLargestSizes(data);

                detailingMaxDepth = !_.isNumber(detailingMaxDepth)
                    ? largestSizes.length
                    : detailingMaxDepth;

                const depth = data.depth;

                // Special Case, At depth 0, All Level 1 resources are under a Pseudo overview resource
                const resourceConfig = config.sizing[depth || 1];
                const globalConfig = _.merge({}, resourceConfig, config.sizing[0]);
                const detailedGlobalConfig = globalConfig.detailed; // Global will always be detailed

                availableSpace.margin = clusterMapUtil.getMargin(detailedGlobalConfig);

                if (depth === 0) {
                    // Special Case
                    return generateGlobalLayout(
                        data.children,
                        availableSpace,
                        detailedGlobalConfig,
                        detailingMaxDepth,
                        globalConfig.alignmentRatio
                    );
                }

                // Resource at global level will get all availableSpace
                availableSpace.sizeConfig = detailedGlobalConfig;
                // For the sub-level,
                // we need to calculate size requirements for a depth 1 more than current depth
                const contentSpace = clusterMapUtil.getContentSize(
                    availableSpace,
                    detailedGlobalConfig
                );
                availableSpace.subLevel = getDetailedChildContainedDimension(
                    largestSizes,
                    contentSpace,
                    detailedGlobalConfig.subLevel,
                    detailingMaxDepth,
                    false
                );
                let unboundedLayout = false;
                if (!availableSpace.subLevel) {
                    // We failed the densest assignments. Let the canvas draw out of bounds.
                    //  This means the canvas is larger than the view height or width.
                    //  (This is the same strategy as used for top-level depth=0 in generateGlobalLayout function)
                    const subLevelCompactScale = detailedGlobalConfig.subLevel.compact;

                    const assignments = getUnboundedLayoutAssignment(
                        availableSpace,
                        [data],
                        detailedGlobalConfig,
                        subLevelCompactScale,
                        globalConfig.alignmentRatio
                    );
                    justifyLayoutSizing(assignments, availableSpace);

                    // Can we expand layout of our item starting from the smallest to the biggest
                    expandAssignedLayoutContent(
                        assignments,
                        [data],
                        detailedGlobalConfig,
                        subLevelCompactScale,
                        globalConfig.alignmentRatio
                    );

                    availableSpace = assignments[0];
                    unboundedLayout = true;
                }

                moveExcessContainerSpace(availableSpace);
                return { canvasSize: availableSpace, assignments: availableSpace, unboundedLayout };
            }

            /**** Packing algorithm. Greedy, pack the biggest space first heuristic.  ****/
            function generateGlobalLayout(
                globalItems,
                availableSpace,
                overviewSizeConfig,
                maxDepth,
                alignmentRatio = SQUARE_ALIGNMENT_RATIO
            ) {
                if (!overviewSizeConfig || overviewSizeConfig.type !== configTypes.DETAILED) {
                    return;
                }

                clusterMapUtil.sortMapItems(globalItems, config.globalSortByKey);

                const subLevelCompactScale = overviewSizeConfig.subLevel.compact;

                let assignments = tryLayoutAssignmentAcrossScale(
                    availableSpace,
                    globalItems,
                    overviewSizeConfig,
                    subLevelCompactScale,
                    alignmentRatio
                );

                // We failed the densest assignments. Let the canvas draw out of bounds.
                if (!assignments) {
                    assignments = getUnboundedLayoutAssignment(
                        availableSpace,
                        globalItems,
                        overviewSizeConfig,
                        subLevelCompactScale,
                        alignmentRatio
                    );
                }

                // Justify content in available space
                justifyLayoutSizing(assignments, availableSpace);

                // Can we expand layout of our item starting from the smallest to the biggest
                expandAssignedLayoutContent(
                    assignments,
                    globalItems,
                    overviewSizeConfig,
                    maxDepth,
                    alignmentRatio
                );

                assignments.forEach((assignment) => moveExcessContainerSpace(assignment));

                return { assignments, canvasSize: availableSpace };
            }

            function moveExcessContainerSpace(
                assignment,
                addToHorizontalMargins = true,
                addToVerticalMargins = true
            ) {
                const contentWidth = assignment.subLevel.columns * assignment.subLevel.width;
                const contentHeight = assignment.subLevel.rows * assignment.subLevel.height;
                const { width, height } = clusterMapUtil.getContainerSize(
                    { width: contentWidth, height: contentHeight },
                    assignment.sizeConfig
                );

                if (addToHorizontalMargins) {
                    assignment.margin.x += (assignment.width - width) / 2;
                }

                if (addToVerticalMargins) {
                    assignment.margin.y += (assignment.height - height) / 2;
                }
            }

            // Does a binary search across full range of compact size config's scale to find the best size assignment,
            // Stops when it hits the threshold
            function tryLayoutAssignmentAcrossScale(
                availableSpace,
                globalItems,
                overviewSizeConfig,
                subLevelCompactScale,
                alignmentRatio,
                threshold = 0.05
            ) {
                let assignments;

                // Binary Search
                const maxScale = 1;
                const minScale = 0;
                let left = minScale;
                let right = maxScale;
                let scale = maxScale;
                let runningAssignment;

                do {
                    if (scale < threshold) {
                        left = right = scale = minScale;
                    }

                    const subLevelCompactConfig = {
                        configType: configTypes.COMPACT,
                        compact: subLevelCompactScale.dimension(scale),
                    };
                    const trialSubLevelSizingConfigs = Array(globalItems.length).fill(
                        subLevelCompactConfig
                    );
                    runningAssignment = assignSpace(
                        globalItems,
                        [availableSpace],
                        overviewSizeConfig,
                        trialSubLevelSizingConfigs,
                        alignmentRatio
                    );

                    if (runningAssignment) {
                        // We got an assignment, try something larger
                        assignments = runningAssignment;
                        left = scale;
                    } else {
                        // No assignment, try something smaller
                        right = scale;
                    }

                    scale = (left + right) / 2;
                } while (scale > minScale && scale < maxScale && right - left >= threshold);

                return assignments;
            }

            // Do the size assignment after horizontal space expansion to accommodate smallest of assignment.
            // Also does vertical space expansion to at-least accommodate single item height if necessary
            function getUnboundedLayoutAssignment(
                availableSpace,
                globalItems,
                overviewSizeConfig,
                subLevelCompactScale,
                alignmentRatio
            ) {
                const largestNumberOfChildren = getLargestSizes(globalItems[0].parent, 1);
                const densestCompactSizeConfig = subLevelCompactScale.dimension(0);
                const subLevelCompactConfig = {
                    configType: configTypes.COMPACT,
                    compact: densestCompactSizeConfig,
                };
                const subLevelSizingConfigs = Array(globalItems.length).fill(subLevelCompactConfig);

                let assignments;

                for (let trialScale = 0; !assignments && trialScale < 1; trialScale += 0.1) {
                    const compactSizingForExpansion = subLevelCompactScale.dimension(trialScale);

                    const largestItemChildDimensions = getCompactChildContainedDimension(
                        largestNumberOfChildren,
                        compactSizingForExpansion,
                        {},
                        alignmentRatio
                    );

                    const largestItemContainerSize = clusterMapUtil.getContainerSize(
                        {
                            width:
                                largestItemChildDimensions.columns *
                                largestItemChildDimensions.width,
                            height:
                                largestItemChildDimensions.rows * largestItemChildDimensions.height,
                        },
                        overviewSizeConfig
                    );

                    const areaForAllItems =
                        globalItems.length *
                        largestItemContainerSize.width *
                        largestItemContainerSize.height;
                    const availableHeight = Math.max(
                        availableSpace.height,
                        largestItemContainerSize.height
                    );
                    const expandedWidth = areaForAllItems / availableHeight;
                    const expandedCanvas = clusterMapUtil.expandCoords({
                        x0: availableSpace.x0,
                        y0: availableSpace.y0,
                        x1: availableSpace.x0 + expandedWidth,
                        y1: availableSpace.y0 + availableHeight,
                    });

                    assignments = assignSpace(
                        globalItems,
                        [expandedCanvas],
                        overviewSizeConfig,
                        subLevelSizingConfigs,
                        alignmentRatio
                    );
                }

                if (!assignments) {
                    throw new ClusterMapLayoutCalculationError('Layout calculation failed!');
                }

                assignments.forEach((item) => {
                    availableSpace.x1 = Math.max(availableSpace.x1, item.x1);
                    availableSpace.y1 = Math.max(availableSpace.y1, item.y1);
                });

                availableSpace.width = availableSpace.x1 - availableSpace.x0;
                availableSpace.height = availableSpace.y1 - availableSpace.y0;

                return assignments;
            }

            // Tries to expand assignment levels if possible
            function expandAssignedLayoutContent(
                assignments,
                globalItems,
                overviewSizeConfig,
                maxDepth,
                alignmentRatio
            ) {
                const subLevelMinCompactConfigs = getSmallestSubLevelConfig(
                    assignments,
                    globalItems,
                    overviewSizeConfig,
                    maxDepth,
                    overviewSizeConfig.subLevel
                );

                // We don't have any detailed sub-level
                if (!subLevelMinCompactConfigs || subLevelMinCompactConfigs.length <= 1) {
                    return;
                }

                for (
                    let i = 0, subLevelConfig = overviewSizeConfig.subLevel;
                    subLevelConfig && i < subLevelMinCompactConfigs.length;
                    i++,
                        subLevelConfig = subLevelConfig.detailed && subLevelConfig.detailed.subLevel
                ) {
                    if (!subLevelConfig.compact) {
                        return;
                    }
                    subLevelMinCompactConfigs[i] =
                        subLevelMinCompactConfigs[i] || subLevelConfig.compact;
                }

                const detailingMaxDepth = Math.min(maxDepth, subLevelMinCompactConfigs.length);
                const subLevelFixedSizeConfig = {
                    alignmentRatio,
                    detailed: overviewSizeConfig.subLevel.detailed,
                    fixedCompactDepthConfig: subLevelMinCompactConfigs,
                };

                for (let i = assignments.length - 1; i >= 0; i--) {
                    const itemContentSpace = clusterMapUtil.getContentSize(
                        assignments[i],
                        overviewSizeConfig
                    );
                    assignments[i].subLevel = getDetailedChildContainedDimension(
                        getLargestSizes(globalItems[i]),
                        itemContentSpace,
                        subLevelFixedSizeConfig,
                        detailingMaxDepth,
                        false
                    );
                }
            }

            function getSmallestSubLevelConfig(
                assignments,
                globalItems,
                overviewSizeConfig,
                maxDepth,
                subLevelVariableSizeConfig
            ) {
                let detailingDepth = 0;
                let finalSubLevelConfigs;
                let sizeAssignmentFailed = false;

                while (detailingDepth <= maxDepth && !sizeAssignmentFailed) {
                    const subLevelMinConfigs = [];
                    let lastAssignmentDepth;
                    let assignmentIdx = assignments.length;

                    while (--assignmentIdx >= 0) {
                        const itemContentSpace = clusterMapUtil.getContentSize(
                            assignments[assignmentIdx],
                            overviewSizeConfig
                        );

                        let subLevelConfig = getDetailedChildContainedDimension(
                            getLargestSizes(globalItems[assignmentIdx]),
                            itemContentSpace,
                            subLevelVariableSizeConfig,
                            detailingDepth,
                            false
                        );

                        if (!subLevelConfig) {
                            sizeAssignmentFailed = true;
                            break;
                        }

                        let depth = 0;
                        while (subLevelConfig) {
                            if (!subLevelConfig.subLevel) {
                                if (
                                    subLevelConfig.sizeConfig &&
                                    subLevelConfig.sizeConfig.type === configTypes.COMPACT &&
                                    (!subLevelMinConfigs[depth] ||
                                        subLevelMinConfigs[depth].width > subLevelConfig.width)
                                ) {
                                    subLevelMinConfigs[depth] = subLevelConfig.sizeConfig;
                                }
                                break;
                            } else {
                                subLevelConfig = subLevelConfig.subLevel;
                                depth++;
                            }
                        }

                        if (!_.isNumber(lastAssignmentDepth)) {
                            lastAssignmentDepth = depth;
                        } else if (lastAssignmentDepth !== depth) {
                            sizeAssignmentFailed = true;
                            break;
                        }
                    }

                    if (!sizeAssignmentFailed) {
                        finalSubLevelConfigs = subLevelMinConfigs;
                    }
                    detailingDepth++;
                }

                return finalSubLevelConfigs;
            }

            function assignSpace(nodes, spaces, nodeSizeConfig, subLevelConfigs, alignmentRatio) {
                if (!nodes || !nodes.length) {
                    return null;
                }
                const nodeLayout = [];

                for (const idx in nodes) {
                    if (_.isEmpty(spaces)) {
                        return null;
                    }

                    const current = nodes[idx];
                    const nodeContainer = getLargestSpace(spaces);
                    const accommodatingBox = clusterMapUtil.getContentSize(
                        nodeContainer,
                        nodeSizeConfig
                    );
                    const subLevelSizeConfig = subLevelConfigs[idx];

                    let subLevelSize;
                    if (subLevelSizeConfig.configType === configTypes.COMPACT) {
                        subLevelSize = getCompactChildContainedDimension(
                            getLargestSizes(current, 0),
                            subLevelSizeConfig.compact,
                            accommodatingBox,
                            alignmentRatio
                        );
                    }

                    if (!subLevelSize) {
                        return null;
                    }

                    const nodeContainerSize = clusterMapUtil.getContainerSize(
                        {
                            width: subLevelSize.columns * subLevelSize.width,
                            height: subLevelSize.rows * subLevelSize.height,
                        },
                        nodeSizeConfig
                    );

                    const totalContainerHeight = nodeContainerSize.height;
                    const totalContainerWidth = nodeContainerSize.width;

                    if (
                        totalContainerWidth > nodeContainer.width ||
                        totalContainerHeight > nodeContainer.height
                    ) {
                        return null;
                    }

                    nodeLayout.push(
                        clusterMapUtil.expandCoords({
                            x0: nodeContainer.x0,
                            y0: nodeContainer.y0,
                            width: nodeContainerSize.width,
                            height: nodeContainerSize.height,
                            sizeConfig: nodeSizeConfig,
                            subLevel: subLevelSize,
                            margin: clusterMapUtil.getMargin(nodeSizeConfig),
                        })
                    );

                    removeFreeSpace(spaces, nodeContainer, nodeContainerSize);
                }

                return nodeLayout;
            }

            function getLargestSizes(resource, atIndex) {
                let largestSizes = resource.largestSizes;

                if (config.minChildrenSizes && resource.largestSizes) {
                    const depth = resource.depth || 0;
                    largestSizes = clusterMapUtil.maxInArrays([
                        resource.largestSizes,
                        config.minChildrenSizes.slice(depth, depth + resource.largestSizes.length),
                    ]);
                }

                return _.isNumber(atIndex) && atIndex >= 0 ? largestSizes[atIndex] : largestSizes;
            }

            function getDetailedChildContainedDimension(
                largestChildCounts,
                contentSpace,
                sizeConfig,
                maxSizingDepth = 0
            ) {
                if (!largestChildCounts || !contentSpace || !sizeConfig) {
                    return null;
                }

                let alignmentRatio = sizeConfig.alignmentRatio || SQUARE_ALIGNMENT_RATIO;

                // First get compact sizing
                let dimension = null;
                if (sizeConfig.fixedCompactDepthConfig || sizeConfig.compact) {
                    let subLevelCompactSizeConfig;
                    if (_.isEmpty(sizeConfig.fixedCompactDepthConfig)) {
                        subLevelCompactSizeConfig = sizeConfig.compact;
                    } else {
                        subLevelCompactSizeConfig = sizeConfig.fixedCompactDepthConfig[0];
                    }

                    if (subLevelCompactSizeConfig) {
                        dimension = getCompactChildContainedDimension(
                            largestChildCounts[0],
                            subLevelCompactSizeConfig,
                            contentSpace,
                            alignmentRatio
                        );
                    }
                }

                if (maxSizingDepth <= 1 || !sizeConfig.detailed || !sizeConfig.detailed.subLevel) {
                    return dimension;
                }

                // Try going detailed one more level down
                const contentWidth = contentSpace.width;
                const contentHeight = contentSpace.height;

                const preferredAlignment = getPreferredAlignment(
                    largestChildCounts[0],
                    alignmentRatio,
                    contentSpace
                );

                const { rows, columns } = preferredAlignment;
                alignmentRatio = preferredAlignment.alignmentRatio;

                const childrenCapacity = rows * columns;

                let subLevelSizeConfig;
                if (_.isEmpty(sizeConfig.fixedCompactDepthConfig)) {
                    subLevelSizeConfig = sizeConfig.detailed.subLevel;
                } else {
                    subLevelSizeConfig = {
                        detailed: sizeConfig.detailed.subLevel.detailed,
                        fixedCompactDepthConfig: sizeConfig.fixedCompactDepthConfig.slice(1),
                    };
                }

                if (!subLevelSizeConfig) {
                    return dimension;
                }

                const perItemMaxSize = {
                    width: contentWidth / columns,
                    height: contentHeight / rows,
                };

                const perItemMaxContentSize = clusterMapUtil.getContentSize(
                    perItemMaxSize,
                    sizeConfig.detailed
                );

                const subLevelDimension = getDetailedChildContainedDimension(
                    largestChildCounts.slice(1),
                    perItemMaxContentSize,
                    subLevelSizeConfig,
                    maxSizingDepth - 1
                );

                if (!subLevelDimension) {
                    return dimension;
                }

                const subLevelContent = {
                    width: subLevelDimension.columns * subLevelDimension.width,
                    height: subLevelDimension.rows * subLevelDimension.height,
                };

                if (sizeConfig.forceAlign) {
                    const currentAlignmentRatio = subLevelContent.width / subLevelContent.height;
                    if (alignmentRatio >= currentAlignmentRatio) {
                        subLevelContent.width = subLevelContent.height * alignmentRatio;
                    } else {
                        subLevelContent.height = subLevelContent.width * alignmentRatio;
                    }
                }

                const { width, height } = clusterMapUtil.getContainerSize(
                    subLevelContent,
                    sizeConfig.detailed
                );

                const detailedMinSide = sizeConfig.detailed.minSide;
                if ((detailedMinSide && width < detailedMinSide) || height < detailedMinSide) {
                    return dimension;
                }

                return {
                    width,
                    height,
                    rows,
                    columns,
                    childrenCapacity,
                    alignmentRatio,
                    sizeConfig: sizeConfig.detailed,
                    subLevel: subLevelDimension,
                };
            }

            /**
             * Based on Top-Down sizing with fixed available content space, calculates size of a summarized child
             * @param {number} numberOfChildren
             *    Number of children to calculate for
             * @param {{getScale, dimension} | { type, width, height }} childConfig
             *    Scale to be used for child dimension calculation
             * @param {Object} [contentSpace]
             *    Content space available to all children combined
             * @param {number} contentSpace.width
             *    Width available to all children combined
             * @param {number} contentSpace.height
             *    Height available to all children combined
             * @param {number} [alignmentRatio]
             *    Preferred alignment ratio
             * @return {{width: number, height: number, rows: number, columns: number,
             *    childrenCapacity: number, alignmentRatio: number, sizeConfig: *} | null}
             */
            function getCompactChildContainedDimension(
                numberOfChildren,
                childConfig,
                contentSpace = {},
                alignmentRatio = 1
            ) {
                if (
                    !numberOfChildren ||
                    !childConfig ||
                    (childConfig.type !== configTypes.COMPACT && !childConfig.dimension)
                ) {
                    return null;
                }

                const contentWidth = contentSpace.width;
                const contentHeight = contentSpace.height;

                const preferredAlignment = getPreferredAlignment(
                    numberOfChildren,
                    alignmentRatio,
                    contentSpace
                );

                const { rows, columns } = preferredAlignment;
                alignmentRatio = preferredAlignment.alignmentRatio;

                const childrenCapacity = rows * columns;

                let sizeConfig;
                if (childConfig.dimension) {
                    // Math.floor while getting the scale back is done to avoid epsilon errors
                    // which otherwise result in just over the required dimensions
                    const scale = !(contentWidth && contentHeight)
                        ? 0
                        : childConfig.getScale(
                              Math.floor(Math.min(contentWidth / columns, contentHeight / rows))
                          );
                    if (scale < 0) {
                        return null;
                    }
                    sizeConfig = childConfig.dimension(Math.min(scale, 1));
                } else {
                    sizeConfig = childConfig;
                }

                const { width, height } = sizeConfig;

                if (
                    (contentWidth && contentWidth < width * columns) ||
                    (contentHeight && contentHeight < height * rows)
                ) {
                    return null;
                }

                return {
                    width,
                    height,
                    rows,
                    columns,
                    childrenCapacity,
                    alignmentRatio,
                    sizeConfig,
                };
            }

            function getPreferredAlignment(numberOfChildren, alignmentRatio, { width, height }) {
                let rows = Math.ceil(Math.sqrt(numberOfChildren));
                let columns = rows;

                // If alignmentRatio is false(y) (undefined, null, 0), calculate on available space.
                alignmentRatio =
                    alignmentRatio && alignmentRatio > 0 ? alignmentRatio : width / height;

                if (alignmentRatio !== 1) {
                    alignmentRatio =
                        alignmentRatio < 1
                            ? Math.max(alignmentRatio, 1 / config.maxAlignmentRatio)
                            : Math.min(alignmentRatio, config.maxAlignmentRatio);

                    let smallerSide = rows;
                    let longerSide = columns;
                    let excessCapacity = smallerSide * longerSide - numberOfChildren;
                    const greaterThanOneRatio =
                        alignmentRatio >= 1 ? alignmentRatio : 1 / alignmentRatio;

                    while (excessCapacity) {
                        const side1 = Math.max(smallerSide - 1, 1);
                        const side2Min = Math.ceil(numberOfChildren / side1);
                        const side2Max = Math.floor(greaterThanOneRatio * side1);

                        if (side2Min <= side2Max) {
                            const newExcessCapacity = side1 * side2Min - numberOfChildren;
                            if (newExcessCapacity >= 0 && newExcessCapacity <= excessCapacity) {
                                smallerSide = side1;
                                longerSide = side2Min;
                                excessCapacity = newExcessCapacity;
                                continue;
                            }
                        }

                        break;
                    }

                    rows = alignmentRatio >= 1 ? smallerSide : longerSide;
                    columns = alignmentRatio >= 1 ? longerSide : smallerSide;
                    alignmentRatio = columns / rows;
                }

                return { rows, columns, alignmentRatio };
            }

            /**** Justify layout sizing and fill in free spaces. SPACE FILL!!  ****/
            function justifyLayoutSizing(
                assignments,
                availableSpace,
                horizontalJustify = true,
                verticalJustify = true
            ) {
                const horizontalAccessor = {
                    start: 'x0',
                    end: 'x1',
                    size: 'width',
                    orthoStart: 'y0',
                    orthoEnd: 'y1',
                };
                const verticalAccessor = {
                    start: 'y0',
                    end: 'y1',
                    size: 'height',
                    orthoStart: 'x0',
                    orthoEnd: 'x1',
                };

                const accessors = [];

                if (horizontalJustify) {
                    accessors.push(horizontalAccessor);
                }

                if (verticalJustify) {
                    accessors.push(verticalAccessor);
                }

                for (const accessor of accessors) {
                    const downstreamDependencies = [];
                    const sortedAssignments = _.sortBy(
                        assignments,
                        (assignment) => assignment[accessor.start]
                    );

                    for (let i = 0; i < sortedAssignments.length; i++) {
                        const a1 = sortedAssignments[i];
                        const dependencies = [];
                        for (let j = i + 1; j < sortedAssignments.length; j++) {
                            const a2 = sortedAssignments[j];
                            if (
                                a2[accessor.orthoStart] - a1[accessor.orthoEnd] < 0 &&
                                a1[accessor.orthoStart] - a2[accessor.orthoEnd] < 0
                            ) {
                                dependencies.push(j);
                            }
                        }
                        downstreamDependencies.push(dependencies);
                    }

                    const dependencyChains = getDependencyChains(downstreamDependencies)
                        .map((chain) => {
                            return {
                                chain,
                                sum: chain.reduce(
                                    (sum, item) => sum + sortedAssignments[item][accessor.size],
                                    0
                                ),
                            };
                        })
                        .sort((a, b) => b.sum - a.sum || b.chain.length - a.chain.length);

                    const upstreamDependencies = [];
                    downstreamDependencies.forEach((dependencies, upstreamValue) => {
                        dependencies.forEach((dependency) => {
                            if (!upstreamDependencies[dependency]) {
                                upstreamDependencies[dependency] = [];
                            }
                            upstreamDependencies[dependency].push(upstreamValue);
                        });
                    });

                    findAndDistribute(
                        sortedAssignments,
                        dependencyChains,
                        downstreamDependencies,
                        upstreamDependencies,
                        accessor,
                        availableSpace[accessor.size]
                    );
                }
            }

            function getDependencyChains(dependencies, item = null) {
                let seenItems = [];
                let chains = [];
                const itemsDependencies = dependencies[item] || dependencies;
                for (let i = 0; i < itemsDependencies.length; i++) {
                    const nextDependency = item === null ? i : itemsDependencies[i];
                    if (seenItems.includes(nextDependency)) {
                        continue;
                    }
                    chains = chains.concat(getDependencyChains(dependencies, nextDependency));
                    seenItems = seenItems.concat(chains.flat());
                }

                if (item !== null) {
                    if (chains.length) {
                        chains.forEach((chain) => chain.unshift(item));
                    } else {
                        chains.push([item]);
                    }
                }

                return chains;
            }

            function findAndDistribute(
                assignments,
                dependencyChains,
                downstreamDependencies,
                upstreamDependencies,
                accessor,
                maxSizeValue
            ) {
                const seenItems = new Set();
                let startValue;
                let endValue;
                let startIndex;
                let endIndex;
                let freeItemsValueSum;
                let seen;
                let item;

                dependencyChains.forEach(({ chain }) => {
                    startValue = 0;
                    endValue = -Infinity;
                    startIndex = 0;
                    freeItemsValueSum = 0;
                    for (endIndex = 0; endIndex < chain.length; endIndex++) {
                        item = chain[endIndex];
                        seen = seenItems.has(item);
                        const value = assignments[item];
                        if (!seen) {
                            freeItemsValueSum += value[accessor.size];
                        } else {
                            if (endIndex !== 0) {
                                endValue = Math.max(endValue, value[accessor.start]);
                                distributeDirectionalEmptySpaceOnChain(chain);
                                endValue = -Infinity;
                            }
                            startIndex = endIndex + 1;
                            startValue = value[accessor.end];
                        }
                    }

                    if (!seen && endIndex === chain.length) {
                        endValue = maxSizeValue;
                        distributeDirectionalEmptySpaceOnChain(chain);
                    }

                    chain.forEach((item) => seenItems.add(item));
                });

                function distributeDirectionalEmptySpaceOnChain(chain) {
                    if (
                        startValue === null ||
                        endIndex - startIndex <= 0 ||
                        endValue - startValue <= 0
                    ) {
                        return;
                    }

                    let runningCompletedItemSum = 0;
                    let availableSpace = endValue - startValue;

                    for (; startIndex < endIndex; startIndex++) {
                        const itemIdx = chain[startIndex];
                        const currentItem = assignments[itemIdx];

                        // Check if we overlap a already seen upstream item, If so, mitigate
                        if (upstreamDependencies[itemIdx]) {
                            let maxSeenValue = -Infinity;
                            for (const idx of upstreamDependencies[itemIdx]) {
                                const seenValue = assignments[idx][accessor.end];
                                if (seenItems.has(idx) && seenValue > maxSeenValue) {
                                    maxSeenValue = seenValue;
                                }
                            }

                            if (maxSeenValue > startValue) {
                                freeItemsValueSum -= runningCompletedItemSum;
                                runningCompletedItemSum = 0;
                                if (endValue < maxSeenValue) {
                                    return;
                                }
                                startValue = maxSeenValue;
                                availableSpace = endValue - startValue;
                            }
                        }

                        let newSize =
                            (currentItem[accessor.size] / freeItemsValueSum) * availableSpace;
                        let itemEndValue = startValue + newSize;
                        runningCompletedItemSum += currentItem[accessor.size];

                        // Check if we overlap a already seen downstream item, If so, mitigate
                        if (downstreamDependencies[itemIdx]) {
                            let minSeenValue = Infinity;
                            for (const idx of downstreamDependencies[itemIdx]) {
                                const seenValue = assignments[idx][accessor.start];
                                if (seenItems.has(idx) && seenValue < minSeenValue) {
                                    minSeenValue = seenValue;
                                }
                            }

                            if (minSeenValue < itemEndValue) {
                                freeItemsValueSum -= runningCompletedItemSum;
                                runningCompletedItemSum = 0;
                                itemEndValue = minSeenValue;
                                newSize = itemEndValue - startValue;
                                availableSpace = endValue - itemEndValue;
                            }
                        }

                        currentItem[accessor.start] = startValue;
                        currentItem[accessor.size] = newSize;
                        currentItem[accessor.end] = itemEndValue;
                        startValue = itemEndValue;
                    }
                }
            }

            /**** Free space Calculations ****/
            function removeFreeSpace(spaces, accommodatingBox, { width, height }, threshold = 50) {
                let idx = spaces.indexOf(accommodatingBox);
                spaces.splice(idx, 1);

                const newSpaces = [];

                if (accommodatingBox.height - height > threshold) {
                    newSpaces.push(
                        clusterMapUtil.expandCoords({
                            x0: accommodatingBox.x0,
                            y0: accommodatingBox.y0 + height,
                            width,
                            height: accommodatingBox.height - height,
                        })
                    );
                }

                if (accommodatingBox.width - width > 0) {
                    newSpaces.push(
                        clusterMapUtil.expandCoords({
                            x0: accommodatingBox.x0 + width,
                            y0: accommodatingBox.y0,
                            width: accommodatingBox.width - width,
                            height: accommodatingBox.height,
                        })
                    );
                }

                for (const ndx in newSpaces) {
                    let merged = null;

                    if (idx > 0) {
                        merged = getMerged(spaces[idx - 1], newSpaces[ndx], threshold);
                        if (merged) {
                            spaces[idx - 1] = merged;
                        }
                    }

                    if (
                        !merged &&
                        Math.abs(newSpaces[ndx].y1 - newSpaces[ndx].y0) >= threshold &&
                        Math.abs(newSpaces[ndx].x1 - newSpaces[ndx].x0) >= threshold
                    ) {
                        spaces.splice(idx, 0, newSpaces[ndx]);
                        idx += 1;
                    }
                }

                if (idx > 0 && idx < spaces.length) {
                    const merged = getMerged(spaces[idx - 1], spaces[idx], threshold);
                    if (merged) {
                        spaces[idx - 1] = merged;
                        spaces.splice(idx, 1);
                    }
                }
            }

            function getMerged(item1, item2, threshold) {
                let space1, space2;
                // Vertical merge first, Horizontal second.
                if (
                    (item1.y1 === item2.y0 || item2.y1 === item1.y0) &&
                    Math.abs(item1.x0 - item2.x0) + Math.abs(item1.x1 - item2.x1) <= threshold
                ) {
                    // Vertical merge

                    if (item1.y1 === item2.y0) {
                        space1 = item1;
                        space2 = item2;
                    } else {
                        space1 = item2;
                        space2 = item1;
                    }

                    return clusterMapUtil.expandCoords({
                        x0: Math.max(space1.x0, space2.x0),
                        x1: Math.min(space1.x1, space2.x1),
                        y0: space1.y0,
                        y1: space2.y1,
                    });
                } else if (
                    (item1.x1 === item2.x0 || item2.x1 === item1.x0) &&
                    Math.abs(item1.y0 - item2.y0) + Math.abs(item1.y1 - item2.y1) <= threshold
                ) {
                    // Horizontal merge
                    if (item1.x1 === item2.x0) {
                        space1 = item1;
                        space2 = item2;
                    } else {
                        space1 = item2;
                        space2 = item1;
                    }

                    return clusterMapUtil.expandCoords({
                        x0: space1.x0,
                        x1: space2.x1,
                        y0: Math.max(space1.y0, space2.y0),
                        y1: Math.min(space1.y1, space2.y1),
                    });
                }

                return null; // Unable to merge, threshold too tight for current merge
            }

            function getLargestSpace(spaces) {
                let largestSpace;

                for (const space of spaces) {
                    if (
                        !largestSpace ||
                        Math.min(space.width, space.height) >
                            Math.min(largestSpace.width, largestSpace.height)
                    ) {
                        largestSpace = space;
                    }
                }

                return largestSpace;
            }
        };
    },
];
