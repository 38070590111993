angular.module('signalview.heatmap').constant('AWS_EC2_MATRIX', [
    {
        'Instance Type': 't2.micro',
        vCPU: '1',
        'Memory (GiB)': '1',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'Low to Moderate',
        'Physical Processor': 'Intel Xeon family',
        'Clock Speed (GHz)': 'Up to 3.3',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 't2.small',
        vCPU: '1',
        'Memory (GiB)': '2',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'Low to Moderate',
        'Physical Processor': 'Intel Xeon family',
        'Clock Speed (GHz)': 'Up to 3.3',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 't2.medium',
        vCPU: '2',
        'Memory (GiB)': '4',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'Low to Moderate',
        'Physical Processor': 'Intel Xeon family',
        'Clock Speed (GHz)': 'Up to 3.3',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 't2.large',
        vCPU: '2',
        'Memory (GiB)': '8',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'Low to Moderate',
        'Physical Processor': 'Intel Xeon family',
        'Clock Speed (GHz)': 'Up to 3.0',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'm4.large',
        vCPU: '2',
        'Memory (GiB)': '8',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'm4.xlarge',
        vCPU: '4',
        'Memory (GiB)': '16',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'm4.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '32',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'm4.4xlarge',
        vCPU: '16',
        'Memory (GiB)': '64',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'm4.10xlarge',
        vCPU: '40',
        'Memory (GiB)': '160',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'm3.medium',
        vCPU: '1',
        'Memory (GiB)': '3.75',
        'Storage (GB)': '1 x 4 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2670 v2*',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'm3.large',
        vCPU: '2',
        'Memory (GiB)': '7.5',
        'Storage (GB)': '1 x 32 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2670 v2*',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'm3.xlarge',
        vCPU: '4',
        'Memory (GiB)': '15',
        'Storage (GB)': '2 x 40 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2670 v2*',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'm3.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '30',
        'Storage (GB)': '2 x 80 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2670 v2*',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'c4.large',
        vCPU: '2',
        'Memory (GiB)': '3.75',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2666 v3',
        'Clock Speed (GHz)': '2.9',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c4.xlarge',
        vCPU: '4',
        'Memory (GiB)': '7.5',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2666 v3',
        'Clock Speed (GHz)': '2.9',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c4.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '15',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2666 v3',
        'Clock Speed (GHz)': '2.9',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c4.4xlarge',
        vCPU: '16',
        'Memory (GiB)': '30',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2666 v3',
        'Clock Speed (GHz)': '2.9',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c4.8xlarge',
        vCPU: '36',
        'Memory (GiB)': '60',
        'Storage (GB)': 'EBS Only',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2666 v3',
        'Clock Speed (GHz)': '2.9',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c3.large',
        vCPU: '2',
        'Memory (GiB)': '3.75',
        'Storage (GB)': '2 x 16 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2680 v2',
        'Clock Speed (GHz)': '2.8',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c3.xlarge',
        vCPU: '4',
        'Memory (GiB)': '7.5',
        'Storage (GB)': '2 x 40 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2680 v2',
        'Clock Speed (GHz)': '2.8',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c3.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '15',
        'Storage (GB)': '2 x 80 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2680 v2',
        'Clock Speed (GHz)': '2.8',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c3.4xlarge',
        vCPU: '16',
        'Memory (GiB)': '30',
        'Storage (GB)': '2 x 160 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2680 v2',
        'Clock Speed (GHz)': '2.8',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'c3.8xlarge',
        vCPU: '32',
        'Memory (GiB)': '60',
        'Storage (GB)': '2 x 320 SSD',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2680 v2',
        'Clock Speed (GHz)': '2.8',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'g2.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '15',
        'Storage (GB)': '1 x 60 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon  E5-2670',
        'Clock Speed (GHz)': '2.6',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'g2.8xlarge',
        vCPU: '32',
        'Memory (GiB)': '60',
        'Storage (GB)': '2 x 120 SSD',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2670',
        'Clock Speed (GHz)': '2.6',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': '-',
    },
    {
        'Instance Type': 'r3.large',
        vCPU: '2',
        'Memory (GiB)': '15.25',
        'Storage (GB)': '1 x 32 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'r3.xlarge',
        vCPU: '4',
        'Memory (GiB)': '30.5',
        'Storage (GB)': '1 x 80 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'r3.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '61',
        'Storage (GB)': '1 x 160 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'r3.4xlarge',
        vCPU: '16',
        'Memory (GiB)': '122',
        'Storage (GB)': '1 x 320 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'r3.8xlarge',
        vCPU: '32',
        'Memory (GiB)': '244',
        'Storage (GB)': '2 x 320 SSD',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'i2.xlarge',
        vCPU: '4',
        'Memory (GiB)': '30.5',
        'Storage (GB)': '1 x 800 SSD',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'i2.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '61',
        'Storage (GB)': '2 x 800 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'i2.4xlarge',
        vCPU: '16',
        'Memory (GiB)': '122',
        'Storage (GB)': '4 x 800 SSD',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'i2.8xlarge',
        vCPU: '32',
        'Memory (GiB)': '244',
        'Storage (GB)': '8 x 800 SSD',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2670 v2',
        'Clock Speed (GHz)': '2.5',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': '-',
        'Intel Turbo': 'Yes',
        'EBS OPT': '-',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'd2.xlarge',
        vCPU: '4',
        'Memory (GiB)': '30.5',
        'Storage (GB)': '3 x 2000',
        'Networking Performance': 'Moderate',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'd2.2xlarge',
        vCPU: '8',
        'Memory (GiB)': '61',
        'Storage (GB)': '6 x 2000',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'd2.4xlarge',
        vCPU: '16',
        'Memory (GiB)': '122',
        'Storage (GB)': '12 x 2000',
        'Networking Performance': 'High',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
    {
        'Instance Type': 'd2.8xlarge',
        vCPU: '36',
        'Memory (GiB)': '244',
        'Storage (GB)': '24 x 2000',
        'Networking Performance': '10 Gigabit',
        'Physical Processor': 'Intel Xeon E5-2676 v3',
        'Clock Speed (GHz)': '2.4',
        'Intel AVX†': 'Yes',
        'Intel AVX2†': 'Yes',
        'Intel Turbo': 'Yes',
        'EBS OPT': 'Yes',
        'Enhanced Networking†': 'Yes',
    },
]);
