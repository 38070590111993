import templateUrl from './clusterMap.tpl.html';
import clusterMapTooltip from './clusterMapTooltip.tpl.html';
import primaryHeader from './headers/primaryHeader.tpl.html';
import secondaryHeader from './headers/secondaryHeader.tpl.html';
import breadCrumbs from './headers/breadCrumbs.tpl.html';

export default {
    templateUrl,
    bindings: {
        vizConfig: '<',
        predefinedFilters: '<',
        sourceFilters: '<',
        variables: '<',
        filterSuggestionQueryCallback: '<',
        onSelection: '<',
        time: '<',
    },
    controller: [
        '_',
        '$window',
        '$scope',
        '$rootScope',
        '$timeout',
        '$compile',
        '$element',
        'TetherDrop',
        'clusterMapConfig',
        'clusterMapUtil',
        'clusterMapViz',
        'clusterMapStreamer',
        'kubeDataService',
        'dashboardVariablesService',
        'routeParameterService',
        'urlOverridesService',
        'URL_PARAMETER_CONSTANTS',
        'KUBE_PROPERTY_TO_VARIABLE_ALIAS',
        'infoSidebarUtil',
        'ANALYZER_EVENT',
        'featureEnabled',
        function (
            _,
            $window,
            $scope,
            $rootScope,
            $timeout,
            $compile,
            $element,
            TetherDrop,
            clusterMapConfig,
            clusterMapUtil,
            clusterMapViz,
            clusterMapStreamer,
            kubeDataService,
            dashboardVariablesService,
            routeParameterService,
            urlOverridesService,
            URL_PARAMETER_CONSTANTS,
            KUBE_PROPERTY_TO_VARIABLE_ALIAS,
            infoSidebarUtil,
            ANALYZER_EVENT,
            featureEnabled
        ) {
            const TOOLTIP_DEBOUNCE_TIMEOUT = 100;

            const $ctrl = this;
            const mapElementClass = '.cluster-map-viz';
            const headerClass = 'header-container';
            const dataConfig = clusterMapConfig();
            const dataService = clusterMapStreamer(dataConfig);
            const headerDOMToScopeMap = new Map();
            const paramsForSecondaryHeader = []; // PlaceHolder for Content

            const debouncedShowTooltip = _.debounce(showTooltip, TOOLTIP_DEBOUNCE_TIMEOUT);
            const hasKubernetesInsights = featureEnabled('kubernetesInsights');
            let resourceSelectionPromise;

            const objectTemplateMap = {
                CLUSTER: {
                    header: {
                        primary: primaryHeader,
                        secondary: secondaryHeader,
                        paramsForSecondaryHeader,
                    },
                    footer: null,
                },
                NODE: {
                    header: {
                        primary: primaryHeader,
                        secondary: secondaryHeader,
                        paramsForSecondaryHeader,
                    },
                    footer: null,
                },
            };

            let viz;
            let tooltip;
            let predefinedFilterProps = [];
            let streamingStarted = false;
            const debouncedStartFullDepthStreaming = _.debounce(startFullDepthStreaming, 1000);

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.loadingMap = true;
            $ctrl.getCurrentQuery = getCurrentQuery;
            $ctrl.getHeaderEntitySeverityClass = getHeaderEntitySeverityClass;
            $ctrl.removeTooltip = removeTooltip;
            $ctrl.clearMapFilters = clearMapFilters;
            $ctrl.breadCrumbs = breadCrumbs;
            $ctrl.onBreadCrumbClick = onBreadCrumbClick;
            $ctrl.showTooltip = debouncedShowTooltip;
            $ctrl.breadCrumbObjects = [
                'kubernetes_cluster',
                'kubernetes_node',
                'kubernetes_pod_name',
                'container_spec_name',
            ];

            function $onInit() {
                const vizConfig = Object.assign(
                    { element: $element.find(mapElementClass).get(0) },
                    $ctrl.vizConfig
                );

                viz = clusterMapViz(vizConfig, dataConfig);
                viz.setPrimaryHeaderCallback(addPrimaryHeader);
                viz.setSecondaryHeaderCallback(addSecondaryHeader);
                viz.setParamsHandlerForSecondaryHeader(getParamsForSecondaryHeader);

                bindEvents();
                debouncedStartFullDepthStreaming();
                setMapAutoSelectionOverrides();
                $ctrl.filterSuggestionQueryCallback(getCurrentQuery);
            }

            function $onChanges({ sourceFilters, variables, predefinedFilters, time }) {
                if (sourceFilters || variables) {
                    $ctrl.loadingMap = true;
                    updateVizFilterState();
                }

                if (time) {
                    debouncedStartFullDepthStreaming();
                }

                if (predefinedFilters) {
                    predefinedFilterProps = $ctrl.predefinedFilters.map((v) => v.property);
                }
            }

            function bindEvents() {
                viz.on(viz.EVENTS.ZOOM, onZoom);
                viz.on(viz.EVENTS.MOUSEOVER, debouncedShowTooltip);
                viz.on(viz.EVENTS.MOUSEOUT, removeTooltip);
                viz.on(viz.EVENTS.RENDER, onVizRender);
                viz.on(viz.EVENTS.DESTROY, onElementDestroy);
                viz.on(viz.EVENTS.CLICK, onResourceClick);
                dataService.setCallback(onMapStateUpdate);

                $window.addEventListener('resize', viz.resize);
                const resizeListener = $rootScope.$on('resize', viz.resize);

                const routeWatch = routeParameterService.registerRouteWatch(
                    URL_PARAMETER_CONSTANTS.infoSidebarSources,
                    setMapAutoSelectionOverrides
                );

                $scope.$on('$destroy', function () {
                    resizeListener();
                    routeWatch();
                    $window.removeEventListener('resize', viz.resize);
                    debouncedStartFullDepthStreaming.cancel();
                    dataService.cleanup();
                    removeTooltip();
                });
            }

            function getCurrentQuery() {
                return dataConfig.getSignalFlowForAllResources();
            }

            function startFullDepthStreaming() {
                // Use with a debounce only
                dataService.cleanup();

                $ctrl.loadingMap = true;
                const resourceHierarchy = dataConfig.getResourceHierarchy();
                for (const resource of resourceHierarchy) {
                    const resourceConfig = dataConfig.get(resource);
                    if (resourceConfig && resourceConfig.hasStreamingJob()) {
                        dataService.startStreaming(resource, $ctrl.time);
                    }
                }
                streamingStarted = true;
            }

            function onMapStateUpdate(stateRoot) {
                if (streamingStarted) {
                    $ctrl.loadingMap = false;
                    $ctrl.hasNoData = stateRoot.children.length === 0;
                }

                $scope.$applyAsync(() => viz.update(stateRoot));

                const currentStateFilters = dataService.getStateFilters() || [];
                const unaryIdFilteredBranch = stateRoot.unaryIdFilteredBranch || [];

                // Zoom to the deepest uniquely identifiable resource
                const hierarchicalProps = dataConfig.getHierarchicalGroupByKeys();
                const appliedPositiveFilterKeys = currentStateFilters
                    .filter((f) => !f.NOT)
                    .map((f) => f.property);

                let depth = unaryIdFilteredBranch.length;
                let resourceToZoom = null;

                while (--depth > 0) {
                    const idPropForDepth = hierarchicalProps[depth];
                    if (!idPropForDepth || appliedPositiveFilterKeys.includes(idPropForDepth)) {
                        resourceToZoom = unaryIdFilteredBranch[depth];
                        updateFilterStateForAutoZoom(resourceToZoom);
                        break;
                    }
                }

                if ($ctrl.variables.length) {
                    $ctrl.selectedPath = resourceToZoom;
                } else {
                    $ctrl.selectedPath = null;
                }
                viz.zoomToResource(resourceToZoom || stateRoot);
            }

            function onZoom(originalEvent, data, { phase }) {
                if (phase === viz.PHASES.IN_PROGRESS) {
                    removeTooltip();
                } else if (phase === viz.PHASES.FINISHED) {
                    setMapAutoSelectionOverrides();
                }
            }

            function onBreadCrumbClick(level) {
                let data = $ctrl.selectedPath;
                while (data.groupedUpon !== level) {
                    data = data.parent;
                }
                onResourceClick(null, data, { hasZoom: true });
            }

            // Set filters on click
            function onResourceClick(originalEvent, data, { hasZoom } = { hasZoom: false }) {
                const target = this;

                const isSelected = viz.getSelectedId() === data.id;
                const isZoomable = viz.isValidZoomLevel(data.depth);
                const doubleClick = originalEvent && originalEvent.detail >= 2;
                const zoomInteraction = hasZoom || (isZoomable && (isSelected || doubleClick));

                $timeout.cancel(resourceSelectionPromise);
                resourceSelectionPromise = $timeout(() => {
                    if (zoomInteraction) {
                        $ctrl.loadingMap = true;
                        const overridesToSet = getOverridesToSetForData(data);
                        setUrlOverrides(overridesToSet);
                    }

                    if (originalEvent && target) {
                        setInfoSidebarRef(target, data);
                    }

                    $scope.$apply();
                });
            }

            function getOverridesToSetForData(data) {
                const hierarchicalProps = dataConfig.getHierarchicalGroupByKeys();

                const overridesToSet = [];

                // Remove all hierarchical filters with depth greater than current
                for (let depth = data.depth + 1; depth < hierarchicalProps.length; depth++) {
                    const property = hierarchicalProps[depth];
                    if (property) {
                        overridesToSet.push({ property, propertyValue: null });
                    }
                }

                // Set all hierarchical filters with depth less than or equal to current
                while (data) {
                    const property = hierarchicalProps[data.depth];
                    if (property) {
                        overridesToSet.push({ property, propertyValue: data.data[property] });
                    }
                    data = data.parent;
                }

                return overridesToSet;
            }

            function setUrlOverrides(overrides) {
                setVariableOverride(overrides);
                updateSourceOverrides(overrides);
                viz.resize();
            }

            function setMapAutoSelectionOverrides() {
                viz.setAutoSelectionFilters(urlOverridesService.getInfoSidebarSources() || []);
                const selectedData = viz.getSelectedData();
                if (selectedData && $ctrl.onSelection) {
                    $ctrl.onSelection(selectedData);
                }
            }

            function setInfoSidebarRef(element, data) {
                const resourceConfig = dataConfig.get(dataConfig.getResourceAtDepth(data.depth));

                if (!resourceConfig || !resourceConfig.getGroupByKey()) {
                    return;
                }

                let resourceOverrides = getOverridesToSetForData(data);
                resourceOverrides = resourceOverrides.filter(
                    ({ propertyValue }) => !_.isEmpty(propertyValue)
                );
                const panel = infoSidebarUtil.getPanelForFilters(
                    resourceConfig.getGroupByKey(),
                    resourceOverrides
                );

                if (!panel) {
                    return;
                }

                infoSidebarUtil.setInfoPanelSidebarURLParams(panel, resourceOverrides);
                viz.setSelection(element, data);
            }

            function updateFilterStateForAutoZoom(data) {
                const currentStateFilters = dataService.getStateFilters() || [];
                const appliedFilterKeys = currentStateFilters.map((f) => f.property);
                const overridesForData = getOverridesToSetForData(data);
                const overridesToAdd = overridesForData.filter(
                    ({ property }) => !appliedFilterKeys.includes(property)
                );
                const mergedFilters = currentStateFilters.concat(overridesToAdd);

                dataService.passivelySetStateFilters(mergedFilters);
                setUrlOverrides(mergedFilters);
            }

            function setVariableOverride(overrides) {
                overrides.forEach(({ property, propertyValue }) => {
                    if (!predefinedFilterProps.includes(property)) {
                        return;
                    }

                    const alias = KUBE_PROPERTY_TO_VARIABLE_ALIAS[property] || property;
                    dashboardVariablesService.setVariableOverride(
                        alias,
                        property,
                        propertyValue,
                        false
                    );
                });
            }

            function updateSourceOverrides(overrides) {
                // Assuming it is not a "NOT" filter
                const sourceOverrideMap = {};
                ($ctrl.sourceFilters || []).forEach(
                    (override) => (sourceOverrideMap[override.property] = override)
                );

                overrides.forEach(({ property, propertyValue }) => {
                    if (propertyValue) {
                        if (!sourceOverrideMap[property]) {
                            sourceOverrideMap[property] = { property };
                        }
                        sourceOverrideMap[property].propertyValue = propertyValue;
                    } else {
                        delete sourceOverrideMap[property];
                    }
                });

                const finalOverrides = Object.values(sourceOverrideMap).filter(
                    ({ property }) => !predefinedFilterProps.includes(property)
                );

                urlOverridesService.setSourceFilterOverrideList(finalOverrides);
            }

            function clearMapFilters() {
                $ctrl.loadingMap = true;
                urlOverridesService.clearSourceOverride([]);
                dashboardVariablesService.clearVariablesOverride();
            }

            function onVizRender(originalEvent, data, { phase }) {
                if (phase !== viz.PHASES.FINISHED) {
                    return;
                }

                if (hasKubernetesInsights) {
                    const analyzerContexts = data.map(({ depth, groupedUpon, groupId }) => {
                        return {
                            context: dataConfig.getResourceAtDepth(depth),
                            property: groupedUpon,
                            propertyValue: groupId,
                        };
                    });

                    kubeDataService.setAnalyzerContexts(analyzerContexts);
                }
                $scope.$applyAsync();
            }

            function getParamsForSecondaryHeader(resourceType) {
                return (
                    (objectTemplateMap[resourceType] &&
                        objectTemplateMap[resourceType].header.paramsForSecondaryHeader) ||
                    []
                );
            }

            function addPrimaryHeader(data, resourceType, size) {
                addHeader(this, data, objectTemplateMap[resourceType].header.primary, size);
            }

            function addSecondaryHeader(data, resourceType, size) {
                addHeader(this, data, objectTemplateMap[resourceType].header.secondary, size);
            }

            function addHeader(DOM, datum, headerTemplate, size) {
                const headerElement = angular.element(
                    `<div class="${headerClass}" ng-include="template">`
                );
                angular.element(DOM).append(headerElement);

                const childScope = $scope.$new();
                childScope.clusterMapResource = datum;

                childScope.template = headerTemplate;
                childScope.data = datum.data;
                childScope.datum = datum;
                childScope.size = size - 2;

                $compile(headerElement)(childScope);
                headerDOMToScopeMap.set(DOM, childScope);
            }

            function getHeaderEntitySeverityClass(headerEntity) {
                return clusterMapUtil.getStateColorClass(
                    headerEntity.data,
                    dataConfig
                        .get(dataConfig.getResourceAtDepth(headerEntity.depth))
                        .getColorByConfig(),
                    headerEntity.depth
                );
            }

            function onElementDestroy(originalEvent, elementsRemoved) {
                const headers = Array.from(headerDOMToScopeMap.keys());
                const removedEls = angular.element(elementsRemoved);

                headers
                    .filter((header) => removedEls.has(header).length || removedEls.is(header))
                    .forEach(function (header) {
                        if (headerDOMToScopeMap.has(header)) {
                            headerDOMToScopeMap.get(header).$destroy();
                            headerDOMToScopeMap.delete(header);
                        }
                    });
            }

            function showTooltip(originalEvent, datum) {
                if (!datum || datum.depth === 0 || viz.isTransitioning()) {
                    return;
                }

                if (!tooltip) {
                    const tooltipScope = $scope.$new();
                    tooltipScope.template = clusterMapTooltip;

                    tooltip = {
                        tooltipScope,
                        content: angular.element(
                            '<div class="cluster-map-tooltip"><div ng-include="template"></div></div>'
                        )[0],
                    };

                    $compile(tooltip.content)(tooltipScope);
                }

                removeTooltip();

                const dropOptions = {
                    target: this.node(),
                    position: 'right center',
                    content: tooltip.content,
                    classPrefix: 'cluster-map-',
                    classes: 'resource-tooltip cluster-map-tooltip',
                    tetherOptions: {},
                    constrainToScrollParent: false,
                };

                const { tooltipScope } = tooltip;
                const resourceType = dataConfig.getResourceAtDepth(datum.depth);
                const isSelected = viz.getSelectedId() === datum.id;
                const isZoomable =
                    viz.isValidZoomLevel(datum.depth) && viz.currentOverviewDepth() < datum.depth;
                let interactionMessage;

                if (isZoomable) {
                    interactionMessage = isSelected
                        ? 'Click to zoom'
                        : 'Click to select, double click to zoom';
                } else if (!isSelected) {
                    interactionMessage = 'Click to select';
                }

                tooltipScope.interactionMessage = interactionMessage;
                tooltipScope.data = dataConfig.get(resourceType).getTooltipData(datum);
                tooltip.tether = new TetherDrop(dropOptions);

                // Run Digest cycle on new data and allow DOM to Update. This makes Tether to pick up correct size.
                tooltip.tooltipScope.$applyAsync(() => {
                    tooltip.DOMUpdatePromise = $timeout(
                        () => tooltip.tether && tooltip.tether.open()
                    );
                });
            }

            function removeTooltip() {
                // Flush debounce queue
                debouncedShowTooltip(null, null);

                if (tooltip && tooltip.tether) {
                    $timeout.cancel(tooltip.DOMUpdatePromise);
                    tooltip.tether.remove();
                    tooltip.tether.destroy();
                    delete tooltip.tether;
                }
            }

            $scope.$on(
                ANALYZER_EVENT.CLUSTER_MAP_LOOK_FOR_MATCHING_CHILD,
                function (event, cluster, target, requiredFilters) {
                    const child = dataService.findChildMatchingAnalyzerResult(cluster, target);
                    if (child) {
                        $scope.$emit(
                            ANALYZER_EVENT.CLUSTER_MAP_FOUND_MATCHING_CHILD,
                            child,
                            requiredFilters,
                            target.key
                        );
                    }
                }
            );

            function updateVizFilterState() {
                const filters = copyFilters();
                dataService.updateFilterState(filters);
                $scope.$applyAsync();
            }

            function copyFilters() {
                // copy filters so the original filters are not modified during the state update.
                const variableMappedFilters = ($ctrl.variables || []).map(
                    ({ property, value }) => ({ property, propertyValue: value, NOT: false })
                );
                const sourceFilters = angular.copy($ctrl.sourceFilters || []);
                return sourceFilters.concat(variableMappedFilters);
            }
        },
    ],
};
