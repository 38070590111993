export function getCPUChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'CPU Used by Container',
        defaultPlotType: 'LineChart',
        axes: [
            {
                min: 0,
                label: '% of Node',
            },
            {
                min: 0,
                label: '% of Container limit',
            },
        ],
        legendOptions: {
            fields: [
                { property: 'container_spec_name', enabled: true },
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'kubernetes_node_uid', enabled: false },
                { property: 'container_id', enabled: false },
                { property: 'host', enabled: false },
                { property: 'container_image', enabled: false },
                { property: 'container_name', enabled: false },
                { property: 'kubernetes_pod_uid', enabled: false },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
            ],
        },
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            displayName:
                'container_cpu_utilization - Sum by kubernetes_cluster,kubernetes_pod_name,container_spec_name,container_id,kubernetes_node',
            streamLabel: 'CPU_UTILIZATION',
            programText: `CPU_UTILIZATION = data('container_cpu_utilization', filter=filter('kubernetes_pod_name', '*') ${filterString}, rollup='rate').sum(by=[\'kubernetes_cluster\', \'kubernetes_pod_name\', \'container_spec_name\', \'container_id\', \'kubernetes_node\']).publish(label=\'CPU_UTILIZATION\', enable=False)`,
        },
        {
            displayName:
                'kubernetes.container_cpu_limit - Sum by kubernetes_cluster,kubernetes_pod_name,container_spec_name,container_id,kubernetes_node',
            streamLabel: 'CPU_LIMIT',
            programText: `CPU_LIMIT = data('kubernetes.container_cpu_limit', filter=filter('kubernetes_pod_name', '*') ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'container_spec_name', 'container_id']).publish(label=\'CPU_LIMIT\', enable=False)`,
        },
        {
            displayName: '% of Container Limit',
            streamLabel: 'CONTAINER_LIMIT_1',
            programText:
                "CONTAINER_LIMIT_1 = (CPU_UTILIZATION / CPU_LIMIT).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'container_spec_name', 'container_id']).publish(label='CONTAINER_LIMIT_1')",
            yAxis: 1,
        },
        {
            displayName:
                'container_spec_cpu_quota - Sum by kubernetes_cluster,kubernetes_pod_name,container_spec_name,kubernetes_node',
            streamLabel: 'CPU_QUOTA',
            programText: `CPU_QUOTA = data('container_spec_cpu_quota', filter=filter('kubernetes_pod_name', '*')  ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'container_spec_name']).publish(label=\'CPU_QUOTA\', enable=False)`,
        },
        {
            displayName:
                'container_spec_cpu_period - Sum by kubernetes_cluster,kubernetes_pod_name,container_spec_name,kubernetes_node',
            streamLabel: 'CPU_PERIOD',
            programText: `CPU_PERIOD = data('container_spec_cpu_period', filter=filter('kubernetes_pod_name', '*') ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'container_spec_name']).publish(label=\'CPU_PERIOD\', enable=False)`,
        },
        {
            displayName: '% of Container Limit',
            streamLabel: 'CONTAINER_LIMIT_2',
            programText:
                "CONTAINER_LIMIT_2 = (CPU_UTILIZATION/ (CPU_QUOTA / CPU_PERIOD)).sum(by=['kubernetes_cluster', 'kubernetes_pod_name', 'container_spec_name', 'container_id']).publish(label='CONTAINER_LIMIT_2')",
            yAxis: 1,
        },
        {
            displayName: 'cpu.num_processors - Sum by kubernetes_node',
            streamLabel: 'HOST_NUM_PROCESSORS',
            programText:
                "HOST_NUM_PROCESSORS = data('cpu.num_processors').sum(by=['kubernetes_node']).publish(label='HOST_NUM_PROCESSORS', enable=False)",
        },
        {
            displayName: '% of Node CPU',
            streamLabel: 'NODE_CPU_PERCENT_1',
            programText:
                "NODE_CPU_PERCENT_1 = (CPU_UTILIZATION / HOST_NUM_PROCESSORS).sum(by=['kubernetes_node', 'kubernetes_pod_name', 'container_spec_name']).publish(label='NODE_CPU_PERCENT_1')",
        },
        {
            displayName: '% of Node CPU',
            streamLabel: 'NODE_CPU_PERCENT_2',
            programText: `NODE_CPU_PERCENT_2 = data('container_cpu_percent', filter=filter('kubernetes_pod_name', '*') ${filterString}, rollup='rate').sum(by=['kubernetes_cluster', 'kubernetes_pod_name', 'container_spec_name', 'container_id']).publish(label='NODE_CPU_PERCENT_2')`,
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getMemoryChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Memory Used by Container',
        defaultPlotType: 'LineChart',
        axes: [
            {
                min: 0,
                label: 'bytes used',
            },
            {
                min: 0,
                label: '% of container limit',
            },
        ],
        legendOptions: {
            fields: [
                { property: 'container_spec_name', enabled: true },
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'kubernetes_pod_uid', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
            ],
        },
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'container',
            streamLabel: 'A',
            paletteIndex: 3,
            plotType: 'AreaChart',
            valueUnit: 'Byte',
            programText: `A = data('container_memory_usage_bytes', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_namespace', '*') and filter('deployment', '*', match_missing=True) and filter('sf_tags', '*', match_missing=True) ${filterString}).sum(by=['container_spec_name', 'kubernetes_pod_uid', 'kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_pod_name']).publish(label='A')`,
        },
        {
            displayName: 'limit',
            streamLabel: 'B',
            paletteIndex: 6,
            programText: `B = data('kubernetes.container_memory_limit', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_namespace', '*') and filter('deployment', '*', match_missing=True) and filter('sf_tags', '*', match_missing=True) ${filterString}).above(0, inclusive=True).sum(by=['kubernetes_pod_uid', 'container_spec_name', 'kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_pod_name']).publish(label='B', enable=False)`,
        },
        {
            displayName: '% Memory Of Limit',
            streamLabel: 'C',
            programText: "C = (A/B*100).publish(label='C')",
            yAxis: 1,
        },
        {
            displayName:
                'container_spec_memory_limit_bytes - Exclude x < 0 - Sum by kubernetes_pod_uid,container_spec_name,kubernetes_namespace,kubernetes_pod_name,kubernetes_cluster',
            streamLabel: 'D',
            programText: `D = data('container_spec_memory_limit_bytes', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_namespace', '*') and filter('deployment', '*', match_missing=True) and filter('sf_tags', '*', match_missing=True) ${filterString}).above(0, inclusive=True).sum(by=['kubernetes_pod_uid', 'container_spec_name', 'kubernetes_namespace', 'kubernetes_pod_name', 'kubernetes_cluster']).publish(label='D', enable=False)`,
        },
        {
            displayName: '% Memory Of Limit',
            streamLabel: 'E',
            programText: "E = (A/D*100).publish(label='E')",
            yAxis: 1,
        },
    ];

    return { chartOptions, publishLabelOptions };
}
