import templateUrl from './globalEvent.tpl.html';

angular
    .module('signalview.globalEvents')

    .service('globalEventsService', [
        '$log',
        'sfxModal',
        '$rootScope',
        'userAnalytics',
        'signalboost',
        function ($log, sfxModal, $rootScope, userAnalytics, signalboost) {
            return {
                createGlobalEvent: function (timestamp, properties, dimensions) {
                    if (!properties) {
                        properties = {};
                    }
                    if (!properties.description) {
                        properties.description = '';
                    }

                    const modalInstance = sfxModal.open({
                        templateUrl,
                        controller: 'GlobalEventController',
                        size: 'md',
                        resolve: {
                            event: function () {
                                return {
                                    id: null,
                                    timestamp: timestamp || Date.now(),
                                    metadata: {
                                        sf_eventType: '',
                                    },
                                    properties: properties,
                                    dimensions: dimensions,
                                };
                            },
                        },
                        backdrop: 'static',
                        keyboard: false,
                    });

                    return modalInstance.result.then(
                        function (event) {
                            return event;
                        },
                        function (e) {
                            if (e !== 'backdrop click' && e !== 'escape key press') {
                                $log.error('Error in user create event flow ', e);
                                userAnalytics.event('global-event', 'create-error');
                            } else {
                                $log.debug('Canceled user create event flow ', e);
                                userAnalytics.event('global-event', 'create-cancel');
                            }
                            return null;
                        }
                    );
                },
                deleteGlobalEvent: function (event) {
                    return signalboost.event.delete(event.id).then(
                        function () {
                            $log.debug('Deleted user event ', event);
                            userAnalytics.event('global-event', 'delete');
                            $rootScope.$broadcast('delete global event', {
                                id: event.id,
                                timestamp: event.timestamp,
                            });
                        },
                        function () {
                            $log.error('Error deleting user event ', event);
                            userAnalytics.event('global-event', 'delete-error');
                        }
                    );
                },
            };
        },
    ]);
