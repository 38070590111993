export default [
    'moment',
    'CALENDAR_WINDOW_CONSTANTS',
    function (moment, CALENDAR_WINDOW_CONSTANTS) {
        const RECOGNIZED_CYCLES = ['Quarter', 'Month', 'Week', 'Day', 'Hour'];
        const WEEKDAYS = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];
        const CALENDAR_WINDOW_BLOCK_NAME_MAPPING = {
            quarter: 'quarterly',
            month: 'monthly',
            week: 'weekly',
            day: 'daily',
            hour: 'hourly',
        };
        const RECOGNIZED_HOURS_OF_DAY = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        const DAY_SUFFIX = 'd';
        const MINUTE_SUFFIX = 'm';
        const HOUR_SUFFIX = 'h';
        const MAX_DAY_OF_MONTH = 28;
        const DEFAULT_MINUTE_VAL = 0;
        const MAX_MINUTE_VAL = 0;
        const MIN_MINUTE_VAL = 0;
        const MAX_DAY_VAL = 28;
        const MIN_DAY_VAL = 1;
        const ANTE_MERIDIAN = 'am';
        const POST_MERIDIAN = 'pm';
        const ONE_HOUR_IN_MS = 1 * 60 * 60 * 1000;
        const ONE_DAY_IN_MS = 1 * 24 * ONE_HOUR_IN_MS;
        // As per product,
        // Quarter -> 92d
        // Month -> 31d
        const CYCLE_NAME_TO_RANGE_MAPPING = {
            hour: {
                optimalRange: '-180m',
                optimalRangeInMs: 180 * 60 * 1000, // 180 mins in ms
                cycleLengthInMs: ONE_HOUR_IN_MS,
            },
            day: {
                optimalRange: '-60h',
                optimalRangeInMs: 60 * ONE_HOUR_IN_MS, // 60 hrs in ms
                cycleLengthInMs: ONE_DAY_IN_MS, // 1 day in ms
            },
            week: {
                optimalRange: '-20d',
                optimalRangeInMs: 20 * ONE_DAY_IN_MS, // 20 days in ms
                cycleLengthInMs: 7 * ONE_DAY_IN_MS, // 7 days in ms
            },
            month: {
                optimalRange: '-60d',
                optimalRangeInMs: 60 * ONE_DAY_IN_MS, // 60 days in ms
                cycleLengthInMs: 31 * ONE_DAY_IN_MS, // 31 days in ms
            },
            quarter: {
                optimalRange: '-100d',
                optimalRangeInMs: 100 * ONE_DAY_IN_MS, // 100 days in ms
                cycleLengthInMs: 92 * ONE_DAY_IN_MS, // 92 days in ms
            },
        };

        function getValidCycleNames() {
            return RECOGNIZED_CYCLES;
        }

        function getValidCycleNamesInLowerCase() {
            const lowerCaseCycleNames = [];
            RECOGNIZED_CYCLES.forEach(function (cycleName) {
                lowerCaseCycleNames.push(cycleName.toLowerCase());
            });
            return lowerCaseCycleNames;
        }

        function getMonthNames() {
            return moment.months();
        }

        function getWeekdayNames() {
            return WEEKDAYS;
        }

        function getCalendarWindowBlockMapping() {
            return CALENDAR_WINDOW_BLOCK_NAME_MAPPING;
        }

        function getDefaultMinuteValue() {
            return DEFAULT_MINUTE_VAL;
        }

        function getHoursInCustomFormat() {
            const hoursInCustomFormat = [];
            RECOGNIZED_HOURS_OF_DAY.forEach(function (h) {
                hoursInCustomFormat.push(h + HOUR_SUFFIX);
            });
            return hoursInCustomFormat;
        }

        function getMonthsInCustomFormat() {
            const daysInCustomFormat = [];
            for (let d = 1; d <= MAX_DAY_OF_MONTH; d++) {
                daysInCustomFormat.push(d + DAY_SUFFIX);
            }
            return daysInCustomFormat;
        }

        function getOrdinalDay(dayNum) {
            return moment(dayNum, 'DD').format('Do');
        }

        // Conversion examples:
        // '1st' -> '1d'
        // '20th' -> '20d'
        function convertOrdinalToCustomDayFormat(ordinalMonth) {
            return ordinalMonth.replace(/\D/g, '') + DAY_SUFFIX;
        }

        function splitDay(day) {
            if (!day.match(/^\d+d$/g)) {
                return [];
            }
            return day.split(DAY_SUFFIX);
        }

        function convertCustomDayToOrdinalFormat(customDay) {
            const customDayParts = splitDay(customDay);
            if (customDayParts.length === 2) {
                const day = Number(customDayParts[0]);
                // This takes care of 'NaN' also.
                if (day >= MIN_DAY_VAL && day <= MAX_DAY_VAL) {
                    return getOrdinalDay(day);
                }
            }
            return null;
        }

        function validateCustomDay(customDay) {
            const customDayParts = splitDay(customDay);
            if (customDayParts.length !== 2) {
                return null;
            }
            const day = Number(customDayParts[0]);
            // This takes care of 'NaN' also.
            if (day < MIN_DAY_VAL || day > MAX_DAY_VAL) {
                return null;
            }
            return customDay;
        }

        function convertCustomMinuteFormat(customMinute) {
            const customMinuteParts = customMinute.split(MINUTE_SUFFIX);
            if (customMinuteParts.length !== 2) {
                return null;
            }
            const minute = Number(customMinuteParts[0]);
            // This takes care of 'NaN' also.
            if (minute >= MIN_MINUTE_VAL && minute <= MAX_MINUTE_VAL) {
                return minute;
            }
            return null;
        }

        function getHourAndMeridian(customHour) {
            const converted = Number(customHour.split(HOUR_SUFFIX)[0]);
            let meridian = POST_MERIDIAN;
            let hour = converted;
            if (converted < 12) {
                meridian = ANTE_MERIDIAN;
            }

            if (converted === 0) {
                hour = 12;
            } else if (converted > 12) {
                hour = converted - 12;
            }

            hour = hour + HOUR_SUFFIX;
            return { hour, meridian };
        }

        function isValidCustomHour(customHour) {
            return !!customHour.match('^([0-9]|1[0-9]|2[0-3])' + HOUR_SUFFIX + '$');
        }

        function convertCustomHour(customHour) {
            return customHour.split(HOUR_SUFFIX)[0];
        }

        function isCalendarTransformation(dataManipulation) {
            return dataManipulation.fn && isCalendarWindowType(dataManipulation.fn);
        }

        function doesCalendarTransformationExist(allPlots) {
            if (allPlots.length === 0) {
                return false;
            }
            return allPlots.some(function (plot) {
                if (!plot.dataManipulations || plot.dataManipulations.length === 0) {
                    return false;
                }
                return plot.dataManipulations.some(isCalendarTransformation);
            });
        }

        function isCalendarWindowType(fn) {
            return (
                fn.options &&
                fn.options.windowType ===
                    CALENDAR_WINDOW_CONSTANTS.ANALYTICS_WINDOW_TYPES.CALENDAR_WINDOW
            );
        }

        function isDashboardWindowType(fn) {
            return (
                fn.options?.windowType ===
                CALENDAR_WINDOW_CONSTANTS.ANALYTICS_WINDOW_TYPES.DASHBOARD_WINDOW
            );
        }

        function getCycleToRangeMapping(cycleName) {
            return CYCLE_NAME_TO_RANGE_MAPPING[cycleName];
        }

        function getOptimizedWindowObject(cycleName, currentWindow) {
            const rangeMap = getCycleToRangeMapping(cycleName);
            if (!rangeMap) {
                return null;
            }
            let currentDefaultTime;

            // absolute case
            if (
                typeof currentWindow.absoluteStart === 'number' &&
                typeof currentWindow.absoluteEnd === 'number'
            ) {
                currentDefaultTime = currentWindow.absoluteEnd - currentWindow.absoluteStart;
            } else if (typeof currentWindow.rangeEnd === 'number') {
                currentDefaultTime = currentWindow.rangeEnd - currentWindow.range;
            } else {
                // relative case
                currentDefaultTime = Math.abs(currentWindow.range);
            }
            // if current window is either < cycle length
            if (currentDefaultTime < rangeMap.cycleLengthInMs) {
                return rangeMap;
            }
            return null;
        }

        return {
            getDefaultMinuteValue,
            getValidCycleNames,
            getValidCycleNamesInLowerCase,
            getMonthNames,
            getWeekdayNames,
            getCalendarWindowBlockMapping,
            getHourAndMeridian,
            getHoursInCustomFormat,
            getMonthsInCustomFormat,
            getOrdinalDay,
            getCycleToRangeMapping,
            convertOrdinalToCustomDayFormat,
            convertCustomDayToOrdinalFormat,
            convertCustomMinuteFormat,
            validateCustomDay,
            isValidCustomHour,
            convertCustomHour,
            isCalendarTransformation,
            isCalendarWindowType,
            doesCalendarTransformationExist,
            getOptimizedWindowObject,
            isDashboardWindowType,
        };
    },
];
