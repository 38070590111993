import { APM_FILTER } from './apmFilterService';

export const APMQueryService = [
    'apmFilterService',
    'DETECTOR_TYPES',
    function (apmFilterService, DETECTOR_TYPES) {
        return {
            forVersion: function (apmVersion) {
                const isApmEnabled = [DETECTOR_TYPES.APM, DETECTOR_TYPES.APM_V2].includes(
                    apmVersion
                );
                const isApmV2Detector = apmVersion === DETECTOR_TYPES.APM_V2;
                const apmFilter = apmFilterService.forVersion(apmVersion);

                return {
                    getSourceSelectors,
                    getEnvironmentDetectorsQuery,
                    getEnvironmentAlertsQuery,
                    getServiceEndpointDetectorsQuery,
                    getServiceEndpointAlertsQuery,
                    getDetectorsFilteredByAPM,
                    getBusinessWorkflowAlertsQuery,
                    getBusinessWorkflowDetectorsQuery,
                };

                function isDefinedAndNotWildcard(value) {
                    return typeof value === 'string' && value !== '' && value !== '*';
                }

                function newSourceSelector(key, value) {
                    return [`${apmFilter.getKey(key)}:"${value}"`];
                }

                function getSourceSelectors(
                    env = null,
                    serviceEndpoints = [],
                    businessWorkflow = []
                ) {
                    let sourceSelectors = [];

                    if (env) {
                        sourceSelectors.push(newSourceSelector(APM_FILTER.environment, env));
                    } else {
                        sourceSelectors.push([]);
                    }

                    if (Array.isArray(businessWorkflow)) {
                        const newItems = businessWorkflow
                            .map((item) => item.resource)
                            .filter(isDefinedAndNotWildcard)
                            .map((resource) => newSourceSelector(APM_FILTER.workflow, resource));

                        sourceSelectors = extendSourceSelectors(sourceSelectors, newItems);
                    }

                    if (Array.isArray(serviceEndpoints)) {
                        const newItems = [];
                        serviceEndpoints.forEach((item) => {
                            newItems.push(
                                ...item.endpoints.map((endpoint) => {
                                    const singleSourceSelector = [];

                                    if (isDefinedAndNotWildcard(item.service)) {
                                        singleSourceSelector.push(
                                            ...newSourceSelector(APM_FILTER.service, item.service)
                                        );
                                    }

                                    if (isDefinedAndNotWildcard(endpoint)) {
                                        singleSourceSelector.push(
                                            ...newSourceSelector(APM_FILTER.operation, endpoint)
                                        );
                                    }

                                    return singleSourceSelector;
                                })
                            );
                        });

                        sourceSelectors = extendSourceSelectors(sourceSelectors, newItems);
                    }

                    return sourceSelectors;
                }

                function extendSourceSelectors(sourceSelectors, newItems) {
                    if (!Array.isArray(newItems) || !newItems.length) {
                        return sourceSelectors;
                    }

                    const extended = [];
                    sourceSelectors.forEach((singleSelector) => {
                        newItems.forEach((item) => {
                            extended.push([...singleSelector, ...item]);
                        });
                    });

                    return extended;
                }

                /**
                 * Returns a Lucene query to filter queries for active alerts in the selected APM environment.
                 */
                function getEnvironmentAlertsQuery(environmentQuery = null) {
                    if (!isApmEnabled || environmentQuery === null) {
                        return null;
                    }

                    return (
                        '(' +
                        apmFilter.getKey(APM_FILTER.environment) +
                        ':' +
                        environmentQuery +
                        ')'
                    );
                }

                /**
                 * Returns a Lucene query to filter queries for detectors to the selected APM environment.
                 */
                function getEnvironmentDetectorsQuery(environmentQuery = null) {
                    if (!isApmEnabled || environmentQuery === null) {
                        return null;
                    }

                    return (
                        '(sf_sourceSelectors:"' +
                        apmFilter.getKey(APM_FILTER.environment) +
                        ':\\"' +
                        environmentQuery +
                        '\\"")'
                    );
                }

                /**
                 * Returns a Lucene query to filter queries for active alerts on the selected service endpoints.
                 */
                function getServiceEndpointAlertsQuery(serviceEndpointQuery) {
                    if (!isApmEnabled || !Array.isArray(serviceEndpointQuery)) {
                        return null;
                    }

                    return serviceEndpointQuery
                        .map((p) => {
                            return (
                                '(' +
                                apmFilter.getKey(APM_FILTER.service) +
                                ':' +
                                p.service +
                                ' AND ' +
                                apmFilter.getKey(APM_FILTER.operation) +
                                ':(' +
                                p.operations.join(' OR ') +
                                '))'
                            );
                        })
                        .join(' OR ');
                }

                /**
                 * Returns a Lucene query to filter queries for active alerts on the selected business workflow.
                 */
                function getBusinessWorkflowAlertsQuery(businessWorkflowQuery) {
                    if (!isApmV2Detector || !Array.isArray(businessWorkflowQuery)) {
                        return null;
                    }

                    return businessWorkflowQuery
                        .map((p) => {
                            return (
                                '(' + apmFilter.getKey(APM_FILTER.workflow) + ':' + p.resource + ')'
                            );
                        })
                        .join(' OR ');
                }

                /**
                 * Returns a Lucene query to filter queries for detectors related to the selected service endpoints.
                 */
                function getServiceEndpointDetectorsQuery(serviceEndpointQuery) {
                    if (!isApmEnabled || !Array.isArray(serviceEndpointQuery)) {
                        return null;
                    }

                    return serviceEndpointQuery
                        .map((p) => {
                            return (
                                '(sf_sourceSelectors:"' +
                                apmFilter.getKey(APM_FILTER.service) +
                                ':\\"' +
                                p.service +
                                '\\"" AND (' +
                                p.operations
                                    .map(
                                        (o) =>
                                            'sf_sourceSelectors:"' +
                                            apmFilter.getKey(APM_FILTER.operation) +
                                            ':\\"' +
                                            o +
                                            '\\""'
                                    )
                                    .join(' OR ') +
                                '))'
                            );
                        })
                        .join(' OR ');
                }

                /**
                 * Returns a Lucene query to filter queries for detectors related to the selected business workflows.
                 */
                function getBusinessWorkflowDetectorsQuery(businessWorkflowQuery) {
                    if (!isApmV2Detector || !Array.isArray(businessWorkflowQuery)) {
                        return null;
                    }

                    return businessWorkflowQuery
                        .map((p) => {
                            return (
                                '(sf_sourceSelectors:"' +
                                apmFilter.getKey(APM_FILTER.workflow) +
                                ':\\"' +
                                p.resource +
                                '\\"")'
                            );
                        })
                        .join(' OR ');
                }

                function getDetectorsFilteredByAPM(
                    detectors,
                    serviceEndpointQuery,
                    businessWorkflowQuery,
                    environmentQuery
                ) {
                    if (!isApmEnabled) {
                        return detectors;
                    }

                    const filteredDetectors = detectors.filter((detector) => {
                        return (
                            sourceSelectorsMatchServiceEndpointQuery(
                                detector.sf_sourceSelectors,
                                serviceEndpointQuery
                            ) &&
                            sourceSelectorsMatchBusinessWorkflowQuery(
                                detector.sf_sourceSelectors,
                                businessWorkflowQuery
                            ) &&
                            sourceSelectorsMatchEnvironmentQuery(
                                detector.sf_sourceSelectors,
                                environmentQuery
                            )
                        );
                    });
                    return filteredDetectors;
                }

                function sourceSelectorsMatchEnvironmentQuery(sourceSelectors, environmentQuery) {
                    if (!environmentQuery) {
                        return true;
                    }
                    if (!sourceSelectors) {
                        return false;
                    }
                    const environmentMetadata = `${APM_FILTER.environment}:\"${environmentQuery}\"`;
                    return sourceSelectors.includes(environmentMetadata);
                }

                function sourceSelectorsMatchServiceEndpointQuery(
                    sourceSelectors,
                    serviceEndpointQuery
                ) {
                    if (!Array.isArray(serviceEndpointQuery) || !serviceEndpointQuery.length) {
                        return true;
                    }
                    if (!sourceSelectors) {
                        return false;
                    }

                    for (const p of serviceEndpointQuery) {
                        const serviceFilter = `${APM_FILTER.service}:\"${p.service}\"`;
                        const operationsFilters = p.operations.map(
                            (o) => `${APM_FILTER.operation}:\"${o}\"`
                        );
                        const operationFilterSatisfied = operationsFilters.some((opFilter) =>
                            sourceSelectors.includes(opFilter)
                        );
                        if (sourceSelectors.includes(serviceFilter) && operationFilterSatisfied) {
                            return true;
                        }
                    }
                    return false;
                }

                function sourceSelectorsMatchBusinessWorkflowQuery(
                    sourceSelectors,
                    businessWorkflowQuery
                ) {
                    if (
                        !isApmV2Detector ||
                        !Array.isArray(businessWorkflowQuery) ||
                        !businessWorkflowQuery.length
                    ) {
                        return true;
                    }
                    if (!sourceSelectors) {
                        return false;
                    }

                    for (const p of businessWorkflowQuery) {
                        const resourceFilter = `${APM_FILTER.workflow}:\"${p.resource}\"`;
                        if (sourceSelectors.includes(resourceFilter)) {
                            return true;
                        }
                    }
                    return false;
                }
            },
        };
    },
];
