angular
    .module('signalview.timePicker')
    .constant('timepickerConstants', {
        deltas: {
            m: 60.0,
            h: 60.0 * 60.0,
            d: 60.0 * 60.0 * 24.0,
            w: 60.0 * 60.0 * 24.0 * 7,
        },
        revKeys: ['w', 'd', 'h', 'm'],
    })
    .factory('timepickerUtils', [
        'urlOverridesService',
        'TimeParser',
        'timepickerConstants',
        'chartUtils',
        function (urlOverridesService, TimeParser, timepickerConstants, chartUtils) {
            const deltas = timepickerConstants.deltas;
            const revKeys = timepickerConstants.revKeys;

            function getRangeFromRelative(t) {
                let utc = 0;
                angular.forEach(t.num, function (n, i) {
                    utc += n * deltas[t.type[i]];
                });
                return -1 * utc * 1000;
            }

            function getChartConfigParametersFromURLTimeObject(time) {
                if (!time) {
                    return null;
                }

                /* Sometimes the time object doesn't contain the relative flag, so we have to manually check
                    if the time is relative or not. This was causing the RAP-1784 issue.
                */
                if (!angular.isDefined(time.relative)) {
                    time.relative = _.isString(time.start) && time.start.startsWith('-');
                }

                const configTime = {
                    absoluteStart: null,
                    absoluteEnd: null,
                    range: null,
                    rangeEnd: null,
                };

                if (time.relative) {
                    const start = new TimeParser(time.start);
                    const end = new TimeParser(time.end, true);

                    configTime.range = getRangeFromRelative(start);
                    configTime.rangeEnd = end ? getRangeFromRelative(end) : null;
                } else {
                    configTime.absoluteStart = time.start;
                    configTime.absoluteEnd = time.end;
                }

                return configTime;
            }

            function getChartConfigURLTimeParameters() {
                const time = urlOverridesService.getGlobalTimePicker();
                return getChartConfigParametersFromURLTimeObject(time);
            }

            function msToDisplayValue(ms) {
                if (!ms) {
                    return '0m';
                }
                let value = '';
                ms = Math.abs(ms);
                angular.forEach(revKeys, function (k) {
                    const multiple = deltas[k] * 1000;
                    const divisor = Math.floor(ms / multiple);
                    ms = ms % multiple;
                    if (divisor > 0) {
                        value = value + divisor + k;
                    }
                });
                if (!value.length) {
                    value = '<1m'; // "seconds" unit not supported
                }
                return value;
            }

            function msToRelative(ms) {
                if (ms === 0) {
                    return 'Now';
                }
                const rel = msToDisplayValue(ms);
                if (rel.length > 0) {
                    // should we even check ?
                    return '-' + rel;
                }
                return rel;
            }

            function chartConfigToTimePickerObj(chartConfig) {
                const retObj = {};
                if (chartConfig.range) {
                    retObj.relative = true;
                    retObj.start = msToRelative(chartConfig.range);
                    retObj.end = msToRelative(chartConfig.rangeEnd);
                } else {
                    retObj.relative = false;
                    retObj.start = chartConfig.absoluteStart;
                    retObj.end = chartConfig.absoluteEnd;
                }
                return retObj;
            }

            function getURLParamStringForTimePicker(timePicker) {
                if (!timePicker || !isValidTimepicker) {
                    return '';
                }

                const { start, end, relative } = timePicker;

                if (relative) {
                    const endTimeStr = end ? '&endTime=' + end : '';

                    return `startTime=${start}${endTimeStr}`;
                } else {
                    return `startTimeUTC=${start}&endTimeUTC=${end}`;
                }
            }

            function isValidTimepicker(timepicker) {
                timepicker.hasOwnProperty('start');
                timepicker.hasOwnProperty('end');
            }

            function getCurrentTimeRangeForChartConfig(chartConfig) {
                let start = 0;
                let end = 0;
                const globalTime = urlOverridesService.getGlobalTimeAbsolute();
                if (globalTime) {
                    start = globalTime.start;
                    end = globalTime.end;
                    return {
                        start,
                        end,
                    };
                } else {
                    const chartDefaultTime = chartUtils.getChartTimeConfig(chartConfig);
                    if (chartDefaultTime.absoluteEnd) {
                        start = chartDefaultTime.absoluteStart;
                        end = chartDefaultTime.absoluteEnd;
                    } else {
                        start = Date.now() - Math.abs(chartDefaultTime.range);
                        end = Date.now();
                    }
                }

                return {
                    start,
                    end,
                };
            }

            return {
                getRangeFromRelative: getRangeFromRelative,
                getURLParamStringForTimePicker,
                getChartConfigURLTimeParameters: getChartConfigURLTimeParameters,
                getChartConfigParametersFromURLTimeObject:
                    getChartConfigParametersFromURLTimeObject,
                msToDisplayValue: msToDisplayValue,
                chartConfigToTimePickerObj: chartConfigToTimePickerObj,
                msToRelative: msToRelative,
                getCurrentTimeRangeForChartConfig,
            };
        },
    ]);
