import React, { ReactNode } from 'react';
import { FC, useEffect } from 'react';
import gdprTemplateUrl from '../legacy/app/legal/gdpr.tpl.html';
import { AngularInjector } from '../common/AngularUtils';
import type { RouteComponentProps, ExtractRouteParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useRouteContext } from './routing/RouteContext';
import qs from 'query-string';

export const Home: FC = () => {
    const currentUser = AngularInjector.useInjectedClass('currentUser');
    const sfxModal = AngularInjector.useInjectedClass('sfxModal');
    const history = useHistory();
    const { search, params } = useRouteContext();

    useEffect(() => {
        let gdprModal;
        if (params.terms) {
            gdprModal = currentUser.hasAgreedToTerms().then((agreed: boolean) =>
                sfxModal.open({
                    templateUrl: gdprTemplateUrl,
                    // declare-used-dependency-to-linter::GdprController
                    controller: 'GdprController',
                    size: 'md',
                    resolve: {
                        previouslyAgreed: agreed,
                    },
                    backdrop: 'static',
                    keyboard: false,
                })
            );
        } else {
            gdprModal = Promise.resolve();
        }

        Promise.all([currentUser.orgId(), gdprModal]).then(([orgId]) => {
            history.replace({
                pathname: `/home/${orgId}`,
                search: qs.stringify({
                    ...search,
                    terms: undefined,
                }),
            });
        });
    }, [currentUser, history, search, sfxModal, params.terms]);

    return <></>;
};

export const goToOrgHome = (
    routeProps: RouteComponentProps<ExtractRouteParams<string, string>>
): ReactNode => {
    const orgId = routeProps.match.params.orgId;
    const currentUser = AngularInjector.instantiate('currentUser');
    const featureEnabled = AngularInjector.instantiate('featureEnabled');
    const isOlly = (window as any).signalviewConfig.isOllyRootApp;

    currentUser
        .setPreferredOrg(orgId)
        .then(() => {
            return currentUser.orgPreferences();
        })
        .then((orgPrefs: { sf_homePagePath: string }) => {
            /*
             * TODO: in O11y, this should take a more comprehensive approach that works
             * across standalone products.
             */

            if (orgPrefs.sf_homePagePath) {
                return isOlly ? '/home' : orgPrefs.sf_homePagePath;
            }

            // Send APM-only customers directly into the APM experience.
            if (featureEnabled('apm2') && !featureEnabled('infra')) {
                return '/apm';
            }

            return isOlly ? '/home' : `/dashboards/${orgId}`;
        })
        .catch(() => {
            return isOlly ? '/home' : `/dashboards/${orgId}`;
        })
        .then((homeUrl: string) => {
            routeProps.history.replace(homeUrl);
        });

    return undefined;
};
