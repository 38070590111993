import React from 'react';
import { BannerProvider } from '@splunk/olly-common/GettingStartedBanner';
import { gettingStartedBannerContentAlerts } from './alertsGSBContent';
import { AngularInjector } from '../../../../common/AngularUtils';
import { useLegacyThemeServiceKey } from '../../../../common/theme/ThemeProvider';
import { ThemeProvider } from '@splunk/olly-common';

export default function AlertsBanner({ currentUser }) {
    const themeKey = useLegacyThemeServiceKey();
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    return (
        <ThemeProvider colorScheme={themeKey}>
            <BannerProvider
                currentUser={currentUser}
                themeKey={themeKey}
                gettingStartedBannerContent={gettingStartedBannerContentAlerts}
                userAnalytics={userAnalytics}
                dismissedPillarApiName="sf_uiOnboardingBannerDismissedAlerts"
            />
        </ThemeProvider>
    );
}
