import React, { FC, useEffect, useState } from 'react';
import { AngularInjector } from '../../common/AngularUtils';

export const SmartGatewayDownload: FC<{ version?: string }> = ({ version }) => {
    // declare-used-dependency-to-linter::smartGatewayDownloadService
    const smartGatewayDownloadService = AngularInjector.useInjectedClass(
        'smartGatewayDownloadService'
    );

    const [displayMessage, setDisplayMessage] = useState('');

    useEffect(() => {
        if (!version) {
            setDisplayMessage('Failed to download.');
        } else {
            setDisplayMessage('The file download will begin shortly, please wait...');
        }

        smartGatewayDownloadService.downloadSmartGateway(
            version,
            () => setDisplayMessage('Download complete.'),
            () => setDisplayMessage('Failed to download.')
        );
    }, [version, smartGatewayDownloadService]);

    return (
        <>
            <br />
            <div>&nbsp;&nbsp;&nbsp;{displayMessage}</div>
        </>
    );
};
