import templateUrl from './ingestToken.tpl.html';
import manageAlertModalTemplateUrl from './manageAlertModal.tpl.html';
import currentUsageTooltipTemplate from './currentUsageTooltipTemplate.tpl.html';
import expiryAlertTooltipTemplate from './expiryAlertTooltip.tpl.html';

angular
    .module('signalview.organization')

    .component('ingestToken', {
        templateUrl,
        bindings: {
            token: '<',
            currentUsage: '<',
            creator: '<',
            lastRotatedBy: '<',
            orgId: '<',
            editable: '<',
            loadChart: '<',
            renameToken: '&',
            rotateToken: '&',
            onLimitChange: '&',
            hasTokenLimits: '<',
            subscriptionType: '<',
            account: '<',
            hasReadNamedTokenCapability: '<',
            hasUpdateNamedTokenCapability: '<',
        },
        controller: [
            'sfxModal',
            'namedTokenService',
            'subscriptionTypeUtil',
            'featureEnabled',
            'timeZoneService',
            'currentUser',
            'AUTH_SCOPES',
            function (
                sfxModal,
                namedTokenService,
                subscriptionTypeUtil,
                featureEnabled,
                timeZoneService,
                currentUser,
                AUTH_SCOPES
            ) {
                const ctrl = this;
                const TOKEN_EXPIRY_SHOW_ALERT_THRESHOLD = 60;
                /* bindable variables */
                ctrl.expanded = false;
                ctrl.currentUsageTooltipTemplate = currentUsageTooltipTemplate;
                ctrl.expiryAlertTooltipTemplate = expiryAlertTooltipTemplate;
                ctrl.tokenExpiresInDays = 0;
                ctrl.shouldShowTokenExpiryAlert = false;
                ctrl.isTokenExpired = false;
                ctrl.AUTH_SCOPES = AUTH_SCOPES;

                /* bindable functions */
                ctrl.$onInit = $onInit;
                ctrl.editAlert = editAlert;
                ctrl.isAboveThreshold = isAboveThreshold;
                ctrl.toggleExpandToken = toggleExpandToken;
                ctrl.toggleDisabled = toggleDisabled;
                ctrl.getThreshold = getThreshold;
                ctrl.isAdmin = currentUser.isAdmin();
                ctrl.rotateOrgTokenEnabled = featureEnabled('rotateOrgToken');
                ctrl.newRbacExperienceEnabled = featureEnabled('newRbacExperience');
                ctrl.getRoleTitle = getRoleTitle;

                /* function definitions */

                function $onInit() {
                    ctrl.mtsBasedOrg = subscriptionTypeUtil.isCustomMts(ctrl.subscriptionType);
                    ctrl.hostBasedOrg = subscriptionTypeUtil.isHostBased(ctrl.subscriptionType);

                    ctrl.nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(ctrl.subscriptionType);
                    ctrl.categories = subscriptionTypeUtil.getCategoriesBySubscription(
                        ctrl.subscriptionType
                    );

                    ctrl.showManageLimitsOption = ctrl.hasTokenLimits;
                    if (ctrl.token.expiry) {
                        ctrl.tokenExpiresInDays = timeZoneService.getDifferenceInDaysBetweenMoments(
                            timeZoneService.moment(),
                            ctrl.token.expiry
                        );
                        ctrl.shouldShowTokenExpiryAlert =
                            ctrl.tokenExpiresInDays <= TOKEN_EXPIRY_SHOW_ALERT_THRESHOLD;

                        ctrl.isTokenExpired = ctrl.tokenExpiresInDays <= 0;
                    }
                }

                function getRoleTitle(token) {
                    return token.roles?.map((role) => role.title).join(', ') ?? '';
                }

                function editAlert() {
                    ctrl.processing = true;
                    manageAlertModal();
                }

                function isAboveThreshold() {
                    const threshold = getThreshold();
                    return (
                        ctrl.token.exceedingLimits || (threshold && threshold < ctrl.currentUsage)
                    );
                }

                function getThreshold() {
                    if (ctrl.token.limits) {
                        if (ctrl.nonLegacyOrg) {
                            return ctrl.token.limits.categoryQuota;
                        } else {
                            return ctrl.token.limits.dpmQuota;
                        }
                    }
                }

                function toggleExpandToken() {
                    ctrl.expanded = !ctrl.expanded;
                    ctrl.accessTokenVisible = false;
                }

                function toggleDisabled() {
                    if (ctrl.token.disabled) {
                        namedTokenService.enable(ctrl.token.name);
                    } else {
                        namedTokenService.disable(ctrl.token.name);
                    }

                    ctrl.token.disabled = !ctrl.token.disabled;
                }

                /* non-bindable */

                function manageAlertModal() {
                    sfxModal
                        .open({
                            templateUrl: manageAlertModalTemplateUrl,
                            controller: 'ManageAlertModalController',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return {
                                        token: ctrl.token,
                                    };
                                },
                                subscriptionType: function () {
                                    return ctrl.subscriptionType;
                                },
                            },
                            backdrop: 'static',
                            keyboard: false,
                        })
                        .result.then(function (token) {
                            ctrl.onLimitChange({ token: token });
                            ctrl.token = token;
                        })
                        .finally(function () {
                            ctrl.processing = false;
                        });
                }
            },
        ],
    });
