import templateUrl from './catalogsidebar.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';

angular.module('signalview.catalog').directive('catalogSidebar', [
    'sourceFilterService',
    'urlOverridesService',
    '$window',
    '$log',
    '$timeout',
    '$location',
    'CHART_DISPLAY_EVENTS',
    'orgNotificationService',
    'sidebarService',
    'displayName',
    'config',
    'catalogDataService',
    'currentUser',
    '$rootScope',
    'featureEnabled',
    function (
        sourceFilterService,
        urlOverridesService,
        $window,
        $log,
        $timeout,
        $location,
        CHART_DISPLAY_EVENTS,
        orgNotificationService,
        sidebarService,
        displayName,
        config,
        catalogDataService,
        currentUser,
        $rootScope,
        featureEnabled
    ) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl,
            scope: {
                previewObject: '=?',
                filters: '=?',
                tentativeQueryParam: '=?',
                removeSource: '=?',
                toggleSfActive: '=?',
                lastActiveTimeRange: '=?',
                data: '=?',
                bucketSize: '=?',
                selectionOverride: '=?',
                displayName: '=?',
                topicOverride: '=?',
                emptyMessage: '@?',
                showAdvancedFilters: '@?',
                ignoreUrl: '@?',
                allowAltClick: '@?',
                hideFilter: '=?',
                disableSearch: '<',
                removeSidebar: '=?',
            },
            link: function ($scope) {
                const orgId = ngRoute?.params.orgID;

                $scope.orgId = orgId;
                const queryModifierFields = [
                    'partialQuery',
                    'currentQuery',
                    'categoryFilter',
                    'allCategoryFilter',
                    'dimensionFilter',
                    'allDimensionFilter',
                    'creationTimeRange',
                    'modifiedTimeRange',
                    'lastActiveTimeRange',
                    'activeTime',
                    'filters.length',
                    'orgId',
                    'categorySelection',
                    'tentativeQueryParam.query',
                ];
                //var categoryParameterIndex = queryModifierFields.indexOf('categorySelection');
                const currentQueryParameterIndex = queryModifierFields.indexOf(
                    'tentativeQueryParam.query'
                );
                const filterEntryElement = angular.element('.catalog-filter-input');
                const highPriorityFields = catalogDataService.getHighPriorityFields();

                // SimpleFx: No UI objects to be allowed on catalog
                const ALLOWED_TYPES = [
                    'Property',
                    'Tag',
                    'MetricTimeSeries',
                    'Dimension',
                    'Metric',
                ];

                $scope.emptyMessage = $scope.emptyMessage || 'Sorry, nothing matched your search.';
                $scope.data = $scope.data || catalogDataService;
                $scope.tentativeQueryParam = { query: '' };
                $scope.displayName = $scope.displayName || displayName;
                $scope.bucketSize = $scope.bucketSize || 5;
                $scope.sidebarSvc = sidebarService;
                $scope.partialQuery = '';
                $scope.selectedObjects = {};
                $scope.selectedKeys = {};
                $scope.selectedBucket = null;
                $scope.creationTimeRange = null;
                $scope.modifiedTimeRange = null;
                $scope.lastActiveTimeRange = null;
                $scope.activeTime = '';
                $scope.isLoading = 0;
                $scope.updateCount = 0;
                $scope.replaceCount = 0;
                $scope.pageNumber = 0;
                $scope.scrollReset = 0;
                $scope.categorySelection = null;
                $scope.showProperties = false;

                $scope.timeRanges = [
                    {
                        displayName: '1 Hour Ago',
                        value: 60 * 60 * 1000,
                    },
                    {
                        displayName: '1 Day Ago',
                        value: 24 * 60 * 60 * 1000,
                    },
                    {
                        displayName: '1 Week Ago',
                        value: 7 * 24 * 60 * 60 * 1000,
                    },
                    {
                        displayName: '1 Month Ago',
                        value: 30 * 24 * 60 * 60 * 1000,
                    },
                    {
                        displayName: 'Anytime',
                        value: null,
                    },
                ];

                $scope.toggleSfActive = function () {
                    let targetTimeRange;
                    if (
                        $scope.lastActiveTimeRange === null ||
                        $scope.lastActiveTimeRange.value === null
                    ) {
                        targetTimeRange = $scope.activeRanges[0];
                    } else {
                        targetTimeRange = $scope.activeRanges[1];
                    }
                    $scope.setActiveOn(targetTimeRange);
                    $scope.refocusSearchBar();
                };

                $scope.activeRanges = [
                    {
                        displayName: 'only inactive',
                        value: 1,
                    },
                    {
                        displayName: 'only active',
                        value: null,
                    },
                ];

                $scope.resetPseudoSelection = function () {
                    $scope.pseudoSelection = {
                        bucketIndex: -1,
                        hitIndex: 0,
                        header: false,
                        footer: false,
                    };
                };

                $scope.refocusSearchBar = function () {
                    filterEntryElement.focus();
                };

                $scope.applyUrlQuery = function (isInitialLoad) {
                    const sourceOverride = urlOverridesService.getSourceOverride();
                    if (sourceOverride) {
                        $scope.filters = sourceFilterService.getSourceFilters(sourceOverride);
                    } else {
                        $scope.filters = [];
                    }

                    const createdOn = urlOverridesService.getCreatedOn();
                    $scope.timeRanges.forEach(function (tr) {
                        if (tr.value === createdOn) {
                            $scope.creationTimeRange = tr;
                        }
                    });

                    const activeOn = urlOverridesService.getActiveOn();
                    $scope.activeRanges.forEach(function (tr) {
                        if (tr.value === activeOn) {
                            $scope.lastActiveTimeRange = tr;
                        }
                    });

                    const focusTopic = $scope.topicOverride || urlOverridesService.getTopicFilter();
                    $scope.setSelection(focusTopic);

                    const selectedObject =
                        urlOverridesService.getCatalogSelection() || $scope.selectionOverride;

                    if (selectedObject !== null) {
                        //any time a selection comes in via a url param, it is ALWAYS a single select object
                        //because we dont attempt to capture multiselect in url params.  wipe out existing.

                        $scope.selectedObjects = {};
                        $scope.selectedKeys = {};

                        $scope.applySelectionPreview(selectedObject);

                        if (isInitialLoad) {
                            $scope.urlParamSkipOnce = true;
                        }
                    }

                    const focusTopicHost = focusTopic && focusTopic.key === 'host';
                    const selectedKeyHost = selectedObject && selectedObject.key === 'host';
                    const selectedValueHost =
                        selectedObject &&
                        selectedObject.key === 'sf_key' &&
                        selectedObject.value === 'host';
                    if (focusTopicHost || selectedKeyHost || selectedValueHost) {
                        $timeout(orgNotificationService.checkNumHosts, 1000);
                    }
                };

                $scope.setCreatedOn = function (tr) {
                    urlOverridesService.setCreatedOn(tr.value);
                    $scope.refocusSearchBar();
                };

                $scope.setActiveOn = function (tr) {
                    urlOverridesService.setActiveOn(tr.value);
                };

                $scope.updatePath = function () {
                    urlOverridesService.setSourceOverride(
                        $scope.filters.map(function (f) {
                            return (
                                f.property +
                                ':' +
                                sourceFilterService.flattenPropertyValue(f.propertyValue)
                            );
                        })
                    );
                };

                $scope.$on('React:$routeUpdate', function () {
                    if ($scope.ignoreUrl) {
                        return;
                    }
                    $scope.applyUrlQuery();
                });

                $scope.removeSource = function (idx) {
                    $scope.filters.splice(idx, 1);
                    $scope.resetPseudoSelection();
                };

                $scope.getActivityState = function () {
                    return $scope.translateTimeQuery($scope.lastActiveTimeRange) ? 0 : 1;
                };

                $scope.getCreationRange = function () {
                    return $scope.translateTimeQuery($scope.creationTimeRange);
                };

                $scope.getQueryPrefix = function () {
                    const sourceFilter = sourceFilterService.translateSourceFilterObjects(
                        $scope.filters
                    );
                    const creationRangeQuery = $scope.translateTimeQuery($scope.creationTimeRange);
                    const lastActiveRangeQuery = $scope.translateTimeQuery(
                        $scope.lastActiveTimeRange
                    );

                    const q = ['sf_organizationID:' + $scope.orgId, 'NOT _exists_:sf_uiHelper'];
                    if (sourceFilter) {
                        q.push(sourceFilter);
                    }
                    if (creationRangeQuery) {
                        q.push('sf_createdOnMs:' + creationRangeQuery);
                    }

                    if (!lastActiveRangeQuery) {
                        q.push(
                            '(((sf_type:MetricTimeSeries AND sf_isActive:true) || ((NOT sf_type:MetricTimeSeries) AND (NOT _exists_:sf_isActive))) AND NOT sf_tags:inactive)'
                        );
                    } else {
                        q.push('(sf_isActive:false || sf_tags:inactive)');
                    }
                    return q.join(' AND ');
                };

                $scope.getCurrentQuery = function () {
                    return $scope.getQueryPrefix();
                };

                $scope.translateTimeQuery = function (v) {
                    const millis = v.value;
                    if (millis !== null) {
                        return '[' + (Date.now() - Math.abs(millis)).toString() + ' TO *]';
                    } else {
                        return null;
                    }
                };

                $scope.getNextDataSet = function () {
                    if ($scope.categorySelection) {
                        $scope.pageNumber++;
                        $scope.updateBucketsAppend();
                    }
                };

                $scope.addFilters = function (filter) {
                    const dupKeyIdx = sourceFilterService.findPositivePropertyFilter(
                        filter,
                        $scope.filters
                    );
                    if (dupKeyIdx !== -1) {
                        $scope.filters[dupKeyIdx] = filter;
                        $scope.updatePath();
                        $scope.updateBucketsReplace();
                    } else if (
                        !$scope.filters.some(function (existingFilter) {
                            return (
                                sourceFilterService.translateSourceFilterObjects([
                                    existingFilter,
                                ]) === sourceFilterService.translateSourceFilterObjects([filter])
                            );
                        })
                    ) {
                        $scope.filters.push(filter);
                    }
                    $scope.setSelectionPath({});
                    $scope.tentativeQueryParam.query = '';
                    $scope.deselectAll();
                };

                $scope.$watch('filters.length', function () {
                    if ($scope.ignoreUrl) {
                        return;
                    }
                    $scope.updatePath();
                });

                $scope.deselectAll = function () {
                    //todo
                    $scope.categorySelection = null;
                    //$scope.$broadcast('resetSelections');
                };

                $scope.updateBucketsAppend = function () {
                    $scope.updateBuckets(true);
                };

                $scope.updateBucketsReplace = function (newset, oldset) {
                    $timeout.cancel($scope.updateBucketTimeout);

                    // for the specific case where text is being typed, wait until no keystrokes are registered for 200ms
                    // all other groupwatch fields instantly re-run the query.
                    if (newset && newset[currentQueryParameterIndex]) {
                        $scope.showProperties =
                            newset[currentQueryParameterIndex].indexOf(':') > -1;
                    }
                    if (
                        newset &&
                        oldset &&
                        newset[currentQueryParameterIndex] &&
                        newset[currentQueryParameterIndex] !== oldset[currentQueryParameterIndex]
                    ) {
                        const throttle =
                            $scope.tentativeQueryParam.query &&
                            $scope.tentativeQueryParam.query.length > 1
                                ? 200
                                : 1000;
                        $scope.updateBucketTimeout = $timeout(
                            $scope.updateBucketsReplace,
                            throttle
                        );
                        return;
                    }
                    $scope.resetPseudoSelection();

                    $scope.scrollReset++;
                    $scope.updateBuckets(false);
                };

                $scope.setSelectionPath = function (bucket) {
                    const proposedSelection = {};

                    let emptyBucket = false;
                    if (bucket.key) {
                        proposedSelection.key = bucket.key;
                    } else if (bucket.type) {
                        proposedSelection.type = bucket.type;
                    } else {
                        emptyBucket = true;
                        $scope.tentativeQueryParam.query = '';
                    }
                    if (
                        angular.equals(proposedSelection, $scope.categorySelection) ||
                        emptyBucket
                    ) {
                        urlOverridesService.clearTopicFilter(null, null);
                    } else {
                        if (proposedSelection.key) {
                            urlOverridesService.setTopicFilter(bucket.key, 'key');
                        } else if (proposedSelection.type) {
                            urlOverridesService.setTopicFilter(bucket.type, 'type');
                        } else {
                            $log.error('Encountered an unknown topic filter.');
                        }
                    }
                };

                $scope.clearTopicFilter = function () {
                    $location.search('topicFilterKey', null);
                    $location.search('topicFilterType', null);
                };

                $scope.setSelection = function (bucket) {
                    $scope.categorySelection = bucket;
                    $scope.refocusSearchBar();
                };

                $scope.goBack = function () {
                    //this may be weird if they came here from a link with no history.
                    //would take them to their about page or whatever their homepage is
                    // OTOH, its damn useful in every other case...
                    $window.history.back();
                };

                function scoreBucket(bucket) {
                    if (bucket.key && highPriorityFields[bucket.key]) {
                        return highPriorityFields[bucket.key];
                    } else if (bucket.dimension) {
                        return 2;
                    } else if (bucket.type === 'Property') {
                        return 1;
                    } else {
                        return 0;
                    }
                }

                $scope.reorganizeBuckets = function (res) {
                    if (res && res.length > 0) {
                        res.sort(function (a, b) {
                            return scoreBucket(b) - scoreBucket(a);
                        });
                    }
                };

                $scope.updateBuckets = function (append) {
                    if (!$scope.orgId || $scope.disableSearch) {
                        return;
                    }
                    if (!append) {
                        $scope.isLoading++;
                        $scope.replaceCount++;
                        $scope.pageNumber = 0;
                    }
                    const targetReplaceCount = $scope.replaceCount;
                    const opts = $scope.tentativeQueryParam.query
                        ? $scope.tentativeQueryParam.query.split(':')
                        : [];
                    let prop = '';
                    let partial = '';
                    if (opts.length >= 2) {
                        prop = opts[0];
                        partial = opts.slice(1).join(':');
                    } else if (opts.length === 1) {
                        partial = opts[0];
                    }
                    const limit = $scope.categorySelection ? 100 : $scope.bucketSize;

                    const filters = $scope.filters.map(function (filter) {
                        return filter.property + ':' + filter.propertyValue;
                    });

                    const params = {
                        filters: filters,
                        active: $scope.getActivityState(),
                        createdOn: $scope.getCreationRange(),
                        property: prop,
                        partialInput: partial,
                        keys:
                            $scope.categorySelection && $scope.categorySelection.key
                                ? $scope.categorySelection.key
                                : null,
                        types:
                            $scope.categorySelection && $scope.categorySelection.type
                                ? $scope.categorySelection.type
                                : null,
                        offset: $scope.pageNumber * limit,
                        limit: limit,
                        organizationId: $scope.orgId,
                    };

                    currentUser.isSuperUser().then(function (isSuperUser) {
                        if (!(isSuperUser && config('superpower.unreleasedFeatures'))) {
                            params.filters.push('!_exists_:sf_discoverySelectors');
                        }

                        $scope.data.getList(params).then(
                            function (res) {
                                res = res.filter(
                                    (obj) => !obj.type || ALLOWED_TYPES.includes(obj.type)
                                );
                                if (!append) {
                                    $scope.isLoading--;
                                }
                                if ($scope.replaceCount !== targetReplaceCount) {
                                    $log.info('Threw away stale response data');
                                    return;
                                }
                                res.forEach(function (bucket) {
                                    if (!bucket.sf_id) {
                                        bucket._sf_synthetic_object = true;
                                    }
                                    if (bucket.type === 'Property' && !bucket.dimension) {
                                        bucket.hits = [];
                                    }
                                });

                                res = res.filter(function (bucket) {
                                    return bucket.value !== 'immusage' || featureEnabled('infra');
                                });
                                if (!append) {
                                    if ($scope.data.sortResult(params)) {
                                        $scope.reorganizeBuckets(res);
                                    }
                                    $scope.bucketData = res;
                                } else {
                                    res.forEach(function (resultBucket) {
                                        let found = false;
                                        $scope.bucketData.forEach(function (localBucket) {
                                            if (
                                                (localBucket.sf_id &&
                                                    localBucket.sf_id === resultBucket.sf_id) ||
                                                (localBucket.sf_bucket &&
                                                    localBucket.sf_bucket ===
                                                        resultBucket.sf_bucket) ||
                                                (localBucket.key &&
                                                    localBucket.key === resultBucket.key) ||
                                                (localBucket.type &&
                                                    localBucket.type === resultBucket.type) ||
                                                (localBucket.sf_section &&
                                                    localBucket.sf_section ===
                                                        resultBucket.sf_section)
                                            ) {
                                                localBucket.hits = (localBucket.hits || []).concat(
                                                    resultBucket.hits || []
                                                );
                                                found = true;
                                            }
                                        });
                                        if (!found) {
                                            $scope.bucketData.push(resultBucket);
                                        }
                                    });
                                }
                            },
                            function () {
                                if (!append) {
                                    $scope.isLoading--;
                                }
                                $scope.bucketData = [];
                            }
                        );
                    });
                };

                function clearObject(obj) {
                    Object.keys(obj).forEach(function (k) {
                        delete obj[k];
                    });
                }
                $scope.clearSelection = function () {
                    $scope.updateCount++;
                    urlOverridesService.clearCatalogSelection();
                    clearObject($scope.selectedObjects);
                    clearObject($scope.selectedKeys);
                    $scope.updatePreviewObject();
                };

                $scope.objectSelectionCount = function () {
                    //we are explicitly only allowing this to be done on non dimension objects.
                    return Object.keys($scope.selectedObjects).length;
                };

                $scope.updatePreviewObject = function () {
                    const newPreviewObject = [];

                    angular.forEach($scope.selectedObjects, function (val, key) {
                        newPreviewObject.push({
                            type: 'object',
                            id: key,
                            object: val,
                        });
                    });

                    angular.forEach($scope.selectedKeys, function (val) {
                        if (val.key === null) return;

                        newPreviewObject.push({
                            type: 'property',
                            key: val.key,
                            value: val.value,
                        });
                    });

                    if (
                        !newPreviewObject.length &&
                        $scope.selectedBucket &&
                        !$scope.selectedBucket.sf_section
                    ) {
                        const bucket = $scope.selectedBucket;
                        newPreviewObject.push({
                            type: 'topic',
                            isProperty: !!$scope.selectedBucket.key || bucket.isProperty,
                            isDimension: !!$scope.selectedBucket.dimension || bucket.isDimension,
                            isType: bucket.isType,
                            topic: $scope.selectedBucket,
                        });
                    }

                    if (!angular.equals($scope.previewObject, newPreviewObject)) {
                        $scope.previewObject = newPreviewObject;
                    }
                };

                $scope.selectionToggle = function (doc, singleselect, bucketIndex, hitIndex) {
                    // when the selection is changed ,we need to update some maps so the old catalog preview widget
                    // can do its thing.  eventually once we're satisfied with the behavior ill have to roll it over
                    // to a more compact form.
                    if (doc._sf_synthetic_object) {
                        $scope.clearSelection();
                        $scope.selectedBucket = doc;
                        urlOverridesService.setCatalogSelection(doc);
                        $scope.updatePreviewObject();
                    } else {
                        $scope.selectedBucket = null;

                        if (singleselect) {
                            urlOverridesService.setCatalogSelection(doc);
                        } else {
                            urlOverridesService.clearCatalogSelection();
                            $scope.applySelectionPreview(doc);
                        }
                    }

                    if (angular.isDefined(bucketIndex) && angular.isDefined(hitIndex)) {
                        if (hitIndex === -1) {
                            $scope.pseudoSelection.header = true;
                        } else {
                            $scope.pseudoSelection.header = false;
                        }
                        $scope.pseudoSelection.footer = false;
                        $scope.pseudoSelection.bucketIndex = bucketIndex;
                        $scope.pseudoSelection.hitIndex = hitIndex;
                    }

                    $scope.refocusSearchBar();
                };

                $scope.applySelectionPreview = function (doc) {
                    if (doc === null || doc === undefined) {
                        return;
                    }

                    $scope.selectedBucket = undefined;

                    if (doc.value === '*') {
                        $scope.selectedBucket = {
                            key: doc.key,
                            value: '*',
                            isProperty: true,
                        };
                    } else if (doc.key === 'sf_key' || doc.key === 'sf_type') {
                        $scope.selectedBucket = {
                            key: doc.value,
                            value: '*',
                            isDimension: doc.key === 'sf_key',
                            isType: doc.key === 'sf_type',
                        };
                    } else if (doc.sf_id) {
                        if ($scope.selectedObjects[doc.sf_id]) {
                            delete $scope.selectedObjects[doc.sf_id];
                        } else {
                            $scope.selectedObjects[doc.sf_id] = doc;
                        }
                    } else if (doc.key) {
                        const dimensionpair = doc.key + ':' + doc.value;

                        if ($scope.selectedKeys[dimensionpair]) {
                            delete $scope.selectedKeys[dimensionpair];
                        } else {
                            $scope.selectedKeys[dimensionpair] = doc;
                        }
                    } else {
                        $log.warn('Unidentified value selected.');
                    }

                    $scope.updatePreviewObject();
                };

                $scope.$watch('filters.length', function (newLength, oldLength) {
                    if ($scope.ignoreUrl) {
                        return;
                    }
                    newLength = newLength || 0;
                    oldLength = oldLength || 0;

                    // Only clear current input entry when something is added to the filter
                    // but not when something is removed
                    if (newLength > oldLength) {
                        $scope.tentativeQueryParam.query = '';
                    }
                });

                $scope.$watch('sidebarSvc.showSidebar', function (newVal, oldVal) {
                    if (!newVal && !oldVal) {
                        return;
                    }
                    $timeout.cancel($scope.resizeThrottle);
                    $scope.resizeThrottle = $timeout(function () {
                        $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                        if (newVal) {
                            if (orgId) {
                                $scope.orgId = orgId;
                                $scope.updateBucketsReplace();
                            } else {
                                currentUser
                                    .orgId()
                                    .then(function (orgId) {
                                        $scope.orgId = orgId;
                                    })
                                    .then($scope.updateBucketsReplace);
                            }
                            $scope.$emit('show catalog sidebar');
                        }
                    }, 500);
                });

                const bucketReplaceWatch = function (newsel, oldsel) {
                    if (!angular.equals(newsel, oldsel)) {
                        $scope.updateBucketsReplace();
                    }
                };
                $scope.$watch('categorySelection', function (newsel, oldsel) {
                    if (!angular.equals(newsel, oldsel)) {
                        $scope.pageNumber = 0;
                    }
                });
                $scope.$watchGroup(queryModifierFields, bucketReplaceWatch);

                $scope.$on('catalog sidebar reload', () => $scope.updateBucketsReplace());

                $scope.applyUrlQuery(true);
            },
        };
    },
]);
