angular
    .module('sfx.ui')
    .constant('JIRA_AUTH_METHOD', {
        USER_AND_PASSWORD: 'UsernameAndPassword',
        EMAIL_AND_TOKEN: 'EmailAndToken',
    })
    .service('jiraService', [
        '$http',
        'API_URL',
        'JIRA_AUTH_METHOD',
        function ($http, API_URL, AUTH_METHOD) {
            const JIRA_BASE_PATH = '/v2/integration/_/jira/';
            const CREATE_TEST_TICKET_PATH = 'create-and-comment-test-issue';
            const JIRA_GET_PROJECTS_PATH = JIRA_BASE_PATH + 'get-projects/';

            function getRequestBody(baseUrl, auth, user, password) {
                return {
                    authMethod: auth,
                    basicAuthUser: user,
                    basicAuthPass: password,
                    baseUrl: baseUrl,
                };
            }

            function unpackCredentialParams(credential) {
                return {
                    baseUrl: credential.baseUrl,
                    authMethod: credential.authMethod,
                    user: credential[
                        credential.authMethod === AUTH_METHOD.EMAIL_AND_TOKEN
                            ? 'userEmail'
                            : 'username'
                    ],
                    password:
                        credential[
                            credential.authMethod === AUTH_METHOD.EMAIL_AND_TOKEN
                                ? 'apiToken'
                                : 'password'
                        ],
                    projectKey: credential.projectKey,
                    issueType: credential.issueType,
                    assigneeName: credential.assignee ? credential.assignee.name : undefined,
                };
            }

            function jiraPostProjects(credential) {
                const { baseUrl, authMethod, user, password } = unpackCredentialParams(credential);
                return $http.post(
                    API_URL + JIRA_GET_PROJECTS_PATH,
                    getRequestBody(baseUrl, authMethod, user, password)
                );
            }

            function jiraGetProjects(credentialId) {
                return $http.get(
                    API_URL + JIRA_BASE_PATH + encodeURIComponent(credentialId) + '/projects'
                );
            }

            function jiraPostIssueTypes(credential) {
                const { baseUrl, authMethod, user, password, projectKey } =
                    unpackCredentialParams(credential);

                return $http.post(
                    API_URL +
                        JIRA_GET_PROJECTS_PATH +
                        encodeURIComponent(projectKey) +
                        '/get-issue-types',
                    getRequestBody(baseUrl, authMethod, user, password)
                );
            }

            function jiraGetIssueTypes(credentialId, projectKey) {
                return $http.get(
                    API_URL +
                        JIRA_BASE_PATH +
                        encodeURIComponent(credentialId) +
                        '/projects/' +
                        encodeURIComponent(projectKey) +
                        '/issue-types'
                );
            }

            function jiraPostAssignees(credential, query) {
                const { baseUrl, authMethod, user, password, projectKey } =
                    unpackCredentialParams(credential);
                return $http.post(
                    API_URL +
                        JIRA_GET_PROJECTS_PATH +
                        encodeURIComponent(projectKey) +
                        '/assignable-users?query=' +
                        encodeURIComponent(query),
                    getRequestBody(baseUrl, authMethod, user, password)
                );
            }

            function jiraGetAssignees(credentialId, projectKey, query) {
                return $http.get(
                    API_URL +
                        JIRA_BASE_PATH +
                        encodeURIComponent(credentialId) +
                        '/projects/' +
                        encodeURIComponent(projectKey) +
                        '/assignable-users?query=' +
                        encodeURIComponent(query)
                );
            }

            function getTestIssueQueryParams(projectKey, issueType, assigneeName) {
                return (
                    '?projectKey=' +
                    encodeURIComponent(projectKey) +
                    '&issueType=' +
                    encodeURIComponent(issueType) +
                    (assigneeName ? '&assignee=' + encodeURIComponent(assigneeName) : '')
                );
            }

            function jiraPostCreateAndCommentTestIssue(credential) {
                const { baseUrl, authMethod, user, password, projectKey, issueType, assigneeName } =
                    unpackCredentialParams(credential);

                return $http.post(
                    API_URL +
                        JIRA_BASE_PATH +
                        CREATE_TEST_TICKET_PATH +
                        getTestIssueQueryParams(projectKey, issueType, assigneeName),
                    getRequestBody(baseUrl, authMethod, user, password)
                );
            }

            function jiraGetCreateAndCommentTestIssue(
                credentialId,
                projectKey,
                issueType,
                assigneeName
            ) {
                return $http.get(
                    API_URL +
                        JIRA_BASE_PATH +
                        encodeURIComponent(credentialId) +
                        '/' +
                        CREATE_TEST_TICKET_PATH +
                        getTestIssueQueryParams(projectKey, issueType, assigneeName)
                );
            }

            function sortByName(options) {
                options.sort((a, b) => a.name.localeCompare(b.name));
                return options;
            }

            function projectsMapper(projects) {
                return sortByName(
                    projects.map((option) => ({
                        id: option.key,
                        name: `${option.name} (${option.key})`,
                    }))
                );
            }

            function issueTypesMapper(issueTypes) {
                return sortByName(
                    issueTypes.map((option) => ({ id: option.name, name: option.name }))
                );
            }

            function getIssueUrl(baseUrl, issueKey) {
                return _.trimEnd(baseUrl, '/') + '/browse/' + issueKey;
            }

            return {
                projects: {
                    get: function (credentialId) {
                        return jiraGetProjects(credentialId)
                            .then((response) => response.data)
                            .then(projectsMapper);
                    },
                    post: function (credential) {
                        return jiraPostProjects(credential)
                            .then((response) => response.data)
                            .then(projectsMapper);
                    },
                },
                issueTypes: {
                    get: function (credentialId, projectKey) {
                        return jiraGetIssueTypes(credentialId, projectKey)
                            .then((response) => response.data)
                            .then(issueTypesMapper);
                    },
                    post: function (credential) {
                        return jiraPostIssueTypes(credential)
                            .then((response) => response.data)
                            .then(issueTypesMapper);
                    },
                },
                assignees: {
                    get: function (credentialId, projectKey, query) {
                        return jiraGetAssignees(credentialId, projectKey, query).then(
                            (response) => response.data
                        );
                    },
                    post: function (credential, query) {
                        return jiraPostAssignees(credential, query).then(
                            (response) => response.data
                        );
                    },
                },
                createAndCommentTestIssue: {
                    get: function (credentialId, projectKey, issueType, assigneeName) {
                        return jiraGetCreateAndCommentTestIssue(
                            credentialId,
                            projectKey,
                            issueType,
                            assigneeName
                        ).then((response) => response.data);
                    },
                    post: function (credential) {
                        return jiraPostCreateAndCommentTestIssue(credential).then(
                            (response) => response.data
                        );
                    },
                },
                getIssueUrl,
            };
        },
    ]);
