const RESOLVED_ALERTS_STORE = 'resolved-alerts';

class ResolvedAlertsStore {
    public pushId(alertId: string): void {
        const newResolvedIds = [...this.getIds(), alertId];
        sessionStorage.setItem(RESOLVED_ALERTS_STORE, JSON.stringify(newResolvedIds));
    }

    public getIds(): Array<string> {
        return JSON.parse(sessionStorage.getItem(RESOLVED_ALERTS_STORE) || '[]');
    }
}

const resolvedAlertsStore = new ResolvedAlertsStore();
export default resolvedAlertsStore;
