import templateUrl from './ingestTokenOptions.tpl.html';

export const ingestTokenOptions = {
    templateUrl,
    bindings: {
        editable: '<',
        token: '<',
        showManageLimitsOption: '<',
        editAlert: '<',
        toggleDisabled: '<',
        renameToken: '<',
        rotateToken: '<',
        nonLegacy: '<',
        hasUpdateNamedTokenCapability: '<',
        isTokenExpired: '<',
    },
};
