import globalEventItemTemplateTemplateUrl from './globalEventItemTemplate.tpl.html';

angular
    .module('signalview.globalEvents')

    .controller('GlobalEventController', [
        '$scope',
        '$uibModalInstance',
        '$log',
        '$q',
        '$timeout',
        '$rootScope',
        'userAnalytics',
        'signalboost',
        'timeZoneService',
        'TimeParser',
        'event',
        'currentUser',
        'organizationService',
        function (
            $scope,
            $uibModalInstance,
            $log,
            $q,
            $timeout,
            $rootScope,
            userAnalytics,
            signalboost,
            timeZoneService,
            TimeParser,
            event,
            currentUser,
            organizationService
        ) {
            $scope.globalEventItemTemplateTemplateUrl = globalEventItemTemplateTemplateUrl;
            $scope.event = angular.copy(event);
            $scope.event.eventType = event.metadata.sf_eventType;

            const properties = $scope.event.properties;
            if (!properties.description) {
                properties.description = '';
            }

            if ($scope.event.id) {
                if (!$scope.event.dimensions) {
                    $scope.event.dimensions = {};
                }

                const dimensions = $scope.event.dimensions;

                if ($scope.event.metadata.sf_key) {
                    $scope.event.metadata.sf_key.forEach(function (prop) {
                        if (prop !== 'sf_eventType' && prop !== 'sf_source' && !dimensions[prop]) {
                            dimensions[prop] = $scope.event.metadata[prop];
                        } else if (prop === 'sf_source') {
                            $scope.event.source = $scope.event.metadata.sf_source;
                        }
                    });
                }
            }

            $scope.saving = false;
            $scope.searching = false;

            $scope.getGlobalETS = function (q) {
                const eventQuery =
                    '(NOT sf_eventType:_SF_PLOT_KEY* AND NOT sf_eventCategory:ALERT AND sf_type:EventTimeSeries)';
                $scope.searching = true;
                return signalboost.autosuggest
                    .getValueSuggestions({
                        query: eventQuery,
                        property: 'sf_eventType',
                        partialInput: q || '*',
                        limit: 20,
                        types: ['EventTimeSeries'],
                    })
                    .then(function (items) {
                        if (!items.length) {
                            return [
                                {
                                    type: 'eventTimeSeries',
                                    value: q,
                                    unmatched: true,
                                },
                            ];
                        } else {
                            return items.map(function (d) {
                                return {
                                    type: 'eventTimeSeries',
                                    value: d,
                                };
                            });
                        }
                    })
                    .finally(function () {
                        $scope.searching = false;
                    });
            };

            $scope.setGlobalETS = function (ets) {
                $log.info('selected', ets);
            };

            $scope.implicitGetETS = function (inFocus) {
                if (!inFocus) return;
                const origEventType = $scope.event.eventType || ' ';
                $scope.event.eventType = origEventType + '  ';
                // this is required to match typeahead-wait-ms
                $timeout(function () {
                    $scope.$broadcast('setViewValue', 'globalEventType', origEventType);
                }, 200);
            };

            $scope.onKeyStroke = function () {
                if (!$scope.event.eventType) {
                    $timeout(function () {
                        $scope.$broadcast('setViewValue', 'globalEventType', ' ');
                    }, 200);
                }
            };

            $scope.save = function () {
                return $q
                    .all({
                        org: organizationService.get(),
                        userEmail: currentUser.email(),
                    })
                    .then((results) => {
                        const org = results.org;
                        const userEmail = results.userEmail;

                        const event = $scope.event;
                        const properties = event.properties;

                        properties.sf_organization = org.organizationName;
                        properties.sf_organizationID = org.id;

                        if (event.id) {
                            properties.sf_updatedBy = userEmail;
                        } else {
                            properties.sf_createdBy = userEmail;
                        }

                        $scope.errorSaving = false;
                        $scope.saving = true;
                        const promise = $scope.event.id
                            ? signalboost.event.update(
                                  $scope.event.id,
                                  $scope.event.eventType,
                                  $scope.event.timestamp,
                                  $scope.event.properties,
                                  $scope.event.dimensions,
                                  $scope.event.source
                              )
                            : signalboost.event.create(
                                  $scope.event.eventType,
                                  $scope.event.timestamp,
                                  $scope.event.properties,
                                  $scope.event.dimensions
                              );
                        promise
                            .then(
                                function (result) {
                                    if (result && result.id) {
                                        $scope.event.id = result.id;
                                    }
                                    if (event.id) {
                                        $log.debug('Edited user event ', $scope.event);
                                        userAnalytics.event('global-event', 'edit');
                                        $rootScope.$broadcast('edit global event', $scope.event);
                                    } else {
                                        $log.debug('Created user event ', $scope.event);
                                        userAnalytics.event('global-event', 'create');
                                        $rootScope.$broadcast('new global event', $scope.event);
                                    }
                                    $uibModalInstance.close($scope.event);
                                },
                                function (e) {
                                    $log.error('Error saving event.', e);
                                    $scope.errorSaving = true;
                                    if (event.id) {
                                        userAnalytics.event('global-event', 'edit-error');
                                    } else {
                                        userAnalytics.event('global-event', 'create-error');
                                    }
                                }
                            )
                            .finally(function () {
                                $scope.saving = false;
                            });
                    });
            };

            function setTimestampStr() {
                $scope.timestampStr =
                    timeZoneService
                        .moment($scope.event.timestamp)
                        .format('MM/DD/YYYY hh:mm:ss a') || '';
            }

            $scope.$watch('event.timestamp', function (nval, oval) {
                if (nval !== oval) {
                    setTimestampStr();
                }
            });

            $scope.$watch('event.eventType', function (nval) {
                // can't use ng-required, using validity instead
                // using ng-required does not trigger typeahead for empty strings
                $scope.globalEventForm.$setValidity(
                    'globalEventType',
                    nval && nval.replace(/^\s/g, '') ? true : false
                );
            });

            $scope.$watch('timestampStr', function (nval, oval) {
                if (nval !== oval) {
                    const newTime = new TimeParser($scope.timestampStr);
                    if (newTime && newTime.utc > 0) {
                        $scope.event.timestamp = newTime.utc;
                        $scope.globalEventForm.$setValidity('globalEventTimestamp', true);
                    } else {
                        $scope.globalEventForm.$setValidity('globalEventTimestamp', false);
                    }
                }
            });

            setTimestampStr();
        },
    ]);
