angular
    .module('sfx.ui')

    .directive('numberSelect', [
        function () {
            return {
                require: 'ngModel',
                link: function (_1, _2, _3, ngModelCtrl) {
                    ngModelCtrl.$parsers.push(function (value) {
                        return parseInt(value, 10); // Convert option value to number
                    });

                    ngModelCtrl.$formatters.push(function (value) {
                        if (value) {
                            return value.toString(); // Convert scope value to string
                        }
                    });
                },
            };
        },
    ]);
