import integrationsPageTemplateUrl from './integrationsPage.tpl.html';

export const IntegrationsPage = {
    templateUrl: integrationsPageTemplateUrl,
    controller: [
        '$scope',
        '$log',
        'signalboost',
        'signalviewMetrics',
        'config',
        'organizationService',
        function ($scope, $log, signalboost, signalviewMetrics, config, organizationService) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;
            $scope.showUnreleasedFeatures = config('superpower.unreleasedFeatures');
            $scope.initialized = false;

            $scope.editable = false;
            $scope.isOrgAvailable = false;

            function initialize() {
                organizationService.get().then((org) => {
                    $scope.org = org || {};
                    initOrg();
                    signalviewMetrics.endRouteUi('integrations');
                    $scope.isOrgAvailable = true;
                });
            }

            function getOrgEditable() {
                $scope.editable = false;
                return signalboost.uauth
                    .canCreate($scope.org.id, 'OrgUser')
                    .then((hasPermission) => {
                        $scope.editable = hasPermission;
                    })
                    .catch(function () {
                        $log.log('Failed looking up user auth org permissions.');
                        return false;
                    });
            }

            function initOrg() {
                getOrgEditable().then(() => {
                    $scope.initialized = true;
                });
            }
        },
    ],
};
