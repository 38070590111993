const MILLISECONDS_IN_A_SECOND = 1000;
const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * 60;
const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * 60;
const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * 24;
const MILLISECONDS_IN_A_WEEK = MILLISECONDS_IN_A_DAY * 7;

const timeUnitToMillisecondsMap = {};
// This generates a one to one map of units to their values in milliseconds
// ex. timeUnitToMilliseconds.week = MILLISECONDS_IN_A_WEEK, etc...
[
    [1, ['ms', 'millisecond', 'milliseconds']],
    [MILLISECONDS_IN_A_SECOND, ['s', 'sec', 'secs', 'second', 'seconds']],
    [MILLISECONDS_IN_A_MINUTE, ['m', 'min', 'mins', 'minute', 'minutes']],
    [MILLISECONDS_IN_AN_HOUR, ['h', 'hr', 'hrs', 'hour', 'hours']],
    [MILLISECONDS_IN_A_DAY, ['d', 'day', 'days']],
    [MILLISECONDS_IN_A_WEEK, ['w', 'week', 'weeks']],
].forEach(function (config) {
    const timeUnitInMilliseconds = config[0];
    const timeUnits = config[1];

    timeUnits.forEach(function (timeUnit) {
        timeUnitToMillisecondsMap[timeUnit] = timeUnitInMilliseconds;
    });
});

export function timeUnitToMilliseconds(unit) {
    return timeUnitToMillisecondsMap[unit];
}
