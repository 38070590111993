import AccessControlPermissionAction from './AccessControlPermissionAction';

const AccessControlPermissionActionsSerializer = {
    serialize: (actions) => {
        if (actions.includes(AccessControlPermissionAction.READ)) {
            if (actions.includes(AccessControlPermissionAction.WRITE)) {
                return 'Read/Write';
            } else {
                return 'Read';
            }
        }
        return 'Unknown';
    },
    deserialize: (actions) => {
        switch (actions) {
            case 'Read/Write':
                return [AccessControlPermissionAction.READ, AccessControlPermissionAction.WRITE];
            case 'Read':
                return [AccessControlPermissionAction.READ];
            default:
                return [];
        }
    },
};

export default AccessControlPermissionActionsSerializer;
