import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import AccessControl from '../../../common/ui/accessControl/AccessControl';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import accessControlHelper from '../../../common/ui/accessControl/accessControlHelper';
import ExitGuardService from '../../dashboardGroup/aclDashboardGroup/ExitGuardService';
import AclMirrorDashboardGroupSelector from './AclMirrorDashboardGroupSelector';
import aclValidator from '../../../common/ui/accessControl/aclValidator';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';
import {
    StyledPanel,
    StyledButton,
    StyledBody,
    StyledFooter,
    StyledForm,
} from '../../../common/ui/ModalStyles';
import useAclOrgPreferencesContext from '../../../common/ui/accessControl/useAclOrgPreferencesContext';
import useAclUserContext from '../../../common/ui/accessControl/useAclUserContext';
import useAclPermissions from '../../../common/ui/accessControl/useAclPermissions';
import useDashboardMirrorState from '../../../common/ui/accessControl/useDashboardMirrorState';
import useExitGuard from '../../../common/ui/accessControl/useExitGuard';
import { ThemeProvider } from '../../../common/theme/ThemeProvider';
import MissingRbacCapabilityMessage from '../../../legacy/app/dashboard/MissingRbacCapabilityMessage';

const SAVE_BUTTON_LABEL = 'Save';
const CANCEL_BUTTON_LABEL = 'Cancel';

export default function EditAclDashboard({
    currentDashboardGroupId,
    dashboardId,
    userData,
    themeKey,
    readOnly,
    save,
    onDismiss,
    onClose,
    exitGuardService,
    missingUpdateDashboardCapability,
}) {
    const userContext = useAclUserContext(userData);
    const orgPrefContext = useAclOrgPreferencesContext(userContext.orgId);
    const [mirrorPermissions] = useState({ acl: null, parent: currentDashboardGroupId });

    const {
        initialPermissions,
        permissions,
        parentPermissions,
        userEffectiveActions,
        isLoading,
        reset,
        update: updatePermissions,
    } = useAclPermissions(userContext, dashboardId);

    const { permissions: currentDashboardGroupPermissions } = useAclPermissions(
        userContext,
        currentDashboardGroupId
    );

    const hasUnsavedChanges = !accessControlHelper.isEqualPermissionSet(
        permissions,
        initialPermissions
    );

    const {
        isLoading: isMirrorStateLoading,
        isMirror,
        validGroups,
    } = useDashboardMirrorState(dashboardId);

    const objectType = isMirror
        ? AccessControlObjectType.MIRRORED_DASHBOARD
        : AccessControlObjectType.DASHBOARD;

    const customLabel = isMirror ? 'Dashboard layout' : undefined;
    const validationErrors = aclValidator.getNonWritableObjectError(
        permissions,
        userEffectiveActions,
        objectType
    );
    const canSave = hasUnsavedChanges && !validationErrors.length;
    const hasInvalidGroup =
        !isMirrorStateLoading && permissions?.parent && !validGroups.includes(permissions.parent);

    useExitGuard(
        exitGuardService,
        () => save(permissions),
        () => reset(initialPermissions),
        !validationErrors.length,
        !isLoading && hasUnsavedChanges
    );

    const showDashboardGroupPicker = isMirror || hasInvalidGroup;
    const parentObjectSelector = showDashboardGroupPicker ? (
        <AclMirrorDashboardGroupSelector
            disabled={readOnly}
            dashboardId={dashboardId}
            onSelect={(dashboardGroupModel, sortedAcl) =>
                onDashboardGroupUpdate(dashboardGroupModel, sortedAcl)
            }
            initialValue={permissions?.parent}
        />
    ) : undefined;

    return (
        <ThemeProvider colorScheme={themeKey}>
            <AclFormContext.Provider
                value={{
                    ...userContext,
                    disabled: readOnly || isLoading,
                    syncInProgress: isLoading,
                    restrictTeamAccess: orgPrefContext.sf_restrictTeamManagement,
                }}
            >
                <StyledPanel>
                    {(isMirrorStateLoading || isLoading) && <WaitSpinner size="medium" />}
                    {!isMirrorStateLoading && !isLoading && (
                        <StyledForm onSubmit={handleSubmit}>
                            {missingUpdateDashboardCapability && (
                                <StyledBody>
                                    <MissingRbacCapabilityMessage />
                                </StyledBody>
                            )}
                            <StyledBody>
                                {!isLoading && hasInvalidGroup && (
                                    <div className="alert alert-warning text-left">
                                        <div className="text-left">
                                            {accessControlHelper.getInvalidParentErrorMessage(
                                                objectType,
                                                customLabel,
                                                readOnly
                                            )}
                                        </div>
                                    </div>
                                )}
                                {permissions && (
                                    <AccessControl
                                        customObjectLabel={customLabel}
                                        permissions={permissions}
                                        parentPermissions={parentPermissions}
                                        onChangeCallback={onPermissionsUpdate}
                                        objectType={objectType}
                                        readonly={readOnly}
                                        parentObjectSelector={parentObjectSelector}
                                    />
                                )}
                            </StyledBody>
                            <ThemeProvider colorScheme={isMirror ? 'dark' : themeKey}>
                                {isMirror && (
                                    <StyledBody appearance="dark">
                                        {mirrorPermissions && (
                                            <AccessControl
                                                customObjectLabel="Mirror read and customization"
                                                permissions={mirrorPermissions}
                                                parentPermissions={currentDashboardGroupPermissions}
                                                objectType={AccessControlObjectType.MIRROR}
                                                readonly={true}
                                            />
                                        )}
                                    </StyledBody>
                                )}
                                <StyledFooter appearance={isMirror ? 'dark' : 'normal'}>
                                    <StyledButton
                                        label={CANCEL_BUTTON_LABEL}
                                        appearance="secondary"
                                        size="small"
                                        onClick={() => onDismiss('cancel')}
                                    />
                                    {!readOnly && (
                                        <Tooltip
                                            style={{ marginLeft: 10 }}
                                            content={
                                                validationErrors.length ? validationErrors[0] : ''
                                            }
                                        >
                                            <StyledButton
                                                label={SAVE_BUTTON_LABEL}
                                                type="submit"
                                                appearance="primary"
                                                size="small"
                                                disabled={!canSave}
                                            />
                                        </Tooltip>
                                    )}
                                </StyledFooter>
                            </ThemeProvider>
                        </StyledForm>
                    )}
                </StyledPanel>
            </AclFormContext.Provider>
        </ThemeProvider>
    );

    function onPermissionsUpdate(newPermissions) {
        // apply currentDashboardGroupId as default parent once parent picker is unavailable
        if (
            !parentObjectSelector &&
            accessControlHelper.detectPermissionType(newPermissions.acl) ===
                AccessControlPermissionTypes.INHERIT
        ) {
            return updatePermissions(
                { ...newPermissions, parent: currentDashboardGroupId },
                currentDashboardGroupPermissions
            );
        }

        updatePermissions(newPermissions);
    }

    function onDashboardGroupUpdate(dashboardGroupModel, dashboardGroupAcl) {
        updatePermissions(
            { ...permissions, parent: dashboardGroupModel?.id },
            dashboardGroupAcl ? { acl: dashboardGroupAcl } : null
        );
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validationErrors.length) return;

        save(permissions)
            .then(() => onClose(true))
            .catch((error) => handleError(error));
    }

    function handleDismiss() {
        onDismiss(false);
    }

    function handleError() {
        window.alert('Failed saving dashboard');
        handleDismiss();
    }
}

EditAclDashboard.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    currentDashboardGroupId: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    themeKey: PropTypes.string.isRequired,
    exitGuardService: PropTypes.instanceOf(ExitGuardService).isRequired,
    onDismiss: PropTypes.func,
    onClose: PropTypes.func,
    save: PropTypes.func,
    readOnly: PropTypes.bool,
    missingUpdateDashboardCapability: PropTypes.bool,
};

EditAclDashboard.defaultProps = {
    readOnly: false,
    onDismiss: () => {},
    onClose: () => {},
    save: () => Promise.resolve(),
};
