import { chartDisplayToo } from './chartDisplayToo';
import templateUrl from './chartDisplayOmega.tpl.html';

export const chartDisplayOmega = {
    ...chartDisplayToo,
    templateUrl,
    controller: [
        '$scope',
        '$controller',
        '$element',
        function ($scope, $controller, $element) {
            const parent = $controller(chartDisplayToo.controller, { $scope, $element });
            angular.extend(this, parent);

            /*
             * Because of the way we store a reference to the lexical `this` inside of the
             * implementation of the `chartDisplayToo` controller, we need to capture the
             * template attribute bindings that are set onto the instance of the "child" controller,
             * and propagate them to the "parent" controller instance.
             *
             * i.e. at the beginning of the `chartDisplayToo` controller implementation, we do:
             *
             * ```
             *  const $ctrl = this;
             * ```
             *
             * and because we're not doing "proper" inheritance here, that `$ctrl` variable will point
             * to the `chartDisplayToo` controller instance, not the `chartDisplayOmega` instance.
             * which means that when angular attaches the template-bound attributes - such as `chartModel` -
             * to the `chartDisplayOmega` instance, they are not visible to the code in the `chartDisplayToo`
             * instance. for example, code like:
             *
             * ```
             *  function reassertModelVersion() {
             *      if (angular.isDefined($ctrl.chartModel.$isOriginallyV2)) {
             *          $ctrl.isOriginallyV2 = $ctrl.chartModel.$isOriginallyV2;
             *      } else {
             *          $ctrl.isOriginallyV2 =
             *              $ctrl.chartModel.sf_modelVersion === 2 ||
             *              $ctrl.chartModel.sf_flowVersion === 2;
             *      }
             *  }
             * ```
             *
             * will receive an undefined for `$ctrl.chartModel` if we don't do this child to parent
             * propagation of bound attributes.
             */
            this.$onInit = () => {
                Object.keys(chartDisplayToo.bindings).forEach((attr) => {
                    parent[attr] = this[attr];
                });
                parent.$onInit();
            };
        },
    ],
};
