import templateUrl from './dashboardVariables.tpl.html';

angular
    .module('signalview.variables')

    .directive('dashboardVariables', [
        'sourceFilterService',
        'dashboardUtil',
        'dashboardVariableUtils',
        function (sourceFilterService, dashboardUtil, dashboardVariableUtils) {
            return {
                restrict: 'E',
                templateUrl,
                scope: {
                    filterAlias: '=',
                    overrides: '=?',
                    charts: '=',
                    onPillEnter: '=?',
                    isHierarchical: '=?',
                    getCurrentTimeRange: '<?',
                },
                link: function ($scope) {
                    function update() {
                        $scope.variables = dashboardUtil.mergeVariablesWithOverrides(
                            $scope.filterAlias || [],
                            $scope.overrides || []
                        );

                        $scope.values = $scope.variables.map(function (f) {
                            const valueToUse = dashboardVariableUtils.getVariableDefaultValue(f);
                            if (angular.isString(valueToUse)) {
                                return sourceFilterService.unflattenPropertyValue(valueToUse || '');
                            } else {
                                return valueToUse;
                            }
                        });
                    }

                    $scope.$watchCollection('filterAlias', update);
                    update();
                },
            };
        },
    ]);
