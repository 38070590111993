export const ENTERPRISE_PRODUCT = 'HOST_ENTERPRISE';
export const STANDARD_PRODUCT = 'HOST_STANDARD';

export const MONTH_PERIOD = 'Month';

export default [
    '$q',
    '$http',
    'ACCOUNT_SOURCES',
    'CUSTOMER_SERVER_API_URL',
    'auth',
    'createSyntheticMarketplaceAccount',
    'featureEnabled',
    'API_URL',
    function (
        $q,
        $http,
        ACCOUNT_SOURCES,
        CUSTOMER_SERVER_API_URL,
        auth,
        createSyntheticMarketplaceAccount,
        featureEnabled,
        API_URL
    ) {
        let cachedOrg = null;
        let orgSubscription = null;

        return {
            get,
            syncOrg,
            getExpirationBuffer,
            getHostBasedPlans,
        };

        function get(org) {
            if (orgSubscription !== null && org === cachedOrg) {
                return orgSubscription;
            }
            cachedOrg = org;
            orgSubscription = OrgSubscription(org);
            return orgSubscription;
        }

        function syncOrg(accountNumber) {
            const result = $http.put(
                CUSTOMER_SERVER_API_URL + '/customer/v1/account/' + accountNumber + '/sync'
            );
            if (orgSubscription !== null) {
                orgSubscription.breakPromises();
            }
            return result;
        }

        function getExpirationBuffer(org) {
            return org.accountRenews ? 6 : 0; // in hours
        }

        function getHostBasedPlans() {
            return $http.get(CUSTOMER_SERVER_API_URL + '/customer/v1/plans/hosts');
        }

        /* definition of service created by getter */

        function OrgSubscription(org) {
            let accountInfoPromise = null;

            const svc = {
                breakPromises,
                getAccount,
            };

            return svc;

            function breakPromises(type) {
                if (type === 'account' || !type) {
                    accountInfoPromise = null;
                }
            }

            function getAccount() {
                if (accountInfoPromise !== null) {
                    return accountInfoPromise;
                }
                const localOrg = angular.copy(org);
                // AWS Marketplace Org
                if (localOrg.accountSource === ACCOUNT_SOURCES.AWS_MARKET_PLACE) {
                    accountInfoPromise = createSyntheticMarketplaceAccount(localOrg);
                } else if (featureEnabled('immBillingPageUpdate')) {
                    accountInfoPromise = $http
                        .get(API_URL + `/v2/organization/imm/usage_details`)
                        .then((acc) => acc.data);
                } else {
                    accountInfoPromise = $q.when(null);
                }

                return accountInfoPromise;
            }
        }
    },
];
