import newDashboardTemplateUrl from '../dashboardGroup/newdashboard.tpl.html';

angular.module('signalview.dashboard').factory('dashboardCloner', [
    '$q',
    '$location',
    'sfxModal',
    '$window',
    '$log',
    'blockingLoadService',
    'dashboardGroupService',
    'dashboardUtil',
    'signalboost',
    'currentUser',
    'writepermissionsPermissionsChecker',
    'featureEnabled',
    function (
        $q,
        $location,
        sfxModal,
        $window,
        $log,
        blockingLoadService,
        dashboardGroupService,
        dashboardUtil,
        signalboost,
        currentUser,
        writepermissionsPermissionsChecker,
        featureEnabled
    ) {
        return function clone(dashboard, chartsList, snapshot, group, routeChangeCallback) {
            let targetGroupId;
            let currentGroupPromise;

            if (group && (group.sf_id || group.id)) {
                currentGroupPromise = $q.when(group);
            } else {
                currentGroupPromise = signalboost.user
                    .getDefaultPageId()
                    .then((groupId) => dashboardGroupService.get(groupId))
                    .catch((e) => {
                        $log.error('error fetching default dashboard group: ', e);
                        return $q.when();
                    });
            }
            return $q
                .all({
                    hasWritePermission:
                        writepermissionsPermissionsChecker.hasWritePermissions(dashboard),
                    group: currentGroupPromise,
                })
                .then(({ hasWritePermission, group }) => {
                    const dashboardClone = angular.copy(dashboard);

                    // The default behavior to check to see if the current user has write
                    // permissions, if they don't, set the cloned dashboard to be editable
                    // by anyone
                    if (!hasWritePermission) {
                        writepermissionsPermissionsChecker.setWriters(dashboardClone, {});
                    }

                    return sfxModal
                        .open({
                            templateUrl: newDashboardTemplateUrl,
                            controller: 'NewDashboardController',
                            resolve: {
                                params: function () {
                                    return {
                                        dashboard: dashboardClone,
                                        page: group,
                                        title: 'Save dashboard as',
                                    };
                                },
                                defaultWriters: currentUser.defaultWritePermissions(),
                            },
                            backdrop: 'static',
                            keyboard: false,
                        })
                        .result.then((data) => {
                            targetGroupId = data.pageId;
                            return dashboardUtil.getDashboardData(
                                angular.copy(data.dashboard),
                                data.dashboardName,
                                chartsList
                            );
                        });
                })
                .then(function (data) {
                    const spinner = blockingLoadService();

                    // TODO(trevor): Once groupIds are gone, this will need refactoring
                    return dashboardUtil.createDashboardFromData(data, targetGroupId).then(
                        function (dashboardId) {
                            if (routeChangeCallback) routeChangeCallback();
                            // $location.url is used to ensure that we remove unrelated query params e.g. configId
                            const groupIdStr = featureEnabled('dashboardViews')
                                ? '?groupId=' + targetGroupId
                                : '';
                            $location.url(`/dashboard/${dashboardId}` + groupIdStr);
                            spinner.close();
                        },
                        function (e) {
                            const saveType = snapshot && snapshot.id ? 'saving' : 'cloning';
                            $window.alert('Error ' + saveType + ' dashboard.');
                            $log.log('Error ' + saveType + ' dashboard.', e);
                            spinner.close();
                        }
                    );
                });
        };
    },
]);
