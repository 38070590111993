angular
    .module('signalboost')

    .service('TagRestService', [
        '$log',
        'INACTIVE_TAG',
        'Restangular',
        'signalboost',
        'SignalboostBaseService',
        function ($log, INACTIVE_TAG, rest, signalboost, SignalboostBaseService) {
            //. tag service
            class TagRestService extends SignalboostBaseService {
                constructor() {
                    super('tag', false);
                }

                search(token) {
                    const params = {};
                    params.query = 'sf_tag:*' + token + '*';
                    params.orderBy = 'sf_tag';
                    return signalboost.tag
                        .all()
                        .customGET('', params)
                        .then(function (data) {
                            return data.rs;
                        });
                }
                addQueryAltFields(querystr) {
                    return querystr;
                }
                getQuerySortOrder() {
                    return 'sf_tag';
                }
                getInactiveTag() {
                    return signalboost.tag.basicSearch(INACTIVE_TAG).then(
                        function (results) {
                            return results.length
                                ? rest.restangularizeElement(null, results[0], 'tag')
                                : signalboost.tag.create({ sf_tag: 'inactive' });
                        },
                        function () {
                            $log.error('Cannot find or create inactive tag.');
                            return null;
                        }
                    );
                }
            }

            rest.addElementTransformer('tag', function (tag) {
                if (angular.isString(tag)) {
                    return tag;
                }

                // remove a tag
                tag.removeMember = function (id) {
                    return tag.customDELETE('member/' + id, { maxWait: '0s' }, {});
                };
                tag.addMember = function (id) {
                    return tag.customPOST({}, 'member/' + id, { maxWait: '0s' });
                };
                return tag;
            });

            return TagRestService;
        },
    ]);
