'use strict';
import templateUrl from './jiraIntegration.html';
import jiraSelectionModalUrl from './jiraSelection.html';
import jiraCreateTestIssueStatusUrl from './jiraCreateTestIssueStatus.html';

/* jshint camelcase: false */

angular.module('sfx.ui').directive('sfxJiraIntegration', [
    'sfxModal',
    '$log',
    'JIRA_AUTH_METHOD',
    'jiraService',
    'jiraAssigneeTypeaheadServiceFactory',
    'PRODUCT_NAME',
    function (
        sfxModal,
        $log,
        AUTH_METHOD,
        jiraService,
        jiraAssigneeTypeaheadServiceFactory,
        PRODUCT_NAME
    ) {
        return {
            restrict: 'E',
            scope: true,
            AUTH_METHOD: true,
            require: '^form',
            templateUrl,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.AUTH_METHOD = AUTH_METHOD;
                $scope.indicators = {
                    assignee: {},
                    projectKey: {},
                    issueType: {},
                    createTestIssue: {},
                };

                const assigneeTypeaheadService =
                    jiraAssigneeTypeaheadServiceFactory.createInstance();

                function updateUiFlags() {
                    const cred = $scope.credential;

                    const cloudCredentialsPresent =
                        cred.authMethod === AUTH_METHOD.EMAIL_AND_TOKEN &&
                        cred.userEmail &&
                        (cred.apiToken ||
                            (!$scope.isDirty('baseUrl') && !$scope.isDirty('userEmail')));

                    const serverCredentialsPresent =
                        cred.authMethod === AUTH_METHOD.USER_AND_PASSWORD &&
                        cred.username &&
                        (cred.password ||
                            (!$scope.isDirty('baseUrl') && !$scope.isDirty('username')));

                    $scope.canSelectProject =
                        $scope.editable &&
                        !$scope.indicators.issueType.loading &&
                        cred.baseUrl &&
                        (cloudCredentialsPresent || serverCredentialsPresent);

                    $scope.canSelectProjectDependantFields =
                        $scope.editable &&
                        !$scope.indicators.projectKey.loading &&
                        $scope.canSelectProject &&
                        cred.projectKey;
                }

                function reset() {
                    const credential = $scope.credential;

                    credential.authMethod = credential.authMethod || AUTH_METHOD.EMAIL_AND_TOKEN;
                    credential.name = credential.name || 'JIRA';

                    updateUiFlags();
                }

                $scope.setAuthMethod = function (authMethod) {
                    if ($scope.credential.authMethod !== authMethod) {
                        clearCredentials();
                        $scope.credential.authMethod = authMethod;
                        $scope.integrationForm.$setDirty();
                    }
                };

                function clearCredentialsWhenBaseUrlChanges(newValue, oldValue) {
                    if (newValue === oldValue) return;

                    if (newValue !== $scope.originalState.baseUrl) {
                        clearCredentials();
                    }
                }

                function clearCredentials() {
                    switch ($scope.credential.authMethod) {
                        case AUTH_METHOD.EMAIL_AND_TOKEN:
                            $scope.credential.userEmail = '';
                            $scope.credential.apiToken = '';
                            break;
                        case AUTH_METHOD.USER_AND_PASSWORD:
                            $scope.credential.username = '';
                            $scope.credential.password = '';
                            break;
                    }
                }

                $scope.selectJiraProject = function () {
                    modalSelect('Select project', jiraService.projects, 'projectKey');
                };
                $scope.selectIssueType = function () {
                    modalSelect('Select issue type', jiraService.issueTypes, 'issueType');
                };

                function isJiraCredentialChanged() {
                    return [
                        'baseUrl',
                        'authMethod',
                        'username',
                        'password',
                        'userEmail',
                        'apiToken',
                    ].some((key) => $scope.isDirty(key));
                }

                function shallUseStoredCredentials() {
                    return $scope.originalState.authMethod && !isJiraCredentialChanged();
                }

                function modalSelect(title, optionsAccessors, selectedKey) {
                    const credential = $scope.credential;
                    const indicators = $scope.indicators[selectedKey];

                    const optionsPromise = shallUseStoredCredentials()
                        ? optionsAccessors.get(credential.id, credential.projectKey)
                        : optionsAccessors.post(credential);

                    indicators.error = undefined;
                    indicators.loading = true;

                    optionsPromise
                        .then((options) => {
                            indicators.loading = false;
                            const modal = sfxModal.open({
                                templateUrl: jiraSelectionModalUrl,
                                controller: 'jiraSelection',
                                resolve: {
                                    title: () => title,
                                    options: () => options,
                                    selected: () => $scope.credential[selectedKey],
                                },
                                backdrop: 'static',
                                keyboard: false,
                            });

                            modal.result.then((selected) => {
                                $scope.credential[selectedKey] = selected;
                                $scope.integrationForm.$setDirty();
                            });
                        })
                        .catch((error) => {
                            $log.error(error);
                            indicators.error = (error.data && error.data.message) || error;
                            indicators.loading = false;
                        });
                }

                $scope.suggestAssignees = (query) => {
                    return assigneeTypeaheadService.suggestAssignees(
                        shallUseStoredCredentials(),
                        $scope.credential,
                        query
                    );
                };

                $scope.selectAssignee = (selectedAssigneeName) => {
                    const selectedAssignee =
                        assigneeTypeaheadService.getAssignee(selectedAssigneeName);
                    $scope.credential.assignee = selectedAssignee;
                    $scope.integrationForm.$setDirty();
                };

                $scope.clearAssignee = () => {
                    delete $scope.credential.assignee;
                };

                function openCreateIssueStatusModal(status) {
                    sfxModal.open({
                        templateUrl: jiraCreateTestIssueStatusUrl,
                        controller: [
                            '$scope',
                            ($scope) => {
                                $scope.title = 'Test Issue Status';
                                $scope.model = status;
                            },
                        ],
                        backdrop: 'static',
                        keyboard: false,
                    });
                }

                $scope.createTestIssue = () => {
                    const credential = $scope.credential;
                    const statusPromise = shallUseStoredCredentials()
                        ? jiraService.createAndCommentTestIssue.get(
                              credential.id,
                              credential.projectKey,
                              credential.issueType,
                              credential.assignee ? credential.assignee.name : undefined
                          )
                        : jiraService.createAndCommentTestIssue.post(credential);

                    $scope.indicators.createTestIssue.loading = true;
                    statusPromise.then(
                        (status) => {
                            $scope.indicators.createTestIssue.loading = false;
                            if (_.get(status, 'createIssueResult.success')) {
                                status.issueUrl = jiraService.getIssueUrl(
                                    $scope.credential.baseUrl,
                                    status.createIssueResult.data.key
                                );
                            }
                            openCreateIssueStatusModal(status);
                        },
                        (error) => {
                            $scope.indicators.createTestIssue.loading = false;
                            openCreateIssueStatusModal(error.data);
                        }
                    );
                };

                $scope.isDirty = function (key) {
                    return $scope.credential[key] !== $scope.originalState[key];
                };

                $scope.$on('reset', reset);
                reset();

                $scope.$watch('credential', updateUiFlags, true);
                $scope.$watch('credential.baseUrl', clearCredentialsWhenBaseUrlChanges);
                $scope.$watch('credential.assignee', () => {
                    $scope.assigneeUniqueDisplayName =
                        assigneeTypeaheadService.assigneeToUniqueDisplayName(
                            $scope.credential.assignee
                        );
                });
            },
        };
    },
]);
