import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import Select from '@splunk/react-ui/Select';
import { ThemeProvider } from '@splunk/olly-common';
import { debounce } from 'lodash';
import { useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';
import useNavigatorList from './useNavigatorList';

const FETCH_LIMIT = 20;

const throttleMills = 500;

const StyledSelectorContainer = styled.div`
    height: 100%;
    justify-content: center;
`;

const StyledSelect = styled(Select)`
    min-width: 160px;
    width: 100%;
    height: 28px;
    border: 1px solid rgba(0, 0, 0, 0.4);
`;

const StyledMenu = styled(Select.Option)`
    font-size: ${variables.fontSize};
    line-height: 24px;
    width: 130%;
`;

const NavigatorSelector = ({ onNavigatorSelector, target }) => {
    const colorScheme = useLegacyThemeServiceKey();
    const [navigator, setNavigator] = useState({});
    const [navigatorSearchTerm, setNavigatorSearchTerm] = useState('');
    const [navigators, setNavigators] = useState([]);
    const { isLoading, allNavigators } = useNavigatorList();

    useEffect(() => {
        let slicedNavigators = [];
        slicedNavigators = allNavigators
            .slice(0, FETCH_LIMIT)
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        const copyNavigator = {
            ...target,
            name: target.navigatorName,
        };
        if (navigatorSearchTerm === '') {
            setNavigator(copyNavigator);
            setNavigators(slicedNavigators);
        }
    }, [target, allNavigators, navigatorSearchTerm]);

    const setFilterChange = useMemo(
        () =>
            debounce((keyword) => {
                setNavigatorSearchTerm(keyword);
            }, throttleMills),
        [setNavigatorSearchTerm]
    );

    const handleFilterChange = useCallback(
        (e, { keyword }) => {
            setFilterChange(keyword);
            let filterNav = allNavigators.filter((n) =>
                n?.name?.toLowerCase().includes(keyword.toLowerCase())
            );
            filterNav = filterNav.slice(0, FETCH_LIMIT);
            setNavigators(filterNav);
        },
        [setNavigators, allNavigators, setFilterChange]
    );

    const handleClose = useCallback(() => {
        setNavigatorSearchTerm('');
    }, [setNavigatorSearchTerm]);

    const handleNavigatorChange = useCallback(
        (e, { value }) => {
            const filterNavigator = navigators.find((n) =>
                n?.name?.toLowerCase().includes(value.toLowerCase())
            );
            setNavigator(filterNavigator);
            onNavigatorSelector(filterNavigator);
        },
        [navigators, onNavigatorSelector]
    );

    return (
        <ThemeProvider colorScheme={colorScheme}>
            <StyledSelectorContainer>
                <StyledSelect
                    value={navigator?.name}
                    placeholder={navigator?.name || 'Select a navigator'}
                    menuStyle={{
                        minWidth: '240px',
                        maxWidth: '328px',
                        maxHeight: '330px',
                    }}
                    defaultPlacement="below"
                    onFilterChange={handleFilterChange}
                    onChange={handleNavigatorChange}
                    onClose={handleClose}
                    filter
                    animateLoading
                    appearance="toggle"
                    isLoadingOptions={isLoading}
                    loadingMessage="Navigators will appear here"
                >
                    {navigators?.length &&
                        navigators?.map((navigator) => (
                            <StyledMenu
                                label={navigator?.name}
                                value={navigator?.name}
                                key={`${navigator?.name}-${Math.random()}`}
                            />
                        ))}
                </StyledSelect>
            </StyledSelectorContainer>
        </ThemeProvider>
    );
};

export default NavigatorSelector;
