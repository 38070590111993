export default [
    '$uibModal',
    'themeService',
    '$rootScope',
    function ($uibModal, themeService, $rootScope) {
        function updateModalTheme() {
            const modal = document.getElementsByClassName('sf-ui modal')[0];
            if (!modal) {
                return;
            }
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];

            const modalRoot = angular.element(modal);
            const modalBackdropRoot = angular.element(modalBackdrop);

            if (themeService.dark) {
                modalRoot.addClass('dark');
                modalBackdropRoot.addClass('dark');
            } else {
                modalRoot.removeClass('dark');
                modalBackdropRoot.removeClass('dark');
            }
        }

        // Listens the theme change when modal is opened
        $rootScope.$on('theme update', updateModalTheme);

        const svc = {
            open: function (options) {
                // decorate the modal to ensure it is styled properly regardless of attach point
                // relative to .sf-ui
                let classes = (options.windowClass || '') + ' sf-ui sf-bootstrap';
                if (themeService.dark) {
                    classes += ' dark';
                    options.backdropClass = 'dark';
                }
                options.windowClass = classes;
                options.ariaLabelledBy = 'modal-title';
                options.ariaDescribedBy = 'modal-description';
                return $uibModal.open(options);
            },
        };

        return svc;
    },
];
