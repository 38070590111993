import { NotificationCategory, NotificationService } from '@splunk/olly-common';

export default [
    '_',
    '$q',
    'featureEnabled',
    function (_, $q, featureEnabled) {
        return {
            responseError: function (originalResponse) {
                console.log('originalResponse'.originalResponse);
                if (featureEnabled('newRoleErrorInterceptor') && originalResponse.status === 403) {
                    const FORBIDDEN_ERROR_MESSAGE =
                        "Your role doesn't let you perform this action. Contact your administrator for more information.";

                    const displayForbiddenToast = _.throttle(
                        () => {
                            NotificationService.post({
                                category: NotificationCategory.TOAST,
                                message: FORBIDDEN_ERROR_MESSAGE,
                                type: 'error',
                            });
                        },
                        1000,
                        { trailing: false }
                    );

                    displayForbiddenToast();
                }

                return $q.reject(originalResponse);
            },
        };
    },
];
