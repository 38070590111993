export default [
    '$scope',
    'metricObj',
    'metricService',
    'MAX_METRIC_DESCRIPTION_LENGTH',
    'METRIC_TYPE_CONVERSION',
    function (
        $scope,
        metricObj,
        metricService,
        MAX_METRIC_DESCRIPTION_LENGTH,
        METRIC_TYPE_CONVERSION
    ) {
        $scope.save = save;
        $scope.maxDescriptionLength = MAX_METRIC_DESCRIPTION_LENGTH;

        init();

        function init() {
            $scope.metricName = metricObj.name;
            $scope.metricDescription = metricObj.description;
        }

        function save() {
            const metric = {
                type: METRIC_TYPE_CONVERSION.displayToNative[metricObj.metricType],
                description: $scope.metricDescription,
            };
            $scope.errorMessage = null;
            return metricService
                .put(metricObj.name, metric)
                .then((result) => {
                    return $scope.$close(result);
                })
                .catch((response) => {
                    const apiMessage = response.data.message;
                    $scope.errorMessage =
                        'Something went wrong saving metric description: ' + apiMessage;
                });
        }
    },
];
