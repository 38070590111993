import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import AclDashboardGroupService from '../../../legacy/app/dashboardGroup/aclDashboardGroup/AclDashboardGroupService';
import AccessControl from '../../../common/ui/accessControl/AccessControl';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import accessControlHelper from '../../../common/ui/accessControl/accessControlHelper';
import useInputState from '../../../common/react-utils/useInputState';
import aclValidator from '../../../common/ui/accessControl/aclValidator';
import useAclUserContext from '../../../common/ui/accessControl/useAclUserContext';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';
import AclFormInput from '../../../common/ui/accessControl/AclFormInput';
import useAclPermissions from '../../../common/ui/accessControl/useAclPermissions';
import { ThemeProvider } from '../../../common/theme/ThemeProvider';
import {
    StyledPanel,
    StyledButton,
    StyledFooter,
    StyledBody,
    StyledModalHeader,
    StyledForm,
} from '../../../common/ui/ModalStyles';
import useAclOrgPreferencesContext from '../../../common/ui/accessControl/useAclOrgPreferencesContext';

const OBJECT_TYPE = AccessControlObjectType.DASHBOARD_GROUP;

const DASHBOARD_GROUP_NAME_LABEL = 'Name';
const DASHBOARD_GROUP_DESC_LABEL = 'Description';
const SAVE_BUTTON_LABEL = 'Save';
const CANCEL_BUTTON_LABEL = 'Cancel';

export default function CreateAclDashboardGroup({
    userData,
    onDismiss,
    onClose,
    customLabel,
    themeKey,
}) {
    const aclDashboardGroupService = AclDashboardGroupService.useInstance();
    const label = customLabel || OBJECT_TYPE.label;

    const userContext = useAclUserContext(userData);
    const [defaultPermissions] = useState({
        acl: accessControlHelper.getDefaultAcl(
            userContext.orgId,
            userContext.userId,
            AccessControlPermissionTypes.PUBLIC
        ),
        parent: null,
    });
    const orgPrefContext = useAclOrgPreferencesContext(userContext.orgId);

    const {
        permissions,
        userEffectiveActions,
        update: updatePermissions,
        isLoading,
    } = useAclPermissions(userContext, null, defaultPermissions);

    const NEW_DASHBOARD_GROUP_LABEL = `New ${label?.toLowerCase()}`;
    const VALIDATION_ERROR_MESSAGE = {
        NO_NAME: `Please enter a name for the ${label?.toLowerCase()}`,
        NO_CURRENT_USER_IN_ACL: accessControlHelper.getNoCurrentUserInAclErrorMessage(label),
    };

    const [syncInProgress, setSyncInProgress] = useState(false);
    const [name, setName] = useInputState('');
    const [description, setDescription] = useInputState('');

    const validationErrors = validateForm();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <AclFormContext.Provider
                value={{
                    ...userContext,
                    disabled: isLoading,
                    syncInProgress: isLoading,
                    restrictTeamAccess: orgPrefContext.sf_restrictTeamManagement,
                }}
            >
                <StyledPanel>
                    <StyledForm onSubmit={handleSubmit}>
                        <StyledModalHeader>{NEW_DASHBOARD_GROUP_LABEL}</StyledModalHeader>

                        <StyledBody>
                            <AclFormInput
                                name="dashboard-group-name"
                                label={DASHBOARD_GROUP_NAME_LABEL}
                                value={name}
                                onChange={setName}
                            />
                            <AclFormInput
                                name="dashboard-group-description"
                                label={DASHBOARD_GROUP_DESC_LABEL}
                                value={description}
                                onChange={setDescription}
                            />
                            <AccessControl
                                permissions={permissions}
                                onChangeCallback={updatePermissions}
                                objectType={OBJECT_TYPE}
                                customObjectLabel={label}
                            />
                        </StyledBody>
                        <StyledFooter>
                            <StyledButton
                                label={CANCEL_BUTTON_LABEL}
                                appearance="secondary"
                                size="small"
                                onClick={() => onDismiss('cancel')}
                            />
                            <Tooltip
                                style={{ marginLeft: 10 }}
                                content={validationErrors.length ? validationErrors[0] : ''}
                            >
                                <StyledButton
                                    label={SAVE_BUTTON_LABEL}
                                    type="submit"
                                    appearance="primary"
                                    size="small"
                                    disabled={syncInProgress || !!validationErrors.length}
                                    data-test="create-new-dashboard-group"
                                />
                            </Tooltip>
                        </StyledFooter>
                    </StyledForm>
                </StyledPanel>
            </AclFormContext.Provider>
        </ThemeProvider>
    );

    function validateForm() {
        const validationErrors = [];

        if (name.trim() === '') {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.NO_NAME);
        }

        const aclErrors = aclValidator.getNonWritableObjectError(
            permissions,
            userEffectiveActions,
            OBJECT_TYPE
        );
        return [...validationErrors, ...aclErrors];
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (validationErrors.length) {
            return;
        }

        setSyncInProgress(true);
        aclDashboardGroupService
            .create({
                name,
                description,
                permissions,
            })
            .then((dashboardGroup) => onClose({ dashboardGroup, permissions }))
            .catch((error) => handleError(error));
    }

    function handleError(error) {
        window.alert('Failed creating dashboard-group');
        onDismiss(error);
    }
}

CreateAclDashboardGroup.propTypes = {
    userData: PropTypes.object.isRequired,
    themeKey: PropTypes.string.isRequired,
    onDismiss: PropTypes.func,
    onClose: PropTypes.func,
    customLabel: PropTypes.string,
};
