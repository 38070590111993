angular
    .module('signalview.page')

    .service('pageTypeService', [
        '$log',
        'dashboardGroupService',
        'imageService',
        'dashboardUtil',
        'SYSTEM_USER_ID',
        function ($log, dashboardGroupService, imageService, dashboardUtil, SYSTEM_USER_ID) {
            function getIconForServiceName(serviceName) {
                serviceName = (serviceName || '').toLowerCase().replace(/\s/g, '');
                return imageService.getServiceIconURL(serviceName);
            }

            const dashboardGroupTypes = {
                USER: {
                    display: 'User',
                    identifyingProperties: ['sf_email'],
                    identifyFromDashboard: function (dashboard) {
                        // TODO: update to handle v2 object
                        return !!dashboard.sf_email && !!dashboard.email;
                    },
                    getFavoriteKey: dashboardUtil.getCustomAndUserFavoriteKey,
                    getIconUrl: function (dashboardGroup) {
                        return imageService.getProfileImageForEmail(
                            dashboardGroup.sf_email || dashboardGroup.email,
                            36
                        );
                    },
                },
                BUILTIN: {
                    display: 'Built-in',
                    identifyFromGroup: function (group) {
                        return (
                            (group.sf_creator || group.creator) === SYSTEM_USER_ID &&
                            (group.sf_importOf ||
                                group.importOf ||
                                group.sf_serviceDiscoveryVersion ||
                                group.serviceDiscoveryVersion)
                        );
                    },
                    identifyFromDashboard: function (dashboard) {
                        return (dashboard.sf_creator || dashboard.creator) === SYSTEM_USER_ID;
                    },
                    getFavoriteKey: function (dashboard, group) {
                        const groupName = (group || dashboard).sf_page || (group || dashboard).name;
                        const dashboardName = dashboard.sf_dashboard || dashboard.name;

                        return groupName + '-' + dashboardName;
                    },
                    getIconUrl: function (dashboardGroup) {
                        return getIconForServiceName(dashboardGroup.sf_page || dashboardGroup.name);
                    },
                },
                CUSTOM: {
                    display: 'Custom',
                    identifyFromDashboard: function (dashboard) {
                        const email = dashboard.sf_email || dashboard.email;
                        const creator = dashboard.sf_creator || dashboard.creator;

                        return !email && creator !== SYSTEM_USER_ID;
                    },
                    getFavoriteKey: dashboardUtil.getCustomAndUserFavoriteKey,
                    getIconUrl: function (dashboardGroup) {
                        return imageService.getDefaultIconURL(
                            dashboardGroup.sf_id || dashboardGroup.id
                        );
                    },
                },
            };

            function getTypes() {
                return dashboardGroupTypes;
            }

            function getType(dashboardGroup) {
                const keys = Object.keys(dashboardGroupTypes);
                const dashboardGroupV1 = dashboardGroupService.convertToV1(dashboardGroup);

                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const groupType = dashboardGroupTypes[key];
                    const identifyingProperties = groupType.identifyingProperties;
                    const identifyFromGroup = groupType.identifyFromGroup;

                    if (
                        (identifyingProperties &&
                            identifyingProperties.some((prop) =>
                                dashboardGroupV1.hasOwnProperty(prop)
                            )) ||
                        (identifyFromGroup && identifyFromGroup(dashboardGroup)) ||
                        (!identifyingProperties && !identifyFromGroup)
                    ) {
                        return groupType;
                    }
                }
                $log.error('Cannot find type for dashboard group ' + dashboardGroup.id);
            }

            function getTypeFromDashboard(dashboard) {
                if (dashboard) {
                    const dashboardGroupType = Object.values(dashboardGroupTypes).find(
                        (groupType) => groupType.identifyFromDashboard(dashboard)
                    );
                    return dashboardGroupType || dashboardGroupTypes.CUSTOM;
                }
                return dashboardGroupTypes.CUSTOM;
            }

            return {
                getType: getType,
                getTypes: getTypes,
                getTypeFromDashboard: getTypeFromDashboard,
                getIconForServiceName: getIconForServiceName,
            };
        },
    ]);
