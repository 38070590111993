import templateUrl from './orgIntegrations.tpl.html';
import integrationModalTemplateUrl from './integrationModal/integrationModal.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .directive('orgIntegrations', [
        '$filter',
        '$location',
        'sfxModal',
        'integrationsListService',
        'integrationNameMap',
        'credentialV2Service',
        'signalboost',
        'integrationsPollingService',
        'largeVolumeBarService',
        'namedTokenService',
        '$rootScope',
        function (
            $filter,
            $location,
            sfxModal,
            integrationsListService,
            integrationNameMap,
            credentialV2Service,
            signalboost,
            integrationsPollingService,
            largeVolumeBarService,
            namedTokenService,
            $rootScope
        ) {
            return {
                restrict: 'AE',
                replace: true,
                templateUrl,
                scope: {
                    org: '=',
                },
                link: {
                    pre: function ($scope) {
                        const eventCategories = '(COLLECTD SERVICE_DISCOVERY)';
                        $scope.initialFilters = [
                            {
                                iconClass: 'icon-properties',
                                property: 'sf_eventCategory',
                                propertyValue: eventCategories,
                                query: 'sf_eventCategory:' + eventCategories,
                                type: 'property',
                                value: eventCategories,
                                unescaped: true,
                            },
                        ];
                    },
                    post: function ($scope) {
                        const categories = integrationsListService.getIntegrationsCategories();

                        integrationsPollingService.registerListener(setIntegrationExists);

                        $scope.$watch('query', function () {
                            $scope.categories = categories
                                .map(function (category) {
                                    return angular.extend({}, category);
                                })
                                .filter(function (category) {
                                    const integrations = $filter('filter')(
                                        category.integrations,
                                        $scope.query
                                    );
                                    category.integrations = integrations;
                                    return integrations.length;
                                });
                        });

                        const openLargeVolumeModal = $location.search().largeVolume;
                        if (openLargeVolumeModal) {
                            largeVolumeBarService.openModal();
                            $location.search('largeVolume', null).replace();
                        }

                        signalboost.uauth
                            .canCreate($scope.org.id, 'Credentials')
                            .then((permission) => ($scope.editable = permission));

                        let modalInstance;
                        function openModal(integrationType, integrationName, hasInputs) {
                            modalInstance = sfxModal.open({
                                controller: 'integrationModalController',
                                templateUrl: integrationModalTemplateUrl,
                                size: 'lg',
                                resolve: {
                                    org: () => $scope.org,
                                    editable: () => $scope.editable,
                                    integrationType: () => integrationType,
                                    integrationName: () => integrationName,
                                    apiToken: () => getDefaultApiToken(),
                                    updateActiveIntegrations: () => updateActiveIntegrations,
                                },
                                windowClass: 'integration-modal',
                                backdrop: hasInputs ? 'static' : true,
                                keyboard: !hasInputs,
                            });

                            modalInstance.result.catch(function () {
                                $location.search({ selectedKeyValue: null });
                            });
                        }

                        function getDefaultApiToken() {
                            const TOKEN_PLACEHOLDER = '<insert_token_here>';
                            return namedTokenService
                                .get('Default')
                                .then(({ data }) => {
                                    if (data?.secret && !data?.disabled) {
                                        return data.secret;
                                    }
                                    return TOKEN_PLACEHOLDER;
                                })
                                .catch(() => {
                                    return TOKEN_PLACEHOLDER;
                                });
                        }

                        function updateActiveIntegrations() {
                            credentialV2Service
                                .getActiveIntegrations()
                                .then((activeIntegrations) => {
                                    $scope.activeIntegrations = activeIntegrations;
                                });
                        }

                        updateActiveIntegrations();

                        let keyVal;
                        function updateModal() {
                            let newKeyVal = $location.search().selectedKeyValue;
                            if (!newKeyVal) {
                                if (modalInstance) modalInstance.close();
                                keyVal = null;
                                return;
                            }

                            newKeyVal = newKeyVal.split(':');
                            if (!keyVal || keyVal[1] !== newKeyVal[1]) {
                                if (modalInstance) modalInstance.close();

                                const newName = integrationNameMap[newKeyVal[1]];
                                if (newName && newName !== newKeyVal[1]) {
                                    $location
                                        .search({ selectedKeyValue: newKeyVal[0] + ':' + newName })
                                        .replace();
                                    keyVal = null;
                                    return;
                                }

                                keyVal = newKeyVal;

                                const hasInputs = keyVal[0] === 'integrations';
                                openModal(keyVal[0], keyVal[1], hasInputs);
                            }
                        }

                        function setIntegrationExists(integrationExists) {
                            $scope.integrationExists = integrationExists;
                        }

                        const unSubscribeRouteUpdate = $rootScope.$on(
                            'React:$routeUpdate',
                            updateModal
                        );
                        updateModal();

                        $scope.$on('$destroy', function () {
                            unSubscribeRouteUpdate();
                            integrationsPollingService.deregisterListener(setIntegrationExists);
                            if (modalInstance) modalInstance.close();
                        });
                    },
                },
            };
        },
    ]);
