import CrossLink from '../../legacy/app/crossLinks/crossLink';
import { withNg2React } from '../../common/util/Ng2React';

const CrossLinkAngular = {
    ...CrossLink,
    bindings: {
        ...CrossLink.bindings,
        popupAlign: '<',
        incomingDimensions: '<',
    },
};

export default CrossLinkAngular;
export const CrossLinkReact = withNg2React({
    name: 'crossLinkAngular',
    definition: CrossLinkAngular,
});
