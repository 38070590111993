import logger from '../../../../common/logger';

const info = logger('ChartNoDataService').extend('info');

function getBlockContextFromMessage(message) {
    // If there is only one context it is returned as the blockContext property, but if an array it is blockContexts and we only need to get the first value
    return message.blockContext || message.blockContexts[0];
}

/**
 *
 * This service is used to determine if there is no data available for a chart.
 *
 * Once any data has been returned for the chart it will set the hasData property and skip any further checks
 *
 * The general flow looks like this:
 * - Get the signalflow model for the chart and parse it to match data blocks to incoming messages in the job stream
 * - Whenever a FETCH_NUM_TIMESERIES message comes in on the job stream check if it has data, if it does set hasData to true and quit further checking
 * - When a FETCH_NUM_TIMESERIES message has been received for every data block and none of them have data then query the metric finder for the metrics in the chart
 * - If the metric finder returns any of the metrics queried for them set hasData to true and quit further checking
 * - If the metric finder didn't return any of the metrics queried then assume that we have no data for the chart and call the callback provided
 *
 * For a more detailed description of the logic refer to: https://docs.google.com/document/d/1i_TMC23HECzUkZx3914jp8CceNh67mnciDtoq9_o3Yc/edit#
 */
export const ChartNoDataService = [
    'API_URL',
    '$http',
    'filterConverter',
    function (API_URL, $http, filterConverter) {
        // Something important and meaningful to the service
        function ChartNoDataService(chartModel, noDataFoundCallback) {
            this.chartModel = chartModel;
            this.noDataFoundCallback = noDataFoundCallback;
            this.hasData = false;
            this.dataError = false;
            this.dataBlocks = [];
            this.signalFlowModel = this.fetchSignalFlowModel(chartModel);
        }

        ChartNoDataService.prototype.shouldStopProcessing = function () {
            return (
                this.hasData || this.dataError || this.noDataReturnedInFetchNumTimeseriesMessages
            );
        };

        ChartNoDataService.prototype.allDataBlocksCheckedForActiveMetrics = function () {
            return this.dataBlocks.every((dataBlock) => dataBlock.checkedMetricFinder);
        };

        ChartNoDataService.prototype.createDataBlocksFromSignalFlowModel = function (
            signalFlowModel
        ) {
            // If the model is not an array then something went wrong so stop checking because we can't correctly check
            if (!Array.isArray(signalFlowModel)) {
                this.dataError = true;
                return;
            }

            // Get the original program text and split by newlines to get each row of text. This will be used to compute the column information from the position.
            const programTextLines = this.chartModel.sf_viewProgramText.split('\n');
            let charactersBeforeThisLine = 0;

            signalFlowModel.forEach((expression, index) => {
                // We can only validate 'data' functions, not other ones like 'newrelic' or 'graphite'
                if (!expression.start || expression.start.functionName !== 'data') {
                    return;
                }

                const dataBlock = {};
                dataBlock.line = index + 1;
                dataBlock.metric = expression.start.args.metric.value;
                dataBlock.position = expression.start.position; // this field denotes the count of chars preceding this data() call. For example, 'A = data('foobar').publish(...)' would have a position value of 4
                dataBlock.column = dataBlock.position - charactersBeforeThisLine + 1;
                if (expression.start?.args?.filter) {
                    dataBlock.filters = filterConverter(expression.start.args.filter);
                }
                this.dataBlocks.push(dataBlock);

                charactersBeforeThisLine =
                    charactersBeforeThisLine + programTextLines[index].length + 1;
            });
        };

        ChartNoDataService.prototype.fetchSignalFlowModel = function (chartModel) {
            return $http({
                method: 'POST',
                url: API_URL + '/v2/signalflow/_/getSignalFlowModel',
                data: chartModel.sf_viewProgramText,
                headers: {
                    'Content-Type': 'text/plain',
                },
            }).then(
                (response) => this.createDataBlocksFromSignalFlowModel(response.data),
                (err) => {
                    this.dataError = true;
                    info(
                        'There was an error trying to parse the signalflow model. Unable to check if no data is being returned.',
                        err
                    );
                }
            );
        };

        ChartNoDataService.prototype.onFeedback = function (feedbackMessages) {
            if (this.shouldStopProcessing()) {
                return;
            }

            this.latestFetchNumTimeseriesMessages = feedbackMessages.filter(
                (message) => message.messageCode === 'FETCH_NUM_TIMESERIES'
            );

            this.signalFlowModel.then(this.checkDataBlocksForMatchingTimeseries.bind(this));
        };

        ChartNoDataService.prototype.matchMessagesWithDataBlocks = function () {
            this.latestFetchNumTimeseriesMessages.forEach((message) => {
                const blockContext = getBlockContextFromMessage(message);
                const matchingDataBlock = this.dataBlocks.find(
                    (block) =>
                        block.line === blockContext.line && block.column === blockContext.column
                );
                if (matchingDataBlock) {
                    matchingDataBlock.fetchNumTimeseriesMessage = message;
                }
            });
        };

        // This can be true even if we haven't received a message for every data block yet, since if any message has data we
        //  will be able to stop checking other messages
        ChartNoDataService.prototype.someDataBlocksHaveData = function () {
            // If any of the data blocks that have a matching fetch num timeseries message have a non-zero value then we have received data
            return this.dataBlocks.some((dataBlock) => {
                if (
                    dataBlock.fetchNumTimeseriesMessage &&
                    dataBlock.fetchNumTimeseriesMessage.numInputTimeSeries !== 0
                ) {
                    return true;
                }

                return false;
            });
        };

        // This makes sure that BOTH all of the data blocks have a message and that none of those messages have a value
        ChartNoDataService.prototype.allDataBlocksHaveAMessageWithNoData = function () {
            return this.dataBlocks.every((dataBlock) => {
                return (
                    dataBlock.fetchNumTimeseriesMessage &&
                    dataBlock.fetchNumTimeseriesMessage.numInputTimeSeries === 0
                );
            });
        };

        ChartNoDataService.prototype.checkDataBlocksForMatchingTimeseries = function () {
            // Because this is asynchronous, check to see if we should stop processing before doing the work
            if (this.shouldStopProcessing()) {
                return;
            }

            this.matchMessagesWithDataBlocks();

            if (this.someDataBlocksHaveData()) {
                this.hasData = true;
                info(
                    'Found data for at least one time series in the data. Stopping no data service checks.'
                );
                return;
            }

            if (this.allDataBlocksHaveAMessageWithNoData()) {
                this.noDataReturnedInFetchNumTimeseriesMessages = true;
                info(
                    'No data was found for every time series in the data. Performing secondary metric check.'
                );
                for (const dataBlock of this.dataBlocks) {
                    // if one of the async calls to the metric finder has found an active metric, end the check
                    if (this.hasData) {
                        return;
                    }
                    this.checkMetricFinderForMetrics(dataBlock);
                }
            }
        };

        ChartNoDataService.prototype.getMetricFinderRequest = function (dataBlock) {
            return {
                method: 'GET',
                url:
                    API_URL +
                    `/v2/metric/_/metricfinder?activeOnly=true${this.getMetricFinderFilters(
                        dataBlock
                    )}&query=${dataBlock.metric}`,
            };
        };

        ChartNoDataService.prototype.getMetricFinderFilters = function (dataBlock) {
            if (!dataBlock.filters) {
                return '';
            }
            let metricFinderFilterArgs = '';
            // applyIfExists matches documents missing the property filtered for if the match-missing flag is applied
            // Metric finder does not allow for this flag so we remove such filters to avoid getting a false-positive
            dataBlock.filters
                .filter((filter) => !filter.applyIfExists)
                .forEach((filter) => {
                    let metricFinderFilterArg = '&filters=';
                    if (filter.NOT) {
                        metricFinderFilterArg += '!';
                    }
                    metricFinderFilterArg += filter.property + ':' + filter.propertyValue;
                    metricFinderFilterArgs += metricFinderFilterArg;
                });
            if (metricFinderFilterArgs) {
                return metricFinderFilterArgs;
            }
        };

        ChartNoDataService.prototype.hasMatchInMetricFinderResponse = function (
            response,
            dataBlock
        ) {
            return response.data.metrics.metricResults.some((metric) => {
                return dataBlock.metric === metric.value;
            });
        };

        ChartNoDataService.prototype.checkMetricFinderForMetrics = function (dataBlock) {
            $http(this.getMetricFinderRequest(dataBlock)).then(
                (response) => {
                    if (this.hasMatchInMetricFinderResponse(response, dataBlock)) {
                        this.hasData = true;
                    }
                    dataBlock.checkedMetricFinder = true;
                    if (
                        this.allDataBlocksCheckedForActiveMetrics() &&
                        !this.hasData &&
                        !this.dataError
                    ) {
                        this.noDataFoundCallback();
                    }
                },
                (err) => {
                    this.dataError = true;
                    info(
                        'There was an error querying the metric finder for the metrics. Unable to check if no data is being returned.',
                        err
                    );
                }
            );
        };

        // Using name shouldUse instead of just use to avoid linting issues that expect all 'use' functions to be React hooks
        ChartNoDataService.shouldUseChartNoDataService = function (chartModel) {
            return chartModel.sf_uiModel.chartconfig.noDataMessage;
        };

        return ChartNoDataService;
    },
];
