import templateUrl from './singleLineAlertTicker.html';

export const singleLineAlertTicker = {
    bindings: {
        alerts: '<',
    },
    templateUrl,
    controller: [
        'ALERT_SEVERITY_LEVELS',
        '$interval',
        '$element',
        '$timeout',
        function (ALERT_SEVERITY_LEVELS, $interval, $element, $timeout) {
            const $ctrl = this;

            $ctrl.alertIndex = 0;
            $ctrl.$onChanges = $onChanges;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.scrolling = false;

            // How often we should check to see if we should scroll or not. We can
            // also try to handle this deterministically by ensuring we send all
            // possible resize events here, but this seemed to be a pretty cheap check
            // todo every few seconds, so I figured it may be worth having this be an
            // independent check.
            const TICK_INTERVAL_MS = 3000;
            const interval = $interval(tick, TICK_INTERVAL_MS);

            // Checks to see if the information in the ticker overflow the ticker's
            // bounds in the layout, indicating that we should start scrolling the
            // ticker.
            function shouldScroll() {
                const containerWidth = $element[0].getBoundingClientRect().width;

                if ($ctrl.scrolling) {
                    const scrollWidth = $element.find('.scroller-slider')[0].scrollWidth;
                    return scrollWidth > containerWidth;
                } else {
                    const scrollWidth = $element[0].scrollWidth - 5;
                    return scrollWidth > containerWidth;
                }
            }

            // Determines how long the animation should take, this is going to depend
            // on how much space we have to cover so it's relative to container width
            // and scroll width. Speed can be adjusted with the scalar.
            function animationDuration() {
                const containerWidth = $element[0].getBoundingClientRect().width;
                const scrollWidth = $element[0].scrollWidth;
                const DURATION_SCALAR = 8;
                return (scrollWidth / containerWidth) * DURATION_SCALAR;
            }

            function tick() {
                if (shouldScroll()) {
                    startScrolling();
                } else {
                    stopScrolling();
                }
            }

            function startScrolling() {
                if ($ctrl.scrolling) return;

                $ctrl.scrolling = true;
                const duration = animationDuration();

                // need to wait for the $ctr.scolling to ng-if some divs into existance first.
                $timeout(() => {
                    $element.find('.scroller').css('animation-duration', duration + 's');
                    $element.find('.filler').css('animation-duration', duration + 's');
                });
            }

            function stopScrolling() {
                if (!$ctrl.scrolling) return;
                $ctrl.scrolling = false;
            }

            function $onDestroy() {
                $interval.cancel(interval);
            }

            function $onChanges() {
                const alertsBySeverity = this.alerts;

                let orderedAlertList = [];
                // this is NOT SMOKEY_SEVERITY_LEVELS because we dont want to show normal.
                ALERT_SEVERITY_LEVELS.forEach((severity) => {
                    if (alertsBySeverity[severity] !== undefined) {
                        orderedAlertList = orderedAlertList.concat(alertsBySeverity[severity]);
                    }
                });

                $ctrl.orderedAlertList = orderedAlertList;
            }
        },
    ],
};
