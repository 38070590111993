angular.module('signalview.detector').service('generateRangeText', [
    'moment',
    function (moment) {
        return function (range, capitalize) {
            return moment()
                .subtract(range)
                .fromNow(true)
                .replace('7 days', '1 week')
                .split(' ')
                .map(function (word) {
                    if (range > 45000 && (word === 'a' || word === 'an')) {
                        return 1;
                    }
                    if (capitalize) {
                        return word[0].toUpperCase() + word.slice(1);
                    } else {
                        return word;
                    }
                })
                .join(' ');
        };
    },
]);
