import { Variable } from '@splunk/olly-imm/build/Dashboard/dashboard.model';

export class DashboardSavedVariablesContext {
    private savedVariables: Variable[] = [];

    public setSavedVariables(savedVariables: Variable[]): void {
        this.savedVariables = savedVariables;
    }

    public getSavedVariables(): Variable[] {
        return this.savedVariables;
    }
}
