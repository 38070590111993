angular.module('signalview.heatmap').controller('HeatmapSettingsController', [
    '$scope',
    'heatmapUserPreferences',
    'INACTIVE_HOST_DURATIONS',
    'userPreferences',
    'orgId',
    'heatmap',
    function (
        $scope,
        heatmapUserPreferences,
        INACTIVE_HOST_DURATIONS,
        userPreferences,
        orgId,
        heatmap
    ) {
        $scope.orgId = orgId;

        $scope.INACTIVE_HOST_DURATIONS = INACTIVE_HOST_DURATIONS;

        let hideDeadHosts = userPreferences.sf_navigatorHideDeadHosts;
        if (hideDeadHosts === undefined) {
            hideDeadHosts = true;
        }

        $scope.navigatorConfig = {
            hideDeadHosts: hideDeadHosts,
            inactiveHostDuration:
                parseInt(userPreferences.sf_navigatorInactiveHostDuration, 10) ||
                INACTIVE_HOST_DURATIONS.AUTO,
        };

        $scope.$watch('navigatorConfig.hideDeadHosts', function (cur, prev) {
            if (cur === prev) return;
            heatmapUserPreferences.hideDeadHosts(cur);
            heatmap.hideDeadHosts(cur);
        });

        function getDurationLabel() {
            switch ($scope.navigatorConfig.inactiveHostDuration) {
                case INACTIVE_HOST_DURATIONS.AUTO:
                    return 'automatically';
                case INACTIVE_HOST_DURATIONS.FIVE_MINS:
                    return '5 minutes';
                case INACTIVE_HOST_DURATIONS.TEN_MINS:
                    return '10 minutes';
                case INACTIVE_HOST_DURATIONS.TWENTY_MINS:
                    return '20 minutes';
                case INACTIVE_HOST_DURATIONS.THIRTY_MINS:
                    return '30 minutes';
                default:
                    throw new Error(
                        'Unable to determine label for ',
                        $scope.navigatorConfig.inactiveHostDuration
                    );
            }
        }

        $scope.selectedInactiveHostDurationLabel = getDurationLabel();
        $scope.$watch('navigatorConfig.inactiveHostDuration', function (cur, prev) {
            if (cur === prev) return;
            heatmapUserPreferences.inactiveHostDuration(cur);
            $scope.selectedInactiveHostDurationLabel = getDurationLabel();
        });
    },
]);
