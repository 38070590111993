import templateUrl from './gaugeChart.tpl.html';

export const gaugeChart = {
    templateUrl,
    bindings: {
        latestValue: '<',
        plotPrefix: '<',
        plotSuffix: '<',
        plotUnit: '<',
        hasDescription: '<',
        colorByValueScale: '<',
        color: '<',
        mini: '<?',
        chartTitle: '<?',
        hideTimestamp: '<?',
        maxDecimalPlaces: '<?',
        useKmg2: '<?',
        disableAnimations: '<?',
    },
    controller: [
        'd3',
        '$scope',
        '$element',
        'CHART_DISPLAY_EVENTS',
        'colorByValueService',
        'valueFormatter',
        '$timeout',
        function (
            d3,
            $scope,
            $element,
            CHART_DISPLAY_EVENTS,
            colorByValueService,
            valueFormatter,
            $timeout
        ) {
            const ctrl = this;
            const config = {
                size: 230,
                clipWidth: 230,
                clipHeight: 150,
                ringInset: 10,
                ringWidth: 18,
                pointerWidth: 12,
                pointerTailLength: 4,
                pointerHeadLengthPercent: 0.99,
                minValue: 0,
                maxValue: 100,
                minAngle: -90,
                maxAngle: 90,
                transitionMs: 750,
                labelFormat: d3.format(',g'),
                labelInset: 5,
            };
            const minSize = 210;
            let topShift = 20; // the chart should render on the bottom of the container rather than the middle
            let smallerThanMinSize = false;
            let range;
            let radius;
            let pointerHeadLength;
            let arc;
            let scale;
            let pointer;
            let thresholds;
            let resetDebounced;

            ctrl.$onInit = $onInit;
            ctrl.$onChanges = $onChanges;
            ctrl.config = config;
            ctrl.digitFontSize = 50;

            function $onInit() {
                calculate();
                configure();

                if (config.clipHeight) {
                    d3.select($element.find('.gauge-area')[0])
                        .append('svg:svg')
                        .attr(
                            'class',
                            'gauge' + (smallerThanMinSize ? ' smaller-than-minimum' : '')
                        )
                        .attr('width', config.clipWidth)
                        .attr('height', config.clipHeight);
                    render(ctrl.latestValue);
                }
            }

            function $onChanges(changesObj) {
                const { latestValue, chartTitle, hideTimestamp } = changesObj;

                if (changesObj && pointer && latestValue && latestValue !== '-') {
                    update(ctrl.latestValue);
                }

                if (chartTitle) {
                    const needsUpdate =
                        (chartTitle.previousValue === '' && chartTitle.currentValue) ||
                        (typeof chartTitle.previousValue === 'string' &&
                            chartTitle.currentValue === '');
                    if (needsUpdate) {
                        resetWithTimer();
                    }
                }

                if (
                    hideTimestamp &&
                    typeof hideTimestamp.previousValue === 'boolean' &&
                    hideTimestamp.previousValue !== hideTimestamp.currentValue
                ) {
                    resetWithTimer();
                }
            }

            $scope.$watch('$ctrl.colorByValueScale', resetWithTimer, true);
            $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, () => {
                const gaugeArea = $element.find('.gauge-area');
                const currentWidth = gaugeArea.width();
                const currentHeight = gaugeArea.height();
                if (currentWidth !== config.clipWidth || currentHeight !== config.clipHeight) {
                    $timeout(resetWithTimer, 500);
                }
            });

            function calculate() {
                thresholds = [];
                if (ctrl.colorByValueScale && ctrl.colorByValueScale.length) {
                    thresholds = ctrl.colorByValueScale.map((rule) => rule.gt || rule.gte || 0);
                }
                config.maxValue = 100;
                if (ctrl.colorByValueScale[0] && ctrl.colorByValueScale[0].lte) {
                    config.maxValue = ctrl.colorByValueScale[0].lte;
                }
                const lastThreshold = ctrl.colorByValueScale[ctrl.colorByValueScale.length - 1];
                config.minValue = 0;
                if (lastThreshold && lastThreshold.gte) {
                    config.minValue = lastThreshold.gte;
                }
                thresholds.push(config.maxValue);
                thresholds.sort((a, b) => a - b);

                const gaugeArea = $element.find('.gauge-area');
                config.clipWidth = gaugeArea.width();
                config.clipHeight = gaugeArea.height();
                config.size = config.clipHeight * 2 - 4 * config.ringInset;
                smallerThanMinSize = false;
                if (config.clipWidth < minSize && !ctrl.mini) {
                    config.size = minSize;
                    smallerThanMinSize = true;
                } else if (config.size > config.clipWidth) {
                    config.size = config.clipWidth - 2 * config.ringInset;
                }

                // square root ratio. x^1/2 / 18 = y^1/2 / size;
                config.ringWidth = Math.pow((Math.pow(config.size, 1 / 2) / 64.3) * 18, 2);
                config.pointerWidth = Math.pow((Math.pow(config.size, 1 / 2) / 52.5) * 12, 2);
                if (ctrl.hasDescription || ctrl.mini) {
                    topShift = 0;
                }

                if (ctrl.mini) {
                    config.size = 70;
                    config.ringInset = 5;
                    config.ringWidth = 5;
                    config.pointerWidth = 3;
                }
            }

            function resetWithTimer() {
                if (resetDebounced) {
                    $timeout.cancel(resetDebounced);
                }
                resetDebounced = $timeout(reset, 500);
            }

            function reset() {
                d3.select($element.find('.gauge-area svg')[0]).remove();
                $onInit();
                resetDebounced = null;
            }

            function configure() {
                range = config.maxAngle - config.minAngle;
                // the container is a rectangle, meaning radius needs to be smaller
                // than the size / 2. This is so that the numeric tics can fit
                // inside the gauge container
                radius = config.size / 2 - 5;
                pointerHeadLength = Math.round(radius * config.pointerHeadLengthPercent);
                scale = d3.scale.linear().range([0, 1]).domain([config.minValue, config.maxValue]);

                let previousEndAngle = config.minAngle;
                arc = d3.svg
                    .arc()
                    .innerRadius(radius - config.ringWidth - config.ringInset)
                    .outerRadius(radius - config.ringInset)
                    .startAngle(function () {
                        return deg2rad(previousEndAngle);
                    })
                    .endAngle(function (d) {
                        previousEndAngle = scale(d) * range + config.minAngle;
                        return deg2rad(previousEndAngle);
                    });
            }

            function render(newValue) {
                const svg = d3.select($element.find('.gauge-area svg')[0]);
                const centerTx = centerTranslation();

                // draw the ticks for each thresholds
                if (!ctrl.mini) {
                    const ticks = svg
                        .append('g')
                        .attr('class', 'label-line')
                        .attr('transform', centerTx);
                    ticks
                        .selectAll('line')
                        .data(thresholds)
                        .enter()
                        .append('line')
                        .attr('y1', 0)
                        .attr('y2', 10)
                        .attr('transform', function (d) {
                            return 'rotate(' + newAngle(d) + ') translate(0,' + -1 * radius + ')';
                        });
                }

                // draw the arc for each threshold.
                const arcs = svg.append('g').attr('class', 'arc').attr('transform', centerTx);
                arcs.selectAll('path')
                    .data(thresholds)
                    .enter()
                    .append('path')
                    .attr('fill', function (d) {
                        // color in by the upper bound threshold
                        return colorByValueService.getColorForUpperBound(ctrl.colorByValueScale, d);
                    })
                    .attr('d', arc);

                if (!ctrl.mini) {
                    // draw the number labels in respective places by translate
                    const lg = svg
                        .append('g')
                        .attr('class', 'gauge-label')
                        .attr(
                            'transform',
                            'translate(' +
                                (config.clipWidth / 2 - 5) +
                                ',' +
                                (radius + topShift) +
                                ')'
                        );
                    lg.selectAll('text')
                        .data(thresholds)
                        .enter()
                        .append('text')
                        .attr('transform', function (d) {
                            const angleInRadians = deg2rad(scale(d) * range);
                            const newRadius = radius + config.labelInset;
                            let radiusX = newRadius;
                            if (angleInRadians < Math.PI / 2) {
                                radiusX = newRadius + config.labelFormat(d).length * 6 - 9;
                            }
                            // x = r * cos (angle) , y = r * sin (angle)
                            // this is so that the label remains upright instead of rotating around the arc
                            return (
                                'translate(' +
                                -1 * radiusX * Math.cos(angleInRadians) +
                                ',' +
                                -1 * newRadius * Math.sin(angleInRadians) +
                                ')'
                            );
                        })
                        .text(config.labelFormat);
                }

                // linear gradient for the pointer
                const svgDefs = svg.append('defs');
                const gradient = svgDefs
                    .append('linearGradient')
                    .attr('id', 'pointerGradient')
                    .attr('x1', '0%')
                    .attr('x2', '0%')
                    .attr('y1', '0%')
                    .attr('y2', '100%');
                gradient.append('stop').attr('class', 'pointer-stop-1').attr('offset', '0');
                gradient.append('stop').attr('class', 'pointer-stop-2').attr('offset', '0.4');
                gradient.append('stop').attr('class', 'pointer-stop-3').attr('offset', '0.41');
                gradient.append('stop').attr('class', 'pointer-stop-4').attr('offset', '1');

                // draw the pointer
                const lineData = [
                    [config.pointerWidth / 2, 0],
                    [0, -pointerHeadLength],
                    [-(config.pointerWidth / 2), 0],
                    [0, config.pointerTailLength],
                    [config.pointerWidth / 2, 0],
                ];
                const pointerLine = d3.svg.line().interpolate('monotone');
                const pg = svg
                    .append('g')
                    .data([lineData])
                    .attr('class', 'pointer')
                    .attr('transform', centerTx);

                pointer = pg
                    .append('path')
                    .attr('d', pointerLine)
                    .attr('fill', 'url(#pointerGradient)')
                    .attr('transform', 'rotate(' + config.minAngle + ')');

                update(newValue);

                const isNarrow = $element.closest('.min-width-gridster').length;
                const topAdjustment = isNarrow || smallerThanMinSize ? 15 : 1;
                ctrl.valueTop =
                    config.size / 2 - ctrl.digitFontSize - topAdjustment + topShift / 2 + 'px';
                // Use the smaller between the width of the chart, for when there is no
                // visible gauge, and width of the space inside the gauge
                ctrl.suffixWidth = Math.min(
                    config.clipWidth,
                    config.size - 2 * (config.ringWidth + config.ringInset)
                );
            }

            function update(newValue) {
                if (newValue === undefined || newValue === null) {
                    ctrl.formattedValue = '-';
                    newValue = config.minValue;
                    let pointerToUpdate = pointer;
                    if (!ctrl.disableAnimations) {
                        pointerToUpdate = pointerToUpdate
                            .transition()
                            .duration(config.transitionMs)
                            .ease('elastic');
                    }
                    pointerToUpdate.attr('transform', 'rotate(-90)');
                    return;
                }

                let pointerAngle = newAngle(newValue);
                if (newValue > config.maxValue) {
                    pointerAngle = config.maxAngle + 2;
                } else if (newValue < config.minValue) {
                    pointerAngle = config.minAngle - 2;
                }

                let pointerToUpdate = pointer;
                if (!ctrl.disableAnimations) {
                    pointerToUpdate = pointerToUpdate
                        .transition()
                        .duration(config.transitionMs)
                        .ease('elastic');
                }
                pointerToUpdate.attr('transform', 'rotate(' + pointerAngle + ')');

                let maxDigitsAllowed = Math.max(
                    1,
                    Math.floor(
                        smallerThanMinSize
                            ? config.clipWidth / ctrl.digitFontSize
                            : config.size / ctrl.digitFontSize
                    )
                );
                if (newValue > Math.pow(10, maxDigitsAllowed) || newValue < 1) {
                    // if value exceeds the max digits allowed, suffix gets added to the number indicating million or thousand
                    // if value is less than 1, the digit count increases because of '0.' that gets added
                    maxDigitsAllowed -= 1;
                }

                if (ctrl.maxDecimalPlaces) {
                    maxDigitsAllowed = Math.min(maxDigitsAllowed, ctrl.maxDecimalPlaces);
                }

                ctrl.formattedValue = ctrl.plotUnit
                    ? valueFormatter.formatScalingUnit(newValue, ctrl.plotUnit, maxDigitsAllowed)
                    : valueFormatter.formatValue(newValue, maxDigitsAllowed, ctrl.useKmg2);
            }

            resetWithTimer();

            // helper functions

            function deg2rad(deg) {
                return (deg * Math.PI) / 180;
            }

            function newAngle(d) {
                const ratio = scale(d);
                const newAngle = config.minAngle + ratio * range;
                return newAngle;
            }

            function centerTranslation() {
                return 'translate(' + config.clipWidth / 2 + ',' + (radius + topShift) + ')';
            }
        },
    ],
};
