import './login/module';

import security from './security';
import securityRetryQueue from './securityRetryQueue';
import securityInterceptor from './securityInterceptor';
import headerInterceptor from './headerInterceptor';
import signalviewRestScope from './signalviewRestScopeProvider';
import uiBootstrap from 'angular-ui-bootstrap';
import sdContentCreationAuth from './sdContentCreationAuth';

angular
    .module('sfx.security', [
        'sfx.security.login', // Contains the login form template and controller
        uiBootstrap, // Used to display the login form as a modal dialog.
        'support.supportService',
        'signalview.timeservice',
        'restangular',
        'signalboost',
        'support.supportService',
        'sfx.data',
    ])
    .factory('security', security)
    .factory('securityRetryQueue', securityRetryQueue)
    .factory('securityInterceptor', securityInterceptor)
    .factory('headerInterceptor', headerInterceptor)
    .factory('sdContentCreationAuth', sdContentCreationAuth)
    .provider('signalviewRestScope', signalviewRestScope)
    .config([
        '$httpProvider',
        'RestangularProvider',
        'timeserviceProvider',
        function ($httpProvider, restProvider, timeservice) {
            // We have to add the interceptor to the queue as a string because the interceptor depends upon service instances that are not available in the config block.
            let gotSkew = false;

            // declare-used-dependency-to-linter::headerInterceptor
            $httpProvider.interceptors.push('headerInterceptor');

            restProvider.setResponseInterceptor(function (data, operation, what, url, response) {
                try {
                    if (!gotSkew && response.headers('Date') !== null) {
                        // giving it a 50/50 on this working.  better double check when date becomes accessible
                        timeservice
                            .$get()
                            .setSkew(Date.now() - new Date(response.headers('Date')).getTime());
                        gotSkew = true;
                    }
                } catch (e) {
                    console.log('Could not determine a server skew, assuming zero.');
                }
                return data;
            });

            restProvider.setResponseExtractor(function (response) {
                const newResponse = response;
                // using _originalElement so we can support originalElement as a valid property
                if (angular.isArray(response)) {
                    angular.forEach(newResponse, function (value, key) {
                        if (angular.isObject(newResponse[key])) {
                            newResponse[key]._originalElement = angular.copy(value);
                        }
                    });
                } else {
                    if (angular.isObject(newResponse)) {
                        newResponse._originalElement = angular.copy(response);
                    }
                }
                return newResponse;
            });
        },
    ])
    .run([
        'security',
        'securityRetryQueue',
        'Restangular',
        'signalviewRestScope',
        '$rootScope',
        function (service, queue, restProvider, restHook, $rootScope) {
            $rootScope.$on('migrated services initialized', function () {
                restProvider.setFullRequestInterceptor(function (
                    element,
                    operation,
                    route,
                    url,
                    headers,
                    params,
                    httpConfig
                ) {
                    return restHook.scopeRequest(
                        element,
                        operation,
                        route,
                        url,
                        headers,
                        params,
                        httpConfig
                    );
                });
            });
            // Register a handler for when an item is added to the retry queue
            queue.onItemAddedCallbacks.push(function () {
                if (queue.hasMore()) {
                    service.showLogin();
                }
            });
        },
    ]);
