const FETCH_SUGGESTION_PATH = '/v2/signalflow/_/getDetectorTypeForQueries';

angular.module('signalboost').factory('signalflowV2Service', [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        return {
            fetchDetectorTypeRecommendation,
        };

        function fetchDetectorTypeRecommendation(programText) {
            return $http({
                method: 'POST',
                url: API_URL + FETCH_SUGGESTION_PATH,
                data: programText,
                headers: { 'Content-Type': 'text/plain' },
            }).then((response) => response.data);
        }
    },
]);
