import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AUTH_SCOPES } from './ingestTokenConstants';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Switch from '@splunk/react-ui/Switch';
import Multiselect from '@splunk/react-ui/Multiselect';
import { variables } from '@splunk/themes';
import MultipleScopedTokenWarning from './multipleScopedTokenWarning';

const StyledAuthScopeApi = styled.div`
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
`;

const StyledMultiselect = styled(Multiselect)`
    font-size: ${variables.fontSizeSmall};
    line-height: 16px;
    width: 430px !important;
    button {
        svg {
            width: ${variables.spacingMedium};
            height: ${variables.spacingMedium};
        }
    }
    input {
        height: ${variables.spacingXLarge};
    }
`;

const StyledSwitch = styled(Switch)`
    width: fit-content;
`;

export default function AuthScopeCheckboxes({
    orgHasRum,
    handleClick,
    authScopes,
    handleWarningClick,
    multiScopeWarning,
    selectedRoleValues,
    roleOptions,
    handleRoleChange,
    newRbacExperienceEnabled,
}) {
    const showWarning = authScopes[AUTH_SCOPES.INGEST] && authScopes[AUTH_SCOPES.API];
    const isRoleLoading = roleOptions.length === 0;
    return (
        <div>
            <ControlGroup label="Authorization Scope" labelPosition="top" controlsLayout="none">
                {orgHasRum && (
                    <StyledSwitch
                        key={AUTH_SCOPES.RUM}
                        value={AUTH_SCOPES.RUM}
                        appearance="checkbox"
                        selected={authScopes[AUTH_SCOPES.RUM]}
                        onClick={handleClick}
                        inline={false}
                    >
                        Rum Token
                    </StyledSwitch>
                )}
                <StyledSwitch
                    key={AUTH_SCOPES.INGEST}
                    value={AUTH_SCOPES.INGEST}
                    appearance="checkbox"
                    selected={authScopes[AUTH_SCOPES.INGEST]}
                    onClick={handleClick}
                    disabled={authScopes[AUTH_SCOPES.RUM]}
                    inline={false}
                >
                    Ingest Token
                </StyledSwitch>
                <StyledAuthScopeApi>
                    <StyledSwitch
                        key={AUTH_SCOPES.API}
                        value={AUTH_SCOPES.API}
                        appearance="checkbox"
                        selected={authScopes[AUTH_SCOPES.API]}
                        onClick={handleClick}
                        disabled={authScopes[AUTH_SCOPES.RUM]}
                        inline={false}
                    >
                        {newRbacExperienceEnabled ? 'API Token with roles :' : 'Api Token'}
                    </StyledSwitch>
                    {newRbacExperienceEnabled && (
                        <StyledMultiselect
                            placeholder="Select which roles to grant to the Access Token"
                            values={selectedRoleValues}
                            onChange={handleRoleChange}
                            isLoadingOptions={isRoleLoading}
                            loadingMessage="Loading..."
                            disabled={!authScopes[AUTH_SCOPES.API] || authScopes[AUTH_SCOPES.RUM]}
                            inline
                        >
                            {roleOptions}
                        </StyledMultiselect>
                    )}
                </StyledAuthScopeApi>
            </ControlGroup>
            {showWarning && (
                <MultipleScopedTokenWarning
                    hasAccepted={multiScopeWarning}
                    handleClick={handleWarningClick}
                />
            )}
        </div>
    );
}

AuthScopeCheckboxes.propTypes = {
    orgHasRum: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    authScopes: PropTypes.object.isRequired,
    handleWarningClick: PropTypes.func.isRequired,
    multiScopeWarning: PropTypes.bool.isRequired,
    selectedRoleValues: PropTypes.array.isRequired,
    roleOptions: PropTypes.array.isRequired,
    handleRoleChange: PropTypes.func.isRequired,
    newRbacExperienceEnabled: PropTypes.bool.isRequired,
};
