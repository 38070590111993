import { CategoryLimit, CategoryLimits } from './categoryLimits';

export enum SubscriptionType {
    Hosts = 'Hosts',
    MTS = 'MTS',
    Legacy = 'Legacy',
}

export enum ReportName {
    HOST = 'HOST',
    MTS = 'MTS',
    DPM = 'DPM',
}

export const SubscriptionTypeToReport = {
    [SubscriptionType.Hosts]: ReportName.HOST,
    [SubscriptionType.MTS]: ReportName.DPM,
    [SubscriptionType.Legacy]: ReportName.DPM,
};

export function isHostBased(
    subscriptionType: SubscriptionType
): subscriptionType is SubscriptionType.Hosts {
    return subscriptionType === SubscriptionType.Hosts;
}

export function isCustomMts(
    subscriptionType: SubscriptionType
): subscriptionType is SubscriptionType.MTS {
    return subscriptionType === SubscriptionType.MTS;
}

export function isNonLegacy(subscriptionType: SubscriptionType): boolean {
    return isHostBased(subscriptionType) || isCustomMts(subscriptionType);
}

export function getCategoriesBySubscription(subscriptionType: SubscriptionType): CategoryLimit[] {
    if (isNonLegacy(subscriptionType)) {
        return CategoryLimits.filter((category) =>
            category.plans.some((plan) => plan === subscriptionType)
        );
    }
    return [];
}

export default {
    isHostBased,
    isCustomMts,
    isNonLegacy,
    getCategoriesBySubscription,
    SUBSCRIPTION_TYPE_TO_REPORT_MAPPING: SubscriptionTypeToReport,
};
