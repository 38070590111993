import React from 'react';
import PropTypes from 'prop-types';

import SimpleSpec from './nextSteps/components/SimpleSpec';

const EVENT_RECIPIENTS = 'Recipients';

const types = {
    pagerduty: CredentialRecipient,
    victorops: CredentialRecipient,
    slack: SlackRecipient,
    email: EmailRecipient,
    webhook: CredentialRecipient,
    xmatters: CredentialRecipient,
    team: TeamRecipient,
    // teamEmail,   /* Not sure how to implement this and we don't have a reference example */
    servicenow: CredentialRecipient,
    bigpanda: CredentialRecipient,
    office365: CredentialRecipient,
    opsgenie: CredentialRecipient,
    amazoneventbridge: CredentialRecipient,
    jira: CredentialRecipient,
};

function SlackRecipient(props) {
    const { recipient, title } = props;
    const { credentialName, channel } = recipient;

    if (!credentialName) {
        return null;
    }

    return `${title}: ${recipient.credentialName} (${channel})`;
}

function CredentialRecipient(props) {
    const { recipient, title } = props;
    const { credentialName } = recipient;

    if (!credentialName) {
        return null;
    }

    return `${title}: ${recipient.credentialName}`;
}

function EmailRecipient(props) {
    const { recipient } = props;
    const { email } = recipient;

    if (!email) {
        return null;
    }

    return recipient.email;
}

function TeamRecipient(props) {
    const { recipient, title } = props;
    const { teamName } = recipient;

    if (!teamName) {
        return null;
    }

    return `${title}: ${recipient.teamName}`;
}

export function NotificationRecipients(props) {
    const { recipients } = props;

    if (!recipients || recipients.length === 0) {
        return null;
    }

    const renderedRecipients = [];
    recipients.forEach((recipient, index) => {
        if (!types[recipient.type]) {
            return null;
        }

        const Component = types[recipient.type];
        const renderedRecipient = (
            <span>
                <Component recipient={recipient} title={recipient.title} />
            </span>
        );
        // Add a divider before each recipient except the first one
        if (renderedRecipient && index !== 0) {
            renderedRecipients.push(<span> | </span>);
        }
        renderedRecipients.push(renderedRecipient);
    });

    return <SimpleSpec label={EVENT_RECIPIENTS} value={<>{renderedRecipients}</>} />;
}

NotificationRecipients.propTypes = {
    recipients: PropTypes.array,
};
