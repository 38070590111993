'use strict';
import templateUrl from './userBadge.html';

angular.module('sfx.ui').component('userBadge', {
    templateUrl,
    bindings: {
        user: '<?',
        name: '<?',
        email: '<?',
        id: '<?',
        format: '@',
    },
    controller: [
        function () {
            const ctrl = this;

            ctrl.$onInit = $onInit;
            ctrl.$profilePage = '#/userprofile/';

            function $onInit() {
                if (!ctrl.user) {
                    ctrl.user = {
                        sf_user: ctrl.name,
                        sf_email: ctrl.email,
                        sf_id: ctrl.id,
                    };
                }
            }
        },
    ],
});
