export const sfActivate = [
    '$parse',
    function ($parse) {
        return function (scope, element, attrs) {
            const fn = $parse(attrs.sfActivate);
            const handler = function (event) {
                scope.$apply(fn, { event: event });
            };
            if (!element.attr('tabindex')) {
                element.attr('tabindex', 0);
            }
            element.bind('click', handler);
            element.bind('keyup', function (event) {
                if (event.keyCode === 32) {
                    handler(event);
                }
            });
        };
    },
];
