import samlTemplateUrl from '../../../app/saml/saml.tpl.html';
import { memoize } from 'lodash';
import logger from '../../../common/logger';

const log = logger('SAML');
const error = log.extend('error');

/**
 * Validates and Exchanges a SAML token for a user token
 */
function validateAndExchangeToken(token, { security, signalboost, logService }) {
    function respond() {
        if (!token) {
            log('No saml token.');
            return Promise.reject(null);
        } else {
            logService.logData(
                'INFO',
                'saml.js -> validateAndExchangeToken: exhcanging SAML token',
                token
            );
            return signalboost.user.exchangeSaml(token);
        }
    }
    logService.logData(
        'INFO',
        'saml.js -> validateAndExchangeToken: logging out and clearing the token'
    );

    // remove current auth token, regardless of saml token validity
    return security.logout().then(respond, respond);
}

/**
 * ## Exchanges a SAML token for a User token (once only)
 * Token exchange is followed by redirecting user to correct location.
 *
 * Successive call with same SAML token will return the
 * response of very first call.
 */
const processSamlTokenOnce = memoize(function (
    token,
    { $rootScope, $window, $location, security, signalboost, loginService, ssoRedirect, logService }
) {
    const split = token.split(':::');
    let url = '';
    let actualToken = token;
    if (split !== undefined && split.length > 1) {
        actualToken = split[0];
        url = split[1].replace(/-/g, '+').replace(/_/g, '/').replace(/=/g, '');
    }
    return validateAndExchangeToken(actualToken, { security, signalboost, logService }).then(
        function (result) {
            if (result && result.redirectUrl) {
                logService.logData(
                    'INFO',
                    'saml.js -> validateAndExchangeToken: received token from api',
                    result
                );
                $window.location = result.redirectUrl;
            } else {
                loginService.initOrgAndUserPostLogin().then(function ({ user }) {
                    $rootScope.$broadcast('current user changed', user);
                    if (!!url) {
                        logService.logData(
                            'INFO',
                            'saml.js -> validateAndExchangeToken -> initOrgAndUserPostLogin: received url from token',
                            url
                        );
                        $window.location = atob(url);
                    } else {
                        url = ssoRedirect.getAndClear();
                        logService.logData(
                            'INFO',
                            'saml.js -> validateAndExchangeToken -> initOrgAndUserPostLogin: received redirect url from ssoRedirect',
                            url
                        );
                        if (!!url) {
                            logService.logData(
                                'INFO',
                                'saml.js -> validateAndExchangeToken -> initOrgAndUserPostLogin: no url received from ssoRedirect',
                                url
                            );
                            $location.url(url);
                        } else {
                            $location.path('/home');
                        }
                    }
                });
            }
        },
        function (e) {
            logService.logData(
                'ERROR',
                'saml.js -> validateAndExchangeToken -> initOrgAndUserPostLogin: error while processing token',
                e
            );
            $window.alert('Error processing SAML token. Please contact your administrator', e);
            error('Error exchanging saml token.', e);
            $location.path('/signin');
        }
    );
});

const SamlTokenController = [
    '$rootScope',
    '$window',
    '$location',
    '$scope',
    'security',
    'signalboost',
    'loginService',
    'ssoRedirect',
    'logService',
    function (
        $rootScope,
        $window,
        $location,
        $scope,
        security,
        signalboost,
        loginService,
        ssoRedirect,
        logService
    ) {
        const $ctrl = this;

        $scope.processSamlToken = (token) =>
            processSamlTokenOnce(token, {
                $rootScope,
                $window,
                $location,
                security,
                signalboost,
                loginService,
                ssoRedirect,
                logService,
            });

        $ctrl.$onInit = () => {
            $scope.processSamlToken($ctrl.samlToken).finally(() => {
                $scope.$applyAsync();
            });
        };
    },
];

// there is no ui for the saml token exchange
// it's just a redirect to user's homepage
// with an intermediate step of exchanging
// the saml token for an access token
export default {
    bindings: { samlToken: '<' },
    templateUrl: samlTemplateUrl,
    controller: SamlTokenController,
};
