angular
    .module('signalboost')

    .service('UserRestService', [
        '$http',
        'currentUser',
        'dashboardGroupService',
        'dashboardV2Service',
        'signalboost',
        'signalboostUtil',
        'SignalboostBaseService',
        'API_URL',
        function (
            $http,
            currentUser,
            dashboardGroupService,
            dashboardV2Service,
            signalboost,
            signalboostUtil,
            SignalboostBaseService,
            API_URL
        ) {
            class UserRestService extends SignalboostBaseService {
                constructor() {
                    super('user', true);
                    this.cachePromise = null;
                    this.cachedDefer = null;
                }
                clearCache() {
                    this.cachePromise = null;
                    if (this.cacheDefer) {
                        this.cacheDefer.reject('UserService cache cleared');
                        this.cacheDefer = null;
                    }
                }

                getDefaultPageId() {
                    return currentUser.orgPreferences().then((prefs) => {
                        return prefs.sf_preferredPage;
                    });
                }
                getDefaultPage() {
                    return this.getDefaultPageId().then(function (pageId) {
                        return dashboardGroupService.get(pageId);
                    });
                }
                getAllDashboardMembers() {
                    return this.getDefaultPage().then((page) =>
                        dashboardGroupService.getDashboards(page.id)
                    );
                }
                getDefaultDashboard() {
                    return this.getAllDashboardMembers().then(function (allDashboards) {
                        //for now, first dashboard is default.
                        if (allDashboards.length === 0) {
                            return null;
                        }
                        return dashboardV2Service.get(allDashboards[0].id);
                    });
                }
                search(token) {
                    const params = {};
                    params.query = token;
                    return signalboost.user
                        .all()
                        .customGET('', params)
                        .then(function (data) {
                            return data.rs.sort(signalboostUtil.sfUserComparator);
                        });
                }
                setNewUserPassword(fullName, email, token, password) {
                    return $http.put(API_URL + '/v2/password', {
                        token: token,
                        email: email,
                        password: password,
                        fullName: fullName,
                    });
                }
                resetPassword(email, token, password) {
                    return $http.put(API_URL + '/v2/password', {
                        token: token,
                        email: email,
                        password: password,
                    });
                }
                updatePassword(oldPassword, newPassword) {
                    return $http.post(API_URL + '/v2/password/update', {
                        oldPassword: oldPassword,
                        newPassword: newPassword,
                    });
                }
                validatePasswordResetToken(token) {
                    return $http.get(API_URL + `/v2/password/${token}`).then(function (resp) {
                        const session = resp.data;
                        if (session && session.sessionType === 'RESET') {
                            return session;
                        }
                        return null;
                    });
                }
                requestPasswordReset(email) {
                    return $http.post(API_URL + '/v2/password/reset', { email: email });
                }
                exchangeSaml(token) {
                    return this.all().all('saml').all('exchange').post({
                        token: token,
                    });
                }
                features() {
                    return this.all().all('features').get();
                }
            }

            return UserRestService;
        },
    ]);
