angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Top 5-min Load Avg')
                .mode('list')
                .type('line')
                .maxDecimalPlaces(3)
                .updateInterval(5000)
                .sort('-value');

            chart
                .plot()
                .metric('load.midterm')
                .name('')
                .propertyFilters(filters)
                .addManipulation(blockService.topn.asAggregation([], { count: 5 }))
                .addManipulation(blockService.mean.asAggregation([{ value: 'host' }]));

            return chart;
        }

        chartTemplates.register('collectdHostsTop5MinLoad', createChart);
    },
]);
