import templateUrl from './analyticsPipeline.tpl.html';

angular.module('signalview.chartbuilder').directive('analyticsPipeline', [
    'analyticsService',
    '$timeout',
    function (analyticsService, $timeout) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // Array of DataManipulation objects defining the analytics pipeline
                pipeline: '=',
                // Determines whether this analytics pipeline is editable via this widget
                editable: '=',
                // Used to acquire grouping filters in analytics blocks
                getCurrentQuery: '=',
                // Toggles an alert when a counter metric is detected. Poorly abstracted
                // need to rethink how this one gets wired in.
                isCounter: '=',
                plot: '=',
                // This is the timezone from chart options for calendar
                // window transformations
                calendarWindowTimezone: '=',
                // Get the time range the chart is currently viewing
                getCurrentTimeRange: '<?',
                isDetectorPlot: '<?',
            },
            link: function ($scope, elem) {
                $scope.getBlockName = function (block) {
                    return analyticsService.createDataManipulationName(block);
                };

                $scope.getToolTip = function (block) {
                    return analyticsService.getToolTip(block);
                };

                $scope.removeBlock = function (block) {
                    let index = -1;
                    $scope.pipeline.some(function (candidate, candidateIndex) {
                        if (candidate === block) {
                            index = candidateIndex;
                            return true;
                        }
                    });

                    $scope.pipeline.splice(index, 1);
                };

                // Toggle between button to add analytics and its input
                $scope.showAnalyticsInput = function () {
                    const analyticsButton = elem.find('.plot-analytics-button');
                    analyticsButton.addClass('sf-hidden');
                    const analyticsInput = elem.find('sfx-analytics-selector');
                    analyticsInput
                        .removeClass('sf-invisible')
                        .find('.analytics-input')
                        .focus()
                        .on('blur.analytics', function () {
                            analyticsInput.addClass('sf-invisible');
                            analyticsButton.removeClass('sf-hidden');
                            $scope.$digest();
                        });
                };

                $scope.clickOnEnter = function (evt) {
                    if (evt.keyCode !== 13) {
                        return;
                    }
                    $timeout(function () {
                        evt.target.click();
                    });
                };

                $scope.$watchCollection('plot.dataManipulations', function (newAnalytics) {
                    const analyticsButton = elem.find('.plot-analytics-button');
                    if (newAnalytics && newAnalytics.length) {
                        analyticsButton.text('+');
                    } else {
                        analyticsButton.text('Add analytics');
                    }
                });
            },
        };
    },
]);
