export const asyncScriptLoader = [
    '$timeout',
    '$document',
    '$q',
    function ($timeout, $document, $q) {
        const DEFAULT_MAX_WAIT_MS = 30000; // 30s
        const CHECK_INTERVAL_MS = 200;
        const loadingUrlPromiseMap = {};

        return {
            get: function (url, checkFn, maxWait) {
                let initializationTime = null;
                maxWait = maxWait || DEFAULT_MAX_WAIT_MS;

                function checkScript() {
                    $timeout(function () {
                        const result = checkFn();
                        if (result) {
                            loadingUrlPromiseMap[url].resolve(result);
                        } else if (Date.now() - initializationTime < maxWait) {
                            checkScript();
                        } else {
                            loadingUrlPromiseMap[url].reject(
                                'Timed out waiting for Script to load!'
                            );
                        }
                    }, CHECK_INTERVAL_MS);
                }

                if (!loadingUrlPromiseMap[url]) {
                    loadingUrlPromiseMap[url] = $q.defer();
                    initializationTime = Date.now();

                    const script = $document[0].createElement('script');
                    script.setAttribute('type', 'text/javascript');
                    script.setAttribute('src', url);
                    $document[0].body.appendChild(script);
                    checkScript();
                }

                return loadingUrlPromiseMap[url].promise;
            },
        };
    },
];
