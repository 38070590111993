export default [
    '$rootScope',
    '$location',
    function ($rootScope, $location) {
        let previousState = angular.copy($location.search());
        const watchersByParameter = {};

        function coerceParamToString(p) {
            if (angular.isArray(p)) {
                return p.join('');
            }
            return p;
        }

        function compareStates(left, right, changeSet) {
            Object.keys(left).forEach(function (param) {
                const currentValue = left[param];
                const prevValue = right[param];
                if (
                    (!currentValue && prevValue) || // value was removed
                    (currentValue && !prevValue) || // value was added
                    (currentValue &&
                        prevValue &&
                        coerceParamToString(currentValue) !== coerceParamToString(prevValue)) //value changed
                ) {
                    changeSet[param] = true;
                }
            });
        }

        $rootScope.$on('React:$routeUpdate', function () {
            const currentLocation = window.location.href;
            const parametersChanged = {};
            const currentState = $location.search();
            compareStates(currentState, previousState, parametersChanged);
            compareStates(previousState, currentState, parametersChanged);

            const callbacksNeeded = [];

            Object.keys(parametersChanged).forEach(function (param) {
                if (watchersByParameter[param]) {
                    watchersByParameter[param].forEach(function (cb) {
                        if (callbacksNeeded.indexOf(cb) === -1) {
                            // inefficient for reducing a watchgroup down to a single call, but works for now
                            callbacksNeeded.push(cb);
                        }
                    });
                }
            });

            callbacksNeeded.forEach(function (cb) {
                cb(currentLocation, parametersChanged);
            });

            previousState = angular.copy(currentState);
        });

        function unRegisterWatch(unregisterFn) {
            //this is pretty inefficient but it generally wont be a lot to iterate through.
            angular.forEach(watchersByParameter, function (fnArr, paramName) {
                watchersByParameter[paramName] = watchersByParameter[paramName].filter(function (
                    fn
                ) {
                    return fn !== unregisterFn;
                });
            });
        }

        function getRouteUnwatcher(fn) {
            return function () {
                unRegisterWatch(fn);
            };
        }

        function upsertParam(paramName) {
            if (!watchersByParameter[paramName]) {
                watchersByParameter[paramName] = [];
            }
        }

        function registerRouteWatch(paramName, callback) {
            return registerRouteWatchGroup([paramName], callback);
        }

        function registerRouteWatchGroup(paramNames, callback) {
            paramNames.forEach(function (paramName) {
                upsertParam(paramName);
                watchersByParameter[paramName].push(callback);
            });
            return getRouteUnwatcher(callback);
        }

        return {
            registerRouteWatch: registerRouteWatch,
            registerRouteWatchGroup: registerRouteWatchGroup,
        };
    },
];
