angular.module('signalview.analytics').factory('analyticsSuggester', [
    'blockService',
    function (blockService) {
        return function (q, dataManipulations) {
            q = q.trim();

            if (q.indexOf(':') === -1) {
                return blockService
                    .all()
                    .filter(function (fn) {
                        // If the function name doesn't match the query don't include it
                        if (fn.toString().toLowerCase().indexOf(q.toLowerCase()) === -1) {
                            return false;
                        }

                        // Only allow TIMESHIFT to be used if none of the existing manipulations
                        // are timeshifts.
                        if (fn.getType() === 'TIMESHIFT') {
                            return dataManipulations.every(function (manip) {
                                return manip.fn.type !== 'TIMESHIFT';
                            });
                        }

                        return true;
                    })
                    .map(function (fn) {
                        return {
                            type: fn.getType(),
                            displayName: fn.getDisplayName(),
                        };
                    });
            } else {
                const splitResult = q.split(':');
                const fnName = splitResult[0];

                const targetFn = blockService.getByDisplayName(fnName);

                if (targetFn && !targetFn.isStandalone()) {
                    return [
                        { displayName: fnName + ':' + 'Aggregation', type: targetFn.getType() },
                        { displayName: fnName + ':' + 'Transformation', type: targetFn.getType() },
                    ];
                } else {
                    return [];
                }
            }
        };
    },
]);
