import templateUrl from './emailNotifyBlock.tpl.html';

angular.module('signalview.detector.notification').directive('emailNotifyBlock', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                remove: '&onRemove',
                readOnly: '=?',
                doneEdit: '&onFinishEdit',
            },
            templateUrl,
            link: function ($scope, element) {
                $scope.finishEdit = function () {
                    if ($scope.model.email) {
                        $scope.edit = false;
                    } else {
                        if ($scope.remove) $scope.remove();
                    }
                    if ($scope.doneEdit) $scope.doneEdit();
                };

                $scope.editMode = function () {
                    $scope.edit = true;

                    $timeout(function () {
                        element.find('input')[0].focus();
                    });
                };

                if (!$scope.model.email) {
                    $scope.editMode();
                }
            },
        };
    },
]);
