import templateUrl from './objectproperties.tpl.html';

angular.module('signalview.objectmanager').directive('objectProperties', [
    'currentUser',
    'v1ObjectService',
    'v2ObjectService',
    'objectService',
    'migratedSignalboost',
    'hasCapability',
    function (
        currentUser,
        v1ObjectService,
        v2ObjectService,
        objectService,
        migratedSignalboost,
        hasCapability
    ) {
        return {
            restrict: 'AE',
            scope: {
                selectedObjects: '=',
                isNewCatalog: '=',
                viewMode: '=',
            },
            templateUrl,
            link: function ($scope, element) {
                $scope.onPropertyNameKeypress = function (event) {
                    if (event && event.keyCode === 13) {
                        element.find('[name="propertyValue"]').focus();
                    }
                };

                $scope.onPropertyValueKeypress = function (event) {
                    if (event && event.keyCode === 13) {
                        $scope.createProperty($scope.prop);
                        $scope.focusPropertyName();
                    }
                };

                $scope.focusPropertyName = function () {
                    element.find('[name="propertyName"]').focus();
                };
            },
            controller: [
                '$scope',
                '$log',
                '$q',
                '$location',
                '$timeout',
                '$http',
                'API_URL',
                'catalogHelper',
                function ($scope, $log, $q, $location, $timeout, $http, API_URL, catalogHelper) {
                    $scope.properties = [];
                    $scope.prop = {
                        name: '',
                        value: '',
                    };

                    $scope.editMode = false;
                    $scope.addMode = false;

                    if ($scope.selectedObjects && $scope.selectedObjects.length > 0) {
                        const associatedCapability = catalogHelper.getCapabilityForType(
                            $scope.selectedObjects[0].sf_type
                        );
                        if (associatedCapability) {
                            hasCapability(associatedCapability).then(
                                (hasUpdateCapability) =>
                                    ($scope.hasUpdateCapability = hasUpdateCapability)
                            );
                        } else {
                            // catch-all for any other object types that might use this shared component
                            $scope.hasUpdateCapability = true;
                        }
                    }

                    $scope.enableEditMode = function () {
                        $scope.originalProperties = angular.copy($scope.properties);
                        $scope.editMode = !$scope.isMtsSelected();
                    };

                    $scope.isMtsSelected = function () {
                        return $scope.selectedObjects.some(
                            (object) => object.sf_type === 'MetricTimeSeries'
                        );
                    };

                    $scope.enableAddProperty = function () {
                        $scope.enableEditMode();
                        $timeout(function () {
                            $scope.focusPropertyName();
                        });
                    };

                    $scope.cancelEdit = function () {
                        $scope.editMode = false;
                        $scope.properties = $scope.originalProperties;
                    };

                    $scope.saveEdit = function () {
                        $scope.editMode = false;

                        if ($scope.prop.name.length > 1 && $scope.prop.value) {
                            $scope.createProperty($scope.prop);
                        }

                        commitDeletions();
                        commitUpdates();

                        $scope.originalProperties = $scope.properties;
                    };

                    $scope.showAllPropertyValues = function (property) {
                        currentUser.orgId().then(function (orgId) {
                            // Ensure that the user is directed to the catalog if they're not there
                            // already (if they're in a restricted view).
                            $location.path('/catalog/' + orgId);

                            const search = $location.search();
                            search.selectedKeyValue = `sf_key:${property.name}`;
                            delete search.select;
                            $location.search(search);
                        });
                    };

                    $scope.addToFilter = function (property) {
                        const query = property.name + ':' + property.value;

                        const search = $location.search();
                        const sources = search['sources[]'] || [];
                        sources.push(query);
                        search['sources[]'] = sources;
                        delete search.topicFilterKey;

                        search.selectedKeyValue = 'sf_key:host';

                        $location.search(search);
                    };

                    $scope.crosslinkActions = [];

                    $scope.viewPropertyValue = function (property) {
                        const search = $location.search();

                        search.selectedKeyValue = property.name + ':' + property.value;
                        delete search.select;
                        search.topicFilterKey = property.name;
                        currentUser.orgId().then(function (orgId) {
                            // Ensure that the user is directed to the catalog if they're not there
                            // already (if they're in a restricted view).
                            $location.path('/catalog/' + orgId);
                            $location.search(search);
                        });
                    };

                    $scope.clearProperties = function () {
                        $scope.properties = [];
                        $scope.prop = {
                            name: '',
                            value: '',
                        };
                    };

                    function updateObjectProperties(object) {
                        let tags;

                        if (v2ObjectService.isV2Object(object)) {
                            tags = object.tags;
                        } else if (object.sf_type !== 'Tag' && object.sf_tags) {
                            tags = object.sf_tags;
                        } else {
                            tags = [];
                        }

                        $q.all(
                            tags.map(function (tagName) {
                                return $http.get(API_URL + '/v2/tag/' + tagName).then(
                                    function (r) {
                                        return r.data;
                                    },
                                    function () {
                                        $log.warn(
                                            'Failed to look up tag tied to object! Proceeding anyway'
                                        );
                                        return {};
                                    }
                                );
                            })
                        )
                            .then(function (tags) {
                                $scope.properties = objectService
                                    .getObjectProperties(object)
                                    .map(function (property) {
                                        if (object.sf_key) {
                                            property.isDimension =
                                                object.sf_key.indexOf(property.name) !== -1;
                                        }

                                        tags.some(function (tag) {
                                            if (tag[property.name] !== property.value) return;

                                            property.fromTag = tag.sf_tag;
                                            return true; // short circuit some loop
                                        });

                                        return property;
                                    });
                            })
                            .catch(function (error) {
                                $log.error(error);
                            });
                    }

                    $scope.$watch('selectedObjects', function (objects) {
                        $scope.clearProperties();
                        if (!objects) return;

                        if (objects.length === 1) {
                            updateObjectProperties(objects[0]);
                        }
                    });

                    function commitDeletions() {
                        $scope.properties = $scope.properties.filter(function (property) {
                            if (property.delete) {
                                $q.all(
                                    $scope.selectedObjects
                                        // filter out any object types that don't have an associated REST service
                                        .filter(function (object) {
                                            return (
                                                v1ObjectService.getV1ServiceForObject(object) ||
                                                object.sf_type === 'Dimension' ||
                                                object.sf_type === 'Metric'
                                            );
                                        })
                                        .map(function (object) {
                                            if (
                                                object.sf_type === 'Dimension' ||
                                                object.sf_type === 'Metric'
                                            ) {
                                                const sf_type = object.sf_type.toLowerCase();

                                                return migratedSignalboost[sf_type].delete(
                                                    `/${object.sf_id || object.id}?property=${
                                                        property.name
                                                    }`
                                                );
                                            }
                                            return v1ObjectService
                                                .getV1ServiceForObject(object)
                                                .one(object.sf_id || object.id)
                                                .customDELETE('?property=' + property.name);
                                        })
                                );

                                return false;
                            }

                            return true;
                        });
                    }

                    $scope.deleteProperty = function (property) {
                        property.delete = true;
                    };

                    function commitUpdates() {
                        const changes = {};

                        $scope.properties.forEach(function (property) {
                            if (!property.delete && property.value !== property.originalValue) {
                                changes[property.name] = property.value;
                                property.originalValue = property.value;
                            }
                        });

                        // Update database
                        const promises = $scope.selectedObjects
                            .filter(function (object) {
                                return !v2ObjectService.isV2Object(object);
                            })
                            .map(function (object) {
                                const sf_type = object.sf_type.toLowerCase();
                                return (
                                    sf_type === 'dimension' || sf_type === 'metric'
                                        ? migratedSignalboost[sf_type].patch(
                                              `/${object.sf_id}`,
                                              changes
                                          )
                                        : v1ObjectService
                                              .getV1ServiceForObject(object)
                                              .one(object.sf_id)
                                              .patch(changes)
                                ).then(function () {
                                    Object.keys(changes).forEach(function (propertyName) {
                                        object[propertyName] = changes[propertyName];
                                    });
                                });
                            });

                        const customProperties = {};
                        $scope.properties.forEach(function (prop) {
                            if (!prop.delete) customProperties[prop.name] = prop.value;
                        });
                        $scope.selectedObjects.forEach(function (object) {
                            if (v2ObjectService.isV2Object(object)) {
                                object.customProperties = customProperties;
                                promises.push(v2ObjectService.update(object));
                            }
                        });
                        return $q.all(promises);
                    }

                    $scope.createProperty = function (newProperty) {
                        if (!(newProperty.name && newProperty.name.length > 1 && newProperty.value))
                            return;

                        // If existing property, update it
                        const exists = $scope.properties.some(function (existingProperty) {
                            if (existingProperty.name !== newProperty.name) return false;

                            if (existingProperty.delete) delete existingProperty.delete;

                            existingProperty.value = newProperty.value;
                            return true;
                        });

                        if (!exists) $scope.properties.push(newProperty);

                        // Clear new property inputs
                        $scope.prop = {
                            name: '',
                            value: '',
                        };

                        $scope.newPropertyForm.propertyName.$setPristine();
                        $scope.newPropertyForm.propertyValue.$setPristine();
                    };
                },
            ],
        };
    },
]);
