const TIMEPICKER_SHORT_DATE_FORMAT = 'MM/DD/YYYY';
const TIMEPICKER_SHORT_TIME_FORMAT = 'hh:mm:ss a';
const TIMEPICKER_SHORT_FORMAT = 'MM/DD/YYYY hh:mm:ss a';
const AN_HOUR = 60 * 60 * 1000;

angular
    .module('signalview.timePicker')
    .constant('TIMEPICKER_SHORT_DATE_FORMAT', TIMEPICKER_SHORT_DATE_FORMAT)
    .constant('TIMEPICKER_SHORT_TIME_FORMAT', TIMEPICKER_SHORT_TIME_FORMAT)
    .constant('TIMEPICKER_SHORT_FORMAT', TIMEPICKER_SHORT_FORMAT)
    .service('timeOptions', [
        function () {
            return ['-1m', '-15m', '-1h', '-12h', '-1d', '-1w', '-31d'];
        },
    ])
    .service('timeDisplayValue', [
        'TimeParser',
        '$log',
        function (TimeParser, $log) {
            const display = {
                m: 'minute',
                h: 'hour',
                d: 'day',
                w: 'week',
            };
            return function (value) {
                const data = new TimeParser(value);
                try {
                    if (data) {
                        if (data.utc) {
                            return '';
                        } else {
                            if (value.indexOf(' to ') !== -1) {
                                return '';
                            }
                            const words = [];
                            for (let i = 0; i < data.num.length; i++) {
                                const num = data.num[0];
                                // Special case for 31 days being equivalent to 1 month
                                if (num === '31' && data.type[0] === 'd') {
                                    words.push('month');
                                } else {
                                    const type = display[data.type[0]];
                                    if (num > 1) {
                                        words.push(num + ' ' + type + 's');
                                    } else {
                                        words.push(type);
                                    }
                                }
                            }
                            return 'Past ' + words.join(' and ');
                        }
                    }
                } catch (e) {
                    $log.warn('Failed to parse time!  Returning empty string!');
                    return '';
                }
                return '';
            };
        },
    ])
    .service('richTimeOptions', [
        'timeOptions',
        'timeDisplayValue',
        function (timeOptions, timeDisplayValue) {
            return timeOptions.map(function (s) {
                return { display: timeDisplayValue(s), value: s };
            });
        },
    ])
    .service('absoluteTimeOptions', [
        'timeZoneService',
        function (timeZoneService) {
            const list = [
                { display: 'Now to an hour from now', offset: AN_HOUR },
                { display: 'Now to 6 hours from now', offset: 6 * AN_HOUR },
                { display: 'Now to 12 hours from now', offset: 12 * AN_HOUR },
                { display: 'Now to a day from now', offset: 24 * AN_HOUR },
                { display: 'Now to 2 days from now', offset: 48 * AN_HOUR },
            ];

            return function () {
                const now = timeZoneService.moment();
                return list.map(function (item) {
                    return {
                        display: item.display,
                        value: getTimeRangeString(now, now.clone().add(item.offset)),
                    };
                });
            };
        },
    ])
    .service('relativeTimeOptions', [
        'timeZoneService',
        function (timeZoneService) {
            const timespans = [
                { displayName: 'an hour', offset: AN_HOUR },
                { displayName: '6 hours', offset: 6 * AN_HOUR },
                { displayName: '12 hours', offset: 12 * AN_HOUR },
                { displayName: 'a day', offset: 24 * AN_HOUR },
                { displayName: '2 days', offset: 48 * AN_HOUR },
            ];

            return function (startTimestamp, endTimestamp) {
                if (endTimestamp === 0) {
                    endTimestamp = null;
                }

                const startTime = timeZoneService.moment(startTimestamp);
                const endTime = timeZoneService.moment(endTimestamp);
                let displayPrefix, displayPostfix;

                if (startTimestamp) {
                    if (endTimestamp) {
                        // Has both start/end time
                        displayPrefix = 'Extend by ';
                        displayPostfix = '';
                    } else {
                        // Has only start time
                        displayPrefix = 'To ';
                        displayPostfix = ' from now';
                    }
                } else {
                    // Basic case, No timestamp given
                    displayPrefix = 'Now to ';
                    displayPostfix = ' from now';
                }

                return timespans.map(function (timespan) {
                    return {
                        display: displayPrefix + timespan.displayName + displayPostfix,
                        value: getTimeRangeString(startTime, endTime.clone().add(timespan.offset)),
                    };
                });
            };
        },
    ])
    .service('timeToRange', [
        function () {
            return function (timeobj) {
                const timeRange = {};
                if (timeobj.startUTC && timeobj.endUTC) {
                    timeRange.absoluteStart = timeobj.startUTC;
                    timeRange.absoluteEnd = timeobj.endUTC;
                    timeRange.range = null;
                    timeRange.rangeEnd = null;
                } else {
                    timeRange.absoluteStart = null;
                    timeRange.absoluteEnd = null;
                    if (timeobj.range || timeobj.start) {
                        timeRange.range = timeobj.range
                            ? -1 * Math.abs(timeobj.range)
                            : -1 * Math.abs(new Date() - timeobj.start);
                    }
                    if (timeobj.end) {
                        timeRange.rangeEnd = -1 * Math.abs(new Date() - timeobj.end);
                    } else {
                        timeRange.rangeEnd = 0;
                    }
                }
                return timeRange;
            };
        },
    ]);

function getTimeRangeString(start, end) {
    return start.format(TIMEPICKER_SHORT_FORMAT) + ' to ' + end.format(TIMEPICKER_SHORT_FORMAT);
}
