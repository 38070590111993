'use strict';
import templateUrl from './notFound.html';

export default {
    templateUrl,
    bindings: {
        objectTypeLabel: '@',
    },
    controller: [
        'currentUser',
        '$q',
        function (currentUser, $q) {
            const $ctrl = this;

            $ctrl.$onInit = () => {
                $q.all({
                    orgId: currentUser.orgId(),
                    isAdmin: currentUser.isAdmin(),
                }).then(({ isAdmin, orgId }) => {
                    $ctrl.membersLink = `#/organization/${orgId}?selectedKeyValue=sf_section:members`;
                    $ctrl.isAdmin = isAdmin;
                    $ctrl.dataLoaded = true;
                    $ctrl.objectTypeLabel = $ctrl.objectTypeLabel?.toLowerCase() || '';
                });
            };
        },
    ],
};
