import React, { useState } from 'react';
import ArrowDown from '@splunk/react-icons/ArrowDown';
import ArrowUp from '@splunk/react-icons/ArrowUp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CellFormatter from './cellFormatter';
import { variables } from '@splunk/themes';

const StyledDiv = styled.div`
    padding-top: 6px;
    padding-left: 4px;
    cursor: pointer;
    height: 100%;
`;

const StyledSpan = styled.span`
    float: left;
`;

const StyledDraggableIndicatorContainer = styled.div`
    width: 6px;
    height: 21px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 4px;
    margin-top: 4px;
`;

const StyledDraggableIndicator = styled.div`
    visibility: hidden;

    ${StyledDiv}:hover & {
        visibility: visible;
    }

    cursor: move;
    padding: 0.375rem 1.25rem 1.5375em;
    background: radial-gradient(
            circle at 1px 1px,
            ${variables.neutral400},
            ${variables.neutral400} 1px,
            transparent 1px
        )
        0px 0px / 4px 6px;
`;

export default function SortDropDownMenu({ sortHandler, column, sortPreference }) {
    const [dragClass, setDragClass] = useState('');

    const sortDirection = () => {
        if (column.key === sortPreference.column) {
            return !sortPreference.ascending ? (
                <ArrowDown aria-label="Arrow Down" />
            ) : (
                <ArrowUp aria-label="Arrow Up" />
            );
        }
        return;
    };

    function invokeSort() {
        let isAscending;
        if (column.key === sortPreference.column) {
            isAscending = !sortPreference.ascending;
        } else {
            isAscending = !column.isPlot;
        }
        sortHandler(column.key, isAscending ? 'ASC' : 'DESC');
    }

    function handleDragOver() {
        if (!column.isPlot) {
            setDragClass('rdg-drag-target');
        }
    }

    function handleDragLeave() {
        setDragClass('');
    }

    return (
        <StyledDiv
            className={dragClass}
            onClick={() => invokeSort()}
            onDragOver={() => handleDragOver()}
            onDragLeave={() => handleDragLeave()}
            onDrop={() => handleDragLeave()}
        >
            <StyledSpan>{sortDirection()}</StyledSpan>
            <CellFormatter value={column.name} />
            {column.draggable && (
                <StyledDraggableIndicatorContainer>
                    <StyledDraggableIndicator></StyledDraggableIndicator>
                </StyledDraggableIndicatorContainer>
            )}
        </StyledDiv>
    );
}

SortDropDownMenu.propTypes = {
    sortHandler: PropTypes.func,
    column: PropTypes.object,
    sortPreference: PropTypes.object,
};
