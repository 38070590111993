import templateUrl from './chartTemplatePreview.tpl.html';

angular.module('signalview.chartTemplates').directive('chartTemplatePreview', [
    'chartTemplates',
    'awsMetricInformation',
    'azureMetricInformation',
    'chartUrlUtils',
    function (chartTemplates, awsMetricInformation, azureMetricInformation, chartUrlUtils) {
        return {
            restrict: 'AE',
            scope: {
                template: '@',
                object: '=',
                filters: '=',
                start: '=',
                end: '=',
            },
            templateUrl,
            link: function ($scope) {
                function updateChart() {
                    const filters = [];
                    if ($scope.filters) {
                        $scope.filters.forEach(function (filter) {
                            if (filter.type === 'property') {
                                if (filter.property === 'sf_key') {
                                    filters.push('_exists_:' + filter.propertyValue);
                                } else {
                                    filters.push(filter.property + ':' + filter.propertyValue);
                                }
                            }
                        });
                    }

                    if ($scope.object) {
                        if ($scope.object.sf_type === 'Topic') {
                            filters.push('_exists_:' + $scope.object.sf_topic);
                        } else {
                            $scope.object.sf_key.forEach(function (key) {
                                filters.push(key + ':' + $scope.object[key]);
                            });
                        }
                    }

                    const filterMap = {};
                    if (filters.length) {
                        filters.forEach(function (filter) {
                            const parts = filter.split(':');
                            filterMap[parts[0]] = parts[1];
                        });
                    }

                    $scope.link = chartUrlUtils.getNewChartURLWithFilters(filters, $scope.template);

                    const chart = chartTemplates.get($scope.template)(filterMap);
                    if ($scope.start !== undefined && $scope.end !== undefined) {
                        chart.range($scope.start, $scope.end);
                    } else if (
                        filterMap.sf_metric &&
                        (awsMetricInformation.isAWSMetric(filterMap.sf_metric) ||
                            azureMetricInformation.isAzureMetric(filterMap.sf_metric))
                    ) {
                        //if its an AWS/Azure metric, then use 1h instead of 15m default, assuming no other timerange
                        //has been specified
                        chart.range(3600000, 0);
                    }

                    $scope.chartModel = chart.config();
                }

                $scope.$watch('object', function () {
                    updateChart();
                });

                $scope.$watch('filters.length', function (oldLength, newLength) {
                    if (oldLength !== newLength) updateChart();
                });
            },
        };
    },
]);
