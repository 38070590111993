angular
    .module('signalview.orgIntegrations')

    .constant('AWS_SERVICES', {
        autoscaling: 'Auto Scaling',
        cloudfront: 'CloudFront',
        dynamodb: 'DynamoDB',
        ec2: 'Elastic Compute Cloud',
        ecs: 'EC2 Container Service',
        elasticache: 'ElastiCache',
        ebs: 'Elastic Block Store',
        elb: 'Elastic Load Balancing',
        opsworks: 'OpsWorks',
        rds: 'Relational Database Service',
        route53: 'Route 53',
        sns: 'Simple Notification Service',
        sqs: 'Simple Queue Service',
        s3: 'Simple Storage Service',
        awsbilling: 'AWS Billing',
    })

    .constant('AWS_SERVICES_ENABLED', {
        autoscaling: true,
        cloudfront: true,
        dynamodb: false,
        ec2: true,
        ecs: true,
        elasticache: true,
        ebs: true,
        elb: true,
        opsworks: true,
        rds: true,
        route53: true,
        sns: true,
        sqs: true,
        s3: false,
        awsbilling: false,
    });
