angular
    .module('signalview.objectmanager')
    // This is an interim service until we move
    // all objects to v2
    .service('v1ObjectService', [
        'signalboost',
        function (signalboost) {
            function getV1ServiceForObject(object) {
                let type = (object || {}).sf_type;
                if (!type) {
                    return null;
                }
                type = type.toLowerCase();
                if (type === 'detector' && object.sf_programText) {
                    return signalboost.detector1flow2;
                } else {
                    return signalboost.types[type];
                }
            }

            return {
                getV1ServiceForObject: getV1ServiceForObject,
            };
        },
    ]);
