'use strict';

angular.module('signalview.detectorV2').service('v2DetectorConverter', [
    'v2DetectorAPIWrapper',
    'programTextUtils',
    'convertV1ToV2Detector',
    'plotUtils',
    function (v2DetectorAPIWrapper, programTextUtils, convertV1ToV2Detector, plotUtils) {
        return {
            convertV1ToV2,
            convertV1ToV2ApiWrapper,
            saveV1AsV2,
        };

        function convertV1ToV2ApiWrapper(detectorV1, normalizedProgramText) {
            return v2DetectorAPIWrapper(convertV1ToV2(detectorV1, normalizedProgramText));
        }

        function convertV1ToV2(detectorV1, normalizedProgramText) {
            return convertV1ToV2Detector(detectorV1, normalizedProgramText);
        }

        function saveV1AsV2(detectorV1) {
            const detectorPlotUniqueKey = plotUtils.getDetectorPlot(detectorV1)?.uniqueKey;
            const conversionOpts = {
                skipPublishInvisible: true,
                annotateThreshold: true,
                generateRules: true,
                includeEvents: true,
                plotsToExclude:
                    detectorPlotUniqueKey || detectorPlotUniqueKey === 0
                        ? [detectorPlotUniqueKey]
                        : [],
            };

            return convertV1ToV2ApiWrapper(
                detectorV1,
                programTextUtils.getV2ProgramTextWithOptions(detectorV1.sf_uiModel, conversionOpts)
            )
                .save()
                .then((response) => response.data);
        }
    },
]);
