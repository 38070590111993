import { uniq } from 'lodash';
import templateUrl from './commaSeparatedInput.tpl.html';

angular.module('sfx.ui').component('sfxIntegrationSharedCommaSeparatedInput', {
    templateUrl,
    bindings: {
        label: '@',
        placeholder: '@',
        name: '@',
        editable: '<',
        values: '<',
        form: '<',
        onChange: '&',
        onSanitizedChange: '&',
        singleValueRegex: '<',
    },
    controller: class IntegrationSharedCommaSeparatedInput {
        static $inject = [];

        $onInit() {
            if (!this.name) {
                throw new Error('IntegrationSharedCommaSeparatedInput must have a name.');
            }
        }

        $onChanges() {
            this.rawList = this.values.join(',');
        }

        handleChange() {
            this.onChange({
                values: this.rawList.split(','),
            });
            this.onSanitizedChange({
                values: uniq(
                    this.rawList
                        .split(',')
                        .map((str) => str.trim())
                        .filter((str) => str)
                ),
            });
        }
    },
});
