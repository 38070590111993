angular.module('signalview.page').controller('NewDashboardController', [
    '$q',
    '$scope',
    'params',
    'defaultWriters',
    '$location',
    'dashboardGroupService',
    'dashboardGroupUtil',
    'pageDisplayTitle',
    'dashboardV2Service',
    'featureEnabled',
    'promiseGenerationManager',
    function (
        $q,
        $scope,
        params,
        defaultWriters,
        $location,
        dashboardGroupService,
        dashboardGroupUtil,
        pageDisplayTitle,
        dashboardV2Service,
        featureEnabled,
        promiseGenerationManager
    ) {
        const GROUP_SUGGESTION_DEBOUNCE = 200;

        $scope.writePermissionsEnabled = featureEnabled('writepermissions');

        $scope.displayTitle = pageDisplayTitle;
        $scope.parentPage = dashboardGroupService.convertToV1(params.page);
        $scope.title = params.title || 'New Dashboard';

        $scope.obj = params.dashboard || {
            charts: [],
        };
        $scope.obj.authorizedWriters = $scope.obj.authorizedWriters || {};

        $scope.messages = [];
        $scope.defaultWriters = defaultWriters;

        $scope.closeMessage = function (index) {
            $scope.messages.splice(index, 1);
        };

        $scope.getGroupSuggestions = promiseGenerationManager(
            dashboardGroupUtil.getGroupSuggestions,
            GROUP_SUGGESTION_DEBOUNCE
        );

        $scope.getSuggestions = (query) => {
            const showFakeItems = false;
            return $scope.getGroupSuggestions(query, showFakeItems);
        };

        function showErrorMessage(msg) {
            $scope.messages.push({
                type: 'danger',
                msg: msg,
            });
        }

        function clearMessages() {
            $scope.messages = [];
        }

        function checkstatus(d) {
            clearMessages();
            if (d && d.data) {
                if (d.data.badFields) {
                    for (const key in d.data.badFields) {
                        showErrorMessage(d.data.badFields[key]);
                    }
                } else if (d.data.message) {
                    showErrorMessage(d.data.message);
                }
            } else if (d && d.type === 'error') {
                showErrorMessage(
                    'There was an error creating this ' + $scope.label + '. Try again later.'
                );
            }
            $scope.loading = false;
        }

        // TODO remove new dashboard creation once we get rid of old way of creating new dashboards
        function createNewDashboard() {
            const obj = $scope.obj;
            $scope.loading = true;
            let pagePromise = null;
            if ($scope.isNewPage($scope.parentPage)) {
                pagePromise = dashboardGroupService.create({ name: $scope.parentPage });
            } else {
                pagePromise = $q.when($scope.parentPage);
            }

            pagePromise.then((page) => {
                function closeModal() {
                    $scope.$close({
                        dashboard: $scope.obj,
                        pageId: page.sf_id || page.id,
                    });
                }

                if (params.dashboard) {
                    // Cloning should always follow this path
                    closeModal();
                } else {
                    // TODO(trevor): Remove after the views migration
                    obj.groupId = page.sf_id || page.id;

                    dashboardV2Service.create(obj).then((createdDashboard) => {
                        $location.url(`/dashboard/${createdDashboard.id}`);
                        closeModal();
                    }, checkstatus);
                }
            });
        }

        $scope.isNewPage = (parentPage) => {
            if (!parentPage) {
                return false;
            }
            return !parentPage.id && !parentPage.sf_id;
        };

        $scope.create = function () {
            if (!$scope.obj.name || !$scope.parentPage) return;
            createNewDashboard();
        };

        $scope.selectedGroupIsValid = function () {
            return !!$scope.parentPage;
        };
    },
]);
