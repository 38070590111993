// This service provides functionality to help determine if a dashboard is a mirror or not.
// Currently, we do so by figuring out how many times it appears across dashboard groups
// or if a filter override exists.
export default [
    'dashboardUtil',
    'dashboardV2Service',
    '$q',
    function (dashboardUtil, dashboardV2Service, $q) {
        // returns number of mirrors associated with dashboardId and number of groups the
        // dashboard is found in
        function getDashboardAppearanceCount(dashboardId) {
            return dashboardUtil.getAllDashboardConfigs(dashboardId).then(function (configs) {
                return {
                    numMirrors: configs.length,
                    numGroups: _.uniq(configs.map((config) => config.groupId)).length,
                };
            });
        }

        function configHasOverrides(config) {
            return !!(
                config?.nameOverride ||
                config?.descriptionOverride ||
                config?.filtersOverride?.sources?.length > 0 ||
                config?.filtersOverride?.variables?.some((variable) => variable.value)
            );
        }

        function isDashboardMirror(dashboardId) {
            if (!dashboardId) {
                return $q.resolve(false);
            }
            return dashboardV2Service
                .getMirrorState(dashboardId)
                .then((mirrorState) => !!mirrorState.mirrored);
        }

        return {
            getDashboardAppearanceCount,
            configHasOverrides,
            isDashboardMirror,
        };
    },
];
