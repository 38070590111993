import AccessControlPermissionAction from '../../../common/ui/accessControl/AccessControlPermissionAction';
import PRINCIPAL_TYPE from '../../../common/ui/accessControl/AccessControlPrincipalType';

angular.module('signalview.page').controller('dashboardGroupSelectorControl', [
    '$timeout',
    'dashboardGroupService',
    'pageTypeService',
    'currentUser',
    'featureEnabled',
    function ($timeout, dashboardGroupService, pageTypeService, currentUser, featureEnabled) {
        const $ctrl = this;
        const REQUEST_DELAY = 300;
        const PAGE_SIZE = 10;
        $ctrl.dashboardGroupQuery = '';
        $ctrl.currentPage = 1;
        $ctrl.sortTopic = 'name';
        $ctrl.sortAscending = true;
        let queryGeneration = 1;

        updateResults();

        $ctrl.selectDashboardGroup = selectDashboardGroup;
        $ctrl.adjustPageCount = adjustPageCount;
        $ctrl.getPageCount = getPageCount;
        $ctrl.handleQueryChange = handleQueryChange;
        $ctrl.setTopic = setTopic;
        $ctrl.handleKeyEvent = handleKeyEvent;

        function $onInit() {
            currentUser.getOrgMember().then((orgMember) => {
                $ctrl.orgId = orgMember.id;
            });
        }

        const isAccessControl = featureEnabled('accessControl');
        $ctrl.$onInit = $onInit;

        /* initializing code */

        function selectDashboardGroup(dashboardGroup) {
            if (!dashboardGroup.isUserDashboardGroup) {
                $ctrl.selectedDashboardGroup = dashboardGroup;
            }
        }

        function adjustPageCount(diff) {
            const destPage = $ctrl.currentPage + diff;
            if (destPage > 0 && destPage <= $ctrl.getPageCount()) {
                $ctrl.currentPage = destPage;
                updateResults();
            }
        }

        function setTopic(topic) {
            if ($ctrl.sortTopic === topic) {
                $ctrl.sortAscending = !$ctrl.sortAscending;
            } else {
                $ctrl.sortAscending = true;
                $ctrl.sortTopic = topic;
            }
            updateResults();
        }

        function getPageCount() {
            // pagecount is always at least 1, even with zero results
            return $ctrl.numMatches ? Math.max(1, Math.ceil($ctrl.numMatches / PAGE_SIZE)) : 1;
        }

        function handleKeyEvent({ originalEvent }) {
            // update query if enter key is pressed in the input
            if (originalEvent.keyCode === 13) {
                updateResults();
            }
        }

        function handleQueryChange() {
            $ctrl.currentPage = 1;
            $timeout.cancel($ctrl.pendingQuery);
            $ctrl.pendingQuery = $timeout(updateResults, REQUEST_DELAY);
        }

        /* unscoped functions */

        function updateResults() {
            $ctrl.requestInFlight = true;
            const generationCopy = ++queryGeneration;

            // this is a pretty bad hack, but it'll give us the case insensitive
            // search we need on dashboard group name. Spaces cannot be escaped in our
            // syntax so use ? to wildcard them away.
            const queryString = $ctrl.dashboardGroupQuery
                ? $ctrl.dashboardGroupQuery.replace(/ /gi, '?').toLowerCase()
                : '';

            return dashboardGroupService
                .search({
                    name: queryString,
                    offset: ($ctrl.currentPage - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    orderBy: ($ctrl.sortAscending ? '' : '-') + $ctrl.sortTopic,
                })
                .then(({ count, results }) => {
                    if (generationCopy !== queryGeneration) {
                        return;
                    }
                    if (isAccessControl) {
                        $ctrl.queryMatches = results.filter((r) => {
                            r.isUserDashboardGroup = isUserDashboardGroup(r);
                            if (r.isUserDashboardGroup) {
                                return true;
                            }

                            return r?.permissions?.acl?.some((p) => {
                                if (
                                    p.principalType === PRINCIPAL_TYPE.ORG ||
                                    p.principalId === $ctrl.orgId
                                ) {
                                    return p.actions.includes(AccessControlPermissionAction.WRITE);
                                }
                            });
                        });
                    } else {
                        $ctrl.queryMatches = results.map((r) => {
                            r.isUserDashboardGroup = isUserDashboardGroup(r);
                            return r;
                        });
                    }

                    $ctrl.numMatches = count;
                })
                .finally(() => {
                    if (generationCopy !== queryGeneration) {
                        return;
                    }
                    $ctrl.requestInFlight = false;
                });
        }

        function isUserDashboardGroup(obj) {
            return pageTypeService.getType(obj) === pageTypeService.getTypes().USER;
        }
    },
]);
