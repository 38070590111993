import templateUrl from './supportOrgView.tpl.html';

export default {
    bindings: {
        orgId: '=',
        zuoraAccount: '=',
    },
    templateUrl,
    controller: [
        'organizationService',
        function (organizationService) {
            const ctrl = this;

            ctrl.$onInit = $onInit;

            function $onInit() {
                organizationService.supportInfo().then((supportInfo) => {
                    ctrl.sbRestLimits = supportInfo.orgLimits;
                    ctrl.sbRestAccount = supportInfo.accountInfo;
                });
            }
        },
    ],
};
