angular
    .module('signalview.orgUsageChart')

    .service('orgUsageService', [
        'moment',
        'timeZoneService',
        'featureEnabled',
        function (moment, timeZoneService, featureEnabled) {
            return {
                constructValidPaymentIntervals,
                generateSignalflow,
                generateSignalflowWithDropped,
            };

            function constructValidPaymentIntervals(planInfo, numIntervals = 1, intervalsStart) {
                const intervals = [];
                if (planInfo) {
                    const day = planInfo.lastActiveRatePlan.billCycleDay;
                    // intervalsStart is undefined for dpm org so isNewAvgCalc is true for flag is on and org type is host/mts.
                    const isNewAvgCalc = featureEnabled('subscriptionUsageAvg') && intervalsStart;
                    const dateObj = isNewAvgCalc ? moment().utc() : moment();
                    dateObj.startOf('day');
                    const dayOfMonth = dateObj.date();

                    if (dayOfMonth < day) {
                        dateObj.startOf('month');
                    } else {
                        dateObj.add(1, 'month').startOf('month');
                    }

                    dateObj.date(Math.min(day, dateObj.daysInMonth()));

                    for (let x = 0; x < numIntervals; x++) {
                        const endTime = dateObj.toDate().getTime();
                        // Added 1h because we need to calculate average till next month first date.
                        const end = isNewAvgCalc ? endTime + 3600000 : endTime;
                        if (intervalsStart && intervalsStart > end) {
                            // Exclude any intervals earlier than this time
                            break;
                        }
                        dateObj.subtract(1, 'month').startOf('month');
                        dateObj.date(Math.min(day, dateObj.daysInMonth()));
                        const start = dateObj.toDate().getTime();
                        intervals.push({
                            start,
                            end,
                            rangeDescription: getRangeDescription(x, start, end, isNewAvgCalc),
                            monthsAgo: x,
                        });
                    }
                }
                return intervals;
            }

            function generateSignalflow(label, orgId) {
                return (
                    "data('sf.org.datapointsTotalCount', filter=filter('orgId', '" +
                    orgId +
                    "'), extrapolation='null', maxExtrapolations=-1, rollup='rate').scale(60).publish(label='" +
                    label +
                    "')"
                );
            }

            function generateSignalflowWithDropped(orgId) {
                //this is done as a single data block because it is possible for sf.org.numDatapointsDroppedExceededQuota to never have been created, which would cause an A+B correlation to fail.
                return (
                    "data('sf.org.numDatapoints*', filter=filter('sf_metric','sf.org.numDatapointsReceived','sf.org.numDatapointsDroppedExceededQuota') and filter('orgId','" +
                    orgId +
                    "'), extrapolation='null', maxExtrapolations=-1, rollup='rate').sum().scale(60).publish()"
                );
            }

            function getRangeDescription(monthOffset, startms, endms, isNewAvgCalc) {
                const monthsAgo = Math.abs(monthOffset);
                const startShort = isNewAvgCalc
                    ? timeZoneService.moment(startms).utc().format('MMM D')
                    : timeZoneService.moment(startms).format('MMM D');
                const endShort = isNewAvgCalc
                    ? timeZoneService.moment(endms).utc().format('MMM D')
                    : timeZoneService.moment(endms).format('MMM D');
                let desc = '';
                switch (monthsAgo) {
                    case 0:
                        desc = 'Current (';
                        break;
                    case 1:
                        desc = 'Last (';
                        break;
                }
                const suffix = monthsAgo === 0 || monthsAgo === 1 ? ')' : '';
                return desc + startShort + ' - ' + endShort + suffix;
            }
        },
    ]);
