import { ngRoute } from '../../../../app/routing/ngRoute';
import { CHART_TYPE } from '../../../common/data/apiv2/getOrgScopeErrorHandler';

export const chartV2NewData = [
    'chartV2Service',
    '$q',
    'getOrgScopeErrorHandler',
    'chartTemplates',
    'dashboardV2Service',
    'dashboardGroupService',
    'signalboostUtil',
    function (
        chartV2Service,
        $q,
        getOrgScopeErrorHandler,
        chartTemplates,
        dashboardV2Service,
        dashboardGroupService,
        signalboostUtil
    ) {
        const sourceChartId = ngRoute.params.fromChart;
        const sourceDashboardId = ngRoute.params.toDashboard;
        const sourceGroupId = ngRoute.params.groupId;
        const configId = ngRoute.params.configId;
        const templateChart = chartTemplates.fromUrl();

        if (templateChart) {
            return {
                chart: templateChart.asV2(),
                dashboard: null,
            };
        } else if (sourceChartId) {
            return chartV2Service
                .get(sourceChartId, getOrgScopeErrorHandler(CHART_TYPE))
                .then(function (resp) {
                    if (resp === null || resp.sf_uiModel) {
                        return $q.reject('Chart not found, or invalid version');
                    } else {
                        return resp;
                    }
                })
                .then(function (chart) {
                    const originalChartId = chart.id;
                    delete chart.id;
                    chart.name = 'Copy of ' + chart.name;
                    return chartV2Service
                        .getDashboardWithGroupForChartID(originalChartId)
                        .then(function ({ dashboard, dashboardGroup }) {
                            return {
                                chart,
                                dashboard,
                                dashboardGroup,
                                groupId: sourceGroupId,
                                configId,
                            };
                        })
                        .catch(function () {
                            return { chart: chart, dashboard: null };
                        });
                });
        } else if (sourceDashboardId) {
            return dashboardV2Service
                .get(sourceDashboardId)
                .then(function (dashboard) {
                    const groupId = sourceGroupId || dashboard.groupId;
                    return $q.all({
                        dashboard: signalboostUtil.initializeOrgForObj(dashboard),
                        dashboardGroup: dashboardGroupService.get(groupId).catch(() => null),
                    });
                })
                .then(function ({ dashboard, dashboardGroup }) {
                    return {
                        chart: null,
                        dashboard,
                        dashboardGroup,
                        groupId: sourceGroupId,
                        configId,
                    };
                });
        } else {
            return $q.when({
                chart: null,
                dashboard: null,
            });
        }
    },
];

export const chartV2Data = [
    'chartV2Service',
    '$q',
    'getOrgScopeErrorHandler',
    function (chartV2Service, $q, getOrgScopeErrorHandler) {
        return chartV2Service
            .get(ngRoute.params.id, getOrgScopeErrorHandler(CHART_TYPE))
            .then(function (resp) {
                if (resp === null || resp.sf_uiModel) {
                    return $q.reject('Chart not found, or invalid version');
                } else {
                    return resp;
                }
            })
            .then(function (chart) {
                return $q
                    .all({
                        dashboardFetch: chartV2Service
                            .getDashboardWithGroupForChartID(chart.id, ngRoute.params.groupId)
                            .then(function ({ dashboard, dashboardGroup }) {
                                return {
                                    chart,
                                    dashboard,
                                    dashboardGroup,
                                    groupId: ngRoute.params.groupId,
                                    configId: ngRoute.params.configId,
                                };
                            })
                            .catch(function () {
                                return { chart: chart, dashboard: null };
                            }),
                        plotFetch: chartV2Service.getChartWithPlots(chart.id),
                    })
                    .then(function ({ dashboardFetch, plotFetch }) {
                        return angular.extend(dashboardFetch, { plots: plotFetch.plots });
                    });
            });
    },
];
