import { AngularInjector } from '../../common/AngularUtils';
import { defaultRouteValue, QueryParamType, RouteContext } from './RouteContext';
import { merge } from 'lodash';
import type { History } from 'history';

class CurrentRoute {
    private lastRoute: RouteContext = { ...defaultRouteValue };
    private route: RouteContext = { ...defaultRouteValue };
    private rootScope?: angular.IRootScopeService;
    public readonly EventType = 'customNgRoute';
    public history?: History;

    private getRootScope(): angular.IRootScopeService {
        if (!this.rootScope) {
            const rootScope = AngularInjector.instantiate<angular.IRootScopeService>('$rootScope');
            if (rootScope) {
                this.rootScope = rootScope;
            }
        }
        return this.rootScope as angular.IRootScopeService;
    }

    public get pathname(): string {
        return this.route.pathname;
    }

    public get url(): string {
        return this.route.url;
    }

    public get matchedPath(): string {
        return this.route.matchedPath;
    }

    public get search(): QueryParamType {
        return this.route.search;
    }

    public get params(): QueryParamType {
        return this.route.params;
    }

    public get pathParams(): Record<string, string | undefined> {
        return this.route.pathParams;
    }

    public getLastRoute(): RouteContext {
        return Object.assign({}, this.lastRoute);
    }

    public getRoute(): RouteContext {
        return Object.assign({}, this.route);
    }

    public reload(): void {
        this.route.reloadPath && this.route.reloadPath();
    }

    public setRoute(newRoute: Omit<RouteContext, 'params'>, fireSuccess = false): RouteContext {
        if (this.route.url !== window.location.hash.substring(1)) {
            this.lastRoute = this.route;
        }

        // Set to a variable in closure so as to immutably be passed onto to $applyAsync.
        const route = Object.assign({}, this.route, newRoute);
        route.params = merge({}, route.search, route.pathParams);

        this.route = route;

        // Using the original angular-route events $routeChange* will trigger the angular
        // $routeProvider too. Augmenting the event name to be non-clashing since every path is now
        // under React-Router's control.
        this.getRootScope().$applyAsync(() => {
            if (fireSuccess) {
                this.getRootScope().$broadcast('React:$routeChangeSuccess', route);
            } else {
                this.getRootScope().$broadcast('React:$routeUpdate', route);
            }
        });

        return this.getRoute();
    }
}

export const ngRoute = new CurrentRoute();
