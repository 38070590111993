angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Total Network I/O')
                .type('column')
                .forcedResolution(30000)
                .colorByMetric(true)
                .yAxis(0, { label: 'Input (red)' })
                .yAxis(1, { label: 'Output (blue)' });

            const input = chart
                .plot()
                .metric('if_octets.tx')
                .color('#b04600')
                .yAxis(0)
                .propertyFilter('plugin_instance', 'eth*')
                .propertyFilter('plugin', 'interface')
                .propertyFilters(filters)
                .addManipulation(blockService.scale.asMacro({ scaleAmount: 8 }));

            // Output plot is just a clone of the previous with some modifications
            input.cloneTo(chart).metric('if_octets.rx').color('#0077c2').yAxis(1);

            return chart;
        }

        chartTemplates.register('collectdNetworkIO', createChart);
    },
]);
