import templateUrl from './pointDensityPicker.tpl.html';

export default [
    function () {
        return {
            restrict: 'E',
            templateUrl,
            replace: true,
            controller: [
                '$scope',
                'urlOverridesService',
                function ($scope, urlOverridesService) {
                    $scope.allowedDensities = {
                        0.5: {
                            label: 'Low',
                            icon: 'icon-densitylow',
                        },
                        1: {
                            label: 'Default',
                            icon: 'icon-densitystandard',
                        },
                        2: {
                            label: 'High',
                            icon: 'icon-densityhigh',
                        },
                        4: {
                            label: 'Very high',
                            icon: 'icon-densityveryhigh',
                        },
                    };
                    $scope.setDensity = function (density) {
                        $scope.density = density;
                    };

                    $scope.density = urlOverridesService.getPointDensity();
                    $scope.$on('React:$routeUpdate', function () {
                        $scope.density = urlOverridesService.getPointDensity();
                    });

                    $scope.$watch('density', function (newDensity, oldDensity) {
                        if (newDensity && newDensity !== oldDensity) {
                            urlOverridesService.setPointDensity(newDensity);
                            $scope.$emit('pointDensityChanged');
                        }
                    });
                },
            ],
        };
    },
];
