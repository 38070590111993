angular
    .module('signalview.organization')

    .service('organizationDataService', [
        '$q',
        'currentUser',
        'organizationService',
        'featureEnabled',
        function ($q, currentUser, organizationService, featureEnabled) {
            const ORDER_BY = ['sf_user', 'sf_email'];

            return {
                getList,
                sortResult,
                queryUsers,
                getSectionName,
            };

            function getBuckets() {
                return {
                    settings: {
                        sf_section: 'General Settings',
                        value: 'settings',
                    },
                    overview: {
                        sf_section: 'Organization Overview',
                        value: 'overview',
                    },
                    accesstokens: {
                        sf_section: 'Access Tokens',
                        value: 'accesstokens',
                    },
                    globaldatalinks: {
                        sf_section: 'Global Data Links',
                        value: 'globaldatalinks',
                    },
                    immusage: {
                        sf_section: 'Subscription Usage',
                        value: 'immusage',
                    },
                    // TODO: new billing and usage (will be removed once old page is removed)
                    apmusage: {
                        sf_section: 'Subscription Usage',
                        value: 'apmusage',
                    },
                    lousage: {
                        sf_section: 'Subscription Usage',
                        value: 'lousage',
                    },
                    teams: {
                        sf_section: 'Teams',
                        value: 'teams',
                    },
                    businessworkflows: {
                        sf_section: 'Business Workflow Configuration',
                        value: 'businessworkflows',
                    },
                    metricsets: {
                        sf_section: 'MetricSets Configuration',
                        value: 'metricsets',
                    },
                    traceretention: {
                        sf_section: 'Extended Trace Retention Configuration',
                        value: 'traceretention',
                    },
                    members: {
                        sf_section: 'Users',
                        value: 'members',
                    },
                };
            }

            function queryUsers(orgId, partialInput, offset, limit) {
                offset = offset || 0;
                limit = limit || 100;
                const query = formulateUsersQuery(partialInput, orgId);
                return organizationService
                    .searchMembersWithArbitraryQuery(query, offset, limit, ORDER_BY)
                    .then(function (result) {
                        return result.results.map(function (orgMember) {
                            orgMember.sf_type = 'OrgUser';
                            return orgMember;
                        });
                    });
            }

            function getSectionName(bucketValue) {
                const buckets = getBuckets(bucketValue);

                if (bucketValue && buckets[bucketValue]) {
                    if (bucketValue !== 'billing' || featureEnabled('infra')) {
                        return buckets[bucketValue].sf_section;
                    }
                }
            }

            function addLinkToBuckets(buckets, organizationId) {
                buckets.forEach((bucket) => {
                    bucket.link = `#/organization/${organizationId}?selectedKeyValue=sf_section:${bucket.value}`;
                });
            }

            function getList(params) {
                const keyedBuckets = getBuckets();

                return $q
                    .all({
                        isAdmin: currentUser.isAdmin(),
                        users: params.skipLoadingUsers
                            ? Promise.resolve([])
                            : queryUsers(
                                  params.organizationId,
                                  params.partialInput,
                                  params.offset,
                                  params.limit
                              ),
                    })
                    .then(function (data) {
                        keyedBuckets.members.hits = data.users;
                        const buckets = [];
                        if (params.partialInput || !data.isAdmin) {
                            // If there is a query or it's non-admin
                            buckets.push(keyedBuckets.accesstokens);
                            buckets.push(keyedBuckets.globaldatalinks);
                            buckets.push(keyedBuckets.teams);
                            buckets.push(keyedBuckets.members);
                        } else {
                            if (featureEnabled('writepermissions')) {
                                buckets.push(keyedBuckets.settings);
                            }
                            buckets.push(keyedBuckets.overview);
                            buckets.push(keyedBuckets.accesstokens);
                            buckets.push(keyedBuckets.globaldatalinks);
                            buckets.push(keyedBuckets.teams);

                            if (featureEnabled('infra')) {
                                buckets.push(keyedBuckets.immusage);
                            } else if (featureEnabled('apm2')) {
                                buckets.push(keyedBuckets.apmusage);
                            } else if (featureEnabled('logs')) {
                                buckets.push(keyedBuckets.lousage);
                            }

                            // APM-related admin pages.
                            if (featureEnabled('apm2')) {
                                if (featureEnabled('apm2Workflows')) {
                                    buckets.push(keyedBuckets.businessworkflows);
                                }
                                if (featureEnabled('apm2LongTermRetentionConfig')) {
                                    buckets.push(keyedBuckets.traceretention);
                                }
                                buckets.push(keyedBuckets.metricsets);
                            }

                            buckets.push(keyedBuckets.members);
                        }
                        addLinkToBuckets(buckets, params.organizationId);
                        return $q.when(buckets);
                    })
                    .catch(() => $q.when([]));
            }

            function sortResult() {
                return false;
            }

            function formulateUsersQuery(partialInput, orgId) {
                const q = partialInput;
                let s;
                if (q === '') {
                    s = '(sf_user:* OR sf_email:*)';
                } else if (!q.match(/:/)) {
                    const newQuery = ['sf_email', 'sf_user'].map((f) => {
                        return `(${f}.lowercase:*${
                            q ? q.replace(/ /gi, '?').toLowerCase() + '*' : ''
                        } )`;
                    });
                    s = '(' + newQuery.join(' OR ') + ')';
                }
                return s + ' AND sf_type:OrgUser AND sf_organizationID:' + orgId;
            }
        },
    ]);
