import templateUrl from './slackNotifyBlock.tpl.html';

angular.module('signalview.detector.notification').directive('slackNotifyBlock', [
    '$log',
    '$timeout',
    'slackChannelService',
    'notificationsUtil',
    function ($log, $timeout, slackChannelService, notificationsUtil) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                credentials: '=credentials',
                remove: '&onRemove',
                readOnly: '=?',
                doneEdit: '&onFinishEdit',
            },
            templateUrl,
            link: function ($scope, element) {
                $scope.ui = {
                    dropdownOpen: false,
                };

                $scope.selector = {
                    suggestions: [],
                    highlighted: null,
                    onSelect: selectChannel,
                };

                function selectChannel(selection) {
                    const channel = selection.channel;
                    $scope.model.channel = '#' + channel.name;
                    $scope.model.channelSlackId = channel.slackId;
                    $scope.finishEdit();
                }

                function generateFakeChannelResult(name) {
                    return {
                        displayName: '#' + name,
                        channel: {
                            name: name,
                            memberCount: null,
                            purpose: null,
                            slackId: null,
                            warning: `We couldn’t find #${name} in the list of channels returned from Slack. Please make sure that the channel name you typed is correct.`,
                        },
                    };
                }

                function updateSuggestions(suggestions) {
                    $scope.selector.suggestions = suggestions;
                    $scope.selector.highlighted = $scope.selector.suggestions[0];
                }

                $scope.credentialType = notificationsUtil.getIntegrationNameForV1orV2(
                    $scope.model.type
                );

                $scope.setCredential = function (credential) {
                    $scope.model.credentialId = credential.id;
                    $scope.model.credentialName = credential.name;
                    $scope.credential = credential;

                    $timeout(function () {
                        const input = element.find('input')[0];
                        if (input) {
                            input.focus();
                        }
                    });
                };

                $scope.$watch('ui.dropdownOpen', function (newValue, oldValue) {
                    if (oldValue && !newValue) {
                        if (!$scope.model.credentialId && $scope.remove) $scope.remove();
                    }
                });

                let currentRequestId = 0;
                $scope.suggestSlackChannel = function () {
                    let query = $scope.selector.query;
                    if (!$scope.credential || $scope.credential.method !== 'OAuth') return;
                    const thisRequestId = ++currentRequestId;

                    if (!query) query = '';

                    // Strip hash symbols if user decided to put them in when searching
                    query = query.replace(/#/g, '');

                    slackChannelService.searchSlackChannels($scope.credential.id, query).then(
                        (results) => {
                            if (thisRequestId !== currentRequestId) return;

                            const suggestions = results.map((result) => ({
                                displayName: '#' + result.name,
                                channel: result,
                            }));

                            // If suggestions don't have an exact match of the query, add a
                            // fake result at the end of the list to allow users to specify a
                            // channel which may not yet exist in their slack channels or may be
                            // a private channel
                            const directMatchChannel = generateFakeChannelResult(query);

                            const hasDirectMatch = suggestions.some(
                                (suggestion) =>
                                    suggestion.displayName === directMatchChannel.displayName
                            );
                            if (!hasDirectMatch) {
                                suggestions.push(directMatchChannel);
                            }
                            updateSuggestions(suggestions);
                        },
                        (reason) => {
                            if (reason === slackChannelService.DEBOUNCED_REASON) {
                                return;
                            }
                            $log.warn('Could not fetch slack channels.');
                            updateSuggestions([generateFakeChannelResult(query)]);
                        }
                    );
                };

                $scope.finishEdit = function () {
                    if ($scope.model.credentialId && $scope.model.channel) {
                        $scope.edit = false;
                    } else {
                        if ($scope.remove) $scope.remove();
                    }
                    if ($scope.doneEdit) $scope.doneEdit();
                };

                $scope.editMode = function () {
                    $scope.edit = true;

                    $timeout(function () {
                        if ($scope.model.credentialId) {
                            $scope.selector.query = $scope.model.channel;
                            element.find('input')[0].focus();
                        } else {
                            $scope.ui.dropdownOpen = $scope.credentials.length !== 1;
                        }
                    });
                };

                $scope.$watch('credentials.length', function () {
                    $scope.credentials.some(function (credential) {
                        if (credential.id === $scope.model.credentialId) {
                            $scope.setCredential(credential);
                            return true;
                        }
                    });

                    if (!$scope.credential) {
                        $log.error('Unable to find matching credential!');
                    }
                });

                if (!$scope.model.credentialId) {
                    // If there's only one credential, automatically pick it.
                    if ($scope.credentials.length === 1) {
                        $scope.setCredential($scope.credentials[0]);
                    }

                    $scope.editMode();
                } else {
                    $scope.credentials.some(function (credential) {
                        if (credential.id === $scope.model.credentialId) {
                            $scope.setCredential(credential);
                            return true;
                        }
                    });

                    if (!$scope.credential) {
                        $log.error('Unable to find matching credential!');
                    }
                }
            },
        };
    },
]);
