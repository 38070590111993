import templateUrl from './globalActionsWrapper.tpl.html';

export default {
    templateUrl,
    bindings: {},
    controller: [
        'globalNavProps',
        'globalNavUpdateService',
        function (globalNavProps, globalNavUpdateService) {
            const $ctrl = this;
            let unregister;
            $ctrl.$onInit = $onInit;
            $ctrl.$onDestroy = $onDestroy;

            function $onInit() {
                // register the update callback to globalNavUpdateService
                unregister = globalNavUpdateService.subscribe(updateCallback);
            }

            function $onDestroy() {
                unregister();
            }

            function updateCallback(updates) {
                if (updates && Object.keys(updates).length) {
                    assignRelevantPropsToCtrl(updates, globalNavProps);
                }
            }

            function assignRelevantPropsToCtrl(updates, props) {
                props.forEach((prop) => {
                    if (updates.hasOwnProperty(prop)) {
                        $ctrl[prop] = updates[prop];
                    }
                });
            }
        },
    ],
};
