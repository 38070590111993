'use strict';

import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular.module('sfx.ui').controller('customEventModal', [
    '$scope',
    'event',
    'globalEventsService',
    'hasCapability',
    function ($scope, event, globalEventsService, hasCapability) {
        $scope.event = event;
        const properties = $scope.event.properties;

        hasCapability(Capability.DELETE_EVENT).then((hasDeleteEventCapability) => {
            $scope.hasDeleteEventCapability = hasDeleteEventCapability;
        });

        // Try to find links in events
        if (properties) {
            Object.keys(properties).forEach(function (key) {
                const value = properties[key];
                if (
                    typeof value === 'string' &&
                    isURL(value, { require_protocol: true }) &&
                    !isEmail(value)
                ) {
                    $scope.link = {
                        label: key,
                        url: value,
                    };
                }
            });
        }

        $scope.delete = function () {
            globalEventsService.deleteGlobalEvent(event).then(function () {
                $scope.$close();
            });
        };
    },
]);
