import SortableColumnManager, {
    ORDER_ASCENDING,
} from '../../../../app/alerts/common/SortableColumnManager';
import mutingListTemplateUrl from './mutingList.tpl.html';
import { Monitoring } from '../../../../app/routing/Sitemaps';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

const DEFAULT_SORT_COLUMNS = [
    {
        column: 'stopTime',
        order: ORDER_ASCENDING,
    },
    {
        column: 'startTime',
        order: ORDER_ASCENDING,
    },
];

export const mutingList = {
    bindings: {
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl: mutingListTemplateUrl,
    controller: [
        '$scope',
        'zeroStateService',
        'mutingService',
        'urlOverridesService',
        '$timeout',
        'currentUser',
        'featureEnabled',
        'hasCapability',
        function (
            $scope,
            zeroStateService,
            mutingService,
            urlOverridesService,
            $timeout,
            currentUser,
            featureEnabled,
            hasCapability
        ) {
            this.$onInit = function () {
                this.setHierarchicalNav(Monitoring.name, Monitoring.IDs.mutingRules);
            };

            this.$onDestroy = function () {
                this.setHierarchicalNav();
            };

            // actions part
            $scope.zeroStateService = zeroStateService;

            $scope.getUserDisplayName = mutingService.getMutingUserDisplayName;

            $scope.profilePage = '#/userprofile/';

            $scope.sortableColumnManager = new SortableColumnManager(DEFAULT_SORT_COLUMNS);

            $scope.recurringMutingRulesEnabled = featureEnabled('recurringMutingRules');

            $scope.deleteModalState = {
                isOpen: false,
                onCancel: function () {
                    $timeout(() => {
                        $scope.deleteModalState.isOpen = false;
                    }, 0);
                },
            };

            const sortUrlParameter = urlOverridesService.getSort();
            if (sortUrlParameter) {
                $scope.sortableColumnManager.applySortString(sortUrlParameter);
            }

            $scope.reverseColumnOrder = (field) => {
                $scope.sortableColumnManager.reverseColumnOrder(field);
                urlOverridesService.setSort($scope.sortableColumnManager.getSortString());
                reloadList();
            };
            $scope.getColumnOrder = (field) => $scope.sortableColumnManager.getColumnOrder(field);

            hasCapability(Capability.CREATE_MUTING_RULE).then((hasCreateMutingRuleCapability) => {
                $scope.hasCreateMutingRuleCapability = hasCreateMutingRuleCapability;
            });

            hasCapability(Capability.DELETE_MUTING_RULE).then((hasDeleteMutingRuleCapability) => {
                $scope.hasDeleteMutingRuleCapability = hasDeleteMutingRuleCapability;
            });

            // data loading part
            const limit = 50;
            $scope.currentLimit = limit;

            function loadList() {
                $scope.loading = true;
                mutingService
                    .getList({
                        query: $scope.mutingQuery
                            ? 'sf_filters.lowercase:*' + $scope.mutingQuery.toLowerCase() + '*'
                            : '*',
                        limit: $scope.currentLimit,
                        orderBy: $scope.sortableColumnManager.getSortArray(),
                    })
                    .then(function (mutings) {
                        $scope.mutings = mutings;
                        $scope.loading = false;
                        $scope.$applyAsync();
                    });
            }

            $scope.getNextDataSet = function () {
                $scope.currentLimit += limit;
                loadList();
            };

            function reloadList() {
                $scope.mutings = [];
                $scope.currentLimit = limit;
                loadList();
            }

            currentUser.features().then((features) => {
                $scope.isSendAlertsOnceMutingPeriodHasEndedEnabled =
                    featureEnabled('sendAlertsOnceMutingPeriodHasEnded') &&
                    features.calculated.includes('sendAlertsOnceMutingPeriodHasEnded');
            });

            $scope.editMuting = (muting) => {
                mutingService.openMutingModal(muting, []).then(reloadList);
            };

            $scope.deleteMuting = (muting) => {
                $scope.deleteModalState.onDelete = function () {
                    $timeout(() => {
                        $scope.deleteModalState.isOpen = false;
                        mutingService.deleteMuting(muting).then(reloadList);
                    }, 0);
                };

                $scope.deleteModalState.isOpen = true;
                $scope.deleteModalState.title = 'Delete Muting Rule';
                $scope.deleteModalState.description =
                    'You are about to delete this muting rule. Are you sure?';
                $scope.deleteModalState.callToAction = 'Delete';
            };

            $scope.endMuting = (muting) => {
                $scope.deleteModalState.onDelete = function () {
                    $timeout(() => {
                        $scope.deleteModalState.isOpen = false;
                        mutingService.endMuting(muting).then(reloadList);
                    }, 0);
                };

                $scope.deleteModalState.isOpen = true;
                $scope.deleteModalState.title = 'Resume Notifications';
                $scope.deleteModalState.description =
                    'You are about to resume notifications. Are you sure?';
                $scope.deleteModalState.callToAction = 'Resume';
            };

            $scope.recurrenceText = (muting) => {
                const { recurrence } = muting;
                if (!recurrence) return '-';

                const recurrencePatterns = {
                    '1_d': '1 day',
                    '1_w': '1 week',
                };

                const unitPlurals = {
                    d: 'days',
                    w: 'weeks',
                };

                const key = `${recurrence.value}_${recurrence.unit}`;
                return (
                    recurrencePatterns[key] || `${recurrence.value} ${unitPlurals[recurrence.unit]}`
                );
            };

            let queryDebounce;
            $scope.$watch('mutingQuery', function () {
                $timeout.cancel(queryDebounce);
                queryDebounce = $timeout(reloadList, 200);
            });

            // Make it looks like it's loading on first load since it is going to be triggered (with debounce) above eventually
            $scope.loading = true;
        },
    ],
};
