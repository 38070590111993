export default [
    '$http',
    '$q',
    'API_URL',
    '$timeout',
    'featureEnabled',
    function ($http, $q, API_URL, $timeout, featureEnabled) {
        const resourceEndpoint = API_URL + '/v2/alerts';
        let queuedAlertStateRequests = [];
        let queueTimeoutTimer = null;

        const MAX_QUEUE_SIZE = 10;
        const MAX_WAIT_TIME = 300;

        const api = {
            getAlertStateBatched,
        };

        return api;

        function getAlertStateBatched(filteredProgramRequest) {
            if (featureEnabled('disableSmokey')) {
                return $q.when({
                    detectors: [],
                    alertsBySeverity: {},
                });
            }

            if (queuedAlertStateRequests.length === 0) {
                queueTimeoutTimer = $timeout(submitAllQueuedRequests, MAX_WAIT_TIME);
            }

            const deferred = $q.defer();
            queuedAlertStateRequests.push({
                params: filteredProgramRequest,
                defer: deferred,
            });

            if (queuedAlertStateRequests.length >= MAX_QUEUE_SIZE) {
                submitAllQueuedRequests();
            }

            return deferred.promise;
        }

        function submitAllQueuedRequests() {
            $timeout.cancel(queueTimeoutTimer);
            const requests = queuedAlertStateRequests;
            queuedAlertStateRequests = [];
            $http({
                method: 'POST',
                url: `${resourceEndpoint}/_/bulksignalflow`,
                data: requests.map((v) => v.params),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                response.data.forEach((value, idx) => {
                    requests[idx].defer.resolve(value);
                });
            });
        }
    },
];
