import templateUrl from './dimensionValues.tpl.html';

angular.module('signalview.objectmanager').directive('dimensionValues', [
    'signalboost',
    '$location',
    'currentUser',
    function (signalboost, $location, currentUser) {
        return {
            restrict: 'E',
            scope: {
                isNewCatalog: '=',
                object: '=',
                filters: '=',
                inViewAll: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.showValues = function (selectedValue) {
                        const object = $scope.object;
                        const search = $location.search();

                        search.topicFilterKey = object.sf_topic;

                        if (selectedValue) {
                            search.selectedKeyValue = object.sf_topic + ':' + selectedValue;
                        }

                        $location.search(search);
                    };

                    function updateValues(object) {
                        // only show available values for section headers ("Topics")
                        if (!object || angular.isArray(object) || object.sf_type !== 'Topic') {
                            return;
                        }

                        loadValues().then(function (values) {
                            $scope.values = values;
                        });
                    }

                    $scope.$watch('object', updateValues);
                    $scope.$watch('filters', updateValues);

                    function loadValues() {
                        const filters = [];

                        if ($scope.filters && $scope.filters.length) {
                            $scope.filters.forEach(function (filter) {
                                filters.push(filter.property + ':' + filter.propertyValue);
                            });
                        }

                        return currentUser.orgId().then(function (orgId) {
                            return signalboost.autosuggest
                                .all()
                                .all('suggest')
                                .all('value')
                                .customGET('', {
                                    partialInput: '',
                                    query: filters.join(' AND '),
                                    limit: 40,
                                    type: 'Dimension',
                                    property: $scope.object.sf_topic,
                                    organizationId: orgId,
                                })
                                .catch(function () {
                                    return [];
                                });
                        });
                    }
                },
            ],
        };
    },
]);
