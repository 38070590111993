import { ClusterMapLayoutCalculationError } from '../../../../app/kubeNavigator/clusterMapErrors';

export default [
    '_',
    'd3v4',
    'clusterMapUtil',
    'clusterMapVizConfigUtil',
    'clusterMapLayoutGenerator',
    'infraNavClusterMapFilterUtils',
    function (
        _,
        d3v4,
        clusterMapUtil,
        clusterMapVizConfigUtil,
        clusterMapLayoutGenerator,
        infraNavClusterMapFilterUtils
    ) {
        const CLASS_PREFIX = 'cluster-map';

        // max level to allow zooming into; set to 3 to allow for zooming to the pods level (Cluster -> Node -> Pod)
        const MAX_ZOOM_LEVEL = 3;
        const MIN_SUBLEVEL_DEPTH_FROM_OVERVIEW = 2;
        const MIN_SUBLEVEL_DEPTH_FROM_ROOT = 3;
        const MAX_TOP_LEVEL_FROM_ROOT = 1;
        const DEBOUNCE_TIMEOUTS = {
            LIGHT: 100, // ms
            HEAVY: 500, // ms
        };

        const ZOOM_DIRECTIONS = {
            DRILL_DOWN: 'drillDown',
            DRILL_UP: 'drillUp',
        };

        const PHASES = {
            STARTING: 'starting',
            IN_PROGRESS: 'inProgress',
            FINISHED: 'finished',
        };

        const EVENTS = {
            MOUSEOVER: 'mouseover',
            MOUSEOUT: 'mouseout',
            CLICK: 'click',
            ZOOM: 'zoom',
            RENDER: 'render',
            DESTROY: 'destroy',
            GLOBAL_ZOOM: 'globalzoom',
        };

        const defaultClasses = {
            rootContainer: `${CLASS_PREFIX}-root`,
            renderingParent: `${CLASS_PREFIX}-rendering-parent`,
            headerClass: `${CLASS_PREFIX}-header`,
            primaryHeaderClass: `${CLASS_PREFIX}-primary-header`,
            container: `${CLASS_PREFIX}-container`,
            content: `${CLASS_PREFIX}-content`,
            contentShiftedByHeaderObjects: `${CLASS_PREFIX}-header-shifted-content`,
            alertBorder: `${CLASS_PREFIX}-alert-border`,
            background: `${CLASS_PREFIX}-resource-background`,
            unknownResourceStatus: `${CLASS_PREFIX}-state-unknown`,
            topLevelResource: `${CLASS_PREFIX}-top-level-resource`,
            subLevelResource: `${CLASS_PREFIX}-sub-level-resource`,
            resourceFilteredOut: `${CLASS_PREFIX}-resource-filtered-out`,
            headerResources: `${CLASS_PREFIX}-header-resources`,
            selectionHighlight: `${CLASS_PREFIX}-selection`,
            highlightContainer: `${CLASS_PREFIX}-highlight-container`,
            highlightPath: `${CLASS_PREFIX}-highlight-path`,
            highlightLeafPath: `${CLASS_PREFIX}-highlight-leaf-path`,
            highlightLeaf: `${CLASS_PREFIX}-highlight-leaf`,
            selectedResource: `${CLASS_PREFIX}-selected-resource`,
        };

        return getNewClusterMapViz;

        function getNewClusterMapViz(params = {}, dataConfig) {
            const renderer = {};
            const resourceClasses = Object.assign({}, defaultClasses);
            const layoutStack = [];
            const rendererList = [
                renderDepth0,
                renderDepth1,
                renderDepth2,
                renderDepth3,
                renderDepth4,
            ];
            const allStateClassString = `${resourceClasses.unknownResourceStatus} ${_.values(
                clusterMapVizConfigUtil.severityClasses
            ).join(' ')}`;

            const config = clusterMapVizConfigUtil.getFilledVizConfig(params);
            const layout = clusterMapLayoutGenerator(config);
            const parentElement = d3v4.select(config.element);
            const resourceGroupIdentifier = dataConfig.getResourceGroupMap();
            const groupIdentifierToResource = _.invert(resourceGroupIdentifier);

            const resize = _.debounce(updateSizes, DEBOUNCE_TIMEOUTS.HEAVY);
            const debouncedUpdate = _.debounce(update, DEBOUNCE_TIMEOUTS.HEAVY);
            const debouncedSetHoverHighlight = _.debounce(
                setHoverHighlight,
                DEBOUNCE_TIMEOUTS.LIGHT
            );

            const defaultScales = {
                x: d3v4.scaleLinear(),
                y: d3v4.scaleLinear(),
            };

            const eventDispatcher = d3v4.dispatch.apply(null, Object.values(EVENTS));
            const interactionObjects = {};

            let svg;
            let renderingParent;
            let currentOverviewResource = null;
            let transitioning = false;
            let dataRoot;
            let zoomHandle;
            let initialized = false;
            let maxZoomLevel = MAX_ZOOM_LEVEL;
            let onPrimaryHeader = _.noop;

            init();

            const api = {
                ZOOM_DIRECTIONS,
                PHASES,
                EVENTS,
                on,
                off,
                isTransitioning,
                purge,
                resize,
                resetCanvas,
                currentOverviewDepth,
                setPrimaryHeaderCallback,
                getMaxZoomLevel,
                setMaxZoomLevel,
                isValidZoomLevel,
                isDeeperThanMaxZoomLevel,
                isZoomable,
                zoomToResource,
                stopZoom,
                setSelection,
                getSelectedData,
                getSelectedId,
                setAutoSelectionFilters,
                update: debouncedUpdate,
            };

            return api;

            function init() {
                if (initialized) {
                    return;
                }

                dataConfig.getResourceHierarchy().forEach((resourceType, depth) => {
                    const groupByKeyIdentifier = resourceGroupIdentifier[resourceType];

                    resourceClasses[
                        groupByKeyIdentifier
                    ] = `${CLASS_PREFIX}-resource-${resourceType.toLowerCase()}`;
                    resourceClasses[depth] = `${CLASS_PREFIX}-depth-${depth}`;

                    renderer[groupByKeyIdentifier] = rendererList[depth];
                });

                if (parentElement.empty()) {
                    throw new Error('Visualization container element not found.');
                }

                svg = parentElement
                    .append('svg')
                    .classed(resourceClasses.rootContainer, true)
                    .on('mouseover', removeHoverHighlight);

                resetCanvas();
                initialized = true;
            }

            /**
             * Binds event listeners (callback)
             * callback has context this of the element in context, with signature
             * (originalEvent, data, otherParams)
             * @param { string } event:
             *          Event string to bind to.
             *          To bind multiple callbacks namespace the string as EVENT.<namespaced name>
             * @param { function } callback: callback function
             */
            function on(event, callback) {
                eventDispatcher.on(event, callback);
            }

            /**
             * Unbinds an event string or namespaced event string
             * @param { string } event: Event string or namespaced event string to unbind.
             */
            function off(event) {
                eventDispatcher.on(event, null);
            }

            function isTransitioning() {
                return transitioning;
            }

            function currentOverviewDepth() {
                if (!currentOverviewResource) {
                    return null;
                }

                return currentOverviewResource.depth;
            }

            function setPrimaryHeaderCallback(callback) {
                onPrimaryHeader = callback;
            }

            function updateSizes() {
                setViewBox();

                layoutStack.length = 0;

                if (!dataRoot) {
                    return;
                }

                update(dataRoot);
            }

            function purge() {
                dataRoot = null;
                layoutStack.length = 0;
                currentOverviewResource = null;
                resetCanvas();
            }

            function resetCanvas() {
                transitioning = false;
                setViewBox();
                recreateHoverHighlight();
                removeSelectionHighlight();
                if (renderingParent) {
                    removeElements(renderingParent);
                }
                renderingParent = getNewRenderingParent();
            }

            function getNewRenderingParent() {
                return svg
                    .insert('g', ':first-child')
                    .classed(resourceClasses.renderingParent, true)
                    .attr(
                        'transform',
                        clusterMapUtil.transform(config.rootPadding, config.rootPadding)
                    );
            }

            function setViewBox() {
                const containerWidth = parseInt(parentElement.style('width').slice(0, -2));
                const containerHeight = parseInt(parentElement.style('height').slice(0, -2));

                config.viewBox = {
                    width: containerWidth - 2 * config.rootPadding,
                    height: containerHeight - 2 * config.rootPadding,
                };

                defaultScales.x.domain([0, config.viewBox.width]).range([0, config.viewBox.width]);
                defaultScales.y
                    .domain([0, config.viewBox.height])
                    .range([0, config.viewBox.height]);
            }

            function setLayoutExtents(canvasSize) {
                const { width, height } = canvasSize || {};
                const padding = 2 * config.rootPadding;
                svg.style('width', (width || 0) + padding).style('height', height || 0);
            }

            function update(data) {
                if (!dataRoot || data !== dataRoot) {
                    dataRoot = data;
                    resetCurrentOverviewAndCanvas();
                }

                if (!currentOverviewResource) {
                    currentOverviewResource = dataRoot;
                } else {
                    clearCurrentOverviewIfNeeded();
                }

                if (data.layoutCalculationRequired) {
                    setViewBox();
                    layoutStack.length = 0;
                    delete data.layoutCalculationRequired;
                }

                const childrenToBeRendered =
                    currentOverviewResource.id === dataRoot.id
                        ? currentOverviewResource.children
                        : [currentOverviewResource];

                try {
                    const { layout } = getCachedLayoutConfig(currentOverviewResource);
                    render(childrenToBeRendered, layout, renderingParent, defaultScales);
                } catch (error) {
                    if (!(error instanceof ClusterMapLayoutCalculationError)) {
                        throw error;
                    }
                }
            }

            function clearCurrentOverviewIfNeeded() {
                if (currentOverviewResource.id !== dataRoot.id) {
                    if (!dataRoot || !dataRoot.children || dataRoot.children.length < 1) {
                        resetCurrentOverviewAndCanvas();
                    } else {
                        // copy in order to not remove from actual hierarchy
                        const matchingChild = findMatchingChild();
                        if (!matchingChild) {
                            resetCurrentOverviewAndCanvas();
                        }
                    }
                }
            }

            function findMatchingChild() {
                const idTrace = [];
                let resource = currentOverviewResource;
                while (resource) {
                    idTrace.push(resource.id);
                    resource = resource.parent;
                }

                idTrace.reverse();
                idTrace.shift(); // Top will be data root
                resource = dataRoot;

                while (resource && resource.children && idTrace.length) {
                    const id = idTrace.shift();
                    resource = resource.children.find((child) => child.id === id);
                }

                return !idTrace.length ? resource : null;
            }

            function resetCurrentOverviewAndCanvas() {
                currentOverviewResource = null;
                resetCanvas();
                currentOverviewResource = dataRoot;
            }

            function getCachedLayoutConfig(data) {
                let layoutConfig;
                if (layoutStack[data.depth]) {
                    layoutConfig = layoutStack[data.depth];
                } else {
                    const { canvasSize, assignments, unboundedLayout } =
                        layout.getLayoutForResource(data, config.viewBox, config.maxDetailingDepth);

                    layoutConfig = {
                        layout: assignments,
                        canvasSize,
                    };
                    // if the resource is unbounded (exceeds the current max layout size), we'll set a flag to set the canvas height/width
                    // in this case (by default height is set to null/zero for child-level depths)
                    data.unboundedLayout = unboundedLayout;
                }

                if (layoutConfig && layoutConfig.layout) {
                    layoutStack[data.depth] = layoutConfig;
                }
                // Clear out anything after current depth
                layoutStack.length = data.depth + 1;

                setLayoutExtents(
                    data.depth === 0 || data.unboundedLayout ? layoutConfig.canvasSize : null
                );

                return layoutConfig;
            }

            function getMaxZoomLevel() {
                return maxZoomLevel;
            }

            function setMaxZoomLevel(maxLevel) {
                maxZoomLevel = maxLevel || MAX_ZOOM_LEVEL;
            }

            function isValidZoomLevel(depth) {
                return depth !== undefined && depth >= 0 && depth <= maxZoomLevel;
            }

            function isDeeperThanMaxZoomLevel(depth) {
                return depth !== undefined && depth > maxZoomLevel;
            }

            function isZoomable({ depth, children }) {
                return (
                    depth !== undefined &&
                    depth > currentOverviewDepth() &&
                    isValidZoomLevel(depth) &&
                    children?.length > 0
                );
            }

            function stopZoom() {
                clearTimeout(zoomHandle);
            }

            function zoom(data, zoomToElement) {
                if (
                    transitioning || // Not when already transitioning
                    !data || // No data, duh, what will we transition to
                    !(data.children && data.children.length)
                ) {
                    return;
                }

                stopZoom();

                // No, transition to same data (but stop zoom beforehand in case of a previous call to zoom into
                // another resource).
                if (currentOverviewResource === data) {
                    return;
                }

                const originalEvent = d3v4.event;
                const zoomDirection = zoomToElement
                    ? ZOOM_DIRECTIONS.DRILL_DOWN
                    : ZOOM_DIRECTIONS.DRILL_UP;

                if (data.id === resourceGroupIdentifier.GLOBAL) {
                    eventDispatcher.call(EVENTS.GLOBAL_ZOOM, zoomToElement, d3v4.event, data);
                }

                // Run zoom in next execution context,
                // allowing processes listening on click or zoom start to do their work beforehand without blocking UI.
                // Doing this with timeout also allows for a chance to stop zoom from ever starting.
                zoomHandle = setTimeout(() => {
                    try {
                        zoomTransition(data, zoomToElement);
                    } catch (error) {
                        if (!(error instanceof ClusterMapLayoutCalculationError)) {
                            throw error;
                        }
                    }
                });

                eventDispatcher.call(EVENTS.ZOOM, null, originalEvent, data, {
                    phase: PHASES.STARTING,
                    zoomDirection,
                });
            }

            function zoomTransition(data, zoomToElement) {
                const originalEvent = d3v4.event;

                const oldRenderingParent = renderingParent;
                const newRenderingParent = getNewRenderingParent();
                renderingParent = newRenderingParent;

                const lastLayoutStackSize = layoutStack.length - 1;
                const layoutConfig = getCachedLayoutConfig(data);
                const newRenderingData =
                    data.id === resourceGroupIdentifier.GLOBAL ? data.children : [data];
                const zoomDirection = zoomToElement
                    ? ZOOM_DIRECTIONS.DRILL_DOWN
                    : ZOOM_DIRECTIONS.DRILL_UP;

                transitioning = true;
                eventDispatcher.call(EVENTS.ZOOM, newRenderingParent, originalEvent, data, {
                    phase: PHASES.IN_PROGRESS,
                    zoomDirection,
                });

                currentOverviewResource = data;
                oldRenderingParent.attr('opacity', 1);
                newRenderingParent.attr('opacity', 0);

                let renderingParentTransition;
                const transition = getTransition();
                render(newRenderingData, layoutConfig.layout, newRenderingParent, defaultScales);

                if (zoomToElement) {
                    const start = zoomToElement.getBoundingClientRect();
                    const end = oldRenderingParent.node().getBoundingClientRect();
                    layoutStack[lastLayoutStackSize].zoomedFrom = start;

                    if (end.width - start.width > 0) {
                        // Zooming in
                        const scaleX = end.width / start.width;
                        const scaleY = end.height / start.height;

                        const translateX = start.left - end.left;
                        const translateY = start.top - end.top;

                        newRenderingParent
                            .attr(
                                'transform',
                                `translate(${config.rootPadding + translateX}, ${
                                    config.rootPadding + translateY
                                }) scale(${1 / scaleX}, ${1 / scaleY})`
                            )
                            .transition(transition)
                            .attr('opacity', 1)
                            .attr(
                                'transform',
                                clusterMapUtil.transform(config.rootPadding, config.rootPadding)
                            );

                        renderingParentTransition = oldRenderingParent
                            .transition(transition)
                            .attr('opacity', 0)
                            .attr(
                                'transform',
                                `translate(${config.rootPadding - translateX * scaleX}, ${
                                    config.rootPadding - translateY * scaleY
                                }) scale(${scaleX}, ${scaleY})`
                            );
                    }
                } else if (layoutConfig.zoomedFrom) {
                    // No zoomToElement present, zoom out from current view
                    const start = oldRenderingParent.node().getBoundingClientRect();
                    const end = layoutConfig.zoomedFrom;
                    delete layoutConfig.zoomedFrom;

                    const scaleX = end.width / start.width;
                    const scaleY = end.height / start.height;

                    const translateX = end.left - start.left;
                    const translateY = end.top - start.top;

                    newRenderingParent
                        .attr(
                            'transform',
                            `translate(${config.rootPadding - translateX / scaleX}, ${
                                config.rootPadding - translateY / scaleY
                            }) scale(${1 / scaleX}, ${1 / scaleY})`
                        )
                        .transition(transition)
                        .attr('opacity', 1)
                        .attr(
                            'transform',
                            clusterMapUtil.transform(config.rootPadding, config.rootPadding)
                        );

                    renderingParentTransition = oldRenderingParent
                        .transition(transition)
                        .attr('opacity', 0)
                        .attr(
                            'transform',
                            `translate(${config.rootPadding + translateX}, ${
                                config.rootPadding + translateY
                            }) scale(${scaleX}, ${scaleY})`
                        );
                }

                if (!renderingParentTransition) {
                    // We failed the two blocks above, defaulting to in-place transition
                    newRenderingParent.transition(transition).attr('opacity', 1);

                    renderingParentTransition = oldRenderingParent
                        .transition(transition)
                        .attr('opacity', 0);
                }

                renderingParentTransition.on('end', () => {
                    transitioning = false;
                    removeElements(oldRenderingParent);
                    eventDispatcher.call(EVENTS.ZOOM, newRenderingParent, originalEvent, data, {
                        phase: PHASES.FINISHED,
                        zoomDirection,
                    });
                });
            }

            function zoomToResource(data) {
                // If we are at level greater than max allowed, move up until we are in range.
                while (data && !isValidZoomLevel(data.depth)) {
                    data = data.parent;
                }

                // Make sure the resource is visible
                let visibleResource = data;
                while (visibleResource && visibleResource.depth > 0) {
                    visibleResource = visibleResource.parent.children.includes(visibleResource)
                        ? visibleResource.parent
                        : null;
                }

                if (visibleResource === dataRoot) {
                    zoom(data);
                }
            }

            function render(data, layout, parent, scales) {
                eventDispatcher.call(EVENTS.RENDER, parent, null, data, {
                    phase: PHASES.IN_PROGRESS,
                });
                renderAllLevels(data, layout, parent, scales);
                recreateHoverHighlight();
                recreateSelectionHighlight();
                eventDispatcher.call(EVENTS.RENDER, parent, null, data, { phase: PHASES.FINISHED });
            }

            function renderAllLevels(data, layout, parent, scales) {
                if (!data || !data.length || !layout) {
                    const depthClass = resourceClasses[1];
                    removeElements(parent.selectAll(`.${depthClass}`));
                    return;
                }

                const referenceEntity = data[0];
                const resourceGroupedUpon = referenceEntity.groupedUpon;

                const depthClass = resourceClasses[referenceEntity.depth];
                const resourceTypeClass = resourceClasses[resourceGroupedUpon];

                const sortByKey =
                    !config.sizing[referenceEntity.depth] ||
                    !config.sizing[referenceEntity.depth].sortByKey
                        ? config.globalSortByKey
                        : config.sizing[referenceEntity.depth].sortByKey;

                clusterMapUtil.sortMapItems(data, sortByKey, config.globalSortByKey);

                const entities = parent
                    .selectAll(`.${depthClass}.${resourceClasses.container}`)
                    .data(data, (d) => d.id);

                const enter = entities
                    .enter()
                    .append('g')
                    .classed(depthClass, true)
                    .classed(resourceClasses.container, true)
                    .classed(resourceTypeClass, true);

                const update = entities.merge(enter);
                const exit = entities.exit();

                const selection = { enter, update, exit };

                renderCommonComponents(selection, layout, scales);

                renderer[resourceGroupedUpon](selection, layout, scales);

                removeElements(exit);

                update.each(function (data, idx) {
                    const currentConfig = layout instanceof Array ? layout[idx] : layout;
                    if (currentConfig.subLevel) {
                        const contentContainer = d3v4
                            .select(this)
                            .select(`.${resourceClasses.content}`);
                        renderAllLevels(
                            data.children,
                            currentConfig.subLevel,
                            contentContainer,
                            scales
                        );
                    }
                });
            }

            function removeElements(selection) {
                if (!selection || selection.size() === 0) {
                    return;
                }

                const elementsToBeRemoved = [];

                selection.each(function () {
                    // d3 selection.each is different than Array's exit.forEach
                    elementsToBeRemoved.push(this);
                });

                eventDispatcher.call(EVENTS.DESTROY, null, null, elementsToBeRemoved);

                selection.remove();
            }

            function onPrimaryHeaderEnter(parent, { currentConfig }) {
                const { sizeConfig } = currentConfig;
                if (
                    !sizeConfig ||
                    !sizeConfig.header ||
                    !sizeConfig.header.primary ||
                    !onPrimaryHeader
                ) {
                    return;
                }

                const header = parent
                    .append('foreignObject')
                    .classed(resourceClasses.headerClass, true)
                    .classed(resourceClasses.primaryHeaderClass, true)
                    .on('click.primaryHeader', onResourceClick);

                onPrimaryHeader.call(
                    header.node(),
                    header.datum(),
                    groupIdentifierToResource[header.datum().groupedUpon],
                    sizeConfig.header.primary
                );
            }

            function onPrimaryHeaderUpdate(parent, { currentConfig, scales, ignoreMarginTop }) {
                const { sizeConfig } = currentConfig;
                if (!sizeConfig || !sizeConfig.header || !sizeConfig.header.primary) {
                    return;
                }

                const { x, y, width } = clusterMapUtil.getBackgroundBox(
                    currentConfig,
                    sizeConfig,
                    ignoreMarginTop
                );

                transitioned(parent.select(`.${resourceClasses.primaryHeaderClass}`))
                    .attr('x', scales.x(x))
                    .attr('y', scales.y(y - currentConfig.sizeConfig.header.primary))
                    .attr('width', scales.x(width))
                    .attr('height', scales.y(currentConfig.sizeConfig.header.primary));
            }

            function renderCommonComponents({ enter, update }, layout, scales) {
                enter.each(function (data, idx) {
                    const thisEl = d3v4.select(this);
                    const perItemConfig = layout instanceof Array;
                    const currentConfig = perItemConfig ? layout[idx] : layout;

                    onPrimaryHeaderEnter(thisEl, { currentConfig });

                    thisEl.append('g').classed(resourceClasses.content, true);
                });

                transitioned(update).attr('transform', (d, idx) => {
                    const perItemConfig = layout instanceof Array;
                    const currentConfig = perItemConfig ? layout[idx] : layout;
                    const position = clusterMapUtil.getRelativePosition(currentConfig, idx);
                    return clusterMapUtil.transform(scales.x(position.x), scales.y(position.y));
                });

                update.each(function (data, idx) {
                    const thisEl = d3v4.select(this);
                    const perItemConfig = layout instanceof Array;
                    const currentConfig = perItemConfig ? layout[idx] : layout;
                    const margin = clusterMapUtil.getMargin(
                        currentConfig.sizeConfig,
                        currentConfig
                    );
                    const ignoreMarginTop = shouldIgnoreMarginTop(data);

                    onPrimaryHeaderUpdate(thisEl, {
                        currentConfig,
                        scales,
                        margin,
                        ignoreMarginTop,
                    });

                    let content = thisEl.select(`.${resourceClasses.content}`);

                    if (currentConfig.subLevel) {
                        const contentPosition = clusterMapUtil.getContentRelativePosition(
                            currentConfig,
                            ignoreMarginTop,
                            false
                        );
                        if (content.size() === 0) {
                            content = thisEl.append('g').classed(resourceClasses.content, true);
                        }

                        transitioned(content).attr(
                            'transform',
                            clusterMapUtil.transform(
                                scales.x(contentPosition.x),
                                scales.y(contentPosition.y)
                            )
                        );
                    } else {
                        removeElements(content);
                    }
                });
            }

            function renderDepth0() {
                return; //noop
            }

            function renderDepth1({ enter, update }, layout, scales) {
                const depth = 1;

                if (!(layout instanceof Array)) {
                    layout = [layout];
                }

                addBackground(enter, update, layout, scales, depth);

                enter
                    .on('click.default', onResourceClick)
                    .on('mouseover', onObjectMouseOver)
                    .on('mouseout', onObjectMouseOut);
            }

            function renderDepth2({ enter, update }, layout, scales) {
                const depth = 2;

                addBackground(enter, update, layout, scales, depth);

                enter
                    .on('click.default', onResourceClick)
                    .on('mouseover', onObjectMouseOver)
                    .on('mouseout', onObjectMouseOut);
            }

            function renderDepth3({ enter, update }, layout, scales) {
                const depth = 3;

                addBackground(enter, update, layout, scales, depth);

                enter
                    .on('click.default', onResourceClick)
                    .on('mouseover', onObjectMouseOver)
                    .on('mouseout', onObjectMouseOut);
            }

            function renderDepth4({ enter, update }, layout, scales) {
                const depth = 4;

                addBackground(enter, update, layout, scales, depth);

                enter
                    .on('click.default', onResourceClick)
                    .on('mouseover', onObjectMouseOver)
                    .on('mouseout', onObjectMouseOut);
            }

            function onResourceClick(data) {
                const event = d3v4.event;
                event.stopPropagation(); // Don't propagate/bubble-up event since our setup is hierarchical
                const target = d3v4.select(this);

                // Don't dispatch the event if the clicked resource is the selected one, since only
                // the overview resources and containers will be selected, and neither can be zoomed by
                // clicking them again.
                if (getSelectedId() !== data.id) {
                    eventDispatcher.call(EVENTS.CLICK, target, event, data);
                }
            }

            function onObjectMouseOver(data) {
                const event = d3v4.event;
                event.stopPropagation(); // Don't propagate/bubble-up event since our setup is hierarchical

                // Ensuring it triggers only if the target switches.
                const subResource = this.querySelector(`.${resourceClasses.content}`);
                if (
                    this.contains(event.relatedTarget) &&
                    !(subResource && subResource.contains(event.relatedTarget))
                ) {
                    return;
                }

                const target = d3v4.select(this);
                debouncedSetHoverHighlight(target, data);
                eventDispatcher.call(EVENTS.MOUSEOVER, target, event, data);
            }

            function onObjectMouseOut(data) {
                const event = d3v4.event;
                event.stopPropagation(); // Don't propagate/bubble-up event since our setup is hierarchical

                // Ensuring it triggers only if the target switches.
                const subResource = this.querySelector(`.${resourceClasses.content}`);
                if (
                    this.contains(event.relatedTarget) &&
                    !(subResource && subResource.contains(event.relatedTarget))
                ) {
                    return;
                }

                removeHoverHighlight(event);
                const target = d3v4.select(this);
                eventDispatcher.call(EVENTS.MOUSEOUT, target, event, data);
            }

            // Assumes this takes in a selection under same parent and thus have same config
            function addBackground(enterSelection, updateSelection, layout, scales, depth) {
                const dataList = updateSelection.data();
                let data;
                if (dataList.length) {
                    data = dataList[0];
                    if (depth === currentOverviewResource.depth) {
                        data = dataList.find((d) => d === currentOverviewResource) || dataList[0];
                    }
                }

                const isTopLevelResource =
                    currentOverviewResource === data ||
                    data.depth - dataRoot.depth <= MAX_TOP_LEVEL_FROM_ROOT;

                const depthFromCurrentOverview = depth - currentOverviewResource.depth;
                const subLevelsMinDepth =
                    currentOverviewResource === dataRoot
                        ? MIN_SUBLEVEL_DEPTH_FROM_ROOT
                        : MIN_SUBLEVEL_DEPTH_FROM_OVERVIEW;

                const isSubLevelResource =
                    !isTopLevelResource && depthFromCurrentOverview >= subLevelsMinDepth;

                enterSelection
                    .classed(resourceClasses.topLevelResource, isTopLevelResource)
                    .classed(resourceClasses.subLevelResource, isSubLevelResource)
                    .insert('rect', ':first-child')
                    .classed(resourceClasses.background, true);

                if (_.isArray(layout)) {
                    updateSelection.each(function (data, idx) {
                        const currentConfig = layout[idx];

                        updateBackground(
                            d3v4.select(this),
                            currentConfig,
                            scales,
                            shouldIgnoreMarginTop(data)
                        );
                    });
                } else {
                    updateBackground(updateSelection, layout, scales, shouldIgnoreMarginTop(data));
                }
            }

            function addBackgroundClass(d) {
                const stateColorClass = clusterMapUtil.getStateColorClass(
                    d.data,
                    dataConfig.get(groupIdentifierToResource[d.groupedUpon]).getColorByConfig(),
                    d.depth
                );

                // removing previously added classes
                const rect = d3v4.select(this);
                rect.classed(stateColorClass, false);
                rect.classed(resourceClasses.unknownResourceStatus, false);

                rect.classed(stateColorClass || resourceClasses.unknownResourceStatus, true);
            }

            function updateBackground(selection, layout, scales, shouldIgnoreMarginTop) {
                const sizeConfig = layout.sizeConfig;

                const { x, y, width, height } = clusterMapUtil.getBackgroundBox(
                    layout,
                    sizeConfig,
                    shouldIgnoreMarginTop,
                    false
                );
                const radius = clusterMapUtil.getScaledSmallestSize(
                    sizeConfig.radius,
                    width,
                    height
                );

                selection
                    .classed(
                        resourceClasses.resourceFilteredOut,
                        (d) =>
                            d.resourceIsFilteredOut ||
                            infraNavClusterMapFilterUtils.isParentFilteredOut(d)
                    )
                    .select(`.${resourceClasses.background}`)
                    .attr('x', scales.x(x))
                    .attr('y', scales.y(y))
                    .attr('width', scales.x(width))
                    .attr('height', scales.y(height))
                    .attr('rx', radius)
                    .classed(allStateClassString, false)
                    .each(addBackgroundClass);
            }

            function getTransition() {
                return d3v4.transition().duration(config.transitionDuration).ease(d3v4.easePoly);
            }

            function transitioned(selection, transition) {
                return config.animate
                    ? selection.transition(transition || getTransition())
                    : selection;
            }

            function shouldIgnoreMarginTop(data) {
                // If overview is Global, then first visible resource is 1 level below Global overview
                const firstVisibleOverviewResourceDepth =
                    currentOverviewResource.depth + (currentOverviewResource === dataRoot ? 1 : 0);

                return (
                    config.pinFirstLevelToTop &&
                    data &&
                    (currentOverviewResource === data ||
                        (data.depth === firstVisibleOverviewResourceDepth &&
                            currentOverviewResource.children &&
                            currentOverviewResource.children.includes(data)))
                );
            }

            function recreateHoverHighlight() {
                const { highlightElement } = interactionObjects;
                if (!highlightElement || !document.contains(highlightElement.node())) {
                    return removeHoverHighlight();
                }

                const highlightedResource = d3v4.select(highlightElement.node().parentElement);
                // Flush out debounce queue
                debouncedSetHoverHighlight(null);
                setHoverHighlight(highlightedResource, highlightedResource.datum());
            }

            function recreateSelectionHighlight() {
                const { autoSelectKeys } = interactionObjects;
                let selectedResource;

                if (
                    interactionObjects.selectionElement &&
                    !document.contains(interactionObjects.selectionElement.node())
                ) {
                    removeSelectionHighlight();
                }

                if (currentOverviewResource && autoSelectKeys) {
                    const currentDepth = currentOverviewResource.depth;
                    selectedResource = clusterMapUtil.identifyElementFromOverrides(
                        resourceClasses,
                        renderingParent,
                        dataConfig.getHierarchicalGroupByKeys(),
                        autoSelectKeys,
                        currentDepth
                    );
                } else if (interactionObjects.selectionElement) {
                    selectedResource = d3v4.select(
                        interactionObjects.selectionElement.node().parentElement
                    );
                }

                if (!selectedResource || !selectedResource.size()) {
                    return;
                }

                setSelection(selectedResource, selectedResource.datum());
            }

            function setAutoSelectionFilters(overrides) {
                delete interactionObjects.autoSelectKeys;
                const hierarchicalGroupByKeys = dataConfig.getHierarchicalGroupByKeys();

                overrides = overrides.filter(
                    ({ property }) => property && hierarchicalGroupByKeys.includes(property)
                );
                if (overrides.length) {
                    interactionObjects.autoSelectKeys = overrides;
                    recreateSelectionHighlight();
                }
            }

            function getSelectedData() {
                return (
                    interactionObjects.selectionElement &&
                    interactionObjects.selectionElement.datum()
                );
            }

            function getSelectedId() {
                return interactionObjects.selectedNodeId;
            }

            function setSelection(element, { depth, id, resourceIsFilteredOut } = {}) {
                let { selectionElement } = interactionObjects;

                selectionElement = addHighlight(element, depth, resourceIsFilteredOut, false, {
                    selectionElement: selectionElement,
                    highlightElement: selectionElement,
                    removeHighlight: removeSelectionHighlight,
                });

                delete interactionObjects.selectedNodeId;
                if (selectionElement) {
                    element.classed(resourceClasses.selectedResource, true);
                    selectionElement.classed(resourceClasses.selectionHighlight, true);
                    interactionObjects.selectionElement = selectionElement;
                    interactionObjects.selectedNodeId = id;
                    delete interactionObjects.autoSelectKeys; // We have a selection

                    // Reset hover highlight to be on top
                    const { highlightElement } = interactionObjects;
                    if (highlightElement) {
                        const highlightedResource = d3v4.select(
                            highlightElement.node().parentElement
                        );
                        removeHoverHighlight();
                        setHoverHighlight(highlightedResource, highlightedResource.datum());
                    }
                }
            }

            function setHoverHighlight(element, data = {}) {
                const { depth, resourceIsFilteredOut } = data;
                const isResourceFilteredOut =
                    resourceIsFilteredOut ||
                    infraNavClusterMapFilterUtils.isParentFilteredOut(data);

                const highlightElement = addHighlight(
                    element,
                    depth,
                    isResourceFilteredOut,
                    isZoomable(data),
                    {
                        selectionElement: interactionObjects.selectionElement,
                        highlightElement: interactionObjects.highlightElement,
                        removeHighlight: removeHoverHighlight,
                    }
                );

                if (highlightElement) {
                    interactionObjects.highlightElement = highlightElement;
                }
            }

            function removeSelectionHighlight() {
                const { selectionElement } = interactionObjects;
                if (!selectionElement) {
                    return;
                }
                d3v4.select(selectionElement.node().parentElement).classed(
                    resourceClasses.selectedResource,
                    false
                );
                selectionElement.remove();
                delete interactionObjects.selectedNodeId;
                delete interactionObjects.selectionElement;
            }

            function removeHoverHighlight({ relatedTarget } = {}) {
                debouncedSetHoverHighlight(null); // Flush debounce queue
                const { highlightElement } = interactionObjects;
                if (
                    !highlightElement ||
                    (relatedTarget && highlightElement.node().contains(relatedTarget))
                ) {
                    return;
                }
                highlightElement.remove();
                delete interactionObjects.highlightElement;
            }

            function addHighlight(
                element,
                depth,
                resourceIsFilteredOut,
                showZoom,
                { removeHighlight, selectionElement: selected, highlightElement: highlight }
            ) {
                if (!element || !svg.node().contains(element.node()) || resourceIsFilteredOut) {
                    return null;
                }

                const hasHighlightContainer =
                    highlight && highlight.node().parentElement === element.node();
                const isSelected = selected && selected.node().parentElement === element.node();

                const resourceBackground = element.select(`.${resourceClasses.background}`);

                if (
                    resourceBackground.size() === 0 ||
                    resourceBackground.node().parentElement !== element.node()
                ) {
                    removeHighlight();
                    return null;
                }

                if (!hasHighlightContainer) {
                    removeHighlight();
                    highlight = element
                        .append('g')
                        .classed(resourceClasses.highlightContainer, true);
                }

                const { highlightPath, hasSizeConstraints } =
                    clusterMapUtil.getHighlightShape(resourceBackground);

                // Create or update highlight
                if (!showZoom || !hasSizeConstraints || !isSelected) {
                    const highlightBorder = hasHighlightContainer
                        ? highlight.select(`.${resourceClasses.highlightPath}`)
                        : highlight.append('path').classed(resourceClasses.highlightPath, true);

                    highlightBorder.attr('d', highlightPath);
                }

                // Create or update highlight
                if (!showZoom || (hasSizeConstraints && !isSelected)) {
                    highlight.select(`.${resourceClasses.highlightLeaf}`).remove();
                    return highlight;
                }

                const zoomBackground = hasHighlightContainer
                    ? highlight.select(`.${resourceClasses.highlightLeafPath}`)
                    : highlight.append('path').classed(resourceClasses.highlightLeafPath, true);

                let zoomContainer;
                if (hasHighlightContainer) {
                    zoomContainer = highlight.select(`.${resourceClasses.highlightLeaf}`);
                } else {
                    zoomContainer = highlight
                        .append('foreignObject')
                        .classed(resourceClasses.highlightLeaf, true);

                    zoomContainer.append('xhtml:i').classed('icon-magnify', true);
                }

                const { startX, startY, scale, containerLength, zoomLeafPath } =
                    clusterMapUtil.getZoomLeafShape(resourceBackground);

                const transform = hasSizeConstraints
                    ? `scale(${scale}) translate(-50%, -50%)`
                    : `scale(${scale}) translate(4px, 1px)`;

                zoomBackground.attr('d', zoomLeafPath);

                zoomContainer
                    .attr('x', startX)
                    .attr('y', startY)
                    .attr('height', containerLength)
                    .attr('width', containerLength)
                    .select('.icon-magnify')
                    .style('transform', transform)
                    .style('left', hasSizeConstraints ? '50%' : 0)
                    .style('top', hasSizeConstraints ? 'calc(50% + 1px)' : 0);

                return highlight;
            }
        }
    },
];
