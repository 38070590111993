import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export const dyGraphConfigurationGenerator = [
    'chartbuilderUtil',
    'valueFormatter',
    'dyGraphUtils',
    'chartDisplayUtils',
    function (chartbuilderUtil, valueFormatter, dyGraphUtils, chartDisplayUtils) {
        const DEFAULT_PRECISION = 3;
        const MAX_PRECISION = 10;

        function getChartMode(uiModel) {
            return safeLookup(uiModel, 'chartMode') || 'graph';
        }

        function updateModeMixins(chartModel) {
            let mixin = {};
            const chartMode = getChartMode(chartModel.sf_uiModel);
            const hasHeatMap = chartbuilderUtil.hasVisualization(chartModel.sf_uiModel, 'heatmap');
            let showLegend = false;
            let chartTarget = null;
            if (chartMode === 'graph') {
                showLegend = true;
                mixin = angular.extend(mixin, {
                    drawXAxis: true,
                    drawYAxis: true,
                    drawXGrid: false,
                    drawYGrid: !hasHeatMap,
                    skipMouseMove: false,
                    xAxisHeight: 26,
                    drawPoints: chartModel.sf_uiModel.chartconfig.showDots,
                    pointSize: 1.5,
                    highlightSeriesOpts: {
                        strokeWidth: 5,
                    },
                    highlightSeriesBackgroundAlpha: 1.0,
                    highlightStrokeWidth: 5,
                    highlightCircleSize: 0,
                });
                chartTarget = '.regular-chart-target';
            }

            return { target: chartTarget, config: mixin, legend: showLegend };
        }

        function containsDuplicateLabels(ticks) {
            for (let i = 0; i < ticks.length - 1; i++) {
                if (parseFloat(ticks[i].label) === parseFloat(ticks[i + 1].label)) {
                    return true;
                }
            }
            return false;
        }

        function axesContainDuplicateLabels(dyGraphInstance) {
            if (!dyGraphInstance || !dyGraphInstance.axes_) {
                return false;
            }

            for (let i = 0; i < dyGraphInstance.axes_.length; i++) {
                const ticks = angular.copy(dyGraphInstance.axes_[i].ticks);
                if (ticks && containsDuplicateLabels(ticks)) {
                    return true;
                }
            }
            return false;
        }

        function reformatTicks(ticks, precision, scalingUnit, chartModel) {
            const formatYValue = getYValueFormatter(precision, scalingUnit, chartModel);
            ticks.forEach((tick) => {
                tick.label = formatYValue(tick.v);
            });
            return ticks;
        }

        function getPrecisionForTicks(ticks, chartModel, dynamicPrecision) {
            let precision = dynamicPrecision || DEFAULT_PRECISION;
            const scalingUnit = getScalingUnitForAxis(chartModel.sf_uiModel.allPlots, 0);

            // Find minimum precision to represent distinct ticks
            if (containsDuplicateLabels(ticks)) {
                while (precision < MAX_PRECISION) {
                    precision++;
                    reformatTicks(ticks, precision, scalingUnit, chartModel);
                    if (!containsDuplicateLabels(ticks)) {
                        return precision;
                    }
                }
            } else {
                while (precision > DEFAULT_PRECISION) {
                    precision--;
                    reformatTicks(ticks, precision, scalingUnit, chartModel);
                    if (containsDuplicateLabels(ticks)) {
                        return precision + 1;
                    }
                }
            }

            return precision;
        }

        function updatePrecisionOptions(
            dyGraphInstance,
            dyGraphConfigCache,
            chartModel,
            dynamicPrecision
        ) {
            const configuredPrecision = chartModel.sf_uiModel.chartconfig.axisPrecision;
            if (configuredPrecision) {
                return;
            }

            dynamicPrecision = dynamicPrecision || [];

            for (let i = 0; i < dyGraphInstance.axes_.length; i++) {
                const ticks = angular.copy(dyGraphInstance.axes_[i].ticks);
                const precision = getPrecisionForTicks(ticks, chartModel, dynamicPrecision[i]);
                dynamicPrecision[i] = precision;
                updateYAxes(chartModel, dyGraphConfigCache, dynamicPrecision);
            }
            return dynamicPrecision;
        }

        function getYValueFormatter(precision, scalingUnit, chartModel) {
            // if this axis has scaling unit metrics, then reassign formatYValue to
            // a formatter that accounts for scaling units.
            let formatYValue;
            if (scalingUnit) {
                formatYValue = function (value) {
                    return valueFormatter.formatScalingUnit(value, scalingUnit, precision);
                };
            } else {
                /**
                 * Format a value for display on a Y axis.
                 *
                 * Uses valueFormatter.formatValue() to format the actual value, taking into
                 * consideration the KMB/KMG2 setting of the chart. Axis precision is
                 * bounded within [3;10]. The minimum precision of 3 prevents duplicate
                 * rounded Y axis label values.
                 */
                formatYValue = function (value) {
                    return valueFormatter.formatValue(
                        value,
                        precision,
                        chartModel.sf_uiModel.chartconfig.useKMG2
                    );
                };
            }
            return formatYValue;
        }

        function updateYAxes(chartModel, dyGraphConfigCache, dynamicPrecision) {
            // fixme (kevin): doesnt work on yaxis removal
            let yAxisConfigurationList = chartModel.sf_uiModel.chartconfig.yAxisConfigurations || [
                chartDisplayUtils.getYAxis(0),
                chartDisplayUtils.getYAxis(1),
            ];
            const yAxis2Referenced = chartModel.sf_uiModel.allPlots.some(function (plot) {
                return plot.yAxisIndex === 1;
            });

            if (!yAxis2Referenced && yAxisConfigurationList.length) {
                yAxisConfigurationList = [yAxisConfigurationList[0]];
            }

            if (!angular.isDefined(yAxisConfigurationList)) {
                return;
            }

            const configuredPrecision = chartModel.sf_uiModel.chartconfig.axisPrecision || null; // 0 is invalid
            const axisPrecision = angular.isNumber(configuredPrecision) ? configuredPrecision : 0;
            let precision = Math.min(MAX_PRECISION, Math.max(DEFAULT_PRECISION, axisPrecision));
            dynamicPrecision = dynamicPrecision || [];

            const isTimeSlice = chartDisplayUtils.isTimeSliceMode(chartModel.sf_uiModel);
            const isStacked = dyGraphConfigCache.stackedGraph;
            let hasTitle = false;

            dyGraphConfigCache.axesLabels = {};

            for (let i = 0; i < yAxisConfigurationList.length; i++) {
                precision = dynamicPrecision[i] || precision;
                const scalingUnit = getScalingUnitForAxis(chartModel.sf_uiModel.allPlots, i);
                const formatYValue = getYValueFormatter(precision, scalingUnit, chartModel);

                let min = yAxisConfigurationList[i].min;
                let max = yAxisConfigurationList[i].max;

                if (!angular.isDefined(min) || min === null) {
                    min = isStacked ? 0 : null;
                }

                if (!angular.isDefined(max)) {
                    max = null;
                }

                const label = yAxisConfigurationList[i].label;
                const yAxisKey = 'y' + (i === 0 ? '' : i + 1);
                hasTitle = label || hasTitle;
                const axisSpace = (hasTitle ? 16 : 0) + Math.max(precision, 4) * 10;
                //ignore yaxis configurations unless we're plotting actual graphs not sparklines
                dyGraphConfigCache.axes[yAxisKey] =
                    chartModel.sf_uiModel.chartMode === 'graph'
                        ? {
                              valueRange: [min, max],
                              independentTicks: true,
                              ticker: dyGraphUtils.tickerFunction,
                              axisLabelFormatter: formatYValue,
                              axisLabelWidth: axisSpace,
                              pixelsPerLabel: 20,
                              axisLineColor: 'transparent',
                          }
                        : {};

                if (!isTimeSlice) {
                    dyGraphConfigCache.axesLabels[yAxisKey + 'label'] = label;
                }
            }
        }

        function updatePlotType(
            chartModel,
            dyGraphConfigCache,
            dyGraphMaps,
            getPlotObject,
            resolveSeriesName,
            streamObject
        ) {
            angular.forEach(dyGraphMaps.tsidToIndex, function (idx, tsid) {
                const targetedPlot = getPlotObject(streamObject.metaDataMap[tsid], chartModel);
                resolveSeriesName(streamObject.metaDataMap[tsid], chartModel);
                let plotType = null;

                plotType = safeLookup(targetedPlot, 'configuration.visualization');

                //TODO : HOW DO I NULLIFY A PLOTTER WITHOUT BREAKING EVERYTHING? FILLGRAPH NOT COMPATIBLE WITH PLOTTER
                plotType = plotType || chartModel.sf_uiModel.chartType || 'line';
                let fill = false;
                let plotter = null;
                switch (plotType) {
                    case 'area':
                        plotter = dyGraphUtils.plotters.area;
                        fill = true;
                        break;
                    case 'column':
                        plotter = dyGraphUtils.plotters.bar;
                        fill = false;
                        break;
                    case 'line':
                        plotter = dyGraphUtils.plotters.line;
                        break;
                    case 'heatmap':
                        plotter = dyGraphUtils.plotters.heatmap;
                        break;
                }

                if (!dyGraphConfigCache.series[tsid]) {
                    dyGraphConfigCache.series[tsid] = {};
                }

                if (plotter) {
                    dyGraphConfigCache.series[tsid].plotter = plotter;
                }
                dyGraphConfigCache.series[tsid].fillGraph = fill;
            });
        }

        const updateSeries = function (
            chartModel,
            dyGraphConfigCache,
            dyGraphMaps,
            getPlotObject,
            resolveSeriesName,
            streamObject
        ) {
            const needRender = false;
            angular.forEach(dyGraphMaps.tsidToIndex, function (idx, tsid) {
                const targetedPlot = getPlotObject(streamObject.metaDataMap[tsid], chartModel);
                resolveSeriesName(streamObject.metaDataMap[tsid], chartModel);
                let yAxisIndex = 0;
                if (targetedPlot) {
                    yAxisIndex = angular.isDefined(targetedPlot.yAxisIndex)
                        ? targetedPlot.yAxisIndex
                        : 0;
                }

                if (!dyGraphConfigCache.series[tsid]) {
                    dyGraphConfigCache.series[tsid] = {};
                }

                const ts = dyGraphConfigCache.series[tsid];
                if (yAxisIndex === 0) {
                    dyGraphConfigCache.series[tsid].strokePattern = [];
                    ts.axis = 'y';
                } else {
                    dyGraphConfigCache.series[tsid].strokePattern = [10, 2];
                    ts.axis = 'y' + (yAxisIndex + 1);
                }
            });
            return needRender;
        };

        const updatePlotNames = function (chartModel, dyGraphConfigCache, dyGraphMaps) {
            angular.forEach(dyGraphMaps.tsidToIndex, function (idx, tsid) {
                //var desiredName = scope.resolveSeriesName(scope.streamObject.metaDataMap[tsid],chartModel);
                dyGraphConfigCache.labels[idx] = tsid;
            });
            return false;
        };

        const updateBucketCount = function (chartModel, dyGraphConfigCache) {
            dyGraphConfigCache.bucketCount = chartModel.sf_uiModel.chartconfig.bucketCount;
        };

        const updateAllOptions = function (
            chartModel,
            dyGraphConfigCache,
            dyGraphMaps,
            getPlotObject,
            resolveSeriesName,
            streamObject
        ) {
            if (!chartModel) {
                return;
            }
            updateYAxes(chartModel, dyGraphConfigCache);
            updatePlotType(
                chartModel,
                dyGraphConfigCache,
                dyGraphMaps,
                getPlotObject,
                resolveSeriesName,
                streamObject
            );
            updateSeries(
                chartModel,
                dyGraphConfigCache,
                dyGraphMaps,
                getPlotObject,
                resolveSeriesName,
                streamObject
            );
            updatePlotNames(chartModel, dyGraphConfigCache, dyGraphMaps);
            updateBucketCount(chartModel, dyGraphConfigCache);
            updateModeMixins(chartModel);
        };

        function getScalingUnitForAxis(allPlots, yAxisIndex) {
            const plotsOnAxis = allPlots.filter((p) => {
                return (
                    p.yAxisIndex === yAxisIndex &&
                    !p.transient &&
                    !p.invisible &&
                    (p.type === 'plot' || p.type === 'ratio')
                );
            });

            if (plotsOnAxis.length) {
                const firstUnitType = safeLookup(plotsOnAxis[0], 'configuration.unitType');

                if (!firstUnitType) {
                    return;
                }

                const axisHasCommonUnit = plotsOnAxis.every((p) => {
                    return (p.configuration || {}).unitType === firstUnitType;
                });

                if (axisHasCommonUnit) {
                    return firstUnitType;
                }
            }
        }

        return {
            updateModeMixins: updateModeMixins,
            updateAllOptions: updateAllOptions,
            updatePrecisionOptions: updatePrecisionOptions,
            axesContainDuplicateLabels: axesContainDuplicateLabels,
        };
    },
];
