import templateUrl from './legendKeyConfigurator.tpl.html';

angular.module('signalview.chartbuilder').directive('legendKeyConfigurator', [
    'chartDisplayUtils',
    function (chartDisplayUtils) {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                model: '=',
                legendKeys: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.ready = false;
                $scope.sortOptions = { axis: 'y' };

                function getDisplayableKeys(keyset) {
                    if (!keyset) {
                        return keyset;
                    }
                    return keyset.filter(function (key) {
                        return key !== 'jobId' && key !== 'computationId';
                    });
                }

                function initializeKeyList() {
                    if (!$scope.legendKeys || $scope.legendKeys.length === 0) {
                        return;
                    }
                    $scope.originalColumnConfiguration = angular.copy($scope.model);
                    if ($scope.originalColumnConfiguration) {
                        $scope.configType = 'CUSTOM';
                        $scope.keyList = updateKeyList(
                            getDisplayableKeys($scope.legendKeys),
                            $scope.originalColumnConfiguration
                        );
                    } else {
                        $scope.configType = 'AUTO';
                    }
                }

                function updateKeyList(jobKeys, configKeys) {
                    // jobkeys : array of strings
                    // configKeys : array of objects
                    // return the existing configuration appended with any extra keys in this job.
                    const allConfiguredKeys = {};
                    configKeys.forEach(function (confKey) {
                        allConfiguredKeys[confKey.property] = true;
                    });

                    const keyList = configKeys;
                    angular.forEach(jobKeys, function (key) {
                        if (!allConfiguredKeys[key]) {
                            keyList.push({
                                enabled: true,
                                property: key,
                            });
                        }
                    });

                    return keyList;
                }

                $scope.$watch('legendKeys', function (newval) {
                    $scope.ready = newval && newval.length > 0;
                    if ($scope.ready) {
                        initializeKeyList();
                    }
                });

                $scope.$watch(
                    'keyList',
                    function () {
                        $scope.onOk();
                    },
                    true
                );

                $scope.$watch('configType', function (newval) {
                    if ($scope.ready && newval === 'CUSTOM') {
                        const hadKeyList = !!$scope.keyList;
                        $scope.keyList = updateKeyList(
                            getDisplayableKeys($scope.legendKeys),
                            $scope.keyList || []
                        );
                        if (!hadKeyList) {
                            //if this is the first time we're generating, sort by key ascending
                            $scope.keyList = $scope.keyList.sort(function (a, b) {
                                return $scope
                                    .getAliasedName(a.property)
                                    .toLowerCase()
                                    .localeCompare($scope.getAliasedName(b.property).toLowerCase());
                            });
                        }
                        $scope.onOk();
                    } else {
                        $scope.onOk();
                    }
                });

                $scope.getAliasedName = chartDisplayUtils.getAliasedName;

                $scope.onCancel = function () {
                    if ($scope.ready) {
                        initializeKeyList();
                    }
                };

                $scope.onOk = function () {
                    if ($scope.ready) {
                        if ($scope.configType === 'CUSTOM') {
                            //copy to trigger watchers, and to never allow consumers to modify internal state
                            $scope.model = angular.copy($scope.keyList);
                        } else if ($scope.model) {
                            $scope.model = null;
                        }
                    }
                };
            },
        };
    },
]);
