import logger from '../../../../common/logger';

const warn = logger('ChartVersionService').extend('warn');

export function getVersion(chart) {
    if (chart.sf_modelVersion) {
        return chart.sf_modelVersion;
    }
    if (chart.sf_flowVersion === 2) {
        return 2;
    }

    if (
        (typeof chart.name !== 'undefined' || typeof chart.programText !== 'undefined') &&
        !chart.sf_id
    ) {
        return 2;
    }

    if (chart.sf_uiModel) {
        return 1;
    }

    warn('Could not be sure of chart version, returning 1.');

    return 1;
}

export function isChartDisplayTooType(candidateChart) {
    const isV2Chart = getVersion(candidateChart) === 2;

    //TODO : this is a bit unpleasant but can be cleaned up at a later date when we have only one model version
    if (isV2Chart) {
        const type = candidateChart.options.type;
        return (
            candidateChart &&
            (type === 'Text' ||
                type === 'SingleValue' ||
                type === 'List' ||
                type === 'Heatmap' ||
                type === 'SystemMap' ||
                type === 'Map' ||
                type === 'TableChart' ||
                type === 'LogsChart' ||
                type === 'LogsTimeSeriesChart')
        );
    } else {
        return (
            candidateChart &&
            (candidateChart.sf_uiModel.chartMode === 'text' ||
                candidateChart.sf_uiModel.chartMode === 'single' ||
                candidateChart.sf_uiModel.chartMode === 'list' ||
                candidateChart.sf_uiModel.chartMode === 'heatmap' ||
                candidateChart.sf_uiModel.chartMode === 'template' ||
                candidateChart.sf_uiModel.chartMode === 'systemMap' ||
                candidateChart.sf_uiModel.chartMode === 'table' ||
                candidateChart.sf_uiModel.chartMode === 'logs' ||
                candidateChart.sf_uiModel.chartMode === 'logsTimeSeries')
        );
    }
}
