export default [
    '$http',
    '$log',
    'API_URL',
    function ($http, $log, API_URL) {
        const BASE_URL = `${API_URL}/v2/role`;

        function getAllRoles() {
            return $http
                .get(BASE_URL)
                .then((response) => {
                    return response.data;
                })
                .catch((e) => {
                    $log.error(e.message);
                    throw e;
                });
        }

        return {
            getAllRoles,
        };
    },
];
