export default [
    'NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP',
    'NOTIFIER_INTEGRATIONS_V2_MAP',
    'NOTIFIER_INTEGRATIONS',
    function (
        NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP,
        NOTIFIER_INTEGRATIONS_V2_MAP,
        NOTIFIER_INTEGRATIONS
    ) {
        this.convertV1ListToV2 = function (notifications) {
            if (notifications === null) return [];

            return angular.copy(notifications).map(function (notification) {
                notification.type = NOTIFIER_INTEGRATIONS_V2_MAP[notification.type];
                delete notification.credentialName;

                return notification;
            });
        };

        this.convertV2ListToV1 = function (notifications) {
            if (notifications === null) return [];

            return angular.copy(notifications).map(function (notification) {
                if (NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP[notification.type]) {
                    notification.type = NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP[notification.type];
                }
                return notification;
            });
        };

        this.getIntegrationNameForV1orV2 = function (type) {
            let serviceName = type;
            if (NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP[type]) {
                serviceName = NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP[type];
            }
            return NOTIFIER_INTEGRATIONS[serviceName];
        };

        this.normalizeTypeIdentifier = function (type) {
            if (NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP[type]) {
                return NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP[type];
            }
            return type;
        };
    },
];
