angular
    .module('signalview.sharing')

    /**
     * Controller for sharing composer.
     * Requires passing in a 'type' string for the object
     * Requires passing in a function generateShareableSnapshot which generates a snapshot and returns
     * a info about the new snapshot
     */
    .controller('SharingController', [
        '$scope',
        '$q',
        '$timeout',
        'currentUser',
        'shareableSnapshotService',
        'sharingService',
        'type',
        'generateShareableSnapshot',
        'organizationService',
        function (
            $scope,
            $q,
            $timeout,
            currentUser,
            shareableSnapshotService,
            sharingService,
            type,
            generateShareableSnapshot,
            organizationService
        ) {
            $scope.type = type;
            $scope.title = 'Share ' + type;
            $scope.instructions =
                'Share a copy of the current state of this ' +
                type +
                ' (including unsaved changes). The copy can be edited without affecting the original ' +
                type +
                '.';
            $scope.message = '';
            $scope.notifications = [];
            $scope.generatingSnapshot = !!generateShareableSnapshot;
            $scope.types = sharingService.getShareableNotificationTypes();
            $scope.invite = false;
            $scope.toInvite = [];
            $scope.disabledEmails = [];
            $scope.tmpNotifications = [];

            currentUser.isAdmin().then(function (isAdmin) {
                $scope.isAdmin = isAdmin;
            });

            $scope.share = function () {
                $scope.error = '';
                $scope.processing = true;
                $scope.tmpNotifications = $scope.tmpNotifications.filter(function (n) {
                    return (
                        $scope.invite ||
                        n.type !== 'email' ||
                        $scope.toInvite.indexOf(n.email) === -1
                    );
                });
                $scope.notifications = angular.copy($scope.tmpNotifications);
                const snapshot = $scope.snapshot;
                let result;
                if ($scope.message) {
                    result = shareableSnapshotService.update({
                        ...snapshot,
                        description: $scope.message,
                    });
                }
                const notifications = sharingService.processNotifications($scope.notifications);
                (result || $q.when())
                    .then(function () {
                        return shareableSnapshotService.notify(snapshot.id, notifications);
                    })
                    .then(function () {
                        $scope.tmpNotifications = [];
                        $scope.toInvite = [];
                        $scope.disabledEmails = [];
                        const message = sharingService.getSharedStr(notifications);
                        if (message) {
                            $scope.sharedMessage = message;
                            $timeout(function () {
                                $scope.$close();
                            }, 2000);
                        } else {
                            $scope.$close();
                        }
                    })
                    .catch(function (error) {
                        $scope.error = error;
                    })
                    .finally(function () {
                        $scope.processing = false;
                    });
            };

            function updateDisabledEmails() {
                if ($scope.invite) {
                    $scope.disabledEmails = [];
                } else {
                    $scope.disabledEmails = angular.copy($scope.toInvite);
                }
            }

            function uniquify() {
                const emails = {};
                const slack = {};
                const office365 = {};
                return $scope.notifications.filter(function (n) {
                    if (n.type === 'email') {
                        const email = n.email.toLowerCase();
                        if (!emails[email] && n.email.match(/\S+\@\S+\.\S+/)) {
                            emails[email] = true;
                            return true;
                        }
                        return false;
                    } else if (n.type === 'slack') {
                        if (slack[n.channel + n.credentialName]) return false;
                        slack[n.channel + n.credentialName] = true;
                        return true;
                    } else if (n.type === 'office365') {
                        if (office365[n.credentialId + n.credentialName]) return false;
                        office365[n.credentialId + n.credentialName] = true;
                        return true;
                    }
                });
            }

            $scope.validateMembership = function () {
                let emails = [];
                const tmpNotifications = uniquify();
                tmpNotifications.forEach(function (n) {
                    if (n.type === 'email' && n.email && n.email.match(/\S+\@\S+\.\S+/)) {
                        emails.push(n.email.toLowerCase());
                    }
                });
                if (emails.length) {
                    organizationService
                        .searchMembersWithArbitraryQuery(
                            'sf_email:(' + emails.join(' OR ') + ')',
                            0,
                            100
                        )
                        .then(function (response) {
                            const found = response.results.map(function (r) {
                                return r.email.toLowerCase();
                            });
                            emails = emails.filter(function (e) {
                                return found.indexOf(e) === -1;
                            });
                            $scope.toInvite = emails;
                        })
                        .catch(function () {
                            $scope.toInvite = [];
                        })
                        .finally(function () {
                            $scope.notifications = $scope.tmpNotifications =
                                angular.copy(tmpNotifications);
                            updateDisabledEmails();
                        });
                } else {
                    $scope.notifications = $scope.tmpNotifications = angular.copy(tmpNotifications);
                    $scope.toInvite = [];
                    updateDisabledEmails();
                }
            };

            $scope.$watch('invite', updateDisabledEmails);

            if (generateShareableSnapshot) {
                generateShareableSnapshot()
                    .then(function (snapshotInfo) {
                        $scope.snapshot = snapshotInfo.snapshot;
                        $scope.link = snapshotInfo.link;
                        $scope.daysTo = snapshotInfo.daysTo;
                    })
                    .catch(function (error) {
                        $scope.error = error;
                    })
                    .finally(function () {
                        $scope.generatingSnapshot = false;
                    });
            }
        },
    ]);
