import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import { encodeSearch } from '@splunk/olly-common/utils/urlUtils';

angular.module('chartbuilderUtil').factory('chartUtils', [
    'plotUtils',
    'Chart',
    'urlOverridesService',
    'dashboardVariableUtils',
    'sourceFilterService',
    'chartVersionService',
    'signalboostUtil',
    'signalFlowProgramUtils',
    'programTextUtils',
    'dashboardVariablesService',
    function (
        plotUtils,
        Chart,
        urlOverridesService,
        dashboardVariableUtils,
        sourceFilterService,
        chartVersionService,
        signalboostUtil,
        signalFlowProgramUtils,
        programTextUtils,
        dashboardVariablesService
    ) {
        const PREVIEW_CHART_ID = 'PREVIEW_CHART_ID';
        const TRUNCATE_DETECTOR_LIST_THRESHOLD = 5;
        const NORMAL_SEVERITY = 'Normal';

        function isPreviewChart(chart) {
            return chart && chart.sf_id === PREVIEW_CHART_ID;
        }

        function createPreviewChart(obj) {
            const chart = Chart.create();
            chart.id(PREVIEW_CHART_ID);
            chart.name('Preview');

            const plot = chart.plot();
            plot.metric(obj.sf_metric);

            if (obj.sf_key.length > 1) {
                obj.sf_key.forEach(function (key) {
                    if (key === 'sf_metric') return;

                    plot.propertyFilter(key, obj[key]);
                });
            }

            return chart.config();
        }

        function addVariables(sources, defaultVariables, overrideVariables) {
            let variables = defaultVariables.map(function (v) {
                return dashboardVariableUtils.getValueStrFromVariable(v);
            });

            variables = variables.concat(
                overrideVariables
                    .filter(function (v) {
                        return dashboardVariableUtils.getVariableFromStr(v).value;
                    })
                    .map(function (v) {
                        return v.split('=')[1];
                    })
            );
            let newSources = sources.filter(function (f) {
                let propertyName = f.split(':')[0];
                if (propertyName) {
                    propertyName = propertyName.replace(/^[!~]+/, '');
                }
                return !variables.some(function (v) {
                    const propertyMatch = v.split(':')[0] === propertyName;
                    const withValue = !!v.split(':')[1];
                    return propertyMatch && withValue;
                });
            });
            newSources = newSources.concat(variables);
            return newSources;
        }

        function isOverrideable(variableDefinition, overrideVariableStr) {
            const override = dashboardVariableUtils.getVariableFromStr(overrideVariableStr);
            return !!(
                override.alias === variableDefinition.alias &&
                override.property === variableDefinition.property &&
                (!variableDefinition.required || override.value)
            );
        }

        // remove overrides that don't have a definition in the default variables
        function filterOverrideVariables(defaultVariables, overrideVariables) {
            return overrideVariables.filter(function (v) {
                return defaultVariables.some(function (f) {
                    return isOverrideable(f, v);
                });
            });
        }
        // remove default variables that are overridden
        function filterDefaultVariables(defaultVariables, overrideVariables) {
            return defaultVariables.filter(function (f) {
                return (
                    angular.isDefined(f.value) &&
                    f.value !== null &&
                    !overrideVariables.some(function (v) {
                        return isOverrideable(f, v);
                    })
                );
            });
        }

        function getChartOverridesCustom(filterAlias, sources) {
            const defaultVariables = filterAlias || [];
            const mergedVariables =
                dashboardVariablesService.applyVariableOverridesToVariableModel(defaultVariables);
            const variables = mergedVariables.filter(
                dashboardVariableUtils.getVariableDefaultValue
            );

            return addVariables(sources || [], variables, []).map(function (v) {
                const filterOptions = sourceFilterService.extractFilterOptions(v);
                const kv = v.split(':');
                const value = sourceFilterService.unflattenPropertyValue(kv.splice(1).join(':'));
                return {
                    key: filterOptions.property,
                    value: value,
                    not: filterOptions.NOT,
                    applyIfExists: filterOptions.applyIfExists,
                };
            });
        }

        function getChartOverrides(filterAlias) {
            const sources = urlOverridesService.getSourceOverride() || [];
            return getChartOverridesCustom(filterAlias, sources);
        }

        function getChartFiltersLink(filterAlias, resolveVariables) {
            const chartFiltersLink = [];
            const time = urlOverridesService.getGlobalTimePicker();
            let sources = urlOverridesService.getSourceOverride() || [];
            const density = urlOverridesService.getPointDensity() || null;
            const urlVariables = dashboardVariablesService.getVariablesOverride() || [];
            const defaultVariables = filterAlias || [];
            const filteredDefaultVariables = filterDefaultVariables(defaultVariables, urlVariables);
            const overrideVariables = filterOverrideVariables(defaultVariables, urlVariables);
            if (time && time.start) {
                if (time.relative) {
                    chartFiltersLink.push('startTime=' + time.start + '&endTime=' + time.end);
                } else {
                    chartFiltersLink.push('startTimeUTC=' + time.start + '&endTimeUTC=' + time.end);
                }
            }
            if (resolveVariables) {
                sources = addVariables(sources, filteredDefaultVariables, overrideVariables);
            } else {
                const urlOverrides = dashboardVariablesService.getVariablesOverride();
                if (urlOverrides) {
                    // if any variable url overrides are present, carry them verbatim.
                    const variablesParameter = dashboardVariablesService
                        .getVariablesOverride()
                        .map(function (f) {
                            return 'variables[]=' + f;
                        })
                        .join('&');
                    chartFiltersLink.push(variablesParameter);
                }
            }

            if (sources.length) {
                chartFiltersLink.push(
                    sources
                        .map(function (f) {
                            return 'sources[]=' + f;
                        })
                        .join('&')
                );
            }

            if (density) {
                chartFiltersLink.push('density=' + density);
            }

            return encodeSearch(chartFiltersLink.join('&'));
        }

        // Return time range fields from chart's configuration
        function getChartTimeConfig(config) {
            if (!config) {
                return {};
            }
            // Make sure not to return undefined values
            return {
                absoluteStart: angular.isDefined(config.absoluteStart)
                    ? config.absoluteStart
                    : null,
                absoluteEnd: angular.isDefined(config.absoluteEnd) ? config.absoluteEnd : null,
                range: angular.isDefined(config.range) ? config.range : null,
                rangeEnd: angular.isDefined(config.rangeEnd)
                    ? config.rangeEnd
                    : angular.isDefined(config.range)
                    ? 0
                    : null,
            };
        }

        function getMetricsAndFiltersInChart(chart) {
            const metricSelectors = [];
            const filterSelectors = [];

            if (chart.sf_uiModel) {
                chart.sf_uiModel.allPlots.forEach(function (plot) {
                    if (!plot.transient && plot.seriesData && plot.seriesData.metric) {
                        metricSelectors.push(plot.seriesData.metric);
                        plot.queryItems.forEach(function (item) {
                            if (item.property !== undefined && item.propertyValue !== undefined) {
                                if (angular.isArray(item.propertyValue)) {
                                    item.propertyValue.forEach(function (v) {
                                        filterSelectors.push(item.property + ':"' + v + '"');
                                    });
                                } else {
                                    filterSelectors.push(
                                        item.property + ':"' + item.propertyValue + '"'
                                    );
                                }
                            } else {
                                const arr = item.query.split(':');
                                const key = arr[0];
                                let value = arr.splice(1).join(':');
                                if (
                                    value[0] !== '"' ||
                                    value.length < 2 ||
                                    value[value.length - 1] !== '"'
                                ) {
                                    // If it's not already quoted
                                    value = '"' + value + '"';
                                }

                                const filter = key + ':' + value;
                                filterSelectors.push(filter);
                            }
                        });
                    }
                });
            }

            return {
                metrics: metricSelectors,
                filters: filterSelectors,
            };
        }

        // NOT filters are wholly inclusive, !host:a, !host:b becomes !host:a AND !host:b
        // from other filters, the last filter trumps others, host:a, host:b - host:b trumps host:a
        function applyFiltersToPlotsForNOT(
            not,
            variablesInput,
            sourceOverridesInput,
            model,
            allowOptional
        ) {
            function filterNegateFunc(v) {
                const negated = v.NOT ? true : false;
                return negated === not;
            }
            const override = {};
            // variables are not negated by default today, at some point we might support negated variables
            // this code is looking ahead
            const variables = (variablesInput || []).filter(filterNegateFunc);
            variables.forEach(function (v) {
                if (v.propertyValue !== undefined) {
                    override[v.property] = {
                        value: v.propertyValue,
                        applyIfExists: !!v.applyIfExists,
                    };
                }
            });
            const sourceOverrides = (sourceOverridesInput || []).filter(filterNegateFunc);
            sourceOverrides.forEach(function (v) {
                const isInVariables =
                    variables &&
                    variables.some(function (v2) {
                        return v2.property === v.property;
                    });

                if (!isInVariables) {
                    override[v.property] = {
                        value: v.propertyValue,
                        applyIfExists: !!v.applyIfExists,
                    };
                }
            });

            if (!Object.keys(override).length) return;
            model.sf_uiModel.allPlots.forEach(function (plot) {
                const found = {};
                plot.queryItems.forEach(function (queryItem) {
                    const negated = queryItem.NOT ? true : false;
                    if (override[queryItem.property] && negated === not) {
                        found[queryItem.property] = override[queryItem.property];
                        queryItem.propertyValue = override[queryItem.property].value;
                        queryItem.applyIfExists = override[queryItem.property].applyIfExists;
                    }
                });
                const allOverrides = (variables || []).concat(sourceOverrides || []);
                allOverrides.forEach(function (v) {
                    if (
                        (!found[v.property] ||
                            (not && found[v.property].value !== v.propertyValue)) &&
                        v.propertyValue !== undefined &&
                        !v.replaceOnly
                    ) {
                        plot.queryItems.push({
                            property: v.property,
                            propertyValue: v.propertyValue,
                            iconClass: 'icon-properties',
                            type: 'property',
                            query: v.property + ':' + v.propertyValue,
                            value: v.propertyValue,
                            NOT: not,
                            applyIfExists: v.applyIfExists,
                            // on a relevant basis - currently only set in getEventQuery
                            optional: found[v.property] ? false : !!allowOptional,
                        });
                    }
                });
            });
        }

        function applyFiltersToPlots(variables, sourceOverrides, model, allowOptional) {
            // property:value and !property:value will be treated as separate properties
            // since one can build a query 'a:b* and !a:bcd'
            // also, variables don't support ! condition today

            // apply override filters that have NOT set to true
            applyFiltersToPlotsForNOT(true, variables, sourceOverrides, model, allowOptional);
            // apply override filters that don't have NOT set
            applyFiltersToPlotsForNOT(false, variables, sourceOverrides, model, allowOptional);
        }

        function getChartFilterQuery(charts) {
            if (!charts) return '';
            const keys = Object.keys(charts);
            if (!keys.length) return '';
            const allMetrics = {};
            keys.forEach(function (chartId) {
                const chart = charts[chartId];
                const version = chartVersionService.getVersion(chart);
                if (version !== 2) {
                    chart.sf_uiModel.allPlots.forEach(function (plot) {
                        if (plot.seriesData && plot.seriesData.metric) {
                            allMetrics[plotUtils.sanitizePlotMetric(plot)] = true;
                        }
                    });
                } else {
                    signalFlowProgramUtils.getMetrics(chart.programText).forEach(function (metric) {
                        allMetrics[sanitizeTerm(metric)] = true;
                    });
                }
            });
            const metrics = Object.keys(allMetrics);
            if (!metrics.length) {
                return '';
            }
            return signalboostUtil.inactiveEmitterFilter(
                'sf_metric:(' + metrics.join(' OR ') + ')'
            );
        }

        function getChartSignalFlow(charts) {
            if (!charts) return [];
            const keys = Object.keys(charts);
            if (!keys.length) return [];
            const allSignalFlow = [];
            keys.forEach(function (chartId) {
                const chart = charts[chartId];
                const version = chartVersionService.getVersion(chart);
                if (version !== 2) {
                    if (
                        chart.sf_uiModel.chartMode === 'graph' ||
                        chart.sf_uiModel.chartMode === 'heatmap' ||
                        chart.sf_uiModel.chartMode === 'list' ||
                        chart.sf_uiModel.chartMode === 'single'
                    )
                        allSignalFlow.push({
                            programText: programTextUtils.getV2ProgramText(chart.sf_uiModel, false),
                            packageSpecifications: '', //TODO pass this once this is ever supported
                        });
                } else {
                    if (
                        chart.options.type === 'TimeSeriesChart' ||
                        chart.options.type === 'Heatmap' ||
                        chart.options.type === 'List' ||
                        chart.options.type === 'SingleValue'
                    ) {
                        allSignalFlow.push({
                            programText: chart.programText,
                            packageSpecifications: '', //TODO pass this once this is ever supported
                        });
                    }
                    if (chart.options.type === 'RequestBasedSloChart') {
                        allSignalFlow.push({
                            programText: programTextUtils.getSLOProgramText(
                                chart.programText,
                                chart.options.goodEventsLabel,
                                chart.options.totalEventsLabel
                            ),
                            packageSpecifications: '',
                        });
                    }
                }
            });
            return allSignalFlow.filter((f) => !!f.programText);
        }

        function getSeverityToDetectorAlertCount(alertsBySeverity) {
            const severityToDetectorAlertInfo = {};
            Object.keys(alertsBySeverity).forEach(function (severity) {
                const alerts = alertsBySeverity[severity];
                const detectorAlertInfo = {};
                alerts.forEach(function (alert) {
                    if (detectorAlertInfo[alert.detectorId]) {
                        detectorAlertInfo[alert.detectorId].count += 1;
                    } else {
                        detectorAlertInfo[alert.detectorId] = {
                            count: 1,
                            detectorName: alert.detectorName,
                        };
                    }
                });
                severityToDetectorAlertInfo[severity] = detectorAlertInfo;
            });
            return severityToDetectorAlertInfo;
        }

        /**
         * Return list of detectors by severity and alert count.
         */
        function getDetectorsBySeverity(severityToDetectorToAlertCount) {
            const allDetectors = [];
            Object.keys(severityToDetectorToAlertCount).forEach(function (severity) {
                const detectorAlertInfo = severityToDetectorToAlertCount[severity];
                Object.keys(detectorAlertInfo).forEach(function (detectorId) {
                    allDetectors.push({
                        id: detectorId,
                        name: detectorAlertInfo[detectorId].detectorName,
                        alertState: severity,
                        alertCount:
                            severity !== NORMAL_SEVERITY ? detectorAlertInfo[detectorId].count : 0,
                    });
                });
            });
            return allDetectors;
        }

        function getAlertMenuState(alertsBySeverity) {
            const severityToDetectorToAlertCount =
                getSeverityToDetectorAlertCount(alertsBySeverity);
            const allDetectors = getDetectorsBySeverity(severityToDetectorToAlertCount);

            const allActiveDetectors = allDetectors.filter(
                (state) => state.alertState !== NORMAL_SEVERITY
            );

            const truncated = allActiveDetectors.slice(0, TRUNCATE_DETECTOR_LIST_THRESHOLD);
            let numTruncated = 0;
            allActiveDetectors
                .slice(TRUNCATE_DETECTOR_LIST_THRESHOLD)
                .forEach((info) => (numTruncated += info.alertCount));

            return {
                truncatedDetectors: truncated,
                allDetectors: allDetectors,
                omittedAlerts: numTruncated,
            };
        }

        function getLinkDetectorSignalFlow(programText, id, name) {
            if (id && name) {
                return (
                    programText +
                    `\n\n# Linked Detector : ${name}\nalerts(detector_id="${id}").publish("${id}")`
                );
            } else {
                return (
                    programText +
                    '\n\n# Please provide a detector ID to link to and uncomment the following line:\n#alerts(detector_id="<ID>").publish()'
                );
            }
        }

        return {
            createPreviewChart: createPreviewChart,
            isPreviewChart: isPreviewChart,
            getChartFiltersLink: getChartFiltersLink,
            getChartOverrides: getChartOverrides,
            getChartOverridesCustom: getChartOverridesCustom,
            getChartTimeConfig: getChartTimeConfig,
            isVariableOverrideable: isOverrideable,
            getMetricsAndFiltersInChart: getMetricsAndFiltersInChart,
            applyFiltersToPlots: applyFiltersToPlots,
            getChartSignalFlow,
            getChartFilterQuery,
            getAlertMenuState,
            getLinkDetectorSignalFlow,
        };
    },
]);
