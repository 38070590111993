import templateUrl from './checklist.html';

angular.module('sfx.ui').directive('checklist', [
    '$timeout',
    function ($timeout) {
        return {
            controller: [
                '$scope',
                'NEWRELIC_FILTER_KEYS',
                function ($scope, NEWRELIC_FILTER_KEYS) {
                    $scope.NEWRELIC_FILTER_KEYS = NEWRELIC_FILTER_KEYS;
                    $scope.isActive = function (item) {
                        return $scope.selectedItems.indexOf(item) > -1;
                    };
                },
            ],
            link: function ($scope, element, attrs, ngForm) {
                /**
                 * Remove *item* from *items* if in *items*, otherwise
                 * add *item* to *items*.
                 */
                $scope.toggle = function (item) {
                    const index = $scope.selectedItems.indexOf(item);
                    if (index === -1) {
                        $scope.selectedItems.push(item);
                    } else {
                        $scope.selectedItems.splice(index, 1);
                    }
                    ngForm.$setDirty();
                };

                /**
                 * Show a transient product to pick a new
                 * product.
                 */
                $scope.addTransientItem = function () {
                    $scope.showTransientItem = true;
                };

                /**
                 * Add a new product to list of selected products
                 * to sync metrics for.
                 */
                $scope.addItem = function (oldItem, newItem) {
                    if (!oldItem) {
                        $scope.showTransientItem = false;
                        $scope.selectedItems.push(newItem);
                    } else {
                        const index = $scope.selectedItems.indexOf(oldItem);
                        $scope.selectedItems[index] = newItem;
                        $scope.removeItem(oldItem);
                    }
                    ngForm.$setDirty();
                };

                $scope.removeItem = function (item) {
                    const index = $scope.selectedItems.indexOf(item);
                    if (index !== -1) {
                        $scope.selectedItems.splice(index, 1);
                    }
                    const key = $scope.NEWRELIC_FILTER_KEYS[item.toUpperCase()];
                    delete $scope.domains[key];
                    delete $scope.metrics[key];
                    delete $scope.stats[key];
                    ngForm.$setDirty();
                };

                function setFilters(container, item, filters) {
                    if (!filters) {
                        delete container[$scope.NEWRELIC_FILTER_KEYS[item.toUpperCase()]];
                    } else {
                        container[$scope.NEWRELIC_FILTER_KEYS[item.toUpperCase()]] = filters;
                    }
                    ngForm.$setDirty();
                }

                /**
                 * Set domains for a particular product.
                 */
                $scope.setDomains = function (item, domains) {
                    setFilters($scope.domains, item, domains);
                };

                /**
                 * Set metrics for a particular product.
                 */
                $scope.setMetrics = function (item, metrics) {
                    setFilters($scope.metrics, item, metrics);
                };

                /**
                 * Set stats for a particular product.
                 */
                $scope.setStats = function (item, stats) {
                    setFilters($scope.stats, item, stats);
                };

                $scope.$watch('credentialId', function (nval, oval) {
                    if (nval && !oval && $scope.flags) {
                        $timeout(function () {
                            $scope.flags.dismissValidated = true;
                        }, 2000);
                    }
                });
            },
            require: '^form',
            restrict: 'E',
            scope: {
                items: '=',
                selectedItems: '=',
                domains: '=',
                metrics: '=',
                stats: '=',
                editable: '=',
                credentialId: '=',
                flags: '=',
            },
            templateUrl,
        };
    },
]);
