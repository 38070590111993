// This file is ported version of react-data-grid-addons which is no longer supported
// addressing FOSSA Uncontrolled Resource Consumption in parsejson issue
// see CORN-2478

import React from 'react';
import PropTypes from 'prop-types';
import html5DragDropContext from './html5DragDropContext';
import DraggableHeaderCell from './DraggableHeaderCell';

class DraggableContainer extends React.Component {
    render() {
        return React.cloneElement(React.Children.only(this.props.children), {
            ...this.props,
            draggableHeaderCell: DraggableHeaderCell,
        });
    }
}

DraggableContainer.propTypes = {
    children: PropTypes.element.isRequired,
};

export default html5DragDropContext(DraggableContainer);
