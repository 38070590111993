export const alertListDetectorCache = [
    function () {
        /**
         * A simple cache for detectors fetched by the alert list.
         */
        const detectorsById = {};
        return {
            put: function (detector) {
                detectorsById[detector.sf_id] = detector;
            },
            getCache: function () {
                return detectorsById;
            },
            clear: function () {
                Object.keys(detectorsById).forEach(function (key) {
                    delete detectorsById[key];
                });
            },
        };
    },
];
