import subscribeToExistingDetectorModalTemplateUrl from '../detector/subscribeToExistingDetectorModal.tpl.html';
import subscribeToDetectorMissingFiltersConfirmTemplateUrl from '../detector/subscribeToDetectorMissingFiltersConfirm.tpl.html';

angular.module('signalview.dashboard').service('relatedDetectorService', [
    '$q',
    'sfxModal',
    '$log',
    'detectorService',
    'writepermissionsPermissionsChecker',
    'DetectorV2SearchService',
    function (
        $q,
        sfxModal,
        $log,
        detectorService,
        writepermissionsPermissionsChecker,
        DetectorV2SearchService
    ) {
        function subscribeToDetectorById(detectorId) {
            return detectorService.get(detectorId).then((detector) => {
                return subscribeToDetector(detector, {});
            });
        }

        function subscribeToDetector(detector, filtersToMatch) {
            const openModal = function () {
                return sfxModal.open({
                    templateUrl: subscribeToExistingDetectorModalTemplateUrl,
                    controller: 'SubscribeToExistingDetectorController',
                    size: 'md',
                    resolve: {
                        data: function () {
                            return {
                                detector,
                            };
                        },
                        hasWritePermission:
                            writepermissionsPermissionsChecker.hasWritePermissions(detector),
                    },
                    backdrop: 'static',
                    keyboard: false,
                }).result;
            };

            const override = getOverrideFilters(detector, filtersToMatch);
            if (override.length) {
                return sfxModal
                    .open({
                        templateUrl: subscribeToDetectorMissingFiltersConfirmTemplateUrl,
                        size: 'md',
                        resolve: {
                            data: function () {
                                return {
                                    detector,
                                    override: override,
                                };
                            },
                        },
                        controller: [
                            '$scope',
                            'data',
                            function ($scope, data) {
                                $scope.title = 'Detector Filters';
                                $scope.data = data;
                            },
                        ],
                        backdrop: 'static',
                        keyboard: false,
                    })
                    .result.then(openModal);
            } else {
                return openModal();
            }
        }

        function bindDetector(detector, filtersToMatch) {
            detector.subscribe = function () {
                return subscribeToDetector(detector, filtersToMatch);
            };
        }

        function getOverrideFilters(detector, filtersToMatch) {
            return Object.keys(filtersToMatch)
                .filter(function (selector) {
                    return detector.sf_sourceSelectors.indexOf(selector) === -1;
                })
                .map(function (selector) {
                    // unquote the value (in order to add it correctly to the source filter)
                    const arr = selector.split(':');
                    const key = arr[0];
                    let value = arr[1];
                    if (
                        (value[0] === '"' && value.length >= 2) ||
                        value[value.length - 1] === '"'
                    ) {
                        value = value.slice(1, value.length - 1);
                    }
                    return key + ':' + value;
                });
        }

        function validateFilterSelectors(filterSelectors) {
            return filterSelectors.every(function (f) {
                const value = f.split(':').slice(1).join(':');
                return value.charAt(0) === '"' && value.charAt(value.length - 1) === '"';
            });
        }

        function hasEquivalentFilters(
            selector,
            equivalentFilters,
            equivalentFiltersCache,
            filtersToMatch
        ) {
            if (!equivalentFilters || !equivalentFilters.length) return false;
            if (equivalentFiltersCache[selector]) return true;
            return equivalentFilters.some(function (filterSet) {
                const hasSelector = filterSet.indexOf(selector) !== -1;
                const hasFilter = filterSet.some(function (v) {
                    return filtersToMatch[v];
                });
                if (hasFilter) {
                    filterSet.forEach(function (v) {
                        equivalentFiltersCache[v] = true;
                    });
                }
                return hasSelector && hasFilter;
            });
        }

        function getDetectorsForContext(metricSelectors, filterSelectors, getCurrentQuery, chart) {
            if (!validateFilterSelectors(filterSelectors)) {
                $log.warn('A filter selector for getting relevant detectors was not quoted!');
            }
            if (metricSelectors.length) {
                const filtersToMatch = {};
                const metricsToMatch = {};
                filterSelectors.forEach(function (selector) {
                    filtersToMatch[selector] = true;
                });
                metricSelectors.forEach(function (selector) {
                    metricsToMatch[selector] = true;
                });

                const filterDetectorResult = function (detector) {
                    const equivalentFiltersCache = {};
                    // filter out the detector/template that doesn't have matching selector (it needs to be strictly subset)
                    return !detector.sf_sourceSelectors.some(function (selector) {
                        let hasMatchingFilters = filtersToMatch[selector];
                        if (!hasMatchingFilters) {
                            hasMatchingFilters = hasEquivalentFilters(
                                selector,
                                detector.sf_sourceSelectorEquivalentFilters,
                                equivalentFiltersCache,
                                filtersToMatch
                            );
                        }
                        return !hasMatchingFilters && !metricsToMatch[selector];
                    });
                };

                const promises = {
                    detector: DetectorV2SearchService.search({
                        sourceSelectors: metricSelectors.map((singleSelector) => [singleSelector]),
                        limit: 1000,
                    }),
                };

                return $q
                    .all(promises)
                    .then(function (results) {
                        const detectors = results.detector.rs.filter(filterDetectorResult);

                        return {
                            detectors: detectors,
                            matchedFilters: filtersToMatch,
                        };
                    })
                    .then(function (alerts) {
                        if (chart) {
                            return appendRelatedDetectorsFromChart(alerts.detectors, chart).then(
                                function () {
                                    return alerts;
                                }
                            );
                        } else {
                            return alerts;
                        }
                    })
                    .then(function (alerts) {
                        alerts.detectors.forEach(function (detector) {
                            bindDetector(detector, filtersToMatch);
                        });
                        return alerts;
                    });
            } else {
                return $q.when({ detectors: [], matchedFilters: {} });
            }
        }

        function getAllRelatedDetectorIds(sf_uiModel) {
            return [
                ...(sf_uiModel.relatedDetectors || []),
                ...(sf_uiModel.autoDetectRelatedDetectorIds || []),
            ];
        }

        function appendRelatedDetectorsFromChart(detectors, chart) {
            const RELATED_DETECTORS_TO_LOAD = 5;

            // The api doesn't allow for querying multiple objects by id, so we parallelize
            // as many requests for related detectors as necessary.
            const relatedDetectorIds = getAllRelatedDetectorIds(chart.sf_uiModel);

            if (relatedDetectorIds) {
                detectors.forEach(function (detector) {
                    const index = relatedDetectorIds.indexOf(detector.sf_id);
                    if (index !== -1) {
                        relatedDetectorIds.splice(index, 1);
                    }
                });

                const push = function (detector) {
                    detectors.push(detector);
                };

                // Load first 5 related detectors
                return $q.all(
                    relatedDetectorIds.slice(0, RELATED_DETECTORS_TO_LOAD).map(function (id) {
                        return detectorService.get(id).then(push, angular.noop);
                    })
                );
            } else {
                return $q.when(null);
            }
        }

        return {
            getDetectorsForContext: getDetectorsForContext,
            hasEquivalentFilters: hasEquivalentFilters,
            subscribeToDetectorById: subscribeToDetectorById,
        };
    },
]);
