import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

const className = new BEMHelper('chart-header');

export default function ChartHeader({ swatchClass, title }) {
    return (
        <header {...className()}>
            <i className={`swatch ${swatchClass}`}></i>
            <span {...className('title')}>{title}</span>
        </header>
    );
}

ChartHeader.propTypes = {
    swatchClass: PropTypes.string,
    title: PropTypes.string,
};
