angular.module('signalflowv2').factory('signalFlowProgramUtils', [
    'programAnalyzer',
    '$log',
    function (programAnalyzer, $log) {
        function getMetrics(sflow2) {
            const metrics = [];
            let summary;
            try {
                summary = programAnalyzer.getProgramSummary(sflow2);
            } catch (e) {
                $log.error('Could not parse signalflow 2 to find metrics.');
                return [];
            }
            summary.statements.forEach(function (stmt) {
                stmt.blocks.forEach(function (block) {
                    if (block.name === 'data') {
                        const metricBlockParam =
                            block.kwArguments['metric'] || block.positionalArguments[0] || null;
                        if (metricBlockParam) {
                            const type = metricBlockParam.value.getType();
                            let metric = null;
                            if (type === 'variable') {
                                metric = summary
                                    .evaluateExpression(metricBlockParam.context)
                                    .getValue();
                            } else if (type === 'string') {
                                metric = metricBlockParam.value.getValue();
                            } else {
                                $log.warn(
                                    'Could not determine metric name to suggest against in SignalFlow2'
                                );
                            }
                            if (metric !== null) {
                                metrics.push(metric);
                            }
                        }
                    }
                });
            });
            return metrics;
        }
        return {
            getMetrics: getMetrics,
        };
    },
]);
