import BusinessWorkflowSelection from './businessWorkflowSelection';
import businessWorkflowFilter from './businessWorkflowFilter';
import businessWorkflowSelector from './businessWorkflowSelector';
import businessWorkflowSelectorDataSource from './businessWorkflowSelectorDataSource';

angular
    .module('signalview.common.tracing.businessWorkflow', ['signalboost'])

    // components
    .component('businessWorkflowFilter', businessWorkflowFilter)
    .component('businessWorkflowSelector', businessWorkflowSelector)

    // services
    .service('BusinessWorkflowSelection', BusinessWorkflowSelection)
    .factory('businessWorkflowSelectorDataSource', businessWorkflowSelectorDataSource);
