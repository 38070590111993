import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { colorAccessibilityOptions } from '@splunk/olly-services/lib/services/ColorAccessibility/colorAccessibilityOptions';

angular.module('signalview.detector.wizard').service('detectorWizardMessageHandlebarService', [
    'APP_URL',
    'detectorUtils',
    '_',
    'moment',
    function (APP_URL, detectorUtils, _, moment) {
        const INPUTS_REGEX = /{{2,}[^\S\n]*inputs\.([\w]+)\.value[^\S\n]*}{2,}/;
        const FAKE_INPUT_VALUE = {
            value: '9.99', // design decision to default to a random number that looks real
        };

        function getContextObject(detector, rule) {
            const severity = rule.severityLevel || rule.severity;
            const plots = safeLookup(detector, 'sf_uiModel.allPlots') || [];
            const detectorId = detector.sf_id || detector.id;
            const params = {
                detectorId: detectorId || 'NEW_DETECTOR_ID',
                detectorName: detector.sf_detector || detector.name || 'Untitled Detector',
                detectorUrl: detectorId ? APP_URL + '#/detector/' + detectorId : 'URL',
                ruleName: rule.name || rule.detectLabel || 'Untitled Rule',
                ruleSeverity: severity,
                ruleSeverityColor:
                    colorAccessibilityOptions.default.alertColors[severity.toLowerCase()],
                readableRule:
                    rule.readable ||
                    rule.description ||
                    detectorUtils.getAutoDetectorRuleDescription(rule, plots, true),
                runbookUrl: rule.runbookUrl,
                tip: rule.tip,
                incidentId: 'DI_FNDRAEAE',
                timestamp: moment(),
                detectorOnCondition: '', // do not need for now
                detectorOffCondition: '', // do not need for now
                anomalyState: 'ANOMALOUS',
                imageUrl: 'http://your-preview-image-url.goes-here.com',
                normal: false,
                anomalous: true,
                manuallyResolved: false,
                detectorStopped: false,
                organizationId: detector.sf_organizationID,
            };

            params.inputs = {};
            const message = detectorUtils.getAutoDetectorRuleParameterizedString(rule, plots, true);
            setInputsFromMessage(params, message);

            return params;
        }

        function setInputsFromMessage(params, message) {
            const variables = getInputVariables(message);
            variables.forEach((variable) => {
                params.inputs[variable] = FAKE_INPUT_VALUE;
            });
        }

        function getInputVariables(message) {
            let match = message.match(INPUTS_REGEX);
            const descriptions = [];
            while (match !== null) {
                descriptions.push(match[1]);

                message = message.replace(INPUTS_REGEX, '');
                match = message.match(INPUTS_REGEX);
            }

            return _.uniq(descriptions);
        }

        return {
            getContextObject,
            getInputVariables,
            FAKE_INPUT_VALUE,
        };
    },
]);
