angular.module('sfx.data').service('zeroStateService', [
    '$window',
    '$q',
    'organizationService',
    'DetectorV2SearchService',
    function ($window, $q, organizationService, DetectorV2SearchService) {
        const ZERO_STATE_SERVICE_IDENTIFIER = 'zeroStateService_hasMetrics_';
        const svc = {
            queryForMetrics: oneActiveCall(queryForMetricsProvider),
            queryForDetectors: oneActiveCall(queryForDetectors),
        };

        function oneActiveCall(callback) {
            let inProgress;
            return () => {
                if (inProgress) {
                    return inProgress;
                }
                inProgress = callback().finally(() => {
                    inProgress = null;
                });
                return inProgress;
            };
        }

        function getZeroStateIdentifier(orgId) {
            return ZERO_STATE_SERVICE_IDENTIFIER + orgId;
        }

        function queryForMetricsProvider() {
            return organizationService
                .get()
                .then((org) => {
                    // only check zero state for non paying customers
                    if (org.accountType === 'TRIAL') {
                        return getZeroStateIdentifier(org.id);
                    } else {
                        return null;
                    }
                })
                .then(function (zsi) {
                    if (zsi === null) {
                        return $q.when(false);
                    }
                    let queryPromise = null;
                    if ($window.localStorage.getItem(zsi)) {
                        queryPromise = $q.when(true);
                    } else {
                        queryPromise = organizationService.hasMetrics();
                    }
                    return queryPromise.then((hasMetrics) => {
                        svc.hasNoMetrics = !hasMetrics;
                        if (hasMetrics) {
                            $window.localStorage.setItem(zsi, 'true');
                        }
                        //returning flag for has no metrics
                        return !hasMetrics;
                    });
                });
        }

        function queryForDetectors() {
            return DetectorV2SearchService.search({
                offset: 0,
                limit: 1,
            }).then((results) => {
                svc.hasNoDetectors = results.count <= 0;
                return svc.hasNoDetectors;
            });
        }

        return svc;
    },
]);
