function MetadataMessage(tsid, channel) {
    this.channel = channel;
    this.properties = {
        sf_key: [],
    };
    this.tsId = tsid;
    this.type = 'metadata';
}

MetadataMessage.prototype.addKey = function (key, val) {
    this.properties.sf_key.push(key);
    this.setProperty(key, val);
};

MetadataMessage.prototype.setProperty = function (key, val) {
    this.properties[key] = val;
};

export default MetadataMessage;
