angular.module('signalview.chartV2').service('chartV2Utils', [
    'blockMapToUIModelConverter',
    'uiModelToVisualizationOptionsService',
    'programTextUtils',
    'plotUtils',
    '$log',
    function (
        blockMapToUIModelConverter,
        uiModelToVisualizationOptionsService,
        programTextUtils,
        plotUtils,
        $log
    ) {
        function v1ToV2(v1Chart) {
            return {
                name: v1Chart.sf_chart,
                description: v1Chart.sf_description,
                programText: programTextUtils.getV2ProgramText(
                    v1Chart.sf_uiModel,
                    true,
                    false,
                    [],
                    false,
                    false,
                    true
                ), //todo check params
                options: uiModelToVisualizationOptionsService(v1Chart.sf_uiModel).serialize(),
            };
        }

        function convertPlots(data, v2Chart) {
            try {
                const uiModel = blockMapToUIModelConverter.convertBlockMap(data, v2Chart.options);
                const chart = {
                    sf_chart: v2Chart.name,
                    sf_id: v2Chart.id,
                    sf_description: v2Chart.description || '',
                    sf_uiModel: uiModel,
                    sf_viewProgramText: v2Chart.programText || '',
                    sf_flowVersion: 2,
                    sf_jobMaxDelay: 0,
                };

                return chart;
            } catch (e) {
                return null;
            }
        }

        function forcePublishNormalization(v1Chart) {
            v1Chart.sf_uiModel.allPlots.forEach(function (plot) {
                plot._originalLabel = plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
            });
        }

        function processKnownLabels(knownLabels, model, orphanedPlots) {
            const labelToIndex = {};
            angular.forEach(knownLabels, function (label, idx) {
                labelToIndex[label] = idx;
            });
            const existingLabels = {};
            let newPlots = [];
            let maxIdx = 0;
            model.sf_uiModel.allPlots.forEach(function (plot) {
                if (maxIdx < plot.uniqueKey) {
                    maxIdx = plot.uniqueKey;
                }
                if (knownLabels.indexOf(plot.name) !== -1) {
                    existingLabels[plot._originalLabel] = plot;
                    newPlots.push(plot);
                } else {
                    existingLabels[plot._originalLabel] = plot;
                    newPlots.push(plot);
                    //welp.  lets see if its okay to
                }
            });
            maxIdx++;

            knownLabels.forEach(function (label) {
                if (!existingLabels[label]) {
                    const foundOrphaned = model.sf_uiModel.allPlots.find((plot) => {
                        return plot._originalLabel === label;
                    });
                    if (foundOrphaned) {
                        newPlots.push(foundOrphaned);
                        orphanedPlots.splice(orphanedPlots.indexOf(foundOrphaned), 1);
                    } else {
                        newPlots.push({
                            uniqueKey: maxIdx++,
                            name: label,
                            yAxisIndex: 0,
                            seriesData: {},
                            configuration: {
                                colorOverride: null,
                                visualization: null,
                                prefix: null,
                                suffix: null,
                                unitType: null,
                            },
                            transient: false,
                            type: 'plot',
                            invisible: false,
                            queryItems: [],
                            dataManipulations: [],
                        });
                    }
                }
            });

            newPlots = newPlots.sort(function (a, b) {
                const aIndex = labelToIndex[a.name];
                const bIndex = labelToIndex[b.name];
                if (aIndex === bIndex) {
                    // this situation could arise if we ever decide to try to handle multiple duplicate publishes.
                    $log.error('Plot sorting had equal indices.  Order is likely inconsistent!');
                    return 0;
                }
                return aIndex > bIndex ? 1 : -1;
            });
            model.sf_uiModel.allPlots = newPlots;
        }

        return {
            v1ToV2,
            convertPlots,
            forcePublishNormalization,
            processKnownLabels,
        };
    },
]);
