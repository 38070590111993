import detectorNoNotificationConfirmTemplateUrl from '../detectorNoNotificationConfirm.tpl.html';
import detectorNameModalTemplateUrl from './detectorNameModal.tpl.html';

import {
    DetectorModelConversionError,
    DetectorModelConversionNoMatchingRuleError,
    DetectorModelConversionSignalBoostValidationError,
} from '../../../../app/detector/detectorModelConversionErrors';

angular.module('signalview.detector').factory('detectorUtils', [
    '$http',
    '$log',
    '$q',
    'sfxModal',
    '$window',
    'API_URL',
    'APM_METRIC_TYPE',
    'APM_LATENCY_METRICS',
    'APM_VOLUME_METRICS',
    'LEGACY_METADATA',
    'PACKAGE_INFO',
    'APM_PATH_PREFIX',
    '_',
    'plotUtils',
    'parseDuration',
    'analyticsService',
    'mustache',
    'signalboost',
    'detectorService',
    'confirmService',
    'programTextUtils',
    'featureEnabled',
    'functionsMetadataService',
    'ruleToSignalflowV2',
    'detectorv2Service',
    'detectorVersionService',
    'preflightEstimation',
    'chartbuilderUtil',
    'currentUser',
    'Handlebars',
    'chartUtils',
    'calendarWindowUtil',
    'blockMapToUIModelConverter',
    'v2DetectorConverter',
    'chartDisplayUtils',
    'detectorV2Converter',
    'visualizationOptionsToUIModel',
    'signalflowMetadataFinder',
    'processNotificationRecipient',
    'signalTypeService',
    function (
        $http,
        $log,
        $q,
        sfxModal,
        $window,
        API_URL,
        APM_METRIC_TYPE,
        APM_LATENCY_METRICS,
        APM_VOLUME_METRICS,
        LEGACY_METADATA,
        PACKAGE_INFO,
        APM_PATH_PREFIX,
        _,
        plotUtils,
        parseDuration,
        analyticsService,
        mustache,
        signalboost,
        detectorService,
        confirmService,
        programTextUtils,
        featureEnabled,
        functionsMetadataService,
        ruleToSignalflowV2,
        detectorv2Service,
        detectorVersionService,
        preflightEstimation,
        chartbuilderUtil,
        currentUser,
        Handlebars,
        chartUtils,
        calendarWindowUtil,
        blockMapToUIModelConverter,
        v2DetectorConverter,
        chartDisplayUtils,
        detectorV2Converter,
        visualizationOptionsToUIModel,
        signalflowMetadataFinder,
        processNotificationRecipient,
        signalTypeService
    ) {
        const FIRST_PRIME_ABOVE_1000 = 1009;
        // We don't need the whole chart options for UI Model plot conversion
        // Just chart types
        const DUMMY_CHART_OPTIONS = {
            type: 'TimeSeriesChart',
            chartType: 'LineChart',
        };
        const DETECTOR_TEAM_UPDATES = {
            LINKS: 'links',
            RECIPIENTS: 'recipients',
        };

        const DEFAULT_DEFINITION_TYPE = 'infrastructure';

        function updateDetectorValidityState(rules, referencePlots) {
            rules.forEach(function (rule) {
                updateDetectorRuleValidityState(rule, referencePlots);
            });
        }

        function updateDetectorRuleValidityState(rule, referencePlots) {
            const error = validateDetectorRule(rule, referencePlots);
            rule.invalid = error !== undefined;
            return error;
        }

        function hasAboveThreshold(rule) {
            return rule.above !== undefined && rule.above !== '';
        }

        function hasBelowThreshold(rule) {
            return rule.below !== undefined && rule.below !== '';
        }

        function validateDetectorFunctionRule(rule) {
            try {
                ruleToSignalflowV2.validateFunctionRule(rule);
            } catch (e) {
                return e;
            }
        }

        function validateRangeThreshold(rule) {
            if (isDynamicThreshold(rule.above) || isDynamicThreshold(rule.below)) {
                return '';
            }
            if (rule.thresholdMode === 'within range' || rule.thresholdMode === 'out of range') {
                const below = parseFloat(rule.below, 10);
                const above = parseFloat(rule.above, 10);
                if (below > above) {
                    return 'The lower threshold must be less than or equal to the upper threshold.';
                }
            }
            return '';
        }

        function validateCompoundConditions(rule, referencePlots) {
            if (!rule.conditions) {
                return new Error('Rule conditions are empty');
            }

            if (rule.conditions.length > 10) {
                return new Error('Cannot have more than 10 compound conditions');
            }

            const errors = rule.conditions
                .map((condition) => validateLegacyCondition(condition, referencePlots))
                .filter((error) => error)
                .join('\n');

            if (errors) {
                return new Error(errors);
            }
        }

        function validateLegacyCondition(rule, referencePlots) {
            if (!rule.targetPlot) return new Error('Missing targetPlot field');

            if (rule.targetPlot === rule.above || rule.targetPlot === rule.below) {
                return new Error('Target plot cannot be used as a threshold');
            }

            if (
                (rule.above === undefined || rule.above === '') &&
                (rule.below === '' || rule.below === undefined)
            ) {
                return new Error('Missing either an above or below field');
            }

            if (!rule.duration) return new Error('Missing duration field');

            if (
                rule.triggerMode === 'percent of duration' &&
                rule.percentOfDuration === undefined
            ) {
                return new Error(
                    'percentOfDuration is required when  triggerMode is percent of duration'
                );
            }

            if (isNaN(parseDuration(rule.duration))) {
                return new Error('Invalid duration');
            }

            if (!rule.thresholdMode) return new Error('Missing threshold mode');

            if (rule.autoResolveAfter === '') {
                return new Error('Invalid auto resolve after value');
            }

            switch (rule.thresholdMode) {
                case 'above':
                    if (rule.above === undefined) {
                        return new Error('Missing above field');
                    }
                    break;
                case 'below':
                    if (rule.below === undefined) {
                        return new Error('Missing below field');
                    }
                    break;
                case 'within range':
                case 'out of range': {
                    if (rule.above === undefined || rule.below === undefined) {
                        return new Error(
                            'Range threshold modes request both an above and below field'
                        );
                    }
                    const rangeError = validateRangeThreshold(rule);
                    if (rangeError) {
                        return new Error(rangeError);
                    }
                    break;
                }
            }

            const targetUniqueKey = plotUtils.getUniqueKeyFromLetter(rule.targetPlot);
            const isTargetValid = referencePlots.some(function (plot) {
                return !plot.transient && plot.uniqueKey === targetUniqueKey;
            });
            if (!isTargetValid) return new Error('Invalid target plot');

            if (isDynamicThreshold(rule.above)) {
                const abovePlotUniqueKey = plotUtils.getUniqueKeyFromLetter(rule.above);
                const isAbovePlotValid = referencePlots.some(function (plot) {
                    return !plot.transient && plot.uniqueKey === abovePlotUniqueKey;
                });
                if (!isAbovePlotValid) {
                    return new Error('Invalid above value');
                }
            }

            if (isDynamicThreshold(rule.below)) {
                const belowPlotUniqueKey = plotUtils.getUniqueKeyFromLetter(rule.below);
                const isBelowPlotValid = referencePlots.some(function (plot) {
                    return !plot.transient && plot.uniqueKey === belowPlotUniqueKey;
                });
                if (!isBelowPlotValid) {
                    return new Error('Invalid above value');
                }
            }
        }

        function validateDetectorRule(rule, referencePlots, skipName) {
            if (!skipName) {
                if (!rule.name) return new Error('Missing name field');
            }

            if (!rule.severityLevel) return new Error('Missing severityLevel field');

            if (rule.type === 'Function') {
                return validateDetectorFunctionRule(rule);
            } else if (hasCompoundConditions(rule)) {
                return validateCompoundConditions(rule, referencePlots);
            }
            return validateLegacyCondition(rule, referencePlots);
        }

        function getFunctionRuleSummary(inputRule, plots) {
            const rule = angular.copy(inputRule);
            const params = {
                package: rule.package,
                version: rule.version,
                path: rule.path,
                module: rule.module,
                function: rule.function,
            };
            const func = functionsMetadataService.getFunctionJson(params) || {};
            const summary = func.triggerSummary || '';
            const inputs = func.inputs || {};
            const values = {};
            const plotNames = {};
            (plots || []).forEach(function (plot) {
                if (plot.uniqueKey) {
                    const label = plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
                    plotNames[label] = plot.name || label;
                }
            });
            ruleToSignalflowV2.getInputsAndValuesForDisplay(rule, plotNames).forEach(function (v) {
                let value = '[' + inputs[v.param].displayName + ']';
                if (angular.isArray(v.value) && v.value.length) {
                    value = '[' + v.value.join(', ') + ']';
                } else if (angular.isDefined(v.value) && v.value !== null) {
                    value = v.value.toString();
                }
                // INFO: we can safely ignore the semgrep rule "handlebars_safestring", because we manually escaped the expression provided to Handlebar.SafeString method
                values[v.param] = new Handlebars.SafeString(Handlebars.escapeExpression(value)); // nosemgrep: tools.semgrep.rules.CCF.handlebars_safestring
            });
            try {
                const template = Handlebars.compile(summary);
                return template(values);
            } catch (e) {
                $log.error('error templating rule trigger summary ', e);
                return func.displayName || '';
            }
        }

        function getRuleSignalConditionStr(ruleCondition, allPlots) {
            if (isNaN(parseFloat(ruleCondition, 10))) {
                const abovePlot = plotUtils.getPlotByKey(ruleCondition, allPlots);
                if (abovePlot) {
                    return abovePlot.name;
                } else {
                    return '';
                }
            } else {
                return ruleCondition;
            }
        }

        function getThresholdSummary(rule, allPlots) {
            const hasAbove = hasAboveThreshold(rule);
            const hasBelow = hasBelowThreshold(rule);

            let abovePlotStr = '';
            if (hasAbove) {
                abovePlotStr = getRuleSignalConditionStr(rule.above, allPlots);
            }
            let belowPlotStr = '';
            if (hasBelow) {
                belowPlotStr = getRuleSignalConditionStr(rule.below, allPlots);
            }

            let threshold = '';
            switch (rule.thresholdMode) {
                case 'out of range':
                    threshold = 'outside of ' + belowPlotStr + ' and ' + abovePlotStr;
                    break;
                case 'within range':
                    threshold = 'within ' + belowPlotStr + ' and ' + abovePlotStr;
                    break;
                case 'above':
                    threshold = 'above ' + abovePlotStr;
                    break;
                case 'below':
                    threshold = 'below ' + belowPlotStr;
                    break;
                default:
                    throw new Error('No such rule threshold ' + rule.thresholdMode);
            }
            return threshold;
        }

        function getLegacyRuleDescription(rule, allPlots) {
            const targetPlot = plotUtils.getPlotByKey(rule.targetPlot, allPlots);
            if (!targetPlot) return ['Detector rule'];
            const target = 'The value of ' + targetPlot.name + ' is ';
            const threshold = getThresholdSummary(rule, allPlots);
            let trigger = '';
            if (rule.count) {
                if (parseInt(rule.count, 10) !== 1) {
                    trigger += ' ' + rule.count + ' times within ' + rule.duration;
                }
            } else {
                if (parseInt(rule.percentOfDuration, 10) !== 100) {
                    trigger += ' for ' + rule.percentOfDuration + '% of ' + rule.duration;
                } else {
                    trigger += ' for ' + rule.duration;
                }
            }

            return [target, threshold, trigger];
        }

        function getAutoDetectorRuleSplitDescription(rule, allPlots, skipName) {
            if (validateDetectorRule(rule, allPlots, skipName) !== undefined) {
                return [''];
            }

            if (rule.type === 'Function') {
                return [getFunctionRuleSummary(rule, allPlots)];
            }

            if (hasCompoundConditions(rule)) {
                return rule.conditions
                    .map((condition) => {
                        let description = [];
                        if (condition.operator) {
                            description.push(` ${condition.operator} `);
                        }
                        description = description.concat(
                            getLegacyRuleDescription(condition, allPlots)
                        );
                        return description;
                    })
                    .reduce((acc, curr) => acc.concat(curr), [])
                    .concat(['.']);
            } else {
                const description = getLegacyRuleDescription(rule, allPlots);
                description.push('.');
                return description;
            }
        }

        function getAutoDetectorRuleDescription(rule, allPlots, skipName) {
            return getAutoDetectorRuleSplitDescription(rule, allPlots, skipName).join('');
        }

        // If candidate is a number, it is considered static, otherwise it is not static.
        function isStaticThreshold(candidate) {
            return candidate !== undefined && !isNaN(parseFloat(candidate, 10));
        }

        // If a threshold is defined but isn't a float, assume it's a dynamic threshold.
        function isDynamicThreshold(candidate) {
            return !isStaticThreshold(candidate) && candidate !== undefined && candidate !== '';
        }

        function createThresholdBlock(eventType, defaultSource, rule) {
            if (!eventType || !defaultSource) {
                throw new Error('Threshold blocks must have an event name and a default source.');
            }

            const params = [];
            params.push("eventType='" + eventType + "'");
            params.push("defaultSource='" + defaultSource + "'");

            if (isStaticThreshold(rule.above)) params.push('high=' + rule.above);
            if (isStaticThreshold(rule.below)) params.push('low=' + rule.below);

            const duration = parseDuration(rule.duration);
            if (duration !== undefined) params.push('duration=' + duration);

            if (rule.count !== undefined) params.push('count=' + rule.count);

            if (rule.percentOfDuration) {
                params.push('fractionOfDuration=' + parseFloat(rule.percentOfDuration) / 100);
            }

            if (rule.thresholdMode === 'within range') params.push('inverted=1');

            return eventType + '=threshold(' + params.join(', ') + ')';
        }

        function getDetectorRuleProgramText(model, rule) {
            const statements = [];
            const plots = model.sf_uiModel.allPlots;
            const detectorId = model.sf_id;
            const detectorRevisionNumber = model.sf_uiModel.revisionNumber;

            // Skip invalid rules
            if (validateDetectorRule(rule, plots)) return;

            const ruleName = analyticsService.getPlotName(
                rule.uniqueKey,
                detectorId,
                detectorRevisionNumber
            );

            const sourceUniqueKey = plotUtils.getUniqueKeyFromLetter(rule.targetPlot);
            const sourcePlotName = analyticsService.getPlotName(
                sourceUniqueKey,
                detectorId,
                detectorRevisionNumber
            );

            // Create threshold block
            statements.push(createThresholdBlock(ruleName, sourcePlotName, rule));

            // Connect source to rule
            const sourceToRuleTemplate = '{{sourceName}} -> {{ruleName}}';
            statements.push(
                mustache.render(sourceToRuleTemplate, {
                    sourceName: sourcePlotName,
                    ruleName: ruleName,
                })
            );

            // Connect comparators to rule
            const comparatorToDetectorTemplate = '{{comparatorName}}->?{{direction}}:{{ruleName}}';

            if (isDynamicThreshold(rule.above)) {
                const aboveUniqueKey = plotUtils.getUniqueKeyFromLetter(rule.above);
                const abovePlotName = analyticsService.getPlotName(
                    aboveUniqueKey,
                    model.sf_id,
                    model.sf_uiModel.revisionNumber
                );

                statements.push(
                    mustache.render(comparatorToDetectorTemplate, {
                        comparatorName: abovePlotName,
                        direction: 'high',
                        ruleName: ruleName,
                    })
                );
            }

            if (isDynamicThreshold(rule.below)) {
                const belowUniqueKey = plotUtils.getUniqueKeyFromLetter(rule.below);
                const belowPlotName = analyticsService.getPlotName(
                    belowUniqueKey,
                    model.sf_id,
                    model.sf_uiModel.revisionNumber
                );

                statements.push(
                    mustache.render(comparatorToDetectorTemplate, {
                        comparatorName: belowPlotName,
                        direction: 'low',
                        ruleName: ruleName,
                    })
                );
            }

            return statements.join(';');
        }

        function copyViewModel(viewModel) {
            const model = {
                sf_id: viewModel.sf_id,
                sf_organizationID: viewModel.sf_organizationID,
                sf_detector: viewModel.sf_detector,
                sf_description: viewModel.sf_description,
                sf_uiModel: viewModel.sf_uiModel,
                sf_authorizedUserWriters: viewModel.sf_authorizedUserWriters,
                sf_authorizedTeamWriters: viewModel.sf_authorizedTeamWriters,
            };

            if (
                model.sf_uiModel &&
                model.sf_uiModel.chartconfig &&
                !angular.isDefined(model.sf_uiModel.chartconfig.pointDensity)
            ) {
                model.sf_uiModel.chartconfig.pointDensity = '';
            }
            if (viewModel.sf_jobMaxDelay) {
                model.sf_jobMaxDelay = parseInt(viewModel.sf_jobMaxDelay);
            } else {
                model.sf_jobMaxDelay = 0;
            }

            if (viewModel.sf_jobMinDelay) {
                model.sf_jobMinDelay = parseInt(viewModel.sf_jobMinDelay);
            } else {
                model.sf_jobMinDelay = 0;
            }

            if (viewModel.sf_timezone) {
                model.sf_timezone = viewModel.sf_timezone;
            } else {
                // explicitly send empty timezone to clear previous timezone
                model.sf_timezone = '';
            }

            if (viewModel.sf_jobResolution) {
                model.sf_jobResolution = viewModel.sf_jobResolution;
            } else {
                model.sf_jobResolution = 1000;
            }

            if (viewModel.sf_isDpmDetector) {
                model.sf_isDpmDetector = viewModel.sf_isDpmDetector;
            }

            if (viewModel.sf_teams) {
                model.sf_teams = viewModel.sf_teams;
            }

            return model;
        }

        function addEmptyDetectorRule(uiModel) {
            const rule = {
                name: '',
                uniqueKey: chartbuilderUtil.getNextUniqueKey(uiModel, true),
                notifications: [],
                jobResolution: '1 second',
                duration: '5 minutes',
                severityLevel: 'Critical',
                edit: true,
                isCustomizedMessage: false,
            };
            uiModel.rules.push(rule);
            return rule;
        }

        function generateDetectorV1Model(viewModel) {
            function cleanViewModel(viewModel) {
                const model = copyViewModel(viewModel);
                const rules = viewModel.sf_uiModel.rules;
                const plots = viewModel.sf_uiModel.allPlots;

                if (viewModel.sf_uiModel.chartconfig) {
                    delete viewModel.sf_uiModel.chartconfig.pointDensity;
                    delete viewModel.sf_uiModel.chartconfig.resolutionOverride;
                }

                const rulesByJobResolution = {};

                updateDetectorValidityState(rules, plots);

                // Skip publishing of all plots for detectors
                const plotsToSkipPublish = plots.map(function (plot) {
                    return plot.uniqueKey;
                });

                const baseProgramText = programTextUtils.getPlotsProgramText(
                    model,
                    plotsToSkipPublish,
                    false
                );

                const activeRules = rules.filter(function (rule) {
                    return !rule.invalid && !rule.disabled;
                });

                model.sf_rules = activeRules.map(function (rule) {
                    // Group this rule with other rules using the same job resolution
                    // first convert the friendly job resolution string to milliseconds
                    const resolution = parseDuration(rule.jobResolution);
                    if (!rulesByJobResolution[resolution]) {
                        rulesByJobResolution[resolution] = [];
                    }
                    rulesByJobResolution[resolution].push(rule);

                    const eventTypeName = analyticsService.getPlotName(
                        rule.uniqueKey,
                        model.sf_id,
                        model.sf_uiModel.revisionNumber
                    );

                    return {
                        baseProgramText: baseProgramText,
                        jobResolution: model.sf_jobResolution || 1000,
                        uniqueKey: rule.uniqueKey,
                        eventType: eventTypeName,
                        name: rule.name,
                        description: rule.description,
                        readable: getAutoDetectorRuleDescription(rule, plots),
                        severity: rule.severityLevel,
                        notifications: rule.notifications,
                    };
                });

                model.sf_programs = [];

                activeRules.forEach(function (rule) {
                    const ruleProgramText =
                        baseProgramText + ';' + getDetectorRuleProgramText(model, rule);

                    model.sf_programs.push({
                        programText: ruleProgramText,
                        jobResolution: model.sf_jobResolution || 1000,
                    });
                });

                return model;
            }

            viewModel.sf_uiModel.revisionNumber += 1;
            return cleanViewModel(viewModel);
        }

        function getLegacyRuleSignalValueParameterizedString(rule, allPlots) {
            const plots = {};
            plots[rule.targetPlot] = plotUtils.getPlotByKey(rule.targetPlot, allPlots).name;

            if (isDynamicThreshold(rule.above)) {
                plots[rule.above] = plotUtils.getPlotByKey(rule.above, allPlots).name;
            }

            if (isDynamicThreshold(rule.below)) {
                plots[rule.below] = plotUtils.getPlotByKey(rule.below, allPlots).name;
            }

            return getSignalValueParameterizedStringForPlots(plots);
        }

        function getSignalValueParameterizedStringForPlots(plots) {
            return _.map(plots, (plotName, plot) => {
                let parameterizedString = '';
                parameterizedString += `{{#if anomalous}}Signal value for ${plotName}: {{inputs.${plot}.value}}\n`;
                parameterizedString += `{{else}}Current signal value for ${plotName}: {{inputs.${plot}.value}}\n`;
                parameterizedString += '{{/if}}\n\n';
                return parameterizedString;
            }).join('\n');
        }

        function getCompoundConditionSignalValueParameterizedString(rule, allPlots) {
            const plots = {};
            rule.conditions.forEach((condition) => {
                plots[condition.targetPlot] = plotUtils.getPlotByKey(
                    condition.targetPlot,
                    allPlots
                ).name;
                if (isDynamicThreshold(condition.above)) {
                    const upperPlot = plotUtils.getPlotByKey(condition.above, allPlots);
                    plots[condition.above] = upperPlot.name;
                }

                if (isDynamicThreshold(condition.below)) {
                    const belowPlot = plotUtils.getPlotByKey(condition.below, allPlots);
                    plots[condition.below] = belowPlot.name;
                }
            });
            return getSignalValueParameterizedStringForPlots(plots);
        }

        function getLegacyRuleParameterizedString(rule, allPlots) {
            const targetPlot = plotUtils.getPlotByKey(rule.targetPlot, allPlots);

            if (!targetPlot) return 'Detector rule';

            let parameterizedString =
                '{{#if anomalous}}\n\tRule "{{{ruleName}}}" in detector "{{{detectorName}}}" triggered at {{timestamp}}.\n';
            parameterizedString +=
                '{{else}}\n\tRule "{{{ruleName}}}" in detector "{{{detectorName}}}" cleared at {{timestamp}}.\n';
            parameterizedString += '{{/if}}\n\n';
            parameterizedString += '{{#if anomalous}}';
            parameterizedString += '\nTriggering condition: {{{readableRule}}}\n';
            parameterizedString += '{{/if}}\n\n';

            if (hasCompoundConditions(rule)) {
                parameterizedString += getCompoundConditionSignalValueParameterizedString(
                    rule,
                    allPlots
                );
            } else {
                parameterizedString += getLegacyRuleSignalValueParameterizedString(rule, allPlots);
            }

            parameterizedString += '{{#notEmpty dimensions}}';
            parameterizedString += '\nSignal details:\n';
            parameterizedString += '{{{dimensions}}}\n{{/notEmpty}}\n\n';
            parameterizedString += '{{#if anomalous}}';
            parameterizedString += '\n{{#if runbookUrl}}Runbook: {{{runbookUrl}}}{{/if}}\n';
            parameterizedString += '{{#if tip}}Tip: {{{tip}}}{{/if}}\n';
            parameterizedString += '{{/if}}';

            return parameterizedString;
        }

        function setRuleShowThreshold(rule) {
            let showThreshold = false;
            if (rule.type === 'Function') {
                const params = getFunctionParams(rule);
                const f = functionsMetadataService.getFunctionJson(params);
                if (f && f.showThreshold) {
                    showThreshold = true;
                }
            } else {
                showThreshold = true;
            }
            rule.showThreshold = showThreshold;
        }

        function getAutoDetectorRuleParameterizedString(rule, allPlots, skipName) {
            if (validateDetectorRule(rule, allPlots, skipName) !== undefined) {
                return '';
            }

            if (rule.type === 'Function') {
                const params = getFunctionParams(rule);

                const context = {
                    orientation: rule.inputs.orientation,
                };
                return (
                    functionsMetadataService.getNotificationParameterizedString(params, context) ||
                    null
                );
            }

            return getLegacyRuleParameterizedString(rule, allPlots);
        }

        function getEventAnnotations(rule) {
            if (rule.type === 'Function') {
                const params = getFunctionParams(rule);
                return functionsMetadataService.getEventAnnotations(params) || [];
            }

            return [];
        }

        function getFunctionParams(rule) {
            return {
                package: rule.package,
                version: rule.version,
                path: rule.path,
                module: rule.module,
                function: rule.function,
            };
        }

        function generateDetectorV1SignalflowV2Model(viewModel) {
            function cleanViewModel(viewModel) {
                const model = copyViewModel(viewModel);
                model.sf_signalflowVersion = 2;
                viewModel.sf_signalflowVersion = 2;

                const rules = viewModel.sf_uiModel.rules;
                const plots = viewModel.sf_uiModel.allPlots;

                rules.forEach((rule) => {
                    if (hasCompoundConditions(rule)) {
                        rule.conditions.forEach((condition) => {
                            const description = getLegacyRuleDescription(condition, plots);
                            description.push('.');
                            condition.readableCondition = description.join('');
                        });
                    }
                });

                if (viewModel.sf_uiModel.chartconfig) {
                    delete viewModel.sf_uiModel.chartconfig.pointDensity;
                    delete viewModel.sf_uiModel.chartconfig.resolutionOverride;
                    delete viewModel.sf_uiModel.chartconfig.forcedResolution;

                    // doesn't make sense to persist absolute time for detector view
                    delete viewModel.sf_uiModel.chartconfig.absoluteStart;
                    delete viewModel.sf_uiModel.chartconfig.absoluteEnd;
                }

                // remove minimum resolution
                delete model.sf_jobResolution;

                updateDetectorValidityState(rules, plots);

                const plotsToSkipPublish = plots.map(function (plot) {
                    return plot.uniqueKey;
                });

                model.sf_programText = programTextUtils.getV2ProgramText(
                    { allPlots: plots, rules: rules },
                    true,
                    true,
                    plotsToSkipPublish,
                    false,
                    true
                );
                viewModel.sf_programText = model.sf_programText;

                const activeRules = rules.filter(function (rule) {
                    return !rule.invalid;
                });

                model.sf_rules = activeRules.map(function (rule) {
                    let parameterized;
                    if (rule.parameterized) {
                        parameterized = compileParameterizedField(rule.parameterized);
                    }
                    if (!parameterized) {
                        parameterized = getAutoDetectorRuleParameterizedString(rule, plots);
                    }
                    // clean up and put it back.
                    rule.parameterized = parameterized;

                    let parameterizedSubject;
                    if (rule.parameterizedSubject) {
                        parameterizedSubject = compileParameterizedField(rule.parameterizedSubject);
                    }

                    if (!rule.readable) {
                        rule.readable = getAutoDetectorRuleDescription(rule, plots);
                    }

                    const toReturn = {
                        detectLabel: rule.name,
                        description: rule.description,
                        disabled: rule.disabled || false,
                        readable: rule.readable || getAutoDetectorRuleDescription(rule, plots),
                        parameterized: parameterized,
                        parameterizedSubject: parameterizedSubject,
                        severity: rule.severityLevel,
                        notifications: rule.notifications,
                        isCustomizedMessage: rule.isCustomizedMessage,
                    };
                    ['runbookUrl', 'tip'].forEach(function (property) {
                        if (angular.isDefined(rule[property]) && rule[property] !== null) {
                            toReturn[property] = rule[property];
                        }
                    });
                    return toReturn;
                });

                model.sf_programs = [];
                viewModel.sf_programs = [];
                delete model.sf_viewProgramText;
                delete viewModel.sf_viewProgramText;
                delete model.sf_packageSpecifications;
                return model;
            }

            viewModel.sf_uiModel.revisionNumber += 1;
            return cleanViewModel(viewModel);
        }

        function compileParameterizedField(toCompile) {
            try {
                $window.Handlebars.compile(toCompile)();
                return toCompile;
            } catch (e) {
                $log.error('Parameterized error', e);
                return null;
            }
        }

        function saveDetectorV1(model) {
            return detectorService.save(model);
        }

        function saveDetectorV1SignalflowV2(model) {
            if (model.sf_id) {
                return signalboost.detector1flow2.one(model.sf_id).patch(model);
            } else {
                delete model.sf_id;
                return signalboost.detector1flow2.create(model);
            }
        }

        function getNotificationDisplayDetails(rules) {
            let notifications = [];
            rules.forEach((rule) => {
                notifications = notifications.concat(rule.notifications);
            });
            return processNotificationRecipient.getDisplayDetails(notifications);
        }

        function saveConvertedFromV2Detector(detector) {
            return v2DetectorConverter.saveV1AsV2(detector).then((v2Model) => {
                // Fetch display details (teams/credentials)
                // to show teams and opsgenie notifications correctly.
                return (
                    getNotificationDisplayDetails(v2Model.rules)
                        // fresh model from save response needs to be converted to v1 detector model again
                        // so we need to fetch signalFlowModel and generate sf_uiModel
                        .then(() => getSignalFlowModel(v2Model.programText))
                        .then((signalFlowModel) => {
                            const sf_uiModel = getV2DetectorUIModel(v2Model, signalFlowModel);
                            chartbuilderUtil.createTransientIfNeeded(sf_uiModel);

                            // convert v2 model into v1
                            return detectorV2Converter.v2ModelToV1Model(v2Model, sf_uiModel);
                        })
                );
            });
        }

        function saveDetector(viewModel, forceFlow2) {
            const model = generateDetectorModel(viewModel, forceFlow2);

            return currentUser
                .orgId()
                .then(
                    function (orgId) {
                        model.sf_organizationID = orgId;
                        // v2 detectors converted to v1 model structure should have
                        // sf_modelVersion = 2 (see: detectorV2Converter.v2ModelToV1Model())
                        if (viewModel.sf_modelVersion === 2) {
                            return saveConvertedFromV2Detector(model);
                        } else if (isFlow2(viewModel)) {
                            return saveDetectorV1SignalflowV2(model);
                        } else {
                            return saveDetectorV1(model);
                        }
                    },
                    function (e) {
                        return $q.reject(e);
                    }
                )
                .then(
                    function (model) {
                        // Update responses do not provide the id, this fills that gap
                        if (!model.sf_id) model.sf_id = viewModel.sf_id;
                        viewModel.sf_currentJobIds = model.sf_currentJobIds;
                        viewModel.sf_programs = model.sf_programs || [];
                        viewModel.sf_rules = model.sf_rules;
                        if (model.sf_programText) {
                            viewModel.sf_programText = model.sf_programText;
                        }
                        if (model.sf_signalflowVersion) {
                            viewModel.sf_signalflowVersion = model.sf_signalflowVersion;
                        }

                        return model;
                    },
                    function (e) {
                        return $q.reject(e);
                    }
                )
                .catch(function (e) {
                    // rollback revision since the update failed
                    viewModel.sf_uiModel.revisionNumber -= 1;
                    $log.error('Unable to save detector', e);
                    return $q.reject(e);
                });
        }

        function generateDetectorModel(viewModel, forceFlow2) {
            if (forceFlow2 || isFlow2(viewModel)) {
                return generateDetectorV1SignalflowV2Model(viewModel);
            } else {
                return generateDetectorV1Model(viewModel);
            }
        }

        function isFlow2(model) {
            // if it's a new detector and feature flag for 1.5 is enabled
            // or if it's an existing 1.5 detector
            return model.sf_signalflowVersion === 2;
        }

        function hasCompoundConditions(rule) {
            return rule.conditions;
        }

        function hasDynamicThreshold(rule) {
            return (
                hasCompoundConditions(rule) ||
                (hasAboveThreshold(rule) && isDynamicThreshold(rule.above)) ||
                (hasBelowThreshold(rule) && isDynamicThreshold(rule.below))
            );
        }

        function hasThresholdOrCompoundConditions(rule) {
            return (
                hasCompoundConditions(rule) || hasAboveThreshold(rule) || hasBelowThreshold(rule)
            );
        }

        function getFunctionStreamName(rule) {
            try {
                const inputs = functionsMetadataService.getFunctionJson(rule).inputs || {};
                const streamName = Object.keys(inputs).find(
                    (input) => inputs[input].dataType.type === 'Stream'
                );
                if (!streamName) {
                    $log.warn('No streams in this rule.');
                }
                return streamName;
            } catch (e) {
                $log.warn('No rule metadata ', e);
                return '';
            }
        }

        function getFunctionStream(rule) {
            const streamName = getFunctionStreamName(rule);
            return (rule.inputs || {})[streamName];
        }

        function getPlotsForFunctionRule(rule, plots, types) {
            if (!types || !types.length) {
                types = ['plot'];
            }
            let plotsToReturn = [];
            try {
                const ruleInputs = rule.inputs || {};
                const inputs = functionsMetadataService.getFunctionJson(rule).inputs || {};
                Object.keys(inputs)
                    .filter(function (input) {
                        return inputs[input].dataType.type === 'Stream';
                    })
                    .forEach(function (streamName) {
                        const plotLetter = ruleInputs[streamName];
                        if (plotLetter) {
                            plotsToReturn = plotsToReturn.concat(
                                plotUtils.getAllRelevantPlotObjects(plotLetter, plots, types)
                            );
                        }
                    });
            } catch (e) {
                $log.warn('No rule metadata ', e);
            }
            return plotsToReturn;
        }

        function getMetricPlotsForFunctionRule(rule, plots) {
            return getPlotsForFunctionRule(rule, plots, ['plot']);
        }

        function validateFunctionInput(plots, rule, inputs) {
            const model = { allPlots: plots, rules: [rule] };
            const programText = programTextUtils.getV2ProgramText(model, true, true);
            return $http({
                method: 'POST',
                url: API_URL + '/v2/signalflow/_/getProgramInfo',
                data: programText,
                headers: {
                    'Content-Type': 'text/plain',
                },
            }).then(
                function (success) {
                    $log.info('Validation success.', success);
                },
                function (resp) {
                    const data = resp.data;
                    $log.warn('Function input is erroneous.', data);
                    const toReturn = {
                        error: 'There was an error.',
                    };
                    if (
                        data &&
                        data.errorType &&
                        data.errorType === 'ANALYTICS_PROGRAM_ASSERTION_ERROR' &&
                        data.context
                    ) {
                        const context = data.context.context;
                        const template = context.sfui_errorTemplate;
                        const values = {};
                        angular.forEach(context, function (value, key) {
                            if (inputs[key] && inputs[key].displayName) {
                                values[key] =
                                    '"' + inputs[key].displayName + '" (value ' + value + ')';
                            } else {
                                values[key] = value;
                            }
                        });
                        delete values.sfui_errorTemplate;
                        delete values.sfui_errorKeys;
                        const error = $window.Handlebars.compile(template);
                        toReturn.keys = {};
                        // TODO remove sfui_error_keys after joe fix
                        (context.sfui_errorKeys || []).forEach(
                            (key) => (toReturn.keys[key] = true)
                        );
                        try {
                            toReturn.error = error(values);
                        } catch (e) {
                            $log.error('Failed applying template.', e);
                        }
                    }
                    return $q.reject(toReturn);
                }
            );
        }

        function detectorNameModal(detectorName) {
            const modalInstance = sfxModal.open({
                templateUrl: detectorNameModalTemplateUrl,
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.detectorName = detectorName;
                    },
                ],
                size: 'md',
                backdrop: 'static',
                keyboard: false,
            });
            return modalInstance.result;
        }

        function signalResolution() {
            let signalResolutionPromises = {};
            let lastPlots = null;
            return function (plotLabel, allPlotsInput) {
                if (!angular.equals(lastPlots, allPlotsInput)) {
                    signalResolutionPromises = {};
                    lastPlots = angular.copy(allPlotsInput);
                }
                // if we have resolution promise in cache, just return that
                if (signalResolutionPromises[plotLabel]) {
                    return signalResolutionPromises[plotLabel];
                }

                const allPlots = plotUtils.getAllRelevantPlotAndRatioObjects(
                    plotLabel,
                    allPlotsInput
                );
                const plotKey = plotUtils.getUniqueKeyFromLetter(plotLabel);
                allPlots.forEach(function (plot) {
                    plot.invisible = plot.uniqueKey !== plotKey;
                });
                const model = { allPlots };
                const programText = programTextUtils.getV2ProgramText(model, true);
                const promise = preflightEstimation(programText);
                signalResolutionPromises[plotLabel] = promise;
                return promise;
            };
        }

        function getNumMetricPlots(uiModel) {
            const plots = (uiModel || {}).allPlots || [];
            return plots.filter(function (plot) {
                return (plot.type === 'plot' || plot.type === 'ratio') && !plot.transient;
            }).length;
        }

        function confirmUpdateTeamRecipients(toAdd, toRemove) {
            const title = 'Update Notification Recipients';
            const confirmQuestion =
                "Update detector's notification recipients to reflect the links you have specified?";
            const updateType = DETECTOR_TEAM_UPDATES.RECIPIENTS;
            return confirmUpdateForTeamChanges(toAdd, toRemove, confirmQuestion, title, updateType);
        }

        function confirmUpdateForTeamChanges(toAdd, toRemove, confirmQuestion, title, updateType) {
            const needsConfirm = (toAdd && toAdd.length) || (toRemove && toRemove.length);

            if (needsConfirm) {
                const text = getConfirmTextChangedTeams(toAdd, toRemove, updateType);
                text.unshift(confirmQuestion);

                return confirmService.confirm({
                    title,
                    text,
                    yesText: 'Yes',
                    noText: 'No',
                });
            } else {
                return $q.when(false);
            }
        }

        function showConfirmNoNotifications(uiModel) {
            // show confirmation when trying to save and there's a rule without notification policy.
            const noNotifications = uiModel.rules
                .filter(function (rule) {
                    return !rule.notifications || !rule.notifications.length;
                })
                .map(function (rule) {
                    return rule.name;
                });
            if (noNotifications.length) {
                return sfxModal
                    .open({
                        templateUrl: detectorNoNotificationConfirmTemplateUrl,
                        controller: [
                            '$scope',
                            'names',
                            function ($scope, names) {
                                $scope.names = names;
                            },
                        ],
                        resolve: {
                            names: function () {
                                return noNotifications;
                            },
                        },
                    })
                    .result.then(function (result) {
                        if (!result) {
                            return $q.reject('cancelling detector save');
                        }
                    });
            }
            return $q.when();
        }

        function maybeUpdateTeamNotifications(
            detector,
            { updatedTeams, addedTeams, removedTeams, hasWritePermissions }
        ) {
            if (!hasWritePermissions) {
                return $q.when();
            }
            const teamsProp = detector.teams ? 'teams' : 'sf_teams';
            detector[teamsProp] = updatedTeams;

            // check if newly linked or unlinked teams are in the rules
            const teamIdsToAdd = addedTeams.map((team) => team.teamId);
            const needsAdd = !notifiesAllTeams(teamIdsToAdd, detector);
            const teamIdsToRemove = removedTeams.map((team) => team.teamId);
            const needsSubtract = notifiesSomeTeams(teamIdsToRemove, detector);
            let confirmPromise;

            if (needsSubtract || needsAdd) {
                confirmPromise = confirmUpdateTeamRecipients(addedTeams, removedTeams).then(
                    (confirmed) => {
                        if (confirmed) {
                            setNotificationsForLinkedTeams(addedTeams, removedTeams, detector);
                        }
                    }
                );
            } else {
                confirmPromise = $q.when();
            }

            return confirmPromise.then(
                updateDetector(detector).catch((err) => {
                    const errorData =
                        err && err.data && err.data.message
                            ? err.data.message
                            : 'An unexpected error occurred.';
                    throw { errMessage: `Error occurred saving detector: ${errorData}` };
                })
            );
        }

        function updateDetector(detector) {
            // If this is a v2 detector using a v2 data model call update
            if (detectorVersionService.getInternalVersion(detector) === 2 && !detector.sf_uiModel) {
                return detectorv2Service.update(detector);
            } else {
                return saveDetector(detector);
            }
        }

        function getConfirmTextChangedTeams(addedTeams, removedTeams, updateType) {
            const text = [];

            const [addText, removeText] =
                updateType === DETECTOR_TEAM_UPDATES.LINKS
                    ? ['Link detector to ', 'Unlink detector to ']
                    : ['Add ', 'Remove '];

            if (addedTeams && addedTeams.length) {
                const addedTeamsStr = addedTeams.map((t) => t.teamName).join(', ');
                text.push(
                    `${addText} the following team${addedTeams.length > 1 ? 's' : ''}: ` +
                        addedTeamsStr
                );
            }

            if (removedTeams && removedTeams.length) {
                const removedTeamsStr = removedTeams.map((t) => t.teamName).join(', ');
                text.push(
                    `${removeText} the following team${removedTeams.length > 1 ? 's' : ''}: ` +
                        removedTeamsStr
                );
            }

            return text;
        }

        function notifiesSomeTeams(teams, detector) {
            if (!teams.length) {
                return false;
            }

            const isV2 = detectorVersionService.getInternalVersion(detector) === 2;
            const rules = detector.rules ? detector.rules : detector.sf_uiModel.rules;
            const teamNotificationType = isV2 ? 'Team' : 'team';

            return rules.some((rule) => {
                for (let i = 0; i < rule.notifications.length; i++) {
                    if (
                        rule.notifications[i].type === teamNotificationType &&
                        teams.includes(rule.notifications[i].team)
                    ) {
                        return true;
                    }
                }
            });
        }

        function notifiesAllTeams(teams, detector) {
            if (!teams.length) {
                return true;
            }

            const rules = detector.rules ? detector.rules : detector.sf_uiModel.rules;

            return rules.every((rule) => {
                for (let i = 0; i < teams.length; i++) {
                    if (rule.notifications.every((n) => n.team !== teams[i])) {
                        return false;
                    }
                }
                return true;
            });
        }

        function setNotificationsForLinkedTeams(teamsToAdd, teamsToRemove, detector) {
            const isV2 = detectorVersionService.getInternalVersion(detector) === 2;
            const rules = detector.rules ? detector.rules : detector.sf_uiModel.rules;
            const notificationType = isV2 ? 'Team' : 'team';

            rules.forEach((rule) => {
                const newNotifications = [];
                for (let i = 0; i < rule.notifications.length; i++) {
                    const notification = rule.notifications[i];
                    if (
                        !teamsToRemove.find((team) => team.teamId === notification.team) &&
                        !teamsToAdd.find((team) => team.teamId === notification.team)
                    ) {
                        newNotifications.push(notification);
                    }
                }

                teamsToAdd.forEach((team) =>
                    newNotifications.push({
                        team: team.teamId,
                        teamName: team.teamName,
                        type: notificationType,
                    })
                );

                rule.notifications = newNotifications;
            });
        }

        function hasThresholdMode(rule) {
            return hasCompoundConditions(rule) || rule.thresholdMode;
        }

        // categories related functions

        function getAllCategories() {
            return [LEGACY_METADATA.static, ...getFunctions(), LEGACY_METADATA.dynamic];
        }

        function getCategoriesByType() {
            const categories = getAllCategories();

            return categories.filter((category) => category.type === DEFAULT_DEFINITION_TYPE);
        }

        function getCategoryTypes() {
            return functionsMetadataService.getDefinitionTypes();
        }

        function getFunctions() {
            const categories = functionsMetadataService.getFunctionsList(PACKAGE_INFO);
            categories.forEach((category) => {
                category.functions.forEach((func) => {
                    if (func.sensitivity) {
                        func.sensitivity.options.push({
                            displayName: 'Custom',
                            name: 'custom',
                            inputs: {},
                        });
                    }
                });
            });

            return categories;
        }

        function getOptimizedCalendarWindow(cycleName, chartConfigTime) {
            const timerObject = chartUtils.getChartTimeConfig(chartConfigTime);
            const optimizedWindowObj = calendarWindowUtil.getOptimizedWindowObject(
                cycleName,
                timerObject
            );

            if (!optimizedWindowObj) {
                return;
            }

            return optimizedWindowObj;
        }

        function convertV1ToV2Time(v1TimeObject) {
            if (v1TimeObject.range) {
                return {
                    type: 'relative',
                    range: Math.abs(v1TimeObject.range),
                };
            } else if (v1TimeObject.absoluteStart && v1TimeObject.absoluteEnd) {
                return {
                    type: 'absolute',
                    start: v1TimeObject.absoluteStart,
                    end: v1TimeObject.absoluteEnd,
                };
            }
            $log.error('Could not find a time configuration to save.  Defaulting to 15m');
            return {
                type: 'relative',
                range: 900000,
            };
        }

        // APM related functions

        // check if there is any valid apm rule by checking the path metadata
        function hasValidApmRule(rules) {
            if (!rules || !angular.isArray(rules)) return false;

            return rules.some(
                (r) => !r.invalid && signalflowMetadataFinder.isModulePathApm(r.path)
            );
        }

        function hasValidApmV2Rule(rules) {
            if (!rules || !angular.isArray(rules)) return false;

            return rules.some(
                (r) => !r.invalid && signalflowMetadataFinder.isModulePathApmV2(r.path)
            );
        }

        // return default apm detector name based on apm metric type and selection
        // if there is NO specific endpoint (multiple or '*'), it'll be NOT included in the detector name
        // otherwise, the endpoint info will be included in the detector name
        // Params:
        // - apmMetricType: the apm metric type (errors/latency/workflow_errors/workflow_latency)
        // - selection: the service/endpoint or business/workflow selection
        function getDefaultApmDetectorName(apmMetricType, selection) {
            if (
                !selection ||
                typeof signalTypeService.APM_METRIC_TYPE_MAP[apmMetricType] === 'undefined'
            )
                return;

            const LABEL = signalTypeService.APM_METRIC_TYPE_MAP[apmMetricType].textLabel;

            if (signalTypeService.SERVICE_ENDPOINT['apmMetricGroup'].includes(apmMetricType)) {
                const { service, endpoints } = selection;
                const endpoint =
                    endpoints.length === 1 && endpoints[0] !== '*' ? `:${endpoints[0]}` : '';
                return `${LABEL} detector for ${service}${endpoint}`;
            } else if (
                featureEnabled('apm2Workflows') &&
                signalTypeService.WORKFLOW['apmMetricGroup'].includes(apmMetricType)
            ) {
                const { resource } = selection;
                return `${LABEL} detector for ${resource}`;
            }
        }

        // return apm metric type based on the path
        // - LATENCY if path starts with 'signalfx.detectors.apm.latency';
        // - ERRORS if path starts with 'signalfx.detectors.apm.errors';
        // - WORKFLOW_ERROR_RATE if path starts with 'signalfx.detectors.apm.workflow_errors';
        // - WORKFLOW_LATENCY if path starts with 'signalfx.detectors.apm.workflow_latency';
        // otherwise, return undefined
        function getApmMetricTypeFromPath(path) {
            if (!path) return;

            const isApm2WorkflowsEnabled = featureEnabled('apm2Workflows');

            if (path.startsWith(`${APM_PATH_PREFIX}${APM_METRIC_TYPE.SERVICE_LATENCY}`)) {
                return APM_METRIC_TYPE.SERVICE_LATENCY;
            } else if (path.startsWith(`${APM_PATH_PREFIX}${APM_METRIC_TYPE.SERVICE_ERRORS}`)) {
                return APM_METRIC_TYPE.SERVICE_ERRORS;
            } else if (
                isApm2WorkflowsEnabled &&
                path.startsWith(`${APM_PATH_PREFIX}${APM_METRIC_TYPE.WORKFLOW_LATENCY}`)
            ) {
                return APM_METRIC_TYPE.WORKFLOW_LATENCY;
            } else if (
                isApm2WorkflowsEnabled &&
                path.startsWith(`${APM_PATH_PREFIX}${APM_METRIC_TYPE.WORKFLOW_ERROR_RATE}`)
            ) {
                return APM_METRIC_TYPE.WORKFLOW_ERROR_RATE;
            }
        }

        // return apm metric type based on metrics
        // - LATENCY if all metrics are apm latency metrics
        // - ERRORS if all metrics are apm volume metrics
        // otherwise, return undefined
        // Jakub, 03/30/20: I've added heuristic to the name, because it relies on a hard-coded list built with APM 1.0 in mind
        // with addition of APM 2.0 the list is no longer complete, and also we're now providing a backend endpoint which
        // returns apm version and type based on a metric and its filters - and it looks for actual MTSes in the DB
        function getHeuristicApm1MetricTypeFromMetrics(metrics) {
            if (!metrics || metrics.length === 0) return;

            const latencyOnly = metrics.every((m) => APM_LATENCY_METRICS.has(m));
            if (latencyOnly) return APM_METRIC_TYPE.SERVICE_LATENCY;

            const volumeOnly = metrics.every((m) => APM_VOLUME_METRICS.has(m));
            if (volumeOnly) return APM_METRIC_TYPE.SERVICE_ERRORS;
        }

        const getSignalFlowModel = (programText) => {
            return $http({
                method: 'POST',
                url: API_URL + '/v2/signalflow/_/getSignalFlowModel?parseDetectors=true',
                data: programText,
                headers: {
                    'Content-Type': 'text/plain',
                },
            })
                .then((res) => res.data)
                .catch((res) => {
                    switch (res.status) {
                        case 406:
                            throw new DetectorModelConversionSignalBoostValidationError(
                                (res.data || {}).message
                            );
                        default:
                            throw new Error((res.data || {}).message);
                    }
                });
        };

        const getPlotsFromProgramText = (programText) => {
            return $http({
                method: 'POST',
                url: API_URL + '/v2/signalflow/_/getSignalFlowModel',
                data: programText,
                headers: {
                    'Content-Type': 'text/plain',
                },
            }).then(
                (resp) => resp.data,
                function (response) {
                    if (response && response.status === 406) {
                        $log.warn(
                            'Could not obtain plots from program text, falling back on using signalflow editor.'
                        );
                    } else {
                        $log.error('Could not obtain plots from program text.');
                    }
                    return [];
                }
            );
        };

        const getMaxUniqueKey = (plotsArr) => {
            let max = -1;
            plotsArr.forEach((plot) => {
                if (plot.uniqueKey > max) {
                    max = plot.uniqueKey;
                }
            });
            return max;
        };

        // Extend rule with v2 model detector data
        const extendRuleWithDetectorV2Model = (rule, allPlots, detectorV2Model) => {
            const v2RulesData = detectorV2Model.rules || [];

            // find v2Model matching rule object
            const v2ModelRule = v2RulesData.find((r) => r.detectLabel === rule.name);

            if (!v2ModelRule) {
                throw new DetectorModelConversionNoMatchingRuleError(
                    `No matching rule definition to detect label: ${rule.name}`
                );
            }

            rule.isCustomizedMessage = v2ModelRule.parameterizedBody !== null;

            if ('undefined' !== typeof v2ModelRule.disabled) {
                rule.disabled = v2ModelRule.disabled;
            } else {
                rule.disabled = false;
            }

            if (v2ModelRule.notifications) {
                rule.notifications = v2ModelRule.notifications;
            }

            if (v2ModelRule.parameterizedBody) {
                rule.parameterized = v2ModelRule.parameterizedBody;
            } else {
                rule.parameterized = getAutoDetectorRuleParameterizedString(rule, allPlots);
            }

            if (v2ModelRule.parameterizedSubject) {
                rule.parameterizedSubject = v2ModelRule.parameterizedSubject;
            }

            if (v2ModelRule.severity) {
                rule.severityLevel = v2ModelRule.severity;
            }

            if (v2ModelRule.tip) {
                rule.tip = v2ModelRule.tip;
            }

            if (v2ModelRule.runbookUrl) {
                rule.runbookUrl = v2ModelRule.runbookUrl;
            }

            if (!rule.readable) {
                rule.readable = getAutoDetectorRuleDescription(rule, allPlots);
            }

            if (rule.conditions) {
                rule.conditions.forEach((condition) => {
                    const description = getLegacyRuleDescription(condition, allPlots);
                    description.push('.');
                    condition.readableCondition = description.join('');
                });
            }

            return rule;
        };

        const getV2DetectorUIModel = (
            detectorV2Model,
            signalFlowModel,
            skipRulesNotFoundInDetectorModel
        ) => {
            let sf_uiModel;

            try {
                sf_uiModel = blockMapToUIModelConverter.convertDetectorBlockMap(
                    signalFlowModel,
                    DUMMY_CHART_OPTIONS,
                    detectorV2Model
                );
            } catch (e) {
                throw new DetectorModelConversionError(e.message);
            }

            sf_uiModel.rules = sf_uiModel.rules
                .map((rule) => {
                    if (rule.conditions) {
                        rule.targetPlot = rule.conditions[0].targetPlot;
                    } else if (rule.inputs && rule.inputs.stream) {
                        rule.targetPlot = rule.inputs.stream;
                    } else {
                        $log.warn('Unable to provide targetPlot.');
                    }

                    rule.invalid = false;
                    rule.uniqueKey = chartbuilderUtil.getNextUniqueKey(sf_uiModel, true);

                    try {
                        extendRuleWithDetectorV2Model(rule, sf_uiModel.allPlots, detectorV2Model);
                    } catch (e) {
                        if (
                            skipRulesNotFoundInDetectorModel &&
                            e instanceof DetectorModelConversionNoMatchingRuleError
                        ) {
                            // null will be filtered out in next pipe operation
                            return null;
                        }

                        throw e;
                    }

                    return rule;
                })
                // filter out skipped rules (skipRulesNotFoundInDetectorModel = true)
                .filter((rule) => rule !== null);

            return sf_uiModel;
        };

        const getV2DetectorPlotModel = (detectorName, p, pLabels, visualizationOptions) => {
            const plots = p || [];
            const plotLabels = pLabels || {
                dataLabels: [],
                detectLabels: [],
                eventLabels: [],
                packageVersions: [],
                streamPublishInfo: [],
            };

            const newPlotModel = {
                sf_uiModel: {
                    allPlots: [],
                },
            };

            // Attempt to convert to UI model
            try {
                newPlotModel.sf_uiModel = blockMapToUIModelConverter.convertDetectorBlockMap(
                    plots,
                    DUMMY_CHART_OPTIONS
                );
            } catch (e) {
                // Here, we failed to convert to plot model. Do nothing because
                // we will return a default plot.
            }

            const uiModelExtension = visualizationOptionsToUIModel(
                angular.extend(
                    {
                        type: 'TimeSeriesChart',
                    },
                    visualizationOptions
                )
            );
            angular.extend(newPlotModel.sf_uiModel, uiModelExtension);

            // Annotate UI model with plot publish labels from program text
            // chartDisplayUtils.processKnownLabels(plotLabels, newPlotModel, [], publishLabelOptions);
            chartDisplayUtils.processKnownLabels(plotLabels, newPlotModel, []);

            // The uniqueKey for the defaultPlot cannot be in range of the other
            // plot labels. So, choose a prime # above 1000 because if you have a
            // 1000 plots, good luck to you.
            newPlotModel.sf_uiModel.allPlots.push({
                invisible: false,
                queryItems: [],
                transient: false,
                type: 'event',
                uniqueKey:
                    getMaxUniqueKey(newPlotModel.sf_uiModel.allPlots) + FIRST_PRIME_ABOVE_1000,
                seriesData: {
                    detectorQuery: detectorName,
                },
            });

            return {
                allPlots: newPlotModel.sf_uiModel.allPlots,
                // Return the current unique key which will be used in case of
                // converted plots. Otherwise, it may not be used.
                currentUniqueKey: newPlotModel.sf_uiModel.currentUniqueKey,
            };
        };

        const showFailedDetectorLinkingWarning = function () {
            return confirmService.confirm({
                title: 'Detector Linking',
                text: [
                    'This detector will not be linked to the chart it was created from, as you do not have the correct permissions.',
                ],
                yesText: 'Ok',
                noText: '',
            });
        };

        const showDetectorWarningForDashboardTimeWindow = function () {
            return confirmService.confirm({
                title: `Detector's transformation window`,
                text: [
                    `This chart uses a dashboard-specific transformation window. Because dashboard window can't be applied to detectors,
                     the transformation window will be updated to the closest match to the current time window of the dashboard.`,
                ],
                template:
                    '<a href="https://docs.splunk.com/Observability/en/analytics/signalflow.html#dashboard-window-transformations" target="_blank" >Learn more <i class="new-tab-icon"></i></a>',
                yesText: 'Ok',
                noText: '',
            });
        };

        function linkDetectorToUnconvertableChart(detectorId, detectorName, chartId) {
            const CHART_URL = API_URL + `/v2/chart/${chartId}`;
            return $http
                .get(CHART_URL)
                .then(function (chartResponse) {
                    const chart = chartResponse.data;
                    chart.programText = chartUtils.getLinkDetectorSignalFlow(
                        chart.programText,
                        detectorId,
                        detectorName
                    );
                    return $http.put(CHART_URL, chart);
                })
                .then(
                    function () {
                        return true;
                    },
                    function () {
                        return false;
                    }
                );
        }

        return {
            maybeUpdateTeamNotifications,
            hasAboveThreshold,
            hasBelowThreshold,
            updateDetectorValidityState,
            updateDetectorRuleValidityState,
            validateDetectorRule,
            validateRangeThreshold,
            getAutoDetectorRuleDescription,
            getAutoDetectorRuleSplitDescription,
            getAutoDetectorRuleParameterizedString,
            getEventAnnotations,
            saveDetector,
            generateDetectorModel,
            generateDetectorV1Model,
            isFlow2,
            hasDynamicThreshold,
            hasThresholdOrCompoundConditions,
            validateCompoundConditions,
            validateLegacyCondition,
            isStaticThreshold,
            addEmptyDetectorRule,
            getMetricPlotsForFunctionRule,
            getFunctionRuleSummary,
            getFunctionStreamName,
            getFunctionStream,
            setRuleShowThreshold,
            validateFunctionInput,
            detectorNameModal,
            signalResolution,
            getNumMetricPlots,
            getPlotsForFunctionRule,
            compileParameterizedField,
            showConfirmNoNotifications,
            hasThresholdMode,
            hasCompoundConditions,
            getAllCategories,
            getCategoriesByType,
            getCategoryTypes,
            getOptimizedCalendarWindow,
            convertV1ToV2Time,
            hasValidApmRule,
            hasValidApmV2Rule,
            getDefaultApmDetectorName,
            getApmMetricTypeFromPath,
            getHeuristicApm1MetricTypeFromMetrics,
            getPlotsFromProgramText,
            getSignalFlowModel,
            getV2DetectorPlotModel,
            getV2DetectorUIModel,
            showFailedDetectorLinkingWarning,
            showDetectorWarningForDashboardTimeWindow,
            linkDetectorToUnconvertableChart,
        };
    },
]);
