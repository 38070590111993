angular
    .module('signalboost')

    .service('signalboostUtil', [
        '$q',
        'currentUser',
        'INACTIVE_TAG',
        'DOMAIN_ORGANIZATION_ID',
        '$location',
        function ($q, currentUser, INACTIVE_TAG, DOMAIN_ORGANIZATION_ID, $location) {
            return {
                inactiveEmitterFilter,
                inactiveEmittersSanitizer,
                initializeOrgForObj,
                isMemberOf,
                memberOfSearchTerm,
                metricQuerySanitizer,
                metricTSQuerySanitizer,
                setUpdated,
                sfUserComparator,
                sourceQuerySanitizer,
            };

            function metricQuerySanitizer(querystr, querytoken) {
                querystr =
                    (querystr ? '((' + querystr + ') AND ' : '(') + 'NOT _exists_:sf_programId)';
                return inactiveEmittersSanitizer(querystr, querytoken);
            }

            function metricTSQuerySanitizer(querystr, querytoken) {
                querystr = metricQuerySanitizer(querystr, querytoken);
                return sourceQuerySanitizer(querystr, querytoken);
            }

            function inactiveEmittersSanitizer(querystr, querytoken) {
                // ignore inactive emitters unless explicitly requested
                if (querytoken !== 'inactive') {
                    return inactiveEmitterFilter(querystr);
                } else {
                    return querystr;
                }
            }

            function inactiveEmitterFilter(querystr) {
                return '(' + querystr + ' AND (NOT ' + INACTIVE_TAG + '))';
            }

            function sourceQuerySanitizer(querystr, querytoken) {
                querystr =
                    (querystr ? '((' + querystr + ') AND ' : '(') + 'NOT _exists_:sf_programId)';
                return inactiveEmittersSanitizer(querystr, querytoken);
            }

            function isMemberOf(value, parentId) {
                return value['sf_memberOf'] && value['sf_memberOf'].indexOf(parentId) !== -1;
            }

            function memberOfSearchTerm(parentId) {
                return `sf_memberOf:( ${parentId} )`;
            }

            function setUpdated(obj) {
                return obj.patch({
                    sf_updatedOnMs: new Date().getTime(),
                });
            }

            function sfUserComparator() {
                return function (a, b) {
                    const noa = !angular.isDefined(a.sf_user);
                    const nob = !angular.isDefined(b.sf_user);
                    if (noa && nob) {
                        return a.sf_email === b.sf_email ? 0 : a.sf_email < b.sf_email ? -1 : 1;
                    }
                    if (noa) {
                        return 1;
                    }
                    if (nob) {
                        return -1;
                    }
                    return a.sf_user === b.sf_user ? 0 : a.sf_user < b.sf_user ? -1 : 1;
                };
            }

            /**
             * Sets the current organization to the 'sf_organizationID' of *obj* if it
             * isn't already so.
             *
             * @param {Object} obj
             * @returns The object received.
             */
            function initializeOrgForObj(obj) {
                // support v1 and v2
                const requestedOrgId = obj?.sf_organizationID || obj?.organizationId;

                if (!requestedOrgId) {
                    return $q.when(obj);
                }

                if (DOMAIN_ORGANIZATION_ID && DOMAIN_ORGANIZATION_ID !== requestedOrgId) {
                    // if it's incorrect
                    $location.path('/404').replace();
                    return $q.reject('Wrong organization');
                }

                return currentUser.orgId().then(function (orgId) {
                    if (orgId !== requestedOrgId) {
                        return currentUser.setPreferredOrg(requestedOrgId).then(() => obj);
                    } else {
                        return $q.when(obj);
                    }
                });
            }
        },
    ]);
