import templateUrl from './eventOverlayRow.tpl.html';
import metricFilterItemTemplateUrl from '../../charting/chartbuilder/metricFilterItemTemplate.tpl.html';
import { colorAccessibilityOptions } from '@splunk/olly-services/lib/services/ColorAccessibility/colorAccessibilityOptions';

export default {
    templateUrl,
    bindings: {
        currentEvent: '=',
        getEventColor: '=',
        getEventLine: '=',
        dashboardEditable: '=',
    },
    controller: [
        '$scope',
        '$timeout',
        'analyticsService',
        'plotSignalSuggestionService',
        'colorAccessibilityService',
        'murmurHash',
        'userAnalytics',
        function (
            $scope,
            $timeout,
            analyticsService,
            plotSignalSuggestionService,
            colorAccessibilityService,
            murmurHash,
            userAnalytics
        ) {
            $scope.metricFilterItemTemplateUrl = metricFilterItemTemplateUrl;
            $scope.objectTypes = ['EventTimeSeries'];
            const ctrl = this;
            const EVENT_COLORS = colorAccessibilityOptions.default.plotColors;

            ctrl.getSignalSuggestionsDebounced = getSignalSuggestionsDebounced;
            ctrl.onEventSelected = onEventSelected;
            ctrl.getCurrentQuery = getCurrentQuery;
            ctrl.onEdit = onEdit;
            ctrl.deleteRow = deleteRow;
            ctrl.$onInit = $onInit;
            ctrl.onEventLineChange = onEventLineChange;

            function $onInit() {
                $scope.tempEventSuggest =
                    ctrl.currentEvent.eventSignal && ctrl.currentEvent.eventSignal.eventSearchText;
                if (ctrl.currentEvent.sources && ctrl.currentEvent.sources.length) {
                    ctrl.currentEvent.sources.forEach((source) => {
                        source.propertyValue = source.value;
                    });
                }
            }

            let currentSignalSuggestPromise;
            const eventPlot = {
                type: 'event',
                seriesData: {},
            };

            function getSignalSuggestionsDebounced(q) {
                // we need to control debouncing outside of ng-model and typeahead directives
                // in order to accurately populate the "is fetching" behavior
                $timeout.cancel(currentSignalSuggestPromise);
                currentSignalSuggestPromise = $timeout(function () {
                    const resultPromise = plotSignalSuggestionService.getSignalSuggestions(
                        q,
                        false,
                        [],
                        eventPlot,
                        'eventOverlay'
                    );
                    return resultPromise.then(function (rs) {
                        const noExactQueryMatch = !rs.some(function (resp) {
                            return resp.value === q;
                        });

                        if (noExactQueryMatch && q) {
                            rs.push({
                                type: 'eventTimeSeries',
                                value: $scope.tempEventSuggest,
                                isSynthetic: true,
                            });
                        }
                        return rs;
                    });
                }, 200);
                return currentSignalSuggestPromise;
            }

            let labelEdited;
            function onEventSelected(item) {
                const eventObj = getEventSignal(item);
                const currentEventColor = ctrl.getEventColor(eventObj);
                ctrl.currentEvent.eventSignal = eventObj;

                if (!labelEdited) {
                    ctrl.currentEvent.label = item.value.sf_detector || item.value;
                }

                if (currentEventColor) {
                    ctrl.currentEvent.eventColor = currentEventColor;
                } else {
                    const colorIndex = Math.abs(murmurHash(item.value)) % EVENT_COLORS.length;
                    ctrl.currentEvent.eventColor = EVENT_COLORS[colorIndex];
                }

                ctrl.currentEvent.eventLine = ctrl.getEventLine(eventObj) || false;
            }

            function getEventSignal(item) {
                const eventObj = {
                    eventType: item.type,
                };

                if (angular.isObject(item.value)) {
                    eventObj.eventSearchText = item.value['sf_detector'];
                    eventObj.detectorId = item.value['sf_id'];
                } else {
                    eventObj.eventSearchText = item.value;
                }
                return eventObj;
            }

            function getCurrentQuery() {
                if (
                    ctrl.currentEvent.eventSignal &&
                    ctrl.currentEvent.eventSignal.eventType === 'eventTimeSeries'
                ) {
                    return analyticsService.getEventQuery([], {
                        eventQuery: $scope.tempEventSuggest,
                    });
                } else {
                    return analyticsService.getEventQuery([], {
                        detectorQuery: $scope.tempEventSuggest,
                    });
                }
            }

            function onEdit() {
                labelEdited = true;
            }

            function onEventLineChange() {
                if (ctrl.currentEvent.eventLine) {
                    userAnalytics.event('dashboard', 'event-overlay-row-drawline-on');
                } else {
                    userAnalytics.event('dashboard', 'event-overlay-row-drawline-off');
                }
            }

            function deleteRow() {
                $scope.$emit('delete event overlay row', ctrl.currentEvent);
            }

            const getCurrentEventObj = () => {
                return {
                    eventSearchText: ctrl.currentEvent.eventSignal.eventSearchText,
                    eventType: ctrl.currentEvent.eventSignal.eventType,
                    eventLine: ctrl.currentEvent.eventLine,
                    eventSources: ctrl.currentEvent.sources,
                    eventColor: ctrl.currentEvent.eventColor,
                };
            };

            $scope.$watch('$ctrl.currentEvent.eventLine', () => {
                if (!ctrl.currentEvent.eventSignal) {
                    return;
                }
                $scope.$emit('change all events line', getCurrentEventObj());
            });

            $scope.$watch('$ctrl.currentEvent.eventColor', function () {
                $scope.currentColor = colorAccessibilityService
                    .get()
                    .convertPlotColorToAccessible(ctrl.currentEvent.eventColor);

                if (ctrl.currentEvent.eventSignal) {
                    $scope.$emit('change all events color', getCurrentEventObj());
                }
            });
        },
    ],
};
