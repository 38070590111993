export const sortOptionService = function () {
    const sortOptions = {
        source: {
            primary: {
                field: 'sf_source',
                type: 'string',
            },
            secondary: {
                field: 'metric',
                type: 'string',
            },
            ascending: true,
        },
        sf_metric: {
            primary: {
                field: 'metric',
                type: 'string',
            },
            secondary: {
                field: 'sf_source',
                type: 'string',
            },
            ascending: true,
        },
        value: {
            primary: {
                field: 'raw',
                type: 'number',
            },
            secondary: {
                field: 'sf_source',
                type: 'string',
            },
            ascending: true,
        },
        pinnedValue: {
            primary: {
                field: 'pinnedRaw',
                type: 'number',
            },
            secondary: {
                field: 'sf_source',
                type: 'string',
            },
            ascending: true,
        },
    };

    this.getSortPreferences = function (model) {
        let sortOptions = this.getSortOption('source');

        let containsDirectionalAnalytics = null;

        model.sf_uiModel.allPlots.forEach(function (plot) {
            if (!plot.invisible && (plot.type === 'plot' || plot.type === 'ratio')) {
                plot.dataManipulations.forEach(function (manip) {
                    if (manip.fn.type === 'TOPN') {
                        containsDirectionalAnalytics = 1;
                    } else if (manip.fn.type === 'BOTTOMN') {
                        containsDirectionalAnalytics = -1;
                    }
                });
            }
        });

        if (containsDirectionalAnalytics > 0) {
            sortOptions = this.getSortOption('value');
            sortOptions.ascending = false;
        }

        if (containsDirectionalAnalytics < 0) {
            sortOptions = this.getSortOption('value');
            sortOptions.ascending = true;
        }

        return sortOptions;
    };

    this.sortTimesliceValues = function (datapoints, sortOptions, secondaryAsc) {
        const s1 = sortOptions.primary.field;
        const s2 = sortOptions.secondary.field;
        const s1LowerCase = sortOptions.primary.type === 'string';
        const s2LowerCase = sortOptions.secondary.type === 'string';
        const isPrimaryNumberType = sortOptions.primary.type === 'number';
        const asc = sortOptions.ascending;
        const secAsc = !!secondaryAsc;

        datapoints.sort(function (a, b) {
            if (sortOptions.metaData) {
                a = a.pointMetaData;
                b = b.pointMetaData;
            }
            // try to handle nonstring type nulls as nulls(lowest prio in sort)
            let aprimary = a[s1];
            let bprimary = b[s1];
            let asecondary = a[s2];
            let bsecondary = b[s2];
            if (s1LowerCase) {
                aprimary = (aprimary || '').toLowerCase();
                bprimary = (bprimary || '').toLowerCase();
            }
            if (s2LowerCase) {
                asecondary = (asecondary || '').toLowerCase();
                bsecondary = (bsecondary || '').toLowerCase();
            }
            if (isPrimaryNumberType) {
                aprimary = aprimary || 0;
                bprimary = bprimary || 0;
            }

            if (aprimary === bprimary) {
                if (aprimary === null && bprimary === null) {
                    return 0;
                } else if (aprimary === null) {
                    return -1;
                } else if (bprimary === null) {
                    return 1;
                }
                if (asecondary > bsecondary) {
                    return 1 * (secAsc ? 1 : -1);
                } else if (asecondary < bsecondary) {
                    return -1 * (secAsc ? 1 : -1);
                } else {
                    return 0;
                }
            } else if (aprimary > bprimary || bprimary === null) {
                return 1 * (asc ? 1 : -1);
            } else if (aprimary < bprimary || aprimary === null) {
                return -1 * (asc ? 1 : -1);
            }
        });
        return datapoints;
    };

    // force a getter so values cannot be overriden as we pass a copy.
    this.getSortOption = function (option, direction, disableMetadata) {
        const sortOpt = angular.copy(
            sortOptions[option] || {
                primary: {
                    field: option,
                    type: 'string',
                },
                secondary: {
                    field: 'metric',
                    type: 'string',
                },
                metaData: !disableMetadata,
                ascending: true,
            }
        );
        if (angular.isDefined(direction)) {
            sortOpt.ascending = direction;
        }
        return sortOpt;
    };
};
