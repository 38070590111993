import { convertMSToString } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { DEFAULT_PERCENTILE_VALUE } from './PERCENTILE/PercentileController';

angular
    .module('signalview.analytics')
    .service('blockService', [
        function () {
            const functions = [];

            function createAnalyticsFunction(config) {
                if (!config.type || !config.convert || !config.displayName) {
                    throw new Error(
                        'Analytics function definition must have a type, displayName, and convert function.'
                    );
                }

                const api = config.convert;

                function defaultToReadableString() {
                    return config.displayName;
                }

                function defaultGetTooltip() {
                    return null;
                }

                api.toReadableString = config.toReadableString || defaultToReadableString;

                api.getToolTip = config.getToolTip || defaultGetTooltip;

                api.toString = function () {
                    return config.displayName || config.type;
                };

                api.isStandalone = function () {
                    return config.isStandalone === true || config.isFakeAggregation;
                };

                api.getType = function () {
                    return config.type;
                };

                api.getDisplayName = function () {
                    return config.displayName;
                };

                api.getDefaultOptions = function () {
                    return config.defaultOptions || {};
                };

                // Copied comment from previous implementation:
                // functions internally referenced as aggregations but arent.
                // TODO : make a third type perhaps?  aggregation, transformation, thing?
                api.isFakeAggregation = function () {
                    return config.isFakeAggregation === true;
                };

                api.isForcedGroupCollapse = function () {
                    return config.isForcedGroupCollapse === true;
                };

                api.asMacro = function (options) {
                    return {
                        fn: {
                            type: config.type,
                            options: angular.extend({}, api.getDefaultOptions(), options),
                        },
                        direction: {
                            type: 'aggregation',
                            options: {
                                aggregateGroupBy: [],
                                collapseGroups: false,
                            },
                        },
                    };
                };

                api.asAggregation = function (groups, options) {
                    return {
                        fn: {
                            type: config.type,
                            options: angular.extend({}, api.getDefaultOptions(), options),
                        },
                        direction: {
                            type: 'aggregation',
                            options: {
                                aggregateGroupBy: groups || [],
                                collapseGroups: false,
                            },
                        },
                    };
                };

                if (!config.isStandalone) {
                    api.asTransformation = function (amount, unit, options) {
                        return {
                            fn: {
                                type: config.type,
                                options: angular.extend({}, api.getDefaultOptions(), options),
                            },
                            direction: {
                                type: 'transformation',
                                options: {
                                    unit: unit,
                                    amount: amount,
                                    transformTimeRange: '' + amount + unit,
                                },
                            },
                        };
                    };
                }

                return api;
            }

            const _this = this;
            // Adds an analytics function using it's definition to the provider
            this.add = function (definition) {
                const type = definition.type.toLowerCase();
                if (type in _this) {
                    throw new Error(
                        'A definition for block ' + definition.type + ' already exists'
                    );
                }

                const func = createAnalyticsFunction(definition);
                functions.push(func);
                _this[type] = func;
            };

            this.get = function (type) {
                if (!type) return null;
                return _this[type.toLowerCase()];
            };

            this.getByDisplayName = function (displayName) {
                let targetFn = null;
                functions.some(function (fn) {
                    if (displayName === fn.getDisplayName()) {
                        targetFn = fn;
                        return true;
                    }
                });
                return targetFn;
            };

            this.all = function () {
                return functions.slice();
            };

            this.getFakeAggregations = function () {
                return this.all()
                    .filter((func) => func.isFakeAggregation())
                    .map((func) => func.getType());
            };
        },
    ])

    .run([
        'blockService',
        function (blockService) {
            function isNullOrUndefined(_) {
                return _ === undefined || _ === null;
            }

            blockService.add({
                type: 'SUM',
                displayName: 'Sum',
                convert: function () {
                    return ['stats:!sum'];
                },
            });

            blockService.add({
                type: 'COUNT',
                displayName: 'Count',
                isStandalone: true,
                convert: function () {
                    return ['stats:!count'];
                },
            });

            blockService.add({
                type: 'MEAN',
                displayName: 'Mean',
                convert: function () {
                    return ['stats:!mean'];
                },
            });

            blockService.add({
                type: 'PERCENTILE',
                defaultOptions: {
                    percentile: DEFAULT_PERCENTILE_VALUE,
                },
                displayName: 'Percentile',
                convert: function (model) {
                    return ['stats:!p' + ((model && model.percentile) || DEFAULT_PERCENTILE_VALUE)];
                },
                toReadableString: function (model) {
                    let pctl = DEFAULT_PERCENTILE_VALUE;
                    if (model.fn.options && model.fn.options.percentile) {
                        pctl = model.fn.options.percentile;
                    }
                    return 'P' + pctl;
                },
            });

            blockService.add({
                type: 'EWMA',
                displayName: 'EWMA',
                isFakeAggregation: true,
                isStandalone: true,
                convert: function (model) {
                    //this generates a hash to determine when to rerun the job.  nothing more
                    return (
                        'EWMA' +
                        model.smoothingLevel +
                        model.alpha +
                        model.beta +
                        model.forecast +
                        model.damping
                    );
                },
                toReadableString: function (opt) {
                    const model = opt.fn.options;
                    switch (model.smoothingLevel) {
                        case undefined:
                        case 1:
                            return 'EWMA';
                        case 2:
                            return 'Double EWMA';
                        default:
                            return 'EWMA(Error)';
                    }
                },
                getToolTip: function (opt) {
                    const model = opt.fn.options;
                    const alpha = isNullOrUndefined(model.alpha) ? 0.1 : model.alpha;

                    switch (model.smoothingLevel) {
                        case undefined:
                        case 1:
                            return 'alpha=' + alpha;
                        case 2: {
                            const displayableParams = [];
                            displayableParams.push('alpha=' + alpha);
                            const beta = isNullOrUndefined(model.beta) ? 0.1 : model.beta;
                            displayableParams.push('beta=' + beta);

                            if (model.forecast) {
                                displayableParams.push('forecast=' + model.forecast);
                            }

                            if (!isNullOrUndefined(model.damping)) {
                                displayableParams.push('damping=' + model.damping);
                            }

                            return displayableParams.join(' ');
                        }
                        default:
                            return null;
                    }
                },
            });

            blockService.add({
                type: 'TIMESHIFT',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Timeshift',
                convert: function () {
                    return [];
                },
                toReadableString: function (model) {
                    let timeMs = 0;
                    if (model.fn.options) {
                        if (angular.isDefined(model.fn.options.milliseconds)) {
                            timeMs = model.fn.options.milliseconds;
                        }
                    }
                    const dn = convertMSToString(timeMs || '');
                    const summaryStr = 'Timeshift ' + dn;

                    return summaryStr;
                },
            });

            blockService.add({
                type: 'TOPN',
                isForcedGroupCollapse: true,
                isStandalone: true,
                displayName: 'Top',
                convert: function (model, key, groups) {
                    return selectByGroup(model, groups, 'top');
                },
                toReadableString: function (model) {
                    return 'Top ' + readableNumber(model);
                },
            });

            blockService.add({
                type: 'BOTTOMN',
                isForcedGroupCollapse: true,
                isStandalone: true,
                displayName: 'Bottom',
                convert: function (model, key, groups) {
                    return selectByGroup(model, groups, 'bottom');
                },
                toReadableString: function (model) {
                    return 'Bottom ' + readableNumber(model);
                },
            });

            blockService.add({
                type: 'MAX',
                displayName: 'Maximum',
                convert: function () {
                    return ['stats:!max'];
                },
            });

            blockService.add({
                type: 'MIN',
                displayName: 'Minimum',
                convert: function () {
                    return ['stats:!min'];
                },
            });

            blockService.add({
                type: 'STDDEV',
                displayName: 'Standard deviation',
                convert: function () {
                    return ['stats:!stddev'];
                },
            });

            blockService.add({
                type: 'MEAN_STDDEV',
                displayName: 'Mean + Standard deviation',
                convert: function (model, tempkey) {
                    function getTempKey(key) {
                        return tempkey + '_' + key;
                    }
                    const stddevcount = model && model.stddevcount ? model.stddevcount : 1;
                    const rawfetch = getTempKey('INPUT');
                    const stdevout = getTempKey('STDDEV');
                    const meanout = getTempKey('MEAN');
                    const summacro = getTempKey('SUM');
                    let txt =
                        rawfetch +
                        '=id;' +
                        rawfetch +
                        '->' +
                        'stats:!stddev->' +
                        stdevout +
                        '=id;' +
                        rawfetch +
                        '->' +
                        'stats:!mean->' +
                        meanout +
                        '=id;' +
                        summacro +
                        '= [?a,?b,!out]{ ?a + ?b -> !out } ;';
                    if (stddevcount !== 1) {
                        txt += stdevout + '-> { ?in * ' + stddevcount + ' -> !out } ->';
                    } else {
                        txt += stdevout + '->';
                    }

                    txt += '?a:' + summacro + ';' + meanout + '->?b:' + summacro + ';' + summacro;
                    return [txt];
                },
                toReadableString: function (model) {
                    let stddevcount = 1;
                    if (model.fn.options && model.fn.options.stddevcount) {
                        stddevcount = model.fn.options.stddevcount;
                    }
                    return (
                        'Mean ' +
                        (stddevcount > 0 ? '+ ' : '') +
                        (stddevcount !== 1 ? stddevcount + ' ' : '') +
                        'STDDEV'
                    );
                },
            });

            blockService.add({
                type: 'VARIANCE',
                displayName: 'Variance',
                convert: function () {
                    return ['stats:!variance'];
                },
            });

            blockService.add({
                type: 'DELTA',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Delta',
                convert: function () {
                    return ['delta()'];
                },
            });

            blockService.add({
                type: 'RATE',
                displayName: 'Rate',
                isStandalone: true,
                convert: function () {
                    return ['rate()'];
                },
            });

            blockService.add({
                type: 'RATEOFCHANGE',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Rate of change',
                convert: function () {
                    return ['rateofchange()'];
                },
            });

            blockService.add({
                type: 'INTEGRATE',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Integrate',
                convert: function () {
                    return 'integrate()';
                },
            });

            blockService.add({
                type: 'SCALE',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Scale',
                convert: function (model) {
                    if (model && model.scaleAmount) {
                        return ['{ ?in * ' + model.scaleAmount + ' -> !out }'];
                    } else {
                        return [];
                    }
                },
                toReadableString: function (model) {
                    let scaleAmount = 1;
                    if (model.fn.options && model.fn.options.scaleAmount) {
                        scaleAmount = model.fn.options.scaleAmount;
                    }
                    return 'Scale:' + scaleAmount;
                },
            });

            blockService.add({
                type: 'EXCLUDE',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Exclude',
                convert: function (model) {
                    if (!model) return [];

                    const high = parseFloat(model.high, 10);
                    const low = parseFloat(model.low, 10);

                    const hasHighFilter = angular.isNumber(high) && !isNaN(high);
                    const hasLowFilter = angular.isNumber(low) && !isNaN(low);
                    const hasBothFilters = hasHighFilter && hasLowFilter;

                    const inclusive = !!model.inclusive;

                    if (!hasHighFilter && !hasLowFilter) return [];

                    const setToLimit = model.action === 'set to limit';

                    let macro;

                    if (
                        hasBothFilters &&
                        (model.mode === 'out of range' || model.mode === 'within range')
                    ) {
                        if (model.mode === 'out of range') {
                            if (inclusive) {
                                macro =
                                    'if ( ?in > ' +
                                    low +
                                    ' && ?in < ' +
                                    high +
                                    ' ) { ?in -> !out }';
                            } else {
                                macro =
                                    'if ( ?in >= ' +
                                    low +
                                    ' && ?in <= ' +
                                    high +
                                    ' ) { ?in -> !out }';
                            }

                            if (setToLimit) {
                                macro += ' else if ( ?in <= ' + low + ') { ' + low + ' -> !out }';
                                macro += ' else if ( ?in >= ' + high + ') { ' + high + ' -> !out }';
                            }
                            return ['{' + macro + '}'];
                        } else if (model.mode === 'within range') {
                            if (inclusive) {
                                macro =
                                    'if ( ?in < ' +
                                    low +
                                    ' || ?in > ' +
                                    high +
                                    ' ) { ?in -> !out }';
                            } else {
                                macro =
                                    'if ( ?in <= ' +
                                    low +
                                    ' || ?in >= ' +
                                    high +
                                    ' ) { ?in -> !out }';
                            }
                            return ['{' + macro + '}'];
                        }
                    } else if (hasHighFilter && model.mode === 'above') {
                        if (inclusive) {
                            macro = 'if ( ?in < ' + high + ' ) { ?in -> !out }';
                        } else {
                            macro = 'if ( ?in <= ' + high + ' ) { ?in -> !out }';
                        }

                        if (setToLimit) {
                            macro += ' else { ' + high + ' -> !out }';
                        }

                        return ['{' + macro + '}'];
                    } else if (hasLowFilter && model.mode === 'below') {
                        if (inclusive) {
                            macro = 'if ( ?in > ' + low + ' ) { ?in -> !out }';
                        } else {
                            macro = 'if ( ?in >= ' + low + ' ) { ?in -> !out }';
                        }

                        if (setToLimit) {
                            macro += ' else { ' + low + ' -> !out }';
                        }

                        return ['{' + macro + '}'];
                    }

                    return [];
                },
                toReadableString: function (model) {
                    const options = model.fn.options;

                    const high = parseFloat(options.high, 10);
                    const low = parseFloat(options.low, 10);

                    const hasHighFilter = angular.isNumber(high) && !isNaN(high);
                    const hasLowFilter = angular.isNumber(low) && !isNaN(low);
                    const hasBothFilters = hasHighFilter && hasLowFilter;

                    const inclusive = !!options.inclusive;

                    const inclusiveChar = inclusive ? '=' : '';

                    if (hasHighFilter && options.mode === 'above') {
                        return 'Exclude x >' + inclusiveChar + ' ' + options.high;
                    } else if (hasLowFilter && options.mode === 'below') {
                        return 'Exclude x <' + inclusiveChar + ' ' + options.low;
                    } else if (hasBothFilters) {
                        if (options.mode === 'out of range') {
                            return (
                                'Exclude x <' +
                                inclusiveChar +
                                ' ' +
                                options.low +
                                ' or x >' +
                                inclusiveChar +
                                ' ' +
                                options.high
                            );
                        } else if (options.mode === 'within range') {
                            return (
                                'Exclude ' +
                                options.low +
                                ' <' +
                                inclusiveChar +
                                ' x <' +
                                inclusiveChar +
                                ' ' +
                                options.high
                            );
                        }
                    } else {
                        return 'Exclude';
                    }
                },
            });

            blockService.add({
                type: 'CEIL',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Ceiling',
                convert: function () {
                    return ['{ ceil(?in) -> !out }'];
                },
            });

            blockService.add({
                type: 'FLOOR',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Floor',
                convert: function () {
                    return ['{ floor(?in) -> !out }'];
                },
            });

            blockService.add({
                type: 'ABS',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Absolute value',
                convert: function () {
                    return ['{ abs(?in) -> !out }'];
                },
            });

            blockService.add({
                type: 'POW',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Power',
                defaultOptions: {
                    powerType: 'exponent',
                    powerAmount: 2,
                },
                convert: function (model) {
                    if (!model) return [];

                    model.powerType = model.powerType || 'exponent';
                    model.powerAmount = model.powerAmount || 2;

                    if (model.powerType === 'exponent') {
                        return ['{ pow(?in, ' + model.powerAmount + ') -> !out }'];
                    } else if (model.powerType === 'base') {
                        return ['{ pow(' + model.powerAmount + ', ?in) -> !out }'];
                    } else {
                        return [];
                    }
                },
                toReadableString: function (model) {
                    const options = model.fn.options;
                    if (!options) return 'Power';

                    if (options.powerType === 'exponent') {
                        return 'Pow(x, ' + options.powerAmount + ')';
                    } else if (options.powerType === 'base') {
                        return 'Pow(' + options.powerAmount + ', x)';
                    } else {
                        return 'Power';
                    }
                },
            });

            blockService.add({
                type: 'LOG',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'LN',
                convert: function () {
                    return ['{ log(?in) -> !out }'];
                },
            });

            blockService.add({
                type: 'LOG10',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Log10',
                convert: function () {
                    return ['{ log10(?in) -> !out }'];
                },
            });

            blockService.add({
                type: 'SQRT',
                isFakeAggregation: true,
                isStandalone: true,
                displayName: 'Square root',
                convert: function () {
                    return ['{ sqrt(?in) -> !out }'];
                },
            });

            // Used in TOPN and BOTTOMN
            function selectByGroup(model, groupby, outputport) {
                let parms = '';
                if (!model) {
                    parms = 'count=5';
                } else if (!model.mode || model.mode === 'count') {
                    parms = 'count=';
                    parms += model.count || 5;
                } else {
                    parms = 'percentage=';
                    parms += model.count ? model.count / 100 : 0.05;
                }

                let groupByText = '';
                if (groupby.length > 0) {
                    groupByText = "'" + groupby.join("','") + "'";
                }

                return [
                    'groupby(' + groupByText + ')',
                    'select(' + parms + '):!' + outputport,
                    'split',
                ];
            }

            function readableNumber(model) {
                let mode = 'count';
                let count = 5;
                if (model.fn.options) {
                    if (angular.isDefined(model.fn.options.count)) {
                        count = model.fn.options.count || count;
                    }
                    if (angular.isDefined(model.fn.options.mode)) {
                        mode = model.fn.options.mode;
                    }
                }

                if (mode === 'percent') {
                    return count + '%';
                } else {
                    return count;
                }
            }
        },
    ]);
