export const ChartPanningControl = [
    '$window',
    '$log',
    function ($window, $log) {
        function createPanControl(config) {
            let isPanning = false;
            let startPanningTimeStamp = null;
            let updateInterval = null;
            function cancelPanAction(notify) {
                $window.clearInterval(updateInterval);
                updateInterval = null;
                isPanning = false;
                startPanningTimeStamp = null;
                if (notify) {
                    config.onCancel();
                }
            }

            function beginPanAction() {
                isPanning = true;
                startPanningTimeStamp = Date.now();
                updateInterval = $window.setInterval(updatePanAction, 30);
            }

            function confirmPanAction() {
                let timeDelta = Date.now() - startPanningTimeStamp;
                if (timeDelta < 300) {
                    //if its a "click" then treat it like 2 seconds
                    timeDelta = 2000;
                }
                cancelPanAction(false);
                if (config.onPanComplete) {
                    config.onPanComplete(timeDelta);
                }
                return timeDelta;
            }

            function updatePanAction() {
                if (config.onUpdate) {
                    config.onUpdate(Date.now() - startPanningTimeStamp);
                }
            }

            return {
                start: function () {
                    beginPanAction();
                },
                stop: function () {
                    if (isPanning) {
                        confirmPanAction();
                    } else {
                        $log.info('Tried to stop when not panning already!');
                    }
                },
                isPanning: function () {
                    return isPanning;
                },
                cancel: function () {
                    cancelPanAction(false);
                },
            };
        }

        return createPanControl;
    },
];
