'use strict';
import templateUrl from './dashboardGroupPermissions.tpl.html';

export default {
    templateUrl,
    bindings: {
        dashboardGroupObject: '<',
        currentDashboard: '<',
        editable: '<',
        missingUpdateDashboardGroupCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$q',
        '$scope',
        '$log',
        'currentUser',
        'dashboardGroupService',
        'dashboardV2Service',
        'exitGuardModal',
        'writepermissionsPermissionsChecker',
        function (
            $q,
            $scope,
            $log,
            currentUser,
            dashboardGroupService,
            dashboardV2Service,
            exitGuardModal,
            // this component should remain based on writepermissions, because it is specifically only working with
            // writepermissions, there's a different component for editing accessControl permissions
            writepermissionsPermissionsChecker
        ) {
            const $ctrl = this;
            let obj, authorizedWriters, originalAuthorizedWriters, idUpdateMap;

            function save() {
                const v2Group = dashboardGroupService.convertToV2(obj);
                const hasGroupWritePermission = canEditGroupPermissions();
                v2Group.authorizedWriters = authorizedWriters;
                originalAuthorizedWriters = angular.copy(v2Group.authorizedWriters);

                const dashboardGroupUpdatePromises = [];
                if ($ctrl.dashboards) {
                    $ctrl.dashboards.forEach((dashboard) => {
                        if (!canEditDashboardPermissions(dashboard) || !idUpdateMap[dashboard.id]) {
                            return;
                        }
                        dashboard.authorizedWriters = $ctrl.dashboardIdToWriters[dashboard.id];

                        if ($ctrl.currentDashboard && $ctrl.currentDashboard.id === dashboard.id) {
                            $ctrl.currentDashboard.authorizedWriters = dashboard.authorizedWriters;
                        }

                        dashboardGroupUpdatePromises.push(dashboardV2Service.update(dashboard));
                    });
                }

                let dashboardGroupPromise;
                if (hasGroupWritePermission && idUpdateMap[v2Group.id]) {
                    dashboardGroupPromise = dashboardGroupService.update(v2Group);
                    dashboardGroupUpdatePromises.push(dashboardGroupPromise);
                } else {
                    dashboardGroupPromise = $q.when(v2Group);
                }

                return $q
                    .all(dashboardGroupUpdatePromises)
                    .catch((e) => {
                        $log.error('error', e);
                    })
                    .then(() => {
                        writepermissionsPermissionsChecker.setWriters(obj, authorizedWriters);
                        return dashboardGroupPromise;
                    })
                    .finally(function () {
                        $scope.dashboardGroupPermissionsForm.$setPristine();
                    });
            }

            function reset() {
                $ctrl.$onInit();
                $scope.$broadcast('resetPermissions', $ctrl.authorizedWriters);
                $scope.dashboardGroupPermissionsForm.$setPristine();
                return $q.when();
            }

            function exitGuard() {
                return exitGuardModal(save, reset);
            }

            function updateDashboardPermissionMap(dashboards) {
                $ctrl.dashboardPermissionsMap = {};
                return writepermissionsPermissionsChecker
                    .hasWritePermissionsBulk(dashboards)
                    .then(function (perms) {
                        perms = perms || [];
                        dashboards.forEach((dashboard, idx) => {
                            $ctrl.dashboardPermissionsMap[dashboard.id] = perms[idx];
                        });
                        $ctrl.canSaveAnything = perms.includes(true) || canEditGroupPermissions();
                    });
            }

            function canEditDashboardPermissions(dashboard) {
                return $ctrl.userIsAdmin || $ctrl.dashboardIdToWriters[dashboard.id];
            }

            function canEditGroupPermissions() {
                return $ctrl.editable || $ctrl.userIsAdmin;
            }

            $ctrl.$onInit = function () {
                obj = $ctrl.dashboardGroupObject;
                if (!originalAuthorizedWriters) {
                    originalAuthorizedWriters = writepermissionsPermissionsChecker.getWriters(obj);
                }
                authorizedWriters = $ctrl.authorizedWriters =
                    angular.copy(originalAuthorizedWriters);
                $ctrl.dashboardGroupId = obj.id;
                $ctrl.dashboardPermissionsVisible = false;
                $ctrl.dashboardIdToWriters = {};

                const userWritePermissionsPromise = currentUser
                    .defaultWritePermissions()
                    .then((defaults) => {
                        $ctrl.defaultWriters = defaults;
                    });
                const isAdminPromise = currentUser
                    .isAdmin()
                    .then((isAdmin) => ($ctrl.userIsAdmin = isAdmin));
                const dashboardPermissionsPromise = dashboardGroupService
                    .getDashboards(obj.id || obj.sf_id)
                    .then((response) => {
                        $scope.dashboardPermissionsPromise = updateDashboardPermissionMap([
                            ...response.dashboards,
                        ]);

                        $ctrl.dashboards = response.dashboards;
                        $ctrl.dashboards.forEach((dashboard) => {
                            const writers =
                                writepermissionsPermissionsChecker.getWriters(dashboard);
                            $ctrl.dashboardIdToWriters[dashboard.id] = writers || {};
                        });
                    });

                idUpdateMap = {};

                $q.all([
                    userWritePermissionsPromise,
                    isAdminPromise,
                    dashboardPermissionsPromise,
                ]).finally(function () {
                    $ctrl.permissionInformationReady = true;
                });
            };

            $ctrl.onChange = function (id) {
                if (id) {
                    idUpdateMap[id] = true;
                } else {
                    $log.error('Was notified of a permission update with no ID!');
                }
                $scope.dashboardGroupPermissionsForm.$setDirty();
            };

            $ctrl.showDashboardPermissions = function () {
                $ctrl.dashboardPermissionsVisible = true;
            };

            $ctrl.hideDashboardPermissions = function () {
                $ctrl.dashboardPermissionsVisible = false;
            };

            $ctrl.cancel = function () {
                $scope.$emit('dismiss modal');
            };

            $ctrl.saveAndClose = function () {
                save().then((dashboardGroup) => {
                    $scope.$emit('close modal', dashboardGroup);
                });
            };

            $scope.$watch('dashboardGroupPermissionsForm.$dirty', function (newVal) {
                if (newVal) {
                    $ctrl.tabby.enableExitGuard(exitGuard);
                } else {
                    $ctrl.tabby.disableExitGuard();
                }
            });
        },
    ],
};
