import templateUrl from './gcpIntegration.tpl.html';
import integrationServicesTemplateUrl from './integrationServices.html';

angular.module('sfx.ui').component('sfxGcpIntegration', {
    templateUrl,
    bindings: {
        credential: '<',
        editable: '<',
        integrationForm: '<',
        sharedCredentialState: '<',
    },
    controller: [
        '$scope',
        'sfxModal',
        '$log',
        '$timeout',
        'gcpService',
        'displayNamesWithMore',
        '_',
        'PRODUCT_NAME',
        function (
            $scope,
            sfxModal,
            $log,
            $timeout,
            gcpService,
            displayNamesWithMore,
            _,
            PRODUCT_NAME
        ) {
            const ctrl = this;
            const allowedWhitelistServiceCode = ['compute']; // only service that's allowing whitelist currently.

            ctrl.$onInit = function () {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                reset();
            };
            $scope.$on('reset', reset);

            ctrl.editGCPServices = editGCPServices;
            ctrl.$onChanges = reset;
            ctrl.toggleAddSelected = toggleAddSelected;
            ctrl.services = [];
            const supportedGCPServicesPromise = gcpService
                .getSupportedGCPServices()
                .then(function (result) {
                    return result.data;
                })
                .catch(function () {
                    $log.error('Failed trying to fetch supported GCP services');
                });

            ctrl.removeServiceKey = function (project) {
                const idx = $scope.projectList.indexOf(project);
                if (idx !== -1) {
                    $scope.projectList.splice(idx, 1);
                } else {
                    $log.error('Project to delete was not found');
                }
                ctrl.credential.projectServiceKeys = $scope.projectList;
                ctrl.integrationForm.$setDirty(true);
            };

            ctrl.customMetricTypeDomains = [];
            ctrl.customMetricTypeDomainRegex = new RegExp('^[.0-9a-zA-Z_-]+(/([^/]+))?$');
            ctrl.onCustomMetricTypeDomainsChange = (values) =>
                (ctrl.customMetricTypeDomains = values);
            ctrl.onCustomMetricTypeDomainsSanitizedChange = (values) =>
                (ctrl.credential.customMetricTypeDomains = values);

            function editGCPServices() {
                const modal = sfxModal.open({
                    templateUrl: integrationServicesTemplateUrl,
                    controller: 'integrationServices',
                    resolve: {
                        selected: function () {
                            return ctrl.services;
                        },
                        editable: function () {
                            return ctrl.editable;
                        },
                        serviceToName: supportedGCPServicesPromise.then((result) => {
                            return result;
                        }),
                        params: function () {
                            return {
                                modalTitle: 'Select Service Metrics to Import',
                                syncAllOptionText: 'Sync all supported services',
                                syncSelectedText: 'Sync only selected services',
                                syncAllHelpText:
                                    'Imports metrics from all supported services, updating as new services are added',
                            };
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                });

                modal.result.then((services) => {
                    ctrl.services = services;
                    checkShouldShowWhitelist();
                });
            }

            function checkShouldShowWhitelist() {
                const allowedWhitelistService =
                    ctrl.services.length === 0 ||
                    _.filter(
                        ctrl.services,
                        (service) => allowedWhitelistServiceCode.indexOf(service.code) !== -1
                    );
                $scope.whitelistAllowed =
                    allowedWhitelistService && allowedWhitelistService.length !== 0;
            }

            function toggleAddSelected() {
                $scope.addSelected = !$scope.addSelected;
            }

            function getInitialWhitelist() {
                ctrl.whitelist = (ctrl.credential.whitelist || []).join(',');
            }

            function reset() {
                ctrl.credential.services = ctrl.credential.services || [];
                ctrl.services = ctrl.credential.services.map((service) => {
                    return {
                        code: service,
                    };
                });
                supportedGCPServicesPromise.then((result) => {
                    ctrl.services = ctrl.credential.services.map((service) => {
                        return {
                            code: service,
                            name: result[service],
                        };
                    });
                });

                $scope.projectList = ctrl.credential.projectServiceKeys || [];
                ctrl.credential.pollRate = ctrl.credential.pollRate || 300000;
                getInitialWhitelist();
                checkShouldShowWhitelist();

                if (
                    ctrl.credential.importGCPMetrics === undefined ||
                    ctrl.credential.importGCPMetrics === null
                ) {
                    ctrl.credential.importGCPMetrics = true;
                }

                if (!ctrl.credential.type) {
                    ctrl.credential.type = 'GCP';
                }

                ctrl.customMetricTypeDomains = ctrl.credential.customMetricTypeDomains || [];

                ctrl.sharedCredentialState.updateCredentials = false;
                ctrl.integrationForm.$setPristine(true);
            }

            $scope.$watchCollection('$ctrl.services', (newValue) => {
                if (newValue === undefined) {
                    return;
                }

                const newCodes = newValue.map((service) => {
                    return service.code;
                });

                const oldCodes = ctrl.credential.services || [];
                if (!angular.equals(oldCodes, newCodes)) {
                    ctrl.credential.services = newCodes;

                    ctrl.integrationForm.$setDirty(true);
                }

                if (newValue.length === 0) {
                    $scope.gcpServicesText = 'All services';
                } else {
                    $scope.gcpServicesText = displayNamesWithMore.shortenNames(newValue);
                }
            });

            $scope.$watch('$ctrl.credential.pollRate', function (newVal, oldVal) {
                if (!oldVal || newVal === oldVal) {
                    return;
                }
                ctrl.integrationForm.$setDirty(true);
            });

            $scope.$watch('$ctrl.whitelist', function () {
                let nonEmptyWhitelist = ctrl.whitelist.split(',').filter((whitelist) => whitelist);
                nonEmptyWhitelist = nonEmptyWhitelist.map((whitelist) => whitelist.trim());
                ctrl.credential.whitelist = _.uniq(nonEmptyWhitelist);
            });

            $scope.$on('service keys imported', function (ev, serviceKeys) {
                addServiceKeys(serviceKeys);
                toggleAddSelected();
            });

            // for highlighting purposes if an existing project gets updated
            $scope.duplicateProjects = [];
            function addServiceKeys(serviceKeys) {
                serviceKeys.forEach(function (serviceKey) {
                    const serviceKeyId = serviceKey.project_id;
                    const duplicateProject = $scope.projectList.find(
                        (project) => serviceKeyId === project.projectId
                    );
                    if (duplicateProject) {
                        duplicateProject.projectKey = JSON.stringify(serviceKey);
                        $scope.duplicateProjects.push(duplicateProject.projectId);
                    } else {
                        $scope.projectList.push({
                            projectId: serviceKey.project_id,
                            projectKey: JSON.stringify(serviceKey),
                        });
                    }
                });
                ctrl.credential.projectServiceKeys = $scope.projectList;
                ctrl.sharedCredentialState.updateCredentials = !!ctrl.credential.projectServiceKeys;
                ctrl.integrationForm.$setDirty(true);
                $timeout(function () {
                    $scope.duplicateProjects = [];
                }, 1000);
            }
        },
    ],
});
