angular
    .module('signalboost')

    .service('DetectorRestService', [
        'SignalboostBaseService',
        function (SignalboostBaseService) {
            return class DetectorRestService extends SignalboostBaseService {
                constructor() {
                    super('detector', false);
                }
                addQueryTypeFields(query) {
                    return 'sf_detector:' + query;
                }
                getQuerySortOrder() {
                    return 'sf_detector';
                }
            };
        },
    ]);
