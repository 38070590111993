import ngSanitize from '@splunk/olly-angular-sanitize';

import showdown from 'showdown';
window.showdown = showdown;
import showdownPrettify from 'showdown-prettify/dist/showdown-prettify.js';
showdown.extensions.prettify = showdownPrettify;
import showdownTable from 'showdown-table';
showdown.extensions.table = showdownTable;
import showdownGithub from 'showdown-github/dist/showdown-github.js';
showdown.extensions.github = showdownGithub;

// adapted from https://github.com/btford/angular-markdown-directive/blob/master/markdown.js
angular
    .module('signalview.markdown', [ngSanitize])
    .provider('markdownConverter', function () {
        // custom extension for Showdown to prevent tab nabbing
        const addRelLinkAttribute = {
            type: 'output',
            regex: /(<a .+<\/a>)/g,
            replace: (match) => {
                return match.replace('>', ' rel="noopener noreferrer">');
            },
        };
        showdown.extension('addRelLinkAttribute', addRelLinkAttribute);

        let opts = {};
        return {
            config: function (newOpts) {
                opts = newOpts;
            },
            $get: function () {
                return new showdown.Converter(opts);
            },
        };
    })
    .config([
        'markdownConverterProvider',
        function (markdownConverterProvider) {
            // options to be passed to Showdown
            // see: https://github.com/coreyti/showdown#extensions
            markdownConverterProvider.config({
                // prettify supports <pre></pre> to prettify indentation and border
                // github supports ~~strike through this text~~
                // we can add custom extensions here later, such as <sfx>plotnumber</sfx> to
                // add single value chart text
                extensions: ['prettify', 'github', 'addRelLinkAttribute'],
                literalMidWordUnderscores: true,
                literalMidWordAsterisks: true,
                strikethrough: true,
            });
        },
    ])
    .directive('btfMarkdown', [
        '$sanitize',
        'markdownConverter',
        function ($sanitize, markdownConverter) {
            return {
                restrict: 'AE',
                link: function (scope, element, attrs) {
                    if (attrs.btfMarkdown) {
                        scope.$watch(attrs.btfMarkdown, function (newVal) {
                            markdownConverter.setOption('tables', !attrs.disabledTables);
                            const html = newVal
                                ? $sanitize(markdownConverter.makeHtml(newVal))
                                : '';
                            element.html(html);
                        });
                    } else {
                        const html = $sanitize(markdownConverter.makeHtml(element.text()));
                        element.html(html);
                    }
                },
            };
        },
    ]);
