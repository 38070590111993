'use strict';

angular.module('sfx.ui').controller('integrationServices', [
    '_',
    '$scope',
    '$uibModalInstance',
    'editable',
    'params',
    'selected',
    'serviceToName',
    function (_, $scope, $uibModalInstance, editable, params, selected, serviceToName) {
        $scope.editable = editable;
        $scope.params = params || {};

        // note: do not show entries without names
        const serviceNames = Object.values(serviceToName).filter(Boolean).sort();
        $scope.hasDuplicatedNames = serviceNames.length !== _.sortedUniq(serviceNames).length;

        // note: do not show entries without names
        // this is used by the backend to denote special entries
        // integrationServicesSpec.js contains more information
        $scope.serviceIds = _.keys(serviceToName)
            .sort()
            .filter((key) => serviceToName[key]);
        $scope.getServiceNameById = (serviceId) => serviceToName[serviceId];

        $scope.syncOptions = selected.length === 0 ? 'SYNC_ALL' : 'SYNC_SELECTED';
        if ($scope.params.disableSyncAll) {
            $scope.syncOptions = 'SYNC_SELECTED';
        }

        $scope.idSelectionMap = _.transform(
            serviceToName,
            (result, _, key) => {
                result[key] = selected.some((service) => service.code === key);
            },
            {}
        );
        $scope.getSelectedIds = () =>
            _.toPairs($scope.idSelectionMap)
                .filter(([, selected]) => selected)
                .map(([key]) => key);

        Object.defineProperty($scope, 'isApplyAllowed', {
            get: () => $scope.syncOptions === 'SYNC_ALL' || $scope.getSelectedIds().length > 0,
        });

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.apply = function () {
            const result =
                $scope.syncOptions === 'SYNC_ALL'
                    ? []
                    : $scope.getSelectedIds().map((key) => ({
                          code: key,
                          name: serviceToName[key],
                      }));

            $uibModalInstance.close(result);
        };
    },
]);
