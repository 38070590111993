'use strict';
import templateUrl from './detectorPermissions.tpl.html';

export default {
    templateUrl,
    bindings: {
        detectorObject: '<',
        editable: '<',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$scope',
        'detectorUtils',
        'detectorv2Service',
        'currentUser',
        '$log',
        function ($scope, detectorUtils, detectorv2Service, currentUser, $log) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            let obj, authorizedWriters;
            function $onInit() {
                obj = $ctrl.detectorObject;

                if (obj.sf_uiModel) {
                    // v1
                    $ctrl.authorizedWriters = {
                        users: angular.copy(obj.sf_authorizedUserWriters),
                        teams: angular.copy(obj.sf_authorizedTeamWriters),
                    };
                } else {
                    // v2
                    $ctrl.authorizedWriters = angular.copy(obj.authorizedWriters) || {};
                }

                authorizedWriters = $ctrl.authorizedWriters;

                currentUser.defaultWritePermissions().then(function (defaults) {
                    $ctrl.defaultWriters = defaults;
                });

                currentUser.isAdmin().then((isAdmin) => ($ctrl.userIsAdmin = isAdmin));
            }

            $ctrl.cancel = function () {
                $scope.$emit('dismiss modal');
            };

            $ctrl.saveAndClose = function () {
                let updatePromise;
                if (obj.sf_uiModel) {
                    // v1
                    obj.sf_authorizedUserWriters = authorizedWriters.users;
                    obj.sf_authorizedTeamWriters = authorizedWriters.teams;
                    updatePromise = detectorUtils.saveDetector(obj);
                } else {
                    obj.authorizedWriters = authorizedWriters;
                    updatePromise = detectorv2Service.update(obj);
                }

                updatePromise
                    .then(function (res) {
                        $scope.$emit('close modal', res);
                    })
                    .catch(function (e) {
                        $log.error('error', e);
                        $scope.$emit('dismiss modal');
                    });
            };
        },
    ],
};
