import mutingOptionsTemplateUrl from './mutingOptions.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular.module('signalview.alertMuting').controller('MutingModalController', [
    '$scope',
    'alertMutingApiService',
    'mutingService',
    '$timeout',
    'eventTimeSeriesService',
    'sourceFilterService',
    '$log',
    'mutingParams',
    'notifyBlockService',
    'processNotificationRecipient',
    'currentUser',
    'featureEnabled',
    'relativeTimeOptions',
    '_',
    'dimensionService',
    'chartbuilderUtil',
    'typeaheadUtils',
    'filterFactory',
    'DetectorV2SearchService',
    'hasCapability',
    function (
        $scope,
        alertMutingApiService,
        mutingService,
        $timeout,
        eventTimeSeriesService,
        sourceFilterService,
        $log,
        mutingParams,
        notifyBlockService,
        processNotificationRecipient,
        currentUser,
        featureEnabled,
        relativeTimeOptions,
        _,
        dimensionService,
        chartbuilderUtil,
        typeaheadUtils,
        filterFactory,
        DetectorV2SearchService,
        hasCapability
    ) {
        const longMutingDuration = 48 * 60 * 60 * 1000; // 48 hours
        const dayInMilliseconds = 24 * 60 * 60 * 1000;

        $scope.step = 0;
        $scope.titleText = 'Muting rule';
        $scope.mutingOptionsTemplateUrl = mutingOptionsTemplateUrl;
        $scope.resolveMatchingActiveAlertsEnabled = featureEnabled('resolveMatchingActiveAlerts');
        $scope.recurringMutingRulesEnabled = featureEnabled('recurringMutingRules');

        // Initialize the muteOption based on the existing $scope.muteIndefinitely value
        $scope.muteOption = 'indefinitely';
        $scope.recurrenceOption = $scope.recurrenceOption || 'noRepeat';
        $scope.recurrenceValue = $scope.recurrenceValue || 1;
        $scope.recurrenceUnit = $scope.recurrenceUnit || 'd';
        $scope.recurrence = $scope.recurrence || {
            value: 1,
            unit: 'd',
        };
        $scope.nextRecurrence = 0;
        $scope.errorMsg = '';
        $scope.deleteModalState = {
            isOpen: false,
            onCancel: function () {
                $timeout(() => {
                    $scope.deleteModalState.isOpen = false;
                }, 0);
            },
        };

        hasCapability(Capability.DELETE_MUTING_RULE).then((hasDeleteMutingRuleCapability) => {
            $scope.hasDeleteMutingRuleCapability = hasDeleteMutingRuleCapability;
        });

        function fetchDetector() {
            DetectorV2SearchService.get($scope.detectorId).then(function (obj) {
                $scope.detector = obj;
            });
        }

        function updateTitle() {
            $scope.titleText = '';
            if ($scope.started) {
                $scope.titleText = 'Active ';
            }
            $scope.titleText += 'Muting rule';
            if ($scope.step === 1) {
                $scope.titleText += ' - ';
            }
            if ($scope.muteIndefinitely) {
                $scope.muteDuration = 'Mute Indefinitely';
            } else if ($scope.step === 1) {
                setMaxDurationText();
            }
        }
        updateTitle();

        function mapAndFilterFilters(filters) {
            return filters
                .map(function (filter) {
                    filter.iconClass = 'icon-properties';
                    filter.type = 'property';
                    return filter;
                })
                .filter(function (filter) {
                    if (
                        filter.property === 'sf_detectorId' &&
                        angular.isString(filter.propertyValue)
                    ) {
                        // only if it's detector id and it's only one value
                        $scope.detectorId = filter.propertyValue;
                        fetchDetector();
                        return false;
                    }
                    return true;
                });
        }

        currentUser.features().then((features) => {
            $scope.isSendAlertsOnceMutingPeriodHasEndedEnabled =
                featureEnabled('sendAlertsOnceMutingPeriodHasEnded') &&
                features.calculated.includes('sendAlertsOnceMutingPeriodHasEnded');

            if (!mutingParams.muting) {
                $scope.sendAlertsOnceMutingPeriodHasEnded =
                    $scope.isSendAlertsOnceMutingPeriodHasEndedEnabled;
            }
        });

        let muting;
        if (mutingParams.muting) {
            muting = mutingParams.muting;
            $scope.hasId = muting.id;
            $scope.description = muting.description;
            $scope.filters = mapAndFilterFilters(muting.filters || []);
            $scope.mutingTime = { absoluteStart: muting.startTime, absoluteEnd: muting.stopTime };
            $scope.currentTime = { startUTC: muting.startTime, endUTC: muting.stopTime };
            $scope.started = muting.started;
            if (muting.started) {
                $scope.customScheduleConfig = {
                    disableFrom: true,
                    disableFromReason:
                        'Cannot update start time of alert muting that has already started.',
                };
            }
            $scope.muteIndefinitely = muting.stopTime === 0;
            $scope.ended = !$scope.muteIndefinitely && muting.stopTime < Date.now();
            $scope.mutingRuleEditable = !$scope.ended;
            $scope.scheduleOptions = relativeTimeOptions(muting.startTime, muting.stopTime);
            $scope.creatorName = mutingService.getMutingUserDisplayName(muting.creatorUser);
            $scope.created = muting.created;
            $scope.creatorProfileId = muting.creatorUser?.id;
            $scope.lastUpdatedName = mutingService.getMutingUserDisplayName(
                muting.lastUpdatedByUser
            );
            $scope.lastUpdated = muting.lastUpdated;
            $scope.lastUpdatedProfileId = muting.lastUpdatedByUser?.id;
            $scope.hasAuditInfo =
                $scope.creatorName ||
                $scope.created ||
                $scope.lastUpdatedName ||
                $scope.lastUpdated;
            $scope.sendAlertsOnceMutingPeriodHasEnded = muting.sendAlertsOnceMutingPeriodHasEnded;
            $scope.resolveMatchingActiveAlerts = muting.resolveMatchingActiveAlerts;
            $scope.muteOption = muting.stopTime === 0 ? 'indefinitely' : 'periodically';
            if (muting.recurrence !== null) {
                patchRecurrenceValueFromAPI(muting.recurrence);
            } else {
                $scope.recurrenceOption = 'noRepeat';
            }
        } else {
            $scope.description = '';
            $scope.filters = mapAndFilterFilters(mutingParams.filters || []);
            $scope.mutingTime = null;
            $scope.muteIndefinitely = true;
            $scope.muteOption = 'indefinitely';
            $scope.mutingRuleEditable = true;
            $scope.titleText = 'Create muting rule';
        }

        function patchRecurrenceValueFromAPI(recurrence) {
            const recurrenceOptions = {
                '1_d': { option: 'daily', unit: 'd', value: 1 },
                '1_w': { option: 'weekly', unit: 'w', value: 1 },
                custom: { option: 'custom', unit: recurrence.unit, value: recurrence.value },
                noRepeat: { option: 'noRepeat' },
            };

            const key =
                recurrence.value !== 1 ? 'custom' : `${recurrence.value}_${recurrence.unit}`;
            const defaultKey = 'noRepeat';

            const {
                option,
                unit = null,
                value = null,
            } = recurrenceOptions[key] || recurrenceOptions[defaultKey];

            $scope.recurrenceOption = option;
            $scope.recurrenceUnit = unit;
            $scope.recurrenceValue = value;
        }

        $scope.getRestrictorQuery = function () {
            return '';
        };

        $scope.objectTypes = ['MetricTimeSeries'];

        $scope.$on('timePickerChanged', function (ev, timeObj) {
            $scope.currentTime = {
                startUTC: $scope.started ? $scope.currentTime.startUTC : timeObj.startUTC,
                endUTC: timeObj.endUTC,
            };

            const difference = $scope.currentTime.endUTC - $scope.currentTime.startUTC;
            $scope.longMuting = difference > longMutingDuration;
            setMaxDurationText();
        });

        function setMaxDurationText() {
            const difference = $scope.currentTime.endUTC - $scope.currentTime.startUTC;
            const hours = difference / (1000 * 60 * 60); // time difference in hours
            if (hours >= 24) {
                // days
                const days = hours / 24;
                if (Number.isInteger(days)) {
                    $scope.muteDuration = days + ' Days';
                } else {
                    $scope.muteDuration = 'More than ' + Math.floor(days) + ' Days';
                }
            } else {
                if (Number.isInteger(hours)) {
                    $scope.muteDuration = hours + ' Hour';
                } else {
                    $scope.muteDuration = 'More than ' + Math.floor(hours) + ' Hour';
                }

                if (hours !== 1) {
                    $scope.maxDuration += 's';
                }
            }
        }

        function finishSaving() {
            return $timeout($scope.$close, 3000);
        }

        function onFailure(response) {
            return $timeout(() => {
                $scope.step = 0;
                $scope.loading = false;
                $scope.errorMsg = response.data.message;
            }, 500);
        }

        function updateActiveAlertsCount() {
            $scope.matchingActiveAlertsCount = 0;
            if (!$scope.resolveMatchingActiveAlerts) {
                return Promise.resolve();
            }

            return eventTimeSeriesService
                .aggregation({
                    query:
                        sourceFilterService.translateSourceFilterObjects(getFilters()) +
                        ' AND sf_anomalyState:anomalous',
                    includeArchived: true,
                    getAggregation: true,
                    terms: ['sf_anomalyState'],
                })
                .then((result) => ($scope.matchingActiveAlertsCount = result.count))
                .catch((e) => $log.error('Failure retrieving matching active alerts count', e));
        }

        $scope.isDisabled = function () {
            const currentTimeSelected =
                $scope.currentTime && $scope.currentTime.startUTC && $scope.currentTime.endUTC;

            return (
                !(currentTimeSelected || $scope.muteIndefinitely) ||
                !($scope.filters.length || $scope.detectorId) ||
                !$scope.description
            );
        };

        $scope.back = function () {
            if ($scope.detectorId) {
                $scope.step = 0;
            } else {
                $scope.step -= 1;
            }
        };

        $scope.$watch('step', updateTitle);

        function failure(e) {
            $scope.loading = false;
            $log.error('Failure processing muting', e);
            $scope.$applyAsync();
        }

        function markStepLoaded(stepNo) {
            return () => {
                $scope.step = stepNo;
                $scope.loading = false;
                $scope.$applyAsync();
            };
        }

        function getFilters() {
            let filters = $scope.filters;
            if ($scope.detectorId) {
                filters = filters.concat([
                    { property: 'sf_detectorId', propertyValue: $scope.detectorId },
                ]);
            }
            return filters;
        }

        $scope.next = function () {
            $scope.loading = true;

            const recurrenceDuration =
                $scope.recurrence.unit === 'd'
                    ? $scope.recurrence.value * dayInMilliseconds
                    : $scope.recurrence.value * dayInMilliseconds * 7;

            if (!$scope.muteIndefinitely) {
                $scope.nextRecurrence = $scope.currentTime.startUTC + recurrenceDuration;
            }

            const oneYearAgo = Date.now() - 365 * 24 * 60 * 60 * 1000;

            eventTimeSeriesService
                .aggregation({
                    // query the ets with the dimensions that were updated in the last year (to not get so many and some of them no longer have detector associated with it)
                    query:
                        sourceFilterService.translateSourceFilterObjects(getFilters()) +
                        ` AND sf_anomalyStateUpdateTimestampMs:[${oneYearAgo} TO *]`,
                    includeArchived: true,
                    getAggregation: true,
                    terms: ['sf_detectorId'],
                })
                .then(function (result) {
                    if (result.aggregations) {
                        const detectorIds = result.aggregations
                            .filter(function (aggregation) {
                                return aggregation.name;
                            })
                            .map(function (aggregation) {
                                return aggregation.name;
                            });
                        return DetectorV2SearchService.search({
                            detectorIds,
                        });
                    } else {
                        return { rs: [] };
                    }
                })
                .then(function (result) {
                    let notificationRecipients = [];
                    result.rs.forEach(function (detector) {
                        (detector.sf_uiModel.rules || []).forEach(function (rule) {
                            notificationRecipients = notificationRecipients.concat(
                                rule.notifications
                            );
                        });
                    });

                    return processNotificationRecipient
                        .getDisplayDetails(notificationRecipients)
                        .then(() => result);
                })
                .then((result) => {
                    $scope.detectorScope = result.rs;

                    result.rs.forEach(function (detector) {
                        detector.recipients = [];
                        (detector.sf_uiModel.rules || []).forEach(function (rule) {
                            detector.recipients = _.uniq(
                                detector.recipients.concat(
                                    rule.notifications.map(notifyBlockService)
                                )
                            );
                        });
                    });

                    if (result.rs.length === 1) {
                        result.rs[0].expanded = true;
                    }
                })
                .then(updateActiveAlertsCount)
                .then(markStepLoaded(1))
                .catch(failure);
        };

        $scope.save = function () {
            if ($scope.isDisabled()) {
                return;
            }
            $scope.loading = true;

            const obj = {
                description: $scope.description,
                filters: getFilters(),
                sendAlertsOnceMutingPeriodHasEnded: $scope.sendAlertsOnceMutingPeriodHasEnded,
            };

            if ($scope.currentTime) {
                obj.startTime = $scope.currentTime.startUTC;
                obj.stopTime = $scope.currentTime.endUTC;
            }

            if ($scope.recurrenceOption !== 'noRepeat') {
                obj.recurrence = $scope.recurrence;
            }

            if ($scope.muteIndefinitely) {
                obj.stopTime = 0;
            }

            if (muting) {
                alertMutingApiService.update(muting.id, obj).then(finishSaving).catch(onFailure);
            } else {
                alertMutingApiService
                    .create(obj, $scope.resolveMatchingActiveAlerts)
                    .then(finishSaving)
                    .catch(onFailure);
            }
        };

        $scope.resumeNotifications = () => {
            $scope.deleteModalState.onDelete = function () {
                $timeout(function () {
                    $scope.deleteModalState.isOpen = false;
                    mutingService.endMuting(muting).then($scope.$close);
                }, 0);
            };

            $scope.deleteModalState.isOpen = true;
            $scope.deleteModalState.title = 'Resume Notifications';
            $scope.deleteModalState.description =
                'You are about to resume notifications. Are you sure?';
            $scope.deleteModalState.callToAction = 'Resume';
        };

        $scope.delete = (muting) => {
            $scope.deleteModalState.onDelete = function () {
                $timeout(function () {
                    $scope.deleteModalState.isOpen = false;
                    mutingService.deleteMuting(muting).then($scope.$close);
                }, 0);
            };

            $scope.deleteModalState.isOpen = true;
            $scope.deleteModalState.title = 'Delete Muting Rule';
            $scope.deleteModalState.description =
                'You are about to delete this muting rule. Are you sure?';
            $scope.deleteModalState.callToAction = 'Delete';
        };

        $scope.sourceSuggestions = (
            currentQuery,
            q,
            limit,
            propertyTypes,
            objectTypes,
            disableTag
        ) => {
            if ($scope.filters.length === 0) {
                // for the first filter with no context, we can use ES to get the list of properties/values. This will avoid the
                // issue of meatballs only returning a subset of possible values
                return getFirstSuggestion(q, limit);
            } else {
                return chartbuilderUtil.getSourceSuggestions(
                    currentQuery,
                    q,
                    limit,
                    propertyTypes,
                    objectTypes,
                    disableTag
                );
            }
        };

        function getFirstSuggestion(q, limit) {
            if (!limit) {
                limit = 100;
            }
            const parsedPartial = typeaheadUtils.parsePartial(q);
            const isNotQuery = parsedPartial.isNot();
            const propertyName = parsedPartial.getProperty();
            const propertyValue = parsedPartial.getValue();
            const isValueSearch = parsedPartial.isValueSuggest();

            if (!isValueSearch) {
                return dimensionService
                    .getPropertyNameSuggest({ partialInput: propertyName })
                    .then((results) => {
                        return results.map((data) =>
                            filterFactory.FILTER(
                                filterFactory.TYPES.PROPERTY,
                                data,
                                isNotQuery,
                                propertyName
                            )
                        );
                    });
            }

            return dimensionService
                .getPropertyValueSuggest({
                    property: propertyName,
                    partialInput: propertyValue,
                    limit,
                })
                .then((results) => {
                    return results.map((data) =>
                        filterFactory.FILTER(
                            filterFactory.TYPES.VALUE,
                            data,
                            isNotQuery,
                            propertyName
                        )
                    );
                });
        }

        $scope.updateMuteOption = (selectedMuteOption) => {
            $scope.muteIndefinitely = selectedMuteOption === 'indefinitely';
            $scope.muteOption = selectedMuteOption;
        };

        $scope.updateRecurrenceValue = (val) => {
            $scope.recurrenceValue = val;
            $scope.recurrence.value = val;
        };

        $scope.updateRecurrenceUnit = (unit) => {
            $scope.recurrenceUnit = unit;
            $scope.recurrence.unit = unit;
        };

        $scope.recurrenceOnChange = (option) => {
            $scope.recurrenceOption = option;
            switch (option) {
                case 'daily':
                    $scope.recurrence = {
                        value: 1,
                        unit: 'd',
                    };
                    break;
                case 'weekly':
                    $scope.recurrence = {
                        value: 1,
                        unit: 'w',
                    };
                    break;
                case 'custom':
                    $scope.recurrence = {
                        value: $scope.recurrenceValue,
                        unit: $scope.recurrenceUnit,
                    };
                    break;
                case 'noRepeat':
                    delete $scope.recurrence;
                    break;
                default:
                    $scope.recurrence = {
                        value: 1,
                        unit: 'd',
                    };
            }
        };
    },
]);
