import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import alertTypeService from '../../legacy/app/alerts/alertTypeService';

type MutingStates = {
    isMuted?: boolean;
    triggeredWhileMuted?: boolean;
    triggeredNotificationSent?: boolean;
};

const MutedChip: FC<{ eventState: string; mutingStates: MutingStates | undefined }> = ({
    eventState,
    mutingStates,
}) => {
    const [tooltipText, setTooltipText] = useState('');
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (mutingStates?.isMuted) {
            const eventStateText = alertTypeService.getDisplayFor(eventState).toLowerCase();
            setTooltipText(
                `This alert was ${eventStateText} while a muting rule was active. No notifications were sent.`
            );
            setLabel('notifications muted');
        } else if (mutingStates?.triggeredWhileMuted && mutingStates?.triggeredNotificationSent) {
            setTooltipText('Alert was muted but the muting period expired and an alert was sent.');
            setLabel('muted then sent');
        }
    }, [eventState, mutingStates]);

    return tooltipText && label ? (
        <Tooltip content={tooltipText}>
            <div className="label-badge muted">
                <i className="glyphicon glyphicon-volume-off" />
                {label}
            </div>
        </Tooltip>
    ) : null;
};

MutedChip.propTypes = {
    eventState: PropTypes.string.isRequired,
    mutingStates: PropTypes.object,
};

export default MutedChip;
