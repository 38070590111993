import { addCommonHeaders } from './createV2Service.js';
import { AngularInjector } from '../../../../common/AngularUtils';

const EFFECTIVE_PERMISSIONS_PATH = 'effective';

PermissionsServiceFactory.$inject = ['API_URL', '$http', 'featureEnabled'];
export default function PermissionsServiceFactory(API_URL, $http, featureEnabled) {
    const PERMISSIONS_API_URL = `${API_URL}/v2/permissions`;

    this.fetchPermissions = (objectId) => {
        if (!featureEnabled('accessControl')) {
            return null;
        }

        return $http(
            addCommonHeaders({
                method: 'GET',
                url: `${PERMISSIONS_API_URL}/${objectId}`,
            })
        ).then((response) => response.data);
    };

    this.fetchEffectivePermissions = (objectIds) => {
        if (!featureEnabled('accessControl')) {
            return null;
        }

        return $http(
            addCommonHeaders({
                method: 'GET',
                url: `${PERMISSIONS_API_URL}/${EFFECTIVE_PERMISSIONS_PATH}`,
                params: {
                    id: objectIds,
                },
            })
        ).then((response) => response.data);
    };
}

PermissionsServiceFactory.useInstance = () =>
    AngularInjector.useInjectedClass(PermissionsServiceFactory);
