angular.module('sfx.api.v2').service('autoRedirectWhenHttpError', [
    '$location',
    function ($location) {
        return function autoRedirectWhenHttpErrorHandler(responseOrError) {
            if (responseOrError?.status === 404) {
                $location.path('/404').replace();
            } else if (responseOrError?.status !== 401) {
                // 401s trigger a relogin modal because of access denied
                $location.path('/error').replace();
            }

            throw responseOrError;
        };
    },
]);
