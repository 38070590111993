import ControlGroup from '@splunk/react-ui/ControlGroup';
import Tooltip from '@splunk/react-ui/Tooltip';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AclDashboardService from '../../../legacy/app/dashboard/aclDashboard/AclDashboardService';
import useInputState from '../../../common/react-utils/useInputState';
import AccessControl from '../../../common/ui/accessControl/AccessControl';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import accessControlHelper from '../../../common/ui/accessControl/accessControlHelper';
import AclDashboardGroupSelector from './AclDashboardGroupSelector';
import AclFormInput from '../../../common/ui/accessControl/AclFormInput';
import useAclOrgPreferencesContext from '../../../common/ui/accessControl/useAclOrgPreferencesContext';
import useAclUserContext from '../../../common/ui/accessControl/useAclUserContext';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';
import {
    StyledPanel,
    StyledButton,
    StyledModalHeader,
    StyledBody,
    StyledFooter,
    StyledForm,
    StyledSectionHeader,
} from '../../../common/ui/ModalStyles';
import aclValidator from '../../../common/ui/accessControl/aclValidator';
import useAclPermissions from '../../../common/ui/accessControl/useAclPermissions';
import { ThemeProvider } from '../../../common/theme/ThemeProvider';
import Switch from '@splunk/react-ui/Switch';
import styled from 'styled-components';
import { AngularInjector } from '../../../common/AngularUtils';
import { variables } from '@splunk/themes';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import P from '@splunk/react-ui/Paragraph';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

const OBJECT_TYPE = AccessControlObjectType.DASHBOARD;

const SAVE_DASHBOARD_AS_LABEL = 'Save dashboard as';
const DASHBOARD_NAME_LABEL = 'Dashboard name';
const DASHBOARD_DESC_LABEL = 'Dashboard description';
const SAVE_TO_DASHBOARD_GROUP_LABEL = 'Save to dashboard group';
const DASHBOARD_GROUP_LABEL = 'Dashboard group';
const SAVE_BUTTON_LABEL = 'Save';
const CANCEL_BUTTON_LABEL = 'Cancel';
const NO_DATA_LINKS_LABEL = 'No data links have been created for this dashboard.';
const DASHBOARD_DATA_LINKS_LABEL = 'Clone data links';
const DASHBOARD_DATA_LINKS_TOOLTIP_LABEL =
    'Select this option to save all local data links to the cloned dashboard.';

const StyledDatalinkCheckboxControlGroup = styled(ControlGroup)`
    margin-top: ${variables.spacingMedium};
`;

const StyledDatalinkCheckboxLabel = styled(P)`
    margin-bottom: 0;
    padding-left: 3px;
    color: ${(props) =>
        props.disabled ? variables.contentColorMuted : variables.contentColorActive};
    &:focus {
        outline: 3px solid ${variables.focusColor};
    }
`;

const LoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
`;

export default function CreateAclDashboard({
    dashboardModel,
    chartsList,
    defaultDashboardGroupId,
    onDismiss,
    onClose,
    userData,
    themeKey,
    isDecorativeIcon = false,
}) {
    const userContext = useAclUserContext(userData);
    const [defaultPermissions] = useState({
        acl: accessControlHelper.getDefaultAcl(
            userContext.orgId,
            userContext.userId,
            AccessControlPermissionTypes.INHERIT
        ),
        parent: defaultDashboardGroupId || null,
    });
    const orgPrefContext = useAclOrgPreferencesContext(userContext.orgId);

    const {
        permissions,
        parentPermissions: dashboardGroupPermissions,
        userEffectiveActions,
        update: updatePermissions,
        isLoading,
    } = useAclPermissions(userContext, null, defaultPermissions);
    const featureEnabled = AngularInjector.instantiate('featureEnabled');
    const crossLinkCloneEnabled = featureEnabled('crosslinkCloneCheckbox') && dashboardModel.id;
    const [name, setName] = useInputState('');
    const [isDatalinksClonned, setIsDatalinksClonned] = useState(false);
    const [crossLinks, setCrossLinks] = useState([]);
    const [description, setDescription] = useInputState('');
    const [dashboardGroup, setDashboardGroup] = useState(null);

    const aclDashboardService = AclDashboardService.useInstance();

    const [saveInProgress, setSaveInProgress] = useState(false);
    const [isCrossLinksLoading, setIsCrossLinksLoading] = useState(crossLinkCloneEnabled);

    const validationErrors = [
        ...validateForm(),
        ...aclValidator.getNonWritableObjectError(permissions, userEffectiveActions, OBJECT_TYPE),
    ];
    const canSave = !saveInProgress && !isLoading && !validationErrors.length;

    const handleDataLinksSelection = () => setIsDatalinksClonned(!isDatalinksClonned);

    const hasCrossLinks = !(crossLinks && crossLinks.length > 0);

    useEffect(() => {
        if (crossLinkCloneEnabled) {
            aclDashboardService
                .getCrossLinks(dashboardModel.id)
                .then((crossLinks) => setCrossLinks(crossLinks.results))
                .catch((error) => handleError(error, 'Failed to fetch data links'))
                .finally(() => setIsCrossLinksLoading(false));
        }
    }, [dashboardModel.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider colorScheme={themeKey}>
            <AclFormContext.Provider
                value={{
                    ...userContext,
                    disabled: isLoading || saveInProgress,
                    syncInProgress: isLoading,
                    restrictTeamAccess: orgPrefContext.sf_restrictTeamManagement,
                }}
            >
                <StyledPanel>
                    <StyledForm onSubmit={handleSubmit}>
                        <StyledModalHeader>{SAVE_DASHBOARD_AS_LABEL}</StyledModalHeader>

                        <StyledBody>
                            {isCrossLinksLoading ? (
                                <LoadingSpinner>
                                    <WaitSpinner size="medium" />
                                </LoadingSpinner>
                            ) : (
                                <>
                                    <AclFormInput
                                        autoFocus={true}
                                        name="dashboard-name"
                                        label={DASHBOARD_NAME_LABEL}
                                        value={name}
                                        onChange={setName}
                                    />
                                    <AclFormInput
                                        name="dashboard-description"
                                        label={DASHBOARD_DESC_LABEL}
                                        value={description}
                                        onChange={setDescription}
                                    />
                                    <AccessControl
                                        permissions={permissions}
                                        parentPermissions={dashboardGroupPermissions}
                                        onChangeCallback={onPermissionsUpdate}
                                        objectType={OBJECT_TYPE}
                                        isDecorativeIcon={isDecorativeIcon}
                                    />

                                    {crossLinkCloneEnabled && (
                                        <StyledDatalinkCheckboxControlGroup labelWidth="unset">
                                            <Tooltip
                                                contentRelationship="label"
                                                data-test="datalink-disabled-tooltip"
                                                content={hasCrossLinks && NO_DATA_LINKS_LABEL}
                                            >
                                                <Switch
                                                    onClick={handleDataLinksSelection}
                                                    selected={isDatalinksClonned}
                                                    appearance="checkbox"
                                                    inline="true"
                                                    disabled={hasCrossLinks}
                                                >
                                                    <StyledDatalinkCheckboxLabel
                                                        tabIndex={0}
                                                        disabled={hasCrossLinks}
                                                    >
                                                        {DASHBOARD_DATA_LINKS_LABEL}
                                                    </StyledDatalinkCheckboxLabel>
                                                </Switch>
                                            </Tooltip>
                                            <Tooltip
                                                role="tooltip"
                                                data-test="datalink-helper-tooltip"
                                                content={DASHBOARD_DATA_LINKS_TOOLTIP_LABEL}
                                            />
                                        </StyledDatalinkCheckboxControlGroup>
                                    )}

                                    <StyledSectionHeader>
                                        {SAVE_TO_DASHBOARD_GROUP_LABEL}
                                    </StyledSectionHeader>

                                    <ControlGroup label={DASHBOARD_GROUP_LABEL} labelPosition="top">
                                        <AclDashboardGroupSelector
                                            disabled={false}
                                            defaultDashboardGroupId={defaultDashboardGroupId}
                                            onReset={() => onDashboardGroupUpdate(null, null)}
                                            onChange={(dashboardGroupModel, sortedAcl) =>
                                                onDashboardGroupUpdate(
                                                    dashboardGroupModel,
                                                    sortedAcl
                                                )
                                            }
                                        />
                                    </ControlGroup>
                                </>
                            )}
                        </StyledBody>
                        <StyledFooter>
                            <StyledButton
                                label={CANCEL_BUTTON_LABEL}
                                appearance="secondary"
                                size="small"
                                onClick={() => onDismiss('cancel')}
                            />
                            <Tooltip
                                style={{ marginLeft: 10 }}
                                content={validationErrors.length ? validationErrors[0] : ''}
                            >
                                <StyledButton
                                    label={SAVE_BUTTON_LABEL}
                                    type="submit"
                                    appearance="primary"
                                    size="small"
                                    disabled={!canSave}
                                />
                            </Tooltip>
                        </StyledFooter>
                    </StyledForm>
                </StyledPanel>
            </AclFormContext.Provider>
        </ThemeProvider>
    );

    function onPermissionsUpdate(newPermissions) {
        if (
            accessControlHelper.detectPermissionType(newPermissions.acl) ===
            AccessControlPermissionTypes.INHERIT
        ) {
            return updatePermissions(
                { ...newPermissions, parent: dashboardGroup?.id },
                dashboardGroup?.permissions
            );
        }

        updatePermissions(newPermissions);
    }

    function onDashboardGroupUpdate(dashboardGroupModel, dashboardGroupAcl) {
        updatePermissions(
            { ...permissions, parent: dashboardGroupModel?.id },
            dashboardGroupAcl ? { acl: dashboardGroupAcl } : null
        );
        setDashboardGroup(dashboardGroupModel);
    }

    function validateForm() {
        const validationErrors = [];
        if (name.trim() === '') {
            validationErrors.push('Please enter a name for the dashboard');
        }
        if (dashboardGroup === null) {
            validationErrors.push('Please select dashboard group');
        }
        return validationErrors;
    }

    function handleDismiss(error) {
        onDismiss(error);
    }

    function filterCrossLinksProperties(dashboardId) {
        const allowedProperties = ['propertyName', 'targets', 'propertyValue', 'aliases'];

        return crossLinks.map((crossLink) =>
            allowedProperties.reduce(
                (filteredCrossLink, allowedProps) => ({
                    ...filteredCrossLink,
                    contextId: dashboardId,
                    [allowedProps]: crossLink[allowedProps],
                }),
                {}
            )
        );
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validationErrors.length) return;
        setSaveInProgress(true);
        aclDashboardService
            .create(
                { ...dashboardModel, name, description },
                permissions,
                chartsList,
                dashboardGroup.id
            )
            .then((dashboardId) => {
                if (isDatalinksClonned) {
                    aclDashboardService
                        .createMultipleCrossLinks(filterCrossLinksProperties(dashboardId))
                        .then(onClose({ dashboardId, dashboardGroupId: dashboardGroup.id }))
                        .catch((error) => {
                            onClose({ dashboardId, dashboardGroupId: dashboardGroup.id });
                            handleError(error, 'Failed to clone datalinks');
                        });
                } else {
                    onClose({ dashboardId, dashboardGroupId: dashboardGroup.id });
                }
            })
            .catch((error) => handleError(error, 'Failed to create dashboard'));
    }

    function handleError(error, message) {
        window.alert(message);
        handleDismiss(error);
    }
}

CreateAclDashboard.propTypes = {
    userData: PropTypes.object.isRequired,
    themeKey: PropTypes.string.isRequired,
    dashboardModel: PropTypes.object.isRequired,
    defaultDashboardGroupId: PropTypes.string,
    chartsList: PropTypes.array,
    onDismiss: PropTypes.func,
    onClose: PropTypes.func,
    isDecorativeIcon: PropTypes.bool,
};

CreateAclDashboard.defaultProps = {
    onDismiss: () => {},
    onClose: () => {},
};
