import { InstrumentedServiceClient, UserPreference } from '@splunk/olly-services';
import { CurrentUserServiceMethods } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStore';
import {
    ShareableSnapshotService,
    SnapShot,
} from '../../common/data/apiv2/shareableSnapshotService';
import { addSnapshotToList, removeSnapshotsFromList } from '../recentpages/recentPages';

export const isWorkspace = (snapshot?: SnapShot): boolean => {
    // if snapshot is there with the id, it's actually a snapshot
    // (a.k.a. workspace)
    return !!snapshot?.id;
};

export class NewDashboardService {
    public static $inject = ['currentUser', 'httpClient'];

    /* Singleton values */
    private static hasNewCharts = false;
    private static numUnseenCharts = 0;

    private currentUser: CurrentUserServiceMethods;
    private shareableSnapshotService: ShareableSnapshotService;

    constructor(
        currentUser: CurrentUserServiceMethods,
        httpClient: ReturnType<InstrumentedServiceClient>
    ) {
        this.currentUser = currentUser;
        this.shareableSnapshotService = new ShareableSnapshotService(currentUser, httpClient);

        // Get info to show unseen charts badge and count on "new dashboard" icon
        currentUser
            .orgPreferences()
            .then((prefs) => {
                NewDashboardService.numUnseenCharts = prefs.sf_numUnseenCharts || 0;
                const dashboardId = prefs.sf_newDashboard;
                if (dashboardId) {
                    return this.shareableSnapshotService.getIgnoreNotFound(dashboardId);
                }
            })
            .then((snapshot) => {
                if (snapshot && snapshot.payload.charts.length) {
                    NewDashboardService.hasNewCharts = true;
                }
            });
    }

    public get hasNewCharts(): boolean {
        return NewDashboardService.hasNewCharts;
    }

    public get numUnseenCharts(): number {
        return NewDashboardService.numUnseenCharts;
    }

    /**
     * Update user preference with new info about new dashboard and the service with
     * any other new data
     */
    public updateNewDashboardInfo(
        userPreferenceUpdates: Partial<UserPreference> = {},
        serviceDataUpdate?: { hasNewCharts: boolean }
    ): Promise<UserPreference> {
        return this.currentUser.orgPreferences().then((prefs) => {
            const update: Partial<UserPreference> = {
                ...userPreferenceUpdates,
                sf_id: prefs.sf_id,
            };

            if ('sf_numUnseenCharts' in userPreferenceUpdates) {
                NewDashboardService.numUnseenCharts = update.sf_numUnseenCharts || 0;
            }

            if (serviceDataUpdate && 'hasNewCharts' in serviceDataUpdate) {
                NewDashboardService.hasNewCharts = serviceDataUpdate.hasNewCharts;
                if (!NewDashboardService.hasNewCharts) {
                    update.sf_newDashboard = '';
                    update.sf_recentlyViewedDashboards = removeSnapshotsFromList(
                        prefs.sf_recentlyViewedDashboards
                    );
                }
            }

            if (userPreferenceUpdates.sf_newDashboard && NewDashboardService.hasNewCharts) {
                const snapshot = { snapshotId: userPreferenceUpdates.sf_newDashboard };
                update.sf_recentlyViewedDashboards = addSnapshotToList(
                    snapshot,
                    prefs.sf_recentlyViewedDashboards
                );
            }
            return this.currentUser.updateOrgPreferences(update);
        });
    }

    public isWorkspace = isWorkspace;
}
