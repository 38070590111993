import metricsCatalogResults from './metricsCatalogResults.js';
import facet from './facet.js';
import metricFilters from './metricFilters.js';
import filterIcons from './filterIcons.js';
import termsMatchService from './termsMatchService.js';
import customCategoryEditorController from './customCategoryEditorController';
import metricDescriptionController from './metricDescriptionController';
import metricsBrowse from './metricsBrowse.js';
import metricsBrowseService from './metricsBrowseService.js';
import { METRICS_CATALOG_CONSTANTS } from './metricsCatalogConstants.js';
import metricsCatalogSearchUtil from './metricsCatalogSearchUtil';
import metricsSearchBox from './metricsSearchBox.js';
import filterSelectModal from './filterSelectModal/filterSelectModal';
import metricProperties from './metricProperties.js';
import { metricsPage } from './metricsPage';

const METRIC_CATALOG_EVENTS = {
    REFRESH_FACETS: 'metric.catalog.refreshFacets',
    EMPTY_METRIC_RESULTS: 'metric.catalog.noMetricResults',
    FACETS_REFRESH_START: 'metric.catalog.startFacetsRefresh',
};

angular
    .module('signalview.metricsCatalog', [])
    .constant('METRICS_CATALOG_CONSTANTS', METRICS_CATALOG_CONSTANTS)
    .controller('customCategoryEditorController', customCategoryEditorController)
    .controller('metricDescriptionController', metricDescriptionController)
    .component('metricsCatalogResults', metricsCatalogResults)
    .component('facet', facet)
    .component('metricFilters', metricFilters)
    .component('filterIcons', filterIcons)
    .constant('METRIC_CATALOG_EVENTS', METRIC_CATALOG_EVENTS)
    .service('termsMatchService', termsMatchService)
    .component('metricsBrowse', metricsBrowse)
    .service('metricsBrowseService', metricsBrowseService)
    .service('filterSelectModal', filterSelectModal)
    .service('metricsCatalogSearchUtil', metricsCatalogSearchUtil)
    .directive('metricsSearchBox', metricsSearchBox)
    .component('metricProperties', metricProperties)
    .component('metricsPage', metricsPage);
