'use strict';
import templateUrl from './fancyMenuSuggestions.html';

/**
 * The pop-over part of a fancy-menu which is the list and the optional details
 * pane.
 * ```
 * <fancy-menu>
 *   ...
 *   <fancy-menu-suggestions
 *     text-highlight="partialText"
 *     hide-details="false"
 *   ></fancy-menu-suggestions>
 *   ...
 * </fancy-menu>
 * ```
 * @param {string} [text-highlight] - Highlights the matching text in each
 * result in the list
 * @param {boolean} [hide-details=false] - Controls whether the
 * details pane will show.
 * @param {Element} transclusion - Contents to show in the details pane
 */
angular.module('sfx.ui').directive('fancyMenuSuggestions', [
    function () {
        return {
            restrict: 'E',
            transclude: true,
            require: '^^fancyMenu',
            templateUrl,
            scope: {
                textHighlight: '=',
                hideDetails: '=',
                limitSize: '=',
                disableHighlight: '@',
                errorText: '=',
            },
            link: function ($scope, element, attrs, menu) {
                $scope.menu = menu;
            },
        };
    },
]);
