angular.module('signalview.chartbuilder').controller('SectionsCtrl', [
    '$scope',
    function ($scope) {
        let previousTab;

        function setSelectedTab(tab) {
            previousTab = $scope.selectedTab;
            $scope.selectedTab = tab;
            if (!previousTab || tab.id !== previousTab.id) {
                $scope.$emit('tab selected', $scope.selectedTab.id);
            } else {
                $scope.$emit('clicked active tab');
            }
        }

        setSelectedTab($scope.toSelectTab || $scope.sectionTabs[0]);

        $scope.selectTab = function (tab) {
            if (!tab.disabled) {
                setSelectedTab(tab);
            }
        };

        function goToTab(id) {
            $scope.sectionTabs.some(function (tab) {
                if (tab.id === id && !tab.disabled) {
                    setSelectedTab(tab);
                    return true;
                }
                return false;
            });
        }

        $scope.$on('go to tab', function (ev, id) {
            goToTab(id);
        });

        function goToPreviousTab() {
            if (!previousTab) {
                return;
            }
            const newTab = previousTab;
            previousTab = $scope.selectedTab;
            goToTab(newTab.id);
        }

        $scope.$on('go to previous tab', goToPreviousTab);

        // Move off current tab if it is disabled, to the given one
        $scope.$on('leave disabled tab', function (ev, id) {
            if ($scope.selectedTab && $scope.selectedTab.disabled) {
                goToTab(id);
            }
        });
    },
]);
