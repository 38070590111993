import templateUrl from './listView.tpl.html';
import { condenseIds } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.heatmap').directive('listView', [
    '$log',
    'metricsDataService',
    'mustache',
    '_',
    'GROUPED_TABLE_EVENTS',
    'heatmapUtilsService',
    function ($log, metricsDataService, mustache, _, GROUPED_TABLE_EVENTS, heatmapUtilsService) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.hosts = {};
                    $scope.loading = true;
                    $scope.mode = {};

                    const heatmap = $scope.heatmap;

                    const ID_DELIMITER = ' | ';

                    function syncMode() {
                        $scope.mode = heatmap.mode();

                        if ($scope.mode.idProperties && $scope.mode.idProperties.length) {
                            metricsDataService.setPrimaryIdProperty($scope.mode.idProperties[0].id);
                        }

                        $scope.columnTypeByMetric = {};
                        const columns = $scope.mode.listColumns;
                        if (!columns) {
                            return;
                        }
                        Object.keys(columns).forEach(function (id) {
                            const column = columns[id];
                            if (column.metric) {
                                $scope.columnTypeByMetric[column.metric] = column.aggregate;
                            }
                        });
                    }

                    const eventBindings = [];
                    eventBindings.push(heatmap.on('mode updated', syncMode));
                    syncMode();

                    $scope.hasFilters = heatmap.filterBy().length > 0;

                    const idFunction = function (metadata) {
                        return mustache.render(heatmap.getId(metadata));
                    };

                    const dataService = metricsDataService.create(idFunction);

                    dataService.on('data', function ($event, data) {
                        $scope.hosts = {};
                        const aggregations = {};

                        const ids = Object.keys(data);
                        const condensedIds = condenseIds(ids, ID_DELIMITER);

                        ids.forEach(function (id) {
                            const hostId = condensedIds[id];
                            const values = data[id];
                            const host = {
                                id: hostId,
                                value: values,
                            };
                            angular.extend(host, metricsDataService.getMetadataById(id));
                            $scope.hosts[hostId] = host;

                            // Keep track of values for each metric type, for aggregation
                            Object.keys(values).forEach(function (metric) {
                                if (!$scope.columnTypeByMetric[metric]) {
                                    return;
                                }
                                const value = values[metric];
                                if (typeof value !== 'number') {
                                    return;
                                }
                                if (!aggregations[metric]) {
                                    aggregations[metric] = [value];
                                } else {
                                    aggregations[metric].push(value);
                                }
                            });
                        });

                        // Aggregate metric values for each column
                        $scope.mode.listColumns.forEach(function (column) {
                            if (!column.metric) {
                                return;
                            }
                            if (!aggregations[column.metric]) {
                                column.aggregationValue = null;
                                return;
                            }

                            // Default to sum
                            column.aggregationValue = aggregations[column.metric].reduce(
                                (sum, value) => {
                                    return sum + value;
                                }
                            );
                            if ($scope.columnTypeByMetric[column.metric] === 'mean') {
                                const numValues = aggregations[column.metric].length;
                                column.aggregationValue /= numValues;
                            }
                        });

                        $log.debug('Heatmap data updated');
                        heatmap.hasDataValues(true);
                        heatmap.update(_.values($scope.hosts));

                        $scope.$broadcast('new hosts data');
                        $scope.loading = false;
                        $scope.$emit('loadComplete');
                    });

                    dataService.on('stream start', function () {
                        $scope.loading = true;
                    });

                    const reset = function () {
                        $log.debug('Reset signal received');
                        $scope.loading = false;
                        heatmap.hasDataValues(false);
                        reloadData();
                    };

                    dataService.on('reset', reset);

                    const reloadData = function () {
                        $log.info('reloading list data');
                        const filterBy = heatmap.filterBy() || [];

                        const colorBys = heatmap.mode().metrics;

                        let timeRange = '';
                        if (heatmap.timeRange()) {
                            timeRange = heatmap.timeRange().slice(1); // Remove '-' prefix
                        }

                        const program = heatmapUtilsService.colorByToSignalFlow(
                            colorBys,
                            filterBy,
                            timeRange
                        );

                        dataService.resolution(colorBys[0].job.resolution);
                        dataService.program(program);

                        dataService.start();
                    };

                    eventBindings.push(heatmap.on('reset', reset));
                    eventBindings.push(heatmap.on('filterBy updated', reloadData));
                    eventBindings.push(heatmap.on('timeRange updated', reloadData));
                    eventBindings.push(heatmap.on('mode updated', reloadData));

                    $scope.$on(GROUPED_TABLE_EVENTS.DATA_SORTED, function (evt, hosts) {
                        const groupBy = heatmap.groupBy();
                        if (
                            heatmap.mode().alwaysGroupBy &&
                            groupBy.indexOf(heatmap.mode().alwaysGroupBy) === -1
                        ) {
                            groupBy.push(heatmap.mode().alwaysGroupBy);
                        }
                        const groupByLength = groupBy.length;
                        if (!groupByLength) {
                            return;
                        }

                        // Metric values for first and second level groups
                        const firstValues = {};
                        const secondValues = {};

                        // Properties for first and second level groups
                        const firstProperty = groupBy[0];
                        const secondProperty = groupByLength > 1 ? groupBy[1] : null;

                        hosts.forEach(function (id) {
                            const host = $scope.hosts[id];
                            const hostFirstProperty = host[firstProperty];
                            const hostSecondProperty = secondProperty ? host[secondProperty] : null;

                            // Initialization
                            if (!firstValues[hostFirstProperty]) {
                                firstValues[hostFirstProperty] = {};
                            }
                            if (secondProperty) {
                                if (!secondValues[hostFirstProperty]) {
                                    secondValues[hostFirstProperty] = {};
                                }
                                if (!secondValues[hostFirstProperty][hostSecondProperty]) {
                                    secondValues[hostFirstProperty][hostSecondProperty] = {};
                                }
                            }

                            // Get metric values for each group
                            Object.keys(host.value).forEach(function (metric) {
                                const value = host.value[metric];
                                if (typeof value !== 'number') {
                                    return;
                                }
                                if (!firstValues[hostFirstProperty][metric]) {
                                    firstValues[hostFirstProperty][metric] = [value];
                                } else {
                                    firstValues[hostFirstProperty][metric].push(value);
                                }
                                if (secondProperty) {
                                    if (!secondValues[hostFirstProperty][hostSecondProperty]) {
                                        secondValues[hostFirstProperty][hostSecondProperty] = {};
                                    }
                                    if (
                                        !secondValues[hostFirstProperty][hostSecondProperty][metric]
                                    ) {
                                        secondValues[hostFirstProperty][hostSecondProperty][
                                            metric
                                        ] = [value];
                                    } else {
                                        secondValues[hostFirstProperty][hostSecondProperty][
                                            metric
                                        ].push(value);
                                    }
                                }
                            });
                        });

                        // Aggregated values for first and second level group metrics
                        const firstGroups = {};
                        const secondGroups = {};

                        // Aggregate metric values for each column in first level groups
                        Object.keys(firstValues).forEach(function (group) {
                            $scope.mode.listColumns.forEach(function (column) {
                                if (!column.metric) {
                                    return;
                                }

                                if (!firstGroups[group]) {
                                    firstGroups[group] = {};
                                }
                                if (!firstValues[group][column.metric]) {
                                    firstGroups[group][column.metric] = null;
                                    return;
                                }

                                // Default to sum
                                firstGroups[group][column.metric] = firstValues[group][
                                    column.metric
                                ].reduce((sum, value) => {
                                    return sum + value;
                                });
                                if ($scope.columnTypeByMetric[column.metric] === 'mean') {
                                    const numValues = firstValues[group][column.metric].length;
                                    firstGroups[group][column.metric] /= numValues;
                                }
                            });
                        });

                        // Aggregate metric values for each column in second level groups
                        Object.keys(secondValues).forEach(function (firstGroup) {
                            Object.keys(secondValues[firstGroup]).forEach(function (group) {
                                $scope.mode.listColumns.forEach(function (column) {
                                    if (!column.metric) {
                                        return;
                                    }

                                    if (!secondGroups[firstGroup]) {
                                        secondGroups[firstGroup] = {};
                                    }
                                    if (!secondGroups[firstGroup][group]) {
                                        secondGroups[firstGroup][group] = {};
                                    }
                                    if (!secondValues[firstGroup][group][column.metric]) {
                                        secondGroups[firstGroup][group][column.metric] = null;
                                        return;
                                    }

                                    // Default to sum
                                    secondGroups[firstGroup][group][column.metric] = secondValues[
                                        firstGroup
                                    ][group][column.metric].reduce((sum, value) => {
                                        return sum + value;
                                    });
                                    if ($scope.columnTypeByMetric[column.metric] === 'mean') {
                                        const numValues =
                                            secondValues[firstGroup][group][column.metric].length;
                                        secondGroups[firstGroup][group][column.metric] /= numValues;
                                    }
                                });
                            });
                        });

                        // Set aggregations by group level in the heatmap
                        heatmap.firstLevelGroups(firstGroups);
                        heatmap.secondLevelGroups(secondGroups);
                    });

                    $scope.$on('$destroy', function () {
                        eventBindings.forEach(function (unbind) {
                            unbind();
                        });
                        dataService.stop();
                    });

                    reloadData();
                },
            ],
        };
    },
]);
