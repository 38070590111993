// it is assumed here that there will never be more than 10k results
// returned by a query for services or endpoints
const REASONABLE_INFINITY = 10000;

export const ENDPOINT_WILDCARD = '*';

serviceEndpointSelectorDataSourceFactory.$inject = [
    'traceAPIService',
    'suggestAPIService',
    'DETECTOR_TYPES',
];
export default function serviceEndpointSelectorDataSourceFactory(
    traceAPIService,
    suggestAPIService,
    DETECTOR_TYPES
) {
    const { APM, APM_V2 } = DETECTOR_TYPES;

    return {
        fetchServices,
        fetchOperations,
    };

    function fetchServices(selectedDetectorType = APM, hideInferred, environment) {
        if (selectedDetectorType === APM) {
            return traceAPIService
                .getServices(environment, hideInferred)
                .then((response) => Object.entries(response))
                .then((kvPairs) => filterInferred(!hideInferred, kvPairs))
                .then((kvPairs) => kvPairs.map(([key]) => key));
        } else if (selectedDetectorType === APM_V2) {
            const query = buildQueryObject('sf_service', buildFilters(environment));
            return suggestAPIService.getSignalFlowSuggest(query);
        }

        throw new Error('Invalid APM type.');
    }

    function fetchOperations(selectedDetectorType = APM, environment, service) {
        if (selectedDetectorType === APM) {
            return traceAPIService
                .getOperations(environment, service)
                .then((response) => [
                    ENDPOINT_WILDCARD,
                    ...response.endpoints,
                    ...response.operations,
                ]);
        } else if (selectedDetectorType === APM_V2) {
            const query = buildQueryObject('sf_operation', buildFilters(environment, service));
            return suggestAPIService
                .getSignalFlowSuggest(query)
                .then((response) => [ENDPOINT_WILDCARD, ...response]);
        }

        throw new Error('Invalid APM type.');
    }

    function buildQueryObject(property, filters) {
        return {
            property,
            limit: REASONABLE_INFINITY,
            additionalFilters: filters,
        };
    }

    function buildFilters(environment, service) {
        const filters = [
            { property: 'sf_kind', values: ['SERVER', 'CONSUMER'] },
            { property: 'sf_metric', values: ['spans.count'] },
        ];

        if (!!environment) {
            filters.push({ property: 'sf_environment', values: [environment] });
        }

        if (!!service) {
            filters.push({ property: 'sf_service', values: [service] });
        }

        return filters;
    }

    function filterInferred(isInferredAllowed, kvPairs) {
        return kvPairs.filter(([, value]) => {
            if (!isInferredAllowed) {
                return !value.inferred;
            }
            return true;
        });
    }
}
