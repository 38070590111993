/*
 * This module attempts to bundle all signalview related JS except vendors into one module file
 * for ease of loading and management.  It does not posses the CSS but could be altered to do.
 */

import './common/entry.js';
import './app/entry.js';

/* Modules exposed as part of signalviewModule */
import * as kubernetes from './legacy/app/kubeNavigator/module';
import * as k8sSidebar from './legacy/app/kubeNavigator/infoSidebar/module';
import { sourceOverrideParsers } from './legacy/common/urlparams/urlOverrides';
import { variableParsers } from './legacy/app/dashboard/variables/dashboardVariableUtils';

export { isAuthenticatedPath } from './common/security/pathValidateService';
export { credentialDisplay } from './legacy/app/orgintegrations/categories/businessProcess/credentialDisplay';
export { googleAuthIntegration } from './legacy/app/orgintegrations/categories/businessProcess/googleAuthIntegration';
export { dashboard } from './legacy/app/dashboard/dashboard';
export { heatmapGraph } from './legacy/app/heatmap/heatmapGraph';
export { groupedHostsList } from './legacy/app/heatmap/hostlist/hostlist';
export { supportService } from '@splunk/olly-services/lib';
export { loginRedirect } from '@splunk/olly-services/lib';

export const uriSerializers = { filter: sourceOverrideParsers, variable: variableParsers };
export { kubernetes, k8sSidebar };
export { GlobalActionsWrapperReact } from './legacy/app/globalNav/module';
export { NgDashboardIndexPage } from './legacy/app/dashboardIndex/_module';

export { reactNgBridge } from './app/reactAngularBridge/MigratedServiceProxies';

export { App as SignalView, SetupSignalViewMigratedServices } from './app/Index';
export { bootstrapAngular, AngularInjector } from './common/AngularUtils';
export { useAccountLimitOverageNotifier } from './app/administrative/subscriptionCategories/useAccountLimitOverageNotifier';
export { createContentService } from './legacy/app/globalNav/module';
