import objectUsage from './objectUsage';
import { catalogHelper } from './catalogHelper';
import objectCrossLink from './objectCrossLink';

angular
    .module('signalview.objectmanager', [
        'signalboost',
        'signalview.businessObjectDefinitions',
        'signalview.analytics',
        'signalview.collectdPlugins',
        'signalview.detector.rule',
        'signalview.sfGlobalNav',
        'sfx.api.v2',
        'signalview.dashboardUtil',
        'sfx.data',
    ])
    .service('catalogHelper', catalogHelper)
    .component('objectUsage', objectUsage)
    .component('objectCrossLink', objectCrossLink);
