import { RESOLUTION_TO_CHART_RANGE, resolutionToChartRange } from './resolutionToChartRangeModule';

angular
    .module('signalview.detector.signal')
    .constant('RESOLUTION_TO_CHART_RANGE', RESOLUTION_TO_CHART_RANGE)
    .service('resolutionToChartRangeService', [
        function () {
            return resolutionToChartRange;
        },
    ]);
