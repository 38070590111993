import templateUrl from './ingestTokens.tpl.html';
import createNamedTokenModal from './createNamedTokenModal.tpl.html';
import rotateTokenModal from './rotateTokenModal.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../../app/routing/ngRoute';

angular
    .module('signalview.organization')

    .component('ingestTokens', {
        bindings: {
            org: '=',
            editable: '=',
        },
        templateUrl,
        controller: [
            '$scope',
            '$timeout',
            'sfxModal',
            '$window',
            '$log',
            'blockingLoadService',
            'currentUser',
            'ES_INDEXING_DELAY',
            'ingestTokenDataService',
            'orgSubscriptionService',
            'signalStream',
            'namedTokenService',
            'roleTokenService',
            'featureEnabled',
            'subscriptionTypeUtil',
            '_',
            'AUTH_SCOPES',
            'routeParameterService',
            'urlOverridesService',
            '$q',
            'themeService',
            'hasCapability',
            function (
                $scope,
                $timeout,
                sfxModal,
                $window,
                $log,
                blockingLoadService,
                currentUser,
                ES_INDEXING_DELAY,
                ingestTokenDataService,
                orgSubscriptionService,
                signalStream,
                namedTokenService,
                roleTokenService,
                featureEnabled,
                subscriptionTypeUtil,
                _,
                AUTH_SCOPES,
                routeParameterService,
                urlOverridesService,
                $q,
                themeService,
                hasCapability
            ) {
                const ctrl = this;
                const debouncedApply = _.debounce(() => {
                    $scope.$apply();
                }, 200);
                const DEFAULT_ORDER = ['-exceedingLimits', '-lastUpdated'];
                //css classes for status messaging, defaulted to below limit in css
                const CLOSE_TO_LIMIT = 'above-notification-limit';
                const ABOVE_LIMIT = 'above-token-limit';

                // display helpers
                ctrl.initialized = false;
                ctrl.fetchingTokens = true;
                ctrl.showDisabled = true;
                ctrl.orderBy = DEFAULT_ORDER;
                ctrl.currentDpm = {};
                ctrl.currentCategoryUsage = {};
                ctrl.hasTokenLimits = featureEnabled('tokenLimits');
                ctrl.selectedOption = {};
                ctrl.showBanner = true;

                // streaming helper for dpm and host based on tokenId
                const tsidToTokenId = {};

                //stream helper for dpm
                const latestDatapoints = {};

                // streaming helpers for host based current usage
                const tsidToCategory = {};

                const legacyMetricDelay = featureEnabled('legacyHostContainerNumCustomMetricDelay');

                /* controller-bound functions */
                ctrl.$onInit = $onInit;
                ctrl.$onDestroy = $onDestroy;
                ctrl.createNewToken = createNewToken;
                ctrl.getNextPage = getNextPage;
                ctrl.getPreviousPage = getPreviousPage;
                ctrl.onLimitChange = onLimitChange;
                ctrl.renameToken = renameToken;
                ctrl.rotateToken = rotateToken;
                ctrl.updateOrderBy = updateOrderBy;
                ctrl.updateSearch = updateSearch;
                ctrl.getCurrentUsage = getCurrentUsage;
                ctrl.selectOption = selectOption;
                ctrl.closeBanner = closeBanner;
                ctrl.updateTokenExpiry = updateTokenExpiry;
                ctrl.fetchTokenPager = fetchTokenPager;
                ctrl.clearTokenExpiryParam = clearTokenExpiryParam;

                routeParameterService.registerRouteWatch('tokenExpiry', ctrl.fetchTokenPager);

                /* function definitions */
                function $onInit() {
                    ctrl.nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(
                        ctrl.org.accountSubscriptionType
                    );
                    ctrl.categories = subscriptionTypeUtil.getCategoriesBySubscription(
                        ctrl.org.accountSubscriptionType
                    );
                    ctrl.tokenExpirationDays = [
                        {
                            displayName: 'Expires within 30 days',
                            name: '30',
                        },
                        {
                            displayName: 'Expires within 7 days',
                            name: '7',
                        },
                    ];

                    loadAccountInformation();

                    fetchTokenPager();

                    userHasSeenTokenLimit();

                    checkCapability();
                }

                function $onDestroy() {
                    if (ctrl.streamObj) ctrl.streamObj.stopStream();
                }

                function closeBanner() {
                    ctrl.showBanner = false;
                }

                function updateTokenExpiry() {
                    urlOverridesService.setTokenExpiry(ctrl.tokenExpirationDays[0].name);
                    ctrl.selectedOption = ctrl.tokenExpirationDays[0];
                }

                /**
                 * verifying the UPDATE_NAMEDTOKEN and READ_NAMEDTOKEN capability is present or not
                 * */
                function checkCapability() {
                    const spinner = blockingLoadService();
                    $q.all([
                        hasCapability(Capability.UPDATE_NAMEDTOKEN),
                        hasCapability(Capability.READ_NAMEDTOKEN),
                        hasCapability(Capability.CREATE_NAMEDTOKEN),
                    ]).then(function ([
                        hasUpdateNamedTokenCapability,
                        hasReadNamedTokenCapability,
                        hasCreateNamedTokenCapability,
                    ]) {
                        ctrl.hasUpdateNamedTokenCapability = featureEnabled('newRbacExperience')
                            ? hasUpdateNamedTokenCapability
                            : ctrl.editable;
                        ctrl.hasReadNamedTokenCapability = hasReadNamedTokenCapability;
                        ctrl.hasCreateNamedTokenCapability = featureEnabled('newRbacExperience')
                            ? hasCreateNamedTokenCapability
                            : ctrl.editable;

                        if (!hasReadNamedTokenCapability) {
                            ngRoute?.history?.replace('/forbidden');
                        }

                        if (spinner) {
                            spinner.close();
                        }
                    });
                }

                /**
                 * Pull token expiry from the url, if token expiry exists, fetch specific tokens.
                 * Otherwise fetch all tokens and remove any url params.
                 */
                function fetchTokenPager() {
                    const tokenExpiry = urlOverridesService.getTokenExpiry();
                    if (tokenExpiry === ctrl.tokenExpirationDays[0].name) {
                        ctrl.selectedOption = ctrl.tokenExpirationDays[0];
                    } else if (tokenExpiry === ctrl.tokenExpirationDays[1].name) {
                        ctrl.selectedOption = ctrl.tokenExpirationDays[1];
                    } else {
                        clearTokenExpiryParam();
                    }
                    getFreshTokenPager();
                }

                function clearTokenExpiryParam() {
                    ctrl.selectedOption = {};
                    urlOverridesService.clearTokenExpiry();
                }

                function getAllRoles($scope) {
                    roleTokenService.getAllRoles().then((allRolesData) => {
                        $scope.allRoles = allRolesData.filter(
                            (role) => role.title.toLowerCase() !== 'admin'
                        );
                    });
                }

                function createNewToken() {
                    const tokenNames = ctrl.tokenBundles.map(function (bundle) {
                        return bundle.token.name;
                    });
                    let spinner;

                    newTokenNameModal(tokenNames)
                        .result.then(function (data) {
                            const name = data.name;
                            spinner = blockingLoadService();
                            if (!name) {
                                return;
                            }

                            const authScopes = Object.keys(data.authScopes).filter(
                                (scope) => data.authScopes[scope]
                            );
                            const permissions = data.permissions;
                            return namedTokenService
                                .create(name, authScopes, permissions, data.selectedRoleIdsAndTitle)
                                .then(function () {
                                    $timeout(() => {
                                        ctrl.orderBy = DEFAULT_ORDER;
                                        ctrl.searchInput = '';
                                        clearTokenExpiryParam();
                                        getFreshTokenPager();
                                    }, ES_INDEXING_DELAY);
                                })
                                .catch(function (error) {
                                    $log.error('Error creating new token ', error);
                                    $window.alert('Error creating new token');
                                });
                        })
                        .finally(function () {
                            if (spinner) {
                                spinner.close();
                            }
                        });
                }

                function getNextPage() {
                    ctrl.fetchingTokens = true;
                    ctrl.tokenBundles = [];

                    ctrl.pager.next().then((pager) => {
                        ctrl.pager = pager;
                        ctrl.tokenBundles = pager.page;
                        ctrl.fetchingTokens = false;
                        $scope.$digest();
                    });
                }

                function getPreviousPage() {
                    ctrl.pager = ctrl.pager.previous();
                    ctrl.tokenBundles = ctrl.pager.page;
                }

                function selectOption(option) {
                    ctrl.selectedOption = option;
                    urlOverridesService.setTokenExpiry(option.name);
                }

                function onLimitChange(token) {
                    const tokenName = token.name;
                    const bundle = ctrl.tokenBundles.find(function (bundle) {
                        return bundle.token.name === tokenName;
                    });
                    if (bundle) {
                        bundle.token = token;
                    }

                    //loop through all categories, remove current status and update with new status information if available
                    ctrl.categories.forEach((category) => {
                        const categoryValue = category.value;
                        removeCurrentStatusForCategory(tokenName, categoryValue);

                        const currentCategoryValue = safeLookup(
                            ctrl.currentCategoryUsage,
                            tokenName + '.categoryValues.' + categoryValue + '.value'
                        );
                        if (currentCategoryValue) {
                            const newCategoryStatus = getCurrentCategoryStatus(
                                token,
                                categoryValue,
                                currentCategoryValue
                            );
                            updateCurrentStatusForCategory(
                                tokenName,
                                categoryValue,
                                newCategoryStatus
                            );
                        }
                    });
                }

                function newTokenNameModal(
                    names,
                    oldName,
                    oldPermissions,
                    oldAuthScopes,
                    oldRoles
                ) {
                    return sfxModal.open({
                        windowClass: '',
                        size: 'lg',
                        templateUrl: createNamedTokenModal,
                        controller: [
                            '$scope',
                            function ($scope) {
                                $scope.names = names;
                                $scope.oldTokenName = oldName || '';
                                $scope.orgHasRum = ctrl.org.features.includes('rum');
                                $scope.accessControlEnabled = featureEnabled('accessControl');
                                $scope.newRbacExperienceEnabled =
                                    featureEnabled('newRbacExperience');
                                $scope.AUTH_SCOPES = AUTH_SCOPES;
                                $scope.oldAuthScopes = oldAuthScopes;
                                $scope.oldRoles = oldRoles;
                                const shouldFetchAllRoles =
                                    $scope.newRbacExperienceEnabled &&
                                    (!oldName || oldAuthScopes?.includes(AUTH_SCOPES.API));
                                shouldFetchAllRoles && getAllRoles($scope);

                                $scope.checkedRum = () => {
                                    $scope.data.authScopes = {
                                        ...$scope.data.authScopes,
                                        [AUTH_SCOPES.INGEST]: false,
                                        [AUTH_SCOPES.API]: false,
                                    };
                                };
                                $scope.data = {
                                    nickName: oldName || '',
                                    authScopes: {
                                        [AUTH_SCOPES.RUM]: false,
                                        [AUTH_SCOPES.INGEST]: false,
                                        [AUTH_SCOPES.API]: false,
                                    },
                                };
                                // react props
                                $scope.oldPermissions = oldPermissions;
                                $scope.themeKey = themeService.getColorScheme();
                                $scope.userData = null;
                                $q.all({
                                    user: currentUser.getOrgMember(),
                                    orgId: currentUser.orgId(),
                                }).then(({ user, orgId }) => {
                                    $scope.userData = {
                                        userId: user.id,
                                        isAdmin: user.admin,
                                        orgId,
                                    };
                                    $scope.$applyAsync();
                                });

                                $scope.validateName = function (newName) {
                                    const matchesOldName = oldName && newName === oldName;
                                    $scope.duplicateName =
                                        !matchesOldName && $scope.names.indexOf(newName) > -1;
                                    $scope.nameContainsZeroWidth = /[\u200B-\u200D\uFEFF]/.test(
                                        newName
                                    );
                                    $scope.isValid =
                                        newName &&
                                        !matchesOldName &&
                                        !$scope.duplicateName &&
                                        !$scope.nameContainsZeroWidth;
                                };
                                $scope.onConfirmNewToken = (
                                    nickName,
                                    authScopes,
                                    permissions,
                                    selectedRoleIdsAndTitle
                                ) =>
                                    $scope.$close({
                                        name: nickName,
                                        authScopes,
                                        permissions,
                                        selectedRoleIdsAndTitle,
                                    });

                                $scope.validateName();
                            },
                        ],
                        backdrop: 'static',
                        keyboard: false,
                    });
                }

                function rotateToken(token) {
                    const modalInstance = sfxModal.open({
                        windowClass: 'rotate-token-modal',
                        size: 'md',
                        templateUrl: rotateTokenModal,
                        controller: [
                            '$scope',
                            function ($scope) {
                                $scope.themeKey = themeService.getColorScheme();
                                $scope.token = token;
                                $scope.onSubmit = (gracefulExpiry) => {
                                    $scope.$close(gracefulExpiry);
                                };
                            },
                        ],
                        backdrop: 'static',
                        keyboard: false,
                    });

                    modalInstance.result.then((gracefulExpiry) => {
                        const spinner = blockingLoadService();
                        namedTokenService
                            .rotate(token.name, gracefulExpiry)
                            .then((newToken) => {
                                if (spinner) {
                                    spinner.close();
                                }

                                const idx = ctrl.tokenBundles.findIndex(function (bundle) {
                                    return bundle.token.name === token.name;
                                });
                                const bundle = ctrl.tokenBundles[idx];
                                bundle.token = newToken;
                            })
                            .catch(() => {
                                if (spinner) {
                                    spinner.close();
                                }
                                $window.alert('Error rotating token');
                            });
                    });
                }

                function renameToken(token) {
                    const oldName = token.name;
                    const names = ctrl.tokenBundles.map(function (bundle) {
                        return bundle.token.name;
                    });
                    const oldPermissions = token.permissions;
                    const oldAuthScopes = token.authScopes;
                    const oldRoles = token.roles?.map((role) => role.title);
                    const modalInstance = newTokenNameModal(
                        names,
                        oldName,
                        oldPermissions,
                        oldAuthScopes,
                        oldRoles
                    );
                    let spinner;

                    modalInstance.result
                        .then(function (data) {
                            const newName = data.name;
                            spinner = blockingLoadService();
                            const idx = ctrl.tokenBundles.findIndex(function (bundle) {
                                return bundle.token.name === oldName;
                            });
                            const bundle = ctrl.tokenBundles[idx];
                            const permissions = data.permissions;
                            return namedTokenService
                                .rename(oldName, newName, permissions, data.selectedRoleIdsAndTitle)
                                .then(function (newToken) {
                                    bundle.token = newToken;
                                })
                                .catch(function () {
                                    $window.alert('Error renaming token');
                                });
                        })
                        .finally(function () {
                            if (spinner) {
                                spinner.close();
                            }
                        });
                }

                let currentProperty;
                let ascending;

                function updateOrderBy(property) {
                    ascending = property === currentProperty && !ascending;
                    currentProperty = property;

                    ctrl.fetchingTokens = true;
                    ctrl.orderBy = (ascending ? '-' : '') + property.split('.')[1];

                    ingestTokenDataService
                        .getPaginatedTokens(ctrl.org.id, ctrl.orderBy)
                        .then((pager) => {
                            ctrl.fetchingTokens = false;
                            ctrl.pager = pager;
                            ctrl.tokenBundles = pager.page;
                        })
                        .catch(() => {
                            ctrl.fetchingTokens = false;
                            $window.alert('Failed to retrieve tokens');
                        });
                }

                let searchGeneration = 0;
                function updateSearch() {
                    ctrl.fetchingTokens = true;
                    ctrl.tokenBundles = [];
                    ctrl.pager = null;
                    const localGeneration = ++searchGeneration;
                    const expiryLimit = ctrl.selectedOption.name ? ctrl.selectedOption.name : '';

                    ingestTokenDataService
                        .getPaginatedTokens(
                            ctrl.org.id,
                            ctrl.orderBy,
                            ctrl.searchInput,
                            expiryLimit
                        )
                        .then((pager) => {
                            // if this response is not the freshest, then discard it.
                            if (localGeneration < searchGeneration) {
                                return;
                            }

                            ctrl.fetchingTokens = false;
                            ctrl.pager = pager;
                            ctrl.tokenBundles = pager.page;
                        });
                }

                function getCurrentUsage(bundle) {
                    const tokenKey = bundle.token.id;
                    if (!_.isEmpty(ctrl.currentDpm)) {
                        return ctrl.currentDpm[tokenKey];
                    }
                    if (!_.isEmpty(ctrl.currentCategoryUsage)) {
                        return ctrl.currentCategoryUsage[tokenKey];
                    }
                }

                /* non-bindable functions */

                function dataDPMCallback(datapoint) {
                    if (
                        !latestDatapoints[datapoint.tsid] ||
                        latestDatapoints[datapoint.tsid].timestamp < datapoint.timestamp
                    ) {
                        latestDatapoints[datapoint.tsid] = datapoint;
                        const dpmKey = tsidToTokenId[datapoint.tsid];
                        ctrl.currentDpm[dpmKey] = datapoint.value;
                    }

                    debouncedApply();
                }

                function hostUsageDataCallback(data) {
                    const categoryValue = tsidToCategory[data.tsid];
                    const latest = {};
                    const timestamp = data.timestamp;
                    const value = data.value;

                    if ((value !== null && !latest.timestamp) || timestamp > latest.timestamp) {
                        latest.timestamp = timestamp;
                        latest.category = categoryValue;

                        const tokenKey = tsidToTokenId[data.tsid];

                        if (!ctrl.currentCategoryUsage[tokenKey]) {
                            ctrl.currentCategoryUsage[tokenKey] = {
                                categoryValues: {},
                                allStatus: [],
                                status: '',
                            };
                            ctrl.categories.forEach((category) => {
                                ctrl.currentCategoryUsage[tokenKey].categoryValues[category.value] =
                                    {};
                            });
                        }

                        ctrl.currentCategoryUsage[tokenKey].categoryValues[categoryValue].value =
                            value;
                        ctrl.currentCategoryUsage[tokenKey].timestamp = timestamp;

                        setCurrentStatusForToken(tokenKey, categoryValue, value);
                    }

                    debouncedApply();
                }

                //sets css classes containing messaging and coloring for status
                //below limit messaging set on template reset to empty
                function setCurrentStatusForToken(tokenKey, categoryValue, value) {
                    if (!ctrl.hasTokenLimits) {
                        // no need to set coloring based on token limits for orgs without token limits
                        return;
                    }
                    const token = ctrl.tokenIdToTokenMap[tokenKey];
                    if (!token || !token.limits) {
                        $log.error(
                            'Unable to find token: ' +
                                tokenKey +
                                ' in token map, skipping status evaluation'
                        );
                        return;
                    }
                    removeCurrentStatusForCategory(tokenKey, categoryValue);
                    //evaluate and add new status
                    const newCategoryStatus = getCurrentCategoryStatus(token, categoryValue, value);
                    updateCurrentStatusForCategory(tokenKey, categoryValue, newCategoryStatus);
                }

                function removeCurrentStatusForCategory(tokenKey, categoryValue) {
                    const currentStatus = safeLookup(
                        ctrl.currentCategoryUsage,
                        tokenKey + '.categoryValues.' + categoryValue + '.status'
                    );
                    //remove current status from the all status array, to reset
                    if (currentStatus) {
                        const index = ctrl.currentCategoryUsage[tokenKey].allStatus.findIndex(
                            (status) => {
                                return status === currentStatus;
                            }
                        );
                        if (index !== -1) {
                            ctrl.currentCategoryUsage[tokenKey].allStatus.splice(index, 1);
                        } else {
                            $log.error(
                                'Unable to find current status: ' +
                                    currentStatus +
                                    ' in status array for token: ' +
                                    tokenKey
                            );
                        }
                    }
                }

                function updateCurrentStatusForCategory(
                    tokenName,
                    categoryValue,
                    newCategoryStatus
                ) {
                    ctrl.currentCategoryUsage[tokenName].categoryValues[categoryValue].status =
                        newCategoryStatus;
                    if (!!newCategoryStatus) {
                        ctrl.currentCategoryUsage[tokenName].allStatus.push(newCategoryStatus);
                    }
                    //find status representing all categories
                    ctrl.currentCategoryUsage[tokenName].status = getTopStatusForToken(tokenName);
                }

                function getTopStatusForToken(tokenName) {
                    if (
                        ctrl.currentCategoryUsage[tokenName].allStatus.some((status) => {
                            return status === ABOVE_LIMIT;
                        })
                    ) {
                        return ABOVE_LIMIT;
                    } else if (
                        ctrl.currentCategoryUsage[tokenName].allStatus.some((status) => {
                            return status === CLOSE_TO_LIMIT;
                        })
                    ) {
                        return CLOSE_TO_LIMIT;
                    }
                    return '';
                }

                function getCurrentCategoryStatus(token, categoryValue, value) {
                    const limitThreshold = token.limits.categoryQuota;
                    const notificationThreshold = token.limits.categoryNotificationThreshold;
                    if (
                        token.exceedingLimits ||
                        (limitThreshold && limitThreshold[categoryValue] < value)
                    ) {
                        return ABOVE_LIMIT;
                    } else if (
                        notificationThreshold &&
                        notificationThreshold[categoryValue] <= value
                    ) {
                        return CLOSE_TO_LIMIT;
                    }
                    return '';
                }

                function getFreshTokenPager() {
                    ctrl.fetchingTokens = true;
                    ctrl.tokenBundles = {};
                    // fetch relevant object bundles for each token. Each bundle contains
                    // a token and, if they exist, the coinciding creator
                    const expiryLimit = ctrl.selectedOption.name ? ctrl.selectedOption.name : '';
                    const searchTerm = ctrl.searchInput ? ctrl.searchInput : '';
                    return ingestTokenDataService
                        .getPaginatedTokens(ctrl.org.id, ctrl.orderBy, searchTerm, expiryLimit)
                        .then((pager) => {
                            refreshTokens(pager);
                        });
                }

                /**
                 * Refresh token state on the page with the latest result from the api
                 * @param pager
                 */
                function refreshTokens(pager) {
                    ctrl.tokenBundles = pager.page;
                    ctrl.pager = pager;
                    ctrl.fetchingTokens = false;
                    ctrl.initialized = true;
                    ctrl.tokenIdToTokenMap = ctrl.tokenBundles.reduce(function (map, token) {
                        map[token.token.id] = token.token;
                        return map;
                    }, {});
                    streamCurrentUsage();
                }

                function loadAccountInformation() {
                    orgSubscriptionService
                        .get(ctrl.org)
                        .getAccount()
                        .then((account) => {
                            ctrl.account = account;
                            ctrl.quotas = (account || {}).quotas;
                        });
                }

                function streamCurrentUsage() {
                    const timeInMillis = legacyMetricDelay ? 15 * 60 * 1000 : 30 * 60 * 1000;

                    if (!ctrl.nonLegacyOrg) {
                        const signalFlowText =
                            'data("sf.org.numDatapointsReceivedByToken", rollup="sum", filter=filter("orgId", "' +
                            ctrl.org.id +
                            '")).sum(by=["tokenId"]).sum(over="1m").publish()';

                        const jobOpts = {
                            signalFlowText: signalFlowText,
                            resolution: 5000,
                            offsetByMaxDelay: true,
                            callback: dataDPMCallback,
                            metaDataUpdated: metaDataDPMUpdated,
                        };
                        ctrl.streamObj = signalStream.stream(jobOpts);
                    } else {
                        const signalFlowText = hostBasedTokenUsageSignalFlow();
                        const jobOpts = {
                            signalFlowText: signalFlowText,
                            offsetByMaxDelay: true,
                            callback: hostUsageDataCallback,
                            metaDataUpdated: metaDataUpdatedCallback,
                            historyrange: timeInMillis,
                        };

                        ctrl.streamObj = signalStream.stream(jobOpts);
                    }
                }

                function hostBasedTokenUsageSignalFlow() {
                    let signalflowText = '';
                    ctrl.categories.forEach((category) => {
                        const letter = String.fromCharCode(64 + category.value);
                        signalflowText += letter + ' = data("' + category.tokenMetric + '"';
                        if (category.resourceType) {
                            signalflowText +=
                                ', filter=filter("resourceType", "' + category.resourceType + '")';
                        }
                        signalflowText +=
                            ', rollup="latest").sum(by=["tokenId"]).publish(label="' +
                            letter +
                            '")\n';
                    });

                    return signalflowText;
                }

                function metaDataDPMUpdated(data, tsid) {
                    tsidToTokenId[tsid] = data.tokenId;
                }

                function metaDataUpdatedCallback(data, tsid) {
                    const metric = data.sf_originatingMetric;
                    const resourceType = data.resourceType;
                    ctrl.categories.forEach((category) => {
                        //token metric is the same for host and container, check by resource type
                        if (resourceType) {
                            if (
                                metric === category.tokenMetric &&
                                resourceType === category.resourceType
                            ) {
                                tsidToCategory[tsid] = category.value;
                            }
                        } else if (metric === category.tokenMetric) {
                            tsidToCategory[tsid] = category.value;
                        }
                    });

                    tsidToTokenId[tsid] = data.tokenId;
                }

                function userHasSeenTokenLimit() {
                    const userSeenDate = new Date().getTime();
                    currentUser
                        .preferences()
                        .then(function (prefs) {
                            if (prefs) {
                                return currentUser.updatePreferences({
                                    sf_tokenPageLastViewed: userSeenDate,
                                    sf_id: prefs.sf_id,
                                });
                            }
                        })
                        .then(() => {
                            $scope.$emit('refresh hazard icon');
                        });
                }
            },
        ],
    });
