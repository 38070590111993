export default [
    '_',
    '$rootScope',
    function (_, $rootScope) {
        /**
         * @param {*} items[] - A list of items to paginate.
         * @param {Object} pageCount - The number of pages to divide the
         *                             items among.
         * @returns {Object[]} - A list of *pageCount* lists containing
         *                       the items in *items* in the order they
         *                       were received.
         */
        const memoized = _.memoize(
            function (items, pageCount) {
                let page;
                const pages = [];
                const pageSize = Math.ceil(items.length / pageCount) || 1;
                for (let i = 0; i < pageCount; i++) {
                    page = [];
                    for (let j = 0; j < pageSize; j++) {
                        const item = items[i * pageSize + j];
                        if (item) {
                            page.push(item);
                        }
                    }
                    pages.push(page);
                }
                return pages.filter(function (page) {
                    return page.length > 0;
                });
            },
            function (items, pageCount) {
                return (
                    items
                        .map(function (itm) {
                            return itm.id;
                        })
                        .join('') + pageCount
                );
            }
        );

        $rootScope.$on('React:$routeChangeSuccess', function () {
            memoized.cache.clear();
        });

        return memoized;
    },
];
