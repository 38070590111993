import templateUrl from './globalNavHomeButton.tpl.html';

export default {
    templateUrl,
    controller: [
        '$rootScope',
        '$location',
        'currentUser',
        'globalNavService',
        'dashboardUtil',
        function ($rootScope, $location, currentUser, globalNavService, dashboardUtil) {
            const $ctrl = this;

            $ctrl.svc = globalNavService;

            function getCurrentPath() {
                let currentPath = $location.path();
                if ($location.path().match(/dashboard/)) {
                    const currentParams = $location.search();
                    const groupId = currentParams['groupId'];
                    const configId = currentParams['configId'];
                    if (groupId && configId) {
                        const dashboardSearchParams = dashboardUtil.getDashboardSearchParamsString(
                            groupId,
                            configId
                        );
                        currentPath = `${currentPath}?${dashboardSearchParams}`;
                    }
                }
                return currentPath;
            }

            $ctrl.setAsHomePage = function () {
                currentUser.orgPreferences().then((prefs) => {
                    const update = {
                        sf_id: prefs.sf_id,
                        sf_homePagePath: getCurrentPath(),
                    };

                    currentUser.updateOrgPreferences(update).then(() => {
                        $rootScope.$broadcast('initialize home page link');
                    });
                });
            };

            /**
             * We want to check if user's home page is a dashboard mirror
             * and if they are already on it, not to navigate with path update.
             * This is to prevent url params to be cleared which would lead to
             * dashboard thinking it's in a dirty state.
             */
            $ctrl.preventDefaultIfOnHomePageAlready = function (event) {
                const currentPath = '#' + getCurrentPath();
                if (currentPath === $ctrl.svc.homeHref) {
                    event.preventDefault();
                }
            };
        },
    ],
};
