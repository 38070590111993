'use strict';

import urlOverridesService from './urlOverrides.js';
import routeParameterService from './routeParameterService.js';
import URL_PARAMETER_CONSTANTS from './URL_PARAMETER_CONSTANTS.js';

angular
    .module('signalview.routeParameters', [])
    .factory('routeParameterService', routeParameterService);

angular
    .module('signalview.urlOverrides', ['sfx.filterControls', 'sfUtil'])
    .service('urlOverridesService', urlOverridesService)
    .constant('URL_PARAMETER_CONSTANTS', URL_PARAMETER_CONSTANTS);
