import templateUrl from './catalogDynamicView.tpl.html';

angular.module('signalview.objectmanager').directive('catalogDynamicView', [
    function () {
        return {
            restrict: 'E',
            scope: {
                objects: '=',
                selectedType: '=',
                isNewCatalog: '=',
                availablePlugins: '=',
                viewMode: '=',
            },
            templateUrl,
        };
    },
]);
