import isEmail from 'validator/lib/isEmail';

export const catalogQuickActionsDataProvider = [
    'catalogDataService',
    'imageService',
    'pageTypeService',
    'displayName',
    '$http',
    '$q',
    'currentUser',
    'API_URL',
    'featureEnabled',
    'heatmapUtilsService',
    'sortUtil',
    'searchCategoriesUtil',
    'iconUtils',
    function (
        catalogDataService,
        imageService,
        pageTypeService,
        displayName,
        $http,
        $q,
        currentUser,
        API_URL,
        featureEnabled,
        heatmapUtilsService,
        sortUtil,
        searchCategoriesUtil,
        iconUtils
    ) {
        const dashboardTypeValue = 'Dashboard';

        const { getAllowedTypesForMetadataSearch, generateAllowedTypeKeys } = searchCategoriesUtil;

        const metadataSearchTypes = getAllowedTypesForMetadataSearch();
        const allowedTypesForMetadataSearch = generateAllowedTypeKeys(metadataSearchTypes);

        function isDashboardViewsEnabled() {
            return featureEnabled('dashboardViews');
        }

        function isNewGlobalSearchEnabled() {
            return featureEnabled('globalSearch');
        }

        function explicitlyAddPageInSearchTypes(types) {
            if (!types) {
                return false;
            }

            if (Array.isArray(types)) {
                return types.length > 0 && types.indexOf(dashboardTypeValue) > -1;
            } else {
                return types.toLowerCase() === dashboardTypeValue.toLowerCase();
            }
        }

        function addPageSearchTypeForDashboardViews(types) {
            if (isDashboardViewsEnabled() && explicitlyAddPageInSearchTypes(types)) {
                if (!Array.isArray(types)) {
                    types = [types];
                }
                types.unshift('Page');
            }
            return types;
        }

        let canceler = $q.defer();
        function performCatalogSearch(orgId, types, keys, text) {
            canceler.resolve();
            canceler = $q.defer();

            if (angular.equals(keys, 'metric')) {
                keys = 'sf_metric';
            }
            types = addPageSearchTypeForDashboardViews(types);

            const params = {
                active: 1,
                filters: [],
                limit: 20,
                offset: 0,
                organizationId: orgId,
                partialInput: text,
                type: types,
                keys: keys,
            };
            return $http
                .get(API_URL + '/v2/_globalsearch', { params: params, timeout: canceler.promise })
                .then((result) => result.data);
        }

        function performMetadataSearch(orgId, key, value) {
            let types,
                keys = [];
            if (key) {
                if (allowedTypesForMetadataSearch[key]) {
                    types = key;
                } else {
                    keys = key;
                }
            } else {
                types = Object.keys(metadataSearchTypes);
            }
            return performCatalogSearch(orgId, types, keys, value);
        }

        function getImgForHit(hit) {
            let img;
            if (hit.sf_page || hit.sf_dashboard) {
                if (hit.sf_page && isEmail(hit.sf_page)) {
                    //user
                    img = imageService.getProfileImageForEmail(hit.sf_page);
                } else {
                    img = pageTypeService.getIconForServiceName(hit.sf_page);
                }
            } else if (hit.sf_type === 'Navigator') {
                if (hit.navigatorCode) {
                    img = heatmapUtilsService.getModeIcon(hit.navigatorCode);
                }
            } else if (iconUtils.hasIcon(hit.sf_type) || iconUtils.hasIcon(hit.key)) {
                img = iconUtils.getIconURL(hit.sf_type) || iconUtils.getIconURL(hit.key);
            }
            return img;
        }

        function getSecondaryTitle(hit) {
            let secondary = [];
            if (hit.sf_type === 'Chart') {
                secondary.push(hit.sf_dashboard);
                secondary.push(hit.sf_page);
            } else if (hit.sf_type === dashboardTypeValue) {
                secondary.push(hit.sf_page);
            }
            secondary = secondary.filter((s) => s);
            return secondary.join(' - ');
        }

        function isDimension(key) {
            if (!key) {
                return false;
            } else {
                return key.toLowerCase() === 'property' || key.toLowerCase() === 'dimension';
            }
        }

        function formatCatalogSearchHit(orgId, bucket, hit, searchKey) {
            if (hit.sf_type === 'Property' && hit.key && !hit[hit.key]) {
                return getHitForDimensionName(orgId, hit);
            }

            const selected = hit.key
                ? 'selectedKeyValue=' + hit.key + ':' + hit[hit.key]
                : 'select=' + hit.sf_type + ':' + hit.sf_id;
            const link = '#/catalog/' + orgId + '?' + selected;
            let secondary = getSecondaryTitle(hit);
            let type =
                bucket.key === 'sf_metric'
                    ? 'metric'
                    : '' ||
                      catalogDataService.getAliases()[bucket.key] ||
                      bucket.sf_section ||
                      bucket.key ||
                      bucket.type ||
                      displayName.getName(bucket);

            if (isDimension(searchKey)) {
                type = 'Dimension';
                secondary = hit.key;
            }

            return {
                label: displayName.getName(hit),
                secondary: secondary,
                // this is propagated to UI to indicate the type of hit which came from the bucket search result
                type: type,
                href: link,
                img: getImgForHit(hit),
            };
        }

        // Special type of hit that doesn't have a value. Just a suggested
        // Property or Dimension name for the given partial input
        function getHitForDimensionName(orgId, hit) {
            const selected = 'selectedKeyValue=' + hit.key + ':*';
            const link = '#/catalog/' + orgId + '?' + selected;
            return {
                label: hit.key,
                type: hit.sf_type,
                href: link,
                img: getImgForHit(hit),
            };
        }

        function catalogSearch(key, value) {
            return currentUser.orgId().then(function (orgId) {
                return performMetadataSearch(orgId, key, value).then(
                    function (list) {
                        if (!isNewGlobalSearchEnabled()) {
                            sortUtil.reorganizeBuckets(list);
                        }
                        const result = [];
                        list.forEach(function (bucket) {
                            if (bucket.hits) {
                                bucket.hits
                                    .filter(function (hit) {
                                        return hit && allowedTypesForMetadataSearch[hit.sf_type];
                                    })
                                    .forEach(function (hit) {
                                        result.push(
                                            formatCatalogSearchHit(orgId, bucket, hit, key)
                                        );
                                    });
                            }
                        });
                        return result;
                    },
                    function () {
                        return [];
                    }
                );
            });
        }
        return {
            catalogSearch,
        };
    },
];
