export const ORDER_ASCENDING = 'ascending';
// eslint-disable-next-line import/no-unused-modules
export const ORDER_DESCENDING = 'descending';
export default class SortableColumnManager {
    /**
     * Constructor takes an array of sorting options like below
     * [
     *  {
     *    column: 'stopTime',
     *    order: ORDER_ASCENDING
     *  },
     *  {
     *    column: 'startTime',
     *    order: ORDER_DESCENDING
     *  }
     * ]
     **/
    constructor(sortColumns) {
        this.sortColumns = sortColumns;
    }

    // If desired order isn't given as 2nd parameter, it will flip the order
    setColumnOrder(columnName, order) {
        const sort = this.getSortByColumn(columnName);
        if (!sort) {
            return null;
        }

        if (order) {
            sort.order = order;
        } else {
            sort.order = sort.order === ORDER_ASCENDING ? ORDER_DESCENDING : ORDER_ASCENDING;
        }

        const newSortColumns = [sort];
        for (let i = 0; i < this.sortColumns.length; i++) {
            if (this.sortColumns[i].column !== sort.column) {
                newSortColumns.push(this.sortColumns[i]);
            }
        }
        this.sortColumns = newSortColumns;
    }

    reverseColumnOrder(columnName) {
        this.setColumnOrder(columnName);
    }

    getColumnOrder(columnName) {
        const sort = this.getSortByColumn(columnName);
        if (!sort) {
            return null;
        }
        return sort.order;
    }

    getSortArray() {
        return this.sortColumns.map((sort) => {
            if (sort.order === ORDER_ASCENDING) {
                return sort.column;
            } else {
                return '-' + sort.column;
            }
        });
    }

    applySortString(sortString) {
        if (typeof sortString !== 'string') {
            return null;
        }

        sortString.split(',').forEach((sortSubstring) => {
            const isDescending = sortSubstring.indexOf('-') === 0;
            const column = isDescending ? sortSubstring.substring(1) : sortSubstring;
            const order = isDescending ? ORDER_DESCENDING : ORDER_ASCENDING;
            this.setColumnOrder(column, order);
        });
    }

    getSortString() {
        return this.getSortArray().join(',');
    }

    getSortByColumn(columnName) {
        for (let i = 0; i < this.sortColumns.length; i++) {
            if (this.sortColumns[i].column === columnName) {
                if ([ORDER_ASCENDING, ORDER_DESCENDING].indexOf(this.sortColumns[i].order) >= 0) {
                    return this.sortColumns[i];
                } else {
                    return null;
                }
            }
        }
        return null;
    }
}
