import templateUrl from './detectorWizardChart.tpl.html';
import renderThrottleMessageDetectorTemplateUrl from '../../../../charting/chartbuilder/renderThrottleMessageDetector.tpl.html';
import jobFetchCapsMessageTemplateUrl from '../../../../charting/chartbuilder/jobFetchCapsMessage.tpl.html';

/**
 * The chart component is a chart display that for detector's signals.
 * It also requires a shared chart state to populate data table values when needed.
 */
angular.module('signalview.detector.wizard').component('detectorWizardChart', {
    bindings: {
        model: '<',
        sharedChartState: '<',
        chartDisplayDebouncer: '<',
        allowEventPublishes: '<',
        explainIncidentId: '=?',
        isClearEvent: '=?',
        chartConfigOverride: '=?',
        disableLegend: '=?',
        incidentInfo: '=?',
        isAlertModalV2Chart: '=?',
    },
    templateUrl,
    controller: [
        '$scope',
        function ($scope) {
            $scope.renderThrottleMessageDetectorTemplateUrl =
                renderThrottleMessageDetectorTemplateUrl;
            $scope.jobFetchCapsMessageTemplateUrl = jobFetchCapsMessageTemplateUrl;
        },
    ],
});
