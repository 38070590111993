export const modelToStartEnd = [
    'chartDisplayUtils',
    function (chartDisplayUtils) {
        return function (chartConfig) {
            let start = null;
            let end = null;
            const timenow = Date.now();
            if (!chartDisplayUtils.isAbsoluteTimeFromConfig(chartConfig)) {
                start = timenow + chartDisplayUtils.getFetchDurationFromConfig(false, chartConfig);
                end = timenow + chartDisplayUtils.getEndDurationFromConfig(false, chartConfig);
            } else {
                start = chartDisplayUtils.getFetchDurationFromConfig(true, chartConfig);
                end = chartDisplayUtils.getEndDurationFromConfig(true, chartConfig);
            }
            return {
                start: start,
                end: end,
            };
        };
    },
];
