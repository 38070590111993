import React, { FC } from 'react';

type LOOrganizationsTabProps = {
    orgId: string;
    featureEnabled: any;
};

const LOOrganizationsTab: FC<LOOrganizationsTabProps> = ({ orgId, featureEnabled }) => {
    return (
        <div className="sf-tab-header-container billing-usage-header">
            <a
                className="sf-tab-header billing-usage-tab-header"
                href={'#/organization/' + orgId + '?selectedKeyValue=sf_section:immusage'}
            >
                Infrastructure Monitoring
            </a>
            {featureEnabled('apm2') && (
                <a
                    className="sf-tab-header billing-usage-tab-header"
                    href={'#/organization/' + orgId + '?selectedKeyValue=sf_section:apmusage'}
                >
                    APM
                </a>
            )}
            {featureEnabled('logs') && (
                <a
                    className="sf-tab-header billing-usage-tab-header selected"
                    href={'#/organization/' + orgId + '?selectedKeyValue=sf_section:lousage'}
                >
                    Log Observer
                </a>
            )}
        </div>
    );
};
export default LOOrganizationsTab;
