angular.module('signalview.metricService', []).service('metricService', [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        const url = API_URL + '/v2/metric';
        const VALID_METRIC_TYPES = ['CUMULATIVE_COUNTER', 'COUNTER', 'GAUGE'];
        const extractData = (response) => response.data;

        const processFacets = function (response) {
            return extractData(response).map(mapFacet);
        };

        const mapFacet = function (facet) {
            const facetValues = extractFacetInformation(facet);
            return {
                display: facet.alias || facet.key,
                property: facet.key,
                values: facetValues,
            };
        };

        const extractFacetInformation = function (facet) {
            if (!facet.values) {
                return [];
            }
            return facet.values.map((value) => {
                return {
                    filterValue: facet.key + ':' + value,
                    name: value,
                };
            });
        };

        function toV2Write(v1Object, description, metricType) {
            const typeToWrite = metricType || v1Object.sf_metricType;
            if (!typeToWrite || !VALID_METRIC_TYPES.includes(typeToWrite)) {
                throw new Error(
                    'Critical error when trying to update a metric, no metricType could be determined!'
                );
            }
            return {
                name: v1Object.sf_metric,
                description: description || v1Object.sf_description,
                type: typeToWrite,
            };
        }

        const svc = {
            get: function (name) {
                const encodedName = encodeURIComponent(name);
                return $http.get(url + '/' + encodedName);
            },
            getProperties: function (name, filters = [], activeOnly) {
                const params = {
                    activeOnly,
                    filters,
                    limit: 100,
                };
                const encodedName = encodeURIComponent(name);
                return $http.get(url + '/' + encodedName + '/_/properties', { params });
            },
            suggest: function (query, filters = [], activeOnly) {
                const params = {
                    query,
                    filters,
                    activeOnly,
                };
                return $http.get(`${url}/_/suggest`, { params });
            },
            facets: function (query, filters = [], activeOnly) {
                const params = {
                    query,
                    filters,
                    activeOnly,
                };
                return $http.get(`${url}/_/facets`, { params }).then(processFacets);
            },
            metricfinder: function (query, filters = [], activeOnly) {
                const params = {
                    query,
                    filters,
                    activeOnly,
                };
                return $http.get(`${url}/_/metricfinder`, { params }).then(function (response) {
                    const ret = response.data;
                    ret.facets = (ret.facets || []).map(mapFacet);
                    ret.customCategories = (ret.customCategories || []).map(mapFacet);
                    return ret;
                });
            },
            fetchFacetByKey: function (key, query, filters, activeOnly, partialInput) {
                const params = {
                    query,
                    filters,
                    activeOnly,
                    partialInput,
                };
                return $http
                    .get(`${url}/_/facet-by-key/${key}`, { params })
                    .then(extractData)
                    .then(extractFacetInformation);
            },
            fetchExtraMetricInfo: function (name, filters = [], activeOnly) {
                const params = {
                    filters,
                    activeOnly,
                };
                return $http
                    .get(`${url}/${encodeURIComponent(name)}/_/catalog-details`, { params })
                    .then(extractData);
            },
            predefinedFacets: function (query, filters = [], activeOnly) {
                const params = {
                    query,
                    filters,
                    activeOnly,
                };
                return $http.get(`${url}/_/custom-categories`, { params }).then(processFacets);
            },
            put: function (name, metric) {
                return $http.put(`${url}/${encodeURIComponent(name)}`, metric).then(extractData);
            },
            toV2Write,
        };
        return svc;
    },
]);
