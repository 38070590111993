angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Active Hosts')
                .type('line')
                .mode('single')
                .disableThrottle(true)
                .maxDecimalPlaces(4);

            chart
                .plot()
                .metric('memory.free')
                .addManipulation(blockService.count.asAggregation())
                .addManipulation(blockService.max.asTransformation(1, 'm'))
                .extrapolationPolicy('LAST_VALUE_EXTRAPOLATION')
                .propertyFilters(filters)
                .maxExtrapolations(5);

            return chart;
        }

        chartTemplates.register('collectdHostsActiveCount', createChart);
    },
]);
