export default [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        const resourceEndpoint = API_URL + '/v2/sso';

        function getAssertion(issuer) {
            return $http
                .post(`${resourceEndpoint}/assertion`, {
                    issuer,
                })
                .then((response) => response.data);
        }

        function postSAMLAndRedirect(url, samlRequest, relayState) {
            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            if (relayState) {
                url = url + '?RelayState=' + relayState;
            }
            form.setAttribute('action', url);

            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', 'SAMLResponse');
            hiddenField.setAttribute('value', samlRequest);
            form.appendChild(hiddenField);

            document.body.appendChild(form);
            form.submit();
        }

        function getAssertionAndRedirect(issuer, relayState) {
            return getAssertion(issuer).then((data) => {
                postSAMLAndRedirect(data.subjectRecipient, data.samlRequest, relayState);
            });
        }

        return {
            getAssertionAndRedirect,
        };
    },
];
