export const ChartDisplayDebounceService = [
    '$timeout',
    'featureEnabled',
    '$log',
    function ($timeout, featureEnabled, $log) {
        const TS_COUNT_THRESHOLD = 1000;
        const HIGH_TS_TIMEOUT = 5000;
        const LEADING_DEBOUNCE_TIMEOUT = 1000;

        //A shareable debounce service that can be used to synchronize job debounce events between different chart displays.
        function ChartDisplayDebounceService() {
            this.lastTimeSeriesCount = 0;
            this.enabled = false;
            this.paused = false;
            this.deferred = null;
            this.suspended = false;
            this.debounceForAllJobs = false;
            this.callbacks = {
                data: [],
                progress: [],
            };
            this.debounceJobRequests = false;
            this.debouncingRequest = false;
        }

        ChartDisplayDebounceService.prototype.debounceAllJobs = function (_) {
            this.debounceForAllJobs = _;
        };

        ChartDisplayDebounceService.prototype.cancelDeferred = function () {
            $timeout.cancel(this.deferred);
            this.deferred = null;
        };

        ChartDisplayDebounceService.prototype.suspend = function () {
            this.cancelDeferred();
            this.suspended = true;
        };

        ChartDisplayDebounceService.prototype.unsuspend = function () {
            this.paused = false;
            this.suspended = false;
            this.invokeData();
        };

        ChartDisplayDebounceService.prototype.pause = function () {
            this.paused = true;
            this.cancelDeferred();
        };

        ChartDisplayDebounceService.prototype.unpause = function () {
            if (this.paused) {
                this.paused = false;
                this.jobRequested();
            }
        };

        ChartDisplayDebounceService.prototype.jobRequested = function () {
            const self = this;
            if (self.suspended) {
                return;
            }

            const highTsCount = this.lastTimeSeriesCount > TS_COUNT_THRESHOLD;
            const shouldDebounce = self.enabled && (highTsCount || self.debounceForAllJobs);

            if (shouldDebounce) {
                if (highTsCount) {
                    self.debouncedInvokeData(HIGH_TS_TIMEOUT, true);
                } else if (self.debounceForAllJobs) {
                    self.debouncedInvokeData(LEADING_DEBOUNCE_TIMEOUT);
                }
            } else {
                self.debounceJobRequests = false;
                self.invokeData();
            }
        };

        ChartDisplayDebounceService.prototype.debouncedInvokeData = function (
            deferredTimeout,
            trailing
        ) {
            const self = this;

            self.cancelDeferred();
            if (self.debounceJobRequests || trailing) {
                self.invokeProgress();
                self.debounceJobRequests = true;
                self.deferred = $timeout(() => {
                    self.debounceJobRequests = false;
                    self.debouncedInvokeData(deferredTimeout, false);
                }, deferredTimeout);
            } else {
                self.debounceJobRequests = true;
                self.suspended = false;
                self.invokeData();
                self.deferred = $timeout(() => (self.debounceJobRequests = false), deferredTimeout);
            }
        };

        ChartDisplayDebounceService.prototype.latestJobStats = function (_) {
            this.lastTimeSeriesCount = _;
        };

        ChartDisplayDebounceService.prototype.setEnabled = function (_) {
            if (!featureEnabled('disableBigChartDebouncer')) {
                this.enabled = _;
            } else {
                $log.info('Cannot set debounce enable state because the feature is disabled.');
            }
        };

        ChartDisplayDebounceService.prototype.isWaiting = function () {
            return this.suspended || this.debouncingRequest || this.paused;
        };

        ChartDisplayDebounceService.prototype.registerListener = function (callback, p) {
            this.callbacks.data.push(callback);
            this.callbacks.progress.push(p);
        };

        ChartDisplayDebounceService.prototype.invokeData = function () {
            this.debouncingRequest = false;
            this.callbacks.data.forEach((_) => _());
        };

        ChartDisplayDebounceService.prototype.invokeProgress = function () {
            this.debouncingRequest = true;
            this.callbacks.progress.forEach((_) => _());
        };

        ChartDisplayDebounceService.prototype.isPaused = function () {
            return this.paused;
        };

        ChartDisplayDebounceService.prototype.isSuspended = function () {
            return this.suspended;
        };

        return ChartDisplayDebounceService;
    },
];
