'use strict';
import templateUrl from './incidentIntelligenceServiceNowIntegration.html';

/* jshint camelcase: false */

angular.module('sfx.ui').directive('sfxIncidentIntelligenceServiceNowIntegration', [
    'PRODUCT_NAME',
    function (PRODUCT_NAME) {
        return {
            restrict: 'E',
            scope: true,
            require: '^form',
            templateUrl,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;

                function reset() {
                    const credential = $scope.credential;
                    credential.name = credential.name || 'INCIDENTINTELLIGENCESERVICENOW';
                }

                function clearCredentialsWhenBaseUrlChanges(newValue, oldValue) {
                    if (newValue === oldValue) return;

                    if (newValue !== $scope.originalState.baseUrl) {
                        clearCredentials();
                    }
                }

                function clearCredentials() {
                    $scope.credential.snowLoginId = '';
                    $scope.credential.snowSecret = '';
                    $scope.credential.timeZone = '';
                    $scope.credential.orgToken = '';
                }

                $scope.isDirty = function (key) {
                    return $scope.credential[key] !== $scope.originalState[key];
                };

                $scope.$on('reset', reset);
                reset();

                $scope.$watch('credential.baseUrl', clearCredentialsWhenBaseUrlChanges);
            },
        };
    },
]);
