import logger from '../../logger';
import { cloneDeep } from 'lodash';

const error = logger('scalingUnitService').extend('error');

import { SCALING_UNIT_DEFINITIONS } from './scalingUnitTypes';

let NAME_TO_CONFIG;
let NAME_TO_SCALE;

indexUnitTypes();

function getScalingUnits() {
    return cloneDeep(SCALING_UNIT_DEFINITIONS);
}

function getScaleForUnit(unitName) {
    if (!NAME_TO_SCALE[unitName]) {
        error(`Tried to retrieve scale nonexistent unit: ${unitName}`);
    }

    return cloneDeep(NAME_TO_SCALE[unitName]);
}

function getUnitDefinition(unitName) {
    if (!NAME_TO_CONFIG[unitName]) {
        error(`Tried to retrieve unit definition for nonexistent unit: ${unitName}`);
    }

    return cloneDeep(NAME_TO_CONFIG[unitName]);
}

function scaleDown(value, unit) {
    let currentUnit = getUnitDefinition(unit);

    while (shouldConvertSmaller(value, currentUnit)) {
        value = toNextSmaller(value, currentUnit);
        currentUnit = currentUnit.nextSmaller;
    }

    return { value, unit: currentUnit };
}

function scaleUp(value, unit) {
    let currentUnit = getUnitDefinition(unit);

    while (shouldConvertLarger(value, currentUnit)) {
        value = toNextLarger(value, currentUnit);
        currentUnit = currentUnit.nextLarger;
    }

    return { value, unit: currentUnit };
}

function scaleToBestUnit(value, unitName) {
    if (value < 1) {
        return scaleDown(value, unitName);
    } else {
        return scaleUp(value, unitName);
    }
}

function shouldConvertSmaller(value, unit) {
    return unit.nextSmaller && value < 1;
}

function shouldConvertLarger(value, unit) {
    return unit.nextLarger && value * (unit.factor / unit.nextLarger.factor) >= 1;
}

function toNextSmaller(value, unit) {
    return value * (unit.factor / unit.nextSmaller.factor);
}

function toNextLarger(value, unit) {
    return value * (unit.factor / unit.nextLarger.factor);
}

// private functions
function indexUnitTypes() {
    NAME_TO_CONFIG = SCALING_UNIT_DEFINITIONS.reduce((map, unitType) => {
        unitType.subTypes.forEach((subType) => {
            map[subType.name] = subType;
        });

        return map;
    }, {});

    NAME_TO_SCALE = SCALING_UNIT_DEFINITIONS.reduce((map, unitType) => {
        unitType.subTypes.forEach((unit) => (map[unit] = unitType.subTypes));
        return map;
    }, {});

    SCALING_UNIT_DEFINITIONS.forEach((unitType) => {
        unitType.subTypes.forEach((unit, idx) => {
            if (unitType.subTypes[idx - 1]) {
                unit.nextSmaller = unitType.subTypes[idx - 1];
            }

            if (unitType.subTypes[idx + 1]) {
                unit.nextLarger = unitType.subTypes[idx + 1];
            }
        });
    });
}

export {
    getScalingUnits,
    getScaleForUnit,
    getUnitDefinition,
    scaleDown,
    scaleUp,
    scaleToBestUnit,
    shouldConvertSmaller,
    shouldConvertLarger,
};
