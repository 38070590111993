export default function () {
    class ServiceEndpointSelection {
        constructor(service, endpoints) {
            this.service = service ? service : '';
            this.endpoints = endpoints ? endpoints : [];
        }

        // Getter ---------------------------------

        get service() {
            return this._service;
        }

        get endpoints() {
            return this._endpoints;
        }

        // Setter ---------------------------------

        set service(service) {
            this._service = service ? service : '';
        }

        set endpoints(endpoints) {
            if (endpoints && angular.isArray(endpoints)) {
                this._endpoints = endpoints;
            }
        }

        // Method ---------------------------------

        hasService() {
            return this._service !== '';
        }

        hasEndpoint() {
            return this.endpoints.length !== 0;
        }

        isComplete() {
            return this.hasService() && this.hasEndpoint();
        }

        isEmpty() {
            return !this.hasService() && !this.hasEndpoint();
        }

        isEndpointSelected(endpoint) {
            return this.endpoints.includes(endpoint);
        }

        addEndpoint(endpoint) {
            if (endpoint && endpoint !== '' && !this.isEndpointSelected(endpoint)) {
                this.endpoints.push(endpoint);
                this.endpoints.sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });
            }
        }

        removeEndpoint(endpoint) {
            this.endpoints = this.endpoints.filter((e) => e !== endpoint);
        }

        toggleEndpoint(endpoint) {
            if (this.isEndpointSelected(endpoint)) {
                this.removeEndpoint(endpoint);
            } else {
                this.addEndpoint(endpoint);
            }
        }

        copyProperties(other) {
            if (!other) return;
            this.service = other.service;
            this.endpoints = other.endpoints;
        }

        clear() {
            this.service = '';
            this.endpoints = [];
        }

        getSelectionSummaryStr() {
            if (this.isEmpty()) return '';

            let selectionStr = this.service + ':';
            if (this.endpoints.length > 0) {
                selectionStr += this.endpoints[0];
                if (this.endpoints.length > 1) {
                    selectionStr += ', ' + (this.endpoints.length - 1) + ' more';
                }
            }

            return selectionStr;
        }

        getId() {
            return this.service + '-' + this.endpoints.sort().join('-');
        }

        toString() {
            return this.service + ':' + this.endpoints.sort().join();
        }
    }

    return ServiceEndpointSelection;
}
