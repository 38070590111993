angular
    .module('signalview.organization')

    .controller('ManageAlertModalController', [
        '$log',
        '$scope',
        '$timeout',
        'data',
        'namedTokenService',
        'notificationsUtil',
        'subscriptionType',
        'subscriptionTypeUtil',
        '_',
        'featureEnabled',
        'PRODUCT_NAME',
        function (
            $log,
            $scope,
            $timeout,
            data,
            namedTokenService,
            notificationsUtil,
            subscriptionType,
            subscriptionTypeUtil,
            _,
            featureEnabled,
            PRODUCT_NAME
        ) {
            $scope.PRODUCT_NAME = PRODUCT_NAME;
            $scope.apiLimitConfigurationAllowed = featureEnabled('tokenApiLimits');
            $scope.mtsmLimitConfigurationAllowed = featureEnabled('tokenApiLimitMtsm');
            $scope.token = data.token;
            $scope.threshold = {};
            $scope.threshold.dpmQuota = data.token.limits.dpmQuota;
            $scope.notificationThreshold = data.token.limits.dpmNotificationThreshold;
            $scope.notifications = notificationsUtil.convertV2ListToV1(data.token.notifications);
            $scope.threshold.jobsPerMinuteLimit = data.token.limits.jobsPerMinuteLimit;
            $scope.threshold.eventSearchesPerMinute = data.token.limits.eventSearchesPerMinute;
            $scope.threshold.mtsmLimit = data.token.limits.mtsmLimit;
            $scope.isNonLegacy = subscriptionTypeUtil.isNonLegacy(subscriptionType);
            $scope.categoryThreshold = angular.copy(data.token.limits.categoryQuota, {});
            $scope.advancedSettings = false;

            $scope.relevantCategories =
                subscriptionTypeUtil.getCategoriesBySubscription(subscriptionType);
            $scope.indexToCategoryName = {};
            $scope.relevantCategories.forEach((categoryLimitInfo) => {
                const singularName = categoryLimitInfo.name.slice(0, -1); // remove the last 's'
                $scope.indexToCategoryName[categoryLimitInfo.value] = singularName;
            });

            $scope.isAdvanceSettingConfigured = function () {
                return Object.values($scope.threshold).some(
                    (value) => value !== '' && value !== null
                );
            };

            $scope.isExistingTokenLimitConfigured = function () {
                if (!$scope.isNonLegacy && data.token.limits.dpmQuota !== null) {
                    return true;
                }

                if ($scope.isNonLegacy) {
                    for (const key in data.token.limits.categoryQuota) {
                        if (data.token.limits.categoryQuota[key] !== null) {
                            return true;
                        }
                    }
                }

                if (
                    data.token.limits.jobsPerMinuteLimit !== null ||
                    data.token.limits.eventSearchesPerMinute !== null ||
                    data.token.limits.mtsmLimit !== null
                ) {
                    return true;
                }

                return false;
            };

            $scope.isRecipientDisabled = function () {
                if (
                    (!$scope.isNonLegacy &&
                        $scope.threshold.dpmQuota !== '' &&
                        $scope.threshold.dpmQuota !== null) ||
                    Object.values($scope.categoryThreshold).some(
                        (value) => value !== '' && value !== null
                    )
                ) {
                    return false;
                }
                return true;
            };

            $scope.isUpdateButtonDisabled = function () {
                return (
                    !$scope.isExistingTokenLimitConfigured() &&
                    !$scope.isAdvanceSettingConfigured() &&
                    $scope.isRecipientDisabled()
                );
            };

            $scope.saveOrUpdate = function () {
                $scope.state = 'loading';
                const notifications = notificationsUtil.convertV1ListToV2($scope.notifications);
                let notificationLimit = null;
                let threshold = null;
                let categoryThreshold = null;
                let categoryNotificationLimit = null;
                let jobLimit = null;
                let eventSearchLimit = null;
                let mtsmLimit = null;

                if (!$scope.isNonLegacy) {
                    if ($scope.threshold.dpmQuota) {
                        threshold = parseInt($scope.threshold.dpmQuota, 10);
                        notificationLimit = threshold * 0.9;
                    }
                } else {
                    if ($scope.categoryThreshold) {
                        categoryThreshold = {};
                        categoryNotificationLimit = {};
                        _.forEach($scope.categoryThreshold, (value, type) => {
                            value = parseInt(value, 10);
                            if (!isNaN(value)) {
                                categoryThreshold[type] = value;
                                categoryNotificationLimit[type] = value * 0.9;
                            }
                        });
                    }
                }

                if ($scope.threshold.jobsPerMinuteLimit) {
                    jobLimit = parseInt($scope.threshold.jobsPerMinuteLimit, 10);
                }

                if ($scope.threshold.eventSearchesPerMinute) {
                    eventSearchLimit = parseInt($scope.threshold.eventSearchesPerMinute, 10);
                }

                if ($scope.threshold.mtsmLimit) {
                    mtsmLimit = parseInt($scope.threshold.mtsmLimit, 10);
                }

                return namedTokenService
                    .setLimits(
                        data.token.name,
                        threshold,
                        notificationLimit,
                        notifications,
                        jobLimit,
                        eventSearchLimit,
                        categoryThreshold,
                        categoryNotificationLimit,
                        mtsmLimit
                    )
                    .then(
                        function (token) {
                            $scope.state = 'updated';
                            $timeout(function () {
                                $scope.$close(token);
                            }, 1000);
                        },
                        (response) => {
                            $scope.state = 'failed';
                            $log.error('Error updating limits', response);
                        }
                    );
            };

            $scope.toggleAdvancedSettings = function () {
                $scope.advancedSettings = !$scope.advancedSettings;
            };
        },
    ]);
