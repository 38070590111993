import templateUrl from './metricsPicker.tpl.html';
import metricsPickerLegacyResults from './metricsPickerLegacyResults.tpl.html';
import filterTooltipTemplateUrl from './filterTooltip.tpl.html';
import metricTooltipTemplateUrl from './metricTooltip.tpl.html';
import tabCompletionResultTemplateUrl from '../typeaheadDropDown/tabCompletionResult.tpl.html';

angular.module('signalview.metricsPicker').directive('metricsPicker', [
    'INTERNAL_METRIC_PREFIX',
    'METRIC_TYPE',
    'EVENT_TYPE',
    'ALERT_TYPE',
    'METRIC_DELIMITERS',
    'METRICS_CATALOG_CONSTANTS',
    'chartbuilderUtil',
    'signalboost',
    '$q',
    'catalogMetricDocumentation',
    'objectService',
    'currentUser',
    '$log',
    'metricService',
    '_',
    'organizationService',
    'filterSelectModal',
    'integrationsListService',
    'metricsBrowseService',
    'metricsCatalogSearchUtil',
    'promiseGenerationManager',
    function (
        INTERNAL_METRIC_PREFIX,
        METRIC_TYPE,
        EVENT_TYPE,
        ALERT_TYPE,
        METRIC_DELIMITERS,
        METRICS_CATALOG_CONSTANTS,
        chartbuilderUtil,
        signalboost,
        $q,
        catalogMetricDocumentation,
        objectService,
        currentUser,
        $log,
        metricService,
        _,
        organizationService,
        filterSelectModal,
        integrationsListService,
        metricsBrowseService,
        metricsCatalogSearchUtil,
        promiseGenerationManager
    ) {
        return {
            restrict: 'E',
            replace: false,
            templateUrl,
            scope: {
                onSelect: '=',
                onClearFilter: '=?',
                visible: '=?',
                viewType: '=?',
                allowMultipleSelection: '@?',
            },
            link: function ($scope) {
                const searchTypes = {
                    METRIC: 'metric',
                    EVENT: 'event',
                };

                const delimRegExp = new RegExp(
                    '[' + _.escapeRegExp(METRIC_DELIMITERS.join('')) + ']'
                );
                const LIST_DELIM = ', ';
                const requestDebouncedEventsResultSet = promiseGenerationManager(
                    requestEventsResultSet,
                    0
                );
                const requestDebouncedMetricsSuggestions = promiseGenerationManager(
                    requestMetricsSuggestions,
                    0
                );

                let lastSearchTypeSelection;
                let resultsCache = {};

                $scope.templates = {
                    filterTooltipTemplateUrl,
                    metricTooltipTemplateUrl,
                    tabCompletionResultTemplateUrl,
                    metricsPickerLegacyResults,
                };

                $scope.searchTypes = searchTypes;
                $scope.currentSearchType = null;
                $scope.isAdmin = false;

                $scope.prioritizedFilters = null; // vs. [] being no filters found
                $scope.filters = null; // vs. [] being no filters found
                $scope.filtersConfig = []; // Metadata for prioritized suggested search filters
                $scope.selectedFilters = []; // User selected search filters, from suggestions

                $scope.isDetector = $scope.viewType === 'detector';
                $scope.searchTerm = ''; // Prevent triggering $watch when this changes from undefined to ''

                $scope.searchResults = null; // vs. [] being no results found
                $scope.resultsChecked = {};
                $scope.numChecked = 0;

                $scope.isActiveMetricsOnly = true;

                // Make sure the bucket-result toggles link to select all metrics is
                // only shown if there are less than page limit results returned.
                // Otherwise, the user will expect metrics which haven't been retrieved
                // yet to be added to the dashboard.
                $scope.pageLimit = 101;
                $scope.pageNumber = 0;
                $scope.scrollReset = 0;

                $scope.METRICS_CATALOG_CONSTANTS = METRICS_CATALOG_CONSTANTS;

                $scope.getIntegrationDisplayName = metricsBrowseService.getDisplayName;

                $scope.isSearchDisabled = false;
                $scope.currentNumOfSearchTerms = 0;

                $scope.toggleExpandBrowseCategory = toggleExpandBrowseCategory;
                $scope.showMoreFilterValues = showMoreFilterValues;
                $scope.addFilterFromIntegrationAndSearch = addFilterFromIntegrationAndSearch;
                $scope.getResultsSet = getResultsSet;
                $scope.setSearchType = setSearchType;
                $scope.purgeResults = purgeResults;
                $scope.addFilter = addFilter;
                $scope.addFilterFromString = addFilterFromString;
                $scope.addFilterFromStringAndSearch = addFilterFromStringAndSearch;
                $scope.removeFilter = removeFilter;
                $scope.expandFilters = expandFilters;
                $scope.suggestMetricGroups = suggestMetricGroups;
                $scope.selectMetricGroup = selectMetricGroup;
                $scope.getMetricMetadata = getMetricMetadata;
                $scope.canSelectAll = canSelectAll;
                $scope.selectAll = selectAll;
                $scope.selectNone = selectNone;
                $scope.viewOrSelectItem = viewOrSelectItem;
                $scope.viewOneOrMoreCharts = viewOneOrMoreCharts;
                $scope.viewOneOrMorePlots = viewOneOrMorePlots;
                $scope.updateAndGetResults = updateAndGetResults;
                $scope.getCategoryName = getCategoryName;
                $scope.getExtraSearchTermCount = metricsCatalogSearchUtil.getExtraSearchTermCount;

                initialize();

                function initialize() {
                    $scope.isLoading = true;

                    // Admins can customize the initial filters
                    const isAdminPromise = currentUser.isAdmin().then(function (isAdmin) {
                        $scope.isAdmin = isAdmin;
                    });

                    // Get configured prioritized filters for this organization
                    const orgSettingsPromise = organizationService
                        .getOrgSettings()
                        .then(function (prefs) {
                            if (!prefs) {
                                $log.error('No preferences object found for current organization');
                                $scope.orgPrefsId = '';
                                $scope.filtersConfig = [];
                            } else {
                                $scope.orgPrefsId = prefs.sf_id;
                                $scope.filtersConfig = prefs.sf_prioritizedFilters.filters;
                            }
                        });

                    const activeIntegrationsPromise = integrationsListService
                        .getAllEnabledIntegrations()
                        .then((integrations) => {
                            const integrationsByCategory =
                                metricsBrowseService.getIntegrationsByCategory(integrations);
                            $scope.recentlyAddedIntegrations =
                                integrationsByCategory[
                                    METRICS_CATALOG_CONSTANTS.RECENTLY_ADDED_CATEGORY_NAME
                                ];
                            delete integrationsByCategory[
                                METRICS_CATALOG_CONSTANTS.RECENTLY_ADDED_CATEGORY_NAME
                            ];
                            $scope.integrations =
                                metricsBrowseService.getFinalListOfIntegrations(
                                    integrationsByCategory
                                );
                        });

                    const customCategoriesPromise = metricService
                        .predefinedFacets()
                        .then((categories) => {
                            categories.forEach((category) => (category.expanded = true));
                            $scope.customCategories = categories;
                        });

                    setSearchType(searchTypes.METRIC);
                    initializeWatchers();

                    $q.all({
                        isAdminPromise,
                        orgSettingsPromise,
                        activeIntegrationsPromise,
                        customCategoriesPromise,
                    }).then(() => ($scope.isLoading = false));

                    $scope.$on('metric finder search terms', (evt, searchTermsCount) => {
                        $scope.isSearchDisabled =
                            searchTermsCount > METRICS_CATALOG_CONSTANTS.MAX_SEARCH_TERMS;
                        $scope.currentNumOfSearchTerms = searchTermsCount;
                    });
                }

                function initializeWatchers() {
                    $scope.$watch('currentSearchType', handleSearchTypeUpdate);

                    $scope.$watchCollection('resultsChecked', function () {
                        let numChecked = 0;
                        const itemIds = Object.keys($scope.resultsChecked);
                        for (let i = 0; i < itemIds.length; i++) {
                            const id = itemIds[i];
                            if ($scope.resultsChecked[id]) {
                                numChecked++;
                            }
                        }
                        $scope.numChecked = numChecked;
                        generateCatalogResultOnClickActionText();
                    });

                    // Use events search only for event type chart
                    $scope.$on('chartModeChanged', handleChartModeUpdate);
                }

                // Construct "filters" parameter for queries
                function filtersParam() {
                    return $scope.selectedFilters.map(
                        (filter) => `${filter.NOT ? '!' : ''}${filter.name}:${filter.currentValue}`
                    );
                }

                function getCategoryName(name) {
                    return (
                        METRICS_CATALOG_CONSTANTS.INTEGRATION_CATEGORIES_SENTENCE_CASE[name] || name
                    );
                }

                // Select filter value
                function addFilter(filter, value) {
                    // Remove filter pill before adding it to the selected filters
                    let foundFilter = false;
                    if ($scope.prioritizedFilters) {
                        for (let i = 0; i < $scope.prioritizedFilters.length; i++) {
                            if ($scope.prioritizedFilters[i].name === filter.name) {
                                foundFilter = true;
                                $scope.prioritizedFilters.splice(i, 1);
                                break;
                            }
                        }
                    }
                    if (!foundFilter && $scope.filters) {
                        for (let j = 0; j < $scope.filters.length; j++) {
                            if ($scope.filters[j].name === filter.name) {
                                $scope.filters.splice(j, 1);
                                break;
                            }
                        }
                    }
                    filter.selected = true;
                    filter.currentValue = value;
                    if (!$scope.selectedFilters.length) {
                        // Backend only supports finding metrics with filters (not events)
                        setSearchType(searchTypes.METRIC);
                    }
                    $scope.selectedFilters.push(filter);

                    $scope.searchTerm = '';
                    $scope.getResultsSet(false);
                }

                // Remove selected filter
                function removeFilter(filter) {
                    $scope.resultsChecked = {};
                    for (let i = 0; i < $scope.selectedFilters.length; i++) {
                        if (
                            $scope.selectedFilters[i].name === filter.name &&
                            $scope.selectedFilters[i].currentValue === filter.currentValue
                        ) {
                            $scope.selectedFilters.splice(i, 1);
                            break;
                        }
                    }
                    if ($scope.onClearFilter) {
                        $scope.onClearFilter(filter.name, filter.currentValue);
                    }

                    if (!_.isEmpty(filter.values)) {
                        filter.currentValue = filter.values[0];
                    }

                    filter.selected = false;
                    // Rerun search now without the removed filter
                    $scope.getResultsSet(false);
                }

                function expandFilters() {
                    $scope.filtersExpanded = !$scope.filtersExpanded;
                }

                // ---------
                // Searching
                // ---------

                function getMetricGroups(orgId, searchTerm) {
                    const params = {
                        partialInput: searchTerm,
                        limit: $scope.pageLimit,
                        organizationId: orgId,
                    };
                    const filters = filtersParam();
                    if (filters && filters.length) {
                        params.query = filters.join(' AND ');
                    }
                    return signalboost.autosuggest
                        .all()
                        .all('suggest')
                        .all('metricgroup')
                        .customGET('', params)
                        .then(
                            function (results) {
                                return results.groups
                                    .filter(function (group) {
                                        // Ignore internal metrics
                                        return !(
                                            group.position === 0 &&
                                            group.name.indexOf(INTERNAL_METRIC_PREFIX) === 0
                                        );
                                    })
                                    .map(function (group) {
                                        return group.name;
                                    });
                            },
                            function (err) {
                                $log.error('Unable to get metric groups', err);
                                return [];
                            }
                        );
                }

                function isMetricDelimiter(s) {
                    return METRIC_DELIMITERS.indexOf(s) >= 0;
                }

                function containsMetricDelimiter(s) {
                    return METRIC_DELIMITERS.reduce(function (acc, v) {
                        return acc || s.indexOf(v) >= 0;
                    }, false);
                }

                function getMetricDelimiter(metric) {
                    const match = delimRegExp.exec(metric);
                    if (!match) {
                        return null;
                    }
                    return match[0];
                }

                function splitMetric(metric) {
                    const delimiter = getMetricDelimiter(metric);
                    // If delimiter is null, split will return a single element array
                    // with the whole metric.
                    return metric.split(delimiter);
                }

                // Truncate metric names in this format:
                // <beginning_FIRST_NODE_LENGTH_chars>...<last_PENULTIMATE_NODE_LENGTH_chars><METRIC_DELIM><last_group>
                function formatMetricSuggestion(fullName) {
                    const FIRST_NODE_LENGTH = 10;
                    const PENULTIMATE_NODE_LENGTH = 10;

                    const nodes = splitMetric(fullName);
                    const delimiter = getMetricDelimiter(fullName);
                    let suggestion = fullName;
                    if (nodes.length > 2) {
                        let firstNode = nodes[0];
                        let needEllipsis = nodes.length > 3;
                        if (firstNode.length > FIRST_NODE_LENGTH) {
                            firstNode = firstNode.substr(0, FIRST_NODE_LENGTH);
                            needEllipsis = true;
                        }
                        let penultimateNode = nodes[nodes.length - 2];
                        if (penultimateNode.length > PENULTIMATE_NODE_LENGTH) {
                            penultimateNode = penultimateNode.substr(-1 * PENULTIMATE_NODE_LENGTH);
                            needEllipsis = true;
                        }
                        suggestion = firstNode;
                        suggestion += needEllipsis ? '...' : delimiter;
                        suggestion += penultimateNode + delimiter + nodes[nodes.length - 1];
                    }
                    return suggestion;
                }

                // Auto-completion suggestions for the search term entered so far. If
                // nextGroup is true, get suggestions for the next position in the
                // metric name.
                function suggestMetricGroups(searchTerm, nextGroup) {
                    const groups = [];
                    if (!isMetricSearch() || !searchTerm || !searchTerm.length) {
                        // Don't show suggestions
                        return $q.when(groups);
                    }

                    const delimiter = getMetricDelimiter(searchTerm);

                    return currentUser
                        .orgId()
                        .then(function (orgId) {
                            if (nextGroup) {
                                if (delimiter) {
                                    searchTerm += delimiter;
                                    return getMetricGroups(orgId, searchTerm);
                                }
                                // If we don't know the delimiter from the searchTerm (because it
                                // doesn't include one), search with all of the delimiters we
                                // support and combine the promise results into a single array.
                                return $q
                                    .all(
                                        METRIC_DELIMITERS.map(function (d) {
                                            return getMetricGroups(orgId, searchTerm + d);
                                        })
                                    )
                                    .then(function (results) {
                                        return results.reduce(function (acc, groups, i) {
                                            return acc.concat(
                                                groups.map(function (g) {
                                                    return METRIC_DELIMITERS[i] + g;
                                                })
                                            );
                                        }, []);
                                    });
                            } else {
                                return getMetricGroups(orgId, searchTerm);
                            }
                        })
                        .then(function (groups) {
                            const items = groups.map(function (group) {
                                let fullName;
                                if (
                                    isMetricDelimiter(searchTerm.substr(-1)) ||
                                    isMetricDelimiter(group.substr(0, 1))
                                ) {
                                    // Append group to search term
                                    fullName = searchTerm + group;
                                } else if (!containsMetricDelimiter(searchTerm)) {
                                    // Replace search term with group
                                    fullName = group;
                                } else {
                                    // Replace last part of entered search term with selected group
                                    // We can always determine the metric delimiter at this point.
                                    const delimiter = getMetricDelimiter(searchTerm);
                                    const prefix = splitMetric(searchTerm)
                                        .slice(0, -1)
                                        .join(delimiter);
                                    fullName = prefix + delimiter + group;
                                }

                                // Text that will be formatted in suggestions dropdown
                                const suggestion = formatMetricSuggestion(fullName);
                                const delimiter = getMetricDelimiter(suggestion);
                                const lastDelimPos = delimiter
                                    ? suggestion.lastIndexOf(delimiter)
                                    : -1;
                                const hasDelimiter = lastDelimPos >= 0;

                                const boldFullName = !hasDelimiter ? suggestion : '';
                                const normalPrefix = hasDelimiter
                                    ? suggestion.substr(0, lastDelimPos + 1)
                                    : '';
                                const boldSuffix = hasDelimiter
                                    ? suggestion.substr(lastDelimPos + 1, suggestion.length)
                                    : '';

                                return {
                                    group: group,
                                    fullName: fullName,
                                    boldFullName: boldFullName,
                                    normalPrefix: normalPrefix,
                                    boldSuffix: boldSuffix,
                                };
                            });
                            if (searchTerm.substr(-1) === '*') {
                                // Let user continue with the entered input instead of picking
                                // from the suggestions
                                items.unshift({
                                    group: '',
                                    fullName: searchTerm,
                                    boldFullName: '',
                                    normalPrefix: formatMetricSuggestion(searchTerm),
                                    boldSuffix: '',
                                });
                            }
                            return items;
                        });
                }

                // Update search term with auto-completion result selected and run
                // search
                function selectMetricGroup(suggestion) {
                    $scope.searchTerm = suggestion.fullName;
                    $scope.getResultsSet(false);
                }

                function addPropertiesAndTags(obj, metadata) {
                    // Properties
                    const properties = objectService.getObjectProperties(obj);
                    if (properties && properties.length) {
                        metadata.properties = properties;
                    }
                    // Tags
                    if (obj.sf_type !== 'Tag' && obj.sf_tags) {
                        metadata.tags = obj.sf_tags.join(LIST_DELIM);
                    }
                }

                function addV2PropertiesAndTags(obj, metadata) {
                    // Properties
                    const properties = Object.keys(obj.customProperties);
                    if (properties.length) {
                        metadata.properties = properties.map(function (property) {
                            return { name: property, value: obj.customProperties[property] };
                        });
                    }
                    // Tags
                    metadata.tags = obj.tags.join(LIST_DELIM);
                }

                // Friendlier formatting of metric type value
                function formatMetricType(metricType) {
                    return metricType.toLowerCase().replace('_', ' ');
                }

                // Populate metric type, properties, and tags for the metric item
                // if missing, which catalog search doesn't return
                function getMetricMetadata(item) {
                    if (item.type !== METRIC_TYPE || item.metricType) {
                        // Either not a metric or metadata was already cached
                        return;
                    }

                    if (item.regExStyle) {
                        // Wildcard result item
                        item.metricType = 'n/a';
                        return;
                    }

                    // TODO(jwy): Gather this info in signalboost-rest during the catalog
                    // search
                    metricService.get(item.value).then(
                        function (response) {
                            const metricObj = response.data;
                            item.metricType = formatMetricType(metricObj.type);
                            addV2PropertiesAndTags(metricObj, item);
                        },
                        function (err) {
                            $log.error('Unable to get metric metadata', err);
                            item.metricType = 'n/a';
                        }
                    );
                }

                function searchPropertyTypes(propertyList) {
                    const page = $scope.pageNumber++;
                    return currentUser.orgId().then(function (orgId) {
                        return signalboost.autosuggest
                            .all()
                            .all('suggest')
                            .all('values')
                            .customGET('', {
                                partialInput: $scope.searchTerm,
                                offset: page * $scope.pageLimit,
                                limit: $scope.pageLimit,
                                property: propertyList,
                                field: '*',
                                organizationId: orgId,
                            })
                            .then(
                                function (results) {
                                    return results || [];
                                },
                                function (err) {
                                    $log.error('Unable to get metrics and events', err);
                                    return [];
                                }
                            );
                    });
                }

                function getEventsAndDetectors() {
                    return searchPropertyTypes(['sf_eventType', 'sf_detector']);
                }

                function resetResultsAndFilters() {
                    $scope.lastSelection = null;
                    $scope.searchResults = null;
                    if ($scope.searchTerm) {
                        $scope.prioritizedFilters = [];
                        $scope.filters = [];
                    } else {
                        $scope.prioritizedFilters = null;
                        $scope.filters = null;
                    }

                    resetMetricFindabilitySearch();
                }

                function resetPaginationCaches() {
                    $scope.numChecked = 0;
                    $scope.resultsChecked = {};
                    $scope.scrollReset++;
                    $scope.pageNumber = 0;
                    resultsCache = {};
                }

                function isValidSearch() {
                    return !(_.isEmpty($scope.searchTerm) && _.isEmpty($scope.selectedFilters));
                }

                function addToResultsCache(results) {
                    // Cache all the results items by id
                    for (let m = 0; m < results.length; m++) {
                        const item = results[m];
                        // Plot model uses "name" instead of "value "
                        item.name = item.value;
                        resultsCache[item.id] = item;
                    }
                }

                function getDocumentationAndProcessMetricMetadata(results) {
                    const docPromises = {};
                    let metrics = results && results.sf_metric ? results.sf_metric : [];

                    if (metrics.length < $scope.pageLimit) {
                        metrics = metrics.sort(function (a, b) {
                            return a.sf_metric
                                .toLowerCase()
                                .localeCompare(b.sf_metric.toLowerCase());
                        });
                    }

                    metrics.forEach(function (metricObj) {
                        docPromises[metricObj.sf_metric] =
                            catalogMetricDocumentation.getMetricDocumentation(metricObj.sf_metric);
                    });

                    return $q.all(docPromises).then(function (metricsDocs) {
                        const dataSet = [];

                        // Gather metric info
                        for (let i = 0; i < metrics.length; i++) {
                            const metricObj = metrics[i];
                            const metric = metricObj.sf_metric;
                            // Ignore internal metrics
                            if (metric.indexOf(INTERNAL_METRIC_PREFIX) === 0) {
                                continue;
                            }
                            const metricId = metricObj.sf_id || metric;
                            // Use "value" and "type" as key names to match the schema used
                            // by getGlobResults()
                            const metricMetadata = {
                                id: metricId,
                                value: metric,
                                type: METRIC_TYPE,
                            };

                            // Description
                            const doc = metricsDocs[metric];
                            if (doc && doc.yaml.brief) {
                                metricMetadata.description = doc.yaml.brief;
                            }
                            // Metric type
                            if (metricObj.sf_metricType) {
                                metricMetadata.metricType = formatMetricType(
                                    metricObj.sf_metricType
                                );
                                addPropertiesAndTags(metricObj, metricMetadata);
                            }
                            dataSet.push(metricMetadata);
                        }

                        // Gather event type info
                        let eventTypes = results ? results.sf_eventType : [];
                        if (eventTypes) {
                            if (eventTypes.length < $scope.pageLimit) {
                                eventTypes = eventTypes.sort(function (a, b) {
                                    return a.sf_eventType
                                        .toLowerCase()
                                        .localeCompare(b.sf_eventType.toLowerCase());
                                });
                            }
                            for (let j = 0; j < eventTypes.length; j++) {
                                const eventTypeObj = eventTypes[j];
                                const eventType = eventTypeObj.sf_eventType;
                                const eventTypeId = eventTypeObj.sf_id || eventType;
                                // Use "value" and "type" as key names to match the schema
                                // in getGlobResults()
                                const eventTypeMetadata = {
                                    id: eventTypeId,
                                    value: eventType,
                                    type: EVENT_TYPE,
                                };
                                dataSet.push(eventTypeMetadata);
                            }
                        }

                        // Gather detector info
                        let detectors = results && results.sf_detector ? results.sf_detector : [];
                        if (detectors) {
                            if (detectors.length < $scope.pageLimit) {
                                detectors = detectors.sort(function (a, b) {
                                    return a.sf_detector
                                        .toLowerCase()
                                        .localeCompare(b.sf_detector.toLowerCase());
                                });
                            }
                            for (let k = 0; k < detectors.length; k++) {
                                const detectorObj = detectors[k];
                                const detector = detectorObj.sf_detector;
                                const detectorId = detectorObj.sf_id || detector;
                                // Use "value" and "type" as key names to match the schema
                                // in getGlobResults()
                                const detectorMetadata = {
                                    id: detectorId,
                                    value: detector,
                                    type: ALERT_TYPE,
                                };
                                // Description
                                if (detectorObj.sf_description) {
                                    detectorMetadata.description = detectorObj.sf_description;
                                }
                                addPropertiesAndTags(detectorObj, detectorMetadata);
                                dataSet.push(detectorMetadata);
                            }
                        }

                        return dataSet;
                    });
                }

                function requestEventsResultSet(append) {
                    let eventGlobs = null;

                    if (!append && $scope.searchTerm) {
                        eventGlobs = chartbuilderUtil.getEventGlobResults($scope.searchTerm);
                    }

                    const values = getEventsAndDetectors();
                    return $q.all({ eventGlobs, values });
                }

                function getEventsResultSet(append) {
                    const eventResultPromise = append
                        ? requestEventsResultSet(true)
                        : requestDebouncedEventsResultSet(false);

                    return eventResultPromise.then(function (results) {
                        if (!results) {
                            return;
                        }
                        const { eventGlobs, values } = results;
                        let dataSet = [];

                        if (!append) {
                            // Globs lists shouldn't be too large
                            if (eventGlobs && eventGlobs.length) {
                                const globs = eventGlobs
                                    .map((item) => {
                                        if (item.regExStyle === 'event') {
                                            item.type = EVENT_TYPE;
                                            return item;
                                        } else if (item.regExStyle === 'alert') {
                                            item.type = ALERT_TYPE;
                                            return item;
                                        }
                                        return null;
                                    })
                                    .filter((item) => item !== null)
                                    .forEach((glob) => {
                                        glob.id = `${glob.value}-${glob.regExStyle}`;
                                    });

                                dataSet = dataSet.concat(globs);
                            }
                        }

                        return getDocumentationAndProcessMetricMetadata(values).then((results) => {
                            dataSet = dataSet.concat(results);
                            addToResultsCache(dataSet);
                            $scope.searchResults = append
                                ? $scope.searchResults.concat(dataSet)
                                : dataSet;
                            return dataSet;
                        });
                    });
                }

                function updateAndGetResults(searchTerm) {
                    $scope.searchTerm = searchTerm;
                    getResultsSet(false);
                }

                function getResultsSet(append) {
                    if ($scope.isSearchDisabled) {
                        return;
                    }
                    if (!append) {
                        resetPaginationCaches();
                    }

                    if (!isValidSearch()) {
                        resetResultsAndFilters();
                        return;
                    }

                    if (append && !$scope.isSearching) {
                        $scope.isLoadingMore = true;
                    } else {
                        $scope.isSearching = true;
                    }

                    let resultPromise = null;

                    if (isMetricSearch()) {
                        resultPromise = getMetricSuggestionResultsSet(append);
                    } else if (isEventSearch()) {
                        resultPromise = getEventsResultSet(append);
                    }

                    if (resultPromise) {
                        resultPromise.finally(() => {
                            $scope.isLoadingMore = false;
                            $scope.isSearching = false;
                        });
                    }
                }

                function setSearchType(value) {
                    $scope.currentSearchType = value;
                }

                function purgeResults() {
                    $scope.searchResults = null;
                }

                function restoreLastSearchType() {
                    $scope.currentSearchType = lastSearchTypeSelection;
                }

                function isMetricSearch() {
                    return $scope.currentSearchType === searchTypes.METRIC;
                }

                function isEventSearch() {
                    return $scope.currentSearchType === searchTypes.EVENT;
                }

                function applyCallback(items, singleItemMode) {
                    if (!items.length) {
                        return;
                    }

                    const addFiltersToPlots = $scope.addMetricsWithFilters;
                    $scope.isLoading = true;

                    $scope
                        .onSelect(items, $scope.selectedFilters, singleItemMode, addFiltersToPlots)
                        .finally(function () {
                            $scope.resultsChecked = {};
                            $scope.isLoading = false;
                        });
                }

                // TODO(jwy): Disable remaining checkboxes when maximum number manually
                // selected has been reached

                // Select all items or none, to add multiple metrics or charts at once
                function canSelectAll() {
                    return (
                        $scope.allowMultipleSelection &&
                        $scope.searchResults &&
                        $scope.searchResults.length < $scope.pageLimit &&
                        $scope.numChecked < $scope.searchResults.length
                    );
                }

                function selectAll() {
                    if (!$scope.canSelectAll()) {
                        return;
                    }
                    const itemIds = Object.keys(resultsCache);
                    for (let i = 0; i < itemIds.length; i++) {
                        $scope.resultsChecked[itemIds[i]] = true;
                    }
                }

                function selectNone() {
                    if ($scope.numChecked === 0) {
                        return;
                    }
                    $scope.resultsChecked = {};
                }

                function viewOrSelectItem(item) {
                    if ($scope.numChecked) {
                        // If any checkboxes are checked, when clicking on an item, toggle
                        // the checkbox instead of adding a chart - use links for that
                        $scope.resultsChecked[item.id] = !$scope.resultsChecked[item.id];
                        return;
                    }
                    if (!$scope.allowMultipleSelection) {
                        $scope.lastSelection = item;
                    }
                    applyCallback([item], false);
                }

                function getSelectedItemsFromCache() {
                    const items = [];
                    const itemIds = Object.keys($scope.resultsChecked);
                    const fromFindabilityResults = $scope.currentSearchType === searchTypes.METRIC;
                    const cache = fromFindabilityResults ? $scope.searchResults : resultsCache;
                    for (let i = 0; i < itemIds.length; i++) {
                        const id = itemIds[i];
                        if ($scope.resultsChecked[id]) {
                            items.push(cache[id]);
                        }
                    }
                    return items;
                }

                // View all selected items either in one chart or each in its own chart
                function viewOneOrMoreCharts(singleChart) {
                    const invalidForSingle =
                        singleChart &&
                        ($scope.numChecked === 0 || $scope.numChecked >= $scope.pageLimit);
                    const invalidForMultiple =
                        !singleChart &&
                        ($scope.numChecked <= 1 || $scope.numChecked >= $scope.pageLimit);
                    if (invalidForSingle || invalidForMultiple) {
                        return;
                    }

                    const items = getSelectedItemsFromCache();
                    applyCallback(items, singleChart);
                }

                function viewOneOrMorePlots() {
                    const items = getSelectedItemsFromCache();
                    applyCallback(items);
                }

                function handleChartModeUpdate(evt, newMode) {
                    const eventChartMode = 'event';
                    if (newMode === eventChartMode) {
                        setSearchType(searchTypes.EVENT);
                        $scope.eventChart = true;
                    } else {
                        restoreLastSearchType();
                        $scope.eventChart = false;
                    }
                }

                function handleSearchTypeUpdate(newVal, oldVal) {
                    lastSearchTypeSelection = oldVal;
                    resetResultsAndFilters();
                }

                // ------------------------
                // Metric Findability Stuff
                // ------------------------
                function toggleExpandBrowseCategory(category) {
                    category.expanded = !category.expanded;
                }

                function requestMetricsSuggestions() {
                    const query = metricsCatalogSearchUtil.parseSearchText($scope.searchTerm);
                    return metricsCatalogSearchUtil
                        .fetchProcessedMetricAndFacetResults(query, filtersParam(), true)
                        .catch((err) => {
                            $log.error("Unable to get metric-picker's metric suggestions", err);
                            return {};
                        });
                }

                function resetMetricFindabilitySearch() {
                    $scope.searchResults = null;
                    $scope.addMetricsWithFilters = false;
                    $scope.addSeparateMetrics = false;
                }

                function getMetricSuggestionResultsSet(append) {
                    // Metric findability does not have pagination. But for consistency and if in future this becomes a thing
                    // we can handle that here
                    if (append) {
                        return $q.when();
                    }

                    resetMetricFindabilitySearch();

                    return requestDebouncedMetricsSuggestions()
                        .then(function (results) {
                            const metrics = results.metrics;
                            if (!metrics) {
                                return;
                            }

                            metrics.forEach((metric, index) => {
                                // Keep index as id for metric findability to keep track of metric
                                metric.id = index;
                                metric.type = METRIC_TYPE;
                                // For metric name, plot object uses name while Signal object uses value
                                metric.value = metric.name;
                            });
                            $scope.searchResults = metrics;
                            $scope.searchTokens = metricsCatalogSearchUtil.getSanitizedTokens(
                                $scope.searchTerm
                            );
                            metricsCatalogSearchUtil.sendGAEvent(
                                'metrics-sidebar-search-results-count',
                                $scope.searchResults.length
                            );
                            metricsCatalogSearchUtil.sendGAEvent(
                                'metrics-sidebar-search-filters-count',
                                $scope.selectedFilters.length
                            );
                            metricsCatalogSearchUtil.sendGAEvent(
                                'metrics-sidebar-search-terms-count',
                                $scope.searchTokens.length
                            );
                            generateCatalogResultOnClickActionText();
                        })
                        .finally(() => {
                            $scope.isSearching = false;
                        });
                }

                function showMoreFilterValues(name, key) {
                    const title = `${METRICS_CATALOG_CONSTANTS.SHOW_MORE_MODAL_TITLE_PREFIX} ${name}`;
                    const queryInfo = {
                        key: key,
                        isActiveMetricsOnly: true,
                    };
                    return filterSelectModal(
                        title,
                        queryInfo,
                        'metrics-finder-metrics-sidebar'
                    ).then((result) => {
                        addFilterFromStringAndSearch(result);
                    }, angular.noop);
                }

                function addFilterFromIntegrationAndSearch(integrationName) {
                    const filter = metricsBrowseService.getFilterForIntegration(integrationName);
                    addFilterFromStringAndSearch(filter);
                }

                function addFilterFromStringAndSearch(filterString) {
                    addFilterFromString(filterString);
                    getResultsSet();
                }

                function addFilterFromString(filterString) {
                    if (_.isEmpty(filterString) || !filterString.includes(':')) {
                        return;
                    }

                    const { property, propertyValue, NOT } =
                        metricsCatalogSearchUtil.getSourceFilterFromString(filterString);
                    const filter = {
                        name: property,
                        alias: property,
                        selected: true,
                        currentValue: propertyValue,
                        NOT: NOT,
                    };
                    $scope.selectedFilters.push(filter);
                }

                function generateCatalogResultOnClickActionText() {
                    let text = '';
                    if ($scope.numChecked === 0) {
                        if ($scope.viewType === 'dashboard') {
                            text = 'Click metric name to add a chart to this dashboard';
                        } else {
                            text =
                                'Click metric name to add a plot to this ' +
                                ($scope.isDetector ? 'detector' : 'chart');
                        }
                    } else {
                        text = 'Click checkbox to toggle selection of this metric';
                    }

                    $scope.catalogResultsOnClickActionText = text;
                }
            },
        };
    },
]);
