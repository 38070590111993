import PRINCIPAL_TYPE from './AccessControlPrincipalType';

export default class AccessControlPrincipalDataCollection {
    constructor(teams = [], users = []) {
        this.teams = teams.map((t) => this.prepareTeam(t));
        this.users = users.map((u) => this.prepareUser(u));

        this._ids = {};
        [...this.teams, ...this.users].forEach((principal) => {
            this._ids[principal.id] = principal;
        });
    }

    isEmpty() {
        return !!Object.keys(this._ids).length;
    }

    hasObject(id) {
        return !!this._ids[id];
    }

    concat(newPrincipals) {
        const newTeams = newPrincipals.teams.filter((t) => !this.hasObject(t.id));
        const newUsers = newPrincipals.users.filter((u) => !this.hasObject(u.id));

        return new AccessControlPrincipalDataCollection(
            [...this.teams, ...newTeams],
            [...this.users, ...newUsers]
        );
    }

    add(principal, principalType) {
        if (this.hasObject(principal.id)) {
            return;
        }

        const newPrincipal = this.prepare(principal, principalType);
        this._ids[principal.id] = newPrincipal;

        switch (principalType) {
            case PRINCIPAL_TYPE.TEAM:
                this.teams = [...this.teams, newPrincipal];
                break;
            case PRINCIPAL_TYPE.USER:
                this.users = [...this.users, newPrincipal];
                break;
        }
    }

    getById(principalId) {
        return this._ids[principalId] || null;
    }

    flatten() {
        return [...this.teams, ...this.users];
    }

    prepare(obj, principalType) {
        const patch = { __principalType: principalType };
        switch (principalType) {
            case PRINCIPAL_TYPE.TEAM:
                patch.label = `${obj.name} Team`;
                break;
            case PRINCIPAL_TYPE.USER:
                patch.label = `${obj.fullName} (${obj.email})`;
                break;
            case PRINCIPAL_TYPE.ORG:
                patch.label = 'Everyone';
                break;
        }
        return { ...obj, ...patch };
    }

    prepareTeam(t) {
        return this.prepare(t, PRINCIPAL_TYPE.TEAM);
    }

    prepareUser(u) {
        return this.prepare(u, PRINCIPAL_TYPE.USER);
    }

    prepareOrg(org) {
        return this.prepare(org, PRINCIPAL_TYPE.ORG);
    }
}
