'use strict';

angular.module('sfx.ui').directive('listSelector', [
    function () {
        return {
            restrict: 'A',
            scope: {
                items: '=listSelector',
                highlighted: '=listSelectorHighlighted',
                onSelect: '=listSelectorOnSelect',
                onCancel: '=listSelectorOnCancel',
                container: '=listSelectorContainer',
            },
            link: function ($scope, element) {
                function scrollIntoView(index) {
                    const item = $scope.container.find('[data-list-index="' + index + '"]')[0];
                    if (item) item.scrollIntoView(false);
                }

                function highlightNext() {
                    let highlightIndex = $scope.items.indexOf($scope.highlighted) + 1;

                    if (highlightIndex === $scope.items.length) {
                        highlightIndex = 0;
                    }

                    $scope.highlighted = $scope.items[highlightIndex];
                    scrollIntoView(highlightIndex);
                }

                function highlightPrevious() {
                    let highlightIndex = $scope.items.indexOf($scope.highlighted) - 1;
                    if (highlightIndex === -1) {
                        highlightIndex = $scope.items.length - 1;
                    }

                    $scope.highlighted = $scope.items[highlightIndex];
                    scrollIntoView(highlightIndex);
                }

                element.on('keydown', function ($event) {
                    switch ($event.keyCode) {
                        case 27: // escape
                            if ($scope.onCancel) $scope.onCancel();
                            $scope.$apply();
                            break;
                        case 40: // down arrow
                            highlightNext();
                            $event.preventDefault();
                            $scope.$apply();
                            break;
                        case 38: // up arrow
                            highlightPrevious();
                            $event.preventDefault();
                            $scope.$apply();
                            break;
                        case 9: // tab
                        case 13: // enter
                            if ($scope.highlighted) {
                                $scope.onSelect($scope.highlighted);
                                element.focus();
                                $event.preventDefault();
                                $scope.$apply();
                            }
                            break;
                    }
                });
            },
        };
    },
]);
