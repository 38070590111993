angular.module('signalview.objectService', []).service('objectService', [
    'v2ObjectService',
    function (v2ObjectService) {
        const SKIPPED_PROPERTIES = {
            route: true,
            getRestangularUrl: true,
            getRequestedUrl: true,
            addRestangularMethod: true,
            clone: true,
            reqParams: true,
            parentResource: true,
            restangularCollection: true,
            restangularized: true,
            fromServer: true,
        };

        // Extract property keys and values from the given object
        function getObjectProperties(inputObject) {
            inputObject = inputObject._originalElement || inputObject;
            const object = v2ObjectService.isV2Object(inputObject)
                ? inputObject.customProperties
                : inputObject;
            return Object.entries(object)
                .filter(isCustomerFacingPropAndHasValue)
                .map(([key]) => ({
                    name: key,
                    originalValue: object[key],
                    value: object[key],
                }));
        }

        function isCustomerFacingPropAndHasValue([key, value]) {
            return !(
                key.match(/^(sf_|_)/) ||
                value === '' ||
                SKIPPED_PROPERTIES[key] ||
                typeof value === 'function'
            );
        }

        return {
            getObjectProperties,
        };
    },
]);
