export const histogramDygraphConfiguration = {
    drawPoints: false,
    showRoller: false,
    rightGap: 24,
    highlightCircleSize: 0,
    showLabelsOnHighlight: false,
    axisLineWidth: 0,
    axisLineColor: 'transparent',
    hideOverlayOnMouseOut: false,
    axes: {
        x: {
            drawGrid: false,
            labelsKMB: true,
        },
    },
    yAxisLabelWidth: 36,
    ylabel: 'Count',
    valueRange: [0, null],
    fillAlpha: 0.4,
};
