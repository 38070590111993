export default [
    '$http',
    'API_URL',
    '$rootScope',
    function ($http, API_URL, $rootScope) {
        const URL = API_URL + '/v2/aws/supportedNamespacesAndRegions';
        let namespaceAndRegionsPromise = null;
        let allRegionsPromise = null;

        function getSupportedNamespacesAndRegions() {
            if (!namespaceAndRegionsPromise) {
                namespaceAndRegionsPromise = $http.get(URL);
            }
            return namespaceAndRegionsPromise;
        }

        function isRegionSelectedByUser(userSelection, regionCodes) {
            return _.intersection(userSelection, regionCodes).length > 0;
        }

        function constructUserChoiceObject(userSelection, regions) {
            const result = [];
            Object.keys(userSelection).forEach(function (code) {
                if (userSelection[code]) {
                    const name = regions[code];
                    result.push({
                        code: code,
                        name: name,
                    });
                }
            });
            return result;
        }

        function getAllRegions() {
            if (!allRegionsPromise) {
                allRegionsPromise = getSupportedNamespacesAndRegions().then(function (result) {
                    return angular.extend(
                        {},
                        result.data.regions,
                        result.data.govcloud,
                        result.data.china
                    );
                });
            }
            return allRegionsPromise;
        }

        function clearData() {
            namespaceAndRegionsPromise = null;
            allRegionsPromise = null;
        }

        // Note: This will in turn invoke the namespaces and regions promise
        getAllRegions();

        $rootScope.$on('current user changed', clearData);

        return {
            getSupportedNamespacesAndRegions,
            isRegionSelectedByUser,
            constructUserChoiceObject,
            getAllRegions,
        };
    },
];
