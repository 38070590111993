import templateUrl from './publishConfigurations.tpl.html';

angular
    .module('signalview.chartV2')
    .directive('publishConfigurations', [
        '$timeout',
        function ($timeout) {
            return {
                restrict: 'E',
                scope: {
                    plot: '=',
                    scrollOnExpand: '<',
                    disabledFields: '<',
                },
                templateUrl,
                controller: 'publishConfigurationsCtrl',
                link: function ($scope, elem) {
                    $scope.scrollSelfIntoView = function () {
                        $timeout(
                            function () {
                                elem[0].scrollIntoView(true);
                            },
                            0,
                            true
                        );
                    };
                },
            };
        },
    ])
    .controller('publishConfigurationsCtrl', [
        '$scope',
        'PLOT_OPTION_STRINGS',
        function ($scope, PLOT_OPTION_STRINGS) {
            $scope.setYAxis = function (axisIndex) {
                $scope.plot.yAxisIndex = axisIndex;
            };

            $scope.applyUnitChanges = function (unitType, prefix, suffix) {
                if (unitType) {
                    $scope.plot.configuration.unitType = unitType;
                    $scope.plot.configuration.prefix = '';
                    $scope.plot.configuration.suffix = '';
                } else if (prefix || suffix) {
                    $scope.plot.configuration.unitType = null;
                    $scope.plot.configuration.prefix = prefix;
                    $scope.plot.configuration.suffix = suffix;
                } else {
                    $scope.plot.configuration.unitType = null;
                    $scope.plot.configuration.prefix = '';
                    $scope.plot.configuration.suffix = '';
                }
            };

            $scope.PLOT_OPTION_STRINGS = PLOT_OPTION_STRINGS;

            $scope.toggleExpandCollapse = function () {
                $scope.expanded = !$scope.expanded;
                if ($scope.scrollOnExpand !== false && $scope.expanded) {
                    $scope.scrollSelfIntoView();
                }
            };
        },
    ]);
