import templateUrl from './editor.tpl.html';

angular.module('signalview.detector.signal').directive('detectorSignalEditor', [
    'chartbuilderUtil',
    'detectorUtils',
    'config',
    '$timeout',
    'validateSignalDeletion',
    'timepickerUtils',
    function (
        chartbuilderUtil,
        detectorUtils,
        config,
        $timeout,
        validateSignalDeletion,
        timepickerUtils
    ) {
        return {
            restrict: 'E',
            scope: {
                detector: '=',
                currentRuleInEdit: '=?',
                onCancel: '&onCancel',
                disableBrowse: '=?',
                selectedSignal: '=?',
                compoundSelected: '=?',
                isModal: '=?',
                compoundConditionAvailable: '=?',
            },
            templateUrl,
            link: function ($scope) {
                $scope.selectSignalMode = angular.isDefined($scope.selectedSignal);
                $scope.unreleasedFeatures = !!config('superpower.unreleasedFeatures');
                $scope.isNew = !$scope.detector.sf_id;
                $scope.altMode = false;

                chartbuilderUtil.createTransientIfNeeded($scope.detector.sf_uiModel);

                $scope.sharedState = {
                    editMode: true,
                };

                function updateVisibility() {
                    $scope.allVisible = $scope.detector.sf_uiModel.allPlots.every((plot) => {
                        return (
                            !plot.invisible || plot.transient || plot.name === $scope.detector.sf_id
                        );
                    });
                }

                updateVisibility();

                //in order to make it easier for tree elements to access utility methods, pass them together
                // in the passedUtils object
                $scope.passedUtils = {
                    removePlot: function (plot) {
                        chartbuilderUtil.removePlot(plot, $scope.detector.sf_uiModel.allPlots);
                    },
                    addYAxis: function (idx) {
                        chartbuilderUtil.addYAxis(
                            idx,
                            $scope.detector.sf_uiModel.chartconfig.yAxisConfigurations
                        );
                    },
                    clonePlotConstruct: function (itm) {
                        chartbuilderUtil.clonePlotConstruct(
                            itm,
                            $scope.detector.sf_uiModel,
                            $scope.detector.sf_id
                        );
                    },
                    addPlotNoModal: function () {
                        chartbuilderUtil.addNewPlot($scope.detector.sf_uiModel);
                    },
                };

                $scope.isValidSignal = function (plot) {
                    if (plot.type === 'ratio') {
                        return plot.valid;
                    } else if (plot.type === 'plot') {
                        return !!plot.seriesData.metric;
                    } else {
                        return false;
                    }
                };

                $scope.validateSignalDeletion = function (plot) {
                    return validateSignalDeletion(
                        plot,
                        $scope.detector.sf_uiModel.allPlots,
                        $scope.detector.sf_uiModel.rules.filter(function (rule) {
                            return rule.uniqueKey !== ($scope.currentRuleInEdit || {}).uniqueKey;
                        })
                    );
                };

                function getValidPlots() {
                    return $scope.detector.sf_uiModel.allPlots.filter(function (signal) {
                        return $scope.isValidSignal(signal);
                    });
                }

                $scope.$watch('detector.sf_programText', function (newVal, oldVal) {
                    if (newVal === oldVal) return;
                    $scope.validPlots = getValidPlots().length;
                    $scope.signalEditor.$setDirty();

                    detectorUtils.updateDetectorValidityState(
                        $scope.detector.sf_uiModel.rules,
                        $scope.detector.sf_uiModel.allPlots
                    );
                });

                $scope.cancel = function () {
                    $scope.onCancel();
                };
                $timeout(function () {
                    $scope.$emit('resendPlotInformation');
                }, 0);

                $scope.$on('update plot state', updateVisibility);

                // Received from a plotdisplay directive
                $scope.$on(
                    'plot alt mode apply',
                    function (evt, altModeDisplayedKey, altModeOn = false) {
                        $scope.altMode = altModeOn;
                    }
                );

                function toggleAllVisibility(invisibility) {
                    $scope.detector.sf_uiModel.allPlots.forEach((plot) => {
                        if (!plot.transient) {
                            plot.invisible = invisibility;
                        }
                    });
                }

                $scope.toggleAll = function (makeAllVisible) {
                    toggleAllVisibility(!makeAllVisible);
                    $scope.allVisible = makeAllVisible;
                };

                $scope.getCurrentTimeRange = function () {
                    return timepickerUtils.getCurrentTimeRangeForChartConfig(
                        $scope.detector.sf_uiModel.chartconfig
                    );
                };
            },
        };
    },
]);
