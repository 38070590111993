angular
    .module('signalview.heatmap')
    .value('INACTIVE_HOST_DURATIONS', {
        AUTO: -1,
        FIVE_MINS: 1000 * 60 * 5,
        TEN_MINS: 1000 * 60 * 10,
        TWENTY_MINS: 1000 * 60 * 20,
        THIRTY_MINS: 1000 * 60 * 30,
    })
    .service('heatmapUserPreferences', [
        '$rootScope',
        '$log',
        'currentUser',
        'navigatorModes',
        'INACTIVE_HOST_DURATIONS',
        function ($rootScope, $log, currentUser, navigatorModes, INACTIVE_HOST_DURATIONS) {
            function updatePreferences(patch) {
                if (!angular.isObject(patch)) {
                    throw new Error('A preferences patch is required.');
                }

                return currentUser
                    .preferences()
                    .then(function (preferences) {
                        $log.info('Updating user preferences to set navigator mode to: ', patch);

                        patch.sf_id = preferences.sf_id;

                        return currentUser.updatePreferences(patch);
                    })
                    .then(function () {
                        $rootScope.$emit('update preferences');
                    })
                    .catch(function (e) {
                        $log.error('Unable to update navigator mode', e);
                    });
            }

            function getPreference(key) {
                return currentUser.preferences().then(function (preferences) {
                    return preferences[key];
                });
            }

            function mode(_) {
                if (!arguments.length) {
                    return getPreference('sf_navigatorMode').then(function (id) {
                        let match = navigatorModes.get(id);
                        if (!match) match = navigatorModes.get('collectd hosts');
                        return match;
                    });
                }

                const modeObject = navigatorModes.get(_);

                if (!modeObject) {
                    throw new Error('Invalid heatmap mode: ' + _);
                }

                return updatePreferences({
                    sf_navigatorMode: _,
                });
            }

            function hideDeadHosts(_) {
                if (!arguments.length) {
                    return getPreference('sf_navigatorHideDeadHosts');
                }

                return updatePreferences({
                    sf_navigatorHideDeadHosts: _,
                });
            }

            function inactiveHostDuration(_) {
                if (!arguments.length) {
                    return getPreference('sf_navigatorInactiveHostDuration').then(function (
                        duration
                    ) {
                        return parseInt(duration || INACTIVE_HOST_DURATIONS.AUTO);
                    });
                }

                return updatePreferences({
                    sf_navigatorInactiveHostDuration: '' + _, // coerce all property values to strings, due to ES indexing
                });
            }

            return {
                mode: mode,
                hideDeadHosts: hideDeadHosts,
                inactiveHostDuration: inactiveHostDuration,
            };
        },
    ]);
