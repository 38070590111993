angular.module('signalflowv2').service('expressionTrimmer', [
    function () {
        function parensBalanced(expr) {
            let depth = 0;
            for (let i = 0; i < expr.length; i++) {
                const char = expr.charAt(i);
                if (char === '(') {
                    depth++;
                } else if (char === ')') {
                    depth--;
                    if (depth < 0) {
                        return false;
                    }
                }
            }
            return depth === 0;
        }

        function trimParenthesesIfPossible(expressionText) {
            let lastGood = expressionText;
            let trimmed = expressionText.substring(1, expressionText.length - 1);
            while (
                lastGood.charAt(0) === '(' &&
                lastGood.charAt(lastGood.length - 1) === ')' &&
                parensBalanced(trimmed)
            ) {
                lastGood = trimmed;
                trimmed = trimmed.substring(1, trimmed.length - 1);
            }
            return lastGood;
        }

        return {
            parensBalanced: parensBalanced,
            trimParenthesesIfPossible: trimParenthesesIfPossible,
        };
    },
]);
