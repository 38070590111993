angular.module('signalview.page').controller('RemoveDashboardFromGroupController', [
    '$location',
    '$scope',
    'dashboardGroupService',
    'params',
    'dashboardUtil',
    'recentPagesService',
    'urlOverridesService',
    function (
        $location,
        $scope,
        dashboardGroupService,
        params,
        dashboardUtil,
        recentPagesService,
        urlOverridesService
    ) {
        $scope.parentPage = params.parentPage;
        $scope.dashboard = params.dashboard;

        $scope.remainingGroups = [];

        params.allConfigs.then((allConfigs) => {
            $scope.currentConfig = dashboardUtil.getConfig(allConfigs, params.configId);
            buildRemainingGroupStrings(
                allConfigs.filter((config) => config.configId !== params.configId)
            );
        });

        function buildRemainingGroupStrings(configs) {
            configs.forEach((config) => {
                $scope.remainingGroups.push({
                    displayString: buildGroupString(config),
                    configId: config.configId,
                    groupId: config.groupId,
                });
            });
        }

        function buildGroupString(config) {
            return `${config.nameOverride || $scope.dashboard.name} (in ${config.groupName})`;
        }

        $scope.remove = function () {
            return dashboardGroupService
                .removeDashboardConfig($scope.parentPage.id, params.configId)
                .then(() => {
                    return recentPagesService
                        .removeSpecificRecentEntryOfDashboard(params.configId)
                        .then(() => {
                            $scope.$parent.$broadcast('removed config', params.configId);
                            // TODO(trevor): Remove sf_id when v1 hierarchy gets removed after v2 chart migrations are finished
                            $location.url(
                                `/page/${$scope.parentPage.id || $scope.parentPage.sf_id}`
                            );
                            return $scope.$close();
                        });
                });
        };

        $scope.navigateToDashboardInContext = function (config) {
            urlOverridesService.clearAllNonLocationUrlParams();
            dashboardUtil.addDashboardSearchParams({
                groupId: config.groupId,
                configId: config.configId,
            });
            $scope.$dismiss();
        };
    },
]);
