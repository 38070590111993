angular
    .module('signalview.chartV2')

    .service('v2ChartAPIWrapper', [
        '$http',
        'API_URL',
        'currentUser',
        'mustache',
        'localStorage',
        'uiModelToVisualizationOptionsService',
        function (
            $http,
            API_URL,
            currentUser,
            mustache,
            localStorage,
            uiModelToVisualizationOptionsService
        ) {
            const v2chartendpoint = '/v2/chart';

            function getCleanV2Chart(chart) {
                const chartCopy = angular.copy(chart);
                delete chartCopy.sf_modelVersion;
                delete chartCopy.sf_chartIndex;
                return chartCopy;
            }
            //provides name-normalized getters for pulling various fields off the detector response
            return function (chart) {
                if (!chart) {
                    chart = {
                        name: '',
                        programText: '',
                    };
                }

                const chartObject = {
                    chart: function () {
                        return chart;
                    },
                    getCUrl: function () {
                        return currentUser.orgId().then(function (orgId) {
                            let str = mustache.render(
                                'curl -X ' +
                                    (chart.id ? 'PUT' : 'POST') +
                                    ' "' +
                                    API_URL +
                                    '/v2/chart' +
                                    (chart.id ? '/' + chart.id : '') +
                                    '?organizationId={{ORG}}" -H "X-SF-TOKEN: {{TOKEN}}" -H "Content-Type: application/json;charset=UTF-8"',
                                {
                                    ORG: orgId,
                                    TOKEN: localStorage['X-SF-TOKEN'],
                                }
                            );
                            str += ' -d "' + angular.toJson(chart).replace(/"/g, '\\"') + '"';
                            return str;
                        });
                    },
                    save: function () {
                        //TODO : use v2 wrapper?
                        return currentUser.orgId().then(function (orgId) {
                            const chartToSave = getCleanV2Chart(chart);
                            if (!chartToSave.id) {
                                return $http.post(
                                    API_URL + v2chartendpoint + '?organizationId=' + orgId,
                                    chartToSave
                                );
                            } else {
                                return $http.put(
                                    API_URL +
                                        v2chartendpoint +
                                        '/' +
                                        chartToSave.id +
                                        '?organizationId=' +
                                        orgId,
                                    chartToSave
                                );
                            }
                        });
                    },
                    saveAs: function () {
                        const chartCopy = getCleanV2Chart(chart);
                        delete chartCopy.id;
                        return currentUser.orgId().then(function (orgId) {
                            return $http.post(
                                API_URL + v2chartendpoint + '?organizationId=' + orgId,
                                chartCopy
                            );
                        });
                    },
                    clone: function (chartIndex) {
                        return currentUser.orgId().then(function (orgId) {
                            if (chart.sloId) {
                                return $http.post(API_URL + v2chartendpoint + '/createSloChart', {
                                    sloId: chart.sloId,
                                });
                            }
                            return $http.post(
                                API_URL + v2chartendpoint + '/_/clone?organizationId=' + orgId,
                                {
                                    chart: chart,
                                    chartIndex: chartIndex,
                                }
                            );
                        });
                    },
                    isEphemeral: function () {
                        return !chart.id;
                    },
                    getId: function () {
                        return chart.id;
                    },
                    getName: function () {
                        return chart.name;
                    },
                    setName: function (name) {
                        chart.name = name;
                    },
                    getDescription: function () {
                        return chart.description;
                    },
                    setDescription: function (description) {
                        chart.description = description;
                    },
                    getSignalFlow: function () {
                        return chart.programText;
                    },
                    setSignalFlow: function (signalflow) {
                        chart.programText = signalflow;
                    },
                    updateVisualizationOptions: function (uiModel) {
                        chart.options = uiModelToVisualizationOptionsService(uiModel).serialize();
                    },
                    getVisualizationOptions: function () {
                        return chart.options;
                    },
                };
                return chartObject;
            };
        },
    ]);
