'use strict';
import templateUrl from './tabby.html';

export default {
    templateUrl,
    bindings: {
        selected: '<',
        onSelect: '<',
    },
    transclude: true,
    controller: [
        '$q',
        '$log',
        '$timeout',
        function ($q, $log, $timeout) {
            const $ctrl = this;

            $ctrl.$onInit = function () {
                $ctrl.tabs = [];
                $timeout(function () {
                    selectByTitle($ctrl.selected);
                });
            };

            function noExitGuard() {
                return $q.when();
            }

            let exitGuard = noExitGuard;

            $ctrl.enableExitGuard = function (guard) {
                exitGuard = guard;
            };

            $ctrl.disableExitGuard = function () {
                exitGuard = noExitGuard;
            };

            $ctrl.select = function (tab) {
                exitGuard().then(function () {
                    exitGuard = noExitGuard;
                    $ctrl.tabs.forEach((p) => (p.selected = false));
                    tab.selected = true;

                    if (typeof $ctrl.onSelect === 'function') {
                        $ctrl.onSelect(tab.title, tab);
                    }
                });
            };

            $ctrl.addPane = function (tab) {
                $ctrl.tabs.push(tab);
                if ($ctrl.tabs.length === 1) {
                    $ctrl.select(tab);
                }
            };

            function selectByTitle(title) {
                const toSelect = $ctrl.tabs.filter((t) => t.title === title)[0];
                if (!toSelect) {
                    $log.warn('Unable to find tab: ' + title);
                } else {
                    $ctrl.select(toSelect);
                }
            }
        },
    ],
};
