import templateUrl from './infoSidebar.tpl.html';

export default {
    templateUrl,
    bindings: {
        onHideSidebar: '&',
        selectedTabDisplayName: '<?',
        onHideSidebarClick: '<?',
        displayAnalyzer: '<',
        time: '<',
    },
    controller: [
        '$rootScope',
        '$scope',
        'CHART_DISPLAY_EVENTS',
        'URL_PARAMETER_CONSTANTS',
        'urlOverridesService',
        'infoSidebarUtil',
        'kubeDataService',
        '$timeout',
        'featureEnabled',
        'userAnalytics',
        function (
            $rootScope,
            $scope,
            CHART_DISPLAY_EVENTS,
            URL_PARAMETER_CONSTANTS,
            urlOverridesService,
            infoSidebarUtil,
            kubeDataService,
            $timeout,
            featureEnabled,
            userAnalytics
        ) {
            const $ctrl = this;

            $ctrl.hasKubernetesInsights = featureEnabled('kubernetesInsights');

            const allTabs = {
                clusterMapAnalyzer: {
                    title: 'Analyzer',
                    tabId: 'clusterMapAnalyzer',
                },
                info: {
                    title: 'Info',
                    tabId: 'info',
                },
            };
            const getAnalyzerPanelEventLabel = (isSidebarOpen) =>
                isSidebarOpen ? 'Sidebar_Open' : 'Sidebar_Close';

            $ctrl.selectedTabId = null;

            $ctrl.$onInit = initSidebar;
            $ctrl.setSelected = setSelected;
            $ctrl.toggleSidebar = toggleSidebar;

            function initSidebar() {
                // This will default to true if not explicitly marked closed
                $ctrl.isSidebarOpen = !urlOverridesService.isInfoSidebarHidden();

                setupTabs();
                bindEvents();

                if ($ctrl.isSidebarOpen) {
                    $timeout(emitResize);
                }
            }

            function bindEvents() {
                $ctrl.stopTracking = $scope.$on('$locationChangeStart', setupTabs);
            }

            function setupTabs() {
                $ctrl.tabs = {};

                if ($ctrl.displayAnalyzer && $ctrl.hasKubernetesInsights) {
                    $ctrl.tabs[allTabs.clusterMapAnalyzer.tabId] = allTabs.clusterMapAnalyzer;
                }

                const availableInfoPanel = infoSidebarUtil.getInfoPanelsForSidebarURLParams();
                openSidebarBasedOnInfoTab(availableInfoPanel.length <= 0);

                if (availableInfoPanel.length) {
                    $ctrl.tabs[allTabs.info.tabId] = allTabs.info;
                }
                $ctrl.hasSidebarTabs = Object.keys($ctrl.tabs).length > 0;

                const selectedTabId = infoSidebarUtil.getTabSelected();
                setSelected(selectedTabId);
            }

            function setSelected(tabId) {
                const defaultSelection = !tabId;

                // Open Info tab if available and no selection is provided
                if (defaultSelection && $ctrl.tabs[allTabs.info.tabId]) {
                    tabId = allTabs.info.tabId;
                }

                if (tabId === allTabs.info.tabId) {
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                }

                Object.values($ctrl.tabs).forEach((tab, idx) => {
                    tab.selected = (!tabId && idx === 0) || tabId === tab.tabId;
                    if (tab.selected) {
                        $ctrl.selectedTabId = tab.tabId;

                        // Set URL state, only if explicitly selected
                        if (!defaultSelection) {
                            infoSidebarUtil.setTabSelected($ctrl.selectedTabId);
                        }
                    }
                });
            }

            function toggleSidebar() {
                $ctrl.isSidebarOpen = !$ctrl.isSidebarOpen;

                const infoSidebarInfoRefType = urlOverridesService.getSearchParam(
                    URL_PARAMETER_CONSTANTS.infoSidebarInfoTabRefType
                );
                const getEntityClickEventLabel =
                    infoSidebarInfoRefType &&
                    infoSidebarUtil.INFO_PANELS[infoSidebarInfoRefType].getEntityClickEventLabel;

                updateSidebarUrlState();
                // Resize charts in the main panel
                emitResize();
                if ($ctrl.isSidebarOpen && $ctrl.selectedTabId === allTabs.info.tabId) {
                    // Reinitialize charts in the sidebar because dygraphs height and width get resized to 0 when they are hidden
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                }
                const isSidebarOpen = $ctrl.isSidebarOpen;
                $ctrl.onHideSidebar({ isSidebarOpen });
                $ctrl.onHideSidebarClick && $ctrl.onHideSidebarClick();

                const eventCategorySuffix = $ctrl.selectedTabDisplayName;
                const eventCategory =
                    kubeDataService.getDataViewK8sNavEventCategory(eventCategorySuffix);
                const eventLabel =
                    $ctrl.selectedTabDisplayName === 'Map'
                        ? getAnalyzerPanelEventLabel($ctrl.isSidebarOpen)
                        : (getEntityClickEventLabel &&
                              getEntityClickEventLabel($ctrl.isSidebarOpen)) ||
                          null;
                userAnalytics.event(eventCategory, 'click', null, eventLabel);
            }

            function updateSidebarUrlState() {
                urlOverridesService.hideInfoSidebar(!$ctrl.isSidebarOpen);
            }

            function emitResize() {
                $rootScope.$emit('resize');
            }

            function openSidebarBasedOnInfoTab(hasNoInfoPanel) {
                // if infoSidebarHidden is not in the url (which is put there when a user manually closes the sidebar)
                // if there are no info panels, close the sidebar by default otherwise the sidebar was closed by default
                // and needs to be opened since info panels are available
                const infoSidebarHidden = urlOverridesService.isInfoSidebarHidden();
                if (!infoSidebarHidden) {
                    let isSidebarOpen = true;
                    if (hasNoInfoPanel) {
                        isSidebarOpen = false;
                    }
                    $ctrl.isSidebarOpen = isSidebarOpen;
                    $ctrl.onHideSidebar({ isSidebarOpen });
                }
            }
        },
    ],
};
