import templateUrl from './typeaheadDropDownMultiSelect.tpl.html';

angular
    .module('signalview.typeahead')

    .directive('typeaheadDropDownMultiSelect', [
        '$timeout',
        '$window',
        'typeaheadUtils',
        function ($timeout, $window, typeaheadUtils) {
            return {
                restrict: 'E',
                terminal: true,
                scope: {
                    getSuggestions: '=',
                    onItemSelected: '=',
                    typedValue: '=?',
                    reset: '=?',
                    inputClass: '@?',
                    skipAutoResize: '=?',
                    skipRefocus: '@?',
                    skipAutoFocus: '@?',
                    keepTypedValue: '@?',
                    filterTitle: '@?',
                    modelChangeType: '@',
                    multiSelectable: '=',
                    toggleSelection: '=',
                    isSelected: '=',
                    numFilters: '=',
                    inFiltersContext: '=',
                    clearClientState: '=',
                    matchingDimensions: '=?',
                    showFilterIcon: '=?',
                    placeHolder: '@?',
                    removeLastFilter: '=?',
                    isVariable: '@?',
                    onEditBlur: '=?',
                    onSuggestionsBlur: '=?',
                    getValues: '=?',
                    dropDownTabIndex: '<?',
                    labelId: '@?',
                    disabled: '<?',
                    announceSuggestionsCount: '@?',
                },
                templateUrl,
                controller: 'TypeaheadDropDownController',
                link: function ($scope, elem) {
                    if (!$scope.suggestionsAlignment) {
                        $scope.suggestionsAlignment = 'left';
                    }
                    $scope.maxWidth = undefined;
                    $scope.skipRefocus = true;
                    $scope.valueSearch = false;
                    $scope.suggestionsInFocus = false;
                    $scope.showSelectedOnly = false;
                    $scope.placeholder = $scope.placeHolder || 'Optional';
                    if ($scope.typedValue === undefined) {
                        $scope.typedValue = '';
                    }
                    $scope.results = null;
                    $scope.keySelectionIndex = null;
                    const inp = angular.element('.typeahead-input', elem);
                    const dropDown = angular.element('.suggestions-container', elem);

                    $scope.refocusInput = function () {
                        blurSuggestions();
                        inp.focus();
                    };

                    $scope.onEnterInput = function () {
                        $scope.inInputContext = true;
                    };

                    $scope.onLeaveInput = function () {
                        $scope.inInputContext = false;
                    };

                    $scope.onEnterSuggestions = function () {
                        $scope.inSuggestionsContext = true;
                    };

                    $scope.onLeaveSuggestions = function () {
                        $scope.inSuggestionsContext = false;
                    };

                    /**
                     * The special case of auto suggest key selected and value suggestions show up,
                     * clicking on the suggestion would cause input to blur and inSuggestionsContext
                     * was not kept because the mouse did not leave but it was intentionally set to false.
                     *
                     * When suggestion shows up, mouse enter event doesn't happen again
                     * (inSuggestionsContext flag remains flase) and clicking
                     * on the item caused the blur on input and suggest is not accepted.
                     */
                    $scope.onMouseDown = function () {
                        $scope.inSuggestionsContext = true;
                    };

                    // clear all states
                    function blurInputInternal(e, userInput) {
                        blurSuggestions();
                        $scope.clearSuggestionsInternal(true, true);
                        if (!userInput) {
                            if ($scope.clearClientState) {
                                $scope.clearClientState();
                            }
                            inp.blur();
                        }
                    }

                    // blurs the input but clears suggestions
                    // only if focus is not in the dropdown menu
                    // and not in the sub-pills
                    $scope.blurInput = function (e) {
                        if (
                            $scope.suggestionsInFocus ||
                            $scope.inFiltersContext ||
                            $scope.inSuggestionsContext
                        ) {
                            return;
                        }
                        if ($scope.numFilters) {
                            $scope.applySelectedFilters(e);
                        } else {
                            blurInputInternal(e, true);
                        }
                        if ($scope.onEditBlur) {
                            $scope.onEditBlur();
                        }
                    };

                    // this is when the dropdown has focus for spacebar interaction
                    // and we need to close dropdown when focus is out of entire widget
                    // and accept filters if selected. if the filter pills are in focus
                    // then ignore this blur
                    $scope.blurSuggestionsContainer = function (e) {
                        blurSuggestions();
                        if ($scope.inFiltersContext || $scope.inInputContext) {
                            return;
                        }
                        if ($scope.numFilters) {
                            $scope.applySelectedFilters(e);
                        } else {
                            if ($scope.valueSearch) {
                                $scope.clearSuggestionsInternal(true);
                            }
                        }

                        if ($scope.onSuggestionsBlur) {
                            $scope.onSuggestionsBlur();
                        }
                    };

                    function blurSuggestions() {
                        $scope.suggestionsInFocus = false;
                        $scope.inSuggestionsContext = false;
                    }

                    // when input is in focus, we want to clear book-kept state
                    // and fetch suggestions
                    let ignoreFocus = false;
                    $scope.getInputSuggestions = function (userInput) {
                        if (ignoreFocus) {
                            ignoreFocus = false;
                            return;
                        }
                        blurSuggestions();
                        $scope.getSuggestionsInternal(userInput);
                    };

                    // the key selection index (set by controller,
                    // defines which suggested menu item is currently in focus)
                    // - when this index changes, we want to make sure the dropdown
                    // menu has focus so user can engage spacebar/enter interaction
                    // for multi-select ux
                    function focusSuggestion() {
                        const keySelectionAvailable =
                            angular.isNumber($scope.keySelectionIndex) &&
                            $scope.keySelectionIndex >= 0;
                        if (keySelectionAvailable && $scope.multiSelectable && !inp.is(':focus')) {
                            focusSuggestionInternal(true);
                        }
                    }

                    function focusSuggestionInternal(suggestionsInFocus) {
                        $scope.suggestionsInFocus = suggestionsInFocus;
                        if (suggestionsInFocus) {
                            dropDown.focus();
                        }
                    }

                    function isSpaceBar(e) {
                        return e.keyCode === 32;
                    }
                    function isReturnKey(e) {
                        return e.keyCode === 13;
                    }
                    function isBackspace(e) {
                        return e.keyCode === 8;
                    }
                    function isHorizontalTab(e) {
                        return e.keyCode === 9;
                    }
                    function isEscKey(e) {
                        return e.keyCode === 27;
                    }

                    function setDropDownAlignment() {
                        $timeout(function () {
                            const boundingRect = elem[0].getBoundingClientRect();
                            const left = boundingRect.left;
                            const screenWidth = $window.innerWidth;
                            const midWay = screenWidth / 2;
                            if (left < midWay) {
                                $scope.maxWidth = screenWidth - boundingRect.left;
                                $scope.suggestionsAlignment = 'left';
                            } else {
                                $scope.maxWidth = boundingRect.right;
                                $scope.suggestionsAlignment = 'right';
                            }
                        }, 0);
                    }

                    $scope.scrollIntoView = function (val) {
                        if (typeof val === 'number') {
                            const item = dropDown.find('[data-list-index="' + val + '"]')[0];
                            if (item && !isInViewPort(item)) item.scrollIntoView(false);
                        }
                    };

                    function isInViewPort(item) {
                        const itemElement = elem.find(item);
                        const elementTop = itemElement.position().top;
                        const elementBottom = elementTop + itemElement.outerHeight();
                        const scrollParent = itemElement.scrollParent();
                        const viewportTop = scrollParent.scrollTop();
                        const viewportBottom = viewportTop + scrollParent.height();
                        return elementBottom > viewportTop && elementTop < viewportBottom;
                    }

                    // if input has a key stroke,
                    // check for return key to commit selections in non-multi-select mode,
                    // or incrementally toggle that selection for multi-select mode,
                    // for all other key strokes, defer to controller
                    $scope.inputKeyPressed = function (e) {
                        $scope.valueSearch =
                            $scope.typedValue.indexOf(':') !== -1 || $scope.isVariable;
                        if (isEscKey(e)) {
                            $scope.consumeEvent(e);
                            blurInputInternal(e);
                        } else if (!$scope.valueSearch && isHorizontalTab(e)) {
                            $scope.keyPressInternal(e);
                            inp.focus();
                        } else if (isReturnKey(e) || isHorizontalTab(e)) {
                            if (!$scope.fetching && $scope.results.length > 0) {
                                $scope.applySelectedFilters(
                                    e,
                                    $scope.numFilters > 0
                                        ? ''
                                        : $scope.results[$scope.keySelectionIndex]
                                );
                            } else if (
                                $scope.typedValue.split(':').length > 1 &&
                                $scope.typedValue.split(':')[1]
                            ) {
                                const parsedPartial = typeaheadUtils.parsePartial(
                                    $scope.typedValue
                                );
                                $scope.applySelectedFilters(e, {
                                    type: 'property',
                                    propertyValue: parsedPartial.propertyValue,
                                    property: parsedPartial.propertyName,
                                    NOT: parsedPartial.not,
                                });
                            } else {
                                $scope.consumeEvent(e);
                            }
                        } else if (isBackspace(e) && $scope.numFilters && !$scope.typedValue) {
                            // input is empty and user has hit backspace when there are filters, delete last filter
                            if ($scope.removeLastFilter) $scope.removeLastFilter();
                        } else {
                            $scope.keyPressInternal(e);
                        }
                    };

                    // if a suggested menu item has a key stroke
                    // check for space bar to toggle selections,
                    // check for return key to commit selections in non-multi-select mode,
                    // or incrementally toggle that selection for multi-select mode,
                    // for all other key strokes, defer to controller
                    $scope.onSuggestedItemKeyPressed = function (e, result) {
                        if ($scope.multiSelectable && isSpaceBar(e)) {
                            $scope.onSuggestedItemChecked(e, result);
                        } else if (isReturnKey(e) || isHorizontalTab(e)) {
                            $scope.applySelectedFilters(e, $scope.numFilters > 0 ? '' : result);
                        } else if (isEscKey(e)) {
                            $scope.consumeEvent(e);
                            $scope.clearSuggestionsInternal(true);
                            ignoreFocus = true;
                            $scope.refocusInput();
                        } else {
                            $scope.keyPressInternal(e);
                        }
                    };

                    // add or remove item to/from list of multi-selected filters
                    $scope.onSuggestedItemChecked = function (e, checkedItem) {
                        $scope.consumeEvent(e);
                        focusSuggestionInternal(true);
                        $scope.toggleSelection(checkedItem);
                    };

                    // when an item is clicked, we want to add it to selections
                    // if already in multi-select mode, or commit selection
                    // if it's not in multi-select mode
                    $scope.onSuggestedItemClicked = function (e, result) {
                        if (result.action) {
                            if (!result.disabled) {
                                result.action();
                                $scope.clearSuggestionsInternal(true, true);

                                if (!$scope.keepTypedValue) {
                                    $scope.typedValue = '';
                                }
                            }
                        } else if ($scope.numFilters > 0) {
                            $scope.onSuggestedItemChecked(e, result);
                        } else {
                            $scope.applySelectedFilters(e, result);
                        }
                    };

                    // clean up dropdown focus state and multi-select enabled state
                    // based on passed in values set by client directive
                    $scope.cleanup = function (valueSearch) {
                        $scope.valueSearch = valueSearch;
                        $scope.suggestionsInFocus = false;
                    };

                    // when there are new results we want to clean up book-kept state
                    // of whether dropdown was in focus, etc.
                    // this is called by the controller when there are new results
                    $scope.onResults = function () {
                        $scope.cleanup($scope.multiSelectable);
                        setDropDownAlignment();
                    };

                    // clean up book-kept state and
                    // commit selections.
                    $scope.applySelectedFilters = function (e, result) {
                        const resultIsPropertyKey =
                            result &&
                            result.type === 'property' &&
                            result.propertyValue === undefined;
                        const valueSearch = $scope.valueSearch && !resultIsPropertyKey;
                        const isTag = result && result.type === 'tag';
                        $scope.cleanup();
                        if (typeof result === 'undefined') {
                            result = '';
                        }
                        if (result.propertyValue) {
                            result.propertyValue = result.propertyValue.toString().trim();
                        }
                        $scope.itemSelectedInternal(result);
                        if (valueSearch || isTag) {
                            $scope.consumeEvent(e);
                            $timeout(function () {
                                blurInputInternal(e, false);
                            }, 0);
                        }
                    };

                    $scope.toggleShowSelected = function (e) {
                        $scope.consumeEvent(e);
                        $scope.showSelectedOnly = !$scope.showSelectedOnly;
                        $scope.getSuggestionsInternal($scope.typedValue);
                    };

                    // clean up book-kept state and refocus input
                    function resetFocus(valueSearch) {
                        $scope.cleanup(valueSearch);
                        $scope.refocusInput();
                    }

                    // if user presses the up or down arrow, we need to ensure
                    // the dropdown menu has focus so user can engage spacebar to
                    // select or deselect multiple items
                    $scope.$watch('keySelectionIndex', function () {
                        focusSuggestion();
                    });

                    $scope.$watch('numFilters', function (nval, oval) {
                        // if we have a change in number of sub-pills because of addition
                        // or deletion, we need to go back to input focus so user
                        // can continue to search
                        // we don't want to do this if user is currently in the dropdown menu
                        // spacebar-ing on items
                        if (angular.isDefined(nval) && angular.isDefined(oval) && nval !== oval) {
                            if (nval > oval) {
                                focusSuggestionInternal(true);
                            } else if (!$scope.suggestionsInFocus && $scope.valueSearch) {
                                resetFocus($scope.multiSelectable);
                            }
                        }
                        if ($scope.numFilters) {
                            $scope.placeholder = '';
                        } else {
                            $scope.placeholder = $scope.placeHolder || 'Optional';
                        }
                        setDropDownAlignment();
                    });

                    $scope.$watch('matchingDimensions', function () {
                        let warning = '';
                        if ($scope.matchingDimensions && $scope.matchingDimensions.length > 1) {
                            // This is stuffed into ng-bootstrap's tooltip directive which doesn't allow templates to be passed in.
                            warning =
                                '<div style="text-align:left;">There are ' +
                                $scope.matchingDimensions.length +
                                ' sets of metrics which match the current filter: ';
                            warning +=
                                '<ul><li>' +
                                $scope.matchingDimensions.join('</li><li>') +
                                '</li></ul>';
                            warning +=
                                'Add additional filters to ensure only one set of metrics is used in this plot.</div>';
                        }
                        $scope.multipleMetricSetWarning = warning;
                    });
                },
            };
        },
    ]);
