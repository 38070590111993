'use strict';

import writepermissionsPermissionsChecker from './util/writepermissionsPermissionsChecker';
import hasCapability from './rbac/hasCapability';
import dashboardGroupUtil from './util/dashboardGroupUtil';
import realmInfo from './util/realmInfo';
import cookieHelper from './util/cookieHelper';
import getDataFacets from './util/getDataFacets.js';
import permissionsChecker from './util/permissionsChecker.js';
import rbacUtilsService from './rbac/rbacUtilsService';
import logService from './util/logService';
import 'angular-cookies';

angular
    .module('sfx.data', [
        'swagger-client',
        'support.supportService',
        'sfx.api.v2',
        'sfx.external',
        'ngCookies',
    ])
    .service('writepermissionsPermissionsChecker', writepermissionsPermissionsChecker)
    .service('hasCapability', hasCapability)
    .service('logService', logService)
    .service('rbacUtilsService', rbacUtilsService)
    .service('dashboardGroupUtil', dashboardGroupUtil)
    .service('realmInfo', realmInfo)
    .service('cookieHelper', cookieHelper)
    .service('getDataFacets', getDataFacets)
    .service('permissionsChecker', permissionsChecker)
    .constant('window', window)
    .constant('localStorage', window.localStorage)
    .constant('sessionStorage', window.sessionStorage)
    .constant('BUILT_IN_METRICS', ['demo.lb.*', 'demo.trans.*', 'sf.*', 'ui.*'])
    .run([
        '$rootScope',
        '$log',
        'sfxApi',
        'currentUser',
        'credentialV2Service',
        'realmInfo',
        'auth',
        function ($rootScope, $log, sfxApi, currentUser, credentialV2Service, realmInfo, auth) {
            $rootScope.$on('current organization changed', function (event, organization) {
                $log.info(
                    `Current organization set to ${organization.organizationName} (${organization.id})`
                );
                credentialV2Service.clearCache();
            });

            $rootScope.$on('current user changed', function (event, user) {
                $log.info('Current user changed to', user.sf_user);
                currentUser.clearUserCache();
            });

            function onTokenChanged() {
                currentUser.clearUserCache();
                realmInfo.set();
                sfxApi.authorization(auth.authToken());
            }

            $rootScope.$on('migrated services initialized', function () {
                auth.onAuth(onTokenChanged);
                auth.onDeAuth(onTokenChanged);
            });
        },
    ]);
