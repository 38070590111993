import templateUrl from './textChart.tpl.html';

export const textChart = {
    templateUrl,
    bindings: {
        chartModel: '<',
        hideTitle: '<',
        isPreview: '<',
        openHref: '<',
    },
    controller: [
        'chartLoadedEvent',
        function (chartLoadedEvent) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            function $onInit() {
                chartLoadedEvent();
            }
        },
    ],
};
