import { useCallback, useEffect, useRef, useState } from 'react';
import { AngularInjector } from '../../../common/AngularUtils';
import { isNonLegacy } from './subscriptionTypeUtil';
import { CategoryLimit, CategoryLimits } from './categoryLimits';
import { GlobalNotification, NotificationCategory, NotificationService } from '@splunk/olly-common';
import { PRODUCT_NAME, SUPPORT_EMAIL } from '../../../legacy/common/consts';
import { Organization, OverQuotaWarnings } from '@splunk/olly-services';
import { Auth } from '@splunk/olly-services/lib/services/Auth/AuthStore';
import { isEqual } from 'lodash';
import { useIsAuthenticatedPath } from '../../../common/security/pathValidateService';
import type { History } from 'history';
import { noop } from 'lodash';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

const SubscriptionOverageCategory = 'immSubscriptionOverage';
const TokenOverageCategory = 'immTokenOverage';

function getLimitCategoryOverageNotification(
    categoryName: string,
    supportSwitch: boolean
): GlobalNotification {
    const SUPPORT_LABEL = supportSwitch ? 'access the ' : 'contact ';
    return {
        type: 'warning',
        category: NotificationCategory.GLOBAL,
        subCategory: SubscriptionOverageCategory,
        message:
            `${PRODUCT_NAME} is monitoring more than the maximum number of ${categoryName.toLowerCase()} specified in your organization's subscription.
        Data point ingestion is being limited. For help, ` + SUPPORT_LABEL,
        callToAction: {
            link: supportSwitch
                ? 'https://quickdraw.splunk.com/redirect/?product=Observability&location=o11y-app-support&version=current'
                : `mailto:${SUPPORT_EMAIL}`,
            ctaString: supportSwitch ? 'Splunk Support Portal' : SUPPORT_EMAIL,
            openInNewContext: true,
        },
    };
}

//loop through limits in priority order find first category over limit
function setOverageType(categories: Record<number, boolean>): CategoryLimit | undefined {
    return CategoryLimits.find((category) => categories[category.value]);
}

const getDPMOverageNotification = (
    org: Organization,
    isNonLegacyOrg: boolean,
    supportSwitch: boolean
): GlobalNotification => {
    const SUPPORT_LABEL = supportSwitch ? 'access the ' : 'contact ';
    return isNonLegacyOrg
        ? {
              type: 'warning',
              category: NotificationCategory.GLOBAL,
              subCategory: SubscriptionOverageCategory,
              message:
                  `${PRODUCT_NAME} detected unusual metric submission patterns for your organization.
              To ensure that your data continues to be processed and stored, ` + SUPPORT_LABEL,
              callToAction: {
                  link: supportSwitch
                      ? 'https://quickdraw.splunk.com/redirect/?product=Observability&location=o11y-app-support&version=current'
                      : `mailto:${SUPPORT_EMAIL}`,
                  ctaString: supportSwitch ? 'Splunk Support Portal' : SUPPORT_EMAIL,
                  openInNewContext: true,
              },
          }
        : {
              type: 'warning',
              category: NotificationCategory.GLOBAL,
              subCategory: SubscriptionOverageCategory,
              message:
                  `Your ${PRODUCT_NAME} account exceeded its subscription and is being limited.
              Only data points associated with existing metric time series continue to be processed and stored. For help, ` +
                  SUPPORT_LABEL,
              callToAction: {
                  link: supportSwitch
                      ? 'https://quickdraw.splunk.com/redirect/?product=Observability&location=o11y-app-support&version=current'
                      : `mailto:${SUPPORT_EMAIL}`,
                  ctaString: supportSwitch ? 'Splunk Support Portal' : SUPPORT_EMAIL,
                  openInNewContext: true,
              },
          };
};

const getTokenOverageNotification = (): GlobalNotification => {
    return {
        type: 'warning',
        category: NotificationCategory.GLOBAL,
        subCategory: TokenOverageCategory,
        message: `An access token in your organization exceeded a usage limit.
        Integrations that use this access token might be unable to send data to Infrastructure Monitoring.`,
        callToAction: {
            link: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=manage.ingest.limits&version=current',
            ctaString: 'Learn more.',
            openInNewContext: true,
        },
    };
};

export const useAccountLimitOverageNotifier = (history: History, auth: Auth): void => {
    const overQuotaNotificationService = AngularInjector.useInjectedClass(
        'overQuotaNotificationService'
    );
    const organizationService = AngularInjector.useInjectedClass('organizationService');
    const currentUser = AngularInjector.useInjectedClass('currentUser');
    const featureEnabled = AngularInjector.useInjectedClass('featureEnabled');
    const supportSwitch = featureEnabled('supportSwitch');
    const isNewRbacExperienceEnabled = featureEnabled('newRbacExperience');
    const isAuthenticatedPath = useIsAuthenticatedPath(history);
    const [isNonLegacyOrg, setNonLegacyOrg] = useState(false);
    const [hasReadTokenCapability, setHasReadTokenCapability] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [org, setOrg] = useState<Organization>();

    const overageWarnings = useRef<OverQuotaWarnings>();

    const handleQuotaStatusUpdate = useCallback(
        (warnings: OverQuotaWarnings): void => {
            if (!org || isEqual(warnings, overageWarnings.current)) {
                return;
            }

            if (isAdmin && !isEqual(warnings.org, overageWarnings.current?.org)) {
                NotificationService.clear({
                    category: NotificationCategory.GLOBAL,
                    subCategory: SubscriptionOverageCategory,
                });

                if (warnings?.org?.overDpmLimit) {
                    NotificationService.post({
                        ...getDPMOverageNotification(org, isNonLegacyOrg, supportSwitch),
                        onRemove: noop,
                    });
                }

                if (warnings.org?.categoryLimits) {
                    const limitCategory = setOverageType(warnings.org.categoryLimits);

                    if (limitCategory) {
                        NotificationService.post({
                            ...getLimitCategoryOverageNotification(
                                limitCategory.name,
                                supportSwitch
                            ),
                            onRemove: noop,
                        });
                    }
                }
            }

            if (
                !isEqual(warnings.token, overageWarnings.current?.token) &&
                hasReadTokenCapability
            ) {
                NotificationService.clear({
                    category: NotificationCategory.GLOBAL,
                    subCategory: TokenOverageCategory,
                });

                if (warnings.token?.overTokenLimit) {
                    NotificationService.post({ ...getTokenOverageNotification(), onRemove: noop });
                }
            }

            overageWarnings.current = warnings;
        },
        [hasReadTokenCapability, isAdmin, isNonLegacyOrg, org, supportSwitch]
    );

    useEffect(() => {
        if (isAuthenticatedPath) {
            Promise.all([
                organizationService.get(),
                currentUser.isAdmin(),
                currentUser.hasCapabilities([Capability.READ_NAMEDTOKEN]),
            ]).then(([org, admin, hasReadTokenCapability]: [Organization, boolean, boolean]) => {
                setOrg(org);
                setIsAdmin(admin);
                setNonLegacyOrg(isNonLegacy((org as any).accountSubscriptionType));
                hasReadTokenCapability = isNewRbacExperienceEnabled
                    ? isNewRbacExperienceEnabled
                    : admin;
                setHasReadTokenCapability(hasReadTokenCapability);
            });
        }
    }, [currentUser, isAuthenticatedPath, organizationService, auth, isNewRbacExperienceEnabled]);

    useEffect(() => {
        let unwatch: () => void | undefined;
        let timeout = 0;

        const clearNotification = (subCategory: string): void => {
            NotificationService.clear({
                category: NotificationCategory.GLOBAL,
                subCategory: subCategory,
            });
        };

        const watchQuotaStatusUpdate = (): void => {
            overageWarnings.current = undefined;

            if (isAdmin) {
                clearNotification(SubscriptionOverageCategory);
            }

            if (hasReadTokenCapability) {
                clearNotification(TokenOverageCategory);
            }

            unwatch = overQuotaNotificationService.watch(handleQuotaStatusUpdate);
        };

        if (org) {
            timeout = window.setTimeout(watchQuotaStatusUpdate, 1000);
        }
        return (): void => {
            clearTimeout(timeout);
            if (unwatch) {
                unwatch();
            }
        };
    }, [
        handleQuotaStatusUpdate,
        hasReadTokenCapability,
        isAdmin,
        org,
        overQuotaNotificationService,
    ]);
};
