/**
 * Utilities for searching objects.
 */
angular
    .module('signalview.search', [])
    .service('searchService', [
        '_',
        function (_) {
            const compareString = function (expected, actual) {
                if (typeof actual === 'string') {
                    // Return true if expected is the empty string, otherwise do a
                    // case-insensitive comparison.
                    return actual.toLowerCase().indexOf(expected.toLowerCase()) > -1;
                }
                return false;
            };

            const compareProp = function (expected, actual, compFn) {
                if (typeof expected === 'undefined') {
                    return true;
                } else if (!actual) {
                    return false;
                } else if (actual.constructor === Array && expected.constructor === Object) {
                    return _.some(actual, function (obj) {
                        return compareObj(compFn, expected, obj);
                    });
                } else if (typeof actual !== typeof expected) {
                    return false;
                } else if (typeof expected === 'string') {
                    return compareString(expected, actual);
                }
                return actual === expected;
            };

            /**
             * Performs a deep comparison between *obj* and *pattern* to determine if
             * *obj* contains equivalent property values.
             *
             * @param {Function} comparator - Comparator which is used to determine if
             *                                a property from *pattern* matches the
             *                                corresponding property from *obj*.
             * @param {Object} pattern
             * @param {Object} obj
             */
            function compareObj(comparator, pattern, obj) {
                return _.some(Object.keys(pattern), function (key) {
                    return comparator(pattern[key], obj[key], comparator);
                });
            }

            return {
                compareObj: compareObj,
                compareProp: compareProp,
            };
        },
    ])
    /**
     * Select a subset of items from *array* that match a pattern object *pattern*.
     *
     * @param array - The source array.
     * @param pattern - A pattern object. For example, "{name: 'M', phone: '1'}" will
     *                  return an array of items which have property 'name'
     *                  containing 'M' or property 'phone' containing '1'.
     *
     *                  String comparisons are case-insensitive. Pattern objects may
     *                  contain other pattern objects in order to match arrays.
     */
    .filter('searchField', [
        'searchService',
        function (searchService) {
            return function (pattern, array) {
                const matcher = function (item) {
                    return searchService.compareObj(searchService.compareProp, pattern, item);
                };
                return array.filter(matcher);
            };
        },
    ])
    /**
     * Wraps any substrings in the string *text* that match the pattern *pattern*
     * in a span with a "highlighted" class.
     *
     * @param {string} text - String to search through.
     * @param {string} pattern - Substring to search for.
     * @example <caption>Example usage of highlight.</caption>
     * <span ng-bind-html="text | highlight:pattern"></span>
     */
    .filter('highlight', [
        '$sce',
        '_',
        function ($sce, _) {
            const template = '<span class="sfx-match">$&</span>';

            return function (text, pattern, useGlob) {
                text = _.escape(text);
                pattern = _.escapeRegExp(_.escape(pattern)).replace(/\\\*/gi, '.*');

                if (useGlob) {
                    pattern = pattern.replace(/\\\*/gi, '.*');
                }

                const contents = pattern ? text.replace(new RegExp(pattern, 'gi'), template) : text;

                return $sce.trustAsHtml(contents);
            };
        },
    ]);
