import { useEffect, useState } from 'react';
import type { History } from 'history';

const UNAUTHENTICATED_PATH_REGEXES_LIST = [
    /invite/,
    /^\/google\//,
    /^\/signin/,
    /^\/saml\//,
    /^\/oidc/,
    /^\/saml-notify/,
    /password-reset/,
    /^\/sso\//,
    /^\/verify\//i,
    /^\/_/,
];
const UNAUTHENTICATED_EXACT_PATH_LIST = ['/signup', '/forgot-password'];
const NON_WHITE_SPACE_CHAR_REGEX = /\/\S+/;

function reducerFn(path: string): boolean {
    let isAuth = true;
    for (const p in UNAUTHENTICATED_PATH_REGEXES_LIST) {
        isAuth = isAuth && !path.match(UNAUTHENTICATED_PATH_REGEXES_LIST[p]);
    }
    for (const q in UNAUTHENTICATED_EXACT_PATH_LIST) {
        isAuth = isAuth && path !== UNAUTHENTICATED_EXACT_PATH_LIST[q];
    }
    return isAuth;
}

// Intercept failed requests
export function isAuthenticatedPath(path: string): boolean {
    return !!(path.match(NON_WHITE_SPACE_CHAR_REGEX) && reducerFn(path));
}

export function useIsAuthenticatedPath(history: History): boolean {
    const [isAuthenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const checkPath = (): void => {
            const currentPath = `${history.location.pathname}${history.location.search}`;
            const toSetAuthenticated = isAuthenticatedPath(currentPath);
            setAuthenticated(toSetAuthenticated);
        };

        checkPath();
        return history.listen(checkPath);
    }, [history]);

    return isAuthenticated;
}

export default { isAuthenticatedPath };
