import templateUrl from './metricProperties.tpl.html';

export default {
    bindings: {
        metricName: '=',
        filters: '=',
        addFilter: '<',
        getProperties: '<',
    },
    templateUrl,
    controller: [
        'filterSelectModal',
        'METRICS_CATALOG_CONSTANTS',
        function (filterSelectModal, METRICS_CATALOG_CONSTANTS) {
            const $ctrl = this;
            const GA_PREFIX = 'metrics-finder-expanded-property';
            const NUM_COLUMNS = 3;

            $ctrl.$onInit = onInit;
            $ctrl.openModalForProperty = openModalForProperty;

            function openModalForProperty(property) {
                const title = `${METRICS_CATALOG_CONSTANTS.FILTER_MODAL_TITLE}  ${property}`;
                const filters = ['sf_metric:' + $ctrl.metricName];
                const queryInfo = {
                    key: property,
                    query: '',
                    filters: filters,
                    isActiveMetricsOnly: true,
                };
                return filterSelectModal(title, queryInfo, GA_PREFIX).then((result) => {
                    $ctrl.addFilter(result);
                }, angular.noop);
            }

            function fetchProperties() {
                const filters = [];
                $ctrl.filters.forEach((filter) => {
                    filters.push(filter.value);
                });
                return $ctrl.getProperties($ctrl.metricName, filters).then((properties) => {
                    $ctrl.properties = properties;
                    $ctrl.columnItemCount = Math.ceil($ctrl.properties.length / NUM_COLUMNS);
                });
            }

            function onInit() {
                fetchProperties();
            }
        },
    ],
};
