angular
    .module('signalview.orgIntegrations')

    .controller('integrationModalController', [
        '$scope',
        '$uibModalInstance',
        'org',
        'editable',
        'integrationType',
        'integrationName',
        'apiToken',
        'updateActiveIntegrations',
        'signalboost',
        'SAML_PROVIDERS',
        '$q',
        function (
            $scope,
            $uibModalInstance,
            org,
            editable,
            integrationType,
            integrationName,
            apiToken,
            updateActiveIntegrations,
            signalboost,
            SAML_PROVIDERS,
            $q
        ) {
            if (editable !== true || editable !== false) {
                signalboost.uauth
                    .canCreate(org.id, 'Credentials')
                    .then((permission) => ($scope.editable = permission));
            } else {
                $scope.editable = editable;
            }
            $scope.org = org;
            $scope.integrationType = integrationType;
            $scope.integrationName = integrationName;
            $scope.apiToken = apiToken;
            $scope.$on('dismiss modal', $scope.dismiss);

            function closeModal() {
                $scope.$broadcast('closing modal');
                $uibModalInstance.dismiss();
                updateActiveIntegrations();
            }

            $scope.formTracker = {};
            $scope.dismiss = () => {
                if (!_.isEmpty($scope.formTracker)) {
                    if (
                        $scope.hasNewIntegration &&
                        (SAML_PROVIDERS.indexOf(integrationName) !== -1 ||
                            integrationName === 'aws')
                    ) {
                        $scope.dismissWithDraft().then(closeModal);
                    } else {
                        $scope
                            .confirmModal({
                                isDestructive: false,
                                callToActionText: 'Close',
                                description: `You have unsaved changes to one or more integrations!
                          <br/>
                          These changes will be lost if you close without saving.`,
                            })
                            .then(closeModal);
                    }
                } else {
                    closeModal();
                }
            };

            $scope.dismissWithDraft = () => {
                const deferred = $q.defer();
                $scope.confirm = {
                    isNonDestructive: true,
                    description: 'Your new integration is incomplete.',
                    dismissText: 'Continue editing',
                    action2Text: 'Discard',
                    callToActionText: 'Come back later',
                    dismiss: () => {
                        $scope.confirm = null;
                        deferred.reject();
                    },
                    onAction2: () => {
                        $scope.confirm = null;
                        deferred.resolve();
                    },
                    onAction: () => {
                        $scope.confirm = null;
                        $scope.$broadcast('save integration draft');
                        deferred.resolve();
                    },
                };
                return deferred.promise;
            };

            $scope.confirmModal = (modalParams) => {
                const deferred = $q.defer();
                $scope.confirm = {
                    isNonDestructive: !modalParams.isDestructive,
                    dismissText: 'Cancel',
                    callToActionText: modalParams.callToActionText || 'Continue',
                    description: modalParams.description || '',
                    onAction: () => {
                        $scope.confirm = null;
                        deferred.resolve();
                    },
                    dismiss: () => {
                        $scope.confirm = null;
                        deferred.reject();
                    },
                };
                return deferred.promise;
            };

            $scope.hasNewIntegration = false;
            $scope.$on('hasNewIntegrationToggle', (event, data) => {
                $scope.hasNewIntegration = data.hasNewIntegration;
            });
        },
    ]);
