import templateUrl from './chartAxes.tpl.html';

angular.module('signalview.chartbuilder').directive('chartAxes', [
    function () {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                model: '=',
            },
        };
    },
]);
