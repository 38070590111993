// provides injection access to a secondary token to be used for service discovery content creation related queries.

export default [
    '$window',
    function ($window) {
        let token = null;
        return {
            get: function () {
                if (!token) {
                    token = prompt(
                        'serviceDiscoveryContentMode is enabled, provide alternate token for Websocket use.'
                    );
                    $window.sdContentCreationAuthToken = token;
                }
                return token;
            },
        };
    },
];
