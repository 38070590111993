import { encodeSearch } from '@splunk/olly-common/utils/urlUtils';

angular.module('chartbuilderUtil').service('chartUrlUtils', [
    function () {
        function getNewChartURLWithFilters(filters, template) {
            const templateType = template || 'default';
            const base = '#/chart/new';

            let search = 'template=' + templateType;

            if (filters.length) {
                search += '&filters=' + filters.join('&filters=');
            }

            return `${base}?${encodeSearch(search)}`;
        }

        return {
            getNewChartURLWithFilters,
        };
    },
]);
