angular.module('chartbuilderUtil').factory('regExParsingUtil', [
    function () {
        function hasGraphiteSyntax(q) {
            return q.match(/\[|\]|\{|\}|\*/);
        }

        function hasGraphiteSpecificSyntax(q) {
            return q.match(/\[|\]|\{|\}/);
        }

        function hasGraphiteDelimiter(q) {
            return q.match(/\./);
        }

        function hasNewRelicSyntax(q) {
            return q.match(/\/|\*/);
        }

        function hasNewRelicSpecificSyntax(q) {
            return q.match(/\//);
        }

        function hasNewRelicDelimiter(q) {
            return q.match(/\//);
        }

        function hasWildCard(q) {
            return q.match(/\*/);
        }

        return {
            hasGraphiteSyntax: hasGraphiteSyntax,
            hasGraphiteSpecificSyntax: hasGraphiteSpecificSyntax,
            hasGraphiteDelimiter: hasGraphiteDelimiter,
            hasNewRelicSyntax: hasNewRelicSyntax,
            hasNewRelicSpecificSyntax: hasNewRelicSpecificSyntax,
            hasNewRelicDelimiter: hasNewRelicDelimiter,
            hasWildCard: hasWildCard,
        };
    },
]);
