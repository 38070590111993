'use strict';

angular.module('sfx.ui').controller('jiraSelection', [
    '$scope',
    '$uibModalInstance',
    'title',
    'options',
    'selected',
    function ($scope, $uibModalInstance, title, options, selected) {
        $scope.title = title;
        $scope.model = {
            options: options || [],
            selected: selected,
            applyAllowed: options.map((option) => option.id).includes(selected),
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.apply = function () {
            $uibModalInstance.close($scope.model.selected);
        };

        $scope.$watch('model.selected', function (newValue, oldValue) {
            if (newValue === oldValue) return;

            $scope.model.applyAllowed = true;
        });
    },
]);
