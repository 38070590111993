'use strict';
import templateUrl from './dashboardGroupInfo.tpl.html';

export default {
    templateUrl,
    bindings: {
        dashboardGroupObject: '<',
        editable: '<',
        missingUpdateDashboardGroupCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$log',
        '$scope',
        'exitGuardModal',
        '$q',
        'dashboardGroupService',
        'currentUser',
        'SYSTEM_USER_ID',
        function (
            $log,
            $scope,
            exitGuardModal,
            $q,
            dashboardGroupService,
            currentUser,
            SYSTEM_USER_ID
        ) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;
            let page;

            currentUser.isSuperUser().then((isSuperUser) => {
                $scope.isSuperUser = isSuperUser;
            });

            $scope.getRestrictorQuery = function () {
                return '(NOT sf_archived:T) AND sf_type:MetricTimeSeries AND sf_isActive:true';
            };

            function save() {
                if ($scope.importQualifiers) {
                    page.importQualifiers = $scope.importQualifiers.map((qualifier) => {
                        const metric = qualifier.metric;
                        const filters = qualifier.filters.map((filter) => {
                            const property = filter.property;
                            let values = filter.propertyValue;

                            if (values === '*') {
                                // empty arrays will be treated as an exists condition
                                values = [];
                            }

                            if (!Array.isArray(values)) {
                                // all values must be a string array (treated as multi-select with a single value)
                                values = [values];
                            }

                            return {
                                property,
                                values,
                                not: filter.NOT,
                            };
                        });

                        return {
                            metric,
                            filters,
                        };
                    });
                }

                page.name = $scope.name;
                page.description = $scope.description;
                $scope.loading = true;
                return dashboardGroupService
                    .update(page)
                    .catch(function (e) {
                        $log.error(e);
                    })
                    .finally(function () {
                        $scope.loading = false;
                        $scope.pageInfo.$setPristine();
                    });
            }

            function exitGuard() {
                return exitGuardModal(save, reset);
            }

            $scope.$watch('pageInfo.$dirty', function (newVal) {
                if (newVal) {
                    $ctrl.tabby.enableExitGuard(exitGuard);
                } else {
                    $ctrl.tabby.disableExitGuard();
                }
            });

            $scope.addImportQualifier = function () {
                $scope.importQualifiers.push({
                    metric: '',
                    filters: [],
                });
            };

            $scope.removeImportQualifier = function (qualifier) {
                const indexToRemove = $scope.importQualifiers.indexOf((q) => q === qualifier);
                $scope.importQualifiers.splice(indexToRemove, 1);
            };

            function convertQualifierFiltersToFilterPropertyList(filters) {
                return filters.map((filter) => {
                    const property = filter.property;
                    const propertyValues = filter.values;

                    let propertyValue;
                    if (propertyValues.length === 0) {
                        propertyValue = '*';
                    } else if (propertyValues.length === 1) {
                        propertyValue = propertyValues[0];
                    } else {
                        propertyValue = propertyValues;
                    }

                    return {
                        property,
                        propertyValue,
                        NOT: filter.not,
                        type: 'property',
                        iconClass: 'icon-properties',
                    };
                });
            }

            function $onInit() {
                page = dashboardGroupService.convertToV2($ctrl.dashboardGroupObject);
                $scope.page = page;
                $scope.isServiceDiscovery = page.creator === SYSTEM_USER_ID;

                if (page.importQualifiers) {
                    $scope.importQualifiers = page.importQualifiers.map((qualifier) => {
                        const metric = qualifier.metric;
                        const filters = convertQualifierFiltersToFilterPropertyList(
                            qualifier.filters
                        );

                        return {
                            metric,
                            filters,
                        };
                    });
                } else {
                    $scope.importQualifiers = [
                        {
                            metric: '',
                            filters: [],
                        },
                    ];
                }

                $scope.name = page.name;
                $scope.description = page.description;
                $scope.id = page.id;
            }

            function reset() {
                $onInit();
                $scope.pageInfo.$setPristine();
                return $q.when();
            }

            $scope.saveAndClose = function () {
                save().then((updatedGroup) => {
                    $scope.$emit('close modal', updatedGroup);
                });
            };

            $scope.cancel = function () {
                $scope.$emit('dismiss modal');
            };
        },
    ],
};
