import heatmapTemplateUrl from './heatmap.tpl.html';

export const heatmap = {
    bindings: {
        orgId: '<',
        heatmap: '<',
        userPreferences: '<',
    },
    templateUrl: heatmapTemplateUrl,
    controller: [
        '$scope',
        '$location',
        '$timeout',
        'CHART_DISPLAY_EVENTS',
        'heatmapUserPreferences',
        'KUBE_INFRA_NAV_ID',
        'navigatorModes',
        'credentialV2Service',
        'sidebarService',
        '$window',
        'urlOverridesService',
        'heatmapUtilsService',
        'colorAccessibilityService',
        'instrumentationService',
        'organizationService',
        'routeParameterService',
        'featureEnabled',
        'title',
        'SUPPORT_EMAIL',
        function (
            $scope,
            $location,
            $timeout,
            CHART_DISPLAY_EVENTS,
            heatmapUserPreferences,
            KUBE_INFRA_NAV_ID,
            navigatorModes,
            credentialV2Service,
            sidebarService,
            $window,
            urlOverridesService,
            heatmapUtilsService,
            colorAccessibilityService,
            instrumentationService,
            organizationService,
            routeParameterService,
            featureEnabled,
            title,
            SUPPORT_EMAIL
        ) {
            const $ctrl = this;
            $ctrl.$onInit = init;
            const timer = instrumentationService.getInstrumentationTimer(
                'ui.infrastructure',
                ['complete'],
                30000
            );
            timer.init();

            $scope.sidebarService = sidebarService;
            $scope.kubernetesInfraNavId = KUBE_INFRA_NAV_ID;
            $scope.SUPPORT_CONTACT = featureEnabled('supportSwitch')
                ? 'Access the Splunk Support Portal'
                : 'Contact ' + SUPPORT_EMAIL;
            const watchForFirstLoad = $scope.$on('loadComplete', function () {
                timer.report('complete');
                watchForFirstLoad();
            });

            $scope.$watch('sidebarService.showSidebar', resize);

            $scope.switchToAWSMode = function () {
                heatmapUserPreferences.mode('AWS instances');
            };

            $scope.switchToMode = function (modeId) {
                const oldModeId = $scope.mode ? $scope.mode.id : null;
                $scope.mode = navigatorModes.get(modeId);

                if (oldModeId !== modeId) {
                    urlOverridesService.setSourceOverride(null);
                    urlOverridesService.setGroupBy(null);
                    urlOverridesService.setOutlierStrategy(null);
                    urlOverridesService.setColorBy(null);
                    urlOverridesService.setOutlierDepth(null);
                    urlOverridesService.setTab(null);
                    urlOverridesService.setMapSelection(null);
                    urlOverridesService.clearTimePicker();
                }

                heatmapUserPreferences.mode(modeId).then(function () {
                    $location.path('/navigator/' + $ctrl.orgId + '/' + modeId);
                });
            };

            $scope.switchToK8s = function () {
                $location.path('/k8s');
            };

            function init() {
                const heatmap = $ctrl.heatmap;
                // If there is no heatmap generated, or mode available, show default
                // "please setup integration" page.
                if (!heatmap || !heatmap.mode()) {
                    return;
                }

                const mode = ($scope.mode = heatmap.mode());
                const modes = ($scope.modes = heatmap.modes());

                $scope.config = {
                    heatmap: heatmap,
                };

                updatePageTitle(mode);
                colorAccessibilityService.updatePalette(
                    $ctrl.userPreferences.sf_colorAccessibility
                );
                $scope.accessibleColors = colorAccessibilityService.get().getHeatmapColors();
                $scope.categorizedModes = heatmapUtilsService.sortAndCategorizeModes(modes);

                let integrationStatusGeneration = 0;
                $scope.infiniteScrollGeneration = 0;

                $scope.awsIntegrationRecentlyUpdated = false;

                sidebarService.showSidebar = modes.length > 1;

                $scope.infiniteScrollGeneration++;

                if (mode.id === 'AWS instances') {
                    organizationService.get().then(function (org) {
                        if (org.accountType !== 'TRIAL') {
                            return;
                        }
                        integrationStatusGeneration++;
                        const expectedStatusGeneration = integrationStatusGeneration;
                        credentialV2Service.getAll().then(function (creds) {
                            if (expectedStatusGeneration !== integrationStatusGeneration) {
                                return;
                            }
                            const latestIntegrationMs = creds.reduce(function (prev, cred) {
                                if (cred.type === 'AWSCloudWatch' && prev < cred.lastUpdated) {
                                    return cred.lastUpdated;
                                }
                                return prev;
                            }, 0);
                            $scope.awsIntegrationRecentlyUpdated = latestIntegrationMs;
                        });
                    });
                } else {
                    $scope.awsIntegrationRecentlyUpdated = 0;
                }

                heatmap.on('groupBy updated', function () {
                    $scope.infiniteScrollGeneration++;
                });

                heatmap.loadStateFromUrl();

                routeParameterService.registerRouteWatch('sources[]', () => {
                    heatmap.loadStateFromUrl();
                });
            }

            function updatePageTitle(mode) {
                let pageTitle = 'Infrastructure';
                if (mode) {
                    pageTitle += ` - ${mode.displayName} (${mode.category})`;
                }
                title.updateTitle(pageTitle);
            }

            function resize() {
                // push to next tick to allow markup resizes to go through first
                $timeout(function () {
                    $scope.$broadcast('resize');
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                });
            }

            $scope.needToShowBanner = function () {
                return Date.now() - $scope.awsIntegrationRecentlyUpdated < 60 * 60 * 1000;
            };

            $scope.$on('$destroy', function () {
                timer.abort();
                $window.removeEventListener('resize', resize);
            });

            $scope.$on('tab changed', function () {
                $scope.infiniteScrollGeneration++;
            });

            $scope.getNextDataSet = function () {
                $scope.$broadcast('infiniteScroll.loadMore');
            };

            $scope.getModeIcon = function (modeId) {
                return heatmapUtilsService.getModeIcon(modeId);
            };

            $window.addEventListener('resize', resize);
        },
    ],
};
