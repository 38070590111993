const KEY_PREFIX = 'azure_tag_';
const KEY_PREFIX_REGEX = new RegExp('^' + KEY_PREFIX);

// identify each key-value pair for the purpose of one-way data flow in UI
// identifier are removed before sending to the backend, their only purpose
// is to discern entries
let lastId = 0;

function signalflowAstToTags(expression, trail = '') {
    if (expression.type === 'binary_expression') {
        if (expression.op !== 'OR') {
            throw new Error(`Unexpected binary operator: ${expression.op}`);
        }

        const left = signalflowAstToTags(expression.left, trail + '.left');
        const right = signalflowAstToTags(expression.right, trail + '.right');
        return left.concat(right);
    } else if (expression.type === 'filter') {
        const key = signalflowAstToTags(expression.field, '.field');
        if (!KEY_PREFIX_REGEX.test(key)) {
            throw new Error(`filter key must start with "azure_tag_" at ${trail + '.field'}`);
        }

        return [
            {
                id: ++lastId,
                key: key.replace(KEY_PREFIX_REGEX, ''),
                value: signalflowAstToTags(expression.value, '.value'),
            },
        ];
    } else if (expression.type === 'string') {
        return expression.value;
    } else {
        throw new Error(`Unexpected type "${expression.type} at ${trail}"`);
    }
}

export class SfxIntegrationAzureFilterConverter {
    static DI = 'sfxIntegrationAzureFilterConverter';
    static $inject = ['migratedCredentialV2Service'];

    constructor(migratedCredentialV2Service) {
        this.migratedCredentialV2Service = migratedCredentialV2Service;
    }

    tagsToSignalflow(tags) {
        const signalflow = tags
            .map(
                ({ key, value }) =>
                    `filter(${JSON.stringify(KEY_PREFIX + key)}, ${JSON.stringify(value)})`
            )
            .join(' or ');

        return signalflow;
    }

    getNextId() {
        return ++lastId;
    }

    async signalflowToTags(signalflow) {
        if (signalflow === '') {
            return [];
        }

        const signalFlowModels = await this.migratedCredentialV2Service.transformSignalFlow([
            signalflow,
        ]);
        const expression = signalFlowModels[0].statements[0].expression;

        try {
            return signalflowAstToTags(expression, '.statements[0].expression');
        } catch {
            return undefined;
        }
    }

    resourceFilterRulesToSignalflow(resourceFilterRules) {
        if (resourceFilterRules === null || resourceFilterRules === undefined) {
            return '';
        }

        if (!Array.isArray(resourceFilterRules)) {
            return undefined;
        }

        if (resourceFilterRules.length > 1) {
            // attribute managed through REST API / terraform
            return undefined;
        }

        if (resourceFilterRules.length === 0) {
            return '';
        }

        return resourceFilterRules[0]?.filter?.source;
    }

    signalflowToResourceFilterRules(signalflow) {
        if (signalflow === '') {
            return [];
        }

        return [
            {
                filter: {
                    source: signalflow,
                },
            },
        ];
    }
}

angular
    .module('sfx.ui')
    .service(SfxIntegrationAzureFilterConverter.DI, SfxIntegrationAzureFilterConverter);
