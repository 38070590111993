import templateUrl from './opsgenieNotifyBlock.tpl.html';

angular.module('signalview.detector.notification').directive('opsgenieNotifyBlock', [
    '$log',
    '$timeout',
    'notificationsUtil',
    'opsgenieTeamService',
    'processNotificationRecipient',
    function (
        $log,
        $timeout,
        notificationsUtil,
        opsgenieTeamService,
        processNotificationRecipient
    ) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                credentials: '=credentials',
                remove: '&onRemove',
                readOnly: '=?',
                doneEdit: '&onFinishEdit',
            },
            link: function ($scope, $element) {
                const noTeam = {
                    name: '(No team)',
                    teamId: null,
                    description:
                        'Select this option to send alerts to Opsgenie without specifying an Opsgenie team',
                };

                let currentTeamSearchRequestId = 0;

                $scope.credentialType = notificationsUtil.getIntegrationNameForV1orV2(
                    $scope.model.type
                );
                $scope.ui = {
                    dropdownOpen: false,
                };

                $scope.selector = {
                    suggestions: [],
                    highlighted: null,
                    onSelect: selectTeam,
                };

                $scope.setCredentialAndContinueEdit = setCredentialAndContinueEdit;
                $scope.finishEdit = finishEdit;
                $scope.editMode = editMode;

                initialize();

                function initialize() {
                    if (!$scope.model.credentialId) {
                        // if there's only one opsgenie integration, automatically pick it
                        if ($scope.credentials.length === 1) {
                            setCredentialAndContinueEdit($scope.credentials[0]);
                        } else {
                            editMode();
                        }
                    } else {
                        $scope.credentials.some((credential) => {
                            if (credential.id === $scope.model.credentialId) {
                                setCredential(credential);

                                if (doesModelHaveResponder()) {
                                    $scope.selectedResponder = getResponderTeam($scope.model);
                                } else if (!credential.isTeamKey) {
                                    selectTeam(noTeam);
                                }
                                return true;
                            }
                        });
                    }

                    $scope.$watch('ui.dropdownOpen', function (newValue, oldValue) {
                        if (oldValue && !newValue) {
                            if (!$scope.model.credentialId && $scope.remove) $scope.remove();
                        }
                    });

                    $scope.$watch('selector.query', updateTeamSuggestions);
                }

                function updateTeamSuggestions(query) {
                    if (!$scope.credential) return;
                    const thisRequestId = ++currentTeamSearchRequestId;
                    if (!query) query = '';

                    const suggestions = [];

                    if (!query) {
                        suggestions.push(getResponderTeam(noTeam));
                        updateSuggestions(suggestions);
                    }

                    opsgenieTeamService.searchOpsgenieTeams($scope.credential.id, query).then(
                        (results) => {
                            if (thisRequestId !== currentTeamSearchRequestId) return;

                            results.forEach((result) => {
                                suggestions.push({
                                    displayName: result.name,
                                    team: result,
                                });
                            });

                            const queryHasNoTeam = noTeam.name
                                .toLowerCase()
                                .includes(query.toLowerCase());

                            if (queryHasNoTeam) {
                                suggestions.push(getResponderTeam(noTeam));
                            }

                            if (query) {
                                const directMatchChannel = getEmptySearchResponse(query, true);
                                const hasDirectMatch = suggestions.some(
                                    (suggestion) =>
                                        suggestion.displayName.toLowerCase() ===
                                        directMatchChannel.displayName.toLowerCase()
                                );

                                if (!hasDirectMatch) {
                                    suggestions.push(directMatchChannel);
                                }
                            }

                            updateSuggestions(suggestions);
                        },
                        (reason) => {
                            if (reason === opsgenieTeamService.DEBOUNCED_REASON) {
                                return;
                            }
                            $log.warn('Could not fetch Opsgenie teams.');
                            if (query) {
                                suggestions.push(getEmptySearchResponse(query));
                            }
                            updateSuggestions(suggestions);
                        }
                    );
                }

                function getEmptySearchResponse(name, warn) {
                    const responder = getResponderTeam(name);
                    if (warn) {
                        responder.team.warning = `We couldn’t find ${name} in the list of teams returned from Opsgenie.
                  Please make sure that the team name you typed is correct.`;
                    }
                    return responder;
                }

                function updateSuggestions(suggestions) {
                    $scope.selector.suggestions = suggestions;
                    $scope.selector.highlighted = $scope.selector.suggestions[0];
                }

                function getResponderTeam(param) {
                    let team = {};
                    if (param) {
                        if (angular.isString(param)) {
                            team = { name: param };
                        } else if (param.responderName || param.responderId) {
                            // From model
                            team = mapModelToTeam(param);
                        } else if (param.team || param.name || param.teamId) {
                            // From team
                            team = param.team || param;
                        }
                    }

                    const responder = {
                        displayName: team?.name || '',
                        team,
                    };

                    return responder;
                }

                function selectTeam(param) {
                    const selection = getResponderTeam(param);
                    $scope.selectedResponder = selection;
                    if (selection.team === noTeam) {
                        delete $scope.model.responderName;
                        delete $scope.model.responderId;
                    } else {
                        const { responderName, responderId, displayName } = mapTeamToModel(
                            selection.team
                        );
                        if (responderId) {
                            delete $scope.model.responderName;
                        } else {
                            $scope.model.responderName = responderName;
                        }

                        $scope.model.displayName = displayName;
                        $scope.model.responderId = responderId;
                    }
                    finishEdit();
                }

                function mapTeamToModel({ name, teamId }) {
                    return {
                        responderName: teamId ? undefined : name,
                        responderId: teamId,
                        displayName: name,
                    };
                }

                function mapModelToTeam({ responderId, responderName, displayName }) {
                    if (!responderName && !displayName) {
                        searchOpsgenieTeams(responderId);
                    } else {
                        return { name: responderName || displayName, teamId: responderId };
                    }
                }

                function searchOpsgenieTeams(responderId) {
                    return processNotificationRecipient
                        .getDisplayDetails([$scope.model])
                        .then((results) => {
                            const result = results.find((r) => r.responderId === responderId);
                            const displayName = result?.displayName || result?.responderName;
                            const team = { name: displayName, teamId: responderId };
                            $scope.selectedResponder.displayName = displayName || '';
                            $scope.selectedResponder.team = team;
                        })
                        .catch((error) => {
                            $log.warn('Could not fetch Opsgenie teams.' + error);
                        });
                }

                function setCredentialAndContinueEdit(credential) {
                    setCredential(credential);
                    if (credential.isTeamKey) {
                        finishEdit();
                    } else {
                        editMode();
                    }
                }

                function setCredential(credential) {
                    $scope.model.credentialId = credential.id;
                    $scope.model.credentialName = credential.name;
                    $scope.credential = credential;
                }

                function finishEdit() {
                    if ($scope.credential && $scope.credential.isTeamKey) {
                        $scope.edit = false;
                    } else if (
                        doesModelHaveResponder() ||
                        ($scope.selectedResponder && $scope.selectedResponder.team === noTeam)
                    ) {
                        $scope.model.responderType = 'Team';
                        $scope.edit = false;
                    } else {
                        if ($scope.remove) $scope.remove();
                    }
                    if ($scope.doneEdit) $scope.doneEdit();
                }

                function doesModelHaveResponder() {
                    return $scope.model && ($scope.model.responderId || $scope.model.responderName);
                }

                function editMode() {
                    $scope.edit = true;

                    if (!$scope.model.credentialId) {
                        $timeout(() => ($scope.ui.dropdownOpen = true));
                    } else {
                        if (!$scope.selectedResponder) {
                            $scope.selectedResponder = getResponderTeam();
                        }

                        if (
                            $scope.selectedResponder.displayName !== $scope.selector.query &&
                            $scope.selectedResponder.team !== noTeam
                        ) {
                            $scope.selector.query = $scope.selectedResponder.displayName;
                        } else {
                            updateTeamSuggestions($scope.selector.query);
                        }

                        $timeout(() => {
                            const input = $element.find('input')[0];
                            if (input) {
                                input.focus();
                            }
                        });
                    }
                }
            },
            templateUrl,
        };
    },
]);
