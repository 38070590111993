import orgUsageModalUrl from './orgUsageModal.tpl.html';

angular.module('signalview.orgUsageChart').service('orgUsageModalService', [
    'sfxModal',
    'organizationService',
    'linkableModalService',
    function (sfxModal, organizationService, linkableModalService) {
        linkableModalService.register('usageReports', openModal, true);

        return {
            showUsageReports: () => linkableModalService.open('usageReports'),
        };

        function openModal() {
            return sfxModal.open({
                templateUrl: orgUsageModalUrl,
                controller: 'OrgUsageModalController',
                size: 'lg',
                resolve: {
                    org: function () {
                        return organizationService.get();
                    },
                },
            });
        }
    },
]);
