import templateUrl from './apiSection.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .constant('API_LIST', [
        {
            key: 'apireference',
            name: 'API Reference',
            docUrl: 'https://developers.signalfx.com/',
        },
        {
            key: 'java',
            name: 'Java API',
            docUrl: 'https://github.com/signalfx/signalfx-java',
            infraOnly: true,
        },
        {
            key: 'nodejs',
            name: 'Node API',
            docUrl: 'https://github.com/signalfx/signalfx-nodejs',
            infraOnly: true,
        },
        {
            key: 'python',
            name: 'Python API',
            docUrl: 'https://github.com/signalfx/signalfx-python',
            infraOnly: true,
        },
        {
            key: 'ruby',
            name: 'Ruby API',
            docUrl: 'https://github.com/signalfx/signalfx-ruby',
            infraOnly: true,
        },
        {
            key: 'golibrary',
            name: 'Go Library',
            docUrl: 'https://github.com/signalfx/golib/tree/master/sfxclient',
            infraOnly: true,
        },
        {
            key: 'net',
            name: '.Net API',
            docUrl: 'https://github.com/signalfx/Metrics.NET.SignalFX',
            infraOnly: true,
        },
    ])

    .directive('apiSection', [
        '$filter',
        'STATIC_RESOURCE_ROOT',
        'API_LIST',
        'featureEnabled',
        'PRODUCT_NAME',
        function ($filter, STATIC_RESOURCE_ROOT, API_LIST, featureEnabled, PRODUCT_NAME) {
            return {
                replace: true,
                templateUrl,
                scope: {
                    query: '=',
                },
                link: function ($scope) {
                    $scope.STATIC_RESOURCE_ROOT = STATIC_RESOURCE_ROOT;
                    $scope.PRODUCT_NAME = PRODUCT_NAME;
                    $scope.$watch('query', function () {
                        $scope.filteredApis = $filter('filter')(API_LIST, $scope.query);
                    });
                    const hasInfraEntitlement = featureEnabled('infra');
                    $scope.filterApi = function (api) {
                        return !api.infraOnly || hasInfraEntitlement;
                    };
                },
            };
        },
    ]);
