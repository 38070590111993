import templateUrl from './teamPageDescription.tpl.html';

import dummyText from '../../../app/teams/teamDescriptionTemplate.js';

export default {
    templateUrl,
    bindings: {
        description: '<',
        team: '<',
        isMember: '<',
        isAdmin: '<',
        orgId: '<',
        isRestrictTeamManagement: '<',
        isTeamManager: '<',
    },
    controller: [
        '$log',
        '$scope',
        '$window',
        'teamsApiService',
        'featureEnabled',
        function ($log, $scope, $window, teamsApiService) {
            const ctrl = this;

            ctrl.$onInit = $onInit;
            ctrl.$onChanges = $onChanges;
            ctrl.toggleEditMode = toggleEditMode;
            ctrl.saveDescriptionEdits = saveDescriptionEdits;

            function $onInit() {
                if (!ctrl.description) {
                    ctrl.description = dummyText;
                }
                ctrl.teamDisplayName = generatePossessiveTeamName();
            }

            function $onChanges(changeObj) {
                if (changeObj.team) {
                    ctrl.team = angular.copy(changeObj.team.currentValue);
                    ctrl.teamDisplayName = generatePossessiveTeamName();
                }
            }

            function toggleEditMode() {
                ctrl.editMode = !ctrl.editMode;
                ctrl.candidateEdits = ctrl.description;
            }

            function generatePossessiveTeamName() {
                const teamName = ctrl.team ? ctrl.team.name : 'Unnamed Team';
                const isTeamNamePlural = teamName[teamName.length - 1].toLowerCase() === 's';
                return isTeamNamePlural ? teamName + "'" : teamName + "'s";
            }

            function saveDescriptionEdits() {
                const newTeam = angular.extend({}, ctrl.team, { description: ctrl.candidateEdits });
                teamsApiService
                    .updateTeam(ctrl.team.id, newTeam)
                    .then((updateTeam) => {
                        $scope.$emit('description update', updateTeam.description);
                    })
                    .catch((error) => {
                        $window.alert('Failed updating team');
                        $log.error('Failed updating team', error);
                    });
                toggleEditMode();
            }
        },
    ],
};
