import templateUrl from '../resolutionPill.tpl.html';
import { bindings } from '../resolutionPill';
import { samplingAndRenderingIcons } from '../samplingAndRenderingIcons';
import { singleLineAlertTicker } from '../singleLineAlertTicker/singleLineAlertTicker';
import { withNg2React } from '../../../../../common/util/Ng2React';
const ResolutionPillComponent = {
    templateUrl,
    bindings,
};

export const ResolutionPill = withNg2React({
    name: 'resolution-pill',
    definition: ResolutionPillComponent,
});

export const SamplingAndRenderingIcon = withNg2React({
    name: 'sampling-and-rendering-icons',
    definition: samplingAndRenderingIcons,
});

export const SingleLineAlertTicker = withNg2React({
    name: 'single-line-alert-ticker',
    definition: singleLineAlertTicker,
});
