angular.module('sfx.ui').service('jiraAssigneeTypeaheadServiceFactory', [
    'jiraService',
    function (jiraService) {
        return {
            createInstance: function jiraAssigneeTypeaheadService() {
                let assigneeCache;

                function suggestAssignees(useStoredCredentials, credential, query) {
                    const assignees = useStoredCredentials
                        ? jiraService.assignees.get(credential.id, credential.projectKey, query)
                        : jiraService.assignees.post(credential, query);

                    return assignees.then((assignees) => {
                        assigneeCache = new Map();
                        assignees.forEach((a) => {
                            const key = assigneeToUniqueDisplayName(a);
                            assigneeCache.set(key, a);
                        });

                        return Array.from(assigneeCache.keys());
                    });
                }

                function getAssignee(assigneeUniqueName) {
                    return assigneeCache.get(assigneeUniqueName);
                }

                function assigneeToUniqueDisplayName(assignee) {
                    return assignee ? `${assignee.displayName} (${assignee.name})` : '';
                }

                return {
                    suggestAssignees,
                    getAssignee,
                    assigneeToUniqueDisplayName,
                };
            },
        };
    },
]);
