import templateUrl from './largeVolumeModal.tpl.html';

export const largeVolumeModalService = [
    'sfxModal',
    function (sfxModal) {
        return {
            open: function (params) {
                return sfxModal.open({
                    templateUrl,
                    controller: 'largeVolumeModalController',
                    resolve: {
                        params: function () {
                            return params;
                        },
                    },
                }).result;
            },
        };
    },
];
