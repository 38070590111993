import { scopedSessionStorage } from '@splunk/olly-services/lib';
import { getProgramArgsForDashboardInTime } from '../utils/programArgsUtils';

export default [
    '$q',
    '$http',
    '$log',
    '$timeout',
    'API_URL',
    'murmurHash',
    'urlOverridesService',
    'featureEnabled',
    function ($q, $http, $log, $timeout, API_URL, murmurHash, urlOverridesService, featureEnabled) {
        const ss = scopedSessionStorage.withPrefix('signalFlowInfoService');
        const FLUSH_SIZE = 8;
        const FLUSH_TIMEOUT = 200;
        let flushDebounce = null;
        let currentQueue = [];
        const pendingRequests = {};
        const isDashboardTimeWindowEnabled = featureEnabled('dashboardTimeWindow');
        function getKey(key) {
            return murmurHash(key) + '';
        }

        function debounceFlush() {
            $timeout.cancel(flushDebounce);
            flushDebounce = $timeout(flushBatch, FLUSH_TIMEOUT);
        }

        function flushBatch() {
            $timeout.cancel(flushDebounce);
            if (currentQueue.length === 0) {
                return;
            }
            const global = urlOverridesService.getGlobalTimeAbsolute();
            const programArgs = getProgramArgsForDashboardInTime({
                absoluteStart: global?.start,
                absoluteEnd: global?.end,
            });
            //keep closure reference to what will be issued, reset service level queue to empty
            const localQueue = currentQueue;
            currentQueue = [];
            $http({
                method: 'POST',
                url:
                    API_URL +
                    (isDashboardTimeWindowEnabled
                        ? '/v2/signalflow/_/getBatchedSignalFlowWithArgsInformation?ephemeral=true'
                        : '/v2/signalflow/_/getBatchedSignalFlowInformation?ephemeral=true'),
                data: isDashboardTimeWindowEnabled
                    ? {
                          programTexts: localQueue.map((e) => e.signalFlow),
                          programArgs,
                      }
                    : localQueue.map((e) => e.signalFlow),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(
                function (resp) {
                    if (resp.data.length !== localQueue.length) {
                        $log.error(
                            'Response for batched signalflow information has a length mismatch'
                        );
                        dumpQueue(localQueue);
                        return;
                    }
                    angular.forEach(resp.data, function (infoObject, idx) {
                        // sanity check to ensure we dont cache obviously invalid responses
                        const sflow = localQueue[idx].signalFlow;
                        if (
                            sflow &&
                            (infoObject.programInfo !== null || infoObject.plots !== null)
                        ) {
                            ss.set(getKey(sflow), infoObject);
                        }
                        localQueue[idx].defer.resolve(infoObject);
                        delete pendingRequests[localQueue[idx].hash];
                    });
                },
                function () {
                    dumpQueue(localQueue);
                }
            );
        }

        function dumpQueue(arr) {
            arr.forEach((e) => {
                e.resolve(null);
            });
        }

        function addRequest(signalFlow) {
            if (!signalFlow) {
                return $q.when({
                    programInfo: {
                        streamPublishInfo: [],
                        eventLabels: [],
                    },
                    plots: null,
                });
            }
            const hash = getKey(signalFlow);
            //local promise lookup
            if (pendingRequests[hash]) {
                return pendingRequests[hash].then(function (obj) {
                    return angular.copy(obj);
                });
            }

            //session storage lookup
            const val = ss.get(hash);
            if (val) {
                return $q.when(val);
            }

            const defer = $q.defer();
            currentQueue.push({
                signalFlow,
                hash,
                defer,
            });

            if (currentQueue.length >= FLUSH_SIZE) {
                flushBatch();
            } else {
                debounceFlush();
            }
            pendingRequests[hash] = defer.promise;
            return defer.promise;
        }

        function prePopulate(programTextArray) {
            if (!programTextArray || !programTextArray.length) {
                return;
            }
            programTextArray.forEach(addRequest);
            flushBatch();
        }

        return {
            addRequest,
            prePopulate,
        };
    },
];
