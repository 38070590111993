angular.module('signalview.chart').factory('Plot', [
    'chartVisualizationService',
    'plotUtils',
    function (cvs, plotUtils) {
        const availableTypes = ['plot', 'ratio', 'event'];
        const availableVisualizations = cvs.getChartTypes();

        function Plot(config) {
            this.data = angular.extend(angular.copy(Plot.DEFAULT_MODEL), config);
        }

        Plot.create = function (config) {
            return new Plot(config);
        };

        Plot.DEFAULT_MODEL = {
            yAxisIndex: 0,
            invisible: false,
            transient: false,
            seriesData: {},
            configuration: {},
            queryItems: [],
            dataManipulations: [],
        };

        Plot.ROLLUP = {
            DELTA: 'DELTA_ROLLUP',
        };

        Plot.prototype.type = function (type) {
            if (arguments.length === 0) {
                return this.data.type;
            }

            if (availableTypes.indexOf(type) === -1) {
                throw new Error('Invalid plot type: ' + type);
            }

            this.data.type = type;
            return this;
        };

        Plot.prototype.uniqueKey = function (uniqueKey) {
            if (arguments.length === 0) {
                return this.data.uniqueKey;
            }

            this.data.uniqueKey = uniqueKey;
            this.data._originalLabel = plotUtils.getLetterFromUniqueKey(uniqueKey);
            return this;
        };

        Plot.prototype.rollup = function (rollup) {
            if (arguments.length === 0) {
                return this.data.configuration.rollupPolicy;
            }

            this.data.configuration.rollupPolicy = rollup;
            return this;
        };

        Plot.prototype.name = function (name) {
            if (arguments.length === 0) {
                return this.data.name;
            }

            this.data.name = name;
            return this;
        };

        Plot.prototype.visible = function (isVisible) {
            if (arguments.length === 0) {
                return !this.data.invisible;
            }

            this.data.invisible = !isVisible;

            return this;
        };

        Plot.prototype.yAxis = function (yAxisIndex) {
            if (arguments.length === 0) {
                return this.data.yAxisIndex;
            }

            this.data.yAxisIndex = yAxisIndex;

            return this;
        };

        Plot.prototype.color = function (color) {
            if (arguments.length === 0) {
                return this.data.configuration.colorOverride;
            }

            this.data.configuration.colorOverride = color;

            return this;
        };

        Plot.prototype.excludeTemplateFilters = function (excludeTemplateFilters) {
            if (arguments.length === 0) {
                return this.data.configuration.excludeTemplateFilters;
            }

            this.data.configuration.excludeTemplateFilters = excludeTemplateFilters;

            return this;
        };

        Plot.prototype.extrapolationPolicy = function (policy) {
            if (arguments.length === 0) {
                return this.data.configuration.extrapolationPolicy;
            }

            this.data.configuration.extrapolationPolicy = policy;

            return this;
        };

        Plot.prototype.maxExtrapolations = function (maxExtrapolations) {
            if (arguments.length === 0) {
                return this.data.configuration.maxExtrapolations;
            }

            this.data.configuration.maxExtrapolations = maxExtrapolations;

            return this;
        };

        Plot.prototype.rollupPolicy = function (policy) {
            if (arguments.length === 0) {
                return this.data.configuration.rollupPolicy;
            }

            this.data.configuration.rollupPolicy = policy;

            return this;
        };

        Plot.prototype.visualization = function (visualization) {
            if (arguments.length === 0) {
                return this.data.configuration.visualization;
            }

            const isValid = availableVisualizations.some(function (availableVisualization) {
                return availableVisualization.value === visualization;
            });

            if (!isValid) {
                throw new Error('Invalid plot visualization: ' + visualization);
            }

            this.data.configuration.visualization = visualization;

            return this;
        };

        Plot.prototype.macro = function (macro) {
            if (arguments.length === 0) {
                return this.data.expressionText;
            }

            const type = this.type();
            if (type === undefined) {
                this.type('ratio');
            } else if (type !== 'ratio') {
                throw new Error('Cannot add macro to non-ratio plot type.');
            }

            if (!this.name()) {
                this.name(macro);
            }

            this.data.expressionText = macro;
            return this;
        };

        Plot.prototype.regExStyle = function (regExStyle) {
            if (arguments.length === 0) {
                return this.data.seriesData.regExStyle;
            }

            this.data.seriesData.regExStyle = regExStyle;
            return this;
        };

        Plot.prototype.metric = function (metric) {
            if (arguments.length === 0) {
                return this.data.seriesData.metric;
            }

            const type = this.type();
            if (type === undefined) {
                this.type('plot');
            } else if (type !== 'plot') {
                throw new Error('Cannot set metric of non-plot plot type.');
            }

            if (!this.name()) {
                this.name(metric);
            }

            this.data.seriesData.metric = metric;
            return this;
        };

        Plot.prototype.eventQuery = function (eventQuery) {
            if (arguments.length === 0) {
                return this.data.seriesData.eventQuery;
            }

            const type = this.type();
            if (type === undefined) {
                this.type('event');
            } else if (type !== 'event') {
                throw new Error('Cannot set eventQuery of non-event plot type.');
            }

            if (!this.name()) {
                this.name(eventQuery);
            }

            this.data.seriesData.eventQuery = eventQuery;
            return this;
        };

        Plot.prototype.detectorId = function (detectorId) {
            if (arguments.length === 0) {
                return this.data.seriesData.detectorId;
            }

            const type = this.type();
            if (type === undefined) {
                this.type('event');
            } else if (type !== 'event') {
                throw new Error('Cannot set detectorQuery of non-event plot type.');
            }

            this.data.seriesData.detectorId = detectorId;
            return this;
        };

        Plot.prototype.detectorQuery = function (detectorQuery) {
            if (arguments.length === 0) {
                return this.data.seriesData.detectorQuery;
            }

            const type = this.type();
            if (type === undefined) {
                this.type('event');
            } else if (type !== 'event') {
                throw new Error('Cannot set detectorQuery of non-event plot type.');
            }

            if (!this.name()) {
                this.name(detectorQuery);
            }

            this.data.seriesData.detectorQuery = detectorQuery;
            return this;
        };

        Plot.prototype.propertyFilter = function (propertyName, value) {
            propertyName = propertyName || '';
            const notProperty = propertyName.startsWith('!');
            const property = propertyName.replace(/^!/, '');
            if (arguments.length === 0) {
                // No viable operation for empty arguments
                return;
            } else if (arguments.length === 1) {
                // If only the property name was provided, return the value in the list
                let matchingPropertyValue;
                this.data.queryItems.some(function (item) {
                    const notItem = item.NOT ? true : false;
                    if (
                        item.type === 'property' &&
                        item.property === property &&
                        notItem === notProperty
                    ) {
                        matchingPropertyValue = item.propertyValue;
                        return true;
                    }
                });

                return matchingPropertyValue;
            } else {
                // Find existing properties with the same name
                const existingProperties = this.data.queryItems.filter(function (item) {
                    const notItem = item.NOT ? true : false;
                    return (
                        item.type === 'property' &&
                        item.property === property &&
                        notItem === notProperty
                    );
                });

                // If the property already exists, just overwrite the current values,
                // otherwise, add a new property;
                const propertyObject = existingProperties.length ? existingProperties[0] : {};

                propertyObject.NOT = notProperty;
                propertyObject.iconClass = 'sf-icon-property';
                propertyObject.property = property;
                propertyObject.propertyValue = value;
                if (angular.isArray(value)) {
                    const values = value.map(function (v) {
                        return '"' + v + '"';
                    });
                    propertyObject.query = property + ':(' + values.join(' ') + ')';
                } else {
                    propertyObject.query = property + ':"' + value + '"';
                }
                propertyObject.type = 'property';
                propertyObject.value = value;

                // If this is a new property add it to the query items list;
                if (!existingProperties.length) {
                    this.data.queryItems.push(propertyObject);
                }

                return this;
            }
        };

        Plot.prototype.propertyFilters = function (filters) {
            if (arguments.length === 0) {
                filters = {};
                this.data.queryItems.forEach(function (filter) {
                    filters[filter.property] = filter.propertyValue;
                });

                return filters;
            } else {
                const self = this;

                if (filters) {
                    Object.keys(filters).forEach(function (key) {
                        self.propertyFilter(key, filters[key]);
                    });
                }

                return this;
            }
        };

        Plot.prototype.removeManipulation = function (manipulation) {
            const index = this.data.dataManipulations.indexOf(manipulation);
            if (index !== -1) {
                this.data.dataManipulations.splice(index, 1);
                return true;
            }

            return false;
        };

        Plot.prototype.addManipulation = function (manipulation) {
            this.data.dataManipulations.push(manipulation);
            return this;
        };

        Plot.prototype.getManipulations = function () {
            return this.data.dataManipulations;
        };

        Plot.prototype.config = function (config) {
            if (arguments.length === 0) {
                return this.data;
            } else {
                this.data = config;
                return this;
            }
        };

        Plot.prototype.cloneTo = function (chart) {
            if (!chart) throw new Error('You must pass in a chart to clone to');
            const config = angular.copy(this.config());
            delete config.uniqueKey;
            return chart.plot(config);
        };

        return Plot;
    },
]);
