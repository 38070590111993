/**
 * Provides utilities for rendering a web page progressively.
 */
export default [
    function () {
        const ids = [];
        const readyById = {};

        /**
         * Check whether or not a part of the DOM tree should be rendered.
         *
         * On the first invocation of this function for a particular ID, a
         * component will be added to the stack of components to render. Once all
         * components above it in the stack have been rendered, a component will
         * become ready to render.
         *
         * @param {*} id - An value that identifies one or more parts of the DOM.
         * @param {boolean} isVisible - Whether or not the portions of the DOM tree
         * representing the object identified by *id* is already created.
         */
        const isReady = function (id, isVisible) {
            if (isVisible) {
                readyById[id] = true;
            } else if (!readyById.hasOwnProperty(id)) {
                readyById[id] = false;
                ids.push(id);
            }
            return readyById[id];
        };

        const update = function () {
            let id;
            const numPopped = 0;
            let poppedAny = false;
            while (ids.length > 0 && numPopped < 5) {
                id = ids.pop();
                if (!readyById[id]) {
                    readyById[id] = true;
                    poppedAny = true;
                }
            }
            return poppedAny;
        };

        return {
            isReady: isReady,
            update: update,
        };
    },
];
