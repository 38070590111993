import templateUrl from './integrationBadge.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .constant('INTEGRATION_BADGES', {
        activemq: 'integration_activemq.png',
        adfs: 'integration_adfs.png',
        apache: 'integration_apache.png',
        appdynamics: 'integration_appdynamics.png',
        autoscaling: 'integration_awsautoscaling.png',
        aws: 'integration_aws.png',
        awsbilling: 'integration_awsbilling.png',
        amazoneventbridge: 'integration_amazoneventbridge.png',
        cassandra: 'integration_cassandra.png',
        chef: 'integration_chef.png',
        cloudfront: 'integration_awscloudfront.png',
        couchbase: 'integration_couchbase.png',
        docker: 'integration_docker.png',
        dynamodb: 'integration_awsdynamodb.png',
        ebs: 'integration_awsebs.png', // aws elastic block store
        ec2: 'integration_awsec2.png', // aws elastic compute service
        ecs: 'integration_awsecs.png', // aws ec2 container service
        elasticache: 'integration_awselasticache.png',
        elasticsearch: 'integration_elasticsearch.png',
        elb: 'integration_awselb.png', // aws elastic load balancing
        googleauth: 'integration_google.png',
        googleSaml: 'integration_googlesaml.png',
        googleCloudIdentity: 'integration_googlesaml.png',
        azureAd: 'integration_azureactivedirectory.png',
        bigpanda: 'integration_bigpanda.png',
        jira: 'integration_jira.png',
        kafka: 'integration_kafka.png',
        kubernetes: 'integration_kubernetes.png',
        memcached: 'integration_memcached.png',
        mesos: 'integration_mesos.png',
        mongodb: 'integration_mongodb.png',
        moreoptions: 'integration_moreoptions.png',
        mysql: 'integration_mysql.png',
        newrelic: 'integration_newrelic.png',
        nginx: 'integration_nginx.png',
        office365: 'integration_office365.png',
        onelogin: 'integration_onelogin.png',
        okta: 'integration_okta.png',
        opsgenie: 'integration_opsgenie.png',
        opsworks: 'integration_awsopsworks.png',
        pagerduty: 'integration_pagerduty.png',
        servicenow: 'integration_servicenow.png',
        pingone: 'integration_pingone.png',
        puppet: 'integration_puppet.png',
        postgresql: 'integration_postgresql.png',
        rabbitmq: 'integration_rabbitmq.png',
        rds: 'integration_awsrds.png', // aws relational database service
        redis: 'integration_redis.png',
        route53: 'integration_awsroute53.png',
        s3: 'integration_awss3.png', // aws simple storage service
        saml: 'integration_saml.png',
        sfxcollectd: 'integration_signalfx.png',
        shellscript: 'integration_shellscript.png',
        slack: 'integration_slack.png',
        sns: 'integration_awssns.png', // aws simple notification service
        sqs: 'integration_awssqs.png', // aws simple queue service
        varnish: 'integration_varnish.png',
        victorops: 'integration_victorops.png',
        windows: 'integration_windows.png',
        webhook: 'integration_webhook.png',
        xmatters: 'integration_xmatters.png',
        zookeeper: 'integration_zookeeper.png',
        splunk: 'integration_splunk.png',
        externalLink: 'integration_externallink.png',
        default: 'integration_default.png',
    })
    .service('getIntegrationBadgeURL', [
        'STATIC_RESOURCE_ROOT',
        'INTEGRATIONS_DOC_URL',
        'INTEGRATION_BADGES',
        'integrationsMeta',
        function (
            STATIC_RESOURCE_ROOT,
            INTEGRATIONS_DOC_URL,
            INTEGRATION_BADGES,
            integrationsMeta
        ) {
            function getRepoImage(name) {
                const integrationMetadata = integrationsMeta[name];
                if (integrationMetadata) {
                    const imgAddress = integrationMetadata.logo_small.split('/').reverse()[0];
                    return (
                        INTEGRATIONS_DOC_URL +
                        'assets/images/integrations/' +
                        imgAddress.replace(/%402x/, '@2x')
                    );
                }
            }

            return function (name) {
                return (
                    getRepoImage(name) ||
                    STATIC_RESOURCE_ROOT +
                        'assets/' +
                        (INTEGRATION_BADGES[name] || INTEGRATION_BADGES.default)
                );
            };
        },
    ])
    /**
     * Renders a component that shows the icon of a service and a short
     * description. If *showLongVersion* is true, also shows a longer description
     * and a link to more information.
     */
    .component('integrationBadge', {
        templateUrl,
        bindings: {
            isEnabled: '<',
            badgeName: '@',
            badgeTitle: '@',
            badgeImg: '@',
            showLongVersion: '<?',
            shortDescription: '@?',
            longDescription: '@?',
            url: '@?',
            size: '@?',
        },
        controller: [
            '$scope',
            'STATIC_RESOURCE_ROOT',
            function ($scope, STATIC_RESOURCE_ROOT) {
                this.STATIC_RESOURCE_ROOT = STATIC_RESOURCE_ROOT;

                this.dismissModal = function () {
                    $scope.$emit('dismiss modal');
                };
            },
        ],
    });
