import aboutUsTemplateUrl from '../../../app/globalNav/aboutUs.tpl.html';

export default {
    templateUrl: aboutUsTemplateUrl,
    controller: [
        'moment',
        '$scope',
        function (moment, $scope) {
            $scope.currentYear = moment().format('YYYY');
        },
    ],
};
