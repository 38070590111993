angular.module('signalview.alerts').service('resolveAlert', [
    '$http',
    'API_URL',
    'currentUser',
    function ($http, API_URL, currentUser) {
        return function (incidentId) {
            return currentUser.orgId().then(function (orgId) {
                const url = API_URL + '/v2/event/resolve?organizationId=' + orgId;
                return $http.post(url, {
                    incidentId: incidentId,
                });
            });
        };
    },
]);
