import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';
import alertTypeService from '../../legacy/app/alerts/alertTypeService';
import SimpleSpec from './nextSteps/components/SimpleSpec';
import { NotificationRecipients } from './NotificationRecipients';
import Link from '@splunk/react-ui/Link';

const TITLE_ALERT = 'About this Alert';
const INCIDENT_ID_LABEL = 'Incident ID';
const EVENT_TYPE_LABEL_TRIGGER = 'trigger';

function AboutThisAlertOtherEventIncidentLink(props) {
    if (props.otherEventInIncident) {
        const eventType = props.otherEventInIncident.properties.is;
        const isClearingEvent = alertTypeService.isClearingEvent(eventType);

        let eventTypeLabel;

        if (isClearingEvent) {
            eventTypeLabel = alertTypeService.getDisplayFor(eventType).toLowerCase();
        } else {
            eventTypeLabel = EVENT_TYPE_LABEL_TRIGGER;
        }

        const otherEventInIncidentText = 'View ' + eventTypeLabel + ' event';

        return (
            <SimpleSpec
                label=""
                value={
                    <Link
                        id="link-about-detector"
                        {...props.className('link')}
                        onClick={() => props.gotoOtherEvent()}
                        target={'_blank'}
                    >
                        {otherEventInIncidentText}
                    </Link>
                }
            />
        );
    }

    return <></>;
}

AboutThisAlertOtherEventIncidentLink.propTypes = {
    className: PropTypes.func,
    otherEventInIncident: PropTypes.object,
    otherEventLink: PropTypes.string,
    gotoOtherEvent: PropTypes.func,
};

export default function AboutThisAlert(props) {
    // create component className helper or use passed in parentClass helper
    const className = new BEMHelper('about-detector');
    const titleClassName = className('section-title');

    return (
        <div {...className()}>
            <h4 {...titleClassName}>{TITLE_ALERT}</h4>
            <NotificationRecipients recipients={props.notificationRecipients} />
            <SimpleSpec label={INCIDENT_ID_LABEL} value={props.incidentId} />
            <AboutThisAlertOtherEventIncidentLink
                gotoOtherEvent={props.gotoOtherEvent}
                otherEventInIncident={props.otherEventInIncident}
                otherEventLink={props.otherEventLink}
                className={className}
            />
        </div>
    );
}

AboutThisAlert.propTypes = {
    parentClass: PropTypes.func,
    gotoOtherEvent: PropTypes.func,
    otherEventInIncident: PropTypes.object,
    otherEventLink: PropTypes.string,
    incidentId: PropTypes.string,
    notificationRecipients: PropTypes.array,
};
