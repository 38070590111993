import templateUrl from './heatmapColorBy.tpl.html';

angular.module('signalview.heatmap').directive('heatmapColorBy', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            require: '^^groupableHeatmap',
            scope: {},
            templateUrl,
            link: function ($scope, element, attrs, heatmap) {
                const selector = ($scope.selector = {});
                selector.selected = heatmap.heatmap.colorBy();
                selector.suggestions = null;
                selector.highlighted = null;
                selector.inputVisible = false;

                selector.showInput = function () {
                    selector.inputVisible = true;

                    $timeout(function () {
                        element.find('input').focus();
                    }, 100);
                };

                selector.select = function (item) {
                    selector.query = null;
                    selector.selected = item;
                    selector.inputVisible = false;
                };

                heatmap.heatmap.on('colorBy updated', function ($event, colorBy) {
                    selector.selected = colorBy;
                });

                function updateColorBy() {
                    let colorBy;

                    if (selector.selected) {
                        colorBy = selector.selected;
                    }

                    if (selector.highlighted) {
                        colorBy = selector.highlighted;
                    }

                    heatmap.heatmap.colorBy(colorBy);
                }

                $scope.$watch('selector.selected', updateColorBy, true);

                function updateSuggestions() {
                    const mode = heatmap.heatmap.mode();
                    selector.suggestions = mode.metrics;

                    if (selector.query) {
                        selector.highlighted = selector.suggestions[0];
                    } else {
                        selector.highlighted = null;
                    }
                }

                heatmap.heatmap.on('mode updated', updateSuggestions);
                $scope.$watch('selector.visible', function (isVisible) {
                    if (isVisible) {
                        updateSuggestions();
                    } else {
                        selector.suggestions = null;
                        selector.highlighted = null;
                        selector.inputVisible = false;
                    }
                });
            },
        };
    },
]);
