angular.module('sfx.ui').service('gcpService', [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        function getSupportedGCPServices() {
            return $http.get(API_URL + '/v2/gcp/supportedServices');
        }

        function getListOfProjects(sf_id) {
            return $http.get(API_URL + '/v2/gcp/projectList?credentialId=' + sf_id);
        }

        return {
            getSupportedGCPServices,
            getListOfProjects,
        };
    },
]);
