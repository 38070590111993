import templateUrl from './teamLinkingModal.tpl.html';
import teamErrorModalTemplateUrl from './teamErrorModal.tpl.html';

const LINKABLE_OBJECTS = {
    DETECTOR: 'detector',
    DASHBOARD_GROUP: 'dashboard_group',
};

export const teamLinkingService = [
    '$q',
    '$log',
    'sfxModal',
    'teamsApiService',
    'blockingLoadService',
    'permissionsChecker',
    'writepermissionsPermissionsChecker',
    'detectorUtils',
    'currentUser',
    'organizationService',
    function (
        $q,
        $log,
        sfxModal,
        teamsApiService,
        blockingLoadService,
        permissionsChecker,
        writepermissionsPermissionsChecker,
        detectorUtils,
        currentUser,
        organizationService
    ) {
        const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred.';
        const HELP_LINK =
            'https://quickdraw.splunk.com/redirect/?product=Observability&location=teamhome.linkalerts&version=current';

        return {
            editDetector,
            editDashboardGroup,
            showErrorModal,
        };

        function editDetector(object) {
            return $q.when(object.sf_teams || object.teams || []).then((currentLinkedTeams) => {
                return edit(object, currentLinkedTeams, LINKABLE_OBJECTS.DETECTOR).then(
                    (updates) => {
                        // may have a detector update promise if applicable, or empty promise to resolve.
                        return detectorUtils
                            .maybeUpdateTeamNotifications(object, updates)
                            .then((results) => {
                                const errorResults = results.filter((r) => r && r.errMessage);
                                if (errorResults && errorResults.length) {
                                    let errorMessage = '';
                                    errorResults.forEach((error) => {
                                        errorMessage += error.errMessage + '</br>';
                                        $log.error(error.errMessage);
                                    });
                                    showErrorModal(errorMessage);
                                }
                            });
                    }
                );
            });
        }

        function editDashboardGroup(object) {
            return $q.when(object.sf_teams || object.teams || []).then((currentLinkedTeams) => {
                return edit(object, currentLinkedTeams, LINKABLE_OBJECTS.DASHBOARD_GROUP);
            });
        }

        function edit(object, currentLinkedTeams, objectType) {
            const loadingIndicator = blockingLoadService();
            const modalInstance = sfxModal.open({
                templateUrl,
                resolve: {
                    teamLinkingObject: function () {
                        // Get org settings and admin settings
                        const promises = [
                            organizationService.getOrgSettings(),
                            currentUser.isAdmin(),
                        ];
                        return Promise.all(promises).then(([prefs, isAdmin]) => {
                            const isRestrictTeamManagement = !!prefs.sf_restrictTeamManagement;
                            return teamsApiService.getTeamSummary().then((data) => {
                                return {
                                    teams: data.results,
                                    userTeams: data.results,
                                    isRestrictTeamManagement,
                                    isAdmin,
                                };
                            });
                        });
                    },
                    hasWritePermissions() {
                        switch (objectType) {
                            case LINKABLE_OBJECTS.DASHBOARD_GROUP:
                                return permissionsChecker.hasDashboardGroupWriteAccess(object);
                            case LINKABLE_OBJECTS.DETECTOR:
                                return writepermissionsPermissionsChecker.hasWritePermissions(
                                    object
                                );
                            default:
                                $log.error(
                                    `teamLinkingService does not support permissions checking for this objectType - ${objectType}.`
                                );
                                return Promise.resolve(true);
                        }
                    },
                },
                controllerAs: '$ctrl',
                controller: [
                    '$scope',
                    '$uibModalInstance',
                    'hasWritePermissions',
                    'teamLinkingObject',
                    function ($scope, $uibModalInstance, hasWritePermissions, teamLinkingObject) {
                        const ctrl = this;
                        const LINK_TYPES = { old: 'old', new: 'new', removed: 'removed' };

                        loadingIndicator.close();
                        ctrl.toAddQuery = '';
                        ctrl.teams = teamLinkingObject.teams;
                        ctrl.userTeamMap = teamLinkingObject.userTeamsById || {};
                        ctrl.isAdmin = teamLinkingObject.isAdmin;
                        ctrl.LINK_TYPES = LINK_TYPES;
                        ctrl.hasWritePermissions = hasWritePermissions;
                        ctrl.isDirty = false;
                        ctrl.currentlyHasLinkedTeams = currentLinkedTeams.length > 0;
                        ctrl.isRestrictTeamManagement = teamLinkingObject.isRestrictTeamManagement;
                        ctrl.teamSuggestions = teamLinkingObject.userTeams;
                        ctrl.availableTeamSuggestions =
                            ctrl.teamSuggestions && ctrl.teamSuggestions.length > 0;

                        ctrl.teamStates = ctrl.teams.reduce((map, team) => {
                            map[team.id] = {
                                linkType: currentLinkedTeams.includes(team.id)
                                    ? LINK_TYPES.old
                                    : null,
                                name: team.name,
                                allowTeamModifications: ctrl.hasWritePermissions,
                            };
                            return map;
                        }, {});

                        updateDropdownOptions();

                        ctrl.removeTeam = function (id) {
                            if (ctrl.teamStates[id].linkType === LINK_TYPES.old) {
                                ctrl.teamStates[id].linkType = LINK_TYPES.removed;
                            } else if (ctrl.teamStates[id].linkType === LINK_TYPES.new) {
                                ctrl.teamStates[id].linkType = null;
                            }
                            updateIsDirty();
                            updateDropdownOptions();
                        };

                        ctrl.save = function () {
                            const linkedTeams = [];
                            angular.forEach(ctrl.teamStates, (stateObj, teamId) => {
                                if (
                                    stateObj.linkType === LINK_TYPES.new ||
                                    stateObj.linkType === LINK_TYPES.old
                                ) {
                                    linkedTeams.push(teamId);
                                }
                            });

                            const results = {
                                // finalized list of ids of teams to link
                                updatedTeams: linkedTeams,
                                // list of objects {teamId, teamName} representing teams added in this edit
                                addedTeams: getTeamsByState(LINK_TYPES.new),
                                // list of objects {teamId, teamName} representing teams removed in this edit
                                removedTeams: getTeamsByState(LINK_TYPES.removed),
                                // send whether the user has write permissions to the original object
                                // used to indicate whether to show additional modal for detector flow
                                hasWritePermissions: ctrl.hasWritePermissions,
                            };

                            $uibModalInstance.close(results);
                        };

                        ctrl.onSelect = function (teamToAdd) {
                            if (ctrl.teamStates[teamToAdd.id].linkType === LINK_TYPES.removed) {
                                ctrl.teamStates[teamToAdd.id].linkType = LINK_TYPES.old;
                            } else {
                                ctrl.teamStates[teamToAdd.id].linkType = LINK_TYPES.new;
                            }
                            updateIsDirty();
                            updateDropdownOptions();
                            ctrl.toAddQuery = '';
                            angular.element('.team-link-input').blur();
                        };

                        function getTeamsByState(linkType) {
                            const teams = [];
                            angular.forEach(ctrl.teamStates, (team, id) => {
                                if (linkType === team.linkType) {
                                    teams.push({ teamId: id, teamName: team.name });
                                }
                            });
                            return teams;
                        }

                        function updateDropdownOptions() {
                            ctrl.dropdownOptions = ctrl.teamSuggestions
                                .filter((team) => {
                                    return (
                                        team.name
                                            .toLowerCase()
                                            .includes(ctrl.toAddQuery.toLowerCase()) &&
                                        LINK_TYPES.old !== ctrl.teamStates[team.id].linkType &&
                                        LINK_TYPES.new !== ctrl.teamStates[team.id].linkType
                                    );
                                })
                                .map((team) => ({ displayName: team.name, id: team.id }));
                            ctrl.highlighted = ctrl.dropdownOptions[0];
                        }

                        function updateIsDirty() {
                            ctrl.isDirty =
                                getTeamsByState(LINK_TYPES.removed).length > 0 ||
                                getTeamsByState(LINK_TYPES.new).length > 0;
                        }

                        $scope.$watch('$ctrl.toAddQuery', updateDropdownOptions);
                    },
                ],
                backdrop: true,
                keyboard: true,
            });

            return modalInstance.result;
        }

        function showErrorModal(message) {
            const errorMessage = message ? message : UNEXPECTED_ERROR_MESSAGE;
            sfxModal.open({
                templateUrl: teamErrorModalTemplateUrl,
                size: 'lg',
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.modalTitle = 'Error';
                        $scope.errorMessage = errorMessage;
                        $scope.helpLink = HELP_LINK;
                    },
                ],
            });
        }
    },
];
