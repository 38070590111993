import templateUrl from './heatmapChart.tpl.html';
import { SIGNALFX_GREEN } from '../../../../common/consts';
import { cloneDeep } from 'lodash';
import {
    getProgramArgsForDashboardInTime,
    isDashboardTimeWindowSelected,
} from '../../../utils/programArgsUtils';

export const heatmapChart = {
    templateUrl,
    bindings: {
        allPlots: '<',
        autoGradients: '<',
        chartModel: '<',
        colorByValueScale: '<',
        colorByValue: '<',
        colorOverride: '<',
        colorRange: '<',
        editMode: '<',
        fixedUnitsPerRow: '<',
        groupBy: '<',
        hideTimestamp: '<',
        hideTitle: '<',
        isOriginallyV2: '<',
        jobMessageSummary: '<',
        plotDataGeneration: '<',
        sharedChartConfig: '=',
        signalFlow: '<',
        sortBy: '<',
        updateInterval: '<',
        useValueAsColor: '<',
        useKMG2: '<',
        onNewDataProvider: '&',
        openHref: '<',
        disableAnimations: '<',
        chartRollupMessage: '<',
        alertState: '<',
        showNoDataMessage: '<',
        themeKey: '<',
    },
    controller: [
        '_',
        '$element',
        '$window',
        '$timeout',
        'BaseDataProvider',
        'd3',
        'createHeatmap',
        'colorService',
        'chartDisplayUtils',
        'CHART_DISPLAY_EVENTS',
        'colorByValueService',
        '$scope',
        'routeParameterService',
        'timepickerUtils',
        'chartLoadedEvent',
        'SAMPLE_CONSTANTS',
        'DASHBOARD_HEATMAP_NO_DATA',
        'featureEnabled',
        function (
            _,
            $element,
            $window,
            $timeout,
            BaseDataProvider,
            d3,
            createHeatmap,
            colorService,
            chartDisplayUtils,
            CHART_DISPLAY_EVENTS,
            colorByValueService,
            $scope,
            routeParameterService,
            timepickerUtils,
            chartLoadedEvent,
            SAMPLE_CONSTANTS,
            DASHBOARD_HEATMAP_NO_DATA,
            featureEnabled
        ) {
            const $ctrl = this;

            let componentDidInitialize;
            let unregisterRouteWatchGroup;
            let dataProvider;
            let modeSetTimeout;
            let resizeTimeout;
            let newDataTimeout;
            let plotTimeout;
            let redrawTimeout;
            let delayedRedrawTimeout;
            let waitingForDataTimeout;
            let dynamicColorRangeFunction;
            let dataPoints = [];
            let currentMaximum = 100;
            let currentMinimum = 0;
            let visibleTSIDs = [];
            let metadataMap = {};
            let missingDatapointCounter = {};

            const HIDDEN_PROPERTIES = [
                'computationId',
                'depth',
                'id',
                'jobId',
                'parent',
                'sf_isPreQuantized',
                'sf_key',
                'sf_metric',
                'sf_organizationID',
                'sf_originatingMetric',
                'sf_resolutionMs',
                'sf_streamLabel',
                'sf_type',
                'sf_uiAnalyticsDerivedMetric',
                'sf_uiHelper',
                'tsid',
                'visualizationKey',
            ];
            // TODO: figure out sensible values
            const DURATION = 5 * 60 * 1000; // 5 min
            const RESOLUTION = 15 * 1000; // 15 s
            const MISSING_DATAPOINT_LIMIT = DURATION / RESOLUTION;
            const throttledDebouncedMetadataProcessing = _.throttle(debouncedMetaData, 200, {
                trailing: true,
            });

            $ctrl.aspectRatio = 'auto';
            $ctrl.unitPackConstraints = {
                getConstrainedUnitsPerRow,
                getConstrainedAspectRatio,
            };

            $ctrl.$onChanges = $onChanges;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$onInit = $onInit;
            $ctrl.cancelSelection = cancelSelection;
            $ctrl.getHeatmapContainer = getHeatmapContainer;
            $ctrl.groupPadding = groupPadding;
            $ctrl.labelMarkup = labelMarkup;
            $ctrl.setDimensions = setDimensions;
            $ctrl.sortFunction = sortFunction;
            $ctrl.tooltipMsgOnNoData = DASHBOARD_HEATMAP_NO_DATA;

            /* scoped function definitions */

            function $onChanges(changesObj) {
                const {
                    allPlots,
                    autoGradients,
                    colorByValue,
                    colorByValueScale,
                    colorOverride,
                    colorRange,
                    fixedUnitsPerRow,
                    groupBy,
                    hideTimestamp,
                    signalFlow,
                    sortBy,
                    updateInterval,
                    useValueAsColor,
                    plotDataGeneration,
                } = changesObj;

                if (plotDataGeneration || (signalFlow && signalFlow.currentValue)) {
                    // Force signalflow regeneration V1 charts
                    updateSignalFlow(
                        $ctrl.signalFlow +
                            (plotDataGeneration ? '\n# CACHE_BUST : ' + Math.random() : '')
                    );
                }

                if (updateInterval) {
                    updateProviderTimeRange();
                }

                if (
                    componentDidInitialize &&
                    (colorOverride ||
                        autoGradients ||
                        useValueAsColor ||
                        colorByValue ||
                        colorByValueScale)
                ) {
                    // if any coloring options change, then refresh the visualization
                    refreshVisualization();
                }

                // this was a deep compare
                if (colorRange) {
                    refreshVisualization();
                }

                // this was a deep compare
                if (sortBy) {
                    handleSortyChange();
                }

                if (componentDidInitialize && groupBy) {
                    updateGroupBy();
                }

                if ((plotDataGeneration || allPlots) && $ctrl.sharedChartConfig) {
                    handlePlotChange();
                }

                if (hideTimestamp || fixedUnitsPerRow) {
                    resize();
                }
            }

            function $onDestroy() {
                if (unregisterRouteWatchGroup) {
                    unregisterRouteWatchGroup();
                }
                $window.removeEventListener('resize', resize);
            }

            function $onInit() {
                componentDidInitialize = true;

                // initialize heatmap object
                $ctrl.heatmap = createHeatmap();
                $ctrl.heatmap.disableUrlSetters(true);
                $ctrl.heatmap.coloringFunction(coloringFunction);
                $ctrl.heatmap.hideNulls(true);
                updateGroupBy();

                // choose the correct plot for the heatmap
                updateHeatmapPlot();

                // set initial coloring functions
                setColorRangeFuncs();

                // set the dimensions of the display based on parent component
                setDimensions();

                // event bindings
                $scope.$on('cancel selections', cancelSelection);
                $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, resize);

                // watch colorByValueScale, since changes are being spliced into the original
                // object, which would not trigger $onChanges
                $scope.$watch(
                    '$ctrl.colorByValueScale',
                    () => {
                        refreshVisualization();
                    },
                    true
                );

                $ctrl.latestValue = null;

                // create data provider and notify parent.
                dataProvider = new BaseDataProvider(callback);
                dataProvider.setFilterAlias($ctrl.filterAlias);
                dataProvider.setWithDerivedMetadata(true); // gets all properties tied to the MTS
                dataProvider.setOffsetByMaxDelay(true);
                updateProviderTimeRange();
                $ctrl.onNewDataProvider({ dataProvider });

                // subscribe to timerange related url changes
                unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                    ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
                    updateProviderTimeRange.bind(this)
                );
            }

            function cancelSelection() {
                if ($ctrl.heatmap) {
                    $ctrl.heatmap.selection(null);
                }
            }

            function getHeatmapContainer() {
                return $element.find('.heatmap-chart-display');
            }

            function groupPadding(d) {
                if (d.depth === 0) {
                    return { top: 0, bottom: 0, left: 0, right: 0 };
                } else {
                    return { top: 22, bottom: 10, left: 10, right: 10 };
                }
            }

            function labelMarkup(d) {
                if (d.depth === 0) {
                    return '';
                } else {
                    // declare-used-dependency-to-linter::heatmapChartLabel
                    return '<heatmap-chart-label data="d" heatmap="heatmap"></heatmap-chart-label>';
                }
            }

            function setDimensions() {
                const container = getHeatmapContainer();

                $ctrl.parentWidth = container.width() - 4;
                $ctrl.parentHeight = container.height() - 10;
            }

            function sortFunction(a, b) {
                if (a.groupKey) {
                    return sort(a.id, b.id, true);
                }
                let sortBy = '';
                let asc = true;

                if ($ctrl.sortBy) {
                    asc = $ctrl.sortBy.asc;
                    sortBy = ($ctrl.sortBy.value || {}).property;
                }
                if (!sortBy) {
                    return sort(a.id, b.id, asc);
                }
                if (sortBy === 'value') {
                    return asc ? a.value - b.value : b.value - a.value;
                }
                const metadata = metadataMap;
                if (!metadata) {
                    return sort(a.id, b.id, asc);
                }
                const metadataA = metadata[a.tsid];
                const metadataB = metadata[b.tsid];
                const valueA = metadataA[sortBy];
                const valueB = metadataB[sortBy];

                if (typeof valueA === 'number') {
                    return asc ? valueA - valueB : valueB - valueA;
                } else {
                    return sort(valueA, valueB, asc);
                }
            }

            /* non-scoped functions */

            function updateProviderTimeRange() {
                if (!dataProvider) {
                    return;
                }

                // the change in the global time range should not update the chartModel.sf_uiModel.chartconfig here
                const chartModel = cloneDeep($ctrl.chartModel);
                chartDisplayUtils.updateGlobalTimeRange(
                    chartModel,
                    timepickerUtils.getChartConfigURLTimeParameters()
                );

                const { chartconfig, chartMode } = chartModel.sf_uiModel;
                const rangeParams = chartDisplayUtils.getJobRangeParametersFromConfig(
                    chartconfig,
                    chartMode
                );

                const programArgs = getProgramArgsForDashboardInTime(chartconfig);
                dataProvider.setResolution(rangeParams.resolution);
                if (
                    featureEnabled('dashboardTimeWindow') &&
                    isDashboardTimeWindowSelected(chartModel.sf_viewProgramText)
                ) {
                    dataProvider.setProgramArgs(programArgs);
                }
                dataProvider.setHistoryrange(rangeParams.range);
                dataProvider.setStopTime(rangeParams.endAt);
                dataProvider.setFallbackResolutionMs(rangeParams.fallbackResolutionMs);
            }

            function callback({ type, data }) {
                // this does not actually digest, and neither does the data provider.
                // we may need to force digests when latestValue is updated
                // so that it actually picks up and shows the new value.
                if (type === 'data') {
                    handleNewData(data);
                } else if (type === 'init') {
                    reset();
                } else if (type === 'timestampAdvance') {
                    $ctrl.latestTimeStamp = data;
                }
            }

            function handleNewData(data) {
                if (!$ctrl.datapointReceived) {
                    $ctrl.datapointReceived = true;
                }

                let updatedMetadata;

                Object.keys(data).forEach((tsid) => {
                    if (!metadataMap[tsid]) {
                        const metadata = angular.copy(dataProvider.getMetricMetaData(tsid));

                        // alias tsid to id, since heatmap looks for id as an indentifier
                        metadata.id = metadata.tsid;
                        metadataMap[tsid] = metadata;
                        updatedMetadata = true;
                    }
                });

                throttledDebouncedMetadataProcessing();

                if (updatedMetadata) {
                    updateVisibilities();
                }
                onData(data);

                $scope.$digest();
            }

            function debouncedMetaData() {
                $timeout.cancel(modeSetTimeout);
                modeSetTimeout = $timeout(
                    function () {
                        setMode();
                        redraw();
                    },
                    2000,
                    false
                );
            }

            function updateSignalFlow(signalflow) {
                if (dataProvider) {
                    dataProvider.setSignalFlowText(signalflow);
                }
            }

            function onData(tsidToData) {
                const data = mapDataToHeatmapPoints(tsidToData);

                updateData(data);
                if (!$ctrl.sharedChartConfig.heatmapPlotConfig.hasMetadata) {
                    setMode();
                    $ctrl.sharedChartConfig.heatmapPlotConfig.hasMetadata = true;
                }
                $timeout.cancel(newDataTimeout);
                newDataTimeout = $timeout(redraw, 200);
                $timeout.cancel(waitingForDataTimeout);
                $ctrl.sharedChartConfig.heatmapPlotConfig.waitingForData = false;

                storeData(data);
            }

            function mapDataToHeatmapPoints(tsidToData) {
                return Object.keys(tsidToData).map((tsid) => {
                    const metadata = metadataMap[tsid];
                    const datapoint = tsidToData[tsid];
                    let latestValue = datapoint.value;

                    countMissingDatapoints(datapoint, tsid);

                    if (missingDatapointCounter[tsid] >= MISSING_DATAPOINT_LIMIT) {
                        latestValue = undefined;
                    }

                    return {
                        id: metadata.tsid,
                        key: metadata.tsid,
                        value: latestValue,
                    };
                });
            }

            function updateData(data) {
                if (!data || !data.length) {
                    return;
                }

                currentMaximum = Number.MIN_VALUE;
                currentMinimum = Number.MAX_VALUE;
                $ctrl.heatmap.hasDataValues(true);

                data.forEach((d) => {
                    if (d.value !== null && visibleTSIDs.includes(metadataMap[d.key].tsid)) {
                        if (d.value > currentMaximum) {
                            currentMaximum = d.value;
                        }
                        if (d.value < currentMinimum) {
                            currentMinimum = d.value;
                        }
                    }

                    if (metadataMap[d.key]) {
                        metadataMap[d.key].value = d.value;
                        metadataMap[d.key]._highlighted = true;
                    }
                });

                updateDynamicColorRange(currentMinimum, currentMaximum);
            }

            function countMissingDatapoints(datapoint, tsid) {
                // For every null value reported, increment missing datapoint counter
                if (datapoint.value === null) {
                    missingDatapointCounter[tsid]++;
                } else if (missingDatapointCounter[tsid] !== 0) {
                    // if we find a value, reset the missing datapoint counter,
                    // we only care about consecutive nulls.
                    missingDatapointCounter[tsid] = 0;
                }
            }

            function updateRenderCounts(count) {
                if (
                    !$ctrl.jobMessageSummary ||
                    $ctrl.jobMessageSummary.passThruCount === undefined ||
                    $ctrl.jobMessageSummary.throttleCount === undefined
                ) {
                    return;
                }
                const total =
                    $ctrl.jobMessageSummary.passThruCount + $ctrl.jobMessageSummary.throttleCount;
                if (total > SAMPLE_CONSTANTS.MAXIMUM_HEATMAP_SAMPLE_RATE) {
                    $scope.$emit(CHART_DISPLAY_EVENTS.RENDER_STATS, {
                        rendered: count,
                        total: total,
                    });
                } else {
                    $scope.$emit(CHART_DISPLAY_EVENTS.RENDER_STATS, null);
                }
            }

            function redraw() {
                const prunedTimeSeries = pruneTimeSeriesByVisibility();
                $ctrl.heatmap.update(prunedTimeSeries);
                updateRenderCounts(prunedTimeSeries.length);
            }

            function delayedRedraw(ms = 200) {
                $timeout.cancel(delayedRedrawTimeout);
                delayedRedrawTimeout = $timeout(redraw, ms, false);
            }

            function setMode() {
                $timeout.cancel(modeSetTimeout);
                const allKeys = {};
                const data = pruneTimeSeriesByVisibility();
                const propertyCounts = {};

                angular.forEach(data, (metadata) => {
                    metadata.sf_key.forEach((key) => {
                        // increment the number of times that we've seen this key in allKeys
                        if (!angular.isDefined(allKeys[key])) {
                            allKeys[key] = 1;
                        } else {
                            allKeys[key]++;
                        }
                    });

                    // Collect properties (for use in e.g. sort by, tooltip)
                    bookKeepMetadata(propertyCounts, metadata);
                });

                if ($ctrl.editMode) {
                    // TODO: using this shared object to ship properties from the display
                    // component to the configuration component should be avoided, use events
                    // to pass this information.
                    const sortableProperties = getSortableProperties(
                        propertyCounts,
                        Object.keys(data).length
                    ).sort();
                    $ctrl.sharedChartConfig.heatmapPlotConfig.toolTip = sortableProperties.map(
                        (property) => {
                            return {
                                property: property,
                                displayName: getPropertyDisplayName(property),
                            };
                        }
                    );
                }

                const toolTipProperties = getTooltipProperties(propertyCounts);
                const tooltipKeyList = toolTipProperties.map((toolTip) => {
                    return {
                        property: toolTip,
                        displayName: getPropertyDisplayName(toolTip),
                        isSummaryProperty: true,
                    };
                });

                const idTemplate = getIdTemplate(allKeys);
                const modeKey = 'heatmapchart';

                setDimensions();

                $ctrl.heatmap.mode({
                    id: 'id',
                    displayName: modeKey,
                    metrics: [modeKey],
                    idTemplate,
                    type: 'elemental',
                    tooltipKeyList,
                    tooltipRootClassName: 'heatmap-chart-tooltip-container',
                });
            }

            function getTooltipProperties(propertyCounts) {
                const toolTipProperties = Object.keys(propertyCounts).sort();

                movePropertyToTop(toolTipProperties, 'value');

                const sortBy = ($ctrl.sortBy || {}).value || {};

                if (sortBy.property) {
                    movePropertyToTop(toolTipProperties, sortBy.property);
                }

                return toolTipProperties;
            }

            function getIdTemplate(allKeys) {
                // generate the idTemplate using the properties and property/value pairs that
                // that we've seen so far.
                return Object.keys(allKeys)
                    .filter((key) => HIDDEN_PROPERTIES.indexOf(key) === -1)
                    .sort((a, b) => allKeys[b] - allKeys[a]) // sort such that keys with most occurrence show up first
                    .slice(0, 5) // get the first 5 keys only to avoid crowding the display id
                    .map((key) => '{{{' + key + '}}}')
                    .join(', ');
            }

            function pruneTimeSeriesByVisibility() {
                return Object.entries(metadataMap)
                    .filter((entry) => visibleTSIDs.includes(entry[0]))
                    .map((entry) => entry[1])
                    .splice(0, SAMPLE_CONSTANTS.MAXIMUM_HEATMAP_SAMPLE_RATE);
            }

            function storeData(data) {
                const newData = data;
                if (newData.length) {
                    if (!dataPoints) {
                        dataPoints = [];
                    }

                    // filter out the points with new data and then concatenate the new set
                    // into dataPoints.
                    dataPoints = dataPoints
                        .filter((point) => {
                            return !newData.some((newPoint) => newPoint.key === point.key);
                        })
                        .concat(newData);
                }
            }

            function updateDynamicColorRange(min, max) {
                let minVal = min;
                let maxVal = max;
                const colorRange = $ctrl.colorRange;

                if (colorRange) {
                    if (angular.isDefined(colorRange.min) && colorRange.min !== null) {
                        minVal = colorRange.min;
                    }
                    if (angular.isDefined(colorRange.max) && colorRange.max !== null) {
                        maxVal = colorRange.max;
                    }
                }

                dynamicColorRangeFunction.domain([
                    Math.min(minVal, maxVal),
                    Math.max(minVal, maxVal),
                ]);
            }

            function getSortableProperties(propertyCounts, numberOfNodes) {
                return Object.keys(propertyCounts).filter((property) => {
                    const propertyValues = propertyCounts[property];

                    // collect properties that have diverse values; e.g. there is no need
                    // to offer aws_region as a sortable prop if all node have aws_region:us-east-1
                    return Object.keys(propertyValues).some(
                        (p1) => propertyValues[p1] !== numberOfNodes
                    );
                });
            }

            function getPropertyDisplayName(property) {
                return property === 'value' ? 'Value' : property;
            }

            function movePropertyToTop(arr, property) {
                const propertyIndex = arr.indexOf(property);

                if (propertyIndex !== -1) {
                    arr.splice(propertyIndex, 1);
                    arr.unshift(property);
                }
            }

            function bookKeepMetadata(book, metadata) {
                Object.keys(metadata)
                    .filter((k) => {
                        // filter out properties that:
                        return (
                            !k.match(/^_/) && // begin with underscores (e.g. d3 created properties)
                            typeof metadata[k] !== 'object' && // have objects for values
                            HIDDEN_PROPERTIES.indexOf(k) === -1
                        ); // are hidden
                    })
                    .forEach((property) => {
                        if (!book[property]) {
                            book[property] = {};
                        }

                        const currentValue = metadata[property];

                        if (!book[property][currentValue]) {
                            book[property][currentValue] = 1;
                        } else {
                            book[property][currentValue]++;
                        }
                    });
            }

            function sort(a, b, asc) {
                const toCompareA = a || '';
                const toCompareB = b || '';

                if (asc) {
                    return toCompareA.localeCompare(toCompareB);
                } else {
                    return toCompareB.localeCompare(toCompareA);
                }
            }

            function getConstrainedUnitsPerRow(aspectRatio, numNodes, suggestedUnitsPerRow) {
                if ($ctrl.fixedUnitsPerRow) {
                    return $ctrl.fixedUnitsPerRow;
                } else if ($ctrl.groupBy && $ctrl.groupBy.length) {
                    return suggestedUnitsPerRow;
                } else {
                    return Math.ceil(Math.sqrt(aspectRatio * numNodes));
                }
            }

            function getConstrainedAspectRatio(aspectRatio) {
                if ($ctrl.groupBy && $ctrl.groupBy.length && !$ctrl.fixedUnitsPerRow) {
                    return aspectRatio;
                } else {
                    return 1;
                }
            }

            function getPalette() {
                const color = $ctrl.colorOverride || SIGNALFX_GREEN;
                const numShades = $ctrl.autoGradients || 5;

                return colorService.getShades(color, Math.floor(numShades / 2));
            }

            function setColorRangeFuncs() {
                const palette = getPalette();

                dynamicColorRangeFunction = d3.scale.quantile().domain([0, 100]).range(palette);
            }

            function updateHeatmapPlot() {
                const chartConfig = $ctrl.chartModel.sf_uiModel.chartconfig || {};

                $ctrl.sharedChartConfig = $ctrl.sharedChartConfig || {};
                $ctrl.sharedChartConfig.heatmapPlotConfig =
                    $ctrl.sharedChartConfig.heatmapPlotConfig || {};

                if (!chartConfig.groupBy) {
                    chartConfig.groupBy = [];
                }
                if (!chartConfig.colorByValueScale) {
                    chartConfig.colorByValueScale = [];
                }
                if (!chartConfig.heatmapColorRange) {
                    chartConfig.heatmapColorRange = {};
                }
                if (!chartConfig.heatmapSortBy) {
                    chartConfig.heatmapSortBy = { asc: true, value: {} };
                }
                if (!angular.isDefined(chartConfig.heatmapUnitsPerRow)) {
                    chartConfig.heatmapUnitsPerRow = 0;
                }
                if (!angular.isDefined(chartConfig.heatmapAutoGradients)) {
                    chartConfig.heatmapAutoGradients = 5;
                }
                if (!angular.isDefined(chartConfig.heatmapUseValueAsColor)) {
                    chartConfig.heatmapUseValueAsColor = false;
                }
                if (!angular.isDefined(chartConfig.hideTimestamp)) {
                    chartConfig.hideTimestamp = false;
                }

                handlePlotChange();
            }

            function updateVisibilities() {
                const tsidToPlot = Object.entries(metadataMap).reduce((map, [tsid, metadata]) => {
                    map[tsid] = chartDisplayUtils.getPlotObject(metadata, $ctrl.chartModel);
                    return map;
                }, {});

                visibleTSIDs = Object.entries(tsidToPlot)
                    .filter((entry) => !entry[1].invisible)
                    .map((entry) => entry[0]);

                $timeout.cancel(plotTimeout);
                plotTimeout = $timeout(
                    () => {
                        $ctrl.loading = true;
                        const data = dataPoints || [];

                        if (data.length) {
                            updateData(data);
                        }

                        const metadata = metadataMap;

                        if (metadata) {
                            $ctrl.sharedChartConfig.heatmapPlotConfig.hasMetadata = true;
                            setMode();
                        } else {
                            $ctrl.sharedChartConfig.heatmapPlotConfig.hasMetadata = false;
                            $ctrl.modeSet = false;
                            $timeout.cancel(modeSetTimeout);
                            $ctrl.sharedChartConfig.heatmapPlotConfig.toolTip = [];
                        }

                        redraw();
                        $timeout(
                            function () {
                                $ctrl.loading = false;
                                chartLoadedEvent(200);
                            },
                            100,
                            false
                        );
                    },
                    200,
                    false
                );
            }

            function refreshVisualization() {
                $timeout.cancel(redrawTimeout);

                redrawTimeout = $timeout(function () {
                    setColorRangeFuncs();
                    updateDynamicColorRange(currentMinimum, currentMaximum);
                    redraw();
                }, 200);
            }

            function coloringFunction(ts) {
                const value = ts.value || 0;

                if ($ctrl.useValueAsColor) {
                    return colorService.hexForRgbInt(ts.value);
                } else {
                    let color = getClosest(value);
                    if (!color) {
                        color = dynamicColorRangeFunction(value);
                    }
                    return color;
                }
            }

            function getClosest(value) {
                const scale = $ctrl.colorByValueScale;
                const hasFixedThreshold = $ctrl.colorByValue && scale && scale.length;

                return hasFixedThreshold
                    ? colorByValueService.getColorForValue(scale, value)
                    : null;
            }

            function handleSortyChange() {
                const data = dataPoints || [];
                if (data.length) {
                    if (metadataMap) {
                        setMode();
                    }
                }
                delayedRedraw(50);
            }

            function handlePlotChange() {
                // There should be exactly one plot being represented by the heatmap
                const plotConfig =
                    $ctrl.allPlots.filter((plot) => {
                        return !plot.invisible && !plot.transient;
                    }) || [];

                if (plotConfig.length && plotConfig[0].configuration) {
                    $ctrl.sharedChartConfig.heatmapPlotConfig.prefix =
                        plotConfig[0].configuration.prefix || '';
                    $ctrl.sharedChartConfig.heatmapPlotConfig.suffix =
                        plotConfig[0].configuration.suffix || '';
                }
            }

            function resize() {
                $timeout.cancel(resizeTimeout);
                resizeTimeout = $timeout(
                    function () {
                        setDimensions();
                        $ctrl.heatmap.resize();
                    },
                    200,
                    false
                );
            }

            function reset() {
                missingDatapointCounter = {};
                dataPoints = [];
                metadataMap = {};

                // mark heatmap empty
                $ctrl.hasData = false;
                $ctrl.heatmap.hasDataValues(false);
                if ($ctrl.sharedChartConfig) {
                    $ctrl.sharedChartConfig.heatmapPlotConfig.hasMetadata = false;
                    $ctrl.sharedChartConfig.heatmapPlotConfig.waitingForData = true;
                }

                // cancel calls to debounced functions that might be in flight
                $timeout.cancel(modeSetTimeout);
                $timeout.cancel(waitingForDataTimeout);
            }

            function updateGroupBy() {
                $ctrl.heatmap.groupBy($ctrl.groupBy || []);
            }
        },
    ],
};
