import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.alertsOverview').service('eventAlertService', [
    'eventService',
    '$q',
    'urlOverridesService',
    function (eventService, $q, urlOverridesService) {
        //Apply custom time if available or create one from global Time picker
        function applyTime(params, time) {
            const timepicker = time || urlOverridesService.getGlobalTimePicker();
            if (timepicker) {
                if (timepicker.relative) {
                    if (timepicker.start) {
                        params.from = convertStringToMS(timepicker.start);
                    }
                    if (timepicker.end && timepicker.end !== 'Now') {
                        params.to = convertStringToMS(timepicker.end);
                    }
                } else {
                    if (timepicker.start) {
                        params.from = timepicker.start;
                    }
                    if (timepicker.end) {
                        params.to = timepicker.end;
                    }
                }
            }
        }

        function getConfig(aggregatedOn, aggregatedValues, baseQuery, defaultSearchFields) {
            return {
                getEvents: function (query, max, offset = 0, time = null) {
                    const params = {
                        query: query || '*',
                        limit: max,
                        offset: offset,
                    };
                    applyTime(params, time);
                    return eventService.search(params).then(function (result) {
                        return result.map(function (event) {
                            return angular.extend({}, event.properties, event.metadata, {
                                id: event.id, //for modal open
                                sf_id: event.id, //for ng-repeat track
                                sf_tsId: event.tsId,
                                timestamp: event.timestamp,
                            });
                        });
                    });
                },
                getExtras: function () {
                    return $q.when([]);
                },
                aggregation: function (query) {
                    const params = {
                        query: query || '*',
                        aggregation: aggregatedOn,
                    };
                    applyTime(params);
                    return eventService.termAggregation(params);
                },
                processEvent: function (event) {
                    return {
                        description: JSON.stringify(event),
                        event: event,
                    };
                },
                getBaseEventQuery: function () {
                    return baseQuery;
                },
                showTimePicker: true,
                severityProperty: aggregatedOn,
                severities: aggregatedValues,
                objectTypes: ['EventTimeSeries'],
                defaultSearchFields: defaultSearchFields,
                hasLowerCaseIndex: false,
                alertMode: false,
            };
        }

        return {
            bounce: getConfig(
                'type',
                ['Bounce'],
                ['sf_eventType:"NotificationDeliveryFailed"'].join(' AND '),
                ['destination', 'ttl']
            ),
            elog: getConfig(
                'level',
                ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'],
                ['_exists_:"level"', 'sf_eventType:"elog"'].join(' AND '),
                ['message', 'trace_chain', 'exception', 'exception_message']
            ),
            exceptions: getConfig(
                'level',
                [],
                ['sf_eventCategory:"EXCEPTION"', 'sf_eventType:"elog"'].join(' AND '),
                ['message', 'trace_chain', 'exception', 'exception_message']
            ),
        };
    },
]);
