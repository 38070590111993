export default [
    'urlOverridesService',
    'dashboardVariablesService',
    'dashboardUtil',
    '$location',
    function (urlOverridesService, dashboardVariablesService, dashboardUtil, $location) {
        return function setUrlParamsForSnapshot(snapshot, dashboard) {
            if (!snapshot.id || !(dashboard || snapshot.savedFilters)) return;

            const sourceOverride = urlOverridesService.getSourceOverride();
            const variablesOverride = dashboardVariablesService.getVariablesUrlOverrideAsModel();
            const timePickerOverride = urlOverridesService.getGlobalTimePicker();
            const pointDensityOverride = urlOverridesService.getPointDensity();

            const currentFilters = {
                sources: sourceOverride,
                variables: variablesOverride,
                time: timePickerOverride,
                density: pointDensityOverride,
            };

            const savedFilters =
                snapshot.savedFilters || dashboardUtil.getSavedDashboardFilters(dashboard);
            if (dashboardUtil.setFilterOverrides(savedFilters, currentFilters)) {
                $location.replace();
            }
        };
    },
];
