export default [
    'dashboardVariablesService',
    'sourceFilterService',
    'timepickerUtils',
    'urlOverridesService',
    function (
        dashboardVariablesService,
        sourceFilterService,
        timepickerUtils,
        urlOverridesService
    ) {
        const getTroubleshootIndexParams = function (chart, filterAlias) {
            const environments = [];
            const services = [];
            const operations = [];
            const workflows = [];

            // APMF-2179: get time info from chart options
            const chartStartTime =
                chart && chart.options && chart.options.time && chart.options.time.range
                    ? timepickerUtils.msToRelative(chart.options.time.range)
                    : '-15m';

            // retrieve time info from time picker or chart options
            const globalTimePicker = urlOverridesService.getGlobalTimePicker();
            const urlPicker =
                timepickerUtils.getURLParamStringForTimePicker(globalTimePicker) ||
                `startTime=${chartStartTime}`;

            // retrieve tracing info from dashboard variables and overrides
            const variables = dashboardVariablesService.applyVariableOverridesToVariableModel(
                filterAlias || []
            );
            const overrides = sourceFilterService.getSourceFilters(
                urlOverridesService.getSourceOverride() || []
            );
            variables.concat(overrides).forEach((variable) => {
                if (variable.propertyValue) {
                    variable.value = angular.isArray(variable.propertyValue)
                        ? variable.propertyValue
                        : [variable.propertyValue];
                }

                if (variable.property === 'sf_environment') {
                    environments.push(...variable.value);
                } else if (variable.property === 'sf_service') {
                    services.push(...variable.value);
                } else if (variable.property === 'sf_operation') {
                    operations.push(...variable.value);
                } else if (variable.property === 'sf_workflow') {
                    workflows.push(...variable.value);
                }
            });

            return getAPM2TroubleshootIndexParams(
                urlPicker,
                environments,
                services,
                operations,
                workflows
            );
        };

        /**
         * Conversion of TagInput to APM2 TagFilterInput format. Assumes all tag-value pairs use an "IN" operation.
         */
        function convertToAPM2TagFilterInput(tag) {
            return {
                tag: tag.tagName,
                operation: 'IN',
                values: tag.values,
                inverted: false,
            };
        }

        function getAPM2TroubleshootIndexParams(
            urlPicker,
            environments,
            services,
            operations,
            workflows
        ) {
            const globalTagInputs = [];
            const spanTagInputs = [];
            const selectedTag = [];

            if (environments.length > 0 && environments[0] !== '*') {
                globalTagInputs.push(
                    convertToAPM2TagFilterInput({ tagName: 'sf_environment', values: environments })
                );
            }

            if (workflows.length > 0 && workflows[0] !== '*') {
                globalTagInputs.push(
                    convertToAPM2TagFilterInput({ tagName: '_sf_workflow', values: [workflows[0]] })
                );
            }

            if (services.length === 1 && operations.length === 1) {
                const tagInput = [];
                tagInput.push(
                    convertToAPM2TagFilterInput({ tagName: 'sf_service', values: services })
                );
                tagInput.push(
                    convertToAPM2TagFilterInput({ tagName: 'sf_endpoint', values: operations })
                );
                spanTagInputs.push({
                    tags: tagInput,
                });
                selectedTag.push({ tagName: 'sf_service', value: services[0] });
                selectedTag.push({ tagName: 'sf_endpoint', value: operations[0] });
            } else if (services.length > 0 && operations.length === 0) {
                services.forEach((service) =>
                    spanTagInputs.push({
                        tags: [
                            convertToAPM2TagFilterInput({
                                tagName: 'sf_service',
                                values: [service],
                            }),
                        ],
                    })
                );
                if (services.length === 1) {
                    selectedTag.push({ tagName: 'sf_service', value: services[0] });
                }
            }

            const queryParams = {
                traceFilter: {
                    tags: globalTagInputs,
                },
                spanFilters: spanTagInputs,
            };

            return (
                `filters=${encodeURIComponent(JSON.stringify(queryParams))}&${urlPicker}` +
                `${
                    selectedTag.length > 0
                        ? `&selected=${encodeURIComponent(
                              JSON.stringify([{ nodeTags: selectedTag }])
                          )}`
                        : ''
                }`
            );
        }

        return {
            getTroubleshootIndexParams,
        };
    },
];
