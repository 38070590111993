import templateUrl from './detectorRichFormEditor.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';
import { CHART_TYPE, DETECTOR_TYPE } from '../../common/data/apiv2/getOrgScopeErrorHandler';

export default {
    templateUrl,
    controller: [
        '$scope',
        'title',
        'detectorService',
        'chartV2Service',
        'signalboostUtil',
        'snapshotLoadService',
        'getOrgScopeErrorHandler',
        'currentUser',
        function (
            $scope,
            title,
            detectorService,
            chartV2Service,
            signalboostUtil,
            snapshotLoadService,
            getOrgScopeErrorHandler,
            currentUser
        ) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;

            function initialize() {
                resolveDetectorData.then((data) => {
                    $scope.data = {
                        updateTitle: title.updateTitle,
                        data,
                    };
                });
            }

            function resolveDetectorData() {
                if (ngRoute.params.fromDetector) {
                    return detectorService
                        .get(ngRoute.params.fromDetector, getOrgScopeErrorHandler(DETECTOR_TYPE))
                        .then(function (detector) {
                            return signalboostUtil.initializeOrgForObj(detector);
                        })
                        .then(function (detector) {
                            return { detector: detectorService.cloneV1(detector) };
                        });
                } else if (ngRoute.params.fromChart || ngRoute.params.fromSnapshot) {
                    const chartId = ngRoute.params.fromChart;
                    const snapshotId = ngRoute.params.fromSnapshot;
                    const chartIndex = ngRoute.params.chartId;

                    let chartPromise = null;
                    if (chartId) {
                        chartPromise = chartV2Service.get(
                            chartId,
                            getOrgScopeErrorHandler(CHART_TYPE)
                        );
                    } else {
                        chartPromise = snapshotLoadService(snapshotId).then(function (snapshot) {
                            if (chartIndex && chartIndex.startsWith('SYNTH_')) {
                                return snapshot.payload.charts.filter(function (currentChart) {
                                    return currentChart.id === chartIndex;
                                })[0];
                            } else {
                                return snapshot.payload.chart;
                            }
                        });
                    }
                    return chartPromise
                        .then(function (chart) {
                            return signalboostUtil.initializeOrgForObj(chart);
                        })
                        .then(function (chart) {
                            const detector = detectorService.newV1();

                            detector.sf_detector = chart.sf_chart + ' Detector';
                            detector.sf_uiModel.fromChart = chartId;

                            //until we're ready to support it properly, do not allow event plot imports
                            detector.sf_uiModel.allPlots = chart.sf_uiModel.allPlots.filter(
                                function (plot) {
                                    return plot.type !== 'event';
                                }
                            );

                            detector.sf_uiModel.currentUniqueKey =
                                chart.sf_uiModel.currentUniqueKey;
                            return { detector: detector };
                        });
                } else {
                    // Ensures the orgId is in the cache
                    return currentUser.orgId().then(function () {
                        return { detector: detectorService.newV1() };
                    });
                }
            }
        },
    ],
};
