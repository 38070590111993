import templateUrl from './detailedDetectorView.tpl.html';

export default {
    templateUrl,
    bindings: {
        model: '<',
        chartConfigOverride: '<',
        rollupMessage: '<',
        sharedChartState: '<',
        detectorPreviewConfig: '<',
        legendDataTableClassName: '@',
        passChartAndEventInformation: '<',
        legendEventsClassName: '@',
    },
    controller: [
        'DETECTOR_CHART_IDENTIFIERS',
        function (DETECTOR_CHART_IDENTIFIERS) {
            const $ctrl = this;
            $ctrl.detectorChartIdentifiers = DETECTOR_CHART_IDENTIFIERS;
            $ctrl.jobFeedback = { [DETECTOR_CHART_IDENTIFIERS.MAIN]: [] };
        },
    ],
};
