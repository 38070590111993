import templateUrl from './newrelicIntegration.html';

angular
    .module('sfx.ui')
    .constant('NEWRELIC_PRODUCTS', {
        APM: 'APM',
        MOBILE: 'Mobile',
        SERVERS: 'Servers',
    })
    .constant('NEWRELIC_FILTER_KEYS', {
        APM: 'applications',
        MOBILE: 'mobile_applications',
        SERVERS: 'servers',
    })
    /**
     * Controls the JSON representation of the credentials object for New Relic.
     *
     * The JSON should look like::
     *
     *    {
     *        type: 'NewRelic',
     *        name: 'New Relic',
     *        apiKey: '',
     *        products: ['APM', 'Mobile']
     *        apmFilters: {
     *          filterDomains: [],
     *          filterMetricNames: [],
     *          filterStats: []
     *        },
     *        mobileFilters: {
     *          filterDomains: ['Mobile test'],
     *          filterMetricNames: [],
     *          filterStats: []
     *        }
     *        serverFilters: {
     *          filterDomains: [],
     *          filterMetricNames: [],
     *          filterStats: []
     *        }
     *    }
     */
    .directive('sfxNewrelicIntegration', [
        '_',
        'NEWRELIC_PRODUCTS',
        'NEWRELIC_FILTER_KEYS',
        function (_, NEWRELIC_PRODUCTS, NEWRELIC_FILTER_KEYS) {
            return {
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.NEWRELIC_PRODUCTS = _.values(NEWRELIC_PRODUCTS);

                        const copyValidRules = function (syncRules) {
                            const toReturn = {};
                            Object.keys(syncRules)
                                .filter(function (f) {
                                    return syncRules[f];
                                })
                                .map(function (f) {
                                    toReturn[f] = syncRules[f];
                                });
                            return toReturn;
                        };

                        let options;

                        const setValidity = function () {
                            $scope.flags.preValidate = !$scope.credential.id;
                            if (!$scope.credential.id) {
                                return;
                            }
                            const rules = options.filterdomains || {};
                            const products = $scope.credential.products || [];
                            const validDomains = Object.keys(rules).filter(function (f) {
                                return rules[f];
                            }).length;
                            if (!validDomains || validDomains !== products.length) {
                                $scope.integrationForm.$setDirty();
                                $scope.integrationForm.$setValidity('domains', false);
                                $scope.credential.enabled = false;
                                $scope.flags.isIncomplete = true;
                            } else {
                                $scope.integrationForm.$setValidity('domains', true);
                                $scope.credential.enabled = $scope.originalEnabled;
                                $scope.flags.isIncomplete = false;
                            }
                        };

                        const reset = function () {
                            $scope.credential.name = $scope.credential.name || 'New Relic';
                            options = getFilters();
                            $scope.temp = {
                                apiKey: '',
                                products: ($scope.credential.products || [])
                                    .map(function (product) {
                                        return NEWRELIC_PRODUCTS[product];
                                    })
                                    .slice(),
                                domains: options.filterdomains || {},
                                metrics: options.filtermetricnames || {},
                                stats: options.filterstats || {},
                            };
                            if (!$scope.credential.id) {
                                $scope.credential.enabled = false;
                                $scope.originalEnabled = true;
                            } else {
                                $scope.originalEnabled = !!$scope.credential.enabled;
                                setValidity();
                            }
                            $scope.sharedCredentialState.updateCredentials = false;
                        };
                        reset();

                        $scope.$on('reset', reset);
                        $scope.$on('credentials saved', setValidity);
                        $scope.$on('credentials enabled', function () {
                            $scope.originalEnabled = true;
                            setValidity();
                        });
                        $scope.$on('credentials disabled', function () {
                            $scope.originalEnabled = false;
                            setValidity();
                        });
                        $scope.$watch('temp.apiKey', function (newValue) {
                            if (newValue) {
                                $scope.credential.apiKey = newValue;
                                $scope.sharedCredentialState.updateCredentials = true;
                            } else {
                                $scope.credential.apiKey = null;
                            }
                        });
                        // The backend expects product names to be all upper case, so
                        // upperCase them when they change.
                        $scope.$watchCollection('temp.products', function () {
                            $scope.credential.products = $scope.temp.products.map(function (s) {
                                return s.toUpperCase();
                            });
                            setValidity();
                        });

                        $scope.$watchCollection('temp.domains', function (nval, oval) {
                            $scope.filterdomains = copyValidRules($scope.temp.domains);
                            const domains = $scope.filterdomains;
                            if (!angular.equals(nval, oval)) {
                                $scope.originalEnabled = Object.keys(domains).length !== 0;
                            }
                            setValidity();
                            updateFilters();
                        });
                        $scope.$watchCollection('temp.metrics', function () {
                            $scope.filtermetricnames = copyValidRules($scope.temp.metrics);
                            updateFilters();
                        });
                        $scope.$watchCollection('temp.stats', function () {
                            $scope.filterstats = copyValidRules($scope.temp.stats);
                            updateFilters();
                        });

                        function updateFilters() {
                            $scope.credential.apmFilters = createNewRelicIntegrationFilters(
                                NEWRELIC_FILTER_KEYS.APM
                            );
                            $scope.credential.mobileFilters = createNewRelicIntegrationFilters(
                                NEWRELIC_FILTER_KEYS.MOBILE
                            );
                            $scope.credential.serverFilters = createNewRelicIntegrationFilters(
                                NEWRELIC_FILTER_KEYS.SERVERS
                            );
                        }

                        function createNewRelicIntegrationFilters(key) {
                            const filter = {};
                            if ($scope.filterdomains && $scope.filterdomains[key]) {
                                filter.filterDomains = $scope.filterdomains[key];
                            }

                            if ($scope.filtermetricnames && $scope.filtermetricnames[key]) {
                                filter.filterMetricNames = $scope.filtermetricnames[key];
                            }

                            if ($scope.filterstats && $scope.filterstats[key]) {
                                filter.filterStats = $scope.filterstats[key];
                            }

                            return filter;
                        }

                        function getFilters() {
                            const options = {};
                            const filterDomainsMap = {};
                            const filterMetricNamesMap = {};
                            const filterStatsMap = {};

                            const newRelicIntegrationFiltersMap = {};
                            newRelicIntegrationFiltersMap[NEWRELIC_FILTER_KEYS.APM] =
                                $scope.credential.apmFilters;
                            newRelicIntegrationFiltersMap[NEWRELIC_FILTER_KEYS.MOBILE] =
                                $scope.credential.mobileFilters;
                            newRelicIntegrationFiltersMap[NEWRELIC_FILTER_KEYS.SERVERS] =
                                $scope.credential.serverFilters;

                            angular.forEach(newRelicIntegrationFiltersMap, (filter, key) => {
                                if (filter) {
                                    if (filter.filterDomains && filter.filterDomains.length) {
                                        if (!filterDomainsMap[key]) {
                                            filterDomainsMap[key] = [];
                                        }
                                        filterDomainsMap[key] = filterDomainsMap[key].concat(
                                            filter.filterDomains
                                        );
                                    }

                                    if (
                                        filter.filterMetricNames &&
                                        filter.filterMetricNames.length
                                    ) {
                                        if (!filterMetricNamesMap[key]) {
                                            filterMetricNamesMap[key] = [];
                                        }
                                        filterMetricNamesMap[key] = filterMetricNamesMap[
                                            key
                                        ].concat(filter.filterMetricNames);
                                    }

                                    if (filter.filterStats && filter.filterStats.length) {
                                        if (!filterStatsMap[key]) {
                                            filterStatsMap[key] = [];
                                        }
                                        filterStatsMap[key] = filterStatsMap[key].concat(
                                            filter.filterStats
                                        );
                                    }
                                }
                            });

                            options.filterdomains = filterDomainsMap;
                            options.filtermetricnames = filterMetricNamesMap;
                            options.filterstats = filterStatsMap;

                            return options;
                        }
                    },
                ],
                require: '^form',
                restrict: 'E',
                templateUrl,
            };
        },
    ]);
