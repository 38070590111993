angular.module('signalview.legal').controller('GdprController', [
    '$log',
    '$scope',
    '$uibModalInstance',
    'legalService',
    'previouslyAgreed',
    'userV2Service',
    function ($log, $scope, $uibModalInstance, legalService, previouslyAgreed, userV2Service) {
        // Need this to be a boolean for checkbox default value
        $scope.previouslyAgreed = $scope.currentAgreement = !!previouslyAgreed;

        $scope.privacyPolicy = legalService.privacyPolicy;
        $scope.termsOfService = legalService.termsOfService;

        $scope.processing = false;

        $scope.confirm = function () {
            $scope.processing = true;
            return userV2Service
                .agreeToTerms()
                .then(function () {
                    $scope.processing = false;
                    $log.info('User confirmation saved.');
                    $uibModalInstance.close();
                })
                .catch((e) => {
                    $scope.processing = false;
                    $log.error('Failed to save user confirmation.', e);
                });
        };
    },
]);
