import React, { FC } from 'react';
import { Route, Switch, useInitializeRoute } from './ReactRouteUtils';
import { Ng2React } from '../../common/util/Ng2React';
import { mutingList } from '../../legacy/app/alerts/muting/mutingList';
import { Redirect, useLocation } from 'react-router-dom';
import detectorV1New from '../../legacy/app/detector/detectorV1New';
import detectorV1Edit from '../../legacy/app/detector/detectorV1Edit';
import detectorV2New from '../../legacy/app/signalflowv2/detector/detectorV2New';
import detectorV2Router from '../../legacy/app/signalflowv2/detector/detectorV2Router';

export const DetectorRoutes: FC = () => {
    const routeUtilsBootStrap = useInitializeRoute();
    const { search } = useLocation();

    return (
        <Switch>
            <Redirect
                from="/detector/:id/edit"
                to={{ pathname: '/detector/v2/:id/edit', search }}
            />

            <Route exact path="/detector/new" title="New Detector" straps={[routeUtilsBootStrap]}>
                <Ng2React name="detectorV1New" definition={detectorV1New} />
            </Route>

            <Route
                exact
                path="/detector/v1/:id/edit"
                title="Detector"
                straps={[routeUtilsBootStrap]}
            >
                <Ng2React name="detectorV1Edit" definition={detectorV1Edit} />
            </Route>

            <Route
                exact
                path="/detector/v2/new"
                title="New Detector"
                straps={[routeUtilsBootStrap]}
            >
                <Ng2React name="detectorV2New" definition={detectorV2New} />
            </Route>

            <Route
                exact
                path="/detector/v2/:id/edit"
                title="Detector"
                straps={[routeUtilsBootStrap]}
            >
                <Ng2React name="detectorV2Router" definition={detectorV2Router} />
            </Route>

            <Route
                exact
                path={['/muting', '/muting/:orgID']}
                title="Muting Rules"
                straps={[routeUtilsBootStrap]}
            >
                <Ng2React name="mutingList" definition={mutingList} />
            </Route>
        </Switch>
    );
};
