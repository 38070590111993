export default [
    'integrationsMeta',
    'METRICS_CATALOG_CONSTANTS',
    function (integrationsMeta, METRICS_CATALOG_CONSTANTS) {
        return {
            getIntegrationsByColumn,
            getFinalListOfIntegrations,
            getIntegrationsByCategory,
            getDisplayName,
            getSortedCategorizedIntegration,
            chunkArray,
            getFilterForIntegration,
            getTopRecentlyAddedIntegrations,
        };

        // Takes in active integrations list and returns sorted categorized list chunked into columns
        function getIntegrationsByColumn(activeIntegrations) {
            const finalList = getSortedCategorizedIntegration(activeIntegrations);
            addIntegrationButton(finalList);
            const totalIntegrations = finalList.length;

            return chunkArray(finalList, getItemsPerColumn(totalIntegrations));
        }

        // Takes in active integrations list and returns sorted categorized list
        function getSortedCategorizedIntegration(activeIntegrations) {
            const integrationsByCategory = getIntegrationsByCategory(activeIntegrations);
            return getFinalListOfIntegrations(integrationsByCategory);
        }

        function getItemsPerColumn(total) {
            return Math.ceil(total / METRICS_CATALOG_CONSTANTS.INTEGRATION_COLUMN_COUNT);
        }

        function chunkArray(arr, chunkSize) {
            const results = [];
            if (chunkSize <= 0) {
                results.push(arr);
                return results;
            }
            while (arr.length) {
                results.push(arr.splice(0, chunkSize));
            }
            return results;
        }

        function getFinalListOfIntegrations(integrationsByCategory) {
            const finalList = [];
            // Go through the integration categories as they appear in this list
            METRICS_CATALOG_CONSTANTS.INTEGRATION_CATEGORIES.forEach((category) => {
                if (
                    !integrationsByCategory[category] ||
                    integrationsByCategory[category].length === 0
                ) {
                    return;
                }
                finalList.push({
                    type:
                        category === METRICS_CATALOG_CONSTANTS.RECENTLY_ADDED_CATEGORY_NAME
                            ? METRICS_CATALOG_CONSTANTS.ITEM_TYPES.RECENTLY_ADDED_CATEGORY
                            : METRICS_CATALOG_CONSTANTS.ITEM_TYPES.INTEGRATION_CATEGORY,
                    name: category,
                    data: {
                        values: integrationsByCategory[category],
                    },
                });
            });

            return finalList;
        }

        function addIntegrationButton(finalList) {
            finalList.push({
                type: METRICS_CATALOG_CONSTANTS.ITEM_TYPES.BUTTON,
                data: {
                    title: 'Add integration',
                    href: '#/integrations',
                },
            });
        }

        function getIntegrationsByCategory(activeIntegrationsList) {
            const integrationsByCategory = {};
            const recentlyAddedCandidates = [];
            const currentTime = Date.now();

            activeIntegrationsList.forEach((integrationObj) => {
                const meta = integrationsMeta[integrationObj.name];
                if (!meta) {
                    return;
                }

                // Only include integrations with data_signature
                // Otherwise, there would be no way to filter by it on the browse
                // page.
                if (!meta.data_signature) {
                    return;
                }

                const browseCategories = meta.browse_categories;
                if (!browseCategories) {
                    return;
                }

                if (
                    integrationObj.created &&
                    currentTime - integrationObj.created <=
                        METRICS_CATALOG_CONSTANTS.RECENTLY_ADDED_INTEGRATIONS_THRESHOLD_MS
                ) {
                    recentlyAddedCandidates.push(integrationObj);
                }

                browseCategories.forEach((category) => {
                    if (!integrationsByCategory[category]) {
                        integrationsByCategory[category] = [];
                    }
                    integrationsByCategory[category].push(integrationObj.name);
                });
            });

            integrationsByCategory[METRICS_CATALOG_CONSTANTS.RECENTLY_ADDED_CATEGORY_NAME] =
                getTopRecentlyAddedIntegrations(recentlyAddedCandidates);
            return integrationsByCategory;
        }

        function getDisplayName(integrationName) {
            return integrationsMeta[integrationName].display_name;
        }

        function getFilterForIntegration(integrationName) {
            const dataSignature = integrationsMeta[integrationName].data_signature;
            // Remove double quotes from the data signature so it can be used as a filter string
            return dataSignature.replace(/['"]+/g, '');
        }

        function getTopRecentlyAddedIntegrations(candidateList) {
            const list = _.orderBy(candidateList, 'created', 'desc').map((obj) => obj.name);
            return list.splice(0, METRICS_CATALOG_CONSTANTS.RECENTLY_ADDED_INTEGRATIONS_LIMIT);
        }
    },
];
