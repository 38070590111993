/*
  High-level service for managing common alerting workflows, such as showing
  incident modals.
*/
class AlertingService {
    constructor(eventService, eventModal, alertTypeService, confirmService) {
        this.eventService = eventService;
        this.eventModal = eventModal;
        this.alertTypeService = alertTypeService;
        this.confirmService = confirmService;
    }

    openIncidentModal(alert) {
        const incidentId = alert.incidentId || alert.sf_incidentId;
        const firingStateQuery = this.alertTypeService.getFiringStateQueryStr();

        return this.eventService
            .search({
                query: 'incidentId:"' + incidentId + '" AND is:(' + firingStateQuery + ')',
                orderBy: '-sf_timestamp',
                limit: 1,
                offset: 0,
            })
            .then((data) => {
                if (data && data.length) {
                    return data[0];
                } else {
                    // cant find event? should not happen in real life.
                    throw new Error('Event cannot be found');
                }
            })
            .catch((error) => {
                // show error message
                this.confirmService.confirm({
                    title: 'Error',
                    text: [error.message],
                    yesText: 'Close',
                    noText: '',
                });
            })
            .then(this.eventModal);
    }
}

AlertingService.$inject = ['eventService', 'eventModal', 'alertTypeService', 'confirmService'];

export default AlertingService;
