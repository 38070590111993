import templateUrl from './awsMetricsAndTags.tpl.html';

angular.module('sfx.ui').component('awsMetricsAndTags', {
    templateUrl,
    bindings: {
        filter: '<',
        integrationForm: '<',
        editable: '<',
    },
    controller: [
        '$timeout',
        '$element',
        function ($timeout, $element) {
            const ctrl = this;
            const metricInputClassName = '.aws-metric-input';
            const tagInputClassName = '.aws-tag-input';

            ctrl.$onInit = () => {
                ctrl.showTagInput = false;
                ctrl.showMetricInput = false;
                ctrl.filter.tags = ctrl.filter.tags || [];
                ctrl.filter.metrics = ctrl.filter.metrics || [];
            };
            ctrl.toggleTagButton = toggleTagButton;
            ctrl.toggleMetricButton = toggleMetricButton;
            ctrl.addFilters = addFilters;
            ctrl.removeTag = removeTag;
            ctrl.removeMetric = removeMetric;
            ctrl.delayedFocusInput = delayedFocusInput;

            function toggleTagButton(toggleOn) {
                ctrl.showTagInput = !!toggleOn;

                if (toggleOn) {
                    delayedFocusInput($element, tagInputClassName);
                } else {
                    addTagFilters();
                }
            }

            function toggleMetricButton(toggleOn) {
                ctrl.showMetricInput = !!toggleOn;

                if (toggleOn) {
                    delayedFocusInput($element, metricInputClassName);
                } else {
                    addMetricFilters();
                }
            }

            function addFilters(ev, type) {
                if (isTabOrEnter(ev)) {
                    if (type === 'metrics') {
                        addMetricFilters();
                    } else if (type === 'tags') {
                        addTagFilters();
                    }
                    ctrl.integrationForm.$setDirty(true);
                }
            }

            function addTagFilters() {
                if (ctrl.tagFilter) {
                    ctrl.filter.tags.push({
                        name: ctrl.tagFilter,
                    });
                }
                ctrl.tagFilter = '';
            }

            function addMetricFilters() {
                if (ctrl.metricFilter) {
                    ctrl.filter.metrics.push({
                        name: ctrl.metricFilter,
                    });
                }
                ctrl.metricFilter = '';
            }

            function removeTag(tag) {
                const idx = ctrl.filter.tags.indexOf(tag);
                if (idx !== -1) {
                    ctrl.filter.tags.splice(idx, 1);
                    ctrl.integrationForm.$setDirty(true);
                }
            }

            function removeMetric(metric) {
                const idx = ctrl.filter.metrics.indexOf(metric);
                if (idx !== -1) {
                    ctrl.filter.metrics.splice(idx, 1);
                    ctrl.integrationForm.$setDirty(true);
                }
            }

            function isTabOrEnter(ev) {
                return ev.keyCode === 13 || ev.keyCode === 9;
            }

            function delayedFocusInput(elem, className) {
                $timeout(() => {
                    const input = elem.find(className)[0];
                    if (input) {
                        input.focus();
                    }
                });
            }
        },
    ],
});
