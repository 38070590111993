import {
    convertMSToString,
    convertMSToStringWithSingleUnit,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { CALENDAR_WINDOW_CONSTANTS } from '../analytics/util/calendarWindowConstants';

export const DASHBOARD_WINDOW_DEFAULT_TIME = '15m';
const DASHBOARD_WINDOW_TIME_SF_KEY = 'ui.dashboard_window';

export const getDefaultTimeInString = (timeRange, timeInSingleUnit) => {
    const { absoluteStart, absoluteEnd, range, rangeEnd } = timeRange;
    const start = convertToDbTimestamp(absoluteStart || -1 * Math.abs(range));
    const end = convertToDbTimestamp(absoluteEnd || rangeEnd);

    if (timeInSingleUnit) {
        return convertMSToStringWithSingleUnit(end - start);
    } else {
        return convertMSToString(end - start);
    }
};

export const isRelativeTimestamp = (ts) => !ts || ts <= 0;

export const convertToDbTimestamp = (uiTsFormat) => {
    if (isRelativeTimestamp(uiTsFormat)) {
        return Date.now() + (uiTsFormat || 0);
    }
    return uiTsFormat;
};

export const getProgramArgsForDashboardInTime = (timeRange) => ({
    [DASHBOARD_WINDOW_TIME_SF_KEY]: getDefaultTimeInString(timeRange) || null,
});

export const getProgramArgs = (jobOpts, absoluteGlobalTime) => {
    if (!jobOpts.usedByDetectorUI) {
        if (absoluteGlobalTime) {
            return { programArgs: getProgramArgsForDashboardInTime(absoluteGlobalTime) };
        }

        return { programArgs: jobOpts.programArgs };
    }
    if (jobOpts.programArgs) {
        delete jobOpts.programArgs;
    }

    return null;
};

export const convertDashboardWinToMovingWin = (dataManipulation, absDashboardCurrentTime) => {
    const amountAndUnit = absDashboardCurrentTime.match(/(\d+)([^\d]+)/);
    return dataManipulation.map((manipulation) => {
        if (
            manipulation.fn.options.windowType ===
            CALENDAR_WINDOW_CONSTANTS.ANALYTICS_WINDOW_TYPES.DASHBOARD_WINDOW
        ) {
            manipulation.direction.options.transformTimeRange = absDashboardCurrentTime;
            manipulation.direction.options.amount = parseInt(amountAndUnit[1]);
            manipulation.direction.options.unit = amountAndUnit[2];
            manipulation.fn.options = {};
        }
        return manipulation;
    });
};

export const isDashboardTimeWindowSelected = (programText) => {
    if (programText) {
        return programText.includes(DASHBOARD_WINDOW_TIME_SF_KEY);
    }
    return false;
};
