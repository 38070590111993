import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

const className = BEMHelper('simple-spec');

export default function SimpleSpec(props) {
    const labelModifiers = [];
    const valueModifiers = [];

    if (props.inactive) {
        valueModifiers.push('inactive');
    }

    if (props.labelclass) {
        labelModifiers.push(props.labelclass);
    }

    if (React.isValidElement(props.value) && isBtn(props.value)) {
        labelModifiers.push('btn-height');
    }

    return (
        <div {...className()}>
            <div {...className('label', labelModifiers)}>{props.label}</div>
            <div {...className('value', valueModifiers)}>{props.value}</div>
        </div>
    );

    function isBtn(node) {
        return node.props && node.props.className && node.props.className.includes('btn');
    }
}

SimpleSpec.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
    inactive: PropTypes.bool,
    labelclass: PropTypes.string,
};
