import { appNotificationService } from './appNotificationService.js';
import { largeVolumeBarService } from './largeVolumeBarService.js';
import { largeVolumeRow } from './largeVolumeRow.js';
import { largeVolumeModalController } from './largeVolumeModalController.js';
import { largeVolumeModalService } from './largeVolumeModalService.js';

angular
    .module('signalview.appNotifications', ['sfx.modal', 'ui.bootstrap'])
    .service('appNotificationService', appNotificationService)
    .service('largeVolumeBarService', largeVolumeBarService)
    .service('largeVolumeModalService', largeVolumeModalService)
    .component('largeVolumeRow', largeVolumeRow)
    .controller('largeVolumeModalController', largeVolumeModalController)
    .run([
        '$rootScope',
        'largeVolumeBarService',
        function ($rootScope, largeVolumeBarService) {
            $rootScope.$on('current organization changed', initialize);

            function initialize() {
                largeVolumeBarService.initializeNotificationBar();
            }
        },
    ]);
