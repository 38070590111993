import templateUrl from './corpFloatingLogo.tpl.html';

export default {
    templateUrl,
    bindings: {},
    controller: [
        'DOMAIN_INFO',
        function (DOMAIN_INFO) {
            const $ctrl = this;

            $ctrl.hasDomain = !!DOMAIN_INFO;
        },
    ],
};
