import CancelToken from '../../../../common/sfUtil/CancelToken';

export default [
    '$http',
    '$q',
    '$log',
    'createV2Service',
    'currentUser',
    'dashboardV2Service',
    'featureEnabled',
    'organizationService',
    function (
        $http,
        $q,
        $log,
        createV2Service,
        currentUser,
        dashboardV2Service,
        featureEnabled,
        organizationService
    ) {
        const dashboardGroupService = createV2Service('dashboardgroup');
        const v1PropertiesToV2 = {
            sf_page: 'name',
            sf_id: 'id',
            sf_description: 'description',
            sf_dashboards: 'dashboards',
            sf_teams: 'teams',
            sf_created: 'created',
            sf_creator: 'creator',
            sf_updatedOnMs: 'lastUpdated',
            sf_updatedBy: 'lastUpdatedBy',
            sf_serviceDiscoveryVersion: 'serviceDiscoveryVersion',
            sf_importOf: 'importOf',
            sf_email: 'email',
            sf_organizationID: 'organizationId',
        };
        const v2ExcludeProps = ['email', 'serviceDiscoveryVersion', 'organizationId'];

        const crudFuncs = ['create', 'get', 'update', 'search', 'delete'];
        const apiToUIDensityMap = { LOW: 0.5, DEFAULT: null, HIGH: 2, HIGHEST: 4 };
        const uiToAPIDensityMap = {};

        angular.forEach(apiToUIDensityMap, (value, key) => {
            if (value !== null && angular.isDefined(value)) {
                uiToAPIDensityMap[value] = key;
            }
        });

        angular.extend(dashboardGroupService, {
            addDashboardToGroup,
            apiDashboardGroupToUI,
            convertToV1,
            convertToV2,
            getDashboards,
            getDashboardGroups,
            getAllGroupsForIndex,
            getDashboardConfig,
            getOrderedConfigs,
            getOrderedConfigsByDashboardIds,
            getTeamDashboardGroups,
            removeDashboardConfig,
            searchByDashboardId,
            searchByName,
            searchWritableByAny,
            updateTeamLinks,
            updateDashboardConfig,
            updateDashboardOrdering,
            exportGroup,
            importGroup,
            getLinkedTeams,
        });

        crudFuncs.forEach((func) => {
            // Create and update need to be converted going both ways
            if (func === 'create' || func === 'update') {
                const baseFunc = dashboardGroupService[func];
                dashboardGroupService[func] = function (group) {
                    return baseFunc(uiDashboardGroupToAPI(group));
                };
            }

            const unwrappedFunc = dashboardGroupService[func];
            if (func === 'search') {
                dashboardGroupService[func] = function (...args) {
                    return unwrappedFunc.apply(this, args).then((response) => {
                        response.results = response.results.map((group) => {
                            return apiDashboardGroupToUI(group);
                        });
                        return response;
                    });
                };
            } else {
                dashboardGroupService[func] = function (...args) {
                    return unwrappedFunc.apply(this, args).then((group) => {
                        return apiDashboardGroupToUI(group);
                    });
                };
            }
        });

        return dashboardGroupService;

        function apiDashboardGroupToUI(apiGroup) {
            const group = angular.copy(apiGroup);

            if (group?.dashboardConfigs) {
                group.dashboardConfigs = group.dashboardConfigs.map((config) => {
                    const density = apiToUIDensityMap[config.chartDensityOverride];

                    if (config.filtersOverride) {
                        config.filtersOverride.density = density || null;

                        if (config.filtersOverride.time) {
                            config.filtersOverride.time.relative = angular.isString(
                                config.filtersOverride.time.start
                            );
                        }
                    }

                    return config;
                });
            }

            return group;
        }

        function uiDashboardGroupToAPI(uiGroup) {
            const group = angular.copy(uiGroup);

            if (group.dashboardConfigs) {
                group.dashboardConfigs = group.dashboardConfigs.map((config) => {
                    if (config.filtersOverride) {
                        const density = uiToAPIDensityMap[config.filtersOverride.density];
                        config.chartDensityOverride = density || 'DEFAULT';
                        delete config.filtersOverride.density;

                        if (config.filtersOverride.time && config.filtersOverride.time.relative) {
                            delete config.filtersOverride.time.relative;
                        }
                    }

                    if (config.selectedEventOverlaysOverride) {
                        config.selectedEventOverlaysOverride =
                            config.selectedEventOverlaysOverride.map((overlay) => {
                                if (overlay.overlayId) {
                                    return { overlayId: overlay.overlayId };
                                } else {
                                    return overlay;
                                }
                            });
                    }

                    return config;
                });
            }

            return group;
        }

        function exportGroup(id) {
            const resourcePath = '/' + id + '/export';
            return $http
                .get(dashboardGroupService.resourceEndpoint + resourcePath)
                .then((response) => {
                    return response.data;
                });
        }

        function importGroup(pkg) {
            const resourcePath = '/import';
            return $http
                .post(dashboardGroupService.resourceEndpoint + resourcePath, pkg)
                .then((response) => {
                    return response.data;
                });
        }

        function getLinkedTeams(dashboardGroupId) {
            const resourcePath = `/_/${dashboardGroupId}/teams`;
            return $http
                .get(dashboardGroupService.resourceEndpoint + resourcePath)
                .then((response) => response.data);
        }

        function onError(message) {
            return function (error) {
                $log.error(message + ': ', error);
                return $q.reject(error);
            };
        }

        function searchByName(name, cancelToken = new CancelToken()) {
            const deferred = $q.defer();
            cancelToken.cancel = () => deferred.resolve();

            const searchEndpoint =
                dashboardGroupService.resourceEndpoint +
                '?excludeCustom=true' +
                '&' +
                'name=' +
                name;
            return $http
                .get(searchEndpoint, { timeout: deferred.promise })
                .then((resp) => resp.data);
        }

        function searchByDashboardId(dashboardId, cancelToken = new CancelToken()) {
            const deferred = $q.defer();
            cancelToken.cancel = () => deferred.resolve();

            const searchEndpoint =
                dashboardGroupService.resourceEndpoint + '?dashboardId=' + dashboardId;

            return $http
                .get(searchEndpoint, { timeout: deferred.promise })
                .then((resp) => resp.data);
        }

        function searchWritableByAny(query, cancelToken = new CancelToken()) {
            const deferred = $q.defer();
            cancelToken.cancel = () => deferred.resolve();

            const searchEndpoint = dashboardGroupService.resourceEndpoint;
            const params = {
                name: query,
                excludeGenerated: true,
                excludeReadOnly: true,
            };

            return $http
                .get(searchEndpoint, { params, timeout: deferred.promise })
                .then((resp) => resp.data);
        }
        /**
         * Returns dashboard groups of the index dashboard group type which returns configs regardless of whether the
         * organization has views or does not have views.
         *
         * TODO: deprecate this call after switch to linking to teams object
         */
        function getTeamDashboardGroups(teamId) {
            return $http
                .get(`${dashboardGroupService.resourceEndpoint}?teamId=${teamId}&limit=0`, {
                    headers: {
                        accept: 'application/vnd.splunk.observability.dashboardgroup.index+json',
                    },
                })
                .then((resp) => resp.data.results);
        }

        function updateTeamLinks(dashboardGroupId, linkedTeams) {
            return dashboardGroupService.get(dashboardGroupId).then((group) => {
                group.teams = linkedTeams;
                return dashboardGroupService.update(group);
            });
        }

        function getDashboards(dashboardGroupId) {
            return currentUser
                .orgId()
                .then(function (organizationId) {
                    const resourcePath = '/' + dashboardGroupId + '/dashboards';
                    return $http.get(dashboardGroupService.resourceEndpoint + resourcePath, {
                        params: { organizationId },
                    });
                })
                .then((response) => {
                    response.data.dashboards = response.data.dashboards.map((dash) => {
                        return dashboardV2Service.apiDashboardToUI(dash);
                    });
                    return response.data;
                })
                .catch(onError('Failed to retrieve dashboards belonging to DashboardGroup'));
        }

        function getDashboardGroups(dashboardGroupIds, params) {
            return currentUser
                .orgId()
                .then(function (orgId) {
                    params = params || {};
                    params.organizationId = orgId;
                    const resourcePath = '/_/dashboardGroups';
                    return $http.post(
                        dashboardGroupService.resourceEndpoint + resourcePath,
                        dashboardGroupIds,
                        { params }
                    );
                })
                .then((response) => {
                    return response.data;
                }, onError('Batch retrieval of DashboardGroups by ID failed'));
        }

        function getAllGroupsForIndex() {
            return organizationService.get().then((org) => {
                const organizationId = org.id;
                const params = { organizationId };
                const indexEndpoint = dashboardGroupService.resourceEndpoint + '/_/dashboard-index';

                return $http.get(indexEndpoint, { params }).then(({ data }) => data.results);
            });
        }

        function convertToV1(v2Group) {
            if (!v2Group) {
                return;
            }
            if (v2Group.sf_page) {
                return v2Group;
            }

            const v1Group = {};
            Object.entries(v1PropertiesToV2).forEach(([v1Prop, v2Prop]) => {
                if (v2Group[v2Prop]) {
                    v1Group[v1Prop] = v2Group[v2Prop];
                }
            });

            if (v2Group.dashboardConfigs) {
                v1Group.sf_dashboardConfigs = angular.copy(v2Group.dashboardConfigs);
            }

            if (v2Group.authorizedWriters) {
                v1Group.sf_authorizedUserWriters = v2Group.authorizedWriters.users;
                v1Group.sf_authorizedTeamWriters = v2Group.authorizedWriters.teams;
            }

            if (v2Group.hasWritePermission) {
                v1Group.hasWritePermission = v2Group.hasWritePermission;
            }

            v1Group.sf_type = 'Page';
            return v1Group;
        }

        function convertToV2(v1Group) {
            if (v1Group.name) {
                return v1Group;
            }

            const v2Group = {};
            Object.entries(v1PropertiesToV2).forEach(([v1Prop, v2Prop]) => {
                if (!v2ExcludeProps.includes(v1PropertiesToV2[v1Prop]) && v1Group[v1Prop]) {
                    v2Group[v2Prop] = v1Group[v1Prop];
                }
            });

            if (v1Group.sf_dashboardConfigs) {
                v2Group.dashboardConfigs = angular.copy(v1Group.sf_dashboardConfigs);
            }

            v2Group.authorizedWriters = {
                users: v1Group.sf_authorizedUserWriters || [],
                teams: v1Group.sf_authorizedTeamWriters || [],
            };

            v2Group.importQualifiers = v1Group.sf_importQualifiers;

            return v2Group;
        }

        /**
         * Adds the given dashboard to the given group. This should only be invoked
         * in the context of an org that has enabled dashboardViews, or when a
         * dashboard is being created for the first time so that the config object
         * can be created in sbrest.
         *
         * @param groupId: The id of the group to which the dashboard is being added
         * @param dashboardId: The id of the dashboard to be added
         * @param config: The config object to add to the dashboard group to connect
         *                the dashboard to that group. If this is left unused, a
         *                "blank" config object will be created and returned. This
         *                object should not have a configId set.
         *
         * @returns The id of the config object connecting the specified group and
         * dashboard. If a config object was provided, it will return the same
         * object with its configId.
         */
        function addDashboardToGroup(groupId, dashboardId, config = {}) {
            let existingConfigs;
            return dashboardGroupService
                .get(groupId)
                .then((group) => {
                    group.dashboardConfigs = group.dashboardConfigs || [];

                    existingConfigs = group.dashboardConfigs
                        .filter((config) => {
                            return config.dashboardId === dashboardId;
                        })
                        .map((config) => config.configId);

                    config.dashboardId = config.dashboardId || dashboardId;
                    group.dashboardConfigs.push(config);

                    return dashboardGroupService.update(group);
                })
                .then((updatedGroup) => {
                    updatedGroup.dashboardConfigs = updatedGroup.dashboardConfigs || [];

                    const newConfig = updatedGroup.dashboardConfigs.find((config) => {
                        return (
                            config.dashboardId === dashboardId &&
                            !existingConfigs.includes(config.configId)
                        );
                    });

                    if (newConfig) {
                        return newConfig.configId;
                    } else {
                        // Non-views don't receive config objects in the HTTP response
                        return null;
                    }
                });
        }

        /**
         * Retrieves the config object from the given group.
         *
         * @param groupId: The group in which to search for the config object.
         * @param configId: The id of the desired config object.
         *
         * @returns The config object if it exists in the group.
         */
        function getDashboardConfig(groupId, configId) {
            // TODO(trevor): Make a decision about whether these functions will return the object or the id, we should be consistent
            return dashboardGroupService.get(groupId).then((group) => {
                if (group && group.dashboardConfigs) {
                    return group.dashboardConfigs.find((config) => {
                        return config.configId === configId;
                    });
                } else {
                    return null;
                }
            });
        }

        /**
         * Updates the given config object in the given group. Note that the same
         * action can be taken by doing a full dashboard group update. This function
         * is intended to provide a convenient option when the changes made are
         * confined to a dashboard's config overrides.
         *
         * @param groupId: The group to which the config belongs.
         * @param config: The updated config object. This object must contain the
         * configId of the original config object.
         *
         * @returns The config object once the update has succeeded.
         */
        function updateDashboardConfig(groupId, config) {
            return dashboardGroupService.get(groupId).then((group) => {
                const configIndex = (group.dashboardConfigs || []).findIndex(
                    (origConfig) => origConfig.configId === config.configId
                );

                if (configIndex >= 0) {
                    group.dashboardConfigs[configIndex] = config;
                    return dashboardGroupService.update(group).then((updatedGroup) => {
                        // TODO(trevor): Should this be how we handle this? We should expect order to be invariant
                        return updatedGroup.dashboardConfigs[configIndex];
                    });
                }
            });
            // TODO(trevor): This will fail if the config doesn't have an id or if there is no configs list

            // TODO(trevor): What happens on failure to find the config?
        }

        /**
         * Deletes the config object with the given id in the given group. Note
         * that this is not necessarily equivalent to removing the dashboard from
         * the group, as other instances may still exist. This should not be used
         * as a way to delete a dashboard; however, if deleting a dashboard
         * permanently, this should be used at least once in conjunction with the
         * deletion to ensure all references in dashboard groups are removed.
         *
         * @param groupId: The group containing the config object.
         * @param configId: The configId of the config object.
         *
         * @returns A truthy value on success, falsey otherwise?
         */
        function removeDashboardConfig(groupId, configId) {
            return dashboardGroupService.get(groupId).then((group) => {
                const configIndex = (group.dashboardConfigs || []).findIndex(
                    (origConfig) => origConfig.configId === configId
                );

                if (configIndex >= 0) {
                    group.dashboardConfigs.splice(configIndex, 1);

                    // TODO(trevor): Presumably this will not be necessary after migration, since getting a group shouldn't provide this list
                    delete group.dashboards;

                    return dashboardGroupService.update(group);
                }
            });
        }

        function getOrderedConfigs(dashboardConfigs, dashboardList) {
            const configMap = dashboardConfigs.reduce((map, config) => {
                map[config.configId] = config;
                return map;
            }, {});
            const orderedConfigs = dashboardList.map((dashboard) => {
                const id = dashboard.configId;
                return configMap[id];
            });
            return orderedConfigs;
        }

        function getOrderedConfigsByDashboardIds(dashboardConfigs, dashboardIds) {
            const configMap = dashboardConfigs.reduce((map, config) => {
                map[config.dashboardId || config.id] = config;
                return map;
            }, {});
            const orderedConfigs = dashboardIds.map((dashboardId) => {
                return configMap[dashboardId];
            });
            return orderedConfigs;
        }

        /**
         * @param dashboardGroup: DashboardGroup to update
         * @param dashboardList: List of ordered dashboards
         *
         * @returns the updated DashboardGroup if successful
         */
        function updateDashboardOrdering(dashboardGroup, dashboardList) {
            const dashboardGroupId = dashboardGroup.groupId || dashboardGroup.id;
            return dashboardGroupService.get(dashboardGroupId).then((originalGroup) => {
                const dashboardViewsEnabled = featureEnabled('dashboardViews');
                if (dashboardViewsEnabled) {
                    const configs =
                        dashboardGroup.dashboardConfigs || dashboardGroup.sf_dashboardConfigs;
                    originalGroup.dashboardConfigs = getOrderedConfigs(configs, dashboardList);
                } else {
                    originalGroup.dashboards = dashboardList.map((dashboard) => dashboard.id);
                }
                return dashboardGroupService.update(originalGroup);
            });
        }
    },
];
