export const TypedCorrelationStore = [
    function () {
        const joinerStr = '___';

        function strCmp(a, b) {
            return a.localeCompare(b);
        }

        //need sf_originatingMetric?
        const disallowedKeys = ['computationId', 'sf_metric', 'computingFor', 'jobId'];
        function pruneKeys(inputKeys) {
            return inputKeys.filter(function (key) {
                return disallowedKeys.indexOf(key) === -1;
            });
        }

        function getKeySignatureString(uniqueKeys) {
            return uniqueKeys.join(joinerStr);
        }

        function getKeyValueStr(uniqueKeys, metadata) {
            return uniqueKeys
                .map(function (key) {
                    return key + ':' + metadata[key];
                })
                .join(joinerStr);
        }

        function TypedCorrelationStore(exactMatch) {
            //sorted keys joined as a string, in map form.
            //it is normal for this map to have a key with an empty string.
            this.keySignatureMap = {};
            this.keySignatures = [];
            this.exactMatch = exactMatch;
        }

        //ONLY CALL THIS WITH THRESHOLDS
        TypedCorrelationStore.prototype.addThreshold = function (metadata) {
            const uniqueKeys = pruneKeys(metadata.sf_key).sort(strCmp);
            const keyStr = getKeySignatureString(uniqueKeys);
            const keyValueStr = getKeyValueStr(uniqueKeys, metadata);

            if (!this.keySignatureMap[keyStr]) {
                this.keySignatureMap[keyStr] = {};
                const sigMap = {};
                uniqueKeys.forEach(function (key) {
                    sigMap[key] = true;
                });
                this.keySignatures.push(sigMap);
            }
            if (!this.keySignatureMap[keyStr][keyValueStr]) {
                this.keySignatureMap[keyStr][keyValueStr] = [];
            }

            this.keySignatureMap[keyStr][keyValueStr].push(metadata.tsid);
        };

        //ONLY CALL THIS WITH SIGNALS
        TypedCorrelationStore.prototype.getCorrelatedTSIDs = function (metadata) {
            let foundCorrelation = false;
            const originalKeys = pruneKeys(metadata.sf_key);
            let proposedKeySignature = null;
            let maxMatchingKeys = -1;
            this.keySignatures.forEach(function (keysig) {
                const keySize = Object.keys(keysig).length;
                let matchedKeys = 0;
                originalKeys.forEach(function (k) {
                    if (keysig[k]) {
                        matchedKeys++;
                    }
                });
                if (matchedKeys === keySize && matchedKeys > maxMatchingKeys) {
                    maxMatchingKeys = matchedKeys;
                    proposedKeySignature = Object.keys(keysig);
                    foundCorrelation = true;
                }
            });

            const keySignature = getKeySignatureString(proposedKeySignature);
            if (foundCorrelation && this.keySignatureMap[keySignature]) {
                const keyValue = getKeyValueStr(proposedKeySignature, metadata);
                if (
                    !this.exactMatch &&
                    Object.keys(this.keySignatureMap[keySignature]).length === 1
                ) {
                    // HACK : this handles known(?) scenarioes where we should have correlated anyway(1 threshold of type).
                    // and is intended to fix issues where the usage of CONST as a threshold fails to correlate properly
                    // due to incorrect sf_originatingMetric values.
                    return this.keySignatureMap[keySignature][
                        Object.keys(this.keySignatureMap[keySignature])[0]
                    ];
                } else {
                    return this.keySignatureMap[keySignature][keyValue]
                        ? this.keySignatureMap[keySignature][keyValue]
                        : [];
                }
            } else {
                return [];
            }
        };

        return TypedCorrelationStore;
    },
];
