export const locationHistory = [
    '$rootScope',
    '$location',
    function ($rootScope, $location) {
        const history = [];

        function shouldIgnore(hash) {
            return (
                hash &&
                ((hash.match(/^\/chart\//) &&
                    !(hash.match(/^\/chart\/v1\//) || hash.match(/^\/chart\/v2\//))) ||
                    (hash.match(/^\/temp\/chart\//) &&
                        !(
                            hash.match(/^\/temp\/chart\/v1\//) || hash.match(/^\/temp\/chart\/v2\//)
                        )))
            );
        }

        $rootScope.$on('React:$routeChangeSuccess', function (e, data) {
            if (shouldIgnore(data.pathname)) {
                return;
            }

            if (history.length > 0 && data.url === history[history.length - 1]) {
                return;
            }

            history.push(data.url);
        });

        // Update latest location when the route is updated but controller doesn't change
        $rootScope.$on('React:$routeUpdate', function (e, data) {
            if (!shouldIgnore(data?.pathname || $location.path())) {
                const pos = Math.max(history.length - 1, 0);
                history[pos] = data?.url || $location.url();
            }
        });

        return {
            get,
            last,
            current,
            first,
        };

        function get(position) {
            if (position !== undefined) {
                return history[position];
            } else {
                return history.slice();
            }
        }

        function last(position) {
            if (position === undefined) position = 1;
            const lastPosition = history.length - 1 - position;
            if (position < 0 || lastPosition < 0) {
                // Invalid position
                return null;
            }
            return history[history.length - 1 - position];
        }

        function current() {
            return last(0);
        }

        function first() {
            return get(0);
        }
    },
];
