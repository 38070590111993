angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    function (chartTemplates, Chart, plotUtils) {
        function createChart(filters) {
            function addPlot(metric, color) {
                const plot = chart
                    .plot()
                    .metric(metric)
                    .name(metric + ' in bytes')
                    .visible(false)
                    .propertyFilters(filters);

                const plotLetter = plotUtils.getLetterFromUniqueKey(plot.uniqueKey());
                chart
                    .plot()
                    .macro(plotLetter + ' / 1000000000')
                    .color(color)
                    .name(metric);
            }

            const chart = Chart.create()
                .name('Memory')
                .type('area')
                .stacked(true)
                .yAxis(0, { label: 'Gigabytes' });

            addPlot('memory.free', '#05ce00');
            addPlot('memory.used', '#e9008a');
            addPlot('memory.buffered', '#00b9ff');
            addPlot('memory.cached', '#e5b312');

            return chart;
        }

        chartTemplates.register('collectdMemory', createChart);
    },
]);
