'use strict';
import templateUrl from './pagerdutyIntegration.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('sfxPagerdutyIntegration', [
    'notificationService',
    function (notificationService) {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                $scope.temp = {};

                function reset() {
                    const credential = $scope.credential;
                    if (!credential.name) {
                        credential.name = 'PagerDuty';
                    }
                    $scope.temp.apiKey = '';
                    $scope.sharedCredentialState.updateCredentials = false;
                    $scope.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                        $scope.credential.lastUpdated
                    );
                }

                $scope.$on('reset', reset);
                reset();

                $scope.$watch('temp.apiKey', function (newValue) {
                    if (newValue) {
                        $scope.credential.apiKey = newValue;
                        $scope.sharedCredentialState.updateCredentials = true;
                    } else {
                        $scope.credential.apiKey = null;
                    }
                });
            },
        };
    },
]);
