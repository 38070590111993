import { AngularInjector } from '../AngularUtils';
import { SplunkThemeProvider, CustomizeTheme, AnyTheme, getTheme } from '@splunk/themes';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { CurrentUserService } from '@splunk/olly-services/lib';
import { ColorAccessibilityKeys } from '@splunk/olly-services/lib/services/ColorAccessibility/ColorAccessibilityStore';
import { SplunkThemeProviderProps } from '@splunk/themes/types/SplunkThemeProvider';
import type { IRootScopeService } from 'angular';

export const themeConfig: SplunkThemeProviderProps = {
    family: 'prisma',
    density: 'compact',
    colorScheme: 'dark',
};

export const defaultColorScheme = themeConfig.colorScheme;

export type ThemeProviderProps = Pick<
    SplunkThemeProviderProps,
    'colorScheme' | 'additionalThemeProperties'
>;

/*
    changed popover z-index from default 1060 to 1070 because of zindex mismatch between multi-level modals and menus on them
    see https://splunkui.splunkdev.page/Packages/themes/SplunkThemeProvider#Customized%20Variables for reference
    */
const themeCustomizer: CustomizeTheme = (theme: AnyTheme) => ({
    ...theme,
    zindexPopover: getTheme(theme).zindexModal + 20,
});

export const ThemeProvider: FC<ThemeProviderProps> = ({
    children,
    colorScheme = themeConfig.colorScheme,
    additionalThemeProperties,
}) => (
    <SplunkThemeProvider
        density={themeConfig.density}
        family={themeConfig.family}
        colorScheme={colorScheme}
        customizeTheme={themeCustomizer}
        additionalThemeProperties={additionalThemeProperties}
    >
        {children}
    </SplunkThemeProvider>
);

/**
 * @return a string representing the class to be added for color accessibility in signalview
 */
export function useActiveColorAccessibilityType(): string {
    const currentUser = AngularInjector.useInjectedClass<CurrentUserService>('currentUser');
    const colorAccessibilityService = AngularInjector.useInjectedClass('colorAccessibilityService');
    const [colorAccessibilityClass, setColorAccessibilityClass] = useState<string>('');

    const setColorAccessibility = useCallback(
        (key: ColorAccessibilityKeys | undefined): void => {
            if (colorAccessibilityService) {
                const classToAdd = colorAccessibilityService.get().getClassForPaletteKey(key);
                setColorAccessibilityClass(classToAdd);
            }
        },
        [colorAccessibilityService]
    );

    useEffect(() => {
        // eslint-disable-next-line camelcase
        currentUser.preferences().then(({ sf_colorAccessibility }) => {
            setColorAccessibility(sf_colorAccessibility);
        });
    }, [currentUser, setColorAccessibility]);

    return colorAccessibilityClass;
}

export type ColorScheme = 'light' | 'dark';
export const useLegacyThemeServiceKey = (): ColorScheme => {
    const $rootScope = AngularInjector.useInjectedClass<IRootScopeService>('$rootScope');
    const themeService = AngularInjector.useInjectedClass('themeService');
    const [themeKey, setThemeKey] = useState<ColorScheme>(themeService.getColorScheme());

    useEffect(() => {
        return $rootScope?.$on('theme update', () => {
            setThemeKey(themeService.getColorScheme() as ColorScheme);
        });
    }, [$rootScope, themeService]);

    return themeKey;
};

export const useLegacyThemeServiceSync = (colorScheme?: ColorScheme): void => {
    const legacyThemeService = AngularInjector.useInjectedClass('themeService');
    useEffect(() => {
        if (colorScheme && legacyThemeService.getColorScheme() !== colorScheme) {
            // Avoids circular updates
            legacyThemeService.setTheme(colorScheme);
        }
    }, [colorScheme, legacyThemeService]);
};
