import React, { FC } from 'react';
import { Route, Switch } from './ReactRouteUtils';
import { Ng2React } from '../../common/util/Ng2React';
import {
    accessControlStyleGuide,
    alertEventModalStyleGuide,
    detectorWizardTypePickerStyleGuide,
    serviceEndpointSelectorStyleGuide,
    styleguide,
} from '../../legacy/common/styleguide/styleguide';

export const LegacyStyleGuideRoutes: FC = () => (
    <Switch>
        <Route exact path="/styleguide" title="Style Guide">
            <Ng2React name="styleguide" definition={styleguide} />
        </Route>

        <Route exact path="/styleguide/alertEventModalV2" title="Style Guide">
            <Ng2React name="alertEventModalStyleGuide" definition={alertEventModalStyleGuide} />
        </Route>

        <Route exact path="/styleguide/accessControl" title="Style Guide">
            <Ng2React name="accessControlStyleGuide" definition={accessControlStyleGuide} />
        </Route>

        <Route exact path="/styleguide/detectorWizardTypePicker" title="Style Guide">
            <Ng2React
                name="detectorWizardTypePickerStyleGuide"
                definition={detectorWizardTypePickerStyleGuide}
            />
        </Route>

        <Route exact path="/styleguide/serviceEndpointSelectorGuide" title="Style Guide">
            <Ng2React
                name="serviceEndpointSelectorStyleGuide"
                definition={serviceEndpointSelectorStyleGuide}
            />
        </Route>
    </Switch>
);
