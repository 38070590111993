angular
    .module('signalview.detector')

    .service('detectorPriorityService', [
        'colorAccessibilityService',
        function (colorAccessibilityService) {
            const knownPriorities = [
                {
                    priority: 'critical',
                    severity: 1000,
                    displayName: 'Critical',
                    alertClass: 'critical-border',
                    class: 'swatch-critical',
                },
                {
                    priority: 'major',
                    severity: 800,
                    displayName: 'Major',
                    alertClass: 'major-border',
                    class: 'swatch-major',
                },
                {
                    priority: 'minor',
                    severity: 600,
                    displayName: 'Minor',
                    alertClass: 'minor-border',
                    class: 'swatch-minor',
                },
                {
                    priority: 'warning',
                    severity: 400,
                    displayName: 'Warning',
                    alertClass: 'warning-border',
                    class: 'swatch-warning',
                },
                {
                    priority: 'info',
                    severity: 200,
                    displayName: 'Info',
                    alertClass: 'info-border',
                    class: 'swatch-info',
                },
                {
                    priority: 'normal',
                    severity: 0,
                    displayName: 'Normal',
                    alertClass: 'normal-border',
                    class: 'swatch-normal',
                },
                {
                    priority: 'custom',
                    severity: 0,
                    displayName: 'Custom',
                    class: 'swatch-custom',
                },
            ];
            const severityToDefinitionMap = {};
            const displayToSeverity = {};
            knownPriorities.forEach(function (itm) {
                severityToDefinitionMap[itm.severity] = itm;
                displayToSeverity[itm.displayName] = itm.severity;
            });
            function getColorBySeverity(sev) {
                const severityOptions = severityToDefinitionMap[sev];
                const priority = severityOptions.priority;
                const colorPalette = colorAccessibilityService.get().getAlertColors();
                return colorPalette[priority] || null;
            }
            function getDisplayNameBySeverity(sev) {
                return severityToDefinitionMap[sev].displayName;
            }
            function getAlertClassBySeverity(sev) {
                return severityToDefinitionMap[sev].alertClass;
            }
            function getSwatchClassBySeverity(sev) {
                return severityToDefinitionMap[sev].class;
            }
            function getSeverityByDisplay(display) {
                return displayToSeverity[display];
            }

            return {
                getColorBySeverity,
                getDisplayNameBySeverity,
                getAlertClassBySeverity,
                getSwatchClassBySeverity,
                getSeverityByDisplay,
            };
        },
    ]);
