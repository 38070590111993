import { ActionCardType } from '@splunk/olly-common/ActionCard';

import CreateDetector from '../../../../assets/images/alerts_detector.png';
import GettingNotified from '../../../../assets/images/alerts_getting_notified.png';
import LinkCharts from '../../../../assets/images/alerts_link_charts.png';

export const gettingStartedBannerContentAlerts = {
    title: 'Get the most out of alerts',
    description:
        'Get alerted when ciritical issues arise.  Splunk Observability Cloud helps you respond to problems in seconds.',
    docsLink:
        'https://quickdraw.splunk.com/redirect/?product=Observability&location=intro.alerts.onboarding&version=current',
    communityLink:
        'https://quickdraw.splunk.com/redirect/?product=Observability&location=devdocs.alerts.onboarding&version=current',
    actionCards: [
        {
            title: 'Create a detector to get alerted',
            type: ActionCardType.Docs,
            thumbnail: CreateDetector,
            source: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=createdetectors.alerts.onboarding&version=current',
            showBorder: true,
        },
        {
            title: 'Linking alerts and charts',
            type: ActionCardType.Docs,
            thumbnail: LinkCharts,
            source: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=linkdetectorstocharts.alerts.onboarding&version=current',
            showBorder: true,
        },
        {
            title: 'Getting notified',
            type: ActionCardType.Docs,
            thumbnail: GettingNotified,
            source: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=notifservices.alerts.onboarding&version=current',
            showBorder: true,
        },
    ],
};
