import CONTAINER_DETAILS_TEXT_CHART_CONFIG from '../../../../app/kubeNavigator/configs/containers/containerDetailTextChartConfig';
import fixedFilterEventFeed from '../common/fixedFilterEventFeed.tpl.html';
import templateUrl from './kubeContainerDetail.tpl.html';
import {
    getCPUChartModel,
    getMemoryChartModel,
} from '../../../../app/kubeNavigator/configs/containers/containerDetailChartModels.js';
import { getEventChartModel } from '../../../../app/kubeNavigator/configs/containers/containerEventFeedConfig.js';

export default {
    templateUrl,
    bindings: {
        time: '<',
        filterAlias: '<', // Variable definitions to be supplied to chart models
        filters: '<',
        isOnSidebar: '<?',
    },
    controller: [
        'transientChartModelMaker',
        'kubeDataService',
        function (transientChartModelMaker, kubeDataService) {
            const $ctrl = this;
            let lastFilterAliases;

            $ctrl.$onChanges = $onChanges;

            $ctrl.configs = {
                textChart: CONTAINER_DETAILS_TEXT_CHART_CONFIG,
            };

            $ctrl.sharedChartState = {};

            function $onChanges({ filterAlias, filters, time }) {
                const filtersUpdated =
                    (filterAlias || filters) &&
                    !angular.equals(lastFilterAliases, $ctrl.filterAlias);

                if (filtersUpdated) {
                    lastFilterAliases = angular.copy($ctrl.filterAlias);

                    const areVariablesPresent =
                        $ctrl.filterAlias &&
                        $ctrl.filterAlias.some(({ value }) => !_.isEmpty(value));

                    $ctrl.areOverridesPresent =
                        areVariablesPresent ||
                        ($ctrl.filters && $ctrl.filters.some(({ value }) => !_.isEmpty(value)));
                }

                if (filtersUpdated || time) {
                    processChartModels();
                }
            }

            function processChartModels() {
                const time = kubeDataService.getChartModelTime($ctrl.time);
                const filters = kubeDataService.cleanFilters($ctrl.filterAlias);
                const filtersString = kubeDataService.generateFilterStrings(filters);

                const cpuChartModel = getCPUChartModel(time, filtersString);
                const memoryChartModel = getMemoryChartModel(time, filtersString);

                $ctrl.configs.chartModels = [
                    transientChartModelMaker(
                        cpuChartModel.chartOptions,
                        cpuChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        memoryChartModel.chartOptions,
                        memoryChartModel.publishLabelOptions
                    ),
                ];

                const eventChartModel = getEventChartModel(time, filtersString);
                $ctrl.eventFeed = {
                    templateUrl: fixedFilterEventFeed,
                    model: transientChartModelMaker(
                        eventChartModel.chartOptions,
                        eventChartModel.publishLabelOptions
                    ),
                };
            }
        },
    ],
};
