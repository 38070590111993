'use strict';

angular.module('sfx.ui').controller('awsIntegrationRegions', [
    '$scope',
    '$uibModalInstance',
    'selected',
    'editable',
    'awsRegions',
    'govCloudRegions',
    'chinaRegions',
    'awsIntegrationService',
    'AWS_INTEGRATION_CONSTANTS',
    function (
        $scope,
        $uibModalInstance,
        selected,
        editable,
        awsRegions,
        govCloudRegions,
        chinaRegions,
        awsIntegrationService,
        AWS_INTEGRATION_CONSTANTS
    ) {
        $scope.editable = editable;
        $scope.awsRegions = awsRegions;
        $scope.codes = Object.keys(awsRegions);
        $scope.govCloudRegions = govCloudRegions;
        $scope.govCloudCodes = Object.keys(govCloudRegions);
        $scope.chinaRegions = chinaRegions;
        $scope.chinaCodes = Object.keys(chinaRegions);
        $scope.selectedCodes = {};
        $scope.awsConsts = AWS_INTEGRATION_CONSTANTS;

        // Transform the selected items to the selectedName space lookup table
        selected.forEach(function (selected) {
            $scope.selectedCodes[selected.code] = true;
        });

        const userSelectedCodes = Object.keys($scope.selectedCodes);

        if (selected.length === 0) {
            $scope.syncOptions = $scope.awsConsts.SYNC_TYPES.ALL;
        } else if (
            awsIntegrationService.isRegionSelectedByUser(userSelectedCodes, $scope.govCloudCodes)
        ) {
            // User chose gov cloud
            $scope.syncOptions = $scope.awsConsts.SYNC_TYPES.GOV_CLOUD;
        } else if (
            awsIntegrationService.isRegionSelectedByUser(userSelectedCodes, $scope.chinaCodes)
        ) {
            // User chose china region
            $scope.syncOptions = $scope.awsConsts.SYNC_TYPES.CHINA;
        } else {
            $scope.syncOptions = $scope.awsConsts.SYNC_TYPES.SELECTED;
        }

        function isSyncOptionSyncAll() {
            return $scope.syncOptions === $scope.awsConsts.SYNC_TYPES.ALL;
        }

        function checkApplyAllowed() {
            $scope.applyAllowed =
                isSyncOptionSyncAll() ||
                _.some($scope.selectedCodes, (code) => {
                    return code;
                });
        }

        $scope.deleteCodes = function () {
            $scope.selectedCodes = {};
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.$watch('selectedCodes', checkApplyAllowed, true);
        $scope.$watch('syncOptions', checkApplyAllowed);

        $scope.apply = function () {
            awsIntegrationService.getAllRegions().then(function (allRegions) {
                let result = [];

                if ($scope.syncOptions !== $scope.awsConsts.SYNC_TYPES.ALL) {
                    result = awsIntegrationService.constructUserChoiceObject(
                        $scope.selectedCodes,
                        allRegions
                    );
                }

                $uibModalInstance.close(result);
            });
        };
    },
]);
