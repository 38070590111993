import { locationHistory } from './locationHistory';
import { fullscreen } from './fullscreen';
import { promiseGenerationManager } from './promiseGenerationManager';
import { createCallbackRegistry } from './createCallbackRegistry';

angular
    .module('sfUtil', [])

    .filter('encodeURIComponent', [
        '$window',
        function ($window) {
            return $window.encodeURIComponent;
        },
    ])

    .service('fullscreen', fullscreen)
    .service('locationHistory', locationHistory)
    .service('promiseGenerationManager', promiseGenerationManager)
    .service('createCallbackRegistry', createCallbackRegistry);
