import { AngularInjector } from '../../../../common/AngularUtils';

import { AccessControlPermissionTypes } from '@splunk/olly-services';
import AccessControlObjectType from '../../../../common/ui/accessControl/AccessControlObjectType.js';
import AccessControlPermissionAction from '../../../../common/ui/accessControl/AccessControlPermissionAction.js';
import accessControlHelper from '../../../../common/ui/accessControl/accessControlHelper';

export const PERMISSIONS_ACTIONS = {
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    VIEW: 'VIEW',
};

PermissionsChecker.useInstance = () => AngularInjector.useInjectedClass(PermissionsChecker);
PermissionsChecker.$inject = [
    '_',
    '$q',
    // declare-used-dependency-to-linter::featureEnabled
    'featureEnabled',
    // declare-used-dependency-to-linter::permissionsService
    'permissionsService',
    // declare-used-dependency-to-linter::writepermissionsPermissionsChecker
    'writepermissionsPermissionsChecker',
];
export default function PermissionsChecker(
    _,
    $q,
    featureEnabled,
    permissionsService,
    writepermissionsPermissionsChecker
) {
    const PERMISSION_DEFAULT_CHECKERS = {
        [PERMISSIONS_ACTIONS.UPDATE]: (subject) =>
            getSubjectEffectivePermissions(subject).then((effectivePermissions) =>
                hasWriteAccess(effectivePermissions)
            ),
        [PERMISSIONS_ACTIONS.DELETE]: (subject) => {
            getSubjectEffectivePermissions(subject).then((effectivePermissions) =>
                hasWriteAccess(effectivePermissions)
            );
        },
        [PERMISSIONS_ACTIONS.VIEW]: (subject) => {
            getSubjectEffectivePermissions(subject).then((effectivePermissions) =>
                hasReadAccess(effectivePermissions)
            );
        },
    };

    const PERMISSION_CUSTOM_CHECKERS = {
        [AccessControlObjectType.DASHBOARD]: {
            // removing a dashboard requires RW on both: Dashboard and its Dashboard Group
            [PERMISSIONS_ACTIONS.DELETE]: (dashboard, currentGroup) => {
                const dashboardId = extractIdFromMetabaseObject(dashboard);
                const currentGroupId = extractIdFromMetabaseObject(currentGroup);

                if (dashboardId && currentGroupId) {
                    return permissionsService
                        .fetchEffectivePermissions([dashboardId, currentGroupId])
                        .then(
                            (effectives) =>
                                hasWriteAccess(effectives[dashboardId]) &&
                                hasWriteAccess(effectives[currentGroupId])
                        );
                }
                return Promise.resolve(false);
            },
        },
    };

    this.hasDashboardWriteAccess = (subject, isSnapshot = false) => {
        return this.hasPermissionTo(
            PERMISSIONS_ACTIONS.UPDATE,
            AccessControlObjectType.DASHBOARD,
            subject,
            isSnapshot
        );
    };

    this.hasDashboardGroupWriteAccess = (subject) => {
        return this.hasPermissionTo(
            PERMISSIONS_ACTIONS.UPDATE,
            AccessControlObjectType.DASHBOARD_GROUP,
            subject
        );
    };

    // current data model (metabase) does not allow us to know the type of the subject at this point
    this.hasPermissionTo = (operation, subjectType, subject, isSnapshot) => {
        if (!featureEnabled('accessControl')) {
            if (operation === PERMISSIONS_ACTIONS.UPDATE) {
                return writepermissionsPermissionsChecker.hasWritePermissions(subject);
            } else {
                return $q.resolve(true);
            }
        }

        if (isSnapshot) {
            return $q.resolve(true);
        }

        return applyCalculation(subject, subjectType, operation);
    };

    this.getHierarchyWritePermissions = (dashboard, group, isSnapshot) => {
        if (!featureEnabled('accessControl')) {
            return writepermissionsPermissionsChecker.getHierarchyWritePermissions(
                dashboard,
                group
            );
        }

        if (isSnapshot) {
            return $q.resolve({
                hasWritePermission: true,
                hasGroupWritePermission: true,
                hasGroupReadPermission: true,
                canEditAllSiblingDashboards: true,
                canBeMirrored: true,
                canRemoveMirrors: true,
                canViewSibling: () => false, // there's no siblings in this case
            });
        }

        const groupId = extractIdFromMetabaseObject(group);
        const dashboardId = extractIdFromMetabaseObject(dashboard);
        const siblingIds = group?.dashboards;
        const ids = _.chain([groupId, dashboardId]).concat(siblingIds).compact().uniq().value(); // id can't be 0

        return permissionsService.fetchEffectivePermissions(ids).then((effectives) => ({
            hasWritePermission: hasWriteAccess(effectives[dashboardId]),
            hasGroupWritePermission: hasWriteAccess(effectives[groupId]),
            hasGroupReadPermission: hasReadAccess(effectives[groupId]),
            canEditAllSiblingDashboards:
                siblingIds && siblingIds.every((id) => hasWriteAccess(effectives[id])),
            canBeMirrored:
                AccessControlPermissionTypes.INHERIT ===
                accessControlHelper.detectPermissionType(dashboard?.permissions?.acl),
            canRemoveMirrors: canRemoveMirrors(group, dashboard),
            canViewSibling: (id) => hasReadAccess(effectives[id]),
        }));
    };

    function getSubjectEffectivePermissions(subject) {
        const id = extractIdFromMetabaseObject(subject);
        return permissionsService
            .fetchEffectivePermissions(id)
            .then((effectives) => effectives[id]);
    }

    function applyCalculation(subject, subjectType, operation) {
        const customPermissionsChecker = PERMISSION_CUSTOM_CHECKERS?.[subjectType]?.[operation];
        const defaultPermissionsChecker = PERMISSION_DEFAULT_CHECKERS?.[operation];
        // this allows to pass not only actual subject (like dashboard)
        // but also required related objects (like current dashboard group)
        const args = Array.isArray(subject) ? subject : [subject];

        if (customPermissionsChecker) {
            return customPermissionsChecker(...args);
        } else if (defaultPermissionsChecker) {
            return defaultPermissionsChecker(...args);
        }

        throw new Error(`There is no ${operation} permissions checker for ${subjectType.label}.`);
    }
}

// With AccessControl we cannot remove mirror if it's the last mirror of a dashboard
// and inherits permissions from the group
function canRemoveMirrors(group, dashboard) {
    const inheritsPermissionsFromGroup = dashboard?.permissions?.parent === group?.id;
    const dashboardMirrorsInGroup = group?.dashboardConfigs?.filter(
        (config) => config.dashboardId === dashboard?.id
    );
    return !inheritsPermissionsFromGroup || dashboardMirrorsInGroup?.length !== 1;
}

function extractIdFromMetabaseObject(obj) {
    return obj?.id || obj?.sf_id;
}

function hasWriteAccess(effectivePermissions) {
    return effectivePermissions?.includes(AccessControlPermissionAction.WRITE);
}

function hasReadAccess(effectivePermissions) {
    return effectivePermissions?.includes(AccessControlPermissionAction.READ);
}
