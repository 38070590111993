import templateUrl from './sfnavmenubarinput.tpl.html';

export default [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'AE',
            replace: true,
            scope: {
                inputVal: '@',
                onModify: '=',
                inFocus: '=?',
                type: '@?',
                placeholder: '@?',
                disabled: '@?',
                delayMs: '@?',
            },
            templateUrl,
            link: function ($scope) {
                $scope.doneEnter = false;
                $scope.inputModel = angular.copy($scope.inputVal);
                let timeoutHandle = null;
                $scope.$watch('inputModel', function (nval, oval) {
                    if (nval === oval) {
                        return;
                    }
                    if (timeoutHandle) {
                        $timeout.cancel(timeoutHandle);
                    }
                    timeoutHandle = $timeout(function () {
                        timeoutHandle = null;
                        $scope.onModify($scope.inputModel);
                    }, $scope.delayMs || 1000);
                });
                $scope.onKeyPress = function ($event) {
                    if ($event.keyCode !== 13 || !$scope.inputModel) {
                        return;
                    }
                    if (timeoutHandle) {
                        $timeout.cancel(timeoutHandle);
                    }
                    $scope.onModify($scope.inputModel);
                    $event.target.blur();
                    $scope.inFocus = false;
                    $scope.mouseEntered = false;
                    $scope.doneEnter = true;
                };
                $scope.$watch('inputVal', function (nval, oval) {
                    if (!nval || nval === oval || nval === $scope.inputModel) {
                        return;
                    }
                    $scope.inputModel = angular.copy($scope.inputVal);
                });
            },
        };
    },
];
