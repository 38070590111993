angular
    .module('signalview.nameDescription', ['signalboost'])

    .controller('NameDescriptionController', [
        '$scope',
        '$log',
        'dashboardGroupService',
        'signalboost',
        'params',
        'defaultWriters',
        'featureEnabled',
        'migratedSignalboost',
        function (
            $scope,
            $log,
            dashboardGroupService,
            signalboost,
            params,
            defaultWriters,
            featureEnabled,
            migratedSignalboost
        ) {
            if (!params.obj || !params.obj.sf_type) {
                $log.error('Invalid object for name/description editor. ', params.obj);
                return;
            }
            const typeLc = params.obj.sf_type.toLowerCase();
            $scope.label = params.label || params.obj.sf_type;
            $scope.defaultWriters = defaultWriters;
            $scope.writePermissionsEnabled = featureEnabled('writepermissions');

            $scope.obj = {
                name: params.obj['sf_' + typeLc] || '',
                desc: params.obj.sf_description || '',
                type: params.obj.sf_type,
                id: params.obj.sf_id || null,
                authorizedWriters: params.obj.authorizedWriters || {},
            };

            $scope.namePlaceHolder = params.namePlaceHolder || 'Enter name for ' + $scope.label;
            $scope.descriptionPlaceHolder =
                params.descriptionPlaceHolder ||
                'Enter a description for ' + $scope.label + '. (Optional)';
            $scope.messages = [];
            $scope.cancel = function () {
                try {
                    this.$dismiss();
                } catch (e) {}
            };

            $scope.closeMessage = function (index) {
                $scope.messages.splice(index, 1);
            };

            function showErrorMessage(msg) {
                $scope.messages.push({
                    type: 'danger',
                    msg: msg,
                });
            }

            $scope.loading = false;
            function setLoading() {
                $scope.loading = true;
            }

            function clearMessages() {
                $scope.messages = [];
                $scope.loading = false;
            }

            $scope.checkstatus = function (d) {
                clearMessages();
                if (d && d.data) {
                    if (d.data.badFields) {
                        for (const key in d.data.badFields) {
                            showErrorMessage(d.data.badFields[key]);
                        }
                    } else if (d.data.message) {
                        showErrorMessage(d.data.message);
                    }
                } else if (d && d.type === 'error') {
                    showErrorMessage(
                        'There was an error creating this ' + $scope.label + '. Try again later.'
                    );
                }
            };

            function update() {
                const toUpdate = {};
                if ($scope.obj.name !== params.obj['sf_' + typeLc]) {
                    toUpdate['sf_' + typeLc] = $scope.obj.name;
                }

                if ($scope.obj.authorizedWriters) {
                    toUpdate.authorizedWriters = $scope.obj.authorizedWriters;
                }

                if ($scope.obj.desc !== params.obj.sf_description) {
                    toUpdate.sf_description = $scope.obj.desc;
                }
                if (!Object.keys(toUpdate).length) {
                    $scope.cancel();
                    return;
                }
                setLoading();

                let updatePromise;
                if (params.obj.sf_type === 'Page') {
                    updatePromise = dashboardGroupService.update(toUpdate);
                } else {
                    updatePromise = params.obj.patch(toUpdate);
                }

                return updatePromise.then(function () {
                    angular.extend(params.obj, toUpdate);
                    $scope.$close(params.obj);
                }, $scope.checkstatus);
            }

            function create() {
                setLoading();
                const toCreate = { sf_type: $scope.obj.type };

                toCreate['sf_' + typeLc] = $scope.obj.name;
                if ($scope.obj.desc) {
                    toCreate.sf_description = $scope.obj.desc;
                }
                angular.extend(toCreate, params.obj);

                let createObjPromise;

                if ($scope.obj.type === 'Page') {
                    const v2DashboardGroup = dashboardGroupService.convertToV2(toCreate);
                    if ($scope.obj.authorizedWriters) {
                        v2DashboardGroup.authorizedWriters = $scope.obj.authorizedWriters;
                    }
                    createObjPromise = dashboardGroupService.create(v2DashboardGroup);
                } else if (typeLc === 'dimension' || typeLc === 'metric') {
                    createObjPromise = migratedSignalboost[typeLc].post('', toCreate);
                } else {
                    createObjPromise = signalboost.types[typeLc].create(toCreate);
                }

                return createObjPromise.then(function (created) {
                    if (created.hasOwnProperty('dashboards')) {
                        created = dashboardGroupService.convertToV1(created);
                    }
                    $scope.$close(created);
                }, $scope.checkstatus);
            }

            $scope.commit = function () {
                $scope.messages = [];
                if ($scope.obj.name === '') {
                    showErrorMessage('Please enter a name for the ' + $scope.label + '.');
                    return;
                }
                if ($scope.obj.id) {
                    return update();
                } else {
                    return create();
                }
            };
        },
    ]);
