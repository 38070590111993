'use strict';

import './favorites/_module';
import './filterControls/_module';

angular.module('sfx', [
    'sfx.ui',
    'sfx.data',
    'sfx.debug',
    'sfx.external',
    'sfx.util',
    'sfx.favorites',
    'sfx.filterControls',
]);
