'use strict';

/* jshint camelcase: false */
angular.module('sfx.data').factory('eventService', [
    '$log',
    'sfxApi',
    'currentUser',
    '$timeout',
    'detectorPriorityService',
    '$http',
    'API_URL',
    function ($log, sfxApi, currentUser, $timeout, detectorPriorityService, $http, API_URL) {
        /*
         * Manual unmarshalling of JSON-encoded fields will soon no longer be
         * necessary as this step will be taken care of by SignalBoost itself.
         */
        const jsonUnmarshallMetadataFields = ['sf_detectInputContexts', 'sf_key', 'sf_memberOf'];
        const jsonUnmarshallPropertiesFields = ['inputSources', 'inputValues', 'inputs', 'sources'];

        function unmarshalJsonFields(obj, fields) {
            fields.forEach(function (name) {
                if (obj[name] && typeof obj[name] === 'string') {
                    try {
                        obj[name] = JSON.parse(obj[name]);
                    } catch (e) {
                        $log.warn('Unable to unmarshall JSON field ' + name + ' from ' + obj, e);
                    }
                }
            });
        }

        /**
         * Coerce the given event properties' schema into the latest alert event schema.
         *
         * Handles all prior schema versions and modifies the event properties to
         * match the latest alert event schema. As of October 2016, that's schema
         * v3, which is based around the following structure for the 'inputs'
         * property:
         *
         * "inputs": {
         *   "_S0": {
         *     "key": {
         *       "dim1": "dim1value",
         *       ...
         *     },
         *     "value": 42
         *   }
         * }
         */
        function coerceAlertEventSchema(properties) {
            properties.inputs = {};

            if (properties.inputSources) {
                // v2 schema events should have an inputSources map
                angular.forEach(properties.inputSources, function (dimensions, name) {
                    let value;
                    switch (name) {
                        case 'high':
                            value = properties.highThreshold;
                            break;
                        case 'low':
                            value = properties.lowThreshold;
                            break;
                        default:
                            if (!properties.inputValues) {
                                return;
                            }

                            value = properties.inputValues[name];
                            break;
                    }

                    properties.inputs[name] = {
                        key: dimensions,
                        value: value,
                    };
                });
            } else if (properties.inputValues) {
                // Some early v2 detector events might have been created without an
                // sf_schema and without inputSources.
                angular.forEach(properties.inputValues, function (value, name) {
                    properties.inputs[name] = {
                        key: {},
                        value: value,
                    };
                });
            }

            if (properties.value) {
                if (typeof properties.sources === 'string') {
                    // In very old events, sources can be a comma-separated of dimension
                    // values.
                    const sources = properties.sources.split(',');
                    properties.sources = {};
                    sources.forEach(function (item, index) {
                        sources[index] = item;
                    });
                } else if (typeof properties.sources === 'undefined') {
                    // It's also possible for sources to not be present at all on v1
                    // detector events that detected on the default group.
                    properties.sources = {};
                }

                properties.inputs.in = {
                    key: properties.sources,
                    value: properties.value,
                };
            }

            /*
             * For v1 schema, or if high and low thresholds weren't in the
             * inputSources because they were static thresholds, capture them now
             * (without a `key`).
             */
            if (properties.highThreshold && !properties.inputs.high) {
                properties.inputs.high = {
                    value: properties.highThreshold,
                };
            }
            if (properties.lowThreshold && !properties.inputs.low) {
                properties.inputs.low = {
                    value: properties.lowThreshold,
                };
            }

            delete properties.inputSources;
            delete properties.inputValues;
            delete properties.sources;
            delete properties.highThreshold;
            delete properties.lowThreshold;
        }

        function coerceAlertEventSchemaIfNecessary(item) {
            if (item.metadata.sf_eventCategory === 'ALERT' && item.properties.sf_schema !== 3) {
                coerceAlertEventSchema(item.properties);
            }
        }

        /**
         * Returns an event term histogram according to the given parameters.
         *
         * Parameters can either be an events query, or a parameter objects containing a query.
         */
        function histogram(params) {
            return currentUser
                .orgId()
                .then(function (orgId) {
                    if (typeof params === 'string') {
                        params = {
                            query: params,
                        };
                    }

                    params.orgId = orgId;
                    params.interval = params.interval || 1;
                    params.aggregateOnFields = params.aggregation;
                    delete params.aggregation;

                    return $http.post(API_URL + '/v2/event/_termhistogram2', params);
                })
                .then(function (results) {
                    return results.data ? results.data : [];
                });
        }

        /**
         * Search for events.
         *
         * Parameters can either be an events query, or a parameter object containing a query.
         */
        function search(params) {
            return currentUser
                .orgId()
                .then(function (orgId) {
                    if (typeof params === 'string') {
                        params = {
                            query: params,
                        };
                    }

                    params.orgid = orgId;
                    params.orderBy = params.orderBy || '-sf_timestamp';
                    params.metadata = params.metadata || '*';
                    params.expandJson = true;

                    return sfxApi.event.getEvents(params);
                })
                .then(function (results) {
                    results.forEach(function (item) {
                        // Unmarshall fields that are JSON-encoded
                        unmarshalJsonFields(item.metadata, jsonUnmarshallMetadataFields);
                        unmarshalJsonFields(item.properties, jsonUnmarshallPropertiesFields);

                        // Schema coercion only matters for ALERT events for schema versions different than 3.
                        coerceAlertEventSchemaIfNecessary(item);

                        // Gather detect contexts for available inputs.
                        const contexts = item.metadata.sf_detectInputContexts;
                        angular.forEach(item.properties.inputs, function (input, name) {
                            input.identifier = name;
                            if (contexts && contexts[name]) {
                                angular.extend(input, contexts[name]);
                            }
                        });
                    });
                    return results;
                });
        }

        function applyDetectContexts(item) {
            const contexts = item.metadata.sf_detectInputContexts;
            angular.forEach(item.properties.inputs, function (input, name) {
                input.identifier = name;
                if (contexts && contexts[name]) {
                    angular.extend(input, contexts[name]);
                }
            });
        }

        function v2SegmentedSearch(params) {
            params.orderBy = params.orderBy ? [params.orderBy] : ['-sf_timestamp'];
            params.fields = ['*'];

            return $http.post(API_URL + '/v2/event/_termfetch2', params).then(function (results) {
                const events = [];
                angular.forEach(results.data, function (eventList, label) {
                    eventList.forEach(function (item) {
                        // Unmarshall fields that are JSON-encoded
                        if (item.metadata.sf_key) {
                            item.metadata.sf_key = JSON.parse(item.metadata.sf_key); //SAD.
                        }

                        // Schema coercion only matters for ALERT events for schema versions different than 3.
                        coerceAlertEventSchemaIfNecessary(item);

                        // Gather detect contexts for available inputs.
                        applyDetectContexts(item);

                        item.___label = label;
                        events.push(item);
                    });
                });
                return events;
            });
        }

        function v2search(params) {
            params.orderBy = params.orderBy ? [params.orderBy] : ['-sf_timestamp'];
            params.fields = ['*'];

            return $http.post(API_URL + '/v2/event/find', params).then(function (results) {
                results.data.forEach(function (item) {
                    // Unmarshall fields that are JSON-encoded
                    if (item.metadata.sf_key) {
                        item.metadata.sf_key = JSON.parse(item.metadata.sf_key); //SAD.
                    }

                    // Schema coercion only matters for ALERT events for schema versions different than 3.
                    if (
                        item.metadata.sf_eventCategory === 'ALERT' &&
                        item.properties.sf_schema !== 3
                    ) {
                        coerceAlertEventSchema(item.properties);
                    }

                    // Gather detect contexts for available inputs.
                    applyDetectContexts(item);
                });
                return results.data;
            });
        }

        function processV2StreamedEvent(event, metadata, model, rules) {
            event.timestamp = event.timestampMs;
            event.metadata = metadata;
            if (event.metadata.sf_detectLabel) {
                event.metadata.sf_eventCategory = 'ALERT';
                if (event.properties.sf_schema !== 3) {
                    coerceAlertEventSchema(event.properties);
                }

                if (rules && rules.length) {
                    const matchingRules = rules.filter(function (rule) {
                        return (
                            rule.name === event.metadata.sf_detectLabel ||
                            rule.detectLabel === event.metadata.sf_detectLabel
                        );
                    });
                    if (matchingRules.length) {
                        const rule = matchingRules[0];
                        event.metadata.sf_severity = rule.severityLevel || rule.severity;
                        event.metadata.sf_priority = detectorPriorityService.getSeverityByDisplay(
                            event.metadata.sf_severity
                        );
                        event.metadata.sf_displayName = rule.name || rule.detectLabel;
                        event.metadata.sf_detector = model.sf_detector;
                        event.metadata.sf_detectorId = model.sf_id;
                    }
                }
            }
        }

        function streamHistogram(inputparams) {
            const params = angular.extend({ interval: 5 }, inputparams);

            // TODO(kevin): replace this with SignalFlow v2 event streaming
            let cancelled = false;
            let needBackfill = true;
            const interval = needBackfill ? params.interval : 10;
            const intervalMs = interval * 1000;
            let lastPollId = null;

            function cancel() {
                cancelled = true;
                $timeout.cancel(lastPollId);
            }

            function poll() {
                const indexingDelay = 20000;
                const start = needBackfill ? params.start : Date.now() - intervalMs - indexingDelay;
                const end = needBackfill ? params.end : Date.now() - indexingDelay;
                needBackfill = false;
                histogram({
                    query: params.query || '',
                    startTime: start,
                    endTime: end,
                    aggregation: inputparams.aggregation,
                    programToQuery: inputparams.programToQuery,
                    interval: interval,
                })
                    .then(function (data) {
                        if (cancelled) {
                            return;
                        }
                        try {
                            params.callback(data);
                        } catch (e) {
                            $log.error('Error on event streaming callback.', e);
                        }
                        lastPollId = $timeout(poll, intervalMs);
                    })
                    .catch(function (e) {
                        $log.error('Event streaming interrupted by error response from server.', e);
                    });
            }

            poll();
            return {
                cancel: cancel,
            };
        }

        function termAggregation(params) {
            return currentUser.orgId().then(function (orgId) {
                if (typeof params === 'string') {
                    params = {
                        query: params,
                    };
                }

                params.orgid = orgId;

                return sfxApi.event.getEventAggregation(params);
            });
        }

        function getEventProgramText(event) {
            return $http({
                method: 'GET',
                url: `${API_URL}/v2/event/${event.id}/jobDetails`,
            })
                .then((res) => res.data.programText)
                .catch((e) => {
                    $log.error('Error on fetching event program text.', e);
                    return '';
                });
        }

        return {
            search: search,
            v2SegmentedSearch: v2SegmentedSearch,
            v2search: v2search,
            streamHistogram: streamHistogram,
            histogram: histogram,
            termAggregation: termAggregation,
            processV2StreamedEvent: processV2StreamedEvent,
            getEventProgramText,
        };
    },
]);
