angular
    .module('signalview.utils')

    .service('colorService', [
        '$window',
        function ($window) {
            // returns hex with numShades (ending at -30 and +30 lightness)
            // on either side of mid color
            // mid color is lightened 50% to begin with
            // # of colors in between 20, 50 and 80 are calculated
            // from numShades
            // [color20%, ... color50%, ... color80%]
            function getShades(colorHex, numShades) {
                if (!colorHex) {
                    return [];
                }
                numShades = Math.floor(numShades) || 1;
                const midColor = new $window.w3color(colorHex);
                const hsl = midColor.toHsl();
                const step = Math.ceil(30 / numShades);
                const colors = [];

                for (let i = 0; i < numShades * 2 + 1; i++) {
                    const l = 20 + i * step;
                    const hslStr = 'hsl(' + hsl.h + ', ' + hsl.s * 100 + '%, ' + l + '%)';
                    const color = new $window.w3color(hslStr);
                    colors.push(color.toHexString());
                }

                return colors.reverse();
            }

            function hslManipulation(colorHex, adjustments) {
                let { h, s, l } = new $window.w3color(colorHex).toHsl();

                h = adjustments.h || adjustments.h === 0 ? adjustments.h : h;
                s = adjustments.s || adjustments.s === 0 ? adjustments.s : s * 100;
                l = adjustments.l || adjustments.l === 0 ? adjustments.l : l * 100;

                const color = new $window.w3color(`hsl(${h},${s}%,${l}%)`);

                return color.toHexString();
            }

            function incrementalHslManipulation(colorHex, adjustments) {
                const hsl = new $window.w3color(colorHex).toHsl();
                const h = hsl.h + (adjustments.h || 0);
                const s = hsl.s * 100 + (adjustments.s || 0);
                const l = hsl.l * 100 + (adjustments.l || 0);
                const color = new $window.w3color(`hsl(${h},${s}%,${l}%)`);

                return color.toHexString();
            }

            function hexForRgbInt(intValue) {
                intValue = intValue || 0;
                const blue = intValue & 255;
                const green = (intValue >> 8) & 255;
                const red = (intValue >> 16) & 255;
                return new window.w3color(
                    'rgb(' + red + ', ' + green + ', ' + blue + ')'
                ).toHexString();
            }

            return {
                hexForRgbInt,
                incrementalHslManipulation,
                getShades,
                hslManipulation,
            };
        },
    ]);
