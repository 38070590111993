import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { AngularInjector } from '../../../common/AngularUtils';

const EVENT_STATE_TO_DISPLAY = {
    anomalous: 'Triggered',
    'too high': 'Triggered',
    'too low': 'Triggered',
    ok: 'Cleared',
    stopped: 'Stopped',
    cleared: 'Manually Resolved',
    'manually resolved': 'Manually Resolved',
    'auto resolved': 'Auto-Cleared',
};

export default function HumanReadableTimeLabel(props) {
    const tzService = AngularInjector.useInjectedClass('timeZoneService');
    const timezone = tzService.getTimezone();
    const timestampMomentTz = timezone
        ? moment(props.timestamp).tz(timezone)
        : moment(props.timestamp);

    const eventState = EVENT_STATE_TO_DISPLAY[props.state];
    const timeAgo = timestampMomentTz.fromNow();
    const dateUsFormat = timestampMomentTz.format('L');
    const timeWithTz = timestampMomentTz.format('LTS (UTC Z)');
    return `Alert ${eventState}, ${timeAgo} on ${dateUsFormat} at ${timeWithTz} `;
}

HumanReadableTimeLabel.propTypes = {
    state: PropTypes.oneOf(Object.keys(EVENT_STATE_TO_DISPLAY)),
    timestamp: PropTypes.Number,
};
