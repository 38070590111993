/**
 * The uiModel data is put at the top level of the mode, removing any of the top level fields from the navigator
 * This function adds those top level fields back to the object excluding the uiModel field
 */
function addTopLevelNavigatorFields(mode, result) {
    const topLevelFields = Object.keys(mode).filter((key) => key !== 'id' && key !== 'uiModel');

    topLevelFields.forEach((key) => (result[key] = mode[key]));

    // Add the top level id using the key instanceId so it doesn't conflict with the id from the uiModel
    result.instanceId = mode.id;

    // for custom navigators, there is no special 'navigator code/id';
    // for custom navs we'll use the instance uuid as the id.
    if (!result.id && mode.ownerScope === 'organization') {
        result.id = result.instanceId;
    }

    return result;
}

angular.module('signalview.heatmap').provider('navigatorModes', function () {
    const provider = this;
    let modesById = {};

    this.get = function (id) {
        return modesById[id] || null;
    };

    this.forMetric = function (metricId) {
        for (const id in modesById) {
            if (!modesById.hasOwnProperty(id)) continue;

            const mode = modesById[id];
            if (mode.metrics) {
                for (let index = 0; index < mode.metrics.length; index++) {
                    if (mode.metrics[index].id === metricId) {
                        return mode;
                    }
                }
            }
        }
    };

    this.$get = [
        'config',
        'API_URL',
        '$http',
        '$rootScope',
        '$log',
        function (config, API_URL, $http, $rootScope, $log) {
            let cachedModes = null;
            let cacheUntil = 0;
            const cachedModesExpiry = 60 * 1000;
            const api = {};
            const testModes = [];
            api.get = provider.get.bind(provider);
            api.forMetric = provider.forMetric.bind(provider);

            function filterUnreleased(results) {
                const res = results
                    .filter(function (result) {
                        return result !== null;
                    })
                    .filter(function (result) {
                        return (
                            (result.unreleased && config('superpower.unreleasedFeatures')) ||
                            !result.unreleased
                        );
                    });

                //blow up the cache if we didn't find anything, this improves responsiveness in situations where the first
                //integration has yet to be discovered
                if (res.length === 0) {
                    cacheUntil = 0;
                }
                return res;
            }

            function joinModes(serverModes, modeUpdates) {
                const modeIdMap = {};
                const sortedModes = serverModes.sort(
                    (modeA, modeB) => modeB.lastUpdated - modeA.lastUpdated
                );
                angular.forEach(sortedModes, function (mode) {
                    if (!modeIdMap[mode.id]) {
                        modeIdMap[mode.id] = mode;
                    }
                });

                angular.forEach(modeUpdates, function (modeupdate) {
                    if (modeIdMap[modeupdate.id]) {
                        $log.info('Overwrote mode ' + modeupdate.id);
                    }
                    modeIdMap[modeupdate.id] = modeupdate;
                });

                modesById = modeIdMap;

                const outputValues = [];

                angular.forEach(modeIdMap, function (m) {
                    outputValues.push(m);
                });

                return outputValues;
            }

            function getModesFromServer() {
                return $http.get(API_URL + '/v2/navigator').then(function (res) {
                    return joinModes(
                        res.data.results.map((m) => {
                            const result = m.uiModel;
                            addTopLevelNavigatorFields(m, result);
                            return result;
                        }),
                        testModes
                    ).sort((a, b) => (a.category || '').localeCompare(b.category || ''));
                });
            }

            $rootScope.$on('current organization changed', function () {
                cachedModes = null;
            });

            function getModes() {
                const now = Date.now();
                if (!cachedModes || now > cacheUntil) {
                    cacheUntil = now + cachedModesExpiry;
                    cachedModes = config.await().then(getModesFromServer).then(filterUnreleased);
                }
                return cachedModes;
            }

            api.getNavigator = function (id) {
                return getModes().then((modes) => {
                    return modes?.find((mode) => mode.instanceId === id);
                });
            };

            // exposing these functions for usage in olly
            api.joinModes = joinModes;
            api.addTopLevelNavigatorFields = addTopLevelNavigatorFields;
            api.filterUnreleased = filterUnreleased;
            api.getModes = getModes;

            return api;
        },
    ];
});
