import templateUrl from './collectdProcessList.tpl.html';

export default [
    'hostProcessDataStream',
    'currentUser',
    'valueFormatter',
    'timeZoneService',
    'moment',
    function (hostProcessDataStream, currentUser, valueFormatter, timeZoneService, moment) {
        return {
            restrict: 'E',
            scope: {
                hostName: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.sortDescending = true;
                $scope.sortIndex = 8;
                $scope.limitProcessDisplay = true;
                $scope.displayLimit = 10;
                $scope.processSchema = [
                    {
                        label: 'PID',
                    },
                    {
                        label: 'USER',
                        align: 'right',
                    },
                    {
                        label: 'PR',
                        align: 'right',
                    },
                    {
                        label: 'NI',
                        formatter: 'kmg',
                        align: 'right',
                    },
                    {
                        label: 'VIRT',
                        formatter: 'kmg',
                        align: 'right',
                    },
                    {
                        label: 'RES',
                        formatter: 'kmg',
                        align: 'right',
                    },
                    {
                        label: 'SHR',
                        formatter: 'kmg',
                        align: 'right',
                    },
                    {
                        label: 'S',
                        align: 'right',
                    },
                    {
                        label: '%CPU',
                        formatter: 'percent',
                        align: 'right',
                    },
                    {
                        label: '%MEM',
                        formatter: 'percent',
                        align: 'right',
                    },
                    {
                        label: 'TIME+',
                        align: 'right',
                    },
                    {
                        label: 'COMMAND',
                    },
                ];

                function updateProcessStats() {
                    $scope.processStats = {};
                    $scope.processInfo.forEach(function (process) {
                        const state = $scope.processStats[process[7]];
                        if (!state) {
                            $scope.processStats[process[7]] = 0;
                        }
                        $scope.processStats[process[7]]++;
                    });
                }

                $scope.isRightAlignIndex = function (index) {
                    return index !== 1 && index !== 11 && index !== 7;
                };

                $scope.formatKMG = function (val) {
                    return valueFormatter.formatValue(val * 1024, 1, true);
                };

                $scope.formatPercent = function (val) {
                    return val.toFixed(1);
                };

                $scope.toggleSorting = function (idx) {
                    if (idx !== $scope.sortIndex) {
                        $scope.sortDescending = true;
                    } else {
                        $scope.sortDescending = !$scope.sortDescending;
                    }
                    $scope.sortIndex = idx;
                    $scope.sortProcesses();
                };

                function getMSFromHHMMSS(timestring) {
                    return moment.duration(timestring);
                }
                $scope.sortProcesses = function () {
                    $scope.processInfo.sort(function (a, b) {
                        let delt;
                        if (!$scope.sortDescending) {
                            const swp = b;
                            b = a;
                            a = swp;
                        }

                        if (typeof b[$scope.sortIndex] === 'string') {
                            if ($scope.sortIndex === 10) {
                                // HH:mm.ss time format...
                                delt =
                                    getMSFromHHMMSS(b[$scope.sortIndex]) -
                                    getMSFromHHMMSS(a[$scope.sortIndex]);
                            } else {
                                //strcmp
                                delt = b[$scope.sortIndex].localeCompare(a[$scope.sortIndex]);
                            }
                        } else {
                            delt = b[$scope.sortIndex] - a[$scope.sortIndex];
                        }
                        if (delt === 0) {
                            return b[0] - a[0];
                        }
                        return delt;
                    });
                };

                $scope.$on('$destroy', function () {
                    hostProcessDataStream.stopStream();
                });

                function startProcessStream() {
                    $scope.loading = true;
                    currentUser.orgId().then(function (orgId) {
                        hostProcessDataStream.streamProcessInfo(
                            $scope.hostName,
                            orgId,
                            function (data) {
                                $scope.loading = false;
                                $scope.processInfo = [];
                                angular.forEach(data.table, function (arr, pid) {
                                    arr.unshift(parseInt(pid, 10));
                                    $scope.processInfo.push(arr);
                                });
                                $scope.timeStamp = timeZoneService
                                    .moment(data.date)
                                    .format('ddd MMM D YYYY HH:mm:ss');
                                updateProcessStats();
                                $scope.sortProcesses();
                            },
                            function () {
                                $scope.processInfo = [];
                            }
                        );
                    });
                }

                $scope.$watch('hostName', function (newVal, oldVal) {
                    if (newVal === oldVal) {
                        return;
                    }

                    if (oldVal) {
                        hostProcessDataStream.stopStream();
                    }
                    startProcessStream();
                });
                startProcessStream();
            },
        };
    },
];
