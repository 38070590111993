angular
    .module('chartbuilderUtil')

    .service('colorByValueService', [
        'colorAccessibilityService',
        '_',
        function (colorAccessibilityService, _) {
            /*
      DON'T MODIFY THE COLOR VALUES! THEY ARE PERSISTED AND NEED TO BE USED TO
      MAP ACCROSS COLOR PALETTES.
     */
            const defaultScale = {
                red: '#ea1849',
                major: '#ff7e00',
                minor: '#e4ec00',
                warning: '#af71a0',
                green: '#05ce00',
            };

            const newDefaultScale = {
                red: '#ea1849',
                major: '#eac24b',
                minor: '#e5e517',
                warning: '#acef7f',
                green: '#6bd37e',
            };

            const colorOrders = [
                ['red', 'green'],
                ['red', 'minor', 'green'],
                ['red', 'major', 'minor', 'green'],
                ['red', 'major', 'minor', 'warning', 'green'],
            ];

            const colorToLabel = {};
            Object.keys(newDefaultScale).forEach(function (key) {
                colorToLabel[newDefaultScale[key]] = key;
            });

            function invertThresholdColors(colorThresholds) {
                colorThresholds[0].color =
                    colorThresholds[0].color === newDefaultScale.green
                        ? newDefaultScale.red
                        : newDefaultScale.green;

                reevalColorbys(colorThresholds);
            }

            function reevalColorbys(colorThresholds) {
                const inverted =
                    colorThresholds[0].color === newDefaultScale.green ||
                    colorThresholds[0].color === defaultScale.green;

                let colorScale = colorOrders[colorThresholds.length - 2].map(function (idx) {
                    return newDefaultScale[idx];
                });

                colorScale = inverted ? colorScale.reverse() : colorScale;

                for (let i = 0; i < colorThresholds.length; i++) {
                    colorThresholds[i].color = colorScale[i];
                }
            }

            function removeColorThreshold(colorThresholds, idx) {
                const customizedColors = isColorThresholdCustomized(colorThresholds);
                colorThresholds.splice(idx, 1);

                if (!customizedColors) {
                    reevalColorbys(colorThresholds);
                }
            }

            function isColorThresholdCustomized(colorThresholds) {
                let currentDefaultColorOrder = angular.copy(
                    colorOrders[colorThresholds.length - 2]
                );
                const inverted =
                    colorThresholds[0].color === newDefaultScale.green ||
                    colorThresholds[0].color === defaultScale.green;

                if (inverted) {
                    currentDefaultColorOrder = currentDefaultColorOrder.reverse();
                }

                return _.some(colorThresholds, (rule, idx) => {
                    return newDefaultScale[currentDefaultColorOrder[idx]] !== rule.color;
                });
            }

            function addThreshold(colorRule) {
                const customizedColors = isColorThresholdCustomized(colorRule);
                const inverted =
                    colorRule[0].color === newDefaultScale.green ||
                    colorRule[0].color === defaultScale.green;

                let colorScale = colorOrders[colorRule.length - 1].map(function (idx) {
                    return newDefaultScale[idx];
                });

                colorScale = inverted ? colorScale.reverse() : colorScale;
                const colorToAdd = colorScale[colorRule.length - 1];
                const threshold = {
                    color: colorToAdd,
                    availableColors: getDefaultScale(),
                    showAllColors: false,
                };
                colorRule.splice(colorRule.length - 1, 0, threshold);

                if (!customizedColors) {
                    reevalColorbys(colorRule);
                }
            }

            function getDefault() {
                return [
                    {
                        color: newDefaultScale.red,
                        gt: null,
                        availableColors: getDefaultScale(),
                        showAllColors: false,
                    },
                    {
                        color: newDefaultScale.green,
                        gt: 0,
                        availableColors: getDefaultScale(),
                        showAllColors: false,
                    },
                ];
            }

            function isThresholdValid(colorThresholds, idx) {
                if (!colorThresholds[idx]) {
                    return false;
                }
                if (idx === 0 || colorThresholds[idx].gt === undefined) {
                    return true;
                } else {
                    return colorThresholds[idx - 1].gt >= colorThresholds[idx].gt;
                }
            }

            function thresholdsAreValid(colorThresholds) {
                let hasThreshold;
                for (let i = 0; i < colorThresholds.length - 1; i++) {
                    if (colorThresholds[i].gt || colorThresholds[i].gt === 0) {
                        hasThreshold = true;
                    }
                    if (!isThresholdValid(colorThresholds, i)) {
                        return false;
                    }
                }
                return true && hasThreshold;
            }

            function isColorInScale(color, scale) {
                const colors = _.values(scale);
                return colors.indexOf(color) !== -1;
            }

            function convertOldColorToNewColor(color) {
                const colorsToSeverity = _.invert(defaultScale);
                const severity = colorsToSeverity[color];
                return newDefaultScale[severity];
            }

            function convertColors(colorThresholds) {
                colorThresholds.forEach((colorThreshold) => {
                    const color = colorThreshold.color;
                    if (isColorInScale(color, defaultScale)) {
                        colorThreshold.color = convertOldColorToNewColor(color);
                    }
                });
            }

            function getColorForValue(colorThresholds, val) {
                for (let i = 0; i < colorThresholds.length; i++) {
                    if (
                        (i === colorThresholds.length - 1 ||
                            isGreaterThan(val, colorThresholds[i])) &&
                        (i === 0 || isLessThan(val, colorThresholds[i]))
                    ) {
                        let color = colorThresholds[i].color;
                        if (isColorInScale(color, defaultScale)) {
                            color = convertOldColorToNewColor(color);
                        }

                        if (isColorInScale(color, newDefaultScale)) {
                            return colorAccessibilityService
                                .get()
                                .convertHeatmapColorToAccessible(color);
                        } else {
                            return colorAccessibilityService
                                .get()
                                .convertPlotColorToAccessible(color);
                        }
                    }
                }
            }

            function getColorForUpperBound(colorThresholds, upperBound) {
                if (!colorThresholds || colorThresholds.length === 0) {
                    // if no color threshold is defined, return a generic dark gray.
                    return '#777';
                }
                for (let i = 0; i < colorThresholds.length; i++) {
                    if (
                        colorThresholds[i].lte === upperBound ||
                        colorThresholds[i].lt === upperBound
                    ) {
                        let color = colorThresholds[i].color;
                        if (isColorInScale(color, defaultScale)) {
                            color = convertOldColorToNewColor(color);
                        }

                        if (isColorInScale(color, newDefaultScale)) {
                            return colorAccessibilityService
                                .get()
                                .convertHeatmapColorToAccessible(color);
                        } else {
                            return colorAccessibilityService
                                .get()
                                .convertPlotColorToAccessible(color);
                        }
                    }
                }
            }

            function isGreaterThan(val, currentThreshold) {
                return (
                    (typeof currentThreshold.gt !== 'number' &&
                        typeof currentThreshold.gte !== 'number') ||
                    (typeof currentThreshold.gt === 'number' && val > currentThreshold.gt) ||
                    (typeof currentThreshold.gte === 'number' && val >= currentThreshold.gte)
                );
            }

            function isLessThan(val, currentThreshold) {
                return (
                    (typeof currentThreshold.lt !== 'number' &&
                        typeof currentThreshold.lte !== 'number') ||
                    (typeof currentThreshold.lt === 'number' && val < currentThreshold.lt) ||
                    (typeof currentThreshold.lte === 'number' && val <= currentThreshold.lte)
                );
            }

            function getBorderClass(color) {
                if (isColorInScale(color, defaultScale)) {
                    color = convertOldColorToNewColor(color);
                }
                const defaultColor = colorAccessibilityService
                    .get()
                    .convertHeatmapColorToDefault(color);
                return 'color-by-value-border-' + colorToLabel[defaultColor];
            }

            function getBorderClasses() {
                let str = '';
                Object.keys(newDefaultScale).forEach(function (key) {
                    str += ' color-by-value-border-' + key;
                });
                return str;
            }

            function getDefaultScale() {
                return _.map(newDefaultScale, (color) => {
                    return colorAccessibilityService.get().convertHeatmapColorToAccessible(color);
                });
            }

            return {
                getDefault: getDefault,
                invertThresholdColors: invertThresholdColors,
                removeColorThreshold: removeColorThreshold,
                isThresholdValid: isThresholdValid,
                thresholdsAreValid: thresholdsAreValid,
                getColorForValue: getColorForValue,
                addThreshold: addThreshold,
                getBorderClasses: getBorderClasses,
                getBorderClass: getBorderClass,
                getColorForUpperBound: getColorForUpperBound,
                getDefaultScale: getDefaultScale,
                convertColors: convertColors,
            };
        },
    ]);
