import _ from 'lodash';

const NODE_IMPORT_KEYS = ['module', 'name', 'type'];
const NODE_IMPORT_ALIASED_KEYS = ['alias', 'module', 'name', 'type'];

export function isNodeFilter(ref, filterName) {
    return (
        isObject(ref) &&
        ref.type === 'filter' &&
        ref.field.value === filterName &&
        (isNodeString(ref.value) || isNodeStringArray(ref.values))
    );
}

export function isNodeBinaryOp(ref, operator) {
    if (
        !isObject(ref) ||
        !isObject(ref.left) ||
        !isObject(ref.right) ||
        ref.type !== 'binary_expression'
    ) {
        return false;
    }

    if (operator !== null && operator !== undefined && operator !== ref.op) {
        return false;
    }

    return true;
}

export function isNodeImport(ref) {
    return (
        isObjectAndHasExactlyListedKeys(ref, NODE_IMPORT_KEYS) ||
        isObjectAndHasExactlyListedKeys(ref, NODE_IMPORT_ALIASED_KEYS)
    );
}

function isNodeStringArray(ref) {
    return Array.isArray(ref) && ref.every(isNodeString);
}

export function isNodeString(ref) {
    return isObject(ref) && ref.type === 'string' && typeof ref.value === 'string';
}

export function isNodeNone(ref) {
    return isObject(ref) && ref.type === 'none';
}

export function isObjectAndHasExactlyListedKeys(ref, keys) {
    return isObject(ref) && _.isEqual(Object.keys(ref).sort(), keys.sort());
}

export function isObject(ref) {
    return !!ref && typeof ref === 'object' && !Array.isArray(ref);
}
