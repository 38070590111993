'use strict';

import { CurrentUserServiceMethods } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStore';
import { HTTPMethod, InstrumentedServiceClient } from '@splunk/olly-services/lib';
import qs from 'query-string';

export enum SnapshotType {
    Chart = 'Chart',
    Dashboard = 'Dashboard',
}

export type SnapShot = {
    description: string;
    id?: string;
    name: string;
    organizationId: string;
    payload: Record<string, any>;
    sourceId?: string;
    type: SnapshotType;
};

/**
 * Shareable snapshot service is a v2 api that
 * creates ephemeral snapshots of dashboards and charts
 * that expire after a set time.
 * These snapshots can be shared with users or on chat.
 */
export class ShareableSnapshotService {
    public static $inject = ['currentUser', 'httpClient'];

    private static resourceEndpoint = `/v2/shareablesnapshot`;

    private currentUser: CurrentUserServiceMethods;
    private httpClient: ReturnType<InstrumentedServiceClient>;

    public types = SnapshotType;

    constructor(
        currentUser: CurrentUserServiceMethods,
        httpClient: ReturnType<InstrumentedServiceClient>
    ) {
        this.currentUser = currentUser;
        this.httpClient = httpClient;
    }

    /**
     * Retrieve current organization id for user session and
     * create a query parameter of the form ?organizationId={{orgId}}
     */
    private getOrgIdParams(): Promise<{ organizationId: string }> {
        return this.currentUser.orgId().then((orgId) => {
            return { organizationId: orgId };
        });
    }

    /**
     * Search for snapshots for the current organization.
     * Returns snapshots
     */
    public search(query = ''): Promise<SnapShot> {
        return this.getOrgIdParams()
            .then((params) =>
                this.httpClient<SnapShot>({
                    httpMethod: HTTPMethod.GET,
                    endpoint: `${ShareableSnapshotService.resourceEndpoint}?${qs.stringify({
                        ...params,
                        query,
                    })}`,
                })
            )
            .then((resp) => {
                return resp.data;
            });
    }

    /**
     * Create a new snapshot.
     * Returns created snapshot.
     */
    public create(
        type: SnapshotType,
        name = '',
        description = '',
        payload: Record<string, any>,
        sourceId?: string
    ): Promise<SnapShot> {
        return this.getOrgIdParams()
            .then((params) => {
                const body: SnapShot = {
                    name,
                    description,
                    type,
                    payload: { ...payload, version: 1 },
                    ...params,
                    ...(sourceId && { sourceId }),
                };

                return this.httpClient<SnapShot>({
                    body,
                    httpMethod: HTTPMethod.POST,
                    endpoint: `${ShareableSnapshotService.resourceEndpoint}`,
                });
            })
            .then((resp) => {
                return resp.data;
            });
    }

    /**
     * Put updated request for a snapshot.
     * Returns updated snapshot.
     */
    public update(snapshot: SnapShot): Promise<SnapShot> {
        return this.getOrgIdParams()
            .then((params) => {
                return this.httpClient<SnapShot>({
                    httpMethod: HTTPMethod.PUT,
                    endpoint: `${ShareableSnapshotService.resourceEndpoint}/${snapshot.id}`,
                    body: {
                        ...snapshot,
                        ...(snapshot.sourceId && { sourceId: snapshot.sourceId }),
                        ...params,
                    },
                });
            })
            .then((resp) => {
                return resp.data;
            });
    }

    /**
     * Get a snapshot by id.
     * Returns snapshot.
     */
    public get(
        snapshotId: string,
        errorHandler?: (arg: { fn: (...args: any) => any; id: string; error: Error }) => void
    ): Promise<SnapShot | void> {
        return this.getOrgIdParams()
            .then((params) => {
                return this.httpClient<SnapShot>({
                    httpMethod: HTTPMethod.GET,
                    endpoint: `${
                        ShareableSnapshotService.resourceEndpoint
                    }/${snapshotId}?${qs.stringify(params)}`,
                });
            })
            .then(
                (resp) => {
                    if (
                        resp.data.payload?.dashboard &&
                        !('maxDelayOverride' in resp.data.payload.dashboard)
                    ) {
                        resp.data.payload.dashboard.maxDelayOverride = null;
                    }
                    return resp.data;
                },
                (error: Error) => {
                    if (errorHandler) {
                        return errorHandler({ fn: this.get, id: snapshotId, error: error });
                    } else {
                        return Promise.reject(error);
                    }
                }
            );
    }

    public getIgnoreNotFound(snapshotId: string): Promise<SnapShot | void> {
        const ignoreError = (): void => {};
        return this.get(snapshotId, ignoreError);
    }

    /**
     * Notify recipients with snapshot information. Receives a snapshot id and
     * an object containing arrays of notifications
     */
    public notify(
        snapshotId: string,
        notifications: { email?: string; slack?: string; office365?: string }
    ): Promise<Record<string, never>> {
        return this.getOrgIdParams()
            .then((params) => {
                return this.httpClient<Record<string, never>>({
                    httpMethod: HTTPMethod.POST,
                    endpoint: `${
                        ShareableSnapshotService.resourceEndpoint
                    }/${snapshotId}/notify?${qs.stringify(params)}`,
                    body: {
                        emails: notifications.email,
                        slack: notifications.slack,
                        office365: notifications.office365,
                    },
                });
            })
            .then((resp) => {
                return resp.data;
            });
    }
}
