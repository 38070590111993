'use strict';
import templateUrl from './fancyGroupBy.tpl.html';
import fancyGroupByInputTemplateUrl from './fancyGroupByInput.tpl.html';

angular.module('sfx.ui').directive('fancyGroupBy', [
    '$log',
    '$timeout',
    function ($log, $timeout) {
        return {
            restrict: 'E',
            scope: {
                getSuggestions: '=',
                groupBy: '=',
                enableUrlParams: '=?',
                onGroupBy: '=?',
                groupByLimit: '@',
                hideLabel: '@?',
            },
            templateUrl,
            link: {
                pre: function ($scope) {
                    $scope.selector = {
                        selected: [],
                        suggestions: null,
                        highlighted: null,
                        query: null,
                    };
                },
                post: function ($scope, element) {
                    $scope.fancyGroupByInputTemplateUrl = fancyGroupByInputTemplateUrl;
                    $scope.groupByLimit = $scope.groupByLimit
                        ? parseInt($scope.groupByLimit, 10)
                        : 2;

                    const selector = $scope.selector;

                    function getGroupBys() {
                        const groupBys = selector.selected.slice(0);

                        if (selector.highlighted) {
                            const hasHighlighted = groupBys.some(function (groupBy) {
                                return groupBy.displayName === selector.highlighted.displayName;
                            });

                            if (!hasHighlighted) {
                                if ($scope.replacementIndex === -1) {
                                    groupBys.push(selector.highlighted);
                                } else {
                                    groupBys.splice(
                                        $scope.replacementIndex,
                                        1,
                                        selector.highlighted
                                    );
                                }
                            }
                        }

                        return groupBys.map(function (item) {
                            return item.displayName;
                        });
                    }

                    function resetReplacementState() {
                        $scope.replacementIndex = -1;
                        $scope.replacementSelection = null;
                    }

                    resetReplacementState();

                    selector.inputVisible = false;
                    selector.showInput = function () {
                        selector.inputVisible = true;

                        $timeout(function () {
                            element.find('input').focus();
                        });
                    };

                    selector.hideInput = function () {
                        selector.inputVisible = false;
                    };

                    function loadGroupBy() {
                        const groupBy = $scope.groupBy || [];
                        if (!angular.equals(groupBy, getGroupBys())) {
                            selector.selected = groupBy.map(function (groupBy) {
                                return {
                                    displayName: groupBy,
                                };
                            });

                            selector.inputVisible = false;
                        }
                    }

                    $scope.$watchCollection('groupBy', loadGroupBy);
                    loadGroupBy();

                    let inCooldown = false;

                    selector.select = function (item) {
                        selector.query = null;
                        selector.highlighted = null;
                        if ($scope.replacementIndex === -1) {
                            selector.selected.push(item);
                            updateSuggestions();
                        } else {
                            selector.selected.splice($scope.replacementIndex, 1, item);
                            resetReplacementState();
                        }
                        selector.hideInput();
                        inCooldown = true;
                        $timeout(function () {
                            inCooldown = false;
                        }, 2000);
                    };

                    selector.replace = function (item) {
                        $scope.replacementIndex = selector.selected.indexOf(item);
                        $scope.replacementSelection = selector.selected.slice(
                            $scope.replacementIndex,
                            1
                        )[0];
                        updateSuggestions();
                        selector.showInput();
                    };

                    let currentRequest = 0;
                    function updateSuggestions() {
                        const query = selector.query;
                        selector.suggestions = [];
                        currentRequest++;
                        const thisRequest = currentRequest;
                        $scope.getSuggestions(query).then(function (results) {
                            if (thisRequest !== currentRequest) {
                                $log.debug('Dropping stale results.', results);
                                return;
                            }
                            const highlighted =
                                selector.highlighted && selector.highlighted.displayName;
                            selector.suggestions = results
                                .filter(function (facet) {
                                    const alreadySelected = $scope.groupBy.some(function (item) {
                                        return item === facet && highlighted !== item;
                                    });

                                    if (alreadySelected) return false;
                                    if (!query) return true;
                                    return facet.indexOf(query) !== -1;
                                })
                                .map(function (facet) {
                                    return {
                                        displayName: facet,
                                    };
                                });

                            if (selector.query) {
                                selector.highlighted = selector.suggestions[0];
                            } else {
                                selector.highlighted = null;
                            }
                        });
                    }

                    selector.remove = function (item) {
                        selector.selected.splice(selector.selected.indexOf(item), 1);
                        updateSuggestions();
                        selector.showInput();
                    };

                    $scope.$watch(
                        'selector.selected',
                        function (newValue, oldValue) {
                            $scope.groupBy = getGroupBys();
                            if (newValue !== oldValue) {
                                $scope.onGroupBy && $scope.onGroupBy();
                            }
                        },
                        true
                    );

                    $scope.$watch(
                        'selector.highlighted',
                        function () {
                            if (inCooldown) {
                                selector.highlighted = null;
                                return;
                            }
                            //set highlight
                            $scope.$emit('groupBy selector highlighted', getGroupBys());
                        },
                        true
                    );

                    $scope.$watch('selector.visible', function (isVisible, wasVisible) {
                        if (isVisible) {
                            updateSuggestions();

                            if (!wasVisible) {
                                $scope.$emit('groupBy selector visible');
                            }
                        } else {
                            selector.suggestions = null;
                            selector.highlighted = null;
                            selector.inputVisible = false;

                            if (wasVisible) {
                                $scope.$emit('groupBy selector hidden');
                                resetReplacementState();
                            }
                        }
                    });

                    $scope.$watch('selector.query', updateSuggestions);
                },
            },
        };
    },
]);
