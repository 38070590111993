import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import { detectorTypeResolver } from '../alerts/detectorTypeResolver';

const AUTODETECT_LABEL = 'autodetect';
const AUTODETECT_TOOLTIP = 'AutoDetect Detector event';

const CUSTOMIZATION_LABEL = 'custom';
const CUSTOMIZATION_TOOLTIP = 'Customized AutoDetect Detector event';

type Event = {
    sf_eventDetectorOrigin?: string;
};

const AutodetectChip: FC<{ event: Event }> = ({ event }) => {
    const [tooltipText, setTooltipText] = useState('');
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (detectorTypeResolver.isOOTBDetectorEvent(event)) {
            setLabel(AUTODETECT_LABEL);
            setTooltipText(AUTODETECT_TOOLTIP);
        }
        if (detectorTypeResolver.isOOTBCustomizationDetectorEvent(event)) {
            setLabel(CUSTOMIZATION_LABEL);
            setTooltipText(CUSTOMIZATION_TOOLTIP);
        }
    }, [event]);

    return tooltipText && label ? (
        <Tooltip content={tooltipText}>
            <span className="label-badge autodetect">
                <i className="glyphicon glyphicon-flash" />
                {label}
            </span>
        </Tooltip>
    ) : null;
};

AutodetectChip.propTypes = {
    event: PropTypes.object.isRequired,
};

export default AutodetectChip;
