angular
    .module('signalboost')

    /**
     * Signalboost provides service objects for every major object (e.g. TagRestService for tags
     * and DetectorRestService for detectors). Each service object inherits from the
     * base service, SignalboostBaseService. 'signalboostBuilder' builds each of these
     * services and attaches them to the signalboost in order to avoid circular dependencies.
     */
    .factory('signalboostBuilder', [
        '$rootScope',
        'signalboost',
        'SignalboostBaseService',
        'signalboostUtil',
        'API_V1_URL',
        'UserRestService',
        'UserAuthRestService',
        'DetectorRestService',
        'EventRestService',
        'TagRestService',
        'AutoSuggestRestService',
        'OrgPreferenceRestService',
        'MetricTimeSeriesRestService',
        function (
            $rootScope,
            signalboost,
            SignalboostBaseService,
            signalboostUtil,
            API_V1_URL,
            UserRestService,
            UserAuthRestService,
            DetectorRestService,
            EventRestService,
            TagRestService,
            AutoSuggestRestService,
            OrgPreferenceRestService,
            MetricTimeSeriesRestService
        ) {
            $rootScope.$on('clear caches', function clearCaches() {
                angular.forEach(signalboost, (svc) => {
                    if (svc.clearCache) {
                        svc.clearCache();
                    }
                });
            });

            // Classes that add no functionality on top of the BaseService class are
            // declared here.
            class DetectorV1SignalflowV2ServiceV1 extends SignalboostBaseService {
                constructor() {
                    super('detector', false, 'detectorv1signalflowv2');
                    this.endpoint('detectorsignalflowv2');
                }
            }

            class SessionServiceV1 extends SignalboostBaseService {
                constructor() {
                    super('session', true);
                }
            }

            class SourceServiceV1 extends SignalboostBaseService {
                constructor() {
                    super('source', false, '', signalboostUtil.sourceQuerySanitizer);
                }
            }

            // TODO (matthew): move to own service
            signalboost.sso = {
                returnToken: function (apiRoute, token, org, params) {
                    // Need to figure out a way to use the rest call.
                    // JS clients can't capture 302s as of now.
                    // rest.all('sso').one(apiRoute).post('', params);
                    let paramsStr = '?';
                    angular.forEach(params, function (value, key) {
                        paramsStr += '&' + key + '=' + value;
                    });
                    paramsStr += '&token=' + token;
                    paramsStr += '&requested_org=' + org;
                    const windowLocation = API_V1_URL + '/sso/' + apiRoute + paramsStr;
                    window.location = windowLocation;
                },
            };

            return { build };

            function build() {
                angular.extend(signalboost, {
                    // object apis
                    user: new UserRestService(),
                    metrictimeseries: new MetricTimeSeriesRestService(),
                    detector: new DetectorRestService(),
                    detector1flow2: new DetectorV1SignalflowV2ServiceV1(),
                    event: new EventRestService(),
                    autosuggest: new AutoSuggestRestService(),
                    session: new SessionServiceV1(),
                    uauth: new UserAuthRestService(),
                    tag: new TagRestService(),
                    source: new SourceServiceV1(),
                    orgpreference: new OrgPreferenceRestService(),
                });
            }
        },
    ]);
