class DimensionsService {
    getForPlots(plots = [], orgId) {
        return this._getUniqueDimensions(plots).map((dimension) => ({
            propertyName: dimension.key,
            propertyValue: dimension.value,
            url: this._getDataLinkUrl(dimension, orgId),
        }));
    }

    _getUniqueDimensions(plots) {
        const dimensions = plots
            .filter((plot) => !!plot.key)
            .flatMap((plot) => this._getPlotDimensions(plot));
        return _.uniqWith(dimensions, _.isEqual);
    }

    _getPlotDimensions(plot) {
        return Object.entries(plot.key)
            .map(([key, value]) => ({ key, value }))
            .filter((obj) => obj.key !== 'sf_metric');
    }

    _getDataLinkUrl({ key, value }, orgId) {
        return `#/alerts/${orgId}?sources[]=${encodeURIComponent(key)}:${encodeURIComponent(
            value
        )}`;
    }
}

export default new DimensionsService();
