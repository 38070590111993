import { isNodeFilter, isNodeBinaryOp, isNodeString } from './signalflowAstNodeVerifiers.js';

import { extractString, extractStringArray } from './signalflowAstFunctionArgumentsExtractors.js';

export function extractApmV2ServiceEndpoints(ref, key) {
    if (isSupportedApmV2FilterNode(ref)) {
        if (isSupportedApmV2ServiceEndpointsWithKindNode(ref)) {
            return extractSingleServiceFilter(ref.left, key);
        } else if (isSupportedApmV2EnvironmentServiceEndpointsWithKindNode(ref)) {
            return extractSingleServiceFilter(ref.left.left, key);
        } else if (isSupportedApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref)) {
            return extractApmV2ServiceListSegment(ref.left.left);
        } else if (isSupportedApmV2MultiServiceEndpointsWithKindNode(ref)) {
            return extractApmV2ServiceListSegment(ref.left);
        } else {
            let singleServiceFilterNode;
            if (isLegacyApmV2MultiServiceEndpointsWithKindNode(ref)) {
                singleServiceFilterNode = ref.right.left;
            } else if (isLegacyApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref)) {
                singleServiceFilterNode = ref.right.left.left;
            }

            const leftFilterList = extractApmV2ServiceListSegment(ref.left);
            const rightMetadata = extractSingleServiceFilter(singleServiceFilterNode, key);

            return leftFilterList.concat(rightMetadata);
        }
    }
    throw new Error(`Parameter ${key} is not a valid APM filter with service-endpoints.`);
}

export function extractApmV2Workflows(ref, key) {
    if (isSupportedApmV2FilterNode(ref)) {
        if (isSupportedApmV2EnvironmentWorkflowsFilterNode(ref)) {
            return extractWorkflowsFilter(ref.left, key);
        } else if (isSupportedApmV2WorkflowsFilterNode(ref)) {
            return extractWorkflowsFilter(ref, key);
        }
    }
    throw new Error(`Parameter ${key} is not a valid APM filter with workflows.`);
}

export function extractApmV2Environment(ref, key) {
    if (
        isSupportedApmV2ServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2MultiServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2WorkflowsFilterNode(ref) ||
        isLegacyApmV2MultiServiceEndpointsWithKindNode(ref)
    ) {
        return undefined;
    } else if (
        isSupportedApmV2EnvironmentServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2EnvironmentWorkflowsFilterNode(ref)
    ) {
        return extractEnvironmentFilter(ref.right, key);
    } else if (isLegacyApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref)) {
        return extractEnvironmentFilter(ref.right.right, key);
    }

    throw new Error(`Parameter ${key} is not a valid APM filter with environment.`);
}

function extractEnvironmentFilter(ref, key) {
    if (isNodeFilter(ref, 'sf_environment')) {
        return ref.value.value;
    }
    throw new Error(`Parameter ${key} is not a valid APM sf_environment filter.`);
}

function extractWorkflowsFilter(ref, key) {
    let filterList = [];

    if (isNodeFilter(ref, 'sf_workflow')) {
        filterList = filterList.concat({
            _resource: ref.value.value,
        });
    } else if (isNodeBinaryOp(ref, 'OR')) {
        filterList = filterList.concat(
            extractWorkflowsFilter(ref.left, key),
            extractWorkflowsFilter(ref.right, key)
        );
    } else {
        throw new Error(`Parameter ${key} is not a valid APM sf_workflow filter.`);
    }

    return filterList;
}

function extractSingleServiceFilter(ref, key) {
    if (isSupportedApmV2SingleServiceFilterNode(ref)) {
        const endpointsNode = ref.right;

        return [
            {
                _endpoints: isNodeString(endpointsNode.value)
                    ? [extractString(endpointsNode.value)]
                    : extractStringArray(endpointsNode.values),
                _service: ref.left.value.value,
            },
        ];
    }
    throw new Error(`Parameter ${key} is not a valid APM single service filter.`);
}

function extractApmV2ServiceListSegment(ref) {
    let filterList = [];

    if (isSupportedApmV2SingleServiceFilterNode(ref)) {
        return extractSingleServiceFilter(ref);
    }

    if (isSupportedApmV2SingleServiceFilterNode(ref.left)) {
        filterList = filterList.concat(extractSingleServiceFilter(ref.left));
    } else {
        filterList = filterList.concat(extractApmV2ServiceListSegment(ref.left));
    }

    filterList = filterList.concat(extractSingleServiceFilter(ref.right));
    return filterList;
}

export function isSupportedApmV2FilterNode(ref) {
    return (
        isSupportedApmV2ServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2EnvironmentServiceEndpointsWithKindNode(ref) ||
        isLegacyApmV2MultiServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2MultiServiceEndpointsWithKindNode(ref) ||
        isLegacyApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref) ||
        isSupportedApmV2WorkflowsFilterNode(ref) ||
        isSupportedApmV2EnvironmentWorkflowsFilterNode(ref)
    );
}

function isSupportedApmV2MultiServiceEndpointsWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmV2ServiceListSegmentNode(ref.left) &&
        isSupportedApmV2KindFilterNode(ref.right)
    );
}

// this is support for legacy, invalid signalflow, fixed with this change:
// commit hash: 0595d664892b5f3908143606ff5172611f8c9c26
// Issue: https://signalfuse.atlassian.net/browse/APMF-1983
function isLegacyApmV2MultiServiceEndpointsWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'OR') &&
        isSupportedApmV2ServiceListSegmentNode(ref.left) &&
        isSupportedApmV2ServiceEndpointsWithKindNode(ref.right)
    );
}

function isSupportedApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmV2ServiceListSegmentWithKindNode(ref.left) &&
        isSupportedApmV2EnvironmentFilterNode(ref.right)
    );
}

// this is support for legacy, invalid signalflow, fixed with this change:
// commit hash: 0595d664892b5f3908143606ff5172611f8c9c26
// Issue: https://signalfuse.atlassian.net/browse/APMF-1983
function isLegacyApmV2EnvironmentMultiServiceEndpointsWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'OR') &&
        isSupportedApmV2ServiceListSegmentNode(ref.left) &&
        isSupportedApmV2EnvironmentServiceEndpointsWithKindNode(ref.right)
    );
}

function isSupportedApmV2ServiceListSegmentWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmV2ServiceListSegmentNode(ref.left) &&
        isSupportedApmV2KindFilterNode(ref.right)
    );
}

function isSupportedApmV2ServiceListSegmentNode(ref) {
    if (isSupportedApmV2SingleServiceFilterNode(ref)) {
        return true;
    }

    return (
        isNodeBinaryOp(ref, 'OR') &&
        isSupportedApmV2ServiceListSegmentNode(ref.left) &&
        isSupportedApmV2SingleServiceFilterNode(ref.right)
    );
}

function isSupportedApmV2EnvironmentServiceEndpointsWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmV2ServiceEndpointsWithKindNode(ref.left) &&
        isSupportedApmV2EnvironmentFilterNode(ref.right)
    );
}

function isSupportedApmV2ServiceEndpointsWithKindNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmV2SingleServiceFilterNode(ref.left) &&
        isSupportedApmV2KindFilterNode(ref.right)
    );
}

function isSupportedApmV2KindFilterNode(ref) {
    return isNodeFilter(ref, 'sf_kind');
}

function isSupportedApmV2SingleServiceFilterNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isNodeFilter(ref.left, 'sf_service') &&
        isNodeFilter(ref.right, 'sf_operation')
    );
}

function isSupportedApmV2EnvironmentFilterNode(ref) {
    return isNodeFilter(ref, 'sf_environment');
}

function isSupportedApmV2EnvironmentWorkflowsFilterNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmV2WorkflowsFilterNode(ref.left) &&
        isSupportedApmV2EnvironmentFilterNode(ref.right)
    );
}

function isSupportedApmV2WorkflowsFilterNode(ref) {
    if (isNodeFilter(ref, 'sf_workflow')) {
        return true;
    }

    return (
        isNodeBinaryOp(ref, 'OR') &&
        isSupportedApmV2WorkflowsFilterNode(ref.left) &&
        isSupportedApmV2WorkflowsFilterNode(ref.right)
    );
}
