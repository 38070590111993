import { AccessControlPermissionTypes, detectPrincipalPermissionType } from '@splunk/olly-services';
import AccessControlPermissionAction from './AccessControlPermissionAction';
import PRINCIPAL_TYPE from './AccessControlPrincipalType';
import AccessControlPermissionActionsSerializer from './AccessControlPermissionActionsSerializer';

const accessControlHelper = {
    extendWithActions,
    detectPermissionType,
    getDefaultAcl,
    getDefaultActions,
    canUserModifyPermissions,
    getUserEffectiveActions,
    getNoCurrentUserInAclErrorMessage,
    isEqualPermissionSet,
    isPermissionsObjectValid,
    getInvalidParentErrorMessage,
    getInvalidPermissionTypeErrorMessage,
    getAclLimitReachedMessage,
};

export default accessControlHelper;

function createNewAClOrgObject(id, writeAccess) {
    return createNewAclObject(id, PRINCIPAL_TYPE.ORG, writeAccess);
}

function createNewAclUserObject(id, writeAccess) {
    return createNewAclObject(id, PRINCIPAL_TYPE.USER, writeAccess);
}

function createNewAclObject(id, type, writeAccess) {
    return extendWithActions(
        {
            principalId: id,
            principalType: type,
        },
        writeAccess
    );
}

function isAclPropValid(acl) {
    return Array.isArray(acl) || acl === null;
}

function isParentPropValid(parent) {
    const propType = typeof parent;
    return ['string', 'undefined'].includes(propType) || parent === null;
}

function isPermissionsObjectValid(obj) {
    if (typeof obj !== 'object') {
        return false;
    }

    if (!isAclPropValid(obj?.acl)) {
        return false;
    }

    if (!isParentPropValid(obj?.parent)) {
        return false;
    }

    return true;
}

function detectPermissionType(acl = []) {
    return detectPrincipalPermissionType(acl);
}

function getDefaultAcl(orgId, userId, permissionType) {
    const defaultAcl = [];
    switch (permissionType) {
        case AccessControlPermissionTypes.INHERIT:
            return null;
        case AccessControlPermissionTypes.PUBLIC:
            defaultAcl.push(createNewAClOrgObject(orgId, true));
            break;
        case AccessControlPermissionTypes.RESTRICTED_WRITE:
            // default acl for RESTRICTED_WRITE: currentOrg - read, currentUser (not admin) - read/write
            defaultAcl.push(createNewAClOrgObject(orgId, false));
            if (userId) {
                defaultAcl.push(createNewAclUserObject(userId, true));
            }
            break;
        case AccessControlPermissionTypes.RESTRICTED:
            // default acl for RESTRICTED: currentUser - read/write
            if (userId) {
                defaultAcl.push(createNewAclUserObject(userId, true));
            }
            break;
    }

    return defaultAcl;
}

function getDefaultActions(permissionType) {
    const actions = [];
    switch (permissionType) {
        case AccessControlPermissionTypes.RESTRICTED_WRITE:
            actions.push(AccessControlPermissionAction.READ, AccessControlPermissionAction.WRITE);
            break;
        case AccessControlPermissionTypes.RESTRICTED:
            actions.push(AccessControlPermissionAction.READ);
            break;
    }
    return actions;
}

function extendWithActions(aclObj, writeAccess) {
    const actions = [AccessControlPermissionAction.READ];
    if (writeAccess) {
        actions.push(AccessControlPermissionAction.WRITE);
    }

    return {
        ...aclObj,
        actions,
    };
}

function getUserEffectiveActions(userId, aclPrincipals, isAdmin) {
    if (isAdmin) {
        return [AccessControlPermissionAction.READ, AccessControlPermissionAction.WRITE];
    }

    const userActions = [];
    aclPrincipals.forEach((principal) => {
        if (
            // use org actions
            PRINCIPAL_TYPE.ORG === principal.__principalType ||
            // use team actions when user is member of a team
            (PRINCIPAL_TYPE.TEAM === principal.__principalType &&
                Array.isArray(principal.members) &&
                principal.members.includes(userId)) ||
            // use user personal actions
            (PRINCIPAL_TYPE.USER === principal.__principalType && principal.id === userId)
        ) {
            principal.actions.forEach((action) => {
                // add only actions not already present in user effective actions
                if (!userActions.includes(action)) {
                    userActions.push(action);
                }
            });
        }
    });
    return userActions;
}

function canUserModifyPermissions(userEffectiveActions) {
    return userEffectiveActions.includes(AccessControlPermissionAction.WRITE);
}

function getNoCurrentUserInAclErrorMessage(objectTypeLabel) {
    const message = 'Add yourself to the permissions list to save changes';
    if (typeof objectTypeLabel !== 'string') {
        return message;
    }

    return `${message} to this ${objectTypeLabel.toLowerCase()}`;
}

function getInvalidParentErrorMessage(objectType, customLabel, readonly) {
    const label = customLabel || objectType.label;
    const parentLabel = objectType.parent.label;
    const message = `This ${label?.toLowerCase()} inherits its permissions from an invalid ${parentLabel?.toLowerCase()}.`;
    if (readonly) {
        return `${message} Please contact an admin.`;
    }
    return `${message} To fix this issue, please select a valid ${parentLabel?.toLowerCase()} in the dropdown and click \'Save\'.`;
}

function getInvalidPermissionTypeErrorMessage(objectType, customLabel, readonly) {
    const label = customLabel || objectType.label;
    const message = `This ${label?.toLowerCase()} has invalid permissions.`;
    if (readonly) {
        return `${message} Please contact an admin.`;
    }
    return `${message} To fix this issue, please select a valid type in the dropdown and click \'Save\'.`;
}

function getAclLimitReachedMessage(aclLimit) {
    return `A maximum of ${aclLimit} teams or people can be added to the ACL. Please consolidate the list by inviting teams instead of individuals where possible before adding to the list.`;
}

function isEqualPermissionSet(permissions1, permissions2) {
    if (permissions1?.parent !== permissions2?.parent) {
        return false;
    }

    if (serializeAcl(permissions1?.acl) !== serializeAcl(permissions2?.acl)) {
        return false;
    }

    return true;
}

function serializeAcl(acl) {
    return (acl || [])
        .map(
            (item) =>
                `${item.principalId}-${AccessControlPermissionActionsSerializer.serialize(
                    item.actions
                )}`
        )
        .sort()
        .join();
}
