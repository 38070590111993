import variablesEditor from './variablesEditor';
import dashboardPermissions from './dashboardPermissions';
import dashboardCrossLinkDefinition from './dashboardCrossLinkDefinition';
import dashboardSettingsModal from './dashboardSettingsModal';
import dashboardInfo from './dashboardInfo';
import eventOverlayRow from './eventOverlayRow';
import eventOverlayPage from './eventOverlayPage';
import maxDelayOverridePicker from './maxDelayOverridePicker';

angular
    .module('signalview.dashboard.modal', [
        'signalview.typeahead',
        'signalview.dashboardUtil',
        'signalview.urlOverrides',
        'sfx.filterControls',
        'sfx.api.v2',
    ])
    .service('dashboardSettingsModal', dashboardSettingsModal)
    .component('maxDelayOverridePicker', maxDelayOverridePicker)
    .component('dashboardPermissions', dashboardPermissions)
    .component('dashboardInfo', dashboardInfo)
    .component('dashboardCrossLinkDefinition', dashboardCrossLinkDefinition)
    .component('eventOverlayPage', eventOverlayPage)
    .component('eventOverlayRow', eventOverlayRow)
    .component('variablesEditor', variablesEditor);
