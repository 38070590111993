'use strict';

import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import alertModal2TemplateUrl from '../../../../app/alertEventModalV2/alertEventModalV2.html';
import customEventModalTemplateUrl from './customEventModal.html';

/* jshint camelcase: false */

angular
    .module('sfx.ui')
    .factory('showEvent', [
        'sfxModal',
        'v2DetectorResolverUtil',
        function (sfxModal, v2DetectorResolverUtil) {
            return function (event) {
                let template, controller, size;

                switch (event.metadata.sf_eventCategory) {
                    case 'ALERT':
                        template = alertModal2TemplateUrl;
                        size = 'lg alert-modal-v2';
                        // declare-used-dependency-to-linter::alertEventModalV2
                        controller = 'alertEventModalV2';
                        break;

                    default:
                        template = customEventModalTemplateUrl;
                        // declare-used-dependency-to-linter::customEventModal
                        controller = 'customEventModal';
                        break;
                }

                return sfxModal.open({
                    templateUrl: template,
                    controller: controller,
                    resolve: {
                        event: v2DetectorResolverUtil.extendV2DetectorBasedEvent(event),
                    },
                    size: size,
                });
            };
        },
    ])
    .factory('eventModal', [
        '$location',
        'showEvent',
        function ($location, showEvent) {
            return function (event) {
                if (event.id) {
                    $location.search('eventId', event.id);
                } else {
                    showEvent(event);
                }
            };
        },
    ])
    .run([
        '$rootScope',
        '$window',
        '$log',
        'eventService',
        '$location',
        'showEvent',
        'routeParameterService',
        'userAnalytics',
        'signalviewMetrics',
        'moment',
        function (
            $rootScope,
            $window,
            $log,
            eventService,
            $location,
            showEvent,
            routeParameterService,
            userAnalytics,
            signalviewMetrics,
            moment
        ) {
            let currentIncidentId = null;
            let currentEventId = null;
            let modal = null;
            let eventIdLastUpdateToAnotherEvent = false;

            function closeModal() {
                if (modal) {
                    modal.close();
                    modal = null;
                }
            }

            function updateEventURLParamClose() {
                // If we are going from one event to another - we don't need to wipe out the URL parameter
                if (!eventIdLastUpdateToAnotherEvent) {
                    $location.search('eventId', null);
                }

                eventIdLastUpdateToAnotherEvent = false;
            }

            const onEventIdUpdate = function () {
                const newEventId = $location.search().eventId;
                eventIdLastUpdateToAnotherEvent = false;

                // if we are already updating it - don't re-trigger the update
                if (currentEventId !== newEventId) {
                    if (currentEventId && newEventId) {
                        eventIdLastUpdateToAnotherEvent = true;
                    }

                    // close any existing modal (if it's there)
                    closeModal();

                    currentEventId = newEventId;

                    if (newEventId) {
                        eventService
                            .search({
                                query: '_id:"' + newEventId + '"',
                                limit: 1,
                                offset: 0,
                            })
                            .then(function (data) {
                                if (data && data.length) {
                                    const event = data[0];
                                    // check if we'are still really processing the event that we received.
                                    if (currentEventId === event.id) {
                                        modal = showEvent(event);
                                        modal.result.finally(updateEventURLParamClose);
                                        userAnalytics.event('incident-modal', 'open-from-app');
                                        signalviewMetrics.incr('ui.incidentModal.openedFromApp');
                                    } else {
                                        // otherwise, throw away that event since we no longer care about it.
                                        $log.info('Throw away stale event response');
                                    }
                                } else {
                                    //cant find event? should not happen in real life.
                                    currentEventId = null;
                                    $location.search('eventId', null);
                                    $window.alert("Event can't be found");
                                }
                            });
                    }
                }
            };

            function updateIncidentURLParamClose() {
                $location.search('incidentId', null);
                $location.search('is', null);
            }

            const onIncidentIdUpdate = function () {
                const newIncidentId = $location.search().incidentId;
                const is = $location.search().is;
                if (currentIncidentId !== newIncidentId) {
                    currentIncidentId = newIncidentId;

                    closeModal();

                    if (currentIncidentId) {
                        const params = ['incidentId:"' + currentIncidentId + '"'];
                        if (is) {
                            params.push('is:"' + sanitizeTerm(is) + '"');
                        }
                        eventService
                            .search({
                                query: params.join(' AND '),
                                limit: 1,
                                offset: 0,
                            })
                            .then(function (data) {
                                if (data && data.length) {
                                    const event = data[0];

                                    if (currentIncidentId === event.properties.incidentId) {
                                        // convert incidentId parameter to eventId and adjust proper timestamp.
                                        const resolution = event.properties.sf_resolutionMs || 1000;
                                        const range = resolution * 600;
                                        const eachSide = range / 2;
                                        const timestamp = moment(event.timestamp);
                                        const start = timestamp.clone().subtract(eachSide, 'ms');
                                        const end = timestamp.clone().add(eachSide, 'ms');
                                        $location.search('viewTime', event.timestamp);
                                        $location.search('startTimeUTC', start.valueOf());
                                        $location.search('endTimeUTC', end.valueOf());
                                        $location.replace();

                                        modal = showEvent(event);
                                        modal.result.finally(updateIncidentURLParamClose);

                                        userAnalytics.event(
                                            'incident-modal',
                                            'open-from-notifications'
                                        );
                                        signalviewMetrics.incr(
                                            'ui.incidentModal.openedFromNotifications'
                                        );
                                    }
                                } else {
                                    currentIncidentId = null;
                                }
                            });
                    }
                }
            };

            routeParameterService.registerRouteWatch('eventId', onEventIdUpdate);
            routeParameterService.registerRouteWatch('incidentId', onIncidentIdUpdate);

            $rootScope.$on('React:$routeChangeSuccess', onEventIdUpdate);
            $rootScope.$on('React:$routeChangeSuccess', onIncidentIdUpdate);
        },
    ]);
