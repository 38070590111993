export default [
    '$q',
    '$log',
    function ($q, $log) {
        const retryQueue = [];
        const service = {
            // The security service puts its own handler in here!
            onItemAddedCallbacks: [],
            hasMore: function () {
                return retryQueue.length > 0;
            },
            lastSource: function () {
                if (service.hasMore()) {
                    return retryQueue[0].source;
                } else {
                    return '';
                }
            },
            lastPromise: function () {
                if (service.hasMore()) {
                    return retryQueue[0].promise;
                } else {
                    return null;
                }
            },
            rejectLast: function (err) {
                if (service.hasMore()) {
                    retryQueue[0].retryItem.cancel(err);
                }
            },
            push: function (retryItem, source, promise) {
                retryQueue.push({
                    retryItem: retryItem,
                    source: source,
                    promise: promise,
                });
                // Call all the onItemAdded callbacks
                angular.forEach(service.onItemAddedCallbacks, function (cb) {
                    try {
                        cb(retryItem);
                    } catch (e) {
                        $log.error(
                            'securityRetryQueue.push(retryItem): callback threw an error' + e
                        );
                    }
                });
            },
            isLastError: function (source) {
                return service.lastSource() === source && source !== '';
            },
            pushRetryFn: function (reason, source, retryFn) {
                if (service.isLastError(source)) {
                    return service.lastPromise();
                }
                // The reason parameter is optional
                if (arguments.length === 1) {
                    retryFn = reason;
                    reason = undefined;
                }

                // The deferred object that will be resolved or rejected by calling retry or cancel
                const deferred = $q.defer();
                const retryItem = {
                    reason: reason,
                    retry: function () {
                        // Wrap the result of the retryFn into a promise if it is not already
                        $q.when(retryFn()).then(
                            function (value) {
                                // If it was successful then resolve our deferred
                                deferred.resolve(value);
                            },
                            function (value) {
                                // Othewise reject it
                                deferred.reject(value);
                            }
                        );
                    },
                    cancel: function (err) {
                        // Give up on retrying and reject our deferred
                        deferred.reject(err);
                    },
                };
                service.push(retryItem, source, deferred.promise);
                return deferred.promise;
            },
            retryReason: function () {
                return service.hasMore() && retryQueue[0].retryItem.reason;
            },
            cancelAll: function () {
                while (service.hasMore()) {
                    retryQueue.shift().retryItem.cancel();
                }
            },
            retryAll: function () {
                while (service.hasMore()) {
                    retryQueue.shift().retryItem.retry();
                }
            },
        };
        return service;
    },
];
