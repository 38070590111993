import templateUrl from './saveAclDashboardModal.tpl.html';

const saveAclDashboardModalController = [
    '$scope',
    'themeService',
    'currentUser',
    'organizationService',
    '$q',
    function ($scope, themeService, currentUser, organizationService, $q) {
        const $ctrl = this;

        $ctrl.$onInit = function () {
            $scope.dashboard = $ctrl.resolve.params.dashboard;
            $scope.chartsList = $ctrl.resolve.params.chartsList || $ctrl.resolve.params.allCharts;
            $scope.defaultDashboardGroupId =
                $ctrl.resolve.params.defaultDashboardGroupId || $ctrl.resolve.params.currentGroupId;
            $scope.sourceFilters = $ctrl.resolve.params.sourceFilters;
            $scope.isMirror = $ctrl.resolve.params.isMirror;
            $scope.onSuccessCallback = $ctrl.resolve.params.onSuccess;

            $scope.userId = null;
            $scope.orgId = null;

            currentUser
                .getOrgMember()
                .then((user) => {
                    $scope.userId = user.id;
                    $scope.isAdmin = user.admin;
                })
                .finally(() => {
                    $scope.$applyAsync();
                });

            currentUser.orgId().then((orgId) => ($scope.orgId = orgId));

            $scope.themeKey = themeService.getColorScheme();
            $scope.userData = null;
            $q.all({
                user: currentUser.getOrgMember(),
                orgId: organizationService
                    .getOrgSettings()
                    .then((result) => result.sf_organizationID),
            })
                .then(({ user, orgId }) => {
                    $scope.userData = { userId: user.id, isAdmin: user.admin, orgId };
                })
                .finally(() => {
                    $scope.$applyAsync();
                });
        };

        $scope.handleDismiss = (reason) => {
            $ctrl.modalInstance.dismiss(reason);
        };

        $scope.handleClose = (result) => {
            $ctrl.modalInstance.close(result);
        };
    },
];

export default {
    templateUrl,
    bindings: {
        modalInstance: '<',
        resolve: '<',
    },
    controller: saveAclDashboardModalController,
};
