import {
    convertMSToString,
    convertStringToMS,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.chartbuilder').directive('timeStampAsString', [
    function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs) {
                const millis = scope.$eval(attrs.ngMdlTarget);
                if (millis) {
                    scope.$eval(attrs.ngModel + " = '" + convertMSToString(millis) + "'");
                }
                scope.$watch(attrs.ngModel, function (value) {
                    scope.$eval(attrs.ngMdlTarget + '=' + convertStringToMS(value));
                });
            },
        };
    },
]);
