import forgotPasswordTemplateUrl from './forgotpassword.tpl.html';

export default {
    templateUrl: forgotPasswordTemplateUrl,
    controller: [
        '$scope',
        'signalboost',
        function ($scope, signalboost) {
            $scope.email = '';
            $scope.submitMessage = 'Reset Password';
            $scope.state = '';

            function done() {
                $scope.state = 'done';
            }

            $scope.submit = function () {
                $scope.state = 'loading';
                return signalboost.user.requestPasswordReset($scope.email).then(done, done);
            };
        },
    ],
};
