import templateUrl from './usageDataChart.tpl.html';

angular
    .module('signalview.orgUsageChart')

    .component('usageDataChart', {
        bindings: {
            chartConfig: '<',
            primarySignalFlow: '<',
            averageSignalFlow: '<',
            averageSignalFlowPartial: '<',
            maximumsSignalFlow: '<',
            rangeObject: '<',
            low: '<',
            lowLabel: '<',
            high: '<',
            highLabel: '<',
            yAxisLabel: '<',
            timeRange: '<',
            showMaxUsage: '<',
            isHostBasedUsage: '<',
            onStreamComplete: '&',
            labelUnit: '<',
        },
        templateUrl,
        controller: [
            '$log',
            'signalStream',
            '$scope',
            'SUPPORT_EMAIL',
            'featureEnabled',
            function ($log, signalStream, $scope, SUPPORT_EMAIL, featureEnabled) {
                const ctrl = this;

                ctrl.STREAMS_COMPLETED = 3;

                ctrl.$onChanges = $onChanges;
                ctrl.$onInit = $onInit;
                ctrl.$onDestroy = $onDestroy;
                ctrl.updateCharts = updateCharts;

                $scope.supportSwitch = featureEnabled('supportSwitch');

                function startJob(signalFlowText, resolution, callbacks) {
                    const historyrange = ctrl.rangeObject.start - Date.now();
                    const jobParams = {
                        resolution,
                        historyrange,
                        signalFlowText,
                        metaDataUpdated() {},
                        streamStartCallback() {},
                        callback(data) {
                            const timestampMs = data.timestamp;
                            const value = data.value;

                            callbacks.dataCallback({
                                timestampMs,
                                data: [{ value }],
                            });
                        },
                        streamStopCallback: callbacks.streamCloseCallback,
                    };

                    if (ctrl.rangeObject.monthsAgo === 0 || !ctrl.showMaxUsage) {
                        jobParams.immediate = true;
                    } else {
                        jobParams.stopTime = ctrl.rangeObject.end - Date.now();
                    }

                    if (resolution !== null) {
                        jobParams.resolution = resolution;
                    } else {
                        jobParams.fallbackResolutionMs = 5 * 60 * 1000;
                    }

                    angular.extend(jobParams, callbacks);
                    return signalStream.stream(jobParams);
                }

                function runPrimaryRequest() {
                    const resolution =
                        (ctrl.chartConfig && ctrl.chartConfig.primaryResolution) || 60 * 60 * 1000;
                    return startJob(ctrl.primarySignalFlow, resolution, {
                        dataCallback({ timestampMs, data }) {
                            if (
                                timestampMs > ctrl.rangeObject.end ||
                                timestampMs < ctrl.rangeObject.start
                            ) {
                                return;
                            }
                            data.forEach(function (datapoint) {
                                ctrl.currentData = ctrl.currentData || [];
                                ctrl.currentData.push({
                                    timestampMs,
                                    value: Math.floor(datapoint.value),
                                });
                            });
                        },
                        streamCloseCallback() {
                            ctrl.graphReady++;
                            if (ctrl.onStreamComplete) {
                                ctrl.onStreamComplete();
                            }
                            $log.info('stream complete');
                            ctrl.currentRequest = null;
                            ctrl.currentMaximalRequest = null;
                            ctrl.currentAverageRequest = null;

                            // emitting the stream close event to make the loader false in hostbasedChart.js
                            $scope.$emit('onStreamCloseCallback');
                            $scope.$digest();
                        },
                        metaDataCallback(metadata) {
                            ctrl.metricToTSIDMap[metadata.properties.sf_metric] = metadata.tsid;
                            ctrl.tsidToMetricMap[metadata.tsid] = metadata.properties.sf_metric;
                        },
                    });
                }

                function runAverageRequest() {
                    const resolution =
                        (ctrl.chartConfig && ctrl.chartConfig.primaryResolution) || 60 * 60 * 1000;
                    const isCurrentMonth = ctrl.rangeObject.monthsAgo === 0;
                    const averageProgramText = isCurrentMonth
                        ? ctrl.averageSignalFlowPartial
                        : ctrl.averageSignalFlow;

                    return startJob(averageProgramText, resolution, {
                        dataCallback({ timestampMs, data }) {
                            if (
                                timestampMs >= ctrl.rangeObject.end ||
                                timestampMs < ctrl.rangeObject.start
                            ) {
                                return;
                            }
                            data.forEach(function (datapoint) {
                                ctrl.currentAverageData = [];
                                ctrl.currentAverageData.push({
                                    timestampMs: ctrl.rangeObject.start,
                                    value: Math.floor(datapoint.value),
                                });
                                ctrl.currentAverageData.push({
                                    timestampMs: ctrl.rangeObject.end,
                                    value: Math.floor(datapoint.value),
                                });
                            });
                        },
                        streamCloseCallback() {
                            ctrl.graphReady++;
                            if (ctrl.onStreamComplete) {
                                ctrl.onStreamComplete();
                            }
                            $log.info('avg stream complete');
                            ctrl.currentRequest = null;
                            ctrl.currentAverageRequest = null;
                            ctrl.currentMaximalRequest = null;
                            // emitting the stream close event to make the loader false in hostbasedChart.js
                            $scope.$emit('onStreamCloseCallback');
                            $scope.$digest();
                        },
                        metaDataCallback(metadata) {
                            ctrl.metricToTSIDMap[metadata.properties.sf_metric] = metadata.tsid;
                            ctrl.tsidToMetricMap[metadata.tsid] = metadata.properties.sf_metric;
                        },
                    });
                }

                function runMaximumsRequest() {
                    let currentHour = null;
                    let rollingMaximum = 0;
                    const datapoints = [];
                    const resolution =
                        (ctrl.chartConfig && ctrl.chartConfig.maximumsResolution) || 60 * 1000;

                    return startJob(ctrl.maximumsSignalFlow, resolution, {
                        dataCallback({ timestampMs, data }) {
                            if (
                                timestampMs > ctrl.rangeObject.end ||
                                timestampMs < ctrl.rangeObject.start
                            ) {
                                return;
                            }
                            if (ctrl.isHostBasedUsage) {
                                data.forEach(function (datapoint) {
                                    if (datapoint.value === null) {
                                        return;
                                    }
                                    ctrl.currentMaximalData = ctrl.currentMaximalData || [];
                                    ctrl.currentMaximalData.push({
                                        timestampMs,
                                        value: Math.floor(datapoint.value),
                                    });
                                });
                            } else {
                                //could probably make this faster
                                const timeStampHour = Math.floor(timestampMs / (60 * 60 * 1000));
                                data.forEach((datapoint) => {
                                    if (currentHour === null) {
                                        currentHour = timeStampHour;
                                        rollingMaximum = datapoint.value;
                                    }

                                    if (currentHour !== timeStampHour) {
                                        ctrl.currentMaximalData = ctrl.currentMaximalData || [];
                                        ctrl.currentMaximalData.push({
                                            timestampMs,
                                            value: Math.floor(rollingMaximum),
                                        });
                                        currentHour = timeStampHour;
                                        rollingMaximum = 0;
                                    } else if (rollingMaximum < datapoint.value) {
                                        rollingMaximum = datapoint.value;
                                    }

                                    // store minutely datapoints for p95
                                    if (datapoint.value) {
                                        datapoints.push(datapoint.value);
                                    }
                                });
                            }
                        },
                        streamCloseCallback() {
                            ctrl.graphReady++;
                            $log.info('stream complete');
                            ctrl.currentRequest = null;
                            ctrl.currentMaximalRequest = null;
                            ctrl.currentAverageRequest = null;

                            // emitting the stream close event to make the loader false in hostbasedChart.js
                            $scope.$emit('onStreamCloseCallback');

                            $scope.$digest();
                            if (ctrl.onStreamComplete) {
                                const percentiles = getPercentiles([95, 99], datapoints);
                                ctrl.onStreamComplete({
                                    percentiles: {
                                        95: percentiles[0],
                                        99: percentiles[1],
                                    },
                                });
                            }
                        },
                        metaDataCallback(metadata) {
                            ctrl.metricToTSIDMap[metadata.properties.sf_metric] = metadata.tsid;
                            ctrl.tsidToMetricMap[metadata.tsid] = metadata.properties.sf_metric;
                        },
                    });
                }

                function updateCharts() {
                    if (ctrl.currentRequest) {
                        stopJob(
                            ctrl.currentRequest,
                            'org usage and billing chart, means user switched billing period'
                        );
                        ctrl.currentRequest = null;
                    }
                    if (ctrl.currentMaximalRequest) {
                        stopJob(
                            ctrl.currentMaximalRequest,
                            'org usage and billing chart, maximums user switched billing period'
                        );
                        ctrl.currentMaximalRequest = null;
                    }
                    if (ctrl.currentAverageRequest) {
                        stopJob(
                            ctrl.currentAverageRequest,
                            'org usage and billing chart, maximums user switched billing period'
                        );
                        ctrl.currentAverageRequest = null;
                    }

                    if (!ctrl.rangeObject) {
                        return;
                    }

                    ctrl.graphReady = 0;
                    ctrl.currentData = [];
                    ctrl.currentAverageData = [];
                    ctrl.currentMaximalData = [];
                    ctrl.metricToTSIDMap = {};
                    ctrl.tsidToMetricMap = {};
                    ctrl.xaxisrange = [ctrl.rangeObject.start, ctrl.rangeObject.end];

                    ctrl.currentRequest = runPrimaryRequest();

                    if (ctrl.isHostBasedUsage) {
                        ctrl.currentAverageRequest = runAverageRequest();
                    } else {
                        // No average usage request to wait for
                        ctrl.graphReady++;
                    }

                    if (ctrl.showMaxUsage) {
                        ctrl.currentMaximalRequest = runMaximumsRequest();
                    } else {
                        // No maximums request to wait for
                        ctrl.graphReady++;
                    }
                }

                function stopJob(request, reason) {
                    request.stopStream(reason);
                }

                function $onChanges(changes) {
                    const rangeObjectChange = changes.rangeObject;
                    const hasRangeObjectChange =
                        rangeObjectChange && rangeObjectChange.currentValue;
                    const primarySignalFlowChange = changes.primarySignalFlow;
                    const maximumsSignalFlowChange = changes.maximumsSignalFlow;

                    if (
                        !ctrl.currentRequest &&
                        (hasRangeObjectChange ||
                            (primarySignalFlowChange && primarySignalFlowChange.currentValue) ||
                            (maximumsSignalFlowChange && maximumsSignalFlowChange.currentValue))
                    ) {
                        updateCharts();
                    }
                }

                function $onDestroy() {
                    if (ctrl.currentRequest) {
                        stopJob(ctrl.currentRequest, 'org usage and billing chart, navigated away');
                    }
                    if (ctrl.currentAverageRequest) {
                        stopJob(
                            ctrl.currentAverageRequest,
                            'org usage and billing chart, navigated away'
                        );
                    }
                    if (ctrl.currentMaximalRequest) {
                        stopJob(
                            ctrl.currentMaximalRequest,
                            'org usage and billing chart, maximums user navigated away'
                        );
                    }
                }

                function $onInit() {
                    $scope.SUPPORT_EMAIL = SUPPORT_EMAIL;
                    updateCharts();
                }

                /* nonscoped functions */

                // it is important not to use the data gathered by the primary requests for this,
                // as that data is hourly averages, and will be an inaccurate proxy for p95 of dpm
                // usage.
                function getPercentiles(percentiles, datapoints) {
                    if (!datapoints || !datapoints.length) {
                        return percentiles.map(() => {
                            return 0;
                        });
                    }

                    const range = datapoints.length - 1;
                    const sortedPoints = datapoints.sort();
                    return percentiles.map((p) => {
                        return sortedPoints[Math.ceil((range * p) / 100)];
                    });
                }
            },
        ],
    });
