/*
       Message text can be autogenerated in multiple places, and
       sometimes percent values in message text are rendered as floats.
       This is a quick hack at the choke point to truncate in these cases.
       e.g., replace `14.0000000000002%` with `14%`,
       This does not affect any values less than `truncateBelow` decimal places.
       NOTE: As this is a regexp, it will not round.
       PERCENTAGE_DECIMAL_TRUNCATION matches:
             $1               $2                     $3                 $4
        (any number)(period character literal)(decimal value)(percent character literal)
        */
export const truncateLongPercentageValues = (text = '', threshold = 12): string => {
    const PERCENTAGE_DECIMAL_TRUNCATION = new RegExp(
        `([0-9]+)([.])([0-9]{${threshold},})([%])`,
        'g'
    );
    return text.replace(PERCENTAGE_DECIMAL_TRUNCATION, '$1%');
};
