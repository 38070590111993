angular.module('sfx.api.v2').service('slackChannelService', [
    'integrationCredentialCacheFactory',
    function (integrationCredentialCacheFactory) {
        const cacheIdentifier = 'slack channel';
        const endpointGenerator = (id) => `/_/slack/${id}/channel-search`;

        const slackChannelServiceAPI = integrationCredentialCacheFactory(
            cacheIdentifier,
            endpointGenerator
        );
        slackChannelServiceAPI.searchSlackChannels = slackChannelServiceAPI.search;

        return slackChannelServiceAPI;
    },
]);
