import { Monitoring } from '../../../../app/routing/Sitemaps';

export const AlertNavController = [
    '$scope',
    'detectorWizardModal',
    'currentUser',
    function ($scope, detectorWizardModal, currentUser) {
        $scope.detectorWizardModal = detectorWizardModal;
        $scope.currentUser = currentUser;

        this.$onInit = function () {
            this.setHierarchicalNav(Monitoring.name, Monitoring.IDs.alerts);
            $scope.setHierarchicalNav = this.setHierarchicalNav;
        };

        this.$onDestroy = function () {
            this.setHierarchicalNav();
        };
    },
];
