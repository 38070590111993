export default [
    '_',
    '$log',
    'clusterMapUtil',
    function (_, $log, clusterMapUtil) {
        const rootId = 'GLOBAL';
        const depthAllowedWithFreeFormParentLayout = 2;

        return function (dataConfig) {
            let stateRoot;
            const resourcesById = new Map();
            resetState();

            return {
                getState,
                updateHierarchy,
                updateData,
                removeDeadResources,
                resetState,
                findChildMatchingAnalyzerResult,
            };

            function resetState() {
                resourcesById.clear();
                stateRoot = {
                    id: rootId,
                    groupId: rootId,
                    children: [],
                    depth: 0,
                };
            }

            function getState() {
                return stateRoot;
            }

            function removeChildResource(resource, child) {
                if (!resource || !resource.children) {
                    return;
                }

                let index = resource.children.indexOf(child);
                if (index >= 0) {
                    resource.children.splice(index, 1);
                }

                index = resource.hiddenChildren?.indexOf(child) || -1;
                if (index >= 0) {
                    resource.hiddenChildren.splice(index, 1);
                }
            }

            function safeRemoveResource(resource) {
                const depthsRemoved = [];

                // We are at the top level and that should never be deleted
                // Or the function was called without a resource, so just return nothing
                if (!resource || resource === stateRoot) {
                    return [];
                }

                // Resource has children or is still reporting itself so it is NOT SAFE TO REMOVE any more resources in the tree
                if (
                    (resource.children && resource.children.length > 0) ||
                    (resource.hiddenChildren && resource.hiddenChildren.length > 0) ||
                    resource.isReporting
                ) {
                    return [];
                }

                if (resourcesById.has(resource.id)) {
                    depthsRemoved.push(resource.depth);
                    resourcesById.delete(resource.id);
                }

                // This shouldn't happen, but this is a guard in case it is missing a parent for an unexpected reason
                if (!resource.parent) {
                    return depthsRemoved;
                }

                removeChildResource(resource.parent, resource);

                // recursively safely remove the parent resources
                return [...depthsRemoved, ...safeRemoveResource(resource.parent)];
            }

            function removeDeadResources(updates) {
                let sizeCalculationRequired = false;
                let forceLayoutUpdate = false;

                // Mark every resource in the set as not reporting (this makes the removal logic much simpler)
                for (const resourceId of updates) {
                    if (resourcesById.has(resourceId)) {
                        resourcesById.get(resourceId).isReporting = false;
                    }
                }

                for (const resourceId of updates) {
                    if (resourcesById.has(resourceId)) {
                        const depthsRemoved = safeRemoveResource(resourcesById.get(resourceId));

                        sizeCalculationRequired =
                            sizeCalculationRequired || depthsRemoved.length > 0;

                        if (depthsRemoved.includes(depthAllowedWithFreeFormParentLayout)) {
                            forceLayoutUpdate = true;
                        }
                    }
                }

                if (sizeCalculationRequired) {
                    const currentLargestSize = stateRoot.largestSizes;
                    clusterMapUtil.calculateLargestSizes(stateRoot);
                    stateRoot.layoutCalculationRequired =
                        stateRoot.layoutCalculationRequired ||
                        forceLayoutUpdate ||
                        !_.isEqual(currentLargestSize, stateRoot.largestSizes);
                }

                return stateRoot;
            }

            // Receives Stream
            function updateData(updates) {
                for (const update of updates) {
                    const target = resourcesById.get(update.key);

                    if (!target) {
                        $log.warn('Missing data for', update.resourceType, ':', update.key);
                        continue;
                    }

                    target.isReporting = true;

                    if (!update.isColoringJob) {
                        continue;
                    }

                    const metricConfig = dataConfig.get(update.resourceType).getColorByMetric();
                    clusterMapUtil.mergeDataValue(target, update, metricConfig);
                }

                return stateRoot;
            }

            /**
             * Add or update data in the hierarchy
             * @param updates
             * @returns {*}
             */
            function updateHierarchy(updates) {
                let sizeCalculationRequired = false;
                // We only maintain same size resource from Depth 2 and on-wards.
                // Any new item before that should force a layout update
                let forceLayoutUpdate = false;

                for (const update of updates) {
                    let resource;
                    // Get the depth of the starting metadata update
                    let depth = dataConfig.getResourceDepth(update.resourceType);

                    // And the id
                    let id = dataConfig.get(update.resourceType).getId(update.metadata);

                    do {
                        // If this isn't the starting resource, but another level up the tree
                        if (resource) {
                            resource = createOrUpdateResourceParent(id, depth, update, resource);
                        } else {
                            resource = createOrUpdateResource(id, depth, update);
                        }

                        if (resource) {
                            // If this is at the top level other than root then set its parent to the root
                            if (depth === 1) {
                                if (!resource.parent) {
                                    resource.parent = stateRoot;
                                    stateRoot.children.push(resource);
                                }
                                break;
                            }

                            depth -= 1;
                            // Get the parent resource type
                            const resourceTypeAtDepth = dataConfig.getResourceAtDepth(depth);
                            // Get the id of the parent resource
                            id = dataConfig.get(resourceTypeAtDepth).getId(update.metadata);
                        }
                    } while (resource && id);
                }

                if (sizeCalculationRequired) {
                    const currentLargestSize = stateRoot.largestSizes;
                    clusterMapUtil.calculateLargestSizes(stateRoot);
                    stateRoot.layoutCalculationRequired =
                        stateRoot.layoutCalculationRequired ||
                        forceLayoutUpdate ||
                        !_.isEqual(currentLargestSize, stateRoot.largestSizes);
                }

                return stateRoot;

                function createOrUpdateResource(id, depth, update) {
                    let resource;
                    // If this resource has been added before use that as the starting object
                    if (resourcesById.has(id)) {
                        resource = resourcesById.get(id);
                        resource.data = _.extend(resource.data, update.metadata);
                    } else {
                        // For a new resource
                        const groupKey = dataConfig.get(update.resourceType).getGroupByKey();
                        const sourceId = dataConfig.get(update.resourceType).getSourceId();
                        resource = {
                            id,
                            groupId: update.metadata[groupKey],
                            groupedUpon: groupKey,
                            depth: depth,
                            events: [],
                            data: _.extend({}, update.metadata),
                            sourceId: sourceId,
                        };

                        resourcesById.set(id, resource);
                        sizeCalculationRequired = true;
                        if (depth === depthAllowedWithFreeFormParentLayout) {
                            forceLayoutUpdate = true;
                        }
                    }

                    if (update.headerCandidateStream) {
                        resource.headerLabel = update.headerLabel;
                        resource.headerCandidateForDepth = update.headerCandidateForDepth;
                    }

                    resource.isReporting = true;
                    return resource;
                }

                function createOrUpdateResourceParent(id, depth, update, resource) {
                    let parent;

                    if (resource.parent) {
                        // If the resource already has a parent use that
                        parent = resource.parent;
                    } else if (resourcesById.has(id)) {
                        // Otherwise if it is in the Map of resources then use that to set the parent-child relationship
                        parent = resourcesById.get(id);
                        if (!parent.children) {
                            parent.children = [];
                        }
                        parent.children.push(resource);
                        resource.parent = parent;
                    } else {
                        // Otherwise the parent resource doesn't exist yet
                        const parentResourceType = dataConfig.getResourceAtDepth(depth);
                        const groupKey = dataConfig.get(parentResourceType).getGroupByKey();
                        const sourceId = dataConfig.get(parentResourceType).getSourceId();
                        const data = extractInfoForDepth(update.metadata, depth);

                        parent = {
                            id,
                            groupId: update.metadata[groupKey],
                            groupedUpon: groupKey,
                            depth: depth,
                            events: [],
                            data: data,
                            children: [resource],
                            sourceId: sourceId,
                        };

                        resourcesById.set(id, parent);
                        resource.parent = parent;
                        sizeCalculationRequired = true;
                        if (depth === depthAllowedWithFreeFormParentLayout) {
                            forceLayoutUpdate = true;
                        }
                    }

                    if (resource.headerCandidateForDepth === depth) {
                        if (!parent.headerChildren) {
                            parent.headerChildren = {};
                        }
                        parent.headerChildren[resource.id] = resource;
                    } else if (parent.headerChildren && !resource) {
                        delete parent.headerChildren[resource.id];
                    }

                    return parent;
                }
            }

            function extractInfoForDepth(data, depth) {
                const info = {};
                while (depth > 0) {
                    const parentResourceType = dataConfig.getResourceAtDepth(depth);
                    const groupKey = dataConfig.get(parentResourceType).getGroupByKey();
                    info[groupKey] = data[groupKey];
                    depth = depth - 1;
                }
                return info;
            }

            function findChildMatchingAnalyzerResult(cluster, targetKeyValuePair) {
                const state = getState();
                let matchingChild = null;
                if (state.children && state.children.length > 0) {
                    const children = state.children;
                    children.forEach((child) => {
                        if (child.groupId === cluster) {
                            matchingChild = findMatchingChildInCluster(child, targetKeyValuePair);
                        }
                    });
                }
                return matchingChild;
            }

            function findMatchingChildInCluster(cluster, target) {
                let matchingChild = null;
                const collection = [cluster];
                while (collection.length) {
                    const node = collection.shift();
                    if (
                        node &&
                        node.data &&
                        node.data[target.key] &&
                        node.data[target.key] === target.value
                    ) {
                        // once we have found a second result, we know the sidebar will be ambiguous so we can return
                        if (matchingChild !== null) {
                            return null;
                        }
                        matchingChild = node;
                    } else if (node.children) {
                        collection.unshift(...node.children);
                    }
                }
                return matchingChild;
            }
        };
    },
];
