import '../subscriptionCategories/module.js';
import '../../../../app/administrative/organization/generalSettings/module.js';

import { organizationInfo } from './organizationInfo.js';
import { ingestTokenOptions } from './ingestTokens/ingestTokenOptions';
import { AUTH_SCOPES } from './ingestTokens/ingestTokenConstants';
import orgCrossLinkDefinition from './orgCrossLinkDefinition';
import { organizationPage } from './organizationPage';
import CreateNamedTokenModal from './ingestTokens/createNamedTokenModal.js';
import RotateTokenModal from './ingestTokens/rotateTokenModal.js';
import { react2angular } from 'react2angular';

import 'angular-ui-sortable';

angular
    .module('signalview.organization', [
        'signalboost',
        'signalview.imageService',
        'sfx.ui',
        'signalview.userAnalytics',
        'sfx.data',
        'signalview.sfGlobalNav',
        'signalview.orgUsage',
        'signalview.orgIntegrations',
        'signalview.timezone',
        'signalview.generalOrganizationSettings',
        'lineviz',
        'ui.sortable',
        'sfx.api.v2',
        'signalview.subscriptionInfo',
        'signalview.appNotifications',
    ])
    .component('organizationPage', organizationPage)
    .component('organizationInfo', organizationInfo)
    .component('orgCrossLinkDefinition', orgCrossLinkDefinition)
    .component('ingestTokenOptions', ingestTokenOptions)
    .component('createNamedTokenModal', react2angular(CreateNamedTokenModal))
    .component('rotateTokenModal', react2angular(RotateTokenModal))
    .constant('AUTH_SCOPES', AUTH_SCOPES);
