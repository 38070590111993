import withinThresholdService from './withinThresholdService.js';

angular.module('dyGraphUtils', []).service('dyGraphUtils', [
    '$log',
    function ($log) {
        //plotter enhancements to properly render in the event that the plotter is NOT
        //the first series in the map.  leads to RANDOM rendering in mixed-render scenarios.
        const minimumColumnCount = 10;
        const minimumBarChartGap = 2;
        function tickerFunctionDeduplicate(ticks) {
            let lastSeen = null;
            const seenDifferent = ticks.some(function (tick) {
                if (lastSeen === null) {
                    lastSeen = tick.label;
                }
                return lastSeen !== tick.label;
            });
            if (!seenDifferent && ticks.length > 0) {
                ticks = ticks.splice(Math.floor(ticks.length / 2), 1);
                const tickLabelLength = ticks[0].label.length;
                // if all values are duplicates and the value being represented is less than
                // the possible values that can be represented given the text string size of the label,
                // then convert it into an expotential form with as many decimals as will fit
                // given the label provided originally, with a minimum character consumption
                // of 4, eg: 3e-8
                if (ticks[0].v < Math.pow(10, -1 * Math.max(tickLabelLength - 2, 2))) {
                    ticks[0].label = ticks[0].v.toExponential(Math.max(tickLabelLength - 4, 0));
                }
            }
            return ticks;
        }
        function tickerFunction(t, e, a, i, r, n) {
            //override default ticker behavior only if all existing ticks have the same
            //value, this prevents low variance and value data from showing a bunch of
            //0.00 lines.
            const ticks = Dygraph.numericLinearTicks(t, e, a, i, r, n);
            return tickerFunctionDeduplicate(ticks);
        }

        function getDygraphSeriesInformation(e, plotFn) {
            const srs = e.dygraph.attributes_.series_;
            let plotcount = 0;
            let firstplotterindex = Number.MAX_VALUE;
            let firstplotterTSID = null;
            const alltsids = [];

            //traverse all visible points(allSeriesPoints) and if they are intended to be
            //the current plot function, bump the count
            e.allSeriesPoints.forEach(function (itm) {
                if (!itm || itm.length < 1 || !itm[0].name) {
                    return;
                }
                const tsid = itm[0].name;
                const series = srs[tsid];
                if (series.options.plotter === plotFn) {
                    plotcount++;
                    alltsids.push(tsid);
                    if (series.idx < firstplotterindex) {
                        firstplotterTSID = tsid;
                        firstplotterindex = series.idx;
                    }
                }
            });
            //render if the lowest visible index using this plotter has a corresponding TSID  that equals this series's TSID
            //apparently, these plotters are supposed to run once per 'render' regardless of the number of actual series that use this plotter function
            //in order to support stacked scenarios better
            const renderThisSeries = firstplotterTSID === e.setName;
            return {
                renderThisSeries: renderThisSeries,
                plotCount: plotcount,
                tsidsWithPlotter: alltsids,
                firsdataSetIndex: firstplotterindex,
                seriesCount: plotcount,
            };
        }

        function lineplotter(e) {
            const g = e.dygraph;
            const setName = e.setName;
            let strokeWidth = g.getOption('strokeWidth')[g.setIndexByName_[setName] - 1];
            const borderWidth = g.getNumericOption('strokeBorderWidth', setName);
            const drawPointCallback =
                g.getOption('drawPointCallback', setName) || Dygraph.Circles.DEFAULT;
            const strokePattern = g.getOption('strokePattern', setName);
            const drawPoints = g.getBooleanOption('drawPoints', setName);
            const pointSize = g.getNumericOption('pointSize', setName);
            const threshholdFillDirection =
                g.getOption('thresholdingInfo')[g.setIndexByName_[setName] - 1];
            const isWithin = Math.abs(threshholdFillDirection) === 2;
            let otherWithinSetName = null;
            let s = g.getHighlightSeries();
            if (isWithin) {
                if (s) {
                    //only render from the perspective of the high within, which is positive 2.
                    s = s.filter(function (name) {
                        if (name === setName) {
                            return false;
                        }
                        return (
                            g.getOption('thresholdingInfo')[g.setIndexByName_[name] - 1] &&
                            g.getOption('thresholdingInfo')[g.setIndexByName_[name] - 1] !== 2
                        );
                    });
                    if (s.length === 1) {
                        otherWithinSetName = s[0];
                    } else {
                        console.warn('Failed to find other within set to plot to!');
                        return;
                    }
                }
            }
            const lineColor = e.color;
            const ctx = e.drawingContext;

            ctx.save();
            setClip(ctx, g);
            const isHighlighted =
                e.drawingContext.canvas.parentElement.firstElementChild !== e.drawingContext.canvas;

            if (isHighlighted) {
                strokeWidth = 2;
            }
            if (!threshholdFillDirection) {
                if (borderWidth && strokeWidth) {
                    DygraphCanvasRenderer._drawStyledLine(
                        e,
                        g.getOption('strokeBorderColor', setName),
                        strokeWidth + 2 * borderWidth,
                        strokePattern,
                        drawPoints,
                        drawPointCallback,
                        pointSize
                    );
                }

                DygraphCanvasRenderer._drawStyledLine(
                    e,
                    lineColor,
                    strokeWidth,
                    strokePattern,
                    drawPoints,
                    drawPointCallback,
                    pointSize
                );
            } else if (isHighlighted) {
                let earliestDate = Number.MAX_VALUE;
                let latestDate = 0;
                const currentSetIdx = g.setIndexByName_[setName];
                let withinSetIdx = null;
                if (otherWithinSetName) {
                    withinSetIdx = g.setIndexByName_[otherWithinSetName];
                }
                const area = e.plotArea;
                const axis = g.axisPropertiesForSeries(setName);
                let axisY = 1.0 + axis.minyval * axis.yscale;
                const fillAlpha = 0.2;
                const stackedGraph = false;
                const baseline = {};
                if (axisY < 0.0) {
                    axisY = 0.0;
                } else if (axisY > 1.0) {
                    axisY = 1.0;
                }
                axisY = threshholdFillDirection < 0 ? area.h : -1 * area.h * axisY - area.y;

                const points = e.points;
                const iterator = stackedGraph
                    ? null
                    : DygraphCanvasRenderer._getIteratorPredicate(
                          g.getBooleanOption('connectSeparatedPoints', setName)
                      );
                // null prevents the skipping of NaN values which we want to manually extrapolate as 0
                const iter = Dygraph.createIterator(points, 0, points.length, iterator);

                // setup graphics context
                let prevX = NaN;
                let newYs;
                // should be same color as the lines but only 15% opaque.
                const rgb = hexToRgb(lineColor);
                const errorColor =
                    'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + fillAlpha + ')';
                ctx.fillStyle = errorColor;
                ctx.beginPath();

                const thresholdPaths = [];
                let currentThresholdPath;

                while (iter.hasNext) {
                    const point = iter.next();

                    if (point.xval < earliestDate) {
                        earliestDate = point.xval;
                    }

                    if (point.xval > latestDate) {
                        latestDate = point.xval;
                    }
                    if (!Dygraph.isOK(point.y)) {
                        // if NaN, establish coordinates from known stacking baseline if known or bottom of graph
                        // and increment the x position
                        const bl = baseline[point.canvasx] || axisY;
                        newYs = [bl, bl];
                    } else {
                        newYs = [point.canvasy, axisY];
                    }

                    if (isNaN(prevX)) {
                        ctx.moveTo(point.canvasx, newYs[0]);
                        prevX = point.xval;
                    } else {
                        ctx.lineTo(point.canvasx, newYs[0]);
                    }

                    prevX = point.canvasx;

                    if (withinSetIdx) {
                        const xIdx = withinThresholdService.findMatchingXIndex(point.canvasx, g);
                        if (!xIdx) {
                            continue;
                        }

                        let lowerY;
                        if (g.file_[xIdx][withinSetIdx] !== null) {
                            lowerY = g.toDomYCoord(g.file_[xIdx][withinSetIdx]);
                        } else {
                            lowerY = withinThresholdService.interpolateY(xIdx, withinSetIdx, g);
                        }

                        if (lowerY <= newYs[0]) {
                            if (!currentThresholdPath) {
                                // Now we know above threshold plot just became higher on the chart. Let's walk back to find the intersection that made this happen.
                                const intersect = withinThresholdService.findIntersect(
                                    point.canvasx,
                                    newYs[0],
                                    lowerY,
                                    currentSetIdx,
                                    withinSetIdx,
                                    g
                                );
                                if (intersect) {
                                    currentThresholdPath = {
                                        leftIntersect: intersect,
                                        leftIntersectIdx: xIdx,
                                    };
                                } else {
                                    // When upper threshold is higher from the very beginning,
                                    // use the point on the plot because the intersection cannot be found.
                                    currentThresholdPath = {
                                        leftIntersect: {
                                            x: point.canvasx,
                                            y: newYs[0],
                                        },
                                        // intersectIdx is to know which part of the plot data we want to look at when rendering this threshold path.
                                        // It is not a dealbreaker, but does save some time.
                                        leftIntersectIdx: xIdx,
                                    };
                                }
                            }
                        } else {
                            if (currentThresholdPath) {
                                // Now we know above threshold became lower so let's find the intersection to know when the paint zone ended.
                                const intersect = withinThresholdService.findIntersect(
                                    point.canvasx,
                                    newYs[0],
                                    lowerY,
                                    currentSetIdx,
                                    withinSetIdx,
                                    g
                                );
                                if (intersect) {
                                    currentThresholdPath.rightIntersect = intersect;
                                    currentThresholdPath.rightIntersectIdx = xIdx;
                                    thresholdPaths.push(currentThresholdPath);
                                }

                                currentThresholdPath = null;
                            }
                        }
                    }
                }

                ctx.strokeStyle = lineColor;
                const strokeRgb = hexToRgb(lineColor);
                ctx.lineWidth = strokeWidth;
                ctx.strokeStyle =
                    'rgba(' + strokeRgb.r + ',' + strokeRgb.g + ',' + strokeRgb.b + ',0.9)';
                ctx.stroke();

                if (!withinSetIdx) {
                    ctx.lineTo(g.toDomXCoord(latestDate), axisY);
                    ctx.lineTo(g.toDomXCoord(earliestDate), axisY);
                    ctx.fill();
                } else {
                    for (let x = g.file_.length - 1; x > 0; x--) {
                        const tsms = g.file_[x][0].getTime();
                        if (tsms <= latestDate && tsms >= earliestDate) {
                            ctx.lineTo(
                                g.toDomXCoord(tsms),
                                g.toDomYCoord(g.file_[x][withinSetIdx])
                            );
                        }
                    }

                    // This happens when the right end of chart still has a open threshold
                    // (which means above threshold remains higher without closing the paint zone)
                    if (currentThresholdPath) {
                        currentThresholdPath.rightIntersect = {
                            x: Infinity,
                        };
                        currentThresholdPath.rightIntersectIdx = g.file_.length - 1;
                        thresholdPaths.push(currentThresholdPath);
                    }
                }

                ctx.closePath();

                withinThresholdService.renderThresholds(
                    thresholdPaths,
                    currentSetIdx,
                    withinSetIdx,
                    g,
                    ctx
                );
            }
            ctx.restore();
        }

        lineplotter.multiPass = true;

        function isHighlightedTimeSeries(name, highlights) {
            return highlights && angular.isArray(highlights) && highlights.indexOf(name) !== -1;
        }

        function hexToRgb(hex) {
            if (hex.charAt(0) === '#') {
                hex = hex.substring(1);
            }
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                      r: parseInt(result[1], 16),
                      g: parseInt(result[2], 16),
                      b: parseInt(result[3], 16),
                  }
                : null;
        }

        function areaplotter(e) {
            const isHighlightSeries =
                e.singleSeriesName &&
                angular.isArray(e.singleSeriesName) &&
                e.singleSeriesName.length > 0;
            const seriesMetadata = getDygraphSeriesInformation(e, areaplotterref);

            if (!seriesMetadata.renderThisSeries && !isHighlightSeries) {
                return;
            }

            const g = e.dygraph;
            const setNames = g.getLabels().slice(1); // remove x-axis

            // getLabels() includes names for invisible series, which are not included in
            // allSeriesPoints. We remove those to make the two match.
            // TODO(danvk): provide a simpler way to get this information.
            for (let i = setNames.length; i >= 0; i--) {
                if (!g.visibility()[i]) {
                    setNames.splice(i, 1);
                }
            }

            const anySeriesFilled = (function () {
                for (let i = 0; i < setNames.length; i++) {
                    if (g.getBooleanOption('fillGraph', setNames[i])) {
                        return true;
                    }
                }
                return false;
            })();

            if (!anySeriesFilled) {
                return;
            }

            const ctx = e.drawingContext;
            const area = e.plotArea;
            const sets = e.allSeriesPoints;
            const setCount = sets.length;

            const fillAlpha = g.getNumericOption('fillAlpha');
            const stackedGraph = g.getBooleanOption('stackedGraph');

            const drawPointCallback = g.getOption('drawPointCallback') || Dygraph.Circles.DEFAULT;
            const drawPoints = g.getBooleanOption('drawPoints');
            const pointSize = g.getNumericOption('pointSize');

            const colors = g.getColors();

            // For stacked graphs, track the baseline for filling.
            //
            // The filled areas below graph lines are trapezoids with two
            // vertical edges. The top edge is the line segment being drawn, and
            // the baseline is the bottom edge. Each baseline corresponds to the
            // top line segment from the previous stacked line. In the case of
            // step plots, the trapezoids are rectangles.
            const baseline = {};
            let currBaseline;
            let prevStepPlot; // for different line drawing modes (line/step) per series

            ctx.save();
            setClip(ctx, g);
            // process sets in reverse order (needed for stacked graphs)
            for (let setIdx = setCount - 1; setIdx >= 0; setIdx--) {
                const setName = setNames[setIdx];
                if (!g.getBooleanOption('fillGraph', setName)) {
                    continue;
                }
                let yaxis = 0;
                if (e.dygraph.getOption('axis', setName) === 'y2') {
                    yaxis = 1;
                }

                const stepPlot = g.getBooleanOption('stepPlot', setName);
                const color = colors[setIdx];
                const axis = g.axisPropertiesForSeries(setName);
                let axisY = 1.0 + axis.minyval * axis.yscale;
                if (axisY < 0.0) {
                    axisY = 0.0;
                } else if (axisY > 1.0) {
                    axisY = 1.0;
                }
                axisY = area.h * axisY + area.y;

                const points = sets[setIdx];
                const iterator = stackedGraph
                    ? null
                    : DygraphCanvasRenderer._getIteratorPredicate(
                          g.getBooleanOption('connectSeparatedPoints', setName)
                      );
                // null prevents the skipping of NaN values which we want to manually extrapolate as 0
                const iter = Dygraph.createIterator(points, 0, points.length, iterator);

                // setup graphics context
                let prevX = NaN;
                let prevYs = [-1, -1];
                let newYs;
                // should be same color as the lines but only 15% opaque.
                const rgb = hexToRgb(color);
                const errorColor =
                    'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + fillAlpha + ')';
                ctx.fillStyle = errorColor;
                ctx.beginPath();
                let lastX;
                let isFirst = true;
                const lineCoordinates = [];
                while (iter.hasNext) {
                    const point = iter.next();
                    if (!Dygraph.isOK(point.y)) {
                        // if NaN, establish coordinates from known stacking baseline if known or bottom of graph
                        // and increment the x position
                        const bl = baseline[point.canvasx] || axisY;
                        newYs = [bl, bl];
                        lastX = point.xval;
                    } else if (stackedGraph) {
                        if (!isFirst && lastX === point.xval) {
                            continue;
                        } else {
                            isFirst = false;
                            lastX = point.xval;
                        }

                        currBaseline = baseline[point.canvasx];
                        let lastY;
                        if (currBaseline === undefined) {
                            lastY = axisY;
                        } else {
                            if (prevStepPlot) {
                                lastY = currBaseline[0];
                            } else {
                                lastY = currBaseline;
                            }
                        }
                        newYs = [point.canvasy, lastY];

                        if (stepPlot) {
                            // Step plots must keep track of the top and bottom of
                            // the baseline at each point.
                            if (prevYs[0] === -1) {
                                baseline[point.canvasx] = [point.canvasy, axisY];
                            } else {
                                baseline[point.canvasx] = [point.canvasy, prevYs[0]];
                            }
                        } else {
                            baseline[point.canvasx] = point.canvasy;
                        }
                    } else {
                        newYs = [point.canvasy, axisY];
                    }
                    if (!isNaN(prevX)) {
                        const startcoords = {
                            x: prevX,
                            y: prevYs[0],
                        };
                        const endcoords = {
                            x: null,
                            y: null,
                        };
                        ctx.moveTo(prevX, prevYs[0]);

                        // Move to top fill point
                        if (stepPlot) {
                            ctx.lineTo(point.canvasx, prevYs[0]);
                            endcoords.x = point.canvasx;
                            endcoords.y = prevYs[0];
                        } else {
                            ctx.lineTo(point.canvasx, newYs[0]);
                            endcoords.x = point.canvasx;
                            endcoords.y = newYs[0];
                        }
                        // Move to bottom fill point
                        if (prevStepPlot && currBaseline) {
                            // Draw to the bottom of the baseline
                            ctx.lineTo(point.canvasx, currBaseline[1]);
                        } else {
                            ctx.lineTo(point.canvasx, newYs[1]);
                        }

                        ctx.lineTo(prevX, prevYs[1]);
                        ctx.closePath();
                        lineCoordinates.push(startcoords);
                        if (!iter.hasNext) {
                            lineCoordinates.push(endcoords);
                        }
                    }
                    prevYs = newYs;
                    prevX = point.canvasx;
                }
                prevStepPlot = stepPlot;
                if (!isHighlightSeries || isHighlightedTimeSeries(setName, e.singleSeriesName)) {
                    ctx.fill();
                }

                //draw a line along the top edge of the area plot.
                ctx.closePath();

                if (yaxis === 1) {
                    //THIS IS PROVIDED BY DYGRAPH.  MAY SCREW UP DEPENDENCIES
                    //set line draw pattern to dashed for area charts on the second yaxis.
                    ctx.installPattern([10, 10]);
                }
                ctx.beginPath();
                for (let l = 0; l < lineCoordinates.length; l++) {
                    if (l === 0) {
                        ctx.moveTo(lineCoordinates[l].x, lineCoordinates[l].y);
                    } else {
                        ctx.lineTo(lineCoordinates[l].x, lineCoordinates[l].y);
                    }
                }
                ctx.strokeStyle = color;
                if (!isHighlightSeries || isHighlightedTimeSeries(setName, e.singleSeriesName)) {
                    ctx.stroke();
                }
                ctx.closePath();

                if (drawPoints) {
                    for (let d = 0; d < lineCoordinates.length; d++) {
                        drawPointCallback.call(
                            e.dygraph,
                            e.dygraph,
                            e.setName,
                            ctx,
                            lineCoordinates[d].x,
                            lineCoordinates[d].y,
                            color,
                            pointSize,
                            setIdx
                        );
                    }
                }

                if (yaxis === 1) {
                    //THIS IS PROVIDED BY DYGRAPH.  MAY SCREW UP DEPENDENCIES
                    ctx.uninstallPattern();
                }
            }
            ctx.restore();
        }

        areaplotter.singlePass = true;

        function barplotter(e) {
            // We need to handle all the series simultaneously.
            const isHighlightSeries =
                e.singleSeriesName &&
                angular.isArray(e.singleSeriesName) &&
                e.singleSeriesName.length > 0;

            const setNames = e.dygraph.getLabels().slice(1); // remove x-axis

            const seriesMetadata = getDygraphSeriesInformation(e, barplotterref);
            const stackedGraph = e.dygraph.getBooleanOption('stackedGraph');
            const alltsids = seriesMetadata.tsidsWithPlotter;
            const barCount = stackedGraph ? 1 : alltsids.length;

            if (!seriesMetadata.renderThisSeries && !isHighlightSeries) {
                return;
            }

            const seekMap = {};

            function seekPoint(expectedCanvasX, dataSetIndex) {
                let pointTarget;
                const dataset = sets[dataSetIndex];
                if (seekMap[dataSetIndex]) {
                    pointTarget = seekMap[dataSetIndex];
                } else {
                    pointTarget = 0;
                }

                //scan forward for the target
                const max = dataset.length;
                for (
                    let seekIdx = pointTarget;
                    seekIdx < max &&
                    (isNaN(dataset[seekIdx].canvasx) ||
                        dataset[seekIdx].canvasx <= expectedCanvasX);
                    seekIdx++
                ) {
                    if (dataset[seekIdx].canvasx === expectedCanvasX) {
                        seekMap[dataSetIndex] = seekIdx + 1;
                        return dataset[seekIdx];
                    }
                }
                return null;
            }

            const g = e.dygraph;
            const ctx = e.drawingContext;
            const sets = e.allSeriesPoints;
            ctx.save();
            setClip(ctx, g);
            const usedSets = [];
            for (let n = 0; n < sets.length; n++) {
                if (alltsids.indexOf(sets[n][0].name) === -1) {
                    usedSets.push(false);
                } else {
                    usedSets.push(true);
                }
            }

            const setName = e.setName;
            const yAxisIndex = e.dygraph.attributes_.series_[setName].yAxis;
            const yBottom = e.dygraph.toDomYCoord(0, yAxisIndex);

            // Find the minimum separation between x-values.
            // This determines the bar width.
            let minimumXSeparation = Infinity;

            const barColors = g.getColors();

            let longestSetLength = 0;
            let longestDataSetIndex = 0;

            for (let t = 0; t < sets.length; t++) {
                if (!usedSets[t]) {
                    continue;
                }

                const points = sets[t];
                if (points.length > longestSetLength) {
                    longestSetLength = points.length;
                    longestDataSetIndex = t;
                }

                if (e.dygraph && e.dygraph.sfx && e.dygraph.sfx.pointTickMs) {
                    minimumXSeparation =
                        e.dygraph.toDomXCoord(Date.now()) -
                        e.dygraph.toDomXCoord(Date.now() - e.dygraph.sfx.pointTickMs);
                } else {
                    for (let k = 1; k < points.length; k++) {
                        const sep = points[k].canvasx - points[k - 1].canvasx;
                        if (sep < minimumXSeparation) {
                            minimumXSeparation = sep;
                        }
                    }
                }

                minimumXSeparation = Math.min(
                    minimumXSeparation,
                    e.dygraph.width_ / minimumColumnCount
                );

                const totalBarWidth = minimumXSeparation - minimumBarChartGap;
                const barWidth = Math.max(Math.floor(totalBarWidth / barCount), 1);

                //var canvasxPositions = [];
                //anything that passes the filter for setnames using bar plotter increments this value
                //so we can track the offsets necessary to render individual TSID data in sequence

                let renderableSeries = false;

                if (!stackedGraph) {
                    let barsFound = 0;
                    for (let j = 0; j < sets.length; j++) {
                        if (alltsids.indexOf(sets[j][0].name) !== -1) {
                            ctx.fillStyle = barColors[j];
                            for (let i = 0; i < sets[j].length; i++) {
                                if (
                                    !isHighlightSeries ||
                                    isHighlightedTimeSeries(setNames[j], e.singleSeriesName)
                                ) {
                                    const p = sets[j][i];
                                    const centerXPosition = p.canvasx;
                                    const leftXPosition = Math.floor(
                                        centerXPosition - totalBarWidth / 2 + barsFound * barWidth
                                    );

                                    // TODO : maybe we shouldn't draw all the way to the bottom?
                                    ctx.fillRect(
                                        leftXPosition,
                                        Math.floor(p.canvasy),
                                        Math.ceil(barWidth),
                                        Math.floor(yBottom - p.canvasy)
                                    );
                                }
                            }
                            barsFound++;
                        }
                    }
                } else {
                    const len = longestSetLength;
                    for (let timeIndex = 0; timeIndex < len; timeIndex++) {
                        // iterate on number of timeslices
                        const gradients = [];
                        let cxp = 0;
                        let lastPos = yBottom;
                        let maxYPx = 0;
                        let minYPx = 999999999;
                        // at times, the data arrays are misaligned, seek forward with the "seekPoint" function
                        // which keeps a hash of the last matched index for a particular series of datapoints
                        const expectedCanvasX = sets[longestDataSetIndex][timeIndex].canvasx;
                        for (
                            let dataSetIndex = sets.length - 1;
                            dataSetIndex > -1;
                            dataSetIndex--
                        ) {
                            //then across each point in the slice

                            const point = seekPoint(expectedCanvasX, dataSetIndex, timeIndex);
                            renderableSeries =
                                !isHighlightSeries ||
                                isHighlightedTimeSeries(setNames[dataSetIndex], e.singleSeriesName);
                            if (
                                point &&
                                alltsids.indexOf(point.name) !== -1 &&
                                !isNaN(point.canvasy)
                            ) {
                                cxp = cxp || point.canvasx;
                                if (renderableSeries) {
                                    gradients.push({
                                        pos: point.canvasy,
                                        color: barColors[dataSetIndex],
                                        prevPos: lastPos,
                                    });
                                }
                                lastPos = point.canvasy;
                                if (point.canvasy > maxYPx) {
                                    maxYPx = point.canvasy;
                                }
                                if (point.canvasy < minYPx) {
                                    minYPx = point.canvasy;
                                }
                            }
                        }
                        const lxp = Math.floor(cxp - totalBarWidth / 2 + 0 * barWidth);
                        const delt = minYPx;
                        const grad = ctx.createLinearGradient(0, 0, 0, yBottom);
                        gradients.reverse();
                        if (gradients.length === 1) {
                            grad.addColorStop(0, 'transparent');
                        }
                        for (let gradIdx = 0; gradIdx < gradients.length; gradIdx++) {
                            const c = gradients[gradIdx];
                            let stop1 = 0;
                            let stop2 = 0;
                            if (c.pos !== 0 || isHighlightSeries) {
                                stop1 = Math.max(0, Math.min(1, c.pos / yBottom));
                            }
                            if (c.prevPos !== 0 || isHighlightSeries) {
                                stop2 = Math.min(1, Math.max(0, c.prevPos / yBottom));
                            }
                            if (isHighlightSeries) {
                                grad.addColorStop(stop1, 'transparent');
                            }
                            grad.addColorStop(stop1, c.color);
                            grad.addColorStop(stop2, c.color);

                            if (isHighlightSeries) {
                                grad.addColorStop(stop2, 'transparent');
                            }
                        }

                        if (gradients.length === 1) {
                            grad.addColorStop(1, 'transparent');
                        }

                        ctx.fillStyle = grad;
                        ctx.fillRect(lxp, delt, Math.ceil(barWidth), yBottom - delt);
                        //draw the result
                    }
                    return;
                }
            }
            ctx.restore();
        }

        function bucketize(datasets, timestampIndex, minY, maxY, bucketCount, allTSIDs, stacked) {
            //bucketize all data pertaining to the tsids given, assuming a min/max range.
            //bucket count defaults to 20.
            const allValues = [];
            const counts = [];
            const bucketRanges = [];
            for (let fillarr = 0; fillarr < bucketCount; fillarr++) {
                counts.push(0);
            }
            datasets.forEach(function (tsiddata) {
                const timeSliceData = tsiddata[timestampIndex];
                if (timeSliceData && allTSIDs.indexOf(timeSliceData.name) !== -1) {
                    allValues.push(stacked ? timeSliceData.yval_stacked : timeSliceData.yval);
                }
            });
            const graphRange = maxY - minY;

            allValues.forEach(function (val) {
                if (!isNaN(val) && val !== null) {
                    counts[Math.floor(((val - minY) / graphRange) * bucketCount)]++;
                }
            });

            for (let y = 0; y < bucketCount; y++) {
                bucketRanges.push([
                    minY + (y / bucketCount) * graphRange,
                    minY + ((y + 1) / bucketCount) * graphRange,
                ]);
            }

            let minCount = Number.MAX_VALUE;
            let maxCount = Number.MIN_VALUE;
            counts.forEach(function (c) {
                if (c < minCount) {
                    minCount = c;
                }
                if (c > maxCount) {
                    maxCount = c;
                }
            });

            const colorPercents = counts.map(function (count) {
                return count / maxCount;
            });
            let canvasXPosition = 0;
            if (datasets[0] && datasets[0].length > 0 && datasets[0][timestampIndex]) {
                canvasXPosition = datasets[0][timestampIndex].canvasx || 0;
            }
            return {
                counts: counts,
                bucketRanges: bucketRanges,
                colorLevels: colorPercents,
                canvasx: canvasXPosition,
            };
        }

        barplotter.singlePass = true;

        function heatPlotter(e) {
            //fixme : we could get some perf improvement by properly rounding to a pixel
            // so we dont need to antialias.
            const seriesMetadata = getDygraphSeriesInformation(e, heatmapplotterref);
            if (!seriesMetadata.renderThisSeries || e.singleSeriesName) {
                return;
            }
            const stackedGraph = e.dygraph.getBooleanOption('stackedGraph');
            const bucketCount = e.dygraph.getOption('bucketCount') || 20;
            const allTSIDs = seriesMetadata.tsidsWithPlotter || [];
            const allTSIDMap = {};
            allTSIDs.forEach((tsid) => {
                allTSIDMap[tsid] = true;
            });
            const barCount = 1;
            const bucketGap = 1;
            //FIXME get the correct axis?  are we even going to allow dual axis heatmaps
            const heatRange = e.dygraph.yAxisRange(0);
            const minY = heatRange[0];
            const maxY = heatRange[1];

            const yBottom = e.dygraph.toDomYCoord(minY);
            const bucketProportion = 1 / bucketCount;
            const bucketHeight = Math.floor((yBottom - bucketCount * bucketGap) / bucketCount);

            const ctx = e.drawingContext;
            const sets = e.allSeriesPoints;
            const allBuckets = [];
            // Find the minimum separation between x-values.
            // This determines the bar width.
            const xAxisExtremes = e.dygraph.xAxisExtremes();
            let minimumXSeparation =
                e.dygraph.toDomXCoord(xAxisExtremes[1]) - e.dygraph.toDomXCoord(xAxisExtremes[0]);

            const allTimeStamps = {};

            let lastx = null;
            sets.forEach(function (set) {
                set.forEach(function (pt) {
                    if (lastx !== null) {
                        // TODO : this could be incorrect if there are no adjacent points in any timeseries
                        const sep = Math.abs(pt.canvasx - lastx);
                        if (sep < minimumXSeparation) {
                            minimumXSeparation = sep;
                        }
                    }
                    if (!isNaN(pt.xval)) {
                        if (!allTimeStamps[pt.xval]) {
                            allTimeStamps[pt.xval] = [];
                        }
                        if (allTSIDMap[pt.name]) {
                            allTimeStamps[pt.xval].push([pt]);
                        }
                    }
                    lastx = pt.canvasx;
                });
            });

            const orderedTimeStamps = Object.keys(allTimeStamps).sort();
            orderedTimeStamps.forEach(function (targetTs) {
                allBuckets.push(
                    bucketize(
                        allTimeStamps[targetTs],
                        0,
                        minY,
                        maxY,
                        bucketCount,
                        allTSIDs,
                        stackedGraph
                    )
                );
            });

            const barWidth = minimumXSeparation;
            const rgb = hexToRgb(e.color);
            allBuckets.forEach(function (bucket) {
                bucket.colorLevels.forEach(function (opacity, index) {
                    //apply a logarithmic scale to opacity changes
                    opacity = 1 - Math.log(2 - opacity) / Math.log(2);
                    ctx.fillStyle = `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
                    const centerXPosition = bucket.canvasx;
                    const yPos = (index + 1) * yBottom * bucketProportion;

                    const leftXPosition = centerXPosition - barWidth / 2;

                    ctx.fillRect(
                        leftXPosition,
                        yBottom - yPos,
                        barWidth / barCount,
                        Math.max(bucketHeight - 1, 1)
                    );
                });
            });
        }

        heatPlotter.singlePass = true;

        function isInRange(dyGraphInstance, ts) {
            let inRange = true;
            if (ts && dyGraphInstance) {
                const ranges = dyGraphInstance.xAxisRange();
                if (ts < ranges[0] || ts > ranges[1]) {
                    inRange = false;
                }
            }
            return inRange;
        }

        function findClosestValidNonZeroIndex(domX, dgInstance) {
            let minDistX = Infinity;

            let closestPoint = null;
            const sets = dgInstance.layout_.points;

            for (let i = 0; i < sets.length; i++) {
                const points = sets[i];
                const len = points.length;
                for (let j = 0; j < len; j++) {
                    const point = points[j];
                    if (!Dygraph.isValidPoint(point, true)) continue;

                    if (point.yval) {
                        // always ignore zero

                        const distx = Math.abs(point.canvasx - domX);
                        if (distx < minDistX) {
                            minDistX = distx;
                            closestPoint = point;
                        }
                    }
                }
            }
            return closestPoint;
        }

        function findMaxMinForDomRange(startX, endX, dgInstance) {
            let bottom = -Infinity;
            let top = Infinity;

            const sets = dgInstance.layout_.points;

            for (let i = 0; i < sets.length; i++) {
                const points = sets[i];
                const len = points.length;
                for (let j = 0; j < len; j++) {
                    const point = points[j];
                    if (!Dygraph.isValidPoint(point, true)) continue;

                    if (point.canvasx >= startX && point.canvasx <= endX) {
                        bottom = Math.max(bottom, point.canvasy);
                        top = Math.min(top, point.canvasy);
                    }
                }
            }
            if (bottom === -Infinity || top === Infinity) {
                // not found
                return null;
            }
            return { top: top, bottom: bottom };
        }

        function findClosestPointOnTimeSlice(
            domX,
            domY,
            dgInstance,
            ignoreZero,
            disallowedIndices
        ) {
            //TODO ?  pull request to dygraph...
            let minDistX = Infinity;
            let minDistY = Infinity;
            let closestPoint = null;
            const sets = dgInstance.layout_.points;

            for (let i = 0; i < sets.length; i++) {
                const points = sets[i];
                const len = points.length;
                for (let j = 0; j < len; j++) {
                    const point = points[j];
                    if (!Dygraph.isValidPoint(point, true)) continue;

                    // if we want to skip zeros due to stacked chart, and we have a point in mind already, then check the next point
                    if (
                        (ignoreZero && closestPoint && point.yval === 0) ||
                        (disallowedIndices && disallowedIndices[point.name])
                    ) {
                        continue;
                    }
                    const distx = Math.abs(point.canvasx - domX);
                    const disty = Math.abs(point.canvasy - domY);

                    if (distx < minDistX || (distx === minDistX && disty < minDistY)) {
                        minDistX = distx;
                        minDistY = disty;
                        closestPoint = point;
                    }
                }
            }
            if (closestPoint === null) {
                return null;
            }

            const name = closestPoint.name;
            return {
                row: closestPoint.idx,
                seriesName: name,
                point: closestPoint,
                distance: minDistX + minDistY,
            };
        }

        const barplotterref = barplotter;
        const areaplotterref = areaplotter;
        const heatmapplotterref = heatPlotter;

        function setInstanceProperty(dyGraphInstance, property, value) {
            if (dyGraphInstance) {
                if (!dyGraphInstance.sfx) {
                    dyGraphInstance.sfx = {};
                }
                dyGraphInstance.sfx[property] = value;
            }
        }

        function setClip(ctx, dygraph, allowEvents) {
            //sets a clipping area to draw something "within the plottable area" which is essentially anything but the axes
            const startx = dygraph.toDomXCoord(dygraph.xAxisRange()[0]);
            const endx = dygraph.toDomXCoord(dygraph.xAxisRange()[1]);

            let yAxisRanges = [];
            try {
                yAxisRanges = dygraph.yAxisRanges();
            } catch (e) {
                $log.warn('Dygraph had no yAxes to query against!');
            }
            if (yAxisRanges.length === 0) {
                $log.warn('Unable to find yAxis extents to prevent draws.  Aborting.');
                return;
            }
            const starty = dygraph.toDomYCoord(yAxisRanges[0][0]) + (allowEvents ? 50 : 0);
            const endy = dygraph.toDomYCoord(yAxisRanges[0][1]);
            ctx.beginPath();
            ctx.moveTo(startx, starty);
            ctx.lineTo(startx, endy);
            ctx.lineTo(endx, endy);
            ctx.lineTo(endx, starty);
            ctx.clip();
        }

        return {
            plotters: {
                bar: barplotter,
                area: areaplotter,
                heatmap: heatPlotter,
                line: lineplotter,
            },
            bucketize: bucketize,
            inRange: isInRange,
            tickerFunction: tickerFunction,
            tickerFunctionDeduplicate: tickerFunctionDeduplicate,
            findClosestPointOnTimeSlice: findClosestPointOnTimeSlice,
            setInstanceProperty: setInstanceProperty,
            setClip: setClip,
            findClosestValidNonZeroIndex: findClosestValidNonZeroIndex,
            findMaxMinForDomRange: findMaxMinForDomRange,
        };
    },
]);
