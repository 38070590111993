/*
 * This is a small wrapper around the angular cookie service
 * it is used to ensure there is an expiry for the cookie
 * and that the cookie can only be read from secure connections
 *
 */

export default [
    '$cookies',
    'IS_PRODUCTION',
    function ($cookies, IS_PRODUCTION) {
        // options allows passing additional cookie options
        // sets secure in production environments
        function set(key, value, options) {
            const cookieOptions = {};
            if (options) {
                Object.assign(cookieOptions, options);
            }

            if (IS_PRODUCTION) {
                cookieOptions.secure = true;
            }

            $cookies.put(key, value, cookieOptions);
        }

        function clear(key) {
            $cookies.remove(key);
        }

        function get(key) {
            return $cookies.get(key);
        }

        function setIfEmpty(key, value, options) {
            const currentValue = get(key);
            if (currentValue === null || currentValue === undefined) {
                set(key, value, options);
            }
        }

        function msFromNowToExpireDate(msExpiry) {
            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + msExpiry);
            return expiryDate;
        }

        return {
            set,
            get,
            clear,
            setIfEmpty,
            msFromNowToExpireDate,
        };
    },
];
