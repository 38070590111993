import templateUrl from './filterIcons.tpl.html';

export default {
    templateUrl,
    bindings: {
        filter: '<',
        addFilter: '<',
        gaPrefix: '@',
    },
    controller: [
        function () {
            const $ctrl = this;
            $ctrl.excludeFilter = excludeFilter;

            function excludeFilter() {
                const notFilter = '!' + $ctrl.filter;
                $ctrl.addFilter(notFilter);
            }
        },
    ],
};
