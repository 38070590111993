import templateUrl from './pageDashboardSelector.tpl.html';

angular
    .module('signalview.page')

    .directive('pageDashboardSelector', [
        '$log',
        '$timeout',
        '$window',
        'dashboardGroupCache',
        'variableTransferService',
        'favoriteService',
        'pageTypeService',
        'CHART_DISPLAY_EVENTS',
        'dashboardGroupService',
        'dashboardUtil',
        'featureEnabled',
        'currentUser',
        'userAnalytics',
        'IS_MOBILE',
        function (
            $log,
            $timeout,
            $window,
            dashboardGroupCache,
            variableTransferService,
            favoriteService,
            pageTypeService,
            CHART_DISPLAY_EVENTS,
            dashboardGroupService,
            dashboardUtil,
            featureEnabled,
            currentUser,
            userAnalytics,
            IS_MOBILE
        ) {
            return {
                restrict: 'AE',
                replace: true,
                templateUrl,
                scope: {
                    model: '=',
                    dashboardGroup: '=',
                    filterState: '=',
                    configId: '=?',
                    orgOverviewContext: '=?',
                    hierarchyPermissions: '<',
                },
                link: function ($scope, element) {
                    let tabSelectorDestroyed = false;
                    $scope.activeDashboard = $scope.configId || $scope.model?.id;
                    if ($scope.dashboardGroup) {
                        $scope.dashboardGroup = dashboardGroupService.convertToV2(
                            $scope.dashboardGroup
                        );
                    }

                    $scope.allowReordering = !IS_MOBILE;
                    Object.defineProperty($scope, 'hasGroupWritePermission', {
                        get: () => !!$scope.hierarchyPermissions?.hasGroupWritePermission,
                    });

                    const pageType = pageTypeService.getTypeFromDashboard($scope.model);

                    const readOnlyEnabled = featureEnabled('readOnly');

                    function getDashboardDisplayTitle(config, dash) {
                        return config?.nameOverride || dash?.name;
                    }

                    function measureTabs() {
                        $timeout(function () {
                            const tabs = element.find('.sf-item-has-favorite.sf-offscreen');

                            if ($scope.dashboardList && $scope.dashboardList.length) {
                                // If no tabs were found, but there is a dashboardList, then very likely the tabs
                                //  were not yet rendered in the DOM so make this call again after a short delay to get the tab widths
                                if (tabs.length) {
                                    tabs.each(function (idx, el) {
                                        $scope.dashboardList[idx].width = angular
                                            .element(el)
                                            .outerWidth(true);
                                    });

                                    adjustTabsToSpace();
                                } else {
                                    $timeout(measureTabs, 100);
                                }
                            }
                        }, 0);
                    }

                    function cleanPendingFetch() {
                        $scope.pendingDashboardFetch = null;
                    }

                    function createTab(dash, config) {
                        return {
                            id: dash.id,
                            configId: config?.configId,
                            name: dash.name,
                            description: config?.descriptionOverride || dash.description,
                            sf_type: 'Dashboard',
                            locked: readOnlyEnabled && dash.locked,
                            filterAlias: dash.filters ? dash.filters.variables : null,
                            filters: dash.filters || {},
                            displayName: getDashboardDisplayTitle(config, dash),
                            favoriteKey: pageType.getFavoriteKey(
                                dash,
                                $scope.dashboardGroup,
                                config?.configId
                            ),
                        };
                    }

                    function findConfig(configId) {
                        return $scope.dashboardGroup?.dashboardConfigs?.find((config) => {
                            return config.configId === configId;
                        });
                    }

                    function buildSelectorTabsForSingleDashboard() {
                        $scope.dashboardList = generateDashboardTabList([$scope.model]);
                        currentUser.orgId().then((orgId) => {
                            setDashboardTabHrefs($scope.dashboardList, orgId);
                            $scope.orgId = orgId;
                        });
                    }

                    function buildSelectorTabsForGroup() {
                        if (!$scope.pendingDashboardFetch) {
                            $scope.pendingDashboardFetch = dashboardGroupService
                                .getDashboards($scope.dashboardGroup.id)
                                .then(function (results) {
                                    if (tabSelectorDestroyed) {
                                        return;
                                    }

                                    // always repopulate with new data since it could have different config/order.
                                    const cachedTabWidths = getDashboardNameToTabWidthMap(
                                        $scope.dashboardList
                                    );

                                    $scope.dashboardList = generateDashboardTabList(
                                        results.dashboards,
                                        $scope.dashboardGroup.dashboardConfigs
                                    );

                                    let requireTabMeasure = true;

                                    if (cachedTabWidths) {
                                        requireTabMeasure = false;
                                        for (const dash of $scope.dashboardList) {
                                            const cachedWidth = cachedTabWidths[dash.displayName];
                                            if (cachedWidth) {
                                                dash.width = cachedWidth;
                                            } else {
                                                requireTabMeasure = true;
                                                break;
                                            }
                                        }
                                    }

                                    if (requireTabMeasure) {
                                        measureTabs();
                                    } else {
                                        adjustTabsToSpace();
                                    }

                                    dashboardGroupCache.setMostRecent(
                                        $scope.dashboardGroup.id,
                                        $scope.dashboardList
                                    );
                                    updateVariableCacheAndHrefs();

                                    return currentUser.orgId();
                                }, cleanPendingFetch)
                                .then(function (orgId) {
                                    setDashboardTabHrefs($scope.dashboardList, orgId);
                                    $scope.orgId = orgId;
                                });
                        }
                    }

                    $scope.buildSelectorTabs = function () {
                        if (!$scope.dashboardGroup) {
                            buildSelectorTabsForSingleDashboard();
                        } else {
                            buildSelectorTabsForGroup();
                        }
                    };

                    function getDashboardNameToTabWidthMap(dashboardList) {
                        if (!dashboardList || !dashboardList.length || !dashboardList[0].width) {
                            return null;
                        }

                        return dashboardList.reduce((map, dash) => {
                            map[dash.displayName] = dash.width;
                            return map;
                        }, {});
                    }

                    function generateDashboardTabList(dashboards, configs) {
                        let dashboardList;

                        if (configs && configs.length) {
                            dashboardList = [];
                            const dashboardsById = dashboards.reduce((map, dash) => {
                                map[dash.id] = dash;
                                return map;
                            }, {});

                            // The case in which the group has the new format of dashboard/group relationships
                            configs.forEach(function (config) {
                                const currentDashboard = dashboardsById[config.dashboardId];
                                if (!currentDashboard) {
                                    $log.warn(
                                        `Dashboard ${config.dashboardId} has a config but was not found`
                                    );
                                    return;
                                }
                                dashboardList.push(createTab(currentDashboard, config));
                            });
                        } else {
                            // The legacy path for groups with no configs
                            dashboardList = dashboards.map((currentDashboard) =>
                                createTab(currentDashboard)
                            );
                        }

                        removeInvalidTabs();
                        return dashboardList;
                    }

                    function updateAllTabsOfDashboard(dashboard, updatedActiveConfig) {
                        const dashboardListCopy = angular.copy($scope.dashboardList);
                        dashboardListCopy.forEach((dashTab, index) => {
                            if (dashTab.id === dashboard.id) {
                                let updatedTab;
                                if (updatedActiveConfig.configId === dashTab.configId) {
                                    updatedTab = createTab(dashboard, updatedActiveConfig);
                                } else {
                                    const config = dashboardUtil.getConfig(
                                        $scope.dashboardGroup?.dashboardConfigs,
                                        dashTab.configId
                                    );
                                    updatedTab = createTab(dashboard, config);
                                }

                                $scope.dashboardList[index] = updatedTab;
                            }
                        });
                    }

                    function removeInvalidTabs() {
                        if (!$scope.dashboardList) {
                            return;
                        }
                        $scope.dashboardList = $scope.dashboardList.filter((dashTab) => !!dashTab);
                    }

                    function setDashboardTabHrefs(dashboardList, orgId) {
                        $scope.dashboardTabHrefs = {};
                        _.forEach(dashboardList, function (dashboard) {
                            $scope.dashboardTabHrefs[dashboard.configId || dashboard.id] =
                                getDashboardTabHref(dashboard.id, dashboard.configId, orgId);
                        });
                    }

                    function getDashboardTabHref(dashboardId, configId, orgId) {
                        let variableParams = '';
                        if ($scope.hrefs) {
                            variableParams = $scope.hrefs[configId || dashboardId];
                        }

                        if ($scope.orgOverviewContext) {
                            const params =
                                variableParams === ''
                                    ? `dashboard=${dashboardId}`
                                    : `${variableParams}&dashboard=${dashboardId}`;
                            return `#/organization/${orgId}?${params}`;
                        }

                        const dashboardParams = dashboardUtil.getDashboardSearchParamsString(
                            $scope.dashboardGroup?.id,
                            configId
                        );

                        const allParams =
                            variableParams === ''
                                ? `${dashboardParams}`
                                : `${variableParams}&${dashboardParams}`;

                        return `#/dashboard/${dashboardId}${
                            allParams === '' ? '' : `?${allParams}`
                        }`;
                    }

                    function updateVariableCacheAndHrefs() {
                        if ($scope.dashboardList && $scope.dashboardList.length) {
                            const baseVariables = $scope.model?.filters?.variables || [];
                            const currentConfig = findConfig($scope.activeDashboard);
                            const variableOverrides =
                                currentConfig?.filtersOverride?.variables || [];

                            const variables = dashboardUtil.mergeVariablesWithOverrides(
                                baseVariables,
                                variableOverrides
                            );
                            variableTransferService.storeVariables(
                                $scope.dashboardGroup?.id,
                                variables
                            );

                            $scope.hrefs = variableTransferService.updateHrefs(
                                $scope.dashboardGroup?.id,
                                $scope.dashboardList,
                                ($scope.filterState || {}).timePickerDirty,
                                ($scope.filterState || {}).pointDensityDirty
                            );

                            setDashboardTabHrefs($scope.dashboardList, $scope.orgId);
                        }
                    }

                    $timeout($scope.buildSelectorTabs, 0);

                    // The id here could be a dashboardId or a configId
                    function getDashboardFromList(id) {
                        return ($scope.dashboardList || []).find(function (d) {
                            return d.configId === id || d.id === id;
                        });
                    }

                    $scope.hideDashTab = {};

                    function adjustTabsToSpace() {
                        // no dashboardList, no tab adjustment
                        const widthEval = !($scope.dashboardList || []).some(function (d) {
                            return d.width;
                        });
                        if (!$scope.dashboardList || widthEval) {
                            return;
                        }

                        const activeDashboardObj = getDashboardFromList($scope.activeDashboard);
                        if (!activeDashboardObj) {
                            return;
                        }
                        let preallocatedWidth =
                            30 /* margins */ + 70 /* dropdown */ + 30; /* sidebar icon */

                        // Sometimes this element is hidden if the user has no group read permission
                        const dashboardNameWidth = angular
                            .element('.dashboard-selector-sibling')
                            .outerWidth(true); /* element next to this */
                        if (dashboardNameWidth) {
                            preallocatedWidth += dashboardNameWidth;
                        }

                        preallocatedWidth += activeDashboardObj.width;
                        if (readOnlyEnabled && activeDashboardObj.locked) {
                            preallocatedWidth += 76;
                        }

                        const parentWidth = element
                            .parents('.dashboard-selector-container')
                            .width();
                        const spaceAllotted = parentWidth - preallocatedWidth;
                        let totalTabWidth = 0;
                        const readOnlyPillWidth = 76;

                        $scope.dashboardList.forEach(function (dashTab) {
                            const dashTabKey = dashTab.configId || dashTab.id;
                            if (dashTabKey === $scope.activeDashboard) {
                                $scope.hideDashTab[dashTabKey] = false;
                                return;
                            }

                            // add dashboard length to total and hide if limit is passed
                            totalTabWidth += dashTab.width;
                            if (readOnlyEnabled && dashTab.locked) {
                                // we need to allot more space so we can display the read-only pill
                                totalTabWidth += readOnlyPillWidth;
                            }
                            if (spaceAllotted < totalTabWidth) {
                                $scope.hideDashTab[dashTabKey] = true;
                                totalTabWidth -= dashTab.width;
                                if (readOnlyEnabled && dashTab.locked) {
                                    totalTabWidth -= readOnlyPillWidth;
                                }
                            } else {
                                $scope.hideDashTab[dashTabKey] = false;
                            }
                        });
                    }

                    $scope.someDashboardsHidden = function () {
                        return $scope.dashboardList.some(function (dash) {
                            return $scope.hideDashTab[dash.configId || dash.id];
                        });
                    };

                    // during page load adjust tabs based on window width, afterwards stop
                    // watching window width and adjust by parent element width.
                    let initialLoadDone = false;
                    const unwatchWindowWidth = $scope.$watch(function () {
                        return $window.innerWidth;
                    }, adjustTabsToSpace);

                    function asyncAdjustTabsToSpace() {
                        $timeout(adjustTabsToSpace, 0);
                    }

                    const unwatchInitialElem = $scope.$watch(
                        function () {
                            return element.parents('.dashboard-selector-container').innerWidth();
                        },
                        function (parentWidth) {
                            if (Math.abs(parentWidth - $window.innerWidth) < 40) {
                                unwatchWindowWidth();
                                unwatchInitialElem();
                                initialLoadDone = true;
                                angular.element($window).bind('resize', asyncAdjustTabsToSpace);
                                $scope.$on(
                                    CHART_DISPLAY_EVENTS.CONTEXT_RESIZE,
                                    asyncAdjustTabsToSpace
                                );
                            }
                        }
                    );

                    $scope.lastVisible = function (index) {
                        if ($scope.dashboardList) {
                            for (let i = index + 1; i < $scope.dashboardList.length; i++) {
                                const currTab = $scope.dashboardList[i];
                                if (!$scope.hideDashTab[currTab.configId || currTab.id]) {
                                    return false;
                                }
                            }
                            return true;
                        }
                    };

                    $scope.isFavorite = favoriteService.isFavorite;
                    $scope.addFavorite = favoriteService.add;
                    $scope.removeFavorite = favoriteService.remove;

                    $scope.canViewDashboard = (dashboard) => {
                        return !!$scope.hierarchyPermissions?.canViewSibling(dashboard.id);
                    };

                    $scope.canShowDashboardInMenu = (dashboard) => {
                        if (
                            !$scope.hasGroupWritePermission &&
                            !$scope.canViewDashboard(dashboard)
                        ) {
                            return false;
                        }

                        if ($scope.hideDashTab[dashboard.configId || dashboard.id]) {
                            return false;
                        }

                        return true;
                    };

                    $scope.canShowDashboardInDropdown = (dashboard) => {
                        if (
                            !$scope.hasGroupWritePermission &&
                            !$scope.canViewDashboard(dashboard)
                        ) {
                            return false;
                        }

                        if (!$scope.hideDashTab[dashboard.configId || dashboard.id]) {
                            return false;
                        }

                        return true;
                    };

                    $scope.getDashboardHref = (dashboard) => {
                        if (!$scope.canViewDashboard(dashboard)) {
                            return null;
                        }

                        if ([dashboard.id, dashboard.configId].includes($scope.activeDashboard)) {
                            return null;
                        }

                        return $scope.dashboardTabHrefs?.[dashboard.configId || dashboard.id];
                    };

                    $scope.$watch('model.name', function (nval) {
                        if (nval && $scope.dashboardList) {
                            const activeDash = getDashboardFromList($scope.activeDashboard);
                            if (activeDash) {
                                activeDash.displayName = getDashboardDisplayTitle(
                                    findConfig($scope.configId),
                                    $scope.model
                                );
                            }
                            if (initialLoadDone) {
                                adjustTabsToSpace();
                            }
                        }
                    });

                    $scope.$watch('model.locked', function () {
                        if ($scope.dashboardList) {
                            const activeDash = getDashboardFromList($scope.activeDashboard);
                            if (activeDash) {
                                activeDash.locked = readOnlyEnabled && $scope.model.locked;
                            }
                            if (initialLoadDone) {
                                adjustTabsToSpace();
                            }
                        }
                    });

                    // Ensure that the cache contains up to date info about
                    // dashboard variables
                    $scope.$watchCollection('model.filters.variables', function () {
                        if ($scope.dashboardList) {
                            const activeDash = getDashboardFromList($scope.activeDashboard);
                            if (activeDash) {
                                activeDash.filters.variables = $scope.model.filters.variables;
                            }
                            dashboardGroupCache.setMostRecent(
                                $scope.dashboardGroup?.id,
                                $scope.dashboardList
                            );
                            updateVariableCacheAndHrefs();
                        }
                    });

                    // make sure that saved filters don't get out of sync
                    $scope.$watchCollection('filterState', function () {
                        if ($scope.dashboardList) {
                            const activeDash = getDashboardFromList($scope.activeDashboard);
                            if (activeDash) {
                                activeDash.filters = $scope.model.filters;
                            }
                            dashboardGroupCache.setMostRecent(
                                $scope.dashboardGroup?.id,
                                $scope.dashboardList
                            );
                            updateVariableCacheAndHrefs();
                        }
                    });

                    // Update hrefs when the route updates, since that probably means new
                    // filter values were selected
                    $scope.$on('React:$routeUpdate', function () {
                        dashboardGroupCache.setMostRecent(
                            $scope.dashboardGroup?.id,
                            $scope.dashboardList
                        );
                        updateVariableCacheAndHrefs();
                    });

                    // Move the active tab before the page load happens
                    const routeWithConfig = new RegExp(/dashboard\/.{11}\?.*configId=(.{11})/);
                    const routeWithoutConfig = new RegExp(/dashboard\/(.{11})/);
                    $scope.$on('$locationChangeStart', function (_event, newUrl, oldUrl) {
                        const newMatch =
                            newUrl.match(routeWithConfig) || newUrl.match(routeWithoutConfig);
                        const oldMatch =
                            oldUrl.match(routeWithConfig) || oldUrl.match(routeWithoutConfig);
                        // Ignore navigating to New Dashboard (/temp/dashboard/<id>)
                        if (
                            newMatch &&
                            oldMatch &&
                            newMatch[1] !== oldMatch[1] &&
                            newUrl.indexOf('/temp/') === -1
                        ) {
                            $scope.activeDashboard = newMatch[1];
                        }
                    });

                    $scope.$on('$destroy', function () {
                        tabSelectorDestroyed = true;
                        angular.element($window).unbind('resize', asyncAdjustTabsToSpace);
                    });

                    $scope.$on('removed config', (evt, configId) => {
                        const index = $scope.dashboardList.findIndex((config) => {
                            return config.configId === configId;
                        });

                        if (index >= 0) {
                            $scope.dashboardList.splice(index, 1);
                        }
                    });

                    $scope.$on('dashboard tab update', (evt, dashboard, config) => {
                        updateAllTabsOfDashboard(dashboard, config);
                        measureTabs();
                    });

                    $scope.$on('jqui-drag-stop', function () {
                        if ($scope.hierarchyPermissions?.hasGroupWritePermission) {
                            userAnalytics.event('dashboard-reorder', 'drag-stop');
                            dashboardGroupService
                                .updateDashboardOrdering(
                                    $scope.dashboardGroup,
                                    $scope.dashboardList
                                )
                                .then((dashboardGroup) => {
                                    $scope.dashboardGroup.dashboards = dashboardGroup.dashboards;

                                    if (dashboardGroup.dashboardConfigs) {
                                        $scope.dashboardGroup.dashboardConfigs =
                                            dashboardGroup.dashboardConfigs;
                                    }
                                });
                        } else {
                            userAnalytics.event('dashboard-reorder', 'drag-stop-no-permission');
                        }
                    });

                    if ($scope.dashboardGroup) {
                        const cachedList = dashboardGroupCache.getDashboardsIfCached(
                            $scope.dashboardGroup?.id
                        );
                        if (cachedList) {
                            $scope.dashboardList = cachedList;

                            // Creating a new dashboard and saving it to the dashboard you are already on
                            // means that the most recently created dashboard needs to be added to
                            // the cached list.
                            const activeDashListItem = getDashboardFromList($scope.activeDashboard);

                            if (!activeDashListItem && $scope.activeDashboard) {
                                // $scope.dashNames[$scope.model.sf_id] = getDashboardDisplayTitle($scope.model);
                                $scope.dashboardList.push({
                                    id: $scope.model.id,
                                    configId: $scope.configId,
                                    displayName: getDashboardDisplayTitle($scope.model),
                                    filters: $scope.model.filters || {},
                                });
                            }

                            if (!activeDashListItem || !activeDashListItem.width) {
                                measureTabs();
                            }
                        }
                    }
                },
            };
        },
    ]);
