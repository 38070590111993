'use strict';
import templateUrl from './highlight.html';

/**
 * Highlights a matching portions of a given text, text insensitive.
 *
 * ```
 * <highlight text="Hello world" part="hello">
 * ```
 * @param {string} text - The text to display
 * @param {string} part - The substrings within the text to highlight
 */
angular.module('sfx.ui').directive('highlight', [
    function () {
        return {
            restrict: 'E',
            scope: {
                text: '=',
                part: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    function updateParts() {
                        const text = $scope.text;
                        if (!text) $scope.fragments = [];
                        // splitting on empty string breaks text in to chars
                        if ($scope.part === '') $scope.part = null;

                        if ($scope.part) {
                            const normalizedFragments = text
                                .toLocaleLowerCase()
                                .split($scope.part.toLocaleLowerCase());
                            const fragments = [];
                            let index = 0;
                            normalizedFragments.forEach(function (normalizedFragment) {
                                const length = normalizedFragment.length;
                                let fragment = text.substr(index, length);
                                fragments.push(fragment);
                                index += length;

                                fragment = text.substr(index, $scope.part.length);
                                fragments.push(fragment);
                                index += $scope.part.length;
                            });

                            $scope.fragments = fragments;
                        } else {
                            $scope.fragments = [text];
                        }
                    }

                    $scope.$watchGroup(['text', 'part'], updateParts);
                },
            ],
        };
    },
]);
