import {
    accessControlStyleGuide,
    alertEventModalStyleGuide,
    detectorWizardTypePickerStyleGuide,
    serviceEndpointSelectorStyleGuide,
    styleguide,
    styleGuideController,
} from './styleguide';
import styleGuideItemTemplateUrl from './item.tpl.html';

angular
    .module('styleguide', ['metadataFunctionInputs', 'signalview.alertEventModalV2'])
    .controller('StyleGuideController', styleGuideController)
    .component('styleguide', styleguide)
    .component('alertEventModalStyleGuide', alertEventModalStyleGuide)
    .component('accessControlStyleGuide', accessControlStyleGuide)
    .component('detectorWizardTypePickerStyleGuide', detectorWizardTypePickerStyleGuide)
    .component('serviceEndpointSelectorStyleGuide', serviceEndpointSelectorStyleGuide)
    .directive('styleGuideItem', [
        function () {
            const classMatcher = /class=\"([a-zA-Z0-9\-_ ]+)\"/g;
            const ngClasses = / ?ng\-[a-zA-Z]+/g;
            const emptyAttributes = / ?class=\"\"|=\"\"/g;
            const ariaAttributes = / ?aria[a-zA-Z\-]+=\"[a-zA-Z0-9\-_]+\"/g;
            const commentedCodeStart = / *<!--PICK-AS-HTML-BLOCK\n/g;
            const commentedCodeEnd = /\n *PICK-AS-HTML-BLOCK-->/g;

            function classFixer(match, classes) {
                return 'class="' + classes.replace(ngClasses, '') + '"';
            }

            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    title: '@',
                    description: '@',
                },
                templateUrl: styleGuideItemTemplateUrl,
                link: function ($scope, element) {
                    let theCode = angular.element('.content', element)[0].innerHTML;
                    let rootTab = theCode.match(/\n( +)/);

                    theCode = theCode
                        .replace(classMatcher, classFixer)
                        .replace(ariaAttributes, '')
                        .replace(emptyAttributes, '');

                    theCode = theCode.replace(commentedCodeStart, '').replace(commentedCodeEnd, '');

                    if (rootTab) {
                        rootTab = new RegExp('^' + rootTab[1], 'gm');
                        theCode = theCode.replace(rootTab, '');
                    }

                    $scope.theCode = theCode.trim();
                },
            };
        },
    ]);
