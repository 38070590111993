import templateUrl from './detectorEventTable.tpl.html';

export const detectorEventTable = [
    'eventModal',
    'alertTypeService',
    'detectorPriorityService',
    function (eventModal, alertTypeService, detectorPriorityService) {
        return {
            scope: {
                detectorEvents: '=',
                pinTime: '=',
                createNewEvent: '=',
                hasEventsTab: '=',
            },
            restrict: 'E',
            templateUrl,
            link: function (scope) {
                scope.showEventDetails = eventModal;

                scope.getAlertClass = function (severity) {
                    return detectorPriorityService.getAlertClassBySeverity(severity);
                };

                scope.getDisplayText = function (state) {
                    return alertTypeService.getDisplayFor(state);
                };

                scope.isClearingEvent = function (state) {
                    return alertTypeService.isClearingEvent(state);
                };
            },
        };
    },
];
