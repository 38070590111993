'use strict';

angular
    .module('sfx.external', ['angular-sha256'])
    .constant('moment', window.moment)
    .constant('_', window._)
    .constant('parallelCoordinatesChart', window.parallelCoordinatesChart)
    .constant('Tether', window.Tether)
    .constant('TetherTooltip', window.Tooltip)
    .constant('TetherDrop', window.Drop)
    .constant('EventEmitter', window.EventEmitter);
