import templateUrl from './editableSourceFilterItem.tpl.html';

export default [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            replace: false,
            controller: 'EditableSourceFilterController',
            templateUrl,
            scope: {
                objectTypes: '=',
                filterIndex: '=',
                sourceFilters: '=',
                getCurrentQuery: '=',
                applySourceFilters: '=',
                appliedVariables: '=?',
                onBeginEdit: '=?',
                onApplyEdit: '=?',
                onEndEdit: '=?',
                useNewFormat: '@?',
                showFilterOptions: '=?',
                filterTitle: '@?',
                disabled: '<?',
            },
            link: function ($scope, elem) {
                if (!$scope.filterTitle) {
                    $scope.filterTitle = '';
                }

                $scope.focus = function () {
                    // timeout is required for digests to have occurred
                    // and created an input dom element
                    $timeout(function () {
                        const value = $scope.editingInPlace.filter;
                        let rawSource = '';
                        if ($scope.editingInPlace.original) {
                            rawSource = $scope.editingInPlace.original.propertyValue;
                        }
                        const elt = elem.find('.sfx-input')[0];
                        elt.focus();
                        const isValueArray = angular.isArray(rawSource);
                        if (!isValueArray) {
                            elt.setSelectionRange(value.indexOf(':') + 1, value.length);
                        }
                    }, 0);
                };
            },
        };
    },
];
