export default [
    '_',
    'd3v4',
    function (_, d3v4) {
        // Layout system assumed for following Config
        // [Container Boundary] <-Margin-> [Background Boundary] <-Padding-> [Content Boundary] <-Inner Content Container ->
        const configTypes = {
            DETAILED: 'detailed',
            COMPACT: 'compact',
        };

        const severityClasses = {
            warning: 'warning',
            critical: 'critical',
            normal: 'normal',
            default: 'default',
        };

        const defaultPadding = 10;

        // Keeping Upper limits on compact and Lower limits on detailed view the same
        // on these two props allows for a smooth transition
        const defaultMargin = 4;
        const detailedToCompactTransitionSide = 50;

        // Use scale to provide scaled radius with size.
        const radialScale = d3v4.scaleLinear().domain([30, 800]).range([2, 15]).clamp(true);
        const compactScaleConfig = {
            minSide: 10,
            maxSide: detailedToCompactTransitionSide,
            minMargin: 1,
            maxMargin: defaultMargin,
            header: { primaryPassive: 0, primary: 0, secondary: 0 },
            footer: 0,
        };

        const defaultCompactScale = getCompactSizingScale(compactScaleConfig);
        const landscapeCompactScale = getCompactSizingScale(
            _.extend({}, compactScaleConfig, { aspectRatio: 2 })
        );

        const defaultVizConfig = {
            globalSortByKey: 'sf_createdOnMs',
            maxAlignmentRatio: 10, // Maximum Alignment ratio to Honor. Limit = [ 1 / maxAlignmentRatio, maxAlignmentRatio ]
            transitionDuration: 200, // Default transition duration used by cluster map in milliseconds.
            rootPadding: 20,
            // pinFirstLevelToTop:
            // if set to true, sets the top level (as visible on screen) resource to ignore margin-top and thereby pin to top,
            // If set to false the resource will be rendered in the center of the screen (i.e. includes the margin-top)
            pinFirstLevelToTop: true,
            maxDetailingDepth: 2, // Level of details to show. (PS: Last level is compact and not detailed)
            animate: false,
            // Minimum number of children to consider at each level while generating layout
            // (Forces layout to allocate size to at-least accommodate these many resources)
            minChildrenSizes: [0, 0, 2, 2],
            // Depth wise Sizing
            sizing: [
                // Depth 0: This config being a Global config, overrides config for whichever resource is at top
                {
                    detailed: {
                        type: configTypes.DETAILED,
                        header: {},
                    },
                },
                // Depth 1
                {
                    // alignmentRatio number provides a preference towards an alignment (Width / Height) and also acts as limit
                    // Eg: a ratio of 2 i.e. (Width/Height :: 2/1) means the layout will be between a
                    // square to a maximum width of double it's height (or in other words moderately landscape-y)
                    alignmentRatio: 2,
                    sortByKey: 'sf_createdOnMs',
                    detailed: {
                        type: configTypes.DETAILED,
                        radius: radialScale,
                        margin: defaultMargin,
                        padding: 4,
                        minSide: detailedToCompactTransitionSide,
                        header: { primary: 35, secondary: 25, padding: 4 },
                        subLevel: null,
                    },
                    compact: defaultCompactScale,
                },
                // Depth 2
                {
                    alignmentRatio: 1,
                    sortByKey: 'node_creation_timestamp',
                    detailed: {
                        type: configTypes.DETAILED,
                        radius: radialScale,
                        margin: defaultMargin,
                        padding: defaultPadding,
                        minSide: detailedToCompactTransitionSide,
                        header: {},
                        footer: 0,
                        subLevel: null,
                    },
                    compact: defaultCompactScale,
                },
                // Depth 3
                {
                    alignmentRatio: 1,
                    forceAlign: true, // Readjust the resource size to maintain alignmentRatio
                    sortByKey: 'pod_creation_timestamp',
                    detailed: {
                        type: configTypes.DETAILED,
                        radius: radialScale,
                        margin: defaultMargin,
                        padding: defaultPadding,
                        minSide: detailedToCompactTransitionSide,
                        header: {},
                        subLevel: null,
                    },
                    compact: defaultCompactScale,
                },
                // Depth 4
                {
                    alignmentRatio: 1,
                    // TODO: Need to change this to container_creation_timestamp once
                    // available
                    sortByKey: 'container_spec_name',
                    radius: radialScale,
                    compact: landscapeCompactScale,
                },
            ],
        };

        return {
            severityClasses,
            configTypes,
            getCompactSizingScale,
            getFilledVizConfig,
        };

        function getFilledVizConfig(params) {
            const config = _.merge({}, defaultVizConfig, params);

            // Linking Sub-levels
            config.sizing[1].detailed.subLevel = config.sizing[2];
            config.sizing[2].detailed.subLevel = config.sizing[3];
            config.sizing[3].detailed.subLevel = config.sizing[4];

            return config;
        }

        function getCompactSizingScale(compactSizing) {
            const sizeScale = d3v4
                .scaleLinear()
                .domain([0, 1])
                .range([compactSizing.minSide, compactSizing.maxSide]);
            const marginScale = d3v4
                .scaleLinear()
                .domain([0, 1])
                .range([compactSizing.minMargin, compactSizing.maxMargin]);
            const padding = 0;
            const verticalStaticSpace =
                compactSizing.header.primary +
                compactSizing.footer +
                (compactSizing.header.secondary ? compactSizing.header.secondary + padding : 0);

            return {
                dimension: dimension,
                getScale: invert,
            };

            function invert(size) {
                return (
                    (size - (sizeScale(0) + 2 * marginScale(0))) /
                    (sizeScale(1) - sizeScale(0) + 2 * (marginScale(1) - marginScale(0)))
                );
            }

            /**
             * Returns scaled compact dimensions
             * @param {number} scale
             *    Scale of dimension to return. Between [0, 1]
             * @return {{ width: number, height: number, margin: number, padding: number,
             *    header: {primary: number, primaryPassive: number, secondary: number}, footer: number }}
             */
            function dimension(scale) {
                const scaledMargin = marginScale(scale);
                const scaledSize = sizeScale(scale);
                const width = scaledSize + 2 * scaledMargin;
                const aspectRatio = compactSizing.aspectRatio || 1;
                const height = width / aspectRatio + verticalStaticSpace;

                return {
                    scale,
                    width,
                    height,
                    padding,
                    aspectRatio,
                    type: configTypes.COMPACT,
                    margin: scaledMargin,
                    header: compactSizing.header,
                    footer: compactSizing.footer,
                    radius: radialScale,
                };
            }
        }
    },
];
