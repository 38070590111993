import templateUrl from './heatmapFilterBy.tpl.html';

angular.module('signalview.heatmap').directive('heatmapFilterBy', [
    'mustache',
    'plotToSignalflowV2',
    'chartbuilderUtil',
    'heatmapUtilsService',
    '$q',
    function (mustache, plotToSignalflowV2, chartbuilderUtil, heatmapUtilsService, $q) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.filters = $scope.heatmap.filterBy();

                $scope.heatmap.on('filterBy updated', function ($event, filterBy) {
                    $scope.filters = filterBy;
                });

                function updateFilterBy() {
                    let filterBy;

                    if ($scope.filters.length) {
                        filterBy = $scope.filters.slice(0);
                    } else {
                        filterBy = [];
                    }

                    $scope.heatmap.filterBy(angular.copy(filterBy));
                }

                $scope.$watch('filters', updateFilterBy, true);

                $scope.sourceSuggestions = function (query, key) {
                    // We don't need to use the passed in query because the filters are mapped onto the
                    // program text rendered below
                    const input = key;
                    const colorBy = $scope.heatmap.colorBy();
                    const filterBy = $scope.heatmap.filterBy() || [];
                    let program;
                    // if we can use the signalflow that drives the visualization, use that
                    if (colorBy) {
                        const job = colorBy.job;
                        program = mustache.render(job.template, {
                            filter: plotToSignalflowV2.filters(filterBy.concat(job.filters)),
                        });
                    } else if ($scope.heatmap.mode() && $scope.heatmap.mode().metrics) {
                        // else this is a list type which has the template in a different place
                        const colorBys = $scope.heatmap.mode().metrics;
                        program = heatmapUtilsService.colorByToSignalFlow(colorBys, filterBy, '');
                    } else {
                        return $q.reject(
                            'Could not determine a program to filter on for the current mode!'
                        );
                    }
                    return chartbuilderUtil.getSuggestionsFromSignalFlow(
                        [
                            {
                                programText: program.concat('.publish("suggestions")'),
                                packageSpecifications: '',
                            },
                        ],
                        100,
                        input,
                        [],
                        [],
                        null,
                        false,
                        'sf_isActive:true',
                        0,
                        0
                    );
                };

                $scope.getRestrictorQuery = function () {
                    return null;
                };
            },
        };
    },
]);
