angular
    .module('lineviz', ['d3'])
    .service('linevizGradientIncrementer', function () {
        let curIdx = 1;

        return function () {
            return curIdx++;
        };
    })
    .directive('lineviz', [
        'd3v4',
        'linevizGradientIncrementer',
        'timeZoneService',
        '$rootScope',
        'themeService',
        'featureEnabled',
        function (
            d3,
            linevizGradientIncrementer,
            timeZoneService,
            $rootScope,
            themeService,
            featureEnabled
        ) {
            return {
                replace: true,
                scope: {
                    data: '=',
                    low: '=?',
                    lowLabel: '=?',
                    high: '=?',
                    highLabel: '=?',
                    yAxisLabel: '=?',
                    xaxisrange: '=?',
                    aspectRatio: '=?',
                    averageData: '=?',
                    maxData: '=',
                    isHostBasedUsage: '=?',
                    config: '=',
                    newPlan: '=',
                    timeRange: '=?',
                    timeRangeObject: '=?',
                    labelUnit: '=?',
                },
                link: function ($scope, $elem) {
                    $scope.maxData.sort(function (a, b) {
                        return b.timestampMs - a.timestampMs;
                    });

                    const config = $scope.config || {};
                    const THRESHOLD_NAME = 'threshold-gradient' + linevizGradientIncrementer();

                    const margin = {
                        top: config.hasOwnProperty('topMargin') ? config.topMargin : 30,
                        right: config.hasOwnProperty('rightMargin') ? config.rightMargin : 50,
                        bottom: config.hasOwnProperty('bottomMargin') ? config.bottomMargin : 30,
                        left: config.hasOwnProperty('leftMargin') ? config.leftMargin : 100,
                    };
                    const width = 300 * ($scope.aspectRatio || 1);
                    const height = 300;

                    const normalColor = config.normalColor || '#7B56DB';
                    const warningColor = config.warningColor || '#aaa';
                    const overColor = config.overColor || '#ea1849';
                    let limitLineColor = config.limitLineColor || getLimitLineColorForTheme();
                    const highLineColor = '#ea1849';
                    const averageColor = config.averageColor || '#6272b2';
                    const textColor = config.textColor || '#999';
                    const hoverColor = config.hoverColor || '#6b6b6b';
                    const axisColor = config.axisColor || '#ccc';
                    const gradientOpacity = config.gradientOpacity || getOpacity();

                    const bottomMarginFactor = $scope.isHostBasedUsage ? 4 : 3; // Some charts have two legend lines
                    const viewbox =
                        '0 0 ' +
                        (width + margin.left + margin.right) +
                        ' ' +
                        (height + margin.top + margin.bottom * bottomMarginFactor);

                    const bisectDate = d3.bisector(function (d) {
                        return d.timestampMs;
                    }).left;

                    //show a tick every 7 days for a monthly range
                    //otherwise assumes range is a week, show a tick every day
                    const tickLength = $scope.timeRange === 'month' ? 7 : 1;

                    const x = d3.scaleTime().range([0, width]);

                    const y = d3.scaleLinear().nice().range([height, 0]);

                    const xAxis = d3
                        .axisBottom()
                        .scale(x)
                        .tickFormat(d3.timeFormat('%b %d'))
                        .ticks(d3.timeDay.every(tickLength));

                    const maxArea = d3
                        .area()
                        .x(function (d) {
                            return x(d.timestampMs);
                        })
                        .y1(function (d) {
                            return y(d.value);
                        })
                        .y0(function () {
                            return y(0);
                        });

                    const maxLine = d3
                        .line()
                        .x(function (d) {
                            return x(d.timestampMs);
                        })
                        .y(function (d) {
                            return y(d.value);
                        });

                    const t1 = d3
                        .line()
                        .x(function (d) {
                            return d.x;
                        })
                        .y(function (d) {
                            return d.y;
                        });

                    const svg = d3
                        .select($elem[0])
                        .append('svg')
                        .attr('width', '100%')
                        .attr('height', '100%')
                        .attr('viewBox', viewbox)
                        .attr('preserveAspectRatio', 'xMaxYMin meet')
                        .append('g')
                        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                    const yAxisLabel = $scope.yAxisLabel || 'Datapoints per minute (DPM)';

                    svg.append('text')
                        .attr('class', 'yaxis-label')
                        .attr('text-anchor', 'end')
                        .attr('y', 6)
                        .attr('dy', '.75em')
                        .attr('transform', 'rotate(-90),translate(-50,-' + margin.left + ')')
                        .style('shape-rendering', 'crispEdges')
                        .text(yAxisLabel);

                    const data = $scope.data;

                    function getYUpperBound() {
                        const highs = [
                            d3.max(data, function (d) {
                                return d.value;
                            }),
                            d3.max($scope.maxData, function (d) {
                                return d.value;
                            }),
                        ];
                        // Include explicit high value, or a default if not using maximums as limit line
                        if ($scope.high || !$scope.isHostBasedUsage) {
                            highs.push($scope.high === null ? 1000 : $scope.high);
                        }
                        return d3.max(highs);
                    }

                    // Given the styling of the embedded chart, there is a need to insure
                    // that the domain has roughly six days of x axis space without data,
                    // if not it is possible we'll end up with a label over data.
                    if ($scope.xaxisrange && data.length && $scope.timeRange === 'month') {
                        const naturalCushion =
                            $scope.xaxisrange[1] - data[data.length - 1].timestampMs;
                        const sevenDays = 3600000 * 24 * 7;
                        const cushion = naturalCushion > sevenDays ? 0 : sevenDays - naturalCushion;
                        $scope.xaxisrange[1] = $scope.xaxisrange[1] + cushion;
                    }
                    x.domain(
                        $scope.xaxisrange || [
                            data[0].timestampMs,
                            data[data.length - 1].timestampMs,
                        ]
                    );
                    y.domain([0, getYUpperBound()]);
                    let ymax;

                    let planLimitOverAverage = true;
                    if ($scope.isHostBasedUsage && $scope.maxData.length && $scope.xaxisrange) {
                        // Extend limit line to the far right of the chart
                        const latestLimitValue = $scope.maxData[0].value;
                        $scope.maxData.unshift({
                            timestampMs: $scope.xaxisrange[1],
                            value: latestLimitValue,
                        });

                        if ($scope.averageData && $scope.averageData.length) {
                            const averageValue = $scope.averageData[0].value;
                            $scope.averageData.unshift({
                                timestampMs: $scope.xaxisrange[1],
                                value: averageValue,
                            });

                            planLimitOverAverage = latestLimitValue >= averageValue;
                        }
                    }

                    let yAxis = null;

                    yAxis = d3.axisLeft().ticks([5]).scale(y);

                    let gradientData = null;

                    //used to set the gradient color based on whether high and low static values
                    //were passed into the line visualization
                    function setGradientData() {
                        ymax = $scope.high ? Math.min($scope.high * 2, 100000000) : 100000000;

                        if ($scope.high === null && $scope.low === null) {
                            gradientData = [
                                { offset: '0%', color: normalColor },
                                { offset: '100%', color: normalColor },
                            ];
                        } else if ($scope.high > $scope.low && $scope.high !== $scope.low) {
                            gradientData = [
                                { offset: '0%', color: normalColor },
                                { offset: ($scope.low / ymax) * 100 + '%', color: normalColor },
                                { offset: ($scope.low / ymax) * 100 + '%', color: warningColor },
                                { offset: ($scope.high / ymax) * 100 + '%', color: warningColor },
                                { offset: ($scope.high / ymax) * 100 + '%', color: overColor },
                                { offset: '100%', color: overColor },
                            ];
                        } else if ($scope.high < $scope.low) {
                            gradientData = [
                                { offset: '0%', color: normalColor },
                                { offset: ($scope.high / ymax) * 100 + '%', color: normalColor },
                                { offset: ($scope.high / ymax) * 100 + '%', color: overColor },
                                { offset: '100%', color: overColor },
                            ];
                        } else {
                            gradientData = [
                                { offset: '0%', color: normalColor },
                                { offset: ($scope.low / ymax) * 100 + '%', color: normalColor },
                                { offset: ($scope.low / ymax) * 100 + '%', color: overColor },
                                { offset: '100%', color: overColor },
                            ];
                        }
                    }

                    setGradientData();

                    const thresholdGradient = svg
                        .append('linearGradient')
                        .attr('id', THRESHOLD_NAME)
                        .attr('gradientUnits', 'userSpaceOnUse')
                        .attr('x1', 0)
                        .attr('y1', y(0))
                        .attr('x2', 0)
                        .attr('y2', y(ymax));

                    function drawGradients() {
                        const stops = thresholdGradient.selectAll('stop').data(gradientData);

                        stops
                            .enter()
                            .append('stop')
                            .attr('offset', function (d) {
                                return d.offset;
                            })
                            .attr('stop-color', function (d) {
                                return d.color;
                            })
                            .attr('stop-opacity', gradientOpacity);

                        stops.exit().remove();

                        stops
                            .attr('offset', function (d) {
                                return d.offset;
                            })
                            .attr('stop-color', function (d) {
                                return d.color;
                            })
                            .attr('stop-opacity', gradientOpacity);
                    }

                    drawGradients();

                    svg.append('g')
                        .attr('class', 'x axis')
                        .attr('transform', 'translate(0,' + height + ')')
                        .call(xAxis);

                    if (yAxis) {
                        svg.append('g')
                            .attr('class', 'y axis')
                            .attr('transform', 'translate(0,0)')
                            .call(yAxis);
                    }

                    function mousemove() {
                        // Update tooltip timestamp and values
                        let timestampText = '';
                        let dataText = '';
                        let maxDataText = '';
                        // Clear last datapoint dots and legend swatches
                        svg.selectAll('circle').remove();

                        try {
                            const hoverX = d3.mouse(this)[0];
                            hoverLine.attr('x1', hoverX).attr('x2', hoverX);

                            const x0 = x.invert(d3.mouse(this)[0]),
                                i = bisectDate(data, x0, 1),
                                d0 = data[i - 1],
                                d1 = data[i],
                                d = x0 - d0.timestampMs > d1.timestampMs - x0 ? d1 : d0;
                            // UTC timezone is used for host/mts subscription usage chart tooltip.
                            const timeStamp = timeZoneService.moment(d.timestampMs);
                            timestampText =
                                featureEnabled('subscriptionUsageAvg') && $scope.isHostBasedUsage
                                    ? timeStamp.utc().format('MMMM Do YYYY, HH:mm')
                                    : timeStamp.format('MMMM Do YYYY, HH:mm');

                            dataText = $scope.isHostBasedUsage ? 'Count: ' : 'Average: ';
                            dataText += d3.format(',')(d.value);

                            const maxd = $scope.maxData.find((datum) => {
                                return datum.timestampMs === d.timestampMs;
                            });
                            if (maxd) {
                                maxDataText = $scope.isHostBasedUsage
                                    ? 'Plan Limit: '
                                    : 'Maximum: ';
                                maxDataText += d3.format(',')(maxd.value);

                                // Mark the datapoint in the chart
                                // Draw before the datapoint marker, so that the datapoint is draw on top
                                svg.append('circle')
                                    .attr('cx', hoverX)
                                    .attr('cy', y(maxd.value))
                                    .attr('r', 5)
                                    .attr('stroke', getBorderColorForTheme())
                                    .attr('stroke-width', 1)
                                    .attr('fill', limitLineColor);

                                // Legend color swatch
                                tooltip
                                    .append('circle')
                                    .attr('cx', maxDataTextX)
                                    .attr('cy', -5)
                                    .attr('r', 8)
                                    .attr('stroke', getBorderColorForTheme())
                                    .attr('stroke-width', 1)
                                    .attr('fill', limitLineColor);
                            }

                            // Mark the datapoint in the chart
                            svg.append('circle')
                                .attr('cx', hoverX)
                                .attr('cy', y(d.value))
                                .attr('r', 5)
                                .attr('stroke', getBorderColorForTheme())
                                .attr('stroke-width', 1)
                                .attr('fill', normalColor);

                            // Legend color swatch
                            tooltip
                                .append('circle')
                                .attr('cx', dataTextX)
                                .attr('cy', -5)
                                .attr('r', 8)
                                .attr('stroke', getBorderColorForTheme())
                                .attr('stroke-width', 1)
                                .attr('fill', normalColor);
                        } catch (e) {}

                        tooltip.select('.lineviz-tooltip-text').text(timestampText);
                        tooltip.select('.lineviz-tooltip-data').text(dataText);
                        tooltip.select('.lineviz-tooltip-max-data').text(maxDataText);
                    }

                    const tooltipY = height + margin.bottom * 2;
                    const tooltip = svg
                        .append('g')
                        .attr('transform', 'translate(0, ' + tooltipY + ')');

                    tooltip.append('text').attr('class', 'lineviz-tooltip-text');

                    const dataTextX = 210;
                    tooltip
                        .append('text')
                        .attr('class', 'lineviz-tooltip-data')
                        .attr('transform', 'translate(' + (dataTextX + 20) + ', 0)');

                    const maxDataTextX = 400;
                    const secondLineY = 35;
                    if ($scope.maxData.length) {
                        tooltip
                            .append('text')
                            .attr('class', 'lineviz-tooltip-max-data')
                            .attr('transform', 'translate(' + (maxDataTextX + 20) + ', 0)');
                    }

                    tooltip
                        .append('text')
                        .attr('class', 'lineviz-tooltip-text-line2')
                        .attr('transform', 'translate(0, 40)');

                    tooltip
                        .append('text')
                        .attr('class', 'lineviz-tooltip-average-data')
                        .attr('transform', 'translate(' + (dataTextX + 20) + ', 40)');

                    // Draw maximums behind data
                    if (!$scope.isHostBasedUsage) {
                        svg.append('path')
                            .attr('transform', 'translate(1,0)')
                            .datum($scope.maxData)
                            .attr('class', 'max-line')
                            .attr('stroke', 'url(#' + THRESHOLD_NAME + ')')
                            .attr('d', maxArea);
                    }

                    svg.append('path')
                        .attr('transform', 'translate(1,0)')
                        .datum(data)
                        .attr('class', 'line')
                        .attr('d', maxArea)
                        .attr('fill', 'url(#' + THRESHOLD_NAME + ')');

                    if ($scope.averageData && $scope.averageData.length) {
                        const thisPeriodText = $scope.isHostBasedUsage ? 'This Period:' : '';
                        const averageValue = Math.floor($scope.averageData[0].value);
                        let averageDataText = $scope.timeRangeObject.monthsAgo
                            ? 'Average Usage: '
                            : 'Average Usage (to date): ';
                        averageDataText += d3.format(',')(averageValue);
                        tooltip
                            .append('rect')
                            .attr('x', dataTextX - 8)
                            .attr('y', secondLineY - 1)
                            .attr('width', 16)
                            .attr('height', 3)
                            .attr('fill', averageColor);

                        tooltip.select('.lineviz-tooltip-text-line2').text(thisPeriodText);
                        tooltip.select('.lineviz-tooltip-average-data').text(averageDataText);
                    }

                    function drawLimitsLine() {
                        // Draw limits over data
                        if ($scope.isHostBasedUsage) {
                            svg.append('path')
                                .attr('transform', 'translate(1,0)')
                                .datum($scope.maxData)
                                .attr('class', 'max-line')
                                .attr('stroke', limitLineColor)
                                .attr('fill', 'none')
                                .attr('d', maxLine);

                            if ($scope.averageData && $scope.averageData.length) {
                                const labelOffset = planLimitOverAverage ? 14 : -3;
                                // Draw average
                                svg.append('path')
                                    .attr('transform', 'translate(1,0)')
                                    .datum($scope.averageData)
                                    .attr('class', 'max-line')
                                    .attr('stroke', averageColor)
                                    .attr('fill', 'none')
                                    .attr('d', maxLine);

                                svg.append('text')
                                    .attr('y', y($scope.averageData[0].value) + labelOffset)
                                    .attr('x', x($scope.xaxisrange[1]))
                                    .attr('text-anchor', 'end')
                                    .style('fill', averageColor)
                                    .text('Average Usage');
                            }

                            // Label for limits line, if there is one
                            if ($scope.maxData.length) {
                                const labelOffset = planLimitOverAverage ? -3 : 14;
                                svg.append('text')
                                    .attr('y', y($scope.maxData[0].value) + labelOffset)
                                    .attr('x', x($scope.xaxisrange[1]))
                                    .attr('text-anchor', 'end')
                                    .style('fill', limitLineColor)
                                    .text('Plan Limit');
                            }
                        }
                    }
                    drawLimitsLine();

                    function limitLine(value, label) {
                        return [
                            {
                                x: x($scope.xaxisrange[0]),
                                y: y(value),
                            },
                            {
                                x: x($scope.xaxisrange[1]),
                                y: y(value),
                            },
                            {
                                label: label,
                                dpm: value,
                            },
                        ];
                    }

                    function updateAxes() {
                        const transition = svg.transition();

                        y.domain([0, getYUpperBound()]);

                        transition.select('.y.axis').duration(750).call(yAxis);
                        transition.select('.line').duration(750).attr('d', maxArea);
                        transition
                            .select('#' + THRESHOLD_NAME)
                            .duration(0)
                            .attr('x1', 0)
                            .attr('y1', y(0))
                            .attr('x2', 0)
                            .attr('y2', y(ymax));
                    }

                    let insufficientElection;
                    function setInsufficient() {
                        insufficientElection =
                            d3.max($scope.maxData || data, function (d) {
                                return d.value;
                            }) > $scope.high;

                        if ($scope.newPlan) {
                            const percentOverage = d3.mean(data, function (d) {
                                return d.value > $scope.high && d.value ? 1 : 0;
                            });
                            $scope.newPlan.insufficient = percentOverage > 0.3 ? true : false;
                        }
                    }

                    function drawThresholds() {
                        const limitLines = [];
                        if ($scope.low) {
                            limitLines.push(limitLine($scope.low, $scope.lowLabel));
                        }
                        if ($scope.high && $scope.high !== $scope.low) {
                            limitLines.push(limitLine($scope.high, $scope.highLabel));
                        }

                        const lines = svg.selectAll('.threshold').data(limitLines);

                        lines.exit().remove();

                        setLimitLines(
                            lines
                                .enter()
                                .append('path')
                                .attr('class', 'threshold')
                                .attr('stroke-width', 1)
                                .attr('fill', 'none')
                        );

                        setLimitLines(lines);

                        const limitLabels = svg.selectAll('.limit-label').data(limitLines);

                        limitLabels.exit().remove();

                        setLimitLabels(
                            limitLabels
                                .enter()
                                .append('text')
                                .attr('class', 'limit-label')
                                .attr('text-anchor', 'end')
                        );

                        setLimitLabels(limitLabels);
                    }

                    function setLimitLabels(labels) {
                        return labels
                            .attr('x', (lineData) => lineData[1].x - 5)
                            .attr('y', (lineData) => lineData[1].y - 10)
                            .text(function (lineData) {
                                let labelText =
                                    (lineData[2].label || '') +
                                    ' (' +
                                    d3.format(',.0f')(lineData[2].dpm);
                                if ($scope.labelUnit) {
                                    labelText += ' ' + $scope.labelUnit;
                                }
                                labelText += ')';
                                return labelText;
                            })
                            .style('fill', function (lineData) {
                                if (
                                    lineData[2].label === $scope.highLabel &&
                                    insufficientElection
                                ) {
                                    return highLineColor;
                                }
                                return textColor;
                            });
                    }

                    function setLimitLines(lines) {
                        return lines
                            .attr('stroke-dasharray', function (lineData) {
                                if (lineData[2].label === $scope.highLabel) {
                                    return insufficientElection ? null : '5, 5';
                                }
                            })
                            .attr('stroke', function (lineData) {
                                if (lineData[2].label === $scope.highLabel) {
                                    return insufficientElection ? highLineColor : '#3A6A00';
                                }
                                return textColor;
                            })
                            .attr('d', (lineData) => t1(lineData.slice(0, 2)));
                    }

                    drawThresholds();

                    const borderData = [
                        {
                            x: 0,
                            y: 0,
                        },
                        {
                            x: 0,
                            y: y(y.domain()[0]),
                        },
                        {
                            x: width,
                            y: y(y.domain()[0]),
                        },
                        {
                            x: width,
                            y: 0,
                        },
                        {
                            x: 0,
                            y: 0,
                        },
                    ];
                    const bottomLeftBorders = [
                        {
                            x: width,
                            y: y(y.domain()[0]),
                        },
                        {
                            x: 0,
                            y: y(y.domain()[0]),
                        },
                        {
                            x: 0,
                            y: 0,
                        },
                    ];

                    svg.append('path')
                        .attr('d', t1(bottomLeftBorders))
                        .attr('stroke', axisColor)
                        .attr('stroke-width', 2)
                        .attr('fill', 'none');

                    const hoverRect = svg
                        .append('path')
                        .attr('d', t1(borderData))
                        .attr('fill', 'white')
                        .attr('fill-opacity', 0)
                        .attr('class', 'hover-rect');

                    hoverRect
                        .on('mouseover', function () {
                            tooltip.style('display', null);
                            hoverLine.style('display', null);
                        })
                        .on('mousemove', mousemove);

                    const hoverLine = svg
                        .append('line')
                        .attr('stroke', hoverColor)
                        .attr('stroke-width', 1)
                        .attr('y1', 0)
                        .attr('y2', height)
                        .attr('x1', 0)
                        .attr('x2', 0);

                    $scope.$watchGroup(['high', 'low'], updateChart);

                    function getOpacity() {
                        // dpm chart has black max limit behind it,
                        // which makes the transparent opacity look weird
                        if (!$scope.isHostBasedUsage && $scope.maxData && $scope.maxData.length) {
                            return '1';
                        } else {
                            return '0.6';
                        }
                    }

                    function getLimitLineColorForTheme() {
                        if (themeService.dark) {
                            return '#fff';
                        } else {
                            return '#000';
                        }
                    }

                    function getBorderColorForTheme() {
                        if (themeService.dark) {
                            return '#ccc';
                        } else {
                            return '#000';
                        }
                    }

                    $rootScope.$on('theme update', () => {
                        limitLineColor = config.limitLineColor || getLimitLineColorForTheme();
                        drawLimitsLine();
                    });

                    function updateChart() {
                        setInsufficient();
                        setGradientData();
                        updateAxes();
                        drawThresholds();
                        drawGradients();
                    }
                },
            };
        },
    ]);
