import PropTypes from 'prop-types';
import Dropdown from '@splunk/react-ui/Dropdown';
import Tooltip from '@splunk/react-ui/Tooltip';
import Menu from '@splunk/react-ui/Menu';
import { EyeToggleHidden, EyeToggleVisible } from '@splunk/olly-common/Icons/EyeToggleIcon';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@splunk/react-ui/Button';
import Cog from '@splunk/react-icons/Cog';

TableChartGearMenu.propTypes = {
    columns: PropTypes.array,
    legendColumns: PropTypes.array,
    legendColumnsHandler: PropTypes.func,
    groupByKey: PropTypes.string,
    hideTimestamp: PropTypes.bool,
    toggleTimestampDisplay: PropTypes.func,
    hidePlots: PropTypes.bool,
};

const StyledButton = styled(Button)`
    float: right;
`;

const LargeGearIcon = styled(Cog)`
    width: 1.25em;
    height: 1.25em;
`;

const StyledGearMenu = styled(Menu)`
    & {
        width: 200px;
        max-height: 325px;
        overflow-y: scroll;
    }
    [data-test='tooltip'] {
        width: 100%;
        > span {
            width: 100%;
        }
    }
`;

const StyledGearButton = (
    <StyledButton
        size="large"
        appearance="default"
        icon={<LargeGearIcon />}
        aria-label="Table-Chart Menu"
    />
);

const StyledDiv = styled.div`
    max-height: 200px;
    overflow-y: scroll;
    display: inline;
`;

const StyledIconDiv = styled.div`
    display: flex;
    > div > svg {
        font-size: 20px;
    }
    > span {
        margin-left: 5px;
    }
`;

const StyledHeading = styled(Menu.Heading)`
    && {
        font-size: 14px;
        margin: 0;
    }
`;

const SHOW_COLUMNS = 'SHOW/HIDE COLUMNS';
const OTHER_ACTIONS = 'OTHER ACTIONS';
const HIDE_TIMESTAMP = 'Hide timestamp';
const SHOW_TIMESTAMP = 'Show timestamp';

export default function TableChartGearMenu({
    columns,
    legendColumns,
    legendColumnsHandler,
    groupByKey,
    hideTimestamp,
    toggleTimestampDisplay,
    hidePlots,
}) {
    const [showHideCols, setShowHideCols] = useState([]);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    useEffect(() => {
        const cols = [];
        columns.forEach((col) => {
            if (hidePlots && col.isPlot) {
                return;
            }
            cols.push({
                key: col.key,
                name: col.name,
                selected: col.isVisible,
                disabled: col.key === groupByKey,
                isPlot: col.isPlot,
            });
        });
        setShowHideCols(cols);
    }, [columns, groupByKey, legendColumns, hidePlots]);

    const onDropdownRequestOpen = () => {
        setIsDropDownOpen(true);
    };

    const onDropdownRequestClose = ({ reason }) => {
        setIsDropDownOpen(
            !(reason === 'toggleClick' || reason === 'offScreen' || reason === 'clickAway')
        );
    };

    return (
        <StyledDiv>
            <Dropdown
                toggle={StyledGearButton}
                open={isDropDownOpen}
                onRequestClose={onDropdownRequestClose}
                onRequestOpen={onDropdownRequestOpen}
            >
                <StyledGearMenu data-test="table-chart-settings-menu">
                    <StyledHeading>{SHOW_COLUMNS}</StyledHeading>
                    {showHideCols.map((col) =>
                        col.disabled ? (
                            <Tooltip
                                key={`${col.key}-tooltip`}
                                compact
                                content="The group by column cannot be hidden"
                                defaultPlacement={'left'}
                            >
                                <Menu.Item
                                    key={col.key}
                                    onClick={() => legendColumnsHandler(col)}
                                    truncate
                                    selectable
                                    selected={col.selected}
                                    disabled={col.disabled}
                                >
                                    <span>{col.name}</span>
                                </Menu.Item>
                            </Tooltip>
                        ) : (
                            <Menu.Item
                                key={col.key}
                                onClick={() => legendColumnsHandler(col)}
                                truncate
                                selectable
                                selected={col.selected}
                                disabled={col.disabled}
                            >
                                <span>{col.name}</span>
                            </Menu.Item>
                        )
                    )}
                    <Menu.Divider />
                    <StyledHeading>{OTHER_ACTIONS}</StyledHeading>
                    {hideTimestamp && (
                        <Menu.Item onClick={() => toggleTimestampDisplay()}>
                            <StyledIconDiv>
                                <EyeToggleVisible />
                                <span> {SHOW_TIMESTAMP} </span>
                            </StyledIconDiv>
                        </Menu.Item>
                    )}
                    {!hideTimestamp && (
                        <Menu.Item onClick={() => toggleTimestampDisplay()}>
                            <StyledIconDiv>
                                <EyeToggleHidden />
                                <span> {HIDE_TIMESTAMP} </span>
                            </StyledIconDiv>
                        </Menu.Item>
                    )}
                </StyledGearMenu>
            </Dropdown>
        </StyledDiv>
    );
}
