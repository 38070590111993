import { supportService } from '@splunk/olly-services/lib';
angular.module('support.supportService').controller('SupportController', [
    '$scope',
    '$http',
    'security',
    '$timeout',
    '$log',
    'orgSubscriptionService',
    'SUPPORT_SERVER_API_URL',
    function (
        $scope,
        $http,
        security,
        $timeout,
        $log,
        orgSubscriptionService,
        SUPPORT_SERVER_API_URL
    ) {
        $scope.supportOrg = function (orgId) {
            if (orgId && !supportService.getSupportOrg()) {
                $scope.enteringCustomer = true;
                security.sudo(orgId).then(function () {
                    return $timeout($scope.$close, 2000);
                });
            }
        };

        $scope.syncOrg = function (accountNumber) {
            $scope.orgSyncError = false;
            $scope.isSyncing = true;
            return orgSubscriptionService
                .syncOrg(accountNumber)
                .then(function (response) {
                    const data = (response || {}).data;
                    const orgId = (data || {}).orgId;
                    if (orgId) {
                        $scope.supportOrg(orgId);
                    } else {
                        $scope.orgSyncError = true;
                        $log.error('Failed syncing org ', accountNumber);
                    }
                })
                .catch(function (e) {
                    $scope.orgSyncError = true;
                    $log.error('Failed syncing org ', accountNumber, e);
                })
                .finally(function () {
                    $scope.isSyncing = false;
                });
        };

        let searchTimeout = null;
        $scope.loading = false;

        $scope.supportSearchResult = [];

        let requestCount = 0;

        function search() {
            const term = $scope.supportSearchTerm;
            requestCount++;
            const targerRequestCount = requestCount;
            if (!term) {
                $scope.supportSearchResult = [];
                return;
            }
            $scope.loading = true;
            $http({
                method: 'GET',
                url: SUPPORT_SERVER_API_URL + '/v1/as/suggest/organization',
                params: {
                    partialInput: term,
                },
            }).then(
                function (response) {
                    if (targerRequestCount === requestCount) {
                        $scope.supportSearchResult = response.data;
                        $scope.loading = false;
                    }
                },
                function () {
                    if (targerRequestCount === requestCount) {
                        $scope.loading = false;
                    }
                }
            );
        }

        $scope.$watch('supportSearchTerm', function () {
            if (searchTimeout) {
                $timeout.cancel(searchTimeout);
            }
            searchTimeout = $timeout(search, 200);
        });
    },
]);
