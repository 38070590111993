import { chunk } from 'lodash';
import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import mutingModalTemplateUrl from './mutingModal.tpl.html';

angular.module('signalview.alertMuting').service('mutingService', [
    'currentUser',
    '$q',
    'sfxModal',
    '$timeout',
    'organizationService',
    '$filter',
    'alertMutingApiService',
    'DetectorV2SearchService',
    function (
        currentUser,
        $q,
        sfxModal,
        $timeout,
        organizationService,
        $filter,
        alertMutingApiService,
        DetectorV2SearchService
    ) {
        return {
            deleteMuting,
            endMuting,
            getFormattedDurationAndCreator,
            getMutingTooltip,
            getList,
            getAll,
            getCurrent,
            getListForDetector,
            getMutingText,
            getMutingUserDisplayName,
            isMutingStarted,
            mute,
            openMutingModal,
        };

        function getAll(params) {
            const searchParams = {
                ...params,
                limit: params.limit || 1000,
                offset: 0,
            };
            return alertMutingApiService
                .search(searchParams)
                .then((response) => {
                    const results = response.data.results;
                    const totalResults = response.data.count;
                    const remainingPagesCount = totalResults / searchParams.limit;
                    const allPages = [joinMutingsWithUserAndDetectorDetails(results || [])];
                    for (let page = 1; page < remainingPagesCount; page++) {
                        const offset = searchParams.offset + page * searchParams.limit;
                        allPages.push(getList({ ...searchParams, offset }));
                    }
                    return Promise.all(allPages);
                })
                .then((mutings) => mutings.reduce((left, right) => left.concat(right)));
        }

        function getCurrent() {
            return alertMutingApiService.getCurrent().then((response) => {
                const results = response.data.results;
                return joinMutingsWithUserAndDetectorDetails(results);
            });
        }

        function getList(param) {
            return alertMutingApiService
                .search(param)
                .then((response) => response?.data?.results || [])
                .then(joinMutingsWithUserAndDetectorDetails);
        }

        function joinMutingsWithUserAndDetectorDetails(mutings) {
            // Pull detector id from filters and assign as property on muting
            mutings.forEach((item) => {
                const detectorIdFilter = item.filters.find(isDetectorIdFilter);

                if (detectorIdFilter) {
                    item.detectorId = detectorIdFilter.propertyValue;
                }
            });

            return $q
                .all({
                    users: fetchUsersForMutings(mutings),
                    detectors: fetchDetectorsForMutings(mutings),
                })
                .then(({ users, detectors }) => {
                    const userIdToObj = mapUserIdsToUsers(users.results);
                    const detectorIdToObj = mapDetectorIdsToDetectors(detectors.rs);

                    setMutingListDisplayProperties(mutings, userIdToObj, detectorIdToObj);

                    return mutings;
                });
        }

        function getListForDetector(detectorId) {
            return getList({
                query: `filters:*${sanitizeTerm(detectorId)}*`,
                limit: 100,
                orderBy: ['startTime', 'stopTime'],
            }).then(function (mutings) {
                return mutings.filter(function (muting) {
                    return muting.filters.some(function (filter) {
                        return (
                            !filter.NOT &&
                            filter.property === 'sf_detectorId' &&
                            filter.propertyValue === detectorId
                        );
                    });
                });
            });
        }

        function deleteWithConfirmation(id, isDelete) {
            const modalInstance = sfxModal.open({
                template: '<div><i class="busy-spinner-light"></i></div>',
                windowClass: 'full-screen-busy-spinner',
                backdrop: 'static',
                keyboard: false,
            });

            if (isDelete) {
                alertMutingApiService.delete(id);
            } else {
                alertMutingApiService.unmute(id);
            }
            return $timeout(modalInstance.close, 3000);
        }

        function deleteMuting(mutingRule) {
            return deleteWithConfirmation(mutingRule.id, true);
        }

        function endMuting(mutingRule) {
            return deleteWithConfirmation(mutingRule.id, false);
        }

        function openMutingModal(muting, filters) {
            const modalInstance = sfxModal.open({
                templateUrl: mutingModalTemplateUrl,
                controller: 'MutingModalController',
                size: 'md',
                resolve: {
                    mutingParams: function () {
                        return {
                            muting: muting,
                            filters: filters || [],
                        };
                    },
                },
                backdrop: 'static',
                keyboard: false,
            });

            return modalInstance.result;
        }

        function isMutingStarted(mutingList) {
            return mutingList.some((muting) => muting.started);
        }

        function getMutingText(isMutingStarted) {
            return `Notifications for one or more rules in this detector are ${
                isMutingStarted ? 'currently' : 'scheduled to be'
            } muted.`;
        }

        function mute(id) {
            const filters = [
                {
                    property: 'sf_detectorId',
                    propertyValue: id,
                },
            ];

            return openMutingModal(null, filters);
        }

        function getFormattedDurationAndCreator(muting) {
            const mutingStart = $filter('date')(muting.startTime, 'short');
            const mutingEnd = !muting.stopTime
                ? '(no end date)'
                : $filter('date')(muting.stopTime, 'short');
            return `${mutingStart} to ${mutingEnd} - ${getMutingUserDisplayName(
                muting.creatorUser
            )}`;
        }

        function getMutingTooltip(muting) {
            return (
                (muting.description ? muting.description + ' - ' : '') +
                getFormattedDurationAndCreator(muting)
            );
        }

        function isDetectorIdFilter(filter) {
            return filter.property === 'sf_detectorId' && angular.isString(filter.propertyValue);
        }

        function stringifyFilter(filter) {
            let value = filter.propertyValue;
            if (Array.isArray(value)) {
                value = value.join(',');
            }
            return (filter.NOT ? '!' : '') + filter.property + ':' + value;
        }

        function fetchDetectorsForMutings(mutings) {
            const detectorIdSet = mutings.reduce((set, muting) => {
                if (muting.detectorId) {
                    set.add(muting.detectorId);
                }
                return set;
            }, new Set());

            const detectorIds = [...detectorIdSet];

            if (detectorIds.length) {
                return DetectorV2SearchService.search({
                    detectorIds: detectorIds,
                });
            } else {
                return $q.when({ rs: [] });
            }
        }

        function fetchUsersForMutings(mutings) {
            const ids = _.uniq(mutings.flatMap((row) => [row.creator, row.lastUpdatedBy]));

            if (!ids.length) {
                return $q.when({ results: [] });
            } else {
                // Chunking IDs to result in max 4000 charaters at a time.
                // Our REST header size limit is at ~6000 characters. Keeping 2000 characters as buffer.
                // Average ID string length === 11 characters
                // Term size of id query "<ID> OR " === 15 charaters.
                // Max size of chunks: Math.ceil(4000 / 15) = 267
                const chunkSize = 267;
                const orgIdPromise = currentUser.orgId();
                const idBatches = chunk(ids, chunkSize);
                const batchedUserPromises = idBatches.map((idBatch) =>
                    orgIdPromise.then((orgId) => {
                        const creatorsQuery = `sf_userID: ${sanitizeTerm(
                            idBatch
                        )} AND sf_organizationID: ${sanitizeTerm(orgId)}`;
                        return organizationService.searchMembersWithArbitraryQuery(
                            creatorsQuery,
                            0,
                            1000
                        );
                    })
                );

                return $q.when(
                    Promise.all(batchedUserPromises).then((userResults) =>
                        userResults.reduce(
                            (aggregate, currentValue) => ({
                                count: aggregate.count + currentValue.count,
                                results: aggregate.results.concat(currentValue.results),
                            }),
                            {
                                count: 0,
                                results: [],
                            }
                        )
                    )
                );
            }
        }

        function mapUserIdsToUsers(users) {
            return users.reduce((map, orgUser) => {
                map[orgUser.userId] = orgUser;
                return map;
            }, {});
        }

        function mapDetectorIdsToDetectors(detectors) {
            return detectors.reduce((map, detector) => {
                map[detector.sf_id] = detector;
                return map;
            }, {});
        }

        function setMutingListDisplayProperties(mutings, usersById, detectorsById) {
            const now = Date.now();

            mutings.forEach(function (row) {
                row.creatorUser = usersById[row.creator];
                row.lastUpdatedByUser = usersById[row.lastUpdatedBy];

                if (row.detectorId) {
                    row.detector = detectorsById[row.detectorId];
                }

                // for display purpose only
                row.target = row.filters
                    .filter((f) => !isDetectorIdFilter(f))
                    .map(stringifyFilter)
                    .join(', ');

                row.started = row.startTime < now;
            });
        }

        function getMutingUserDisplayName(user) {
            return user?.fullName || user?.email;
        }
    },
]);
