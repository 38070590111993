export default [
    '$log',
    function ($log) {
        const EXPIRATION_MODES = {
            TIME: 'time',
            INTERVAL: 'interval',
        };

        class WindowedDataRepository {
            constructor() {
                this._expirationMode = EXPIRATION_MODES.TIME;
                this._expirationTime = 60000;
                this._expirationIntervals = 10;
                this._data = [];
                this._tsidToIndex = {};
                this._numTs = 0;
                this._timeStampToDataArray = {};
            }

            setExpirationPolicy(mode, value) {
                this._expirationMode = mode;
                if (mode === EXPIRATION_MODES.TIME) {
                    this._expirationTime = value;
                } else if (mode === EXPIRATION_MODES.INTERVAL) {
                    this._expirationIntervals = value;
                } else {
                    $log.error('Invalid expiration policy');
                }
                this._applyExpiration();
            }

            setTimeExpiration(value) {
                this.setExpirationPolicy(EXPIRATION_MODES.TIME, value);
            }

            setIntervalExpiration(value) {
                this.setExpirationPolicy(EXPIRATION_MODES.INTERVAL, value);
            }

            // this._data = [
            //   [ ts1, valTsid1, valTsid2, valTsid3 ], <= referenced by this._timeStampToDataArray[ts1]
            //   [ ts2, valTsid1, valTsid2, valTsid3 ]
            // ]           ^^ col referenced by this._tsidToIndex[valTsid1]
            //
            // we keep references to the arrays stored in this._data keyed by their
            // timestamps:
            //
            // this._timeStampToDataArray = {
            //   [timestamp]: [ ts1, valTsid1, valTsid2, valTsid3 ]
            // }
            onDataReceived(data) {
                Object.entries(data).forEach(([tsid, datapoint]) => {
                    const { timestamp, value } = datapoint;

                    if (!this._hasFileForTimestamp(timestamp)) {
                        this._createFileForTimestamp(timestamp);
                    }

                    const dataArrayToInsert = this._timeStampToDataArray[timestamp];

                    if (this._isNewTsid(tsid)) {
                        this._onNewTsidReceived(tsid);
                    }

                    dataArrayToInsert[this._tsidToIndex[tsid]] = value;
                });

                this._applyExpiration();
            }

            _onNewTsidReceived(tsid) {
                // in case this is called without first checking that the tsid is new
                // return early if we find that it isn't new
                if (!this._isNewTsid(tsid)) {
                    return;
                }

                this._numTs++;
                this._tsidToIndex[tsid] = this._numTs;

                // backfills nulls in the indices of old time slices that the new tsid
                // will occupy
                this._data.forEach((timeSlice) => timeSlice.push(null));
            }

            get(tsid) {
                const tsidIdx = this._tsidToIndex[tsid];

                if (tsidIdx) {
                    return this._data.map((dataArr) => {
                        return { ts: dataArr[0], value: dataArr[tsidIdx] };
                    });
                } else {
                    return null;
                }
            }

            getMany(tsids) {
                const data = {};

                tsids.forEach((tsid) => {
                    data[tsid] = this.get(tsid);
                });

                return data;
            }

            getAll() {
                const data = {};

                Object.entries(this._tsidToIndex).forEach(([tsid, idx]) => {
                    const datapoints = this._data.map((timeSlice) => {
                        return { ts: timeSlice[0], value: timeSlice[idx] };
                    });

                    data[tsid] = datapoints;
                });

                return data;
            }

            getCurrentValue(tsid) {
                const tsidIndex = this._tsidToIndex[tsid];

                if (tsidIndex) {
                    const data = this._data;
                    const currentTimeSlice = data[data.length - 1];

                    return {
                        ts: currentTimeSlice[0],
                        value: currentTimeSlice[tsidIndex],
                    };
                }
            }

            getCurrentValues(tsids) {
                const data = {};

                tsids.forEach((tsid) => {
                    data[tsid] = this.getCurrentValue(tsid);
                });

                return data;
            }

            getCurrentTimestamp() {
                if (!this._data.length) {
                    return null;
                }

                const latestSlice = this._data[this._data.length - 1];
                return latestSlice[0];
            }

            _applyExpiration() {
                if (this._expirationMode === EXPIRATION_MODES.INTERVAL) {
                    this._applyIntervalExpiration();
                } else if (this._expirationMode === EXPIRATION_MODES.TIME) {
                    this._applyTimeExpiration();
                } else {
                    $log.error('Invalid expiration mode');
                }
            }

            _applyIntervalExpiration() {
                while (this._data && this._data.length > this._expirationIntervals) {
                    this._deleteOldestTimeSlice();
                }
            }

            _applyTimeExpiration() {
                const indexToCut = this._data.findIndex((timeSlice) => {
                    if (timeSlice[0] > Date.now() - this._expirationTime) return true;
                });

                // nothing to delete in this case
                if (indexToCut === -1) return;

                for (let i = indexToCut; i > 0; i--) {
                    this._deleteOldestTimeSlice();
                }
            }

            _deleteOldestTimeSlice() {
                const removedTs = this._data.shift()[0];
                delete this._timeStampToDataArray[removedTs];
            }

            _createFileForTimestamp(timestamp) {
                const newFile = [timestamp];

                this._data.push(newFile);
                this._timeStampToDataArray[timestamp] = newFile;
            }

            _hasFileForTimestamp(timestamp) {
                return !!this._timeStampToDataArray[timestamp];
            }

            _isNewTsid(tsid) {
                return this._tsidToIndex[tsid] === undefined;
            }
        }

        return WindowedDataRepository;
    },
];
