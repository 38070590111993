import templateUrl from './catalogGlobalSearch.tpl.html';
export default {
    templateUrl,
    bindings: {
        placeholderText: '@', // placeholder text in the search bar
    },
    controller: [
        '$scope',
        'catalogQuickActionsDataProvider',
        'quickActionsUtil',
        function ($scope, catalogQuickActionsDataProvider, quickActionsUtil) {
            const $ctrl = this;
            const documentElem = angular.element('.quick-action-input-container');

            $ctrl.$onInit = $onInit;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.performSearch = performSearch;
            $ctrl.mouseEnter = mouseEnter;
            $ctrl.clickHandler = clickHandler;

            $ctrl.debounceTimeout = 400;
            $ctrl.itemClass = 'catalog-result';
            $ctrl.containerClass = 'catalog-result-container';

            const parseForKeyValue = (inputText) => {
                if (!inputText || inputText.indexOf(':') < 0) {
                    return { val: inputText };
                }
                const splitted = inputText.split(':');
                return { key: splitted.shift(), val: splitted.join(':') };
            };

            function $onInit() {
                $ctrl.inputText = '';
                $ctrl.result = [];
                $ctrl.loading = false;
                $ctrl.isOpen = false;
                // Note: Scope the keyboard handler to just this component
                // Else, you might collide with global keyboard handlers
                documentElem.bind('keydown', onKeyDown);
            }

            function $onDestroy() {
                documentElem.unbind('keydown', onKeyDown);
            }

            function clickHandler($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $ctrl.isOpen = $ctrl.result.length > 0;
            }

            function performSearch() {
                if ($ctrl.inputText.length < 1) {
                    $ctrl.result = [];
                    return;
                }

                $ctrl.loading = true;
                const { key, val } = parseForKeyValue($ctrl.inputText);

                return catalogQuickActionsDataProvider
                    .catalogSearch(key, val)
                    .then((response) => {
                        $ctrl.result = response;
                        if ($ctrl.result.length > 0) {
                            $ctrl.selectedIndex = 0;
                            $ctrl.isOpen = true;
                        } else {
                            $ctrl.selectedIndex = -1;
                            $ctrl.isOpen = false;
                        }
                    })
                    .finally(() => {
                        $scope.$applyAsync();
                        $ctrl.loading = false;
                    });
            }

            function mouseEnter(idx) {
                $ctrl.selectedIndex = idx;
            }

            // Note: while the bootstrap documentation recommends `keyboard-nav`
            // attribute to enable keyboard navigations on dropdowns, it does not
            // work. Hence, we have to settle for keyboard handlers.
            function onKeyDown(event) {
                if (!$ctrl.result || !$ctrl.result.length) {
                    $ctrl.selectedIndex = -1;
                    return;
                }
                switch (event.keyCode) {
                    case 38: //up
                        $ctrl.selectedIndex -= 1;
                        if ($ctrl.selectedIndex < 0) {
                            $ctrl.selectedIndex = $ctrl.result.length - 1;
                        }
                        quickActionsUtil.tryToScrollToItem(
                            $ctrl.selectedIndex,
                            $ctrl.itemClass,
                            $ctrl.containerClass
                        );
                        break;
                    case 40: //down
                        $ctrl.selectedIndex += 1;
                        if ($ctrl.selectedIndex >= $ctrl.result.length) {
                            $ctrl.selectedIndex = 0;
                        }
                        quickActionsUtil.tryToScrollToItem(
                            $ctrl.selectedIndex,
                            $ctrl.itemClass,
                            $ctrl.containerClass
                        );
                        break;
                    case 13: {
                        //enter
                        quickActionsUtil.navigateToItem($ctrl.result[$ctrl.selectedIndex]);
                        break;
                    }
                    default:
                        break;
                }
                $scope.$applyAsync();
            }
        },
    ],
};
