import {
    keyValuePairToQuery,
    filterReWritable,
    sanitizeTerm,
    sanitizeTermUsingSpaces,
} from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';

export default [
    '$log',
    'plotToSignalflowV2',
    function ($log, plotToSignalflowV2) {
        const PROPERTY_NAME_PATTERN = '([^:^\\s]+)';

        function assignQueryToFilter(filter) {
            const notQueryPrefix = filter.NOT ? 'NOT ' : '';
            const useSpaces = true;

            const sourceQuery =
                filter.property + ':' + generateTermsForQuery(filter.propertyValue, useSpaces);
            filter.query = notQueryPrefix + sourceQuery;

            switch (filter.property) {
                case 'sf_source':
                    filter.value = filter.propertyValue;
                    break;
                case 'sf_tags':
                    filter.value = filter.property
                        .replace('sf_tags:', '')
                        .replace('(', '')
                        .replace(')', '');
                    break;
                default:
                    filter.value = sourceQuery.match(/^sf_/)
                        ? capitalize(sourceQuery.replace(/^sf_/, ''))
                        : sourceQuery;
                    break;
            }

            function capitalize(s) {
                return s[0].toUpperCase() + s.slice(1);
            }
        }

        function isValidFilter(filter) {
            const filterRegex = new RegExp(PROPERTY_NAME_PATTERN + ':(\\S+)');
            return filter.match(filterRegex);
        }

        // Return object containing property options and property name with option
        // symbols removed
        this.extractFilterOptions = function (filterStr) {
            const options = {};
            const filterRegex = new RegExp(PROPERTY_NAME_PATTERN + ':(\\S*)');
            const match = filterStr.match(filterRegex);
            let property = match[1];

            options.applyIfExists = !!property.match(/^~/);
            property = property.replace(/^~/, '');
            options.NOT = !!property.match(/^!/);
            property = property.replace(/^!/, '');

            options.property = property;
            return options;
        };

        function filterStrToObject(filterStr) {
            const filterObj = this.extractFilterOptions(filterStr);
            let propertyValue = this.unflattenPropertyValue(
                filterStr.split(':').slice(1).join(':')
            );

            // convert numbers and booleans to string
            if (
                typeof propertyValue !== 'undefined' &&
                (typeof propertyValue === 'number' || typeof propertyValue === 'boolean')
            ) {
                propertyValue = propertyValue.toString();
            }
            filterObj.propertyValue = propertyValue;

            this.assignIconClassToFilter(filterObj);
            assignQueryToFilter(filterObj);
            this.assignTypeToFilter(filterObj);

            return filterObj;
        }

        this.filterStrToObject = filterStrToObject;

        this.assignIconClassToFilter = function (filter) {
            switch (filter.property) {
                case 'sf_source':
                    filter.iconClass = 'icon-timeseries';
                    break;
                case 'sf_tags':
                    filter.iconClass = 'icon-tag';
                    break;
                default:
                    filter.iconClass = 'icon-properties';
                    break;
            }
        };

        this.assignTypeToFilter = function (filter) {
            switch (filter.property) {
                case 'sf_source':
                    filter.type = 'name';
                    break;
                case 'sf_tags':
                    filter.type = 'tag';
                    break;
                default:
                    filter.type = 'property';
                    break;
            }
        };

        this.findPositivePropertyFilter = function (newfilter, existingfilters) {
            let index = -1;
            existingfilters.forEach(function (f, i) {
                if (f.property === newfilter.property && !newfilter.NOT && !f.NOT) {
                    index = i;
                }
            });
            return index;
        };

        this.getSourceFilters = function (sources) {
            const sourceFilter = sources.filter(isValidFilter).map(filterStrToObject.bind(this));

            return sourceFilter;
        };

        function generateTermsForQuery(propertyValue, useSpaces) {
            const sanitize = useSpaces ? sanitizeTermUsingSpaces : sanitizeTerm;
            if (angular.isArray(propertyValue)) {
                const terms = propertyValue
                    .map(function (value) {
                        return sanitize(value);
                    })
                    .filter(function (value) {
                        return value;
                    });
                if (!terms.length) {
                    return sanitize(' ');
                } else {
                    return '(' + terms.join(' OR ') + ')';
                }
            } else {
                return sanitize(propertyValue);
            }
        }

        this.generateTermsForQuery = generateTermsForQuery;

        this.translateSourceFilterObjects = function (filterList, onlyIfPropertyExists) {
            if (!angular.isArray(filterList)) {
                return '';
            }
            const unescapedFilters = filterList.filter(function (srcFilter) {
                return srcFilter.unescaped;
            });
            const validFilters = filterList.filter(function (srcFilter) {
                return (
                    !srcFilter.unescaped &&
                    (srcFilter.query ||
                        (srcFilter.key && srcFilter.value) ||
                        (srcFilter.property !== undefined && srcFilter.propertyValue !== undefined))
                );
            });
            const filtersLength = validFilters.length + unescapedFilters.length;
            if (filtersLength !== filterList.length) {
                $log.error(filterList.length - filtersLength + ' filter did not possess a query.');
            }
            let queries = validFilters.map(function (srcFilter) {
                let query = '';
                let property, value;
                if (srcFilter.property !== undefined && srcFilter.propertyValue !== undefined) {
                    //new escapeable format
                    let not = srcFilter.NOT;
                    property = srcFilter.property;
                    value = srcFilter.propertyValue;

                    //handle legacy filter situations where property was being abused with lucene syntax(!).
                    if (property.indexOf('!') === 0) {
                        property = property.substring(1);
                        not = true;
                    }

                    const rewritten = filterReWritable(property, value, not);
                    if (
                        rewritten &&
                        !srcFilter.applyIfExists &&
                        !srcFilter.optional &&
                        !onlyIfPropertyExists
                    ) {
                        query = rewritten;
                    } else {
                        value = generateTermsForQuery(value);
                        property = generateTermsForQuery(property);
                        query = (not ? 'NOT ' : '') + property + ':' + value;
                        if (srcFilter.applyIfExists || srcFilter.optional || onlyIfPropertyExists) {
                            query = '(NOT _exists_:' + property + ') OR ' + query;
                        }
                    }
                } else {
                    //legacy prebuilt query format
                    query = srcFilter.query;
                    if (srcFilter.query.indexOf(':') > -1) {
                        const splitQuery = query.split(':');
                        property = splitQuery.splice(0, 1);
                        value = splitQuery.join(':');
                        query = keyValuePairToQuery(property, value);
                    }
                }
                return '(' + query + ')';
            });
            queries = queries.concat(
                unescapedFilters.map(function (srcFilter) {
                    return (
                        '(' +
                        (srcFilter.NOT ? 'NOT ' : '') +
                        (srcFilter.query || srcFilter.property + ':' + srcFilter.propertyValue) +
                        ')'
                    );
                })
            );
            return queries.join(' AND ');
        };

        this.translateSourceFilterObjectsToFilterBlock = function (filters) {
            // remove empty value filters because they turn into *, which changes the
            // meaning(must have any value of the property)
            const nonEmptyValueFilters = filters.filter((filter) => {
                return filter.propertyValue && filter.propertyValue.length;
            });
            return plotToSignalflowV2.filters(nonEmptyValueFilters);
        };

        this.unflattenPropertyValue = function (value) {
            let unflattened;
            if (value.charAt(0) === '{' || value.charAt(0) === '[') {
                try {
                    unflattened = JSON.parse(value);
                } catch (e) {
                    // not json
                    unflattened = value;
                }
            } else {
                unflattened = value;
            }
            if (angular.isDefined(unflattened) && !angular.isArray(unflattened)) {
                return unflattened.toString();
            } else {
                return unflattened;
            }
        };

        this.flattenPropertyValue = function (value) {
            if (angular.isArray(value)) {
                return angular.toJson(value);
            } else {
                return value;
            }
        };

        /**
         * Utility to return a string of all filter names from a list of filter values
         * eg. [['a', 'b'], 'c'] will be returned as 'a, b, c'
         */
        this.getFilterNamesString = function (valuesList, delimiter) {
            if (!delimiter) delimiter = ', ';
            return valuesList
                .map(function (f) {
                    if (angular.isArray(f)) return f.join(delimiter);
                    else return f;
                })
                .join(delimiter);
        };

        /**
         * Returns a shortened list of filter names from a list
         */
        this.getDisplayNameForFilters = function (filters, maxStringLength) {
            if (!angular.isArray(filters)) return filters || '';
            const propertyValue = angular.copy(filters);
            const maxLen = maxStringLength || 20;
            let maxIndex = 0;
            let runningLen = 0;
            propertyValue.forEach(function (p, i) {
                runningLen += p.length;
                if (runningLen <= maxLen) {
                    maxIndex = i;
                }
            });
            let reducedStr = propertyValue.splice(0, maxIndex + 1).join(', ');
            if (propertyValue.length) {
                reducedStr += ', ' + propertyValue.length + ' more';
            }
            return reducedStr;
        };
    },
];
