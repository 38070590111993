import { chartNav } from './chartV2Nav';

angular
    .module('signalview.chartV2', [
        'signalflowv2',
        'signalview.chartbuilder',
        'visualizationOptions',
        'signalflowV2ApiUtils.v2Chart',
        'signalview.snapshotSharing',
        'signalview.TOOLTIPS',
    ])
    .component('chartNav', chartNav);
