export const NONE_OPTION = 'None';

export function isGroupBySelected(chartModel) {
    const groupBy = chartModel.sf_uiModel.chartconfig.groupBy;
    return groupBy && Array.isArray(groupBy) && groupBy[0] !== NONE_OPTION;
}

export function moveGroupByColumnToFirstPosition(chartModel, colDefs, keyGetter) {
    const groupByCol = chartModel.sf_uiModel.chartconfig.groupBy[0];
    colDefs.forEach((col, i) => {
        if (!col) {
            return;
        }
        if (keyGetter(col) === groupByCol) {
            colDefs.splice(i, 1);
            if (typeof col !== 'string') {
                col.draggable = false;
            }
            colDefs.unshift(col);
        }
    });
}
