angular
    .module('signalview.snapshot')

    .service('snapshotChartSaveService', [
        'sfxModal',
        '$location',
        'snapshotEditsService',
        'dashboardV2Service',
        'dashboardUtil',
        function (sfxModal, $location, snapshotEditsService, dashboardV2Service, dashboardUtil) {
            function saveChart(chartModel, dashboardModel, snapshot, redirectToDashboard) {
                const chart = angular.copy(chartModel);
                delete chart.sf_modelVersion;
                delete chart.sf_flowVersion;
                let dashboard = angular.copy(dashboardModel);
                const charts = snapshot.payload.charts;
                if (snapshot.chartId === -1) {
                    dashboardV2Service.addTransientChart(dashboard, chart);
                    charts.push(chart);
                } else {
                    charts.forEach(function (c, i) {
                        if (c.id === chart.id) {
                            charts[i] = chart;
                        }
                    });
                }
                dashboard.filters = dashboardUtil.getDashboardUrlFilterState();
                dashboard = dashboardV2Service.uiDashboardToAPI(dashboard);

                const modalInstance = sfxModal.open({
                    template: '<div><i class="busy-spinner-light"></i></div>',
                    windowClass: 'full-screen-busy-spinner',
                    backdrop: 'static',
                    keyboard: false,
                });

                return snapshotEditsService
                    .saveDashboardEdits(dashboard, charts, false, snapshot)
                    .then(function (saved) {
                        if (redirectToDashboard) {
                            $location.path('/temp/dashboard/' + saved.id);
                        }
                        return saved;
                    })
                    .finally(function () {
                        modalInstance.close();
                    });
            }
            return {
                saveChart: saveChart,
            };
        },
    ]);
