export default [
    'dashboardVariablesService',
    'sourceFilterService',
    'timepickerUtils',
    'urlOverridesService',
    function (
        dashboardVariablesService,
        sourceFilterService,
        timepickerUtils,
        urlOverridesService
    ) {
        const getRUMIndexParams = function (chart, filterAlias) {
            const environments = [];
            const apps = [];
            const product = [];

            const mapValueToArray = (value) =>
                (angular.isArray(value) ? value : [value]).filter(Boolean);

            // Retrieve time info from chart options
            const chartStartTime =
                chart && chart.options && chart.options.time && chart.options.time.range
                    ? timepickerUtils.msToRelative(chart.options.time.range)
                    : '-15m';

            // retrieve time info from time picker or chart options
            const globalTimePicker = urlOverridesService.getGlobalTimePicker();
            const urlPicker =
                timepickerUtils.getURLParamStringForTimePicker(globalTimePicker) ||
                `startTime=${chartStartTime}`;

            // retrieve tracing info from dashboard variables and overrides
            const variables = dashboardVariablesService.applyVariableOverridesToVariableModel(
                filterAlias || []
            );
            const overrides = sourceFilterService.getSourceFilters(
                urlOverridesService.getSourceOverride() || []
            );

            variables.concat(overrides).forEach(({ property, propertyValue, value }) => {
                const serializedValues = propertyValue
                    ? mapValueToArray(propertyValue)
                    : mapValueToArray(value);

                if (property === 'sf_environment') {
                    environments.push(...serializedValues);
                } else if (property === 'app') {
                    apps.push(...serializedValues);
                } else if (property === 'sf_product') {
                    product.push(...serializedValues);
                }
            });

            return getIndexParams(urlPicker, environments, apps, product);
        };

        /**
         * Conversion of TagInput to APM2 TagFilterInput format. Assumes all tag-value pairs use an "IN" operation.
         */
        function convertToRUMTagFilterInput(tag) {
            return {
                tag: tag.tagName,
                operation: 'IN',
                values: tag.values,
            };
        }

        function getIndexParams(urlPicker, environments, apps, product) {
            const queryParams = [];

            if (environments.length > 0 && environments[0] !== '*') {
                queryParams.push(
                    convertToRUMTagFilterInput({ tagName: 'sf_environment', values: environments })
                );
            }

            if (apps.length > 0 && apps[0] !== '*') {
                queryParams.push(convertToRUMTagFilterInput({ tagName: 'app', values: apps }));
            }

            if (product.length > 0 && product[0] !== '*') {
                queryParams.push(
                    convertToRUMTagFilterInput({ tagName: 'sf_product', values: product })
                );
            }

            return `filters=${encodeURIComponent(JSON.stringify(queryParams))}&${urlPicker}`;
        }

        return {
            getRUMIndexParams,
        };
    },
];
