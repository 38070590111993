angular
    .module('signalboost', [
        'restangular',
        'sfx.security',
        'sfUtil',
        'sfx.debug',
        'signalview.analytics',
        'signalflowv2',
        'sfx.api.v2',
    ])

    .constant('INACTIVE_TAG', 'sf_tags:(inactive)')

    .config([
        '$httpProvider',
        '$compileProvider',
        'RestangularProvider',
        'API_URL',
        function ($httpProvider, $compileProvider, restProvider, API_URL) {
            $compileProvider.aHrefSanitizationWhitelist(
                /^\s*(https?|ftp|mailto|chrome-extension|blob):/
            );

            // Enable CORS
            $httpProvider.defaults.useXDomain = true;
            $httpProvider.defaults.withCredentials = true;
            delete $httpProvider.defaults.headers.common['X-Requested-With'];

            // TODO refactor this as a raw provider
            restProvider.setBaseUrl(API_URL + '/v1');
            restProvider.setRestangularFields({
                id: 'sf_id',
            });

            restProvider.addElementTransformer('user', function (user) {
                return user;
            });
            restProvider.addElementTransformer('organization', function (org) {
                return org;
            });
        },
    ])

    .run([
        'signalboostBuilder',
        function (signalboostBuilder) {
            signalboostBuilder.build();
        },
    ]);
