import templateUrl from './plotEditor.tpl.html';

angular.module('signalview.chartbuilder').directive('plotEditor', [
    'userAnalytics',
    function (userAnalytics) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                model: '=',
                passedUtils: '=',
                variables: '=',
                sourceOverrides: '=',
                sourceListUpdated: '=',
                isDetectorPlot: '=',
                sharedChartState: '=',
                isV2Chart: '@?',
                getCurrentTimeRange: '<?',
            },
            link: function ($scope) {
                $scope.editMode = $scope.sharedChartState.editMode;
                $scope.chartMode = $scope.model.sf_uiModel.chartMode;
                $scope.altMode = false;

                function updateVisibility() {
                    $scope.allVisible = $scope.model.sf_uiModel.allPlots.every((plot) => {
                        return (
                            !plot.invisible || plot.transient || plot.name === $scope.model.sf_id
                        );
                    });
                }

                updateVisibility();

                $scope.$on('chartModeChanged', function (evt, mode) {
                    $scope.chartMode = mode;
                });

                // Set the chart options' timezone
                $scope.$on('setCalendarWindowTimezoneFromAnalytics', function (evt, data) {
                    $scope.model.sf_uiModel.chartconfig.timezone = data;
                });

                $scope.$on('update plot state', updateVisibility);

                // Received from a plotdisplay directive
                $scope.$on(
                    'plot alt mode apply',
                    function (evt, altModeDisplayedKey, altModeOn = false) {
                        $scope.altMode = altModeOn;
                    }
                );

                function toggleAllVisibility(invisibility) {
                    $scope.model.sf_uiModel.allPlots.forEach((plot) => {
                        if (!plot.transient) {
                            plot.invisible = invisibility;
                        }
                    });
                }

                $scope.toggleAll = function (makeAllVisible) {
                    toggleAllVisibility(!makeAllVisible);
                    $scope.allVisible = makeAllVisible;
                };

                $scope.requestFallbackMode = function () {
                    $scope.$emit('requestFallbackMode');
                };

                $scope.sortOptions = {
                    axis: 'y',
                    items: 'li.plotdisplay-container:not(.disable-reorder)',
                    handle: '.icon-drag',
                    start: function (e, ui) {
                        angular.element(ui.item).addClass('plot-reorder-drag-border');
                    },
                    stop: function (e, ui) {
                        angular.element(ui.item).removeClass('plot-reorder-drag-border');
                        angular.element('.ui-sortable-placeholder').remove();
                    },
                    update: function () {
                        userAnalytics.event('chart', 'plot-reorder');
                    },
                    delay: 150,
                };

                $scope.$on('map-plot-type', function (evt, label, type) {
                    const chartconfig = $scope.model.sf_uiModel.chartconfig;
                    // If this label was used for anything already, clear that.
                    if (chartconfig.valueLabel === label) {
                        chartconfig.valueLabel = null;
                    }
                    if (chartconfig.latitudeLabel === label) {
                        chartconfig.latitudeLabel = null;
                    }
                    if (chartconfig.longitudeLabel === label) {
                        chartconfig.longitudeLabel = null;
                    }
                    switch (type) {
                        case 'value':
                            chartconfig.valueLabel = label;
                            break;
                        case 'latitude':
                            chartconfig.latitudeLabel = label;
                            break;
                        case 'longitude':
                            chartconfig.longitudeLabel = label;
                            break;
                    }
                });
            },
        };
    },
]);
