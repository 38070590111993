import customerSupportTemplateUrl from './customerSupport.tpl.html';

export default [
    'sfxModal',
    function (sfxModal) {
        return {
            openModal,
        };

        // Open customer support modal
        function openModal() {
            sfxModal.open({
                templateUrl: customerSupportTemplateUrl,
                controller: 'SupportController',
            });
        }
    },
];
