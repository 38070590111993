import NODE_LIST_CONFIG from '../../../app/kubeNavigator/configs/nodes/nodeListconfig';
import WORKLOADS_LIST_CONFIG from '../../../app/kubeNavigator/configs/workloads/workloadsListConfig';
import kubeNavigatorTemplateUrl from './kubeNavigatorTemplate.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';
import qs from 'query-string';

export default {
    templateUrl: kubeNavigatorTemplateUrl,
    controller: [
        '$scope',
        'featureEnabled',
        'urlOverridesService',
        'dashboardVariablesService',
        'dashboardVariableUtils',
        'routeParameterService',
        'CHART_DISPLAY_EVENTS',
        'SIDEBAR_EVENTS',
        'KUBE_PROPERTY_TO_VARIABLE_ALIAS',
        'URL_PARAMETER_CONSTANTS',
        'ANALYZER_EVENT',
        'infoSidebarUtil',
        function (
            $scope,
            featureEnabled,
            urlOverridesService,
            dashboardVariablesService,
            dashboardVariableUtils,
            routeParameterService,
            CHART_DISPLAY_EVENTS,
            SIDEBAR_EVENTS,
            KUBE_PROPERTY_TO_VARIABLE_ALIAS,
            URL_PARAMETER_CONSTANTS,
            ANALYZER_EVENT,
            infoSidebarUtil
        ) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;

            if (!ngRoute.params.selectedTab) {
                ngRoute.history.replace({
                    search: qs.stringify({ selectedTab: 'map' }),
                });
            }

            const workloadsListConfig = angular.copy(WORKLOADS_LIST_CONFIG);
            $scope.tabs = {
                map: {
                    displayName: 'Map',
                    key: 'map',
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'kubernetes.node_ready',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster Name', false, false),
                    ],
                    enableSourceOverrides: true,
                },
                nodeList: {
                    displayName: 'Nodes',
                    key: 'nodes',
                    searchable: {
                        placeholder: 'Search nodes',
                    },
                    configs: {
                        nodeListConfig: NODE_LIST_CONFIG,
                    },
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'kubernetes.node_ready',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster Name', true),
                    ],
                },
                workloadsList: {
                    displayName: 'Workloads',
                    key: 'workloads',
                    searchable: {
                        placeholder: 'Search workloads',
                    },
                    configs: {
                        workloadsListConfig,
                    },
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'kubernetes.pod_phase',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster', true, true),
                        getPredefinedFilter('kubernetes_namespace', 'Namespace', false, false),
                        getPredefinedFilter('kubernetes_workload', 'Workload Type', false, false),
                    ],
                },
                nodeDetail: {
                    displayName: 'Node Detail',
                    key: 'nodeDetail',
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'kubernetes.node_ready',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster', true),
                        getPredefinedFilter('kubernetes_node', 'Node Name'),
                    ],
                },
                workloadDetail: {
                    displayName: 'Workload Detail',
                    key: 'workloadDetail',
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'kubernetes.pod_phase',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster', true),
                        getPredefinedFilter('kubernetes_namespace', 'Namespace'),
                        getPredefinedFilter('kubernetes_workload_name', 'Workload'),
                    ],
                },
                podDetail: {
                    displayName: 'Pod Detail',
                    key: 'podDetail',
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'kubernetes.pod_phase',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster', true),
                        getPredefinedFilter('kubernetes_node', 'Node Name'),
                        getPredefinedFilter(
                            'kubernetes_pod_name',
                            'Pod Name',
                            false,
                            true,
                            false,
                            true
                        ),
                    ],
                },
                containerDetail: {
                    displayName: 'Container Detail',
                    key: 'containerDetail',
                    variableSuggestionModel: getChartSuggestionJsonForMetrics([
                        'container_cpu_utilization',
                    ]),
                    predefinedFilters: [
                        getPredefinedFilter('kubernetes_cluster', 'Cluster', true),
                        getPredefinedFilter('container_id', 'Container ID'),
                    ],
                },
            };

            $scope.setFilterSuggestionCallback = setFilterSuggestionCallback;
            $scope.onHideSidebar = onHideSidebar;
            $scope.getTabUrl = getTabUrl;
            // always try to show the sidebar
            $scope.showSidebar = true;

            function updatePredefinedFilterState() {
                if (!$scope.selectedTab.predefinedFilters) {
                    return;
                }

                const variables = (dashboardVariablesService.getVariablesFromUrl() || []).filter(
                    (v) => v.value
                );
                const sourceFilters = urlOverridesService.getSourceFilterOverrideList() || [];
                const mergedFilters = dashboardVariablesService.cleanAndMergeVariablesToFilters(
                    variables,
                    sourceFilters
                );

                $scope.selectedTab.predefinedFilters.forEach((predefinedFilter) => {
                    const property = predefinedFilter.property;
                    let value = [];
                    mergedFilters.forEach((filter) => {
                        if (property === filter.property && filter.propertyValue) {
                            value = value.concat(filter.propertyValue);
                        }
                    });

                    predefinedFilter.value = predefinedFilter.propertyValue = Array.from(
                        new Set(value)
                    ); // dedupe and add
                });
            }

            function getPredefinedFilter(
                property,
                description,
                autoSelect = false,
                required = true,
                applyIfExists = false,
                replaceOnly = false,
                restricted = false,
                preferredSuggestions = []
            ) {
                const alias = KUBE_PROPERTY_TO_VARIABLE_ALIAS[property] || property;
                return {
                    alias,
                    property,
                    description,
                    applyIfExists,
                    replaceOnly,
                    restricted,
                    required,
                    preferredSuggestions,
                    autoSelect,
                };
            }

            function getChartSuggestionJsonForMetrics(metrics) {
                return metrics.map((metric) => {
                    return {
                        options: { type: 'TimeSeriesChart' },
                        programText: `A = data('${metric}', filter=filter('kubernetes_cluster', '*')).publish(label='A')`,
                    };
                });
            }

            function setFilterSuggestionCallback(callback) {
                $scope.getFilterSuggestionQuery = callback;
            }

            function onRouteUpdate() {
                updatePredefinedFilterState();

                // Keep only variables required by the page
                $scope.variables = $scope.selectedTab.predefinedFilters
                    ? $scope.selectedTab.predefinedFilters.filter((v) =>
                          angular.isArray(v.value) ? v.value.length : v.value
                      )
                    : [];

                $scope.sourceFilters = getCleanSourceFilters();

                $scope.mergedFilters = dashboardVariablesService.cleanAndMergeVariablesToFilters(
                    $scope.variables,
                    $scope.sourceFilters
                );

                // Update with sanitized filters/variables set
                dashboardVariablesService.setVariablesOverride($scope.variables);
                urlOverridesService.setSourceFilterOverrideList($scope.sourceFilters);
                urlOverridesService.useReplaceForLastChange();
            }

            function getCleanSourceFilters() {
                if (!$scope.selectedTab.enableSourceOverrides) {
                    // Keep filters only if asked for by the page
                    return [];
                }

                const sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
                const propsAsVariables = $scope.selectedTab.predefinedFilters.map(
                    (f) => f.property
                );
                return sourceOverrides.filter(
                    (f) => f.NOT || !propsAsVariables.includes(f.property)
                );
            }

            function getTabUrl(key) {
                const defaultUrl = '#/k8s/' + key;
                const variableStrings = urlOverridesService.getSearchParam(
                    URL_PARAMETER_CONSTANTS.variablesOverride
                );
                if (!variableStrings) {
                    return defaultUrl;
                }

                const filterProperties = new Set();
                const predefinedFilters = $scope.tabs[key].predefinedFilters;
                predefinedFilters.forEach((filter) => {
                    filterProperties.add(filter.property);
                });

                const variables = variableStrings.filter((v) => {
                    const variable = dashboardVariableUtils.getVariableFromStr(v);
                    return filterProperties.has(variable.property);
                });

                if (!variables.length) {
                    return defaultUrl;
                }

                return defaultUrl + '?variables[]=' + variables.join('&variables[]=');
            }

            function bindEvents() {
                const routeWatch = routeParameterService.registerRouteWatchGroup(
                    [
                        URL_PARAMETER_CONSTANTS.sourceOverride,
                        URL_PARAMETER_CONSTANTS.variablesOverride,
                    ],
                    onRouteUpdate
                );

                $scope.$on('$destroy', () => routeWatch());
            }

            function initialize() {
                if (featureEnabled('apm2')) {
                    workloadsListConfig.COLUMNS.push({
                        aggregate: null,
                        displayName: 'Service',
                        format: null,
                        metric: null,
                        property: 'sf_k8s_service',
                        deepLink: true,
                    });
                }

                $scope.selectedTab =
                    ngRoute.params.selectedTab in $scope.tabs
                        ? $scope.tabs[ngRoute.params.selectedTab]
                        : $scope.tabs.map;
                bindEvents();
                onRouteUpdate();
            }

            $scope.$on(SIDEBAR_EVENTS.OPEN_SIDEBAR, function () {
                urlOverridesService.hideInfoSidebar(false);
                $scope.showSidebar = true;
                $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
            });

            $scope.$on(
                ANALYZER_EVENT.SIDEBAR_LOOK_FOR_MATCHING_CHILD,
                function (event, propertyValue, result, requiredFilters) {
                    $scope.$broadcast(
                        ANALYZER_EVENT.CLUSTER_MAP_LOOK_FOR_MATCHING_CHILD,
                        propertyValue,
                        result,
                        requiredFilters
                    );
                }
            );

            $scope.$on(
                ANALYZER_EVENT.CLUSTER_MAP_FOUND_MATCHING_CHILD,
                function (event, child, requiredFilters, key) {
                    $scope.$broadcast(
                        ANALYZER_EVENT.SIDEBAR_FOUND_MATCHING_CHILD,
                        child,
                        requiredFilters,
                        key
                    );
                }
            );

            function onHideSidebar(isSidebarOpen) {
                if (!isSidebarOpen) {
                    $scope.showSidebar = false;
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);

                    const onClusterMap = ngRoute.params.selectedTab === 'map';
                    if (!onClusterMap) {
                        urlOverridesService.clearInfoSidebarSources();
                        infoSidebarUtil.forceSidebarDefaultTabSelection();
                    }
                    urlOverridesService.setMapSelection(null);
                }
            }
        },
    ],
};
