angular.module('signalview.dashboard').factory('aclDashboardCloner', [
    '$location',
    'sfxModal',
    '$log',
    'featureEnabled',
    function ($location, sfxModal, $log, featureEnabled) {
        return function clone(dashboard, chartsList, defaultDashboardGroup, routeChangeCallback) {
            return sfxModal
                .open({
                    // declare-used-dependency-to-linter::saveAclDashboardModal
                    component: 'saveAclDashboardModal',
                    resolve: {
                        params: () => ({
                            dashboard,
                            defaultDashboardGroupId: defaultDashboardGroup?.id,
                            chartsList,
                        }),
                    },
                    backdrop: 'static',
                    keyboard: true,
                })
                .result.then(({ dashboardId, dashboardGroupId }) => {
                    if ('function' === typeof routeChangeCallback) {
                        routeChangeCallback();
                    }

                    const groupIdParam = featureEnabled('dashboardViews')
                        ? `?groupId=${dashboardGroupId}`
                        : '';
                    $location.url(`/dashboard/${dashboardId}` + groupIdParam);
                })
                .catch((error) => error && $log.error(error));
        };
    },
]);
