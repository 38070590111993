import templateUrl from './webhookNotifyBlock.tpl.html';
import webhookNotifyEditModalTemplateUrl from './webhookNotifyEditModal.tpl.html';

angular.module('signalview.detector.notification').directive('webhookNotifyBlock', [
    '$timeout',
    'sfxModal',
    'notificationsUtil',
    function ($timeout, sfxModal, notificationsUtil) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                rule: '=?',
                credentials: '=?',
                remove: '&onRemove',
                readOnly: '=?',
                doneEdit: '&onFinishEdit',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.edit = false;
                    let modalPromise;

                    $scope.finishEdit = function () {
                        if (
                            !modalPromise &&
                            !$scope.model.url &&
                            !$scope.model.credentialId &&
                            $scope.remove
                        ) {
                            $scope.remove();
                        }
                        $scope.edit = false;
                        if ($scope.doneEdit) $scope.doneEdit();
                    };

                    $scope.cancelEdit = function () {
                        $scope.edit = false;
                    };

                    // Default to webhook schema version 2 on edit.
                    $scope.model.schemaVersion = 2;

                    $scope.onEdit = function () {
                        $scope.edit = true;

                        $timeout(function () {
                            $scope.dropdownOpen = true;
                        });
                    };

                    $scope.credentialType = notificationsUtil.getIntegrationNameForV1orV2(
                        $scope.model.type
                    );

                    $scope.setCredential = function (credential) {
                        $scope.model.credentialId = credential.id;
                        $scope.model.credentialName = credential.name;
                        $scope.credentialDisabled = !credential.enabled;
                    };

                    $scope.customWebhook = function () {
                        modalPromise = sfxModal.open({
                            templateUrl: webhookNotifyEditModalTemplateUrl,
                            scope: $scope,
                            animation: false,
                            windowClass: 'webhook-notify-edit-modal',
                            backdrop: 'static',
                            keyboard: false,
                        }).result;

                        modalPromise.finally(function () {
                            modalPromise = null;
                            $scope.finishEdit();
                        });
                    };

                    if (!$scope.model.url && !$scope.model.credentialId) {
                        $scope.onEdit();
                    } else if ($scope.model.credentialId) {
                        // If find a credentialId but not a credential, credential has been deleted
                        $scope.credentialDeleted = !$scope.credentials.some(function (credential) {
                            if (credential.id === $scope.model.credentialId) {
                                $scope.setCredential(credential);
                                return true;
                            }
                        });
                    }
                },
            ],
        };
    },
]);
