'use strict';

angular.module('sfx.ui').directive('duration', [
    'parseDuration',
    function (parseDuration) {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                if (!ctrl) return;
                ctrl.$validators.duration = function (value) {
                    return ctrl.$isEmpty(value) || !isNaN(parseDuration(value));
                };

                attrs.$observe('duration', function () {
                    ctrl.$validate();
                });
            },
        };
    },
]);
