angular.module('signalview.detector').service('signalTypeService', [
    'APM_METRIC_TYPE',
    function (APM_METRIC_TYPE) {
        // metric
        const ERROR_RATE_METRIC = 'count';
        const LATENCY_METRIC = 'duration.ns';

        // service/endpoints
        const SERVICE_ENDPOINT = {
            name: 'SERVICE_ENDPOINT',
            errorRateMetric: `spans.${ERROR_RATE_METRIC}`,
            latencyMetric: `spans.${LATENCY_METRIC}`,
            errorRateOption: {
                displayName: 'Service Error Rate',
                value: APM_METRIC_TYPE.SERVICE_ERRORS,
            },
            latencyOption: {
                displayName: 'Service Latency',
                value: APM_METRIC_TYPE.SERVICE_LATENCY,
            },
            percentileToMetrics: {
                99: new Set([`spans.${LATENCY_METRIC}.p99`]),
                90: new Set([`spans.${LATENCY_METRIC}.p90`]),
                50: new Set([`spans.${LATENCY_METRIC}.median`]),
            },
            apmMetricGroup: [APM_METRIC_TYPE.SERVICE_ERRORS, APM_METRIC_TYPE.SERVICE_LATENCY],
            headingText: 'Service/Endpoint',
        };

        // business workflow
        const WORKFLOW = {
            name: 'WORKFLOW',
            errorRateMetric: `workflows.${ERROR_RATE_METRIC}`,
            latencyMetric: `workflows.${LATENCY_METRIC}`,
            errorRateOption: {
                displayName: 'Workflow Error Rate',
                value: APM_METRIC_TYPE.WORKFLOW_ERROR_RATE,
            },
            latencyOption: {
                displayName: 'Workflow Duration',
                value: APM_METRIC_TYPE.WORKFLOW_LATENCY,
            },
            percentileToMetrics: {
                99: new Set([`workflows.${LATENCY_METRIC}.p99`]),
                90: new Set([`workflows.${LATENCY_METRIC}.p90`]),
                50: new Set([`workflows.${LATENCY_METRIC}.median`]),
            },
            apmMetricGroup: [APM_METRIC_TYPE.WORKFLOW_ERROR_RATE, APM_METRIC_TYPE.WORKFLOW_LATENCY],
            headingText: 'Business Workflow',
        };

        const METRIC_VALUE_TO_SIGNAL_TYPE_ENTITY = {
            [SERVICE_ENDPOINT.errorRateMetric]: SERVICE_ENDPOINT,
            [SERVICE_ENDPOINT.latencyMetric]: SERVICE_ENDPOINT,
            [WORKFLOW.errorRateMetric]: WORKFLOW,
            [WORKFLOW.latencyMetric]: WORKFLOW,
        };

        const METRIC_TYPE_TO_SIGNAL_TYPE_ENTITY = {
            [APM_METRIC_TYPE.SERVICE_ERRORS]: SERVICE_ENDPOINT,
            [APM_METRIC_TYPE.SERVICE_LATENCY]: SERVICE_ENDPOINT,
            [APM_METRIC_TYPE.WORKFLOW_ERROR_RATE]: WORKFLOW,
            [APM_METRIC_TYPE.WORKFLOW_LATENCY]: WORKFLOW,
        };

        const APM_METRIC_TYPE_MAP = {
            [APM_METRIC_TYPE.SERVICE_ERRORS]: {
                metricValue: SERVICE_ENDPOINT.errorRateMetric,
                signalTypeEntity: SERVICE_ENDPOINT,
                textLabel: SERVICE_ENDPOINT.errorRateOption.displayName,
            },
            [APM_METRIC_TYPE.SERVICE_LATENCY]: {
                metricValue: SERVICE_ENDPOINT.latencyMetric,
                signalTypeEntity: SERVICE_ENDPOINT,
                textLabel: SERVICE_ENDPOINT.latencyOption.displayName,
            },
            [APM_METRIC_TYPE.WORKFLOW_ERROR_RATE]: {
                metricValue: WORKFLOW.errorRateMetric,
                signalTypeEntity: WORKFLOW,
                textLabel: WORKFLOW.errorRateOption.displayName,
            },
            [APM_METRIC_TYPE.WORKFLOW_LATENCY]: {
                metricValue: WORKFLOW.latencyMetric,
                signalTypeEntity: WORKFLOW,
                textLabel: WORKFLOW.latencyOption.displayName,
            },
        };

        const getSignalTypeEntityForMetricValue = function (metricValue) {
            if (
                !metricValue ||
                typeof METRIC_VALUE_TO_SIGNAL_TYPE_ENTITY[metricValue] === 'undefined'
            ) {
                return SERVICE_ENDPOINT; // default to signal type: service/endpoint
            }

            return METRIC_VALUE_TO_SIGNAL_TYPE_ENTITY[metricValue];
        };

        const getSignalTypeEntityForMetricType = function (metricType) {
            if (
                !metricType ||
                typeof METRIC_TYPE_TO_SIGNAL_TYPE_ENTITY[metricType] === 'undefined'
            ) {
                return SERVICE_ENDPOINT; // default to signal type: service/endpoint
            }

            return METRIC_TYPE_TO_SIGNAL_TYPE_ENTITY[metricType];
        };

        return {
            getSignalTypeEntityForMetricValue,
            getSignalTypeEntityForMetricType,
            APM_METRIC_TYPE_MAP,
            METRIC_VALUE_TO_SIGNAL_TYPE_ENTITY,
            SERVICE_ENDPOINT,
            WORKFLOW,
        };
    },
]);
