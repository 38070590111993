import '../subscriptionCategories/module.js';

import orgSubscriptionService from './orgSubscriptionService.js';
import orgSubscription from './orgSubscription.js';
import hostBasedSubscription from './hostBasedSubscription.js';
import supportOrgView from './supportOrgView.js';
import createSyntheticMarketplaceAccount from './marketplaceMockAccount.js';
import accountSources from './accountSources.js';
import jsonFormatter from 'jsonformatter';

angular
    .module('signalview.orgSubscription', [
        'signalview.appNotifications',
        'support.supportService',
        'signalview.subscriptionInfo',
        jsonFormatter,
    ])
    .constant('ACCOUNT_SOURCES', accountSources)
    .service('orgSubscriptionService', orgSubscriptionService)
    .service('createSyntheticMarketplaceAccount', createSyntheticMarketplaceAccount)
    .component('orgSubscription', orgSubscription)
    .component('hostBasedSubscription', hostBasedSubscription)
    .component('supportOrgView', supportOrgView);
