import { ActionCardType } from '@splunk/olly-common/ActionCard';

import VisualizingDataWithCharts from '../../../assets/images/VisualizingDataWithCharts.png';
import CreatingDashboards from '../../../assets/images/CreatingDashboards.png';
import LinkTeam from '../../../assets/images/LinkTeam.png';

export const gettingStartedBannerContentDashboards = {
    title: 'Get the most out of dashboards',
    description:
        'Built-in dashboard groups give you immediate visibility into the technologies and services being used in your environment.',
    docsLink:
        'https://quickdraw.splunk.com/redirect/?product=Observability&location=dashboards.onboarding&version=current',
    communityLink:
        'https://quickdraw.splunk.com/redirect/?product=Observability&location=devcharts.dashboards.onboarding&version=current',
    actionCards: [
        {
            title: 'Visualizing data with charts',
            type: ActionCardType.Video,
            thumbnail: VisualizingDataWithCharts,
            source: 'ZdUuKbn9sUU',
            timeLength: '23:42',
            showBorder: true,
        },
        {
            title: 'Creating charts and dashboards',
            type: ActionCardType.Docs,
            thumbnail: CreatingDashboards,
            source: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=customdashboards.dashboards.onboarding&version=current',
            showBorder: true,
        },
        {
            title: 'Linking to teams',
            type: ActionCardType.Docs,
            thumbnail: LinkTeam,
            source: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=teams.dashboard.onboarding&version=current',
            showBorder: true,
        },
    ],
};
