import templateUrl from './singleValueChart.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import {
    getProgramArgsForDashboardInTime,
    isDashboardTimeWindowSelected,
} from '../../../app/utils/programArgsUtils';
import { generateTimeSeriesName } from '@splunk/olly-utilities/lib/Timeseries';
import { cloneDeep } from 'lodash';

export const singleValueChart = {
    templateUrl,
    bindings: {
        chartModel: '<',
        colorByMetric: '<',
        colorByValue: '<',
        colorByValueScale: '<',
        hideTitle: '<',
        hideTimestamp: '<',
        maxDecimalPlaces: '<',
        plotColors: '<',
        signalFlow: '<',
        showSparkline: '<',
        openHref: '<',
        useKmg2: '<',
        plotDataGeneration: '<',
        updateInterval: '<',
        onNewDataProvider: '&',
        jobMessageSummary: '<',
        disableAnimations: '<',
        chartRollupMessage: '<',
        alertState: '<',
        inEditor: '<',
        showNoDataMessage: '<',
        themeKey: '<',
    },
    controller: [
        '$element',
        'BaseDataProvider',
        'CHART_DISPLAY_EVENTS',
        'chartDisplayUtils',
        'colorAccessibilityService',
        'colorByValueService',
        'valueFormatter',
        '$scope',
        'routeParameterService',
        'timepickerUtils',
        'WindowedDataRepository',
        '$timeout',
        'chartLoadedEvent',
        'featureEnabled',
        function (
            $element,
            BaseDataProvider,
            CHART_DISPLAY_EVENTS,
            chartDisplayUtils,
            colorAccessibilityService,
            colorByValueService,
            valueFormatter,
            $scope,
            routeParameterService,
            timepickerUtils,
            WindowedDataRepository,
            $timeout,
            chartLoadedEvent,
            featureEnabled
        ) {
            const $ctrl = this;
            const gridsterItem = $element.closest('.gridster-item');

            let unregisterRouteWatchGroup;
            let dataProvider;
            let selectedTsid;
            let windowedDataRepo;
            let lastColorByValueScale;
            let lastSecondaryVisualization;
            let maxSingleValueCharacters = 6;
            let newDataDebouncer;
            const wrappedSuffixClass = 'wrapped-suffix';
            const contentContainers = {};

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$doCheck = $doCheck;
            $ctrl.shouldShowSparkline = chartDisplayUtils.showSparkline;

            $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, adjustMaxCharacters);
            $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, adjustFontSize);

            function $onChanges(changesObj) {
                const {
                    colorByMetric,
                    colorByValue,
                    colorByValueScale,
                    plotDataGeneration,
                    updateInterval,
                    useKmg2,
                    maxDecimalPlaces,
                    showSparkline,
                } = changesObj;

                adjustFontSize();

                if (showSparkline) {
                    adjustMaxCharacters();
                }

                if ((colorByMetric || colorByValue || colorByValueScale) && dataProvider) {
                    updateColor(selectedTsid);
                }

                if (plotDataGeneration && plotDataGeneration.currentValue) {
                    reAssertSelectedId();
                    updateValue();
                }

                if (updateInterval) {
                    updateProviderTimeRange();
                }

                if (useKmg2 || maxDecimalPlaces) {
                    renderValue();
                }
            }

            function $onDestroy() {
                if (unregisterRouteWatchGroup) {
                    unregisterRouteWatchGroup();
                }
            }

            function $doCheck() {
                if ($ctrl.colorByValue) {
                    if (!angular.equals($ctrl.colorByValueScale, lastColorByValueScale)) {
                        lastColorByValueScale = angular.copy($ctrl.colorByValueScale);
                        updateValue();
                    }

                    if (
                        $ctrl.chartModel.sf_uiModel.chartconfig.secondaryVisualization !==
                        lastSecondaryVisualization
                    ) {
                        lastSecondaryVisualization =
                            $ctrl.chartModel.sf_uiModel.chartconfig.secondaryVisualization;
                        updateValue();
                    }
                }
            }

            function $onInit() {
                //the DOM generally hasn't completed reflows on init at this point, so we need this timeout.
                $timeout(adjustMaxCharacters, 100);
                $timeout(adjustFontSize, 100);
                $ctrl.latestValue = null;
                renderValue();
                selectedTsid = null;
                $ctrl.sparklineDatapoints = [];
                dataProvider = new BaseDataProvider(callback);
                dataProvider.setOffsetByMaxDelay(true);
                windowedDataRepo = new WindowedDataRepository();
                updateProviderTimeRange();

                // !!! all visualizations must call this for builders to behave correctly !!!
                $ctrl.onNewDataProvider({ dataProvider });

                unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                    ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
                    angular.bind(this, updateProviderTimeRange)
                );
            }

            function updateProviderTimeRange() {
                if (!dataProvider) {
                    return;
                }

                // the change in the global time range should not update the chartModel.sf_uiModel.chartconfig here
                const chartModel = cloneDeep($ctrl.chartModel);
                chartDisplayUtils.updateGlobalTimeRange(
                    chartModel,
                    timepickerUtils.getChartConfigURLTimeParameters()
                );

                // this doesnt read url params...
                const { chartconfig, chartMode } = chartModel.sf_uiModel;
                const rangeParams = chartDisplayUtils.getJobRangeParametersFromConfig(
                    chartconfig,
                    chartMode
                );

                const programArgs = getProgramArgsForDashboardInTime(chartconfig);
                dataProvider.setResolution(rangeParams.resolution);
                dataProvider.setHistoryrange(rangeParams.range);
                if (
                    featureEnabled('dashboardTimeWindow') &&
                    isDashboardTimeWindowSelected(chartModel.sf_viewProgramText)
                ) {
                    dataProvider.setProgramArgs(programArgs);
                }
                dataProvider.setStopTime(rangeParams.endAt);
                dataProvider.setFallbackResolutionMs(rangeParams.fallbackResolutionMs);
            }

            /* unscoped functions */

            function computeTextSize(text, font) {
                // Compute width required to fit the given text with given font
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                context.font = font;
                const measure = context.measureText(text);
                return measure.width;
            }

            function convertFontSizeToInt(fontSize) {
                return parseInt(fontSize.replace('px', ''), 10);
            }

            function getMaxFontSize() {
                const { plotContainer } = getContentContainers();

                const hasLinearSecondaryVisualization =
                    $ctrl.chartModel.sf_uiModel.chartconfig.secondaryVisualization === 'LINEAR';
                const hasDescription = !$ctrl.hideTitle && $ctrl.chartModel.sf_description;
                const hasWrappedSuffix = plotContainer.hasClass(wrappedSuffixClass);

                if (hasLinearSecondaryVisualization) {
                    return hasDescription ? 80 : 85;
                } else if (hasDescription && hasWrappedSuffix) {
                    return 90;
                }

                return 110; // Keep max font size as 110px by referring to chart.less file.
            }

            function adjustFontSize() {
                if (
                    $ctrl.displayValue !== null &&
                    $ctrl.displayValue !== undefined &&
                    $ctrl.displayValue !== '-' &&
                    !$ctrl.showSparkline
                ) {
                    /* Only if there's a valid display value and it's not empty.
                        If these conditions are not matched then existing auto precision logic will be used.
                    */
                    const offset = 50; // offset to avoid overflow
                    const maxFontSize = getMaxFontSize();
                    const minFontSize = 50; // Keep minimum font size and show ellipses if content overflows

                    const { plotContainer, prefixContainer, valueContainer } =
                        getContentContainers();

                    // Get current font size and font.
                    const currentFontSize = valueContainer.css('font-size');
                    const currentFont = valueContainer.css('font');
                    const currentIntFontSize = convertFontSizeToInt(currentFontSize);

                    // Get current size of the value container
                    const textSize = prefixContainer.width() + valueContainer.width();
                    // Get a estimation of text size of the display value
                    const projectedTextSize =
                        prefixContainer.width() + computeTextSize($ctrl.displayValue, currentFont);

                    /* Get available plot size for displayValue minus the prefix container.
                        Substraction of suffix is not required, it will be wrapped to a new line if enought space is not there
                    */
                    const availablePlotSize =
                        plotContainer.width() - prefixContainer.width() - offset;

                    // Get new font value by simple ratio calculation
                    let scaledFont = parseInt(
                        (availablePlotSize * currentIntFontSize) /
                            Math.max(textSize, projectedTextSize),
                        10
                    );
                    scaledFont = Math.min(maxFontSize, scaledFont);
                    scaledFont = Math.max(minFontSize, scaledFont);
                    valueContainer.css('font-size', `${scaledFont}px`);
                }
            }

            function adjustMaxCharacters() {
                const oldMaxSingleValueCharacters = maxSingleValueCharacters;
                const showSparkline = chartDisplayUtils.showSparkline(
                    $ctrl.chartModel.sf_uiModel.chartconfig
                );
                const charWidth = showSparkline ? 36 : 90;
                maxSingleValueCharacters = Math.max(
                    1,
                    Math.floor(($element.width() - 50) / charWidth)
                );
                if (
                    oldMaxSingleValueCharacters !== maxSingleValueCharacters ||
                    getContentOverflowDelta() > 0
                ) {
                    renderValue();
                }
            }

            function chartLoadedCallback() {
                if (
                    $ctrl.displayValue !== null &&
                    $ctrl.displayValue !== undefined &&
                    $ctrl.displayValue !== '-'
                ) {
                    // only if there's a valid display value and it's not empty
                    chartLoadedEvent();
                }
            }

            function renderValue() {
                const value = $ctrl.latestValue;
                if (value !== null) {
                    const formattingCharLimit = $ctrl.maxDecimalPlaces
                        ? $ctrl.maxDecimalPlaces
                        : maxSingleValueCharacters;

                    $ctrl.displayValue = $ctrl.plotUnit
                        ? valueFormatter.formatScalingUnit(
                              value,
                              $ctrl.plotUnit,
                              formattingCharLimit
                          )
                        : valueFormatter.formatValue(value, formattingCharLimit, $ctrl.useKmg2);

                    adjustFontSize();

                    // Wrap suffix on another line if the prefix + value + suffix don't
                    // all fit in one line
                    if (!$ctrl.plotSuffix || gridsterItem.hasClass('min-width-gridster')) {
                        // Don't need it
                        chartLoadedCallback();
                        return;
                    }

                    $timeout(function () {
                        const { plotContainer, valueContainer, prefixContainer } =
                            getContentContainers();
                        const contentOverflowDelta = getContentOverflowDelta(true);
                        if (contentOverflowDelta > 0) {
                            plotContainer.addClass(wrappedSuffixClass);
                            if (prefixContainer.length) {
                                const maxPrefixWidthPercent = Math.max(
                                    0,
                                    Math.min(
                                        50,
                                        Math.abs(
                                            (100 *
                                                (plotContainer.width() - valueContainer.width())) /
                                                (plotContainer.width() + 1)
                                        )
                                    )
                                );
                                $ctrl.maxPrefixWidth = `${maxPrefixWidthPercent}%`;
                            }
                        } else {
                            plotContainer.removeClass(wrappedSuffixClass);
                            $ctrl.maxPrefixWidth = '50%';
                        }
                        chartLoadedCallback();
                        adjustFontSize();
                    }, 0);
                } else {
                    $ctrl.displayValue = '-';
                }
            }

            // This is used for width adjustment if the content is wrapped (which in some cases means smaller font size).
            // When the browser window is at a size where suffix gets wrapped leading to a smaller font size and if you
            // then expand the browser window, the content sometimes overflows. This happens because when overflow check is
            // done, the *wrapped-suffix* class is still applied and the font size is still small, leading to smaller
            // content width and hence leading to removal of the *wrapped-suffix* class.
            function getUnwrappedOnWrappedSizeRatio(plotContainer) {
                const isWrapped = plotContainer.hasClass(wrappedSuffixClass);
                plotContainer.addClass(wrappedSuffixClass);
                const wrappedFontSize = parseFloat(plotContainer.css('font-size'));
                plotContainer.removeClass(wrappedSuffixClass);
                const unWrappedFontSize = parseFloat(plotContainer.css('font-size'));

                if (isWrapped) {
                    plotContainer.addClass(wrappedSuffixClass);
                }

                return unWrappedFontSize / wrappedFontSize;
            }

            function getContentOverflowDelta(unwrappedContentOnly) {
                const { plotContainer, prefixContainer, valueContainer, suffixContainer } =
                    getContentContainers();

                const availableWidth = plotContainer.width() || 0;
                const prefixWidth = prefixContainer.width() || 0;
                const valueWidth = valueContainer.width() || 0;
                const suffixWidth = suffixContainer.width() || 0;
                let contentWidthSum = prefixWidth + valueWidth + suffixWidth;

                // If the content has wrapped-suffix (which also likely means smaller font size), we need to find out the
                // size of regular (non-wrapped) content. To get that we will be using the approximate relationship
                // unwrappedContentSize / wrappedContentSize = unwrappedFontSize / wrappedFontSize
                // This avoids the flicker which otherwise will be prominent when directly recording the width with the
                // unwrapped content and then making the adjustment (width is determined on render).
                if (unwrappedContentOnly && plotContainer.hasClass(wrappedSuffixClass)) {
                    contentWidthSum *= getUnwrappedOnWrappedSizeRatio(plotContainer);
                }

                return contentWidthSum - availableWidth;
            }

            function getContentContainers() {
                let { plotContainer, prefixContainer, valueContainer, suffixContainer } =
                    contentContainers;

                if (!plotContainer) {
                    plotContainer = $element.find('.single-value-plot-value');
                    if (plotContainer.length) {
                        contentContainers.plotContainer = plotContainer;
                    }
                }
                if (plotContainer && !prefixContainer) {
                    prefixContainer = plotContainer
                        .children('.single-value-prefix')
                        .children('.content');
                    if (prefixContainer.length) {
                        contentContainers.prefixContainer = prefixContainer;
                    }
                }
                if (plotContainer && !valueContainer) {
                    valueContainer = plotContainer
                        .children('.single-value-value')
                        .children('.content');
                    if (valueContainer.length) {
                        contentContainers.valueContainer = valueContainer;
                    }
                }
                if (plotContainer && !suffixContainer) {
                    suffixContainer = plotContainer
                        .children('.single-value-suffix')
                        .children('.content');
                    if (suffixContainer.length) {
                        contentContainers.suffixContainer = suffixContainer;
                    }
                }

                return { plotContainer, prefixContainer, valueContainer, suffixContainer };
            }

            function callback(msg) {
                //this does not actually digest, and neither does the data provider.  we may need to force digests when latestValue is updated
                //so that it actually picks up and shows the new value.
                if (msg.type === 'data') {
                    handleNewData(msg);
                } else if (msg.type === 'init') {
                    $ctrl.latestValue = null;
                    renderValue();
                    selectedTsid = null;
                    $ctrl.sparklineDatapoints = [];
                    //todo: maybe clear instead of reconstruct?
                    windowedDataRepo = new WindowedDataRepository();
                    windowedDataRepo.setExpirationPolicy('interval', 10);
                }
            }

            function findVisiblePlot() {
                let selectedTsid = null;
                const tsids = Object.keys(dataProvider.getMetaDataMap());
                for (let idx = 0; idx < tsids.length && !selectedTsid; idx++) {
                    const plot = chartDisplayUtils.getPlotObject(
                        dataProvider.getMetricMetaData(tsids[idx]),
                        $ctrl.chartModel
                    );

                    if (plot && !plot.invisible) {
                        selectedTsid = tsids[idx];
                    }
                }
                return selectedTsid;
            }

            function reAssertSelectedId() {
                if (!selectedTsid) {
                    selectedTsid = findVisiblePlot();
                } else {
                    const plot = chartDisplayUtils.getPlotObject(
                        dataProvider.getMetricMetaData(selectedTsid),
                        $ctrl.chartModel
                    );

                    if (!plot || plot.invisible) {
                        selectedTsid = findVisiblePlot();
                    }
                }
            }

            function handleNewData(msg) {
                windowedDataRepo.onDataReceived(msg.data);

                reAssertSelectedId();
                $timeout.cancel(newDataDebouncer);
                newDataDebouncer = $timeout(updateValue, 300);
            }

            function updateValue() {
                if (selectedTsid) {
                    const recentPoints = (windowedDataRepo.get(selectedTsid) || []).map((point) => [
                        point.ts,
                        point.value,
                    ]);
                    if (recentPoints.length) {
                        $ctrl.sparklineDatapoints = recentPoints;
                        const [timestamp, value] =
                            $ctrl.sparklineDatapoints[$ctrl.sparklineDatapoints.length - 1];
                        $ctrl.latestValue = value;
                        $ctrl.latestTimeStamp = timestamp;
                    } else {
                        $ctrl.sparklineDatapoints = [];
                        $ctrl.latestValue = null;
                    }
                } else {
                    $ctrl.sparklineDatapoints = [];
                    $ctrl.latestValue = null;
                }

                updateColor(selectedTsid);
                updateUnits(selectedTsid);
                renderValue();
            }

            function updateUnits(tsid) {
                const metadata = dataProvider.getMetricMetaData(tsid);
                if (!metadata) {
                    return;
                }

                const plot = chartDisplayUtils.getPlotObject(metadata, $ctrl.chartModel);
                $ctrl.plotPrefix = safeLookup(plot, 'configuration.prefix') || '';
                $ctrl.plotSuffix = safeLookup(plot, 'configuration.suffix') || '';
                $ctrl.plotUnit = safeLookup(plot, 'configuration.unitType') || '';
            }

            function updateColor(tsid) {
                const metadata = dataProvider.getMetricMetaData(tsid);
                if (!metadata) {
                    return;
                }
                if (!$ctrl.colorByValue) {
                    const skipMetric = !$ctrl.colorByMetric;
                    const skipSource = $ctrl.colorByMetric;
                    const delimiter = '';
                    const plot = chartDisplayUtils.getPlotObject(metadata, $ctrl.chartModel);

                    if (plot.configuration && plot.configuration.colorOverride) {
                        const colorOverride = plot.configuration.colorOverride;
                        $ctrl.color =
                            colorAccessibilityService
                                .get()
                                .convertPlotColorToAccessible(colorOverride) || colorOverride;
                    } else {
                        const seriesName = generateTimeSeriesName(
                            metadata,
                            delimiter,
                            skipMetric,
                            skipSource,
                            plot
                        );

                        $ctrl.color = chartDisplayUtils.getPlotColor(seriesName, $ctrl.plotColors);
                    }
                } else if ($ctrl.colorByValue) {
                    if (
                        $ctrl.latestValue !== null &&
                        $ctrl.colorByValue &&
                        $ctrl.colorByValueScale
                    ) {
                        $ctrl.color = colorByValueService.getColorForValue(
                            $ctrl.colorByValueScale,
                            $ctrl.latestValue
                        );
                    }
                }
            }
        },
    ],
};
