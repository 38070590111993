import templateUrl from './eventOverlayPage.tpl.html';

export default {
    templateUrl,
    bindings: {
        params: '=',
        dashboardEditable: '<',
        missingUpdateDashboardCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$scope',
        '_',
        'colorAccessibilityService',
        'dashboardV2Service',
        '$log',
        '$q',
        'exitGuardModal',
        'featureEnabled',
        function (
            $scope,
            _,
            colorAccessibilityService,
            dashboardV2Service,
            $log,
            $q,
            exitGuardModal,
            featureEnabled
        ) {
            const ctrl = this;

            ctrl.$onInit = $onInit;

            ctrl.addEvent = addEvent;
            ctrl.getEventColor = getEventColor;
            ctrl.getEventLine = getEventLine;

            function $onInit() {
                $scope.params = ctrl.params;

                $scope.events = angular.copy(ctrl.params.dashboard.eventOverlays) || [];
                $scope.isDashboardLocked =
                    featureEnabled('readOnly') && ctrl.params.dashboard.locked;

                $scope.events.forEach((event) => {
                    event.eventColor = colorAccessibilityService
                        .get()
                        .getDefaultColorFromIndex(event.eventColorIndex);
                });
            }

            function addEvent() {
                $scope.events.push(createNewRow());
            }

            function getEventColor(event) {
                // this gets called when we create a new event in event overlay
                // we want to match the color if the same event exists
                const sameEvent = getSameEvent(event);

                if (sameEvent) {
                    return sameEvent.eventColor;
                }
            }

            function getEventLine(event) {
                // this gets called when we create a new event in event overlay
                // we want to match the event line config if the same event exists
                const sameEvent = getSameEvent(event);

                if (sameEvent) {
                    return sameEvent.eventLine;
                }
            }

            $scope.$watch('eventOverlayPage.$dirty', function () {
                ctrl.params.dirty = $scope.eventOverlayPage.$dirty;
            });

            $scope.$on('delete event overlay row', function (ev, eventToDelete) {
                const index = $scope.events.indexOf(eventToDelete);
                if (index !== -1) {
                    $scope.events.splice(index, 1);
                    $scope.eventOverlayPage.$setDirty();
                }
            });

            const isSameEvent = (event1, event2) => {
                // Note: we decided to not account for the event filters because the
                // event overlay color and event line mappings are keyed by eventType
                // and does not account for event filters.
                return !!(
                    event1.eventSignal.eventSearchText === event2.eventSearchText &&
                    event1.eventSignal.eventType === event2.eventType
                );
            };

            const getSameEvent = (event) => {
                return _.find($scope.events, function (e) {
                    return isSameEvent(e, event);
                });
            };

            $scope.$on('change all events color', function (ev, eventColorParams) {
                // change all the same events if one of them changes event color config
                $scope.events.forEach((e) => {
                    if (isSameEvent(e, eventColorParams)) {
                        e.eventColor = eventColorParams.eventColor;
                    }
                });
            });

            $scope.$on('change all events line', function (ev, eventLineParams) {
                // change all the same events if one of them changes event line config
                $scope.events.forEach((e) => {
                    if (isSameEvent(e, eventLineParams)) {
                        e.eventLine = eventLineParams.eventLine;
                    }
                });
            });

            function createNewRow() {
                const defaultColor = '#999999';
                return {
                    eventSignal: '',
                    sources: [],
                    label: '',
                    eventColorIndex: colorAccessibilityService
                        .get()
                        .getDefaultPlotColorIndex(defaultColor),
                    eventColor: defaultColor,
                    eventLine: false,
                };
            }

            $scope.cancel = function () {
                $scope.$emit('dismiss modal');
            };

            function save() {
                ctrl.params.dashboard.eventOverlays = $scope.events
                    .filter(function (e) {
                        return e.eventSignal;
                    })
                    .map(function (e) {
                        return {
                            eventSignal: e.eventSignal,
                            label: e.label || e.eventSignal.eventSearchText,
                            eventLine: e.eventLine,
                            sources: e.sources,
                            eventColorIndex: colorAccessibilityService
                                .get()
                                .getDefaultPlotColorIndex(e.eventColor),
                            overlayId: e.overlayId,
                        };
                    });

                if (ctrl.params.snapshot && ctrl.params.snapshot.id) {
                    $scope.$emit('save complete');
                    $scope.eventOverlayPage.$setPristine();
                    return $q.when();
                } else {
                    return dashboardV2Service
                        .update(ctrl.params.dashboard)
                        .then(function (dashboard) {
                            ctrl.params.dashboard.eventOverlays = dashboard.eventOverlays;
                            $scope.$emit('save complete');
                        })
                        .finally(function () {
                            $scope.eventOverlayPage.$setPristine();
                        });
                }
            }

            $scope.saveAndClose = function () {
                save()
                    .then(function () {
                        $scope.$emit('close modal');
                    })
                    .catch(function (e) {
                        $log.error(e);
                    });
            };

            function exitGuard() {
                return exitGuardModal(save, reset);
            }

            function reset() {
                $scope.events = angular.copy(ctrl.params.dashboard.eventOverlays) || [];
                $scope.eventOverlayPage.$setPristine();
                return $q.when();
            }

            $scope.$watch('eventOverlayPage.$dirty', function (newVal) {
                ctrl.params.dirty = newVal;

                if (newVal) {
                    ctrl.tabby.enableExitGuard(exitGuard);
                } else {
                    ctrl.tabby.disableExitGuard();
                }
            });
        },
    ],
};
