import templateUrl from './kubePodDetail.tpl.html';
import CONTAINER_LIST_CONFIG from '../../../../app/kubeNavigator/configs/pods/podContainerListConfig';
import POD_DETAIL_TEXT_CHART_CONFIG from '../../../../app/kubeNavigator/configs/pods/podDetailTextChartConfig';
import fixedFilterEventFeed from '../common/fixedFilterEventFeed.tpl.html';
import {
    getCPUChartModel,
    getMemoryChartModel,
    getNetworkErrorChartModel,
    getNetworkThroughputChartModel,
} from '../../../../app/kubeNavigator/configs/pods/podChartModelsConfig';
import { getEventChartModel } from '../../../../app/kubeNavigator/configs/pods/podEventFeedConfig';

export default {
    templateUrl,
    bindings: {
        time: '<',
        filterAlias: '<', // Variable definitions to be supplied to chart models
        filters: '<',
        isOnSidebar: '<?',
        onSelection: '<?',
        selectedTabDisplayName: '<?',
    },
    controller: [
        'kubeDataService',
        'transientChartModelMaker',
        function (kubeDataService, transientChartModelMaker) {
            const $ctrl = this;
            let lastFilterAliases;

            $ctrl.configs = {
                containerListConfig: CONTAINER_LIST_CONFIG,
                podDetailTextChartConfig: POD_DETAIL_TEXT_CHART_CONFIG,
            };

            $ctrl.sharedChartState = {};
            $ctrl.searchable = {
                placeholder: 'Search containers',
            };

            $ctrl.$onChanges = $onChanges;

            function $onChanges({ filterAlias, filters, time }) {
                const filtersUpdated =
                    (filterAlias || filters) &&
                    !angular.equals(lastFilterAliases, $ctrl.filterAlias);

                if (filtersUpdated) {
                    lastFilterAliases = angular.copy($ctrl.filterAlias);

                    const areVariablesPresent =
                        $ctrl.filterAlias &&
                        $ctrl.filterAlias.some(({ value }) => !_.isEmpty(value));

                    $ctrl.areOverridesPresent =
                        areVariablesPresent ||
                        ($ctrl.filters && $ctrl.filters.some(({ value }) => !_.isEmpty(value)));
                }

                if (filtersUpdated || time) {
                    processChartModels();
                }
            }

            function processChartModels() {
                const time = kubeDataService.getChartModelTime($ctrl.time);
                const filters = kubeDataService.cleanFilters($ctrl.filterAlias);
                const filtersString = kubeDataService.generateFilterStrings(filters);

                const cpuChartModel = getCPUChartModel(time, filtersString);
                const memoryChartModel = getMemoryChartModel(time, filtersString);
                const networkErrorChartModel = getNetworkErrorChartModel(time, filtersString);
                const networkThroughputChartModel = getNetworkThroughputChartModel(
                    time,
                    filtersString
                );

                $ctrl.configs.podChartModelConfig = [
                    transientChartModelMaker(
                        cpuChartModel.chartOptions,
                        cpuChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        memoryChartModel.chartOptions,
                        memoryChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        networkErrorChartModel.chartOptions,
                        networkErrorChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        networkThroughputChartModel.chartOptions,
                        networkThroughputChartModel.publishLabelOptions
                    ),
                ];

                const eventChartModel = getEventChartModel(time, filtersString);
                $ctrl.eventFeed = {
                    templateUrl: fixedFilterEventFeed,
                    model: transientChartModelMaker(
                        eventChartModel.chartOptions,
                        eventChartModel.publishLabelOptions
                    ),
                };
            }
        },
    ],
};
