import templateUrl from './detectorV2SignalFlowEditor.tpl.html';
import { ngRoute } from '../../../../app/routing/ngRoute';
import qs from 'query-string';

export default {
    templateUrl,
    controller: [
        '$scope',
        '$log',
        'title',
        'detectorService',
        'v2DetectorResolverUtil',
        function ($scope, $log, title, detectorService, v2DetectorResolverUtil) {
            const detectorId = ngRoute.params.fromDetector;
            const chartId = ngRoute.params.fromChart;
            const userChartPermissions = !!chartId && ngRoute.params.chartPermissions === 'true';

            const setDetector = (data) => {
                $scope.editor = data.editor || 'signalflow';
                $scope.data = {
                    updateTitle: title.updateTitle,
                    mutings: [],
                    incident: null,
                };

                const dataObj = { detector: data.detector };

                if ($scope.editor === 'signalflow') {
                    // Data when the text editor is rendered
                    $scope.data.userChartPermissions = userChartPermissions;
                    $scope.data.fromChartId = chartId;
                    $scope.data.detectorObject = dataObj;
                } else {
                    // Data when the rich editor is rendered
                    $scope.data.data = dataObj;
                }
            };

            let detectorObjectPromise;
            if (detectorId) {
                detectorObjectPromise = v2DetectorResolverUtil
                    .getDetector(detectorId)
                    .then((detector) => detectorService.cloneV2(detector))
                    .catch((e) => {
                        $log.error("V2 detector couldn't be received for id " + detectorId);
                        $log.error(e);
                    })
                    .then((detector) => {
                        if (detector) {
                            return v2DetectorResolverUtil
                                .setupDetectorModelForEditor(detector)
                                .then((data) => {
                                    if (data.editor === 'signalflow') {
                                        ngRoute.history.replace({
                                            pathname: ngRoute.pathname,
                                            search: qs.stringify({ detectorSignalFlowEditor: 1 }),
                                        });
                                    }
                                    return data;
                                });
                        }
                    });
            } else {
                detectorObjectPromise = v2DetectorResolverUtil
                    .newDetector(chartId)
                    .then((detector) => ({ detector: detector }));
            }
            detectorObjectPromise.then(setDetector);
        },
    ],
};
