import templateUrl from './objecttitle.tpl.html';

angular.module('signalview.objectmanager').directive('objectTitle', function () {
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            selectedType: '=',
            objectCount: '=',
        },
        templateUrl,
        controller: [
            '$scope',
            function ($scope) {
                $scope.title = function () {
                    let objectType = $scope.selectedType;
                    if (objectType === 'metrictimeseries') {
                        objectType = 'Time Series';
                    } else if (objectType === 'eventtype') {
                        objectType = 'Custom Event';
                    }

                    if ($scope.objectCount === 1) {
                        return objectType;
                    }
                    if ($scope.objectCount > 1) {
                        if (objectType.slice(-1) !== 's') {
                            objectType += 's';
                        }

                        return 'Selected ' + objectType + ' - ' + $scope.objectCount;
                    }

                    // if no objects are selected, assume we're creating a new one
                    return 'New Tag';
                };
            },
        ],
    };
});
