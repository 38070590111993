import templateUrl from './opsgenieIntegration.html';

angular
    .module('sfx.ui')

    .directive('sfxOpsgenieIntegration', [
        'notificationService',
        'opsgenieIntegrationService',
        function (notificationService, opsgenieIntegrationService) {
            return {
                restrict: 'E',
                templateUrl,
                scope: true,
                require: '^sfxIntegration',
                link: function ($scope) {
                    let serviceRegionMap = null;

                    $scope.select = function (region) {
                        $scope.selectedRegion = region;
                        $scope.credential.apiUrl = serviceRegionMap[region];
                        $scope.integrationForm.$setDirty();
                    };
                    $scope.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                        $scope.credential.lastUpdated
                    );

                    $scope.$on('reset', updateRegion);

                    function updateRegion() {
                        const url = $scope.credential.apiUrl;
                        const region = $scope.serviceRegions.find((region) => {
                            return serviceRegionMap[region] === url;
                        });

                        if (region) {
                            $scope.selectedRegion = region;
                        }
                        // update last modified time as well
                        $scope.lastModifiedTs =
                            notificationService.formatNotificationEventTimestamp(
                                $scope.credential.lastUpdated
                            );
                    }

                    function init() {
                        opsgenieIntegrationService.getServiceRegions().then((regionMap) => {
                            serviceRegionMap = regionMap;
                            $scope.serviceRegions = Object.keys(regionMap);
                            if ($scope.credential && $scope.credential.apiUrl) {
                                updateRegion($scope.credential.apiUrl);
                            } else {
                                // Assume the default region is first
                                $scope.select($scope.serviceRegions[0]);
                            }
                        });
                    }
                    init();
                },
            };
        },
    ]);
