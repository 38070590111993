'use strict';

angular.module('sfx.data').factory('eventTimeSeriesService', [
    'sfxApi',
    'createMetabaseObjectService',
    'currentUser',
    function (sfxApi, createMetabaseObjectService, currentUser) {
        const api = sfxApi.eventtimeseries;
        const service = createMetabaseObjectService(api);

        function filterQuery(q, org, includeArchived) {
            let query = 'sf_organizationID:' + org;
            if (!includeArchived) {
                query += ' AND (NOT sf_archived:true) ';
            }

            query += ' AND (' + q + ')';
            return query;
        }

        function filteredFn(parms, fn) {
            return currentUser.orgId().then(function (orgId) {
                if (typeof parms === 'string') {
                    parms = filterQuery(parms, orgId);
                } else if (parms.query) {
                    parms.query = filterQuery(parms.query, orgId, parms.includeArchived);
                }
                return fn(parms);
            });
        }

        function argumentBind(fn) {
            return function (parms) {
                return filteredFn(parms, fn);
            };
        }
        service.get = api.getInstance;
        service.delete = argumentBind(api.delete);
        service.search = argumentBind(api.search);
        service.aggregation = argumentBind(api.aggregation);
        return service;
    },
]);
