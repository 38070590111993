import templateUrl from './filterSelectModal.html';

export default [
    'sfxModal',
    'metricService',
    '$q',
    function (sfxModal, metricService, $q) {
        function openModal(title, queryInfo, gaPrefix) {
            return sfxModal.open({
                templateUrl,
                controller: [
                    '$scope',
                    'promiseGenerationManager',
                    function ($scope, promiseGenerationManager) {
                        const fetchFacetPromise = promiseGenerationManager(
                            metricService.fetchFacetByKey,
                            300
                        );

                        $onInit();
                        $scope.onSearchTermUpdate = onSearchTermUpdate;

                        function $onInit() {
                            $scope.title = title;
                            updateFacetValues();
                            $scope.gaPrefix = gaPrefix
                                ? gaPrefix + '-filter-select-modal'
                                : gaPrefix;
                        }

                        function updateFacetValues(isSearch = false) {
                            $scope.loadingFacets = true;
                            /*
                             * We know that our request from metricfinder fetches for 100 values per facet already
                             * so we don't have to make an additional call for the same values to show. Any new
                             * queries or filters applied will trigger a re-query to metricfinder.
                             *
                             * Only ask meatballs if user inputs search/query to filter for.
                             */
                            if (!isSearch && queryInfo.values) {
                                const deferred = $q.defer();
                                deferred.resolve(queryInfo.values);
                                return deferred.promise
                                    .then(() => {
                                        $scope.values = queryInfo.values;
                                    })
                                    .finally(() => ($scope.loadingFacets = false));
                            }

                            return fetchFacetPromise(
                                queryInfo.key,
                                queryInfo.query,
                                queryInfo.filters,
                                queryInfo.isActiveMetricsOnly,
                                $scope.searchTerm
                            )
                                .then((results) => {
                                    $scope.values = results;
                                })
                                .finally(() => ($scope.loadingFacets = false));
                        }

                        function onSearchTermUpdate() {
                            updateFacetValues(true);
                        }
                    },
                ],
                keyboard: false,
                backdrop: true,
                windowClass: 'select-modal',
            }).result;
        }

        return openModal;
    },
];
