angular
    .module('signalview-utils.signalflowv2', [])
    .service('plotToSignalflowV2', [
        '$window',
        function ($window) {
            return $window.signalviewUtils.conversionUtilities.plotToSignalflowV2;
        },
    ])
    .service('ruleToSignalflowV2', [
        '$window',
        function ($window) {
            return $window.signalviewUtils.conversionUtilities.ruleToSignalflowV2;
        },
    ])
    .service('uiModelToSignalflowV2', [
        '$window',
        function ($window) {
            return $window.signalviewUtils.conversionUtilities.uiModelToSignalflowV2;
        },
    ])
    .service('signalflowV2Utils', [
        '$window',
        function ($window) {
            return $window.signalviewUtils.conversionUtilities.signalflowV2Utils;
        },
    ])
    .service('DETECTOR_TYPES', [
        '$window',
        function ($window) {
            return $window.signalviewUtils.functionUtilities.functionsMetadataDefinitionTypes;
        },
    ])
    .service('SIGNALFLOW_CONSTANTS', [
        '$window',
        function ($window) {
            return $window.signalviewUtils.conversionUtilities.constants;
        },
    ]);

angular.module('signalview-utils.functions', []).service('functionsMetadataService', [
    '$window',
    '$log',
    'featureEnabled',
    function ($window, $log, featureEnabled) {
        const { functionsMetadata, functionsMetadataDefinitionTypes } =
            $window.signalviewUtils.functionUtilities;
        const {
            getNotificationParameterizedString,
            getEventAnnotations,
            getFunctionJson,
            getFunctionsList,
            getPathJson,
            getModules,
            getDefinitionTypes,
        } = functionsMetadata;

        const isAPMV2Path = (path) => path.type === functionsMetadataDefinitionTypes.APM_V2;
        const isAPMV2Module = (module) => isAPMV2Path(module.path);
        const isAPMV2Function = (fn) => fn.moduleType === functionsMetadataDefinitionTypes.APM_V2;

        return {
            getNotificationParameterizedString,
            getEventAnnotations,
            getFunctionJson: function (...args) {
                const fn = getFunctionJson(...args);
                if (!featureEnabled('apm2') && isAPMV2Function(fn)) {
                    $log.error('APM 2.0 function is used but the user is missing entitlement.');
                    return null;
                }
                return fn;
            },
            getPathJson: function (...args) {
                const path = getPathJson(...args);
                if (!featureEnabled('apm2') && isAPMV2Path(path)) {
                    $log.error('APM 2.0 path is used but the user is missing entitlement.');
                    return null;
                }
                return path;
            },
            getFunctionsList: function (...args) {
                return getFunctionsList(...args).filter(
                    (module) => featureEnabled('apm2') || !isAPMV2Module(module)
                );
            },
            getModules: function (...args) {
                return getModules(...args).filter(
                    (fn) => featureEnabled('apm2') || !isAPMV2Function(fn)
                );
            },
            getDefinitionTypes: function (...args) {
                return getDefinitionTypes(...args).filter(
                    (type) =>
                        featureEnabled('apm2') || type !== functionsMetadataDefinitionTypes.APM_V2
                );
            },
        };
    },
]);
