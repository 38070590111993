import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import Button from '@splunk/react-ui/Button';
import { ThemeProvider } from '@splunk/olly-common';
import { variables } from '@splunk/themes';
import { useLegacyThemeServiceKey } from '../../theme/ThemeProvider';
import { Chart, DashboardConfig } from './DashboardChartSlot';

const Message = styled.div`
    padding: 10px;
    font-size: ${variables.fontSizeLarge};
`;

const Footer = styled.div`
    padding: 10px;
`;

const StyledOverlay = styled.div`
    background-color: ${variables.contentColorInverted};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    z-index: 10;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
`;

export type RemoveDashboardPromptProps = {
    show: boolean;
    chart: Chart;
    allDashboardConfigs?: Promise<DashboardConfig[]>;
    onRemove: () => void;
    onCancel: () => void;
};

export const RemoveDashboardPrompt = ({
    show,
    chart,
    allDashboardConfigs,
    onRemove,
    onCancel,
}: RemoveDashboardPromptProps): JSX.Element | null => {
    const colorScheme = useLegacyThemeServiceKey();
    const [message, setMessage] = useState<string>();

    useEffect(() => {
        allDashboardConfigs?.then((configs) => {
            const mirrorsCount = configs.length;
            const groupsCount = uniqBy(configs, 'groupId').length;

            if (mirrorsCount > 1) {
                setMessage(`This chart will be permanently deleted and will no longer be visible in any mirror of this
                    dashboard. This dashboard has ${mirrorsCount} mirrors
                    in ${groupsCount} group${groupsCount > 1 ? 's' : ''}.`);
            } else {
                setMessage(`This will permanently delete the chart ${chart.name}.`);
            }
        });
    }, [allDashboardConfigs, chart.name]);

    return show && message ? (
        <ThemeProvider colorScheme={colorScheme}>
            <StyledOverlay>
                <Message>{message}</Message>
                <Footer>
                    <Button label="Cancel" onClick={onCancel} />
                    <Button label="Delete" onClick={onRemove} appearance="destructive" />
                </Footer>
            </StyledOverlay>
        </ThemeProvider>
    ) : null;
};
