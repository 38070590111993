export const blockingLoadService = [
    'sfxModal',
    function (sfxModal) {
        return function block() {
            return sfxModal.open({
                template: '<div><i class="busy-spinner-light"></i></div>',
                windowClass: 'full-screen-busy-spinner',
                backdrop: 'static',
                keyboard: false,
            });
        };
    },
];
