import { ngRoute } from '../../../app/routing/ngRoute';

angular.module('signalview.dashboard').controller('dashboardSaveAsModal', [
    '$scope',
    'params',
    'orgId',
    'dashboardV2Service',
    'urlOverridesService',
    'dashboardGroupCache',
    'userV2Service',
    function (
        $scope,
        params,
        orgId,
        dashboardV2Service,
        urlOverridesService,
        dashboardGroupCache,
        userV2Service
    ) {
        $scope.title = params.title || 'Unable to modify read-only dashboard';
        $scope.organizationId = orgId;

        $scope.loading = true;
        dashboardV2Service
            .get(params.dashboard.id)
            .then((latestDashboardData) => {
                $scope.dashboard = latestDashboardData;
                $scope.lockedOn = latestDashboardData.lastUpdated;
                if (latestDashboardData.lastUpdatedBy) {
                    userV2Service
                        .getOrgMemberForUserId(latestDashboardData.lastUpdatedBy)
                        .then(function (orgMember) {
                            $scope.loading = false;
                            $scope.lockedBy = userV2Service.getUserBadgeDisplayObj(orgMember);
                        });
                } else {
                    $scope.loading = false;
                }
            })
            .catch(() => {
                $scope.loading = false;
                $scope.failedGet = true;
            });

        $scope.closeAndRedirect = function () {
            $scope.$close();
            urlOverridesService.clearAllNonLocationUrlParams();
            ngRoute.reload();
        };

        $scope.saveAs = function () {
            $scope.$close();
            dashboardGroupCache.invalidateTabCache();
            params.cloneDashboard();
        };
    },
]);
