import kubeNavigator from './kubeNavigator';
import clusterMapStreamer from './common/clusterMapStreamer';
import clusterMapStateFactory from './clusterMap/clusterMapStateFactory';
import clusterMapViz from './clusterMap/clusterMapViz';
import clusterMapUtil from './clusterMap/clusterMapUtil';
import clusterMapConfig from './clusterMap/clusterMapConfig';
import clusterMapFilterUtils from './clusterMap/clusterMapFilterUtils';
import clusterMap from './clusterMap/clusterMap';
import kubeTable from './kubeTables/kubeTable';
import kubeDetails from './kubeDetails/kubeDetails';
import kubeDataTableStreamer from './common/kubeDataTableStreamer';
import clusterMapVizConfigUtil from './clusterMap/clusterMapVizConfigUtil';
import clusterMapLayoutGenerator from './clusterMap/clusterMapLayoutGenerator';
import kubeDataService from './common/kubeDataService';
import kubeMapAnalyzer from './kubeMapAnalyzer/kubeMapAnalyzer';
import kubeNodeDetail from './kubeNodeDetail/kubeNodeDetail';
import kubeWorkloadDetails from './kubeWorkloadDetails/kubeWorkloadDetails';
import kubePodDetail from './kubePodDetail/kubePodDetail';
import kubeContainerDetail from './kubeContainerDetails/kubeContainerDetail';
import nodeListConfig from '../../../app/kubeNavigator/configs/nodes/nodeListconfig';
import workloadsListConfig from '../../../app/kubeNavigator/configs/workloads/workloadsListConfig';
import { withNg2React } from '../../../common/util/Ng2React';
import infraNavClusterMap from './infraNavClusterMap/infraNavClusterMap';
import infraNavClusterMapViz from './infraNavClusterMap/infraNavClusterMapViz';
import infraNavClusterMapFilterUtils from './infraNavClusterMap/infraNavClusterMapFilterUtils';
import infraNavClusterMapStreamer from './infraNavClusterMap/infraNavClusterMapStreamer';

const KUBE_RESOURCE_TYPES = {
    CLUSTER: 'CLUSTER',
    NODE: 'NODE',
    POD: 'POD',
    CONTAINER: 'CONTAINER',
};

const KUBE_PROPERTY_EXCLUDE_LIST = ['jobId', 'tsid', 'value', 'id', 'computationId', 'timestamp'];

const KUBE_PROPERTY_WORKLOAD_MAPPING = {
    replicaSet: 'ReplicaSet',
    replicationController: 'ReplicationController',
    daemonSet: 'DaemonSet',
    statefulSet: 'StatefulSet',
    job: 'Job',
    cronjob: 'CronJob',
    deployment: 'Deployment',
};

const KUBE_POD_PHASES_MAPPING = {
    1: 'Pending',
    2: 'Running',
    3: 'Succeeded',
    4: 'Failed',
    5: 'Unknown',
};

const KUBE_PROPERTY_TO_VARIABLE_ALIAS = {
    kubernetes_workload_name: 'Workload',
    kubernetes_workload: 'Workload Type',
    kubernetes_namespace: 'Namespace',
    kubernetes_cluster: 'Cluster',
    kubernetes_node: 'Node Name',
    kubernetes_pod_name: 'Pod Name',
    kubernetes_pod_uid: 'Pod ID',
    container_spec_name: 'Container Name',
    container_id: 'Container ID',
};

const ANALYZER_EVENT = {
    SIDEBAR_LOOK_FOR_MATCHING_CHILD: 'sidebar event to look for matching child in kubernetes map',
    CLUSTER_MAP_LOOK_FOR_MATCHING_CHILD:
        'cluster map event to look for matching child in kubernetes map',
    CLUSTER_MAP_FOUND_MATCHING_CHILD: 'cluster map event found matching child in kubernetes state',
    SIDEBAR_FOUND_MATCHING_CHILD: 'sidebar event found matching child in kubernetes state',
};

const constants = {
    KUBE_RESOURCE_TYPES,
    KUBE_PROPERTY_WORKLOAD_MAPPING,
    KUBE_PROPERTY_TO_VARIABLE_ALIAS,
};

angular
    .module('signalview.kubernetes', ['signalview.infoSidebarModule', 'signalview.chartMaker'])
    .constant('KubernetesResourceTypes', KUBE_RESOURCE_TYPES)
    .constant('KUBE_PROPERTY_EXCLUDE_LIST', KUBE_PROPERTY_EXCLUDE_LIST)
    .constant('KUBE_POD_PHASES_MAPPING', KUBE_POD_PHASES_MAPPING)
    .constant('KUBE_PROPERTY_TO_VARIABLE_ALIAS', KUBE_PROPERTY_TO_VARIABLE_ALIAS)
    .factory('clusterMapStateFactory', clusterMapStateFactory)
    .factory('clusterMapViz', clusterMapViz)
    .factory('infraNavClusterMapViz', infraNavClusterMapViz)
    .factory('clusterMapConfig', clusterMapConfig)
    .service('clusterMapStreamer', clusterMapStreamer)
    .service('infraNavClusterMapStreamer', infraNavClusterMapStreamer)
    .service('clusterMapUtil', clusterMapUtil)
    .service('kubeDataTableStreamer', kubeDataTableStreamer)
    .service('kubeDataService', kubeDataService)
    .service('clusterMapVizConfigUtil', clusterMapVizConfigUtil)
    .service('clusterMapLayoutGenerator', clusterMapLayoutGenerator)
    .service('clusterMapFilterUtils', clusterMapFilterUtils)
    .service('infraNavClusterMapFilterUtils', infraNavClusterMapFilterUtils)
    .component('clusterMap', clusterMap)
    .component('kubeTable', kubeTable)
    .component('kubeDetails', kubeDetails)
    .component('kubeMapAnalyzer', kubeMapAnalyzer)
    .component('kubeNodeDetail', kubeNodeDetail)
    .component('kubeWorkloadDetails', kubeWorkloadDetails)
    .component('kubePodDetail', kubePodDetail)
    .component('kubeContainerDetail', kubeContainerDetail)
    .component('kubeNavigator', kubeNavigator)
    .component('infraNavClusterMap', infraNavClusterMap)
    .constant('KUBE_PROPERTY_WORKLOAD_MAPPING', KUBE_PROPERTY_WORKLOAD_MAPPING)
    .constant('ANALYZER_EVENT', ANALYZER_EVENT);

const ClusterMapReact = withNg2React({ name: 'ClusterMap', definition: clusterMap });
const ContainerDetailsReact = withNg2React({
    name: 'kubeContainerDetail',
    definition: kubeContainerDetail,
});
const KubeTableReact = withNg2React({ name: 'KubeTable', definition: kubeTable });
const NodeDetailsReact = withNg2React({ name: 'KubeNodeDetail', definition: kubeNodeDetail });
const PodDetailsReact = withNg2React({ name: 'KubePodDetail', definition: kubePodDetail });
const WorkloadDetailsReact = withNg2React({
    name: 'KubeWorkloadDetails',
    definition: kubeWorkloadDetails,
});

const InfraNavClusterMapReact = withNg2React({
    name: 'InfraNavClusterMap',
    definition: infraNavClusterMap,
});

// the exports below are used by the olly repo
// eslint-disable-next-line import/no-unused-modules
export {
    ClusterMapReact,
    ContainerDetailsReact,
    KubeTableReact,
    NodeDetailsReact,
    PodDetailsReact,
    WorkloadDetailsReact,
    InfraNavClusterMapReact,
    constants,
    nodeListConfig,
    workloadsListConfig,
};
