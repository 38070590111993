import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import { AngularInjector } from '../../../../common/AngularUtils';

const StyledDiv = styled.div`
    [data-test='tooltip'] {
        width: 100%;
        [data-test='toggle'] {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: inherit;
            font-size: 12px;
        }
    }
`;

const StyledSpan = styled.span`
    vertical-align: middle;
`;

const StyledAnchor = styled.a`
    vertical-align: middle;
`;

export default function CellFormatter({ value, href, title, defaultCrossLink }) {
    const cellLabelRef = useRef(null);
    const [isOverflowText, setIsOverflowText] = useState(false);
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    useEffect(() => {
        const scrollWidth = cellLabelRef.current?.scrollWidth;
        const offsetWidth = cellLabelRef.current?.offsetWidth;
        if (scrollWidth > offsetWidth) {
            setIsOverflowText(true);
        }
    }, [value]);

    const body = useMemo(() => {
        if (isOverflowText) {
            const valueBody = href ? <a href={href}>{value}</a> : value;
            return (
                <Tooltip compact content={`${title} | ${value}`}>
                    {valueBody}
                </Tooltip>
            );
        }

        if (href) {
            return (
                <StyledAnchor
                    href={href}
                    title={title}
                    onClick={() => {
                        if (defaultCrossLink) {
                            userAnalytics.event(
                                'click',
                                'navigator-default-cross-link',
                                null,
                                defaultCrossLink
                            );
                        } else {
                            userAnalytics.event('click', 'dashboard-default-cross-link');
                        }
                    }}
                >
                    {value || '-'}
                </StyledAnchor>
            );
        }

        return <StyledSpan>{value || '-'}</StyledSpan>;
    }, [isOverflowText, href, title, value, userAnalytics, defaultCrossLink]);

    return <StyledDiv ref={cellLabelRef}>{body}</StyledDiv>;
}

CellFormatter.propTypes = {
    value: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    defaultCrossLink: PropTypes.string,
};
