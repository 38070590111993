/* eslint-disable @typescript-eslint/ban-types */
import { angular2react } from 'angular2react';
import { AngularInjector } from '../AngularUtils';
import type { IComponentOptions } from 'angular';
import * as React from 'react';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { SignalviewTopNavContext } from '../../app/TopNavContextProvider';

const Styled = styled.div`
    &,
    & > * {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }
`;

type Ng2ReactProps<Props extends object> = Props & {
    name: string;
    definition: IComponentOptions;
    fill?: boolean;
};

// This component allows runtime creation of angular component instead of statically to allow
// for AngularInjector.$injector to instantiate
export function Ng2React<Props extends object>(
    props: Ng2ReactProps<Props>
): React.ReactElement<Props> {
    const { setCustomContent, setHierarchicalNav } = React.useContext(SignalviewTopNavContext);
    const Component = useMemo(() => {
        if (!AngularInjector.$injector) {
            throw Error('Injector Not Found');
        }
        return angular2react<Props>(props.name, props.definition, AngularInjector.$injector);
        // Component does not change after a change in definition
        // This is a deliberate design choice to keep these to props static for performance reasons.
        // eslint-disable-next-line
    }, [props.name]);

    const updatedProps = { ...props, setCustomContent, setHierarchicalNav };

    const toRender = <Component {...updatedProps} />;
    return props.fill ? <Styled>{toRender}</Styled> : toRender;
}

export function withNg2React<Props extends object>({
    name,
    definition,
    fill,
}: Ng2ReactProps<{}>): FC<Props> {
    const Component: FC<Props> = (componentProps) => (
        <Ng2React {...componentProps} name={name} definition={definition} fill={fill} />
    );
    Component.displayName = `Ng2React-${name}`;
    return Component;
}
