import { keyValuePairToQuery } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';

angular.module('signalview.heatmap').service('heatmapEventData', [
    'heatmapEndpoint',
    function (heatmapEndpoint) {
        const orderedSeverities = ['Critical', 'Major', 'Minor', 'Warning', 'Info'];

        const priorityToSeverity = {
            1000: 'Critical',
            800: 'Major',
            600: 'Minor',
            400: 'Warning',
            200: 'Info',
        };

        const severityToValue = {
            Critical: 100,
            Major: 80,
            Minor: 60,
            Warning: 40,
            Info: 20,
        };

        function getHighestSeverity(alertCounts) {
            let severity;

            for (let i = 0; i < orderedSeverities.length; i++) {
                severity = orderedSeverities[i];
                if (alertCounts[severity]) return severity;
            }
        }

        function getAlertCounts(aggregation, filters) {
            aggregation = aggregation.slice(0);
            filters = filters.slice(0);

            filters.push(['!sf_archived', 'T']);
            filters.push(['_exists_', 'sf_anomalyState']);

            const filterStrings = filters.map(function (filter) {
                return keyValuePairToQuery(filter[0], filter[1]);
            });

            return heatmapEndpoint
                .alertAggregation(aggregation, filterStrings, 100)
                .then(function (res) {
                    const aggregations = res.data.aggregations || [];

                    if (!angular.isArray(aggregation)) aggregation = [aggregation];

                    function flattenAggregation(keyArray, aggregations, proto) {
                        if (!keyArray.length) {
                            proto.aggregations = aggregations;
                            return proto;
                        }

                        const aggKey = keyArray.shift();
                        return aggregations
                            .map(function (agg) {
                                const aggValue = agg.name;

                                const instance = angular.copy(proto);
                                instance[aggKey] = aggValue;

                                return flattenAggregation(
                                    keyArray.slice(0),
                                    agg.aggregations,
                                    instance
                                );
                            })
                            .reduce(function (prev, cur) {
                                return prev.concat(cur);
                            }, []);
                    }

                    const result = flattenAggregation(aggregation, aggregations, {});

                    result.forEach(function (item) {
                        item.alertCounts = {};
                        item.aggregations.forEach(function (agg) {
                            const severity = priorityToSeverity[agg.name];
                            const count = agg.count;
                            item.alertCounts[severity] = count;
                        });

                        delete item.aggregations;

                        item.highestSeverity = getHighestSeverity(item.alertCounts);
                        item.value = severityToValue[item.highestSeverity];
                    });

                    return result;
                });
        }

        return {
            getAlertCounts: getAlertCounts,
        };
    },
]);
