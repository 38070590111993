import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
const INTEGER_REGEXP = /^\-?\d+$/;

const MAXIMUM_MAX_DELAY = 900000;

angular
    .module('signalview.validators', [])
    .directive('integer', function () {
        // from https://code.angularjs.org/1.2.23/docs/guide/forms
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$parsers.unshift(function (viewValue) {
                    if (INTEGER_REGEXP.test(viewValue)) {
                        // it is valid
                        ctrl.$setValidity('integer', true);
                        return viewValue;
                    } else {
                        // it is invalid, return undefined (no model update)
                        ctrl.$setValidity('integer', false);
                        return undefined;
                    }
                });
            },
        };
    })
    .directive('maxDelayValidateValue', [
        function () {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.maxDelayValue = function (userInput) {
                        const maxDelayMs = convertStrToMS(userInput);
                        return !isNaN(maxDelayMs) && isValidValue(maxDelayMs);
                    };

                    function convertStrToMS(userInput) {
                        switch (userInput) {
                            case 'Default':
                                return null;
                            case 'Auto':
                                return 0;
                            default:
                                return convertStringToMS(userInput);
                        }
                    }

                    function isValidValue(maxDelayMs) {
                        return (
                            maxDelayMs === null ||
                            (maxDelayMs >= 0 && maxDelayMs <= MAXIMUM_MAX_DELAY)
                        );
                    }
                },
            };
        },
    ]);
