import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import dashboardPageTemplateUrl from './dashboardPage.tpl.html';
import { Dashboards as sitemap } from '../../../app/routing/Sitemaps';
import { ngRoute } from '../../../app/routing/ngRoute.ts';

export default {
    bindings: {
        data: '<',
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl: dashboardPageTemplateUrl,
    controller: [
        '$scope',
        'signalboost',
        'signalboostUtil',
        '$q',
        'dashboardGroupService',
        'dashboardMirrorService',
        'dashboardV2Util',
        'dashboardV2Service',
        'CHART_DISPLAY_EVENTS',
        'currentUser',
        'dashboardUtil',
        'featureEnabled',
        'autoRedirectWhenHttpError',
        function (
            $scope,
            // eslint-disable-next-line
            signalboost, // not used, but seems to have side-effects in factory
            signalboostUtil,
            $q,
            dashboardGroupService,
            dashboardMirrorService,
            dashboardV2Util,
            dashboardV2Service,
            CHART_DISPLAY_EVENTS,
            currentUser,
            dashboardUtil,
            featureEnabled,
            autoRedirectWhenHttpError
        ) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;
            $ctrl.$onDestroy = onDestroy;

            async function getData(dashboardId, groupId, configId) {
                $scope.has404 = false;
                $scope.hasLoaded = false;
                $scope.rootObjectTypeLabel = null;

                if (groupId && !dashboardId) {
                    $scope.rootObjectTypeLabel = AccessControlObjectType.DASHBOARD_GROUP.label;
                    return fetchBasedOnGroup(groupId);
                }

                if (dashboardId) {
                    $scope.rootObjectTypeLabel = AccessControlObjectType.DASHBOARD.label;
                    const [hierarchyData, isMirror] = await Promise.all([
                        dashboardV2Util.getAndProcessHierarchy(dashboardId, groupId, true),
                        dashboardMirrorService.isDashboardMirror(dashboardId),
                    ]);

                    // For beta scope, mirrors always inherit from their groups, so you don't have access to mirror if you have no access to group
                    if (!hierarchyData.group && isMirror) {
                        return Promise.reject({ status: 404 });
                    }

                    if (hierarchyData.group) {
                        hierarchyData.group = dashboardGroupService.convertToV2(
                            hierarchyData.group
                        );
                        const foundConfigId = dashboardUtil.getConfigForDashboard(
                            hierarchyData.group.dashboardConfigs,
                            dashboardId
                        )?.configId;
                        hierarchyData.configId = configId || foundConfigId;
                    }

                    await signalboostUtil.initializeOrgForObj(
                        hierarchyData.group || hierarchyData.dashboard
                    );
                    return hierarchyData;
                }

                return Promise.reject({ status: 404 });
            }

            async function fetchBasedOnGroup(groupId) {
                const groupOfUnknownVersion = await dashboardGroupService.get(groupId);
                await signalboostUtil.initializeOrgForObj(groupOfUnknownVersion);

                const group = dashboardGroupService.convertToV2(groupOfUnknownVersion);

                // first we'll try to abide by user preferences
                const userPreferences = await currentUser.orgPreferences();
                const lastViewedConfig = userPreferences.sf_lastViewedDashboard?.[group.id];

                const prioritizedConfigsList = [
                    ...(isDashboardInGroup(lastViewedConfig, group) ? [lastViewedConfig] : []),
                    // if there's no preference saved, or it's invalid then we go to first accessible config
                    ...(group.dashboardConfigs || []),
                    // if there's no accessible configs, then we go to dashboard directly
                    // notice that we turn list of ids into a list of configs here
                    ...(group.dashboards || []).map((id) => ({ dashboardId: id })),
                ];

                for (const config of prioritizedConfigsList) {
                    const dashboard = await dashboardV2Service
                        .get(config.dashboardId)
                        .catch(() => null);
                    if (dashboard) {
                        return {
                            group,
                            configId: config.configId,
                            dashboard,
                            allDashboards: [dashboard],
                            charts: [],
                        };
                    }
                }

                // given that we haven't failed while fetching the group, but we weren't able to fetch a dashboard,
                // we have an empty group
                return {
                    group,
                    allDashboards: [],
                    charts: [],
                };
            }

            $scope.has404 = false;
            $scope.hasLoaded = false;
            $scope.isHomepage = false;

            function setHNavForDashboard(data) {
                if (ngRoute.route.url.startsWith('/home')) {
                    $ctrl.setHierarchicalNav('Home');
                } else if (data.dashboard) {
                    const created = data.dashboard.created;
                    let name = data.dashboard.name;
                    let groupName = data.dashboard.groupName;

                    // get group name from the associated group if it exists. Mirrored dashboards reside in a different group than the
                    // original dashboard.
                    if (data.group) {
                        groupName = data.group.name;
                    }

                    // Mirrored dashboards can have a dashboardConfig listing an overridden name.
                    // Check for the dashboard config and use the nameOverride for the name (if specified).
                    if (data.configId && data.group?.dashboardConfigs) {
                        const dbConfig = data.group.dashboardConfigs.find(
                            (config) => config.configId === data.configId
                        );
                        if (dbConfig?.nameOverride) {
                            name = dbConfig.nameOverride;
                        }
                    }
                    if (!created) {
                        $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.newDashboard);
                    } else {
                        $ctrl.setCustomContent(sitemap.name, {
                            id: sitemap.IDs.dashboardGroup,
                            label: groupName,
                        });
                        $ctrl.setCustomContent(sitemap.name, {
                            id: sitemap.IDs.dashboardName,
                            label: name,
                            path: `#${ngRoute.route.url}`,
                        });
                        $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.dashboardName);
                    }
                }
            }

            function initialize() {
                const data = $ctrl.data;
                // check for homepage in case of errors
                $scope.isHomepage = ngRoute.route.url.startsWith('/home');
                // go get the data if we're being asked to via a dashboardId parameter, if not assume its been provided for us in
                // hierarchy format
                $scope.data = (
                    data.snapshot
                        ? $q.when(data)
                        : getData(data.dashboardId, data.groupId, data.configId)
                )
                    .then(
                        (data) => {
                            $scope.hasLoaded = true;
                            setHNavForDashboard(data);
                            return data;
                        },
                        (responseOrError) => {
                            $scope.hasLoaded = true;

                            if (
                                [403, 404].includes(responseOrError?.status) &&
                                featureEnabled('accessControl')
                            ) {
                                // hijack regular http error execution flow and instead show our custom 404 page
                                $scope.has404 = true;
                                return null;
                            }

                            throw responseOrError;
                        }
                    )
                    .catch(autoRedirectWhenHttpError)
                    .finally(() => $scope.$applyAsync());
            }

            function onDestroy() {
                $ctrl.setHierarchicalNav();
            }

            $scope.$on(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, function (ev, obj) {
                $scope.$broadcast('initializeTimePicker', obj);
            });

            $scope.$on(CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED, function (ev, start, end) {
                $scope.$broadcast('setNewGlobalTimeRange', start, end);
            });
        },
    ],
};

function isDashboardInGroup(lastViewedConfig, group) {
    if (
        group.dashboardConfigs?.map(({ configId }) => configId).includes(lastViewedConfig?.configId)
    ) {
        return true;
    }

    if (group.dashboards?.includes(lastViewedConfig?.dashboardId)) {
        return true;
    }

    return false;
}
