export const fullscreen = [
    '$document',
    '$rootScope',
    '$log',
    function ($document, $rootScope, $log) {
        const _this = this;
        const doc = $document[0];
        const docElement = doc.documentElement;
        let requestFullscreen;
        let exitFullscreen;
        let fullscreenchangeEventName;
        let fullscreenElementPropertyName;

        if (docElement.requestFullscreen) {
            requestFullscreen = docElement.requestFullscreen;
            exitFullscreen = doc.exitFullscreen;
            fullscreenElementPropertyName = 'fullscreenElement';
            fullscreenchangeEventName = 'fullscreenchange';
        } else if (docElement.webkitRequestFullscreen) {
            requestFullscreen = docElement.webkitRequestFullscreen;
            exitFullscreen = doc.webkitExitFullscreen;
            fullscreenElementPropertyName = 'webkitFullscreenElement';
            fullscreenchangeEventName = 'webkitfullscreenchange';
        } else if (docElement.mozRequestFullScreen) {
            requestFullscreen = docElement.mozRequestFullScreen;
            exitFullscreen = doc.mozCancelFullScreen;
            fullscreenElementPropertyName = 'mozFullScreenElement';
            fullscreenchangeEventName = 'mozfullscreenchange';
        } else if (docElement.msRequestFullscreen) {
            requestFullscreen = docElement.msRequestFullscreen;
            exitFullscreen = doc.msExitFullscreen;
            fullscreenElementPropertyName = 'msFullscreenElement';
            fullscreenchangeEventName = 'msfullscreenchange';
        } else {
            $log.info("Disabling fullscreen support (couldn't find browser feature)");
            this.requestFullscreen = angular.noop;
            this.exitFullscreen = angular.noop;
            this.isFullscreen = function () {
                return false;
            };
            return;
        }

        doc.addEventListener(fullscreenchangeEventName, function () {
            if (_this.isFullscreen()) {
                $rootScope.$broadcast('fullscreen enabled');
            } else {
                $rootScope.$broadcast('fullscreen disabled');
            }
        });

        this.isFullscreen = function () {
            return !!doc[fullscreenElementPropertyName];
        };

        this.requestFullscreen = requestFullscreen.bind(docElement);
        this.exitFullscreen = exitFullscreen.bind(doc);
    },
];
