import React, { FC } from 'react';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';
import styled from 'styled-components';
import LOOrganizationsTab from './LOOrganizationsTab';
import LOSubscriptionInfo from './LOSubscriptionInfo';
import LOColumnChartComponent from './LOColumnChartComponent';
import LOLineChartComponent from './LOLineChartComponent';

type LogObserverUsageComponentProps = {
    orgInfo: {
        orgInfo: {
            ingestQuota: string;
            indexQuota: string;
            termStartDate: string;
            termEndDate: string;
        } | null;
        isLoading: boolean | undefined;
    };
    ingestUsages: {
        x: Date;
        y: number;
    }[];
    indexUsages: {
        x: Date;
        y: number;
    }[];
    orgId: string;
    featureEnabled: any;
};

const LogObserverTopDiv = styled.div`
    height: 100%;
    padding: 0 0 0 20px;
`;

const LOSubInfo = styled.div`
    // Currently the max height of the usage charts area. Makes the left-border
    // of the right sidebar at least this tall.
    min-height: 1158px;
`;

const LoadingSpinner = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const formatToSingleDecimal = (value: number): number => {
    return Math.round(value * 10) / 10;
};

const LogObserverUsageComponent: FC<LogObserverUsageComponentProps> = ({
    orgInfo,
    ingestUsages,
    indexUsages,
    orgId,
    featureEnabled,
}) => {
    return (
        <LogObserverTopDiv className="flex-auto">
            <LOOrganizationsTab orgId={orgId} featureEnabled={featureEnabled} />
            {orgInfo.isLoading !== false ? (
                <LoadingSpinner>
                    <WaitSpinner size="large" />
                </LoadingSpinner>
            ) : (
                ingestUsages &&
                indexUsages &&
                ingestUsages.length > 0 &&
                indexUsages.length > 0 && (
                    <>
                        <div className="col-md-8 container-fluid fill-height">
                            <LOColumnChartComponent
                                ingestQuota={formatToSingleDecimal(
                                    Number(orgInfo.orgInfo!.ingestQuota)
                                )}
                                indexQuota={formatToSingleDecimal(
                                    Number(orgInfo.orgInfo!.indexQuota)
                                )}
                                ingestUsage={formatToSingleDecimal(
                                    ingestUsages[ingestUsages.length - 1].y
                                )}
                                indexUsage={formatToSingleDecimal(
                                    indexUsages[indexUsages.length - 1].y
                                )}
                            />
                            <LOLineChartComponent
                                ingestQuota={Number(orgInfo.orgInfo!.ingestQuota)}
                                indexQuota={Number(orgInfo.orgInfo!.indexQuota)}
                                ingestUsage={ingestUsages}
                                indexUsage={indexUsages}
                            />
                        </div>
                        <LOSubInfo className="lo-sub-info col-md-4 container-fluid">
                            <LOSubscriptionInfo
                                ingestQuota={orgInfo.orgInfo!.ingestQuota}
                                indexQuota={orgInfo.orgInfo!.indexQuota}
                                termStartDate={orgInfo.orgInfo!.termStartDate}
                                termEndDate={orgInfo.orgInfo!.termEndDate}
                            />
                        </LOSubInfo>
                    </>
                )
            )}
        </LogObserverTopDiv>
    );
};

export default LogObserverUsageComponent;
