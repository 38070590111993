import templateUrl from './teamNotifyBlock.tpl.html';

angular
    .module('signalview.detector.notification')

    .component('teamNotifyBlock', {
        templateUrl,
        bindings: {
            model: '<',
            teams: '<',
            onRemove: '&',
            doneEdit: '&onFinishEdit',
        },
        controller: [
            '$element',
            '$scope',
            '$timeout',
            function ($element, $scope, $timeout) {
                const ctrl = this;
                const input = angular.element($element.find('input')[0]);

                ctrl.$onInit = $onInit;
                ctrl.editMode = editMode;
                ctrl.finishEdit = finishEdit;
                ctrl.handleKeydown = handleKeydown;
                ctrl.removeTeam = removeTeam;

                function $onInit() {
                    // getTeamName should be attempted first in case the team name has changed,
                    // however if the team has been deleted we can still use teamName.
                    ctrl.teamName = getTeamName(ctrl.model.team);
                    if (!ctrl.teamName) {
                        ctrl.teamName = ctrl.model.teamName;
                        ctrl.noSuchTeam = true;
                    }
                    if (!ctrl.model.team) {
                        editMode();
                    }
                }

                function finishEdit() {
                    if (ctrl.teamName) {
                        ctrl.model.teamName = ctrl.teamName;
                        ctrl.model.team = getTeamId(ctrl.teamName);
                        ctrl.edit = false;
                        ctrl.noSuchTeam = false;
                    } else {
                        removeTeam();
                    }
                    if (ctrl.doneEdit) {
                        ctrl.doneEdit();
                        if (ctrl.teamName) {
                            $scope.$emit('team notification added', {
                                teamName: ctrl.teamName,
                                id: ctrl.model.team,
                            });
                        }
                    }
                }

                function editMode() {
                    ctrl.edit = true;

                    $timeout(() => {
                        // The dropdown doesn't open unless you change the
                        // input first and focus after a second timeout.
                        ctrl.teamName = '';
                        $timeout(() => input.focus());
                    });
                }

                function handleKeydown({ originalEvent }) {
                    // escape key
                    if (originalEvent.keyCode === 27) {
                        ctrl.teamName = getTeamName(ctrl.model.team);
                        finishEdit();
                    }
                }

                function removeTeam() {
                    $scope.$emit('team notification removed', {
                        teamName: ctrl.teamName,
                        id: ctrl.model.team,
                    });
                    if (ctrl.onRemove) {
                        ctrl.onRemove();
                    }
                }

                function getTeamId(name) {
                    const team = ctrl.teams.find((team) => team.name === name);
                    return team ? team.id : null;
                }

                function getTeamName(id) {
                    const team = ctrl.teams.find((team) => team.id === id);
                    return team ? team.name : null;
                }
            },
        ],
    });
