export default class ExitGuardService {
    constructor(enableExitGuard, disableExitGuard, exitGuardModal) {
        this.enableExitGuard = enableExitGuard;
        this.disableExitGuard = disableExitGuard;
        this.exitGuardModal = exitGuardModal;
    }

    create({ onSave, onDiscard, isValid }) {
        return {
            enable: () =>
                this.enableExitGuard(() => this.exitGuardModal(onSave, onDiscard, isValid)),
            disable: () => this.disableExitGuard(),
        };
    }
}
