angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    'blockService',
    function (chartTemplates, Chart, plotUtils, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('CPU % Distribution')
                .description('Brighter color means more hosts are at that CPU % value')
                .disableThrottle(true)
                .forcedResolution(30000)
                .type('area')
                .yAxis(0, {
                    min: 0,
                    max: 120,
                });

            const plot1 = chart
                .plot()
                .metric('cpu.idle')
                .propertyFilter('plugin_instance', 'cpu-average')
                .propertyFilter('plugin', 'aggregation')
                .propertyFilters(filters)
                .addManipulation(blockService.mean.asAggregation([{ value: 'host' }]))
                .visible(false);

            const plot2 = chart
                .plot()
                .macro('100 - ' + plotUtils.getLetterFromUniqueKey(plot1.uniqueKey()))
                .name('CPU %')
                .visible(false);

            const plot2Letter = plotUtils.getLetterFromUniqueKey(plot2.uniqueKey());

            chart
                .plot()
                .name('min')
                .macro(plot2Letter)
                .color('#05ce00')
                .addManipulation(blockService.min.asAggregation());

            chart
                .plot()
                .name('p10')
                .macro(plot2Letter)
                .color('#e5b312')
                .addManipulation(blockService.percentile.asAggregation([], { percentile: 10 }));

            chart
                .plot()
                .name('p50')
                .macro(plot2Letter)
                .color('#00b9ff')
                .addManipulation(blockService.percentile.asAggregation([], { percentile: 50 }));

            chart
                .plot()
                .name('p90')
                .macro(plot2Letter)
                .color('#f47e00')
                .addManipulation(blockService.percentile.asAggregation([], { percentile: 90 }));

            chart
                .plot()
                .name('maximum')
                .macro(plot2Letter)
                .color('#bd468d')
                .addManipulation(blockService.max.asAggregation());

            return chart;
        }

        chartTemplates.register('collectdHostsCpuLoadSpread', createChart);
    },
]);
