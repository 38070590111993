import templateUrl from './detectorV2WizardModal.tpl.html';
import { ngRoute } from '../../../../app/routing/ngRoute';

/**
 * Detector modal shows the detector wizard in a modal.
 * The modal is instantiated with the detector model, and rule that is to be edited.
 */
angular.module('signalview.detectorV2').factory('v2DetectorWizardModal', [
    '$log',
    'sfxModal',
    '$location',
    'notificationsUtil',
    'processNotificationRecipient',
    function ($log, sfxModal, $location, notificationsUtil, processNotificationRecipient) {
        return {
            editRule,
        };

        // Show wizard with passed in parameters - detector model, rule for edit and resolution promise generator
        function edit(params) {
            return sfxModal
                .open({
                    templateUrl,
                    controller: 'v2DetectorWizardModalController',
                    size: 'lg',
                    windowClass: 'detector-wizard-modal detector-wizard-v2-modal-one-chart',
                    resolve: {
                        data: function () {
                            let detectorData;
                            if (params.detector) {
                                // simplest deep copy without references 3 levels down
                                detectorData = angular.fromJson(angular.toJson(params.detector));
                            }
                            let rule;
                            if (params.rule) {
                                rule = angular.fromJson(angular.toJson(params.rule));
                                if (rule.notifications) {
                                    // map notification rules to v1 notification list, will be mapped to v2 types on save
                                    rule.notifications = notificationsUtil.convertV2ListToV1(
                                        rule.notifications
                                    );
                                    rule.notifications =
                                        processNotificationRecipient.filterDeprecatedNotifications(
                                            rule.notifications
                                        );
                                }
                            }
                            const model = angular.copy(params.model);
                            return {
                                detector: detectorData,
                                rule: rule,
                                isNewRule: params.isNewRule,
                                model: model,
                                hasWritePermission: params.hasWritePermission,
                            };
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                })
                .result.then((detector) => {
                    $location.url(`/detector/v2/${detector.id}/edit`);
                    ngRoute.reload();
                });
        }

        // Show edit modal for existing detector for an existing rule for edit
        function editRule(detector, rule, isNewRule, model, hasWritePermission) {
            return edit({ detector, rule, isNewRule, model, hasWritePermission })
                .then(function (editedDetector) {
                    if (editedDetector) {
                        return editedDetector;
                    }
                })
                .catch(function (msg) {
                    $log.warn('Rule edit was canceled.', msg);
                });
        }
    },
]);
