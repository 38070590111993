import templateUrl from './hostBasedCharts.tpl.html';
import {
    CONTAINERS_CATEGORY,
    CUSTOM_METRICS_CATEGORY,
    HOSTS_CATEGORY,
} from '../../upgradeSubscription/monitoringHostService.js';

angular.module('signalview.orgUsageChart').component('hostBasedCharts', {
    templateUrl,
    bindings: {
        planInfoSvc: '<',
    },
    controller: [
        '$scope',
        'd3',
        'monitoringHostService',
        'orgUsageService',
        'organizationService',
        'subscriptionTypeUtil',
        'zeroStateService',
        'orgUsageModalService',
        'SUPPORT_EMAIL',
        'featureEnabled',
        function (
            $scope,
            d3,
            monitoringHostService,
            orgUsageService,
            organizationService,
            subscriptionTypeUtil,
            zeroStateService,
            orgUsageModalService,
            SUPPORT_EMAIL,
            featureEnabled
        ) {
            const ctrl = this;
            let org;
            let streamObj;
            let x, y, xAxis, yAxis;
            const USAGE_VALUES = ['current', 'overage'];

            const BAR_COLOR = '#7B56DB'; // For stacked bars
            // Set up bar charts showing current usage
            const CHART_MARGIN = { top: 30, right: 30, bottom: 15, left: 40 };
            const CHART_WIDTH = 145 - CHART_MARGIN.left - CHART_MARGIN.right;
            const CHART_HEIGHT = 200 - CHART_MARGIN.top - CHART_MARGIN.bottom;

            const categoryToLimit = {}; // Plan limit per category

            ctrl.HOSTS_CATEGORY = HOSTS_CATEGORY;
            ctrl.CONTAINERS_CATEGORY = CONTAINERS_CATEGORY;
            ctrl.CUSTOM_METRICS_CATEGORY = CUSTOM_METRICS_CATEGORY;

            ctrl.categoryToClass = {};
            ctrl.categoryToClass[HOSTS_CATEGORY] = 'hosts-usage-chart';
            ctrl.categoryToClass[CONTAINERS_CATEGORY] = 'containers-usage-chart';
            ctrl.categoryToClass[CUSTOM_METRICS_CATEGORY] = 'custom-metrics-usage-chart';

            ctrl.trendsChartConfig = {
                primaryResolution: 60 * 60 * 1000,
                maximumsResolution: 60 * 60 * 1000,
            };

            ctrl.zeroStateService = zeroStateService;
            ctrl.fetching = true;
            ctrl.latestTimestamp = new Date().getTime();
            ctrl.categoryToValue = { latestTimestamp: ctrl.latestTimestamp };

            ctrl.$onInit = $onInit;
            ctrl.$onDestroy = $onDestroy;

            ctrl.showUsageReports = orgUsageModalService.showUsageReports;
            ctrl.setCategory = setCategory;
            ctrl.setRange = setRange;

            $scope.supportSwitch = featureEnabled('supportSwitch');
            const legacyMetricDelay = featureEnabled('legacyHostContainerNumCustomMetricDelay');

            function $onInit() {
                $scope.SUPPORT_EMAIL = SUPPORT_EMAIL;

                // Functions to scale values to pixels
                x = d3.scale.ordinal().rangeRoundBands([0, CHART_WIDTH], 0.35);
                y = d3.scale.linear().rangeRound([CHART_HEIGHT, 0]);

                // No tick lines or labels on x-axis
                xAxis = d3.svg.axis().scale(x).orient('bottom').tickSize(0).tickValues([]);

                // No tick lines on y-axis
                yAxis = d3.svg
                    .axis()
                    .scale(y)
                    .orient('left')
                    .ticks(5)
                    .innerTickSize(-CHART_WIDTH)
                    .outerTickSize(0)
                    .tickFormat(d3.format('s'));

                // Get account info and start streaming usage metrics
                const jobOpts = {
                    onStreamError,
                    onFeedback,
                    streamStartCallback,
                    callback: dataCallback,
                };

                organizationService
                    .get()
                    .then((organization) => {
                        org = organization;

                        // List of relevant categories
                        ctrl.categories = [];
                        const hostBasedOrg = subscriptionTypeUtil.isHostBased(
                            org.accountSubscriptionType
                        );
                        if (hostBasedOrg) {
                            ctrl.categories = [
                                HOSTS_CATEGORY,
                                CONTAINERS_CATEGORY,
                                CUSTOM_METRICS_CATEGORY,
                            ];
                        }
                        const mtsBasedOrg = subscriptionTypeUtil.isCustomMts(
                            org.accountSubscriptionType
                        );
                        if (mtsBasedOrg) {
                            ctrl.categories = [CUSTOM_METRICS_CATEGORY];
                        }

                        ctrl.usageCategories = subscriptionTypeUtil.getCategoriesBySubscription(
                            org.accountSubscriptionType
                        );

                        return ctrl.planInfoSvc;
                    })
                    .then(
                        (acct) => {
                            ctrl.account = acct;

                            categoryToLimit[HOSTS_CATEGORY] =
                                ctrl.account.quotas.CATEGORY_LIMITS[1];
                            categoryToLimit[CONTAINERS_CATEGORY] =
                                ctrl.account.quotas.CATEGORY_LIMITS[2];
                            categoryToLimit[CUSTOM_METRICS_CATEGORY] =
                                ctrl.account.quotas.CATEGORY_LIMITS[3];

                            ctrl.usageRanges = orgUsageService.constructValidPaymentIntervals(
                                ctrl.account,
                                6,
                                org.created
                            );
                            setRange(ctrl.usageRanges[0]);
                            setCategory(ctrl.usageCategories[0]);

                            streamObj = monitoringHostService.getHostBasedMetricData(
                                acct,
                                jobOpts,
                                legacyMetricDelay
                            );
                        },
                        function () {
                            ctrl.fetching = false;

                            // Get usage metrics, still
                            streamObj = monitoringHostService.getHostBasedMetricData(
                                null,
                                jobOpts,
                                legacyMetricDelay
                            );
                        }
                    );
            }

            function $onDestroy() {
                if (streamObj) {
                    streamObj.stopStream();
                }
            }

            function setCategory(category) {
                ctrl.selectedCategory = category;
                const filters = [];
                if (category.resourceType) {
                    filters.push({ name: 'resourceType', value: category.resourceType });
                }
                ctrl.usageSignalFlow = monitoringHostService.getHostBasedUsageSignalFlow(
                    'usage',
                    category.orgUsageMetric,
                    filters
                );
                ctrl.averageUsageSignalFlow =
                    monitoringHostService.getHostBasedAverageUsageSignalFlow(
                        'averageUsage',
                        category.orgUsageMetric,
                        filters
                    );
                ctrl.averageUsageSignalFlowWithPartialValues =
                    monitoringHostService.getHostBasedAverageUsageSignalFlowWithPartialValues(
                        'averageUsageWithPartial',
                        category.orgUsageMetric,
                        filters
                    );
                ctrl.limitSignalFlow = monitoringHostService.getHostBasedUsageSignalFlow(
                    'limit',
                    category.limitMetric,
                    []
                );
            }

            function setRange(range) {
                ctrl.selectedRange = range;
            }

            // Non-scoped functions

            function onStreamError() {
                ctrl.fetching = false;
            }

            function onFeedback(msgs) {
                if (msgs) {
                    msgs.forEach((msg) => {
                        if (
                            msg.messageCode === 'FETCH_NUM_TIMESERIES' &&
                            msg.numInputTimeSeries === 0
                        ) {
                            const blockContext = msg.blockContext;
                            // These need to match the order of the lines in monitoringHostService's SignalFlow
                            if (blockContext.line === 1) {
                                drawDefaultCurrentUsageChart(HOSTS_CATEGORY);
                            } else if (blockContext.line === 2) {
                                drawDefaultCurrentUsageChart(CONTAINERS_CATEGORY);
                            } else if (blockContext.line === 3) {
                                drawDefaultCurrentUsageChart(CUSTOM_METRICS_CATEGORY);
                            }
                        }
                    });
                }
            }

            function streamStartCallback() {
                ctrl.fetching = false;
            }

            // Currently the graph is in loading state due to pending streams
            // Getting the stream close event from usage chart to make the loader false
            $scope.$on('onStreamCloseCallback', function () {
                ctrl.fetching = false;
                $scope.$digest();
            });

            function dataCallback(categoryToValue, latest) {
                ctrl.latestTimestamp = categoryToValue.latestTimestamp;
                ctrl.categoryToValue = angular.copy(categoryToValue);
                // Fill in zeroes
                ctrl.categories.forEach((category) => {
                    if (!ctrl.categoryToValue[category]) {
                        ctrl.categoryToValue[category] = 0;
                    }
                });
                drawCurrentUsageChart(latest.category, [latest]);
            }

            function drawDefaultCurrentUsageChart(category) {
                // Default value to zero
                const latest = {
                    timestamp: ctrl.latestTimestamp,
                    category,
                    current: 0,
                    overage: 0,
                };
                drawCurrentUsageChart(category, [latest]);
                ctrl.categoryToValue[category] = 0;
            }

            function drawCurrentUsageChart(category, data) {
                if (ctrl.categories.indexOf(category) === -1) {
                    return;
                }

                const selector = '.' + ctrl.categoryToClass[category];
                // Clear previous contents
                d3.select(selector).selectAll('*').remove();

                const svg = d3
                    .select(selector)
                    .attr('height', CHART_HEIGHT + CHART_MARGIN.top + CHART_MARGIN.bottom)
                    .attr('width', CHART_WIDTH + CHART_MARGIN.left + CHART_MARGIN.right)
                    .append('g')
                    .attr(
                        'transform',
                        'translate(' + CHART_MARGIN.left + ',' + CHART_MARGIN.top + ')'
                    );

                const dataIntermediate = USAGE_VALUES.map(function (c) {
                    return data.map(function (d) {
                        return { x: d.category, y: d[c] };
                    });
                });

                const dataStackLayout = d3.layout.stack()(dataIntermediate);

                x.domain(
                    dataStackLayout[0].map(function (d) {
                        return d.x;
                    })
                );

                const planLimit = categoryToLimit[category];

                y.domain([
                    0,
                    d3.max(data, function (d) {
                        const total = d.current + d.overage;
                        return planLimit ? Math.max(planLimit, total) : total;
                    }),
                ]).nice();

                // x-axis
                svg.append('g')
                    .attr('class', 'x-axis')
                    .attr('transform', 'translate(0,' + CHART_HEIGHT + ')')
                    .call(xAxis);

                // y-axis
                svg.append('g')
                    .attr('class', 'y-axis')
                    .call(yAxis)
                    .append('text')
                    .attr('transform', 'rotate(-90)');

                // Bars
                const layer = svg
                    .selectAll('.stack')
                    .data(dataStackLayout)
                    .enter()
                    .append('g')
                    .attr('class', 'stack')
                    .style('opacity', 0.6)
                    .style('fill', BAR_COLOR);

                layer
                    .selectAll('rect')
                    .data(function (d) {
                        return d;
                    })
                    .enter()
                    .append('rect')
                    .attr('x', function (d) {
                        return x(d.x);
                    })
                    .attr('y', function (d) {
                        return y(d.y + d.y0);
                    })
                    .attr('height', function (d) {
                        // Show at least 1 pixel for non-zero values
                        const minHeight = d.y > 0 ? 1 : 0;
                        return Math.max(minHeight, y(d.y0) - y(d.y + d.y0));
                    })
                    .attr('width', x.rangeBand());

                if (planLimit >= 0) {
                    // Horizontal line indicating plan limit amount for this category
                    svg.append('g')
                        .attr('transform', 'translate(0, ' + y(planLimit) + ')')
                        .append('line')
                        .attr('x2', CHART_WIDTH)
                        .style('stroke', '#df1f48')
                        .style('stroke-width', '1px');

                    // Label for line
                    svg.append('text')
                        .attr('y', y(planLimit) - 3)
                        .attr('x', 0)
                        .attr('text-anchor', 'start')
                        .style('fill', '#df1f48')
                        .style('font-size', '10px')
                        .text('Plan: ' + d3.format('s')(planLimit));
                }
            }
        },
    ],
});
