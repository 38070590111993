import templateUrl from './communityError.html';
export default [
    'sfxModal',
    function (sfxModal) {
        function openModal() {
            sfxModal.open({
                templateUrl,
                backdrop: 'static',
                keyboard: false,
            });
        }

        return {
            openModal,
        };
    },
];
