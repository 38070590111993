export const createCallbackRegistry = [
    '$log',
    function ($log) {
        return function (registryName) {
            assertValidName(registryName);

            let callbacks = [];

            return {
                deregister,
                register,
                invokeAll,
                empty() {
                    callbacks = [];
                },
                size() {
                    return callbacks.length;
                },
            };

            function deregister(toDeregister) {
                // NOTE: Offering deregister as an alternative way of callback
                // deregistration since many of the places that use this pattern do
                // this instead of maintaining the deregistration that is returned on
                // registry.
                callbacks = callbacks.filter((callback) => callback !== toDeregister);
            }

            function register(cb) {
                if (typeof cb !== 'function') {
                    throw new Error(
                        `Non-function object with type ${typeof cb} registered to ${registryName}`
                    );
                }

                callbacks.push(cb);

                return () => {
                    deregister(cb);
                };
            }

            function invokeAll(...args) {
                callbacks.forEach((callback) => {
                    try {
                        callback(...args);
                    } catch (e) {
                        $log.warn(
                            `${registryName} threw exception for callback ${callback.name}`,
                            e
                        );
                    }
                });
            }
        };

        function assertValidName(name) {
            if (!name || typeof name !== 'string' || !name.length) {
                throw new Error(`Invalid callback registry name ${name}`);
            }
        }
    },
];
