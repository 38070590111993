import templateUrl from './autoThreshold.tpl.html';
import { SIGNALFX_GREEN } from '../../../../../legacy/common/consts';

angular.module('chartbuilderUtil').directive('autoThreshold', [
    'colorService',
    function (colorService) {
        return {
            scope: {
                thresholds: '=',
                color: '=',
                numGradients: '=?',
            },
            restrict: 'E',
            replace: true,
            templateUrl,
            link: function ($scope) {
                function setBand() {
                    let numShades = $scope.numGradients || 5;
                    numShades = Math.floor(numShades / 2);
                    const shades = colorService.getShades(
                        $scope.color || SIGNALFX_GREEN,
                        numShades
                    );
                    $scope.light = shades[0];
                    $scope.dark = shades[shades.length - 1];
                }
                $scope.$watchGroup(['color', 'numGradients'], setBand);
                setBand();
            },
        };
    },
]);
