angular.module('signalview.modal', ['signalview.modal']).directive('modalCloseButton', function () {
    return {
        restrict: 'E',
        scope: {
            size: '@',
            close: '&',
        },
        template:
            "<button class=\"pull-right icon-delete close-modal-button\" ng-class=\"{'small': size === 'small', 'medium': size !== 'small', 'close-modal-button-2': size !== 'small'}\" aria-label=\"Close\" ng-click=\"dismiss()\"></button>",
        link: function (scope) {
            scope.dismiss = function () {
                scope.close();
            };
        },
    };
});
