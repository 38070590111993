import templateUrl from './dashboardGroupSelector.tpl.html';

angular.module('signalview.dashboard').service('dashboardGroupSelectorService', [
    'sfxModal',
    '$rootScope',
    function (sfxModal, $rootScope) {
        return {
            promptForDashboardGroup,
        };

        function promptForDashboardGroup(title, message) {
            const scope = angular.extend($rootScope.$new(), { title, message });
            const modalParams = {
                scope,
                templateUrl,
                controller: 'dashboardGroupSelectorControl',
                bindToController: true,
                controllerAs: '$ctrl',
                windowClass: 'dashboard-selector-top',
                size: 'lg',
                backdrop: 'static',
                keyboard: true,
            };
            return sfxModal.open(modalParams).result;
        }
    },
]);
