angular.module('sfx.api.v2').service('homepageV2Service', [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        const resourceEndpoint = API_URL + '/v2/userpreferences/homepage';

        function setDashboardAsHomepage(homepageKeyInfo) {
            return $http.put(resourceEndpoint, homepageKeyInfo).then((response) => response.data);
        }

        return {
            resourceEndpoint: resourceEndpoint,
            setDashboardAsHomepage: setDashboardAsHomepage,
        };
    },
]);
