import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export const catalogHelper = [
    function () {
        this.isInFilter = function (object, filters) {
            if (!filters || !object.sf_key) return false;

            return filters.some(function (filter) {
                if (filter.property === 'sf_key') {
                    return filter.propertyValue === object.sf_topic;
                } else {
                    return (
                        filter.property === object.sf_key[0] &&
                        filter.propertyValue === object[filter.property]
                    );
                }
            });
        };

        this.getCapabilityForType = function (sf_type) {
            switch (sf_type) {
                case 'Metric':
                    return Capability.UPDATE_METRIC;
                case 'Tag':
                    return Capability.UPDATE_TAG;
                case 'Dimension':
                    return Capability.UPDATE_DIMENSION;
            }
            return null;
        };
    },
];
