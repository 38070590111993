angular
    .module('signalview.legal', [])

    .service('legalService', [
        '$sce',
        function ($sce) {
            this.privacyPolicy = $sce.trustAsResourceUrl(
                'https://www.splunk.com/en_us/legal/privacy/privacy-policy.html'
            );

            this.termsOfService = $sce.trustAsResourceUrl('https://splunk.com/GeneralTerms');

            this.termsOfUse = $sce.trustAsResourceUrl(
                'https://www.splunk.com/en_us/legal/terms/terms-of-use.html'
            );
        },
    ]);
