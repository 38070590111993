angular.module('signalview.orgUsage').controller('OrgUsageModalController', [
    '$scope',
    '$log',
    'organizationService',
    'urlOverridesService',
    'userAnalytics',
    'org',
    'subscriptionTypeUtil',
    'TimeParser',
    'timeZoneService',
    'featureEnabled',
    'SUPPORT_EMAIL',
    'PRODUCT_NAME',
    function (
        $scope,
        $log,
        organizationService,
        urlOverridesService,
        userAnalytics,
        org,
        subscriptionTypeUtil,
        TimeParser,
        timeZoneService,
        featureEnabled,
        SUPPORT_EMAIL,
        PRODUCT_NAME
    ) {
        const dateFormat = /^[0-1]\d\/[0-3]\d\/\d{4}$/;
        $scope.SUPPORT_EMAIL = SUPPORT_EMAIL;
        $scope.PRODUCT_NAME = PRODUCT_NAME;
        $scope.loading = false;
        $scope.reports = [];
        $scope.aggregateReports = [];
        $scope.dpdReports = [];
        $scope.isDailyReportDatePickerOpen = false;
        $scope.otelDocumentationLink =
            'https://quickdraw.splunk.com/redirect/?product=Observability&location=otel.migration.impact.report&version=current';

        $scope.tabToSelect = urlOverridesService.getTab();
        $scope.onTabSelected = (tabTitle) => urlOverridesService.setTab(tabTitle);

        const beginningOfToday = new Date().setHours(0, 0, 0, 0);
        const dayInMilliseconds = 24 * 60 * 60 * 1000;
        const previousDay = beginningOfToday - dayInMilliseconds;
        // initial with the previous hours
        $scope.hourlyReportTime = previousDay + (new Date().getHours() - 1) * 60 * 60 * 1000;
        $scope.hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        $scope.meridianArray = ['AM', 'PM'];
        $scope.dates = {
            // get yesterday's dpm per dimension report since today's hasn't been generated yet
            dimensionReport: previousDay,
            maxDate: beginningOfToday,
        };
        $scope.dates.dimensionReportStr = getTimestampStr($scope.dates.dimensionReport);
        initializeTimeSelector();

        $scope.setHour = function (hour) {
            $scope.selectedHour = hour;
            $scope.hourlyReportTime = getHourlyTimestamp(
                $scope.selectedHour,
                $scope.selectedMeridian
            );
        };

        $scope.setMeridian = function (meridian) {
            $scope.selectedMeridian = meridian;
            $scope.hourlyReportTime = getHourlyTimestamp(
                $scope.selectedHour,
                $scope.selectedMeridian
            );
        };

        $scope.supportSwitch = featureEnabled('supportSwitch');

        const info = {
            org: org.organizationName,
            orgId: org.id,
        };

        function initializeTimeSelector() {
            // Set the previous hour as selected because current hour's report might not be generated.
            const currentHour = new Date().getHours() - 1;
            $scope.selectedMeridian = 'AM';
            if (currentHour >= 12) {
                $scope.selectedMeridian = 'PM';
            }
            $scope.selectedHour = currentHour === 12 ? 12 : currentHour % 12;
        }

        function getHourlyTimestamp(selectedHour, selectedMeridian) {
            if (selectedMeridian === 'AM') {
                selectedHour = selectedHour % 12;
            } else {
                selectedHour = (selectedHour % 12) + 12;
            }
            // check for date picker object initially setting Date object rather than ms number
            const time = $scope.dates.dimensionReport.getTime
                ? $scope.dates.dimensionReport.getTime()
                : $scope.dates.dimensionReport;
            return time + selectedHour * 60 * 60 * 1000;
        }

        $scope.nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(org.accountSubscriptionType);
        $scope.hostBasedOrg = subscriptionTypeUtil.isHostBased(org.accountSubscriptionType);
        $scope.accountSubscriptionType = org.accountSubscriptionType;

        function loadImpactReports() {
            $scope.otelMigrationImpactReportsLoading = true;

            organizationService
                .availableImpactReports()
                .then(
                    (impactReports) => {
                        $scope.getImpactReportUrl = (report) => report?.SHORTLIVED_URL?.URL;
                        $scope.otelMigrationImpactReports =
                            impactReports?.['OTEL migration impact reports'];
                        $scope.hasOtelMigrationImpactReports =
                            Array.isArray($scope.otelMigrationImpactReports) &&
                            $scope.otelMigrationImpactReports.length;

                        userAnalytics.event('impact-reports', 'load-success');
                    },
                    () => {
                        $log.error('Failed fetching impact reports for ', info);
                        userAnalytics.event('impact-reports', 'load-error');
                    }
                )
                .finally(() => ($scope.otelMigrationImpactReportsLoading = false));
        }

        function loadReports() {
            $scope.loading = true;
            organizationService
                .availableUsageReports(12, 7)
                .then(
                    (usageReports) => {
                        // add all types of monthly reports
                        $scope.reports.push(
                            ...usageReports['Monthly Datapoints per Minute Reports'],
                            ...usageReports['Monthly Host per Hour Reports'],
                            ...usageReports['Monthly MTS per Hour Reports']
                        );
                        $scope.reports.sort(sortByDateAndSubscriptionType);

                        // add all types of monthly aggregate reports
                        $scope.aggregateReports.push(
                            ...usageReports['Monthly Aggregate Datapoints per Minute Reports'],
                            ...usageReports['Monthly Aggregate Hosts per Hour Reports'],
                            ...usageReports['Monthly Aggregate MTS per Hour Reports']
                        );
                        $scope.aggregateReports.sort(sortByDateAndSubscriptionType);

                        $scope.hasAggregateReports = checkForAggregateReports(
                            $scope.aggregateReports
                        );

                        userAnalytics.event('usage-reports', 'load-success');
                    },
                    function () {
                        $log.error('Failed fetching org reports for ', info);
                        userAnalytics.event('usage-reports', 'load-error');
                    }
                )
                .finally(function () {
                    $scope.loading = false;
                    $scope.$applyAsync();
                });
        }

        function sortByDateAndSubscriptionType(a, b) {
            a = new Date(a.DATE);
            b = new Date(b.DATE);
            if (a > b) {
                return -1;
            } else if (a < b) {
                return 1;
            } else {
                // if equal timestamps check account type
                // to ensure current account type is listed most recently
                if (org.accountSubscriptionType === a.REPORT_TYPE) {
                    return 1;
                } else {
                    return -1;
                }
            }
        }

        function checkForAggregateReports(report) {
            return report !== null && angular.isDefined(report) && report.length > 0;
        }

        loadReports();
        loadImpactReports();
        getDailyReport($scope.dates.dimensionReport);
        getHourlyReport($scope.hourlyReportTime);

        $scope.$watch('dates.dimensionReportStr', function (nval, oval) {
            if (nval !== oval) {
                const newTime = new TimeParser(nval);
                if (newTime && newTime.utc > 0 && dateFormat.test(nval)) {
                    $scope.invalidReportDateStr = false;
                    $scope.dates.dimensionReport = newTime.utc;
                    getDailyReport(newTime.utc);
                } else {
                    $scope.invalidReportDateStr = true;
                }
            }
        });

        $scope.$watch('dates.dimensionReport', function (nval, oval) {
            if (nval !== oval) {
                $scope.hourlyReportTime = getHourlyTimestamp(
                    $scope.selectedHour,
                    $scope.selectedMeridian
                );
                $scope.dates.dimensionReportStr = getTimestampStr($scope.dates.dimensionReport);
            }
        });

        $scope.$watch('hourlyReportTime', function (nval, oval) {
            if (nval !== oval) {
                getHourlyReport($scope.hourlyReportTime);
            }
        });

        function getTimestampStr(date) {
            return timeZoneService.moment(date).format('MM/DD/YYYY') || '';
        }

        function getDpmPerDimensionReport(time) {
            $scope.dailyReports = { loading: true, time };
            return organizationService.getDpmPerDimensionReport(time).then(
                (report) => {
                    $scope.dailyReports = { loading: false, dpmReport: [report], time };
                },
                () => {
                    $scope.dailyReports = { loading: false, time };
                }
            );
        }

        function getDailyReport(time) {
            if (!$scope.nonLegacyOrg) {
                return getDpmPerDimensionReport(time);
            }

            $scope.dailyReports = { loading: true, time };
            return organizationService.getDailyReport(time).then(
                (report) => {
                    $scope.dailyReports = { loading: false, time };

                    const customMetricReport = report.CMR;
                    const dimensionReport = report.MDR || report.DPD;
                    if (customMetricReport) {
                        $scope.dailyReports.customMetricReports = [customMetricReport];
                    }
                    if (dimensionReport) {
                        $scope.dailyReports.dimensionReports = [dimensionReport];
                        if (!report.MDR) {
                            dimensionReport.help = {
                                text: 'This report is formatted differently.',
                                link: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=older.report.learnmore&version=current',
                                linkLabel: 'Learn more about older reports',
                            };
                        }
                    }
                },
                () => {
                    $scope.dailyReports = { loading: false, time };
                }
            );
        }

        function getHourlyReport(time) {
            $scope.hourlyReport = { loading: true, time };
            return organizationService.getHourlyReport(time).then(
                (report) => {
                    $scope.hourlyReport.loading = false;
                    $scope.hourlyReport.report = [report];
                },
                () => {
                    $scope.hourlyReport = { loading: false, time };
                }
            );
        }
    },
]);
