import React from 'react';
import PropTypes from 'prop-types';
import Select from '@splunk/react-ui/Select';
import AccessControlPermissionTypeIcon from './AccessControlPermissionTypeIcon';

export default function AccessControlPermissionTypeSelector(props) {
    return (
        <Select {...props} inline={false}>
            {props.options.map((option, index) => (
                <Select.Option
                    key={index}
                    label={option.label}
                    value={option.value}
                    icon={
                        <AccessControlPermissionTypeIcon
                            typeId={option.value}
                            style={{ marginRight: 8 }}
                            iconClass={option.iconClass}
                        />
                    }
                />
            ))}
        </Select>
    );
}

AccessControlPermissionTypeSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.array,
};

AccessControlPermissionTypeSelector.defaultProps = {
    onChange: () => {},
    disabled: false,
};
