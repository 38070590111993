import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import BEMHelper from 'react-bem-helper';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import AccessControlObjectType from './AccessControlObjectType';
import AccessControlPermissionActionsSerializer from './AccessControlPermissionActionsSerializer';
import accessControlHelper from './accessControlHelper';
import { StyledButton, StyledDescription, StyledLabel } from '../ModalStyles';
import SimpleSuggestion from './SimpleSuggestion';
import AccessControlPermissionAction from './AccessControlPermissionAction';
import { AclFormContext } from './AclFormContext';
import Select from '@splunk/react-ui/Select';
import Button from '@splunk/react-ui/Button';
import Tooltip from '@splunk/react-ui/Tooltip';
import TransitionOpen from '@splunk/react-ui/TransitionOpen';
import Cross from '@splunk/react-icons/Cross';
import ChevronRight from '@splunk/react-icons/ChevronRight';
import ChevronDown from '@splunk/react-icons/ChevronDown';
import AccessControlPermissionTypeSelector from './AccessControlPermissionTypeSelector';
import PRINCIPAL_TYPE from './AccessControlPrincipalType';
import Message from '@splunk/react-ui/Message';

const ACL_LIMIT = 25;

const className = new BEMHelper('access-control');

const StyledTable = styled.table`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    font-size: 14px;
    background-color: ${variables.backgroundColorDialog};
    border: 1px solid ${variables.interactiveColorBorder};

    & thead tr {
        background-color: ${variables.backgroundColorPopup};
        border-bottom: 1px solid ${variables.interactiveColorBorder};
    }

    & tbody tr:not(:last-child) {
        border-bottom: 1px solid ${variables.interactiveColorBorder};
    }
`;

const StyledExpandButton = styled(Button)`
    position: absolute;
    left: 0px;
    top: 4px;
`;

export default function AccessControl({
    objectType,
    customObjectLabel,
    permissions,
    parentPermissions,
    onChangeCallback,
    readonly,
    initiallyExpanded,
    parentObjectSelector,
    description,
    isDecorativeIcon = false,
}) {
    const [principalsData, setPrincipalsData] = useState([]);
    const [newItem, setNewItem] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const permissionsForType = useRef({});

    const selectedPermissionType = accessControlHelper.detectPermissionType(permissions?.acl);
    const { userId, orgId, principalsDataRepository, syncInProgress, restrictTeamAccess } =
        useContext(AclFormContext);

    useEffect(() => {
        setIsExpanded(initiallyExpanded || isExpandedByDefault(selectedPermissionType));
        getPrincipalData(permissions, parentPermissions).then((principalsData) =>
            setPrincipalsData(principalsData)
        );
    }, [permissions, parentPermissions]); // eslint-disable-line

    const permissionOptions = getPermissionOptions();

    return (
        <section>
            {!syncInProgress && hasNotRecommendedPermissionType() && (
                <div className="alert alert-warning text-left">
                    <div className="text-left">
                        {accessControlHelper.getInvalidPermissionTypeErrorMessage(
                            objectType,
                            customObjectLabel,
                            readonly
                        )}
                    </div>
                </div>
            )}
            <StyledLabel>{customObjectLabel || objectType.label} permissions</StyledLabel>
            {description && <StyledDescription>{description}</StyledDescription>}
            <div {...className({ extra: 'state--tabular' })}>
                <div {...className('permission-type')}>
                    <StyledExpandButton
                        aria-expanded={isExpanded}
                        size="small"
                        appearance="secondary"
                        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                        icon={
                            isExpanded ? (
                                <ChevronDown
                                    aria-label={`Close ${
                                        customObjectLabel || objectType.label
                                    } permissions`}
                                />
                            ) : (
                                <ChevronRight
                                    aria-label={`Open ${
                                        customObjectLabel || objectType.label
                                    } permissions`}
                                />
                            )
                        }
                    />
                    <AccessControlPermissionTypeSelector
                        options={permissionOptions}
                        value={selectedPermissionType.id}
                        onChange={(e, { value }) => onPermissionTypeChange(value)}
                        disabled={readonly || permissionOptions.length < 2}
                    />
                    {selectedPermissionType === AccessControlPermissionTypes.INHERIT &&
                        parentObjectSelector}
                </div>
                {selectedPermissionType === AccessControlPermissionTypes.INHERIT && (
                    <StyledDescription>{getInheritPermissionsWarningMessage()}</StyledDescription>
                )}
                <TransitionOpen animation="slideFromTop" open={isExpanded}>
                    <div {...className('permissions')}>
                        <StyledTable {...className('table')}>
                            <thead>
                                <tr {...className('table-header')}>
                                    <th {...className('table-cell', 'item')}>Team/User</th>
                                    <th {...className('table-cell', 'permissions')}>Permissions</th>
                                    <th {...className('table-cell', 'remove')}>&nbsp;</th>
                                </tr>
                            </thead>

                            <tbody {...className('table-body')}>
                                {principalsData.map((principal, index) => (
                                    <tr key={index} {...className('table-row')}>
                                        <td {...className('table-cell')}>
                                            <div {...className('table-item-avatar')}>
                                                {principal.icon && (
                                                    <img
                                                        src={principal.icon}
                                                        alt={
                                                            isDecorativeIcon ? '' : principal.label
                                                        }
                                                    />
                                                )}
                                                {principal.iconClassName && (
                                                    <span className={principal.iconClassName} />
                                                )}
                                            </div>
                                            <span {...className('table-item-name')}>
                                                {principal.label}
                                            </span>
                                        </td>
                                        <td {...className('table-cell', 'permissions')}>
                                            {renderActions(principal)}
                                        </td>
                                        <td {...className('table-cell', 'remove')}>
                                            {!selectedPermissionType.readonly &&
                                                isRemovable(principal) && (
                                                    <Button
                                                        size="small"
                                                        appearance="secondary"
                                                        onClick={() => onRemoveClick(principal.id)}
                                                        icon={<Cross />}
                                                        aria-label="Remove"
                                                    />
                                                )}
                                        </td>
                                    </tr>
                                ))}
                                {!selectedPermissionType.readonly && newItem && renderNewItem()}
                            </tbody>
                        </StyledTable>
                        {renderNewItemButton()}
                    </div>
                </TransitionOpen>
            </div>
            {hasTeamPermissionType() && !restrictTeamAccess && (
                <Message type="warning">
                    You are currently giving permissions to a team with restrict access disabled.
                    This means any user may join this team and will be able to access this{' '}
                    {objectType.label?.toLowerCase()}
                </Message>
            )}
        </section>
    );

    function renderNewItemButton() {
        const aclLimitReached = principalsData.length >= ACL_LIMIT;
        const canAddNew = !readonly && !selectedPermissionType.readonly && null === newItem;

        return (
            canAddNew && (
                <Tooltip
                    style={{ maxWidth: 300 }}
                    content={
                        aclLimitReached && accessControlHelper.getAclLimitReachedMessage(ACL_LIMIT)
                    }
                >
                    <StyledButton
                        label="Add team or user"
                        appearance="primary"
                        size="small"
                        disabled={aclLimitReached}
                        onClick={() =>
                            setNewItem({
                                actions:
                                    accessControlHelper.getDefaultActions(selectedPermissionType),
                            })
                        }
                    />
                </Tooltip>
            )
        );
    }

    function renderNewItem() {
        return (
            <tr key={'new-item'} {...className('table-row')}>
                <td {...className('table-cell')}>
                    <SimpleSuggestion
                        mode={'selectAndDestroy'}
                        placeholder="Enter team or user name"
                        searchProvider={search}
                        onSelectCallback={onPrincipalSelect}
                    />
                </td>
                <td {...className('table-cell', 'permissions')}>{renderActions(newItem)}</td>
                <td {...className('table-cell', 'remove')}>
                    <Button
                        size="small"
                        appearance="secondary"
                        onClick={() => setNewItem(null)}
                        icon={<Cross />}
                        aria-label="Remove"
                    />
                </td>
            </tr>
        );
    }

    function getPrincipalData(permissions, parentPermissions) {
        if (permissions?.acl) {
            return principalsDataRepository.getPrincipalsData(permissions.acl);
        }
        if (permissions?.parent && parentPermissions?.acl) {
            return principalsDataRepository.getPrincipalsData(parentPermissions.acl);
        }
        return Promise.resolve([]);
    }

    function getPermissionOptions() {
        const permissionTypes = Object.values(objectType.permissionTypes).map((permissionType) => ({
            value: permissionType.id,
            label: getPermissionTypeLabel(permissionType),
            iconClass: permissionType.iconClass,
        }));

        // add current permission type to allow displaying edge-cases with mismatched acl's
        if (hasNotRecommendedPermissionType()) {
            permissionTypes.push({
                value: selectedPermissionType.id,
                label: `${getPermissionTypeLabel(selectedPermissionType)} (unsupported)`,
            });
        }

        return permissionTypes;
    }

    function hasNotRecommendedPermissionType() {
        return !objectType.permissionTypes.find((item) => selectedPermissionType.id === item.id);
    }

    function hasTeamPermissionType() {
        return permissions?.acl?.some((item) => item.principalType === PRINCIPAL_TYPE.TEAM);
    }

    function isRemovable(permissionItem) {
        return !readonly && permissionItem.id !== orgId;
    }

    function onPrincipalSelect(selected) {
        const newAcl = Array.isArray(permissions.acl) ? [...permissions.acl] : [];
        newAcl.push({
            ...newItem,
            principalType: selected.__principalType,
            principalId: selected.id,
        });

        setNewItem(null);
        onAclChange(newAcl);
    }

    function onPermissionTypeChange(newPermissionTypeId) {
        permissionsForType.current[selectedPermissionType.id] = permissions.acl;
        if (permissionsForType.current[newPermissionTypeId]) {
            onAclChange(permissionsForType.current[newPermissionTypeId]);
        } else {
            onAclChange(
                accessControlHelper.getDefaultAcl(
                    orgId,
                    userId,
                    AccessControlPermissionTypes[newPermissionTypeId]
                )
            );
        }
    }

    function isExpandedByDefault(permissionType) {
        return [
            AccessControlPermissionTypes.RESTRICTED_WRITE,
            AccessControlPermissionTypes.RESTRICTED,
        ].includes(permissionType);
    }

    function onRemoveClick(principalId) {
        const newAcl = (permissions.acl || []).filter((item) => item.principalId !== principalId);
        onAclChange(newAcl);
    }

    function onAclChange(newAcl) {
        onChangeCallback({ ...permissions, acl: newAcl });
    }

    function search(phrase) {
        const activeIds = (permissions.acl || []).map((item) => item.principalId);
        return principalsDataRepository
            .search(phrase)
            .then((results) => results.flatten())
            .then((results) => results.filter((item) => !activeIds.includes(item.id)));
    }

    function renderActions(aclItem) {
        const actionLabelMapper = getActionLabelMapper();
        const initOptions =
            objectType.actions && objectType.actions.length > 0
                ? objectType.actions
                : [
                      [AccessControlPermissionAction.READ],
                      [AccessControlPermissionAction.READ, AccessControlPermissionAction.WRITE],
                  ];
        const options = initOptions.map((item) => ({
            value: AccessControlPermissionActionsSerializer.serialize(item),
            label: actionLabelMapper(AccessControlPermissionActionsSerializer.serialize(item)),
            actions: item,
        }));

        function onPermissionActionsChange(value) {
            const newActions = options.find((option) => option.value === value).actions;

            // new item modified
            if (!aclItem.id) {
                return setNewItem({ ...newItem, actions: newActions });
            }

            const newAcl = (permissions.acl || []).map((item) => {
                if (item.principalId !== aclItem.id) {
                    return item;
                }

                return {
                    ...item,
                    actions: newActions,
                };
            });

            onAclChange(newAcl);
        }

        if (
            !readonly &&
            selectedPermissionType === AccessControlPermissionTypes.RESTRICTED &&
            options.length > 1
        ) {
            return (
                <Select
                    value={AccessControlPermissionActionsSerializer.serialize(aclItem.actions)}
                    onChange={(e, { value }) => onPermissionActionsChange(value)}
                    size="small"
                    inline={false}
                >
                    {options.map((option, index) => (
                        <Select.Option key={index} label={option.label} value={option.value} />
                    ))}
                </Select>
            );
        }

        return actionLabelMapper(
            AccessControlPermissionActionsSerializer.serialize(aclItem.actions)
        );
    }

    function getActionLabelMapper() {
        // you can provide custom action label mapper for specific object type
        if ('function' === typeof objectType.actionLabelMapper) {
            return objectType.actionLabelMapper;
        }
        return (serialized) => serialized;
    }

    function getInheritPermissionsWarningMessage() {
        if (objectType.parent) {
            return `${
                customObjectLabel || objectType.label
            } permissions will be inherited from the ${objectType.parent.label?.toLowerCase()} you save it to.`;
        }
    }

    function getPermissionTypeLabel(permissionType) {
        if (permissionType === AccessControlPermissionTypes.INHERIT && objectType.parent !== null) {
            return (AccessControlPermissionTypes.INHERIT.label || '').replace(
                '$PARENT_OBJECT_TYPE',
                objectType.parent.label?.toLowerCase()
            );
        }

        return permissionType.label;
    }
}

AccessControl.propTypes = {
    permissions: PropTypes.shape({
        acl: PropTypes.array,
        parent: PropTypes.string,
    }),
    parentPermissions: PropTypes.shape({
        acl: PropTypes.array,
        parent: PropTypes.string,
    }),
    objectType: PropTypes.oneOf(Object.values(AccessControlObjectType)),
    customObjectLabel: PropTypes.string,
    onChangeCallback: PropTypes.func,
    initiallyExpanded: PropTypes.bool,
    readonly: PropTypes.bool,
    parentObjectSelector: PropTypes.element,
    description: PropTypes.node,
    isDecorativeIcon: PropTypes.bool,
};

AccessControl.defaultProps = {
    readonly: false,
};
