import React, {
    FC,
    ReactElement,
    ReactNode,
    Reducer,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import {
    Route as ReactRoute,
    Switch as ReactSwitch,
    RouteProps,
    SwitchProps,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import { AngularInjection, AngularInjector } from '../../common/AngularUtils';
import qs from 'query-string';
import { ErrorBoundary } from '../ErrorBoundary';
import { QueryParamType, RouteContext, useRouteContext } from './RouteContext';
import { ngRoute } from './ngRoute';
import { updateTitle } from '../../legacy/common/ui/title/title';
import ContentError from './ContentError';
import { isEqual } from 'lodash';
import { useMemoGuaranteed } from '@splunk/olly-common/hooks';
import { useFeatureFlags } from '@splunk/olly-services/lib/services/FeatureFlag/FeatureFlagStoreProvider';

type StrapSimpleCallback = (routeParams: QueryParamType) => any;
type StrapProps = {
    resolves?: Record<string, AngularInjection | StrapSimpleCallback>;
    straps?: Array<(routeParams: QueryParamType) => Promise<never>>;
    reStraps?: boolean;
};

const STRAP_TIMEOUT = 5000;
const loading = <div className="loading-shroud" />;

type ResolutionState = { key: number; resolves?: Record<string, any> };
export const Strap: FC<StrapProps> = ({ resolves, straps, children, reStraps = false }) => {
    // Keeping resolutions and component key as part of same state update
    const [resolutions, setResolutions] = useReducer<
        Reducer<ResolutionState, Pick<ResolutionState, 'resolves'> | undefined>
    >((prevState, resolves) => ({ key: prevState.key + 1, resolves }), { key: 0 });

    const [strapped, setStrapped] = useState<boolean>(false);
    const [strapEverytime] = useState(reStraps);
    const { params, pathname } = useRouteContext();
    const match = useRouteMatch();
    const { initialized } = useFeatureFlags();

    useEffect(() => {
        setResolutions(undefined);
        setStrapped(false);
    }, [match.path]);

    useEffect(
        () => {
            if (!straps) {
                return setStrapped(true);
            }

            let bailOut = false;
            const timeout = setTimeout(() => {
                console.error('Strap: Strapping the route took longer than expected!');
            }, STRAP_TIMEOUT);

            const onCompletion = (): void => {
                bailOut = true;
                clearTimeout(timeout);
            };

            // Re-strap everytime straps equality changes if reStraps is set to true.
            // For same path re-render not take place if the route is simply re-strapping.
            Promise.all(straps.map((callback) => callback(params)))
                .then(() => !bailOut && setStrapped(true))
                .finally(onCompletion);

            return onCompletion;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        strapEverytime ? [straps, pathname] : [pathname]
    );

    useEffect(() => {
        if (!resolves) {
            setResolutions({});
            return;
        }

        const resolvePromises = Object.entries(resolves).map(([key, callback]) => {
            const resolvePromise = AngularInjector.isAngularInjection(callback)
                ? AngularInjector.instantiate(callback, { params })
                : callback(params);

            return Promise.resolve(resolvePromise).then((resolved) => [key, resolved]);
        });

        let bailOut = false;

        const timeout = setTimeout(() => {
            console.error('Strap: Resolving the route took longer than expected!');
        }, STRAP_TIMEOUT);

        const onCompletion = (): void => {
            bailOut = true;
            clearTimeout(timeout);
        };

        Promise.all(resolvePromises)
            .then((strappedEntries) => {
                if (bailOut) {
                    return;
                }

                setResolutions(Object.fromEntries(strappedEntries));
            })
            .finally(onCompletion);

        return onCompletion;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const cloned = useMemoGuaranteed(() => {
        const { resolves, key: mountKey } = resolutions;
        if (!resolves || !strapped || !initialized) {
            return loading;
        }

        if (Array.isArray(children)) {
            return React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    const key = `${child.key ?? ''}_${mountKey}`;
                    return React.cloneElement(child, { ...resolves, key });
                } else {
                    throw new Error('Route children is not a valid element');
                }
            });
        } else {
            if (React.isValidElement(children)) {
                const key = `${children.key ?? ''}_${mountKey}`;
                return React.cloneElement(children, { ...resolves, key });
            } else {
                throw new Error('Route children is not a valid element');
            }
        }
    }, [strapped, resolutions, initialized]); // eslint-disable-line react-hooks/exhaustive-deps

    return <>{cloned}</>;
};

type ExtendedRouteProps = Omit<RouteProps, 'component'> &
    Omit<StrapProps, 'reStraps'> & {
        title?: string;
        reStraps?: boolean;
        reloadWithParams?: boolean | 'path' | 'search';
    };

export const Switch = ({ children, ...props }: SwitchProps): ReactElement => (
    <ReactSwitch {...props}>
        {children}
        <Route path="*" title="Data not found">
            <ContentError missing />
        </Route>
    </ReactSwitch>
);

enum RouteState {
    NEED_DECLARATION,
    DECLARED,
    NOT_MOUNTED,
}

export const WrappedRoute: FC<ExtendedRouteProps> = (props) => {
    const {
        children,
        title,
        resolves,
        straps,
        reStraps = false,
        reloadWithParams = false,
        ...routeProps
    } = props;
    const currentUser = AngularInjector.useInjectedClass('currentUser');
    const $rootScope = AngularInjector.useInjectedClass('$rootScope');
    const provideRouteChangeContext = props.exact && props.path !== '*';
    const routeState = useRef<RouteState>(RouteState.NEED_DECLARATION);
    const [lastParams, setLastParams] = useState<[Record<string, string | undefined>, string]>([
        {},
        '',
    ]);
    const [reload, setReload] = useState(false);
    const { pathname } = useLocation();
    const lastRouteChangeDeps = useRef<any[]>([]);
    const currentRouteChangeDeps = [pathname];

    const logService = AngularInjector.instantiate('logService');

    if (
        provideRouteChangeContext &&
        // If this is an unmount - mount cycle
        (routeState.current === RouteState.NOT_MOUNTED ||
            // Check on deps if the component is not set to reload with with path change
            // Otherwise, unmount - mount cycle will take of this.
            (!(reloadWithParams === 'path' || reloadWithParams === true) &&
                currentRouteChangeDeps.some((dep) => !lastRouteChangeDeps.current.includes(dep))))
    ) {
        lastRouteChangeDeps.current = currentRouteChangeDeps;

        // This route is going to render, broadcast to all watchers
        $rootScope.$broadcast('React:$routeChangeStart');

        // This route also need to fire corresponding routeChangeSuccess
        routeState.current = RouteState.NEED_DECLARATION;
    }

    // If this is a unmount - mount cycle,
    // move the route state out of it and back to need declaration
    if (routeState.current === RouteState.NOT_MOUNTED) {
        routeState.current = RouteState.NEED_DECLARATION;
    }

    useEffect(() => {
        if (title) {
            updateTitle(title);
        }

        // ensure each route checks for a current user
        currentUser.id();
    }, [title, currentUser]);

    return (
        <ReactRoute
            {...routeProps}
            render={(routeProps): ReactNode => {
                const routeParams = routeProps.match.params;
                const search = routeProps.location.search;
                const [lastRouteParams, lastSearch] = lastParams;

                // This block sequences unMount - Mount Sequence for the Route.
                // It effectively acts as if force reloading the route without any other changes.
                {
                    const callbacks: React.SetStateAction<any>[] = [];

                    if (reloadWithParams) {
                        let shouldReload =
                            (reloadWithParams === 'search' || reloadWithParams === true) &&
                            lastSearch !== search;

                        shouldReload =
                            shouldReload ||
                            ((reloadWithParams === 'path' || reloadWithParams === true) &&
                                !isEqual(lastRouteParams, routeParams));

                        if (shouldReload) {
                            logService.logData(
                                'INFO',
                                'ReactRouteUtils.tsx -> ReactRouteRender',
                                shouldReload
                            );

                            callbacks.push(() => setLastParams([routeParams, search]));
                            logService.logData(
                                'INFO',
                                'ReactRouteUtils.tsx -> on shouldReload flag ReactRouteRender',
                                callbacks
                            );
                        }
                    }
                    if (reload) {
                        callbacks.push(() => setReload(false));
                        logService.logData(
                            'INFO',
                            'ReactRouteUtils.tsx -> on reload ReactRouteRender ',
                            callbacks
                        );
                    }

                    if (callbacks.length) {
                        setTimeout(() => {
                            // Move route state to not mounted
                            // This state forces a one time only re-declaration action from this component
                            routeState.current = RouteState.NOT_MOUNTED;
                            callbacks.forEach((callback) => callback());
                        });

                        return <></>;
                    }
                }

                let toRender: ReactNode = props.render
                    ? props.render(routeProps) || loading
                    : children;

                if (!toRender) {
                    return <></>;
                } else if (straps || resolves) {
                    toRender = (
                        <Strap resolves={resolves} straps={straps} reStraps={reStraps}>
                            {toRender}
                        </Strap>
                    );
                }

                if (provideRouteChangeContext) {
                    logService.logData(
                        'INFO',
                        'ReactRouteUtils.tsx -> on reload ReactRouteRender ',
                        provideRouteChangeContext
                    );

                    const parsedSearch = qs.parse(search) as QueryParamType;

                    // This might not work if props.paths is array and
                    // transition is within the paths of this Route.
                    const currentRoute = ngRoute.setRoute(
                        {
                            matchedPath: routeProps.match.path,
                            pathname: routeProps.location.pathname,
                            url: `${routeProps.location.pathname}${search}`,
                            search: parsedSearch,
                            pathParams: routeParams,
                            reloadPath: () => setReload(true),
                        },
                        routeState.current === RouteState.NEED_DECLARATION
                    );
                    routeState.current = RouteState.DECLARED;

                    logService.logData('INFO', 'ReactRouteUtils.tsx -> currentRoute', currentRoute);
                    toRender = (
                        <RouteContext.Provider value={currentRoute}>
                            {toRender}
                        </RouteContext.Provider>
                    );
                }

                return <ErrorBoundary>{toRender}</ErrorBoundary>;
            }}
        />
    );
};

export const KeyedWrappedRoute: FC<ExtendedRouteProps> = (props) => {
    // Path based keys make sure react renders a completely new component on Path change and
    // does not end up re-using old component and old state.
    const path = props.path ?? '*';
    return (
        <WrappedRoute key={Array.isArray(path) ? path.join(',') : (path as string)} {...props} />
    );
};

export const Route = KeyedWrappedRoute;

export const useInitializeRoute = (): StrapSimpleCallback => {
    const routeUtils = AngularInjector.useInjectedClass('routeUtils');
    return useMemoGuaranteed(() => {
        return (params: QueryParamType): Promise<any> => routeUtils.initializeRoute(params);
    }, [routeUtils]);
};
