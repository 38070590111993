'use strict';
import templateUrl from './detectorSettingsModal.tpl.html';
import subscribeToExistingDetectorModalTemplateUrl from './subscribeToExistingDetectorModal.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export default [
    'sfxModal',
    'writepermissionsPermissionsChecker',
    function (sfxModal, writepermissionsPermissionsChecker) {
        function showModal(tabToSelect, detector) {
            return writepermissionsPermissionsChecker
                .hasWritePermissions(detector)
                .then(function (hasWritePermissions) {
                    return sfxModal.open({
                        templateUrl,
                        controller: [
                            '$scope',
                            'featureEnabled',
                            'hasCapability',
                            function ($scope, featureEnabled, hasCapability) {
                                hasCapability(Capability.UPDATE_DETECTOR).then(
                                    (hasUpdateDetectorCapability) => {
                                        $scope.hasUpdateDetectorCapability =
                                            hasUpdateDetectorCapability;
                                    }
                                );
                                $scope.detector = detector;
                                $scope.editable = hasWritePermissions;
                                $scope.tabToSelect = tabToSelect;
                                $scope.writePermissionsEnabled = featureEnabled('writepermissions');
                                $scope.$on('dismiss modal', function () {
                                    $scope.$dismiss();
                                });

                                $scope.$on('close modal', function ($event, res) {
                                    $scope.$close(res);
                                });
                            },
                        ],
                        windowClass: 'object-settings-modal',
                        backdrop: 'static',
                        keyboard: false,
                    }).result;
                });
        }

        function showSubscribeDetectorModal(detector) {
            sfxModal.open({
                templateUrl: subscribeToExistingDetectorModalTemplateUrl,
                controller: 'SubscribeToExistingDetectorController',
                size: 'md',
                resolve: {
                    data: function () {
                        return {
                            detector: detector,
                        };
                    },
                    hasWritePermission:
                        writepermissionsPermissionsChecker.hasWritePermissions(detector),
                },
                backdrop: true,
            });
        }

        return {
            info: showModal.bind(null, 'Detector Info'),
            permissions: showModal.bind(null, 'Permissions'),
            showSubscribeDetectorModal: showSubscribeDetectorModal,
        };
    },
];
