// This http interceptor listens for X-SF-TOKEN in response headers and stores them in local-storage. It also adds the stored X-SF-TOKEN value
// back in the request header, if the value is found in local storage.
export default [
    '$injector',
    'auth',
    'API_URL',
    'sdContentCreationAuth',
    '$window',
    function ($injector, auth, API_URL, sdContentCreationAuth, $window) {
        return {
            request: function (config) {
                const authHeaders = auth.authHeaders();
                for (const authHeader in authHeaders) {
                    config.headers[authHeader] = authHeaders[authHeader];
                }

                // unfortunately, featureEnabled here would cause a circular dependency so heres the workaround.
                if ($window.sdContentCreationAuthToken && config.url.includes('/suggest')) {
                    config.headers['X-SF-TOKEN'] = sdContentCreationAuth.get();
                }

                if (config.url.search(API_URL) !== -1) {
                    const metrics = $injector.get('signalviewMetrics');
                    metrics.startTime('ui.signalboost.time', {}, config.url);
                    metrics.startTime(
                        'ui.signalboost.' + config.method.toLowerCase() + '.time',
                        {},
                        config.url
                    );
                }
                return config;
            },
            response: function (response) {
                if (response.config.url.search(API_URL) !== -1) {
                    const metrics = $injector.get('signalviewMetrics');
                    metrics.endTime('ui.signalboost.time', {}, response.config.url);
                    metrics.endTime(
                        'ui.signalboost.' + response.config.method.toLowerCase() + '.time',
                        {},
                        response.config.url
                    );
                }
                auth.updateTokenFromResponse(response);
                return response;
            },
        };
    },
];
