import ControlGroup from '@splunk/react-ui/ControlGroup';
import Text from '@splunk/react-ui/Text';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { AclFormContext } from './AclFormContext';

export default function AclFormInput({ autoFocus, name, label, value, onChange }) {
    const context = useContext(AclFormContext);
    const elementFocus = useRef(null);

    useEffect(() => {
        if (autoFocus && elementFocus?.current && !context.disabled) {
            elementFocus?.current?.focus();
        }
    }, [autoFocus, elementFocus, context.disabled]);

    return (
        <ControlGroup label={label} labelPosition="top">
            <Text
                value={value}
                name={name}
                autoComplete="off"
                onChange={onChange}
                disabled={context.disabled}
                ref={elementFocus}
                data-test="dashboard-group-name"
            />
        </ControlGroup>
    );
}

AclFormInput.propTypes = {
    autoFocus: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

AclFormInput.defaultProps = {
    onChange: () => {},
};
