import templateUrl from './chartModeSwitcher.tpl.html';

export const chartModeSwitcher = [
    'chartVisualizationService',
    function (chartVisualizationService) {
        return {
            scope: {
                uiModel: '=',
                disallowedModes: '=?',
            },
            templateUrl,
            restrict: 'E',
            link: function (scope) {
                scope.allChartModes = chartVisualizationService.getChartModes();

                scope.changeChartMode = function (mode) {
                    scope.uiModel.chartMode = mode;
                };

                scope.$on('chartTypeChanged', function (evt, newType) {
                    scope.uiModel.chartMode = newType.mode;
                });

                scope.$on('plot alt mode apply', function (evt, altModeDisplayedKey, altModeOn) {
                    scope.disallowedModes = scope.disallowedModes || {};
                    scope.disallowedModes['heatmap'] = altModeOn;
                });
            },
        };
    },
];
