'use strict';
import templateUrl from './dashboardSettingsModal.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export default [
    'sfxModal',
    '$q',
    'permissionsChecker',
    'crossLinkDataService',
    '$timeout',
    'CROSS_LINK_INDEXING_DELAY',
    'SYSTEM_USER_ID',
    'hasCapability',
    function (
        sfxModal,
        $q,
        permissionsChecker,
        crossLinkDataService,
        $timeout,
        CROSS_LINK_INDEXING_DELAY,
        SYSTEM_USER_ID,
        hasCapability
    ) {
        function showModal(tabToSelect, params, crossLinkParams, rename) {
            const isServiceDiscovery = params.dashboard.creator === SYSTEM_USER_ID;
            return $q
                .all({
                    hasWritePermissions: permissionsChecker.hasDashboardWriteAccess(
                        params.dashboard
                    ),
                    hasUpdateDashboardCapability: hasCapability(Capability.UPDATE_DASHBOARD),
                    hasReadGlobalDataLinkCapability: hasCapability(
                        Capability.READ_GLOBAL_DATA_LINK
                    ),
                })
                .then(function ({
                    hasWritePermissions,
                    hasUpdateDashboardCapability,
                    hasReadGlobalDataLinkCapability,
                }) {
                    return sfxModal.open({
                        templateUrl,
                        controller: [
                            '$scope',
                            'featureEnabled',
                            function ($scope, featureEnabled) {
                                if (crossLinkParams) {
                                    $scope.crossLinkParams = crossLinkParams;
                                    $scope.crossLinkToEdit = crossLinkParams.propValPair;
                                }
                                $scope.missingUpdateDashboardCapability =
                                    !hasUpdateDashboardCapability;
                                $scope.dashboardEditable =
                                    hasWritePermissions &&
                                    !isServiceDiscovery &&
                                    hasUpdateDashboardCapability;
                                $scope.hasReadGlobalDataLinkCapability =
                                    hasReadGlobalDataLinkCapability;
                                $scope.params = params;
                                $scope.rename = rename;
                                $scope.tabToSelect = tabToSelect;
                                $scope.writePermissionsEnabled = featureEnabled('writepermissions');
                                $scope.accessControlEnabled = featureEnabled('accessControl');

                                let saved = false;

                                $scope.onSave = () => (saved = true);

                                $scope.dismiss = () => {
                                    invalidateCrossLinkCache();
                                    if (saved) {
                                        // This means new filters were saved in dashboard modal but user
                                        // exited or closed out. This throws an exception within the promise
                                        // which is caught and handled accordingly (see resetOnSave in dashboardNav.js)
                                        $scope.$dismiss({
                                            saved,
                                        });
                                    }
                                    $scope.$dismiss({
                                        saved,
                                    });
                                };

                                $scope.close = (result) => {
                                    if (angular.isDefined(result)) {
                                        saved = result;
                                    }

                                    invalidateCrossLinkCache();
                                    // have promise of uibModal.open return whether filters were saved
                                    $scope.$close({
                                        saved,
                                    });
                                };

                                $scope.$on('dismiss modal', () => $scope.dismiss());
                                $scope.$on('close modal', (result) => $scope.close(result));

                                function invalidateCrossLinkCache() {
                                    crossLinkDataService.invalidate();
                                    // Also accounting for Indexing Delay
                                    if ($scope.tabToSelect === 'Data links') {
                                        $timeout(
                                            () => crossLinkDataService.invalidate(),
                                            CROSS_LINK_INDEXING_DELAY
                                        );
                                    }
                                }
                            },
                        ],
                        windowClass: 'object-settings-modal',
                        backdrop: 'static',
                        keyboard: true,
                    }).result;
                });
        }

        function showModalToRename(tabToSelect, params) {
            return showModal(tabToSelect, params, null, true);
        }

        return {
            info: showModal.bind(null, 'Dashboard info'),
            rename: showModalToRename.bind(null, 'Dashboard info'),
            permissions: showModal.bind(null, 'Permissions'),
            variables: showModal.bind(null, 'Dashboard variables'),
            eventOverlay: showModal.bind(null, 'Event overlay'),
            crossLinks: showModal.bind(null, 'Data links'),
        };
    },
];
