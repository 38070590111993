angular
    .module('signalview.orgIntegrations')

    .service('dataSignatureService', [
        '$q',
        'integrationsMeta',
        'migratedSignalboost',
        function ($q, integrationsMeta, migratedSignalboost) {
            const dimensionsToQuery = [];
            const metricsToQuery = [];

            function addToQuery(dataSignature) {
                if (dataSignature.match(/sf_metric:/)) {
                    metricsToQuery.push(dataSignature);
                } else {
                    dimensionsToQuery.push(dataSignature);
                }
            }

            function processIntegrations(allIntegrations) {
                // this step only needs to be done the first time the function is called,
                // since the dimensions/metrics to query are the same for all orgs.
                if (!dimensionsToQuery.length) {
                    Object.keys(integrationsMeta).forEach(function (key) {
                        // if meta has field data_signature and displays in app, add it the the query list
                        if (
                            integrationsMeta[key].in_app_categories &&
                            integrationsMeta[key].data_signature
                        ) {
                            addToQuery(integrationsMeta[key].data_signature);
                        }
                    });
                }

                const querySets = [];
                // it would be a mistake to make this or the following query with an
                // empty query string.
                if (dimensionsToQuery.length) {
                    querySets.push(
                        migratedSignalboost.dimension.get('', {
                            query: dimensionsToQuery.join(' OR '),
                        })
                    );
                }
                if (metricsToQuery.length) {
                    querySets.push(
                        migratedSignalboost.metric.get('', {
                            query: metricsToQuery.join(' OR '),
                            fields: 'sf_metric',
                        })
                    );
                }

                return $q.all(querySets).then(function (results) {
                    const dimensionResults = results[0];
                    const metricResults = results[1];
                    const tempStore = {};

                    if (dimensionResults && dimensionResults.rs) {
                        dimensionResults.rs.forEach(function (result) {
                            for (let i = 0; i < result.sf_key.length; i++) {
                                const dimensionField = result.sf_key[i];
                                const dimensionValue = result[dimensionField];
                                tempStore[dimensionField + ':"' + dimensionValue + '"'] = {
                                    enabled: true,
                                    created: result.sf_createdOnMs,
                                };
                            }
                        });
                    }

                    if (metricResults && metricResults.rs) {
                        metricResults.rs.forEach(function (result) {
                            tempStore['sf_metric:"' + result['sf_metric'] + '"'] = {
                                enabled: true,
                                created: result.sf_createdOnMs,
                            };
                        });
                    }

                    Object.keys(integrationsMeta).forEach(function (key) {
                        if (
                            integrationsMeta[key].data_signature &&
                            allIntegrations[key] &&
                            tempStore[integrationsMeta[key].data_signature]
                        ) {
                            allIntegrations[key].enabled =
                                tempStore[integrationsMeta[key].data_signature].enabled;
                            allIntegrations[key].created =
                                tempStore[integrationsMeta[key].data_signature].created;
                        }
                    });
                });
            }

            return {
                processIntegrations: processIntegrations,
            };
        },
    ]);
