import React from 'react';
import styled from 'styled-components';
import InformationCircle from '@splunk/react-icons/InformationCircle';

const StyledInformationCircle = styled(InformationCircle)`
    width: 24px;
    height: auto;
`;

function MissingRbacCapabilityMessage(): JSX.Element {
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <div
                style={{
                    paddingRight: '8px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <StyledInformationCircle variant="filled" />
            </div>
            <p style={{ margin: 0 }}>
                You cannot modify this dashboard or group because your current role-based access
                does not have edit permissions.{' '}
                <strong>For more information, contact your Splunk administrator.</strong>
            </p>
        </div>
    );
}

export default MissingRbacCapabilityMessage;
