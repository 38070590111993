import templateUrl from './percentPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('percentPickerController', [
        '$scope',
        function ($scope) {
            const $ctrl = this;
            $ctrl.$onInit = function () {
                $scope.valid = true;
                $scope.localValue = toDisplayValue($scope.$ctrl.model);
            };

            function toDisplayValue(val) {
                // fallback to 100%
                if (!val && val !== 0) val = 1;

                const percentValue = val * 100;
                // round the value to 2 decimal places
                return Math.floor(percentValue * 100) / 100;
            }

            function toProgramValue(val) {
                return (val || 0) / 100;
            }

            $scope.$watch('localValue', function (v) {
                $scope.valid = validatePercent(v);
                $scope.$ctrl.onTypedUpdate({
                    value: toProgramValue($scope.localValue),
                    validity: $scope.valid,
                });
            });

            function validatePercent(percent) {
                return (
                    angular.isDefined(percent) &&
                    typeof percent === 'number' &&
                    percent >= 0 &&
                    percent <= 100
                );
            }

            $scope.$ctrl.$onChanges = function (changes) {
                if (changes.model) {
                    const value = changes.model.currentValue;
                    $scope.localValue = toDisplayValue(value);
                }
            };
        },
    ])
    .component('percentPicker', {
        replace: true,
        controller: 'percentPickerController',
        bindings: {
            model: '<',
            definition: '<',
            validationError: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
