import { getRelativePerformanceTime } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
export default [
    '$scope',
    '$rootScope',
    'signalviewMetrics',
    'sessionCache',
    'globalNavUpdateService',
    '$window',
    'themeService',
    'colorAccessibilityService',
    'locationChangeService',
    function (
        $scope,
        $rootScope,
        metrics,
        sessioncache,
        globalNavUpdateService,
        $window,
        theme,
        colorAccessibilityService,
        locationChangeService
    ) {
        const FULL_SCREEN_CLASS = 'isFullscreen';
        const WINDOWED_CLASS = 'isWindowed';
        $rootScope.$on('migrated services initialized', function () {
            $rootScope.$on('fullscreen enabled', function () {
                globalNavUpdateService.update({ hideAll: true });
                $scope.isFullscreen = true;
                applyFullScreenClass();
            });

            $rootScope.$on('fullscreen disabled', function () {
                globalNavUpdateService.update({ hideAll: false });
                $scope.isFullscreen = false;
                applyFullScreenClass();
            });
        });

        $scope.colorAccessibility = colorAccessibilityService;
        $scope.theme = theme;

        $rootScope.$on('migrated services initialized', function () {
            // metrics for uncaught errors
            $window.onerror = function () {
                metrics.incr('ui.uncaught.errors');
            };
            // metric for approx page load time
            if ($window.performance && $window.performance.timing) {
                metrics.set(
                    'ui.loadtime',
                    {},
                    getRelativePerformanceTime($window.performance) -
                        $window.performance.timing.navigationStart
                );
            }
            $rootScope.$on('$locationChangeStart', function (e, currentLocation, previousLocation) {
                // leave page hook used by APM business workflows
                if (locationChangeService.vetoChange(currentLocation, previousLocation)) {
                    e.preventDefault();
                } else {
                    // cache the previous path for utility
                    sessioncache.set('previousLocation', previousLocation);
                }
            });
        });

        function applyFullScreenClass() {
            // we have to determine this at runtime in olly
            const appRoot = angular.element(document.getElementsByTagName('body')[0]);
            const isFullScreen = $scope.isFullscreen;
            if (isFullScreen) {
                appRoot.addClass(FULL_SCREEN_CLASS);
                appRoot.removeClass(WINDOWED_CLASS);
            } else {
                appRoot.addClass(WINDOWED_CLASS);
                appRoot.removeClass(FULL_SCREEN_CLASS);
            }
        }

        applyFullScreenClass();
    },
];
