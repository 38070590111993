import templateUrl from './variablesEditor.tpl.html';
import variableTooltipTemplateUrl from '../variables/variableTooltipTemplate.tpl.html';

export default {
    templateUrl,
    bindings: {
        params: '<',
        dashboardEditable: '<',
        onSave: '<?',
        missingUpdateDashboardCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$scope',
        '$log',
        '$q',
        '$timeout',
        '$element',
        'dimensionService',
        'dashboardVariableSuggestUtils',
        'dashboardV2Service',
        'dashboardGroupService',
        'dashboardUtil',
        'exitGuardModal',
        'featureEnabled',
        'dashboardMirrorService',
        'dashboardVariableUtils',
        'sourceFilterService',
        function (
            $scope,
            $log,
            $q,
            $timeout,
            $element,
            dimensionService,
            dashboardVariableSuggestUtils,
            dashboardV2Service,
            dashboardGroupService,
            dashboardUtil,
            exitGuardModal,
            featureEnabled,
            dashboardMirrorService,
            dashboardVariableUtils,
            sourceFilterService
        ) {
            const ctrl = this;

            ctrl.$onInit = $onInit;

            let dashboard, snapshot, config, filterAlias, savedFilters, overrideFilters;

            const DEFAULT_FILTERS_OVERRIDE = {
                sources: null,
                variables: null,
                time: null,
                density: null,
            };

            function $onInit() {
                ctrl.dashboardViewsEnabled = featureEnabled('dashboardViews');

                dashboard = angular.copy(ctrl.params.dashboard);
                snapshot = angular.copy(ctrl.params.snapshot);
                config = angular.copy(ctrl.params.currentConfig);

                if (ctrl.dashboardViewsEnabled) {
                    dashboardMirrorService.isDashboardMirror(dashboard.id).then((isMirror) => {
                        $scope.showOverrides =
                            isMirror || dashboardMirrorService.configHasOverrides(config);
                    });
                }

                filterAlias = dashboard.filters.variables || [];
                savedFilters = dashboard.filters;

                overrideFilters = (config.filtersOverride || {}).variables || [];

                $scope.sortOptions = { axis: 'y' };
                $scope.variables = angular.copy(filterAlias);
                $scope.cleanVariables = angular.copy($scope.variables);

                $scope.variablesOverrides = angular.copy(overrideFilters);

                // due to the way overrides are specified, we need the potential variable overrides
                // in place and ready for editing before they are requested.  this code must remain here or
                // the exit guard will find that cleanOverrides is different from variableOverrides
                $scope.variables.forEach((variable) => {
                    const property = variable.property;
                    if (!$scope.variablesOverrides.find((v) => v.property === property)) {
                        $log.info(
                            `Variable override not present for ${property}, creating one for use.`
                        );
                        const override = instantiateVariableOverride(property);
                        setVariableOverrideToNoOp(override);
                    }
                });

                $scope.cleanOverrides = angular.copy($scope.variablesOverrides);

                // flags to specify css styling for multiselect dropdown values
                $scope.inValuesContext = false;
                $scope.inOverrideValuesContext = false;

                $scope.typedVariableValue = '';
                $scope.typedVariableOverrideValue = '';

                $scope.groupName = ctrl.params.currentGroupName;
                $scope.isDashboardLocked = featureEnabled('readOnly') && dashboard.locked;
                $scope.userHasGroupWritePermission = ctrl.params.userHasGroupWritePermission;
                $scope.dashboardFieldsDisabled =
                    !ctrl.dashboardEditable || $scope.isDashboardLocked;

                if ($scope.dashboardFieldsDisabled) {
                    $scope.variableTooltipTemplateUrl = '';
                } else {
                    $scope.variableTooltipTemplateUrl = variableTooltipTemplateUrl;
                }

                $scope.saving = {
                    progress: false,
                    error: false,
                };

                $scope.$watch(
                    'variables',
                    function (nval, oval) {
                        if (nval === oval) {
                            return;
                        } else {
                            $scope.dashboardLevelForm.$setDirty();
                        }
                    },
                    true
                );

                $scope.$watchGroup(
                    ['dashboardLevelForm.$dirty', 'groupLevelForm.$dirty'],
                    configureExitGuard
                );

                checkEmpty();
            }

            function isOverrideCheckboxSelected(override, key) {
                if (!override || override.length === 0) {
                    return false;
                }

                return !isNullOrUndefined(override[key]);
            }

            function configureExitGuard(dirtyVals) {
                ctrl.params.dirty = dirtyVals.includes(true);
                if (ctrl.params.dirty) {
                    ctrl.tabby.enableExitGuard(exitGuard);
                } else {
                    ctrl.tabby.disableExitGuard();
                }
            }

            function exitGuard() {
                return exitGuardModal(saveAll, reset);
            }

            function saveAll() {
                return $q.all([$scope.save(), $scope.saveVariableOverrides()]);
            }

            function reset() {
                return new Promise((resolve) => {
                    $onInit();
                    resolve();
                }).then(() => {
                    $scope.dashboardVariablesForm.$setPristine();
                });
            }

            function checkEmpty() {
                if (!$scope.variables.length) {
                    $scope.newVariable();
                } else {
                    $scope.showVariable(0);
                }
            }

            $scope.newVariable = function () {
                // Note that it is possible for a variable to be created and already have an override
                // if a variable on a property was deleted, then a new one on the same property is
                // created again. This is because we do not want group updates to propagate from a
                // dashboard action, which would be required if we were to update all mirrors' configs
                // when a dashboard variable was deleted. This assumption is safe as long as we assume
                // a dashboard variable can be uniquely identified (in the context of a dashboard) by
                // its property
                $scope.variables.push({
                    property: '',
                    alias: '',
                    value: '',
                    globalScope: true,
                    required: true,
                    description: '',
                    restricted: false,
                    replaceOnly: false,
                    applyIfExists: false,
                    preferredSuggestions: [],
                });
                $scope.showVariable($scope.variables.length - 1);
                $scope.valueOverrideSelected = false;
            };

            $scope.showVariable = function (index) {
                $scope.active = index;

                $scope.activeVariableOverride = getLocalVariableOverride();

                $scope.activeVariable = getLocalVariable();
                $scope.activeName =
                    $scope.activeVariable.alias || $scope.activeVariable.property || null;

                if ($scope.unregisterSuggestionsWatcher) {
                    $scope.unregisterSuggestionsWatcher();
                }
                if ($scope.unregisterValueOverrideWatcher) {
                    $scope.unregisterValueOverrideWatcher();
                }
                if ($scope.unregisterSuggestionOverridesWatcher) {
                    $scope.unregisterSuggestionOverridesWatcher();
                }

                // current active saved values - shown when user is not making edits to their
                // variable values or overrides
                $scope.variableValues = $scope.getValuesDisplayString();
                $scope.variableOverrideValues = $scope.getOverrideValuesDisplayString();
                // flag to focus in on typeahead multiselect dropdown so suggestions are auto-populated
                $scope.variableValuesInFocus = false;
                $scope.variableOverrideValuesInFocus = false;
                // values selected in edit mode of typeahead multiselect dropdown
                $scope.selectedVariables = null;
                $scope.selectedVariableOverrides = null;

                $scope.valueOverrideSelected = isOverrideCheckboxSelected(
                    getLocalVariableOverride(),
                    'value'
                );

                $scope.unregisterSuggestionsWatcher = $scope.$watchCollection(
                    'activeVariable.preferredSuggestions',
                    markFormIfSuggestionsDirty
                );
                $scope.unregisterValueOverrideWatcher = $scope.$watch(
                    'activeVariableOverride.value',
                    markFormIfValueOverrideDirty
                );
                $scope.unregisterSuggestionOverridesWatcher = $scope.$watchCollection(
                    'activeVariableOverride.preferredSuggestions',
                    markFormIfSuggestionOverridesDirty
                );
            };

            function markFormIfSuggestionsDirty(newSuggestions) {
                if (
                    !angular.equals(
                        newSuggestions,
                        ($scope.cleanVariables[$scope.active] &&
                            $scope.cleanVariables[$scope.active].preferredSuggestions) ||
                            []
                    )
                ) {
                    $scope.dashboardLevelForm.$setDirty();
                }
            }

            function markFormIfValueOverrideDirty(newValue) {
                const currentProperty = $scope.activeVariable.property;
                const cleanOverride = $scope.cleanOverrides.find(
                    (override) => override.property === currentProperty
                );
                if (
                    (newValue && !cleanOverride) ||
                    (cleanOverride && !angular.equals(newValue, cleanOverride.value))
                ) {
                    $scope.groupLevelForm.$setDirty();
                }
            }

            function markFormIfSuggestionOverridesDirty(newOverrides) {
                const currentProperty = $scope.activeVariable.property;
                const cleanOverride = $scope.cleanOverrides.find(
                    (override) => override.property === currentProperty
                );
                if (
                    (newOverrides && !cleanOverride) ||
                    (cleanOverride &&
                        !angular.equals(
                            newOverrides || [],
                            cleanOverride.preferredSuggestions || []
                        ))
                ) {
                    $scope.groupLevelForm.$setDirty();
                }
            }

            function isNullOrUndefined(val) {
                return val === null || val === undefined;
            }

            $scope.selectValueOverrideCheckbox = function () {
                if ($scope.valueOverrideSelected) {
                    $scope.activeVariableOverride.value = null;
                    $scope.variableOverrideValues = null;
                    $scope.typedVariableOverrideValue = '';
                } else if (isNullOrUndefined($scope.activeVariableOverride.value)) {
                    $scope.activeVariableOverride.value = [];
                }
            };

            /*
             * VARIABLE MULTISELECT
             */

            /*
             * Maps active saved variable values to concatenated string for pill display
             */
            $scope.getValuesDisplayString = function () {
                return sourceFilterService.getDisplayNameForFilters($scope.activeVariable.value);
            };

            /*
             * On exit of typeahead multi select pill - apply any cleanup.
             *
             * Used particularly when variable value is required but no values was selected and
             * there existed a cached value.
             */
            $scope.resetVariableValue = function () {
                $scope.selectedVariables = null;
                $scope.typedVariableValue = '';
                if ($scope.activeVariable.required) {
                    getRequiredValue().then(function (val) {
                        $scope.activeVariable.value = val;
                        $scope.variableValues = $scope.getValuesDisplayString();
                    });
                }
                $scope.cachedVariableValue = null;
            };

            /* Fetches some value for current required active variable.
             *
             * We either take from cache if present, preferred suggestions if not empty, or the first
             * result of suggested values.
             */
            function getRequiredValue() {
                // set default to most recently stored cached value if present
                if ($scope.cachedVariableValue) {
                    return $q.when(angular.copy($scope.cachedVariableValue));
                }

                // set to previous state if not wiped
                const currentVal = getLocalVariable().value;
                if (currentVal) {
                    return $q.when(angular.copy(currentVal));
                }

                // set to preferred suggestions if cache and previous state have been cleared
                const hasPreferredSuggestions =
                    $scope.activeVariable.preferredSuggestions &&
                    $scope.activeVariable.preferredSuggestions.length > 0;

                if (hasPreferredSuggestions) {
                    return $q.when(angular.copy($scope.activeVariable.preferredSuggestions));
                }

                const errorHandler = () => {
                    $log.error(
                        'Failed to find suggested values for required property ' +
                            $scope.activeVariable.property
                    );
                    return null;
                };

                // take first result from suggestions
                return $scope.suggestValues().then(function (results) {
                    if (!results || results.length === 0) {
                        errorHandler();
                    }

                    if (angular.isArray(results)) {
                        return results[0];
                    } else {
                        return results;
                    }
                }, errorHandler);
            }

            $scope.removeLastVariableValue = function () {
                if ($scope.variableValues && $scope.variableValues.length) {
                    $scope.removeSelectedVariableValue(
                        $scope.variableValues[$scope.variableValues.length - 1]
                    );
                }
            };

            /*
             * Function when user de-selects a value from dropdown.
             */
            $scope.removeSelectedVariableValue = function (valueToRemove) {
                if (angular.isArray($scope.selectedVariables)) {
                    const index = $scope.selectedVariables.indexOf(valueToRemove);
                    if (index !== -1) {
                        $scope.selectedVariables = $scope.selectedVariables.filter(
                            (v) => v !== valueToRemove
                        );
                        if (!$scope.selectedVariables.length) {
                            $scope.selectedVariables = null;
                        }
                    }
                } else if ($scope.selectedVariables && $scope.selectedVariables === valueToRemove) {
                    $scope.selectedVariables = null;
                }
                $scope.setInValuesContext(false);
            };

            /*
             * Function when user removes all values (clicks X button on displayed values in pill)
             */
            $scope.removeVariableValues = function () {
                $scope.selectedVariables = [];
                clearCurrentVariableValues();
                focus();
            };

            /*
             * Function when user selects a value from dropdown.
             */
            $scope.addVariableValueToSelection = function (value) {
                if (!value) {
                    return;
                }

                if (!$scope.selectedVariables) {
                    $scope.selectedVariables = [value];
                }

                if (!$scope.isSelected({ value })) {
                    if (angular.isArray($scope.selectedVariables)) {
                        $scope.selectedVariables.push(value);
                    } else {
                        if ($scope.selectedVariables) {
                            $scope.selectedVariables = [$scope.selectedVariables, value];
                        } else {
                            $scope.selectedVariables = [value];
                        }
                    }
                }
            };

            /*
             * Function when user clicks into multi select dropdown pill.
             *
             * Sets selected values with the current active or saved values and specifies dropdown
             * to be focused in on (this allows suggestions to be populated on pill enter and not input
             * click).
             */
            $scope.editVariableValues = function () {
                initializeSelectedValues();
                clearCurrentVariableValues();
                focus();
            };

            function initializeSelectedValues() {
                if (!$scope.activeVariable.value) {
                    $scope.selectedVariables = [];
                }

                if (angular.isArray($scope.activeVariable.value)) {
                    $scope.selectedVariables = angular.copy($scope.activeVariable.value);
                } else {
                    $scope.selectedVariables = [$scope.activeVariable.value];
                }
            }

            /*
             * Cache current active/saved variable values and clear state for editing.
             */
            function clearCurrentVariableValues() {
                $scope.cachedVariableValue = angular.copy($scope.activeVariable.value);
                $scope.activeVariable.value = null;
                $scope.variableValues = null;
                $scope.typedVariableValue = '';
            }

            /*
             * Function when user clicks a suggested value or hits 'Apply' - we save new selections
             * to our active variable which we use to track dirty state. If no selection was made and
             * the variable value is required, we use what was previously saved.
             */
            $scope.selectValue = function (suggestion) {
                let value = suggestion.value;
                if (!value) {
                    value = angular.copy($scope.selectedVariables);
                    if (
                        $scope.activeVariable.required &&
                        (!$scope.selectedVariables || $scope.selectedVariables.length === 0)
                    ) {
                        value = $scope.cachedVariableValue;
                    }

                    if ((!value || value.length === 0) && $scope.activeVariable.required) {
                        value = $scope.cachedVariableValue;
                    }
                }

                if (!angular.isArray(value)) {
                    value = [value];
                }

                $scope.selectedVariables = null;
                $scope.variableValuesInFocus = false;
                $scope.cachedVariableValue = null;
                $scope.activeVariable.value = value;
                $scope.variableValues = $scope.getValuesDisplayString();
            };

            /*
             * Helper to get selected variables.
             */
            $scope.getSelectedVariables = function () {
                const selectedVariables = $scope.selectedVariables || null;

                if (angular.isArray(selectedVariables)) {
                    return selectedVariables;
                } else {
                    return [selectedVariables];
                }
            };

            /*
             * Toggle selection function - tells multi select drop down what to do when user
             * selects or de-selects a value.
             */
            $scope.toggleVariableValueSelection = function (suggestion) {
                const value = suggestion.value;
                if ($scope.isSelected({ value })) {
                    $scope.removeSelectedVariableValue(value);
                } else {
                    $scope.addVariableValueToSelection(value);
                }
            };

            $scope.setInValuesContext = function (val) {
                $scope.inValuesContext = val;
            };

            /*
             * Helper to determine if a value is selected - used to determine whether to add or
             * remove a value from selection.
             */
            $scope.isSelected = function ({ value }) {
                if (!$scope.selectedVariables) {
                    return false;
                }
                if (angular.isArray($scope.selectedVariables)) {
                    return $scope.selectedVariables.indexOf(value) !== -1;
                } else {
                    return $scope.selectedVariables && $scope.selectedVariables === value;
                }
            };

            /*
             * Autofocus on variable value typeahead multiselect dropdown so suggestions populate
             * on element show or pill edit instead of input click.
             */
            function focus() {
                $timeout(function () {
                    $element.find('.variable-value-drop-down .sfx-input')[0].focus();
                    $scope.variableValuesInFocus = true;
                }, 0);
            }

            /*
             * VARIABLE OVERRIDE MULTISELECT
             */

            /*
             * Maps active saved override variable values to concatenated string for pill display
             */
            $scope.getOverrideValuesDisplayString = function () {
                return sourceFilterService.getDisplayNameForFilters(
                    $scope.activeVariableOverride.value
                );
            };

            /*
             * On exit of value override typeahead multi select pill - apply any cleanup.
             */
            $scope.resetVariableOverrideValue = function () {
                $scope.selectedVariableOverrides = null;
                $scope.variableOverrideValuesInFocus = false;
                $scope.typedVariableOverrideValue = '';
                $scope.cachedVariableOverrideValue = null;
            };

            $scope.removeLastVariableOverrideValue = function () {
                if ($scope.variableOverrideValues && $scope.variableOverrideValues.length) {
                    $scope.removeSelectedVariableOverrideValue(
                        $scope.variableOverrideValues[$scope.variableOverrideValues.length - 1]
                    );
                }
            };

            /*
             * Function when user de-selects an override value from dropdown.
             */
            $scope.removeSelectedVariableOverrideValue = function (valueToRemove) {
                if (angular.isArray($scope.selectedVariableOverrides)) {
                    const index = $scope.selectedVariableOverrides.indexOf(valueToRemove);
                    if (index !== -1) {
                        $scope.selectedVariableOverrides.splice(index, 1);
                        if (!$scope.selectedVariableOverrides.length) {
                            $scope.selectedVariableOverrides = [];
                        }
                    }
                } else if (
                    $scope.selectedVariableOverrides &&
                    $scope.selectedVariableOverrides === valueToRemove
                ) {
                    $scope.selectedVariableOverrides = [];
                }
                $scope.setInOverrideValuesContext(false);
            };

            /*
             * Function when user removes all overrides (clicks X button on displayed values in pill)
             */
            $scope.removeVariableOverrideValues = function () {
                $scope.selectedVariableOverrides = [];
                clearCurrentVariableOverrideValues();
                focusOverride();
            };

            /*
             * Function when user selects an override from dropdown.
             */
            $scope.addVariableOverrideValueToSelection = function (value) {
                if (!value) {
                    return;
                }

                if (!$scope.selectedVariableOverrides) {
                    $scope.selectedVariableOverrides = [value];
                }

                if (!$scope.isOverrideSelected({ value })) {
                    if (angular.isArray($scope.selectedVariableOverrides)) {
                        $scope.selectedVariableOverrides.push(value);
                    } else {
                        if ($scope.selectedVariableOverrides) {
                            $scope.selectedVariableOverrides = [
                                $scope.selectedVariableOverrides,
                                value,
                            ];
                        } else {
                            $scope.selectedVariableOverrides = [value];
                        }
                    }
                }
            };

            /*
             * Function when user clicks into multi select dropdown pill.
             *
             * Sets selected overrides with the current active or saved overrides and specifies dropdown
             * to be focused in on (this allows suggestions to be populated on pill enter and not input
             * click).
             */
            $scope.editVariableOverrideValues = function () {
                initializeSelectedOverrideValues();
                clearCurrentVariableOverrideValues();
                focusOverride();
            };

            function initializeSelectedOverrideValues() {
                if (!$scope.activeVariableOverride.value) {
                    $scope.selectedVariableOverrides = [];
                }

                if (angular.isArray($scope.activeVariableOverride.value)) {
                    $scope.selectedVariableOverrides = angular.copy(
                        $scope.activeVariableOverride.value
                    );
                } else {
                    $scope.selectedVariableOverrides = [$scope.activeVariableOverride.value];
                }
            }

            /*
             * Cache current active/saved variable values and clear state for editing.
             */
            function clearCurrentVariableOverrideValues() {
                $scope.cachedVariableOverrideValue = angular.copy(
                    $scope.activeVariableOverride.value
                );
                $scope.activeVariableOverride.value = [];
                $scope.variableOverrideValues = null;
                $scope.typedVariableOverrideValue = '';
            }

            /*
             * Function when user clicks onto a suggested value or hits 'Apply' - we save new selections
             * to our active variable which we use to track dirty state. If no selection was made and
             * the variable value is required, we use what was previously saved.
             */
            $scope.selectOverrideValue = function (suggestion) {
                let value = suggestion.value;
                if (!value) {
                    value = angular.copy($scope.selectedVariableOverrides);
                }

                if (!angular.isArray(value)) {
                    value = [value];
                }

                $scope.selectedVariableOverrides = null;
                $scope.variableOverrideValuesInFocus = false;
                $scope.cachedVariableOverrideValue = null;
                $scope.activeVariableOverride.value = value;
                $scope.variableOverrideValues = $scope.getOverrideValuesDisplayString();
            };

            /*
             * Helper to get selected variable overrides.
             */
            $scope.getSelectedOverrideVariables = function () {
                const selectedOverrideVariables = $scope.selectedVariableOverrides || null;

                if (angular.isArray(selectedOverrideVariables)) {
                    return selectedOverrideVariables;
                } else {
                    return [selectedOverrideVariables];
                }
            };

            /*
             * Toggle selection function - tells multi select drop down what to do when user
             * selects or de-selects a value override.
             */
            $scope.toggleVariableOverrideValueSelection = function (suggestion) {
                const value = suggestion.value;
                if ($scope.isOverrideSelected({ value })) {
                    $scope.removeSelectedVariableOverrideValue(value);
                } else {
                    $scope.addVariableOverrideValueToSelection(value);
                }
            };

            $scope.setInOverrideValuesContext = function (val) {
                $scope.inOverrideValuesContext = val;
            };

            /*
             * Helper to determine if a value is selected - used to determine whether to add or
             * remove a value from selection.
             */
            $scope.isOverrideSelected = function ({ value }) {
                if (!$scope.selectedVariableOverrides) {
                    return false;
                }
                if (angular.isArray($scope.selectedVariableOverrides)) {
                    return $scope.selectedVariableOverrides.indexOf(value) !== -1;
                } else {
                    return (
                        $scope.selectedVariableOverrides &&
                        $scope.selectedVariableOverrides === value
                    );
                }
            };

            /*
             * Autofocus on variable value typeahead multiselect dropdown so suggestions populate
             * on element show or pill edit instead of input click.
             */
            function focusOverride() {
                $timeout(function () {
                    $element.find('.variable-override-value-drop-down .sfx-input')[0].focus();
                    $scope.variableOverrideValuesInFocus = true;
                }, 0);
            }

            /*
             * Used when a user creates a new variable and specifies a value is required but does not
             * select values on save - we then set the value to preferred suggestions if it exits or
             * our standard suggested values.
             *
             * See $scope.selectDimension
             */
            $scope.setDefaultValue = function () {
                const currentVariable = getLocalVariable();

                if (!currentVariable.property) {
                    return;
                }

                const required = currentVariable.required;
                if (required) {
                    getRequiredValue().then(function (res) {
                        currentVariable.value = res;
                        $scope.activeVariable.value = res;
                        $scope.variableValues = $scope.getValuesDisplayString();
                    });
                }
            };

            /*
             * If no suggestions were populated, we want to return the search term or query as a
             * result. We also want to include selected values as suggestions that may not have been
             * returned from suggestions.
             */
            function transformSuggestedResults(results, searchTerm, currentValues) {
                if (!results || results.length === 0) {
                    if (searchTerm) {
                        return [{ value: searchTerm }];
                    } else {
                        return [];
                    }
                }
                const transformedResults = results.map((val) => ({ value: val }));
                if (!currentValues) {
                    return transformedResults;
                }

                currentValues.forEach((val) => {
                    if (!transformedResults.find((resultVal) => resultVal.value === val)) {
                        transformedResults.push({ value: val });
                    }
                });
                return transformedResults;
            }

            /*
             * Wrapper that wraps variable suggestions in object - this is required by multiselect
             * dropdown component.
             */
            $scope.suggestVariableValues = function (searchTerm) {
                $scope.typedVariableValue = searchTerm;
                return $scope.suggestValues().then(function (results) {
                    return transformSuggestedResults(results, searchTerm, $scope.selectedVariables);
                });
            };

            $scope.suggestValues = function (limit) {
                const localVariable = getLocalVariable();
                const preferred = localVariable.preferredSuggestions;
                if (localVariable.restricted) {
                    return $q.when(preferred || []);
                }
                return dimensionService.getPropertyValueSuggest({
                    property: localVariable.property,
                    partialInput: $scope.typedVariableValue,
                    limit,
                });
            };

            /*
             * Wrapper that wraps variable override suggestions in object - this is required by
             * multiselect dropdown component.
             */
            $scope.suggestVariableOverrideValues = function (searchTerm) {
                $scope.typedVariableOverrideValue = searchTerm;
                return $scope.suggestOverrideValues(0).then(function (results) {
                    return transformSuggestedResults(
                        results,
                        searchTerm,
                        $scope.selectedVariableOverrides
                    );
                });
            };

            $scope.suggestOverrideValues = function (limit) {
                const localVariable = getLocalVariable();
                const localVariableOverride = getLocalVariableOverride();

                let preferred;
                if (
                    localVariableOverride.preferredSuggestions &&
                    localVariableOverride.preferredSuggestions.length
                ) {
                    preferred = localVariableOverride.preferredSuggestions;
                } else {
                    preferred = localVariable.preferredSuggestions || [];
                }

                if (localVariable.restricted) {
                    return $q.when(preferred);
                }
                return suggestOverrides(
                    'value',
                    limit,
                    $scope.typedVariableOverrideValue,
                    preferred
                );
            };

            $scope.selectDimension = function (dim) {
                const localVariable = getLocalVariable();
                localVariable.property = dim;
                localVariable.value = '';
                $scope.setDefaultValue();
                addVariableOverrideIfNeeded(dim);
            };

            $scope.suggestDimensions = function () {
                const localVariable = getLocalVariable();
                if (!localVariable.property) {
                    localVariable.value = '';
                    localVariable.preferredSuggestions = [];
                }
                const currVal = getLocalVariable().property;
                const nonDimensionProperties = ['sf_tags', 'sf_metric'].filter((prop) =>
                    prop.match(currVal)
                );

                return dimensionService
                    .getPropertyNameSuggest({
                        partialInput: localVariable.property,
                    })
                    .then(function (results) {
                        if (nonDimensionProperties) {
                            results = results.concat(
                                nonDimensionProperties.filter(
                                    (prop) => results.indexOf(prop) === -1
                                )
                            );
                        }
                        const currentVariableProperties = getAllAliasedPropertiesExceptSelf();
                        return results.filter(
                            (res) => currentVariableProperties.indexOf(res) === -1
                        );
                    });
            };

            function getAllAliasedPropertiesExceptSelf() {
                const local = getLocalVariable();
                return $scope.variables
                    .filter((v) => v !== local)
                    .map((variable) => variable.property);
            }

            $scope.suggestPreferredValues = function (query, limit) {
                const selected = getLocalVariable();
                const preferredSuggestions = selected.preferredSuggestions || [];

                return dimensionService
                    .getPropertyValueSuggest({
                        property: selected.property,
                        partialInput: query,
                        limit,
                    })
                    .then(function (res) {
                        return res.filter(function (suggestValue) {
                            return !preferredSuggestions.includes(suggestValue);
                        });
                    });
            };

            $scope.suggestPreferredValuesOverrides = function (query, limit) {
                const selectedOverride = getLocalVariableOverride();
                const preferredSuggestions = selectedOverride.preferredSuggestions || [];

                return dimensionService
                    .getPropertyValueSuggest({
                        property: selectedOverride.property,
                        partialInput: query,
                        limit,
                    })
                    .then(function (res) {
                        return res.filter(function (suggestValue) {
                            return !preferredSuggestions.includes(suggestValue);
                        });
                    });
            };

            $scope.removeVariable = function () {
                // Note that this will not remove the dashboard variable override at the group level if one
                // exists. We do not want to try to update all mirrors in such a case
                const removedVariable = getLocalVariable();
                $scope.variables.splice($scope.active, 1);
                const removedOverrideIdx = (savedFilters.variables || []).findIndex((override) => {
                    return override.property === removedVariable.property;
                });
                if (removedOverrideIdx !== -1) {
                    dashboard.filters.variables.splice(removedOverrideIdx, 1);
                }
                checkEmpty();
                if ($scope.active > $scope.variables.length - 1) {
                    $scope.active = $scope.variables.length - 1;
                    $scope.activeVariableOverride = getLocalVariableOverride();
                }
            };

            $scope.cancel = function () {
                $scope.$emit('dismiss modal');
            };

            $scope.close = function () {
                $scope.$emit('close modal');
            };

            $scope.save = function () {
                updateLocalVariableValues();

                return new Promise((resolve, reject) => {
                    if (snapshot && snapshot.id) {
                        if (ctrl.params.updateVariables) {
                            ctrl.params.updateVariables(filterAlias);
                        }
                        $scope.$emit('save complete', false, filterAlias);
                        $scope.dashboardLevelForm.$setPristine();
                        resolve();
                    } else {
                        return dashboardV2Service
                            .get(dashboard.id)
                            .then(function (dash) {
                                dash.filters.variables = filterAlias;
                                $scope.cleanVariables = angular.copy(filterAlias);
                                return dashboardV2Service.update(dash);
                            })
                            .then(function () {
                                if (ctrl.params.updateVariables) {
                                    ctrl.params.updateVariables(filterAlias);
                                }
                                $scope.$emit('save complete', false, filterAlias);
                                resolve();
                            })
                            .catch((e) => reject(e));
                    }
                })
                    .catch((e) => {
                        $log.error('Failed saving variables ', e);
                        $scope.$emit('save complete', true, filterAlias);
                    })
                    .finally(() => {
                        if (ctrl.onSave) {
                            ctrl.onSave();
                        }
                        $scope.dashboardLevelForm.$setPristine();
                        if (!$scope.groupLevelForm.$dirty) {
                            setAllPristine();
                        }
                    });
            };

            function updateLocalVariableValues() {
                filterAlias = $scope.variables
                    .filter((v) => {
                        return v.property;
                    })
                    .map(updateAlias);
            }

            function updateAlias(variable) {
                if (!variable.alias) {
                    variable.alias = variable.property;
                }
                variable.alias = variable.alias.replace('=', ' eq ');

                return variable;
            }

            $scope.saveVariableOverrides = function () {
                if (!ctrl.dashboardViewsEnabled) {
                    return;
                }

                updateLocalVariableValues();

                if (!snapshot || !snapshot.id) {
                    if (!config.filtersOverride) {
                        config.filtersOverride = angular.copy(DEFAULT_FILTERS_OVERRIDE);
                    }
                    config.filtersOverride.variables = $scope.variablesOverrides;
                    $scope.cleanOverrides = angular.copy(config.filtersOverride.variables);
                    return dashboardGroupService
                        .updateDashboardConfig(ctrl.params.currentGroupId, config)
                        .then(function () {
                            if (ctrl.params.updateVariablesOverrides) {
                                const mergedDefaultAssignments =
                                    dashboardVariableUtils.mergeMirrorVariables(
                                        ctrl.params.dashboard.filters.variables,
                                        config.filtersOverride.variables
                                    );
                                ctrl.params.updateVariablesOverrides(mergedDefaultAssignments);
                            }
                            $scope.$emit('save complete', false, config.filtersOverride.variables);
                        })
                        .catch((e) => {
                            $log.error('Failed saving variable overrides', e);
                        })
                        .finally(() => {
                            if (ctrl.onSave) {
                                ctrl.onSave();
                            }
                            $scope.groupLevelForm.$setPristine();
                            if (!$scope.dashboardLevelForm.$dirty) {
                                setAllPristine();
                            }
                        });
                }

                return Promise.resolve();
            };

            function setVariableOverrideToNoOp(override) {
                override.value = null;
                override.preferredSuggestions = null;
            }

            function addVariableOverrideIfNeeded(property) {
                if (!$scope.variablesOverrides.find((v) => v.property === property)) {
                    const override = instantiateVariableOverride(property);
                    setVariableOverrideToNoOp(override);
                }
            }

            function instantiateVariableOverrideForCurrentVariable() {
                const localVariable = getLocalVariable();
                return instantiateVariableOverride(localVariable);
            }

            function instantiateVariableOverride(property) {
                const newVariableOverride = dashboardUtil.createNewDashboardVariableOverride({
                    property: property,
                });
                $scope.variablesOverrides.push(newVariableOverride);
                $scope.activeVariableOverride = newVariableOverride;

                return newVariableOverride;
            }

            $scope.saveAndClose = function () {
                $scope.save().then($scope.close);
            };

            $scope.saveVariableOverridesAndClose = function () {
                $scope.saveVariableOverrides().then($scope.close);
            };

            function setAllPristine() {
                $scope.dashboardVariablesForm.$setPristine();
            }

            // this entire section needs to be rewritten such that the override and the base variable
            // are passed, processed, and then a request is generated.  currently suggestion is pulling
            // from variable component state in one path, but is supplied by the caller in another.
            function suggestOverrides(type, limit, partialInput, preferred) {
                const selected = getLocalVariable();
                const selectedOverride = getLocalVariableOverride();
                const preferredSuggestions = preferred || selected.preferredSuggestions || [];
                const currVal = selectedOverride[type];
                const suggestType = type === 'value' ? 'value' : 'property';
                let partial;
                if (partialInput !== undefined) {
                    partial = partialInput;
                } else {
                    partial = angular.isArray(currVal) ? '' : currVal;
                }

                return dashboardVariableSuggestUtils.suggestNoSynthetic({
                    charts: [],
                    suggestType,
                    limit,
                    partialInput: partial || '',
                    property: type === 'value' ? selected.property : '',
                    preferredSuggestions,
                    allowWildCards: !selected.required,
                });
            }

            $scope.selectPreferredSuggestion = function (suggestion) {
                const localVariable = getLocalVariable();
                if (!localVariable.preferredSuggestions) {
                    localVariable.preferredSuggestions = [];
                }
                if (localVariable.preferredSuggestions.indexOf(suggestion) === -1) {
                    localVariable.preferredSuggestions.push(suggestion);
                } else {
                    $log.warn('Attempted to add a preferred suggestion that already exists!');
                }
            };

            $scope.selectPreferredSuggestionOverrides = function (suggestion) {
                let localVariableOverride = getLocalVariableOverride();

                if (angular.equals(localVariableOverride, {})) {
                    localVariableOverride = instantiateVariableOverrideForCurrentVariable();
                }

                if ($scope.activeVariableOverride !== localVariableOverride) {
                    $scope.activeVariableOverride = localVariableOverride;
                }

                if (!localVariableOverride.preferredSuggestions) {
                    localVariableOverride.preferredSuggestions = [];
                }

                if (!localVariableOverride.preferredSuggestions.includes(suggestion)) {
                    localVariableOverride.preferredSuggestions.push(suggestion);
                } else {
                    $log.warn('Attempted to add a preferred suggestion that already exists!');
                }

                if (
                    !$scope.variablesOverrides.find((variableOverride) => {
                        return variableOverride.property === localVariableOverride.property;
                    })
                ) {
                    $scope.variablesOverrides.push(localVariableOverride);
                }
            };

            $scope.removePreferredSuggestion = function (index) {
                getLocalVariable().preferredSuggestions.splice(index, 1);
            };

            $scope.removePreferredSuggestionOverride = function (index) {
                getLocalVariableOverride().preferredSuggestions.splice(index, 1);
            };

            function getLocalVariable() {
                return $scope.variables[$scope.active];
            }

            function getLocalVariableOverride() {
                const currentVariableProperty = getLocalVariable().property;
                if (currentVariableProperty) {
                    let variableOverride = $scope.variablesOverrides.find((variableOverride) => {
                        return variableOverride.property === currentVariableProperty;
                    });

                    if (!variableOverride) {
                        $log.error(
                            `Could not find variable override for ${currentVariableProperty}!  Save may not work correctly.`
                        );
                        variableOverride = instantiateVariableOverrideForCurrentVariable();
                        $scope.variablesOverrides.push(variableOverride);
                    }

                    return variableOverride;
                } else {
                    $log.warn('Current property is empty but was requested, returned empty.');
                    return {};
                }
            }
        },
    ],
};
