export default [
    '$scope',
    '$q',
    '_',
    'MAX_CUSTOM_CATEGORY',
    'currentUser',
    'suggestAPIService',
    'organizationService',
    function (
        $scope,
        $q,
        _,
        MAX_CUSTOM_CATEGORY,
        currentUser,
        suggestAPIService,
        organizationService
    ) {
        const autoSuggestLimit = 100;
        const sortOptions = {
            update: markFormDirty,
        };

        $scope.maxCustomCategories = MAX_CUSTOM_CATEGORY;
        $scope.sortOptions = sortOptions;
        $scope.showCategory = showCategory;
        $scope.addNewCategory = addNewCategory;
        $scope.removeActiveCategory = removeActiveCategory;
        $scope.saveAndClose = saveAndClose;
        $scope.getPropertySuggestions = getPropertySuggestions;
        $scope.selectProperty = selectProperty;

        init();

        function init() {
            $scope.isEditable = false;
            $scope.loading = true;

            $q.all({
                admin: currentUser.isAdmin(),
                categories: organizationService.getCustomCategories(),
            })
                .then(({ admin, categories }) => {
                    $scope.isEditable = admin;
                    $scope.customCategories = categories;
                    if (!$scope.customCategories.length) {
                        addNewCategory();
                    }
                    showCategory(0);
                })
                .finally(() => ($scope.loading = false));
        }

        function showCategory(index) {
            if ($scope.customCategories) {
                $scope.activeCategory = $scope.customCategories[index];
            }
        }

        function addNewCategory() {
            if (
                !$scope.isEditable ||
                $scope.customCategories.length >= $scope.maxCustomCategories
            ) {
                return;
            }

            const newCategory = { name: '' };
            $scope.customCategories.push(newCategory);
            showCategory($scope.customCategories.length - 1);
            markFormDirty();
        }

        function removeActiveCategory() {
            if (
                !$scope.isEditable ||
                !$scope.activeCategory ||
                _.isEmpty($scope.customCategories)
            ) {
                return;
            }

            const index = $scope.customCategories.indexOf($scope.activeCategory);
            if (index >= 0) {
                $scope.customCategories.splice(index, 1);
                const selectionIndex =
                    index < $scope.customCategories.length
                        ? index
                        : $scope.customCategories.length - 1;
                showCategory(selectionIndex);
                markFormDirty();
            }
        }

        function getValidCustomCategories() {
            return $scope.customCategories.filter((category) => !_.isEmpty(category.name));
        }

        function save() {
            $scope.errorMessage = null;
            const customCategories = getValidCustomCategories();
            return organizationService
                .updateCustomCategories(customCategories)
                .then((result) => {
                    $scope.customCategories = result;
                })
                .catch((response) => {
                    $scope.errorMessage = response.message;
                    throw response.message;
                });
        }

        function saveAndClose() {
            save().then(() => $scope.$close(true));
        }

        function markFormDirty() {
            $scope.customCategoryForm.$setDirty();
        }

        function getPropertySuggestions() {
            return suggestAPIService.getSignalFlowSuggest({
                programs: [],
                partialInput: $scope.activeCategory.name,
                limit: autoSuggestLimit,
            });
        }

        function selectProperty(name) {
            $scope.activeCategory.name = name;
        }
    },
];
