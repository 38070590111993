export const chartDisplayCanvasRenderer = [
    '$log',
    'dyGraphUtils',
    'themeService',
    function ($log, dyGraphUtils, themeService) {
        const TRIANGLE_BASE = 17;
        const TRIANGLE_HEIGHT = 12;
        const OUTLINE_WIDTH = 2;
        const DIAMOND_SIZE = TRIANGLE_HEIGHT - OUTLINE_WIDTH;
        const DISTANCE_FROM_X_AXIS = OUTLINE_WIDTH - 1;
        const SIZE_MULTIPLIER = 1.1;
        const DEFAULT_POINT_SIZE = 3;
        const WATERMARK_FONT_SIZE = 12;
        const WATERMARK_FONT = `${WATERMARK_FONT_SIZE}px sans-serif`;
        const WATERMARK_Y_OFFSET = 5;
        const WATERMARK_X_OFFSET = 10;
        const WATERMARK_REQUIRED_SPACE = WATERMARK_FONT_SIZE + WATERMARK_Y_OFFSET * 2;
        const DARK_THEME_STRONG_COLOR = 'rgba(191,191,191,0.8)';
        const LIGHT_THEME_STRONG_COLOR = 'rgba(130,130,130,0.4)';
        const DARK_THEME_WATERMARK_COLOR = 'rgba(200,200,200,0.8)';
        const LIGHT_THEME_WATERMARK_COLOR = 'rgba(0,0,0,0.5)';

        function getOutlineColor() {
            return themeService.dark ? '#aaaaaa' : '#555555';
        }

        function drawEventMarker(
            timestamp,
            type,
            basecolor,
            outline,
            ctx,
            drawLines,
            lineColor,
            canvasPositions,
            dygraph,
            increaseSize
        ) {
            // Draw event marker with top vertex placed at the x coordinate position
            // at the given timestamp
            const lineDelta = drawLines ? 0.5 : 0;
            let xpos;
            try {
                xpos = Math.ceil(dygraph.toDomXCoord(timestamp));
            } catch (e) {
                $log.warn('Could not draw an event marker because dygraph was not ready!');
                return;
            }
            xpos -= lineDelta;

            // Take into consideration the width of the shape outlines, which
            // affects positioning
            const shrinkFactor = outline ? 0 : OUTLINE_WIDTH;
            // + 1 for the x-axis line
            let verticalOffset = increaseSize
                ? Math.floor((TRIANGLE_HEIGHT * SIZE_MULTIPLIER - TRIANGLE_HEIGHT) / 2) + 1
                : 1;
            if (outline) {
                verticalOffset += DISTANCE_FROM_X_AXIS;
            }
            ctx.beginPath();

            if (type === 'ALERT') {
                // Alert triangle
                let base = TRIANGLE_BASE;
                let height = TRIANGLE_HEIGHT;
                if (outline) {
                    base -= OUTLINE_WIDTH;
                    height -= OUTLINE_WIDTH;
                }
                if (increaseSize) {
                    base = Math.ceil(base * SIZE_MULTIPLIER);
                    height = Math.ceil(height * SIZE_MULTIPLIER);
                }

                // Top vertex
                ctx.moveTo(xpos, canvasPositions.yBottom + verticalOffset);
                // Bottom right
                ctx.lineTo(
                    xpos + Math.ceil(base / 2),
                    canvasPositions.yBottom + height + verticalOffset
                );
                // Bottom left
                ctx.lineTo(
                    xpos - Math.ceil(base / 2),
                    canvasPositions.yBottom + height + verticalOffset
                );
                ctx.closePath();
                ctx.strokeStyle = basecolor;
                if (outline) {
                    ctx.lineWidth = OUTLINE_WIDTH;
                    ctx.stroke();
                } else {
                    ctx.fillStyle = basecolor;
                    ctx.fill();
                }

                if (drawLines) {
                    ctx.beginPath();
                    ctx.lineTo(xpos, canvasPositions.yBottom + shrinkFactor + verticalOffset);
                    ctx.lineTo(xpos, 0);
                    ctx.lineTo(xpos + lineDelta, 0);
                    ctx.lineTo(
                        xpos + lineDelta,
                        canvasPositions.yBottom + shrinkFactor + verticalOffset
                    );
                    ctx.closePath();
                    ctx.strokeStyle = lineColor || basecolor;
                    ctx.lineWidth = lineDelta;
                    ctx.stroke();
                }
            } else if (type === 'USER_DEFINED') {
                // Custom event diamond
                let eventDiamondHeight = DIAMOND_SIZE;
                if (increaseSize) {
                    eventDiamondHeight = Math.ceil(eventDiamondHeight * SIZE_MULTIPLIER);
                }
                const eventDiamondWidth = Math.ceil(eventDiamondHeight / 2);

                // Top vertex
                ctx.moveTo(xpos, canvasPositions.yBottom + verticalOffset);
                // Right
                ctx.lineTo(
                    xpos + eventDiamondWidth,
                    canvasPositions.yBottom + eventDiamondWidth + verticalOffset
                );
                // Bottom
                ctx.lineTo(xpos, canvasPositions.yBottom + eventDiamondHeight + verticalOffset);
                // Left
                ctx.lineTo(
                    xpos - eventDiamondWidth,
                    canvasPositions.yBottom + eventDiamondWidth + verticalOffset
                );
                ctx.closePath();
                ctx.lineWidth = OUTLINE_WIDTH;
                ctx.strokeStyle = basecolor || getOutlineColor();
                ctx.stroke();

                if (drawLines) {
                    ctx.beginPath();
                    ctx.lineTo(xpos, canvasPositions.yBottom + shrinkFactor + verticalOffset);
                    ctx.lineTo(xpos, 0);
                    ctx.lineTo(xpos, canvasPositions.yBottom + shrinkFactor + verticalOffset);
                    ctx.strokeStyle = lineColor || basecolor;
                }

                ctx.closePath();
                ctx.lineWidth = 1;
                ctx.stroke();
                ctx.moveTo(xpos, canvasPositions.yBottom + shrinkFactor);
            }
        }

        function drawVerticalLine(timestamp, ctx, canvasPositions, dygraph) {
            const lineWidth = 10;
            const xpos = Math.ceil(dygraph.toDomCoords(timestamp, 0, 0)[0]) - lineWidth / 2;
            ctx.beginPath();
            ctx.moveTo(xpos, 0);
            ctx.lineTo(xpos, canvasPositions.yBottom + DISTANCE_FROM_X_AXIS);
            ctx.lineTo(xpos + lineWidth, canvasPositions.yBottom + DISTANCE_FROM_X_AXIS);
            ctx.lineTo(xpos + lineWidth, 0);
            ctx.lineTo(xpos, 0);
            ctx.closePath();
            ctx.fillStyle = themeService.dark ? DARK_THEME_STRONG_COLOR : LIGHT_THEME_STRONG_COLOR;
            ctx.fill();
        }

        function isYAxisReady(dygraph, yAxis) {
            try {
                return !!((dygraph && dygraph.yAxisRanges()) || {})[yAxis];
            } catch (e) {
                $log.warn(
                    'Attempted to query dygraph yaxis information and caught a thrown exception.'
                );
                return false;
            }
        }

        function drawLine(val, yaxis, ctx, dygraph) {
            if (!isYAxisReady(dygraph, yaxis)) {
                return;
            }

            let xmin, xmax, yLevel;

            try {
                xmin = dygraph.toDomXCoord(dygraph.xAxisRange()[0]);
                xmax = dygraph.toDomXCoord(dygraph.xAxisRange()[1]);
                yLevel = dygraph.toDomYCoord(val, yaxis);

                ctx.save();
                dyGraphUtils.setClip(ctx, dygraph);
                ctx.strokeStyle = themeService.dark
                    ? DARK_THEME_WATERMARK_COLOR
                    : LIGHT_THEME_WATERMARK_COLOR;
                ctx.beginPath();
                if (yaxis === 1) {
                    // THIS IS PROVIDED BY DYGRAPH.  MAY SCREW UP DEPENDENCIES
                    ctx.installPattern([10, 10]);
                }
                ctx.moveTo(xmax, yLevel);
                ctx.lineTo(xmin, yLevel);
                ctx.lineWidth = 1;
                ctx.closePath();
                ctx.stroke();
                if (yaxis === 1) {
                    // THIS IS PROVIDED BY DYGRAPH.  MAY SCREW UP DEPENDENCIES
                    ctx.uninstallPattern();
                }
                ctx.restore();
            } catch (e) {
                $log.error('yAxis not ready for overlay draw', e);
                return;
            }
        }

        function getDomYOffset(ctx, dygraph, yAxis, value) {
            if (!isYAxisReady(dygraph, yAxis)) {
                return;
            }

            return dygraph.toDomYCoord(value, yAxis);
        }

        function drawLabel(ctx, dygraph, value, yAxis, label, offsets) {
            if (!isYAxisReady(dygraph, yAxis)) {
                return;
            }

            let xMin, xMax, yPosition;
            const leftJustify = yAxis === 0;

            try {
                xMin = dygraph.toDomXCoord(dygraph.xAxisRange()[0]);
                xMax = dygraph.toDomXCoord(dygraph.xAxisRange()[1]);

                yPosition = dygraph.toDomYCoord(value, yAxis);

                // flip if watermark is going off the top of the chart.
                const yMax = dygraph.yAxisRange(yAxis)[1];
                let needsFlip =
                    yPosition - dygraph.toDomYCoord(yMax, yAxis) < WATERMARK_REQUIRED_SPACE;

                if (!needsFlip) {
                    // check if label conflicts with other labels, flip if necessary. Note:
                    // this might still conflict with other watermark, but there is no good
                    // solution for that case.
                    needsFlip = offsets.some((offset) => {
                        return (
                            offset !== yPosition &&
                            yPosition - offset < WATERMARK_REQUIRED_SPACE &&
                            yPosition > offset
                        );
                    });

                    // undo needsFlip if flipping puts label below the bottom of the chart
                    if (needsFlip) {
                        const yMin = dygraph.yAxisRange(yAxis)[0];
                        needsFlip =
                            dygraph.toDomYCoord(yMin, yAxis) - yPosition > WATERMARK_REQUIRED_SPACE;
                    }
                }

                if (needsFlip) {
                    yPosition += WATERMARK_FONT_SIZE + WATERMARK_Y_OFFSET;
                } else {
                    // when label is above line give and extra 2px push because of y's g's and p's
                    yPosition -= WATERMARK_Y_OFFSET + 2;
                }

                const xPosition = leftJustify
                    ? xMin + WATERMARK_X_OFFSET
                    : xMax - WATERMARK_X_OFFSET;

                ctx.fillStyle = themeService.dark
                    ? DARK_THEME_WATERMARK_COLOR
                    : LIGHT_THEME_WATERMARK_COLOR;
                ctx.textAlign = leftJustify ? 'left' : 'right';
                ctx.font = WATERMARK_FONT;
                ctx.fillText(label, xPosition, yPosition);
            } catch (e) {
                $log.error('yAxis not ready for label draw', e);
            }
        }

        function getPointOverlayDrawer(ctx, dygraph) {
            return {
                drawPoint(point, color, size, outlineColor) {
                    if (!point) {
                        return;
                    }
                    ctx.save();

                    const xpos = dygraph.toDomXCoord(point.point.xval);
                    dyGraphUtils.setClip(ctx, dygraph);
                    size = size || DEFAULT_POINT_SIZE;

                    ctx.fillStyle = color;
                    ctx.beginPath();
                    ctx.arc(xpos, point.point.canvasy, 3, 0, 2 * Math.PI);
                    ctx.fill();
                    if (outlineColor) {
                        ctx.strokeStyle = outlineColor;
                        ctx.stroke();
                    }
                    ctx.closePath();

                    ctx.restore();
                },
            };
        }

        function drawEventBarBox(box, ctx, canvasPositions, dygraph) {
            const lineHeight = 12;
            let xposStart = dygraph.toDomXCoord(dygraph.xAxisRange()[0]);
            let xposEnd = dygraph.toDomXCoord(dygraph.xAxisRange()[1]);
            if (box.start) {
                xposStart = Math.max(xposStart, Math.ceil(dygraph.toDomCoords(box.start, 0, 0)[0]));
            }
            if (box.end) {
                xposEnd = Math.min(xposEnd, Math.ceil(dygraph.toDomCoords(box.end, 0, 0)[0]));
            }
            if (xposEnd - xposStart < 2) {
                // don't draw anything small or negative size
                return;
            }
            const ystart = canvasPositions.yBottom;
            const yend = ystart + lineHeight;
            ctx.beginPath();
            ctx.moveTo(xposStart, ystart);
            ctx.lineTo(xposStart, yend);
            ctx.lineTo(xposEnd, yend);
            ctx.lineTo(xposEnd, ystart);
            ctx.lineTo(xposStart, ystart);
            ctx.closePath();
            ctx.fillStyle = box.color;
            ctx.fill();
        }

        return {
            drawEventMarker,
            drawLine,
            getDomYOffset,
            drawLabel,
            getPointOverlayDrawer,
            drawVerticalLine,
            drawEventBarBox,
        };
    },
];
