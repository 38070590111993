'use strict';

import './dropdowns/module';

import { react2angular } from 'react2angular';
import permissionList from './permissionList/permissionList';
import permissions from './permissions/permissions';
import notFound from './notFound/notFound';
import tabby from './tabby/tabby';
import tabbyTab from './tabby/tabbyTab';
import exitGuardModal from './exitGuardModal/exitGuardModal';
import title from './title/title';
import cozyText from './cozyText/cozyText';
import uiBootstrap from 'angular-ui-bootstrap';
import scalingUnitService from '../../../common/ui/formatting/scalingUnitService';
import download from './download/download';
import corpSiteSignupLink from './corpSiteSignupLink/corpSiteSignupLink';
import { AWS_INTEGRATION_CONSTANTS } from './integration/awsIntegrationConstants';
import awsIntegrationService from './integration/awsIntegrationService';
import awsCredentialsHelperService from './integration/awsCredentialsHelperService';
import opsgenieIntegrationService from './integration/opsgenieIntegrationService';
import AccessControl from '../../../common/ui/accessControl/AccessControl';

angular
    .module('sfx.ui', [
        'sfx.ui.dropdowns',
        uiBootstrap,
        'sfUtil',
        'sfx.external',
        'sfx.util',
        'sfx.data',
        'sfx.filterControls',
        'signalview.routeParameters',
        'signalview.typeahead',
        'signalview.detectorV2',
    ])
    .constant('AWS_INTEGRATION_CONSTANTS', AWS_INTEGRATION_CONSTANTS)
    .component('cozyText', cozyText)
    .component('permissionList', permissionList)
    .component('accessControl', react2angular(AccessControl))
    .component('permissions', permissions)
    .component('notFound', notFound)
    .component('tabby', tabby)
    .component('tabbyTab', tabbyTab)
    .component('corpSiteSignupLink', corpSiteSignupLink)
    .service('download', download)
    .service('title', title)
    .service('exitGuardModal', exitGuardModal)
    .service('scalingUnitService', scalingUnitService)
    .service('awsIntegrationService', awsIntegrationService)
    .service('awsCredentialsHelperService', awsCredentialsHelperService)
    .service('opsgenieIntegrationService', opsgenieIntegrationService);
