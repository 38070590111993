import AlertEventV2Service from './service/alertEventModalV2Service';
import ChartInformationService from './service/chartInformationService';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { detectorTypeResolver } from '../../../app/alerts/detectorTypeResolver';

export default [
    '$scope',
    '$q',
    '$http',
    'API_URL',
    'event',
    'eventTimeSeriesService',
    'timeZoneService',
    'DETECTOR_CHART_IDENTIFIERS',
    'CHART_DISPLAY_EVENTS',
    'timepickerUtils',
    'timeToRange',
    'ChartDisplayDebounceService',
    'detectorService',
    'resolutionToChartRangeService',
    'detectorPriorityService',
    'currentUser',
    'hasCapability',
    'featureEnabled',
    'resolveAlert',
    'alertTypeService',
    '$location',
    'markdownConverter',
    '$sanitize',
    'chartbuilderUtil',
    'userV2Service',
    'eventService',
    'valueFormatter',
    'credentialV2Service',
    'teamsApiService',
    'NOTIFIER_INTEGRATIONS_V2_MAP',
    'v2DetectorResolverUtil',
    function (
        $scope,
        $q,
        $http,
        API_URL,
        event,
        eventTimeSeriesService,
        timeZoneService,
        DETECTOR_CHART_IDENTIFIERS,
        CHART_DISPLAY_EVENTS,
        timepickerUtils,
        timeToRange,
        ChartDisplayDebounceService,
        detectorService,
        resolutionToChartRangeService,
        detectorPriorityService,
        currentUser,
        hasCapability,
        featureEnabled,
        resolveAlert,
        alertTypeService,
        $location,
        markdownConverter,
        $sanitize,
        chartbuilderUtil,
        userV2Service,
        eventService,
        valueFormatter,
        credentialV2Service,
        teamsApiService,
        NOTIFIER_INTEGRATIONS_V2_MAP,
        v2DetectorResolverUtil
    ) {
        const alertEventModalV2Service = new AlertEventV2Service(
            featureEnabled,
            timeZoneService,
            resolutionToChartRangeService,
            detectorPriorityService,
            chartbuilderUtil,
            $http,
            API_URL,
            v2DetectorResolverUtil
        );

        const chartInformationService = new ChartInformationService(
            detectorPriorityService,
            valueFormatter
        );

        const eventTimestamp = timeZoneService.moment(event.timestamp);
        const eventResolution = alertEventModalV2Service.getResolution(event);
        const defaultRange = alertEventModalV2Service.getDefaultRangeConfiguration(
            eventTimestamp,
            eventResolution
        );

        const detectorId = safeLookup(event, 'metadata.sf_detectorId');
        const incidentId = alertEventModalV2Service.getIncidentId(event);

        $scope.alertEventService = alertEventModalV2Service;

        $scope.DETECTOR_CHART_IDENTIFIERS = DETECTOR_CHART_IDENTIFIERS;

        $scope.chartDisplayDebouncer = new ChartDisplayDebounceService();
        $scope.chartDisplayDebouncer.setEnabled(false);

        $scope.event = event;

        $scope.sharedChartState = {};
        $scope.jobScope = { jobFeedback: [] };

        $scope.initRange = defaultRange;
        $scope.initialLocalViewTime = eventTimestamp.valueOf();

        $scope.model = alertEventModalV2Service.getInitialEventModel(event, defaultRange);

        $scope.detectorCreatedByName = '';
        $scope.detectorUpdatedByName = '';
        $scope.detectorCreatedByUserId = null;
        $scope.detectorUpdatedByUserId = null;
        $scope.detectorCreateDate = null;
        $scope.detectorUpdateDate = null;
        $scope.foundDetector = false;
        $scope.otherEventInIncident = null;
        $scope.otherEventLink = null;
        $scope.notificationRecipients = null;
        $scope.plotColorMap = {};
        $scope.chartInfoWithPlotColors = null;

        $scope.$on('PLOT_COLOR_PICKED', (e, plotColor) => {
            $scope.plotColorMap[plotColor.streamLabel] = plotColor.color;
            $scope.chartInfoWithPlotColors = $scope.incidentInformationList.map((incidentInfo) =>
                chartInformationService.getChartInformation(
                    event,
                    incidentInfo,
                    $scope.userOrg,
                    $scope.plotColorMap
                )
            );
        });

        const { plots, identifierToValue } = alertEventModalV2Service.parseInputs(event);
        // extend base model with each signal plot from event.properties.inputs
        plots.forEach((plot) =>
            alertEventModalV2Service.extendModelWithNewPlot($scope.model.sf_uiModel, plot)
        );

        // replace variables with values
        if (event.metadata.sf_detectOnCondition) {
            angular.forEach(identifierToValue, (val, identifier) => {
                event.metadata.sf_detectOnCondition =
                    $scope.event.metadata.sf_detectOnCondition.replace(identifier, val);
            });
        }

        // Incident is drawn as a vertical line but should be displayed only on detailed chart
        // Since vertical lines are inside chartconfig in model shared between both charts
        // we need to override configuration for exploratory chart
        $scope.mainChartConfigOverride = {
            verticalLines: [],
        };

        $scope.getChartInformation = function (incidentInfo) {
            return chartInformationService.getChartInformation(event, incidentInfo, $scope.userOrg);
        };

        $scope.shouldShowNextSteps = () => $scope.event && angular.isDefined($scope.isActive);
        $scope.resolveAlert = resolveAlert;
        $scope.hasCapability = hasCapability;
        $scope.sanitizer = $sanitize;
        $scope.markdownConverter = markdownConverter;

        $scope.getRuleLabel = () =>
            detectorTypeResolver.isSLODetectorEvent($scope.event.metadata)
                ? $scope.event.metadata.sf_eventSloAlertType
                : $scope.event.metadata.sf_displayName;

        isAlertActive(event).then((isActive) => ($scope.isActive = isActive));

        getCurrentMutingStates(event).then((mutingStates) => {
            $scope.currentMutingStates = mutingStates;
        });

        getUserOrg().then((userOrg) => ($scope.userOrg = userOrg));

        if (detectorId) {
            getDetector(detectorId).then((detector) => {
                $scope.detector = detector;

                $scope.foundDetector = true;

                $scope.detectorCreateDate = detector.sf_createdOnMs || detector.created;
                $scope.detectorUpdateDate = detector.sf_updatedOnMs || detector.lastUpdated;

                const createdByUserId = detector.sf_creator || detector.creator;
                if (createdByUserId) {
                    userV2Service.getOrgMemberForUserId(createdByUserId).then(function (orgMember) {
                        $scope.detectorCreatedByName = orgMember.fullName;
                        $scope.detectorCreatedByUserId = orgMember.id;
                    });
                }

                const updatedByUserId = detector.sf_updatedBy || detector.lastUpdatedBy;
                if (updatedByUserId) {
                    userV2Service.getOrgMemberForUserId(updatedByUserId).then(function (orgMember) {
                        $scope.detectorUpdatedByName = orgMember.fullName;
                        $scope.detectorUpdatedByUserId = orgMember.id;
                    });
                }
            });

            getRelatedDashboards(detectorId).then((dashboards) => ($scope.dashboards = dashboards));
        }

        getNotificationRecipients();

        if (incidentId) {
            eventService
                .search({
                    query: 'incidentId:"' + incidentId + '"',
                    limit: 2,
                    offset: 0,
                })
                .then(function (data) {
                    if (data && data.length) {
                        // Find the 'other' event that is part of this incident if it exists
                        if (data.length > 1) {
                            data.forEach((theEvent) => {
                                if (theEvent.id !== event.id) {
                                    // There will only be 2 events in an incident - the other one is the related event
                                    $scope.otherEventInIncident = theEvent;
                                }
                            });

                            // Generate a link relevant to the local event modal context to allow link to go to other related event
                            const params = $location.search();
                            const queryParams = Object.keys(params)
                                .map(function (key) {
                                    if (key !== 'eventId' && key !== 'viewTime') {
                                        return key + '=' + params[key];
                                    }
                                })
                                .join('&');
                            $scope.otherEventLink =
                                '#' +
                                $location.path() +
                                '?' +
                                queryParams +
                                'eventId=' +
                                $scope.otherEventInIncident.id;
                        }
                    }
                });
        }

        $scope.gotoOtherEvent = function () {
            if ($scope.otherEventInIncident) {
                $location.search('eventId', $scope.otherEventInIncident.id);
            }
        };

        if (event.properties.sfui_incidentInformation) {
            $scope.incidentInformationList = angular.fromJson(
                event.properties.sfui_incidentInformation
            );
        } else {
            // render one chart with no incident info
            $scope.incidentInformationList = [null];
        }

        $scope.$on(CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED, onChartTimeRangeSelected);

        function onChartTimeRangeSelected(ev, start, end) {
            const timeobj = {};
            timeobj.startUTC = start;
            timeobj.endUTC = end;
            onTimePickerChange(ev, timeobj);
        }

        $scope.$on('timePickerChanged', onTimePickerChange);

        function onTimePickerChange(ev, timeobj) {
            const chartconfig = $scope.model.sf_uiModel.chartconfig;
            const defaultRange = $scope.initRange;

            alertEventModalV2Service.resetChartConfig(chartconfig);

            const timeRange = timeToRange(timeobj);
            let viewTime = null;
            const updatePatch = {};

            if (!timeRange.absoluteStart && timeRange.range) {
                updatePatch.range = timeRange.range;
                updatePatch.rangeEnd = 0;
            } else if (timeRange.absoluteStart && timeRange.absoluteEnd) {
                updatePatch.absoluteStart = timeRange.absoluteStart;
                updatePatch.absoluteEnd = timeRange.absoluteEnd;

                // it's default range so default viewTime should be set
                if (
                    updatePatch.absoluteStart === defaultRange.absoluteStart &&
                    updatePatch.absoluteEnd === defaultRange.absoluteEnd
                ) {
                    viewTime = $scope.initialLocalViewTime;
                }
            }

            angular.extend(chartconfig, updatePatch);

            $scope.$broadcast(
                'set chart config override',
                viewTime,
                timepickerUtils.chartConfigToTimePickerObj(updatePatch)
            );
        }

        function getUserOrg() {
            return currentUser.orgId();
        }

        function getDetector(detectorId) {
            return detectorService.get(detectorId);
        }

        function getRelatedDashboards(detectorId) {
            return detectorService.getRelatedDashboards(detectorId);
        }

        function isAlertActive(event) {
            return eventTimeSeriesService
                .get(event.tsId)
                .then(
                    (ets) =>
                        event.properties.incidentId &&
                        event.properties.incidentId === ets.sf_incidentId &&
                        event.properties.is &&
                        event.properties.is === ets.sf_anomalyState &&
                        !alertTypeService.isClearingEvent(event.properties.is)
                );
        }

        function getCurrentMutingStates(event) {
            return eventTimeSeriesService.get(event.tsId).then((ets) => ({
                isMuted: ets.sf_isMuted,
                triggeredWhileMuted: ets.sf_triggeredWhileMuted,
                triggeredNotificationSent: ets.sf_triggeredNotificationSent,
            }));
        }

        function getNotificationRecipients() {
            const notificationRecipients =
                alertEventModalV2Service.getNotificationRecipients(event);
            const notificationNameRequests = [];

            if (notificationRecipients && notificationRecipients.length !== 0) {
                notificationRecipients.forEach((recipient) => {
                    addTitleToNotification(recipient);

                    if (isTeamNotificationWithoutName(recipient)) {
                        const request = teamsApiService.get(recipient.team).then((result) => {
                            recipient.teamName = result.name;
                        });
                        notificationNameRequests.push(request);
                    }
                    if (isCredentialNotificationWithoutName(recipient)) {
                        const request = credentialV2Service
                            .get(recipient.credentialId)
                            .then((result) => {
                                recipient.credentialName = result.name;
                            });
                        notificationNameRequests.push(request);
                    }
                });
            }

            // Set the recipients on success or failure since a single name request failure would cause all of these to fail otherwise
            $q.all(notificationNameRequests).then(
                setNotificationRecipientsOnScope,
                setNotificationRecipientsOnScope
            );

            function setNotificationRecipientsOnScope() {
                $scope.notificationRecipients = notificationRecipients;
            }
        }

        function addTitleToNotification(recipient) {
            const notificationType = NOTIFIER_INTEGRATIONS_V2_MAP[recipient.type];
            if (notificationType) {
                recipient.title = notificationType;
            }
        }

        function isTeamNotificationWithoutName(recipient) {
            return recipient.type === 'team' && !recipient.teamName;
        }

        function isCredentialNotificationWithoutName(recipient) {
            return recipient.credentialId && !recipient.credentialName;
        }
    },
];
