import React, { FC } from 'react';
import { Route, Switch } from './ReactRouteUtils';
import { Ng2React } from '../../common/util/Ng2React';
import login from '../../legacy/common/security/login/login';
import { ngRoute } from './ngRoute';
import SignUpInvite from '../../legacy/app/administrative/passwordreset/SignUpInvite';
import { googleTokenExchange } from '../../common/security/googleSignUp/googleTokenExchange';
import PasswordReset from '../../legacy/app/administrative/passwordreset/PasswordReset';
import PasswordRequest from '../../legacy/app/administrative/passwordreset/PasswordRequest';
import SigninOrganization from '../../legacy/common/security/login/SigninOrganization';
import saml from '../../legacy/app/saml/saml';
import samlNotify from '../../legacy/app/saml/samlNotify';
import oidc from '../../legacy/app/oidc/oidc';
import emailVerification from '../../legacy/common/security/login/emailVerification';
import otpVerification from '../../legacy/common/security/login/otpVerification';
import roleValidationError from '../../legacy/common/security/login/roleValidationError';

export const AuthenticationRoutes: FC = () => (
    <Switch>
        <Route exact path={['/idp/signin', '/signin', '/signin/:type']} title="Splunk Login">
            <Ng2React name="login" definition={login} />
        </Route>

        <Route exact path="/signindomain" title="Sign In with Organization">
            <Ng2React name="signinOrganization" definition={SigninOrganization} />
        </Route>

        <Route
            exact
            path={['/invite', '/invite/:accessToken']}
            title="Setup Account"
            resolves={{ accessToken: (): any => ngRoute.params.accessToken }}
        >
            <Ng2React name="signUpInvite" definition={SignUpInvite} />
        </Route>

        <Route exact path="/google/:token" render={googleTokenExchange} />

        <Route
            exact
            path="/saml/:token"
            title="Authenticating"
            resolves={{ samlToken: (): any => ngRoute.params.token }}
        >
            <Ng2React name="saml" definition={saml} />
        </Route>

        <Route exact path="/saml-notify" title="Your login request">
            <Ng2React name="samlNotify" definition={samlNotify} />
        </Route>

        <Route
            exact
            path={['/password-reset', '/password-reset/:accessToken']}
            title="Reset Password"
            resolves={{ accessToken: (): any => ngRoute.params.accessToken }}
        >
            <Ng2React name="passwordReset" definition={PasswordReset} />
        </Route>

        <Route exact path="/forgot-password" title="Reset Password">
            <Ng2React name="passwordRequest" definition={PasswordRequest} />
        </Route>

        <Route exact path="/verify/email" title="Verify Email">
            <Ng2React name="emailVerification" definition={emailVerification} />
        </Route>

        <Route exact path="/verify/onetimepassword" title="Verify Email">
            <Ng2React name="otpVerification" definition={otpVerification} />
        </Route>

        <Route exact path="/verify/error" title="OIDC Validation Error">
            <Ng2React name="roleValidationError" definition={roleValidationError} />
        </Route>

        <Route
            exact
            path="/oidc"
            title="Open Id Connect"
            resolves={{ token: (): any => ngRoute.params.token }}
        >
            <Ng2React name="oidc" definition={oidc} />
        </Route>
    </Switch>
);
