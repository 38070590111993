import templateUrl from './eventSources.tpl.html';

angular.module('sfx.ui').directive('eventSources', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl,
            scope: {
                // `properties` is a simple key/value pairs object, and will be
                // rendered "raw" by the template.
                properties: '=?',
                // `inputs` is a v3 alert event schema inputs info object. The
                // key/value pairs of the dimensions of each input will be rendered by
                // the template.
                inputs: '=?',
            },
            link: function ($scope, $element) {
                $scope.sources = angular.extend({}, $scope.properties);
                angular.forEach($scope.inputs, (val) => {
                    angular.extend($scope.sources, val.key);
                });

                $timeout(() => {
                    // when we render links in markdown in the event feed, we want to
                    // go to the link and prevent the modal from popping out
                    $element.find('.markdown-event-source-value a').click((ev) => {
                        ev.stopPropagation();
                    });
                });
            },
        };
    },
]);
