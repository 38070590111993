import sourceFiltersIsolateTemplateUrl from '../../../app/dashboard/sourceFiltersIsolate.tpl.html';

export const bindings = {
    allCharts: '=?',
    filterAlias: '=?',
    sourceFilters: '=?',
    showFilterOptions: '=?',
    hideLabel: '=?',
    dontUpdateUrl: '=?',
    activeVariables: '=?',
    disabled: '<?',
    labelId: '@?',
    getCurrentTimeRange: '<?',
    announceSuggestionsCount: '@?',
};

export default [
    function () {
        return {
            restrict: 'E',
            templateUrl: sourceFiltersIsolateTemplateUrl,
            scope: bindings,
        };
    },
];
