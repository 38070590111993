import React, { FC } from 'react';
import LOLineChart from './LOLineChart';

type LOLineChartComponentProps = {
    ingestUsage: { x: Date; y: number }[];
    ingestQuota: number;
    indexUsage: { x: Date; y: number }[];
    indexQuota: number;
};

const LOLineChartComponent: FC<LOLineChartComponentProps> = ({
    ingestQuota,
    indexQuota,
    ingestUsage,
    indexUsage,
}) => {
    return (
        <div>
            <div className="org-usage-section-title">Usage Trends</div>
            <div className="usage-charts-container">
                <LOLineChart
                    usage={ingestUsage}
                    quota={ingestQuota}
                    usagelabel="Ingest Usage Trend"
                    labelToClassName="ingest-usage-line-chart"
                />
            </div>
            <div className="usage-charts-container">
                <LOLineChart
                    usage={indexUsage}
                    quota={indexQuota}
                    usagelabel="Index Usage Trend"
                    labelToClassName="index-usage-line-chart"
                />
            </div>
        </div>
    );
};

export default LOLineChartComponent;
