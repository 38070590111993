import templateUrl from './chartOptions.tpl.html';

angular.module('signalview.chartbuilder').directive('chartOptions', [
    '$q',
    'config',
    'chartDisplayUtils',
    'colorAccessibilityService',
    '$rootScope',
    'userAnalytics',
    'MAX_NO_METRIC_MESSAGE_LENGTH',
    'MAX_NO_METRIC_LINK_TEXT_LENGTH',
    'MAX_NO_METRIC_LINK_URL_LENGTH',
    function (
        $q,
        config,
        chartDisplayUtils,
        colorAccessibilityService,
        $rootScope,
        userAnalytics,
        MAX_NO_METRIC_MESSAGE_LENGTH,
        MAX_NO_METRIC_LINK_TEXT_LENGTH,
        MAX_NO_METRIC_LINK_URL_LENGTH
    ) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                model: '=',
                sharedChartState: '=',
                sharedChartConfig: '=',
                disableUrl: '=',
                legendKeys: '=',
                sortOptions: '=',
                setOnChartLegend: '=',
            },
            link: function ($scope) {
                const disallowedKeys = chartDisplayUtils.getDisallowedKeys();
                const DEFAULT_OPTION = '';
                const METRIC_OPTION = 'metric';
                const VALUE_OPTION = 'value';

                // Get copies of the color arrays
                const heatMapColors = colorAccessibilityService
                    .get()
                    .getDefaultHeatmapColors()
                    .slice(0);
                const defaultColors = colorAccessibilityService
                    .get()
                    .getDefaultPlotColors()
                    .slice(0);

                $scope.showUnreleasedFeatures = config('superpower.unreleasedFeatures');
                $scope.onChartLegendOptions = {};
                $scope.secondaryVisualizationOptions = ['None', 'Sparkline', 'Radial', 'Linear'];
                $scope.COLOR_PALETTE = heatMapColors.concat(defaultColors);

                $scope.showLegendChange = showLegendChange;
                $scope.setHeatmapSortBy = setHeatmapSortBy;
                $scope.invertHeatmapSort = invertHeatmapSort;
                $scope.setColorBy = setColorBy;
                $scope.getGroupBySuggestions = getGroupBySuggestions;
                $scope.onColorRangeColorChange = onColorRangeColorChange;
                $scope.onSelectTimezone = onSelectTimezone;
                $scope.timezone = {
                    callback: $scope.onSelectTimezone,
                };
                $scope.maxNoMetricMessageLength = MAX_NO_METRIC_MESSAGE_LENGTH;
                $scope.maxNoMetricLinkTextLength = MAX_NO_METRIC_LINK_TEXT_LENGTH;
                $scope.maxNoMetricLinkUrlLength = MAX_NO_METRIC_LINK_URL_LENGTH;

                initialize();

                // return is to enforce that no further initialization occurs beyond
                // this point.
                return;

                function initialize() {
                    bindEventListeners();
                    bindScopeWatchers();
                    updateColorBy();

                    const chartConfig = getChartConfig();
                    // If timezone is explicit, then use that
                    if (chartConfig.timezone) {
                        $scope.timezone.query = chartConfig.timezone;
                    }

                    // if no hideMissingValues exists set it on the chart model
                    if (!chartConfig.hideMissingValues) {
                        chartConfig.hideMissingValues = false;
                    }
                }

                function showLegendChange() {
                    if ($scope.model.sf_uiModel.chartconfig.showLegend) {
                        userAnalytics.event('chart', 'on-chart-legend-toggle-on');
                    } else {
                        userAnalytics.event('chart', 'on-chart-legend-toggle-off');
                    }
                }

                function bindEventListeners() {
                    // For default time info tooltip
                    $scope.$on('setGlobalTimeRanges', function () {
                        $scope.defaultTimeOverridden = true;
                    });

                    $scope.$on('resetGlobalTimeRanges', function () {
                        $scope.defaultTimeOverridden = false;
                    });
                }

                function bindScopeWatchers() {
                    // For chart coloring info tooltip
                    $scope.$watch('model.sf_uiModel.allPlots', onAllPlotsChange, true);

                    $scope.$watch('model.sf_uiModel.chartconfig.colorByValue', function (newVal) {
                        if (newVal && !$scope.model.sf_uiModel.chartconfig.colorByValueScale) {
                            $scope.model.sf_uiModel.chartconfig.colorByValueScale = [];
                        }
                    });

                    $scope.$watchGroup(
                        [
                            'model.sf_uiModel.chartconfig.colorByMetric',
                            'model.sf_uiModel.chartconfig.colorByValue',
                        ],
                        onColorByGroupWatcherFire
                    );

                    $scope.$watchGroup(
                        [
                            'model.sf_uiModel.chartconfig.dimensionInLegend',
                            'model.sf_uiModel.chartconfig.colorByMetric',
                            'model.sf_uiModel.chartconfig.colorByValue',
                        ],
                        function (nval, oval) {
                            if (!angular.equals(nval, oval)) {
                                $scope.setOnChartLegend();
                            }
                        }
                    );

                    $scope.$watch('legendKeys', onLegendKeysChange);

                    $scope.$watchGroup(
                        [
                            'onChartLegendOptions.dimensionInLegend',
                            'model.sf_uiModel.chartconfig.showLegend',
                        ],
                        onLegendGroupWatcherFire
                    );

                    $scope.$watch(
                        'model.sf_uiModel.chartconfig.secondaryVisualization',
                        onSecondaryVisualizationChange
                    );

                    $scope.$watch(
                        'sharedChartConfig.heatmapPlotConfig.toolTip',
                        onHeatmapTooltipChange
                    );

                    $scope.$watchGroup(
                        [
                            'model.sf_uiModel.chartconfig.colorRange.min',
                            'model.sf_uiModel.chartconfig.colorRange.max',
                        ],
                        onColorRangeChange
                    );

                    // Watch for timezone changes in the chart options
                    // to set the timezone picker's current suggestion query
                    $scope.$watch('model.sf_uiModel.chartconfig.timezone', function (nval, oval) {
                        if (!angular.equals(nval, oval)) {
                            $scope.timezone.query = nval;
                        }
                    });
                }

                function onHeatmapTooltipChange() {
                    const heatmapPlotConfig = $scope.sharedChartConfig.heatmapPlotConfig;

                    if (heatmapPlotConfig && angular.isArray(heatmapPlotConfig.toolTip)) {
                        $scope.hasValueProperty = heatmapPlotConfig.toolTip.some(
                            (f) => f && f.property === 'value'
                        );
                    }
                }

                function updateColorBy() {
                    $scope.colorBy = DEFAULT_OPTION;
                    if ($scope.model.sf_uiModel.chartconfig.colorByMetric) {
                        $scope.colorBy = METRIC_OPTION;
                    } else if ($scope.model.sf_uiModel.chartconfig.colorByValue) {
                        $scope.colorBy = VALUE_OPTION;
                    }
                }

                function setHeatmapSortBy(newVal = {}) {
                    // the copying nonsense happens in order to trigger the $onChanges in
                    // the heatmap display; this change should probably get passed by events
                    const sortBy = angular.copy($scope.model.sf_uiModel.chartconfig.heatmapSortBy);

                    sortBy.value = newVal;
                    $scope.model.sf_uiModel.chartconfig.heatmapSortBy = sortBy;
                }

                function invertHeatmapSort() {
                    // the copying nonsense happens in order to trigger the $onChanges in
                    // the heatmap display; this change should probably get passed by events
                    const sortBy = angular.copy($scope.model.sf_uiModel.chartconfig.heatmapSortBy);

                    sortBy.asc = !sortBy.asc;
                    $scope.model.sf_uiModel.chartconfig.heatmapSortBy = sortBy;
                }

                // Set chart coloring options
                function setColorBy(colorBy) {
                    if (colorBy === METRIC_OPTION) {
                        $scope.colorBy = METRIC_OPTION;
                        $scope.model.sf_uiModel.chartconfig.colorByMetric = true;
                        $scope.model.sf_uiModel.chartconfig.colorByValue = false;
                    } else if (colorBy === VALUE_OPTION) {
                        $scope.colorBy = VALUE_OPTION;
                        $scope.model.sf_uiModel.chartconfig.colorByMetric = false;
                        $scope.model.sf_uiModel.chartconfig.colorByValue = true;
                    } else {
                        $scope.model.sf_uiModel.chartconfig.colorByMetric = false;
                        $scope.model.sf_uiModel.chartconfig.colorByValue = false;
                    }
                }

                function onLegendKeysChange(keys) {
                    if (keys && keys.length > 0) {
                        setLegendSortOptions(keys);
                        setDimensionKeys();
                        setDimensionInLegend();
                    }
                }

                function getGroupBySuggestions(p) {
                    let suggestions = [];

                    if ($scope.sharedChartConfig && $scope.sharedChartConfig.heatmapPlotConfig) {
                        suggestions = ($scope.sharedChartConfig.heatmapPlotConfig.toolTip || [])
                            .map((value) => value.property || '')
                            .filter(function (property) {
                                return (
                                    property !== 'value' &&
                                    (!p || property.match(p.replace(' ', '.*')))
                                );
                            });
                    }

                    return $q.when(suggestions);
                }

                function onColorByGroupWatcherFire(newVal) {
                    if (newVal[0] && !newVal[1]) {
                        $scope.colorBy = METRIC_OPTION;
                    } else if (!newVal[0] && newVal[1]) {
                        $scope.colorBy = VALUE_OPTION;
                    } else {
                        $scope.colorBy = DEFAULT_OPTION;
                    }
                }

                function onLegendGroupWatcherFire() {
                    if (
                        $scope.onChartLegendOptions.dimensionInLegend &&
                        $scope.onChartLegendOptions.dimensionInLegend.originalKey &&
                        $scope.model.sf_uiModel.chartconfig.showLegend
                    ) {
                        $scope.model.sf_uiModel.chartconfig.dimensionInLegend =
                            $scope.onChartLegendOptions.dimensionInLegend.originalKey;
                    }
                }

                function onSecondaryVisualizationChange(newVal) {
                    const chartConfig = $scope.model.sf_uiModel.chartconfig;

                    if (!newVal) {
                        // if previous chart had showSparkline, we need to translate that
                        // over to the secondary visualization for backward compatibility
                        chartConfig.secondaryVisualization = chartConfig.showSparkline
                            ? 'SPARKLINE'
                            : 'NONE';
                    } else if (isListOrSingleValue()) {
                        if (newVal === 'RADIAL' || newVal === 'LINEAR') {
                            $scope.setColorBy(VALUE_OPTION);

                            if (!chartConfig.colorByValueScale) {
                                chartConfig.colorByValueScale = [];
                            }
                        } else if (
                            !chartConfig.colorByValueScale ||
                            chartConfig.colorByValueScale.length === 0
                        ) {
                            $scope.setColorBy($scope.colorBy || DEFAULT_OPTION);
                        }
                    }

                    if (newVal) {
                        userAnalytics.event('chart', 'secondary-visualization-' + newVal);
                    }
                }

                function setLegendSortOptions(keys) {
                    $scope.sortOptions = [
                        { value: '', name: 'Auto' },
                        { value: '+value', name: 'Value (Ascending)' },
                        { value: '-value', name: 'Value (Descending)' },
                        ...createSortOptionsFromKeys(keys),
                    ];

                    const sortPreference = $scope.model.sf_uiModel.chartconfig.sortPreference;
                    const preferredOption = $scope.sortOptions.find(
                        (opt) => opt.value === sortPreference
                    );

                    if (preferredOption) {
                        preferredOption.selected = true;
                    } else {
                        if (getChartMode() !== 'table') {
                            $scope.model.sf_uiModel.chartconfig.sortPreference = '';
                            $scope.sortOptions[0].selected = true;
                        } else {
                            // for table chart the metric name is stored as sort preferences
                            // we need to turn to ('auto') as the sort preference and not override it.
                            $scope.sortOptions[0].selected = true;
                        }
                    }
                }

                function onColorRangeColorChange(newColor) {
                    const chartConfig = $scope.model.sf_uiModel.chartconfig;

                    switch (getChartMode()) {
                        case 'heatmap':
                            chartConfig.heatmapColorOverride = newColor;
                            break;
                    }
                }

                function onColorRangeChange() {
                    const chartConfig = getChartConfig();

                    // NOTE: this object is copied so the other components who are passed
                    // the colorRange know to update. While not optimal, this seems better
                    // than writing watching logic into components that may exist in display
                    // only contexts.
                    chartConfig.colorRange = angular.copy(chartConfig.colorRange);
                }

                function setDimensionKeys() {
                    const dimensionKeyArray = getDisplayableKeys($scope.legendKeys) || [];

                    $scope.dimensionKeys = dimensionKeyArray.map(function (key) {
                        return {
                            originalKey: key,
                            aliasedKey: chartDisplayUtils.getAliasedName(key),
                        };
                    });
                }

                function setDimensionInLegend() {
                    const defaultKey = getDefaultLegendKey();

                    $scope.onChartLegendOptions.dimensionInLegend = {
                        originalKey: defaultKey,
                        aliasedKey: chartDisplayUtils.getAliasedName(defaultKey),
                    };
                }

                function getDefaultLegendKey() {
                    const dimensionInLegend = $scope.model.sf_uiModel.chartconfig.dimensionInLegend;
                    let defaultKey;

                    if (dimensionInLegend) {
                        defaultKey = $scope.model.sf_uiModel.chartconfig.dimensionInLegend;
                    } else {
                        if ($scope.colorBy === METRIC_OPTION) {
                            defaultKey = 'sf_originatingMetric';
                        } else {
                            $rootScope.$broadcast(
                                'on chart legend get highest cardinality dimension',
                                $scope.onChartLegendOptions
                            );
                            defaultKey = $scope.onChartLegendOptions.highestCardinalityDimension;
                        }
                    }

                    return defaultKey;
                }

                function createSortOptionsFromKeys(keys) {
                    const sortOptions = [];

                    keys.filter((key) => disallowedKeys.indexOf(key) === -1).forEach((key) => {
                        const ascending = {
                            value: '+' + key,
                            name: chartDisplayUtils.getAliasedName(key) + ' (Ascending)',
                        };

                        const descending = {
                            value: '-' + key,
                            name: chartDisplayUtils.getAliasedName(key) + ' (Descending)',
                        };

                        sortOptions.push(ascending, descending);
                    });

                    return sortOptions;
                }

                function getDisplayableKeys(keyset) {
                    if (!keyset) {
                        return keyset;
                    }
                    return keyset.filter(function (key) {
                        return disallowedKeys.indexOf(key) === -1;
                    });
                }

                function isListOrSingleValue() {
                    return (
                        $scope.model.sf_uiModel.chartMode === 'list' ||
                        $scope.model.sf_uiModel.chartMode === 'single'
                    );
                }

                function plotHasColorOverride(plot) {
                    return plot.configuration && plot.configuration.colorOverride;
                }

                function plotHasVisualizationOverride(plot) {
                    return plot.configuration && plot.configuration.visualization;
                }

                function onAllPlotsChange() {
                    const allPlots = $scope.model.sf_uiModel.allPlots;

                    $scope.colorByOverridden = allPlots.some(plotHasColorOverride);
                    $scope.visualizationTypeOverridden = allPlots.some(
                        plotHasVisualizationOverride
                    );
                }

                function getChartMode() {
                    return $scope.model.sf_uiModel.chartMode;
                }

                function getChartConfig() {
                    return $scope.model.sf_uiModel.chartconfig;
                }

                function onSelectTimezone(toSave) {
                    const chartConfig = getChartConfig();
                    chartConfig.timezone = toSave;
                    userAnalytics.event('timezone-changed', 'chart-options-' + toSave);
                }
            },
        };
    },
]);
