// The intent of this module is that it holds the collection of components
// and services that we expect to be used throughout the app, as opposed to
// those components which we expect to be used solely in the main tracing
// module.

import './businessWorkflow/module.js';
import './serviceEndpoint/module.js';

import environmentResolver from './environmentResolver';
import traceSuggestionService from './traceSuggestionService';

const APM_METRIC_TYPE = {
    SERVICE_ERRORS: 'errors',
    SERVICE_LATENCY: 'latency',
    WORKFLOW_ERROR_RATE: 'workflow_errors',
    WORKFLOW_LATENCY: 'workflow_latency',
};

const APM_LATENCY_METRICS = new Set([
    // spans
    'spans.duration.ns.min',
    'spans.duration.ns.median',
    'spans.duration.ns.max',
    'spans.duration.ns.p90',
    'spans.duration.ns.p99',
    // traces
    'traces.duration.ns.min',
    'traces.duration.ns.median',
    'traces.duration.ns.max',
    'traces.duration.ns.p90',
    'traces.duration.ns.p99',
]);

const APM_VOLUME_METRICS = new Set(['spans.count', 'traces.count']);

angular
    .module('signalview.common.tracing', [
        'd3',

        'signalview.detector.signal',
        // sub-modules
        'signalview.common.tracing.businessWorkflow',
        'signalview.common.tracing.serviceEndpoint',
    ])

    // constants
    .constant('APM_METRIC_TYPE', APM_METRIC_TYPE)
    .constant('APM_LATENCY_METRICS', APM_LATENCY_METRICS)
    .constant('APM_VOLUME_METRICS', APM_VOLUME_METRICS)

    // services
    .service('environmentResolver', environmentResolver)
    .service('traceSuggestionService', traceSuggestionService);
