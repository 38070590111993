import { ngRoute } from '../../../../app/routing/ngRoute';

angular.module('signalview.chartTemplates').factory('chartTemplates', [
    function () {
        const templates = {};

        const service = {
            register: function (name, template) {
                templates[name] = template;
            },
            get: function (name) {
                return templates[name];
            },
            fromUrl: function () {
                let templateChart = null;
                const filters = {};
                if (ngRoute.params.filters) {
                    let filtersArray = ngRoute.params.filters;

                    if (angular.isString(filtersArray)) filtersArray = [filtersArray];

                    filtersArray.forEach(function (filter) {
                        // Split key:value
                        if (!filter) return;

                        const parts = filter.split(':');
                        filters[parts[0]] = parts.splice(1).join(':');
                    });
                }

                if (ngRoute.params.template) {
                    const template = service.get(ngRoute.params.template);
                    templateChart = template(filters);
                }

                return templateChart;
            },
        };
        return service;
    },
]);
