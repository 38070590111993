import templateUrl from './numberPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('numberPickerController', [
        '$scope',
        function ($scope) {
            $scope.valid = true;

            const $ctrl = this;
            $ctrl.$onInit = function () {
                $scope.localValue = $scope.$ctrl.model;
            };

            $scope.$watch('localValue', function (v) {
                $scope.valid = validate(v);
                $scope.$ctrl.onTypedUpdate({ value: v, validity: $scope.valid });
            });

            function validate(num) {
                return typeof num === 'number';
            }

            $scope.$ctrl.$onChanges = function (changes) {
                if (changes.model) {
                    $scope.localValue = changes.model.currentValue;
                }
            };
        },
    ])
    .component('numberPicker', {
        replace: true,
        controller: 'numberPickerController',
        bindings: {
            model: '<',
            definition: '<',
            validationError: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
