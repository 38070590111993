angular.module('signalview.orgIntegrations').service('integrationsPollingService', [
    '$interval',
    'createCallbackRegistry',
    'integrationsListService',
    function ($interval, createCallbackRegistry, integrationsListService) {
        let integrationPollPromise = null;
        const PERIOD_MS = 60 * 1000; // 1 min
        const cbRegistry = createCallbackRegistry('integrationsPolling');

        function startPolling() {
            if (integrationPollPromise) {
                $interval.cancel(integrationPollPromise);
            }
            integrationPollPromise = $interval(checkIsIntegrationConfigured, PERIOD_MS);
        }

        function stopPolling() {
            cbRegistry.empty();
            $interval.cancel(integrationPollPromise);
            integrationPollPromise = null;
        }

        function checkIsIntegrationConfigured() {
            integrationsListService.isIntegrationConfigured().then((integrationExists) => {
                cbRegistry.invokeAll(integrationExists);

                // Stop polling once an integration has been found
                if (integrationExists) {
                    stopPolling();
                }
            });
        }

        function registerListener(callback) {
            cbRegistry.register(callback);
        }

        function deregisterListener(callback) {
            cbRegistry.deregister(callback);
        }

        return {
            stopPolling,
            startPolling,
            registerListener,
            deregisterListener,
            checkIsIntegrationConfigured,
        };
    },
]);
