import { ngRoute } from '../../../app/routing/ngRoute';

angular
    .module('signalview.orgNotifications')

    .controller('OrgNotificationController', [
        '$scope',
        '$uibModalInstance',
        '$log',
        '$window',
        'currentUser',
        'orgSubscriptionService',
        'org',
        'admins',
        'security',
        'SALES_NUMBER',
        'ACCOUNT_SOURCES',
        'MARKETPLACE_PRODUCT_LINK',
        'organizationService',
        'userV2Service',
        'orgSwitchUrl',
        'PRODUCT_NAME',
        function (
            $scope,
            $uibModalInstance,
            $log,
            $window,
            currentUser,
            orgSubscriptionService,
            org,
            admins,
            security,
            SALES_NUMBER,
            ACCOUNT_SOURCES,
            MARKETPLACE_PRODUCT_LINK,
            organizationService,
            userV2Service,
            orgSwitchUrl,
            PRODUCT_NAME
        ) {
            $scope.PRODUCT_NAME = PRODUCT_NAME;
            $scope.orgSwitchUrl = orgSwitchUrl;
            $scope.org = org;
            $scope.processing = false;
            $scope.subject = '';
            $scope.admins = admins.join(',');
            $scope.SALES_NUMBER = SALES_NUMBER;
            $scope.isAmazon = org && org.accountSource === ACCOUNT_SOURCES.AWS_MARKET_PLACE;
            $scope.MARKETPLACE_PRODUCT_LINK = MARKETPLACE_PRODUCT_LINK;

            $scope.logout = function () {
                security.logout('/signin');
            };

            $scope.update = function () {
                $scope.processing = true;
                const subscriptionSvc = orgSubscriptionService.get($scope.org);

                return subscriptionSvc
                    .estimateSubscription()
                    .then(() => {
                        $scope.processing = false;
                        return organizationService.get();
                    })
                    .then((o) => {
                        if (o.accountStatus === 'ACTIVE') {
                            $log.info('Successfully updated subscription.', o);
                            $uibModalInstance.close();
                            if (org.accountStatus === 'DECOMMISSIONED') {
                                $window.alert(
                                    'Your subscription change may take up to 30 minutes to take effect.'
                                );
                                ngRoute.reload();
                            }
                        }
                    })
                    .catch((e) => {
                        $scope.processing = false;
                        $log.error('Failed updating subscription.', e);
                    });
            };

            currentUser.isAdmin().then(
                function (isAdmin) {
                    $scope.isAdmin = isAdmin;
                    if (!isAdmin) {
                        $scope.subject =
                            org.accountType === 'TRIAL'
                                ? `Request to upgrade ${PRODUCT_NAME} Trial to a paid subscription`
                                : `Request to renew ${PRODUCT_NAME} subscription`;
                    }
                },
                function (e) {
                    $scope.isAdmin = false;
                    $log.error('Failed finding admin status.', e);
                }
            );

            userV2Service.orgs().then((orgs) => {
                $scope.orgs = orgs.filter(function (o) {
                    return o.id !== org.id;
                });
            });
        },
    ]);
