angular
    .module('signalview.detector.notification')

    .service('notifyBlockService', [
        function () {
            return function (notification) {
                switch (notification.type) {
                    case 'pagerduty':
                        return notification.credentialName + ' (PagerDuty)';
                    case 'slack': {
                        return notification.credentialName
                            ? notification.channel + ' (' + notification.credentialName + ')'
                            : notification.channel;
                    }
                    case 'servicenow':
                        return notification.credentialName + ' (ServiceNow)';
                    case 'email':
                        return notification.email;
                    case 'team':
                        return `${notification.teamName || ''} (team)`;
                    case 'victorops':
                        return notification.credentialName + ' (Splunk On-Call)';
                    case 'webhook':
                        return notification.credentialName
                            ? notification.credentialName + ' (Webhook)'
                            : notification.url;
                    case 'xmatters':
                        return notification.credentialName + ' (xMatters)';
                    case 'bigpanda':
                        return notification.credentialName + ' (BigPanda)';
                    case 'office365':
                        return notification.credentialName + ' (Microsoft Teams)';
                    case 'opsgenie': {
                        const opsGenieMessage = notification.displayName || '(No Team)';
                        return notification.credentialName
                            ? opsGenieMessage + ' (' + notification.credentialName + ')'
                            : opsGenieMessage;
                    }
                    case 'amazoneventbridge':
                        return notification.credentialName + ' (Amazon EventBridge)';
                    case 'jira':
                        return notification.credentialName + ' (Jira)';
                    default:
                        return '';
                }
            };
        },
    ]);
