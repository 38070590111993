export function getEventChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Node Events',
        type: 'Event',
    };

    const filterString = filters ? `and (${filters})` : '';

    const publishLabelOptions = [
        {
            displayName: 'Workload Alerts',
            streamLabel: 'NODE_ALERTS',
            programText: `NODE_ALERTS = alerts(detector_name='*', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_node', '*') ${filterString}).publish(label='NODE_ALERTS')`,
        },
        {
            displayName: 'Workload Events',
            streamLabel: 'NODE_EVENTS',
            programText: `NODE_EVENTS = events(eventType='*', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_node', '*') ${filterString}).publish(label='NODE_EVENTS')`,
        },
    ];

    return { chartOptions, publishLabelOptions };
}
