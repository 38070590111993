import templateUrl from './loginForm.tpl.html';
import googleAuthTemplateUrl from './googleAuth.tpl.html';

export default [
    'security',
    'PRODUCT_NAME',
    function (security, PRODUCT_NAME) {
        return {
            restrict: 'E',
            templateUrl,
            replace: true,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.googleAuthTemplateUrl = googleAuthTemplateUrl;
                $scope.closeModal = function () {
                    if ($scope.isModal) {
                        security.cancelLogin();
                    }
                };
                security.loginShown = true;
                $scope.$on('$destroy', function () {
                    security.loginShown = false;
                });
            },
        };
    },
];
