export default [
    '$window',
    'GOOGLE_AUTH_ENABLED',
    '$http',
    'API_URL',
    'DOMAIN_ORGANIZATION_ID',
    '$log',
    function ($window, GOOGLE_AUTH_ENABLED, $http, API_URL, DOMAIN_ORGANIZATION_ID, $log) {
        const hasGoogle = GOOGLE_AUTH_ENABLED;

        function initError(e) {
            $window.alert('Something went wrong, please try again later.');
            $log.error('Failed google init', e);
        }

        // v2 init google auth
        function initGoogleAuth() {
            $http
                .get(
                    API_URL +
                        '/v2/session/google/init?orgId=' +
                        (DOMAIN_ORGANIZATION_ID ? DOMAIN_ORGANIZATION_ID : '')
                )
                .then(function (result) {
                    console.log(result);
                    $window.location.href = result.data.redirectUrl;
                }, initError);
        }

        function initAddGoogleDomain() {
            $http.get(API_URL + '/v2/session/google/add-domain').then(function (result) {
                console.log(result);
                $window.location.href = result.data.redirectUrl;
            }, initError);
        }

        return {
            hasGoogle: function () {
                return hasGoogle;
            },
            initGoogleAuth,
            initAddGoogleDomain,
        };
    },
];
