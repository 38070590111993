import templateUrl from './integrationInstructions.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .directive('integrationInstructions', [
        'integrationsListService',
        function (integrationsListService) {
            return {
                templateUrl,
                scope: {
                    integrationType: '=',
                    apiToken: '@',
                    org: '=',
                    integrationName: '=',
                    editable: '=',
                    confirmModal: '=',
                    dismiss: '=?',
                    formTracker: '=?',
                },
                link: {
                    pre: function ($scope) {
                        if (
                            ['shellscript', 'puppet', 'chef', 'sfxcollectd'].indexOf(
                                $scope.integrationName
                            ) !== -1
                        ) {
                            $scope.integrationName = 'collectd';
                            $scope.integrationType = 'plugin';
                        }
                        $scope.integration = integrationsListService.getIntegration(
                            $scope.integrationName
                        );
                        if (!$scope.integration) $scope.dismiss();
                    },
                },
            };
        },
    ]);
