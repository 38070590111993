import React, { Suspense, useEffect, useState } from 'react';
import { ngRoute } from '../../routing/ngRoute';
import { LazyModule } from '../../lazyModule';

import { API_URL } from '../../../legacy/common/consts.js';

function LogsTimeSeriesChartContainerWrapper({
    auth,
    chartModel,
    themeKey,
    chartHeight,
    urlOverridesService,
    setLinkUrl,
    filters,
    replaceOnlyFilters,
    setLogsFilters,
    setLogsIndexes,
    setLogsTimeRange,
    routeParameterService,
    relatedContentService,
    featureFlags,
    integrations,
    $scope,
    requestOverlayContainer,
    removeOverlayContainer,
}) {
    const [time, setTime] = useState(urlOverridesService.getGlobalTimePicker());

    useEffect(() => {
        const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
            ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
            () => {
                setTime(urlOverridesService.getGlobalTimePicker());
            }
        );
        return () => {
            // Clean up the subscription
            unregisterRouteWatchGroup();
        };
    });

    /**
     * Calling setSourceFilterOverrideList from within the React component didn't seem to be triggering the $digest cycle
     * see https://docs.angularjs.org/guide/$location#using-location-outside-of-the-scope-life-cycle
     * Forcing the digest to trigger by doing a manual $apply call after the filter is updated
     */
    const urlOverridesServiceWithApplyOnGetSourceFilterOverrideList = {
        ...urlOverridesService,
        setSourceFilterOverrideList: (newFilters) => {
            urlOverridesService.setSourceFilterOverrideList(newFilters);
            $scope.$apply();
        },
    };
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <LazyModule
                artifact={{
                    bundleJS: window.signalviewConfig?.logsChartJS,
                    globalPropertyName: 'logsChart',
                }}
                authToken={auth.authToken()}
                chartModel={chartModel}
                themeKey={themeKey}
                chartHeight={chartHeight}
                API_URL={API_URL}
                urlOverridesService={urlOverridesServiceWithApplyOnGetSourceFilterOverrideList}
                setLogsUrl={setLinkUrl}
                globalTime={time}
                filters={filters || urlOverridesService.getSourceFilterOverrideList()}
                replaceOnlyFilters={replaceOnlyFilters}
                relatedContentService={relatedContentService}
                history={ngRoute.history}
                setLogsFilters={setLogsFilters}
                setLogsIndexes={setLogsIndexes}
                setLogsTimeRange={setLogsTimeRange}
                integrations={integrations}
                featureEnabled={(key) => featureFlags[key] !== undefined && featureFlags[key]}
                createOverlayContainer={(attributes) =>
                    requestOverlayContainer(chartModel.sf_id, attributes)
                }
                removeOverlayContainer={(mode) => removeOverlayContainer(chartModel.sf_id, mode)}
            />
        </Suspense>
    );
}

LogsTimeSeriesChartContainerWrapper.displayName = 'LogsTimeSeriesChartContainer';
export default LogsTimeSeriesChartContainerWrapper;
