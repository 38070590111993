import templateUrl from './colorByValueConfiguration.tpl.html';

angular
    .module('signalview.chartbuilder')

    .component('colorByValueConfiguration', {
        templateUrl,
        bindings: {
            colorThresholds: '=', // needs two way binding
            boundedThresholdMode: '<?',
        },
        controller: [
            '$scope',
            '$timeout',
            'colorByValueService',
            'colorAccessibilityService',
            'userAnalytics',
            function (
                $scope,
                $timeout,
                colorByValueService,
                colorAccessibilityService,
                userAnalytics
            ) {
                const $ctrl = this;

                const accessibleColors = colorAccessibilityService.get();
                const defaultHeatmapColors = accessibleColors.getDefaultHeatmapColors();
                const accessibleHeatmapColors = accessibleColors.getHeatmapColors();
                const defaultPlotColors = accessibleColors.getDefaultPlotColors();
                const accessiblePlotColors = accessibleColors.getPlotColors();
                const defaultExtraColors = accessibleColors.getDefaultExtraOverrideColors();
                const accessibleExtraColors = accessibleColors.getExtraOverrideColors();

                const defaultColorScale = colorByValueService.getDefaultScale();
                const colorScaleWithPlotColors = [
                    ...defaultColorScale,
                    ...accessiblePlotColors,
                    ...accessibleExtraColors,
                ];

                $ctrl.errorInThresholds = true;
                $ctrl.MAX_NUM_THRESHOLDS = 5;
                $ctrl.defaultToAccessible = getDefaultToAccessibleColorMap();

                // lifecycle callbacks
                $ctrl.$onInit = $onInit;
                $ctrl.$onChanges = $onChanges;

                $ctrl.addColorByThreshold = addColorByThreshold;
                $ctrl.colorByKeydown = colorByKeydown;
                $ctrl.isThresholdValid = isThresholdValid;
                $ctrl.onColorDropDownToggled = onColorDropDownToggled;
                $ctrl.onColorPicked = onColorPicked;
                $ctrl.onThresholdEdited = onThresholdEdited;
                $ctrl.removeThreshold = removeThreshold;
                $ctrl.singleValInputBlur = singleValInputBlur;
                $ctrl.toggleGt = toggleGt;
                $ctrl.toggleShowMore = toggleShowMore;

                $scope.$watch('$ctrl.tempColorThresholds', copyToRule, true);

                function $onInit() {
                    if (!$ctrl.colorThresholds || !$ctrl.colorThresholds.length) {
                        $ctrl.tempColorThresholds = colorByValueService.getDefault();
                    } else {
                        $ctrl.tempColorThresholds = copyInputThresholds();

                        colorByValueService.convertColors($ctrl.tempColorThresholds);
                        copyToRule();
                    }

                    if ($ctrl.boundedThresholdMode) {
                        setMaxThresholdForBoundedMode();
                        setMinThresholdForBoundedMode();
                        copyToRule();
                    }
                }

                function $onChanges(changesObj) {
                    if (changesObj.boundedThresholdMode || changesObj.colorThresholds) {
                        $onInit();
                    }
                }

                function copyInputThresholds() {
                    return $ctrl.colorThresholds.map((ruleSegment) => {
                        const includeEquals = typeof ruleSegment.gte === 'number';
                        const showAllColors = !defaultColorScale.includes(ruleSegment.color);
                        let availableColors;

                        if (showAllColors) {
                            availableColors = colorScaleWithPlotColors;
                        } else {
                            availableColors = defaultColorScale;
                        }

                        return {
                            includeEquals,
                            showAllColors,
                            availableColors,
                            gt: includeEquals ? ruleSegment.gte : ruleSegment.gt,
                            color: ruleSegment.color,
                        };
                    });
                }

                function setMaxThresholdForBoundedMode() {
                    if (typeof $ctrl.maxThreshold !== 'number') {
                        // in bounded threshold mode, max threshold is the lte of the first element
                        let defaultMaxValue;
                        if ($ctrl.colorThresholds.length !== 0) {
                            defaultMaxValue = $ctrl.colorThresholds[0].lte;
                        }
                        if (defaultMaxValue === undefined) {
                            defaultMaxValue = getDefaultMaxThreshold();
                        }

                        $ctrl.maxThreshold = {
                            gt: defaultMaxValue,
                        };
                    }
                }

                function setMinThresholdForBoundedMode() {
                    if (typeof $ctrl.minThreshold !== 'number') {
                        // in bounded threshold mode, min threshold is the gt of the last element
                        let defaultMinValue;
                        if ($ctrl.colorThresholds.length !== 0) {
                            const lastIndex = $ctrl.colorThresholds.length - 1;
                            defaultMinValue = $ctrl.colorThresholds[lastIndex].gte;
                        }
                        if (defaultMinValue === undefined) {
                            defaultMinValue = getDefaultMinThreshold();
                        }

                        $ctrl.minThreshold = {
                            gt: defaultMinValue,
                        };
                    }
                }

                function onColorPicked() {
                    copyToRule();
                }

                function getDefaultMaxThreshold() {
                    if ($ctrl.colorThresholds.length > 0) {
                        // max = T0 + (T0 - T1) [if there's only one threshold, default the difference to be 10]
                        const [first, second] = $ctrl.tempColorThresholds;
                        const difference = first.gt - (second.gt || second.gte) || 10;

                        return first.gt + difference;
                    } else {
                        return 100;
                    }
                }

                function getDefaultMinThreshold() {
                    const numThresholds = $ctrl.colorThresholds.length;

                    if (numThresholds > 0) {
                        const last = $ctrl.colorThresholds[numThresholds - 1];
                        const secondToLast = $ctrl.colorThresholds[numThresholds - 2];
                        const difference =
                            secondToLast.lte - (secondToLast.gt || secondToLast.gte) || 10;

                        return last.lte - difference;
                    } else {
                        return 0;
                    }
                }

                function addColorByThreshold() {
                    if ($ctrl.tempColorThresholds) {
                        colorByValueService.addThreshold($ctrl.tempColorThresholds);
                    }
                }

                function removeThreshold(idx) {
                    colorByValueService.removeColorThreshold($ctrl.tempColorThresholds, idx);
                }

                function colorByKeydown(event, idx) {
                    if (event.keyCode === 13) {
                        // enter - commit edit
                        if (idx === $ctrl.tempColorThresholds.length - 2 && idx < 3) {
                            addColorByThreshold();
                        }
                        $timeout(function () {
                            angular
                                .element(
                                    '.color-threshold-picker-block:nth-child(' +
                                        (idx + 2) +
                                        ') .single-value-threshold-value input'
                                )
                                .focus();
                        }, 0);
                    } else if (event.keyCode === 27) {
                        // escape - cancel edit
                        $ctrl.tempColorThresholds[idx].gt = null;
                    }
                }

                function toggleGt(idx) {
                    const thresholdItem = $ctrl.tempColorThresholds[idx];
                    thresholdItem.includeEquals = !thresholdItem.includeEquals;
                    copyToRule();
                }

                function singleValInputBlur(idx) {
                    if ($ctrl.tempColorThresholds.length === 2) {
                        return;
                    }

                    if (
                        idx > -1 &&
                        $ctrl.tempColorThresholds[idx].gt !== 0 &&
                        !$ctrl.tempColorThresholds[idx].gt
                    ) {
                        removeThreshold(idx);
                        copyToRule();
                    }
                }

                function isThresholdValid(idx) {
                    return colorByValueService.isThresholdValid($ctrl.tempColorThresholds, idx);
                }

                function isMaxThresholdValid() {
                    if (!$ctrl.boundedThresholdMode) {
                        return true;
                    }

                    if (!$ctrl.maxThreshold || typeof $ctrl.maxThreshold.gt !== 'number') {
                        return false;
                    }

                    return (
                        $ctrl.tempColorThresholds[0].gt === undefined ||
                        $ctrl.maxThreshold.gt > $ctrl.tempColorThresholds[0].gt
                    );
                }

                function isMinThresholdValid() {
                    if (!$ctrl.boundedThresholdMode) {
                        return true;
                    }

                    if (
                        $ctrl.minThreshold === undefined ||
                        typeof $ctrl.minThreshold.gt !== 'number'
                    ) {
                        return false;
                    }

                    const previousIndex = $ctrl.tempColorThresholds.length - 2;

                    return (
                        $ctrl.tempColorThresholds[previousIndex].gt === undefined ||
                        $ctrl.tempColorThresholds[previousIndex].gt === null ||
                        ($ctrl.minThreshold !== undefined &&
                            $ctrl.minThreshold.gt < $ctrl.tempColorThresholds[previousIndex].gt)
                    );
                }

                // if an action requires a copy but does not affect the top level of the
                // tempColorThresholds array, copyToRule should be called directly.
                function copyToRule() {
                    $ctrl.errorInThresholds = !(
                        colorByValueService.thresholdsAreValid($ctrl.tempColorThresholds) &&
                        isMaxThresholdValid() &&
                        isMinThresholdValid()
                    );

                    if (!$ctrl.errorInThresholds) {
                        $ctrl.colorThresholds.splice(0);
                        for (let i = 0; i < $ctrl.tempColorThresholds.length; i++) {
                            if (
                                i + 1 === $ctrl.tempColorThresholds.length ||
                                $ctrl.tempColorThresholds[i].gt ||
                                $ctrl.tempColorThresholds[i].gt === 0
                            ) {
                                // if the second to last rule segment has no gt, then it is not
                                // yet finalized, and will not be copied to the final colorThresholds.
                                $ctrl.colorThresholds.push(getColorThreshold(i));
                            }
                        }
                    }
                }

                function onThresholdEdited(threshold) {
                    const previousThresholdError = $ctrl.errorInThresholds;
                    if (!$ctrl.errorInThresholds) {
                        clearThresholdError();
                    }

                    copyToRule();

                    if (!previousThresholdError && $ctrl.errorInThresholds) {
                        threshold.thresholdError = $ctrl.errorInThresholds;
                    } else if (!$ctrl.errorInThresholds) {
                        clearThresholdError();
                    }
                }

                function clearThresholdError() {
                    $ctrl.tempColorThresholds.forEach((tempThreshold) => {
                        tempThreshold.thresholdError = false;
                    });

                    if ($ctrl.maxThreshold) {
                        $ctrl.maxThreshold.thresholdError = false;
                    }

                    if ($ctrl.minThreshold) {
                        $ctrl.minThreshold.thresholdError = false;
                    }
                }

                function onColorDropDownToggled(open, idx) {
                    if (!open) {
                        const color = $ctrl.tempColorThresholds[idx].color;

                        if (defaultColorScale.includes(color)) {
                            $ctrl.tempColorThresholds[idx].showAllColors = false;
                            setAvailableColors(idx);
                        }

                        const paletteIndex = colorScaleWithPlotColors.indexOf(color);
                        userAnalytics.event(
                            'chart',
                            'color-by-value-threshold-color-picker-palette-index-' + paletteIndex
                        );
                    }
                }

                function toggleShowMore(idx) {
                    const threshold = $ctrl.tempColorThresholds[idx];

                    threshold.showAllColors = !threshold.showAllColors;
                    setAvailableColors(idx);
                }

                function setAvailableColors(idx) {
                    const tempColorThreshold = $ctrl.tempColorThresholds[idx];

                    if (tempColorThreshold.showAllColors) {
                        tempColorThreshold.availableColors = colorScaleWithPlotColors;
                    } else {
                        tempColorThreshold.availableColors = defaultColorScale;
                    }
                }

                function getDefaultToAccessibleColorMap() {
                    return Object.assign(
                        mapDefaultToAccessibleColors(defaultHeatmapColors, accessibleHeatmapColors),
                        mapDefaultToAccessibleColors(defaultPlotColors, accessiblePlotColors),
                        mapDefaultToAccessibleColors(defaultExtraColors, accessibleExtraColors)
                    );
                }

                function mapDefaultToAccessibleColors(defaultColors, accessibleColors) {
                    const map = {};

                    for (let i = 0; i < defaultColors.length; i++) {
                        map[defaultColors[i]] = accessibleColors[i];
                    }

                    return map;
                }

                function getColorThreshold(index) {
                    const currentIndexThreshold = $ctrl.tempColorThresholds[index];
                    const previousIndexThreshold = findLastDefinedThreshold(index - 1);
                    const colorThreshold = {
                        color: currentIndexThreshold.color,
                    };

                    if (index === 0) {
                        // first threshold
                        if (currentIndexThreshold.includeEquals) {
                            colorThreshold.gte = currentIndexThreshold.gt;
                        } else {
                            colorThreshold.gt = currentIndexThreshold.gt;
                        }

                        if ($ctrl.boundedThresholdMode) {
                            colorThreshold.lte = $ctrl.maxThreshold.gt;
                        }
                    } else if (index === $ctrl.tempColorThresholds.length - 1) {
                        // last threshold
                        if (previousIndexThreshold.includeEquals) {
                            colorThreshold.lt = previousIndexThreshold.gt;
                        } else {
                            colorThreshold.lte = previousIndexThreshold.gt;
                        }

                        if ($ctrl.boundedThresholdMode) {
                            colorThreshold.gte = $ctrl.minThreshold.gt;
                        }
                    } else {
                        // middle thresholds
                        if (currentIndexThreshold.includeEquals) {
                            colorThreshold.gte = currentIndexThreshold.gt;
                        } else {
                            colorThreshold.gt = currentIndexThreshold.gt;
                        }

                        if (previousIndexThreshold.includeEquals) {
                            colorThreshold.lt = previousIndexThreshold.gt;
                        } else {
                            colorThreshold.lte = previousIndexThreshold.gt;
                        }
                    }
                    return colorThreshold;
                }

                function findLastDefinedThreshold(index) {
                    for (let i = index; i >= 0; i--) {
                        if (typeof $ctrl.tempColorThresholds[i].gt === 'number') {
                            return $ctrl.tempColorThresholds[i];
                        }
                    }
                }
            },
        ],
    });
