angular.module('sfx.api.v2').factory('integrationCredentialCacheFactory', [
    'API_URL',
    '$http',
    '$q',
    '$log',
    function (API_URL, $http, $q, $log) {
        // if an hour has elapsed between calls to slack channels, ensure that the first call forces
        // successive calls to wait until the response arrives.  this will ensure that the server-side
        // channel cache gets time to populate without causing concurrent lookups, resulting in throttling.
        const ONE_HOUR_IN_MILLIS = 3600000;
        const DEBOUNCED_REASON = 'DEBOUNCED_WHILE_POPULATING_CACHE';
        const API_ENDPOINT = API_URL + '/v2/integration';

        return function (
            cacheIdentifier,
            endpointGenerator,
            cacheLifeInMillis = ONE_HOUR_IN_MILLIS
        ) {
            let queueFutureCalls = false;
            let searchRequestQueue = [];
            let cacheExpirationTime = 0;

            cacheLifeInMillis = cacheLifeInMillis || ONE_HOUR_IN_MILLIS;
            function search(id, query, offset = 0, limit = 20) {
                const url = API_ENDPOINT + endpointGenerator(id);
                if (queueFutureCalls) {
                    const deferred = $q.defer();
                    searchRequestQueue.push({
                        args: [id, query, offset, limit],
                        deferred: deferred,
                    });
                    return deferred.promise;
                }

                if (Date.now() > cacheExpirationTime) {
                    reset();
                    queueFutureCalls = true;
                }

                cacheExpirationTime = Date.now() + cacheLifeInMillis;

                return $http
                    .get(url, {
                        params: { query, offset, limit },
                    })
                    .then((response) => response.data)
                    .finally(() => {
                        if (queueFutureCalls) {
                            queueFutureCalls = false;
                            let numDebouncedCalls = 0;
                            searchRequestQueue.forEach(function (params, idx) {
                                // we will only issue the latest call ever requested to this service when under
                                // queueing restrictions.  this could theoretically cause a problem if this service
                                // were ever to be called from any non-exclusive context.
                                if (idx < searchRequestQueue.length - 1) {
                                    numDebouncedCalls++;
                                    params.deferred.reject(DEBOUNCED_REASON);
                                } else {
                                    params.deferred.resolve(search.apply(null, params.args));
                                }
                            });
                            if (numDebouncedCalls) {
                                $log.info(
                                    `Artificially rejected ${numDebouncedCalls} ${cacheIdentifier} request calls.`
                                );
                            }
                        }
                    });
            }

            function reset() {
                queueFutureCalls = false;
                cacheExpirationTime = 0;
                searchRequestQueue.forEach((req) => {
                    req.deferred.reject('Aborted');
                });
                searchRequestQueue = [];
            }

            return {
                search,
                reset,
                DEBOUNCED_REASON,
            };
        };
    },
]);
