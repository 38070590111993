export default {
    BASE_METRIC_TEMPLATES: [
        {
            template:
                "CONTAINER_READY = data('kubernetes.container_ready'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            template:
                "CPU_UTILIZATION = data('container_cpu_utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='rate', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            template:
                "CPU_CORES_KUBELET = data('machine_cpu_cores'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='average', extrapolation='last_value', maxExtrapolations=2).sum(by=['kubernetes_cluster', 'kubernetes_node'])",
        },
        {
            template:
                "CPU_CORES_MONITOR = data('cpu.num_processors'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='average', extrapolation='last_value', maxExtrapolations=2).sum(by=['kubernetes_cluster', 'kubernetes_node'])",
        },
        {
            template:
                "MEMORY_UTILIZATION = data('container_memory_usage_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='average')",
        },
        {
            template:
                "NODE_MEMORY_KUBELET = data('machine_memory_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='average').sum(by=['kubernetes_cluster', 'kubernetes_node'])",
        },
        {
            template:
                "NODE_MEMORY_MONITOR = data('memory.total'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='average').sum(by=['kubernetes_cluster', 'kubernetes_node'])",
        },
        {
            template: 'PRE_V5_3_SA_MEM = (100 * MEMORY_UTILIZATION / NODE_MEMORY_KUBELET)',
        },
        {
            template: 'POST_V5_3_SA_MEM = (100 * MEMORY_UTILIZATION / NODE_MEMORY_MONITOR)',
        },
        {
            template: 'PRE_V5_3_SA_CPU = (CPU_UTILIZATION / CPU_CORES_KUBELET)',
        },
        {
            template: 'POST_V5_3_SA_CPU = (CPU_UTILIZATION / CPU_CORES_MONITOR)',
        },
    ],
    METRICS: [
        {
            displayName: 'Containers',
            job: {
                varName: 'CONTAINER_READY',
            },
        },
        {
            displayName: 'CPUPercentUtilization',
            job: {
                template:
                    "CPU_PERCENT = union(PRE_V5_3_SA_CPU, POST_V5_3_SA_CPU).sum(by=['container_id'])",
                varName: 'CPU_PERCENT',
            },
        },
        {
            displayName: 'MemoryPercentUtilization',
            job: {
                varName: 'MEMORY_PERCENT_UTILIZATION',
                template:
                    "MEMORY_PERCENT_UTILIZATION = union(PRE_V5_3_SA_MEM, POST_V5_3_SA_MEM).sum(by=['container_id'])",
            },
        },
        {
            displayName: 'DaemonSet Containers',
            filters: [
                {
                    NOT: false,
                    property: 'kubernetes_workload',
                    propertyValue: 'DaemonSet',
                    type: 'property',
                },
            ],
            job: {
                varName: 'DAEMONSET_CONTAINER_READY',
                template:
                    "DAEMONSET_CONTAINER_READY = CONTAINER_READY.dimensions(aliases={'owner': 'daemonSet'})",
            },
        },
        {
            displayName: 'ReplicaSet Containers',
            filters: [
                {
                    NOT: false,
                    property: 'kubernetes_workload',
                    propertyValue: 'ReplicaSet',
                    type: 'property',
                },
            ],
            job: {
                varName: 'REPLICASET_CONTAINER_READY',
                template:
                    "REPLICASET_CONTAINER_READY=CONTAINER_READY.dimensions(aliases={'owner': 'replicaSet'})",
            },
        },
        {
            displayName: 'ReplicationController Containers',
            filters: [
                {
                    NOT: false,
                    property: 'kubernetes_workload',
                    propertyValue: 'ReplicationController',
                    type: 'property',
                },
            ],
            job: {
                varName: 'RC_CONTAINER_READY',
                template:
                    "RC_CONTAINER_READY=CONTAINER_READY.dimensions(aliases={'owner': 'replicaSet'})",
            },
        },
        {
            displayName: 'Deployment Containers',
            filters: [
                {
                    NOT: false,
                    property: 'kubernetes_workload',
                    propertyValue: 'Deployment',
                    type: 'property',
                },
            ],
            job: {
                varName: 'DEPLOYMENT_CONTAINER_READY',
                template:
                    "DEPLOYMENT_CONTAINER_READY = CONTAINER_READY.dimensions(aliases={'owner': 'deployment'})",
            },
        },
    ],
    COLUMNS: [
        {
            aggregate: null,
            displayName: 'Container Name',
            format: 'link',
            property: 'container_spec_name',
            propertiesToLinkOn: ['kubernetes_cluster', 'container_id'],
            panelName: 'container_id',
        },
        {
            aggregate: null,
            displayName: 'Pod Name',
            format: null,
            property: 'kubernetes_pod_name',
        },
        {
            aggregate: null,
            displayName: 'Workload Name',
            format: null,
            property: 'owner',
        },
        {
            aggregate: null,
            displayName: 'Namespace',
            format: null,
            property: 'kubernetes_namespace',
        },
        {
            aggregate: null,
            displayName: 'CPU% of Node Capacity',
            format: 'Percentage',
            property: 'CPUPercentUtilization',
        },
        {
            aggregate: null,
            displayName: 'Mem% of Node Capacity',
            format: 'Percentage',
            property: 'MemoryPercentUtilization',
        },
    ],
    UNIQUE_ID: '{{ container_spec_name }}:{{ container_id }}',
    ENTITY_TYPE: 'Container',
};
