'use strict';
import templateUrl from './chartSettingsModal.tpl.html';

export default [
    'sfxModal',
    'writepermissionsPermissionsChecker',
    'chartV2Service',
    '$q',
    function (sfxModal, writepermissionsPermissionsChecker, chartV2Service, $q) {
        function showModal(tabToSelect, params) {
            const chartId = params.chartObj.sf_id || params.chartObj.id;
            return $q
                .all({
                    permissions: writepermissionsPermissionsChecker.hasWritePermissions(
                        params.chartObj
                    ),
                    chart: chartId ? chartV2Service.get(chartId) : $q.when(params.chartObj),
                })
                .then(function (resp) {
                    params.chartObj = resp.chart;
                    return sfxModal.open({
                        templateUrl,
                        controller: [
                            '$scope',
                            'featureEnabled',
                            function ($scope, featureEnabled) {
                                $scope.params = params;
                                $scope.editable = resp.permissions;
                                $scope.tabToSelect = tabToSelect;
                                $scope.writePermissionsEnabled = featureEnabled('writepermissions');
                                $scope.hasWritePermission = params.hasWritePermission;

                                $scope.$on('dismiss modal', function () {
                                    $scope.$dismiss();
                                });

                                $scope.$on('close modal', function () {
                                    $scope.$close();
                                });
                            },
                        ],
                        windowClass: 'object-settings-modal',
                        backdrop: 'static',
                        keyboard: false,
                    }).result;
                });
        }

        return {
            info: showModal.bind(null, 'Chart info'),
            permissions: showModal.bind(null, 'Permissions'),
        };
    },
];
