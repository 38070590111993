import React from 'react';
import styled from 'styled-components';
import { getTheme } from '@splunk/themes';
import Chip from '@splunk/react-ui/Chip';
import { useLegacyThemeServiceKey } from '../../theme/ThemeProvider';

const StyledChip = styled(Chip)`
    height: 18px;
    border: none;
    width: 65px;
`;

export const ArchivedMetricChip = (): React.ReactElement => {
    const themeKey = useLegacyThemeServiceKey();
    const { accentColorWarning, neutral100 } = getTheme({ colorScheme: themeKey });

    return (
        <StyledChip backgroundColor={neutral100} foregroundColor={accentColorWarning}>
            Archived
        </StyledChip>
    );
};
