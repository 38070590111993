angular
    .module('signalview.organization')

    .service('ingestTokenDataService', [
        '$q',
        'userV2Service',
        'namedTokenService',
        '_',
        function ($q, userV2Service, namedTokenService, _) {
            return {
                getPaginatedTokens,
                getTokens,
            };

            function getPaginatedTokens(orgId, orderBy, searchTerm = '*', expiryLimit = '') {
                const pageLength = 20;
                const pages = [];
                let currentPage = 0;
                const buffer = { active: [], disabled: [] };
                let numTokens = 1;
                let offset = 0;
                let endOfActive = false;

                function fetchNext() {
                    let results;

                    if (buffer.active.length > pageLength || endOfActive) {
                        results = buffer.active.slice(0, pageLength);
                        buffer.active = buffer.active.splice(pageLength);

                        if (endOfActive && results.length < pageLength) {
                            const numDisabledNeeded = pageLength - results.length;

                            results = results.concat(buffer.disabled.slice(0, numDisabledNeeded));
                            buffer.disabled = buffer.disabled.splice(numDisabledNeeded);
                        }
                    }

                    if (results) {
                        return getUserCreators(results, orgId).then((users) => {
                            const mappedResults = results.map((token) => ({
                                token: token,
                                creator: users[token.creator] || {},
                                lastUpdatedBy: users[token.lastUpdatedBy] || {},
                            }));

                            pages.push(mappedResults);
                            currentPage = pages.length - 1;

                            const hasNextPage = !endOfActive || !!buffer.disabled.length;

                            return $q.when({
                                page: mappedResults,
                                next: () => hasNextPage && fetchNext(),
                                previous,
                                currentPage,
                                maxPage: Math.ceil(numTokens / pageLength),
                            });
                        });
                    }

                    if (offset < numTokens) {
                        const options = {
                            orderBy,
                            limit: pageLength * 3,
                            offset: offset || 0,
                        };

                        if (expiryLimit) {
                            return getExpiringTokenList(searchTerm, expiryLimit, options)
                                .then(storeResults)
                                .then(fetchNext);
                        }

                        return getTokens(searchTerm, orgId, options)
                            .then(storeResults)
                            .then(fetchNext);
                    } else {
                        endOfActive = true;
                        return fetchNext();
                    }
                }

                function getNextFromPages() {
                    currentPage++;
                    const page = pages[currentPage];

                    return $q.when({
                        page,
                        previous,
                        next: pages[currentPage + 1] ? getNextFromPages : fetchNext,
                        currentPage,
                        maxPage: Math.ceil(numTokens / pageLength),
                    });
                }

                function previous() {
                    currentPage--;

                    return {
                        next: getNextFromPages,
                        previous,
                        currentPage,
                        page: pages[currentPage],
                        maxPage: Math.ceil(numTokens / pageLength),
                    };
                }

                function storeResults(data) {
                    // always advance by at least one even if no results come back, else this can call infinitely
                    offset += data.results.length || 1;
                    numTokens = data.count;

                    const activeTokens = data.results.filter((t) => !t.disabled);
                    const disabledTokens = data.results.filter((t) => t.disabled);

                    buffer.active = buffer.active.concat(activeTokens);
                    buffer.disabled = buffer.disabled.concat(disabledTokens);
                }

                return fetchNext();
            }

            function getTokens(searchQuery, orgId, options = {}) {
                return namedTokenService.search(searchQuery, options);
            }

            function getExpiringTokenList(searchTerm, expiryLimit, options = {}) {
                return namedTokenService.fetchExpiringToken(searchTerm, expiryLimit, options);
            }

            function getUserCreators(tokens, orgId) {
                // Default org token will have createdBy === orgId. So we need to filter
                // that out to get just user ids. Removing duplicate userIds before api call
                const userIds = _.flatMap(tokens, (token) => [
                    token.creator,
                    token.lastUpdatedBy,
                ]).filter((value, index, arr) => {
                    return value !== null && value !== orgId && arr.indexOf(value) === index;
                });

                if (userIds.length) {
                    return userV2Service
                        .getOrgMembersForUserIds(userIds)
                        .then(function (orgMembers) {
                            return orgMembers.reduce(function (map, orgMember) {
                                map[orgMember.userId] =
                                    userV2Service.getUserBadgeDisplayObj(orgMember);
                                return map;
                            }, {});
                        });
                } else {
                    return $q.when({});
                }
            }
        },
    ]);
