import templateUrl from './detectorSignalflowEditor.tpl.html';

export default {
    templateUrl,
    bindings: {
        signalFlow: '<',
        onErrorsChange: '<',
        onKnownLabelsChange: '<',
        onSignalFlowChange: '<',
        onSignalFlowParsed: '<',
        model: '<',
    },
    controller: [
        '$scope',
        '$rootScope',
        '$timeout',
        '$http',
        '$log',
        '$q',
        'API_URL',
        'CHART_DISPLAY_EVENTS',
        'chartDisplayUtils',
        'aceUtils',
        function (
            $scope,
            $rootScope,
            $timeout,
            $http,
            $log,
            $q,
            API_URL,
            CHART_DISPLAY_EVENTS,
            chartDisplayUtils,
            aceUtils
        ) {
            const $ctrl = this;
            const ERROR_PARSING_INTERVAL = 200;
            const PARSING_INTERVAL = 1000;
            const JOB_RUN_INTERVAL = 2000;

            let aceInstance = null;
            let parsingDebounce = null;
            let jobRunDebounce = null;

            $scope.$on(CHART_DISPLAY_EVENTS.JOB_FEEDBACK_RECEIVED, updateAceAnnotations);

            function updateAceAnnotations(evt, messages) {
                setAceAnnotations(
                    aceUtils.messagesToAnnotations(
                        messages,
                        chartDisplayUtils.getSampleRate($ctrl.model)
                    )
                );
            }

            function setAceAnnotations(annotations) {
                if (aceInstance && aceInstance.getSession()) {
                    aceInstance.getSession().setAnnotations(annotations);
                } else {
                    $log.warn('Tried to set annotations before ace was ready!');
                }
            }

            $ctrl.aceOption = {
                mode: 'python',
                onLoad: function (ace) {
                    aceInstance = ace;
                    aceUtils.updateTheme(aceInstance);
                },
            };

            $scope.$watch('$ctrl.signalFlow', function () {
                if ($ctrl.onSignalFlowChange) {
                    $ctrl.onSignalFlowChange($ctrl.signalFlow);
                }
                debouncedParsing();
            });

            // This watcher listens to when sf_uiModel.allPlots is replaced as whole
            // and won't reflect when it is partially updated.
            $scope.$watch('$ctrl.model.sf_uiModel.allPlots', function () {
                $ctrl.editableLabelOptions = ($ctrl.model.sf_uiModel.allPlots || [])
                    .filter((plot) => !plot.transient)
                    .filter((plot) => plot.type === 'plot')
                    .map(function (plot) {
                        return {
                            _originalLabel: plot._originalLabel,
                            name: plot.name,
                            type: 'plot',
                            configuration: plot.configuration,
                        };
                    });
            });

            $scope.$watch(
                '$ctrl.editableLabelOptions',
                function () {
                    ($ctrl.editableLabelOptions || []).forEach(function (sourceplot) {
                        $ctrl.model.sf_uiModel.allPlots.forEach(function (targetplot) {
                            if (sourceplot._originalLabel === targetplot._originalLabel) {
                                targetplot.name = sourceplot.name;
                            }
                        });
                    });
                },
                true
            );

            function debouncedParsing() {
                $timeout.cancel(parsingDebounce);
                parsingDebounce = $timeout(
                    parseText,
                    $ctrl.errors ? ERROR_PARSING_INTERVAL : PARSING_INTERVAL
                );
            }

            function debouncedJobRuns(sflow) {
                $timeout.cancel(jobRunDebounce);
                jobRunDebounce = $timeout(function () {
                    $ctrl.model.sf_viewProgramText = sflow;
                    if ($ctrl.onSignalFlowParsed) {
                        $ctrl.onSignalFlowParsed();
                    }
                }, JOB_RUN_INTERVAL);
            }

            function parseText() {
                const sflow = $ctrl.signalFlow;
                setErrors(false);
                setAceAnnotations([]);
                if (sflow.length > 0) {
                    return $http({
                        method: 'POST',
                        url: API_URL + '/v2/signalflow/_/getProgramInfo',
                        data: sflow,
                        headers: {
                            'Content-Type': 'text/plain',
                        },
                    }).then(
                        function (resp) {
                            debouncedJobRuns(sflow);
                            if ($ctrl.onKnownLabelsChange) {
                                $ctrl.onKnownLabelsChange(resp.data.detectLabels);
                            }
                            $scope.$emit('v2 signalflow updated', resp.data);
                        },
                        function (resp) {
                            setAceAnnotations(aceUtils.analyticsErrorToAnnotations(resp));
                            setErrors(true);
                        }
                    );
                } else {
                    return $q.when(null);
                }
            }

            function setErrors(errors) {
                $ctrl.errors = errors;
                if ($ctrl.onErrorsChange) {
                    $ctrl.onErrorsChange(errors);
                }
            }

            debouncedParsing();

            $rootScope.$on('theme update', function () {
                aceUtils.updateTheme(aceInstance);
            });
        },
    ],
};
