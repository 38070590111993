angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    'plotUtils',
    function (chartTemplates, Chart, blockService, plotUtils) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Network Errors, 1-min Sum')
                .mode('list')
                .type('line')
                .maxDecimalPlaces(3)
                .updateInterval(5000);

            const letters = ['if_errors.rx', 'if_errors.tx'].map(function (metric) {
                const plot = chart
                    .plot()
                    .metric(metric)
                    .visible(false)
                    .propertyFilter('plugin_instance', 'eth*')
                    .propertyFilter('plugin', 'interface')
                    .propertyFilters(filters);

                return plotUtils.getLetterFromUniqueKey(plot.uniqueKey());
            });

            chart
                .plot()
                .macro(letters.join('+'))
                .name('interface errors')
                .addManipulation(blockService.sum.asTransformation(1, 'm'))
                .addManipulation(blockService.sum.asAggregation());

            chart
                .plot()
                .metric('protocol_counter.RetransSegs')
                .name('retransmits')
                .propertyFilter('plugin', 'protocols')
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asTransformation(1, 'm'))
                .addManipulation(blockService.sum.asAggregation());

            return chart;
        }

        chartTemplates.register('collectdNetworkErrors', createChart);
    },
]);
