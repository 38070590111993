import { useCallback, useState } from 'react';
import { API_URL } from '../../../../common/consts';

export const useFetchOrgInfo = (): {
    orgInfo: {
        ingestQuota: string;
        indexQuota: string;
        termStartDate: string;
        termEndDate: string;
    } | null;
    isLoading: boolean | undefined;
    fetchOrgInfo: any;
} => {
    const endpointUrl = API_URL + '/v2/organization/log-observer-reports/usage_details';
    const [orgInfo, setOrgInfo] = useState<null | {
        ingestQuota: string;
        indexQuota: string;
        termStartDate: string;
        termEndDate: string;
    }>(null);
    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
    const fetchOrgInfo = useCallback(() => {
        fetch(endpointUrl, {
            headers: {
                'X-SF-TOKEN': localStorage['X-SF-TOKEN'],
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setOrgInfo(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [endpointUrl]);
    return { orgInfo, isLoading, fetchOrgInfo };
};
