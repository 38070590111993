export const ChartDisplayOverlayRenderer = [
    'chartDisplayCanvasRenderer',
    'detectorPriorityService',
    'alertTypeService',
    '$log',
    '_',
    function (chartDisplayCanvasRenderer, detectorPriorityService, alertTypeService, $log, _) {
        function containsDetectorEventName(drawEventLines, eventName) {
            // for detector events, the actual eventName is prepended with the detectorId
            // but the keys in drawEventLines (which is the event overlay name) do not include
            // the detectorId. So to match up the eventName to the drawEvent property,
            // we need to do a partial match
            // eventName is detectorId__eventType notificationId_detectorId__eventType
            // key is eventType
            return _.some(
                Object.keys(drawEventLines),
                (key) => eventName.endsWith('__' + key) && drawEventLines[key]
            );
        }

        // eventName is detectorId__eventType or notificationId_detectorId__eventType
        function conatainsDetectorId(drawEventLinesById, eventName) {
            return _.some(
                Object.keys(drawEventLinesById),
                (detectorId) =>
                    eventName.includes(detectorId + '__') && drawEventLinesById[detectorId]
            );
        }

        function getSeverestEventType(
            buckets,
            labelToColor,
            customEventColors,
            overlayParams,
            chartOptionsDrawEventLines
        ) {
            const MAX_SEVERITY = detectorPriorityService.getSeverityByDisplay('Critical');
            const severest = { category: 'USER_DEFINED' };
            let highestSeverity = -1;

            const eventWithDrawEventLine = _.find(buckets.aggregations, (category) => {
                const eventName = category.name;
                const drawEventLines = overlayParams.eventLines || {};
                const drawEventLinesById = overlayParams.eventLinesById || {};
                return (
                    chartOptionsDrawEventLines ||
                    (drawEventLines.hasOwnProperty(eventName) && drawEventLines[eventName]) ||
                    containsDetectorEventName(drawEventLines, eventName) ||
                    conatainsDetectorId(drawEventLinesById, eventName)
                );
            });

            if (eventWithDrawEventLine) {
                severest.drawEventLines = true;
                severest.eventLineColor = getCustomColor(
                    eventWithDrawEventLine.name,
                    customEventColors || {}
                );
            }

            const compositions = buckets._composition;
            const compositionNames = Object.keys(compositions);

            for (let i = 0; i < compositionNames.length; i++) {
                const label = compositionNames[i];
                const histogram = compositions[label];
                for (let j = 0; j < histogram.aggregations.length; j++) {
                    const category = histogram.aggregations[j];
                    const eventName = category.name;
                    if (category.aggregations && category.aggregations.length) {
                        // when there're sub-aggregation, it's alerts
                        severest.category = 'ALERT';
                        for (let j = 0; j < category.aggregations.length; j++) {
                            const priority = category.aggregations[j];
                            let severity = parseInt(priority.name, 10);
                            for (let k = 0; k < priority.aggregations.length; k++) {
                                const state = priority.aggregations[k];
                                if (!state.count) {
                                    continue;
                                }
                                const cleared = alertTypeService.isClearingEvent(state.name);
                                // Weigh triggered events more than cleared
                                if (!cleared) {
                                    severity += MAX_SEVERITY;
                                }
                                if (severity > highestSeverity) {
                                    severest.cleared = cleared;
                                    highestSeverity = severity;
                                    if (highestSeverity === 2 * MAX_SEVERITY) {
                                        // Can't go any higher than triggered critical event
                                        severest.color =
                                            detectorPriorityService.getColorBySeverity(
                                                MAX_SEVERITY
                                            );
                                        return severest;
                                    }
                                }
                            }
                        }
                    } else if (highestSeverity === -1) {
                        if (label in labelToColor) {
                            severest.color = labelToColor[label];
                        } else {
                            severest.color = getCustomColor(eventName, customEventColors || {});
                        }
                    }
                }
            }

            if (highestSeverity !== -1) {
                const originalSeverity =
                    highestSeverity === MAX_SEVERITY
                        ? MAX_SEVERITY
                        : highestSeverity % MAX_SEVERITY;
                severest.color = detectorPriorityService.getColorBySeverity(originalSeverity);
            }
            return severest;
        }

        function getCustomColor(eventName, customEventColors) {
            let customColor = '';
            angular.forEach(customEventColors, (value, key) => {
                if (key === eventName) {
                    customColor = value;
                } else if (key.indexOf('*') !== -1) {
                    if (eventName && eventName.match(key.replace('*', '.*'))) {
                        customColor = value;
                    }
                }
            });
            return customColor;
        }

        function getWatermarkOptions(plotLineConfig) {
            const high = plotLineConfig.hasOwnProperty('high') ? plotLineConfig.high : null;
            const highLabel = plotLineConfig.highLabel || '';
            const low = plotLineConfig.hasOwnProperty('low') ? plotLineConfig.low : null;
            const lowLabel = plotLineConfig.lowLabel || '';

            return { high, highLabel, low, lowLabel };
        }

        function ChartDisplayOverlayRenderer(dygraphInstance) {
            this.dyGraph = dygraphInstance;
            this.ctx = dygraphInstance.canvas_ctx_;
            this.pointDrawer = chartDisplayCanvasRenderer.getPointOverlayDrawer(
                this.ctx,
                this.dyGraph
            );
            this.canvasExtents = {};
            this.recomputeCanvasExtents();
        }

        ChartDisplayOverlayRenderer.prototype.recomputeCanvasExtents = function () {
            let yRange;
            let xRange;
            try {
                yRange = this.dyGraph.yAxisRange();
                xRange = this.dyGraph.xAxisRange();
            } catch (e) {
                $log.warn(
                    'Could not recompute canvas extents beacuse no axis exists to compute against!'
                );
                return;
            }

            this.canvasExtents = {
                yTop: this.dyGraph.toDomYCoord(yRange[1]),
                yBottom: this.dyGraph.toDomYCoord(yRange[0]),
                xBottom: this.dyGraph.toDomXCoord(xRange[1]),
                xTop: this.dyGraph.toDomXCoord(xRange[0]),
            };
        };

        ChartDisplayOverlayRenderer.prototype.getCanvasExtents = function () {
            return this.canvasExtents;
        };

        ChartDisplayOverlayRenderer.prototype.drawEvents = function (
            histogram,
            labelToColor,
            chartOptionsDrawEventLines,
            currentBucket,
            customEventColors,
            eventOverlayParams
        ) {
            const self = this;
            customEventColors = customEventColors || {};
            eventOverlayParams = eventOverlayParams || {};
            self.dyGraph.canvas_ctx_.clearRect(
                this.canvasExtents.xTop - 100,
                this.canvasExtents.yBottom,
                this.canvasExtents.xBottom + 100,
                this.canvasExtents.yBottom + 50
            );
            angular.forEach(histogram, function (eventBucket) {
                if (
                    !eventBucket.aggregations ||
                    !eventBucket.aggregations.length ||
                    !eventBucket.count
                ) {
                    // skip if there's no data, no aggregation or no event count matched
                    return;
                }
                const severest = getSeverestEventType(
                    eventBucket,
                    labelToColor,
                    customEventColors,
                    eventOverlayParams,
                    chartOptionsDrawEventLines
                );
                const outlineOnly = severest.category === 'USER_DEFINED' || severest.cleared;
                const isCurrentBucket =
                    currentBucket && currentBucket.timeStamp === eventBucket.timeStamp;
                // Place top vertex of event marker at the start of the eventBucket's
                // time range, since we are start time inclusive, end time exclusive,
                // and to prevent it looking like the alert triggered late
                chartDisplayCanvasRenderer.drawEventMarker(
                    eventBucket.timeStamp,
                    severest.category,
                    severest.color,
                    outlineOnly,
                    self.ctx,
                    severest.drawEventLines,
                    severest.eventLineColor,
                    self.canvasExtents,
                    self.dyGraph,
                    isCurrentBucket
                );
            });
        };

        ChartDisplayOverlayRenderer.prototype.drawWatermarks = function (yConfigs) {
            const axes = this.dyGraph.getOption('axes');
            if (!axes.y) {
                return;
            }

            const offsets = this.getWatermarkOffsets(yConfigs);

            (yConfigs || []).forEach((yConfig, idx) => {
                const { high, highLabel, low, lowLabel } = getWatermarkOptions(yConfig.plotlines);

                if (high !== null) {
                    chartDisplayCanvasRenderer.drawLine(high, idx, this.ctx, this.dyGraph);
                    if (highLabel) {
                        chartDisplayCanvasRenderer.drawLabel(
                            this.ctx,
                            this.dyGraph,
                            high,
                            idx,
                            highLabel,
                            offsets
                        );
                    }
                }

                if (low !== null) {
                    chartDisplayCanvasRenderer.drawLine(low, idx, this.ctx, this.dyGraph);
                    if (lowLabel) {
                        chartDisplayCanvasRenderer.drawLabel(
                            this.ctx,
                            this.dyGraph,
                            low,
                            idx,
                            lowLabel,
                            offsets
                        );
                    }
                }
            });
        };

        ChartDisplayOverlayRenderer.prototype.getWatermarkOffsets = function (yConfigs) {
            const offsets = [];

            (yConfigs || []).forEach((yConfig, yAxis) => {
                const { high, low } = getWatermarkOptions(yConfig.plotlines);

                [low, high].forEach((value) => {
                    offsets.push(
                        chartDisplayCanvasRenderer.getDomYOffset(
                            this.ctx,
                            this.dyGraph,
                            yAxis,
                            value
                        )
                    );
                });
            });

            return offsets;
        };

        ChartDisplayOverlayRenderer.prototype.renderDragOverlay = function (
            dragStartTime,
            intermediateDragTime
        ) {
            // get start timestamp as the smaller of the time at drag start and the dragged to timestamp
            // then convert those timestamps into offsets using the dygraph utility
            // after that, get the x value of the response(first array element)
            // this allows us to draw a rectangle representing the dragged area.
            const startX = this.dyGraph.toDomCoords(
                Math.min(dragStartTime, intermediateDragTime),
                0
            )[0];
            const endX = this.dyGraph.toDomCoords(
                Math.max(dragStartTime, intermediateDragTime),
                0
            )[0];
            const ctx = this.ctx;
            ctx.beginPath();
            ctx.rect(startX, this.canvasExtents.yTop, endX - startX, this.canvasExtents.yBottom);
            ctx.fillStyle = 'rgba(220,220,220,0.4)';
            ctx.fill();
            ctx.closePath();
        };

        ChartDisplayOverlayRenderer.prototype.drawPoint = function (
            point,
            color,
            size,
            outlineColor
        ) {
            if (point === null) {
                this.pointDrawer.drawPoint(null, null, null, null);
            } else {
                this.pointDrawer.drawPoint(point, color, size, outlineColor);
            }
        };

        ChartDisplayOverlayRenderer.prototype.drawVerticalLines = function (verticalLines) {
            const self = this;
            angular.forEach(verticalLines, function (timestamp) {
                chartDisplayCanvasRenderer.drawVerticalLine(
                    timestamp,
                    self.ctx,
                    self.canvasExtents,
                    self.dyGraph
                );
            });
        };

        ChartDisplayOverlayRenderer.prototype.drawEventBarBoxes = function (boxes) {
            const self = this;
            angular.forEach(boxes, function (box) {
                chartDisplayCanvasRenderer.drawEventBarBox(
                    box,
                    self.ctx,
                    self.canvasExtents,
                    self.dyGraph
                );
            });
        };

        return ChartDisplayOverlayRenderer;
    },
];
