import templateUrl from './webhooksIntegration.html';

angular
    .module('sfx.ui')

    .directive('webhooksIntegration', [
        'notificationService',
        function (notificationService) {
            return {
                restrict: 'E',
                templateUrl,
                scope: true,
                require: '^sfxIntegration',
                link: function ($scope) {
                    $scope.headers = getHeadersFromModel();

                    $scope.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                        $scope.credential.lastUpdated
                    );

                    $scope.$on('reset', function reset() {
                        $scope.headers = getHeadersFromModel();
                        // update modified time as well
                        $scope.lastModifiedTs =
                            notificationService.formatNotificationEventTimestamp(
                                $scope.credential.lastUpdated
                            );
                    });

                    $scope.addHeader = function () {
                        $scope.headers.push({ key: '', value: '' });
                    };

                    $scope.removeHeader = function (idx) {
                        $scope.headers = $scope.headers.filter((pair, index) => index !== idx);
                        $scope.updateHeaders();
                    };

                    $scope.updateHeaders = function () {
                        const newHeaders = {};
                        $scope.headers.forEach((pair) => {
                            if (pair.key && pair.value) {
                                newHeaders[pair.key] = pair.value;
                            }
                        });
                        $scope.credential.headers = newHeaders;
                    };

                    function getHeadersFromModel() {
                        const originalHeaders = [];
                        const savedHeaders = $scope.credential.headers;
                        if (savedHeaders) {
                            angular.forEach(savedHeaders, (value, key) => {
                                originalHeaders.push({ key, value });
                            });
                        } else {
                            originalHeaders.push({ key: '', value: '' });
                        }
                        return originalHeaders;
                    }
                },
            };
        },
    ]);
