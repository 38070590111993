import { AngularInjector } from '../../../../../common/AngularUtils';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { getReservedDimensionKeys } from '@splunk/olly-utilities/lib/Timeseries';

ChartService.useInstance = () => AngularInjector.useInjectedClass(ChartService);

ChartService.$inject = [
    'BaseDataProvider',
    'WindowedDataRepository',
    'chartDisplayUtils',
    'timepickerUtils',
    'timeZoneService',
    'analyticsService',
    'sortOptionService',
    'themeService',
    'routeParameterService',
    'urlOverridesService',
];

export default function ChartService(
    BaseDataProvider,
    WindowedDataRepository,
    chartDisplayUtils,
    timepickerUtils,
    timeZoneService,
    analyticsService,
    sortOptionService,
    themeService,
    routeParameterService,
    urlOverridesService
) {
    this.getProvider = (callback) => new BaseDataProvider(callback);

    this.getRepository = () => new WindowedDataRepository();

    this.formatTime = (timeStamp, format) => {
        return timeZoneService.dateWithTimeZone(timeStamp, format);
    };

    this.updateGlobalTimeRange = (chartModel) => {
        chartDisplayUtils.updateGlobalTimeRange(
            chartModel,
            timepickerUtils.getChartConfigURLTimeParameters()
        );
    };

    this.getJobRangeParametersFromConfig = (chartConfig, chartMode) => {
        return chartDisplayUtils.getJobRangeParametersFromConfig(chartConfig, chartMode);
    };

    this.getSeriesMetadata = (chartModel, metadata) =>
        chartDisplayUtils.getSeriesMetadata(chartModel, metadata);

    this.resolveSeriesName = (
        metadata,
        chartModel,
        skipMetric,
        skipOriginalMetricInAutoMode,
        isOrginallyV2,
        skipOriginalMetric
    ) =>
        chartDisplayUtils.resolveSeriesName(
            metadata,
            chartModel,
            skipMetric,
            skipOriginalMetricInAutoMode,
            isOrginallyV2,
            skipOriginalMetric
        );

    this.getPlotObject = (metadata, chartModel, isOriginallyV2) =>
        chartDisplayUtils.getPlotObject(metadata, chartModel, isOriginallyV2);

    this.safeLookup = (plot, config) => safeLookup(plot, config);

    this.getLegendKeys = (metadataMap, tsidToPlot) =>
        chartDisplayUtils.getLegendKeys(metadataMap, tsidToPlot);

    this.getReservedDimensionKeys = () => getReservedDimensionKeys();

    this.syntheticIdFilter = (val, plotObject) =>
        analyticsService.syntheticIdFilter(val, plotObject);

    this.getSortOption = (col, direction, disableMetadata) =>
        sortOptionService.getSortOption(col, direction, disableMetadata);

    this.sortTimesliceValues = (obj, sortOptions) =>
        sortOptionService.sortTimesliceValues(obj, sortOptions);

    this.registerRouteWatchGroup = (callback) =>
        routeParameterService.registerRouteWatchGroup(
            ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
            callback
        );

    this.sortTimesliceValues = (obj, sortOptions, secondaryAsc) =>
        sortOptionService.sortTimesliceValues(obj, sortOptions, secondaryAsc);

    this.getColorScheme = () => themeService.getColorScheme();

    this.isColumnVisible = (colKey, legendCols) => {
        const columnFound = legendCols.find((elem) => elem.property === colKey);
        if (!columnFound) {
            return true;
        }
        return columnFound.enabled;
    };

    this.resolveDimensions = (metadata, chartModel, showMetric, isOriginallyV2) =>
        chartDisplayUtils.resolveDimensions(
            metadata,
            chartModel,
            showMetric,
            isOriginallyV2,
            null,
            null,
            true
        );

    this.getSourceFilterOverrideList = () => urlOverridesService.getSourceFilterOverrideList();

    this.setSourceFilterOverrideList = (sourceOverride) =>
        urlOverridesService.setSourceFilterOverrideList(sourceOverride);
}
