import SignUpInvite from './SignUpInvite';
import PasswordReset from './PasswordReset';
import PasswordRequest from './PasswordRequest';

angular
    .module('signalview.passwordReset', ['signalboost', 'sfx.security', 'signalview.legal'])
    .component('signUpInvite', SignUpInvite)
    .component('passwordReset', PasswordReset)
    .component('passwordRequest', PasswordRequest)
    .constant(
        'PASSWORD_ERROR_TEXT',
        'Your password must be between 8 and 32 characters, contain at least one capital letter, one number, and one symbol.'
    )
    .constant(
        'PASSWORD_VALIDATION_REGEX',
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\da-zA-Z]).{8,32}$'
    )
    .constant('FULL_NAME_LENGTH_ERROR_TEXT', 'Full name cannot exceed 100 characters')
    .constant(
        'FULL_NAME_REGEX_ERROR_TEXT',
        'User name can only contain alphanumeric characters, spaces, apostrophes, and hyphens.'
    )
    .constant('FULL_NAME_MAX_LENGTH', 100)
    .constant('FULL_NAME_VALIDATION_REGEX', "^[\\w '-]*$");
