angular.module('signalview.heatmap').factory('valueRenderer', [
    'valueFormatter',
    'moment',
    function (valueFormatter, moment) {
        const PRIORITY_TO_SEVERITY = {
            0: 'Ok',
            20: 'Info',
            40: 'Warning',
            60: 'Minor',
            80: 'Major',
            100: 'Critical',
        };

        return function (value, format) {
            switch (format) {
                case 'Number':
                    return valueFormatter.formatValue(value, 2, false);
                case 'Bytes': // used to parse bytes using Number scale
                    value = parseFloat(value, 10);
                    return valueFormatter.formatValue(value, 2, true);
                case 'Kilobytes':
                    value = parseFloat(value, 10) * 1024;
                    return valueFormatter.formatValue(value, 2, true);
                case 'Seconds':
                case 'Age':
                    return moment.duration(value, 'seconds').humanize();
                case 'Percentage':
                    return valueFormatter.formatValue(value, 2, false) + '%';
                case 'ScaleBytes': // used to parse bytes using IEC byte scaling
                    value = parseFloat(value, 10);
                    return valueFormatter.formatScalingUnit(value, 'Byte', 2);
                case 'ScaleKilobytes':
                    value = parseFloat(value, 10) * 1024;
                    return valueFormatter.formatScalingUnit(value, 'Byte', 2);
                case 'Boolean':
                    return value ? 'True' : 'False';
                case 'AlertSeverity':
                    return PRIORITY_TO_SEVERITY[value];
                case 'aws_state':
                    try {
                        return value.match(/Name: (.*)}/)[1];
                    } catch (e) {}
                    return '';
                default:
                    return value;
            }
        };
    },
]);
