import templateUrl from './kubeMapAnalyzer.tpl.html';

export default {
    templateUrl,
    bindings: {
        title: '@',
        queryObjects: '<', // A list of objects to get insights for. { context, property, propertyValue }
        filterBy: '<', // Filters to apply on the context while getting the results.
        variables: '<',
    },
    controller: [
        '_',
        '$scope',
        'kubeDataService',
        'urlOverridesService',
        'dashboardVariablesService',
        'infoSidebarUtil',
        'ANALYZER_EVENT',
        'KUBE_PROPERTY_TO_VARIABLE_ALIAS',
        function (
            _,
            $scope,
            kubeDataService,
            urlOverridesService,
            dashboardVariablesService,
            infoSidebarUtil,
            ANALYZER_EVENT,
            KUBE_PROPERTY_TO_VARIABLE_ALIAS
        ) {
            const $ctrl = this;
            const INSIGHT_DEBOUNCE_TIMEOUT = 1000;
            const NUM_PROPERTIES = 5;
            const MULTI_VIEW_NUM_PROPERTIES = 0;

            $ctrl.loading = false;
            $ctrl.results = [];
            $ctrl.openDetailPage = openDetailPage;
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.resultKeyToTitle = {
                FAILED_PODS: 'failed pods',
                HIGH_CPU_NODES: 'high CPU nodes',
                HIGH_MEM_NODES: 'high Memory nodes',
                NOT_READY_NODES: 'nodes not ready',
                PENDING_PODS: 'pods with pending status',
                RESTARTED_CONTAINERS: 'restarted containers',
                UNKNOWN_NODES: 'nodes with Unknown condition',
                UNKNOWN_PODS: 'pods with Unknown condition',
            };

            $ctrl.showMoreProperties = showMoreProperties;
            $ctrl.hideProperties = hideProperties;
            $ctrl.showAllClusters = showAllClusters;
            $ctrl.showFewerClusters = showFewerClusters;
            $ctrl.SCROLL_BY = 5;

            const getDebouncedResults = _.debounce(getResults, INSIGHT_DEBOUNCE_TIMEOUT);

            const detailPageKeyMapping = {
                deployment: infoSidebarUtil.INFO_PANELS.workloadDetail,
                kubernetes_node: infoSidebarUtil.INFO_PANELS.nodeDetail,
                kubernetes_pod_name: infoSidebarUtil.INFO_PANELS.podDetail,
                kubernetes_pod_uid: infoSidebarUtil.INFO_PANELS.podDetail,
                container_id: infoSidebarUtil.INFO_PANELS.containerDetail,
                container_spec_name: infoSidebarUtil.INFO_PANELS.containerDetail,
            };

            function $onInit() {
                $ctrl.$onChanges();
            }

            function $onChanges() {
                $ctrl.results = null;

                if (!$ctrl.queryObjects || !$ctrl.queryObjects.length) {
                    return;
                }
                $ctrl.isMultiCluster = $ctrl.queryObjects.length > 1;
                $ctrl.DEFAULT_NUM_PROPERTIES = $ctrl.isMultiCluster
                    ? MULTI_VIEW_NUM_PROPERTIES
                    : NUM_PROPERTIES;

                $ctrl.loading = true;
                getDebouncedResults(
                    $ctrl.queryObjects,
                    $ctrl.filterBy,
                    $ctrl.variables,
                    $ctrl.isMultiCluster
                );
            }

            function getResults(queryObjects, filterBy, variables, isMultiCluster) {
                kubeDataService
                    .getMapAnalyzerResult(queryObjects, filterBy, variables)
                    .then((results) => {
                        if (!_.isEmpty(results)) {
                            if (isMultiCluster) {
                                summarizeEmptyClusterResults(results);
                            }

                            $ctrl.results = initializeDisplay(results);
                        }
                    })
                    .finally(() => ($ctrl.loading = false));
            }

            /* modifies results in the case that a condition has some clusters
       with results and some clusters with no results */
            function summarizeEmptyClusterResults(resultsForConditions) {
                for (const conditionResults of resultsForConditions) {
                    const clusterResults = conditionResults.clusterResults;
                    const compressedResults = _.filter(clusterResults, (clusterResult) => {
                        return clusterResult.count > 0;
                    });
                    const numClustersWithNoResults =
                        clusterResults.length - compressedResults.length;

                    if (compressedResults.length > 0 && numClustersWithNoResults > 0) {
                        compressedResults.push({
                            context: {
                                count: numClustersWithNoResults,
                            },
                            condition: conditionResults.condition,
                            count: 0,
                        });
                        conditionResults.clusterResults = compressedResults;
                    }
                }
            }

            function initializeDisplay(resultsForConditions) {
                // Show top 5 results per condition
                for (const conditionResult of resultsForConditions) {
                    conditionResult.clusterResults.forEach((clusterResult) => {
                        const attributeResults = clusterResult.correlatedAttributes;
                        clusterResult.attributesToDisplay = attributeResults
                            ? attributeResults.slice(0, $ctrl.DEFAULT_NUM_PROPERTIES)
                            : [];
                    });
                }
                return resultsForConditions;
            }

            function showMoreProperties(results) {
                const currentIndex = results.attributesToDisplay.length;
                results.attributesToDisplay = results.correlatedAttributes.slice(
                    0,
                    currentIndex + $ctrl.SCROLL_BY
                );
            }

            function hideProperties(results) {
                results.attributesToDisplay = results.correlatedAttributes.slice(
                    0,
                    $ctrl.DEFAULT_NUM_PROPERTIES
                );
            }

            function showAllClusters(conditionResult) {
                conditionResult.displayAllClusters = true;
            }

            function showFewerClusters(conditionResult) {
                conditionResult.displayAllClusters = false;
            }

            function openDetailPage(result, context) {
                const key = result.key;
                const value = result.value;
                const sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
                sourceOverrides.push({
                    NOT: false,
                    property: key,
                    propertyValue: value,
                });

                const detailPage = detailPageKeyMapping[key];
                if (detailPage) {
                    const requiredFilters =
                        infoSidebarUtil.obtainRequiredSidebarFiltersFromVariablesAndSources(
                            detailPage.requiredFilters
                        );
                    // when the required filters found does not match the required filters needed, look for the additional filters
                    if (requiredFilters.length !== detailPage.requiredFilters.length) {
                        $scope.$emit(
                            ANALYZER_EVENT.SIDEBAR_LOOK_FOR_MATCHING_CHILD,
                            context.propertyValue,
                            result,
                            detailPage.requiredFilters
                        );
                    } else {
                        setupInfoSidebar(key, requiredFilters);
                    }
                }
                urlOverridesService.setSourceFilterOverrideList(sourceOverrides);
                carryOverClusterFromAnalyzer(context);
            }

            function carryOverClusterFromAnalyzer(context) {
                const alias = KUBE_PROPERTY_TO_VARIABLE_ALIAS[context.property];
                dashboardVariablesService.setVariableOverride(
                    alias,
                    context.property,
                    context.propertyValue,
                    false
                );
            }

            $scope.$on(
                ANALYZER_EVENT.SIDEBAR_FOUND_MATCHING_CHILD,
                function (event, child, requiredFilters, key) {
                    const metadata = child.data;
                    const infoSidebarSourceFilters = [];
                    requiredFilters.forEach((filter) => {
                        infoSidebarSourceFilters.push({
                            NOT: false,
                            property: filter,
                            propertyValue: metadata[filter],
                        });
                    });
                    setupInfoSidebar(key, infoSidebarSourceFilters);
                }
            );

            function setupInfoSidebar(detailPageKey, sidebarFilters) {
                const detailPage = detailPageKeyMapping[detailPageKey];
                if (detailPage) {
                    urlOverridesService.hideInfoSidebar(false);
                    infoSidebarUtil.setInfoPanelSidebarURLParams(detailPage.value, sidebarFilters);
                }
            }
        },
    ],
};
