import React, { FC, createContext, useMemo, useContext } from 'react';
import { TopNavContextType } from '@splunk/olly-common/Nav';

type TopNavContextProviderType = {
    TopNavStateContext: React.Context<TopNavContextType> | undefined;
};

const noop = (): void => {};

export const SignalviewTopNavContext = createContext({} as TopNavContextType);

/**
 * The TopNavStateContext is passed from olly as props. Converting it to context so it is available to children components.
 */
export const SignalviewTopNavContextProvider: FC<TopNavContextProviderType> = ({
    TopNavStateContext = SignalviewTopNavContext,
    children,
}) => {
    const {
        navigation = [],
        setSiteMap = noop,
        setCustomContent = noop,
        setHierarchicalNav = noop,
    } = useContext(TopNavStateContext);

    const value = useMemo(
        () => ({ navigation, setSiteMap, setCustomContent, setHierarchicalNav }),
        [navigation, setSiteMap, setCustomContent, setHierarchicalNav]
    );

    return (
        <SignalviewTopNavContext.Provider value={value}>
            {children}
        </SignalviewTopNavContext.Provider>
    );
};
