import { catalogQuickActionsDataProvider } from './catalogQuickActionsDataProvider';
import quickActionsUtil from './quickActionsUtil';
import sortUtil from './sortUtil';
import searchCategoriesUtil from './searchCategoriesUtil';
import iconUtils from './iconUtils';

angular
    .module('signalview.quickActions', [])
    .service('catalogQuickActionsDataProvider', catalogQuickActionsDataProvider)
    .service('quickActionsUtil', quickActionsUtil)
    .service('sortUtil', sortUtil)
    .service('searchCategoriesUtil', searchCategoriesUtil)
    .service('iconUtils', iconUtils);
