function JobFeedbackMessage() {
    this.channel = null;
    this.logicalTimestampMs = null;
    this.message = {
        blockSerialNumbers: [],
        blockContexts: [],
        contents: {},
        messageCode: null,
        messageLevel: null,
        numInputTimeSeries: null,
        timestampMs: Date.now(),
    };
    this.type = 'message';
}

JobFeedbackMessage.prototype.setChannel = function (channel) {
    this.channel = channel;
};

JobFeedbackMessage.prototype.setMessage = function (msg) {
    this.message = msg;
};

JobFeedbackMessage.prototype.setTimeStamp = function (ts) {
    this.logicalTimestampMs = ts;
};

export default JobFeedbackMessage;
