import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import { SignalboostBaseService } from '@splunk/olly-imm/build/services/signalboost/SignalboostBaseService/SignalboostBaseService';
import templateUrl from './catalogPreview.tpl.html';

angular
    .module('catalogPreviewer', ['signalview.objectmanager', 'signalview.objectmanager'])
    .directive('catalogPreview', [
        function () {
            //this directive is a shim to move logic around the nuances of the
            //catalog selection scheme vs the object fetching required to generate intelligent previews.
            return {
                scope: {
                    previewObject: '=',
                    filters: '=',
                },
                templateUrl,
                controller: [
                    '$scope',
                    '$q',
                    'signalboost',
                    '$timeout',
                    'currentUser',
                    'metricService',
                    'migratedSignalboost',
                    function (
                        $scope,
                        $q,
                        signalboost,
                        $timeout,
                        currentUser,
                        metricService,
                        migratedSignalboost
                    ) {
                        $scope.currentPreviewPromise = null;
                        $scope.selectedObjectsByType = {};
                        $scope.selectedObjectsByTypeMap = {};
                        $scope.updateCount = 0;
                        const orgIdPromise = currentUser.orgId();

                        function clearObject(obj) {
                            Object.keys(obj).forEach(function (k) {
                                delete obj[k];
                            });
                        }

                        function getObjects(restService, objectKVs) {
                            const query = objectKVs
                                .map(function (objectKV) {
                                    const sanitizedTerm = sanitizeTerm(objectKV.value);
                                    return (
                                        '(sf_key:' +
                                        objectKV.key +
                                        ' AND ' +
                                        objectKV.key +
                                        ':' +
                                        sanitizedTerm +
                                        ')'
                                    );
                                })
                                .join(' OR ');

                            if (query === '') {
                                return $q.when([]);
                            }
                            return orgIdPromise.then(function (orgId) {
                                const newQuery =
                                    '(' + query + ') AND sf_organizationID:"' + orgId + '"';

                                // seperates the olly-imm migrated services
                                const restPromise =
                                    restService.constructor.prototype instanceof
                                    SignalboostBaseService
                                        ? restService.get('', {
                                              query: query,
                                              limit: 10000,
                                          })
                                        : restService.all().customGET('', {
                                              query: newQuery,
                                              limit: 10000,
                                          });

                                return restPromise.then(function (res) {
                                    if (res.rs.length) return res.rs;

                                    // for plain properties
                                    return objectKVs.map(function (property) {
                                        const result = {
                                            sf_id: 'FAKE',
                                            sf_type: 'Property',
                                            sf_key: [property.key],
                                            sf_property: property.value,
                                        };

                                        result[property.key] = property.value;

                                        return result;
                                    });
                                });
                            });
                        }

                        function getKeyObjects() {
                            // sf_metric and sf_source are not traditional "dimensions" and must be fetched separately
                            const sourceKVs = [];
                            const metricKVs = [];
                            const dimensionKVs = [];
                            const topics = [];

                            // Filter out KVs from true objects and separate them by rest service
                            $scope.previewObject.forEach(function (object) {
                                let result;

                                if (object.type === 'topic') {
                                    result = {
                                        sf_id: 'FAKE',
                                        sf_type: 'Topic',
                                        sf_key: [object.topic.key],
                                        sf_topic: object.topic.key,
                                        isProperty:
                                            object.isProperty &&
                                            !object.isDimension &&
                                            !object.isType,
                                        isDimension: object.isDimension,
                                        isType: object.isType,
                                    };

                                    result[object.topic.key] = object.topic.value;

                                    topics.push(result);
                                    return;
                                }

                                if (object.type !== 'property') return;

                                if (object.value === '*') {
                                    const type = object.key.toLowerCase();

                                    result = {
                                        sf_id: 'FAKE',
                                        sf_type: 'Property',
                                        sf_key: [object.key],
                                        sf_property: '*',
                                    };

                                    result[type] = object.value;

                                    topics.push(result);

                                    return;
                                } else if (object.key === 'sf_source') {
                                    sourceKVs.push(object);
                                } else if (object.key === 'sf_metric') {
                                    metricKVs.push(object);
                                } else {
                                    dimensionKVs.push(object);
                                }
                            });

                            const resultPromises = [
                                topics,
                                getObjects(migratedSignalboost.dimension, dimensionKVs),
                                getObjects(signalboost.source, sourceKVs),
                                getObjects(migratedSignalboost.metric, metricKVs),
                            ];

                            return $q.all(resultPromises).then(function (results) {
                                // Merges array of arrays to single array.
                                return [].concat.apply([], results);
                            });
                        }

                        function updatePreviewMaps(docs) {
                            reset();

                            $scope.previewDocs = docs;

                            docs.forEach(function (doc) {
                                const type = doc.sf_type.toLowerCase();

                                if (!$scope.selectedObjectsByType[type]) {
                                    $scope.selectedObjectsByType[type] = [];
                                    $scope.selectedObjectsByTypeMap[type] = {};
                                }

                                $scope.selectedObjectsByType[type].push(doc);
                                $scope.selectedObjectsByTypeMap[type][doc.sf_id] = doc;
                            });
                        }

                        function updatePreview() {
                            let objects = $scope.previewObject
                                .filter(function (object) {
                                    return object.type === 'object';
                                })
                                .map(function (object) {
                                    return object.object;
                                });

                            return getKeyObjects().then(function (results) {
                                $scope.currentPreviewPromise = null;
                                objects = objects.concat(results);
                                updatePreviewMaps(objects);
                            });
                        }

                        function reset() {
                            $scope.updateCount++;

                            clearObject($scope.selectedObjectsByType);
                            clearObject($scope.selectedObjectsByTypeMap);
                        }

                        $scope.updatePreviewMaps = function (docs) {
                            $scope.updateCount++;

                            docs.forEach(function (doc) {
                                const type = doc.sf_type.toLowerCase();
                                if (!$scope.selectedObjectsByType[type]) {
                                    $scope.selectedObjectsByType[type] = [];
                                    $scope.selectedObjectsByTypeMap[type] = {};
                                }
                                $scope.selectedObjectsByType[type].push(doc);
                                $scope.selectedObjectsByTypeMap[type][doc.sf_id] = doc;
                            });
                        };

                        function getSelectedType() {
                            if (!$scope.previewObject) return null;

                            const typesMap = {};

                            $scope.previewObject.forEach(function (object) {
                                typesMap[object.type] = true;
                            });

                            const types = Object.keys(typesMap);

                            if (types.length > 1) {
                                return 'mixed';
                            } else if (types.length === 1) {
                                return types[0];
                            } else {
                                return null;
                            }
                        }

                        function getPlugins(selectionFilter) {
                            const filters = $scope.filters.map(function (searchFilter) {
                                return searchFilter.property + ':' + searchFilter.propertyValue;
                            });

                            if (selectionFilter) {
                                filters.push(selectionFilter);
                            }

                            if ($scope.filters && $scope.filters.length) {
                                $scope.filters.forEach(function (filter) {
                                    filters.push(filter.property + ':' + filter.propertyValue);
                                });
                            }

                            return metricService
                                .fetchFacetByKey('plugin', '', filters, true, '')
                                .then((rs) => rs.map((f) => f.name));
                        }

                        function checkIfHasCollectd(selectionFilter) {
                            // this method was written so long ago that its probably quite dated, but is kept because
                            // there could still be legacy customers with the aggregation plugin
                            const filters = $scope.filters.map(function (searchFilter) {
                                return searchFilter.property + ':' + searchFilter.propertyValue;
                            });

                            if (selectionFilter) {
                                filters.push(selectionFilter);
                            }

                            // This removes false positives (metrics which have a host dimension
                            // but aren't from collectd). This creates a false negative situation
                            // for when the aggregation plugin isn't used, but that's ok because
                            // our charts rely on the existance of the aggregation plugin currently
                            filters.push('plugin:aggregation');

                            // I'm not sure this is still possible in customer environments, but don't know
                            // enough to remove it.
                            return metricService
                                .metricfinder('', filters, true)
                                .then(function (res) {
                                    return res.metrics.length;
                                });
                        }

                        function getCuratedView(objects) {
                            $scope.curatedView = null;
                            $scope.availablePlugins = [];
                            if (!objects || objects.length !== 1) return $q.when(null);

                            const object = objects[0];
                            const selectedType = getSelectedType();

                            let query;
                            const viewData = {
                                viewName: null,
                                plugins: null,
                            };

                            if (selectedType === 'property') {
                                const key = object.key;

                                if (
                                    key === 'host' ||
                                    key === 'InstanceId' ||
                                    key === 'aws_instance_id'
                                ) {
                                    query = 'host:' + object.value;
                                } else if (key.indexOf('aws_') === 0) {
                                    query = key + ':' + object.value;
                                } else {
                                    // if the selected property isn't an AWS property and it's not a
                                    // collectd host property, then no need to check if collectd view
                                    // should be shown.
                                    return $q.when(viewData);
                                }
                            } else if (selectedType !== 'topic' || object.topic.key !== 'host') {
                                // if this isn't a property and not a host dimension, no need to
                                // check if collectd should be shown.
                                return $q.when(viewData);
                            }

                            return $q
                                .all([checkIfHasCollectd(query), getPlugins(query)])
                                .then(function (resp) {
                                    const hosts = resp[0];
                                    const plugins = resp[1];
                                    viewData.plugins = plugins;
                                    if (hosts === 0 || plugins.length === 0) return viewData;
                                    if (hosts === 1) {
                                        viewData.viewName = 'collectdHost';
                                    } else {
                                        viewData.viewName = 'collectdHosts';
                                    }
                                    return viewData;
                                });
                        }

                        $scope.throttledPreviewUpdate = function (objects) {
                            if (!objects || !objects.length) return;
                            $timeout.cancel($scope.currentPreviewPromise);
                            $scope.currentPreviewPromise = $timeout(updatePreview, 0);

                            getCuratedView(objects).then(function (viewInfo) {
                                $scope.curatedView = viewInfo.viewName;
                                $scope.availablePlugins = viewInfo.plugins;
                            });
                        };

                        // even though this is an array, watch is sufficient because the array is
                        // replaced with a new one on mutate.
                        $scope.$watch('previewObject', $scope.throttledPreviewUpdate);
                    },
                ],
            };
        },
    ]);
