import { useState, useEffect, useRef, useCallback } from 'react';
const useAIWebSocket = (url, shouldFetch) => {
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [orgId, setOrgId] = useState('');

    const socketRef = useRef(null);

    useEffect(() => {
        if (!shouldFetch) return;
        setError(null);
        socketRef.current = new WebSocket(url);

        socketRef.current.onopen = () => {
            const authMessage = JSON.stringify({
                type: 'authenticate',
                token: window.localStorage.getItem('X-SF-TOKEN'),
            });
            socketRef.current.send(authMessage);
            console.log('WebSocket Connected');
        };

        socketRef.current.onmessage = (event) => {
            let message;
            try {
                message = JSON.parse(event.data);
            } catch (e) {
                message = event.data;
            }
            if (message && message.type === 'authenticated') {
                setIsAuthenticated(true);
                setOrgId(message.orgId);
            }

            if (message && message.messageChunk) {
                setMessages((previousMessages) => [...previousMessages, message.messageChunk]);
            }
        };

        socketRef.current.onclose = (event) => {
            setIsAuthenticated(false);
            console.log('WebSocket Disconnected', event);
            setError(`Unexpected disconnection: ${event.reason}`);
        };

        socketRef.current.onerror = (err) => {
            console.error('WebSocket Error:', err);
            setError('WebSocket encountered an error.');
        };

        return () => {
            socketRef.current.close();
        };
    }, [url, shouldFetch]);

    const sendMessage = useCallback(
        (incidentId, isAlertForAPMError) => {
            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                if (isAuthenticated && orgId) {
                    const apmPromptMsg = `
                    General guidelines: 
                    (1) mention the steps followed briefly and do not mention the steps skipped. 
                    (2) keep the summary in each step within 5 sentences. 
                    (3) do not respond to the user unless you have finished calling all tools
                    For the incident with ID ${incidentId}, if it is related to high error rate, do the following troubleshooting steps: 
                    (1) Give an investigative summary about the impacted service and environment, along with the incident start time, duration and extent of impact. 
                    (2) Return links to 3 example traces. For each trace, also mention the root cause service. 
                    (3) Use APM tag spotlight on the root cause service and environment with the time range from the start of the alert and conduct outlier analysis to explain which tag values show a high error rate. Order the tags in descending order of error count. Group different tag values for the same tag together in descending order of error count. For each tag value, mention the error count and request count. Do not include service and environment related tags. Exclude any tags with no errors. Specifically check tags like sf_httpMethod, http.response.status_code, exception.type, and recommend to index them if missing. 
                    (4) Check available infrastructure and runtime metrics for the impacted service showing anomaly and summarize the change in less than 5 sentences. Also provide links to charts that show the anomaly in the metric. 
                    (5) End with a link to APM service for the root cause service and environment.
                    `;
                    const otherPromptMsg = `
                    General guidelines: 
                    (1) mention the steps followed briefly and do not mention the steps skipped. 
                    (2) keep the summary in each step within 5 sentences. 
                    (3) do not respond to the user unless you have finished calling all tools.
                    For the incident with ID ${incidentId}, do the following troubleshooting steps: 
                    (1) Give an investigative summary about the impacted service and environment, along with the incident start time, duration and extent of impact. 
                    (2) Classify whether the alert is related to a service, API, processing infrastructure or Kubernetes. Perform steps 3, 4, and 5 only if it satisfies the condition mentioned. 
                    (3) If the alert is related to service or API, then and only then use APM tools and return links to 3 example traces. Also conduct outlier analysis using APM tag spotlight on for errors and latency. End your analysis with a link to the APM service for the root cause service and environment. Else, skip this step. 
                    (4) If the alert is related to processing hardware like CPU, memory, disk or network usage, then and only then check available infrastructure and runtime metrics for the impacted service, and provide a list of metrics along with links to charts that displays unexpected, anomalous or adverse behavior. Else, skip this step. 
                    (5) If the alert is related to kubernetes, then and only then check kubernetes navigators and summarize your findings. Else, skip this step.
                    `;
                    const promptMsg = isAlertForAPMError ? apmPromptMsg : otherPromptMsg;
                    const prompt = {
                        message: promptMsg,
                        threadId: 'new_chat',
                        threadName: null,
                        orgId: orgId,
                        incidentId: incidentId,
                        apiVersion: '1',
                        apiMethod: 'SendChatMessage',
                    };

                    socketRef.current.send(JSON.stringify(prompt));
                }
            } else {
                console.warn('Cannot send message, WebSocket is not open');
            }
        },
        [isAuthenticated, orgId]
    );

    return { messages, sendMessage, error };
};

export default useAIWebSocket;
