'use strict';

/**
  config - Configuration service

  Config allows setting/getting of properties both during config and run time.
  When config properties are updated during runtime, this will broadcase a
  message via $rootScope with the key "<config key> updated" as the event name
  and the value as the argument. This pattern allows clients to both get the
  current value and subscribe to future updates, similar to how disco functions
  in the backend.

  Example:
  module.config(['configProvider', function(configProvider){
    // Set a property during config time
    configProvider('my key', { some: 'complex value' });

    // Get a property during config time
    var prop = configProvider('config key');
  }]);

  module.run(['config', '$rootScope', function(config, $rootScope){
    // Set a property during run time
    config('my key', 1234);

    // Get a property during run time
    var prop = config('my key');

    // Subscribe to changes of a property (you can just use $scope, doesn't
    // have to be $rootScope)
    $rootScope.$on('my key updated', function(event, prop){
      // ...
    });
  }]);
*/
angular.module('sfx.data').provider('config', function () {
    const config = {};

    function get(key) {
        return config[key];
    }

    function set(key, value) {
        config[key] = value;
    }

    const provideHandler = function (key, value) {
        if (arguments.length > 1) return set(key, value);
        else return get(key);
    };

    provideHandler.$get = [
        '$rootScope',
        '$log',
        'currentUser',
        function ($rootScope, $log, currentUser) {
            function handler(key, value) {
                if (arguments.length > 1) {
                    const current = get(key);

                    // Noop if value hasn't changed
                    if (key === current) return;

                    set(key, value);
                    $rootScope.$broadcast(key + ' updated', value);

                    $log.info('Config', key, 'updated to', value);
                } else {
                    return get(key);
                }
            }
            handler.await = function () {
                return currentUser
                    .localStorage()
                    .then(function (storage) {
                        if (!storage) return;
                        const superpowers = storage.get('superpowers');
                        if (!superpowers) return;

                        Object.keys(superpowers).forEach(function (name) {
                            const value = superpowers[name];
                            $log.info('Enabling superpower from localStorage:', name, '=', value);
                            handler('superpower.' + name, value);
                        });
                    })
                    .catch(function (e) {
                        $log.warn('Error while awaiting config', e);
                    });
            };

            return handler;
        },
    ];

    return provideHandler;
});
