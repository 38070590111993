export default [
    '$http',
    '$q',
    'API_URL',
    'SMOKEY_SEVERITY_LEVELS',
    'alertStateBatcher',
    function ($http, $q, API_URL, SMOKEY_SEVERITY_LEVELS, alertStateBatcher) {
        const resourceEndpoint = API_URL + '/v2/alerts';

        const api = {
            getAlertState,
            getAlertStateFromAlertMap,
            getLinkedChartsForDetector,
            getDetectorAlerts,
            getAlertStateBatched,
        };

        return api;

        /*
         * returns list of objects with the following properties:
         *  {
         *      chartId,
         *      chartName,
         *      dashboardId,
         *      dashboardName,
         *      dashboardGroupId,
         *      dashboardGroupName,
         *      configId,
         *      inMirror: flag for whether chart is in a mirrored dashboard
         *  }
         */
        function getLinkedChartsForDetector(detectorId) {
            return $http
                .get(`${resourceEndpoint}/detector/${detectorId}`)
                .then((response) => response.data);
        }

        function forSignalFlow(filteredProgramRequest) {
            return $http({
                method: 'POST',
                url: `${resourceEndpoint}/_/signalflow`,
                data: filteredProgramRequest,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.data);
        }

        function getFilteredProgramRequest(
            programText,
            additionalFilters,
            additionalReplaceOnlyFilters
        ) {
            // Infranav still uses the old filter format when constructing additonal filters, which
            // means we have to normalize to the format expected by AutoSuggestFilter of the backend.
            additionalFilters = (additionalFilters || []).map((filter) => ({
                not: filter.not || filter.NOT,
                values:
                    filter.values ||
                    (Array.isArray(filter.propertyValue)
                        ? filter.propertyValue
                        : [filter.propertyValue]),
                property: filter.property,
            }));

            return {
                programText,
                additionalFilters,
                additionalReplaceOnlyFilters,
            };
        }

        function getAlertState(programText, additionalFilters, additionalReplaceOnlyFilters) {
            if (!programText || !programText.length) {
                return $q.when({});
            }
            return forSignalFlow(
                getFilteredProgramRequest(
                    programText,
                    additionalFilters,
                    additionalReplaceOnlyFilters
                )
            );
        }

        function getAlertStateBatched(
            programText,
            additionalFilters,
            additionalReplaceOnlyFilters
        ) {
            const filteredProgramRequest = getFilteredProgramRequest(
                programText,
                additionalFilters,
                additionalReplaceOnlyFilters
            );
            return alertStateBatcher.getAlertStateBatched(filteredProgramRequest);
        }

        function getDetectorAlerts(detectorIds) {
            if (!detectorIds) {
                return $q.when({});
            }
            return $http({
                method: 'GET',
                url: `${resourceEndpoint}/detectors`,
                params: { detectorIds },
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.data);
        }

        function getAlertStateFromAlertMap(activeAlerts) {
            if (!activeAlerts) return null;

            function getHighestSeverity(alertMap) {
                return SMOKEY_SEVERITY_LEVELS.find((severity) => {
                    const alerts = alertMap[severity];

                    if (angular.isNumber(alerts)) {
                        // If this is a count of alerts
                        return alerts > 0;
                    } else {
                        // If this is a list of alerts

                        // normal is always active, so ignore
                        if (alerts && alerts.length && severity === 'Normal') {
                            return true;
                        } else {
                            return alerts && alerts.filter((alert) => alert.active).length > 0;
                        }
                    }
                });
            }

            // If we are throttled by the server, a response with no severities whatsoever will be
            // returned, causing getHighestSeverity to find nothing in the activeAlerts map.  When this
            // occurs, null is returned to indicate that no state change should occur.
            const highestSeverity = getHighestSeverity(activeAlerts);
            return highestSeverity || null;
        }
    },
];
