angular.module('signalview.chartV2').service('aceUtils', [
    'themeService',
    function (themeService) {
        const DARK_ACE_THEME = 'ace/theme/twilight';
        const LIGHT_ACE_THEME = 'ace/theme/textmate';
        const LINE_BREAK_THRESHOLD = 120;
        const MESSAGE_LEVELS = {
            ERROR: 4,
            WARNING: 3,
            INFO: 2,
            NONE: 1,
        };
        const LEVELS_TO_TYPES = {
            1: null,
            2: 'info',
            3: 'warning',
            4: 'error',
        };

        function getWarningLevel(encountered, old) {
            return Math.max(encountered, old);
        }

        function levelToType(level) {
            return LEVELS_TO_TYPES[level] || null;
        }

        function updateTheme(aceInstance) {
            if (!aceInstance) {
                return;
            }
            aceInstance.setTheme(themeService.dark ? DARK_ACE_THEME : LIGHT_ACE_THEME);
        }

        function messagesToAnnotations(messages, sampleRate) {
            const lineToMessages = {};
            const annotations = [];
            messages.forEach(function (m) {
                if (m.blockContext && !isNaN(m.blockContext.line)) {
                    const lineNo = m.blockContext.line;
                    lineToMessages[lineNo] = lineToMessages[lineNo] || [];
                    lineToMessages[lineNo].push(m);
                }
            });

            angular.forEach(lineToMessages, function (messageList, lineNumber) {
                let findCount = 0;
                let multipleKeysetCount = 0;
                const multipleKeysetDump = [];
                let prePublish = 0;
                let postPublish = 0;
                const sampleDump = [];
                let numFetchesLimited = false;
                let dataBlockCount = 0;
                let publishBlockCount = 0;
                let messageLevel = MESSAGE_LEVELS.NONE;
                let numNoTimeSeries = 0;
                let limitSize = 0;
                const numTimeSeriesDump = [];
                const lineInformation = {};
                const indent = ' ';
                messageList.forEach(function (message) {
                    if (message.messageCode === 'FETCH_NUM_TIMESERIES') {
                        const tsCount = message.numInputTimeSeries;
                        findCount += tsCount;
                        numTimeSeriesDump.push(tsCount);
                        if (tsCount === 0) {
                            numNoTimeSeries++;
                        }
                    } else if (message.messageCode === 'FIND_MATCHED_DIMENSIONS') {
                        dataBlockCount++;
                        multipleKeysetCount += message.contents.dimensionCounts.length > 1 ? 1 : 0;
                        multipleKeysetDump.push(message.contents.dimensionCounts);
                    } else if (message.messageCode === 'ID_NUM_TIMESERIES') {
                        publishBlockCount++;
                        const tsCount = message.numInputTimeSeries;
                        if (tsCount > sampleRate) {
                            sampleDump.push([sampleRate, tsCount]);
                            postPublish += sampleRate;
                        } else {
                            sampleDump.push([tsCount, tsCount]);
                            postPublish += tsCount;
                        }

                        prePublish += tsCount;
                    } else if (message.messageCode === 'FIND_LIMITED_RESULT_SET') {
                        numFetchesLimited++;
                        limitSize = message.contents.limitSize;
                    }
                });

                const messageComposition = [];
                if (dataBlockCount > 0) {
                    if (numFetchesLimited) {
                        messageLevel = getWarningLevel(MESSAGE_LEVELS.WARNING, messageLevel);
                        messageComposition.push(
                            `Displayed data is the result of analyzing only ${findCount} time series` +
                                ` in ${dataBlockCount} ` +
                                (dataBlockCount > 1 ? 'requests' : 'request') +
                                ' for data.' +
                                ` Try filtering to fewer than ${limitSize} timeseries per request.`
                        );
                    } else {
                        messageLevel = getWarningLevel(MESSAGE_LEVELS.INFO, messageLevel);
                        messageComposition.push(
                            `${findCount} timeseries were found in ${dataBlockCount} ` +
                                (dataBlockCount > 1 ? 'requests' : 'request') +
                                ' for data.'
                        );
                    }

                    if (numTimeSeriesDump.length > 1) {
                        messageComposition.push(
                            indent +
                                'Per request : ' +
                                numTimeSeriesDump.map((i) => i + ' timeseries').join(', ')
                        );
                    }
                }

                if (multipleKeysetCount > 0) {
                    messageLevel = getWarningLevel(MESSAGE_LEVELS.WARNING, messageLevel);
                    multipleKeysetDump.forEach(function (keyset) {
                        messageComposition.push(
                            'Different dimension keysets were detected in these timeseries:'
                        );
                        keyset
                            .sort(function (a, b) {
                                return b.count - a.count;
                            })
                            .forEach(function (dimArr) {
                                messageComposition.push(
                                    `  ${dimArr.dimensions
                                        .sort(function (a, b) {
                                            return b.localeCompare(a);
                                        })
                                        .join(', ')} (${dimArr.count} timeseries)`
                                );
                            });
                    });
                }

                if (publishBlockCount > 0 && prePublish !== postPublish) {
                    messageLevel = getWarningLevel(MESSAGE_LEVELS.WARNING, messageLevel);
                    messageComposition.push(
                        `${postPublish} of ${prePublish} timeseries were sampled in ${publishBlockCount} ` +
                            (publishBlockCount > 1 ? 'requests' : 'request') +
                            ' to publish data.'
                    );
                    if (sampleDump.length > 1) {
                        sampleDump.forEach(function (pair) {
                            messageComposition.push(
                                `${indent} ${pair[0]} timeseries sampled out of ${pair[1]}`
                            );
                        });
                    }
                }

                if (numNoTimeSeries > 0) {
                    messageLevel = getWarningLevel(MESSAGE_LEVELS.WARNING, messageLevel);
                    messageComposition.push(
                        numNoTimeSeries +
                            ' ' +
                            (publishBlockCount > 1 ? 'requests' : 'request') +
                            ' for data returned no timeseries.'
                    );
                }

                lineInformation.row = Number(lineNumber) - 1;
                lineInformation.text = messageComposition.join('\n');
                lineInformation.type = levelToType(messageLevel);
                if (lineInformation.text) {
                    annotations.push(lineInformation);
                }
            });

            return annotations;
        }

        function manualLineBreakMessage(msg) {
            if (msg.indexOf('\n') !== -1) {
                //server formatted it for us...
                return msg;
            }
            const msgs = [];
            for (let i = 0; i < Math.ceil(msg.length / LINE_BREAK_THRESHOLD); i++) {
                msgs.push(msg.substring(i * LINE_BREAK_THRESHOLD, (i + 1) * LINE_BREAK_THRESHOLD));
            }
            return msgs.join('\n');
        }

        function analyticsErrorToAnnotations(resp) {
            const err = resp.data;
            if (err.context && err.context.line !== undefined) {
                return [
                    {
                        row: err.context.line - 1,
                        col: err.context.column,
                        text: manualLineBreakMessage(
                            err.context.message || err.message || JSON.stringify(err.context)
                        ),
                        type: 'error',
                    },
                ];
            } else {
                return [
                    {
                        row: 0,
                        col: 0,
                        text: manualLineBreakMessage(err.message || JSON.stringify(err)),
                        type: 'error',
                    },
                ];
            }
        }

        return {
            messagesToAnnotations,
            analyticsErrorToAnnotations,
            updateTheme,
        };
    },
]);
