/* eslint-disable */
// 👇🏼 Node's crypto is being polyfilled and so this works in the browser. Note that this only works when webpack is on or under v4. Also note that if angular->react ever happens, signalview should use the olly-common's function to generate sha256 hash
import crypto from 'crypto';

angular.module('angular-sha256', ['gdi2290.sha256']);
angular.module('ngSha256', ['gdi2290.sha256']);
angular.module('gdi2290.sha256', [
    'gdi2290.gravatar-filter',
    'gdi2290.sha256-service',
    'gdi2290.sha256-filter',
]);
('use strict');
angular.module('gdi2290.gravatar-filter', []).filter('gravatar', [
    'sha256',
    function (sha256) {
        var cache = {};
        return function (text, defaultText) {
            if (!cache[text]) {
                defaultText = defaultText
                    ? sha256.createHash(defaultText.toString().toLowerCase())
                    : '';
                cache[text] = text ? sha256.createHash(text.toString().toLowerCase()) : defaultText;
            }
            return cache[text];
        };
    },
]);
('use strict');
angular.module('gdi2290.sha256-filter', []).filter('sha256', [
    'sha256',
    function (sha256) {
        return function (text) {
            return text ? sha256.createHash(text.toString().toLowerCase()) : text;
        };
    },
]);
('use strict');
angular.module('gdi2290.sha256-service', []).factory('sha256', [sha256Service]);

export default function sha256Service() {
    var sha256 = {
        createHash: function (str) {
            return crypto.createHash('sha256').update(str).digest('hex');
        },
    };
    return sha256;
}
