import templateUrl from './functionParameter.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('functionParameterController', [
        '$scope',
        '$sce',
        function ($scope, $sce) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;

            function $onInit() {
                $scope.valid = true;
                $scope.paramType = $scope.$ctrl.definition.dataType.type;
                $scope.isHidden = $scope.$ctrl.definition.isHidden;

                if ($scope.isHidden) {
                    // if it's a hidden field, there is no need to do any validation
                    // because the value is set programmatically (not by user)
                    $scope.$ctrl.onModelUpdate({
                        value: $scope.$ctrl.definition.defaultValue,
                        validity: true,
                    });
                }

                //fake, as optional doesnt exist, but if this value is required, override and materialize the passed in model
                //value using the default
                if (angular.isDefined($scope.$ctrl.model) && $scope.$ctrl.model !== '') {
                    $scope.modelWithDefaults = $scope.$ctrl.model;
                } else {
                    $scope.modelWithDefaults = angular.copy($scope.$ctrl.definition.defaultValue);
                }

                let tooltip = $scope.$ctrl.definition.description;
                if ($scope.$ctrl.definition.infoUrl) {
                    tooltip +=
                        '<a target="_blank" href="' +
                        $scope.$ctrl.definition.infoUrl +
                        '"> Details</a>';
                }
                if (tooltip) {
                    $scope.tooltipText = $sce.trustAsHtml(tooltip);
                }
            }

            $scope.onTypedValueUpdate = function (value, validity) {
                $scope.$ctrl.model = value;
                $scope.$ctrl.onModelUpdate({
                    value: value,
                    validity: validity,
                });
            };

            $scope.onTypedValueRemoved = function () {
                $scope.$ctrl.onModelValueRemoved();
            };

            $scope.$ctrl.$onChanges = function (changes) {
                // onChanges is fired for any change to component, modified data alone is specified in 'changes' object
                if (!changes.model) {
                    return;
                }

                if (angular.isDefined(changes.model.currentValue)) {
                    $scope.modelWithDefaults = changes.model.currentValue;
                }
            };
        },
    ])
    .component('functionParameter', {
        replace: true,
        controller: 'functionParameterController',
        bindings: {
            model: '<',
            paramName: '<',
            definition: '<',
            plots: '<',
            rule: '<',
            jobInfo: '<',
            disablePicker: '<',
            validationError: '<',
            preSelectedSignal: '<',
            onModelUpdate: '&',
            onModelValueRemoved: '&',
        },
        templateUrl,
    });
