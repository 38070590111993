export default [
    'd3v4',
    'mustache',
    'valueRenderer',
    'kubeDataService',
    'clusterMapVizConfigUtil',
    function (d3v4, mustache, valueRenderer, kubeDataService, clusterMapVizConfigUtil) {
        const COLORING_METHOD = {
            MAP: 'MAP',
            TOP: 'TOP',
        };

        const METRIC_FORMATS = {
            AGGREGATE: 'AGGREGATE',
            ENUM: 'ENUM',
            THRESHOLD: 'THRESHOLD',
        };

        const HOVER_HIGHLIGHT_CONSTANTS = {
            paddingSizePercent: 0.1,
            paddingMin: 2,
            paddingMax: 6,
            minLeafSide: 40,
            maxLeafSide: 80,
            regularBaseLeafSide: 45,
            constrainedBaseLeafSide: 24,
        };

        const DEPTH_TO_COLOR_CLASSES = {
            2: 'node-resource',
            3: 'pod-resource',
            4: 'container-resource',
        };

        return {
            getId,
            getSignalflow,
            getJobPublishLabel,
            processMetricValueForUpdate,
            mergeDataValue,
            getStateColorClass,
            doesThresholdHold,
            getTooltipRowPair,
            getContentSize,
            getBackgroundBox,
            getContainerSize,
            expandCoords,
            sum,
            getContentRelativePosition,
            getRelativePosition,
            transform,
            sortMapItems,
            getScaledSmallestSize,
            getMargin,
            calculateLargestSizes,
            maxInArrays,
            getHighlightShape,
            getZoomLeafShape,
            identifyElementFromOverrides,
        };

        function getId(data, resourceType, template) {
            const idComponents = [];
            for (const component of template) {
                const value = data[component];
                if (!value) {
                    return null;
                }
                idComponents.push(value);
            }
            return resourceType + ':' + idComponents.join(':');
        }

        function getSignalflow(globalFilters = [], metricConfig) {
            const jobOptions = metricConfig.job;
            const varName = jobOptions.varName;
            const signalTextToStream = mustache.render(jobOptions.template, {
                filter: kubeDataService.generateFilterStrings(
                    globalFilters.concat(jobOptions.filters)
                ),
            });

            return `${signalTextToStream}\n${varName}.publish(label="${getJobPublishLabel(
                metricConfig
            )}")`;
        }

        function getJobPublishLabel(metricConfig) {
            return metricConfig.displayName || metricConfig.job.varName;
        }

        function getScaledSmallestSize(scale, ...sizes) {
            return scale ? scale(Math.min(...Object.values(sizes))) : 0;
        }

        function processMetricValueForUpdate(data, metadata, metricConfig) {
            if (!metricConfig || !metricConfig.format) {
                return null;
            }

            if (metricConfig.format === METRIC_FORMATS.AGGREGATE) {
                data[metricConfig.aggregationProperty] = metadata[metricConfig.aggregationProperty];
            }

            return data;
        }

        function sortMapItems(items, defaultSortByKey, fallbackKey) {
            items.sort((item1, item2) => {
                let keyToSort;
                if (
                    defaultSortByKey &&
                    defaultSortByKey in item1.data &&
                    defaultSortByKey in item2.data
                ) {
                    keyToSort = defaultSortByKey;
                } else if (fallbackKey && fallbackKey in item1.data && fallbackKey in item2.data) {
                    keyToSort = fallbackKey;
                }

                if (keyToSort) {
                    const item1Data = item1.data[keyToSort] || 0;
                    const item2Data = item2.data[keyToSort] || 0;
                    if (item1Data === item2Data) {
                        return 0;
                    }
                    return item1Data < item2Data ? -1 : 1;
                }

                if (item1.groupId === item2.groupId) {
                    return 0;
                }
                return item1.groupId < item2.groupId ? -1 : 1;
            });
        }

        function mergeDataValue(target, update, metricConfig) {
            switch (metricConfig.format) {
                case METRIC_FORMATS.AGGREGATE: {
                    if (!target.data.value || !(target.data.value instanceof Set)) {
                        target.data.value = new Set();
                    }

                    const valueSet = target.data.value;
                    const aggregationValue = update[metricConfig.aggregationProperty];

                    if (metricConfig.map[aggregationValue]) {
                        if (update.value === metricConfig.targetValue) {
                            valueSet.delete(metricConfig.map._default);
                            valueSet.add(metricConfig.map[aggregationValue]);
                        } else {
                            valueSet.delete(metricConfig.map[aggregationValue]);
                        }
                    }

                    if (valueSet.size === 0) {
                        valueSet.add(metricConfig.map._default);
                    }

                    break;
                }

                case METRIC_FORMATS.ENUM: {
                    const enumKey = metricConfig.enum[update.value];
                    target.data.value = metricConfig.map[enumKey] || metricConfig.map._default;
                    break;
                }

                case METRIC_FORMATS.THRESHOLD: {
                    if (_.isNumber(update.value)) {
                        target.data.value = doesThresholdHold(metricConfig.thresholds, update.value)
                            ? metricConfig.map.INSIDE
                            : metricConfig.map.OUTSIDE;
                    } else {
                        target.data.value = metricConfig.map._default;
                    }
                    break;
                }

                default:
                    target.data.value = update.value;
            }

            return target;
        }

        function getStateColorClass(data, colorBy, depth) {
            if (!data || _.isNil(data.value)) {
                return;
            }

            let severityClass;
            if (colorBy.method === COLORING_METHOD.TOP) {
                if (!_.isSet(data.value)) {
                    return;
                }

                let mostSevere = -1;
                for (const valueObject of data.value) {
                    const severity = valueObject.severity;
                    if (severity > mostSevere) {
                        mostSevere = severity;
                        severityClass = colorBy.stateToSeverity[valueObject.key];
                    }
                }
            } else if (colorBy.method === COLORING_METHOD.MAP) {
                severityClass = colorBy.stateToSeverity[data.value.key];
            }

            let finalColorClass = severityClass || clusterMapVizConfigUtil.severityClasses.default;

            // Only care about node, pod and container depths
            if (depth >= 2) {
                finalColorClass += ` ${finalColorClass}-${DEPTH_TO_COLOR_CLASSES[depth]}`;
            }
            return finalColorClass;
        }

        function getTooltipRowPair(colorByMetric, value) {
            const tooltipRow = {
                displayName: colorByMetric.displayName || colorByMetric.valueLabel,
            };

            if (colorByMetric.valueType && colorByMetric.valueType === 'CUSTOM') {
                if (colorByMetric.format === METRIC_FORMATS.AGGREGATE) {
                    tooltipRow.values = [...value].map((v) => v.displayValue);
                } else if (
                    colorByMetric.format === METRIC_FORMATS.ENUM ||
                    colorByMetric.format === METRIC_FORMATS.THRESHOLD
                ) {
                    tooltipRow.value = value.displayValue;
                }
            } else if (colorByMetric.format || colorByMetric.valueFormat) {
                tooltipRow.value = valueRenderer(
                    value,
                    colorByMetric.format || colorByMetric.valueFormat
                );
            } else {
                tooltipRow.value = value;
            }

            return tooltipRow;
        }

        function doesThresholdHold({ min, max }, value) {
            return (min || -Infinity) <= value && (max || Infinity) > value;
        }

        /**** Layout Helper Functions ****/
        // Layout system assumed for following Helper functions
        // [Container Boundary] <-Margin-> [Background Boundary] <-Padding-> [Content Boundary] <-Inner Content Container ->
        // Width  == Sum ( Margin, Padding, Inner Content )
        // Height == Sum ( Margin, Padding, Inner Content, header and footer )

        function getContentRelativePosition(config, ignoreMarginTop, showSecondaryHeader = true) {
            const { sizeConfig } = config;
            const margin = getMargin(sizeConfig, config);

            const x = sum(margin.x, sizeConfig.padding);
            const y = sum(
                ignoreMarginTop ? 0 : margin.y,
                sizeConfig.padding,
                sizeConfig.header && sizeConfig.header.primary,
                showSecondaryHeader && getSecondaryHeaderSpace(sizeConfig)
            );

            return { x, y };
        }

        function getRelativePosition(config, index) {
            const x = config.x0 === undefined ? (index % config.columns) * config.width : config.x0;
            const y =
                config.y0 === undefined
                    ? Math.floor(index / config.columns) * config.height
                    : config.y0;
            return { x, y };
        }

        function transform(x, y, k = 1) {
            return d3v4.zoomIdentity.translate(x, y).scale(k);
        }

        function getSecondaryHeaderSpace(config) {
            return config && config.header && config.header.secondary
                ? sum(config.header.secondary, config.padding)
                : 0;
        }

        function getBackgroundBox(
            containerSize,
            containerConfig,
            ignoreMarginTop,
            keepSecondaryHeaderSpace = true
        ) {
            const margin = getMargin(containerConfig, containerSize);

            return {
                x: margin.x,
                y: sum(
                    containerConfig.header && containerConfig.header.primary,
                    ignoreMarginTop ? 0 : margin.y
                ),
                width: sum(containerSize.width, -2 * margin.x),
                height: sum(
                    containerSize.height,
                    -(containerConfig.header && containerConfig.header.primary),
                    -(!keepSecondaryHeaderSpace && getSecondaryHeaderSpace(containerConfig)) -
                        2 * margin.y
                ),
            };
        }

        function getContentSize(containerSize, containerConfig) {
            const bg = getBackgroundBox(containerSize, containerConfig);

            return {
                width: sum(bg.width, -2 * containerConfig.padding),
                height: sum(
                    bg.height,
                    -2 * containerConfig.padding,
                    -getSecondaryHeaderSpace(containerConfig),
                    -containerConfig.footer
                ),
            };
        }

        function getContainerSize(contentSize, containerConfig) {
            const margin = getMargin(containerConfig);

            return {
                width: sum(contentSize.width, 2 * containerConfig.padding, 2 * margin.x),
                height: sum(
                    contentSize.height,
                    containerConfig.header && containerConfig.header.primary,
                    getSecondaryHeaderSpace(containerConfig),
                    containerConfig.footer,
                    2 * containerConfig.padding,
                    2 * margin.y
                ),
            };
        }

        function getMargin(sizeConfig, layout) {
            const defaultMargin = sizeConfig.margin || 0;
            return (layout && layout.margin) || { x: defaultMargin, y: defaultMargin };
        }

        function expandCoords(size) {
            if (_.isNumber(size.width) && _.isNumber(size.height)) {
                size.x1 = size.x0 + size.width;
                size.y1 = size.y0 + size.height;
            } else if (_.isNumber(size.x1) && _.isNumber(size.y1)) {
                size.width = size.x1 - size.x0;
                size.height = size.y1 - size.y0;
            }

            return size;
        }

        function sum(...args) {
            if (!args) {
                return 0;
            }
            let sum = 0;
            for (let i = args.length - 1; i >= 0; i--) {
                sum += args[i] || 0;
            }
            return sum;
        }

        function calculateLargestSizes(resource) {
            let largestSizes = [];

            if (resource.children && resource.children.length) {
                const largestSizesFromSubLevel = [];
                for (const child of resource.children) {
                    const largestSizeFromSubLevel = calculateLargestSizes(child);
                    largestSizesFromSubLevel.push(largestSizeFromSubLevel);
                }
                largestSizes = maxInArrays(largestSizesFromSubLevel);
                largestSizes.unshift(resource.children.length);
            }

            resource.largestSizes = largestSizes;
            return largestSizes;
        }

        function maxInArrays(arrays) {
            if (!arrays || !arrays.length) {
                return;
            }
            const maxLength = arrays.reduce(
                (maxLength, array) => Math.max(maxLength, array.length),
                0
            );
            const max = [];
            for (let i = 0; i < maxLength; i++) {
                max.push(
                    arrays.reduce((max, array) => Math.max(max, array[i] || -Infinity), -Infinity)
                );
            }
            return max;
        }

        function getHighlightShapeExtents(resourceBackgroundRect) {
            const resourceExtents = getResourceBackgroundShapeParams(resourceBackgroundRect);

            const { x, y, height, width, strokeWidth, borderRadius, resourceSide } =
                resourceExtents;
            const { paddingSizePercent, paddingMin, paddingMax, minLeafSide, maxLeafSide } =
                HOVER_HIGHLIGHT_CONSTANTS;

            const padding = Math.min(
                paddingMax,
                Math.max(paddingMin, strokeWidth, paddingSizePercent * resourceSide)
            );

            const insideRadius = borderRadius + strokeWidth / 2;
            const outsideRadius = insideRadius + padding;

            const containerMinSize = Math.min(height, width);
            const hasSizeConstraints = containerMinSize < minLeafSide;

            // Leaf side at max is one-third of resource side,
            // any bigger will cover up unusually large part of the resource background.
            const leafSide = Math.min(
                maxLeafSide,
                Math.max(minLeafSide, outsideRadius, resourceSide / 3)
            );

            return {
                ...resourceExtents,
                padding,
                insideRadius,
                outsideRadius,
                leafSide,
                containerMinSize,
                hasSizeConstraints,
                startX: x - padding,
                startY: y - padding,
                endX: x + width + padding,
                endY: y + height + padding,
            };
        }

        function getHighlightShape(resourceBackgroundRect) {
            const {
                startX,
                startY,
                endX,
                endY,
                padding,
                insideRadius,
                outsideRadius,
                hasSizeConstraints,
            } = getHighlightShapeExtents(resourceBackgroundRect);

            const highlightPath = `
          M ${startX + outsideRadius} ${startY}
          H ${endX - outsideRadius}
          A ${outsideRadius} ${outsideRadius} 0 0 1 ${endX} ${startY + outsideRadius}
          V ${endY - outsideRadius}
          A ${outsideRadius} ${outsideRadius} 0 0 1 ${endX - outsideRadius} ${endY}
          H ${startX + outsideRadius}
          A ${outsideRadius} ${outsideRadius} 0 0 1 ${startX} ${endY - outsideRadius}
          V ${startY + outsideRadius}
          A ${outsideRadius} ${outsideRadius} 0 0 1 ${startX + outsideRadius} ${startY}
          H ${startX + outsideRadius}
          v ${padding}
          H ${startX + outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${startX + padding} ${startY + outsideRadius}
          V ${endY - outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${startX + outsideRadius} ${endY - padding}
          H ${endX - outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${endX - padding} ${endY - outsideRadius}
          V ${startY + outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${endX - outsideRadius} ${startY + padding}
          H ${startX + outsideRadius}
          Z`;

            return { highlightPath, hasSizeConstraints };
        }

        function getZoomLeafShape(resourceBackgroundRect) {
            const {
                startX,
                startY,
                endX,
                endY,
                padding,
                insideRadius,
                outsideRadius,
                containerMinSize,
                hasSizeConstraints,
                leafSide,
            } = getHighlightShapeExtents(resourceBackgroundRect);

            let zoomLeafPath;
            if (hasSizeConstraints) {
                zoomLeafPath = `
          M ${startX + outsideRadius} ${startY + padding}
          A ${insideRadius} ${insideRadius} 0 0 0 ${startX + padding} ${startY + outsideRadius}
          V ${endY - outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${startX + outsideRadius} ${endY - padding}
          H ${endX - outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${endX - padding} ${endY - outsideRadius}
          V ${startY + outsideRadius}
          A ${insideRadius} ${insideRadius} 0 0 0 ${endX - outsideRadius} ${startY + padding}
          H ${startX + outsideRadius}
          Z`;
            } else {
                zoomLeafPath = `
        M ${startX + outsideRadius} ${startY}
        H ${startX + leafSide}
        v ${padding}
        h -${padding}
        L ${startX + padding} ${startY + leafSide - padding}
        v ${padding}
        h -${padding}
        V ${startY + outsideRadius}
        A ${outsideRadius} ${outsideRadius} 0 0 1 ${startX + outsideRadius} ${startY}
        Z`;
            }

            const baseLeafSide = hasSizeConstraints
                ? HOVER_HIGHLIGHT_CONSTANTS.constrainedBaseLeafSide
                : HOVER_HIGHLIGHT_CONSTANTS.regularBaseLeafSide;

            const scale = Math.min(leafSide, containerMinSize) / baseLeafSide;
            const containerLength = hasSizeConstraints ? 2 * padding + containerMinSize : leafSide;

            return {
                startX,
                startY,
                scale,
                containerLength,
                hasSizeConstraints,
                zoomLeafPath,
            };
        }

        function getResourceBackgroundShapeParams(element) {
            const borderRadius = parseFloat(
                element.attr('rx') || element.style('border-radius') || 0
            );
            const strokeSet = element.style('stroke') !== 'none';
            const strokeWidth = strokeSet ? parseFloat(element.style('stroke-width') || 0) : 0;
            let { x, y, height, width } = element.node().getBBox();
            // Actual x, y, height, width needs to account for the stroke-width, which acts just like border-width.
            x -= strokeWidth / 2;
            y -= strokeWidth / 2;
            height += strokeWidth;
            width += strokeWidth;
            const resourceSide = Math.min(height, width);
            return { x, y, height, width, strokeWidth, borderRadius, resourceSide };
        }

        function identifyElementFromOverrides(
            depthClasses,
            renderingParent,
            hierarchicalKeys,
            overrides,
            currentDepth = 0
        ) {
            if (
                !depthClasses ||
                !renderingParent ||
                _.isEmpty(hierarchicalKeys) ||
                _.isEmpty(overrides)
            ) {
                return null;
            }

            const overrideMap = {};
            overrides.forEach(
                ({ property, propertyValue }) => (overrideMap[property] = propertyValue)
            );

            let element =
                currentDepth > 0
                    ? renderingParent.selectAll(`.${depthClasses[currentDepth]}`)
                    : renderingParent;
            for (let keyDepth = 0; keyDepth < hierarchicalKeys.length; keyDepth++) {
                if (_.isEmpty(overrideMap)) {
                    break;
                }

                const key = hierarchicalKeys[keyDepth];
                if (!key || !overrideMap[key]) {
                    continue;
                }

                if (keyDepth > currentDepth) {
                    element = element.selectAll(`.${depthClasses[keyDepth]}`);
                }

                const value = overrideMap[key];
                element = element.filter(
                    (d) =>
                        d.data[key] ===
                        (Array.isArray(value) && value.length === 1 ? value[0] : value)
                );

                delete overrideMap[key];
            }

            return _.isEmpty(overrideMap) && element.size() === 1 ? element : null;
        }
    },
];
