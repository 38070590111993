import templateUrl from './dashboardVariable.tpl.html';
import variableTooltipTemplateUrl from './variableTooltipTemplate.tpl.html';

angular
    .module('signalview.variables')

    .directive('dashboardVariable', [
        '$timeout',
        'sourceFilterService',
        'dashboardVariablesService',
        'userAnalytics',
        'dashboardVariableUtils',
        'dashboardVariableSuggestUtils',
        function (
            $timeout,
            sourceFilterService,
            dashboardVariablesService,
            userAnalytics,
            dashboardVariableUtils,
            dashboardVariableSuggestUtils
        ) {
            return {
                restrict: 'E',
                templateUrl,
                scope: {
                    variables: '=',
                    charts: '=',
                    index: '@',
                    values: '=',
                    onPillEnter: '=',
                    isHierarchical: '=', // if a set of dashboard Variables is hierarchical and ordered from high level to low level,
                    getCurrentTimeRange: '<?',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.skipAutoFocus = true;
                        $scope.variableTooltipTemplateUrl = variableTooltipTemplateUrl;
                    },
                ],
                link: function ($scope, elem) {
                    $scope.filtersToApply = null;
                    $scope.variable = $scope.variables[$scope.index];

                    $scope.cachedValue = '';
                    $scope.inFocus = false;

                    // Suggest function passed into the typeahead dropdown
                    $scope.suggest = function () {
                        let preferredSuggestions;
                        if (
                            $scope.variable.preferredSuggestionsOverride &&
                            $scope.variable.preferredSuggestionsOverride.length
                        ) {
                            preferredSuggestions = $scope.variable.preferredSuggestionsOverride;
                        } else {
                            preferredSuggestions = $scope.variable.preferredSuggestions || [];
                        }

                        // if hierarchical, only use higher level filters to limit suggestions
                        const variables = $scope.isHierarchical
                            ? $scope.variables.slice(0, $scope.index)
                            : $scope.variables;

                        let start = 0;
                        let end = 0;

                        if ($scope.getCurrentTimeRange) {
                            const timeRange = $scope.getCurrentTimeRange();
                            start = timeRange.start;
                            end = timeRange.end;
                        }

                        return dashboardVariableSuggestUtils.suggest({
                            restricted: $scope.variable.restricted,
                            suggestType: 'value',
                            charts: $scope.charts,
                            partialInput: $scope.typedValue,
                            property: $scope.variable.property,
                            preferredSuggestions,
                            allowWildCards: !$scope.variable.required,
                            variables,
                            start,
                            end,
                        });
                    };

                    initVariable();

                    setValueInternal('');

                    initializeFiltersToApply($scope.values[$scope.index]);
                    $scope.inFiltersContext = false;
                    $scope.numFilters = 0;

                    $scope.$on('React:$routeUpdate', checkOverride);

                    $scope.$watch('variables', function () {
                        $scope.variable = $scope.variables[$scope.index];
                        checkOverride();
                    });

                    $scope.$watchCollection('values', function () {
                        const displayNames = {};
                        if (!$scope.values) return;
                        $scope.values.forEach(function (v, i) {
                            displayNames[i] = sourceFilterService.getDisplayNameForFilters(v);
                        });
                        $scope.displayNames = displayNames;
                    });

                    checkOverride();

                    $scope.hasFilter = function (valueToCheck) {
                        const propertyValue = $scope.filtersToApply || null;
                        if (angular.isArray(propertyValue)) {
                            return propertyValue.indexOf(valueToCheck) !== -1;
                        } else {
                            return propertyValue && propertyValue === valueToCheck;
                        }
                    };

                    // The results in the typeaheadDropDownMultiSelect are objects representing dashboard
                    // variables, not just the value string, so we need to provide this wrapper
                    $scope.isSelected = function ({ value }) {
                        return $scope.hasFilter(value);
                    };

                    // Called when the user clicks the 'x' on the blue pill when we are not in dropdown mode
                    $scope.remove = function () {
                        $scope.resettable =
                            $scope.values[$scope.index] && !$scope.variable.required;
                        if ($scope.onPillEnter) {
                            $scope.onPillEnter(null);
                        }
                        clear();
                        focus();
                    };

                    // Called when the user clicks on the dashboard variable pill, puts us into dropdown mode
                    $scope.edit = function () {
                        const tmp = $scope.values[$scope.index]; // this is the applied variable
                        initializeFiltersToApply(tmp);
                        clear();
                        setValueInternal(tmp);
                        $scope.cachedValue = tmp;
                        focus();
                    };

                    // Reset function passed into the typeahead dropdown
                    $scope.reset = function () {
                        $scope.inFocus = false;
                        if (!$scope.values[$scope.index]) {
                            if (!$scope.variable.required && $scope.cachedValue && !$scope.value) {
                                $scope.resettable = true;
                            } else {
                                $scope.values[$scope.index] = $scope.cachedValue;
                            }
                        }
                        setValueInternal($scope.values[$scope.index]);
                        $scope.cachedValue = '';
                        if ($scope.resettable) {
                            $scope.resettable = false;
                            dashboardVariablesService.setVariableOverride(
                                $scope.variable.alias,
                                $scope.variable.property,
                                '',
                                $scope.variable.applyIfExists
                            );
                        }
                    };

                    // select function passed into typeahead dropdown
                    $scope.select = function (suggestion) {
                        let value = suggestion.value;
                        const emptySelect = !value;
                        if (emptySelect) {
                            if ($scope.filtersToApply) {
                                value = angular.copy($scope.filtersToApply);
                            }
                        }
                        $scope.filtersToApply = null;
                        $scope.numFilters = 0;
                        $scope.inFocus = false;
                        setValue(value);
                        if (!emptySelect) {
                            elem.find('input')[0].blur();
                        }

                        const variableValueToCheck = dashboardVariableUtils.getVariableDefaultValue(
                            $scope.variable
                        );

                        if (
                            dashboardVariableUtils.semanticOverrideEqualityCheck(
                                variableValueToCheck,
                                value
                            )
                        ) {
                            dashboardVariablesService.setVariableOverride(
                                $scope.variable.alias,
                                $scope.variable.property,
                                null,
                                $scope.variable.applyIfExists
                            );
                        } else {
                            dashboardVariablesService.setVariableOverride(
                                $scope.variable.alias,
                                $scope.variable.property,
                                value,
                                $scope.variable.applyIfExists
                            );
                        }
                        userAnalytics.event('click', 'dashboard-variable-edit');
                    };

                    // Called when the 'x' in a preexisting pill is clicked when we are in dropdown mode
                    $scope.removeFilter = function (filter) {
                        removeFilter(filter);
                        $scope.setInFiltersContext(false);
                    };

                    // Puts us into value selection mode after clicking the pill(s) that already exist when we enter dropdown mode
                    $scope.editFilter = function (filter) {
                        $scope.typedValue = filter;
                        removeFilter(filter);
                        $scope.setInFiltersContext(false);
                    };

                    $scope.resetAll = function () {
                        initializeFiltersToApply($scope.cachedValue);
                    };

                    // Ennumerates filters for each variable
                    $scope.getFiltersList = function () {
                        const filters = $scope.filtersToApply || null;

                        if (angular.isArray(filters)) {
                            return filters;
                        } else {
                            return [filters];
                        }
                    };

                    // toggleSelection function passed into typeahead dropdown
                    $scope.toggleSelection = function (suggestion) {
                        const value = suggestion.value;
                        const defaultHints = /^choose /i;

                        // Remove the default hints from the filter list
                        if (
                            $scope.variable.value &&
                            $scope.variable.value.length > 0 &&
                            defaultHints.test($scope.variable.value[0])
                        ) {
                            removeFilter($scope.variable.value[0]);
                        }

                        if ($scope.hasFilter(value)) {
                            removeFilter(value);
                        } else {
                            addFilter(value);
                        }
                    };

                    $scope.setInFiltersContext = function (val) {
                        $scope.inFiltersContext = val;
                    };

                    // removeLastFilter function passed into typeahead dropdown
                    $scope.removeLastFilter = function () {
                        const filters = $scope.getFiltersList();
                        if (filters && filters.length) {
                            $scope.removeFilter(filters[filters.length - 1]);
                        }
                    };

                    $scope.isDisplayableValue = function (value) {
                        return value && value.length;
                    };

                    ////////////////////////
                    // Internal Functions //
                    ////////////////////////

                    function setValueInternal(value) {
                        $scope.value = value;
                        $scope.typedValue = angular.isArray(value) ? '' : value;
                        setFiltersToApply(value);
                    }

                    function setFiltersToApply(src) {
                        let propertyValue = angular.copy(src);

                        if (propertyValue && angular.isArray(propertyValue)) {
                            propertyValue = propertyValue.filter(function (filter) {
                                return filter;
                            });

                            if (propertyValue.length < 2) {
                                propertyValue = propertyValue[0] || '';
                            }
                        }

                        $scope.filtersToApply = angular.copy(propertyValue);
                    }

                    function arrayifyFiltersToApply() {
                        if (!angular.isArray($scope.filtersToApply)) {
                            $scope.filtersToApply = [$scope.filtersToApply];
                        }
                    }

                    function initializeFiltersToApply(val) {
                        const src = angular.copy(val);

                        if (!$scope.filtersToApply) {
                            setFiltersToApply(src);
                        }
                        setNumFilters();

                        if (
                            $scope.filtersToApply &&
                            angular.isArray($scope.filtersToApply) &&
                            $scope.filtersToApply.length < 2
                        ) {
                            $scope.filtersToApply = $scope.filtersToApply[0] || '';
                        }
                    }

                    // Determines the number of preexisting filters when we enter dropdown mode
                    function setNumFilters() {
                        const filters = $scope.filtersToApply || null;
                        if (angular.isArray(filters)) {
                            $scope.numFilters = filters.length;
                        } else {
                            $scope.numFilters = 0;
                        }
                    }

                    function checkOverride() {
                        const currentValue = dashboardVariableUtils.getVariableDefaultValue(
                            $scope.variable
                        );
                        const currentAlias = $scope.variable.alias;
                        const valueIsRequired = $scope.variable.required;

                        const urlOverrides =
                            dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
                        const matchingOverride =
                            urlOverrides.find(function (override) {
                                return (
                                    override.alias === currentAlias &&
                                    ((override.value !== null && override.value !== undefined) ||
                                        !valueIsRequired)
                                );
                            }) || {};

                        const newValue = matchingOverride.value;

                        if (!angular.isDefined(newValue)) {
                            setValue(currentValue);
                        } else if (newValue.toString() || !valueIsRequired) {
                            setValue(newValue);

                            if (!newValue) {
                                $scope.filtersToApply = '';
                            }
                        } else if (newValue.toString() === '' && valueIsRequired) {
                            dashboardVariablesService.setVariableOverride(
                                currentAlias,
                                $scope.variable.property,
                                currentValue,
                                $scope.variable.applyIfExists
                            );
                        }
                    }

                    function setValue(value) {
                        $scope.resettable = false;
                        $scope.cachedValue = '';
                        $scope.values[$scope.index] = value;
                        setValueInternal(value);
                    }

                    function focus() {
                        $timeout(function () {
                            elem.find('.sfx-input')[0].select();
                            $scope.inFocus = true;
                        }, 0);
                    }

                    function clear() {
                        $scope.cachedValue = $scope.variable.required
                            ? $scope.cachedValue || $scope.values[$scope.index]
                            : '';
                        $scope.values[$scope.index] = '';
                        setValueInternal('');
                    }

                    function removeFilter(valueToRemove) {
                        const propertyValue = $scope.filtersToApply || null;
                        if (angular.isArray(propertyValue)) {
                            const index = propertyValue.indexOf(valueToRemove);
                            if (index !== -1) {
                                $scope.filtersToApply.splice(index, 1);
                                const value = $scope.filtersToApply;
                                if (!value.length) {
                                    $scope.filtersToApply = '';
                                }
                            }
                        } else if (propertyValue && propertyValue === valueToRemove) {
                            $scope.filtersToApply = '';
                        }
                        setNumFilters();
                    }

                    function addFilter(value) {
                        if (!value) {
                            return;
                        }

                        if (!$scope.filtersToApply) {
                            setFiltersToApply(value);
                            arrayifyFiltersToApply();
                        }

                        if (!$scope.hasFilter(value)) {
                            if (angular.isArray($scope.filtersToApply)) {
                                $scope.filtersToApply.push(value);
                            } else {
                                if ($scope.filtersToApply) {
                                    $scope.filtersToApply = [$scope.filtersToApply, value];
                                } else {
                                    $scope.filtersToApply = [value];
                                }
                            }
                        }

                        setNumFilters();
                    }

                    function initVariable() {
                        const autoSelectValue = $scope.variable.autoSelect;
                        if (autoSelectValue && $scope.variable.value.length === 0) {
                            $scope.suggest().then((suggestions) => {
                                if (suggestions && suggestions.length > 0) {
                                    $scope.select(suggestions[0]);
                                }
                            });
                        }
                    }
                },
            };
        },
    ]);
