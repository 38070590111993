export default [
    'API_URL',
    'auth',
    '$log',
    'PRODUCT_NAME',
    function (API_URL, auth, $log, PRODUCT_NAME) {
        const resourceEndpoint = API_URL + '/v2/smart-gateway/download/';

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';

        function downloadBlob(blob, fileName) {
            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
        }

        function downloadSmartGateway(version, successCallBack, failureCallBack) {
            const request = new XMLHttpRequest();
            request.open('GET', resourceEndpoint + version, true);
            request.setRequestHeader(auth.HEADER, auth.authToken());
            request.responseType = 'blob';

            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        successCallBack();
                    } else {
                        failureCallBack();
                    }
                }
            };

            request.onload = function () {
                if (request.status === 200) {
                    const fileName = request.getResponseHeader('X-SF-Filename');
                    downloadBlob(request.response, fileName);
                } else {
                    $log.error(
                        `Unable to download the ${PRODUCT_NAME} Smart Gateway v` + version,
                        request
                    );
                }
            };

            request.send(null);
        }

        return {
            downloadSmartGateway,
        };
    },
];
