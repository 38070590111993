import { useState, useEffect, useCallback } from 'react';
import { AngularInjector } from '../../../common/AngularUtils';

/**
 * This hook is used for Navigator selector component
 * @returns It will return the data link
 */
const useNavigatorList = () => {
    const [allNavigators, setAllNavigators] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigatorModes = AngularInjector.instantiate('navigatorModes');

    const fetchNavigatorsList = useCallback(async () => {
        setLoading(true);
        navigatorModes
            .getModes()
            .then((response) => {
                setAllNavigators(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.message) {
                    throw new Error(`HTTP error! status: ${err.status}`);
                }
            });
    }, [navigatorModes]);

    useEffect(() => {
        const abortController = new AbortController();
        fetchNavigatorsList();
        return () => abortController.abort();
    }, [fetchNavigatorsList]);

    return { isLoading, allNavigators };
};

export default useNavigatorList;
