import templateUrl from './maxDelayOverridePill.tpl.html';

export default {
    bindings: {
        maxDelayOverride: '<',
        onClick: '&?',
    },
    templateUrl,
    controller: [
        '$scope',
        'msToStrFilter',
        function ($scope, msToStrFilter) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;

            function $onInit() {
                updateDisplay();
            }

            function getDisplay(maxDelayMs) {
                if (maxDelayMs === 0) {
                    return 'Auto';
                }
                return msToStrFilter(maxDelayMs);
            }

            function updateDisplay() {
                $scope.maxDelayDisplay = getDisplay($ctrl.maxDelayOverride);
            }

            $scope.$watch('$ctrl.maxDelayOverride', updateDisplay);

            $scope.onClick = function () {
                if ($ctrl.onClick) {
                    $ctrl.onClick();
                }
            };
        },
    ],
};
