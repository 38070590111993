angular.module('signalview.catalog').service('isEphemeralService', [
    '$rootScope',
    'organizationService',
    function ($rootScope, organizationService) {
        let orgPreferencePromise;

        $rootScope.$on('current organization changed', function () {
            orgPreferencePromise = null;
        });

        function isEphemeral(key) {
            if (!orgPreferencePromise) {
                orgPreferencePromise = organizationService.getOrgSettings().then(function (prefs) {
                    if (!prefs) {
                        return {};
                    }
                    return prefs;
                });
            }
            return orgPreferencePromise.then(function (pref) {
                return {
                    isDieQuickly: (pref.sf_dieQuicklyDims || []).indexOf(key) > -1,
                    isEphemeral: (pref.sf_ephemeralDims || []).indexOf(key) > -1,
                };
            });
        }

        return isEphemeral;
    },
]);
