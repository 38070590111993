export const jobRerunProgressDonut = [
    'CHART_DISPLAY_EVENTS',
    'd3',
    function (CHART_DISPLAY_EVENTS, d3) {
        return {
            scope: {
                height: '@?',
                width: '@?',
            },
            template: '<div class="jobrerundonutarea"></div>',
            link: function ($scope, elem) {
                const width = $scope.width;
                const height = $scope.height;
                const twoPi = 2 * Math.PI;

                const arc = d3.svg.arc().startAngle(0).innerRadius(14).outerRadius(20);

                const svg = d3
                    .select(angular.element('.jobrerundonutarea', elem)[0])
                    .append('svg')
                    .attr('width', width)
                    .attr('height', height)
                    .append('g')
                    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

                const meter = svg.append('g').attr('class', 'progress-meter');

                meter.append('path').attr('class', 'background').attr('d', arc.endAngle(twoPi));

                const foreground = meter.append('path').attr('class', 'foreground');

                foreground.attr('d', arc.endAngle(twoPi * 0));

                function arcTween() {
                    const i = d3.interpolate(0, twoPi);
                    return function (t) {
                        return arc.endAngle(i(t))();
                    };
                }

                function restartProgress() {
                    foreground.transition().duration(5000).attrTween('d', arcTween);
                }

                $scope.$on(CHART_DISPLAY_EVENTS.RESTART_PROGRESS, restartProgress);

                restartProgress();
            },
        };
    },
];
