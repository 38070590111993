import templateUrl from './calendarWindow.tpl.html';

export const calendarWindow = {
    templateUrl,
    bindings: {
        plot: '=',
        fnConfig: '=',
        calendarWindowTimezone: '=',
        isBlockVisible: '=',
    },
    controller: [
        '$scope',
        'moment',
        'calendarWindowUtil',
        'currentUser',
        'userAnalytics',
        function ($scope, moment, calendarWindowUtil, currentUser, userAnalytics) {
            const echoFn = function (input) {
                return input;
            };

            const ctrl = this;
            const GA_ACTION_NAME = 'calendar-window';
            ctrl.util = calendarWindowUtil;
            ctrl.cycleOptionsMap = {
                quarter: {
                    prefix: 'First quarter starting in',
                    startOfCycleOptions: calendarWindowUtil.getMonthNames(),
                    mappingFn: echoFn,
                },
                month: {
                    prefix: 'Starting on the',
                    suffix: 'of the month',
                    startOfCycleOptions: calendarWindowUtil.getMonthsInCustomFormat(),
                    mappingFn: calendarWindowUtil.convertCustomDayToOrdinalFormat,
                },
                week: {
                    prefix: 'Starting on',
                    startOfCycleOptions: calendarWindowUtil.getWeekdayNames(),
                    mappingFn: echoFn,
                },
                day: {
                    prefix: 'Starting at',
                    startOfCycleOptions: calendarWindowUtil.getHoursInCustomFormat(),
                    mappingFn: calendarWindowUtil.convertCustomHour,
                },
                hour: {
                    prefix: 'Starting at',
                    suffix: 'min of the hour',
                    mappingFn: echoFn,
                },
            };
            ctrl.cycleNames = calendarWindowUtil.getValidCycleNames();

            ctrl.$onInit = $onInit;
            ctrl.updateOnCycleLengthChange = updateOnCycleLengthChange;
            ctrl.onSelectHandler = onSelectHandler;
            ctrl.onTimeShiftChange = onTimeShiftChange;
            ctrl.updateTimezone = updateTimezone;
            ctrl.onHidePartialChange = onHidePartialChange;

            ctrl.sfxTimezone = {
                callback: ctrl.onSelectHandler,
            };

            function $onInit() {
                ctrl.shouldShowTimezoneMessage = false;
                ctrl.currentCycleUponOpen = ctrl.fnConfig.options.cycle;
                ctrl.fnConfig.options.cycle = ctrl.fnConfig.options.cycle || 'month';
                ctrl.fnConfig.options.hidePartial =
                    typeof ctrl.fnConfig.options.hidePartial === 'boolean'
                        ? ctrl.fnConfig.options.hidePartial
                        : true;
                ctrl.fnConfig.options.minuteOfHour =
                    ctrl.fnConfig.options.minuteOfHour ||
                    calendarWindowUtil.getDefaultMinuteValue();

                ctrl.updateTimezone();

                ctrl.updateOnCycleLengthChange();

                // When the timezone changes in the chart options, which is passed in,
                // go ahead and reflect that in the timezone picker inside the calendar
                // window analytics transformation block
                $scope.$watch('$ctrl.calendarWindowTimezone', function (nval, oval) {
                    if (!angular.equals(nval, oval)) {
                        ctrl.fnConfig.options.timezone = nval;
                        ctrl.sfxTimezone.query = nval;
                    }
                });

                $scope.$watch('$ctrl.isBlockVisible', function (nval, oval) {
                    if (!angular.equals(nval, oval)) {
                        if (nval === false) {
                            // No need to show timezone message once user closes the analytics
                            // block
                            ctrl.shouldShowTimezoneMessage = false;
                            // Propagate change to the plot builder
                            // so the timezone is set in chart options
                            $scope.$emit(
                                'setCalendarWindowTimezoneFromAnalytics',
                                ctrl.fnConfig.options.timezone
                            );

                            if (ctrl.currentCycleUponOpen !== ctrl.fnConfig.options.cycle) {
                                // Propagate cycle length change up so
                                // the chart window can be optimized
                                $scope.$emit(
                                    'setCalendarWindowCycleLength',
                                    ctrl.fnConfig.options.cycle
                                );
                                userAnalytics.event(
                                    GA_ACTION_NAME,
                                    'cycle-option-selected-' + ctrl.fnConfig.options.cycle
                                );
                            }
                            ctrl.currentCycleUponOpen = null;
                        } else {
                            // Once the user opens the analytics block, save the current cycle
                            // to check if it has changed upon close
                            ctrl.currentCycleUponOpen = ctrl.fnConfig.options.cycle;
                        }
                    }
                });
            }

            function updateStartOfCycleOptions(curr) {
                if (curr.startOfCycleOptions) {
                    ctrl.startOfCycleOptions = curr.startOfCycleOptions;
                    if (ctrl.startOfCycleOptions.indexOf(ctrl.fnConfig.options.cycleStart) === -1) {
                        ctrl.fnConfig.options.cycleStart = ctrl.startOfCycleOptions[0];
                    }
                }
            }

            function updateOnCycleLengthChange() {
                const currentCycleOptions = ctrl.cycleOptionsMap[ctrl.fnConfig.options.cycle];
                updateStartOfCycleOptions(currentCycleOptions);
                ctrl.startOfCycleTextPrefix = currentCycleOptions.prefix;
                ctrl.startOfCycleTextSuffix = currentCycleOptions.suffix;
            }

            function onSelectHandler(toSave) {
                ctrl.fnConfig.options.timezone = toSave;
                ctrl.shouldShowTimezoneMessage = true;
                userAnalytics.event('timezone-changed', 'calendar-window-' + toSave);
            }

            function onTimeShiftChange() {
                // Reset the shift cycle count to default when user unchecks the
                // time shift checkbox
                if (!ctrl.fnConfig.options.isTimeShift) {
                    ctrl.fnConfig.options.shiftCycleCount = 1;
                }
                const timeShiftOption = ctrl.fnConfig.options.isTimeShift
                    ? 'selected'
                    : 'unselected';
                userAnalytics.event(GA_ACTION_NAME, 'time-shift-' + timeShiftOption);
            }

            function onHidePartialChange() {
                if (!ctrl.fnConfig.options.hidePartial) {
                    ctrl.fnConfig.options.isTimeShift = false;
                    ctrl.fnConfig.options.shiftCycleCount = 1;
                }
                const hidePartialOption = ctrl.fnConfig.options.hidePartial
                    ? 'selected'
                    : 'unselected';
                userAnalytics.event(GA_ACTION_NAME, 'hide-partial-' + hidePartialOption);
            }

            function updateTimezone() {
                // If timezone from chart options is present, use it
                if (ctrl.calendarWindowTimezone) {
                    ctrl.fnConfig.options.timezone = ctrl.calendarWindowTimezone;
                    ctrl.sfxTimezone.query = ctrl.fnConfig.options.timezone;
                } else if (
                    ctrl.plot.dataManipulations.length > 1 &&
                    calendarWindowUtil.doesCalendarTransformationExist([ctrl.plot])
                ) {
                    // Else if, a calendar transformation already exists,
                    // then just display system default timezone and don't save it
                    ctrl.sfxTimezone.query = '';
                } else {
                    // If timezone in user preference is set (e.g. not 'Auto'),
                    // use it. Otherwise, use current user's local timezone.
                    currentUser.preferences().then(function (prefs) {
                        ctrl.fnConfig.options.timezone =
                            prefs.sf_timePreference || moment.tz.guess();
                        ctrl.sfxTimezone.query = ctrl.fnConfig.options.timezone;
                    });
                }
            }
        },
    ],
};
