import templateUrl from './metricsBrowse.tpl.html';

export default {
    templateUrl,
    bindings: {
        addFilter: '<',
        editCustomCategories: '<',
    },
    controller: [
        'METRICS_CATALOG_CONSTANTS',
        'integrationsListService',
        'metricsBrowseService',
        'currentUser',
        'metricService',
        'filterSelectModal',
        '$q',
        '$log',
        '$scope',
        function (
            METRICS_CATALOG_CONSTANTS,
            integrationsListService,
            metricsBrowseService,
            currentUser,
            metricService,
            filterSelectModal,
            $q,
            $log,
            $scope
        ) {
            const ctrl = this;
            ctrl.GA_PREFIX = 'metrics-finder-browse-page';

            ctrl.$onInit = $onInit;
            ctrl.applyIntegrationFilterAndSearch = applyIntegrationFilterAndSearch;
            ctrl.viewAllValues = viewAllValues;
            ctrl.getIntegrationDisplayName = metricsBrowseService.getDisplayName;
            ctrl.constants = METRICS_CATALOG_CONSTANTS;
            ctrl.getCategoryName = getCategoryName;

            function $onInit() {
                $q.all({
                    isAdmin: currentUser.isAdmin(),
                    activeIntegration: integrationsListService.getAllEnabledIntegrations(),
                    customCategories: metricService.predefinedFacets(),
                })
                    .then((results) => {
                        ctrl.integrationsByColumn = metricsBrowseService.getIntegrationsByColumn(
                            results.activeIntegration
                        );
                        ctrl.isAdmin = results.isAdmin;
                        ctrl.predefinedFacets = results.customCategories;

                        $scope.$on('updateToCustomCategory', () => {
                            metricService.predefinedFacets().then((result) => {
                                ctrl.predefinedFacets = result;
                            });
                        });
                    })
                    .catch((e) => {
                        $log.error('Unable to initialize metrics browse page', e);
                        $q.reject(e);
                    });
            }

            function getCategoryName(name) {
                return METRICS_CATALOG_CONSTANTS.INTEGRATION_CATEGORIES_SENTENCE_CASE[name] || name;
            }

            function applyIntegrationFilterAndSearch(integrationName) {
                // Note: Don't need to check for presence of filter here because the
                // only integrations that would be displayed are the ones with
                const filter = metricsBrowseService.getFilterForIntegration(integrationName);
                ctrl.addFilter(filter);
            }

            function viewAllValues(name, key) {
                const title = `${METRICS_CATALOG_CONSTANTS.SHOW_MORE_MODAL_TITLE_PREFIX} ${name}`;
                const queryInfo = {
                    key: key,
                    isActiveMetricsOnly: true,
                };
                return filterSelectModal(title, queryInfo, ctrl.GA_PREFIX).then((result) => {
                    ctrl.addFilter(result);
                }, angular.noop);
            }
        },
    ],
};
