import templateUrl from './googleCloudIdentityIntegration.html';
import samlShowOnLoginPageTooltipTemplateUrl from './samlShowOnLoginPageTooltip.tpl.html';

angular.module('sfx.ui').component('sfxGoogleCloudIdentityIntegration', {
    templateUrl,
    bindings: {
        credential: '<',
        sharedCredentialState: '<',
        integrationForm: '<',
        editable: '<',
    },
    controller: [
        '$scope',
        'PRODUCT_NAME',
        function ($scope, PRODUCT_NAME) {
            $scope.PRODUCT_NAME = PRODUCT_NAME;
            const $ctrl = this;
            $ctrl.samlShowOnLoginPageTooltipTemplateUrl = samlShowOnLoginPageTooltipTemplateUrl;

            $ctrl.$onInit = reset;

            $ctrl.$onChanges = reset;

            function reset() {
                if (!$ctrl.credential.name) {
                    $ctrl.credential.name = 'Google Cloud Identity';
                }

                $ctrl.sharedCredentialState.updateCredentials = false;
            }

            $ctrl.updateMetadata = function (content) {
                $ctrl.credential.metadata = content;
                $ctrl.credentialUpdate();
            };

            $ctrl.updatePublicKey = function (content) {
                $ctrl.credential.publicKey = content;
                $ctrl.credentialUpdate();
            };

            $ctrl.credentialUpdate = function () {
                $ctrl.sharedCredentialState.updateCredentials = true;
                $ctrl.integrationForm.$setDirty();
            };
        },
    ],
});
