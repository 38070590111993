import templateUrl from './transientModelChart.tpl.html';

export default {
    templateUrl,
    bindings: {
        chartClassName: '@?',
        chartModel: '<',
        sharedChartState: '<',
        hoverTooltipAbsolute: '<',
        disableLegend: '<',
    },
    controller: [
        '$log',
        '$timeout',
        'visualizationOptionsToUIModel',
        function ($log, $timeout, visualizationOptionsToUIModel) {
            const $ctrl = this;

            $ctrl.$onChanges = $onChanges;

            function $onChanges(changesObj) {
                if (changesObj.chartModel) {
                    // NOTE: this looks like nonsense, but chartdisplay is not prepared
                    // to handle a whole hog model swap, which is why we do this and cause
                    // the creation of an entirely new instance (ng-if'ed on convertedModel
                    // in the template)
                    $ctrl.convertedModel = null;
                    $timeout(() => ($ctrl.convertedModel = convertChartModel()));
                }
            }

            function convertChartModel() {
                if (!$ctrl.chartModel) {
                    return null;
                }

                const chartWithUiModel = angular.copy($ctrl.chartModel);

                try {
                    chartWithUiModel.sf_uiModel = visualizationOptionsToUIModel(
                        $ctrl.chartModel.options
                    );
                } catch (e) {
                    $log.error('Failed to convert transient model chart.');
                }

                return chartWithUiModel;
            }
        },
    ],
};
