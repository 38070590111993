import '../../app/reactAngularBridge/InjectMigratedServices';
import '../../app/administrative/administrativeModule';

import './dashboardIndex/_module';
import './notifications/module';
import './administrative/upgradeSubscription/module';
import './teams/module';
import './crossLinks/module';
import './apm/module';
import './quickActions/module';
import './metricsCatalog/module';
import './alertEventModalV2/module';
import MainController from './MainController';

import logger from '../../common/logger';
import uiBootstrap from 'angular-ui-bootstrap';

import { AngularInjector } from '../../common/AngularUtils';

import { supportService } from '@splunk/olly-services/lib';

const debug = logger('debug');

// eslint-disable-next-line import/no-unused-modules
export const appModule = angular.module('signalview', [
    uiBootstrap,
    'ui.ace',
    'ui.utils',
    'sfx.migratedServices',
    'signalboost',
    'sfx.security',
    'chartdisplay',
    'sfx.file',
    'sfx.import',
    'signalview.alertEventModalV2',
    'signalview.alerts',
    'signalview.alertMuting',
    'signalview.alertsOverview',
    'signalview.catalog',
    'signalview.curated',
    'signalview.chart',
    'signalview.chartbuilder',
    'signalview.chartV2',
    'signalview.crossLinks',
    'signalview.dashboard',
    'signalview.dashboardIndex',
    'signalview.detector',
    'signalview.detector.wizard',
    'signalview.detectorV2',
    'signalview.eventsPanel',
    'signalview.input',
    'signalview.linkableModal',
    'signalview.kubernetes',
    'signalview.metricsCatalog',
    'signalview.metricService',
    'signalview.modal',
    'signalview.objectbrowser',
    'signalview.objectmanager',
    'signalview.objectService',
    'signalview.organization',
    'signalview.orgSubscription',
    'signalview.page',
    'signalview.passwordReset',
    'signalview.quickActions',
    'signalview.saml',
    'signalview.snapshot',
    'signalview.superpowers',
    'signalview.teams',
    'signalview.timeservice',
    'signalview.validators',
    'signalview.sfGlobalNav',
    'signalview.themeService',
    'signalview.heatmap',
    'signalview.upgradeSubscription',
    'signalview.timezone',
    'signalview.notifications',
    'signalview.errorPages',
    'support.supportService',
    'sfx.analyticsSelector',
    'mustache',
    'sfx',
    'signalview.location',
    'splunk.apm',
    'styleguide',
    'signalview.userAnalytics',
    'sfx.modal',
    'signalview.oidc',
]);

appModule
    .controller('Main', MainController)

    .config([
        '$locationProvider',
        'sfxApiProvider',
        'localStorage',
        '$logProvider',
        'API_URL',
        'IS_DEBUG',
        function (
            $locationProvider,
            sfxApiProvider,
            localStorage,
            $logProvider,
            API_URL,
            IS_DEBUG
        ) {
            // angular now default has prefix to ! which is not what we want (yet).
            $locationProvider.hashPrefix('');
            sfxApiProvider.basePath(API_URL + '/v1');
            sfxApiProvider.auth(localStorage['X-SF-TOKEN']);

            $logProvider.debugEnabled(IS_DEBUG);
        },
    ])

    .run([
        'sfxApi',
        'auth',
        '$animate',
        'IS_PRODUCTION',
        'API_URL',
        'CUSTOMER_SERVER_API_URL',
        'SUPPORT_SERVER_API_URL',
        'STATIC_RESOURCE_ROOT',
        'INTEGRATIONS_DOC_URL',
        'urlOverridesService',
        'realmInfo',
        'IS_MOBILE',
        '$injector',
        '$rootScope',
        'locationHistory',
        function (
            sfxApi,
            auth,
            $animate,
            IS_PRODUCTION,
            API_URL,
            CUSTOMER_SERVER_API_URL,
            SUPPORT_SERVER_API_URL,
            STATIC_RESOURCE_ROOT,
            INTEGRATIONS_DOC_URL,
            urlOverridesService,
            realmInfo,
            IS_MOBILE,
            $injector,
            $rootScope
        ) {
            function initializeAuth() {
                const token = urlOverridesService.getAccessToken();
                if (token) {
                    auth.setAuth(token);
                    supportService.setSupportOrg(urlOverridesService.getTargetOrg()); // a hack for screen capturing for now
                    urlOverridesService.clearAccessToken();
                }
                debug(
                    `This is a ${
                        IS_PRODUCTION ? 'PRODUCTION' : 'NON-PRODUCTION'
                    } app. Using ${auth.authToken()} as API token.`
                );
                $animate.enabled(false);
                sfxApi.authorization(auth.authToken());

                if (auth.authToken()) {
                    // for the first time page load.
                    realmInfo.set();
                }
            }

            $rootScope.$on('migrated services initialized', initializeAuth);

            debug(`Endpoints:
            REST API: ${API_URL}
            Customer Server API: ${CUSTOMER_SERVER_API_URL}
            Support Server API: ${SUPPORT_SERVER_API_URL}
            Static Server API: ${STATIC_RESOURCE_ROOT}
            Integration Server API: ${INTEGRATIONS_DOC_URL}`);

            if (IS_MOBILE) {
                debug('User Agent assumed to be mobile.');
                angular.element('.sf-ui').addClass('sf-mobile');
            }

            // provide angular injector to non-angular services
            AngularInjector.$injector = $injector;
        },
    ]);
