import WORKLOAD_DETAILS_CONFIG from '../../../../app/kubeNavigator/configs/workloads/workloadDetailsConfig';
import WORKLOAD_POD_LIST_CONFIG from '../../../../app/kubeNavigator/configs/workloads/workloadPodListConfig.js';
import templateUrl from './kubeWorkloadDetails.tpl.html';
import fixedFilterEventFeed from '../common/fixedFilterEventFeed.tpl.html';
import getEventChartModel from '../../../../app/kubeNavigator/configs/workloads/workloadEventFeedConfig.js';
import {
    getPodPhaseChartModel,
    getCPUChartModel,
    getMemoryChartModel,
} from '../../../../app/kubeNavigator/configs/workloads/workloadChartModelsConfig.js';

export default {
    templateUrl,
    bindings: {
        time: '<',
        filterAlias: '<', // Variable definitions to be supplied to chart models
        filters: '<',
        isOnSidebar: '<?',
        onSelection: '<?',
        selectedTabDisplayName: '<?',
    },
    controller: [
        '_',
        'transientChartModelMaker',
        'kubeDataService',
        'plotToSignalflowV2',
        function (_, transientChartModelMaker, kubeDataService, plotToSignalflowV2) {
            const $ctrl = this;
            let lastFilterAliases;

            $ctrl.$onChanges = $onChanges;
            $ctrl.onPodSelection = onPodSelection;

            $ctrl.configs = {
                workloadDetailsConfig: WORKLOAD_DETAILS_CONFIG,
                podListConfig: WORKLOAD_POD_LIST_CONFIG,
            };

            $ctrl.sharedChartState = {};

            $ctrl.searchable = {
                placeholder: 'Search pods',
            };

            function $onChanges({ filterAlias, filters, time }) {
                const filtersUpdated =
                    (filterAlias || filters) &&
                    !angular.equals(lastFilterAliases, $ctrl.filterAlias);

                if (filtersUpdated) {
                    lastFilterAliases = angular.copy($ctrl.filterAlias);

                    const areVariablesPresent =
                        $ctrl.filterAlias &&
                        $ctrl.filterAlias.some(({ value }) => !_.isEmpty(value));

                    $ctrl.areOverridesPresent =
                        areVariablesPresent ||
                        ($ctrl.filters && $ctrl.filters.some(({ value }) => !_.isEmpty(value)));
                }

                if (filtersUpdated || time) {
                    processChartModels();
                }
            }

            function processChartModels() {
                const time = kubeDataService.getChartModelTime($ctrl.time);
                const filters = kubeDataService.cleanFilters($ctrl.filterAlias);
                const deploymentFilters = kubeDataService.getExclusiveDeploymentFilters(filters);

                const filtersString = kubeDataService.generateFilterStrings(filters);
                const deploymentFiltersString = plotToSignalflowV2.filters(deploymentFilters);

                const cpuChartModel = getCPUChartModel(
                    time,
                    filtersString,
                    deploymentFiltersString
                );
                const memoryChartModel = getMemoryChartModel(
                    time,
                    filtersString,
                    deploymentFiltersString
                );
                const podPhaseChartModel = getPodPhaseChartModel(
                    time,
                    filtersString,
                    deploymentFiltersString
                );

                $ctrl.configs.workloadDetailChartModels = [
                    transientChartModelMaker(
                        cpuChartModel.chartOptions,
                        cpuChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        memoryChartModel.chartOptions,
                        memoryChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        podPhaseChartModel.chartOptions,
                        podPhaseChartModel.publishLabelOptions
                    ),
                ];

                const eventChartModel = getEventChartModel(
                    time,
                    kubeDataService.translateWorkloadFilters(filters)
                );
                $ctrl.eventFeed = {
                    templateUrl: fixedFilterEventFeed,
                    model: transientChartModelMaker(
                        eventChartModel.chartOptions,
                        eventChartModel.publishLabelOptions
                    ),
                };
            }

            function onPodSelection(selection) {
                const { kubernetes_cluster, kubernetes_node, kubernetes_pod_uid } = selection;
                if (
                    $ctrl.onSelection &&
                    kubernetes_cluster &&
                    kubernetes_node &&
                    kubernetes_pod_uid
                ) {
                    $ctrl.onSelection({ kubernetes_cluster, kubernetes_node, kubernetes_pod_uid });
                }
            }
        },
    ],
};
