import templateUrl from './eventsPanel.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular.module('signalview.eventsPanel').directive('eventsPanel', [
    '$q',
    '$timeout',
    'sourceFilterService',
    'urlOverridesService',
    'globalEventsService',
    'analyticsService',
    'CHART_DISPLAY_EVENTS',
    'userAnalytics',
    'dashboardVariablesService',
    'hasCapability',
    function (
        $q,
        $timeout,
        sourceFilterService,
        urlOverridesService,
        globalEventsService,
        analyticsService,
        CHART_DISPLAY_EVENTS,
        userAnalytics,
        dashboardVariablesService,
        hasCapability
    ) {
        return {
            restrict: 'E',
            scope: {
                eventSidebarParams: '=?',
                collapse: '<?',
                eventsOnly: '<?',
            },
            templateUrl,
            link: function ($scope) {
                $scope.timeFilter = {};
                $scope.objectTypes = ['EventTimeSeries'];
                $scope.sourceFilters = [];
                let querySuffix = getSuffix();

                $scope.$on('dashboard.updateOverrides', function (e, data) {
                    $scope.timeFilter.end = data.end || data.endUTC;
                    $scope.timeFilter.start = data.start || data.startUTC;
                    querySuffix = analyticsService.getSuffix(data.sources, data.variables);
                    resetEvents();
                });

                $scope.hasCreateEventCapability = false;
                hasCapability(Capability.CREATE_EVENT).then(
                    (hasCreateEventCapability) =>
                        ($scope.hasCreateEventCapability = hasCreateEventCapability)
                );

                $scope.getCurrentQuery = function (key) {
                    const query = [
                        '_exists_:sf_eventType',
                        'NOT sf_eventType:_SF_PLOT_KEY_*',
                        'sf_type:EventTimeSeries',
                    ];
                    if (key && key.indexOf('sf_eventType:') === 0) {
                        query.push('NOT sf_eventCategory:ALERT');
                    }
                    return $q.when(query.join(' AND '));
                };

                $scope.addNewEvent = function () {
                    globalEventsService.createGlobalEvent().finally(function () {
                        resetEvents();
                    });
                };

                $scope.toggleEventOverlay = function () {
                    if ($scope.eventSidebarParams.showOverlaidEventsInPanel) {
                        userAnalytics.event('dashboard', 'event-overlay-panel-show-all-events');
                    } else {
                        userAnalytics.event(
                            'dashboard',
                            'event-overlay-panel-show-overlaid-events'
                        );
                    }
                    $scope.eventSidebarParams.showOverlaidEventsInPanel =
                        !$scope.eventSidebarParams.showOverlaidEventsInPanel;
                };

                $scope.temp = { search: '' };

                function getQuery() {
                    const sourceFilter = sourceFilterService.translateSourceFilterObjects(
                        $scope.sourceFilters
                    );
                    return sourceFilter || '*';
                }
                function resetEvents() {
                    // Don't update events if we're not expanded
                    $scope.eventQuery = getQuery() + querySuffix;
                }

                function getSuffix() {
                    const sourceOverrideString = urlOverridesService.getSourceOverride() || [];
                    const sourceOverrides =
                        sourceFilterService.getSourceFilters(sourceOverrideString);
                    const dashboardOverrides =
                        dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
                    return analyticsService.getSuffix(sourceOverrides, dashboardOverrides);
                }

                $scope.$watch('sourceFilters', resetEvents, true);
                $scope.$watchGroup(
                    [
                        'eventSidebarParams.showOverlaidEventsInPanel',
                        'eventSidebarParams.eventQuery',
                    ],
                    function () {
                        if ($scope.eventSidebarParams) {
                            if ($scope.eventSidebarParams.showOverlaidEventsInPanel) {
                                $scope.eventQuery = $scope.eventSidebarParams.eventQuery || '';
                            } else {
                                resetEvents();
                            }
                        }
                    }
                );

                $scope.$on('eventTileMouseEnter', function (evt, timestamp) {
                    $scope.$emit('showDashboardVerticalLines', timestamp);
                });

                $scope.$on('eventTileMouseLeave', function () {
                    $scope.$emit('hideDashboardVerticalLines');
                });

                // OT: this seems to be the current way of getting the current time
                // picker value, super hacky, I know. That is sytem is so convoluted
                // right now that I don't think I can clean it up for this feature with-
                // out risking other features.
                $timeout(function () {
                    const urlOverrides = urlOverridesService.getGlobalTimePicker() || {};
                    urlOverrides.store = $scope.timeFilter;
                    $scope.$emit(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, urlOverrides);
                }, 0);
            },
        };
    },
]);
