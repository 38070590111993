import templateUrl from './dashboardInfo.tpl.html';

export default {
    templateUrl,
    bindings: {
        dashboardEditable: '<',
        groupEditable: '<',
        dashboardObject: '<',
        snapshotObject: '<',
        chartObjects: '<',
        currentGroupName: '<',
        currentGroupId: '<',
        currentConfigObject: '<',
        allDashboardConfigsPromise: '<',
        onUpdateDashboardInfo: '<',
        rename: '<',
        onSave: '<?',
        missingUpdateDashboardCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$log',
        '$scope',
        'config',
        'dashboardV2Service',
        'currentUser',
        '$rootScope',
        'exitGuardModal',
        '$q',
        '$element',
        '$timeout',
        'featureEnabled',
        'dashboardUtil',
        'dashboardGroupService',
        'userAnalytics',
        'userV2Service',
        'urlOverridesService',
        'SYSTEM_USER_ID',
        'dashboardMirrorService',
        function (
            $log,
            $scope,
            config,
            dashboardV2Service,
            currentUser,
            $rootScope,
            exitGuardModal,
            $q,
            $element,
            $timeout,
            featureEnabled,
            dashboardUtil,
            dashboardGroupService,
            userAnalytics,
            userV2Service,
            urlOverridesService,
            SYSTEM_USER_ID,
            dashboardMirrorService
        ) {
            const ctrl = this;
            ctrl.$onInit = $onInit;
            ctrl.cancel = cancel;
            ctrl.close = close;
            ctrl.getDashboardUrl = getDashboardUrl;
            ctrl.saveAndClose = saveAndClose;
            ctrl.saveAndCloseOverrides = saveAndCloseOverrides;
            ctrl.updateMaxDelay = updateMaxDelay;
            ctrl.clearNameOverride = clearNameOverride;
            ctrl.clearDescriptionOverride = clearDescriptionOverride;
            ctrl.clearFilterOverrides = clearFilterOverrides;
            ctrl.navigateToDashboardInContext = navigateToDashboardInContext;
            ctrl.selectSourceFilterOverrideCheckbox = selectSourceFilterOverrideCheckbox;

            const DEFAULT_FILTERS_OVERRIDE = { sources: null, time: null, density: null };

            function $onInit() {
                ctrl.isSuperPowers = config('superpower.unreleasedFeatures');
                ctrl.readOnlyEnabled = featureEnabled('readOnly');
                ctrl.dashboardViewsEnabled = featureEnabled('dashboardViews');

                ctrl.tempDashboard = angular.copy(ctrl.dashboardObject);
                ctrl.isServiceDiscovery =
                    ctrl.tempDashboard.creator === SYSTEM_USER_ID && !ctrl.isSuperPowers;
                ctrl.maxDelayOverride = ctrl.tempDashboard.maxDelayOverride;
                ctrl.dashboardFilters = angular.copy(ctrl.tempDashboard.filters) || {};
                if (ctrl.dashboardFilters.sources) {
                    ctrl.dashboardFilters.sources = formatSourceFilters(
                        ctrl.dashboardFilters.sources
                    );
                }
                ctrl.cleanFilters = angular.copy(ctrl.dashboardFilters);

                ctrl.discoveryOptions = ctrl.tempDashboard.discoveryOptions;

                ctrl.snapshot = ctrl.snapshotObject || {};
                ctrl.groupName = ctrl.currentGroupName;
                ctrl.charts = angular.copy(ctrl.chartObjects);

                currentUser.orgId().then(function (orgId) {
                    ctrl.organizationId = orgId;
                });

                ctrl.dashboardFieldsDisabled =
                    !ctrl.dashboardEditable ||
                    ctrl.isServiceDiscovery ||
                    (ctrl.readOnlyEnabled && ctrl.tempDashboard.locked);

                if (featureEnabled('dashboardViews')) {
                    ctrl.tempCurrentConfig = angular.copy(ctrl.currentConfigObject);
                    if (!ctrl.tempCurrentConfig.filtersOverride) {
                        ctrl.configFilters = getNewDefaultOverridesObject();
                    } else {
                        ctrl.configFilters = angular.copy(ctrl.tempCurrentConfig.filtersOverride);
                    }

                    ctrl.configFilters.sources = formatSourceFilters(ctrl.configFilters.sources);
                    ctrl.cleanFilterOverrides = angular.copy(ctrl.configFilters) || [];
                    ctrl.sourceFilterOverrideSelected = !isNullOrUndefined(
                        ctrl.cleanFilterOverrides.sources
                    );

                    dashboardUtil.getAllDashboardConfigs(ctrl.tempDashboard.id).then((configs) => {
                        ctrl.allDashboardConfigs = configs;
                    });

                    dashboardMirrorService
                        .isDashboardMirror(ctrl.tempDashboard.id)
                        .then((isMirror) => {
                            if (
                                isMirror ||
                                dashboardMirrorService.configHasOverrides(ctrl.currentConfigObject)
                            ) {
                                ctrl.showOverrides = true;
                            }
                        });

                    // only want to watch for configFilters or filter overrides if dashboard views is enabled
                    $scope.$watchCollection(
                        '$ctrl.configFilters.sources',
                        markFormIfGroupOverrideFiltersDirty
                    );
                }

                initEditorData();
                initExpiryData();

                $scope.$watchGroup(
                    ['dashboardForm.$dirty', 'groupOverridesForm.$dirty'],
                    configureExitGuard
                );
                $scope.$watchCollection(
                    '$ctrl.dashboardFilters.sources',
                    markFormIfDashboardFiltersDirty
                );
                $scope.$on('max delay dirty', dirtifyDashboardForm);
            }

            function isNullOrUndefined(val) {
                return val === null || val === undefined;
            }

            function selectSourceFilterOverrideCheckbox() {
                if (!ctrl.sourceFilterOverrideSelected) {
                    ctrl.configFilters.sources = null;
                } else if (isNullOrUndefined(ctrl.configFilters.sources)) {
                    ctrl.configFilters.sources = [];
                }
            }

            /*
             * This is a hack to account for the fact that the way filters get set up in the info modal
             * context is different than in a regular dashboard context. In the sourceFilters code, a
             * "propertyValue" attribute is expected, which is not by default a part of the source filters
             * when the UI receives them. As it is the same as the "value" attribute that is the default
             * attribute for source filters, we add "propertyValue" here.
             */
            function formatSourceFilters(sources) {
                if (!sources) {
                    return sources;
                }
                return sources.map((filter) => {
                    filter.propertyValue = filter.value;
                    return filter;
                });
            }

            function getNewDefaultOverridesObject() {
                return angular.copy(DEFAULT_FILTERS_OVERRIDE);
            }

            function initEditorData() {
                initCreatorData();
                initUpdatedByData();
            }

            function initCreatorData() {
                const creator =
                    ctrl.tempDashboard.creator ||
                    ctrl.tempDashboard.sf_creator ||
                    ctrl.snapshot.creator;
                if (creator) {
                    userV2Service.getOrgMemberForUserId(creator).then((user) => {
                        ctrl.creatorName = user.fullName;
                    });
                }
            }

            function initUpdatedByData() {
                const updatedBy =
                    ctrl.tempDashboard.lastUpdatedBy ||
                    ctrl.tempDashboard.sf_updatedBy ||
                    ctrl.snapshot.lastUpdatedBy;
                if (updatedBy) {
                    userV2Service.getOrgMemberForUserId(updatedBy).then((user) => {
                        ctrl.updatedByName = user.fullName;
                    });
                }

                if (ctrl.rename) {
                    $timeout(function () {
                        $element.find('.edit-dashboard-name').focus();
                    });
                }
            }

            function initExpiryData() {
                if (ctrl.snapshot) {
                    ctrl.expiryMs = ctrl.snapshot.expiryMs;
                }
            }

            function configureExitGuard(dirtyVals) {
                if (dirtyVals.includes(true)) {
                    ctrl.tabby.enableExitGuard(exitGuard);
                } else {
                    ctrl.tabby.disableExitGuard();
                }
            }

            function exitGuard() {
                return exitGuardModal(saveAll, reset);
            }

            function reset() {
                $onInit();
                $scope.dashboardInfo.$setPristine();
                return $q.when();
            }

            function markFormIfDashboardFiltersDirty(newFilters) {
                if (!angular.equals(newFilters, ctrl.cleanFilters.sources)) {
                    dirtifyDashboardForm();
                }
            }

            function markFormIfGroupOverrideFiltersDirty(newFilters) {
                // Regardless of what the persisted value is for filters (could be null),
                // the filters object in the source filters component is always a list
                if (!angular.equals(newFilters, ctrl.cleanFilterOverrides.sources)) {
                    $scope.groupOverridesForm.$setDirty();
                }
            }

            function dirtifyDashboardForm() {
                $scope.dashboardForm.$setDirty();
            }

            function cancel() {
                $scope.$emit('dismiss modal');
            }

            function close() {
                $scope.$emit('close modal');
            }

            function getDashboardUrl(groupId, configId) {
                return dashboardUtil.getDashboardUrl(ctrl.tempDashboard.id, groupId, configId);
            }

            function saveAndClose(andClose) {
                if (!$scope.dashboardForm.$invalid) {
                    save().then(function () {
                        if (andClose) {
                            close();
                        }
                    });
                }
            }

            function save() {
                const nameChanged = ctrl.dashboardObject.name !== ctrl.tempDashboard.name;
                ctrl.dashboardObject.name = ctrl.tempDashboard.name;
                ctrl.dashboardObject.description = ctrl.tempDashboard.description;
                ctrl.dashboardObject.maxDelayOverride = ctrl.maxDelayOverride;
                // we need to perform this map as the UI currently uses both value and propertyValue
                // and propertyValue here is what's actually updated. This is unfortunate as our API
                // expects value and not propertyValue
                if (ctrl.dashboardFilters.sources) {
                    ctrl.dashboardFilters.sources = ctrl.dashboardFilters.sources.map((filter) => {
                        filter.value = filter.propertyValue || filter.value;
                        return filter;
                    });
                }
                ctrl.dashboardObject.filters.sources = ctrl.dashboardFilters.sources;
                if (ctrl.discoveryOptions !== null) {
                    ctrl.dashboardObject.discoveryOptions = ctrl.discoveryOptions || null;
                }

                if (ctrl.snapshot.id) {
                    if (ctrl.onUpdateDashboardInfo) {
                        ctrl.onUpdateDashboardInfo(true);
                    }
                    $scope.$emit('save complete');
                    $scope.dashboardForm.$setPristine();
                    return $q.when();
                }

                if (nameChanged) {
                    $rootScope.$broadcast('dashboard name changed', {
                        id: ctrl.dashboardObject.id,
                    });
                }

                // Update our clean version of the filters since the modal may not close
                ctrl.cleanFilters = angular.copy(ctrl.dashboardFilters);

                return dashboardV2Service
                    .update(ctrl.dashboardObject)
                    .then(function () {
                        if (ctrl.onUpdateDashboardInfo) {
                            ctrl.onUpdateDashboardInfo(true);
                        }
                        $scope.$emit('save complete');
                    })
                    .catch(function (e) {
                        $log.error(e);
                        $scope.$emit('save complete', true);
                    })
                    .finally(function () {
                        if (ctrl.onSave) {
                            ctrl.onSave();
                        }
                        $scope.dashboardForm.$setPristine();
                        if (!$scope.groupOverridesForm.$dirty) {
                            setAllPristine();
                        }
                    });
            }

            function saveAndCloseOverrides(andClose) {
                if (!$scope.groupOverridesForm.$invalid) {
                    saveOverrides().then(function () {
                        if (andClose) {
                            close();
                        }
                    });
                }
            }

            function saveOverrides() {
                if (!ctrl.dashboardViewsEnabled) {
                    return;
                }

                // Clean out object polluted by dashboardUtil.getAllDashboardConfigs
                ctrl.tempCurrentConfig = angular.copy(ctrl.tempCurrentConfig);
                delete ctrl.tempCurrentConfig.groupName;
                delete ctrl.tempCurrentConfig.groupId;

                if (shouldSaveSourceFilterOverrides()) {
                    if (!ctrl.tempCurrentConfig.filtersOverride)
                        ctrl.tempCurrentConfig.filtersOverride = {};

                    // Clean out the models that source-edit provides
                    ctrl.tempCurrentConfig.filtersOverride.sources = isNullOrUndefined(
                        ctrl.configFilters.sources
                    )
                        ? null
                        : ctrl.configFilters.sources.map((filter) => {
                              return {
                                  NOT: filter.NOT,
                                  value: filter.propertyValue || filter.value,
                                  property: filter.property,
                                  applyIfExists: filter.applyIfExists || false,
                              };
                          });

                    if (!ctrl.currentConfigObject.filtersOverride) {
                        ctrl.currentConfigObject.filtersOverride = getNewDefaultOverridesObject();
                    }

                    ctrl.currentConfigObject.filtersOverride.sources =
                        ctrl.tempCurrentConfig.filtersOverride.sources;

                    userAnalytics.event('dashboard-views', 'change-filter-overrides-info-modal');

                    // Update our clean version of the filters since the modal may not close
                    ctrl.cleanFilterOverrides = angular.copy(
                        ctrl.currentConfigObject.filtersOverride
                    );
                }

                if (ctrl.currentConfigObject.nameOverride !== ctrl.tempCurrentConfig.nameOverride) {
                    userAnalytics.event('dashboard-views', 'change-name-override-info-modal');
                }

                // Avoid saving any falsey value other than null e.g. the empty string
                if (!ctrl.tempCurrentConfig.nameOverride) {
                    ctrl.tempCurrentConfig.nameOverride = null;
                }
                ctrl.currentConfigObject.nameOverride = ctrl.tempCurrentConfig.nameOverride;

                if (
                    ctrl.currentConfigObject.descriptionOverride !==
                    ctrl.tempCurrentConfig.descriptionOverride
                ) {
                    userAnalytics.event(
                        'dashboard-views',
                        'change-description-override-info-modal'
                    );
                }

                // Avoid saving any falsey value other than null e.g. the empty string
                if (!ctrl.tempCurrentConfig.descriptionOverride) {
                    ctrl.tempCurrentConfig.descriptionOverride = null;
                }
                ctrl.currentConfigObject.descriptionOverride =
                    ctrl.tempCurrentConfig.descriptionOverride;

                return dashboardGroupService
                    .updateDashboardConfig(ctrl.currentGroupId, ctrl.currentConfigObject)
                    .then(() => {})
                    .catch((e) => {
                        $log.error(e);
                    })
                    .finally(() => {
                        if (ctrl.onSave) {
                            ctrl.onSave();
                        }
                        $scope.groupOverridesForm.$setPristine();
                        if (!$scope.dashboardForm.$dirty) {
                            setAllPristine();
                        }
                    });
            }

            function saveAll() {
                return $q.all([save(), saveOverrides()]);
            }

            function shouldSaveSourceFilterOverrides() {
                const startedWithEmptyFilters =
                    !ctrl.tempCurrentConfig.filtersOverride ||
                    !ctrl.tempCurrentConfig.filtersOverride.sources ||
                    !ctrl.tempCurrentConfig.filtersOverride.sources.length;
                const hasEmptyFilters = angular.equals(
                    ctrl.configFilters,
                    DEFAULT_FILTERS_OVERRIDE
                );
                return !(startedWithEmptyFilters && hasEmptyFilters);
            }

            function setAllPristine() {
                $scope.dashboardInfo.$setPristine();
            }

            function updateMaxDelay(maxDelayOverride) {
                ctrl.maxDelayOverride = maxDelayOverride;
            }

            function clearNameOverride() {
                if (!ctrl.tempCurrentConfig.nameOverride) {
                    return;
                }

                ctrl.tempCurrentConfig.nameOverride = null;
                $scope.groupOverridesForm.$setDirty();
            }

            function clearDescriptionOverride() {
                if (!ctrl.tempCurrentConfig.descriptionOverride) {
                    return;
                }

                ctrl.tempCurrentConfig.descriptionOverride = null;
                $scope.groupOverridesForm.$setDirty();
            }

            function clearFilterOverrides() {
                ctrl.configFilters.sources = [];
            }

            function navigateToDashboardInContext(config) {
                // only clear params if we are navigating to a different mirror
                if (config.configId !== ctrl.tempCurrentConfig.configId) {
                    urlOverridesService.clearAllNonLocationUrlParams();
                    dashboardUtil.addDashboardSearchParams({
                        groupId: config.groupId,
                        configId: config.configId,
                    });
                }

                // In this case, we want to ensure that filters are not reset, since we are leaving the
                // current context and entering a new one. If they are allowed to reset, they will reset
                // using the filters on the previous context.
                $scope.$emit('dismiss modal');
            }
        },
    ],
};
