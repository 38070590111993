import accessControlHelper from './accessControlHelper';
import AccessControlPrincipalDataCollection from './AccessControlPrincipalDataCollection';

const ACL = [
    {
        principalId: 'BqDQY5OAAAZ',
        principalType: 'ORG',
    },
    {
        // /v2/team/D4yxoqyAIWE
        principalId: 'D4yxoqyAIZZ',
        principalType: 'TEAM',
    },
    {
        // /v2/organization/member/EBLnJK6AIAA
        principalId: 'D_BFeEkAAZZ',
        principalType: 'USER',
    },
    {
        // /v2/organization/member/EBLnJK6AIAA
        principalId: 'EBLnJK6AIZZ',
        principalType: 'USER',
    },
    {
        // /v2/organization/member/EBLnJK6AIAA
        principalId: 'EBdpF77AAZZ',
        principalType: 'USER',
    },
];

const MOCK_DATA = {
    teams: [
        {
            id: 'D4yxoqyAIZZ',
            name: 'First Viennese School',
            label: 'First Viennese School Team',
            icon: '',
            actions: ['READ'],
        },
    ],
    users: [
        {
            email: 'jhaydn@domain.com',
            fullName: 'Joseph Haydn',
            id: 'D_BFeEkAAZZ',
            __principalType: 'USER',
            label: 'Joseph Haydn (jhaydn@domain.com)',
            icon: 'https://secure.gravatar.com/avatar/7a5bffa4bd4607377081a7f23cdc6b6?s=19&d=retro',
            actions: ['READ', 'WRITE'],
        },
        {
            email: 'wamozart@domain.com',
            fullName: 'Wolfgang Amadeus Mozart',
            id: 'EBLnJK6AIZZ',
            __principalType: 'USER',
            label: 'Wolfgang Amadeus Mozart (wamozart@domain.com)',
            icon: 'https://secure.gravatar.com/avatar/7a5bffa4b45d4607377081a7f23cdc6b6?s=19&d=retro',
            actions: ['READ'],
        },
        {
            email: 'lvbeethoven@domain.com',
            fullName: 'Ludwig van Beethoven',
            id: 'EBdpF77AAZZ',
            __principalType: 'USER',
            label: 'Ludwig van Beethoven (lvbeethoven@domain.com)',
            icon: 'https://secure.gravatar.com/avatar/7a5bffa4bd46407377081a7f23cdc6b6?s=19&d=retro',
            actions: ['READ', 'WRITE'],
        },
    ],
};

function search(phrase) {
    const regExp = new RegExp(phrase, 'i');
    return new Promise((resolve) => {
        setTimeout(
            () =>
                resolve(
                    new AccessControlPrincipalDataCollection(
                        MOCK_DATA.teams.filter((t) => !phrase || t.name.match(regExp)),
                        MOCK_DATA.users.filter(
                            (t) => !phrase || t.fullName.match(regExp) || t.email.match(regExp)
                        )
                    )
                ),
            Math.random() * 300
        );
    });
}

function getByAclList(aclList) {
    const principalsData = [];

    aclList.forEach((item) => {
        switch (item.principalType) {
            case 'USER':
                principalsData.push(MOCK_DATA.users.find((user) => user.id === item.principalId));
                break;
            case 'TEAM':
                principalsData.push(MOCK_DATA.teams.find((team) => team.id === item.principalId));
                break;
        }
    });

    return new Promise((resolve) => {
        setTimeout(() => resolve(principalsData), Math.random() * 300);
    });
}

function getMockAclUser(writeAccess, userIndex = 2) {
    return accessControlHelper.extendWithActions(ACL[userIndex], writeAccess);
}

export const accessControlMock = {
    principalsDataRepository: {
        search,
        getPrincipalsData: getByAclList,
    },
    getMockAclUser,
    getMockCurrentOrgId: () => ACL[0].principalId,
    getMockCurrentUserId: (userIndex = 2) => ACL[userIndex].principalId,
};
