import templateUrl from './detectorRichFormEditor.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';
import qs from 'query-string';

export default {
    templateUrl,
    controller: [
        '$scope',
        'title',
        'v2DetectorResolverUtil',
        'detectorVersionService',
        function ($scope, title, v2DetectorResolverUtil, detectorVersionService) {
            const detectorId = ngRoute.params.id;
            v2DetectorResolverUtil
                .getDetector(detectorId)
                .then((detector) => {
                    if (detectorVersionService.getVersion(detector) === 2) {
                        ngRoute.history.replace({
                            pathname: `/detector/v2/${detectorId}/edit`,
                            search: qs.stringify(ngRoute.search),
                        });
                    } else {
                        return v2DetectorResolverUtil.getDetectorRichViewData(detector);
                    }
                })
                .then((data) => {
                    if (data) {
                        $scope.data = {
                            data: {
                                detector: data.detector,
                                mutings: data.mutings,
                            },
                            updateTitle: title.updateTitle,
                        };
                    }
                });
        },
    ],
};
