import { useMemoGuaranteed } from '@splunk/olly-common/hooks';
import React, { Context, FC, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { createAngularHashHistory } from '@splunk/olly-common/AngularHashHistory';
import { Router } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { TopNavContextType } from '@splunk/olly-common/Nav';
import { RelatedContentService } from '@splunk/olly-common/RelatedContent/useRelatedContent';
import { MetricService } from '@splunk/olly-imm/build/services/signalboost/MetricService/MetricService';
import { DimensionService } from '@splunk/olly-imm/build/services/signalboost/DimensionService/DimensionService';
import {
    Signalboost,
    SignalboostBaseServiceProvider,
} from '@splunk/olly-imm/build/services/signalboost/SignalboostBaseService/SignalboostBaseProvider';
import {
    getInstrumentedHttpServiceClient,
    HTTPProvider,
    PubSubStore,
    PubSubStoreProvider,
} from '@splunk/olly-services';
import { Clipboard } from '@splunk/olly-services/lib/services/Clipboard';
import { UserAnalyticsProvider } from '@splunk/olly-services/lib/services/UserAnalytics/UserAnalyticsProvider';
import { UserAnalytics } from '@splunk/olly-services/lib/services/UserAnalytics/UserAnalytics';
import { SessionStore } from '@splunk/olly-services/lib/Session/SessionStore';
import { SessionCacheStore } from '@splunk/olly-services/lib/services/SessionCache/SessionCacheStore';
import { SessionCacheStoreProvider } from '@splunk/olly-services/lib/services/SessionCache/SessionCacheProvider';
import { UserStore } from '@splunk//olly-services/lib/services/UserV2Service/UserStore';
import { SessionStoreProvider } from '@splunk/olly-services/lib/Session/SessionServiceProvider';
import { AngularInjector } from '../common/AngularUtils';
import { OrganizationStore } from '@splunk/olly-services/lib/services/Organization/OrganizationStore';
import { OrganizationStoreProvider } from '@splunk/olly-services/lib/services/Organization/OrganizationStoreProvider';
import { SalesforceLiveAgentService } from '@splunk/olly-services/lib/services/SalesforceLiveAgentChat/salesforceLiveAgentService';
import { SalesforceLiveAgentChat } from '@splunk/olly-services/lib/services/SalesforceLiveAgentChat/salesforceLiveAgentChat';
import { SalesforceLiveAgentServiceProvider } from '@splunk/olly-services/lib/services/SalesforceLiveAgentChat/SalesforceLiveAgentServiceProvider';
import { UserStoreProvider } from '@splunk//olly-services/lib/services/UserV2Service/UserStoreProvider';
import { OverQuotaNotificationStore } from '@splunk/olly-services/lib/services/OverQuotaNotification/OverQuotaNotificationStore';
import { OverQuotaNotificationStoreProvider } from '@splunk/olly-services/lib/services/OverQuotaNotification/OverQuotaNotificationStoreProvider';
import {
    ColorScheme,
    ThemeProvider,
    ThemeProviderProps,
    useActiveColorAccessibilityType,
    useLegacyThemeServiceSync,
} from '../common/theme/ThemeProvider';
import { NgInjectMigratedServices } from './reactAngularBridge/InjectMigratedServices';
import { API_URL } from '../legacy/common/consts';
import { reactNgBridge } from './reactAngularBridge/MigratedServiceProxies';
import { AppRoutes } from './routing/AppRoutes';
import { SignalviewTopNavContextProvider } from './TopNavContextProvider';
import { AuthStoreProvider } from '@splunk/olly-services/lib/services/Auth/AuthStoreProvider';
import { Auth } from '@splunk/olly-services/lib/services/Auth/AuthStore';
import { GlobalNavUpdateStoreProvider } from '@splunk/olly-services/lib/services/GlobalNav/GlobalNavUpdateStoreProvider';
import { GlobalNavUpdateStore } from '@splunk/olly-services/lib/services/GlobalNav/GlobalNavUpdateStore';
import { SignalViewMetricsStore } from '@splunk/olly-imm/build/metrics/SignalViewMetricsStore';
import { SignalViewMetricsStoreProvider } from '@splunk/olly-imm/build/metrics/SignalViewMetricsStoreProvider';

import { GoogleAnalytics } from '@splunk/olly-services/lib/services/UserAnalytics/GoogleAnalytics';
import { ColorAccessibilityStore } from '@splunk/olly-services/lib/services/ColorAccessibility/ColorAccessibilityStore';
import { ColorAccessibilityStoreProvider } from '@splunk/olly-services/lib/services/ColorAccessibility/ColorAccessibilityProvider';
import { CurrentUserStore } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStore';
import { CredentialV2Store } from '@splunk/olly-services/lib/services/CredentialV2/CredentialV2Store';
import { CredentialV2StoreProvider } from '@splunk/olly-services/lib/services/CredentialV2/CredentialV2StoreProvider';
import { RelatedContentServiceProvider } from './relatedContentService/RelatedContentServiceProvider';
import { SHOULD_DISABLE_GA } from '../legacy/common/consts';
import { CurrentUserStoreProvider } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStoreProvider';
import { LoginStore } from '@splunk/olly-services/lib/services/LoginStore/LoginStore';
import { LoginStoreProvider } from '@splunk/olly-services/lib/services/LoginStore/LoginStoreProvider';
import { DOMAIN_ORGANIZATION_ID } from '../legacy/common/consts';
import { FeatureFlagsProvider } from '@splunk/olly-services/lib/services/FeatureFlag/FeatureFlagStoreProvider';
import { FeatureFlagStore } from '@splunk/olly-services/lib/services/FeatureFlag/FeatureFlagStore';
import { ngRoute } from './routing/ngRoute';
import { isSnapshotEdit, globalNavProps, defaultActionState } from '../legacy/app/globalNav/module';
import { SplunkIntegrationsProvider } from '@splunk/olly-services/lib/services/SplunkIntegrations/SplunkIntegrationsProvider';
import type { History } from 'history';

type AppServiceProps = {
    auth: Auth;
    authToken: string | null;
    baseUrl: string;
    clipboard: Clipboard;
    colorAccessibilityStore?: ReturnType<ColorAccessibilityStore>;
    currentUserStore?: ReturnType<CurrentUserStore>;
    fallbackHistory?: History;
    featureStore: FeatureFlagStore;
    globalNavUpdateStore: ReturnType<GlobalNavUpdateStore>;
    organizationStore: ReturnType<OrganizationStore>;
    overQuotaNotificationStore: ReturnType<OverQuotaNotificationStore>;
    pubSubStore?: PubSubStore;
    relatedContentService?: RelatedContentService;
    salesforceLiveAgentServiceStore?: ReturnType<SalesforceLiveAgentService>;
    sessionCacheStore: SessionCacheStore;
    themeKey?: ColorScheme;
    userAnalytics: ReturnType<UserAnalytics>;
};

type AppProps = {
    themeKey: ThemeProviderProps['colorScheme'];
    TopNavStateContext?: Context<TopNavContextType>;
};

export const AppWithoutRouter = ({ themeKey, TopNavStateContext }: AppProps): JSX.Element => {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();
    const timeoutId = useRef<number>(0);
    const colorAccessibilityClass = useActiveColorAccessibilityType();

    useEffect(() => {
        clearTimeout(timeoutId.current);
        timeoutId.current = window.setTimeout(() => window.dispatchEvent(new Event('resize')));
    }, [width, height]);

    const legacyThemeClasses = `sf-fill-extents sf-ui sf-bootstrap ${themeKey} ${colorAccessibilityClass}`;

    // Memoize routes so that it does not run a complete re-render on useMeasure trigger
    const routes = useMemo(() => <AppRoutes />, []);

    return (
        <div ref={ref} className={legacyThemeClasses}>
            <ThemeProvider colorScheme={themeKey}>
                <SignalviewTopNavContextProvider TopNavStateContext={TopNavStateContext}>
                    {routes}
                </SignalviewTopNavContextProvider>
            </ThemeProvider>
        </div>
    );
};

export const AppRouter: FC = ({ children }) => {
    const angularHashHistory = useMemo(() => createAngularHashHistory(), []);
    return <Router history={angularHashHistory}>{children}</Router>;
};

export const SharedClipboard = React.createContext<Clipboard>({} as Clipboard);

const AppState: FC<AppServiceProps> = ({
    auth,
    authToken,
    baseUrl = '',
    children,
    clipboard,
    colorAccessibilityStore,
    currentUserStore,
    fallbackHistory,
    featureStore,
    globalNavUpdateStore,
    organizationStore,
    overQuotaNotificationStore,
    pubSubStore,
    relatedContentService,
    salesforceLiveAgentServiceStore,
    sessionCacheStore,
    themeKey,
    userAnalytics,
}) => {
    const [orgId, setOrgId] = useState<string>('');
    const [isBroadcasted, broadcast] = useState<boolean>(false);
    const $rootScope = AngularInjector.useInjectedClass<angular.IRootScopeService>('$rootScope');
    const Restangular = AngularInjector.useInjectedClass('Restangular');
    const logService = AngularInjector.instantiate('logService');

    // ToDo +1-1: need to remove once globalNavUpdateService gets deployed on both signalview and olly.
    const globalNavUpdateStoreFallback =
        useMemoGuaranteed<ReturnType<GlobalNavUpdateStore> | void>(() => {
            if (!globalNavUpdateStore) {
                return GlobalNavUpdateStore();
            }
        }, []);

    useLegacyThemeServiceSync(themeKey);

    useEffect(() => {
        logService.logData('INFO', 'Index.tsx -> useEffect: fallbackHistory', fallbackHistory);

        if (fallbackHistory) {
            ngRoute.history = fallbackHistory;
        }
    }, [fallbackHistory, logService]);

    useEffect(() => {
        // Short term hack to support angular migration
        // This event is passed from HTTPService which
        // triggers a digest on completion of every request (default behaviour of $http).

        $rootScope.$on('React:$routeChangeStart', function () {
            const newMatchedOnCurrent = matchPath(window.location.hash.substr(1), {
                path: ngRoute.getRoute().matchedPath,
            });
            const routeChanging = !(ngRoute.params.snapshotID && newMatchedOnCurrent);
            (globalNavUpdateStore || globalNavUpdateStoreFallback).setRouteChanging(routeChanging);
        });

        $rootScope.$on('React:$routeChangeSuccess', function () {
            const isEdit = isSnapshotEdit();
            (globalNavUpdateStore || globalNavUpdateStoreFallback).setGlobalNavProps(
                globalNavProps
            );
            if (!isEdit) {
                (globalNavUpdateStore || globalNavUpdateStoreFallback).update(defaultActionState);
            }
            (globalNavUpdateStore || globalNavUpdateStoreFallback).setRouteChanging(false);
        });

        if (pubSubStore) {
            return pubSubStore.subscribe('REST request finished', function () {
                $rootScope.$applyAsync();
            });
        }
    }, [$rootScope, pubSubStore, globalNavUpdateStore, globalNavUpdateStoreFallback]);

    const sessionStore = useMemoGuaranteed<ReturnType<SessionStore>>(
        () => SessionStore({ authToken, baseUrl }),
        [baseUrl, authToken]
    );

    useEffect(() => {
        organizationStore.get().then((org) => {
            Restangular.setDefaultRequestParams(['get', 'post', 'put'], {
                organizationId: org.id,
            });
            setOrgId(org.id);
            // metric and dimension service in dataSignature.js where being called(sometime) before org available
            // So, this will call metric and dimension service only when org will available
            !isBroadcasted && $rootScope.$broadcast('orgId is set');
            broadcast(true);
        });
    }, [$rootScope, Restangular, isBroadcasted, organizationStore]);

    // ToDo +1-1: need to remove once userAnalytics gets deployed on both signalview and olly.
    const googleAnalytics = useMemoGuaranteed<ReturnType<GoogleAnalytics>>(
        () =>
            !userAnalytics
                ? GoogleAnalytics({
                      disabled: SHOULD_DISABLE_GA,
                      window,
                  })
                : undefined,
        []
    );

    if (!userAnalytics) {
        userAnalytics = UserAnalytics(googleAnalytics);
    }

    const userServiceStore = useMemoGuaranteed<ReturnType<UserStore>>(
        () => UserStore({ authToken, baseUrl }),
        [baseUrl, authToken]
    );

    const metricService = useMemoGuaranteed<MetricService>(
        () => new MetricService(authToken, baseUrl, orgId),
        [authToken, orgId]
    );

    const dimensionService = useMemoGuaranteed<DimensionService>(
        () => new DimensionService(authToken, baseUrl, orgId),
        [authToken, orgId]
    );

    const signalboost = useMemoGuaranteed<Signalboost>(
        () => ({ metric: metricService, dimension: dimensionService }),
        [metricService, dimensionService]
    );

    // 2 reasons to keep this here
    // 1. Fix +1/-1 issue with fallback currentuser store
    // 2. For standalone signalview, utilize userStore and sessionStore instances that are created here
    const currentUserStoreFallback = useMemoGuaranteed<ReturnType<CurrentUserStore>>(
        () =>
            CurrentUserStore({
                DOMAIN_ORGANIZATION_ID,
                baseUrl,
                authToken: auth.authToken(),
                authStore: auth,
                sessionStore,
                sessionCacheStore,
                userStore: userServiceStore,
                window,
                pubSubStore,
            }),
        [auth, pubSubStore, userServiceStore]
    );

    const proxyAuth = useMemoGuaranteed(() => {
        if (!('onWillDeAuth' in auth)) {
            const onWillDeAuthCallbacks: (() => void)[] = [];
            logService.logData(
                'INFO',
                'Index.tsx -> useEffect: fallbackHistory',
                onWillDeAuthCallbacks
            );

            return {
                ...Object(auth),
                ...{
                    onWillDeAuth: (callback: () => void): (() => void) => {
                        {
                            onWillDeAuthCallbacks.push(callback);
                            return (): void => {
                                const callbackIndex = onWillDeAuthCallbacks.indexOf(callback);
                                logService.logData(
                                    'INFO',
                                    'Index.tsx -> useEffect: onWillDeAuth',
                                    callbackIndex
                                );
                                if (callbackIndex >= 0) {
                                    onWillDeAuthCallbacks.splice(callbackIndex, 1);
                                }
                            };
                        }
                    },
                    invokeOnWillDeAuthCallbacks: (): void => {
                        onWillDeAuthCallbacks.forEach((onWillDeAuthCallback) => {
                            onWillDeAuthCallback();
                        });
                    },
                },
            };
        }
    }, [authToken]);

    const signalViewMetricsStore = useMemoGuaranteed<SignalViewMetricsStore>(() => {
        return new SignalViewMetricsStore(proxyAuth || auth, baseUrl, window.performance);
    }, [authToken]);

    // +1/-1 fix, if signalview merged first, can be removed once both olly and signalview are deployed to production.
    const colorAccessibilityStoreFallback = useMemoGuaranteed<
        ReturnType<ColorAccessibilityStore> | undefined
    >(
        () =>
            !colorAccessibilityStore
                ? ColorAccessibilityStore(currentUserStore || currentUserStoreFallback)
                : undefined,
        [currentUserStore]
    );

    useEffect(() => {
        return (): void => {
            signalViewMetricsStore.unSubscribeWillDeAuth();
        };
    }, [signalViewMetricsStore]);

    const overQuotaNotificationStoreFallback = useMemoGuaranteed<
        ReturnType<OverQuotaNotificationStore>
    >(() => OverQuotaNotificationStore({ authToken, baseUrl }), [baseUrl, authToken]);

    useEffect(
        () => (): void => overQuotaNotificationStoreFallback.clear(),
        [overQuotaNotificationStoreFallback]
    );

    const credentialV2Store = useMemoGuaranteed<ReturnType<CredentialV2Store>>(
        () => CredentialV2Store({ authToken, baseUrl }),
        [authToken]
    );
    const loginStore = useMemoGuaranteed<ReturnType<LoginStore>>(
        () =>
            LoginStore({
                currentUser: currentUserStore || currentUserStoreFallback,
                authToken,
                baseUrl,
                navigator: window.navigator,
            }),
        [currentUserStore, currentUserStoreFallback]
    );

    let wrapped = children;

    if (pubSubStore) {
        wrapped = (
            <PubSubStoreProvider serviceStoreInstance={pubSubStore}>{wrapped}</PubSubStoreProvider>
        );
    }

    // ToDo +1-1: need to remove once salesforceLiveAgentService gets deployed on both signalview and olly.
    const salesforceLiveAgentServiceStoreFallback = useMemoGuaranteed<
        ReturnType<SalesforceLiveAgentService>
    >((): any => {
        if (!salesforceLiveAgentServiceStore) {
            return SalesforceLiveAgentService(
                currentUserStoreFallback,
                (window as any).embedded_svc,
                featureStore,
                (window as any).signalviewConfig
            );
        }
    }, []);

    useEffect(() => {
        const salesforceLiveAgentChat = SalesforceLiveAgentChat(
            currentUserStoreFallback,
            featureStore,
            organizationStore,
            (window as any).embedded_svc,
            salesforceLiveAgentServiceStoreFallback
        );
        !salesforceLiveAgentServiceStore && salesforceLiveAgentChat.initSnapIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const httpClient = useMemo(
        () =>
            getInstrumentedHttpServiceClient({
                authToken: auth.authToken(),
                baseUrl: API_URL,
                serviceName: 'IMM/legacy',
            }),
        [auth]
    );

    return (
        <AuthStoreProvider serviceStoreInstance={proxyAuth || auth}>
            <HTTPProvider serviceStoreInstance={httpClient}>
                <GlobalNavUpdateStoreProvider
                    serviceStoreInstance={globalNavUpdateStore || globalNavUpdateStoreFallback}
                >
                    <CredentialV2StoreProvider serviceStoreInstance={credentialV2Store}>
                        <CurrentUserStoreProvider
                            serviceStoreInstance={currentUserStore || currentUserStoreFallback}
                        >
                            <LoginStoreProvider serviceStoreInstance={loginStore}>
                                <FeatureFlagsProvider featureStoreInstance={featureStore}>
                                    <OverQuotaNotificationStoreProvider
                                        serviceStoreInstance={
                                            overQuotaNotificationStore ||
                                            overQuotaNotificationStoreFallback
                                        }
                                    >
                                        <ColorAccessibilityStoreProvider
                                            serviceStoreInstance={
                                                colorAccessibilityStore ||
                                                (colorAccessibilityStoreFallback as ReturnType<ColorAccessibilityStore>)
                                            }
                                        >
                                            <UserAnalyticsProvider
                                                serviceStoreInstance={userAnalytics}
                                            >
                                                <SignalboostBaseServiceProvider
                                                    serviceStoreInstance={signalboost}
                                                >
                                                    <SignalViewMetricsStoreProvider
                                                        serviceStoreInstance={
                                                            signalViewMetricsStore
                                                        }
                                                    >
                                                        <OrganizationStoreProvider
                                                            serviceStoreInstance={organizationStore}
                                                        >
                                                            <SalesforceLiveAgentServiceProvider
                                                                serviceStoreInstance={
                                                                    salesforceLiveAgentServiceStore ||
                                                                    salesforceLiveAgentServiceStoreFallback
                                                                }
                                                            >
                                                                <UserStoreProvider
                                                                    serviceStoreInstance={
                                                                        userServiceStore
                                                                    }
                                                                >
                                                                    <SessionStoreProvider
                                                                        serviceStoreInstance={
                                                                            sessionStore
                                                                        }
                                                                    >
                                                                        <SessionCacheStoreProvider
                                                                            serviceStoreInstance={
                                                                                sessionCacheStore
                                                                            }
                                                                        >
                                                                            <RelatedContentServiceProvider
                                                                                relatedContentService={
                                                                                    relatedContentService
                                                                                }
                                                                            >
                                                                                <SharedClipboard.Provider
                                                                                    value={
                                                                                        clipboard
                                                                                    }
                                                                                >
                                                                                    <SplunkIntegrationsProvider
                                                                                        authToken={auth.authToken()}
                                                                                        baseUrl={
                                                                                            API_URL
                                                                                        }
                                                                                    >
                                                                                        {wrapped}
                                                                                    </SplunkIntegrationsProvider>
                                                                                </SharedClipboard.Provider>
                                                                            </RelatedContentServiceProvider>
                                                                        </SessionCacheStoreProvider>
                                                                    </SessionStoreProvider>
                                                                </UserStoreProvider>
                                                            </SalesforceLiveAgentServiceProvider>
                                                        </OrganizationStoreProvider>
                                                    </SignalViewMetricsStoreProvider>
                                                </SignalboostBaseServiceProvider>
                                            </UserAnalyticsProvider>
                                        </ColorAccessibilityStoreProvider>
                                    </OverQuotaNotificationStoreProvider>
                                </FeatureFlagsProvider>
                            </LoginStoreProvider>
                        </CurrentUserStoreProvider>
                    </CredentialV2StoreProvider>
                </GlobalNavUpdateStoreProvider>
            </HTTPProvider>
        </AuthStoreProvider>
    );
};

/**
 * Container to be executed at top level on olly side or
 * StandAlone Signalview side.
 *
 * Since Olly is consuming signalview's angular services in the short-term
 * and signalview is not guaranteed to always be rendered, the best place
 * to setup and link this app's @splunk/olly-services and angular is
 * within olly itself.
 *
 * NOTE: Once we cut-off ties with angular services,
 * this container will no longer be required and can be discarded and removed
 * from olly. Add <AppState> directly as a wrapper in <App>
 * below instead.
 */
export const SetupSignalViewMigratedServices: FC<AppServiceProps> = ({ children, ...props }) => {
    return (
        <AppState {...props}>
            <NgInjectMigratedServices>{children}</NgInjectMigratedServices>
        </AppState>
    );
};

export const App = (props: AppProps): JSX.Element => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        reactNgBridge.onInitialize(() => {
            setInitialized(true);
        });
    }, []);

    return initialized ? (
        <AppRouter>
            <AppWithoutRouter {...props} />
        </AppRouter>
    ) : (
        <></>
    );
};
