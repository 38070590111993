import { ngRoute } from '../../../../app/routing/ngRoute';
import { loginRedirect, supportService } from '@splunk/olly-services/lib';

export default [
    '$location',
    '$log',
    '$scope',
    'security',
    'googleAuth',
    'globalNavUpdateService',
    'currentUser',
    'SUBDOMAIN_ENABLED',
    'DOMAIN_ORGANIZATION_ID',
    'routeParameterService',
    'organizationService',
    'DOMAIN_INFO',
    'SSO_INFO',
    '$http',
    '$window',
    'API_URL',
    'ssoRedirect',
    'SUBDOMAIN_LOGIN_SEARCH_ENABLED',
    '$rootScope',
    'ssoService',
    'pathValidateService',
    'communityErrorService',
    'logService',
    function (
        $location,
        $log,
        $scope,
        security,
        googleAuth,
        globalNavUpdateService,
        currentUser,
        SUBDOMAIN_ENABLED,
        DOMAIN_ORGANIZATION_ID,
        routeParameterService,
        organizationService,
        DOMAIN_INFO,
        SSO_INFO,
        $http,
        $window,
        API_URL,
        ssoRedirect,
        SUBDOMAIN_LOGIN_SEARCH_ENABLED,
        $rootScope,
        ssoService,
        pathValidateService,
        communityErrorService,
        logService
    ) {
        if ($scope.isModal) {
            // login form is in a modal.
            // hide the modal when URL changes.
            $rootScope.$on('$locationChangeStart', function () {
                security.closeLoginDialog(false);
            });
        } else {
            // hide navbar when login form is not in a modal
            globalNavUpdateService.update({ hideAll: true });
        }
        $scope.subdomainEnabled = SUBDOMAIN_ENABLED && SUBDOMAIN_LOGIN_SEARCH_ENABLED;

        ssoRedirect.setIfEmpty($location.url());

        $scope.enabled = false;
        const isIDP = $location.path().match(/^\/idp\//);

        $scope.params = angular.copy($location.search());

        // This is the URL from which the user initiates the login flow
        $scope.userInitiatedURL = loginRedirect.getRedirectURL();

        // This controller flow get invoked multiple times when the user is not
        // logged in because of many user shims
        // 1. Only store the first URL from which the user came from
        // 2. Don't store the known un-authenticated URLs
        if (!$scope.userInitiatedURL && pathValidateService.isAuthenticatedPath($location.path())) {
            loginRedirect.setRedirectURLIfNotFound($location.url());
        }

        if (isIDP) {
            if (!$scope.params.issuer) {
                $log.warn('Issuer is missing from the parameters');
            }

            if (pathValidateService.isAuthenticatedPath($location.path())) {
                ssoRedirect.setIdpRequestInfo($scope.params.issuer, $location.url());
            }
        }
        $scope.requester = $scope.params.requester;

        $scope.hasGoogle = googleAuth.hasGoogle() && (!DOMAIN_INFO || DOMAIN_INFO.hasGoogleSignIn);

        $scope.initGoogleAuth = googleAuth.initGoogleAuth;

        $scope.page = 'main'; // by default is main page.
        function processPage(nosso) {
            if (nosso) {
                $scope.page = 'nosso';
            } else if (DOMAIN_INFO) {
                if (SSO_INFO.length || DOMAIN_INFO.hasGoogleSignIn) {
                    $scope.page = 'sso';
                } else {
                    $scope.page = 'nossoavailable';
                }
            } else {
                $scope.page = 'main';
            }
        }
        processPage($scope.params.nosso);

        const watcher = routeParameterService.registerRouteWatch(
            'nosso',
            function (changePath, changed) {
                processPage(changed.nosso === ($scope.page === 'sso'));
            }
        );

        $scope.$on('$destroy', function () {
            watcher();
        });

        $scope.SSO_INFO = SSO_INFO;

        // try to get user id and see if it works
        currentUser
            .id()
            .then(() => {
                if (isIDPFlow()) {
                    loginAndReturnAssertion();
                } else if ($location.path().match(/sign/)) {
                    // if we are redirecting to home, we want to remove any query params that will only cause
                    // confusion and worst case, wrong url
                    $location.url('/home');
                } else {
                    logService.logData(
                        'INFO',
                        'Login Controller.js -> currentUser Service Id: reload when url doesnt match from currentUser.id()',
                        url
                    );
                    ngRoute.reload();
                }
            })
            .catch(() => {
                // it doesn't work, let's show signin page
                $scope.enabled = true;
                supportService.removeSupportOrg();
            });
        $scope.user = {};
        $scope.authError = null;
        $scope.message = '';
        $scope.processingSignIn = false;

        function loginAndReturnAssertion() {
            const idpInfo = ssoRedirect.getIdpInfoAndClear();
            const issuer = $scope.params.issuer || idpInfo.issuer;
            const relayState = $scope.params.relaystate || null;
            organizationService.get().then((org) => {
                if (org.accountType === 'TRIAL') {
                    //open modal and redirect to home
                    communityErrorService.openModal();
                    $location.search('issuer', null).replace();
                    $location.path('/home');
                } else {
                    ssoService.getAssertionAndRedirect(issuer, relayState).catch(() => {
                        //Error was thrown so alert and show signin page again
                        $scope.enabled = true;
                        $location.search('issuer', null).replace();
                        $window.alert('Something went wrong');
                    });
                }
            });
        }

        $scope.redirectToSignup = function () {
            $location.path('/signup');
            if (angular.isDefined($scope.$parent.$parent.$dismiss)) {
                $scope.$parent.$parent.$dismiss();
            }
        };

        $scope.getRedirect = function () {
            if (isIDPFlow()) {
                return null;
            } else {
                if ($location.path().match(/signin|signup/)) {
                    return loginRedirect.getRedirectURL() || '/home';
                } else {
                    logService.logData(
                        'INFO',
                        'LoginController.js -> getRedirect -> getting redirection url',
                        $location.path()
                    );
                    return $location.path();
                }
            }
        };

        $scope.login = function () {
            $scope.authError = null;
            $scope.googleAuthError = null;
            const redirect = $scope.getRedirect();
            $scope.message = 'Signing in ...';
            $scope.processingSignIn = true;
            security
                .login($scope.user.email, $scope.user.password, DOMAIN_ORGANIZATION_ID, redirect)
                .then(
                    function (status) {
                        $scope.message = '';
                        if (status.success) {
                            $scope.hideLoginForm = true;
                        }
                        if (!status.success) {
                            $scope.processingSignIn = false;
                            $scope.authError = 'Sign-in failed.';
                            if (status.data && status.data.message) {
                                $scope.authError += ' ' + status.data.message;
                            }
                        } else if (isIDPFlow()) {
                            currentUser
                                .id()
                                .then(loginAndReturnAssertion)
                                .catch(() => {
                                    $location.path('/');
                                });
                        }
                    },
                    function (x) {
                        $scope.processingSignIn = false;
                        // debug to fix the [object object] error message
                        console.log('signinerr', JSON.stringify(x));
                        $scope.authError =
                            'An error occurred: ' +
                            (x && x.data && x.data.message ? x.data.message : x);
                    }
                );
        };

        $scope.ssoLogin = function (id, type) {
            $scope.ssoState = 'loading';
            if (type === 'saml') {
                $http.post(API_URL + '/v2/saml/auth', { providerId: id }).then(
                    (response) => {
                        logService.logData(
                            'INFO',
                            'LoginController.js -> ssoLogin -> getting response url',
                            response
                        );
                        $window.location.href = response.data.redirectUrl;
                    },
                    () => {
                        $scope.ssoState = 'error';
                    }
                );
            } else if (type === 'oidc') {
                const url = `${API_URL}/v2/session/oidc/init?integrationId=${id}&orgId=${DOMAIN_ORGANIZATION_ID}`;

                $http.get(url).then(
                    (response) => {
                        $window.location.href = response.data.redirectUrl;
                    },
                    () => {
                        $scope.ssoState = 'error';
                    }
                );
            }
        };

        $scope.noSSO = function () {
            if ($scope.isModal) {
                $location.url('/signin?nosso=1');
            } else {
                $location.search({ nosso: 1 });
            }
        };

        $scope.googleAuthError = null;

        const googleCode = $scope.params.google;
        if (googleCode) {
            $location.search({ google: null });
            $scope.googleAuthError = null;
            $scope.processingGoogleSignIn = true;
            security
                .googleExchange(googleCode, DOMAIN_ORGANIZATION_ID, $scope.getRedirect())
                .then(function (result) {
                    if (!result.success) {
                        $scope.processingGoogleSignIn = false;
                        $scope.authError = null;
                        $scope.googleAuthError = 'Sign-in failed.';
                        if (result.data && result.data.message) {
                            $scope.googleAuthError += ' ' + result.data.message;
                        }
                        // sign the user out of google because we cannot log him/her in.
                    } else if (isIDPFlow()) {
                        currentUser
                            .id()
                            .then(loginAndReturnAssertion)
                            .catch(() => {
                                $location.path('/');
                            });
                    }
                });
        }

        function isIDPFlow() {
            return isIDP || ssoRedirect.isIdpFlow();
        }
    },
];
