angular.module('sfx.ui').service('integrationFilterClipboardService', [
    '$rootScope',
    function ($rootScope) {
        let copiedFilter;

        function copyFilter(filter) {
            copiedFilter = angular.copy(filter);
        }

        function getFilter() {
            return angular.copy(copiedFilter);
        }

        function clearFilter() {
            copiedFilter = null;
        }

        function isPasteAvailable() {
            return !!copiedFilter;
        }

        $rootScope.$on('current organization changed', clearFilter);

        return {
            copyFilter,
            getFilter,
            clearFilter,
            isPasteAvailable,
        };
    },
]);
