angular.module('chartbuilderUtil', [
    'signalboost',
    'signalview.analytics',
    'sfUtil',
    'sfx.data',
    'sfx.ui',
    'mustache',
    'signalview.murmurHash',
    'signalview.chart',
    'signalview.urlOverrides',
    'signalview.variables',
    'signalview-utils.signalflowv2',
]);
