export default [
    '$window',
    '$log',
    '$timeout',
    '$rootScope',
    'moment',
    'security',
    'imageService',
    'currentUser',
    'appNotificationService',
    'orgNotificationService',
    'organizationService',
    'userV2Service',
    'auth',
    'logService',
    function (
        $window,
        $log,
        $timeout,
        $rootScope,
        moment,
        security,
        imageService,
        currentUser,
        appNotificationService,
        orgNotificationService,
        organizationService,
        userV2Service,
        auth,
        logService
    ) {
        const svc = {
            helpUrl: 'https://signalfx.zendesk.com',
            homeHref: '#/mypage',
            signOut,
        };

        let homePagePromise;
        let recentDashboardIdPromise;
        let authChangeTimeoutId;
        let recentDashboardConfigs;
        let updateOrgListTimeoutId;

        resetUserInfo();
        resetOrgInfo();
        $rootScope.$on('migrated services initialized', updateUserRelated);

        // auth reset events
        $rootScope.$on('current organization changed', setAuthenticated);

        $rootScope.$on('migrated services initialized', function () {
            auth.onAuth(setAuthenticated);
            auth.onDeAuth(unsetAuthenticated);

            auth.onAuth(authChanged);
            auth.onDeAuth(authChanged);
        });

        // recent dashboard events
        $rootScope.$on('dashboard name changed', clearRecentsThatMatch);
        $rootScope.$on('dashboard deleted', clearRecentsThatMatch);

        // favorite dashboard events
        $rootScope.$on('dashboard favorite add', clearFavorites);
        $rootScope.$on('dashboard favorite remove', clearFavorites);

        // home page event
        $rootScope.$on('initialize home page link', initializeHomePage);

        // user change
        $rootScope.$on('current user changed', updateUserRelated);

        // org change
        $rootScope.$on('featureStore initialized', handleOrgChange);

        $rootScope.$on('migrated services initialized', function () {
            initializeUserDisplay().then(initializeOrgForUser);
        });

        return svc;

        function resetOrgInfo() {
            recentDashboardConfigs = null;
            svc.recentDashboards = null;
            svc.favoriteDashboards = null;
            svc.orgId = '';
            svc.isAdmin = false;
            svc.homeHref = '#/mypage';
            homePagePromise = null;

            if (recentDashboardIdPromise) {
                $timeout.cancel(recentDashboardIdPromise);
                recentDashboardIdPromise = null;
            }
            appNotificationService.clear();
            orgNotificationService.stop();
        }

        function resetUserInfo() {
            svc.currentUserImage = '';
            svc.currentUserName = '';
            svc.email = null;
        }

        function updateSuperUserData() {
            currentUser.isSuperUser().then(
                function (isSuperUser) {
                    svc.isSuperUser = isSuperUser;
                    if (isSuperUser) {
                        svc.helpUrl = 'https://signalfx.zendesk.com/access/normal';
                    }
                },
                function (e) {
                    svc.isSuperUser = false;
                    $log.error('failed getting super user info', e);
                }
            );
        }

        function updateSupport() {
            currentUser.isSupport().then(
                function (customersupport) {
                    svc.isSupport = customersupport;
                },
                function (e) {
                    $log.error('Failed checking for support', e);
                    svc.isSupport = false;
                }
            );
        }

        function updateUserRelated() {
            updateSupport();
            updateSuperUserData();
        }

        function updateOrgList() {
            if (updateOrgListTimeoutId) {
                $timeout.cancel(updateOrgListTimeoutId);
                updateOrgListTimeoutId = null;
            }

            userV2Service.orgs().then((orgs) => {
                svc.orgs = orgs;
            });
        }

        function delayedUpdateOrgList() {
            updateOrgListTimeoutId = $timeout(updateOrgList, 5000);
        }

        function initializeUserDisplay() {
            return currentUser
                .get()
                .then((user) => {
                    svc.currentUserImage = imageService.getProfileImageForEmail(user.email);
                    svc.email = user.email;
                    svc.currentUserName = user.fullName || user.email;
                })
                .catch(function () {
                    svc.currentUserName = '';
                    svc.currentUserImage = '';
                    svc.email = null;
                    $log.error('Failed initializing user display.');
                });
        }

        function initializeHomePage() {
            if (homePagePromise) {
                return homePagePromise;
            }
            homePagePromise = currentUser.orgPreferences().then((orgPrefs) => {
                if (orgPrefs.sf_homePagePath) {
                    svc.homeHref = '#' + orgPrefs.sf_homePagePath;
                    homePagePromise = null;
                } else {
                    return currentUser
                        .orgId()
                        .then((orgId) => {
                            svc.homeHref = '#/dashboards/' + orgId;
                            homePagePromise = null;
                        })
                        .catch(function () {
                            $log.error('Failed fetching homepage.');
                            homePagePromise = null;
                        });
                }
            });
            return homePagePromise;
        }

        function initializeOrganizationDisplay() {
            organizationService
                .get()
                .then((org) => {
                    svc.currentOrg = org.organizationName;
                    svc.orgId = org.id;
                    return currentUser.isAdmin();
                })
                .then((isAdmin) => {
                    svc.isAdmin = isAdmin;
                });
        }

        function initializeOrgNotification() {
            orgNotificationService.start(60000 * 5); //every 5 mnts
        }

        function showClassicUIDeprecation() {
            const classicUIDeprecationBanner = $window.signalviewConfig?.classicUIDeprecationBanner;
            const deprecationEndDate = new Date(
                classicUIDeprecationBanner?.endDate || '09/30/2022'
            );
            const deprecationStartDate = new Date(
                classicUIDeprecationBanner?.startDate || '09/01/2022'
            );
            const weekBeforeDeprecation = moment(deprecationEndDate).subtract(7, 'days').toDate();
            const today = new Date();

            if (today >= deprecationStartDate && today <= deprecationEndDate) {
                const classicUIDeprecationScope = 'classicuideprecationnotification';
                const bannerType = today >= weekBeforeDeprecation ? 'warn' : 'info';
                const dateString = moment(deprecationEndDate).format('MMMM D, YYYY');
                appNotificationService.removeScope(classicUIDeprecationScope);
                appNotificationService.add({
                    message: `The Classic user interface will no longer be available after ${dateString}.`,
                    messageClass: bannerType,
                    messageScope: classicUIDeprecationScope,
                    userDismissable: true,
                    dismissAfterMs: 0,
                    global: true,
                    priority: 'medium',
                    link: {
                        text: 'View Product News',
                        href: `https://quickdraw.splunk.com/redirect/?product=Observability&location=sfx.classic.deprecation&version=current`,
                        target: '_blank',
                    },
                });
            }
        }

        function initializeOrgForUser() {
            initializeHomePage();
            initializeOrgNotification();
            initializeOrganizationDisplay();
            showClassicUIDeprecation();
            delayedUpdateOrgList();
        }

        function setAuthenticated() {
            logService.logData(
                'INFO',
                'GloblNavService.js -> setAuthenticated: checking auth status',
                svc
            );
            if (svc.auth) return;
            svc.auth = true;
            authChanged();
        }

        function unsetAuthenticated() {
            if (!svc.auth) return;
            svc.auth = false;
            authChanged();
        }

        function authChanged() {
            resetOrgInfo();
            if (!svc.auth) {
                resetUserInfo();
            } else {
                $timeout.cancel(authChangeTimeoutId);

                authChangeTimeoutId = $timeout(function () {
                    initializeUserDisplay().then(initializeOrganizationDisplay);
                    currentUser.orgId().then((orgId) => (svc.orgId = orgId));
                }, 500);
            }
        }

        function signOut() {
            security.logout('/signin');
        }

        function clearFavorites() {
            svc.favoriteDashboards = null;
        }

        function clearRecentsThatMatch(evt, data) {
            if (recentDashboardConfigs && recentDashboardConfigs.indexOf(data.id) !== -1) {
                recentDashboardConfigs = null;
                svc.recentDashboards = null;
            }
        }

        function handleOrgChange(evt, organization) {
            resetOrgInfo();
            svc.orgId = organization.id;
            initializeOrgForUser();
            // user related data also depends on org context (like super power and support)
            updateUserRelated();
        }
    },
];
