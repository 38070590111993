import React, { FC, useState, useEffect, useRef } from 'react';
import LogObserverUsageComponent from './LogObserverUsageComponent';
import { useFetchOrgInfo } from './useFetchOrgInfo';
import { AngularInjector } from '../../../../../common/AngularUtils';
import useInterval from './useInterval';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../../app/routing/ngRoute';

type LogObserverUsageContainerProps = { themeKey?: 'dark' | 'light'; editable?: boolean };
type DataPoint = { x: Date; y: number };

const LogObserverUsageContainer: FC<LogObserverUsageContainerProps> = ({ editable }) => {
    const { orgInfo, isLoading, fetchOrgInfo } = useFetchOrgInfo();
    const [ingestUsages, setIngestUsages] = useState<DataPoint[]>([]);
    const [indexUsages, setIndexUsages] = useState<DataPoint[]>([]);
    const [orgId, setOrgId] = useState('');

    const organizationService = AngularInjector.useInjectedClass('organizationService');
    const signalStream = AngularInjector.useInjectedClass('signalStream');
    const featureEnabled = AngularInjector.useInjectedClass('featureEnabled');
    const indexDataCache = useRef<DataPoint[]>([]);
    const ingestDataCache = useRef<DataPoint[]>([]);
    const newRbacExperienceEnabled = featureEnabled('newRbacExperience');
    const rbacUtilsService = AngularInjector.instantiate('rbacUtilsService');

    useEffect(() => {
        if (newRbacExperienceEnabled) {
            rbacUtilsService.checkCapabilityAndRedirect(Capability.READ_USAGE);
        } else if (!editable) {
            ngRoute.history?.replace('/NotFound');
        }
    }, [editable, newRbacExperienceEnabled, rbacUtilsService]);

    useEffect(() => {
        fetchOrgInfo();
    }, [fetchOrgInfo]);

    useEffect(() => {
        organizationService.get().then((org: any) => {
            setOrgId(org.id);
        });
    }, [organizationService]);

    useEffect(() => {
        ingestDataCache.current = [];
    }, [ingestUsages]);

    useEffect(() => {
        indexDataCache.current = [];
    }, [indexUsages]);

    useInterval(() => {
        // throttle rendering of the usage charts to every 200ms
        if (ingestDataCache.current && ingestDataCache.current.length > 0) {
            setIngestUsages((prev: DataPoint[]) => [...prev, ...ingestDataCache.current]);
        }

        if (indexDataCache.current && indexDataCache.current.length > 0) {
            setIndexUsages((prev: DataPoint[]) => [...prev, ...indexDataCache.current]);
        }
    }, 200);

    useEffect(() => {
        const fetchMetricData = (
            signalFlow: string,
            dataCache: React.MutableRefObject<DataPoint[]>
        ): void => {
            const now = new Date();
            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
            const signalFlowQuery = signalFlow;
            const range = Date.now() - firstDay;
            const oneHourInMs = 3600000;
            const jobOpts = {
                signalFlowText: signalFlowQuery,
                // signalStream subtracts (5 * resolution) from the start time. But the chart should show the data in this calendar month only.
                historyrange: range - 5 * oneHourInMs,
                callback: (data: { timestamp: number; value: number }): void => {
                    const newData = {
                        x: new Date(data.timestamp),
                        y: data.value / Math.pow(10, 9), // 1 GB is equal to 10^9 bytes, or 1 billion bytes. we are converting bytes to gigabytes (GB) here
                    };
                    dataCache.current = [...dataCache.current, newData];
                },
                metaDataUpdated: (): void => {},
                resolution: oneHourInMs,
            };
            signalStream.stream(jobOpts);
        };

        const indexDataQuery =
            "A = data('sf.org.log.numMessageBytesIndexed').sum(cycle='month', cycle_start='1d', partial_values='True').publish(label='A')";
        const ingestDataQuery =
            "A = data('sf.org.log.numMessageBytesReceived').sum(cycle='month', cycle_start='1d', partial_values='True').publish(label='A')";
        fetchMetricData(indexDataQuery, indexDataCache);
        fetchMetricData(ingestDataQuery, ingestDataCache);
    }, [signalStream]);

    return (
        <LogObserverUsageComponent
            orgInfo={{ orgInfo, isLoading }}
            ingestUsages={ingestUsages}
            indexUsages={indexUsages}
            orgId={orgId}
            featureEnabled={featureEnabled}
        />
    );
};

export default LogObserverUsageContainer;
