export default {
    // Find the index of given x value in dygraph.file_ array.
    // Perform flexible match to handle cases there's no exact match.
    // (the value was rounded, etc.)
    findMatchingXIndex(x, dygraph) {
        let delta = Infinity;
        for (let i = 1; i < dygraph.file_.length; i++) {
            const tsms = dygraph.file_[i][0].getTime();
            if (Math.abs(x - dygraph.toDomXCoord(tsms)) > delta) {
                return i - 1;
            }
            delta = Math.abs(x - dygraph.toDomXCoord(tsms));
        }
    },

    // Interpolate a y-value based on values of the adjacent points in a series.
    interpolateY(xIdx, setIdx, dygraph) {
        if (dygraph.file_[xIdx][setIdx] !== null) {
            return dygraph.toDomYCoord(dygraph.file_[xIdx][setIdx]);
        }

        const adj = getAdjacentPoints(xIdx, setIdx, dygraph);

        const gradient = (adj.right.y - adj.left.y) / (adj.right.x - adj.left.x);
        const xCoord = dygraph.toDomXCoord(dygraph.file_[xIdx][0].getTime());

        return adj.left.y + (xCoord - adj.left.x) * gradient;
    },

    findIntersect(x, upperY, lowerY, upperSetIdx, lowerSetIdx, g) {
        const xIdx = this.findMatchingXIndex(x, g);
        const upperAdj = getAdjacentPoints(xIdx, upperSetIdx, g);
        const upperLine = {
            p1: {
                x,
                y: upperY,
            },
            p2: upperAdj.left,
        };

        let lowerAdj = getAdjacentPoints(xIdx, lowerSetIdx, g);
        const lowerLine = {
            p1: {
                x,
                y: lowerY,
            },
            p2: lowerAdj.left,
        };

        // The intervals are not identical in two within threshold sets,
        // so we need to traverse the lower side to seek the one that actually crosses the upper line.
        while (
            lowerLine.p2.x > upperLine.p2.x &&
            !doIntersect(upperLine.p1, upperLine.p2, lowerLine.p1, lowerLine.p2)
        ) {
            lowerAdj = getAdjacentPoints(lowerAdj.left.idx, lowerSetIdx, g);
            lowerLine.p1 = lowerLine.p2;
            lowerLine.p2 = lowerAdj.left;
        }

        if (doIntersect(upperLine.p1, upperLine.p2, lowerLine.p1, lowerLine.p2)) {
            return getIntersection(upperLine.p1, upperLine.p2, lowerLine.p1, lowerLine.p2);
        }

        return null;
    },

    renderThresholds(thresholdPaths, currentSetIdx, withinSetIdx, g, ctx) {
        const pathsInPoints = collectThresholdPoints(
            thresholdPaths,
            currentSetIdx,
            withinSetIdx,
            g
        );
        renderThresholdPaths(pathsInPoints, ctx);
    },
};

function getAdjacentPoints(xIdx, setIdx, dygraph) {
    let left, right;

    let lx = xIdx;
    while (lx - 1 >= 0 && !dygraph.file_[--lx][setIdx]);
    if (lx >= 0) {
        left = {
            idx: lx,
            x: dygraph.toDomXCoord(dygraph.file_[lx][0].getTime()),
            y: dygraph.toDomYCoord(dygraph.file_[lx][setIdx]),
        };
    }

    let rx = xIdx;
    while (rx + 1 < dygraph.file_.length && !dygraph.file_[++rx][setIdx]);
    if (rx < dygraph.file_.length) {
        right = {
            idx: rx,
            x: dygraph.toDomXCoord(dygraph.file_[rx][0].getTime()),
            y: dygraph.toDomYCoord(dygraph.file_[rx][setIdx]),
        };
    }

    return {
        left,
        right,
    };
}

function onSegment(sp1, sp2, p) {
    if (
        p.x <= Math.max(sp1.x, sp2.x) &&
        p.x >= Math.min(sp1.x, sp2.x) &&
        p.y <= Math.max(sp1.y, sp2.y) &&
        p.y >= Math.min(sp1.y, sp2.y)
    ) {
        return true;
    }
    return false;
}

function orientation(p1, p2, p3) {
    const val = (p2.y - p1.y) * (p3.x - p2.x) - (p2.x - p1.x) * (p3.y - p2.y);

    if (val > 0) {
        return 1;
    } else if (val < 0) {
        return 2;
    }

    return 0;
}

function getIntersection(ap1, ap2, bp1, bp2) {
    const a1 = ap2.y - ap1.y;
    const b1 = ap1.x - ap2.x;
    const c1 = a1 * ap1.x + b1 * ap1.y;
    const a2 = bp2.y - bp1.y;
    const b2 = bp1.x - bp2.x;
    const c2 = a2 * bp1.x + b2 * bp1.y;

    const determinant = a1 * b2 - a2 * b1;

    if (determinant !== 0) {
        return {
            x: (b2 * c1 - b1 * c2) / determinant,
            y: (a1 * c2 - a2 * c1) / determinant,
        };
    }
}

function doIntersect(ap1, ap2, bp1, bp2) {
    const o1 = orientation(ap1, ap2, bp1);
    const o2 = orientation(ap1, ap2, bp2);
    const o3 = orientation(bp1, bp2, ap1);
    const o4 = orientation(bp1, bp2, ap2);

    if (o1 !== o2 && o3 !== o4) {
        return true;
    }

    if (
        (o1 === 0 && onSegment(ap1, bp1, ap2)) ||
        (o2 === 0 && onSegment(ap1, bp2, ap2)) ||
        (o3 === 0 && onSegment(bp1, ap1, bp2)) ||
        (o4 === 9 && onSegment(bp1, ap2, bp2))
    ) {
        return true;
    }

    return false;
}

function collectThresholdPoints(thresholdPaths, currentSetIdx, withinSetIdx, g) {
    const pathsInPoints = [];

    for (let i = 0; i < thresholdPaths.length; i++) {
        const openingPoints = [thresholdPaths[i].leftIntersect];
        const closingPoints = [];
        let isCollecting = false;

        for (
            let j = thresholdPaths[i].leftIntersectIdx;
            j <= thresholdPaths[i].rightIntersectIdx;
            j++
        ) {
            const tsms = g.file_[j][0].getTime();
            const domXCoord = g.toDomXCoord(tsms);

            if (!isCollecting && domXCoord <= thresholdPaths[i].rightIntersect.x) {
                isCollecting = true;
            } else if (domXCoord <= thresholdPaths[i].leftIntersect.x) {
                break;
            }

            if (g.file_[j][currentSetIdx]) {
                if (isCollecting) {
                    openingPoints.push({
                        x: domXCoord,
                        y: g.toDomYCoord(g.file_[j][currentSetIdx]),
                    });
                }
            }

            if (g.file_[j][withinSetIdx]) {
                if (isCollecting) {
                    closingPoints.push({
                        x: domXCoord,
                        y: g.toDomYCoord(g.file_[j][withinSetIdx]),
                    });
                }
            }
        }

        if (thresholdPaths[i].rightIntersect.y) {
            openingPoints.push(thresholdPaths[i].rightIntersect);
        }

        pathsInPoints.push(openingPoints.concat(closingPoints.reverse()));
    }

    return pathsInPoints;
}

function renderThresholdPaths(paths, ctx) {
    for (let i = 0; i < paths.length; i++) {
        ctx.beginPath();
        ctx.lineWidth = 0;
        ctx.moveTo(paths[i][0].x, paths[i][0].y);
        for (let j = 1; j < paths[i].length; j++) {
            ctx.lineTo(paths[i][j].x, paths[i][j].y);
        }
        ctx.fill();
        ctx.closePath();
    }
}
