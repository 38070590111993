angular
    .module('visualizationOptions', [])
    .service('uiModelToVisualizationOptionsService', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.uiToVisualizationOptions;
        },
    ])
    .service('visualizationOptionsToUIModel', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.visualizationOptionsToUI;
        },
    ])
    .service('publishLabelOptionsToPlot', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.publishLabelOptionsToPlot;
        },
    ])
    .service('eventPublishLabelOptionsToPlot', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.eventPublishLabelOptionsToPlot;
        },
    ])
    .service('systemMapLabelOptionsToPlot', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.systemMapLabelOptionsToPlot;
        },
    ])
    .service('systemMapLabelOptionsToPlot', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.systemMapLabelOptionsToPlot;
        },
    ])
    .service('convertV1ToV2Detector', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.detector.convertV1ToV2;
        },
    ]);
