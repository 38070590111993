import { react2angular } from 'react2angular';
import paginateFilter from './paginateFilter';
import initService from './initService';
import dashboardIndexPage from './dashboardIndexPage';
import dashboardIndexFormatter from './dashboardIndexFormatter';
import dashboardGroupsColumns from './dashboardGroupsColumns';
import { withNg2React } from '../../../common/util/Ng2React';
import DashboardBanner from './dashboardBanner';

angular
    .module('signalview.dashboardIndex', [
        'signalview.typeahead',
        'signalview.dashboardUtil',
        'signalview.urlOverrides',
        'sfx.filterControls',
        'sfx.favorites',
        'sfx.api.v2',
    ])
    .filter('paginate', paginateFilter)
    /**
     * The width of a column of dashboard groups measured in pixels.
     *
     * This was chosen as 280 because it accommodates 4 columns on a 1280px with
     * 20px of padding and 10px of margin on each side
     * (i.e. (1180 - ((20 + 10) * 2)) / 4 === 280).
     */
    .constant('DASHBOARD_GROUP_COLUMN_WIDTH', 280)
    /**
     * The minimum length that a query string needs to be to trigger a search.
     */
    .constant('QUERY_MIN_LENGTH', 3)
    .service('initService', initService)
    .service('dashboardIndexFormatter', dashboardIndexFormatter)
    .directive('dashboardGroupsColumns', dashboardGroupsColumns)
    .component('dashboardIndexPage', dashboardIndexPage)
    .component('dashboardBanner', react2angular(DashboardBanner));

// eslint-disable-next-line import/no-unused-modules
export const NgDashboardIndexPage = withNg2React({
    name: 'dashboardIndexPage',
    definition: dashboardIndexPage,
});
