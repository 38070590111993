const ALL_WILDCARD = '*';

export default [
    '_',
    'clusterMapUtil',
    function (_, clusterMapUtil) {
        return { updateHierarchyWithNewFilters, isParentFilteredOut };

        // Does some basic checks and call upon recursive filtering utility when necessary
        function updateHierarchyWithNewFilters(
            stateRoot,
            dataConfig,
            newFilters,
            oldFilters,
            forceApply
        ) {
            if (
                !forceApply &&
                (_.isNil(newFilters) || areEqualFilterLists(newFilters, oldFilters))
            ) {
                stateRoot.unaryIdFilteredBranch = stateRoot.unaryIdFilteredBranch || [stateRoot];
                return oldFilters;
            }

            const filters = newFilters || oldFilters || [];

            const propertyToFilterMap = new Map();

            filters.forEach((filter) => {
                if (filter && filter.property && !_.isEmpty(filter.propertyValue)) {
                    propertyToFilterMap.set(filter.property, filter);
                }
            });

            resetFilteredChildResources(stateRoot); // Reset last filter state
            const { unaryIdFilteredBranch } = filterStateTree(
                stateRoot,
                dataConfig,
                propertyToFilterMap
            );

            stateRoot.unaryIdFilteredBranch = unaryIdFilteredBranch || [stateRoot];

            const currentLargestSize = stateRoot.largestSizes;
            clusterMapUtil.calculateLargestSizes(stateRoot);
            stateRoot.layoutCalculationRequired =
                forceApply ||
                stateRoot.layoutCalculationRequired ||
                !_.isEqual(currentLargestSize, stateRoot.largestSizes);

            return filters;
        }

        function areEqualFilterLists(filterList1, filterList2) {
            const isFilterList1Defined = _.isNil(filterList1);
            const isFilterList2Defined = _.isNil(filterList2);

            if (isFilterList1Defined || isFilterList2Defined) {
                return isFilterList1Defined && isFilterList2Defined;
            }

            if (filterList1.length !== filterList2.length) {
                return false;
            }

            const propertyToFilterMap1 = {};

            filterList1.forEach((f) => (propertyToFilterMap1[f.property] = f));
            return filterList2.every((filter2) => {
                const filter1 = propertyToFilterMap1[filter2.property];
                return (
                    filter1 &&
                    !filter1.NOT === !filter2.NOT &&
                    filter1.property === filter2.property &&
                    _.isEqual(
                        new Set(
                            _.isArray(filter1.propertyValue)
                                ? filter1.propertyValue
                                : [filter1.propertyValue]
                        ),
                        new Set(
                            _.isArray(filter2.propertyValue)
                                ? filter2.propertyValue
                                : [filter2.propertyValue]
                        )
                    )
                );
            });
        }

        function filterStateTree(resource, dataConfig, propertyToFilterMap) {
            if (!resource) {
                return {};
            }

            const idProperty = dataConfig.getHierarchicalGroupByKeys()[resource.depth];
            const { filtersOnRoot, filtersOnSubTree } = bifurcateFiltersOnStateTree(
                resource,
                propertyToFilterMap,
                idProperty
            );

            const { matchesSourceFilters, matchesAllFilters } = matchesFilters(
                resource,
                filtersOnRoot.values()
            );
            resource.matchesFilters = matchesSourceFilters;
            if (!matchesAllFilters) {
                return {
                    satisfyFilters: false,
                    unaryIdFilteredBranch: [],
                };
            }

            // If resource matches all applicable filters, we need to do some more checks and go down the sub-tree

            // Return satisfyFilters = True if:
            // This is the last resource in the branch and all filters are applicable and a match
            if (!resource.children || !resource.children.length) {
                const satisfyFilters =
                    filtersOnRoot.size === propertyToFilterMap.size && matchesAllFilters;

                return {
                    satisfyFilters,
                    unaryIdFilteredBranch: (satisfyFilters && [resource]) || [],
                };
            }

            let childrenSatisfyingFilters = 0;
            let unaryIdFilteredBranch = [];
            const shouldRemoveChildResources = shouldFilterOutChildResources(resource, dataConfig);

            if (shouldRemoveChildResources) {
                // Remove children from hierarchy if necessary
                const children = resource.children;
                resource.children = [];
                children.forEach((child) => {
                    const subTreeState = filterStateTree(child, dataConfig, filtersOnSubTree);
                    if (subTreeState.satisfyFilters) {
                        resource.children.push(child);
                        unaryIdFilteredBranch = subTreeState.unaryIdFilteredBranch;
                        childrenSatisfyingFilters++;
                    } else {
                        if (!resource.hiddenChildren) {
                            resource.hiddenChildren = [];
                        }
                        resource.hiddenChildren.push(child);
                    }
                });
            } else {
                // Keep children flagged if necessary
                resource.children.forEach((child) => {
                    const subTreeState = filterStateTree(child, dataConfig, filtersOnSubTree);
                    if (subTreeState.satisfyFilters) {
                        unaryIdFilteredBranch = subTreeState.unaryIdFilteredBranch;
                        childrenSatisfyingFilters++;
                    }
                    child.resourceIsFilteredOut = !subTreeState.satisfyFilters;
                });
            }

            // Break the unary tree branch here if:
            // More than one child satisfies the filters or there are no filters for sub-tree and it was the only child,
            if (childrenSatisfyingFilters > 1 || filtersOnSubTree.size === 0) {
                unaryIdFilteredBranch = [];
            }

            unaryIdFilteredBranch.unshift(resource);

            // Returns satisfyFilters = True if:
            // Part of sub-tree also satisfies all the filters Or
            // this resource have some filters it satisfies (<= case of filter applicable at this depth and nothing below)
            return {
                unaryIdFilteredBranch,
                satisfyFilters:
                    childrenSatisfyingFilters > 0 ||
                    (isEmptyMatchStateAllowed(resource, dataConfig) && filtersOnRoot.size > 0),
            };
        }

        function shouldFilterOutChildResources(resource, dataConfig) {
            // need to obtain config for next depth, i.e. depth of children
            const resourceType = dataConfig.getResourceAtDepth(resource.depth + 1);
            if (resourceType) {
                const resourceConfig = dataConfig.get(resourceType);
                return resourceConfig && resourceConfig.getFilterAction().includes('REMOVE_CHILD');
            }

            return false;
        }

        function isParentFilteredOut(resource) {
            if (!resource.hasOwnProperty('parent') || resource.parent === null) {
                return false;
            }

            return resource.parent.resourceIsFilteredOut;
        }

        function isEmptyMatchStateAllowed(resource, dataConfig) {
            const resourceType = dataConfig.getResourceAtDepth(resource.depth);
            if (resourceType) {
                const resourceConfig = dataConfig.get(resourceType);
                return (
                    resourceConfig &&
                    resourceConfig.getFilterAction().includes(':ALLOW_EMPTY_MATCH')
                );
            }

            return false;
        }

        function matchesFilter(data, filter) {
            if (!filter) {
                return true;
            }

            const { property, propertyValue, NOT } = filter;
            const hasProperty = data && data[property];

            const matchesProperty =
                hasProperty &&
                (_.isArray(propertyValue)
                    ? propertyValue.some((filterString) =>
                          valueMatchesFilter(data[property], filterString)
                      )
                    : valueMatchesFilter(data[property], propertyValue));

            return (NOT && !matchesProperty) || (!NOT && matchesProperty);
        }

        function valueMatchesFilter(data, filterString) {
            return (
                data &&
                filterString &&
                (data === filterString ||
                    filterString === ALL_WILDCARD ||
                    (filterString.startsWith(ALL_WILDCARD) &&
                        filterString.endsWith(ALL_WILDCARD) &&
                        data.includes(filterString.slice(1, -1))) ||
                    (filterString.startsWith(ALL_WILDCARD) &&
                        data.endsWith(filterString.slice(1))) ||
                    (filterString.endsWith(ALL_WILDCARD) &&
                        data.startsWith(filterString.slice(0, -1))))
            );
        }

        function matchesFilters(resource, filters) {
            let matchesSourceFilters = true;
            let matchesAllFilters = true;
            [...filters].forEach((filter) => {
                if (!matchesFilter(resource.data, filter)) {
                    if (!filter.isMapSelectionFilter) {
                        matchesSourceFilters = false;
                    }
                    matchesAllFilters = false;
                }
            });
            matchesSourceFilters = !resource.data || matchesSourceFilters;
            matchesAllFilters = !resource.data || matchesAllFilters;
            return { matchesSourceFilters, matchesAllFilters };
        }

        function resetFilteredChildResources(resource) {
            resource.resourceIsFilteredOut = false;

            if (resource.hiddenChildren && resource.children) {
                resource.children = resource.children.concat(resource.hiddenChildren);
                resource.hiddenChildren = [];
            }

            if (resource.children) {
                for (const child of resource.children) {
                    resetFilteredChildResources(child);
                }
            }
        }

        function bifurcateFiltersOnStateTree(resource, propertyToFilterMap, currentHierarchyKey) {
            const filtersOnRoot = new Map();
            const filtersOnSubTree = new Map(propertyToFilterMap);

            if (currentHierarchyKey && filtersOnSubTree.has(currentHierarchyKey)) {
                filtersOnRoot.set(currentHierarchyKey, filtersOnSubTree.get(currentHierarchyKey));
                filtersOnSubTree.delete(currentHierarchyKey);
            }

            const filtersPresentWithResource = filtersPresent(
                resource.data,
                filtersOnSubTree.keys()
            );
            filtersPresentWithResource.forEach((filterKey) => {
                filtersOnRoot.set(filterKey, filtersOnSubTree.get(filterKey));
                filtersOnSubTree.delete(filterKey);
            });

            return { filtersOnRoot, filtersOnSubTree };
        }

        function filtersPresent(metadata, filterKeys) {
            return (metadata && [...filterKeys].filter((key) => key in metadata)) || [];
        }
    },
];
