const filters = [
    { NOT: false, type: 'property', property: '_exists_', propertyValue: 'kubernetes_cluster' },
    { NOT: false, type: 'property', property: '_exists_', propertyValue: 'kubernetes_namespace' },
    {
        NOT: false,
        type: 'property',
        property: '_exists_',
        propertyValue: 'kubernetes_workload_name',
    },
];

export default {
    requiredFilters: ['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload_name'],

    BASE_METRIC_TEMPLATES: [
        // Details Metrics
        {
            filters,
            template:
                "WORKLOAD_POD_PHASE = data('kubernetes.pod_phase'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).promote(['kubernetes_workload_name'])",
        },
        {
            filters,
            template:
                "DEPLOYMENT_POD_PHASE = data('kubernetes.pod_phase'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'deployment'})",
        },

        // Resource Metrics
        {
            filters,
            template:
                "CPU_LIMIT = data('kubernetes.container_cpu_limit'{{#workloadTranslatedFilters}}, filter={{{workloadTranslatedFilters}}}{{/workloadTranslatedFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters,
            template:
                "CPU_REQUEST = data('kubernetes.container_cpu_request'{{#workloadTranslatedFilters}}, filter={{{workloadTranslatedFilters}}}{{/workloadTranslatedFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters,
            template:
                "MEMORY_LIMIT = data('kubernetes.container_memory_limit'{{#workloadTranslatedFilters}}, filter={{{workloadTranslatedFilters}}}{{/workloadTranslatedFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters,
            template:
                "MEMORY_REQUEST = data('kubernetes.container_memory_request'{{#workloadTranslatedFilters}}, filter={{{workloadTranslatedFilters}}}{{/workloadTranslatedFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },

        // Desired Metrics
        {
            filters,
            template:
                "DESIRED_WORKLOADS = data('kubernetes.*.desired*'{{#workloadTranslatedFilters}}, filter={{{workloadTranslatedFilters}}}{{/workloadTranslatedFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'kubernetes_name'})",
        },
    ],

    METRICS: [
        // Details Metrics Set
        {
            displayName: 'Pod Phase',
            job: {
                // Union across all and pick single time series
                template:
                    "POD_PHASE = union(WORKLOAD_POD_PHASE, DEPLOYMENT_POD_PHASE).max(by=['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'POD_PHASE',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
        {
            displayName: 'Pod Age',
            job: {
                template:
                    "POD_PHASE_AGE = WORKLOAD_POD_PHASE.max(by=['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name', 'pod_creation_timestamp'])",
                varName: 'POD_PHASE_AGE',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
        {
            displayName: 'Pod Count',
            job: {
                template:
                    "POD_COUNT = union(WORKLOAD_POD_PHASE, DEPLOYMENT_POD_PHASE).between(2, 3, True, True).count(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'POD_COUNT',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
        {
            displayName: 'Desired Pods',
            job: {
                template:
                    "DESIRED_PODS = DESIRED_WORKLOADS.sum(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'DESIRED_PODS',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },

        // Per Container Image Resources
        {
            displayName: 'Container CPU Request',
            job: {
                template:
                    "CONTAINER_CPU_REQUEST = CPU_REQUEST.max(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name', 'container_spec_name'])",
                varName: 'CONTAINER_CPU_REQUEST',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}{{ container_spec_name }}',
            },
        },
        {
            displayName: 'Container CPU Limit',
            job: {
                template:
                    "CONTAINER_CPU_LIMIT = CPU_LIMIT.max(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name', 'container_spec_name'])",
                varName: 'CONTAINER_CPU_LIMIT',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}{{ container_spec_name }}',
            },
        },
        {
            displayName: 'Container Memory Request',
            job: {
                template:
                    "CONTAINER_MEMORY_REQUEST = MEMORY_REQUEST.max(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name', 'container_spec_name'])",
                varName: 'CONTAINER_MEMORY_REQUEST',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}{{ container_spec_name }}',
            },
        },
        {
            displayName: 'Container Memory Limit',
            job: {
                template:
                    "CONTAINER_MEMORY_LIMIT = MEMORY_LIMIT.max(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name', 'container_spec_name'])",
                varName: 'CONTAINER_MEMORY_LIMIT',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}{{ container_spec_name }}',
            },
        },

        // Resource Totals
        {
            displayName: 'CPU Total Request',
            job: {
                template:
                    "CPU_TOTAL_REQUEST = CPU_REQUEST.sum(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'CPU_TOTAL_REQUEST',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
        {
            displayName: 'CPU Total Limit',
            job: {
                template:
                    "CPU_TOTAL_LIMIT = CPU_LIMIT.sum(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'CPU_TOTAL_LIMIT',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
        {
            displayName: 'Memory Total Request',
            job: {
                template:
                    "MEMORY_TOTAL_REQUEST = MEMORY_REQUEST.sum(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'MEMORY_TOTAL_REQUEST',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
        {
            displayName: 'Memory Total Limit',
            job: {
                template:
                    "MEMORY_TOTAL_LIMIT = MEMORY_LIMIT.sum(['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload', 'kubernetes_workload_name'])",
                varName: 'MEMORY_TOTAL_LIMIT',
                unique_id:
                    '{{ kubernetes_cluster }}{{ kubernetes_namespace }}{{ kubernetes_workload_name }}',
            },
        },
    ],

    title: 'Workload Properties',

    dataGroup: [
        {
            /***** GROUP 1 *****/
            propertyMap: [
                {
                    displayName: 'Name',
                    metric: 'Pod Phase',
                    property: 'kubernetes_workload_name',
                },
                {
                    displayName: 'Type',
                    metric: 'Pod Phase',
                    property: 'kubernetes_workload',
                },
                {
                    displayName: 'Pod count actual',
                    metric: 'Pod Count',
                },
                {
                    displayName: 'Pod count desired',
                    metric: 'Desired Pods',
                },
                {
                    displayName: 'Age',
                    format: 'Custom:Age',
                    metric: 'Pod Age',
                    alt: 'Unknown',
                    property: 'pod_creation_timestamp',
                },
                {
                    displayName: 'Namespace',
                    metric: 'Pod Phase',
                    property: 'kubernetes_namespace',
                },
            ],
        },
        {
            /***** GROUP 2 *****/
            limit: 1,
            groupTitle: 'Total Resources',
            propertyMap: [
                {
                    displayName: 'CPU request',
                    format: 'Number',
                    metric: 'CPU Total Request',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty no-border',
                },
                {
                    displayName: 'CPU limit',
                    format: 'Number',
                    metric: 'CPU Total Limit',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty',
                },
                {
                    displayName: 'Memory request',
                    format: 'ScaleBytes',
                    metric: 'Memory Total Request',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty no-border',
                },
                {
                    displayName: 'Memory limit',
                    format: 'ScaleBytes',
                    metric: 'Memory Total Limit',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty',
                },
            ],
        },
        {
            /***** GROUP 3 *****/
            groupTitle: 'Resources by Container',
            propertyMap: [
                {
                    displayProperty: 'container_spec_name',
                    metric: 'Container CPU Request',
                    value: '',
                    alt: '',
                },
                {
                    displayName: 'CPU request',
                    format: 'Number',
                    metric: 'Container CPU Request',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty no-border',
                },
                {
                    displayName: 'CPU limit',
                    format: 'Number',
                    metric: 'Container CPU Limit',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty',
                },
                {
                    displayName: 'Memory request',
                    format: 'ScaleBytes',
                    metric: 'Container Memory Request',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty no-border',
                },
                {
                    displayName: 'Memory limit',
                    format: 'ScaleBytes',
                    metric: 'Container Memory Limit',
                    alt: 'UNLIMITED',
                    customClass: 'warning-on-empty',
                },
            ],
        },
    ],
};
