import templateUrl from '../../../../app/charting/chartdisplay/newChartTypeTemplate.tpl.html';

export const newChartTypeTemplate = {
    templateUrl,
    bindings: {
        chartModel: '<',
        isOriginallyV2: '<',
        signalFlow: '<',
        showSparkline: '<',
        openHref: '@',
        plotDataGeneration: '<',
        updateInterval: '<',
        onNewDataProvider: '&',
    },
    controller: [
        'BaseDataProvider',
        'chartDisplayUtils',
        '$scope',
        'routeParameterService',
        'timepickerUtils',
        function (
            BaseDataProvider,
            chartDisplayUtils,
            $scope,
            routeParameterService,
            timepickerUtils
        ) {
            const $ctrl = this;
            //const previewContainer = $element.find('.chart-data-container');
            let unregisterRouteWatchGroup;
            let dataProvider;
            let selectedTsid;

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $ctrl.$onDestroy = $onDestroy;

            function $onChanges(changesObj) {
                const { signalFlow, updateInterval } = changesObj;

                if (signalFlow && signalFlow.currentValue) {
                    updateSignalFlow(signalFlow.currentValue);
                }

                if (updateInterval) {
                    updateProviderTimeRange();
                }
            }

            function $onDestroy() {
                unregisterRouteWatchGroup();
            }

            function $onInit() {
                $ctrl.latestValue = null;
                selectedTsid = null;
                $ctrl.singleValueSparklineDatapoints = [];
                dataProvider = new BaseDataProvider(callback);
                dataProvider.setFilterAlias($ctrl.filterAlias);

                // !!! all visualizations must call this for builders to behave correctly !!!
                $ctrl.onNewDataProvider({ dataProvider });

                updateProviderTimeRange();
                unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                    ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
                    angular.bind(this, updateProviderTimeRange)
                );

                startStreaming();
            }

            function updateProviderTimeRange() {
                if (!dataProvider) {
                    return;
                }

                chartDisplayUtils.updateGlobalTimeRange(
                    $ctrl.chartModel,
                    timepickerUtils.getChartConfigURLTimeParameters()
                );

                //this doesnt read url params...
                const { chartconfig, chartMode } = $ctrl.chartModel.sf_uiModel;
                const rangeParams = chartDisplayUtils.getJobRangeParametersFromConfig(
                    chartconfig,
                    chartMode
                );

                dataProvider.setResolution(rangeParams.resolution);
                dataProvider.setHistoryrange(rangeParams.range);
                dataProvider.setStopTime(rangeParams.endAt);
            }

            /* unscoped functions */

            function callback(msg) {
                //this does not actually digest, and neither does the data provider.  we may need to force digests when latestValue is updated
                //so that it actually picks up and shows the new value.
                if (msg.type === 'data') {
                    handleNewData(msg);
                } else if (msg.type === 'init') {
                    selectedTsid = null;
                }
            }

            function handleNewData(msg) {
                if (!selectedTsid && Object.keys(msg.data).length > 0) {
                    selectedTsid = Object.keys(msg.data)[0];
                }

                if (selectedTsid && msg.data[selectedTsid]) {
                    const { timestamp, value } = msg.data[selectedTsid];
                    $ctrl.latestValue = value;
                    $ctrl.latestTimeStamp = timestamp;
                } else {
                    $ctrl.latestValue = null;
                }

                $scope.$digest();
            }

            function startStreaming() {
                if ($ctrl.signalFlow) {
                    dataProvider.setSignalFlowText($ctrl.signalFlow);
                    dataProvider.init();
                }
            }

            function updateSignalFlow(signalflow) {
                if (dataProvider) {
                    dataProvider.setSignalFlowText(signalflow);
                }
            }
        },
    ],
};
