import templateUrl from './objectUsage.tpl.html';

export default {
    bindings: {
        viewMode: '=?',
        object: '=',
        filters: '=',
    },
    templateUrl,
    controller: [
        '$location',
        'mtsService',
        'catalogHelper',
        function ($location, mtsService, catalogHelper) {
            const $ctrl = this;

            $ctrl.$onInit = onInit;
            $ctrl.properties = [];

            $ctrl.showPropertyValues = function (propertyKey) {
                const object = $ctrl.object;
                const objectInFilter = catalogHelper.isInFilter(object, $ctrl.filters);

                let key = object.sf_key[0];
                let query;

                if (object.sf_type === 'Topic') {
                    query = 'sf_key:' + object.sf_topic;
                } else {
                    if (key === 'sf_tag') key = 'sf_tags';
                    query = key + ':' + object[key];
                }

                const search = $location.search();

                if (!objectInFilter) {
                    const sources = search['sources[]'] || [];
                    sources.push(query);
                    search['sources[]'] = sources;
                }

                search.topicFilterKey = propertyKey;
                search.selectedKeyValue = `sf_key:${propertyKey}`;
                $location.search(search);
            };

            function updateProperties() {
                const filters = $ctrl.filters;
                const andQueries = [];

                andQueries.push(`sf_metric:"${$ctrl.object.sf_metric}"`);
                andQueries.push('sf_isActive:true');

                if (filters && filters.length) {
                    filters.forEach(function (filter) {
                        andQueries.push(filter.property + ':' + filter.propertyValue);
                    });
                }

                const query = andQueries.join(' AND ');

                mtsService.propertyCounts(query, 0, 100).then((result) => {
                    $ctrl.properties = result.data;
                    $ctrl.columnItemCount = Math.ceil($ctrl.properties.length / 2);
                });
            }

            function onInit() {
                updateProperties();
            }
        },
    ],
};
