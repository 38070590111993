import metricsPickerCatalogResults from './metricsPickerCatalogResults.js';

angular
    .module('signalview.metricsPicker', ['signalview.metricService'])
    .constant('INTERNAL_METRIC_PREFIX', '_SF_')
    .constant('METRIC_DELIMITERS', ['.', '/'])
    .constant('METRIC_TYPE', 'Metric')
    .constant('EVENT_TYPE', 'Event')
    .constant('ALERT_TYPE', 'Alert')
    .component('metricsPickerCatalogResults', metricsPickerCatalogResults);
