import moment from 'moment-timezone';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import React from 'react';
import { AngularInjector } from '../../AngularUtils';

export default function DateTimeFormatter(props) {
    const tzService = AngularInjector.useInjectedClass('timeZoneService');
    const timezone = tzService.getTimezone();
    const momentLocal = timezone ? moment(props.timestamp).tz(timezone) : moment(props.timestamp);

    return (
        <React.Fragment>
            <Moment format="MM/DD/YY HH:mm">{momentLocal}</Moment> (UTC{' '}
            <Moment format="Z">{momentLocal}</Moment>)
        </React.Fragment>
    );
}

DateTimeFormatter.propTypes = {
    timestamp: PropTypes.number,
};
