import { FilterKey } from '@splunk/olly-services';

export default [
    '$rootScope',
    'themeService',
    'imageService',
    function ($rootScope, themeService, imageService) {
        function getIconMap() {
            const theme = themeService.dark ? 'dark' : 'light';

            const apmIcons = (theme) => ({
                [FilterKey.APM_SERVICE_NAME]: `node_distributed_${theme}.svg`,
                [FilterKey.APM_TRACE_ID]: `chart_gantt_${theme}.svg`,
                [FilterKey.APM_WORKFLOW_NAME]: `node_branch_${theme}.svg`,
            });

            const catalogQuickActionsIcons = (theme) => ({
                Detector: `detector_${theme}.svg`,
                Navigation: 'navigation.png',
                sf_metric: 'metric.png',
            });

            const staticQuickActionsIcons = (theme) => ({
                Action: 'actions.png',
                Help: 'help.png',
                'Metric Search': `metrics_search_${theme}.svg`,
            });

            const defaultDataQuickActionsIcons = (theme) => ({
                recent: `recent_${theme}.svg`,
                favorite: `favorite_${theme}.svg`,
            });

            return {
                ...apmIcons(theme),
                ...catalogQuickActionsIcons(theme),
                ...defaultDataQuickActionsIcons(theme),
                ...staticQuickActionsIcons(theme),
            };
        }

        // Listens the theme change
        $rootScope.$on('theme update', getIconMap);

        function hasIcon(type) {
            return type in getIconMap();
        }

        function getIconURL(type) {
            const icons = getIconMap();
            const icon = icons[type];
            if (!icon) {
                return null;
            }
            return imageService.getIconURL(icon);
        }

        return { getIconURL, hasIcon };
    },
];
