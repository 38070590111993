import templateUrl from './sourceFilterItem.tpl.html';
import sourceFilterTooltipTemplateUrl from './sourceFilterTooltip.tpl.html';

export default [
    'sourceFilterService',
    'urlOverridesService',
    function (sourceFilterService, urlOverridesService) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl,
            scope: {
                /*displays models of the form
       {
         iconClass: some string icon class
         value: value to query for
         query: es query string  (optional, but required for underlying functionality)
         type: (optional, but necessary to re-generate the query)
         applyIfExists: boolean (optional, only apply filter to MTS if the property has the value or the MTS does not have the property)
         NOT: boolean
       }
      */
                filter: '=',
                remove: '&onRemove',
                disabled: '<?',
                override: '@',
                variable: '=?',
                showFilterOptions: '=?',
                tooltipDisabled: '@?',
            },
            link: function ($scope, elem) {
                $scope.sourceFilterTooltipTemplateUrl = sourceFilterTooltipTemplateUrl;

                const filter = $scope.filter || {};
                const filterType = elem.find('.source-filter-type');
                const filterToggle = elem.find('.source-filter-toggle');

                $scope.isValueArray = angular.isArray(filter.propertyValue);
                if ($scope.isValueArray) {
                    $scope.reducedValue = sourceFilterService.getDisplayNameForFilters(
                        filter.propertyValue
                    );
                }

                $scope.showOptionsToggle = function () {
                    if (!$scope.showFilterOptions) {
                        return;
                    }
                    filterType.fadeOut('fast');
                    filterToggle.fadeIn('fast');
                };

                $scope.hideOptionsToggle = function () {
                    if (!$scope.showFilterOptions) {
                        return;
                    }
                    filterToggle.fadeOut('fast');
                    filterType.fadeIn('fast');
                };

                // Find this filter in the overrides and update its fields
                function updateUrlOverrides() {
                    let sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
                    sourceOverrides = sourceOverrides.map(function (override) {
                        // Filters returned when getting the source filter override list has
                        // value stored as "value" but setting the source filter override
                        // list expects it to be stored as "propertyValue"
                        override.propertyValue = override.value;
                        if (
                            override.NOT === filter.NOT &&
                            override.property === filter.property &&
                            angular.equals(override.value, filter.propertyValue)
                        ) {
                            override.applyIfExists = filter.applyIfExists;
                            override.propertyValue = filter.propertyValue;
                        }
                        return override;
                    });
                    urlOverridesService.setSourceFilterOverrideList(sourceOverrides);
                }

                $scope.setApplyIfExists = function () {
                    filter.applyIfExists = true;
                    updateUrlOverrides();
                };

                $scope.unsetApplyIfExists = function () {
                    filter.applyIfExists = false;
                    updateUrlOverrides();
                };
            },
        };
    },
];
