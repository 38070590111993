export default [
    'urlOverridesService',
    'URL_PARAMETER_CONSTANTS',
    'kubeDataService',
    'dashboardVariablesService',
    function (
        urlOverridesService,
        URL_PARAMETER_CONSTANTS,
        kubeDataService,
        dashboardVariablesService
    ) {
        const INFO_PANELS = {
            workloadDetail: {
                baseLink: '/k8s/workloadDetail',
                panelNameKey: 'kubernetes_workload_name',
                objectType: 'Workload',
                displayName: 'Workload Detail',
                value: 'workloadDetail',
                requiredFilters: [
                    'kubernetes_cluster',
                    'kubernetes_namespace',
                    'kubernetes_workload_name',
                ],
                selectionPriority: 0,
                getEntityClickEventLabel: (isSidebarOpen) =>
                    isSidebarOpen ? 'Open_Workloads_Info' : 'Close_Workloads_Info',
            },
            nodeDetail: {
                baseLink: '/k8s/nodeDetail',
                panelNameKey: 'kubernetes_node',
                objectType: 'Node',
                value: 'nodeDetail',
                requiredFilters: ['kubernetes_cluster', 'kubernetes_node'],
                selectionPriority: 1,
                getEntityClickEventLabel: (isSidebarOpen) =>
                    isSidebarOpen ? 'Open_Node_Info' : 'Close_Node_Info',
            },
            podDetail: {
                baseLink: '/k8s/podDetail',
                panelNameKey: 'kubernetes_pod_name',
                objectType: 'Pod',
                value: 'podDetail',
                requiredFilters: ['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name'],
                selectionPriority: 2,
                getEntityClickEventLabel: (isSidebarOpen) =>
                    isSidebarOpen ? 'Open_Pod_Info' : 'Close_Pod_Info',
            },
            containerDetail: {
                baseLink: '/k8s/containerDetail',
                panelNameKey: 'container_id',
                objectType: 'Container',
                value: 'containerDetail',
                requiredFilters: ['kubernetes_cluster', 'container_id'],
                selectionPriority: 3,
                getEntityClickEventLabel: (isSidebarOpen) =>
                    isSidebarOpen ? 'Open_Container_Info' : 'Close_Container_Info',
            },
            dashboard: {
                // Special panel handled separately
                value: 'dashboard',
                baseLink: '/dashboard',
                selectionPriority: 0,
            },
        };

        return {
            INFO_PANELS,
            getInfoPanelsForSidebarURLParams,
            getPanelForFilters,
            setInfoPanelSidebarURLParams,
            getTabSelected,
            setTabSelected,
            forceSidebarDefaultTabSelection,
            isDashboardContextPresent,
            getDashboardParams,
            getOverrideContexts,
        };

        function getTabSelected() {
            return urlOverridesService.getSearchParam(URL_PARAMETER_CONSTANTS.infoSidebarTab);
        }

        function setTabSelected(val) {
            urlOverridesService.setSearchParam(URL_PARAMETER_CONSTANTS.infoSidebarTab, val, () =>
                urlOverridesService.clearParams([URL_PARAMETER_CONSTANTS.infoSidebarTab])
            );
        }

        function forceSidebarDefaultTabSelection() {
            urlOverridesService.clearParams([
                URL_PARAMETER_CONSTANTS.infoSidebarTab,
                URL_PARAMETER_CONSTANTS.infoSidebarInfoTabRefType,
            ]);
        }

        function getInfoPanelsForSidebarURLParams() {
            const availableInfoPanels = [];

            if (isDashboardContextPresent()) {
                availableInfoPanels.push(INFO_PANELS.dashboard);
            }

            const infoSidebarFilters = urlOverridesService.getInfoSidebarSources() || [];
            const filterKeys = infoSidebarFilters.map((f) => f.property);

            for (const key in INFO_PANELS) {
                const panel = INFO_PANELS[key];

                const requirementsSatisfied =
                    panel.requiredFilters &&
                    panel.requiredFilters.every((filter) => filterKeys.includes(filter));

                if (requirementsSatisfied) {
                    availableInfoPanels.push(panel);
                }
            }

            availableInfoPanels.sort((p1, p2) => p2.selectionPriority - p1.selectionPriority);

            return availableInfoPanels;
        }

        function getPanelForFilters(panelNameKey, filters) {
            if (!filters) {
                return;
            }

            const panel = Object.values(INFO_PANELS).find(
                (panel) => panel.panelNameKey === panelNameKey
            );
            if (!panel) {
                return;
            }

            const overrideProps = new Set(filters.map(({ property }) => property));
            const unmetRequiredFilters = panel.requiredFilters.some(
                (key) => !overrideProps.has(key)
            );

            if (unmetRequiredFilters) {
                return;
            }

            return panel.value;
        }

        function setInfoPanelSidebarURLParams(infoTabRefType, filters) {
            urlOverridesService.setSearchParam(URL_PARAMETER_CONSTANTS.infoSidebarTab, 'info');
            urlOverridesService.setSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarInfoTabRefType,
                infoTabRefType
            );
            urlOverridesService.setInfoSidebarSourcesList(filters);
        }

        function isDashboardContextPresent() {
            return (
                urlOverridesService.doesParamExist(
                    URL_PARAMETER_CONSTANTS.infoSidebarDashboardId
                ) &&
                urlOverridesService.doesParamExist(
                    URL_PARAMETER_CONSTANTS.infoSidebarDashboardGroupId
                )
            );
        }

        function getDashboardParams() {
            const dashboardId = urlOverridesService.getSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarDashboardId
            );
            const groupId = urlOverridesService.getSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarDashboardGroupId
            );
            const configId = urlOverridesService.getSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarDashboardConfigId
            );
            return { dashboardId, groupId, configId };
        }

        function getOverrideContexts() {
            // TODO: Separate out params used here, if this gets used as a sidebar for a dashboard
            // ( i.e. dashboard with a dashboard-sidebar )

            const timePickerOverride = urlOverridesService.getGlobalTimePicker();
            const pointDensityOverride = urlOverridesService.getPointDensity();
            const sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
            const variableOverrides = dashboardVariablesService.getVariablesOverrideAsModel();
            const { sources, variables } = kubeDataService.getMergedOverridesAndAnalyzerContext(
                sourceOverrides,
                variableOverrides
            );

            return {
                sources,
                variables,
                time: timePickerOverride,
                density: pointDensityOverride,
            };
        }
    },
];
