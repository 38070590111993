import templateUrl from './analyticsBlockConfiguration.tpl.html';

import TYPE_TO_TEMPLATE_URL from './blockTypeToTemplateMap.js';
import { DIRECTION_TYPE } from '../../../../legacy/common/consts';
import { CALENDAR_WINDOW_CONSTANTS } from '../../../../legacy/app/analytics/util/calendarWindowConstants';

angular.module('signalview.analytics').directive('analyticsBlockConfiguration', function () {
    return {
        scope: {
            manipulation: '=',
            currentQuery: '=?',
            isCounter: '=?',
            plot: '=',
            calendarWindowTimezone: '=',
            getCurrentTimeRange: '<?',
            metricType: '<?',
            isDetectorPlot: '<?',
        },
        restrict: 'E',
        templateUrl,
        controller: [
            '$scope',
            'blockService',
            'timeSpanUtils',
            'calendarWindowUtil',
            'SIGNALFLOW_CONSTANTS',
            'userAnalytics',
            function (
                $scope,
                blockService,
                timeSpanUtils,
                calendarWindowUtil,
                SIGNALFLOW_CONSTANTS,
                userAnalytics
            ) {
                // removed uiFeatures.init() as there isn't any featureEnabled being used inside it
                $scope.blockService = blockService;
                $scope.isHistogramTransformation = false;
                const { METRIC_TYPE, ANALYTICS_ARGS } = SIGNALFLOW_CONSTANTS;
                const DEFAULT_POINT_CHART_PROGRAM_TEXT_TIME_RANGE = '15m';

                $scope.confirmAndClose = function () {
                    $scope.manipulation.showMe = false;
                    $scope.$emit('refocus-analytics');
                };

                $scope.$watch('manipulation.showMe', function (v) {
                    if (v === true) {
                        $scope.tentativeDirection = angular.copy($scope.manipulation.direction);
                        $scope.tentativeFn = angular.copy($scope.manipulation.fn);
                        $scope.fnConfig = $scope.tentativeFn;
                        $scope.isCalendarWindowApplicable = canCalendarWindowBeApplied();
                    } else if (v === false) {
                        if (
                            $scope.tentativeDirection &&
                            $scope.tentativeFn &&
                            (calendarWindowUtil.isCalendarWindowType($scope.tentativeFn) ||
                                $scope.tentativeDirection.type !== DIRECTION_TYPE.TRANSFORMATION ||
                                $scope.tentativeDirection.options.amount)
                        ) {
                            // if it's transformation type, it needs valid amount.
                            $scope.manipulation.direction = $scope.tentativeDirection;
                            $scope.manipulation.fn = $scope.tentativeFn;
                        }
                    }
                });

                $scope.$watch('manipulation.fn.options.windowType', () => {
                    const { windowType, programArgs } = $scope.manipulation.fn.options;
                    if (
                        !programArgs &&
                        windowType ===
                            CALENDAR_WINDOW_CONSTANTS.ANALYTICS_WINDOW_TYPES.DASHBOARD_WINDOW
                    ) {
                        $scope.manipulation.fn.options.programArgs = {
                            [ANALYTICS_ARGS.UI_DASHBOARD_WINDOW]:
                                DEFAULT_POINT_CHART_PROGRAM_TEXT_TIME_RANGE,
                        };
                    }
                });

                $scope.$watch('manipulation.fn.type', () => {
                    if (
                        $scope.manipulation.fn.type &&
                        $scope.metricType === METRIC_TYPE.HISTOGRAM
                    ) {
                        $scope.tentativeDirection = $scope.manipulation.direction;
                        $scope.tentativeFn = $scope.manipulation.fn;
                        $scope.fnConfig = $scope.tentativeFn;
                        $scope.isCalendarWindowApplicable = canCalendarWindowBeApplied();
                        $scope.isHistogramTransformation =
                            $scope.tentativeDirection.type === DIRECTION_TYPE.TRANSFORMATION;
                    }
                });

                $scope.$watch('fnConfig.options.percentile', () => {
                    if (
                        $scope.metricType === METRIC_TYPE.HISTOGRAM &&
                        $scope.fnConfig?.options?.percentile
                    ) {
                        userAnalytics.event(
                            'histogram-metric',
                            'percentile-value-changed',
                            $scope.fnConfig.options.percentile
                        );
                    }
                });

                $scope.fnConfig = $scope.manipulation.fn;
                $scope.isCalendarWindowApplicable = canCalendarWindowBeApplied();
                $scope.validators = {
                    timeSpan: timeSpanUtils.validateTimeSpan,
                };

                $scope.getConfigPath = function (type) {
                    if (!type) return null;
                    return TYPE_TO_TEMPLATE_URL[type].configuration;
                };

                function canCalendarWindowBeApplied() {
                    const funcsWithCalendarWindow = ['SUM', 'MEAN', 'MIN', 'MAX'];
                    return (
                        funcsWithCalendarWindow.includes($scope.fnConfig.type) ||
                        ($scope.metricType === METRIC_TYPE.HISTOGRAM &&
                            $scope.fnConfig.type === 'COUNT')
                    );
                }
            },
        ],
    };
});
