angular.module('sfx.api.v2').service('opsgenieTeamService', [
    'integrationCredentialCacheFactory',
    function (integrationCredentialCacheFactory) {
        const cacheIdentifier = 'opsgenie team';
        const endpointGenerator = (id) => `/_/opsgenie/${id}/team-search`;

        const opsgenieTeamServiceAPI = integrationCredentialCacheFactory(
            cacheIdentifier,
            endpointGenerator
        );
        opsgenieTeamServiceAPI.searchOpsgenieTeams = opsgenieTeamServiceAPI.search;

        return opsgenieTeamServiceAPI;
    },
]);
