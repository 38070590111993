import { omit } from 'lodash';
import { DIRECTION_TYPE } from '../../../../../legacy/common/consts';

const TRANSFORMATION_FIELDS = ['over', 'cycle', 'cycle_start', 'partial_values', 'shift_cycles'];

export const splitIntoAggregationAndTransformation = (histogramMethod) => {
    const aggregationTransformationMethods = [];
    const isAggregationTransformationApplied =
        histogramMethod.args?.by || histogramMethod.args?.over || histogramMethod.args?.cycle;

    if (!isAggregationTransformationApplied) {
        return [histogramMethod];
    }

    if (histogramMethod.args?.by || histogramMethod.args?.pct) {
        const keysToOmit = TRANSFORMATION_FIELDS.map((field) => `args.${field}`);
        const aggregationMethod = omit(histogramMethod, keysToOmit);
        aggregationTransformationMethods.push(aggregationMethod);
    } else {
        // when there is only the transformation is selected
        const defaultAggregationMethod = omit(histogramMethod, ['args']);
        aggregationTransformationMethods.push(defaultAggregationMethod);
    }

    if (histogramMethod.args?.over || histogramMethod.args?.cycle) {
        const transformationMethod = omit(histogramMethod, ['args.by']);
        aggregationTransformationMethods.push(transformationMethod);
    }

    return aggregationTransformationMethods;
};

const getAppliedFunctionName = (str, directionType = DIRECTION_TYPE.AGGREGATION) => {
    const separator = directionType === DIRECTION_TYPE.AGGREGATION ? ' ' : '(';
    return str.split(separator)[0];
};

const getHistogramDisplayName = (directionType, displayName1, displayName2) => {
    if (directionType === DIRECTION_TYPE.BOTH) {
        return ` (${displayName1 ? `${displayName1} and ` : ''}${displayName2})`;
    }
    return `${displayName1 ? ` (${displayName1})` : ''}`;
};

/**
 * @returns the histogram function text to show on the UI
 * @example directionTypesDisplayName = ["Mean by demo_datacenter","Mean(1h)"] will return "Mean (by demo_datacenter and over 1h)"
 */
export const createHistogramManipulationName = (directionTypesDisplayName) => {
    if (directionTypesDisplayName.length > 0) {
        const { value: directionValue, directionType } = directionTypesDisplayName[0];
        const appliedFunctionName = getAppliedFunctionName(directionValue, directionType);
        const appliedAggregationTransformationText = directionTypesDisplayName.map(
            (directionTypeDisplayName) => {
                const functionManipulationText = directionTypeDisplayName.value
                    .split(appliedFunctionName)[1]
                    .trim()
                    .replace(/[()]/g, '');
                return functionManipulationText;
            }
        );
        const newDirectionType =
            directionTypesDisplayName.length === 1
                ? directionTypesDisplayName[0].directionType
                : DIRECTION_TYPE.BOTH;
        return `${appliedFunctionName}${getHistogramDisplayName(
            newDirectionType,
            ...appliedAggregationTransformationText
        )}`;
    }
    return '';
};
