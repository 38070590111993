import templateUrl from './detectorWizardMessage.tpl.html';

/**
 * The rule message settings editor component allows user to
 * set runbook, tip and severity for the rule.
 * Also allows editing subject and body in superpowers mode.
 */
angular.module('signalview.detector.wizard').component('detectorWizardMessageEditor', {
    bindings: {
        detector: '<',
        rule: '<',
        isNewRule: '<',
        plots: '<',
        dimensions: '<',
        alertSignalInputs: '<',
        isFlow2: '<',
        selectedPlot: '<',
        fetchChartData: '<',
        onMessageSelect: '&',
        clearMessage: '&',
        proceed: '&',
        done: '&',
    },
    templateUrl,
    controller: [
        '$scope',
        '$window',
        'config',
        'detectorUtils',
        'ALERT_SEVERITY_LEVELS',
        'detectorWizardMessageHandlebarService',
        '_',
        'DimensionList',
        'plotUtils',
        'PRODUCT_NAME',
        function (
            $scope,
            $window,
            config,
            detectorUtils,
            ALERT_SEVERITY_LEVELS,
            detectorWizardMessageHandlebarService,
            _,
            DimensionList,
            plotUtils,
            PRODUCT_NAME
        ) {
            let previousWatch;
            let subjectEdited;
            let messageBodyEdited;
            const autofillSuggestion = {
                messageSubject: null,
                messageBody: null,
            };
            const ctrl = this;

            ctrl.$onInit = $onInit;
            ctrl.toggleEditMessages = toggleEditMessages;
            ctrl.saveEdit = saveEdit;
            ctrl.cancelEdit = cancelEdit;
            ctrl.onSubjectEdited = onSubjectEdited;
            ctrl.onBodyEdited = onBodyEdited;
            ctrl.revertToDefault = revertToDefault;
            ctrl.addVariableSuggestions = addVariableSuggestions;
            ctrl.focusBody = focusBody;
            ctrl.focusSubject = focusSubject;
            ctrl.setTabSuggestion = setTabSuggestion;
            ctrl.getTabSuggestion = getTabSuggestion;

            function copyMessage() {
                if (ctrl.rule.parameterizedSubject) {
                    $scope.messageSubject = ctrl.rule.parameterizedSubject;
                } else {
                    $scope.messageSubject =
                        '{{ruleSeverity}} Alert: {{{ruleName}}} ({{{detectorName}}})';
                }

                const defaultMessage = detectorUtils.getAutoDetectorRuleParameterizedString(
                    ctrl.rule,
                    ctrl.plots,
                    true
                );
                const parameterizedBody =
                    ctrl.rule.parameterized || ctrl.rule.parameterizedBody || '';
                ctrl.rule.isCustomizedMessage = parameterizedBody.trim() !== defaultMessage.trim();
                if (parameterizedBody && ctrl.rule.isCustomizedMessage) {
                    $scope.messageBody = parameterizedBody.replace(
                        /{{dateTimeFormat timestamp format="full"}}/g,
                        '{{timestamp}}'
                    );
                } else {
                    $scope.messageBody = defaultMessage;
                }

                if ($scope.messageBody || $scope.messageSubject) {
                    getInformationFromPreflightAndCompile();
                }
            }

            function $onInit() {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.showUnreleasedFeatures = config('superpower.unreleasedFeatures');

                // copy current rule message settings to local variables
                $scope.severityLevels = ALERT_SEVERITY_LEVELS;
                const rule = ctrl.rule;
                $scope.severityLevel = rule.severityLevel || rule.severity || '';
                $scope.runbookUrl = rule.runbookUrl || '';
                $scope.tip = rule.tip || '';
                $scope.dimensionsByPlot = {};
                copyMessage();
                ctrl.fetchChartData();
                ctrl.ruleDimensions = getSelectedRuleDimension();

                // when local variables change, update with parent binding
                if (previousWatch) {
                    previousWatch();
                }
                previousWatch = $scope.$watchGroup(
                    ['severityLevel', 'runbookUrl', 'tip'],
                    function (nval, oval) {
                        if (!angular.equals(nval, oval)) {
                            ctrl.onMessageSelect({
                                severityLevel: $scope.severityLevel,
                                runbookUrl: $scope.runbookUrl,
                                tip: $scope.tip,
                            });
                            compileParameterizedField();
                        }
                    }
                );
            }

            $scope.setSeverity = function (level) {
                $scope.severityLevel = level;
            };

            function toggleEditMessages(toggle) {
                $scope.editMessagesOpen = toggle;
            }

            function saveEdit() {
                const compiledSubject = detectorUtils.compileParameterizedField(
                    $scope.messageSubject
                );
                const compiledBody = detectorUtils.compileParameterizedField($scope.messageBody);
                const messageSubjectError = !compiledSubject && compiledSubject !== '';
                const messageBodyError = !compiledBody && compiledBody !== '';

                if (messageSubjectError) {
                    $window.alert('Message Subject could not be compiled.');
                } else if (messageBodyError) {
                    $window.alert('Message Body could not be compiled.');
                } else {
                    let messageBody = $scope.messageBody;
                    const defaultMessage = detectorUtils.getAutoDetectorRuleParameterizedString(
                        ctrl.rule,
                        ctrl.plots,
                        true
                    );
                    if (messageBodyEdited && messageBody) {
                        ctrl.rule.isCustomizedMessage =
                            messageBody.trim() !== defaultMessage.trim();
                    }
                    if (messageBody) {
                        messageBody = replaceTimestamp(messageBody);
                    }
                    ctrl.onMessageSelect({
                        severityLevel: $scope.severityLevel,
                        runbookUrl: $scope.runbookUrl,
                        tip: $scope.tip,
                        messageSubject: subjectEdited ? $scope.messageSubject.trim() : '',
                        messageBody: messageBodyEdited ? messageBody : '',
                    });
                    toggleEditMessages(false);
                    getInformationFromPreflightAndCompile();
                }
            }

            function cancelEdit() {
                toggleEditMessages(false);
                copyMessage();
            }

            function onSubjectEdited() {
                subjectEdited = true;
            }

            function onBodyEdited() {
                messageBodyEdited = true;
            }

            function revertToDefault() {
                $scope.messageSubject =
                    '{{ruleSeverity}} Alert: {{{ruleName}}} ({{{detectorName}}})';
                $scope.messageBody = detectorUtils.getAutoDetectorRuleParameterizedString(
                    ctrl.rule,
                    ctrl.plots,
                    true
                );
                if (!$scope.messageBody) {
                    $scope.compiledBody = '';
                }
                ctrl.rule.isCustomizedMessage = false;
                ctrl.clearMessage({
                    clearMessageBody: true,
                    clearMessageSubject: true,
                });
                toggleEditMessages(false);
                getInformationFromPreflightAndCompile();
            }

            function addVariableSuggestions(suggestionText) {
                $scope.$broadcast(
                    'suggestions added',
                    suggestionText,
                    $scope.currentlyFocusedInput
                );
            }

            function setTabSuggestion(suggestionText) {
                if ($scope.currentlyFocusedInput) {
                    autofillSuggestion[$scope.currentlyFocusedInput] = suggestionText;
                }
            }

            function getTabSuggestion(inputType) {
                return autofillSuggestion[inputType] || null;
            }

            function focusSubject() {
                $scope.currentlyFocusedInput = 'messageSubject';
            }

            function focusBody() {
                $scope.currentlyFocusedInput = 'messageBody';
            }

            $scope.$on('detector wizard selected stage', function (ev, stage) {
                if (stage === 'message') {
                    $scope.preflightEvent = null;
                    $scope.editMessagesOpen = false;
                    copyMessage();
                }
            });

            $scope.$on('custom message updated', function (ev, val, type) {
                if (type === 'messageSubject') {
                    $scope.messageSubject = val;
                    onSubjectEdited();
                }

                if (type === 'messageBody') {
                    $scope.messageBody = val;
                    onBodyEdited();
                }
            });

            $scope.$on('pass chart and event information', function (ev, result) {
                $scope.preflightEvent = result.events;
                processMetadata(result.metadata);

                if ($scope.preflightEvent) {
                    getInformationFromPreflightAndCompile();
                }
            });

            $scope.$on('filter text', function (ev, val, helperFunction) {
                $scope.filterText = val;
                $scope.helperFunction = helperFunction;
            });

            function getInformationFromPreflightAndCompile() {
                if ($scope.preflightEvent) {
                    const params = detectorWizardMessageHandlebarService.getContextObject(
                        ctrl.detector,
                        ctrl.rule
                    );

                    params.timestamp = $scope.preflightEvent.timestampMs;
                    params.incidentId = $scope.preflightEvent.properties.incidentId;
                    params.anomalyState = $scope.preflightEvent.properties.is.toUpperCase();
                    params.normal = $scope.preflightEvent.properties.is === 'ok';

                    const context = angular.fromJson(
                        $scope.preflightEvent.metadata.sf_detectInputContexts
                    );

                    angular.forEach(
                        $scope.preflightEvent.properties.inputs,
                        (inputInformation, signalLetter) => {
                            if (inputInformation && inputInformation.value) {
                                const letter = _.first(context[signalLetter].identifiers);
                                params.inputs[letter] = inputInformation;
                            }
                        }
                    );

                    compileParameterizedField(params);
                } else {
                    ctrl.fetchChartData();
                    compileParameterizedField();
                }
            }

            function replaceTimestamp(message) {
                return message.replace(
                    /{{\s*timestamp\s*}}/g,
                    '{{dateTimeFormat timestamp format="full"}}'
                );
            }

            function compileParameterizedField(params) {
                if (!params) {
                    params = detectorWizardMessageHandlebarService.getContextObject(
                        ctrl.detector,
                        ctrl.rule
                    );
                    if (ctrl.alertSignalInputs) {
                        // pad all alert signals with a fake value until preflight information comes back
                        ctrl.alertSignalInputs.forEach((letter) => {
                            params.inputs[letter] =
                                detectorWizardMessageHandlebarService.FAKE_INPUT_VALUE;
                        });
                    }
                }

                if (ctrl.ruleDimensions) {
                    params.dimensions = DimensionList.create(ctrl.ruleDimensions);
                }

                if ($scope.messageBody) {
                    const messageBody = replaceTimestamp($scope.messageBody);
                    const compiled = $window.Handlebars.compile(messageBody)(params).trim();
                    $scope.compiledBody = compiled.replace(/\n\s*\n\s*\n/g, '\n\n');
                }

                if ($scope.messageSubject) {
                    $scope.compiledSubject = $window.Handlebars.compile($scope.messageSubject)(
                        params
                    ).trim();
                }
            }

            function getSelectedRuleDimension() {
                return ctrl.selectedPlot
                    ? $scope.dimensionsByPlot[ctrl.selectedPlot.uniqueKey]
                    : ctrl.allDimensions;
            }

            function processMetadata(metaDataMap) {
                $scope.dimensionsByPlot = {};
                ctrl.allDimensions = {};
                angular.forEach(metaDataMap, (metadata) => {
                    const label = metadata.sf_streamLabel;
                    // v1 detectors always publish each plot and have a label
                    // in v2 detectors you can choose whether or not to publish inputs
                    // so they may or may not have a label, in the case there is no label still update all dimensions
                    if (label) {
                        const uniqueKey = plotUtils.getUniqueKeyFromLetter(label);
                        let dimensions = $scope.dimensionsByPlot[uniqueKey];
                        if (!dimensions) {
                            dimensions = {};
                        }

                        metadata.sf_key.forEach((key) => {
                            dimensions[key] = metadata[key] || dimensions[key];
                            ctrl.allDimensions[key] = metadata[key];
                        });
                        $scope.dimensionsByPlot[uniqueKey] = dimensions;
                    } else {
                        metadata.sf_key.forEach((key) => {
                            ctrl.allDimensions[key] = metadata[key];
                        });
                    }
                });
                ctrl.ruleDimensions = getSelectedRuleDimension();
            }
        },
    ],
});
