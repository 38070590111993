import { sanitizeTermUsingSpaces } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './objecttags.tpl.html';
import iconTypeaheadItemTemplateUrl from '../../../legacy/common/bootstrap/iconTypeaheadItem.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular.module('signalview.objectmanager').directive('objectTags', [
    '$q',
    '$timeout',
    '$location',
    '$log',
    'signalboost',
    'v2ObjectService',
    'userAnalytics',
    'hasCapability',
    'catalogHelper',
    function (
        $q,
        $timeout,
        $location,
        $log,
        signalboost,
        v2ObjectService,
        userAnalytics,
        hasCapability,
        catalogHelper
    ) {
        return {
            restrict: 'AE',
            templateUrl,
            scope: {
                selectedObjects: '=',
                isNewCatalog: '=',
                viewMode: '=',
            },
            link: function ($scope, element) {
                $scope.focusTagNameInput = function () {
                    element.find('input[name="tagName"]').focus();
                };
            },
            controller: [
                '$scope',
                function ($scope) {
                    const GA_COMPONENT = 'catalogTag';
                    $scope.iconTypeaheadItemTemplateUrl = iconTypeaheadItemTemplateUrl;

                    function reset() {
                        $scope.tags = [];
                        $scope.tagname = '';
                    }

                    reset();

                    $scope.editMode = false;
                    $scope.addMode = false;

                    if ($scope.selectedObjects && $scope.selectedObjects.length > 0) {
                        const associatedCapability = catalogHelper.getCapabilityForType(
                            $scope.selectedObjects[0].sf_type
                        );
                        if (associatedCapability) {
                            // need to have the capability to both update tags AND the host object
                            hasCapability(associatedCapability).then(
                                (hasUpdateCapability) =>
                                    ($scope.hasUpdateCapability = hasUpdateCapability)
                            );
                            hasCapability(Capability.UPDATE_TAG).then(
                                (hasUpdateCapability) =>
                                    ($scope.hasUpdateTagCapability = hasUpdateCapability)
                            );
                        } else {
                            // catch-all for any other object types that might use this shared component
                            $scope.hasUpdateCapability = true;
                        }
                    }

                    $scope.isMtsSelected = function () {
                        return $scope.selectedObjects.some(
                            (object) => object.sf_type === 'MetricTimeSeries'
                        );
                    };

                    $scope.enableEditMode = function () {
                        userAnalytics.event(GA_COMPONENT, 'editMode');
                        $scope.originalTags = angular.copy($scope.tags);
                        $scope.editMode = !$scope.isMtsSelected();
                    };

                    $scope.enableAddMode = function () {
                        userAnalytics.event(GA_COMPONENT, 'addMode');
                        $scope.enableEditMode();

                        $timeout(function () {
                            $scope.focusTagNameInput();
                        });
                    };

                    $scope.cancelEdit = function () {
                        userAnalytics.event(GA_COMPONENT, 'cancelEdit');
                        $scope.editMode = false;
                        $scope.tags = $scope.originalTags;
                    };

                    $scope.saveEdit = function () {
                        userAnalytics.event(GA_COMPONENT, 'saveEdit');
                        $scope.editMode = false;

                        if ($scope.tagsForm.tagName.$valid) {
                            addTag($scope.tagname);
                        }

                        commitDeletions();
                        commitAdditions();

                        $scope.originalTags = $scope.tags;
                    };

                    $scope.$watch('selectedObjects', function (objects) {
                        reset();

                        if (objects.length === 1) {
                            const object = objects[0];
                            $scope.originalTags = $scope.tags =
                                (v2ObjectService.isV2Object(object)
                                    ? object.tags
                                    : object.sf_tags) || [];
                        }
                    });

                    $scope.addToFilter = function (tag) {
                        const query = 'sf_tags:' + tag;

                        const search = $location.search();
                        const sources = search['sources[]'] || [];
                        sources.push(query);
                        $location.search('sources[]', sources);
                    };

                    function addTag(tagName) {
                        userAnalytics.event(GA_COMPONENT, 'addTag');
                        if (!$scope.tagsForm.tagName.$valid) return;

                        const exists = $scope.tags.some(function (existingTag) {
                            return existingTag === tagName;
                        });

                        if (exists) {
                            $log.warn(
                                'Rejecting attempt to add an already existing tag: ' + tagName
                            );
                            return;
                        }

                        $scope.tags.push(tagName);
                        $scope.tagname = '';
                        $scope.tagsForm.tagName.$setPristine();
                    }

                    function commitDeletions() {
                        const removedTags = $scope.originalTags.filter(function (oldTag) {
                            return $scope.tags.indexOf(oldTag) === -1;
                        });

                        const promises = removedTags.map(function (tagName) {
                            return signalboost.tag
                                .all()
                                .customGET(
                                    '',
                                    {
                                        query: 'sf_tag:' + sanitizeTermUsingSpaces(tagName),
                                    },
                                    {}
                                )
                                .then(function (data) {
                                    const tagObject = data.rs[0];
                                    if (!tagObject) return;
                                    signalboost.tag
                                        .one(tagObject.sf_id)
                                        .get()
                                        .then(function (tag) {
                                            $scope.selectedObjects
                                                .filter(function (object) {
                                                    return object.sf_type !== 'tag';
                                                })
                                                .forEach(function (object) {
                                                    tag.removeMember(
                                                        v2ObjectService.isV2Object(object)
                                                            ? object.id
                                                            : object.sf_id
                                                    );
                                                });
                                        });
                                });
                        });

                        return $q.all(promises);
                    }

                    function commitAdditions() {
                        const promises = $scope.tags
                            .filter(function (newTag) {
                                return $scope.originalTags.indexOf(newTag) === -1;
                            })
                            .map(function (tagName) {
                                return signalboost.tag
                                    .all()
                                    .customGET(
                                        '',
                                        {
                                            query: 'sf_tag:' + sanitizeTermUsingSpaces(tagName),
                                        },
                                        {}
                                    )
                                    .then(function (data) {
                                        const tag = data.rs[0];

                                        if (!tag) {
                                            return signalboost.tag.create({ sf_tag: tagName });
                                        } else {
                                            return signalboost.tag.one(tag.sf_id).get();
                                        }
                                    })
                                    .then(function (tag) {
                                        $scope.selectedObjects
                                            .filter(function (object) {
                                                return object.sf_type !== 'tag';
                                            })
                                            .forEach(function (object) {
                                                tag.addMember(
                                                    v2ObjectService.isV2Object(object)
                                                        ? object.id
                                                        : object.sf_id
                                                );
                                            });
                                    });
                            });

                        return $q.all(promises);
                    }

                    $scope.removeTag = function (tagName) {
                        userAnalytics.event(GA_COMPONENT, 'removeTag');
                        const index = $scope.tags.indexOf(tagName);
                        if (index === -1) return;

                        $scope.tags.splice(index, 1);
                    };

                    $scope.onKeyPress = function (event) {
                        if (event && event.keyCode === 13) {
                            addTag($scope.tagname);
                        }
                    };

                    $scope.onSelect = function (item) {
                        let str = item.value;
                        str = str.replace(' (new tag)', '');
                        addTag(str);
                    };

                    $scope.tagSearchKey = function (partialTagName) {
                        return signalboost.autosuggest
                            .getTagSuggestions({
                                query: '',
                                partialInput: partialTagName,
                            })
                            .then(function (data) {
                                const tags = [];
                                angular.forEach(data, function (tg) {
                                    tags.push({
                                        iconClass: 'sf-icon-tag',
                                        value: tg,
                                    });
                                });

                                if (!data.length && partialTagName.length > 1) {
                                    tags.push({
                                        iconClass: 'sf-icon-tag',
                                        value: partialTagName + ' (new tag)',
                                    });
                                }
                                return tags;
                            });
                    };
                },
            ],
        };
    },
]);
