import templateUrl from './objectdetector.tpl.html';

/**
 * @description
 * Renders a list of detector rules. For each rules, shows the state of the
 * rule and who it will notify if it triggers.
 */
angular.module('signalview.objectmanager').directive('objectDetector', [
    'NOTIFIER_INTEGRATIONS',
    'detectorUtils',
    function (NOTIFIER_INTEGRATIONS, detectorUtils) {
        return {
            restrict: 'AE',
            scope: {
                object: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.getAutoDetectorRuleDescription = function (rule) {
                        const allPlots = $scope.object.sf_uiModel.allPlots;
                        return detectorUtils.getAutoDetectorRuleDescription(rule, allPlots);
                    };

                    $scope.isValidRule = function (rule) {
                        const allPlots = $scope.object.sf_uiModel.allPlots;
                        return detectorUtils.validateDetectorRule(rule, allPlots) === undefined;
                    };

                    /**
                     * Get a list of strings representing the names of services and
                     * people that *rule* will notify when it triggers.
                     *
                     * @param {Object} rule
                     * @param {Object[]} rule.notifications
                     */
                    $scope.getNotificationsText = function (rule) {
                        const notifierNames = angular.extend({}, NOTIFIER_INTEGRATIONS, {
                            webhooks: 'Webhooks',
                            webhook: 'Webhook',
                        });
                        const names = {};
                        rule.notifications.forEach(function (notification) {
                            names[
                                notification.type === 'email'
                                    ? notification.email
                                    : notifierNames[notification.type]
                            ] = true;
                        });
                        return Object.keys(names).join(', ');
                    };
                },
            ],
        };
    },
]);
