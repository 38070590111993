import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import ExclamationSquare from '@splunk/react-icons/ExclamationSquare';

const StyledErrorIcon = styled(ExclamationSquare)`
    margin-right: ${variables.spacingSmall};
    height: 1.5em;
    width: auto;
`;

const Centered = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CenteredErrorMessage: React.FC = ({ children }) => (
    <Centered>
        <StyledErrorIcon variant="filled" />
        {children}
    </Centered>
);

type Props = {
    children: React.ReactNode;
};
type State = {
    error?: string;
};

export class ErrorBoundary extends React.Component<Props, State> {
    public state = {
        error: undefined,
    };

    /**
     * Map of error types that have been tracked through telemetry. Any tracked error should not be sent twice.
     */
    private trackedRenderErrors: Record<string, boolean> = {};

    /**
     * Log sink for tracking errors caught by this boundary.
     */
    private debugLogger = console.error;

    /**
     * Catch any application-wide errors that are thrown. This happens during rendering issues, so is a centralized
     * location for us to capture, track, and show user a more friendly message than the component unmounting
     * completely.
     */
    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        const errorSignature = error.message;
        if (!this.trackedRenderErrors[errorSignature]) {
            this.trackedRenderErrors[errorSignature] = true;
            this.setState({ error: 'An error occurred while trying to render the page.' });
            this.debugLogger(`An error was caught by an ErrorBoundary.${errorInfo.componentStack}`);
        }
    }

    public render(): ReactNode {
        if (!this.state.error) {
            return this.props.children;
        }

        return <CenteredErrorMessage>{this.state.error}</CenteredErrorMessage>;
    }
}
