import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from '@splunk/react-ui/Link';
import NoDataDark from '../../../assets/images/nodata_dark.svg';
import NoDataLight from '../../../assets/images/nodata_light.svg';

const StyledNoDataMessageBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function NoDataMessage(props) {
    const { message, link, linkText, themeKey = '' } = props;

    return (
        <StyledNoDataMessageBlock className="sf-absolute-fill">
            <img src={themeKey === 'dark' ? NoDataDark : NoDataLight} />
            <p>
                {message}
                <br />
                {link && linkText && (
                    <Link to={link} openInNewContext rel="noopener noreferrer">
                        {linkText}
                    </Link>
                )}
            </p>
        </StyledNoDataMessageBlock>
    );
}

NoDataMessage.propTypes = {
    message: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    themeKey: PropTypes.string,
};
