export default {
    startTime: {
        relative: 'startTime',
        absolute: 'startTimeUTC',
    },
    endTime: {
        relative: 'endTime',
        absolute: 'endTimeUTC',
    },
    environment: 'environment',
    sourceOverride: 'sources[]',
    variablesOverride: 'variables[]',
    pointDensity: 'density',
    groupBy: 'groupBy',
    groupBySelection: 'groupBySelection',
    viewTime: 'viewTime',
    selectedId: 'select', // shares with original catalog for backcompat?
    selectedKeyValue: 'selectedKeyValue',
    createdOn: 'createdOn',
    activeOn: 'activeOn',
    includeInactive: 'includeInactive',
    topicFilterKey: 'topicFilterKey',
    topicFilterType: 'topicFilterType',
    colorBy: 'colorBy',
    outlierStrategy: 'outlierStrategy',
    outlierDepth: 'outlierDepth',
    resolutionAdjustable: 'resolutionAdjustable',
    tab: 'tab',
    mapSelection: 'mapSelection',
    query: 'query',
    filterByTeam: 'filterByTeam',
    selectedEventOverlays: 'selectedEventOverlays',
    selectedSpan: 'selectedSpan',
    accessToken: 'accessToken',
    forceAbsolute: 'forceAbsolute',
    targetOrg: 'targetOrg',
    crossLinkSelection: 'crossLinkSelection',
    traceId: 'traceId',
    duration: 'duration',
    service: 'service',
    services: 'services[]',
    spanCriteria: 'spanCriteria[]',
    spanMatch: 'spanMatch',
    operations: 'operations[]',
    serviceEndpoints: 'serviceEndpoints[]',
    businessWorkflows: 'businessWorkflows[]',
    tags: 'tags[]',
    status: 'status',
    sort: 'sort',
    highlightDetectorPlot: 'highlightDetectorPlot',
    linkDetector: 'linkDetector',
    fromChart: 'fromChart',
    hideInfoSidebar: 'hideInfoSidebar',
    tokenExpiry: 'tokenExpiry',
    detectorOrigins: 'detectorOrigins',
    muted: 'muted',
    // Info Sidebar specific params

    infoSidebarAnalyzerQuery: 'infoSidebarAnalyzerQuery[]',
    // Param for the current active tab on the sidebar
    infoSidebarTab: 'infoSidebarTab',
    // Info tab can have different resources, this Param indicates which resodoesParamExisturce it is (k8s, dashboards, etc)
    infoSidebarInfoTabRefType: 'infoSidebarInfoTabRefType',
    infoSidebarDashboardId: 'infoSidebarDashboardId',
    infoSidebarDashboardGroupId: 'infoSidebarDashboardGroupId',
    infoSidebarDashboardConfigId: 'infoSidebarDashboardConfigId',
    infoSidebarSources: 'infoSidebarSources[]',

    activeModal: 'activeModal',
};
