import { CapabilityValues } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../app/routing/ngRoute';

interface RbacUtilsService {
    checkCapabilityAndRedirect(capability: CapabilityValues): void;
}

function rbacUtilsService(
    hasCapability: (capability: CapabilityValues) => Promise<boolean>
): RbacUtilsService {
    return {
        checkCapabilityAndRedirect(capability: CapabilityValues): void {
            hasCapability(capability).then((isCapabilityPresent: boolean) => {
                if (!isCapabilityPresent) {
                    ngRoute?.history?.replace('/forbidden');
                }
            });
        },
    };
}

rbacUtilsService.$inject = ['hasCapability'];

export default rbacUtilsService;
