import templateUrl from './syncRule.html';

angular.module('sfx.ui').directive('newrelicSyncRule', [
    'sourceFilterService',
    function (sourceFilterService) {
        return {
            restrict: 'E',
            scope: {
                editable: '=',
                items: '=',
                selectedItems: '=',
                selectItem: '=',
                credentialId: '=',
                item: '=?',
                domains: '=?',
                metrics: '=?',
                stats: '=?',
                removeItem: '=?',
                setDomains: '=?',
                setMetrics: '=?',
                setStats: '=?',
            },
            templateUrl,
            link: function ($scope) {
                $scope.domainDisplayType = 'apps';
                if ($scope.item && $scope.item.toLowerCase() === 'servers') {
                    $scope.domainDisplayType = 'servers';
                }
                $scope.selectableItem = function (item) {
                    return item !== $scope.item && $scope.selectedItems.indexOf(item) === -1;
                };
                $scope.getDisplayString = function (values) {
                    return sourceFilterService.getDisplayNameForFilters(values, 70);
                };
            },
        };
    },
]);
