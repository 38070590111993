import React, { createContext, FC, useContext, useMemo } from 'react';
import {
    EntityWithPotentialTargets,
    RelatedContentService,
} from '@splunk/olly-common/RelatedContent/useRelatedContent';
import { Target } from '@splunk/olly-common/RelatedContent/utils';

type Props = {
    relatedContentService?: RelatedContentService;
};

type ContextProps = {
    relatedContentService: RelatedContentService;
};
export const RelatedContentContext = createContext({} as ContextProps);

export const RelatedContentServiceProvider: FC<Props> = ({ relatedContentService, children }) => {
    const props = useMemo(
        () => ({
            relatedContentService: relatedContentService
                ? relatedContentService
                : ({
                      fetchTargetEntities: () => {
                          return new Promise((resolve) => {
                              setTimeout(() => {
                                  resolve([] as Target[]);
                              });
                          });
                      },
                      fetchTargets: () => {
                          return new Promise((resolve) => {
                              setTimeout(() => {
                                  resolve([] as EntityWithPotentialTargets[]);
                              });
                          });
                      },
                  } as RelatedContentService),
        }),
        [relatedContentService]
    );
    return (
        <RelatedContentContext.Provider value={props}>{children}</RelatedContentContext.Provider>
    );
};

export const useRelatedContent = (): ContextProps => useContext(RelatedContentContext);
