export default [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        const URL = API_URL + '/v2/opsgenie/supportedServiceRegions';
        let serviceRegionMapPromise = null;

        function fetchServiceRegions() {
            serviceRegionMapPromise = $http.get(URL).then((result) => {
                return result.data;
            });
        }

        function getServiceRegions() {
            if (!serviceRegionMapPromise) {
                fetchServiceRegions();
            }
            return serviceRegionMapPromise;
        }

        return {
            getServiceRegions,
        };
    },
];
