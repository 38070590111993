'use strict';

import templateUrl from './cozyText.html';

/**
 * Cozy text tries to fit text within a parent container (in width). This is done
 * by first expanding the width of cozy-text to the parent container (width:100%),
 * then iteratively fiddling with the font size of the cozy-text container itself
 * until the inner span of cozy-text is less than the container width.
 *
 * Cozy text will scale the font of text contained within it until it either
 * reaches a min or max font size.
 */
export default {
    templateUrl,
    transclude: true,
    bindings: {
        maxFontSize: '<',
        minFontSize: '<',
    },
    controller: [
        '$element',
        '$scope',
        '$timeout',
        '$log',
        function ($element, $scope, $timeout, $log) {
            const $ctrl = this;
            $scope.hasOverflow = true;

            let minFontSize;
            let maxFontSize;

            $ctrl.$onInit = function () {
                minFontSize = $ctrl.minFontSize || 8;
                maxFontSize = $ctrl.maxFontSize || 14;
                $scope.fontSize = maxFontSize;
            };

            let adjustAttempts = 0;
            const MAX_ADJUST_ATTEMPTS = maxFontSize - minFontSize;

            function adjustFontSize(direction) {
                if (adjustAttempts > MAX_ADJUST_ATTEMPTS) {
                    $log.warn('Exceeded max adjustments for cozy-text', $element);
                    return;
                }

                adjustAttempts++;

                const containerWidth = $element[0].getBoundingClientRect().width;
                const labelWidth = $element
                    .find('.cozy-text-content')[0]
                    .getBoundingClientRect().width;

                if (
                    containerWidth > labelWidth &&
                    $scope.fontSize < maxFontSize &&
                    direction !== 'down'
                ) {
                    $scope.hasOverflow = false;
                    $scope.fontSize++;
                    $timeout(() => adjustFontSize('up'));
                } else if (containerWidth < labelWidth) {
                    if ($scope.fontSize <= minFontSize) {
                        $scope.hasOverflow = true;
                    } else {
                        $scope.hasOverflow = false;
                        $scope.fontSize--;
                        if (direction !== 'up') {
                            $timeout(() => adjustFontSize('down'));
                        }
                    }
                }
            }

            function resize() {
                adjustAttempts = 0;
                $scope.hasOverflow = false;
                $timeout(() => adjustFontSize());
            }

            $scope.$on('resize cozy text', resize);
        },
    ],
};
