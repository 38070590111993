angular.module('signalview.detector').service('deleteDetector', [
    'sfxModal',
    'detectorService',
    'detectorv2Service',
    'userAnalytics',
    '$q',
    '$log',
    'ES_INDEXING_DELAY',
    'signalboost',
    '$timeout',
    function (
        sfxModal,
        detectorService,
        detectorv2Service,
        userAnalytics,
        $q,
        $log,
        ES_INDEXING_DELAY,
        signalboost,
        $timeout
    ) {
        return function (detector) {
            const modalInstance = sfxModal.open({
                template: '<div><i class="busy-spinner-light"></i></div>',
                windowClass: 'full-screen-busy-spinner',
                backdrop: 'static',
                keyboard: false,
            });

            let promise = null;
            if (detector.sf_signalflowVersion === 2) {
                // v2 detectors converted to v1 model structure should have
                // sf_modelVersion = 2 (see: detectorV2Converter.v2ModelToV1Model())
                if (detector.sf_modelVersion === 2) {
                    promise = detectorv2Service.delete(detector.sf_id || detector.id);
                } else {
                    promise = signalboost.detector1flow2.one(detector.sf_id).remove();
                }
            } else {
                promise = detectorService.delete(detector.sf_id || detector.id);
            }
            return promise
                .then(function () {
                    if (detector.sf_id) {
                        userAnalytics.event('detector', 'delete');
                    }
                    return $timeout(modalInstance.close, ES_INDEXING_DELAY);
                })
                .catch(function (e) {
                    modalInstance.close();
                    $log.error(e);
                    alert('Unable to delete detector');
                    return $q.reject('unable to delete detector');
                });
        };
    },
]);
