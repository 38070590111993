export const CHART_CONSTANTS = {
    KEY_ALIAS: {
        value: 'Value',
        pinnedValue: 'Pinned Value',
        rollup: 'Rollup',
        sf_metric: 'Plot Name',
        sf_originatingMetric: 'sf_metric',
        highThreshold: 'High',
        lowThreshold: 'Low',
    },
    UNIT_COLUMNS: ['plainPinnedValue', 'plainValue', 'valuePrefix', 'valueSuffix'],
};
