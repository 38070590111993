import CancelToken from '../../../../common/sfUtil/CancelToken';

angular
    .module('sfx.api.v2')

    .service('teamsApiService', [
        '$q',
        '$http',
        'currentUser',
        'API_URL',
        'notificationsUtil',
        function ($q, $http, currentUser, API_URL, notificationsUtil) {
            /* construct api url for teams resource */
            const apiUrl = API_URL + '/v2';
            const resourceName = 'team';
            const resourceEndpoint = apiUrl + '/' + resourceName;
            const notificationListBuckets = [
                'default',
                'critical',
                'warning',
                'major',
                'minor',
                'info',
            ];
            const DEFAULT_PARAMS = {
                limit: 10000,
                orderBy: 'name',
            };

            // Service Public API
            return {
                addMember,
                addMembers,
                updateMemberships,
                createTeam,
                deleteTeam,
                get,
                getAll,
                getTeams,
                getTeamSummary,
                getTeamDetectors,
                getTeamDashboardGroups,
                removeMember,
                removeMembers,
                renameTeam,
                searchMembers,
                updateTeam,
                updateNotifications,
                linkDashboardGroup,
                linkDetector,
                unlinkDashboardGroup,
                unlinkDetector,
            };

            function getTeamResourceEndpoint(teamId) {
                return resourceEndpoint + '/' + encodeURIComponent(teamId);
            }

            /**
             * Create or update team memberships.
             * A membership has an orgUserId and optionally a boolean isTeamManager
             * Returns updated team members
             */
            function updateMemberships(teamId, memberships) {
                const route = getTeamResourceEndpoint(teamId) + '/memberships';
                return $http.put(route, memberships);
            }

            function addMembers(teamId, members) {
                const route = getTeamResourceEndpoint(teamId) + '/members';
                return $http.put(route, { members });
            }

            function addMember(teamId, userId) {
                const route = resourceEndpoint + '/' + teamId + '/member/' + userId;
                return $http.put(route, userId);
            }

            function getTeamDetectors(teamId, partialInput) {
                const route = resourceEndpoint + '/_/' + teamId + '/detectors';
                const params = partialInput ? { partialInput } : {};
                return $http
                    .get(route, { params })
                    .then(getResponseData)
                    .then((data) => data.detectors);
            }

            // This endpoint provides dashboardgroups in index foramt
            function getTeamDashboardGroups(teamId) {
                const route = resourceEndpoint + '/_/' + teamId + '/dashboardgroups';
                return $http
                    .get(route)
                    .then(getResponseData)
                    .then((data) => data.results);
            }

            /**
             * Create or update a new team with optional description.
             * Returns created token.
             */
            function createTeam(team) {
                team = angular.copy(team);
                if (team.notificationLists) {
                    convertV1NotificationListsToV2NotificationLists(team.notificationLists);
                }

                team.description = team.description || '';
                return getOrgIdParams()
                    .then((params) => $http.post(resourceEndpoint, team, { params }))
                    .then(getResponseData)
                    .then(createDefaultNotificationPolicy);
            }

            function deleteTeam(teamId) {
                return getOrgIdParams().then((params) =>
                    $http.delete(getTeamResourceEndpoint(teamId), { params })
                );
            }

            /**
             *  If no teamId is supplied, then all teams will be fetched
             */
            function get(teamId) {
                if (!teamId) return getAll();

                const route = getTeamResourceEndpoint(teamId);
                return getOrgIdParams()
                    .then((orgIdParams) => $http.get(route, orgIdParams))
                    .then(getResponseData)
                    .then(doUIConversions);
            }

            function getAll(searchParams = {}, cancelToken = new CancelToken()) {
                searchParams = Object.assign(angular.copy(DEFAULT_PARAMS), searchParams);

                return getOrgIdParams()
                    .then((orgIdParams) => {
                        const deferred = $q.defer();
                        cancelToken.cancel = () => deferred.resolve();
                        const params = angular.extend(searchParams, orgIdParams);

                        return $http.get(resourceEndpoint, { params, timeout: deferred.promise });
                    })
                    .then(getResponseData)
                    .then((data) => {
                        data.results = data.results.map(doUIConversions);
                        return data;
                    });
            }

            function getTeams(idList) {
                const params = { id: idList };
                return $http
                    .get(resourceEndpoint + '/_/fetch', { params })
                    .then(getResponseData)
                    .then((data) => {
                        // conversions are required to handle notifications across v2 to v1 notification lists.
                        // Fixes Lions-3160.
                        data = data.map(doUIConversions);
                        return data;
                    });
            }

            function getTeamSummary(searchParams = {}) {
                return getOrgIdParams()
                    .then((orgIdParams) => {
                        const params = angular.extend(
                            {},
                            searchParams,
                            DEFAULT_PARAMS,
                            orgIdParams
                        );
                        return $http.get(resourceEndpoint + '/_/summary', { params });
                    })
                    .then(getResponseData)
                    .then((data) => {
                        data.results = data.results.map(doUIConversions);
                        return data;
                    });
            }

            function removeMember(teamId, userId) {
                const route = resourceEndpoint + '/' + teamId + '/member/' + userId;
                return getOrgIdParams().then(() => $http.delete(route, userId));
            }

            function removeMembers(teamId, removedMembers) {
                const route = getTeamResourceEndpoint(teamId) + '/members';
                const headers = { 'Content-Type': 'application/json' };
                const data = { members: removedMembers };
                return getOrgIdParams().then((params) =>
                    $http.delete(route, { headers, data, params })
                );
            }

            /**
             * Rename team. All tokens belonging to that team get migrated to
             * the new team.
             */
            function renameTeam(teamId, updates) {
                return getOrgIdParams()
                    .then((params) => {
                        return $http.put(resourceEndpoint + '/' + teamId, updates, {
                            params: params,
                        });
                    })
                    .then(getResponseData);
            }

            /*
             * search params is an object containing optional string fields teamId and
             * partial.
             */
            function searchMembers(searchParams) {
                return getOrgIdParams().then((params) => {
                    angular.extend(params, searchParams);
                    return $http
                        .get(resourceEndpoint + '/_/members', { params })
                        .then(getResponseData);
                });
            }

            function updateTeam(teamId, team) {
                team = angular.copy(team);
                if (team.notificationLists) {
                    convertV1NotificationListsToV2NotificationLists(team.notificationLists);
                }

                team.description = team.description || '';
                return getOrgIdParams()
                    .then((params) => $http.put(getTeamResourceEndpoint(teamId), team, { params }))
                    .then(getResponseData)
                    .then(doUIConversions);
            }

            function updateNotifications(teamId, notificationLists) {
                return get(teamId).then((team) => {
                    team.notificationLists = notificationLists;
                    return updateTeam(teamId, team);
                });
            }

            /**
             *  LINK CONTENT TO TEAMS
             */

            function linkDashboardGroup(teamId, dashboardGroupId) {
                const route =
                    getTeamResourceEndpoint(teamId) + `/dashboardgroup/${dashboardGroupId}`;
                return $http.post(route, {});
            }

            function linkDetector(teamId, detectorId) {
                const route = getTeamResourceEndpoint(teamId) + `/detector/${detectorId}`;
                return $http.post(route, {});
            }

            function unlinkDashboardGroup(teamId, dashboardGroupId) {
                const route =
                    getTeamResourceEndpoint(teamId) + `/dashboardgroup/${dashboardGroupId}`;
                return $http.delete(route);
            }

            function unlinkDetector(teamId, detectorId) {
                const route = getTeamResourceEndpoint(teamId) + `/detector/${detectorId}`;
                return $http.delete(route);
            }

            /* private functions */
            function convertV1NotificationListsToV2NotificationLists(notificationLists) {
                notificationListBuckets.forEach((severity) => {
                    if (notificationLists[severity]) {
                        notificationLists[severity] = notificationsUtil.convertV1ListToV2(
                            notificationLists[severity]
                        );
                    }
                });
            }

            function convertV2NotificationListsToV1NotificationLists(notificationLists) {
                notificationListBuckets.forEach((severity) => {
                    if (notificationLists[severity]) {
                        notificationLists[severity] = notificationsUtil.convertV2ListToV1(
                            notificationLists[severity]
                        );
                    }
                });
            }

            function doUIConversions(team) {
                if (team.notificationLists) {
                    convertV2NotificationListsToV1NotificationLists(team.notificationLists);
                }

                return team;
            }

            /**
             * Retrieve current organization id for user session and
             * create a query parameter of the form ?organizationId={{orgId}}
             */
            function getOrgIdParams() {
                return currentUser.orgId().then((organizationId) => {
                    return { organizationId };
                });
            }

            function createDefaultNotificationPolicy(team) {
                const defaultPolicy = { default: [{ team: team.id, type: 'teamEmail' }] };
                return updateNotifications(team.id, defaultPolicy);
            }

            function getResponseData(response) {
                return response.data;
            }
        },
    ]);
