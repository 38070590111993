import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './teamAlerts.tpl.html';

export default {
    templateUrl,
    bindings: {
        team: '<',
        orgId: '<',
    },
    controller: [
        '$interval',
        '$location',
        '$scope',
        'alertTypeService',
        'ETSAlertService',
        'getAlertAggregation',
        'urlOverridesService',
        'DetectorV2SearchService',
        function (
            $interval,
            $location,
            $scope,
            alertTypeService,
            ETSAlertService,
            getAlertAggregation,
            urlOverridesService,
            DetectorV2SearchService
        ) {
            const ctrl = this;
            const eventRefreshInterval = 60 * 1000;
            let queryTerms;

            ctrl.$onInit = $onInit;

            function $onInit() {
                queryTerms = [
                    `sf_anomalyState:(${alertTypeService.getFiringStateQueryStr()})`,
                    `sf_propagatedTeams:${sanitizeTerm(ctrl.team.id)}`,
                ];
                checkDetectorsExist();
                $scope.$on('severity panel clicked', handleSeverityClick);
                refreshAlerts();
                $interval(refreshAlerts, eventRefreshInterval);
            }

            function handleSeverityClick($event, severity) {
                urlOverridesService.setSourceOverride([`sf_severity:${severity}`]);
                urlOverridesService.setFilterByTeam(ctrl.team.id);
                $location.path(`/alerts/${ctrl.orgId}`);
            }

            /* unscoped functions */

            function checkDetectorsExist() {
                DetectorV2SearchService.search({ teamId: ctrl.team.id, limit: 1 }).then(
                    (res) => (ctrl.hasDetectors = res.count !== 0)
                );
            }

            function refreshAlerts() {
                getAlertAggregation(ETSAlertService, queryTerms.join(' AND ')).then(
                    (aggregationData) => {
                        ctrl.alertAggregations = aggregationData;

                        if (Object.keys(aggregationData.result).length) {
                            // if alerts start coming in then cleary the team has linked detectors;
                            // update hasDetectors accordingly.
                            ctrl.hasDetectors = true;
                        }
                    }
                );
            }
        },
    ],
};
