import templateUrl from './stringPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('stringPickerController', [
        '$scope',
        function ($scope) {
            const $ctrl = this;
            $ctrl.$onInit = function () {
                init();
                selectValue($scope.$ctrl.model);
            };

            function init() {
                $scope.valid = true;
                $scope.enumValues = $scope.$ctrl.definition.dataType.values || [];
                $scope.isRadio = $scope.$ctrl.definition.dataType.select === 'radio';
            }

            function selectValue(v) {
                let model = null;
                $scope.enumValues.some(function (val) {
                    if (val.value === v) {
                        model = val;
                        return true;
                    }
                    return false;
                });

                $scope.localValue = {
                    value: model || ($scope.enumValues.length ? $scope.enumValues[0] : null),
                };
            }

            $scope.$watch('localValue.value', function (v) {
                $scope.valid = validate(v);
                $scope.$ctrl.onTypedUpdate({ value: v ? v.value : null, validity: $scope.valid });
            });

            function validate(v) {
                return $scope.enumValues.length === 0 || $scope.enumValues.indexOf(v) > -1;
            }

            $scope.$ctrl.$onChanges = function (changes) {
                if (
                    changes.definition &&
                    !angular.equals(
                        changes.definition.currentValue,
                        changes.definition.previousValue
                    )
                ) {
                    init();
                }
                if (changes.model) {
                    const value = changes.model.currentValue;
                    selectValue(value);
                }
            };
        },
    ])
    .component('stringPicker', {
        replace: true,
        controller: 'stringPickerController',
        bindings: {
            model: '<',
            definition: '<',
            validationError: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
