export default [
    '$rootScope',
    function ($rootScope) {
        // This service maintains a cache that contains some information about the dashboards
        // that is used to populate the favorites sections on the index and the dashboard
        // sidebar. If the cache is empty when either of these two places are visited, then
        // they will fill the cache. Cached dashboards are returned as an array of dashboard
        // like objects (not all dashboard fields are necessary).
        let cache = {};

        $rootScope.$on('current organization changed', function () {
            cache = {};
        });

        function put(favorites) {
            cache = favorites.reduce(function (map, favorite) {
                map[favorite.favoriteKey] = favorite;
                return map;
            }, {});
        }

        function insertManyToCache(array) {
            array.forEach(insertIntoCache);
        }

        function insertIntoCache(favorite) {
            cache[favorite.favoriteKey] = favorite;
        }

        function removeFromCache(key) {
            return delete cache[key];
        }

        function getCache() {
            const favorites = [];
            angular.forEach(cache, (value) => {
                favorites.push(value);
            });
            return favorites;
        }

        return {
            put: put,
            insertManyToCache: insertManyToCache,
            insertIntoCache: insertIntoCache,
            removeFromCache: removeFromCache,
            getCache: getCache,
        };
    },
];
