'use strict';

/* jshint camelcase: false */
angular.module('sfx.data').factory('createMetabaseObjectService', [
    'currentUser',
    '$window',
    '$q',
    'SUPPORT_EMAIL',
    'featureEnabled',
    function (currentUser, $window, $q, SUPPORT_EMAIL, featureEnabled) {
        // api is a swagger resource api for a metabase object
        return function (api) {
            function filterQuery(q, org, includeArchived) {
                let query = 'sf_organizationID:' + org;
                if (!includeArchived) {
                    query += ' AND (NOT sf_archived:true) ';
                }

                if (q !== '') {
                    query += ' AND (' + q + ')';
                }

                return query;
            }

            function filteredFn(parms, fn) {
                return currentUser.orgId().then(function (orgId) {
                    if (typeof parms === 'string') {
                        parms = filterQuery(parms, orgId);
                    } else if (parms.query) {
                        parms.query = filterQuery(parms.query, orgId, parms.includeArchived);
                    }
                    return fn(parms);
                });
            }

            function argumentBind(fn) {
                return function (parms) {
                    return filteredFn(parms, fn);
                };
            }

            function create(obj) {
                if (obj.sf_organizationID) {
                    return api.create({ instance: obj });
                } else {
                    //remove this backup logic once we're sure we no longer make these calls
                    currentUser.isSuperUser().then(function (isSuper) {
                        if (isSuper) {
                            const alertMsg = featureEnabled('supportSwitch')
                                ? '`No organization was specified. Please access the Splunk Support Portal`'
                                : `No organization was specified. Please contact ${SUPPORT_EMAIL}`;
                            $window.alert(alertMsg);
                        }
                    });
                    return currentUser.orgId().then(function (orgId) {
                        obj.sf_organizationID = orgId;
                        return api.create({ instance: obj });
                    });
                }
            }

            function update(obj) {
                return api.update({ id: obj.sf_id, instance: obj }).then(
                    function (updatedObject) {
                        updatedObject.sf_id = obj.sf_id;
                        updatedObject.sf_organizationID = obj.sf_organizationID;
                        return updatedObject;
                    },
                    function (e) {
                        return $q.reject(e);
                    }
                );
            }

            function save(obj) {
                if (obj.sf_id) {
                    return update(obj);
                } else {
                    return create(obj);
                }
            }

            return {
                create: create,
                update: update,
                save: save,
                get: api.getInstance,
                delete: api.delete,
                search: argumentBind(api.search),
                unrestrictedSearch: api.search,
                aggregation: api.aggregation,
                hierarchy: api.getDirectHierarchy,
            };
        };
    },
]);
