import templateUrl from './jobFeedback.tpl.html';
import jobFeedbackMessageTemplateUrl from './jobFeedbackMessage.tpl.html';

angular
    .module('signalview.jobFeedback', [])

    .directive('jobFeedback', [
        '$log',
        function ($log) {
            return {
                scope: {
                    jobFeedback: '=',
                },
                restrict: 'E',
                replace: true,
                templateUrl,
                link: function ($scope) {
                    $scope.feedback = {};
                    function setMessages(msgs) {
                        $scope.feedback.messages = { info: [], warnings: [], errors: [] };
                        msgs.forEach(function (msg) {
                            let msgType = null;
                            switch (msg.messageLevel) {
                                case 'INFO':
                                    msgType = 'info';
                                    break;
                                case 'WARNING':
                                    msgType = 'warnings';
                                    break;
                                case 'ERROR':
                                    msgType = 'errors';
                                    break;
                                default:
                                    break;
                            }
                            if (!msgType) {
                                $log.error(
                                    'Unsupported message level in job feedback message , ',
                                    msg.messageLevel
                                );
                            } else {
                                $scope.feedback.messages[msgType].push(msg);
                            }
                        });
                    }
                    setMessages([]);
                    $scope.$watch('jobFeedback', function () {
                        if ($scope.jobFeedback) {
                            setMessages($scope.jobFeedback);
                        }
                    });
                },
            };
        },
    ])

    .directive('jobFeedbackMessage', function () {
        return {
            scope: {
                message: '=',
            },
            restrict: 'E',
            replace: true,
            templateUrl: jobFeedbackMessageTemplateUrl,
            link: function ($scope) {
                $scope.jobStr = $scope.message.isBackingJob ? 'Backing job' : 'Job';
            },
        };
    });
