angular.module('chartbuilderUtil').factory('regExStyles', [
    function () {
        const availableRegExes = {
            graphite: {
                delimiter: '.',
            },
            newrelic: {
                delimiter: '/',
            },
        };

        function getDelimiter(regExStyle) {
            if (availableRegExes[regExStyle]) {
                return availableRegExes[regExStyle].delimiter;
            } else {
                return null;
            }
        }

        return {
            getDelimiter: getDelimiter,
        };
    },
]);
