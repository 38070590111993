import templateUrl from './sections.tpl.html';

angular.module('signalview.heatmap').directive('navigatorSections', [
    '$log',
    '$timeout',
    'discoveryDashboardUtils',
    function ($log, $timeout, discoveryDashboardUtils) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
                stats: '=?',
                highlightedHosts: '=?',
                hosts: '=',
                mode: '=',
            },
            templateUrl,
            link: function ($scope) {
                let heatmap = $scope.heatmap;
                $scope.getFilterOverrides = function () {
                    return lastDiscoveryFilters.map(function (f) {
                        let property = f[0];
                        let NOT = false;
                        if (property.length && property.charAt(0) === '!') {
                            property = property.substring(1);
                            NOT = true;
                        }

                        return {
                            NOT,
                            property,
                            propertyValue: f[1],
                        };
                    });
                };

                $scope.serviceTabs = [];
                $scope.nonServiceTabs = [];
                $scope.availableTabs = [];
                $scope.selectedTab = null;
                $scope.discoveryDashboardData = null;
                $scope.updateTitle = function () {};

                $scope.config = {
                    preferredTab: null,
                    heatmap: heatmap,
                    highlightedHosts: [],
                };

                const INSIGHTS_TAB = {
                    id: 'Insights',
                    name: 'Insights',
                    isBeta: true,
                    trackingName: 'insights',
                };

                const HOST_PROCESSES_TAB = {
                    id: 'Processes',
                    name: 'Processes',
                    trackingName: 'processes',
                };

                const HOST_PROPERTIES_TAB = {
                    id: 'Properties',
                    name: 'Properties',
                    trackingName: 'properties',
                };

                const HOST_METRICS_TAB = {
                    id: 'Metrics',
                    name: 'Metric List',
                    trackingName: 'metric-list',
                };

                const HOST_LIST_TAB = {
                    id: 'List',
                    name: 'List',
                    trackingName: 'host-list',
                };

                const ALERTS_TAB = {
                    id: 'Alerts',
                    name: 'Alerts',
                    trackingName: 'alerts',
                };

                if (!heatmap.tab() && $scope.mode.type === 'elemental') {
                    $scope.selectedTab = HOST_LIST_TAB;
                }

                $scope.$watch('heatmap', function (newHeatmap) {
                    heatmap = newHeatmap;
                    $scope.config.heatmap = heatmap;
                });

                $scope.$watch('config.highlightedHosts', function (hosts) {
                    $scope.highlightedHosts = hosts;
                });

                function setDimension() {
                    const selected = heatmap.selection();

                    if (!selected) {
                        $scope.hostName = null;
                    } else if (selected.host) {
                        $scope.hostName = selected.host;
                    } else {
                        $scope.hostName = null;
                    }
                }

                $scope.getChartLink = function (chart) {
                    return discoveryDashboardUtils.createChartLinkFunction(
                        heatmap.getDashboardFilters(),
                        $scope.selectedTab.discoveryDashboard
                    )(chart);
                };

                let lastDiscoveryFilters = [];
                let lastDiscoveryDashboardId = null;

                function selectDefaultTab() {
                    $log.debug('Selecting default tab');

                    let tab;
                    if ($scope.config.preferredTab) {
                        tab = $scope.availableTabs.filter(function (t) {
                            return t.id === $scope.config.preferredTab;
                        })[0];
                    }

                    if (!tab) {
                        // default to system metrics for selected host or if there's no
                        // hosts list, otherwise the hosts list
                        tab = $scope.availableTabs.filter(function (t) {
                            return t.name === 'Host List';
                        })[0];

                        const selected = heatmap.selection();
                        if (selected || !tab) {
                            tab = $scope.availableTabs.filter(function (t) {
                                return t.name === 'System Metrics';
                            })[0];
                        }
                        // catch-all
                        if (!tab) {
                            tab = $scope.availableTabs[0];
                        }
                    }

                    $scope.selectTab(tab.id);
                }

                $scope.selectTab = function (tabId) {
                    if (
                        !$scope.availableTabs ||
                        ($scope.selectedTab && $scope.selectedTab.id === tabId)
                    ) {
                        return;
                    }

                    $scope.$emit('tab changed');

                    $log.debug('Selecting a new tab', tabId);

                    $scope.selectedTab = null;
                    $scope.availableTabs.some(function (tab) {
                        if (tab.id === tabId) {
                            $scope.selectedTab = tab;
                            return true;
                        }
                    });

                    if (!$scope.selectedTab) {
                        $log.warn('NO SELECTED TAB!');
                        selectDefaultTab();
                        return;
                    }

                    if ($scope.selectedTab.id) {
                        heatmap.tab($scope.selectedTab.id);
                    }

                    if (!$scope.selectedTab.discoveryDashboard) {
                        lastDiscoveryFilters = [];
                        lastDiscoveryDashboardId = null;
                    }

                    updateDashboardData();
                };

                // retrieves a system dashboard that matches a specific name
                function getSystemDashboardByName(dashboards, desired) {
                    dashboards = dashboards.filter(function (dashboard) {
                        const name = dashboard.name;
                        return name.toLowerCase() === desired.toLowerCase();
                    });
                    return dashboards[0];
                }

                function getSystemDashboard(dashboards) {
                    const mode = heatmap.mode();

                    if (!mode.systemDashboardPrefix) return null;

                    return dashboards
                        .filter(function (dashboard) {
                            const name = dashboard.name;
                            return (
                                name
                                    .toLowerCase()
                                    .indexOf(mode.systemDashboardPrefix.toLowerCase()) === 0
                            );
                        })
                        .sort(function (a, b) {
                            return b.lastUpdated - a.lastUpdated;
                        })[0];
                }

                function updateAvailableTabs(dashboards) {
                    $log.debug('Updating available tabs');
                    const selected = heatmap.selection();
                    heatmap.mode();
                    const singleHostView = selected && selected.sf_key;

                    let systemDashboard;
                    let discardDashboard;
                    if (singleHostView && $scope.mode.singleHostSystemDashboardName) {
                        // pick a specific dashboard for single host view
                        systemDashboard = getSystemDashboardByName(
                            dashboards,
                            $scope.mode.singleHostSystemDashboardName
                        );
                        // discard the multihost dashboard
                        if ($scope.mode.systemDashboardName) {
                            discardDashboard = getSystemDashboardByName(
                                dashboards,
                                $scope.mode.systemDashboardName
                            );
                        }
                    } else if ($scope.mode.systemDashboardName) {
                        // pick a specific dashboard for unselected host view
                        systemDashboard = getSystemDashboardByName(
                            dashboards,
                            $scope.mode.systemDashboardName
                        );
                        // discard the singlehost dashboard
                        if ($scope.mode.singleHostSystemDashboardName) {
                            discardDashboard = getSystemDashboardByName(
                                dashboards,
                                $scope.mode.singleHostSystemDashboardName
                            );
                        }
                    } else {
                        // pick first one, that would be the one with latest ts
                        systemDashboard = getSystemDashboard(dashboards);
                    }

                    // remove the system metric dashboard
                    if (systemDashboard) {
                        dashboards = dashboards.filter(function (dashboard) {
                            return dashboard.id !== systemDashboard.id;
                        });
                    }

                    // discard targeted dashboards
                    if (discardDashboard) {
                        dashboards = dashboards.filter(function (dashboard) {
                            return dashboard.id !== discardDashboard.id;
                        });
                    }

                    if (!singleHostView && $scope.mode.type === 'elemental') {
                        // don't show other aggregated dashboards
                        dashboards = [];
                    }

                    const nonServiceTabs = [];
                    const serviceTabs = [];

                    // Ordering of tabs
                    // aggregated view: host list, alerts, system metrics
                    // detail host view: alerts, system metrics, processes, properties
                    if (!singleHostView && $scope.mode.type === 'elemental') {
                        nonServiceTabs.push(HOST_LIST_TAB);
                    }

                    nonServiceTabs.push(ALERTS_TAB);

                    if (systemDashboard) {
                        nonServiceTabs.push({
                            id: systemDashboard.id,
                            name: 'System Metrics',
                            discoveryDashboard: systemDashboard,
                            trackingName: 'system-metrics',
                        });
                    }

                    if (singleHostView) {
                        if (
                            heatmap.mode().id === 'collectd hosts' ||
                            heatmap.mode().id === 'telegraf hosts' ||
                            heatmap.mode().id === 'telegraf hosts windows'
                        ) {
                            nonServiceTabs.push(HOST_PROCESSES_TAB);
                        }

                        nonServiceTabs.push(HOST_PROPERTIES_TAB);
                        if (heatmap.mode().idProperties && heatmap.mode().idProperties.length) {
                            nonServiceTabs.push(HOST_METRICS_TAB);
                        }
                    }

                    // If there are less than 4 metrics to compare, the insights tab will
                    // not be useful for comparisons
                    if ($scope.mode.type === 'elemental' && $scope.mode.metrics.length > 3) {
                        nonServiceTabs.push(INSIGHTS_TAB);
                    }

                    dashboards.forEach(function (dashboard) {
                        serviceTabs.push({
                            id: dashboard.id,
                            name: dashboard.name,
                            discoveryDashboard: dashboard,
                        });
                    });

                    $scope.serviceTabs = serviceTabs;
                    $scope.nonServiceTabs = nonServiceTabs;
                    $scope.availableTabs = serviceTabs.concat(nonServiceTabs);

                    if ($scope.selectedTab && !isValidTabId($scope.selectedTab.id)) {
                        selectDefaultTab();
                    } else if (isValidTabId(heatmap.tab())) {
                        $scope.selectTab(heatmap.tab());
                    } else if (!$scope.selectedTab) {
                        selectDefaultTab();
                    }
                }

                function isValidTabId(tabId) {
                    return $scope.availableTabs.some(function (tab) {
                        return tab.id === tabId;
                    });
                }

                function updateDashboardData() {
                    const discoveryDashboard = $scope.selectedTab.discoveryDashboard;

                    if (!discoveryDashboard) {
                        $scope.discoveryDashboardData = null;
                        return;
                    }

                    const newDiscoveryFilters = heatmap.getDashboardFilters();

                    if (
                        discoveryDashboard.id === lastDiscoveryDashboardId &&
                        angular.equals(lastDiscoveryFilters, newDiscoveryFilters)
                    ) {
                        return;
                    }

                    lastDiscoveryDashboardId = discoveryDashboard.id;
                    // use lastDF
                    lastDiscoveryFilters = newDiscoveryFilters;

                    $scope.discoveryDashboardData = null;
                    discoveryDashboardUtils
                        .getDashboardData(discoveryDashboard, newDiscoveryFilters)
                        .then(function (data) {
                            if (!$scope.selectedTab.discoveryDashboard) return;
                            $scope.discoveryDashboardData = data;
                        });
                }

                function getSelectedGroupKeyValue(group, mode) {
                    const key =
                        group.groupKey || (mode.requiredProperties && mode.requiredProperties[0]);
                    const value = (group.depth === 0 ? 'All ' : '') + (group.name || group.id);
                    return key && group.parent && !key.startsWith('sf_')
                        ? { key, value }
                        : { value };
                }

                function updateSelectionBreadCrumbs(selection, mode) {
                    if (!selection) {
                        return null;
                    }

                    const breadCrumbs = [];
                    do {
                        breadCrumbs.unshift(getSelectedGroupKeyValue(selection, mode));
                        selection = selection.parent;
                    } while (selection);
                    return breadCrumbs;
                }

                // debounced to prevent storms of requests when updating discovery
                // dashboards
                const updateDiscoveryDashboards = _.debounce(function () {
                    const mode = heatmap.mode();

                    // If mode hasn't been set yet, try again in 2 seconds
                    if (!mode || !mode.id) {
                        return $timeout(updateDiscoveryDashboards, 2000);
                    }
                    setDimension();

                    const selection = heatmap.selection();
                    const groupBySelection = heatmap.groupBySelection();
                    const groupBy = heatmap.groupBy();
                    const alwaysGroupBy = heatmap.mode().alwaysGroupBy;
                    const contextFilters = heatmap.getDashboardFilters();
                    let dashboardsPromise;

                    if (
                        alwaysGroupBy &&
                        groupBySelection &&
                        groupBySelection.length === groupBy.length + 1
                    ) {
                        // Group dashboards
                        groupBy.push(alwaysGroupBy);
                        const properties = {};
                        for (let i = 0; i < groupBy.length; i++) {
                            properties[groupBy[i]] = groupBySelection[i];
                        }
                        dashboardsPromise = discoveryDashboardUtils.getDashboardsFromProperties(
                            properties,
                            contextFilters
                        );
                    } else if (
                        !selection ||
                        selection.children ||
                        selection.id === heatmap.mode().displayName
                    ) {
                        // Overview dashboards
                        dashboardsPromise = discoveryDashboardUtils.getDashboardsFromMode(
                            mode,
                            mode.mtsQuery
                        );
                    } else {
                        // Host dashboards
                        dashboardsPromise = discoveryDashboardUtils.getDashboardsFromObject(
                            selection,
                            contextFilters
                        );
                    }

                    $scope.breadCrumbs = updateSelectionBreadCrumbs(selection, mode);

                    return dashboardsPromise
                        .then((dashboards) => {
                            // Make sure the dashboard has a groupId so the hierarchy can be found later on.
                            const dashboardsWithGroupId = dashboards.filter(
                                (dashboard) => !!dashboard.groupId
                            );
                            updateAvailableTabs(dashboardsWithGroupId);
                            updateDashboardData();
                        })
                        .catch((err) => {
                            updateAvailableTabs([]);
                            $log.error('Unable to update discovery dashboards', err);
                        });
                }, 100);

                heatmap.on('selection updated', updateDiscoveryDashboards);
                heatmap.on('groupBySelection updated', updateDiscoveryDashboards);
                heatmap.on('filterBy updated', updateDiscoveryDashboards);
                updateDiscoveryDashboards();
            },
        };
    },
]);
