export default [
    'ACCOUNT_SOURCES',
    '$http',
    'API_URL',
    'moment',
    function (ACCOUNT_SOURCES, $http, API_URL, moment) {
        // TODO : in phase 2 we expect the org to not require special changes like this to understand the org is from
        // the aws marketplace
        function getDpm() {
            return $http
                .get(API_URL + '/v2/organization/_/get-quota')
                .then((response) => response.data);
        }

        function createMockAccountForDpm(org, dpmData) {
            const dpm = dpmData.dpm;
            const dpmBurstPercent = dpmData.dpmBurstPercent;
            return {
                autoPay: true,
                accountSourceProduct: org.accountSourceProduct,
                accountEntitlements: org.entitlements,
                accountSourceId: org.accountSourceId,
                entitlements: [],
                quotas: {
                    DPM: dpm,
                    DPM_BURST: dpm,
                    DPM_BURST_PERIOD: 0,
                    DPM_BURST_PERCENT: dpmBurstPercent,
                    DPM_BURST_PERIOD_UNIT: 'Hour',
                    ACTIVE_MTS: dpm / 2, // arbitrarily chosen
                    EPM: 1000,
                },
                orgId: org.id,
                autoRenew: true,
                accountSource: ACCOUNT_SOURCES.AWS_MARKET_PLACE,
                orgName: org.organizationName,
                accountType: 'PAYING',
                name: org.organizationName,
                status: 'Active',
                lastActiveRatePlan: {
                    billCycleDay: moment(org.created).date(),
                },
            };
        }

        return function createMockAccount(org) {
            return getDpm().then(function (data) {
                return createMockAccountForDpm(org, data);
            });
        };
    },
];
