import templateUrl from './analyzerSidebarWrapper.tpl.html';

export default {
    templateUrl,
    bindings: {
        title: '<',
    },
    controller: [
        'URL_PARAMETER_CONSTANTS',
        'urlOverridesService',
        'routeParameterService',
        'kubeDataService',
        'dashboardVariablesService',
        function (
            URL_PARAMETER_CONSTANTS,
            urlOverridesService,
            routeParameterService,
            kubeDataService,
            dashboardVariablesService
        ) {
            let routeWatcher;

            const $ctrl = this;
            $ctrl.$onInit = $onInit;
            $ctrl.$onDestroy = $onDestroy;

            function $onInit() {
                bindEvents();
                updateAnalyzer();
            }

            function bindEvents() {
                routeWatcher = routeParameterService.registerRouteWatchGroup(
                    [
                        URL_PARAMETER_CONSTANTS.sourceOverride,
                        URL_PARAMETER_CONSTANTS.variablesOverride,
                        URL_PARAMETER_CONSTANTS.infoSidebarAnalyzerQuery,
                    ],
                    updateAnalyzer
                );
            }

            function $onDestroy() {
                if (routeWatcher) {
                    routeWatcher();
                }
            }

            function updateAnalyzer() {
                $ctrl.filterBy = urlOverridesService.getSourceFilterOverrideList() || [];
                $ctrl.queryObjects = kubeDataService.getAnalyzerContexts();
                $ctrl.variables = dashboardVariablesService.getVariablesOverrideAsModel();
            }
        },
    ],
};
