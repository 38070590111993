export const chartEventDataSource = [
    '$q',
    'eventService',
    'bucketing',
    'timepickerUtils',
    'alertTypeService',
    '$log',
    function ($q, eventService, bucketing, timepickerUtils, alertTypeService, $log) {
        const GLOBAL_EVENT_QUERY_NAME = '___GLOBAL';
        function hasEventSignalFlow(args) {
            if (
                args.programToQuery &&
                args.programToQuery.program &&
                args.programToQuery.program.programText
            ) {
                const programText = args.programToQuery.program.programText;
                //this is a hack for practical purposes.  there is no reason to stream or parse if the text doesnt have these
                return !!programText.match(/alerts|events/);
            }
            return false;
        }

        function isQueryable(args) {
            return args.query || hasEventSignalFlow(args);
        }
        // this module attempts to merge both event histogram streaming from v1 and event streaming
        // from v2 into a normalized form for usage by chart display.
        return {
            initialize: function initialize(version, args) {
                let histogram = [];
                let rawEvents = [];
                let eventStreamer = null;
                const newDataCallback = args.onNewData || angular.noop;
                const resolution = Math.floor(args.res / 1000);
                const eventBuckets = {};
                const bucketer = bucketing(args.start, args.end, args.res);

                const resolutionMs = resolution * 1000;
                let streaming;
                if (!version && isQueryable(args)) {
                    eventStreamer = eventService.streamHistogram({
                        query: args.query,
                        aggregation: ['sf_eventType', 'sf_priority', 'is'],
                        programToQuery: args.programToQuery || null,
                        callback: function (resp) {
                            const result = resp[GLOBAL_EVENT_QUERY_NAME];
                            if (result.length || !histogram.length) {
                                const perLabelHistogramOffset = {};
                                // new result might have already existing data, needs to remove from old result before merging
                                const newTimestamp = {};
                                result.forEach(function (r) {
                                    newTimestamp[r.timeStamp] = true;
                                    r._composition = {};
                                    angular.forEach(resp, function (hist, label) {
                                        if (label !== GLOBAL_EVENT_QUERY_NAME) {
                                            const index = perLabelHistogramOffset[label] || 0;
                                            if (
                                                hist[index] &&
                                                hist[index].timeStamp === r.timeStamp
                                            ) {
                                                r._composition[label] = hist[index];
                                                perLabelHistogramOffset[label] = index + 1;
                                            }
                                        }
                                    });
                                });

                                histogram = histogram
                                    .filter(function (row) {
                                        return !newTimestamp[row.timeStamp];
                                    })
                                    .concat(result);

                                newDataCallback();
                            }
                        },
                        start: args.start,
                        end: args.end,
                        interval: resolution,
                    });
                    streaming = true;
                } else {
                    streaming = false;
                }

                const timestampList = [];
                function addEventHistogram(timestamp, is, priority, count) {
                    timestampList.push(timestamp);
                    const bucketKey = bucketer.bucket(timestamp);
                    let bucket = eventBuckets[bucketKey];
                    const eventState = {
                        aggregations: null,
                        count: count,
                        name: is,
                    };

                    // NOTE: Assumes that all events being added are alerts
                    if (!bucket) {
                        // Create new bucket
                        bucket = {
                            aggregations: [
                                {
                                    aggregations: [
                                        {
                                            aggregations: [eventState],
                                            count: count,
                                            name: priority,
                                        },
                                    ],
                                    count: count,
                                    name: 'ALERT',
                                },
                            ],
                            count: count,
                            name: 'agg_sf_eventType',
                            timeStamp: bucketKey,
                            unmatched: 0,
                        };
                        const compositionAggregation = angular.copy(bucket);
                        bucket._composition = {
                            _SYNTHETIC: compositionAggregation,
                        };
                        eventBuckets[bucketKey] = bucket;
                        histogram.push(bucket);
                    } else {
                        // Use existing bucket
                        bucket.count = bucket.count + count;
                        // Assuming only one aggregation on category, for alerts

                        // try add it to existing priority. Use some so that we escape once found.
                        const existsExistingPriority = bucket.aggregations[0].aggregations.some(
                            function (agg) {
                                if (priority === agg.name) {
                                    agg.count = agg.count + count;
                                    for (let i = 0; i < agg.aggregations.length; i++) {
                                        const state = agg.aggregations[i];
                                        if (is === state.name) {
                                            // There is an existing combination of priority and state
                                            state.count = state.count + count;
                                            return true;
                                        }
                                    }
                                    // Existing priority but not state
                                    agg.aggregations.push(eventState);
                                    return true;
                                }
                            }
                        );
                        if (!existsExistingPriority) {
                            // There is no existing priority
                            bucket.aggregations[0].aggregations.push({
                                aggregations: [eventState],
                                count: count,
                                name: priority,
                            });
                        }

                        // for synthetic buckets, we need to match the parent(global) to the breakdown(_SYNTHETIC)
                        const compositionAggregation = angular.copy(bucket);
                        delete compositionAggregation._composition;
                        bucket._composition._SYNTHETIC = compositionAggregation;
                    }
                }

                function addEvent(evt) {
                    eventService.processV2StreamedEvent(
                        evt,
                        args.getETSMetadata()[evt.tsId],
                        args.getModel(),
                        args.getRules()
                    );
                    addEventHistogram(
                        evt.timestampMs,
                        evt.properties.is,
                        evt.metadata.sf_priority,
                        1
                    );
                    rawEvents.push(evt);
                }

                function getEventNearTime(begin, end, orderBy) {
                    return eventService.v2SegmentedSearch({
                        query: args.query || '',
                        programToQuery: args.programToQuery || null,
                        startTime: begin,
                        endTime: end,
                        limit: 1,
                        orderBy: orderBy,
                    });
                }

                function findClosestTimeStamp(time, timestampList) {
                    let closestTime;
                    let distant = Infinity;
                    // assume things to be in order
                    timestampList.forEach(function (timestamp) {
                        const currentDistant = Math.abs(timestamp - time);
                        if (currentDistant <= distant) {
                            closestTime = timestamp;
                            distant = currentDistant;
                        }
                    });
                    if (!closestTime) {
                        $log.error('could not find closest time');
                        // fall back to time (so sad), this shouldn't happen.
                        closestTime = time;
                    }
                    return closestTime;
                }

                function getClosestEventTime(time) {
                    if (version === 3 || version === 2) {
                        return $q.when(findClosestTimeStamp(time, timestampList));
                    } else {
                        // version 1
                        // fetch event both before and after time to find closest one
                        return $q
                            .all([
                                getEventNearTime(time - resolutionMs, time, '-sf_timestamp'),
                                getEventNearTime(time, time + resolutionMs, 'sf_timestamp'),
                            ])
                            .then(function (result) {
                                const timestampList = result[0]
                                    .concat(result[1])
                                    .map(function (event) {
                                        return event.timestamp;
                                    });
                                return findClosestTimeStamp(time, timestampList);
                            });
                    }
                }

                return {
                    addEvent: addEvent,
                    addEventHistogram: addEventHistogram,
                    getClosestEventTime: getClosestEventTime,
                    cancel: function () {
                        if (eventStreamer) {
                            eventStreamer.cancel();
                        }
                    },
                    expire: function (ts) {
                        if (version === 3) {
                            histogram = histogram.filter(function (bucket) {
                                return bucket.timeStamp > ts;
                            });
                        } else if (version === 2) {
                            //FIXME : this is obviously not very efficient.  need to dynamically construct and expire the histogram.
                            histogram = histogram.filter(function (bucket) {
                                return bucket.timeStamp > ts;
                            });
                            rawEvents = rawEvents.filter(function (evt) {
                                return evt.timestampMs > ts;
                            });
                        } else {
                            histogram = histogram.filter(function (bucket) {
                                return bucket.timeStamp > ts;
                            });
                        }
                    },
                    getHistogram: function () {
                        return histogram;
                    },
                    getEvents: function getEvents(start, end) {
                        if (version === 3) {
                            if (args.getEvents) {
                                return args.getEvents(start, end);
                            } else {
                                return $q.when([]);
                            }
                        } else if (version === 2) {
                            return $q.when(
                                rawEvents.filter(function (evt) {
                                    return evt.timestampMs >= start && evt.timestampMs <= end;
                                })
                            );
                        } else if (isQueryable(args)) {
                            const limit = 101;
                            let events;
                            const timestamps = {};
                            const durations = {};

                            // Start timestamp inclusive, end timestamp exclusive, which is
                            // how ES forms interval buckets in histograms
                            return eventService
                                .v2SegmentedSearch({
                                    query: args.query || '',
                                    startTime: start,
                                    endTime: end,
                                    limit: limit,
                                    programToQuery: args.programToQuery || null,
                                })
                                .then(function getIncidents(results) {
                                    events = results;
                                    // Get durations for incidents where both trigger and clear events
                                    // are already present
                                    for (let i = 0; i < events.length; i++) {
                                        const id = events[i].properties.incidentId;
                                        const ts = events[i].timestamp;
                                        if (timestamps[id]) {
                                            durations[id] = Math.abs(ts - timestamps[id]);
                                            delete timestamps[id];
                                        } else {
                                            timestamps[id] = ts;
                                        }
                                        // Default triggered events to ongoing status
                                        if (
                                            !alertTypeService.isClearingEvent(
                                                events[i].properties.is
                                            )
                                        ) {
                                            events[i].duration = 'Ongoing';
                                        }
                                    }

                                    if (Object.keys(timestamps).length) {
                                        // Try finding other halves for the remaining incidents
                                        const remainingIds = Object.keys(timestamps).map(function (
                                            id
                                        ) {
                                            return '"' + id + '"';
                                        });
                                        return eventService.search({
                                            query: 'incidentId:(' + remainingIds.join(' OR ') + ')',
                                            limit: limit * 2, // At most two events per incident
                                        });
                                    } else {
                                        return $q.when([]);
                                    }
                                })
                                .then(function getDurations(moreEvents) {
                                    for (let i = 0; i < moreEvents.length; i++) {
                                        const id = moreEvents[i].properties.incidentId;
                                        const ts = moreEvents[i].timestamp;
                                        if (timestamps[id] && ts !== timestamps[id]) {
                                            durations[id] = Math.abs(ts - timestamps[id]);
                                            delete timestamps[id];
                                        }
                                    }

                                    // Add duration values to the events
                                    for (let j = 0; j < events.length; j++) {
                                        const evt = events[j];
                                        const ms = durations[evt.properties.incidentId];
                                        if (ms) {
                                            evt.duration = timepickerUtils.msToDisplayValue(ms);
                                        }
                                    }
                                    return events;
                                });
                        } else {
                            return $q.when([]);
                        }
                    },
                    resolution: resolutionMs,
                    currentQuery: args.query,
                    streaming,
                };
            },
        };
    },
];
