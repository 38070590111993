export default function () {
    let orgId = null;
    this.unscopeOnce = false;

    this.scopedOperations = {
        get: true,
        getList: true,
    };

    this.unscopedPaths = {
        metricgroup: true, // Avoid extra query params, which triggers MTS search
        user: true,
        userpreference: true,
        session: true,
        organization: true,
        orguser: true,
        orgmembership: true,
        role: true,
        builtin: true,
    };

    this.unscopedPost = {
        event: true,
    };

    this.scopeGet = function (element, operation, route, url, headers, params, httpConfig) {
        if (
            angular.isDefined(this.scopedOperations[operation]) &&
            orgId !== null &&
            this.unscopeOnce === false
        ) {
            if (!angular.isDefined(this.unscopedPaths[route])) {
                let newQuery = '';
                if (angular.isDefined(params.query) && params.query !== '') {
                    newQuery = '(' + params.query + ') AND ';
                }
                newQuery += '(sf_organizationID:' + orgId + ')';
                params.query = newQuery;
            }
        }
        this.unscopeOnce = false;
        return {
            element: element,
            params: params,
            headers: headers,
            httpConfig: httpConfig,
        };
    };

    this.scopePost = function (element, operation, route, url, headers, params, httpConfig) {
        if (
            this.unscopedPost[route] !== true &&
            orgId !== null &&
            (!angular.isDefined(element.sf_organizationID) || element.sf_organizationID === null)
        ) {
            element.sf_organizationID = orgId;
        }
        return {
            element: element,
            params: params,
            headers: headers,
            httpConfig: httpConfig,
        };
    };

    this.scopeRequest = function (element, operation, route, url, headers, params, httpConfig) {
        return operation === 'post'
            ? this.scopePost(element, operation, route, url, headers, params, httpConfig)
            : this.scopeGet(element, operation, route, url, headers, params, httpConfig);
    };

    const orgScope = this;

    this.$get = [
        '$rootScope',
        'currentUser',
        function ($rootScope, currentUser) {
            $rootScope.$on('migrated services initialized', function () {
                currentUser.orgId().then(function (newOrgId) {
                    orgId = newOrgId;
                });
            });

            $rootScope.$on('current organization changed', function (event, org) {
                orgId = org.id;
            });

            return orgScope;
        },
    ];
}
