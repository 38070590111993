import React, { FC, ReactNode } from 'react';
import { Route, Switch, useInitializeRoute } from './ReactRouteUtils';
import { ngRoute } from './ngRoute';
import { Ng2React } from '../../common/util/Ng2React';
import { heatmap } from '../../legacy/app/heatmap/heatmap';
import { AngularInjector } from '../../common/AngularUtils';

export const NavigatorRoutes: FC = () => {
    const routeUtilsBootStrap = useInitializeRoute();

    return (
        <Switch>
            <Route
                exact
                path="/navigator"
                title="Infrastructure"
                render={(routeProps): ReactNode => {
                    const currentUser = AngularInjector.instantiate('currentUser');
                    const navigatorModes = AngularInjector.instantiate('navigatorModes');
                    routeUtilsBootStrap(routeProps.match.params)
                        .then(() =>
                            Promise.all([
                                currentUser.orgId(),
                                currentUser.preferences(),
                                navigatorModes.getModes(),
                            ])
                        )
                        .then(([orgId, preferences, modes]: any) => {
                            if (modes.length) {
                                if (preferences.sf_navigatorMode) {
                                    ngRoute.history?.replace(
                                        `${ngRoute.pathname}/${orgId}/${preferences.sf_navigatorMode}`
                                    );
                                } else {
                                    ngRoute.history?.replace(
                                        `${ngRoute.pathname}/${orgId}/${modes[0].id}`
                                    );
                                }
                            } else {
                                ngRoute.history?.replace(`${ngRoute.pathname}/${orgId}`);
                            }
                        });
                    return undefined;
                }}
            />

            <Route
                exact
                path="/navigator/:orgID"
                title="Infrastructure"
                resolves={{
                    orgId: ['currentUser', (currentUser): string => currentUser.orgId()],
                    userPreferences: [
                        'currentUser',
                        (currentUser): string => currentUser.preferences(),
                    ],
                    heatmap: [
                        'currentUser',
                        'navigatorModes',
                        (currentUser, navigatorModes): Promise<any> => {
                            return routeUtilsBootStrap(ngRoute.params)
                                .then(() =>
                                    Promise.all([
                                        currentUser.preferences(),
                                        navigatorModes.getModes(),
                                    ])
                                )
                                .then(([preferences, modes]: any) => {
                                    if (!modes.length) {
                                        return null;
                                    }

                                    let modeId = preferences.sf_navigatorMode;
                                    if (modeId) {
                                        const modeIsValid = modes.some(
                                            (mode: any) => mode.id === preferences.sf_navigatorMode
                                        );

                                        if (!modeIsValid) {
                                            modeId = null;
                                        }
                                    }

                                    if (!modeId) {
                                        modeId = modes[0].id;
                                    }

                                    ngRoute.history?.replace(`${ngRoute.pathname}/${modeId}`);

                                    // Used to continue loading indicator, never fulfilled since we're redirecting user
                                    return new Promise(() => {});
                                });
                        },
                    ],
                }}
            >
                <Ng2React name="heatmap" definition={heatmap} />
            </Route>

            <Route
                exact
                path="/navigator/:orgID/:modeID"
                title="Infrastructure"
                resolves={{
                    orgId: ['currentUser', (currentUser): string => currentUser.orgId()],
                    userPreferences: [
                        'currentUser',
                        (currentUser): string => currentUser.preferences(),
                    ],
                    heatmap: [
                        'createHeatmap',
                        'currentUser',
                        'navigatorModes',
                        'INACTIVE_HOST_DURATIONS',
                        function (
                            createHeatmap,
                            currentUser,
                            navigatorModes,
                            INACTIVE_HOST_DURATIONS
                        ): Promise<any> {
                            const modeId = ngRoute.params.modeID;
                            const orgId = ngRoute.params.orgID;

                            const heatmap = createHeatmap();

                            return routeUtilsBootStrap(ngRoute.params)
                                .then(() =>
                                    Promise.all([
                                        currentUser.preferences(),
                                        navigatorModes.getModes(),
                                    ])
                                )
                                .then(function ([preferences, modes]: any) {
                                    heatmap.modes(modes);
                                    let hideDeadHosts = preferences.sf_navigatorHideDeadHosts;
                                    if (hideDeadHosts === undefined) {
                                        hideDeadHosts = true;
                                    }

                                    heatmap.hideDeadHosts(hideDeadHosts);

                                    const inactiveHostDuration =
                                        parseInt(
                                            preferences.sf_navigatorInactiveHostDuration,
                                            10
                                        ) || INACTIVE_HOST_DURATIONS.AUTO;
                                    heatmap.inactiveHostDuration(inactiveHostDuration);

                                    const mode = navigatorModes.get(modeId);
                                    if (!mode) {
                                        // If an invalid mode id is given, redirect to a valid one
                                        if (modes.length) {
                                            ngRoute.history?.replace(
                                                `/navigator/${orgId}/${modes[0].id}`
                                            );
                                        } else {
                                            ngRoute.history?.replace(`/navigator/${orgId}`);
                                        }

                                        return new Promise(() => {});
                                    }

                                    heatmap.mode(mode);
                                    return heatmap;
                                });
                        },
                    ],
                }}
            >
                <Ng2React name="heatmap" definition={heatmap} />
            </Route>
        </Switch>
    );
};
