import templateUrl from './snapshotHeader.tpl.html';

angular.module('signalview.snapshotSharing').directive('snapshotHeader', [
    '$q',
    '$log',
    'currentUser',
    'dateService',
    'organizationService',
    function ($q, $log, currentUser, dateService, organizationService) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                type: '@',
                name: '=?',
                snapshot: '=?',
            },
            link: function ($scope) {
                $scope.setDontShow = function () {
                    $scope.showHeader = false;
                    return currentUser.preferences().then(function (prefs) {
                        currentUser.updatePreferences({
                            sf_id: prefs.sf_id,
                            sf_doNotShowDashboardBanner: true,
                        });
                    });
                };

                function getDontShow() {
                    if (
                        $scope.snapshot &&
                        $scope.snapshot.payload &&
                        !$scope.snapshot.payload.workspace
                    ) {
                        return $q.when(false);
                    } else {
                        return currentUser
                            .preferences()
                            .then(function (prefs) {
                                return (prefs || {}).sf_doNotShowDashboardBanner;
                            })
                            .catch(function (e) {
                                $log.error('Failed fetching preferences.', e);
                            });
                    }
                }

                function getUserName() {
                    let sender = '';
                    if ($scope.snapshot) {
                        sender =
                            ($scope.snapshot.payload || {}).originalSender ||
                            $scope.snapshot.sender;
                    }
                    if (sender) {
                        return organizationService
                            .searchMembersWithArbitraryQuery('sf_email:' + $scope.snapshot.sender)
                            .then(function (response) {
                                if (response.results.length) {
                                    const orgMember = response.results[0];
                                    $scope.user = orgMember.fullName || orgMember.email;
                                } else {
                                    $log.info('No such user.', $scope.snapshot.sender);
                                }
                            })
                            .catch(function (e) {
                                $log.error('No such user.', $scope.snapshot.sender, e);
                            });
                    } else {
                        return $q.when();
                    }
                }

                function getDisplayName() {
                    let parent;
                    if ($scope.snapshot && $scope.snapshot.payload) {
                        parent = $scope.snapshot.payload.parentName;
                        $scope.daysTo = dateService.daysTo($scope.snapshot.expiryMs);
                    }
                    $scope.displayName =
                        (parent ? parent + ' > ' : '') + ($scope.name || '') ||
                        'Untitled ' + $scope.type.charAt(0).toUpperCase() + $scope.type.slice(1);
                }

                getDontShow().then(function (dontShow) {
                    if (!dontShow) {
                        getUserName().then(function () {
                            getDisplayName();
                            $scope.showHeader = true;
                        });
                    }
                });
            },
        };
    },
]);
