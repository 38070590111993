export const applyAlertListState = [
    function () {
        /**
         * Alert list can be updated. We want to carry over the expand state
         */
        function applyAlertListState(newList, existing) {
            let i, item;
            const state = {};
            // gather current states
            for (i = 0; i < existing.length; i++) {
                item = existing[i];
                if (item.expanded) {
                    state[item.track] = item.expanded;
                }
            }

            // apply those states to new list
            for (i = 0; i < newList.length; i++) {
                item = newList[i];
                if (state[item.track]) {
                    item.expanded = state[item.track];
                }
            }

            // with the new state, expand show/hide to the child accordingly.
            for (i = 0; i < newList.length; i++) {
                item = newList[i];
                if (item.expanded) {
                    item.expanded = state[item.track];

                    // not nesting assuming that parent always show up before child.
                    for (let j = 0; j < item.child.length; j++) {
                        item.child[j].show = item.expanded;
                    }
                }
            }
        }
        return function (newResult, oldResult) {
            if (oldResult) {
                applyAlertListState(newResult.result, oldResult.result);
            }
            return newResult;
        };
    },
];
