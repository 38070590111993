import sharingComposerTemplateUrl from './sharingComposer.tpl.html';

angular
    .module('signalview.sharing')

    /**
     * Service to launch sharing composer modal.
     */
    .service('sharingService', [
        'sfxModal',
        '$window',
        'shareableSnapshotService',
        'dashboardUtil',
        'dateService',
        'pageDisplayTitle',
        'chartVersionService',
        function (
            sfxModal,
            $window,
            shareableSnapshotService,
            dashboardUtil,
            dateService,
            pageDisplayTitle,
            chartVersionService
        ) {
            const ALLOWED_TYPES = {
                slack: true,
                office365: true,
                email: true,
            };

            /* service api */
            return {
                getShareableNotificationTypes,
                getSharedStr,
                processNotifications,
                shareChart,
                shareDashboard,
            };

            function getShareableNotificationTypes() {
                return Object.keys(ALLOWED_TYPES);
            }

            function getSharedStr(notifications) {
                const infoStr = [];
                if ((notifications.email || []).length) {
                    infoStr.push('email(s) ' + notifications.email.join(', '));
                }
                if ((notifications.slack || []).length) {
                    const slackChannels = notifications.slack
                        .map(function (s) {
                            return s.channel;
                        })
                        .join(', ');
                    infoStr.push('Slack channel(s) ' + slackChannels);
                }
                if ((notifications.office365 || []).length) {
                    const office365Groups = notifications.office365
                        .map(function (g) {
                            return g.credentialName;
                        })
                        .join(', ');
                    infoStr.push('Microsoft Teams channel(s) ' + office365Groups);
                }
                return 'Shared with ' + infoStr.join(' and ') + '!';
            }

            function processNotifications(notifications) {
                const processed = _.groupBy(
                    notifications.filter(function (n) {
                        return ALLOWED_TYPES[n.type];
                    }),
                    'type'
                );
                processed.email = (processed.email || []).map(function (n) {
                    return n.email;
                });
                return processed;
            }

            function shareDashboard(model, charts, page, snapshot) {
                model = copyAndNormalizeDashboard(model);
                composeModal(
                    'dashboard',
                    dashboardSnapshotGeneratorFactory(model, charts, page, snapshot)
                );
            }

            function shareChart(model, dashboard, snapshot) {
                dashboard = dashboard ? copyAndNormalizeDashboard(dashboard) : null;
                composeModal('chart', chartSnapshotGeneratorFactory(model, dashboard, snapshot));
            }

            /* private functions */

            function composeModal(type, generateShareableSnapshot) {
                const modalInstance = sfxModal.open({
                    templateUrl: sharingComposerTemplateUrl,
                    controller: 'SharingController',
                    size: 'md',
                    resolve: {
                        type: function () {
                            return type;
                        },
                        generateShareableSnapshot: function () {
                            return generateShareableSnapshot;
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                });
                return modalInstance.result;
            }

            function chartSnapshotGeneratorFactory(model, dashboard, snapshot) {
                const isV2 = chartVersionService.getVersion(model) === 2;
                const nameKey = isV2 ? 'name' : 'sf_chart';

                return function () {
                    return dashboardUtil
                        .getChartSnapshotPayload(model, dashboard)
                        .then(function (payload) {
                            const oldPayload = (snapshot || {}).payload || {};
                            const hasNewName =
                                oldPayload.chart &&
                                oldPayload.chart[nameKey] !== payload.chart[nameKey];
                            // keep original dashboard name only if user didn't change name of shared chart
                            if (!hasNewName) {
                                if (dashboard) {
                                    payload.parentName = dashboard.sf_dashboard;
                                } else if (oldPayload.parentName) {
                                    payload.parentName = oldPayload.parentName;
                                }
                            }
                            let sourceId = model.id || model.sf_id || (snapshot || {}).sourceId;
                            if (sourceId && sourceId.startsWith('SYNTH_')) {
                                sourceId = null;
                            }
                            return shareableSnapshotService.create(
                                shareableSnapshotService.types.Chart,
                                model[nameKey],
                                '', // no description
                                payload,
                                sourceId
                            );
                        })
                        .then(function (snapshot) {
                            const snapshotInfo = {};
                            snapshotInfo.snapshot = snapshot;
                            snapshotInfo.link = angular
                                .copy($window.location.href)
                                .replace($window.location.hash, '#/temp/chart/' + snapshot.id);
                            snapshotInfo.daysTo = dateService.daysTo(snapshot.expiryMs);

                            return snapshotInfo;
                        });
                };
            }

            function dashboardSnapshotGeneratorFactory(model, charts, page, snapshot) {
                return function () {
                    return dashboardUtil
                        .getDashboardSnapshotPayload(model, charts)
                        .then(function (payload) {
                            const oldPayload = (snapshot || {}).payload || {};
                            const hasNewName =
                                oldPayload.dashboard &&
                                oldPayload.dashboard.name !== payload.dashboard.name;
                            // keep original page name only if user didn't change name of shared dashboard
                            if (!hasNewName) {
                                if (page) {
                                    payload.parentName = pageDisplayTitle(page);
                                } else if (oldPayload.parentName) {
                                    payload.parentName = oldPayload.parentName;
                                }
                            }
                            return shareableSnapshotService.create(
                                shareableSnapshotService.types.Dashboard,
                                model.name,
                                '', // no description
                                payload,
                                model.id || (snapshot || {}).sourceId
                            );
                        })
                        .then(function (snapshot) {
                            const snapshotInfo = {};
                            snapshotInfo.snapshot = snapshot;
                            snapshotInfo.link = angular
                                .copy($window.location.href)
                                .replace($window.location.hash, '#/temp/dashboard/' + snapshot.id);
                            snapshotInfo.daysTo = dateService.daysTo(snapshot.expiryMs);

                            return snapshotInfo;
                        });
                };
            }

            function copyAndNormalizeDashboard(dashboard) {
                const model = angular.copy(dashboard);
                const isV2 = !!model.name;
                const email = model.email || model.sf_email;
                const name = model.name || model.sf_dashboard;

                if (email && name === email) {
                    if (isV2) {
                        model.name = email + "'s Workspace";
                    } else {
                        model.sf_dashboard = (model.sf_user || model.sf_email) + "'s Workspace";
                    }
                }
                return model;
            }
        },
    ]);
