import { IntegrationsPage } from './integrationsPage';

angular
    .module('signalview.orgIntegrations', [
        'signalboost',
        'signalview.appNotifications',
        'signalview.utils',
        'signalview.catalog',
        'sfx.data',
    ])
    .component('integrationsPage', IntegrationsPage)
    .constant('integrationsDocumentation', window.integrationsDocumentation || {})
    .constant('integrationsMeta', window.integrationsMeta || {})
    .constant('pluginMetrics', window.pluginMetrics || {});
