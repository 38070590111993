export default function getEventChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Workload Events',
        type: 'Event',
    };

    const publishLabelOptions = [
        {
            displayName: 'Workload Alerts',
            streamLabel: 'WORKLOAD_ALERTS',
            programText: `WORKLOAD_ALERTS = alerts(detector_name='*', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_namespace', '*') and (${filters})).publish(label='WORKLOAD_ALERTS')`,
        },
        {
            displayName: 'Workload Events',
            streamLabel: 'WORKLOAD_EVENTS',
            programText: `WORKLOAD_EVENTS = events(eventType='*', filter=filter('kubernetes_cluster', '*') and filter('kubernetes_namespace', '*') and (${filters})).publish(label='WORKLOAD_EVENTS')`,
        },
    ];

    return { chartOptions, publishLabelOptions };
}
