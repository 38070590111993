import { useState, useEffect } from 'react';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import PermissionsServiceFactory from '../../../legacy/common/data/apiv2/permissionsService';
import accessControlHelper from './accessControlHelper';

const INVALID_PERMISSIONS_FALLBACK = { acl: [], parent: null };

export default function useAclPermissions(userContext, objectId, defaultPermissions) {
    const permissionsService = PermissionsServiceFactory.useInstance();
    const [initialPermissions, setInitialPermissions] = useState(
        defaultPermissions || INVALID_PERMISSIONS_FALLBACK
    );
    const [permissions, setPermissions] = useState(
        defaultPermissions || INVALID_PERMISSIONS_FALLBACK
    );
    const [parentPermissions, setParentPermissions] = useState(null);
    const [userEffectiveActions, setUserEffectiveActions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!userContext) {
            return;
        }

        setIsLoading(true);
        fetch(objectId)
            .then(([permissions, parentPermissions]) =>
                update(permissions, parentPermissions, true)
            )
            .then((sortedEffectivePermissions) => {
                setIsLoading(false);
                setInitialPermissions(sortedEffectivePermissions);
            })
            .catch(() => {
                setIsLoading(false);
                setInitialPermissions(INVALID_PERMISSIONS_FALLBACK);
            });
    }, [userContext, objectId]); // eslint-disable-line

    return {
        update,
        reset,
        initialPermissions,
        permissions,
        parentPermissions,
        userEffectiveActions,
        isLoading,
    };

    function update(newPermissions, newParentPermissions, shouldSort = false) {
        let effectives = {
            effectivePermissions: INVALID_PERMISSIONS_FALLBACK,
            effectiveAcl: INVALID_PERMISSIONS_FALLBACK.acl,
        };

        if (accessControlHelper.isPermissionsObjectValid(newPermissions)) {
            effectives = calculateEffectivePermissions(
                newPermissions,
                newParentPermissions,
                newPermissions?.parent
            );
        }

        if (
            accessControlHelper.isEqualPermissionSet(
                permissions,
                effectives.effectivePermissions
            ) &&
            // we need to calculate user effective actions for default permissions
            !accessControlHelper.isEqualPermissionSet(
                defaultPermissions,
                effectives.effectivePermissions
            )
        ) {
            return Promise.resolve(permissions);
        }

        const permissionsPromise = shouldSort
            ? sortAcl(effectives.effectivePermissions)
            : Promise.resolve(effectives.effectivePermissions);

        return Promise.all([
            permissionsPromise,
            sortAcl(newParentPermissions),
            userContext.principalsDataRepository.getPrincipalsData(effectives.effectiveAcl || []),
        ]).then(([sortedEffectivePermissions, sortedNewParentPermissions, principalsData]) => {
            setPermissions(sortedEffectivePermissions);
            setParentPermissions(sortedNewParentPermissions);
            setUserEffectiveActions(
                accessControlHelper.getUserEffectiveActions(
                    userContext.userId,
                    principalsData,
                    userContext.isAdmin
                )
            );
            return Promise.resolve(sortedEffectivePermissions);
        });
    }

    function reset(permissionsToApply) {
        if (permissionsToApply.parent) {
            return permissionsService
                .fetchPermissions(permissionsToApply.parent)
                .then((initialParentPermissions) => {
                    setPermissions(permissionsToApply);
                    setParentPermissions(initialParentPermissions);

                    return permissionsToApply;
                });
        }

        setPermissions(permissionsToApply);
        return Promise.resolve(permissionsToApply);
    }

    function fetch(objectId = null) {
        const permissionsPromise = objectId
            ? permissionsService.fetchPermissions(objectId)
            : Promise.resolve(permissions);

        return permissionsPromise.then((permissions) => {
            if (permissions.parent) {
                return Promise.all([
                    permissions,
                    permissionsService.fetchPermissions(permissions.parent),
                ]);
            }

            return [permissions, null];
        });
    }

    function calculateEffectivePermissions(
        newPermissions,
        newParentPermissions = null,
        newParentId = null
    ) {
        const permissionsType = accessControlHelper.detectPermissionType(newPermissions.acl);
        const aclInheritedFromParent = permissionsType === AccessControlPermissionTypes.INHERIT;

        const effectiveAcl = aclInheritedFromParent
            ? newParentPermissions?.acl
            : newPermissions.acl;
        const effectivePermissions = {
            ...newPermissions,
            parent: aclInheritedFromParent ? newParentId : null,
        };

        return { effectivePermissions, effectiveAcl, permissionsType };
    }

    function sortAcl(rawPermissions) {
        if (!rawPermissions) {
            return null;
        }

        return userContext.principalsDataRepository
            .getSortedAcl(rawPermissions?.acl)
            .then((acl) => ({ ...rawPermissions, acl }));
    }
}
