import templateUrl from './chartDisplayHoverTooltip.tpl.html';

export default {
    templateUrl,
    bindings: {
        nearestPoint: '<',
        nearestEvents: '<',
        leftOffset: '<',
        bottomOffset: '<',
        inEditor: '<',
        positionAbsolute: '<',
        disableLegend: '<',
    },
    controller: class ChartDisplayHoverTooltipController {
        get thresholdColorSwatchClass() {
            return this.nearestPoint.thresholdColorClass || 'swatch-critical';
        }
    },
};
