import dashboardSelectorTemplateUrl from './dashboardSelector.tpl.html';

export default [
    'sfxModal',
    'SYSTEM_USER_ID',
    function (sfxModal, SYSTEM_USER_ID) {
        /**
         * Opens a modal that prompts the user to select a custom (user-created)
         * dashboard by default. Optional overrides (includeGenerated and includeReadOnly)
         * makes selecting built-in as well as read only dashboards also possible.
         *
         * The modal has a title, a message telling the user what to do, a
         * search box, a button to create a new dashboard, and a paginated list of
         * custom dashboards.
         *
         * @param {Object?} defaultDashboard - An optional custom dashboard object
         *                                     that the modal will select by default.
         * @param {string} message - A message to display to the user when they are
         *                           selecting a dashboard.
         * @param {string} title - A title to succinctly describe what the dashboard
         *                         will be used.
         *
         * @param {bool?} includeGenerated - Optional boolean to allow selecting built-in dashboards
         * @param {bool?} includeReadOnly - Optional boolean to allow selecting read-only dashboards
         *
         * @returns A promise which resolves to a dashboard object that the user
         *          selected if the user selects a dashboard and which is rejected if
         *          the user dismisses the modal.
         */
        this.promptForDashboard = function (options = {}) {
            const { title, message, actions, includeGenerated, includeReadOnly } = options;
            let defaultDashboard = options.defaultDashboard;

            // Do not select any dashboard by default if the client passes in a
            // built-in dashboard to prevent the user from select a built-in dashboard.
            if (
                !includeGenerated &&
                defaultDashboard &&
                defaultDashboard.creator === SYSTEM_USER_ID
            ) {
                defaultDashboard = null;
            }
            const modalParams = {
                templateUrl: dashboardSelectorTemplateUrl,
                controller: 'dashboardSelectorControl',
                windowClass: 'dashboard-selector-top',
                size: 'lg',
                resolve: {
                    actions() {
                        return actions;
                    },
                    displayMessage() {
                        return message;
                    },
                    title() {
                        return title;
                    },
                    includeGenerated() {
                        return includeGenerated;
                    },
                    includeReadOnly() {
                        return includeReadOnly;
                    },
                    currentDashboard() {
                        return angular.isObject(defaultDashboard) ? defaultDashboard : null;
                    },
                },
                backdrop: 'static',
                keyboard: true,
            };
            return sfxModal.open(modalParams).result;
        };
    },
];
