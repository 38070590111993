import templateUrl from './commandLine.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .directive('commandLine', [
        '$document',
        '$window',
        function ($document, $window) {
            return {
                restrict: 'AE',
                replace: true,
                scope: {
                    instruction: '@',
                },
                templateUrl,
                link: function (scope, elem) {
                    scope.canCopy = $window.navigator.appVersion.match(/Chrome/);

                    scope.copy = function () {
                        const selection = scope.selectText();
                        $document[0].execCommand('copy');
                        selection.removeAllRanges();
                    };

                    scope.selectText = function () {
                        const textElem = angular.element('.instruction', elem)[0];
                        const selection = $document[0].getSelection();
                        selection.removeAllRanges();

                        const range = $document[0].createRange();
                        range.selectNodeContents(textElem);
                        selection.addRange(range);
                        return selection;
                    };
                },
            };
        },
    ]);
