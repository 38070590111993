import templateUrl from './multiSelectableItem.tpl.html';

angular
    .module('signalview.typeahead')

    .directive('multiSelectableItem', [
        function () {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    iconClass: '=',
                    value: '=',
                    checkable: '=',
                    onChecked: '=',
                    isSelected: '=',
                    result: '=',
                },
                templateUrl,
            };
        },
    ]);
