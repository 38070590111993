export default [
    'visualizationOptionsToUIModel',
    function (visualizationOptionsToUIModel) {
        const DEFAULT_AXIS_OPTIONS = {
            highWatermark: null,
            highWatermarkLabel: null,
            label: '',
            lowWatermark: null,
            lowWatermarkLabel: null,
            max: null,
            min: null,
        };

        const DEFAULT_PUBLISH_LABEL_OPTIONS = {
            paletteIndex: null,
            plotType: null,
            valuePrefix: null,
            valueSuffix: null,
            valueUnit: null,
            yAxis: 0,
        };

        // TODO (skinner): filter out the properties of these that are not strictly necessary.
        const STARTER_CHART = {
            name: 'Chart',
            options: {
                areaChartOptions: { showDataMarkers: false },
                axes: [angular.copy(DEFAULT_AXIS_OPTIONS), angular.copy(DEFAULT_AXIS_OPTIONS)],
                axisPrecision: null,
                colorBy: 'Dimension',
                defaultPlotType: 'LineChart',
                eventPublishLabelOptions: [],
                histogramChartOptions: { colorThemeIndex: 16 },
                includeZero: false,
                legendOptions: { fields: null },
                lineChartOptions: { showDataMarkers: false },
                onChartLegendOptions: {
                    dimensionInLegend: null,
                    showLegend: false,
                },
                programOptions: {
                    disableSampling: false,
                    maxDelay: 0,
                    minimumResolution: 0,
                },
                publishLabelOptions: [],
                showEventLines: false,
                stacked: false,
                time: {
                    range: 900000,
                    type: 'relative',
                },
                type: 'TimeSeriesChart',
                unitPrefix: 'Metric',
                noDataOptions: {
                    linkText: '',
                    linkUrl: '',
                    noDataMessage: '',
                },
            },
            packageSpecifications: '',
            relatedDetectorIds: [],
            tags: null,
        };

        return function makeChartObject(chartOptions, publishLabelOptions, timeRange) {
            const newChart = angular.copy(STARTER_CHART);

            applyChartOptions(newChart, chartOptions);
            applyOptionsListToChart(newChart, publishLabelOptions);

            if (timeRange && timeRange.type) {
                newChart.options.time = timeRange;
            }

            if (chartOptions.watermark) {
                applyWatermarkToChart(newChart, chartOptions.watermark);
            }

            if (chartOptions.legendOptions) {
                newChart.options.legendOptions = chartOptions.legendOptions;
            }

            return newChart;
        };

        function applyChartOptions(toChart, options) {
            toChart.name = options.name;
            toChart.sf_chart = options.name; // legacy field.

            if (options.type !== undefined) {
                toChart.options.type = options.type;
                delete toChart.options.defaultPlotType;
            }

            if (options.defaultPlotType) {
                toChart.options.defaultPlotType = options.defaultPlotType;
            }

            if (options.stacked !== undefined) {
                toChart.options.stacked = options.stacked;
            }

            if (options.time !== undefined) {
                toChart.options.time = options.time;
            }

            if (options.axes !== undefined) {
                toChart.options.axes = options.axes;
            }
        }

        function applyOptionsListToChart(newChart, metricOptions) {
            newChart.programText = generateSignalFlow(metricOptions);
            metricOptions.forEach((options) => applyOptionsToChart(newChart, options));
        }

        function applyOptionsToChart(newChart, options) {
            const publishLabelOptions = getPublishLabelOptions(options);

            newChart.options.publishLabelOptions.push(publishLabelOptions);
            newChart.sf_uiModel = visualizationOptionsToUIModel(newChart.options);
            newChart.sf_flowVersion = 2;
            newChart.sf_viewProgramText = newChart.programText; // verify necessity
        }

        function applyWatermarkToChart(chart, options) {
            const axis = chart.options.axes[0];

            axis.highWatermark = options.value;
            axis.highWatermarkLabel = options.label;
        }

        function getPublishLabelOptions(options) {
            const mergedOptions = Object.assign({}, DEFAULT_PUBLISH_LABEL_OPTIONS, {
                label: options.streamLabel,
                displayName: options.displayName,
                paletteIndex: options.paletteIndex,
                yAxis: options.yAxis || 0,
                options,
            });

            if (options.valueUnit) {
                mergedOptions.valueUnit = options.valueUnit;
            } else if (options.valuePrefix || options.valueSuffix) {
                mergedOptions.valuePrefix = options.valuePrefix || null;
                mergedOptions.valueSuffix = options.valueSuffix || null;
            }

            if (options.plotType) {
                mergedOptions.plotType = options.plotType;
            }

            return mergedOptions;
        }

        function generateSignalFlow(metricOptions) {
            return metricOptions.map((options) => options.programText).join('\n');
        }
    },
];
