import templateUrl from './hostlist.tpl.html';

const groupedHostsList = {
    restrict: 'E',
    scope: {
        heatmap: '=',
        hosts: '=',
        onDataSorted: '=?',
        onGroupSelected: '=?',
        onValueSelected: '=?',
    },
    templateUrl,
    controller: [
        '$scope',
        'GROUPED_TABLE_EVENTS',
        function ($scope, GROUPED_TABLE_EVENTS) {
            const heatmap = $scope.heatmap;
            $scope.clientSideFilters = [];
            let hostsIncludingDeadHosts = $scope.hosts;
            $scope.hasHostData = Object.keys($scope.hosts).length > 0;
            $scope.showAggregatedTable = heatmap.mode().type === 'list';
            const heatmapEventBindings = [];

            $scope.hideDeadHosts = heatmap.hideDeadHosts();
            $scope.alwaysGroupBy = heatmap.mode().alwaysGroupBy;
            setTableData();

            function setGroupBy(groupBy) {
                $scope.groupBy = groupBy;
                if (
                    heatmap.mode().alwaysGroupBy &&
                    $scope.groupBy.indexOf(heatmap.mode().alwaysGroupBy) === -1
                ) {
                    $scope.groupBy.push(heatmap.mode().alwaysGroupBy);
                }
            }

            setGroupBy(heatmap.groupBy());

            heatmapEventBindings.push(
                heatmap.on('groupBy updated', function ($event, groupBy) {
                    setGroupBy(groupBy);
                })
            );

            $scope.colorBy = heatmap.colorBy();
            heatmapEventBindings.push(
                heatmap.on('colorBy updated', function ($event, colorBy) {
                    $scope.colorBy = colorBy;
                })
            );

            $scope.hideDeadHosts = heatmap.hideDeadHosts();
            heatmapEventBindings.push(
                heatmap.on('hideDeadHosts updated', function ($event, hideDeadHosts) {
                    $scope.hideDeadHosts = hideDeadHosts;
                    setTableData();
                })
            );

            $scope.highlighted = heatmap.highlighted();
            heatmapEventBindings.push(
                heatmap.on('highlighted updated', function ($event, highlighted) {
                    $scope.highlighted = highlighted;
                })
            );

            $scope.coloringFunction = heatmap.coloringFunction();
            heatmapEventBindings.push(
                heatmap.on('coloringFunction updated', function ($event, coloringFunction) {
                    $scope.coloringFunction = coloringFunction;
                })
            );

            function updateModeColumns() {
                const mode = heatmap.mode();
                $scope.columns = mode.listColumns;
                $scope.idColumnName = mode.idName;
            }

            updateModeColumns();

            heatmapEventBindings.push(heatmap.on('mode updated', updateModeColumns));

            $scope.$on(GROUPED_TABLE_EVENTS.VALUE_SELECTED, function ($events, selection) {
                if ($scope.onValueSelected) {
                    $scope.onValueSelected(selection);
                    return;
                }

                const currentSelection = heatmap.selection();
                if (currentSelection && selection === currentSelection.id) {
                    // Unselect
                    heatmap.selection(null);
                    heatmap.tab(null);
                } else {
                    heatmap.groupBySelection(null);
                    if (selection === heatmap.mode().displayName) {
                        // Selecting "All" row
                        heatmap.selection({ id: heatmap.mode().displayName });
                    } else {
                        heatmap.selection($scope.hosts[selection]);
                    }
                }
            });

            $scope.$on(GROUPED_TABLE_EVENTS.GROUP_SELECTED, function ($events, groups) {
                if ($scope.onGroupSelected) {
                    $scope.onGroupSelected(groups);
                    return;
                }

                heatmap.selection(null);
                if (groups) {
                    heatmap.groupBySelection(groups);
                } else {
                    // Unselect
                    heatmap.groupBySelection(null);
                    heatmap.tab(null);
                }
            });

            $scope.$on(GROUPED_TABLE_EVENTS.ROW_SELECTED, function ($events, selected) {
                if (selected === heatmap.mode().displayName) {
                    heatmap.selection({ id: heatmap.mode().displayName });
                } else if (selected) {
                    heatmap.selection(selected);
                } else {
                    heatmap.selection(null);
                }
            });

            $scope.highlightHost = function (selected) {
                heatmap.highlighted(selected);
            };

            function setTableData() {
                let hostData = hostsIncludingDeadHosts;
                if ($scope.hideDeadHosts) {
                    hostData = Object.entries(hostsIncludingDeadHosts).reduce(
                        (arr, [key, value]) => {
                            // filter out dead hosts
                            if (!value._isDead) {
                                arr[key] = value;
                            }
                            return arr;
                        },
                        {}
                    );
                }
                $scope.hosts = hostData;
                $scope.hasHostData = Object.keys($scope.hosts).length > 0;
            }

            $scope.$on('new hosts data', function () {
                hostsIncludingDeadHosts = $scope.hosts;
                setTableData();
            });

            $scope.$on('new hosts metadata', function () {
                setTableData();
            });

            $scope.$on('$destroy', function () {
                heatmapEventBindings.forEach(function (unbind) {
                    unbind();
                });
            });

            if ($scope.onDataSorted) {
                $scope.$on(GROUPED_TABLE_EVENTS.DATA_SORTED, (event, hosts) => {
                    $scope.onDataSorted(event, hosts);
                });
            }
        },
    ],
};

angular.module('signalview.heatmap').directive('groupedHostsList', [
    function () {
        return groupedHostsList;
    },
]);

// eslint-disable-next-line import/no-unused-modules
export { groupedHostsList };
