angular
    .module('signalview.imageService', [
        'signalview.murmurHash',
        'signalview.orgIntegrations',
        'sfx.external',
    ])
    .service('imageService', [
        '$sce',
        'STATIC_RESOURCE_ROOT',
        'murmurHash',
        'sha256',
        'IS_HIGH_SECURITY',
        function ($sce, STATIC_RESOURCE_ROOT, murmurHash, sha256, IS_HIGH_SECURITY) {
            const appAlias = 'signalfx';

            const ICON_NAME_SET = [
                'activemq',
                'amazoneventbridge',
                'amazonlinux',
                'amazonmsk',
                'amazonmwaa',
                'ansible',
                'apache',
                'apachesolr',
                'apachespark',
                'apm',
                'appdynamics',
                'awsautoscaling',
                'awsapigateway',
                'awsbilling',
                'awsoptimizer',
                'awscloudfront',
                'awscognito',
                'awsdocumentdb',
                'awsdynamodb',
                'awsebs',
                'awsec2',
                'awsecs',
                'awselasticache',
                'awselb',
                'awsgatewaylb',
                'awskinesisanalytics',
                'awskinesisfirehouse',
                'awskinesisstreams',
                'awslambda',
                'awsnlb',
                'awsopensearch',
                'awsopsworks',
                'awsrds',
                'awsredshift',
                'awsroute53',
                'awss3',
                'awsservices',
                'awssns',
                'awssqs',
                'azure',
                'azureappservice',
                'azurebatch',
                'azureeventhubs',
                'azurefunctions',
                'azureiothub',
                'azurekubernetesservice',
                'azurelogicapps',
                'azurerediscaches',
                'azuresqldatabases',
                'azuresignalrhubs',
                'azuresqlserverelasticpools',
                'azurestorage',
                'azurevirtualmachines',
                'azurevmscalesets',
                'cassandra',
                'centos',
                'cloudfoundry',
                'chef',
                'consul',
                'conviva',
                'couchbase',
                'couchdb',
                'collectd',
                'debian',
                'docker',
                'elasticsearch',
                'envoy',
                'etcd',
                'expvar',
                'gitlab',
                'golang',
                'google',
                'googleappengine',
                'googlecloudbigquery',
                'googlecloudbigtable',
                'googleclouddatastore',
                'googlecloudfunctions',
                'googlecloudplatform',
                'googlecloudpubsub',
                'googlecloudnat',
                'googlecloudrouter',
                'googlecloudrun',
                'googlecloudspanner',
                'googlecloudsql',
                'googlecloudstorage',
                'googlecloudtasks',
                'googlecomputeengine',
                'googlecontainerengine',
                'googlekubernetesengine',
                'hadoop',
                'hadoophdfs',
                'hadoopmapreduce',
                'hadoopyarn',
                'haproxy',
                'heroku',
                'java',
                'jenkins',
                'jira',
                'jmx',
                'kafka',
                'kong',
                'konggateway',
                'knative',
                'kubernetes',
                'logobserverusage',
                'logstash',
                'marathon',
                'memcached',
                'mesos',
                'microsoftiis',
                'microsoftsqlserver',
                'mongodb',
                'mongodbatlas',
                'mysql',
                'newrelic',
                'nginx',
                'nginxplus',
                'nodejs',
                'okta',
                'onelogin',
                'openstack',
                'opsgenie',
                'oracle',
                'otelcol',
                'pagerduty',
                'postgresql',
                'puppetlabs',
                'python',
                'rabbitmq',
                'redis',
                'ruby',
                'rumapplications',
                'samlsso',
                'servicenow',
                'shellscript',
                appAlias,
                'splunk',
                'slack',
                'smartgateway',
                'snowflake',
                'telegraf',
                'traefik',
                'ubuntu',
                'varnish',
                'victorops',
                'vmwaretanzuas',
                'vsphere',
                'webhook',
                'windows',
                'xmatters',
                'zookeeper',
            ].reduce((set, name) => {
                set[name] = true;
                return set;
            }, {});

            const serviceAliases = {
                apachesparkadmin: 'apachespark',
                apachesparkapplications: 'apachespark',
                apmbusinessworkflows: 'apm',
                appdynamicsmetrics: 'appdynamics',
                apmservices: 'apm',
                awsalb: 'awselb',
                awsenhancedrdsmonitoring: 'awsrds',
                'awsenhancedrdsmonitoring-aurora': 'awsrds',
                'awsenhancedrdsmonitoring-sqlserver': 'awsrds',
                cloudintegrationsusage: 'splunk',
                datacenterhosts: appAlias,
                elasticsearchindex: 'elasticsearch',
                gomemory: 'expvar',
                hostswithagentinstalled: appAlias,
                infrastructure: 'collectd',
                'infrastructure(basecollectd)': 'collectd',
                'infrastructure(telegraf)': 'telegraf',
                'jmx(collectd)': 'jmx',
                logobserverusage: appAlias,
                mesospheremarathon: 'marathon',
                'mongodb(otel)': 'mongodb',
                mysql: 'mysql',
                'mysql(otel)': 'mysql',
                opentelemetrycollector: 'otelcol',
                oracledb: 'oracle',
                organization: appAlias,
                organizationmetrics: appAlias,
                'perfcounterreporter(deprecated)': 'windows',
                pivotalcloudfoundry: 'cloudfoundry',
                'postgresql(new)': 'postgresql',
                'postgresql(otel)': 'postgresql',
                'postgresql(smartagent)': 'postgresql',
                redis: 'redis',
                'redis(otel)': 'redis',
                sampledata: appAlias,
                signalfxk8smetricadapter: appAlias,
                windowsiis: 'microsoftiis',
                µapm: 'apm',
                'µapm2.0': 'apm',
                µapmpg: 'apm',
            };

            /**
             * Get the icon for a service.
             *
             * @param {string} serviceName - The name of the service to fetch an icon
             *                               for. This should be all lowercase and
             *                               should not contain spaces.
             * @returns A URL that points to the icon for the service.
             */
            function getServiceIconURL(serviceName) {
                let name = serviceAliases[serviceName] || serviceName;

                // Transitional code.
                if (name === 'signalfx') {
                    name = 'splunk';
                }

                return ICON_NAME_SET[name]
                    ? getIconURL('icon_' + name + '.png')
                    : getDefaultIconURL(serviceName);
            }

            /**
             * Get a default icon for an object.
             *
             * @param {string} id - A string that identifies the object to get a
             *                      default icon for.
             */
            function getDefaultIconURL(id) {
                // Choose from one of eight generic icons numbered 1 to 8
                const hash = murmurHash(id);
                const n = Math.abs(hash % 8) + 1;
                return getIconURL('icon_generic' + n + '.png');
            }

            function getIconURL(iconName) {
                return STATIC_RESOURCE_ROOT + 'assets/' + iconName;
            }

            function getProfileImageForEmail(email, size) {
                if (IS_HIGH_SECURITY || email === '' || email === null) {
                    return STATIC_RESOURCE_ROOT + 'assets/images/avatar.jpg';
                }
                let url =
                    'https://secure.gravatar.com/avatar/' +
                    sha256.createHash(email.trim().toLowerCase()) +
                    '?d=mm';
                if (size) {
                    url += '&size=' + size;
                }
                return $sce.trustAsResourceUrl(url);
            }

            return {
                getDefaultIconURL: getDefaultIconURL,
                getIconURL: getIconURL,
                getProfileImageForEmail: getProfileImageForEmail,
                getServiceIconURL: getServiceIconURL,
            };
        },
    ]);
