export const bucketing = [
    function () {
        return function (start, end, resolution) {
            // for each given time, find the bucket that the time belongs to under start/end/resolution
            const bucket = function (time) {
                const estimateBucket = Math.round((time - start) / resolution);
                return start + estimateBucket * resolution;
            };
            return {
                bucket: bucket,
                resolution: resolution,
                start: start,
                end: bucket(end),
            };
        };
    },
];
