/* eslint no-unused-vars: off */

angular.module('sfx.charting').directive('histogram', [
    'd3',
    '_',
    function (d3, _) {
        return {
            restrict: 'E',
            scope: {
                data: '=',
                config: '=?',
                filterMap: '=?',
                selection: '=?',
                width: '@',
                height: '@',
            },
            link: function ($scope, $element, attrs) {
                if (!$scope.config) $scope.config = {};
                const margin = { top: 20, right: 20, bottom: 40, left: 50 };
                let data = [];
                const element = $element[0];
                const svg = d3.select(element).append('svg');
                const svgGroup = svg
                    .append('g')
                    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                const histogramGroup = svgGroup.append('g').attr('class', 'histogram');
                const path = svgGroup.append('path').attr('class', 'area');
                const xAxisGroup = svgGroup.append('g').attr('class', 'x axis');
                const yAxisGroup = svgGroup.append('g').attr('class', 'y axis');
                const brushGroup = svgGroup.append('g').attr('class', 'brush');
                const x = d3.scale.linear();
                const y = d3.scale.linear();
                //      var kdeY = d3.scale.linear(); see comment about kde below
                const xAxis = d3.svg.axis().orient('bottom');
                const yAxis = d3.svg.axis().orient('left');

                const yAxisLabel = yAxisGroup
                    .append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('y', -45)
                    .attr('x', -50)
                    .attr('dy', '.71em')
                    .style('text-anchor', 'middle');

                const xAxisLabel = xAxisGroup
                    .append('text')
                    .attr('x', 40)
                    .attr('y', 30)
                    .attr('dx', '.71em')
                    .style('text-anchor', 'middle');

                // See comment about kde below.
                // var line = d3.svg.line()
                //     .x(function(d) { return x(d[0]); })
                //     .y(function(d) { return kdeY(d[1]); });

                const brush = d3.svg.brush();

                let histogram;
                function draw() {
                    if (!data.length) return;
                    const config = $scope.config;
                    const coloringFunction =
                        config.coloringFunction ||
                        function () {
                            return 'blue';
                        };
                    const height = parseFloat(attrs.height, 10) - margin.top - margin.bottom,
                        width = parseFloat(attrs.width, 10) - margin.left - margin.right;

                    const dataProperty = config.dataProperty;

                    yAxisLabel.text('Hosts');
                    xAxisLabel.text(dataProperty);

                    const values = data.map(function (datum) {
                        return datum[dataProperty];
                    });

                    x.range([0, width]);
                    if (config.domain) {
                        x.domain(
                            config.domain(values, function (value) {
                                return value;
                            })
                        );
                    } else {
                        x.domain(d3.extent(values));
                    }

                    y.range([height, 0]);
                    //kdeY.range([height, 0]);

                    histogram = d3.layout.histogram().frequency(true).bins(5);

                    const histogramData = histogram(values);

                    svg.attr('width', width + margin.left + margin.right).attr(
                        'height',
                        height + margin.top + margin.bottom
                    );

                    /**
  Hiding KDE for now, should enable it as an option once integrated to chart
  builder
        var kde = kernelDensityEstimator(epanechnikovKernel(7), x.ticks(20));
        var kdeData = kde(values);
        kdeY.domain(d3.extent(kdeData, function(d) { return d[1]; }));

        path.datum(kde(data))
          .attr('d', line)
          .style({ fill: 'none', stroke: 'blue', 'stroke-width': '5px' });
**/

                    y.domain([
                        0,
                        d3.max(histogramData, function (d) {
                            return d.y;
                        }),
                    ]);

                    xAxis.scale(x).ticks(3).tickFormat(d3.format('s'));
                    yAxis.scale(y).ticks(3).tickFormat(d3.format('s'));

                    const bar = histogramGroup
                        .selectAll('.bar')
                        .data(histogramData)
                        .enter()
                        .append('rect')
                        .attr('class', 'bar')
                        .style({
                            fill: coloringFunction,
                        })
                        .attr('x', function (d) {
                            return x(d.x);
                        })
                        .attr('y', function (d) {
                            return y(d.y);
                        })
                        .attr('width', x(histogramData[0].dx) - 1)
                        .attr('height', function (d) {
                            return height - y(d.y);
                        });

                    xAxisGroup.attr('transform', 'translate(0,' + height + ')').call(xAxis);
                    yAxisGroup.call(yAxis);

                    // ****************************************
                    // Brush functions
                    // ****************************************
                    let brushExtent;
                    function brushmove() {
                        y.domain(x.range()).range(x.domain());
                        brushExtent = brush.extent();

                        const brushStart = brush.empty() ? 0 : brushExtent[0],
                            brushEnd = brush.empty() ? 100 : brushExtent[1];

                        // Snap to rect edge
                        //brushGroup.call((brush.empty()) ? brush.clear() : brush.extent([y.invert(brushStart), y.invert(brushEnd)]));

                        // Fade all years in the histogram not within the brush
                        histogramGroup.selectAll('.bar').style('opacity', function (d, i) {
                            return (d.x >= brushStart && d.x < brushEnd) || brush.empty()
                                ? '1'
                                : '.4';
                        });
                    }

                    function brushend() {
                        const brushStart = brush.empty() ? 0 : brushExtent[0],
                            brushEnd = brush.empty() ? 100 : brushExtent[1];

                        histogramGroup.selectAll('rect.bar').classed('muted', function (d) {
                            return (d.x >= brushStart && d.x <= brushEnd) || brush.empty()
                                ? '1'
                                : '.4';
                        });

                        $scope.selection = brushExtent;

                        const filterMap = $scope.filterMap;
                        const dataProperty = $scope.config.dataProperty;

                        if (brush.empty()) {
                            delete filterMap[dataProperty];
                        } else {
                            const extent = brush.extent();
                            if (!extent) return;

                            if (angular.equals(brushExtent, x.domain())) {
                                delete filterMap[dataProperty];
                            } else {
                                filterMap[dataProperty] = brushExtent;
                            }
                        }

                        $scope.$apply();
                    }

                    // Draw the brush
                    brush.x(x).on('brush', brushmove).on('brushend', brushend);

                    brushGroup.call(brush);

                    brushGroup.selectAll('rect').attr('height', height);

                    function resetBrush() {
                        const brushStart = brush.empty() ? 0 : brushExtent[0],
                            brushEnd = brush.empty() ? 100 : brushExtent[1];

                        if (brush.empty()) {
                            histogramGroup.selectAll('rect.bar').classed('muted', function (d) {
                                return (d.x >= brushStart && d.x <= brushEnd) || brush.empty()
                                    ? '1'
                                    : '.4';
                            });
                        }

                        brush.clear().event(d3.select('.brush'));
                    }
                }

                draw();
                /** See note about KDE above
      function kernelDensityEstimator(kernel, x) {
        return function(sample) {
          return x.map(function(x) {
            return [x, d3.mean(sample, function(v) { return kernel(x - v); })];
          });
        };
      }

      function epanechnikovKernel(scale) {
        return function(u) {
          return Math.abs(u /= scale) <= 1 ? 0.75 * (1 - u * u) / scale : 0;
        };
      }
*/
                // Prevent attempts to draw more than once a frame
                const throttledRedraw = _.throttle(draw, 16);

                attrs.$observe('width', function (value) {
                    if (!value && value !== 0) return;
                    throttledRedraw();
                });

                attrs.$observe('height', function (value) {
                    if (!value && value !== 0) return;
                    throttledRedraw();
                });

                $scope.$watch('data', function (_data) {
                    if (!_data) return;
                    data = _data;
                    throttledRedraw();
                });

                $scope.$watch(
                    'filterMap',
                    function (filterMap) {
                        if (!filterMap) return;
                        const dataProperty = $scope.config.dataProperty;

                        const extent = filterMap[dataProperty] || x.domain();
                        const oldExtent = brush.extent();

                        if (angular.equals(extent, x.domain())) {
                            brush.clear();
                        } else {
                            brush.extent(extent);
                        }

                        brush(brushGroup.transition());
                        brush.event(brushGroup.transition().delay(1000));
                    },
                    true
                );

                $scope.$watch('config', throttledRedraw, true);
            },
        };
    },
]);
