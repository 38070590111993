'use strict';
import templateUrl from './samlIntegration.html';
import samlShowOnLoginPageTooltipTemplateUrl from './samlShowOnLoginPageTooltip.tpl.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('sfxSamlIntegration', [
    function () {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                $scope.samlShowOnLoginPageTooltipTemplateUrl =
                    samlShowOnLoginPageTooltipTemplateUrl;
                function reset() {
                    const credential = $scope.credential;

                    if (!credential.name) {
                        credential.name = 'SAML SSO';
                    }

                    $scope.publicKey = credential.publicKey;
                    $scope.issuerUrl = credential.issuerUrl;
                    $scope.metadataUrl = credential.issuerUrl;
                    $scope.sharedCredentialState.updateCredentials = false;
                }

                $scope.$on('reset', reset);
                reset();

                function updateCredentials() {
                    if (!$scope.publicKey || !$scope.issuerUrl) {
                        $scope.credential.publicKey = null;
                        $scope.credential.issuerUrl = null;
                        $scope.credential.metadataUrl = null;
                    } else {
                        $scope.credential.publicKey = $scope.publicKey;
                        $scope.credential.issuerUrl = $scope.issuerUrl;
                        $scope.credential.metadataUrl = $scope.issuerUrl;
                        $scope.sharedCredentialState.updateCredentials = true;
                    }
                }

                $scope.$watchGroup(['publicKey', 'issuerUrl'], updateCredentials);
            },
        };
    },
]);
