'use strict';
import templateUrl from './fancyFilter.tpl.html';

angular.module('sfx.ui').directive('fancyFilter', [
    '$timeout',
    'chartbuilderUtil',
    'sourceFilterService',
    'typeaheadUtils',
    function ($timeout, chartbuilderUtil, sourceFilterService, typeaheadUtils) {
        return {
            restrict: 'E',
            scope: {
                getRestrictorQuery: '=',
                filters: '=',
                objectTypes: '=',
                enableUrlParams: '=?',
                disableTag: '=?',
                hideLabel: '=?',
                emptyText: '@?',
                sourceSuggestions: '=?',
            },
            templateUrl,
            link: function ($scope, element) {
                const selector = ($scope.selector = {});
                const getSourceSuggestions =
                    $scope.sourceSuggestions || chartbuilderUtil.getSourceSuggestions;

                selector.inputVisible = false;
                selector.showInput = function () {
                    selector.inputVisible = true;

                    $timeout(function () {
                        element.find('input').focus();
                    });
                };

                function updateSelected() {
                    selector.selected = $scope.filters || [];
                }
                updateSelected();

                function updateFilterBy(nval, oval) {
                    if (angular.equals(nval, oval)) {
                        // otherwise, this change might override stuff set by the client of the directive.
                        return;
                    }

                    if (selector.selected.length) {
                        $scope.filters = selector.selected.slice(0);
                    } else {
                        $scope.filters = [];
                    }
                }

                $scope.$watch('selector.selected', updateFilterBy, true);
                $scope.$watch('filters', updateSelected, true);

                $scope.onExit = function () {
                    $scope.selector.inputVisible = false;
                };

                $scope.$watch('selector.query', function (query) {
                    if (query === null) return;
                    updateSuggestions();
                });

                function getBaseQuery() {
                    const queryAnd = [];
                    const query =
                        '' + sourceFilterService.translateSourceFilterObjects(selector.selected);
                    if (query) {
                        queryAnd.push(query);
                    }
                    const restrictor = $scope.getRestrictorQuery();
                    if (restrictor) {
                        queryAnd.push(restrictor);
                    }
                    if (query.length) {
                        return queryAnd
                            .map(function (query) {
                                return '(' + query + ')';
                            })
                            .join(' AND ');
                    } else {
                        return '';
                    }
                }

                let dataGenerationCount = 0;

                function getSuggestions(query) {
                    dataGenerationCount++;
                    const expectedGeneration = dataGenerationCount;

                    return getSourceSuggestions(
                        getBaseQuery(),
                        query || '',
                        null,
                        null,
                        $scope.objectTypes,
                        $scope.disableTag
                    ).then(function (items) {
                        if (expectedGeneration !== dataGenerationCount) {
                            //stale data
                            return null;
                        }
                        typeaheadUtils.sort(items, null);
                        return items;
                    });
                }

                function updateSuggestions() {
                    $scope.loading = true;
                    getSuggestions(selector.query).then(function (suggestions) {
                        $scope.loading = false;
                        if (suggestions === null) {
                            //do nothing if response set is null, means we're waiting for more recently queried results
                            return;
                        }

                        suggestions.forEach(function (suggestion) {
                            suggestion.displayName = suggestion.value;
                        });

                        selector.suggestions = suggestions;

                        if (selector.query) {
                            selector.highlighted = selector.suggestions[0];
                        } else {
                            selector.highlighted = null;
                        }
                    });
                }
            },
        };
    },
]);
