import templateUrl from './tokenUsageChart.tpl.html';

angular
    .module('signalview.organization')

    .component('tokenUsageChart', {
        bindings: {
            orgId: '<',
            token: '<',
            account: '<',
            threshold: '<',
            chartDoneLoading: '&',
            subscriptionType: '<',
            hasTokenLimits: '<',
            currentUsage: '<',
        },
        controller: [
            'subscriptionTypeUtil',
            'monitoringHostService',
            'moment',
            function (subscriptionTypeUtil, monitoringHostService, moment) {
                const ctrl = this;

                ctrl.rangeObject = {
                    start: moment().subtract(7, 'days').valueOf(),
                    end: moment(),
                };

                //overage color used when token limit is above plan limit
                //and when token usage is above token limit
                const overageColor = '#b3bedf';
                ctrl.trendsChartConfig = {
                    textColor: '#ea1849',
                    overColor: overageColor,
                    warningColor: overageColor,
                };

                ctrl.lowLabel = 'Alert Threshold';

                ctrl.$onInit = $onInit;
                ctrl.setCategory = setCategory;

                function $onInit() {
                    ctrl.fetching = true;
                    ctrl.nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(ctrl.subscriptionType);

                    if (ctrl.nonLegacyOrg) {
                        initNonLegacy();
                    } else {
                        ctrl.streamDPMUsage = generateDPMUsageSignalFlow();
                    }
                }

                function initNonLegacy() {
                    ctrl.categories = subscriptionTypeUtil.getCategoriesBySubscription(
                        ctrl.subscriptionType
                    );
                    if (!ctrl.currentUsage) {
                        ctrl.currentUsage = {
                            categoryValues: {},
                            timestamp: new Date().getTime(),
                        };
                    }
                    setCategory(ctrl.categories[0]);
                }
                function generateDPMUsageSignalFlow() {
                    return (
                        "data('sf.org.numDatapointsReceivedByToken', filter=filter('orgId', '" +
                        ctrl.orgId +
                        "') and filter('tokenId', '" +
                        ctrl.token.id +
                        "'), extrapolation='null', " +
                        'maxExtrapolations=-1, rollup="rate").sum().scale(60).publish(label=\'DPM\')'
                    );
                }

                function setCategory(category) {
                    ctrl.selectedCategory = category;
                    const filters = getFilters(category);
                    ctrl.streamNonLegacyUsage = monitoringHostService.getHostBasedUsageSignalFlow(
                        'usage',
                        category.tokenMetric,
                        filters
                    );
                }

                function getFilters(category) {
                    const filters = [{ name: 'tokenId', value: ctrl.token.id }];
                    if (category.resourceType) {
                        filters.push({ name: 'resourceType', value: category.resourceType });
                    }
                    return filters;
                }
            },
        ],
        templateUrl,
    });
