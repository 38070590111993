'use strict';

/*
  A basic infinite scroll handler which assumes that the container will only
  grow. The scroll height of the element should never go down, unless the
  "track"ed value also changes (inwhich case the directive resets itself)

  Example:
  <div infinite-scroll="myItemLoader()">
    <div ng-repeat="item in items">
    </div>
  </div>
*/
angular.module('sfx.ui').directive('infiniteScroll', [
    '$q',
    '$window',
    '_',
    '$timeout',
    function ($q, $window, _, $timeout) {
        const DEFAULT_THROTTLE_RATE = 50; // milliseconds
        const DEFAULT_TOLERANCE = 100; // pixels

        return {
            restrict: 'A',
            scope: {
                // The callback this directive will call whenever it thinks more content
                // should be loaded
                callback: '&infiniteScroll',
                // To prevent tight loops due to a flood of scroll events, the scrolling
                // handler is throttled to only fire every [throttleRate] milliseconds
                throttleRate: '=infiniteScrollThrottle',
                // This is the pixel distance from the bottom before this directive will
                // request for more content to be loaded
                tolerance: '=infiniteScrollTolerance',
                // For situations where the inner content may change, track allows for
                // the resetting of the infinite scroll (and scrolling content to the
                // top automatically
                track: '=infiniteScrollTrack',
                performInitialCheck: '=infiniteScrollAutoInit',
            },
            link: function ($scope, $element) {
                const element = $element[0];
                const throttleRate = $scope.throttleRate || DEFAULT_THROTTLE_RATE;
                const tolerance = $scope.tolerance || DEFAULT_TOLERANCE;
                const throttledOnScroll = _.throttle(onScroll, throttleRate);

                // The lowest the user has scrolled so far. This is used to ensure we
                // don't recalculate unnescessarily do computation or call the callback
                // more than nescessary
                let highestBottom = 0;
                let promiseInFlight = false;
                let initialCheckBottom;
                let initialElementHeight = null;

                $scope.$watch('track', reset);
                $scope.$on('infiniteScroll.reset', reset);

                // Throttle scrolling events so that we don't unnescessarily recalculate
                // and bring down the frame rate.
                angular.element(element).on('scroll', throttledOnScroll);

                // Window resize can change the scrollabla area, so recalculate
                // whenever the window size changes.
                $window.addEventListener('resize', throttledOnScroll);
                $scope.$on('$destroy', function () {
                    $window.removeEventListener('resize', throttledOnScroll);
                });

                function reset() {
                    element.scrollTop = 0;
                    highestBottom = 0;
                    initialElementHeight = null;

                    if ($scope.performInitialCheck) {
                        initialCheckBottom = 0;
                        $scope.$applyAsync(initialCheckAndExpand);
                    }
                }

                function onScroll() {
                    if (promiseInFlight) {
                        return;
                    }

                    const currentBottom = element.scrollTop + element.offsetHeight;

                    // If current bottom is higher than the lowest we have been, then
                    // we don't need to continue with the evaluation as we assume the
                    // container will never shrink
                    if (currentBottom <= highestBottom) return;
                    highestBottom = currentBottom;

                    const height = element.scrollHeight;
                    const distanceFromBottom = height - currentBottom;
                    if (distanceFromBottom < tolerance) {
                        const callbackReturn = $scope.callback();
                        promiseInFlight = true;

                        // wrapping callback return in $q so that finally does not cause runtime
                        // error. In this case promiseInFlight is still set back to false.
                        $q.when(callbackReturn).finally(() => {
                            promiseInFlight = false;
                        });

                        // Ensure we don't fire again until reaching the bottom of the
                        // current container, otherwise scroll events while content is
                        // loading may trigger a callback call again.
                        highestBottom = height;
                    }
                }

                function initialCheckAndExpand() {
                    const lastChild = $element.children().last()[0];
                    if (!lastChild) {
                        return;
                    }

                    const lastChildBottom = lastChild.offsetTop + lastChild.offsetHeight;

                    // Stop if inner element does not expand or the scroll parent itself is expanding
                    if (
                        initialCheckBottom === lastChildBottom ||
                        (initialElementHeight !== null &&
                            element.offsetHeight > initialElementHeight)
                    ) {
                        return;
                    }

                    initialCheckBottom = lastChildBottom;
                    initialElementHeight = element.offsetHeight;

                    const parentBottom = element.scrollTop + element.offsetHeight;

                    if (lastChildBottom >= parentBottom + tolerance) {
                        return;
                    }

                    const callbackReturn = $scope.callback();
                    promiseInFlight = true;

                    $q.when(callbackReturn).finally(() => {
                        promiseInFlight = false;
                        // Let DOM update and re-check
                        $timeout(() => initialCheckAndExpand());
                    });
                }
            },
        };
    },
]);
