import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleSuggestion from '../../../common/ui/accessControl/SimpleSuggestion';
import AclDashboardGroupService from '../../../legacy/app/dashboardGroup/aclDashboardGroup/AclDashboardGroupService';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';

export default function AclDashboardGroupSelector({
    disabled,
    defaultDashboardGroupId,
    onReset,
    onChange,
}) {
    const aclDashboardGroupService = AclDashboardGroupService.useInstance();
    const { principalsDataRepository } = useContext(AclFormContext);

    return (
        <SimpleSuggestion
            disabled={disabled}
            searchProvider={search}
            onSelectCallback={handleSelect}
            onChangeCallback={() => handleSelect(null)}
            placeholder="Select group..."
        />
    );

    async function handleSelect({ id: dashboardGroupId }) {
        onReset(dashboardGroupId);
        if (dashboardGroupId) {
            const dashboardGroup = await aclDashboardGroupService.get(dashboardGroupId);
            const acl = await principalsDataRepository.getSortedAcl(
                dashboardGroup?.permissions?.acl
            );

            onChange(dashboardGroup, acl);
        }
    }

    function search(term) {
        return (
            aclDashboardGroupService
                .search(term)
                // for an empty search phrase default DG (if id set) should be at the beginning of an array
                .then((groups) => {
                    if (term || !defaultDashboardGroupId) {
                        return groups;
                    }
                    // check whether options already contain default dg
                    const index = groups.findIndex((dg) => dg.id === defaultDashboardGroupId);
                    // if not then fetch default DG and put at the beginning
                    if (index === -1) {
                        return aclDashboardGroupService
                            .get(defaultDashboardGroupId)
                            .then((defaultDG) => [defaultDG, ...groups]);
                    }
                    const defaultDG = groups[index];
                    groups.splice(index, 1);

                    return [defaultDG, ...groups];
                })
                .then(mapToSuggestionOptions)
        );
    }

    function mapToSuggestionOptions(groups) {
        return groups.map((group) => ({
            label: group.name,
            id: group.id,
        }));
    }
}

AclDashboardGroupSelector.propTypes = {
    disabled: PropTypes.bool.isRequired,
    defaultDashboardGroupId: PropTypes.string,
    onReset: PropTypes.func,
    onChange: PropTypes.func,
};

AclDashboardGroupSelector.defaultProps = {
    onReset: () => {},
    onChange: () => {},
};
