import templateUrl from './orgSubscription.tpl.html';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { supportService } from '@splunk/olly-services/lib';

export default {
    bindings: {
        subscriptionSvc: '=',
        planInfoSvc: '=',
    },
    templateUrl,
    controller: [
        '$q',
        '$window',
        '$log',
        'SALES_NUMBER',
        'orgSubscriptionService',
        'ACCOUNT_SOURCES',
        'MARKETPLACE_PRODUCT_LINK',
        'organizationService',
        function (
            $q,
            $window,
            $log,
            SALES_NUMBER,
            orgSubscriptionService,
            ACCOUNT_SOURCES,
            MARKETPLACE_PRODUCT_LINK,
            organizationService
        ) {
            const ctrl = this;
            ctrl.SALES_NUMBER = SALES_NUMBER;
            let modalInstance = null;

            /* scoped functions */
            ctrl.$onInit = $onInit;
            ctrl.syncOrg = syncOrg;
            ctrl.MARKETPLACE_PRODUCT_LINK = MARKETPLACE_PRODUCT_LINK;

            function $onInit() {
                ctrl.isSupportOrg = supportService.getSupportOrg();
                organizationService.get().then((org) => {
                    ctrl.org = org;
                    ctrl.isTrial = org.accountType === 'TRIAL';
                    ctrl.isCustom = org.accountType === 'CUSTOM';
                    ctrl.isAmazon = org.accountSource === ACCOUNT_SOURCES.AWS_MARKET_PLACE;
                });

                init();

                ctrl.processingSubscription = false;
            }

            function syncOrg() {
                ctrl.syncing = true;
                organizationService.get().then((org) => {
                    orgSubscriptionService
                        .syncOrg(org.accountKey)
                        .then(function () {
                            ngRoute.reload();
                        })
                        .catch(function (e) {
                            $window.alert('There was an error.');
                            $log.error('Failed syncing ', e);
                            ctrl.syncing = false;
                        });
                });
            }

            /* unscoped functions */

            function init() {
                initPlanInfo(true, true);
            }

            function clear() {
                ctrl.subscriptionSvc.breakPromises();
                ctrl.account = null;
                ctrl.upcoming = null;
                ctrl.paymentMethod = null;
                ctrl.paymentHistory = null;
                ctrl.subscriptionHistory = null;
            }

            function doneTransaction() {
                if (modalInstance) {
                    modalInstance.close();
                    modalInstance = null;
                }
                ctrl.processingSubscription = false;
            }

            function getAccount() {
                if (ctrl.account) {
                    return $q.when(ctrl.account);
                }
                ctrl.fetching = true;
                return ctrl.subscriptionSvc.getAccount();
            }

            function initPlanInfo(val, stale) {
                doneTransaction();
                if (!val) {
                    return;
                }
                if (!stale) {
                    clear();
                }
                setAccount(stale);
            }

            function setAccount(stale) {
                if (!stale) {
                    ctrl.subscriptionSvc.breakPromises('account');
                    ctrl.account = null;
                    ctrl.upcoming = null;
                }

                return getAccount().then((acct) => {
                    ctrl.fetching = false;
                    ctrl.account = acct;
                    if (!acct) {
                        return null;
                    }
                    return acct;
                });
            }
        },
    ],
};
