'use strict';
import templateUrl from './slackIntegration.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('sfxSlackIntegration', [
    'notificationService',
    'credentialV2Service',
    '$window',
    '$location',
    function (notificationService, credentialV2Service, $window, $location) {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                const params = $location.search();
                if (params.installed) {
                    $scope.installedIntegration = params.installed;
                }

                $scope.temp = {};

                function reset() {
                    const credential = $scope.credential;
                    if (!credential.name) {
                        credential.name = 'Slack';
                    }
                    $scope.temp.webhookUrl = '';
                    $scope.sharedCredentialState.updateCredentials = false;
                    $scope.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                        $scope.credential.lastUpdated
                    );
                }

                $scope.refreshAccessToken = function () {
                    credentialV2Service.slackOAuthUrl($scope.credential.id).then((url) => {
                        $window.location.href = url;
                    });
                };

                $scope.$on('reset', reset);
                reset();

                $scope.$watch('temp.webhookUrl', function (newValue) {
                    if (newValue) {
                        $scope.credential.webhookUrl = newValue;
                        $scope.sharedCredentialState.updateCredentials = true;
                    } else {
                        $scope.credential.webhookUrl = null;
                    }
                });
            },
        };
    },
]);
