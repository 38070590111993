angular.module('sfx.api.v2').service('chartV2Service', [
    '$q',
    'createV2Service',
    'currentUser',
    '$http',
    'dashboardGroupService',
    'dashboardV2Service',
    function ($q, createV2Service, currentUser, $http, dashboardGroupService, dashboardV2Service) {
        const chartV2Service = createV2Service('chart');

        function getChart(id) {
            return currentUser.orgId().then(function (orgId) {
                const resourcePath = '/' + id + '?organizationId=' + orgId;
                return $http({
                    method: 'GET',
                    url: chartV2Service.resourceEndpoint + resourcePath,
                });
            });
        }

        function getChartData(id, errorHandler) {
            return getChart(id).then(
                function (data) {
                    return data;
                },
                function (error) {
                    if (errorHandler) {
                        return errorHandler({
                            fn: getChart,
                            id: id,
                            error: error,
                        });
                    } else {
                        return $q.reject(error);
                    }
                }
            );
        }

        chartV2Service.getChartWithPlots = function (id) {
            return currentUser.orgId().then(function (orgId) {
                const resourcePath =
                    '/' + id + '?organizationId=' + orgId + '&getParsedOutput=true';
                return $http({
                    method: 'GET',
                    url: chartV2Service.resourceEndpoint + resourcePath,
                }).then(
                    function (resp) {
                        return resp.data;
                    },
                    function () {
                        return null;
                    }
                );
            });
        };

        chartV2Service.get = function (id, errorHandler) {
            return getChartData(id, errorHandler).then(function (resp) {
                return resp.data;
            });
        };

        chartV2Service.getDashboardWithGroupForChartID = function (chartId, groupId) {
            let dashboardGroup;
            const dashboard = dashboardV2Service.getDashboardForChartId(chartId);
            if (groupId) {
                dashboardGroup = dashboardGroupService.get(groupId).catch(() => null);
            } else {
                dashboardGroup = dashboard.then((dashboard) =>
                    dashboard.groupId
                        ? dashboardGroupService.get(dashboard.groupId).catch(() => null)
                        : null
                );
            }

            return $q.all({ dashboard, dashboardGroup });
        };

        return chartV2Service;
    },
]);
