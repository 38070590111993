export const HOSTS_CATEGORY = 'Host Count';
export const CONTAINERS_CATEGORY = 'Container Count';
export const CUSTOM_METRICS_CATEGORY = 'Custom Metrics';

export default [
    'signalStream',
    function (signalStream) {
        const HOSTS_METRIC = 'sf.org.numResourcesMonitored';
        const HOSTS_RESOURCE_TYPE = 'host';

        const CONTAINERS_METRIC = 'sf.org.numResourcesMonitored';
        const CONTAINERS_RESOURCE_TYPE = 'container';

        const CUSTOM_METRICS_METRIC = 'sf.org.numCustomMetrics';

        const tsidToCategory = {};

        // Used in Current Usage charts on host-based Billing and Usage page
        const signalFlowText =
            'A = data("' +
            HOSTS_METRIC +
            '", filter=filter("resourceType", "' +
            HOSTS_RESOURCE_TYPE +
            '"), rollup="latest").sum().publish(label="A")\n' +
            'B = data("' +
            CONTAINERS_METRIC +
            '", filter=filter("resourceType", "' +
            CONTAINERS_RESOURCE_TYPE +
            '"), rollup="latest").sum().publish(label="B")\n' +
            'C = data("' +
            CUSTOM_METRICS_METRIC +
            '", rollup="latest").sum().publish(label="C")';

        function getHostBasedUsageSignalFlowBase(label, metric, filters) {
            let signalFlowText = label + ' = data("' + metric + '", ';
            if (filters && filters.length > 0) {
                const filterText = filters
                    .map((filter) => 'filter("' + filter.name + '", "' + filter.value + '")')
                    .join(' and ');
                signalFlowText += 'filter=' + filterText + ',';
            }
            signalFlowText += 'rollup="max", extrapolation="zero").sum()';
            return signalFlowText;
        }

        // Used in Usage Trends charts on host-based Billing and Usage page
        // Used in Token Usage Usage Trends chart on tokens page
        function getHostBasedUsageSignalFlow(label, metric, filters) {
            let signalFlowText = getHostBasedUsageSignalFlowBase(label, metric, filters);
            signalFlowText += '.publish(label="' + label + '")';
            return signalFlowText;
        }

        function getHostBasedAverageUsageSignalFlow(label, metric, filters) {
            let signalFlowText = getHostBasedUsageSignalFlowBase(label, metric, filters);
            signalFlowText +=
                '.mean(cycle="month", cycle_start="1d", partial_values=False).publish(label="' +
                label +
                '")';
            return signalFlowText;
        }

        function getHostBasedAverageUsageSignalFlowWithPartialValues(label, metric, filters) {
            let signalFlowText = getHostBasedUsageSignalFlowBase(label, metric, filters);
            signalFlowText +=
                '.mean(cycle="month", cycle_start="1d", partial_values=True).publish(label="' +
                label +
                '")';
            return signalFlowText;
        }

        function getHostBasedMetricData(account, opts, legacyMetricDelay) {
            const categoryToValue = {
                latestTimestamp: 0,
            };

            const categoryToLimit = {};

            if (account) {
                categoryToLimit[HOSTS_CATEGORY] = account.quotas.CATEGORY_LIMITS[1];
                categoryToLimit[CONTAINERS_CATEGORY] = account.quotas.CATEGORY_LIMITS[2];
                categoryToLimit[CUSTOM_METRICS_CATEGORY] = account.quotas.CATEGORY_LIMITS[3];
            }

            const timeInMillis = legacyMetricDelay ? 15 * 60 * 1000 : 30 * 60 * 1000;

            const jobOpts = {
                signalFlowText,
                offsetByMaxDelay: true,
                streamStartCallback: opts.streamStartCallback,
                metaDataUpdated: (data, tsid) => {
                    metaDataUpdatedCallback(data, tsid);
                    if (opts.metaDataUpdated) {
                        opts.metaDataUpdated(data, tsid);
                    }
                },
                onFeedback: opts.onFeedback,
                onStreamError: opts.onStreamError,
                historyrange: timeInMillis,
                callback: (data) => {
                    const latest = dataCallback(data, categoryToValue, categoryToLimit);
                    if (opts.callback && latest.timestamp) {
                        opts.callback(categoryToValue, latest);
                    }
                },
            };

            return signalStream.stream(jobOpts);
        }

        function dataCallback(data, categoryToValue, categoryToLimit) {
            const { tsid, timestamp, value } = data;
            const category = tsidToCategory[tsid];
            const latest = {};

            categoryToValue.latestTimestamp = Math.max(timestamp, categoryToValue.latestTimestamp);

            if ((value !== null && !latest.timestamp) || timestamp > latest.timestamp) {
                latest.timestamp = timestamp;
                latest.category = category;

                if (categoryToLimit[category] && value > categoryToLimit[category]) {
                    latest.current = categoryToLimit[category];
                    latest.overage = value - categoryToLimit[category];
                } else {
                    // Either limit is unknown or usage is under the limit
                    latest.current = value;
                    latest.overage = 0;
                }

                categoryToValue[category] = value;
            }

            return latest;
        }

        function metaDataUpdatedCallback(data, tsid) {
            const metric = data.sf_originatingMetric;
            const resourceType = data.resourceType;

            if (metric === HOSTS_METRIC && resourceType === HOSTS_RESOURCE_TYPE) {
                tsidToCategory[tsid] = HOSTS_CATEGORY;
            } else if (metric === CONTAINERS_METRIC && resourceType === CONTAINERS_RESOURCE_TYPE) {
                tsidToCategory[tsid] = CONTAINERS_CATEGORY;
            } else if (metric === CUSTOM_METRICS_METRIC) {
                tsidToCategory[tsid] = CUSTOM_METRICS_CATEGORY;
            }
        }

        return {
            getHostBasedMetricData,
            getHostBasedUsageSignalFlow,
            getHostBasedAverageUsageSignalFlow,
            getHostBasedAverageUsageSignalFlowWithPartialValues,
        };
    },
];
