import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { variables } from '@splunk/themes';
import Menu from '@splunk/react-ui/Menu';
import Button from '@splunk/react-ui/Button';
import Dropdown from '@splunk/react-ui/Dropdown';
import Tooltip from '@splunk/react-ui/Tooltip';
import { capitalize, without } from 'lodash';
import styled from 'styled-components';
import { react2angular } from 'react2angular';
import { AngularInjector } from '../../AngularUtils';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';
import { DetectorOrigin, DetectorOriginLabels } from './DetectorOriginLabels';

const StyledButton = styled(Button)`
    height: 34px;
    min-height: 34px;
    padding: ${variables.spacingXSmall} ${variables.spacingMedium};

    && {
        max-width: 300px;
    }
`;

const StyledHeading = styled.h5`
    && {
        font-size: ${variables.fontSizeSmall};
        margin: 0;
        padding: ${variables.spacingLarge} ${variables.spacingLarge} 0;
        text-transform: uppercase;
    }
`;

const serializeValue = (
    selectedDetectorOrigins: DetectorOrigin[],
    showOnlyMuted: boolean
): string => {
    const valueArray: string[] = [...selectedDetectorOrigins].sort();
    if (showOnlyMuted) {
        valueArray.push('OnlyMuted');
    }

    return valueArray.join(',');
};

const closeReasons = without(Dropdown.possibleCloseReasons, 'contentClick');

const DETECTOR_ORIGINS = Object.values(DetectorOrigin);
export const DETECTOR_ORIGINS_LENGTH = DETECTOR_ORIGINS.length;

const DEFAULT_SELECTED_DETECTOR_ORIGINS = [
    DetectorOrigin.STANDARD,
    DetectorOrigin.AUTO_DETECT,
    DetectorOrigin.AUTO_DETECT_CUSTOMIZATION,
];

export const DetectorListFilters: FC<{
    objectType: string;
    onSelect: (selectedDetectorOrigins: DetectorOrigin[], showOnlyMuted: boolean) => void;
    isShowOnlyMutedEnabled: boolean;
    isMuted: boolean | undefined;
    detectorOrigins: DetectorOrigin[] | undefined;
}> = ({ objectType, onSelect, isShowOnlyMutedEnabled, isMuted, detectorOrigins }) => {
    const [showOnlyMuted, setShowOnlyMuted] = useState<boolean>(isMuted || false);
    const [selectedDetectorOrigins, setSelectedDetectorOrigins] = useState<DetectorOrigin[]>(
        detectorOrigins || DEFAULT_SELECTED_DETECTOR_ORIGINS
    );

    const themeKey = useLegacyThemeServiceKey();
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');

    const handleDetectorOriginSelect = useCallback(
        (detectorOrigin) => {
            if (selectedDetectorOrigins.includes(detectorOrigin)) {
                if (selectedDetectorOrigins.length > 1) {
                    setSelectedDetectorOrigins(
                        selectedDetectorOrigins.filter((item) => item !== detectorOrigin)
                    );
                }
            } else {
                setSelectedDetectorOrigins([...selectedDetectorOrigins, detectorOrigin]);
            }
        },
        [selectedDetectorOrigins]
    );

    const handleShowOnlyMutedSelect = useCallback(
        () => setShowOnlyMuted(!showOnlyMuted),
        [showOnlyMuted]
    );

    const handleClose = useCallback(() => {
        onSelect?.(selectedDetectorOrigins, showOnlyMuted);
        userAnalytics.event(
            `${capitalize(objectType)}_Listing`,
            'Click',
            null,
            `Detector_Origin_Filter/${serializeValue(selectedDetectorOrigins, showOnlyMuted)}`
        );
    }, [objectType, onSelect, selectedDetectorOrigins, showOnlyMuted, userAnalytics]);

    const selectedLabel = useMemo(() => {
        if (selectedDetectorOrigins.length === DETECTOR_ORIGINS_LENGTH) {
            if (showOnlyMuted) {
                return `Only muted ${objectType}`;
            }
            return `All ${objectType}`;
        }

        return `${selectedDetectorOrigins
            .map((detectorOrigin) => DetectorOriginLabels[detectorOrigin])
            .join(', ')} ${showOnlyMuted ? '(Only muted)' : ''}`;
    }, [objectType, selectedDetectorOrigins, showOnlyMuted]);

    const Toggle = (
        <Tooltip content={selectedLabel}>
            <StyledButton appearance="toggle" label={selectedLabel} isMenu />
        </Tooltip>
    );

    useEffect(() => {
        isShowOnlyMutedEnabled || setShowOnlyMuted(false);
    }, [isShowOnlyMutedEnabled]);

    return (
        <ThemeProvider colorScheme={themeKey}>
            <Dropdown
                onRequestClose={handleClose}
                toggle={Toggle}
                retainFocus
                closeReasons={closeReasons}
                defaultPlacement="below"
            >
                <Menu placeholder="All">
                    <StyledHeading>Detector origin</StyledHeading>
                    <Menu.Divider />
                    {DETECTOR_ORIGINS.map(
                        (detectorOrigin): ReactElement => (
                            <Menu.Item
                                onClick={(): void => handleDetectorOriginSelect(detectorOrigin)}
                                key={detectorOrigin}
                                selectable
                                selected={selectedDetectorOrigins.includes(detectorOrigin)}
                            >
                                {DetectorOriginLabels[detectorOrigin]}
                            </Menu.Item>
                        )
                    )}
                    {isShowOnlyMutedEnabled && (
                        <>
                            <StyledHeading>Other filters</StyledHeading>
                            <Menu.Divider />
                            <Menu.Item
                                selectable
                                selected={showOnlyMuted}
                                onClick={handleShowOnlyMutedSelect}
                            >
                                Only muted {objectType}
                            </Menu.Item>
                        </>
                    )}
                </Menu>
            </Dropdown>
        </ThemeProvider>
    );
};

export const detectorListFilters = react2angular(DetectorListFilters, [
    'objectType',
    'isShowOnlyMutedEnabled',
    'onSelect',
    'isMuted',
    'detectorOrigins',
]);
