import templateUrl from './detectorWizardType.tpl.html';

angular.module('signalview.detector.wizard').component('detectorWizardTypePicker', {
    bindings: {
        isNewRule: '<',
        isApmEnabled: '<',
        isApm2Enabled: '<',
        readOnly: '<',
        selectedType: '<',
        onSelectDetectorType: '&',
        proceed: '&',
    },
    templateUrl,
    controller: [
        'DETECTOR_TYPES',
        'STATIC_RESOURCE_ROOT',
        'SPLUNK_APM_PRODUCT_NAME',
        '$log',
        function (DETECTOR_TYPES, STATIC_RESOURCE_ROOT, SPLUNK_APM_PRODUCT_NAME, $log) {
            const $ctrl = this;

            $ctrl.STATIC_RESOURCE_ROOT = STATIC_RESOURCE_ROOT;
            $ctrl.APM_TYPE = DETECTOR_TYPES.APM;
            $ctrl.APM2_TYPE = DETECTOR_TYPES.APM_V2;
            $ctrl.INFRA_TYPE = DETECTOR_TYPES.INFRASTRUCTURE;
            $ctrl.detectorDetectorType = '';
            $ctrl.selectedType = '';
            $ctrl.APM_PRODUCT_NAME = SPLUNK_APM_PRODUCT_NAME;

            // lifecycle
            $ctrl.$onInit = $onInit;

            // user actions
            $ctrl.selectDetectorType = selectDetectorType;

            // helpers

            // infra option is always available so we have 1 as base
            // then apm and apm2 are taken and filtered out if they're false
            $ctrl.hasTwoOptions = () =>
                2 === 1 + [$ctrl.isApmEnabled, $ctrl.isApm2Enabled].filter(Boolean).length;

            function $onInit() {
                if ($ctrl.selectedType === DETECTOR_TYPES.APM && !$ctrl.isApmEnabled) {
                    $log.error(
                        'APM type option was selected but the user is missing entitlement. Defaulting to type infra.'
                    );
                    $ctrl.onSelectDetectorType({ type: DETECTOR_TYPES.INFRASTRUCTURE });
                }

                if ($ctrl.selectedType === DETECTOR_TYPES.APM_V2 && !$ctrl.isApm2Enabled) {
                    $log.error(
                        'APM 2.0 type option was selected but the user is missing entitlement. Defaulting to type infra.'
                    );
                    $ctrl.onSelectDetectorType({ type: DETECTOR_TYPES.INFRASTRUCTURE });
                }

                $ctrl.hasRecommendation = !!$ctrl.selectedType;
                $ctrl.isApmRecommended = $ctrl.selectedType === DETECTOR_TYPES.APM;
                $ctrl.isApm2Recommended = $ctrl.selectedType === DETECTOR_TYPES.APM_V2;
                $ctrl.readOnly = false;
            }

            function selectDetectorType(selectedType) {
                if (!$ctrl.isNewRule || $ctrl.readOnly) {
                    return;
                }

                $ctrl.selectedType = selectedType;
                $ctrl.onSelectDetectorType({ type: selectedType });
            }
        },
    ],
});
