angular.module('signalview.detector').controller('SubscribeToExistingDetectorController', [
    '$scope',
    'data',
    'convertNotificationRules',
    'detectorService',
    'detectorUtils',
    '$timeout',
    '$log',
    'v2DetectorAPIWrapper',
    'hasWritePermission',
    function (
        $scope,
        data,
        convertNotificationRules,
        detectorService,
        detectorUtils,
        $timeout,
        $log,
        v2DetectorAPIWrapper,
        hasWritePermission
    ) {
        $scope.detector = data.detector;
        $scope.isV2 = $scope.detector.programText;
        $scope.notificationRules = !$scope.isV2
            ? convertNotificationRules($scope.detector.sf_uiModel, true)
            : $scope.detector.rules;
        $scope.hasWritePermission = hasWritePermission;

        $scope.update = function () {
            $scope.state = 'loading';
            $scope.$broadcast('beforeSave');
            if (!$scope.isV2) {
                updateV1();
            } else {
                updateV2();
            }
        };

        function updateV1() {
            // populate the notifications by unique key
            const uniqueKeyToNotifications = {};
            angular.forEach($scope.notificationRules, function (rule) {
                uniqueKeyToNotifications[rule.uniqueKey] = rule.notifications;
            });

            // fetch existing detector for update
            detectorService
                .get($scope.detector.sf_id)
                .then(function (model) {
                    // populate the notifications to each rule.
                    angular.forEach(model.sf_uiModel.rules, function (rule) {
                        rule.notifications = uniqueKeyToNotifications[rule.uniqueKey];
                    });

                    return detectorUtils.saveDetector(model);
                })
                .then(
                    function () {
                        $scope.state = 'updated';
                        $timeout($scope.$close, 1000);
                    },
                    function (response) {
                        $scope.state = null;
                        $log.error('Error updating detector', response);
                    }
                );
        }

        function updateV2() {
            const v2Api = v2DetectorAPIWrapper($scope.detector);
            v2Api.save().then(
                function () {
                    $scope.state = 'updated';
                    $timeout($scope.$close, 1000);
                },
                function (response) {
                    $scope.state = null;
                    $log.error('Error updating detector', response);
                }
            );
        }
    },
]);
