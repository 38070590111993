import templateUrl from './awsServiceList.tpl.html';

angular.module('signalview.objectmanager').directive('awsServiceList', [
    'getIntegrationBadgeURL',
    '$location',
    'currentUser',
    'signalboost',
    'AWS_SERVICES',
    function (getIntegrationBadgeURL, $location, currentUser, signalboost, AWS_SERVICES) {
        return {
            restrict: 'E',
            scope: {
                object: '=',
                filters: '=',
                inViewAll: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.showService = function (service) {
                        const object = $scope.object;

                        const search = $location.search();

                        search.topicFilterKey = object.sf_topic;
                        search.selectedKeyValue = object.sf_topic + ':' + service.namespace;

                        $location.search(search);
                    };

                    function getServices(filters, limit) {
                        // filters are exceedingly unlikely to work in this scenario because we search for
                        // namespace dimensions, however the new metadawta catalog view cannot filter anyway.
                        filters = filters || [];
                        limit = limit || 20;

                        return currentUser.orgId().then(function (orgId) {
                            return signalboost.autosuggest
                                .all()
                                .all('suggest')
                                .all('value')
                                .customGET('', {
                                    partialInput: '',
                                    query: filters.join(' AND '),
                                    limit: limit,
                                    type: 'Dimension',
                                    property: 'namespace',
                                    organizationId: orgId,
                                })
                                .catch(function () {
                                    return [];
                                });
                        });
                    }

                    const namespaceToBadgeName = {
                        'AWS/EC2': 'ec2',
                        'AWS/ElastiCache': 'elasticache',
                        'AWS/EBS': 'ebs',
                        'AWS/ELB': 'elb',
                        'AWS/OpsWorks': 'opsworks',
                        'AWS/RDS': 'rds',
                        'AWS/DynamoDB': 'dynamodb',
                        'AWS/CloudFront': 'cloudfront',
                        'AWS/Route53': 'route53',
                        'AWS/SQS': 'sqs',
                        'AWS/ECS': 'ecs',
                        'AWS/S3': 's3',
                        'AWS/AutoScaling': 'autoscaling',
                        'AWS/SNS': 'sns',
                        'AWS/Billing': 'awsbilling',
                    };

                    getServices($scope.filters, 100).then(function (services) {
                        $scope.services = [];

                        services.forEach(function (service) {
                            const namespace = service;
                            const badgeName = namespaceToBadgeName[namespace];
                            const name = AWS_SERVICES[badgeName];

                            if (!name) return;

                            $scope.services.push({
                                name: name,
                                namespace: namespace,
                                badgeName: badgeName,
                                badgeURL: getIntegrationBadgeURL(badgeName),
                            });
                        });
                    });
                },
            ],
        };
    },
]);
