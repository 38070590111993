const EVENT_CATEGORY_NEXT_STEPS = 'Data_View/Alert_NextSteps';
const EVENT_ACTION_CLICK = 'Click';
const EVENT_ACTION_GENERATED = 'Generated';
const EVENT_LABEL_INFRA = 'Infrastructure';

export function trackInfrastructureLinkGenerated(userAnalytics) {
    userAnalytics.event(EVENT_CATEGORY_NEXT_STEPS, EVENT_ACTION_GENERATED, null, EVENT_LABEL_INFRA);
}

export function trackInfrastructureLinkClick(userAnalytics, linkName) {
    userAnalytics.event(
        EVENT_CATEGORY_NEXT_STEPS,
        EVENT_ACTION_CLICK,
        null,
        `${EVENT_LABEL_INFRA}/${linkName}`
    );
}
