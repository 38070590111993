'use strict';
import templateUrl from './awsIntegration.html';
import integrationServicesTemplateUrl from './integrationServices.html';
import awsIntegrationRegionsTemplateUrl from './awsIntegrationRegions.html';
import {
    translateFromModel,
    translateToString,
} from '@splunk/olly-services/lib/services/AWSFilterTranslationService/AWSFilterTranslationService';

/* jshint camelcase: false */

angular.module('sfx.ui').directive('sfxAwsIntegration', [
    'sfxModal',
    '$window',
    'displayNamesWithMore',
    'migratedCredentialV2Service',
    'awsIntegrationService',
    'featureEnabled',
    'awsCredentialsHelperService',
    'PRODUCT_NAME',
    function (
        sfxModal,
        $window,
        displayNamesWithMore,
        migratedCredentialV2Service,
        awsIntegrationService,
        featureEnabled,
        awsCredentialsHelperService,
        PRODUCT_NAME
    ) {
        return {
            restrict: 'E',
            scope: true,
            require: '^form',
            templateUrl,
            link: function ($scope, element, attrs, ngForm) {
                let uiRepresentedSyncRule;
                let uiRepresentedCustomSyncRule;
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.nonAWSRegionCodes = [];
                const supportedNamespacesAndRegionsPromise = awsIntegrationService
                    .getSupportedNamespacesAndRegions()
                    .then(function (result) {
                        $scope.nonAWSRegionCodes = Object.keys(result.data.govcloud).concat(
                            Object.keys(result.data.china)
                        );
                        return result.data;
                    })
                    .catch(function () {
                        $window.alert('Failed trying to fetch supported AWS services');
                    });

                $scope.AUTH_METHOD = awsCredentialsHelperService.AUTH_METHOD;
                $scope.temp = {};
                $scope.allowAwsOptimizerUsage = featureEnabled('awsUsage');

                function reset() {
                    const credential = $scope.credential;

                    credential.roleArn = credential.roleArn || '';
                    credential.key = credential.key || '';
                    credential.token = credential.token || '';
                    if (credential.pollRate === '5 mins') {
                        credential.pollRate = 300000;
                    } else if (credential.pollRate === '1 min') {
                        credential.pollRate = 60000;
                    }
                    credential.pollRate = credential.pollRate || 300000;
                    credential.authMethod = awsCredentialsHelperService.getAuthMethod($scope);
                    credential.regions = credential.regions || [];
                    credential.services = credential.services || [];
                    credential.customCloudWatchNamespaces =
                        credential.customCloudWatchNamespaces || '';
                    $scope.regions = credential.regions.map((region) => {
                        return {
                            code: region,
                        };
                    });

                    if (credential.services && credential.services.length) {
                        $scope.filterOptions = 'FILTER';
                    } else {
                        $scope.filterOptions = '';
                    }

                    $scope.customNamespaceSyncRules = [];
                    if (
                        credential.customNamespaceSyncRules &&
                        credential.customNamespaceSyncRules.length
                    ) {
                        $scope.customNamespaceSyncRules = translateSyncRule(
                            credential.customNamespaceSyncRules
                        );
                    } else if (credential.customCloudWatchNamespaces) {
                        // fallback for legacy customNamespaces. Transform into new structure of
                        // having filters with given namespaces
                        const customNamespaces =
                            credential.customCloudWatchNamespaces.split(/,\s*/);
                        _.uniq(customNamespaces).forEach((namespace) => {
                            $scope.customNamespaceSyncRules.push({
                                name: namespace,
                                filter: {
                                    tags: [],
                                    metrics: [],
                                },
                            });
                        });
                    }

                    awsIntegrationService.getAllRegions().then(function (allRegions) {
                        $scope.regions = credential.regions.map((region) => {
                            return {
                                code: region,
                                name: allRegions[region],
                            };
                        });
                    });

                    resetServices();

                    if (!credential.regions) {
                        credential.regions = [];
                    }

                    $scope.temp.key = '';
                    $scope.temp.token = '';

                    if (!credential.name) {
                        credential.name = 'Amazon Web Services';
                    }
                    $scope.sharedCredentialState.updateCredentials = false;
                }

                function resetServices() {
                    supportedNamespacesAndRegionsPromise.then(function (result) {
                        $scope.cachedNamespaces = result.namespaces;
                        $scope.namespaceSyncRules = [];
                        if (
                            $scope.credential.namespaceSyncRules &&
                            $scope.credential.namespaceSyncRules.length
                        ) {
                            $scope.namespaceSyncRules = translateSyncRule(
                                $scope.credential.namespaceSyncRules
                            );
                        } else {
                            // fallback for legacy services. Transform into new structure of
                            // having filters with given namespaces
                            _.forEach($scope.credential.services, (service) => {
                                $scope.namespaceSyncRules.push({
                                    name: service,
                                    filter: {
                                        tags: [],
                                        metrics: [],
                                    },
                                });
                            });
                        }
                    });
                }

                function translateSyncRule(syncRules) {
                    const namespaceSyncRules = [];
                    const filterStrings = syncRules.map((syncRule) => {
                        if (syncRule.filter) {
                            return syncRule.filter.source;
                        }
                        return null;
                    });

                    migratedCredentialV2Service
                        .transformSignalFlow(filterStrings)
                        .then((signalFlowModels) => {
                            signalFlowModels.forEach((signalFlowModel, idx) => {
                                const syncRule = syncRules[idx];
                                if (!syncRule.filter) {
                                    namespaceSyncRules.push({
                                        name: syncRule.namespace,
                                        filter: {
                                            tags: [],
                                            metrics: [],
                                            action: '',
                                        },
                                    });
                                } else {
                                    try {
                                        const { tags, metrics } =
                                            translateFromModel(signalFlowModel);
                                        // currently only one action is supported. But in the future,
                                        // we might support multiple actions for the filtering
                                        const action = syncRule.filter.action;
                                        namespaceSyncRules.push({
                                            name: syncRule.namespace,
                                            filter: {
                                                tags: tags,
                                                metrics: metrics,
                                                action: action,
                                                defaultAction: syncRule.defaultAction,
                                            },
                                        });
                                    } catch (e) {
                                        namespaceSyncRules.push({
                                            name: syncRule.namespace,
                                            filter: {
                                                signalflow: filterStrings[idx],
                                                action: syncRule.filter.action,
                                                defaultAction: syncRule.defaultAction,
                                            },
                                        });
                                    }
                                }
                            });
                        });
                    return namespaceSyncRules;
                }

                $scope.$on('reset', reset);
                reset();

                $scope.editAwsServices = function () {
                    const modal = sfxModal.open({
                        templateUrl: integrationServicesTemplateUrl,
                        controller: 'integrationServices',
                        resolve: {
                            selected: function () {
                                return supportedNamespacesAndRegionsPromise.then(function (result) {
                                    return $scope.credential.services.map((s) => ({
                                        code: s,
                                        name: result.namespaces[s],
                                    }));
                                });
                            },
                            editable: function () {
                                return $scope.editable;
                            },
                            serviceToName: supportedNamespacesAndRegionsPromise.then(function (
                                result
                            ) {
                                return result.namespaces;
                            }),
                            params: function () {
                                return {
                                    modalTitle: 'Select Service Metrics to Import',
                                    syncAllOptionText: 'Sync all supported services',
                                    syncSelectedText: 'Sync only selected services',
                                    syncAllHelpText:
                                        'Imports metrics from all supported services, updating as new services are added',
                                };
                            },
                        },
                        backdrop: 'static',
                        keyboard: false,
                    });

                    modal.result.then((r) => {
                        setCloudWatchServices(r, true);
                    });
                };

                function regionMapper(regionsArr) {
                    return regionsArr.map((region) => region.code);
                }

                function isNotAWSRegion() {
                    return awsIntegrationService.isRegionSelectedByUser(
                        regionMapper($scope.regions),
                        $scope.nonAWSRegionCodes
                    );
                }

                $scope.shouldUseSecurityToken = isNotAWSRegion();

                $scope.editAwsRegions = function () {
                    const modal = sfxModal.open({
                        templateUrl: awsIntegrationRegionsTemplateUrl,
                        controller: 'awsIntegrationRegions',
                        resolve: {
                            selected: function () {
                                return awsIntegrationService
                                    .getAllRegions()
                                    .then(function (allRegions) {
                                        return $scope.credential.regions.map((s) => ({
                                            code: s,
                                            name: allRegions[s],
                                        }));
                                    });
                            },
                            editable: function () {
                                return $scope.editable;
                            },
                            awsRegions: supportedNamespacesAndRegionsPromise.then(function (
                                result
                            ) {
                                return result.regions;
                            }),
                            govCloudRegions: supportedNamespacesAndRegionsPromise.then(function (
                                result
                            ) {
                                return result.govcloud;
                            }),
                            chinaRegions: supportedNamespacesAndRegionsPromise.then(function (
                                result
                            ) {
                                return result.china;
                            }),
                        },
                        backdrop: 'static',
                        keyboard: false,
                    });

                    modal.result.then(function (regions) {
                        $scope.regions = regions;
                        $scope.shouldUseSecurityToken = isNotAWSRegion();

                        if ($scope.shouldUseSecurityToken) {
                            $scope.credential.authMethod =
                                awsCredentialsHelperService.AUTH_METHOD.SECURITY_TOKEN;
                        }
                    });
                };

                $scope.onNamespaceSyncRulesUpdated = function (services) {
                    uiRepresentedSyncRule = angular.copy(services);
                };

                $scope.onCustomNamespaceSyncRulesUpdated = function (customServices) {
                    uiRepresentedCustomSyncRule = angular.copy(customServices);
                };

                function convertServicesToSyncRules(services) {
                    services = services.filter((service) => service.name);
                    return _.map(services, (serviceObj) => {
                        const serviceName = serviceObj.name;
                        const uiFilter = serviceObj.filter;
                        const syncRule = {};
                        syncRule.namespace = serviceName;
                        if (uiFilter.action) {
                            const filter = {};
                            filter.action = uiFilter.action;
                            const tags = (uiFilter.tags || []).map((tag) => tag.name);
                            const metrics = (uiFilter.metrics || []).map((metric) => metric.name);
                            filter.source = translateToString(tags, metrics);
                            syncRule.filter = filter;
                            syncRule.defaultAction = uiFilter.defaultAction;

                            if (uiFilter.signalflow) {
                                filter.source = uiFilter.signalflow;
                            }
                        }
                        return syncRule;
                    });
                }

                $scope.toggleImportCloudWatch = function () {
                    $scope.credential.importCloudWatch = !$scope.credential.importCloudWatch;

                    if ($scope.credential.importCloudWatch) {
                        $scope.credential.pollRate = $scope.credential.pollRate || 300000;
                    }

                    ngForm.$setDirty(true);
                };

                $scope.toggleImportUsage = function () {
                    $scope.credential.enableAwsUsage = !$scope.credential.enableAwsUsage;

                    ngForm.$setDirty(true);
                };

                $scope.$watch('temp.key', () =>
                    awsCredentialsHelperService.updateCredentials($scope)
                );

                $scope.$watch('temp.token', () =>
                    awsCredentialsHelperService.updateCredentials($scope)
                );

                $scope.$watch('credential.roleArn', () =>
                    awsCredentialsHelperService.updateCredentials($scope)
                );

                $scope.$watchCollection('regions', function (newValue) {
                    if (newValue === undefined) return;

                    const oldCodes = $scope.credential.regions;
                    const newCodes = newValue.map((region) => {
                        return region.code;
                    });
                    if (!angular.equals(oldCodes, newCodes)) {
                        $scope.credential.regions = newCodes;
                        ngForm.$setDirty(true);
                    }

                    if (newValue.length === 0) {
                        $scope.awsRegionsText = 'All regions';
                    } else {
                        $scope.awsRegionsText = displayNamesWithMore.shortenNames(newValue);
                    }
                });

                function setCloudWatchServices(newValue, checkDirty) {
                    if (newValue === undefined) return;

                    const oldOptions = $scope.credential.services;
                    const newOptions = newValue.map((service) => {
                        return service.code;
                    });
                    if (checkDirty && !angular.equals(oldOptions, newOptions)) {
                        ngForm.$setDirty(true);
                    }
                    $scope.credential.services = newOptions;

                    if (newValue.length === 0) {
                        $scope.awsServicesText = 'All services';
                    } else {
                        $scope.awsServicesText = displayNamesWithMore.shortenNames(newValue);
                    }
                }

                $scope.$watch('credential.authMethod', (newValue, oldValue) =>
                    awsCredentialsHelperService.setCredentialsDependingOnAuthMethod(
                        $scope,
                        newValue,
                        oldValue
                    )
                );

                $scope.$watch('credential.pollRate', function (newValue, oldValue) {
                    if (newValue === oldValue) return;
                    if (newValue !== undefined) {
                        ngForm.$setDirty(true);
                    }
                });

                $scope.$watch('filterOptions', (newVal, oldVal) => {
                    if (newVal === '') {
                        $scope.credential.namespaceSyncRules = [];
                        $scope.credential.services = [];
                        resetServices();
                    } else if (newVal !== oldVal && newVal === 'FILTER') {
                        $scope.$broadcast('toggle non-custom metric filtering services dropdown');
                    }
                });

                $scope.$on('prepare credential for save', () => {
                    $scope.credential.enableCheckLargeVolume = !!$scope.credential.isNewIntegration;

                    $scope.credential.namespaceSyncRules =
                        convertServicesToSyncRules(uiRepresentedSyncRule);
                    $scope.credential.customNamespaceSyncRules = convertServicesToSyncRules(
                        uiRepresentedCustomSyncRule
                    );
                });

                $scope.$on('toggled select services dropdown', () => {
                    $scope.filterOptions = 'FILTER';
                });
            },
        };
    },
]);
