export default [
    '$http',
    '$log',
    'createV2Service',
    function ($http, $log, createV2Service) {
        const api = createV2Service('suggest');

        // unsupported v2 api methods
        api.create = unsupportedMethod;
        api.update = unsupportedMethod;
        api.get = unsupportedMethod;
        api.search = unsupportedMethod;
        api.delete = unsupportedMethod;

        // supported v2 api methods
        api.getSignalFlowSuggest = getSignalFlowSuggest;
        api.getMetricCategories = getMetricCategories;
        api.getMetricGroupSuggest = getMetricGroupSuggest;

        return api;

        function getSignalFlowSuggest({
            programs = [],
            property = '',
            partialInput,
            limit = 10000,
            additionalFilters = [],
            additionalReplaceOnlyFilters = [],
            preferredSuggestions = [],
            restrictResultsToPreferred = false,
            extraQuery = null,
            startTime = 0,
            endTime = 0,
        } = {}) {
            return $http
                .post(api.resourceEndpoint + '/_signalflowsuggest2', {
                    programs,
                    property,
                    partialInput,
                    limit,
                    additionalFilters,
                    additionalReplaceOnlyFilters,
                    preferredSuggestions,
                    restrictResultsToPreferred,
                    startTime,
                    endTime,
                    additionalQuery: extraQuery,
                })
                .then((result) => result.data);
        }

        function getMetricGroupSuggest({
            programs,
            property,
            partialInput,
            limit,
            additionalFilters,
            additionalReplaceOnlyFilters,
            additionalQuery,
        } = {}) {
            return $http
                .post(api.resourceEndpoint + '/_metricgroupsuggest', {
                    programs,
                    property,
                    partialInput,
                    limit,
                    additionalFilters,
                    additionalReplaceOnlyFilters,
                    additionalQuery,
                })
                .then((result) => result.data);
        }

        function getMetricCategories({ programText } = {}) {
            return $http
                .post(api.resourceEndpoint + '/_metriccategory', { programText })
                .then((result) => result.data);
        }

        function unsupportedMethod() {
            $log.info('This method is NOT supported by this v2 endpoint.');
        }
    },
];
