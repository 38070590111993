export default [
    function () {
        if (window.signalviewConfig.suppressOrgDomainSwitching) {
            return function (org) {
                return `#/home/${org.id}`;
            };
        } else {
            return function (org) {
                return `${org.url}/#/home/${org.id}`;
            };
        }
    },
];
