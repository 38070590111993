import templateUrl from './loginCorpHeader.tpl.html';

export default {
    templateUrl,
    bindings: {
        ariaLabel: '<?',
        role: '<?',
    },
    controller: [
        'DOMAIN_INFO',
        function (DOMAIN_INFO) {
            const $ctrl = this;

            $ctrl.hasDomain = !!DOMAIN_INFO;
            if (DOMAIN_INFO) {
                $ctrl.fullDomain = DOMAIN_INFO.fullDomain;
            }
        },
    ],
};
