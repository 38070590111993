angular.module('sfx.api.v2').service('getOrgScopeErrorHandler', [
    'autoSwitchOrgWhen403',
    'autoRedirectWhenHttpError',
    function (autoSwitchOrgWhen403, autoRedirectWhenHttpError) {
        /**
         * This service returns a function which takes an objectType as argument,
         * and returns a function that takes in params
         * of the form
         * { fn: originating-function, id: object-id to fetch, error: last-error }
         * and switches org to object-id, if user has access
         * and refetches object
         * otherwise returns last error
         */

        return function (objectType) {
            return function (params) {
                return autoSwitchOrgWhen403(params.error, objectType, params.id)
                    .then(() => params.fn(params.id))
                    .catch(autoRedirectWhenHttpError);
            };
        };
    },
]);

export const DASHBOARD_TYPE = 'dashboard';
export const CHART_TYPE = 'chart';
export const SNAPSHOT_TYPE = 'shareablesnapshot';
export const DETECTOR_TYPE = 'detector';
