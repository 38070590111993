import { react2angular } from 'react2angular';

import OrganizationPreferences from './OrganizationPreferences';

angular
    .module('signalview.generalOrganizationSettings', [])
    .component(
        'organizationPreferences',
        react2angular(OrganizationPreferences, ['editable'], ['featureEnabled'])
    );
