angular
    .module('signalview.selectionFilter', [])

    .provider('selectionFilter', function () {
        function SelectionFilter() {
            // -- public -- //
            let $q = null;
            // args { criteria: , implicit_active: , types : [  { name: , key: , active: , generator: } ... ] }
            // generator is of signature (type, query), should return a promise and resolve generated samples
            this.start = function (args) {
                $q = args.q;
                this.pause = true;
                this.offset = args.offset || 0;
                this.limit = args.limit || 50;
                this.callbackFn = args.callback || null;
                this.setCriteria(args.criteria || '');
                this.implicitactive = args.implicitactive || false;
                this.numsamples = {};
                this.numselected = 0;
                const _this = this;
                angular.forEach(args.types, function (t) {
                    const typename = t.name || '';
                    _this.setTypeActive(typename, t.active || false);
                    _this.setTypeKey(typename, t.key || '');
                    _this.setSampleCounter(typename, t.counter || null);
                    _this.setSampleGenerator(typename, t.generator || null);
                });
                if (args.defaultTypes) {
                    this.setTypesActive(args.defaultTypes);
                }
                this.pause = false;
                this.refresh();
            };

            // set generation criteria when modified
            this.setCriteria = function (criteria, force) {
                this.lastcriteria = this.criteria;
                this.criteria = criteria;
                if (!this.isSameCriteria() || force) {
                    this.invalidateCriteria();
                }
            };

            this.getFromOffset = function (offset) {
                if (offset < 0 || offset > this.getNumSamples() - 1) {
                    return;
                }
                this.offset = offset;
                this.sync++;
                this.samples = {};
                this.generateTypeSamples(this.sync);
            };

            // stop sample generation
            this.stop = function () {
                this.pause = true;
            };

            // resume sample generation
            this.resume = function () {
                this.pause = false;
            };

            // refresh samples
            this.refresh = function () {
                this.pause = false;
                this.invalidateCriteria();
            };

            // get all generated samples with types samples[type] = [sample set]
            this.getAllSamples = function () {
                return this.samples;
            };

            // get all selected samples with types selected[type] = [selection set]
            this.getAllSelected = function () {
                return this.selected;
            };

            // get counts
            this.getNumSamples = function () {
                let count = 0;
                angular.forEach(this.numsamples, function (s) {
                    count += s;
                });
                return count;
            };
            this.getNumTypeSamples = function (type) {
                return this.numsamples[type] || 0;
            };
            this.getNumSelected = function () {
                return this.getCount(this.selected);
            };
            this.getNumTypeSelected = function (type) {
                return this.getTypeCount(type, this.selected.length);
            };

            // set all samples as selected or unselected
            this.setAllSelected = function (flag) {
                const _this = this;
                this.numselected = 0;
                angular.forEach(this.types, function (t) {
                    _this.setTypeSelected(t, flag);
                });
            };

            // toggle type active
            this.toggleTypeActive = function (type) {
                const curractive = this.getTypeNonimplicitActive(type);
                this.setTypeActive(type, !curractive);
            };

            // toggle select
            this.toggleSelect = function (type, key) {
                this.setSelected(type, key, !this.getSelected(type, key));
            };

            // toggle exclusive select
            this.toggleExclusiveSelect = function (type, key) {
                const currently_selected = this.getSelected(type, key);
                const numselected = this.getNumSelected();
                this.removeAllSelected();
                this.setSelected(type, key, numselected > 1 ? true : !currently_selected);
            };

            // get selected status of a specific sample (by unique key) for a type
            this.getSelected = function (type, key) {
                if (this.selected[type] && this.selected[type][key]) {
                    return true;
                }
                return false;
            };

            // get selected status of a specific sample (by unique key) for a type
            this.getExclusiveSelected = function (type, key) {
                if (this.getNumSelected() > 1) {
                    return false;
                }
                return this.getSelected(type, key);
            };

            this.getInexclusiveSelected = function (type, key) {
                if (this.getNumSelected() < 2) {
                    return false;
                }
                return this.getSelected(type, key);
            };

            // set active status of type for generation
            this.setTypeActive = function (type, active, skipInvalidate) {
                let found = false;
                const previousactive = this.activetypes.length;
                const _this = this;
                angular.forEach(this.activetypes, function (t, i) {
                    if (t === type) {
                        if (!active) {
                            _this.activetypes.splice(i, 1);
                        }
                        found = true;
                    }
                });
                if (!found) {
                    angular.forEach(this.types, function (t) {
                        if (t === type) {
                            found = true;
                        }
                    });
                    if (!found) {
                        this.types.push(type);
                        found = true;
                    }
                    if (active) {
                        this.activetypes.push(type);
                    }
                }
                if (!skipInvalidate && previousactive !== this.activetypes.length) {
                    this.invalidateCriteria();
                }
            };

            // -- public - less common use-cases  -- //

            // set a sample (by unique sample key) for type as seleted
            this.setSelected = function (type, key, selected) {
                if (selected && this.selected) {
                    if (!this.selected[type]) {
                        this.selected[type] = {};
                    }
                    this.numselected++;
                    this.selected[type][key] = true;
                } else if (this.selected[type]) {
                    if (
                        angular.isDefined(this.selected[type][key]) &&
                        this.selected[type][key] === true
                    ) {
                        this.numselected--;
                    }
                    delete this.selected[type][key];
                    if (Object.keys(this.selected[type]).length === 0) {
                        this.removeSelected(type);
                    }
                }
            };

            // set sample types
            this.setTypes = function (types) {
                this.types = types;
            };

            // set implicit active mode, i.e. no explicit active ==> all active
            this.setImplicitActive = function (flag) {
                this.implicitactive = flag;
            };

            // generator should have a signature of type and criteria ,
            //   and return a promise that resolves/generates samples
            // generator can be null
            this.setSampleGenerator = function (type, generator) {
                if (generator) {
                    this.generators[type] = generator;
                } else {
                    delete this.generators[type];
                }
            };

            this.setSampleCounter = function (type, counter) {
                if (counter) {
                    this.counters[type] = counter;
                } else {
                    delete this.counters[type];
                }
            };

            // get sample types
            this.getTypes = function () {
                return this.types;
            };

            // get sample generator
            this.getSampleGenerator = function (type) {
                return this.generators[type] || null;
            };

            // get sample counter
            this.getSampleCounter = function (type) {
                return this.counters[type] || null;
            };

            // get criteria
            this.getCriteria = function () {
                return this.criteria;
            };

            // get non implicit active
            this.getTypeNonimplicitActive = function (type) {
                let found = false;
                angular.forEach(this.activetypes, function (t) {
                    if (t === type) {
                        found = true;
                    }
                });
                return found;
            };

            // set active status of type for generation
            this.getTypeActive = function (type) {
                if (this.activetypes.length === 0) {
                    return this.implicitactive;
                }
                return this.getTypeNonimplicitActive(type);
            };

            // get all active types for generation
            this.getAllActive = function (explicit) {
                const _this = this;
                if (this.activetypes.length > 0) {
                    const tmpTypes = [];
                    angular.forEach(this.types, function (t) {
                        if (_this.getTypeNonimplicitActive(t)) {
                            tmpTypes.push(t);
                        }
                    });
                    return tmpTypes;
                } else {
                    return angular.copy(this.implicitactive && !explicit ? this.types : []);
                }
            };

            // set types active
            this.setTypesActive = function (types) {
                const self = this;
                this.activetypes = [];
                types.forEach(function (type) {
                    self.setTypeActive(type, true, true);
                });
            };

            // set generated sample for type
            this.setSample = function (type, sample) {
                const newlength = sample ? sample.length : 0;
                if (newlength) {
                    this.samples[type] = sample;
                    this.updateReverseMap(type);
                } else {
                    this.removeSample(type);
                    this.updateReverseMap(type);
                }
            };

            // get generated sample for type
            this.getSample = function (type) {
                if (this.samples[type]) {
                    return this.samples[type];
                }
                return {};
            };

            // remove generated sample for type
            this.removeSample = function (type) {
                delete this.samples[type];
                this.removeSelected(type);
                this.updateReverseMap(type);
            };

            // set all samples of specific type as selected
            this.setTypeSelected = function (type, selected) {
                if (this.samples[type]) {
                    const s = this.samples[type];
                    if (s.length) {
                        const key = this.getTypeKey(type);
                        const _this = this;
                        angular.forEach(s, function (k) {
                            const curr = _this.getSelected(type, k[key]);
                            if (selected && !curr) {
                                _this.numselected++;
                                _this.setSelected(type, k[key], true);
                            } else if (!selected && curr) {
                                _this.numselected--;
                                _this.setSelected(type, k[key], false);
                            }
                        });
                    }
                }
            };

            // get all selected samples for a type
            this.getTypeSelected = function (type) {
                if (this.selected[type]) {
                    return this.selected[type];
                }
                return {};
            };

            // remove all selected status of samples for type
            this.removeSelected = function (type) {
                delete this.selected[type];
            };

            // remove all generated samples for all types
            this.removeAllSamples = function () {
                this.numsamples = {};
                delete this.samples;
                this.samples = {};
                this.key2sample = {};
                this.removeAllSelected();
            };

            // remove all selected status of samples for all types
            this.removeAllSelected = function () {
                this.numselected = 0;
                delete this.selected;
                this.selected = {};
            };

            // selected samples per active type
            this.selected = {};
            // samples per active type
            this.samples = {};
            // reverse map (need to refactor this perhaps)
            this.key2sample = {};

            // print stats
            this.dump = function () {
                console.log('-----  selection filter stats -----');
                console.log('types : ' + this.types.join(' , '));
                console.log('active types : ' + this.getAllActive().join(' , '));
                console.log('num samples : ' + this.getNumSamples());
                console.log('num selected : ' + this.getNumSelected());
            };

            // -- internal -- //

            this.criteria = '';
            this.lastcriteria = '';
            this.types = [];
            this.activetypes = [];
            this.generators = {};
            this.counters = {};
            this.key = {};
            this.pause = true;
            this.limit = 50;
            this.offset = 0;
            this.callbackFn = null;
            this.implicitactive = false;
            this.numsamples = {};
            this.numselected = 0;
            this.sync = 0;

            this.invalidateCriteria = function () {
                this.generateSamples();
                this.lastcriteria = this.criteria;
            };

            this.isSameCriteria = function () {
                return this.lastcriteria === this.criteria && this.criteria !== '';
            };

            this.allSelected = function () {
                let numUnselected = 0;
                let numSelected = 0;
                const _this = this;
                angular.forEach(this.samples, function (s, type) {
                    if (numUnselected === 0) {
                        const typekey = _this.getTypeKey(type);
                        angular.forEach(s, function (v) {
                            if (!_this.getSelected(type, v[typekey])) {
                                numUnselected++;
                            } else {
                                numSelected++;
                            }
                        });
                    }
                });
                return numUnselected === 0 && numSelected > 0;
            };

            this.generateTypeSamples = function (currSync) {
                const _this = this;
                const activeTypes = this.getAllActive();
                const reloffs = {};
                let off = this.offset;
                let lim = this.limit;
                angular.forEach(activeTypes, function (type) {
                    const count = _this.numsamples[type];
                    if (off > count) {
                        off -= count;
                    } else {
                        reloffs[type] = {
                            offset: off,
                            limit: count - off,
                        };
                        off = 0;
                        if (reloffs[type].limit > lim) {
                            reloffs[type].limit = lim;
                            lim = 0;
                        } else {
                            lim -= reloffs[type].limit;
                        }
                    }
                });
                const generated = [];
                angular.forEach(reloffs, function (offs, type) {
                    const generator = _this.getSampleGenerator(type);
                    if (offs.limit > 0) {
                        let g = null;
                        if (generator) {
                            g = generator(type, _this.criteria, offs.offset, offs.limit).then(
                                function (sample) {
                                    if (_this.sync === currSync) {
                                        _this.setSample(type, sample);
                                    }
                                    return true;
                                }
                            );
                        }
                        if (g && _this.callbackFn) {
                            generated.push(g);
                        }
                    }
                });
                if (generated.length) {
                    $q.all(generated).then(function () {
                        if (_this.sync === currSync) {
                            _this.callbackFn.onSample(_this.samples);
                        }
                    });
                } else if (_this.callbackFn) {
                    _this.callbackFn
                        .onNoData(_this.criteria, _this.offset, _this.limit)
                        .then(function (data) {
                            if (_this.sync === currSync) {
                                angular.forEach(data, function (groupedSample, type) {
                                    _this.setSample(type, groupedSample);
                                });
                            }
                        });
                }
            };

            this.generateCounts = function (currSync) {
                const p = $q.defer();
                const activeTypes = this.getAllActive();
                let count = activeTypes.length;
                const _this = this;
                angular.forEach(activeTypes, function (type) {
                    const counter = _this.getSampleCounter(type);
                    counter(type, _this.criteria).then(function (objCount) {
                        if (_this.sync === currSync) {
                            _this.numsamples[type] = objCount;
                        }
                        count--;
                        if (count === 0) {
                            p.resolve(true);
                        }
                    });
                });
                return p.promise;
            };

            this.generateSamples = function () {
                if (this.pause) {
                    return;
                }
                this.sync++;
                this.offset = 0;
                this.removeAllSamples();
                const _this = this;
                this.generateCounts(this.sync).then(function () {
                    _this.generateTypeSamples(_this.sync);
                });
            };

            this.getCount = function (coll) {
                let count = 0;
                const _this = this;
                angular.forEach(coll, function (c, i) {
                    count += _this.getTypeCount(i, coll);
                });
                return count;
            };

            this.getTypeCount = function (type, ary) {
                return ary[type] ? ary[type].length || Object.keys(ary[type]).length : 0;
            };

            this.setTypeKey = function (type, key) {
                this.key[type] = key;
            };
            this.getTypeKey = function (type) {
                return this.key[type] || '';
            };

            this.getReverseMap = function () {
                return this.key2sample;
            };

            this.updateReverseMap = function (type) {
                if (!this.samples[type]) {
                    delete this.key2sample[type];
                    return;
                }
                const typekey = this.getTypeKey(type);
                const revmap = this.key2sample[type] || {};
                const samplemap = this.samples[type];
                angular.forEach(samplemap, function (o) {
                    revmap[o[typekey]] = o;
                });
                this.key2sample[type] = revmap;
            };
        }

        function SelectionFilterWrap() {
            this.get = function () {
                return new SelectionFilter();
            };
        }

        this.$get = function () {
            return new SelectionFilterWrap();
        };
    });
