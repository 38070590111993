import templateUrl from './objectconfig.tpl.html';
import defaultCatalogOverviewTemplateUrl from './defaultCatalogOverview.tpl.html';

angular.module('signalview.objectmanager').directive('objectConfig', function () {
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            objs: '=', // selected objects
            objmap: '=', // all objects
            update: '=',
            viewMode: '=?',
            filters: '=',
            availablePlugins: '=',
            isNewCatalog: '=',
        },
        templateUrl,
        controller: [
            '$q',
            '$scope',
            'zeroStateService',
            'organizationService',
            'signalboost',
            'v2ObjectService',
            'migratedSignalboost',
            function (
                $q,
                $scope,
                zeroStateService,
                organizationService,
                signalboost,
                v2Objects,
                migratedSignalboost
            ) {
                $scope.defaultCatalogOverviewTemplateUrl = defaultCatalogOverviewTemplateUrl;
                $scope.selectedObjects = [];

                // Used in the ng-include of defaultCatalogObject
                organizationService.get().then((org) => ($scope.org = org));

                $scope.zeroStateService = zeroStateService;

                function selectedType() {
                    const types = Object.keys($scope.objs);

                    if (types.length === 1) {
                        return types[0];
                    } else {
                        return 'Multiple Type';
                    }
                }

                function getSelectedObjects() {
                    const total = $scope.selectedObjectCount;

                    if (total === 1) {
                        // Single select existing object
                        const type = Object.keys($scope.objs)[0];
                        const objectId = Object.keys($scope.objs[type])[0];

                        if (objectId === 'FAKE') return $q.when([$scope.objs[type][objectId]]);
                        const isV2Type = v2Objects.isV2Type(type);
                        let promise = null;
                        if (isV2Type) {
                            promise = v2Objects.get(type, objectId).catch(function () {
                                // not logging for now as all v1 detectors will encounter this path
                                return signalboost.types[type].one(objectId).get();
                            });
                        } else if (type === 'metric' || type === 'dimension') {
                            promise = migratedSignalboost[type].get(`/${objectId}`);
                        } else {
                            promise = signalboost.types[type].one(objectId).get();
                        }
                        return promise.then(function (data) {
                            return [data];
                        });
                    } else if (total > 1) {
                        const objects = [];
                        angular.forEach($scope.objs, function (typeObjects, type) {
                            Object.keys(typeObjects).map(function (objectId) {
                                objects.push($scope.objmap[type][objectId]);
                            });
                        });
                        return $q.when(objects);
                    } else {
                        return $q.when([]);
                    }
                }

                function reset() {
                    $scope.selectedObjectCount = 0;

                    angular.forEach($scope.objs, function (typeObjects) {
                        $scope.selectedObjectCount += Object.keys(typeObjects).length;
                    });

                    $scope.selectedType = selectedType();
                    $scope.loading = true;
                    getSelectedObjects()
                        .then(function (selectedObjects) {
                            $scope.selectedObjects = selectedObjects;
                            $scope.singleObject = selectedObjects.length === 1;
                        })
                        .finally(function () {
                            $scope.loading = false;
                        });
                }

                $scope.$watch('update', reset);
            },
        ],
    };
});
