export default function useExitGuard(service, onSave, onDiscard, isValid, enabled) {
    const exitGuard = service.create({ onSave, onDiscard, isValid });

    if (enabled) {
        exitGuard.enable();
    } else {
        exitGuard.disable();
    }

    return exitGuard;
}
