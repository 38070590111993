import LoginController from './LoginController';
import loginForm from './loginForm';
import googleAuth from './googleAuth';
import corpFloatingLogo from './corpFloatingLogo';
import loginCorpHeader from './loginCorpHeader';
import SigninOrganization from './SigninOrganization';
import ssoRedirect from './ssoRedirect';
import pathValidateService from '../../../../common/security/pathValidateService';
import login from './login';
import emailVerification from './emailVerification';
import otpVerification from './otpVerification';
import roleValidationError from './roleValidationError';

angular
    .module('sfx.security.login', ['signalboost', 'signalview.consts', 'signalview.userAnalytics'])
    .controller('LoginController', LoginController)
    .component('signinOrganization', SigninOrganization)
    .service('googleAuth', googleAuth)
    .service('ssoRedirect', ssoRedirect)
    .constant('pathValidateService', pathValidateService)
    .directive('loginForm', loginForm)
    .component('corpFloatingLogo', corpFloatingLogo)
    .component('loginCorpHeader', loginCorpHeader)
    .component('login', login)
    .component('emailVerification', emailVerification)
    .component('otpVerification', otpVerification)
    .component('roleValidationError', roleValidationError);
