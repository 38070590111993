import React, { FC } from 'react';
import { Route, Switch, useInitializeRoute } from './ReactRouteUtils';
import { ngRoute } from './ngRoute';
import { Ng2React } from '../../common/util/Ng2React';
import dashboardPage from '../../legacy/app/dashboard/dashboardPage';
import { clone } from 'lodash';
import dashboardIndexPage from '../../legacy/app/dashboardIndex/dashboardIndexPage';
import { AngularInjection } from '../../common/AngularUtils';

const dashboardSnapshotResolve: AngularInjection = [
    'snapshotLoadService',
    'currentUser',
    'newDashboardService',
    'dashboardV2Service',
    function (
        snapshotLoadService,
        currentUser,
        newDashboardService,
        dashboardV2Service
    ): Promise<any> {
        const snapshotId = ngRoute.params.snapshotID;
        return snapshotLoadService(snapshotId).then(function (snapshot: any) {
            if (!snapshot) {
                return {};
            }

            const data = clone(snapshot.payload);
            if (snapshot.type === 'Chart') {
                ngRoute.history?.replace(`/temp/chart/${snapshot.id}`);
                return;
            }

            currentUser.orgPreferences().then(function (prefs: any) {
                // Unlikely that the user will know the snapshot id after the new
                // dashboard has been modified, since the id changes after each
                // edit, but just in case...
                if (prefs.sf_newDashboard === snapshotId) {
                    const update = {
                        sf_numUnseenCharts: 0,
                    };
                    newDashboardService.updateNewDashboardInfo(update);
                }
            });
            data.dashboard = dashboardV2Service.apiDashboardToUI(data.dashboard);
            const dashboard = clone(data.dashboard);
            return {
                dashboard: dashboard,
                alldashboards: [data.dashboard],
                charts: data.charts,
                snapshot: {
                    id: snapshot.id,
                    type: snapshot.type,
                    sourceId: snapshot.sourceId,
                    expiryMs: snapshot.expiryMs,
                    creator: snapshot.creator,
                    created: snapshot.created,
                    lastUpdatedBy: snapshot.lastUpdatedBy,
                    lastUpdated: snapshot.lastUpdated,
                    payload: snapshot.payload,
                    sender: snapshot.payload.originalSender || snapshot.sender,
                },
            };
        });
    },
];

const homepage: AngularInjection = [
    'dashboardV2Service',
    function (dashboardV2Service): Promise<any> {
        const homepageInfo = dashboardV2Service.getHomepage();
        return homepageInfo;
    },
];

export const DashboardRoutes: FC = () => {
    const routeUtilsBootStrap = useInitializeRoute();

    return (
        <Switch>
            <Route
                exact
                path={['/dashboards', '/dashboards/:orgID']}
                title="Dashboards"
                straps={[routeUtilsBootStrap]}
            >
                <Ng2React name="dashboardIndexPage" definition={dashboardIndexPage} />
            </Route>

            <Route
                exact
                path={['/page/:groupId', '/dashboard/:dashboardID']}
                title="Dashboard"
                straps={[routeUtilsBootStrap]}
                reloadWithParams="path"
                resolves={{
                    data: (): any => ({
                        groupId: ngRoute.params.groupId,
                        dashboardId: ngRoute.params.dashboardID,
                        configId: ngRoute.params.configId,
                    }),
                }}
            >
                <Ng2React name="dashboardPage" definition={dashboardPage} />
            </Route>

            <Route
                exact
                path="/temp/dashboard/:snapshotID"
                title="Dashboard"
                straps={[routeUtilsBootStrap]}
                reloadWithParams="path"
                resolves={{ data: dashboardSnapshotResolve }}
            >
                <Ng2React name="dashboardPage" definition={dashboardPage} />
            </Route>

            <Route
                exact
                path="/home"
                title="Home"
                straps={[routeUtilsBootStrap]}
                resolves={{ data: homepage }}
            >
                <Ng2React name="dashboardPage" definition={dashboardPage} />
            </Route>
        </Switch>
    );
};
