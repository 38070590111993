import {
    getScalingUnits,
    getScaleForUnit,
    getUnitDefinition,
    scaleDown,
    scaleUp,
    scaleToBestUnit,
    shouldConvertSmaller,
    shouldConvertLarger,
} from './scalingUnitServiceModule';

export default [
    function () {
        return {
            getScalingUnits,
            getScaleForUnit,
            getUnitDefinition,
            scaleDown,
            scaleUp,
            scaleToBestUnit,
            shouldConvertSmaller,
            shouldConvertLarger,
        };
    },
];
