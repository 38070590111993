angular.module('signalview.chartbuilder').directive('modelChangeInjector', [
    // hack directive to force a render/apply of a new model value being set
    // for auto-suggest.  ideally we override/extend autosuggest to perform
    // this two-part suggest we utilize
    function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                scope.$on('setViewValue', function (ev, key, v, skipScope) {
                    if (
                        (skipScope || ev.currentScope === ev.targetScope) &&
                        key === attrs.modelChangeInjector
                    ) {
                        modelCtrl.$setViewValue(v);
                        modelCtrl.$render();
                    }
                });
            },
        };
    },
]);
