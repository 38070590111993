angular
    .module('signalview.detector')
    // SMOKEY_SEVERITY_LEVELS and HEATMAP_ALERT_SEVERITY_LEVELS are identical because we are making
    // a late breaking change.  This should be cleaned up as soon as possible.
    .constant('SMOKEY_SEVERITY_LEVELS', ['Critical', 'Major', 'Minor', 'Warning', 'Info', 'Normal'])
    .constant('ALERT_SEVERITY_LEVELS', ['Critical', 'Major', 'Minor', 'Warning', 'Info'])
    .constant('HEATMAP_ALERT_SEVERITY_LEVELS', [
        'Critical',
        'Major',
        'Minor',
        'Warning',
        'Info',
        'Normal',
    ]);
