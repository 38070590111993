import templateUrl from './singleSelectFilter.tpl.html';

export default {
    templateUrl,
    bindings: {
        filter: '<',
        remove: '&',
        updateFilter: '&',
        isActiveMetricsOnly: '<',
    },
    controller: [
        '$q',
        'metricsCatalogSearchUtil',
        function ($q, metricsCatalogSearchUtil) {
            const $ctrl = this;

            $ctrl.editingInPlace = false;

            $ctrl.editInPlace = editInPlace;
            $ctrl.reset = reset;
            $ctrl.getSuggestions = getSuggestions;
            $ctrl.filterSelected = filterSelected;

            function editInPlace($event) {
                $event.stopPropagation();
                $ctrl.editText = angular.copy($ctrl.filter.value);
                $ctrl.editingInPlace = true;
            }

            function reset() {
                $ctrl.editingInPlace = false;
            }

            function getSuggestions(inputText) {
                const filter = metricsCatalogSearchUtil.getSourceFilterFromString(inputText);
                const partialInput = filter.propertyValue;
                if (!!filter) {
                    return metricsCatalogSearchUtil.fetchSuggestions(
                        filter.property,
                        partialInput,
                        $ctrl.isActiveMetricsOnly
                    );
                } else {
                    return $q.when([]);
                }
            }

            function filterSelected(propertyValue) {
                const inputFilter = metricsCatalogSearchUtil.getSourceFilterFromString(
                    $ctrl.editText
                );
                const filter = {
                    NOT: inputFilter.NOT,
                    property: inputFilter.property,
                    propertyValue,
                    value: `${inputFilter.NOT ? '!' : ''}${inputFilter.property}:${propertyValue}`,
                };
                reset();
                $ctrl.updateFilter({ filter });
            }
        },
    ],
};
