import templateUrl from './confirm.tpl.html';

angular
    .module('signalview.utils')
    /**
     * Utility to show a confirmation of sorts.
     * params {
     *   title: title for confirmation,
     *   text: text for confirmation (list of strings),
     *   yesText: text for yes button (optional, default Yes, empty quotes to hide),
     *   noText: text for no button (optional, default No, empty quotes to hide),
     *   doNotShowPreferenceName: name of property for do not show in user prefs (optional, to show a don't show again checkbox)
     * }
     * returns a true/false value in the $close promise
     */
    .controller('ConfirmController', [
        'currentUser',
        '$log',
        '$scope',
        'params',
        '$sce',
        function (currentUser, $log, $scope, params, $sce) {
            $scope.yesText = params.yesText;
            $scope.noText = params.noText;
            $scope.title = params.title;
            $scope.text = params.text;
            if (params.template) {
                $scope.template = $sce.trustAsHtml(params.template);
            }
            $scope.danger = params.danger;
            $scope.preferenceName = params.doNotShowPreferenceName;
            $scope.doNotShow = false;

            function close(value) {
                if ($scope.doNotShow) {
                    currentUser
                        .preferences()
                        .then(function (prefs) {
                            const toUpdate = { sf_id: prefs.sf_id };
                            toUpdate[$scope.preferenceName] = true;
                            currentUser.updatePreferences(toUpdate);
                        })
                        .catch(function (e) {
                            $log.warn('Failed updating do not show ', $scope.preferenceName, e);
                        });
                }
                $scope.$close(value);
            }

            $scope.no = function () {
                close(false);
            };
            $scope.yes = function () {
                close(true);
            };
        },
    ])

    .service('confirmService', [
        'sfxModal',
        function (sfxModal) {
            return {
                confirm: function (params) {
                    return sfxModal.open({
                        templateUrl,
                        controller: 'ConfirmController',
                        resolve: {
                            params: function () {
                                return params;
                            },
                        },
                    }).result;
                },
            };
        },
    ]);
