angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    'plotUtils',
    function (chartTemplates, Chart, blockService, plotUtils) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Disk Free %')
                .mode('list')
                .type('line')
                .sort('+value')
                .maxDecimalPlaces(3)
                .updateInterval(60000);

            const dfComplexUsedPlot = chart
                .plot()
                .metric('df_complex.used')
                .visible(false)
                .propertyFilter('plugin', 'df')
                .propertyFilters(filters);

            const dfComplexFreePlot = dfComplexUsedPlot.cloneTo(chart).metric('df_complex.free');

            const usedPlotLetter = plotUtils.getLetterFromUniqueKey(dfComplexUsedPlot.uniqueKey());
            const freePlotLetter = plotUtils.getLetterFromUniqueKey(dfComplexFreePlot.uniqueKey());

            const macro = freePlotLetter + '/(' + usedPlotLetter + '+' + freePlotLetter + ')*100';

            chart
                .plot()
                .macro(macro)
                .name('')
                .addManipulation(blockService.mean.asAggregation([{ value: 'plugin_instance' }]))
                .addManipulation(blockService.bottomn.asAggregation());

            return chart;
        }

        chartTemplates.register('collectdDiskFree', createChart);
    },
]);
