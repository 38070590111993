angular.module('signalview.variables').service('dashboardVariablesService', [
    '_',
    '$log',
    'dashboardVariableUtils',
    '$location',
    'urlOverridesService',
    'URL_PARAMETER_CONSTANTS',
    function (
        _,
        $log,
        dashboardVariableUtils,
        $location,
        urlOverridesService,
        URL_PARAMETER_CONSTANTS
    ) {
        function applyVariableOverridesToVariableModel(existingVariables) {
            const variablesCopy = angular.copy(existingVariables);
            const variablesByMap = {};
            variablesCopy.forEach(function (variableItem) {
                variablesByMap[variableItem.property] = variableItem;
            });
            const overrides = getVariablesOverride();
            if (overrides) {
                overrides.forEach((variableOverrideStr) => {
                    const variableFromUrl =
                        dashboardVariableUtils.getVariableFromStr(variableOverrideStr);
                    const variableDef = variablesByMap[variableFromUrl.property];
                    if (variableDef) {
                        variableDef.value = variableFromUrl.value;
                        variableDef.valueOverride = variableFromUrl.value;
                    }
                });
            }
            return variablesCopy;
        }

        function getVariablesUrlOverrideAsModel() {
            // this one pulls global and returns null?
            const overrides = getVariablesOverride();
            if (overrides) {
                return overrides.map((variableOverideStr) => {
                    return dashboardVariableUtils.getVariableFromStr(variableOverideStr);
                });
            }
            return null;
        }

        function getVariablesOverrideAsModel(overrides) {
            if (!overrides) {
                $log.warn(
                    'Get variables override as model called without overrides!  Defaulting to URL'
                );
                overrides = getVariablesOverride();
            }
            if (overrides) {
                return overrides.map((variableOverideStr) => {
                    return dashboardVariableUtils.getVariableFromStr(variableOverideStr);
                });
            }
            return null;
        }

        function getVariablesFromUrl() {
            const variableOverrides = getVariablesUrlOverrideAsModel() || [];
            return variableOverrides.filter(function (override) {
                return override.alias && override.property;
            });
        }

        function mergeUrlValuesToFilterDefinitions(filterDefs, urlValues) {
            if (!filterDefs.variables || !urlValues.variables) {
                return filterDefs;
            }

            const filterPropertyToDefinition = {};
            filterDefs.variables.forEach(function (def) {
                filterPropertyToDefinition[def.property] = def;
            });

            urlValues.variables.forEach(function (urlVar) {
                const def = filterPropertyToDefinition[urlVar.property];
                if (def) {
                    def.value = urlVar.value;
                }
            });

            return filterDefs;
        }

        function checkVariablesAreSame(varA, varB) {
            return varA.alias === varB.alias && varA.property === varB.property;
        }

        function filterOutVariable(variable) {
            const overrides = (getVariablesOverride() || []).filter(function (override) {
                return override && override.split('=')[0].replace(/^~/, '') !== variable;
            });

            return getVariablesOverrideAsModel(overrides);
        }

        function setVariableOverride(variable, property, value, applyIfExists) {
            const overrides = filterOutVariable(variable);
            overrides.push({
                alias: variable,
                property: property,
                value: value,
                applyIfExists: applyIfExists,
            });
            this.setVariablesOverride(overrides);
        }

        function setVariablesOverride(variablesList) {
            if (variablesList && variablesList.length) {
                const variableOverridesAsStrings = variablesList
                    .map((variable) => {
                        let value = dashboardVariableUtils.getVariableDefaultValue(variable);
                        value = dashboardVariableUtils.normalizeVariableValue(value);
                        if (value === null) {
                            return null;
                        }

                        if (angular.isArray(value)) {
                            value = JSON.stringify(value);
                        }

                        return variable.alias + '=' + variable.property + ':' + value;
                    })
                    .filter((v) => v !== null);

                $location.search(
                    URL_PARAMETER_CONSTANTS.variablesOverride,
                    variableOverridesAsStrings
                );
            } else {
                this.clearVariablesOverride();
            }
        }

        // get variables override
        function getVariablesOverride() {
            let params = urlOverridesService.getSearchParam(
                URL_PARAMETER_CONSTANTS.variablesOverride
            );

            if (!params) {
                params = null;
            }

            return params;
        }

        // clear variables override
        function clearVariablesOverride(variable) {
            let overrides = null;
            if (variable) {
                overrides = filterOutVariable(variable);
            }
            if (!overrides || !overrides.length) {
                urlOverridesService.clearParams([URL_PARAMETER_CONSTANTS.variablesOverride]);
            } else {
                this.setVariablesOverride(overrides);
            }
        }

        function cleanAndMergeVariablesToFilters(variables, sourceFilters) {
            return (sourceFilters || [])
                .concat(variables || [])
                .map((f) => {
                    let value;

                    if (_.isNumber(f.value) || !_.isEmpty(f.value)) {
                        value = String(f.value);
                    } else if (_.isNumber(f.propertyValue) || !_.isEmpty(f.propertyValue)) {
                        value = String(f.propertyValue);
                    }

                    // Attach source value as both "value" and "propertyValue" to cover all cases of filters and variables.
                    // Filters can themselves use both propertyValue and value.
                    f.propertyValue = f.value = value;
                    return f;
                })
                .filter(({ value }) => value); // Clean out null filters
        }

        return {
            getVariablesFromUrl: getVariablesFromUrl,
            getVariablesOverrideAsModel,
            getVariablesUrlOverrideAsModel,
            mergeUrlValuesToFilterDefinitions: mergeUrlValuesToFilterDefinitions,
            applyVariableOverridesToVariableModel: applyVariableOverridesToVariableModel,
            checkVariablesAreSame,
            setVariableOverride,
            setVariablesOverride,
            getVariablesOverride,
            clearVariablesOverride,
            cleanAndMergeVariablesToFilters,
        };
    },
]);
