import templateUrl from './teamDashboards.tpl.html';

export default {
    templateUrl,
    bindings: {
        allowTeamModifications: '<',
        team: '<',
        orgId: '<',
    },
    controller: [
        '$log',
        '$q',
        '$scope',
        '$window',
        'DASHBOARD_GROUP_COLUMN_WIDTH',
        'dashboardGroupSelectorService',
        'dashboardGroupService',
        'dashboardIndexFormatter',
        'dashboardV2Service',
        'writepermissionsPermissionsChecker',
        function (
            $log,
            $q,
            $scope,
            $window,
            DASHBOARD_GROUP_COLUMN_WIDTH,
            dashboardGroupSelectorService,
            dashboardGroupService,
            dashboardIndexFormatter,
            dashboardV2Service,
            writepermissionsPermissionsChecker
        ) {
            const ctrl = this;
            let allTeamDashboardGroups;

            ctrl.searchVisibility = {};
            ctrl.plainVisibility = {};
            ctrl.isSearching = false;

            ctrl.$onInit = $onInit;
            ctrl.$onDestroy = $onDestroy;
            ctrl.toggleDashboardGroupVisibility = toggleDashboardGroupVisibility;
            ctrl.updateQuery = updateQuery;
            ctrl.addDashboardGroup = addDashboardGroup;

            function $onInit() {
                ctrl.loading = true;
                queryLinkedDashboardGroups();
                const dashboardGroupsRoot = angular.element('.team-dashboard-cols');
                ctrl.columnCount = getColumnCount(dashboardGroupsRoot);

                angular.element($window).bind(
                    'resize',
                    _.debounce(() => {
                        ctrl.columnCount = getColumnCount(dashboardGroupsRoot);
                        $scope.$digest();
                    }, 100)
                );
            }

            function $onDestroy() {
                angular.element($window).unbind('resize');
            }

            function addDashboardGroup() {
                const title = 'Select a dashboard group to link to this team';
                dashboardGroupSelectorService
                    .promptForDashboardGroup(title)
                    .then((dashboardGroup) => {
                        const updatedTeams = dashboardGroup.teams || [];
                        if (!updatedTeams.includes(ctrl.team.id)) {
                            updatedTeams.push(ctrl.team.id);
                            dashboardGroupService
                                .updateTeamLinks(dashboardGroup.id, updatedTeams)
                                .then(() =>
                                    $q.all({
                                        dashboardGroup: dashboardGroupService.get(
                                            dashboardGroup.id
                                        ),
                                        dashboards: fetchDashboards([dashboardGroup]),
                                    })
                                )
                                .then(({ dashboardGroup, dashboards }) => {
                                    formatSingleDashboardGroup(dashboardGroup, dashboards);
                                });
                        }
                    });
            }

            function queryLinkedDashboardGroups() {
                dashboardGroupService
                    .getTeamDashboardGroups(ctrl.team.id)
                    .then(
                        writepermissionsPermissionsChecker.extendDashboardGroupsWithWritePermissions
                    )
                    .then((groups) => {
                        if (!groups || groups.length < 1) {
                            ctrl.dashboardGroups = [];
                            ctrl.loading = false;
                            return;
                        }
                        fetchDashboards(groups).then((results) => {
                            setFormattedDashboardsAndGroups(groups, results);
                            ctrl.loading = false;
                        });
                    });
            }

            function formatSingleDashboardGroup(dashboardGroup, dashboards) {
                const formattedDashboardGroup =
                    dashboardIndexFormatter.formatDashboardGroup(dashboardGroup);
                const dashboardIdToDashboard = getDashboardByIdMap(dashboards);
                formattedDashboardGroup.dashboards = getDashboardsForViewsOrNonViews(
                    dashboardGroup,
                    dashboardIdToDashboard,
                    formattedDashboardGroup
                );
                ctrl.dashboardGroups.push(formattedDashboardGroup);
                ctrl.plainVisibility[formattedDashboardGroup.id] = true;
            }

            function toggleDashboardGroupVisibility(id) {
                const map = ctrl.isSearching ? ctrl.searchVisibility : ctrl.plainVisibility;
                map[id] = !map[id];
            }

            function updateQuery() {
                if (!ctrl.searchTerm) {
                    ctrl.isSearching = false;
                    ctrl.dashboardGroups = allTeamDashboardGroups;
                    return;
                }

                if (!ctrl.isSearching) {
                    expandAllForSearch();
                }
                ctrl.isSearching = true;
                const lCaseSearchTerm = ctrl.searchTerm.toLowerCase();
                const filteredGroups = allTeamDashboardGroups.filter((group) => {
                    if (group.title.toLowerCase().indexOf(lCaseSearchTerm) !== -1) {
                        return true;
                    }

                    for (let i = 0; i < group.dashboards.length; i++) {
                        if (
                            group.dashboards[i].name.toLowerCase().indexOf(lCaseSearchTerm) !== -1
                        ) {
                            return true;
                        }
                    }

                    return false;
                });

                ctrl.dashboardGroups = filteredGroups;
            }

            /* non-scoped functions */

            function expandAllForSearch() {
                allTeamDashboardGroups.forEach((group) => {
                    ctrl.searchVisibility[group.id] = true;
                });
            }

            function fetchDashboards(groups) {
                const idSet = getSetOfDashboardIdsFromGroups(groups);
                return dashboardV2Service.getAll(idSet, false);
            }

            function getSetOfDashboardIdsFromGroups(groups) {
                const dashboardIdSet = new Set();

                groups.forEach((group) => {
                    const configs = group.dashboardConfigs || group.sf_dashboardConfigs;
                    if (configs) {
                        configs.forEach((config) => dashboardIdSet.add(config.dashboardId));
                    } else if (group.dashboards) {
                        group.dashboards.forEach(dashboardIdSet.add, dashboardIdSet);
                    }
                });

                return [...dashboardIdSet];
            }

            function getColumnCount(el) {
                return Math.floor(el.width() / DASHBOARD_GROUP_COLUMN_WIDTH) || 1;
            }

            function getFormattedDashboardGroups(groups, dashboardIdToDashboard) {
                return groups.map((group) => {
                    const formattedDashboardGroup =
                        dashboardIndexFormatter.formatDashboardGroup(group);
                    // attach formatted dashboard object to the dashboard group
                    formattedDashboardGroup.dashboards = getDashboardsForViewsOrNonViews(
                        group,
                        dashboardIdToDashboard,
                        formattedDashboardGroup
                    );
                    // mark visibility of the dashboard group
                    ctrl.plainVisibility[group.id] = true;
                    return formattedDashboardGroup;
                });
            }

            /**
             * This method is able to handle either views or non-views orgs
             * In orgs with views (or prior to moving off using the index dashboard group call),
             *    the dashboard configs will have the dashboard id information
             * In orgs without views, rely on the dashboards list instead
             *
             * @param group - dashboard group object v2 object type
             * @param dashboardIdToDashboard - map of dashboard id to their dashboard object
             * @param formattedDashboardGroup - view object to function with the dashboard group columns directive
             * @returns formatted dashboard for a dashboard group
             */
            function getDashboardsForViewsOrNonViews(
                group,
                dashboardIdToDashboard,
                formattedDashboardGroup
            ) {
                let formattedDashboards;
                if (group.dashboardConfigs && group.dashboardConfigs.length) {
                    formattedDashboards = getDashboardsForViewsOrgs(
                        group,
                        dashboardIdToDashboard,
                        formattedDashboardGroup
                    );
                } else if (group.dashboards) {
                    formattedDashboards = getDashboardsForNonViewsOrgs(
                        group,
                        dashboardIdToDashboard,
                        formattedDashboardGroup
                    );
                }
                return formattedDashboards || [];
            }

            function getDashboardsForViewsOrgs(
                group,
                dashboardIdToDashboard,
                formattedDashboardGroup
            ) {
                return group.dashboardConfigs
                    .filter((config) => {
                        const configHasMatch = !!dashboardIdToDashboard[config.dashboardId];

                        if (!configHasMatch) {
                            $log.warn('Config not found in dashboards', config);
                        }

                        return configHasMatch;
                    })
                    .map((config) => {
                        const dashboard = dashboardIdToDashboard[config.dashboardId];
                        if (!dashboard.groupId) {
                            dashboard.groupId = group.id;
                        }

                        return dashboardIndexFormatter.formatDashboard({
                            dashboardGroupFavoriteKey: formattedDashboardGroup.getFavoriteKey(
                                dashboard,
                                group,
                                config.configId
                            ),
                            dashboard,
                            config,
                            dashboardGroup: group,
                        });
                    });
            }

            function getDashboardsForNonViewsOrgs(
                group,
                dashboardIdToDashboard,
                formattedDashboardGroup
            ) {
                const dashboardsForGroup = [];
                const config = {};

                group.dashboards
                    // filtered dashboards that user has no access to
                    .filter((dashboardId) => !!dashboardIdToDashboard[dashboardId])
                    .forEach((id) => {
                        const dashboard = dashboardIdToDashboard[id];
                        const formattedDashboard = dashboardIndexFormatter.formatDashboard({
                            dashboardGroupFavoriteKey: formattedDashboardGroup.getFavoriteKey(
                                dashboard,
                                group,
                                config.configId
                            ),
                            dashboard,
                            config,
                            dashboardGroup: group,
                        });
                        dashboardsForGroup.push(formattedDashboard);
                    });

                return dashboardsForGroup;
            }

            function setFormattedDashboardsAndGroups(groups, results) {
                const dashboardByIdMap = getDashboardByIdMap(results);
                ctrl.dashboardGroups = getFormattedDashboardGroups(groups, dashboardByIdMap);
                allTeamDashboardGroups = ctrl.dashboardGroups;
            }

            function getDashboardByIdMap(dashboards) {
                const dashboardByIdMap = {};
                dashboards.forEach((dashboard) => {
                    const dashboardId = dashboard.id || dashboard.sf_id;
                    dashboardByIdMap[dashboardId] = dashboard;
                });
                return dashboardByIdMap;
            }
        },
    ],
};
