import detectorRuleNotificationTemplateUrl from './detectorRuleNotification.tpl.html';
import { cloneDeep } from 'lodash';

angular
    .module('signalview.detector.rule')
    .service('detectorRuleNotificationData', [
        '$q',
        'currentUser',
        'credentialV2Service',
        '$rootScope',
        'teamsApiService',
        function ($q, currentUser, credentialV2Service, $rootScope, teamsApiService) {
            let dataPromise = null;
            let deRegisterListener;

            return {
                fetchData,
                invalidateCache,
            };

            function fetchData() {
                if (!dataPromise) {
                    listenForRouteChange();

                    dataPromise = $q.all({
                        userEmail: currentUser.email(),
                        credentials: credentialV2Service.getAll(),
                        teams: teamsApiService.getTeamSummary().then((response) => {
                            return response.results;
                        }),
                    });
                }
                return dataPromise.then(returnCopiesOfCachedResults);
            }

            function invalidateCache() {
                dataPromise = null;
                $rootScope.$broadcast('refreshNotificationOptions');
            }

            function listenForRouteChange() {
                // add listener to clear the promise once we change location.
                // changing location is a proxy on when we should just ignore the result.
                // we want to cache the result in case the data is used in multiple locations on the
                // same page like detector or detector init modal.
                if (deRegisterListener) {
                    deRegisterListener();
                }
                deRegisterListener = $rootScope.$on('React:$routeChangeSuccess', function () {
                    // remove the promise and remove the listener once we've done that.
                    invalidateCache();
                    deRegisterListener();
                });
            }

            // since we are caching the results of fetchData, we copy the results before
            // returning in order to ensure the integrity of the cached results.
            function returnCopiesOfCachedResults({ userEmail, credentials, teams }) {
                return {
                    userEmail: angular.copy(userEmail),
                    credentials: angular.copy(credentials),
                    teams: angular.copy(teams),
                };
            }
        },
    ])
    .directive('detectorRuleNotification', [
        'notificationsUtil',
        'NOTIFIER_INTEGRATIONS',
        'NOTIFIER_INTEGRATIONS_V2_MAP',
        'detectorRuleNotificationData',
        'TYPE_TO_SERVICE_INTEGRATIONS',
        'featureEnabled',
        '$log',
        function (
            notificationsUtil,
            NOTIFIER_INTEGRATIONS,
            NOTIFIER_INTEGRATIONS_V2_MAP,
            detectorRuleNotificationData,
            TYPE_TO_SERVICE_INTEGRATIONS,
            featureEnabled,
            $log
        ) {
            return {
                restrict: 'EA',
                scope: {
                    notifications: '=',
                    readOnly: '=?',
                    onAdd: '&?',
                    onRemove: '&?',
                    onFinishEdit: '&?',
                    typesIncludeList: '=?',
                    disabledEmails: '=?',
                    version: '@?',
                    detector: '=',
                    team: '=?',
                    disabledAddRecipient: '<',
                },
                templateUrl: detectorRuleNotificationTemplateUrl,
                link: function ($scope) {
                    // INFO: in order to change the label of an integration, we need to clone mapping and adjust
                    //       label on cloned object, because NOTIFIER_INTEGRATIONS is used in API and cannot
                    //       be changed
                    $scope.notifierIntegrations = cloneDeep(NOTIFIER_INTEGRATIONS);
                    $scope.notifierIntegrations.victorops = 'Splunk On-Call';

                    $scope.showWebhook = isTypeEnabled('webhook');
                    $scope.showEmail = isTypeEnabled('email');
                    $scope.showTeam = isTypeEnabled('team');
                    $scope.normalizeTypeIdentifier = notificationsUtil.normalizeTypeIdentifier;

                    $scope.availableNotifications = {};

                    $scope.$on('refreshNotificationOptions', initializeNotificationData);

                    initializeNotificationData();

                    function initializeNotificationData() {
                        detectorRuleNotificationData
                            .fetchData()
                            .then((result) => {
                                $scope.userEmail = result.userEmail;
                                if ($scope.typesIncludeList && $scope.typesIncludeList.length) {
                                    $scope.credentials = result.credentials.filter((credential) => {
                                        if (!TYPE_TO_SERVICE_INTEGRATIONS[credential.type]) {
                                            return false;
                                        }
                                        return (
                                            $scope.typesIncludeList.indexOf(
                                                TYPE_TO_SERVICE_INTEGRATIONS[credential.type]
                                            ) !== -1
                                        );
                                    });
                                } else {
                                    $scope.credentials = result.credentials;
                                }
                                // if this notification policy is part of a team, don't include that team in suggested recipients
                                $scope.teams = result.teams.filter((team) => {
                                    return !$scope.team || $scope.team.id !== team.id;
                                });
                                $scope.loaded = true;
                                updateAvailableNotifications();
                            })
                            .catch((e) => {
                                $log.error('Unable to initialize notification data', e);
                                $scope.loaded = true;
                            });
                    }

                    function updateAvailableNotifications() {
                        $scope.availableNotifications = {};

                        if ($scope.credentials) {
                            $scope.credentials.forEach(function (credential) {
                                if (
                                    credential.type === 'SplunkPlatform' &&
                                    !featureEnabled('splunkPlatformIntegration')
                                ) {
                                    return;
                                }
                                if (!TYPE_TO_SERVICE_INTEGRATIONS[credential.type]) {
                                    return;
                                }
                                $scope.availableNotifications[
                                    TYPE_TO_SERVICE_INTEGRATIONS[credential.type]
                                ] = true;
                            });
                        }

                        if ($scope.userEmail) {
                            const hasSelfEmail = $scope.notifications.some((notification) => {
                                return notification.email === $scope.userEmail;
                            });
                            $scope.availableNotifications.emailMe = !hasSelfEmail;
                        }
                    }

                    $scope.$watchCollection(
                        'notifications',
                        function () {
                            updateAvailableNotifications();
                        },
                        true
                    );

                    $scope.addNotification = function (notification) {
                        $scope.notifications.push(notification);
                        if ($scope.onAdd) {
                            $scope.onAdd({ notification: notification });
                        }

                        //V2 type enums are different than V1, consult the map to rebind to the appropriate model type
                        if (
                            getNotificationVersion() === 2 &&
                            notification &&
                            notification.type &&
                            NOTIFIER_INTEGRATIONS_V2_MAP[notification.type]
                        ) {
                            notification.type = NOTIFIER_INTEGRATIONS_V2_MAP[notification.type];
                        }

                        if (notification && notification.type === 'email' && notification.email) {
                            $scope.finishEdit();
                        }
                    };

                    function getNotificationVersion() {
                        if (!$scope.version || isNaN(parseInt($scope.version, 10))) {
                            return null;
                        } else {
                            return parseInt($scope.version, 10);
                        }
                    }

                    $scope.removeNotification = function (notification) {
                        const indexToRemove = $scope.notifications.indexOf(notification);
                        $scope.notifications.splice(indexToRemove, 1);
                        if ($scope.onRemove) {
                            $scope.onRemove({ notification: notification });
                        }
                    };

                    $scope.finishEdit = function () {
                        if ($scope.onFinishEdit) {
                            $scope.onFinishEdit();
                        }
                    };

                    $scope.isDisabled = function (notification) {
                        const email = (notification || {}).email || '';
                        return ($scope.disabledEmails || []).indexOf(email) !== -1;
                    };

                    function isTypeEnabled(type) {
                        if (!$scope.typesIncludeList) {
                            return true;
                        }
                        return $scope.typesIncludeList.includes(type);
                    }
                },
            };
        },
    ]);
