import {
    getProgramArgsForDashboardInTime,
    isDashboardTimeWindowSelected,
} from '../../utils/programArgsUtils';
import templateUrl from './listChart.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { cloneDeep } from 'lodash';
import { generateTimeSeriesName } from '@splunk/olly-utilities/lib/Timeseries';

export const listChart = {
    templateUrl,
    bindings: {
        chartModel: '<',
        sortPreference: '<',
        colorByMetric: '<',
        colorByValue: '<',
        plotColors: '<',
        signalFlow: '<',
        useKmg2: '<',
        updateInterval: '<',
        maxDecimalPlaces: '<',
        hideMissingValues: '<',
        onNewDataProvider: '&',
        openHref: '<',
        plotDataGeneration: '<',
        hideTitle: '<',
        jobMessageSummary: '<',
        secondaryVisualization: '<',
        disableAnimations: '<',
        widget: '<',
        chartRollupMessage: '<',
        alertState: '<',
        showNoDataMessage: '<',
        themeKey: '<',
        inEditor: '<',
    },
    controller: [
        '$scope',
        'BaseDataProvider',
        'chartDisplayUtils',
        'colorAccessibilityService',
        'valueFormatter',
        'routeParameterService',
        'sortOptionService',
        'timepickerUtils',
        'WindowedDataRepository',
        'colorByValueService',
        'urlOverridesService',
        'chartLoadedEvent',
        '$timeout',
        'featureEnabled',
        function (
            $scope,
            BaseDataProvider,
            chartDisplayUtils,
            colorAccessibilityService,
            valueFormatter,
            routeParameterService,
            sortOptionService,
            timepickerUtils,
            WindowedDataRepository,
            colorByValueService,
            urlOverridesService,
            chartLoadedEvent,
            $timeout,
            featureEnabled
        ) {
            const $ctrl = this;
            let metadataMap;
            let seriesMetadataMap;
            let dataProvider;
            let windowedDataRepo;
            let unregisterRouteWatchGroup;
            let previousLegendKeyConfig;
            let chartPaused = false;
            let redrawDebounce = null;
            $ctrl.listIndex = 20;

            $ctrl.$onChanges = $onChanges;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$doCheck = $doCheck;
            $ctrl.$onInit = $onInit;
            $ctrl.bumpListIndex = bumpListIndex;
            $ctrl.showSparkline = chartDisplayUtils.showSparkline;
            $ctrl.setHasBeenHovered = setHasBeenHovered;

            function $onDestroy() {
                unregisterRouteWatchGroup();
            }

            function $onChanges(changesObj) {
                const {
                    sortPreference,
                    colorByMetric,
                    colorByValue,
                    plotDataGeneration,
                    updateInterval,
                    useKmg2,
                    hideMissingValues,
                } = changesObj;

                if (
                    (sortPreference ||
                        colorByMetric ||
                        colorByValue ||
                        plotDataGeneration ||
                        hideMissingValues) &&
                    dataProvider
                ) {
                    updateDisplayedValues();
                }

                if (updateInterval) {
                    setupStream();
                }

                if (useKmg2) {
                    updateDisplayedValues();
                }
            }

            function $doCheck() {
                const currentLegendKeyConfig =
                    $ctrl.chartModel.sf_uiModel.chartconfig.legendColumnConfiguration;

                if (
                    currentLegendKeyConfig &&
                    !angular.equals(previousLegendKeyConfig, currentLegendKeyConfig)
                ) {
                    updateDisplayedValues();
                    previousLegendKeyConfig = angular.copy(currentLegendKeyConfig);
                }
            }

            function $onInit() {
                dataProvider = new BaseDataProvider(callback);
                dataProvider.setOffsetByMaxDelay(true);
                windowedDataRepo = new WindowedDataRepository();

                // !!! all visualizations must call this for builders to behave correctly !!!
                setupStream();
                $ctrl.onNewDataProvider({ dataProvider });

                unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                    ['startTime', 'endTime', 'startTimeUTC', 'endTimeUTC'],
                    angular.bind(this, setupStream)
                );
            }

            function bumpListIndex() {
                $ctrl.listIndex += 20;
            }

            /* unscoped functions */

            function callback({ type, data }) {
                if (type === 'init') {
                    $ctrl.latestTimeStamp = null;
                    $ctrl.listIndex = 20;
                    metadataMap = {};
                    seriesMetadataMap = {};
                    windowedDataRepo = new WindowedDataRepository();
                    windowedDataRepo.setExpirationPolicy('interval', 10);
                } else if (type === 'data') {
                    handleNewData(data);
                } else if (type === 'timestampAdvance') {
                    // dont do this if chart is paused
                    $ctrl.latestTimeStamp = data;
                }
            }

            function getColorForPlot(plot, metadata) {
                let color;
                if (plot.configuration && plot.configuration.colorOverride) {
                    color = plot.configuration.colorOverride;
                    color =
                        colorAccessibilityService.get().convertPlotColorToAccessible(color) ||
                        color;
                } else {
                    // name used for color generation is different from that of display.
                    const nameForColor = generateTimeSeriesName(
                        metadata,
                        '', // delimiter
                        !$ctrl.colorByMetric,
                        $ctrl.colorByMetric,
                        plot
                    );
                    color = chartDisplayUtils.getPlotColor(nameForColor, $ctrl.plotColors);
                }

                return color;
            }

            function getDisplayNameForPlot(metadata, showMetric) {
                return chartDisplayUtils.resolveSeriesName(
                    metadata,
                    $ctrl.chartModel,
                    false, // skipMetric
                    showMetric,
                    $ctrl.chartModel.$isOriginallyV2
                );
            }

            const filterProperty = function (propertyName, propertyValue, exclude) {
                let sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
                sourceOverrides = sourceOverrides.map((override) => {
                    override.propertyValue = override.value;
                    delete override.value;
                    return override;
                });

                sourceOverrides.push({
                    NOT: exclude,
                    property: propertyName,
                    propertyValue: propertyValue,
                });
                urlOverridesService.setSourceFilterOverrideList(sourceOverrides);
            };

            function pauseChart(pause) {
                chartPaused = pause;
                if (!pause) {
                    updateDisplayedValues();
                }
            }

            let openedCrosslinks = 0;
            $ctrl.crossLinkPauseChartOnToggle = function (open) {
                if (open) {
                    openedCrosslinks++;
                    pauseChart(true);
                } else {
                    openedCrosslinks--;
                    if (openedCrosslinks === 0) {
                        pauseChart(false);
                    }
                }
            };

            const crosslinkActionItems = [
                {
                    title: 'Filter on property',
                    action: (dims) => filterProperty(dims.propertyName, dims.propertyValue, false),
                    label: 'Filter',
                },
                {
                    title: 'Exclude on property',
                    action: (dims) => filterProperty(dims.propertyName, dims.propertyValue, true),
                    label: 'Exclude',
                },
            ];

            function updateCrosslink(metadata, showMetric, lastSet) {
                const dimensions = chartDisplayUtils.resolveDimensions(
                    metadata,
                    $ctrl.chartModel,
                    showMetric,
                    $ctrl.chartModel.$isOriginallyV2,
                    null,
                    null,
                    true
                );

                if (dimensions === 'Unknown') {
                    return null;
                }

                let lastDims = null;
                if (lastSet) {
                    lastDims = {};
                    lastSet.forEach((dims) => {
                        lastDims[dims.propertyName] = dims.propertyValue;
                    });
                }

                const enabledDimensions = _.mapValues(
                    _.omitBy(dimensions, (data) => data.disabled),
                    'value'
                );

                // Provide the dimensions that are not displayed on the chart in the context object. That way they are
                // not shown as dropdown options, but will still be used in url generation.
                const context = _.mapValues(
                    _.pickBy(dimensions, (data) => data.disabled),
                    'value'
                );

                //Don't reset dimension object if no changes occurred. Keeps cross-link usable at per-second refresh rate.
                if (_.isEqual(enabledDimensions, lastDims)) {
                    return {
                        dimensions: lastSet,
                        context: _.isEmpty(context) ? null : context,
                    };
                } else {
                    return {
                        dimensions: _.map(enabledDimensions, (value, key) => ({
                            propertyName: key,
                            propertyValue: value,
                            actionItems: crosslinkActionItems,
                        })),
                        context: _.isEmpty(context) ? null : context,
                    };
                }
            }

            function handleNewData(data) {
                Object.keys(data).forEach((tsid) => {
                    if (!metadataMap[tsid]) {
                        const metadata = dataProvider.getMetricMetaData(tsid);
                        metadataMap[tsid] = metadata;
                        seriesMetadataMap[tsid] = chartDisplayUtils.getSeriesMetadata(
                            $ctrl.chartModel,
                            metadata
                        );
                    }
                });

                windowedDataRepo.onDataReceived(data);

                if (!chartPaused) {
                    //the initial load could return many points, so cancel any same-frame calls except last
                    $timeout.cancel(redrawDebounce);
                    redrawDebounce = $timeout(function () {
                        updateDisplayedValues();
                    }, 0);
                }
            }

            function plotCanShowMetric(plot, tsid) {
                if (plot.synthetic) {
                    return false;
                }

                const onlyHasMetric =
                    metadataMap[tsid].sf_key.length === 3 &&
                    metadataMap[tsid].sf_key.indexOf('sf_metric') !== -1;

                return (
                    (!plot.name && onlyHasMetric) ||
                    plot.seriesData.regExStyle ||
                    (plot.seriesData.metric || '').indexOf('*') !== -1
                );
            }

            function updateDisplayedValues() {
                if (!windowedDataRepo) {
                    return;
                }

                // don't do this if chart is paused
                const plotUKToArrayIndex = {};
                const data = windowedDataRepo.getAll();
                $ctrl.latestTimeSlice = Object.entries(data)
                    .map(([tsid, datapoints], index) => {
                        const plot = chartDisplayUtils.getPlotObject(
                            metadataMap[tsid],
                            $ctrl.chartModel,
                            $ctrl.chartModel.$isOriginallyV2
                        );
                        const showMetric = plotCanShowMetric(plot, tsid);

                        if (!angular.isDefined(plotUKToArrayIndex[plot.uniqueKey])) {
                            //cache plot UK to its array index so we cap out our array seeks at # of plots
                            plotUKToArrayIndex[plot.uniqueKey] =
                                $ctrl.chartModel.sf_uiModel.allPlots.indexOf(plot);
                        }

                        const plotIdx = plotUKToArrayIndex[plot.uniqueKey];

                        if (plot.invisible) {
                            return null;
                        }
                        const lastSlice =
                            ($ctrl.latestTimeSlice && $ctrl.latestTimeSlice[index]) || {};
                        const name = getDisplayNameForPlot(metadataMap[tsid], showMetric);
                        const crosslinkData = updateCrosslink(
                            metadataMap[tsid],
                            showMetric,
                            lastSlice.crosslinkDimensions
                        );
                        const rawValue = datapoints[datapoints.length - 1].value;
                        const unitType = safeLookup(plot, 'configuration.unitType') || '';
                        const charLimit = $ctrl.maxDecimalPlaces
                            ? Math.min($ctrl.maxDecimalPlaces, 6)
                            : 6;
                        const value = unitType
                            ? valueFormatter.formatScalingUnit(rawValue, unitType, charLimit)
                            : valueFormatter.formatValue(rawValue, charLimit, $ctrl.useKmg2);
                        const formattedData = datapoints.map(({ ts, value }) => [ts, value]);
                        const color = getPlotColor(tsid, rawValue);

                        const prefix = safeLookup(plot, 'configuration.prefix') || '';
                        const suffix = safeLookup(plot, 'configuration.suffix') || '';

                        return {
                            tsid,
                            value,
                            rawValue,
                            prefix,
                            suffix,
                            unitType,
                            name,
                            color,
                            sf_source: seriesMetadataMap[tsid].source,
                            metric: seriesMetadataMap[tsid].metric,
                            source: seriesMetadataMap[tsid].source,
                            datapoints: formattedData,
                            pointMetaData: seriesMetadataMap[tsid].raw,
                            raw: formattedData[formattedData.length - 1][1],
                            plotIdx,
                            crosslinkDimensions: crosslinkData.dimensions,
                            crosslinkContext: crosslinkData.context,
                        };
                    })
                    .filter((e) => {
                        return (
                            e !== null &&
                            (!$ctrl.hideMissingValues ||
                                ($ctrl.hideMissingValues && e.rawValue !== null))
                        );
                    });

                sortListings();

                chartLoadedEvent(100);
            }

            function getPlotColor(tsid, rawValue) {
                let color;
                if ($ctrl.chartModel.sf_uiModel.chartconfig.colorByValue) {
                    color = colorByValueService.getColorForValue(
                        $ctrl.chartModel.sf_uiModel.chartconfig.colorByValueScale,
                        rawValue
                    );
                } else {
                    const plot = chartDisplayUtils.getPlotObject(
                        metadataMap[tsid],
                        $ctrl.chartModel,
                        $ctrl.chartModel.$isOriginallyV2
                    );
                    color = getColorForPlot(plot, metadataMap[tsid]);
                }

                return color;
            }

            function setupStream() {
                if (!dataProvider) {
                    return;
                }

                // the change in the global time range should not update the chartModel.sf_uiModel.chartconfig here
                const chartModel = cloneDeep($ctrl.chartModel);
                chartDisplayUtils.updateGlobalTimeRange(
                    chartModel,
                    timepickerUtils.getChartConfigURLTimeParameters()
                );

                const { chartconfig, chartMode } = chartModel.sf_uiModel;
                const rangeParams = chartDisplayUtils.getJobRangeParametersFromConfig(
                    chartconfig,
                    chartMode
                );

                const programArgs = getProgramArgsForDashboardInTime(chartconfig);

                dataProvider.setResolution(rangeParams.resolution);
                dataProvider.setHistoryrange(rangeParams.range);
                dataProvider.setStopTime(rangeParams.endAt);
                if (
                    featureEnabled('dashboardTimeWindow') &&
                    isDashboardTimeWindowSelected(chartModel.sf_viewProgramText)
                ) {
                    dataProvider.setProgramArgs(programArgs);
                }
                dataProvider.setFallbackResolutionMs(rangeParams.fallbackResolutionMs);
                $ctrl.timeRange = timepickerUtils.chartConfigToTimePickerObj(chartconfig);
            }

            function sortByPlotIndex(arr) {
                arr.sort((a, b) => {
                    return a.plotIdx - b.plotIdx;
                });
            }

            function sortListings() {
                // backcompat for previous direction + topic format in select boxes
                // need to split the direction and the topic
                const sortPreference = $ctrl.sortPreference || 'auto';
                let sortOptions;

                sortByPlotIndex($ctrl.latestTimeSlice);

                if (sortPreference !== 'auto') {
                    sortOptions = sortOptionService.getSortOption(sortPreference.substring(1));
                    sortOptions.ascending = sortPreference.charAt(0) === '+' ? true : false;
                } else {
                    sortOptions = sortOptionService.getSortPreferences($ctrl.chartModel);
                }
                sortOptionService.sortTimesliceValues($ctrl.latestTimeSlice, sortOptions);
            }

            function setHasBeenHovered(item) {
                item.hasBeenHovered = true;
            }

            $scope.$watch(
                '$ctrl.chartModel.sf_uiModel.chartconfig.colorByValueScale',
                () => {
                    if ($ctrl.latestTimeSlice) {
                        $ctrl.latestTimeSlice.forEach((timeslice) => {
                            timeslice.color = getPlotColor(timeslice.tsid, timeslice.rawValue);
                        });
                    }
                },
                true
            );
        },
    ],
};
