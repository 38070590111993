// wraps signalStream with additional logic and message collation.  simplifies callback logic

export default [
    'signalStream',
    '$timeout',
    'signalStreamPreRunner',
    '$log',
    'ChartDisplayDebounceService',
    function (signalStream, $timeout, signalStreamPreRunner, $log, ChartDisplayDebounceService) {
        const doLogging = false;
        function logOrNot(msg) {
            if (doLogging) {
                console.log(msg);
            }
        }

        function capitalize(str) {
            return str.charAt(0).toUpperCase() + str.substring(1);
        }

        const configurableJobOpts = [
            'resolution',
            'disabledPublishLabels',
            'disabledDetectLabels',
            'sampleSize',
            'filter',
            'maxDelayMs',
            'timezone',
            'computingFor',
            'offsetByMaxDelay',
            'withDerivedMetadata',
            'replaceOnlyFilter',
            'fallbackResolutionMs',
            'signalFlowText',
            'historyrange',
            'stopTime',
            'immediate',
            'useCache',
            'programArgs',
        ];

        function BaseDataProvider(notifyCallback) {
            this.jobProps = {
                //commented out defaults for now so the prerunner can work
                //todo : alias these on the setter/getter end
                resolution: 1000,
                //disabledPublishLabels: null,
                //disabledDetectLabels: null,
                sampleSize: 100,
                //filter: null,
                //maxDelay: 0,
                //computingFor: null,
                //offsetByMaxDelay: true,
                //withDerivedMetadata: false,
                //replaceOnlyFilter: null,
                //fallbackResolutionMs: null,
                signalFlowText: null,
                //historyrange: 0,
                //stopTime: 0,
                bulk: true,
                callback: this.onData.bind(this),
                eventCallback: this.onEvent.bind(this),
                onFeedback: this.onFeedback.bind(this),
                metaDataUpdated: this.onMetaData.bind(this),
                streamStartCallback: this.onStreamId.bind(this),
                onStreamError: this.onError.bind(this),
                timestampAdvanceCallback: this.onTimeStampAdvance.bind(this),
                streamStopCallback: this.onStreamStop.bind(this),
            };

            this._resolution = null;
            this._lag = null;
            this._activeStream = null;
            this._batchPhaseComplete = false;
            this._kickOffTime = null;
            this._jobDebounceId = null;
            this._initialized = false;
            this._subscriptions = [];
            this._numPublished = 0;
            this._chartDisplayDebouncer = new ChartDisplayDebounceService();
            this._streamId = null;
            this._timeStampAdvanceDebounce = null;
            this._latestTimeStampSeen = null;
            if (notifyCallback) {
                this._subscriptions.push(notifyCallback);
            }

            this._chartDisplayDebouncer.registerListener(
                this.debouncedRerun.bind(this),
                this.progress.bind(this)
            );
        }

        configurableJobOpts.forEach(function (jobOptionName) {
            const capitalizedName = capitalize(jobOptionName);

            BaseDataProvider.prototype['get' + capitalizedName] = function () {
                return this.jobProps[jobOptionName];
            };

            BaseDataProvider.prototype['set' + capitalizedName] = function (value) {
                const oldValue = this.jobProps[jobOptionName];
                this.jobProps[jobOptionName] = value;
                if (this._initialized && oldValue !== value) {
                    this._chartDisplayDebouncer.jobRequested();
                }
            };
        });

        BaseDataProvider.prototype.notifySubscribers = function (msg) {
            this._subscriptions.forEach((fn) => {
                fn(msg);
            });
        };

        BaseDataProvider.prototype.subscribe = function (subscriptionFn) {
            this._subscriptions.push(subscriptionFn);
        };

        BaseDataProvider.prototype.unsubscribe = function (subscriptionFn) {
            const subscriptionIdx = this._subscriptions.indexOf(subscriptionFn);
            if (subscriptionIdx !== -1) {
                this._subscriptions.splice(subscriptionIdx, 1);
            } else {
                $log.warn('Tried to unsubscribe a function that is not subscribed!');
            }
        };

        BaseDataProvider.prototype.reset = function reset() {
            if (this._activeStream) {
                this._activeStream.stopStream();
            }
            this._batchPhaseComplete = false;
            this._resolution = null;
            this._lag = null;
            this._activeStream = null;
            this._numPublished = 0;
            this._timeStampAdvanceDebounce = null;
            this._latestTimeStampSeen = null;
            $timeout.cancel(this._timeStampAdvanceDebounce);
            this.notifySubscribers({ type: 'init', data: null });
        };

        BaseDataProvider.prototype.onData = function onData(data, timestamp) {
            this._latestTimeStampSeen = timestamp;
            this.notifySubscribers({ type: 'data', data });
            logOrNot(data);
        };

        BaseDataProvider.prototype.onEvent = function onEvent(msg) {
            logOrNot(msg);
        };

        BaseDataProvider.prototype.onFeedback = function onFeedback(msgs) {
            const self = this;
            msgs.forEach(function (msg) {
                if (msg.messageCode === 'JOB_RUNNING_RESOLUTION') {
                    self._resolution = msg.contents.resolutionMs;
                } else if (msg.messageCode === 'JOB_INITIAL_MAX_DELAY') {
                    self._lag = msg.contents.maxDelayMs;
                }
            });

            // When no data points arrive after the feedback messages that set
            // lag and resolution, the latest data timestamp is not propagated.
            // To prevent this, propagate the latest data timestamp when lag or
            // resolution is set
            if (self._resolution && self._lag) {
                self.onTimeStampAdvance(self._latestTimeStampSeen);
            }

            this.notifySubscribers({ type: 'feedback', data: msgs });
            logOrNot(msgs);
        };

        BaseDataProvider.prototype.onStreamId = function onStreamId(id) {
            this._streamId = id;
        };

        BaseDataProvider.prototype.onMetaData = function onMetaData(msg) {
            this._numPublished++;
            logOrNot(msg);
        };

        BaseDataProvider.prototype.onError = function onError(channel, type, msg) {
            this.notifySubscribers({ type: 'error', data: msg });
            logOrNot(msg);
        };

        BaseDataProvider.prototype.onTimeStampAdvance = function onTimeStampAdvance(ts) {
            if (this._lag === null || this._resolution === null) {
                return;
            }

            if (
                !this._batchPhaseComplete &&
                ts > this._kickOffTime - this._lag - this._resolution
            ) {
                this.onBatchPhaseComplete();
            }
            $timeout.cancel(this._timeStampAdvanceDebounce);
            this._timeStampAdvanceDebounce = $timeout(
                this.onTimeStampAdvanceRerun.bind(this),
                200,
                false,
                this._latestTimeStampSeen
            );
        };

        BaseDataProvider.prototype.onTimeStampAdvanceRerun = function onTimeStampAdvanceRerun(ts) {
            this.notifySubscribers({ type: 'timestampAdvance', data: ts });
        };

        BaseDataProvider.prototype.onBatchPhaseComplete = function () {
            this._batchPhaseComplete = true;
            this.notifySubscribers({ type: 'batch_phase_complete', data: null });
            logOrNot('batch phase complete');
        };

        BaseDataProvider.prototype.setFilterAlias = function (fa) {
            this.filterAlias = fa;
        };

        BaseDataProvider.prototype.onStreamStop = function onStreamStop() {
            this.onBatchPhaseComplete();
        };

        BaseDataProvider.prototype.init = function init() {
            this._initialized = true;
            this.rerun();
        };

        BaseDataProvider.prototype.rerun = function rerun() {
            if (!this._initialized) {
                return;
            }
            $timeout.cancel(this._jobDebounceId);
            this.reset();
            this._kickOffTime = Date.now();

            const preRun = signalStreamPreRunner.fetch(this.jobProps);
            if (preRun) {
                preRun.setJobOpts(this.jobProps);
            }

            const dataStreamingPromise = preRun || signalStream.stream(this.jobProps);

            // assign active stream before resuming streaming or a race can occur
            this._activeStream = dataStreamingPromise;

            if (preRun) {
                dataStreamingPromise.resumeStreaming();
            }
        };

        BaseDataProvider.prototype.setUseCache = function setUseCache(useCache) {
            this.jobProps.useCache = useCache;
        };

        BaseDataProvider.prototype.progress = function progress() {
            this.notifySubscribers({ type: 'debounced', data: null });
        };

        BaseDataProvider.prototype.debouncedRerun = function () {
            if (!this._initialized) {
                return;
            }
            const self = this;
            $timeout.cancel(this._jobDebounceId);
            self._jobDebounceId = $timeout(self.rerun.bind(self), 100);
        };

        BaseDataProvider.prototype.destroy = function runJob() {
            if (this._activeStream) {
                this._activeStream.stopStream();
            }

            this._subscriptions = [];
        };

        BaseDataProvider.prototype.getMetricMetaData = function getMetricMetaData(tsid) {
            if (this._activeStream) {
                return this._activeStream.metaDataMap[tsid] || null;
            } else {
                console.error('Tried to look up a non existent mts tsid!!!');
                return null;
            }
        };

        BaseDataProvider.prototype.getMetaDataMap = function () {
            if (!this._activeStream) {
                return {};
            }
            return this._activeStream.metaDataMap;
        };

        BaseDataProvider.prototype.getNumTimeseries = function () {
            return this._numPublished;
        };

        BaseDataProvider.prototype.getEventMetaData = function getEventMetaData(tsid) {
            if (this._activeStream) {
                return this._activeStream.etsMetaDataMap[tsid] || null;
            } else {
                console.error('Tried to look up a non existent ets tsid!!!');
                return null;
            }
        };

        BaseDataProvider.prototype.getChartDisplayDebouncer = function () {
            return this._chartDisplayDebouncer;
        };

        BaseDataProvider.prototype.getJobId = function () {
            return this._streamId;
        };

        return BaseDataProvider;
    },
];
