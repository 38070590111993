/**
 * Service for accessing and dealing with notification delivery events from
 * outbound integrations.
 */
angular.module('sfx.ui').service('notificationService', [
    'timeZoneService',
    function (timeZoneService) {
        /**
         * Format a notification timestamp into an appropriate string.
         */
        function formatNotificationEventTimestamp(ts, dateOnly = false) {
            const format = dateOnly ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm:ss a';
            return ts ? timeZoneService.moment(ts).format(format) : 'None';
        }

        return {
            formatNotificationEventTimestamp: formatNotificationEventTimestamp,
        };
    },
]);
