'use strict';
import templateUrl from './oktaIntegration.html';
import samlShowOnLoginPageTooltipTemplateUrl from './samlShowOnLoginPageTooltip.tpl.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('sfxOktaIntegration', [
    'PRODUCT_NAME',
    function (PRODUCT_NAME) {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.samlShowOnLoginPageTooltipTemplateUrl =
                    samlShowOnLoginPageTooltipTemplateUrl;

                function reset() {
                    const credential = $scope.credential;

                    if (!credential.name) {
                        credential.name = 'Okta';
                    }

                    $scope.publicKey = $scope.credential.publicKey;
                    $scope.issuerUrl = $scope.credential.issuerUrl;
                    $scope.metadataUrl = $scope.credential.metadataUrl;
                    $scope.sharedCredentialState.updateCredentials = false;
                }

                $scope.$on('reset', reset);
                reset();

                function updateCredentials() {
                    if (!$scope.publicKey || !$scope.issuerUrl || !$scope.metadataUrl) {
                        $scope.credential.publicKey = null;
                        $scope.credential.issuerUrl = null;
                        $scope.credential.metadataUrl = null;
                    } else {
                        $scope.credential.publicKey = $scope.publicKey;
                        $scope.credential.issuerUrl = $scope.issuerUrl;
                        $scope.credential.metadataUrl = $scope.metadataUrl;
                        $scope.sharedCredentialState.updateCredentials = true;
                    }
                }

                $scope.$watchGroup(['publicKey', 'issuerUrl', 'metadataUrl'], updateCredentials);
            },
        };
    },
]);
