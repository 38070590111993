angular
    .module('signalview.timePicker')
    .constant('AUTO_TIMEZONE', 'Auto (detect from browser)')
    .constant('UTC_TIMEZONE', 'UTC')
    .factory('TimeParser', [
        'timeZoneService',
        function (timeZoneService) {
            const timeExp = new RegExp('^-?(\\d+)([mhdw])');
            //copypasta
            const delta = {
                m: 60.0,
                h: 60.0 * 60.0,
                d: 60.0 * 60.0 * 24.0,
                w: 60.0 * 60.0 * 24.0 * 7,
            };

            function ParsedTimePickerTime(str, isEnd) {
                this.valid = false;
                this.empty = false;

                if (!str || str.trim().length === 0) {
                    this.empty = true;
                    return;
                }

                if (isEnd && str.match(/now/i)) {
                    this.isNow = true;
                    return;
                }

                let matched = str.match(timeExp);
                if (matched !== null) {
                    const nums = [];
                    const types = [];

                    ['w', 'd', 'h', 'm'].forEach(function (t) {
                        matched = str.match(new RegExp('(\\d+)(' + t + ')'));
                        if (matched !== null) {
                            nums.push(matched[1]);
                            types.push(matched[2]);
                        }
                    });

                    this.num = nums;
                    this.type = types;
                    this.valid = true;
                } else if (!str.match(/-/)) {
                    matched = timeZoneService.stringToMoment(str);
                    if (matched && matched.isValid()) {
                        this.utc = matched.diff(0);
                        this.valid = true;
                    }
                }
            }

            ParsedTimePickerTime.prototype.isValid = function () {
                return this.valid;
            };

            ParsedTimePickerTime.prototype.isEmpty = function () {
                return this.empty;
            };

            ParsedTimePickerTime.prototype.isUTC = function () {
                return !!this.utc;
            };

            ParsedTimePickerTime.prototype.getUTC = function () {
                if (this.utc) {
                    return this.utc;
                }
                const now = new Date().getTime();
                if (this.num && this.type) {
                    const self = this;
                    let utc = 0;
                    angular.forEach(self.num, function (n, i) {
                        utc += n * delta[self.type[i]];
                    });
                    return (now / 1000 - utc) * 1000;
                } else {
                    return now;
                }
            };

            return ParsedTimePickerTime;
        },
    ]);
