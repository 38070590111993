import templateUrl from './thresholdValuePicker.tpl.html';

import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.detector.rule').directive('thresholdValuePicker', [
    'chartbuilderUtil',
    'scalingUnitService',
    'valueFormatter',
    function (chartbuilderUtil, scalingUnitService, valueFormatter) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                plots: '=',
                targetSignal: '=',
                signalResolution: '=',
                threshold: '=ngModel',
                disableDynamic: '=?',
                validationError: '=?',
                resolvingResolution: '=?',
            },
            templateUrl,
            link: function ($scope) {
                if (safeLookup($scope.targetSignal, 'configuration.unitType')) {
                    $scope.scalingUnit = scalingUnitService.getUnitDefinition(
                        $scope.targetSignal.configuration.unitType
                    );
                }

                $scope.updateScaledValue = function () {
                    if ($scope.scalingUnit) {
                        const scaledUnit = valueFormatter.formatScalingUnit(
                            $scope.threshold,
                            $scope.scalingUnit.name,
                            5
                        );

                        $scope.scaledThreshold = $scope.threshold ? scaledUnit : '';
                    }
                };

                $scope.updateScaledValue();

                $scope.getPlotKey = function (plot) {
                    return chartbuilderUtil.getLetterFromUniqueKey(plot.uniqueKey);
                };

                $scope.getPlotFromLetter = function (letter) {
                    if (!letter || !isNaN(letter)) return;

                    const abovePlotUniqueKey = chartbuilderUtil.getUniqueKeyFromLetter(letter);
                    return $scope.plots.filter(function (plot) {
                        return !plot.transient && plot.uniqueKey === abovePlotUniqueKey;
                    })[0];
                };

                $scope.$watch('threshold', function (newValue) {
                    if (!newValue) {
                        $scope.isDynamic = false;
                        return;
                    }

                    $scope.signalInfo = null;
                    if ($scope.getPlotFromLetter(newValue)) {
                        $scope.isDynamic = true;
                        $scope.plot = $scope.getPlotFromLetter($scope.threshold);
                        $scope.resolvingResolution = true;
                        $scope
                            .signalResolution($scope.threshold)
                            .then(function (info) {
                                $scope.signalInfo = info;
                            })
                            .finally(function () {
                                $scope.resolvingResolution = false;
                            });
                    } else if (typeof newValue === 'string') {
                        $scope.threshold = newValue.replace(/[^-\d\.]/g, '');
                    }
                });

                $scope.$watch('isDynamic', function (newValue) {
                    if (!newValue) {
                        $scope.plot = undefined;
                    } else {
                        $scope.plot = $scope.getPlotFromLetter($scope.threshold);
                    }
                });

                $scope.setDynamic = function (plot) {
                    $scope.isDynamic = true;
                    $scope.threshold = $scope.getPlotKey(plot);
                };

                $scope.setStatic = function (value) {
                    $scope.isDynamic = false;
                    $scope.threshold = value;
                };
            },
        };
    },
]);
