import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

// it is assumed here that there will never be more than 10k results
// returned by a query for workflow names
const REASONABLE_INFINITY = 10000;

businessWorkflowSelectorDataSourceFactory.$inject = ['suggestAPIService', 'DETECTOR_TYPES'];
export default function businessWorkflowSelectorDataSourceFactory(
    suggestAPIService,
    DETECTOR_TYPES
) {
    const { APM_V2 } = DETECTOR_TYPES;
    const EIGHT_DAYS = '8d';

    return {
        fetchWorkflows,
    };

    function fetchWorkflows(selectedDetectorType = APM_V2, environment) {
        if (selectedDetectorType === APM_V2) {
            const query = buildQueryObject('sf_workflow', buildFilters(environment));
            return suggestAPIService.getSignalFlowSuggest(query).then((response) => [...response]);
        }

        throw new Error('Invalid APM type.');
    }

    function buildQueryObject(property, filters) {
        const endTime = Date.now();
        const startTime = endTime - convertStringToMS(EIGHT_DAYS);

        return {
            property,
            limit: REASONABLE_INFINITY,
            additionalFilters: filters,
            startTime,
            endTime,
        };
    }

    function buildFilters(environment) {
        const filters = [{ property: 'sf_metric', values: ['workflows.count'] }];

        if (!!environment) {
            filters.push({ property: 'sf_environment', values: [environment] });
        }

        return filters;
    }
}
