import angularElastic from 'angular-elastic';
import dashboardVariableUtils from './dashboardVariableUtils';
import { dashboardVariables } from '../../../../app/dashboard/variables/DashboardVariables';

angular
    .module('signalview.variables', [
        'signalview',
        'signalboost',
        'signalview.typeahead',
        'signalview.dashboardUtil',
        'signalview.urlOverrides',
        'sfx.filterControls',
        angularElastic,
        'sfx.api.v2',
    ])
    .service('dashboardVariableUtils', dashboardVariableUtils)
    .component('newDashboardVariables', dashboardVariables);
