import inviteSignupTemplateUrl from './inviteSignup.tpl.html';

export default {
    bindings: {
        accessToken: '<',
    },
    templateUrl: inviteSignupTemplateUrl,
    controller: [
        '$scope',
        'signalboost',
        'security',
        'legalService',
        'sessionService',
        'PASSWORD_ERROR_TEXT',
        'PASSWORD_VALIDATION_REGEX',
        'FULL_NAME_LENGTH_ERROR_TEXT',
        'FULL_NAME_REGEX_ERROR_TEXT',
        'FULL_NAME_MAX_LENGTH',
        'FULL_NAME_VALIDATION_REGEX',
        'userV2Service',
        'PRODUCT_NAME',
        'INTERNAL_ORG',
        'organizationService',
        'userAnalytics',
        'featureEnabled',
        function (
            $scope,
            signalboost,
            security,
            legal,
            sessionService,
            PASSWORD_ERROR_TEXT,
            PASSWORD_VALIDATION_REGEX,
            FULL_NAME_LENGTH_ERROR_TEXT,
            FULL_NAME_REGEX_ERROR_TEXT,
            FULL_NAME_MAX_LENGTH,
            FULL_NAME_VALIDATION_REGEX,
            userV2Service,
            PRODUCT_NAME,
            INTERNAL_ORG,
            organizationService,
            userAnalytics,
            featureEnabled
        ) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;
            const TOKEN_EXPIRY_TIME = 120000;
            $ctrl.inviteLoginFixEnable = featureEnabled('inviteLoginFix');

            $scope.privacyPolicy = legal.privacyPolicy;
            $scope.termsOfService = legal.termsOfService;
            $scope.termsOfUse = legal.termsOfUse;
            $scope.passwordErrorText = PASSWORD_ERROR_TEXT;
            $scope.passwordValidation = new RegExp(PASSWORD_VALIDATION_REGEX);
            $scope.fullNameLengthErrorText = FULL_NAME_LENGTH_ERROR_TEXT;
            $scope.fullNamePatternErrorText = FULL_NAME_REGEX_ERROR_TEXT;
            $scope.fullNameMaxLength = FULL_NAME_MAX_LENGTH;
            $scope.fullNamePattern = FULL_NAME_VALIDATION_REGEX;
            $scope.submitMessage = 'Sign in now';
            $scope.PRODUCT_NAME = PRODUCT_NAME;

            $scope.user = {};

            function error(err) {
                userAnalytics.event(
                    'Create_Account',
                    'click',
                    null,
                    `Submit/Error: ${err.data.message}`
                );
                $scope.error = err.data.message;
                $scope.state = '';
            }

            $scope.state = '';
            function initialize() {
                // Check if the inviteLoginFix feature flag is enabled
                if ($ctrl.inviteLoginFixEnable) {
                    // Check session storage for data to avoid duplicate api call on component remount while invite user
                    const storedSessionData = sessionStorage.getItem($ctrl.accessToken);
                    if (storedSessionData) {
                        // If parsedSessionData is available in session storage, parse and assign it
                        const parsedSessionData = JSON.parse(storedSessionData);
                        assignUserData(parsedSessionData);

                        sessionStorage.removeItem($ctrl.accessToken);
                        return Promise.resolve(); // Resolve promise since data is already available
                    }
                }

                // If feature flag is disabled or no stored session data is found, proceed with existing flow
                return validateInviteTokenByTempToken();
            }

            function validateInviteTokenByTempToken() {
                return sessionService
                    .validateInviteToken($ctrl.accessToken || '')
                    .then(({ data }) => {
                        // Store data in session storage if inviteLoginFixEnable is enabled
                        if ($ctrl.inviteLoginFixEnable) {
                            sessionStorage.setItem($ctrl.accessToken, JSON.stringify(data));
                        }
                        // Assign user data
                        assignUserData(data);
                    })
                    .catch(() => {
                        $scope.state = 'failed';
                    });
            }

            function assignUserData(data) {
                $scope.user.inviter = data.inviter;
                $scope.user.fullName = data.invitee;
                $scope.user.org = data.organization;
                $scope.user.organizationId =
                    data.organizationId === INTERNAL_ORG ? null : data.organizationId;
                $scope.user.email = data.email;
                $scope.user.agreementRequired = !data.agreedToTermsMs;
                $scope.user.agreement = false;

                if ($scope.user.inviter === 'SignalFx') {
                    $scope.description = `Choose a password for your ${PRODUCT_NAME} account`;
                } else {
                    $scope.description =
                        $scope.user.inviter + ' has invited you to join ' + $scope.user.org;
                }
                $scope.inviteContext = data.contextInfo;
                if ($scope.inviteContext) {
                    $scope.snapshotType = $scope.inviteContext.type.toLowerCase();
                    $scope.redirectTo =
                        '/temp/' + $scope.snapshotType + '/' + $scope.inviteContext.id;
                }
            }

            const DEFAULT_INVITE_ACCEPTED_PATH = '/home';
            const FIRST_EXPERIENCE_INVITE_ACCEPTED_PATH = '/integrations';
            function redirectOnInviteAccepted({ user }) {
                if ($scope.redirectTo) {
                    return $scope.redirectTo;
                }

                const userId = user.sf_id;
                return organizationService.get().then((organization) => {
                    const isCreator = organization.creator === userId;
                    const isTrial = organization.accountType === 'TRIAL';
                    const isOrgActive = organization.accountStatus === 'ACTIVE';
                    if (isCreator && isTrial && isOrgActive) {
                        return FIRST_EXPERIENCE_INVITE_ACCEPTED_PATH;
                    }

                    return DEFAULT_INVITE_ACCEPTED_PATH;
                });
            }

            $scope.submit = function () {
                $scope.state = 'loading';
                $scope.error = '';

                // Clearing out the temporary token data which is stored in session storage after 2 mins.
                setTimeout(() => {
                    sessionStorage.removeItem($ctrl.accessToken);
                }, TOKEN_EXPIRY_TIME);

                return signalboost.user
                    .setNewUserPassword(
                        $scope.user.fullName,
                        $scope.user.email,
                        $ctrl.accessToken || '',
                        $scope.user.password
                    )
                    .then(() => {
                        userAnalytics.event('Create_Account', 'click', null, 'Submit/Successful');
                        return security.login(
                            $scope.user.email,
                            $scope.user.password,
                            $scope.user.organizationId,
                            redirectOnInviteAccepted
                        );
                    })
                    .then(() => {
                        if ($scope.user.agreement) {
                            return userV2Service.agreeToTerms();
                        }
                    })
                    .catch(error);
            };
        },
    ],
};
