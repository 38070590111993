const workloadFilters = [
    { type: 'property', NOT: false, property: '_exists_', propertyValue: 'kubernetes_cluster' },
    { type: 'property', NOT: false, property: '_exists_', propertyValue: 'kubernetes_pod_name' },
    {
        type: 'property',
        NOT: false,
        property: '_exists_',
        propertyValue: 'kubernetes_workload_name',
    },
];

export default {
    BASE_METRIC_TEMPLATES: [
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_POD_CONTAINER_COUNT = data('kubernetes.container_ready'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).promote('kubernetes_workload_name')",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_POD_CONTAINER_COUNT = data('kubernetes.container_ready'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'deployment'})",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_POD_PHASE = data('kubernetes.pod_phase'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).promote(['kubernetes_workload_name'])",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_POD_PHASE = data('kubernetes.pod_phase'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'deployment'})",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_CPU_PERCENT = data('container_cpu_utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='rate', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_CPU_PERCENT = data('container_cpu_utilization'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, rollup='rate', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_MEM_USAGE = data('container_memory_usage_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_MEM_USAGE = data('container_memory_usage_bytes'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            template:
                "MEM_USAGE = union(WORKLOAD_MEM_USAGE, DEPLOYMENT_MEM_USAGE).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid'])",
        },
        {
            // No filters, Node only metric, used within Formula. Metric Join will take care of correct stream to pick.
            template:
                "NODE_MEMORY = data('memory.total', extrapolation='last_value', maxExtrapolations=2).sum(by=['kubernetes_cluster', 'kubernetes_node'])",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_NETWORK_IN = data('pod_network_receive_bytes_total'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_NETWORK_IN = data('pod_network_receive_bytes_total'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_NETWORK_OUT = data('pod_network_transmit_bytes_total'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_NETWORK_OUT = data('pod_network_transmit_bytes_total'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_NETWORK_RECV_ERRORS = data('pod_network_receive_errors_total'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_NETWORK_RECV_ERRORS = data('pod_network_receive_errors_total'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            template:
                "NETWORK_RECV_ERRORS = union(WORKLOAD_NETWORK_RECV_ERRORS, DEPLOYMENT_NETWORK_RECV_ERRORS).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid'])",
        },
        {
            filters: workloadFilters,
            template:
                "WORKLOAD_NETWORK_TRANSMIT_ERRORS = data('pod_network_transmit_errors_total'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            filters: workloadFilters,
            template:
                "DEPLOYMENT_NETWORK_TRANSMIT_ERRORS = data('pod_network_transmit_errors_total'{{#exclusiveDeploymentFilters}}, filter={{{exclusiveDeploymentFilters}}}{{/exclusiveDeploymentFilters}}, extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            template:
                "NETWORK_TRANSMIT_ERRORS = union(WORKLOAD_NETWORK_TRANSMIT_ERRORS, DEPLOYMENT_NETWORK_TRANSMIT_ERRORS).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid'])",
        },
    ],

    METRICS: [
        {
            displayName: 'Containers',
            job: {
                template:
                    "POD_CONTAINER_COUNT = union(WORKLOAD_POD_CONTAINER_COUNT, DEPLOYMENT_POD_CONTAINER_COUNT).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid']).not_equals(0)",
                varName: 'POD_CONTAINER_COUNT',
            },
        },
        {
            displayName: 'Phase',
            job: {
                template: 'POD_PHASE = union(WORKLOAD_POD_PHASE, DEPLOYMENT_POD_PHASE)',
                varName: 'POD_PHASE',
            },
        },
        {
            displayName: 'CPU Percent',
            job: {
                template:
                    "CPU_PERCENT = union(WORKLOAD_CPU_PERCENT, DEPLOYMENT_CPU_PERCENT).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid'])",
                varName: 'CPU_PERCENT',
            },
        },
        {
            displayName: 'Memory',
            job: {
                template: 'MEM_PERCENT = (100 * MEM_USAGE / NODE_MEMORY)',
                varName: 'MEM_PERCENT',
            },
        },
        {
            displayName: 'Network In',
            job: {
                template:
                    "NETWORK_IN = union(WORKLOAD_NETWORK_IN, DEPLOYMENT_NETWORK_IN).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid'])",
                varName: 'NETWORK_IN',
            },
        },
        {
            displayName: 'Network Out',
            job: {
                template:
                    "NETWORK_OUT = union(WORKLOAD_NETWORK_OUT, DEPLOYMENT_NETWORK_OUT).sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'kubernetes_pod_uid'])",
                varName: 'NETWORK_OUT',
            },
        },
        {
            displayName: 'Network Errors',
            job: {
                template: 'NETWORK_ERRORS = NETWORK_RECV_ERRORS + NETWORK_TRANSMIT_ERRORS',
                varName: 'NETWORK_ERRORS',
            },
        },
    ],

    COLUMNS: [
        {
            aggregate: null,
            displayName: 'Pod',
            format: 'link',
            metric: null,
            property: 'kubernetes_pod_name',
            propertiesToLinkOn: ['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name'],
            panelName: 'kubernetes_pod_name',
        },
        {
            aggregate: null,
            displayName: 'Workload',
            format: null,
            metric: null,
            property: 'kubernetes_workload_name',
        },
        {
            aggregate: null,
            displayName: 'Node',
            format: 'link',
            metric: null,
            property: 'kubernetes_node',
            propertiesToLinkOn: ['kubernetes_cluster', 'kubernetes_node'],
            alternativeLink: '/k8s/nodeList',
            alternativeLinkText: 'Unscheduled',
            panelName: 'kubernetes_node',
        },
        {
            aggregate: null,
            displayName: 'CPU',
            format: 'Percentage',
            metric: null,
            property: 'CPU Percent',
        },
        {
            aggregate: null,
            displayName: 'Memory',
            format: 'Percentage',
            metric: null,
            property: 'Memory',
        },
        {
            aggregate: null,
            displayName: 'Network In',
            format: 'Bytes',
            metric: null,
            property: 'Network In',
        },
        {
            aggregate: null,
            displayName: 'Network Out',
            format: 'Bytes',
            metric: null,
            property: 'Network Out',
        },
        {
            aggregate: null,
            displayName: 'Network Errors',
            format: 'Bytes',
            metric: null,
            property: 'Network Errors',
        },
        {
            aggregate: null,
            displayName: 'Active Containers',
            format: 'Number',
            metric: null,
            property: 'Containers',
        },
        {
            aggregate: null,
            displayName: 'Phase',
            format: 'Custom',
            metric: null,
            property: 'Phase',
        },
    ],
    UNIQUE_ID: '{{ kubernetes_cluster }}:{{ kubernetes_pod_name }}:{{ kubernetes_pod_uid }}',

    METRICS_TO_AGGREGATE: {
        Phase: {
            displayMapping: [
                'Not Reporting',
                'Pending',
                'Running',
                'Succeeded',
                'Failed',
                'Unknown',
            ],
        },
    },
};
