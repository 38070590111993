import { ngRoute } from '../../../../app/routing/ngRoute';

export default [
    '$scope',
    '$log',
    'signalboost',
    'signalviewMetrics',
    'config',
    '$location',
    'featureEnabled',
    'PRODUCT_NAME',
    'organizationService',
    function (
        $scope,
        $log,
        signalboost,
        signalviewMetrics,
        config,
        $location,
        featureEnabled,
        PRODUCT_NAME,
        organizationService
    ) {
        $scope.PRODUCT_NAME = PRODUCT_NAME;

        if (ngRoute.params.tab === 'integrations') {
            $location.path('/integrations');
            return;
        }
        $scope.hasInfraEntitlement = featureEnabled('infra');

        $scope.tabs = ['Members', 'Billing', 'AccessTokens'];

        // scope bound functions
        $scope.showUnreleasedFeatures = config('superpower.unreleasedFeatures');
        $scope.initialized = false;

        $scope.editable = false;

        initOrg();

        signalviewMetrics.endRouteUi('organization');

        function getOrgEditable() {
            $scope.editable = false;
            return organizationService
                .get()
                .then((org) => {
                    $scope.org = org;
                    return signalboost.uauth.canCreate(org.id, 'OrgUser');
                })
                .then((hasPermission) => ($scope.editable = hasPermission))
                .catch(function () {
                    $log.log('Failed looking up user auth org permissions.');
                    return false;
                });
        }

        function initOrg() {
            getOrgEditable().then(() => {
                $scope.initialized = true;
            });
        }
    },
];
