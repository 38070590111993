export default [
    '$http',
    'createV2Service',
    function ($http, createV2Service) {
        const navigatorService = createV2Service('navigator');

        return {
            exportNavigator,
            importNavigator,
        };

        function exportNavigator(id) {
            const resourcePath = '/' + id + '/export';
            return $http.get(navigatorService.resourceEndpoint + resourcePath).then((response) => {
                return response.data;
            });
        }

        function importNavigator(pkg) {
            const resourcePath = '/import';
            return $http
                .post(navigatorService.resourceEndpoint + resourcePath, pkg)
                .then((response) => {
                    return response.data;
                });
        }
    },
];
