angular.module('sfx.api.v2').service('autoSwitchOrgWhen403', [
    '$q',
    '$http',
    'API_URL',
    'currentUser',
    function ($q, $http, API_URL, currentUser) {
        const getHasAccessApiUrl = (objectType, objectId) =>
            `${API_URL}/v2/${objectType}/_/hasAccess/${objectId}`;

        return function autoSwitchOrgWhen403Handler(responseOrError, objectType, objectId) {
            if (responseOrError?.status !== 403) {
                // if the reponse is not 403, then org switching can't help, we're quitting
                // leave error flow untouched, so another handler can do its work on the original error
                return $q.reject(responseOrError);
            }

            return (
                $http
                    .get(getHasAccessApiUrl(objectType, objectId))
                    .then(({ data }) => {
                        if (!data?.organizationId || !data?.hasAccess) {
                            return $q.reject();
                        }

                        return data.organizationId;
                    })

                    // if there's no org to switch to, we should put original error back in the promise
                    // otherwise later on we'll get an error pertaining to /v2/user/_/hasAccess/
                    .catch(() => {
                        throw responseOrError;
                    })

                    .then((orgId) => currentUser.setPreferredOrg(orgId))

                    // avoid returning user-preferences which would be confusing
                    .then({})
            );
        };
    },
]);
