import React from 'react';
import Message from '@splunk/react-ui/Message';
import P from '@splunk/react-ui/Paragraph';
import List from '@splunk/react-ui/List';
import Switch from '@splunk/react-ui/Switch';

export default function MultipleScopedTokenWarning({ hasAccepted, handleClick }) {
    return (
        <div>
            <Message type="warning">
                <strong>Warning! You have selected more than one permission for this token</strong>
            </Message>
            <P>
                Applying API and Ingest permissions to the same token is not a best practice as it
                may be considered a security concern. API tokens should be approached with caution
                as they can be used to access a variety of data from the system. It is recommended
                to consider the following best practices:
                <List ordered>
                    <List.Item>
                        Create a distinct token for API use and use it with caution
                    </List.Item>
                    <List.Item>
                        Do not share the same token between users and for automation workflows
                    </List.Item>
                    <List.Item>
                        Generate a distinct token for each distinct automation workflow
                    </List.Item>
                </List>
                <Switch appearance="checkbox" selected={hasAccepted} onClick={handleClick}>
                    Please accept to continue with your selection.
                </Switch>
            </P>
        </div>
    );
}
