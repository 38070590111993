import templateUrl from './metricsCatalog.tpl.html';
import metricsCatalogController from './metricsCatalogController';

export const metricsPage = {
    bindings: {
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl,
    controller: metricsCatalogController,
};
