//. check permissions
angular
    .module('signalboost')

    .service('UserAuthRestService', [
        'SignalboostBaseService',
        function (SignalboostBaseService) {
            return class UserAuthService extends SignalboostBaseService {
                constructor() {
                    super('auth', false);
                }

                canRead(objectId) {
                    return this.all().customGET(
                        'read',
                        {
                            targetId: objectId,
                        },
                        {}
                    );
                }

                canWrite(objectId) {
                    return this.all().customGET(
                        'write',
                        {
                            targetId: objectId,
                        },
                        {}
                    );
                }

                canDelete(objectId) {
                    return this.all().customGET(
                        'delete',
                        {
                            targetId: objectId,
                        },
                        {}
                    );
                }

                canCreate(objectId, ot) {
                    // uauth endpoint returns a boolean result which gets translated to its string format at xhr response level
                    // parsing out the string value here to get true boolean.
                    return this.all()
                        .customGET(
                            'create',
                            {
                                targetId: objectId,
                                objectType: ot,
                            },
                            {}
                        )
                        .then((allowed) => allowed === true || allowed === 'true');
                }
            };
        },
    ]);
