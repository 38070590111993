import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { cloneDeep, mapValues } from 'lodash';
import {
    K8S_CONTAINERS_MAP_NAV_CODE,
    K8S_NAV_CONTAINER_IDENTIFIER,
    K8S_NAV_NODE_IDENTIFIER,
    K8S_NAV_POD_IDENTIFIER,
    K8S_NODES_MAP_NAV_CODE,
    K8S_PODS_MAP_NAV_CODE,
    NAVIGATOR_VIEW_TYPE_INSTANCE,
} from './crossLinkUtils';

export default [
    '$q',
    '$location',
    '$rootScope',
    'CROSS_LINK_EVENTS',
    'CROSS_LINK_TYPES',
    'EXTERNAL_LINK_OPTIONS',
    'crossLinkService',
    'crossLinkUtils',
    'featureEnabled',
    '$log',
    'Handlebars',
    'urlOverridesService',
    'dashboardVariablesService',
    function (
        $q,
        $location,
        $rootScope,
        CROSS_LINK_EVENTS,
        CROSS_LINK_TYPES,
        EXTERNAL_LINK_OPTIONS,
        crossLinkService,
        crossLinkUtils,
        featureEnabled,
        $log,
        Handlebars,
        urlOverridesService,
        dashboardVariablesService
    ) {
        const defaultPinnedResolution = 60 * 1000; // Resolution +/-1 min
        const sortedOrder = [
            CROSS_LINK_TYPES.SPLUNK_LINK,
            CROSS_LINK_TYPES.EXTERNAL_LINK,
            CROSS_LINK_TYPES.KIBANA_LINK,
        ];
        let crossLinksPromise = null;
        let currentContext;

        $rootScope.$on('React:$routeChangeStart', () => (currentContext = null));
        $rootScope.$on('React:$routeChangeSuccess', () => {
            invalidate(true);
        });

        // (target, filterTerm, dimension, timeRange, contextData) => { ...; return URI; }
        const urlGenerators = {};

        urlGenerators[CROSS_LINK_TYPES.SPLUNK_LINK] = (
            target,
            filterTerm,
            dimension,
            selectedTimeRange,
            contextData
        ) => {
            let splitIndex = filterTerm.search(/:|=/);
            if (splitIndex !== -1) {
                const compoundTerm = filterTerm;
                filterTerm = compoundTerm.substring(0, splitIndex);
                if (++splitIndex < compoundTerm.length) {
                    const splunkValue = compoundTerm.substring(splitIndex);
                    filterTerm +=
                        '="' + (splunkValue === '*' ? dimension.propertyValue : splunkValue) + '"';
                }
            }
            let URI = target + '/en-US/app/search/search?q=' + filterTerm;
            const timeRange = getTimeWindow(selectedTimeRange, contextData);
            if (timeRange && timeRange.from) {
                URI += '&earliest=' + timeRange.from;
            }
            if (timeRange && timeRange.to) {
                URI += '&latest=' + timeRange.to;
            }
            return URI;
        };

        for (const externalLinkType of [
            CROSS_LINK_TYPES.EXTERNAL_LINK,
            CROSS_LINK_TYPES.KIBANA_LINK,
        ]) {
            urlGenerators[externalLinkType] = (
                target,
                filterTerm,
                dimension,
                selectedTimeRange,
                contextData
            ) => {
                // we do not escpe the target URL template itself, this is to avoid double-encoding when user uses encoded url to define datalink.
                // template variables will be escaped below where appropriate.
                // we also do not escape HTML characters in the template, since we are generating a URL, not HTML, which is why we are
                // safe to disable semgrep for this line
                const URI = Handlebars.compile(target, { noEscape: true }); // nosemgrep: tools.semgrep.rules.CCF.handlebars_noescape
                filterTerm = filterTerm.split(':');
                const timeRange = getTimeWindow(selectedTimeRange, contextData);
                return URI({
                    key: encodeURIComponent(filterTerm[0]),
                    value: encodeURIComponent(
                        filterTerm[1] === '*' ? dimension.propertyValue : filterTerm[1]
                    ),
                    start_time:
                        contextData.timeFormat === 'ISO8601'
                            ? new Date(timeRange.from).toISOString()
                            : timeRange.from,
                    end_time:
                        contextData.timeFormat === 'ISO8601'
                            ? new Date(timeRange.to).toISOString()
                            : timeRange.to,
                    properties: mapValues(contextData.properties, encodeURIComponent),
                });
            };
        }

        /* Params: (crosslink, dimension)
         * Returns:
         *      {context, target, targetName, targetMenuLabel, targetGenerator, targetMap, contextData};
         * OR   null
         */
        const linkers = {};

        linkers[CROSS_LINK_TYPES.SPLUNK_LINK] = (link, dimension, aliases) => {
            const keyMapping = getAliasedPropertyMapping(link, aliases);

            let key;
            // Fallback from Specific to Generic
            let mapped =
                keyMapping[(key = dimension.propertyName + ':' + dimension.propertyValue)] ||
                keyMapping[(key = dimension.propertyName)];

            if (mapped === '') {
                // Freeform Mapping
                mapped = dimension.propertyValue || key;
            } else {
                // Value Mapping
                mapped = mapped || key;
                if (!key.includes(':') && !mapped.includes(':')) {
                    mapped += ':*';
                }
            }

            return {
                aliases: aliases,
                context: CROSS_LINK_TYPES.SPLUNK_LINK,
                target: link.url,
                targetName: link.name,
                targetMenuLabel: 'Examine in ' + link.name,
                targetGenerator: urlGenerators[CROSS_LINK_TYPES[link.type]],
                targetMap: mapped,
                contextData: {
                    resolution: link.minimumTimeWindow,
                    timeFormat: link.timeFormat,
                },
                isDefault: !!link.isDefault,
                isLocal: !!link.isLocal,
            };
        };

        for (const externalLinkType of [
            CROSS_LINK_TYPES.EXTERNAL_LINK,
            CROSS_LINK_TYPES.KIBANA_LINK,
        ]) {
            linkers[externalLinkType] = (link, dimension, aliases) => {
                const keyMapping = getAliasedPropertyMapping(link, aliases);
                const originalPropertyName = link.propertyName;

                // if mapping service is enabled and we have aliases to use, then add the aliased property names
                // into the perceived propertyKeyMapping so we properly map the semantic value to the desired target.
                if (
                    featureEnabled('mappingService') &&
                    link.aliases &&
                    keyMapping[originalPropertyName]
                ) {
                    const existingMappingTarget = keyMapping[originalPropertyName];
                    aliases
                        .filter((e) => e !== link.propertyName)
                        .forEach((aliasedProperty) => {
                            if (!keyMapping[aliasedProperty]) {
                                keyMapping[aliasedProperty] = existingMappingTarget;
                            }
                        });
                }

                let key;
                // Fallback from Specific to Generic
                let mapped =
                    keyMapping[(key = dimension.propertyName + ':' + dimension.propertyValue)] ||
                    keyMapping[(key = dimension.propertyName)] ||
                    key;

                if (!key.includes(':') && !mapped.includes(':')) {
                    mapped += ':*';
                }

                return {
                    aliases: aliases,
                    context: externalLinkType,
                    target: link.url,
                    targetName: link.name,
                    targetGenerator: urlGenerators[CROSS_LINK_TYPES[link.type]],
                    targetMap: mapped,
                    contextData: {
                        resolution: link.minimumTimeWindow,
                        timeFormat: link.timeFormat,
                    },
                    isDefault: !!link.isDefault,
                    isLocal: !!link.isLocal,
                };
            };
        }

        function getAliasedPropertyMapping(link, aliases) {
            let keyMapping = link.propertyKeyMapping || {};
            // if mapping service is enabled and we have aliases to use, then add the aliased property names
            // into the perceived propertyKeyMapping so we properly map the semantic value to the desired target.
            if (featureEnabled('mappingService') && aliases) {
                keyMapping = remapPropertyKeyMapWithAliases(link, aliases);
            }

            return keyMapping;
        }

        function remapPropertyKeyMapWithAliases(link, aliases) {
            // compose a mapping of all keys being aliased on the crosslink itself, so we can determine what needs to be mapped
            const keyMapping = link.propertyKeyMapping || {};
            const keyMappingByProperty = {};
            for (const [key, value] of Object.entries(keyMapping)) {
                let property;
                if (key.indexOf(':') !== -1) {
                    property = key.split(':')[0];
                } else {
                    property = key;
                }
                if (!keyMappingByProperty[property]) {
                    keyMappingByProperty[property] = {};
                }
                keyMappingByProperty[property][key] = value;
            }

            for (const [aliasedProperty, aliasedValues] of Object.entries(aliases)) {
                aliasedValues.forEach((prop) => {
                    // ignoring self, if a mapping exists for an alias of the property in question, then
                    // copy all crosslink mappings with the property replaced by the alias
                    if (prop !== aliasedProperty && keyMappingByProperty[aliasedProperty]) {
                        for (const [mapKey, mapValue] of Object.entries(
                            keyMappingByProperty[aliasedProperty]
                        )) {
                            const mappedAliasKey = mapKey.replace(aliasedProperty, prop);
                            if (!keyMapping[mappedAliasKey]) {
                                keyMapping[mappedAliasKey] = mapValue;
                            } else {
                                $log.info(
                                    'Refused to overwrite an existing crosslink mapping for ' +
                                        mappedAliasKey
                                );
                            }
                        }
                    }
                });
            }
            return keyMapping;
        }

        function resolvePinnedTime(time, defaultResolution) {
            let resolution = time && time.resolution ? time.resolution : defaultResolution;
            resolution = Math.max(resolution, defaultResolution);

            const halfResolution = parseInt(resolution / 2);
            const pinnedTime =
                time && time.pinnedTime ? time.pinnedTime : Date.now() - halfResolution;

            return {
                start: pinnedTime - halfResolution,
                end: pinnedTime + halfResolution,
                relative: false,
            };
        }

        function getTimeWindow(selectedTimeRange, contextData) {
            const contextResolution =
                (contextData && contextData.resolution) || defaultPinnedResolution;
            const timeRange = cloneDeep(selectedTimeRange);
            if (timeRange) {
                if (timeRange.pinnedTime) {
                    timeRange.resolution = Math.max(timeRange.resolution || 0, contextResolution);
                } else {
                    if (timeRange.relative) {
                        timeRange.end =
                            Date.now() +
                            (timeRange.end === 'Now' || timeRange.end === 'now'
                                ? 0
                                : convertStringToMS(timeRange.end));
                    }
                    timeRange.start = timeRange.end - contextResolution;
                    timeRange.relative = false;
                }
            }
            return parseTime(
                timeRange,
                contextResolution,
                true,
                EXTERNAL_LINK_OPTIONS.TIME_FORMATS[contextData.timeFormat] ===
                    EXTERNAL_LINK_OPTIONS.TIME_FORMATS.EpochSeconds
            );
        }

        function parseTime(timepicker, defaultResolution, convertToAbsolute, toUnixTime) {
            if (!timepicker || timepicker.pinnedTime) {
                timepicker = resolvePinnedTime(timepicker, defaultResolution);
            }

            const absTime = { from: null, to: null, relative: null };
            if (timepicker) {
                if (timepicker.relative && convertToAbsolute) {
                    absTime.relative = false;
                    if (timepicker.end) {
                        absTime.to =
                            timepicker.end === 'Now' || timepicker.end === 'now'
                                ? Date.now()
                                : convertStringToMS(timepicker.end);
                    }
                    if (timepicker.start) {
                        absTime.from = convertStringToMS(timepicker.start);
                        if (absTime.from < 0 && absTime.to) {
                            absTime.from += absTime.to;
                        }
                    }
                } else {
                    absTime.relative = timepicker.relative;
                    if (timepicker.start) {
                        absTime.from = timepicker.start;
                    }
                    if (timepicker.end) {
                        absTime.to = timepicker.end;
                    }
                }
            }
            if (convertToAbsolute && toUnixTime) {
                if (absTime.from) {
                    absTime.from = parseInt(absTime.from / 1000);
                }
                if (absTime.to) {
                    absTime.to = parseInt(absTime.to / 1000);
                }
            }
            return absTime;
        }

        function getCrossLinks() {
            if (!crossLinksPromise) {
                const allLinksPromise = crossLinkService.getAll().then((data) => {
                    return crossLinkUtils.indexByTypeAndTrigger(data.crossLinks);
                });

                let contextLinksPromise;

                if (isContextualRoute()) {
                    if (currentContext) {
                        contextLinksPromise = crossLinkService.searchByContext(currentContext, {
                            limit: 10000,
                        });
                    } else {
                        $log.error('Missing crossLink context');
                    }
                }

                crossLinksPromise = $q
                    .all({
                        globalLinks: allLinksPromise,
                        contextualLinks: contextLinksPromise || $q.when(),
                    })
                    .then(({ globalLinks, contextualLinks }) => {
                        contextualLinks = contextualLinks ? contextualLinks.results : null;
                        return crossLinkUtils.getContextualizedCrossLinks(
                            globalLinks,
                            contextualLinks
                        );
                    });
            }

            return crossLinksPromise;
        }

        function getCrossLinksCopy() {
            return getCrossLinks().then(angular.copy);
        }

        function getInternalLinkByTrigger(propertyName, propertyValue, context) {
            return getCrossLinks().then((crossLinks) => {
                const trigger = crossLinkUtils.getTriggerString(propertyName, propertyValue);
                const fallbackTrigger = crossLinkUtils.getTriggerString(propertyName);
                const matchAllTrigger = crossLinkUtils.getTriggerString();
                const internal = crossLinks[CROSS_LINK_TYPES.INTERNAL_LINK];

                // default to the most specific match
                const link =
                    internal[trigger] || internal[fallbackTrigger] || internal[matchAllTrigger];

                if (link) {
                    const conditionedLink = getConditionedLink(link, context);
                    if (conditionedLink) {
                        return angular.copy(conditionedLink);
                    }
                }
            });
        }

        function setCurrentContext(contextId) {
            if (contextId && currentContext !== contextId) {
                currentContext = contextId;
            }
        }

        function attachCrosslinkTargets(dimensions, context) {
            return getCrossLinks().then((crossLinks) => {
                let atLeastOneDimensionHasTargets = false;

                dimensions.forEach((dimension) => {
                    if (dimension.propertyName) {
                        let dimensionHasTargets = false;
                        // Get internal links
                        const { propertyName, propertyValue } = dimension;
                        const keys = {
                            pairLink: crossLinkUtils.getTriggerString(propertyName, propertyValue),
                            propertyLink: crossLinkUtils.getTriggerString(propertyName),
                            matchAllLink: crossLinkUtils.getTriggerString(),
                        };

                        const internal = crossLinks[CROSS_LINK_TYPES.INTERNAL_LINK];

                        const links = {
                            internal: {},
                            external: {},
                        };

                        _.each(keys, (key, linkType) => {
                            // Internal Links
                            if (internal[key]) {
                                const conditionedLink = getConditionedLink(internal[key], context);
                                if (conditionedLink) {
                                    links.internal[linkType] = conditionedLink;
                                    dimensionHasTargets = true;
                                }
                            }

                            // All External Links by their linker types
                            for (const type in linkers) {
                                const linker = linkers[type];
                                if (crossLinks[type]) {
                                    const external = crossLinks[type];
                                    if (!links.external[type]) {
                                        links.external[type] = [];
                                    }
                                    if (external[key]) {
                                        const conditionedLink = getConditionedLink(
                                            external[key],
                                            context
                                        );
                                        if (conditionedLink) {
                                            const targets = conditionedLink.targets;
                                            for (const index in targets) {
                                                links.external[type].push(
                                                    linker(
                                                        targets[index],
                                                        dimension,
                                                        conditionedLink.aliases
                                                    )
                                                );
                                            }
                                            dimensionHasTargets = true;
                                        }
                                    }
                                }
                            }
                        });

                        let external = [];
                        for (const index in sortedOrder) {
                            const type = sortedOrder[index];
                            if (links.external[type]) {
                                external = external.concat(links.external[type]);
                            }
                        }

                        links.external = external;

                        if (dimensionHasTargets) {
                            dimension.targets = links;
                            dimension.targetsAvailable = true;
                            atLeastOneDimensionHasTargets = true;
                        }
                    }
                });
                return atLeastOneDimensionHasTargets;
            });
        }

        /**
         * Return navigator propertyIdentifierTemplate.
         * In the case of K8s map navigator returns {{k8s.node.name}}, {{k8s.pod.name}}, {{container.id}}
         * @param target
         * @returns {*|string}
         */
        function getPropertyIdentifierTemplate(target) {
            if (!target) {
                return target;
            }
            if (target.navigatorCode === K8S_NODES_MAP_NAV_CODE) {
                return `{{${K8S_NAV_NODE_IDENTIFIER}}}`;
            } else if (target.navigatorCode === K8S_PODS_MAP_NAV_CODE) {
                return `{{${K8S_NAV_POD_IDENTIFIER}}}`;
            } else if (target.navigatorCode === K8S_CONTAINERS_MAP_NAV_CODE) {
                return `{{${K8S_NAV_CONTAINER_IDENTIFIER}}}`;
            }

            return target.navigatorPropertyIdentifierTemplate;
        }

        /*
         * add additional k8s context that should be included in the resulting k8s link.
         * See crossLinkUtils#addK8sBreadcrumbAndFilters where any available parent k8s
         * resource gets included as a filter to the navigator redirect url.
         *
         */
        function addAvailableK8sPropertiesAsContext(target, aliasedContext) {
            target.requiredContext = {
                'k8s.cluster.name': aliasedContext['k8s.cluster.name'],
                'k8s.node.name': aliasedContext[K8S_NAV_NODE_IDENTIFIER],
                'k8s.pod.name': aliasedContext[K8S_NAV_POD_IDENTIFIER],
                'container.id': aliasedContext[K8S_NAV_CONTAINER_IDENTIFIER],
                'k8s.container.name': aliasedContext['k8s.container.name'],
            };
        }

        /**
         * For navigator instance types, the property identifier template must be able to be fully resolved from the sourrounding context properties
         * @param target
         * @param aliasedContext
         * @param conditionsMatched
         * @returns {boolean}
         */
        function handleNavigatorConditionsMatched(target, aliasedContext) {
            let conditionsMatched = true;
            if (
                target.navigatorView === NAVIGATOR_VIEW_TYPE_INSTANCE &&
                !!target.navigatorPropertyIdentifierTemplate
            ) {
                let navigatorPropertyIdentifierTemplate =
                    target.navigatorPropertyIdentifierTemplate;
                if (crossLinkUtils.isK8sMapNavigator(target.navigatorCode)) {
                    navigatorPropertyIdentifierTemplate = getPropertyIdentifierTemplate(target);
                }
                if (
                    !crossLinkUtils.isTemplateResolvable(
                        navigatorPropertyIdentifierTemplate,
                        aliasedContext
                    )
                ) {
                    // if the template was not fully resolved based on the surrounding context properties, then this link target is not a match and should not be displayed
                    conditionsMatched = false;
                } else {
                    // if the instance id can be fully resolved, record the fully resolved template for the navigator instance target (ie: mapSelection)
                    target.navigatorPropertyIdentifierTemplateResolved =
                        crossLinkUtils.resolveTemplate(
                            navigatorPropertyIdentifierTemplate,
                            aliasedContext
                        );
                }
            }

            if (crossLinkUtils.isK8sMapNavigator(target.navigatorCode)) {
                addAvailableK8sPropertiesAsContext(target, aliasedContext);
            }
            return conditionsMatched;
        }

        function getConditionedLink(crossLink, context) {
            const targets = crossLink.targets;
            const aliasedContext = getAliasedContext(context, crossLink.aliases);

            const filteredTargets = targets.filter((target) => {
                let conditionsMatched =
                    !target.conditions ||
                    target.conditions.every(({ property, values, not }) => {
                        const filterMatch =
                            aliasedContext[property] &&
                            (values.length === 0 || values.includes(aliasedContext[property]));
                        return not ? !filterMatch : filterMatch;
                    });

                if (conditionsMatched) {
                    // for navigator instance links, the properties required to render the propertyIdentifierTemplate must be available, or else
                    // the link target is treated as if the conditions do not match
                    if (target.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK) {
                        conditionsMatched = handleNavigatorConditionsMatched(
                            target,
                            aliasedContext
                        );
                    }
                }

                return conditionsMatched;
            });

            if (filteredTargets.length === 0) {
                return undefined;
            }

            const defaultLink = filteredTargets.includes(crossLink.defaultLink)
                ? crossLink.defaultLink
                : undefined;

            return Object.assign({}, crossLink, { defaultLink, targets: filteredTargets });
        }

        function getAliasedContext(context, aliases) {
            const aliasedContext = Object.assign({}, context);

            if (featureEnabled('mappingService') && aliases) {
                _.forOwn(context, (value, key) => {
                    if (aliases[key]) {
                        aliases[key].forEach((aliasedProperty) => {
                            aliasedContext[aliasedProperty] = value;
                        });
                    }
                });
            }

            return aliasedContext;
        }

        function getExtendedContext(dimensions, aliases, ...contexts) {
            const availableDimensions = {};

            // Make all dimensions also available as context
            dimensions.forEach((dim) => {
                availableDimensions[dim.propertyName] = dim.propertyValue;
            });

            const extendedContext = Object.assign(availableDimensions, ...contexts);

            return getAliasedContext(extendedContext, aliases);
        }

        function invalidate(isRouteChange) {
            // skip invalidation if there is no cached promise.
            if (crossLinksPromise) {
                crossLinksPromise = null;

                // broadcast a message when invalidation happens outside of a route
                // change, so that components know they need to refresh their understanding
                // of the state of cross links. During route change this message is not
                // necessary.
                if (!isRouteChange) {
                    $rootScope.$broadcast(CROSS_LINK_EVENTS.INVALIDATED_CACHE);
                }
            }
        }

        function isContextualRoute() {
            // currently only dashboard view have contextual cross links
            return !!$location.url().match(/^\/dashboard/);
        }

        function getContextFromURLOverrides() {
            const context = {};
            const urlFilters = urlOverridesService.getSourceFilterOverrideList() || [];
            const urlVariables = dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
            const urlFilterState = urlFilters.concat(urlVariables);

            urlFilterState.forEach(function (item) {
                // NOT filters are not supported (Creates ambiguous context)
                const value = item.propertyValue || item.value;
                if (!item.NOT && item.property && value) {
                    // Filters with multiple values are not supported (Creates ambiguous context)
                    if (angular.isArray(value)) {
                        if (value.length === 1) {
                            context[item.property] = value[0];
                        }
                    } else {
                        context[item.property] = value;
                    }
                }
            });

            return context;
        }

        return {
            attachCrosslinkTargets,
            getCrossLinks: getCrossLinksCopy,
            getInternalLinkByTrigger,
            invalidate,
            setCurrentContext,
            hasSetContext() {
                return !!currentContext;
            },
            getContextFromURLOverrides,
            getAliasedContext,
            getPropertyIdentifierTemplate,
            getExtendedContext,
            remapPropertyKeyMapWithAliases, // public for testing
        };
    },
];
