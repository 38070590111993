import templateUrl from './objectRelatedPlugins.tpl.html';

angular.module('signalview.collectdPlugins').directive('objectRelatedPlugins', [
    '$location',
    'currentUser',
    'catalogPluginDocumentation',
    function ($location, currentUser, catalogPluginDocumentation) {
        return {
            restrict: 'E',
            scope: {
                availablePlugins: '=',
                object: '=',
                filters: '=',
                isNewCatalog: '=',
            },
            templateUrl,
            link: function ($scope) {
                function isInFilter(object) {
                    if (!$scope.filters || !object.sf_key) return false;

                    return $scope.filters.some(function (filter) {
                        if (filter.property === 'sf_key') {
                            return filter.propertyValue === object.sf_topic;
                        } else {
                            return (
                                filter.property === object.sf_key[0] &&
                                filter.propertyValue === object[filter.property]
                            );
                        }
                    });
                }

                $scope.showPlugin = function (pluginName) {
                    const object = $scope.object;
                    const objectInFilter = isInFilter(object);

                    let key = object.sf_key[0];
                    let query;

                    if (object.sf_type === 'Topic') {
                        query = 'sf_key:' + object.sf_topic;
                    } else {
                        if (key === 'sf_tag') key = 'sf_tags';
                        query = key + ':' + object[key];
                    }

                    const search = $location.search();

                    if (!objectInFilter) {
                        const sources = search['sources[]'] || [];
                        sources.push(query);
                        search['sources[]'] = sources;
                    }

                    search.topicFilterKey = 'plugin';

                    if (pluginName) {
                        delete search.select;
                        search.selectedKeyValue = 'plugin:' + pluginName;
                    }
                    $location.search(search);
                };

                $scope.showPlugins = function (selectedPlugin) {
                    const object = $scope.object;
                    const objectInFilter = isInFilter(object);

                    let key = object.sf_key[0];
                    let query;

                    if (object.sf_type === 'Topic') {
                        query = 'sf_key:' + object.sf_topic;
                    } else {
                        if (key === 'sf_tag') key = 'sf_tags';
                        query = key + ':' + object[key];
                    }

                    const search = $location.search();

                    if (!objectInFilter) {
                        const sources = search['sources[]'] || [];
                        sources.push(query);
                        search['sources[]'] = sources;
                    }

                    search.topicFilterKey = 'plugin';

                    if (selectedPlugin) {
                        delete search.select;
                        search.selectedKeyValue = 'plugin:' + selectedPlugin.plugin;
                    }

                    currentUser.orgId().then(function (orgId) {
                        // Ensure that the user is directed to the catalog if they're not there
                        // already (if they're in a restricted view).
                        $location.path('/catalog/' + orgId);
                        $location.search(search);
                    });
                };

                $scope.$watch('availablePlugins', function () {
                    if (!$scope.availablePlugins) return;
                    $scope.pluginDescription = {};
                    $scope.availablePlugins.forEach(function (plugin) {
                        catalogPluginDocumentation
                            .getPluginDocumentation(plugin)
                            .then(function (metadata) {
                                if (metadata && metadata.yaml.brief) {
                                    $scope.pluginDescription[plugin] = metadata.yaml.brief;
                                }
                            });
                    });
                });
            },
        };
    },
]);
