import templateUrl from './metricsSidebar.tpl.html';

angular.module('signalview.metricsSidebar').directive('metricsSidebar', [
    'METRIC_TYPE',
    'EVENT_TYPE',
    '$q',
    '$timeout',
    'Chart',
    'awsMetricInformation',
    'azureMetricInformation',
    'currentUser',
    'dashboardV2Service',
    'dashboardUtil',
    '$http',
    'API_URL',
    function (
        METRIC_TYPE,
        EVENT_TYPE,
        $q,
        $timeout,
        Chart,
        awsMetricInformation,
        azureMetricInformation,
        currentUser,
        dashboardV2Service,
        dashboardUtil,
        $http,
        API_URL
    ) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl,
            scope: {
                currentDashboard: '=?',
                currentCharts: '=?',
                workspaceMode: '=?',
                saveWorkspace: '=?',
                visible: '=?',
                viewType: '<?',
                metricsOnly: '<?',
            },
            link: function ($scope) {
                // -------------
                // Adding charts
                // -------------
                function addPlotToChart(chartModel, item, selectedFilters, addFiltersToPlots) {
                    const plot = chartModel.plot();
                    if (addFiltersToPlots) {
                        const combinedFilters = (item.filters || []).concat(selectedFilters);
                        if (combinedFilters && combinedFilters.length) {
                            const plotFilters = getPlotFilters(combinedFilters);
                            const filtersStr = getFilterString(combinedFilters);
                            plot.propertyFilters(plotFilters);
                            chartModel.description('Filters: ' + filtersStr);
                        }
                    }
                    plot.name(item.name);

                    if (item.type === METRIC_TYPE) {
                        plot.type('plot');
                        plot.metric(item.name);
                        if (
                            awsMetricInformation.isAWSMetric(item.name) ||
                            azureMetricInformation.isAzureMetric(item.name)
                        ) {
                            // If it's an AWS/Azure metric, change the time range to 1h so we pick
                            // up some values on the chart
                            chartModel.range(3600000, 0);
                        }
                    } else {
                        plot.type('event');
                        if (item.type === EVENT_TYPE) {
                            plot.eventQuery(item.name);
                        } else {
                            plot.detectorQuery(item.name);
                        }
                    }
                    // For Graphite (e.g., a.* matches a.b but not a.b.c) and New Relic
                    // (e.g., a/* matches a/b but not a/b/c) style charts
                    if (item.regExStyle && item.regExStyle !== 'plain') {
                        plot.regExStyle(item.regExStyle);
                    }
                }

                function getPlotFilters(filters) {
                    const plotFilters = {};
                    filters.forEach(function (filter) {
                        const name = (filter.NOT ? '!' : '') + filter.name;
                        plotFilters[name] = filter.currentValue;
                    });
                    return plotFilters;
                }

                function getFilterString(filters) {
                    return filters
                        .map(function (filter) {
                            return (
                                (filter.NOT ? '!' : '') + filter.name + ':' + filter.currentValue
                            );
                        })
                        .join(', ');
                }

                // Add chart(s) to the dashboard with plots for the given
                // metrics/events, with all metrics/events in the same chart if
                // "singleChart" is true
                function addChartsToDashboard(
                    items,
                    selectedFilters,
                    singleChart,
                    addFiltersToPlots
                ) {
                    if (!items.length) {
                        return $q.when();
                    }

                    // The $q.when used below will cause this function to be run
                    // synchronously, meaning the loading indicator not to be shown until
                    // everything in this function has completed and a digest is
                    // performed. Work around this by using a timeout to have this code
                    // run asynchronously.
                    return $timeout(function () {
                        const chartModels = [];

                        if (singleChart) {
                            // Single chart containing all plots
                            const singleModel = Chart.create();
                            let singleName = items[0].name;
                            if (items.length > 1) {
                                singleName += ' and ' + (items.length - 1) + ' more';
                            }
                            singleModel.name(singleName);
                            if (
                                !items.some(function (item) {
                                    return item.type === METRIC_TYPE;
                                })
                            ) {
                                // Create event feed chart if there are only eventType or
                                // detector results
                                singleModel.mode('event');
                            }

                            for (let i = 0; i < items.length; i++) {
                                addPlotToChart(
                                    singleModel,
                                    items[i],
                                    selectedFilters,
                                    addFiltersToPlots
                                );
                            }
                            chartModels.push(singleModel);
                        } else {
                            // Multiple charts, one plot each
                            for (let j = 0; j < items.length; j++) {
                                const multipleModel = Chart.create();
                                multipleModel.name(items[j].name);
                                if (items[j].type !== METRIC_TYPE) {
                                    // Create event feed chart
                                    multipleModel.mode('event');
                                }

                                addPlotToChart(
                                    multipleModel,
                                    items[j],
                                    selectedFilters,
                                    addFiltersToPlots
                                );
                                chartModels.push(multipleModel);
                            }
                        }

                        // Save charts in the dashboard
                        let prevNumCharts = 0;
                        let dashboardPromise;
                        if ($scope.workspaceMode) {
                            dashboardPromise = $q.when($scope.currentDashboard);
                        } else {
                            dashboardPromise = dashboardV2Service.get($scope.currentDashboard.id);
                        }

                        let savedCharts = [];

                        return dashboardPromise.then(function (dashboard) {
                            // Create new empty charts to create ids for them
                            const newCharts = chartModels.map((c) => c.asV2());
                            return currentUser
                                .orgId()
                                .then(function (orgId) {
                                    prevNumCharts = (dashboard.charts || []).length;
                                    if ($scope.workspaceMode) {
                                        dashboardV2Service.addTransientCharts(dashboard, newCharts);
                                        savedCharts = newCharts;
                                        return dashboard;
                                    } else {
                                        return $q
                                            .all(
                                                newCharts.map((chart) => {
                                                    return $http.post(
                                                        API_URL +
                                                            '/v2/chart' +
                                                            '?organizationId=' +
                                                            orgId,
                                                        chart
                                                    );
                                                })
                                            )
                                            .then((charts) => {
                                                savedCharts = charts.map((c) => c.data);
                                                return dashboardV2Service.addCharts(
                                                    dashboard,
                                                    savedCharts
                                                );
                                            });
                                    }
                                })
                                .then(function (updatedDashboard) {
                                    dashboardUtil.scrollToFirstChart(
                                        prevNumCharts,
                                        updatedDashboard
                                    );
                                    for (let n = 0; n < savedCharts.length; n++) {
                                        const newChart = savedCharts[n];
                                        newChart.sf_modelVersion = 2;
                                        const chartId = newChart.sf_id || newChart.id;
                                        $scope.currentCharts[chartId] = newChart;
                                    }
                                    $scope.currentDashboard.charts = updatedDashboard.charts;
                                    if ($scope.workspaceMode && $scope.saveWorkspace) {
                                        return $scope.saveWorkspace();
                                    } else {
                                        return $q.when();
                                    }
                                });
                        });
                    }, 0);
                }

                // Construct signal objects to plot for the given search result items
                function makeSignalObjects(items, selectedFilters, addFiltersToPlots) {
                    const signals = items.map(function (item) {
                        const signal = { value: item.value, type: item.type, plotFilters: {} };

                        if (addFiltersToPlots) {
                            // Pass along selected filters
                            const combinedFilters = (item.filters || []).concat(selectedFilters);
                            if (combinedFilters && combinedFilters.length) {
                                const plotFilters = getPlotFilters(combinedFilters);
                                signal.plotFilters = plotFilters;
                            }
                        }

                        return signal;
                    });
                    if (items.length) {
                        $scope.$emit('plotSignalsSelected', signals);
                    }
                    return $q.when();
                }

                $scope.onSelect = function (
                    items,
                    selectedFilters,
                    singleItemMode,
                    addFiltersToPlots
                ) {
                    if ($scope.viewType === 'dashboard') {
                        return addChartsToDashboard(
                            items,
                            selectedFilters,
                            singleItemMode,
                            addFiltersToPlots
                        );
                    } else {
                        return makeSignalObjects(items, selectedFilters, addFiltersToPlots);
                    }
                };
            },
        };
    },
]);
