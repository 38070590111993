import { addCommonHeaders } from '../data/apiv2/createV2Service';
import _ from 'lodash';

const SEARCH_PARAMS_KEYS = ['limit', 'offset', 'orderBy'];

angular.module('signalboost').service('DetectorV2SearchService', [
    'detectorV2Converter',
    'detectorv2Service',
    '$http',
    'API_URL',
    function (detectorV2Converter, detectorv2Service, $http, API_URL) {
        const resourceEndpoint = `${API_URL}/v2/detector/_`;
        const mapModelToV1 = (detectorModel) => {
            const output = detectorV2Converter.v2ModelToV1Model(detectorModel, {
                visualizationOptions: detectorModel.visualizationOptions,
            });

            return output;
        };

        return {
            search(params) {
                return $http
                    .post(
                        `${resourceEndpoint}/search`,
                        onlyDataParams(params),
                        addCommonHeaders({
                            params: onlySearchParams(params),
                        })
                    )
                    .then((res) => ({
                        ...onlySearchParams(params),
                        rs: res.data.results?.map(mapModelToV1),
                        count: res.data.count,
                    }));
            },
            get(detectorId) {
                return detectorv2Service.get(detectorId).then(mapModelToV1);
            },
        };
    },
]);

function onlyDataParams(params) {
    return _.pickBy(
        params,
        (value, key) => value !== undefined && !SEARCH_PARAMS_KEYS.includes(key)
    );
}

function onlySearchParams(params) {
    return _.pick(params, SEARCH_PARAMS_KEYS);
}
