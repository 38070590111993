export default {
    BASE_METRIC_TEMPLATES: [
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_workload',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_workload_name',
                    type: 'property',
                },
            ],
            template:
                "POD_PHASE = data('kubernetes.pod_phase'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
    ],
    METRICS: [
        {
            displayName: 'Pending',
            job: {
                template:
                    "POD_STATUS_PENDING = POD_PHASE.equals(1).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_PENDING',
            },
        },
        {
            displayName: 'Running',
            job: {
                template:
                    "POD_STATUS_RUNNING = POD_PHASE.equals(2).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_RUNNING',
            },
        },
        {
            displayName: 'Succeeded',
            job: {
                template:
                    "POD_STATUS_SUCCEEDED = POD_PHASE.equals(3).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_SUCCEEDED',
            },
        },
        {
            displayName: 'Failed',
            job: {
                template:
                    "POD_STATUS_FAILED = POD_PHASE.equals(4).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_FAILED',
            },
        },
        {
            displayName: 'Unknown',
            job: {
                template:
                    "POD_STATUS_UNKNOWN = POD_PHASE.equals(5).count(by=['kubernetes_workload_name', 'kubernetes_cluster', 'kubernetes_workload', 'kubernetes_namespace'])",
                varName: 'POD_STATUS_UNKNOWN',
            },
        },
    ],
    COLUMNS: [
        {
            aggregate: null,
            displayName: 'Workload',
            format: 'link',
            metric: null,
            property: 'kubernetes_workload_name',
            propertiesToLinkOn: [
                'kubernetes_cluster',
                'kubernetes_workload_name',
                'kubernetes_namespace',
            ],
            panelName: 'kubernetes_workload_name',
        },
        {
            aggregate: null,
            displayName: 'Workload Type',
            format: null,
            metric: null,
            property: 'kubernetes_workload',
        },
        {
            aggregate: null,
            displayName: 'Pending Pods',
            format: 'Number',
            metric: null,
            property: 'Pending',
        },
        {
            aggregate: null,
            displayName: 'Running Pods',
            format: 'Number',
            metric: null,
            property: 'Running',
        },
        {
            aggregate: null,
            displayName: 'Succeeded Pods',
            format: 'Number',
            metric: null,
            property: 'Succeeded',
        },
        {
            aggregate: null,
            displayName: 'Failed Pods',
            format: 'Number',
            metric: null,
            property: 'Failed',
        },
        {
            aggregate: null,
            displayName: 'Unknown Pods',
            format: 'Number',
            metric: null,
            property: 'Unknown',
        },
    ],
    UNIQUE_ID: '{{ kubernetes_workload_name }}',
    ENTITY_TYPE: 'Workloads',
};
