import React, { useState } from 'react';
import ShowMore from './nextSteps/components/ShowMore';
import { AIIcon } from '@splunk/ai-framework-ux';
import Button from '@splunk/react-ui/Button';
import { AngularInjector } from '../../common/AngularUtils';
import { truncateLongPercentageValues } from './utils';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';
import Markdown from '@splunk/react-ui/Markdown';
import useAIWebSocket from './useAIWebSocket';
import { REALM } from '../../legacy/common/consts.js';

const NO_MESSAGE_TEXT = 'No message has been set for this detector.';
const LENGTH_LIMIT = 500;
const AlertEventMessage = React.memo(
    ({ sanitizer, markdownConverter, incidentId, message, alertTitle }) => {
        const [showAIMessage, setShowAIMessage] = useState(false);
        const featureEnabled = AngularInjector.instantiate('featureEnabled');
        const AIInsightEnabled = featureEnabled('apm2ShowAIInsight');
        const { messages, sendMessage, error } = useAIWebSocket(
            `wss://experimental.${REALM}.signalfx.com/v2/aiassist/connect`,
            AIInsightEnabled && message
        );
        const regex = /apm/i;
        const errorRegex = /(error|error rate)/i;
        const isAlertForAPMError = regex.test(alertTitle) && errorRegex.test(alertTitle);

        const clickAIInsightButton = () => {
            setShowAIMessage(true);
            sendMessage(incidentId, isAlertForAPMError);
        };
        const renderMessageMarkup = (text) => {
            const truncated = truncateLongPercentageValues(text);
            return <div dangerouslySetInnerHTML={{ __html: createMarkup(truncated) }} />;
        };

        const createMarkup = (text) => {
            return sanitizer(markdownConverter.makeHtml(text));
        };

        return (
            <>
                {!message ? (
                    NO_MESSAGE_TEXT
                ) : (
                    <>
                        <ShowMore
                            text={message}
                            maxLength={LENGTH_LIMIT}
                            textRenderer={renderMessageMarkup}
                        ></ShowMore>
                        {AIInsightEnabled && !showAIMessage && (
                            <Button
                                onClick={clickAIInsightButton}
                                icon={<AIIcon width={16} height={16} />}
                                label="Get AI Insight"
                                appearance="flat"
                            />
                        )}
                        {showAIMessage && (
                            <div>
                                {error && <div>{error}</div>}
                                {!error && messages.length === 0 && <WaitSpinner />}
                                {!error && messages.length !== 0 && (
                                    <Markdown text={messages.join('')} />
                                )}
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }
);

export default AlertEventMessage;
AlertEventMessage.displayName = 'AlertEventMessage';
