import dropdownSelector from './dropdownSelector/dropdownSelector';

const DROPDOWN_EVENTS = {
    OPEN_MENU: 'sfCommonDropdown.openMenu',
    RESET_SELECTION_STATE: 'sfCommonDropdown.resetSelectionState',
};

angular
    .module('sfx.ui.dropdowns', ['sfUtil'])

    .constant('DROPDOWN_EVENTS', DROPDOWN_EVENTS)

    // components
    .component('dropdownSelector', dropdownSelector);
