import templateUrl from './hostListTable.tpl.html';

angular.module('signalview.heatmap').directive('hostListTable', [
    'sidebarService',
    function (sidebarService) {
        return {
            restrict: 'E',
            scope: {
                hosts: '=',
                heatmap: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.sidebarService = sidebarService;

                    const heatmap = $scope.heatmap;

                    $scope.updateTimeRange = function (timeRange) {
                        heatmap.timeRange(timeRange);
                    };
                },
            ],
        };
    },
]);
