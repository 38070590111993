angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Mem. Page Swaps, 1-min Sum')
                .mode('list')
                .type('line')
                .sort('+source')
                .maxDecimalPlaces(3)
                .updateInterval(5000);

            const swapIn = chart
                .plot()
                .metric('vmpage_io.swap.in')
                .name('swapped in')
                .propertyFilter('plugin', 'vmem')
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asTransformation(1, 'm'))
                .addManipulation(blockService.sum.asAggregation());

            swapIn.cloneTo(chart).metric('vmpage_io.swap.out').name('swapped out');

            return chart;
        }

        chartTemplates.register('collectdMemPageSwaps', createChart);
    },
]);
