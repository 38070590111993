import templateUrl from './detectorNotifyBlock.tpl.html';

angular.module('signalview.detector.notification').directive('detectorNotifyBlock', [
    'notificationsUtil',
    'NOTIFIER_INTEGRATIONS',
    'TYPE_TO_SERVICE_INTEGRATIONS',
    function (notificationsUtil, NOTIFIER_INTEGRATIONS, TYPE_TO_SERVICE_INTEGRATIONS) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                credentials: '=credentials',
                detector: '=?',
                rule: '=?',
                readOnly: '=?',
                remove: '&onRemove',
                doneEdit: '&onFinishEdit',
                teams: '<',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.normalizeTypeIdentifier = notificationsUtil.normalizeTypeIdentifier;
                    /** @type {Object.<string, Object[]>} */
                    $scope.credentialsByService = (function () {
                        const credentials = {};
                        Object.keys(NOTIFIER_INTEGRATIONS).forEach(function (serviceName) {
                            credentials[serviceName] = [];
                        });

                        if ($scope.credentials) {
                            $scope.credentials.forEach(function (credential) {
                                const serviceName = TYPE_TO_SERVICE_INTEGRATIONS[credential.type];
                                if (!serviceName) {
                                    return;
                                }
                                if (credentials.hasOwnProperty(serviceName)) {
                                    credentials[serviceName].push(credential);
                                }
                            });
                        }
                        return credentials;
                    })();
                },
            ],
        };
    },
]);
