import React, { FC } from 'react';
import LOColumnChart from './LOColumnChart';

type LOColumnChartComponentProps = {
    ingestQuota: number;
    indexQuota: number;
    ingestUsage: number;
    indexUsage: number;
};

const LOColumnChartComponent: FC<LOColumnChartComponentProps> = ({
    ingestQuota,
    indexQuota,
    ingestUsage,
    indexUsage,
}) => {
    return (
        <div>
            <div className="org-usage-section-title">
                Current Usage (as of {new Date().toString().substring(0, 19) + '00:00'})
            </div>
            <div className="usage-charts-container">
                <LOColumnChart
                    usage={ingestUsage}
                    quota={ingestQuota}
                    usagelabel="Ingest Usage"
                    labelToClassName="ingest-usage-chart"
                />
                <LOColumnChart
                    usage={indexUsage}
                    quota={indexQuota}
                    usagelabel="Index Usage"
                    labelToClassName="index-usage-chart"
                />
            </div>
        </div>
    );
};

export default LOColumnChartComponent;
