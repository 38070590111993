import PropTypes from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import alertTypeService from '../../legacy/app/alerts/alertTypeService';

const className = new BEMHelper('alert-modal-v2__header');

export class AlertEventModalHeader extends React.Component {
    getEventStateText() {
        return alertTypeService.getDisplayFor(this.props.eventState);
    }

    getHeaderTitle() {
        return `${this.getEventStateText()} ${this.props.severity} Alert`;
    }

    isClearing() {
        return alertTypeService.isClearingEvent(this.props.eventState);
    }

    shouldShowBottomLine() {
        return !this.props.isActive || this.isClearing();
    }

    getRootElementClassName() {
        return className({
            modifiers: {
                highlighted: !this.shouldShowBottomLine(),
            },
            extra: {
                'modal-header': true,
                [this.props.severity]: true,
            },
        });
    }

    getBottomLineClassName() {
        return className({
            element: 'border',
            extra: {
                [this.props.severity]: !this.isClearing(),
            },
        });
    }

    onCloseIconClick() {
        if ('function' === typeof this.props.closeCallback) {
            this.props.closeCallback();
        }
    }

    render() {
        return (
            <div
                {...this.getRootElementClassName()}
                role="dialog"
                aria-labelledby="alertEventModalTitle"
            >
                <h3 className="modal-title" id="alertEventModalTitle">
                    {this.getHeaderTitle()}
                </h3>
                <button
                    className="icon-delete medium clickable"
                    onClick={() => this.onCloseIconClick()}
                    aria-label="close"
                ></button>
                {this.shouldShowBottomLine() && <div {...this.getBottomLineClassName()} />}
            </div>
        );
    }
}

AlertEventModalHeader.propTypes = {
    title: PropTypes.string,
    severity: PropTypes.string,
    isActive: PropTypes.bool,
    eventState: PropTypes.string,
    closeCallback: PropTypes.func,
};
