import orgNotFoundTemplateUrl from '../../app/administrative/organization/orgNotFound.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';

export default [
    '$log',
    'currentUser',
    'sfxModal',
    'auth',
    'ssoRedirect',
    '$location',
    'organizationService',
    'DOMAIN_ORGANIZATION_ID',
    '$q',
    'logService',
    function (
        $log,
        currentUser,
        sfxModal,
        auth,
        ssoRedirect,
        $location,
        organizationService,
        DOMAIN_ORGANIZATION_ID,
        $q,
        logService
    ) {
        let inProgressRequestedOrg = null;

        return {
            createQueryParamsString,
            getQueryParamsString,
            initializeRoute,
        };

        function redirectHome() {
            $location.path('/home');
        }

        // creates query params string key=value&key2=value2
        // from javascript simple object { key: value }
        function createQueryParamsString(params) {
            return Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join('&');
        }

        function getQueryParamsString() {
            const searchObj = $location.search();
            const parameters = [];
            let paramsString = '';

            angular.forEach(searchObj, function (value, paramName) {
                parameters.push(paramName + '=' + value);
            });

            if (parameters.length > 0) {
                paramsString = '?' + parameters.join('&');
            }

            return paramsString;
        }

        // If the route contains an org id param, switch to that org
        function initializeRoute(params) {
            const routeParams = params || ngRoute.params;
            const requestedOrgId = routeParams?.orgID;

            let result;

            if (DOMAIN_ORGANIZATION_ID) {
                // if we are on domain with orgId, use that only that.
                // and we ignore whatever is on the parameter.
                result = setPreferredOrgIdIfNotMatch(DOMAIN_ORGANIZATION_ID);
            } else if (!requestedOrgId) {
                // If no org id exists, warm up the org id cache.
                result = organizationService.get();
            } else {
                // If the users current orgId is different than the requested orgId
                // switch orgs
                result = setPreferredOrgIdIfNotMatch(requestedOrgId);
            }

            // For SSO Redirect, we need to save the first URL that the user tried
            // to access so that we can redirect them to it after SAML token exchange
            // Note 1: We want to overwrite the already saved url because
            // 1. if sso fails and user re-initiates with a new URL,
            //    we want to use the new URL
            // 2. if user sees the login page and re-initiates with a new URL,
            //    we want to use the new URL
            // Note 2: We already clear the saved URL once login succeeds or cookie
            //         expires within 1 hr.
            logService.logData(
                'INFO',
                'routeUtils.js -> $location.url(): intitializing routes',
                $location.url()
            );
            ssoRedirect.set($location.url());

            return result.catch(onInitializationFailure);
        }

        function onInitializationFailure(e) {
            if (!auth.authToken()) {
                // when we fail initialization, we should just reject the route change
                // and not proceed because it doesn't make sense.
                return $q.reject();
            }

            $log.error('Failed finding organization.', ngRoute.params.orgID, e);

            return sfxModal
                .open({
                    templateUrl: orgNotFoundTemplateUrl,
                    backdrop: 'static',
                    size: 'md',
                    controller: 'OrgNotFoundController',
                    resolve: {
                        orgId() {
                            return ngRoute.params.orgID;
                        },
                    },
                })
                .result.then((result) => {
                    if (!(result && result.signout)) {
                        redirectHome();
                    }
                });
        }

        function setPreferredOrgIdIfNotMatch(requestedOrgId) {
            return organizationService.get().then((org) => {
                if (requestedOrgId !== inProgressRequestedOrg || requestedOrgId !== org.id) {
                    inProgressRequestedOrg = requestedOrgId;
                    return currentUser
                        .setPreferredOrg(requestedOrgId)
                        .then(organizationService.get)
                        .finally(() => (inProgressRequestedOrg = null));
                }

                return org;
            });
        }
    },
];
