import styled from 'styled-components';
import { pickVariant } from '@splunk/themes';
import React, { FC } from 'react';
import contentNotFoundLight from '../../assets/images/fox404_light.png';
import contentNotFoundDark from '../../assets/images/fox404_dark.png';
import errorLight from '../../assets/images/fox500_light.png';
import errorDark from '../../assets/images/fox500_dark.png';

const StyledWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
`;

type ContentErrorProps = { missing?: boolean };

const StyledContent = styled.div<ContentErrorProps>`
    flex: 1 0 1px;
    background-image: ${pickVariant('missing', {
        true: {
            light: `url("${contentNotFoundLight}")`,
            dark: `url("${contentNotFoundDark}")`,
        },
        false: {
            light: `url("${errorLight}")`,
            dark: `url("${errorDark}")`,
        },
    })};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 400px;
`;

const ContentError: FC<ContentErrorProps> = ({ missing = false }) => (
    <StyledWrapper>
        <StyledContent missing={missing} />
    </StyledWrapper>
);

export default ContentError;
