import templateUrl from './dropdownSelector.tpl.html';

/*
  Simple dropdown selector with label. Allows user to pass an array of strings
  or an array of objects with (string) displayName, (string) value.

  Params:
  - allowNone (boolean): inserts 'None' option which, when selected, calls
    onSelectOption without any argument.
  - label (string)
  - options (string or option object array): if an array of objects, the objects
    should have displayName (string) and value (any). value will be used as
    displayName if no displayName is provided
  - defaultOption (option object) DEPRECATED: provides a value that should be
    initially selected. Use selectedOption instead
  - selectedOption (string or option object): currently selected option. Will
    not be edited directly
  - selectorTabIndex (int): sets selection order attr on the input, in order
    to allow for pages with multiple inputs to be tabbed through
  - onSelectOption (output): will be called with the selected option when an
    option is selected

  - dropdownClasses (string): class string to be applied to the uib-dropdown
  - dropdownMenuClasses (string): class string to be applied to the
    uib-dropdown-menu
  - dropdownToggleClasses (string): class string to be applied to the
    uib-dropdown-toggle
*/

export default {
    templateUrl,
    bindings: {
        allowNone: '<',
        hideLabel: '<',
        label: '<',
        options: '<',
        defaultOption: '<',
        selectedOption: '<',
        selectorTabIndex: '<?',
        onSelectOption: '&',
        onToggle: '<?',

        // styling attrs
        dropdownClasses: '@',
        dropdownMenuClasses: '@',
        dropdownToggleClasses: '@',
    },
    controller: [
        'userAnalytics',
        function (userAnalytics) {
            const $ctrl = this;

            $ctrl.isOpened = false;
            $ctrl.selectOption = selectOption;

            // lifecycle bindings
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            function $onChanges(changesObj) {
                const { options: optionsChange, selectedOption: selectedOptionChange } = changesObj;

                if (optionsChange && $ctrl.options) {
                    if (optionsAreStrings()) {
                        $ctrl.options = $ctrl.options.map(stringToOption);
                    }
                }

                if (optionsChange || selectedOptionChange) {
                    setSelectedFromOwnOptions();
                }
            }

            function $onInit() {
                if ($ctrl.defaultOption) {
                    $ctrl.selectedOption = $ctrl.defaultOption;
                }
            }

            function getSelectedValue() {
                if (!$ctrl.selectedOption) return null;

                return isString($ctrl.selectedOption)
                    ? $ctrl.selectedOption
                    : $ctrl.selectedOption.value;
            }

            function setSelectedFromOwnOptions() {
                const selectedValue = getSelectedValue();

                if ($ctrl.options) {
                    $ctrl.selectedOption = $ctrl.options.find(
                        (option) => option.value === selectedValue
                    );
                } else {
                    $ctrl.selectedOption = stringToOption(selectedValue);
                }
            }

            function selectOption(option) {
                $ctrl.selectedOption = option;
                $ctrl.onSelectOption({ option });

                if (option.trackClick) {
                    userAnalytics.event('click', option.trackClick);
                }
            }

            function optionsAreStrings() {
                return $ctrl.options.every(isString);
            }

            function isString(thing) {
                return typeof thing === 'string';
            }

            function stringToOption(value) {
                return { displayName: value, value };
            }
        },
    ],
};
