// Support the same URI encoding as the Angular 1.x library:
// https://github.com/angular/angular.js/blob/f6986f8e52c1f889c8649ec75c5abac003102999/src/Angular.js#L1452-L1490

/**
 * This is needed for some urls that are used in olly, but created in angular code when the url is used outside
 * of a full Angular signalview instance.
 * When running in a full signalview instance, there is a click handler in angular code that will check for a link click
 * and ensure that the link format is compatible with angular before updating the actual url.
 * When an angular component is not within a full angular application this click handler doesn't exist, which causes the link
 * to update the url and then angular to see it isn't compatible with angular formatting and it updates the link again,
 * causing a new entry to be included in the browser history.
 * For example, in discovery dashboards embedded in olly the 'open' link for a chart has brackets included and a colon escaped,
 * but angular wants the brackets encoded and the colon not escaped. When running inside an angular app the click handle handles this,
 * but outside a full angular app it doesn't so we need to encode it correctly originally.
 */

export const angularifyUriQuery = (search) =>
    search
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%3B/gi, ';')
        .replace(/%22/gi, '"')
        .replace(/%28/gi, '(')
        .replace(/%29/gi, ')')
        .replace(/%2A/gi, '*')
        .replace(/%21/gi, '!')
        .replace(/%7E/gi, '~')
        .replace(/%27/gi, "'")
        .replace(/%2D/gi, '-')
        .replace(/%5F/gi, '_')
        .replace(/%2E/gi, '.')
        // angular expects brackets to be escaped in stringified JSON
        .replace(/\[/g, '%5B')
        .replace(/\]/g, '%5D')
        .replace(/{/g, '%7B')
        .replace(/}/g, '%7D')
        // angular expects trailing '&' to be stripped
        .replace(/&$/, '')
        // angular expects leading '&' following '?' to be stripped
        .replace(/^&/, '');

export const angularifyUri = (uri) => {
    const [base, search] = uri.split('?');
    if (!search) {
        return base;
    }
    return `${base}?${angularifyUriQuery(search)}`;
};
