import templateUrl from './timeSpanPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('timeSpanPickerController', [
        '$scope',
        'timeSpanUtils',
        function ($scope, timeSpanUtils) {
            $scope.valid = true;

            const $ctrl = this;
            $ctrl.$onInit = function () {
                $scope.localValue = $scope.$ctrl.model;
            };

            function validate(v) {
                $scope.validMinResolution = false;
                $scope.validDuration = timeSpanUtils.validateTimeSpan(v);
                if ($scope.validDuration) {
                    $scope.validMinResolution = timeSpanUtils.validateMinResolution(
                        v,
                        $scope.$ctrl.jobInfo.resolution,
                        $scope.$ctrl.definition
                    );
                }
                $scope.valid = $scope.validDuration && $scope.validMinResolution;
            }

            function update(v) {
                $scope.$ctrl.onTypedUpdate({ value: v, validity: $scope.valid });
            }

            function validateAndUpdate() {
                validate($scope.localValue);
                update($scope.localValue);
            }

            $scope.$watchGroup(['localValue', '$ctrl.jobInfo.resolution'], function () {
                if (!$scope.$ctrl.jobInfo) {
                    return;
                }
                validateAndUpdate();
            });

            $scope.$ctrl.$onChanges = function (changes) {
                if (changes.model) {
                    $scope.localValue = changes.model.currentValue;
                }
            };
        },
    ])

    .component('timeSpanPicker', {
        replace: true,
        controller: 'timeSpanPickerController',
        bindings: {
            model: '<',
            definition: '<',
            jobInfo: '<',
            disablePicker: '<',
            validationError: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
