import templateUrl from './eventPanelTile.tpl.html';

angular.module('signalview.eventsPanel').directive('eventPanelTile', [
    'eventModal',
    function (eventModal) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                event: '=',
                eventColors: '<?',
            },
            templateUrl,
            link: function ($scope) {
                $scope.showInfo = function () {
                    eventModal($scope.event);
                };

                $scope.enterEventTile = function () {
                    $scope.$emit('eventTileMouseEnter', $scope.event.timestamp);
                };

                $scope.leaveEventTile = function () {
                    $scope.$emit('eventTileMouseLeave');
                };
            },
        };
    },
]);
