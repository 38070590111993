import templateUrl from './unitsPicker.tpl.html';

angular.module('signalview.chartbuilder').component('unitsPicker', {
    templateUrl,
    bindings: {
        unitType: '<',
        unitSuffix: '<',
        unitPrefix: '<',
        onSelect: '&',
        direction: '@?',
    },
    controller: [
        'scalingUnitService',
        function (scalingUnitService) {
            const ctrl = this;
            if (!ctrl.direction) {
                ctrl.direction = 'right';
            }
            ctrl.$onInit = function () {
                ctrl.localState = {
                    unitType: ctrl.unitType,
                    unitSuffix: ctrl.unitSuffix,
                    unitPrefix: ctrl.unitPrefix,
                };
                ctrl.hasCustomUnits = hasCustomUnits();
                ctrl.dropdownOpen = false;
            };

            let mostRecentCustomUnits;

            ctrl.scalingUnitTypes = scalingUnitService.getScalingUnits();

            ctrl.clearUnits = function () {
                ctrl.localState.unitType = null;
                stashCustomUnits();
                notifyChanges();
            };

            ctrl.setCustomUnits = function () {
                ctrl.localState.unitType = null;
                ctrl.hasCustomUnits = true;

                // retrieve last custom unit configuration if there is one.
                if (mostRecentCustomUnits) {
                    ctrl.localState.unitPrefix = mostRecentCustomUnits.prefix;
                    ctrl.localState.unitSuffix = mostRecentCustomUnits.suffix;
                }
                notifyChanges();
            };

            ctrl.setUnits = function (name) {
                ctrl.localState.unitType = name;
                stashCustomUnits();
                notifyChanges();
            };

            ctrl.onCustomUnitChanged = function () {
                notifyChanges();
            };

            ctrl.toggleDropdown = function () {
                ctrl.dropdownOpen = !ctrl.dropdownOpen;
            };

            function stashCustomUnits() {
                ctrl.hasCustomUnits = false;
                mostRecentCustomUnits = {
                    prefix: ctrl.localState.unitPrefix,
                    suffix: ctrl.localState.unitSuffix,
                };

                ctrl.localState.unitPrefix = '';
                ctrl.localState.unitSuffix = '';
            }

            function notifyChanges() {
                ctrl.onSelect(ctrl.localState);
            }

            function hasCustomUnits() {
                return ctrl.localState.unitSuffix || ctrl.localState.unitPrefix;
            }
        },
    ],
});
