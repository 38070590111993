export const axisLabelFormatter = [
    'timeZoneService',
    'moment',
    function (timeZoneService, moment) {
        return function (date, granularity) {
            // basically a copy of the default formatter with some modifications to show 00 seconds
            // and bold dates when they are mixed with HH:MM timestamps
            date = moment(date);
            if (granularity >= Dygraph.DECADAL) {
                return '' + date.year();
            } else if (granularity >= Dygraph.MONTHLY) {
                return Dygraph.SHORT_MONTH_NAMES_[date.month()] + ' ' + date.year();
            } else {
                date = timeZoneService.moment(date);
                const frac =
                    date.hours() * 3600 +
                    date.minutes() * 60 +
                    date.seconds() +
                    date.milliseconds();
                if (frac === 0 || granularity >= Dygraph.DAILY) {
                    // e.g. '21Jan' (%d%b)
                    const nd = new Date(date.valueOf() + 3600 * 1000);
                    const datestr =
                        Dygraph.zeropad(nd.getDate()) +
                        ' ' +
                        Dygraph.SHORT_MONTH_NAMES_[nd.getMonth()];
                    if (granularity < Dygraph.DAILY) {
                        return '<span class="interval-label">' + datestr + '</span>';
                    }
                    return datestr;
                } else {
                    const zeropad = Dygraph.zeropad;
                    const d2 = timeZoneService.moment(date.valueOf());
                    if (d2.seconds() || granularity < Dygraph.MINUTELY) {
                        return (
                            zeropad(d2.hours()) +
                            ':' +
                            zeropad(d2.minutes()) +
                            ':' +
                            zeropad(d2.seconds())
                        );
                    } else {
                        return zeropad(d2.hours()) + ':' + zeropad(d2.minutes());
                    }
                }
            }
        };
    },
];
