export const getAlertList = [
    'nestedGroupBy',
    '_',
    '$q',
    function (nestedGroupBy, _, $q) {
        function andQuery(...args) {
            return args
                .filter((item) => item)
                .map((item) => `(${item})`)
                .join(' AND ');
        }

        /**
         * Sum the value of each key in the objects.
         */
        function sumObject(obj1, obj2) {
            const keys = Object.keys(obj2);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                obj1[key] = (obj1[key] || 0) + obj2[key];
            }
        }

        function sortKeys(obj1, obj2) {
            // undefined at the bottom
            if (obj1 === 'undefined') {
                return 1;
            }
            if (obj2 === 'undefined') {
                return -1;
            }
            return obj1.localeCompare(obj2);
        }

        function postNestedExtra(extras) {
            if (angular.isArray(extras)) {
                return extras;
            }
            const keys = Object.keys(extras);
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] === 'undefined') {
                    extras._extras = postNestedExtra(extras[keys[i]]);
                } else {
                    postNestedExtra(extras[keys[i]]);
                }
            }
            return [];
        }

        function nestedGroupped(input, extras, groupBy) {
            const groupped = nestedGroupBy(input, groupBy);
            const grouppedExtras = nestedGroupBy(extras, groupBy);
            postNestedExtra(grouppedExtras);
            return {
                group: groupped,
                extras: grouppedExtras,
            };
        }

        /**
         * Flattened the nested group to a list of objects to be used in a table.
         *
         * Each flattened object will be
         * {
         * level: how many columns that we need to pad before this item.
         * item: the actual event (if it's leave node, otherwise will be undefined)
         * track: the unique id to be tracked by ng-repeat
         * parent: parent item to look up to determine if this item should be visible or not
         * child: children of this item to recursively toggled when expand
         * }
         */
        function processGroupped(
            eventService,
            input,
            extras,
            searchText,
            groupBy,
            severityKey,
            severityFilter,
            doNotFlatten
        ) {
            const groupped = nestedGroupped(input, extras, groupBy);
            const detectorIdToName = {};
            input.forEach(function (event) {
                detectorIdToName[event.sf_detectorId] = event.sf_detector;
            });
            let results = [];
            let level = 0;

            function filterUnmatchedExtra(v) {
                // 3 (properties that we ignored, _not, _or, _original and level always have offset starting from 0 so we do +1)
                // Do angular.copy to get rid of keys like $$hashKey
                return (
                    Object.keys(angular.copy(v)).length === 3 + level + 1 &&
                    angular.equals({}, v._not)
                );
            }

            function process(item, extra, parent) {
                if (Array.isArray(item)) {
                    // It's an array
                    const items = item
                        .filter(function (entry) {
                            // if severity filter exists, only add those event that matches.
                            if (severityFilter) {
                                return severityFilter.includes(entry[severityKey]);
                            }
                            return true;
                        })
                        .map(function (entry) {
                            return {
                                level: level,
                                item: eventService.processEvent(entry),
                                track: entry.sf_id,
                                parent: parent,
                            };
                        });
                    if (parent) {
                        parent.child = items;
                    }
                    if (!doNotFlatten || !parent) {
                        results = results.concat(items);
                    }
                    return _.countBy(item, severityKey);
                } else {
                    // It's an object
                    const severitySummed = {};

                    const keys = Object.keys(item).sort(sortKeys);
                    for (let j = 0; j < keys.length; j++) {
                        let key = keys[j];
                        const value = item[key];

                        // We want to show n/a for undefined.
                        let extraValue = null,
                            extraChild = null,
                            started = null;
                        if (key === 'undefined') {
                            key = 'n/a';
                        } else {
                            if (extra) {
                                if (
                                    (extra[key] && extra[key]._extras) ||
                                    angular.isArray(extra[key])
                                ) {
                                    extraValue = (extra[key]._extras || extra[key]).filter(
                                        filterUnmatchedExtra
                                    );
                                    started = extraValue.some(function (v) {
                                        return v._original.started;
                                    });
                                }
                                extraChild = extra[key];
                            }
                        }
                        const header = {
                            level: level,
                            key: key,
                            display:
                                groupBy[level] === 'sf_detectorId' ? detectorIdToName[key] : key,
                            by: groupBy[level],
                            track: (parent ? parent.track + '-' : '') + key,
                            child: [],
                            parent: parent,
                            extra: extraValue,
                            started: started,
                        };
                        if (parent) {
                            parent.child.push(header);
                        }
                        if (!doNotFlatten || !parent) {
                            results.push(header);
                        }

                        // Recursively call the item
                        level++;
                        header.severity = process(value, extraChild, header);
                        level--;

                        if (severityFilter) {
                            // if severity filter exists and the there's no child with that severity, remove this item
                            const matchingChild = Object.keys(header.severity).some((severity) =>
                                severityFilter.includes(severity)
                            );
                            if (!matchingChild) {
                                results.pop();
                            }
                        }

                        // Sum the severity of each item.
                        sumObject(severitySummed, header.severity);
                    }
                    return severitySummed;
                }
            }

            process(groupped.group, groupped.extras);

            //first level should always be shown.
            results.forEach(function (item) {
                item.show = true;
            });
            return {
                result: results,
                groupBy: groupBy,
                severityFilter: severityFilter,
                detectorIdToName: detectorIdToName,
                severities: eventService.severities,
                searchText: searchText,
                alertMode: eventService.alertMode,
                groupedExtras: groupped.extras,
            };
        }

        return function (
            eventService,
            query,
            max,
            searchText,
            groupingBy,
            severityKey,
            severityFilter,
            doNotFlatten,
            offset = 0,
            timeWindow = null
        ) {
            const groupBy = angular.copy(groupingBy);
            let resultPromise;
            const maxCount = groupingBy.length && eventService.severities.length ? 1000 : max;
            if (!groupingBy.length && severityFilter) {
                // if there's no group by and there's a severity filter, we will use
                // server side filtering. If there's group by, we will filter severity
                // locally on the client.
                const q = andQuery(query, severityKey + ':' + severityFilter);
                resultPromise = eventService.getEvents(q, maxCount, offset, timeWindow);
            } else if (groupingBy.length && eventService.severities.length) {
                // one request per severities and combine them together.
                resultPromise = $q
                    .all(
                        eventService.severities.map(function (severity) {
                            const q = andQuery(query, severityKey + ':' + severity);
                            return eventService.getEvents(q, maxCount, offset, timeWindow);
                        })
                    )
                    .then(function (result) {
                        return [].concat.apply([], result);
                    });
            } else {
                resultPromise = eventService.getEvents(query, maxCount, offset, timeWindow);
            }

            return $q
                .all({ result: resultPromise, extras: eventService.getExtras() })
                .then(function (res) {
                    return _.extend(
                        { response: res },
                        processGroupped(
                            eventService,
                            res.result,
                            res.extras,
                            searchText,
                            groupBy,
                            severityKey,
                            severityFilter,
                            doNotFlatten
                        )
                    );
                });
        };
    },
];
