function DataMessage() {
    this.channel = null;
    this.logicalTimestampMs = null;
    this.data = [];
    this.type = 'data';
    this.version = 1;
}

DataMessage.prototype.setChannel = function (channel) {
    this.channel = channel;
};

DataMessage.prototype.addPoint = function (tsid, value) {
    this.data.push({ tsId: tsid, value: value });
};

DataMessage.prototype.setTimeStamp = function (ts) {
    this.logicalTimestampMs = ts;
};

export default DataMessage;
