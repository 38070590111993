angular.module('signalflowv2').service('blockArgumentUtils', function () {
    const service = {
        isDisabled,
        checkAllowedArguments,
        isPublishBlock,
    };

    function isPublishBlock(block) {
        return block && block.functionName === 'publish';
    }

    function isDisabled(block) {
        return block.args && block.args.enable && block.args.enable.value === false;
    }

    function checkAllowedArguments(block, paramArray) {
        const unrecognizedArguments = Object.keys(block.args || []).filter(
            (x) => !paramArray.includes(x)
        );

        if (unrecognizedArguments.length > 0) {
            throw new Error(
                'The following argument(s) to the "' +
                    block.functionName +
                    '" function are not currently supported for builder mode : [' +
                    unrecognizedArguments.join(',') +
                    ']'
            );
        }
    }

    return service;
});
