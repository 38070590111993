import templateUrl from './kubeTable.tpl.html';
import { isNil, isArray } from 'lodash';
import { ngRoute } from '../../../../app/routing/ngRoute';

// This will return false if the value itself is null or undefined
//  or if it is an array where all of the values are null or undefined
function hasValidValues(data) {
    if (isNil(data)) {
        return false;
    }

    if (isArray(data) && data.every(isNil)) {
        return false;
    }

    return true;
}

export default {
    templateUrl,
    bindings: {
        streamConfig: '<',
        searchable: '=',
        tableTitle: '<',
        time: '<',
        filterBy: '<',
        isOnSidebar: '<?',
        onSelection: '<?',
        selectedTabDisplayName: '<?',
    },
    controller: [
        '$scope',
        '_',
        'mustache',
        'currentUser',
        'infoSidebarUtil',
        'kubeDataService',
        'kubeDataTableStreamer',
        'urlOverridesService',
        'dashboardVariablesService',
        'userAnalytics',
        'SIDEBAR_EVENTS',
        'GROUPED_TABLE_EVENTS',
        'KUBE_PROPERTY_EXCLUDE_LIST',
        'URL_PARAMETER_CONSTANTS',
        function (
            $scope,
            _,
            mustache,
            currentUser,
            infoSidebarUtil,
            kubeDataService,
            kubeDataTableStreamer,
            urlOverridesService,
            dashboardVariablesService,
            userAnalytics,
            SIDEBAR_EVENTS,
            GROUPED_TABLE_EVENTS,
            KUBE_PROPERTY_EXCLUDE_LIST,
            URL_PARAMETER_CONSTANTS
        ) {
            const $ctrl = this;
            $ctrl.data = {};
            $ctrl.idsThatHaveReportedData = new Set();
            $ctrl.loading = true;
            $ctrl.groupBy = [];
            $ctrl.customMapping = customMapping;

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;
            $scope.onGroupBy = onGroupBy;
            $scope.onSearch = _.debounce(onSearch, 500);

            const workloadKeys = [
                kubeDataService.WORKLOAD_KEYS.DEPLOYMENT,
                kubeDataService.WORKLOAD_KEYS.NAME,
            ];
            const streamer = kubeDataTableStreamer();

            $scope.$on(
                GROUPED_TABLE_EVENTS.VALUE_SELECTED,
                function (evt, selection, metadata, { panelName }) {
                    const panel = Object.values(infoSidebarUtil.INFO_PANELS).find(
                        ({ panelNameKey }) =>
                            panelNameKey === panelName ||
                            (workloadKeys.includes(panelNameKey) &&
                                workloadKeys.includes(panelName))
                    );

                    const onClusterMap = ngRoute.params.selectedTab === 'map';
                    const infoSidebarSourceFilters = [];

                    panel.requiredFilters.forEach((requiredFilterKey) => {
                        let metadataValue;
                        if (workloadKeys.includes(requiredFilterKey)) {
                            workloadKeys.some(
                                (key) => (metadataValue = metadata[key]) !== undefined
                            );
                        } else {
                            metadataValue = metadata[requiredFilterKey];
                        }

                        if (metadataValue) {
                            infoSidebarSourceFilters.push({
                                NOT: false,
                                applyIfExists: false,
                                iconClass: 'icon-properties',
                                property: requiredFilterKey,
                                propertyValue: metadataValue,
                                type: 'property',
                                value: metadataValue,
                            });
                        }
                    });

                    if (onClusterMap) {
                        mapSidebarFiltersOnMap(infoSidebarSourceFilters);
                    }

                    if (!$ctrl.isOnSidebar) {
                        urlOverridesService.setMapSelection(metadata.id);
                    }

                    infoSidebarUtil.setInfoPanelSidebarURLParams(
                        panel.value,
                        infoSidebarSourceFilters
                    );
                    $scope.$emit(SIDEBAR_EVENTS.OPEN_SIDEBAR);
                    if ($ctrl.onSelection) {
                        $ctrl.onSelection(metadata);
                    }

                    const infoSidebarInfoRefType = urlOverridesService.getSearchParam(
                        URL_PARAMETER_CONSTANTS.infoSidebarInfoTabRefType
                    );

                    const eventCategory = kubeDataService.getDataViewK8sNavEventCategory(
                        $ctrl.selectedTabDisplayName
                    );

                    userAnalytics.event(
                        eventCategory,
                        'click',
                        null,
                        infoSidebarUtil.INFO_PANELS[
                            infoSidebarInfoRefType
                        ].getEntityClickEventLabel(true)
                    );
                }
            );

            function mapSidebarFiltersOnMap(filters) {
                // to keep track of if the source or variable url filters need to be updated
                let sourceFilterUrlUpdate = false;
                let variableUrlUpdate = false;
                const clusterMapSourceFilters = [];
                const sourceFilters = urlOverridesService.getSourceFilterOverrideList() || [];
                const variables = dashboardVariablesService.getVariablesOverrideAsModel() || [];
                const sourceFilterKeys = sourceFilters.map((s) => s.property);
                const variablesFilterKeys = variables.map((s) => s.property);

                // update all variables and all source filters that may not match the clicked on object
                // if no variable or source filter exists, add the filter
                filters.forEach((filter) => {
                    const { property, propertyValue } = filter;
                    if (variablesFilterKeys.includes(property)) {
                        variables.forEach((variable) => {
                            if (
                                variable.property === property &&
                                valueNeedsUpdate(variable, propertyValue)
                            ) {
                                variableUrlUpdate = true;
                                variable.value = propertyValue;
                            }
                        });
                    } else if (sourceFilterKeys.includes(property)) {
                        sourceFilters.forEach((filter) => {
                            if (
                                filter.property === property &&
                                valueNeedsUpdate(filter, propertyValue)
                            ) {
                                sourceFilterUrlUpdate = true;
                                filter.propertyValue = propertyValue;
                                filter.value = propertyValue;
                            }
                        });
                    } else {
                        sourceFilterUrlUpdate = true;
                        clusterMapSourceFilters.push(filter);
                    }
                });

                if (sourceFilterUrlUpdate) {
                    if (clusterMapSourceFilters.length > 0) {
                        sourceFilters.push(...clusterMapSourceFilters);
                    }
                    urlOverridesService.setSourceFilterOverrideList(sourceFilters);
                }

                if (variableUrlUpdate) {
                    dashboardVariablesService.setVariablesOverride(variables);
                }
            }

            function valueNeedsUpdate(filter, value) {
                return angular.isArray(filter.value)
                    ? !filter.value.includes(value)
                    : filter.value !== value;
            }

            function $onInit() {
                $ctrl.columns = $ctrl.streamConfig.COLUMNS;

                // Add the column properties to exclude in the groupBy
                const columnProps = $ctrl.columns.map((column) => {
                    return column.property;
                });
                $ctrl.groupByExclude = KUBE_PROPERTY_EXCLUDE_LIST.concat(columnProps);

                streamer.setJobParams(idFunction, $ctrl.streamConfig, $ctrl.filterBy, $ctrl.time);
                streamer.addDataCallback(updateData);
                if ($ctrl.streamConfig && $ctrl.streamConfig.METRICS_TO_AGGREGATE) {
                    streamer.addAggregationCallback(aggregateByMetric);
                }

                streamer.start();
                currentUser.isAdmin().then((isAdmin) => {
                    $ctrl.isAdmin = isAdmin;
                });
            }

            function $onChanges({ filterBy, time, streamConfig }) {
                if (!filterBy && !time && !streamConfig) {
                    return;
                }

                $ctrl.loading = true;
                $ctrl.hasTableData = false;

                if (streamer) {
                    streamer.stop();
                    $onInit();
                }
            }

            function updateData(dataByMetric) {
                $ctrl.data = {};
                // Merge all streams
                for (const streamLabel in dataByMetric) {
                    const metricData = dataByMetric[streamLabel];
                    for (const column in metricData) {
                        $ctrl.data[column] = Object.assign(
                            $ctrl.data[column] || {},
                            metricData[column]
                        );
                        $ctrl.timestampSeen = $ctrl.data[column].timestamp;
                        if (hasValidValues(metricData[column].value)) {
                            $ctrl.data[column].ui_hasData = true;
                        }
                        if (shouldRemoveData($ctrl.data[column])) {
                            delete $ctrl.data[column];
                        }
                    }
                }

                // Delete ids that have never reported data
                for (const id in $ctrl.data) {
                    // If it reported datapoints this time add it to the set of ids that have reported data at some point
                    if ($ctrl.data[id].ui_hasData) {
                        $ctrl.idsThatHaveReportedData.add(id);
                    } else {
                        if (!$ctrl.idsThatHaveReportedData.has(id)) {
                            delete $ctrl.data[id];
                        }
                    }
                }

                $ctrl.loading = false;
                $ctrl.hasTableData = _.values($ctrl.data).length > 0;
                $scope.$applyAsync();
            }

            function shouldRemoveData(item) {
                let removeData = false;
                if (!$ctrl.streamConfig.FILTER_OUT_CLAUSE) {
                    return removeData;
                }
                const clause = $ctrl.streamConfig.FILTER_OUT_CLAUSE;
                if (eval(item[clause.metric] + clause.operator)) {
                    removeData = true;
                }
                return removeData;
            }

            $scope.$on('$destroy', () => {
                streamer.stop();
            });

            function aggregateByMetric(idData, metadata, latestValue) {
                let valueToSet = latestValue;
                // check if we want to do some kind of special wildcard metric aggregation
                Object.keys($ctrl.streamConfig.METRICS_TO_AGGREGATE).forEach((key) => {
                    // if the column exists in the metrics to aggregate
                    if (metadata.sf_streamLabel === key) {
                        const columnMapping = $ctrl.streamConfig.METRICS_TO_AGGREGATE[key];
                        const metadataKey = metadata[columnMapping.key];
                        if (metadataKey) {
                            const metricIdx = columnMapping.metrics.indexOf(metadataKey);
                            if (metricIdx !== -1) {
                                let map;
                                if (idData[metadata.sf_streamLabel]) {
                                    map = idData[metadata.sf_streamLabel];
                                } else {
                                    map = [];
                                }
                                map[metricIdx] = latestValue;
                                valueToSet = map;
                            }
                        }
                    }
                });
                return valueToSet;
            }

            function customMapping(value, column) {
                let str = '';
                const aggregationConfig = $ctrl.streamConfig.METRICS_TO_AGGREGATE[column.property];
                // no value return empty
                if (!value) {
                    return str;
                }
                // try to map value to its display when only one value
                if (!value.length) {
                    return aggregationConfig.displayMapping[value] || str;
                }
                for (let i = 0; i < value.length; i++) {
                    if (typeof value[i] === 'number') {
                        str += aggregationConfig.displayMapping[i];
                    }
                }
                return str;
            }

            function onSearch() {
                // ENTITY_TYPE is used to differentiate between tables when there are multiple tables on a given page
                const eventCategorySuffix = $ctrl.streamConfig.ENTITY_TYPE
                    ? `${$ctrl.selectedTabDisplayName}/${$ctrl.streamConfig.ENTITY_TYPE}`
                    : $ctrl.selectedTabDisplayName;
                const eventCategory =
                    kubeDataService.getDataViewK8sNavEventCategory(eventCategorySuffix);
                userAnalytics.event(eventCategory, 'KeyCommand', null, 'Search');
            }

            function onGroupBy() {
                // ENTITY_TYPE is used to differentiate between tables when there are multiple tables on a given page
                const eventCategorySuffix = $ctrl.streamConfig.ENTITY_TYPE
                    ? `${$ctrl.selectedTabDisplayName}/${$ctrl.streamConfig.ENTITY_TYPE}`
                    : $ctrl.selectedTabDisplayName;
                const eventCategory =
                    kubeDataService.getDataViewK8sNavEventCategory(eventCategorySuffix);
                userAnalytics.event(eventCategory, 'click', null, 'Group_By');
            }

            const idFunction = (metadata) => {
                return mustache.render($ctrl.streamConfig.UNIQUE_ID, metadata);
            };
        },
    ],
};
