import templateUrl from './bucketDisplay.tpl.html';
angular
    .module('signalview.buckets', [])
    .directive('bucketDisplay', function () {
        return {
            scope: {
                bucketDefinition: '=',
                bucketIndex: '=',
                addFilters: '=',
                showProperties: '=',
                selectedObjects: '=',
                selectedKeys: '=',
                selectionToggle: '=',
                setSelection: '=',
                categorySelection: '=',
                pseudoSelection: '=',
                selectedBucket: '=',
                displayName: '=',
                filters: '=',
                allowAltClick: '@?',
            },
            templateUrl,
            controller: 'bucketDisplayController',
        };
    })
    .controller('bucketDisplayController', [
        '$scope',
        'filterOnPrimaryKey',
        'getLinkForDocument',
        'catalogDataService',
        'favoriteService',
        function (
            $scope,
            filterOnPrimaryKey,
            getLinkForDocument,
            catalogDataService,
            favoriteService
        ) {
            $scope.showAll = false;
            $scope.getLinkForDocument = getLinkForDocument;
            const aliases = catalogDataService.getAliases();
            if (aliases[$scope.bucketDefinition.key]) {
                $scope.bucketName = aliases[$scope.bucketDefinition.key];
                $scope.aliasedBucketName = $scope.bucketDefinition.key;
            } else if ($scope.bucketDefinition.type === 'Page') {
                $scope.bucketName = 'Dashboard Group';
            }

            $scope.getDisplayTitle = function (doc) {
                let title;
                if ($scope.showSecondaryValue(doc)) {
                    title =
                        $scope.displayName.getName(doc) +
                        ' - (' +
                        $scope.displayName.getSecondaryValue(doc) +
                        ')';
                } else {
                    title = $scope.displayName.getName(doc);
                }
                if ($scope.allowAltClick && $scope.redirect(doc)) {
                    title = title + '\nalt+click for metadata';
                }
                return title;
            };
            $scope.showSecondaryValue = function (doc) {
                return !doc.hideSecondaryValue && $scope.displayName.getSecondaryValue(doc);
            };
            $scope.bucketHeaderClick = function (bucketDefinition, bucketIndex) {
                if (
                    $scope.isBucketHeaderSelected(bucketDefinition) ||
                    bucketDefinition.notSelectable
                ) {
                    return;
                }
                if (bucketDefinition.link) {
                    window.location = bucketDefinition.link;
                } else {
                    $scope.selectionToggle(bucketDefinition, true, bucketIndex, -1);
                }
            };
            $scope.itemClick = function (event, doc, bucketIndex, hitIndex) {
                if ($scope.isSelected(doc, hitIndex) || doc.externalLink) {
                    return;
                }

                /**
                 * If it's linked then go directly to the destination unless it's an altKey then just show
                 * preview like normal catalog behavior.
                 */
                if (($scope.allowAltClick && event.altKey) || !$scope.redirect(doc)) {
                    event.preventDefault();
                    event.stopPropagation();
                    $scope.selectionToggle(doc, true, bucketIndex, hitIndex);
                }
            };
            $scope.redirect = function (doc) {
                return $scope.displayName.redirect && $scope.displayName.redirect(doc);
            };
            $scope.keepSidebar = function (doc) {
                return $scope.displayName.keepSidebar && $scope.displayName.keepSidebar(doc);
            };
            $scope.generateFilter = function (document) {
                const filters = filterOnPrimaryKey(document);
                filters.forEach(function (filter) {
                    $scope.addFilters(filter);
                });
            };

            $scope.isBucketHeaderSelected = function () {
                /**
                 * true iff
                 * - bucket key matches the value of bucket definition or
                 * - bucket id matches the id of bucket definition or
                 * - bucket section is selected in selected keys
                 */
                return (
                    ($scope.selectedBucket &&
                        $scope.selectedBucket.key &&
                        $scope.selectedBucket.key ===
                            ($scope.bucketDefinition.key || $scope.bucketDefinition.type)) ||
                    ($scope.selectedBucket &&
                        $scope.bucketDefinition.sf_id &&
                        $scope.selectedBucket.sf_id === $scope.bucketDefinition.sf_id) ||
                    ($scope.bucketDefinition.sf_section &&
                        $scope.selectedKeys['sf_section:' + $scope.bucketDefinition.value])
                );
            };

            $scope.isSelected = function (doc) {
                if (doc.sf_type === 'Property') {
                    return $scope.selectedKeys[doc.key + ':' + doc.value];
                } else {
                    return $scope.selectedObjects[doc.sf_id];
                }
            };

            $scope.$watch('categorySelection', function () {
                $scope.multiSelect = !!$scope.categorySelection;
            });

            $scope.toggleShowAll = function () {
                //$scope.showAll = !$scope.showAll;
            };

            $scope.selectAll = function () {
                angular.forEach($scope.bucketDefinition.hits, function (doc, idx) {
                    $scope.selectionToggle(doc, false, $scope.bucketIndex, idx);
                });
            };

            $scope.isFavorite = function (doc) {
                return favoriteService.isFavorite(doc);
            };

            $scope.addToFavorites = favoriteService.add;

            $scope.removeFromFavorites = function (doc) {
                favoriteService.remove(doc);
            };

            $scope.showpendingResult = function () {
                if ($scope.bucketDefinition.pendingResult) {
                    $scope.bucketDefinition.hits = $scope.bucketDefinition.hits.concat(
                        $scope.bucketDefinition.pendingResult
                    );

                    delete $scope.bucketDefinition.pendingResultLoading;
                    delete $scope.bucketDefinition.pendingResult;
                }
            };

            function findByKeyAndSplice(array, keyToSplice) {
                if (!array) {
                    return;
                }

                for (let i = 0; i < array.length; i++) {
                    if (array[i].favoriteKey === keyToSplice) {
                        array.splice(i, 1);
                        break;
                    }
                }
            }

            if ($scope.bucketDefinition.value === 'favorites') {
                $scope.$on('dashboard favorite add', function (_event, dashboard) {
                    if ($scope.bucketDefinition.value === 'favorites') {
                        $scope.bucketDefinition.hits.push(dashboard);
                    }
                });

                $scope.$on('dashboard favorite remove', function (_event, deletedKey) {
                    if ($scope.bucketDefinition.value === 'favorites') {
                        findByKeyAndSplice($scope.bucketDefinition.hits, deletedKey);
                        findByKeyAndSplice($scope.bucketDefinition.pendingResult, deletedKey);
                    }
                });
            }
        },
    ]);
