angular
    .module('signalview.detector')
    .constant('NOTIFIER_INTEGRATIONS', {
        // Value determines what to display
        pagerduty: 'PagerDuty',
        servicenow: 'ServiceNow',
        slack: 'Slack',
        victorops: 'VictorOps',
        webhook: 'Webhook',
        bigpanda: 'BigPanda',
        office365: 'Microsoft Teams',
        xmatters: 'xMatters',
        opsgenie: 'Opsgenie',
        amazoneventbridge: 'AmazonEventBridge',
        jira: 'Jira',
        splunkplatform: 'Splunk platform',
    })
    .constant('NOTIFIER_INTEGRATIONS_V2_MAP', {
        // Value should match DetectorRuleNotification name
        email: 'Email',
        webhook: 'Webhook',
        pagerduty: 'PagerDuty',
        servicenow: 'ServiceNow',
        slack: 'Slack',
        victorops: 'VictorOps',
        teamEmail: 'TeamEmail',
        team: 'Team',
        bigpanda: 'BigPanda',
        office365: 'Office365',
        xmatters: 'XMatters',
        opsgenie: 'Opsgenie',
        amazoneventbridge: 'AmazonEventBridge',
        jira: 'Jira',
        splunkplatform: 'SplunkPlatform',
    })
    .constant('NOTIFIER_DEPRECATED_INTEGRATION_TYPES', ['hipchat'])
    .service('NOTIFIER_INTEGRATIONS_V2_REVERSE_MAP', [
        'NOTIFIER_INTEGRATIONS_V2_MAP',
        function (NOTIFIER_INTEGRATIONS_V2_MAP) {
            const V2EnumMapper = {};
            angular.forEach(NOTIFIER_INTEGRATIONS_V2_MAP, function (v2, v1) {
                V2EnumMapper[v2] = v1;
            });
            return V2EnumMapper;
        },
    ]);
