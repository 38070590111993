import { react2angular } from 'react2angular';
import mutedTemplate from './muted.tpl.html';
import mutedThenSentTemplate from './mutedThenSent.tpl.html';

import { AlertDataController } from './AlertDataController';
import { ETSAlertService } from './ETSAlertService';
import { getAlertAggregation } from './getAlertAggregation';
import { getAlertList } from './getAlertList';
import { applyAlertListState } from './applyAlertListState';
import { alertListDetectorCache } from './alertListDetectorCache';
import alertsOverviewPage from './alertsOverviewPage';
import AlertsBanner from './alertsBanner';
import { detectorListFilters } from '../../../../common/ui/detectors/DetectorListFilters';

angular
    .module('signalview.alertsOverview', [
        'sfx.filterControls',
        'sfx.ui',
        'signalview.urlOverrides',
        'signalboost',
    ])

    .constant('severities', ['Critical', 'Major', 'Minor', 'Warning', 'Info'])

    .controller('AlertDataController', AlertDataController)
    .component('alertsBanner', react2angular(AlertsBanner, null, ['currentUser']))
    .component('detectorListFilters', detectorListFilters)
    .component('alertsOverviewPage', alertsOverviewPage)

    .directive('muted', [
        'alertTypeService',
        function (alertTypeService) {
            return {
                replace: true,
                scope: {
                    is: '@?',
                    detectorLink: '@?',
                },
                restrict: 'E',
                templateUrl: mutedTemplate,
                link: function ($scope) {
                    const stateChange = alertTypeService
                        .getDisplayFor($scope.is || 'anomalous')
                        .toLowerCase();
                    $scope.tooltipText = `This alert was ${stateChange} while a muting rule was active. No notifications were sent.`;

                    if ($scope.detectorLink) {
                        $scope.tooltipText += ' Click to view muting rules for this detector.';
                    }
                },
            };
        },
    ])

    .directive('mutedThenSent', [
        function () {
            return {
                replace: true,
                restrict: 'E',
                templateUrl: mutedThenSentTemplate,
            };
        },
    ])

    .filter('thousandSuffix', [
        'valueFormatter',
        function (valueFormatter) {
            /**
             * Used to convert long number with many digits to a short form (with rounding of course)
             */
            return function (input) {
                return valueFormatter.formatValue(input, 3);
            };
        },
    ])

    .service('getAlertAggregation', getAlertAggregation)
    .service('getAlertList', getAlertList)
    .service('ETSAlertService', ETSAlertService)
    .service('applyAlertListState', applyAlertListState)
    .service('alertListDetectorCache', alertListDetectorCache);
