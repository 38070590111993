import templateUrl from './legacyCondition.tpl.html';

angular.module('signalview.detector.rule').directive('legacyCondition', [
    'chartbuilderUtil',
    'detectorUtils',
    function (chartbuilderUtil, detectorUtils) {
        return {
            restrict: 'E',
            scope: {
                rule: '=',
                readable: '=',
                plots: '=',
                disableDynamic: '=',
                metadata: '=',
                numPlots: '=',
                signalResolution: '=',
                hideSummary: '=?',
                preSelectedSignal: '=?',
                jobInfo: '=?',
            },
            templateUrl,
            link: function ($scope) {
                if (!$scope.rule.thresholdMode) {
                    $scope.rule.thresholdMode = 'above';
                }
                if (!$scope.jobInfo) {
                    $scope.jobInfo = {};
                }

                function getPlotResolution() {
                    $scope.jobInfo.resolvingResolution = true;
                    $scope
                        .signalResolution($scope.rule.targetPlot)
                        .then(function (info) {
                            $scope.jobInfo.signalInfo = info;
                        })
                        .finally(function () {
                            $scope.jobInfo.resolvingResolution = false;
                        });
                }

                $scope.getPlotKey = function (plot) {
                    return plot ? chartbuilderUtil.getLetterFromUniqueKey(plot.uniqueKey) : '';
                };

                $scope.plots.some(function (plot) {
                    const thePlot = $scope.getPlotKey(plot) === $scope.rule.targetPlot;
                    if (thePlot) {
                        $scope.plot = plot;
                    }
                    return thePlot;
                });

                $scope.setSelectedPlot = function (plot) {
                    $scope.plot = plot;
                    $scope.rule.targetPlot = $scope.getPlotKey(plot);
                    getPlotResolution();
                };

                function validateRange() {
                    $scope.validationError = detectorUtils.validateRangeThreshold($scope.rule);
                    $scope.hasDynamic = detectorUtils.hasDynamicThreshold($scope.rule);
                }

                $scope.$watchGroup(
                    ['rule.above', 'rule.below', 'rule.thresholdMode'],
                    validateRange
                );

                $scope.$watch('rule.thresholdMode', function (current, previous) {
                    if (current === previous) return;

                    // When swapping from above to below transfer the input value
                    if (previous === 'above' && current === 'below') {
                        $scope.rule.below = $scope.rule.above;
                        $scope.rule.above = '';
                    } else if (previous === 'below' && current === 'above') {
                        $scope.rule.above = $scope.rule.below;
                        $scope.rule.below = '';
                    } else if (previous === 'out of range' || previous === 'within range') {
                        // If switching from two inputs to one, reset the one that's now hidden
                        if (current === 'above') {
                            $scope.rule.below = '';
                        } else if (current === 'below') {
                            $scope.rule.above = '';
                        }
                    }
                });

                $scope.$watch('rule.triggerMode', function (current, previous) {
                    if (current === previous && current !== 'immediately') return;

                    // Set the defaults when switching to a new trigger mode
                    switch (current) {
                        case 'immediately':
                            $scope.rule.count = 1;
                            delete $scope.rule.percentOfDuration;
                            $scope.rule.duration = '1 second';
                            break;
                        case 'duration':
                            $scope.rule.percentOfDuration = 100;
                            delete $scope.rule.count;
                            $scope.rule.duration = '';
                            break;
                        case 'percent of duration':
                            $scope.rule.percentOfDuration = 100;
                            delete $scope.rule.count;
                            $scope.rule.duration = '';
                            break;
                        case 'occurrences in time window':
                            delete $scope.rule.percentOfDuration;
                            $scope.rule.count = '';
                            $scope.rule.duration = '';
                            break;
                        case 'custom':
                            delete $scope.rule.percentOfDuration;
                            $scope.rule.count = '';
                            $scope.rule.duration = '';
                            break;
                    }
                });
                getPlotResolution();
                validateRange();

                $scope.$watch('preSelectedSignal', function (value) {
                    if (value) {
                        const preSelectedPlot = $scope.plots.find(
                            (plot) => $scope.getPlotKey(plot) === value
                        );
                        $scope.setSelectedPlot(preSelectedPlot);
                    }
                });
            },
        };
    },
]);
