export default {
    BASE_METRIC_TEMPLATES: [
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_pod_name',
                    type: 'property',
                },
            ],
            template:
                "CPU_UTIL = data('container_cpu_utilization'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_pod_name',
                    type: 'property',
                },
            ],
            template:
                "CPU_QUOTA = data('container_spec_cpu_quota'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_pod_name',
                    type: 'property',
                },
            ],
            template:
                "CONTAINER_CPU = data('container_spec_cpu_period'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_pod_name',
                    type: 'property',
                },
            ],
            template:
                "CONTAINER_MEM = data('container_memory_usage_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_pod_name',
                    type: 'property',
                },
            ],
            template:
                "CONTAINER_MEM_LIMIT_KUBELET = data('container_spec_memory_limit_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id']).above(0, inclusive=True)",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_pod_name',
                    type: 'property',
                },
            ],
            template:
                "CONTAINER_MEM_LIMIT_API = data('kubernetes.container_memory_limit'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id']).above(0, inclusive=True)",
        },
        {
            template: 'PRE_V5_3_SA_MEM = (CONTAINER_MEM / CONTAINER_MEM_LIMIT_KUBELET)*100',
        },
        {
            template: 'POST_V5_3_SA_MEM = (CONTAINER_MEM / CONTAINER_MEM_LIMIT_API)*100',
        },
        {
            template:
                "CPU_UTILIZATION_LIMIT = data('kubernetes.container_cpu_limit'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id'])",
        },
        {
            template: 'PRE_V5_3_SA_CPU_PERCENT = (CPU_UTIL/(CPU_QUOTA/CONTAINER_CPU))',
        },
        {
            template: 'POST_V5_3_SA_CPU_PERCENT = (CPU_UTIL / CPU_UTILIZATION_LIMIT)',
        },
    ],
    METRICS: [
        {
            displayName: 'CPU',
            job: {
                template:
                    "CPU_PERCENT = union(PRE_V5_3_SA_CPU_PERCENT, POST_V5_3_SA_CPU_PERCENT).sum(by=['container_spec_name', 'container_id'])",
                varName: 'CPU_PERCENT',
            },
        },
        {
            displayName: 'NODE_CPU',
            job: {
                template:
                    "NODE_CPU = data('container_cpu_utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='rate').sum(by=['container_spec_name', 'container_id'])",
                varName: 'NODE_CPU',
            },
        },
        {
            displayName: 'MEMORY',
            job: {
                template: 'MEMORY_PERCENT = union(PRE_V5_3_SA_MEM, POST_V5_3_SA_MEM)',
                varName: 'MEMORY_PERCENT',
            },
        },
        {
            displayName: 'MEMORY_USED',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_pod_name',
                        type: 'property',
                    },
                ],
                template:
                    "MEMORY_USED = data('container_memory_usage_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}).sum(by=['container_spec_name', 'container_id'])",
                varName: 'MEMORY_USED',
            },
        },
    ],
    COLUMNS: [
        {
            aggregate: null,
            displayName: 'Container',
            format: 'link',
            property: 'container_spec_name',
            propertiesToLinkOn: ['kubernetes_cluster', 'container_id'],
            panelName: 'container_id',
        },
        {
            aggregate: null,
            displayName: 'Pod Name',
            format: null,
            property: 'kubernetes_pod_name',
        },
        {
            aggregate: null,
            displayName: 'CPU % of Limit',
            format: 'Percentage',
            property: 'CPU',
        },
        {
            aggregate: null,
            displayName: 'CPU % of Node',
            format: 'Percentage',
            property: 'NODE_CPU',
        },
        {
            aggregate: null,
            displayName: 'Memory % of Limit',
            format: 'Percentage',
            property: 'MEMORY',
        },
        {
            aggregate: null,
            displayName: 'Memory Used (Bytes)',
            format: 'ScaleBytes',
            property: 'MEMORY_USED',
        },
    ],
    UNIQUE_ID: '{{ container_spec_name }}:{{ container_id }}',
};
