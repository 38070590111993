export default [
    'obfuscateSecret',
    function (obfuscateSecret) {
        const AUTH_METHOD = {
            SECURITY_TOKEN: 'SecurityToken',
            EXTERNAL_ID: 'ExternalId',
        };

        function getAuthMethod($scope) {
            if ($scope.credential.authMethod) return $scope.credential.authMethod;

            if ($scope.credential.key || $scope.credential.token) {
                return AUTH_METHOD.SECURITY_TOKEN;
            } else if ($scope.credential.roleArn) {
                return AUTH_METHOD.EXTERNAL_ID;
            } else {
                return AUTH_METHOD.EXTERNAL_ID;
            }
        }

        function updateCredentials($scope) {
            if (!($scope.temp.key && $scope.temp.token) && !$scope.credential.roleArn) {
                $scope.credential.key = null;
                $scope.credential.token = null;
                $scope.credential.roleArn = null;
            } else {
                if ($scope.credential.authMethod === AUTH_METHOD.SECURITY_TOKEN) {
                    $scope.key = obfuscateSecret($scope.temp.key);
                    $scope.token = obfuscateSecret($scope.temp.token);
                    $scope.credential.key = $scope.temp.key;
                    $scope.credential.token = $scope.temp.token;
                }
                $scope.sharedCredentialState.updateCredentials = true;
            }
        }

        function setCredentialsDependingOnAuthMethod($scope, newValue, oldValue) {
            if (!newValue || newValue === oldValue) return;

            switch (newValue) {
                case AUTH_METHOD.SECURITY_TOKEN:
                    $scope.credential.roleArn = '';
                    break;
                case AUTH_METHOD.EXTERNAL_ID:
                    $scope.temp.key = '';
                    $scope.temp.token = '';
                    $scope.credential.key = '';
                    $scope.credential.token = '';
                    break;
            }
        }

        return {
            AUTH_METHOD,
            getAuthMethod,
            updateCredentials,
            setCredentialsDependingOnAuthMethod,
        };
    },
];
