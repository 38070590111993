import { keyValuePairToQuery } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './heatmapAlertsTab.tpl.html';

angular.module('signalview.heatmap').directive('heatmapAlertsTab', [
    'getAlertList',
    'applyAlertListState',
    'getAlertAggregation',
    'severities',
    'ETSAlertService',
    'alertTypeService',
    function (
        getAlertList,
        applyAlertListState,
        getAlertAggregation,
        severities,
        ETSAlertService,
        alertTypeService
    ) {
        return {
            restrict: 'E',
            scope: {
                hosts: '=',
                heatmap: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.severities = severities;

                let maxResult = 20;

                const heatmap = $scope.heatmap;

                function getFilters() {
                    return heatmap.getDashboardFilters().map(function (filter) {
                        return keyValuePairToQuery(filter[0], filter[1]);
                    });
                }

                $scope.severityFilter = null;
                $scope.filters = getFilters();

                function setGroupBy(groupBy) {
                    $scope.groupBy = groupBy;
                    if (
                        heatmap.mode().alwaysGroupBy &&
                        $scope.groupBy.indexOf(heatmap.mode().alwaysGroupBy) === -1
                    ) {
                        $scope.groupBy.push(heatmap.mode().alwaysGroupBy);
                    }
                }
                setGroupBy(heatmap.groupBy());

                $scope.$on('severity clicked', function (ev, filters) {
                    const newSeverity = filters.sf_severity;
                    delete filters['sf_severity'];

                    const currentFilterByObj = {};
                    heatmap.filterBy().forEach(function (filter) {
                        currentFilterByObj[filter.property] = filter.value;
                    });

                    const isEq = angular.equals(currentFilterByObj, filters);
                    if (!isEq) {
                        const result = [];
                        angular.forEach(filters, function (val, key) {
                            let not = false;
                            if (val === 'n/a') {
                                val = '*';
                                not = true;
                            }
                            const query = (not ? 'NOT ' : '') + key + ':' + val;
                            result.push({
                                NOT: not,
                                iconClass: 'icon-properties',
                                property: key,
                                query: query,
                                type: 'property',
                                value: key + ':' + val,
                                propertyValue: val,
                            });
                        });
                        heatmap.filterBy(result);
                    }
                    if ($scope.severityFilter !== newSeverity) {
                        $scope.severityFilter = newSeverity;
                        if (!isEq) {
                            // there's no filter difference but severity changes
                            // we need to trigger update manually, otherwise we wait for
                            // callbacks from heatmap
                            updateDetectorEvents();
                        }
                    }
                });

                $scope.$on('severity panel clicked', function (ev, severity) {
                    if ($scope.severityFilter === severity) {
                        $scope.severityFilter = null;
                    } else {
                        $scope.severityFilter = severity;
                    }
                    updateDetectorEvents();
                });

                function updateDetectorEvents() {
                    getAlertAggregation(ETSAlertService, getQuery()).then(function (result) {
                        $scope.eventData = result;
                        $scope.dataReady = true;
                    });
                    fetchAlertList();
                }

                function getQuery() {
                    const mode = heatmap.mode();
                    const modeAlertQuery = mode.alertQuery;
                    let query =
                        modeAlertQuery +
                        ' AND sf_anomalyState:(' +
                        alertTypeService.getFiringStateQueryStr() +
                        ')';

                    const filters = getFilters().concat(
                        $scope.groupBy.map(function (groupBy) {
                            return '_exists_:' + groupBy;
                        })
                    );
                    if (filters && filters.length) {
                        query += ' AND ' + filters.join(' AND ');
                    }
                    return query;
                }

                function fetchAlertList() {
                    const query = getQuery();
                    $scope.loading = true;
                    getAlertList(
                        ETSAlertService,
                        query,
                        maxResult,
                        '',
                        $scope.groupBy,
                        'sf_severity',
                        $scope.severityFilter
                    ).then(function (result) {
                        $scope.incidentList = applyAlertListState(result, $scope.incidentList);
                        $scope.loading = false;
                    });
                }

                const heatmapEventBindings = [];

                heatmapEventBindings.push(
                    heatmap.on('filterBy updated', function () {
                        $scope.filters = getFilters();
                    })
                );

                heatmapEventBindings.push(
                    heatmap.on('groupBy updated', function () {
                        setGroupBy(heatmap.groupBy());
                        fetchAlertList();
                    })
                );

                heatmapEventBindings.push(heatmap.on('nodes updated', updateDetectorEvents));
                heatmapEventBindings.push(heatmap.on('selection updated', updateDetectorEvents));
                heatmapEventBindings.push(
                    heatmap.on('groupBySelection updated', updateDetectorEvents)
                );

                $scope.$on('infiniteScroll.loadMore', function () {
                    maxResult += 20;
                    updateDetectorEvents();
                });

                $scope.$on('reload event list', function () {
                    updateDetectorEvents();
                });

                $scope.$on('$destroy', function () {
                    heatmapEventBindings.forEach(function (unbind) {
                        unbind();
                    });
                });

                updateDetectorEvents();
            },
        };
    },
]);
