import templateUrl from './detectorQuickNotificationsPanel.tpl.html';

angular
    .module('signalview.detector')
    .service('convertNotificationRules', [
        'detectorUtils',
        function (detectorUtils) {
            return function (uiModel, includeNotifications) {
                let ruleNumber = 0;
                const notificationRules = [];
                angular.forEach(uiModel.rules, function (rule) {
                    ruleNumber += 1;
                    const description = detectorUtils.getAutoDetectorRuleDescription(
                        rule,
                        uiModel.allPlots
                    );
                    const item = {
                        uniqueKey: rule.uniqueKey,
                        name: rule.name,
                        description: description,
                        severity: rule.severityLevel,
                        number: ruleNumber,
                        notifications: includeNotifications ? rule.notifications : [],
                    };
                    notificationRules.push(item);
                });
                return notificationRules;
            };
        },
    ])
    .directive('detectorQuickNotificationsPanel', [
        'notificationKeyService',
        function (notificationKeyService) {
            return {
                restrict: 'EA',
                templateUrl,
                scope: {
                    notificationRules: '=',
                    detector: '=',
                },
                link: function ($scope) {
                    $scope.allNotifications = [];

                    // notification key to the actual notification object.
                    let notificationRulesByKey = {};

                    function collapseData() {
                        $scope.allNotifications = [];

                        // A map of notification key to list of rules that got that notificiation.
                        notificationRulesByKey = {};

                        angular.forEach($scope.notificationRules, function (rule) {
                            for (let i = 0; i < rule.notifications.length; i++) {
                                const notification = rule.notifications[i];

                                // Generate a key that represent current state of the notification
                                // Only use it for the first time since the notification could be updated
                                // but we don't really regenerate the key.
                                notification.key = notificationKeyService(notification);

                                // See if we already have this notification stored
                                const notificationRules = notificationRulesByKey[notification.key];
                                if (!notificationRules) {
                                    // If not, create one and add this rule to the list.
                                    $scope.allNotifications.push(notification);
                                    notificationRulesByKey[notification.key] = [rule];
                                } else {
                                    // If we already see this notification, add this rule to its list.
                                    if (notificationRules.indexOf(rule) === -1) {
                                        notificationRules.push(rule);
                                    }
                                }
                            }
                        });
                    }

                    function expandData() {
                        // convert all the flatten out rules to per rule stuff.
                        angular.forEach($scope.notificationRules, function (rule) {
                            rule.notifications = [];
                        });

                        angular.forEach($scope.allNotifications, function (notification) {
                            if (notification.key) {
                                // has the key, meaning this came from the previous collapse and was mapped to each rule.
                                angular.forEach(
                                    notificationRulesByKey[notification.key],
                                    function (rule) {
                                        rule.notifications.push(angular.copy(notification));
                                    }
                                );
                            } else {
                                // no key, meaning this was added during this expansion, add this to all rule.
                                angular.forEach($scope.notificationRules, function (rule) {
                                    rule.notifications.push(angular.copy(notification));
                                });
                            }
                        });
                    }

                    $scope.collapse = function () {
                        collapseData();
                        $scope.expanded = false;
                    };

                    $scope.collapse();

                    $scope.onAllRemove = function (notification) {
                        delete notificationRulesByKey[notification.key];
                    };

                    $scope.expand = function () {
                        expandData();
                        $scope.expanded = true;
                    };

                    $scope.$on('beforeSave', function () {
                        // need to expand the data if it's in collapsed version since we need the expanded
                        // version data as parameters for detector (and template).
                        if (!$scope.expanded) {
                            expandData();
                        }
                    });
                },
            };
        },
    ]);
