angular.module('signalview.alertsOverview').directive('inputQueryParam', [
    'urlOverridesService',
    function (urlOverridesService) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                function renderFromURLParam() {
                    ngModel.$setViewValue(urlOverridesService.getQueryParam() || '');
                    ngModel.$render();
                }
                renderFromURLParam();
                $scope.$on('React:$routeUpdate', renderFromURLParam);

                element.bind('blur', function () {
                    const value = ngModel.$modelValue;
                    urlOverridesService.setQueryParam(value);
                    $scope.$digest();
                });
            },
        };
    },
]);
