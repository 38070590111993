angular.module('signalflowv2').service('filterConverter', [
    function () {
        function getValueOrValues(filterDef) {
            if (filterDef.value) {
                return filterDef.value.value;
            } else if (filterDef.values) {
                return filterDef.values.map((f) => {
                    return f.value;
                });
            } else {
                throw new Error('References to variables cannot be represented in Plot Builder.');
            }
        }

        function getIsMatchMissing(filterDef) {
            return filterDef.matchMissing && filterDef.matchMissing.value;
        }

        function convertToPill(filterDef) {
            if (filterDef.type === 'filter') {
                return {
                    iconClass: 'icon-properties',
                    NOT: false,
                    property: filterDef.field.value,
                    propertyValue: getValueOrValues(filterDef),
                    applyIfExists: getIsMatchMissing(filterDef),
                };
            } else if (filterDef.type === 'unary_expression' && filterDef.operation === 'NOT') {
                return angular.extend(convertToPill(filterDef.expression), { NOT: true });
            } else {
                throw new Error(
                    `The following SignalFlow cannot be represented in Plot Builder: ${filterDef.type}`
                );
            }
        }

        function convertFilterExpr(filterArg, filters) {
            switch (filterArg.type) {
                case 'filter':
                case 'unary_expression':
                    filters.push(convertToPill(filterArg));
                    break;
                case 'binary_expression':
                    if (filterArg.op === 'AND') {
                        convertFilterExpr(filterArg.left, filters);
                        convertFilterExpr(filterArg.right, filters);
                    } else {
                        throw new Error(
                            `The filter operation ${filterArg.op} is a boolean operation and cannot be represented in Plot Builder.`
                        );
                    }
                    break;
                default:
                    throw new Error(
                        `The following SignalFlow cannot be represented in Plot Builder: ${filterArg.type}`
                    );
            }
            return filters;
        }

        function convertTopLevelFilter(filterArg) {
            const filters = [];
            convertFilterExpr(filterArg, filters);
            return filters;
        }

        return convertTopLevelFilter;
    },
]);
