import { isGroupBySelected, moveGroupByColumnToFirstPosition } from './groupBy';

export function getTableChartColumns({ legendKeys, systemDimensionKeys, chartModel, getColDef }) {
    const nonSystemKeys = legendKeys.filter(
        (key) =>
            !systemDimensionKeys.includes(key) &&
            key !== 'sf_metric' &&
            key !== 'sf_originatingMetric'
    );

    const extraCols = getChartPlots(chartModel.sf_uiModel.allPlots, getColDef);

    const legendConfig = chartModel.sf_uiModel.chartconfig.legendColumnConfiguration || [];

    if (legendConfig.length > 0) {
        const keysCardinality = legendConfig.map((obj) => obj.property);
        nonSystemKeys.sort((a, b) => {
            if (keysCardinality.includes(a)) {
                return keysCardinality.indexOf(a) - keysCardinality.indexOf(b);
            }
            return 1;
        });
    }

    const nonSystemCols = nonSystemKeys.map((key) => (getColDef ? getColDef(key, null) : key));

    const colDefs = extraCols.concat(nonSystemCols);

    if (isGroupBySelected(chartModel)) {
        moveGroupByColumnToFirstPosition(chartModel, colDefs, (item) =>
            typeof item === 'string' ? item : item.key
        );
    }

    return colDefs;
}

function getChartPlots(allPlots, getColDef) {
    //Add the plot definitions to the columns
    const plotColumns = [];
    allPlots.forEach((plot) => {
        if (!plot.transient && (plot.type === 'plot' || plot.type === 'ratio')) {
            plotColumns.push(getColDef ? getColDef(plot.name, plot) : plot.name);
        }
    });

    return plotColumns;
}
