import React, { FC, useEffect } from 'react';
import * as d3 from 'd3';

type LOColumnChartProps = {
    usage: number;
    quota: number;
    usagelabel: string;
    labelToClassName: string;
};

const LOColumnChart: FC<LOColumnChartProps> = ({ usage, quota, usagelabel, labelToClassName }) => {
    const data = [usage];

    const BAR_COLOR = '#839869';
    const CHART_MARGIN = { top: 30, right: 30, bottom: 15, left: 40 };
    const CHART_WIDTH = 145 - CHART_MARGIN.left - CHART_MARGIN.right;
    const CHART_HEIGHT = 200 - CHART_MARGIN.top - CHART_MARGIN.bottom;

    useEffect(() => {
        const x = d3.scaleBand().rangeRound([0, CHART_WIDTH]).padding(0.35);
        const y = d3.scaleLinear().rangeRound([CHART_HEIGHT, 0]);

        const xAxis = d3.axisBottom(x).tickSize(0).tickValues([]);
        const yAxis = d3
            .axisLeft(y)
            .ticks(5)
            .tickSizeInner(-CHART_WIDTH)
            .tickSizeOuter(0)
            .tickFormat(d3.format(','));

        const selector = '.' + labelToClassName;

        d3.select(selector).selectAll('*').remove();

        const svg = d3
            .select(selector)
            .append('svg')
            .attr('width', CHART_WIDTH + CHART_MARGIN.left + CHART_MARGIN.right)
            .attr('height', CHART_HEIGHT + CHART_MARGIN.top + CHART_MARGIN.bottom)
            .append('g')
            .attr('transform', 'translate(' + CHART_MARGIN.left + ',' + CHART_MARGIN.top + ')');

        y.domain([0, Math.max(quota, usage)]).nice();

        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + CHART_HEIGHT + ')')
            .call(xAxis);

        // y-axis
        svg.append('g')
            .attr('class', 'y-axis')
            .call(yAxis)
            .append('text')
            .attr('transform', 'rotate(-90)');

        svg.selectAll()
            .data(data)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', 15)
            .attr('width', x.bandwidth())
            .attr('y', function (d: number) {
                return y(d);
            })
            .attr('height', function (d: number) {
                return CHART_HEIGHT - y(d);
            })
            .style('opacity', 0.6)
            .style('fill', BAR_COLOR);

        const planLimit = quota;
        if (planLimit >= 0) {
            // Horizontal line indicating plan limit amount for this category
            svg.append('g')
                .attr('transform', 'translate(0, ' + y(planLimit) + ')')
                .append('line')
                .attr('x2', CHART_WIDTH)
                .style('stroke', '#EA1849')
                .style('stroke-width', '1px');

            // Label for line
            svg.append('text')
                .attr('y', y(planLimit) - 3)
                .attr('x', 0)
                .attr('text-anchor', 'start')
                .style('fill', '#EA1849')
                .style('font-size', '10px')
                .text('Plan: ' + d3.format(',')(planLimit) + 'GB');
        }
    });

    return (
        <div className="current-usage-chart">
            <svg className={labelToClassName} width="145" height="200" />
            <div className="current-usage-count">
                {usagelabel}:<br />
                {usage + ' GB'}
            </div>
        </div>
    );
};

export default LOColumnChart;
