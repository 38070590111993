import React, { useContext } from 'react';

export type QueryParamType = Record<
    string,
    string | boolean | number | Array<string> | Array<boolean> | Array<number> | null | undefined
>;

export type RouteContext = {
    matchedPath: string;
    pathname: string;
    url: string;
    search: QueryParamType;
    pathParams: Record<string, string | undefined>;
    /**
     * Data type combines search and params into single object
     */
    params: QueryParamType;
    reloadPath?: () => void;
};

export const defaultRouteValue: RouteContext = {
    matchedPath: '',
    pathname: '/',
    url: '/',
    search: {},
    params: {},
    pathParams: {},
};

export const RouteContext = React.createContext<RouteContext>(defaultRouteValue);

export const useRouteContext = (): RouteContext => useContext(RouteContext);
