import templateUrl from './businessWorkflowSelector.tpl.html';

export default {
    templateUrl,
    bindings: {
        defaultSelection: '<',
        selectedDetectorType: '<',
        environment: '<',
        onSelect: '&',
        onRemove: '&',
        onFocus: '&',
        onBlur: '&',
    },
    controller: [
        '_',
        '$element',
        '$timeout',
        'BusinessWorkflowSelection',
        'businessWorkflowSelectorDataSource',
        function (
            _,
            $element,
            $timeout,
            BusinessWorkflowSelection,
            businessWorkflowSelectorDataSource
        ) {
            const $ctrl = this;
            const ON_BLUR_INPUT_TIMEOUT = 120;

            // initial variables
            $ctrl.workflowNameOptions = [];
            $ctrl.dropdownOptions = [];
            $ctrl.editMode = false;
            $ctrl.showDropdown = false;
            $ctrl.inMultiSelectContext = false;

            // lifecycle bindings
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            // blue pill related methods
            $ctrl.removeBusinessWorkflowSelection = removeBusinessWorkflowSelection;
            $ctrl.modifySelection = modifySelection;

            // input related methods
            $ctrl.isInputEmpty = isInputEmpty;
            $ctrl.onClickInput = onClickInput;
            $ctrl.onChangeInput = onChangeInput;
            $ctrl.onBlurInput = onBlurInput;
            $ctrl.getInputValues = getInputValues;

            // dropdown related methods
            $ctrl.optionFilter = optionFilter;
            $ctrl.selectOption = selectOption;
            $ctrl.onDropdownToggle = onDropdownToggle;
            $ctrl.disableEditMode = disableEditMode;

            function $onInit() {
                $ctrl.businessWorkflowSelection =
                    $ctrl.defaultSelection || new BusinessWorkflowSelection();
                resetPlaceholder();
            }

            function $onChanges(changesObj) {
                const {
                    environment: environmentChange,
                    selectedDetectorType: selectedDetectorTypeChange,
                } = changesObj;

                if (environmentChange || selectedDetectorTypeChange) {
                    fetchAndUpdateWorkflowOptions();
                }
            }

            function removeBusinessWorkflowSelection() {
                $ctrl.onRemove({ businessWorkflowSelection: $ctrl.businessWorkflowSelection });
                $ctrl.businessWorkflowSelection.clear();
                resetInput();
                closeDropdown();
            }

            // blue pill related methods

            function modifySelection() {
                $ctrl.editMode = true;
                openDropdown();
            }

            // input related methods

            function isInputEmpty() {
                return !$ctrl.inputValue || $ctrl.inputValue === '';
            }

            function onClickInput() {
                if ($ctrl.businessWorkflowSelection.isEmpty()) {
                    $ctrl.placeholder = 'Select a business workflow';
                }

                $ctrl.editMode = true;
                openDropdown();

                if ($ctrl.onFocus) $ctrl.onFocus();
            }

            function onChangeInput() {
                if (isInputEmpty()) {
                    handldInputIsCleared();
                    return;
                }
            }

            function onBlurInput() {
                $timeout(() => {
                    if (!$ctrl.editMode && !$ctrl.showDropdown && $ctrl.onBlur) {
                        $ctrl.onBlur();
                    }
                }, ON_BLUR_INPUT_TIMEOUT);
            }

            function getInputValues() {
                return isInputEmpty() ? '' : $ctrl.inputValue;
            }

            // dropdown related methods

            function optionFilter(option) {
                if (isInputEmpty()) return true;

                const resourceInputValue = getInputValues();
                return option.value.toLowerCase().includes(resourceInputValue.toLowerCase());
            }

            function selectOption(option, isMultiSelect) {
                if (isMultiSelect) $ctrl.inMultiSelectContext = true;
                selectResource(option.value);
            }

            function onDropdownToggle(open) {
                if (open) {
                    setInput();
                } else {
                    $ctrl.inMultiSelectContext = false;
                    updateBusinessWorkflowSelection();
                }
            }

            function disableEditMode() {
                $ctrl.editMode = false;
                resetInput();
                closeDropdown();
            }

            // private methods

            function convertToOptions(list, type) {
                if (!angular.isArray(list)) return;

                return _.uniq(list).map((option) => {
                    return { type, value: option };
                });
            }

            function updateBusinessWorkflowSelection() {
                const selection = $ctrl.businessWorkflowSelection;

                if (selection.isComplete()) {
                    $ctrl.onSelect({ businessWorkflowSelection: selection });
                } else {
                    selection.clear();

                    if ($ctrl.onBlur) $ctrl.onBlur();
                }

                resetInput();
            }

            function selectResource(selectedResource) {
                $ctrl.businessWorkflowSelection.resource = selectedResource;
                $ctrl.inputValue = selectedResource;
                disableEditMode();
            }

            function openDropdown() {
                setDropdownOptions();

                $timeout(() => {
                    focusOnInputBox();
                    $ctrl.showDropdown = true;
                }, 0);
            }

            function closeDropdown() {
                $ctrl.showDropdown = false;
            }

            function setDropdownOptions() {
                $ctrl.dropdownOptions = $ctrl.workflowNameOptions;
            }

            function setInput() {
                if ($ctrl.businessWorkflowSelection.isEmpty()) return;

                $ctrl.inputValue = $ctrl.businessWorkflowSelection.resource;
            }

            function resetInput() {
                $ctrl.inputValue = '';
                $ctrl.dropdownOptions = [];
                resetPlaceholder();
                $ctrl.editMode = false;
            }

            function handldInputIsCleared() {
                $ctrl.businessWorkflowSelection.clear();
                resetPlaceholder();
            }

            function resetPlaceholder() {
                $ctrl.placeholder = 'Select a business workflow';
            }

            function focusOnInputBox() {
                const inputBox = $element.find('.business-workflow-selector-input')[0] || {};
                $timeout(() => {
                    inputBox.focus();
                }, 0);
            }

            function fetchAndUpdateWorkflowOptions() {
                $ctrl.loading = true;

                $ctrl.workflowOptionsCache = new Map();
                businessWorkflowSelectorDataSource
                    .fetchWorkflows($ctrl.selectedDetectorType, $ctrl.environment)
                    .then((workflows) => {
                        $ctrl.workflowNameOptions = convertToOptions(workflows, 'workflow');
                        setDropdownOptions();

                        $ctrl.loading = false;
                    });
            }
        },
    ],
};
