const severities = {
    critical: 'critical',
    warning: 'warning',
    normal: 'normal',
    unknown: 'unknown',
    unready: 'unready',
};

const KUBE_POD_PHASE_TEMPLATE = `
KUBE_POD_PHASE = data("kubernetes.pod_phase"{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation="last_value", maxExtrapolations=2, rollup="latest")
KUBE_POD_PHASE.count(by=['kubernetes_cluster', 'kubernetes_pod_name', 'sf_service']).publish('Pod SFx Service')
KUBE_POD_PHASE.count(by=['kubernetes_cluster', 'kubernetes_pod_name', 'sf_environment']).publish('Pod SFx Environment')`;

const KUBE_CONTAINER_TEMPLATE = `
CONTAINER_READY = data("kubernetes.container_ready"{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation="last_value", maxExtrapolations=2, rollup="latest")
CONTAINER_READY.count(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_id', 'sf_service']).publish('Container SFx Service')
CONTAINER_READY.count(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_id', 'sf_environment']).publish('Container SFx Environment')
`;

export default {
    defaultResolution: 10000,
    deadHostPeriod: 60000,

    hierarchy: ['CLUSTER', 'NODE', 'POD', 'CONTAINER'],

    resources: {
        CLUSTER: {
            displayName: 'Kubernetes Clusters',
            groupBy: 'kubernetes_cluster',
            idTemplate: ['kubernetes_cluster'],
            tooltipKeyList: [
                {
                    displayName: 'Nodes',
                    format: 'Number',
                    property: 'subLevelCount',
                },
            ],
            filterAction: 'REMOVE_CHILD', // hides clusters which have children that do not match any filters
            parentHeaderCandidatesSelectorMetric: null,
        },

        NODE: {
            displayName: 'Kubernetes Nodes',
            groupBy: 'kubernetes_node',
            idTemplate: ['kubernetes_cluster', 'kubernetes_node'],
            layoutJob: null, // Use coloring job
            tooltipKeyList: [
                {
                    displayName: 'Pods',
                    format: 'Number',
                    property: 'subLevelCount',
                },
                {
                    property: 'value', // Use metric params
                },
            ],
            filterAction: 'REMOVE_CHILD:ALLOW_EMPTY_MATCH', // hides node which have children that do not match any filters
            // but will keep it if the node itself matches a filter
            // Match any of the filter
            parentHeaderCandidatesSelectorMetric: {
                displayName: 'Master Node',
                job: {
                    filters: [
                        {
                            property: '_exists_',
                            propertyValue: 'kubernetes_cluster',
                            NOT: false,
                        },
                        {
                            property: '_exists_',
                            propertyValue: 'kubernetes_node',
                            NOT: false,
                        },
                    ],
                    replaceOnlyFilters: ['kubernetes_node', 'kubernetes_cluster'],
                    template:
                        'MASTER_NODES = data("container_cpu_utilization", filter=(filter("kubernetes_role", "master") or filter("sf_tags", "node-role_kubernetes_io_master"))' +
                        '{{#filter}} and {{{filter}}}{{/filter}}, extrapolation="last_value", maxExtrapolations=2).mean(by=["kubernetes_cluster", "kubernetes_node"])',
                    varName: 'MASTER_NODES',
                },
            },

            metrics: [
                {
                    // We will use the property from mts which reports 1. (MTS pretending to be Boolean !!)
                    displayName: 'Node Condition',
                    description: '',
                    job: {
                        filters: [
                            {
                                property: '_exists_',
                                propertyValue: 'kubernetes_cluster',
                                NOT: false,
                            },
                            {
                                property: '_exists_',
                                propertyValue: 'kubernetes_node',
                                NOT: false,
                            },
                        ],
                        replaceOnlyFilters: ['kubernetes_node', 'kubernetes_cluster'],
                        template:
                            'NODE_CONDITION = data("kubernetes.node_*"{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation="last_value", maxExtrapolations=2, rollup="latest")',
                        varName: 'NODE_CONDITION',
                    },
                    coloring: {
                        method: 'TOP',
                        stateToSeverity: {
                            OUT_OF_DISK: severities.critical,
                            NETWORK_UNAVAILABLE: severities.critical,
                            DEFAULT: severities.unknown,
                            MEMORY_PRESSURE: severities.warning,
                            PID_PRESSURE: severities.warning,
                            DISK_PRESSURE: severities.warning,
                            NODE_READY: severities.normal,
                        },
                    },
                    valueType: 'CUSTOM',
                    valueLabel: 'Conditions',

                    suppliesLayoutInfo: true,
                    format: 'AGGREGATE', // Aggregates multiple metrics having the supplied targetValue
                    targetValue: 1,
                    aggregationProperty: 'sf_originatingMetric',
                    map: {
                        _default: { severity: 4, key: 'DEFAULT', displayValue: 'Unknown' },
                        'kubernetes.node_out_of_disk': {
                            severity: 5,
                            key: 'OUT_OF_DISK',
                            displayValue: 'Out of Disk',
                        },
                        'kubernetes.node_memory_pressure': {
                            severity: 4,
                            key: 'MEMORY_PRESSURE',
                            displayValue: 'Memory Pressure',
                        },
                        'kubernetes.node_p_i_d_pressure': {
                            severity: 4,
                            key: 'PID_PRESSURE',
                            displayValue: 'PID Pressure',
                        },
                        'kubernetes.node_disk_pressure': {
                            severity: 4,
                            key: 'DISK_PRESSURE',
                            displayValue: 'Disk Pressure',
                        },
                        'kubernetes.node_network_unavailable': {
                            severity: 5,
                            key: 'NETWORK_UNAVAILABLE',
                            displayValue: 'Network Unavailable',
                        },
                        'kubernetes.node_ready': {
                            severity: 2,
                            key: 'NODE_READY',
                            displayValue: 'Node Ready',
                        },
                    },
                },
            ],
        },

        POD: {
            displayName: 'Kubernetes Pods',
            groupBy: 'kubernetes_pod_name',
            idTemplate: ['kubernetes_cluster', 'kubernetes_pod_name'],
            filterAction: 'COLOR_CHILD',
            tooltipKeyList: [
                {
                    displayName: 'Pod Name',
                    property: 'kubernetes_pod_name',
                },
                {
                    displayName: 'Containers',
                    format: 'Number',
                    property: 'subLevelCount',
                },
                {
                    property: 'value',
                },
            ],

            metrics: [
                {
                    displayName: 'Pod Phase',
                    description: '',
                    job: {
                        filters: [
                            {
                                property: '_exists_',
                                propertyValue: 'kubernetes_cluster',
                                NOT: false,
                            },
                            {
                                property: '_exists_',
                                propertyValue: 'kubernetes_pod_uid',
                                NOT: false,
                            },
                        ],
                        replaceOnlyFilters: [
                            'kubernetes_pod_uid',
                            'kubernetes_pod_name',
                            'kubernetes_namespace',
                            'kubernetes_node',
                            'kubernetes_cluster',
                        ],
                        template: KUBE_POD_PHASE_TEMPLATE,
                        varName: 'KUBE_POD_PHASE',
                    },
                    coloring: {
                        method: 'MAP',
                        stateToSeverity: {
                            FAILED: severities.critical,
                            UNKNOWN: severities.unknown,
                            DEFAULT: severities.warning,
                            PENDING: severities.warning,
                            RUNNING: severities.normal,
                            SUCCEEDED: severities.normal,
                        },
                    },
                    valueLabel: 'Phase',
                    valueType: 'CUSTOM',

                    suppliesLayoutInfo: true,
                    format: 'ENUM',
                    enum: ['_default', 'Pending', 'Running', 'Succeeded', 'Failed', 'Unknown'],
                    map: {
                        _default: { severity: 2, key: 'DEFAULT', displayValue: 'Unknown' },
                        Pending: { severity: 2, key: 'PENDING', displayValue: 'Pending' },
                        Running: { severity: 5, key: 'RUNNING', displayValue: 'Running' },
                        Succeeded: { severity: 5, key: 'SUCCEEDED', displayValue: 'Succeeded' },
                        Failed: { severity: 4, key: 'FAILED', displayValue: 'Failed' },
                        Unknown: { severity: 2, key: 'UNKNOWN', displayValue: 'Unknown' },
                    },
                },
            ],
        },

        CONTAINER: {
            displayName: 'Containers',
            groupBy: 'container_id',
            idTemplate: ['kubernetes_cluster', 'kubernetes_pod_uid', 'container_id'],
            tooltipKeyList: [
                {
                    property: 'value',
                },
            ],
            filterAction: 'COLOR_CHILD',
            metrics: [
                {
                    displayName: 'Container Status',
                    description: '',
                    job: {
                        filters: [
                            {
                                property: '_exists_',
                                propertyValue: 'kubernetes_cluster',
                                NOT: false,
                            },
                            {
                                property: '_exists_',
                                propertyValue: 'kubernetes_pod_uid',
                                NOT: false,
                            },
                            {
                                property: '_exists_',
                                propertyValue: 'container_id',
                                NOT: false,
                            },
                        ],
                        replaceOnlyFilters: [
                            'container_id',
                            'container_spec_name',
                            'kubernetes_pod_uid',
                            'kubernetes_pod_name',
                            'kubernetes_namespace',
                            'kubernetes_node',
                            'kubernetes_cluster',
                        ],
                        template: KUBE_CONTAINER_TEMPLATE,
                        varName: 'CONTAINER_READY',
                    },
                    coloring: {
                        method: 'MAP',
                        stateToSeverity: {
                            NOT_READY: severities.unready,
                            DEFAULT: severities.unknown,
                            READY: severities.normal,
                        },
                    },
                    valueLabel: 'Container Ready',
                    valueType: 'CUSTOM',

                    suppliesLayoutInfo: true,
                    format: 'THRESHOLD',
                    thresholds: { min: 0.5 },
                    map: {
                        _default: { severity: 2, key: 'DEFAULT', displayValue: 'Unknown' },
                        INSIDE: { severity: 4, key: 'READY', displayValue: 'Ready' },
                        OUTSIDE: { severity: 5, key: 'NOT_READY', displayValue: 'Not Ready' },
                    },
                },
            ],
        },
    },
};
