export const largeVolumeBarService = [
    '$timeout',
    'migratedCredentialV2Service',
    'appNotificationService',
    'largeVolumeModalService',
    function (
        $timeout,
        migratedCredentialV2Service,
        appNotificationService,
        largeVolumeModalService
    ) {
        let notificationBarId;
        let debounce;

        function removeNotificationBar() {
            if (notificationBarId) {
                appNotificationService.remove(notificationBarId);
                notificationBarId = '';
            }
        }

        function initializeNotificationBar() {
            return;
        }

        function openModal() {
            if (notificationBarId) {
                modalCallback();
            } else if (debounce) {
                debounce.then(modalCallback);
            }
        }

        function modalCallback() {
            migratedCredentialV2Service.getLargeVolumeAWSIntegration().then((creds) => {
                if (creds.count === 0) {
                    removeNotificationBar();
                }
                largeVolumeModalService.open({
                    credentials: creds.results,
                });
            });
        }

        return {
            initializeNotificationBar,
            openModal,
        };
    },
];
