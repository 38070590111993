import templateUrl from './alertMessage.tpl.html';

angular
    .module('signalview.utils')

    .controller('AlertMessageController', [
        '$scope',
        'params',
        function ($scope, params) {
            $scope.title = params.title || 'Message';
            $scope.messages = params.messages || [];
        },
    ])

    .service('alertMessageService', [
        'sfxModal',
        function (sfxModal) {
            return function (params) {
                return sfxModal.open({
                    templateUrl,
                    controller: 'AlertMessageController',
                    resolve: {
                        params: function () {
                            return params;
                        },
                    },
                }).result;
            };
        },
    ]);
