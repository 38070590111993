export const ETSAlertService = [
    'eventTimeSeriesService',
    'severities',
    'mutingService',
    'alertTypeService',
    'moment',
    'featureEnabled',
    function (
        eventTimeSeriesService,
        severities,
        mutingService,
        alertTypeService,
        moment,
        featureEnabled
    ) {
        const eventActivityProperty = 'sf_anomalyStateUpdateTimestampMs';
        const eventSeverityProperty = 'sf_severity';

        function generateETSProperties(event) {
            function isNotSystemKey(key) {
                return (
                    key !== 'sf_eventType' &&
                    key !== 'sf_programId' &&
                    key !== 'computationId' &&
                    key !== 'jobId' &&
                    (key !== 'sf_source' ||
                        (key === 'sf_source' && event.sf_source.indexOf('_SF_PLOT_KEY') !== 0))
                );
            }

            return (event.sf_key?.filter(isNotSystemKey) || []).reduce(function (prev, cur) {
                prev[cur] = event[cur];
                return prev;
            }, {});
        }

        function generateETSDescription(properties) {
            return Object.keys(properties)
                .map(function (key) {
                    return properties[key];
                })
                .join(' ');
        }

        return {
            getEvents: function (query, max) {
                return eventTimeSeriesService
                    .search({
                        query: query,
                        orderBy: '-sf_priority,-' + eventActivityProperty,
                        offset: '0',
                        limit: max + '',
                    })
                    .then(function (res) {
                        return res.rs;
                    });
            },
            getExtras: function () {
                const mutingPromise = featureEnabled('useNewMutingGetCurrentEndpoint')
                    ? mutingService.getCurrent()
                    : mutingService.getAll({
                          query: '*',
                          limit: 1000,
                          orderBy: [],
                      });

                return mutingPromise.then(function (result) {
                    const results = [];
                    function processMuting(muting) {
                        if (angular.equals({}, muting._or)) {
                            results.push(muting);
                        } else {
                            // if they have or filters, we will go through them and put each of them on the property
                            const key = Object.keys(muting._or)[0];
                            const val = muting._or[key];
                            for (let i = 0; i < val.length; i++) {
                                const copied = angular.extend({}, muting);
                                angular.extend(copied._or, muting._or);
                                delete copied._or[key];
                                copied[key] = val[i];
                                processMuting(copied);
                            }
                        }
                    }
                    const now = Date.now();
                    result
                        .map(function (muting) {
                            if (muting.startTime < now) {
                                muting.started = true;
                            }
                            const not = {};
                            const or = {};
                            const obj = {
                                _original: muting,
                                _or: or,
                                _not: not,
                            };
                            muting.filters.forEach(function (filter) {
                                if (filter.NOT) {
                                    not[filter.property] = filter.propertyValue;
                                } else if (angular.isArray(filter.propertyValue)) {
                                    or[filter.property] = filter.propertyValue;
                                } else {
                                    obj[filter.property] = filter.propertyValue;
                                }
                            });
                            return obj;
                        })
                        .forEach(processMuting);
                    return results;
                });
            },
            aggregation: function (query) {
                return eventTimeSeriesService.aggregation({
                    query: query,
                    getAggregation: true,
                    terms: [eventSeverityProperty],
                });
            },
            processEvent: function (event) {
                const detectorId = event.sf_detectorId;
                const properties = generateETSProperties(event);
                const description = generateETSDescription(properties);
                // add detectorId after we generate descriptions;
                properties.sf_detectorId = detectorId;

                return {
                    severity: event.sf_severity,
                    duration: event[eventActivityProperty]
                        ? moment(event[eventActivityProperty]).fromNow(true)
                        : 'Unknown',
                    properties: properties,
                    description: description,
                    detector: event.sf_detector || 'Unknown Detector Name',
                    rule: event.sf_eventSloAlertType || event.sf_displayName || 'Unknown Rule Name',
                    id: event.sf_id,
                    detectorId: detectorId,
                    original: event,
                };
            },
            getBaseEventQuery: function () {
                return 'sf_anomalyState:(' + alertTypeService.getFiringStateQueryStr() + ')';
            },
            severityProperty: eventSeverityProperty,
            severities: severities,
            objectTypes: ['EventTimeSeries'],
            defaultSearchFields: ['sf_detector', 'sf_displayName'],
            hasLowerCaseIndex: true,
            alertMode: true,
        };
    },
];
