import { EnabledFeature } from '../../../../common/util/FeatureFlags';
import debug from 'debug';

type LoggingService = {
    logData: (logType: string, identifier: string, data: any) => Promise<void>;
};

const log = debug('Log Service').extend('log');
// ANSI escape codes for colors
const redColor = '\x1b[31m';
const yellowColor = '\x1b[33m';
const resetColor = '\x1b[0m';
const lightGreenColor = '\x1b[92m';
const lightBlueColor = '\x1b[94m';

type logType = { color: string };

export const logTypes: Record<string, logType> = {
    error: {
        color: redColor,
    },
    info: {
        color: lightBlueColor,
    },
    warn: {
        color: yellowColor,
    },
    default: {
        color: lightGreenColor,
    },
};

// Wrapper function to add color to messages
const addColor = (message: string, color: string): string => `${color}${message}${resetColor}`;

function logService(featureEnabled: EnabledFeature): LoggingService {
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async logData(logType: string, identifier: string, data: any): Promise<void> {
            if (featureEnabled && featureEnabled('enableSamlLogs')) {
                const timestamp = new Date().toISOString();
                let logMessage = `[${logType}] [${timestamp}] [Identifier: ${identifier}]`;

                if (data) {
                    // Convert object to string using JSON.stringify
                    const dataString: string = JSON.stringify(data, null, 2);
                    logMessage += ` [Data: ${dataString}]`;
                }

                const color = logTypes[logType.toLowerCase()]?.color || logTypes.default.color;
                // Log the message with the selected color
                log(addColor(logMessage, color));
            }
        },
    };
}

logService.$inject = ['featureEnabled'];

export default logService;
