import templateUrl from './elementalView.tpl.html';

angular.module('signalview.heatmap').directive('elementalView', [
    '$log',
    '$timeout',
    'heatmapDataService',
    '_',
    'colorAccessibilityService',
    'HEATMAP_ALERT_SEVERITY_LEVELS',
    'd3',
    function (
        $log,
        $timeout,
        heatmapDataService,
        _,
        colorAccessibilityService,
        HEATMAP_ALERT_SEVERITY_LEVELS,
        d3
    ) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
                colorPalette: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    const SAMPLING_LIMIT = 10000;
                    const JOB_START_TIMEOUT = 10000;
                    const HEATMAP_PALETTE = $scope.colorPalette;
                    let currentMaximum = 100;
                    let currentMinimum = 0;

                    const QUANTILE_COLORING = d3.scale
                        .quantile()
                        .domain([0, 100])
                        .range(HEATMAP_PALETTE);

                    function quantileColoringFunction(data) {
                        return QUANTILE_COLORING(data.value);
                    }

                    const heatmap = $scope.heatmap;

                    function syncMode() {
                        $scope.mode = heatmap.mode();
                    }

                    const heatmapEventBindings = [];
                    heatmapEventBindings.push(heatmap.on('mode updated', syncMode));
                    syncMode();

                    $scope.hasFilters = heatmap.filterBy().length > 0;
                    heatmapEventBindings.push(
                        heatmap.on('filterBy updated', function () {
                            $scope.hasFilters = heatmap.filterBy().length > 0;
                        })
                    );

                    $scope.hosts = {};
                    $scope.loading = true;

                    $scope.config = {
                        highlightedHosts: [],
                    };

                    const dataService = heatmapDataService.create(heatmap);

                    $scope.hasData = null;

                    function updateColorRange(min, max) {
                        const metric = heatmap.colorBy();
                        const coloring = metric.coloring || { method: 'quantile' };
                        if (coloring.method !== 'quantile') return;

                        min = coloring.minValue || min;
                        max = coloring.maxValue || max;
                        QUANTILE_COLORING.domain([min, max]);
                    }

                    // A one second grace period before showing the no data view
                    $timeout(function () {
                        if (!$scope.hasData) {
                            $scope.hasData = false;
                        }
                    }, JOB_START_TIMEOUT);

                    $scope.$watch('config.highlightedHosts', function (hosts) {
                        if (!hosts) return;

                        Object.keys($scope.hosts).forEach(function (id) {
                            const host = $scope.hosts[id];
                            host._highlighted = !hosts.length || hosts.indexOf(id) !== -1;
                        });

                        heatmap.update(_.values($scope.hosts));
                    });

                    heatmapEventBindings.push(
                        heatmap.on('colorBy updated', function ($event, colorBy) {
                            if (colorBy) {
                                updateColoringFunction();
                            }
                        })
                    );

                    heatmapEventBindings.push(
                        heatmap.on('groupBy updated', function ($event, groupBy) {
                            if (groupBy) {
                                updateColoringFunction();
                            }
                        })
                    );

                    function getThresholdGroupBy() {
                        const groupBy = heatmap.groupBy() || [];
                        return groupBy.slice(0, heatmap.groupByDepth());
                    }

                    function updateColoringFunction() {
                        const threshold = heatmap.threshold();
                        if (threshold && threshold.renderer) {
                            heatmap.coloringFunction(function (data) {
                                return threshold.renderer(
                                    data,
                                    $scope.stats,
                                    getThresholdGroupBy()
                                );
                            });

                            return;
                        }

                        const metric = heatmap.colorBy();

                        const config = metric.coloring || { method: 'quantile' };

                        if (config.method === 'quantile') {
                            let rangeToUse;
                            if (metric.type === 'event') {
                                const colors = colorAccessibilityService.get().getAlertColors();
                                rangeToUse = HEATMAP_ALERT_SEVERITY_LEVELS.map(
                                    (sev) => colors[sev.toLowerCase()]
                                ).reverse();
                            } else if (config.range) {
                                rangeToUse = config.range;
                            } else {
                                rangeToUse = HEATMAP_PALETTE;
                            }

                            if (!angular.equals(QUANTILE_COLORING.range(), rangeToUse)) {
                                QUANTILE_COLORING.range(rangeToUse);
                            }
                            heatmap.coloringFunction(quantileColoringFunction);
                        } else if (config.method === 'threshold') {
                            heatmap.coloringFunction(function (data) {
                                const value = data.value;
                                let coloringConfig;
                                for (let i = 0; i < config.limits.length; i++) {
                                    coloringConfig = config.limits[i];
                                    if (
                                        coloringConfig.lowerLimit === undefined ||
                                        value >= coloringConfig.lowerLimit
                                    ) {
                                        return coloringConfig.color;
                                    }
                                }
                            });
                        } else {
                            QUANTILE_COLORING.range(HEATMAP_PALETTE);
                            heatmap.coloringFunction(quantileColoringFunction);
                        }
                    }

                    updateColoringFunction();

                    heatmapEventBindings.push(
                        heatmap.on('threshold updated', updateColoringFunction)
                    );
                    heatmapEventBindings.push(
                        heatmap.on('groupByDepth updated', updateColoringFunction)
                    );

                    dataService.on('metadata', function ($event, hosts) {
                        hosts.forEach(function (host) {
                            if (host.id in $scope.hosts && !host.value) {
                                host.value = $scope.hosts[host.id].value;
                            }

                            $scope.hosts[host.id] = host;
                        });

                        $log.debug('Heatmap metadata updated');
                        heatmap.update(_.values($scope.hosts));
                        $scope.$broadcast('new hosts metadata', {});
                        $scope.loading = false;
                        $scope.noData = false;
                    });

                    $scope.noData = false;
                    dataService.on('no data', function () {
                        $scope.loading = false;
                        $scope.noData = true;
                    });

                    dataService.on('data', function ($event, data) {
                        currentMaximum = Number.MIN_VALUE;
                        currentMinimum = Number.MAX_VALUE;

                        const highlighted = $scope.config.highlightedHosts;

                        $scope.isSampling = data.length >= SAMPLING_LIMIT;

                        data.forEach(function (d) {
                            if (d.value !== null) {
                                if (d.value > currentMaximum) {
                                    currentMaximum = d.value;
                                }
                                if (d.value < currentMinimum) {
                                    currentMinimum = d.value;
                                }
                            }

                            const host = $scope.hosts[d.key];

                            if (host) {
                                host._hasNoData = d.value === null;
                                host._isDead = d.value === undefined;
                                host.value = d.value;

                                host._highlighted = highlighted.length
                                    ? highlighted.indexOf(d.key) !== -1
                                    : true;
                            }
                        });

                        updateColorRange(currentMinimum, currentMaximum);

                        if (data.length) {
                            $scope.hasData = true;
                        }

                        $log.debug('Heatmap data updated');
                        heatmap.hasDataValues(true);
                        heatmap.update(_.values($scope.hosts));

                        $scope.$broadcast('new hosts data', {});
                        $scope.loading = false;
                        $scope.$emit('loadComplete');
                    });

                    dataService.on('stats', function ($event, data) {
                        $scope.stats = data;
                    });

                    dataService.on('loading', function () {
                        $scope.loading = true;
                    });

                    dataService.on('reset', function () {
                        $log.debug('Reset signal received');
                        $scope.loading = false;
                        $scope.noData = false;
                        $scope.hosts = {};
                        heatmap.hasDataValues(false);
                        heatmap.update(_.values($scope.hosts));
                    });

                    dataService.init();

                    $scope.$on('$destroy', function () {
                        heatmapEventBindings.forEach(function (unbind) {
                            unbind();
                        });
                        dataService.destroy();
                    });
                },
            ],
        };
    },
]);
