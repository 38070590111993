import samlNotifyTemplateUrl from './samlnotify.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';

export default {
    templateUrl: samlNotifyTemplateUrl,
    controller: [
        '$scope',
        function ($scope) {
            $scope.email = ngRoute.params.email;
            ngRoute.history.replace(ngRoute.pathname);
        },
    ],
};
