import { ReactNode } from 'react';
import { ExtractRouteParams, RouteComponentProps } from 'react-router';
import { AngularInjector } from '../../AngularUtils';

export const googleTokenExchange = (
    routeProps: RouteComponentProps<ExtractRouteParams<string, string>>
): ReactNode => {
    const token = routeProps.match.params.token || '';
    const security = AngularInjector.instantiate('security');
    security.googleTokenValidation(token, '/home').then((result: { success: boolean }) => {
        if (!result.success) {
            routeProps.history.replace('/signin');
        }
    });
    return undefined;
};
