import templateUrl from './hostMetrics.tpl.html';

angular.module('signalview.heatmap').directive('hostMetrics', [
    function () {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
            },
            templateUrl,
            link: function ($scope) {
                const heatmap = $scope.heatmap;

                const heatmapEventBindings = [];
                heatmapEventBindings.push(heatmap.on('selection updated', updateAvailableMetrics));

                function updateAvailableMetrics() {
                    $scope.metrics = [];
                    const host = heatmap.selection();
                    if (!host) {
                        return;
                    }

                    const mode = heatmap.mode();
                    mode.idProperties.forEach(function (property) {
                        if (Object.keys(host).indexOf(property.id) === -1) {
                            return;
                        }
                        // Make a fake object for object-related-metrics
                        const obj = {
                            sf_id: 'FAKE',
                            sf_type: 'Property',
                            sf_key: [property.id],
                            sf_property: host[property.id],
                            title: property.displayName,
                        };
                        obj[property.id] = host[property.id];
                        $scope.metrics.push(obj);
                    });
                }
                updateAvailableMetrics();

                heatmapEventBindings.push(heatmap.on('mode updated', updateAvailableMetrics));

                $scope.$on('$destroy', function () {
                    heatmapEventBindings.forEach(function (unbind) {
                        unbind();
                    });
                });
            },
        };
    },
]);
