angular
    .module('signalflowv2', [
        'signalview.utils',
        'sfx.security',
        'signalflow-mocks',
        'signalview-utils.functions',
        'signalview-utils.signalflowv2',
    ])
    .constant(
        'v2ToPlotRollups',
        Object.freeze({
            rate: 'RATE_ROLLUP',
            sum: 'SUM_ROLLUP',
            average: 'AVERAGE_ROLLUP',
            max: 'MAX_ROLLUP',
            min: 'MIN_ROLLUP',
            delta: 'DELTA_ROLLUP',
            count: 'COUNT_ROLLUP',
            latest: 'LATEST_ROLLUP',
            lag: 'LAG_ROLLUP',
            None: null,
        })
    )
    .constant(
        'v2ToPlotExtrapolation',
        Object.freeze({
            last_value: 'LAST_VALUE_EXTRAPOLATION',
            zero: 'ZERO_EXTRAPOLATION',
            null: 'NULL_EXTRAPOLATION',
        })
    );
