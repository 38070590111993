import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AclDashboardGroupService from '../../../legacy/app/dashboardGroup/aclDashboardGroup/AclDashboardGroupService';
import PermissionsServiceFactory from '../../../legacy/common/data/apiv2/permissionsService';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';
import Select from '@splunk/react-ui/Select';

export default function AclMirrorDashboardGroupSelector({
    dashboardId,
    initialValue,
    onSelect,
    disabled,
}) {
    const aclDashboardGroupService = AclDashboardGroupService.useInstance();
    const permissionService = PermissionsServiceFactory.useInstance();
    const { principalsDataRepository } = useContext(AclFormContext);

    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        aclDashboardGroupService
            .getDashboardGroupsForDashboard(dashboardId)
            .then((dashboardGroups) => mapDashboardGroupsToOptions(dashboardGroups))
            .then((options) => {
                const effectiveOptions = extendWithInitialValue(options);
                setOptions(effectiveOptions);
                return effectiveOptions;
            })
            .then((options) => handleChange(initialValue, options))
            .then(() => setIsLoading(false));
    }, [dashboardId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // force re-render when initialValue change and selected value does not reflect that
        if (initialValue && value && initialValue !== value) {
            setValue(initialValue);
        }
    }, [initialValue, value]);

    return (
        <Select
            appearance="toggle"
            value={value}
            disabled={disabled || isLoading}
            onChange={(e, { value }) => handleChange(value, options)}
            placeholder={isLoading ? 'Loading...' : 'Select group...'}
            style={{
                minWidth: 200,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                marginLeft: 8,
            }}
            isLoadingOptions={isLoading}
            animateLoading={true}
        >
            {options.map((option, index) => (
                <Select.Option key={index} label={option.label} value={option.value} />
            ))}
        </Select>
    );

    function extendWithInitialValue(options) {
        const effectiveOptions = [...options];
        if (initialValue && !options.find((option) => initialValue === option.value)) {
            effectiveOptions.push({
                value: initialValue,
                label: `${initialValue} (not available)`,
                isValid: false,
            });
        }
        return effectiveOptions;
    }

    function mapDashboardGroupsToOptions(dashboardGroups) {
        return dashboardGroups.map((dashboardGroup) => ({
            value: dashboardGroup.id,
            label: dashboardGroup.name,
            isValid: true,
        }));
    }
    async function handleChange(dashboardGroupId, options) {
        if (dashboardGroupId) {
            setValue(dashboardGroupId);

            const dashboardGroup = await aclDashboardGroupService
                .get(dashboardGroupId)
                .catch((e) => {
                    if (e?.status === 403) {
                        return { id: dashboardGroupId };
                    }
                    throw e;
                });

            const dashboardGroupPermissions = await permissionService.fetchPermissions(
                dashboardGroupId
            );
            const acl = await principalsDataRepository.getSortedAcl(dashboardGroupPermissions?.acl);
            const isValid = options?.find((option) => option.value === dashboardGroupId)?.isValid;

            onSelect(dashboardGroup, acl, isValid);
        } else {
            onSelect(null, [], true);
        }
    }
}

AclMirrorDashboardGroupSelector.propTypes = {
    dashboardId: PropTypes.string,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    initialValue: PropTypes.string,
};

AclMirrorDashboardGroupSelector.defaultProps = {
    onSelect: () => {},
    disabled: false,
};
