import templateUrl from './metricFilters.tpl.html';

export default {
    templateUrl,
    bindings: {
        filters: '<',
        addFilter: '<',
        gaPrefix: '@',
        containerClass: '@',
    },
    controller: [function () {}],
};
