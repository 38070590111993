import sourceFilters from './sourceFilters';
import sourceEdit from './sourceEdit';
import sourceFilterItem from './sourceFilterItem';
import sourceFilterService from './sourceFilterService';
import editableSourceFilterItem from './editableSourceFilterItem';
import EditableSourceFilterController from './editableSourceFilterController';
import singleSelectFilter from './singleSelectFilter';

angular
    .module('sfx.filterControls', [
        'signalview.urlOverrides',
        'signalview.typeahead',
        'signalview-utils.signalflowv2',
    ])
    .component('sourceEdit', sourceEdit)
    .component('singleSelectFilter', singleSelectFilter)
    .controller('EditableSourceFilterController', EditableSourceFilterController)
    .directive('sourceFilterItem', sourceFilterItem)
    .service('sourceFilterService', sourceFilterService)
    .directive('editableSourceFilterItem', editableSourceFilterItem)
    .directive('sourceFilters', sourceFilters);
