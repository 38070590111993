import templateUrl from './googleAuthIntegration.tpl.html';

const googleAuthIntegration = {
    restrict: 'E',
    replace: true,
    scope: {
        org: '=',
        editable: '=',
    },
    templateUrl,
    controller: [
        '$scope',
        '$timeout',
        'googleAuth',
        'BUSINESS_PROCESS_INTEGRATIONS',
        'organizationService',
        'DOMAIN_INFO',
        function (
            $scope,
            $timeout,
            googleAuth,
            BUSINESS_PROCESS_INTEGRATIONS,
            organizationService,
            DOMAIN_INFO
        ) {
            $scope.DOMAIN_INFO = DOMAIN_INFO;
            $scope.initAddGoogleDomain = googleAuth.initAddGoogleDomain;
            $scope.integration = BUSINESS_PROCESS_INTEGRATIONS['googleauth'];
            $scope.org.googleAuthDomain = $scope.org.googleAuthDomain || [];
            $scope.domains = ($scope.org.googleAuthDomain || []).map(function (d) {
                return {
                    domain: d,
                };
            });
            $scope.deleteModalState = { isOpen: false };

            function handleDomainDelete() {
                domain.deleting = true;
                organizationService.removeGoogleAuthDomain(domain.domain).then(
                    () => {
                        $scope.domains.splice($scope.domains.indexOf(domain), 1);
                        $scope.org.googleAuthDomain.splice(
                            $scope.org.googleAuthDomain.indexOf(domain),
                            1
                        );
                    },
                    function () {
                        domain.deleting = false;
                    }
                );
            }

            $scope.remove = function (domain) {
                const domainName = domain.domain;

                $scope.deleteModalState.onCancel = function () {
                    $timeout(function () {
                        $scope.deleteModalState.isOpen = false;
                    }, 0);
                };

                $scope.deleteModalState.onDelete = function () {
                    $timeout(handleDomainDelete, 0);
                };

                $scope.deleteModalState.isOpen = true;
                $scope.deleteModalState.title = 'Remove ' + domainName;
                $scope.deleteModalState.description =
                    'You are about to remove  ' + domainName + '.';
            };
        },
    ],
};

angular.module('signalview.orgIntegrations').directive('googleAuthIntegration', [
    function () {
        return googleAuthIntegration;
    },
]);

// eslint-disable-next-line import/no-unused-modules
export { googleAuthIntegration };
