import templateUrl from './teamPage.tpl.html';
import { Dashboards as sitemap } from '../../../app/routing/Sitemaps';

export default {
    bindings: {
        teamId: '<',
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl,
    controller: [
        '$location',
        '$q',
        '$scope',
        'currentUser',
        'teamsApiService',
        'teamService',
        'organizationService',
        'featureEnabled',
        function (
            $location,
            $q,
            $scope,
            currentUser,
            teamsApiService,
            teamService,
            organizationService,
            featureEnabled
        ) {
            const ctrl = this;

            ctrl.$onInit = initialize;
            ctrl.$onDestroy = onDestroy;

            function initialize() {
                $scope.$on('description update', ($event, description) => {
                    ctrl.team.description = description;
                });
                ctrl.teamManagerMap = {};

                $q.all([
                    teamsApiService
                        .get(ctrl.teamId)
                        .then((team) => (ctrl.team = team))
                        .catch(() => {
                            $location.path('/404').replace();
                        }),
                    teamService.getUserTeams().then((teams) => (ctrl.userTeams = teams)),
                    teamsApiService
                        .getTeamSummary()
                        .then((response) => (ctrl.teams = response.results)),
                    teamService.getUserMemberships().then((memberships) => {
                        for (const membership of memberships) {
                            ctrl.teamManagerMap[membership.teamId] = membership.isTeamManager;
                        }
                    }),
                    organizationService
                        .getOrgSettings()
                        .then(
                            (prefs) =>
                                (ctrl.isRestrictTeamManagement = !!prefs.sf_restrictTeamManagement)
                        ),
                    currentUser.isAdmin().then((isAdmin) => {
                        ctrl.isAdmin = isAdmin;
                    }),
                ]).then(() => {
                    ctrl.isMember = ctrl.userTeams.map((team) => team.id).includes(ctrl.teamId);
                    ctrl.allowTeamModifications = allowTeamModifications();
                    ctrl.isTeamManager = ctrl.team ? ctrl.teamManagerMap[ctrl.teamId] : false;
                    ctrl.initialized = true;
                    setHNav();
                });

                currentUser.orgId().then((orgId) => {
                    ctrl.orgId = orgId;
                });
            }

            function onDestroy() {
                ctrl.setHierarchicalNav();
            }

            function allowTeamModifications() {
                // Team controls is not enabled or the org does not have write permissions
                // enabled
                if (!featureEnabled('writepermissions') || !ctrl.isRestrictTeamManagement) {
                    return true;
                }

                // team controls is enabled
                return ctrl.isAdmin || ctrl.isMember;
            }

            function setHNav() {
                const { name, id } = ctrl.team;
                ctrl.setCustomContent(sitemap.name, {
                    id: sitemap.IDs.teamName,
                    label: name,
                    path: `#/team/${id}`,
                });
                ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.teamName);
            }
        },
    ],
};
