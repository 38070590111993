'use strict';

/* jshint camelcase: false */
import templateUrl from './integration.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular.module('sfx.ui').directive('sfxIntegration', [
    '$q',
    '$log',
    'BUSINESS_PROCESS_INTEGRATIONS',
    'credentialTypeToKey',
    'credentialV2Service',
    'userAnalytics',
    '$timeout',
    'notificationService',
    '$location',
    'userV2Service',
    'hasCapability',
    function (
        $q,
        $log,
        BUSINESS_PROCESS_INTEGRATIONS,
        credentialTypeToKey,
        credentialV2Service,
        userAnalytics,
        $timeout,
        notificationService,
        $location,
        userV2Service,
        hasCapability
    ) {
        return {
            restrict: 'E',
            scope: {
                orgId: '=',
                credential: '=',
                editable: '=',
                confirmModal: '=', // confirmation screen on integration modal
                highlightText: '=?',
                onDelete: '=?',
                onFormUpdate: '=?',
                label: '@',
                onRowToggle: '=?', // when row toggle occurs with unsaved changes
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    const service = credentialTypeToKey.mapCredTypeToName($scope.credential.type);
                    const integration = BUSINESS_PROCESS_INTEGRATIONS[service];

                    $scope.errorHref = integration.url;
                    $scope.flags = {};
                    $scope.sharedCredentialState = {};
                },
            ],
            link: function ($scope) {
                const states = {
                    VALIDATING: {
                        state: 'VALIDATING',
                        class: 'large-waiting-spinner',
                        text: 'Validating...',
                    },
                    VALIDATED: { state: 'VALIDATED', class: 'icon-success', text: 'Validated!' },
                    ERRORVALIDATION: {
                        state: 'ERRORVALIDATION',
                        class: 'icon-fail',
                        text: 'Validation failed.',
                    },
                    SAVING: { state: 'SAVING', class: 'large-waiting-spinner', text: 'Saving...' },
                    SAVED: { state: 'SAVED', class: 'icon-success', text: 'Saved!' },
                    ERRORSAVE: { state: 'ERRORSAVE', class: 'icon-fail', text: 'Failed to save.' },
                    DELETING: {
                        state: 'DELETING',
                        class: 'large-waiting-spinner',
                        text: 'Deleting...',
                    },
                    DELETED: { state: 'DELETED', class: 'icon-success', text: 'Deleting!' },
                    ERRORDELETION: {
                        state: 'ERRORDELETION',
                        class: 'icon-fail',
                        text: 'Delete failed.',
                    },
                };

                const stateFadeOutTime = 2000;

                $scope.originalState = angular.copy($scope.credential);

                const obfuscated = '**************************************************';
                if ($scope.credential.id) {
                    $scope.obfuscatedText = obfuscated;
                }

                if (!$scope.credential.id || $scope.credential.isNewIntegration) {
                    $scope.rowExpanded = true;
                }

                if ($location.search().updated === $scope.credential.id) {
                    // Assumed to be for a new integration. If the integration being updated is not in the first page of the list,
                    // it might not show up expanded
                    $scope.rowExpanded = true;
                    $location.search('updated', null).replace();
                }

                $scope.deleteModalState = {
                    isOpen: false,
                };

                $scope.timeFormatter = (value) =>
                    notificationService.formatNotificationEventTimestamp(value, true);
                function setState(state, fadeOut) {
                    $scope.state = state;
                    if (fadeOut) {
                        resetState(fadeOut);
                    }
                    delete $scope.integrationMessage;
                }

                function resetState(fadeOut) {
                    if (fadeOut) {
                        $timeout(() => {
                            $scope.stateFadeOut = true;
                        }, stateFadeOutTime).then(() =>
                            $timeout(() => ($scope.stateFadeOut = $scope.state = null), 200)
                        );
                    } else {
                        $scope.state = null;
                    }
                }

                function setStateMessage(message, isError) {
                    $scope.integrationMessage = { message, isError };
                }

                // Keeps $scope.credential object reference intact
                // Helps in restoring credential object without breaking it's two-way binding
                function safeRestoreCredential() {
                    Object.keys($scope.credential).forEach((key) => {
                        delete $scope.credential[key];
                    });
                    angular.extend($scope.credential, angular.copy($scope.originalState));
                }

                hasCapability(Capability.DELETE_INTEGRATION).then(
                    (hasDeleteIntegrationCapability) => {
                        $scope.hasDeleteIntegrationCapability = hasDeleteIntegrationCapability;
                    }
                );

                $scope.delete = function () {
                    const id = $scope.credential.id;
                    const name = $scope.credential.name;

                    if ($scope.credential.isNewIntegration) {
                        deleteCredential(id);
                        return;
                    }

                    $scope.deleteModalState.onCancel = function () {
                        $scope.deleteModalState.isOpen = false;
                        $scope.$apply();
                    };
                    $scope.deleteModalState.onDelete = function () {
                        $scope.$apply(function () {
                            deleteCredential(id);
                        });
                    };

                    $scope.deleteModalState.title = 'Delete Integration';
                    $scope.deleteModalState.description =
                        'You are about to permanently delete the ' +
                        name +
                        ' integration. Deleting this integration will cause any data collection, error detection, or charts which rely on this integration to fail.';
                    $scope.deleteModalState.isOpen = true;
                };

                function deleteCredential(id) {
                    if (id) {
                        setState(states.DELETING);
                        credentialV2Service.delete(id).then(
                            function () {
                                const eventCategory = $scope.credential.type + ' integration';
                                userAnalytics.event(eventCategory, 'deleted');
                                setState(states.DELETED, true);
                                $scope.credential.deleted = true;
                                $scope.$emit('credentials update', $scope.credential);
                                if ($scope.onDelete) $scope.onDelete($scope.credential, $scope.$id);
                            },
                            function (result) {
                                setState(states.ERRORDELETION, true);
                                setStateMessage(result.data.message, true);
                            }
                        );
                    } else {
                        if ($scope.onDelete) $scope.onDelete($scope.credential, $scope.$id);
                    }
                }

                $scope.cancel = function () {
                    if (!$scope.credential.id) {
                        if ($scope.onDelete) {
                            $scope.onDelete($scope.credential, $scope.$id);
                            return;
                        }
                    } else if ($scope.credential.isNewIntegration) {
                        deleteCredential($scope.credential.id);
                        return;
                    }
                    safeRestoreCredential();
                    resetState();
                    $scope.integrationForm.$setPristine(true);
                    if ($scope.credential.id) {
                        $scope.obfuscatedText = obfuscated;
                    }
                    $scope.$broadcast('reset');
                };

                $scope.save = function (saveAndEnable) {
                    const credential = $scope.credential;
                    $scope.$broadcast('prepare credential for save');
                    credential.enabled = $scope.flags.preValidate ? false : credential.enabled;

                    const isNew = !credential.id;

                    const modelToSave = angular.copy(credential);
                    modelToSave.enabled = saveAndEnable ? true : modelToSave.enabled;

                    if (credential.id) modelToSave.id = credential.id;

                    const updatingCredentials = $scope.sharedCredentialState.updateCredentials;

                    setState(updatingCredentials ? states.VALIDATING : states.SAVING);

                    let saveFunction = credentialV2Service.update;
                    if (isNew) {
                        saveFunction = credentialV2Service.create;
                    }

                    saveFunction(modelToSave)
                        .then(function (result) {
                            angular.extend($scope.credential, result);
                            // Keep a separate copy for reset
                            $scope.originalState = angular.copy($scope.credential);
                            $scope.$broadcast('reset');
                            $scope.integrationForm.$setPristine(true);
                            $scope.obfuscatedText = obfuscated;

                            if (isNew || $scope.credential.isNewIntegration) {
                                const eventCategory = credential.type + ' integration';
                                userAnalytics.event(eventCategory, 'created');
                            }

                            if ($scope.credential.isNewIntegration) {
                                delete $scope.credential.isNewIntegration;
                            }

                            delete $scope.sharedCredentialState.updateCredentials;

                            $scope.$emit('credentials update', $scope.credential);
                            $scope.$broadcast('credentials saved');
                            setState(updatingCredentials ? states.VALIDATED : states.SAVED, true);
                        })
                        .catch(function (e) {
                            $log.error(e);
                            setState(
                                updatingCredentials ? states.ERRORVALIDATION : states.ERRORSAVE,
                                true
                            );
                            setStateMessage(e.data.message, true);
                        });
                };

                $scope.validate = function () {
                    setState(states.VALIDATING);
                    credentialV2Service.validate($scope.credential.id).then(
                        function () {
                            setState(states.VALIDATED, true);
                        },
                        function (result) {
                            setState(states.ERRORVALIDATION, true);
                            setStateMessage(result.data.message, true);
                        }
                    );
                };

                $scope.enable = function () {
                    const modelToUpdate = angular.copy($scope.credential);
                    const updatingCredentials = $scope.sharedCredentialState.updateCredentials;
                    modelToUpdate.enabled = true;
                    resetState();
                    setState(updatingCredentials ? states.VALIDATING : states.SAVING);
                    credentialV2Service
                        .update(modelToUpdate)
                        .then(function () {
                            $scope.credential.enabled = $scope.originalState.enabled = true;
                            setState(updatingCredentials ? states.VALIDATED : states.SAVED, true);
                            $scope.$emit('credentials update', $scope.credential);
                            $scope.$broadcast('credentials enabled');
                        })
                        .catch(function (e) {
                            $log.error(e);
                            setState(
                                updatingCredentials ? states.ERRORVALIDATION : states.ERRORSAVE,
                                true
                            );
                            setStateMessage(
                                'Unable to enable the credential - please try again.',
                                true
                            );
                        });
                };

                $scope.disable = function () {
                    const modelToUpdate = angular.copy($scope.credential);
                    modelToUpdate.enabled = false;
                    const updatingCredentials = $scope.sharedCredentialState.updateCredentials;
                    resetState();
                    setState(updatingCredentials ? states.VALIDATING : states.SAVING);
                    credentialV2Service
                        .update(modelToUpdate)
                        .then(function () {
                            setState(updatingCredentials ? states.VALIDATED : states.SAVED, true);
                            $scope.credential.enabled = $scope.originalState.enabled = false;
                            $scope.$emit('credentials update', $scope.credential);
                            $scope.$broadcast('credentials disabled');
                        })
                        .catch(function (e) {
                            $log.error(e);
                            setState(
                                updatingCredentials ? states.ERRORVALIDATION : states.ERRORSAVE,
                                true
                            );
                            setStateMessage(
                                'Unable to disable the credential - please try again.',
                                true
                            );
                        });
                };

                $scope.rowToggle = (expand) => {
                    if (
                        (!expand && $scope.integrationForm.$dirty) ||
                        !$scope.credential.id ||
                        $scope.credential.isNewIntegration
                    ) {
                        getConfirmationPromise().then(
                            () => {
                                $scope.cancel();
                                $scope.rowExpanded = false;
                                // Force the scope to apply in react
                                $scope.$applyAsync();
                            },
                            () => {
                                $log.debug('promise rejected');
                            }
                        );
                    } else {
                        $scope.rowExpanded = expand;
                    }
                };

                /*
                 * Either a callback for onRowToggle confirmation or a confirmation modal should be passed to the component
                 * Otherwise the row collapse will occur
                 */
                function getConfirmationPromise() {
                    let confirmationPromise = null;
                    if ($scope.confirmModal) {
                        confirmationPromise = $scope.confirmModal({
                            isDestructive: false,
                            callToActionText: 'Close',
                            description: `You have unsaved changes in this integration!
                          <br/>
                          These changes will be lost if you close without saving.`,
                        });
                    }
                    if ($scope.onRowToggle) {
                        confirmationPromise = $scope.onRowToggle();
                    }
                    return confirmationPromise || $q.when();
                }

                function resolveUserId(userId) {
                    const displayUserPromise = userV2Service
                        .getCachedOrgMemberForUserId(userId)
                        .then(function (orgMember) {
                            return userV2Service.getUserBadgeDisplayObj(orgMember);
                        });

                    displayUserPromise.finally(() => {
                        $scope.$applyAsync();
                    });

                    return displayUserPromise;
                }

                $scope.$watch('credential.lastUpdatedBy', () => {
                    $scope.integrationCreatedBy = null;
                    $scope.integrationLastUpdatedBy = null;
                    // Covers new to saved credential flow
                    if ($scope.credential.creator) {
                        resolveUserId($scope.credential.creator).then(
                            (user) => ($scope.integrationCreatedBy = user)
                        );
                    }
                    if ($scope.credential.lastUpdatedBy) {
                        resolveUserId($scope.credential.lastUpdatedBy).then(
                            (user) => ($scope.integrationLastUpdatedBy = user)
                        );
                    }
                });

                if ($scope.onFormUpdate) {
                    // Allows parent to keep track unsaved child forms. Needed because $setPristine does not bubble up to parent.
                    $scope.$watch('integrationForm.$dirty', ($dirty) => {
                        $scope.onFormUpdate(
                            $scope.$id,
                            $dirty || !$scope.credential.id || $scope.credential.isNewIntegration
                        );
                    });
                }

                $scope.$on('save integration draft', () => {
                    $scope.saveDraft = true;
                });

                $scope.$on('$destroy', () => {
                    if (
                        $scope.credential.isNewIntegration &&
                        !$scope.credential.deleted &&
                        !$scope.saveDraft
                    ) {
                        $scope.delete();
                    }
                });
            },
        };
    },
]);
