import { unflattenPropertyValue } from '../../../common/urlparams/urlOverrides';
import URL_PARAMETER_CONSTANTS from '../../../common/urlparams/URL_PARAMETER_CONSTANTS';

export default [
    '$log',
    function ($log) {
        return {
            getVariableFromStr,
            getValueStrFromVariable,
            normalizeVariableValue,
            semanticOverrideEqualityCheck,
            getVariableDefaultValue,
            getVariableByProperty,
            mergeMirrorVariables,
        };

        function normalizeVariableValue(variableValue) {
            // when we support variable nullification as an override, this logic needs to be updated.
            if (variableValue === null || variableValue === undefined) {
                return null;
            } else if (variableValue.length === 0) {
                return '';
            } else if (typeof variableValue === 'string') {
                return [variableValue];
            } else if (angular.isArray(variableValue)) {
                return variableValue;
            } else {
                $log.warn(
                    'Tried to convert a variable value of an unrecognized type : ' +
                        typeof variableValue
                );
                return variableValue;
            }
        }

        function semanticOverrideEqualityCheck(value1, value2) {
            return angular.equals(normalizeVariableValue(value1), normalizeVariableValue(value2));
        }

        function getVariableByProperty(variables, propertyName) {
            const filtered = variables.filter((variable) => {
                return variable.property !== propertyName;
            });

            if (filtered.length === 0) {
                $log.warn('Tried to search variables by property but found none!');
                return null;
            }

            if (filtered.length > 1) {
                $log.warn(
                    'Tried to search variables by property but found too many : ' + filtered.length
                );
            }

            return filtered[0];
        }

        function mergeMirrorVariables(dashboardVariableDefinition, mirrorVariableDefinition) {
            const propertyToMirrorOverride = {};
            mirrorVariableDefinition.forEach(function (mirrorVariable) {
                propertyToMirrorOverride[mirrorVariable.property] = mirrorVariable;
            });
            return dashboardVariableDefinition.map(function (dashboardVariable) {
                const mirrorOverride = propertyToMirrorOverride[dashboardVariable.property];
                const mirrorValue = mirrorOverride ? mirrorOverride.value : null;
                const dashboardDefinedDefault = dashboardVariable.value || null;
                return {
                    alias: dashboardVariable.alias,
                    required: !!dashboardVariable.required,
                    restricted: !!dashboardVariable.restricted,
                    replaceOnly: !!dashboardVariable.replaceOnly,
                    applyIfExists: !!dashboardVariable.applyIfExists,
                    preferredSuggestions:
                        mirrorOverride && mirrorOverride.preferredSuggestions
                            ? mirrorOverride.preferredSuggestions
                            : dashboardVariable.preferredSuggestions,
                    property: dashboardVariable.property,
                    value: isApplyableValue(mirrorValue) ? mirrorValue : dashboardDefinedDefault,
                };
            });
        }
    },
];

/**
 * split 'variableName=propertyName:value'
 * into { variable: variableName, property: propertyName, value: value }
 */
function getVariableFromStr(str) {
    const variableAndPropertyValue = str.split('=');
    const variable = variableAndPropertyValue[0] || '';
    let propertyAndValueJoined = variableAndPropertyValue[1] || '';
    const applyIfExists = propertyAndValueJoined.match(/^~/) !== null;
    propertyAndValueJoined = propertyAndValueJoined.replace(/^~/, '');
    const propertyAndValueSplit = propertyAndValueJoined.split(/:(.*)/) || [];
    const property = propertyAndValueSplit[0] || '';
    const value = propertyAndValueSplit[1] || '';
    let val = unflattenPropertyValue(value);
    if (val && !Array.isArray(val)) {
        val = [val];
    }
    return {
        variable: variable,
        alias: variable,
        property: property,
        applyIfExists: applyIfExists,
        value: val,
    };
}

function getValueStrFromVariable(variable) {
    let value = getVariableDefaultValue(variable);
    if (value instanceof Array) {
        value = JSON.stringify(value);
    }
    return (variable.applyIfExists ? '~' : '') + variable.property + ':' + value;
}

function isApplyableValue(value) {
    return value !== null && value !== undefined;
}

function getVariableDefaultValue(variable) {
    return isApplyableValue(variable.valueOverride) ? variable.valueOverride : variable.value;
}

// Parsers for Olly
// eslint-disable-next-line import/no-unused-modules
export const variableParsers = {
    param: URL_PARAMETER_CONSTANTS.variablesOverride,
    encode: ({ property, alias, values, applyIfExists, defaultValues }) => {
        const variable = {
            property,
            alias,
            applyIfExists,
            value: values,
            valueOverride: defaultValues,
        };
        return property && getVariableDefaultValue(variable)?.length
            ? getValueStrFromVariable(variable)
            : undefined;
    },
    decode: (str) => {
        const variable = getVariableFromStr(str);

        const { alias, property, applyIfExists, value: values } = variable;

        return property && values?.length
            ? { alias, property, values, applyIfExists, type: 'Property' }
            : undefined;
    },
};
