import dashboardGroupInfo from './dashboardGroupInfo';
import dashboardGroupPermissions from './dashboardGroupPermissions';
import dashboardGroupSettingsModal from './dashboardGroupSettingsModal';
import aclDashboardGroupPermissions from './aclDashboardGroup/aclDashboardGroupPermissions';
import createAclDashboardGroupModal from './aclDashboardGroup/createAclDashboardGroupModal';
import CreateAclDashboardGroup from '../../../app/dashboardGroup/aclDashboardGroup/CreateAclDashboardGroup';
import EditAclDashboardGroupPermissions from '../../../app/dashboardGroup/aclDashboardGroup/EditAclDashboardGroupPermissions';
import { react2angular } from 'react2angular';

angular
    .module('signalview.page', [
        'restangular',
        'signalboost',
        'signalview.dashboard',
        'signalview.imageService',
        'signalview.timePicker',
        'sfx.ui',
        'signalview.nameDescription',
        'sfx.api.v2',
    ])
    .component('dashboardGroupInfo', dashboardGroupInfo)
    .component('dashboardGroupPermissions', dashboardGroupPermissions)
    .component('aclDashboardGroupPermissions', aclDashboardGroupPermissions)
    .component('createAclDashboardGroupModal', createAclDashboardGroupModal)
    .component('createAclDashboardGroup', react2angular(CreateAclDashboardGroup))
    .component('editAclDashboardGroupPermissions', react2angular(EditAclDashboardGroupPermissions))
    .service('dashboardGroupSettingsModal', dashboardGroupSettingsModal);
