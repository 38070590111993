import React, { useEffect, useState } from 'react';
import { CrossLinkReact } from '../../../crossLinks/crossLinkReact';
import ChartService from '../../../../legacy/app/charting/chartdisplay/tableChart/chartService';
import CellFormatter from './cellFormatter';
import { useCrosslinkHref } from '../../../../common/crosslinks/useCrosslinkHref';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ValueFormatter from '@splunk/olly-common/ValueUtils/ValueFormatter';
import { MAXIMUM_PRECISION_DEFAULT } from './tableGridChart';

const StyledDiv = styled.div`
    position: absolute;
    display: flex;
    margin-top: 4px;
`;

const CrossLinkDiv = styled.div`
    margin-left: auto;
    order: 2;
    margin-top: 2px;
`;

export function getPlotColumnDisplayValue(chartService, maximumPrecision, useKmg2, column, value) {
    const unitType = chartService.safeLookup(column.plot, 'configuration.unitType') || '';
    const prefix = chartService.safeLookup(column.plot, 'configuration.prefix') || '';
    const suffix = chartService.safeLookup(column.plot, 'configuration.suffix') || '';
    return (
        prefix +
        ' ' +
        (unitType
            ? ValueFormatter.formatScalingUnit(value, unitType, maximumPrecision)
            : ValueFormatter.formatValue(value, maximumPrecision, useKmg2)) +
        ' ' +
        suffix
    );
}

export default function TableCellRenderer({ column, height, value, rowData, cellMetaData }) {
    const [hovered, setHovered] = useState(null);

    const [dropDownOpen, setDropDownOpen] = useState(false);

    const popUpAlignment = column.left > 0 ? 'right' : 'left';

    function toggleDropDown(isOpen) {
        setDropDownOpen(isOpen);
        setTimeout(() => cellMetaData.onDataLinkOpen(isOpen), 100);
    }

    useEffect(() => {
        setTimeout(() => {
            if (!dropDownOpen) {
                setHovered(false);
            }
        }, 100);
    }, [dropDownOpen]);

    const chartService = ChartService.useInstance();

    let displayValue;
    if (column.isPlot) {
        displayValue = getPlotColumnDisplayValue(
            chartService,
            cellMetaData.maximumPrecision || MAXIMUM_PRECISION_DEFAULT,
            cellMetaData.useKmg2,
            column,
            value
        );
    } else {
        displayValue = value;
    }

    const crosslinkActionItems = [
        {
            title: 'Filter on property',
            action: () => filterDataTable(false),
            label: 'Filter',
        },
        {
            title: 'Exclude on property',
            action: () => filterDataTable(true),
            label: 'Exclude',
        },
    ];

    const crosslinkDimensions = [
        {
            propertyName: column.name,
            propertyValue: value,
            actionItems: crosslinkActionItems,
        },
    ];

    const crossLinkContext = rowData.crossLinkContext;

    function onMouseEnter() {
        setHovered(true);
    }

    function onMouseLeave() {
        if (!dropDownOpen) {
            setHovered(false);
        }
    }

    const filterDataTable = function (exclude) {
        let sourceOverrides = chartService.getSourceFilterOverrideList() || [];

        sourceOverrides = sourceOverrides.map((override) => {
            override.propertyValue = override.value;
            delete override.value;
            return override;
        });

        sourceOverrides.push({
            NOT: exclude,
            property: column.name,
            propertyValue: value,
        });

        chartService.setSourceFilterOverrideList(sourceOverrides);
    };

    const {
        href,
        title: linkTitle,
        defaultNavigatorCode,
    } = useCrosslinkHref(crosslinkDimensions, crossLinkContext);

    return (
        <StyledDiv
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
            className="react-grid-Cell"
            style={{ height: height, left: column.left, width: column.width }}
        >
            <div className="react-grid-Cell__value">
                <CellFormatter
                    value={displayValue}
                    href={href}
                    title={linkTitle}
                    defaultCrossLink={defaultNavigatorCode}
                />
            </div>
            {hovered && column.hasCrossLink && value && (
                <CrossLinkDiv>
                    <CrossLinkReact
                        popupAlign={popUpAlignment}
                        incomingDimensions={crosslinkDimensions}
                        context={crossLinkContext}
                        onToggle={(open) => toggleDropDown(open)}
                        dropdownAppendToBody={true}
                        scrollAutoClose={true}
                        showConfigureLinkItem={!cellMetaData.inEditor}
                    />
                </CrossLinkDiv>
            )}
        </StyledDiv>
    );
}

TableCellRenderer.propTypes = {
    column: PropTypes.object,
    height: PropTypes.number,
    value: PropTypes.any,
    rowData: PropTypes.object,
    cellMetaData: PropTypes.object,
};
