import PropTypes from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

const className = new BEMHelper('plots-list');

export default function PlotsList({ plots }) {
    function PlotItem(plot, index) {
        const plotKeyLabel = `${plot.key}: `;
        const plotValueLabel = ` (value: ${plot.value !== '-' ? plot.value : 'N/A'})`;
        const nameLabel = plot.name;
        const colorClass = plot.color ? 'custom' : plot.severity;

        return (
            <li key={index} {...className('item', colorClass)}>
                {plot.color && <CustomPlotColorSample color={plot.color} />}
                {!!plot.key && <span className="plot-label">{plotKeyLabel}</span>}
                <span className="plot-name">{nameLabel}</span>
                <span className="plot-value">{plotValueLabel}</span>
            </li>
        );
    }

    return <ul {...className()}>{plots.map((plot, index) => PlotItem(plot, index))}</ul>;
}

PlotsList.propTypes = {
    plots: PropTypes.arrayOf(Object),
};

const CustomPlotColorSample = ({ color }) => (
    <span className="custom-color" style={{ color }}>
        &#9644;
    </span>
);

CustomPlotColorSample.propTypes = {
    color: PropTypes.string,
};
