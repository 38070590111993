export const getAlertAggregation = [
    function () {
        return function (eventService, query) {
            return eventService.aggregation(query).then(function (data) {
                const result = {};
                if (data.aggregations) {
                    data.aggregations.forEach(function (agg) {
                        let r;
                        if (agg.count < 1000) {
                            r = {
                                circle: agg.count,
                                number: agg.count,
                            };
                        } else if (agg.count === 1000) {
                            r = {
                                circle: '1K',
                                number: '1000',
                            };
                        } else {
                            r = {
                                circle: '1K+',
                                number: '1K+',
                            };
                        }
                        result[agg.name] = r;
                    });
                }
                return {
                    result: result,
                    severities: eventService.severities,
                };
            });
        };
    },
];
