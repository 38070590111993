import templateUrl from './analyticsSelector.tpl.html';
import TYPE_TO_TEMPLATE_URL from '../analytics/block/blockTypeToTemplateMap.js';

angular.module('sfx.analyticsSelector').directive('sfxAnalyticsSelector', [
    'analyticsSuggester',
    'blockService',
    'userAnalytics',
    function (analyticsSuggester, blockService, userAnalytics) {
        return {
            restrict: 'E',
            scope: {
                pipeline: '=pipeline',
                disabled: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                'PRODUCT_NAME',
                function ($scope, PRODUCT_NAME) {
                    const selector = ($scope.selector = {});
                    $scope.TYPE_TO_TEMPLATE_URL = TYPE_TO_TEMPLATE_URL;
                    $scope.PRODUCT_NAME = PRODUCT_NAME;
                    selector.suggestions = null;
                    selector.highlighted = null;
                    selector.query = null;

                    selector.onSelect = function (item) {
                        let displayName = item.displayName;

                        userAnalytics.event('analytics-block-select', displayName);

                        const displayNameParts = displayName.split(':');
                        displayName = displayNameParts[0];
                        let mode = displayNameParts[1];

                        const analyticsFunction = blockService.getByDisplayName(displayName);

                        if (analyticsFunction.isStandalone()) {
                            mode = 'Aggregation';
                        }

                        if (mode !== undefined) {
                            $scope.tentativeAnalyticsSuggest = '';
                            let manipulation;
                            if (mode === 'Aggregation') {
                                manipulation = analyticsFunction.asAggregation();
                                manipulation.showMe = true;
                                $scope.pipeline.push(manipulation);
                            } else if (mode === 'Transformation') {
                                manipulation = analyticsFunction.asTransformation(1, 'h');
                                manipulation.showMe = true;
                                $scope.pipeline.push(manipulation);
                            }

                            selector.query = null;
                        } else {
                            selector.query = displayName + ':';
                        }
                    };

                    function updateSuggestions() {
                        let suggestions = analyticsSuggester(selector.query || '', $scope.pipeline);
                        if ($scope.disableAggregation) {
                            suggestions = suggestions.filter(function (f) {
                                return !f.isCrossTimeSeries;
                            });
                        }

                        selector.suggestions = suggestions;

                        if (selector.query) {
                            selector.highlighted = selector.suggestions[0];
                        } else {
                            selector.highlighted = null;
                        }
                    }

                    $scope.$watch('selector.visible', function (isVisible) {
                        if (isVisible) {
                            updateSuggestions();
                        } else {
                            selector.suggestions = null;
                            selector.highlighted = null;
                        }
                    });

                    $scope.$watch('selector.query', function (query) {
                        if (query === null) return;
                        updateSuggestions();
                    });
                },
            ],
        };
    },
]);
