import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './updateSubscription.tpl.html';

angular
    .module('signalview.orgNotifications')

    /*
     * Service is started by globalNavService and runs at the periodicity specified
     * by the caller,
     */

    .service('orgNotificationService', [
        '$q',
        '$log',
        '$interval',
        'sfxModal',
        'appNotificationService',
        'orgSubscriptionService',
        'moment',
        'currentUser',
        'roleService',
        'organizationService',
        'migratedSignalboost',
        'PRODUCT_NAME',
        function (
            $q,
            $log,
            $interval,
            sfxModal,
            appNotificationService,
            orgSubscriptionService,
            moment,
            currentUser,
            roleService,
            organizationService,
            migratedSignalboost,
            PRODUCT_NAME
        ) {
            const ONE_WEEK = 7;
            const ONE_DAY_MS = 86400000;
            const ERR_THRESHOLD_DAYS = 2;
            const SCOPE_NAME = 'orgnotification';
            const SCOPE_NAME_TRIAL = 'trialnotification';

            let intPromise = null;
            let modalPromise = null;
            let hostsChecked = false;

            return {
                start,
                stop,
                checkNumHosts,
            };

            function start(periodicityMs) {
                $log.debug(
                    `Starting org notification service with a polling interval of ${
                        periodicityMs / 1000 / 60
                    } minutes.`
                );
                stop();
                notify();
                intPromise = $interval(notify, periodicityMs);
            }

            function stop() {
                appNotificationService.removeScope(SCOPE_NAME);

                $interval.cancel(intPromise);
                clearModal();
                hostsChecked = false;
            }

            function clearModal() {
                if (modalPromise) {
                    const currentPromise = modalPromise;
                    currentPromise.opened.then(() => currentPromise.dismiss());
                }
                modalPromise = null;
                return $q.when(null);
            }

            function onModalClose(cleanupPromise, org, orgExpiredDays) {
                if (modalPromise === cleanupPromise) {
                    clearModal();
                    if (org.accountType === 'TRIAL') {
                        showNonModalNotificationForTrials(org, true, orgExpiredDays);
                    }
                }
            }

            function modalNotify(org) {
                if (modalPromise) {
                    return modalPromise;
                }

                const orgExpiredDays = Math.ceil(Math.abs(getRelativeValidity(org)));

                const currentModalPromise = sfxModal.open({
                    templateUrl,
                    controller: 'OrgNotificationController',
                    size: 'md',
                    resolve: {
                        org: () => org,
                        account: orgSubscriptionService.get(org).getAccount(),
                        admins: function () {
                            return currentUser
                                .isAdmin()
                                .then(function (isAdmin) {
                                    return isAdmin ? [] : getAdminEmails(org);
                                })
                                .catch(function (e) {
                                    $log.error('Cannot find admins for modal notification.', e);
                                    return [];
                                });
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                });

                modalPromise = currentModalPromise;
                modalPromise.result.finally(() =>
                    onModalClose(currentModalPromise, org, orgExpiredDays)
                );

                return modalPromise.result;
            }

            function getAdminEmails(org) {
                return roleService
                    .orgAdminRole(org.id)
                    .then(function (role) {
                        return organizationService.searchMembersWithArbitraryQuery(
                            'sf_organizationID:' +
                                sanitizeTerm(org.id) +
                                ' AND sf_memberOf:' +
                                sanitizeTerm(role.sf_id),
                            0,
                            100
                        );
                    })
                    .then(function (orgMembers) {
                        return orgMembers.results.map(function (orgMember) {
                            return orgMember.email;
                        });
                    });
            }

            function getContextMessage(org) {
                const ctxt = { link: {}, action: {} };
                return currentUser.isAdmin().then(
                    function (isAdmin) {
                        if (isAdmin) {
                            ctxt.link.href = 'https://calendly.com/sfx-customer-success';
                            ctxt.link.text = 'Schedule an appointment with sales.';
                            ctxt.link.target = '_blank';
                            if (org.accountType !== 'TRIAL') {
                                ctxt.action.href =
                                    'mailto:sales@signalfx.com?subject=Subscription changes for ' +
                                    org.organizationName +
                                    ', org ID ' +
                                    org.id;
                                ctxt.action.text = 'Contact Us';
                            } else {
                                ctxt.action.href = '#/organization/' + org.id + '/billing/upgrade';
                                ctxt.action.text = 'Purchase';
                            }
                            return ctxt;
                        } else {
                            return getAdminEmails(org).then(function (admins) {
                                const subject =
                                    org.accountType === 'TRIAL'
                                        ? `Request to upgrade ${PRODUCT_NAME} Trial to a paid subscription`
                                        : `Request to renew ${PRODUCT_NAME} subscription`;
                                ctxt.link.href =
                                    'mailto:' + admins.join(',') + '?subject=' + subject;
                                ctxt.link.text = 'Please contact your admin for more information.';
                                return ctxt;
                            });
                        }
                    },
                    function (e) {
                        return $q.reject(e);
                    }
                );
            }

            function getMessage(expired, daysBetween) {
                const today = daysBetween >= 0 && daysBetween < 1;
                let msg = 'Your subscription';
                const msgPostfix = ` To continue using ${PRODUCT_NAME} after that, you need a subscription.`;
                if (today) {
                    return msg + ' expires today.' + msgPostfix;
                }
                msg += expired ? ' expired' : ' expires in';
                daysBetween = Math.ceil(daysBetween);
                msg += ' ' + daysBetween;
                const days = daysBetween > 1 ? ' days' : ' day';
                msg += days + (expired ? ' ago.' : '.');
                return msg + msgPostfix;
            }

            function getTrialMessage(expired, daysBetween) {
                const today = daysBetween >= 0 && daysBetween < 1;
                let msg = 'Your free trial with full Enterprise features';
                if (today) {
                    return msg + ' ends today.';
                }
                msg += expired ? ' ended' : ' will end in';
                daysBetween = Math.ceil(daysBetween);
                msg += ' ' + daysBetween;
                const days = daysBetween > 1 ? ' days' : ' day';
                msg += days + (expired ? ' ago.' : '.');
                return msg;
            }

            function showNonModalNotificationForTrials(org, expired, daysToExpiry) {
                currentUser.isAdmin().then(
                    function (isAdmin) {
                        appNotificationService.removeScope(SCOPE_NAME_TRIAL);
                        appNotificationService.add({
                            message: getTrialMessage(expired, daysToExpiry),
                            messageClass: 'normal',
                            messageScope: SCOPE_NAME_TRIAL,
                            userDismissable: false,
                            dismissAfterMs: 0,
                            global: true,
                            priority: 'high', // Takes precedence over all banners
                            callToAction: {
                                text: 'Buy Now',
                                btnClassName: 'btn-quaternary buy-now-btn',
                                trackClickAction: 'buy-now',
                                tooltipEnable: !isAdmin,
                                tooltipText: 'You must be an administrator to subscribe',
                                disable: !isAdmin,
                                // The else case points to an empty fn because the button does not show up
                                // if the callback is not of function type. In this case, we would like the
                                // button to show up, but greyed out.
                                callback: isAdmin
                                    ? orgSubscriptionService.get(org).estimateSubscription
                                    : () => {},
                            },
                        });
                    },
                    function (e) {
                        $log.error(
                            'Could not determine user role for non modal trial notification.',
                            e
                        );
                    }
                );
            }

            function getRelativeValidity(org) {
                const validUntil = org.accountValidUntil;
                const buffer = orgSubscriptionService.getExpirationBuffer(org);
                const now = moment().subtract(buffer, 'hours').utc().valueOf();
                return (validUntil - now) / ONE_DAY_MS; // moment does not have a days between util
            }

            function nonModalNotify(org) {
                clearModal();
                const validUntil = org.accountValidUntil;
                if (isNaN(validUntil) || !validUntil) return $q.when(null);
                const isTrial = org.accountType === 'TRIAL';
                const relativeValidity = getRelativeValidity(org);
                const expired = relativeValidity < 0;
                const absoluteRelativeDays = Math.abs(relativeValidity);

                if (!isTrial) {
                    // Subscription
                    if (!expired && (absoluteRelativeDays > ONE_WEEK || org.accountRenews))
                        return $q.when(null);
                    return orgSubscriptionService
                        .get(org)
                        .getAccount()
                        .then(function (account) {
                            if (
                                account &&
                                ((account.upcomingSubscription &&
                                    account.upcomingSubscription.length) ||
                                    account.autoRenew)
                            ) {
                                return $q.when(null);
                            }
                            return showNonModalNotification(org, expired, absoluteRelativeDays);
                        })
                        .catch(function () {
                            $log.error('Failed fetching org account.');
                            return $q.when(null);
                        });
                } else {
                    // Trial
                    return showNonModalNotificationForTrials(org, expired, absoluteRelativeDays);
                }
            }

            function showNonModalNotification(org, expired, daysBetween) {
                const msgClass = expired || daysBetween <= ERR_THRESHOLD_DAYS ? 'error' : 'warn';
                const msg = getMessage(expired, daysBetween);
                return getContextMessage(org).then(
                    function (ctxt) {
                        appNotificationService.removeScope(SCOPE_NAME);
                        appNotificationService.add({
                            message: msg,
                            messageClass: msgClass,
                            messageScope: SCOPE_NAME,
                            userDismissable: true,
                            link: ctxt.link,
                            callToAction: ctxt.action,
                        });
                    },
                    function (e) {
                        $log.error(
                            'Cannot find org context for current user for org notification.',
                            e
                        );
                    }
                );
            }

            function notifyOrgAccountStatus(org) {
                switch (org.accountStatus) {
                    case 'SUSPENDED':
                    case 'DECOMMISSIONED':
                        return modalNotify(org);
                    case 'ACTIVE':
                    case 'GRACE_PERIOD':
                        return nonModalNotify(org);
                    default:
                        break;
                }
                return $q.when(null);
            }

            function notify() {
                let currOrgId = null;
                return organizationService
                    .get()
                    .then((org) => {
                        currOrgId = org.id;
                        return notifyOrgAccountStatus(org);
                    })
                    .catch((e) => {
                        $log.error(
                            'Cannot find organization for user / org notification.',
                            currOrgId,
                            e
                        );
                    });
            }

            function checkNumHosts() {
                if (hostsChecked) return;
                hostsChecked = true;
                currentUser.orgId().then(function () {
                    migratedSignalboost.dimension
                        .get('', {
                            query: '_exists_:host',
                            getCount: true,
                        })
                        .then(function (result) {
                            if (result.count === 1) {
                                appNotificationService.removeScope('HostFtux');

                                appNotificationService.add({
                                    iconClass: 'icon-info small',
                                    message:
                                        'You only have one host installed. To see percentiles and other aggregations, add more hosts.',
                                    messageScope: 'HostFtux',
                                    messageClass: 'info',
                                    userDismissable: true,
                                    priority: 'high',
                                    global: true,
                                    callToAction: {
                                        text: 'Add Hosts',
                                        href: '#/integrations?selectedKeyValue=collectd:sfxcollectd',
                                        dismissOnClick: true,
                                    },
                                });
                            }
                        })
                        .catch(function (e) {
                            $log.error('Failed fetching host count.', e);
                        });
                });
            }
        },
    ]);
