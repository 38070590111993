angular.module('signalview.awsMetaData', []).factory('awsMetricInformation', [
    function () {
        //a service that attempts to catalog all known AWS Cloudwatch metric names
        //ideally this would be dynamically generated.
        const knownAWSMetrics = {
            CPUUtilization: true,
            DiskReadBytes: true,
            DiskReadOps: true,
            DiskWriteBytes: true,
            DiskWriteOps: true,
            NetworkIn: true,
            NetworkOut: true,
            ProvisionedReadCapacityUnits: true,
            ProvisionedWriteCapacityUnits: true,
            HealthyHostCount: true,
            UnHealthyHostCount: true,
            VolumeIdleTime: true,
            VolumeQueueLength: true,
            VolumeReadOps: true,
            VolumeWriteOps: true,
            VolumeTotalWriteTime: true,
            VolumeWriteBytes: true,
            Latency: true,
            RequestCount: true,
            CPUCreditBalance: true,
            CPUCreditUsage: true,
            HTTPCode_Backend_2XX: true,
            VolumeReadBytes: true,
            VolumeTotalReadTime: true,
            HTTPCode_Backend_4XX: true,
            FreeableMemory: true,
            HealthCheckPercentageHealthy: true,
            HealthCheckStatus: true,
            SwapUsage: true,
            HTTPCode_Backend_3XX: true,
            CurrConnections: true,
            CurrItems: true,
            Evictions: true,
            HTTPCode_ELB_5XX: true,
            NetworkBytesIn: true,
            NetworkBytesOut: true,
            NewConnections: true,
            Reclaimed: true,
            SuccessfulRequestLatency: true,
            EstimatedCharges: true,
            '4xxErrorRate': true,
            '5xxErrorRate': true,
            BytesDownloaded: true,
            BytesUploaded: true,
            Requests: true,
            BytesUsedForCache: true,
            CacheHits: true,
            CacheMisses: true,
            ReplicationLag: true,
            SetTypeCmds: true,
            StringBasedCmds: true,
            TotalErrorRate: true,
            BinLogDiskUsage: true,
            BytesReadIntoMemcached: true,
            BytesUsedForCacheItems: true,
            BytesUsedForHash: true,
            BytesWrittenOutFromMemcached: true,
            CasBadval: true,
            CasHits: true,
            CasMisses: true,
            CmdConfigGet: true,
            CmdConfigSet: true,
            CmdFlush: true,
            CmdGet: true,
            CmdSet: true,
            CmdTouch: true,
            CurrConfig: true,
            DatabaseConnections: true,
            DecrHits: true,
            DecrMisses: true,
            DeleteHits: true,
            DeleteMisses: true,
            DiskQueueDepth: true,
            EvictedUnfetched: true,
            ExpiredUnfetched: true,
            FreeStorageSpace: true,
            GetHits: true,
            GetMisses: true,
            IncrHits: true,
            IncrMisses: true,
            MemoryUtilization: true,
            NetworkReceiveThroughput: true,
            NetworkTransmitThroughput: true,
            NewItems: true,
            ReadIOPS: true,
            ReadLatency: true,
            ReadThroughput: true,
            ReturnedItemCount: true,
            ThrottledRequests: true,
            TouchHits: true,
            TouchMisses: true,
            UnusedMemory: true,
            WriteIOPS: true,
            WriteLatency: true,
            WriteThroughput: true,
            ConsumedWriteCapacityUnits: true,
            GetTypeCmds: true,
            HTTPCode_Backend_5XX: true,
            ApproximateNumberOfMessagesDelayed: true,
            ApproximateNumberOfMessagesNotVisible: true,
            ApproximateNumberOfMessagesVisible: true,
            NumberOfEmptyReceives: true,
            NumberOfMessagesDeleted: true,
            NumberOfMessagesReceived: true,
            NumberOfMessagesSent: true,
            SurgeQueueLength: true,
            cpu_idle: true,
            cpu_nice: true,
            cpu_steal: true,
            cpu_system: true,
            cpu_user: true,
            cpu_waitio: true,
            load_1: true,
            load_15: true,
            load_5: true,
            memory_buffers: true,
            memory_cached: true,
            memory_free: true,
            memory_swap: true,
            memory_total: true,
            memory_used: true,
            procs: true,
            ConsumedReadCapacityUnits: true,
            ReadThrottleEvents: true,
            SentMessageSize: true,
            GroupDesiredCapacity: true,
            GroupInServiceInstances: true,
            GroupMaxSize: true,
            GroupMinSize: true,
            GroupPendingInstances: true,
            GroupTerminatingInstances: true,
            GroupTotalInstances: true,
            NumberOfMessagesPublished: true,
            NumberOfNotificationsDelivered: true,
            NumberOfNotificationsFailed: true,
            PublishSize: true,
            UserErrors: true,
        };
        return {
            isAWSMetric: function (metric) {
                return !!knownAWSMetrics[metric];
            },
        };
    },
]);
