angular.module('signalview.chart').factory('Chart', [
    'Plot',
    'chartVisualizationService',
    'programTextUtils',
    'uiModelToVisualizationOptionsService',
    '$rootScope',
    function (Plot, cvs, programTextUtils, uiModelToVisualizationOptionsService, $rootScope) {
        let availableModes;
        let availableTypes;
        $rootScope.$on('migrated services initialized', function () {
            availableModes = cvs.getChartModes();
            availableTypes = cvs.getChartTypes();
        });

        function Chart(config) {
            this.plots = [];
            if (!config) this.data = angular.copy(Chart.DEFAULT_MODEL);
            else this.config(config);
        }

        Chart.create = function (config) {
            return new Chart(config);
        };

        Chart.DEFAULT_MODEL = {
            sf_chart: '',
            sf_cacheProgram: false,
            sf_uiModel: {
                allPlots: [],
                currentUniqueKey: 1,
                chartType: 'line',
                chartMode: 'graph',
                chartconfig: {
                    range: -15 * 60000,
                    yAxisConfigurations: [
                        {
                            name: 'yAxis0',
                            id: 'yAxis0',
                            max: null,
                            min: null,
                            plotlines: {
                                high: null,
                                low: null,
                            },
                        },
                    ],
                    useKMG2: false,
                },
                revisionNumber: 1,
            },
        };

        Chart.DEFAULT_AXIS_OPTIONS = {
            min: null,
            max: null,
            name: '',
            plotlines: {
                high: null,
                low: null,
            },
        };

        Chart.prototype.asV2 = function () {
            return {
                name: this.data.sf_chart,
                description: '',
                options: uiModelToVisualizationOptionsService(this.data.sf_uiModel).serialize(),
                programText: programTextUtils.getV2ProgramText(
                    this.data.sf_uiModel,
                    true,
                    false,
                    [],
                    false,
                    false,
                    true
                ),
            };
        };

        Chart.prototype.mode = function (mode) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartMode;
            }

            const isValid = availableModes.some(function (availableMode) {
                return availableMode.value === mode;
            });

            if (!isValid) {
                throw new Error('Invalid chart mode: ' + mode);
            }

            this.data.sf_uiModel.chartMode = mode;
            return this;
        };

        Chart.prototype.type = function (type) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartType;
            }

            const isValid = availableTypes.some(function (availableType) {
                return availableType.value === type;
            });

            if (!isValid) {
                throw new Error('Invalid chart type: ' + type);
            }

            this.data.sf_uiModel.chartType = type;
            return this;
        };

        Chart.prototype.maxDecimalPlaces = function (maxDecimalPlaces) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.maxDecimalPlaces;
            }

            this.data.sf_uiModel.chartconfig.maxDecimalPlaces = maxDecimalPlaces;
            return this;
        };

        Chart.prototype.updateInterval = function (updateInterval) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.updateInterval;
            }

            this.data.sf_uiModel.chartconfig.updateInterval = updateInterval;
            return this;
        };

        Chart.prototype.hideMissingValues = function (hideMissingValues) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.hideMissingValues;
            }

            this.data.sf_uiModel.chartconfig.hideMissingValues = hideMissingValues;
            return this;
        };

        Chart.prototype.disableThrottle = function (disableThrottle) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.disableThrottle;
            }

            this.data.sf_uiModel.chartconfig.disableThrottle = disableThrottle;
            return this;
        };

        Chart.prototype.useKMG2 = function (useKMG2) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.useKMG2;
            }

            this.data.sf_uiModel.chartconfig.useKMG2 = useKMG2;
            return this;
        };

        Chart.prototype.sort = function (sortPreference) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.sortPreference;
            }

            this.data.sf_uiModel.chartconfig.sortPreference = sortPreference;
            return this;
        };

        Chart.prototype.forcedResolution = function (resolution) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.forcedResolution;
            }

            this.data.sf_uiModel.chartconfig.forcedResolution = resolution;
            return this;
        };

        Chart.prototype.stacked = function (isStacked) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.stackedChart;
            }

            this.data.sf_uiModel.chartconfig.stackedChart = isStacked;
            return this;
        };

        // Apply a general template filter to all plots in the chart except the ones
        // explicitly excluded
        Chart.prototype.filter = function (key, value) {
            this.plots.forEach(function (plot) {
                if (plot.type() !== 'plot' || plot.excludeTemplateFilters()) return;

                plot.propertyFilter(key, value);
            });

            return this;
        };

        Chart.prototype.colorByMetric = function (isColoredByMetric) {
            if (arguments.length === 0) {
                return this.data.sf_uiModel.chartconfig.colorByMetric;
            }

            this.data.sf_uiModel.chartconfig.colorByMetric = isColoredByMetric;
            return this;
        };

        Chart.prototype.yAxis = function (index, options) {
            const axisConfigs = this.data.sf_uiModel.chartconfig.yAxisConfigurations;

            if (arguments.length === 0) {
                return;
            } else if (arguments.length === 1) {
                return axisConfigs[index];
            } else {
                if (!options) {
                    axisConfigs.splice(index, 1);
                } else {
                    axisConfigs[index] = angular.extend(
                        { id: 'yAxis' + index },
                        Chart.DEFAULT_AXIS_OPTIONS,
                        options
                    );
                }

                return this;
            }
        };

        Chart.prototype.id = function (id) {
            if (arguments.length === 0) {
                return this.data.sf_id;
            }

            this.data.sf_id = id;
            return this;
        };

        Chart.prototype.name = function (name) {
            if (arguments.length === 0) {
                return this.data.sf_chart;
            }

            this.data.sf_chart = name;
            return this;
        };

        Chart.prototype.description = function (description) {
            if (arguments.length === 0) {
                return this.data.sf_description;
            }

            this.data.sf_description = description;
            return this;
        };

        Chart.prototype.range = function (start, end) {
            if (arguments.length === 0) {
                return [start, end];
            }

            if (start !== undefined) {
                this.data.sf_uiModel.chartconfig.range = start;
            }

            if (end !== undefined) {
                this.data.sf_uiModel.chartconfig.rangeEnd = end;
            }

            return this;
        };

        Chart.prototype.plot = function (config) {
            if (angular.isNumber(config)) {
                return this.plots[config];
            }

            const plot = Plot.create(config);
            plot.uniqueKey(this.data.sf_uiModel.currentUniqueKey);
            this.plots.push(plot);
            this.data.sf_uiModel.allPlots.push(plot.config());
            this.data.sf_uiModel.currentUniqueKey++;
            return plot;
        };

        Chart.prototype.updateProgramText = function () {
            programTextUtils.refreshProgramText(this.data);
            return this;
        };

        Chart.prototype.config = function (config) {
            const self = this;

            if (arguments.length === 0) {
                try {
                    this.updateProgramText();
                } catch (e) {
                    console.error('Chart error', e.message);
                }
                return this.data;
            } else {
                this.data = config;
                config.sf_uiModel.allPlots.forEach(function (plotConfig) {
                    const plot = Plot.create(plotConfig);
                    self.plots.push(plot);
                });
            }
        };

        return Chart;
    },
]);
