/**
 * Theme service.
 *
 * The theme service controls the current theme (light/dark). It also supports
 * an 'auto' theme that respects the user's operating system preferred color
 * scheme.
 *
 * It exposes three properties:
 *   - svc.theme: the current theme (auto/light/dark)
 *   - svc.current: the current color scheme (light/dark)
 *   - svc.dark: a boolean indicating whether the current scheme is dark
 *
 * The default theme is 'light'.
 */
angular.module('signalview.themeService', []).service('themeService', [
    '$rootScope',
    '$log',
    'currentUser',
    'auth',
    function ($rootScope, $log, currentUser, auth) {
        const THEMES = {
            AUTO_THEME: 'auto',
            DARK_THEME: 'dark',
            LIGHT_THEME: 'light',
        };

        const svc = this;

        let theme = THEMES.LIGHT_THEME;
        let current = null;

        function setTheme(newTheme) {
            /*
             * Consumers of this event and events that follow should be able to read the newly
             * selected theme, so set svc.dark first.
             */
            theme = newTheme;
            if (theme === THEMES.DARK_THEME || theme === THEMES.LIGHT_THEME) {
                current = theme;
            }
            svc.dark = current === THEMES.DARK_THEME;
            $rootScope.$emit('theme update', svc.dark);
            $log.info('Theme is now ' + theme + '/' + current + '.');
        }

        function setDefault() {
            setTheme(THEMES.LIGHT_THEME);
        }

        setDefault();

        $rootScope.$on('$locationChangeSuccess', function () {
            // Reconsider the auto theme on route changes.
            if (theme === THEMES.AUTO_THEME) {
                $log.info('Route changed; re-evaluating auto theme...');
                setTheme(theme);
            }
        });

        $rootScope.$on('migrated services initialized', function () {
            auth.onDeAuth(function () {
                if (!theme) {
                    setDefault();
                    promise = null;
                }
            });
        });

        svc.getTheme = function () {
            return theme;
        };

        svc.getColorScheme = function () {
            return current || 'dark';
        };

        svc.setTheme = function (val) {
            setTheme(val);
        };

        svc.getThemes = function () {
            return THEMES;
        };

        return svc;
    },
]);
