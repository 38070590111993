// calls a provided callback whenever smokey the detector alert state has changed

export default [
    'alertDataService',
    '$timeout',
    '$interval',
    'documentStateService',
    function (alertDataService, $timeout, $interval, documentStateService) {
        function SmokeyStateProvider(callback) {
            this.callback = callback;
            this.alertState = null;
            this.filters = null;
            this.replaceOnlyFilters = null;
            this.dataGeneration = 0;
            this.stateRefreshDebouncer = null;
            this.updateRelatedDetectorsAlertState =
                this.updateRelatedDetectorsAlertState.bind(this);
            this.updateInterval = $interval(this.updateRelatedDetectorsAlertState, 60000);

            documentStateService.registerVisibilityCallback(this.updateRelatedDetectorsAlertState);
        }

        SmokeyStateProvider.prototype.onSignalFlowChanged = function (signalflow) {
            if (signalflow !== this.signalflow) {
                this.signalflow = signalflow;
                this.updateRelatedDetectorsAlertState();
            }
        };

        SmokeyStateProvider.prototype.updateRelatedDetectorsAlertState = function () {
            $timeout.cancel(this.stateRefreshDebouncer);
            this.stateRefreshDebouncer = $timeout(this.emitAlertState.bind(this), 100);
        };

        SmokeyStateProvider.prototype.emitAlertState = function () {
            const programText = this.signalflow;
            this.dataGeneration++;
            const expectedDataGeneration = this.dataGeneration;
            if (programText && documentStateService.isDocumentVisible()) {
                return alertDataService
                    .getAlertStateBatched(programText, this.filters, this.replaceOnlyFilters)
                    .then((detectorAlertsBySeverity) => {
                        if (expectedDataGeneration !== this.dataGeneration) {
                            //stale fetch
                            return;
                        }
                        let alertState = null;
                        if (detectorAlertsBySeverity.detectors.length) {
                            alertState = alertDataService.getAlertStateFromAlertMap(
                                detectorAlertsBySeverity.alertsBySeverity
                            );
                        }
                        this.callback({
                            alertState,
                            activeAlerts: detectorAlertsBySeverity.alertsBySeverity,
                        });
                    });
            } else {
                this.callback({
                    alertState: null,
                    activeAlerts: {},
                });
            }
        };

        SmokeyStateProvider.prototype.setFilters = function (filters) {
            this.filters = filters;
            this.updateRelatedDetectorsAlertState();
        };

        SmokeyStateProvider.prototype.setReplaceOnlyFilters = function (filters) {
            this.replaceOnlyFilters = filters;
            this.updateRelatedDetectorsAlertState();
        };

        SmokeyStateProvider.prototype.destroy = function () {
            $interval.cancel(this.updateInterval);
            documentStateService.unregisterVisibilityCallback(
                this.updateRelatedDetectorsAlertState
            );
        };

        return SmokeyStateProvider;
    },
];
