'use strict';
import templateUrl from './dashboardGroupSettingsModal.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export default [
    'sfxModal',
    'permissionsChecker',
    'featureEnabled',
    '$q',
    'hasCapability',
    function (sfxModal, permissionsChecker, featureEnabled, $q, hasCapability) {
        // modal accepts both v1 and v2 dashboard groups.
        function showModal(tabToSelect, dashboardGroup, currentDashboard) {
            return $q
                .all({
                    hasWritePermissions:
                        permissionsChecker.hasDashboardGroupWriteAccess(dashboardGroup),
                    hasUpdateDashboardGroupCapability: hasCapability(
                        Capability.UPDATE_DASHBOARD_GROUP
                    ),
                })
                .then(function ({ hasWritePermissions, hasUpdateDashboardGroupCapability }) {
                    return sfxModal.open({
                        templateUrl,
                        controller: [
                            '$scope',
                            function ($scope) {
                                $scope.dashboardGroup = dashboardGroup;
                                $scope.currentDashboard = currentDashboard;
                                $scope.tabToSelect = tabToSelect;
                                $scope.writePermissionsEnabled = featureEnabled('writepermissions');
                                $scope.editable =
                                    hasWritePermissions && hasUpdateDashboardGroupCapability;
                                $scope.missingUpdateDashboardGroupCapability =
                                    !hasUpdateDashboardGroupCapability;

                                $scope.$on('dismiss modal', function () {
                                    $scope.$dismiss();
                                });

                                $scope.$on('close modal', ($event, passDown) => {
                                    $scope.$close(passDown);
                                });

                                $scope.accessControlEnabled = featureEnabled('accessControl');
                            },
                        ],
                        windowClass: 'object-settings-modal',
                        backdrop: 'static',
                        keyboard: false,
                    }).result;
                });
        }

        return {
            info: showModal.bind(null, 'Group info'),
            permissions: showModal.bind(null, 'Permissions'),
        };
    },
];
