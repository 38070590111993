angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    'blockService',
    function (chartTemplates, Chart, plotUtils, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Total Disk Space')
                .type('area')
                .stacked(true)
                .colorByMetric(true)
                .yAxis(0, { label: 'Gigabytes', min: 0 });

            const usedPlot = chart
                .plot()
                .metric('df_complex.used')
                .visible(false)
                .propertyFilter('plugin', 'df')
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asAggregation());

            const usedPlotLetter = plotUtils.getLetterFromUniqueKey(usedPlot.uniqueKey());
            chart
                .plot()
                .macro(usedPlotLetter + ' / 1000000000')
                .name('used disk space')
                .color('#b04600');

            // just a clone the used space and make some modifications
            const freePlot = usedPlot.cloneTo(chart).metric('df_complex.free');

            const freePlotLetter = plotUtils.getLetterFromUniqueKey(freePlot.uniqueKey());
            chart
                .plot()
                .macro(freePlotLetter + ' / 1000000000')
                .name('free disk space')
                .color('#007c1d');

            return chart;
        }

        chartTemplates.register('collectdHostsTotalDiskSpace', createChart);
    },
]);
