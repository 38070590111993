import React from 'react';

const ACL_FORM_DEFAULT_CONTEXT = {
    userId: null,
    isAdmin: false,
    orgId: null,
    disabled: false,
    syncInProgress: false,
    principalsDataRepository: null,
    restrictTeamAccess: false,
};

export const AclFormContext = React.createContext(ACL_FORM_DEFAULT_CONTEXT);
