import templateUrl from './infopanelSidebarWrapper.tpl.html';

export default {
    templateUrl,
    require: {
        sidebarCtrl: '^infoSidebar',
    },
    controller: [
        '$element',
        '$q',
        '$timeout',
        'URL_PARAMETER_CONSTANTS',
        'urlOverridesService',
        'infoSidebarUtil',
        '$location',
        'routeParameterService',
        'dashboardUtil',
        'dashboardV2Util',
        'dashboardGroupService',
        'dashboardVariablesService',
        function (
            $element,
            $q,
            $timeout,
            URL_PARAMETER_CONSTANTS,
            urlOverridesService,
            infoSidebarUtil,
            $location,
            routeParameterService,
            dashboardUtil,
            dashboardV2Util,
            dashboardGroupService,
            dashboardVariablesService
        ) {
            let routeWatcher;
            const PANEL_MESSAGE_TYPE = 'PANEL_MESSAGE';
            const noContextFoundMessage = 'No Context available';
            const FLOATING_HEADER_CLASS = 'floating';

            const $ctrl = this;

            $ctrl.setupPanel = setupPanel;
            $ctrl.onPanelContentLink = onPanelContentLink;
            $ctrl.isDashboardPanel = isDashboardPanel;
            $ctrl.$onInit = $onInit;
            $ctrl.$onDestroy = $onDestroy;

            const scrollToTop = function () {
                const element = angular.element('.info-panel-header')[0];
                if (element) {
                    element.scrollIntoView({ block: 'end', behavior: 'smooth' });
                }
            };

            function $onInit() {
                $ctrl.panelMessage = null;
                $ctrl.panelConfig = null;

                if (routeWatcher) {
                    routeWatcher();
                }

                selectPanel();
                bindEvents();

                $timeout(() => {
                    const panelHeader = angular.element('.info-panel-header', $element).last();
                    const scrollParent = panelHeader.scrollParent();
                    let floatingTimeout;

                    scrollParent.scroll(() => {
                        let scrollTop = scrollParent.scrollTop();

                        if (scrollTop === 0) {
                            panelHeader.removeClass(FLOATING_HEADER_CLASS);
                        } else {
                            if (!floatingTimeout && !panelHeader.hasClass(FLOATING_HEADER_CLASS)) {
                                floatingTimeout = $timeout(() => {
                                    floatingTimeout = null;
                                    scrollTop = scrollParent.scrollTop();
                                    if (scrollTop === 0) {
                                        // Don't float the header, user scrolled back to the top already
                                        return;
                                    }
                                    panelHeader.addClass(FLOATING_HEADER_CLASS);
                                }, 250);
                            }
                        }
                    });
                }, 0);
            }

            function bindEvents() {
                routeWatcher = routeParameterService.registerRouteWatchGroup(
                    [
                        URL_PARAMETER_CONSTANTS.infoSidebarInfoTabRefType,
                        URL_PARAMETER_CONSTANTS.infoSidebarDashboardId,
                        URL_PARAMETER_CONSTANTS.infoSidebarDashboardGroupId,
                        URL_PARAMETER_CONSTANTS.infoSidebarDashboardConfigId,
                        URL_PARAMETER_CONSTANTS.infoSidebarSources,
                    ],
                    selectPanel
                );
            }

            function $onDestroy() {
                if (routeWatcher) {
                    routeWatcher();
                }
            }

            function selectPanel() {
                $ctrl.panelConfig = null;
                $ctrl.availablePanels = infoSidebarUtil.getInfoPanelsForSidebarURLParams();

                if (!$ctrl.availablePanels.length) {
                    infoSidebarUtil.forceSidebarDefaultTabSelection();
                    return;
                }

                const refType = urlOverridesService.getSearchParam(
                    URL_PARAMETER_CONSTANTS.infoSidebarInfoTabRefType
                );
                const referencedPanel =
                    $ctrl.availablePanels.find((panel) => panel.value === refType) ||
                    $ctrl.availablePanels[0];

                setupPanel(referencedPanel);
            }

            function setupPanel(panel) {
                scrollToTop();
                return getPanelConfig(panel)
                    .then((panelConfig) => ($ctrl.panelConfig = panelConfig))
                    .then(setupDataLink)
                    .catch(() => {
                        $ctrl.panelConfig = {
                            value: PANEL_MESSAGE_TYPE,
                            message: noContextFoundMessage,
                        };
                    });
            }

            function setupDataLink({ panel, objectName, infoSidebarFilters }) {
                if (!panel || !objectName || !infoSidebarFilters) {
                    return;
                }

                const dimensions = [
                    {
                        propertyName: panel.panelNameKey,
                        propertyValue: objectName,
                    },
                ];

                const context = infoSidebarFilters.reduce(
                    (context, { property, propertyValue }) => {
                        if (property !== panel.panelNameKey) {
                            context[property] = propertyValue;
                        }
                        return context;
                    },
                    {}
                );

                $ctrl.crossLink = { dimensions, context };
            }

            /*
             * A panel can either show a current dashboard or a non-dashboard,
             * in the case of a non-dashboard (kubernetes page),
             * only use the predefined info sidebar source filters
             */
            function getPanelConfig(panel) {
                // Sources and Filters carried to the sidebars are on a case-by-case basis because of lack of use-cases.

                const overrideContexts = infoSidebarUtil.getOverrideContexts();
                const isDashboard = isDashboardPanel(panel);

                // Case 1: The only working case right now is kubernetes Navigator:
                // It ONLY uses sidebar specific source filters and does not include any variables.
                // To support this behaviour, chart display specifically listens to sidebar only source when on a sidebar
                // and extract its overrides from there. All other components (<kube-details> and <kube-table>) straight up work
                // only on filters and do-NOT have a concept of variables.
                if (!isDashboard) {
                    const infoSidebarFilters = urlOverridesService.getInfoSidebarSources() || [];
                    overrideContexts.variables = infoSidebarFilters;
                    const { propertyValue } =
                        infoSidebarFilters.find((f) => f.property === panel.panelNameKey) || {};

                    let objectName;
                    if (propertyValue && propertyValue.length) {
                        objectName = angular.isArray(propertyValue)
                            ? propertyValue.join(',')
                            : propertyValue;
                    }

                    return $q.when({
                        panel,
                        infoSidebarFilters,
                        objectType: panel.objectType,
                        objectName,
                        ...overrideContexts,
                    });
                }

                // Case 2: Future Use-Case (NOT TESTED): Dashboards in sidebar.
                // These pick up variables and filters from the URLs (i.e. the main page), merge them together,
                // and leave them be to be figured out by the underlying dashboard. This strategy became a little too
                // restrictive for kubernetes navigator in CASE 1, as the sidebar needed to act on independent filters.
                // A good strategy is still needed to be figured out for dashboards in sidebar, when they have any actual
                // use-case in future.
                if (isDashboard) {
                    return getDashboardPanelConfig(panel, overrideContexts);
                }
            }

            function onPanelContentLink() {
                const panel = $ctrl.panelConfig.panel;
                if (!panel) {
                    return;
                }

                const configSources = $ctrl.panelConfig.sources || [];
                const configVariables = $ctrl.panelConfig.variables || [];

                urlOverridesService.clearAll();

                if (isDashboardPanel(panel)) {
                    const { dashboard, group, configId } = $ctrl.panelConfig.data;
                    const dashboardVariables = dashboard.filters.variables || [];
                    const filtersWithoutVariables = configSources.filter(
                        (filter) =>
                            filter.NOT ||
                            !dashboardVariables.some(({ property }) => property === filter.property)
                    );

                    $location.path(`${panel.baseLink}/${dashboard.id}`);
                    urlOverridesService.setSearchParam('groupId', group.groupId);
                    urlOverridesService.setSearchParam('configId', configId);
                    dashboardVariablesService.setVariablesOverride(dashboardVariables);
                    urlOverridesService.setSourceFilterOverrideList(filtersWithoutVariables);
                } else {
                    $location.path(panel.baseLink);
                    dashboardVariablesService.setVariablesOverride(configVariables);
                }

                if ($ctrl.sidebarCtrl.time !== undefined) {
                    const { startTime, endTime } = $ctrl.sidebarCtrl.time;
                    const isRelative = !Number.isInteger(startTime) && startTime.startsWith('-');
                    urlOverridesService.setGlobalTimePicker(startTime, endTime, isRelative);
                }

                $ctrl.sidebarCtrl.stopTracking();
            }

            function isDashboardPanel(panel) {
                return panel === infoSidebarUtil.INFO_PANELS.dashboard;
            }

            function getDashboardPanelConfig(panel, overrideContexts) {
                const { dashboardId, groupId, configId } = infoSidebarUtil.getDashboardParams();

                return dashboardV2Util.getAndProcessHierarchy(dashboardId, groupId).then((data) => {
                    if (data.group) {
                        data.group = dashboardGroupService.convertToV2(data.group);
                        data.configId =
                            configId ||
                            dashboardUtil.getConfigForDashboard(
                                data.group.dashboardConfigs,
                                dashboardId
                            )?.configId;
                    }

                    data.dashboard.filters =
                        dashboardVariablesService.mergeUrlValuesToFilterDefinitions(
                            data.dashboard.filters,
                            {
                                variables: overrideContexts.variables.concat(
                                    overrideContexts.sources.filter((f) => !f.NOT)
                                ),
                            }
                        );

                    return {
                        panel,
                        data,
                        panelName: data.dashboard.name,
                        ...overrideContexts,
                    };
                });
            }
        },
    ],
};
