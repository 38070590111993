import { AngularInjector } from '../AngularUtils';

CrosslinkService.useInstance = () => AngularInjector.useInjectedClass(CrosslinkService);

CrosslinkService.$inject = ['crossLinkDataService', 'crossLinkUtils'];

export default function CrosslinkService(crossLinkDataService, crossLinkUtils) {
    this.getDataService = () => crossLinkDataService;
    this.getUtils = () => crossLinkUtils;
}
