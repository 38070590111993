angular
    .module('signalview.timezone', [])

    .service('timeZoneService', [
        'currentUser',
        '$rootScope',
        '$filter',
        'moment',
        '$log',
        'UTC_TIMEZONE',
        'TIMEPICKER_SHORT_DATE_FORMAT',
        'TIMEPICKER_SHORT_TIME_FORMAT',
        function (
            currentUser,
            $rootScope,
            $filter,
            moment,
            $log,
            UTC_TIMEZONE,
            TIMEPICKER_SHORT_DATE_FORMAT,
            TIMEPICKER_SHORT_TIME_FORMAT
        ) {
            const MINUTE_IN_MS = 60 * 1000;
            const HOURS_IN_A_DAY = 24;
            const MINUTES_IN_A_HOUR = 60;
            const UNSUPPORTED_TIMEZONES = [
                'Canada/East-Saskatchewan',
                'US/Pacific-New',
                'EST',
                'GMT‌-0',
                'GMT‌+0',
                'HST',
                'MST',
                'ROC',
            ];
            let timezone;

            function getTimeZoneFromPrefs() {
                currentUser.preferences().then(function (prefs) {
                    const userPrefTimeZone = prefs.sf_timePreference;
                    if (userPrefTimeZone) {
                        if (
                            !moment.tz.zone(userPrefTimeZone) ||
                            UNSUPPORTED_TIMEZONES.includes(userPrefTimeZone)
                        ) {
                            $log.error('An uninterpretable timezone was retrieved: ' + timezone);
                        } else {
                            timezone = userPrefTimeZone;
                        }
                    }
                });
            }

            $rootScope.$on('migrated services initialized', getTimeZoneFromPrefs);

            $rootScope.$on('current user changed', getTimeZoneFromPrefs);

            // extracts only date from first argument and only time from second
            // and joins as a TIMEPICKER_SHORT_FORMAT string
            function timeAndDateToDatetimeShortString(date, time) {
                return [
                    moment(date).format(TIMEPICKER_SHORT_DATE_FORMAT),
                    moment(time).format(TIMEPICKER_SHORT_TIME_FORMAT),
                ].join(' ');
            }

            function getTimezone() {
                return timezone;
            }

            function getMoment(timestamp, customTimezone) {
                let dateTime = moment();
                if (timestamp) {
                    if (typeof timestamp === 'string') {
                        dateTime = stringToMoment(timestamp);
                    } else {
                        dateTime = moment(timestamp);
                    }
                }

                if (moment.tz.zone(customTimezone)) {
                    return dateTime.tz(customTimezone);
                } else if (moment.tz.zone(timezone)) {
                    return dateTime.tz(timezone);
                }
                return dateTime;
            }

            // Remove redundant elements between the two timestamps. Returns object
            // with fields for formatted start and end timestamps.
            function getCondensedTimeRange(start, end) {
                const startMoment = getMoment(start);
                const endMoment = getMoment(end);

                const startTimestamp = startMoment.format('ddd DD MMM YYYY HH:mm:ss');
                const endTimestamp = endMoment.format('ddd DD MMM YYYY HH:mm:ss');
                if (startTimestamp === endTimestamp) {
                    return { start: startTimestamp };
                }

                // Spans the same day, e.g.,
                // Wed 08 Feb 2017 21:00:00 to 22:00:00
                if (startMoment.format('ddd DD MMM YYYY') === endMoment.format('ddd DD MMM YYYY')) {
                    return { start: startTimestamp, end: endMoment.format('HH:mm:ss') };
                }
                // Spans the same year, e.g.,
                // Wed Feb 08 2017 21:00:00 to Mon Mar 06 05:00:00
                if (startMoment.format('YYYY') === endMoment.format('YYYY')) {
                    return { start: startTimestamp, end: endMoment.format('ddd DD MMM HH:mm:ss') };
                }
                // Spans different years, e.g.,
                // Fri 30 Dec 2016 21:00:00 to Thu 05 Jan 2017 05:00:00
                return { start: startTimestamp, end: endTimestamp };
            }

            function setTimezone(tz) {
                timezone = tz;
            }

            function getTimezoneHourOffset(ms) {
                const zone = moment.tz.zone(timezone);
                let offset;
                if (zone) {
                    offset = zone.offset(ms || Date.now()) / 60;
                } else {
                    const now = new Date();
                    offset = now.getTimezoneOffset() / 60;
                }
                return offset;
            }

            function getUserTimezoneISOString() {
                let offset = getTimezoneHourOffset();

                if (offset === 0) {
                    return 'UTC+00:00';
                } else {
                    const sign = offset > 0 ? '-' : '+';
                    offset = Math.abs(offset);
                    const fullHour = parseInt(offset);
                    const partialHour = parseInt((offset - fullHour) * 60);
                    return `UTC${sign}${('' + fullHour).padStart(2, '0')}:${(
                        '' + partialHour
                    ).padStart(2, '0')}`;
                }
            }

            function getUtcOffset(ms) {
                const offset = getTimezoneHourOffset(ms);
                if (offset === 0) {
                    return 'UTC';
                } else {
                    return 'UTC' + (offset > 0 ? '-' : '+') + Math.abs(offset);
                }
            }

            function stringToMoment(str) {
                // this function receives a string that is already localized to the set timezone
                // and converts it to a moment.
                const date = new Date(str);
                if (timezone) {
                    const localOffset = date.getTimezoneOffset() * MINUTE_IN_MS;
                    const targetOffset =
                        moment.tz.zone(timezone).offset(date.getTime() - localOffset) *
                        MINUTE_IN_MS;
                    return moment.tz(date.getTime() - localOffset + targetOffset, timezone);
                } else {
                    return moment(date.getTime());
                }
            }

            function dateWithTimeZone(ms, format) {
                let offsetStr;
                const zoneObj = moment.tz.zone(timezone);
                if (zoneObj) {
                    offsetStr = moment.tz(ms, timezone).format('ZZ');
                }
                return $filter('date')(ms, format, offsetStr);
            }

            function microDateWithTimeZone(micros, format) {
                return dateWithTimeZone(micros / 1000, format);
            }

            function tzDateOrNever(ms, format) {
                return ms <= 0 ? 'Never' : dateWithTimeZone(ms, format);
            }

            function getAllTimezones() {
                const timezones = moment.tz.names();

                // Remove the ones we don't support, including UTC since we will add that
                // back manually to the beginning of the list.
                UNSUPPORTED_TIMEZONES.push(UTC_TIMEZONE);
                const supportedTimezones = _.difference(timezones, UNSUPPORTED_TIMEZONES);

                // Put UTC at start of timezone list.
                supportedTimezones.unshift(UTC_TIMEZONE);
                return supportedTimezones;
            }

            // Offset string will be formatted as a clock (eg. +00:00)
            // Prepend zeros when needed
            function getOffsetStrFromTimeZone(tz) {
                const zone = moment.tz.zone(tz);
                const offsetInMinutes = zone.offset(moment.utc());
                const prefix = offsetInMinutes > 0 ? '-' : '+';
                const num = Math.abs(offsetInMinutes);
                let hours = Math.floor(num / MINUTES_IN_A_HOUR) % HOURS_IN_A_DAY;
                if (hours < 10) {
                    hours = '0' + hours;
                }
                let minutes = num % MINUTES_IN_A_HOUR;
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }
                return prefix + hours + ':' + minutes;
            }

            /**
             * Gets the difference in days between two moments,
             *
             * @param {Moment | string | number | Date | number[]} momentInFuture
             * @returns {number} a number of days between two moments, returns zero if end is before start
             */
            function getDifferenceInDaysBetweenMoments(from, to) {
                const start = getMoment(from);
                const end = getMoment(to);
                const fullDays = end.diff(start, 'days');
                return Math.max(fullDays, 0);
            }

            return {
                getTimezone: getTimezone,
                setTimezone: setTimezone,
                stringToMoment: stringToMoment,
                moment: getMoment,
                getCondensedTimeRange: getCondensedTimeRange,
                dateWithTimeZone: dateWithTimeZone,
                microDateWithTimeZone: microDateWithTimeZone,
                tzDateOrNever: tzDateOrNever,
                getUtcOffset: getUtcOffset,
                getOffsetStrFromTimeZone: getOffsetStrFromTimeZone,
                getAllTimezones: getAllTimezones,
                getUserTimezoneISOString: getUserTimezoneISOString,
                timeAndDateToDatetimeShortString: timeAndDateToDatetimeShortString,
                getDifferenceInDaysBetweenMoments,
            };
        },
    ])

    .filter('tzDateOrNever', [
        'timeZoneService',
        function (timeZoneService) {
            return timeZoneService.tzDateOrNever;
        },
    ])
    .filter('dateWithTimeZone', [
        'timeZoneService',
        function (timeZoneService) {
            return timeZoneService.dateWithTimeZone;
        },
    ])
    .filter('microDateWithTimeZone', [
        'timeZoneService',
        function (timeZoneService) {
            return timeZoneService.microDateWithTimeZone;
        },
    ]);
