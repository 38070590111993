import templateUrl from './signalInfo.tpl.html';

angular.module('signalview.utils').directive('signalInfo', [
    function () {
        return {
            restrict: 'E',
            scope: {
                info: '=',
                resolvingResolution: '=',
            },
            templateUrl,
        };
    },
]);
