import templateUrl from './objectmetricedit.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular.module('signalview.objectmanager').directive('objectMetricEdit', [
    'METRIC_TYPE_CONVERSION',
    'metricService',
    'hasCapability',
    function (METRIC_TYPE_CONVERSION, metricService, hasCapability) {
        return {
            restrict: 'AE',
            scope: {
                viewMode: '=?',
                object: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.metricTypeNativeToDisplay = METRIC_TYPE_CONVERSION.nativeToDisplay;
                    $scope.metricTypeDisplayToNative = METRIC_TYPE_CONVERSION.displayToNative;
                    $scope.temp = {
                        type: '',
                    };

                    hasCapability(Capability.UPDATE_METRIC).then(
                        (hasUpdateMetricCapability) =>
                            ($scope.hasUpdateMetricCapability = hasUpdateMetricCapability)
                    );

                    $scope.editMode = false;
                    let originalType;

                    $scope.enableEditMode = function () {
                        originalType = $scope.temp.type;

                        $scope.editMode = true;
                    };

                    $scope.cancelEdit = function () {
                        $scope.editMode = false;
                        $scope.temp.type = originalType;
                    };

                    $scope.saveEdit = function () {
                        $scope.editMode = false;

                        commitChanges();

                        originalType = $scope.temp.type;
                    };

                    function commitChanges() {
                        const object = $scope.object;
                        const v2MetricObject = metricService.toV2Write(
                            object,
                            null,
                            $scope.temp.type
                        );
                        return metricService
                            .put(v2MetricObject.name, v2MetricObject)
                            .then(function () {
                                object.sf_metricType = $scope.temp.type;
                            });
                    }

                    $scope.$watch('object', function (object) {
                        $scope.temp.type = object.sf_metricType;
                    });
                },
            ],
        };
    },
]);
