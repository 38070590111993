angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    'blockService',
    function (chartTemplates, Chart, plotUtils, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Memory % Distribution')
                .type('area')
                .yAxis(0, {
                    min: 0,
                    max: 120,
                })
                .disableThrottle(true)
                .forcedResolution(30000);

            const letters = ['memory.free', 'memory.used'].map(function (metric) {
                const plot = chart.plot().metric(metric).propertyFilters(filters).visible(false);

                return plotUtils.getLetterFromUniqueKey(plot.uniqueKey());
            });

            const macro = letters[1] + '/(' + letters[0] + '+' + letters[1] + ') * 100';

            const plot3 = chart.plot().macro(macro).visible(false).name('mem %');

            const plot3Letter = plotUtils.getLetterFromUniqueKey(plot3.uniqueKey());

            chart
                .plot()
                .name('min')
                .macro(plot3Letter)
                .color('#05ce00')
                .addManipulation(blockService.min.asAggregation());

            chart
                .plot()
                .name('p10')
                .macro(plot3Letter)
                .color('#e5b312')
                .addManipulation(blockService.percentile.asAggregation([], { percentile: 10 }));

            chart
                .plot()
                .name('p50')
                .macro(plot3Letter)
                .color('#00b9ff')
                .addManipulation(blockService.percentile.asAggregation([], { percentile: 50 }));

            chart
                .plot()
                .name('p90')
                .macro(plot3Letter)
                .color('#f47e00')
                .addManipulation(blockService.percentile.asAggregation([], { percentile: 90 }));

            chart
                .plot()
                .name('maximum')
                .macro(plot3Letter)
                .color('#bd468d')
                .addManipulation(blockService.max.asAggregation());

            return chart;
        }

        chartTemplates.register('collectdHostsMemoryUsageSpread', createChart);
    },
]);
