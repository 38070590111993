import templateUrl from './detectorRuleEditor2.tpl.html';

/**
 * Detector Rule Editor 2 to be used with detector v1.5
 */
angular.module('signalview.detector.rule').directive('detectorRuleEditor2', [
    'ALERT_SEVERITY_LEVELS',
    'chartbuilderUtil',
    'detectorUtils',
    'config',
    function (ALERT_SEVERITY_LEVELS, chartbuilderUtil, detectorUtils, config) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                plots: '=',
                rule: '=ngModel',
                detector: '=',
                invalidRuleName: '=',
            },
            templateUrl,
            link: function ($scope, elem, attr, ngModel) {
                $scope.showUnreleasedFeatures = config('superpower.unreleasedFeatures');
                $scope.severityLevels = ALERT_SEVERITY_LEVELS;

                if (!$scope.rule.notifications) {
                    $scope.rule.notifications = [];
                }

                function getPlotKey(plot) {
                    return chartbuilderUtil.getLetterFromUniqueKey(plot.uniqueKey);
                }

                $scope.$watch('plots.length', function () {
                    $scope.plotIds = $scope.plots.map(getPlotKey);
                });

                $scope.isConditionValid = function () {
                    return (
                        detectorUtils.validateDetectorRule($scope.rule, $scope.plots, true) ===
                        undefined
                    );
                };

                $scope.$watch(
                    'rule',
                    function () {
                        // update validity
                        const hasErrors = detectorUtils.updateDetectorRuleValidityState(
                            $scope.rule,
                            $scope.plots
                        );

                        if (hasErrors) {
                            $scope.detectorRuleForm.$setValidity('rule', false);
                            ngModel.$setValidity('rule', false);
                        } else {
                            $scope.detectorRuleForm.$setValidity('rule', true);
                            ngModel.$setValidity('rule', true);
                        }

                        $scope.$emit('modified rule name');
                    },
                    true
                );

                $scope.editCondition = function () {
                    $scope.$emit('open rule condition trigger', angular.copy($scope.rule));
                };

                $scope.$on('rule condition finish', function (ev, newRule) {
                    // only apply the one that belongs to this component
                    if (newRule.uniqueKey === $scope.rule.uniqueKey) {
                        // instead of pointing to new object, we need to keep the object since otherwise,
                        // we might have dangling pointers

                        // delete everything
                        _.difference(Object.keys($scope.rule)).forEach(function (fieldToRemove) {
                            delete $scope.rule[fieldToRemove];
                        });
                        // then copy over everything from updated version
                        angular.extend($scope.rule, newRule);
                    }
                });
            },
        };
    },
]);
