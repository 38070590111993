import pageSuggestionTemplateUrl from './pageSuggestion.tpl.html';

angular.module('signalview.page').controller('AddDashboardToGroupController', [
    '$q',
    '$scope',
    'params',
    'dashboardGroupService',
    'dashboardGroupUtil',
    'pageDisplayTitle',
    'promiseGenerationManager',
    function (
        $q,
        $scope,
        params,
        dashboardGroupService,
        dashboardGroupUtil,
        pageDisplayTitle,
        promiseGenerationManager
    ) {
        const GROUP_SUGGESTION_DEBOUNCE = 200;

        $scope.pageSuggestionTemplateUrl = pageSuggestionTemplateUrl;
        $scope.addAsClone = false;
        $scope.selectedGroup = null;
        $scope.displayTitle = pageDisplayTitle;
        $scope.nameOverride = params.dashboard.name;
        $scope.descriptionOverride = params.dashboard.description;
        $scope.allCharts = params.allCharts;
        $scope.sourceFilters = params.sourceFilters || [];
        $scope.noFiltersPassed = !params.sourceFilters;

        function getFiltersToSave() {
            let filtersToSave;

            if ($scope.noFiltersPassed && $scope.sourceFilters.length === 0) {
                filtersToSave = null;
            } else {
                filtersToSave = { sources: $scope.sourceFilters, time: null, density: null };
            }

            return filtersToSave;
        }

        function getGroupId() {
            return $scope.selectedGroup.id || $scope.selectedGroup.sf_id;
        }

        $scope.save = function () {
            if (!$scope.selectedGroup || !getGroupId()) {
                return;
            }

            // The addAsClone ui element has been removed so no user will be able to reach this
            if ($scope.addAsClone) {
                // TODO(trevor): Implement this on next diff
                alert("Sorry! Cloning from this modal isn't ready yet :(");
                $scope.$close();
            } else {
                const groupId = getGroupId();
                let groupPromise;
                if (groupId === 'FAKE') {
                    groupPromise = dashboardGroupService.create({
                        name: $scope.selectedGroup.sf_page,
                    });
                } else {
                    groupPromise = $q.when({ id: groupId });
                }

                const filtersToSave = getFiltersToSave();
                if (filtersToSave && filtersToSave.sources) {
                    filtersToSave.sources.forEach(function (filter) {
                        filter.value = filter.propertyValue || filter.value;
                    });
                }
                return groupPromise.then((group) => {
                    dashboardGroupService
                        .addDashboardToGroup(group.id, params.dashboard.id, {
                            nameOverride:
                                $scope.nameOverride === params.dashboard.name
                                    ? null
                                    : $scope.nameOverride,
                            descriptionOverride:
                                $scope.descriptionOverride === params.dashboard.description
                                    ? null
                                    : $scope.descriptionOverride,
                            filtersOverride: filtersToSave,
                        })
                        .then((configId) => {
                            let onSuccessPromise = Promise.resolve();
                            if ('function' === typeof params.onSuccess) {
                                onSuccessPromise = params.onSuccess(
                                    group.id,
                                    configId,
                                    filtersToSave
                                );
                            }

                            return onSuccessPromise.then(() =>
                                $scope.$close({ sameGroup: group.id === params.currentGroupId })
                            );
                        });
                    // TODO(trevor): Need to add error handling
                });
            }
        };

        $scope.isSelectedGroupValid = function () {
            return !!($scope.selectedGroup && getGroupId());
        };

        $scope.getGroupSuggestions = promiseGenerationManager(
            dashboardGroupUtil.getGroupSuggestions,
            GROUP_SUGGESTION_DEBOUNCE
        );
    },
]);
