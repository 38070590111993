import templateUrl from './victoropsNotifyBlock.tpl.html';

angular
    .module('signalview.detector.notification')
    /**
     * This directive prompts the user for configuration information after the user
     * selects VictorOps as the type of notification to trigger for a detector rule.
     *
     * Specifically, this directive first asks the user to select which VictorOps
     * credentials they want to use, and second asks for a "routing key", a string
     * that identifies a team or user within VictorOps who receive the
     * notification. See the VictorOps REST Endpoint for more information. [1]_
     *
     * [1]: http://victorops.force.com/knowledgebase/articles/Integration/Alert-Ingestion-API-Documentation/
     */
    .directive('victoropsNotifyBlock', [
        '$timeout',
        '$log',
        'notificationsUtil',
        function ($timeout, $log, notificationsUtil) {
            return {
                restrict: 'E',
                require: 'ngModel',
                scope: {
                    model: '=ngModel',
                    credentials: '=credentials',
                    remove: '&onRemove',
                    readOnly: '=?',
                    doneEdit: '&onFinishEdit',
                },
                templateUrl,
                link: function ($scope, element) {
                    $scope.credentialType = notificationsUtil.getIntegrationNameForV1orV2(
                        $scope.model.type
                    );

                    $scope.setCredential = function (credential) {
                        $scope.model.credentialId = credential.id;
                        $scope.model.credentialName = credential.name;
                        $scope.credential = credential;

                        $timeout(function () {
                            element.find('input')[0].focus();
                        });
                    };

                    /**
                     * Close the editor if the model is valid, otherwise remove this block.
                     */
                    $scope.finishEdit = function () {
                        if ($scope.model.credentialId && $scope.model.routingKey) {
                            $scope.edit = false;
                        } else {
                            if ($scope.remove) $scope.remove();
                        }
                        if ($scope.doneEdit) $scope.doneEdit();
                    };

                    $scope.$watch('dropdownOpen', function (newValue, oldValue) {
                        if (oldValue && !newValue) {
                            if (!$scope.model.credentialId && $scope.remove) $scope.remove();
                        }
                    });

                    /**
                     * Show the editor.
                     *
                     * Shows the credential name and an input field for the routing key if
                     * a credential is selected, otherwise shows a dropdown from which the
                     * user can select a credential.
                     *
                     * If the user presses 'Enter' or clicks outside the editor,
                     * finishEdit() is called.
                     */
                    $scope.editMode = function () {
                        $scope.edit = true;

                        $timeout(function () {
                            if ($scope.model.credentialId) {
                                element.find('input')[0].focus();
                            } else {
                                $scope.dropdownOpen = true;
                            }
                        });
                    };

                    // Load the credential if this is a saved notify block, otherwise open
                    // edit mode.
                    if ($scope.model.credentialId) {
                        $scope.credentials.some(function (credential) {
                            if (credential.id === $scope.model.credentialId) {
                                $scope.setCredential(credential);
                                return true;
                            }
                        });
                        if (!$scope.credential) {
                            $log.error('Unable to find matching credential!');
                        }
                    } else {
                        $scope.editMode();
                    }
                },
            };
        },
    ]);
