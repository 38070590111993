import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { reactNgBridge } from '../../../../app/reactAngularBridge/MigratedServiceProxies';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { CreateContentService } from '../../../../common/CreateContentService';
import clipboardTooltipTemplateUrl from './clipboardTooltip.tpl.html';
import templateUrl from './globalActions.tpl.html';
import disabledChartTooltipTemplateUrl from './disabledChartTooltip.tpl.html';

export default {
    templateUrl,
    bindings: {
        object: '<',
        snapshot: '<',
        dashboardState: '<',
        dashboardGroup: '<',
    },
    controller: [
        '$element',
        '$scope',
        '$timeout',
        'blockingLoadService',
        'clipboard',
        'currentUser',
        'packageImporter',
        'createContentService',
        'featureEnabled',
        'hasCapability',
        function (
            $element,
            $scope,
            $timeout,
            blockingLoadService,
            clipboard,
            currentUser,
            packageImporter,
            createContentService,
            featureEnabled,
            hasCapability
        ) {
            let clipboardUnSubscriber;
            const $ctrl = this;

            /* controller api */
            $ctrl.initialized = reactNgBridge.areProxiesInitialized();
            $ctrl.$onInit = $onInit;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$onChanges = $onChanges;
            $ctrl.createChart = () =>
                createContentService.generateNewGraphChart($ctrl, ngRoute.history);
            $ctrl.createTextNote = () =>
                createContentService.generateNewTextNote($ctrl, ngRoute.history);
            $ctrl.createPage = () => createContentService.generateDashboardGroup(ngRoute.history);
            $ctrl.createDetector = () => createContentService.generateDetector(ngRoute.history);
            $ctrl.pasteChartsToDashboard = () =>
                createContentService.pasteChartsToDashboard($ctrl, clipboard);
            $ctrl.getNewDashboardMenuText = () =>
                !$ctrl.initialized ? '' : createContentService.getNewDashboardMenuText(clipboard);
            $ctrl.createDashboard = createDashboardWithLoadingScreen;
            $ctrl.clearClipboard = () => clipboard.clearClips();
            $ctrl.onSubmenuTrigger = onSubmenuTrigger;
            $ctrl.subMenuClose = subMenuClose;
            $ctrl.importV2DashboardGroups = importV2DashboardGroups;
            $ctrl.importV2Dashboards = importV2Dashboards;

            $scope.clipboardTooltipTemplateUrl = clipboardTooltipTemplateUrl;
            $scope.disabledChartTooltipTemplateUrl = disabledChartTooltipTemplateUrl;
            $scope.detectorsPlatformApmDetectorEnabled = featureEnabled(
                'detectorsPlatformApmDetector'
            );

            // To check whether all the dashboard were deleted for the current dashboardGroup or not
            $scope.isDashboardGroupEmpty = () =>
                $ctrl.dashboardGroup && !$ctrl.dashboardGroup.dashboards?.length;

            currentUser.isAdmin().then((isAdmin) => {
                $ctrl.isAdmin = isAdmin;
            });

            hasCapability(Capability.CREATE_DETECTOR).then((hasCreateDetectorCapability) => {
                $scope.hasCreateDetectorCapability = hasCreateDetectorCapability;
            });
            hasCapability(Capability.CREATE_CHART).then(
                (hasCreateChartCapability) =>
                    ($scope.hasCreateChartCapability = hasCreateChartCapability)
            );
            hasCapability(Capability.CREATE_DASHBOARD).then(
                (hasCreateDashboardCapability) =>
                    ($scope.hasCreateDashboardCapability = hasCreateDashboardCapability)
            );
            hasCapability(Capability.CREATE_DASHBOARD_GROUP).then(
                (hasCreateDashboardGroupCapability) =>
                    ($scope.hasCreateDashboardGroupCapability = hasCreateDashboardGroupCapability)
            );
            hasCapability(Capability.READ_INTEGRATION).then(
                (hasReadIntegrationCapability) =>
                    ($scope.hasReadIntegrationCapability = hasReadIntegrationCapability)
            );
            hasCapability(Capability.CREATE_TEAM).then(
                (hasCreateTeamCapability) =>
                    ($scope.hasCreateTeamCapability = featureEnabled('newRbacExperience')
                        ? hasCreateTeamCapability
                        : $ctrl.isAdmin)
            );

            function $onChanges(changesObj) {
                if (changesObj.snapshot) {
                    if (CreateContentService.isNewEmptyDashboard($ctrl) && clipboard.getClips()) {
                        createContentService.pasteChartsToDashboard($ctrl, clipboard);
                    }
                }
            }

            function $onInit() {
                bindWatchers();
                $ctrl.chartClips = clipboard.getClips();
                if (featureEnabled('ollyGlobalFiltersEnabled')) {
                    clipboard.setClipBoardHostElementId($element[0]);
                }
            }

            function $onDestroy() {
                if (clipboardUnSubscriber) {
                    clipboardUnSubscriber();
                }
            }

            function importV2DashboardGroups() {
                packageImporter.importGroups();
            }

            function importV2Dashboards() {
                packageImporter.importDashboards($ctrl.dashboardGroup.id);
            }

            function createDashboardWithLoadingScreen() {
                const spinner = blockingLoadService();

                createContentService.generateNewDashboard(ngRoute.history).finally(function () {
                    // artificial delay so user knows it happened, too fast otherwise
                    $timeout(spinner.close, 250);
                });
            }

            function onSubmenuTrigger(event) {
                if (event.keyCode === 37) {
                    angular.element('.dropdown-submenu ul')[0].classList.add('subMenuDisplay');
                    angular.element('.dropdown-submenu ul li a')[0].focus();
                }
            }

            function subMenuClose() {
                angular.element('.dropdown-submenu ul')[0].classList.remove('subMenuDisplay');
            }

            function bindWatchers() {
                $scope.$on('create a dashboard', createDashboardWithLoadingScreen);
                $scope.$on('create a dashboard group', () =>
                    createContentService.generateDashboardGroup(ngRoute.history)
                );

                reactNgBridge.onInitialize(() => {
                    $ctrl.initialized = true;
                });

                if (!featureEnabled('ollyGlobalFiltersEnabled')) {
                    $scope.$on('clipboard:copyInProgress', handleCopyInProgess);
                }

                clipboardUnSubscriber = clipboard.subscribeToStateChange(({ clips }) => {
                    $ctrl.chartClips = clips;
                    $scope.$applyAsync();
                });
            }

            // Animate chart towards New Dashboard icon, to draw user's attention
            // to the badge and count of unseen charts
            function handleCopyInProgess(evt, params) {
                $ctrl.dashboardState.copyInProgress += 1;
                $timeout(() => {
                    const clipboardIcon = angular.element('.clipboard-anchor', $element);
                    const copyOverlay = angular.element('.copy-overlay', $element);
                    copyOverlay
                        .css({
                            display: 'block',
                            top: params.source.offset().top,
                            left: params.source.offset().left,
                            width: params.source.outerWidth(),
                            height: params.source.outerHeight(),
                        })
                        .animate(
                            {
                                top: clipboardIcon.offset().top,
                                left: clipboardIcon.offset().left,
                                width: clipboardIcon.outerWidth(),
                                height: clipboardIcon.outerHeight(),
                            },
                            {
                                duration: 300,
                                complete() {
                                    copyOverlay.css('display', 'none');
                                    $ctrl.dashboardState.copyInProgress -= 1;
                                    clipboard.addClip(params.chart);
                                    $scope.$apply();
                                },
                            }
                        );
                });
            }
        },
    ],
};
