import templateUrl from './dashboardDiscovery.tpl.html';

angular.module('signalview.objectmanager').directive('dashboardDiscovery', [
    '$location',
    '$timeout',
    '$q',
    'dashboardV2Service',
    '$log',
    function () {
        return {
            restrict: 'E',
            scope: {
                isNewCatalog: '=',
                discoveryOptions: '=',
                filters: '=',
                inViewAll: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    function reset() {
                        $scope.temp = {
                            discoverySelector: '',
                        };

                        if ($scope.discoveryOptions) {
                            $scope.temp.selectors =
                                angular.copy($scope.discoveryOptions.selectors) || [];
                            $scope.temp.discoveryQuery = $scope.discoveryOptions.query || '';
                        } else {
                            $scope.temp.selectors = [];
                            $scope.temp.discoveryQuery = '';
                        }
                    }

                    reset();

                    $scope.editMode = false;

                    $scope.enableEditMode = function () {
                        $scope.originalSelectors = angular.copy($scope.temp.selectors);
                        $scope.editMode = true;
                    };

                    $scope.cancelEdit = function () {
                        $scope.editMode = false;
                        $scope.temp.selectors = $scope.originalSelectors;
                        $scope.temp.discoveryQuery = $scope.originalQuery;
                        $scope.temp.discoverySelector = '';
                    };

                    $scope.saveEdit = function () {
                        $scope.editMode = false;

                        //if we are adding discovery options for the first time
                        if (!$scope.discoveryOptions) {
                            $scope.discoveryOptions = {};
                        }
                        $scope.discoveryOptions.selectors = $scope.temp.selectors;
                        $scope.discoveryOptions.query = $scope.temp.discoveryQuery;
                        $scope.originalSelectors = $scope.temp.selectors;
                        $scope.originalQuery = $scope.temp.discoveryQuery;
                    };

                    $scope.$watch('discoveryOptions', function (object) {
                        reset();

                        if (!object) return;

                        if (object.discoveryOptions) {
                            $scope.originalSelectors = $scope.temp.selectors =
                                angular.copy(object.discoveryOptions.selectors) || [];
                            $scope.originalQuery = $scope.temp.discoveryQuery =
                                object.discoveryOptions.query || '';
                        }
                    });

                    function addDiscoverySelector(selector) {
                        const index = $scope.temp.selectors.indexOf(selector);
                        // Don't allow less than 2 chars or dupes.
                        if (index !== -1 || !selector || selector.length < 2) return;

                        $scope.temp.selectors.push(selector);
                        $scope.temp.discoverySelector = '';
                    }

                    $scope.removeSelector = function (selector) {
                        const index = $scope.temp.selectors.indexOf(selector);
                        if (index === -1) return;

                        $scope.temp.selectors.splice(index, 1);
                    };

                    $scope.commitDiscoverySelector = function () {
                        addDiscoverySelector($scope.temp.discoverySelector);
                    };

                    $scope.onSelectorKeyPress = function (event) {
                        if (event && event.keyCode === 13) {
                            $scope.commitDiscoverySelector();
                        }
                    };
                },
            ],
        };
    },
]);
