angular.module('signalview.objectmanager').service('hostProcessDataStream', [
    '$timeout',
    'Restangular',
    function ($timeout, rest) {
        let latestTimestamp = 0;
        const pollingInterval = 10000;
        let timeoutId = null;

        function getProcessInfo(hostName, orgId, callback, pollingCallback) {
            //hostName = 'varnish-4.0.3-0';
            rest.one('processtable', hostName)
                .get({
                    orgId: orgId,
                    since: latestTimestamp,
                })
                .then(
                    function (resp) {
                        if (resp) {
                            latestTimestamp = resp.date;
                            callback(resp);
                            timeoutId = $timeout(function () {
                                getProcessInfo(hostName, orgId, callback);
                            }, pollingInterval);
                        }
                    },
                    function () {
                        timeoutId = $timeout(function () {
                            getProcessInfo(hostName, orgId, callback);
                        }, pollingInterval);
                        if (pollingCallback) pollingCallback(pollingInterval);
                    }
                );
        }

        function streamProcessInfo(hostName, orgId, callback) {
            getProcessInfo(hostName, orgId, callback);
        }

        function stopStream() {
            $timeout.cancel(timeoutId);
        }

        return {
            streamProcessInfo: streamProcessInfo,
            stopStream: stopStream,
        };
    },
]);
