import templateUrl from './groupableTable.tpl.html';

/*
 * Groupable Table Component
 *
 * Column Config Example
 * { 'aggregate' : < value of null, sum, mean>
 *   'displayName' : < name of column > ,
 *   'format' : < value of null, id, value>
 *   'metric' : < metric the value would come from>,
 *   'property' : < the metadata property to display >,
 * }
 *
 *
 * @param  {Object} columns the column config relating to the column information and where to pull the information from
 * @param  {Object} data
 * @param  {Object} timestampSeen
 * @param  {Array} groupBy a list of up to two group by elements
 * @param  {Array} groupByExclude a list of properties not to display in groupBy suggestions
 * @param  {Object} showGroupByDropdown whether to display groupBy suggestion dropdown or not
 * @param  {Array} colorBy
 * @param  {String} alwaysGroupBy one value to always group by
 * @param  {String} firstLevelGroups key of the first grouping level in an aggregated table
 * @param  {String} secondLevelGroups key of the second grouping level in an aggregated table
 * @param  {Object} coloringFunction to use when coloring data
 * @param  {Object} showAggregatedTable whether to show a top aggregated row or not, data would need to be available based on aggregated display name
 * @param  {Object} aggregatedDisplayName, name to use on top aggregated row for all data values
 * @param  {Function} onMouseOverTableValue, passes hovered table value key to parent
 * @param  {Object} searchable: passes search related params
 *         {
 *           placeholder: placeholder text for the search bar
 *         }
 * @param  {Object} paginate: whether to "Show More" results in a paginated fashion
 *
 * NOTE: paginate and showAggregatedTable cannot be used together
 */
export default {
    templateUrl,
    bindings: {
        columns: '<',
        data: '<',
        timestampSeen: '<',
        groupBy: '<',
        groupByExclude: '<',
        showGroupByDropdown: '<',
        alwaysGroupBy: '<',
        colorBy: '<',
        firstLevelGroups: '<',
        secondLevelGroups: '<',
        coloringFunction: '<',
        showAggregatedTable: '<',
        aggregatedDisplayName: '<',
        onMouseOverTableValue: '&',
        customMapping: '&',
        trackClickPrefix: '@',
        searchable: '=',
        tableTitle: '=',
        paginate: '<',
        isAdmin: '<',
        onSearch: '<?',
        onGroupBy: '<?',
    },
    controller: [
        '_',
        '$scope',
        '$q',
        '$location',
        '$element',
        '$timeout',
        'urlOverridesService',
        'valueRenderer',
        'GROUPED_TABLE_EVENTS',
        'getDataFacets',
        'kubeDataService',
        function (
            _,
            $scope,
            $q,
            $location,
            $element,
            $timeout,
            urlOverridesService,
            valueRenderer,
            GROUPED_TABLE_EVENTS,
            getDataFacets,
            kubeDataService
        ) {
            const SHOW_IN_INCREMENTS = 25;
            const ctrl = this;
            ctrl.criticality = {
                0: 'Ok',
                20: 'Info',
                40: 'Warning',
                60: 'Minor',
                80: 'Major',
                100: 'Critical',
            };
            ctrl.MAX_GROUP_BY_LIMIT = 2;
            ctrl.sortBy = {
                property: {
                    name: 'id',
                    asc: true,
                },
            };
            ctrl.sortAscGroup = true;
            ctrl.scrollTrack = 0;
            ctrl.mouseOverSelection = null;

            ctrl.$onChanges = $onChanges;
            ctrl.$onInit = $onInit;
            ctrl.getGroupBySuggestions = getGroupBySuggestions;
            ctrl.isNonLinkable = isNonLinkable;
            ctrl.renderValue = renderValue;
            ctrl.getCrossLinkDimension = getCrossLinkDimension;
            ctrl.showMore = showMore;
            ctrl.sortByMetric = sortByMetric;
            ctrl.sortByProperty = sortByProperty;
            ctrl.getAggregationValue = getAggregationValue;
            ctrl.showGroupToggles = showGroupToggles;
            ctrl.isExpanded = isExpanded;
            ctrl.hasExpandedGroup = hasExpandedGroup;
            ctrl.toggleGroup = toggleGroup;
            ctrl.isSelectedGroup = isSelectedGroup;
            ctrl.selectGroup = selectGroup;
            ctrl.sortColumnData = sortColumnData;
            ctrl.selectValue = selectValue;
            ctrl.color = color;
            ctrl.isSingleSelection = isSingleSelection;
            ctrl.showGroupHeader = showGroupHeader;
            ctrl.searchAndUpdateUrl = searchAndUpdateUrl;
            ctrl.resetVisibleRows = resetVisibleRows;
            ctrl.$doCheck = _.debounce(refreshRenderedDataCache, 200, {
                leading: true,
                trailing: false,
                maxWait: 5000,
            });
            ctrl.onMouseOver = _.debounce(onMouseOver, 150);

            // Which first level Group By groups are expanded, keyed by group name
            const firstLevelExpanded = {};

            // Which second level Group By groups are expanded, keyed by first
            // level group name, then by second level
            const secondLevelExpanded = {};
            let scheduled = null;

            function $onInit() {
                resetVisibleRows();

                $scope.$watch('ctrl.data', onDataUpdate);
                $scope.$on('$destroy', () => $timeout.cancel(scheduled));
                $scope.$on('groupBy selector highlighted', ($event, groupBy) => {
                    ctrl.groupBy = groupBy;
                    sortData();
                    resetVisibleRows();
                });

                ctrl.coloredByAlert = isColorByAlert();
                ctrl.searchInput = urlOverridesService.getQueryParam();
                onDataUpdate();
            }

            function $onChanges({ colorBy, groupBy }) {
                if (colorBy || groupBy) {
                    onDataUpdate();
                    if (groupBy) {
                        ctrl.currentSelection = null;
                        ctrl.selectedGroup = null;
                        resetVisibleRows();
                    }
                }
            }

            // Scroll to selected value in table
            function scrollSelectedIntoView() {
                if (!ctrl.showAggregatedTable) {
                    return;
                }
                $timeout(() => {
                    const elem = $element.find('.selected')[0];
                    if (!elem) {
                        return;
                    }

                    if (elem.scrollIntoViewIfNeeded) {
                        elem.scrollIntoViewIfNeeded();
                    } else {
                        elem.scrollIntoView({ block: 'end', behavior: 'smooth' });
                    }
                });
            }

            function onDataUpdate() {
                updateSelection();
                sortData();
                scrollSelectedIntoView();
                setVisibleRows(ctrl.visibleRows);
            }

            function updateSelection() {
                // Update selected value based on URL
                const selection = urlOverridesService.getMapSelection();

                // pull group by selection from url to ensure the group-by stays active during updates
                const groupBySelection = urlOverridesService.getGroupBySelection();

                if (selection === ctrl.aggregatedDisplayName) {
                    ctrl.currentSelection = selection;
                    $scope.$emit(GROUPED_TABLE_EVENTS.ROW_SELECTED, ctrl.aggregatedDisplayName);
                } else if (groupBySelection && groupBySelection.length >= 1) {
                    // Expand first level group to show selected group
                    const firstGroup = groupBySelection[0];
                    firstLevelExpanded[firstGroup] = true;
                    if (!secondLevelExpanded[firstGroup]) {
                        secondLevelExpanded[firstGroup] = {};
                    }
                    ctrl.currentSelection = null;
                    ctrl.selectedGroup = groupBySelection;
                } else if (ctrl.data[selection]) {
                    const selectionValue = ctrl.data[selection];
                    ctrl.selectedGroup = null;
                    ctrl.currentSelection = selection;
                    // Expand group to show selection
                    let firstGroup;
                    if (ctrl.groupBy.length) {
                        firstGroup = selectionValue[ctrl.groupBy[0]];
                        firstLevelExpanded[firstGroup] = true;
                    }
                    if (ctrl.groupBy.length > 1) {
                        const secondGroup = selectionValue[ctrl.groupBy[1]];
                        if (!secondLevelExpanded[firstGroup]) {
                            secondLevelExpanded[firstGroup] = {};
                        }
                        secondLevelExpanded[firstGroup][secondGroup] = true;
                    }
                    $scope.$emit(GROUPED_TABLE_EVENTS.ROW_SELECTED, selectionValue);
                } else {
                    ctrl.currentSelection = null;
                }
            }

            function refreshRenderedDataCache() {
                const renderedData = {};
                let reProcessData = _.isEmpty(ctrl.renderedData);

                const data = ctrl.data || {};
                for (const selection in data) {
                    const renderedRow = [];
                    const row = data[selection];
                    for (const column of ctrl.columns) {
                        const value = column.metric
                            ? row.value[column.metric]
                            : row[column.property];
                        renderedRow.push(renderValue(value, column, row));
                    }
                    renderedData[selection] = renderedRow;

                    if (!reProcessData) {
                        reProcessData = !(selection in ctrl.renderedData); // There is new data
                        delete ctrl.renderedData[selection];
                    }
                }

                // There is new data or we have less new data now, either way, re-process.
                reProcessData = reProcessData || ctrl.renderedData.length > 0;
                ctrl.renderedData = renderedData;

                if (reProcessData) {
                    sortData();
                    setVisibleRows(ctrl.visibleRows);
                }
                updateSelection();
            }

            function getCrossLinkDimension(row, column) {
                return {
                    propertyName: column.property,
                    propertyValue: row[ctrl.columns.indexOf(column)],
                };
            }

            function renderValue(value, column, row) {
                let format = column.format;
                if (column.property === 'value') {
                    if (ctrl.colorBy.valueFormat) {
                        format = ctrl.colorBy.valueFormat;
                    }

                    if (row && (row._isDead || row._hasNoData)) {
                        return 'Not reporting';
                    }
                } else if (column.property === 'Age') {
                    value = row.timestamp / 1000 - value;
                } else if (column.property === 'workloadType') {
                    value = kubeDataService.getWorkloadType(row);
                }
                if (format === 'Custom') {
                    return ctrl.customMapping({ value, column });
                }
                return valueRenderer(value, format);
            }

            function compareValue(a, b, asc) {
                // Consider undefined and null to be equivalent
                if (a === b || (a === undefined && b === null) || (a === null && b === undefined)) {
                    return 0;
                }
                // always show undefs last, regardless of sort order
                if (a === undefined || a === null) return 1;
                if (b === undefined || b === null) return -1;

                if (!isNaN(a) && !isNaN(b)) {
                    a = parseFloat(a, 10);
                    b = parseFloat(b, 10);
                    return (a - b) * (asc ? 1 : -1);
                }
                return asc ? a.localeCompare(b) : b.localeCompare(a);
            }

            function compareMetric(a, b) {
                const metric = ctrl.sortBy.metric.name;
                const aValue = a.value[metric];
                const bValue = b.value[metric];
                return compareValue(aValue, bValue, ctrl.sortBy.metric.asc);
            }

            function compareProperty(a, b) {
                const property = ctrl.sortBy.property.name;
                const aValue = a[property];
                const bValue = b[property];
                return compareValue(aValue, bValue, ctrl.sortBy.property.asc);
            }

            function sortFn(a, b) {
                const hostA = ctrl.data[a] || {};
                const hostB = ctrl.data[b] || {};
                const groupBy = ctrl.groupBy || [];
                const groupByLength = groupBy.length;

                let groupCompare = 0;
                let firstProperty;

                if (groupByLength > 0) {
                    firstProperty = groupBy[0];
                    if (ctrl.sortBy.metric) {
                        // Compare by first level group's aggregated metric value
                        const hostAFirstGroup = ctrl.firstLevelGroups[hostA[firstProperty]] || {};
                        const hostAValue = hostAFirstGroup[ctrl.sortBy.metric.name];
                        const hostBFirstGroup = ctrl.firstLevelGroups[hostB[firstProperty]] || {};
                        const hostBValue = hostBFirstGroup[ctrl.sortBy.metric.name];
                        groupCompare = compareValue(hostAValue, hostBValue, ctrl.sortBy.metric.asc);
                    }
                    if (!groupCompare) {
                        // Compare by first level group name
                        groupCompare = compareValue(
                            hostA[firstProperty],
                            hostB[firstProperty],
                            ctrl.sortAscGroup
                        );
                    }
                }

                if (!groupCompare && groupByLength > 1) {
                    const secondProperty = groupBy[1];
                    if (ctrl.sortBy.metric) {
                        // Compare by second level group's aggregated metric value
                        const hostAFirstGroup = ctrl.secondLevelGroups[hostA[firstProperty]] || {};
                        const hostASecondGroup = hostAFirstGroup[hostA[secondProperty]] || {};
                        const hostAValue = hostASecondGroup[ctrl.sortBy.metric.name];
                        const hostBFirstGroup = ctrl.secondLevelGroups[hostB[firstProperty]] || {};
                        const hostBSecondGroup = hostBFirstGroup[hostB[secondProperty]] || {};
                        const hostBValue = hostBSecondGroup[ctrl.sortBy.metric.name];
                        groupCompare = compareValue(hostAValue, hostBValue, ctrl.sortBy.metric.asc);
                    }

                    if (!groupCompare) {
                        // Compare by second level group name
                        groupCompare = compareValue(
                            hostA[secondProperty],
                            hostB[secondProperty],
                            ctrl.sortAscGroup
                        );
                    }
                }

                if (groupCompare) {
                    return groupCompare;
                }

                if (ctrl.sortBy.metric) {
                    // Compare by host's metric value
                    return compareMetric(hostA, hostB);
                }
                // Compare by host's property value
                return compareProperty(hostA, hostB);
            }

            function customMerge(left, right) {
                let result = [];
                while (left.length > 0 && right.length > 0) {
                    const compared = sortFn(left[0], right[0]);
                    if (compared < 0) {
                        result.push(left.shift());
                    } else {
                        result.push(right.shift());
                    }
                }
                result = result.concat(left, right);
                return result;
            }

            function customSort(keys) {
                const len = keys.length;
                if (len < 2) {
                    return keys;
                }
                const pivot = Math.ceil(len / 2);
                return customMerge(customSort(keys.slice(0, pivot)), customSort(keys.slice(pivot)));
            }

            function sortData() {
                if (scheduled) {
                    $timeout.cancel(scheduled);
                }
                scheduled = $timeout(
                    function () {
                        ctrl.sorted = customSort(Object.keys(ctrl.data || {}));
                        searchAndUpdateUrl();
                        setVisibleRows(ctrl.visibleRows);

                        scheduled = null;
                        $scope.$emit(GROUPED_TABLE_EVENTS.DATA_SORTED, ctrl.sorted);
                    },
                    Object.keys(ctrl.data).length > 1000 ? 1000 : 50
                );
            }

            function sortByMetric(metricName) {
                if (!ctrl.columns.filter((column) => column.metric === metricName).length) {
                    return;
                }
                delete ctrl.sortBy.property;

                if (ctrl.sortBy.metric && ctrl.sortBy.metric.name === metricName) {
                    ctrl.sortBy.metric.asc = !ctrl.sortBy.metric.asc;
                } else {
                    ctrl.sortBy.metric = {
                        name: metricName,
                        asc: true,
                    };
                }

                // Flip group(s) sort direction if this is the first column
                if (ctrl.columns[0].metric === metricName) {
                    ctrl.sortAscGroup = !ctrl.sortAscGroup;
                }

                sortData();
            }

            function sortByProperty(propertyName) {
                if (
                    propertyName !== 'id' &&
                    propertyName !== 'value' &&
                    !ctrl.columns.filter((column) => column.property === propertyName).length
                ) {
                    return;
                }

                delete ctrl.sortBy.metric;

                if (ctrl.sortBy.property && ctrl.sortBy.property.name === propertyName) {
                    ctrl.sortBy.property.asc = !ctrl.sortBy.property.asc;
                } else {
                    ctrl.sortBy.property = {
                        name: propertyName,
                        asc: true,
                    };
                }

                // Flip group(s) sort direction if this is the first column
                if (ctrl.columns[0].property === propertyName) {
                    ctrl.sortAscGroup = !ctrl.sortAscGroup;
                }

                sortData();
            }

            function getAggregationValue(level, selection, metric) {
                const firstGroup = ctrl.data[selection][ctrl.groupBy[0]];
                if (level === 0) {
                    // In between sorts, the groups might not be set yet
                    if (!ctrl.firstLevelGroups[firstGroup]) {
                        return;
                    }
                    return ctrl.firstLevelGroups[firstGroup][metric];
                }

                if (!ctrl.secondLevelGroups[firstGroup]) {
                    return;
                }
                const secondGroup = ctrl.data[selection][ctrl.groupBy[1]];
                if (!ctrl.secondLevelGroups[firstGroup][secondGroup]) {
                    return;
                }
                return ctrl.secondLevelGroups[firstGroup][secondGroup][metric];
            }

            function showGroupToggles() {
                return ctrl.showAggregatedTable && ctrl.groupBy.length;
            }

            function isExpanded(level, selection) {
                if (!ctrl.data[selection]) {
                    return false;
                }

                const firstGroup = ctrl.data[selection][ctrl.groupBy[0]];
                if (level === 0) {
                    return firstLevelExpanded[firstGroup];
                }

                if (!secondLevelExpanded[firstGroup]) {
                    return false;
                }
                const secondGroup = ctrl.data[selection][ctrl.groupBy[1]];
                return (
                    firstLevelExpanded[firstGroup] && secondLevelExpanded[firstGroup][secondGroup]
                );
            }

            function hasExpandedGroup(selection) {
                if (!ctrl.groupBy.length) {
                    return true;
                }

                if (ctrl.isSingleSelection(selection)) {
                    if (ctrl.groupBy.length === 1) {
                        // Show single selection for this group
                        return true;
                    }
                    // Show single selection if the next group level up is expanded
                    return ctrl.isExpanded(ctrl.groupBy.length - 2, selection);
                } else {
                    // Show selection if its group is expanded
                    return ctrl.isExpanded(ctrl.groupBy.length - 1, selection);
                }
            }

            function toggleGroup(level, selection) {
                if (!ctrl.showGroupToggles) {
                    return;
                }

                const firstGroup = ctrl.data[selection][ctrl.groupBy[0]];
                if (level === 0) {
                    if (!firstLevelExpanded[firstGroup]) {
                        secondLevelExpanded[firstGroup] = {};
                    }
                    firstLevelExpanded[firstGroup] = !firstLevelExpanded[firstGroup];
                } else {
                    const secondGroup = ctrl.data[selection][ctrl.groupBy[1]];
                    secondLevelExpanded[firstGroup][secondGroup] =
                        !secondLevelExpanded[firstGroup][secondGroup];
                }
            }

            function hostMatchesGroupBy(level, selection) {
                const currentSelection = ctrl.selectedGroup;
                // Either Group By is not applicable for this selection when the group
                // value is empty and the selection does not have that property, or the
                // group and selection have the same non-empty value
                return (
                    (!currentSelection[level] && !ctrl.data[selection][ctrl.groupBy[level]]) ||
                    currentSelection[level] === ctrl.data[selection][ctrl.groupBy[level]]
                );
            }

            function isSelectedGroup(level, selection) {
                if (!ctrl.data[selection]) {
                    return false;
                }

                const currentSelection = ctrl.selectedGroup;
                // Group level must be at the same one as the current selection.
                // level is 0-based index.
                if (!currentSelection || level + 1 !== currentSelection.length) {
                    return false;
                }
                return (
                    hostMatchesGroupBy(0, selection) &&
                    (currentSelection.length === 1 || hostMatchesGroupBy(level, selection))
                );
            }

            function selectGroup(level, selection) {
                ctrl.currentSelection = null;
                if (ctrl.isSelectedGroup(level, selection)) {
                    // deselect
                    ctrl.selectedGroup = null;
                    $scope.$emit(GROUPED_TABLE_EVENTS.GROUP_SELECTED);
                } else {
                    const firstGroup = ctrl.data[selection][ctrl.groupBy[0]];
                    const groups = [firstGroup];
                    if (level > 0) {
                        const secondGroup = ctrl.data[selection][ctrl.groupBy[1]];
                        groups.push(secondGroup);
                    }
                    ctrl.selectedGroup = groups;
                    $scope.$emit(GROUPED_TABLE_EVENTS.GROUP_SELECTED, groups);
                    scrollSelectedIntoView();
                }
            }

            function sortColumnData(column) {
                if (column.disableSort) {
                    return;
                }
                return column.metric
                    ? ctrl.sortByMetric(column.metric)
                    : ctrl.sortByProperty(column.property);
            }

            function selectValue(selection, column) {
                // if link is disabled do not let the click go through.
                if (ctrl.isNonLinkable(selection, column) === true) {
                    if (column.alternativeLink) {
                        $location.path(column.alternativeLink);
                    }
                    return;
                }
                ctrl.selectedGroup = null;
                ctrl.currentSelection = selection;
                $scope.$emit(
                    GROUPED_TABLE_EVENTS.VALUE_SELECTED,
                    selection,
                    ctrl.data[selection],
                    column
                );
                scrollSelectedIntoView();
            }

            function onMouseOver(selection) {
                if (ctrl.processedData.length > 1) {
                    ctrl.mouseOverSelection = selection;
                    const selectionValue = ctrl.data[selection];
                    ctrl.onMouseOverTableValue({ selectionValue });
                }
            }

            function color(selection) {
                if (ctrl.coloringFunction) {
                    const data = ctrl.data[selection] || {};
                    const coloringFunction = ctrl.coloringFunction;
                    return coloringFunction(data);
                }
            }

            function isSingleSelection(selection) {
                // Only care about single data when there's always a Group By, to
                // avoid showing redundant information
                const alwaysGroupBy = ctrl.alwaysGroupBy;
                if (!alwaysGroupBy || !ctrl.data[selection]) {
                    return false;
                }

                for (const current in ctrl.data) {
                    if (current === selection) {
                        continue;
                    }
                    // Check if there's another selection that has all the same values for
                    // every Group By level
                    const hasSameGroups = ctrl.groupBy.reduce((sameGroups, group) => {
                        return (
                            sameGroups && ctrl.data[current][group] === ctrl.data[selection][group]
                        );
                    }, true);
                    if (hasSameGroups) {
                        return false;
                    }
                }
                return true;
            }

            function showGroupHeader(selectionIndex, selection, groupIndex) {
                if (!ctrl.data[selection]) {
                    return false;
                }

                let groupChanged = true;
                if (selectionIndex !== 0) {
                    const previousSelection = ctrl.processedData[selectionIndex - 1];
                    if (!previousSelection || !ctrl.data[previousSelection]) {
                        return false;
                    }

                    const firstGroupBy = ctrl.groupBy[0];
                    groupChanged =
                        ctrl.data[previousSelection][firstGroupBy] !==
                        ctrl.data[selection][firstGroupBy];

                    if (groupIndex === 1 && !groupChanged) {
                        const secondGroupBy = ctrl.groupBy[1];
                        groupChanged =
                            ctrl.data[previousSelection][secondGroupBy] !==
                            ctrl.data[selection][secondGroupBy];
                    }
                }
                // Different group than the last selection, and either all group headers
                // are shown or this is a first level group or the second level
                // group's first level group is expanded, and this group level is
                // either not the last group by or this is not the only selection in the
                // group
                return (
                    groupChanged &&
                    (!ctrl.showGroupToggles() ||
                        groupIndex === 0 ||
                        ctrl.isExpanded(0, selection)) &&
                    (groupIndex + 1 !== ctrl.groupBy.length || !ctrl.isSingleSelection(selection))
                );
            }

            function isColorByAlert() {
                return (
                    ctrl.colorBy && ctrl.colorBy.id && ctrl.colorBy.id.indexOf('___SF_ALERT') === 0
                );
            }

            function showMore() {
                setVisibleRows((ctrl.visibleRows || 0) + SHOW_IN_INCREMENTS);
            }

            function resetVisibleRows() {
                setVisibleRows(
                    Math.min(
                        (ctrl.processedData && ctrl.processedData.length) || 0,
                        SHOW_IN_INCREMENTS
                    )
                );
                $timeout(() => ctrl.scrollTrack++);
            }

            function setVisibleRows(limit) {
                ctrl.visibleRows = ctrl.paginate
                    ? Math.min(
                          (ctrl.processedData && ctrl.processedData.length) || 0,
                          Math.max(limit || 0, 0)
                      )
                    : null;
            }

            function isNonLinkable(selection, column) {
                if (!selection || !column || !column.propertiesToLinkOn) {
                    return false;
                }
                const row = ctrl.data[selection];
                return !column.propertiesToLinkOn.every((property) => row.hasOwnProperty(property));
            }

            function searchAndUpdateUrl() {
                if (!ctrl.searchable || !ctrl.searchInput) {
                    urlOverridesService.clearQueryParam();
                    ctrl.processedData = ctrl.sorted;
                    return;
                }

                urlOverridesService.setQueryParam(ctrl.searchInput);
                const lCaseSearchTerm = ctrl.searchInput.toLowerCase();
                ctrl.processedData = ctrl.sorted.filter((key) =>
                    key.toLowerCase().includes(lCaseSearchTerm)
                );
            }

            function getGroupBySuggestions() {
                const suggestions = getDataFacets(Object.values(ctrl.data), ctrl.groupByExclude);
                return $q.when(suggestions);
            }
        },
    ],
};
