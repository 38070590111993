import templateUrl from './autosuggestinput.tpl.html';
import iconTypeaheadItemTemplateUrl from '../../../legacy/common/bootstrap/iconTypeaheadItem.tpl.html';

angular
    .module('signalview.autoSuggestInput', ['signalboost'])

    .directive('sfAutosuggestInput', [
        '$q',
        '$log',
        function ($q, $log) {
            return {
                restrict: 'AE',
                replace: true,
                scope: {
                    placeholder: '@',
                    inputClassContainer: '@',
                    inputClass: '@',
                    query: '=',
                    suggestFunc: '=',
                    suggestBias: '=',
                    suggestCallback: '=',
                },
                templateUrl,
                controller: function () {},
                link: function ($scope) {
                    $scope.iconTypeaheadItemTemplateUrl = iconTypeaheadItemTemplateUrl;
                    $scope.addAutoSuggestion = function (str) {
                        $scope.query = str.query;
                        $scope.suggestCallback($scope.query, true, str);
                    };

                    $scope.autoSuggest = function (key) {
                        $scope.suggestCallback(key, false);
                        // avoid complex queries for now until we have a query builder
                        if (key.match(/\s+(AND|OR|NOT)\s+/)) {
                            return $q.when([]);
                        }
                        function onFetchError(reason) {
                            $log.error('Auto suggest error ', reason);
                            return [];
                        }
                        const keySegments = key.split(':');
                        if (keySegments.length >= 2) {
                        }

                        return $scope
                            .suggestFunc($scope.suggestBias(), key, 10)
                            .then(function (results) {
                                return results;
                            }, onFetchError);
                    };

                    $scope.getAutoSuggestDisplayLabel = function (res) {
                        if (!res || !res.value) {
                            return '';
                        }
                        let str = res.value;
                        let tagMatch = str.match(/sf_tags:\(?(\S+)\)?/);
                        if (tagMatch) {
                            str = tagMatch[0];
                        } else {
                            tagMatch = str.match(/sf_source:(\S+)/);
                            if (tagMatch) {
                                str = tagMatch[1];
                            }
                        }
                        return str;
                    };

                    $scope.onAutoSuggestSelect = function (item) {
                        const splitResult = item.query.split(':');
                        if (splitResult.length >= 2 && splitResult[1].length > 0) {
                            $scope.addAutoSuggestion(item);
                        } else if (item.type === 'property') {
                            const valueToApply = item.value + ':';
                            $scope.$broadcast('setViewValue', 'autoSuggestInput', valueToApply);
                        }
                    };
                },
            };
        },
    ]);
