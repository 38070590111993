'use strict';

angular.module('signalview.heatmap').factory('heatmapEndpoint', [
    'currentUser',
    '$http',
    'API_URL',
    function (currentUser, $http, API_URL) {
        const resourceEndpoint = API_URL + '/v2/heatmap';

        function getAlertAggregation(aggregation, filters, limit) {
            return currentUser.orgId().then(function (orgId) {
                return $http.get(resourceEndpoint + '/alertAggregation', {
                    params: {
                        aggregation: aggregation,
                        filters: filters || [],
                        limit: limit || 100,
                        organizationId: orgId,
                    },
                });
            });
        }

        return {
            alertAggregation: getAlertAggregation,
        };
    },
]);
