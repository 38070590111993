import templateUrl from './hostproperties.tpl.html';

angular.module('signalview.heatmap').directive('hostProperties', [
    'valueFormatter',
    function (valueFormatter) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
            },
            templateUrl,
            link: function ($scope) {
                const heatmap = $scope.heatmap;

                $scope.selection = heatmap.selection();
                const heatmapEventBindings = [];
                heatmapEventBindings.push(
                    heatmap.on('selection updated', function ($event, selection) {
                        $scope.selection = selection;
                        initializeProperties();
                    })
                );

                const PROPERTY_DENYLIST = ['tsid', 'jobId', 'value', 'parent', 'depth', 'stat'];

                function updateModeColumns() {
                    const mode = heatmap.mode();
                    $scope.columns = mode.propertyColumns;
                    $scope.tabs = {};

                    $scope.columns.forEach(function (column) {
                        column.forEach(function (coldef) {
                            $scope.tabs[coldef.header] = coldef;
                        });
                    });
                }

                updateModeColumns();

                heatmapEventBindings.push(heatmap.on('mode updated', updateModeColumns));

                const formatters = {
                    host_mem_total: function (val) {
                        return valueFormatter.formatValue(val * 1024, 2, true);
                    },
                };

                $scope.format = function (property) {
                    if (!$scope.selection) {
                        return;
                    }
                    if (formatters[property]) {
                        return formatters[property]($scope.selection[property]);
                    } else {
                        return $scope.selection[property];
                    }
                };

                function initializeProperties() {
                    if (!$scope.selection) return;
                    const allowedProperties = {};
                    if ($scope.tabs.AWS) {
                        $scope.tabs.AWS.properties = [];

                        Object.keys($scope.selection).forEach(function (property) {
                            if (property.match(/^aws_/)) {
                                $scope.tabs.AWS.properties.push(property);
                            }
                        });
                    }

                    if ($scope.tabs.Other) {
                        $scope.tabs.Other.properties = [];
                    }

                    if ($scope.tabs.OS) {
                        $scope.tabs.OS.properties = $scope.tabs.OS.properties.filter(function (f) {
                            return f !== 'host_linux_version';
                        });
                        if ($scope.selection.host_kernel_name === 'Linux') {
                            $scope.tabs.OS.properties.push('host_linux_version');
                        }
                    }

                    Object.keys($scope.tabs).forEach(function (tab) {
                        if (tab !== 'Other' && $scope.tabs[tab]) {
                            $scope.tabs[tab].properties.forEach(function (property) {
                                allowedProperties[property] = true;
                            });
                        }
                    });

                    $scope.tabs.Other.properties = Object.keys($scope.selection).filter(function (
                        property
                    ) {
                        if (allowedProperties[property]) return false;
                        return (
                            !(
                                property.match(/^(sf_|_)/) ||
                                PROPERTY_DENYLIST.indexOf(property) !== -1
                            ) || property === 'sf_host_interfaces'
                        );
                    });
                }
                initializeProperties();

                $scope.$on('$destroy', function () {
                    heatmapEventBindings.forEach(function (unbind) {
                        unbind();
                    });
                });
            },
        };
    },
]);
