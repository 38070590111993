angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Total Disk I/O Ops')
                .type('column')
                .forcedResolution(30000)
                .yAxis(0, { label: 'writes/s - BLUE' })
                .yAxis(1, { label: 'reads/s - RED' });

            const writePlot = chart
                .plot()
                .metric('disk_ops.write')
                .color('#0077c2')
                .yAxis(0)
                .propertyFilter('plugin', 'disk')
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asAggregation());

            // just a clone of the previous with some modifications
            writePlot.cloneTo(chart).metric('disk_ops.read').color('#b04600').yAxis(1);

            return chart;
        }

        chartTemplates.register('collectdHostsTotalDiskIOOps', createChart);
    },
]);
