import templateUrl from './outlierStrategyPicker.tpl.html';

angular
    .module('signalview.heatmap')
    .constant('FETCH_THRESHOLD_VALUE', function (host, data, groupBy) {
        let populationTracker = data;
        for (let x = 0; x < groupBy.length; x++) {
            const gbk = groupBy[x];
            //note this code will follow the parent's coloring scheme if a subgroup is added.  this is temporary until we
            //choose the UX we want around compute-by vs visualize-by
            if (host && host[gbk] && populationTracker && populationTracker[host[gbk]]) {
                populationTracker = populationTracker[host[gbk]];
            }
        }
        return typeof populationTracker === 'number' ? populationTracker : null;
    })
    .service('HEATMAP_THRESHOLD', [
        'FETCH_THRESHOLD_VALUE',
        'colorAccessibilityService',
        function (FETCH_THRESHOLD_VALUE, colorAccessibilityService) {
            function thresholdRenderer(host, data, groupBy) {
                const colorPalette = colorAccessibilityService.get().getHeatmapColors();
                if (host._hasNoData) {
                    return '#FFFFFF';
                } else if (host._isDead) {
                    return '#444444';
                }

                const tsval = FETCH_THRESHOLD_VALUE(host, data, groupBy);

                if (tsval !== null) {
                    return host.value <= tsval
                        ? colorPalette[0]
                        : colorPalette[colorPalette.length - 1];
                } else {
                    return '#FFFFFF';
                }
            }

            function getHostLevelGroupings(currentGroups, hostKeys) {
                if (!currentGroups) {
                    currentGroups = [];
                }

                if (!hostKeys) {
                    hostKeys = [];
                }

                return currentGroups.concat(hostKeys).join('", "');
            }
            return [
                {
                    displayName: 'Off',
                    refName: 'off',
                    signalflow: function () {
                        return null;
                    },
                    renderer: null,
                },
                {
                    displayName: 'Deviation from Mean',
                    refName: 'deviationFromMean',
                    signalflow: function (varName, groupings, keyProperties) {
                        let groupBy = '';
                        if (groupings && groupings.length) {
                            groupBy = 'by=["' + groupings.join('", "') + '"]';
                        }

                        const groupByWithHost =
                            'by=["' + getHostLevelGroupings(groupings, keyProperties) + '"]';

                        let text = '';
                        text += 'GROUPED_DATA = (' + varName + ').mean(' + groupByWithHost + ')\n';
                        text += 'MEAN = (GROUPED_DATA).mean(' + groupBy + ')\n';
                        text += 'STDDEV = (GROUPED_DATA).stddev(' + groupBy + ')\n';
                        text += '(MEAN + 3 * STDDEV).publish(label="outlier threshold")';
                        return text;
                    },
                    renderer: thresholdRenderer,
                    description: 'Highlight hosts that exceed the Mean + 3 Standard Deviations.',
                },
                {
                    displayName: 'Deviation from Median',
                    refName: 'deviationFromMedian',
                    signalflow: function (varName, groupings, keyProperties) {
                        let groupBy = '';
                        if (groupings && groupings.length) {
                            groupBy = 'by=["' + groupings.join('", "') + '"]';
                        }

                        const groupByWithHost =
                            'by=["' + getHostLevelGroupings(groupings, keyProperties) + '"]';

                        let text = '';
                        text += 'GROUPED_DATA = (' + varName + ').mean(' + groupByWithHost + ')\n';
                        text +=
                            'MEDIAN = (GROUPED_DATA).percentile(pct=50' +
                            (groupBy ? ', ' + groupBy : '') +
                            ')\n';
                        text +=
                            'MEDIAN_ABS_DEV = (GROUPED_DATA - MEDIAN).abs().percentile(pct=50' +
                            (groupBy ? ', ' + groupBy : '') +
                            ')\n';
                        text += '(MEDIAN + 3 * MEDIAN_ABS_DEV).publish(label="outlier threshold")';
                        return text;
                    },
                    renderer: thresholdRenderer,
                    description:
                        'Highlight hosts that exceed the Median + 3 times the Median Absolute Deviation.   <br><br>The Median Absolute Deviation is the median of the absolute values of the deviations from the median.',
                },
            ];
        },
    ])
    .directive('outlierStrategyPicker', [
        '$timeout',
        'HEATMAP_THRESHOLD',
        function ($timeout, HEATMAP_THRESHOLD) {
            return {
                restrict: 'E',
                require: '^^groupableHeatmap',
                scope: {
                    activityState: '=',
                },
                templateUrl,
                link: function ($scope, element, attrs, heatmap) {
                    const OFF_STATE = 'off';
                    $scope.activityState = false;
                    let lastSelectedItem = null;
                    const selector = ($scope.selector = {});

                    selector.inputVisible = false;
                    selector.showInput = function () {
                        selector.inputVisible = true;

                        $timeout(function () {
                            element.find('input').focus();
                        }, 100);
                    };

                    function updateActivityState($event, threshold) {
                        selector.selected = threshold;
                        $scope.activityState = threshold.refName !== OFF_STATE;
                    }

                    selector.suggestions = null;
                    selector.highlighted = null;
                    selector.query = null;
                    updateActivityState(null, heatmap.heatmap.threshold());

                    selector.select = function (item) {
                        selector.query = null;
                        selector.selected = item;
                        selector.inputVisible = false;
                    };

                    heatmap.heatmap.on('threshold updated', updateActivityState);

                    function updateThreshold() {
                        let threshold;

                        if (selector.selected) {
                            threshold = selector.selected;

                            if (threshold.refName !== OFF_STATE) {
                                lastSelectedItem = threshold;
                            }
                            heatmap.heatmap.threshold(threshold);
                        }
                    }

                    function activate() {
                        $scope.selector.selected = lastSelectedItem || HEATMAP_THRESHOLD[1];
                    }

                    function deactivate() {
                        $scope.selector.selected = HEATMAP_THRESHOLD[0];
                    }

                    $scope.$watch('selector.selected', updateThreshold);

                    function updateSuggestions() {
                        selector.suggestions = HEATMAP_THRESHOLD.slice(1);

                        if (selector.query) {
                            selector.highlighted = selector.suggestions[0];
                        } else {
                            selector.highlighted = null;
                        }
                    }

                    $scope.$watch('activityState', function (newValue, oldValue) {
                        if (newValue === oldValue) return;

                        if (newValue) {
                            activate();
                        } else {
                            deactivate();
                        }
                    });
                    $scope.$watch('selector.visible', function (isVisible) {
                        if (isVisible) {
                            updateSuggestions();
                        } else {
                            selector.suggestions = null;
                            selector.highlighted = null;
                            selector.inputVisible = false;
                        }
                    });
                },
            };
        },
    ]);
