/**
 * Chart Overlay
 *
 * This directive is a presentation layer that interacts with custom charts
 * to present and hide overlays based on requests from the charts.
 * Custom chart means a chart in the form of a bundled react component, which is embedded through a chart wrapper (See logsChartWrapper.js).
 *
 * To utilize this feature, when the chart wrapper is selected in chartDisplayToo.tpl.html,
 * the chart wrapper needs receive three props (chart-model, request-overlay-container, remove-overlay-container) like below:
 * ```
 * <div ng-switch-when="new_chart_type">
 *     <custom-chart-container-wrapper
 * ...
 *          chart-model="$ctrl.chartModel"
 *          request-overlay-container="$ctrl.requestOverlayContainer"
 *          remove-overlay-container="$ctrl.removeOverlayContainer"
 *     ></custom-chart-container-wrapper>
 * </div>
 * ```
 */

import { uniqueId } from 'lodash';

import templateUrl from './chartOverlay.tpl.html';

export const CHART_OVERLAY_EVENTS = {
    OVERLAY_REQUESTED: 'request overlay container',
    OVERLAY_CREATED: 'overlay container created',
    OVERLAY_REMOVE_REQUESTED: 'hide overlay container',
    OVERLAY_REMOVED: 'overlay container removed',
};

export const CHART_OVERLAY_MODES = {
    SIDEBAR: 'sidebar',
    POPOVER: 'popover',
};

const POPOVER_MARGIN_TOP = 14;
const SIDEBAR_MAX_WIDTH = 1000;
const POPOVER_MAX_HEIGHT = 800;

function validateOverlayRequest(attributes) {
    if (!attributes) {
        return 'Chart Overlay: Chart attributes are missing';
    }
    if (
        attributes['mode'] !== CHART_OVERLAY_MODES.SIDEBAR &&
        attributes['mode'] !== CHART_OVERLAY_MODES.POPOVER
    ) {
        return 'Chart Overlay: Invalid type. Possible options: sidebar, popover';
    }
}

function generateOverlayDomId(type, chartWrapperId) {
    return `${type}-${chartWrapperId}-${uniqueId()}`;
}

function getOverlayObject(chartWrapperId, attributes, chartRect, parentRect) {
    const overlayObject = {
        wrapperId: chartWrapperId,
        domId: generateOverlayDomId(attributes['mode'], chartWrapperId),
        type: attributes['mode'],
    };

    if (attributes['mode'] === CHART_OVERLAY_MODES.SIDEBAR) {
        if (attributes['width']) {
            overlayObject.width = `${Math.min(attributes['width'], SIDEBAR_MAX_WIDTH)}px`;
        }
    } else if (attributes['mode'] === CHART_OVERLAY_MODES.POPOVER) {
        overlayObject.top = `${
            chartRect.top + chartRect.height - parentRect.top + POPOVER_MARGIN_TOP
        }px`;
        if (attributes['height']) {
            overlayObject.height = `${Math.min(attributes['height'], POPOVER_MAX_HEIGHT)}px`;
        }
    }

    return overlayObject;
}

function removeFromOverlaysIfExists(overlayObjects, chartWrapperId, mode) {
    for (let i = 0; i < overlayObjects.length; i++) {
        if (overlayObjects[i].wrapperId === chartWrapperId && overlayObjects[i].type === mode) {
            overlayObjects.splice(i);
            return true;
        }
    }
    return false;
}

function sortOverlayObjects(overlayA, overlayB) {
    if (overlayA.wrapperId > overlayB.wrapperId) {
        return -1;
    } else if (overlayA.wrapperId < overlayB.wrapperId) {
        return 1;
    }
    if (overlayA.wrapperId === overlayB.wrapperId) {
        if (overlayA.type === CHART_OVERLAY_MODES.POPOVER) {
            return -1;
        } else {
            return 1;
        }
    }
    return 0;
}

export const chartOverlay = [
    '$timeout',
    '$log',
    function ($timeout, $log) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl,
            link: function ($scope, element) {
                $scope.overlayObjects = [];

                $scope.$on(
                    CHART_OVERLAY_EVENTS.OVERLAY_REQUESTED,
                    function (evt, requestedBy, chartWrapperId, attributes, chartElem) {
                        const validationError = validateOverlayRequest(attributes);
                        if (validationError) {
                            $log.warn(validationError);
                            return;
                        }

                        const chartRect = chartElem.getClientRects()[0];
                        const parentRect = element.parent()[0].getClientRects()[0];
                        const overlayObject = getOverlayObject(
                            chartWrapperId,
                            attributes,
                            chartRect,
                            parentRect
                        );

                        const newOverlayObjects = [overlayObject];
                        const overlayObjectsToRemove = [];
                        $scope.overlayObjects.forEach((oldOverlayObject) => {
                            // Only one chart can present overlays.
                            // For that one chart, one overlay per each mode (popver, sidebar) can be shown.
                            if (
                                oldOverlayObject.wrapperId === overlayObject.wrapperId &&
                                oldOverlayObject.type !== overlayObject.type
                            ) {
                                newOverlayObjects.push(oldOverlayObject);
                            } else {
                                overlayObjectsToRemove.push(oldOverlayObject);
                            }
                        });
                        $scope.overlayObjects = newOverlayObjects.sort(sortOverlayObjects);
                        overlayObjectsToRemove.forEach((overlayObjectToRemove) => {
                            $scope.$broadcast(
                                CHART_OVERLAY_EVENTS.OVERLAY_REMOVED,
                                overlayObjectToRemove.wrapperId,
                                overlayObjectToRemove.type
                            );
                        });

                        $timeout(() => {
                            $scope.$apply();
                            requestedBy.$emit(CHART_OVERLAY_EVENTS.OVERLAY_CREATED, overlayObject);
                        });
                    }
                );

                $scope.$on(
                    CHART_OVERLAY_EVENTS.OVERLAY_REMOVE_REQUESTED,
                    function (evt, wrapperIdToRemove, modeToRemove) {
                        if (
                            removeFromOverlaysIfExists(
                                $scope.overlayObjects,
                                wrapperIdToRemove,
                                modeToRemove
                            )
                        ) {
                            $scope.$broadcast(
                                CHART_OVERLAY_EVENTS.OVERLAY_REMOVED,
                                wrapperIdToRemove,
                                modeToRemove
                            );
                            $scope.$applyAsync();
                        }
                    }
                );
            },
        };
    },
];
