export const alertMutingApiService = [
    '$http',
    'API_URL',
    'currentUser',
    function ($http, API_URL, currentUser) {
        const url = API_URL + '/v2/alertmuting';

        return {
            create,
            update,
            delete: requestDelete,
            unmute,
            search,
            getCurrent,
        };

        function getQueryParams(organizationId, resolveMatchingActiveAlerts) {
            const params = Object.entries({ organizationId, resolveMatchingActiveAlerts })
                .filter(([, value]) => typeof value !== 'undefined')
                .map(([key, value]) => `${key}=${String(value)}`);

            return `?${params.join('&')}`;
        }

        function create(param, resolveMatchingActiveAlerts) {
            return currentUser.orgId().then(function (orgId) {
                return $http.post(url + getQueryParams(orgId, resolveMatchingActiveAlerts), param);
            });
        }

        function update(id, param, resolveMatchingActiveAlerts) {
            return currentUser.orgId().then(function (orgId) {
                return $http.put(
                    url + '/' + id + getQueryParams(orgId, resolveMatchingActiveAlerts),
                    param
                );
            });
        }

        function requestDelete(id) {
            return currentUser.orgId().then(function (orgId) {
                return $http.delete(url + '/' + id + '?organizationId=' + orgId);
            });
        }

        function search(params) {
            return currentUser.orgId().then(function (orgId) {
                return $http.get(url + '?organizationId=' + orgId, { params });
            });
        }

        function unmute(id) {
            return currentUser.orgId().then(function (orgId) {
                return $http.put(url + '/' + id + '/unmute?organizationId=' + orgId, {});
            });
        }

        function getCurrent() {
            return $http.get(url + '/getCurrent');
        }
    },
];
