import { react2angular } from 'react2angular';
import ngSanitize from '@splunk/olly-angular-sanitize';
import ChartNameModalController from './ChartNameModalController.js';
import chartInfoPane from './chartInfoPane';
import chartSettingsModal from './chartSettingsModal';
import setUrlParamsForSnapshot from './setUrlParamsForSnapshot';
import { CHART_CONSTANTS } from './util/chartConstants';
import { ArchivedMetricChip } from '../../../../common/ui/archived-metrics/ArchivedMetricChip';

angular
    .module('signalview.chartbuilder', [
        'signalboost',
        ngSanitize,
        'signalview.analytics',
        'chartbuilderUtil',
        'signalview.urlOverrides',
        'signalview.dashboard',
        'signalview.instrumentation',
        'sfx.data',
        'sfx.ui',
        'signalview.analytics',
        'signalview.objectbrowser',
        'sfx.debug',
        'signalview.sfGlobalNav',
        'sfx.filterControls',
        'signalview.colorPicker',
        'signalview.appNotifications',
        'signalview.awsMetaData',
        'signalview.azureMetaData',
        'signalview.chartdisplay.shared',
        'signalview.snapshotSharing',
        'signalview.metricsSidebar',
        'signalflowv2',
        'sfx.api.v2',
        'signalview.variables',
        'signalview.jobFeedback',
        'signalview.TOOLTIPS',
        'signalview.common.tracing',
    ])
    .controller('ChartNameModalController', ChartNameModalController)
    .component('chartInfoPane', chartInfoPane)
    .component('archivedMetricChip', react2angular(ArchivedMetricChip))
    .service('chartSettingsModal', chartSettingsModal)
    .service('setUrlParamsForSnapshot', setUrlParamsForSnapshot)
    .constant('CALENDAR_MESSAGE', { NONE: '', OPTIMIZE: 'Optimize', RESET: 'Reset' })
    .constant('CHART_CONSTANTS', CHART_CONSTANTS);
