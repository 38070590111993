'use strict';

import templateUrl from './block.html';

angular.module('sfx.ui').directive('sfxBlock', [
    function () {
        return {
            restrict: 'E',
            scope: {
                label: '=',
                removable: '=',
                remove: '&onRemove',
                iconType: '@',
            },
            templateUrl,
        };
    },
]);
