import templateUrl from './legacyConditionLabel.tpl.html';
angular.module('signalview.detector.rule').directive('legacyConditionLabel', [
    '$sce',
    function ($sce) {
        return {
            restrict: 'E',
            scope: {
                metadata: '=',
            },
            templateUrl,
            link: function ($scope) {
                let tooltip = $scope.metadata.description;
                if ($scope.metadata.infoUrl) {
                    tooltip +=
                        '<a target="_blank" href="' + $scope.metadata.infoUrl + '"> Details</a>';
                }
                if (tooltip) {
                    $scope.tooltipText = $sce.trustAsHtml(tooltip);
                }
            },
        };
    },
]);
