angular.module('chartbuilderUtil').factory('programTextUtils', [
    'plotUtils',
    'analyticsService',
    '$log',
    'SAMPLE_CONSTANTS',
    'uiModelToSignalflowV2',
    function (plotUtils, analyticsService, $log, SAMPLE_CONSTANTS, uiModelToSignalflowV2) {
        const EXPRESSION_KEY_EXP = /[a-zA-Z]+/g;
        const CHAINED_TIMESHIFT_DEPTH_CHARACTER = 'X';

        function noExpressionLoops(plots) {
            return plots.every(function (plot) {
                return isAcyclicGraph(plot, plots, null);
            });
        }

        function isAcyclicGraph(root, plots, parentPlotUsageMap) {
            // any recursive expression query only cares about anything used thus far and not what
            // other recursive walks found.  copy the expressionMap and pass it along.
            let noLoop = true;
            if (root.type === 'plot') {
                return noLoop;
            } else if (root.type === 'ratio') {
                const expressionMap = angular.copy(parentPlotUsageMap) || {};
                expressionMap[root.uniqueKey] = true;
                const keys = plotUtils.getAllExpressionKeys(root.expressionText);
                const keyToUKMap = plotUtils.getPlotExpressionKeyToUKMap(plots);
                const keyArr = [];
                angular.forEach(keys, function (uselessbool, letter) {
                    keyArr.push(keyToUKMap[letter]);
                });
                angular.forEach(plots, function (plot) {
                    if (
                        plot.type === 'ratio' &&
                        plot.uniqueKey !== root.uniqueKey &&
                        keyArr.indexOf(plot.uniqueKey) !== -1
                    ) {
                        if (!expressionMap[plot.uniqueKey]) {
                            noLoop = noLoop && isAcyclicGraph(plot, plots, expressionMap);
                        } else {
                            noLoop = false;
                        }
                    }
                });
            }
            return noLoop;
        }

        function areAllExpressionsValid(plots) {
            return !plots.some(function (targetPlot) {
                if (targetPlot.type !== 'ratio' || targetPlot.transient) {
                    return false;
                }
                return isExpressionInvalid(targetPlot.expressionText, plots, targetPlot.uniqueKey);
            });
        }

        // WIP : verify the the expression is valid
        // check for loops, non-existent variable names, parenthesis , etc
        // currently fails to detect poor arithmetic syntax such as 'A+B+'
        function isExpressionInvalid(expression, inboundPlots, expressionkey) {
            if (!expression) {
                return ['No expression found'];
            }

            // Create a duplicate plot list with the proposed expression changes in place
            const plots = angular.copy(inboundPlots);
            plots.forEach(function (plot) {
                if (plot.uniqueKey === expressionkey) {
                    plot.expressionText = expression;
                }
            });

            const errMsgs = [];
            const operators = ['+', '/', '-', '*'];

            const expressionKeys = expression.match(EXPRESSION_KEY_EXP) || [];
            const expressionUniqueKeys = expressionKeys.map(function (key) {
                return plotUtils.getUniqueKeyFromLetter(key.toUpperCase());
            });

            // Check for self reference
            const hasSelfReference = expressionUniqueKeys.some(function (uniqueKey) {
                return uniqueKey === expressionkey;
            });
            if (hasSelfReference) {
                errMsgs.push(
                    'Expressions may not reference themselves : ' +
                        plotUtils.getLetterFromUniqueKey(expressionkey) +
                        '.'
                );
            }

            // Ensure plot keys used are valid
            const allPlotKeys = plotUtils.getAllPlotUniqueKeys(plots);
            const invalidPlotKeys = expressionUniqueKeys.filter(function (uniqueKey) {
                return (
                    angular.isUndefined(uniqueKey) || angular.isUndefined(allPlotKeys[uniqueKey])
                );
            });
            if (invalidPlotKeys.length) {
                errMsgs.push(
                    'Invalid plot key used : ' +
                        invalidPlotKeys.map(plotUtils.getLetterFromUniqueKey).join(', ') +
                        '.'
                );
            }

            // Ensure symbols used are valid
            const invalidSymbols = expression.split(/['+\/*\-() ']/).filter(function (i) {
                return isNaN(i) && expressionKeys.indexOf(i) === -1;
            });
            if (invalidSymbols.length) {
                errMsgs.push('Unrecognized symbols: ' + invalidSymbols.join(', ') + '.');
            }

            if (expression.split(/['+\/*\-'](' ')?['+\/*)']/).length > 1) {
                errMsgs.push('Operator without operand detected.');
            }

            //not the best check...
            if (operators.indexOf(expression.trim().charAt(expression.length - 1)) !== -1) {
                errMsgs.push('Dangling operator detected.');
            }

            // Ensure parentheses match
            let openParens = 0;
            const invalidParensIndices = [];
            if (typeof expression === 'string') {
                for (let x = 0; x < expression.length; x++) {
                    if (expression.charAt(x) === '(') {
                        openParens++;
                    } else if (expression.charAt(x) === ')') {
                        openParens--;

                        if (openParens < 0) {
                            invalidParensIndices.push(x + 1);
                        }
                    }
                }
            }
            if (invalidParensIndices.length) {
                errMsgs.push(
                    'Parenthesis mismatched at indices ' + invalidParensIndices.join(', ') + '.'
                );
            }

            if (openParens !== 0) {
                errMsgs.push('Unopened or unclosed parenthesis pair.');
            }

            // Ensure no looping behavior
            if (!noExpressionLoops(plots)) {
                errMsgs.push('Loops not allowed.');
            }

            return errMsgs.length === 0 ? false : errMsgs;
        }

        function generateTimeShiftedSourcesForPlot(
            modelId,
            revisionNumber,
            plot,
            parentoffset,
            parentkey,
            ids
        ) {
            const itm = angular.copy(plot);
            let hasOffset = false;
            angular.forEach(itm.dataManipulations, function (manip) {
                if (manip.fn && manip.fn.options && manip.fn.options.milliseconds) {
                    hasOffset = true;
                    manip.fn.options.milliseconds += parentoffset;
                }
            });
            if (!hasOffset) {
                itm.dataManipulations.push({
                    fn: {
                        type: 'TIMESHIFT',
                        options: {
                            milliseconds: parentoffset,
                        },
                    },
                    direction: {
                        options: {
                            aggregateGroupBy: [],
                        },
                        type: 'aggregation',
                    },
                });
            }
            return analyticsService.getPlotText(
                itm,
                modelId,
                revisionNumber,
                true,
                true,
                parentkey,
                false,
                ids
            );
        }

        function generateTimeShiftedSourcesForRatio(
            plots,
            modelId,
            revisionNumber,
            ratio,
            parentoffset,
            parentkey,
            isRoot,
            ids
        ) {
            if (isExpressionInvalid(ratio.expressionText, plots, ratio.uniqueKey)) {
                $log.error('Invalid expression: ' + ratio.expressionText);
            }
            const plotsByUK = {};
            plots.forEach(function (plot) {
                plotsByUK[plot.uniqueKey] = plot;
            });

            if (!isRoot) {
                return getRatioText(
                    plots,
                    modelId,
                    revisionNumber,
                    ratio,
                    parentoffset,
                    true,
                    parentkey,
                    isRoot,
                    ids,
                    true
                );
            } else {
                const keys = plotUtils.getAllExpressionKeys(ratio.expressionText);
                const EKtoUK = plotUtils.getPlotExpressionKeyToUKMap(plots);

                return Object.keys(keys)
                    .map(function (EK) {
                        const plot = plotsByUK[EKtoUK[EK]];

                        // make a copy of all constituent plots as necessary if we need to get a chain-timeshifted
                        // expression's output.
                        // recursively walks the usage tree and keeps a running tally of the offsets needed.
                        // will cause "excessive" fetches as the code is not smart enough to re-use pre-generated
                        // expressions that do not need recalculation
                        if (plot.type === 'plot') {
                            return generateTimeShiftedSourcesForPlot(
                                modelId,
                                revisionNumber,
                                plot,
                                parentoffset,
                                parentkey,
                                ids
                            );
                        } else if (plot.type === 'ratio') {
                            return generateTimeShiftedSourcesForRatio(
                                plots,
                                modelId,
                                revisionNumber,
                                plot,
                                parentoffset,
                                parentkey,
                                false,
                                ids
                            );
                        } else {
                            return '';
                        }
                    })
                    .join(';');
            }
        }

        function getRatioText(
            plots,
            modelId,
            revisionNumber,
            ratio,
            parentoffset,
            shiftMode,
            parentkey,
            throttle,
            ids,
            skipPublish
        ) {
            //note that shiftMode is a superset of skipPublish

            if (isExpressionInvalid(ratio.expressionText, plots, ratio.uniqueKey)) {
                $log.error('Attempted to generate expression signalflow on an invalid expression');
            }

            let plotName;

            if (shiftMode) {
                plotName = analyticsService.getPlotNameForChainedTimeshift(
                    ratio.uniqueKey,
                    modelId,
                    revisionNumber,
                    parentkey
                );
            } else {
                plotName = analyticsService.getPlotName(ratio.uniqueKey, modelId, revisionNumber);
            }

            ids.push(plotName + '=id(report=1)');

            const EKtoUK = plotUtils.getPlotExpressionKeyToUKMap(plots);
            let timeshifttext = '';
            let offset = parentoffset || 0;

            ratio.dataManipulations.forEach(function (manip) {
                const type = manip.fn.type;
                if (type === 'TIMESHIFT' && manip.fn.options.milliseconds) {
                    offset += manip.fn.options.milliseconds;
                } else if (type === 'TOPN' || type === 'BOTTOMN') {
                    //Disable throttling if a select block is being used due to how SAMPLE works.
                    throttle = false;
                }
            });

            let finalKey = parentkey || ratio.uniqueKey;

            if (offset > 0) {
                // every time we chain a ratio's timeshift, append a character to ensure uniqueness against previous
                finalKey = finalKey.toString() + CHAINED_TIMESHIFT_DEPTH_CHARACTER;
                // if expression or its constituent expressions has a timeshift, then propagate that
                // through all usages and create duplicate timeshifted sflow paths to emulate
                // the timeshift block
                timeshifttext = generateTimeShiftedSourcesForRatio(
                    plots,
                    modelId,
                    revisionNumber,
                    ratio,
                    offset,
                    finalKey,
                    true,
                    ids
                );
            }

            const macroBlockName = plotName + '_MACROBLOCK';

            const keys = plotUtils.getAllExpressionKeys(ratio.expressionText);
            const publishKey = analyticsService.getPublishKey(modelId, revisionNumber);
            let macroExpr = '';
            const manips = ratio.dataManipulations;
            let anonymousMacro = '';
            let sbjt = [];

            const allKeyIdentifiers = [];

            angular.forEach(keys, function (whatever, key) {
                allKeyIdentifiers.push('?' + key);
            });

            allKeyIdentifiers.push('!OUT');

            anonymousMacro += macroBlockName + '=';
            anonymousMacro += '[' + allKeyIdentifiers.join(',') + ']';
            anonymousMacro += '{';

            let currentKey = '';
            for (let n = 0; n < ratio.expressionText.length; n++) {
                let isOperator = false;
                if (ratio.expressionText.charAt(n).match(/[a-zA-Z]/)) {
                    if (!currentKey) {
                        macroExpr += '?';
                    }
                    currentKey += ratio.expressionText.charAt(n);
                } else {
                    currentKey = '';
                    isOperator = ratio.expressionText.charAt(n).match(/[-\/*+]/);
                }
                if (isOperator) {
                    macroExpr += ' ';
                }
                macroExpr += ratio.expressionText.charAt(n);
                // FIXME : temporary until we decide how to "fix" our parser to figure out operators
                // if the current character is an operator and
                // AND (
                // the next character is NOT an operator or number        A*-1 --> A * -1
                // OR                                                      ^
                // the previous character is NOT an operator A-1 --> A - 1
                // )                                          ^
                // add a trailing space
                if (
                    isOperator &&
                    (!ratio.expressionText.charAt(n + 1).match(/[-\/*+0-9]/) ||
                        !ratio.expressionText.charAt(n - 1).match(/[-\/*+]/))
                ) {
                    macroExpr += ' ';
                }
            }
            macroExpr += '->!OUT';

            macroExpr = macroExpr.toUpperCase();

            anonymousMacro += macroExpr;

            anonymousMacro += '}';
            ids.push(anonymousMacro);

            sbjt.push(macroBlockName + ':!OUT');

            const dataManipulationOutput = analyticsService.generateDataManipulationText(
                manips,
                analyticsService.getPlotName(ratio.uniqueKey, modelId, revisionNumber)
            );
            sbjt = sbjt.concat(dataManipulationOutput);

            let ptext = '';

            if (timeshifttext) {
                ptext += timeshifttext + ';';
            }

            if (sbjt.length > 0) {
                ptext += sbjt.join('->');
            }

            ptext += '->' + plotName;
            if (!shiftMode) {
                if (throttle) {
                    ptext += '->groupby() -> sample(' + throttle + ') -> split';
                } else {
                    ptext += '->groupby() -> split';
                }

                if (!skipPublish) {
                    ptext +=
                        "->publish(metric='" +
                        plotName +
                        "',sf_uiAnalyticsDerivedMetric=1,sf_uiHelper='" +
                        publishKey +
                        "')";
                }
            }

            angular.forEach(keys, function (v, key) {
                const uniqueKey = EKtoUK[key];

                if (angular.isDefined(uniqueKey)) {
                    ptext += ';';

                    if (offset > 0) {
                        ptext += analyticsService.getPlotNameForChainedTimeshift(
                            uniqueKey,
                            modelId,
                            revisionNumber,
                            finalKey
                        );
                    } else {
                        ptext += analyticsService.getPlotName(uniqueKey, modelId, revisionNumber);
                    }

                    ptext += '->?' + key + ':' + macroBlockName;
                } else {
                    $log.error('ERROR COULDNT FIND PLOT FOR ' + key);
                }
            });

            return ptext;
        }

        // NOTE: use getV2ProgramTextWithOptions instead so that we can eventually
        // get rid of this signature and won't have invocations of this function like
        // with six nulls before the option they are interested in.
        function getV2ProgramText(
            uiModel,
            skipPublishInvisible,
            generateRules,
            plotsToSkipPublish,
            countDetectEventsBy,
            annotateThreshold,
            includeEvents,
            plotsToExclude
        ) {
            const plots = (uiModel.allPlots || []).filter((p) => !p.incomplete);
            let rules = [];
            if (generateRules) {
                rules = uiModel.rules || [];
            }
            const params = {
                plots: plots,
                rules: rules,
                plotsToSkipPublish: plotsToSkipPublish,
                countDetectEventsBy: countDetectEventsBy,
                annotateThreshold: annotateThreshold,
                plotsToExclude: plotsToExclude,
            };

            if (includeEvents) {
                params.includeEvents = includeEvents;
            }
            try {
                return uiModelToSignalflowV2.generateText(params, skipPublishInvisible, true);
            } catch (e) {
                $log.error('Unable to generate SignalFlow from uiModel! ' + e);
                return null;
            }
        }

        function getV2ProgramTextWithOptions(uiModel, options = {}) {
            return getV2ProgramText(
                uiModel,
                options.skipPublishInvisible,
                options.generateRules,
                options.plotsToSkipPublish,
                options.countDetectEventsBy,
                options.annotateThreshold,
                options.includeEvents,
                options.plotsToExclude
            );
        }

        function getV2ProgramTextPublishRuleCountDetectEventsBy(plots, rules, countDetectEventsBy) {
            const params = {
                plots: plots,
                rules: rules,
                plotsToSkipPublish: plots.map(function (plot) {
                    return plot.uniqueKey;
                }),
                countDetectEventsBy: countDetectEventsBy,
                annotateThreshold: false,
            };
            return uiModelToSignalflowV2.generateText(params, true, true);
        }

        function getV2ProgramTextPublishRuleAnnotateThreshold(plots, rules) {
            const params = {
                plots: plots,
                rules: rules.filter(
                    (rule) => (rule.type || rule.thresholdMode || rule.conditions) && !rule.invalid
                ),
                plotsToSkipPublish: plots
                    .filter((plot) => !plot.uniqueKey || plot.invisible)
                    .map((plot) => plot.uniqueKey),
                countDetectEventsBy: null,
                annotateThreshold: true,
            };
            return uiModelToSignalflowV2.generateText(params, true, true);
        }

        function getProgramText(plots, modelId, revisionNumber, plotsToSkipPublish, throttle) {
            plotsToSkipPublish = plotsToSkipPublish || [];

            const statements = [];
            const invalidPlot = {};

            // track all id's in generated program text so they can be declared up front.
            // this prevents out of order reference behaviors when expressions reference a plot
            // that is to be processed after the expression itself
            let allIds = [];
            plots.forEach(function (plot) {
                if (plot.transient) return;

                let statement;
                const skipPublish = plotsToSkipPublish.indexOf(plot.uniqueKey) !== -1;
                if (plot.type === 'plot') {
                    statement = analyticsService.getPlotText(
                        plot,
                        modelId,
                        revisionNumber,
                        skipPublish,
                        null,
                        null,
                        throttle,
                        allIds
                    );
                } else if (plot.type === 'ratio' && !invalidPlot[plot.sourceKeyId]) {
                    statement = getRatioText(
                        plots,
                        modelId,
                        revisionNumber,
                        plot,
                        null,
                        null,
                        null,
                        throttle,
                        allIds,
                        skipPublish
                    );
                }

                if (statement) {
                    statements.push(statement);
                } else {
                    invalidPlot[plot.uniqueKey] = true;
                }
            });
            const idMap = {};
            //deduplicate
            allIds = allIds.filter(function (decl) {
                if (!idMap[decl]) {
                    idMap[decl] = true;
                    return true;
                } else {
                    return false;
                }
            });
            return allIds.concat(statements).join(';');
        }

        function getPlotsProgramText(model, plotsToSkipPublish, throttle) {
            return getProgramText(
                model.sf_uiModel.allPlots,
                model.sf_id,
                model.sf_uiModel.revisionNumber,
                plotsToSkipPublish,
                throttle
            );
        }

        function generateRatioText(
            uiModel,
            modelId,
            ratio,
            parentoffset,
            shiftMode,
            parentkey,
            throttle,
            ids,
            skipPublish
        ) {
            return getRatioText(
                uiModel.allPlots,
                modelId,
                uiModel.revisionNumber,
                ratio,
                parentoffset,
                shiftMode,
                parentkey,
                throttle,
                ids,
                skipPublish
            );
        }

        function refreshDetectorProgramTextV2(model, optionOverrides) {
            let rules = model.sf_uiModel.rules || [];
            const plots = model.sf_uiModel.allPlots || [];
            const plotsToSkipPublish = plots.map(function (plot) {
                return plot.uniqueKey;
            });
            rules = rules.filter(function (rule) {
                return !rule.invalid && rule.thresholdMode;
            });
            const defaultProgramOptions = {
                skipPublishInvisible: true,
                generateRules: true,
                plotsToSkipPublish,
                countDetectEventsBy: false,
                annotateThreshold: true,
            };
            const providedOptions = optionOverrides || {};
            model.sf_programText = getV2ProgramTextWithOptions(
                { allPlots: plots, rules: rules },
                { ...defaultProgramOptions, ...providedOptions }
            );
            model.sf_viewProgramText = getV2ProgramTextWithOptions(
                { allPlots: plots, rules: rules },
                { ...defaultProgramOptions, plotsToSkipPublish: null, ...providedOptions }
            );
            return {
                programText: model.sf_programText,
                viewProgramText: model.sf_viewProgramText,
                throttledProgramText: null,
            };
        }

        function refreshProgramTextChartBuilderV1(model) {
            model.sf_programText = getV2ProgramText(
                model.sf_uiModel,
                false,
                false,
                [],
                false,
                false,
                true
            );
            delete model.sf_throttledProgramText;
            model.sf_viewProgramText = getV2ProgramText(
                model.sf_uiModel,
                false,
                false,
                [],
                false,
                false,
                true
            );
            if (
                !model.sf_programText &&
                (model.sf_uiModel.chartMode === 'text' || model.sf_uiModel.chartMode === 'event')
            ) {
                // empty program text disallowed
                // don't delete this by default becase we want to support
                // programs at some point and feed it into chart markdown
                delete model.sf_programText;
            }
        }

        function refreshProgramText(model, detectorOptionOverrides) {
            if (model.sf_type === 'Detector' && model.sf_signalflowVersion === 2) {
                return refreshDetectorProgramTextV2(model, detectorOptionOverrides);
            }
            if (!model.sf_flowVersion) {
                const invisiblePlotUniqueKeys = plotUtils.getInvisiblePlotUniqueKeys(model);
                let programText = getPlotsProgramText(model, invisiblePlotUniqueKeys, null);
                // TODO remove, let caller set this
                model.sf_programText = programText;
                let throttledProgramText;
                // TODO remove
                delete model.sf_throttledProgramText;
                const viewProgramText = getPlotsProgramText(
                    model,
                    [],
                    SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE
                );
                // TODO remove
                model.sf_viewProgramText = viewProgramText;
                if (
                    !programText &&
                    (model.sf_uiModel.chartMode === 'text' ||
                        model.sf_uiModel.chartMode === 'event')
                ) {
                    // empty program text disallowed
                    // don't delete this by default becase we want to support
                    // programs at some point and feed it into chart markdown
                    programText = null;
                    // TODO remove
                    delete model.sf_programText;
                }
                return {
                    programText: programText,
                    viewProgramText: viewProgramText,
                    throttledProgramText: throttledProgramText,
                };
            } else {
                return null;
            }
        }

        function getSLOProgramText(programText, goodEventsLabel, totalEventsLabel) {
            return [
                programText,
                `(${totalEventsLabel}.sum() - ${goodEventsLabel}.sum()).publish('bad')`,
                `${goodEventsLabel}.sum().publish('good')`,
            ].join('\n');
        }

        return {
            noExpressionLoops: noExpressionLoops,
            areAllExpressionsValid: areAllExpressionsValid,
            isExpressionInvalid: isExpressionInvalid,
            generateRatioText: generateRatioText,
            getPlotsProgramText: getPlotsProgramText,
            refreshDetectorProgramTextV2: refreshDetectorProgramTextV2,
            refreshProgramText: refreshProgramText,
            refreshProgramTextChartBuilderV1: refreshProgramTextChartBuilderV1,
            getProgramText: getProgramText,
            getV2ProgramText: getV2ProgramText,
            getV2ProgramTextWithOptions,
            getV2ProgramTextPublishRuleCountDetectEventsBy:
                getV2ProgramTextPublishRuleCountDetectEventsBy,
            getV2ProgramTextPublishRuleAnnotateThreshold:
                getV2ProgramTextPublishRuleAnnotateThreshold,
            getSLOProgramText: getSLOProgramText,
        };
    },
]);
