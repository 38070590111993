angular.module('signalview.utils').service('clock', [
    function () {
        function now() {
            return new Date();
        }

        function fromString(dateString) {
            return new Date(dateString);
        }

        function isBefore(comparedDate, refDate) {
            return comparedDate.getTime() < refDate.getTime();
        }

        return {
            now,
            fromString,
            isBefore,
        };
    },
]);
