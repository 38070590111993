export const promiseGenerationManager = [
    '$log',
    '$q',
    '$timeout',
    function ($log, $q, $timeout) {
        return managerFactory;

        /*
      Wraps a function that returns a promise in a debouncer and generation
      managing function. Will only resolve if the response belongs
      to the most current request, and reject otherwise.
     */
        function managerFactory(requester, debounce = 0) {
            let numGenerations = 0;
            let passedDeferrals = [];
            let timeoutId;

            const debounced = (givenDeferral, nextGen, ...args) => {
                const localGeneration = nextGen;
                const promise = requester(...args);

                // deferral is pushed in here, to cover case in which the most
                // current request is blocked on debounce when the next most recent
                // promise resolves.
                passedDeferrals.push(givenDeferral);

                promise.then(
                    () => {
                        if (localGeneration !== numGenerations) {
                            $log.log(`Outdated request successful (${requester.name})`);
                            return;
                        }

                        const mostRecentDeferral = passedDeferrals[passedDeferrals.length - 1];
                        mostRecentDeferral.resolve(promise);

                        passedDeferrals = [];
                    },
                    (e) => {
                        if (localGeneration !== numGenerations) {
                            $log.log(`Outdated request rejected (${requester.name})`);
                            return;
                        }

                        const mostRecentDeferral = passedDeferrals[passedDeferrals.length - 1];
                        mostRecentDeferral.reject(e);

                        passedDeferrals = [];
                    }
                );
            };

            return function (...args) {
                const deferral = $q.defer();

                if (timeoutId) {
                    $timeout.cancel(timeoutId);
                }

                // numGenerations is incremented outside of the debounced function to cover
                // the case in which the most current query is blocked in the debounce
                // when a prior request resolves.
                numGenerations++;
                timeoutId = $timeout(() => debounced(deferral, numGenerations, ...args), debounce);

                return deferral.promise;
            };
        }
    },
];
