import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';
import * as _ from 'lodash';

const DEFAULT_TRUNCATE_LENGTH = 160;
const SHOW_MORE_LABEL = 'Show more';
const SHOW_LESS_LABEL = 'Show less';

const className = BEMHelper('show-more');

export default function ShowMore(props) {
    const [isExpanded, setIsExpanded] = useState(false);

    const truncateLength = props.maxLength || DEFAULT_TRUNCATE_LENGTH;
    const isCollapsable = props.text.length > truncateLength;

    let textToDisplay = props.text;

    if (isCollapsable && !isExpanded) {
        textToDisplay = truncateText(props.text);
    }

    return (
        <React.Fragment>
            {renderText()}
            {renderShowMoreButton()}
        </React.Fragment>
    );

    function renderText() {
        if ('function' !== typeof props.textRenderer) {
            return <div>{textToDisplay}</div>;
        }

        return props.textRenderer(textToDisplay);
    }

    function truncateText(text) {
        return _.truncate(text, {
            length: truncateLength,
            separator: /[\,\.\;]? /,
            omission: '...',
        });
    }

    function renderShowMoreButton() {
        if (isCollapsable) {
            const showMoreLessLabel = isExpanded ? SHOW_LESS_LABEL : SHOW_MORE_LABEL;
            return (
                <div className="btn-container">
                    <button
                        {...className('link', [], ['sf-clickable'])}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {showMoreLessLabel}
                    </button>
                </div>
            );
        }
    }
}

ShowMore.propTypes = {
    text: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    textRenderer: PropTypes.func,
};
