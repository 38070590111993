angular.module('signalview.azureMetaData', []).factory('azureMetricInformation', [
    function () {
        //a service that attempts to catalog all known Azure metric names
        //ideally this would be dynamically generated.
        const knownAzureMetrics = {
            'Percentage CPU': true,
            'Network In': true,
            'Network Out': true,
            'Disk Read Bytes/Sec': true,
            'Disk Write Bytes/Sec': true,
            'Disk Read Operations/Sec': true,
            'Disk Write Operations/Sec': true,
            'CPU Credits Remaining': true,
            'CPU Credits Consumed': true,
            CoreCount: true,
            TotalNodeCount: true,
            LowPriorityCoreCount: true,
            TotalLowPriorityNodeCount: true,
            CreatingNodeCount: true,
            StartingNodeCount: true,
            WaitingForStartTaskNodeCount: true,
            StartTaskFailedNodeCount: true,
            IdleNodeCount: true,
            OfflineNodeCount: true,
            RebootingNodeCount: true,
            ReimagingNodeCount: true,
            RunningNodeCount: true,
            LeavingPoolNodeCount: true,
            UnusableNodeCount: true,
            PreemptedNodeCount: true,
            TaskStartEvent: true,
            TaskCompleteEvent: true,
            TaskFailEvent: true,
            PoolCreateEvent: true,
            PoolResizeStartEvent: true,
            PoolResizeCompleteEvent: true,
            PoolDeleteStartEvent: true,
            PoolDeleteCompleteEvent: true,
            connectedclients: true,
            totalcommandsprocessed: true,
            cachehits: true,
            cachemisses: true,
            getcommands: true,
            setcommands: true,
            evictedkeys: true,
            totalkeys: true,
            expiredkeys: true,
            usedmemory: true,
            usedmemoryRss: true,
            serverLoad: true,
            cacheWrite: true,
            cacheRead: true,
            percentProcessorTime: true,
            connectedclients0: true,
            totalcommandsprocessed0: true,
            cachehits0: true,
            cachemisses0: true,
            getcommands0: true,
            setcommands0: true,
            evictedkeys0: true,
            totalkeys0: true,
            expiredkeys0: true,
            usedmemory0: true,
            usedmemoryRss0: true,
            serverLoad0: true,
            cacheWrite0: true,
            cacheRead0: true,
            percentProcessorTime0: true,
            connectedclients1: true,
            totalcommandsprocessed1: true,
            cachehits1: true,
            cachemisses1: true,
            getcommands1: true,
            setcommands1: true,
            evictedkeys1: true,
            totalkeys1: true,
            expiredkeys1: true,
            usedmemory1: true,
            usedmemoryRss1: true,
            serverLoad1: true,
            cacheWrite1: true,
            cacheRead1: true,
            percentProcessorTime1: true,
            connectedclients2: true,
            totalcommandsprocessed2: true,
            cachehits2: true,
            cachemisses2: true,
            getcommands2: true,
            setcommands2: true,
            evictedkeys2: true,
            totalkeys2: true,
            expiredkeys2: true,
            usedmemory2: true,
            usedmemoryRss2: true,
            serverLoad2: true,
            cacheWrite2: true,
            cacheRead2: true,
            percentProcessorTime2: true,
            connectedclients3: true,
            totalcommandsprocessed3: true,
            cachehits3: true,
            cachemisses3: true,
            getcommands3: true,
            setcommands3: true,
            evictedkeys3: true,
            totalkeys3: true,
            expiredkeys3: true,
            usedmemory3: true,
            usedmemoryRss3: true,
            serverLoad3: true,
            cacheWrite3: true,
            cacheRead3: true,
            percentProcessorTime3: true,
            connectedclients4: true,
            totalcommandsprocessed4: true,
            cachehits4: true,
            cachemisses4: true,
            getcommands4: true,
            setcommands4: true,
            evictedkeys4: true,
            totalkeys4: true,
            expiredkeys4: true,
            usedmemory4: true,
            usedmemoryRss4: true,
            serverLoad4: true,
            cacheWrite4: true,
            cacheRead4: true,
            percentProcessorTime4: true,
            connectedclients5: true,
            totalcommandsprocessed5: true,
            cachehits5: true,
            cachemisses5: true,
            getcommands5: true,
            setcommands5: true,
            evictedkeys5: true,
            totalkeys5: true,
            expiredkeys5: true,
            usedmemory5: true,
            usedmemoryRss5: true,
            serverLoad5: true,
            cacheWrite5: true,
            cacheRead5: true,
            percentProcessorTime5: true,
            connectedclients6: true,
            totalcommandsprocessed6: true,
            cachehits6: true,
            cachemisses6: true,
            getcommands6: true,
            setcommands6: true,
            evictedkeys6: true,
            totalkeys6: true,
            expiredkeys6: true,
            usedmemory6: true,
            usedmemoryRss6: true,
            serverLoad6: true,
            cacheWrite6: true,
            cacheRead6: true,
            percentProcessorTime6: true,
            connectedclients7: true,
            totalcommandsprocessed7: true,
            cachehits7: true,
            cachemisses7: true,
            getcommands7: true,
            setcommands7: true,
            evictedkeys7: true,
            totalkeys7: true,
            expiredkeys7: true,
            usedmemory7: true,
            usedmemoryRss7: true,
            serverLoad7: true,
            cacheWrite7: true,
            cacheRead7: true,
            percentProcessorTime7: true,
            connectedclients8: true,
            totalcommandsprocessed8: true,
            cachehits8: true,
            cachemisses8: true,
            getcommands8: true,
            setcommands8: true,
            evictedkeys8: true,
            totalkeys8: true,
            expiredkeys8: true,
            usedmemory8: true,
            usedmemoryRss8: true,
            serverLoad8: true,
            cacheWrite8: true,
            cacheRead8: true,
            percentProcessorTime8: true,
            connectedclients9: true,
            totalcommandsprocessed9: true,
            cachehits9: true,
            cachemisses9: true,
            getcommands9: true,
            setcommands9: true,
            evictedkeys9: true,
            totalkeys9: true,
            expiredkeys9: true,
            usedmemory9: true,
            usedmemoryRss9: true,
            serverLoad9: true,
            cacheWrite9: true,
            cacheRead9: true,
            percentProcessorTime9: true,
            SuccessfulRequests: true,
            ServerErrors: true,
            UserErrors: true,
            QuotaExceededErrors: true,
            ThrottledRequests: true,
            IncomingRequests: true,
            IncomingMessages: true,
            OutgoingMessages: true,
            IncomingBytes: true,
            OutgoingBytes: true,
            ActiveConnections: true,
            ConnectionsOpened: true,
            ConnectionsClosed: true,
            CaptureBacklog: true,
            CapturedMessages: true,
            CapturesBytes: true,
            INREQS: true,
            SUCCREQ: true,
            FAILREQ: true,
            SVRBSY: true,
            INTERR: true,
            MISCERR: true,
            INMSGS: true,
            EHINMSGS: true,
            OUTMSGS: true,
            EHOUTMSGS: true,
            EHINMBS: true,
            EHINBYTES: true,
            EHOUTMBS: true,
            EHOUTBYTES: true,
            EHABL: true,
            EHAMSGS: true,
            EHAMBS: true,
            RunsStarted: true,
            RunsCompleted: true,
            RunsSucceeded: true,
            RunsFailed: true,
            RunsCancelled: true,
            RunLatency: true,
            RunSuccessLatency: true,
            RunThrottledEvents: true,
            RunFailurePercentage: true,
            ActionsStarted: true,
            ActionsCompleted: true,
            ActionsSucceeded: true,
            ActionsFailed: true,
            ActionsSkipped: true,
            ActionLatency: true,
            ActionSuccessLatency: true,
            ActionThrottledEvents: true,
            TriggersStarted: true,
            TriggersCompleted: true,
            TriggersSucceeded: true,
            TriggersFailed: true,
            TriggersSkipped: true,
            TriggersFired: true,
            TriggerLatency: true,
            TriggerFireLatency: true,
            TriggerSuccessLatency: true,
            TriggerThrottledEvents: true,
            BillableActionExecutions: true,
            BillableTriggerExecutions: true,
            TotalBillableExecutions: true,
            storage: true,
            connection_successful: true,
            connection_failed: true,
            blocked_by_firewall: true,
            deadlock: true,
            xtp_storage_percent: true,
            workers_percent: true,
            sessions_percent: true,
            dtu_limit: true,
            dtu_used: true,
            dwu_limit: true,
            dwu_consumption_percent: true,
            dwu_used: true,
            dw_cpu_percent: true,
            dw_physical_data_read_percent: true,
            cpu_percent: true,
            physical_data_read_percent: true,
            log_write_percent: true,
            dtu_consumption_percent: true,
            storage_percent: true,
            eDTU_limit: true,
            storage_limit: true,
            eDTU_used: true,
            storage_used: true,
            UsedCapacity: true,
            Transactions: true,
            Ingress: true,
            Egress: true,
            SuccessServerLatency: true,
            SuccessE2ELatency: true,
            Availability: true,
            BlobCapacity: true,
            BlobCount: true,
            ContainerCount: true,
            TableCapacity: true,
            TableCount: true,
            TableEntityCount: true,
            QueueCapacity: true,
            QueueCount: true,
            QueueMessageCount: true,
            FileCapacity: true,
            FileCount: true,
            FileShareCount: true,
            CpuTime: true,
            Requests: true,
            BytesReceived: true,
            BytesSent: true,
            Http101: true,
            Http2xx: true,
            Http3xx: true,
            Http401: true,
            Http403: true,
            Http404: true,
            Http406: true,
            Http4xx: true,
            Http5xx: true,
            MemoryWorkingSet: true,
            AverageMemoryWorkingSet: true,
            AverageResponseTime: true,
            AppConnections: true,
        };
        return {
            isAzureMetric: function (metric) {
                return !!knownAzureMetrics[metric];
            },
        };
    },
]);
