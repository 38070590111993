import { trace, context, Span } from '@opentelemetry/api';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import murmurhash from 'murmurhash-js';
import { getCustomTracer } from '@splunk/olly-tracing/getCustomTracer';
import { AppModuleProps, ModuleArtifact } from './types';

const tracer = getCustomTracer();

/*
 * Module resource loader manages the loading of resources for the artifact. Maintains
 * an internal cache of resources that have previously been loaded to avoid duplicate loading.
 */

export enum ModuleResourceLoadState {
    LOADING = 'loading',
    DONE = 'done',
    ERROR = 'error',
}

type ModuleResource = {
    element: HTMLScriptElement | HTMLLinkElement;
    loadStatus: ModuleResourceLoadState;
};

type ResourceLoadContext = {
    span: Span;
};

/**
 * Attaches load and error event listeners to the given resource element. Removes attached listeners
 * when either event occurs to cleanup.
 */
function attachResourceEventListeners(
    resourceElement: ModuleResource['element'],
    onLoad: () => void,
    onError: () => void
): void {
    const onLoadWrap = (): void => {
        onLoad();
        removeListeners(); // eslint-disable-line @typescript-eslint/no-use-before-define
    };

    const onErrorWrap = (): void => {
        onError();
        removeListeners(); // eslint-disable-line @typescript-eslint/no-use-before-define
    };

    const removeListeners = (): void => {
        resourceElement.removeEventListener('load', onLoadWrap);
        resourceElement.removeEventListener('error', onErrorWrap);
    };

    resourceElement.addEventListener('load', onLoadWrap);
    resourceElement.addEventListener('error', onErrorWrap);
}

function annotateFailedResourceSpan(span: Span): void {
    span.setAttributes({
        error: true,
        'error.message': 'Resource load failed',
    });
}

function annotatePreviouslyLoadedResourceSpan(span: Span): void {
    span.setAttributes({
        message: 'Resource load previously loaded',
    });
}

function annotatePreviouslyFailedResourceSpan(span: Span): void {
    span.setAttributes({
        error: true,
        'error.message': 'Resource load previously failed',
    });
}

function toResourceCacheKey(bundleURL: string): string {
    try {
        return murmurhash.murmur3(bundleURL, 1).toString();
    } catch {
        // Return the original URL if hashing failed
        return bundleURL;
    }
}

/*
 * Loads a js resource as a script element in the document <head>. Calls onLoad callback on successful load
 * and onError on failure. If the resource has already been attached listeners are added to the existing
 * element to avoid duplicate elements.
 */
const moduleJavascriptResourceCache: Record<string, ModuleResource> = {};
function loadModuleJavascript(
    moduleBundleJS: string,
    onLoad: (e?: unknown) => void,
    onError: () => void,
    resourceLoadContext: ResourceLoadContext
): void {
    // Check for existing resource load for same artifact, otherwise load resource
    const resource = moduleJavascriptResourceCache[toResourceCacheKey(moduleBundleJS)];
    switch (resource?.loadStatus) {
        case ModuleResourceLoadState.DONE: {
            onLoad();
            annotatePreviouslyLoadedResourceSpan(resourceLoadContext.span);
            break;
        }
        case ModuleResourceLoadState.ERROR: {
            onError();
            annotatePreviouslyFailedResourceSpan(resourceLoadContext.span);
            break;
        }
        case ModuleResourceLoadState.LOADING: {
            attachResourceEventListeners(resource.element, onLoad, () => {
                onError();
                annotateFailedResourceSpan(resourceLoadContext.span);
            });
            break;
        }
        default: {
            const scriptEl = document.createElement('script');

            moduleJavascriptResourceCache[toResourceCacheKey(moduleBundleJS)] = {
                element: scriptEl,
                loadStatus: ModuleResourceLoadState.LOADING,
            };

            attachResourceEventListeners(
                scriptEl,
                () => {
                    moduleJavascriptResourceCache[toResourceCacheKey(moduleBundleJS)].loadStatus =
                        ModuleResourceLoadState.DONE;
                    onLoad();
                },
                () => {
                    moduleJavascriptResourceCache[toResourceCacheKey(moduleBundleJS)].loadStatus =
                        ModuleResourceLoadState.ERROR;
                    onError();
                    annotateFailedResourceSpan(resourceLoadContext.span);
                }
            );

            scriptEl.async = true;
            scriptEl.src = moduleBundleJS;

            document.head.appendChild(scriptEl);
            break;
        }
    }
}

/*
 * Loads a css resource as a link element in the document <head>. Calls onLoad callback on successful load
 * and onError on failure. If the resource has already been attached listeners are added to the existing
 * element to avoid duplicate elements. If no resource is linked, it will resolve.
 */
const moduleCssResourceCache: Record<string, ModuleResource> = {};
function loadModuleCss(
    moduleBundleCss: string,
    onLoad: (e?: unknown) => void,
    onError: () => void,
    resourceLoadContext: ResourceLoadContext
): void {
    // Check for existing resource load for same artifact, otherwise load resource
    const resource = moduleCssResourceCache[toResourceCacheKey(moduleBundleCss)];
    switch (resource?.loadStatus) {
        case ModuleResourceLoadState.DONE: {
            onLoad();
            annotatePreviouslyLoadedResourceSpan(resourceLoadContext.span);
            break;
        }
        case ModuleResourceLoadState.ERROR: {
            onError();
            annotatePreviouslyFailedResourceSpan(resourceLoadContext.span);
            break;
        }
        case ModuleResourceLoadState.LOADING: {
            attachResourceEventListeners(resource.element, onLoad, () => {
                onError();
                annotateFailedResourceSpan(resourceLoadContext.span);
            });
            break;
        }
        default: {
            const linkEl = document.createElement('link');

            moduleCssResourceCache[toResourceCacheKey(moduleBundleCss)] = {
                element: linkEl,
                loadStatus: ModuleResourceLoadState.LOADING,
            };

            attachResourceEventListeners(
                linkEl,
                () => {
                    moduleCssResourceCache[toResourceCacheKey(moduleBundleCss)].loadStatus =
                        ModuleResourceLoadState.DONE;
                    onLoad();
                },
                () => {
                    moduleCssResourceCache[toResourceCacheKey(moduleBundleCss)].loadStatus =
                        ModuleResourceLoadState.ERROR;
                    onError();
                    annotateFailedResourceSpan(resourceLoadContext.span);
                }
            );

            linkEl.rel = 'stylesheet';
            linkEl.href = moduleBundleCss;

            document.head.appendChild(linkEl);
        }
    }
}

type ModuleLoadStatus = {
    entryComponent: React.ComponentType<AppModuleProps>;
    status: ModuleResourceLoadState;
};

export const noopComponent = (): null => null;

/**
 * Returns the load status for the given artifact.
 * - Returns `undefined` if the artifact has not been loaded. Should call `loadModule()` to instantiate.
 * - Returns `status` as "error" if any artifact resource has failed or if all resources are loaded
 *   but the entryComponent cannot be found.
 * - Returns `status` as "loading" if any artifact resource is loading.
 * - Returns `status` as "loaded" and returns the `entryComponent` if the artifact is ready to be used.
 */
export function getModuleLoadStatus(artifact: ModuleArtifact): ModuleLoadStatus | undefined {
    const jsStatus: ModuleResourceLoadState | undefined =
        moduleJavascriptResourceCache[toResourceCacheKey(artifact.bundleJS)]?.loadStatus;
    const cssStatus: ModuleResourceLoadState | undefined = artifact.bundleCSS
        ? moduleCssResourceCache[toResourceCacheKey(artifact.bundleCSS)]?.loadStatus
        : ModuleResourceLoadState.DONE;

    // Look for the entry component on the global scope
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const entryComponent: React.ComponentType<AppModuleProps> = (window as any)[
        artifact.globalPropertyName
    ];

    // The module has not been loaded at all if either resource status is undefined
    if (jsStatus === undefined || cssStatus === undefined) {
        return undefined;
    }

    // The module did not load if either resource failed to load or the js resource loaded but no
    // entry component was published.
    if (
        jsStatus === ModuleResourceLoadState.ERROR ||
        cssStatus === ModuleResourceLoadState.ERROR ||
        (jsStatus === ModuleResourceLoadState.DONE && entryComponent === undefined)
    ) {
        return {
            entryComponent: noopComponent,
            status: ModuleResourceLoadState.ERROR,
        };
    }

    // The module is loading if either resource is still loading
    if (
        jsStatus === ModuleResourceLoadState.LOADING ||
        cssStatus === ModuleResourceLoadState.LOADING
    ) {
        return {
            entryComponent: noopComponent,
            status: ModuleResourceLoadState.LOADING,
        };
    }

    // The module has loaded and is ready to be used, return entry component
    return {
        entryComponent,
        status: ModuleResourceLoadState.DONE,
    };
}

// Cache of loading promises, keyed off the artifact.globalPropertyName.
const loadModulePromiseCache: Record<string, Promise<unknown>> = {};

const SERVICE_NAME = 'signalview/lazyModuleResourceLoader';

/**
 * Loads the module resources or attaches listeners to an existing resource load for the same artifact.
 * Returns a promise that resolves when all resources have been loaded and rejects if any resources load
 * fails.
 */
export function loadModule(artifact: ModuleArtifact): Promise<unknown> {
    const { globalPropertyName, bundleJS, bundleCSS } = artifact;

    // Check if the loading is already in our cache
    if (loadModulePromiseCache[globalPropertyName]) {
        return loadModulePromiseCache[globalPropertyName];
    }

    const loadModuleSpan = tracer.startSpan('load', {
        attributes: {
            globalPropertyName,
            bundleJS,
            bundleCSS,
            'workflow.name': 'signalview.module.load',
            [SemanticResourceAttributes.SERVICE_NAME]: SERVICE_NAME,
        },
    });

    const loadModulePromise = context.with(trace.setSpan(context.active(), loadModuleSpan), () => {
        // Spans to track artifact resource loads
        const loadModuleJavascriptSpan = tracer.startSpan('load.javascript', {
            attributes: {
                'http.url': artifact.bundleJS,
                [SemanticResourceAttributes.SERVICE_NAME]: SERVICE_NAME,
            },
        });
        const loadModuleCssSpan =
            bundleCSS &&
            tracer.startSpan('load.css', {
                attributes: {
                    'http.url': artifact.bundleCSS,
                    [SemanticResourceAttributes.SERVICE_NAME]: SERVICE_NAME,
                },
            });

        const jsPromise = new Promise((resolve, reject) => {
            loadModuleJavascript(bundleJS, resolve, reject, {
                span: loadModuleJavascriptSpan,
            });
        }).finally(() => {
            loadModuleJavascriptSpan.end();
        });
        const cssPromise =
            bundleCSS &&
            loadModuleCssSpan &&
            new Promise((resolve, reject) => {
                loadModuleCss(bundleCSS, resolve, reject, {
                    span: loadModuleCssSpan,
                });
            }).finally(() => {
                loadModuleCssSpan.end();
            });

        return Promise.all<unknown>([jsPromise, cssPromise])
            .catch((err) => {
                loadModuleSpan.setAttributes({
                    error: true,
                    'error.message': `Error loading module: ${artifact.globalPropertyName}`,
                });
                throw err;
            })
            .finally(() => {
                loadModuleSpan.end();
            });
    });

    loadModulePromiseCache[globalPropertyName] = loadModulePromise;

    return loadModulePromise;
}
