import templateUrl from './heatmapContextualMenu.tpl.html';
import heatmapSettingsTemplateUrl from './heatmapSettings.tpl.html';

angular.module('signalview.heatmap').component('heatmapContextualMenu', {
    templateUrl,
    bindings: {
        heatmap: '<',
    },
    controller: [
        'currentUser',
        'download',
        'navigatorService',
        'packageImporter',
        'sfxModal',
        function (currentUser, download, navigatorService, packageImporter, sfxModal) {
            const $ctrl = this;
            $ctrl.isSuperUser = false;
            $ctrl.showSettingsModal = showSettingsModal;
            $ctrl.exportNavigator = exportNavigator;
            $ctrl.importNavigator = importNavigator;
            $ctrl.$onInit = onInit;

            let orgId;
            function onInit() {
                currentUser.orgId().then(function (o) {
                    orgId = o;
                });

                currentUser.isSuperUser().then((_isSuperUser) => {
                    $ctrl.isSuperUser = _isSuperUser;
                });
            }

            function showSettingsModal() {
                const settingsModal = sfxModal.open({
                    templateUrl: heatmapSettingsTemplateUrl,
                    size: 'md',
                    controller: 'HeatmapSettingsController',
                    resolve: {
                        userPreferences: function () {
                            return currentUser.preferences();
                        },
                        orgId: function () {
                            return orgId;
                        },
                        heatmap: function () {
                            return $ctrl.heatmap;
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                });

                return settingsModal.result;
            }

            function exportNavigator() {
                const name = $ctrl.heatmap.mode().displayName + '.json';
                const content = navigatorService.exportNavigator($ctrl.heatmap.mode().instanceId);
                return download(name, content);
            }

            function importNavigator() {
                packageImporter.importNavigators();
            }
        },
    ],
});
