import { useState, useEffect } from 'react';
import AclDashboardService from '../../../legacy/app/dashboard/aclDashboard/AclDashboardService';

export default function useDashboardMirrorState(dashboardId) {
    const [isMirror, setIsMirror] = useState(false);
    const [validGroups, setValidGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const dashboardService = AclDashboardService.useInstance();

    useEffect(() => {
        setIsLoading(true);
        dashboardService.getMirrorState(dashboardId).then((result) => {
            setIsMirror(result['mirrored']);
            setValidGroups(result['groupIds'] || []);
            setIsLoading(false);
        });
    }, [dashboardId, dashboardService]);

    return { isMirror, validGroups, isLoading };
}
