angular
    .module('signalview.detector')

    // generate a String key that represents a given notification (to be used in hash key)
    .service('notificationKeyService', function () {
        return function (notification) {
            const key = notification.type;
            switch (notification.type) {
                case 'email':
                    return key + '_' + notification.email;
                case 'team':
                    return key + '_' + notification.team;
                case 'slack':
                    return key + '_' + notification.credentialId + '_' + notification.channel;
                case 'victorops':
                    return key + '_' + notification.credentialId + '_' + notification.routingKey;
                case 'xmatters':
                case 'opsgenie':
                case 'amazoneventbridge':
                case 'jira':
                case 'pagerduty':
                    return key + '_' + notification.credentialId;
                case 'webhook':
                    return key + '_' + notification.url + '_' + notification.secret;
            }
        };
    });
