import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

import Link from '@splunk/react-ui/Link';
import MessageBar from '@splunk/react-ui/MessageBar';
import { AnyTheme, ColorScheme, VariableInterpolation, variables } from '@splunk/themes';

import { AngularInjector } from '../../../../common/AngularUtils';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../../../common/theme/ThemeProvider';

type themeColor =
    | VariableInterpolation<AnyTheme, 'white'>
    | VariableInterpolation<AnyTheme, 'black'>;

const getColor = (theme: ColorScheme): themeColor =>
    theme === 'light' ? variables.white : variables.black;

const StyledDiv = styled.div<{ theme: ColorScheme }>`
    margin-left: -20px;
    & div {
        color: ${({ theme }): themeColor => getColor(theme)} !important;
        & a {
            color: ${({ theme }): themeColor => getColor(theme)} !important;
        }
    }
`;

const OrgUsageExceedBanner: FC = () => {
    const overQuotaNotificationService = AngularInjector.useInjectedClass(
        'overQuotaNotificationService'
    );
    const featureEnabled = AngularInjector.instantiate('featureEnabled');

    const themeKey = useLegacyThemeServiceKey();
    const [usageType, setUsageType] = useState('');

    useEffect(() => {
        if (featureEnabled('newUsageExceedBanner')) {
            overQuotaNotificationService
                .getExceededMetricUsageCategory()
                .then((usageTypeValues: string) => setUsageType(usageTypeValues))
                .catch((err: any) => console.error(err));
        }
    }, [featureEnabled, overQuotaNotificationService]);

    return (
        <>
            {usageType.length > 0 && (
                <ThemeProvider colorScheme={themeKey}>
                    <StyledDiv theme={themeKey}>
                        <MessageBar type="error" onRequestClose={(): void => setUsageType('')}>
                            {usageType} usage exceeds your plan limit. To learn more, see the
                            <Link
                                to="https://quickdraw.splunk.com/redirect/?product=Observability&location=subscription.usage.overview&version=current"
                                openInNewContext
                            >
                                subscription usage documentation
                            </Link>
                            .
                        </MessageBar>
                    </StyledDiv>
                </ThemeProvider>
            )}
        </>
    );
};

export default OrgUsageExceedBanner;
