angular.module('signalview.utils').service('dateService', [
    'moment',
    function (moment) {
        return {
            daysTo: function (ms) {
                const a = moment(Date.now());
                const b = moment(ms);
                return Math.abs(a.diff(b, 'days'));
            },
        };
    },
]);
