'use strict';
import templateUrl from './toggle.html';

angular.module('sfx.ui').directive('sfxToggle', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            templateUrl,
        };
    },
]);
