angular.module('signalview.orgIntegrations').service('credentialTypeToKey', [
    'BUSINESS_PROCESS_INTEGRATIONS',
    function (BUSINESS_PROCESS_INTEGRATIONS) {
        const credTypeToName = {};
        angular.forEach(BUSINESS_PROCESS_INTEGRATIONS, (integration, key) => {
            credTypeToName[integration.credType] = key;
        });

        function mapCredTypeToName(credType) {
            return credTypeToName[credType];
        }

        return {
            mapCredTypeToName,
        };
    },
]);
