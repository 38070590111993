import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from '@splunk/react-ui/Dropdown';
import Menu from '@splunk/react-ui/Menu';
import GroupByDropdownButton from './dropdownButton';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import ChartService from '../../../../legacy/app/charting/chartdisplay/tableChart/chartService';
import _ from 'lodash';

const StyledDropdown = styled.div`
    display: inline-block;
`;

const StyledMenu = styled(Menu)`
    & {
        max-height: 325px;
        overflow-y: auto;
        width: min-content;
    }
    [data-test='tooltip'] {
        width: 100%;
        > span {
            width: 100%;
        }
    }
`;

const StyledMenuItem = styled(Menu.Item)`
    span {
        width: max-content;
    }
`;

export default function GroupByDropdown({
    title,
    options,
    onChange,
    selected,
    onRemove,
    disabled,
    legendColumns,
    noneOption,
}) {
    const chartService = ChartService.useInstance();
    const [selectedOption, setSelectedOption] = useState(selected);
    const [dropdownItems, setDropdownItems] = useState([]);

    useEffect(() => {
        setSelectedOption(selected || '');
    }, [selected]);

    const toggle = (
        <GroupByDropdownButton
            title={title}
            selected={selectedOption}
            disabled={disabled}
            onRemove={onRemove}
            noneOption={noneOption}
        />
    );

    useEffect(() => {
        const items = [];
        options.forEach((option) => {
            items.push({
                key: option,
                isHidden: !chartService.isColumnVisible(option, legendColumns),
            });
        });
        setDropdownItems(_.cloneDeep(items));
    }, [options, legendColumns, chartService]);

    return (
        <StyledDropdown>
            <Dropdown toggle={toggle} retainFocus>
                <StyledMenu retainFocus={false}>
                    {dropdownItems.map((item) =>
                        item.isHidden ? (
                            <Tooltip
                                key={`key-${item.key}-tooltip`}
                                compact
                                content="This key is currently hidden. Adding it as a group by will resurface the column in the table."
                                defaultPlacement={'left'}
                            >
                                <StyledMenuItem
                                    key={`key-${item.key}`}
                                    selectable
                                    selected={selectedOption === item.key}
                                    onClick={() => {
                                        setSelectedOption(item.key);
                                        onChange(item.key, item.isHidden);
                                    }}
                                >
                                    <span>{item.key}</span>
                                </StyledMenuItem>
                            </Tooltip>
                        ) : (
                            <StyledMenuItem
                                key={`key-${item.key}`}
                                selectable
                                selected={selectedOption === item.key}
                                onClick={() => {
                                    setSelectedOption(item.key);
                                    onChange(item.key, item.isHidden);
                                }}
                            >
                                <span>{item.key}</span>
                            </StyledMenuItem>
                        )
                    )}
                </StyledMenu>
            </Dropdown>
        </StyledDropdown>
    );
}

GroupByDropdown.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    selected: PropTypes.bool,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    legendColumns: PropTypes.array,
    noneOption: PropTypes.string,
};
