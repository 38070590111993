import templateUrl from './objectRelatedMetrics.tpl.html';

angular.module('signalview.objectmanager').directive('objectRelatedMetrics', [
    '$location',
    'currentUser',
    'catalogMetricDocumentation',
    'catalogHelper',
    'metricService',
    function ($location, currentUser, catalogMetricDocumentation, catalogHelper, metricService) {
        return {
            restrict: 'AE',
            scope: {
                object: '=',
                filters: '=',
                isNewCatalog: '=',
                hideTitle: '@?',
            },
            templateUrl,
            link: function ($scope) {
                function setMetrics(metrics) {
                    $scope.metrics = metrics;
                    $scope.metricDescription = {};
                    metrics.forEach(function (metric) {
                        catalogMetricDocumentation
                            .getMetricDocumentation(metric.value)
                            .then(function (metadata) {
                                if (metadata && metadata.yaml.brief) {
                                    $scope.metricDescription[metric.value] = metadata.yaml.brief;
                                }
                            });
                    });
                }

                $scope.showMetrics = function (selectedMetric) {
                    const object = $scope.object;
                    const objectInFilter = catalogHelper.isInFilter(object, $scope.filters);

                    const query = getSourceQuery(object);
                    const search = $location.search();

                    if (!objectInFilter) {
                        const sources = search['sources[]'] || [];
                        sources.push(query);
                        search['sources[]'] = sources;
                    }

                    search.topicFilterKey = 'sf_metric';

                    if (selectedMetric) {
                        delete search.select;
                        search.selectedKeyValue = 'sf_metric:' + selectedMetric.value;
                    }

                    currentUser.orgId().then(function (orgId) {
                        // Ensure that the user is directed to the catalog if they're not there
                        // already (if they're in a restricted view).
                        $location.path('/catalog/' + orgId);
                        $location.search(search);
                    });
                };

                $scope.showInMetricFinder = function () {
                    const object = $scope.object;
                    const objectInFilter = catalogHelper.isInFilter(object, $scope.filters);
                    const query = getSourceQuery(object);
                    const sources = $location.search()['sources[]'] || [];
                    if (!objectInFilter) {
                        sources.push(query);
                    }
                    const search = { 'sources[]': sources };
                    $location.path('/metrics');
                    $location.search(search);
                };

                function getSourceQuery(object) {
                    let key = object.sf_key[0];
                    let query;
                    if (object.sf_type === 'Topic') {
                        query = 'sf_key:' + object.sf_topic;
                    } else {
                        if (key === 'sf_tag') key = 'sf_tags';
                        query = key + ':' + object[key];
                    }
                    return query;
                }

                function updateMetrics(object) {
                    if (
                        !object ||
                        angular.isArray(object) ||
                        object.sf_type === 'Metric' ||
                        object.sf_type === 'MetricTimeSeries'
                    )
                        return;

                    let key, query;
                    if (object.sf_type === 'Dimension' || object.sf_type === 'Property') {
                        key = object.sf_key[0];
                    } else if (object.sf_type === 'Topic') {
                        query = 'sf_key:' + object.sf_topic;
                    } else {
                        key = 'sf_' + object.sf_type.toLowerCase();
                    }

                    if (!query) {
                        if (!object[key]) return;

                        if (key === 'sf_tag') key = 'sf_tags';

                        query = key + ':' + object[key] + '';
                    }

                    loadMetrics(query).then(function (metrics) {
                        setMetrics(metrics);
                    });
                }

                $scope.$watch('object', updateMetrics);
                $scope.$watch('filters', updateMetrics);

                function loadMetrics(metricQuery) {
                    const filters = [metricQuery];

                    if ($scope.filters && $scope.filters.length) {
                        $scope.filters.forEach(function (filter) {
                            filters.push(filter.property + ':' + filter.propertyValue);
                        });
                    }

                    return metricService.metricfinder('', filters, true).then((res) => {
                        return res.metrics.metricResults.splice(0, 40);
                    });
                }
            },
        };
    },
]);
