import templateUrl from './heatmapLabelTooltip.tpl.html';
import { modifyTemplateData } from '@splunk/olly-services/lib';
import { addWordBreaks } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

/*jshint loopfunc: true */
angular.module('signalview.heatmap').directive('heatmapLabelTooltip', [
    '$location',
    'mustache',
    'valueRenderer',
    '$q',
    'metricTimeSeriesService',
    function ($location, mustache, valueRenderer, $q, metricTimeSeriesService) {
        return {
            restrict: 'E',
            scope: {
                data: '=',
                prefix: '=',
                suffix: '=',
                heatmap: '=',
                tooltipMsgOnNoData: '=?',
                updateTooltipPosition: '&',
            },
            templateUrl,
            controller: [
                '$scope',
                function ($scope) {
                    const data = $scope.data;
                    if (data === null || data === undefined) return;

                    const heatmap = $scope.heatmap;
                    const mode = heatmap.mode();
                    const modes = heatmap.modes();
                    const keyList = angular.copy(mode.tooltipKeyList);
                    let isDisplayNameAvailable = false,
                        idProperty;

                    // Timeseries are elements
                    const isElement = !!data.sf_key;
                    $scope.isElement = isElement;

                    const isSelected = heatmap.selection() === data;
                    $scope.isSelected = isSelected;

                    // If the tooltip is over a group, we need to find the category (property key)
                    // along with the name of the group (property value)
                    if (!isElement) {
                        // If the category is artificial (grouping on sf_streamLabel), then
                        // don't present a category at all, just use the grouped metric name.
                        // This is because the grouping is constructed by our system (architecture
                        // view) and the user has no notion of the grouping (or even what grouping
                        // on sf_streamLabel would mean).
                        $scope.category =
                            data.groupKey === 'sf_streamLabel' ? '' : addWordBreaks(data.groupKey);

                        $scope.categoryName = addWordBreaks(data.name);
                    }

                    function renderId(data, keyList, mode, heatmap) {
                        let idTemplate,
                            valueLabel = 'Value',
                            valueFormat = null,
                            idDisplayName = null,
                            displayNameProperty = null;
                        if (mode.type === 'elemental') {
                            idTemplate = mode.idTemplate;
                            idDisplayName = mode.idDisplayName;
                            const colorByMetric = heatmap.colorBy();

                            if (colorByMetric) {
                                if (colorByMetric.valueLabel) {
                                    valueLabel = colorByMetric.valueLabel;
                                }

                                if (colorByMetric.valueFormat) {
                                    valueFormat = colorByMetric.valueFormat;
                                }
                            }
                        } else {
                            mode.map.metrics.some(function (metric) {
                                if (metric.name === data.sf_streamLabel) {
                                    if (metric.idTemplate) {
                                        idTemplate = metric.idTemplate;
                                    }

                                    if (metric.valueLabel) {
                                        valueLabel = metric.valueLabel;
                                    }

                                    if (metric.valueFormat) {
                                        valueFormat = metric.valueFormat;
                                    }

                                    return true;
                                }
                            });
                        }

                        keyList.some(function (item) {
                            if (item.property === 'value') {
                                item.displayName = valueLabel;
                                item.format = valueFormat;
                                return true;
                            }
                        });

                        if (idDisplayName) {
                            const { idTemplateModified, modifiedData, someKeyHasData } =
                                modifyTemplateData(idDisplayName, data);
                            isDisplayNameAvailable = someKeyHasData;

                            if (isDisplayNameAvailable) {
                                displayNameProperty = mustache.render(
                                    idTemplateModified,
                                    modifiedData
                                );
                            }
                        }

                        const { idTemplateModified, modifiedData } = modifyTemplateData(
                            idTemplate,
                            data,
                            $scope.tooltipMsgOnNoData
                        );
                        idProperty = mustache.render(idTemplateModified, modifiedData);

                        return isDisplayNameAvailable ? displayNameProperty : idProperty;
                    }

                    if (isElement) {
                        $scope.id = renderId(data, keyList, mode, heatmap);
                    }

                    const properties = {};
                    keyList.forEach(function (key) {
                        if (isDisplayNameAvailable && idProperty) {
                            properties['Id'] = idProperty;
                        }
                        if (typeof data[key.property] !== 'undefined') {
                            if (key.property === 'value' && (data._isDead || data._hasNoData)) {
                                properties[key.displayName] = 'Unknown';
                            } else {
                                const value = valueRenderer(data[key.property], key.format);
                                if (value === undefined) return;
                                properties[key.displayName] = value;
                            }
                        }
                    });
                    $scope.properties = properties;

                    const modeToMatchingProperty = {};
                    const filterModeToMatchingProperty = {};

                    function getSwitchableModes(currentMode, modes, data) {
                        const properties = Object.keys(data);

                        const switchableModeMarkup = modes.map(function (mode) {
                            if (
                                (mode.type !== 'elemental' && mode.type !== 'list') ||
                                mode.id === currentMode.id
                            )
                                return '';

                            let matchingProperty = null;

                            properties.some(function (property) {
                                const hasRequiredProperties =
                                    mode.requiredProperties &&
                                    mode.requiredProperties.length === 1 &&
                                    mode.requiredProperties[0] === property;

                                if (hasRequiredProperties) {
                                    matchingProperty = property;
                                    return true;
                                }

                                if (
                                    mode.proxyProperties &&
                                    mode.proxyProperties.indexOf(property) !== -1
                                ) {
                                    matchingProperty = property;
                                    return true;
                                }

                                return false;
                            });

                            if (matchingProperty) {
                                modeToMatchingProperty[mode.id] = matchingProperty;

                                const value = data[matchingProperty];
                                const query =
                                    mode.mtsQuery +
                                    ' AND NOT sf_archived:T AND sf_isActive:true AND ' +
                                    matchingProperty +
                                    ':' +
                                    value;

                                return metricTimeSeriesService
                                    .search({
                                        query: query,
                                        limit: '1',
                                    })
                                    .then(function (resp) {
                                        return resp.count > 0 ? mode : false;
                                    });
                            }

                            let matchingFilterProperty = null;

                            properties.some(function (property) {
                                // filterProperties is an array, we only match a single property
                                // from it, but in order.
                                const hasFilterProperties =
                                    mode.filterProperties && mode.filterProperties.length >= 1;
                                if (hasFilterProperties) {
                                    for (let i = 0; i < mode.filterProperties.length; i++) {
                                        if (mode.filterProperties[i] === property) {
                                            matchingFilterProperty = property;
                                            return true;
                                        }
                                    }
                                }

                                return false;
                            });

                            if (matchingFilterProperty) {
                                filterModeToMatchingProperty[mode.id] = matchingFilterProperty;

                                const prop = data[matchingFilterProperty];
                                const q =
                                    mode.mtsQuery +
                                    ' AND NOT sf_archived:T AND sf_isActive:true AND ' +
                                    matchingFilterProperty +
                                    ':' +
                                    prop;

                                return metricTimeSeriesService
                                    .search({
                                        query: q,
                                        limit: '1',
                                    })
                                    .then(function (resp) {
                                        return resp.count > 0 ? mode : false;
                                    });
                            }

                            return $q.when(false);
                        });

                        return $q.all(switchableModeMarkup).then(function (results) {
                            return results.filter(function (mode) {
                                return mode;
                            });
                        });
                    }

                    function updateSwitchableModes(mode, modes, data) {
                        getSwitchableModes(mode, modes, data).then(function (switchableModes) {
                            $scope.switchableModes = switchableModes;
                            $scope.$emit('reposition');
                            $scope.$emit('switchable modes updated');
                        });
                    }

                    if (!isElement) {
                        let sampleData = data;
                        while (sampleData.children) {
                            sampleData = sampleData.children[0];
                        }

                        updateSwitchableModes(mode, modes, sampleData);
                    } else if (isSelected) {
                        updateSwitchableModes(mode, modes, data);
                    }

                    const filters = heatmap.filterBy();
                    let node = data;
                    let alreadyFiltered = false;
                    while (node.groupKey) {
                        if (node.groupKey !== 'sf_streamLabel') {
                            alreadyFiltered = filters.some(function (filter) {
                                if (
                                    filter.property === node.groupKey &&
                                    filter.propertyValue === node.name
                                ) {
                                    return true;
                                }
                            });
                            if (!alreadyFiltered) {
                                filters.push({
                                    type: 'property',
                                    property: node.groupKey,
                                    propertyValue: node.name,
                                });
                            }
                        }

                        node = node.parent;
                    }

                    $scope.getUrl = function (mode) {
                        let path = $location.path();
                        const parts = path.split('/');
                        // discard last part (current mode)
                        parts.pop();
                        path = parts.join('/');

                        // add new mode id
                        path += '/' + mode.id;
                        let param;

                        let queryParams;
                        if (!$scope.id) {
                            queryParams = filters.map(function (filter) {
                                return 'sources[]=' + filter.property + ':' + filter.propertyValue;
                            });
                        } else {
                            queryParams = [];
                        }

                        if (mode.id in filterModeToMatchingProperty) {
                            const property = filterModeToMatchingProperty[mode.id];
                            param = 'sources[]=' + property + ':' + data[property];
                            if (queryParams.indexOf(param) === -1) {
                                queryParams.push(param);
                            }
                        } else if ($scope.id) {
                            const matchingProperty = modeToMatchingProperty[mode.id];
                            const idTemplate = mode.idTemplate;
                            // For elemental type modes, the mapSelection query parameter value is parsed from the data object using the mode's idTemplate.
                            // Otherwise, the matchingProperty (i.e. the first value of array requiredProperties or proxyProperties in mode) is used.
                            const mapSelectionQueryParamValue =
                                mode.type === 'elemental'
                                    ? mustache.render(idTemplate, data)
                                    : data[matchingProperty];
                            param = 'mapSelection=' + mapSelectionQueryParamValue;
                            if (queryParams.indexOf(param) === -1) {
                                queryParams.push(param);
                            }
                        }

                        path += '?' + queryParams.join('&');
                        return path;
                    };
                },
            ],
        };
    },
]);
