import PropTypes from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

const className = new BEMHelper('saving-status');

export default function SavingStatus({ status }) {
    const statusInfo = getStatus(status);

    if (statusInfo) {
        return (
            <div {...className()}>
                <div {...className('block-page')}>
                    <div {...className('center-content')}>
                        <i className={`loading-status ${statusInfo.class}`}></i>
                        <span>{statusInfo.title}</span>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

function getStatus(status) {
    switch (status) {
        case 'APPLYING':
            return { class: 'large-waiting-spinner', title: 'Applying Changes...' };
        case 'COMPLETED':
            return { class: 'icon-success', title: 'Changes Applied.' };
        case 'FAILED':
            return { class: 'icon-fail', title: 'Changes Failed to Apply.' };
        default:
            return null;
    }
}

SavingStatus.propTypes = {
    status: PropTypes.string,
};
