import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
angular
    .module('metadataFunctionInputs')

    .factory('timeSpanUtils', [
        function () {
            return {
                validateTimeSpan: function (timeStr) {
                    return typeof timeStr === 'string' && !!timeStr.match(/^[1-9]\d*[wdhms]$/);
                },
                validateMinResolution: function (timeStr, jobResolution, signalDefinition) {
                    const ms = convertStringToMS(timeStr);
                    return (
                        ms >=
                        jobResolution * signalDefinition.resolutionConstraint.minUserInputMultiple
                    );
                },
            };
        },
    ]);
