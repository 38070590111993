'use strict';
import { parseDuration } from '../../../../common/ui/formatting/parseDurationModule';

/*
  Given a string such as "<number><unit>..." this will return the millisecond value
  the string represents. Ex. "2m 2s" would evaluate to 122000.
*/
angular.module('sfx.ui').factory('parseDuration', function () {
    return parseDuration;
});
