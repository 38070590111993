export const largeVolumeModalController = [
    '$scope',
    '$uibModalInstance',
    'params',
    'PRODUCT_NAME',
    function ($scope, $uibModalInstance, params, PRODUCT_NAME) {
        $scope.PRODUCT_NAME = PRODUCT_NAME;
        $scope.credentials = params.credentials;
        $scope.isEmpty = !$scope.credentials || $scope.credentials.length === 0;
        $scope.$on('React:$routeUpdate', $uibModalInstance.dismiss);
    },
];
