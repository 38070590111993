import templateUrl from './contentFileUpload.html';

angular.module('sfx.ui').component('contentFileUpload', {
    templateUrl,
    bindings: {
        content: '<',
        editable: '<',
        onUpdate: '&',
    },
    controller: [
        '$element',
        '$scope',
        function ($element, $scope) {
            const $ctrl = this;

            $ctrl.$onChanges = reset;
            $ctrl.$onInit = onInit;

            function onInit() {
                const fileUpload = angular.element('.file', $element);
                fileUpload.on('change', function () {
                    const file = fileUpload.get()[0].files[0];
                    fileUpload.val('');
                    if (!file) {
                        return;
                    }

                    const reader = new FileReader();
                    reader.onload = function () {
                        $ctrl.name = file.name;
                        $ctrl.content = reader.result;

                        if ($ctrl.onUpdate) {
                            $ctrl.onUpdate({
                                content: $ctrl.content,
                            });
                        }

                        $scope.$apply();
                    };
                    reader.readAsText(file);
                });

                $ctrl.upload = function () {
                    fileUpload.click();
                };

                reset();
            }

            function reset() {
                if ($ctrl.content) {
                    $ctrl.name = 'uploaded file';
                } else {
                    $ctrl.name = null;
                }
            }
        },
    ],
});
