// This http interceptor listens for authentication failures
import { supportService } from '@splunk/olly-services/lib';
export default [
    '$injector',
    'securityRetryQueue',
    'sessionCache',
    'auth',
    '$q',
    '$log',
    '$rootScope',
    '$location',
    'pathValidateService',
    function (
        $injector,
        securityRetryQueue,
        sessioncache,
        auth,
        $q,
        $log,
        $rootScope,
        $location,
        pathValidateService
    ) {
        function checkNeedsAuthentication(originalResponse, ifYes, ifNo) {
            const wasAuthSet = auth.authToken();
            auth.clearAuth();
            supportService.removeSupportOrg();
            sessioncache.clearCache();
            $rootScope.$broadcast('clear caches');
            const path = $location.path();
            if (pathValidateService.isAuthenticatedPath(path) && wasAuthSet) {
                // if it's authenticated path and it's actually authenticated
                // otherwise, we are already interacting in unauthenticated context.
                if (securityRetryQueue.isLastError(path)) {
                    securityRetryQueue.rejectLast(originalResponse);
                    return securityRetryQueue.lastPromise();
                }
                securityRetryQueue.pushRetryFn('unauthorized-server', path, ifYes);
            } else {
                return ifNo();
            }
        }

        return {
            responseError: function (originalResponse) {
                if (originalResponse.status === 401) {
                    const TOKEN_HEADER = 'X-SF-TOKEN';
                    const requestAuthToken = originalResponse.config.headers[TOKEN_HEADER];
                    const currentAuthToken = auth.authHeaders()[TOKEN_HEADER];

                    // If auth tokens changed since the request was made just ignore the response
                    if (requestAuthToken !== currentAuthToken) {
                        return $q.reject(originalResponse);
                    }

                    return checkNeedsAuthentication(
                        originalResponse,
                        function retryRequest() {
                            // We must use $injector to get the $http service to prevent circular dependency
                            const $http = $injector.get('$http');

                            if (Object.keys(auth.authHeaders()).length === 0) {
                                $log.warn('No auth info found to retry a request!');
                                return $q.reject(
                                    'An attempt was made to retry a call with no authentication!'
                                );
                            } else {
                                return $http(originalResponse.config);
                            }
                        },
                        function () {
                            return $q.reject(originalResponse);
                        }
                    );
                } else {
                    return $q.reject(originalResponse);
                }
            },
        };
    },
];
