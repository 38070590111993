import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('chartbuilderUtil').service('chartbuilderUtil', [
    'sfxModal',
    'signalboost',
    'analyticsService',
    '$q',
    '$log',
    '$window',
    'chartV2Service',
    'plotUtils',
    'signalviewMetrics',
    'regExParsingUtil',
    'timeZoneService',
    'typeaheadUtils',
    'filterFactory',
    'ROLLUPS',
    'suggestAPIService',
    function (
        sfxModal,
        signalboost,
        analyticsService,
        $q,
        $log,
        $window,
        chartV2Service,
        plotUtils,
        signalviewMetrics,
        regExParsingUtil,
        timeZoneService,
        typeaheadUtils,
        filterFactory,
        ROLLUPS,
        suggestAPIService
    ) {
        const alwaysAppliedRollups = [ROLLUPS.DELTA, ROLLUPS.LAG, ROLLUPS.RATE, ROLLUPS.COUNT];

        function areAllRegExValid(plots) {
            return !plots.some(function (targetPlot) {
                if (
                    targetPlot.type !== 'plot' ||
                    targetPlot.transient ||
                    !plotUtils.isAliasedRegExStyle(targetPlot)
                ) {
                    return false;
                }
                return (
                    plotUtils.validateRegEx(
                        targetPlot.seriesData.metric,
                        targetPlot.seriesData.regExStyle
                    ).length > 0
                );
            });
        }

        function prepareChartSave(model) {
            const maxDelay = parseInt(safeLookup(model, 'sf_uiModel.chartconfig.maxDelay'), 10);
            if (maxDelay > 0) {
                model.sf_jobMaxDelay = maxDelay;
            } else {
                model.sf_jobMaxDelay = 0;
            }

            //this model property is considered transient and should not be persisted yet
            if (model && model.sf_uiModel && model.sf_uiModel.chartconfig) {
                delete model.sf_uiModel.chartconfig.pointDensity;
            }

            // never run a backing job
            delete model.sf_jobResolution;
            delete model.$isOriginallyV2;
            model.sf_cacheProgram = false;
        }

        function addTransientPlot(uiModel) {
            uiModel.allPlots.push({
                name: 'New Plot',
                type: 'plot',
                invisible: false,
                transient: true,
                dataManipulations: [],
                yAxisIndex: 0,
                queryItems: [],
                metricDefinition: {},
                seriesData: {},
                uniqueKey: getNextUniqueKey(uiModel),
            });
        }

        function getTransientPlotIndex(plots) {
            let transientIndex = -1;

            angular.forEach(plots, function (plot, idx) {
                if (plot.transient === true) {
                    transientIndex = idx;
                }
            });
            return transientIndex;
        }

        function cleanPlots(plotList) {
            plotList.forEach(function (plot) {
                if (plot.transient) {
                    plot.type = 'plot';
                }
            });
        }

        function getNextUniqueKey(uiModel, isRule) {
            let targetUniqueKey;
            if (uiModel.currentUniqueKey < 27) {
                if (isRule) {
                    if (!uiModel.currentRuleUniqueKey) {
                        uiModel.currentRuleUniqueKey = 27;
                    }
                    targetUniqueKey = uiModel.currentRuleUniqueKey++;
                } else {
                    targetUniqueKey = uiModel.currentUniqueKey++;
                }
            } else {
                if (!uiModel.currentRuleUniqueKey) {
                    targetUniqueKey = uiModel.currentUniqueKey++;
                } else {
                    uiModel.currentUniqueKey = uiModel.currentRuleUniqueKey;
                    targetUniqueKey = uiModel.currentUniqueKey++;
                    delete uiModel.currentRuleUniqueKey;
                }
            }
            return targetUniqueKey;
        }

        function createTransientIfNeeded(uiModel) {
            let hasEmptyPlot = false;
            angular.forEach(uiModel.allPlots, function (plot) {
                if (isEmptyPlot(plot)) {
                    hasEmptyPlot = true;
                }

                // TODO: this should be split out into its own function and callers
                // refactored to call both where they currently call this function.
                if (plot.transient && isValidPlot(plot)) {
                    plot.transient = false;

                    if (!plot._originalLabel) {
                        plot._originalLabel = determineNextPublishLabel(
                            plotUtils.getLetterFromUniqueKey(plot.uniqueKey),
                            uiModel.allPlots
                        );
                    }
                }
            });

            if (!hasEmptyPlot) {
                addTransientPlot(uiModel);
            }
        }

        function isEmptyPlot(plot) {
            return (
                (plot.type === 'plot' && !plot.seriesData.metric) ||
                (plot.type === 'ratio' && !plot.expressionText)
            );
        }

        function isValidPlot(plot) {
            switch (plot.type) {
                case 'plot':
                    return !!plot.seriesData.metric;
                case 'ratio':
                    return !!plot.expressionText;
                case 'event':
                    return !!(
                        plot.seriesData.eventQuery ||
                        plot.seriesData.detectorQuery ||
                        plot.seriesData.detectorId
                    );
            }
        }

        function removePlot(plot, plots) {
            const key = plot.uniqueKey;
            for (let d = 0; d < plots.length; d++) {
                if (plots[d].uniqueKey === key) {
                    plots.splice(d, 1);
                }
            }
        }

        function movePlotToTop(plot, plots) {
            removePlot(plot, plots);
            plots.unshift(plot);
        }

        function movePlotToBottom(plot, plots) {
            removePlot(plot, plots);
            const lastPlot = plots[plots.length - 1];
            if (lastPlot && lastPlot.transient) {
                const lastRow = plots.pop();
                plots.push(plot);
                plots.push(lastRow);
            } else {
                plots.push(plot);
            }
        }

        function determineNextPublishLabel(proposedLabel, allplots) {
            const existingLabelMap = {};
            allplots.forEach((plot) => {
                if (plot._originalLabel) {
                    existingLabelMap[plot._originalLabel] = true;
                }
            });
            if (existingLabelMap[proposedLabel]) {
                $log.warn('A newly created plot matched an existing original label!');
                let numIters = 1;
                const maxIters = 100;
                let modifiedLabel = proposedLabel + numIters;
                while (numIters < maxIters && !existingLabelMap[modifiedLabel]) {
                    numIters++;
                }

                if (existingLabelMap[modifiedLabel]) {
                    //If we still havent found a unique label after 100 tries, just throw a random number at the end
                    modifiedLabel = proposedLabel + Math.random();
                }

                return modifiedLabel;
            } else {
                return proposedLabel;
            }
        }

        function clonePlot(plot, uiModel) {
            const itm = angular.copy(plot);
            const transientIndex = getTransientPlotIndex(uiModel.allPlots);
            if (transientIndex > -1) {
                itm.uniqueKey = uiModel.allPlots[transientIndex].uniqueKey;
                //recompute the programtext since we are re-assigning a unique key.
                uiModel.allPlots[transientIndex] = itm;
            } else {
                //this should never happen unless we're doing some weird multi-clone thing.
                itm.uniqueKey = getNextUniqueKey(uiModel);
                //recompute the programtext since we are re-assigning a unique key.
                uiModel.allPlots.push(itm);
            }

            if (itm._originalLabel) {
                itm._originalLabel = determineNextPublishLabel(
                    plotUtils.getLetterFromUniqueKey(itm.uniqueKey),
                    uiModel.allPlots
                );
            }
        }

        function cloneCombine(combine, uiModel) {
            const itm = angular.copy(combine);
            const transientIndex = getTransientPlotIndex(uiModel.allPlots);

            if (transientIndex > -1) {
                itm.uniqueKey = uiModel.allPlots[transientIndex].uniqueKey;
                uiModel.allPlots[transientIndex] = itm;
            } else {
                //this shouldn't happen unless we run into some weird multi-clone scenario...
                itm.uniqueKey = getNextUniqueKey(uiModel);
                uiModel.allPlots.push(itm);
            }

            if (itm._originalLabel) {
                itm._originalLabel = determineNextPublishLabel(
                    plotUtils.getLetterFromUniqueKey(itm.uniqueKey),
                    uiModel.allPlots
                );
            }
        }

        function clonePlotConstruct(itm, uiModel, modelId) {
            //clone any 'plot type' item within allPlots, defined as anything that can generate a new TSR
            switch (itm.type) {
                case 'plot':
                    clonePlot(itm, uiModel, modelId);
                    break;
                case 'ratio':
                    cloneCombine(itm, uiModel, modelId);
                    break;
                default:
                    $log.error('Failed to edit object. Unknown type.');
                    break;
            }
        }

        function addYAxis(idx, yAxisConfigurations) {
            if (idx < 0 || idx >= yAxisConfigurations.length) {
                //fixme this yaxis config needs to be moved to a getter service
                yAxisConfigurations.push({
                    max: null,
                    min: null,
                    plotlines: {
                        high: null,
                        low: null,
                    },
                    title: {
                        text: '',
                    },
                });
            }
        }

        function addNewPlot(uiModel) {
            const mdl = {
                name: 'New Plot',
                type: 'plot',
                invisible: false,
                transient: false,
                dataManipulations: [],
                yAxisIndex: 0,
                queryItems: [],
                metricDefinition: {},
                seriesData: {},
                uniqueKey: getNextUniqueKey(uiModel),
            };
            uiModel.allPlots.push(mdl);
            return mdl;
        }

        function addNewSmokeyPlot(uiModel, detector) {
            const plot = addNewPlot(uiModel);
            plot._originalLabel = plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
            plot.configuration = { colorOverride: null };
            plot.expressionText = null;
            plot.name = detector.sf_detector ? detector.sf_detector : detector.name;
            plot.seriesData.detectorId = detector.sf_id ? detector.sf_id : detector.id;
            plot.type = 'event';
            delete plot.yAxisIndex;
            return plot;
        }

        // Add plots to the chart for the given signals and scroll to the first one
        function addPlotsForSignals(uiModel, signals, skipValidation) {
            // Because we are relying on the $watch's on the plot models to fire when
            // we set its fields here, and those are not deep $watch's, we need to
            // add brand new plots instead of filling in the transient plot. Set the
            // transient plot aside for now.
            const existingLabels = {};
            let maxUniqueKey = -1;
            uiModel.allPlots.forEach(function (plot) {
                if (plot.uniqueKey > maxUniqueKey) {
                    maxUniqueKey = plot.uniqueKey;
                }
                if (plot._originalLabel) {
                    existingLabels[plot._originalLabel] = true;
                }
                existingLabels[plotUtils.getLetterFromUniqueKey(plot.uniqueKey)] = true;
            });
            const transientPlot = uiModel.allPlots.splice(-1, 1)[0];
            const firstPlotKey = transientPlot.uniqueKey;

            // TODO(jwy): Make validation work with detectors, which have extra plots
            // for events
            if (!skipValidation && firstPlotKey !== maxUniqueKey) {
                $log.error('Could not add plot, model inconsistency detected!');
                alert('An unexpected error occurred when adding plots.');
                return;
            }

            for (let i = 0; i < signals.length; i++) {
                const signal = signals[i];
                const plot = addNewPlot(uiModel);
                if (i === 0) {
                    // Reuse the transient plot's key
                    plot.uniqueKey = firstPlotKey;
                    uiModel.currentUniqueKey--;
                }

                // we need to ensure we do not have duplicate labels, so we will append
                // numbers to the end of each originalLabel until that is true.
                let numAttempts = 0;
                const letter = plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
                let proposedLabel = letter;
                if (plot.uniqueKey !== firstPlotKey) {
                    // Ignore reused transient plot
                    while (existingLabels[proposedLabel]) {
                        proposedLabel = letter + ++numAttempts;
                    }
                }
                plot._originalLabel = proposedLabel;

                if (signal.type === 'Metric') {
                    plot.type = 'plot';
                    plot.configuration = { rollupPolicy: null };
                    plot.seriesData = { metric: signal.value, regExStyle: null };
                } else if (signal.type === 'Event') {
                    plot.type = 'event';
                    plot.seriesData = { eventQuery: signal.value };
                } else if (signal.type === 'Alert') {
                    plot.type = 'event';
                    plot.seriesData = {
                        detectorQuery: signal.value,
                    };
                }

                // Add property filters
                const properties = Object.keys(signal.plotFilters);
                for (let j = 0; j < properties.length; j++) {
                    const property = properties[j];
                    const filter = signal.plotFilters[property];
                    plot.queryItems.push(
                        filterFactory.FILTER(filterFactory.TYPES.VALUE, filter, false, property)
                    );
                }

                plot.name = analyticsService.createPlotName(plot);
            }

            addTransientPlot(uiModel);
            angular.element('.plot-' + firstPlotKey)[0].scrollIntoView(true);
        }

        function getPublishDetailsFromMessage(message) {
            return safeLookup(message, 'contents');
        }

        function getDataSourcesFromMessage(plots, message) {
            const publishDetails = getPublishDetailsFromMessage(message);
            if (publishDetails) {
                return publishDetails.sourceDataBlocks.map((block) => {
                    const lineNo = block.blockSourceContext.line;
                    return {
                        label: block.blockSerialName,
                        plotKey: plots[lineNo - 1].uniqueKey,
                        lineNo,
                    };
                });
            }
        }

        function getRollupFromMessage(message) {
            return safeLookup(message, 'contents.rollupCounts');
        }

        function getCommonRollupFromMessage(message, tsCount) {
            if (tsCount === 0) {
                // No rollup information will be returned when there is no data
                return '';
            }

            const rollups = getRollupFromMessage(message);
            if (rollups && rollups.length) {
                if (rollups.every((rollup) => rollup.rollupType === rollups[0].rollupType)) {
                    return ROLLUPS[rollups[0].rollupType.toUpperCase()];
                } else {
                    // Multiple rollup types
                    return null;
                }
            }
        }

        function getDataCharacteristicsFromMessage(message) {
            const { nativeness, aperiodicity } = safeLookup(message, 'contents') || {};
            return { nativeness, aperiodicity };
        }

        // Checks if rollup is applied. Rollup is applied if:
        // 1. Source data is not at native resolution, or
        // 2. Irrespective of resolution, rollup is of type of Delta, Lag, Rate/Sec or Count
        function isRollupApplied(rollupMessage, dataCharacteristicsMessage) {
            if (!rollupMessage) {
                // Rollup type not yet determined
                return;
            }
            const rollups = getRollupFromMessage(rollupMessage);
            const { nativeness } = getDataCharacteristicsFromMessage(dataCharacteristicsMessage);
            if (!angular.isDefined(nativeness)) {
                // Nativeness not yet determined
                return;
            }
            return (
                !nativeness ||
                (rollups &&
                    rollups.some((rollup) =>
                        alwaysAppliedRollups.includes(ROLLUPS[rollup.rollupType.toUpperCase()])
                    ))
            );
        }

        function processJobMessages(plotsFromModel, allMsgs, plotIndexToLineOffset = 0) {
            const plots = plotsFromModel.filter((p) => !p.transient);
            /*
        It is possible for a single line number to have multiple identical messages sent due to a quirk in how
        implicitly created timeseries(timeshift) are reported.  For this reason we cap certain known messages
        at one per line number in a map.  This can lead to somewhat unstable results in this cases, but there
        is insufficient information right now to determine which is the "original"
       */
            let resolution = null;
            const plotKeyToInfoMap = {};
            const lineNumberToFindLimited = {};
            const lineNumberToDimensionMsg = {};
            const lineNumberToFindCount = {};
            const lineNumberToPrePublishCount = {};
            const lineNumberToRollup = {};
            const lineNumberToDataCharacteristics = {};
            const lineNumberToPublishSources = {};

            allMsgs.forEach(function (msg) {
                const lineNumber = msg.blockContext ? msg.blockContext.line : null;
                if (msg.messageCode === 'FETCH_NUM_TIMESERIES') {
                    lineNumberToFindCount[lineNumber] = msg;
                } else if (msg.messageCode === 'FIND_MATCHED_DIMENSIONS') {
                    lineNumberToDimensionMsg[lineNumber] = msg;
                } else if (msg.messageCode === 'ID_NUM_TIMESERIES') {
                    lineNumberToPrePublishCount[lineNumber] = msg;
                } else if (msg.messageCode === 'JOB_RUNNING_RESOLUTION') {
                    resolution = msg.contents.resolutionMs;
                } else if (msg.messageCode === 'FIND_LIMITED_RESULT_SET') {
                    lineNumberToFindLimited[lineNumber] = msg;
                } else if (msg.messageCode === 'FETCH_NUM_ROLLUPS') {
                    lineNumberToRollup[lineNumber] = msg;
                } else if (msg.messageCode === 'FETCH_NATIVE_RESOLUTION') {
                    lineNumberToDataCharacteristics[lineNumber] = msg;
                } else if (msg.messageCode === 'PUBLISH_SOURCES') {
                    lineNumberToPublishSources[lineNumber] = msg;
                }
            });

            plots.forEach(function (plot, idx) {
                const plotType = plot.type;
                if (plotType === 'event' || plot.transient) {
                    return;
                }
                const lineNo = idx + plotIndexToLineOffset + 1;
                let findMsg = null;
                let dimensionCounts = null;
                let publishedTimeseries = null;

                if (plot.type === 'plot') {
                    findMsg = lineNumberToFindCount[lineNo] || null;
                    const dimensionMsg = lineNumberToDimensionMsg[lineNo];
                    if (dimensionMsg) {
                        dimensionCounts = dimensionMsg.contents.dimensionCounts;
                    }
                }

                const prePublishCountMsg = lineNumberToPrePublishCount[lineNo];
                if (prePublishCountMsg) {
                    publishedTimeseries = prePublishCountMsg.numInputTimeSeries;
                }

                const sources = getDataSourcesFromMessage(
                    plots,
                    lineNumberToPublishSources[lineNo]
                );
                const rollups = getRollupFromMessage(lineNumberToRollup[lineNo]);

                let commonRollupType;
                let rollupApplied;

                let tsCount = null;
                if (rollups) {
                    if (findMsg) {
                        tsCount = findMsg.numInputTimeSeries;
                    }

                    commonRollupType = getCommonRollupFromMessage(
                        lineNumberToRollup[lineNo],
                        tsCount
                    );
                    rollupApplied = isRollupApplied(
                        lineNumberToRollup[lineNo],
                        lineNumberToDataCharacteristics[lineNo]
                    );
                } else if (sources) {
                    const firstSourceLineNo = sources[0].lineNo;
                    if (lineNumberToFindCount[firstSourceLineNo]) {
                        tsCount = lineNumberToFindCount[firstSourceLineNo].numInputTimeSeries;
                    }

                    const commonRollupForFirstSource = getCommonRollupFromMessage(
                        lineNumberToRollup[firstSourceLineNo],
                        tsCount
                    );
                    const allSourcesHaveSameRollup =
                        sources.length === 1 ||
                        sources.every((source) => {
                            let sourceTsCount = 0;
                            if (lineNumberToFindCount[source.lineNo]) {
                                sourceTsCount =
                                    lineNumberToFindCount[source.lineNo].numInputTimeSeries;
                            }
                            return (
                                getCommonRollupFromMessage(
                                    lineNumberToRollup[source.lineNo],
                                    sourceTsCount
                                ) === commonRollupForFirstSource
                            );
                        });

                    if (allSourcesHaveSameRollup) {
                        commonRollupType = commonRollupForFirstSource;
                    } else {
                        // Multiple rollup types
                        commonRollupType = null;
                    }

                    for (let i = 0; i < sources.length; i++) {
                        const lineNo = sources[i].lineNo;
                        const isRollupAppliedToLine = isRollupApplied(
                            lineNumberToRollup[lineNo],
                            lineNumberToDataCharacteristics[lineNo]
                        );
                        // Ignore lines where rollup application is unknown (undefined)
                        if (isRollupAppliedToLine === true) {
                            rollupApplied = true;
                            break;
                        } else if (isRollupAppliedToLine === false) {
                            rollupApplied = false;
                        }
                    }
                }

                plotKeyToInfoMap[plot.uniqueKey] = {
                    tsidCount: findMsg,
                    dimensions: dimensionCounts,
                    timeSeriesPrePublish: publishedTimeseries,
                    resolution: resolution,
                    dataCharacteristics: getDataCharacteristicsFromMessage(
                        lineNumberToDataCharacteristics[lineNo]
                    ),
                    mtsCap: lineNumberToFindLimited[lineNo]
                        ? lineNumberToFindLimited[lineNo].contents.limitSize
                        : null,
                    publishLabel: safeLookup(
                        getPublishDetailsFromMessage(lineNumberToPublishSources[lineNo]),
                        'publishBlock.blockSerialName'
                    ),
                    visible: !plot.invisible,
                    rollups,
                    rollupApplied,
                    commonRollupType,
                    sources,
                };
            });

            plots.forEach(function (plot) {
                if (plot.type === 'ratio') {
                    const cappedPlotKeys = [];
                    const expressionsEncountered = {};
                    const relevantUKs = plotUtils.getAllRelevantPlots(
                        plot,
                        expressionsEncountered,
                        plots
                    );
                    Object.keys(relevantUKs).forEach(function (uk) {
                        if (plotKeyToInfoMap[uk].mtsCap) {
                            cappedPlotKeys.push(plotUtils.getLetterFromUniqueKey(uk));
                            plotKeyToInfoMap[plot.uniqueKey].mtsCap = true;
                        }
                    });
                    plotKeyToInfoMap[plot.uniqueKey].cappedPlotKeys = cappedPlotKeys;
                }
            });

            return plotKeyToInfoMap;
        }

        function getGlobResults(q) {
            const rs = [];
            const hasGlob = q.indexOf('*') > -1;

            if (hasGlob) {
                rs.push({ type: 'metric', value: q, regExStyle: 'plain' });
            }
            if (regExParsingUtil.hasGraphiteSyntax(q) && regExParsingUtil.hasGraphiteDelimiter(q)) {
                if (regExParsingUtil.hasGraphiteSpecificSyntax(q)) {
                    rs.splice(0, 0, { type: 'metric', value: q, regExStyle: 'graphite' });
                } else {
                    rs.push({ type: 'metric', value: q, regExStyle: 'graphite' });
                }
            }

            return Promise.resolve(rs);
        }

        function getEventGlobResults(q) {
            const rs = [];
            const hasGlob = q.indexOf('*') > -1;
            if (hasGlob) {
                rs.push({ type: 'eventTimeSeries', value: q, regExStyle: 'event' });
                rs.push({ type: 'detectorEvents', value: q, regExStyle: 'alert' });
            }
            return $q.when(rs);
        }

        function singleValColorSchemeIsValid(model) {
            return (
                !model.sf_uiModel.chartconfig.colorByValue ||
                (model.sf_uiModel.chartconfig.colorByValueScale &&
                    model.sf_uiModel.chartconfig.colorByValueScale.length >= 2)
            );
        }

        function setSecondaryVisualization(chartconfig, oldMode, chartMode, chartModeValueCache) {
            // preserving the default secondary visualization
            if (chartModeValueCache['list'] && chartMode === 'single') {
                // if switching to single value with SPARKLINE secondary visualization, switch to NONE (default).
                if (chartModeValueCache['list'].secondaryVisualization === 'SPARKLINE') {
                    chartconfig.secondaryVisualization = 'NONE';
                } else {
                    chartconfig.secondaryVisualization =
                        chartModeValueCache['list'].secondaryVisualization;
                }
            }

            if (chartMode === 'list') {
                if (!chartModeValueCache['list']) {
                    chartconfig.secondaryVisualization = 'SPARKLINE';
                }

                if (chartModeValueCache['single']) {
                    // if switching to list with NONE secondary visualization, switch to SPARKLINE (default).
                    if (chartModeValueCache['single'].secondaryVisualization === 'NONE') {
                        chartconfig.secondaryVisualization = 'SPARKLINE';
                    } else {
                        chartconfig.secondaryVisualization =
                            chartModeValueCache['single'].secondaryVisualization;
                    }
                }
            }

            if (
                chartconfig.secondaryVisualization === 'LINEAR' ||
                chartconfig.secondaryVisualization === 'RADIAL'
            ) {
                chartconfig.colorByValue = true;
            }
        }

        const service = {
            singleValColorSchemeIsValid: singleValColorSchemeIsValid,
            addTransientPlot: addTransientPlot,
            getTransientPlotIndex: getTransientPlotIndex,
            cleanPlots: cleanPlots,
            createTransientIfNeeded: createTransientIfNeeded,
            removePlot: removePlot,
            movePlotToTop: movePlotToTop,
            movePlotToBottom: movePlotToBottom,
            clonePlotConstruct: clonePlotConstruct,
            addYAxis: addYAxis,
            addNewPlot: addNewPlot,
            addNewSmokeyPlot: addNewSmokeyPlot,
            addPlotsForSignals: addPlotsForSignals,
            processJobMessages: processJobMessages,
            prepareChartSave: prepareChartSave,
            setSecondaryVisualization,
            getLetterFromUniqueKey: plotUtils.getLetterFromUniqueKey,
            getUniqueKeyFromLetter: plotUtils.getUniqueKeyFromLetter,
            areAllRegExValid: areAllRegExValid,
            saveChart: function (model, modalscope) {
                prepareChartSave(model);

                const modalInstance = modalscope
                    ? sfxModal.open({
                          template: '<div><i class="busy-spinner-light"></i></div>',
                          windowClass: 'full-screen-busy-spinner',
                          backdrop: 'static',
                          keyboard: false,
                      })
                    : null;

                function closeModal() {
                    // modal is created if modalscope is true
                    if (modalInstance) modalInstance.close();
                }

                if (model.patch) {
                    return model.patch().then(
                        function (d) {
                            angular.extend(model, d);
                            closeModal();
                            return $q.when(model);
                        },
                        function () {
                            alert('Error occurred while saving the chart');
                            closeModal();
                            return null;
                        },
                        function () {
                            alert('Error occurred while saving the chart');
                            closeModal();
                            return null;
                        }
                    );
                } else {
                    return chartV2Service.update(model).then(
                        function (chart) {
                            closeModal();
                            return $q.when(chart);
                        },
                        function (e) {
                            closeModal();
                            $log.error('Failed saving chart.', e);
                            $window.alert('Failed saving chart.');
                            signalviewMetrics.incr('ui.chart.failedChartCreations');
                            return $q.reject();
                        }
                    );
                }
            },
            getLegendTimeStampString: function (inp) {
                if (!inp) {
                    return null;
                }
                return timeZoneService.moment(inp).format('ddd DD MMM YYYY HH:mm:ss');
            },
            hasVisualization: function (chartUIModel, viz) {
                //determines if the default chart level-viz has caused a visible (viz) to exist,
                //or if (viz) has been explicitly set on any plot
                //
                //current usages assume to know the full extend of all model properties needed
                //to watch in order to detect a change.  any logic change here needs to be reflected
                //in watches wherever this is used.
                if (!chartUIModel || !chartUIModel.allPlots) {
                    return false;
                }

                const defaultVizIsHeatMap = chartUIModel.chartType === viz;
                for (let x = 0; x < chartUIModel.allPlots.length; x++) {
                    const plot = chartUIModel.allPlots[x];
                    if (!plot.invisible && !plot.transient) {
                        const cfg = plot.configuration;
                        if (cfg && cfg.visualization === viz) {
                            return true;
                        } else if ((!cfg || !cfg.visualization) && defaultVizIsHeatMap) {
                            return true;
                        }
                    }
                }
                return false;
            },
            getGlobResults: getGlobResults,
            getEventGlobResults: getEventGlobResults,
            getSourceSuggestions: function (
                currentQuery,
                q,
                limit,
                propertyTypes,
                objectTypes,
                disableTag
            ) {
                return service.getSuggestions(currentQuery, q, limit, {}, objectTypes, disableTag);
            },
            convertSuggestToFilters: function (mode, data, isNot, propertyName) {
                if (!data) {
                    return [];
                }
                return data.map(function (v) {
                    return filterFactory.FILTER(mode, v, isNot, propertyName);
                });
            },
            getSyntheticResults: function (propertyName, propertyValue, isNot, proposedFilters) {
                if (propertyValue.indexOf('*') !== -1) {
                    return [
                        filterFactory.FILTER(
                            filterFactory.TYPES.VALUE,
                            propertyValue,
                            isNot,
                            propertyName
                        ),
                    ];
                } else if (
                    propertyValue &&
                    !proposedFilters.some(function (itm) {
                        return itm.propertyValue === propertyValue;
                    })
                ) {
                    // if there is no exact match for what the user typed, offer it as a synthetic match
                    return [
                        filterFactory.FILTER(
                            filterFactory.TYPES.SYNTHETIC,
                            propertyValue,
                            isNot,
                            propertyName
                        ),
                    ];
                } else {
                    return [];
                }
            },
            priorityJoin: function (resultArrays, hashFn) {
                //joins an array of arrays ensuring that first-seen order is preserved.
                const seen = {};
                const finalResult = [];
                resultArrays.forEach(function (results) {
                    results.forEach(function (result) {
                        const lookupKey = hashFn ? hashFn(result) : result;
                        if (!seen[lookupKey]) {
                            seen[lookupKey] = true;
                            finalResult.push(result);
                        }
                    });
                });
                return finalResult;
            },
            getSuggestionsFromSignalFlow: function (
                sflow,
                limit,
                partial,
                filters,
                replaceOnlyFilters,
                preferredSuggestions,
                restrictResultsToPreferred,
                additionalQueryStr,
                startTime,
                endTime
            ) {
                // We will deliberately ignore replaceOnlyFilters but it is provided for context in future implementations.
                // Currently we're not using filter context in this suggest and cannot know if something was to be replaced
                // or not.
                replaceOnlyFilters = [];
                const parsedPartial = typeaheadUtils.parsePartial(partial);
                const isValueSuggest = parsedPartial.isValueSuggest();
                const propertyName = parsedPartial.getProperty();
                const propertyValue = parsedPartial.getValue();
                const isNot = parsedPartial.isNot();
                if (isValueSuggest) {
                    return suggestAPIService
                        .getSignalFlowSuggest({
                            programs: sflow,
                            property: propertyName,
                            partialInput: propertyValue,
                            limit: limit,
                            additionalFilters: filters,
                            additionalReplaceOnlyFilters: replaceOnlyFilters,
                            preferredSuggestions,
                            restrictResultsToPreferred,
                            extraQuery: additionalQueryStr || null,
                            startTime,
                            endTime,
                        })
                        .then(function (data) {
                            const filters = service.convertSuggestToFilters(
                                filterFactory.TYPES.VALUE,
                                data,
                                parsedPartial.isNot(),
                                propertyName
                            );
                            return filters.concat(
                                service.getSyntheticResults(
                                    propertyName,
                                    propertyValue,
                                    isNot,
                                    filters
                                )
                            );
                        });
                } else {
                    //todo check not
                    return $q
                        .all({
                            properties: suggestAPIService.getSignalFlowSuggest({
                                programs: sflow,
                                property: null,
                                partialInput: propertyName,
                                limit: limit,
                                additionalFilters: filters,
                                additionalReplaceOnlyFilters: replaceOnlyFilters,
                                extraQuery: additionalQueryStr || null,
                                startTime,
                                endTime,
                            }),
                            tags: suggestAPIService.getSignalFlowSuggest({
                                programs: sflow,
                                property: 'sf_tags',
                                partialInput: propertyName,
                                limit: limit,
                                additionalFilters: filters,
                                additionalReplaceOnlyFilters: replaceOnlyFilters,
                                preferredSuggestions,
                                restrictResultsToPreferred,
                                extraQuery: additionalQueryStr || null,
                                startTime,
                                endTime,
                            }),
                        })
                        .then(function (resps) {
                            return service
                                .convertSuggestToFilters(
                                    filterFactory.TYPES.PROPERTY,
                                    resps.properties,
                                    parsedPartial.isNot(),
                                    propertyName
                                )
                                .concat(
                                    service.convertSuggestToFilters(
                                        filterFactory.TYPES.TAG,
                                        resps.tags,
                                        parsedPartial.isNot(),
                                        propertyName
                                    )
                                );
                        });
                }
            },
            getSuggestions: function (
                currentQuery,
                q,
                limit,
                propertyTypes,
                objectTypes,
                disableTag
            ) {
                if (!limit) {
                    limit = 100;
                }

                const sfTypes = objectTypes || [];
                const parsedPartial = typeaheadUtils.parsePartial(q);
                const isNotQuery = parsedPartial.isNot();
                const propertyName = parsedPartial.getProperty();
                const propertyValue = parsedPartial.getValue();
                const isValueSearch = parsedPartial.isValueSuggest();
                const allQueries = [];
                if (!isValueSearch) {
                    allQueries.push(
                        signalboost.autosuggest.getPropertySuggestions({
                            query: currentQuery,
                            partialInput: propertyName,
                            limit: limit,
                            types: sfTypes,
                        })
                    );
                    if (!disableTag) {
                        allQueries.push(
                            signalboost.autosuggest.getTagSuggestions({
                                query: currentQuery,
                                partialInput: propertyName,
                                limit: limit,
                                types: sfTypes,
                            })
                        );
                    }
                } else {
                    allQueries.push(
                        signalboost.autosuggest.getValueSuggestions({
                            query:
                                propertyName === 'sf_metric'
                                    ? `((${currentQuery}) AND NOT sf_metric:_SF_PLOT_KEY*)`
                                    : currentQuery,
                            property: propertyName,
                            partialInput: propertyValue,
                            limit: limit,
                            types: sfTypes,
                        })
                    );
                }

                return $q.all(allQueries).then(function (items) {
                    let rs = [];
                    if (!isValueSearch) {
                        rs = rs.concat(
                            service.convertSuggestToFilters(
                                filterFactory.TYPES.PROPERTY,
                                items[0],
                                isNotQuery,
                                propertyName
                            )
                        );
                        //check if tags were returned, if so process them
                        if (items[1]) {
                            rs = rs.concat(
                                service.convertSuggestToFilters(
                                    filterFactory.TYPES.TAG,
                                    items[1],
                                    isNotQuery,
                                    propertyName
                                )
                            );
                        }
                        let index = 2;
                        angular.forEach(propertyTypes, function (iconName, ipropertyName) {
                            //this handles ETS vs MTS it seems
                            const iconClass = iconName || 'properties';
                            rs = rs.concat(
                                service
                                    .convertSuggestToFilters(
                                        filterFactory.TYPES.VALUE,
                                        items[index],
                                        isNotQuery,
                                        ipropertyName
                                    )
                                    .forEach(function (s) {
                                        s.iconClass = iconClass;
                                    })
                            );
                            index++;
                        });
                    } else {
                        rs = rs
                            .concat(
                                service.convertSuggestToFilters(
                                    filterFactory.TYPES.VALUE,
                                    items[0],
                                    isNotQuery,
                                    propertyName
                                )
                            )
                            .concat(
                                rs.concat(
                                    service.getSyntheticResults(
                                        propertyName,
                                        propertyValue,
                                        isNotQuery,
                                        rs
                                    )
                                )
                            );
                    }
                    return rs;
                });
            },
            processJobThrottle: function (plotKeyToInfoMap, model) {
                let dataThrottled = false;
                let throttleCount = 0;
                let passThruCount = 0;
                const uiModelPlotsByKey = {};
                model.sf_uiModel.allPlots.forEach(function (plot) {
                    uiModelPlotsByKey[plot.uniqueKey] = plot;
                });

                angular.forEach(plotKeyToInfoMap, function (plot, plotKey) {
                    //Disable throttling if a select block is being used due to how SAMPLE works.
                    //we didnt actually throttle this output if top or bottom was present
                    if (uiModelPlotsByKey[plotKey].invisible) {
                        return;
                    }
                    const hasTopOrBottom = model.sf_uiModel.allPlots.some(function (plotModel) {
                        if (plotModel.uniqueKey !== parseInt(plotKey, 10)) return;
                        return plotModel.dataManipulations.some(function (manip) {
                            return manip.fn.type === 'TOPN' || manip.fn.type === 'BOTTOMN';
                        });
                    });
                    if (plot.timeSeriesPrePublish > 100 && !hasTopOrBottom) {
                        dataThrottled = true;
                        throttleCount += plot.timeSeriesPrePublish - 100;
                        passThruCount += 100;
                    } else {
                        passThruCount += plot.timeSeriesPrePublish;
                    }
                });
                return {
                    dataThrottled: dataThrottled,
                    throttleCount: throttleCount,
                    passThruCount: passThruCount,
                };
            },
            getSaveableModel: function (chartModel, overriddenChartconfig) {
                const model = angular.copy(chartModel);
                // Save original model chartconfig values, before they were overridden
                angular.extend(model.sf_uiModel.chartconfig, overriddenChartconfig);

                if (model.sf_uiModel.chartMode !== 'graph') {
                    model.sf_jobResolution = parseInt(
                        model.sf_uiModel.chartconfig.updateInterval,
                        10
                    );
                } else {
                    const resolution = parseInt(model.sf_uiModel.chartconfig.forcedResolution, 10);
                    if (!isNaN(resolution) && resolution > 0) {
                        model.sf_jobResolution = resolution;
                    } else {
                        delete model.sf_jobResolution;
                    }
                }
                return model;
            },
            getNextUniqueKey,
        };

        return service;
    },
]);
