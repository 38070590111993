angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Disk I/O')
                .colorByMetric(true)
                .yAxis(0, { label: 'Operations' });

            ['disk_ops.read', 'disk_ops.write'].forEach(function (metric) {
                chart
                    .plot()
                    .metric(metric)
                    .propertyFilters(filters)
                    .addManipulation(blockService.sum.asAggregation());
            });

            return chart;
        }

        chartTemplates.register('collectdDiskIOOps', createChart);
    },
]);
