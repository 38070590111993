import templateUrl from './download.tpl.html';

/**
 * A simple function which accepts a name and content promise and presents the
 * user with a download modal. Useful for exporting json.
 */
export default [
    'sfxModal',
    '$q',
    '$sce',
    '$log',
    function (sfxModal, $q, $sce, $log) {
        return function (name, content, objectType) {
            sfxModal.open({
                templateUrl,
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.ready = false;
                        $scope.name = name;
                        $scope.objectType = objectType;

                        $q.when(content)
                            .then((content) => {
                                $scope.ready = true;
                                const blob = new Blob([JSON.stringify(content, null, 2)], {
                                    type: 'text/plain;charset=utf-8',
                                    endings: 'transparent',
                                });

                                const url = URL.createObjectURL(blob);
                                $scope.content = $sce.trustAsResourceUrl(url);
                            })
                            .catch((e) => {
                                $log.error(e);
                                $scope.error = e.message;
                            });
                    },
                ],
                windowClass: 'obj-export-modal',
                size: 'sm',
                backdrop: true,
            });
        };
    },
];
