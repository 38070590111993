import templateUrl from '../../../app/dashboard/sourceFiltersIsolate.tpl.html';
import { bindings } from './sourceFiltersIsolate';
import { withNg2React } from '../../../common/util/Ng2React';

const SourceFiltersIsolateComponent = {
    templateUrl,
    bindings,
};

export const SourceFiltersIsolateReact = withNg2React({
    name: 'source-filters-isolate',
    definition: SourceFiltersIsolateComponent,
});
