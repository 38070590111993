import infoSidebar from './infoSidebar';
import analyzerSidebarWrapper from './wrappers/analyzerSidebarWrapper';
import infoSidebarUtil from './infoSidebarUtil';
import infopanelSidebarWrapper from './wrappers/infopanelSidebarWrapper';
import { withNg2React } from '../../../../common/util/Ng2React';

const SIDEBAR_EVENTS = {
    OPEN_SIDEBAR: 'open detail sidebar',
};

angular
    .module('signalview.infoSidebarModule', [])
    .service('infoSidebarUtil', infoSidebarUtil)
    .component('analyzerSidebarWrapper', analyzerSidebarWrapper)
    .component('infopanelSidebarWrapper', infopanelSidebarWrapper)
    .component('infoSidebar', infoSidebar)
    .constant('SIDEBAR_EVENTS', SIDEBAR_EVENTS);

const InfoSidebarReact = withNg2React({ name: 'InfoSidebar', definition: infoSidebar });

// the export below is used by the olly repo
// eslint-disable-next-line import/no-unused-modules
export { InfoSidebarReact };
