export const ThresholdCorrelator = [
    'TypedCorrelationStore',
    '$log',
    'chartbuilderUtil',
    function (TypedCorrelationStore, $log, chartbuilderUtil) {
        function ThresholdCorrelator() {
            this.thresholdTypeMap = {};
            this.hasThresholds = false;
            this.hasMoreThanOneThresholdPerType = false;
        }

        ThresholdCorrelator.prototype.addThreshold = function (metadata) {
            if (metadata.sfui_streamType !== 'signal') {
                this.hasThresholds = true;
                const key = metadata.sfui_state + metadata.sfui_orientation;

                if (!this.thresholdTypeMap[key]) {
                    this.thresholdTypeMap[key] = new TypedCorrelationStore();
                } else {
                    this.hasMoreThanOneThresholdPerType = true;
                }

                this.thresholdTypeMap[key].addThreshold(metadata);
            } else {
                $log.error('A signal was provided to add threshold.');
            }
        };

        ThresholdCorrelator.prototype.addThresholdFromUiConfig = function (metadata, plots) {
            if (metadata.sfui_config) {
                this.hasThresholds = true;
                angular.forEach(metadata.sfui_config, (config) => {
                    const key = config.sfui_state + config.sfui_orientation;
                    if (!this.thresholdTypeMap[key]) {
                        this.thresholdTypeMap[key] = new TypedCorrelationStore(true);
                    } else {
                        this.hasMoreThanOneThresholdPerType = true;
                    }
                    const newMetadata = {};
                    Object.assign(newMetadata, config);
                    Object.assign(newMetadata, metadata);

                    if (config.sfui_targetPlot) {
                        const uniqueKey = chartbuilderUtil.getUniqueKeyFromLetter(
                            config.sfui_targetPlot
                        );
                        const plot = plots.find(
                            (plot) => !plot.transient && plot.uniqueKey === uniqueKey
                        );
                        if (plot) {
                            newMetadata.sf_originatingMetric = plot.seriesData.metric;
                        }
                    }
                    this.thresholdTypeMap[key].addThreshold(newMetadata);
                });
            } else {
                $log.error('A signal was provided to add threshold.');
            }
        };

        ThresholdCorrelator.prototype.getCorrelatedTSIDs = function (metadata) {
            let resp = [];
            angular.forEach(this.thresholdTypeMap, function (store) {
                resp = resp.concat(store.getCorrelatedTSIDs(metadata));
            });
            return resp;
        };

        ThresholdCorrelator.prototype.hasMultiple = function () {
            return this.hasMoreThanOneThresholdPerType;
        };

        ThresholdCorrelator.prototype.hasAnyThresholds = function () {
            return this.hasThresholds;
        };

        return ThresholdCorrelator;
    },
];
