import templateUrl from './standaloneChart.tpl.html';

export default {
    bindings: {
        data: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        'programTextUtils',
        'urlOverridesService',
        'timeToRange',
        'chartDisplayUtils',
        'timeZoneService',
        'STATIC_RESOURCE_ROOT',
        'chartVersionService',
        'setUrlParamsForSnapshot',
        function (
            $scope,
            programTextUtils,
            urlOverridesService,
            timeToRange,
            chartDisplayUtils,
            timeZoneService,
            STATIC_RESOURCE_ROOT,
            chartVersionService,
            setUrlParamsForSnapshot
        ) {
            const $ctrl = this;
            let data;

            $ctrl.$onInit = initialize;
            $scope.STATIC_RESOURCE_ROOT = STATIC_RESOURCE_ROOT;
            $scope.timezone = timeZoneService.getUtcOffset();

            function initialize() {
                data = $ctrl.data;
                $scope.data = data;

                const timePicker = urlOverridesService.getGlobalTimePicker();

                let chart = data.chart;
                const dashboard = data.dashboard;
                if (dashboard && dashboard.filters && dashboard.filters.variables) {
                    $scope.filterAlias = dashboard.filters.variables;
                }

                if (chartVersionService.getVersion(chart || {}) === 2) {
                    // convert v2 to v1 model
                    chart = chartDisplayUtils.createConformingModelFromV2(chart);
                } else {
                    // v1
                    programTextUtils.refreshProgramText(chart);
                }

                $scope.model = chart;
                $scope.snapshot = data.snapshot;

                setUrlParamsForSnapshot(data.snapshot || {}, data.dashboard);

                const chartconfig = chart.sf_uiModel.chartconfig;
                if (timePicker) {
                    // apply time picker config if provided
                    const timeConfig = timeToRange(timePicker);
                    angular.extend(chartconfig, timeConfig);
                }

                // don't know if this is needed but if there's no time config specified
                // default to -15hm
                if (!chartconfig.absoluteEnd && !chartconfig.absoluteStart && !chartconfig.range) {
                    chartconfig.range = -900000;
                }

                // check and override time to absolute value
                const forceAbsolute = urlOverridesService.getForceAbsolute();
                if (chartconfig.range && forceAbsolute) {
                    chartconfig.absoluteStart = forceAbsolute - Math.abs(chartconfig.range);
                    // todo will have to fix with max delay or force give me what you have flag.
                    chartconfig.absoluteEnd = forceAbsolute - (Math.abs(chartconfig.rangeEnd) || 0);
                    delete chartconfig.range;
                    delete chartconfig.rangeEnd;
                }
                chartDisplayUtils.applyPointDensity(
                    chart,
                    urlOverridesService.getPointDensity() || null
                );

                chartconfig.maxDelay = 1;
            }
        },
    ],
};
