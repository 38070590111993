import { supportService } from '@splunk/olly-services/lib';

export default {
    template: '<div></div>',
    controller: [
        '$rootScope',
        '$location',
        'auth',
        function ($rootScope, $location, auth) {
            /*
        When we are parking the browser, listen to route change.
        When route change happens, see if there're access token and org in the parameters
        and use that to set the appropriate token and org.
       */
            const unregister = $rootScope.$on('React:$routeChangeStart', function () {
                if ($location.search() && $location.search().accessToken) {
                    const accessToken = $location.search().accessToken;
                    const targetOrg = $location.search().targetOrg;

                    auth.setAuth(accessToken);
                    supportService.setSupportOrg(targetOrg);
                }
                unregister();
            });

            // always clear any auth when loaded and expect the redirection to contain auth information
            auth.clearAuth();
        },
    ],
};
