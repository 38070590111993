import templateUrl from './barChart.tpl.html';

export const barChart = {
    templateUrl,
    bindings: {
        latestValue: '<?',
        colorByValueScale: '<?',
        mini: '<?',
        disableAnimations: '<?',
        alertState: '<?',
        inEditor: '<?',
        chartModel: '<?',
    },
    controller: [
        '$scope',
        '$element',
        '$timeout',
        'd3',
        'CHART_DISPLAY_EVENTS',
        'colorByValueService',
        function ($scope, $element, $timeout, d3, CHART_DISPLAY_EVENTS, colorByValueService) {
            const ctrl = this;

            let paddingLeft = 20;
            let clipHeight;
            let clipWidth;
            let width;
            let svg;
            let labelFormat;
            let scale;
            let thresholds;
            let maxValue;
            let minValue;
            let displayLine;
            let trianglePath;
            let resetDebounced;
            let isActiveAlertState;
            ctrl.$onInit = configure;
            ctrl.$onChanges = $onChanges;

            function configure() {
                calculate();
                svg = d3
                    .select($element.find('.bar-area')[0])
                    .append('svg:svg')
                    .attr('class', 'bar-chart')
                    .attr('width', clipWidth)
                    .attr('height', clipHeight);
                labelFormat = d3.format(',g');
                scale = d3.scale.linear().range([0, 1]).domain([minValue, maxValue]);
                if (width !== 0) {
                    render();
                    update(ctrl.latestValue);
                }
            }

            $scope.$watch('$ctrl.colorByValueScale', resetWithTimer, true);
            $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, () => {
                const currentWidth = $element.find('.bar-area').width();
                if (currentWidth !== clipWidth) {
                    resetWithTimer();
                }
            });

            function $onChanges(changesObj) {
                const { latestValue } = changesObj;

                const alertState =
                    changesObj && changesObj.alertState && changesObj.alertState.currentValue;
                /**
                 * When alert state is propagated from the async call, re-calculate and re-render the svg
                 * to account for the marquee (active alert link in replace of the chart description).
                 */
                if (alertState && changesObj.alertState.currentValue.alertState) {
                    isActiveAlertState = true;
                    reset();
                } else if (alertState && !changesObj.alertState.currentValue.alertState) {
                    isActiveAlertState = false;
                }

                if (changesObj && displayLine && latestValue && latestValue !== '-') {
                    update(ctrl.latestValue);
                }
            }

            function resetWithTimer() {
                if (resetDebounced) {
                    $timeout.cancel(resetDebounced);
                }
                resetDebounced = $timeout(reset, 500);
            }
            function reset() {
                d3.select(angular.element('.bar-area svg', $element)[0]).remove();
                configure();
                resetDebounced = null;
            }

            function calculate() {
                thresholds = [];
                if (ctrl.colorByValueScale && ctrl.colorByValueScale.length) {
                    thresholds = ctrl.colorByValueScale.map((rule) => rule.gt || rule.gte || 0);
                }
                maxValue = 100;
                if (ctrl.colorByValueScale[0] && ctrl.colorByValueScale[0].lte) {
                    maxValue = ctrl.colorByValueScale[0].lte;
                }

                const lastThreshold = ctrl.colorByValueScale[ctrl.colorByValueScale.length - 1];
                minValue = 0;
                if (lastThreshold && lastThreshold.gte) {
                    minValue = lastThreshold.gte;
                }
                thresholds.push(maxValue);
                if (!ctrl.colorByValueScale || !ctrl.colorByValueScale.length) {
                    // if color by value scale is not set
                    thresholds.push(minValue);
                }
                thresholds.sort((a, b) => a - b);

                clipWidth = $element.find('.bar-area').width();
                clipHeight = $element.find('.bar-area').height();

                width = clipWidth - 50;
                if (width < 0) {
                    width = 0;
                }

                if (ctrl.mini) {
                    width = clipWidth - 10;
                    paddingLeft = 5;
                }
            }

            /**
             * Returns the Y coordinates for the bar chart svg components (e.g. rectangle, line, triangle).
             * This is here as we need to adjust the Y coordinate of the svg when marquee (active alert
             * from smokey) is present. Given adjustments to the padding for bar charts for the marquee,
             * we also need to adjust these coordinates if the bar chart is being viewed in chart editor.
             */
            function getYCoordinates() {
                let rectY;
                let labelY;
                let triangleY;
                let lineY;
                if (ctrl.inEditor) {
                    rectY = 100;
                    labelY = 85;
                    triangleY = 15;
                    lineY = 15;
                } else if (isActiveAlertState) {
                    rectY = 75;
                    labelY = 60;
                    triangleY = -10;
                    lineY = -10;
                } else {
                    rectY = 85;
                    labelY = 70;
                    triangleY = 0;
                    lineY = 0;
                }

                return {
                    rectY: rectY,
                    labelY: labelY,
                    triangleY: triangleY,
                    lineY: lineY,
                };
            }

            function render() {
                const g = svg.append('g');

                let previousValue = 0;
                let previousX = 0;
                const yValues = getYCoordinates();
                const y = yValues.rectY;
                g.selectAll('rect')
                    .data(thresholds)
                    .enter()
                    .append('rect')
                    .attr('x', function (d) {
                        const valueToReplace = previousX;
                        previousX = paddingLeft + scale(d) * width;
                        return valueToReplace;
                    })
                    .attr('y', ctrl.mini ? 18 : y)
                    .attr('fill', function (d) {
                        return colorByValueService.getColorForUpperBound(ctrl.colorByValueScale, d);
                    })
                    .attr('width', function (d) {
                        const valueToReplace = previousValue;
                        previousValue = scale(d) * width;
                        return scale(d) * width - valueToReplace;
                    })
                    .attr('height', ctrl.mini ? 7 : 18);

                const lineLength = 105;
                if (!ctrl.mini) {
                    const lg = svg
                        .append('g')
                        .attr('class', 'bar-label')
                        .attr('transform', 'translate(-10, 10)');
                    lg.selectAll('text')
                        .data(thresholds)
                        .enter()
                        .append('text')
                        .attr('transform', function (d) {
                            return (
                                'translate(' +
                                (scale(d) * width + paddingLeft) +
                                ',' +
                                yValues.labelY +
                                ')'
                            );
                        })
                        .text(labelFormat);

                    const triangle = svg
                        .append('g')
                        .attr(
                            'transform',
                            'translate(' + paddingLeft + ',' + (lineLength - 5) + ')'
                        );
                    trianglePath = triangle
                        .append('path')
                        .attr('class', 'display-triangle')
                        .attr('d', d3.svg.symbol().type('triangle-up').size(80));
                }

                const line = svg.append('g').attr('transform', 'translate(' + paddingLeft + ', 0)');
                displayLine = line
                    .append('line')
                    .attr('class', 'display-line' + (ctrl.mini ? ' mini' : ''))
                    .attr('y1', ctrl.mini ? 5 : 0)
                    .attr('y2', ctrl.mini ? 32 : lineLength);
            }

            function update(value) {
                let translateX = scale(value) * width;
                if (value > maxValue) {
                    translateX = width + 5;
                } else if (value < minValue) {
                    translateX = -5;
                }

                if (value === undefined || value === null) {
                    translateX = -5;
                }

                const duration = 750;
                let displayLineToUpdate = displayLine;
                if (!ctrl.disableAnimations) {
                    displayLineToUpdate = displayLineToUpdate
                        .transition()
                        .duration(duration)
                        .ease('elastic');
                }

                const yValues = getYCoordinates();

                displayLineToUpdate.attr(
                    'transform',
                    'translate(' + translateX + ', ' + yValues.lineY + ')'
                );

                if (trianglePath) {
                    let trianglePathToUpdate = trianglePath;
                    if (!ctrl.disableAnimations) {
                        trianglePathToUpdate = trianglePathToUpdate
                            .transition()
                            .duration(duration)
                            .ease('elastic');
                    }
                    trianglePathToUpdate.attr(
                        'transform',
                        'translate(' + translateX + ', ' + yValues.triangleY + ')'
                    );
                }
            }

            resetWithTimer();
        },
    ],
};
