import templateUrl from './alertIncidents.tpl.html';
import removedDetectorsStore from '../../../app/alerts/common/removedDetectorsStore.ts';
import resolvedAlertsStore from '../../../app/alerts/common/resolvedAlertsStore';
import { detectorTypeResolver } from '../../../app/alerts/detectorTypeResolver';
import {
    DetectorOrigin,
    DetectorOriginLabels,
} from '../../../common/ui/detectors/DetectorOriginLabels';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular
    .module('signalview.alertsOverview')
    .directive('alertIncidents', [
        'eventService',
        'featureEnabled',
        '$q',
        'resolveAlert',
        'eventModal',
        'ES_INDEXING_DELAY',
        '$timeout',
        'deleteDetector',
        '$window',
        'detectorService',
        'mutingService',
        'alertTypeService',
        'teamLinkingService',
        'detectorSettingsModal',
        'hasCapability',
        'writepermissionsPermissionsChecker',
        function (
            eventService,
            featureEnabled,
            $q,
            resolveAlert,
            eventModal,
            ES_INDEXING_DELAY,
            $timeout,
            deleteDetector,
            $window,
            detectorService,
            mutingService,
            alertTypeService,
            teamLinkingService,
            detectorSettingsModal,
            hasCapability,
            writepermissionsPermissionsChecker
        ) {
            return {
                restrict: 'E',
                scope: {
                    incidentList: '=',
                    detectorCache: '=',
                },
                templateUrl,
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.$watch('incidentList.groupBy', function () {
                            $scope.detectorView =
                                $scope.incidentList &&
                                $scope.incidentList.groupBy &&
                                $scope.incidentList.groupBy.indexOf('sf_detectorId') !== -1;
                        });

                        $scope.deleteModalState = {
                            isOpen: false,
                            onCancel: function () {
                                $timeout(() => {
                                    $scope.deleteModalState.isOpen = false;
                                }, 0);
                            },
                        };

                        function getEvent(ets) {
                            const modtime = ets.original['sf_anomalyStateUpdateTimestampMs'];
                            if (!modtime) {
                                // WTH
                                $window.alert('Event activity not available');
                                return $q.reject('Event cannot be found');
                            }
                            return eventService
                                .search({
                                    query:
                                        'sf_tsId:"' +
                                        ets.original.sf_id +
                                        '" AND is:(' +
                                        alertTypeService.getFiringStateQueryStr() +
                                        ')',
                                    from: modtime,
                                    orderBy: '-sf_timestamp',
                                    limit: 1,
                                    offset: 0,
                                })
                                .then(function (data) {
                                    if (data && data.length) {
                                        return $q.when(data[0]);
                                    } else {
                                        //cant find event? should not happen in real life.
                                        return $q.reject('Event cannot be found');
                                    }
                                })
                                .finally(() => {
                                    $scope.$applyAsync();
                                });
                        }

                        function reloadList() {
                            $scope.$emit('reload event list');
                        }

                        hasCapability(Capability.DELETE_DETECTOR).then(
                            (hasDeleteDetectorCapability) => {
                                $scope.hasDeleteDetectorCapability = hasDeleteDetectorCapability;
                            }
                        );

                        hasCapability(Capability.UPDATE_DETECTOR).then(
                            (hasUpdateDetectorCapability) => {
                                $scope.hasUpdateDetectorCapability = hasUpdateDetectorCapability;
                            }
                        );

                        hasCapability(Capability.CREATE_MUTING_RULE).then(
                            (hasCreateMutingRuleCapability) => {
                                $scope.hasCreateMutingRuleCapability =
                                    hasCreateMutingRuleCapability;
                            }
                        );

                        hasCapability(Capability.UPDATE_INCIDENT).then(
                            (hasUpdateIncidentCapability) => {
                                $scope.hasUpdateIncidentCapability = hasUpdateIncidentCapability;
                            }
                        );

                        hasCapability(Capability.READ_EVENT).then((hasReadEventCapability) => {
                            $scope.hasReadEventCapability = hasReadEventCapability;
                        });

                        $scope.rowClicked = function (ets) {
                            if (ets.event) {
                                eventModal(ets.event);
                            } else {
                                getEvent(ets).then(eventModal, function () {
                                    $window.alert('Event cannot be found');
                                });
                            }
                        };

                        function getPropertyForItem(item) {
                            const filter = {};
                            // go from leave to root node.
                            let counter = 100;
                            while (item && --counter > 0) {
                                if (item.by && item.key) {
                                    filter[item.by] = item.key;
                                }
                                item = item.parent;
                            }
                            return filter;
                        }

                        $scope.severityClick = function (severity, item) {
                            const filter = getPropertyForItem(item);
                            filter.sf_severity = severity;
                            $scope.$emit('severity clicked', filter);
                        };

                        function toggleItemShow(item, show) {
                            // toggle this item show/hide and also toggle the child depending on the expand state.
                            item.show = show;
                            if (item.child) {
                                for (let i = 0; i < item.child.length; i++) {
                                    toggleItemShow(item.child[i], show && item.expanded);
                                }
                            }
                        }

                        $scope.toggleItem = function (item) {
                            // toggle current item and flip all the child show/hide
                            item.expanded = !item.expanded;
                            if (item.child) {
                                for (let i = 0; i < item.child.length; i++) {
                                    toggleItemShow(item.child[i], item.expanded);
                                }
                            }
                        };

                        $scope.$on('alert resolved in modal', reloadList);

                        $scope.resolve = function (ets) {
                            ets.resolving = true;
                            getEvent(ets)
                                .then(
                                    function (event) {
                                        return resolveAlert(event.properties.incidentId).then(
                                            () =>
                                                featureEnabled('resolveAlert') &&
                                                handleResolvedAlerts(event.properties.incidentId)
                                        );
                                    },
                                    function () {
                                        // in case there's no event found or what not, at least we
                                        // want to clear this ETS.
                                        return resolveAlert(ets.original.sf_incidentId).then(
                                            () =>
                                                featureEnabled('resolveAlert') &&
                                                handleResolvedAlerts(ets.original.sf_incidentId)
                                        );
                                    }
                                )
                                .then(function () {
                                    return (
                                        !featureEnabled('resolveAlert') &&
                                        $timeout(reloadList, ES_INDEXING_DELAY)
                                    );
                                })
                                .finally(function () {
                                    ets.resolving = false;
                                });
                        };

                        function handleResolvedAlerts(alertId) {
                            resolvedAlertsStore.pushId(alertId);
                            reloadList();
                        }

                        function getDetector(detectorId) {
                            return detectorService.get(detectorId);
                        }

                        function handleDetectorDeleted(detectorId) {
                            removedDetectorsStore.pushId(detectorId);
                            reloadList();
                        }

                        $scope.deleteDetector = function (detectorId) {
                            $scope.deleteModalState.isOpen = true;
                            $scope.deleteModalState.isLoading = true;

                            getDetector(detectorId).then(function (detector) {
                                $scope.deleteModalState.onDelete = function () {
                                    $timeout(function () {
                                        $scope.deleteModalState.isOpen = false;
                                        deleteDetector(detector).then(() =>
                                            handleDetectorDeleted(detectorId)
                                        );
                                    }, 0);
                                };

                                $timeout(function () {
                                    const name = detector.sf_detector || detector.name;

                                    $scope.deleteModalState.title = 'Delete Detector';
                                    $scope.deleteModalState.description =
                                        'You are about to permanently delete the detector ' +
                                        name +
                                        '. Any alerts from this detector will stop alerting.';
                                    $scope.deleteModalState.isLoading = false;
                                }, 0);
                            });
                        };

                        $scope.isOOTBDetector = (detectorId) => {
                            const detector = $scope.detectorCache[detectorId];
                            return detectorTypeResolver.isOOTBDetector(detector);
                        };

                        $scope.isOOTBCustomizationDetector = (detectorId) => {
                            const detector = $scope.detectorCache[detectorId];
                            return detectorTypeResolver.isOOTBCustomizationDetector(detector);
                        };

                        $scope.isOOTBDetectorEvent = (event) => {
                            return detectorTypeResolver.isOOTBDetectorEvent(event.item?.original);
                        };

                        $scope.isOOTBCustomizationDetectorEvent = (event) => {
                            return detectorTypeResolver.isOOTBCustomizationDetectorEvent(
                                event.item?.original
                            );
                        };

                        $scope.isSLODetectorEvent = (event) => {
                            return detectorTypeResolver.isSLODetectorEvent(event.item?.original);
                        };

                        $scope.OOTBDetectorLabel = `${
                            DetectorOriginLabels[DetectorOrigin.AUTO_DETECT]
                        } Detector`;
                        $scope.OOTBDetectorEventLabel = `${
                            DetectorOriginLabels[DetectorOrigin.AUTO_DETECT]
                        } Detector event`;
                        $scope.OOTBCustomizationDetectorLabel = `${
                            DetectorOriginLabels[DetectorOrigin.AUTO_DETECT_CUSTOMIZATION]
                        } Detector`;
                        $scope.OOTBCustomizationDetectorEventLabel = `${
                            DetectorOriginLabels[DetectorOrigin.AUTO_DETECT_CUSTOMIZATION]
                        } Detector event`;

                        $scope.hasDetectorWritePermission = function (detectorId) {
                            return $scope.detectorCache[detectorId]?.hasWritePermission === true;
                        };

                        $scope.openPermissionsModal = function (detectorId) {
                            return getDetector(detectorId)
                                .then(detectorSettingsModal.permissions)
                                .then((detector) => {
                                    return writepermissionsPermissionsChecker.hasWritePermissions(
                                        detector
                                    );
                                })
                                .then((hasWritePermission) => {
                                    $scope.detectorCache[detectorId].hasWritePermission =
                                        hasWritePermission;
                                });
                        };

                        $scope.subscribe = function (detectorId) {
                            getDetector(detectorId).then((detector) => {
                                detectorSettingsModal.showSubscribeDetectorModal(detector);
                            });
                        };

                        $scope.openMutingModal = (muting, filters) => {
                            mutingService.openMutingModal(muting, filters).then(reloadList);
                        };

                        $scope.getFormattedMutingDurationAndCreator =
                            mutingService.getFormattedDurationAndCreator;
                        $scope.getMutingTooltip = mutingService.getMutingTooltip;

                        $scope.showTeamRelationEditor = function (detectorId) {
                            return detectorService.get(detectorId).then(function (detector) {
                                return teamLinkingService.editDetector(detector);
                            });
                        };

                        $scope.mute = function (item) {
                            const filters = [];
                            let properties;
                            if (item.item) {
                                properties = item.item.properties;
                            } else {
                                properties = getPropertyForItem(item);
                            }
                            angular.forEach(properties, function (value, key) {
                                const item = { property: key, propertyValue: value };
                                if (value === 'n/a') {
                                    item.NOT = true;
                                    item.propertyValue = '*';
                                }
                                filters.push(item);
                            });

                            $scope.openMutingModal(null, filters);
                        };

                        $scope.deleteMuting = (muting) => {
                            $scope.deleteModalState.onDelete = function () {
                                $timeout(() => {
                                    $scope.deleteModalState.isOpen = false;
                                    mutingService.deleteMuting(muting).then(reloadList);
                                }, 0);
                            };

                            $scope.deleteModalState.isOpen = true;
                            $scope.deleteModalState.title = 'Delete Muting Rule';
                            $scope.deleteModalState.description =
                                'You are about to delete this muting rule. Are you sure?';
                            $scope.deleteModalState.callToAction = 'Delete';
                        };

                        $scope.endMuting = (muting) => {
                            $scope.deleteModalState.onDelete = function () {
                                $timeout(() => {
                                    $scope.deleteModalState.isOpen = false;
                                    mutingService.endMuting(muting).then(reloadList);
                                }, 0);
                            };

                            $scope.deleteModalState.isOpen = true;
                            $scope.deleteModalState.title = 'Resume Notifications';
                            $scope.deleteModalState.description =
                                'You are about to resume notifications. Are you sure?';
                            $scope.deleteModalState.callToAction = 'Resume';
                        };
                    },
                ],
            };
        },
    ])
    .filter('alertKeyAlias', [
        function () {
            const alias = {
                sf_detector: 'Detector',
                sf_detectorId: 'Detector',
            };

            return function (input) {
                return alias[input] || input;
            };
        },
    ]);
