export default [
    '$http',
    '$log',
    '$q',
    'createV2Service',
    function ($http, $log, $q, createV2Service) {
        const v2Svc = createV2Service('crosslink');

        return angular.extend(v2Svc, {
            getAll,
            getGlobalCrossLinks,
            searchByContext,
            searchByTrigger,
            bulkCreate,
        });

        function getAll() {
            return $http
                .get(v2Svc.resourceEndpoint + '/_/all')
                .then((response) => response.data)
                .catch((e) => {
                    $log.error('Failed to retrieve cross links');
                    return $q.reject(e);
                });
        }

        function bulkCreate(crossLinks) {
            return $http
                .post(v2Svc.resourceEndpoint + '/_/bulk', crossLinks)
                .then((res) => res.data);
        }

        function searchByContext(dashboardId, options = {}) {
            const { orderBy, limit, offset } = options;
            const searchParams = {
                orderBy,
                limit,
                offset,
                context: dashboardId,
            };

            return searchWithUiFields(searchParams);
        }

        function searchByTrigger(propertyName, propertyValue, context = 'global', options = {}) {
            const { orderBy, limit, offset } = options;
            const searchParams = {
                context: context,
                propertyName,
                orderBy,
                limit,
                offset,
            };

            if (propertyValue && propertyValue !== '*') {
                searchParams.propertyValue = propertyValue;
            }

            return searchWithUiFields(searchParams);
        }

        function getGlobalCrossLinks(options = {}) {
            const { orderBy, limit, offset } = options;
            const searchParams = {
                context: 'global',
                orderBy,
                limit,
                offset,
            };

            return searchWithUiFields(searchParams);
        }

        // uses internal search endpoint, which does a secondary lookup on
        // the target object to get the name (and potentially other info)
        function searchWithUiFields(searchParams) {
            return $http
                .get(v2Svc.resourceEndpoint + '/_/search', { params: searchParams })
                .then((response) => response.data);
        }
    },
];
