import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AccessControlPermissionTypes } from '@splunk/olly-services';
import Tooltip from '@splunk/react-ui/Tooltip';
import Multiselect from '@splunk/react-ui/Multiselect';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Link from '@splunk/react-ui/Link';
import Message from '@splunk/react-ui/Message';
import { variables } from '@splunk/themes';
import useAclOrgPreferencesContext from '../../../../../common/ui/accessControl/useAclOrgPreferencesContext';
import useAclUserContext from '../../../../../common/ui/accessControl/useAclUserContext';
import AccessControlObjectType from '../../../../../common/ui/accessControl/AccessControlObjectType';
import accessControlHelper from '../../../../../common/ui/accessControl/accessControlHelper';
import AccessControl from '../../../../../common/ui/accessControl/AccessControl';
import AuthScopeCheckboxes from './authScopeCheckboxes';
import useInputState from '../../../../../common/react-utils/useInputState';
import AclFormInput from '../../../../../common/ui/accessControl/AclFormInput';
import { AUTH_SCOPES } from './ingestTokenConstants';
import useAclPermissions from '../../../../../common/ui/accessControl/useAclPermissions';
import { AclFormContext } from '../../../../../common/ui/accessControl/AclFormContext';
import {
    StyledBody,
    StyledButton,
    StyledFooter,
    StyledForm,
    StyledModalHeader,
    StyledPanel,
} from '../../../../../common/ui/ModalStyles';
import { ThemeProvider } from '../../../../../common/theme/ThemeProvider';
import styled from 'styled-components';

const StyledMultiselect = styled(Multiselect)`
    font-size: ${variables.fontSizeSmall};
    line-height: 16px;
    width: 600px !important;
    button {
        svg {
            width: ${variables.spacingMedium};
            height: ${variables.spacingMedium};
        }
    }
    input {
        height: ${variables.spacingXLarge};
    }
`;

const StyledMessage = styled(Message)`
    margin-top: ${(props) =>
        props.isPopOverClose
            ? variables.spacingLarge
            : `calc(${props.remainingRolesLength * 32}px + ${
                  props.remainingRolesLength ? '32px' : '64px'
              }) `};
`;

export default function CreateNamedTokenModal({
    themeKey,
    userData,
    onDismiss,
    oldTokenName,
    oldPermissions,
    tokenNames,
    orgHasRum,
    onConfirmNewToken,
    accessControlEnabled,
    newRbacExperienceEnabled,
    oldAuthScopes = [],
    oldRoles = [],
    allRoles = [],
}) {
    const allRolesTitle = allRoles.map((role) => role.title).sort();
    const [selectedRoleValues, setSelectedRoleOptionValues] = useState([]);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [isPopOverClose, setPopOverClose] = useState(true);
    const userContext = useAclUserContext(userData);
    const orgPrefContext = useAclOrgPreferencesContext(userContext.orgId);
    const OBJECT_TYPE = AccessControlObjectType.NAMED_TOKEN;

    const initPermissions = oldPermissions
        ? oldPermissions
        : {
              acl: accessControlHelper.getDefaultAcl(
                  userContext.orgId,
                  userContext.userId,
                  AccessControlPermissionTypes.INHERIT
              ),
              parent: null,
          };
    const [defaultPermissions] = useState(initPermissions);

    const label = 'Access Token';
    const POWER_ROLE = 'power';
    const USER_ROLE = 'user';
    const NEW_ACCESS_TOKEN_LABEL = oldTokenName ? `Edit Your ${label}` : `Create Your ${label}`;
    const OK_BUTTON_LABEL = oldTokenName ? 'Update' : 'Create';
    const VALIDATION_ERROR_MESSAGE = {
        NO_NAME: 'Please enter a name for the access token',
        SAME_NAME: 'Please enter a new name for the access token',
        DUPLICATE_NAME: 'Name already in use. Please enter a new access token name',
        CONTAINS_ZERO_WIDTH_CHARACTER: 'Name cannot contain zero-width characters',
        EDIT_DUPLICATE_NAME:
            'Please enter a new name. To keep the same name leave this field blank',
        NO_AUTH_SCOPES: 'Please select an authorization scope for the access token',
        WARNING_NOT_ACCEPTED:
            'Please accept the warning for selecting multiple scopes for the same token',
        ROLES_NOT_SELECTED: 'Please select a role for the access token',
    };

    const WARNING_MESSAGE_FOR_ROLE_CHANGE =
        "Warning! Changing assigned role on a token may result in change in token's capabilities. Please be sure, role being assigned provides needed capabilities. ";

    const {
        permissions,
        update: updatePermissions,
        isLoading,
    } = useAclPermissions(userContext, null, defaultPermissions);

    const [name, setName] = useInputState(oldTokenName ? oldTokenName : '');
    const [authScopes, setAuthScopes] = useState({
        [AUTH_SCOPES.RUM]: false,
        [AUTH_SCOPES.INGEST]: false,
        [AUTH_SCOPES.API]: true,
    });

    const [multiScopeWarning, setMultiScopeWarning] = useState(false);

    const handleClick = (e, { value }) => {
        setMultiScopeWarning(false);
        if (value === AUTH_SCOPES.RUM) {
            setAuthScopes({
                [AUTH_SCOPES.RUM]: !authScopes[AUTH_SCOPES.RUM],
                [AUTH_SCOPES.INGEST]: false,
                [AUTH_SCOPES.API]: false,
            });
        } else {
            setAuthScopes({
                ...authScopes,
                [AUTH_SCOPES.RUM]: false,
                [value]: !authScopes[value],
            });
        }
    };

    const handleWarningClick = () => {
        setMultiScopeWarning(!multiScopeWarning);
    };

    useEffect(() => {
        if (newRbacExperienceEnabled) {
            let defaultRoleValues = [];

            if (!oldTokenName && allRolesTitle.length > 0) {
                const isPowerRoleExist = allRolesTitle.includes(POWER_ROLE);
                const isUserRoleExist = allRolesTitle.includes(USER_ROLE);

                if (isPowerRoleExist) {
                    defaultRoleValues = [POWER_ROLE];
                } else if (isUserRoleExist) {
                    defaultRoleValues = [USER_ROLE];
                }
            } else {
                defaultRoleValues = oldRoles;
            }

            setSelectedRoleOptionValues(defaultRoleValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRolesTitle.length, newRbacExperienceEnabled]);

    const roleOptions = allRolesTitle.map((role) => (
        <Multiselect.Option key={role} label={role} value={role} />
    ));

    const isRoleLoading = roleOptions.length === 0;

    const isEditTokenModalVisible =
        newRbacExperienceEnabled && oldTokenName && oldAuthScopes.includes(AUTH_SCOPES.API);

    const handleRoleChange = (e, { values }) => {
        setSelectedRoleOptionValues(values);

        if (isEditTokenModalVisible) {
            const isRoleUpdated = JSON.stringify(values.sort()) !== JSON.stringify(oldRoles.sort());
            setShowWarningMessage(isRoleUpdated);
        }
    };

    const validationErrors = validateForm();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <AclFormContext.Provider
                value={{
                    ...userContext,
                    disabled: isLoading,
                    syncInProgress: isLoading,
                    restrictTeamAccess: orgPrefContext.sf_restrictTeamManagement,
                }}
            >
                <StyledPanel onSubmit={handleSubmit}>
                    <StyledForm>
                        <StyledModalHeader>{NEW_ACCESS_TOKEN_LABEL}</StyledModalHeader>
                        <StyledBody>
                            <AclFormInput
                                name="named-token-name"
                                label="Name"
                                value={name}
                                onChange={setName}
                            />
                            {oldTokenName === '' && (
                                <AuthScopeCheckboxes
                                    orgHasRum={orgHasRum}
                                    handleClick={handleClick}
                                    authScopes={authScopes}
                                    handleWarningClick={handleWarningClick}
                                    multiScopeWarning={multiScopeWarning}
                                    selectedRoleValues={selectedRoleValues}
                                    roleOptions={roleOptions}
                                    handleRoleChange={handleRoleChange}
                                    newRbacExperienceEnabled={newRbacExperienceEnabled}
                                />
                            )}
                            {/* API Roles ControlGroup only visible in the 
                            Edit Token modal and token have a API Authorization scope */}
                            {isEditTokenModalVisible && (
                                <ControlGroup
                                    label="API Roles"
                                    labelPosition="top"
                                    controlsLayout="none"
                                >
                                    <StyledMultiselect
                                        placeholder="Select which roles to grant to the Access Token"
                                        values={selectedRoleValues}
                                        onChange={handleRoleChange}
                                        onOpen={() => setPopOverClose(false)}
                                        onClose={() => setPopOverClose(true)}
                                        isLoadingOptions={isRoleLoading}
                                        loadingMessage="Loading..."
                                        inline
                                    >
                                        {roleOptions}
                                    </StyledMultiselect>
                                    {showWarningMessage && (
                                        <StyledMessage
                                            isPopOverClose={isPopOverClose}
                                            remainingRolesLength={
                                                allRolesTitle.length - selectedRoleValues.length
                                            }
                                            type="warning"
                                        >
                                            <div>
                                                {WARNING_MESSAGE_FOR_ROLE_CHANGE}
                                                <Link
                                                    to="https://quickdraw.splunk.com/redirect/?product=Observability&location=admin.roles.matrices&version=current"
                                                    openInNewContext
                                                >
                                                    Learn more
                                                </Link>
                                            </div>
                                        </StyledMessage>
                                    )}
                                </ControlGroup>
                            )}
                            {accessControlEnabled && (
                                <AccessControl
                                    permissions={permissions}
                                    onChangeCallback={updatePermissions}
                                    objectType={OBJECT_TYPE}
                                    customObjectLabel={label}
                                />
                            )}
                        </StyledBody>
                        <StyledFooter>
                            <StyledButton
                                label="Cancel"
                                appearance="secondary"
                                size="small"
                                onClick={onDismiss}
                            />
                            <Tooltip
                                style={{ marginLeft: 10 }}
                                content={validationErrors.length ? validationErrors[0] : ''}
                            >
                                <StyledButton
                                    label={OK_BUTTON_LABEL}
                                    type="submit"
                                    appearance="primary"
                                    size="small"
                                    disabled={!!validationErrors.length}
                                />
                            </Tooltip>
                        </StyledFooter>
                    </StyledForm>
                </StyledPanel>
            </AclFormContext.Provider>
        </ThemeProvider>
    );

    function validateForm() {
        const validationErrors = oldTokenName ? validateExistingToken() : validateNewToken();

        return [...validationErrors];
    }

    function validateNewToken() {
        const validationErrors = [];

        if (name.trim() === '') {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.NO_NAME);
        } else if (tokenNames.indexOf(name) > -1) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.DUPLICATE_NAME);
        } else if (containsZeroWidthCharacter(name)) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.CONTAINS_ZERO_WIDTH_CHARACTER);
        } else if (Object.keys(authScopes).every((key) => !authScopes[key])) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.NO_AUTH_SCOPES);
        } else if (
            Object.values(authScopes).filter((value) => value).length > 1 &&
            !multiScopeWarning
        ) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.WARNING_NOT_ACCEPTED);
        } else if (
            newRbacExperienceEnabled &&
            authScopes[AUTH_SCOPES.API] &&
            !selectedRoleValues.length
        ) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.ROLES_NOT_SELECTED);
        }

        return validationErrors;
    }

    function validateExistingToken() {
        const validationErrors = [];

        if (name.trim() === '') {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.NO_NAME);
        } else if (oldTokenName !== name && tokenNames.indexOf(name) > -1) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.DUPLICATE_NAME);
        } else if (containsZeroWidthCharacter(name)) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.CONTAINS_ZERO_WIDTH_CHARACTER);
        } else if (
            newRbacExperienceEnabled &&
            oldAuthScopes.includes(AUTH_SCOPES.API) &&
            !selectedRoleValues.length
        ) {
            validationErrors.push(VALIDATION_ERROR_MESSAGE.ROLES_NOT_SELECTED);
        }

        return validationErrors;
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (validationErrors.length) {
            return;
        }

        const isCreateMode =
            !oldTokenName && newRbacExperienceEnabled && authScopes[AUTH_SCOPES.API];

        const selectedPermissions = permissions.acl !== null ? permissions : null;
        let selectedRoleIdsAndTitle = [];
        if (isEditTokenModalVisible || isCreateMode) {
            const filteredRoles = allRoles.filter((role) =>
                selectedRoleValues?.includes(role.title)
            );
            selectedRoleIdsAndTitle = filteredRoles.map((role) => ({
                id: role.id,
                title: role.title,
            }));
        }

        onConfirmNewToken(name, authScopes, selectedPermissions, selectedRoleIdsAndTitle);
    }

    function containsZeroWidthCharacter(name) {
        return /[\u200B-\u200D\uFEFF]/.test(name);
    }
}

CreateNamedTokenModal.propTypes = {
    themeKey: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    onDismiss: PropTypes.func.isRequired,
    oldTokenName: PropTypes.string.isRequired,
    oldPermissions: PropTypes.object.isRequired,
    tokenNames: PropTypes.array.isRequired,
    orgHasRum: PropTypes.bool.isRequired,
    onConfirmNewToken: PropTypes.func.isRequired,
    accessControlEnabled: PropTypes.bool.isRequired,
    newRbacExperienceEnabled: PropTypes.bool.isRequired,
    oldAuthScopes: PropTypes.array,
    oldRoles: PropTypes.array,
    allRoles: PropTypes.array,
};
