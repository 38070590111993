export default [
    'featureEnabled',
    function () {
        function getAllowedTypesForMetadataSearch() {
            return {
                Property: true,
                Dimension: true,
                Tag: true,
            };
        }

        function generateAllowedTypeKeys(types) {
            return Object.keys(types).reduce((result, key) => {
                if (types[key]) {
                    result[key] = true;
                    result[key.toLowerCase()] = true;
                }
                return result;
            }, {});
        }

        return {
            generateAllowedTypeKeys,
            getAllowedTypesForMetadataSearch,
        };
    },
];
