'use strict';
import templateUrl from './dashboardPermissions.tpl.html';

export default {
    templateUrl,
    bindings: {
        dashboardObject: '<',
        dashboardEditable: '<',
        groupName: '<',
        allDashboardConfigs: '<',
        missingUpdateDashboardCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$q',
        '$scope',
        'exitGuardModal',
        'currentUser',
        'dashboardV2Service',
        '$log',
        'featureEnabled',
        'SYSTEM_USER_ID',
        function (
            $q,
            $scope,
            exitGuardModal,
            currentUser,
            dashboardV2Service,
            $log,
            featureEnabled,
            SYSTEM_USER_ID
        ) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;

            let obj, authorizedWriters, originalAuthorizedWriters;
            function $onInit() {
                obj = $ctrl.dashboardObject;
                if (!originalAuthorizedWriters) {
                    originalAuthorizedWriters = angular.copy(obj.authorizedWriters);
                }
                $ctrl.wasCreatedBySystem = obj.creator === SYSTEM_USER_ID;
                authorizedWriters = $ctrl.authorizedWriters =
                    angular.copy(originalAuthorizedWriters) || {};

                currentUser.defaultWritePermissions().then(function (defaults) {
                    $ctrl.defaultWriters = defaults;
                });

                $ctrl.chartIdToWriters = {};
                $ctrl.dashboardViewsEnabled = featureEnabled('dashboardViews');

                currentUser.isAdmin().then((isAdmin) => ($ctrl.userIsAdmin = isAdmin));

                if ($ctrl.allDashboardConfigs) {
                    $ctrl.allDashboardConfigs.then((configs) => {
                        $ctrl.appearanceCount = configs.length;
                    });
                }
            }

            function save() {
                obj.authorizedWriters = authorizedWriters;
                originalAuthorizedWriters = angular.copy(obj.authorizedWriters);
                return dashboardV2Service
                    .update(obj)
                    .catch(function (e) {
                        $log.error('error', e);
                    })
                    .finally(function () {
                        $scope.dashboardPermissionsForm.$setPristine();
                    });
            }

            function reset() {
                $onInit();
                $scope.$broadcast('resetPermissions', $ctrl.authorizedWriters);
                $scope.dashboardPermissionsForm.$setPristine();
                return $q.when();
            }

            function exitGuard() {
                return exitGuardModal(save, reset);
            }

            $ctrl.cancel = function () {
                $scope.$emit('dismiss modal');
            };

            $ctrl.saveAndClose = function () {
                save().then(() => {
                    $scope.$emit('close modal');
                });
            };

            $scope.$watch('dashboardPermissionsForm.$dirty', function (newVal) {
                if (newVal) {
                    $ctrl.tabby.enableExitGuard(exitGuard);
                } else {
                    $ctrl.tabby.disableExitGuard();
                }
            });

            $scope.onChange = function () {
                $scope.dashboardPermissionsForm.$setDirty();
            };
        },
    ],
};
