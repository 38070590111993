'use strict';

import { bucketing } from './bucketing';
import { nestedGroupBy } from './nestedGroupBy';
import routeUtils from './routeUtils';

angular
    .module('sfx.util', [])
    .service('bucketing', bucketing)
    .service('nestedGroupBy', nestedGroupBy)
    .service('routeUtils', routeUtils);
