const NODE_NUMBER_TYPES = ['long', 'double'];

angular.module('signalview.detector').service('blockMapToUIModelRuleConditions', [
    function () {
        const getConditions = (statement) => {
            const conditions = [];
            // notice that when starting, operator is not provided
            // hence the first condition will not receive an operator
            parseNode(statement.start.on);

            return conditions;

            function parseNode(node, operator, lastOperator) {
                // only when leafs and binary expression nodes are handled
                // otherwise UI won't be provided
                if (!isNodeWhenFn(node) && !isNodeBinaryOp(node)) {
                    throw new Error('Unknown trigger type.');
                }

                if (node.op === 'OR' && lastOperator === 'AND') {
                    throw new Error('ANDs MUST be applied before ORs.');
                }

                if (node.op) {
                    lastOperator = node.op;
                }

                // if it's a binary expression then we haven't reached when leafs yet
                // pass operators down to be applied on when leafs later
                if (isNodeBinaryOp(node)) {
                    parseNode(node.left, operator, lastOperator);
                    parseNode(node.right, node.op, lastOperator);
                    return;
                }

                // after the check for binary expression, the only possibility left
                // is a node expression

                const condition = {
                    targetPlot: node.predicate.left.name,
                    triggerMode: 'immediately',
                    duration: '1 second',
                };

                if (isNodeComparisonRule(node.predicate, 'GT')) {
                    condition.above = getSimpleNodeValue(node.predicate.right);
                    condition.thresholdMode = 'above';
                } else if (isNodeComparisonRule(node.predicate, 'LT')) {
                    condition.below = getSimpleNodeValue(node.predicate.right);
                    condition.thresholdMode = 'below';
                } else if (isNodeWithinRange(node)) {
                    condition.thresholdMode = 'within range';
                    condition.below = getSimpleNodeValue(node.predicate.left.right);
                    condition.above = getSimpleNodeValue(node.predicate.right.right);
                    condition.targetPlot = node.predicate.left.left.name;
                } else if (isNodeOutOfRange(node)) {
                    condition.thresholdMode = 'out of range';
                    condition.above = getSimpleNodeValue(node.predicate.left.right);
                    condition.below = getSimpleNodeValue(node.predicate.right.right);
                    condition.targetPlot = node.predicate.left.left.name;
                } else {
                    throw new Error('Unsupported SignalFlowModel');
                }

                // the operator applied on a condition is the operator
                // to be used between this condition and the one before it
                // on the list of conditions
                if (operator) {
                    condition.operator = operator;
                }

                if (node.duration && node.fraction) {
                    condition.duration = node.duration.value;
                    condition.percentOfDuration = percentToHumanValue(node.fraction.value);
                    condition.triggerMode = 'percent of duration';
                } else if (node.duration) {
                    condition.duration = node.duration.value;
                    condition.triggerMode = 'duration';
                    condition.count = 1;
                } else {
                    condition.count = 1;
                }

                conditions.push(condition);
            }

            function percentToHumanValue(numericValue) {
                const numberOfDecimals = countDecimals(numericValue);
                const humanValue = numericValue * 100;
                return parseFloat(humanValue.toFixed(Math.max(0, numberOfDecimals - 2)));
            }

            function countDecimals(value) {
                if (Math.floor(value) === value) {
                    return 0;
                }
                return value.toString().split('.')[1].length || 0;
            }
        };

        function canParse(statement) {
            if (
                !isObject(statement.start) ||
                !isObject(statement.start.on) ||
                !(isNodeBinaryOp(statement.start.on) || isNodeWhenFn(statement.start.on)) ||
                statement.start.off ||
                !Array.isArray(statement.streamMethods) ||
                statement.streamMethods.length !== 1 ||
                statement.streamMethods[0].functionName !== 'publish'
            ) {
                return false;
            }
            return true;
        }

        function isStaticThresholdRule(rule) {
            if (Array.isArray(rule.conditions) && rule.conditions.length !== 1) {
                return false;
            }

            const condition = rule.conditions[0];
            if ('undefined' !== typeof condition.above && isNaN(condition.above)) {
                return false;
            }

            if ('undefined' !== typeof condition.below && isNaN(condition.below)) {
                return false;
            }

            return true;
        }

        return {
            getConditions,
            canParse,
            isStaticThresholdRule,
        };
    },
]);

function isNodeWithinRange(node) {
    return (
        isNodeBinaryOp(node.predicate, 'AND') &&
        isNodeComparisonRule(node.predicate.left, 'GE') &&
        isNodeComparisonRule(node.predicate.right, 'LE') &&
        node.predicate.left.left.name === node.predicate.right.left.name
    );
}

function isNodeOutOfRange(node) {
    return (
        isNodeBinaryOp(node.predicate, 'OR') &&
        isNodeComparisonRule(node.predicate.left, 'GT') &&
        isNodeComparisonRule(node.predicate.right, 'LT') &&
        node.predicate.left.left.name === node.predicate.right.left.name
    );
}

function getSimpleNodeValue(node) {
    if (isNodeNumber(node)) {
        return node.value;
    } else if (isNodeVariable(node)) {
        return node.name;
    } else if (isNodeThreshold(node)) {
        const valueNode = node.start.args.object;

        if (isNodeNegativeNumber(valueNode)) {
            return getSimpleNodeValue(valueNode.expression) * -1;
        }

        return getSimpleNodeValue(valueNode);
    } else {
        throw new Error('Cannot extract simple value from node.');
    }
}

function isNodeWhenFn(ref) {
    if (!isObject(ref) || !isObject(ref.predicate) || 'when' !== ref.type) {
        return false;
    }

    return true;
}

function isNodeBinaryOp(ref, operator) {
    if (
        !isObject(ref) ||
        !isObject(ref.left) ||
        !isObject(ref.right) ||
        ref.type !== 'binary_expression'
    ) {
        return false;
    }

    if (operator !== null && operator !== undefined && operator !== ref.op) {
        return false;
    }

    return true;
}

function isNodeComparisonRule(ref, operator) {
    if (
        !isNodeBinaryOp(ref, operator) ||
        !isNodeVariable(ref.left) ||
        !isNodeThreshold(ref.right)
    ) {
        return false;
    }

    if (getSimpleNodeValue(ref.left) === getSimpleNodeValue(ref.right)) {
        throw new Error('Comparison of stream to itself is incorrect.');
    }

    return true;
}

function isNodeNumber(ref) {
    if (
        !isObject(ref) ||
        NODE_NUMBER_TYPES.includes(ref.type) === -1 ||
        typeof ref.value !== 'number' ||
        isNaN(ref.value) ||
        !isFinite(ref.value)
    ) {
        return false;
    }

    return true;
}

function isNodeNegativeNumber(ref) {
    if (
        !isObject(ref) ||
        ref.type !== 'unary_expression' ||
        ref.operation !== 'NEGATE' ||
        !isNodeNumber(ref.expression)
    ) {
        return false;
    }

    return true;
}

function isNodeVariable(ref) {
    if (!isObject(ref) || 'var_ref' !== ref.type || typeof ref.name !== 'string') {
        return false;
    }

    return true;
}

function isNodeThreshold(ref) {
    if (!isObject(ref) || 'stream' !== ref.type || !isObject(ref.start)) {
        return false;
    }

    const startNode = ref.start;
    if (startNode.type !== 'stream_function' || startNode.functionName !== 'threshold') {
        return false;
    }

    if (!isObject(startNode.args) || !isObject(startNode.args.object)) {
        return false;
    }

    const valueNode = startNode.args.object;
    if (
        !isNodeNumber(valueNode) &&
        !isNodeNegativeNumber(valueNode) &&
        !isNodeVariable(valueNode)
    ) {
        return false;
    }

    return true;
}

function isObject(ref) {
    return !!ref && typeof ref === 'object' && !Array.isArray(ref);
}
