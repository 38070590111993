export default [
    function () {
        return (data, disallowedPropertyList, allowedPropertyList) => {
            if (!data || data.length === 0) {
                return [];
            }

            if (!disallowedPropertyList) {
                disallowedPropertyList = [];
            }
            if (!allowedPropertyList) {
                allowedPropertyList = [];
            }

            // Build a map of all properties/dimensions and their values
            const facetValues = {};
            const facetNodeCount = {}; // counts number of nodes with facet present

            // List groupings above this number at the bottom
            const SANE_NUMBER_OF_GROUPS = 30;

            data.forEach(function (datum) {
                Object.keys(datum).forEach(function (key) {
                    // exclude internal and disallowed properties (unless explicitly listed in the
                    // allowList overrides)
                    const isAllowed = allowedPropertyList.indexOf(key) !== -1;
                    const isInternalProperty =
                        (key.indexOf('sf_') === 0 || key.indexOf('_') === 0) && !isAllowed;
                    const isDisallowed = disallowedPropertyList.indexOf(key) !== -1;

                    if (isInternalProperty || isDisallowed) {
                        return;
                    }

                    const value = datum[key];
                    let values = facetValues[key];

                    if (values === undefined) {
                        values = facetValues[key] = [];
                        facetNodeCount[key] = 0;
                    }

                    facetNodeCount[key]++;

                    if (values.indexOf(value) === -1) {
                        values.push(value);
                    }
                });
            });

            const facetsWithReasonableNumberOfGroups = [];
            const facetsWithTooManyGroups = [];
            const uselessFacets = [];

            Object.keys(facetValues)
                .sort()
                .forEach(function (facet) {
                    const cardinality = facetValues[facet].length;

                    if (
                        facetNodeCount[facet] === data.length &&
                        (cardinality === 1 || cardinality === data.length)
                    ) {
                        // Skip facets which only have one member or have a unique value per
                        // datum
                        uselessFacets.push(facet);
                    } else if (cardinality > SANE_NUMBER_OF_GROUPS) {
                        // Group facets with sane number of groups before the ones that have more
                        // items
                        facetsWithTooManyGroups.push(facet);
                    } else {
                        facetsWithReasonableNumberOfGroups.push(facet);
                    }
                });

            return facetsWithReasonableNumberOfGroups
                .concat(facetsWithTooManyGroups)
                .concat(uselessFacets);
        };
    },
];
