export function getCPUChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'CPU% of Node Used/Container',
        axes: [
            {
                min: 0,
                label: '% of node',
            },
        ],
        legendOptions: {
            fields: [
                { property: 'container_spec_name', enabled: true },
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
                { property: 'kubernetes_node_uid', enabled: false },
                { property: 'kubernetes_pod_uid', enabled: false },
                { property: 'container_id', enabled: false },
                { property: 'container_image', enabled: false },
                { property: 'container_name', enabled: false },
                { property: 'host', enabled: false },
            ],
        },
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'Container CPU utilization',
            streamLabel: 'CONTAINER_CPU_UTILIZATION',
            programText: `CONTAINER_CPU_UTILIZATION = data('container_cpu_utilization', filter=filter('kubernetes_pod_name', '*') ${filterString}, rollup='rate').sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name', 'container_spec_name', 'container_id']).publish(label='CONTAINER_CPU_UTILIZATION', enable=False)`,
        },
        {
            displayName: 'Machine CPU cores',
            streamLabel: 'MACHINE_CPU_CORES',
            programText:
                "MACHINE_CPU_CORES = data('cpu.num_processors').sum(by=['kubernetes_node']).publish(label='MACHINE_CPU_CORES', enable=False)",
        },
        {
            displayName: 'CPU Capacity % Used',
            streamLabel: 'CALCULATED_CPU_USAGE',
            programText:
                "CALCULATED_CPU_USAGE = (CONTAINER_CPU_UTILIZATION / MACHINE_CPU_CORES).sum(by=['kubernetes_node', 'kubernetes_pod_name', 'container_spec_name']).publish('CALCULATED_CPU_USAGE')",
        },
        {
            displayName: 'CPU Capacity % Used',
            streamLabel: 'DIRECT_CPU_USAGE',
            programText: `DIRECT_CPU_USAGE = data('container_cpu_percent', filter=filter('kubernetes_pod_name', '*') ${filterString}, rollup='rate').sum(by=['kubernetes_cluster', 'kubernetes_pod_name', 'container_spec_name', 'container_id']).publish('DIRECT_CPU_USAGE')`,
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getMemoryChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Memory Used by Container',
        defaultPlotType: 'AreaChart',
        stacked: true,
        axes: [
            {
                min: 0,
            },
        ],
        legendOptions: {
            fields: [
                { property: 'container_spec_name', enabled: true },
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'container_name', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
                { property: 'kubernetes_pod_uid', enabled: true },
                { property: 'container_id', enabled: true },
                { property: 'container_image', enabled: true },
                { property: 'host', enabled: false },
            ],
        },
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'bytes used',
            streamLabel: 'CONTAINER_MEMORY_USAGE',
            programText: `CONTAINER_MEMORY_USAGE = data('container_memory_usage_bytes', filter=filter('kubernetes_pod_name', '*') ${filterString}).publish('CONTAINER_MEMORY_USAGE')`,
            valueUnit: 'Byte',
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getNetworkErrorChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Pod Network Errors',
        defaultPlotType: 'ColumnChart',
        legendOptions: {
            fields: [
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
            ],
        },
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            streamLabel: 'Total Receive Errors',
            programText: `TRE = data('pod_network_receive_errors_total', filter=filter('kubernetes_pod_name', '*') ${filterString}, rollup='delta').sum(by=['kubernetes_pod_name']).publish(label='Total Receive Errors')`,
            paletteIndex: 4,
        },
        {
            streamLabel: 'Total Transmit Errors',
            programText: `TTE = data('pod_network_transmit_errors_total', filter=filter('kubernetes_pod_name', '*')  ${filterString}, rollup='delta').sum(by=['kubernetes_pod_name']).publish(label='Total Transmit Errors')`,
            paletteIndex: 2,
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getNetworkThroughputChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Pod Network Throughput',
        defaultPlotType: 'AreaChart',
        stacked: true,
        legendOptions: {
            fields: [
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
            ],
        },
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            streamLabel: 'Total Network Receive',
            programText: `TNR = data('pod_network_receive_bytes_total', filter=filter('kubernetes_pod_name', '*') ${filterString}, rollup='rate', extrapolation='zero').sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name']).publish(label='Total Network Receive')`,
            valueUnit: 'Byte',
            paletteIndex: 5,
        },
        {
            streamLabel: 'Total Network Transmit',
            programText: `TNT = data('pod_network_transmit_bytes_total', filter=filter('kubernetes_pod_name', '*')  ${filterString}, rollup='rate', extrapolation='zero').sum(by=['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name']).publish(label='Total Network Transmit')`,
            valueUnit: 'Byte',
            paletteIndex: 2,
        },
    ];

    return { chartOptions, publishLabelOptions };
}
