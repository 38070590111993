export default [
    '$log',
    'teamsApiService',
    'currentUser',
    function ($log, teamsApiService, currentUser) {
        return {
            getUserTeams,
            getUserMemberships,
        };

        function getUserTeams() {
            return currentUser
                .email()
                .then((email) => {
                    return teamsApiService.searchMembers({ partial: email }).then(({ results }) => {
                        const teamsToFetch = results
                            .filter((member) => member.email === email)
                            .map((member) => member.teamId);
                        return teamsApiService
                            .getTeams(teamsToFetch)
                            .then((teams) => {
                                return teams.filter((t) => t);
                            })
                            .catch((error) => $log.error("failed fetching user's teams " + error));
                    });
                })
                .catch(() => {
                    currentUser.isSuperUser().then((isSuper) => {
                        // support users don't have orgUser objects, don't bother logging in that case
                        if (!isSuper) {
                            $log.error('failed to find org user');
                        }
                    });
                    return [];
                });
        }

        function getUserMemberships() {
            return currentUser
                .email()
                .then((email) => {
                    return teamsApiService
                        .searchMembers({ partial: email })
                        .then(({ results }) => results.filter((member) => member.email === email));
                })
                .catch(() => {
                    currentUser.isSuperUser().then((isSuper) => {
                        // support users don't have orgUser objects, don't bother logging in that case
                        if (!isSuper) {
                            $log.error('failed to find org user');
                        }
                    });
                    return [];
                });
        }
    },
];
