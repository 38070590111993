import templateUrl from './streamPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('streamPickerController', [
        '$scope',
        '$log',
        function ($scope, $log) {
            $scope.valid = true;

            const $ctrl = this;
            $ctrl.$onInit = function () {
                $scope.enumValues = $scope.$ctrl.definition.dataType.values;
                $scope.localValue = {};
                $scope.enumValues.some(function (value) {
                    if (value.label === $scope.$ctrl.model) {
                        $scope.localValue.value = value;
                        return true;
                    }
                    return false;
                });
            };

            $scope.setSelectedPlot = function (plot) {
                $scope.localValue.value = plot;
            };
            if (!$scope.enumValues.length || $scope.enumValues.length === 0) {
                $log.error('No value choices specified for stream function picker.');
            }
            $scope.$watch('localValue.value', function (v) {
                $scope.valid = validate(v);
                $scope.$ctrl.onTypedUpdate({ value: v ? v.label : null, validity: $scope.valid });
            });

            function validate(v) {
                return $scope.enumValues.indexOf(v) > -1;
            }
        },
    ])
    .component('streamPicker', {
        replace: true,
        controller: 'streamPickerController',
        bindings: {
            model: '<',
            definition: '<',
            jobInfo: '<',
            resolvingResolution: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
