import templateUrl from './serviceEndpointFilter.tpl.html';

export default {
    templateUrl,
    bindings: {
        emptyText: '<',
        environment: '<',
        hideLabel: '<',
        isMultiSelect: '<',
        label: '<',
        hideInferred: '<',
        apmVersion: '<',
    },
    controller: [
        '$element',
        '$timeout',
        'routeParameterService',
        'ServiceEndpointSelection',
        'urlOverridesService',
        function (
            $element,
            $timeout,
            routeParameterService,
            ServiceEndpointSelection,
            urlOverridesService
        ) {
            const $ctrl = this;

            let unregisterRouteWatcher;

            $ctrl.editMode = false;
            // Note: it does not work for $onChange()
            // because we dont update it in some of the modifying methods (ex: onRemove, onUpdate..)
            // if we need to support $onChange() on this, we need to make updates on the modifying methods
            $ctrl.selectedFilters = [];

            // lifecycle bindings
            $ctrl.$onInit = $onInit;
            $ctrl.$onDestroy = $onDestroy;

            // callback functions
            $ctrl.enableEditMode = enableEditMode;
            $ctrl.onAddButtonKeydown = onAddButtonKeydown;
            $ctrl.onSelect = onSelect;
            $ctrl.onUpdate = onUpdate;
            $ctrl.onRemove = onRemove;
            $ctrl.onBlurInput = onBlurInput;
            $ctrl.onBlurDefaultInput = onBlurDefaultInput;

            function $onInit() {
                unregisterRouteWatcher = routeParameterService.registerRouteWatch(
                    'serviceEndpoints[]',
                    updateFiltersFromUrl
                );

                updateFiltersFromUrl();
            }

            function $onDestroy() {
                unregisterRouteWatcher();
            }

            function enableEditMode() {
                $ctrl.editMode = true;

                focusOnDefaultSelector();
            }

            function onAddButtonKeydown($event) {
                if ($event.key !== 'Tab') {
                    enableEditMode();
                }
            }

            function onSelect(serviceEndpointSelection) {
                const id = serviceEndpointSelection.getId();
                if ($ctrl.selectedFilters.some((f) => f.getId() === id)) return;

                $ctrl.selectedFilters.push(serviceEndpointSelection);
                urlOverridesService.setServiceEndpoints($ctrl.selectedFilters);
                disableEditMode();
            }

            function onUpdate(selection, index) {
                $ctrl.selectedFilters[index].copyProperties(selection);

                const filterIds = new Set();
                $ctrl.selectedFilters = $ctrl.selectedFilters.filter((f) => {
                    const id = f.getId();
                    if (!filterIds.has(id)) {
                        filterIds.add(id);
                        return true;
                    }
                });

                urlOverridesService.setServiceEndpoints($ctrl.selectedFilters);
                disableEditMode();
            }

            function onRemove(index) {
                $ctrl.selectedFilters.splice(index, 1);
                urlOverridesService.setServiceEndpoints($ctrl.selectedFilters);
            }

            function onBlurInput(serviceEndpointSelection) {
                if (!serviceEndpointSelection.isComplete()) {
                    $ctrl.selectedFilters = $ctrl.selectedFilters.filter((f) => f.isComplete());
                }
            }

            function onBlurDefaultInput() {
                disableEditMode();
            }

            //------------------------------------------------------------------
            // Private methods
            //------------------------------------------------------------------

            function disableEditMode() {
                $ctrl.editMode = false;
            }

            function focusOnDefaultSelector() {
                $timeout(() => {
                    const inputBox = $element.find(
                        '.service-endpoint-filter-default-selector input'
                    )[0];
                    if (inputBox) {
                        inputBox.click();
                    }
                }, 0);
            }

            function updateFiltersFromUrl() {
                $ctrl.selectedFilters = urlOverridesService
                    .getServiceEndpoints()
                    .map(
                        (serviceEndpoint) =>
                            new ServiceEndpointSelection(
                                serviceEndpoint.service,
                                serviceEndpoint.endpoints
                            )
                    );
            }
        },
    ],
};
