import { isNodeImport } from '../../../../app/signalflowv2/blockMapUtils/signalflowAstNodeVerifiers.js';

const APM_PATH_PREFIX = 'signalfx.detectors.apm.';

angular
    .module('signalflowv2')
    .constant('APM_PATH_PREFIX', APM_PATH_PREFIX)
    .factory('signalflowMetadataFinder', [
        'functionsMetadataService',
        'PACKAGE_INFO',
        'DETECTOR_TYPES',
        function (functionsMetadataService, PACKAGE_INFO, DETECTOR_TYPES) {
            return {
                findFunctionMetadata,
                isModulePathApm,
                isModulePathApmV2,
                isModulePathInfrastructure,
                signalflowContainsInfraImports,
                signalflowContainsApmImports,
                signalflowContainsApmV2Imports,
                signalflowContainsOnlyInfraImports,
                signalflowContainsOnlyApmImports,
                signalflowContainsOnlyApmV2Imports,
            };

            function findFunctionMetadata(nameToFind, signalflowAst) {
                const [moduleNameToFind, functionNameToFind] = nameToFind.split('.');

                if (!moduleNameToFind || !functionNameToFind) {
                    return null;
                }

                const matchingImportStatement = signalflowAst
                    .filter(isNodeImport)
                    .find((importStatement) => {
                        if (importStatement.alias) {
                            return moduleNameToFind === importStatement.alias;
                        } else {
                            return moduleNameToFind === importStatement.name;
                        }
                    });
                if (!matchingImportStatement) {
                    return null;
                }

                const query = {
                    package: PACKAGE_INFO.package,
                    version: PACKAGE_INFO.version,
                    path: matchingImportStatement.module,
                    module: matchingImportStatement.name,
                    function: functionNameToFind,
                };

                const moduleMetadata = safeGetModule(query);
                if (!moduleMetadata) {
                    return null;
                }

                const matchingFunctionMetadata = safeGetFunction(query);
                if (!matchingFunctionMetadata) {
                    return null;
                }

                return {
                    package: query.package,
                    version: query.version,
                    path: query.path,
                    module: moduleMetadata,
                    name: query.function,
                    inputs: matchingFunctionMetadata.inputs,
                };
            }

            function signalflowContainsInfraImports(signalflowAst) {
                return getAllModulePaths(signalflowAst).some((path) =>
                    isModulePathInfrastructure(path)
                );
            }

            function signalflowContainsApmImports(signalflowAst) {
                return getAllModulePaths(signalflowAst).some((path) => isModulePathApm(path));
            }

            function signalflowContainsApmV2Imports(signalflowAst) {
                return getAllModulePaths(signalflowAst).some((path) => isModulePathApmV2(path));
            }

            function signalflowContainsOnlyInfraImports(signalflowAst) {
                const allModulePaths = getAllModulePaths(signalflowAst);
                return (
                    !!allModulePaths.length &&
                    allModulePaths.every((path) => isModulePathInfrastructure(path))
                );
            }

            function signalflowContainsOnlyApmImports(signalflowAst) {
                const allModulePaths = getAllModulePaths(signalflowAst);
                return (
                    !!allModulePaths.length && allModulePaths.every((path) => isModulePathApm(path))
                );
            }

            function signalflowContainsOnlyApmV2Imports(signalflowAst) {
                const allModulePaths = getAllModulePaths(signalflowAst);
                return (
                    !!allModulePaths.length &&
                    allModulePaths.every((path) => isModulePathApmV2(path))
                );
            }

            function getAllModulePaths(signalflowAst) {
                return signalflowAst
                    .filter(isNodeImport)
                    .map((m) => m.module)
                    .filter(Boolean);
            }

            function isModulePathApm(path) {
                return getModulePathType(path) === DETECTOR_TYPES.APM;
            }

            function isModulePathApmV2(path) {
                return getModulePathType(path) === DETECTOR_TYPES.APM_V2;
            }

            function isModulePathInfrastructure(path) {
                return getModulePathType(path) === DETECTOR_TYPES.INFRASTRUCTURE;
            }

            function getModulePathType(path) {
                const query = {
                    package: PACKAGE_INFO.package,
                    version: PACKAGE_INFO.version,
                    path,
                };
                const pathMetadata = safeGetPath(query);
                return !!pathMetadata ? pathMetadata.type : null;
            }

            function safeGetPath(query) {
                try {
                    return functionsMetadataService.getPathJson(query);
                } catch {
                    return null;
                }
            }

            function safeGetModule(query) {
                try {
                    return functionsMetadataService.getModules(query)[0];
                } catch {
                    return null;
                }
            }

            function safeGetFunction(query) {
                try {
                    return functionsMetadataService.getFunctionJson(query);
                } catch {
                    return null;
                }
            }
        },
    ]);
