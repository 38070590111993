import templateUrl from './eventPanelAuditTile.tpl.html';

export default {
    templateUrl,
    bindings: {
        event: '<',
    },
    controller: [
        '$scope',
        'userV2Service',
        function ($scope, userV2Service) {
            const $ctrl = this;
            $ctrl.$onInit = function () {
                const userId = this.event.properties.userId || this.event.metadata.principalId;
                if (userId) {
                    userV2Service
                        .getCachedOrgMemberForUserId(userId)
                        .then(function (orgMember) {
                            $ctrl.user = userV2Service.getUserBadgeDisplayObj(orgMember);
                        })
                        .finally(() => {
                            $scope.$applyAsync();
                        });
                }
            };
        },
    ],
};
