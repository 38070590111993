'use strict';
import templateUrl from './detectorInfoPane.tpl.html';

export default {
    templateUrl,
    bindings: {
        detectorObject: '<',
        editable: '<',
    },
    controller: [
        '$scope',
        'currentUser',
        '$http',
        '$log',
        'config',
        'API_URL',
        'featureEnabled',
        'alertDataService',
        function (
            $scope,
            currentUser,
            $http,
            $log,
            config,
            API_URL,
            featureEnabled,
            alertDataService
        ) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;
            $ctrl.cancel = cancel;

            let detector;
            function $onInit() {
                detector = $ctrl.detectorObject;

                $scope.detector = detector;
                $scope.fetchingLinkedCharts = true;
                alertDataService
                    .getLinkedChartsForDetector(detector.id || detector.sf_id)
                    .then(function (result) {
                        $scope.fetchingLinkedCharts = false;
                        $scope.linkedCharts = result.slice(0, 20);
                        $scope.numAdditionalCharts = result.length - 20;
                    });

                $scope.v2Detector = detector.id && !detector.sf_id;
                $scope.isLocked =
                    detector.sf_isDpmDetector || (featureEnabled('readOnly') && detector.locked);
                $scope.v2JobIds = [];
                if ($scope.detector.sf_signalflowVersion === 2) {
                    $scope.v2JobIds = $scope.detector.sf_currentJobIds || [];
                } else if ($scope.v2Detector && config('superpower.unreleasedFeatures')) {
                    $http
                        .get(API_URL + '/v2/detector/_/' + $scope.detector.id + '/jobs')
                        .then(function (jobIds) {
                            $scope.v2JobIds = jobIds.data;
                        })
                        .catch(function (e) {
                            $log.error('Failed fetching jobs for detector ', e);
                        });
                }

                currentUser.isSuperUser().then(function (isSuperUser) {
                    $scope.showDebuggingInfo = isSuperUser;
                });
            }

            function cancel() {
                $scope.$emit('dismiss modal');
            }
        },
    ],
};
