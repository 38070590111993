import objectDescriptionTemplateUrl from './objectDescription.tpl.html';
import markdownModalTemplateUrl from './markdownModal.tpl.html';

angular.module('signalview.objectmanager').directive('objectDescription', [
    '$timeout',
    '$window',
    'signalboost',
    'catalogMetricDocumentation',
    'catalogPluginDocumentation',
    'sfxModal',
    'v2ObjectService',
    'config',
    'SYSTEM_USER_ID',
    'metricService',
    'migratedSignalboost',
    'hasCapability',
    'catalogHelper',
    function (
        $timeout,
        $window,
        signalboost,
        catalogMetricDocumentation,
        catalogPluginDocumentation,
        sfxModal,
        v2Object,
        config,
        SYSTEM_USER_ID,
        metricService,
        migratedSignalboost,
        hasCapability,
        catalogHelper
    ) {
        return {
            restrict: 'AE',
            scope: {
                object: '=',
                viewMode: '=',
            },
            templateUrl: objectDescriptionTemplateUrl,
            link: function ($scope, element) {
                const isSuperPowers = config('superpower.unreleasedFeatures');
                $scope.isServiceDiscovery =
                    ($scope.object.sf_creator === SYSTEM_USER_ID ||
                        $scope.object.creator === SYSTEM_USER_ID) &&
                    !isSuperPowers;

                $scope.temp = {
                    description: '',
                };

                const associatedCapability = catalogHelper.getCapabilityForType(
                    $scope.object.sf_type
                );
                if (associatedCapability) {
                    hasCapability(associatedCapability).then(
                        (hasUpdateCapability) => ($scope.hasUpdateCapability = hasUpdateCapability)
                    );
                } else {
                    // catch-all for any other object types that might use this shared component
                    $scope.hasUpdateCapability = true;
                }

                if ($scope.object.sf_type === 'Metric' && $scope.object.sf_metric) {
                    catalogMetricDocumentation
                        .getMetricDocumentation($scope.object.sf_metric)
                        .then(function (metadata) {
                            if (!metadata) {
                                return;
                            }
                            $scope.documentationDescription = metadata.yaml.brief || null;
                            $scope.modalMarkdown = metadata.markdown;
                        });
                }

                if ($scope.object.sf_type === 'Dimension' && $scope.object.plugin) {
                    catalogPluginDocumentation
                        .getPluginDocumentation($scope.object.plugin)
                        .then(function (metadata) {
                            if (!metadata) {
                                return;
                            }
                            $scope.documentationDescription = metadata.yaml.brief || null;
                            $scope.modalMarkdown = metadata.markdown;
                        });
                }

                $scope.editMode = false;
                let originalDescription;

                $scope.enableEditMode = function () {
                    originalDescription = $scope.temp.description;

                    $scope.editMode = true;

                    $timeout(function () {
                        $scope.focusDescriptionField();
                    });
                };

                $scope.showMarkdownModal = function (markdown) {
                    sfxModal.open({
                        templateUrl: markdownModalTemplateUrl,
                        resolve: {
                            markdown: function () {
                                return markdown;
                            },
                        },
                        size: 'lg',
                        controller: [
                            '$scope',
                            'markdown',
                            function ($scope, markdown) {
                                $scope.markdown = markdown;
                            },
                        ],
                    });
                };

                $scope.getDescriptionEditText = function () {
                    if (!$scope.temp.description && !$scope.documentationDescription) {
                        return 'Add description...';
                    } else {
                        return 'Edit';
                    }
                };

                $scope.cancelEdit = function () {
                    $scope.editMode = false;
                    $scope.temp.description = originalDescription;
                };

                $scope.saveEdit = function () {
                    $scope.editMode = false;

                    commitChanges();

                    originalDescription = $scope.temp.description;
                };

                $scope.focusDescriptionField = function () {
                    element.find('textarea').focus();
                };

                function commitChanges() {
                    const object = $scope.object;

                    if (v2Object.isV2Object(object)) {
                        object.description = $scope.temp.description;
                        return v2Object.update(object);
                    }

                    let v1ObjectPromise = null;

                    if ($scope.object.sf_type === 'Metric') {
                        const v2MetricObject = metricService.toV2Write(
                            object,
                            $scope.temp.description,
                            null
                        );
                        v1ObjectPromise = metricService.put(v2MetricObject.name, v2MetricObject);
                    } else if (object.sf_type === 'Dimension') {
                        v1ObjectPromise = migratedSignalboost.dimension.patch(`/${object.sf_id}`, {
                            sf_description: $scope.temp.description,
                        });
                    } else {
                        v1ObjectPromise = signalboost.types[object.sf_type.toLowerCase()]
                            .one(object.sf_id)
                            .patch({
                                sf_description: $scope.temp.description,
                            });
                    }
                    return v1ObjectPromise
                        .then(function () {
                            object.sf_description = $scope.temp.description;
                        })
                        .catch(function () {
                            $window.alert('Error saving description');
                        });
                }

                $scope.$watch(
                    'object',
                    function (object) {
                        $scope.temp.description = v2Object.isV2Object(object)
                            ? object.description
                            : object.sf_description;
                    },
                    true
                );
            },
        };
    },
]);
