'use strict';
import templateUrl from './servicenowIntegration.html';

angular.module('sfx.ui').directive('sfxServicenowIntegration', [
    'obfuscateSecret',
    'notificationService',
    function (obfuscateSecret, notificationService) {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                const ISSUE_TYPE = {
                    INCIDENT: 'Incident',
                    PROBLEM: 'Problem',
                    EVENT: 'Event',
                };
                $scope.ISSUE_TYPE = ISSUE_TYPE;
                $scope.temp = {};

                function reset() {
                    const credential = $scope.credential;

                    if (!credential.name) {
                        credential.name = 'ServiceNow';
                    }
                    $scope.temp.username = '';
                    $scope.temp.password = '';
                    $scope.temp.instanceName = '';
                    $scope.credential.issueType = credential.issueType || ISSUE_TYPE.INCIDENT;
                    $scope.sharedCredentialState.updateCredentials = false;
                    $scope.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                        $scope.credential.lastUpdated
                    );
                }

                function updateCredentials() {
                    if (!($scope.temp.username && $scope.temp.password)) {
                        $scope.credential.username = null;
                        $scope.credential.password = null;
                    } else {
                        $scope.username = obfuscateSecret($scope.temp.username);
                        $scope.credential.username = $scope.temp.username;
                        $scope.credential.password = $scope.temp.password;
                        $scope.sharedCredentialState.updateCredentials = true;
                    }
                }

                $scope.$on('reset', reset);
                reset();

                $scope.$watch('temp.username', updateCredentials);
                $scope.$watch('temp.password', updateCredentials);
                $scope.$watch('credential.issueType', function (newValue, oldValue) {
                    if (!newValue || newValue === oldValue) return;
                    $scope.integrationForm.$setDirty();
                    $scope.credential.issueType = newValue;
                });
                $scope.$watch('temp.instanceName', function (newValue, oldValue) {
                    if (!newValue || newValue === oldValue) return;
                    $scope.credential.instanceName = newValue;
                    // if user never changes the issueType, pick the one that's already there or the default
                    $scope.credential.issueType =
                        $scope.credential.issueType || ISSUE_TYPE.INCIDENT;
                });
            },
        };
    },
]);
