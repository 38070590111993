angular.module('signalview.linkableModal', []).service('linkableModalService', [
    '$rootScope',
    '$timeout',
    '$log',
    'urlOverridesService',
    function ($rootScope, $timeout, $log, urlOverridesService) {
        let activeModal = null;
        const modalRegistry = {};

        $rootScope.$on('React:$routeUpdate', () => activate());
        $timeout(() => activate());

        return {
            register,
            open,
        };

        // openFn should return sfxModal instance
        function register(title, openFn, withTabs) {
            if (modalRegistry[title]) {
                $log.warn('Modal with same id already registered. Replacing.');
            }

            modalRegistry[title] = { openFn, withTabs };
        }

        function activate() {
            const modalTitle = urlOverridesService.getActiveModal();
            if (!modalRegistry[modalTitle]) {
                urlOverridesService.clearActiveModal();
                activeModal = null;
                return;
            }

            if (modalTitle === activeModal) {
                return;
            }

            open(modalTitle);
        }

        function open(title) {
            const modal = modalRegistry[title];
            if (!modal) {
                $log.warn(`No modal with id:"${title}" registered.`);
                return;
            }

            activeModal = title;
            const modalInstance = modal?.openFn();
            modalInstance?.opened?.then(() => urlOverridesService.setActiveModal(title));
            modalInstance?.closed?.then(() => {
                urlOverridesService.clearActiveModal();
                if (modal?.withTabs) {
                    urlOverridesService.clearTab();
                }
            });

            return modalInstance;
        }
    },
]);
