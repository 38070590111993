angular.module('jqueryui', []).directive('jquiSortable', function () {
    return {
        link: function (scope, el, attrs) {
            const sortOptions = {
                opacity: 0.5,
                multiple: true,
                snap: true,
                tolerance: 'touch',
                placholder: 'beingDragged',
                snapMode: 'both',
                helper: 'clone',
                distance: 5,
                forcePlaceHolderSize: true,
                revert: false,
                classes: { 'ui-sortable-helper': 'jqui-dragging' },
                start() {
                    scope.$emit('jqui-drag-start');
                },
                stop() {
                    scope.$emit('jqui-drag-stop');
                },
            };

            angular.extend(sortOptions, JSON.parse(attrs.jquiSortable));
            el.sortable(sortOptions);

            scope.$watch(attrs.ngDisabled, function (newValue) {
                el.sortable('option', 'disabled', newValue);
            });

            scope.sortConfiguration = sortOptions;
            el.disableSelection();

            el.on('sortdeactivate', function (event, ui) {
                if (scope.$eval(sortOptions.notAllowed)) {
                    scope.$emit('jqui-drag-cancel');
                    el.sortable('cancel');
                } else {
                    const from = angular.element(ui.item).scope().$index;
                    const to = el
                        .children(
                            scope.sortConfiguration.items ? scope.sortConfiguration.items : null
                        )
                        .index(ui.item);
                    if (to >= 0) {
                        scope.$apply(function () {
                            if (from >= 0) {
                                const sortArr = scope.$eval(sortOptions.targetArrayName);
                                const draggedItem = sortArr[from];
                                sortArr.splice(from, 1);
                                sortArr.splice(to, 0, draggedItem);
                            } else {
                                ui.item.remove();
                            }
                        });
                    }
                }
            });
        },
    };
});
