export default {
    requiredFilters: ['kubernetes_cluster', 'container_id'],

    BASE_METRIC_TEMPLATES: [
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "CPU_QUOTA = data('container_spec_cpu_quota'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).sum(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_spec_name'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "CPU_PERIOD = data('container_spec_cpu_period'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).sum(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_spec_name'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "CPU_LIMIT = data('kubernetes.container_cpu_limit'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).sum(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_spec_name'])",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "CPU_PERCENT_UTILIZATION = data('container_cpu_utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='rate')",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "MEMORY_LIMIT_KUBELET = data('container_spec_memory_limit_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).above(0, inclusive=True)",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "MEMORY_LIMIT_API = data('kubernetes.container_memory_limit'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).above(0, inclusive=True)",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "MEMORY_UTILIZATION = data('container_memory_usage_bytes'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='average')",
        },
        {
            filters: [
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'kubernetes_cluster',
                    type: 'property',
                },
                {
                    NOT: false,
                    property: '_exists_',
                    propertyValue: 'container_id',
                    type: 'property',
                },
            ],
            template:
                "CONTAINER_READY = data('kubernetes.container_ready'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
        {
            template:
                'PRE_V530_SMART_AGENT_CPU = CPU_QUOTA / CPU_PERIOD if CPU_QUOTA > 0 and CPU_PERIOD > 0 else None',
        },
    ],
    METRICS: [
        {
            displayName: 'CPU Limit',
            job: {
                template:
                    "CPU_CORE_CONTAINER_LIMIT = union(CPU_LIMIT, PRE_V530_SMART_AGENT_CPU).sum(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_spec_name'])",
                varName: 'CPU_CORE_CONTAINER_LIMIT',
                unique_id: '{{ kubernetes_cluster }}{{ kubernetes_pod_uid }}{{ container_id }}',
            },
        },
        {
            displayName: 'CPU Utilization',
            job: {
                varName: 'CPU_PERCENT_UTILIZATION',
                unique_id: '{{ kubernetes_cluster }}{{ kubernetes_pod_uid }}{{ container_id }}',
            },
        },
        {
            displayName: 'Memory Limit',
            job: {
                template:
                    "MEMORY_LIMIT = union(MEMORY_LIMIT_KUBELET, MEMORY_LIMIT_API).sum(by=['kubernetes_cluster', 'kubernetes_pod_uid', 'container_spec_name'])",
                varName: 'MEMORY_LIMIT',
                unique_id: '{{ kubernetes_cluster }}{{ kubernetes_pod_uid }}{{ container_id }}',
            },
        },
        {
            displayName: 'Memory Utilization',
            job: {
                varName: 'MEMORY_UTILIZATION',
                unique_id: '{{ kubernetes_cluster }}{{ kubernetes_pod_uid }}{{ container_id }}',
            },
        },
        {
            displayName: 'Container Ready',
            job: {
                varName: 'CONTAINER_READY',
                unique_id: '{{ kubernetes_cluster }}{{ kubernetes_pod_uid }}{{ container_id }}',
            },
        },
    ],

    title: 'Container Properties',

    dataGroup: [
        {
            /***** GROUP 1 *****/
            limit: 1,
            groupTitle: null,
            groupHeader: null,
            propertyMap: [
                {
                    displayName: 'Name',
                    format: 'id',
                    metric: 'CPU Utilization',
                    property: 'container_spec_name',
                },
            ],
        },
        {
            /***** GROUP 2 *****/
            limit: 1,
            groupTitle: null,
            groupHeader: null,
            propertyMap: [
                {
                    displayName: 'Ready',
                    format: 'Custom:ResourceStatus',
                    metric: 'Container Ready',
                    property: null,
                },
                {
                    displayName: 'Pod',
                    format: 'id',
                    metric: 'CPU Utilization',
                    property: 'kubernetes_pod_name',
                },
                {
                    displayName: 'Node',
                    format: null,
                    metric: 'CPU Utilization',
                    property: 'kubernetes_node',
                },
            ],
        },
        {
            /***** GROUP 3 *****/
            limit: 1,
            groupTitle: null,
            groupHeader: null,
            propertyMap: [
                {
                    displayName: 'Image',
                    format: 'id',
                    metric: 'CPU Utilization',
                    property: 'container_image',
                },
                {
                    displayName: 'CPU % used of node',
                    format: 'Percentage',
                    metric: 'CPU Utilization',
                    property: null,
                },
                {
                    displayName: 'CPU Limit',
                    format: 'Number',
                    metric: 'CPU Limit',
                    property: null,
                    alt: 'UNLIMITED',
                },
                {
                    displayName: 'Memory used',
                    format: 'ScaleBytes',
                    metric: 'Memory Utilization',
                    property: null,
                },
                {
                    displayName: 'Memory Limit',
                    format: 'ScaleBytes',
                    metric: 'Memory Limit',
                    property: null,
                    alt: 'UNLIMITED',
                },
            ],
        },
    ],
};
