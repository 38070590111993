import templateUrl from './awsMetricFiltering.tpl.html';

angular.module('sfx.ui').component('awsMetricFiltering', {
    templateUrl,
    bindings: {
        credential: '<',
        integrationForm: '<',
        editable: '<',
        services: '<',
        onSyncRuleUpdate: '<',
        isCustomNamespace: '<?',
        cachedNamespaces: '<?',
    },
    controller: [
        '$scope',
        '$filter',
        '$timeout',
        '$element',
        '$window',
        '$q',
        'awsIntegrationService',
        'integrationFilterClipboardService',
        'showSignalFlowModal',
        '_',
        function (
            $scope,
            $filter,
            $timeout,
            $element,
            $window,
            $q,
            awsIntegrationService,
            integrationFilterClipboardService,
            showSignalFlowModal,
            _
        ) {
            const $ctrl = this;
            let displayNameToNamespaceCode = {};
            let supportedNamespacesAndRegionsPromise;
            let tempServices = [];

            $ctrl.$onInit = () => {
                $ctrl.filterActionsToName = {
                    Include: 'Import Only...',
                    Exclude: "Don't Import...",
                };
                supportedNamespacesAndRegionsPromise = awsIntegrationService
                    .getSupportedNamespacesAndRegions()
                    .then(function (result) {
                        return result.data;
                    })
                    .catch(function () {
                        $window.alert('Failed trying to fetch supported AWS services');
                    });
                reset();
                supportedNamespacesAndRegionsPromise.then(function (result) {
                    displayNameToNamespaceCode = _.invert(result.namespaces);
                    $ctrl.cachedNamespaces = result.namespaces;
                });

                if (!$ctrl.isCustomNamespace) {
                    $scope.$on('toggle non-custom metric filtering services dropdown', () => {
                        toggleServicesDropdown(true);
                    });
                }
            };

            $ctrl.getSuggestions = getSuggestions;
            $ctrl.isSelected = isSelected;
            $ctrl.onItemSelected = onItemSelected;
            $ctrl.toggleSelection = toggleSelection;
            $ctrl.applySelectedServices = applySelectedServices;
            $ctrl.clearClientState = clearClientState;
            $ctrl.toggleServicesDropdown = toggleServicesDropdown;
            $ctrl.addCustomNamespaces = addCustomNamespaces;
            $ctrl.deleteService = deleteService;
            $ctrl.copyFilter = copyFilter;
            $ctrl.pasteFilter = pasteFilter;
            $ctrl.clearFilter = clearFilter;
            $ctrl.isPasteAvailable = isPasteAvailable;
            $ctrl.shouldShowPasteTooltip = shouldShowPasteTooltip;
            $ctrl.setFilterAction = setFilterAction;
            $ctrl.viewSignalFlow = viewSignalFlow;
            $ctrl.onCustomNamespaceBlur = onCustomNamespaceBlur;

            function getSuggestions(userInput) {
                const listOfNamespaces = Object.values($ctrl.cachedNamespaces);
                return $q.when($filter('filter')(listOfNamespaces, userInput));
            }

            function isSelected(result) {
                const namespaceCode = displayNameToNamespaceCode[result];
                return _.some(tempServices, (item) => item.name === namespaceCode);
            }

            function onItemSelected(item) {
                toggleSelection(item);
                applySelectedServices();
            }

            function toggleSelection(result) {
                const namespaceCode = displayNameToNamespaceCode[result];
                if (isSelected(result)) {
                    const index = _.findIndex(tempServices, (item) => item.name === namespaceCode);
                    tempServices.splice(index, 1);
                } else {
                    tempServices.push({
                        name: namespaceCode,
                        filter: {},
                    });
                }
            }

            function applySelectedServices() {
                $ctrl.services = angular.copy(tempServices);
                $ctrl.isServiceEmpty = _.isEmpty($ctrl.services);
                toggleServicesDropdown(false);
                $ctrl.integrationForm.$setDirty(true);
            }

            function clearClientState() {
                // No need to reset any states
            }

            function addCustomNamespaces() {
                $ctrl.services.push({
                    name: '',
                    filter: {},
                    isNew: true,
                });
                $ctrl.integrationForm.$setDirty(true);
                $timeout(() => {
                    const idx = $ctrl.services.length - 1;
                    const input = $element.find(`.custom-namespace-input-${idx}`)[0];
                    if (input) {
                        input.focus();
                    }
                });
            }

            function onCustomNamespaceBlur(idx) {
                const service = $ctrl.services[idx];
                if (service && service.isNew) {
                    service.isNew = false;
                    if (!service.name) {
                        $ctrl.services.splice(idx, 1);
                    }
                }
            }

            function toggleServicesDropdown(toggleOn) {
                $ctrl.showServicesDropdown = !!toggleOn;

                if ($ctrl.showServicesDropdown) {
                    tempServices = angular.copy($ctrl.services);
                    if (!$ctrl.isCustomNamespace) {
                        $scope.$emit('toggled select services dropdown');
                    }
                    $timeout(focusServiceInput);
                }
            }

            function copyFilter(filter) {
                integrationFilterClipboardService.copyFilter(filter);
            }

            function pasteFilter(idx) {
                if (isPasteAvailable()) {
                    $ctrl.services[idx].filter = integrationFilterClipboardService.getFilter();
                    $ctrl.integrationForm.$setDirty(true);
                }
            }

            function isPasteAvailable() {
                return integrationFilterClipboardService.isPasteAvailable();
            }

            function clearFilter(filter) {
                filter.action = '';
                filter.tags = [];
                filter.metrics = [];
                $ctrl.integrationForm.$setDirty(true);
            }

            function focusServiceInput() {
                const input = $element.find('.typeahead-input')[0];
                if (input) {
                    input.focus();
                }
            }

            function deleteService(idx) {
                $ctrl.services.splice(idx, 1);
                $ctrl.integrationForm.$setDirty(true);
                $ctrl.isServiceEmpty = _.isEmpty($ctrl.services);
            }

            function shouldShowPasteTooltip(filter) {
                return (
                    (filter.tags && filter.tags.length) ||
                    (filter.metrics && filter.metrics.length) ||
                    filter.signalflow
                );
            }

            function reset() {
                $ctrl.isServiceEmpty =
                    !$ctrl.credential.service || !$ctrl.credential.service.length;
                displayNameToNamespaceCode = _.invert($ctrl.cachedNamespaces);
            }

            function setFilterAction(filter, action) {
                filter.action = action;

                if (action === 'Include') {
                    filter.defaultAction = 'Exclude';
                } else if (action === 'Exclude') {
                    filter.defaultAction = 'Include';
                } else {
                    filter.defaultAction = '';
                }

                $ctrl.integrationForm.$setDirty(true);
            }

            function viewSignalFlow(signalflow, namespace) {
                namespace = $ctrl.cachedNamespaces[namespace] || namespace;
                showSignalFlowModal(signalflow, `Filter Code for ${namespace}`);
            }

            $scope.$on('reset', reset);

            $scope.$watch(
                '$ctrl.services',
                (newVal) => {
                    $ctrl.isServiceEmpty = _.isEmpty(newVal);
                    $ctrl.onSyncRuleUpdate($ctrl.services);
                },
                true
            );
        },
    ],
});
