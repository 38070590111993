import { useState, useEffect } from 'react';
import OrganizationPreferenceService from '../../../app/administrative/organization/generalSettings/OrganizationPreferenceService';

export default function useAclOrgPreferencesContext(orgId) {
    const orgPreferenceService = OrganizationPreferenceService.useInstance();
    const [orgSettings, setOrgSettings] = useState({});

    useEffect(() => {
        const fetchOrgData = async () => {
            const organizationSettings = await orgPreferenceService.get();
            setOrgSettings(organizationSettings);
        };

        fetchOrgData();
    }, [orgId, orgPreferenceService]);

    return orgSettings;
}
