import templateUrl from './syncItem.html';

angular
    .module('sfx.ui')
    .constant('NEWRELIC_STATS', [
        'average_call_time',
        'average_exclusive_time',
        'average_response_time',
        'average_time',
        'average_value',
        'call_count',
        'calls_per_minute',
        'count',
        'max_call_time',
        'max_per_second',
        'max_response_time',
        'min_call_time',
        'min_per_second',
        'min_response_time',
        'per_second',
        'percent',
        'requests_per_minute',
        'score',
        'standard_deviation',
        'threshold',
        'threshold_min',
        'throughput',
        'total',
        'total_call_time',
        'total_call_time_per_minute',
        'total_time',
        'value',
    ])
    .directive('newrelicSyncItem', [
        'API_URL',
        'NEWRELIC_STATS',
        '$log',
        '$http',
        '$timeout',
        'currentUser',
        function (API_URL, NEWRELIC_STATS, $log, $http, $timeout, currentUser) {
            return {
                restrict: 'E',
                scope: {
                    type: '@',
                    openFlag: '=',
                    item: '=',
                    values: '=',
                    setValues: '=',
                    credentialId: '=',
                    domains: '=?',
                    domainDisplayType: '=?',
                },
                templateUrl,
                link: function ($scope, elem) {
                    const url = API_URL + '/v2/integration/newrelic/applications';
                    let getMatchesTimeout;
                    $scope.NEWRELIC_STATS = NEWRELIC_STATS;
                    $scope.typedValue = '';
                    function setStats() {
                        $scope.stats = $scope.NEWRELIC_STATS.map(function (stat) {
                            return $scope.selectAll || $scope.temp.indexOf(stat) !== -1;
                        });
                    }

                    function copyValues() {
                        $scope.temp = angular.copy($scope.values || []);
                        $scope.selectAll = $scope.values && !$scope.values.length;
                        $scope.matches = [];
                        if ($scope.type === 'stats') {
                            setStats();
                        }
                    }

                    function scroll() {
                        const el = angular.element(elem).parents('.integrations-content')[0];
                        angular.element(el).animate({ scrollTop: el.scrollHeight }, 500);
                    }

                    function consumeEvent(e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    $scope.removeValue = function (index) {
                        $scope.temp.splice(index, 1);
                    };

                    $scope.addValue = function () {
                        if ($scope.typedValue) {
                            const index = $scope.temp.indexOf($scope.typedValue);
                            $scope.temp.push($scope.typedValue);
                            if (index !== -1) {
                                $scope.temp.splice(index, 1);
                            }
                            $scope.typedValue = '';
                            $timeout(function () {
                                const el = angular.element(elem).find('.values-list')[0];
                                angular.element(el).animate({ scrollTop: el.scrollHeight }, 500);
                                scroll();
                            }, 0);
                            $timeout.cancel(getMatchesTimeout);
                            $scope.matches = [];
                        }
                    };

                    $scope.inputKeyPressed = function (event) {
                        if (event.keyCode === 13) {
                            // enter
                            consumeEvent(event);
                            $scope.addValue();
                        } else if (event.keyCode === 27) {
                            // esc
                            $scope.cancel(event);
                        }
                    };

                    $scope.getMatches = function () {
                        $scope.matches = [];
                        $scope.fetchingMatches = true;
                        currentUser
                            .orgId()
                            .then(function (orgId) {
                                let subUrl = '';
                                const params = {
                                    credId: $scope.credentialId,
                                    count: 5,
                                };
                                let inlineParams = ['organizationId=' + orgId];
                                if ($scope.type === 'apps') {
                                    subUrl = 'DomainSample';
                                    params.filterDomainName = $scope.typedValue;
                                } else {
                                    subUrl = 'MetricSample';
                                    params.filterMetricName = $scope.typedValue;
                                    inlineParams = inlineParams
                                        .concat($scope.domains || [])
                                        .map(function (f) {
                                            return 'filterDomainName=' + f;
                                        });
                                }
                                return $http.get(
                                    url + '/' + subUrl + '?' + inlineParams.join('&'),
                                    { params: params }
                                );
                            })
                            .then(function (resp) {
                                $scope.matches = (resp || {}).data || [];
                            })
                            .catch(function (e) {
                                $log.error('Failed finding matches.', e);
                                $scope.matches = [];
                            })
                            .finally(function () {
                                $scope.fetchingMatches = false;
                            });
                    };

                    $scope.cancel = function (event) {
                        if (event) {
                            consumeEvent(event);
                        }
                        copyValues();
                        $scope.openFlag = false;
                    };

                    $scope.done = function () {
                        let value = $scope.temp;
                        if (value && !value.length && !$scope.values) {
                            value = $scope.values;
                        }
                        $scope.setValues($scope.item, $scope.selectAll ? [] : value);
                        $scope.openFlag = false;
                    };

                    copyValues();

                    $scope.$watch('typedValue', function () {
                        if ($scope.type === 'stats') {
                            return;
                        }
                        if ($scope.typedValue) {
                            $timeout.cancel(getMatchesTimeout);
                            getMatchesTimeout = $timeout($scope.getMatches, 100);
                        } else {
                            $scope.matches = [];
                        }
                    });

                    $scope.$watch('values', copyValues);
                    $scope.$watch('selectAll', function () {
                        if ($scope.type === 'stats' && $scope.selectAll) {
                            setStats();
                        }
                    });
                    $scope.$watch(
                        'stats',
                        function () {
                            if ($scope.type === 'stats') {
                                $scope.temp = $scope.stats
                                    .map(function (f, i) {
                                        return f ? i + 1 : 0;
                                    })
                                    .filter(function (f) {
                                        return f;
                                    })
                                    .map(function (f) {
                                        return $scope.NEWRELIC_STATS[f - 1];
                                    });
                                $scope.selectAll =
                                    $scope.temp.length === $scope.NEWRELIC_STATS.length;
                            }
                        },
                        true
                    );
                    $scope.$watch('openFlag', function (nval, oval) {
                        if (oval && !nval && !angular.equals($scope.temp, $scope.values)) {
                            copyValues();
                        } else if (nval && !oval) {
                            $timeout(scroll, 0);
                        }
                    });
                },
            };
        },
    ]);
