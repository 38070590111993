import templateUrl from './lastingPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('lastingPickerController', [
        '$scope',
        'timeSpanUtils',
        function ($scope, timeSpanUtils) {
            const ERROR_MESSAGE = {
                PERCENT: 'The percentage must be a whole number between 1 and 100.',
            };

            $scope.errorMessages = [];

            $scope.valid = true;
            $scope.validDuration = true;
            $scope.validMinResolution = true;
            $scope.validPercent = true;

            const $ctrl = this;

            $ctrl.$onInit = function () {
                const model = $scope.$ctrl.model;

                $scope.localDurationValue = model.duration;
                $scope.localPercentValue =
                    typeof model.percent === 'number'
                        ? numberToPercent(model.percent)
                        : model.percent;
            };

            function numberToPercent(val) {
                return Math.round(val * 100);
            }

            function percentToNumber(val) {
                return parseFloat((val / 100).toFixed(2));
            }

            function validateDuration(duration) {
                return timeSpanUtils.validateTimeSpan(duration);
            }

            function validatePercent(percent) {
                return (
                    percent !== null &&
                    typeof percent === 'number' &&
                    Number.isInteger(percent) &&
                    percent > 0 &&
                    percent <= 100
                );
            }

            function validate() {
                const errorMessages = [];

                $scope.validMinResolution = true;
                $scope.validDuration = validateDuration($scope.localDurationValue);

                if ($scope.validDuration) {
                    $scope.validMinResolution = timeSpanUtils.validateMinResolution(
                        $scope.localDurationValue,
                        $scope.$ctrl.jobInfo.resolution,
                        $scope.$ctrl.definition
                    );
                }
                $scope.validPercent = validatePercent($scope.localPercentValue);

                if (!$scope.validPercent) {
                    errorMessages.push(ERROR_MESSAGE.PERCENT);
                }

                $scope.valid =
                    $scope.validDuration && $scope.validMinResolution && $scope.validPercent;

                $scope.errorMessages = errorMessages;
            }

            function update() {
                $scope.$ctrl.onTypedUpdate({
                    value: {
                        duration: $scope.localDurationValue,
                        percent: percentToNumber($scope.localPercentValue),
                    },
                    validity: $scope.valid,
                });
            }

            function validateAndUpdate() {
                validate();
                update();
            }

            $scope.$watchGroup(
                ['localDurationValue', 'localPercentValue', '$ctrl.jobInfo.resolution'],
                function () {
                    if (!$scope.$ctrl.jobInfo) {
                        return;
                    }
                    validateAndUpdate();
                }
            );

            $scope.$ctrl.$onChanges = function (changes) {
                if (changes.model) {
                    const currentValue = changes.model.currentValue || {};
                    if (typeof currentValue.duration === 'string') {
                        $scope.localDurationValue = currentValue.duration;
                    }

                    if (
                        typeof currentValue.percent === 'number' &&
                        numberToPercent(currentValue.percent) !== $scope.localPercentValue &&
                        $scope.validPercent
                    ) {
                        $scope.localPercentValue = numberToPercent(currentValue.percent);
                    }
                }
            };
        },
    ])

    .component('lastingPicker', {
        replace: true,
        controller: 'lastingPickerController',
        bindings: {
            model: '<',
            definition: '<',
            jobInfo: '<',
            disablePicker: '<',
            validationError: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
