import templateUrl from './credentialDisplay.tpl.html';

const credentialDisplay = {
    replace: true,
    restrict: 'E',
    templateUrl,
    scope: {
        service: '=',
        orgId: '=',
        editable: '=',
        confirmModal: '=', // confirmation screen for unsaved changed
        formTracker: '=',
        onFormTrackerUpdate: '=?',
        onSearch: '=?', // when a search is triggered with unsaved changes
        onRowToggle: '=?', // when row toggle occurs with unsaved changes
    },
    controller: [
        '$q',
        '$scope',
        '$log',
        '$window',
        '_',
        'SAML_PROVIDERS',
        'BUSINESS_PROCESS_INTEGRATIONS',
        'credentialV2Service',
        'promiseGenerationManager',
        'userV2Service',
        function (
            $q,
            $scope,
            $log,
            $window,
            _,
            SAML_PROVIDERS,
            BUSINESS_PROCESS_INTEGRATIONS,
            credentialV2Service,
            promiseGenerationManager,
            userV2Service
        ) {
            const getCredentials = promiseGenerationManager(credentialV2Service.getType);
            const formTracker = $scope.formTracker;

            // For o11y, we need to bubble up form tracker updates to parent
            const formTrackerUpdateCallback = () => {
                if (!angular.isDefined($scope.onFormTrackerUpdate)) {
                    return;
                }
                $scope.onFormTrackerUpdate(formTracker);
            };
            const batchSize = 15;
            $scope.unsavedCredentials = [];
            $scope.credentials = [];

            $scope.filterText = {
                current: null,
                transient: null,
            };

            $scope.fetchCredentialBatch = (filterText) => {
                filterText = filterText || $scope.filterText.current;
                $scope.fetchingCredentialBatch = true;

                const credentialBatchPromise = getCredentials(
                    $scope.credType,
                    $scope.credentials.length - $scope.unsavedCredentials.length,
                    batchSize,
                    '-created',
                    filterText
                ).then((results) => {
                    return userV2Service
                        .getCachedOrgMembersByUserIds(
                            _.uniq(
                                _.flatMap(results.credentials, (cred) => [
                                    cred.creator,
                                    cred.lastUpdatedBy,
                                ])
                            )
                        )
                        .then(() => {
                            $scope.credentials = $scope.credentials.concat(results.credentials);
                            $scope.totalCredentialCount = results.count;
                        });
                });

                credentialBatchPromise.finally(() => {
                    $scope.$applyAsync();
                    $scope.fetchingCredentialBatch = false;
                });

                return credentialBatchPromise;
            };

            $scope.$watch('service', function () {
                let prevIntegrationType = null;
                if ($scope.integration && $scope.integration.credType) {
                    prevIntegrationType = $scope.integration.credType;
                }
                $scope.integration = BUSINESS_PROCESS_INTEGRATIONS[$scope.service];
                $scope.credType = $scope.integration.credType;
                // Ensure to reset credentials to be empty when the cred type no longer matches.
                if (prevIntegrationType !== $scope.credType) {
                    $scope.credentials = [];
                }
                $scope.fetchCredentialBatch();
            });

            // Note: Add integration only if present in the allowlist
            // do not add in a generic manner
            $scope.addIntegration = function () {
                if ($scope.service in BUSINESS_PROCESS_INTEGRATIONS) {
                    if (
                        SAML_PROVIDERS.indexOf($scope.service) !== -1 ||
                        $scope.service === 'aws' ||
                        $scope.service === 'amazoneventbridge'
                    ) {
                        // We need to present the credential id to the user in order for them to
                        // do a SAML/AWS integration. As soon as they indicate they want to perform
                        // a SAML/AWS integration, we immediately create the creds object inorder to
                        // expose the ID to them. This can obviously lead to garbage objects, but
                        // we considered the alternative implications of not doing it this way
                        // and determined that the current datastore can't ensure the constraints
                        // nescessary for security if we do not follow this approach.
                        const initialModel = {
                            name: 'SAML SSO',
                            type: $scope.credType,
                        };

                        if ($scope.service === 'aws') {
                            initialModel.name = 'AWS';
                            initialModel.type = 'AWSCloudWatch';
                            initialModel.authMethod = 'ExternalId';
                            initialModel.pollRate = 300000;
                            initialModel.services = [];
                            initialModel.regions = [];
                        } else if ($scope.service === 'amazoneventbridge') {
                            initialModel.name = 'Amazon EventBridge';
                            initialModel.type = 'AmazonEventBridge';
                        } else {
                            initialModel.doNotValidate = true;
                        }

                        credentialV2Service
                            .create(initialModel)
                            .then(function (model) {
                                model.isNewIntegration = true;
                                $scope.unsavedCredentials.push(model);
                                $scope.credentials.unshift(model);
                                $scope.$emit('hasNewIntegrationToggle', {
                                    hasNewIntegration: true,
                                });
                            })
                            .catch(function (e) {
                                $log.error('Unable to generate SAML Integration object.');
                                $log.error(e);
                            });
                    } else if ($scope.service === 'slack') {
                        credentialV2Service.slackOAuthUrl().then((url) => {
                            $window.location.href = url;
                        });
                    } else {
                        const model = {
                            type: $scope.credType,
                        };
                        $scope.unsavedCredentials.push(model);
                        $scope.credentials.unshift(model);
                    }
                } else {
                    $log.error('Unsupported integration ', $scope.service);
                }
            };

            function filterCredentials(value) {
                if ($scope.filterText.current !== value) {
                    $scope.filterText.current = $scope.filterText.transient = value || null;
                    $scope.credentials = [];
                    $scope.unsavedCredentials = [];
                    $scope.totalCredentialCount = 0;
                    $scope.fetchCredentialBatch($scope.filterText.transient).then(() => {
                        _.keys(formTracker).forEach((key) => {
                            delete formTracker[key];
                        });
                        formTrackerUpdateCallback();
                    });
                }
            }

            $scope.confirmAndFilter = (value) => {
                if (value !== '' && !_.isEmpty(formTracker)) {
                    getConfirmationPromise()
                        .then(
                            () => {
                                filterCredentials(value);
                            },
                            () => {
                                $log.debug('promise rejected');
                            }
                        )
                        .finally(() => ($scope.filterText.transient = $scope.filterText.current));
                } else {
                    filterCredentials(value);
                }
            };

            /*
             * Either a callback for onSearch confirmation or a confirmation modal should be passed to the component
             * Otherwise the search will occur
             */
            function getConfirmationPromise() {
                let confirmationPromise = null;
                if ($scope.confirmModal) {
                    confirmationPromise = $scope.confirmModal({
                        isDestructive: false,
                        callToActionText: 'Continue Search',
                        description:
                            'You have unsaved changes that will be lost if you continue the search!',
                    });
                }
                if ($scope.onSearch) {
                    confirmationPromise = $scope.onSearch();
                }
                return confirmationPromise || $q.when();
            }

            $scope.integrationFormUpdateTracker = (formId, hasLocalUpdates) => {
                if (hasLocalUpdates) {
                    formTracker[formId] = hasLocalUpdates;
                } else {
                    delete formTracker[formId];
                }
                formTrackerUpdateCallback();
            };

            $scope.hasNewIntegration = () => {
                return _.some($scope.unsavedCredentials, (cred) => cred.isNewIntegration);
            };

            $scope.removeCred = function (removedCred, id) {
                const foundIndex = $scope.credentials.indexOf(removedCred);

                if (foundIndex !== -1) {
                    $scope.credentials.splice(foundIndex, 1);
                }

                const unsavedIndex = $scope.unsavedCredentials.indexOf(removedCred);
                if (unsavedIndex !== -1) {
                    $scope.unsavedCredentials.splice(unsavedIndex, 1);
                }

                if (unsavedIndex === -1 && foundIndex > -1) {
                    // We are deleting actual credential
                    $scope.totalCredentialCount--;
                }

                $scope.integrationFormUpdateTracker(id, false);
                if (!$scope.hasNewIntegration()) {
                    $scope.$emit('hasNewIntegrationToggle', { hasNewIntegration: false });
                }
            };

            $scope.$on('credentials update', ($event, credential) => {
                const foundIndex = $scope.unsavedCredentials.indexOf(credential);
                if (foundIndex !== -1 && !credential.deleted) {
                    $scope.unsavedCredentials.splice(foundIndex, 1);
                    $scope.totalCredentialCount++;
                }
            });
        },
    ],
};

angular.module('signalview.orgIntegrations').directive('credentialDisplay', [
    function () {
        return credentialDisplay;
    },
]);

// eslint-disable-next-line import/no-unused-modules
export { credentialDisplay };
