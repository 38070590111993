'use strict';

/* jshint camelcase: false */
angular.module('sfx.data').factory('detectorService', [
    'sfxApi',
    'createMetabaseObjectService',
    '$q',
    'API_URL',
    '$http',
    'currentUser',
    function (sfxApi, createMetabaseObjectService, $q, API_URL, $http, currentUser) {
        const service = createMetabaseObjectService(sfxApi.detector);

        service.search = () => {
            throw new Error(
                'This method is no longer supported, please use DetectorV2SearchService or direct V2 endpoint call.'
            );
        };

        service.delete = function (id) {
            return currentUser.orgId().then(function (orgId) {
                return $http({
                    method: 'DELETE',
                    url: API_URL + '/v2/detector/' + id + '?organizationId=' + orgId,
                });
            });
        };

        service.getRelatedDashboards = function (detectorId) {
            const url = `${API_URL}/v2/detector/${detectorId}/_dashboards`;
            return $http.get(url).then((resp) => resp.data);
        };

        service.getLinkedTeams = function (detectorId) {
            const url = `${API_URL}/v2/detector/_/${detectorId}/teams`;
            return $http.get(url).then((resp) => resp.data);
        };

        function getV2Detector(id) {
            return currentUser.orgId().then(function (orgId) {
                return $http({
                    method: 'GET',
                    url: API_URL + '/v2/detector/' + id + '?organizationId=' + orgId,
                });
            });
        }

        function getV2DetectorData(id, errorHandler) {
            return getV2Detector(id).then(
                function (data) {
                    return data;
                },
                function (error) {
                    if (errorHandler) {
                        return errorHandler({
                            fn: getV2Detector,
                            id: id,
                            error: error,
                        });
                    } else {
                        return $q.reject(error);
                    }
                }
            );
        }

        service.get = (id, errorHandler) =>
            getV2DetectorData(id, errorHandler).then((res) => res.data);

        service.newV1 = function () {
            return {
                sf_detector: '',
                sf_programText: '',
                sf_signalflowVersion: 2,
                sf_type: 'Detector',
                sf_uiModel: {
                    currentUniqueKey: 1,
                    chartType: 'line',
                    chartMode: 'graph',
                    chartconfig: {
                        pointDensity: '',
                        disableThrottle: false,
                        range: 15 * 60000,
                        showDots: true,
                        yAxisConfigurations: [
                            {
                                name: 'yAxis0',
                                id: 'yAxis0',
                                max: null,
                                min: null,
                                plotlines: {
                                    high: null,
                                    low: null,
                                },
                            },
                        ],
                    },
                    revisionNumber: 1,
                    allPlots: [],
                    rules: [],
                },
            };
        };

        service.cloneV1 = (sampleV1Detector) => {
            const clone = angular.copy(sampleV1Detector);
            delete clone.sf_id;
            delete clone.sf_programText;
            delete clone.sf_updatedBy;
            delete clone.sf_createdBy;
            delete clone.sf_rules;
            delete clone.sf_jobIdsHistory;
            delete clone.sf_currentJobIds;

            clone.sf_detector += ' (Clone)';
            clone.sf_uiModel.revisionNumber = 1;

            if (clone.sf_uiModel.fromChart !== undefined) {
                delete clone.sf_uiModel.fromChart;
            }

            const uiModel = clone.sf_uiModel;

            let eventPlotIndex = -1;
            uiModel.allPlots.some(function (plot, index) {
                if (plot.type === 'event' && plot.name === sampleV1Detector.sf_id) {
                    eventPlotIndex = index;
                    return true;
                }
            });

            if (eventPlotIndex !== -1) {
                uiModel.allPlots.splice(eventPlotIndex, 1);
            }

            return clone;
        };

        service.cloneV2 = (sampleV2Detector) => {
            const clone = angular.copy(sampleV2Detector);
            delete clone.created;
            delete clone.creator;
            delete clone.customProperties;
            delete clone.id;
            delete clone.labelResolutions;
            delete clone.lastUpdated;
            delete clone.lastUpdatedBy;
            delete clone.overMTSLimit;
            delete clone.packageSpecifications;
            delete clone.tags;
            delete clone.teams;

            clone.name += ' (Clone)';

            return clone;
        };

        return service;
    },
]);
