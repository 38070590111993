import templateUrl from './catalogObjectView.tpl.html';

angular.module('signalview.objectmanager').directive('catalogObjectView', [
    '$location',
    '$timeout',
    'config',
    function ($location, $timeout, config) {
        return {
            restrict: 'E',
            scope: {
                object: '=',
                selectedType: '=',
                viewMode: '=',
                filters: '=',
                availablePlugins: '=',
                isNewCatalog: '=',
            },
            templateUrl,
            controller: [
                '$scope',
                'SYSTEM_USER_ID',
                function ($scope, SYSTEM_USER_ID) {
                    function updateInViewAll() {
                        const object = $scope.object;

                        if (!object) return;

                        const search = $location.search();

                        if (object.isType) {
                            $scope.inViewAll = search.topicFilterType === object.sf_topic;
                        } else {
                            $scope.inViewAll = search.topicFilterKey === object.sf_topic;
                        }
                    }

                    $scope.$on('$locationChangeSuccess', updateInViewAll);

                    $scope.$watch('object', function (object) {
                        if (!object) return;

                        const hasMetricFilter =
                            $scope.filters &&
                            $scope.filters.some(function (filter) {
                                return filter.property === 'sf_metric';
                            });

                        $scope.showPreview =
                            (object && object.sf_metric && object.sf_topic !== 'sf_metric') ||
                            hasMetricFilter;

                        $scope.isFakeObject = object.sf_id === 'FAKE';

                        $scope.objectList = [$scope.object];

                        const isSuperPowers = config('superpower.unreleasedFeatures');
                        $scope.isServiceDiscovery =
                            ($scope.object.sf_creator === SYSTEM_USER_ID ||
                                $scope.object.creator === SYSTEM_USER_ID) &&
                            !isSuperPowers;

                        updateInViewAll();
                    });
                },
            ],
            link: function ($scope, elem) {
                elem.bind('scroll', function () {
                    $scope.scrolled = elem[0].scrollTop > 100;
                });

                function scrollToHash(hash) {
                    const res = angular.element(elem[0]).find('.' + hash + '-view-anchor');
                    if (!res || !res.length) return null;
                    const hashElem = res[0];
                    angular
                        .element(elem)
                        .animate({ scrollTop: angular.element(hashElem).offset().top - 50 }, 750);
                    return hashElem;
                }

                function scrollToTop() {
                    angular.element(elem).animate({ scrollTop: 0 }, 300);
                }

                function scrollToAnchor() {
                    const hash = $location.hash();
                    if (!hash) {
                        scrollToTop();
                    } else if (!scrollToHash(hash)) {
                        // this handles deep linked urls, need time
                        // for the dashboards to fill out
                        $timeout(function () {
                            scrollToHash(hash);
                        }, 2000);
                    }
                }

                $scope.$on('updateAnchor', scrollToAnchor);
                $scope.$on('React:$routeUpdate', scrollToAnchor);

                if ($location.hash()) scrollToAnchor();
            },
        };
    },
]);
