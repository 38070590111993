import templateUrl from './detectorWizardSettings.tpl.html';

const autoClearMetadata = {
    displayName: 'Auto-Clear alerts',
    description: 'Clear active alerts after a specified time has elapsed',
    infoUrl:
        'https://quickdraw.splunk.com/redirect/?product=Observability&location=zombie.alert&version=current',
};

/**
 * The alert settings component allows user to edit rule condition
 * for selected alert condition.
 * All the binding functions and callbacks refer to RuleConditionController
 * so we reuse existing detector functions rule condition modal code.
 */
angular.module('signalview.detector.wizard').component('detectorWizardAlertSettings', {
    bindings: {
        rule: '<',
        readable: '<',
        plots: '<',
        numPlots: '<',
        category: '<',
        currentSelection: '<',
        jobInfo: '<',
        resolvingResolution: '<',
        signalResolution: '<',
        functionInputError: '<',
        isValid: '<',
        compoundConditionsEnabled: '<',
        preSelectedSignal: '<',
        functionSelector: '<',
        functionTrackBy: '<',
        sensitivityModel: '<',
        sharedPreflightState: '<',
        isNewRule: '<',
        updateRule: '&',
        updateSensitivity: '&',
        updateFuncInputValue: '&',
        removeFuncInputValue: '&',
        updateFunctionSelector: '&',
        proceed: '&',
        done: '&',
        onSelectTab: '&',
        toggleExpand: '&',
        expand: '<',
        orgSettings: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        '_',
        function ($scope, _) {
            const ctrl = this;
            const orOperator = 'OR';
            const andOperator = 'AND';
            const defaultOperator = orOperator;

            ctrl.$onInit = $onInit;
            ctrl.addCompoundCondition = addCompoundCondition;
            ctrl.removeCondition = removeCondition;
            ctrl.moveCondition = moveCondition;
            ctrl.autoClearMetadata = autoClearMetadata;

            const initCallbacks = [];
            ctrl.compoundOrderTitles = [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
                'Sixth',
                'Seventh',
                'Eighth',
                'Ninth',
                'Tenth',
            ];

            function $onInit() {
                $scope.sectionTabs = [
                    {
                        id: 'settings',
                        name: 'Alert Settings',
                    },
                    {
                        id: 'event',
                        name: 'Simulated Events',
                    },
                    {
                        id: 'data',
                        name: 'Data Table',
                    },
                ];
                $scope.tabIndices = {};
                $scope.sectionTabs.forEach(function (tab, index) {
                    $scope.tabIndices[tab.id] = index;
                });

                $scope.showAdvanced = false;
                $scope.selectTab($scope.sectionTabs[$scope.tabIndices.settings]); // settings
                $scope.legacyJob = {};
                // when local variable changes, update with parent binding
                if (initCallbacks.length) {
                    initCallbacks.forEach((s) => s());
                }
                initCallbacks.push(
                    $scope.$on('detector wizard selected stage', function (ev, stage) {
                        onUpdateTabStage('', stage);
                    })
                );
                initCallbacks.push(
                    $scope.$on('go to tab', function (ev, tabId, stage) {
                        onUpdateTabStage(tabId, stage);
                    })
                );
            }

            function checkForMoreOptions() {
                $scope.hasAdvancedOptions = false;
                if (ctrl.currentSelection) {
                    angular.forEach(ctrl.currentSelection.inputs, function (val) {
                        if (val.aboveTheFold === false) {
                            $scope.hasAdvancedOptions = true;
                        }
                    });
                }
            }

            function onUpdateTabStage(tabId, stage) {
                if (stage === 'settings') {
                    let tabIndex = $scope.tabIndices.settings;
                    if (tabId === 'events') {
                        tabIndex = $scope.tabIndices.event;
                    } else if (tabId === 'data') {
                        tabIndex = $scope.tabIndices.data;
                    }
                    $scope.selectTab($scope.sectionTabs[tabIndex]);
                    angular
                        .element('.detector-wizard-data-table')
                        .removeClass('show-off-detector-wizard-screen')
                        .detach()
                        .appendTo(angular.element('.settings-legend-data-table-parent'));
                    angular
                        .element('.detector-wizard-events-table')
                        .removeClass('show-off-detector-wizard-screen')
                        .detach()
                        .appendTo(angular.element('.settings-legend-events-parent'));
                    checkForMoreOptions();
                }
            }

            $scope.selectTab = function (tab) {
                $scope.selectedTab = tab;
                ctrl.onSelectTab({ tab: tab });
            };

            function addCompoundCondition() {
                ctrl.rule.conditions.push({
                    thresholdMode: 'above',
                    triggerMode: 'immediately',
                    targetPlot: ctrl.rule.targetPlot,
                    operator: defaultOperator,
                });
            }

            function removeCondition(idx) {
                ctrl.rule.conditions.splice(idx, 1);
            }

            function moveCondition(from, to) {
                const lengthOfConditions = ctrl.rule.conditions.length;
                if (from < 0) {
                    from = 0;
                }

                if (to < 0) {
                    to = 0;
                }

                if (from >= lengthOfConditions) {
                    from = lengthOfConditions - 1;
                }

                if (to >= lengthOfConditions) {
                    to = lengthOfConditions - 1;
                }
                const itemToMove = ctrl.rule.conditions.splice(from, 1)[0];
                // reset the operator
                if (to === 0) {
                    ctrl.rule.conditions[0].operator = itemToMove.operator;
                    delete itemToMove.operator;
                }

                // if moving from first one condition, we need to set the operator
                if (from === 0 && to === lengthOfConditions - 1) {
                    itemToMove.operator = defaultOperator;
                    delete ctrl.rule.conditions[0].operator;
                }
                ctrl.rule.conditions.splice(to, 0, itemToMove);
            }

            $scope.$watch(
                '$ctrl.rule.conditions',
                () => {
                    let orSelected;
                    let andSelected;
                    if (ctrl.rule.conditions) {
                        const targetPlots = [];
                        ctrl.rule.conditions.forEach((condition) => {
                            if (condition.operator === orOperator) {
                                orSelected = true;
                            }

                            if (condition.operator === andOperator) {
                                andSelected = true;
                            }
                            targetPlots.push(condition.targetPlot);
                        });

                        if (_.uniq(targetPlots).length !== 1) {
                            $scope.$emit('toggle compound conditions', true);
                        }
                    }

                    ctrl.bothOperatorSelected = orSelected && andSelected;
                },
                true
            );
        },
    ],
});
