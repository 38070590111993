import templateUrl from './detectorRuleListItem.tpl.html';

angular.module('signalview.detector.rule').directive('detectorRuleListItem', [
    'detectorUtils',
    'notifyBlockService',
    'moment',
    function (detectorUtils, notifyBlockService, moment) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                plots: '=',
                activeAlerts: '=',
                detector: '=',
                rule: '=ngModel',
                delete: '&onDelete',
                clone: '&onClone',
                onSave: '&onSave',
                fetchActiveAlerts: '&fetchActiveAlerts',
                activeAlertsOutdated: '=',
                activeAlertsLastRefresh: '=',
                isLoadingAlertsCount: '=',
                invalidRuleName: '=',
                onToggleRule: '&onToggleRule',
                isSelected: '=',
                editRuleCallback: '=',
            },
            templateUrl,
            link: function ($scope) {
                const rule = $scope.rule;

                const isNewRule = $scope.rule.draft;

                $scope.isLocked = $scope.detector.sf_isDpmDetector;
                $scope.editMode = false;

                $scope.notificationsAsString = rule.notifications
                    ? rule.notifications.map(notifyBlockService).join(', ')
                    : '';

                $scope.edit = function () {
                    if ($scope.editRuleCallback) {
                        $scope.editRuleCallback(rule);
                    } else {
                        if ($scope.editMode) return;
                        $scope.editMode = true;
                    }
                };

                $scope.enable = function () {
                    rule.disabled = false;
                    $scope.onSave();
                };

                $scope.disable = function () {
                    rule.disabled = true;
                    $scope.onSave();
                };

                $scope.close = function () {
                    $scope.editMode = false;
                    rule.edit = false;
                    $scope.onSave();
                };

                $scope.isAlertsCountUnknown = () =>
                    typeof $scope.activeAlerts !== 'number' && !isNewRule;

                $scope.getRuleDescription = (rule) =>
                    detectorUtils.getAutoDetectorRuleDescription(rule, $scope.plots);

                $scope.getTooltipText = () => {
                    let text = 'Active alerts for this rule';
                    if ($scope.activeAlertsLastRefresh) {
                        text += ` (last refresh: ${moment($scope.activeAlertsLastRefresh).format(
                            'HH:mm:ss'
                        )})`;
                    }

                    return text;
                };

                $scope.getBadgeType = function () {
                    if ($scope.rule.disabled) {
                        return 'disabled';
                    } else if ($scope.rule.invalid) {
                        return 'invalid';
                    } else {
                        return 'active';
                    }
                };

                if (rule.edit && !$scope.editRuleCallback) {
                    $scope.edit();
                }
            },
        };
    },
]);
