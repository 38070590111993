angular
    .module('signalview.objectmanager')
    // This is an interim service until we move
    // all objects to v2
    .service('v2ObjectService', [
        '$q',
        'detectorv2Service',
        function ($q, detectorv2Service) {
            const services = {
                detector: {
                    service: detectorv2Service,
                    sf_type: 'Detector',
                    test: function (o) {
                        return angular.isDefined(o.visualizationOptions) || o.programText;
                    },
                },
            };

            function getService(type) {
                return services[type.toLowerCase()];
            }

            function isV2Type(type) {
                return getService(type) !== undefined;
            }

            function isV2Object(object) {
                return (
                    typeof object.sf_type === 'string' &&
                    isV2Type(object.sf_type) &&
                    getService(object.sf_type).test(object)
                );
            }

            function getV2Object(type, id) {
                const obj = getService(type);
                if (!obj) {
                    return $q.reject('Unsupported');
                }
                return obj.service.get(id).then(function (object) {
                    const data = {
                        sf_type: obj.sf_type,
                    };
                    return angular.extend(data, object);
                });
            }

            function updateV2Object(object) {
                const obj = getService(object.sf_type);
                if (!obj) {
                    return $q.reject('Unsupported');
                }
                const toUpdate = angular.copy(object);
                delete toUpdate.sf_type;
                return obj.service.update(toUpdate);
            }

            return {
                isV2Object: isV2Object,
                isV2Type: isV2Type,
                get: getV2Object,
                update: updateV2Object,
            };
        },
    ]);
