import absConfigTemplateUrl from './ABS/config.tpl.html';
import bottomnConfigTemplateUrl from './BOTTOMN/config.tpl.html';
import ceilConfigTemplateUrl from './CEIL/config.tpl.html';
import countConfigTemplateUrl from './COUNT/config.tpl.html';
import deltaConfigTemplateUrl from './DELTA/config.tpl.html';
import ewmaConfigTemplateUrl from './EWMA/config.tpl.html';
import excludeConfigTemplateUrl from './EXCLUDE/config.tpl.html';
import floorConfigTemplateUrl from './FLOOR/config.tpl.html';
import integrateConfigTemplateUrl from './INTEGRATE/config.tpl.html';
import logConfigTemplateUrl from './LOG/config.tpl.html';
import log10ConfigTemplateUrl from './LOG10/config.tpl.html';
import maxConfigTemplateUrl from './MAX/config.tpl.html';
import meanConfigTemplateUrl from './MEAN/config.tpl.html';
import meanStdDevConfigTemplateUrl from './MEAN_STDDEV/config.tpl.html';
import minConfigTemplateUrl from './MIN/config.tpl.html';
import percentileConfigTemplateUrl from './PERCENTILE/config.tpl.html';
import powConfigTemplateUrl from './POW/config.tpl.html';
import rateOfChangeConfigTemplateUrl from './RATEOFCHANGE/config.tpl.html';
import rateConfigTemplateUrl from './RATE/config.tpl.html';
import scaleConfigTemplateUrl from './SCALE/config.tpl.html';
import sqrtConfigTemplateUrl from './SQRT/config.tpl.html';
import stddevConfigTemplateUrl from './STDDEV/config.tpl.html';
import sumConfigTemplateUrl from './SUM/config.tpl.html';
import timeshiftConfigTemplateUrl from './TIMESHIFT/config.tpl.html';
import topnConfigTemplateUrl from './TOPN/config.tpl.html';
import varianceConfigTemplateUrl from './VARIANCE/config.tpl.html';

import absDescTemplateUrl from './ABS/description.tpl.html';
import bottomnDescTemplateUrl from './BOTTOMN/description.tpl.html';
import ceilDescTemplateUrl from './CEIL/description.tpl.html';
import countDescTemplateUrl from './COUNT/description.tpl.html';
import deltaDescTemplateUrl from './DELTA/description.tpl.html';
import ewmaDescTemplateUrl from './EWMA/description.tpl.html';
import excludeDescTemplateUrl from './EXCLUDE/description.tpl.html';
import floorDescTemplateUrl from './FLOOR/description.tpl.html';
import integrateDescTemplateUrl from './INTEGRATE/description.tpl.html';
import logDescTemplateUrl from './LOG/description.tpl.html';
import log10DescTemplateUrl from './LOG10/description.tpl.html';
import maxDescTemplateUrl from './MAX/description.tpl.html';
import meanDescTemplateUrl from './MEAN/description.tpl.html';
import meanStdDevDescTemplateUrl from './MEAN_STDDEV/description.tpl.html';
import minDescTemplateUrl from './MIN/description.tpl.html';
import percentileDescTemplateUrl from './PERCENTILE/description.tpl.html';
import powDescTemplateUrl from './POW/description.tpl.html';
import rateOfChangeDescTemplateUrl from './RATEOFCHANGE/description.tpl.html';
import rateDescTemplateUrl from './RATE/description.tpl.html';
import scaleDescTemplateUrl from './SCALE/description.tpl.html';
import sqrtDescTemplateUrl from './SQRT/description.tpl.html';
import stddevDescTemplateUrl from './STDDEV/description.tpl.html';
import sumDescTemplateUrl from './SUM/description.tpl.html';
import timeshiftDescTemplateUrl from './TIMESHIFT/description.tpl.html';
import topnDescTemplateUrl from './TOPN/description.tpl.html';
import varianceDescTemplateUrl from './VARIANCE/description.tpl.html';

const TYPE_TO_TEMPLATE_URL = {
    ABS: {
        configuration: absConfigTemplateUrl,
        description: absDescTemplateUrl,
    },
    BOTTOMN: {
        configuration: bottomnConfigTemplateUrl,
        description: bottomnDescTemplateUrl,
    },
    CEIL: {
        configuration: ceilConfigTemplateUrl,
        description: ceilDescTemplateUrl,
    },
    COUNT: {
        configuration: countConfigTemplateUrl,
        description: countDescTemplateUrl,
    },
    DELTA: {
        configuration: deltaConfigTemplateUrl,
        description: deltaDescTemplateUrl,
    },
    EWMA: {
        configuration: ewmaConfigTemplateUrl,
        description: ewmaDescTemplateUrl,
    },
    EXCLUDE: {
        configuration: excludeConfigTemplateUrl,
        description: excludeDescTemplateUrl,
    },
    FLOOR: {
        configuration: floorConfigTemplateUrl,
        description: floorDescTemplateUrl,
    },
    INTEGRATE: {
        configuration: integrateConfigTemplateUrl,
        description: integrateDescTemplateUrl,
    },
    LOG: {
        configuration: logConfigTemplateUrl,
        description: logDescTemplateUrl,
    },
    LOG10: {
        configuration: log10ConfigTemplateUrl,
        description: log10DescTemplateUrl,
    },
    MAX: {
        configuration: maxConfigTemplateUrl,
        description: maxDescTemplateUrl,
    },
    MEAN: {
        configuration: meanConfigTemplateUrl,
        description: meanDescTemplateUrl,
    },
    MEAN_STDDEV: {
        configuration: meanStdDevConfigTemplateUrl,
        description: meanStdDevDescTemplateUrl,
    },
    MIN: {
        configuration: minConfigTemplateUrl,
        description: minDescTemplateUrl,
    },
    PERCENTILE: {
        configuration: percentileConfigTemplateUrl,
        description: percentileDescTemplateUrl,
    },
    POW: {
        configuration: powConfigTemplateUrl,
        description: powDescTemplateUrl,
    },
    RATE: {
        configuration: rateConfigTemplateUrl,
        description: rateDescTemplateUrl,
    },
    RATEOFCHANGE: {
        configuration: rateOfChangeConfigTemplateUrl,
        description: rateOfChangeDescTemplateUrl,
    },
    SCALE: {
        configuration: scaleConfigTemplateUrl,
        description: scaleDescTemplateUrl,
    },
    SQRT: {
        configuration: sqrtConfigTemplateUrl,
        description: sqrtDescTemplateUrl,
    },
    STDDEV: {
        configuration: stddevConfigTemplateUrl,
        description: stddevDescTemplateUrl,
    },
    SUM: {
        configuration: sumConfigTemplateUrl,
        description: sumDescTemplateUrl,
    },
    TIMESHIFT: {
        configuration: timeshiftConfigTemplateUrl,
        description: timeshiftDescTemplateUrl,
    },
    TOPN: {
        configuration: topnConfigTemplateUrl,
        description: topnDescTemplateUrl,
    },
    VARIANCE: {
        configuration: varianceConfigTemplateUrl,
        description: varianceDescTemplateUrl,
    },
};

export default TYPE_TO_TEMPLATE_URL;
