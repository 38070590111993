export default [
    '$http',
    'API_URL',
    'createV2Service',
    function ($http, API_URL, createV2Service) {
        const api = createV2Service('trace');
        const SERVICE_MAP_ENDPOINT = API_URL + '/v2/servicemap/';

        api.search = search;
        api.getServices = getServices;
        api.getOperations = getOperations;
        api.getTraceAnalysis = getTraceAnalysis;
        api.getSpanTags = getSpanTags;
        api.getServiceMapByTraceSearch = getServiceMapByTraceSearch;
        api.getTraceServiceMap = getTraceServiceMap;
        api.getOutlierAnalysis = getOutlierAnalysis;
        api.getTraceGroup = getTraceGroup;
        api.getEndpointAnalysis = getEndpointAnalysis;

        return api;

        // trace search does not take the parameters that are expected by the generic
        // search function so we override it with our own.
        function search({
            services,
            operations,
            duration,
            time,
            filters,
            status,
            includeOutlierAnalysis,
            includeTraceIds,
            spans,
        } = {}) {
            return $http
                .post(api.resourceEndpoint, {
                    services,
                    operations,
                    duration,
                    time,
                    filters,
                    status,
                    includeOutlierAnalysis,
                    includeTraceIds,
                    spans,
                })
                .then((result) => result.data);
        }

        function getServices(environment) {
            return $http
                .get(api.resourceEndpoint + '/services', { params: { cluster: environment } })
                .then((result) => result.data);
        }

        function getOperations(environment, services) {
            return $http
                .get(api.resourceEndpoint + '/operations', {
                    params: { services, cluster: environment },
                })
                .then((result) => result.data);
        }

        function getTraceAnalysis(traceId) {
            // TODO(skinner): move this off of experimental when it is safe
            return $http
                .get(api.experimentalEndpoint + `/${traceId}/analysis`)
                .then((result) => result.data);
        }

        function getTraceServiceMap(traceId) {
            return $http
                .get(SERVICE_MAP_ENDPOINT + `trace/${traceId}`)
                .then((result) => result.data);
        }

        function getServiceMapByTraceSearch({
            services,
            operations,
            duration,
            time,
            filters,
        } = {}) {
            return $http
                .post(SERVICE_MAP_ENDPOINT + 'traces', {
                    services,
                    operations,
                    duration,
                    time,
                    filters,
                })
                .then((result) => result.data);
        }

        function getSpanTags({ services, time, partialUserInput, environment } = {}) {
            return $http
                .post(api.resourceEndpoint + '/spantags', {
                    services,
                    time,
                    partialUserInput,
                    cluster: environment,
                })
                .then((result) => result.data);
        }

        function getOutlierAnalysis(
            {
                services,
                operations,
                duration,
                time,
                filters,
                excludeAnalyzerResult,
                excludeTopTagAnalysis,
                spans,
                traceIds,
            } = {},
            histogramParams
        ) {
            // TODO(skinner): move this off of experimental when it is safe
            return $http
                .post(
                    api.experimentalEndpoint + '/outliers',
                    angular.extend(
                        {
                            services,
                            operations,
                            duration,
                            time,
                            filters,
                            excludeAnalyzerResult,
                            excludeTopTagAnalysis,
                            spans,
                            traceIds,
                        },
                        histogramParams
                    )
                )
                .then((result) => result.data);
        }

        function getTraceGroup(
            {
                initiatingService,
                initiatingOperation,
                services,
                operations,
                httpMethod,
                duration,
                time,
                filters,
                status,
                excludedTraceIds,
                spans,
            } = {},
            urlParams
        ) {
            return $http
                .post(
                    api.resourceEndpoint + '/group',
                    {
                        initiatingService,
                        initiatingOperation,
                        services,
                        operations,
                        httpMethod,
                        duration,
                        time,
                        filters,
                        status,
                        excludedTraceIds,
                        spans,
                    },
                    { params: urlParams }
                )
                .then((result) => result.data);
        }

        function getEndpointAnalysis({ service, endpoint, environment, timestamp }) {
            return $http.post(api.experimentalEndpoint + '/endpointAnalysis', {
                service,
                endpoint,
                cluster: environment,
                startMs: timestamp - 60 * 1000,
                endMs: timestamp,
            });
        }
    },
];
