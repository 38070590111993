import timePickerTemplateUrl from './sftimepicker.tpl.html';

angular
    .module('signalview.timePicker')

    .directive('sftimepicker', function () {
        return {
            restrict: 'EA',
            require: ['sftimepicker', '?^ngModel'],
            controller: 'sfTimepickerController',
            replace: true,
            scope: {},
            templateUrl: timePickerTemplateUrl,
            link: function (scope, element, attrs, ctrls) {
                const timepickerCtrl = ctrls[0],
                    ngModelCtrl = ctrls[1];
                if (ngModelCtrl) {
                    timepickerCtrl.init(ngModelCtrl, element.find('input'));
                }
            },
        };
    })

    .controller('sfTimepickerController', [
        '$scope',
        '$attrs',
        '$parse',
        '$log',
        '$locale',
        'sftimepickerConfig',
        'timeZoneService',
        function ($scope, $attrs, $parse, $log, $locale, timepickerConfig, timeZoneService) {
            let selected = timeZoneService.moment(),
                ngModelCtrl = {
                    $setViewValue: angular.noop,
                }; // nullModelCtrl
            const meridians = angular.isDefined($attrs.meridians)
                ? $scope.$parent.$eval($attrs.meridians)
                : timepickerConfig.meridians || $locale.DATETIME_FORMATS.AMPMS;
            this.init = function (ngModelCtrl_, inputs) {
                ngModelCtrl = ngModelCtrl_;
                ngModelCtrl.$render = this.render;

                const hoursInputEl = inputs.eq(0),
                    minutesInputEl = inputs.eq(1),
                    secondsInputEl = inputs.eq(2);

                const mousewheel = angular.isDefined($attrs.mousewheel)
                    ? $scope.$parent.$eval($attrs.mousewheel)
                    : timepickerConfig.mousewheel;
                if (mousewheel) {
                    this.setupMousewheelEvents(hoursInputEl, minutesInputEl, secondsInputEl);
                }

                $scope.readonlyInput = angular.isDefined($attrs.readonlyInput)
                    ? $scope.$parent.$eval($attrs.readonlyInput)
                    : timepickerConfig.readonlyInput;
                this.setupInputEvents(hoursInputEl, minutesInputEl, secondsInputEl);
            };

            let hourStep = timepickerConfig.hourStep;
            if ($attrs.hourStep) {
                $scope.$parent.$watch($parse($attrs.hourStep), function (value) {
                    hourStep = parseInt(value, 10);
                });
            }

            let minuteStep = timepickerConfig.minuteStep;
            if ($attrs.minuteStep) {
                $scope.$parent.$watch($parse($attrs.minuteStep), function (value) {
                    minuteStep = parseInt(value, 10);
                });
            }

            let secondStep = timepickerConfig.secondStep;
            if ($attrs.secondStep) {
                $scope.$parent.$watch($parse($attrs.secondStep), function (value) {
                    secondStep = parseInt(value, 10);
                });
            }

            // 12H / 24H mode
            $scope.showMeridian = timepickerConfig.showMeridian;
            if ($attrs.showMeridian) {
                $scope.$parent.$watch($parse($attrs.showMeridian), function (value) {
                    $scope.showMeridian = !!value;

                    if (ngModelCtrl.$error.time) {
                        // Evaluate from template
                        const hours = getHoursFromTemplate();
                        const minutes = getMinutesFromTemplate();
                        if (angular.isDefined(hours) && angular.isDefined(minutes)) {
                            selected.hours(hours);
                            refresh();
                        }
                    } else {
                        updateTemplate();
                    }
                });
            }

            // Get $scope.hours in 24H mode if valid
            function getHoursFromTemplate() {
                let hours = parseInt($scope.hours, 10);
                const valid = $scope.showMeridian
                    ? hours > 0 && hours < 13
                    : hours >= 0 && hours < 24;
                if (!valid) {
                    return undefined;
                }

                if ($scope.showMeridian) {
                    if (hours === 12) {
                        hours = 0;
                    }
                    if ($scope.meridian === meridians[1]) {
                        hours = hours + 12;
                    }
                }
                return hours;
            }

            function getMinutesFromTemplate() {
                const minutes = parseInt($scope.minutes, 10);
                return minutes >= 0 && minutes < 60 ? minutes : undefined;
            }

            function getSecondsFromTemplate() {
                const seconds = parseInt($scope.seconds, 10);
                return seconds >= 0 && seconds < 60 ? seconds : undefined;
            }

            function pad(value) {
                return angular.isDefined(value) && value.toString().length < 2
                    ? '0' + value
                    : value;
            }

            // Respond on mousewheel spin
            this.setupMousewheelEvents = function (hoursInputEl, minutesInputEl, secondsInputEl) {
                const isScrollingUp = function (e) {
                    if (e.originalEvent) {
                        e = e.originalEvent;
                    }
                    //pick correct delta variable depending on event
                    const delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
                    return e.detail || delta > 0;
                };

                hoursInputEl.bind('mousewheel wheel', function (e) {
                    $scope.$apply(
                        isScrollingUp(e) ? $scope.incrementHours() : $scope.decrementHours()
                    );
                    e.preventDefault();
                });

                minutesInputEl.bind('mousewheel wheel', function (e) {
                    $scope.$apply(
                        isScrollingUp(e) ? $scope.incrementMinutes() : $scope.decrementMinutes()
                    );
                    e.preventDefault();
                });

                secondsInputEl.bind('mousewheel wheel', function (e) {
                    $scope.$apply(
                        isScrollingUp(e) ? $scope.incrementSeconds() : $scope.decrementSeconds()
                    );
                    e.preventDefault();
                });
            };

            this.setupInputEvents = function (hoursInputEl, minutesInputEl, secondsInputEl) {
                if ($scope.readonlyInput) {
                    $scope.updateHours = angular.noop;
                    $scope.updateMinutes = angular.noop;
                    $scope.updateSeconds = angular.noop;
                    return;
                }

                $scope.updateHours = function () {
                    const hours = getHoursFromTemplate();

                    if (angular.isDefined(hours)) {
                        selected.hours(hours);
                        refresh('h');
                    }
                };

                hoursInputEl.bind('blur', function () {
                    if (!$scope.invalidHours && $scope.hours < 10) {
                        $scope.$apply(function () {
                            $scope.hours = pad($scope.hours);
                        });
                    }
                });

                $scope.updateMinutes = function () {
                    const minutes = getMinutesFromTemplate();

                    if (angular.isDefined(minutes)) {
                        selected.minutes(minutes);
                        refresh('m');
                    }
                };

                minutesInputEl.bind('blur', function () {
                    if (!$scope.invalidMinutes && $scope.minutes < 10) {
                        $scope.$apply(function () {
                            $scope.minutes = pad($scope.minutes);
                        });
                    }
                });

                $scope.updateSeconds = function () {
                    const seconds = getSecondsFromTemplate();

                    if (angular.isDefined(seconds)) {
                        selected.seconds(seconds);
                        refresh('s');
                    }
                };

                secondsInputEl.bind('blur', function () {
                    if (!$scope.invalidSeconds && $scope.seconds < 10) {
                        $scope.$apply(function () {
                            $scope.seconds = pad($scope.seconds);
                        });
                    }
                });
            };

            this.render = function () {
                const date = ngModelCtrl.$modelValue
                    ? timeZoneService.moment(ngModelCtrl.$modelValue)
                    : null;

                if (isNaN(date)) {
                    ngModelCtrl.$setValidity('time', false);
                    $log.error(
                        'Timepicker directive: "ng-model" value must be a Date object, a number of milliseconds since 01.01.1970 or a string representing an RFC2822 or ISO 8601 date.'
                    );
                } else {
                    if (date) {
                        selected = date;
                    }
                    makeValid();
                    updateTemplate();
                }
            };

            // Call internally when we know that model is valid.
            function refresh(keyboardChange) {
                makeValid();
                const updatedDate = timeZoneService.moment(ngModelCtrl.$modelValue);
                const hours = selected.hours();
                const minutes = selected.minutes();
                const seconds = selected.seconds();
                updatedDate.hours(hours);
                updatedDate.minutes(minutes);
                updatedDate.seconds(seconds);
                ngModelCtrl.$setViewValue(updatedDate);
                updateTemplate(keyboardChange);
            }

            function makeValid() {
                ngModelCtrl.$setValidity('time', true);
                $scope.invalidHours = false;
                $scope.invalidMinutes = false;
            }

            function updateTemplate(keyboardChange) {
                let hours = selected.hours();
                const minutes = selected.minutes();
                const seconds = selected.seconds();

                if ($scope.showMeridian) {
                    hours = hours === 0 || hours === 12 ? 12 : hours % 12; // Convert 24 to 12 hour system
                }

                $scope.hours = keyboardChange === 'h' ? hours : pad(hours);
                $scope.minutes = keyboardChange === 'm' ? minutes : pad(minutes);
                $scope.seconds = keyboardChange === 's' ? seconds : pad(seconds);
                $scope.meridian = selected.hours() < 12 ? meridians[0] : meridians[1];
            }

            function addSeconds(seconds) {
                const dt = timeZoneService.moment(selected.valueOf() + seconds * 1000);
                selected.hours(dt.hours()).minutes(dt.minutes()).seconds(dt.seconds());
                refresh();
            }

            $scope.incrementHours = function () {
                addSeconds(hourStep * 60 * 60);
            };
            $scope.decrementHours = function () {
                addSeconds(-hourStep * 60 * 60);
            };
            $scope.incrementMinutes = function () {
                addSeconds(minuteStep * 60);
            };
            $scope.decrementMinutes = function () {
                addSeconds(-minuteStep * 60);
            };
            $scope.incrementSeconds = function () {
                addSeconds(secondStep);
            };
            $scope.decrementSeconds = function () {
                addSeconds(-secondStep);
            };

            $scope.toggleMeridian = function () {
                addSeconds(12 * 60 * 60 * (selected.hours() < 12 ? 1 : -1));
            };
        },
    ]);
