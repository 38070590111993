import templateUrl from './importError.tpl.html';

const NAVIGATOR_PACKAGE_TYPE = 'NAVIGATOR';
const GROUP_PACKAGE_TYPE = 'GROUP';
const DASHBOARD_PACKAGE_TYPE = 'DASHBOARD';

const CUTOVER_DATE = 'December 8, 2020';

const VERSION_MESSAGE = `If this file was exported on or before ${CUTOVER_DATE}, please try exporting again.`;

function checkIfPackagesAreValid(packages, expectedType) {
    const isPackagesValid = packages.every((pkg) => {
        return pkg && pkg.packageType === expectedType;
    });

    return isPackagesValid;
}

class PackageImporter {
    constructor(
        multiFileLoader,
        dashboardGroupService,
        dashboardV2Service,
        navigatorService,
        $q,
        $log,
        $location,
        sfxModal
    ) {
        this.multiFileLoader = multiFileLoader;
        this.dashboardGroupService = dashboardGroupService;
        this.dashboardV2Service = dashboardV2Service;
        this.navigatorService = navigatorService;
        this.$q = $q;
        this.$log = $log;
        this.$location = $location;
        this.sfxModal = sfxModal;
    }

    importGroups() {
        return this.multiFileLoader
            .load()
            .then((fileContents) => {
                const files = fileContents.map((contents) => JSON.parse(contents));
                const isValid = checkIfPackagesAreValid(files, GROUP_PACKAGE_TYPE);
                if (!isValid) {
                    return this.$q.reject('Invalid file format.');
                }

                return this.$q
                    .all(
                        files.map((file) => {
                            return this.dashboardGroupService.importGroup(file);
                        })
                    )
                    .then((importedGroups) => {
                        this.$log.info('Imported dashboard groups', importedGroups);
                        this.$location.search({});
                        this.$location.path(`/page/${importedGroups[0].id}`);
                    });
            })
            .catch((error) => {
                const errorMessage = error.data?.message || error;
                this.openErrorModal('dashboard group(s)', errorMessage, VERSION_MESSAGE);
            });
    }

    importDashboards(groupId) {
        return this.multiFileLoader
            .load()
            .then((fileContents) => {
                const files = fileContents.map((contents) => JSON.parse(contents));
                const isValid = checkIfPackagesAreValid(files, DASHBOARD_PACKAGE_TYPE);
                if (!isValid) {
                    return this.$q.reject('Invalid file format.');
                }

                return this.$q
                    .all(
                        files.map((file) => {
                            return this.dashboardV2Service.import(file, groupId);
                        })
                    )
                    .then((importedDashboards) => {
                        this.$log.info('Imported dashboards', importedDashboards);
                        this.$location.search({});
                        this.$location.path(`/dashboard/${importedDashboards[0].id}`);
                    });
            })
            .catch((error) => {
                const errorMessage = error.data?.message || error;
                this.openErrorModal('dashboard(s)', errorMessage, VERSION_MESSAGE);
            });
    }

    openErrorModal(objectType, errorMessage, versionMessage = '') {
        this.sfxModal.open({
            templateUrl,
            resolve: {
                objectType: () => objectType,
                errorMessage: () => errorMessage,
                versionMessage: () => versionMessage,
            },
            controller: [
                '$scope',
                'objectType',
                'errorMessage',
                'versionMessage',
                function ($scope, objectType, errorMessage, versionMessage) {
                    $scope.message = `Error importing ${objectType}`;
                    $scope.error = errorMessage;
                    $scope.versionMessage = versionMessage;
                },
            ],
            windowClass: 'obj-export-modal',
            keyboard: true,
        });
    }

    importNavigators() {
        return this.multiFileLoader.load().then((fileContents) => {
            const files = fileContents.map((contents) => JSON.parse(contents));
            const isValid = checkIfPackagesAreValid(files, NAVIGATOR_PACKAGE_TYPE);

            if (!isValid) {
                return this.$q.reject('Invalid file format.');
            }

            return this.$q
                .all(
                    files.map((file) => {
                        return this.navigatorService.importNavigator(file);
                    })
                )
                .then((importedNavigators) => {
                    this.$log.info('Imported navigators', importedNavigators);
                    this.$location.path(`/navigator/${importedNavigators[0].id}`);
                })
                .catch((err) => {
                    this.$log.error('Failed to import navigators', err);
                });
        });
    }
}

PackageImporter.$inject = [
    // declare-used-dependency-to-linter::multiFileLoader
    'multiFileLoader',
    // declare-used-dependency-to-linter::dashboardGroupService
    'dashboardGroupService',
    // declare-used-dependency-to-linter::dashboardV2Service
    'dashboardV2Service',
    // declare-used-dependency-to-linter::navigatorService
    'navigatorService',
    '$q',
    '$log',
    '$location',
    // declare-used-dependency-to-linter::sfxModal
    'sfxModal',
];

export default PackageImporter;
