import templateUrl from './heatmapChartLabel.tpl.html';

export const heatmapChartLabel = function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            data: '=',
        },
        templateUrl,
        link: function ($scope) {
            if ($scope.data.name === 'undefined') {
                $scope.name = 'n/a';
                $scope.tooltip = 'No value for ' + $scope.data.groupKey;
            } else {
                $scope.name = $scope.data.name;
                $scope.tooltip = $scope.name;
            }

            $scope.$on('rescaled', () => $scope.$broadcast('resize cozy text'));
        },
    };
};
