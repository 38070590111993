import templateUrl from './resolutionSuggestions.tpl.html';

angular.module('chartbuilderUtil').directive('resolutionSuggestions', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                visibility: '=',
                model: '=',
                valueLimit: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.hideTimeout = null;

                $scope.values = [
                    {
                        label: 'Auto',
                        value: null,
                    },
                    {
                        label: '1s  (1000 ms)',
                        value: 1000,
                    },
                    {
                        label: '5s  (5000 ms)',
                        value: 5000,
                    },
                    {
                        label: '10s (10000 ms)',
                        value: 10000,
                    },
                    {
                        label: '30s (30000 ms)',
                        value: 30000,
                    },
                    {
                        label: '1m (60000 ms)',
                        value: 60000,
                    },
                    {
                        label: '5m (300000 ms)',
                        value: 300000,
                    },
                    {
                        label: '1h (3600000 ms)',
                        value: 3600000,
                    },
                ];
                $scope.$watch('visibility', function (newval) {
                    if (newval) {
                        $timeout.cancel($scope.hideTimeout);
                        $scope.internalVisibility = true;
                    } else {
                        $scope.hideTimeout = $timeout(function () {
                            //open to suggestions on how to fight dropdown from eating my event and preventing the click
                            //on the menu item from registering.
                            $scope.internalVisibility = false;
                        }, 200);
                    }
                });

                $scope.updateValue = function (v, evt) {
                    evt.preventDefault();
                    $scope.model = v;
                    $timeout.cancel($scope.hideTimeout);
                    $scope.internalVisibility = false;
                };
            },
        };
    },
]);
