import { ngRoute } from '../../../../app/routing/ngRoute';
import emailVerificationTemplateUrl from './emailVerification.tpl.html';

export default {
    templateUrl: emailVerificationTemplateUrl,
    controller: [
        '$http',
        'API_URL',
        '$location',
        function ($http, API_URL, $location) {
            const $ctrl = this;

            $ctrl.credentials = {
                sessionId: ngRoute.params.sessionId,
                email: ngRoute.params.email,
                integrationId: ngRoute.params.integrationId,
            };

            if (!$ctrl.credentials.sessionId || !$ctrl.credentials.integrationId) {
                $ctrl.error = 'Invalid Credentials.';
            }

            $ctrl.validate = () => {
                if (!$ctrl.credentials.email) {
                    $ctrl.error = 'Valid email is required';
                } else if ($ctrl.emailConfirmation !== $ctrl.credentials.email) {
                    $ctrl.error = "Emails don't match";
                } else {
                    $ctrl.error = undefined;
                }
            };

            $ctrl.submit = function () {
                $ctrl.loading = true;
                $http.post(API_URL + '/v2/session/oidc/email', $ctrl.credentials).then(
                    () => {
                        $ctrl.loading = false;
                        $ctrl.error = undefined;
                        $location.path(`/verify/onetimepassword`).search($ctrl.credentials);
                    },
                    (response) => {
                        $ctrl.loading = false;
                        if (response.status === -1) {
                            $ctrl.error = 'Network Error';
                        } else {
                            $ctrl.error = response.data?.message || 'Verification Failed!';
                        }
                    }
                );
            };
        },
    ],
};
