import templateUrl from './dashboardGroupsColumns.tpl.html';

// this directive represents one section of up to three columns
export default [
    'STATIC_RESOURCE_ROOT',
    'favoriteService',
    'zeroStateService',
    'initService',
    'featureEnabled',
    'dashboardGroupService',
    'userAnalytics',
    'dashboardGroupCache',
    'IS_MOBILE',
    function (
        STATIC_RESOURCE_ROOT,
        favoriteService,
        zeroStateService,
        initService,
        featureEnabled,
        dashboardGroupService,
        userAnalytics,
        dashboardGroupCache,
        IS_MOBILE
    ) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                dashboardGroups: '=',
                columnCount: '=',
                name: '=',
                query: '=',
                showDashboardGroups: '=',
                isDashboardGroupVisible: '=',
                toggleDashboardGroupVisibility: '=',
                showAllClicked: '=',
                sectionTitle: '<?',
            },
            link: function ($scope) {
                $scope.isReady = initService.isReady;
                $scope.favoriteService = favoriteService;
                $scope.getColumnId = function (column) {
                    return column[0].id + ' ' + column.length;
                };

                // reordering disabled for beta scope of accessControl
                $scope.allowReordering = !IS_MOBILE && !featureEnabled('accessControl');
                $scope.showNoDragPermission = {};

                $scope.addFavorite = function (item) {
                    // reformatting occurs for the reason that these favorites are cached
                    // and used elsewhere in places that have not aliased these props to the
                    // same names as they have in the dashboard index.
                    favoriteService.add({
                        dashboardIn: {
                            sf_dashboard: item.name,
                            sf_page: item.sf_page,
                            sf_type: 'Dashboard',
                            sf_description: item.description,
                            sf_id: item.id,
                            favoriteKey: item.favoriteKey,
                        },
                    });
                };

                zeroStateService.queryForMetrics().then((hasNoMetrics) => {
                    $scope.hasNoMetrics = hasNoMetrics;
                    if ($scope.name === 'Built-in browse' && hasNoMetrics) {
                        $scope.dashboardGroups.push(getNewUserIntegration());
                    }
                });

                function getNewUserIntegration() {
                    return {
                        title: 'Set up an integration',
                        iconUrl: STATIC_RESOURCE_ROOT + 'assets/images/icon_addnew.png',
                        type: 'redirect',
                        id: 'setup-new-integration',
                    };
                }

                function getGroupFromEvent(event) {
                    return event.targetScope.$parent.dashboardGroup;
                }

                $scope.$on('jqui-drag-stop', function (event) {
                    const dashboardGroup = getGroupFromEvent(event);
                    $scope.showNoDragPermission[dashboardGroup.id] = false;
                    if (dashboardGroup.hasWritePermission) {
                        userAnalytics.event('dashboard-reorder', 'drag-stop');
                        return dashboardGroupService
                            .updateDashboardOrdering(dashboardGroup, dashboardGroup.dashboards)
                            .then((dashboardGroup) => {
                                $scope.$emit('dashboards reordered on index page', dashboardGroup);
                                dashboardGroupCache.invalidateTabCache();
                            });
                    } else {
                        userAnalytics.event('dashboard-reorder', 'drag-stop-no-permission');
                    }
                });

                $scope.$on('jqui-drag-start', function (event) {
                    const dashboardGroup = getGroupFromEvent(event);
                    if (!dashboardGroup.hasWritePermission) {
                        $scope.showNoDragPermission[dashboardGroup.id] = true;
                    } else {
                        $scope.showNoDragPermission[dashboardGroup.id] = false;
                    }
                });
            },
        };
    },
];
