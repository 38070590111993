import sourceFiltersTemplateUrl from './sourceFilters.tpl.html';

export default [
    'dashboardVariablesService',
    'chartUtils',
    function (variablesService, chartUtils) {
        return {
            restrict: 'E',
            templateUrl: sourceFiltersTemplateUrl,
            replace: true,
            controller: [
                '$scope',
                function ($scope) {
                    $scope.removeSource = function (idx) {
                        $scope.sourceFilters.splice(idx, 1);
                    };

                    $scope.getCurrentQuery = function () {
                        return chartUtils.getChartSignalFlow($scope.allCharts);
                    };

                    $scope.appliedVariables = [];
                    function updateAppliedVariables() {
                        const variables = $scope.activeVariables || $scope.filterAlias || [];
                        const overrides = variablesService.getVariablesFromUrl();
                        $scope.appliedVariables = variables.map(function (f) {
                            const override = overrides.filter(function (v) {
                                return v.alias === f.alias && v.property === f.property;
                            });
                            let value = f.value;
                            if (override.length) {
                                value = override[0].value;
                            } else if (f.valueOverride) {
                                value = f.valueOverride[0];
                            }
                            return {
                                variable: f.alias,
                                property: f.property,
                                value: value,
                                replaceOnly: f.replaceOnly,
                                applyIfExists: f.applyIfExists,
                            };
                        });
                    }
                    updateAppliedVariables();
                    $scope.$watchCollection('activeVariables', updateAppliedVariables);
                    $scope.$watchCollection('filterAlias', updateAppliedVariables);
                    $scope.$on('React:$routeUpdate', updateAppliedVariables);
                },
            ],
        };
    },
];
