import { isObject } from './signalflowAstNodeVerifiers.js';

const NODE_NUMBER_TYPES = ['long', 'double'];
const PERCENTILE_VALUES = [50, 90, 99];

export function extractGroupBy(ref, key, defaultValue) {
    if (!isObject(ref)) {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is required, but is not present.`);
    }

    if (ref.type === 'none') {
        return [];
    } else if (ref.type === 'list') {
        return (ref.items || []).map((item, i) => extractString(item, `${key}.[${i}]`));
    } else {
        throw new Error(`Parameter ${key} is not a valid list.`);
    }
}

export function extractLasting(ref, key, defaultValue) {
    if (!isObject(ref) || ref.type !== 'lasting') {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not present or is not a lasting.`);
    }

    return {
        duration: extractString(ref.duration, `${key}.duration`),
        percent: extractNumber(ref.atLeast, `${key}.atLeast`),
    };
}

export function extractTimeSpan(ref, key, defaultValue) {
    if (!isObject(ref) || ref.type !== 'string') {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not present or is not a string.`);
    }
    return ref.value;
}

export function extractStream(ref, key) {
    if (!isObject(ref) || ref.type !== 'var_ref') {
        throw new Error(`Parameter ${key} is not present or is not a stream.`);
    }
    return ref.name;
}

export function extractStringArray(ref, key) {
    if (!Array.isArray(ref)) {
        throw new Error(`Parameter ${key} is not not an array.`);
    }

    return ref.map((item, i) => extractString(item, `${key}.${i}`));
}

export function extractString(ref, key, defaultValue) {
    if (!isObject(ref) || ref.type !== 'string') {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not present or is not a string.`);
    }
    return ref.value;
}

export function extractNumber(ref, key, defaultValue) {
    if (!isObject(ref) || !NODE_NUMBER_TYPES.includes(ref.type)) {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not a number.`);
    }

    return ref.value;
}

export function extractBoolean(ref, key, defaultValue) {
    if (!isObject(ref) || ref.type !== 'boolean') {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not a boolean.`);
    }

    return ref.value;
}

export function extractPercent(ref, key, defaultValue) {
    if (!isObject(ref) || !NODE_NUMBER_TYPES.includes(ref.type)) {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not a percentage.`);
    }

    return ref.value;
}

export function extractPercentile(ref, key, defaultValue) {
    if (!isObject(ref) || !NODE_NUMBER_TYPES.includes(ref.type)) {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Parameter ${key} is not a percentile.`);
    }
    if (!PERCENTILE_VALUES.includes(ref.value)) {
        throw new Error(`Parameter ${key} has invalid percentile value`);
    }
    return ref.value;
}
