import PRINCIPAL_TYPE from './AccessControlPrincipalType';
import AccessControlPermissionAction from './AccessControlPermissionAction';
import AccessControlPrincipalDataCollection from './AccessControlPrincipalDataCollection';
import sha256Service from '../../../legacy/common/external/angular-sha256';

const sha256 = new sha256Service();

class PrincipalDataRepository {
    constructor(accessControlPrincipalService, userId, orgId) {
        this.accessControlPrincipalService = accessControlPrincipalService;
        this.userId = userId;
        this.orgId = orgId;
        this.collection = new AccessControlPrincipalDataCollection();
    }

    getSortedAcl(acl) {
        if (Array.isArray(acl)) {
            return this._updateCollection(acl).then(() => this._sortPrincipals(acl));
        }
        return Promise.resolve(acl);
    }

    getPrincipalsData(acl) {
        return this._updateCollection(acl).then(() =>
            acl.map((element) => this._aclToPrincipalData(element))
        );
    }

    search(term) {
        return this.accessControlPrincipalService.searchPrincipals(term);
    }

    _updateCollection(acl) {
        const missingPrincipals = acl
            .filter((item) => !this.collection.hasObject(item.principalId))
            .filter((item) =>
                [PRINCIPAL_TYPE.TEAM, PRINCIPAL_TYPE.USER].includes(item.principalType)
            );

        if (!missingPrincipals.length) {
            return Promise.resolve(this.collection);
        }

        return this.accessControlPrincipalService
            .getPrincipals(missingPrincipals)
            .then((newPrincipals) => (this.collection = this.collection.concat(newPrincipals)));
    }

    _sortPrincipals(acl) {
        // 1. Organization
        // 2. Current user
        // 3. READ/WRITE access principals (in alphabetical order)
        // 4. READ access principals (in alphabetical order)
        return _.sortBy(acl, [
            (aclItem) => aclItem.principalType !== PRINCIPAL_TYPE.ORG,
            (aclItem) => aclItem.principalId !== this.userId,
            (aclItem) => !aclItem.actions.includes(AccessControlPermissionAction.WRITE),
            (aclItem) => {
                const item = this.collection.getById(aclItem.principalId);
                return item ? item.label.toLocaleLowerCase() : '';
            },
        ]);
    }

    _aclToPrincipalData(item) {
        if (item.principalType === PRINCIPAL_TYPE.ORG) {
            return this._orgToPrincipalData(item);
        }
        return this._userOrTeamToPrincipalData(item);
    }

    _orgToPrincipalData(item) {
        return this.collection.prepareOrg({
            id: this.orgId,
            actions: item.actions,
            icon: '',
            iconClassName: 'icon-control_everyone',
        });
    }

    _userOrTeamToPrincipalData(item) {
        const principal = this.collection.getById(item.principalId);
        return {
            ...principal,
            icon:
                item.principalType !== PRINCIPAL_TYPE.TEAM
                    ? this._getIconLink(principal.email)
                    : undefined,
            iconClassName:
                item.principalType === PRINCIPAL_TYPE.TEAM ? 'icon-control_team' : undefined,
            actions: item.actions,
        };
    }

    _getIconLink(email) {
        if (!email) {
            return null;
        }

        const text = email.toString().toLowerCase();
        const hash = sha256.createHash(text);

        return `https://secure.gravatar.com/avatar/${hash}?s=19&d=retro`;
    }
}

export default PrincipalDataRepository;
