angular
    .module('signalboost')

    .service('EventRestService', [
        '$log',
        '$q',
        'signalboost',
        'SignalboostBaseService',
        'currentUser',
        function ($log, $q, signalboost, SignalboostBaseService, currentUser) {
            return class EventService extends SignalboostBaseService {
                constructor() {
                    super('event', false);
                }

                create(eventType, timestamp, properties, dimensions, source) {
                    return currentUser.orgId().then((orgId) => {
                        const body = {
                            eventType: eventType,
                            timestamp: timestamp || Date.now(),
                            category: 'USER_DEFINED',
                        };
                        if (properties) {
                            body.properties = properties;
                        }
                        if (dimensions) {
                            body.dimensions = dimensions;
                        }
                        if (source) {
                            body.source = source;
                        }
                        return this.all().customPOST(body, '', { orgid: orgId }, {});
                    });
                }

                delete(id) {
                    return currentUser.orgId().then((orgId) => {
                        return this.all().one(orgId).one(id).remove();
                    });
                }

                update(id, eventType, timestamp, properties, dimensions, source) {
                    return this.delete(id).then(
                        function () {
                            return signalboost.event.create(
                                eventType,
                                timestamp,
                                properties,
                                dimensions,
                                source
                            );
                        },
                        function (e) {
                            $log.error('Error updating event.');
                            return $q.reject(e);
                        }
                    );
                }
            };
        },
    ]);
