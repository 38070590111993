import templateUrl from './propertyKeyMap.tpl.html';

export default {
    templateUrl,
    bindings: {
        credential: '=',
        allowEmptyExternal: '<', // Allow for empty values for external term
        sfxPlaceholder: '@', // Sfx term input placeholder
        externalPlaceholder: '@', // External term placeholder
        disallowEmptyMap: '<', // Allow empty credential.propertyKeyMapping?
        showSignalfxTermsSuggestionBox: '=',
        showExternalTermsSuggestionBox: '=',
        hasUpdateCapability: '<', // Checking the capability, if rbac is ON else return the editable(admin) flag
        shouldEditableFormFieldForRbac: '<', // Checking the capability only if rbac is on, else return true
        labelId: '@?',
    },
    controller: [
        '$scope',
        '$timeout',
        '_',
        'suggestAPIService',
        function ($scope, $timeout, _, suggestAPIService) {
            const $ctrl = this;
            let debouncedSuggestions;

            $ctrl.$onInit = () => {
                reset();
            };

            $ctrl.setShowSignalFxTermSuggestionBox = () => {
                $ctrl.showSignalfxTermsSuggestionBox = true;
            };

            $ctrl.unsetShowSignalFxTermSuggestionBox = () => {
                $ctrl.showSignalfxTermsSuggestionBox = false;
            };

            $ctrl.getPropertySuggestions = (input) => {
                $timeout.cancel(debouncedSuggestions);
                debouncedSuggestions = $timeout(() => {
                    input = input || '';
                    let property = null;
                    let partialInput = input;
                    if (input.includes(':')) {
                        const keyval = input.split(':');
                        property = keyval.shift() || '';
                        partialInput = keyval.join(':');
                    }
                    return suggestAPIService
                        .getSignalFlowSuggest({
                            programs: [],
                            limit: 100,
                            property: property,
                            partialInput: partialInput,
                        })
                        .then((data) => {
                            return data.filter((props) => {
                                const key = property ? property + ':' + props : props;
                                return !$ctrl.temp.validKeyMapping[key];
                            });
                        });
                }, 100);
                return debouncedSuggestions;
            };

            function focusTypeaheadSiblingInput(elem) {
                $timeout(
                    () => {
                        elem.parent().siblings().find('input').focus();
                    },
                    0,
                    false
                );
            }

            function rowAction(row) {
                $timeout(() => {
                    const lastIndex = $ctrl.temp.propertyMap.length - 1;
                    const rowIndex = $ctrl.temp.propertyMap.indexOf(row);

                    if (_.isEmpty(row.externalTerm)) {
                        row.externalTerm = $ctrl.allowEmptyExternal ? '' : null;
                    }

                    // Remove map for the row, if key is changed or externalTerm is null
                    if (
                        row.mappedKey &&
                        (row.fxTerm !== row.mappedKey || row.externalTerm === null)
                    ) {
                        delete $ctrl.temp.validKeyMapping[row.mappedKey];
                        row.mappedKey = null;
                    }

                    // Remove external term if the key is being replicated. This disables the external term input.
                    if (
                        row.fxTerm &&
                        $ctrl.temp.validKeyMapping[row.fxTerm] &&
                        row.fxTerm !== row.mappedKey
                    ) {
                        row.externalTerm = null;
                    }

                    if (
                        rowIndex === lastIndex &&
                        !_.isEmpty(row.fxTerm) &&
                        row.externalTerm !== null
                    ) {
                        addAndBindPropertyRow();
                    } else if (rowIndex > -1 && rowIndex < lastIndex && _.isEmpty(row.fxTerm)) {
                        if (lastIndex > 0) {
                            $scope.propertyKeyMapForm[
                                'externalTerm_' + (lastIndex - 1)
                            ].$setPristine();
                            $scope.propertyKeyMapForm[
                                'externalTerm_' + (lastIndex - 1)
                            ].$setUntouched();
                        }
                        $ctrl.temp.propertyMap.splice(rowIndex, 1);
                    }

                    if (!_.isEmpty(row.fxTerm) && row.externalTerm !== null) {
                        $ctrl.temp.validKeyMapping[row.fxTerm] = row.externalTerm;
                        row.mappedKey = row.fxTerm;
                    }
                    $scope.propertyKeyMapForm.$setDirty();
                });
            }

            function addAndBindPropertyRow(fxVal, externalVal) {
                const row = {
                    mappedKey: fxVal || null,
                    fxTerm: fxVal || null,
                    externalTerm: externalVal || null,
                    rowAction: () => rowAction(row),
                    onSuggestionSelected: (val, elem) => {
                        if (row.fxTerm && row.fxTerm.indexOf(':') !== -1) {
                            const keyval = row.fxTerm.split(':');
                            row.fxTerm = keyval[0] + ':' + val;
                            focusTypeaheadSiblingInput(elem);
                        } else {
                            row.fxTerm = val;
                        }
                        rowAction(row);
                    },
                    onBlur: () => {
                        rowAction(row);
                        if ($ctrl.disallowEmptyMap && $scope.propertyKeyMapForm) {
                            $scope.propertyKeyMapForm.firstSfxTerm.$setTouched();
                            $scope.propertyKeyMapForm.firstSfxTerm.$setDirty();
                        }
                    },
                };

                $ctrl.temp.propertyMap.push(row);
            }

            function reset() {
                if (!$ctrl.credential.propertyKeyMapping) {
                    $ctrl.credential.propertyKeyMapping = {};
                }

                $ctrl.temp = {
                    propertyMap: [],
                    validKeyMapping: $ctrl.credential.propertyKeyMapping,
                };

                for (const key in $ctrl.temp.validKeyMapping) {
                    addAndBindPropertyRow(key, $ctrl.temp.validKeyMapping[key]);
                }

                // One empty row, for new inputs
                addAndBindPropertyRow();
            }

            $scope.$on('reset', reset);
        },
    ],
};
