import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular
    .module('signalview.detector')
    .service('preflightEstimation', [
        '$http',
        'API_URL',
        function ($http, API_URL) {
            return function (programText, packageSpec) {
                return $http
                    .post(API_URL + '/v2/signalflow/_/estimate', {
                        programText: programText,
                        packageSpecification: packageSpec,
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            };
        },
    ])
    .service('v2PreflightEstimation', [
        '$http',
        'API_URL',
        function ($http, API_URL) {
            return function (programText, detectLabel) {
                return $http
                    .post(
                        API_URL + '/v2/signalflow/_/estimatePreflight',
                        { programText: programText },
                        { params: { enabledLabels: detectLabel } }
                    )
                    .then(function (resp) {
                        return resp.data;
                    });
            };
        },
    ])
    .controller('PreflightController', [
        '$scope',
        'signalStream',
        '$log',
        '$interval',
        'preflightEstimation',
        'v2PreflightEstimation',
        'generateRangeText',
        'detectorPriorityService',
        'signalviewMetrics',
        'chartDisplayUtils',
        'CHART_DISPLAY_EVENTS',
        'programTextUtils',
        function (
            $scope,
            signalStream,
            $log,
            $interval,
            preflightEstimation,
            v2PreflightEstimation,
            generateRangeText,
            detectorPriorityService,
            signalviewMetrics,
            chartDisplayUtils,
            CHART_DISPLAY_EVENTS,
            programTextUtils
        ) {
            const ONE_HOUR_MS = 60 * 60 * 1000;
            const ONE_DAY_MS = 24 * ONE_HOUR_MS;
            const ONE_WEEK_MS = 7 * ONE_DAY_MS;

            const PROGRESS_REFRESH_INTERVAL = 1000;

            const MANUAL_PREFLIGHT_THRESHOLD = 45000; // 45 seconds time to complete

            // we will not allow preflight to be performed if it takes more than this time
            const PREFLIGHT_MAX_TIME_LIMIT = ONE_HOUR_MS;

            const PREFLIGHT_DELAY_SEC = 5;

            $scope.disableAllMetricPublishes = false;

            $scope.isPreflight = false;
            $scope.preflightEligible = $scope.isV2Detector; //v1 initializes to false. v2 manages its own eligibilty

            $scope.sharedPreflightState = {};

            $scope.$on('preflight eligible', function (evt, eligible) {
                $scope.preflightEligible = eligible;
                $scope.chartDisplayDebouncer.setEnabled(!eligible && !$scope.isWizard);
                if (!$scope.preflightEligible) {
                    cancelPreflightDebounce();
                    cancelProgressUpdate();
                    $scope.isPreflight = false;
                    $scope.stopStreaming();
                    $scope.$broadcast('preflight chart update', false);
                    $scope.preflightEligible = false;
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                    $scope.sharedPreflightState.preflightState = null;
                }
            });

            $scope.$on(CHART_DISPLAY_EVENTS.CHART_DISPLAY_WINDOW_EVENTS, function (ev, count) {
                $scope.sharedPreflightState.alertInRange = count;
            });

            $scope.stopStreaming = function () {
                if ($scope.dataStreamingHandler && $scope.dataStreamingHandler.stopStream) {
                    signalviewMetrics.incr('ui.preflight.stop');
                    $scope.dataStreamingHandler.stopStream();
                    $scope.sharedPreflightState.preflightState = 'DONE';
                }
                cancelProgressUpdate();
                $scope.dataStreamingHandler = null;
            };

            let progressUpdateInterval;
            function cancelProgressUpdate() {
                if (progressUpdateInterval) {
                    $interval.cancel(progressUpdateInterval);
                }
            }

            function getProgramText() {
                if ($scope.isV2Detector) {
                    return $scope.detector.programText;
                }
                try {
                    return programTextUtils.getV2ProgramTextPublishRuleCountDetectEventsBy(
                        $scope.model.sf_uiModel.allPlots,
                        $scope.detectorPreviewConfig.preview.rules,
                        'is'
                    );
                } catch (e) {
                    $log.error(e);
                }
            }

            let preflightDebounce;
            function detectorWatch(nval, oval) {
                if (nval === oval) {
                    return;
                }
                preflightTriggerDelay();
            }

            let jobRangeParameters;
            let preflightStartTime;
            let preflightEndTime;
            let preflightAvailableFrom;

            $scope.sharedPreflightState.preflightState = null;

            function preflightTriggerDelay() {
                if (!$scope.preflightEligible) {
                    return;
                }
                $scope.chartDisplayDebouncer.setEnabled(false);
                jobRangeParameters = chartDisplayUtils.getJobRangeParameters($scope.model);
                jobRangeParameters.range = Math.max(jobRangeParameters.range, -ONE_WEEK_MS);
                if (jobRangeParameters.endAt < -ONE_WEEK_MS) {
                    // older than one week, not available
                    cancelPreflightDebounce();
                    $scope.$broadcast('preflight chart update', true);
                    preflightAvailableFrom = Date.now() - ONE_WEEK_MS;
                    $scope.sharedPreflightState.preflightState = 'TOO_OLD';
                    return;
                }

                $scope.stopStreaming();
                clearEstimation();
                cancelPreflightDebounce();
                cancelProgressUpdate();
                getEstimation();
                $scope.$broadcast('preflight chart update', true);
                signalviewMetrics.incr('ui.preflight.delayTrigger');
                if (!checkIfShouldDoManual()) {
                    $scope.sharedPreflightState.preflightState = 'COUNTDOWN';
                    $scope.sharedPreflightState.preflightSecDelay = PREFLIGHT_DELAY_SEC;
                    preflightDebounce = $interval(
                        function () {
                            $scope.sharedPreflightState.preflightSecDelay--;

                            if ($scope.sharedPreflightState.preflightSecDelay <= 0) {
                                cancelPreflightDebounce();
                                checkAndExecutePreflight();
                            }
                        },
                        1000,
                        $scope.sharedPreflightState.preflightSecDelay
                    );
                }
            }

            $scope.$on('preflight trigger', preflightTriggerDelay);

            function cancelPreflightDebounce() {
                $scope.sharedPreflightState.preflightSecDelay = null;
                $interval.cancel(preflightDebounce);
                preflightDebounce = null;
            }

            $scope.$watch('detectorPreviewConfig.preview', detectorWatch);

            function getTimeFromEstimation() {
                return (
                    $scope.sharedPreflightState.estimation.range /
                    ($scope.sharedPreflightState.estimation.speed || 1)
                );
            }

            function fakeEstimation() {
                return {
                    fake: true,
                    speed: 1000,
                    resolution: 1000,
                };
            }

            function processEstimation(estimation) {
                $scope.sharedPreflightState.estimation = estimation;
                if ($scope.sharedPreflightState.estimation) {
                    $scope.sharedPreflightState.estimation.range =
                        jobRangeParameters.endAt - jobRangeParameters.range;
                    $scope.sharedPreflightState.estimation.rangeDisplay = generateRangeText(
                        $scope.sharedPreflightState.estimation.range
                    );
                    const timeToRunPreflight = getTimeFromEstimation();
                    $scope.sharedPreflightState.estimation.time =
                        generateRangeText(timeToRunPreflight);

                    jobRangeParameters.resolution =
                        $scope.sharedPreflightState.estimation.resolution;

                    $scope.backRangeTimeDisplay = generateRangeText($scope.backRange);

                    if (timeToRunPreflight > PREFLIGHT_MAX_TIME_LIMIT) {
                        preflightInvalid();
                    }
                    if (
                        !$scope.sharedPreflightState.estimation.fake &&
                        $scope.setResolutionOverride
                    ) {
                        $scope.setResolutionOverride(
                            $scope.sharedPreflightState.estimation.resolution
                        );
                    }
                }
            }
            function checkIfShouldDoManual() {
                if (
                    !$scope.sharedPreflightState.estimation ||
                    $scope.sharedPreflightState.estimation.fake
                ) {
                    return false;
                }
                // if time it takes to run is more than threshold
                if (getTimeFromEstimation() > MANUAL_PREFLIGHT_THRESHOLD) {
                    cancelPreflightDebounce();
                    $scope.sharedPreflightState.preflightState = 'MANUAL';
                    return true;
                } else {
                    return false;
                }
            }
            let estimationCount = 0;

            function fetchEstimation() {
                if ($scope.isV2Detector) {
                    return v2PreflightEstimation(getProgramText(), $scope.rule.detectLabel);
                } else {
                    return preflightEstimation(getProgramText());
                }
            }

            function getEstimation() {
                if (!$scope.sharedPreflightState.estimation) {
                    const currentEstimationCount = ++estimationCount;
                    fetchEstimation(getProgramText())
                        .catch(fakeEstimation)
                        .then(function (estimation) {
                            if (currentEstimationCount !== estimationCount) {
                                // ignore
                                return;
                            }
                            processEstimation(estimation);
                            // if it's still preflight eligible and not streaming
                            if (
                                $scope.preflightEligible &&
                                !$scope.dataStreamingHandler &&
                                !checkIfShouldDoManual() && // see if should be manually triggered
                                $scope.sharedPreflightState.preflightState === 'PREPARING' // if it's in preparing state, then trigger preflight immediately
                            ) {
                                checkAndExecutePreflight();
                            }
                        }, preflightInvalid);
                }
            }

            function clearEstimation() {
                $scope.sharedPreflightState.estimation = null;
            }

            function checkAndExecutePreflight() {
                if (!$scope.sharedPreflightState.estimation) {
                    // assume that estimation call is pending
                    $scope.sharedPreflightState.preflightState = 'PREPARING';
                    return;
                }
                if (!checkIfShouldDoManual()) {
                    $scope.performPreflight();
                }
            }

            let latestDataTimestamp;
            let eventAggregatedData = [];
            let eventAggregatedDataAccumulated = [];

            function resetResult() {
                $scope.longestEventRangeDisplay = null;

                latestDataTimestamp = 0;
                eventAggregatedData = [];
                eventAggregatedDataAccumulated = [];
            }

            function preflightInvalid() {
                cancelPreflightDebounce();
                $scope.sharedPreflightState.preflightState = 'INVALID';
            }

            function getProgressPercent() {
                const endTime = preflightEndTime || Date.now();
                return parseInt(
                    (Math.max(0, latestDataTimestamp - preflightStartTime) * 100) /
                        Math.max(1, endTime - preflightStartTime)
                );
            }

            function updateProgress() {
                const percent = getProgressPercent();

                // broadcast to chart display
                $scope.$broadcast(
                    'preflight update event',
                    eventAggregatedData,
                    latestDataTimestamp,
                    percent
                );
                eventAggregatedDataAccumulated =
                    eventAggregatedDataAccumulated.concat(eventAggregatedData);
                eventAggregatedData = [];
                if (percent > 95) {
                    $scope.sharedPreflightState.preflightState = 'LIVE';
                }
            }

            function getDisabledDetectLabels() {
                // v1 detectors fetch programText specfic to each rule and therefore don't need this
                if ($scope.isV2Detector) {
                    const rules = $scope.model.policies;
                    return rules
                        .filter((rule) => {
                            return (
                                $scope.rule.detectLabel &&
                                $scope.rule.detectLabel !== rule.detectLabel
                            );
                        })
                        .map((rule) => {
                            return rule.detectLabel;
                        });
                }
            }

            $scope.$on(
                CHART_DISPLAY_EVENTS.CHART_EVENT_STREAM_INIT,
                function eventStreamInit(ev, obj) {
                    obj.preflightAvailableFrom = preflightAvailableFrom;
                    obj.latestDataTimestamp = latestDataTimestamp;
                    obj.eventAggregatedDataAccumulated = eventAggregatedDataAccumulated;
                    obj.percent = getProgressPercent();
                }
            );

            $scope.performPreflight = function () {
                if ($scope.sharedPreflightState.preflightState === 'INVALID') {
                    return;
                }
                cancelPreflightDebounce();

                if (
                    !$scope.isV2Detector &&
                    (!$scope.detectorPreviewConfig.preview ||
                        !$scope.detectorPreviewConfig.isPreflight)
                ) {
                    // nothing to preflight then
                    $scope.sharedPreflightState.preflightState = null;
                    return;
                }

                // also need time out when job doesn't start in time.
                $log.info('starting preflight');

                const signalFlowToStream = getProgramText();

                $scope.isPreflight = true;

                // assume that we are preflighting only one rule right now
                const severityLevel = $scope.isV2Detector
                    ? $scope.rule.severity
                    : $scope.detectorPreviewConfig.preview.rules[0].severityLevel;
                $scope.ruleSeverity = severityLevel.toLowerCase();
                const severity = detectorPriorityService.getSeverityByDisplay(severityLevel);

                function scheduleProgress() {
                    cancelProgressUpdate();
                    progressUpdateInterval = $interval(updateProgress, PROGRESS_REFRESH_INTERVAL);
                }

                const disabledDetectLabels = getDisabledDetectLabels();
                const jobOpts = {
                    computingFor: $scope.model.sf_id,
                    traceContext: { chartId: $scope.model.sf_id },
                    signalFlowText: signalFlowToStream,
                    resolution: jobRangeParameters.resolution,
                    historyrange: jobRangeParameters.range,
                    stopTime: jobRangeParameters.endAt,
                    disableAllMetricPublishes: $scope.disableAllMetricPublishes,
                    disabledDetectLabels,
                    timestampAdvanceCallback: function (timestamp) {
                        latestDataTimestamp = timestamp;
                    },
                    callback: function (data) {
                        const metadata = $scope.dataStreamingHandler.metaDataMap[data.tsid];
                        if (metadata) {
                            const count = data.value;
                            const timestamp = data.timestamp;

                            eventAggregatedData.push({
                                count,
                                timestamp,
                                is: metadata.is,
                                priority: severity,
                            });
                        }

                        latestDataTimestamp = data.timestamp;
                    },
                    eventCallback: function () {},
                    streamStartCallback: function streamStarted() {
                        $log.info('stream start');
                        resetResult();
                    },
                    streamStopCallback: function () {
                        $log.info('stream stop');
                        signalviewMetrics.incr('ui.preflight.completed');
                        updateProgress();
                        $scope.dataStreamingHandler = null;
                        cancelProgressUpdate();
                    },
                    onFeedback: function onFeedback(msgs) {
                        msgs.forEach(function (msg) {
                            if (msg.messageCode === 'FETCH_NUM_TIMESERIES') {
                                $scope.sharedPreflightState.estimation.numInputTimeSeries =
                                    msg.numInputTimeSeries;
                            } else if (msg.messageCode === 'JOB_RUNNING_RESOLUTION') {
                                $scope.sharedPreflightState.estimation.resolution =
                                    msg.contents.resolutionMs;
                            }
                        });
                    },
                    metaDataUpdated: function onMetaDataMessage() {},
                    onStreamError: function onJobError() {
                        $log.error('stream error');
                        signalviewMetrics.incr('ui.preflight.error');
                        $scope.dataStreamingHandler = null;
                        preflightInvalid();
                    },
                    maxDelayMs:
                        parseInt(safeLookup($scope.model, 'sf_uiModel.chartconfig.maxDelay'), 10) ||
                        null,
                    offsetByMaxDelay: true,
                };
                resetResult();
                $scope.stopStreaming();
                $scope.sharedPreflightState.preflightState = 'RUNNING';
                const now = Date.now();
                preflightStartTime = now + jobRangeParameters.range;
                latestDataTimestamp = preflightStartTime;
                preflightAvailableFrom = now - ONE_WEEK_MS;
                if (preflightAvailableFrom < preflightStartTime) {
                    // if available from is less than actual start time, we don't care, mark it as 0.
                    preflightAvailableFrom = 0;
                }
                if (jobRangeParameters.endAt) {
                    // if it's non streaming, if it's streaming, we don't assign end time so we just keep it
                    // streaming for progress reporting.
                    preflightEndTime = now + jobRangeParameters.endAt;
                }

                $scope.$broadcast('chart reinit event streaming');
                scheduleProgress();
                signalviewMetrics.incr('ui.preflight.start');

                //TODO(annie): refactor v1 to use preflight
                if ($scope.isV2Detector) {
                    $scope.dataStreamingHandler = signalStream.streamPreflight(jobOpts);
                } else {
                    $scope.dataStreamingHandler = signalStream.stream(jobOpts);
                }
                $scope.sharedPreflightState.alertInRange = 0;
            };
        },
    ]);
