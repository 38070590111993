export const METRICS_CATALOG_CONSTANTS = {
    INTEGRATION_COLUMN_COUNT: 3,
    ITEM_TYPES: {
        BUTTON: 0,
        INTEGRATION_CATEGORY: 1,
        RECENTLY_ADDED_CATEGORY: 2,
    },
    RECENTLY_ADDED_CATEGORY_NAME: 'Recently Added',
    INTEGRATION_CATEGORIES: [
        'Recently Added',
        'Cloud infrastructure and services',
        'Compute',
        'Containers',
        'System / OS',
        'Storage',
        'Database',
        'Migration',
        'Networking & Content Delivery',
        'Developer Tools',
        'Management Tools',
        'Media Services & Solutions',
        'AI & Machine Learning',
        'Analytics',
        'Security, Identity & Compliance',
        'Mobile Services',
        'Application Integration',
        'Customer Engagement',
        'Desktop & App Streaming',
        'Internet of Things',
        'Game Development',
        'Cloud Cost Management',
        'API Platform and Ecosystems',
        'Web',
    ],
    INTEGRATION_CATEGORIES_SENTENCE_CASE: {
        'Networking & Content Delivery': 'Networking & content delivery',
        'Developer Tools': 'Developer tools',
        'Management Tools': 'Management tools',
        'Media Services & Solutions': 'Media services & solutions',
        'Mobile Services': 'Mobile services',
        'Application Integration': 'Application integration',
        'Customer Engagement': 'Customer engagement',
        'Desktop & App Streaming': 'Desktop & app streaming',
        'Game Development': 'Game development',
        'Cloud Cost Management': 'Cloud cost management',
        'API Platform and Ecosystems': 'API platform and ecosystems',
    },
    DEFAULT_CUSTOM_CATEGORIES_VALUE_LIMIT: 5,
    RECENTLY_ADDED_INTEGRATIONS_LIMIT: 5,
    RECENTLY_ADDED_INTEGRATIONS_THRESHOLD_MS: 86400000,
    SHOW_MORE_MODAL_TITLE_PREFIX: 'Values of ',
    FILTER_MODAL_TITLE: 'Filter by',
    GOOGLE_ANALYTICS_EVENT_CATEGORY: 'metrics-finder',
    MAX_SEARCH_TERMS: 10,
};
