import templateUrl from './samplingAndRenderingIcons.tpl.html';

export const samplingAndRenderingIcons = {
    templateUrl,
    bindings: {
        jobMessageSummary: '<',
        renderScore: '<',
        datapointReceived: '<',
        renderStats: '<',
        shouldRender: '&',
    },
    controller: [function () {}],
};
