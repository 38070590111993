export default [
    '$log',
    '$rootScope',
    'dashboardGroupService',
    function ($log, $rootScope, dashboardGroupService) {
        return {
            deleteGroup,
            findOrCreateDefaultGroup,
            getGroupSuggestions,
        };

        // this should be used instead of calling dashboardGroupService directly,
        // because it broadcasts info about deleted dashboards on the rootScope

        // TODO(trevor): Add argument here for the dashboards actually deleted
        function deleteGroup(pageId) {
            return dashboardGroupService.get(pageId).then((dashboardGroup) => {
                dashboardGroup.dashboards.forEach((dashboardId) => {
                    $rootScope.$broadcast('dashboard deleted', dashboardId);
                });

                return dashboardGroupService.delete(pageId).catch((e) => {
                    $log.error('failed deleting page', e);
                    throw e;
                });
            });
        }

        function findOrCreateDefaultGroup(user, primaryOrgUser) {
            if (user.homePageId) {
                user.sf_homePageId = user.homePageId;
                user.homePageId = ''; // does
                user.patch();
            }

            if (user.sf_homePageId) {
                return dashboardGroupService.get(user.sf_homePageId);
            }

            const newDashboardGroup = {
                name: primaryOrgUser.sf_email,
                description: 'Default Page',
            };

            return dashboardGroupService
                .create(newDashboardGroup)
                .then((createdPage) => user.patch({ sf_homePageId: createdPage.sf_id }));
        }

        function getGroupSuggestions(queryName, showFakeItems = true) {
            return dashboardGroupService.searchWritableByAny(queryName).then(({ results }) => {
                if (showFakeItems) {
                    const hasExactPage = results.some((page) => page.name === queryName);
                    if (!hasExactPage) {
                        results.unshift({
                            sf_page: queryName,
                            sf_id: 'FAKE',
                        });
                    }
                }

                return results;
            });
        }
    },
];
