angular
    .module('signalview.dashboard')

    .service('variableTransferService', [
        'dashboardGroupCache',
        'dashboardVariablesService',
        'urlOverridesService',
        'sourceFilterService',
        'dashboardVariableUtils',
        'featureEnabled',
        function (
            dashboardGroupCache,
            dashboardVariablesService,
            urlOverridesService,
            sourceFilterService,
            dashboardVariableUtils,
            featureEnabled
        ) {
            function acceptableValue(varCache, variable, value) {
                // value is acceptable if there aren't restricted values, values are not
                // restricted, or the value is in the list of preferredSuggestions.
                value = angular.isArray(value) ? value : [value];

                if (!variable.preferredSuggestions || !variable.preferredSuggestions.length) {
                    return value;
                } else if (variable.restricted) {
                    return value.filter(function (val) {
                        return variable.preferredSuggestions.indexOf(val) !== -1;
                    });
                } else {
                    return value;
                }
            }

            function hasSelectedNewValues(savedVals, queryStrVals) {
                if (!savedVals || !queryStrVals) {
                    return false;
                }
                if (savedVals.length !== queryStrVals.length) {
                    return true;
                }

                const valueMap = {};
                savedVals.reduce((map, value) => {
                    map[value] = true;
                    return map;
                }, valueMap);

                return !queryStrVals.some((value) => valueMap[value]);
            }

            /* public functions */

            function updateHrefs(dashboardGroupId, dashboardList, includeTime, includeDensity) {
                const varCache = dashboardGroupCache.getVariables(dashboardGroupId);
                const time = includeTime && urlOverridesService.getGlobalTimePicker();
                const density = includeDensity && urlOverridesService.getPointDensity();
                const dashboardIdToParams = {};

                dashboardList.forEach(function (dashboard) {
                    const linkParams = [];
                    if (time && time.start) {
                        if (time.relative) {
                            linkParams.push('startTime=' + time.start + '&endTime=' + time.end);
                        } else {
                            linkParams.push(
                                'startTimeUTC=' + time.start + '&endTimeUTC=' + time.end
                            );
                        }
                    }

                    if (density) {
                        linkParams.push('density=' + density);
                    }

                    if (varCache) {
                        const carriedVariables = getVariables(
                            dashboardGroupId,
                            dashboard.filters.variables
                        );
                        if (carriedVariables) {
                            linkParams.push(carriedVariables);
                        }
                    }

                    // TODO(trevor): Using dashboard id as backup, should be ok in practice but be aware
                    const dashKey = dashboard.configId || dashboard.id;
                    dashboardIdToParams[dashKey] = linkParams.length ? linkParams.join('&') : '';
                });

                return dashboardIdToParams;
            }

            function storeVariables(dashboardGroupId, variables) {
                const varCache = dashboardGroupCache.getVariables(dashboardGroupId);
                const currentValues = {};
                const variableMapByProperty = {};

                // for each variable with a default value, store the value using dimension
                // as the key.
                (variables || []).forEach((variable) => {
                    const value = dashboardVariableUtils.getVariableDefaultValue(variable);
                    currentValues[variable.property] = angular.isArray(value) ? value : [value];
                    variableMapByProperty[variable.property] = variable;
                });

                // only overrides that have been specifically selected by the user will be stored
                // in the cache.
                const overrides = dashboardVariablesService.getVariablesUrlOverrideAsModel() || [];
                overrides.forEach((variable) => {
                    const value = angular.isArray(variable.value)
                        ? variable.value
                        : [variable.value];
                    const variableProperty = variable.property;
                    if (
                        hasSelectedNewValues(currentValues[variableProperty], value) ||
                        varCache[variableProperty]
                    ) {
                        if (
                            featureEnabled('mappingService') &&
                            variableMapByProperty[variableProperty] &&
                            variableMapByProperty[variableProperty].propertyMappings
                        ) {
                            variableMapByProperty[variableProperty].propertyMappings.forEach(
                                (propertyMapping) => {
                                    varCache[propertyMapping] = value;
                                }
                            );
                        } else {
                            varCache[variableProperty] = value;
                        }
                    }
                });

                // Remove variables from the cache that were cleared by the user

                const overrideProperties = [];
                overrides.map((variable) => {
                    const variableProperty = variable.property;
                    if (
                        featureEnabled('mappingService') &&
                        variableMapByProperty[variableProperty] &&
                        variableMapByProperty[variableProperty].propertyMappings
                    ) {
                        overrideProperties.push(
                            ...variableMapByProperty[variableProperty].propertyMappings
                        );
                    } else {
                        overrideProperties.push(variableProperty);
                    }
                });

                for (const property in varCache) {
                    if (overrideProperties.indexOf(property) === -1) {
                        delete varCache[property];
                    }
                }

                dashboardGroupCache.storeVariables(dashboardGroupId, varCache);
            }

            function getVariables(groupId, variables) {
                const varCache = dashboardGroupCache.getVariables(groupId);
                const overrides = [];

                if (variables) {
                    variables.forEach(function (variable) {
                        if (varCache.hasOwnProperty(variable.property)) {
                            // get values that are relevant to and not excluded from variables on
                            // the current dashboard.
                            const savedValues = acceptableValue(
                                varCache,
                                variable,
                                varCache[variable.property]
                            );

                            // do not override value if variable has default, if there is no saved value,
                            // or if variable is restricted such that saved values must be rejected.
                            if (varCache.hasOwnProperty(variable.property) && savedValues) {
                                let valueStr;
                                if (savedValues.length === 1 && !savedValues[0]) {
                                    valueStr = '';
                                } else {
                                    valueStr =
                                        sourceFilterService.flattenPropertyValue(savedValues);
                                }

                                const fullVariableStr = `${variable.alias}=${variable.property}:${valueStr}`;

                                overrides.push(
                                    `variables[]=${encodeURIComponent(fullVariableStr)}`
                                );
                            }
                        }
                    });
                }

                // set overrides to the url and return the list of overrides.
                if (overrides.length) {
                    return overrides.join('&');
                }
                return '';
            }

            const reset = dashboardGroupCache.clearVariables;

            return {
                getVariables: getVariables,
                storeVariables: storeVariables,
                reset: reset,
                updateHrefs: updateHrefs,
            };
        },
    ]);
