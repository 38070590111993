import k8sConfig from '../../../../app/kubeNavigator/configs/clusterMapConfig';

export default [
    'clusterMapUtil',
    function (util) {
        const DEFAULT_RESOLUTION = 60000;
        const ROOT_RESOURCE = 'GLOBAL';

        return clusterMapConfig;

        function clusterMapConfig(navConfigData) {
            const config = navConfigData ?? k8sConfig;
            const resources = {};
            const deadHostPeriod = config.deadHostPeriod;
            const resolution = config.defaultResolution || DEFAULT_RESOLUTION;
            const hierarchy = [ROOT_RESOURCE].concat(config.hierarchy);
            const resourceGroupMap = { GLOBAL: ROOT_RESOURCE };
            const allSignalFlow = [];
            const hierarchicalGroupByKeys = [null]; // Global first level;

            config.hierarchy.forEach((key) => {
                resourceGroupMap[key] = config.resources[key].groupBy;
            });

            for (const resourceType in config.resources) {
                const resource = clusterResourceConfig(
                    resourceType,
                    config.resources[resourceType],
                    hierarchy.indexOf(resourceType)
                );
                resources[resourceType] = resource;
                hierarchicalGroupByKeys.push(resource.getGroupByKey());
            }

            // Replace all double quotes with single quotes to avoid 500 error response from api.
            for (const resource of hierarchy) {
                const resourceConfig = resources[resource];
                if (resourceConfig && resourceConfig.hasStreamingJob()) {
                    allSignalFlow.push({
                        programText: resourceConfig.getResourceJobs().replace(/"/g, "'"),
                        packageSpecifications: '',
                    });
                }
            }

            return {
                get(resourceType) {
                    return resources[resourceType];
                },
                getDeadHostPeriod() {
                    return deadHostPeriod;
                },
                getResolution() {
                    return resolution;
                },
                getResourceHierarchy() {
                    return hierarchy;
                },
                getHierarchicalGroupByKeys() {
                    return hierarchicalGroupByKeys;
                },
                getResourceAtDepth(depth) {
                    return hierarchy[depth];
                },
                getResourceDepth(resourceType) {
                    return hierarchy.indexOf(resourceType);
                },
                getResourceGroupMap() {
                    return resourceGroupMap;
                },
                getSignalFlowForAllResources() {
                    return allSignalFlow;
                },
            };
        }

        function clusterResourceConfig(resourceType, originalConfig, depth) {
            let colorByMetricIndex = 0;
            const config = Object.assign({ depth }, originalConfig);

            return {
                getConfig() {
                    return Object.assign({}, config);
                },
                getFilterAction() {
                    return config.filterAction;
                },
                getGroupByKey() {
                    return config.groupBy;
                },
                getId(data) {
                    return util.getId(data, resourceType, config.idTemplate);
                },
                getSourceId() {
                    return config.sourceId;
                },
                getColorByMetric() {
                    return config.metrics ? config.metrics[colorByMetricIndex] : null;
                },
                getColorByConfig() {
                    const colorByMetric = this.getColorByMetric();
                    return colorByMetric ? colorByMetric.coloring : null;
                },
                getColorByJob(filters) {
                    return getJob(filters, this.getColorByMetric());
                },
                getColorByMetricDescriptions() {
                    return config.metrics
                        ? config.metrics.map(({ displayName, description }) => ({
                              displayName,
                              description,
                          }))
                        : null;
                },
                getLayoutConfig() {
                    return config.layoutJob;
                },
                getLayoutJob(filters) {
                    return getJob(filters, this.getLayoutConfig());
                },
                requiresLayoutJob() {
                    return !this.getColorByMetric() || !this.getColorByMetric().suppliesLayoutInfo;
                },
                hasStreamingJob() {
                    return this.getColorByMetric() !== null || this.getLayoutJob() !== null;
                },
                setColorBy(index) {
                    colorByMetricIndex = index;
                },
                /**
                 * Processess and returns metadata along with information associated/derived on the metadata
                 * @param metadata
                 * @param tsId
                 * @param { metadata, id, streamData, resourceType, ...attrs } metaObject
                 * @returns { metadata, id, streamData, resourceType, ...attrs } metaObject
                 */
                processMetaObjectForTsId(metadata, tsId, metaObject = {}) {
                    metaObject.id = this.getId(metadata);
                    metaObject.resourceType = resourceType;
                    const streamLabel = metadata.sf_streamLabel;
                    const streamData = {
                        label: streamLabel,
                        isColoringJob:
                            streamLabel === util.getJobPublishLabel(this.getColorByMetric()),
                    };

                    util.processMetricValueForUpdate(streamData, metadata, this.getColorByMetric());

                    if (!metaObject.streamData) {
                        metaObject.streamData = {};
                    }
                    metaObject.streamData[tsId] = streamData;

                    if (!metaObject.metadata) {
                        metaObject.metadata = {};
                    }

                    Object.assign(metaObject.metadata, metadata);

                    const isHeaderCandidate = isParentHeaderCandidatesSelectorStream(
                        config,
                        streamLabel
                    );

                    if (isHeaderCandidate) {
                        metaObject.headerCandidateStream = tsId;
                        metaObject.headerLabel = streamLabel;
                        metaObject.headerCandidateForDepth = depth - 1;
                    }

                    return metaObject;
                },
                getTooltipKeyList() {
                    return config.tooltipKeyList;
                },
                getHeaderCandidateSelectorJob(filters) {
                    return getJob(filters, config.parentHeaderCandidatesSelectorMetric);
                },
                getResourceJobs(filters) {
                    const jobs = [];
                    jobs.push(this.getColorByJob(filters));

                    if (this.requiresLayoutJob()) {
                        jobs.push(this.getLayoutJob(filters));
                    }

                    jobs.push(this.getHeaderCandidateSelectorJob(filters));

                    return jobs.filter((job) => job !== null).join('\n');
                },
                getTooltipData(datum) {
                    const displayName = datum.data[datum.groupedUpon];
                    const headerLabel = datum.headerLabel;
                    const tooltipKeyList = this.getTooltipKeyList();

                    if (!tooltipKeyList) {
                        return { displayName };
                    }

                    const tooltipDataList = [];

                    tooltipKeyList.forEach((key) => {
                        if (key.property === 'subLevelCount' && datum.children) {
                            tooltipDataList.push(
                                util.getTooltipRowPair(key, datum.children.length)
                            );
                        } else if (key.property === 'value' && datum.data.value) {
                            tooltipDataList.push(
                                util.getTooltipRowPair(this.getColorByMetric(), datum.data.value)
                            );
                        } else if (datum.data[key.property]) {
                            tooltipDataList.push(
                                util.getTooltipRowPair(key, datum.data[key.property])
                            );
                        }
                    });

                    return { displayName, headerLabel, tooltipDataList };
                },
            };

            function isParentHeaderCandidatesSelectorStream(resourceConfig, streamLabel) {
                const headerSelectorMetric = resourceConfig.parentHeaderCandidatesSelectorMetric;
                return (
                    resourceConfig.depth > 1 &&
                    headerSelectorMetric &&
                    util.getJobPublishLabel(headerSelectorMetric) === streamLabel
                );
            }

            function getJob(filters = [], jobConfig) {
                if (!jobConfig || !jobConfig.job) {
                    return null;
                }
                if (jobConfig.job.replaceOnlyFilters && filters) {
                    filters = filters.filter(({ property }) =>
                        jobConfig.job.replaceOnlyFilters.includes(property)
                    );
                } else {
                    filters = [];
                }
                return util.getSignalflow(filters, jobConfig);
            }
        }
    },
];
