'use strict';

/* jshint camelcase: false */

/*
  Superpowers - Superuser app configuration

  Superpowers have configuration options only available to superusers
  (SignalFx employees). They enable app config overrides which are not
  available to regular users. Superpowers are persisted in the browsers
  local storage, and can be reset manually by executing
  "localStorage.superpowers = ''" in the console.
*/
angular.module('sfx.debug').controller('Superpowers', [
    '$scope',
    '$window',
    'CUSTOMER_SERVER_API_URL',
    'IS_PRODUCTION',
    'localStorage',
    'config',
    'currentUser',
    'PRODUCT_NAME',
    'uiFeatures',
    function (
        $scope,
        $window,
        CUSTOMER_SERVER_API_URL,
        IS_PRODUCTION,
        localStorage,
        config,
        currentUser,
        PRODUCT_NAME,
        uiFeatures
    ) {
        $scope.PRODUCT_NAME = PRODUCT_NAME;
        $scope.customerServerUrl = CUSTOMER_SERVER_API_URL;
        $scope.features = uiFeatures.getFeatures();
        $scope.isProduction = IS_PRODUCTION;

        uiFeatures.subscribeToStateChange((newState) => {
            if (newState.initialized) {
                $scope.features = newState.featuresEnabled;
                $scope.$applyAsync();
            }
        });
        $scope.setFeature = function (key, val) {
            uiFeatures.setEnabled(key, val);
        };

        $scope.temp = {
            apiEndpoint: localStorage['API_URL'],
            wsEndpoint: localStorage['SIGNALFLOW_V2_API_URL'],
            experimentalEndpoint: localStorage['EXPERIMENTAL_URL'],
        };

        $scope.resetHeatmapMode = function () {
            currentUser
                .preferences()
                .then(function (preferences) {
                    preferences.sf_navigatorMode = ''; // can't delete properties via this api
                    return currentUser.updatePreferences(preferences);
                })
                .then(function () {
                    $window.alert("You've never set a navigator mode");
                });
        };

        $scope.createTrialNew = function () {
            if ($scope.isProduction) {
                $window.alert('Trial creation via superpowers has been disabled.');
            } else {
                $window.alert('Please create the trial using sfc.');
            }
        };

        $scope.resetWhatsNew = function () {
            currentUser
                .preferences()
                .then(function (preferences) {
                    preferences.sf_showWhatsNew =
                        preferences.sf_showWhatsNew - 1000 * 60 * 60 * 24 * 7;
                    return currentUser.updatePreferences(preferences);
                })
                .then(function () {
                    $window.alert("You've erased your memory of a weeks worth of new things");
                });
        };

        $scope.$watch('temp.apiEndpoint', function (newValue) {
            if (!newValue) {
                delete localStorage['API_URL'];
            } else {
                localStorage['API_URL'] = newValue;
            }
        });

        $scope.$watch('temp.wsEndpoint', function (newValue) {
            if (!newValue) {
                delete localStorage['SIGNALFLOW_V2_API_URL'];
            } else {
                localStorage['SIGNALFLOW_V2_API_URL'] = newValue;
            }
        });

        $scope.$watch('temp.experimentalEndpoint', function (newValue) {
            if (!newValue) {
                delete localStorage['EXPERIMENTAL_URL'];
            } else {
                localStorage['EXPERIMENTAL_URL'] = newValue;
            }
        });

        currentUser
            .localStorage()
            .then(function (storage) {
                function getCurrentSuperpowers() {
                    return storage.get('superpowers') || {};
                }

                function getChangedPowers() {
                    const changed = {};
                    const currentPowers = $scope.power;
                    const oldPowers = getCurrentSuperpowers();

                    // Determine how the old powers differ from the new
                    Object.keys(oldPowers).forEach(function (name) {
                        const currentPower = currentPowers[name];
                        const oldPower = oldPowers[name];

                        if (oldPower !== currentPower) {
                            changed[name] = currentPower;
                        }
                    });

                    // Determine if any new powers have been introduced
                    Object.keys(currentPowers).forEach(function (name) {
                        // Skip powers which existed in the old powers since
                        // we already saw them in the previous block.
                        if (name in oldPowers) return;

                        const currentPower = currentPowers[name];
                        const oldPower = oldPowers[name];

                        if (oldPower !== currentPower) {
                            changed[name] = currentPower;
                        }
                    });

                    return changed;
                }

                function updateSuperpowers() {
                    // Get only the powers which have changed
                    const changed = getChangedPowers();

                    // Persist the configuration in localstorage
                    storage.set('superpowers', $scope.power);

                    // Emit configuration updates
                    Object.keys(changed).forEach(function (name) {
                        const value = $scope.power[name];
                        config('superpower.' + name, value);
                    });
                }

                $scope.power = getCurrentSuperpowers();
                $scope.$watch('power', updateSuperpowers, true);
            })
            .finally(() => {
                $scope.$applyAsync();
            });
    },
]);
