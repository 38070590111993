import { blockingLoadService } from './blockingLoadService.js';
import { asyncScriptLoader } from './asyncScriptLoader.js';
import { sfActivate } from './sfActivate';
import orgSwitchUrl from './orgSwitchUrl';
import {
    convertMSToString,
    addWordBreaks,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular
    .module('signalview.utils', ['sfUtil'])

    .directive('sfActivate', sfActivate)

    .service('blockingLoadService', blockingLoadService)
    .service('orgSwitchUrl', orgSwitchUrl)
    .service('asyncScriptLoader', asyncScriptLoader)

    // a filter to convert time-duration from ms to string
    // example, 1000 | msToStr , should yield '1s'
    .filter('msToStr', [
        function () {
            return convertMSToString;
        },
    ])
    .filter('wordBreak', [
        '$sce',
        function ($sce) {
            return function (text) {
                const wordBreakText = addWordBreaks(text);
                return $sce.trustAsHtml(wordBreakText);
            };
        },
    ]);
