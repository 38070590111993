import templateUrl from './jiraNotifyBlock.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('signalview.detector.notification').directive('jiraNotifyBlock', [
    '$timeout',
    '$log',
    'notificationsUtil',
    'jiraAssigneeTypeaheadServiceFactory',
    'PRODUCT_NAME',
    function (
        $timeout,
        $log,
        notificationsUtil,
        jiraAssigneeTypeaheadServiceFactory,
        PRODUCT_NAME
    ) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                credentials: '=credentials',
                remove: '&onRemove',
                readOnly: '=?',
                doneEdit: '&onFinishEdit',
            },
            templateUrl,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;

                const assigneeTypeaheadService =
                    jiraAssigneeTypeaheadServiceFactory.createInstance();

                $scope.ui = {
                    dropdownOpen: false,
                };
                $scope.credentialType = notificationsUtil.getIntegrationNameForV1orV2(
                    $scope.model.type
                );

                function updateAssigneeData() {
                    const assignee =
                        safeLookup($scope, 'model.assignee') ||
                        safeLookup($scope, 'credential.assignee');
                    $scope.assigneeUniqueDisplayName =
                        assigneeTypeaheadService.assigneeToUniqueDisplayName(assignee);
                    $scope.assigneeDescription = assignee
                        ? ' - assignee: ' + $scope.assigneeUniqueDisplayName
                        : '';
                }

                $scope.setCredential = function (credential) {
                    $scope.model.credentialId = credential.id;
                    $scope.model.credentialName = credential.name;
                    $scope.credential = credential;
                };

                $scope.finishEdit = function () {
                    if ($scope.model.credentialId) {
                        $scope.edit = false;
                    } else {
                        if ($scope.remove) {
                            $scope.remove();
                        }
                    }
                    if ($scope.doneEdit) $scope.doneEdit();
                };

                $scope.$watch('ui.dropdownOpen', function (newValue, oldValue) {
                    if (oldValue && !newValue) {
                        if (!$scope.model.credentialId && $scope.remove) $scope.remove();
                    }
                });

                $scope.editMode = function () {
                    $scope.edit = true;

                    $timeout(function () {
                        $scope.ui.dropdownOpen = $scope.credentials.length !== 1;
                    });
                };

                $scope.suggestAssignees = (query) => {
                    return assigneeTypeaheadService.suggestAssignees(
                        true,
                        $scope.credential,
                        query
                    );
                };

                $scope.selectAssignee = (selectedAssigneeName) => {
                    const selectedAssignee =
                        assigneeTypeaheadService.getAssignee(selectedAssigneeName);
                    $scope.model.assignee = selectedAssignee;
                    $scope.finishEdit();
                };

                $scope.clearAssignee = () => {
                    delete $scope.model.assignee;
                };

                $scope.$watch('credential', updateAssigneeData);
                $scope.$watch('model.assignee', updateAssigneeData);

                if (!$scope.model.credentialId) {
                    if ($scope.credentials.length === 1) {
                        $scope.setCredential($scope.credentials[0]);
                    }
                    $scope.editMode();
                } else {
                    $scope.credentials.some((credential) => {
                        if (credential.id === $scope.model.credentialId) {
                            $scope.setCredential(credential);
                            return true;
                        }
                    });

                    if (!$scope.credential) {
                        $log.error('Unable to find matching credential!');
                    }
                }
            },
        };
    },
]);
