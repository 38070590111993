'use strict';

angular.module('sfx.ui').provider('obfuscateSecret', function () {
    let visibleChars = 4;

    this.setVisibleChars = function (num) {
        visibleChars = num;
    };

    this.$get = function () {
        return function (secret) {
            if (secret.length <= visibleChars) return secret;

            return (
                secret.substr(0, secret.length - visibleChars).replace(/./g, '*') +
                secret.substr(secret.length - visibleChars, visibleChars)
            );
        };
    };
});
