import templateUrl from './alertsList.html';

export const alertsList = {
    bindings: {
        alerts: '<',
    },
    templateUrl,
    controller: [
        'alertingService',
        function (alertingService) {
            this.openIncidentModal = alertingService.openIncidentModal.bind(alertingService);
        },
    ],
};
