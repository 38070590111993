angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Total TCP Connections')
                .type('column')
                .forcedResolution(30000)
                .yAxis(0, { label: 'Curr conns - BLUE' })
                .yAxis(1, { label: 'New conns - RED' });

            const currentPlot = chart
                .plot()
                .metric('protocol_counter.CurrEstab')
                .color('#0077c2')
                .yAxis(0)
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asAggregation());

            // Output plot is just a clone of the previous with some modifications
            currentPlot
                .cloneTo(chart)
                .metric('protocol_counter.ActiveOpens')
                .color('#b04600')
                .yAxis(1);

            return chart;
        }

        chartTemplates.register('collectdHostsTotalTCPConnections', createChart);
    },
]);
