import DataMessage from './messages/dataMessage';
import MetadataMessage from './messages/metadataMessage';
import JobFeedbackMessage from './messages/jobFeedbackMessage';
import DatapointGenerator from './generators/datapointGenerator';
import NormalizedGenerator from './generators/normalizedGenerator';
import SinusoidalGenerator from './generators/sinusoidalGenerator';
import VolatilityGenerator from './generators/volatilityGenerator';

function getSyntheticId(uk) {
    //stub
    let value = uk;
    if (typeof value !== 'number') {
        value = parseInt(value, 10);
    }
    if ((typeof value !== 'number' && !isNaN(value)) || value < 0) {
        console.log('Invalid unique key specified.');
        return null;
    }

    let letterValue;
    const letters = [];

    while (value > 26) {
        letterValue = value % 26;
        value = Math.floor(value / 26);

        if (letterValue === 0) {
            // Since A maps to 1, Z maps to 0 in this scheme
            letterValue = 26;
            value -= 1;
        }

        letters.push(String.fromCharCode(64 + letterValue));
    }

    letterValue = value % 26;
    if (letterValue === 0) letterValue = 26;
    letters.push(String.fromCharCode(64 + letterValue));

    return letters.reverse().join('');
}

function generateRandomHandle() {
    return Array.from(Array(8), () => Math.floor(Math.random() * 36).toString(36)).join('');
}

function getDataGenerator(type = 'datapoint') {
    switch (type) {
        case 'normalized':
            return new NormalizedGenerator();
        case 'volatility':
            return new VolatilityGenerator();
        case 'sinusoidal':
            return new SinusoidalGenerator();
        case 'datapoint':
        default:
            return new DatapointGenerator(Date.now());
    }
}

const TTFI = 0;
function MockSignalFlowClient() {
    //we would normally deal with auth on initialization
}

MockSignalFlowClient.prototype.execute = function (params) {
    const valueGenerator = getDataGenerator('datapoint');
    const dims = ['dim1', 'dim2', 'dim3', 'dim4'];
    const channelName = Date.now().toString();

    //this is really basic and only works on charts with keys < J, but will suffice for testing
    const publishLabels = [];
    const publishFinder = /publish\(label='/g;
    let match = publishFinder.exec(params.program);
    while (match) {
        publishLabels.push(params.program.charAt(match.index + 15));
        match = publishFinder.exec(params.program);
    }

    const findOffsets = [];
    for (let f = 0; f < publishLabels.length; f++) {
        findOffsets.push(f * 5);
    }
    const numTimeSeriesPerPublish = 10;
    const allTimeSeries = [];
    let callback = angular.noop;
    function passMessage(msg) {
        callback(null, msg);
    }

    function passError(msg) {
        callback(msg, null);
    }

    for (let pl = 0; pl < publishLabels.length; pl++) {
        for (let n = 1; n <= numTimeSeriesPerPublish; n++) {
            allTimeSeries.push({
                id: getSyntheticId(pl + 2 * n + n),
                label: publishLabels[pl],
            });
        }
    }

    let lastDataMs = params.start;
    const resolution = params.resolution;
    const maxDelay = 0;

    function publishControlMsgs() {
        const msgs = [
            {
                channel: null,
                event: 'STREAM_START',
                timestampMs: Date.now(),
                type: 'control-message',
            },
            {
                channel: null,
                event: 'JOB_START',
                handle: generateRandomHandle(),
                timestampMs: Date.now(),
                type: 'control-message',
            },
        ];

        msgs.forEach(function (m) {
            m.channel = channelName;
            m.timestampMs = Date.now();
            passMessage(m);
        });
    }

    function publishErrorMessages() {
        const msgs = [];
        msgs.forEach(passError);
    }

    function publishMetadata() {
        let mmsg = null;
        function addKey(key, idx) {
            mmsg.addKey(key, idx === 0 ? Math.random().toString() : key + 'FakeValue');
        }
        for (let x = 0; x < allTimeSeries.length; x++) {
            mmsg = new MetadataMessage(allTimeSeries[x].id, channelName);
            dims.forEach(addKey);
            mmsg.setProperty('sf_streamLabel', allTimeSeries[x].label);
            mmsg.setProperty('sf_metric', 'FakeMetric' + Math.random());
            passMessage(mmsg);
        }
    }

    function publishJobFeedback(blockSerialOffsets) {
        const msgs = [];
        const resolutionMsg = new JobFeedbackMessage();
        resolutionMsg.setChannel(channelName);
        resolutionMsg.setTimeStamp(Date.now());
        resolutionMsg.setMessage({
            blockSerialNumbers: [],
            blockContexts: [],
            resolutionMs: resolution,
            contents: {
                resolutionMs: resolution,
            },
            messageCode: 'JOB_RUNNING_RESOLUTION',
            messageLevel: 'INFO',
            numInputTimeSeries: 0,
            timestampMs: Date.now(),
        });
        msgs.push(resolutionMsg);

        const lag = new JobFeedbackMessage();
        lag.setChannel(channelName);
        lag.setTimeStamp(Date.now());
        lag.setMessage({
            blockSerialNumbers: [],
            blockContexts: [],
            resolutionMs: resolution,
            contents: {
                maxDelayMs: 0, //maybe we should random between maxdelay and 1?
            },
            messageCode: 'JOB_INITIAL_MAX_DELAY',
            messageLevel: 'INFO',
            numInputTimeSeries: 0,
            timestampMs: Date.now(),
            tsIds: [], //wat
        });
        msgs.push(lag);

        blockSerialOffsets.forEach(function (bso) {
            const matchedDims = new JobFeedbackMessage();
            matchedDims.setChannel(channelName);
            matchedDims.setTimeStamp(Date.now());
            matchedDims.setMessage({
                contents: {
                    dimensionCounts: [
                        {
                            count: numTimeSeriesPerPublish,
                            dimensions: angular.copy(dims),
                        },
                    ],
                },
                blockSerialNumbers: [bso],
                blockContexts: [
                    {
                        line: bso,
                        column: 1,
                    },
                ],
                messageCode: 'FIND_MATCHED_DIMENSIONS',
                messageLevel: 'INFO',
                numInputTimeSeries: numTimeSeriesPerPublish,
                timestampMs: Date.now(),
                tsIds: [], //wat
            });
            msgs.push(matchedDims);

            const fetchNumTimeSeries = new JobFeedbackMessage();
            fetchNumTimeSeries.setChannel(channelName);
            fetchNumTimeSeries.setTimeStamp(Date.now());
            fetchNumTimeSeries.setMessage({
                blockSerialNumbers: [bso],
                blockContexts: [
                    {
                        line: bso,
                        column: 1,
                    },
                ],
                messageCode: 'FETCH_NUM_TIMESERIES',
                messageLevel: 'INFO',
                numInputTimeSeries: numTimeSeriesPerPublish,
                timestampMs: Date.now(),
                tsIds: [], //wat
            });
            msgs.push(fetchNumTimeSeries);

            const numOutputTimeSeries = new JobFeedbackMessage();
            numOutputTimeSeries.setChannel(channelName);
            numOutputTimeSeries.setTimeStamp(Date.now());
            numOutputTimeSeries.setMessage({
                blockSerialNumbers: [bso + 1],
                blockContexts: [
                    {
                        line: bso,
                        column: 1,
                    },
                ],
                messageCode: 'ID_NUM_TIMESERIES',
                messageLevel: 'INFO',
                numInputTimeSeries: numTimeSeriesPerPublish,
                timestampMs: Date.now(),
                tsIds: [], //wat
            });
            msgs.push(numOutputTimeSeries);
        });

        msgs.forEach(passMessage);
    }

    function checkNewDataNeeded() {
        const processTime = Date.now() - maxDelay;
        let msg = null;
        let newMsgMs = lastDataMs;
        for (let t = lastDataMs + resolution; t < processTime; t += resolution) {
            msg = new DataMessage();
            msg.setChannel(channelName);
            msg.setTimeStamp(t);
            newMsgMs = t;
            for (let x = 0; x < allTimeSeries.length; x++) {
                msg.addPoint(allTimeSeries[x].id, valueGenerator.next().value);
            }
            passMessage(msg);
        }
        lastDataMs = newMsgMs;
    }

    let dataInterval = null;
    return {
        close: function () {
            window.clearInterval(dataInterval);
        },
        stream: function (cb) {
            //this needs to be done to skip angular digest
            window.setTimeout(function () {
                window.setTimeout(function () {
                    callback = cb;
                    publishControlMsgs();
                    publishErrorMessages();
                    publishMetadata();
                    publishJobFeedback(findOffsets);
                    checkNewDataNeeded();
                    dataInterval = window.setInterval(checkNewDataNeeded, 1000);
                }, TTFI);
            }, 0);
        },
    };
};

MockSignalFlowClient.prototype.explain = function () {
    console.error('Explain mock not implemented!');
};

export default MockSignalFlowClient;
