import templateUrl from './chartDisplayDebounceOverlay.tpl.html';

export const chartDisplayDebounceOverlay = {
    templateUrl,
    bindings: {
        chartDisplayDebouncer: '<',
        onEnterDebounceArea: '&',
        onLeaveDebounceArea: '&',
        preventDataUpdates: '&',
        updateNow: '&',
    },
    controller: [
        function () {
            const $ctrl = this;
            $ctrl.$onInit = function () {
                $ctrl.updateDataNow = $ctrl.chartDisplayDebouncer.unsuspend;
                $ctrl.preventDataUpdates = $ctrl.chartDisplayDebouncer.suspend;
                $ctrl.onEnterDebounceArea = $ctrl.chartDisplayDebouncer.pause;
                $ctrl.onLeaveDebounceArea = $ctrl.chartDisplayDebouncer.unpause;
            };
        },
    ],
};
