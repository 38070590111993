export const PercentileController = [
    '$scope',
    function ($scope) {
        const $ctrl = this;
        let percentile;

        $ctrl.$onInit = () => {
            percentile = $scope.fnConfig.options.percentile;
        };

        $ctrl.$onDestroy = () => {
            if (!$scope.fnConfig.options.percentile) {
                $scope.fnConfig.options.percentile = percentile;
            }
        };
    },
];

export const DEFAULT_PERCENTILE_VALUE = 90;
