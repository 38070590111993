export const APM_FILTER = {
    service: 'sf_service',
    operation: 'sf_operation',
    environment: 'sf_environment',
    kind: 'sf_kind',
    error: 'sf_error',
    dimensionalized: 'sf_dimensionalized',
    workflow: 'sf_workflow',
    env_search_metric: 'service.request.count',
};

const APM_V1_MAPPER = {
    [APM_FILTER.service]: 'service',
    [APM_FILTER.operation]: 'operation',
    [APM_FILTER.environment]: 'cluster',
    [APM_FILTER.kind]: 'kind',
    [APM_FILTER.error]: 'error',
    [APM_FILTER.workflow]: 'workflow',
    [APM_FILTER.env_search_metric]: 'traces.count',
};

export const APMFilterService = [
    '$log',
    'filterFactory',
    'blockService',
    'DETECTOR_TYPES',
    'signalTypeService',
    function ($log, filterFactory, blockService, DETECTOR_TYPES, signalTypeService) {
        return {
            forVersion: function (apmVersion = DETECTOR_TYPES.APM) {
                return {
                    getKey,
                    generateQueryItems,
                    generateDataManipulation,
                };

                function getKey(filter) {
                    if (apmVersion === DETECTOR_TYPES.APM) {
                        return APM_V1_MAPPER[filter] || filter;
                    }
                    return filter;
                }

                function generateQueryItems({
                    environment,
                    customFilters,
                    signalTypeEntity,
                    selection,
                }) {
                    const queryItems = generateBaseQueryItems(signalTypeEntity, selection);
                    const environmentFilter = filterFactory.FILTER(
                        filterFactory.TYPES.VALUE,
                        environment,
                        false,
                        getKey(APM_FILTER.environment)
                    );
                    queryItems.push(environmentFilter);

                    if (Array.isArray(customFilters) && customFilters.length) {
                        customFilters.forEach((f) => {
                            const customFilter = filterFactory.FILTER(
                                filterFactory.TYPES.VALUE,
                                f.propertyValue,
                                f.NOT,
                                f.property
                            );
                            queryItems.push(customFilter);
                        });
                    } else {
                        const customFilterNone = filterFactory.FILTER(
                            filterFactory.TYPES.VALUE,
                            '*',
                            true,
                            getKey(APM_FILTER.dimensionalized)
                        );
                        queryItems.push(customFilterNone);
                    }

                    return queryItems;
                }

                function generateDataManipulation(type, signalTypeEntity) {
                    const analyticsFunction = blockService.getByDisplayName(type);
                    const manipulation = analyticsFunction.asAggregation();
                    const groupBy = {
                        [signalTypeService.SERVICE_ENDPOINT.name]: [
                            { value: getKey(APM_FILTER.service) },
                            { value: getKey(APM_FILTER.operation) },
                        ],
                        [signalTypeService.WORKFLOW.name]: [{ value: getKey(APM_FILTER.workflow) }],
                    };

                    manipulation.direction.options.aggregateGroupBy.push(
                        ...groupBy[signalTypeEntity.name]
                    );

                    return manipulation;
                }

                function generateBaseQueryItems(signalTypeEntity, selection) {
                    let queryItems = [
                        filterFactory.FILTER(
                            filterFactory.TYPES.VALUE,
                            ['SERVER', 'CONSUMER'],
                            false,
                            getKey(APM_FILTER.kind)
                        ),
                    ];

                    if (signalTypeEntity.name === signalTypeService.SERVICE_ENDPOINT.name) {
                        const { service, endpoints } = selection;
                        queryItems = [
                            ...queryItems,
                            filterFactory.FILTER(
                                filterFactory.TYPES.VALUE,
                                service,
                                false,
                                getKey(APM_FILTER.service)
                            ),
                            filterFactory.FILTER(
                                filterFactory.TYPES.VALUE,
                                endpoints,
                                false,
                                getKey(APM_FILTER.operation)
                            ),
                        ];
                    } else if (signalTypeEntity.name === signalTypeService.WORKFLOW.name) {
                        const { resource } = selection;
                        queryItems = [
                            filterFactory.FILTER(
                                filterFactory.TYPES.VALUE,
                                resource,
                                false,
                                getKey(APM_FILTER.workflow)
                            ),
                        ];
                    } else {
                        $log.error('Signal type not found.');
                    }

                    return queryItems;
                }
            },
        };
    },
];
