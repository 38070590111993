'use strict';
import templateUrl from './permissionList.html';

export default {
    templateUrl,
    bindings: {
        permissionList: '<',
        defaults: '<',
        editable: '<',
        onChange: '&',
    },
    controller: [
        '$scope',
        'featureEnabled',
        'teamsApiService',
        '$q',
        'organizationService',
        function ($scope, featureEnabled, teamsApiService, $q, organizationService) {
            const ctrl = this;

            let permissionList;
            // list of both User and OrgMember (formally OrgUser) objects
            let users;
            let teams;
            let teamsPromise;

            function getOrgUserDisplayObj(user) {
                const email = user.sf_email || user.email;
                const name = user.sf_user || user.fullName;
                let displayName = name;

                if (!displayName) {
                    displayName = email;
                } else {
                    displayName += ' (' + email + ')';
                }

                return {
                    displayName: displayName,
                    id: user.sf_id || user.id,
                    name: name,
                    email: email,
                    isNewAddition: true,
                    isUser: true,
                };
            }

            function addUser(user) {
                const displayObj = getOrgUserDisplayObj(user);
                ctrl.writers.push(displayObj);
                users.push(user.sf_id || user.id);
            }

            function addTeam(team) {
                ctrl.writers.push({
                    displayName: team.name,
                    id: team.id,
                    isTeam: true,
                });

                teams.push(team.id);
            }

            function addUserById(id) {
                if (users.includes(id)) return;

                organizationService.member(id).then(function (orgUser) {
                    addUser(orgUser);
                });
            }

            function addTeamById(id) {
                if (teams.includes(id)) return;

                teamsApiService.get(id).then(function (team) {
                    addTeam(team);
                });
            }

            ctrl.anyoneCanEditToggled = function () {
                if (ctrl.anyoneCanEdit) {
                    ctrl.showNewItemInput = false;
                    ctrl.writers = [];
                    users.splice(0);
                    teams.splice(0);
                } else {
                    if (ctrl.defaults) {
                        if (ctrl.defaults.users) {
                            ctrl.defaults.users.forEach((id) => addUserById(id));
                        }

                        if (ctrl.defaults.teams) {
                            ctrl.defaults.teams.forEach((id) => addTeamById(id));
                        }
                    }
                }
                onPermissionChanged();
            };

            $scope.$watch('$ctrl.toAddQuery', function () {
                updateDropdownOptions();
            });

            $scope.$on('resetPermissions', function (evt, newPermissionList) {
                ctrl.permissionList = newPermissionList;
                ctrl.$onInit();
            });

            function onPermissionChanged() {
                if (ctrl.onChange) {
                    ctrl.onChange();
                }
            }

            let updateId = 0;
            function updateDropdownOptions() {
                const toAddQuery = ctrl.toAddQuery || '';
                const currentUpdate = ++updateId;
                const escapedWildcardQuery = `*${toAddQuery.replace(/([ /])/g, '\\$1')}*`;
                const orgUserQuery = `sf_user:${escapedWildcardQuery} OR sf_email:${escapedWildcardQuery}`;

                $q.all({
                    teams: teamsPromise.then(function (teams) {
                        return teams
                            .filter(
                                (team) =>
                                    team.name.toLowerCase().indexOf(toAddQuery.toLowerCase()) !== -1
                            )
                            .map((team) => ({
                                displayName: team.name,
                                id: team.id,
                                isTeam: true,
                            }));
                    }),
                    users: organizationService
                        .searchMembersWithArbitraryQuery(orgUserQuery, 0, 1000)
                        .then(function (response) {
                            return response.results
                                .filter(function (result) {
                                    return ctrl.writers.every(function (selected) {
                                        return selected.id !== result.id;
                                    });
                                })
                                .map(getOrgUserDisplayObj);
                        }),
                }).then(function (results) {
                    if (updateId !== currentUpdate) return;
                    ctrl.dropdownOptions = results.teams
                        .concat(results.users)
                        .filter((item) =>
                            ctrl.writers.every((selected) => selected.id !== item.id)
                        );
                });
            }

            ctrl.$onInit = function () {
                permissionList = ctrl.permissionList || {};
                users = permissionList.users = permissionList.users || [];
                teams = permissionList.teams = permissionList.teams || [];

                teamsPromise = teamsApiService.get().then(function (data) {
                    return data.results;
                });

                $scope.writePermissionsEnabled = featureEnabled('writepermissions');
                ctrl.anyoneCanEdit = !(users.length || teams.length);

                ctrl.writers = [];

                teams.forEach(function (id) {
                    teamsApiService.get(id).then(function (team) {
                        ctrl.writers.push({
                            displayName: team.name,
                            id: team.id,
                            isTeam: true,
                        });
                    });
                });

                users.forEach(function (id) {
                    organizationService.member(id).then(function (orgUser) {
                        const displayObj = getOrgUserDisplayObj(orgUser);
                        ctrl.writers.push(displayObj);
                    });
                });

                ctrl.showNewItemInput = false;
            };

            ctrl.addNew = function () {
                ctrl.showNewItemInput = true;
                ctrl.toAddQuery = '';
                updateDropdownOptions();
            };

            ctrl.removeItem = function (itemToRemove) {
                ctrl.writers = ctrl.writers.filter(function (item) {
                    return item.id !== itemToRemove.id;
                });

                const target = itemToRemove.isUser ? users : teams;

                const index = target.findIndex(function (item) {
                    return item === itemToRemove.id;
                });

                if (index !== -1) {
                    target.splice(index, 1);
                }

                onPermissionChanged();
            };

            ctrl.onSelect = function (itemToAdd) {
                ctrl.writers.push(itemToAdd);

                const target = itemToAdd.isUser ? users : teams;
                target.push(itemToAdd.id);

                ctrl.showNewItemInput = false;

                onPermissionChanged();
            };
        },
    ],
};
