import { DetectorModelConversionError } from '../../../../app/detector/detectorModelConversionErrors';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { splitIntoAggregationAndTransformation } from '../../../app/charting/chartbuilder/util/histogramMetricUtility';

angular.module('signalflowv2').service('blockMapToUIModelConverter', [
    'plotUtils',
    'analyticsFunctionConverter',
    '$log',
    'v2ToPlotRollups',
    'v2ToPlotExtrapolation',
    'visualizationOptionsToUIModel',
    'filterConverter',
    'publishLabelOptionsToPlot',
    'eventPublishLabelOptionsToPlot',
    'systemMapLabelOptionsToPlot',
    'expressionTrimmer',
    'blockArgumentUtils',
    'chartDisplayUtils',
    'calendarWindowUtil',
    'blockMapToUIModelRuleConditions',
    'blockMapToUIModelFunctionsPropertiesConverter',
    'analyticsService',
    'v2DetectorAPIWrapper',
    'SIGNALFLOW_CONSTANTS',
    function (
        plotUtils,
        analyticsFunctionConverter,
        $log,
        v2ToPlotRollups,
        v2ToPlotExtrapolation,
        visualizationOptionsToUIModel,
        filterConverter,
        publishLabelOptionsToPlot,
        eventPublishLabelOptionsToPlot,
        systemMapLabelOptionsToPlot,
        expressionTrimmer,
        blockArgumentUtils,
        chartDisplayUtils,
        calendarWindowUtil,
        blockMapToUIModelRuleConditions,
        blockMapToUIModelFunctionsPropertiesConverter,
        analyticsService,
        v2DetectorAPIWrapper,
        SIGNALFLOW_CONSTANTS
    ) {
        const SKIPPABLE_ALIASED_NODE_FIELDS = [
            'metric',
            'filter',
            'rollup',
            'extrapolation',
            'maxExtrapolations',
        ];
        const CONFIG_OPTIONS_TO_COPY = ['colorOverride', 'visualization'];
        const STATEMENT_TYPE_MAP = {
            PLOT: 'plot',
            EXPRESSION: 'ratio',
            EVENT: 'event',
            DETECTOR: 'detector',
        };
        const ALLOWED_STATEMENT_TYPES = Object.keys(STATEMENT_TYPE_MAP);

        const ALLOWED_PLOT_PLOT_ARGS = [
            'metric',
            'filter',
            'rollup',
            'extrapolation',
            'maxExtrapolations',
        ];
        const ALLOWED_EVENT_PLOT_ARGS = [
            'detector_name',
            'detector_id',
            'autodetect_id',
            'eventType',
            'filter',
        ];
        const PLOT_TYPES = {
            PLOT: 'plot',
            EVENT: 'event',
            RATIO: 'ratio',
        };
        const { METRIC_TYPE } = SIGNALFLOW_CONSTANTS;

        function mergePlot(plot, allPlots) {
            const matchingPlotIndex = allPlots.findIndex((p) => {
                return plot._originalLabel && plot._originalLabel === p._originalLabel;
            });

            if (matchingPlotIndex !== -1) {
                CONFIG_OPTIONS_TO_COPY.forEach((optName) => {
                    plot.configuration[optName] =
                        allPlots[matchingPlotIndex].configuration[optName];
                });
                plot.yAxisIndex = allPlots[matchingPlotIndex].yAxisIndex;
            }

            return plot;
        }

        function applyNodeAliases(plot, start) {
            plot.configuration.aliases = {};
            angular.forEach(start.args, function (val, key) {
                if (
                    !SKIPPABLE_ALIASED_NODE_FIELDS.includes(key) &&
                    val.type === 'long' &&
                    val.value >= 0
                ) {
                    plot.configuration.aliases[val.value] = key;
                }
            });
        }

        function getPlotType(statementType) {
            return STATEMENT_TYPE_MAP[statementType] || null;
        }

        function applyFilters(start, plot) {
            if (start.args.filter) {
                plot.queryItems = filterConverter(start.args.filter);
            }
        }

        function indexLabelOptionsByLabel(v2ChartModel) {
            const plotLabelConfigs = {};
            const { publishLabelOptions, eventPublishLabelOptions, systemMapLabelOptions } =
                v2ChartModel;

            const indexer = (plotLabelConfig) => {
                plotLabelConfigs[plotLabelConfig.label] = plotLabelConfig;
            };

            if (publishLabelOptions) publishLabelOptions.forEach(indexer);
            if (eventPublishLabelOptions) eventPublishLabelOptions.forEach(indexer);
            if (systemMapLabelOptions) systemMapLabelOptions.forEach(indexer);

            return plotLabelConfigs;
        }

        function convertDetectTypeStatement(statement, signalflowSyntaxTree) {
            let rule;

            if (blockMapToUIModelRuleConditions.canParse(statement)) {
                rule = {
                    conditions: blockMapToUIModelRuleConditions.getConditions(statement),
                };

                if (blockMapToUIModelRuleConditions.isStaticThresholdRule(rule)) {
                    // static threshold rule has flattened condition
                    rule = {
                        ...rule.conditions[0],
                    };
                }
            } else if (
                blockMapToUIModelFunctionsPropertiesConverter.canParse(
                    statement,
                    signalflowSyntaxTree
                )
            ) {
                rule = blockMapToUIModelFunctionsPropertiesConverter.getRuleProperties(
                    statement,
                    signalflowSyntaxTree
                );
            } else {
                throw new DetectorModelConversionError('Unsupported V2 detector!');
            }

            if (hasAutoResolveAfter(statement)) {
                rule.autoResolveAfter = getAutoResolveAfter(statement);
            }

            const publishStreamMethod = statement.streamMethods[0];
            rule.name = publishStreamMethod.args.label.value;

            return rule;
        }

        function getAutoResolveAfter(statement) {
            return statement.start.autoResolve.value;
        }

        function hasAutoResolveAfter(statement) {
            if (statement && statement.start && statement.start.autoResolve) {
                return true;
            }

            return false;
        }

        function validatePublishLabel(statement) {
            const uniqueKeyLetter = plotUtils.getLetterFromUniqueKey(statement.uniqueKey);
            const publishMethod = statement.streamMethods.find(
                (streamMethod) => streamMethod.functionName === 'publish'
            );
            const publishLabel = safeLookup(publishMethod, 'args.label.value');
            // detectors created using rich UI have publish label corresponding to the plot's unique key
            if (uniqueKeyLetter !== publishLabel) {
                throw new DetectorModelConversionError('Unsupported V2 detector!');
            }
        }

        // For detectors, we are converting program text to UI model plots
        // to obtain rollup information. However, we only care about plots and not
        // detect or anonymous (no label) blocks. This method is used to filter out
        // DetectorPlot and AnonymousPlot statement plot types.
        // TODO: This filtering should be removed once we are able to convert
        // detect / anonymous blocks
        function convertDetectorBlockMap(statementDefinitions, v2ChartModel, detectorV2Model = {}) {
            const originalDetector = v2DetectorAPIWrapper(detectorV2Model);
            const filteredStatementDefinitions = statementDefinitions.filter((statement) => {
                return ALLOWED_STATEMENT_TYPES.includes(statement.type);
            });

            statementDefinitions
                .filter((statement) => statement.type === 'PLOT' || statement.type === 'EXPRESSION')
                .forEach((statement) => validatePublishLabel(statement));

            const extendedChartModel = angular.extend(
                {},
                v2ChartModel,
                detectorV2Model.visualizationOptions
            );
            const sf_uiModel = convertBlockMap(filteredStatementDefinitions, extendedChartModel);

            sf_uiModel.rules = statementDefinitions
                .filter((statement) => statement.type === 'DETECT')
                .map((statement) => convertDetectTypeStatement(statement, statementDefinitions));

            // use v2 model detector visOptions
            if (detectorV2Model.visualizationOptions) {
                angular.extend(sf_uiModel.chartconfig, {
                    showDots: originalDetector.getShowDataMarkers(),
                    eventLines: originalDetector.getShowEventLines(),
                    disableThrottle: originalDetector.getDisableThrottle(),
                });
            }

            return sf_uiModel;
        }

        function getManipulationsData(fixedChainables, timezone, metricType) {
            return fixedChainables.map((fixedChainable) => {
                const dataManipulation = analyticsFunctionConverter.convertAnalyticsBlock(
                    fixedChainable,
                    metricType
                );
                // Set timezone for calendar transformations from uiModel
                return setCalendarWindowTimezone(dataManipulation, timezone);
            });
        }

        function convertBlockMap(statementDefinitions, v2ChartModel) {
            let maximumUniqueKey = -1;
            const plotLabelConfigs = v2ChartModel ? indexLabelOptionsByLabel(v2ChartModel) : {};
            const uiModel = v2ChartModel ? visualizationOptionsToUIModel(v2ChartModel) : {};
            const seenLabels = {};

            const plots = statementDefinitions.map((statement) => {
                if (!ALLOWED_STATEMENT_TYPES.includes(statement.type)) {
                    $log.error(`Unrecognized statement type ${statement.type}`);
                    throw new Error('Unrecognizable expression encountered!');
                }

                const uniqueKey = statement.uniqueKey;
                const key = plotUtils.getLetterFromUniqueKey(uniqueKey);
                const plotType = getPlotType(statement.type);
                const plot = getBlankPublishLabelOptions(plotType, uniqueKey);
                const fixedChainables = statement.streamMethods;

                if (plotType === PLOT_TYPES.PLOT) {
                    convertPlotTypeStatement(plot, statement);
                } else if (plotType === PLOT_TYPES.RATIO) {
                    // signalboost adds unnecessary parens, we could consider moving the
                    // to the server but its useful on the client regardless
                    plot.expressionText = expressionTrimmer.trimParenthesesIfPossible(
                        statement.expressionText
                    );
                } else if (plotType === PLOT_TYPES.EVENT) {
                    convertEventTypeStatement(plot, statement);
                }

                const lastBlock = fixedChainables[fixedChainables.length - 1];
                let isPublished = false;

                const publishLabel = getPublishLabel(lastBlock, key);
                const hasExplicitLabel = isPublishAndHasExplicitLabel(lastBlock);

                if (seenLabels[publishLabel]) {
                    throw new Error(
                        `One or more publish functions are using the same label, ${publishLabel}. This label may have been automatically determined for publish functions without an explicit label. Ensure that the label ${publishLabel} is used only once.`
                    );
                }

                plot._originalLabel = publishLabel;
                seenLabels[publishLabel] = true;

                if (blockArgumentUtils.isPublishBlock(lastBlock)) {
                    // TODO : styling needs to be applied here, but is waiting on some code from analytics
                    const customConfig = plotLabelConfigs[publishLabel];

                    if (hasExplicitLabel && customConfig) {
                        applyCustomConfigToPlot(uiModel.chartMode, plot, customConfig);
                    }

                    // double check value's value.
                    isPublished = !blockArgumentUtils.isDisabled(lastBlock);
                    fixedChainables.splice(fixedChainables.length - 1, 1);
                }

                plot.invisible = !isPublished;

                /**
                 * @name histogram
                 * For the histogram, it'll receive the same stream method object as the analytics functions,
                 * but the aggregation and transformation are combined into the single object here.
                 * Therefore, we need to separate the stream method object, so that it can fit into the existing code of the analytics functions.
                 */
                const isHistogramMetric =
                    statement.start?.functionName?.toUpperCase() === METRIC_TYPE.HISTOGRAM;
                if (isHistogramMetric && fixedChainables.length) {
                    const histogramMethod = fixedChainables[0];
                    const aggregationTransformationMethods =
                        splitIntoAggregationAndTransformation(histogramMethod);
                    plot.histogramMethods = getManipulationsData(
                        aggregationTransformationMethods,
                        uiModel.chartconfig.timezone,
                        METRIC_TYPE.HISTOGRAM
                    );
                    plot.metricType = METRIC_TYPE.HISTOGRAM;
                    fixedChainables.shift();
                }
                plot.dataManipulations = getManipulationsData(
                    fixedChainables,
                    uiModel.chartconfig.timezone
                );

                plot.uniqueKey = uniqueKey;

                if (uniqueKey > maximumUniqueKey) {
                    maximumUniqueKey = uniqueKey;
                }

                if (!plot.name) {
                    plot.name = analyticsService.createPlotName(plot);
                }

                return mergePlot(plot, uiModel.allPlots);
            });

            uiModel.allPlots = plots;

            if (maximumUniqueKey !== -1) {
                uiModel.currentUniqueKey = maximumUniqueKey + 1;
            } else {
                $log.warn('Could not determine a current unique key.');
            }

            chartDisplayUtils.addUnresolvedYAxes(uiModel);

            return uiModel;
        }

        function applyCustomConfigToPlot(chartMode, plot, customConfig) {
            if (plot.type === PLOT_TYPES.PLOT || plot.type === PLOT_TYPES.RATIO) {
                if (chartMode === 'systemMap') {
                    systemMapLabelOptionsToPlot(plot, customConfig);
                } else {
                    publishLabelOptionsToPlot(plot, customConfig);
                }
            } else if (plot.type === PLOT_TYPES.EVENT) {
                eventPublishLabelOptionsToPlot(plot, customConfig);
            } else {
                $log.warn(
                    'Duplicate labels encountered between data publishes and event publishes!'
                );
            }
        }

        function getBlankPublishLabelOptions(plotType, uniqueKey) {
            return {
                configuration: {},
                dataManipulations: [],
                metricType: '',
                histogramMethods: [],
                invisible: false,
                name: '',
                queryItems: [],
                seriesData: {},
                transient: false,
                type: plotType,
                uniqueKey: uniqueKey,
                yAxisIndex: 0,
                expressionText: null,
            };
        }

        function getPublishLabel(lastBlock, implicitLabel) {
            if (isPublishAndHasExplicitLabel(lastBlock)) {
                return lastBlock.args.label.value;
            }

            return implicitLabel;
        }

        function isPublishAndHasExplicitLabel(lastBlock) {
            if (lastBlock && lastBlock.functionName === 'publish') {
                return lastBlock.args && !!lastBlock.args.label;
            }

            return false;
        }

        function convertPlotTypeStatement(plot, statement) {
            const start = statement.start;

            plot.seriesData.metric = statement.start.args.metric.value;

            applyFilters(start, plot);

            if (start.args.rollup) {
                plot.configuration.rollupPolicy = v2ToPlotRollups[start.args.rollup.value] || null;
            }

            if (start.args.extrapolation) {
                plot.configuration.extrapolationPolicy =
                    v2ToPlotExtrapolation[start.args.extrapolation.value] || null;
            }

            const maxExtrapolationsArg = start.args.maxExtrapolations;

            if (maxExtrapolationsArg) {
                if (maxExtrapolationsArg.hasOwnProperty('value')) {
                    plot.configuration.maxExtrapolations = parseInt(maxExtrapolationsArg.value, 10);
                } else if (
                    maxExtrapolationsArg.type === 'unary_expression' &&
                    maxExtrapolationsArg.operation === 'NEGATE' &&
                    maxExtrapolationsArg.expression &&
                    maxExtrapolationsArg.expression.type === 'long'
                ) {
                    // basically, this is checking for a -1 (infinite)
                    plot.configuration.maxExtrapolations =
                        -1 * maxExtrapolationsArg.expression.value;
                } else {
                    throw new Error(
                        'References to variables cannot be represented in Plot Builder.'
                    );
                }
            }

            if (start.functionName === 'graphite') {
                // difficult to validate due to aliasing
                plot.seriesData.regExStyle = 'graphite';
                applyNodeAliases(plot, start);
            } else if (start.functionName === 'newrelic') {
                // difficult to validate due to aliasing
                plot.seriesData.regExStyle = 'newrelic';
                applyNodeAliases(plot, start);
            } else {
                blockArgumentUtils.checkAllowedArguments(statement.start, ALLOWED_PLOT_PLOT_ARGS);
                plot.seriesData.regExStyle = null;
            }
        }

        function convertEventTypeStatement(plot, statement) {
            const start = statement.start;

            blockArgumentUtils.checkAllowedArguments(start, ALLOWED_EVENT_PLOT_ARGS);
            applyFilters(start, plot);
            const fnName = statement.start.functionName;

            if (fnName === 'events') {
                plot.seriesData.eventQuery = statement.start.args.eventType.value;
            } else if (fnName === 'alerts') {
                if (statement.start.args.detector_name) {
                    plot.seriesData.detectorQuery = statement.start.args.detector_name.value;
                } else if (statement.start.args.detector_id) {
                    plot.seriesData.detectorId = statement.start.args.detector_id.value;
                } else if (statement.start.args.autodetect_id) {
                    plot.seriesData.autodetectId = statement.start.args.autodetect_id.value;
                }
            } else {
                throw new Error(
                    `The following SignalFlow cannot be represented in Plot Builder: ${fnName}`
                );
            }
        }

        function setCalendarWindowTimezone(dataManipulation, timezone) {
            if (
                dataManipulation &&
                calendarWindowUtil.isCalendarTransformation(dataManipulation) &&
                timezone
            ) {
                dataManipulation.fn.options.timezone = timezone;
            }
            return dataManipulation;
        }

        return {
            convertBlockMap,
            convertDetectorBlockMap,
            convertDetectTypeStatement,
        };
    },
]);
