'use strict';
import groupableTable from './groupableTable/groupableTable.js';

const GROUPED_TABLE_EVENTS = {
    ROW_SELECTED: 'grouped table row selected',
    GROUP_SELECTED: 'grouped table group selected',
    DATA_SORTED: 'grouped table data sorted',
    VALUE_SELECTED: 'grouped table value selected',
};

angular
    .module('sfx.charting', ['d3'])
    .component('groupableTable', groupableTable)
    .constant('GROUPED_TABLE_EVENTS', GROUPED_TABLE_EVENTS);
