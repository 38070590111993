import templateUrl from './timeZoneSelector.tpl.html';

angular
    .module('signalview.timezone')
    .constant('AUTO_TIMEZONE', 'Auto (detect from browser)')
    .constant('UTC_TIMEZONE', 'UTC')
    .component('timeZoneSelector', {
        templateUrl,
        bindings: {
            selector: '=',
            inputStyleClass: '@',
            allowAutoTimezone: '@',
        },
        controller: [
            '$element',
            '$filter',
            '$scope',
            '$timeout',
            'moment',
            'timeZoneService',
            'AUTO_TIMEZONE',
            function ($element, $filter, $scope, $timeout, moment, timeZoneService, AUTO_TIMEZONE) {
                const ctrl = this;
                ctrl.allTimezones = null;
                ctrl.errorText = 'No matching time zone';

                ctrl.$onInit = $onInit;

                function $onInit() {
                    ctrl.allTimezones = getAllTimezones();
                    ctrl.selector.suggestions = ctrl.allTimezones;
                    ctrl.selector.onSelect = onSelectHandler;
                    ctrl.selector.onClose = onClose;
                    ctrl.selector.onSelect = onSelectHandler;
                    ctrl.selector.onShow = onShowHandler;

                    $scope.$watch('$ctrl.selector.query', updateDropdownOptions);
                }

                function onSelectHandler(timezone) {
                    onClose(timezone);
                }

                function onShowHandler() {
                    ctrl.currentTimezone = ctrl.selector.query;
                    // clear input only if user has autotimezone
                    if (ctrl.selector.query === AUTO_TIMEZONE) {
                        ctrl.selector.query = '';
                    }
                }

                function onClose(timezone) {
                    ctrl.selector.visible = false;
                    saveTimezone(timezone);
                    ctrl.selector.query = ctrl.currentTimezone;
                }

                function saveTimezone(timezone) {
                    if (timezone) {
                        const tzName = timezone.displayName;
                        const toSave = moment.tz.zone(tzName) ? tzName : '';
                        ctrl.currentTimezone = tzName;
                        // Invoke the passed in callback
                        ctrl.selector.callback(toSave);
                        // Ensure input is deselected
                        unfocusTriggerInput();
                    }
                }

                function unfocusTriggerInput() {
                    $timeout(() => {
                        $element.find('.edit-timezone-input').blur();
                    });
                }

                function getAllTimezones() {
                    let allTimezones = timeZoneService.getAllTimezones();

                    allTimezones = allTimezones.map(function (tz) {
                        const offsetFromUTC = timeZoneService.getOffsetStrFromTimeZone(tz);
                        return {
                            displayName: tz,
                            additionalInfo: offsetFromUTC,
                            additionalInfoClass: 'timezone-picker-utc-offset',
                        };
                    });

                    // Add special Auto timezone to beginning of list
                    if (ctrl.allowAutoTimezone) {
                        allTimezones.unshift({ displayName: AUTO_TIMEZONE });
                    }

                    return allTimezones;
                }

                function updateDropdownOptions() {
                    let filterBy = ctrl.selector.query;
                    if (ctrl.selector.query === AUTO_TIMEZONE) {
                        filterBy = '';
                    }
                    ctrl.selector.suggestions = $filter('filter')(ctrl.allTimezones, filterBy);
                }
            },
        ],
    });
