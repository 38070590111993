import templateUrl from './login/loginmodal.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';
import { supportService } from '@splunk/olly-services/lib';
import { loginRedirect } from '@splunk/olly-services/lib';

export default [
    '$http',
    '$q',
    '$log',
    '$location',
    '$rootScope',
    'securityRetryQueue',
    'sfxModal',
    '$timeout',
    'sessionCache',
    'auth',
    'loginService',
    'signalviewMetrics',
    'API_V2_URL',
    'SUPPORT_SERVER_API_URL',
    'ssoRedirect',
    'logService',
    function (
        $http,
        $q,
        $log,
        $location,
        $rootScope,
        securityRetryQueue,
        sfxModal,
        $timeout,
        sessioncache,
        auth,
        loginService,
        signalviewMetrics,
        API_V2_URL,
        SUPPORT_SERVER_API_URL,
        ssoRedirect,
        logService
    ) {
        // Redirect to the given url (defaults to '/')
        function redirect(url) {
            logService.logData('INFO', 'security.js ->redirect: inside redirect url function', url);

            const isActualRedirect = !!url;
            url = url || '/';
            if ($location.path() === url) {
                logService.logData(
                    'LOG',
                    'security.js -> redirect: getting url from location method',
                    url
                );
                ngRoute.reload();
            } else {
                logService.logData(
                    'LOG',
                    'security.js -> redirect: getting no url from location method',
                    url
                );
                $location.url(url);

                // Replaces history and allow user to go past the signin directly.
                if (isActualRedirect) {
                    logService.logData(
                        'LOG',
                        'security.js -> redirect: replace history and allow user to go past signin',
                        isActualRedirect
                    );
                    $location.replace();
                }
            }
            return $q.when(true);
        }

        // Login form modal stuff
        let loginDialog = null;

        function openLoginDialog() {
            if (loginDialog) {
                throw new Error('Trying to open a modal that is already open!');
            }
            const loginModalScope = angular.extend($rootScope.$new(), { isModal: true });
            loginDialog = sfxModal.open({
                templateUrl,
                controller: 'LoginController',
                scope: loginModalScope,
                backdrop: 'static',
                keyboard: false,
                windowClass: 'sf-login-modal',
            });
            loginDialog.result.then(onLoginDialogClose).finally(loginModalScope.$destroy);
        }

        function closeLoginDialog(success) {
            if (loginDialog) {
                loginDialog.close(success);
            }
        }

        function onLoginDialogClose(success) {
            loginDialog = null;
            if (success) {
                securityRetryQueue.retryAll();
            } else {
                securityRetryQueue.cancelAll();
                redirect();
            }
        }

        // for debugging
        let logdate = null;
        const logTime = function (msg) {
            if (!logdate) {
                logdate = new Date();
            }

            $log.debug(msg, ' took ' + (Date.now() - logdate.getTime()) + ' ms');
            logdate = new Date();
        };

        function postLogin(redirectTo) {
            logTime('Initializing UI');
            ssoRedirect.getAndClear(); // clear any SSO redirect
            // Delete the redirect URL from session storage. Btw, this URL is used
            // to redirect user for an authenticated link such as a 'shared dash link'
            loginRedirect.purgeRedirectURL();
            return loginService
                .initOrgAndUserPostLogin()
                .then(function ({ user, orgId }) {
                    $rootScope.$broadcast('current user changed', user);
                    logTime('UI Initialization complete');
                    signalviewMetrics.endRouteDependency('signin');
                    loginService.markSuccessfulSignUp();
                    closeLoginDialog(true);

                    if (typeof redirectTo === 'function') {
                        return redirectTo({ user, orgId });
                    } else {
                        return redirectTo;
                    }
                })
                .then(function (nextPath) {
                    if (!nextPath) {
                        return;
                    }

                    logTime('redirecting ');
                    if (nextPath.includes('mypage')) {
                        $location.url('/home');
                        return;
                    }

                    return redirect(nextPath);
                })
                .catch(function (e) {
                    $log.error('Failed post login {}', e);
                    return null;
                });
        }

        function login(params, redirectTo) {
            logTime('logging user in');
            return $http(params).then(
                () => postLogin(redirectTo).then(() => ({ success: true })),
                (response) => ({
                    success: false,
                    status: response.stats,
                    data: response.data,
                })
            );
        }

        // The public API of the service
        const service = {
            // Show the modal login modal
            showLogin: function () {
                if (!service.loginShown) {
                    openLoginDialog();
                }
            },
            sudo: function (orgId) {
                $rootScope.$broadcast('clear caches');

                return $http({
                    method: 'POST',
                    url: SUPPORT_SERVER_API_URL + '/v2/session/_/sudo',
                    data: {
                        orgId: orgId,
                    },
                }).then(function (response) {
                    if (response.data.organizationId) {
                        // this assume the access token is stored somewhere else.
                        supportService.setSupportOrg(response.data.organizationId);
                        $location.path('/home/' + response.data.organizationId);
                    }
                });
            },
            unsudo: function () {
                $rootScope.$broadcast('clear caches');

                return $http({
                    method: 'POST',
                    url: SUPPORT_SERVER_API_URL + '/v2/session/_/unsudo',
                }).then(function () {
                    supportService.removeSupportOrg();
                    $location.path('/home');
                });
            },
            googleExchange: function (code, orgId, redirect) {
                return login(
                    {
                        method: 'POST',
                        url: API_V2_URL + '/session/google/exchange',
                        data: {
                            code: code,
                            orgId: orgId,
                        },
                    },
                    redirect
                );
            },
            googleTokenValidation: function (token, redirect) {
                return login(
                    {
                        method: 'POST',
                        url: API_V2_URL + '/session/google/validate',
                        data: {
                            token: token,
                        },
                    },
                    redirect
                );
            },
            // Attempt to authenticate a user by the given email and password
            login: function (email, password, orgId, redirectTo) {
                return login(
                    {
                        method: 'POST',
                        url: API_V2_URL + '/session',
                        data: {
                            email: email,
                            password: password,
                            organizationId: orgId,
                        },
                    },
                    redirectTo
                );
            },
            // Logout the current user and redirect
            logout: function (redirectTo) {
                logService.logData(
                    'LOG',
                    'security.js -> logout: calling logout function',
                    redirectTo
                );

                const p = $q.defer();
                logService.logData(
                    'LOG',
                    'security.js -> logout: getting support org',
                    supportService.getSupportOrg()
                );
                if (supportService.getSupportOrg()) {
                    service.unsudo().then(function () {
                        p.resolve({
                            success: true,
                        });
                    });
                } else {
                    // Flush metrics.
                    auth.invokeOnWillDeAuthCallbacks();
                    $rootScope.$broadcast('clear caches');
                    $http({
                        method: 'DELETE',
                        url: API_V2_URL + '/session',
                    });
                    // clear auth after the http request
                    // has had time to pass on localStorage token
                    // to the server, otherwise we get unauthorize
                    // errors
                    $timeout(function () {
                        auth.clearAuth();
                        sessioncache.clearCache();
                        logService.logData(
                            'LOG',
                            'security.js -> logout: clearing session and auth cache',
                            redirectTo
                        );

                        if (redirectTo) {
                            redirect(redirectTo);
                        }
                        p.resolve({
                            success: true,
                        });
                    }, 500);
                }
                return p.promise;
            },
            // Give up trying to login and clear the retry queue
            cancelLogin: function () {
                closeLoginDialog(false);
                redirect();
            },
            closeLoginDialog,
        };

        return service;
    },
];
