import PropTypes from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { CrossLinkReact } from '../../crossLinks/crossLinkReact';

const className = new BEMHelper('dimensions-list');

export default function DimensionsList({ dimensions }) {
    const context = {};

    for (const dimension of dimensions) {
        context[dimension.propertyName] = dimension.propertyValue;
    }

    function DimensionItem(dimension, index) {
        return (
            <li key={index} {...className('item')}>
                <a className="chart-information__link" href={dimension.url}>
                    {dimension.propertyName} : {dimension.propertyValue}
                </a>
                <CrossLinkReact incomingDimensions={dimension} context={context} />
            </li>
        );
    }

    return (
        <ul {...className()}>
            {dimensions.map((dimension, index) => DimensionItem(dimension, index))}
        </ul>
    );
}

DimensionsList.propTypes = {
    dimensions: PropTypes.arrayOf(Object),
};
