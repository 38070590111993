export const NO_PERMISSIONS_TOOLTIP = 'You do not have permissions to edit this detector.';

export default [
    '_',
    'orgMembershipService',
    'currentUser',
    '$q',
    'dashboardGroupService',
    'featureEnabled',
    function (_, orgMembershipService, currentUser, $q, dashboardGroupService, featureEnabled) {
        const api = {};

        function getTeamsAndOrgUser() {
            return $q.all({
                // eat failure here; support server users will fail to retrieve teams
                teams: orgMembershipService
                    .getTeams()
                    .then((results) => results.data)
                    .catch(() => []),
                // eat failure here; support server users have no orgUser
                orgUserId: currentUser
                    .getOrgMember()
                    .then((profile) => profile.id)
                    .catch(angular.noop),
            });
        }

        function hasPermissions(permissionsObject) {
            return hasPermissionsBulk([permissionsObject]).then(([obj]) => obj);
        }

        function hasPermissionsBulk(permissionsObjectList) {
            let teamAndOrgPromise;

            return $q.all(
                permissionsObjectList.map((permissionsObject) => {
                    if (!permissionsObject) return $q.when(true);

                    const permissionedTeams = permissionsObject.teams || [];
                    const permissionedUsers = permissionsObject.users || [];

                    if (permissionedUsers.length === 0 && permissionedTeams.length === 0) {
                        return $q.when(true);
                    }

                    if (!teamAndOrgPromise) {
                        teamAndOrgPromise = getTeamsAndOrgUser();
                    }
                    return teamAndOrgPromise.then(({ teams, orgUserId }) => {
                        const isUserPermissioned = permissionedUsers.includes(orgUserId);
                        const isTeamPermissioned = permissionedTeams.some((t) => teams.includes(t));

                        return isUserPermissioned || isTeamPermissioned;
                    });
                })
            );
        }

        api.hasWritePermissionsBulk = function (objList) {
            const authorizedWritersList = objList.map(getAuthorizedWriters);
            return hasPermissionsBulk(authorizedWritersList);
        };

        function getAuthorizedWriters(obj) {
            let authorizedWriters;

            if (obj) {
                if (obj.sf_type) {
                    authorizedWriters = {
                        users: obj.sf_authorizedUserWriters,
                        teams: obj.sf_authorizedTeamWriters,
                    };
                } else {
                    authorizedWriters = obj.authorizedWriters;
                }
            }
            return authorizedWriters;
        }

        api.hasWritePermissions = function (obj) {
            const authorizedWriters = getAuthorizedWriters(obj);
            return hasPermissions(authorizedWriters);
        };

        api.getWriters = function (obj) {
            let writers = {};

            if (obj.sf_uiModel || obj.sf_type) {
                // v1
                writers = {
                    users: angular.copy(obj.sf_authorizedUserWriters),
                    teams: angular.copy(obj.sf_authorizedTeamWriters),
                };
            } else {
                // v2
                writers = angular.copy(obj.authorizedWriters) || {};
            }

            return writers;
        };

        api.setWriters = function (obj, writers) {
            if (obj.sf_uiModel || obj.sf_type) {
                // v1
                obj.sf_authorizedUserWriters = writers.users;
                obj.sf_authorizedTeamWriters = writers.teams;
            } else {
                // v2
                obj.authorizedWriters = writers;
            }
        };

        api.extendDashboardGroupsWithWritePermissions = (dashboardGroups) => {
            return api
                .hasWritePermissionsBulk(dashboardGroups)
                .then((writePermissionFlags) => _.zip(dashboardGroups, writePermissionFlags))
                .then((zipped) =>
                    zipped.map(([group, hasWritePermission]) => ({ hasWritePermission, ...group }))
                );
        };

        api.getHierarchyWritePermissions = function (dashboard, group) {
            if (!featureEnabled('writepermissions')) {
                return $q.all({
                    hasGroupWritePermission: true,
                    hasGroupReadPermission: true,
                    hasWritePermission: true,
                    canBeMirrored: true,
                    canRemoveMirrors: true,
                    // under writepermissions system READ access can't be limited, so it's always true
                    canViewSibling: () => true,
                });
            }

            if (!group) {
                return $q.all({
                    hasGroupWritePermission: true,
                    hasGroupReadPermission: true,
                    hasWritePermission: api.hasWritePermissions(dashboard),
                    canBeMirrored: true,
                    canRemoveMirrors: true,
                    // as in other cases of canViewSibling, we want to consistently return false when an id is not a sibling
                    // when the group is missing, the dashboard itself is its only sibling
                    canViewSibling: (id) => id === extractIdFromMetabaseObject(dashboard),
                });
            }

            // this handles a case when after deleting the last dashboard within a group
            // we're left with an empty group
            if (!dashboard) {
                return $q.all({
                    hasGroupWritePermission: api.hasWritePermissions(group),
                    hasGroupReadPermission: true,
                    hasWritePermission: undefined,
                    canBeMirrored: undefined,
                    canRemoveMirrors: undefined,
                    // although user always has READ access in writepermissions world, we return false when an object doesn't exist
                    canViewSibling: (id) => group.dashboards.includes(id),
                });
            }

            return (
                dashboardGroupService
                    .getDashboards(group.id || group.sf_id)
                    .then((result) => result.dashboards)

                    // swap one of fetched dashboards with our preloaded dashboard
                    // original commit which added this was:
                    // https://github.com/signalfx/signalview/commit/9fe5b83a2161ff4e205f0c4cb275a4f8ae279fc0
                    .then((dashboards) => [
                        ...dashboards.filter((result) => result.id !== dashboard.id),
                        dashboard,
                    ])

                    .then((dashboards) =>
                        $q.all({
                            hasGroupWritePermission: api.hasWritePermissions(group),
                            hasGroupReadPermission: true,
                            hasWritePermission: api.hasWritePermissions(dashboard),
                            canBeMirrored: true,
                            canRemoveMirrors: true,
                            canEditAllSiblingDashboards: $q
                                .all(dashboards.map(api.hasWritePermissions))
                                .then((flags) => flags.every(_.identity)),
                            // although user always has READ access in writepermissions world, we return false when an object doesn't exist
                            canViewSibling: (id) =>
                                dashboards.some(
                                    (dashboard) => extractIdFromMetabaseObject(dashboard) === id
                                ),
                        })
                    )
            );
        };

        return api;
    },
];

function extractIdFromMetabaseObject(obj) {
    return obj?.id || obj?.sf_id;
}
