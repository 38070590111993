import templateUrl from './ruleConditionModal.tpl.html';
import {
    convertMSToString,
    convertStringToMS,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular
    .module('signalview.detector.rule')
    .directive('ruleConditionModal', function () {
        return {
            restrict: 'E',
            scope: {
                detector: '=',
                plots: '=',
                numPlots: '=',
                getSignalResolutionPromise: '=',
            },
            templateUrl,
            controller: 'RuleConditionController',
        };
    })
    .controller('RuleConditionController', [
        '$log',
        '$q',
        '$scope',
        '$timeout',
        'chartbuilderUtil',
        'detectorUtils',
        function ($log, $q, $scope, $timeout, chartbuilderUtil, detectorUtils) {
            // fields on rule object that is supposed to be carried over regardless of whatever is selected;
            const RULE_FIELDS = [
                'edit',
                'invalid',
                'name',
                'notifications',
                'severityLevel',
                'uniqueKey',
            ];

            function getPlotLetter(plot) {
                return chartbuilderUtil.getLetterFromUniqueKey(plot.uniqueKey);
            }

            function updateSelection(initial) {
                if (typeof $scope.selection.category !== 'undefined') {
                    $scope.categoryShown = $scope.selection.category;
                }
                if (typeof $scope.selection.func !== 'undefined') {
                    selectFunc(initial);
                }
            }

            $scope.preSelectedSignal = null;

            $scope.categoryMouseEnter = function (index) {
                if (
                    typeof $scope.selection.category === 'undefined' &&
                    $scope.categoryShown !== index
                ) {
                    $scope.categoryShown = index;
                    selectFunc();
                    formatReadableRuleString();
                }
            };

            $scope.categoryClick = function (index, $event) {
                if ($scope.selection.category !== index) {
                    $event.stopPropagation();
                    $scope.selection = {
                        category: index,
                        func: 0,
                    };
                    updateSelection();
                    setWatchOnRule();
                } else {
                    clearWatchOnRule();
                }
            };

            function populateScaledResolution() {
                // populate a temporary assoc array of parameter name to preferred scaled resolutions
                // keep scaling semantics in here alone
                const jobResolution = $scope.jobInfo.resolution;
                if (!$scope.selectedFunc || !$scope.selectedFunc.sensitivity) {
                    return;
                }
                $scope.selectedFunc.sensitivity.options.forEach(function (sen) {
                    if (sen.resolutionConstraint) {
                        angular.forEach(sen.resolutionConstraint, function (value, key) {
                            const multiple = value.minDefaultMultiple;
                            const scaledResolution = jobResolution * multiple;
                            const objValue = sen.inputs[key];
                            const isDurationProperty = objValue.hasOwnProperty('duration');
                            const objMsValue = convertStringToMS(
                                isDurationProperty ? objValue.duration : objValue
                            );
                            const preferredMsValue = Math.max(objMsValue, scaledResolution);
                            const preferredValueString = convertMSToString(preferredMsValue);
                            if (!sen.scaledResolution) {
                                sen.scaledResolution = {};
                            }
                            sen.scaledResolution[key] = angular.copy(sen.inputs[key]);
                            if (isDurationProperty) {
                                sen.scaledResolution[key].duration = preferredValueString;
                            } else {
                                sen.scaledResolution[key] = preferredValueString;
                            }
                        });
                    }
                });
            }

            function setDurations() {
                if (!$scope.selectedFunc) {
                    return;
                }
                populateScaledResolution();
                if (!$scope.sensitivityModel) {
                    let currentMatch = null;
                    if ($scope.selectedFunc.sensitivity && $scope.rule) {
                        $scope.selectedFunc.sensitivity.options.some(function (sen) {
                            if (
                                isMatch(
                                    $scope.rule.inputs,
                                    sen.inputs,
                                    sen.scaledResolution || {},
                                    sen.resolutionConstraint || {}
                                )
                            ) {
                                currentMatch = sen.name;
                            } else {
                                return false;
                            }
                        });
                    }
                    if (currentMatch) {
                        setSensitivityModelName(currentMatch);
                    }
                }
                if ($scope.sensitivityModel && $scope.selectedFunc.sensitivity) {
                    $scope.sensitivityChanges();
                }
            }

            function setSensitivityModelName(name) {
                $scope.sensitivityModel = { value: name };
                if (name === 'custom') {
                    $scope.expand = true;
                }
            }

            function assignSensitivityModel() {
                if (
                    ($scope.sensitivityModel && $scope.sensitivityModel.value === 'custom') ||
                    !$scope.jobInfo
                ) {
                    return;
                }
                if ($scope.selectedFunc && $scope.selectedFunc.sensitivity && $scope.rule) {
                    if (
                        !$scope.selectedFunc.sensitivity.options.some(function (sen) {
                            if (
                                isMatch(
                                    $scope.rule.inputs,
                                    sen.inputs,
                                    sen.scaledResolution || {},
                                    sen.resolutionConstraint || {}
                                )
                            ) {
                                setSensitivityModelName(sen.name);
                                return true;
                            } else {
                                return false;
                            }
                        })
                    ) {
                        setSensitivityModelName('custom');
                    }
                }
            }

            $scope.categoryContainerClicked = function () {
                // if container is clicked, clear all the selection
                $scope.selection = {};
                $scope.categoryShown = null;
                $scope.selectedFunc = null;

                $scope.rule = {};
                clearWatchOnRule();
            };

            function getFirstVisible(plots) {
                let firstVisible = null;
                if (
                    plots.some(function (plot) {
                        if (!plot.transient && !plot.invisible && plot.type !== 'event') {
                            firstVisible = plot;
                            return true;
                        } else {
                            return false;
                        }
                    })
                ) {
                    return firstVisible;
                } else {
                    return plots[0];
                }
            }

            let inputValid = {};
            const inputCache = {}; // input cache to quickly restore the inputs when they switch back to it
            function selectFunc(initial) {
                const oldSelection = $scope.selectedFunc || {};
                const oldModule = oldSelection.module || {};
                const oldFunc = oldSelection.function;
                const oldModulePath = (oldModule.path || '') + ' ' + (oldModule.name || '');
                let oldFuncPath;
                if (oldFunc) {
                    oldFuncPath = oldModulePath + ' ' + oldFunc.name;
                }
                $scope.selectedFunc = angular.copy(
                    $scope.categories[$scope.categoryShown].functions[$scope.selection.func || 0]
                );
                const newModule = ($scope.selectedFunc || {}).module || {};
                const newModulePath = (newModule.path || '') + ' ' + (newModule.name || '');
                let newFuncPath;
                if ($scope.selectedFunc.function) {
                    newFuncPath = newModulePath + ' ' + $scope.selectedFunc.function.name;
                }
                const isSameModulePath = oldModulePath === newModulePath;
                const isSameFunction =
                    isSameModulePath && angular.equals(oldFunc, $scope.selectedFunc.function);
                const inputs = $scope.selectedFunc.inputs;
                if (inputs) {
                    $scope.selectedFunc.sortedInputKeys = Object.keys(inputs).sort(function (a, b) {
                        const valA = inputs[a];
                        const valB = inputs[b];
                        const priorityA = angular.isDefined(valA.priority)
                            ? valA.priority
                            : Number.MAX_SAFE_INTEGER;
                        const priorityB = angular.isDefined(valB.priority)
                            ? valB.priority
                            : Number.MAX_SAFE_INTEGER;
                        return priorityA - priorityB;
                    });
                    angular.forEach(inputs, function (val) {
                        if (val.dataType.type === 'Stream') {
                            // assign data type values for all plots to stream input
                            val.dataType.values = PLOT_OPTIONS;
                        }
                    });
                }
                if (oldFuncPath) {
                    inputCache[oldFuncPath] = angular.copy($scope.rule);
                }

                if (!isSameModulePath) {
                    $scope.expand = false;
                    $scope.sensitivityModel = {};
                }

                if (!initial && $scope.selectedFunc) {
                    // if it's not initial selection meaning user has select a new function,
                    // initialize it with default value.
                    if (
                        $scope.selectedFunc.function.type === 'static' ||
                        $scope.selectedFunc.function.type === 'dynamic'
                    ) {
                        if (!$scope.rule.thresholdMode) {
                            // if current rule doesn't have threshold mode, meaning it's empty or a function, the overwrite it
                            $scope.rule = {
                                // use pre-selected signal if available, instead of default first visible signal
                                targetPlot:
                                    $scope.preSelectedSignal ||
                                    ($scope.plots.length
                                        ? getPlotLetter(getFirstVisible($scope.plots))
                                        : null),
                                triggerMode: 'immediately',
                                jobResolution: '1 second',
                                thresholdMode: 'above',
                                showThreshold: true,
                            };

                            $scope.isValid = false;
                        } else if (
                            $scope.selectedFunc.function.type === 'static' &&
                            detectorUtils.hasDynamicThreshold($scope.rule)
                        ) {
                            delete $scope.rule.above;
                            delete $scope.rule.below;
                            $scope.isValid = false;
                        }
                    } else {
                        let cachedValues = {};
                        if (isSameModulePath) {
                            if ($scope.sensitivityModel.value === 'custom') {
                                cachedValues = angular.copy($scope.rule.inputs);
                            } else if ($scope.selectedFunc.sensitivity) {
                                // not all functions have sensitivities
                                $scope.selectedFunc.sensitivity.options.some(function (sen) {
                                    if (sen.name === $scope.sensitivityModel.value) {
                                        angular.extend(cachedValues, sen.inputs);
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                            }
                        } else if (newFuncPath && inputCache[newFuncPath]) {
                            cachedValues = inputCache[newFuncPath].inputs;
                        }
                        $scope.rule = {
                            package: $scope.selectedFunc.package,
                            path: $scope.categories[$scope.categoryShown].path.name,
                            module: $scope.selectedFunc.module.name,
                            function: $scope.selectedFunc.function.name,
                            version: $scope.selectedFunc.version,
                            inputs: {},
                            type: 'Function',
                            showThreshold: $scope.selectedFunc.showThreshold,
                        };

                        if (!isSameFunction) {
                            inputValid = {};
                        }
                        angular.forEach($scope.selectedFunc.inputs, function (val, key) {
                            const isStream = val.dataType.type === 'Stream';
                            // use pre-selected signal if available, instead of default first visible signal or cached value
                            if ($scope.preSelectedSignal && isStream) {
                                $scope.rule.inputs[key] = $scope.preSelectedSignal;
                                inputValid[key] = true;
                            } else {
                                if (angular.isDefined(cachedValues[key])) {
                                    $scope.rule.inputs[key] = cachedValues[key];
                                } else if (val.dataType.type === 'Stream') {
                                    $scope.rule.inputs[key] = PLOT_OPTIONS.length
                                        ? getFirstVisible(PLOT_OPTIONS).label
                                        : null;
                                } else {
                                    $scope.rule.inputs[key] = val.defaultValue;
                                }
                            }
                        });
                    }

                    // copy over whatever original fields that should be on the rule
                    angular.extend($scope.rule, originalFields);
                }

                if ($scope.selectedFunc) {
                    // function selector
                    $scope.functionSelector = {
                        dataType: {
                            type: 'String',
                            values: $scope.categories[$scope.categoryShown].functions.map(function (
                                f
                            ) {
                                return {
                                    displayName: f.function.displayName,
                                    value: f.function.name,
                                };
                            }),
                        },
                        defaultValue: $scope.selectedFunc.function.name,
                    };

                    // copy description, displayName, prompt, aboveTheFold, dataType, etc.
                    const tooltip = $scope.categories[$scope.categoryShown].tooltip || {};
                    $scope.functionSelector.description = tooltip.description;
                    $scope.functionSelector.displayName = tooltip.displayName;
                    $scope.functionSelector.aboveTheFold = tooltip.aboveTheFold;
                    $scope.functionSelector.prompt = tooltip.prompt;
                    if (tooltip.dataType && tooltip.dataType.select) {
                        $scope.functionSelector.dataType.select = tooltip.dataType.select;
                    }
                }

                $scope.hasMoreOptions = false;
                if ($scope.selectedFunc && $scope.selectedFunc.inputs) {
                    if (initial) {
                        // assume that all inputs are valid if opening the modal from selected input
                        angular.forEach($scope.selectedFunc.inputs, function (val, key) {
                            inputValid[key] = true;
                        });
                    }
                    angular.forEach($scope.selectedFunc.inputs, function (val) {
                        if (val.aboveTheFold === false) {
                            $scope.hasMoreOptions = true;
                        }
                    });

                    // update validity since we might be coming here from a hover to selection state
                    // and inputs will be the same and won't retrigger input validation
                    // if we have a pre-selected signal, don't wait for metadata based input to set value since it may
                    // not be active in the view
                    if (isSameFunction || $scope.preSelectedSignal) {
                        updateValidity();
                        setJobInfo();
                    }
                }
            }

            $scope.updateFunctionSelector = function (value) {
                if (
                    typeof $scope.categoryShown !== 'undefined' &&
                    typeof $scope.selection.category === 'undefined'
                ) {
                    // hover mode, ignore
                    return;
                }
                const functions = $scope.categories[$scope.categoryShown].functions;
                for (let i = 0; i < functions.length; i++) {
                    if (functions[i].function.name === value) {
                        if ($scope.selection.func !== i) {
                            // could already set by clicking on the category
                            $scope.selection.func = i;
                            selectFunc();
                            setWatchOnRule();
                        }
                        return;
                    }
                }
            };

            $scope.functionClick = function (categoryIndex, functionIndex) {
                if (
                    $scope.selection.category === categoryIndex &&
                    $scope.selection.func === functionIndex
                ) {
                    return;
                }
                $scope.selection = {
                    category: categoryIndex,
                    func: functionIndex,
                };
                selectFunc();
            };

            let PLOT_OPTIONS;

            $scope.setPlotOptions = function () {
                PLOT_OPTIONS = $scope.plots
                    .filter(function (plot) {
                        return !plot.transient && plot.type !== 'event';
                    })
                    .map(function (plot) {
                        const plotLetter = getPlotLetter(plot);
                        return {
                            label: plotLetter,
                            name: plot.name,
                            type: plot.type,
                            invisible: plot.invisible,
                        };
                    });
            };

            function init() {
                $scope.selectedFunc = null;
                // assume that when modal open, plots don't change.
                // That may no longer be true if we allow changing plots while keeping the modal open.
                $scope.setPlotOptions();
                $scope.categories = detectorUtils.getCategoriesByType();

                // we need to store index of dynamic threshold function in a variable to check against in the markup
                // the code below is meant to handle any functions order so it gets the right index
                $scope.categories.some(function (category, index) {
                    if (
                        (category.functions || []).some(function (fn) {
                            return (fn.function || {}).type === 'dynamic';
                        })
                    ) {
                        $scope.legacyDynamicCategory = index;
                        return true;
                    } else {
                        return false;
                    }
                });

                // determine what is currently selected
                // assuming that they're all using signalfx right now
                $scope.selection = {};
                if ($scope.rule.type === 'Function') {
                    const currentPath = $scope.rule.path;
                    const currentFunction = $scope.rule['function'];

                    $scope.categories.some(function (category, index) {
                        if (category.path.name === currentPath) {
                            $scope.selection.category = index;
                            return category.functions.some(function (func, index) {
                                if (func.function.name === currentFunction) {
                                    $scope.selection.func = index;
                                    return true;
                                } else {
                                    return false;
                                }
                            });
                        }
                        return false;
                    });
                    updateSelection(true);
                } else if ($scope.rule.thresholdMode) {
                    if (detectorUtils.hasDynamicThreshold($scope.rule)) {
                        // if there's any signal as threshold
                        // assume dynamic is the last category
                        $scope.selection = {
                            category: $scope.categories.length - 1,
                            func: 0,
                        };
                    } else {
                        // assume static is the first one
                        $scope.selection = {
                            category: 0,
                            func: 0,
                        };
                    }
                    updateSelection(true);
                }
                $scope.categoryShown = $scope.selection.category || 0;
            }

            let originalFields = {};
            $scope.initializeWithRule = function (rule, preSelectedSignal) {
                $scope.open = true;
                originalFields = {};
                RULE_FIELDS.forEach(function (key) {
                    originalFields[key] = rule[key];
                });
                $scope.rule = rule;
                $scope.preSelectedSignal = preSelectedSignal;
                init();
                setWatchOnRule();
            };

            $scope.$on('open rule condition', function (ev, rule) {
                $scope.initializeWithRule(rule);
            });

            function setJobInfo(prop, value) {
                if (!prop) {
                    const inputs = ($scope.selectedFunc || {}).inputs || {};
                    prop = Object.keys(inputs).filter(function (field) {
                        const val = inputs[field];
                        return val && val.dataType && val.dataType.type === 'Stream';
                    })[0];
                    if (prop && $scope.rule.inputs && $scope.rule.inputs[prop]) {
                        value = $scope.rule.inputs[prop];
                    } else {
                        return;
                    }
                }
                $scope.estimatingResolution = true;
                getJobResolutionForPlot(value)
                    .then(function (response) {
                        if (!$scope.jobInfo) {
                            $scope.jobInfo = response;
                        } else {
                            delete $scope.jobInfo.success;
                            angular.extend($scope.jobInfo, response);
                        }
                        $scope.jobInfo.lastPlot = value;
                        // adjust durations to match resolution, as needed
                        setDurations();
                        assignSensitivityModel();
                    })
                    .finally(function () {
                        $scope.estimatingResolution = false;
                    });
            }

            let functionInputValidationTimeout = null;
            let functionInputValidationRequestCount = 0;
            function updateValidity() {
                $timeout.cancel(functionInputValidationTimeout);
                $scope.functionInputError = null;
                $scope.isValid = false;
                const newValid = Object.keys($scope.selectedFunc.inputs).every(function (field) {
                    return inputValid[field];
                });
                if (newValid) {
                    if (
                        !$scope.rule ||
                        $scope.rule.type !== 'Function' ||
                        angular.isUndefined($scope.selection.category) ||
                        !$scope.jobInfo
                    ) {
                        $scope.isValid = true;
                        return;
                    }
                    functionInputValidationTimeout = $timeout(function () {
                        functionInputValidationRequestCount++;
                        const currentRequest = functionInputValidationRequestCount;
                        const rule = angular.copy($scope.rule);
                        // at the time of creation or save, could have been invalid
                        // mark it as not invalid for validation
                        // also set dummy rule name so there's a detect block
                        rule.invalid = false;
                        if (!rule.name) {
                            rule.name = 'Rule at ' + Date.now();
                        }
                        detectorUtils
                            .validateFunctionInput($scope.plots, rule, $scope.selectedFunc.inputs)
                            .then(
                                function () {
                                    if (currentRequest === functionInputValidationRequestCount) {
                                        $scope.isValid = true;
                                    }
                                },
                                function (e) {
                                    if (
                                        currentRequest === functionInputValidationRequestCount &&
                                        (e.error || (e.keys && e.keys.length))
                                    ) {
                                        $scope.functionInputError = e;
                                        $log.error('Failed function validation.', e);
                                    }
                                }
                            );
                    }, 500);
                }
            }

            // In UX where signal is pre-selected before alert settings,
            // we want to capture the selected signal and find the job resolution
            // in some cases the rule stream needs to be set as well because the settings view may not be active
            // to capture change
            $scope.setPreSelectedSignal = function (plotLetter) {
                $scope.preSelectedSignal = plotLetter;
                if ($scope.rule) {
                    if ($scope.rule.inputs) {
                        const streamName = detectorUtils.getFunctionStreamName($scope.rule);
                        if (streamName) {
                            $scope.rule.inputs[streamName] = plotLetter;
                        }
                    } else if ($scope.rule.targetPlot) {
                        $scope.rule.targetPlot = plotLetter;
                    }
                }
                setJobInfo();
            };

            $scope.updateFuncInputValue = function (prop, value, valid) {
                $scope.rule.inputs[prop] = value;
                inputValid[prop] = valid;

                // validate it right away
                updateValidity();

                if ($scope.selectedFunc.inputs[prop].dataType.type === 'Stream') {
                    setJobInfo(prop, value);
                }

                setWatchOnRule();
            };

            $scope.signalResolution = function (plot) {
                return $scope
                    .getSignalResolutionPromise(plot)
                    .then(function (response) {
                        $log.info('Got resolution for plot ', plot, response);
                        const jobInfo = angular.copy(response);
                        jobInfo.success = true;
                        return jobInfo;
                    })
                    .catch(function (e) {
                        $log.error(
                            'Failed fetching resolution for plot ' +
                                plot +
                                ', defaulting to 1 second ',
                            e
                        );
                        return { resolution: 1000 };
                    });
            };

            function getJobResolutionForPlot(plot) {
                if (angular.isUndefined($scope.selection.category)) {
                    return $q.when({ resolution: 1000 });
                } else {
                    // estimate for plot
                    return $scope.signalResolution(plot);
                }
            }

            function isMatch(object, attrs, scaledResolution, resolutionConstraint) {
                const keys = Object.keys(attrs);
                const length = keys.length;
                if (!object) return !length;
                const obj = Object(object);
                for (let i = 0; i < length; i++) {
                    const key = keys[i];
                    let attrValueEquals = angular.equals(attrs[key], obj[key]);
                    if (!attrValueEquals && resolutionConstraint[key] && scaledResolution[key]) {
                        attrValueEquals = angular.equals(scaledResolution[key], obj[key]);
                    }
                    if (!attrValueEquals) return false;
                }
                return true;
            }

            $scope.toggleExpand = function () {
                $scope.expand = !$scope.expand;
            };

            $scope.functionTrackBy = function (func, prop) {
                return func.function.name + '.' + prop;
            };

            let publishPreviewDebounce;
            function publishPreview(immediate) {
                $timeout.cancel(publishPreviewDebounce);
                publishPreviewDebounce = $timeout(
                    function () {
                        if ($scope.open && $scope.isValid) {
                            $scope.$emit('rule publish preview', $scope.rule, true);
                        }
                    },
                    immediate ? 0 : 500
                );
            }

            let timeoutVar = null;
            function needsPreflight(nval, oval) {
                if (angular.equals(nval, oval) || !angular.isDefined(nval)) {
                    return;
                }
                if (angular.isUndefined($scope.selection.category) || $scope.estimatingResolution) {
                    // we don't have the job info yet for a function, or we are in hover mode
                    formatReadableRuleString();
                    return;
                }
                // we need to time this out because input validity is set as a result
                // of input validation callbacks - controlled by the directive link
                // the rule watch is triggered sooner than input validity setting - ending up
                // previewing based on last validity status
                $timeout.cancel(timeoutVar);
                timeoutVar = $timeout(function () {
                    timeoutVar = null;
                    if (!$scope.rule) {
                        $scope.isValid = false;
                    } else if ($scope.rule.type !== 'Function') {
                        $scope.isValid =
                            detectorUtils.validateLegacyCondition($scope.rule, $scope.plots) ===
                            undefined;
                    }
                    $scope.readable = null;
                    if ($scope.open) {
                        if ($scope.isValid) {
                            // publish immediately if rule is set or removed
                            publishPreview(!oval || !nval);
                        }
                        formatReadableRuleString();
                    }
                }, 0);
            }

            // picking a new plot may produce the same resolution, we keep track of lastPlot stored in the jobInfo
            // we can get this same info from the scope.rule change but at that point the resolution may not be ready yet
            $scope.$watchGroup(
                ['selection.category', 'jobInfo.lastPlot', 'open', 'isValid'],
                needsPreflight
            );

            // we need to watch on both rule and category index
            // because rule won't trigger when you 'select' and then 'deselect' the same category,
            // no change in rule data
            let watchOnRule = null;

            function setWatchOnRule() {
                if (!watchOnRule) {
                    if (typeof $scope.selection.category !== 'undefined') {
                        watchOnRule = $scope.$watch('rule', needsPreflight, true);
                    }
                }
            }

            function clearWatchOnRule() {
                if (watchOnRule) {
                    watchOnRule();
                    watchOnRule = null;
                }
            }

            $scope.$watch('rule.inputs', assignSensitivityModel, true);

            function formatReadableRuleString() {
                if (!$scope.rule) {
                    // for new undefined rule that's being closed
                    return;
                }
                if ($scope.rule.type !== 'Function') {
                    $scope.readable = detectorUtils.getAutoDetectorRuleDescription(
                        $scope.rule,
                        $scope.plots,
                        true
                    );
                } else if ($scope.rule.type === 'Function') {
                    $scope.readable = detectorUtils.getFunctionRuleSummary(
                        $scope.rule,
                        $scope.plots
                    );
                }
            }
            $scope.formatReadableRuleString = formatReadableRuleString;

            $scope.sensitivityChanges = function () {
                $scope.expand = $scope.expand || $scope.sensitivityModel.value === 'custom';

                $scope.selectedFunc.sensitivity.options.some(function (sen) {
                    if (sen.name === $scope.sensitivityModel.value) {
                        angular.extend($scope.rule.inputs, sen.inputs);
                        angular.extend($scope.rule.inputs, sen.scaledResolution || {});
                        return true;
                    } else {
                        return false;
                    }
                });
            };

            $scope.save = function () {
                clearWatchOnRule();
                $scope.open = false;
                $scope.rule.readable = detectorUtils.getAutoDetectorRuleDescription(
                    $scope.rule,
                    $scope.plots,
                    true
                );
                $scope.rule.parameterized = detectorUtils.getAutoDetectorRuleParameterizedString(
                    $scope.rule,
                    $scope.plots
                );
                $scope.$emit('rule condition finish trigger', $scope.rule);
                $scope.rule = null;
            };

            $scope.close = function () {
                clearWatchOnRule();
                $scope.open = false;
                $scope.$emit('rule condition cancel trigger');
                $scope.rule = null;
            };
        },
    ]);
