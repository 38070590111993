import ServiceEndpointSelection from './serviceEndpointSelection';
import serviceEndpointFilter from './serviceEndpointFilter';
import serviceEndpointSelector from './serviceEndpointSelector';
import serviceEndpointSelectorDataSource from './serviceEndpointSelectorDataSource';

angular
    .module('signalview.common.tracing.serviceEndpoint', ['signalboost'])

    // components
    .component('serviceEndpointFilter', serviceEndpointFilter)
    .component('serviceEndpointSelector', serviceEndpointSelector)

    // services
    .service('ServiceEndpointSelection', ServiceEndpointSelection)
    .factory('serviceEndpointSelectorDataSource', serviceEndpointSelectorDataSource);
