const isRumDetector = (detector) =>
    (detector?.sourceSelectors || detector?.sf_sourceSelectors || []).some(
        (metric) => typeof metric === 'string' && metric.startsWith('rum.')
    );

const isSyntheticsDetector = (detector) =>
    (detector?.sourceSelectors || detector?.sf_sourceSelectors || []).some(
        (metric) => typeof metric === 'string' && metric.startsWith('synthetics.')
    );

const APM_SOURCE_SELECTORS = [
    ['spans.count', 'sf_service:', 'sf_operation:'], // service/endpoint
    ['service.request.count', 'sf_service:'], // service
    ['workflows.', 'sf_workflow:'], // workflow
];

const isApmDetector = (detector) =>
    APM_SOURCE_SELECTORS.some((sourceSelectors) =>
        sourceSelectors.every((selectorPrefix) =>
            (detector?.sourceSelectors || detector?.sf_sourceSelectors || []).some(
                (metric) => typeof metric === 'string' && metric.startsWith(selectorPrefix)
            )
        )
    );

const isOOTBDetector = (detector) => detector?.detectorOrigin === 'AutoDetect';

const isOOTBCustomizationDetector = (detector) =>
    detector?.detectorOrigin === 'AutoDetectCustomization';

const isOOTBDetectorEvent = (event) => event?.sf_eventDetectorOrigin === 'AutoDetect';

const isOOTBCustomizationDetectorEvent = (event) =>
    event?.sf_eventDetectorOrigin === 'AutoDetectCustomization';

const isSLODetectorEvent = (event) => event?.sf_eventDetectorOrigin === 'SLO';

const isSupportedByDetectorPlatform = (detector) =>
    isRumDetector(detector) ||
    isSyntheticsDetector(detector) ||
    isOOTBDetector(detector) ||
    isOOTBCustomizationDetector(detector);

export const detectorTypeResolver = {
    isRumDetector,
    isApmDetector,
    isSyntheticsDetector,
    isOOTBDetector,
    isOOTBCustomizationDetector,
    isOOTBDetectorEvent,
    isOOTBCustomizationDetectorEvent,
    isSLODetectorEvent,
    isSupportedByDetectorPlatform,
};
