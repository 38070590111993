/* Service for creating app wide notifications and notifying all watchers of changes
 * to the current set of notifications.
 *
 * notifications should be of the format
 * {
 *     [required]
 *     message: text,
 *             - single line plain text (not enforced)
 *     [optional]
 *     messageClass: normal/info/warn/error [default info]
 *     messageScope: <name of scope, example 'subscription'
 *             - use this if you want to scope notifications for deletion
 *     iconClass: name of class(es) space delim
 *     link: { text: text, href: href },
 *             - this is linkable text following mandatory message
 *     callToAction: {
 *       text: text,
 *       href: href,
 *       disable: boolean [default false]
 *       trackClickAction: string
 *             - this is to add track-click event to the button
 *       tooltipEnable: boolean [default false]
 *       tooltipPlacement: string [default 'bottom']
 *       tooltipText: text
 *       dismissOnClick: boolean,
 *       callback: function to call when button is pressed
 *     },
 *             - this is a call to action button
 *     priority: high/medium/low, [default low]
 *             - no priorities within a classfn
 *     userDismissable: true/false, [default true]
 *     dismissAfterMs: ms, [undefined or 0 won't dismiss]
 *     global: true/false, [default true]
 *             - if false, notification will be removed on route change
 *  }
 */

export const appNotificationService = [
    '$timeout',
    '$rootScope',
    function ($timeout, $rootScope) {
        const PRIORITY_LEVELS = { high: 1, medium: 2, low: 3 };
        let notifications = [];
        let nextIndex = 1;
        let watchers = [];

        $rootScope.$on('React:$routeChangeSuccess', removeLocalNotifications);

        return {
            add,
            all() {
                return angular.copy(notifications);
            },
            clear,
            remove,
            removeScope,
            watch,
        };

        // adds a notification and returns an id for this notification
        function add(notificIn) {
            if (!notificIn.message) {
                return 0; // 0 is not indexable in the id scheme
            }
            // start with defaults and override if needed
            const priority = PRIORITY_LEVELS[notificIn.priority] || PRIORITY_LEVELS.low;
            const messageClass = notificIn.messageClass || 'info';
            const id = nextIndex++;
            const notification = {
                message: notificIn.message,
                messageClass: messageClass,
                messageScope: notificIn.messageScope || '',
                iconClass: notificIn.iconClass,
                link: notificIn.link || {},
                callToAction: notificIn.callToAction || {},
                userDismissable: notificIn.userDismissable !== false, //default is true
                global: notificIn.global !== false, //default is true
                priority,
                id: id,
            };

            if (!notification.callToAction.tooltipText) {
                notification.callToAction.tooltipText = '';
            }
            if (!notification.callToAction.tooltipPlacement) {
                notification.callToAction.tooltipPlacement = 'bottom';
            }

            notifications.push(notification);
            if (!isNaN(notificIn.dismissAfterMs) && notificIn.dismissAfterMs > 0) {
                $timeout(function () {
                    remove(id);
                }, notificIn.dismissAfterMs);
            }
            notifyWatchers();
            return id;
        }

        function clear() {
            notifications = [];
            notifyWatchers();
        }

        function remove(id) {
            notifications = notifications.filter((n) => n.id !== id);
            notifyWatchers();
        }

        function removeScope(scopeName) {
            if (!scopeName) return;
            notifications = notifications.filter((n) => n.messageScope !== scopeName);
            notifyWatchers();
        }

        function watch(callback) {
            watchers.push(callback);
            return function unlisten() {
                watchers = watchers.filter((watcher) => watcher !== callback);
            };
        }

        /* private functions */

        function notifyWatchers() {
            const notificationsCopy = angular.copy(notifications);
            watchers.forEach((watcher) => watcher(notificationsCopy));
        }

        function removeLocalNotifications() {
            notifications = notifications.filter((notification) => notification.global);
            notifyWatchers();
        }
    },
];
