angular
    .module('signalview.orgIntegrations')

    .service('integrationDocumentationService', [
        '$q',
        '$log',
        'INTEGRATIONS_DOC_URL',
        'integrationsDocumentation',
        'integrationsMeta',
        'integrationsListService',
        'catalogMetricDocumentation',
        'pluginMetrics',
        'REALM',
        function (
            $q,
            $log,
            INTEGRATIONS_DOC_URL,
            integrationsDocumentation,
            integrationsMeta,
            integrationsListService,
            catalogMetricDocumentation,
            pluginMetrics,
            REALM
        ) {
            function getMetrics(service) {
                if (!pluginMetrics[service]) {
                    return $q.when();
                }

                return $q
                    .all(
                        pluginMetrics[service].map(function (metric) {
                            return catalogMetricDocumentation.getMetricDocumentation(metric);
                        })
                    )
                    .then(function (results) {
                        return results.map(function (metric, idx) {
                            const metricInfo = metric.yaml;
                            metricInfo.uglyName = pluginMetrics[service][idx];
                            metricInfo.description = metric.markdown.trim();

                            // For backwards compatibility with old metric docs.  Can be removed
                            // once migrated to new metrics.yaml format.
                            if (metricInfo.description.startsWith('###')) {
                                metricInfo.description = metricInfo.description
                                    .split('\n')
                                    .slice(1)
                                    .join('\n')
                                    .trim();
                            }

                            return metricInfo;
                        });
                    });
            }

            function getMarkdown(service) {
                return (
                    integrationsDocumentation[service] &&
                    integrationsDocumentation[service].markdown
                );
            }

            function titleize(str) {
                const smallWords =
                    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
                const bigWords = /^(ec2|http)$/i;

                return str
                    .split(' ')
                    .map(function (word) {
                        if (word.match(smallWords)) {
                            return word.toLowerCase();
                        } else if (word.match(bigWords)) {
                            return word.toUpperCase();
                        } else {
                            return word && word[0].toUpperCase() + word.slice(1).toLowerCase();
                        }
                    })
                    .join(' ');
            }

            const headingPattern = new RegExp(/^(#+)\s*(.+)/);
            const customTabMappingPattern = new RegExp(/<!--- (\w+) --->/);
            const imagePattern = new RegExp(/\((.*png)\)/);
            const linkImagePattern = new RegExp(/[^\!]\[.*?\]/);
            const scrollAnchorPattern = new RegExp(/\[(.*?)\]\(#.*?\)/);
            const tokenPlaceholder = new RegExp(/YOUR_SIGNALFX_API_TOKEN/g);
            const orgIdPlaceholder = new RegExp(/YOUR_SIGNALFX_ORG_ID/g);
            const realmPlaceholder = new RegExp(/YOUR_SIGNALFX_REALM/g);
            const magicLinkPattern = new RegExp(/\[(.*?)\]\([^\(]*?\)\[\]\(sfx_link:(.*?)\)/s);

            const tabMap = {
                Overview: ['OVERVIEW', 'DESCRIPTION', 'USAGE'],
                Setup: ['INSTALLATION', 'CONFIGURATION', 'REQUIREMENTS'],
                Metrics: ['DIMENSIONS', 'ADDITIONAL METRIC INFO'],
                'Built-in Content': ['FEATURES'],
            };

            function parseMarkdown(service, orgId, accessToken) {
                const markdown = getMarkdown(service);
                if (!markdown) {
                    return {};
                }

                let imageMatch, scrollAnchorMatch, magicLinkMatch, customTab;
                const sections = {},
                    sectionStack = [],
                    customTabMappings = {};

                markdown.split('\n').forEach(function (line) {
                    const headingMatch = line.match(headingPattern);
                    if (headingMatch) {
                        const sectionDepth = headingMatch[1].length;
                        const oldDepth =
                            sectionStack.length > 0
                                ? sectionStack[sectionStack.length - 1].depth
                                : -1;
                        if (sectionDepth <= oldDepth && sectionStack.length > 0) {
                            let depth;
                            do {
                                depth = sectionStack.pop().depth;
                            } while (depth - 1 >= sectionDepth && sectionStack.length > 0);
                        }
                        const sectionTitle = titleize(headingMatch[2]);
                        line = titleize(line);
                        sectionStack.push({ title: sectionTitle, depth: sectionDepth });
                    }

                    const customTabMatch = line.match(customTabMappingPattern);
                    if (customTabMatch) {
                        customTab = titleize(customTabMatch[1]);
                    } else if (customTab) {
                        if (sectionStack.length > 0) {
                            if (customTabMappings[customTab] === undefined) {
                                customTabMappings[customTab] = [];
                            }
                            customTabMappings[customTab].push(
                                titleize(sectionStack[sectionStack.length - 1].title)
                            );
                        } else {
                            $log.error('Malformed custom tab mapping', customTab, line);
                        }
                        customTab = null;
                    }

                    if (sectionStack.length > 0) {
                        // reconfigure image links to point at the assets repo
                        imageMatch = line.match(imagePattern);
                        if (imageMatch) {
                            // Reference from markdown is relative to original github repo
                            line = line.replace(
                                imagePattern,
                                '(' +
                                    INTEGRATIONS_DOC_URL +
                                    'assets/images/integrations/' +
                                    imageMatch[1].split('/').reverse()[0] +
                                    ')'
                            );

                            // Images that are links in the repo need to be edited to show up
                            // as images in the directive
                            line = line.replace(linkImagePattern, '![]');
                        }

                        // Remove all scroll anchor links
                        scrollAnchorMatch = line.match(scrollAnchorPattern);
                        if (scrollAnchorMatch) {
                            line = line.replace(scrollAnchorPattern, '$1');
                        }

                        line = line.replace(tokenPlaceholder, accessToken);
                        line = line.replace(orgIdPlaceholder, orgId);
                        line = line.replace(realmPlaceholder, REALM);

                        // The stack contains all sections at or "above" the current section.
                        for (const { title, depth } of sectionStack) {
                            // this line should be after all replacements are done
                            sections[title] = sections[title] || { content: '', depth };
                            sections[title].content = sections[title].content + line + '\n';
                        }
                    }
                });

                // Do the magic link replacement with the whole doc so that multiline links
                // work properly.
                for (const name of Object.keys(sections)) {
                    // replace links across github repo with links across tabs
                    magicLinkMatch = sections[name].content.match(magicLinkPattern);
                    while (magicLinkMatch) {
                        const integrationType = integrationsListService.getIntegrationType(
                            magicLinkMatch[2]
                        );
                        sections[name].content = sections[name].content.replace(
                            magicLinkPattern,
                            '[$1](#/integrations?selectedKeyValue=' + integrationType + ':$2)'
                        );
                        magicLinkMatch = sections[name].content.match(magicLinkPattern);
                    }
                }

                return {
                    sections: sections,
                    customTabMappings: customTabMappings,
                    useLegacyBuild: (getMeta(service) || {}).useLegacyBuild,
                };
            }

            function mapToTabs({ sections, customTabMappings, useLegacyBuild }) {
                if (!sections) {
                    return;
                }

                const tabs = {};
                if (useLegacyBuild) {
                    Object.keys(tabMap).forEach(function (tab) {
                        const title = titleize(tab);
                        tabMap[title]
                            .concat(customTabMappings[title] || [])
                            .forEach(function (sectionTitle) {
                                const titleized = titleize(sectionTitle);
                                if (sections[titleized]) {
                                    const section = {
                                        title: sectionTitle,
                                        content: sections[titleized].content,
                                    };
                                    if (tabs[title]) {
                                        tabs[title].push(section);
                                    } else {
                                        tabs[title] = [section];
                                    }
                                }
                            });
                    });
                } else {
                    for (const name of Object.keys(sections)) {
                        if (sections[name].depth === 2) {
                            const content = sections[name].content;

                            tabs[name] = [
                                {
                                    title: name,
                                    content: content.split('\n').slice(1).join('\n'),
                                },
                            ];
                        }
                    }
                }

                return tabs;
            }

            function getDocumentation(service, org, apiToken) {
                return mapToTabs(parseMarkdown(service, org.id, apiToken));
            }

            function getMeta(service) {
                return integrationsMeta[service];
            }

            return {
                getMetrics: getMetrics,
                getDocumentation: getDocumentation,
                getMeta: getMeta,
            };
        },
    ]);
