import templateUrl from './rollupSelector.tpl.html';

angular
    .module('chartbuilderUtil')
    .service('rollupsFromMetricType', [
        'ROLLUPS',
        function (ROLLUPS) {
            const knownMetricTypes = {
                GAUGE: 'GAUGE',
                COUNTER: 'COUNTER',
                CUMULATIVE_COUNTER: 'CUMULATIVE_COUNTER',
            };

            function getRollupsFromMetricTypes(metricTypes, useAutoPrefix) {
                let policiesAdded = [];
                const availablePolicies = [
                    {
                        displayName: 'Auto',
                        description: 'No metrics matched this query.',
                        value: null,
                        isAuto: true,
                    },
                ];

                if (!metricTypes) {
                    return availablePolicies;
                }

                function addPolicy(policy) {
                    if (policiesAdded.indexOf(policy.value) === -1) {
                        availablePolicies.push(policy);
                        policiesAdded = policy.value;
                    }
                }

                //add the rollups available to everyone
                addPolicy(ROLLUPS.SUM);
                addPolicy(ROLLUPS.AVERAGE);
                addPolicy(ROLLUPS.MIN);
                addPolicy(ROLLUPS.MAX);
                addPolicy(ROLLUPS.COUNT);
                addPolicy(ROLLUPS.LATEST);
                addPolicy(ROLLUPS.LAG);

                //add metrictype specific rollups
                if (!metricTypes.some((item) => item.metricType === knownMetricTypes.GAUGE)) {
                    if (metricTypes.some((item) => item.metricType === knownMetricTypes.COUNTER)) {
                        addPolicy(ROLLUPS.RATE);
                    } else if (
                        metricTypes.some(
                            (item) => item.metricType === knownMetricTypes.CUMULATIVE_COUNTER
                        )
                    ) {
                        addPolicy(ROLLUPS.RATE);
                        addPolicy(ROLLUPS.DELTA);
                    }
                }

                if (metricTypes.length > 1) {
                    const policy = {};
                    policy.value = null;
                    policy.displayName = useAutoPrefix ? 'Auto (Multiple)' : 'Multiple';
                    policy.description =
                        'Summarizes data using the default rollup for each metric based on its metric type: average for gauges, and rate/sec for counters and cumulative counters. Used when the selected metrics include more than one metric type.';
                    availablePolicies[0] = policy;
                } else {
                    // there is only one metric type
                    const aggMetricType = metricTypes[0];
                    if (aggMetricType) {
                        const rollupType = getRollupTypeFromValue(aggMetricType.rollupType);
                        const policy = angular.copy(ROLLUPS[rollupType]);
                        if (policy) {
                            if (useAutoPrefix) {
                                policy.displayName = 'Auto (' + policy.displayName + ')';
                            }
                            availablePolicies[0] = policy;
                        }
                    }
                }

                return availablePolicies;
            }

            function getRollupDisplayName(rollupValue) {
                let result = '';
                Object.keys(ROLLUPS).some(function (rollup) {
                    if (ROLLUPS[rollup].value !== rollupValue) {
                        return false;
                    }
                    result = ROLLUPS[rollup].displayName;
                    return true;
                });

                return result;
            }

            function getCurrentRollupDisplayName(metricTypes, currentRollup, useAutoPrefix) {
                if (currentRollup) {
                    return getRollupDisplayName(currentRollup);
                } else {
                    const availablePolicies = getRollupsFromMetricTypes(metricTypes, useAutoPrefix);
                    return availablePolicies && availablePolicies.length
                        ? availablePolicies[0].displayName
                        : '...';
                }
            }

            function getRollupTypeFromValue(rollupValue) {
                let rollupType = '';
                Object.entries(ROLLUPS).forEach(([key, value]) => {
                    if (value.value === rollupValue) {
                        rollupType = key;
                    }
                });
                return rollupType;
            }

            return {
                get: getRollupsFromMetricTypes,
                getCurrentRollupDisplayName: getCurrentRollupDisplayName,
                getRollupDisplayName,
            };
        },
    ])
    .directive('rollupSelector', [
        'rollupsFromMetricType',
        function (rollupsFromMetricType) {
            return {
                restrict: 'E',
                scope: {
                    knownMetricTypes: '=',
                    currentRollup: '=',
                    onSelect: '=',
                },
                templateUrl,
                link: function ($scope) {
                    $scope.currentRollupDisplayName =
                        rollupsFromMetricType.getCurrentRollupDisplayName(
                            $scope.knownMetricTypes,
                            $scope.currentRollup,
                            true
                        );
                    $scope.selector = {
                        visible: false,
                        query: '',
                    };

                    $scope.$watchCollection('knownMetricTypes', function (metricTypes) {
                        $scope.availablePolicies = rollupsFromMetricType.get(metricTypes, true);
                        $scope.currentRollupDisplayName =
                            rollupsFromMetricType.getCurrentRollupDisplayName(
                                metricTypes,
                                $scope.currentRollup,
                                true
                            );
                    });

                    $scope.optionSelected = function (rollup) {
                        $scope.onSelect(rollup);
                        $scope.menu.hide();
                        $scope.currentRollup = rollup.value;
                        $scope.currentRollupDisplayName = rollup.displayName;

                        $timeout(() => {
                            const nextElement = document.querySelector('.advanced-label');
                            if (nextElement) {
                                nextElement.focus();
                            }
                        }, 0);
                    };
                },
            };
        },
    ]);
