angular
    .module('signalview.organization')

    .controller('OrgNotFoundController', [
        '$scope',
        '$uibModalInstance',
        'security',
        'orgId',
        'userV2Service',
        'orgSwitchUrl',
        function ($scope, $uibModalInstance, security, orgId, userV2Service, orgSwitchUrl) {
            $scope.orgId = orgId;
            $scope.orgSwitchUrl = orgSwitchUrl;
            $scope.signingOut = false;
            function cleanUp() {
                $scope.signingOut = false;
                $uibModalInstance.close({ signout: true });
            }
            $scope.logout = function () {
                $scope.signingOut = true;
                security.logout('/signin').then(cleanUp).catch(cleanUp);
            };

            userV2Service.orgs().then((orgs) => {
                $scope.orgs = orgs.filter(function (o) {
                    return o.id !== orgId;
                });
            });
        },
    ]);
