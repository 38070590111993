import templateUrl from './office365NotifyBlock.tpl.html';

angular.module('signalview.detector.notification').directive('office365NotifyBlock', [
    '$timeout',
    '$log',
    'notificationsUtil',
    function ($timeout, $log, notificationsUtil) {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                model: '=ngModel',
                credentials: '=credentials',
                remove: '&onRemove',
                readOnly: '=?',
                doneEdit: '&onFinishEdit',
            },
            templateUrl,
            link: function ($scope) {
                $scope.credentialType = notificationsUtil.getIntegrationNameForV1orV2(
                    $scope.model.type
                );

                $scope.setCredential = function (credential) {
                    $scope.model.credentialId = credential.id;
                    $scope.model.credentialName = credential.name;
                    $scope.credential = credential;
                };

                $scope.finishEdit = function (credential) {
                    $scope.setCredential(credential);

                    if ($scope.model.credentialId) {
                        $scope.edit = false;
                    } else {
                        if ($scope.remove) $scope.remove();
                    }
                    if ($scope.doneEdit) $scope.doneEdit();
                };

                $scope.$watch('dropdownOpen', function (newValue, oldValue) {
                    if (oldValue && !newValue) {
                        if (!$scope.model.credentialId && $scope.remove) $scope.remove();
                    }
                });

                $scope.editMode = function () {
                    if (!$scope.editable) return;

                    $scope.edit = true;

                    $timeout(function () {
                        $scope.dropdownOpen = true;
                    });
                };

                $scope.editable = $scope.credentials.length > 1;

                if (!$scope.model.credentialId) {
                    if ($scope.credentials.length === 1) {
                        $scope.setCredential($scope.credentials[0]);
                        if ($scope.doneEdit) $scope.doneEdit();
                    } else {
                        $scope.editMode();
                    }
                } else {
                    $scope.credentials.some((credential) => {
                        if (credential.id === $scope.model.credentialId) {
                            $scope.setCredential(credential);
                            return true;
                        }
                    });

                    if (!$scope.credential) {
                        $scope.editable = true;
                        $log.error('Unable to find matching credential!');
                    }
                }
            },
        };
    },
]);
