const THRESHOLD_TYPE_TO_DISPLAY = {
    limit_above: 'High Threshold',
    limit_below: 'Low Threshold',
    fire_pct_threshold_stream: 'Trigger Threshold',
    fire_threshold: 'Trigger Threshold',
};

const PLOT_TYPE_TO_DISPLAY = {
    ...THRESHOLD_TYPE_TO_DISPLAY,
    preceding_error_pct: 'Preceding Error Percent',
    current_error_pct: 'Error Percent',
    err_pct: 'Error Percent',
};

export default {
    getDisplayFor(type) {
        if (typeof type !== 'string') {
            return '';
        }
        const thresholdType = Object.keys(PLOT_TYPE_TO_DISPLAY).find((prefix) =>
            type.startsWith(prefix)
        );
        return thresholdType ? PLOT_TYPE_TO_DISPLAY[thresholdType] : '';
    },

    isThresholdType(type) {
        return Object.keys(THRESHOLD_TYPE_TO_DISPLAY).some((prefix) => type.startsWith(prefix));
    },

    isKnownPlotType(type) {
        return Object.keys(PLOT_TYPE_TO_DISPLAY).some((prefix) => type.startsWith(prefix));
    },
};
