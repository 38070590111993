export default [
    function () {
        return {
            splitStringByTerms,
        };

        // Create an array for the given string that alternates between parts of the string that match any of the given
        // terms and parts that do not, so that they can be styled differently in the template. The first element of the
        // output array is an un-styled part of the string (does not match any search term), or empty string if the string
        // begins with a match. The next element would be a styled part (match), followed by an un-styled part, etc. If
        // terms overlap in the string, split it after the first term matched, followed by an empty string, then the rest of
        // the second match. Examples:
        //
        // Input: originalString 'disk_io_time.io_time', lowerCaseTerms ['io', 'time']
        // Output: ['disk_', 'io_', 'time', '.io', '_', 'time']
        //
        // Input: originalString 'cpu.utilization', lowerCaseTerms ['cpu', 'util']
        // Output: ['', 'cpu', '.', 'util', 'ization']
        //
        // Input: originalString 'ps_cputime.syst', lowerCaseTerms ['ps_cpu', 'cputime']
        // Output: ['', 'ps_cpu', '', 'time', '.syst']
        function splitStringByTerms(originalStr, lowerCaseTerms) {
            if (!originalStr) {
                return [''];
            }
            if (!lowerCaseTerms || !lowerCaseTerms.length) {
                return [originalStr];
            }

            const str = originalStr.toLocaleLowerCase();
            const matchIndexToTermLength = {};

            for (let i = 0; i < lowerCaseTerms.length; i++) {
                const term = lowerCaseTerms[i];
                if (!term) {
                    continue;
                }
                let startIdx = 0;
                let matchIdx = str.indexOf(term, startIdx);

                while (matchIdx !== -1) {
                    if (!matchIndexToTermLength[matchIdx]) {
                        matchIndexToTermLength[matchIdx] = 0;
                    }
                    // Keep the longest match at this index
                    matchIndexToTermLength[matchIdx] = Math.max(
                        matchIndexToTermLength[matchIdx],
                        term.length
                    );
                    startIdx = matchIdx + term.length;
                    matchIdx = str.indexOf(term, startIdx);
                }
            }

            const matchIndices = Object.keys(matchIndexToTermLength).map((idx) => parseInt(idx));
            if (!matchIndices.length) {
                return [originalStr];
            }

            // Construct the alternating array
            matchIndices.sort((a, b) => a - b);
            let nonMatchStartIdx = 0;
            const splitStr = [];

            for (let j = 0; j < matchIndices.length; j++) {
                const matchStartIdx = matchIndices[j];
                // Get the non-match first
                let matchStartOffset = 0;
                if (matchStartIdx < nonMatchStartIdx) {
                    // Matches are overlapping
                    if (matchStartIdx + matchIndexToTermLength[matchStartIdx] < nonMatchStartIdx) {
                        // This match is completely within the previous match. Skip it
                        continue;
                    }
                    matchStartOffset = nonMatchStartIdx - matchStartIdx;
                    splitStr.push('');
                } else {
                    splitStr.push(originalStr.substring(nonMatchStartIdx, matchStartIdx));
                }

                // Now get the match
                const matchEndIdx = matchStartIdx + matchIndexToTermLength[matchStartIdx];
                splitStr.push(originalStr.substring(matchStartIdx + matchStartOffset, matchEndIdx));
                nonMatchStartIdx = matchEndIdx;
            }

            if (nonMatchStartIdx < str.length) {
                // Rest of the string after the last match
                splitStr.push(originalStr.substring(nonMatchStartIdx, str.length));
            }

            return splitStr;
        }
    },
];
