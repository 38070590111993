export const chartLoadedEvent = [
    '$window',
    '$timeout',
    function ($window, $timeout) {
        let debounce = null;

        // optional debounce timeout
        return function (timeout) {
            if ($window.chartLoaded) {
                if (debounce) {
                    $timeout.cancel(debounce);
                    debounce = null;
                }

                // for outside controller like taking screenshot
                // use timeout so that the loading icon disappear
                debounce = $timeout(function () {
                    $window.chartLoaded();
                    debounce = null;
                }, timeout);
            }
        };
    },
];
