import uiBootstrap from 'angular-ui-bootstrap';
import { calendarWindow } from './block/calendarWindow.js';
import calendarWindowUtil from './util/calendarWindowUtil.js';
import { CALENDAR_WINDOW_CONSTANTS } from './util/calendarWindowConstants.js';
import { PercentileController } from './block/PERCENTILE/PercentileController';

angular
    .module('signalview.analytics', [
        'sfUtil',
        'sfx.filterControls',
        'signalboost',
        'mustache',
        'signalview.typeahead',
        uiBootstrap,
    ])
    .component('calendarWindow', calendarWindow)
    .controller('PercentileController', PercentileController)
    .constant('CALENDAR_WINDOW_CONSTANTS', CALENDAR_WINDOW_CONSTANTS)
    .service('calendarWindowUtil', calendarWindowUtil);
