angular.module('signalflowv2').service('blockConversionCalendarWindowParser', [
    'calendarWindowUtil',
    function (calendarWindowUtil) {
        const RECOGNIZED_CYCLES = calendarWindowUtil.getValidCycleNamesInLowerCase();
        const CALENDAR_WINDOW_TYPE = 2;
        const CYCLE_START_DEFAULTS = {
            hour: '0m',
            day: '0h',
            week: 'Monday',
            month: '1d',
            quarter: 'January',
        };

        function getCycle(cycle) {
            if (typeof cycle !== 'string' || RECOGNIZED_CYCLES.indexOf(cycle) === -1) {
                throw new Error(
                    `Transformation over a calendar window cannot be represented in Plot Builder. Supported cycle values are ${RECOGNIZED_CYCLES.join(
                        ', '
                    )}`
                );
            }
            return cycle;
        }

        function getHourAndMeridian(cycleStart) {
            return calendarWindowUtil.getHourAndMeridian(cycleStart);
        }

        function getCycleStartForQuarter(cycleStart) {
            if (calendarWindowUtil.getMonthNames().indexOf(cycleStart) === -1) {
                throw new Error(
                    `Transformation over a calendar window cannot be represented in Plot Builder. Start of Quarter: ${cycleStart} is not supported.`
                );
            }
            return cycleStart;
        }

        function getCycleStartForMonth(cycleStart) {
            const day = calendarWindowUtil.validateCustomDay(cycleStart);
            if (!day) {
                throw new Error(
                    `Transformation over a calendar window cannot be represented in Plot Builder. cycle_start: ${cycleStart} is not supported.`
                );
            }
            return day;
        }

        function getCycleStartForWeek(cycleStart) {
            if (calendarWindowUtil.getWeekdayNames().indexOf(cycleStart) === -1) {
                throw new Error(
                    `Transformation over a calendar window cannot be represented in Plot Builder. Start of Week: ${cycleStart} is not supported.`
                );
            }
            return cycleStart;
        }

        function getCycleStartForHour(cycleStart) {
            const minuteOfHour = calendarWindowUtil.convertCustomMinuteFormat(cycleStart);
            // The simple !minuteOfHour will exclude 0, but 0 is a valid value
            if (minuteOfHour === null) {
                throw new Error(
                    'Transformation over a calendar window cannot be represented in Plot Builder. Only cycle_start:0m is supported for cycle:hour.'
                );
            }
            return minuteOfHour;
        }

        function getWindowType() {
            return CALENDAR_WINDOW_TYPE;
        }

        function getPartialValueFlag(partialValue) {
            if (typeof partialValue !== 'boolean') {
                throw new Error(
                    'Transformation over a calendar window cannot be represented in Plot Builder. partial_values must be of type boolean.'
                );
            }
            return partialValue;
        }

        function getShiftCycle(shiftCycleValue) {
            if (Number.isInteger(shiftCycleValue) && shiftCycleValue >= 0) {
                return shiftCycleValue;
            }
            throw new Error(
                'Transformation over a calendar window cannot be represented in Plot Builder. shift_cycles must be >= 0.'
            );
        }

        function getCycleStartObject(cycleName, persistedCycleStartValue) {
            let cycleStart;
            let minuteOfHour;
            let hourMeridian;
            let result;
            switch (cycleName) {
                case 'quarter':
                    cycleStart = getCycleStartForQuarter(persistedCycleStartValue);
                    break;
                case 'month':
                    cycleStart = getCycleStartForMonth(persistedCycleStartValue);
                    break;
                case 'week':
                    cycleStart = getCycleStartForWeek(persistedCycleStartValue);
                    break;
                case 'day':
                    if (!calendarWindowUtil.isValidCustomHour(persistedCycleStartValue)) {
                        throw new Error(
                            `Transformation over a calendar window cannot be represented in Plot Builder. cycle_start: ${persistedCycleStartValue} is not supported.`
                        );
                    }
                    result = getHourAndMeridian(persistedCycleStartValue);
                    cycleStart = result.hour;
                    hourMeridian = result.meridian;
                    break;
                case 'hour':
                    minuteOfHour = getCycleStartForHour(persistedCycleStartValue);
                    break;
                default:
                    throw new Error(
                        `Transformation over a calendar window cannot be represented in Plot Builder. cycle: ${cycleName} is not supported.`
                    );
            }

            return {
                cycleStart,
                minuteOfHour,
                hourMeridian,
            };
        }

        function getDefaultCycleStart(cycle) {
            return CYCLE_START_DEFAULTS[cycle];
        }

        return {
            getCycle,
            getCycleStartObject,
            getHourAndMeridian,
            getCycleStartForMonth,
            getCycleStartForQuarter,
            getCycleStartForWeek,
            getCycleStartForHour,
            getWindowType,
            getPartialValueFlag,
            getShiftCycle,
            getDefaultCycleStart,
        };
    },
]);
