angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    'blockService',
    function (chartTemplates, Chart, plotUtils, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Top Mem Page Swaps/sec')
                .mode('list')
                .type('line')
                .sort('-value')
                .maxDecimalPlaces(3)
                .updateInterval(5000);

            const letters = ['vmpage_io.swap.in', 'vmpage_io.swap.out'].map(function (metric) {
                const plot = chart.plot().metric(metric).visible(false).propertyFilters(filters);

                return plotUtils.getLetterFromUniqueKey(plot.uniqueKey());
            });

            chart
                .plot()
                .macro(letters[0] + '+' + letters[1])
                .name('')
                .addManipulation(blockService.topn.asAggregation([], { count: 5 }))
                .addManipulation(blockService.mean.asAggregation([{ value: 'host' }]));

            return chart;
        }

        chartTemplates.register('collectdHostsTopMemoryPageSwaps', createChart);
    },
]);
