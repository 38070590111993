'use strict';
import templateUrl from './amazonEventBridgeIntegration.html';

/* jshint camelcase: false */

angular.module('sfx.ui').directive('sfxAmazoneventbridgeIntegration', [
    'awsIntegrationService',
    function (awsIntegrationService) {
        return {
            restrict: 'E',
            scope: true,
            require: '^form',
            templateUrl,
            link: function ($scope) {
                $scope.temp = {};

                loadRegions();

                function reset() {
                    initCredentials($scope);
                    $scope.sharedCredentialState.updateCredentials = false;
                }

                function initCredentials($scope) {
                    const credential = $scope.credential;
                    if (!credential.name) {
                        credential.name = 'Amazon EventBridge';
                    }
                }

                function loadRegions() {
                    awsIntegrationService.getSupportedNamespacesAndRegions().then((result) => {
                        $scope.awsRegions = angular.extend(
                            {},
                            result.data.regions,
                            result.data.govcloud
                        );
                    });
                }

                $scope.$on('reset', reset);
                reset();
            },
        };
    },
]);
