import plotTypeService from './plotTypeService';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

const DATA_STREAMS_PATTERN = /(data|streams\.error_rate)\(\'?([^(),']*)\'?/;

class PlotsService {
    getPlotsInformation(
        plots = [],
        event,
        formatValue = this.formatNumberValue,
        plotColorMap = {}
    ) {
        return plots
            .sort((plotA, plotB) => {
                const first = this._isThresholdPlot(plotA);
                const second = this._isThresholdPlot(plotB);
                if (first === second) {
                    return 0;
                }
                if (first) {
                    return 1;
                }
                return -1;
            })
            .map((plot) => {
                if (this._isThresholdPlot(plot)) {
                    return {
                        key: this._getPlotKey(plot),
                        name: this._getPlotName(plot),
                        value: formatValue(plot.value),
                        severity: this._getPlotSeverity(plot, event.metadata.sf_severity),
                    };
                }

                const roundingLength = this.getRoundingLengthForTriggerValue(plots);
                const value = plot.value;

                return {
                    key: this._getPlotKey(plot),
                    name: this._getPlotName(plot),
                    value: formatValue(value, roundingLength),
                    color: this._getPlotColor(plot, plotColorMap),
                    severity: this._getPlotSeverity(plot, event.metadata.sf_severity),
                };
            });
    }

    getPlotsForIncident(event, incidentInfo) {
        const allDetectorPlots = Object.values(event.properties.inputs);

        // looks like for build-in detectors, we don't get any incidentInfo,
        // so all the plots will be displayed
        const incidentRelatedPlots =
            incidentInfo && incidentInfo.selectedIdentifiers
                ? this._getRelatedPlots(allDetectorPlots, incidentInfo)
                : allDetectorPlots;

        return incidentRelatedPlots;
    }

    _getRelatedPlots(plots, incidentInfo) {
        const plotMap = this._getPlotLabelMap(plots);
        const identifiers = incidentInfo.selectedIdentifiers;

        return plots.filter((input) => {
            const label = plotMap[input.identifier];
            return identifiers.indexOf(label) !== -1;
        });
    }

    // example output: { _S1: 'A', _S2: limit_above_27_1 }
    _getPlotLabelMap(plots) {
        return plots.reduce((plotToLabelMap, input) => {
            if (input.identifiers) {
                plotToLabelMap[input.identifier] = input.identifiers[0];
            }
            return plotToLabelMap;
        }, {});
    }

    formatNumberValue(value, digitLimit = 6) {
        return parseFloat(Number(value).toFixed(digitLimit));
    }

    getTriggerPlot(plots) {
        return plots.find((plot) => plot.fragment && plot.fragment.match(DATA_STREAMS_PATTERN));
    }

    getRoundingLengthForTriggerValue(plots) {
        const threshold = this.getThresholdValue(plots);
        // Round to one digit longer than the threshold value by default
        // but if there is no threshold value round to 6
        return threshold ? threshold.toString().length + 1 : 6;
    }

    getTriggerValue(plots, formatValue = this.formatNumberValue) {
        const roundingLength = this.getRoundingLengthForTriggerValue(plots);
        const triggerPlot = this.getTriggerPlot(plots);
        return triggerPlot ? formatValue(triggerPlot.value, roundingLength) : '';
    }

    getTriggerValueAsNumber(plots) {
        const roundingLength = this.getRoundingLengthForTriggerValue(plots);
        const triggerPlot = this.getTriggerPlot(plots);
        return triggerPlot ? this.formatNumberValue(triggerPlot.value, roundingLength) : '';
    }

    getThresholdValue(plots, formatValue = this.formatNumberValue) {
        const thresholdPlot = plots.find((plot) => this._isThresholdPlot(plot));
        return thresholdPlot ? formatValue(thresholdPlot.value) : '';
    }

    getTriggerPlotName(plots) {
        const triggerPlot = this.getTriggerPlot(plots);
        return triggerPlot ? this._parseMetricName(triggerPlot) : '';
    }

    _getPlotKey(plot) {
        const identifier = this._getIdentifier(plot);
        return identifier && !plotTypeService.isKnownPlotType(identifier) ? identifier : null;
    }

    _getMetricName(plot) {
        return safeLookup(plot, 'key.sf_metric');
    }

    _parseMetricName(plot) {
        const match = plot.fragment && plot.fragment.match(DATA_STREAMS_PATTERN);
        return safeLookup(match, '2');
    }

    _getPlotName(plot) {
        return (
            this._getMetricName(plot) ||
            this._parseMetricName(plot) ||
            plotTypeService.getDisplayFor(this._getIdentifier(plot))
        );
    }

    _getPlotSeverity(plot, alertSeverity) {
        return this._isThresholdPlot(plot) ? alertSeverity.toLowerCase() : 'normal';
    }

    _getPlotColor(plot, plotColorMap) {
        return plotColorMap[plot.fragment];
    }

    _isThresholdPlot(plot) {
        const identifier = this._getIdentifier(plot);
        return identifier && plotTypeService.isThresholdType(identifier);
    }

    _getIdentifier(plot) {
        return safeLookup(plot, 'identifiers.0');
    }
}

export default new PlotsService();
