import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AngularInjector } from '../../../common/AngularUtils';

export const ValidatedChartRedirect: FC<{ chartId?: string }> = ({ chartId }) => {
    const history = useHistory();
    const { search } = useLocation();
    const chartV2Service = AngularInjector.useInjectedClass('chartV2Service');
    const alertMessageService = AngularInjector.useInjectedClass('alertMessageService');

    useEffect(() => {
        const goToAlerts = (): void => history.replace('/alerts/');

        async function checkChart(): Promise<void> {
            try {
                await chartV2Service.get(chartId);
                history.replace({ pathname: `/chart/v2/${chartId}`, search });
            } catch (e) {
                alertMessageService({
                    title: 'Chart not found',
                    messages: [
                        'The chart requested was not found. Possible reasons include :',
                        'The currently logged in organization does not have permission to view this object',
                        'The object has been deleted.',
                    ],
                }).then(goToAlerts, goToAlerts);
            }
        }

        checkChart();
    }, [chartId, chartV2Service, history, alertMessageService, search]);

    return null;
};
