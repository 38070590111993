import { getVersion } from '../../../app/charting/chart/chartVersionServiceModule';
import {
    getChartPositionFromDashboardCharts,
    removeUiFieldsFromDashboard,
} from '@splunk/olly-services';
import { DASHBOARD_TYPE } from './getOrgScopeErrorHandler';

angular.module('sfx.api.v2').service('dashboardV2Service', [
    '$q',
    '$log',
    '$http',
    'createV2Service',
    'currentUser',
    'signalviewMetrics',
    'autoSwitchOrgWhen403',
    'autoRedirectWhenHttpError',
    function (
        $q,
        $log,
        $http,
        createV2Service,
        currentUser,
        signalviewMetrics,
        autoSwitchOrgWhen403,
        autoRedirectWhenHttpError
    ) {
        const dashboardV2Service = createV2Service('dashboard');

        const crudFuncs = ['create', 'get', 'update', 'search', 'delete'];
        const apiToUIDensityMap = { LOW: 0.5, DEFAULT: null, HIGH: 2, HIGHEST: 4 };
        const uiToAPIDensityMap = {};

        angular.forEach(apiToUIDensityMap, (value, key) => {
            if (value !== null && angular.isDefined(value)) {
                uiToAPIDensityMap[value] = key;
            }
        });

        dashboardV2Service.getHomepage = function () {
            return $http({
                method: 'GET',
                url: dashboardV2Service.resourceEndpoint + '/homepage',
            }).then(
                function (resp) {
                    return {
                        configId: resp.data.configId,
                        dashboardId: resp.data.id,
                        groupId: resp.data.groupId,
                    };
                },
                function () {
                    $log.error('Failed fetching homepage');
                    return null;
                }
            );
        };

        // Hacky, but the following code wraps request/response objects to/from V2 dashboard
        // endpoint such that dashboardFilters retain their v1 shape clientside.
        // Specifically this means that v2 Dashboard Model prop chartDensity is moved
        // clientside to reside under filters (matching v1 and the logical grouping in the UI).

        dashboardV2Service.apiDashboardToUI = function (dashboard) {
            const dash = angular.copy(dashboard);
            const density = apiToUIDensityMap[dash.chartDensity];
            dash.filters = dash.filters || {};
            dash.filters.density = density || null;
            if (dash.filters.time) {
                dash.filters.time.relative = angular.isString(dash.filters.time.start);
            }

            if (dash.charts) {
                // a guard against widgets with null chartIds, which indicates a widget referenced a chart
                // which is no longer linked to this dashboard, or duplicate chartIds (which indicates 2 linked charts
                // share the same chartIndex).  failsafe to ensure the UI renders, we are working on handling these consistency
                // issues on the backend.

                const chartIds = dash.charts.map((widget) => {
                    return widget.chartId;
                });
                dash.charts = dash.charts.filter((widget, index) => {
                    const isDuplicate = chartIds.indexOf(widget.chartId) !== index;
                    const isNullOrUndefined =
                        angular.isUndefined(widget.chartId) || widget.chartId === null;

                    if (isNullOrUndefined) {
                        $log.error('Removing chart widget with null chartId.', {
                            dashboard: dash,
                            widget: widget,
                        });
                        signalviewMetrics.incr('ui.dashboard.nullWidgetChartId', {
                            dashboard: dash.id,
                        });
                    } else if (isDuplicate) {
                        $log.error('Removing chart widget with duplicate chartId.', {
                            dashboard: dash,
                            widget: widget,
                        });
                        signalviewMetrics.incr('ui.dashboard.duplicateWidgetChartId', {
                            dashboard: dash.id,
                        });
                    }

                    return !isNullOrUndefined && !isDuplicate;
                });

                // this reshaping is necessary because these widget objects are passed directly to gridster,
                // which expects properties to have a certain name.  probably worth a refactor.
                dash.charts.forEach((widget) => {
                    widget.sizeX = widget.width > 0 ? widget.width : 6;
                    widget.sizeY = widget.height > 0 ? widget.height : 1;
                    widget.col = widget.column;
                });
            }

            return dash;
        };

        dashboardV2Service.uiDashboardToAPI = function (dashboard) {
            return removeUiFieldsFromDashboard(dashboard);
        };

        // Although it returns an array there is just one dashboard associated
        // with a chart and the size of the array will be 1
        dashboardV2Service.getDashboardForChartId = function (chartId) {
            const resourcePath = '?chartId=' + chartId;
            return $http({
                method: 'GET',
                url: dashboardV2Service.resourceEndpoint + resourcePath,
            }).then(
                function (resp) {
                    return resp.data.results[0];
                },
                function () {
                    $log.error('Failed fetching dashboard for chart ', chartId);
                    return null;
                }
            );
        };

        const baseUpdate = dashboardV2Service.update;
        dashboardV2Service.update = function (dashboard) {
            return baseUpdate(dashboardV2Service.uiDashboardToAPI(dashboard));
        };

        const baseCreate = dashboardV2Service.create;
        dashboardV2Service.create = function (dashboard) {
            return baseCreate(dashboardV2Service.uiDashboardToAPI(dashboard));
        };

        for (const func of Object.keys(dashboardV2Service)) {
            if (crudFuncs.includes(func)) {
                const unwrappedFunc = dashboardV2Service[func];
                if (func === 'search') {
                    dashboardV2Service[func] = function (...args) {
                        return unwrappedFunc.apply(this, args).then((response) => {
                            response.results = response.results.map((dashboard) => {
                                return dashboardV2Service.apiDashboardToUI(dashboard);
                            });
                            return response;
                        });
                    };
                } else {
                    dashboardV2Service[func] = function (...args) {
                        return unwrappedFunc.apply(this, args).then((dashboard) => {
                            return dashboardV2Service.apiDashboardToUI(dashboard);
                        });
                    };
                }
            }
        }

        function logErrorAndReject(message, error) {
            $log.error(message + ':', error);
            return $q.reject(error);
        }
        function onError(message) {
            return function (error) {
                return logErrorAndReject(message, error);
            };
        }

        function getTransientChartId(charts) {
            const currentChartIds = (charts || []).map(function (chart) {
                let id = chart.sf_id || chart.id || chart.chartId || '';
                id = id.replace('SYNTH_CHART_ID_', '');
                return parseInt(id, 10);
            });
            const max = _.max(currentChartIds);
            const nextId = max + 1 || 0;
            const transientId = 'SYNTH_CHART_ID_' + nextId;
            return transientId;
        }

        dashboardV2Service.searchByAny = function (searchParams) {
            return currentUser
                .orgId()
                .then(function (orgId) {
                    const resourcePath = '/_/search';
                    let params = { organizationId: orgId };
                    params = angular.extend(params, searchParams);

                    return $http.get(dashboardV2Service.resourceEndpoint + resourcePath, {
                        params: params,
                    });
                })
                .then(function (resp) {
                    resp.data.results = resp.data.results.map((dash) => {
                        return dashboardV2Service.apiDashboardToUI(dash);
                    });
                    return resp.data;
                });
        };

        dashboardV2Service.getAll = function (ids, fetchCharts = true) {
            const resourcePath = `/_/dashboards?fetchCharts=${fetchCharts}`;
            return $http
                .post(dashboardV2Service.resourceEndpoint + resourcePath, ids)
                .then(function (resp) {
                    return resp.data;
                });
        };

        dashboardV2Service.searchCacheForIndex = function () {
            const resourcePath = '/_/searchCache';
            return $http
                .get(dashboardV2Service.resourceEndpoint + resourcePath)
                .then(function (resp) {
                    return resp.data;
                });
        };

        dashboardV2Service.getHierarchyV2 = function (
            dashboardId,
            groupId,
            disableErrorHandling = false
        ) {
            const resourcePath = '/_/hierarchy/' + dashboardId;
            return doFetch()
                .catch((responseOrError) =>
                    autoSwitchOrgWhen403(responseOrError, DASHBOARD_TYPE, dashboardId).then(doFetch)
                )
                .catch(
                    disableErrorHandling
                        ? (e) => {
                              throw e;
                          }
                        : autoRedirectWhenHttpError
                );

            function doFetch() {
                return $http
                    .get(dashboardV2Service.resourceEndpoint + resourcePath, {
                        params: { group: groupId },
                    })
                    .then((response) => response.data);
            }
        };

        dashboardV2Service.lockDashboard = function (dashId) {
            return currentUser
                .orgId()
                .then(function (orgId) {
                    const resourcePath = '/' + dashId + '/lock';
                    return $http.put(dashboardV2Service.resourceEndpoint + resourcePath, true, {
                        params: {
                            organizationId: orgId,
                        },
                    });
                })
                .then((response) => {
                    return response.data;
                })
                .catch(onError('Failed to lock dashboard: '));
        };

        dashboardV2Service.unlockDashboard = function (dashId) {
            return currentUser
                .orgId()
                .then(function (orgId) {
                    const resourcePath = '/' + dashId + '/unlock';
                    return $http.put(dashboardV2Service.resourceEndpoint + resourcePath, true, {
                        params: {
                            organizationId: orgId,
                        },
                    });
                })
                .then((response) => {
                    return response.data;
                })
                .catch(onError('Failed to unlock dashboard: '));
        };

        dashboardV2Service.saveChartToDashboard = function (dashboard, chart) {
            return dashboardV2Service.get(dashboard.id).then(function (dashboard) {
                dashboard.charts.push(getChartPositionFromDashboardCharts(dashboard, chart));
                return dashboardV2Service.update(dashboard);
            });
        };

        dashboardV2Service.getMirrorState = function (dashboardId) {
            const resourcePath = `/${dashboardId}/mirrorState`;
            return $http
                .get(dashboardV2Service.resourceEndpoint + resourcePath)
                .then((response) => response.data)
                .catch(onError('Failed to check mirror state: '));
        };

        dashboardV2Service.export = function (id) {
            const resourcePath = `/${id}/export`;
            return $http
                .get(dashboardV2Service.resourceEndpoint + resourcePath)
                .then((response) => response.data);
        };

        dashboardV2Service.import = function (pkg, groupId) {
            const endpoint = dashboardV2Service.resourceEndpoint + '/import';
            const params = { groupId: groupId };
            return $http.post(endpoint, pkg, { params }).then((response) => response.data);
        };

        dashboardV2Service.addTransientChart = function (dashboard, chart) {
            const version = getVersion(chart);
            if (version === 1 && !chart.sf_id) {
                chart.sf_id = getTransientChartId(dashboard.charts);
                chart.sf_chartIndex = Date.now();
            } else if (version === 2) {
                if (!chart.id) {
                    chart.id = getTransientChartId(dashboard.charts);
                    chart.sf_chartIndex = Date.now();
                }
                chart.sf_modelVersion = 2;
            }

            // in the event this call FAILS, the objects passed in are irrevocably changed
            // maybe we should do deep copies...?
            const chartWidth = 6;
            let maxRow = 0;

            dashboard.charts = dashboard.charts || [];

            dashboard.charts.forEach(function (widget) {
                if (widget.col >= chartWidth) {
                    return;
                }
                const row = widget.row + (widget.height || 1);
                if (row > maxRow) {
                    maxRow = row;
                }
            });

            dashboard.charts.push({
                column: 0,
                col: 0,
                row: maxRow,
                sizeX: chartWidth,
                width: chartWidth,
                sizeY: 1,
                height: 1,
                chartId: chart.sf_id || chart.id,
            });
        };

        dashboardV2Service.addTransientCharts = function (dashboard, charts) {
            const NUM_COLS_PER_ROW = 12;
            // Best effort at placing two charts per row. Might be off if the
            // existing charts in the dashboard have imbalanced placement.
            const chartWidth = NUM_COLS_PER_ROW / 2;
            let lastRow = 0;
            // Find last row of existing dashboard that contains charts
            dashboard.charts = dashboard.charts || [];
            dashboard.charts.forEach(function (widget) {
                const row = widget.row;
                if (row > lastRow) {
                    lastRow = row;
                }
            });

            // Find last column used by a chart in the last row
            let lastCol = 0;
            let lastRowHeight = 0;
            dashboard.charts.forEach(function (widget) {
                if (widget.row === lastRow) {
                    const col = widget.col + (widget.sizeX || 1);
                    if (col > lastCol) {
                        lastCol = col;
                    }
                    const chartHeight = widget.sizeY || 1;
                    if (chartHeight > lastRowHeight) {
                        lastRowHeight = chartHeight;
                    }
                }
            });
            let startCol = 0;
            let otherCol = chartWidth;
            // If we're currently at column 0, use row lastRow and column 0.
            // Otherwise...
            if (lastCol > 0) {
                if (NUM_COLS_PER_ROW - lastCol >= chartWidth) {
                    // New chart can fit in the righthand column of lastRow
                    startCol = chartWidth;
                    otherCol = 0;
                } else {
                    // New chart won't fit in the last row. Start a new one.
                    lastRow += lastRowHeight;
                }
            }

            let now = Date.now();
            for (let i = 0; i < charts.length; i++) {
                const chart = charts[i];
                // Make sure these are unique
                const version = getVersion(chart);
                if (version === 1 && !chart.sf_id) {
                    chart.sf_id = getTransientChartId(dashboard.charts);
                    chart.sf_chartIndex = now++;
                } else if (version === 2) {
                    if (!chart.id) {
                        chart.id = getTransientChartId(dashboard.charts);
                        chart.sf_chartIndex = now++;
                    }
                    chart.sf_modelVersion = 2;
                }
                // Place chart in left or right column, and increment rows based on
                // which column the first chart is in
                dashboard.charts.push({
                    col: i % 2 ? otherCol : startCol,
                    column: i % 2 ? otherCol : startCol,
                    row: startCol ? lastRow + Math.ceil(i / 2) : lastRow + Math.floor(i / 2),
                    sizeX: chartWidth,
                    width: chartWidth,
                    sizeY: 1,
                    height: 1,
                    chartId: chart.sf_id || chart.id,
                });
            }
        };

        dashboardV2Service.addCharts = function (dashboard, charts) {
            dashboardV2Service.addTransientCharts(dashboard, charts);
            return dashboardV2Service.update(dashboard);
        };

        return dashboardV2Service;
    },
]);
