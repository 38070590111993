angular.module('signalview.timeservice', []).provider('timeservice', [
    function () {
        // this is what we believe the clock skew is between the client and the server
        let serverSkew = 0;
        // this is how long we assume ingest+analytics takes
        const inputDelay = 2000;
        const ts = {
            setSkew: function (skew) {
                if (angular.isNumber(skew)) {
                    serverSkew = skew;
                }
            },
            getSkew: function () {
                return serverSkew;
            },
            getServerTime: function () {
                return Date.now() - serverSkew - inputDelay;
            },
            getServerTimeFromLocal: function (localMs) {
                return localMs - serverSkew - inputDelay;
            },
        };

        this.$get = function () {
            return ts;
        };
    },
]);
