// https://en.wikipedia.org/wiki/Box-Muller_transform
function boxMullerTransform(mean, standardDeviation) {
    const normalizedValue =
        Math.sqrt(-2 * Math.log(1 - Math.random())) * Math.cos(2 * Math.PI * Math.random());

    return normalizedValue * standardDeviation + mean;
}

function NormalizedGenerator(max = 100, mean = 50, standardDeviation = 20) {
    this.max = max;
    this.mean = mean;
    this.standardDeviation = standardDeviation;
}

// Make this interface similar to a built in JS generator
NormalizedGenerator.prototype.next = function () {
    boxMullerTransform(this.mean, this.standardDeviation);
    // Get current random value
    // Generate next random value
    // Call the transform with these values
    return { value: boxMullerTransform(this.mean, this.standardDeviation) };
};

export default NormalizedGenerator;
