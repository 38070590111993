angular.module('signalview.detector.wizard', [
    'signalview.chartbuilder',
    'sfx.api.v2',
    'sfx.ui',
    'sfx.data',
    'sfx.util',
    'signalboost',
    'signalview.sfGlobalNav',
    'signalview.detector',
    'signalview.detector.notification',
    'signalview.detector.rule',
    'signalview.detector.signal',
    'signalview.snapshot',
    'signalview-utils.functions',
    'signalview-utils.signalflowv2',
    'signalflowv2',
]);
