const INPUT_MARKUP = '<input type="file" multiple style="display:none"/>';

/**
  Call .load() to bring up the device file selection menu.

  Returns a promise of an array of the text content of all the files the user
  selects.
**/
class MultiFileLoader {
    constructor($window) {
        this.fileInput = angular.element(INPUT_MARKUP).get()[0];
        $window.document.body.appendChild(this.fileInput);
    }

    load() {
        return new Promise((resolve) => {
            const fileInput = this.fileInput;

            fileInput.onchange = () => {
                // fileInput.files is iterable but not an array
                const contentPromises = [...fileInput.files].map((file) => {
                    return new Promise((_resolve, _reject) => {
                        const reader = new FileReader();
                        reader.onerror = (e) => _reject(e);
                        reader.onload = () => _resolve(reader.result);
                        reader.readAsText(file);
                    });
                });

                fileInput.value = '';
                resolve(Promise.all(contentPromises));
            };

            fileInput.click();
        });
    }
}

MultiFileLoader.$inject = ['$window'];

export default MultiFileLoader;
