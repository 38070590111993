'use strict';
import templateUrl from './pingOneIntegration.html';
import samlShowOnLoginPageTooltipTemplateUrl from './samlShowOnLoginPageTooltip.tpl.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('sfxPingOneIntegration', [
    'PRODUCT_NAME',
    function (PRODUCT_NAME) {
        return {
            restrict: 'E',
            scope: true,
            require: '^sfxIntegration',
            templateUrl,
            link: function ($scope) {
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.samlShowOnLoginPageTooltipTemplateUrl =
                    samlShowOnLoginPageTooltipTemplateUrl;

                function reset() {
                    const credential = $scope.credential;

                    if (!credential.name) {
                        credential.name = 'PingOne';
                    }

                    $scope.sharedCredentialState.updateCredentials = false;
                }

                $scope.$on('reset', reset);
                reset();

                $scope.updateMetadata = function (content) {
                    $scope.credential.metadata = content;
                    $scope.credentialUpdate();
                };

                $scope.updatePublicKey = function (content) {
                    $scope.credential.publicKey = content;
                    $scope.credentialUpdate();
                };

                $scope.credentialUpdate = function () {
                    $scope.sharedCredentialState.updateCredentials = true;
                    $scope.integrationForm.$setDirty();
                };
            },
        };
    },
]);
