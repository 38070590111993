export default {
    METRICS: [
        {
            displayName: 'CPU',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_node',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_cluster',
                        type: 'property',
                    },
                ],
                template:
                    "CPU_UTILIZATION = data('cpu.utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2).mean(by=['kubernetes_cluster', 'kubernetes_node'])",
                varName: 'CPU_UTILIZATION',
            },
        },
        {
            displayName: 'Memory',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_node',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_cluster',
                        type: 'property',
                    },
                ],
                template:
                    "MEMORY_UTILIZATION = data('memory.utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2).mean(by=['kubernetes_cluster', 'kubernetes_node'])",
                varName: 'MEMORY_UTILIZATION',
            },
        },
        {
            displayName: 'Disk',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_node',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_cluster',
                        type: 'property',
                    },
                ],
                template:
                    "DISK_UTILIZATION = data('disk.summary_utilization'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2).mean(by=['kubernetes_cluster', 'kubernetes_node'])",
                varName: 'DISK_UTILIZATION',
            },
        },
        {
            displayName: 'Network',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_node',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_cluster',
                        type: 'property',
                    },
                ],
                template:
                    "NETWORK_TOTAL = data('network.total'{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation='last_value', maxExtrapolations=2).mean(by=['kubernetes_cluster', 'kubernetes_node'])",
                varName: 'NETWORK_TOTAL',
            },
        },
        {
            displayName: 'Condition',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_node',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_cluster',
                        type: 'property',
                    },
                ],
                template:
                    'NODE_CONDITION = data("kubernetes.node_*"{{#filter}}, filter={{{filter}}}{{/filter}}, extrapolation="last_value", maxExtrapolations=2, rollup="latest").above(1, inclusive=True)',
                varName: 'NODE_CONDITION',
            },
        },
    ],
    COLUMNS: [
        {
            aggregate: null,
            displayName: 'Node',
            format: 'link',
            metric: null,
            property: 'kubernetes_node',
            propertiesToLinkOn: ['kubernetes_node', 'kubernetes_cluster'],
            panelName: 'kubernetes_node',
        },
        {
            aggregate: null,
            displayName: 'CPU Used',
            format: 'Percentage',
            metric: null,
            property: 'CPU',
        },
        {
            aggregate: null,
            displayName: 'Memory Used',
            format: 'Percentage',
            metric: null,
            property: 'Memory',
        },
        {
            aggregate: null,
            displayName: 'Disk Used',
            format: 'Percentage',
            metric: null,
            property: 'Disk',
        },
        {
            aggregate: null,
            displayName: 'Network',
            format: 'Number',
            metric: null,
            property: 'Network',
        },
        {
            aggregate: null,
            displayName: 'Host Cores',
            format: null,
            metric: null,
            property: 'host_cpu_cores',
        },
        {
            aggregate: null,
            displayName: 'Total Memory',
            format: 'ScaleKilobytes',
            metric: null,
            property: 'host_mem_total',
        },
        {
            aggregate: null,
            displayName: 'Condition',
            format: 'Custom',
            metric: null,
            property: 'Condition',
            disableSort: true,
        },
    ],

    UNIQUE_ID: '{{ kubernetes_cluster}} {{kubernetes_node}}',

    METRICS_TO_AGGREGATE: {
        Condition: {
            metrics: [
                'kubernetes.node_out_of_disk',
                'kubernetes.node_ready',
                'kubernetes.node_p_i_d_pressure',
                'kubernetes.node_memory_pressure',
                'kubernetes.node_network_unavailable',
            ],
            key: 'sf_originatingMetric',
            displayMapping: [
                'Out of Disk',
                'Ready',
                'PID Pressure',
                'Memory Pressure',
                'Disk Pressure',
                'Network Unavailable',
            ],
        },
    },
};
