import templateUrl from './metricsEventsSidebar.tpl.html';

angular.module('signalview.metricsEventsSidebar').directive('metricsEventsSidebar', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl,
            scope: {
                currentDashboard: '=?',
                currentCharts: '=?',
                isReadOnlyDashboard: '=?',
                workspaceMode: '=?',
                saveWorkspace: '=?',
                viewType: '@?',
                eventSidebarParams: '=?',
                closeSidebar: '=',
                metricsOnly: '<?',
                eventsTabSelected: '=',
            },
            link: function ($scope, sidebar) {
                function initSidebar() {
                    // Enable resizing sidebar to accommodate long metric names
                    sidebar.resizable({
                        handles: 'w',
                        minWidth: 360,
                        maxWidth: 465, // < browser min-width - gridster-main min-width
                        resize: function (event, ui) {
                            //there is a bug with draggable that incorrectly determines the positioning
                            //of the dragged element, causing left to be set.  this is a hack to prevent
                            //it from setting the left value
                            angular.element(ui.originalElement).css('left', 0);
                        },
                    });
                }
                initSidebar();

                $scope.metricsTabSelected = !$scope.eventsTabSelected;

                $scope.isMetricsTabSelected = function () {
                    return $scope.metricsTabSelected && !$scope.isReadOnlyDashboard;
                };

                $scope.setMetricsTab = function () {
                    if ($scope.metricsTabSelected) {
                        return;
                    }
                    $scope.metricsTabSelected = true;
                    initSidebar();
                };

                $scope.setEventsTab = function () {
                    if (!$scope.metricsTabSelected) {
                        return;
                    }
                    $scope.metricsTabSelected = false;
                    sidebar.resizable('destroy');
                };

                $scope.$on('selectMetricsTab', $scope.setMetricsTab);
                $scope.$on('selectEventsTab', $scope.setEventsTab);
            },
        };
    },
]);
