import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BEMHelper from 'react-bem-helper';
import PlotsList from './PlotsList';
import DimensionsList from './DimensionsList';
import SimpleSpec from '../nextSteps/components/SimpleSpec';

const PLOTS_LABEL = 'Plots:';
const CONDITION_LABEL = 'Condition:';
const TRIGGER_VALUE_LABEL = 'Trigger value:';
const DIMENSIONS_LABEL = 'Dimensions:';
const SHOW_PLOT_INFORMATION_LABEL = 'Show plot information';
const HIDE_PLOT_INFORMATION_LABEL = 'Hide plot information';

const className = new BEMHelper('chart-information');

export default function ChartInformation({ chartInformation }) {
    const [isExpanded, setIsExpanded] = useState(false);

    function ShowMoreButton() {
        const toggleShowMore = () => setIsExpanded(!isExpanded);
        const buttonLabel = isExpanded ? HIDE_PLOT_INFORMATION_LABEL : SHOW_PLOT_INFORMATION_LABEL;
        return (
            <button {...className('link', [], 'sf-clickable')} onClick={toggleShowMore}>
                {buttonLabel}
            </button>
        );
    }

    return (
        <section {...className()}>
            <SimpleSpec label={PLOTS_LABEL} value={<PlotsList plots={chartInformation.plots} />} />
            <ShowMoreButton />
            {isExpanded && (
                <>
                    <SimpleSpec label={CONDITION_LABEL} value={chartInformation.condition} />
                    <SimpleSpec label={TRIGGER_VALUE_LABEL} value={chartInformation.triggerValue} />
                    <SimpleSpec
                        label={DIMENSIONS_LABEL}
                        value={<DimensionsList dimensions={chartInformation.dimensions} />}
                    />
                </>
            )}
        </section>
    );
}

ChartInformation.propTypes = {
    chartInformation: PropTypes.object,
};
