import React, { FC } from 'react';

type LOSubscriptionInfoProps = {
    ingestQuota: string;
    indexQuota: string;
    termStartDate: string;
    termEndDate: string;
};

const LOSubscriptionInfo: FC<LOSubscriptionInfoProps> = ({
    ingestQuota,
    indexQuota,
    termStartDate,
    termEndDate,
}) => {
    return (
        <div className="org-sub-info">
            <div className="org-subscription-title">Subscription</div>
            <div className="subscription-note">
                If you are on a Pooled contract, please contact your Splunk account team for your
                subscription information.
            </div>
            <hr />
            <div className="org-sub-info-section">
                <div>
                    <div className="org-usage-label">Ingest Quota:</div>
                    <div className="sf-inline">{ingestQuota} GB</div>
                </div>
                <div>
                    <div className="org-usage-label">Index Quota:</div>
                    <div className="sf-inline">{indexQuota} GB</div>
                </div>
                <div>
                    <div className="org-usage-label">Term Start Date:</div>
                    <div className="sf-inline">
                        {new Date(termStartDate).toDateString().substring(4)}
                    </div>
                </div>
                <div>
                    <div className="org-usage-label">Term End Date:</div>
                    <div className="sf-inline">
                        {new Date(termEndDate).toDateString().substring(4)}
                    </div>
                </div>
            </div>
            <div>
                <div className="org-subscription-title">Payment History</div>
                <hr />
                <div className="org-sub-info-section">
                    <p className="subscription-note">Please contact your Splunk account team.</p>
                </div>
            </div>
        </div>
    );
};

export default LOSubscriptionInfo;
