'use strict';
import templateUrl from './fancyMenuInput.html';

/**
 * The input controlling the fancy menu, used to capture keyboard events to
 * control the menu
 *
 * ```
 *  <fancy-menu>
 *    ...
 *    <input
 *      type="text"
 *      ng-model="query"
 *      fancy-menu-input
 *    >
 *    ...
 *  </fancy-menu>
 * ```
 */
angular.module('sfx.ui').directive('fancyMenuInput', [
    function () {
        return {
            restrict: 'A',
            replace: true,
            require: ['^^fancyMenu', 'ngModel'],
            templateUrl,
            link: function ($scope, element, attrs, controllers) {
                $scope.menu = controllers[0];
            },
        };
    },
]);
