angular.module('signalview.orgIntegrations').run([
    '$rootScope',
    'zeroStateService',
    'integrationsPollingService',
    'currentUser',
    'auth',
    function ($rootScope, zeroStateService, integrationsPollingService, currentUser, auth) {
        let orgChangeUnregister, routeChangeUnregister;

        function register() {
            unregister(); // so that we don't duplicate the registration

            orgChangeUnregister = $rootScope.$on('current organization changed', handleOrgChange);
            routeChangeUnregister = $rootScope.$on('React:$routeChangeSuccess', handleRouteChange);
        }

        function unregister() {
            if (orgChangeUnregister) {
                orgChangeUnregister();
                orgChangeUnregister = null;
            }
            if (routeChangeUnregister) {
                routeChangeUnregister();
                routeChangeUnregister = null;
            }
            integrationsPollingService.stopPolling();
        }

        function handleOrgChange() {
            zeroStateService.queryForMetrics();
            zeroStateService.queryForDetectors();

            integrationsPollingService.startPolling();
        }

        function handleRouteChange() {
            // Check if any state has changed, while we're changing routes
            integrationsPollingService.checkIsIntegrationConfigured();
            if (zeroStateService.hasNoMetrics) {
                zeroStateService.queryForMetrics();
            }
            if (zeroStateService.hasNoDetectors) {
                zeroStateService.queryForDetectors();
            }
        }

        // this will prevent metric and dimension service from being called if orgId is not set
        $rootScope.$on('orgId is set', function () {
            // when user login
            auth.onAuth(register);

            // when user logout
            auth.onDeAuth(unregister);

            // see if user is already logged in when loaded
            // we don't actually care if it fails since we listen to auth token above when user login.
            currentUser.get().then(function () {
                register();
            });
        });
    },
]);
