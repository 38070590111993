import templateUrl from './integrationDocumentation.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .directive('integrationDocumentation', [
        '$location',
        '$compile',
        '$timeout',
        'integrationDocumentationService',
        function ($location, $compile, $timeout, integrationDocSvc) {
            return {
                replace: true,
                templateUrl,
                scope: {
                    org: '=',
                    apiToken: '@',
                    editable: '=',
                    service: '=',
                    integrationType: '=',
                    formTracker: '=',
                    confirmModal: '=',
                },
                link: function ($scope, element) {
                    const overviewTabName = 'Overview';
                    const metricsTabName = 'Metrics';

                    $scope.documentation = integrationDocSvc.getDocumentation(
                        $scope.service,
                        $scope.org,
                        $scope.apiToken
                    );
                    $scope.meta = integrationDocSvc.getMeta($scope.service) || {};

                    const setupTabName = $scope.documentation ? 'Setup' : 'Configure';
                    // if integration must be configured, we need to add the connect tab
                    if ($scope.integrationType === 'integrations') {
                        $scope.documentation = $scope.documentation || {};
                        if (!$scope.documentation[setupTabName]) {
                            $scope.documentation[setupTabName] = [];
                        }
                        $scope.documentation[setupTabName].push({ title: 'CONNECT' });
                    }
                    $scope.tabNames = Object.keys($scope.documentation);

                    $scope.activeTab =
                        $location.search().tab ||
                        ($scope.documentation[overviewTabName] ? overviewTabName : setupTabName);
                    $scope.setTab = function (tab) {
                        $scope.activeTab = tab;
                        $location.search('tab', tab);
                    };

                    if ($scope.meta.useLegacyBuild) {
                        integrationDocSvc.getMetrics($scope.service).then(function (metrics) {
                            if (!metrics || !metrics.length) {
                                return;
                            }
                            $scope.monitors = _.groupBy(metrics, (metric) => {
                                return _.get(metric, 'monitor', '');
                            });
                            if (!$scope.documentation[metricsTabName]) {
                                $scope.tabNames.push(metricsTabName);
                                $scope.documentation[metricsTabName] = [];
                            }
                            $scope.documentation[metricsTabName].unshift({ title: 'METRICS' });
                        });
                    }

                    function setClickHandlers() {
                        $timeout(function () {
                            // handle click to expand on images in the features subsection
                            element
                                .find('.description img')
                                .on('click', function () {
                                    const el = angular.element(this);
                                    el.toggleClass('large');
                                })
                                .each(function () {
                                    angular
                                        .element('<i class="icon-magnify"></i>')
                                        .appendTo(angular.element(this).parent());
                                });

                            // replace code blocks with our command-line directive
                            element.find('pre > code').each(function () {
                                const p = angular.element(this.parentElement);
                                const instruction = this.textContent
                                    .replace(/"/gi, "'")
                                    // Prevent double curly braces from getting interpreted in $compile.
                                    .replace(/{{/g, "{{ '{{' }}");

                                p.replaceWith(
                                    $compile(
                                        '<command-line instruction="' +
                                            instruction +
                                            '"></command-line>'
                                    )($scope)
                                );
                            });
                        }, 0);
                    }

                    $scope.$watch('activeTab', setClickHandlers);
                },
            };
        },
    ]);
