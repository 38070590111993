import { startCase } from 'lodash';

import dimensionsService from './dimensionsService';
import plotsService from './plotsService';

export const APM_IDENTIFIERS = ['ep_above_ft', 'd_above_ft'];

export default class ChartInformationService {
    constructor(detectorPriorityService, valueFormatter) {
        this.detectorPriorityService = detectorPriorityService;
        this.valueFormatter = valueFormatter;
    }

    getChartInformation(event, incidentInfo, orgId, plotColorMap) {
        const incidentRelatedPlots = plotsService.getPlotsForIncident(event, incidentInfo);
        const triggerValue = plotsService.getTriggerValue(
            incidentRelatedPlots,
            this.valueFormatter.formatValue
        );

        return {
            swatchClass: this._getSwatchClass(event.metadata.sf_severity),
            title: this.buildChartTitle(event, incidentInfo, incidentRelatedPlots),
            plots: plotsService.getPlotsInformation(
                incidentRelatedPlots,
                event,
                this.valueFormatter.formatValue,
                plotColorMap
            ),
            condition: event.metadata.sf_detectOnCondition,
            triggerValue: triggerValue,
            dimensions: dimensionsService.getForPlots(incidentRelatedPlots, orgId),
        };
    }

    buildChartTitle(event, incidentInfo, relatedPlots) {
        if (incidentInfo && APM_IDENTIFIERS.some((id) => id === incidentInfo.conditionIdentifier)) {
            const isRum = !event.metadata.service;
            return isRum
                ? this.buildForRUMDetector(event, relatedPlots)
                : this.buildForAPMDetector(event, relatedPlots);
        }
        return this.buildForInfrastructureDetector(relatedPlots, incidentInfo);
    }

    buildForAPMDetector(event, relatedPlots) {
        const service = event.metadata.service;
        const value = plotsService.getTriggerValue(relatedPlots, this.valueFormatter.formatValue);
        const threshold = plotsService.getThresholdValue(relatedPlots);

        return `APM Service Error Rate - ${service} (value: ${value}) > ${threshold}`;
    }

    buildForRUMDetector(event, relatedPlots) {
        const operation = startCase(event.metadata?.sf_operation);
        const value = plotsService.getTriggerValue(relatedPlots, this.valueFormatter.formatValue);
        const threshold = plotsService.getThresholdValue(relatedPlots);
        return `RUM ${operation} Error Rate (value: ${value}) > ${threshold}`;
    }

    buildForInfrastructureDetector(relatedPlots, incidentInfo) {
        if (incidentInfo && incidentInfo.builtInFunction && incidentInfo.readableCondition) {
            return incidentInfo.readableCondition;
        }

        const metricName = plotsService.getTriggerPlotName(relatedPlots);
        let value = plotsService.getTriggerValue(relatedPlots, this.valueFormatter.formatValue);
        if (value === '-') {
            value = 'N/A';
        }

        return metricName && value ? `${metricName} (value: ${value})` : '';
    }

    _getSwatchClass(severityName) {
        const severity = this.detectorPriorityService.getSeverityByDisplay(severityName);

        return this.detectorPriorityService.getSwatchClassBySeverity(severity);
    }
}
