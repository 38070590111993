export const orgMembershipService = [
    '$http',
    'currentUser',
    'API_URL',
    function ($http, currentUser, API_URL) {
        /* construct api url for teams resource */
        const apiUrl = API_URL + '/v2';
        const resourceName = 'organization/member';
        const resourceEndpoint = apiUrl + '/' + resourceName;

        // Service Public API
        return {
            deleteMember,
            getTeams,
            grantAdminAccess,
            revokeAdminAccess,
        };

        function getTeams() {
            return getOrgIdParams().then((params) =>
                $http.get(resourceEndpoint + '/teams', { params })
            );
        }

        function deleteMember(orgUserId) {
            return getOrgIdParams().then((params) =>
                $http.delete(resourceEndpoint + '/' + orgUserId, { params })
            );
        }

        /**
         * Retrieve current organization id for user session and
         * create a query parameter of the form ?organizationId={{orgId}}
         */
        function getOrgIdParams() {
            return currentUser.orgId().then((organizationId) => ({ organizationId }));
        }

        function grantAdminAccess(orgUserId) {
            return $http.put(`${resourceEndpoint}/${orgUserId}`, { admin: true });
        }

        function revokeAdminAccess(orgUserId) {
            return $http.put(`${resourceEndpoint}/${orgUserId}`, { admin: false });
        }
    },
];
