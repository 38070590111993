import templateUrl from './filterSignal.tpl.html';

angular.module('signalview.chartbuilder').component('filterSignal', {
    templateUrl,
    bindings: {
        getCurrentQuery: '<',
        tentativeQueryParam: '<?',
        objectTypes: '=?',
        sourceFilters: '<',
        dimensionKeys: '<?',
        disabled: '<?',
        matchingDimensions: '<?',
        showFilterButton: '<',
        showFilterOptions: '<?',
        activeVariable: '<',
        sourceSuggestions: '=?',
        getCurrentTimeRange: '<?',
    },
    controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
            $scope.showFilterInput = function () {
                $scope.$ctrl.isAddingNewFilter = true;
                const filterButton = $element.find('.plot-filter-button');
                filterButton.addClass('sf-hidden');
                const filterInput = $element.find('source-edit');
                filterInput
                    .removeClass('sf-invisible')
                    .find('input.resizable')
                    .focus()
                    .on('blur.filter', function (evt) {
                        // Unless user is selecting a value from the suggestions
                        if (angular.element(evt.relatedTarget).hasClass('suggestions-container')) {
                            return;
                        }

                        $scope.$ctrl.tentativeQueryParam = '';
                        $scope.$ctrl.isAddingNewFilter = false;
                        filterInput.addClass('sf-invisible');
                        filterButton.removeClass('sf-hidden');
                        filterInput.off('blur.filter');
                    });
            };
        },
    ],
});
