import templateUrl from './aggregationConfiguration.tpl.html';

angular.module('signalview.analytics').directive('aggregationConfiguration', [
    '$q',
    'plotUtils',
    'userAnalytics',
    'parseDuration',
    'calendarWindowUtil',
    'suggestAPIService',
    'featureEnabled',
    function (
        $q,
        plotUtils,
        userAnalytics,
        parseDuration,
        calendarWindowUtil,
        suggestAPIService,
        featureEnabled
    ) {
        return {
            scope: {
                aggDef: '=aggregationDefinition',
                confirmAndClose: '=?',
                fnType: '=?',
                currentQuery: '=?',
                isCounter: '=?',
                isCalendarWindowApplicable: '=?',
                isBlockVisible: '=?',
                plot: '=',
                fnConfig: '=',
                calendarWindowTimezone: '=',
                getCurrentTimeRange: '<?',
                isDetectorPlot: '<?',
            },
            templateUrl,
            link: function (scope) {
                scope.model = {};
                scope.getGroupBySuggestions = function (v) {
                    if (!plotUtils.isAliasedRegExStyle(scope.plot)) {
                        let start = 0;
                        let end = 0;
                        if (scope.getCurrentTimeRange) {
                            const range = scope.getCurrentTimeRange();
                            start = range.start;
                            end = range.end;
                        }
                        return suggestAPIService.getSignalFlowSuggest({
                            programs: scope.currentQuery(),
                            property: null,
                            partialInput: v,
                            limit: 10,
                            additionalFilters: null,
                            additionalReplaceOnlyFilters: null,
                            preferredSuggestions: null,
                            restrictResultsToPreferred: false,
                            extraQuery: null,
                            startTime: start,
                            endTime: end,
                        });
                    } else {
                        const rx = new RegExp(v, 'i');
                        const results = plotUtils.getPlotAliases(scope.plot).filter(function (v) {
                            return v.match(rx);
                        });
                        return $q.when(results);
                    }
                };

                scope.$watch('aggDef.options.amount', function (current, last) {
                    if (scope.aggDef) {
                        const options = scope.aggDef.options;
                        if (angular.isUndefined(current)) {
                            options.amount = last;
                        }
                        options.transformTimeRange = (options.amount || 1) + options.unit;
                        sendTimeRangeSelection(options.transformTimeRange);
                    }
                });

                scope.$watch('aggDef.options.unit', function () {
                    if (scope.aggDef) {
                        const options = scope.aggDef.options;
                        options.transformTimeRange = (options.amount || 1) + options.unit;
                        sendTimeRangeSelection(options.transformTimeRange);
                    }
                });

                function sendTimeRangeSelection(transformTimeRange) {
                    const timeRangeInMs = parseDuration(transformTimeRange);
                    userAnalytics.event(
                        'aggregation-selection',
                        'timeframe-changed',
                        timeRangeInMs
                    );
                }

                if (scope.fnConfig && calendarWindowUtil.isCalendarWindowType(scope.fnConfig)) {
                    scope.$watch('fnConfig.options.windowType', function () {
                        userAnalytics.event(
                            'aggregation-selection',
                            'window-type-selected',
                            scope.fnConfig.options.windowType
                        );
                    });
                }

                scope.onGroupBySuggested = function (groupby) {
                    if (!scope.aggDef.options.aggregateGroupBy) {
                        scope.aggDef.options.aggregateGroupBy = [];
                    }
                    scope.aggDef.options.aggregateGroupBy.push({
                        value: groupby,
                    });
                };

                scope.onKeyPressed = function (ev) {
                    if (ev.keyCode === 13) {
                        if (scope.confirmAndClose) {
                            scope.confirmAndClose();
                        }
                    }
                };

                // Feature flag and detector check for Dashboard Time Window
                scope.dashboardTimeWindowEnabled =
                    featureEnabled('dashboardTimeWindow') && !scope.isDetectorPlot;

                scope.isPercentileType = () => scope.fnConfig.type === 'PERCENTILE';
            },
        };
    },
]);
