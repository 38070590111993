import templateUrl from './alertCirclesPanel.tpl.html';

angular.module('signalview.alertsOverview').directive('alertCirclesPanel', [
    function () {
        return {
            restrict: 'E',
            scope: {
                aggregations: '=',
                severityFilter: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.containerClick = function () {
                    // when clicking on container, clear the severity filter (if exists)
                    if ($scope.severityFilter) {
                        $scope.severityClick($scope.severityFilter);
                    }
                };

                $scope.severityClick = function (severity) {
                    $scope.$emit('severity panel clicked', severity);
                };
            },
        };
    },
]);
