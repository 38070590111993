'use strict';

angular.module('sfx.data').factory('roleService', [
    'sfxApi',
    'createMetabaseObjectService',
    '$log',
    function (sfxApi, createMetabaseObjectService, $log) {
        const api = createMetabaseObjectService(sfxApi.role);

        api.orgAdminRole = (orgId) => {
            return api
                .unrestrictedSearch(`sf_role:"admin" AND sf_roleTargetId:"${orgId}"`)
                .then((adminRoles) => {
                    if (adminRoles.count !== 1) {
                        $log.error('Expected only on admin role type for the org', adminRoles);
                    }

                    return adminRoles.rs[0];
                });
        };

        return api;
    },
]);
