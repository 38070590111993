import {
    convertMSToString,
    convertStringToMS,
} from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { CALENDAR_WINDOW_CONSTANTS } from '../../analytics/util/calendarWindowConstants';
import { DIRECTION_TYPE } from '../../../common/consts';
import { DEFAULT_PERCENTILE_VALUE } from '../../analytics/block/PERCENTILE/PercentileController';

angular.module('signalflowv2').service('blockConversionFunctions', [
    'blockConversionDurationParser',
    'blockArgumentUtils',
    'blockConversionCalendarWindowParser',
    'SIGNALFLOW_CONSTANTS',
    function (
        blockConversionDurationParser,
        blockArgumentUtils,
        blockConversionCalendarWindowParser,
        SIGNALFLOW_CONSTANTS
    ) {
        const AGGREGATION_FIELD = 'by';
        const TRANSFORMATION_FIELD = 'over';
        const CALENDAR_WINDOW_TRANSFORMATION_FIELDS = [
            'windowType',
            'cycle',
            'cycle_start',
            'partial_values',
            'shift_cycles',
        ];
        const AGGREGATION_OR_TRANSFORMATION_ARGUMENTS = [
            AGGREGATION_FIELD,
            TRANSFORMATION_FIELD,
        ].concat(CALENDAR_WINDOW_TRANSFORMATION_FIELDS);
        const AGGREGATION_ALLOWED_BUT_NOT_TRANSFORMATION = ['COUNT', 'RATE'];
        const { METRIC_TYPE } = SIGNALFLOW_CONSTANTS;

        function checkAggregationTransformationOnly(block) {
            blockArgumentUtils.checkAllowedArguments(
                block,
                AGGREGATION_OR_TRANSFORMATION_ARGUMENTS
            );
        }

        function checkNoArguments(block) {
            blockArgumentUtils.checkAllowedArguments(block, []);
        }

        function getBaseObject(type) {
            return {
                direction: {
                    type: 'aggregation',
                    options: {
                        aggregateGroupBy: [],
                        transformTimeRange: null,
                    },
                },
                fn: {
                    type: type,
                    options: {},
                },
            };
        }

        function toNumber(arg) {
            let multiplier = 1;

            if (arg.type === 'unary_expression') {
                if (arg.operation === 'NEGATE') {
                    multiplier = -1;
                    arg = arg.expression;
                }
            }

            if (arg.type === 'long' || arg.type === 'double') {
                return multiplier * arg.value;
            } else {
                throw new Error('Unexpected non-number value for an argument');
            }
        }

        function toNumberOrRaise(rawValue, functionName, argName) {
            try {
                return toNumber(rawValue);
            } catch (e) {
                throw new Error(
                    `Conversion requires that "${functionName}" parameter "${argName}" be a number.`
                );
            }
        }

        function assertArgPresent(block, functionName, argName) {
            if (!block.args[argName]) {
                throw new Error(
                    `Conversion requires that "${functionName}" parameter "${argName}" be present.`
                );
            }
        }

        function handleAggregation(baseObject, block) {
            baseObject.direction.type = 'aggregation';
            if (block.args && block.args.by) {
                const groupBys = block.args.by;
                if (groupBys && groupBys.type !== 'none') {
                    if (angular.isArray(groupBys.items)) {
                        baseObject.direction.options.aggregateGroupBy = groupBys.items.map((gb) => {
                            return {
                                value: gb.value,
                            };
                        });
                    } else if (groupBys && typeof groupBys.type === 'string') {
                        baseObject.direction.options.aggregateGroupBy.push({
                            value: groupBys.value,
                        });
                    } else {
                        throw new Error('Could not determine what to group by!');
                    }
                }
            }
        }

        function handleCalendarWindowTransformation(baseObject, block) {
            baseObject.direction.type = 'transformation';
            baseObject.fn.options.windowType = blockConversionCalendarWindowParser.getWindowType();
            baseObject.fn.options.cycle = blockConversionCalendarWindowParser.getCycle(
                block.args.cycle.value
            );
            let persistedCycleStart;
            let isPartial = false;
            let shiftCount = 0;
            if (!block.args.cycle_start) {
                persistedCycleStart = blockConversionCalendarWindowParser.getDefaultCycleStart(
                    baseObject.fn.options.cycle
                );
            } else {
                persistedCycleStart = block.args.cycle_start.value;
            }

            if (typeof persistedCycleStart !== 'string') {
                throw new Error(
                    `Transformation over a calendar window cannot be represented in Plot Builder. cycle_start: ${persistedCycleStart} is not supported.`
                );
            }

            const cycleStartObject = blockConversionCalendarWindowParser.getCycleStartObject(
                baseObject.fn.options.cycle,
                persistedCycleStart
            );

            if (block.args.partial_values) {
                isPartial = blockConversionCalendarWindowParser.getPartialValueFlag(
                    block.args.partial_values.value
                );
            }

            if (block.args.shift_cycles && isPartial) {
                throw new Error(
                    'Transform over a calendar window cannot be represented in Plot Builder. shift_cycles cannot be present when partial values are enabled'
                );
            }

            baseObject.fn.options.cycleStart = cycleStartObject.cycleStart;
            baseObject.fn.options.minuteOfHour = cycleStartObject.minuteOfHour;
            baseObject.fn.options.hourMeridian = cycleStartObject.hourMeridian;

            // isPartial represents the signalflow text's value
            // If true, then we want to show partial values.
            // Else, we want to hide partial values
            baseObject.fn.options.hidePartial = !isPartial;

            if (block.args.shift_cycles) {
                shiftCount = blockConversionCalendarWindowParser.getShiftCycle(
                    block.args.shift_cycles.value
                );
            }
            baseObject.fn.options.isTimeShift = !!(shiftCount > 0);
            baseObject.fn.options.shiftCycleCount = shiftCount;

            return baseObject;
        }

        function getAggregationObject(block, type) {
            const baseObject = getBaseObject(type);
            handleAggregation(baseObject, block);
            return baseObject;
        }

        function getAggregationTransformationObject(block, type, metricType) {
            const baseObject = getBaseObject(type);
            if (!block.args) {
                return baseObject;
            }

            if (block.args.over) {
                if (
                    AGGREGATION_ALLOWED_BUT_NOT_TRANSFORMATION.indexOf(type) !== -1 &&
                    metricType !== METRIC_TYPE.HISTOGRAM
                ) {
                    throw new Error(
                        `Transformations of ${type} cannot be represented in the Plot Builder.`
                    );
                }
                const value = block.args.over.value;
                if (block.args.over.type === 'string') {
                    baseObject.direction.type = DIRECTION_TYPE.TRANSFORMATION;
                    const amount = blockConversionDurationParser.getAmount(value);
                    const unit = blockConversionDurationParser.getUnit(value);
                    if (blockConversionDurationParser.isRepresentableDuration(value)) {
                        baseObject.direction.options.transformTimeRange = value;
                        baseObject.direction.options.amount = amount;
                        baseObject.direction.options.unit = unit;
                    } else {
                        throw new Error(
                            `Transform duration ${value} cannot be represented in Plot Builder. Supported duration values are s (seconds), m (minutes), ...`
                        );
                    }
                } else if (block.args.over.args) {
                    const { default: defaultTime, key: dashboardWindowKey } = block.args.over.args;
                    baseObject.direction.type = DIRECTION_TYPE.TRANSFORMATION;
                    baseObject.fn.options.windowType =
                        CALENDAR_WINDOW_CONSTANTS.ANALYTICS_WINDOW_TYPES.DASHBOARD_WINDOW;
                    baseObject.fn.options.programArgs = {
                        [dashboardWindowKey.value]: defaultTime.value,
                    };
                } else {
                    throw new Error(
                        `Transform duration ${value} cannot be represented in Plot Builder. Supported duration values are s (seconds), m (minutes), ...`
                    );
                }
            } else if (block.args.cycle) {
                handleCalendarWindowTransformation(baseObject, block);
            } else {
                handleAggregation(baseObject, block);
            }
            return baseObject;
        }

        function handleAboveBelow(block) {
            blockArgumentUtils.checkAllowedArguments(block, [
                'include',
                'clamp',
                'limit',
                'inclusive',
            ]);
            if (block.args.include && block.args.include.type !== 'boolean') {
                throw new Error('Conversion requires that include be a boolean.');
            }
            if (block.args.clamp && block.args.clamp.type !== 'boolean') {
                throw new Error('Conversion requires that clamp be a boolean.');
            }
            let limit;
            if (block.args.limit) {
                try {
                    limit = toNumber(block.args.limit);
                } catch (e) {
                    throw new Error('Conversion requires that limit be a number.');
                }
            }

            const inclusive = !!(block.args.inclusive && block.args.inclusive.value === true);
            const clamp = !!(block.args.clamp && block.args.clamp.value === true);
            const _ = getBaseObject('EXCLUDE');
            _.fn.options.inclusive = !inclusive; // because the UI presents the inverse operation( include vs exclude )
            _.fn.options.action = clamp ? 'set to limit' : 'drop';

            if (block.functionName === 'above') {
                _.fn.options.low = limit;
                _.fn.options.mode = 'below';
            } else if (block.functionName === 'below') {
                _.fn.options.high = limit;
                _.fn.options.mode = 'above';
            } else {
                throw new Error('Uninterpretable function name passed to exclude parsing.');
            }

            return _;
        }

        function above(block) {
            return handleAboveBelow(block);
        }

        function abs(block) {
            checkNoArguments(block);
            return getBaseObject('ABS');
        }

        function below(block) {
            return handleAboveBelow(block);
        }

        function between(block) {
            return handleBetween(block);
        }

        function not_between(block) {
            return handleBetween(block);
        }

        function handleBetween(block) {
            blockArgumentUtils.checkAllowedArguments(block, [
                'low_inclusive',
                'high_inclusive',
                'clamp',
                'low_limit',
                'high_limit',
            ]);
            if (block.args.low_inclusive && block.args.low_inclusive.type !== 'boolean') {
                throw new Error('Conversion requires that low_inclusive be a boolean.');
            }
            if (block.args.high_inclusive && block.args.high_inclusive.type !== 'boolean') {
                throw new Error('Conversion requires that high_inclusive be a boolean.');
            }
            if (block.args.clamp && block.args.clamp.type !== 'boolean') {
                throw new Error('Conversion requires that clamp be a boolean.');
            }
            let low_limit;
            if (block.args.low_limit) {
                try {
                    low_limit = toNumber(block.args.low_limit);
                } catch (e) {
                    throw new Error('Conversion requires that low_limit be a number.');
                }
            }
            let high_limit;
            if (block.args.high_limit) {
                try {
                    high_limit = toNumber(block.args.high_limit);
                } catch (e) {
                    throw new Error('Conversion requires that high_limit be a number.');
                }
            }

            const low_inclusive = !!(
                block.args.low_inclusive && block.args.low_inclusive.value === true
            );
            const high_inclusive = !!(
                block.args.high_inclusive && block.args.high_inclusive.value === true
            );
            if (low_inclusive !== high_inclusive) {
                throw new Error('Inclusiveness must match for conversion to occur.');
            }
            const inclusive = low_inclusive;
            const clamp = !!(block.args.clamp && block.args.clamp.value === true);
            const _ = getBaseObject('EXCLUDE');
            _.fn.options.inclusive = !inclusive; // because the UI presents the inverse operation( include vs exclude )
            _.fn.options.action = clamp ? 'set to limit' : 'drop';
            _.fn.options.low = low_limit;
            _.fn.options.high = high_limit;
            if (block.functionName === 'between') {
                _.fn.options.mode = 'out of range';
            } else if (block.functionName === 'not_between') {
                _.fn.options.mode = 'within range';
                _.fn.options.action = 'drop'; // always have to drop because within range cannot determine threshold
            } else {
                throw new Error('Uninterpretable function name passed to between parsing.');
            }

            return _;
        }

        function bottom(block) {
            blockArgumentUtils.checkAllowedArguments(block, [
                AGGREGATION_FIELD,
                'count',
                'percentage',
            ]);
            const _ = getAggregationObject(block, 'BOTTOMN');
            if (block.args.count) {
                _.fn.options.count = block.args.count.value;
            } else if (block.args.percentage) {
                _.fn.options.mode = 'percent';
                _.fn.options.count = block.args.percentage.value * 100;
            }

            return _;
        }

        function ceil(block) {
            checkNoArguments(block);
            return getBaseObject('CEIL');
        }

        function count(block, metricType) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'COUNT', metricType);
        }

        function delta(block) {
            checkNoArguments(block);
            return getBaseObject('DELTA');
        }

        function floor(block) {
            checkNoArguments(block);
            return getBaseObject('FLOOR');
        }

        function integrate(block) {
            checkNoArguments(block);
            return getBaseObject('INTEGRATE');
        }

        function log(block) {
            checkNoArguments(block);
            return getBaseObject('LOG');
        }

        function log10(block) {
            checkNoArguments(block);
            return getBaseObject('LOG10');
        }

        function max(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'MAX');
        }

        function mean(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'MEAN');
        }

        function mean_plus_stddev(block) {
            blockArgumentUtils.checkAllowedArguments(block, [
                AGGREGATION_FIELD,
                TRANSFORMATION_FIELD,
                'stddevs',
            ]);
            const _ = getAggregationTransformationObject(block, 'MEAN_STDDEV');
            _.fn.options.stddevcount = angular.isDefined(block.args.stddevs)
                ? parseInt(toNumber(block.args.stddevs), 10)
                : 1;
            return _;
        }

        function min(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'MIN');
        }

        function percentile(block, metricType) {
            const ALLOWED_ARGUMENTS =
                metricType === METRIC_TYPE.HISTOGRAM
                    ? AGGREGATION_OR_TRANSFORMATION_ARGUMENTS
                    : [AGGREGATION_FIELD, TRANSFORMATION_FIELD];
            blockArgumentUtils.checkAllowedArguments(block, [...ALLOWED_ARGUMENTS, 'pct']);
            const _ = getAggregationTransformationObject(block, 'PERCENTILE');

            if (angular.isDefined(block.args.pct)) {
                const floatPercentage = parseFloat(block.args.pct.value);
                if (floatPercentage > 99.99 || floatPercentage < 1) {
                    throw new Error('Percentage must be between 1 and 99.99.');
                }
                _.fn.options.percentile = floatPercentage;
            } else {
                _.fn.options.percentile = DEFAULT_PERCENTILE_VALUE;
            }

            return _;
        }

        function pow(block) {
            blockArgumentUtils.checkAllowedArguments(block, ['exponent', 'base']);
            const _ = getBaseObject('POW');
            if (block.args.exponent) {
                _.fn.options.powerType = 'exponent';
                _.fn.options.powerAmount = parseInt(block.args.exponent.value, 10);
            } else if (block.args.base) {
                _.fn.options.powerType = 'base';
                _.fn.options.powerAmount = parseInt(block.args.base.value, 10);
            } else {
                console.error('Unrecognized power block arguments');
            }
            return _;
        }

        function rate(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationObject(block, 'RATE');
        }

        function rateofchange(block) {
            checkNoArguments(block);
            return getBaseObject('RATEOFCHANGE');
        }

        function scale(block) {
            blockArgumentUtils.checkAllowedArguments(block, ['multiple']);
            const _ = getBaseObject('SCALE');
            const mult = block.args.multiple;
            if (mult) {
                _.fn.options.scaleAmount = toNumber(mult);
            } else {
                _.fn.options.scaleAmount = 1;
            }
            return _;
        }

        function sqrt(block) {
            checkNoArguments(block);
            return getBaseObject('SQRT');
        }

        function stddev(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'STDDEV');
        }

        function sum(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'SUM');
        }

        function timeshift(block) {
            blockArgumentUtils.checkAllowedArguments(block, ['offset']);
            const _ = getBaseObject('TIMESHIFT');
            const value = block.args.offset.value;
            let rangeStr;
            const type = block.args.offset.type;
            if (type === 'long') {
                rangeStr = convertMSToString(value);
            } else if (type === 'string') {
                rangeStr = value;
            } else {
                throw new Error('Unrecognized timeshift argument type.');
            }

            _.fn.options.milliseconds = convertStringToMS(rangeStr) || null;
            return _;
        }

        function top(block) {
            blockArgumentUtils.checkAllowedArguments(block, [
                AGGREGATION_FIELD,
                'count',
                'percentage',
            ]);
            const _ = getAggregationObject(block, 'TOPN');
            if (block.args.count) {
                _.fn.options.count = block.args.count.value;
            } else if (block.args.percentage) {
                _.fn.options.mode = 'percent';
                _.fn.options.count = block.args.percentage.value * 100;
            }
            return _;
        }

        function ewma(block) {
            // this is a bit of a hack because double ewma uses ewma.
            blockArgumentUtils.checkAllowedArguments(block, [
                'over',
                'alpha',
                'beta',
                'trend_over',
                'forecast',
                'damping',
            ]);
            const _ = getBaseObject('EWMA');
            if (block.args.over) {
                throw new Error(
                    'The "over" parameter to function ewma cannot be represented in Plot Builder.'
                );
            }
            _.fn.options.smoothingLevel = 1;
            if (block.args.alpha) {
                _.fn.options.alpha = block.args.alpha.value;
            }
            if (block.args.beta) {
                _.fn.options.beta = block.args.beta.value;
            }
            return _;
        }

        function double_ewma(block) {
            const _ = ewma(block);
            if (block.args.over || block.args.trend_over) {
                throw new Error(
                    'The "over" and "trend_over" parameters to function double_ewma cannot be represented in Plot Builder.'
                );
            }
            _.fn.options.smoothingLevel = 2;
            if (block.args.forecast) {
                _.fn.options.forecast = block.args.forecast.duration.value;
            }
            if (block.args.damping) {
                _.fn.options.damping = block.args.damping.value;
            }
            return _;
        }

        function variance(block) {
            checkAggregationTransformationOnly(block);
            return getAggregationTransformationObject(block, 'VARIANCE');
        }

        function equals(block) {
            assertArgPresent(block, 'equals', 'value');
            const value = toNumberOrRaise(block.args.value, 'equals', 'value');

            const functionObject = getBaseObject('EXCLUDE');
            // exclude values which are...
            functionObject.fn.options.mode = 'out of range';
            // .equals() does drop by default and does not have a way to customize that behavior
            functionObject.fn.options.action = 'drop';
            functionObject.fn.options.low = value;
            functionObject.fn.options.high = value;
            return functionObject;
        }

        function not_equals(block) {
            assertArgPresent(block, 'not_equals', 'value');
            const value = toNumberOrRaise(block.args.value, 'not_equals', 'value');

            const functionObject = getBaseObject('EXCLUDE');
            // exclude values which are...
            functionObject.fn.options.mode = 'within range';
            functionObject.fn.options.low = value;
            functionObject.fn.options.high = value;
            return functionObject;
        }

        return {
            above,
            abs,
            below,
            between,
            bottom,
            ceil,
            count,
            delta,
            double_ewma,
            equals,
            ewma,
            floor,
            integrate,
            log,
            log10,
            max,
            mean_plus_stddev,
            mean,
            min,
            not_between,
            not_equals,
            percentile,
            pow,
            rate,
            rateofchange,
            scale,
            sqrt,
            stddev,
            sum,
            timeshift,
            top,
            variance,
        };
    },
]);
