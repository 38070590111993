import templateUrl from './booleanPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('booleanPickerController', [
        '$scope',
        function ($scope) {
            $scope.enumValues = [
                { value: false, displayName: 'No' },
                { value: true, displayName: 'Yes' },
            ];

            const $ctrl = this;
            $ctrl.$onInit = $onInit;

            function $onInit() {
                selectValue($scope.$ctrl.model);
            }

            function selectValue(m) {
                let model = null;
                $scope.enumValues.some(function (val) {
                    if (val.value === m) {
                        model = val;
                        return true;
                    }
                    return false;
                });

                $scope.localValue = {
                    value: model || $scope.enumValues[0],
                };
            }

            $scope.$watch('localValue.value', function (v) {
                $scope.$ctrl.onTypedUpdate({ value: v.value, validity: true });
            });

            $scope.$ctrl.$onChanges = function (changes) {
                if (changes.model) {
                    const value = changes.model.currentValue;
                    selectValue(value);
                }
            };
        },
    ])
    .component('booleanPicker', {
        replace: true,
        controller: 'booleanPickerController',
        bindings: {
            model: '<',
            definition: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
