export const nestedGroupBy = [
    '_',
    function (_) {
        /**
         * Perform group by recursively from a given list of groupBy properties.
         */
        return function (list, groupBy) {
            const result = { root: list };

            // current maintain each level of of group that are being processed (break down further)
            let current = [result];

            for (let i = 0; i < groupBy.length; i++) {
                const newCurrent = [];

                for (let j = 0; j < current.length; j++) {
                    const currentGroup = current[j];
                    const keys = Object.keys(currentGroup);

                    for (let k = 0; k < keys.length; k++) {
                        const key = keys[k];

                        currentGroup[key] = _.groupBy(currentGroup[key], groupBy[i]);
                        newCurrent.push(currentGroup[key]);
                    }
                }
                current = newCurrent;
            }
            return result.root;
        };
    },
];
