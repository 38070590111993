import templateUrl from './buttonToInput.tpl.html';

angular.module('sfx.ui').component('buttonToInput', {
    templateUrl,
    bindings: {
        tagOrMetric: '<',
        remove: '<',
        focusInput: '<',
        integrationForm: '<',
        editable: '<',
    },

    controller: [
        '$element',
        function ($element) {
            const ctrl = this;
            ctrl.toggleButton = toggleButton;
            ctrl.completeEdit = completeEdit;

            function toggleButton(editMode) {
                ctrl.tagOrMetric.editMode = !!editMode;

                if (editMode) {
                    ctrl.focusInput($element, '.metric-tag-input');
                }
            }

            function completeEdit(ev) {
                if (isTabOrEnter(ev)) {
                    toggleButton(false);
                    ctrl.integrationForm.$setDirty(true);
                    if (!ctrl.tagOrMetric.name) {
                        ctrl.remove(ctrl.tagOrMetric);
                    }
                }
            }

            function isTabOrEnter(ev) {
                return ev.keyCode === 13 || ev.keyCode === 9;
            }
        },
    ],
});
