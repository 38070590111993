export default [
    function () {
        const priorityMap = new Map(
            [
                { category: 'Navigation', priority: 2 },
                { category: 'APM', priority: 3 },
                { category: 'Navigator', priority: 4 },
                { category: 'Synthetics', priority: 5 },
                { category: 'RUM', priority: 6 },
                { category: 'Logs', priority: 7 },
                { category: 'Team', priority: 8 },
                { category: 'Dashboard', priority: 9 },
                { category: 'Detector', priority: 10 },
                { category: 'Chart', priority: 11 },
                { category: 'Metric', priority: 12 },
                { category: 'Property', priority: 13 },
                { category: 'Dimension', priority: 14 },
                { category: 'Tag', priority: 15 },
                { category: 'Integration', priority: 16 },
                { category: 'Administration', priority: 17 },
                { category: 'Action', priority: 18 },
                { category: 'Help', priority: 19 },
                { category: 'Metric Search', priority: 20 },
                { category: 'Trace Search', priority: 21 },
                { category: 'Log Search', priority: 22 },
            ].map(({ category, priority }) => [category.toLowerCase(), priority])
        );

        function reorganizeBuckets(res) {
            if (res && res.length > 0) {
                res.sort(function (a, b) {
                    return scoreBucketByPriority(a) - scoreBucketByPriority(b);
                });
            }
        }

        function scoreBucketByPriority(bucket) {
            const normalizedType = bucket.type && bucket.type.toLowerCase();
            const normalizedDomain = bucket.domain && bucket.domain.toLowerCase();
            const normalizedKey = bucket.key && bucket.key.toLowerCase();

            return (
                (normalizedType &&
                    priorityMap.has(normalizedType) &&
                    priorityMap.get(normalizedType)) ||
                (normalizedDomain &&
                    priorityMap.has(normalizedDomain) &&
                    priorityMap.get(normalizedDomain)) ||
                (normalizedKey &&
                    priorityMap.has(normalizedKey) &&
                    priorityMap.get(normalizedKey)) ||
                priorityMap.get('help') - 1
            );
        }

        return { reorganizeBuckets };
    },
];
