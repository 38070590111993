import { isObjectAndHasExactlyListedKeys } from '../../../../app/signalflowv2/blockMapUtils/signalflowAstNodeVerifiers.js';

import {
    extractStream,
    extractGroupBy,
    extractTimeSpan,
    extractNumber,
    extractLasting,
    extractBoolean,
    extractString,
    extractPercent,
    extractPercentile,
} from '../../../../app/signalflowv2/blockMapUtils/signalflowAstFunctionArgumentsExtractors.js';

import {
    extractApmServiceEndpoints,
    extractApmEnvironment,
    extractApmCustomFilters,
} from '../../../../app/signalflowv2/blockMapUtils/signalflowAstApmFilterExtractors';

import {
    extractApmV2ServiceEndpoints,
    extractApmV2Workflows,
    extractApmV2Environment,
} from '../../../../app/signalflowv2/blockMapUtils/signalflowAstApmV2FilterExtractors';

const DETECT_EXPRESSION_KEYS = ['expressionText', 'start', 'streamMethods', 'type', 'uniqueKey'];
const FUNCTION_EXPRESSION_KEYS = ['args', 'functionName', 'originalText', 'position', 'type'];

const PACKAGE_INFO = {
    package: 'signalfx',
    version: '0.0.1',
};

const FILTER_TYPES = {
    serviceEndpoints: 'SERVICE_ENDPOINTS',
    workflows: 'WORKFLOWS',
    environment: 'ENVIRONMENT',
    customFilters: 'FILTERS',
};

const DATATYPE_TO_EXTRACTOR_MAP = {
    Stream: (args, key) => extractStream(args[key], key),
    GroupBy: (args, key, defaultValue) => extractGroupBy(args[key], key, defaultValue),
    TimeSpan: (args, key, defaultValue) => extractTimeSpan(args[key], key, defaultValue),
    AutoResolveAfter: (args, key, defaultValue) => extractTimeSpan(args[key], key, defaultValue),
    Number: (args, key, defaultValue) => extractNumber(args[key], key, defaultValue),
    Lasting: (args, key, defaultValue) => extractLasting(args[key], key, defaultValue),
    Boolean: (args, key, defaultValue) => extractBoolean(args[key], key, defaultValue),
    String: (args, key, defaultValue) => extractString(args[key], key, defaultValue),
    Percent: (args, key, defaultValue) => extractPercent(args[key], key, defaultValue),
    Percentile: (args, key, defaultValue) => extractPercentile(args[key], key, defaultValue),
};

const FILTER_TYPE_TO_EXTRACTOR_MAP = {
    apm: {
        [FILTER_TYPES.serviceEndpoints]: (args, key) => extractApmServiceEndpoints(args[key], key),
        [FILTER_TYPES.environment]: (args, key) => extractApmEnvironment(args[key], key),
        [FILTER_TYPES.customFilters]: (args, key) => extractApmCustomFilters(args[key], key),
    },
    apm_v2: {
        [FILTER_TYPES.serviceEndpoints]: (args, key) =>
            extractApmV2ServiceEndpoints(args[key], key),
        [FILTER_TYPES.workflows]: (args, key) => extractApmV2Workflows(args[key], key),
        [FILTER_TYPES.environment]: (args, key) => extractApmV2Environment(args[key], key),
        [FILTER_TYPES.customFilters]: (args, key) => extractApmCustomFilters(args[key], key),
    },
};

angular
    .module('signalflowv2')
    .constant('PACKAGE_INFO', PACKAGE_INFO)
    .factory('blockMapToUIModelFunctionsPropertiesConverter', [
        'signalflowMetadataFinder',
        function (signalflowMetadataFinder) {
            return {
                canParse,
                getRuleProperties,
            };

            function canParse(statement, signalflowAst) {
                if (!isObjectAndHasExactlyListedKeys(statement, DETECT_EXPRESSION_KEYS)) {
                    return false;
                }

                const functionExpression = statement.start;
                if (
                    !isObjectAndHasExactlyListedKeys(
                        functionExpression,
                        FUNCTION_EXPRESSION_KEYS
                    ) ||
                    functionExpression.type !== 'user_function'
                ) {
                    return false;
                }

                const functionName = functionExpression.functionName;
                if (!signalflowMetadataFinder.findFunctionMetadata(functionName, signalflowAst)) {
                    return false;
                }

                return true;
            }

            function getRuleProperties(statement, signalflowAst) {
                if (!canParse(statement, signalflowAst)) {
                    throw new Error('Unsupported DETECT expression structure.');
                }

                assertNoMixedImports(signalflowAst);

                const functionExpression = statement.start;
                const functionName = functionExpression.functionName;
                const fnMetadata = signalflowMetadataFinder.findFunctionMetadata(
                    functionName,
                    signalflowAst
                );
                const moduleType = fnMetadata.module.moduleType;

                const extractedInputs = {};
                const extractedProperties = {};

                const functionParams = Object.keys(fnMetadata.inputs).filter(
                    (key) => !fnMetadata.inputs[key].isInvalidFuncParam
                );
                const argsExpression = functionExpression.args;

                assertNoUnsupportedInputs(functionExpression, functionParams);

                functionParams.forEach((key) => {
                    const fieldDescription = fnMetadata.inputs[key];
                    const dataType = fieldDescription.dataType.type;

                    if (argsExpression[key] === undefined && fieldDescription.optional) return;

                    if (dataType === 'Filter') {
                        const subFilters = fieldDescription.dataType.fillFromRuleProperties;
                        (subFilters || []).forEach((subFilter) => {
                            const extractor = getFilterExtractor(moduleType, subFilter.type, key);
                            extractedProperties[subFilter.value] = extractor(argsExpression, key);
                        });

                        extractedInputs[key] = fieldDescription.defaultValue;
                    } else {
                        const extractor = getExtractor(dataType, key);
                        extractedInputs[key] = extractor(
                            argsExpression,
                            key,
                            fieldDescription.defaultValue
                        );
                    }
                });

                return {
                    ...extractedProperties,
                    package: fnMetadata.package,
                    version: fnMetadata.version,
                    path: fnMetadata.path,
                    module: fnMetadata.module.name,
                    moduleImportAlias: fnMetadata.module.moduleImportAlias,
                    function: fnMetadata.name,
                    invalid: false,
                    inputs: extractedInputs,
                    type: 'Function',
                };
            }

            function assertNoMixedImports(signalflowAst) {
                const hasInfraImports =
                    signalflowMetadataFinder.signalflowContainsInfraImports(signalflowAst);
                const hasApmImports =
                    signalflowMetadataFinder.signalflowContainsApmImports(signalflowAst);
                if (hasInfraImports && hasApmImports) {
                    throw new Error('Mixed infra/apm detectors not allowed.');
                }
            }

            function assertNoUnsupportedInputs(functionExpression, functionParams) {
                const unknownInput = Object.keys(functionExpression.args).find(
                    (key) => !functionParams.includes(key)
                );

                if (unknownInput) {
                    throw new Error(`Unsupported function expression argument: "${unknownInput}"`);
                }
            }
        },
    ]);

function getExtractor(dataType, key) {
    const extractor = DATATYPE_TO_EXTRACTOR_MAP[dataType];
    if (!extractor) {
        throw new Error(`Unknown data type (${dataType}) for ${key}.`);
    }
    return extractor;
}

function getFilterExtractor(moduleType, filterType, key) {
    const extractor = FILTER_TYPE_TO_EXTRACTOR_MAP[moduleType][filterType];
    if (!extractor) {
        throw new Error(`Unknown filter type (${filterType}) for ${key}.`);
    }
    return extractor;
}
