import { APM_FILTER } from '../../app/detector/signal/apmFilterService';

export default [
    'traceAPIService',
    'DETECTOR_TYPES',
    'apmFilterService',
    'suggestAPIService',
    function (traceAPIService, DETECTOR_TYPES, apmFilterService, suggestAPIService) {
        const DEFAULT_SEARCH_WINDOW = 12 * 60 * 60 * 1000; // 12 hours
        const DEFAULT_RETENTION_WINDOW = 8 * 24 * 60 * 60 * 1000; // 8 days
        const AUTOSUGGEST_LIMIT = 100;

        return {
            getCachingApi,
            getServiceSuggestions,
            getOperationSuggestions,
            getTagSuggestions,
            getEnvironments,
            getEnvironmentSuggestions,
        };

        function getCachingApi(_environment) {
            let servicesPromise;
            let environment = _environment;

            // The operations suggestion endpoint with no service filter is pretty
            // slow and the endpoint doesn't accept a partial right now, so we are
            // eagerly loading the ouput in the hopes that it is done by the time
            // the user needs it. Would be great if we could stop doing this at some
            // point.
            let operationsPromise = getNoServiceSuggestions();

            return {
                getOperationSuggestions,
                getOperationAndEndpointSuggestions,
                getServiceSuggestions,
                getTagSuggestions(searchTerm, services, time) {
                    return getTagSuggestions(searchTerm, services, time, environment);
                },
                getEnvironments,
                setEnvironment,
            };

            function getOperationSuggestions(searchTerm) {
                return operationsPromise.then((operations) => {
                    const filter = getRegExp(searchTerm);

                    return operations.filter((operation) => {
                        return operation.match(filter);
                    });
                });
            }

            function getOperationAndEndpointSuggestions(searchTerm, services) {
                return traceAPIService.getOperations(environment, services).then((response) => {
                    const filter = getRegExp(searchTerm);

                    return {
                        endpoints: response.endpoints
                            .filter((e) => e.match(filter))
                            .sort(caseInsensitiveAlphaCompare),
                        operations: response.operations
                            .filter((o) => o.match(filter))
                            .sort(caseInsensitiveAlphaCompare),
                    };
                });
            }

            function getServiceSuggestions(searchTerm) {
                if (!servicesPromise) {
                    servicesPromise = traceAPIService
                        .getServices(environment)
                        .then(getServicesAsArray);
                }

                return servicesPromise.then((suggestions) => {
                    const filter = getRegExp(searchTerm);

                    return suggestions.filter((service) => {
                        return service.value.match(filter);
                    });
                });
            }

            function getNoServiceSuggestions() {
                return traceAPIService.getOperations(environment).then(getOperationsAsArray);
            }

            function setEnvironment(_environment) {
                environment = _environment;
                operationsPromise = getNoServiceSuggestions();
            }
        }

        function getServiceSuggestions(filter, environment) {
            traceAPIService.getServices(environment).then((services) => {
                return getServicesAsArray(services).filter((service) =>
                    service.value.match(filter)
                );
            });
        }

        function getOperationSuggestions(filter, environment) {
            return traceAPIService
                .getOperations(environment)
                .then(getOperationsAsArray)
                .then((operations) => operations.filter((op) => op.match(filter)));
        }

        function getEnvironments(apmVersion = DETECTOR_TYPES.APM) {
            const filterService = apmFilterService.forVersion(apmVersion);

            return suggestAPIService
                .getSignalFlowSuggest({
                    property: filterService.getKey(APM_FILTER.environment),
                    partialInput: null,
                    limit: 100,
                    additionalFilters: [
                        {
                            property: 'sf_metric',
                            values: [filterService.getKey(APM_FILTER.env_search_metric)],
                        },
                    ],
                    additionalReplaceOnlyFilters: [],
                    startTime: Date.now() - DEFAULT_RETENTION_WINDOW,
                    endTime: Date.now(),
                })
                .then((environments) => environments.sort());
        }

        function getEnvironmentSuggestions(searchTerm, apmVersion = DETECTOR_TYPES.APM_V2) {
            const filterService = apmFilterService.forVersion(apmVersion);

            return suggestAPIService
                .getSignalFlowSuggest({
                    property: filterService.getKey(APM_FILTER.environment),
                    partialInput: searchTerm,
                    limit: AUTOSUGGEST_LIMIT,
                    additionalFilters: [
                        {
                            property: 'sf_metric',
                            values: [filterService.getKey(APM_FILTER.env_search_metric)],
                        },
                    ],
                    additionalReplaceOnlyFilters: [],
                    startTime: Date.now() - DEFAULT_RETENTION_WINDOW,
                    endTime: Date.now(),
                })
                .then((environments) => environments.sort());
        }

        function getTagSuggestions(searchTerm, services, time, environment) {
            if (!time) {
                time = { gte: Date.now() - DEFAULT_SEARCH_WINDOW };
            }

            const options = { services, time, environment };

            return requestTagSuggestions(searchTerm, options).then((response) => {
                return response.sort(caseInsensitiveAlphaCompare).map((s) => ({ value: s }));
            });
        }

        /*
         * Helper to transform the API response into something that is a little
         * nicer to work with in our context.
         *
         * @param {object} services
         *   js object containing information objects keyed by service name
         *
         * @return
         *   array containing objects with:
         *     {string} value - service name
         *     {boolean} inferred - is service inferred
         *     {string} classList - space separated list of classes to apply to suggestion
         */
        function getServicesAsArray(services) {
            return Object.entries(services)
                .map(([service, info]) => {
                    // TODO: there should probably be a util for transforming lists of
                    // items into items that are friendly to both multiselectDropdown
                    // and dropdownSelector. For now this logic can live here, but
                    // it assumes to much about its eventual usage.
                    return {
                        value: service,
                        inferred: info.inferred,
                        classList: info.inferred ? 'trace-inferred-service-label' : '',
                    };
                })
                .sort((matchA, matchB) => matchA.value.localeCompare(matchB.value));
        }

        function getOperationsAsArray(apiResponse) {
            const jointSet = new Set([...apiResponse.operations, ...apiResponse.endpoints]);

            return Array.from(jointSet).sort((strA, strB) => strA.localeCompare(strB));
        }

        function requestTagSuggestions(searchTerm, { services, time, environment } = {}) {
            return traceAPIService.getSpanTags({
                services,
                time,
                partialUserInput: searchTerm,
                environment,
            });
        }

        function caseInsensitiveAlphaCompare(strA, strB) {
            // perform case insensitive sorting
            return strA.toLowerCase().localeCompare(strB.toLowerCase());
        }

        function getRegExp(searchTerm) {
            return new RegExp(searchTerm.replace(/\*/, '\\*'), 'gi');
        }
    },
];
