angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'blockService',
    function (chartTemplates, Chart, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Load Average')
                .mode('list')
                .type('line')
                .maxDecimalPlaces(3)
                .updateInterval(5000);

            chart
                .plot()
                .metric('load.shortterm')
                .name('01-min')
                .propertyFilters(filters)
                .addManipulation(blockService.mean.asAggregation());

            chart
                .plot()
                .metric('load.midterm')
                .name('05-min')
                .propertyFilters(filters)
                .addManipulation(blockService.mean.asAggregation());

            chart
                .plot()
                .metric('load.longterm')
                .name('15-min')
                .propertyFilters(filters)
                .addManipulation(blockService.mean.asAggregation());

            return chart;
        }

        chartTemplates.register('collectdLoadAverage', createChart);
    },
]);
