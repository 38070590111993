import { random } from 'lodash';

const doublePi = 628;

function SinusoidalGenerator(amplitude = 50) {
    this.amplitude = amplitude;
    this.x = random(0, doublePi);
}

// Make this interface similar to a built in JS generator
SinusoidalGenerator.prototype.next = function () {
    const pureValue = this.amplitude * Math.sin(this.x / 100) + this.amplitude * 2;
    const randomnessRange = random(0, this.amplitude);
    const semiRandomValue = random(pureValue - randomnessRange, pureValue + randomnessRange, true);
    this.x = (this.x + random(1, 3)) % doublePi;
    return { value: semiRandomValue };
};

export default SinusoidalGenerator;
