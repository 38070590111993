import React from 'react';
import PropTypes from 'prop-types';
import { BannerProvider } from '@splunk/olly-common/GettingStartedBanner';
import { gettingStartedBannerContentDashboards } from './dashboardGSBContent';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';
import { AngularInjector } from '../../../common/AngularUtils';

export default function DashboardBanner({ currentUser }) {
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    const themeKey = useLegacyThemeServiceKey();
    return (
        <ThemeProvider colorScheme={themeKey}>
            <BannerProvider
                currentUser={currentUser}
                themeKey={themeKey}
                gettingStartedBannerContent={gettingStartedBannerContentDashboards}
                userAnalytics={userAnalytics}
                dismissedPillarApiName="sf_uiOnboardingBannerDismissedDashboards"
            />
        </ThemeProvider>
    );
}

DashboardBanner.propTypes = {
    currentUser: PropTypes.object,
};
