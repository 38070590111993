import templateUrl from './eventPanelAlertTile.tpl.html';

angular.module('signalview.eventsPanel').directive('eventPanelAlertTile', [
    'alertTypeService',
    function (alertTypeService) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                event: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.getDescription = function () {
                    const anomalyState = $scope.event.properties.is;
                    return alertTypeService.getDisplayFor(anomalyState);
                };

                $scope.isClearingEvent = alertTypeService.isClearingEvent;
            },
        };
    },
]);
