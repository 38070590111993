const COMMON_HEADERS = {
    'X-SF-SOURCE': 'signalview',
};

export const createV2Service = [
    '$q',
    'currentUser',
    '$http',
    'API_URL',
    'EXPERIMENTAL_URL',
    function ($q, currentUser, $http, API_URL, EXPERIMENTAL_URL) {
        const apiUrl = API_URL + '/v2';
        const experimentalApiUrl = EXPERIMENTAL_URL + '/v2';

        return function (resourceName) {
            const resourceEndpoint = apiUrl + '/' + resourceName;
            const experimentalEndpoint = experimentalApiUrl + '/' + resourceName;

            function create(obj, params) {
                return currentUser
                    .orgId()
                    .then(function (orgId) {
                        params = params || {};
                        params.organizationId = orgId;

                        return $http.post(
                            resourceEndpoint,
                            obj,
                            addCommonHeaders({ params: params })
                        );
                    })
                    .then(function (response) {
                        return response.data;
                    });
            }

            function update(obj) {
                return currentUser
                    .orgId()
                    .then(function (orgId) {
                        return $http.put(
                            resourceEndpoint + '/' + obj.id,
                            obj,
                            addCommonHeaders({
                                params: {
                                    organizationId: orgId,
                                },
                            })
                        );
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            }

            function get(id, errorHandler) {
                return currentUser
                    .orgId()
                    .then(function (orgId) {
                        return $http.get(
                            resourceEndpoint + '/' + id,
                            addCommonHeaders({
                                params: {
                                    organizationId: orgId,
                                },
                            })
                        );
                    })
                    .then(
                        function (resp) {
                            return resp.data;
                        },
                        function (error) {
                            if (errorHandler) {
                                return errorHandler({
                                    fn: get,
                                    id: id,
                                    error: error,
                                });
                            } else {
                                return $q.reject(error);
                            }
                        }
                    );
            }

            // searchParams { query: string, limit: [optional], offset: [optional], orderBy: [optional] }
            // dashboards and dashboard groups do not take a query field, but rather
            // a name field. Use alternate functions such as dashboardV2Service#searchByAny
            // for queries not by name.
            function search(searchParams) {
                return currentUser
                    .orgId()
                    .then(function (orgId) {
                        const params = { organizationId: orgId };
                        angular.extend(params, searchParams);

                        return $http.get(resourceEndpoint, addCommonHeaders({ params: params }));
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            }

            function deleteResource(id) {
                return currentUser.orgId().then(function (orgId) {
                    return $http.delete(
                        resourceEndpoint + '/' + id,
                        addCommonHeaders({
                            params: {
                                organizationId: orgId,
                            },
                        })
                    );
                });
            }

            return {
                experimentalEndpoint,
                resourceEndpoint: resourceEndpoint,
                create: create,
                update: update,
                get: get,
                search: search,
                delete: deleteResource,
            };
        };
    },
];

export function addCommonHeaders(config) {
    return addHeaders(config, COMMON_HEADERS);
}

function addHeaders(config, headersToAdd) {
    const resultHeaders = config.headers || {};
    config.headers = angular.extend(resultHeaders, headersToAdd);
    return config;
}
