angular.module('signalview.detector.rule').constant('LEGACY_METADATA', {
    static: {
        path: {
            legacy: true,
            advanced: false,
            displayName: 'Static Threshold',
            description: 'Alert when a metric value crosses a static threshold.',
            whenToUse:
                'Works well for metrics with a static range of "good" and "bad" values, such as when SLA should be above 99.9%, latency should be between 200 and 300ms. For monitoring signals that are approaching a threshold value (such as CPU percentage approaching 90%), consider using Resource Running Out. To compare one signal with another, use Custom Threshold.',
            name: 'thresholds',
            infoUrl:
                'https://quickdraw.splunk.com/redirect/?product=Observability&location=staticthreshold.learnmore&version=current',
            type: 'infrastructure',
        },
        functions: [
            {
                function: {
                    displayName: 'Compare to Static Thresholds',
                    description:
                        'Triggers an alert when a signal goes above or below a static threshold',
                    type: 'static',
                    name: 'static',
                    inputs: {
                        targetPlot: {
                            description: 'Metric or expression to analyze',
                            displayName: 'Signal',
                        },
                        thresholdMode: {
                            description: 'What condition should trigger the alert',
                            displayName: 'Alert when',
                        },
                        above: {
                            description: 'Alert if signal is above this value',
                            displayName: 'Threshold',
                        },
                        below: {
                            description: 'Alert if signal is below this value',
                            displayName: 'Threshold',
                        },
                        lowerThreshold: {
                            displayName: 'Lower threshold',
                            description: 'The lower value in a threshold range',
                        },
                        upperThreshold: {
                            displayName: 'Upper threshold',
                            description: 'The upper value in a threshold range',
                        },
                        triggerMode: {
                            displayName: 'Trigger sensitivity',
                            description: 'When to trigger an alert',
                        },
                        duration: {
                            displayName: 'Duration',
                            description: 'Time period the signal must meet the threshold condition',
                        },
                        percentOfDuration: {
                            displayName: 'Percent of duration',
                            description:
                                'Percent of signal values in a time period that must meet the threshold condition.',
                            infoUrl:
                                'https://quickdraw.splunk.com/redirect/?product=Observability&location=staticthreshold.percentofduration&version=current',
                        },
                    },
                },
            },
        ],
    },
    dynamic: {
        path: {
            legacy: true,
            advanced: true,
            displayName: 'Custom Threshold',
            description: 'Alert when a metric value crosses a static threshold or another signal.',
            whenToUse:
                'Use this condition when you need to be alerted if one signal meets a condition based on the value of one or more other signals, or if you want to specify compound conditions using AND and OR operators.',
            name: 'dynamic',
            infoUrl:
                'https://quickdraw.splunk.com/redirect/?product=Observability&location=customthreshold.learnmore&version=current',
            type: 'infrastructure',
        },
        functions: [
            {
                function: {
                    displayName: 'Compare to Dynamic Thresholds',
                    description: 'Create detectors by comparing any signal in your chart.',
                    type: 'dynamic',
                    name: 'dynamic',
                    inputs: {
                        targetPlot: {
                            description: 'Metric or expression to analyze',
                            displayName: 'Signal',
                        },
                        thresholdMode: {
                            description: 'What condition should trigger the alert',
                            displayName: 'Alert when',
                        },
                        above: {
                            description: 'Alert if signal is above this value',
                            displayName: 'Threshold',
                        },
                        below: {
                            description: 'Alert if signal is below this value',
                            displayName: 'Threshold',
                        },
                        lowerThreshold: {
                            displayName: 'Lower threshold',
                            description: 'The lower value in a threshold range',
                        },
                        upperThreshold: {
                            displayName: 'Upper threshold',
                            description: 'The upper value in a threshold range',
                        },
                        triggerMode: {
                            displayName: 'Trigger sensitivity',
                            description: 'When to trigger an alert',
                        },
                        duration: {
                            displayName: 'Duration',
                            description: 'Time period the signal must meet the threshold condition',
                        },
                        percentOfDuration: {
                            displayName: 'Percent of duration',
                            description:
                                'Percent of signal values in a time period that must meet the threshold condition',
                            infoUrl:
                                'https://quickdraw.splunk.com/redirect/?product=Observability&location=customthreshold.percentofduration&version=current',
                        },
                    },
                },
            },
        ],
    },
});
