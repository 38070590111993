import orgUsageChartTemplateUrl from './orgUsageChart.tpl.html';

angular
    .module('signalview.orgUsageChart')

    .controller('OrgUsageChartController', [
        '$scope',
        '$log',
        'zeroStateService',
        'orgUsageService',
        'orgUsageModalService',
        'PRODUCT_NAME',
        function (
            $scope,
            $log,
            zeroStateService,
            orgUsageService,
            orgUsageModalService,
            PRODUCT_NAME
        ) {
            $scope.PRODUCT_NAME = PRODUCT_NAME;
            $scope.dropStatus = {
                isOpen: false,
            };
            $scope.fetching = true;

            $scope.zeroStateService = zeroStateService;

            $scope.planInfo = {};
            function onNoAccount() {
                $log.info('No account for org.');
                $scope.syntheticPlan = true;
                $scope.planInfo = {
                    lastActiveRatePlan: {
                        billCycleDay: 1,
                    },
                    quotas: {
                        DPM: null,
                        DPM_BURST: null,
                    },
                    orgId: $scope.orgId,
                };
            }

            $scope.getPlanInformation = function () {
                $scope.syntheticPlan = false;
                return $scope.planInfoSvc.then(
                    function (acct) {
                        $scope.fetching = false;
                        if (!acct) {
                            onNoAccount();
                        } else {
                            $scope.planInfo = acct;
                        }
                        return;
                    },
                    function () {
                        $scope.fetching = false;
                        onNoAccount();
                        return;
                    }
                );
            };

            $scope.setRange = function (r) {
                $scope.selectedRange = r;
            };

            function setupUsageRanges() {
                $scope.usageRanges = orgUsageService.constructValidPaymentIntervals(
                    $scope.planInfo,
                    3
                );
                $scope.selectedRange = $scope.usageRanges[0];
            }

            $scope.showUsageReports = orgUsageModalService.showUsageReports;

            $scope.primarySignalFlow = orgUsageService.generateSignalflow('DPS', $scope.orgId);
            $scope.maximumsSignalFlow = orgUsageService.generateSignalflow('DPS1M', $scope.orgId);

            // even if there is an error, set up usage ranges with defaults
            $scope.getPlanInformation().then(setupUsageRanges, setupUsageRanges);
        },
    ])

    .directive('orgUsageChart', function () {
        return {
            restrict: 'AE',
            replace: false,
            scope: {
                planInfoSvc: '=',
                orgId: '=',
            },
            templateUrl: orgUsageChartTemplateUrl,
            controller: 'OrgUsageChartController',
        };
    });
