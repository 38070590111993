// TODO: should be moved to @splunk/olly-common

export enum DetectorOrigin {
    STANDARD = 'Standard',
    AUTO_DETECT = 'AutoDetect',
    AUTO_DETECT_CUSTOMIZATION = 'AutoDetectCustomization',
}

export const DetectorOriginLabels = {
    [DetectorOrigin.STANDARD]: 'Standard',
    [DetectorOrigin.AUTO_DETECT]: 'AutoDetect',
    [DetectorOrigin.AUTO_DETECT_CUSTOMIZATION]: 'Customized AutoDetect',
};
