import templateUrl from './exitGuardModal.html';

export default [
    'sfxModal',
    function (sfxModal) {
        function openModal(onSave, onContinue, isValid = true) {
            return sfxModal.open({
                templateUrl,
                controller: [
                    '$scope',
                    '$q',
                    function ($scope, $q) {
                        $scope.isValid = isValid;

                        $scope.save = function () {
                            let promise = $q.when();
                            if (onSave) {
                                promise = onSave();
                            }

                            promise.then(function () {
                                $scope.$close();
                            });
                        };

                        $scope.continue = function () {
                            let promise = $q.when();
                            if (onContinue) {
                                promise = onContinue();
                            }

                            promise.then(function () {
                                $scope.$close();
                            });
                        };
                    },
                ],
                keyboard: false,
                backdrop: 'static',
            }).result;
        }

        return openModal;
    },
];
