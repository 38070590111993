import templateUrl from './tagRow.tpl.html';

angular.module('sfx.ui').component('sfxIntegrationAzureTagRow', {
    templateUrl,
    bindings: {
        tagId: '<',
        tagKey: '<',
        tagValue: '<',
        editable: '<',
        onChange: '&',
        onRemove: '&',
    },
    controller: class AzureTagFilters {
        static $inject = [];

        handleChange() {
            this.onChange({
                tagId: this.tagId,
                tagKey: this.tagKey,
                tagValue: this.tagValue,
            });
        }

        handleRemove() {
            this.onRemove({
                tagId: this.tagId,
            });
        }

        isValid(value) {
            return value.length > 0 && value.length <= 256;
        }
    },
});
