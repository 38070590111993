angular.module('signalflowv2').service('blockConversionDurationParser', function () {
    const RECOGNIZED_TIME_UNITS = ['s', 'm', 'h', 'd'];

    function getUnit(dur) {
        return dur.charAt(dur.length - 1);
    }

    function getAmount(dur) {
        return Number(dur.substring(0, dur.length - 1));
    }

    function isRepresentableDuration(dur) {
        const unit = getUnit(dur);
        return !isNaN(getAmount(dur)) && RECOGNIZED_TIME_UNITS.indexOf(unit) !== -1;
    }

    return {
        getUnit,
        getAmount,
        isRepresentableDuration,
        RECOGNIZED_TIME_UNITS,
    };
});
