import logger from '../../../../common/logger';

const info = logger('dimensionService').extend('info');

export default [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        const url = API_URL + '/v2/dimension';

        function search(query, offset = 0, limit = 100) {
            return $http.get(url, {
                params: {
                    query,
                    offset,
                    limit,
                },
            });
        }

        // This is used to check whether there is data or not
        // So, limit the results to 1 record
        function presenceSearch(query) {
            return $http.get(url, {
                params: {
                    query,
                    offset: 0,
                    limit: 1,
                },
            });
        }

        function getPropertyNameSuggest({ partialInput = '', limit = 100 } = {}) {
            return $http
                .get(url + '/suggest', {
                    params: {
                        partialInput,
                        limit,
                    },
                })
                .then((result) => result.data);
        }

        function getPropertyValueSuggest({ property, partialInput = '', limit = 100 } = {}) {
            if (!property) {
                info('property is required for value suggestion.');
                return;
            }

            return $http
                .get(url + '/suggest', {
                    params: {
                        property,
                        partialInput,
                        limit,
                    },
                })
                .then((result) => result.data);
        }

        return {
            search,
            presenceSearch,
            getPropertyNameSuggest,
            getPropertyValueSuggest,
        };
    },
];
