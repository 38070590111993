import { convertMSToString, safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import {
    sanitizeTerm,
    sanitizeWithRegExMode,
    filterReWritable,
    escapeESQueryForProgramText,
} from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';

const DASHBOARD_WINDOW_CONSTANTS = {
    DASHBOARD_TIME_LABEL: 'Dashboard window',
};

angular.module('signalview.analytics').service('analyticsService', [
    'blockService',
    '$log',
    'sourceFilterService',
    'mustache',
    'plotUtils',
    'regExStyles',
    '_',
    'calendarWindowUtil',
    function (
        blockService,
        $log,
        sourceFilterService,
        mustache,
        plotUtils,
        regExStyles,
        _,
        calendarWindowUtil
    ) {
        /******
         * analyticsService will provide a way for the UI to be dynamically constructed based on
         * the modeDef configuration object, combined with the convert function which will likely
         * apply string replace by model value against some base signalflow text.
         */
        const self = this;
        const OBJECT_ID_PLACEHOLDER = '__OBJECT_ID__';
        this.sfTextGenerationErrorString = '???ERROR???';

        this.isDerivedMetric = function (obj) {
            return (
                angular.isDefined(obj.sf_uiAnalyticsDerivedMetric) &&
                obj.sf_uiAnalyticsDerivedMetric === 1
            );
        };

        this.createDataManipulationName = function (manip) {
            const fn = blockService.get(manip.fn.type);
            let fnExpr = fn.toReadableString(manip);

            if (manip.direction.type === 'aggregation') {
                const aggGroups = manip.direction.options.aggregateGroupBy;
                if (aggGroups && aggGroups.length > 0) {
                    const groups = [];
                    angular.forEach(aggGroups, function (itm) {
                        groups.push(itm.value);
                    });
                    fnExpr += ' by ' + groups.join(',');
                }
            } else if (calendarWindowUtil.isCalendarWindowType(manip.fn)) {
                // Calendar Window
                let timeShiftText = '';
                let pluralSuffix = '';
                if (manip.fn.options.isTimeShift && manip.fn.options.shiftCycleCount) {
                    if (manip.fn.options.shiftCycleCount > 1) {
                        pluralSuffix = 's';
                    }
                    timeShiftText =
                        ', timeshift ' +
                        manip.fn.options.shiftCycleCount +
                        ' ' +
                        manip.fn.options.cycle +
                        pluralSuffix;
                }

                fnExpr +=
                    '(' +
                    calendarWindowUtil.getCalendarWindowBlockMapping()[manip.fn.options.cycle] +
                    timeShiftText +
                    ')';
            } else if (calendarWindowUtil.isDashboardWindowType(manip.fn)) {
                fnExpr += '(' + DASHBOARD_WINDOW_CONSTANTS.DASHBOARD_TIME_LABEL + ')';
            } else {
                // Moving Window
                fnExpr += '(' + manip.direction.options.transformTimeRange + ')';
            }
            return fnExpr;
        };

        this.getToolTip = function (manip) {
            const fn = blockService.get(manip.fn.type);
            return fn.getToolTip(manip);
        };

        this.createPlotName = function (model) {
            const nameParts = [];
            try {
                if (model.type === 'ratio') {
                    nameParts.push(model.expressionText || 'New Expression');
                } else if (model.type === 'plot') {
                    nameParts.push(model.seriesData.metric || 'New Plot');
                } else if (model.type === 'event') {
                    nameParts.push(
                        model.seriesData.eventQuery || model.seriesData.detectorQuery || 'New Plot'
                    );
                } else {
                    $log.log('Unknown model type');
                }

                if (model.offset) {
                    nameParts.push(convertMSToString(model.offset));
                }

                angular.forEach(model.dataManipulations, function (manip) {
                    nameParts.push(self.createDataManipulationName(manip));
                });
            } catch (e) {
                $log.log('Failed to generate a descriptive plot name:', e);
                nameParts.push('New Plot');
            }
            return nameParts.join(' - ');
        };

        this.getSuffix = function (filters = [], filterOverrides = []) {
            filters = filters.filter((f) => f.value);
            filterOverrides = filterOverrides.filter((f) => f.value);
            const nonDuplicateFilters = filters.filter((filter) =>
                _.every(filterOverrides, (f) => f.property !== filter.property)
            );
            const nonReplaceOnlyFilters = filterOverrides.filter((f) => !f.replaceOnly);
            const replaceOnlyFilters = filterOverrides.filter(
                (f) => f.replaceOnly && _.find(filters, (filter) => filter.property === f.property)
            );

            const requiredFilters = nonDuplicateFilters
                .concat(nonReplaceOnlyFilters)
                .concat(replaceOnlyFilters);
            let suffix = sourceFilterService.translateSourceFilterObjects(requiredFilters);
            if (suffix) {
                suffix = ' AND ' + suffix;
            }
            return suffix;
        };

        this.getEventQuery = function (filters, seriesData, onlyIfExistsFilters) {
            const requiredFilters = filters
                ? sourceFilterService.translateSourceFilterObjects(filters)
                : null;
            const ifExistsFilters = onlyIfExistsFilters
                ? sourceFilterService.translateSourceFilterObjects(onlyIfExistsFilters, true)
                : null;
            let suffix = [requiredFilters, ifExistsFilters].filter((f) => !!f).join(' AND ');
            if (suffix) {
                suffix = ' AND ' + suffix;
            }

            let hasGlob = false;
            if (seriesData.detectorId) {
                return `(sf_detectorId:"${seriesData.detectorId}"${suffix})`;
            } else if (seriesData.detectorQuery) {
                hasGlob = seriesData.detectorQuery.indexOf('*') > -1;
                if (hasGlob) {
                    return '(sf_detector:' + sanitizeTerm(seriesData.detectorQuery) + suffix + ')';
                } else {
                    return '(sf_detector:"' + seriesData.detectorQuery + '"' + suffix + ')';
                }
            } else if (seriesData.eventQuery) {
                hasGlob = seriesData.eventQuery.indexOf('*') > -1;
                if (hasGlob) {
                    return '(sf_eventType:' + sanitizeTerm(seriesData.eventQuery) + suffix + ')';
                } else {
                    return '(sf_eventType:"' + seriesData.eventQuery + '"' + suffix + ')';
                }
            } else {
                $log.warn('Could not find a type to use for event query');
                return null;
            }
        };

        function getTransformAggregationDefinition(model, analyticFunction, needsCollapse) {
            // needsCollapse indicates whether or not an empty groupby has been performed.
            // allows us to eliminate redundant empty groupbys and do so between a transform and aggregation
            const rArr = [];
            let allGroupBys = [];
            let collapseNext = needsCollapse;
            if (model.type === 'aggregation') {
                const aggGroups = model.options.aggregateGroupBy;
                if (aggGroups && aggGroups.length > 0) {
                    angular.forEach(aggGroups, function (itm) {
                        allGroupBys.push(itm.value);
                    });

                    if (!analyticFunction.isForcedGroupCollapse()) {
                        rArr.push("groupby('" + allGroupBys.join("','") + "')");
                        collapseNext = true;
                    } else {
                        //always collapse before a forced collapse fn(top/bottom)
                        rArr.push('groupby()');
                    }
                } else if (analyticFunction.isForcedGroupCollapse()) {
                    rArr.push('groupby()');
                } else if (needsCollapse && !analyticFunction.isFakeAggregation()) {
                    collapseNext = false;
                    rArr.push('groupby()');
                    allGroupBys = null;
                }
            } else if (model.type === 'transformation') {
                if (model.options.transformTimeRange) {
                    rArr.push('window(' + model.options.transformTimeRange + ')');
                }
            }

            return {
                jobDef: rArr,
                groupBy: allGroupBys,
                collapseNext: collapseNext,
            };
        }

        this.generateDataManipulationText = function (manips, plotName) {
            let blocks = [];

            if (!manips) {
                return blocks;
            }

            let collapseNext = true;

            manips.forEach(function (manip, index) {
                const manipFn = manip.fn;
                const analyticFunction = blockService.get(manipFn.type);
                const transformobj = getTransformAggregationDefinition(
                    manip.direction,
                    analyticFunction,
                    collapseNext
                );
                collapseNext = transformobj.collapseNext;
                blocks = blocks.concat(transformobj.jobDef);

                const localVarKey = plotName + '_TEMP_VAR_' + index;
                const definition = analyticFunction(
                    manipFn.options,
                    localVarKey,
                    transformobj.groupBy || []
                );
                blocks = blocks.concat(definition);
            });

            return blocks;
        };

        function generateFetchBlock(configuration, manips) {
            let fetchBlock = 'fetch';
            const paramtext = [];

            if (configuration.extrapolationPolicy && configuration.extrapolationPolicy !== 'AUTO') {
                // if there is a custom extrapolation policy, apply it
                paramtext.push("extrapolation='" + configuration.extrapolationPolicy + "'");
            } else {
                paramtext.push("extrapolation='NULL_EXTRAPOLATION'");
            }

            if (configuration.maxExtrapolations) {
                //if the user has specified max extrapolations and the value is valid, apply it here
                paramtext.push('maxExtrapolations=' + configuration.maxExtrapolations);
            } else {
                paramtext.push('maxExtrapolations=-1');
            }

            if (configuration.rollupPolicy) {
                paramtext.push("rollup='" + configuration.rollupPolicy + "'");
            }

            let offset = 0;
            angular.forEach(manips, function (manip) {
                offset = safeLookup(manip, 'fn.options.milliseconds') || offset;
            });
            if (offset) {
                paramtext.push('offset=' + Math.abs(offset) * -1);
            }

            if (paramtext.length > 0) {
                fetchBlock += '(' + paramtext.join(',') + ')';
            }

            return fetchBlock;
        }

        function generateDimensionalizeBlock(configuration, plot) {
            if (!plotUtils.isAliasedRegExStyle(plot)) {
                $log.error('Tried to generate metadata block in non graphite mode!');
                return null;
            }
            const params = [];
            const delim = regExStyles.getDelimiter(plot.seriesData.regExStyle);
            params.push("_sf_delimiter='" + delim + "'");

            if (configuration.aliases) {
                angular.forEach(configuration.aliases, function (alias, idx) {
                    if (alias) {
                        params.push(alias + '=' + idx);
                    }
                });
            }
            return 'dimensionalize(' + params.join(', ') + ')';
        }

        function generateFindBlock(configuration, sourceQuery, seriesData, plot) {
            const paramtext = [];

            // cap the number of fetched timeseries if configured
            // note that FIND results are ordered by sf_createdOnMs so this would match the
            // oldest time series first
            if (configuration.mtsLimit) {
                paramtext.push('limit=' + configuration.mtsLimit);
            }

            // add the query

            let metricTerm;
            const isAliased = plotUtils.isAliasedRegExStyle(plot);
            if (isAliased) {
                metricTerm =
                    'sf_metric:' +
                    sanitizeWithRegExMode(seriesData.metric, plot.seriesData.regExStyle);
                if (!metricTerm) {
                    $log.error(
                        'Tried to generate a find block using an unrecognized configuration!'
                    );
                }
            } else {
                const rewritten = filterReWritable('sf_metric', seriesData.metric, false);
                if (rewritten) {
                    metricTerm = rewritten;
                } else {
                    metricTerm = 'sf_metric:' + sanitizeTerm(seriesData.metric);
                }
            }
            let query =
                '(' +
                metricTerm +
                ' AND ' +
                (seriesData.withProgramId
                    ? '(_exists_:sf_programId OR _exists_:computationId)'
                    : '(NOT _exists_:sf_programId AND NOT _exists_:computationId)') +
                ')';
            let filteredQuery = '';
            if (!isAliased) {
                const sourceFilter = sourceFilterService.translateSourceFilterObjects(sourceQuery);

                if (sourceFilter.length > 0) {
                    filteredQuery = ' AND (' + sourceFilter + ')';
                }
            }

            query += filteredQuery;

            query = escapeESQueryForProgramText(query, "'");

            paramtext.push("query='" + query + "'");

            return 'find(' + paramtext.join(',') + ')';
        }

        this.getPlotText = function (
            plot,
            sf_id,
            revisionNumber,
            skipPublish,
            timeShiftMode,
            timeShiftKey,
            throttle,
            ids
        ) {
            const sourceQuery =
                plot.queryItems &&
                plot.queryItems.filter(function (item) {
                    return !item.disabled;
                });
            const seriesData = plot.seriesData;
            let configuration = plot.configuration;
            const manips = plot.dataManipulations;
            const key = plot.uniqueKey;
            sf_id = sf_id || OBJECT_ID_PLACEHOLDER;

            // Ensure configuration isn't null or undefined so that we can do checks on it
            // in the fetch and find block generation without having to worry about the
            // container object's existence
            configuration = configuration || {};

            let sbjt = [];
            if (!seriesData.metric) {
                $log.log('Unable to generate text for plot with no metric selected.  Skipping.');
                return null;
            }

            sbjt.push(generateFindBlock(configuration, sourceQuery, seriesData, plot));
            if (plotUtils.isAliasedRegExStyle(plot)) {
                const mdblock = generateDimensionalizeBlock(configuration, plot);
                if (mdblock) {
                    sbjt.push(mdblock);
                }
            }
            sbjt.push(generateFetchBlock(configuration, manips));

            //if no analytics present or only fake analytics present
            let hasOneRealAnalyticsFunction = false;
            let needsReduction = false;
            angular.forEach(manips, function (manip, idx) {
                // determine whether we have any analytics at all.  if not we must reduce the output so it can
                // be published

                const analyticFunction = blockService.get(manip.fn.type);

                if (
                    !(
                        analyticFunction.isFakeAggregation() &&
                        (safeLookup(manip, 'direction.type') === 'aggregation' ||
                            safeLookup(manip, 'direction.type') === 'transformation')
                    )
                ) {
                    hasOneRealAnalyticsFunction = true;
                }

                //Disable throttling if a select block is being used due to how SAMPLE works.
                if (manip.fn.type === 'TOPN' || manip.fn.type === 'BOTTOMN') {
                    throttle = false;
                }
                // if the first analytics function is a fake aggregation or a transformation,
                // then we need to reduce.  real aggregations will reduce themselves.
                if (
                    idx === 0 &&
                    ((analyticFunction.isFakeAggregation() &&
                        safeLookup(manip, 'direction.type') === 'aggregation') ||
                        safeLookup(manip, 'direction.type') === 'transformation')
                ) {
                    needsReduction = true;
                }
            });

            if (!hasOneRealAnalyticsFunction || needsReduction) {
                sbjt.push('split');
            }

            let plotName;
            if (timeShiftMode) {
                plotName = this.getPlotNameForChainedTimeshift(
                    key,
                    sf_id,
                    revisionNumber,
                    timeShiftKey
                );
            } else {
                plotName = this.getPlotName(key, sf_id, revisionNumber);
            }

            ids.push(plotName + '=id(report=1)');

            sbjt = sbjt.concat(this.generateDataManipulationText(manips, plotName));
            sbjt.push(plotName);

            if (!skipPublish) {
                if (throttle) {
                    sbjt.push('groupby()');
                    sbjt.push('sample(' + throttle + ')');
                    sbjt.push('split');
                } else {
                    sbjt.push('groupby()');
                    sbjt.push('split');
                }

                const publishText = mustache.render(
                    "publish(metric='{{metric}}',sf_uiAnalyticsDerivedMetric=1,sf_uiHelper='{{uiHelper}}')",
                    {
                        metric: this.getPlotName(key, sf_id, revisionNumber),
                        uiHelper: self.getPublishKey(sf_id, revisionNumber),
                    }
                );

                sbjt.push(publishText);
            }

            return sbjt.join(' -> ');
        };

        this.getPublishKey = function (chartId, revisionNumber) {
            chartId = chartId || OBJECT_ID_PLACEHOLDER;
            return chartId.replace(/-/g, '_SF_REPLACED_DASH_') + '_' + revisionNumber;
        };

        this.getPlotNameForChainedTimeshift = function (key, sf_id, revisionNumber, parentkey) {
            return this.getPlotName(key, sf_id, revisionNumber) + '_TIMESHIFTFOR' + parentkey;
        };

        this.syntheticIdFilter = function (value, plotObj, metadata) {
            if (value && value.indexOf('_SF_COMP_') === 0) {
                return (plotObj && plotObj.name) || (metadata && metadata.sf_streamLabel);
            } else {
                return value;
            }
        };

        this.getPlotName = function (key, sf_id, revisionNumber) {
            if (!angular.isDefined(revisionNumber)) {
                alert(
                    'Your charts need to be resaved for updates to the persistent model, edit/save your chart.'
                );
            }

            sf_id = sf_id || OBJECT_ID_PLACEHOLDER;
            sf_id = sf_id.replace(/-/g, '_SF_REPLACED_DASH_');
            return '_SF_PLOT_KEY_' + sf_id + '_' + key + '_' + revisionNumber;
        };
    },
]);
