import { ngRoute } from '../../../../app/routing/ngRoute';
import otpVerificationTemplateUrl from './otpVerification.tpl.html';

export default {
    templateUrl: otpVerificationTemplateUrl,
    controller: [
        '$http',
        'API_URL',
        '$location',
        function ($http, API_URL, $location) {
            const $ctrl = this;

            $ctrl.credentials = {
                sessionId: ngRoute.params.sessionId,
                email: ngRoute.params.email,
                integrationId: ngRoute.params.integrationId,
            };

            if (
                !$ctrl.credentials.sessionId ||
                !$ctrl.credentials.email ||
                !$ctrl.credentials.integrationId
            ) {
                $ctrl.error = 'Invalid Credentials.';
            }

            $ctrl.onChange = () => {
                $ctrl.error = undefined;
            };

            $ctrl.restartVerification = () => {
                $location.path('/signin');
            };

            $ctrl.submit = function () {
                $ctrl.loading = true;

                $http
                    .post(API_URL + '/v2/session/oidc/confirmation', {
                        sessionId: $ctrl.credentials.sessionId,
                        integrationId: $ctrl.credentials.integrationId,
                        verificationCode: $ctrl.verificationCode,
                    })
                    .then(
                        (response) => {
                            $ctrl.loading = false;
                            $ctrl.error = undefined;
                            if (response.data?.redirectURI) {
                                window.location.href = response.data?.redirectURI;
                            } else {
                                $location.path('/signin');
                            }
                        },
                        (response) => {
                            $ctrl.loading = false;
                            if (response.status === -1) {
                                $ctrl.error = 'Network Error';
                            } else {
                                $ctrl.error = response.data?.message || 'Incorrect or Expired code';
                            }
                        }
                    );
            };
        },
    ],
};
