'use strict';

angular.module('sfx.ui').filter('fromNow', [
    'moment',
    function (moment) {
        return function (input, excludeSuffix) {
            return moment(input).fromNow(excludeSuffix);
        };
    },
]);
