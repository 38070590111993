import chartConfigurationTemplateUrl from './chartConfiguration.tpl.html';
import renderThrottleMessageTemplateUrl from './renderThrottleMessage.tpl.html';
import jobFetchCapsMessageTemplateUrl from './jobFetchCapsMessage.tpl.html';

angular
    .module('signalview.chartbuilder')
    .controller('ChartConfigurationCtrl', [
        '$scope',
        '$timeout',
        'plotUtils',
        'CHART_DISPLAY_EVENTS',
        'chartDisplayUtils',
        'calendarWindowUtil',
        'CALENDAR_MESSAGE',
        function (
            $scope,
            $timeout,
            plotUtils,
            CHART_DISPLAY_EVENTS,
            chartDisplayUtils,
            calendarWindowUtil,
            CALENDAR_MESSAGE
        ) {
            $scope.renderThrottleMessageTemplateUrl = renderThrottleMessageTemplateUrl;
            $scope.jobFetchCapsMessageTemplateUrl = jobFetchCapsMessageTemplateUrl;

            const OPTIMIZE_MESSAGE_DEBOUNCE_RATE = 1000;
            let shouldHideMessageOnNextChange = true;
            let debounceCalenderOptimizationMessageInitComplete = null;
            $scope.CALENDAR_MESSAGE = CALENDAR_MESSAGE;
            $scope.calendarOptimizationMessageState = CALENDAR_MESSAGE.NONE;

            $scope.isRenderableMode = function () {
                return (
                    $scope.model.sf_uiModel.chartMode !== 'text' &&
                    $scope.model.sf_uiModel.chartMode !== 'event'
                );
            };

            $scope.processJobMessages = function (messages) {
                if (!messages) {
                    return;
                }
                if ($scope.builderDisplayMode === 'fallback') {
                    $scope.throttled = chartDisplayUtils.jobMessagesReceivedV2(
                        messages,
                        $scope.model
                    );
                } else {
                    $scope.throttled = chartDisplayUtils.jobMessagesReceivedV1(
                        messages,
                        $scope.model
                    );
                }
                $scope.$emit('jobFeedbackEvent', messages);
            };

            $scope.$watchGroup(
                [
                    'model.sf_uiModel.chartconfig.showSparkline',
                    'model.sf_uiModel.chartconfig.secondaryVisualization',
                ],
                function (newval, oldval) {
                    if (angular.isDefined(newval) && newval !== oldval) {
                        $scope.$broadcast('chart settings modified');
                        $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                    }
                }
            );

            $scope.$watch('model.sf_uiModel.chartconfig.hideTimestamp', function () {
                $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
            });

            $scope.alertState = null;
            $scope.$on('alert state updated', function (evt, smokeyState) {
                $scope.alertState = smokeyState.alertState;
                $scope.alertStateClass = smokeyState.alertState
                    ? smokeyState.alertState.toLowerCase()
                    : '';
            });

            $scope.$on(CHART_DISPLAY_EVENTS.JOB_FEEDBACK_RECEIVED, function (evt, msgs) {
                $scope.jobFeedback = msgs;
                $scope.processJobMessages(msgs);
            });

            $scope.$on('chartPreviewUpdated', function () {
                if (shouldHideMessageOnNextChange) {
                    $scope.hideCalendarOptimizationMessage();
                } else {
                    setCalenderOptimizationMessageDebounce();
                }
            });

            $scope.$on('calendarWindowOptimized', function (event, cycleName) {
                $scope.optimizedCycleName =
                    calendarWindowUtil.getCalendarWindowBlockMapping()[cycleName];
                $scope.calendarOptimizationMessageState = CALENDAR_MESSAGE.RESET;
                setCalendarOptimizationMessage();
            });

            $scope.toggleCalendarOptimization = function () {
                $scope.$emit(
                    'toggleCalendarWindowOptimization',
                    $scope.calendarOptimizationMessageState
                );
                // flip calendar message
                if ($scope.calendarOptimizationMessageState === CALENDAR_MESSAGE.RESET) {
                    $scope.calendarOptimizationMessageState = CALENDAR_MESSAGE.OPTIMIZE;
                } else {
                    $scope.calendarOptimizationMessageState = CALENDAR_MESSAGE.RESET;
                }
                setCalendarOptimizationMessage();
            };

            $scope.hideCalendarOptimizationMessage = function () {
                $scope.calendarOptimizationMessageState = CALENDAR_MESSAGE.NONE;
            };

            function setCalendarOptimizationMessage() {
                shouldHideMessageOnNextChange = false;
                setCalenderOptimizationMessageDebounce();
            }

            function setCalenderOptimizationMessageDebounce() {
                if (debounceCalenderOptimizationMessageInitComplete !== null) {
                    $timeout.cancel(debounceCalenderOptimizationMessageInitComplete);
                }
                debounceCalenderOptimizationMessageInitComplete = $timeout(function () {
                    shouldHideMessageOnNextChange = true;
                }, OPTIMIZE_MESSAGE_DEBOUNCE_RATE);
            }

            $scope.$watch('model.sf_uiModel.chartMode', function (v) {
                if (v === 'single' || v === 'list') {
                    $scope.model.sf_uiModel.chartType = 'line';
                }
            });

            $scope.$on(CHART_DISPLAY_EVENTS.RENDER_STATS, function (evt, pl) {
                $scope.renderStats = pl;
            });

            $scope.$on(CHART_DISPLAY_EVENTS.JOB_FETCH_CAPS, function (evt, pl) {
                $scope.jobFetchCaps = pl;
            });

            $scope.$on('legendKeysAvailable', function (evt, pl) {
                $scope.legendKeys.splice(0);
                pl.forEach((k) => {
                    $scope.legendKeys.push(k);
                });
            });

            $scope.$on('plotTimeSeriesData', function (evt, plotKeyToInfoMap) {
                $scope.maxMts = { count: 0 };
                angular.forEach(plotKeyToInfoMap || {}, function (value, key) {
                    const plot = value || {};
                    const count = (plot.tsidCount || {}).numInputTimeSeries || 0;
                    if (plot.visible && count > $scope.maxMts.count) {
                        $scope.maxMts = {
                            count: count,
                            plot: plotUtils.getLetterFromUniqueKey(key),
                        };
                    }
                });
            });

            // initialize sort preferece if not previously set
            if (!angular.isDefined($scope.model.sf_uiModel.chartconfig.sortPreference)) {
                $scope.model.sf_uiModel.chartconfig.sortPreference = '';
            }
        },
    ])
    .directive('chartConfiguration', [
        '_',
        '$timeout',
        '$window',
        'currentUser',
        'CHART_DISPLAY_EVENTS',
        'HTML2CANVAS',
        'alertMessageService',
        function (
            _,
            $timeout,
            $window,
            currentUser,
            CHART_DISPLAY_EVENTS,
            html2canvas,
            alertMessageService
        ) {
            return {
                restrict: 'E',
                scope: {
                    model: '=',
                    maxDelayOverride: '=',
                    legendKeys: '=',
                    sourceFilters: '=',
                    jobFeedback: '=?',
                    sharedChartState: '=?',
                    sharedChartConfig: '=?',
                    filterAlias: '=?',
                    snapshot: '=?',
                    builderDisplayMode: '<?',
                    inEditor: '<',
                },
                templateUrl: chartConfigurationTemplateUrl,
                controller: 'ChartConfigurationCtrl',
                link: function ($scope, element) {
                    const debouncedSave = _.debounce(saveHeightPreference, 500);
                    const debouncedUpdateChartOffset = _.debounce(updateChartOffsetTop, 100);
                    const TAB_HEADER_HEIGHT = 40;
                    const SMOKEY_SHADOW_PADDING = 3;
                    const CHART_MIN_HEIGHT = 200;
                    const FULL_SCREEN_SIZE = -1;
                    let configPanelHeight = 0;
                    let chartSizer;
                    let maxChartHeight;
                    let chartOffsetTop;
                    let windowResizeHandlerRetry;
                    let isTabOpen;

                    initializeChartResizer();

                    $scope.$on('destroy', () => {
                        $window.removeEventListener('resize', handleWindowResize);
                    });

                    function initializeChartResizer() {
                        $timeout(() => {
                            currentUser.preferences().then((preferences) => {
                                chartSizer = element.find('.chart-builder-preview-sizer');
                                // set offset from top
                                updateChartOffsetTop();

                                // set chart height according to existing preferences if they have been set
                                initializeChartHeight(preferences);

                                // listen for window resize and handle accordingly
                                $window.addEventListener('resize', handleWindowResize);

                                $scope.$on(
                                    'possible filter bar resize',
                                    debouncedUpdateChartOffset
                                );
                                $scope.$on(
                                    CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED,
                                    handleTabSelection
                                );
                                $scope.$on('full screen chart toggle config', handleTabToggle);

                                // changes to this toggle an info banner
                                $scope.$watch(
                                    'throttled.throttleCount + throttled.passThruCount',
                                    () => {
                                        $timeout(updateChartOffsetTop);
                                    }
                                );

                                // make element resizable. Appends css class .ui-resizeable-handle
                                applyResizableToSizer();
                            });
                        });
                    }

                    function applyResizableToSizer() {
                        chartSizer.resizable({
                            handles: 's',
                            minHeight: CHART_MIN_HEIGHT,
                            maxHeight: maxChartHeight,
                            resize,
                        });
                    }

                    function initializeChartHeight(preferences) {
                        const preferFullScreen =
                            preferences.sf_preferredChartSize === FULL_SCREEN_SIZE;
                        let preferredSize;
                        maxChartHeight = getChartSizerMaxHeight();

                        if (preferences.sf_preferredChartSize) {
                            const savedPreferredSize = Math.max(
                                CHART_MIN_HEIGHT,
                                preferences.sf_preferredChartSize
                            );

                            // if user has set preference to full screen or if user preference
                            // is larger than current screen, then use full screen
                            if (preferFullScreen || savedPreferredSize > maxChartHeight) {
                                $scope.fullScreenChart = true;
                                $scope.$emit('set full screen chart', $scope.fullScreenChart);
                                preferredSize = maxChartHeight;
                                configPanelHeight = 0;
                            } else {
                                preferredSize = savedPreferredSize;
                            }

                            chartSizer.css('height', preferredSize);
                        }
                    }

                    function handleTabSelection() {
                        updateConfigSectionInFullScreen(null, false);
                    }

                    function handleTabToggle() {
                        updateConfigSectionInFullScreen(null, true);
                    }

                    function updateConfigSectionInFullScreen($event, clickedActiveTab) {
                        if (!$scope.fullScreenChart) {
                            return;
                        }

                        let newTabStatus;

                        if (isTabOpen && clickedActiveTab) {
                            $scope.$emit('full screen chart close tab');
                            newTabStatus = false;
                        } else if (!isTabOpen) {
                            $scope.$emit('full screen chart open tab');
                            newTabStatus = true;
                        }

                        if (angular.isDefined(newTabStatus) && isTabOpen !== newTabStatus) {
                            isTabOpen = newTabStatus;
                            updateConfigPanelHeight();
                            chartSizer.css('height', getChartSizerMaxHeight());
                            broadcastResize();
                        }
                    }

                    function updateConfigPanelHeight() {
                        if (isTabOpen) {
                            configPanelHeight = Math.min(300, Math.floor(maxChartHeight / 2));
                        } else {
                            configPanelHeight = 0;
                        }
                    }

                    function resize(event, ui) {
                        if ($window.innerHeight - event.pageY <= TAB_HEADER_HEIGHT) {
                            // set fullScreenChart and tell parent
                            $scope.fullScreenChart = true;
                            isTabOpen = false;
                            $scope.$emit('set full screen chart', $scope.fullScreenChart);

                            // round chart size to fill screen
                            ui.element.css('height', getChartSizerMaxHeight());
                        } else if ($scope.fullScreenChart) {
                            $scope.fullScreenChart = false;
                            configPanelHeight = 0;
                            $scope.$emit('set full screen chart', $scope.fullScreenChart);
                        }

                        broadcastResize();
                        debouncedSave(ui.size.height);
                    }

                    function saveHeightPreference(height) {
                        currentUser.preferences().then((prefs) => {
                            currentUser.updatePreferences({
                                sf_id: prefs.sf_id,
                                sf_preferredChartSize: $scope.fullScreenChart
                                    ? FULL_SCREEN_SIZE
                                    : height,
                            });
                        });
                    }

                    // reflow seems to happen before window resize event occurs, so we track this on
                    // a different cycle. It is initialized once on page load and then updated When
                    // filters change (because the header containing the filter changes size and affects
                    // the offset of the chartSizer).
                    function updateChartOffsetTop() {
                        const oldOffset = chartOffsetTop;
                        chartOffsetTop = chartSizer.offset().top;

                        if (oldOffset !== chartOffsetTop) {
                            handleWindowResize();
                        }
                    }

                    function handleWindowResize(isTimeoutTry) {
                        if (isTimeoutTry) {
                            $timeout.cancel(windowResizeHandlerRetry);
                        }

                        maxChartHeight = getChartSizerMaxHeight();

                        applyResizableToSizer();

                        if ($scope.fullScreenChart) {
                            chartSizer.css('height', maxChartHeight);
                        } else if (chartSizer.height() >= maxChartHeight) {
                            chartSizer.css('height', maxChartHeight);

                            $scope.fullScreenChart = true;
                            $scope.$emit('set full screen chart', $scope.fullScreenChart);
                        }

                        broadcastResize();

                        // making the window smaller creates some issues without the timed out retry,
                        // the window sometimes ends up being slightly smaller than when the last event fires.
                        if (!isTimeoutTry) {
                            windowResizeHandlerRetry = $timeout(() => handleWindowResize(true), 15);
                        }
                    }

                    function getChartSizerMaxHeight() {
                        return (
                            $window.innerHeight -
                            chartOffsetTop -
                            configPanelHeight -
                            SMOKEY_SHADOW_PADDING -
                            TAB_HEADER_HEIGHT
                        );
                    }

                    function broadcastResize() {
                        $scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                    }

                    function download() {
                        const parentElement = element.parent();
                        const chartName =
                            ($scope.model && $scope.model.sf_chart) ||
                            ($scope.chart && $scope.chart.name);
                        const hideOnDownload = angular
                            .element(
                                '.chart-buttons, chart-mode-switcher, .plot-editor-container, .sf-clickable',
                                parentElement
                            )
                            .filter(':visible');
                        const sfxLogo = angular.element(
                            '.dashboard-portlet-sfx-logo',
                            parentElement
                        );

                        parentElement.css('height', element.offset().top + element.height());
                        hideOnDownload.hide();
                        sfxLogo.show();

                        html2canvas(parentElement[0]).then(
                            function (canvas) {
                                const a = document.getElementById('chart-image-download-link');
                                a.href = canvas.toDataURL('image/png');
                                a.download = chartName + ' chart.png';
                                a.click();
                                sfxLogo.hide();
                                parentElement.css('height', '');
                                hideOnDownload.show();
                            },
                            function () {
                                alertMessageService({
                                    title: 'Image Generation Error',
                                    messages: [
                                        'An error occurred while generating an image to this chart.',
                                    ],
                                });

                                parentElement.css('height', '');
                                sfxLogo.hide();
                                hideOnDownload.show();
                            }
                        );
                    }

                    $scope.$on('download chart as image', download);
                },
            };
        },
    ]);
