import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import AccessControl from '../../../common/ui/accessControl/AccessControl';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import ExitGuardService from './ExitGuardService';
import useAclUserContext from '../../../common/ui/accessControl/useAclUserContext';
import useAclOrgPreferencesContext from '../../../common/ui/accessControl/useAclOrgPreferencesContext';
import aclValidator from '../../../common/ui/accessControl/aclValidator';
import {
    StyledPanel,
    StyledButton,
    StyledBody,
    StyledFooter,
    StyledForm,
} from '../../../common/ui/ModalStyles';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';
import useAclPermissions from '../../../common/ui/accessControl/useAclPermissions';
import useExitGuard from '../../../common/ui/accessControl/useExitGuard';
import accessControlHelper from '../../../common/ui/accessControl/accessControlHelper';
import { ThemeProvider } from '../../../common/theme/ThemeProvider';
import MissingRbacCapabilityMessage from '../../../legacy/app/dashboard/MissingRbacCapabilityMessage';

const OBJECT_TYPE = AccessControlObjectType.DASHBOARD_GROUP;

const SAVE_BUTTON_LABEL = 'Save';
const CANCEL_BUTTON_LABEL = 'Cancel';

export default function EditAclDashboardGroupPermissions({
    exitGuardService,
    dashboardGroupId,
    userData,
    onDismiss,
    save,
    readOnly,
    themeKey,
    missingUpdateDashboardGroupCapability,
}) {
    const userContext = useAclUserContext(userData);
    const orgPrefContext = useAclOrgPreferencesContext(userContext.orgId);
    const {
        permissions,
        initialPermissions,
        userEffectiveActions,
        update: updatePermissions,
        reset,
        isLoading,
    } = useAclPermissions(userContext, dashboardGroupId);

    const hasUnsavedChanges = !accessControlHelper.isEqualPermissionSet(
        permissions,
        initialPermissions
    );
    const validationErrors = aclValidator.getNonWritableObjectError(
        permissions,
        userEffectiveActions,
        OBJECT_TYPE
    );

    const canSave = hasUnsavedChanges && !validationErrors.length;

    useExitGuard(
        exitGuardService,
        () => save(permissions),
        () => reset(initialPermissions),
        !validationErrors.length,
        !isLoading && hasUnsavedChanges
    );

    return (
        <ThemeProvider colorScheme={themeKey}>
            <AclFormContext.Provider
                value={{
                    ...userContext,
                    disabled: readOnly || isLoading,
                    syncInProgress: isLoading,
                    restrictTeamAccess: orgPrefContext.sf_restrictTeamManagement,
                }}
            >
                <StyledPanel>
                    <StyledForm onSubmit={handleSubmit}>
                        {missingUpdateDashboardGroupCapability && (
                            <StyledBody>
                                <MissingRbacCapabilityMessage />
                            </StyledBody>
                        )}
                        <StyledBody>
                            {permissions && (
                                <AccessControl
                                    permissions={permissions}
                                    onChangeCallback={updatePermissions}
                                    objectType={OBJECT_TYPE}
                                    readonly={readOnly}
                                />
                            )}
                        </StyledBody>
                        <StyledFooter>
                            <StyledButton
                                label={CANCEL_BUTTON_LABEL}
                                appearance="secondary"
                                size="small"
                                onClick={() => onDismiss('cancel')}
                            />
                            {!readOnly && (
                                <Tooltip
                                    style={{ marginLeft: 10 }}
                                    content={validationErrors.length ? validationErrors[0] : ''}
                                >
                                    <StyledButton
                                        label={SAVE_BUTTON_LABEL}
                                        type="submit"
                                        appearance="primary"
                                        size="small"
                                        disabled={!canSave}
                                    />
                                </Tooltip>
                            )}
                        </StyledFooter>
                    </StyledForm>
                </StyledPanel>
            </AclFormContext.Provider>
        </ThemeProvider>
    );

    function handleSubmit(event) {
        event.preventDefault();
        if (validationErrors.length) return;

        save(permissions).catch((error) => handleError(error));
    }

    function handleError(error) {
        window.alert('Failed saving dashboard-group permissions');
        onDismiss(error);
    }
}

EditAclDashboardGroupPermissions.propTypes = {
    userData: PropTypes.object.isRequired,
    themeKey: PropTypes.string.isRequired,
    dashboardGroupId: PropTypes.string,
    exitGuardService: PropTypes.instanceOf(ExitGuardService).isRequired,
    onDismiss: PropTypes.func,
    save: PropTypes.func,
    readOnly: PropTypes.bool,
    missingUpdateDashboardGroupCapability: PropTypes.bool,
};

EditAclDashboardGroupPermissions.defaultProps = {
    readOnly: false,
    onDismiss: () => {},
    save: () => Promise.resolve(),
};
