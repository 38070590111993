angular.module('signalview.detector.rule').service('processNotificationRecipient', [
    '$http',
    'API_URL',
    '_',
    'NOTIFIER_INTEGRATIONS_V2_MAP',
    '$q',
    '$log',
    'NOTIFIER_DEPRECATED_INTEGRATION_TYPES',
    function (
        $http,
        API_URL,
        _,
        NOTIFIER_INTEGRATIONS_V2_MAP,
        $q,
        $log,
        NOTIFIER_DEPRECATED_INTEGRATION_TYPES
    ) {
        // Handler to process opsgenie
        const opsgenieHandler = (notifications) => {
            const teamNotifications = notifications.filter(
                (n) =>
                    (n.type === 'Opsgenie' ||
                        NOTIFIER_INTEGRATIONS_V2_MAP[n.type] === 'Opsgenie') &&
                    n.responderType === 'Team'
            );

            teamNotifications
                .filter((n) => !n.responderId && n.responderName)
                .forEach((n) => (n.displayName = n.responderName));

            const teamsWithResponderId = teamNotifications.filter((n) => n.responderId);

            if (!teamsWithResponderId.length) {
                // if there's no team with responder id, skip the process
                return $q.when(notifications);
            }

            const grouped = _.groupBy(teamsWithResponderId, (n) => n.credentialId);

            const body = [];
            _.forEach(grouped, (value, key) => {
                body.push({
                    integrationId: key,
                    teamIds: value.map((n) => n.responderId),
                });
            });
            return $http
                .post(API_URL + '/v2/integration/_/opsgenie/responder-names', body)
                .then((r) => {
                    _.forEach(r.data, (teams, key) => {
                        const teamByTeamId = {};
                        teams.forEach((t) => {
                            teamByTeamId[t.teamId] = t;
                        });
                        (grouped[key] || []).forEach(
                            (n) => (n.displayName = teamByTeamId[n.responderId].name)
                        );
                    });
                    return notifications;
                })
                .catch((e) => {
                    $log.error('Failed fetching opsgenie team names from ids', e);
                    return notifications;
                });
        };

        // Handler to process teams
        const teamHandler = (notifications) => {
            const teamIds = [];
            const teamIdToTeamMapping = {};
            notifications.forEach((n) => {
                if (n.type === 'Team' || NOTIFIER_INTEGRATIONS_V2_MAP[n.type] === 'Team') {
                    teamIds.push(n.team);
                    if (!teamIdToTeamMapping[n.team]) {
                        teamIdToTeamMapping[n.team] = [];
                    }
                    teamIdToTeamMapping[n.team].push(n);
                }
            });

            // If there are no teams, then skip the processing
            if (teamIds.length === 0) {
                return $q.when(notifications);
            }

            return $http({
                method: 'GET',
                url: API_URL + '/v2/team/_/fetch',
                params: {
                    id: teamIds,
                },
            })
                .then((resp) => {
                    resp.data.forEach((team) => {
                        if (teamIdToTeamMapping[team.id]) {
                            teamIdToTeamMapping[team.id].forEach((teamMapping) => {
                                teamMapping.teamName = team.name;
                            });
                        }
                    });
                    return notifications;
                })
                .catch((e) => {
                    $log.error('Failed fetching team names from ids', e);
                    return notifications;
                });
        };

        const filterDeprecatedNotifications = (notifications) => {
            return notifications.filter(
                (notification) => !NOTIFIER_DEPRECATED_INTEGRATION_TYPES.includes(notification.type)
            );
        };

        return {
            filterDeprecatedNotifications: filterDeprecatedNotifications,
            // This is a tool to process notification recipients and
            // populate them with display values
            getDisplayDetails: (notifications) => {
                return $q
                    .all({
                        opsgenie: opsgenieHandler(notifications),
                        team: teamHandler(notifications),
                    })
                    .then(() => {
                        return notifications;
                    });
            },
        };
    },
]);
