import templateUrl from './thresholdDepthPicker.tpl.html';

angular.module('signalview.heatmap').directive('thresholdDepthPicker', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            require: '^^groupableHeatmap',
            scope: {},
            templateUrl,
            link: function ($scope, element, attrs, heatmap) {
                $scope.generateSelectionDisplayName = function (depth) {
                    if (!arguments.length) depth = heatmap.heatmap.groupByDepth();

                    if (depth !== 0) {
                        return 'Per ' + heatmap.heatmap.groupBy().slice(0, depth).join(' and ');
                    } else {
                        return 'Across Population';
                    }
                };

                heatmap.heatmap.on('groupByDepth updated', function ($event, groupByDepth) {
                    selector.selected = generateSuggestions()[groupByDepth] || null;
                });

                heatmap.heatmap.on('groupBy updated', function ($event, groupBy) {
                    if (heatmap.heatmap.groupByDepth() > groupBy.length) {
                        heatmap.heatmap.groupByDepth(groupBy.length);
                    }
                });

                $scope.groupByClicked = function (idx) {
                    heatmap.heatmap.groupByDepth(idx);
                };

                const selector = ($scope.selector = {
                    inputVisible: false,
                    selected: { value: heatmap.heatmap.groupByDepth() },
                    suggestions: null,
                    highlighted: null,
                    query: null,
                });

                selector.showInput = function () {
                    selector.inputVisible = true;

                    $timeout(function () {
                        element.find('input').focus();
                    }, 100);
                };

                selector.select = function (item) {
                    selector.query = null;
                    selector.selected = item;
                    selector.inputVisible = false;
                };

                function updateDepth() {
                    let threshold;

                    if (selector.selected) {
                        threshold = selector.selected.value;
                    }
                    heatmap.heatmap.groupByDepth(threshold || 0);
                }

                $scope.$watch('selector.selected', updateDepth, true);

                function generateSuggestions() {
                    const suggestions = [
                        {
                            displayName: 'Across Population',
                            value: 0,
                        },
                    ];

                    heatmap.heatmap.groupBy().forEach(function (group, idx) {
                        suggestions.push({
                            displayName: $scope.generateSelectionDisplayName(idx + 1),
                            value: idx + 1,
                        });
                    });

                    return suggestions;
                }
                function updateSuggestions() {
                    selector.suggestions = generateSuggestions();

                    if (selector.query) {
                        selector.highlighted = selector.suggestions[0];
                    } else {
                        selector.highlighted = null;
                    }
                }

                $scope.$watch('selector.visible', function (isVisible) {
                    if (isVisible) {
                        updateSuggestions();
                    } else {
                        selector.suggestions = null;
                        selector.highlighted = null;
                        selector.inputVisible = false;
                    }
                });
            },
        };
    },
]);
