import templateUrl from './autoClearAlerts.tpl.html';

/**
 *
 */
angular.module('signalview.detector.wizard').component('autoClearAlertsFunction', {
    bindings: {
        rule: '<',
        onTypedUpdate: '&',
        onTypedValueRemoved: '&',
    },
    templateUrl,
    controller: [
        '$scope',
        'timeSpanUtils',
        function ($scope, timeSpanUtils) {
            const ctrl = this;

            ctrl.$onInit = $onInit;

            function $onInit() {
                $scope.autoResolve = {
                    isEnabled: !!ctrl.rule.inputs['auto_resolve_after'],
                    autoResolveAfter: ctrl.rule.inputs['auto_resolve_after'],
                };
            }

            function validate(v) {
                const valid = timeSpanUtils.validateTimeSpan(v);
                $scope.valid = valid;
                return valid;
            }

            function validateAndUpdate(value) {
                const validity = validate(value);
                $scope.$ctrl.onTypedUpdate({ value, validity });
            }

            $scope.$watch('autoResolve.autoResolveAfter', function (current) {
                if ($scope.autoResolve.isEnabled) {
                    validateAndUpdate(current);
                }
            });

            $scope.$watch('autoResolve.isEnabled', function (current) {
                if (!current) {
                    // Remove the rule from the validation
                    $scope.$ctrl.onTypedValueRemoved();
                    // Delete the rule
                    delete ctrl.rule.inputs['auto_resolve_after'];
                } else {
                    // Validate and update
                    validateAndUpdate($scope.autoResolve.autoResolveAfter);
                }
            });
        },
    ],
});
