import { CurrentUserService } from '@splunk/olly-services/lib';
import { EnabledFeature } from '../../../../common/util/FeatureFlags';
import { CapabilityValues } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

const hasCapability = (
    currentUser: CurrentUserService,
    featureEnabled: EnabledFeature
): ((capability: CapabilityValues) => Promise<boolean>) => {
    return async (capability: CapabilityValues): Promise<boolean> => {
        if (featureEnabled('newRbacExperience')) {
            const userCapabilities = await currentUser.userCapabilities();
            return userCapabilities.includes(capability);
        }
        return true;
    };
};

hasCapability.$inject = ['currentUser', 'featureEnabled'];
export default hasCapability;
