import templateUrl from './cannotDeleteSignal.tpl.html';

angular.module('signalview.detector').service('validateSignalDeletion', [
    '$q',
    'sfxModal',
    'plotUtils',
    'detectorUtils',
    function ($q, sfxModal, plotUtils, detectorUtils) {
        return function (plotToDelete, plots, rules) {
            const plotKeyToDelete = plotToDelete.uniqueKey;
            const affectedRules = rules.filter(function (rule) {
                let referringPlots = [];
                if (rule.type === 'Function') {
                    referringPlots = detectorUtils.getPlotsForFunctionRule(rule, plots, [
                        'plot',
                        'ratio',
                    ]);
                } else if (rule.targetPlot) {
                    referringPlots = plotUtils.getAllRelevantPlotAndRatioObjects(
                        rule.targetPlot,
                        plots
                    );
                }
                return referringPlots.some(function (plot) {
                    return plot.uniqueKey === plotKeyToDelete;
                });
            });
            if (!affectedRules.length) {
                return $q.when();
            }
            const modalParams = {
                templateUrl,
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.rules = affectedRules;
                        $scope.plot = plotToDelete;
                    },
                ],
                size: 'md',
                backdrop: 'static',
                keyboard: true,
            };
            return sfxModal.open(modalParams).result;
        };
    },
]);
