import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@splunk/react-ui/Tooltip';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Text from '@splunk/react-ui/Text';
import {
    StyledBody,
    StyledButton,
    StyledFooter,
    StyledForm,
    StyledModalHeader,
    StyledPanel,
} from '../../../../../common/ui/ModalStyles';
import { ThemeProvider } from '../../../../../common/theme/ThemeProvider';
import useInputState from '../../../../../common/react-utils/useInputState';

const GRACEFUL_EXPIRY_DESCRIPTION =
    'Time interval, in seconds, during which the old secret continues to function. After the time expires, the API rejects requests using the old secret. 0 means that the old secret immediately stops working. The maximum allowed interval is 5,184,000 seconds (60 days).';
const GRACEFUL_EXPIRY_ERROR_TOOLTIP = 'Graceful expiry must be a valid number.';

function isValidGracefulExpiry(gracefulExpiry) {
    const gracefulExpiryInteger = parseInt(gracefulExpiry, 10);

    if (
        isNaN(gracefulExpiryInteger) ||
        gracefulExpiryInteger > 5184000 ||
        gracefulExpiryInteger < 0
    ) {
        return false;
    }

    return true;
}

export default function RotateTokenModal({ themeKey, tokenName, onSubmit, onDismiss }) {
    const [gracefulExpiry, setGracefulExpiry] = useInputState(0);

    return (
        <ThemeProvider colorScheme={themeKey}>
            <StyledPanel onSubmit={handleSubmit}>
                <StyledForm>
                    <StyledModalHeader>Rotate Token - {tokenName}</StyledModalHeader>
                    <StyledBody>
                        <ControlGroup label="Graceful Expiry in Seconds" labelPosition="top">
                            <Text
                                value={gracefulExpiry}
                                name="graceful-expiry"
                                onChange={setGracefulExpiry}
                            />
                        </ControlGroup>
                        <div>
                            <span>{GRACEFUL_EXPIRY_DESCRIPTION}</span>
                        </div>
                    </StyledBody>
                    <StyledFooter>
                        <StyledButton
                            label="Cancel"
                            appearance="secondary"
                            size="small"
                            onClick={onDismiss}
                        />
                        <Tooltip
                            style={{ marginLeft: 10 }}
                            content={
                                !isValidGracefulExpiry(gracefulExpiry)
                                    ? GRACEFUL_EXPIRY_ERROR_TOOLTIP
                                    : ''
                            }
                        >
                            <StyledButton
                                label="Rotate"
                                type="submit"
                                appearance="primary"
                                size="small"
                                disabled={!isValidGracefulExpiry(gracefulExpiry)}
                            />
                        </Tooltip>
                    </StyledFooter>
                </StyledForm>
            </StyledPanel>
        </ThemeProvider>
    );

    function handleSubmit(e) {
        e.preventDefault();

        onSubmit(gracefulExpiry);
    }
}

RotateTokenModal.propTypes = {
    themeKey: PropTypes.string.isRequired,
    tokenName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
};
