import templateUrl from './groupbyPicker.tpl.html';

angular
    .module('metadataFunctionInputs')
    .controller('groupbyPickerController', [
        '$scope',
        'detectorUtils',
        'suggestAPIService',
        'plotToSignalflowV2',
        function ($scope, detectorUtils, suggestAPIService, plotToSignalflowV2) {
            $scope.valid = true;

            const $ctrl = this;
            $ctrl.$onInit = function () {
                $scope.localValue = angular.copy($scope.$ctrl.model);
            };

            $scope.getSuggestions = function (v) {
                //return $q.when(['joe','bob','jane','jill']);
                const plots =
                    detectorUtils.getMetricPlotsForFunctionRule(
                        $scope.$ctrl.rule || {},
                        $scope.$ctrl.plots || []
                    ) || [];
                const programs = plots.map((plot) => {
                    return {
                        programText: plotToSignalflowV2.generate(plot, 'AUTOSUGGEST'),
                        packageSpecifications: '',
                    };
                });
                const defaultQuery = 'sf_isActive:true';

                return suggestAPIService.getSignalFlowSuggest({
                    programs: programs,
                    property: null,
                    partialInput: v,
                    limit: 10,
                    additionalFilters: [],
                    additionalReplaceOnlyFilters: [],
                    extraQuery: defaultQuery,
                });
            };
            $scope.$watchCollection('localValue', function (v) {
                $scope.valid = validate(v);
                $scope.$ctrl.onTypedUpdate({ value: angular.copy(v), validity: $scope.valid });
            });

            function validate(num) {
                return num === null || angular.isArray(num);
            }

            $scope.$ctrl.$onChanges = function (changes) {
                if (changes.model) {
                    const value = changes.model.currentValue;
                    $scope.localValue = angular.copy(value);
                }
            };
        },
    ])
    .component('groupbyPicker', {
        replace: true,
        controller: 'groupbyPickerController',
        bindings: {
            model: '<',
            definition: '<',
            plots: '<',
            rule: '<',
            onTypedUpdate: '&',
        },
        templateUrl,
    });
