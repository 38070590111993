export default [
    '$http',
    '$log',
    'API_URL',
    'featureEnabled',
    function ($http, $log, API_URL, featureEnabled) {
        const BASE_URL = API_URL + '/v2/token';

        function getTokenResourceEndpoint(name) {
            return BASE_URL + '/' + encodeURIComponent(name);
        }

        const svc = {
            get: function (name) {
                return $http.get(getTokenResourceEndpoint(name));
            },
            search: function (name, options = {}) {
                return $http
                    .get(BASE_URL, {
                        params: {
                            name: name,
                            offset: options.offset,
                            limit: options.limit,
                            orderBy: options.orderBy,
                        },
                    })
                    .then((resp) => resp.data)
                    .catch((e) => {
                        $log.error(e);
                        throw e;
                    });
            },
            fetchExpiringToken: function (name, expiryLimit, options = {}) {
                return $http
                    .get(BASE_URL + '/expires?expiryLimit=' + expiryLimit, {
                        params: {
                            name: name,
                            offset: options.offset,
                            limit: options.limit,
                        },
                    })
                    .then((resp) => resp.data)
                    .catch((e) => {
                        $log.error(e.message);
                        throw e;
                    });
            },
            rename: function (oldName, newName, permissions, selectedRoleIds) {
                return $http
                    .get(getTokenResourceEndpoint(oldName))
                    .then(function (resp) {
                        const token = resp.data;
                        token.name = newName;
                        token.permissions = permissions;
                        token.roles = selectedRoleIds;

                        return $http.put(getTokenResourceEndpoint(oldName), token);
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            setLimits: function (
                name,
                dpmLimit,
                dpmNotificationLimit,
                notifications,
                jobLimit,
                eventSearchLimit,
                categoryLimit,
                categoryNotificationLimit,
                mtsmLimit
            ) {
                return $http
                    .get(getTokenResourceEndpoint(name))
                    .then(function (resp) {
                        const token = resp.data;
                        if (!token.limits) token.limits = {};

                        token.limits.dpmQuota = dpmLimit;
                        token.limits.dpmNotificationThreshold = dpmNotificationLimit;
                        token.limits.categoryQuota = categoryLimit;
                        token.limits.categoryNotificationThreshold = categoryNotificationLimit;
                        token.notifications = notifications;

                        if (featureEnabled('tokenApiLimits')) {
                            token.limits.jobsPerMinuteLimit = jobLimit;
                            token.limits.eventSearchesPerMinute = eventSearchLimit;
                            if (featureEnabled('tokenApiLimitMtsm')) {
                                token.limits.mtsmLimit = mtsmLimit;
                            }
                        }
                        return $http.put(getTokenResourceEndpoint(name), token);
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            disable: function (name) {
                return $http
                    .get(getTokenResourceEndpoint(name))
                    .then(function (resp) {
                        const token = resp.data;
                        token.disabled = true;
                        return $http.put(getTokenResourceEndpoint(name), token);
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            enable: function (name) {
                return $http
                    .get(getTokenResourceEndpoint(name))
                    .then(function (resp) {
                        const token = resp.data;
                        token.disabled = false;
                        return $http.put(getTokenResourceEndpoint(name), token);
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            create: function (name, authScopes, permissions, selectedRoleIds) {
                const entity = { name, description: '', authScopes, roles: selectedRoleIds };
                if (permissions) {
                    entity.permissions = permissions;
                }

                return $http.post(BASE_URL, entity).then(function (resp) {
                    return resp.data;
                });
            },
            rotate: function (name, gracefulExpiry) {
                if (!featureEnabled('rotateOrgToken')) {
                    return Promise.resolve();
                }
                return $http
                    .post(getTokenResourceEndpoint(name) + `/rotate?graceful=${gracefulExpiry}`)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
        };
        return svc;
    },
];
