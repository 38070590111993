import templateUrl from './signalFlowModal.tpl.html';

angular
    .module('signalflowv2')
    .factory('showSignalFlowModal', [
        'sfxModal',
        function (sfxModal) {
            return function (signalflow, modalTitle) {
                sfxModal.open({
                    templateUrl,
                    controller: 'signalFlowModalController',
                    windowClass: 'signalflow-view-modal',
                    backdrop: 'static',
                    resolve: {
                        signalflow: function () {
                            return signalflow;
                        },
                        modalTitle: function () {
                            return modalTitle;
                        },
                    },
                });
            };
        },
    ])
    .controller('signalFlowModalController', [
        '$scope',
        '$rootScope',
        'signalflow',
        'modalTitle',
        'aceUtils',
        function ($scope, $rootScope, signalflow, modalTitle, aceUtils) {
            $scope.signalflow = signalflow;
            $scope.modalTitle = modalTitle;

            let aceInstance = null;
            $scope.aceOption = {
                mode: 'python',
                onLoad: function (ace) {
                    aceInstance = ace;
                    aceUtils.updateTheme(aceInstance);
                    aceInstance.setOptions({
                        readOnly: true,
                        highlightActiveLine: false,
                        highlightGutterLine: false,
                        showPrintMargin: false,
                        maxLines: 40,
                    });
                    aceInstance.renderer.$cursorLayer.element.style.display = 'none';
                },
            };
            $rootScope.$on('theme update', function () {
                aceUtils.updateTheme(aceInstance);
            });
        },
    ]);
