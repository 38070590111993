export default [
    'cookieHelper',
    'SF_REALM_NUMBER',
    'DOMAIN_INFO',
    function (cookieHelper, SF_REALM_NUMBER, DOMAIN_INFO) {
        const KEY = 'SF_LAST_ACTIVE';
        const CUSTOM_DOMAIN_KEY = 'SF_CUSTOM_DOMAIN';
        //the top level domain could also be included as a config.
        const TL_DOMAIN = '.signalfx.com';

        function clear() {
            cookieHelper.clear(KEY);
            cookieHelper.clear(CUSTOM_DOMAIN_KEY);
        }

        function set() {
            const expireDate = new Date();
            expireDate.setFullYear(expireDate.getFullYear() + 1);
            const realm = SF_REALM_NUMBER;
            const options = {
                expires: expireDate,
                domain: TL_DOMAIN,
            };
            if (realm) {
                cookieHelper.set(KEY, realm, options);
            }
            if (DOMAIN_INFO && DOMAIN_INFO.domain) {
                cookieHelper.set(CUSTOM_DOMAIN_KEY, DOMAIN_INFO.fullDomain, options);
            } else {
                cookieHelper.clear(CUSTOM_DOMAIN_KEY);
            }
        }

        return {
            set,
            clear,
        };
    },
];
