import {
    isNodeFilter,
    isNodeBinaryOp,
    isNodeString,
    isNodeNone,
} from './signalflowAstNodeVerifiers.js';

import { extractString, extractStringArray } from './signalflowAstFunctionArgumentsExtractors.js';

export function extractApmCustomFilters(ref, key) {
    const customFilters = [];
    const nodeQueue = [ref];

    while (nodeQueue.length) {
        const node = nodeQueue.shift();
        if (!node || isNodeNone(node)) {
            return [];
        } else if (isSupportedApmSingleCustomFilterNode(node)) {
            customFilters.push(extractApmSingleCustomFilter(node));
        } else if (isNodeBinaryOp(node, 'OR')) {
            nodeQueue.unshift(node.left, node.right);
        } else {
            throw new Error(`Parameter ${key} is not a valid apm custom filter.`);
        }
    }

    return customFilters;
}

function isSupportedApmSingleCustomFilterNode(ref) {
    return (
        ref.type === 'filter' &&
        ref.field &&
        ref.field.type === 'string' &&
        ref.value &&
        ref.value.type === 'string'
    );
}

function extractApmSingleCustomFilter(ref) {
    const property = ref.field.value;
    const allValues = ref.value.value.split(',');
    const value = allValues[0];
    let propertyValue;

    if (allValues.length > 1) {
        propertyValue = allValues;
    } else {
        propertyValue = value;
    }

    return {
        iconClass: 'icon-properties',
        type: 'property',
        property,
        propertyValue,
        value,
        NOT: false,
    };
}

export function extractApmServiceEndpoints(ref, key) {
    if (isSupportedApmFilterNode(ref)) {
        if (isSupportedApmSingleServiceFilterNode(ref)) {
            return extractSingleServiceFilter(ref);
        } else if (isSupportedApmClusterServiceEndpointsNode(ref)) {
            return extractSingleServiceFilter(ref.left, key);
        } else if (isSupportedApmClusterMultiServiceEndpointsNode(ref)) {
            return extractApmServiceListSegment(ref.left);
        } else {
            let singleServiceFilterNode;
            if (isSupportedApmServiceListSegmentNode(ref)) {
                singleServiceFilterNode = ref.right;
            } else if (isLegacyApmClusterMultiServiceEndpointsNode(ref)) {
                singleServiceFilterNode = ref.right.left;
            }

            const leftFilterList = extractApmServiceListSegment(ref.left);
            const rightMetadata = extractSingleServiceFilter(singleServiceFilterNode, key);

            return leftFilterList.concat(rightMetadata);
        }
    }
    throw new Error(`Parameter ${key} is not a valid apm filter.`);
}

export function extractApmEnvironment(ref, key) {
    if (isSupportedApmSingleServiceFilterNode(ref) || isSupportedApmServiceListSegmentNode(ref)) {
        return undefined;
    } else if (isSupportedApmClusterServiceEndpointsNode(ref)) {
        return extractClusterFilter(ref.right, key);
    } else if (isSupportedApmClusterMultiServiceEndpointsNode(ref)) {
        return extractClusterFilter(ref.right, key);
    } else if (isLegacyApmClusterMultiServiceEndpointsNode(ref)) {
        return extractClusterFilter(ref.right.right, key);
    }

    throw new Error(`Parameter ${key} is not a valid apm filter.`);
}

function extractClusterFilter(ref, key) {
    if (isNodeFilter(ref, 'cluster')) {
        return ref.value.value;
    }
    throw new Error(`Parameter ${key} is not a valid apm filter.`);
}

function extractSingleServiceFilter(ref, key) {
    if (isSupportedApmSingleServiceFilterNode(ref)) {
        const endpointsNode = ref.right;

        return [
            {
                _endpoints: isNodeString(endpointsNode.value)
                    ? [extractString(endpointsNode.value)]
                    : extractStringArray(endpointsNode.values),
                _service: ref.left.value.value,
            },
        ];
    }
    throw new Error(`Parameter ${key} is not a valid apm filter.`);
}

function extractApmServiceListSegment(ref) {
    let filterList = [];

    if (isSupportedApmSingleServiceFilterNode(ref)) {
        return extractSingleServiceFilter(ref);
    }

    if (isSupportedApmSingleServiceFilterNode(ref.left)) {
        filterList = filterList.concat(extractSingleServiceFilter(ref.left));
    } else {
        filterList = filterList.concat(extractApmServiceListSegment(ref.left));
    }

    filterList = filterList.concat(extractSingleServiceFilter(ref.right));
    return filterList;
}

export function isSupportedApmFilterNode(ref) {
    if (
        isSupportedApmSingleServiceFilterNode(ref) ||
        isSupportedApmClusterServiceEndpointsNode(ref) ||
        isSupportedApmServiceListSegmentNode(ref) ||
        isSupportedApmClusterMultiServiceEndpointsNode(ref) ||
        isLegacyApmClusterMultiServiceEndpointsNode(ref)
    ) {
        return true;
    }
}

function isSupportedApmClusterMultiServiceEndpointsNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmServiceListSegmentNode(ref.left) &&
        isSupportedApmClusterFilterNode(ref.right)
    );
}

// this is support for legacy, invalid signalflow, fixed with this change:
// commit hash: 0595d664892b5f3908143606ff5172611f8c9c26
// Issue: https://signalfuse.atlassian.net/browse/APMF-1983
function isLegacyApmClusterMultiServiceEndpointsNode(ref) {
    return (
        isNodeBinaryOp(ref, 'OR') &&
        isSupportedApmServiceListSegmentNode(ref.left) &&
        isSupportedApmClusterServiceEndpointsNode(ref.right)
    );
}

function isSupportedApmServiceListSegmentNode(ref) {
    if (isSupportedApmSingleServiceFilterNode(ref)) {
        return true;
    }

    return (
        isNodeBinaryOp(ref, 'OR') &&
        isSupportedApmServiceListSegmentNode(ref.left) &&
        isSupportedApmSingleServiceFilterNode(ref.right)
    );
}

function isSupportedApmClusterServiceEndpointsNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isSupportedApmSingleServiceFilterNode(ref.left) &&
        isSupportedApmClusterFilterNode(ref.right)
    );
}

function isSupportedApmSingleServiceFilterNode(ref) {
    return (
        isNodeBinaryOp(ref, 'AND') &&
        isNodeFilter(ref.left, 'service') &&
        isNodeFilter(ref.right, 'operation')
    );
}

function isSupportedApmClusterFilterNode(ref) {
    return isNodeFilter(ref, 'cluster');
}
