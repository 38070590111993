import nameDescriptionTemplateUrl from '../editors/namedescription.tpl.html';
import { NotificationCategory, NotificationService } from '@splunk/olly-common';

/**
 * A 'page' (= 'dashboard group') in SignalView is akin to a profile page for a
 * particular topic object. Pages can be referenced by name (for a particular
 * topic) or by ID.
 */
angular.module('signalview.page').service('pageService', [
    '$log',
    '$location',
    'sfxModal',
    '$rootScope',
    '$timeout',
    '$window',
    'ES_INDEXING_DELAY',
    'pageDisplayTitle',
    'signalboostUtil',
    'userAnalytics',
    'dashboardGroupService',
    'dashboardGroupUtil',
    'dashboardV2Service',
    'teamLinkingService',
    'appNotificationService',
    'currentUser',
    'dashboardUtil',
    'urlOverridesService',
    'featureEnabled',
    function (
        $log,
        $location,
        sfxModal,
        $rootScope,
        $timeout,
        $window,
        ES_INDEXING_DELAY,
        pageDisplayTitle,
        signalboostUtil,
        userAnalytics,
        dashboardGroupService,
        dashboardGroupUtil,
        dashboardV2Service,
        teamLinkingService,
        appNotificationService,
        currentUser,
        dashboardUtil,
        urlOverridesService,
        featureEnabled
    ) {
        function teamRelationEditor(page) {
            return teamLinkingService
                .editDashboardGroup(page)
                .then(({ updatedTeams, addedTeams, removedTeams }) => {
                    if (page.id) {
                        page.teams = updatedTeams;
                    } else {
                        page.sf_teams = updatedTeams;
                    }
                    return dashboardGroupService
                        .updateTeamLinks(page.sf_id || page.id, updatedTeams)
                        .then(() => {
                            const notification = generateTeamRelationsNotification(
                                addedTeams,
                                removedTeams
                            );
                            appNotificationService.add(notification);
                            NotificationService.post({
                                category: NotificationCategory.SNACKBAR,
                                subCategory: 'dashboard-group',
                                message: notification.message,
                                callToAction: {
                                    link: notification.link?.href,
                                    ctaString: notification.link?.text,
                                },
                            });
                        });
                });
        }

        function generateTeamRelationsNotification(addedTeams, removedTeams) {
            const notification = { dismissAfterMs: 4000, userDismissable: true };

            if (addedTeams.length && removedTeams.length) {
                notification.message =
                    `Dashboard group successfully added to ${addedTeams.length} teams` +
                    ` and removed from ${removedTeams.length} teams.`;
            } else if (addedTeams.length) {
                if (addedTeams.length > 1) {
                    notification.message = `Dashboard group successfully added to ${addedTeams.length} teams.`;
                } else {
                    notification.message = `Dashboard group successfully added to ${addedTeams[0].teamName}.`;
                    notification.link = {
                        text: 'Go to team page',
                        href: `#/team/${addedTeams[0].teamId}`,
                    };
                }
            } else if (removedTeams.length) {
                if (removedTeams.length > 1) {
                    notification.message = `Dashboard group successfully removed from ${removedTeams.length} teams.`;
                } else {
                    notification.message = `Dashboard group successfully removed from ${removedTeams[0].teamName}.`;
                    notification.link = {
                        text: 'Go to team page',
                        href: `#/team/${removedTeams[0].teamId}`,
                    };
                }
            }
            return notification;
        }

        /**
         * Shows a modal window with spinner and deletes the dashboard group.
         *
         * @param {Object} page - The dashboard group to delete.
         */

        function deleteDashboardGroup(page) {
            const modalInstance = sfxModal.open({
                template: '<div><i class="busy-spinner-light"></i></div>',
                windowClass: 'full-screen-busy-spinner',
                backdrop: 'static',
                keyboard: false,
            });

            return dashboardGroupUtil.deleteGroup(page.id || page.sf_id).then(
                () => {
                    $timeout(() => {
                        $rootScope.$broadcast('pageDeleted', page.id || page.sf_id);
                        modalInstance.close();
                        urlOverridesService.clearAll();
                        $location.path('/dashboards');
                    }, ES_INDEXING_DELAY);

                    userAnalytics.event('page', 'delete');
                },
                function (e) {
                    $window.alert(
                        'Failed deleting page.' + (e && e.data && e.data.message)
                            ? e.data.message
                            : ''
                    );
                    modalInstance.close();
                }
            );
        }

        /**
         * @param {Object[]} groupById - A mapping from group ID to a group object.
         * @param {Object[]} dashboards - An array of dashboard objects.
         * @returns A mapping from a group ID to that group's dashboards.
         */
        function collectDashboardsByGroupId(groupById, dashboards) {
            const dashboardsByGroupId = {};
            const dashboardMap = {};
            dashboards.forEach((dashboard) => {
                const dashboardId = dashboard.id || dashboard.sf_id;
                dashboardMap[dashboardId] = dashboard;
            });

            angular.forEach(groupById, function (group, groupId) {
                dashboardsByGroupId[groupId] = [];
                // this maintains the ordering of the group's dashboards
                group.sf_dashboardConfigs.forEach((config) => {
                    const dashboard = dashboardMap[config.dashboardId];
                    if (dashboard) {
                        dashboardsByGroupId[groupId].push(dashboard);
                    }
                });
            });

            return dashboardsByGroupId;
        }

        function isEditableBy(page, user) {
            return (
                user &&
                (signalboostUtil.isMemberOf(page, user.sf_id) || page.sf_creator === user.sf_id)
            );
        }

        function editPageName(page) {
            return sfxModal.open({
                templateUrl: nameDescriptionTemplateUrl,
                controller: 'NameDescriptionController',
                windowClass: 'name-description-modal',
                resolve: {
                    params: function () {
                        return { obj: page };
                    },
                    defaultWriters: currentUser.defaultWritePermissions(),
                },
            }).result;
        }

        function getDashboardConfigsInGroup(groupId, dashboardId) {
            return dashboardGroupService.get(groupId).then((group) => {
                group.dashboardConfigs = group.dashboardConfigs || [];

                return group.dashboardConfigs
                    .filter((config) => {
                        return config.dashboardId === dashboardId;
                    })
                    .map((config) => config.configId);
            });
        }

        function importDashboard(page, dashboardId) {
            const groupId = page.sf_id || page.id;
            return getDashboardConfigsInGroup(groupId, dashboardId).then((existingConfigs) => {
                return dashboardV2Service.get(dashboardId).then((dashboard) => {
                    // TODO(trevor): Remove/refactor once groupId is deprecated
                    dashboard.groupId = groupId;
                    if (featureEnabled('accessControl')) {
                        _assignDefaultAclPermissions(dashboard, groupId);
                    }
                    return dashboardV2Service
                        .update(dashboard)
                        .then(() => {
                            return getDashboardConfigsInGroup(groupId, dashboardId);
                        })
                        .then((configs) => {
                            const configId = configs.filter(
                                (configId) => !existingConfigs.includes(configId)
                            )[0];
                            const dashboardViewsParams = { groupId };
                            if (configId) {
                                dashboardViewsParams.configId = configId;
                            }

                            $location.search({});
                            dashboardUtil.addDashboardSearchParams(dashboardViewsParams);

                            $location.path('/dashboard/' + dashboardId);
                        });
                });
            });
        }

        function _assignDefaultAclPermissions(dashboard, dashboardGroupId) {
            const permissions = {
                parent: dashboardGroupId,
                acl: null,
            };
            Object.assign(dashboard, { permissions });
        }

        return {
            collectDashboardsByGroupId,
            isEditableBy,
            teamRelationEditor,
            editPageName,
            importDashboard,
            deleteDashboardGroup,
        };
    },
]);
