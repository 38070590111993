import { context, Span, SpanStatusCode, trace } from '@opentelemetry/api';
import { startSpanWithMMS } from '@splunk/olly-tracing/utils';
import { memoize, once } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import {
    getModuleLoadStatus,
    loadModule,
    ModuleResourceLoadState,
    noopComponent,
} from './lazyModuleResourceLoader';
import { ModuleArtifact } from './types';

const createLoadSpan = memoize(
    (globalPropertyName: string): Span =>
        startSpanWithMMS('signalview/LazyModule', `bootstrap.${globalPropertyName}`)
);

type MemoizedModuleEntryComponentProps<A = Record<string, unknown>> = {
    ModuleEntryComponent: React.ComponentType<A>;
    moduleProps: A;
};

/**
 * Component to memoize the entry component so it rerenders only when the module props have changed.
 * This is mostly a precautionary measure against future state changes that might be introduced and
 * not protected against at a higher memoization layer.
 */
// eslint-disable-next-line react/display-name
const MemoizedModuleEntryComponent = React.memo(
    ({ ModuleEntryComponent, moduleProps }: MemoizedModuleEntryComponentProps): JSX.Element => (
        <ModuleEntryComponent {...moduleProps} />
    )
);

type LazyModuleProps = {
    /** Artifact defining module to load */
    artifact: ModuleArtifact;
    traceLoad?: boolean;
};

/**
 * Load a module. Waits for the module to be ready, then instantiates the entry component
 * with the ModuleProps.
 *
 * Assumes it is wrapped in a <Suspense> component to handle fallback when module is loading. This means
 * that the component will be frozen from updates until the thrown load Promise resolves successfully,
 * which avoids multiple "load" calls from being triggered from state changes.
 *
 * The `traceLoad` flag should only be set if the app module correctly handles the `onLoad` prop.
 * i.e. it calls the callback when it has finished bootstrapping and includes information about whether the load was successful.
 */
export const LazyModule = ({
    artifact,
    traceLoad = false,
    ...componentProps
}: LazyModuleProps & Record<string, unknown>): JSX.Element => {
    const moduleLoadCalled = useRef<boolean>(false);
    const [loadSpan, setLoadSpan] = useState<Span | null>(null);
    const loadContext = useMemo(
        () => (loadSpan ? trace.setSpan(context.active(), loadSpan as Span) : context.active()),
        [loadSpan]
    );

    // Get module load status
    const { entryComponent: ModuleEntryComponent, status } = getModuleLoadStatus(artifact) || {
        entryComponent: noopComponent,
    };
    if (!moduleLoadCalled.current) {
        // If it has been already loaded on the very first check
        // No need to add a load span because there is no load
        if (traceLoad && status !== ModuleResourceLoadState.DONE) {
            setLoadSpan(createLoadSpan(artifact.globalPropertyName));
        }
        moduleLoadCalled.current = true;
    }

    // Module has failed loading
    if (status === ModuleResourceLoadState.ERROR) {
        loadSpan?.setStatus({ code: SpanStatusCode.ERROR }).end();
        throw new Error(
            `Unable to load module: ${artifact.globalPropertyName}, JS: ${artifact.bundleJS}, CSS: ${artifact.bundleCSS}`
        );
    }

    // Module is loaded, instatiate entry component
    if (status === ModuleResourceLoadState.DONE) {
        const onLoad = once((hasError?: boolean) =>
            loadSpan
                ?.setStatus({ code: hasError ? SpanStatusCode.ERROR : SpanStatusCode.UNSET })
                .setAttribute('error', !!hasError)
                .end()
        );
        const allModuleProps = { ...componentProps, onLoad, loadContext };

        return (
            <MemoizedModuleEntryComponent
                ModuleEntryComponent={ModuleEntryComponent}
                moduleProps={allModuleProps}
            />
        );
    }

    // Attempt to load module
    // The React Suspense component uses a thrown promise as the indicator to show the fallback
    //  instead of rendering the component.
    // See https://reactjs.org/docs/concurrent-mode-suspense.html for more information
    throw context.with(loadContext, () => loadModule(artifact));
};
