import { AngularInjection } from '../../common/AngularUtils';
import { Switch, Route, useInitializeRoute } from './ReactRouteUtils';
import { Redirect, useLocation } from 'react-router-dom';
import { Ng2React } from '../../common/util/Ng2React';
import standaloneChart from '../../legacy/app/charting/chart/standaloneChart';
import { chartNav } from '../../legacy/app/signalflowv2/chart/chartV2Nav';
import { chartV2Data, chartV2NewData } from '../../legacy/app/signalflowv2/chart/chartRouteData';
import { ValidatedChartRedirect } from '../charting/chartbuilder/ValidatedChartRedirect';
import React, { FC, ReactNode } from 'react';

const snapShotData: AngularInjection = [
    // declare-used-dependency-to-linter::snapshotRouteDataService
    'snapshotRouteDataService',
    (snapshotRouteDataService): Promise<any> => snapshotRouteDataService.getData(),
];

const StandAloneChartRoutes: FC = () => {
    const routeUtilsBootStrap = useInitializeRoute();
    const { search } = useLocation();

    return (
        <Switch>
            <Redirect
                exact
                from="/_/temp/chart/:snapshotID"
                to={{ pathname: '/_/temp/chart/v2/:snapshotID', search }}
            />

            <Route
                exact
                path="/_/temp/chart/v2/:snapshotID"
                title="Chart"
                reloadWithParams="path"
                straps={[routeUtilsBootStrap]}
                resolves={{ data: snapShotData }}
            >
                <Ng2React name="standaloneChart" definition={standaloneChart} fill />
            </Route>

            <Redirect
                exact
                from="/_/chart/:chartId"
                to={{ pathname: '/_/chart/v2/:chartId', search }}
            />

            <Route exact path="/_/chart/v2/new" resolves={{ data: chartV2NewData }}>
                <Ng2React name="standaloneChart" definition={standaloneChart} fill />
            </Route>

            <Route
                exact
                reloadWithParams="path"
                path="/_/chart/v2/:id"
                resolves={{ data: chartV2Data }}
            >
                <Ng2React name="standaloneChart" definition={standaloneChart} fill />
            </Route>
        </Switch>
    );
};

const SnapShotChartRoutes: FC = () => {
    const routeUtilsBootStrap = useInitializeRoute();
    const { search } = useLocation();

    return (
        <Switch>
            <Redirect
                exact
                from="/temp/chart/:snapshotID"
                to={{ pathname: '/temp/chart/v2/:snapshotID', search }}
            />

            <Route
                exact
                path="/temp/chart/v2/:snapshotID"
                title="Chart"
                reloadWithParams="path"
                straps={[routeUtilsBootStrap]}
                resolves={{ data: snapShotData }}
            >
                <Ng2React name="chartNav" definition={chartNav} />
            </Route>

            <Route path="/_/temp/chart">
                <StandAloneChartRoutes />
            </Route>
        </Switch>
    );
};

const OrgChartRoutes: FC = () => {
    const routeUtilsBootStrap = useInitializeRoute();
    const { search } = useLocation();

    return (
        <Switch>
            <Redirect from="/chart/new" to={{ pathname: '/chart/v2/new', search }} />

            <Route
                exact
                path={['/chart/:chartId', '/chart/:chartId/edit', '/chart/:chartId/view']}
                render={({ match }): ReactNode => {
                    return <ValidatedChartRedirect chartId={match.params.chartId} />;
                }}
            />

            <Route
                exact
                path="/chart/v2/new"
                title="New Chart"
                straps={[routeUtilsBootStrap]}
                resolves={{ data: chartV2NewData }}
            >
                <Ng2React name="chartNav" definition={chartNav} />
            </Route>

            <Route
                exact
                path="/chart/v2/:id"
                title="Chart"
                reloadWithParams="path"
                straps={[routeUtilsBootStrap]}
                resolves={{ data: chartV2Data }}
            >
                <Ng2React name="chartNav" definition={chartNav} />
            </Route>
        </Switch>
    );
};

export const ChartRoutes: FC = () => {
    return (
        <Switch>
            <Route path="/chart">
                <OrgChartRoutes />
            </Route>

            <Route path="/temp/chart">
                <SnapShotChartRoutes />
            </Route>

            <Route path={['/_/temp/chart', '/_/chart']}>
                <StandAloneChartRoutes />
            </Route>
        </Switch>
    );
};
