import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ControlGroup from '@splunk/react-ui/ControlGroup';
import Tooltip from '@splunk/react-ui/Tooltip';
import useInputState from '../../../common/react-utils/useInputState';
import AccessControl from '../../../common/ui/accessControl/AccessControl';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import AclDashboardGroupSelector from './AclDashboardGroupSelector';
import { SourceFiltersIsolateReact } from '../../../legacy/app/dashboard/sourceFiltersIsolateReact';
import AclDashboardGroupService from '../../../legacy/app/dashboardGroup/aclDashboardGroup/AclDashboardGroupService';
import { AclFormContext } from '../../../common/ui/accessControl/AclFormContext';
import {
    StyledPanel,
    StyledButton,
    StyledFooter,
    StyledBody,
    StyledDescription,
    StyledModalHeader,
    StyledForm,
} from '../../../common/ui/ModalStyles';
import useAclOrgPreferencesContext from '../../../common/ui/accessControl/useAclOrgPreferencesContext';
import useAclUserContext from '../../../common/ui/accessControl/useAclUserContext';
import AclFormInput from '../../../common/ui/accessControl/AclFormInput';
import useAclPermissions from '../../../common/ui/accessControl/useAclPermissions';
import aclValidator from '../../../common/ui/accessControl/aclValidator';
import { ThemeProvider } from '../../../common/theme/ThemeProvider';

const OBJECT_TYPE = AccessControlObjectType.MIRROR;

const MODAL_TITLE = 'Add a mirror of this dashboard';
const MODAL_DESCRIPTION =
    'Adding a mirror of this dashboard to the current or another dashboard group means the same dashboard will appear multiple times. Changes to the dashboard itself will be visible in all of its mirrors in any dashboard groups in which they appear. Users of a dashboard group can customize a mirror for themselves using overrides. Use save as to create a separate copy instead.';
const DASHBOARD_GROUP_LABEL = 'My dashboard group';
const DASHBOARD_NAME_OVERRIDE_LABEL = 'Dashboard name override';
const DASHBOARD_DESC_OVERRIDE_LABEL = 'Dashboard description override';
const FILTER_OVERRIDES_LABEL = 'Default filter overrides';
const SAVE_BUTTON_LABEL = 'Save';
const CANCEL_BUTTON_LABEL = 'Cancel';

const StyledControlGroup = styled(ControlGroup)`
    & {
        source-filters-isolate,
        .dashboard-filter-scroller,
        .sourceeditparent {
            min-width: 100%;
        }
    }
`;

export default function CreateAclMirror({
    dashboardModel,
    chartsList,
    initialSourceFilters,
    defaultDashboardGroupId,
    userData,
    themeKey,
    onDismiss,
    onClose,
    onSuccess,
}) {
    const userContext = useAclUserContext(userData);
    const [defaultPermissions] = useState({
        acl: null,
        parent: defaultDashboardGroupId,
    });
    const orgPrefContext = useAclOrgPreferencesContext(userContext.orgId);

    const {
        permissions,
        parentPermissions: dashboardGroupPermissions,
        update: updatePermissions,
        userEffectiveActions,
        isLoading,
    } = useAclPermissions(userContext, null, defaultPermissions);

    const [name, setName] = useInputState(dashboardModel.name);
    const [description, setDescription] = useInputState('');
    const [dashboardGroup, setDashboardGroup] = useState(null);

    const aclDashboardGroupService = AclDashboardGroupService.useInstance();
    const [sourceFilters] = useState(initialSourceFilters || []);

    const [saveInProgress, setSaveInProgress] = useState(false);

    const validationErrors = [
        ...validateForm(),
        ...aclValidator.getNonWritableObjectError(permissions, userEffectiveActions, OBJECT_TYPE),
    ];
    const canSave = !saveInProgress && !isLoading && !validationErrors.length;

    return (
        <ThemeProvider colorScheme={themeKey}>
            <AclFormContext.Provider
                value={{
                    ...userContext,
                    disabled: isLoading || saveInProgress,
                    syncInProgress: isLoading,
                    restrictTeamAccess: orgPrefContext.sf_restrictTeamManagement,
                }}
            >
                <StyledPanel>
                    <StyledForm onSubmit={handleSubmit}>
                        <StyledModalHeader>
                            {MODAL_TITLE}
                            <StyledDescription>{MODAL_DESCRIPTION}</StyledDescription>
                        </StyledModalHeader>
                        <StyledBody>
                            <div className="form-group">
                                <label htmlFor="dashboard-group" className="sfx-label">
                                    {DASHBOARD_GROUP_LABEL}
                                </label>
                                <AclDashboardGroupSelector
                                    disabled={false}
                                    defaultDashboardGroupIdId={defaultDashboardGroupId}
                                    onReset={() => onDashboardGroupUpdate(null, null)}
                                    onChange={(dashboardGroupModel, acl) =>
                                        onDashboardGroupUpdate(dashboardGroupModel, acl)
                                    }
                                />
                            </div>
                            <AclFormInput
                                name="dashboard-name"
                                label={DASHBOARD_NAME_OVERRIDE_LABEL}
                                value={name}
                                onChange={setName}
                            />
                            <AclFormInput
                                name="dashboard-description"
                                label={DASHBOARD_DESC_OVERRIDE_LABEL}
                                value={description}
                                onChange={setDescription}
                            />
                            <StyledControlGroup label={FILTER_OVERRIDES_LABEL} labelPosition="top">
                                <SourceFiltersIsolateReact
                                    allCharts={chartsList}
                                    sourceFilters={sourceFilters}
                                    showFilterOptions={true}
                                    dontUpdateUrl={true}
                                    hideLabel={true}
                                />
                            </StyledControlGroup>
                            <AccessControl
                                permissions={permissions}
                                parentPermissions={dashboardGroupPermissions}
                                parentObjectId={dashboardGroup?.id || dashboardGroup?.sf_id}
                                objectType={OBJECT_TYPE}
                                readonly={true}
                            />
                        </StyledBody>
                        <StyledFooter>
                            <StyledButton
                                label={CANCEL_BUTTON_LABEL}
                                appearance="secondary"
                                size="small"
                                onClick={() => onDismiss('cancel')}
                            />
                            <Tooltip
                                style={{ marginLeft: 10 }}
                                content={validationErrors.length ? validationErrors[0] : ''}
                            >
                                <StyledButton
                                    label={SAVE_BUTTON_LABEL}
                                    type="submit"
                                    appearance="primary"
                                    size="small"
                                    disabled={!canSave}
                                />
                            </Tooltip>
                        </StyledFooter>
                    </StyledForm>
                </StyledPanel>
            </AclFormContext.Provider>
        </ThemeProvider>
    );

    function onDashboardGroupUpdate(dashboardGroupModel, dashboardGroupAcl) {
        updatePermissions(
            { ...permissions, parent: dashboardGroupModel?.id },
            dashboardGroupAcl ? { acl: dashboardGroupAcl } : null
        );
        setDashboardGroup(dashboardGroupModel);
    }

    function validateForm() {
        const validationErrors = [];
        if (dashboardGroup === null) {
            validationErrors.push('Please select dashboard group');
        }
        return validationErrors;
    }

    function getNameOverride() {
        return name === dashboardModel.name ? null : name;
    }

    function getDescriptionOverride() {
        return description === dashboardModel.description ? null : description;
    }

    function getFiltersToSave() {
        let filtersToSave;
        if (!initialSourceFilters && sourceFilters.length === 0) {
            filtersToSave = null;
        } else {
            filtersToSave = {
                sources: sourceFilters.map((filter) => ({
                    ...filter,
                    value: filter.propertyValue || filter.value,
                })),
                time: null,
                density: null,
            };
        }

        return filtersToSave;
    }

    function handleDismiss(error) {
        onDismiss(error);
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validationErrors.length) return;
        setSaveInProgress(true);

        const filtersToSave = getFiltersToSave();

        aclDashboardGroupService
            .addDashboardToGroup(dashboardGroup.id, dashboardModel.id, {
                nameOverride: getNameOverride(),
                descriptionOverride: getDescriptionOverride(),
                filtersOverride: filtersToSave,
            })
            .then((configId) => onSuccess(dashboardGroup.id, configId, filtersToSave))
            .then(() => onClose({ sameGroup: dashboardGroup.id === defaultDashboardGroupId }))
            .catch((e) => handleError(e));
    }

    function handleError(error) {
        window.alert('Failed creating a mirror');
        handleDismiss(error);
    }
}

CreateAclMirror.propTypes = {
    userData: PropTypes.object.isRequired,
    themeKey: PropTypes.string.isRequired,
    dashboardModel: PropTypes.object.isRequired,
    defaultDashboardGroupId: PropTypes.string,
    chartsList: PropTypes.array,
    initialSourceFilters: PropTypes.array,
    onDismiss: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

CreateAclMirror.defaultProps = {
    onDismiss: () => {},
    onClose: () => {},
    onSuccess: () => {},
};
