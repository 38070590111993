export default {
    requiredFilters: ['kubernetes_cluster'],
    METRICS: [
        {
            displayName: 'Phase',
            job: {
                filters: [
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_cluster',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_node',
                        type: 'property',
                    },
                    {
                        NOT: false,
                        property: '_exists_',
                        propertyValue: 'kubernetes_pod_name',
                        type: 'property',
                    },
                ],
                template:
                    "POD_PHASE = data('kubernetes.pod_phase'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2).promote(['kubernetes_cluster', 'kubernetes_node', 'kubernetes_pod_name'])",
                varName: 'POD_PHASE',
                unique_id: '{{ kubernetes_cluster }}{{ kubernetes_pod_uid }}',
            },
        },
    ],

    title: 'Pod Properties',

    dataGroup: [
        {
            /***** GROUP 1 *****/
            limit: 1,
            groupTitle: null,
            groupHeader: null,
            propertyMap: [
                {
                    displayName: 'Name',
                    format: 'id',
                    metric: 'Phase',
                    property: 'kubernetes_pod_name',
                },
                {
                    displayName: 'Pod ID',
                    format: 'id',
                    metric: 'Phase',
                    property: 'kubernetes_pod_uid',
                },
                {
                    displayName: 'Pod age',
                    format: 'Custom:Age',
                    metric: 'Phase',
                    property: 'pod_creation_timestamp',
                },
                {
                    displayName: 'Pod phase',
                    format: 'Custom:PodPhase',
                    metric: 'Phase',
                    property: null,
                },
            ],
        },
        {
            /***** GROUP 2: POD CONDITIONS 'TBD' NOT AVAILABLE NOW *****/
            /***** GROUP 3 *****/
            limit: 1,
            groupTitle: null,
            groupHeader: null,
            propertyMap: [
                {
                    displayName: 'Node',
                    format: null,
                    metric: 'Phase',
                    property: 'kubernetes_node',
                },
                {
                    displayName: 'Workload Type',
                    format: null,
                    metric: 'Phase',
                    property: 'kubernetes_workload',
                },
                {
                    displayName: 'Workload',
                    format: null,
                    metric: 'Phase',
                    property: 'deployment',
                },
                {
                    displayName: 'Namespace',
                    format: null,
                    metric: 'Phase',
                    property: 'kubernetes_namespace',
                },
            ],
        },
    ],
};
