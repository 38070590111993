import { supportService } from '@splunk/olly-services/lib';

angular
    .module('signalview.snapshot')

    .service('snapshotEditsService', [
        '$location',
        'dashboardUtil',
        'shareableSnapshotService',
        'currentUser',
        'newDashboardService',
        'dashboardV2Service',
        function (
            $location,
            dashboardUtil,
            shareableSnapshotService,
            currentUser,
            newDashboardService,
            dashboardV2Service
        ) {
            this.saveDashboardEdits = function (
                model,
                charts,
                reroute,
                originalSnapshot,
                ignoreSavedFilters,
                updateUnseen
            ) {
                const originalSnapshotId = originalSnapshot.id;
                model = dashboardV2Service.uiDashboardToAPI(model);
                return dashboardUtil
                    .getDashboardSnapshotPayload(model, charts, ignoreSavedFilters)
                    .then(function (payload) {
                        if (originalSnapshot && originalSnapshot.payload) {
                            if (originalSnapshot.payload.workspace) {
                                payload.workspace = true;
                            }
                            if (originalSnapshot.payload.parentName) {
                                payload.parentName = originalSnapshot.payload.parentName;
                            }
                            payload.originalSender =
                                originalSnapshot.payload.originalSender ||
                                originalSnapshot.sender ||
                                '';
                        }
                        return shareableSnapshotService.create(
                            shareableSnapshotService.types.Dashboard,
                            model.name,
                            model.description || '',
                            payload,
                            (originalSnapshot || {}).sourceId
                        );
                    })
                    .then(function (snapshot) {
                        if (reroute) {
                            $location.path('/temp/dashboard/' + snapshot.id);
                        }
                        // Update prefs if the dashboard that was updated is the "new
                        // dashboard" in this org
                        if (supportService.getSupportOrg()) {
                            return snapshot;
                        }
                        return currentUser
                            .orgPreferences()
                            .then(function (prefs) {
                                if (prefs.sf_newDashboard === originalSnapshotId) {
                                    const update = {
                                        sf_newDashboard: snapshot.id,
                                    };
                                    if (updateUnseen) {
                                        update['sf_numUnseenCharts'] = prefs.sf_numUnseenCharts + 1;
                                    }
                                    const hasNewCharts = !!snapshot.payload.dashboard.charts.length;
                                    newDashboardService.updateNewDashboardInfo(update, {
                                        hasNewCharts: hasNewCharts,
                                    });
                                }
                            })
                            .then(function () {
                                return snapshot;
                            });
                    });
            };
        },
    ]);
