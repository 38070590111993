angular.module('sfx.ui').service('displayNamesWithMore', function () {
    function shortenNames(variableNames) {
        const visibleNameLimit = 2;
        let index = 0;
        const namesToJoin = [];

        while (index < visibleNameLimit) {
            const name = variableNames[index];
            if (!name) {
                break;
            }
            namesToJoin.push(name.name);
            index += 1;
        }
        let joinedName = namesToJoin.join(', ');

        if (variableNames.length > visibleNameLimit) {
            joinedName += ', and ' + (variableNames.length - visibleNameLimit) + ' more';
        }

        return joinedName;
    }

    return {
        shortenNames,
    };
});
