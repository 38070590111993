export default [
    'cookieHelper',
    function (cookieHelper) {
        const ONE_HOUR_MS = 60 * 60 * 1000;
        const FIVE_MIN_MS = 5 * 60 * 1000;
        const KEY = 'SF_SSO_REDIRECT';
        const SAML_ISSUER = 'SF_SAML_ISSUER';

        function setIfEmpty(url) {
            cookieHelper.setIfEmpty(KEY, url, {
                expires: cookieHelper.msFromNowToExpireDate(ONE_HOUR_MS),
            });
        }

        function set(url) {
            cookieHelper.set(KEY, url, {
                expires: cookieHelper.msFromNowToExpireDate(ONE_HOUR_MS),
            });
        }

        function getAndClear() {
            const url = cookieHelper.get(KEY);
            cookieHelper.clear(KEY);
            return url;
        }

        function setIdpRequestInfo(issuer, url) {
            cookieHelper.set(SAML_ISSUER, issuer, {
                expires: cookieHelper.msFromNowToExpireDate(FIVE_MIN_MS),
            });
            cookieHelper.set(KEY, url, {
                expires: cookieHelper.msFromNowToExpireDate(ONE_HOUR_MS),
            });
        }

        function getIdpInfoAndClear() {
            const issuer = cookieHelper.get(SAML_ISSUER);
            cookieHelper.clear(SAML_ISSUER);

            return { issuer };
        }

        function isIdpFlow() {
            return !!cookieHelper.get(SAML_ISSUER);
        }

        return {
            setIfEmpty,
            set,
            getAndClear,
            setIdpRequestInfo,
            getIdpInfoAndClear,
            isIdpFlow,
        };
    },
];
