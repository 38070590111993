export default {
    requiredFilters: ['kubernetes_cluster', 'kubernetes_node'],

    BASE_METRIC_TEMPLATES: [
        {
            template:
                "NODE_READY = data('kubernetes.node_ready'{{#filter}}, filter={{{filter}}}{{/filter}}, rollup='latest', extrapolation='last_value', maxExtrapolations=2)",
        },
    ],

    METRICS: [
        {
            job: {
                varName: 'NODE_READY',
                unique_id: '{{kubernetes_cluster}}{{kubernetes_node}}',
            },
        },
    ],

    title: 'Node Properties',

    dataGroup: [
        {
            /***** GROUP 1 *****/
            groupTitle: null,
            groupHeader: null,
            propertyMap: [
                {
                    displayName: 'Name',
                    format: null,
                    metric: 'NODE_READY',
                    property: 'kubernetes_node',
                },
                {
                    displayName: 'ID',
                    format: null,
                    metric: 'NODE_READY',
                    property: 'kubernetes_node_uid',
                },
                {
                    displayName: 'Node Condition',
                    format: 'Custom:ResourceStatus',
                    metric: 'NODE_READY',
                    property: null,
                },
            ],
        },
    ],
};
