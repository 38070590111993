angular.module('signalview.analytics').constant('ROLLUPS', {
    RATE: {
        value: 'RATE_ROLLUP',
        displayName: 'Rate/sec',
        description:
            'For cumulative counters, divides delta by the length of the interval. For counters, divides sum by length of the interval. Useful for showing running totals (e.g. error count) as a rate (e.g. number of errors per second)',
    },
    SUM: {
        value: 'SUM_ROLLUP',
        displayName: 'Sum',
        description: 'Summarizes data by adding up data point values received during each interval',
    },
    AVERAGE: {
        value: 'AVERAGE_ROLLUP',
        displayName: 'Average',
        description:
            'Summarizes data by calculating the mean of data point values received during each interval',
    },
    MAX: {
        value: 'MAX_ROLLUP',
        displayName: 'Max',
        description:
            'Summarizes data by taking the highest value from among the data points received during each interval',
    },
    DELTA: {
        value: 'DELTA_ROLLUP',
        displayName: 'Delta',
        description:
            'For cumulative counters, summarizes data by calculating the difference between data points at successive intervals. Equivalent to sum for incremental counters',
    },
    MIN: {
        value: 'MIN_ROLLUP',
        displayName: 'Min',
        description:
            'Summarizes data by taking the lowest value from among the data points received during each interval',
    },
    COUNT: {
        value: 'COUNT_ROLLUP',
        displayName: 'Count',
        description:
            'Summarizes data by counting the number of data points received during each interval',
    },
    LAG: {
        value: 'LAG_ROLLUP',
        displayName: 'Lag',
        description: 'The measured ingestion lag of the data received during each interval',
    },
    LATEST: {
        value: 'LATEST_ROLLUP',
        displayName: 'Latest',
        description:
            'Summarizes data by using the most recent value from among the data points received during each interval',
    },
});
