import { AngularInjector } from '../../AngularUtils';

import PRINCIPAL_TYPE from './AccessControlPrincipalType';
import CancelToken from '../../sfUtil/CancelToken';
import AccessControlPrincipalDataCollection from './AccessControlPrincipalDataCollection';

const SEARCH_USERS_LIMIT = 100;
const SEARCH_TEAMS_LIMIT = 100;

const CACHE_SIZE_LIMIT = 100;
const CACHE_EXPIRATION_TIME = 1000 * 180;

export default class AccessControlPrincipalService {
    constructor(organizationService, teamsApiService) {
        this.organizationService = organizationService;
        this.teamsApiService = teamsApiService;

        this.lastRequestToken = { [PRINCIPAL_TYPE.USER]: null, [PRINCIPAL_TYPE.TEAM]: null };
        this.cache = new Map();
    }

    searchPrincipals(phrase) {
        const teamsPromise = this.search(PRINCIPAL_TYPE.TEAM, phrase);
        const usersPromise = this.search(PRINCIPAL_TYPE.USER, phrase);

        return Promise.all([teamsPromise, usersPromise]).then(
            ([teams, users]) => new AccessControlPrincipalDataCollection(teams, users)
        );
    }

    search(principalType, phrase) {
        if (this.lastRequestToken[principalType] instanceof CancelToken) {
            this.lastRequestToken[principalType].cancel();
        }

        this.lastRequestToken[principalType] = new CancelToken();
        switch (principalType) {
            case PRINCIPAL_TYPE.TEAM:
                return this.searchTeams(phrase);
            case PRINCIPAL_TYPE.USER:
                return this.searchUsers(phrase);
        }
    }

    searchTeams(phrase) {
        return this.teamsApiService
            .getAll(
                { name: phrase, limit: SEARCH_TEAMS_LIMIT },
                this.lastRequestToken[PRINCIPAL_TYPE.TEAM]
            )
            .then((res) => res.results);
    }

    searchUsers(phrase) {
        return this.organizationService
            .members(phrase, 0, SEARCH_USERS_LIMIT, this.lastRequestToken[PRINCIPAL_TYPE.USER])
            .then((res) => res.results);
    }

    getPrincipals(aclList) {
        const teamsIds = aclList
            .filter((acl) => acl.principalType === PRINCIPAL_TYPE.TEAM)
            .map((acl) => acl.principalId);

        const usersIds = aclList
            .filter((acl) => acl.principalType === PRINCIPAL_TYPE.USER)
            .map((acl) => acl.principalId);

        const teamsPromise = this.useCache(teamsIds, this.teamsApiService.getTeams);
        const usersPromise = this.useCache(usersIds, this.organizationService.getMembersByIds);

        return Promise.all([teamsPromise, usersPromise]).then(
            ([teams, users]) => new AccessControlPrincipalDataCollection(teams, users)
        );
    }

    useCache(ids, getter) {
        const findById = (id, items) => items.find((item) => item.id === id);
        const now = Date.now();

        const needsToFetch = ids.filter((id) => {
            const cached = this.cache.get(id);
            return !(cached && cached.timestamp > now - CACHE_EXPIRATION_TIME);
        });

        if (needsToFetch.length) {
            const promise = getter(needsToFetch);
            needsToFetch.forEach((id) => {
                const singleItemPromise = promise.then(findById.bind(null, id));
                if (this.cache.size < CACHE_SIZE_LIMIT) {
                    this.cache.set(id, { timestamp: now, promise: singleItemPromise });
                }
            });
        }

        return Promise.all(ids.map((id) => this.cache.get(id)?.promise || Promise.resolve(null)));
    }
}

AccessControlPrincipalService.$inject = ['organizationService', 'teamsApiService'];
AccessControlPrincipalService.useInstance = () =>
    AngularInjector.useInjectedClass(AccessControlPrincipalService);
