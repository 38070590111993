angular.module('signalview.maps', ['signalview.utils']).service('signalViewMaps', [
    'asyncScriptLoader',
    '$window',
    '$document',
    '$log',
    '$q',
    function (asyncScriptLoader, $window, $document, $log, $q) {
        const MAPBOX_GL_URL = 'https://api.tiles.mapbox.com/mapbox-gl-js/v0.49.0/mapbox-gl.js';
        const MAPBOX_GL_CSS_URL = 'https://api.tiles.mapbox.com/mapbox-gl-js/v0.49.0/mapbox-gl.css';
        const GEOJSON_EXTENT_URL =
            'https://api.mapbox.com/mapbox.js/plugins/geojson-extent/v0.0.1/geojson-extent.js';

        const future = $q.defer();
        let fetching = false;

        function getMapBoxGLPromise() {
            return asyncScriptLoader
                .get(MAPBOX_GL_URL, function () {
                    return $window.mapboxgl;
                })
                .then(function (mbgl) {
                    // Add stylesheet
                    const link = $document[0].createElement('link');
                    link.setAttribute('rel', 'stylesheet');
                    link.setAttribute('type', 'text/css');
                    link.setAttribute('href', MAPBOX_GL_CSS_URL);
                    $document[0].head.appendChild(link);

                    // TODO: Set token
                    mbgl.accessToken = '';
                    $log.info('MapBoxGL loaded and configured.');
                    return mbgl;
                });
        }

        function getGeoJSONExtentPromise() {
            return asyncScriptLoader.get(GEOJSON_EXTENT_URL, function () {
                return $window.geojsonExtent;
            });
        }

        return {
            get: function () {
                if (!fetching) {
                    fetching = true;

                    $q.all({
                        mapboxgl: getMapBoxGLPromise(),
                        geoJSONExtent: getGeoJSONExtentPromise(),
                    }).then(function (libs) {
                        future.resolve(libs);
                    });
                }

                return future.promise;
            },
        };
    },
]);
