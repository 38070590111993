'use strict';

/* jshint camelcase: false */

angular.module('sfx.ui').directive('millisecondsAsSeconds', [
    function () {
        return {
            restrict: 'AE',
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                const secondsToMilliseconds = function (value) {
                    if (angular.isNumber(value)) {
                        return value * 1000;
                    }
                };

                const millisecondsToSeconds = function (value) {
                    if (angular.isNumber(value)) {
                        return value / 1000;
                    }
                };

                ngModel.$parsers.push(secondsToMilliseconds);
                ngModel.$formatters.push(millisecondsToSeconds);
            },
        };
    },
]);
