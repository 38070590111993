'use strict';
import templateUrl from './objectEditors.html';

/* jshint camelcase: false */
angular.module('sfx.ui').directive('objectEditors', [
    'userV2Service',
    function (userV2Service) {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                object: '=',
                snapshot: '=?',
            },
            templateUrl,
            link: function ($scope) {
                const snapshot = $scope.snapshot || {};
                const creator =
                    $scope.object.sf_creator || $scope.object.creator || snapshot.creator;
                const updatedBy =
                    $scope.object.sf_updatedBy ||
                    $scope.object.lastUpdatedBy ||
                    snapshot.lastUpdatedBy;
                $scope.createdOnMs =
                    $scope.object.sf_createdOnMs || $scope.object.created || snapshot.created;
                $scope.updatedOnMs =
                    $scope.object.sf_updatedOnMs ||
                    $scope.object.lastUpdated ||
                    snapshot.lastUpdated;

                if (creator) {
                    userV2Service.getOrgMemberForUserId(creator).then(function (orgMember) {
                        $scope.createdBy = userV2Service.getUserBadgeDisplayObj(orgMember);
                    });
                }
                if (updatedBy) {
                    userV2Service.getOrgMemberForUserId(updatedBy).then(function (orgMember) {
                        $scope.updatedBy = userV2Service.getUserBadgeDisplayObj(orgMember);
                    });
                }
            },
        };
    },
]);
