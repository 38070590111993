export const chartVisualizationService = [
    'featureEnabled',
    function (featureEnabled) {
        const CHART_TYPES = [
            {
                name: 'Line',
                icon: 'icon-viewchart',
                value: 'line',
            },
            {
                name: 'Area',
                icon: 'icon-viewfilledchart',
                value: 'area',
            },
            {
                name: 'Column',
                icon: 'icon-viewbarchart',
                value: 'column',
            },
            {
                name: 'Histogram',
                icon: 'icon-viewhistogram',
                value: 'heatmap',
            },
        ];

        const CHART_MODES = [
            {
                name: 'Graph',
                icon: 'icon-viewchart',
                value: 'graph',
            },
            {
                name: 'List',
                icon: 'icon-viewlist',
                value: 'list',
            },
            {
                name: 'Single value',
                icon: 'icon-viewsinglevalue',
                value: 'single',
            },
            {
                name: 'Heatmap',
                icon: 'icon-viewheat',
                value: 'heatmap',
            },
            {
                name: 'Event feed',
                icon: 'icon-viewevent',
                value: 'event',
            },
            {
                name: 'Text',
                icon: 'icon-viewnote',
                value: 'text',
            },
        ];

        const TABLE_CHART_MODE = {
            name: 'Table',
            icon: 'icon-tablechart',
            value: 'table',
        };

        const TEMPLATE_CHART_MODE = {
            name: 'Template',
            icon: 'icon-help',
            value: 'template',
        };

        return {
            getChartTypes,
            getChartModes,
        };

        function getChartTypes() {
            return angular.copy(CHART_TYPES);
        }

        function getChartModes() {
            const modes = angular.copy(CHART_MODES);

            modes.push(TABLE_CHART_MODE);

            if (featureEnabled('newChartDevelopmentMode')) {
                modes.push(TEMPLATE_CHART_MODE);
            }

            return modes;
        }
    },
];
