angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    function (chartTemplates, Chart, plotUtils) {
        function createChart(filters) {
            const chart = Chart.create().name('CPU %').yAxis(0, {
                min: 0,
                max: 120,
            });

            const plot1 = chart
                .plot()
                .metric('cpu.idle')
                .propertyFilter('plugin_instance', 'cpu-average')
                .propertyFilters(filters)
                .visible(false);

            chart
                .plot()
                .macro('100 - ' + plotUtils.getLetterFromUniqueKey(plot1.uniqueKey()))
                .visualization('area')
                .name('CPU %');

            return chart;
        }

        chartTemplates.register('collectdCPULoad', createChart);
    },
]);
