const REMOVED_DETECTORS_KEY = 'rm-detectors';

class RemovedDetectorsStore {
    public pushId(detectorId: string): void {
        const newRemovedIds = [...this.getIds(), detectorId];
        sessionStorage.setItem(REMOVED_DETECTORS_KEY, JSON.stringify(newRemovedIds));
    }

    public getIds(): Array<string> {
        return JSON.parse(sessionStorage.getItem(REMOVED_DETECTORS_KEY) || '[]');
    }
}

const removedDetectorsStore = new RemovedDetectorsStore();
export default removedDetectorsStore;
