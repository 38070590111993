angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    'blockService',
    function (chartTemplates, Chart, plotUtils, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Top CPU %')
                .disableThrottle(true)
                .forcedResolution(30000)
                .mode('list')
                .type('line')
                .maxDecimalPlaces(3)
                .updateInterval(5000)
                .sort('-value');

            const plot1 = chart
                .plot()
                .metric('cpu.idle')
                .propertyFilter('plugin_instance', 'cpu-average')
                .propertyFilter('plugin', 'aggregation')
                .propertyFilters(filters)
                .addManipulation(blockService.mean.asAggregation([{ value: 'host' }]))
                .addManipulation(blockService.mean.asTransformation(1, 'm'))
                .visible(false);

            chart
                .plot()
                .macro('100 - ' + plotUtils.getLetterFromUniqueKey(plot1.uniqueKey()))
                .name('')
                .addManipulation(blockService.topn.asAggregation([], { count: 5 }));

            return chart;
        }

        chartTemplates.register('collectdHostsTopCpuLoad', createChart);
    },
]);
