angular
    .module('signalview.timePicker', ['signalboost', 'signalview.urlOverrides', 'sfx.external'])

    // this is just a copy of bootstrap's timepicker, but with support for seconds.
    // I suspect they will add support for this, but until then...
    .constant('sftimepickerConfig', {
        hourStep: 1,
        minuteStep: 1,
        secondStep: 1,
        showMeridian: true,
        meridians: null,
        readonlyInput: false,
        mousewheel: true,
    })

    // TODO: find a more reasonable home for this; it is not intrinsically related
    // to this module.
    .directive('stopClickEventPropagation', function () {
        return {
            restrict: 'AE',
            link: function (scope, elem) {
                elem.bind('click', function (event) {
                    event.stopPropagation();
                });
            },
        };
    });
