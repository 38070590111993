import templateUrl from './teamsFilter.tpl.html';

export default {
    templateUrl,
    controller: [
        'urlOverridesService',
        'teamsApiService',
        function (urlOverridesService, teamsApiService) {
            const ctrl = this;

            ctrl.$onInit = $onInit;
            ctrl.onSelectTeam = onSelectTeam;

            function $onInit() {
                teamsApiService.getTeamSummary().then(({ results }) => {
                    ctrl.teams = results;
                    const filterByTeam = urlOverridesService.getFilterByTeam();
                    if (filterByTeam) {
                        onSelectTeam(ctrl.teams.find((team) => team.id === filterByTeam));
                    }
                });
            }

            function onSelectTeam(team) {
                ctrl.selectedTeam = team;
                urlOverridesService.setFilterByTeam(team ? team.id : null);
            }
        },
    ],
};
