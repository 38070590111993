import passwordResetTemplateUrl from './passwordreset.tpl.html';

export default {
    bindings: { accessToken: '<' },
    templateUrl: passwordResetTemplateUrl,
    controller: [
        '$scope',
        'signalboost',
        'security',
        'DOMAIN_ORGANIZATION_ID',
        'PASSWORD_VALIDATION_REGEX',
        'PASSWORD_ERROR_TEXT',
        function (
            $scope,
            signalboost,
            security,
            DOMAIN_ORGANIZATION_ID,
            PASSWORD_VALIDATION_REGEX,
            PASSWORD_ERROR_TEXT
        ) {
            const $ctrl = this;
            $ctrl.$onInit = initialize;

            $scope.passwordRequirementText = PASSWORD_ERROR_TEXT;
            $scope.passwordValidation = new RegExp(PASSWORD_VALIDATION_REGEX);

            $scope.state = '';
            $scope.user = {};

            function initialize() {
                return signalboost.user.validatePasswordResetToken($ctrl.accessToken || '').then(
                    function (session) {
                        if (session) {
                            $scope.user.email = session.email;
                            $scope.user.password = '';
                            $scope.description = 'Reset password for ' + $scope.user.email;
                        } else {
                            $scope.state = 'failed';
                            $scope.error = 'Cannot reset password';
                        }
                    },
                    function (err) {
                        $scope.state = 'failed';
                        $scope.error = err.data.message;
                    }
                );
            }

            function error(err) {
                $scope.error = err.data.message;
                $scope.state = '';
            }

            $scope.submit = function () {
                $scope.state = 'loading';
                $scope.error = '';

                return signalboost.user
                    .resetPassword($scope.user.email, $ctrl.accessToken || '', $scope.user.password)
                    .then(function (val) {
                        if (val) {
                            security
                                .login(
                                    $scope.user.email,
                                    $scope.user.password,
                                    DOMAIN_ORGANIZATION_ID,
                                    '/home'
                                )
                                .then(function () {}, $scope.onerror);
                        } else {
                            $scope.error = 'Failed updating password.';
                            $scope.state = '';
                        }
                    }, error);
            };
        },
    ],
};
