import templateUrl from './largeVolumeRow.tpl.html';

export const largeVolumeRow = {
    templateUrl,
    bindings: {
        credential: '<',
        dismiss: '<',
    },
    controller: [
        '$window',
        '$scope',
        'moment',
        'credentialV2Service',
        'credentialTypeToKey',
        'userV2Service',
        function (
            $window,
            $scope,
            moment,
            credentialV2Service,
            credentialTypeToKey,
            userV2Service
        ) {
            const $ctrl = this;
            const dateFormat = 'MM/DD/YYYY';
            $ctrl.$onInit = () => {
                $ctrl.state = '';
                $ctrl.createdDate = moment($ctrl.credential.created).format(dateFormat);
                userV2Service
                    .getOrgMemberForUserId($ctrl.credential.creator)
                    .then((orgMember) => {
                        $ctrl.creator = userV2Service.getUserBadgeDisplayObj(orgMember);
                    })
                    .finally(() => {
                        $scope.$applyAsync();
                    });
            };
            $ctrl.enable = enable;
            $ctrl.filter = filter;

            function enable() {
                const credential = angular.copy($ctrl.credential);
                $ctrl.state = 'loading';
                credential.enableCheckLargeVolume = false;
                credential.enabled = true;
                credentialV2Service.update(credential).then(
                    () => {
                        $ctrl.state = 'validated';
                    },
                    () => {
                        $ctrl.state = 'error';
                    }
                );
            }

            function filter() {
                const credentialName = credentialTypeToKey.mapCredTypeToName($ctrl.credential.type);
                $window.location.href = `#/integrations?selectedKeyValue=integrations:${credentialName}&updated=${$ctrl.credential.id}&tab=Setup`;
            }
        },
    ],
};
