import { AngularInjector } from '../../../../common/AngularUtils';

class AclDashboardService {
    constructor(dashboardUtil, dashboardV2Service, crossLinkService) {
        this.dashboardUtil = dashboardUtil;
        this.dashboardV2Service = dashboardV2Service;
        this.crossLinkService = crossLinkService;
    }

    create(dashboard, permissions, chartsList, dashboardGroupId) {
        return this.dashboardUtil
            .getDashboardData(dashboard, null, chartsList)
            .then((data) =>
                this.dashboardUtil.createDashboardFromData(data, dashboardGroupId, permissions)
            );
    }

    getCrossLinks(contextId) {
        return this.crossLinkService.searchByContext(contextId);
    }

    createMultipleCrossLinks(crossLinks) {
        return this.crossLinkService.bulkCreate(crossLinks);
    }

    save(dashboardModel) {
        return this.dashboardV2Service.update(dashboardModel);
    }

    get(id) {
        return this.dashboardV2Service.get(id);
    }

    getMirrorState(dashboardId) {
        return this.dashboardV2Service.getMirrorState(dashboardId);
    }
}

AclDashboardService.$inject = ['dashboardUtil', 'dashboardV2Service', 'crossLinkService'];
AclDashboardService.useInstance = () => AngularInjector.useInjectedClass(AclDashboardService);

export default AclDashboardService;
