import templateUrl from './detectorV2.tpl.html';
import renderThrottleMessageDetectorTemplateUrl from '../../charting/chartbuilder/renderThrottleMessageDetector.tpl.html';
import detectorV2SettingsPanelTemplateUrl from './detectorV2SettingsPanel.tpl.html';
import jobFetchCapsMessageTemplateUrl from '../../charting/chartbuilder/jobFetchCapsMessage.tpl.html';

angular.module('signalview.detectorV2').directive('v2DetectorEditor', [
    'CHART_DISPLAY_EVENTS',
    'DETECTOR_CHART_IDENTIFIERS',
    function (CHART_DISPLAY_EVENTS, DETECTOR_CHART_IDENTIFIERS) {
        return {
            restrict: 'E',
            templateUrl,
            controller: 'v2DetectorController',
            link: function ($scope) {
                $scope.detectorChartIdentifiers = DETECTOR_CHART_IDENTIFIERS;

                $scope.renderThrottleMessageDetectorTemplateUrl =
                    renderThrottleMessageDetectorTemplateUrl;
                $scope.detectorV2SettingsPanelTemplateUrl = detectorV2SettingsPanelTemplateUrl;
                $scope.jobFetchCapsMessageTemplateUrl = jobFetchCapsMessageTemplateUrl;
                $scope.$on(CHART_DISPLAY_EVENTS.RENDER_STATS, function (evt, pl) {
                    $scope.renderStats = pl;
                });
                $scope.$on(CHART_DISPLAY_EVENTS.JOB_FETCH_CAPS, function (evt, pl) {
                    $scope.jobFetchCaps = pl;
                });
                $scope.$watch('model.sf_jobMaxDelay', function (n) {
                    $scope.model.sf_uiModel.chartconfig.maxDelay = n;
                });
                $scope.$watch('model.sf_jobResolution', function () {
                    $scope.model.sf_uiModel.chartconfig.forcedResolution =
                        $scope.model.sf_jobResolution;
                });
                $scope.$watch('model.sf_timezone', function (n) {
                    $scope.model.sf_uiModel.chartconfig.timezone = n;
                });
                $scope.$watch('chartConfigOverride', function (n) {
                    $scope.chartConfigOverride = n;
                });
            },
        };
    },
]);
