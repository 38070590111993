import templateUrl from './organizationOverview.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../app/routing/ngRoute';

angular.module('signalview.organization').directive('organizationOverview', [
    function () {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                org: '=',
                editable: '<',
            },
            controller: [
                '$scope',
                '$location',
                '$q',
                '$log',
                'crossLinkDataService',
                'dashboardGroupService',
                'dashboardV2Util',
                'sidebarService',
                'subscriptionTypeUtil',
                'SUPPORT_EMAIL',
                'PRODUCT_NAME',
                'featureEnabled',
                'rbacUtilsService',
                function (
                    $scope,
                    $location,
                    $q,
                    $log,
                    crossLinkDataService,
                    dashboardGroupService,
                    dashboardV2Util,
                    sidebarService,
                    subscriptionTypeUtil,
                    SUPPORT_EMAIL,
                    PRODUCT_NAME,
                    featureEnabled,
                    rbacUtilsService
                ) {
                    $scope.sidebarService = sidebarService;
                    $scope.initialized = false;
                    $scope.SUPPORT_EMAIL = SUPPORT_EMAIL;
                    $scope.PRODUCT_NAME = PRODUCT_NAME;
                    $scope.supportSwitch = featureEnabled('supportSwitch');

                    function fetchOrgOverviewDashboards() {
                        const nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(
                            $scope.org.accountSubscriptionType
                        );
                        const pageName = nonLegacyOrg ? 'Organization Metrics' : 'Organization';
                        return dashboardGroupService
                            .searchByName(pageName)
                            .then((response) => {
                                const pages = response.results;
                                if (pages.length > 0) {
                                    return dashboardGroupService.getDashboards(pages[0].id);
                                } else {
                                    return $q.reject('no pages found');
                                }
                            })
                            .then((data) => {
                                return data.dashboards;
                            });
                    }

                    let orgDashboardsPromise;
                    function getOrgOverviewDashboards() {
                        if (!orgDashboardsPromise) {
                            orgDashboardsPromise = fetchOrgOverviewDashboards();
                        }
                        return orgDashboardsPromise;
                    }

                    function getDefaultDashboard(dashboards) {
                        const throttlingDashboard = _.find(dashboards, (dashboard) => {
                            return dashboard.name === 'Throttling';
                        });
                        const engagementDashboard = _.find(dashboards, (dashboard) => {
                            return dashboard.name === 'Engagement';
                        });
                        // Use Throttling dashboard as the Overview, if there is one; if not,
                        // use the Engagement dashboard; and if that doesn't exist, simply use
                        // the first dashboard in the list.
                        return throttlingDashboard || engagementDashboard || dashboards[0];
                    }

                    function getSelectedDashboard(dashboards, dashboardId) {
                        const dashboard = _.find(dashboards, (dashboard) => {
                            return dashboard.id === dashboardId;
                        });
                        return dashboard || dashboards[0];
                    }

                    function getData() {
                        return getOrgOverviewDashboards()
                            .then((dashboards) => {
                                if (dashboards.length > 0) {
                                    let dashboard;
                                    if ($location.search().dashboard) {
                                        dashboard = getSelectedDashboard(
                                            dashboards,
                                            $location.search().dashboard
                                        );
                                    } else {
                                        dashboard = getDefaultDashboard(dashboards);
                                    }
                                    $scope.dashboardId = dashboard.id || dashboard.sf_id;
                                    return dashboardV2Util.getAndProcessHierarchy(
                                        $scope.dashboardId
                                    );
                                } else {
                                    $log.error('No dashboards found');
                                    return $q.reject('no dashboards found');
                                }
                            })
                            .finally(() => {
                                $scope.initialized = true;
                            });
                    }

                    initializeView();

                    function initializeView() {
                        $scope.initialized = false;
                        checkCapability();
                        $scope.data = getData();
                        $scope.data.then(({ page }) => {
                            if (page) {
                                crossLinkDataService.setCurrentContext(page.id || page.sf_id);
                            }
                        });
                    }

                    function checkCapability() {
                        if (featureEnabled('newRbacExperience')) {
                            rbacUtilsService.checkCapabilityAndRedirect(
                                Capability.READ_ORGANIZATION_OVERVIEW
                            );
                        } else if (!$scope.editable) {
                            ngRoute.history?.replace('/NotFound');
                        }
                    }

                    $scope.$on('React:$routeUpdate', function () {
                        if (
                            $location.search().dashboard &&
                            $location.search().dashboard !== $scope.dashboardId
                        ) {
                            initializeView();
                        }
                    });

                    $scope.$on('requestInitializeTimePicker', function (ev, obj) {
                        $scope.$broadcast('initializeTimePicker', obj);
                    });

                    $scope.$on('chartTimeRangeSelected', function (ev, start, end) {
                        $scope.$broadcast('setNewGlobalTimeRange', start, end);
                    });
                },
            ],
        };
    },
]);
