angular
    .module('signalboost')

    .service('AutoSuggestRestService', [
        'signalboost',
        'SignalboostBaseService',
        'currentUser',
        '$log',
        function (signalboost, SignalboostBaseService, currentUser, $log) {
            function logTypeCoercion(suggestType, types) {
                $log.warn(
                    'Suggest ' +
                        suggestType +
                        ' was called with no type or multiple types:' +
                        types +
                        '.  Type was forcibly changed to "Chart" to force UI index lookup.'
                );
            }

            return class AutoSuggestRestService extends SignalboostBaseService {
                constructor() {
                    super('as', false);
                }

                getPropertySuggestions(params) {
                    if (!params.types || params.types.length !== 1) {
                        logTypeCoercion('property', params.types);
                        params.types = ['Chart'];
                    }
                    if (!params.organizationId) {
                        return currentUser.orgId().then(function (orgId) {
                            params.organizationId = orgId;
                            return signalboost.autosuggest
                                .all()
                                .all('suggest')
                                .all('property')
                                .getList(params);
                        });
                    }
                    return signalboost.autosuggest
                        .all()
                        .all('suggest')
                        .all('property')
                        .getList(params);
                }

                getValueSuggestions(params) {
                    if (!params.types || params.types.length > 1) {
                        logTypeCoercion('value', params.types);
                        params.types = ['Chart'];
                    }
                    if (!params.organizationId) {
                        return currentUser.orgId().then(function (orgId) {
                            params.organizationId = orgId;
                            return signalboost.autosuggest
                                .all()
                                .all('suggest')
                                .all('value')
                                .getList(params);
                        });
                    }
                    return signalboost.autosuggest
                        .all()
                        .all('suggest')
                        .all('value')
                        .getList(params);
                }

                getTagSuggestions(params) {
                    if (!params.types || params.types.length > 1) {
                        logTypeCoercion('tag', params.types);
                        params.types = ['Chart'];
                    }
                    if (!params.organizationId) {
                        return currentUser.orgId().then(function (orgId) {
                            params.organizationId = orgId;
                            return signalboost.autosuggest
                                .all()
                                .all('suggest')
                                .all('tag')
                                .getList(params);
                        });
                    }
                    return signalboost.autosuggest.all().all('suggest').all('tag').getList(params);
                }
            };
        },
    ]);
