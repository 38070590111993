// KMB units (1000 divisors)
const kmb = [
    { mod: Math.pow(10, 0), descriptor: '' },
    { mod: Math.pow(10, 3), descriptor: 'k' },
    { mod: Math.pow(10, 6), descriptor: 'M' },
    { mod: Math.pow(10, 9), descriptor: 'B' },
    { mod: Math.pow(10, 12), descriptor: 'T' },
    { mod: Math.pow(10, 15), descriptor: 'Qa' },
    { mod: Math.pow(10, 18), descriptor: 'Qi' },
];

// KMG2 units (1024 divisors)
const kmg2 = [
    { mod: Math.pow(2, 0), descriptor: '' },
    { mod: Math.pow(2, 10), descriptor: 'k' },
    { mod: Math.pow(2, 20), descriptor: 'M' },
    { mod: Math.pow(2, 30), descriptor: 'G' },
    { mod: Math.pow(2, 40), descriptor: 'T' },
    { mod: Math.pow(2, 50), descriptor: 'P' },
    { mod: Math.pow(2, 60), descriptor: 'E' },
];

export default {
    kmb,
    kmg2,
};
