import nameDescriptionTemplateUrl from '../../editors/namedescription.tpl.html';
import dashboardTabTooltipTemplateUrl from '../../dashboardGroup/dashboardTabTooltip.tpl.html';
import readOnlyTooltipTemplateUrl from '../../../common/tooltips/readOnlyTooltip.html';
import AclDashboardService from '../aclDashboard/AclDashboardService';
import AccessControlObjectType from '../../../../common/ui/accessControl/AccessControlObjectType';

export default [
    '$scope',
    '$log',
    '$timeout',
    'actions',
    'currentDashboard',
    'displayMessage',
    'title',
    'userAnalytics',
    'currentUser',
    'dashboardV2Service',
    'dashboardGroupService',
    'sfxModal',
    'featureEnabled',
    'includeGenerated',
    'includeReadOnly',
    'dashboardMirrorService',
    'MEMBERSHIP_DELAY',
    'promiseGenerationManager',
    'dashboardUtil',
    function (
        $scope,
        $log,
        $timeout,
        actions,
        currentDashboard,
        displayMessage,
        title,
        userAnalytics,
        currentUser,
        dashboardV2Service,
        dashboardGroupService,
        sfxModal,
        featureEnabled,
        includeGenerated,
        includeReadOnly,
        dashboardMirrorService,
        MEMBERSHIP_DELAY,
        promiseGenerationManager,
        dashboardUtil
    ) {
        $scope.dashboardTabTooltipTemplateUrl = dashboardTabTooltipTemplateUrl;
        $scope.readOnlyTooltipTemplateUrl = readOnlyTooltipTemplateUrl;

        // this is built to work on modals only.
        $scope.actions = actions || ['ok'];
        $scope.requestDelay = 1000;
        $scope.firstResultSetDisplayed = false;
        $scope.title = title || 'Clone Chart';
        $scope.message = displayMessage;
        $scope.pageSize = 10;
        $scope.query = {
            dashboardQuery: '',
            currentPage: 1,
            sortTopic: 'name',
            ascending: true,
        };
        $scope.readOnlyEnabled = featureEnabled('readOnly');

        const REQUEST_DELAY = 200;
        const debouncedSearchDashboards = promiseGenerationManager(searchDashboards, REQUEST_DELAY);

        $scope.createDashboard = function () {
            const createPage = featureEnabled('accessControl') ? _createAclPage() : _createPage();

            createPage
                .then(function (page) {
                    userAnalytics.event('dashboard', 'create');

                    $scope.requestInFlight = true;

                    return $timeout(function () {
                        dashboardGroupService.getDashboards(page.sf_id).then(function (data) {
                            const dashboards = data.dashboards;

                            if (dashboards.length) {
                                $scope.setSelectedDashboard(dashboards[0]);
                                $scope.firstResultSetDisplayed = false;
                            }
                            return $scope.updateResults();
                        });
                    }, MEMBERSHIP_DELAY);
                })
                .catch(function (error) {
                    $log.error(error);
                });
        };

        function _createPage() {
            return sfxModal.open({
                templateUrl: nameDescriptionTemplateUrl,
                controller: 'NameDescriptionController',
                resolve: {
                    params: function () {
                        return {
                            obj: { sf_type: 'Page' },
                            label: 'Dashboard',
                        };
                    },
                    defaultWriters: currentUser.defaultWritePermissions(),
                },
                windowClass: 'name-description-modal',
            }).result;
        }

        function _createAclPage() {
            return sfxModal
                .open({
                    // declare-used-dependency-to-linter::createAclDashboardGroupModal
                    template: `<create-acl-dashboard-group-modal custom-label="${AccessControlObjectType.DASHBOARD.label}"></create-acl-dashboard-group-modal>`,
                })
                .result.then(({ dashboardGroup, permissions }) => {
                    const dashboardId = dashboardGroup.sf_dashboards[0];
                    const dashboardService = new AclDashboardService(
                        dashboardUtil,
                        dashboardV2Service
                    );

                    // first we need to update dashboard permissions (by default it's inherit from Dashboard Group)
                    return dashboardService
                        .get(dashboardId)
                        .then((dashboard) =>
                            dashboardService.save({
                                ...dashboard,
                                permissions,
                            })
                        )
                        .then(() => dashboardGroup);
                });
        }

        function onDashboardChosen(dashboard, action) {
            $scope.$close({ dashboard, action });
        }

        $scope.onDashboardSelect = function (event, dashboard) {
            if (event && event.keyCode === 13) {
                $scope.setSelectedDashboard(dashboard);
                angular.element('.ok-button')[0].focus();
            }
        };

        $scope.setSelectedDashboard = function (dashboard) {
            if (dashboard && !($scope.readOnlyEnabled && dashboard.locked)) {
                return dashboardMirrorService
                    .getDashboardAppearanceCount(dashboard.id)
                    .then(function (result) {
                        $scope.numMirrors = result.numMirrors;
                        $scope.numGroups = result.numGroups;
                        $scope.selectedDashboard = dashboard;
                        currentDashboard = dashboard;
                    });
            }
        };

        $scope.setSelectedDashboard(currentDashboard);

        $scope.adjustPageCount = function (diff) {
            const destPage = $scope.query.currentPage + diff;
            if (destPage > 0 && destPage <= $scope.getPageCount()) {
                $scope.query.currentPage = destPage;
            }
        };

        $scope.getFormattedTime = function (ms) {
            const dateObj = new Date(ms);
            return dateObj.toDateString() + ' ' + dateObj.toLocaleTimeString();
        };

        $scope.confirmDashboardSelection = function (action) {
            if ($scope.selectedDashboard) {
                onDashboardChosen($scope.selectedDashboard, action);
            }
        };

        $scope.$watch('query.dashboardQuery', function () {
            $scope.query.currentPage = 1;
            $scope.updateResults();
        });

        $scope.$watch('query.currentPage', function (newVal) {
            if (newVal < 1 || newVal > $scope.getPageCount()) {
                return;
            }
            $scope.updateResults();
        });

        $scope.setTopic = function (topic) {
            if (topic === 'groupName') {
                if ($scope.sortAfterSearch) {
                    $scope.query.ascending = !$scope.query.ascending;
                }
                $scope.sortAfterSearch = true;
            } else if ($scope.query.sortTopic === topic) {
                $scope.query.ascending = !$scope.query.ascending;
            } else {
                $scope.query.ascending = true;
                $scope.sortAfterSearch = false;
                $scope.query.sortTopic = topic;
            }
            $scope.updateResults();
        };

        function sortByProperty(property) {
            let sortOrder = 1;
            if (property[0] === '-') {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
                return result * sortOrder;
            };
        }

        $scope.updateResults = function () {
            $scope.requestInFlight = true;

            return debouncedSearchDashboards()
                .then(handleDashboardSearch)
                .catch(() => ($scope.requestInFlight = false));
        };

        function searchDashboards() {
            const query = $scope.query;
            return dashboardV2Service.search({
                name: query.dashboardQuery,
                offset: (query.currentPage - 1) * $scope.pageSize,
                limit: $scope.pageSize,
                orderBy: (query.ascending ? '' : '-') + query.sortTopic,
                excludeGenerated: !includeGenerated,
                excludeReadOnly: !includeReadOnly,
                requireGroupId: true, // relies on sf_page propagation, and does not guarantee a dashboard has a dashboardGroup
            });
        }

        function handleDashboardSearch(data) {
            // TODO(trevor): Remove/refactor the below when groupIds are deprecated

            // NB: can result in pages with <10 results, but since dashboards without
            // dashboard groups cannot be updated, is nonetheless necessary.  will
            // replace on backend after migration of dashboards to store dashboardGroup
            // as set property (required for Views).
            const results = data.results.filter((dashboard) => !!dashboard.groupId);

            // now that V2 dashboards no longer return data about dashboard groups,
            // we need to batch fetch dashboard groups by ID so we can include
            // relevant data (chiefly group name)
            const dashboardGroupIds = results.map((dash) => dash.groupId);

            const dashboardGroupData = dashboardGroupService.getDashboardGroups(dashboardGroupIds);

            dashboardGroupData.then((dashboardGroups) => {
                results.forEach(function (dashboard) {
                    const dashboardGroup = dashboardGroups[dashboard.groupId] || {};
                    dashboard.groupName = dashboardGroup.name || '';
                });
            });

            // since groupName is not part of dashboardV2 endpoint, to sort by groupName we must sort clientside.
            if ($scope.sortAfterSearch) {
                const sortOrder = $scope.query.ascending ? 'groupName' : '-groupName';

                results.sort(sortByProperty(sortOrder));
            }

            $scope.dashboardResultList = data;

            // if this is the first time we're showing results, forcibly add the recent dashboard if its not in the list
            if (!$scope.firstResultSetDisplayed) {
                let foundCurrent = false;
                $scope.dashboardResultList.results.forEach(function (dashboard) {
                    if (currentDashboard && dashboard.id === currentDashboard.id) {
                        foundCurrent = true;
                    }
                });
                if (currentDashboard && !foundCurrent) {
                    $scope.dashboardResultList.results = [currentDashboard].concat(
                        $scope.dashboardResultList.results
                    );
                }
                $scope.firstResultSetDisplayed = true;
            }
            $scope.requestInFlight = false;
        }

        $scope.getPageCount = function () {
            // pagecount is always at least 1, even with zero results
            return $scope.dashboardResultList
                ? Math.max(1, Math.ceil($scope.dashboardResultList.count / $scope.pageSize))
                : 1;
        };
    },
];
