angular
    .module('signalboost')

    .service('MetricTimeSeriesRestService', [
        'signalboostUtil',
        'SignalboostBaseService',
        function (signalboostUtil, SignalboostBaseService) {
            return class MetricTimeSeriesService extends SignalboostBaseService {
                constructor() {
                    super('metrictimeseries', false, '', signalboostUtil.metricTSQuerySanitizer);
                }

                addQueryTypeFields(query) {
                    return 'sf_metric:' + query + ' OR ' + ' sf_source:' + query;
                }

                getQuerySortOrder() {
                    return 'sf_metric';
                }
            };
        },
    ]);
