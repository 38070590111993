import { sanitizeTermUsingSpaces } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';

angular
    .module('signalview.businessObjectDefinitions', [])
    .service('businessObjects', [
        'pageDisplayTitle',
        function (pageDisplayTitle) {
            return [
                {
                    displayName: 'Metric',
                    propertyKey: 'sf_metric',
                    filterKey: 'sf_metric',
                    typeValue: 'Metric',
                },
                {
                    displayName: 'Event',
                    propertyKey: function (doc) {
                        return doc.sf_key
                            .map(function (key) {
                                return doc[key];
                            })
                            .join('.');
                    },
                    filterKey: function (doc) {
                        return doc.sf_key;
                    },
                    typeValue: 'EventTimeSeries',
                },
                {
                    displayName: 'Detector',
                    propertyKey: 'sf_detector',
                    filterKey: 'sf_detector',
                    typeValue: 'Detector',
                    getLink: function (doc) {
                        return '#/detector/' + doc.sf_id + '/edit';
                    },
                    redirect: true,
                },
                {
                    displayName: 'Tag',
                    propertyKey: 'sf_tag',
                    filterKey: 'sf_tags',
                    typeValue: 'Tag',
                },
                {
                    displayName: 'Navigator',
                    propertyKey: 'displayName',
                    filterKey: 'displayName',
                    typeValue: 'Navigator',
                    getLink: function (doc) {
                        let navigatorLink = '#/infra/entity/';
                        // for builtin navigators, use the navigatorCode. For custom navigators, we link to the navigator id
                        if (
                            (!doc.ownerScope || doc.ownerScope !== 'organization') &&
                            doc.navigatorCode
                        ) {
                            navigatorLink += doc.navigatorCode;
                        } else {
                            navigatorLink += doc.id;
                        }
                        if (doc.defaultGroupBy) {
                            navigatorLink += '?groupBy=' + encodeURIComponent(doc.defaultGroupBy);
                        }
                        return navigatorLink;
                    },
                    redirect: true,
                },
                {
                    displayName: 'Team',
                    propertyKey: 'sf_team',
                    filterKey: 'sf_team',
                    typeValue: 'Team',
                    getLink: function (doc) {
                        return '#/team/' + doc.sf_id;
                    },
                },
                {
                    displayName: 'Time Series',
                    propertyKey: function (doc) {
                        return doc.sf_key
                            .map(function (key) {
                                return doc[key];
                            })
                            .join('.');
                    },
                    filterKey: function (doc) {
                        return doc.sf_key;
                    },
                    typeValue: 'MetricTimeSeries',
                },
                {
                    displayName: 'Chart',
                    propertyKey: 'sf_chart',
                    filterKey: 'sf_chart',
                    typeValue: 'Chart',
                    getLink: function (doc) {
                        return '#/chart/' + doc.sf_id;
                    },
                    redirect: true,
                },
                {
                    displayName: 'Dashboard',
                    propertyKey: 'sf_dashboard',
                    filterKey: 'sf_dashboard',
                    typeValue: 'Dashboard',
                    secondaryValue: pageDisplayTitle,
                    getLink: function (doc) {
                        return '#/dashboard/' + (doc.sf_id || doc.id);
                    },
                    redirect: true,
                },
                {
                    displayName: 'Dashboard Group',
                    propertyKey: pageDisplayTitle,
                    filterKey: 'sf_page',
                    typeValue: 'Page',
                    getLink: function (doc) {
                        return '#/page/' + doc.sf_id;
                    },
                },
                {
                    displayName: 'Dimension',
                    propertyKey: function (doc) {
                        return doc.sf_key
                            .map(function (key) {
                                return doc[key];
                            })
                            .join('.');
                    },
                    filterKey: function (doc) {
                        return doc.sf_key;
                    },
                    typeValue: 'Dimension',
                },
                {
                    displayName: 'Dimension?',
                    propertyKey: function (doc) {
                        return doc.value;
                    },
                    filterKey: function (doc) {
                        return [doc.key];
                    },
                    typeValue: 'Property',
                },
                {
                    displayName: 'OrgUser',
                    propertyKey: function (doc) {
                        return doc.sf_user || doc.fullName || doc.sf_email || doc.email;
                    },
                    filterKey: 'sf_user',
                    typeValue: 'OrgUser',
                    getLink: function (doc) {
                        return '#/profile/' + (doc.sf_id || doc.id);
                    },
                    redirect: true,
                    keepSidebar: true,
                },
            ];
        },
    ])
    .service('filterOnPrimaryKey', [
        'businessObjects',
        '$log',
        function (businessObjects, $log) {
            const keysToProperties = {};
            businessObjects.forEach(function (obj) {
                keysToProperties[obj.typeValue] = obj;
            });

            return function (obj) {
                const type = obj.sf_type || 'Property';
                const bObj = keysToProperties[type];
                if (bObj) {
                    if (typeof bObj.filterKey === 'string') {
                        const val = obj[bObj.propertyKey];
                        return [
                            {
                                iconClass: 'icon-properties',
                                type: 'name',
                                query: bObj.filterKey + ':' + sanitizeTermUsingSpaces(val),
                                property: bObj.filterKey,
                                propertyValue: val,
                                value: val,
                                NOT: false,
                            },
                        ];
                    } else {
                        //assume fn that will return array of keys based on doc
                        return bObj.filterKey(obj).map(function (key) {
                            const val = obj[key] || obj.value; // fall back for properties
                            return {
                                iconClass: 'icon-properties',
                                type: 'name',
                                query: key + ':' + sanitizeTermUsingSpaces(val),
                                property: key,
                                propertyValue: val,
                                value: val,
                                NOT: false,
                            };
                        });
                    }
                } else {
                    $log.error('Failed to find an appropriate way to filter an object.');
                    return null;
                }
            };
        },
    ])
    .service('getLinkForDocument', [
        'businessObjects',
        function (businessObjects) {
            const keysToProperties = {};
            businessObjects.forEach(function (obj) {
                keysToProperties[obj.typeValue] = obj;
            });
            return function (doc) {
                if (doc.externalLink) {
                    return doc.externalLink;
                } else if (
                    (doc.sf_id || doc.id) &&
                    doc.sf_type &&
                    keysToProperties[doc.sf_type] &&
                    keysToProperties[doc.sf_type].getLink
                ) {
                    return keysToProperties[doc.sf_type].getLink(doc);
                }
                return null;
            };
        },
    ])
    .service('displayName', [
        'businessObjects',
        function (businessObjects) {
            const keysToProperties = {};
            businessObjects.forEach(function (obj) {
                keysToProperties[obj.typeValue] = obj;
            });

            return {
                getName: function (obj) {
                    const bObj = keysToProperties[obj.sf_type];
                    if (bObj) {
                        if (typeof bObj.propertyKey === 'string') {
                            return obj[bObj.propertyKey];
                        } else {
                            //assume lookup fn
                            return bObj.propertyKey(obj);
                        }
                    } else {
                        return 'Unknown';
                    }
                },
                getSecondaryValue: function (obj) {
                    const bObj = keysToProperties[obj.sf_type];
                    if (bObj && bObj.secondaryValue) {
                        if (typeof bObj.secondaryValue === 'string') {
                            return obj[bObj.secondaryValue];
                        } else {
                            //assume lookup fn
                            return bObj.secondaryValue(obj);
                        }
                    } else {
                        return '';
                    }
                },
                redirect: function (obj) {
                    const bObj = keysToProperties[obj.sf_type];
                    return bObj && bObj.redirect;
                },
                keepSidebar: function (obj) {
                    const bObj = keysToProperties[obj.sf_type];
                    return bObj && bObj.keepSidebar;
                },
            };
        },
    ]);
