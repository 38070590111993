import teamPage from './teamPage.js';
import teamPageDescription from './teamPageDescription.js';
import teamAlerts from './teamAlerts.js';
import teamDashboards from './teamDashboards.js';
import teamService from './teamService.js';
import teamsFilter from './teamsFilter.js';
import { teamLinkingService } from './teamLinkingService.js';
import { teamPageHeader } from './teamPageHeader.js';

angular
    .module('signalview.teams', ['sfx.data'])
    .component('teamDashboards', teamDashboards)
    .component('teamAlerts', teamAlerts)
    .component('teamPageDescription', teamPageDescription)
    .component('teamsFilter', teamsFilter)
    .component('teamPageHeader', teamPageHeader)
    .component('teamPage', teamPage)
    .service('teamLinkingService', teamLinkingService)
    .service('teamService', teamService);
