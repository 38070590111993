import styled from 'styled-components';
import React, { FC } from 'react';

const StyledWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
`;

const StyledMessage = styled.div`
    margin-bottom: 20px;
`;

const EXPLANATION_MESSAGE = "Classic SignalFx UI doesn't support this type of detector";

const UnsupportedDetector: FC = () => {
    return (
        <StyledWrapper>
            <StyledContent>
                <StyledMessage>{EXPLANATION_MESSAGE}</StyledMessage>
            </StyledContent>
        </StyledWrapper>
    );
};

export default UnsupportedDetector;
