import {
    ArchivedMetricsWarningBar,
    EntityType,
} from '@splunk/olly-imm/build/archived-metrics/ArchivedMetricsWarningBar';
import { react2angular } from 'react2angular';
import React, { FC } from 'react';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../theme/ThemeProvider';
import { MPMRule } from '@splunk/olly-imm/build/archived-metrics/ArchivedMetrics';

type ArchivedMetricsWarningBarProps = {
    entityType: EntityType;
    show?: boolean;
    mpmRules?: MPMRule[];
};

export const ArchivedMetricsWarning: FC<ArchivedMetricsWarningBarProps> = ({
    entityType,
    show,
    mpmRules,
}) => {
    const themeKey = useLegacyThemeServiceKey();

    return (
        <ThemeProvider colorScheme={themeKey}>
            <ArchivedMetricsWarningBar
                entityType={entityType}
                show={show}
                mpmRules={mpmRules}
            ></ArchivedMetricsWarningBar>
        </ThemeProvider>
    );
};

export const archivedMetricsWarningBar = react2angular(ArchivedMetricsWarning, [
    'entityType',
    'show',
    'mpmRules',
]);
