export default [
    '$timeout',
    '$location',
    '$window',
    function ($timeout, $location, $window) {
        return {
            tryToScrollToItem,
            navigateToItem,
            getLinkToSelf,
        };

        function tryToScrollToItem(index, itemClass, containerClass) {
            $timeout(function () {
                try {
                    const item = angular.element(`.${itemClass}-${index}`);
                    const container = angular.element(`.${containerClass}`);
                    if (item.position().top < 0) {
                        container.animate(
                            {
                                scrollTop: container.scrollTop() + item.position().top,
                            },
                            200
                        );
                    } else if (item.position().top + item.height() > container.height()) {
                        container.animate(
                            {
                                scrollTop:
                                    container.scrollTop() +
                                    item.position().top +
                                    item.height() -
                                    container.height(),
                            },
                            200
                        );
                    }
                } catch (e) {
                    console.log(e);
                }
            }, 100);
        }

        function navigateToItem(item) {
            let href = item.href;

            if (href) {
                href = getLinkToSelf(href);
                if (href.indexOf('http') === 0) {
                    $window.location.href = href;
                    return;
                }
                if (href.indexOf('#') === 0) {
                    href = href.substring(1);
                }
                $location.url(href);
            }
            if (item.action) {
                item.action();
            }
        }

        function getLinkToSelf(link) {
            return $location.$$absUrl.includes(link) ? $location.$$absUrl : link;
        }
    },
];
