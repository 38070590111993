'use strict';

/**
  Sends click events to GA. use track-click="<action name>" to apply this
  to anything which can take click events. The event category will always be "click".

  use track-click-value="<event value>" to send events with event value
  use track-click-label="<event label>" to send events with event label
*/
angular.module('signalview.userAnalytics').directive('trackClick', [
    'userAnalytics',
    function (userAnalytics) {
        return {
            restrict: 'A',
            scope: {
                trackClick: '@',
            },
            link: function ($scope, element, attrs) {
                element.on('click', function () {
                    // Don't send empty events
                    if (!$scope.trackClick) {
                        return;
                    }
                    userAnalytics.event(
                        'click',
                        $scope.trackClick,
                        attrs.trackClickValue,
                        attrs.trackClickLabel
                    );
                });
            },
        };
    },
]);
