import signinOrganizationTemplateUrl from './signinorganization.tpl.html';

export default {
    templateUrl: signinOrganizationTemplateUrl,
    controller: [
        'SUBDOMAIN_ROOT',
        '$scope',
        '$http',
        'API_URL',
        '$window',
        'ssoRedirect',
        function (SUBDOMAIN_ROOT, $scope, $http, API_URL, $window, ssoRedirect) {
            $scope.SUBDOMAIN_ROOT = SUBDOMAIN_ROOT;

            $scope.state = '';
            $scope.domain = '';

            $scope.submit = function () {
                $scope.state = 'loading';
                let url = ssoRedirect.getAndClear() || '';
                // Redirect to '/home' when the cookie - SF_SSO_REDIRECT
                // is set to redirect to a different org id i.e. /home/:orgId
                const urlParams = url.split('/');
                if (urlParams.length === 3 && urlParams[1] === 'home') {
                    url = '/home';
                }

                $http.post(API_URL + '/v2/organization/_/hasDomain', $scope.domain).then(
                    (response) => {
                        $window.location.href = response.data + '/#' + url;
                    },
                    () => {
                        $scope.state = 'error';
                    }
                );
            };
        },
    ],
};
