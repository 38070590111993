import templateUrl from './teamPageHeader.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';

export const teamPageHeader = {
    templateUrl,
    bindings: {
        team: '<?',
        teams: '<',
        userTeams: '<',
    },
    controller: [
        '$element',
        '$scope',
        '$timeout',
        '$window',
        function ($element, $scope, $timeout, $window) {
            const ctrl = this;
            const tabContainer = angular.element($element.find('.team-tab-header .flex-auto')[0]);
            const $myTeamsLabel = $element.find('.dashboard-tabs-measure .label-tab');

            ctrl.$onChanges = $onChanges;
            ctrl.$onDestroy = $onDestroy;
            ctrl.$onInit = $onInit;
            ctrl.$postLink = $postLink;
            ctrl.goToTeamDashboard = goToTeamDashboard;

            function $onInit() {
                ctrl.isViewingOwnTeam =
                    ctrl.team && ctrl.userTeams.some((team) => team.id === ctrl.team.id);

                angular.element($window).bind(
                    'resize',
                    _.debounce(() => {
                        updateVisibility();
                        $scope.$digest();
                    }, 100)
                );
            }

            function $onChanges(changeObj) {
                if (changeObj.userTeams) {
                    measureTabs().then(updateVisibility);
                }
            }

            function $onDestroy() {
                angular.element($window).unbind('resize');
            }

            function goToTeamDashboard(teamId) {
                ngRoute.history.replace({
                    pathname: `/team/${teamId}`,
                });
            }

            function $postLink() {
                measureTabs().then(updateVisibility);
            }

            /* non-scoped functions */
            function updateVisibility() {
                const widthLimit = tabContainer.innerWidth() - $myTeamsLabel.outerWidth(true);
                let allocatedWidth = 0;

                ctrl.hasHiddenTeams = false;
                ctrl.visibilityByTeam = {};

                if (ctrl.isViewingOwnTeam) {
                    allocatedWidth += ctrl.measurementByTeamId[ctrl.team.id];
                }

                angular.forEach(ctrl.measurementByTeamId, (width, id) => {
                    if (ctrl.team && ctrl.team.id === id) {
                        ctrl.visibilityByTeam[id] = true;
                        return;
                    }

                    const sufficientFreeSpace = allocatedWidth + width < widthLimit;
                    ctrl.visibilityByTeam[id] = sufficientFreeSpace;

                    if (sufficientFreeSpace) {
                        allocatedWidth += width;
                    } else {
                        ctrl.hasHiddenTeams = true;
                    }
                });
            }

            function measureTabs() {
                return $timeout(() => {
                    // if changing a team's name becomes possible on the landing page
                    // we will no longer be able to reuse measurements.
                    ctrl.measurementByTeamId = ctrl.measurementByTeamId || {};

                    $element
                        .find('.dashboard-tabs-measure .sf-tab-header.team-measurement-tab')
                        .each((idx, element) => {
                            const teamId = ctrl.userTeams[idx].id;
                            if (ctrl.measurementByTeamId[teamId]) {
                                return;
                            }
                            ctrl.measurementByTeamId[teamId] = angular
                                .element(element)
                                .outerWidth(true);
                        });
                });
            }
        },
    ],
};
