import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BEMHelper from 'react-bem-helper';
import moment from 'moment';

const className = new BEMHelper('default-token-expiration-configuration');

const VALID_DURATION_PATTERN = /^[0-9]+$/;
const DEFAULT_NUMBER = 30;

const currentDate = moment();
const futureDate = moment().add(18, 'years');

const daysIn18Years = futureDate.diff(currentDate, 'days');

function isValidDuration(duration) {
    const matches = VALID_DURATION_PATTERN.test(duration);

    if (matches) {
        const value = parseInt(duration, 10);

        if (value === 0) {
            return { isValid: false, message: 'Enter a value greater than 0.' };
        }

        return {
            isValid: value <= daysIn18Years,
            message:
                value > daysIn18Years
                    ? "Enter a day that's within the next 18 years."
                    : 'Valid input.',
        };
    }

    return { isValid: false, message: 'Invalid input.' };
}

export default function DefaultTokenExpirationConfiguration({
    label,
    onChange,
    value,
    additionalInfo,
}) {
    const [number, setNumber] = useState(DEFAULT_NUMBER);

    useEffect(() => {
        const parsedValue = parseInt(value, 10);
        if (value && number !== parsedValue && isValidDuration(value).isValid) {
            setNumber(value);
        }
    }, [number, value]);

    const onChangeNumber = (e) => {
        const newValue = e.target.value;
        onChange(newValue, isValidDuration(newValue).isValid);
        setNumber(newValue);
    };

    const { isValid, message } = isValidDuration(value);

    return (
        <div {...className()}>
            <label>
                <span {...className('input-label')}>{label}</span>
                <input
                    {...className('duration-number', isValid ? null : 'invalid')}
                    type="text"
                    name={label}
                    value={number}
                    onChange={onChangeNumber}
                />
                days
            </label>
            {!isValid && <div {...className('error')}>{message}</div>}
            <div {...className('additional-information')}>{additionalInfo}</div>
        </div>
    );
}

DefaultTokenExpirationConfiguration.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    additionalInfo: PropTypes.string,
};
