angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    'plotUtils',
    'blockService',
    function (chartTemplates, Chart, plotUtils, blockService) {
        function createChart(filters) {
            const chart = Chart.create()
                .name('Network Errors')
                .type('column')
                .forcedResolution(30000)
                .colorByMetric(true)
                .yAxis(0, { label: 'Interface errors - RED' })
                .yAxis(1, { label: 'Retransmits - BLUE' });

            const letters = ['if_errors.rx', 'if_errors.tx'].map(function (metric) {
                const plot = chart
                    .plot()
                    .metric(metric)
                    .visible(false)
                    .propertyFilter('plugin_instance', 'eth*')
                    .propertyFilter('plugin', 'interface')
                    .propertyFilters(filters);

                return plotUtils.getLetterFromUniqueKey(plot.uniqueKey());
            });

            chart
                .plot()
                .macro(letters.join('+'))
                .name('interface errors')
                .yAxis(0)
                .color('#b04600')
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asTransformation(1, 'm'))
                .addManipulation(blockService.sum.asAggregation());

            chart
                .plot()
                .metric('protocol_counter.RetransSegs')
                .name('retransmits')
                .yAxis(1)
                .color('#007c1d')
                .propertyFilter('plugin', 'protocols')
                .propertyFilters(filters)
                .addManipulation(blockService.sum.asAggregation());

            return chart;
        }

        chartTemplates.register('collectdHostsNetworkErrors', createChart);
    },
]);
