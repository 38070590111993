angular.module('signalflowv2').service('analyticsFunctionConverter', [
    'blockConversionFunctions',
    'blockArgumentUtils',
    function (blockConversionFunctions, blockArgumentUtils) {
        function convertAnalyticsBlock(block, metricType) {
            if (blockArgumentUtils.isDisabled(block)) {
                throw new Error(
                    'enable=False can only be used in a publish function in Plot Builder.'
                );
            }
            if (blockConversionFunctions[block.functionName]) {
                return blockConversionFunctions[block.functionName](block, metricType);
            } else {
                throw new Error(
                    `The function ${block.functionName} cannot be represented in Plot Builder.`
                );
            }
        }

        return {
            convertAnalyticsBlock,
        };
    },
]);
