angular.module('signalview.instrumentation', []).service('instrumentationService', [
    '$timeout',
    '$log',
    'signalviewMetrics',
    '$window',
    function ($timeout, $log, metrics, $window) {
        function getTime() {
            if ($window.performance) {
                return $window.performance.now();
            } else {
                return Date.now();
            }
        }
        return {
            getInstrumentationTimer: function (metricPrefix, eventList, timeoutMs) {
                metrics.incr(metricPrefix + '.total');
                let startTime = null,
                    timeoutPromise = null,
                    metricsPublished = false;
                const eventMap = {};
                const events = angular.copy(eventList);
                events.forEach(function (itm) {
                    eventMap[itm] = null;
                });

                function publishMetrics() {
                    // publish known metrics and timeouts if applicable.  cancel the timeout timer
                    metricsPublished = true;
                    $timeout.cancel(timeoutPromise);
                    metrics.incr(metricPrefix + '.instrumented');
                    angular.forEach(eventMap, function (milliseconds, eventname) {
                        if (milliseconds !== null) {
                            metrics.incr([metricPrefix, eventname, 'total'].join('.'));
                            metrics.set([metricPrefix, eventname].join('.'), {}, milliseconds);
                        }
                    });
                    if (events.length > 0) {
                        metrics.incr(metricPrefix + '.timeout');
                        events.forEach(function (eventname) {
                            metrics.incr([metricPrefix, eventname, 'timeout'].join('.'));
                        });
                    }
                }

                function getNextEvent() {
                    return events[0] || null;
                }

                return {
                    init: function () {
                        startTime = getTime();
                        timeoutPromise = $timeout(publishMetrics, timeoutMs || 60000);
                    },
                    abort: function () {
                        if (!metricsPublished) {
                            metrics.incr(metricPrefix + '.aborted');
                            $log.info('Aborted instrumentation.');
                            metricsPublished = true;
                        }
                    },
                    report: function (eventName) {
                        if (startTime === null) {
                            $log.error(
                                'An attempt was made to report ' +
                                    eventName +
                                    ' before initializing the instrumentation timer.'
                            );
                            return;
                        }
                        if (metricsPublished) {
                            $log.warn(
                                'An attempt was made to report ' +
                                    eventName +
                                    ' after the timeout had occurred, ' +
                                    'instrumentation was aborted, or all metrics have already been published.'
                            );
                            return;
                        }
                        // if the value being reported has never been reported is the
                        // next event in the sequence, add its data and pop it
                        if (eventMap[eventName] === null && eventName === getNextEvent()) {
                            eventMap[eventName] = getTime() - startTime;
                            events.shift();
                            //if all events have been reported, publish immediately
                            if (events.length === 0) {
                                publishMetrics();
                            }
                        } else {
                            $log.error(
                                'The event ' +
                                    eventName +
                                    ' has either already been reported or is out of order.'
                            );
                        }
                    },
                };
            },
        };
    },
]);
