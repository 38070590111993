import templateUrl from './maxDelayOverridePicker.tpl.html';
import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export default {
    bindings: {
        maxDelayOverride: '<',
        disabled: '<',
        updateMaxDelay: '&',
        labelId: '@?',
    },
    templateUrl,
    controller: [
        '$scope',
        'msToStrFilter',
        function ($scope, msToStrFilter) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            function $onInit() {
                $scope.maxDelayDisplay = convertMSToStr($ctrl.maxDelayOverride);
                $scope.updateMaxDelay = $ctrl.updateMaxDelay;
                $scope.disabled = $ctrl.disabled;
                $scope.errorMessage = '';
                $scope.isDropdownOpen = false;
            }

            $scope.handleKeydown = function (event) {
                if (event.keyCode === 27) {
                    event.preventDefault();
                    $scope.isDropdownOpen = false;
                    if ($ctrl.labelId) {
                        document.getElementById($ctrl.labelId).focus();
                    }
                }
            };

            $scope.handleInputKeydown = function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    $scope.isDropdownOpen = true;
                }
            };

            function $onChanges() {
                $scope.maxDelayDisplay = convertMSToStr($ctrl.maxDelayOverride);
            }

            $scope.maxDelayOverrideOptions = [
                {
                    display: 'Default',
                    value: '(use individual chart settings)',
                },
                {
                    display: 'Auto',
                    value: '(as little as possible)',
                },
                {
                    display: '5s',
                    value: '',
                },
                {
                    display: '10s',
                    value: '',
                },
                {
                    display: '30s',
                    value: '',
                },
                {
                    display: '1m',
                    value: '',
                },
                {
                    display: '5m',
                    value: '',
                },
            ];

            function convertMSToStr(ms) {
                switch (ms) {
                    case null:
                        return 'Default';
                    case 0:
                        return 'Auto';
                    default:
                        return msToStrFilter(ms);
                }
            }

            function convertStrToMS(delayString) {
                switch (delayString) {
                    case 'Default':
                        return null;
                    case 'Auto':
                        return 0;
                    default:
                        return convertStringToMS(delayString);
                }
            }

            function emitMessageIfDifferent(maxDelayMs) {
                if (maxDelayMs !== $ctrl.maxDelayOverride) {
                    $scope.$emit('max delay dirty');
                }
            }

            $scope.setMaxDelay = function (userInput) {
                $scope.maxDelayDisplay = userInput;

                if (userInput) {
                    const maxDelayMs = convertStrToMS(userInput);
                    emitMessageIfDifferent(maxDelayMs);

                    $scope.updateMaxDelay({ maxDelayOverride: maxDelayMs });
                }
            };

            $scope.$watch(
                'maxDelayForm.input.$error',
                function (errors) {
                    if (errors.pattern || errors.required) {
                        $scope.errorMessage = 'Enter in minutes (m) and/or seconds (s)';
                    } else if (errors.maxDelayValue) {
                        $scope.errorMessage = 'Max delay must be no longer than 15 minutes';
                    } else {
                        $scope.errorMessage = '';
                    }
                },
                true
            );
        },
    ],
};
