import templateUrl from './kubeDetails.tpl.html';

// TODO: Refactor common functions from here and kubeTable into a common utility
export default {
    templateUrl,
    bindings: {
        config: '<',
        time: '<',
        filterBy: '<',
    },
    controller: [
        '$scope',
        '_',
        'mustache',
        'kubeDataTableStreamer',
        'valueRenderer',
        'KUBE_POD_PHASES_MAPPING',
        function (
            $scope,
            _,
            mustache,
            kubeDataTableStreamer,
            valueRenderer,
            KUBE_POD_PHASES_MAPPING
        ) {
            const $ctrl = this;
            const streamer = kubeDataTableStreamer();

            $ctrl.loading = true;
            $ctrl.sourceFilters = [];
            $ctrl.requirementsSatisfied = false;
            $ctrl.$onChanges = $onChanges;
            $ctrl.renderValue = renderValue;
            $ctrl.getGroupRowIds = getGroupRowIds;

            function $onChanges({ filterBy, time, config }) {
                if (!filterBy && !time && !config) {
                    return;
                }

                $ctrl.title = $ctrl.config.title;
                $ctrl.dataGroup = $ctrl.config.dataGroup;

                if (streamer) {
                    streamer.stop();
                }

                for (const filterKey of $ctrl.config.requiredFilters) {
                    if (
                        $ctrl.filterBy &&
                        $ctrl.filterBy.some((filter) => filter.property === filterKey)
                    ) {
                        continue;
                    }
                    $ctrl.loading = false;
                    $ctrl.requirementsSatisfied = false;
                    return;
                }

                $ctrl.requirementsSatisfied = true;
                initializeStreamer();
            }

            function initializeStreamer() {
                $ctrl.loading = true;
                $ctrl.hasTableData = false;

                const idFunction = getIdFunction($ctrl.config.METRICS);
                streamer.setJobParams(idFunction, $ctrl.config, $ctrl.filterBy, $ctrl.time);
                streamer.addDataCallback(updateData);
                streamer.start();
            }

            function getGroupRowIds({ propertyMap }) {
                const map = propertyMap.find((map) => map.metric && $ctrl.data[map.metric]);
                if (map) {
                    return $ctrl.data[map.metric].rowIds;
                }
            }

            function updateData(dataByMetric, latestTimeStamp) {
                $ctrl.latestTimeStamp = Math.max($ctrl.latestTimeStamp || 0, latestTimeStamp);
                $ctrl.data = {};
                for (const streamLabel in dataByMetric) {
                    const metricData = dataByMetric[streamLabel];
                    $ctrl.data[streamLabel] = { rows: metricData };
                    $ctrl.data[streamLabel].rowIds = Object.keys(metricData);
                    $ctrl.data[streamLabel].rowIds.sort(dataIdsSortBy);
                }

                $ctrl.loading = false;
                $ctrl.hasTableData = _.values($ctrl.data).length > 0;
            }

            function dataIdsSortBy(a, b) {
                return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
            }

            $scope.$on('$destroy', () => {
                // When users click around the app really fast, the streamer
                // object has not had a chance to get initialized yet. There is nothing
                // to do if the streamer object does not exist
                if (!streamer) {
                    return;
                }
                streamer.stop();
            });

            function getIdFunction(METRICS = []) {
                const idTemplates = {};
                METRICS.forEach((metric) => {
                    idTemplates[metric.displayName || metric.job.varName] = metric.job.unique_id;
                });

                return (metadata) =>
                    idTemplates[metadata.sf_streamLabel]
                        ? mustache.render(idTemplates[metadata.sf_streamLabel], metadata)
                        : metadata.sf_streamLabel;
            }

            function getResourceStatus(value) {
                switch (value) {
                    case 1:
                        return 'Ready';
                    case 0:
                        return 'Not Ready';
                    case -1:
                        return 'Unknown';
                }
            }

            function getPodPhase(value) {
                if (value in KUBE_POD_PHASES_MAPPING) {
                    return KUBE_POD_PHASES_MAPPING[value];
                }
                return 'Unknown';
            }

            function renderCustom({ timestamp, value }, propertyMap) {
                if (!value) {
                    return valueRenderer('Unknown');
                }

                const format = propertyMap.format.split(':')[1];

                if (format === 'ResourceStatus') {
                    return getResourceStatus(value);
                }

                if (format === 'PodPhase') {
                    return getPodPhase(value);
                }

                if (format === 'Age') {
                    const converted = new Date(value).getTime();
                    value = (timestamp - converted) / 1000;
                }

                return valueRenderer(value, format);
            }

            function renderValue(id, rowMap, propertyMap) {
                let formattedValue;

                if (propertyMap && !_.isNil(propertyMap.value)) {
                    formattedValue = propertyMap.value;
                } else if (rowMap && rowMap.rows && rowMap.rows[id] && propertyMap) {
                    const row = rowMap.rows[id];
                    const format = propertyMap.format;
                    const timestamp = row.timestamp;

                    const propertyName = !propertyMap.propertyNameTemplate
                        ? propertyMap.property || 'value'
                        : mustache.render(propertyMap.propertyNameTemplate, row).toLowerCase(); // Dimension/props use snake_case
                    const value = row[propertyName];

                    if (format && format === 'List') {
                        let value = '';
                        Object.keys(row).forEach((key) => {
                            if (key.startsWith(propertyName)) {
                                value += key + ':' + row[key] + '\n';
                            }
                        });

                        if (value.length) {
                            formattedValue = value;
                        }
                    } else if (format && format.startsWith('Custom')) {
                        formattedValue = renderCustom({ timestamp, value }, propertyMap);
                    } else if (!_.isNil(value)) {
                        formattedValue = valueRenderer(value, format);
                    }
                }

                if (formattedValue) {
                    return `<span class="formatted-value">${formattedValue}</span>`;
                } else if (!_.isNil(propertyMap.alt)) {
                    return `<span class="empty-alt-value">${propertyMap.alt}</span>`;
                }

                return '<span class="empty-value">-</span>';
            }
        },
    ],
};
