import catalogGlobalSearch from './catalogGlobalSearch.js';
import catalogFilterTemplateUrl from './catalogFilter.tpl.html';
import { catalog } from './catalog';

angular
    .module('signalview.catalog', [
        'signalview.buckets',
        'signalview.businessObjectDefinitions',
        'signalview.globalEvents',
        'signalview.orgNotifications',
        'sfx.filterControls',
        'signalview.urlOverrides',
        'sfx.ui',
        'catalogPreviewer',
        'signalview.detector',
        'sfx.api.v2',
    ])
    .component('catalogGlobalSearch', catalogGlobalSearch)
    .service('sidebarService', [
        function () {
            return {
                // default to not show side bar
                showSidebar: false,
            };
        },
    ])
    .directive('catalogFilter', [
        '$location',
        function ($location) {
            return {
                replace: true,
                templateUrl: catalogFilterTemplateUrl,
                link: function ($scope) {
                    $scope.focusFilter = function (filter) {
                        const search = $location.search();
                        search.selectedKeyValue = filter.property + ':' + filter.propertyValue;
                        delete search.select;
                        delete search.topicFilterKey;
                        $location.search(search);
                    };
                },
            };
        },
    ])
    .component('catalog', catalog);
