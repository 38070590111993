import { isEqual } from 'lodash';

import { SfxIntegrationAzureFilterConverter } from './filterConverter.js';
import templateUrl from './tagFilters.tpl.html';

angular.module('sfx.ui').component('sfxAzureTagFilters', {
    templateUrl,
    bindings: {
        signalflow: '<',
        editable: '<',
        onSignalflowChange: '&',
    },
    controller: class AzureTagFilters {
        static $inject = [SfxIntegrationAzureFilterConverter.DI, '$scope', '$q'];

        constructor(sfxIntegrationAzureFilterConverter, $scope, $q) {
            this.sfxIntegrationAzureFilterConverter = sfxIntegrationAzureFilterConverter;
            this.$scope = $scope;
            this.$q = $q;
            this.signalflowToTagsCache = new Map();

            this.isUICompatible = true;
            this.isLoading = false;
            this.hasError = false;
            this.tags = [];
        }

        get isValid() {
            return this.tags.every((tag) => tag.key && tag.value);
        }

        get canAddTag() {
            return this.tags.every((tag) => tag.key || tag.value);
        }

        $onInit() {
            this.reloadFromSignalflow();
        }

        $onChanges() {
            this.reloadFromSignalflow();
        }

        handleCreate() {
            this.tags.push({
                id: this.sfxIntegrationAzureFilterConverter.getNextId(),
                key: '',
                value: '',
            });
            this.dispatchChange();
        }

        handleRemove(tagId) {
            this.tags = this.tags.filter((t) => t.id !== tagId);
            this.dispatchChange();
        }

        handleChange(tagId, tagKey, tagValue) {
            const tag = this.tags.find((tag) => tag.id === tagId);
            tag.key = tagKey;
            tag.value = tagValue;

            this.dispatchChange();
        }

        dispatchChange() {
            const signalflow = this.sfxIntegrationAzureFilterConverter.tagsToSignalflow(this.tags);
            this.signalflowToTagsCache.set(signalflow, JSON.parse(JSON.stringify(this.tags)));

            this.onSignalflowChange({
                signalflow,
            });
        }

        reloadFromSignalflow() {
            if (this.signalflowToTagsCache.has(this.signalflow)) {
                const cachedTags = this.signalflowToTagsCache.get(this.signalflow);
                if (!isEqual(this.tags, cachedTags)) {
                    this.tags = JSON.parse(JSON.stringify(cachedTags));
                }
                return;
            }

            if (this.signalflow === null || this.signalflow === undefined) {
                this.isUICompatible = false;
                return;
            }

            this.isUICompatible = true;
            this.parseSignalflow();
        }

        parseSignalflow() {
            this.isLoading = true;
            this.hasError = false;
            this.sfxIntegrationAzureFilterConverter
                .signalflowToTags(this.signalflow)
                .then(
                    (tags) => {
                        this.tags = tags;

                        if (this.tags === undefined) {
                            this.isUICompatible = false;
                            return;
                        }

                        this.signalflowToTagsCache.set(
                            this.signalflow,
                            JSON.parse(JSON.stringify(this.tags))
                        );
                    },
                    (e) => {
                        this.hasError = true;
                        console.error(e);
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
});
