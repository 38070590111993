import objectInfoModalTemplateUrl from './objectinfomodal.tpl.html';
import objectInfoTemplateUrl from './objectinfo.tpl.html';

import uiBootstrap from 'angular-ui-bootstrap';
angular
    .module('signalview.objectbrowser', [
        'restangular',
        'signalboost',
        'signalview.selectionFilter',
        uiBootstrap,
        'signalview.instrumentation',
        'sfx.data',
        'signalview.consts',
        'signalview.autoSuggestInput',
        'signalview.sfGlobalNav',
        'signalview.globalEvents',
    ])

    .service('sfObjInfo', [
        'sfxModal',
        function (sfxModal) {
            return {
                showObjectInfo: function (obj) {
                    sfxModal.open({
                        templateUrl: objectInfoModalTemplateUrl,
                        controller: [
                            '$scope',
                            'obj',
                            function ($scope, obj) {
                                $scope.obj = obj;
                                $scope.name = '';
                            },
                        ],
                        size: 'md',
                        resolve: {
                            obj: function () {
                                return obj;
                            },
                        },
                    });
                },
            };
        },
    ])

    .directive('sfObjectInfo', [
        function () {
            return {
                restrict: 'AE',
                replace: true,
                scope: {
                    obj: '=',
                    hideTitle: '@?',
                    name: '=?',
                    snapshot: '=?',
                },
                templateUrl: objectInfoTemplateUrl,
                link: function (scope) {
                    scope.name =
                        scope.obj.name || scope.obj['sf_' + scope.obj.sf_type.toLowerCase()] || '';
                },
            };
        },
    ]);
