import { IntegrationTypeOptions } from '@splunk/olly-integrations';

angular
    .module('signalview.orgIntegrations')

    .service('integrationsListService', [
        'BUSINESS_PROCESS_INTEGRATIONS',
        'BUSINESS_PROCESS_ENABLED',
        'SAML_PROVIDERS',
        'config',
        'integrationsMeta',
        'dataSignatureService',
        'getIntegrationBadgeURL',
        'featureEnabled',
        '_',
        'credentialV2Service',
        function (
            BUSINESS_PROCESS_INTEGRATIONS,
            BUSINESS_PROCESS_ENABLED,
            SAML_PROVIDERS,
            config,
            integrationsMeta,
            dataSignatureService,
            getIntegrationBadgeURL,
            featureEnabled,
            _,
            credentialV2Service
        ) {
            const apmIntegrations = new Set([
                'customurl',
                'elastic-stack',
                'splunk',
                'pagerduty',
                'slack',
                'victorops',
                'webhook',
                'servicenow',
                'bigpanda',
                'xmatters',
                'opsgenie',
                'amazoneventbridge',
                'onelogin',
                'okta',
                'saml',
                'googleSaml',
                'googleCloudIdentity',
                'googleauth',
                'azureAd',
            ]);
            const categoryMap = {
                essential: {
                    items: ['aws'],
                    title: 'Essential Services',
                },
                servicesMonitored: {
                    items: ['aws', 'newrelic'],
                    title: 'Services Monitored',
                },
                AWSservicesMonitored: {
                    items: [],
                    title: 'AWS Services Monitored',
                },
                GCPServicesMonitored: {
                    items: [],
                    title: 'GCP Services Monitored',
                },
                AzureServicesMonitored: {
                    items: [],
                    title: 'Azure Services Monitored',
                },
                linkedServices: {
                    items: [],
                    title: 'Linked Services',
                },
                notification: {
                    items: [
                        'pagerduty',
                        'slack',
                        'victorops',
                        'webhook',
                        'servicenow',
                        'bigpanda',
                        'office365',
                        'xmatters',
                        'opsgenie',
                        'amazoneventbridge',
                        'jira',
                        'incidentIntelligenceJira',
                        'incidentIntelligenceZoom',
                        'incidentIntelligenceServiceNow',
                        'incidentIntelligenceBMCRemedy',
                    ],
                    title: 'Notification Services',
                },
                authentication: {
                    items: SAML_PROVIDERS.concat(['googleauth']),
                    title: 'Login Services',
                },
                deprecatedServices: {
                    items: ['googleSaml'],
                    title: 'Deprecated Services',
                },
            };

            const enabledIntegrations = _.assign({}, BUSINESS_PROCESS_ENABLED);

            function getIntegrationType(integrationName) {
                if (integrationName.match(/collectd/)) {
                    return 'plugin';
                } else if (BUSINESS_PROCESS_INTEGRATIONS[integrationName]) {
                    return 'integrations';
                } else {
                    return 'custom';
                }
            }

            // add integrations stored in constants
            const allIntegrations = _.assign({}, BUSINESS_PROCESS_INTEGRATIONS);

            // add those from integrationsDocumentation
            Object.keys(integrationsMeta).forEach(function (key) {
                if (integrationsMeta[key].in_app_categories) {
                    allIntegrations[key] = {
                        longDescription: integrationsMeta[key].description,
                        name: key,
                        title: integrationsMeta[key].display_name,
                        sortOrder: integrationsMeta[key].sort_order,
                        credType: allIntegrations[key] ? allIntegrations[key].credType : null,
                        feature: integrationsMeta[key].feature,
                    };

                    const categories = integrationsMeta[key].in_app_categories;
                    for (let i = 0; i < categories.length; i++) {
                        if (categoryMap[categories[i]]) {
                            categoryMap[categories[i]].items.push(key);
                        }
                    }

                    enabledIntegrations[key] = true;
                }
            });

            Object.keys(allIntegrations).forEach(function (key) {
                allIntegrations[key].integrationType = getIntegrationType(key);
                allIntegrations[key].badgeURL = getIntegrationBadgeURL(key);
            });

            function getIntegrationsCategories() {
                dataSignatureService.processIntegrations(allIntegrations);

                return Object.keys(categoryMap).map(function (category) {
                    const integrations = getFilteredIntegrations(category).filter(
                        (integration) =>
                            integration &&
                            (apmIntegrations.has(integration.name) ||
                                featureEnabled('infra') ||
                                integration.feature)
                    );
                    return {
                        title: categoryMap[category].title,
                        integrations,
                    };
                });
            }

            function isIntegrationConfigured() {
                return dataSignatureService.processIntegrations(allIntegrations).then(() => {
                    return _.some(allIntegrations, (integration) => integration.enabled);
                });
            }

            function filterEnabledIfNotSuper(category) {
                let integrationNames = categoryMap[category].items;
                if (!config('superpower.unreleasedFeatures')) {
                    integrationNames = integrationNames.filter(function (integrationName) {
                        return (
                            enabledIntegrations[integrationName] ||
                            featureEnabled('integration_enabled_' + integrationName)
                        );
                    });
                }
                return integrationNames;
            }

            function getFilteredIntegrations(category) {
                const integrations = filterEnabledIfNotSuper(category);
                return integrations.map(function (integrationName) {
                    return allIntegrations[integrationName];
                });
            }

            function getIntegration(name) {
                return allIntegrations[name];
            }

            function getAllEnabledIntegrations() {
                return dataSignatureService.processIntegrations(allIntegrations).then(() => {
                    const list = [];
                    for (const key of Object.keys(allIntegrations)) {
                        if (allIntegrations[key].enabled) {
                            list.push({
                                name: key,
                                created: allIntegrations[key].created,
                            });
                        }
                    }
                    return list;
                });
            }

            function getOrgIntegrations() {
                return _.values(allIntegrations).filter(
                    (integration) =>
                        apmIntegrations.has(integration.name) ||
                        featureEnabled('infra') ||
                        integration.feature
                );
            }

            function isIntegrationPurchased(integration) {
                if (integration.dependsOn.length) {
                    return (
                        integration.dependsOn.filter((featureFlag) => featureEnabled(featureFlag))
                            .length > 0
                    );
                }
                return true;
            }

            function isIntegrationHidden(integration) {
                if (integration?.excludesOn?.length) {
                    return integration.excludesOn.some((featureFlag) => {
                        if (featureFlag.startsWith('!')) {
                            return !featureEnabled(featureFlag.slice(1));
                        }
                        return featureEnabled(featureFlag);
                    });
                }
                return false;
            }

            function getAllIntegrationsForSearch() {
                return (window.__olly_integrations || []).filter(
                    (integration) =>
                        integration.readyForRelease &&
                        !isIntegrationHidden(integration) &&
                        isIntegrationPurchased(integration)
                );
            }

            async function getActiveIntegrations() {
                return credentialV2Service.getActiveIntegrations();
            }

            function isIntegrationScriptedOrDocs(integrationTile) {
                return (
                    integrationTile.type === IntegrationTypeOptions.SCRIPTED ||
                    integrationTile.type === IntegrationTypeOptions.DOCS_ONLY
                );
            }

            function getIntegrationRedirectPath(integrationTile, hasIntegrationBeenSetUp) {
                const isIntegrationHosted = integrationTile.type === IntegrationTypeOptions.HOSTED;
                const isScriptedOrDocs = isIntegrationScriptedOrDocs(integrationTile);

                if (!isScriptedOrDocs && hasIntegrationBeenSetUp) {
                    if (isIntegrationHosted) {
                        return `#${integrationTile.newIntegrationLink}`;
                    }
                    return `#/integrations/${integrationTile.name}`;
                } else if (isIntegrationHosted || integrationTile.flowSteps) {
                    return `#${integrationTile.newIntegrationLink}`;
                } else {
                    return `#/integrations/${integrationTile.name}/description`;
                }
            }

            return {
                getIntegrationType: getIntegrationType,
                getIntegrationsCategories: getIntegrationsCategories,
                getIntegration: getIntegration,
                getAllIntegrations: getOrgIntegrations,
                getFilteredIntegrations: getFilteredIntegrations,
                isIntegrationConfigured,
                getAllEnabledIntegrations,
                getAllIntegrationsForSearch,
                getActiveIntegrations,
                getIntegrationRedirectPath,
            };
        },
    ]);
