//Component for filter/exclude by data table
import templateUrl from './tableCell.tpl.html';
import tableCellRollupTooltip from '../../../../../app/charting/chartdisplay/chartTooltip/tableCellRollupTooltip.tpl.html';

export const tableCell = {
    templateUrl,
    bindings: {
        row: '<',
        column: '<',
        grid: '<',
        linkTime: '<',
        inEditor: '<?',
        disableLegendDropdown: '<?',
    },
    controller: [
        '$scope',
        'urlOverridesService',
        function ($scope, urlOverridesService) {
            const $ctrl = this;
            const ignoredKeys = [
                'sf_metric',
                'sf_originatingMetric',
                'value',
                'pinnedValue',
                'highThreshold',
                'lowThreshold',
                'computationId',
                'rollup',
            ];

            const actionItems = [
                {
                    title: 'Filter on cell',
                    action: () => $scope.filterDataTable(false),
                    label: 'Filter',
                },
                {
                    title: 'Exclude on cell',
                    action: () => $scope.filterDataTable(true),
                    label: 'Exclude',
                },
            ];

            let unwatchScrollForTooltip;

            $ctrl.cellOptions = {};

            $ctrl.getCellValue = function () {
                return $ctrl.grid.getCellValue($ctrl.row, $ctrl.column);
            };

            $ctrl.onMouseEnter = function () {
                $ctrl.hovering = true;
                $ctrl.crosslinkContext = {};

                const dataTableScope = $ctrl.grid.appScope;
                // Plot Dimensions Keys / Data-table column names
                const plotDimensionKeys = dataTableScope.legendKeys.filter(
                    (legend) => !ignoredKeys.includes(legend)
                );
                // Respective dimension values
                const pointMetaData =
                    dataTableScope.sortedPoints[$ctrl.grid.rows.indexOf($ctrl.row)].pointMetaData;

                if (pointMetaData) {
                    plotDimensionKeys.forEach((key) => {
                        $ctrl.crosslinkContext[key] = pointMetaData[key];
                    });
                }
                $ctrl.hovered = true;
            };

            $ctrl.onMouseLeave = function () {
                $ctrl.hovering = false;
            };

            $ctrl.$onInit = function () {
                $scope.showFilterLink = ignoredKeys.indexOf($ctrl.column.name) === -1;
                if ($ctrl.column.name === 'rollup') {
                    $ctrl.helpTooltipUrl = tableCellRollupTooltip;
                }
            };

            $ctrl.$onChanges = function () {
                $ctrl.cellOptions.dimensions = [
                    {
                        propertyName: $ctrl.column.name,
                        propertyValue: $ctrl.getCellValue(),
                        actionItems: $ctrl.grid.appScope.chartModel.sf_detector
                            ? null
                            : actionItems,
                    },
                ];

                // Should not appear on a chart builder
                $ctrl.cellOptions.showConfigureLinkItem = !$ctrl.inEditor;
            };

            $scope.filterDataTable = function (exclude) {
                let sourceOverrides = urlOverridesService.getSourceFilterOverrideList() || [];
                sourceOverrides = sourceOverrides.map((override) => {
                    override.propertyValue = override.value;
                    delete override.value;
                    return override;
                });

                let colName = $ctrl.column.name;
                if (colName === 'sf_originatingMetric') {
                    colName = 'sf_metric';
                }
                sourceOverrides.push({
                    NOT: exclude,
                    property: colName,
                    propertyValue: $ctrl.getCellValue(),
                });
                urlOverridesService.setSourceFilterOverrideList(sourceOverrides);
            };

            function watchScrollForTooltip() {
                unwatchScrollForTooltip = $scope.$watchGroup(
                    ['$ctrl.grid.isScrollingHorizontally', '$ctrl.grid.isScrollingVertically'],
                    function () {
                        if (
                            $ctrl.grid.isScrollingHorizontally ||
                            $ctrl.grid.isScrollingVertically
                        ) {
                            $ctrl.helperTooltipOpen = false;
                        }
                    }
                );
            }

            function unwatchScroll() {
                if (unwatchScrollForTooltip) {
                    unwatchScrollForTooltip();
                    unwatchScrollForTooltip = null;
                }
            }

            $scope.$watch('$ctrl.helperTooltipOpen', function (tooltipOpen) {
                unwatchScroll();
                if (tooltipOpen) {
                    watchScrollForTooltip();
                }
            });
        },
    ],
};
