import NODE_DETAIL_CONFIG from '../../../../app/kubeNavigator/configs/nodes/nodeDetailConfig.js';
import WORKLOAD_LIST_CONFIG from '../../../../app/kubeNavigator/configs/nodes/nodeWorkloadsListConfig.js';
import CONTAINER_LIST_CONFIG from '../../../../app/kubeNavigator/configs/nodes/NodeContainerListConfig.js';
import templateUrl from './kubeNodeDetail.tpl.html';
import fixedFilterEventFeed from '../common/fixedFilterEventFeed.tpl.html';
import {
    getCPUChartModel,
    getMemoryChartModel,
    getNetworkInChartModel,
    getNetworkOutChartModel,
} from '../../../../app/kubeNavigator/configs/nodes/nodeDetailChartModelsConfig.js';
import { getEventChartModel } from '../../../../app/kubeNavigator/configs/nodes/nodesEventFeedConfig';

export default {
    templateUrl,
    bindings: {
        time: '<',
        filterAlias: '<', // Variable definitions to be supplied to chart models
        filters: '<',
        isOnSidebar: '<?',
        onSelection: '<?',
        selectedTabDisplayName: '<?',
    },
    controller: [
        'kubeDataService',
        'transientChartModelMaker',
        function (kubeDataService, transientChartModelMaker) {
            const $ctrl = this;
            let lastFilterAliases;

            $ctrl.configs = {
                nodeDetailConfig: NODE_DETAIL_CONFIG,
                workloadListConfig: WORKLOAD_LIST_CONFIG,
                containerListConfig: CONTAINER_LIST_CONFIG,
            };

            $ctrl.sharedChartState = {};
            $ctrl.searchableWorkloads = { placeholder: 'Search workloads' };
            $ctrl.searchableContainers = { placeholder: 'Search containers' };
            $ctrl.$onChanges = $onChanges;
            $ctrl.onWorkloadSelection = onWorkloadSelection;
            $ctrl.onContainerSelection = onContainerSelection;

            function onWorkloadSelection(selection) {
                const { kubernetes_cluster, service } = selection;
                if (service && $ctrl.onSelection) {
                    $ctrl.onSelection({ kubernetes_cluster, service });
                }
            }

            function onContainerSelection(selection) {
                const { kubernetes_cluster, kubernetes_node, kubernetes_pod_uid, container_id } =
                    selection;
                if (container_id && kubernetes_pod_uid && $ctrl.onSelection) {
                    $ctrl.onSelection({
                        kubernetes_cluster,
                        kubernetes_node,
                        kubernetes_pod_uid,
                        container_id,
                    });
                }
            }

            function $onChanges({ filterAlias, filters, time }) {
                const filtersUpdated =
                    (filterAlias || filters) &&
                    !angular.equals(lastFilterAliases, $ctrl.filterAlias);

                if (filtersUpdated) {
                    lastFilterAliases = angular.copy($ctrl.filterAlias);

                    const areVariablesPresent =
                        $ctrl.filterAlias &&
                        $ctrl.filterAlias.some(({ value }) => !_.isEmpty(value));

                    $ctrl.areOverridesPresent =
                        areVariablesPresent ||
                        ($ctrl.filters && $ctrl.filters.some(({ value }) => !_.isEmpty(value)));
                }

                if (filtersUpdated || time) {
                    processChartModels();
                }
            }

            function processChartModels() {
                const time = kubeDataService.getChartModelTime($ctrl.time);
                const filters = kubeDataService.cleanFilters($ctrl.filterAlias);
                const filtersString = kubeDataService.generateFilterStrings(filters);

                const cpuChartModel = getCPUChartModel(time, filtersString);
                const memoryChartModel = getMemoryChartModel(time, filtersString);
                const networkInChartModel = getNetworkInChartModel(time, filtersString);
                const networkOutChartModel = getNetworkOutChartModel(time, filtersString);

                $ctrl.configs.nodeDetailChartModels = [
                    transientChartModelMaker(
                        cpuChartModel.chartOptions,
                        cpuChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        memoryChartModel.chartOptions,
                        memoryChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        networkInChartModel.chartOptions,
                        networkInChartModel.publishLabelOptions
                    ),
                    transientChartModelMaker(
                        networkOutChartModel.chartOptions,
                        networkOutChartModel.publishLabelOptions
                    ),
                ];

                const eventChartModel = getEventChartModel(time, filtersString);
                $ctrl.eventFeed = {
                    templateUrl: fixedFilterEventFeed,
                    model: transientChartModelMaker(
                        eventChartModel.chartOptions,
                        eventChartModel.publishLabelOptions
                    ),
                };
            }
        },
    ],
};
