angular
    .module('signalview.detectorV2')
    .constant('V2_DETECTOR_PUBLISH_ENABLED_ATTRIBUTES', [
        'displayName',
        'label',
        'paletteIndex',
        'valuePrefix',
        'valueSuffix',
        'valueUnit',
    ])
    .service('v2DetectorAPIWrapper', [
        '$http',
        'API_URL',
        'currentUser',
        'mustache',
        'localStorage',
        'uiModelToVisualizationOptionsService',
        'V2_DETECTOR_PUBLISH_ENABLED_ATTRIBUTES',
        'NOTIFIER_INTEGRATIONS_V2_MAP',
        function (
            $http,
            API_URL,
            currentUser,
            mustache,
            localStorage,
            uiModelToVisualizationOptionsService,
            V2_DETECTOR_PUBLISH_ENABLED_ATTRIBUTES,
            NOTIFIER_INTEGRATIONS_V2_MAP
        ) {
            const v2detectorendpoint = '/v2/detector';
            //provides name-normalized getters for pulling various fields off the detector response
            return function (detector) {
                if (!detector) {
                    detector = {
                        name: '',
                        programText: '',
                        rules: [],
                    };
                }

                return {
                    detector: function () {
                        return detector;
                    },
                    getCUrl: function () {
                        return currentUser.orgId().then(function (orgId) {
                            let str = mustache.render(
                                'curl -X ' +
                                    (detector.id ? 'PUT' : 'POST') +
                                    ' "' +
                                    API_URL +
                                    '/v2/detector' +
                                    (detector.id ? '/' + detector.id : '') +
                                    '?organizationId={{ORG}}" -H "X-SF-TOKEN: {{TOKEN}}" -H "Content-Type: application/json;charset=UTF-8"',
                                {
                                    ORG: orgId,
                                    TOKEN: localStorage['X-SF-TOKEN'],
                                }
                            );
                            str += ' -d "' + angular.toJson(detector).replace(/"/g, '\\"') + '"';
                            return str;
                        });
                    },
                    save: function () {
                        //clean out contentType because not ignorable legacy property
                        (detector.rules || []).forEach(function (rule) {
                            (rule.notifications || []).forEach(function (notif) {
                                const notifTypeLc = notif.type.toLowerCase();
                                if (NOTIFIER_INTEGRATIONS_V2_MAP[notifTypeLc]) {
                                    notif.type = NOTIFIER_INTEGRATIONS_V2_MAP[notifTypeLc];
                                }
                                if (notif.type === 'Webhook') {
                                    delete notif.contentType;
                                }
                            });
                        });

                        // doesn't make sense to use absolute time range in detector view.
                        if (
                            detector.visualizationOptions &&
                            detector.visualizationOptions.time &&
                            detector.visualizationOptions.time.type === 'absolute'
                        ) {
                            delete detector.visualizationOptions.time;
                        }

                        return currentUser.orgId().then(function (orgId) {
                            if (!detector.id) {
                                return $http.post(
                                    API_URL + v2detectorendpoint + '?organizationId=' + orgId,
                                    detector
                                );
                            } else {
                                return $http.put(
                                    API_URL +
                                        v2detectorendpoint +
                                        '/' +
                                        detector.id +
                                        '?organizationId=' +
                                        orgId,
                                    detector
                                );
                            }
                        });
                    },
                    isEphemeral: function () {
                        return !detector.id;
                    },
                    getId: function () {
                        return detector.id;
                    },
                    getName: function () {
                        return detector.name;
                    },
                    setName: function (name) {
                        detector.name = name;
                    },
                    getDescription: function () {
                        return detector.description;
                    },
                    setDescription: function (description) {
                        detector.description = description;
                    },
                    getSignalFlow: function () {
                        return detector.programText;
                    },
                    setSignalFlow: function (signalflow) {
                        detector.programText = signalflow;
                    },
                    getRules: function () {
                        return detector.rules;
                    },
                    setRules: function (rules) {
                        detector.rules = rules;
                    },
                    getShowDataMarkers: function () {
                        return (
                            (detector.visualizationOptions &&
                                detector.visualizationOptions.showDataMarkers) ||
                            false
                        );
                    },
                    setShowDataMarkers: function (showMarkers) {
                        if (!detector.visualizationOptions) {
                            detector.visualizationOptions = {};
                        }
                        detector.visualizationOptions.showDataMarkers = showMarkers;
                    },
                    getShowEventLines: function () {
                        return (
                            (detector.visualizationOptions &&
                                detector.visualizationOptions.showEventLines) ||
                            false
                        );
                    },
                    getTeams() {
                        return detector.teams;
                    },
                    setTeams(teams) {
                        detector.teams = teams;
                    },
                    setShowEventLines: function (showEventLines) {
                        if (!detector.visualizationOptions) {
                            detector.visualizationOptions = {};
                        }
                        detector.visualizationOptions.showEventLines = showEventLines;
                    },
                    getDisableThrottle: function () {
                        return (
                            (detector.visualizationOptions &&
                                detector.visualizationOptions.disableSampling) ||
                            false
                        );
                    },
                    setDisableThrottle: function (disableThrottle) {
                        if (!detector.visualizationOptions) {
                            detector.visualizationOptions = {};
                        }
                        detector.visualizationOptions.disableSampling = disableThrottle;
                    },
                    getTime: function () {
                        return (
                            (detector.visualizationOptions && detector.visualizationOptions.time) ||
                            null
                        );
                    },
                    setTime: function (time) {
                        if (!detector.visualizationOptions) {
                            detector.visualizationOptions = {};
                        }
                        detector.visualizationOptions.time = time;
                    },
                    getMaxDelay: function () {
                        return detector.maxDelay;
                    },
                    setMaxDelay: function (maxDelay) {
                        detector.maxDelay = maxDelay;
                    },
                    getTimezone: function () {
                        return detector.timezone;
                    },
                    setTimezone: function (timezone) {
                        detector.timezone = timezone;
                    },
                    updatePublishLabelOptions: function (uiModel) {
                        const visualizationOptions =
                            uiModelToVisualizationOptionsService(uiModel).serialize();

                        for (let i = 0; i < visualizationOptions.publishLabelOptions.length; i++) {
                            Object.keys(visualizationOptions.publishLabelOptions[i]).forEach(
                                (attr) => {
                                    if (V2_DETECTOR_PUBLISH_ENABLED_ATTRIBUTES.indexOf(attr) < 0) {
                                        delete visualizationOptions.publishLabelOptions[i][attr];
                                    }
                                }
                            );
                        }

                        detector.visualizationOptions.publishLabelOptions =
                            visualizationOptions.publishLabelOptions;
                    },
                };
            };
        },
    ]);
