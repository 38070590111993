'use strict';

angular.module('chartbuilderUtil').service('fixPlotExpressionKey', [
    'plotUtils',
    'programTextUtils',
    function (plotUtils, programTextUtils) {
        return function (allPlots) {
            if (programTextUtils.areAllExpressionsValid(allPlots)) {
                const expressionPlots = [];
                const oldExpressionKeyToNew = {};
                let newUniqueKey = 1;
                allPlots.forEach(function (plot) {
                    if (plot.type === 'ratio') {
                        expressionPlots.push(plot);
                    }

                    const newExpressionKey = plotUtils.getLetterFromUniqueKey(newUniqueKey);
                    const oldExpressionKey = plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
                    oldExpressionKeyToNew[oldExpressionKey] = newExpressionKey;
                    plot.uniqueKey = newUniqueKey;
                    if (plot._originalLabel) {
                        plot._originalLabel = newExpressionKey;
                    }
                    newUniqueKey++;
                });

                expressionPlots.forEach(function (plot) {
                    let newExpressionText = '';
                    let letterToUpdate = '';
                    for (let i = 0; i < plot.expressionText.length; i++) {
                        const nextCharacter = plot.expressionText[i];

                        if (nextCharacter.match(/[a-zA-Z]/)) {
                            letterToUpdate += nextCharacter;
                        } else {
                            if (oldExpressionKeyToNew[letterToUpdate]) {
                                newExpressionText += oldExpressionKeyToNew[letterToUpdate];
                            }
                            letterToUpdate = '';
                            newExpressionText += nextCharacter;
                        }
                    }

                    if (oldExpressionKeyToNew[letterToUpdate]) {
                        newExpressionText += oldExpressionKeyToNew[letterToUpdate];
                    } else {
                        newExpressionText += letterToUpdate;
                    }
                    plot.expressionText = newExpressionText;
                });
            } else {
                throw new Error('Cannot fix plots while there are invalid expressions.');
            }
        };
    },
]);
