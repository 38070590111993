import accessControlHelper from './accessControlHelper';

export default {
    getNonWritableObjectError,
};

function getNonWritableObjectError(permissions, userEffectiveActions, objectType) {
    const validationErrors = [];
    const hasExplicitEntries = permissions?.acl?.length > 0;

    if (!accessControlHelper.canUserModifyPermissions(userEffectiveActions)) {
        if (permissions?.parent) {
            validationErrors.push(
                `You must have write permission to the ${objectType?.parent?.label} to inherit its permissions.`
            );
        } else {
            validationErrors.push(
                accessControlHelper.getNoCurrentUserInAclErrorMessage(objectType.label)
            );
        }
    } else if (!hasExplicitEntries && !permissions?.parent) {
        validationErrors.push('Add at least one item to permissions table');
    }
    return validationErrors;
}
