import templateUrl from './createAclDashboardGroupModal.tpl.html';

const createAclDashboardGroupModalController = [
    '$scope',
    'themeService',
    'currentUser',
    'organizationService',
    '$q',
    function ($scope, themeService, currentUser, organizationService, $q) {
        $scope.themeKey = themeService.getColorScheme();
        $scope.userData = null;
        $q.all({
            user: currentUser.getOrgMember(),
            orgId: organizationService.getOrgSettings().then((result) => result.sf_organizationID),
        }).then(({ user, orgId }) => {
            $scope.userData = { userId: user.id, isAdmin: user.admin, orgId };
            $scope.$applyAsync();
        });

        $scope.onDismiss = (reason) => {
            $scope.$parent.$dismiss(reason);
        };

        $scope.onClose = (result) => $scope.$parent.$close(result);
    },
];

export default {
    templateUrl,
    controller: createAclDashboardGroupModalController,
    bindings: {
        customLabel: '@',
    },
};
