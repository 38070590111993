import { sanitizeQuery } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';

angular
    .module('signalboost')

    .service('SignalboostBaseService', [
        '$log',
        '$q',
        'Restangular',
        'signalboost',
        function ($log, $q, Restangular, signalboost) {
            /**
             * Base object for SignalBoost service objects. Provides methods for
             * creating and retrieving web resources from the SignalBoost REST API
             * (e.g. 'one', 'all', and 'create').
             *
             * @param {string} type - The name of the resource to interact with. This
             *                        must be the same as the path to the resource in
             *                        the Signalboost REST API. For example, if this
             *                        service should handle Page resources which are
             *                        available at the '/page' endpoint, then this
             *                        value should be 'page'.
             * @param {boolean} currentSupport
             * @param {string} nickname
             */
            return class BaseService {
                constructor(type, currentSupport, nickname, querySanitizer) {
                    this.type = type;
                    this.querySanitizer = querySanitizer || null;
                    if (currentSupport) {
                        this.current = function () {
                            return Restangular.one(type, 'current');
                        };
                    }

                    if (nickname) {
                        signalboost.types[nickname] = this;
                    } else {
                        signalboost.types[this.type] = this;
                    }
                }

                endpoint(endPoint) {
                    if (endPoint) {
                        this.endPoint = endPoint;
                    }
                    return this.endPoint || this.type;
                }
                all() {
                    return Restangular.all(this.endpoint());
                }
                one(id) {
                    return Restangular.one(this.endpoint(), id);
                }
                create(itm) {
                    return this.all().post(itm);
                }
                many(ids) {
                    const queries = [];
                    const self = this;
                    angular.forEach(ids, function (id) {
                        if (!id) {
                            $log.error('Id not provided for fetch in ', ids);
                        } else {
                            queries.push(
                                Restangular.one(self.endpoint(), id)
                                    .get()
                                    .then(
                                        function (data) {
                                            return data.sf_type.toLowerCase() === self.type
                                                ? data
                                                : null;
                                        },
                                        function () {
                                            return null;
                                        }
                                    )
                            );
                        }
                    });
                    return $q.all(queries).then(function (objects) {
                        const results = [];
                        angular.forEach(objects, function (obj) {
                            if (obj) {
                                results.push(obj);
                            }
                        });
                        return results;
                    });
                }
                addQueryTypeFields(querytoken) {
                    return (
                        '(sf_' +
                        this.type +
                        ':' +
                        querytoken +
                        ' AND sf_type:' +
                        this.type.charAt(0).toUpperCase() +
                        this.type.substring(1) +
                        ')'
                    );
                }
                addQueryAltFields(querystr, querytoken) {
                    return (
                        querystr +
                        (querytoken.match(/\*/) ? '' : ' OR sf_tags:(' + querytoken + ')')
                    );
                }
                sanitizeQuery(querystr, querytoken) {
                    if (this.querySanitizer) {
                        return this.querySanitizer(querystr, querytoken);
                    }
                    return querystr;
                }
                getQuerySortOrder() {
                    return 'sf_' + this.type;
                }
                /**
                 *
                 * @param {string} token - A search query.
                 * @param {boolean} getcount - If true, return the count of results
                 *                             instead of the results themselves.
                 *                             Defaults to false.
                 * @param {number} offset
                 * @param {number} limit
                 * @param {string} sortBy
                 * @param {boolean} sortDesc
                 */
                basicSearch(token, getcount, offset, limit, sortBy, sortDesc) {
                    const params = {};
                    if (token.match(/[:\s]/g) || !token) {
                        params.query = token;
                    } else {
                        const hasWildCard = token.match(/\*/);
                        params.query = this.addQueryTypeFields(
                            hasWildCard ? sanitizeQuery(token) : '*' + sanitizeQuery(token) + '*'
                        );
                        params.query = this.addQueryAltFields(params.query, token);
                    }

                    params.query = this.sanitizeQuery(params.query, token);
                    const orderBy = this.getQuerySortOrder();
                    if (orderBy) {
                        params.orderBy = orderBy;
                    }
                    if (getcount) {
                        params.getCount = true;
                    }
                    if (angular.isDefined(offset) && angular.isDefined(limit)) {
                        params.offset = offset;
                        params.limit = limit;
                    }
                    if (angular.isDefined(sortBy) && sortBy !== '' && sortBy !== null) {
                        const sortStr = (sortDesc ? '-' : '') + sortBy;
                        params.orderBy = sortStr;
                    }
                    return Restangular.all(this.endpoint())
                        .customGET('', params)
                        .then(function (data) {
                            return getcount ? data.count : data.rs;
                        });
                }
            };
        },
    ]);
