export default function () {
    class BusinessWorkflowSelection {
        constructor(resource) {
            this.resource = resource ? resource : '';
        }

        // Getter ---------------------------------

        get resource() {
            return this._resource;
        }

        // Setter ---------------------------------

        set resource(resource) {
            this._resource = resource ? resource : '';
        }

        // Method ---------------------------------

        hasResource() {
            return this._resource !== '';
        }

        isComplete() {
            return this.hasResource();
        }

        isEmpty() {
            return !this.hasResource();
        }

        copyProperties(other) {
            if (!other) return;
            this.resource = other.resource;
        }

        clear() {
            this.resource = '';
        }

        getSelectionSummaryStr() {
            if (this.isEmpty()) return '';

            return this.resource;
        }

        getId() {
            return this.resource;
        }

        toString() {
            return this.resource;
        }
    }

    return BusinessWorkflowSelection;
}
