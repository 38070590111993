'use strict';

/**
 * A pop-over list menu with a details pane and keyboard controls
 *
 * ```
 *  <fancy-menu
 *    fancy-menu-suggestions="selector.suggestions"
 *    fancy-menu-on-select="selector.onSelect"
 *    fancy-menu-highlighted="selector.highlighted"
 *    fancy-menu-visible="selector.visible"
 *  >
 *    <input
 *      type="text"
 *      ng-model="selector.query"
 *      fancy-menu-input
 *    >
 *    <fancy-menu-suggestions
 *      text-highlight="selector.query"
 *      hide-details="selector.hideDetails"
 *    >{{ selector.highlighted.displayName }}</fancy-menu-suggestions>
 *  </fancy-menu>
 * ```
 *
 * @param  {Array} fancy-menu-suggestions List of suggestions to display
 * @param  {Function} fancy-menu-on-select Function to call on select intent
 * @param  {Element} fancy-menu-input The input control for the menu
 * @param  {Element} fancy-menu-suggestions The element where the list will
 *                   be rendered
 * @param  {Object} [fancy-menu-highlighted] The current selected element
 * @param  {boolean} [fancy-menu-visible=false] Whether the menu is shown
 * @param  {Function} fancy-menu-on-show Function to call when menu is shown
 */
angular.module('sfx.ui').directive('fancyMenu', [
    function () {
        return {
            restrict: 'E',
            scope: {
                suggestions: '=fancyMenuSuggestions',
                onSelect: '=fancyMenuOnSelect',
                onClose: '=?fancyMenuOnClose',
                closeOnSelect: '=?fancyMenuCloseOnSelect',
                highlighted: '=?fancyMenuHighlighted',
                visible: '=?fancyMenuVisible',
                onShow: '=?fancyMenuOnShow',
            },
            link: function ($scope, element) {
                $scope.element = element;
            },
            controller: [
                '$scope',
                function ($scope) {
                    const menu = this;

                    menu.$scope = $scope;

                    // Controls whether the suggestions is visible or not
                    menu.onSelect = function (item) {
                        if ($scope.visible && $scope.onSelect) {
                            $scope.onSelect(item);
                            if ($scope.closeOnSelect) {
                                $scope.visible = false;
                            }
                        }
                    };

                    menu.show = function () {
                        $scope.visible = true;
                        if ($scope.onShow) {
                            $scope.onShow();
                        }
                    };

                    menu.hide = function () {
                        $scope.visible = false;
                        if ($scope.onClose) {
                            $scope.onClose();
                        }
                    };

                    // Container is used by the list-selector to determine highlighted item
                    $scope.$watch('element', function (element) {
                        menu.container = element;
                    });
                },
            ],
        };
    },
]);
