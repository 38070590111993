angular.module('signalview.detector.wizard').factory('DimensionList', [
    '_',
    function (_) {
        // functionally, DimensionList appears to serve as a way to override a map's toString for the
        // purpose of mustache rendering.  It also needs to be able to provide direct access to all
        // dimensions it contains, and so necessarily must have native keys on the top level object.

        function DimensionList() {}

        DimensionList.create = function (dimensions) {
            const dimensionList = new DimensionList();
            angular.forEach(dimensions, (val, key) => {
                dimensionList[key] = val;
            });

            return dimensionList;
        };

        DimensionList.prototype.toString = function () {
            const keyValue = _.map(this, (val, key) => {
                if (this.hasOwnProperty(key)) {
                    return `${key} : ${val}`;
                }
                return null;
            }).filter((s) => s !== null);
            return `{ ${keyValue.join(', ')} }`;
        };

        return DimensionList;
    },
]);
