import { timeUnitToMilliseconds } from '../../../legacy/common/ui/formatting/timeUnitToMillisecondsModule';

// one or more digits then zero or more spaces then one or more alphabetic characters
const friendlyDurationExp = /(\d+)\s*([a-zA-Z]+)/g;

/*
  Given a string such as "<number><unit>..." this will return the millisecond value
  the string represents. Ex. "2m 2s" would evaluate to 122000.

  The units are passed through the "timeUnitToMillisecond" module, so any unit specified
  there can be used. Units may be used more than once in any order and negative values are
  not valid.
*/
export function parseDuration(time) {
    if (typeof time !== 'string') return NaN;

    // commas are treated the same as spaces, so we normalize to spaces
    time = time.replace(/,/g, ' ');

    let isValid = false;
    let total = 0;
    let part = friendlyDurationExp.exec(time);
    let value, unit;

    // If the first part wasn't at the very beginning of the given
    // string, consider the value invalid (since it precedes with invalid
    // characters)
    if (part && friendlyDurationExp.lastIndex !== part[0].length) {
        return NaN;
    }

    while (part !== null) {
        value = part[1];
        unit = part[2];
        const timeUnitInMilliseconds = timeUnitToMilliseconds(unit);
        if (timeUnitInMilliseconds) {
            total += value * timeUnitInMilliseconds;
        } else {
            return NaN;
        }

        // Only if we parse all the way to the end is the value considered
        // valid
        if (friendlyDurationExp.lastIndex === time.length) {
            isValid = true;
        }
        part = friendlyDurationExp.exec(time);
    }

    if (isValid) return total;
    else return NaN;
}
