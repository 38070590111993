angular
    .module('signalview.page')

    .service('dashboardGroupCache', [
        '$rootScope',
        function ($rootScope) {
            function checkRouteAndCleanStaleCache(e, prev, curr) {
                if (!prev.match(/\/dashboard\//) && !curr.match(/\/dashboard\//)) {
                    clear();
                }
            }

            function clear() {
                tabCache = {};
                varCache = {};
            }

            $rootScope.$on('$locationChangeStart', checkRouteAndCleanStaleCache);
            let mostRecentGroup;
            let tabCache = {};
            let varCache = {};

            $rootScope.$on('deletedDashboard', function (_event, dashboardGroupId, dashboardId) {
                removeDashboardFromTabCache(dashboardGroupId, dashboardId);
            });

            function removeDashboardFromTabCache(dashboardGroupId, dashboardId) {
                const cachedTabs = tabCache[dashboardGroupId];
                if (cachedTabs) {
                    for (let i = 0; i < (cachedTabs || []).length; i++) {
                        if (cachedTabs[i].id === dashboardId) {
                            cachedTabs.splice(i, 1);
                            break;
                        }
                    }
                }
            }

            function setMostRecent(dashboardGroupId, dashboards) {
                mostRecentGroup = dashboardGroupId;
                tabCache[dashboardGroupId] = dashboards;
            }

            function getDashboardsIfCached(dashboardGroupId) {
                if (dashboardGroupId !== mostRecentGroup) {
                    clear();
                }
                return tabCache[dashboardGroupId];
            }

            function storeVariables(dashboardGroupId, cacheUpdates) {
                mostRecentGroup = dashboardGroupId;
                angular.extend(varCache, cacheUpdates);
            }

            function getVariables(dashboardGroupId) {
                if (!dashboardGroupId || dashboardGroupId !== mostRecentGroup) {
                    clear();
                }
                return varCache;
            }

            function clearVariables() {
                varCache = {};
            }

            function invalidateTabCache() {
                tabCache = {};
            }

            return {
                setMostRecent,
                getDashboardsIfCached,
                storeVariables,
                getVariables,
                clearVariables,
                removeDashboardFromTabCache,
                invalidateTabCache,
            };
        },
    ]);
