export function getEventChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Container Events',
        type: 'Event',
    };

    const filterString = filters ? `and (${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'Container Alerts',
            streamLabel: 'CONTAINER_ALERTS',
            programText: `CONTAINER_ALERTS = alerts(detector_name='*', filter=filter('kubernetes_cluster', '*') and filter('container_id', '*') ${filterString}).publish(label='CONTAINER_ALERTS')`,
        },
        {
            displayName: 'Container Events',
            streamLabel: 'CONTAINER_EVENTS',
            programText: `CONTAINER_EVENTS = events(eventType='*', filter=filter('kubernetes_cluster', '*') and filter('container_id', '*') ${filterString}).publish(label='CONTAINER_EVENTS')`,
        },
    ];

    return { chartOptions, publishLabelOptions };
}
