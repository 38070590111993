export default [
    'currentUser',
    'traceSuggestionService',
    'urlOverridesService',
    'DETECTOR_TYPES',
    function (currentUser, traceSuggestionService, urlOverridesService, DETECTOR_TYPES) {
        const { APM, APM_V2 } = DETECTOR_TYPES;
        const APM_VERSION_ENVIRONMENT_PROPERTY_MAP = {
            [APM]: 'sf_lastViewedEnvironment',
            [APM_V2]: 'sf_lastViewedAPM2Environment',
        };

        return {
            getEnvironmentInfo,
            getApm2EnvironmentInfo,
        };

        function getEnvironmentInfo(apmVersion = APM) {
            if (apmVersion === APM) {
                return getApm1EnvironmentInfo();
            } else if (apmVersion === APM_V2) {
                return getApm2EnvironmentInfo();
            }

            throw new Error('Invalid APM version.');
        }

        function getApm1EnvironmentInfo() {
            return traceSuggestionService.getEnvironments().then((environments) => {
                const envUrlOverride = urlOverridesService.getEnvironment();

                if (envUrlOverride && environments.includes(envUrlOverride)) {
                    markCurrentEnvironmentInPrefs(envUrlOverride, APM);
                    return { environments, current: envUrlOverride };
                }

                return getPreferredEnvironment(environments, APM).then((environment) => {
                    markCurrentEnvironmentInPrefs(environment, APM);
                    return { environments, current: environment };
                });
            });
        }

        async function getApm2EnvironmentInfo() {
            const environments = await traceSuggestionService.getEnvironments(APM_V2);
            const preferredEnv = await getPreferredEnvironment(environments, APM_V2);

            markCurrentEnvironmentInPrefs(preferredEnv, APM_V2);
            return { environments, current: preferredEnv };
        }

        // Updates last viewed environment in user preferences in case the resolved
        // environment does not match the current environment
        function markCurrentEnvironmentInPrefs(environment, apmVersion) {
            const environmentPropertyName = getEnvironmentPropertyName(apmVersion);

            return currentUser.orgPreferences().then((prefs) => {
                if (prefs[environmentPropertyName] === environment) {
                    return;
                }

                return currentUser.updateOrgPreferences({
                    sf_id: prefs.sf_id,
                    [environmentPropertyName]: environment,
                });
            });
        }

        async function getPreferredEnvironment(environments, apmVersion) {
            const orgPreferences = await currentUser.orgPreferences();

            const environmentPropertyName = getEnvironmentPropertyName(apmVersion);
            const savedPreferredEnv = orgPreferences[environmentPropertyName];

            const hasAvailableSavedPreferredEnv =
                !!savedPreferredEnv && environments.includes(savedPreferredEnv);
            return hasAvailableSavedPreferredEnv ? savedPreferredEnv : environments[0];
        }

        function getEnvironmentPropertyName(apmVersion) {
            const propertyName = APM_VERSION_ENVIRONMENT_PROPERTY_MAP[apmVersion];
            if (!propertyName) {
                throw new Error('Invalid APM version.');
            }

            return propertyName;
        }
    },
];
