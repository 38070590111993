import templateUrl from './xMattersIntegration.html';

angular
    .module('sfx.ui')

    .directive('sfxXmattersIntegration', [
        'notificationService',
        function (notificationService) {
            return {
                restrict: 'E',
                templateUrl,
                scope: true,
                require: '^sfxIntegration',
                link: function ($scope) {
                    $scope.lastModifiedTs = notificationService.formatNotificationEventTimestamp(
                        $scope.credential.lastUpdated
                    );

                    $scope.$on('reset', function reset() {
                        // update modified time as well
                        $scope.lastModifiedTs =
                            notificationService.formatNotificationEventTimestamp(
                                $scope.credential.lastUpdated
                            );
                    });
                },
            };
        },
    ]);
