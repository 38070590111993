angular.module('signalview.heatmap').service('metricsDataService', [
    '$rootScope',
    'signalStream',
    '$log',
    '_',
    function ($rootScope, signalStream, $log, _) {
        let idToMetadata = {};
        let primaryIdProperty;

        function create(idFunction) {
            if (!angular.isFunction(idFunction)) {
                throw new Error('An metadata -> id function is required');
            }

            const api = {};
            let tsidToMetadata = {},
                resolution = 1000,
                historyRange,
                stopTime,
                program = '',
                streamObject,
                running = false;

            const scope = $rootScope.$new();
            api.on = scope.$on.bind(scope);

            api.resolution = function (_) {
                if (!arguments.length) return _;

                if (!angular.isNumber(_)) {
                    throw new Error('Resolution must be a number.');
                }

                if (!angular.equals(resolution, _)) {
                    $log.debug('Resolution updated to', resolution);
                    resolution = _;

                    scope.$emit('resolution updated', resolution);

                    if (running) {
                        api.restart();
                    }
                }

                return this;
            };

            api.historyRange = function (_) {
                if (!arguments.length) return _;

                if (!angular.isNumber(_)) {
                    throw new Error('History range must be a number.');
                }

                if (!angular.equals(historyRange, _)) {
                    $log.debug('History range updated to ', historyRange);
                    historyRange = _;

                    scope.$emit('history range updated', historyRange);

                    if (running) {
                        api.restart();
                    }
                }

                return this;
            };

            api.stopTime = function (_) {
                if (!arguments.length) return _;

                if (!angular.isNumber(_)) {
                    throw new Error('Stop time must be a number.');
                }

                if (!angular.equals(stopTime, _)) {
                    $log.debug('Stop time updated to ', stopTime);
                    stopTime = _;

                    scope.$emit('stop time updated', stopTime);

                    if (running) {
                        api.restart();
                    }
                }

                return this;
            };

            api.program = function (_) {
                if (!arguments.length) return _;

                if (!angular.isString(_)) {
                    throw new Error('Program must be a string.');
                }

                if (!angular.equals(program, _)) {
                    program = _;
                    $log.debug('Program updated to', program);
                    scope.$emit('program updated', program);

                    if (running) {
                        api.restart();
                    }
                }

                return this;
            };

            function streamStartHandler() {
                $log.debug('Metrics data stream has started');
                running = true;
                scope.$emit('stream start');
            }

            function metadataHandler(metadata, tsid) {
                if (!running) return;
                if (tsid in tsidToMetadata) {
                    $log.debug('Updating metadata for existing tsid ' + tsid);
                }

                tsidToMetadata[tsid] = metadata;

                // Join metadata for metrics coming from the same host but with
                // different properties
                const id = idFunction(metadata);
                if (idToMetadata[id]) {
                    // Make sure we retain sf_key values that includes the primary
                    // identifying property
                    // FIXME(jwy): Metadata associated with the other identifying
                    // properties is lost, which limits discovery of all dashboards
                    // related to this host
                    let keys;
                    if (
                        primaryIdProperty &&
                        idToMetadata[id].sf_key.indexOf(primaryIdProperty) !== -1
                    ) {
                        keys = idToMetadata[id].sf_key;
                    }
                    angular.extend(idToMetadata[id], metadata);
                    if (keys) {
                        idToMetadata[id].sf_key = keys;
                    }
                } else {
                    // Make a copy since metadata object is also used by tsidToMetadata
                    idToMetadata[id] = Object.assign({}, metadata);
                }
                scope.$emit('metadata updated');
            }

            function dataHandler(tsidToData) {
                if (!running) return;

                const data = {};

                Object.keys(tsidToData).forEach(function (tsid) {
                    const metadata = tsidToMetadata[tsid];
                    const id = idFunction(metadata);
                    const datapoint = tsidToData[tsid];
                    const latestValue = datapoint.value;

                    if (!data[id]) {
                        data[id] = {};
                    }

                    data[id][metadata.sf_streamLabel] = latestValue;
                });

                scope.$emit('data', data);
            }

            function stop() {
                if (!running) return false;
                running = false;

                streamObject.stopStream();

                tsidToMetadata = {};
                idToMetadata = {};

                return true;
            }

            function restart() {
                if (!program) {
                    throw new Error('No program defined.');
                }

                $log.info('Restarting metrics data service');

                // Stop existing stream
                stop();

                tsidToMetadata = {};
                idToMetadata = {};
                streamObject = signalStream.stream({
                    resolution: resolution,
                    historyrange: historyRange || -1 * (resolution * 2),
                    stopTime: stopTime,
                    signalFlowText: program,
                    bulk: true,
                    ephemeral: true,
                    offsetByMaxDelay: true,
                    resolutionAdjustable: false,
                    withDerivedMetadata: false,
                    streamStartCallback: streamStartHandler,
                    metaDataUpdated: metadataHandler,
                    callback: dataHandler,
                });
            }

            api.restart = _.debounce(restart, 300);
            api.start = api.restart;
            api.stop = stop;

            return api;
        }

        function setPrimaryIdProperty(property) {
            primaryIdProperty = property;
        }

        function getMetadataById(id) {
            return idToMetadata[id];
        }

        return {
            create: create,
            setPrimaryIdProperty: setPrimaryIdProperty,
            getMetadataById: getMetadataById,
        };
    },
]);
