import templateUrl from './azureIntegration.tpl.html';
import integrationServicesTemplateUrl from './integrationServices.html';

import { SfxIntegrationAzureFilterConverter } from './azure/filterConverter.js';

angular.module('sfx.ui').component('sfxAzureIntegration', {
    templateUrl,
    bindings: {
        credential: '<',
        editable: '<',
        integrationForm: '<',
        sharedCredentialState: '<',
        obfuscatedText: '<',
    },
    controller: [
        '$scope',
        // declare-used-dependency-to-linter::sfxModal
        'sfxModal',
        '$log',
        // declare-used-dependency-to-linter::azureService
        'azureService',
        // declare-used-dependency-to-linter::displayNamesWithMore
        'displayNamesWithMore',
        SfxIntegrationAzureFilterConverter.DI,
        function (
            $scope,
            sfxModal,
            $log,
            azureService,
            displayNamesWithMore,
            sfxIntegrationAzureFilterConverter
        ) {
            const ctrl = this;
            ctrl.$onChanges = reset;
            ctrl.editAzureServices = editAzureServices;
            ctrl.editAzureSubscriptions = editAzureSubscriptions;
            ctrl.services = [];
            ctrl.subscriptions = [];

            ctrl.additionalServices = [];
            ctrl.onAdditionalServicesChange = (values) => (ctrl.additionalServices = values);
            ctrl.onAdditionalServicesSanitizedChange = (values) =>
                (ctrl.credential.additionalServices = values);

            const supportedAzureServicesPromise = azureService
                .getAvailableServices()
                .then(function (result) {
                    return result.data;
                })
                .catch(function () {
                    $log.error('Failed trying to fetch supported Azure services');
                });
            const supportedAzureEnvironmentsPromise = azureService
                .getSupportedEnvironments()
                .catch(function () {
                    $log.error('Failed trying to fetch supported Azure environment');
                });

            let azureSubscriptionPromise;
            ctrl.$onInit = function () {
                reset();
            };
            $scope.$on('reset', reset);

            this.getFilterRulesSignalflow = () =>
                sfxIntegrationAzureFilterConverter.resourceFilterRulesToSignalflow(
                    ctrl.credential.resourceFilterRules
                );

            this.setFilterRulesSignalflow = (signalflow) => {
                this.credential.resourceFilterRules =
                    sfxIntegrationAzureFilterConverter.signalflowToResourceFilterRules(signalflow);
                this.integrationForm.$setDirty(true);
            };

            function editAzureServices() {
                const modal = sfxModal.open({
                    templateUrl: integrationServicesTemplateUrl,
                    controller: 'integrationServices',
                    resolve: {
                        selected: function () {
                            return ctrl.services;
                        },
                        editable: function () {
                            return ctrl.editable;
                        },
                        serviceToName: supportedAzureServicesPromise.then((result) => {
                            return result;
                        }),
                        params: function () {
                            return {
                                modalTitle: 'Select Service Metrics to Import',
                                syncAllOptionText: 'Sync all supported services',
                                syncSelectedText: 'Sync only selected services',
                                syncAllHelpText:
                                    'Imports metrics from all supported services, updating as new services are added',
                            };
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                });

                modal.result.then((services) => {
                    ctrl.services = services;
                });
            }

            function editAzureSubscriptions() {
                if (!ctrl.credential.appId && !ctrl.credential.secretKey) {
                    azureSubscriptionPromise = azureService.getListOfSubscriptionsWithId(
                        ctrl.credential.id,
                        ctrl.credential.azureEnvironment
                    );
                } else {
                    azureSubscriptionPromise = azureService.getListOfSubscriptionsWithoutId(
                        ctrl.credential.tenantId,
                        ctrl.credential.appId,
                        ctrl.credential.secretKey,
                        ctrl.credential.azureEnvironment
                    );
                }
                ctrl.state = 'Fetching Subscriptions from Azure';
                const modal = sfxModal.open({
                    templateUrl: integrationServicesTemplateUrl,
                    controller: 'integrationServices',
                    resolve: {
                        selected: function () {
                            return ctrl.subscriptions;
                        },
                        editable: function () {
                            return ctrl.editable;
                        },
                        serviceToName: azureSubscriptionPromise
                            .then((result) => {
                                ctrl.state = '';
                                return result.data;
                            })
                            .catch((e) => {
                                ctrl.error = e.data.message;
                                ctrl.state = '';
                            }),
                        params: function () {
                            return {
                                modalTitle: 'Select Subscriptions to Import',
                                syncAllOptionText: '',
                                syncSelectedText: 'Sync only selected subscriptions',
                                syncAllHelpText: '',
                                disableSyncAll: true,
                            };
                        },
                    },
                    backdrop: 'static',
                    keyboard: false,
                });

                modal.result.then((subscriptions) => {
                    ctrl.subscriptions = subscriptions;
                });
            }

            function reset() {
                ctrl.credential.services = ctrl.credential.services || [];
                ctrl.credential.pollRate = ctrl.credential.pollRate || 60000;
                ctrl.error = '';
                ctrl.services = ctrl.credential.services.map((service) => {
                    return {
                        code: service,
                    };
                });
                supportedAzureServicesPromise.then((result) => {
                    ctrl.services = ctrl.credential.services.map((service) => {
                        return {
                            code: service,
                            name: result[service],
                        };
                    });
                });
                ctrl.credential.subscriptions = ctrl.credential.subscriptions || [];
                ctrl.subscriptions = ctrl.credential.subscriptions.map((subscription) => {
                    return {
                        code: subscription,
                    };
                });
                // If an existing credential does not have azureEnvironment set, give a default value to show correct form content
                // for a new integration that's being added, it won't have any value so the user needs to pick one
                // and move on to the rest of the form.
                if (!ctrl.credential.azureEnvironment && ctrl.credential.enabled) {
                    ctrl.credential.azureEnvironment = azureService.DEFAULT_ENVIRONMENT;
                }
                supportedAzureEnvironmentsPromise.then(function (allEnvironments) {
                    ctrl.azureEnvironments = allEnvironments;
                    ctrl.azureEnvironment = {
                        code: ctrl.credential.azureEnvironment,
                        name: allEnvironments[ctrl.credential.azureEnvironment],
                    };
                });

                if (
                    ctrl.credential.id ||
                    (ctrl.credential.tenantId && ctrl.credential.appId && ctrl.credential.secretKey)
                ) {
                    if (ctrl.credential.id) {
                        azureSubscriptionPromise = azureService.getListOfSubscriptionsWithId(
                            ctrl.credential.id,
                            ctrl.credential.azureEnvironment
                        );
                    } else {
                        azureSubscriptionPromise = azureService.getListOfSubscriptionsWithoutId(
                            ctrl.credential.tenantId,
                            ctrl.credential.appId,
                            ctrl.credential.secretKey,
                            ctrl.credential.azureEnvironment
                        );
                    }
                    ctrl.state = 'Fetching Subscriptions from Azure';
                    azureSubscriptionPromise.then((result) => {
                        ctrl.subscriptions = ctrl.credential.subscriptions.map((subscription) => {
                            ctrl.state = '';
                            return {
                                code: subscription,
                                name: result.data[subscription],
                            };
                        });
                    });
                }

                if (
                    ctrl.credential.importAzureMonitor === undefined ||
                    ctrl.credential.importAzureMonitor === null
                ) {
                    ctrl.credential.importAzureMonitor = true;
                }

                if (!ctrl.credential.type) {
                    ctrl.credential.type = 'Azure';
                }

                ctrl.additionalServices = ctrl.credential.additionalServices || [];

                const credentialsPresent = ctrl.credential.appId && ctrl.credential.secretKey;
                ctrl.sharedCredentialState.updateCredentials = credentialsPresent;
                ctrl.integrationForm.$setPristine(true);
            }

            $scope.$watchGroup(
                [
                    '$ctrl.credential.appId',
                    '$ctrl.credential.secretKey',
                    '$ctrl.credential.tenantId',
                ],
                () => {
                    ctrl.error = '';
                    if (ctrl.credential.appId && ctrl.credential.secretKey) {
                        ctrl.sharedCredentialState.updateCredentials = true;
                    } else {
                        ctrl.sharedCredentialState.updateCredentials = false;
                    }
                }
            );

            $scope.$watch('$ctrl.credential.pollRate', function (newVal, oldVal) {
                if (!oldVal || newVal === oldVal) {
                    return;
                }
                ctrl.integrationForm.$setDirty(true);
            });

            $scope.$watchCollection('$ctrl.services', (newValue) => {
                if (newValue === undefined) {
                    return;
                }

                const newCodes = newValue.map((service) => {
                    return service.code;
                });

                const oldCodes = ctrl.credential.services || [];
                if (!angular.equals(oldCodes, newCodes)) {
                    ctrl.credential.services = newCodes;

                    ctrl.integrationForm.$setDirty(true);
                }

                if (newValue.length === 0) {
                    $scope.azureServicesText = 'All services';
                } else {
                    $scope.azureServicesText = displayNamesWithMore.shortenNames(newValue);
                }
            });

            $scope.$watchCollection('$ctrl.subscriptions', (newValue) => {
                if (newValue === undefined) {
                    return;
                }

                const newCodes = newValue.map((subscription) => {
                    return subscription.code;
                });

                const oldCodes = ctrl.credential.subscriptions || [];
                if (!angular.equals(oldCodes, newCodes)) {
                    ctrl.credential.subscriptions = newCodes;

                    ctrl.integrationForm.$setDirty(true);
                }

                if (newValue.length === 0) {
                    $scope.azureSubscriptionText = 'Select Subscriptions';
                } else {
                    $scope.azureSubscriptionText = displayNamesWithMore.shortenNames(newValue);
                }
            });

            $scope.$watch('$ctrl.azureEnvironment.code', function (newCode) {
                if (newCode === undefined) return;
                const oldCode = ctrl.credential.azureEnvironment;

                if (!angular.equals(oldCode, newCode)) {
                    ctrl.credential.azureEnvironment = newCode;
                    // Invalidate subscriptions associated with the old environment
                    ctrl.subscriptions = [];

                    ctrl.integrationForm.$setDirty(true);
                }
            });
        },
    ],
});
