import { addCommonHeaders } from './createV2Service.js';

angular.module('sfx.api.v2').service('mappingService', [
    'createV2Service',
    'currentUser',
    '$http',
    '$q',
    function (createV2Service, currentUser, $http, $q) {
        const mappingService = createV2Service('mapping');

        const PROPERTY_MAPPING_PATH = '/_/propertyAliases';
        const BULK_PROPERTY_MAPPING_PATH = '/_/bulk/propertyAliases';
        const METRIC_MAPPING_PATH = '/_/metricAliases';

        const mappingServicePropertyCache = {};
        const mappingServiceMetricCache = {};

        mappingService.create = () => null;
        mappingService.update = () => null;
        mappingService.get = () => null;
        mappingService.delete = () => null;
        mappingService.search = () => null;

        function userFetchAndResponseParseWrapper(requestExecutor) {
            return currentUser
                .orgId()
                .then((organizationId) => {
                    return requestExecutor(organizationId);
                })
                .then((response) => response.data);
        }

        function getAliasSearchExecutor(subpath, name) {
            return (organizationId) => {
                return $http.get(
                    mappingService.resourceEndpoint + subpath,
                    addCommonHeaders({
                        params: {
                            organizationId,
                            name,
                        },
                    })
                );
            };
        }

        function getAliasBulkSearchExecutor(propertyNames) {
            return (organizationId) => {
                return $http.post(
                    mappingService.resourceEndpoint + BULK_PROPERTY_MAPPING_PATH,
                    propertyNames,
                    addCommonHeaders({
                        params: {
                            organizationId,
                        },
                    })
                );
            };
        }

        mappingService.getPropertyAliases = function (propertyName) {
            if (mappingServicePropertyCache[propertyName]) {
                return $q.when(angular.copy(mappingServicePropertyCache[propertyName]));
            }
            return userFetchAndResponseParseWrapper(
                getAliasSearchExecutor(PROPERTY_MAPPING_PATH, propertyName)
            ).then(function (resp) {
                mappingServicePropertyCache[propertyName] = angular.copy(resp);
                return resp;
            });
        };

        mappingService.getMetricAliases = function (metricName) {
            if (mappingServiceMetricCache[metricName]) {
                return $q.when(angular.copy(mappingServiceMetricCache[metricName]));
            }
            return userFetchAndResponseParseWrapper(
                getAliasSearchExecutor(METRIC_MAPPING_PATH, metricName)
            ).then(function (resp) {
                mappingServiceMetricCache[metricName] = angular.copy(resp);
                return resp;
            });
        };

        mappingService.getPropertyAliasesBulk = function (propertyNames) {
            // not the smartest cache, but good enough for now
            const foundUnknown = propertyNames.some((prop) => !mappingServicePropertyCache[prop]);

            // full cache hit on bulk call
            if (!foundUnknown) {
                const result = {};
                propertyNames.forEach(function (prop) {
                    result[prop] = angular.copy(mappingServicePropertyCache[prop]);
                });
                return $q.when(result);
            }

            // else get them all
            return userFetchAndResponseParseWrapper(getAliasBulkSearchExecutor(propertyNames)).then(
                function (resp) {
                    Object.keys(resp).forEach(function (prop) {
                        mappingServicePropertyCache[prop] = resp[prop];
                    });
                    return resp;
                }
            );
        };

        return mappingService;
    },
]);
