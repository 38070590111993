export type CategoryLimit = {
    name: string;
    displayName: string;
    docUrlKey: string;
    resourceType?: string;
    value: number;
    plans: string[];
    tokenMetric: string;
    orgUsageMetric: string;
    limitMetric: string;
};

export const CategoryLimits: CategoryLimit[] = [
    {
        name: 'Hosts',
        displayName: 'Host Count',
        docUrlKey: 'host',
        resourceType: 'host',
        value: 1,
        plans: ['Hosts'],
        tokenMetric: 'sf.org.numResourcesMonitoredByToken',
        orgUsageMetric: 'sf.org.numResourcesMonitored',
        limitMetric: 'sf.org.subscription.hosts',
    },
    {
        name: 'Containers',
        displayName: 'Container Count',
        docUrlKey: 'container',
        resourceType: 'container',
        value: 2,
        plans: ['Hosts'],
        tokenMetric: 'sf.org.numResourcesMonitoredByToken',
        orgUsageMetric: 'sf.org.numResourcesMonitored',
        limitMetric: 'sf.org.subscription.containers',
    },
    {
        name: 'Custom Metrics',
        displayName: 'Custom Metrics',
        docUrlKey: 'custom',
        value: 3,
        plans: ['Hosts', 'MTS'],
        tokenMetric: 'sf.org.numCustomMetricsByToken',
        orgUsageMetric: 'sf.org.numCustomMetrics',
        limitMetric: 'sf.org.subscription.customMetrics',
    },
];
