angular.module('signalview.heatmap').service('calculateOverrepresentation', [
    function () {
        function updateKVCounts(destination, key, value) {
            let values = destination[key];
            if (!values) {
                values = destination[key] = {};
            }

            if (value in values) {
                values[value]++;
            } else {
                values[value] = 1;
            }
        }

        function oneTailedSum(k, N, K, n) {
            let p = 0.0;
            let j = k;

            while (j <= Math.min(K, n)) {
                p += hypergeometricPMF(
                    j, // sample matches
                    {
                        n: N - n, // total matching
                        m: n, // total non-matching
                        k: K, // sample size
                    }
                );

                j++;
            }

            return p;
        }

        /**
         * @author Joe Ross
         * @param  {list of objects} data  each element in the list
         *                  corresponds a host (the unit of analysis), and the
         *                  dictionary contains the metadata (dimension-value
         *                  pairs) and metric value. ASSUMPTION: all dictionaries
         *                  have the same keys
         * @param  {list of strings} properties list of properties to consider from
         *                  the objects in the list
         * @param  {Function} test   the function which returns a boolean result for
         *                           a given host determining whether it's in the group
         *                           to include in the prediction calculation
         * @param  {String} metricName      key corresponding to the metric
         * @return {list of objects}  a list of dimension values, sorted by how
         *                  over-represented they are in the part of the
         *                  population with "high" metric values, together with
         *                  the values by which they are sorted (namely, the
         *                  hypergeometric p-value)
         */
        function calculate(data, properties, test) {
            const overallCounts = {};
            const highCounts = {};
            const totalPopulation = data.length;
            let totalHighPopulation = 0;

            data.forEach(function (datum) {
                if (test(datum)) {
                    totalHighPopulation++;

                    properties.forEach(function (key) {
                        updateKVCounts(overallCounts, key, datum[key]);
                        updateKVCounts(highCounts, key, datum[key]);
                    });
                } else {
                    properties.forEach(function (key) {
                        updateKVCounts(overallCounts, key, datum[key]);
                    });
                }
            });

            const scoredMetadata = Object.keys(highCounts).reduce(function (acc, key) {
                const values = highCounts[key];
                return acc.concat(
                    Object.keys(values).map(function (value) {
                        const sum = oneTailedSum(
                            values[value],
                            totalPopulation,
                            overallCounts[key][value],
                            totalHighPopulation
                        );

                        return [[key, value], sum];
                    })
                );
            }, []);

            scoredMetadata.sort(function (a, b) {
                return a[1] - b[1];
            });

            return scoredMetadata;
        }

        return calculate;
    },
]);
