import { sanitizeTermUsingSpaces } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './groupableHeatmap.tpl.html';

angular.module('signalview.heatmap').directive('groupableHeatmap', [
    function () {
        return {
            restrict: 'E',
            scope: {
                loading: '=',
                heatmap: '=',
            },
            templateUrl,
            controllerAs: 'groupableHeatmap',
            controller: [
                '$scope',
                'relatedDetectorService',
                'sidebarService',
                'currentUser',
                'NAVIGATOR_HEATMAP_NO_DATA',
                function (
                    $scope,
                    relatedDetectorService,
                    sidebarService,
                    currentUser,
                    NAVIGATOR_HEATMAP_NO_DATA
                ) {
                    $scope.sidebarService = sidebarService;
                    $scope.tooltipMsgOnNoData = NAVIGATOR_HEATMAP_NO_DATA;
                    const heatmap = (this.heatmap = $scope.heatmap);
                    $scope.chart = {
                        alert: null,
                    };

                    currentUser.orgId().then((orgId) => {
                        $scope.orgId = orgId;
                    });

                    let dataGeneration = 0;
                    const eventBindings = [];
                    eventBindings.push(
                        heatmap.on('selection updated', function () {
                            $scope.heatmap.detectorMenuOpen = false;
                            $scope.initAlert();
                        })
                    );

                    function getDetectorFilters(node) {
                        const filters = [];
                        if (node._isUnit) {
                            //is an actual host
                            filters.push('host:"' + node.host + '"');
                        } else {
                            while (node.depth !== 0 && node.parent) {
                                //not thrilled with the double quotes, but this is the "api"
                                filters.push(node.groupKey + ':"' + node.name + '"');
                                node = node.parent;
                            }
                        }

                        // because we walked child->parent, reverse this so its in parent->child order as shown in heatmap
                        return filters.reverse();
                    }

                    $scope.getTop = function () {
                        const selectionPosition = $scope.heatmap.selectionPosition;
                        if (selectionPosition && selectionPosition.bottom) {
                            return selectionPosition.bottom;
                        } else {
                            return 0;
                        }
                    };

                    $scope.initAlert = function () {
                        dataGeneration++;
                        const expectedDataGeneration = dataGeneration;
                        const colorBy = heatmap.colorBy();
                        if (!heatmap.selection() || (colorBy && colorBy.mode === 'alerts')) {
                            $scope.chart = null;
                            return;
                        }

                        let filters = [];

                        if (heatmap.filterBy()) {
                            filters = heatmap.filterBy().map(function (f) {
                                return f.property + ':"' + f.propertyValue + '"';
                            });
                        }

                        const groupFilters = getDetectorFilters(heatmap.selection());

                        filters = filters.concat(groupFilters);
                        relatedDetectorService
                            .getDetectorsForContext(colorBy.metricSelectors, filters, function () {
                                return colorBy.metricSelectors
                                    .map(function (metric) {
                                        return 'sf_metric:' + sanitizeTermUsingSpaces(metric);
                                    })
                                    .join(' OR ');
                            })
                            .then(function (res) {
                                if (expectedDataGeneration === dataGeneration) {
                                    $scope.chart = {
                                        alert: res,
                                    };
                                }
                            });
                    };

                    $scope.labelMarkup = function () {
                        // declare-used-dependency-to-linter::heatmapElementLabel
                        return '<heatmap-element-label data="d" heatmap="heatmap"></heatmap-element-label>';
                    };

                    $scope.$on('$destroy', function () {
                        eventBindings.forEach(function (unbind) {
                            unbind();
                        });
                    });
                },
            ],
        };
    },
]);
