angular.module('signalview.typeahead').factory('filterFactory', [
    '$log',
    function ($log) {
        const FILTER_TYPES = {
            PROPERTY: 1,
            VALUE: 2,
            TAG: 3,
            SYNTHETIC: 4,
        };

        function FilterFactory(mode, v, isNot, propertyName) {
            if (propertyName.indexOf(':') !== -1) {
                throw new Error(
                    'Property names may not contain colons!  Likely an invalid parse invocation!'
                );
            }
            isNot = !!isNot;
            if (mode === FILTER_TYPES.PROPERTY) {
                return {
                    iconClass: 'icon-properties',
                    type: 'property',
                    property: v,
                    value: v,
                    NOT: isNot,
                };
            } else if (mode === FILTER_TYPES.VALUE) {
                return {
                    iconClass: 'icon-properties',
                    type: 'property',
                    property: propertyName,
                    propertyValue: v,
                    value: v,
                    NOT: isNot,
                };
            } else if (mode === FILTER_TYPES.SYNTHETIC) {
                return {
                    iconClass: 'icon-properties',
                    type: 'property',
                    property: propertyName,
                    propertyValue: v,
                    value: v,
                    NOT: isNot,
                    isSynthetic: true,
                };
            } else if (mode === FILTER_TYPES.TAG) {
                return {
                    iconClass: 'icon-tag',
                    type: 'tag',
                    property: 'sf_tags',
                    propertyValue: v,
                    value: v,
                    NOT: isNot,
                };
            } else {
                $log.error(
                    'A call to convert results into filters was made with an invalid mode! ' + mode
                );
            }
        }

        return {
            FILTER: FilterFactory,
            TYPES: FILTER_TYPES,
        };
    },
]);
