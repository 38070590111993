import { react2angular } from 'react2angular';
import detectorSignalflowEditor from './detectorSignalflowEditor.js';
import detailedDetectorView from './detailedDetectorView.js';
import detectorV2New from './detectorV2New';
import detectorV2Router from './detectorV2Router';
import UnsupportedDetector from './UnsupportedDetector';

angular
    .module('signalview.detectorV2', [
        'signalflowv2',
        'signalview.detector',
        'signalview.detector.rule',
        'signalflowV2ApiUtils.v2Detector',
    ])
    .component('detectorSignalflowEditor', detectorSignalflowEditor)
    .component('detailedDetectorView', detailedDetectorView)
    .component('detectorV2New', detectorV2New)
    .component('detectorV2Router', detectorV2Router)
    .component('unsupportedDetector', react2angular(UnsupportedDetector));
