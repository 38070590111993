angular
    .module('signalview.orgIntegrations')

    .service('integrationNameMap', [
        'integrationsMeta',
        function (integrationsMeta) {
            const map = {};
            const collectdPattern = /collectd-/;
            const customPattern = /[win-|aws-]/;
            Object.keys(integrationsMeta).forEach(function (integrationName) {
                if (integrationName.match(collectdPattern)) {
                    map[integrationName.replace(collectdPattern, '')] = integrationName;
                } else if (integrationName.match(customPattern)) {
                    map[integrationName.replace(customPattern, '')] = integrationName;
                }
            });

            return map;
        },
    ]);
