import templateUrl from './colorPicker.tpl.html';

angular
    .module('signalview.colorPicker', [])

    .component('colorPicker', {
        templateUrl,
        bindings: {
            currentColor: '=',
            vertical: '<?',
            radioMode: '<?',
            showGradient: '<?',
            numGradients: '<?',
            availableColors: '<?',
        },
        controller: [
            '$scope',
            'colorAccessibilityService',
            'colorService',
            function ($scope, colorAccessibilityService, colorService) {
                const ctrl = this;

                ctrl.$onInit = $onInit;
                ctrl.colorClicked = colorClicked;

                function colorClicked(color) {
                    if (ctrl.colorFromIndex) {
                        // since we are saving plot overrides as hex codes, we always use a
                        // hexcode in the default palette, determined by idx of the selected color
                        setCurrentColorIndex(color);
                        if (colorAccessibilityService.get().isPlotColor(color)) {
                            color = colorAccessibilityService
                                .get()
                                .convertPlotColorToDefault(color);
                        } else if (colorAccessibilityService.get().isHeatmapColor(color)) {
                            color = colorAccessibilityService
                                .get()
                                .convertHeatmapColorToDefault(color);
                        }

                        if (ctrl.currentColor === color && !ctrl.radioMode) {
                            ctrl.currentColor = null;
                            ctrl.currentColorIndex = null;
                        } else {
                            ctrl.currentColor = color;
                        }
                    } else {
                        if (ctrl.currentColor === color && !ctrl.radioMode) {
                            ctrl.currentColor = null;
                        } else {
                            ctrl.currentColor = color;
                        }
                    }
                }

                function $onInit() {
                    setColors();
                    setCurrentColor();
                    if (ctrl.showGradient) {
                        $scope.$watch('$ctrl.numGradients', setGradients);
                    }
                }

                function setGradients() {
                    let numShades = ctrl.numGradients || 5;
                    numShades = Math.floor(numShades / 2);
                    ctrl.availableColors = colorAccessibilityService
                        .get()
                        .getHeatmapChartColors()
                        .map(function (color) {
                            const shades = colorService.getShades(color, numShades);
                            return {
                                low: shades[0],
                                mid: color,
                                high: shades[shades.length - 1],
                            };
                        });
                }

                function setColors() {
                    if (ctrl.showGradient) {
                        setGradients();
                    } else {
                        ctrl.colorFromIndex = true;
                        $scope.$watch('$ctrl.currentColor', function () {
                            setCurrentColorIndex(ctrl.currentColor);
                        });
                        if (!ctrl.availableColors) {
                            const colors = colorAccessibilityService.get().getPlotColors();
                            ctrl.availableColors = angular.copy(colors);
                        }
                    }
                }

                function setCurrentColorIndex(currentColor) {
                    let color = currentColor;
                    if (colorAccessibilityService.get().isDefaultColor(color)) {
                        color = colorAccessibilityService.get().convertPlotColorToAccessible(color);
                    } else if (colorAccessibilityService.get().isDefaultHeatmapColor(color)) {
                        color = colorAccessibilityService
                            .get()
                            .convertHeatmapColorToAccessible(color);
                    }
                    ctrl.currentColorIndex = ctrl.availableColors.indexOf(color);
                }

                function setCurrentColor() {
                    if (ctrl.currentColor) {
                        // currentColor should always be from the default palette
                        setCurrentColorIndex(ctrl.currentColor);

                        if (!ctrl.showGradient && ctrl.currentColorIndex === -1) {
                            ctrl.availableColors.push($scope.currentColor);
                        } else if (
                            ctrl.showGradient &&
                            colorAccessibilityService.get().isDefaultColor(ctrl.currentColor)
                        ) {
                            const numShades = Math.floor((ctrl.numGradients || 5) / 2);
                            const shades = colorService.getShades(ctrl.currentColor, numShades);
                            ctrl.availableColors.push({
                                low: shades[0],
                                mid: ctrl.currentColor,
                                high: shades[shades.length - 1],
                            });
                        }
                    }
                }
            },
        ],
    });
