import templateUrl from './editablePlotFilter.tpl.html';

angular.module('signalview.chartbuilder').directive('editablePlotFilter', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            replace: false,
            controller: 'EditableSourceFilterController',
            templateUrl,
            scope: {
                sourceFilters: '=',
                showFilterOptions: '=?',
                filterIndex: '=',
                activeVariable: '=?',
                getCurrentQuery: '=',
                matchingDimensions: '=?',
                dimensionKeys: '=?',
                matchingSourceSize: '=?',
                objectTypes: '=?',
                sourceSuggestions: '=?',
                getCurrentTimeRange: '<?',
            },
            link: function ($scope, elem) {
                $scope.focus = function () {
                    // timeout is required for digests to have occurred
                    // and created an input dom element
                    $timeout(function () {
                        const value = $scope.editingInPlace.filter;
                        let rawSource = '';
                        if ($scope.editingInPlace.original) {
                            rawSource = $scope.editingInPlace.original.propertyValue;
                        }
                        const elt = elem.find('.sfx-input')[0];
                        elt.focus();
                        const isValueArray = angular.isArray(rawSource);
                        if (!isValueArray) {
                            elt.setSelectionRange(value.indexOf(':') + 1, value.length);
                        }
                    }, 0);
                };
            },
        };
    },
]);
