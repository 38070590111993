import { SNAPSHOT_TYPE } from '../../../common/data/apiv2/getOrgScopeErrorHandler';

angular
    .module('signalview.snapshot')

    .service('snapshotLoadService', [
        '$location',
        '$q',
        'getOrgScopeErrorHandler',
        'shareableSnapshotService',
        function ($location, $q, getOrgScopeErrorHandler, shareableSnapshotService) {
            return function getSnapshot(snapshotId) {
                if (!snapshotId) {
                    $location.path('/mypage');
                    return $q.when();
                }
                return shareableSnapshotService.get(
                    snapshotId,
                    getOrgScopeErrorHandler(SNAPSHOT_TYPE)
                );
            };
        },
    ]);
