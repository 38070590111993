angular
    .module('signalview.catalog')
    .constant('catalog.pluginDocumentation', window.pluginDocumentation || {})
    .constant('catalog.metricDocumentation', window.metricDocumentation || {})
    .service('catalogMetricDocumentation', [
        'catalog.metricDocumentation',
        '$q',
        function (docs, $q) {
            function getMetricDocumentation(key) {
                return $q.when(docs[key] || null);
            }

            return {
                getMetricDocumentation: getMetricDocumentation,
            };
        },
    ])
    .service('catalogPluginDocumentation', [
        'catalog.pluginDocumentation',
        '$q',
        function (docs, $q) {
            function getPluginDocumentation(key) {
                return $q.when(docs[key] || null);
            }

            return {
                getPluginDocumentation: getPluginDocumentation,
            };
        },
    ]);
