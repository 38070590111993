import templateUrl from '../../../../../app/charting/chartdisplay/sparkline/sparkline.tpl.html';
angular.module('signalview.sparkline', []).directive('sparkline', [
    'CHART_DISPLAY_EVENTS',
    function (CHART_DISPLAY_EVENTS) {
        //the sparkline directive is used to manage two parameters, colors and datapoints.
        //it must clean up after itself on destroy in the event it is used in a top/bottom scenario
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                datapoints: '=',
                color: '=',
                dynamicSize: '@?',
                thickness: '@?',
            },
            controller: [
                '$scope',
                '$window',
                function ($scope, $window) {
                    let resizeTimer = null;

                    function delayedResize(amount) {
                        $window.clearTimeout(resizeTimer);
                        resizeTimer = $window.setTimeout(() => {
                            if ($scope.dyGraphInstance) {
                                $scope.dyGraphInstance.resize();
                            }
                        }, amount || 0);
                    }

                    $scope.$watch(
                        'datapoints',
                        function () {
                            if ($scope.dyGraphInstance) {
                                if ($scope.dynamicSize) {
                                    $scope.dyGraphInstance.resize();
                                }
                                $scope.dyGraphInstance.updateOptions({
                                    file: $scope.datapoints,
                                });
                            } else if ($scope.datapoints && $scope.datapoints.length) {
                                $scope.dyGraphInstance = new Dygraph(
                                    $scope.dyGraphTargetElement,
                                    $scope.datapoints,
                                    {
                                        axes: {
                                            x: {
                                                drawAxis: false,
                                            },
                                            y: {
                                                drawAxis: false,
                                            },
                                        },
                                        strokeWidth: [$scope.thickness * 1 || 1],
                                        drawGrid: false,
                                        showLabelsOnHighlight: false,
                                        colors: [$scope.color],
                                    }
                                );

                                delayedResize(0);
                            }
                        },
                        true
                    );

                    $scope.$watch('color', function () {
                        if ($scope.dyGraphInstance) {
                            $scope.dyGraphInstance.updateOptions({
                                colors: [$scope.color],
                            });
                        }
                    });

                    $scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, function () {
                        delayedResize(1000);
                    });

                    $scope.$on('$destroy', function () {
                        if ($scope.dyGraphInstance && $scope.dyGraphInstance.destroy) {
                            $scope.dyGraphInstance.destroy();
                            $scope.dyGraphInstance = null;
                        }
                        $window.clearTimeout(resizeTimer);
                    });
                },
            ],
            link: function ($scope, elem) {
                $scope.dyGraphInstance = null;
                $scope.dyGraphTargetElement = angular.element('.sparkline-dygraph-target', elem)[0];
            },
        };
    },
]);
