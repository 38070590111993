angular.module('signalview.location', []).service('locationChangeService', [
    function () {
        const listeners = [];

        const removeLocationChangeListener = (listener) => {
            const index = listeners.indexOf(listener);
            if (index !== -1) {
                listeners.splice(index, 1);
            }
        };

        const addLocationChangeListener = (listener) => {
            if (!listener || typeof listener !== 'function') return;
            removeLocationChangeListener(listener);
            listeners.push(listener);
        };

        const vetoChange = (newUrl, oldUrl) => {
            for (const listener of listeners) {
                if (listener(newUrl, oldUrl)) {
                    return true;
                }
            }
            return false;
        };

        return {
            removeLocationChangeListener,
            addLocationChangeListener,
            vetoChange,
        };
    },
]);
