import React from 'react';
import styled from 'styled-components';
import { variables } from '@splunk/themes';
import ChipBase from '@splunk/react-ui/Chip';

const LightChip = styled(ChipBase)`
    border-color: ${variables.black};
    div {
        color: ${variables.black};
        overflow: unset;
    }
`;

const DarkChip = styled(ChipBase)`
    div {
        overflow: unset;
    }
`;

function filterChipWrapper({ filterText, themeService }) {
    const themeKey = themeService.getColorScheme();

    return themeKey === 'light' ? (
        <LightChip appearance="outline" key={filterText} value={filterText}>
            {filterText}
        </LightChip>
    ) : (
        <DarkChip appearance="outline" key={filterText} value={filterText}>
            {filterText}
        </DarkChip>
    );
}

filterChipWrapper.displayName = 'filterChip';

export default filterChipWrapper;
