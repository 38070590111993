import _ from 'lodash';
import plotsService from '../../../alertEventModalV2/service/plotsService';

import templateUrl from './chartDisplayValueHighlighter.tpl.html';

class ChartDisplayValueHighlighterController {
    constructor(alertTypeService, valueFormatter) {
        this._alertTypeService = alertTypeService;
        this.valueFormatter = valueFormatter;
    }

    $onChanges() {
        this.__focusedEventCachedTriggerValue = null;
        this.__focusedEventCachedTriggerValueAsString = null;
    }

    get isEnabled() {
        return !!this.dyGraphInstance && !!this.focusedEvent;
    }

    get showValueHighlights() {
        if (_.isNil(this._focusedEventCachedTriggerValue)) {
            return false;
        }

        const [xRangeLo, xRangeHi] = this.dyGraphInstance.xAxisRange();
        if (this.xValue < xRangeLo || this.xValue > xRangeHi) {
            return false;
        }

        const [yRangeLo, yRangeHi] = this.dyGraphInstance.yAxisRange(0);
        if (this.yValue < yRangeLo || this.yValue > yRangeHi) {
            return false;
        }

        return true;
    }

    get highlightSeverity() {
        return this.focusedEvent.metadata.sf_severity;
    }

    get isClearingEvent() {
        return this._alertTypeService.isClearingEvent(this.focusedEvent.properties.is);
    }

    get yValueHighlightTopProp() {
        return `${this.dyGraphInstance.toDomYCoord(this.yValue, 0).toFixed(2)}px`;
    }

    get yValueHighlightLabel() {
        return this._focusedEventCachedTriggerValueAsString;
    }

    get xValueHighlightLeftProp() {
        return `${this.dyGraphInstance.toDomXCoord(this.xValue).toFixed(2)}px`;
    }

    get xValueHighlightLabel() {
        return this.tzPrefHour ? this.tzPrefHour : new Date(this.xValue).toLocaleTimeString();
    }

    get yValue() {
        return this._focusedEventCachedTriggerValue;
    }

    get xValue() {
        return this.focusedEvent.timestamp;
    }

    get _focusedEventCachedTriggerValueAsString() {
        if (!this.isEnabled) {
            return null;
        }

        if (!_.isNil(this.__focusedEventCachedTriggerValueAsString)) {
            return this.__focusedEventCachedTriggerValueAsString;
        }

        const focusedEvent = this.focusedEvent;

        if (!focusedEvent || !focusedEvent.properties || !focusedEvent.properties.inputs) {
            this.__focusedEventCachedTriggerValueAsString = null;
            return this.__focusedEventCachedTriggerValueAsString;
        }

        this.__focusedEventCachedTriggerValueAsString = plotsService.getTriggerValue(
            plotsService.getPlotsForIncident(this.focusedEvent, this.incidentInfo),
            this.valueFormatter.formatValue
        );
        return this.__focusedEventCachedTriggerValueAsString;
    }

    get _focusedEventCachedTriggerValue() {
        if (!this.isEnabled) {
            return null;
        }

        if (!_.isNil(this.__focusedEventCachedTriggerValue)) {
            return this.__focusedEventCachedTriggerValue;
        }

        const focusedEvent = this.focusedEvent;

        if (!focusedEvent || !focusedEvent.properties || !focusedEvent.properties.inputs) {
            this.__focusedEventCachedTriggerValue = null;
            return this.__focusedEventCachedTriggerValue;
        }

        this.__focusedEventCachedTriggerValue = plotsService.getTriggerValueAsNumber(
            plotsService.getPlotsForIncident(this.focusedEvent, this.incidentInfo)
        );
        return this.__focusedEventCachedTriggerValue;
    }
}

export const chartDisplayValueHighlighterComponent = {
    bindings: {
        dyGraphInstance: '<',
        focusedEvent: '<',
        incidentInfo: '<',
        tzPrefHour: '=',
    },
    templateUrl,
    controller: ['alertTypeService', 'valueFormatter', ChartDisplayValueHighlighterController],
};
