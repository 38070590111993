import templateUrl from './elementHeatmapLabel.tpl.html';

angular.module('signalview.heatmap').directive('heatmapElementLabel', [
    '$timeout',
    '$compile',
    'TetherDrop',
    function ($timeout, $compile, TetherDrop) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                data: '=',
                heatmap: '=',
                hideControls: '=',
            },
            templateUrl,
            link: function ($scope) {
                $scope.largeLabel = $scope.data._padding.top > 100;
                const eventBindings = [];
                const heatmap = $scope.heatmap;

                if ($scope.data.name === 'undefined') {
                    $scope.name = 'n/a';
                    $scope.tooltip = 'No value for ' + $scope.data.groupKey;
                } else {
                    $scope.name = $scope.data.name;
                    $scope.tooltip = $scope.name;
                }

                function isInFilters(datum, filters) {
                    return filters.some(function (filter) {
                        return (
                            filter.property === datum.groupKey &&
                            filter.propertyValue === datum.name
                        );
                    });
                }

                $scope.onMouseEnter = function () {
                    $timeout(function () {
                        heatmap.highlighted($scope.data);
                    }, 100);
                };

                $scope.onMouseLeave = function () {
                    $timeout(function () {
                        heatmap.highlighted(null);
                    }, 100);
                };

                $scope.selectThisGroup = function () {
                    heatmap.selection($scope.data);
                };

                $scope.detectorToggle = function ($event) {
                    angular.element(document).click(); // Close dropdown if open, click event is prevented from bubbling up
                    $scope.$emit('detectorMenuToggle');
                    $scope.selectThisGroup($event);
                };

                $scope.dropdownToggle = function (open, dropdownElement) {
                    if (open) {
                        heatmap.selection($scope.data);
                        $scope.$emit(
                            'crosslinkMenuToggle',
                            dropdownElement.find('.cross-link-menu-container')
                        );
                    } else {
                        heatmap.selection(null);
                    }
                };

                function updateFilterableState() {
                    const filters = heatmap.filterBy();
                    $scope.filterable =
                        $scope.data.depth !== 0 &&
                        (!filters.length || !isInFilters($scope.data, filters));
                }

                eventBindings.push(heatmap.on('filterBy updated', updateFilterableState));
                updateFilterableState();

                function filterTo(exclude) {
                    const filters = heatmap.filterBy();
                    let datum = $scope.data;

                    let alreadyFiltered;
                    while (datum.depth !== 0) {
                        alreadyFiltered = isInFilters(datum, filters);

                        if (!alreadyFiltered) {
                            filters.push({
                                iconClass: 'icon-properties',
                                type: 'property',
                                query: datum.groupKey + ':' + datum.name,
                                value: datum.groupKey + ':' + datum.name,
                                property: datum.groupKey,
                                propertyValue: datum.name,
                                NOT: exclude,
                            });
                        }

                        datum = datum.parent;
                    }

                    heatmap.filterBy(filters);
                    $scope.filterable = false;
                }

                $scope.crosslinkActions = [
                    {
                        title: 'Filter on property',
                        action: () => filterTo(false),
                        label: 'Filter',
                    },
                    {
                        title: 'Exclude on property',
                        action: () => filterTo(true),
                        label: 'Exclude',
                    },
                ];

                let labelTooltip;
                let tooltipOverDatumId = null;
                let tooltipTimeout;

                $scope.showTooltip = function (event) {
                    const contentsElement = event.target;
                    const data = $scope.data;
                    $timeout.cancel(tooltipTimeout);
                    if (tooltipOverDatumId === data.id) return;

                    if (labelTooltip && tooltipOverDatumId !== null) {
                        labelTooltip.destroy();
                        tooltipOverDatumId = null;
                    }

                    const $labelTooltipContent = angular.element(
                        // declare-used-dependency-to-linter::heatmapLabelTooltip
                        '<heatmap-label-tooltip data="data" heatmap="heatmap"></heatmap-label-tooltip>'
                    );
                    const labelTooltipContent = $labelTooltipContent[0];
                    const labelTooltipScope = $scope.$new();
                    labelTooltipScope.data = data;
                    labelTooltipScope.heatmap = $scope.heatmap;

                    $compile(labelTooltipContent)(labelTooltipScope);

                    $labelTooltipContent.on('mouseenter', function () {
                        $timeout.cancel(tooltipTimeout);
                        tooltipOverDatumId = data.id;
                    });

                    $labelTooltipContent.on('mouseleave', function () {
                        tooltipTimeout = $timeout(function () {
                            if (tooltipOverDatumId === data.id) {
                                labelTooltip.destroy();
                                tooltipOverDatumId = null;
                            }
                        }, 100);
                    });

                    let classes = 'heatmap-label-tooltip resource-tooltip';
                    classes += ' heatmap-label-tooltip-depth-' + data.depth;

                    labelTooltip = new TetherDrop({
                        target: contentsElement,
                        position: 'right center',
                        content: labelTooltipContent,
                        classPrefix: 'heatmap-label-',
                        classes: classes,
                        tetherOptions: {},
                        constrainToScrollParent: false,
                    });

                    tooltipOverDatumId = data.id;

                    // Wait for switchable modes to load prior to opening the tooltip to
                    // resolve some tooltip positioning issues
                    labelTooltipScope.$on('switchable modes updated', function () {
                        $timeout(function () {
                            if (tooltipOverDatumId === data.id) {
                                labelTooltip.open();
                            }
                        });
                    });
                };

                $scope.hideTooltip = function () {
                    const data = $scope.data;
                    tooltipTimeout = $timeout(function () {
                        if (tooltipOverDatumId === data.id) {
                            labelTooltip.destroy();
                            tooltipOverDatumId = null;
                        }
                    }, 100);
                };

                $scope.$on('rescaled', () => $scope.$broadcast('resize cozy text'));

                $scope.$on('$destroy', function () {
                    if (tooltipOverDatumId) {
                        labelTooltip.destroy();
                    }

                    eventBindings.forEach(function (unbind) {
                        unbind();
                    });
                });
            },
        };
    },
]);
