'use strict';
import templateUrl from './aclDashboardGroupPermissions.tpl.html';
import ExitGuardService from '../../../../app/dashboardGroup/aclDashboardGroup/ExitGuardService';
import AclDashboardGroupService from './AclDashboardGroupService';

export default {
    templateUrl,
    bindings: {
        dashboardGroup: '<',
        readOnly: '<',
        missingUpdateDashboardGroupCapability: '<?',
    },
    require: {
        tabby: '^tabby',
    },
    controller: [
        '$injector',
        '$scope',
        'themeService',
        'currentUser',
        'organizationService',
        'exitGuardModal',
        '$q',
        function (
            $injector,
            $scope,
            themeService,
            currentUser,
            organizationService,
            exitGuardModal,
            $q
        ) {
            const $ctrl = this;
            const aclDashboardGroupService = $injector.instantiate(AclDashboardGroupService);

            $scope.userId = null;
            $scope.orgId = null;

            $ctrl.$onInit = () => {
                $scope.exitGuardService = new ExitGuardService(
                    $ctrl.tabby.enableExitGuard,
                    $ctrl.tabby.disableExitGuard,
                    exitGuardModal
                );

                $scope.themeKey = themeService.getColorScheme();
                $scope.userData = null;
                $q.all({
                    user: currentUser.getOrgMember(),
                    orgId: organizationService
                        .getOrgSettings()
                        .then((result) => result.sf_organizationID),
                }).then(({ user, orgId }) => {
                    $scope.userData = { userId: user.id, isAdmin: user.admin, orgId };
                    $scope.dashboardGroupId = $ctrl.dashboardGroup?.id;
                    $scope.$applyAsync();
                });

                $scope.onDismiss = () => $scope.$emit('dismiss modal');
                $scope.save = (permissions) => {
                    const updatedDG = { ...$ctrl.dashboardGroup, permissions };
                    return aclDashboardGroupService
                        .update(updatedDG)
                        .then(() => $scope.$emit('close modal', updatedDG));
                };
            };
        },
    ],
};
