angular.module('sfx.charting').directive('parallelCoordinatesChart', [
    '_',
    '$log',
    'parallelCoordinatesChart',
    'd3',
    function (_, $log, parallelCoordinatesChart, d3) {
        return {
            restrict: 'E',
            scope: {
                data: '=',
                dimensions: '=',
                config: '=?',
                highlight: '=?',
                filterMap: '=?',
                selected: '=?',
                muteFilter: '=?',
                highlightFilter: '=?',
                width: '@',
                height: '@',
            },
            link: function ($scope, $element, attrs) {
                const chart = parallelCoordinatesChart();
                const element = $element[0];
                let filters = $scope.filterMap;
                let data;

                chart
                    .container(element)
                    .data($scope.data || [])
                    .dimensionDragging(false);

                // Prevent attempts to draw more than once a frame
                const throttledRedraw = _.throttle(chart.redraw, 16);
                const updateFilterMap = _.debounce(function () {
                    $scope.filterMap = filters;
                    $scope.$apply();
                }, 300);

                function redraw() {
                    if (!data) return;
                    throttledRedraw();
                }

                attrs.$observe('width', function (value) {
                    if (!value && value !== 0) return;
                    chart.width(value);
                    redraw();
                });

                attrs.$observe('height', function (value) {
                    if (!value && value !== 0) return;
                    chart.height(value);
                    redraw();
                });

                chart.onFiltersChange(function (_filters, selected) {
                    if (!angular.equals($scope.filterMap, _filters)) {
                        filters = _filters;
                        updateFilterMap();
                    }

                    if (!angular.equals($scope.selected, selected)) {
                        $scope.selected = selected;
                    }
                });

                function findMetricByDisplayName(displayName) {
                    let metric;
                    $scope.dimensions.some(function (_metric) {
                        if (_metric.displayName === displayName) {
                            metric = _metric;
                            return true;
                        }
                    });

                    return metric;
                }

                chart.onHighlightChange(function (highlight) {
                    if ($scope.highlight && $scope.highlight.displayName === highlight) return;
                    const metric = findMetricByDisplayName(highlight);

                    $scope.highlight = metric;
                    $scope.$apply();
                });

                function makeDomainFunction(metric) {
                    return function (data, accessor) {
                        let minValue = metric.minValue;
                        let maxValue = metric.maxValue;

                        if (minValue === undefined) {
                            minValue = d3.min(data, accessor);
                        }

                        if (maxValue === undefined) {
                            maxValue = d3.max(data, accessor);
                        }

                        return [minValue, maxValue];
                    };
                }

                chart.domain(function (dimension, data) {
                    const metric = findMetricByDisplayName(dimension);

                    if (metric) {
                        return makeDomainFunction(metric)(data, function (datum) {
                            return datum[dimension];
                        });
                    } else {
                        $log.warn(
                            'Unable to find matching metric: ' + dimension,
                            $scope.dimensions
                        );

                        return d3.extent(data, function (d) {
                            return d[dimension];
                        });
                    }
                });

                $scope.$watch('highlight', function (value) {
                    if (!value) return;
                    chart.highlight(value.displayName);
                    redraw();
                });

                $scope.$watch('highlightFilter', function (filter) {
                    chart.highlightFilter(filter);
                });

                $scope.$watch('muteFilter', function (filter) {
                    chart.muteFilter(filter);
                });

                $scope.$on('update highlighted', redraw);

                $scope.$watch('config', function (value) {
                    if (!value) return;

                    Object.keys(value).forEach(function (key) {
                        chart[key](value[key]);
                    });
                });

                $scope.$watch('dimensions', function (dimensions) {
                    if (dimensions === undefined) return;

                    const metricNames = dimensions.map(function (dimension) {
                        return dimension.displayName;
                    });

                    chart.dimensions(metricNames);
                    redraw();
                });

                $scope.$watch('data', function (_data) {
                    if (!_data) return;
                    data = _data;
                    chart.data(data);
                    redraw();
                });

                $scope.$watch(
                    'filterMap',
                    function (filterMap) {
                        if (!filterMap) return;
                        chart.filters(angular.copy(filterMap));
                    },
                    true
                );
            },
        };
    },
]);
