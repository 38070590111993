import './overview/module.js';
import alertTypeService from './alertTypeService.js';

angular
    .module('signalview.alerts', [
        'signalview.alertsOverview',
        'sfx.ui',
        'sfx.data',
        'signalboost',
        'signalview.sfGlobalNav',
    ])
    .service('alertTypeService', () => alertTypeService);
