angular.module('sfx.ui').directive('gcpServiceKeys', [
    '$window',
    '$q',
    '_',
    function ($window, $q, _) {
        return {
            link: function ($scope, $elem) {
                const objFile = angular.element('.import-service-account-key', $elem);
                const objTitle = $elem;

                objTitle.bind('click', () => {
                    objFile.click();
                });

                function readServiceKeys(fileObj) {
                    const defer = $q.defer();
                    const reader = new FileReader();

                    reader.onload = function () {
                        let serviceKey;
                        try {
                            serviceKey = angular.fromJson(reader.result);
                        } catch (exc) {
                            defer.reject(
                                new Error(`File ${fileObj.name} could not be parsed as JSON`)
                            );
                            return;
                        }

                        if (!('project_id' in serviceKey)) {
                            defer.reject(
                                new Error(`File ${fileObj.name} should have a project_id field!`)
                            );
                            return;
                        }

                        defer.resolve(serviceKey);
                    };

                    reader.readAsText(fileObj);
                    return defer.promise;
                }

                objTitle.after(objFile);

                objFile.bind('change', function () {
                    const tokenFiles = objFile.get()[0].files;
                    try {
                        if (!tokenFiles || tokenFiles.length === 0) {
                            return;
                        }

                        $q.all(
                            _.map(tokenFiles, function (file) {
                                return readServiceKeys(file);
                            })
                        )
                            .then(function (serviceKeys) {
                                $scope.$emit('service keys imported', serviceKeys);
                            })
                            .catch(function (error) {
                                $window.alert(error);
                            });
                    } finally {
                        objFile.val('');
                    }
                });
            },
        };
    },
]);
