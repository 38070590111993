angular.module('signalview.detector').controller('DetectorUpdateV1p5Controller', [
    '$scope',
    '$log',
    '$window',
    'detector',
    'detectorUtils',
    function ($scope, $log, $window, detector, detectorUtils) {
        $scope.detector = detector;
        $scope.updating = false;

        const uiModel = $scope.detector.sf_uiModel || {};
        const rules = uiModel.rules || [];

        $scope.hasRules = rules.some(function (rule) {
            return rule.name;
        });

        $scope.update = function () {
            $scope.updating = true;
            $log.info('Updating detector...');
            const newDetector = angular.copy($scope.detector);
            newDetector.sf_uiModel.rules.forEach(function (rule) {
                (rule.notifications || []).forEach(function (notification) {
                    if (notification.type === 'webhook' && notification.schemaVersion !== 2) {
                        notification.schemaVersion = 2;
                    }
                });
            });
            detectorUtils
                .saveDetector(newDetector, true)
                .then(function (updated) {
                    $scope.$close(updated);
                })
                .catch(function (e) {
                    $log.error('Error updating detector.', e);
                    $window.alert('There was an error updating this detector.');
                    $scope.$dismiss();
                })
                .finally(function () {
                    $scope.updating = false;
                });
        };
    },
]);
