angular.module('signalview.chartTemplates').run([
    'chartTemplates',
    'Chart',
    function (chartTemplates, Chart) {
        chartTemplates.register('default', function (filters) {
            const chart = Chart.create();
            const plot = chart.plot().metric('');

            if (filters) {
                Object.keys(filters).forEach(function (key) {
                    if (key === 'sf_metric') {
                        plot.metric(filters[key]);
                        chart.name(filters[key]);
                    } else {
                        plot.propertyFilter(key, filters[key]);
                    }
                });
            }

            return chart;
        });
    },
]);
