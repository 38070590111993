import templateUrl from './heatmapThresholdSelector.tpl.html';

angular.module('signalview.heatmap').directive('heatmapThresholdSelector', [
    function () {
        return {
            restrict: 'E',
            require: '^^groupableHeatmap',
            scope: {},
            templateUrl,
            link: function ($scope, element, attrs, heatmap) {
                $scope.threshold = heatmap.heatmap.threshold();
                heatmap.heatmap.on('threshold updated', function ($event, threshold) {
                    $scope.threshold = threshold;
                });
            },
        };
    },
]);
