'use strict';
import templateUrl from './tabbyTab.html';

export default {
    templateUrl,
    require: {
        tabby: '^tabby',
    },
    bindings: {
        title: '@',
        onlyInitWhenShown: '<', // When true, initializes the component only when the tab is active
    },
    transclude: true,
    controller: [
        function () {
            this.$onInit = function () {
                this.tabby.addPane(this);
            };
        },
    ],
};
