export default [
    '$scope',
    '$timeout',
    'userAnalytics',
    function ($scope, $timeout, userAnalytics) {
        $scope.editingInPlace = null;
        $scope.inPlaceFilters = [];
        let watcher = null;

        $scope.editInPlace = function () {
            if ($scope.onBeginEdit) {
                $scope.onBeginEdit($scope.filterIndex);
            }
            const currentFilter = angular.copy($scope.sourceFilters[$scope.filterIndex]);
            let propertyValue = currentFilter.propertyValue;
            if (angular.isArray(propertyValue) && propertyValue.length < 2) {
                currentFilter.propertyValue = propertyValue[0] || '';
                propertyValue = currentFilter.propertyValue;
            }
            let filter = '';
            if (!angular.isArray(propertyValue)) {
                if (currentFilter.NOT) {
                    filter += '!';
                }
                if (currentFilter.property) {
                    filter += currentFilter.property + ':';
                }
                if (propertyValue) {
                    filter += propertyValue;
                }
            }
            $scope.inPlaceFilters = angular.copy($scope.sourceFilters);
            $scope.inPlaceFilters.splice($scope.filterIndex, 1);
            $scope.editingInPlace = { filter: filter, key: filter, original: currentFilter };
            if ($scope.focus) {
                $scope.focus();
            }

            watcher = $scope.$watchCollection('inPlaceFilters', function (newFilters, oldFilters) {
                if (
                    newFilters &&
                    oldFilters &&
                    newFilters.length &&
                    newFilters.length !== oldFilters.length
                ) {
                    $scope.applyInPlace();
                }
            });
        };

        $scope.exitEditInPlace = function () {
            if (watcher) {
                watcher();
                watcher = null;
            }
            $scope.editingInPlace = null;
            $scope.inPlaceFilters = [];
            if ($scope.onEndEdit) {
                $scope.onEndEdit($scope.filterIndex);
            }
        };

        $scope.applyInPlace = function () {
            if ($scope.onApplyEdit) {
                $scope.onApplyEdit($scope.filterIndex);
            }
            const len = $scope.inPlaceFilters.length - 1;
            const currentFilter = $scope.inPlaceFilters[len];
            $scope.sourceFilters[$scope.filterIndex] = currentFilter;
            if ($scope.applySourceFilters) {
                $scope.applySourceFilters();
            }
            $timeout(function () {
                $scope.exitEditInPlace();
            }, 0);
        };

        $scope.removeItem = function () {
            userAnalytics.event('click', 'remove-source-filter-override');
            $scope.sourceFilters.splice($scope.filterIndex, 1);
        };
    },
];
