import _ from 'lodash';

export const RESOLUTION_TO_CHART_RANGE = [
    {
        minResolution: 0,
        maxResolution: 5000,
        value: '-15m',
    },
    {
        minResolution: 5001,
        maxResolution: 10000,
        value: '-1h',
    },
    {
        minResolution: 10001,
        value: '-12h',
    },
];

export function resolutionToChartRange(resolution) {
    let chartRange = '-15m';
    RESOLUTION_TO_CHART_RANGE.forEach(function (range) {
        if (
            resolution >= range.minResolution &&
            (_.isUndefined(range.maxResolution) || resolution <= range.maxResolution)
        ) {
            chartRange = range.value;
        }
    });
    return chartRange;
}
