angular.module('signalview.documentState', []).factory('documentStateService', [
    '$document',
    function ($document) {
        let isVisible = true;
        const callbacks = new Set();
        $document.on('visibilitychange', function () {
            isVisible = !$document.attr('hidden');

            callbacks.forEach((callback) => {
                if (callback && typeof callback === 'function') {
                    callback();
                }
            });
        });
        return {
            isDocumentVisible: function () {
                return isVisible;
            },
            registerVisibilityCallback: function (callback) {
                callbacks.add(callback);
            },
            unregisterVisibilityCallback: function (callback) {
                callbacks.delete(callback);
            },
        };
    },
]);
