import React, { FC, useMemo } from 'react';
import { useCurrentUserStore } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStoreProvider';
import getApmApp from './get-apm-app';
import ContentError from '../routing/ContentError';
import { useSplunkTheme } from '@splunk/themes';
import { AngularInjector } from '../../common/AngularUtils';
import { react2angular } from 'react2angular';

export const ApmApp: FC<{ themeKey?: 'dark' | 'light' }> = ({ themeKey }) => {
    const Apm = useMemo<FC<any>>(getApmApp, []);
    const { colorScheme } = useSplunkTheme();
    const featureEnabled = AngularInjector.useInjectedClass('featureEnabled');
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    const auth = AngularInjector.useInjectedClass('auth');
    const currentUser = useCurrentUserStore();
    const colorAccessibilityService = AngularInjector.useInjectedClass('colorAccessibilityService');
    const locationChangeService = AngularInjector.useInjectedClass('locationChangeService');

    if (!Apm) {
        return <ContentError />;
    }

    return (
        <Apm
            themeKey={themeKey || colorScheme}
            featureEnabled={featureEnabled}
            userAnalytics={userAnalytics}
            auth={auth}
            currentUser={currentUser}
            colorAccessibilityService={colorAccessibilityService}
            locationChangeService={locationChangeService}
        />
    );
};

export const ngApmApp = react2angular(ApmApp, ['themeKey']);
