import Button from '@splunk/react-ui/Button';
import styled from 'styled-components';
import { variables } from '@splunk/themes';

const secondaryBackgroundColor = '#333';

const StyledPanel = styled.div`
    background: ${variables.backgroundColorDialog};
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
        props.theme.splunkThemeV1.colorScheme === 'dark'
            ? variables.contentColorDefault
            : variables.black};
`;

const StyledForm = styled.form`
    min-height: 200px;
    width: 100%;
`;

const StyledLabel = styled.label`
    color: ${variables.contentColorMuted};
    margin: 0;
    padding: 8px 0px;
`;

const StyledModalHeader = styled.h2`
    padding: 20px 30px;
    margin-bottom: 0;
`;

const StyledSectionHeader = styled.header`
    font-weight: bold;
    font-size: 12px;
    color: ${variables.contentColorDefault};
    margin-top: 20px;
    padding: 15px 0 5px;
    border-top: 1px solid ${variables.contentColorDefault};
`;

const StyledFooter = styled.div`
    background: ${(props) =>
        props.theme.splunkThemeV1.colorScheme === 'dark'
            ? secondaryBackgroundColor
            : 'transparent'};
    text-align: right;
    padding: 20px 30px;
`;

const StyledButton = styled(Button)`
    border-radius: 4px;
`;

const StyledBody = styled.div`
    background: ${(props) =>
        props.appearance === 'dark' ? secondaryBackgroundColor : 'transparent'};
    padding: 20px 30px;
`;

const StyledDescription = styled.div`
    color: ${variables.contentColorDefault};
    font-size: 12px;
    margin: 4px 0;
`;

export {
    StyledPanel,
    StyledForm,
    StyledModalHeader,
    StyledSectionHeader,
    StyledBody,
    StyledFooter,
    StyledButton,
    StyledDescription,
    StyledLabel,
};
