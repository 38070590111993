import { baseStorageClient } from '@splunk/olly-services/lib';
import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export default [
    '_',
    '$q',
    '$http',
    'EXPERIMENTAL_URL',
    'mustache',
    'KubernetesResourceTypes',
    'plotToSignalflowV2',
    'KUBE_PROPERTY_WORKLOAD_MAPPING',
    'urlOverridesService',
    'URL_PARAMETER_CONSTANTS',
    'KUBE_PROPERTY_TO_VARIABLE_ALIAS',
    function (
        _,
        $q,
        $http,
        EXPERIMENTAL_URL,
        mustache,
        KubernetesResourceTypes,
        plotToSignalflowV2,
        KUBE_PROPERTY_WORKLOAD_MAPPING,
        urlOverridesService,
        URL_PARAMETER_CONSTANTS,
        KUBE_PROPERTY_TO_VARIABLE_ALIAS
    ) {
        const insightsURL = `${EXPERIMENTAL_URL}/v2/_/insights/k8s`;
        const workloadTypes = Object.keys(KUBE_PROPERTY_WORKLOAD_MAPPING);
        const CACHE_TTL = 20000;
        const cacheKeyPrefix = 'k8s-insights';
        const insightsCache = baseStorageClient(cacheKeyPrefix, {}); // In-memory cache
        const KUBERNETES_CLUSTER = 'kubernetes_cluster';
        const DATA_VIEW_K8S_NAV = 'Data_View/K8s_Nav/';

        const WORKLOAD_KEYS = {
            NAME: 'kubernetes_workload_name',
            OBJECT_NAME: 'kubernetes_name',
            TYPE: 'kubernetes_workload',
            DEPLOYMENT: 'deployment',
            CRONJOB: 'cronJob',
        };

        return {
            WORKLOAD_KEYS,
            getMapAnalyzerResult,
            getWorkloadType,
            getMergedOverridesAndAnalyzerContext,
            isAnalyzerContextPresent,
            getAnalyzerContexts,
            setAnalyzerContexts,
            getProcessedProgramText,
            cleanFilters,
            getExclusiveDeploymentFilters,
            translateWorkloadFilters,
            getChartModelTime,
            generateFilterStrings,
            getDataViewK8sNavEventCategory,
        };

        /**
         * Gets results of kubernetes insights across multiple contexts
         * @param queryObjects
         *        { context: KubernetesResourceTypes,
         *          contextKey: string,
         *          contextValue: string
         *        }
         * @param filters
         *        { propertyKey: string,
         *          propertyValue: string,
         *          NOT: bool
         *        }
         * @return Promise
         */
        function getMapAnalyzerResult(queryObjects, filters = [], variables = []) {
            if (!queryObjects) {
                return $q.reject(null);
            }

            for (const { context, property, propertyValue } of queryObjects) {
                if (!KubernetesResourceTypes[context] || !property || !propertyValue) {
                    return $q.reject('Illegal context');
                }
            }

            const clusters = [];
            for (const { context, property, propertyValue } of queryObjects) {
                if (
                    context === KubernetesResourceTypes.CLUSTER &&
                    property === KUBERNETES_CLUSTER
                ) {
                    clusters.push(propertyValue);
                }
            }

            // If the query context isn't at the cluster level, add the cluster context from variables
            if (!clusters.length) {
                const allFilters = (variables || []).concat(filters || []);
                for (const { property, value } of allFilters) {
                    if (property === KUBERNETES_CLUSTER) {
                        clusters.push(...value);
                    }
                }
            }

            // Assume multiple contexts means multiple cluster contexts
            const context =
                queryObjects.length === 1
                    ? queryObjects[0].context
                    : KubernetesResourceTypes.CLUSTER;
            const params = {
                clusters,
                context,
                filters: generateFilterStrings(filters),
            };

            const cachedData = insightsCache.get(getInsightsCacheKey(params));
            const responsePromise = cachedData
                ? $q.when(cachedData)
                : $http.get(insightsURL, { params }).then((response) => response.data);

            return responsePromise.then((results) => {
                cacheInsightsResult(params, results);
                // Add context to results to be used in filtering
                results.forEach((conditionResult) => {
                    conditionResult.clusterResults.forEach((clusterResult) => {
                        const context = {
                            context: KubernetesResourceTypes.CLUSTER,
                            property: 'kubernetes_cluster',
                            propertyValue: clusterResult.cluster,
                        };
                        clusterResult.context = context;
                    });
                });
                return results;
            });
        }

        function cacheInsightsResult(params, result) {
            // Add to cache if not present
            const cacheKey = getInsightsCacheKey(params);
            if (!insightsCache.get(cacheKey)) {
                insightsCache.set(cacheKey, result, CACHE_TTL);
            }
        }

        function getInsightsCacheKey({ context, filters, clusters = [] }) {
            const clusterString = clusters.join(',');
            return `${context}:${filters}:${clusterString}`;
        }

        function getDataViewK8sNavEventCategory(eventCategorySuffix) {
            return DATA_VIEW_K8S_NAV + eventCategorySuffix.replaceAll(' ', '_');
        }

        function getProcessedProgramText({ BASE_METRIC_TEMPLATES, METRICS }, filterBy = []) {
            const metricTemplates = [];
            const publishBlocks = [];

            METRICS.forEach((metric) => {
                if (metric.job.template) {
                    metricTemplates.push(metric.job);
                }

                if (metric.job.varName) {
                    publishBlocks.push(metric);
                }
            });

            const processedBaseTemplates = processJobTemplate(BASE_METRIC_TEMPLATES, filterBy);
            const processedMetricTemplates = processJobTemplate(metricTemplates, filterBy);
            const processedPublishBlocks = publishBlocks.map(
                ({ job, displayName }) => `${job.varName}.publish("${displayName || job.varName}")`
            );

            return processedBaseTemplates
                .concat(processedMetricTemplates, processedPublishBlocks)
                .join('\n');
        }

        function processJobTemplate(jobTemplates, filterBy) {
            if (!jobTemplates) {
                return [];
            }

            return jobTemplates.map(({ template, filters }) => {
                const allFilters = cleanFilters(filterBy.concat(filters || []));
                const exclusiveDeploymentFilters = cleanFilters(
                    getExclusiveDeploymentFilters(allFilters)
                );

                return mustache.render(template || '', {
                    filter: generateFilterStrings(allFilters),
                    exclusiveDeploymentFilters: expandServiceFilters(exclusiveDeploymentFilters),
                    workloadTranslatedFilters: translateWorkloadFilters(allFilters),
                });
            });
        }

        function translateWorkloadFilters(filters = []) {
            const translatedFilters = [];
            const filterStrings = [];
            filters.forEach((f) => {
                const { property, propertyValue, NOT } = f;
                if (property === WORKLOAD_KEYS.NAME && propertyValue) {
                    const workloadSelectors = [
                        [{ property: WORKLOAD_KEYS.NAME, propertyValue, NOT }],
                        [{ property: WORKLOAD_KEYS.OBJECT_NAME, propertyValue, NOT }],
                        getExclusiveDeploymentFilters([
                            { property: WORKLOAD_KEYS.NAME, propertyValue, NOT },
                        ]),
                    ];

                    const workloadSelectorStrings = workloadSelectors.map(
                        (s) => `(${plotToSignalflowV2.filters(s)})`
                    );
                    filterStrings.push(`(${workloadSelectorStrings.join(' or ')})`);
                } else if (
                    property === WORKLOAD_KEYS.TYPE &&
                    propertyValue &&
                    propertyValue !== '*'
                ) {
                    const workloadType = _.camelCase(propertyValue);
                    const workloadTypeSelectors = [
                        [{ property: WORKLOAD_KEYS.TYPE, propertyValue, NOT }],
                    ];

                    if (workloadType === WORKLOAD_KEYS.DEPLOYMENT) {
                        workloadTypeSelectors.push([
                            { property: '_exists_', propertyValue: WORKLOAD_KEYS.DEPLOYMENT, NOT },
                        ]);
                    }

                    if (workloadType === WORKLOAD_KEYS.CRONJOB) {
                        workloadTypeSelectors.push([
                            { property: '_exists_', propertyValue: WORKLOAD_KEYS.CRONJOB, NOT },
                        ]);
                    }

                    const workloadSelectorStrings = workloadTypeSelectors.map(
                        (s) => `(${plotToSignalflowV2.filters(s)})`
                    );
                    filterStrings.push(`(${workloadSelectorStrings.join(' or ')})`);
                } else {
                    translatedFilters.push(f);
                }
            });

            // Check for all metrics with workloads. filter('kubernetes_workload', '*')
            if (filterStrings.length) {
                translatedFilters.push({
                    property: '_exists_',
                    propertyValue: WORKLOAD_KEYS.TYPE,
                });
            }

            if (translatedFilters.length) {
                filterStrings.push(generateFilterStrings(translatedFilters));
            }

            return filterStrings.join(' and ');
        }

        // Encompasses all necessary translations generically required on each k8s pages
        // and returns the generated filter string
        function generateFilterStrings(filters = []) {
            return expandServiceFilters(filters);
        }

        // Accounts for both 'service' and 'sf_service' filters under single key
        function expandServiceFilters(filters = []) {
            const serviceFiltersMap = {};
            const nonServiceFilters = [];
            filters.forEach((f) => {
                const { property, propertyValue } = f;
                if (property !== 'service' && property !== 'sf_service') {
                    nonServiceFilters.push(plotToSignalflowV2.filters([f]));
                    return;
                }

                const valueKey = Array.isArray(propertyValue)
                    ? propertyValue.sort().join('')
                    : propertyValue;

                serviceFiltersMap[valueKey] = f;
            });

            const serviceFilterStrings = Object.values(serviceFiltersMap).map((filter) => {
                const not = filter.NOT || false;
                const service = plotToSignalflowV2.filters([
                    { ...filter, NOT: false, property: 'service' },
                ]);
                const sf_service = plotToSignalflowV2.filters([
                    { ...filter, NOT: false, property: 'sf_service' },
                ]);
                return `${not ? '!' : ''}(${service} or ${sf_service})`;
            });

            return nonServiceFilters.concat(serviceFilterStrings).join(' and ');
        }

        function cleanFilters(filters = []) {
            const filterMap = {};
            filters.forEach(({ property, propertyValue, type, NOT }) => {
                if (property === '_exists_' && type === 'property') {
                    property = propertyValue;
                    propertyValue = null;
                }
                const isEmptyValue = _.isEmpty(propertyValue);
                propertyValue = isEmptyValue ? '*' : propertyValue;
                if (!filterMap[property] || !isEmptyValue) {
                    filterMap[property] = { property, propertyValue, NOT };
                }
            });
            return Object.values(filterMap);
        }

        function getChartModelTime({ startTime, endTime } = {}) {
            if (!startTime && !endTime) {
                return;
            }

            if (Number.isInteger(startTime)) {
                return { type: 'absolute', start: startTime, end: endTime };
            } else if (startTime.startsWith('-')) {
                const range = convertStringToMS(startTime);
                const rangeEnd = convertStringToMS(endTime) || 0;

                if (!isNaN(range)) {
                    return { type: 'relative', range, rangeEnd };
                }
            }
        }

        // TODO: Remove once we have no more dependency on SignalFx-Agent < 5.0.0
        function getExclusiveDeploymentFilters(filters = []) {
            const translatedFilters = angular.copy(filters);
            const workloadFilter = translatedFilters.find(
                ({ property }) => property === WORKLOAD_KEYS.NAME
            );
            if (!workloadFilter) {
                return translatedFilters;
            }

            // filter out "kuberentes_workload_name" and exclusively keep "deployment" only
            workloadFilter.NOT = true;
            translatedFilters.push({
                property: WORKLOAD_KEYS.DEPLOYMENT,
                propertyValue: workloadFilter.propertyValue,
            });

            return translatedFilters;
        }

        // TODO: Remove this once we use kubernetes_workload property
        function getWorkloadType(data) {
            const intersection = Object.keys(data).filter((x) => workloadTypes.includes(x));
            if (intersection.length > 1) {
                // We have two workload types that are children of Deployment
                // i.e. Replica Set and Replication Controller
                if (!intersection.includes(WORKLOAD_KEYS.DEPLOYMENT)) {
                    return 'Multiple';
                }
                const nonDeploymentWorkload = intersection.filter(
                    (v) => v !== WORKLOAD_KEYS.DEPLOYMENT
                )[0];
                return KUBE_PROPERTY_WORKLOAD_MAPPING[nonDeploymentWorkload];
            }
            return KUBE_PROPERTY_WORKLOAD_MAPPING[intersection[0]];
        }

        function isAnalyzerContextPresent() {
            return urlOverridesService.doesParamExist(
                URL_PARAMETER_CONSTANTS.infoSidebarAnalyzerQuery
            );
        }

        function getAnalyzerContexts() {
            const contexts = urlOverridesService.getSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarAnalyzerQuery
            );
            if (!contexts) {
                return null;
            }
            return contexts
                .map((contextStr) => {
                    const { context, property, propertyValue } = JSON.parse(contextStr);
                    if (context && property && propertyValue) {
                        return { context, property, propertyValue };
                    }
                    return null;
                })
                .filter((c) => c);
        }

        function setAnalyzerContexts(contextList) {
            const analyzerParams = contextList.map(({ context, property, propertyValue }) => {
                return JSON.stringify({ context, property, propertyValue });
            });

            urlOverridesService.setSearchParam(
                URL_PARAMETER_CONSTANTS.infoSidebarAnalyzerQuery,
                analyzerParams
            );
        }

        function getMergedOverridesAndAnalyzerContext(sourceFilters, variables) {
            const sourceFiltersMap = {};
            const variablesMap = {};

            if (sourceFilters) {
                sourceFilters.forEach((filter) => {
                    const property = (filter.NOT ? '!' : '') + filter.property;
                    sourceFiltersMap[property] = filter;
                    if (!angular.isArray(filter.propertyValue)) {
                        filter.propertyValue = [filter.propertyValue];
                    }
                });
            }

            if (variables) {
                variables.forEach((variable) => {
                    variablesMap[variable.property] = variable;
                    if (!angular.isArray(variable.value)) {
                        variable.value = [variable.value];
                    }

                    if (!variable.alias && KUBE_PROPERTY_TO_VARIABLE_ALIAS[variable.property]) {
                        variable.alias = KUBE_PROPERTY_TO_VARIABLE_ALIAS[variable.property];
                    }
                });
            }

            const analyzerContexts = getAnalyzerContexts() || [];

            analyzerContexts.forEach(({ property, propertyValue }) => {
                if (!propertyValue) {
                    return;
                }

                if (sourceFiltersMap[property]) {
                    if (!sourceFiltersMap[property].propertyValue.includes(propertyValue)) {
                        sourceFiltersMap[property].propertyValue.push(propertyValue);
                    }
                } else {
                    const propertyValueList = [propertyValue];
                    sourceFiltersMap[property] = {
                        property,
                        propertyValue: propertyValueList,
                        value: propertyValueList,
                        NOT: false,
                    };
                }

                if (variablesMap[property]) {
                    if (!variablesMap[property].value.includes(propertyValue)) {
                        variablesMap[property].value.push(propertyValue);
                    }
                } else {
                    variablesMap[property] = {
                        alias: KUBE_PROPERTY_TO_VARIABLE_ALIAS[property] || property,
                        property,
                        value: [propertyValue],
                        applyIfExists: true,
                    };
                }
            });

            return {
                sources: Object.values(sourceFiltersMap),
                variables: Object.values(variablesMap),
            };
        }
    },
];
