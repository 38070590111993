import orgUsageReportItemTemplateUrl from './orgUsageReportItems.tpl.html';
angular.module('signalview.orgUsage').component('orgUsageReportItems', {
    templateUrl: orgUsageReportItemTemplateUrl,
    bindings: {
        loading: '<',
        time: '<',
        reports: '<',
        subscriptionType: '<',
        name: '@?',
        daily: '<?',
        hourly: '<?',
    },
    controller: [
        'subscriptionTypeUtil',
        function (subscriptionTypeUtil) {
            const $ctrl = this;

            $ctrl.isSameSubscription = function (reportType) {
                return (
                    subscriptionTypeUtil.SUBSCRIPTION_TYPE_TO_REPORT_MAPPING[
                        $ctrl.subscriptionType
                    ] === reportType
                );
            };
        },
    ],
});
