import templateUrl from './detectorWizardRecipients.tpl.html';

/**
 * The recipients editor component shows the rule notification widget
 * and allows user to update notifications for the rule.
 */
angular.module('signalview.detector.wizard').component('detectorWizardRecipientsEditor', {
    bindings: {
        detector: '<',
        rule: '<',
        isNewRule: '<',
        onRecipientsSelect: '&',
        proceed: '&',
        done: '&',
    },
    templateUrl,
    controller: [
        '$scope',
        'featureEnabled',
        function ($scope) {
            let previousWatch;
            const ctrl = this;
            ctrl.$onInit = $onInit;

            function $onInit() {
                // copy notifications to local variable
                $scope.recipients = angular.copy(ctrl.rule.notifications || []);
                // pass modified notifications to parent binding
                if (previousWatch) {
                    previousWatch();
                }
                previousWatch = $scope.$watch(
                    'recipients',
                    function (nval, oval) {
                        if (nval && !angular.equals(nval, oval)) {
                            ctrl.onRecipientsSelect({ recipients: $scope.recipients });
                        }
                    },
                    true
                );
            }
        },
    ],
});
