export default [
    '$http',
    'API_URL',
    'featureEnabled',
    function ($http, API_URL, featureEnabled) {
        const url = API_URL + '/v2/metrictimeseries';

        const isDashboardTimeWindowEnabled = featureEnabled('dashboardTimeWindow');
        function search(query, offset = 0, limit = 100) {
            return $http.get(url, {
                params: {
                    query,
                    offset,
                    limit,
                },
            });
        }

        function getPropertyCounts(query, offset = 0, limit = 100) {
            const endpoint = `${url}/_/property-counts`;

            return $http.get(endpoint, {
                params: {
                    query,
                    offset,
                    limit,
                },
            });
        }

        function getMetricTypes(
            programTexts,
            start,
            stop,
            additionalFilters,
            additionalReplaceOnlyFilters,
            programArgs
        ) {
            const programTextsWithFilters = {
                programTexts,
                start,
                stop,
                additionalFilters,
                additionalReplaceOnlyFilters,
                ...(isDashboardTimeWindowEnabled && { programArgs: programArgs }),
            };
            const endpoint = `${API_URL}/v2/signalflow/_/getMetricTypes`;
            return $http.post(endpoint, programTextsWithFilters).then((res) => res.data);
        }

        return {
            search,
            propertyCounts: getPropertyCounts,
            getMetricTypes,
        };
    },
];
