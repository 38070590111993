import templateUrl from './overview.tpl.html';
import { AlertNavController } from './AlertNavController';

export default {
    bindings: {
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl,
    controller: AlertNavController,
};
