angular.module('sfx.ui').service('azureService', [
    '$http',
    'API_URL',
    function ($http, API_URL) {
        let environmentsPromise = null;
        const DEFAULT_ENVIRONMENT = 'AZURE';

        function getAvailableServices() {
            return $http.get(API_URL + '/v2/azure/_/availableServices');
        }

        function getSupportedEnvironments() {
            // Environments are hardcoded as of 5/2/2019.
            if (!environmentsPromise) {
                environmentsPromise = new Promise(function (resolve) {
                    resolve({
                        AZURE: 'Azure',
                        AZURE_US_GOVERNMENT: 'Azure Government',
                    });
                });
            }
            return environmentsPromise;
        }

        function getListOfSubscriptionsWithoutId(tenantId, appId, secretKey, azureEnv) {
            tenantId = encodeURIComponent(tenantId || '');
            appId = encodeURIComponent(appId || '');
            secretKey = encodeURIComponent(secretKey || '');
            const envQuery = azureEnv ? `&azureEnvironment=${encodeURIComponent(azureEnv)}` : '';
            return $http.get(
                API_URL +
                    `/v2/azure/_/subscriptions?tenantID=${tenantId}&appID=${appId}&secretKey=${secretKey}${envQuery}`
            );
        }

        function getListOfSubscriptionsWithId(id, azureEnv) {
            const envQuery = azureEnv ? `?azureEnvironment=${encodeURIComponent(azureEnv)}` : '';
            return $http.get(API_URL + `/v2/azure/_/subscriptions/${id}${envQuery}`);
        }

        return {
            getAvailableServices,
            getSupportedEnvironments,
            getListOfSubscriptionsWithId,
            getListOfSubscriptionsWithoutId,
            DEFAULT_ENVIRONMENT,
        };
    },
]);
