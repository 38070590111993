import templateUrl from './integrationsCategory.tpl.html';

angular
    .module('signalview.orgIntegrations')

    .directive('integrationsCategory', [
        '$location',
        '_',
        'featureEnabled',
        function ($location, _, featureEnabled) {
            return {
                restrict: 'AE',
                replace: false,
                templateUrl,
                scope: {
                    categoryTitle: '=',
                    integrations: '=',
                    activeIntegrations: '=',
                },
                link: function ($scope) {
                    $scope.load = function (integrationType, integrationName) {
                        $location.search({
                            selectedKeyValue: integrationType + ':' + integrationName,
                        });
                    };

                    const [explicitlySorted, intList] = _.partition(
                        $scope.integrations,
                        function (i) {
                            return i.sortOrder !== undefined;
                        }
                    );
                    const restSorted = _.sortBy(intList, function (i) {
                        return i.title.toLowerCase();
                    });
                    // Splice in the explicitly ordered integrations at the specified
                    // index, sorting them first so the indexes stay consistent.
                    _.sortBy(explicitlySorted, 'sortOrder').forEach(function (i) {
                        restSorted.splice(i.sortOrder, 0, i);
                    });
                    $scope.integrations = restSorted;

                    // if integration has 'feature' in the meta,
                    // we want to show it ONLY if the feature is enabled
                    $scope.showIntegration = function (integration) {
                        if (integration.infraOnly && !featureEnabled('infra')) {
                            return false;
                        }
                        if (!integration.feature) {
                            return true;
                        }
                        return featureEnabled(integration.feature);
                    };
                },
            };
        },
    ]);
