export function getCPUChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'CPU% Used By Pod',
        defaultPlotType: 'AreaChart',
        axes: [
            {
                min: 0,
                label: 'cpu usage %',
            },
        ],
        legendOptions: {
            fields: [
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'container_spec_name', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'kubernetes_pod_uid', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
                { property: 'host', enabled: true },
                { property: 'container_image', enabled: true },
                { property: 'container_id', enabled: true },
                { property: 'gcp_id', enabled: false },
                { property: 'metric_source', enabled: false },
                { property: 'container_name', enabled: false },
                { property: 'machine_id', enabled: false },
                { property: 'AWSUniqueId', enabled: false },
                { property: 'kubernetes_node_uid', enabled: false },
            ],
        },
    };

    const filterString = filters ? `, filter=(${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'centi-core usage per node',
            streamLabel: 'POD_CPU_USAGE',
            programText: `POD_CPU_USAGE = data('container_cpu_utilization', rollup='rate' ${filterString}).sum(by=['kubernetes_node', 'kubernetes_cluster', 'kubernetes_pod_uid', 'kubernetes_pod_name']).publish(label='POD_CPU_USAGE', enable=False)`,
        },
        {
            displayName: '# cores per node',
            streamLabel: 'NODE_CPU_CORES',
            programText: `NODE_CPU_CORES = data('cpu.num_processors' ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node']).publish(label='NODE_CPU_CORES', enable=False)`,
        },
        {
            displayName: 'machine_cpu_cores - Sum by kubernetes_cluster, kubernetes_node',
            streamLabel: 'MACHINE_CPU_CORES',
            programText: `MACHINE_CPU_CORES = data('machine_cpu_cores' ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node']).publish(label='MACHINE_CPU_CORES', enable=False)`,
        },
        {
            displayName: 'CPU Capacity % Used',
            streamLabel: 'CPU_USAGE_1',
            programText:
                "CPU_USAGE_1 = (POD_CPU_USAGE / NODE_CPU_CORES).publish(label='CPU_USAGE_1')",
            valueSuffix: '%',
        },
        {
            displayName: 'CPU Capacity % Used',
            streamLabel: 'CPU_USAGE_2',
            programText:
                "CPU_USAGE_2 = (POD_CPU_USAGE / MACHINE_CPU_CORES).publish(label='CPU_USAGE_2')",
            valueSuffix: '%',
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getMemoryChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Mem% Used By Pod',
        defaultPlotType: 'AreaChart',
        axes: [
            {
                min: 0,
                label: 'memory usage %',
            },
        ],
        legendOptions: {
            fields: [
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'host', enabled: true },
                { property: 'container_image', enabled: true },
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
                { property: 'sf_metric', enabled: true },
                { property: 'container_spec_name', enabled: true },
                { property: 'kubernetes_pod_uid', enabled: true },
                { property: 'container_id', enabled: true },
                { property: 'gcp_id', enabled: false },
                { property: 'metric_source', enabled: false },
                { property: 'container_name', enabled: false },
                { property: 'machine_id', enabled: false },
                { property: 'AWSUniqueId', enabled: false },
                { property: 'kubernetes_node_uid', enabled: false },
            ],
        },
    };

    const filterString = filters ? `, filter=(${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'Memory bytes usage per pod',
            streamLabel: 'POD_MEM_USAGE',
            programText: `POD_MEM_USAGE = data('container_memory_usage_bytes' ${filterString}).sum(by=['kubernetes_node', 'kubernetes_cluster', 'kubernetes_pod_uid', 'kubernetes_pod_name']).publish(label='POD_MEM_USAGE', enable=False)`,
        },
        {
            displayName: 'Node Memory',
            streamLabel: 'NODE_MEMORY',
            programText: `NODE_MEMORY = data('memory.total' ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node']).publish(label='NODE_MEMORY', enable=False)`,
        },
        {
            displayName: 'machine_memory_bytes - Sum by kubernetes_cluster, kubernetes_node',
            streamLabel: 'MACHINE_MEMORY',
            programText: `MACHINE_MEMORY = data('machine_memory_bytes' ${filterString}).sum(by=['kubernetes_cluster', 'kubernetes_node']).publish(label='MACHINE_MEMORY', enable=False)`,
        },
        {
            displayName: 'Memory Capacity % Used',
            streamLabel: 'MEMORY_USAGE_1',
            programText:
                "MEMORY_USAGE_1 = (100 * POD_MEM_USAGE / NODE_MEMORY).publish(label='MEMORY_USAGE_1')",
            valueSuffix: '%',
        },
        {
            displayName: 'Memory Capacity % Used',
            streamLabel: 'MEMORY_USAGE_2',
            programText:
                "MEMORY_USAGE_2 = (100 * POD_MEM_USAGE / MACHINE_MEMORY).publish(label='MEMORY_USAGE_2')",
            valueSuffix: '%',
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getNetworkInChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Network In by Pod',
        defaultPlotType: 'AreaChart',
        stacked: true,
        stackedChart: true,
        legendOptions: {
            fields: [
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'kubernetes_namespace', enabled: true },
                { property: 'kubernetes_pod_uid', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
                { property: 'sf_metric', enabled: true },
            ],
        },
    };

    const filterString = filters ? `, filter=(${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'Network In/sec by pod',
            streamLabel: 'POD_NETWORK_RECV',
            programText: `POD_NETWORK_RECV = data(\'pod_network_receive_bytes_total\', rollup=\'rate\' ${filterString}).sum(by=[\'kubernetes_cluster\', \'kubernetes_node\', \'kubernetes_pod_uid\', \'kubernetes_pod_name\']).publish(\'POD_NETWORK_RECV\')`,
            valueUnit: 'Byte',
        },
    ];

    return { chartOptions, publishLabelOptions };
}

export function getNetworkOutChartModel(time, filters) {
    const chartOptions = {
        time,
        name: 'Network Out by Pod',
        defaultPlotType: 'AreaChart',
        stacked: true,
        stackedChart: true,
        legendOptions: {
            fields: [
                { property: 'kubernetes_pod_name', enabled: true },
                { property: 'kubernetes_node', enabled: true },
                { property: 'kubernetes_cluster', enabled: true },
                { property: 'kubernetes_pod_uid', enabled: true },
                { property: 'sf_originatingMetric', enabled: true },
                { property: 'sf_metric', enabled: true },
            ],
        },
    };

    const filterString = filters ? `, filter=(${filters})` : '';
    const publishLabelOptions = [
        {
            displayName: 'Network out/sec by pod',
            streamLabel: 'POD_NETWORK_SENT',
            programText: `POD_NETWORK_SENT = data(\'pod_network_transmit_bytes_total\', rollup=\'rate\' ${filterString}).sum(by=[\'kubernetes_cluster\', \'kubernetes_node\', \'kubernetes_pod_uid\', \'kubernetes_pod_name\']).publish(\'POD_NETWORK_SENT\')`,
            valueUnit: 'Byte',
        },
    ];

    return { chartOptions, publishLabelOptions };
}
