const CLEARING_STATES = ['ok', 'stopped', 'manually resolved', 'cleared', 'auto resolved'];
const FIRING_STATES = ['anomalous', 'too high', 'too low'];

const STATE_TO_DISPLAY = {
    anomalous: 'Triggered',
    'too high': 'Triggered',
    'too low': 'Triggered',
    ok: 'Cleared',
    stopped: 'Stopped',
    cleared: 'Manually Resolved',
    'manually resolved': 'Manually Resolved',
    'auto resolved': 'Auto-Cleared',
};

export default {
    getDisplayFor(is) {
        return STATE_TO_DISPLAY[is];
    },

    isClearingEvent(is) {
        return CLEARING_STATES.indexOf(is) !== -1;
    },

    getFiringStateQueryStr() {
        return FIRING_STATES.map((s) => {
            return '"' + s + '"';
        }).join(' OR ');
    },
};
