angular
    .module('signalflowV2ApiUtils.v2Chart', [])
    .factory('v2ChartFactory', [
        '$window',
        function ($window) {
            return $window.signalviewV2ApiUtils.chart.chartOptions;
        },
    ])
    .constant(
        'defaultTextNoteMarkdown',
        'This is a markdown editor for creating text notes for your dashboards.<br> Enter your text or markdown on the left, and preview it on the right.<br>Here are some markdown examples:<br>\n' +
            '#####_small headline italicized_\n' +
            '1. ordered list\n' +
            '2. same\n' +
            '\n' +
            '~~strike through~~\n' +
            '<pre>code segment\n' +
            'more code\n' +
            '</pre>\n' +
            'this is a [link](https://signalfx.com)\n' +
            '\n' +
            '* bullet1\n' +
            '* bullet2\n' +
            '\n' +
            'Click Create to save this text note to your dashboard.\n'
    )
    .service('v2ChartService', [
        'v2ChartFactory',
        function (v2ChartFactory) {
            function getEmptyV2Chart(mode) {
                if (mode === 'text') {
                    return {
                        name: '',
                        description: '',
                        programText: '',
                        options: new v2ChartFactory.Text({}),
                    };
                } else {
                    return {
                        name: '',
                        description: '',
                        programText: '',
                        options: new v2ChartFactory.TimeSeriesChart({}),
                    };
                }
            }

            return {
                getEmptyV2Chart,
            };
        },
    ]);
