import templateUrl from './legacyPlotName.tpl.html';

angular.module('signalview.detector.rule').directive('legacyPlotName', [
    function () {
        return {
            restrict: 'E',
            scope: {
                plotKey: '=',
                plotName: '=',
                plotType: '=',
            },
            templateUrl,
        };
    },
]);
