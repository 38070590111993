import { sanitizeTerm } from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import { angularifyUriQuery } from '../../../common/urlparams/urlAngularify';

angular.module('signalview.heatmap').factory('discoveryDashboardUtils', [
    '$http',
    '$log',
    '$q',
    'API_URL',
    'currentUser',
    'Chart',
    'dashboardV2Util',
    function ($http, $log, $q, API_URL, currentUser, Chart, dashboardV2Util) {
        const DISCOVERY_ENDPOINT = API_URL + '/v2/dashboard/_/discoveryDashboards';

        function getDiscoveryQueryFromProperties(properties) {
            return currentUser.orgId().then(function (orgId) {
                let query = [];

                for (const property in properties) {
                    const value = properties[property];

                    query.push(
                        'sf_discoverySelectors:("_exists_:' +
                            property +
                            '")' +
                            ' OR sf_discoverySelectors:("' +
                            property +
                            ':' +
                            value +
                            '")'
                    );
                }

                query = query.join(' OR ');
                query = '(' + query + ') AND (sf_organizationID:' + orgId + ')';
                return query;
            });
        }

        function getDiscoveryQueryFromMode(mode) {
            if (!mode.discoveryQuery || mode.discoveryQuery.length === 0) {
                $log.error('No discovery query found for use in finding SD dashboards!');
                return $q.reject('Invalid discovery query provided.');
            }

            return currentUser.orgId().then(function (orgId) {
                return (
                    '(' +
                    mode.discoveryQuery
                        .map((q) => {
                            return 'sf_discoverySelectors:("' + q + '")';
                        })
                        .join(' OR ') +
                    ') AND (sf_organizationID:' +
                    orgId +
                    ')'
                );
            });
        }

        function discoveryQueryHasInstanceId(dashboard) {
            return (
                dashboard &&
                dashboard.discoveryOptions &&
                dashboard.discoveryOptions.query.indexOf('_exists_:InstanceId') !== -1
            );
        }

        function createChartLinkFunction(filters, dashboard) {
            return function getChartLink() {
                let link = '';

                if (filters.length) {
                    const variablePropertyToName = {};

                    if (
                        dashboard.filters &&
                        dashboard.filters.variables &&
                        dashboard.filters.variables.length
                    ) {
                        dashboard.filters.variables.forEach(function (variable) {
                            const prop = variable.property;
                            if (variable.replaceOnly) {
                                return;
                            }
                            variablePropertyToName[prop] = variable.alias;
                        });
                    }

                    let parts = filters.map(function (filter) {
                        const key = filter[0];
                        let value = filter[1];

                        if (Array.isArray(value)) {
                            value = JSON.stringify(value);
                        }

                        return key + ':' + value;
                    });

                    if (discoveryQueryHasInstanceId(dashboard)) {
                        parts = parts.filter(function (part) {
                            return part !== '_exists_:host';
                        });
                    }

                    link += parts
                        .map(function (part) {
                            const prop = part.split(':')[0];
                            if (variablePropertyToName[prop]) {
                                return (
                                    'variables[]=' +
                                    encodeURIComponent(variablePropertyToName[prop] + '=' + part)
                                );
                            } else {
                                return 'sources[]=' + encodeURIComponent(part);
                            }
                        })
                        .join('&');
                }

                return angularifyUriQuery(link);
            };
        }

        function createCleanChartFunction(filters, dashboard) {
            return function cleanChart(chart) {
                // v2 charts, which don't have sf_type, don't get cleaned in the same way
                if (!chart.sf_type) return chart;

                $log.error('Tried to clean a V1 Discovery Dashboard Chart.');

                // Delete all the random unnecessary properties.
                delete chart.sf_updatedBy;
                delete chart.sf_createdBy;
                delete chart.sf_viewProgramText;
                delete chart.sf_programTextRegex;
                delete chart.sf_throttledProgramText;
                delete chart.sf_uiModel.allPlotProgramText;

                const uiModel = chart.sf_uiModel;
                delete uiModel.uiHelperValue;
                delete uiModel.allDetectorDecorators;
                delete uiModel.sf_detectorDecorators;
                delete uiModel.allPlotConstructs;

                const wrappedChart = Chart.create(chart);
                const oredFilters = {};
                filters.forEach(function (filter) {
                    const property = filter[0];
                    const value = filter[1];
                    let oredValue = oredFilters[property];
                    if (!oredValue) {
                        oredValue = value;
                    } else if (angular.isArray(oredValue)) {
                        oredValue.push(value);
                    } else {
                        oredValue = [oredValue, value];
                    }
                    oredFilters[property] = oredValue;
                });
                Object.keys(oredFilters).forEach(function (filter) {
                    // For AWS dashboards, don't add host filters which may be coming from
                    // hosts views. This is a hack to enable shoving AWS dashboards to the
                    // hosts view.
                    const value = oredFilters[filter];
                    if (
                        angular.equals([filter, value], ['_exists_', 'host']) &&
                        discoveryQueryHasInstanceId(dashboard)
                    ) {
                        return;
                    }
                    wrappedChart.filter(filter, value);
                });

                return wrappedChart.config();
            };
        }

        function getDashboardsFromObject(object, filters) {
            return getDiscoveryQueryFromObject(object).then((selectorQuery) => {
                return getDashboards(selectorQuery, convertFiltersToQuery(filters));
            });
        }

        function getDashboardsFromProperties(properties, filters) {
            return getDiscoveryQueryFromProperties(properties).then((selectorQuery) => {
                return getDashboards(selectorQuery, convertFiltersToQuery(filters));
            });
        }

        function getDashboardsFromMode(mode, mtsQuery) {
            return getDiscoveryQueryFromMode(mode).then((query) => {
                return getDashboards(query, mtsQuery);
            });
        }

        function getDashboards(selectorQuery, query) {
            return currentUser.orgId().then((orgId) => {
                return $http
                    .get(DISCOVERY_ENDPOINT, { params: { selectorQuery, query, orgId } })
                    .then((result) => {
                        result.data.sort((a, b) => a.name.localeCompare(b.name));
                        return result.data;
                    })
                    .catch(() => {
                        $log.warn('Failed to fetch discovery dashboards');
                        return [];
                    });
            });
        }

        function getDashboardData(dashboard, filters, disableErrorHandling = false) {
            if (!dashboard) {
                return $q.when(null);
            }

            const cleanChart = createCleanChartFunction(filters, dashboard);

            return dashboardV2Util
                .getAndProcessHierarchy(dashboard.id, undefined, disableErrorHandling)
                .then(function (data) {
                    data.charts = data.charts.map(cleanChart);
                    return data;
                });
        }

        function convertFiltersToQuery(filters = []) {
            // TODO: REMOVE LOGIC AROUND 'HOST' PROP ASAP AFTER MATT-2559 IS RESOLVED
            return filters
                .map(([prop, val]) =>
                    prop === 'host'
                        ? `(host:${sanitizeTerm(val)} OR host.name:${sanitizeTerm(val)})`
                        : `${prop}:${sanitizeTerm(val)}`
                )
                .join(' AND ');
        }

        return {
            getDashboards,
            getDashboardsFromObject,
            getDashboardsFromProperties,
            getDashboardsFromMode,
            getDiscoveryQueryFromMode,
            convertFiltersToQuery,
            createChartLinkFunction,
            createCleanChartFunction,
            getDashboardData,
        };
    },
]);
