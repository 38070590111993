import React, { FC, useMemo, useCallback } from 'react';
import { react2angular } from 'react2angular';
import { DashboardFilters } from '@splunk/olly-imm/build/Dashboard/dashboardFilter/DashboardFilters';
import {
    Filter,
    Variable,
    CurrentTimeRangeCallback,
} from '@splunk/olly-imm/build/Dashboard/dashboard.model';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';
import { AngularInjector } from '../../../common/AngularUtils';

type dashboardFilter = {
    allCharts?: any;
    activeVariables?: Variable[];
    sourceFilters?: Filter[];
    getCurrentTimeRange: CurrentTimeRangeCallback;
};

export const DashboardFilter: FC<dashboardFilter> = ({
    allCharts,
    activeVariables,
    sourceFilters,
    getCurrentTimeRange,
}) => {
    const themeKey = useLegacyThemeServiceKey();

    const chartUtils = AngularInjector.useInjectedClass('chartUtils');

    const dashboardVariablesService = AngularInjector.useInjectedClass('dashboardVariablesService');

    const suggestAPIService = AngularInjector.useInjectedClass('suggestAPIService');

    const urlOverrides = AngularInjector.useInjectedClass('urlOverridesService');

    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');

    const programText = useMemo(
        () => chartUtils.getChartSignalFlow(allCharts),
        [allCharts, chartUtils]
    );

    const getOverridesCallback = useCallback(
        () => dashboardVariablesService.getVariablesFromUrl(),
        [dashboardVariablesService]
    );

    const getSuggestionsCallback = useCallback(
        (body: any) => suggestAPIService.getSignalFlowSuggest(body),
        [suggestAPIService]
    );

    const setSourceUrlCallback = useCallback(
        (filter: Filter[]): void => urlOverrides.setSourceFilterOverrideList(filter),
        [urlOverrides]
    );

    const setUserAnalyticsCallback = useCallback(
        (event, message) => userAnalytics.event(event, message),
        [userAnalytics]
    );

    return (
        <ThemeProvider colorScheme={themeKey}>
            <DashboardFilters
                programText={programText}
                getOverridesCallback={getOverridesCallback}
                getSuggestionsCallback={getSuggestionsCallback}
                setSourceUrlCallback={setSourceUrlCallback}
                setUserAnalyticCallback={setUserAnalyticsCallback}
                activeVariables={activeVariables}
                sourceFilters={sourceFilters}
                getCurrentTimeRange={getCurrentTimeRange}
            ></DashboardFilters>
        </ThemeProvider>
    );
};

export const dashboardFilter = react2angular(DashboardFilter, [
    'allCharts',
    'activeVariables',
    'sourceFilters',
    'getCurrentTimeRange',
]);
