import {
    sanitizeTerm,
    sanitizeWithRegExMode,
    sanitizeTermForNewRelic,
    sanitizeTermForGraphite,
} from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';

angular.module('chartbuilderUtil').factory('plotUtils', [
    '$log',
    'regExParsingUtil',
    'regExStyles',
    'signalflowV2Utils',
    'blockService',
    'migratedSignalboost',
    function (
        $log,
        regExParsingUtil,
        regExStyles,
        signalflowV2Utils,
        blockService,
        migratedSignalboost
    ) {
        const EXPRESSION_KEY_EXP = /[a-zA-Z]+/g;

        function getLetterFromUniqueKey(uk) {
            let value = uk;
            if (typeof value !== 'number') {
                value = parseInt(value, 10);
            }
            if ((typeof value !== 'number' && !isNaN(value)) || value < 0) {
                console.log('Invalid unique key specified.');
                return null;
            }

            let letterValue;
            const letters = [];

            while (value > 26) {
                letterValue = value % 26;
                value = Math.floor(value / 26);

                if (letterValue === 0) {
                    // Since A maps to 1, Z maps to 0 in this scheme
                    letterValue = 26;
                    value -= 1;
                }

                letters.push(String.fromCharCode(64 + letterValue));
            }

            letterValue = value % 26;
            if (letterValue === 0) letterValue = 26;
            letters.push(String.fromCharCode(64 + letterValue));

            return letters.reverse().join('');
        }

        function getUniqueKeyFromLetter(letters) {
            if (!letters || typeof letters !== 'string') return;
            const letterCount = letters.length;

            return letters
                .toUpperCase()
                .split('')
                .reduce(function (prev, next, index) {
                    return prev + (next.charCodeAt(0) - 64) * Math.pow(26, letterCount - index - 1);
                }, 0);
        }

        function getPlotByKey(key, plots) {
            return plots.find(function (plot) {
                return getLetterFromUniqueKey(plot.uniqueKey) === key;
            });
        }

        function getPlotByUniqueKey(key, plots) {
            return plots.find(function (plot) {
                return plot.uniqueKey.toString() === key;
            });
        }

        function getAllPlotUniqueKeys(plots) {
            const uniqueKeys = {};

            plots
                .filter(function (plot) {
                    return !plot.transient && plot.type !== 'event';
                })
                .forEach(function (plot) {
                    uniqueKeys[plot.uniqueKey] = true;
                });

            return uniqueKeys;
        }

        function getPlotExpressionKeyToUKMap(plots) {
            const plotEKtoUK = {};

            plots
                .filter(function (plot) {
                    return !plot.transient;
                })
                .forEach(function (plot) {
                    const letter = getLetterFromUniqueKey(plot.uniqueKey);
                    plotEKtoUK[letter] = plot.uniqueKey;
                });

            return plotEKtoUK;
        }

        function getPlotLetter(plot) {
            return getLetterFromUniqueKey(plot.uniqueKey);
        }

        function getPlotAliases(plot) {
            if (!isAliasedRegExStyle(plot)) {
                $log.warn('Attempted to get aliases for a non aliasable  plot.');
                return null;
            }

            const aliasMap = plot.configuration.aliases || {};
            const delim = regExStyles.getDelimiter(plot.seriesData.regExStyle);
            const dynamicTerms = [];
            const graphiteTerms = plot.seriesData.metric.split(delim);
            graphiteTerms.forEach(function (t, idx) {
                const aliasName = aliasMap[idx] || null;
                dynamicTerms.push(aliasName ? aliasName : 'node' + idx);
            });
            return dynamicTerms;
        }

        function validateRegEx(expr, style) {
            //newrelic we only support * and /, so should be okay... no state to keep for curlybraces/brackets?
            if (style === 'graphite') {
                const exprParts = expr.split('.');
                const errs = [];
                exprParts.forEach(function (node, idx) {
                    if (regExParsingUtil.hasGraphiteSyntax(node)) {
                        let curlyCount = 0;
                        let bracketCount = 0;

                        for (let x = 0; x < node.length; x++) {
                            switch (node[x]) {
                                case '{':
                                    curlyCount++;
                                    break;
                                case '}':
                                    curlyCount--;
                                    break;
                                case '[':
                                    bracketCount++;
                                    break;
                                case ']':
                                    bracketCount--;
                                    break;
                            }
                        }
                        if (curlyCount !== 0) {
                            errs.push({ nodeNumber: idx, message: 'Mismatched {} detected.' });
                        }
                        if (bracketCount !== 0) {
                            errs.push({ nodeNumber: idx, message: 'Mismatched [] detected.' });
                        }
                    }
                });
                return errs;
            }
            return [];
        }

        function getNodeSamplesForAlias(plot) {
            const regExStyle = plot.seriesData.regExStyle;
            const delim = regExStyles.getDelimiter(regExStyle);
            if (isAliasedRegExStyle(plot)) {
                return migratedSignalboost.metric
                    .get('', {
                        query:
                            '(sf_metric:' +
                            sanitizeWithRegExMode(plot.seriesData.metric, regExStyle) +
                            ')',
                        limit: 1,
                        fields: ['sf_metric'],
                    })
                    .then(
                        function (data) {
                            if (!data.rs.length) {
                                //wat
                                return null;
                            } else {
                                return data.rs[0].sf_metric.split(delim);
                            }
                        },
                        function () {
                            $log.error('MTS sample call failed, giving up!');
                            return null;
                        }
                    );
            }
            return null;
        }

        function getInvisiblePlotUniqueKeys(model) {
            return model.sf_uiModel.allPlots
                .filter(function (plot) {
                    return plot.invisible;
                })
                .map(function (plot) {
                    return plot.uniqueKey;
                });
        }

        function isAliasedRegExStyle(plot) {
            // 3 types so far, wildcard, graphite, newrelic
            const style = plot.seriesData.regExStyle;
            return style === 'graphite' || style === 'newrelic';
        }

        function hasVariables(chart) {
            return chart.sf_uiModel.allPlots.some(function (f) {
                return f.queryItems.some(function (q) {
                    return q.type === 'variable';
                });
            });
        }

        function sanitizePlotMetric(plot) {
            const regExStyle = plot.seriesData.regExStyle || '';
            const metric = plot.seriesData.metric;
            switch (regExStyle) {
                case 'graphite':
                    return sanitizeTermForGraphite(metric);
                case 'newrelic':
                    return sanitizeTermForNewRelic(metric);
                default:
                    return sanitizeTerm(plot.seriesData.metric);
            }
        }

        // update plot visibility, for now just heatmap consideration
        // sets all visible ratio/metric plots to invisible
        // sets one of them to be visible (if no candidate is passed in)
        function updatePlotVisibility(chartMode, plots, preferredCandidate) {
            if (chartMode !== 'heatmap') {
                return null;
            }
            let candidate = preferredCandidate || null;
            const plotsToConsider = (plots || []).filter(function (plot) {
                return (
                    (!candidate || plot.uniqueKey !== candidate.uniqueKey) &&
                    (plot.type === 'ratio' || plot.type === 'plot') &&
                    !plot.invisible &&
                    !plot.transient
                );
            });
            plotsToConsider.forEach(function (plot) {
                plot.invisible = true;
            });
            if (!candidate && plotsToConsider.length) {
                candidate = plotsToConsider[0];
            }
            if (candidate) {
                candidate.invisible = false;
            }
            return candidate;
        }

        function getAllRelevantPlots(rootPlot, expressionsEncountered, allPlots) {
            // finds all plot unique keys that are involved in the provided root plot.  ignores loops and tries to give results anyway

            let relevantPlots = {};
            if (rootPlot.type === 'ratio') {
                expressionsEncountered[rootPlot.uniqueKey] = true;
                const keys = getAllExpressionKeys(rootPlot.expressionText);
                const keyToUKMap = getPlotExpressionKeyToUKMap(allPlots);
                const keyArr = Object.keys(keys).map(function (key) {
                    return keyToUKMap[key];
                });
                relevantPlots[rootPlot.uniqueKey] = true;
                angular.forEach(allPlots, function (plot) {
                    if (plot.type === 'plot' && keyArr.indexOf(plot.uniqueKey) !== -1) {
                        relevantPlots[plot.uniqueKey] = true;
                    } else if (
                        plot.type === 'ratio' &&
                        plot.uniqueKey !== rootPlot.uniqueKey &&
                        keyArr.indexOf(plot.uniqueKey) !== -1
                    ) {
                        if (!expressionsEncountered[plot.uniqueKey]) {
                            relevantPlots = angular.extend(
                                relevantPlots,
                                getAllRelevantPlots(plot, expressionsEncountered, allPlots)
                            );
                        } else {
                            $log.info(
                                'Loop detected when trying to find plots used by an expression.'
                            );
                        }
                    }
                });
            } else if (rootPlot.type === 'plot') {
                relevantPlots[rootPlot.uniqueKey] = true;
            } else if (rootPlot.type === 'event') {
                //nope.  not a valid plot.
            } else {
                $log.error('Unrecognized plot type encountered.');
            }
            return relevantPlots;
        }

        function getAllExpressionKeys(str) {
            const keys = {};

            if (!str) return keys;

            const matches = str.match(EXPRESSION_KEY_EXP);
            if (matches) {
                matches
                    .map(function (key) {
                        // Normalize to uppercase
                        return key.toUpperCase();
                    })
                    .forEach(function (key) {
                        keys[key] = true;
                    });
            }

            return keys;
        }

        function getAllRelevantPlotObjects(plotLetter, plots, types) {
            if (!types) {
                types = ['plot'];
            }
            const key = getUniqueKeyFromLetter(plotLetter);
            const expressions = {};
            const rootPlot =
                plots.filter(function (plot) {
                    return key === plot.uniqueKey;
                })[0] || {};
            const relevantPlots = getAllRelevantPlots(rootPlot, expressions, plots);
            const metricPlots = {};
            plots.forEach(function (plot) {
                if (types.indexOf(plot.type) !== -1) {
                    metricPlots[plot.uniqueKey] = plot;
                }
            });
            return Object.keys(relevantPlots)
                .filter(function (plotKey) {
                    plotKey = parseInt(plotKey, 10);
                    const plot = plots.find((p) => p.uniqueKey === plotKey);
                    return plot && types.some((t) => t === plot.type);
                })
                .map(function (plotKey) {
                    return metricPlots[plotKey];
                });
        }

        function getAllRelevantPlotAndRatioObjects(plotLetter, plots) {
            return angular.copy(getAllRelevantPlotObjects(plotLetter, plots, ['ratio', 'plot']));
        }

        function hasValidPlots(model) {
            try {
                signalflowV2Utils.topologicalSortNonTransientDataPlotsOnly(
                    angular.copy(model.sf_uiModel.allPlots)
                );
                return true;
            } catch (e) {
                // ignore exception display
                return false;
            }
        }

        const FAKE_AGGREGATIONS = blockService.getFakeAggregations();

        function extractAggregationDimensions(plot) {
            const aggregationDimensions = {};

            let i = plot.dataManipulations.length - 1;
            let currDataManipulation = {};
            while (i >= 0 && Object.entries(aggregationDimensions).length === 0) {
                currDataManipulation = plot.dataManipulations[i];

                if (!FAKE_AGGREGATIONS.includes(currDataManipulation.fn.type)) {
                    currDataManipulation.direction.options.aggregateGroupBy.forEach(
                        (groupByObject) => {
                            aggregationDimensions[groupByObject.value] = true;
                        }
                    );
                }

                i--;
            }

            return aggregationDimensions;
        }

        function canCorrelateByAggregations(plots) {
            const dimensionsGroupedBy = {};

            let canCorrelate = true;
            plots.forEach((plot) => {
                const aggregationDimensions = extractAggregationDimensions(plot);
                const numDimensions = Object.keys(aggregationDimensions).length;

                if (numDimensions > 0 && !dimensionsGroupedBy[numDimensions]) {
                    dimensionsGroupedBy[numDimensions] = aggregationDimensions;
                } else if (
                    numDimensions > 0 &&
                    !angular.equals(dimensionsGroupedBy[numDimensions], aggregationDimensions)
                ) {
                    canCorrelate = false;
                    return;
                }
            });

            if (!canCorrelate) {
                return canCorrelate;
            }

            const sortedLengths = Object.keys(dimensionsGroupedBy).sort();
            let smallerSet = dimensionsGroupedBy[sortedLengths.shift()];
            let largerSet;
            sortedLengths.forEach((setLength) => {
                largerSet = dimensionsGroupedBy[setLength];
                if (!Object.keys(smallerSet).every((dimension) => largerSet[dimension])) {
                    canCorrelate = false;
                    return;
                }
                smallerSet = largerSet;
            });

            return canCorrelate;
        }

        function formCorrelationErrorMsg(plots, msgEnds) {
            if (!msgEnds) {
                return `There was a problem with the correlation in Plot ${plots[0]}. It may be one of the following issues:\n- The metrics involved in your correlation may have differing dimensions such that analytics does not know how to relate them.\n- The metrics involved may share dimensions, but may not have the same values of those dimensions.`;
            } else if (plots.length === 1) {
                return `Plot ${plots[0]} ${msgEnds.single}`;
            } else if (plots.length === 2) {
                return `Plots ${plots.join(' and ')} ${msgEnds.multiple}`;
            } else {
                return `Plots ${plots.slice(0, -1).join(', ')}, and ${plots[plots.length - 1]} ${
                    msgEnds.multiple
                }`;
            }
        }

        /**
         *
         * @param type: One of the types defined in CORRELATION_ERR_TYPES
         * @param failingPlots: An array of the letter(s) of the plots that should be referenced in the error message
         * @returns {{isSynthetic: boolean, type: string, msg: string}}
         */
        function formCorrelationError(type, failingPlots) {
            const err = {
                isSynthetic: true,
                type: type,
            };

            if (type !== CORRELATION_ERR_TYPES.GENERAL_CORRELATION_ERR) {
                err.msg = formCorrelationErrorMsg(failingPlots, CORRELATION_ERR_MSGS[type]);
            } else {
                err.msg = formCorrelationErrorMsg(failingPlots);
            }

            return err;
        }

        const CORRELATION_ERR_TYPES = {
            NO_TS_ERR: 'NO_TS_ERR',
            BAD_AGGREGATION_GROUPINGS: 'BAD_AGGREGATION_GROUPINGS',
            GENERAL_CORRELATION_ERR: 'GENERAL_CORRELATION_ERR',
        };

        const CORRELATION_ERR_MSGS = {
            NO_TS_ERR: {
                single: 'contains no time series. Analytics does not know how to relate it to other plots.',
                multiple:
                    'contain no time series. Analytics does not know how to relate them to each other.',
            },
            BAD_AGGREGATION_GROUPINGS: {
                single: "uses plots that cannot be related by the dimensions on which they are aggregated. Each plot must be aggregated on a set of dimensions that form either a superset or a subset of all other plots' aggregating dimensions.",
            },
        };

        function getCorrelationError(plotToCheck, allPlots, plotKeyToInfoMap) {
            // Get all unique keys of relevant plots not including the correlation
            // being checked for errors
            const relevantPlotUniqueKeys = Object.keys(
                getAllRelevantPlots(plotToCheck, {}, allPlots)
            ).filter((uniqueKey) => uniqueKey !== plotToCheck.uniqueKey.toString());

            // Get all plot letters of plots with no time series
            const relevantPlotsWithNoTS = relevantPlotUniqueKeys
                .filter((uk) => {
                    return (
                        plotKeyToInfoMap[uk].timeSeriesPrePublish !== null &&
                        plotKeyToInfoMap[uk].timeSeriesPrePublish === 0
                    );
                })
                .map((uk) => getLetterFromUniqueKey(uk));

            // If we have at least one plot with no time series, show that error
            if (relevantPlotsWithNoTS.length > 0) {
                return formCorrelationError(CORRELATION_ERR_TYPES.NO_TS_ERR, relevantPlotsWithNoTS);
            }

            // Get actual plot objects to check aggregation groupBys
            const relevantPlots = relevantPlotUniqueKeys.map((uk) => {
                return getPlotByUniqueKey(uk, allPlots);
            });

            // Check for aggregation grouping errors, and if there are any, report
            // which plots may be the problem
            if (relevantPlots.every((plot) => plot.dataManipulations.length > 0)) {
                if (!canCorrelateByAggregations(relevantPlots)) {
                    return formCorrelationError(CORRELATION_ERR_TYPES.BAD_AGGREGATION_GROUPINGS, [
                        getLetterFromUniqueKey(plotToCheck.uniqueKey),
                    ]);
                }
            }

            // This case is a catch-all for the errors that are too expensive to detect in the UI
            // The message will list possible issues and include the usual link to docs.
            if (plotKeyToInfoMap[plotToCheck.uniqueKey].timeSeriesPrePublish === 0) {
                return formCorrelationError(CORRELATION_ERR_TYPES.GENERAL_CORRELATION_ERR, [
                    getLetterFromUniqueKey(plotToCheck.uniqueKey),
                ]);
            }

            return null;
        }

        function getDetectorPlot(detector) {
            if (!detector || !detector.sf_uiModel || !detector.sf_id || !detector.sf_detector) {
                return undefined;
            }

            return detector.sf_uiModel.allPlots.find(
                (plot) =>
                    plot.type === 'event' &&
                    plot.name === detector.sf_id &&
                    plot.seriesData?.detectorQuery === detector.sf_detector
            );
        }

        function createDetectorPlot(detector, uniqueKey) {
            if (!detector || (!uniqueKey && uniqueKey !== 0)) {
                $log.error('Cannot create detector plot without detector and unique key!');
                return null;
            }

            return {
                name: detector.sf_id,
                type: 'event',
                invisible: false,
                transient: false,
                dataManipulations: [],
                yAxisIndex: 0,
                queryItems: [],
                metricDefinition: {},
                seriesData: { detectorQuery: detector.sf_detector },
                uniqueKey: uniqueKey,
            };
        }

        return {
            getLetterFromUniqueKey: getLetterFromUniqueKey,
            getUniqueKeyFromLetter: getUniqueKeyFromLetter,
            getAllPlotUniqueKeys: getAllPlotUniqueKeys,
            getPlotAliases: getPlotAliases,
            getPlotByKey: getPlotByKey,
            validateRegEx: validateRegEx,
            getPlotExpressionKeyToUKMap: getPlotExpressionKeyToUKMap,
            getPlotLetter: getPlotLetter,
            getNodeSamplesForAlias: getNodeSamplesForAlias,
            getInvisiblePlotUniqueKeys: getInvisiblePlotUniqueKeys,
            isAliasedRegExStyle: isAliasedRegExStyle,
            hasPlotVariables: hasVariables,
            sanitizePlotMetric: sanitizePlotMetric,
            updatePlotVisibility: updatePlotVisibility,
            getAllRelevantPlots: getAllRelevantPlots, // plot keys only
            getAllExpressionKeys: getAllExpressionKeys,
            getAllRelevantPlotObjects: getAllRelevantPlotObjects, // complete plot objects
            getAllRelevantPlotAndRatioObjects: getAllRelevantPlotAndRatioObjects,
            hasValidPlots: hasValidPlots,
            getCorrelationError: getCorrelationError,
            getDetectorPlot: getDetectorPlot,
            createDetectorPlot: createDetectorPlot,
            CORRELATION_ERR_TYPES: CORRELATION_ERR_TYPES,
        };
    },
]);
