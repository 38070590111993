export { getPodPhaseChartModel, getCPUChartModel, getMemoryChartModel };

function getPodPhaseChartModel(time, filters, deploymentFilters) {
    const chartOptions = {
        time,
        name: '# Pods by Phase',
        defaultPlotType: 'AreaChart',
        stacked: true,
        stackedChart: true,
        legendOptions: {
            fields: [
                { enabled: true, property: 'kubernetes_workload_name' },
                { enabled: true, property: 'kubernetes_cluster' },
                { enabled: true, property: 'kubernetes_namespace' },
                { enabled: true, property: 'sf_originatingMetric' },
                { enabled: true, property: 'sf_metric' },
            ],
        },
    };

    const aggregationGroup =
        "['kubernetes_cluster', 'kubernetes_namespace', 'kubernetes_workload_name']";

    const publishLabelOptions = [
        {
            // sfxagent >= 5.0.0
            displayName: 'Workload Pod Phase',
            streamLabel: 'WORKLOAD_PHASE',
            programText: `WORKLOAD_PHASE = data('kubernetes.pod_phase', filter=(${filters}), rollup='latest', extrapolation='last_value', maxExtrapolations=2).promote(['kubernetes_workload_name'])`,
        },
        {
            // sfxagent < 5.0.0 TODO: Remove when agent 5.0.0 is completely adopted
            displayName: 'Deployment Pod Phase',
            streamLabel: 'DEPLOYMENT_PHASE',
            programText: `DEPLOYMENT_PHASE = data('kubernetes.pod_phase', filter=(${deploymentFilters}), rollup='latest', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'deployment'})`,
        },
        {
            // For union to work we need 'kubernetes_workload_name' to be a dimension on both or None.
            displayName: 'Pod Phase',
            streamLabel: 'PHASE',
            programText: 'PHASE = union(WORKLOAD_PHASE, DEPLOYMENT_PHASE)',
        },
        {
            displayName: 'Pending',
            streamLabel: 'Pending',
            programText: `Pending = PHASE.below(1, inclusive=True).count(by=${aggregationGroup}).publish(label=\'Pending\')`,
            paletteIndex: 6,
        },
        {
            displayName: 'Running',
            streamLabel: 'Running',
            programText: `Running = PHASE.equals(2).count(by=${aggregationGroup}).publish(label=\'Running\')`,
            paletteIndex: 2,
        },
        {
            displayName: 'Succeeded',
            streamLabel: 'Succeeded',
            programText: `Succeeded = PHASE.equals(3).count(by=${aggregationGroup}).publish(label=\'Succeeded\')`,
            paletteIndex: 14,
        },
        {
            displayName: 'Failed',
            streamLabel: 'Failed',
            programText: `Failed = PHASE.equals(4).count(by=${aggregationGroup}).publish(label=\'Failed\')`,
            paletteIndex: 4,
        },
        {
            displayName: 'Unknown',
            streamLabel: 'Unknown',
            programText: `Unknown = PHASE.above(5, inclusive=True).count(by=${aggregationGroup}).publish(label=\'Unknown\')`,
            paletteIndex: 0,
        },
    ];

    return { chartOptions, publishLabelOptions };
}

function getCPUChartModel(time, filters, deploymentFilters) {
    const chartOptions = {
        time,
        name: 'CPU% Pctile over Containers',
        defaultPlotType: 'AreaChart',
    };

    let publishLabelOptions = [
        {
            // sfxagent >= 5.0.0
            displayName: 'Workload System Value',
            streamLabel: 'WORKLOAD_SYS_VALUE',
            programText: `WORKLOAD_SYS_VALUE = data('container_cpu_utilization', filter=(${filters}), rollup='rate', extrapolation='last_value', maxExtrapolations=2).promote(['kubernetes_workload_name'])`,
        },
        {
            // sfxagent < 5.0.0 TODO: Remove when agent 5.0.0 is completely adopted
            displayName: 'Deployment System Value',
            streamLabel: 'DEPLOYMENT_SYS_VALUE',
            programText: `DEPLOYMENT_SYS_VALUE = data('container_cpu_utilization', filter=(${deploymentFilters}), rollup='rate', extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'deployment'})`,
        },
        {
            // For union to work we need 'kubernetes_workload_name' to be a dimension on both or None.
            displayName: 'System Value',
            streamLabel: 'SYS_VALUE',
            programText: 'SYS_VALUE = union(WORKLOAD_SYS_VALUE, DEPLOYMENT_SYS_VALUE)',
        },
    ];

    publishLabelOptions = publishLabelOptions.concat(getDistributionPlots({ valueSuffix: '%' }));

    return { chartOptions, publishLabelOptions };
}

function getMemoryChartModel(time, filters, deploymentFilters) {
    const chartOptions = {
        time,
        name: 'Mem Usage Pctile',
        defaultPlotType: 'AreaChart',
    };

    let publishLabelOptions = [
        {
            // sfxagent >= 5.0.0
            displayName: 'Workload System Value',
            streamLabel: 'WORKLOAD_SYS_VALUE',
            programText: `WORKLOAD_SYS_VALUE = data('container_memory_usage_bytes', filter=(${filters}), extrapolation='last_value', maxExtrapolations=2).promote(['kubernetes_workload_name'])`,
        },
        {
            // sfxagent < 5.0.0 TODO: Remove when agent 5.0.0 is completely adopted
            displayName: 'Deployment System Value',
            streamLabel: 'DEPLOYMENT_SYS_VALUE',
            programText: `DEPLOYMENT_SYS_VALUE = data('container_memory_usage_bytes', filter=(${deploymentFilters}), extrapolation='last_value', maxExtrapolations=2).dimensions(aliases={'kubernetes_workload_name': 'deployment'})`,
        },
        {
            // For union to work we need 'kubernetes_workload_name' to be a dimension on both or None.
            displayName: 'System Value',
            streamLabel: 'SYS_VALUE',
            programText:
                "SYS_VALUE = union(WORKLOAD_SYS_VALUE, DEPLOYMENT_SYS_VALUE).sum(by=['kubernetes_pod_name'])",
        },
    ];

    publishLabelOptions = publishLabelOptions.concat(getDistributionPlots({ valueUnit: 'Byte' }));

    return { chartOptions, publishLabelOptions };
}

function getDistributionPlots({ valueSuffix, valueUnit }) {
    return [
        {
            displayName: 'Maximum',
            streamLabel: 'Maximum',
            programText: "Maximum = SYS_VALUE.max().publish(label='Maximum')",
            valueSuffix,
            valueUnit,
            paletteIndex: 7,
        },
        {
            displayName: '99th Percentile',
            streamLabel: 'P99',
            programText: "P99 = SYS_VALUE.percentile(pct=99).publish(label='P99')",
            valueSuffix,
            valueUnit,
            paletteIndex: 9,
        },
        {
            displayName: '90th Percentile',
            streamLabel: 'P90',
            programText: "P90 = SYS_VALUE.percentile(pct=90).publish(label='P90')",
            valueSuffix,
            valueUnit,
            paletteIndex: 2,
        },
        {
            displayName: '50th Percentile',
            streamLabel: 'P50',
            programText: "P50 = SYS_VALUE.percentile(pct=50).publish(label='P50')",
            valueSuffix,
            valueUnit,
            paletteIndex: 15,
        },
        {
            displayName: 'Minimum',
            streamLabel: 'Minimum',
            programText: "Minimum = SYS_VALUE.min().publish(label='Minimum')",
            valueSuffix,
            valueUnit,
            paletteIndex: 14,
        },
    ];
}
