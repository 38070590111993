import { AccessControlPermissionTypes } from '@splunk/olly-services';
import AccessControlPermissionAction from './AccessControlPermissionAction';

const DASHBOARD_GROUP = {
    label: 'Dashboard group',
    parent: null,
    permissionTypes: [
        AccessControlPermissionTypes.PUBLIC,
        AccessControlPermissionTypes.RESTRICTED_WRITE,
        AccessControlPermissionTypes.RESTRICTED,
    ],
    actions: [
        [AccessControlPermissionAction.READ],
        [AccessControlPermissionAction.READ, AccessControlPermissionAction.WRITE],
    ],
};

const DASHBOARD = {
    label: 'Dashboard',
    parent: DASHBOARD_GROUP,
    permissionTypes: [
        AccessControlPermissionTypes.INHERIT,
        AccessControlPermissionTypes.PUBLIC,
        AccessControlPermissionTypes.RESTRICTED_WRITE,
        AccessControlPermissionTypes.RESTRICTED,
    ],
};

const MIRRORED_DASHBOARD = {
    ...DASHBOARD,
    permissionTypes: [AccessControlPermissionTypes.INHERIT],
};

const MIRROR = {
    label: 'Mirror',
    parent: DASHBOARD_GROUP,
    permissionTypes: [
        {
            ...AccessControlPermissionTypes.INHERIT,
            label: 'Inherit from parent dashboard group',
        },
    ],
    actionLabelMapper: (serialized) => {
        const dict = { 'Read/Write': 'Read/Customize' };
        return dict[serialized] || serialized;
    },
};

const NAMED_TOKEN = {
    label: 'Access Token',
    parent: null,
    permissionTypes: [
        {
            ...AccessControlPermissionTypes.INHERIT,
            label: 'Only Admins can Read',
            iconClass: 'icon-control_admin',
        },
        {
            ...AccessControlPermissionTypes.RESTRICTED,
            label: 'Admins and Select Users or Teams can Read',
            iconClass: 'icon-control_team',
        },
        {
            ...AccessControlPermissionTypes.PUBLIC,
            label: 'Everyone can Read',
        },
    ],
    actionLabelMapper: (serialized) => {
        const dict = { 'Read/Write': 'Read' }; // This is a hack to make it look like its only a read permisison
        return dict[serialized] || serialized;
    },
    actions: [[AccessControlPermissionAction.READ]],
};

export default {
    DASHBOARD_GROUP,
    DASHBOARD,
    MIRROR,
    MIRRORED_DASHBOARD,
    NAMED_TOKEN,
};
