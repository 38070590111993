import dateTimePickerTemplateUrl from './sfdatetimepicker.tpl.html';

angular
    .module('signalview.timePicker')

    .directive('sfDateTimePicker', [
        'timeZoneService',
        function (timeZoneService) {
            return {
                restrict: 'AE',
                scope: {
                    pickedDate: '=',
                    toolTip: '@',
                    minDate: '@',
                    maxDate: '@',
                    dropdownToggled: '=?',
                    hideTimePicker: '=?',
                    isOpen: '=?',
                    header: '@',
                },
                templateUrl: dateTimePickerTemplateUrl,
                link: function ($scope) {
                    $scope.timezone = timeZoneService.getUserTimezoneISOString();
                },
            };
        },
    ]);
