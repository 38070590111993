import { convertToDbTimestamp, isRelativeTimestamp } from '../utils/programArgsUtils';
import templateUrl from './eventStream.tpl.html';

angular.module('signalview.eventsPanel').directive('eventStream', [
    'eventService',
    '$interval',
    '$timeout',
    'chartLoadedEvent',
    function (eventService, $interval, $timeout, chartLoadedEvent) {
        return {
            restrict: 'E',
            scope: {
                query: '=',
                program: '<',
                to: '=',
                from: '=',
                range: '=',
                refreshIntervalms: '@?',
                maxStreamElements: '@?',
                eventColors: '<?',
            },
            templateUrl,
            link: function ($scope, element) {
                const limit = 50;
                let offset = 0;
                const refreshInterval = $scope.refreshIntervalms || 30000;

                $scope.isLoading = false;
                // stores recent events if user has scrolled away from top
                $scope.recentEvents = [];

                let latestRequest;
                function loadMoreEvents() {
                    $scope.isLoading = true;

                    const request = {
                        query: $scope.query,
                        programToQuery: $scope.program || null,
                        limit: limit,
                        offset: offset,
                    };

                    if ($scope.to !== undefined) {
                        request.endTime = convertToDbTimestamp($scope.to);
                    }
                    if ($scope.from !== undefined) {
                        request.startTime = convertToDbTimestamp($scope.from);
                    }
                    if ($scope.range !== undefined && $scope.range !== null) {
                        request.startTime = Date.now() - Math.abs($scope.range);
                    }

                    const promise = eventService.v2search(request).then(function (results) {
                        // If we're in a stale promise discard the results
                        if (promise !== latestRequest) {
                            return;
                        }

                        $scope.isLoading = false;
                        $scope.events = $scope.events.concat(results);
                        offset += results.length;
                    });

                    latestRequest = promise;
                    return promise;
                }

                $scope.loadMoreEvents = loadMoreEvents;

                let refresh;
                let scrollCheckSet;
                let userScrolled;
                function resetEvents() {
                    offset = 0;
                    scrollCheckSet = false;
                    userScrolled = false;
                    $scope.events = [];
                    $scope.recentEvents = [];
                    $scope.spliceCount = 0;
                    $interval.cancel(refresh);
                    refresh = null;
                    loadMoreEvents().then(function () {
                        // if timeframe for stream is relative, periodically fetch most recent events
                        const noTimeFrame = !($scope.to || $scope.from);
                        const isRelativeTimeFrame =
                            isRelativeTimestamp($scope.to) || isRelativeTimestamp($scope.from);
                        if (!refresh && (noTimeFrame || isRelativeTimeFrame)) {
                            refresh = $interval(fetchRecentEvents, refreshInterval);
                        }
                        getStreamElement();
                        chartLoadedEvent();
                    });
                }

                function fetchRecentEvents() {
                    // create new query whose timestamp is most recent existing event +1 to relative end
                    const toTs = convertToDbTimestamp($scope.to);
                    let fromTs = 0;
                    if ($scope.recentEvents.length) {
                        fromTs = $scope.recentEvents[0].timestamp + 1;
                    } else if ($scope.events.length) {
                        fromTs = $scope.events[0].timestamp + 1;
                    } else {
                        fromTs = toTs - 2 * refreshInterval;
                    }

                    let query = '';
                    if ($scope.query) {
                        query = $scope.query;
                    }

                    const request = {
                        query: query,
                        startTime: fromTs,
                        endTime: toTs,
                        programToQuery: $scope.program,
                        orderBy: '-sf_timestamp',
                    };

                    // fetch recent events and prepend to events array
                    eventService.v2search(request).then(function (results) {
                        if (!results.length) {
                            return;
                        }

                        const totalLength =
                            results.length + $scope.events.length + $scope.recentEvents.length;
                        const maxCount = $scope.maxStreamElements || 500;
                        if (totalLength > maxCount) {
                            resetEvents();
                            return;
                        }
                        offset += results.length;

                        // if event stream was empty to begin with, let's just add recent events
                        // to the events stream
                        if (!$scope.events.length) {
                            $scope.events = $scope.events.concat(results);
                        } else {
                            if (userScrolled) {
                                // store events for deferred update, when user clicks on notification badge
                                $scope.recentEvents = $scope.recentEvents.concat(results);
                            } else {
                                // if user did not scroll, just add them to the top of event stream
                                // since it's most recent timestamp-wise
                                flushRecentToStream(results, true);
                            }
                        }
                    });
                }

                function getStreamElement() {
                    const tiles = angular.element(element[0]).find('.events-tiles');
                    if (tiles && tiles.length) {
                        const tilesElement = tiles[0];
                        if (!scrollCheckSet) {
                            scrollCheckSet = true;
                            angular.element(tilesElement).bind('scroll', function () {
                                userScrolled = true;
                            });
                        }
                        return tilesElement;
                    } else {
                        return null;
                    }
                }

                $scope.showRecentEvents = function (autoScroll) {
                    flushRecentToStream($scope.recentEvents, autoScroll);
                    $scope.recentEvents = [];
                };

                $scope.spliceCount = 0;
                function flushRecentToStream(recent, autoScroll) {
                    recent.sort(function (a, b) {
                        return a.timestamp - b.timestamp;
                    });
                    recent.forEach(function (event) {
                        $scope.events.unshift(event);
                    });
                    if (autoScroll) {
                        const stream = getStreamElement();
                        if (stream) {
                            // this seems most effective
                            angular.element(stream).animate(
                                {
                                    scrollTop: 0,
                                },
                                500
                            );
                        }
                    }
                    // trimming off end of stream because some events
                    // are so frequent, we could have an endless stream
                    // slowing down the ui (as observed)
                    const maxCount = $scope.maxStreamElements || 500;
                    if ($scope.events.length > maxCount) {
                        const toRemove = $scope.events.length - maxCount;
                        $scope.events.splice(maxCount, toRemove);
                        offset -= toRemove;
                        $scope.spliceCount++;
                    }
                }

                $scope.$watchGroup(['query', 'from', 'to', 'program'], resetEvents, true);

                $scope.$on('$destroy', function () {
                    $interval.cancel(refresh);
                });

                function resetEventsDelayed() {
                    $timeout(resetEvents, 2000);
                }

                function refreshStreamIfDirty(unused, data) {
                    if (!data || !data.id) return;
                    function hasModifiedEvent(arrayElement) {
                        return arrayElement.id === data.id;
                    }
                    if (
                        $scope.events.some(hasModifiedEvent) ||
                        $scope.recentEvents.some(hasModifiedEvent)
                    ) {
                        resetEventsDelayed();
                    }
                }
                $scope.$on('delete global event', refreshStreamIfDirty);
                $scope.$on('edit global event', refreshStreamIfDirty);
                $scope.$on('new global event', resetEventsDelayed);
            },
        };
    },
]);
