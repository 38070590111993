angular.module('signalview.typeahead').service('typeaheadUtils', [
    function () {
        function ParsedPartial(partial) {
            const splitPartial = partial.split(':');
            const isValueSuggest = splitPartial.length > 1;
            let propertyName = splitPartial[0] || '';
            let isNot = false;
            if (propertyName.indexOf('!') === 0) {
                isNot = true;
                propertyName = propertyName.substring(1);
            }
            this.not = isNot;
            this.propertyName = propertyName;
            this.valueSuggest = isValueSuggest;
            this.propertyValue = splitPartial.splice(1).join(':');
        }

        ParsedPartial.prototype.isNot = function () {
            return this.not;
        };

        ParsedPartial.prototype.getProperty = function () {
            return this.propertyName;
        };

        ParsedPartial.prototype.getValue = function () {
            return this.propertyValue;
        };

        ParsedPartial.prototype.isValueSuggest = function () {
            return this.valueSuggest;
        };

        function parsePartial(partial) {
            return new ParsedPartial(partial);
        }

        function sort(filters, keys) {
            const keyMap = {};
            (keys || []).forEach(function (key) {
                keyMap[key] = true;
            });

            filters.sort(function (a, b) {
                if (!a.isSynthetic && b.isSynthetic) {
                    return -1;
                } else if (a.isSynthetic && !b.isSynthetic) {
                    return 1;
                }

                if (keyMap[a.property] && !keyMap[b.property]) {
                    return -1;
                } else if (keyMap[b.property] && !keyMap[a.property]) {
                    return 1;
                }
                const valueA = ((a || {}).value || '').toLowerCase();
                const valueB = ((b || {}).value || '').toLowerCase();
                return valueA.localeCompare(valueB);
            });
        }

        return {
            parsePartial,
            sort,
        };
    },
]);
