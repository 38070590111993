import templateUrl from './facet.tpl.html';

export default {
    templateUrl,
    bindings: {
        addFilter: '<',
        loadingFacets: '<',
    },
    controller: [
        '$scope',
        'filterSelectModal',
        'METRIC_CATALOG_EVENTS',
        'METRICS_CATALOG_CONSTANTS',
        function ($scope, filterSelectModal, METRIC_CATALOG_EVENTS, METRICS_CATALOG_CONSTANTS) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;

            $ctrl.showAllFacets = showAllFacets;

            $ctrl.GA_PREFIX = 'metrics-finder-facets-sidebar';

            let activeMetrics;
            let searchQuery;
            let searchFilters;
            let facets;
            let customCategories;
            let facetsMap = {};

            function $onInit() {
                // we want this to initialize so upon a user's first search and reload, loading spinner appears
                $ctrl.facetResults = [];
                $ctrl.showFacetResult = true;
            }

            // on facet refresh, we want to clear results to indicate a re-fetch is happening
            $scope.$on(METRIC_CATALOG_EVENTS.FACETS_REFRESH_START, function () {
                $ctrl.facetResults = [];
                $ctrl.showFacetResult = true;
            });

            $scope.$on(
                METRIC_CATALOG_EVENTS.REFRESH_FACETS,
                function (
                    event,
                    query,
                    filters,
                    isActiveMetricsOnly,
                    facetResult,
                    customCategoriesResult
                ) {
                    $ctrl.showFacetResult = true;
                    activeMetrics = isActiveMetricsOnly;
                    searchQuery = query;
                    searchFilters = filters;
                    facets = facetResult || [];
                    customCategories = customCategoriesResult || [];
                    $ctrl.facetResults = customCategories.concat(facets);
                    facetsMap = getFacetsMap();
                }
            );

            $scope.$on(METRIC_CATALOG_EVENTS.EMPTY_METRIC_RESULTS, function () {
                $ctrl.showFacetResult = false;
            });

            function getFacetsMap() {
                const propertyValues = {};
                for (let i = 0; i < $ctrl.facetResults.length; i++) {
                    const facet = $ctrl.facetResults[i];
                    propertyValues[facet.property] = facet.values;
                }
                return propertyValues;
            }

            function showAllFacets(name, key) {
                const title = `${METRICS_CATALOG_CONSTANTS.FILTER_MODAL_TITLE} ${name}`;

                const queryInfo = {
                    key: key,
                    query: searchQuery,
                    filters: searchFilters,
                    isActiveMetricsOnly: activeMetrics,
                    values: facetsMap[key],
                };

                return filterSelectModal(title, queryInfo, $ctrl.GA_PREFIX).then((result) => {
                    $ctrl.addFilter(result);
                }, angular.noop);
            }
        },
    ],
};
