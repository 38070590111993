import templateUrl from './orgUsage.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

angular
    .module('signalview.orgUsage')

    .component('orgUsage', {
        templateUrl,
        bindings: {
            orgId: '<',
            editable: '<',
        },
        controller: [
            '$scope',
            '$location',
            'orgSubscriptionService',
            'organizationService',
            'subscriptionTypeUtil',
            'currentUser',
            'featureEnabled',
            'rbacUtilsService',
            function (
                $scope,
                $location,
                orgSubscriptionService,
                organizationService,
                subscriptionTypeUtil,
                currentUser,
                featureEnabled,
                rbacUtilsService
            ) {
                const ctrl = this;

                ctrl.$onInit = $onInit;

                ctrl.newUsageBillingEnabled = featureEnabled('newUsageBilling');
                ctrl.apm2Enabled = featureEnabled('apm2');
                ctrl.logsEnabled = featureEnabled('logs');
                ctrl.newRbacExperience = featureEnabled('newRbacExperience');

                function $onInit() {
                    checkCapabilityAndRedirect();
                    organizationService
                        .get()
                        .then((org) => {
                            ctrl.subscriptionSvc = orgSubscriptionService.get(org);
                            ctrl.accountPromise = ctrl.subscriptionSvc.getAccount();
                            ctrl.nonLegacyOrg = subscriptionTypeUtil.isNonLegacy(
                                org.accountSubscriptionType
                            );
                            ctrl.hostBasedOrg = subscriptionTypeUtil.isHostBased(
                                org.accountSubscriptionType
                            );
                        })
                        .finally(() => {
                            $scope.$applyAsync();
                        });

                    userHasSeenTokenLimit();
                }

                function checkCapabilityAndRedirect() {
                    const newRbacExperienceEnabled = featureEnabled('newRbacExperience');
                    if (newRbacExperienceEnabled) {
                        rbacUtilsService.checkCapabilityAndRedirect(Capability.READ_USAGE);
                    } else if (!ctrl.editable) {
                        $location.replace().url('/NotFound');
                    }
                }

                function userHasSeenTokenLimit() {
                    const userSeenDate = new Date().getTime();
                    currentUser
                        .preferences()
                        .then(function (prefs) {
                            if (prefs) {
                                return currentUser.updatePreferences({
                                    sf_orgBillingPageLastViewed: userSeenDate,
                                    sf_id: prefs.sf_id,
                                });
                            }
                        })
                        .then(() => {
                            $scope.$emit('refresh hazard icon');
                        });
                }
            },
        ],
    });
