import qs from 'query-string';
import { isDashboardTimeWindowSelected } from '../../../app/utils/programArgsUtils';
import { detectorTypeResolver } from '../../../../app/alerts/detectorTypeResolver';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { Monitoring } from '../../../../app/routing/Sitemaps';
import templateUrl from './detectorV2SignalFlowEditor.tpl.html';

export default {
    bindings: {
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl,
    controller: [
        '$scope',
        'title',
        'v2DetectorResolverUtil',
        'DetectorV2SearchService',
        'featureEnabled',
        function ($scope, title, v2DetectorResolverUtil, DetectorV2SearchService, featureEnabled) {
            const detectorId = ngRoute.params.id;
            const incidentId = ngRoute.params.incidentId;
            const $ctrl = this;

            $ctrl.$onDestroy = function () {
                this.setHierarchicalNav();
            };

            v2DetectorResolverUtil.getDetector(detectorId).then(async (detector) => {
                if (
                    !ngRoute.params.detectorSignalFlowEditor &&
                    !ngRoute.params.forceClassicWizard &&
                    (await isSupportedByDetectorPlatform(detectorId))
                ) {
                    handleDetectorPlatformRedirect();
                    return;
                }

                if (isDashboardTimeWindowSelected(detector.programText))
                    return prepareDetectorSignalflowViewData(detector);

                // conversion to sf_uiModel trial
                v2DetectorResolverUtil.setupDetectorModelForEditor(detector).then((data) => {
                    if (data.editor === 'signalflow') {
                        return prepareDetectorSignalflowViewData(detector);
                    }
                    if (ngRoute.params.detectorSignalFlowEditor) {
                        return prepareDetectorSignalflowViewData(detector, {
                            canEditInRichEditor: true,
                        });
                    }
                    return prepareDetectorRichViewData(data.detector);
                });
            });

            const isSupportedByDetectorPlatform = async (detectorId) => {
                return DetectorV2SearchService.search({ detectorIds: [detectorId] })
                    .then((res) => (Array.isArray(res.rs) ? res.rs[0] : undefined))
                    .then(
                        (detector) =>
                            detectorTypeResolver.isSupportedByDetectorPlatform(detector) ||
                            (featureEnabled('detectorsPlatformApmDetector') &&
                                detectorTypeResolver.isApmDetector(detector))
                    );
            };

            const handleDetectorPlatformRedirect = () => {
                ngRoute.history.replace({
                    pathname: `/detector-wizard/${detectorId}/edit`,
                    search: qs.stringify(ngRoute.search),
                });
            };

            function prepareDetectorRichViewData(detector) {
                return v2DetectorResolverUtil.getDetectorRichViewData(detector).then((data) => {
                    $scope.editor = 'rich';
                    $scope.data = {
                        data: {
                            detector: data.detector,
                            mutings: data.mutings,
                        },
                        updateTitle: title.updateTitle,
                    };
                    $ctrl.setCustomContent(Monitoring.name, {
                        id: Monitoring.IDs.editDetector,
                        label: `Detector: ${data.detector.sf_detector || 'Untitled'}`,
                    });
                    $ctrl.setHierarchicalNav(Monitoring.name, Monitoring.IDs.editDetector);
                });
            }

            function prepareDetectorSignalflowViewData(detector, viewData = {}) {
                return v2DetectorResolverUtil
                    .getDetectorSignalflowViewData(detector, incidentId)
                    .then((data) => {
                        // Once signalflow editor is rendered we need to make sure
                        // it will be default view for a specific detector unless browser tab was closed.
                        // This gives us a chance to avoid view switch (SignalFlow editor / Rich UI editor)
                        // after modifiyng detector program text from not renderable to renderable in rich UI
                        ngRoute.history.replace({
                            pathname: ngRoute.pathname,
                            search: qs.stringify({
                                ...ngRoute.search,
                                detectorSignalFlowEditor: 1,
                            }),
                        });

                        $scope.editor = 'signalflow';
                        $scope.data = {
                            updateTitle: title.updateTitle,
                            mutings: data.mutings,
                            incident: data.incident,
                            detectorObject: {
                                detector: data.detector,
                                plotLabels: data.plotsData.plotLabels,
                                plots: data.plotsData.plots,
                            },
                            ...viewData,
                        };
                    });
            }
        },
    ],
};
