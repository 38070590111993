angular
    .module('signalboost')

    .service('signalboost', [
        function () {
            // In order to avoid circular dependencies the service is initialized as
            // an empty object and passed into its various subservices and then into
            // signalboostBuilder.
            return {
                types: [],
            };
        },
    ]);
