import templateUrl from './percentilePicker.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

angular.module('metadataFunctionInputs').component('percentilePicker', {
    templateUrl,
    bindings: {
        model: '<',
        definition: '<',
        validationError: '<',
        onTypedUpdate: '&',
    },
    controller: [
        function () {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            $ctrl.onSelectValue = onSelectValue;

            function $onInit() {
                $ctrl.options = safeLookup($ctrl, 'definition.dataType.values') || [];

                onSelectValue($ctrl.model);
            }

            function $onChanges(changesObj) {
                const { model: modelChange } = changesObj;

                if (modelChange) {
                    updateSelectedValueAndisValid(modelChange.currentValue);
                }
            }

            function onSelectValue(value) {
                updateSelectedValueAndisValid(value);

                $ctrl.onTypedUpdate({
                    value: $ctrl.selectedValue,
                    validity: $ctrl.isValid,
                });
            }

            function updateSelectedValueAndisValid(value) {
                $ctrl.selectedValue = value;

                $ctrl.isValid = typeof value === 'number' && value >= 0 && value <= 100;
            }
        },
    ],
});
