import { ngRoute } from '../../../../app/routing/ngRoute';

angular
    .module('signalview.snapshot')

    .service('snapshotRouteDataService', [
        '$location',
        'snapshotLoadService',
        'v2ChartService',
        function ($location, snapshotLoadService, v2ChartService) {
            function getData() {
                const searchParams = $location.search();
                let chartId = searchParams.chartId;
                const dashboardId = searchParams.toDashboard || searchParams.fromDashboard;
                const fromChart = searchParams.fromChart;
                let snapshotId = ngRoute.params.snapshotID;

                let chart;
                let clone = false;
                if (snapshotId === 'new') {
                    if (dashboardId) {
                        snapshotId = dashboardId;
                        if (chartId) {
                            clone = true;
                        } else {
                            const chartMode = ngRoute.params.mode;

                            chart = v2ChartService.getEmptyV2Chart(chartMode);
                            chartId = -1;
                        }
                    } else {
                        snapshotId = fromChart;
                        clone = true;
                    }
                }

                return snapshotLoadService(snapshotId).then(function (snapshot) {
                    const data = angular.copy(snapshot.payload);
                    if (!chart && snapshot.type === 'Dashboard') {
                        chart = (data.charts || []).filter(function (c) {
                            return c.sf_id ? c.sf_id === chartId : c.id === chartId;
                        })[0];
                        if (!chart) {
                            $location.path('/temp/dashboard/' + snapshot.id);
                            return;
                        }
                    } else if (!chart && snapshot.type === 'Chart') {
                        chart = data.chart;
                    }
                    return {
                        dashboard: data.dashboard,
                        chart: chart,
                        snapshot: {
                            id: snapshot.id,
                            type: snapshot.type,
                            chartId: chartId,
                            sourceId: snapshot.type === 'Chart' ? snapshot.sourceId : null,
                            savedFilters: data.savedFilters,
                            expiryMs: snapshot.expiryMs,
                            creator: snapshot.creator,
                            created: snapshot.created,
                            lastUpdatedBy: snapshot.lastUpdatedBy,
                            lastUpdated: snapshot.lastUpdated,
                            payload: snapshot.payload,
                            sender: snapshot.payload.originalSender || snapshot.sender,
                        },
                        cloneFromSnapshot: clone,
                    };
                });
            }

            return {
                getData: getData,
            };
        },
    ]);
