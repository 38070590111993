'use strict';

angular.module('sfx.data').provider('sfxApi', [
    'window',
    function (window) {
        const schema = window.API_SCHEMA;
        let auth;

        // Override the base path
        this.basePath = function (basePath) {
            schema.apis.forEach(function (api) {
                api.apiDeclaration.basePath = basePath;
            });
        };

        this.auth = function (authToken) {
            auth = authToken;
        };

        this.$get = [
            'swaggerClient',
            function (swaggerClient) {
                const api = swaggerClient(schema);
                api.authorization(auth);
                return api;
            },
        ];
    },
]);
