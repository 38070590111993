angular.module('signalview.heatmap').service('heatmapUtilsService', [
    'KUBE_INFRA_NAV_ID',
    'imageService',
    'mustache',
    'plotToSignalflowV2',
    function (KUBE_INFRA_NAV_ID, imageService, mustache, plotToSignalflowV2) {
        const HEATMAP_MODE_ICONS = {
            azurefunctionssignalfx: 'azurefunctions',
            autoscaling: 'awsautoscaling',
            cloudfront: 'awscloudfront',
            elasticache: 'awselasticache',
            elb: 'awselb',
            'AWS instances': 'awsec2',
            lambda: 'awslambda',
            'rds databases': 'awsrds',
            sns: 'awssns',
            'sqs queues': 'awssqs',
            'Docker containers': 'docker',
            'gcp appengine': 'googleappengine',
            'gcp bigtable': 'googlecloudbigtable',
            'gcp cloudfunctions': 'googlecloudfunctions',
            'gcp storage': 'googlecloudstorage',
            'gcp compute': 'googlecomputeengine',
            'gcp containerengine': 'googlecontainerengine',
            'gcp pubsub subscription': 'googlecloudpubsub',
            'gcp pubsub topic': 'googlecloudpubsub',
            'gcp spanner': 'googlecloudspanner',
            'gke-overview': 'googlekubernetesengine',
            'gke-nodes': 'googlekubernetesengine',
            'gke-pods': 'googlekubernetesengine',
            'kubernetes containers': 'kubernetes',
            'kubernetes nodes': 'kubernetes',
            'kubernetes pods': 'kubernetes',
            k8snodes: 'kubernetes',
            k8sworkloads: 'kubernetes',
            'marathon application instances': 'marathon',
            'mesos agents': 'mesos',
            'mesos services': 'mesos',
            'cf cloud controller': 'cloudfoundry',
            'cf containers': 'cloudfoundry',
            'cf diego': 'cloudfoundry',
            'cf instances': 'cloudfoundry',
            'cf logging': 'cloudfoundry',
            'cf other services': 'cloudfoundry',
            'cf service brokers': 'cloudfoundry',
            'consul connect meshes': 'consul',
            'consul connect services': 'consul',
            'telegraf hosts': 'telegraf',
            'telegraf hosts windows': 'windows',
            'windows hosts': 'windows',
        };
        HEATMAP_MODE_ICONS[KUBE_INFRA_NAV_ID] = 'kubernetes';

        function getModeIcon(modeId) {
            const service = HEATMAP_MODE_ICONS[modeId] || modeId;
            return imageService.getServiceIconURL(service);
        }

        function sortAndCategorizeModes(modesIn) {
            const categorizedModes = {};
            modesIn.forEach(function (mode) {
                if (categorizedModes[mode.category] === undefined) {
                    categorizedModes[mode.category] = [];
                }

                categorizedModes[mode.category].push(mode);
            });

            return categorizedModes;
        }

        /**
         * Convert the navigator entity metric (ColorBy) job filters (metric.job.filters) to the PropertyFilter
         * format for use in SignalFlow. The PropertyFilter format uses property of "NOT" vs "not"
         * @param filters
         */
        function convertEntityMetricFiltersToPropertyFilters(filters) {
            return filters?.map((filter) => ({
                ...filter,
                NOT: Boolean(filter.not),
            }));
        }

        function colorByToSignalFlow(colorBys, filterBy, timeRange) {
            return colorBys
                .map(function (colorBy) {
                    const varName = colorBy.job.varName;
                    const signalTextToStream = mustache.render(colorBy.job.template, {
                        filter: plotToSignalflowV2.filters(
                            filterBy.concat(
                                convertEntityMetricFiltersToPropertyFilters(colorBy.job.filters)
                            )
                        ),
                        timeRange, // this is used for "over" arguments
                    });
                    return (
                        signalTextToStream +
                        '\n' +
                        varName +
                        '.publish("' +
                        colorBy.displayName +
                        '")'
                    );
                })
                .join('\n');
        }

        return {
            getModeIcon: getModeIcon,
            sortAndCategorizeModes: sortAndCategorizeModes,
            colorByToSignalFlow,
            convertEntityMetricFiltersToPropertyFilters,
        };
    },
]);
