import { chartTooltip } from './chartTooltip.js';
import { tableCell } from './tableCell.js';

import uiGrid from 'angular-ui-grid';

angular
    .module('signalview.chartTooltip', [
        uiGrid,
        'ui.grid.pinning',
        'ui.grid.resizeColumns',
        'ui.grid.autoResize',
        'ui.grid.exporter',
        'signalview.chartSorter',
        'signalview.chartdisplay.shared',
        'signalview.detector',
    ])
    .directive('chartTooltip', chartTooltip)
    .component('tableCell', tableCell);
