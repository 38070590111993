import templateUrl from './detectorV2Rule.tpl.html';

angular.module('signalview.detectorV2').directive('v2DetectorRule', [
    'ALERT_SEVERITY_LEVELS',
    'v2DetectorWizardModal',
    'moment',
    function (ALERT_SEVERITY_LEVELS, v2DetectorWizardModal, moment) {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                rule: '=',
                isNewRule: '=',
                labels: '=?',
                reassign: '=?',
                alertCount: '=',
                detector: '=?',
                openSignalflowTab: '=',
                model: '=?',
                hasWritePermission: '<?',
                onToggleRule: '&',
                isSelected: '<?',
                fetchActiveAlerts: '&fetchActiveAlerts',
            },
            link: function ($scope) {
                $scope.severityLevels = ALERT_SEVERITY_LEVELS;
                $scope.edit = edit;

                const isNewRule = !$scope.detector.rules.some(
                    (rule) => rule.detectLabel === $scope.rule.detectLabel
                );

                $scope.isLoadingAlertsCount = () => $scope.alertCount?.isLoading === true;
                $scope.isAlertsCountUnknown = () =>
                    typeof $scope.alertCount?.count !== 'number' && !isNewRule;
                $scope.activeAlertsOutdated = () => $scope.alertCount?.outdated === true;
                $scope.getTooltipText = () => {
                    let text = 'Active alerts for this rule';
                    if ($scope.alertCount?.timestamp) {
                        text += ` (last refresh: ${moment($scope.alertCount.timestamp).format(
                            'HH:mm:ss'
                        )})`;
                    }
                    return text;
                };

                function edit() {
                    v2DetectorWizardModal.editRule(
                        $scope.detector,
                        $scope.rule,
                        $scope.isNewRule,
                        $scope.model,
                        $scope.hasWritePermission
                    );
                }
            },
        };
    },
]);
