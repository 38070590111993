/* eslint no-unused-vars: off */
import templateUrl from './eventPanelCustomTile.tpl.html';

angular.module('signalview.eventsPanel').directive('eventPanelCustomTile', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                event: '=',
                eventColors: '<?',
            },
            templateUrl,
            link: {
                pre: function ($scope) {
                    let maximumCount = 5;
                    const event = $scope.event;

                    /**
                     * Fill target with up to remaining properties from the source object,
                     * taken from the given list of property keys.
                     */
                    function fillProperties(source, keys, target, remaining) {
                        source = source || {};
                        keys = keys || [];
                        for (let i = 0; i < keys.length; i++) {
                            if (remaining === 0) {
                                break;
                            }

                            const key = keys[i];
                            // sf_eventType and properties already on the target are ignored
                            if (key === 'sf_eventType' || target[key]) {
                                continue;
                            }

                            target[key] = source[key];
                            remaining--;
                        }
                        return remaining;
                    }

                    const properties = {};
                    maximumCount = fillProperties(
                        event.metadata,
                        event.metadata.sf_key,
                        properties,
                        maximumCount
                    );
                    maximumCount = fillProperties(
                        event.properties,
                        Object.keys(event.properties),
                        properties,
                        maximumCount
                    );

                    $scope.properties = properties;
                    $scope.displayName = (
                        (event.properties && event.properties.sf_displayName) ||
                        event.metadata.sf_displayName ||
                        event.metadata.sf_eventType ||
                        ''
                    ).substring(0, 512);
                },
            },
        };
    },
]);
