angular.module('sfx.ui').directive('sfxIntegrationCommaSeparatedInputPattern', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            ngModel.$parsers.unshift(function (value) {
                const regexp = scope.$eval(attrs.sfxIntegrationCommaSeparatedInputPattern);

                if (!regexp) {
                    return value;
                }

                if (!(regexp instanceof RegExp)) {
                    console.error('sfxIntegrationCommaSeparatedInputPattern expects a RegExp.');
                    return value;
                }

                const isValid = value
                    .split(',')
                    .map((str) => str.trim())
                    .filter((str) => str)
                    .every((str) => str.match(regexp));
                ngModel.$setValidity('pattern', isValid);

                return value;
            });
        },
    };
});
