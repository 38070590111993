export default [
    '$scope',
    'model',
    'featureEnabled',
    function ($scope, model, featureEnabled) {
        initialize();

        function cancel() {
            $scope.$dismiss();
        }

        function save() {
            $scope.$close(model);
        }

        function initialize() {
            $scope.model = model;
            $scope.cancel = cancel;
            $scope.save = save;
            $scope.writePermissionsEnabled = featureEnabled('writepermissions');
        }
    },
];
