import { useEffect, useRef } from 'react';

type IntervalFunction = () => unknown | void;

function useInterval(callback: IntervalFunction, delay: number | null): void {
    const savedCallback = useRef<IntervalFunction | null>(null);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick(): void {
            if (savedCallback.current !== null) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return (): void => clearInterval(id);
        }
        return undefined; // to please the linter
    }, [delay]);
}

export default useInterval;
