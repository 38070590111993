import { PRODUCT_NAME } from '../../../../common/consts';

angular
    .module('signalview.orgIntegrations')
    .service('TYPE_TO_SERVICE_INTEGRATIONS', [
        'BUSINESS_PROCESS_INTEGRATIONS',
        function (BUSINESS_PROCESS_INTEGRATIONS) {
            const credentialsTypeToServiceMap = {};
            Object.keys(BUSINESS_PROCESS_INTEGRATIONS).forEach(function (key) {
                credentialsTypeToServiceMap[BUSINESS_PROCESS_INTEGRATIONS[key].credType] =
                    BUSINESS_PROCESS_INTEGRATIONS[key].name;
            });
            return credentialsTypeToServiceMap;
        },
    ])
    .constant('BUSINESS_PROCESS_INTEGRATIONS', {
        aws: {
            name: 'aws',
            credType: 'AWSCloudWatch',
            title: 'Amazon Web Services',
            shortDescription: 'Sync AWS data and CloudWatch metrics',
            longDescription: `Connect your Amazon Web Services account to sync AWS metadata (like tags and instance names) to dimensions in ${PRODUCT_NAME}. Enable CloudWatch syncing to view CloudWatch system metrics in ${PRODUCT_NAME}.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=connect.sync.aws&version=current',
        },
        pagerduty: {
            name: 'pagerduty',
            credType: 'PagerDuty',
            title: 'PagerDuty',
            shortDescription: 'Send alerts through PagerDuty',
            longDescription: `Integrate PagerDuty with ${PRODUCT_NAME}, then configure your detectors to open PagerDuty incidents when alerts are triggered. Use multiple PagerDuty integrations to map your ${PRODUCT_NAME} alerts to different escalation policies.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.pagerduty&version=current',
        },
        onelogin: {
            name: 'onelogin',
            title: 'OneLogin',
            credType: 'OneLogin',
            shortDescription: `Log into ${PRODUCT_NAME} with OneLogin`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} using OneLogin.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=sso.onelogin&version=current',
        },
        okta: {
            name: 'okta',
            title: 'Okta',
            credType: 'Okta',
            shortDescription: `Log into ${PRODUCT_NAME} with Okta`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} using Okta.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=sso.okta&version=current',
        },
        newrelic: {
            name: 'newrelic',
            title: 'New Relic',
            credType: 'NewRelic',
            shortDescription: 'Sync NewRelic metrics',
            longDescription: `Connect your NewRelic account to sync your NewRelic application performance metrics to ${PRODUCT_NAME}.`,
            url: 'https://support.signalfx.com/hc/en-us/articles/206873596',
        },
        servicenow: {
            name: 'servicenow',
            title: 'ServiceNow',
            credType: 'ServiceNow',
            shortDescription: 'Post alerts to ServiceNow',
            longDescription: `Integrate ServiceNow with ${PRODUCT_NAME}, then configure your detectors to send alert notifications to ServiceNow.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.servicenow&version=current',
        },
        slack: {
            name: 'slack',
            title: 'Slack',
            credType: 'Slack',
            shortDescription: 'Post alerts to Slack',
            longDescription: `Integrate Slack with ${PRODUCT_NAME}, then configure your detectors to post alerts to your Slack channel.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.slack&version=current',
        },
        victorops: {
            name: 'victorops',
            title: 'Splunk On-Call',
            credType: 'VictorOps',
            shortDescription: 'Send alerts through Splunk On-Call',
            longDescription: `Integrate Splunk On-Call with ${PRODUCT_NAME}, then configure your detectors to send alerts to Splunk On-Call.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.splunkoncall&version=current',
        },
        googleauth: {
            name: 'googleauth',
            title: 'Google Sign-In',
            credType: 'googleauth',
            shortDescription: `Log into ${PRODUCT_NAME} with Google Sign-In`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} using Google credentials for your domain.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=configure.googlesignin&version=current',
        },
        pingone: {
            name: 'pingone',
            title: 'PingOne',
            credType: 'PingOne',
            shortDescription: `Log into ${PRODUCT_NAME} with PingOne`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} with PingOne.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=configure.pingone&version=current',
        },
        adfs: {
            name: 'adfs',
            title: 'ADFS',
            credType: 'ADFS',
            shortDescription: `Log into ${PRODUCT_NAME} using ADFS`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} with ADFS.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=configure.adfs&version=current',
        },
        saml: {
            name: 'saml',
            title: 'SAML',
            credType: 'Saml',
            shortDescription: `Develop SAML integration with ${PRODUCT_NAME}`,
            longDescription: `This generic SAML SSO integration is provided by ${PRODUCT_NAME} for development and testing of new SAML SSO connections to ${PRODUCT_NAME}. ${PRODUCT_NAME} does not endorse the security or authenticity of any provider that this integration is configured to access.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=develop.saml.integration&version=current',
        },
        webhook: {
            name: 'webhook',
            title: 'Webhook',
            credType: 'Webhook',
            shortDescription: `Send alerts from ${PRODUCT_NAME} via webhooks`,
            longDescription:
                'Set up shared webhooks that are reusable across notification policies.',
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=webhook.notifications.learnmore&version=current',
        },
        xmatters: {
            name: 'xmatters',
            title: 'xMatters',
            credType: 'XMatters',
            shortDescription: `Send alerts from ${PRODUCT_NAME} via xMatters`,
            longDescription: `Integrate xMatters with ${PRODUCT_NAME}, then configure your detectors to route actionable alert data to xMatters. xMatters leverages your group on-call schedules and rotations, escalation rules, and user device preferences to quickly engage the right resources with customizable response and collaboration options.`,
            url: 'https://help.xmatters.com/integrations/monitoring/signalfx.htm?cshid=SIGNALFX',
        },
        gcp: {
            name: 'gcp',
            title: 'GCP',
            credType: 'GCP',
            shortDescription: 'Sync GCP metrics',
            longDescription: 'Connect your Google Cloud Platform account to sync metadata',
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=connect.gcp&version=current',
        },
        azure: {
            name: 'Azure',
            title: 'Azure',
            credType: 'Azure',
            shortDescription: 'Sync Azure metrics',
            longDescription: 'Connect your Microsoft Azure account to monitor Azure services',
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=connect.azure&version=current',
        },
        googleSaml: {
            name: 'googleSaml',
            title: 'Google Cloud Identity (Deprecated)',
            credType: 'GoogleSaml',
            shortDescription: `Log into ${PRODUCT_NAME} with Google Cloud Identity`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} with Google Cloud Identity. Deprecated as it cause problems with Google Sign-In`,
            url: '',
        },
        googleCloudIdentity: {
            name: 'googleCloudIdentity',
            title: 'Google Cloud Identity',
            credType: 'GoogleCloudIdentity',
            shortDescription: `Log into ${PRODUCT_NAME} with Google Cloud Identity`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} with Google Cloud Identity.`,
            url: '',
        },
        azureAd: {
            name: 'azureAd',
            title: 'Microsoft Entra ID',
            credType: 'AzureAD',
            shortDescription: `Log into ${PRODUCT_NAME} with Microsoft Entra ID`,
            longDescription: `Enable your users to log into ${PRODUCT_NAME} with Microsoft Entra ID.`,
            url: '',
        },
        bigpanda: {
            name: 'bigpanda',
            title: 'BigPanda',
            credType: 'BigPanda',
            shortDescription: 'Send alerts through BigPanda',
            longDescription: `Integrate BigPanda with ${PRODUCT_NAME}, then configure your detectors to send alerts to BigPanda`,
        },
        office365: {
            name: 'office365',
            title: 'Microsoft Teams',
            credType: 'Office365',
            shortDescription: 'Send alerts to Microsoft Teams',
            longDescription: `Integrate Microsoft Teams with ${PRODUCT_NAME}, then configure your detectors to send alerts to Microsoft Teams.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.microsoftteams&version=current',
        },
        opsgenie: {
            name: 'opsgenie',
            title: 'Opsgenie',
            credType: 'Opsgenie',
            shortDescription: 'Send alerts through Opsgenie',
            longDescription: `Integrate Opsgenie with ${PRODUCT_NAME}, then configure your detectors to send alerts to Opsgenie.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.opsgenie&version=current',
        },
        amazoneventbridge: {
            name: 'amazoneventbridge',
            credType: 'AmazonEventBridge',
            title: 'Amazon EventBridge',
            shortDescription: 'Send alerts through Amazon EventBridge',
            longDescription: `Integrate Amazon EventBridge with ${PRODUCT_NAME}, then configure your detectors to send events when alerts are triggered.`,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.amazoneventbridge&version=current',
        },
        jira: {
            name: 'jira',
            credType: 'Jira',
            title: 'JIRA',
            shortDescription: `Create JIRA issues based on ${PRODUCT_NAME} alerts`,
            longDescription: `Integrate JIRA with ${PRODUCT_NAME}, then configure your detectors to create JIRA issues when alerts are triggered`,
        },
        incidentIntelligenceJira: {
            name: 'incidentIntelligenceJira',
            credType: 'IncidentIntelligenceJira',
            title: 'Atlassian Jira',
            shortDescription: `Allow Incident Intelligence to create and syncronize Jira Issues.`,
            longDescription: `Jira is a platform for issue management. The Incident Intelligence integration for Jira allows Incident Intelligence users to create issues based on incidents and synchronize the incident status with the Jira issue.
            `,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability',
        },
        incidentIntelligenceServiceNow: {
            name: 'incidentIntelligenceServiceNow',
            credType: 'IncidentIntelligenceServiceNow',
            title: 'ServiceNow Incidents',
            shortDescription: `Allow Incident Intelligence to create and syncronize ServiceNow tickets.`,
            longDescription: `ServiceNow is a platform for ticket management. The Incident Intelligence integration for ServiceNow allows Incident Intelligence users to create tickets based on incidents and synchronize the incident status with the ServiceNow ticket.
            `,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability',
        },
        incidentIntelligenceBMCRemedy: {
            name: 'incidentIntelligenceBMCRemedy',
            credType: 'IncidentIntelligenceBMCRemedy',
            title: 'BMC Remedy',
            shortDescription: `Allow Incident Intelligence to create and syncronize BMC Remedy Tickets.`,
            longDescription: `BMC is a platform for ticket management. The Incident Intelligence integration for BMC allows Incident Intelligence users to create tickets based on incidents and synchronize the incident status with the BMC ticket.
            `,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability',
        },
        incidentIntelligenceZoom: {
            name: 'incidentIntelligenceZoom',
            credType: 'IncidentIntelligenceZoom',
            title: 'Zoom',
            shortDescription: `Allow Incident Intelligence to create Zoom meetings.`,
            longDescription: `Zoom is a platform for online meetings. The Incident Intelligence integration for Zoom allows Incident Intelligence users to create meetings to facilitate collaboration on an incident.
            `,
            url: 'https://quickdraw.splunk.com/redirect/?product=Observability',
        },
        SplunkPlatform: {
            name: 'splunkplatform',
            title: 'Splunk platform',
            credType: 'SplunkPlatform',
            shortDescription: `Send alerts from ${PRODUCT_NAME} to Splunk platform`,
            longDescription: '',
            url: '',
        },
    })
    .constant('BUSINESS_PROCESS_ENABLED', {
        adfs: false,
        amazoneventbridge: true,
        aws: true,
        azure: true,
        azureAd: false,
        bigpanda: true,
        gcp: true,
        googleauth: true,
        googleSaml: true,
        googleCloudIdentity: true,
        newrelic: true,
        office365: true,
        okta: true,
        onelogin: true,
        opsgenie: true,
        pagerduty: true,
        pingone: false,
        saml: false,
        servicenow: true,
        slack: true,
        victorops: true,
        webhook: true,
        xmatters: true,
        incidentIntelligenceJira: true,
        incidentIntelligenceServiceNow: true,
        incidentIntelligenceBMCRemedy: true,
        incidentIntelligenceZoom: true,
    })
    .constant('SAML_PROVIDERS', [
        'onelogin',
        'okta',
        'pingone',
        'adfs',
        'saml',
        'googleCloudIdentity',
        'azureAd',
    ])
    .constant('CREDENTIAL_FIELDS', {
        pagerduty: 'serviceKey',
        slack: 'credentialId',
        victorops: 'routingKey',
    });
