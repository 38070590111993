//quick and dirty, not really that random but doesnt matter for this use case.
function seededPRNG(s) {
    return function () {
        s = Math.sin(s) * 10000;
        return s - Math.floor(s);
    };
}

function DatapointGenerator(seed) {
    this.generateValue = seededPRNG(seed);
}

// Make this interface similar to a built in JS generator
DatapointGenerator.prototype.next = function () {
    return { value: this.generateValue() };
};

export default DatapointGenerator;
