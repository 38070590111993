angular
    .module('signalboost')

    .service('OrgPreferenceRestService', [
        'signalboost',
        'signalboostUtil',
        'SignalboostBaseService',
        'currentUser',
        function (signalboost, signalboostUtil, SignalboostBaseService, currentUser) {
            return class OrgPreferenceRestService extends SignalboostBaseService {
                constructor() {
                    super('orgpreference', false);
                }

                search() {
                    return currentUser.orgId().then(function (id) {
                        // Organization id required to look up preferences object by key
                        const params = { organizationId: id };
                        return signalboost.orgpreference
                            .all()
                            .customGET('', params)
                            .then(function (data) {
                                return data.rs.sort(signalboostUtil.sfUserComparator);
                            });
                    });
                }
            };
        },
    ]);
