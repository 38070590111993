/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import ChartService from '../../../../legacy/app/charting/chartdisplay/tableChart/chartService';
import {
    ResolutionPill,
    SamplingAndRenderingIcon,
    SingleLineAlertTicker,
} from '../../../../legacy/app/charting/chartdisplay/tableChart/chartDirectives';
import CellFormatter from './cellFormatter';
import SortDropDownMenu from './sortDropDownMenu';
import TableChartGearMenu from './tableChartGearMenu';
import TableCellRenderer from './TableCellRenderer';
import _ from 'lodash';
import GroupByDropdown from './dropDown';
import styled from 'styled-components';
import { ThemeProvider } from '../../../../common/theme/ThemeProvider';
import DraggableContainer from './draggable-header/DraggableContainer';
import {
    getProgramArgsForDashboardInTime,
    isDashboardTimeWindowSelected,
} from '../../../../legacy/app/utils/programArgsUtils';
import { AngularInjector } from '../../../../common/AngularUtils';
import { getTableChartColumns } from './utils/chartColumns';
import { isGroupBySelected, moveGroupByColumnToFirstPosition, NONE_OPTION } from './utils/groupBy';
import { timestampFormat } from './utils/timestamp';
import { getPlotColumnDisplayValue } from './TableCellRenderer';

export const MAXIMUM_PRECISION_DEFAULT = 7;

const ASC = 'ASC';
const DESC = 'DESC';
const ASC_NOTATION = '+';
const DESC_NOTATION = '-';

// The chart title has an inline padding top of 12, which is adjusted when resizing the grid.
const GRID_HEADER_PADDING_WIDTH = 12;
const FOOTER_DEFAULT_HEIGHT = 8;

const SF_DIMENSIONS_TO_SHOW = [
    'sf_service',
    'sf_error',
    'sf_kind',
    'sf_operation',
    'sf_environment',
    'sf_dimensionalized',
    'sf_workflow',
    'sf_failure_root_cause_service',
    'sf_endpoint',
    'sf_httpMethod',
];

const StyledDropdownSection = styled.div`
    margin-bottom: 0.875rem;
`;

const HEADER_ROW_HEIGHT = 30;
const ROW_HEIGHT = 25;

let chartPaused = false;
let openedDatalinks = 0;

function isChartPaused() {
    return chartPaused;
}

function shouldUpdateColumns(oldColumns, newColumns) {
    if (oldColumns.length !== newColumns.length) {
        return true;
    }

    for (let i = 0; i < oldColumns.length; i++) {
        if (!_.isEqual(oldColumns[i], newColumns[i])) {
            return true;
        }
    }

    return false;
}

function TableGridChart({
    chartModel,
    themeKey,
    openHref,
    isPreview,
    jobMessageSummary,
    chartRollupMessage,
    hideMissingValues,
    maximumPrecision,
    updateInterval,
    useKmg2,
    onNewDataProvider,
    onSortChanged,
    useExportToCsv,
    onGroupByChanged,
    onLegendColumnConfigChanged,
    onPlotChange,
    onTimestampChanged,
    CHART_CONSTANTS,
    INTERNAL_PROPERTY_DENYLIST,
    chartLoadedEvent,
    alertState,
    inEditor,
    chartHeight,
    builderDisplayMode,
}) {
    const chartService = ChartService.useInstance();
    const featureEnabled = AngularInjector.instantiate('featureEnabled');
    const getLegendColumns = () =>
        chartModel.sf_uiModel.chartconfig.legendColumnConfiguration || [];
    const [legendColumns, setLegendColumns] = useState(getLegendColumns());
    const updateLegendColumns = useCallback((updatedCols) => {
        onLegendColumnConfigChanged(updatedCols);
        setLegendColumns(updatedCols);
    });

    const { setExportData } = useExportToCsv();

    // default grid height is 350 (per RDG docs)
    const [gridHeight, setGridHeight] = useState(350);
    const [timestamp, setTimestamp] = useState(null);

    const [columns, setColumns] = useState([]);
    const [displayedColumns, setDisplayedColumns] = useState([]);

    const [tableData, setTableData] = useState([]);

    const [selectedGroupByState, setSelectedGroupByState] = useState();
    const [groupByItems, setGroupByItems] = useState([]);

    const [alertClass, setAlertClass] = useState('');
    const [activeAlerts, setActiveAlerts] = useState(null);
    const [hideTimestampClass, setHideTimestampClass] = useState('flex-auto table-grid-chart');
    const [hideTimestamp, setHideTimestamp] = useState(
        chartModel.sf_uiModel.chartconfig.hideTimestamp
    );
    const footerRef = useRef(null);
    const groupByRef = useRef(null);
    const headerRef = useRef(null);
    const containerRef = useRef(null);

    const systemDimensionKeys = chartService.getReservedDimensionKeys();
    const sortPreference = {};

    const legendKeys = useRef([]);
    const metadataMap = useRef({});
    const dataProvider = useRef({});
    const seriesMetadataMap = useRef({});
    const dataCache = useRef({});
    const rows = useRef([]);
    const collapsedRows = useRef([]);

    const ignoredKeys = [
        'sf_metric',
        'sf_originatingMetric',
        'value',
        'computationId',
        'key',
        'metric',
        'metricKey',
        'pointMetadata',
        'tsid',
    ];

    let plotKeys = [];
    let colDefs = [];
    let windowedDataRepo;
    let redrawDebounce = null;
    let unregisterRouteWatchGroup;

    const handleCrossLinkOpen = useCallback((dropDownOpen) => {
        if (dropDownOpen) {
            openedDatalinks++;
            pauseChart(true);
        } else {
            openedDatalinks--;
            if (openedDatalinks === 0) {
                pauseChart(false);
            }
        }
    }, []);

    function pauseChart(pause) {
        chartPaused = pause;
        if (!pause) {
            updateDisplayedValues();
        }
    }

    const RowRenderer = ({ renderBaseRow, ...props }) => {
        props.cellMetaData.onDataLinkOpen = handleCrossLinkOpen;
        props.cellMetaData.inEditor = inEditor;
        props.cellMetaData.maximumPrecision = maximumPrecision;
        props.cellMetaData.useKmg2 = useKmg2;
        return <div>{renderBaseRow({ cellRenderer: TableCellRenderer, ...props })}</div>;
    };

    useEffect(() => {
        initialize();
        return () => {
            unregisterRouteWatchGroup();
            //Workaround to force remove the cross link menu if open
            const crossLinkElement = document.body.getElementsByClassName(
                'cross-link-menu-container'
            )[0];
            if (crossLinkElement) {
                document.body.removeChild(crossLinkElement);
            }
            chartPaused = false;
            openedDatalinks = 0;
        };
    }, [onNewDataProvider]);

    useEffect(() => {
        setupStream();
    }, [updateInterval]);

    useEffect(() => {
        //Add style class when timestamp is hidden
        setHideTimestampClass(
            hideTimestamp
                ? 'flex-auto table-grid-chart timestamp-hidden'
                : 'flex-auto table-grid-chart'
        );
        computeGridHeight();
    }, [hideTimestamp]);

    useEffect(() => {
        setExportData({
            columns,
            getPlotColumnDisplayValue: getPlotColumnDisplayValue.bind(
                null,
                chartService,
                maximumPrecision || MAXIMUM_PRECISION_DEFAULT,
                useKmg2
            ),
            tableData,
            timestamp: hideTimestamp ? undefined : timestamp,
        });
    }, [tableData, timestamp, hideTimestamp, maximumPrecision]);

    useEffect(() => {
        if (inEditor) {
            const alertStateClass =
                alertState && alertState.alertState ? alertState.alertState.toLowerCase() : '';
            const activeAlertClass = alertState ? 'chart-alert-state-' + alertStateClass : '';
            setAlertClass(activeAlertClass);
        }
        if (alertState && alertState.alertState && alertState.alertState !== 'Normal') {
            setActiveAlerts(alertState.activeAlerts);
        } else {
            setActiveAlerts(null);
        }
        // update grid height with a small delay to ensure alerts are part of the header height
        setTimeout(computeGridHeight, 100);
    }, [alertState, chartHeight]);

    const handleGridSort = useCallback((col, direction) => {
        sortPreference.column = col;
        sortPreference.ascending = direction === ASC;
        if (sortPreference.ascending) {
            onSortChanged(ASC_NOTATION + col);
        } else {
            onSortChanged(DESC_NOTATION + col);
        }
        sort();
    }, []);

    const handleGridGroupBy = useCallback((col) => {
        onGroupByChanged(col);
        sort();
    }, []);

    const computeGridHeight = () => {
        // adjust the height for tablechart top padding ((set at 12px).
        const headerHeight = headerRef.current
            ? headerRef.current.clientHeight + GRID_HEADER_PADDING_WIDTH
            : GRID_HEADER_PADDING_WIDTH;

        if (!chartHeight && containerRef.current.offsetParent) {
            chartHeight = containerRef.current.offsetParent.clientHeight;
        }
        const groupByHeight = groupByRef.current ? groupByRef.current.clientHeight : 0;
        const footerHeight = footerRef.current
            ? footerRef.current.clientHeight
            : FOOTER_DEFAULT_HEIGHT;
        const height = chartHeight - headerHeight - footerHeight - groupByHeight;

        if (height > 0) {
            setGridHeight(height);
        }
    };

    function initialize() {
        dataProvider.current = chartService.getProvider(callback);
        dataProvider.current.setOffsetByMaxDelay(true);
        windowedDataRepo = chartService.getRepository();

        // !!! all visualizations must call this for builders to behave correctly !!!
        setupStream();
        onNewDataProvider(dataProvider.current);
        unregisterRouteWatchGroup = chartService.registerRouteWatchGroup(setupStream);
    }

    const setupStream = useCallback(() => {
        if (!dataProvider.current) {
            return;
        }
        // the change in the global time range should not update the chartModel.sf_uiModel.chartconfig here
        const chartModelCopy = _.cloneDeep(chartModel);

        chartService.updateGlobalTimeRange(chartModelCopy);

        const { chartconfig, chartMode } = chartModelCopy.sf_uiModel;
        const rangeParams = chartService.getJobRangeParametersFromConfig(chartconfig, chartMode);
        const programArgs = getProgramArgsForDashboardInTime(chartconfig);
        dataProvider.current.setResolution(rangeParams.resolution);
        dataProvider.current.setHistoryrange(rangeParams.range);
        dataProvider.current.setStopTime(rangeParams.endAt);
        if (
            featureEnabled('dashboardTimeWindow') &&
            isDashboardTimeWindowSelected(chartModel.sf_viewProgramText)
        ) {
            dataProvider.current.setProgramArgs(programArgs);
        }

        dataProvider.current.setFallbackResolutionMs(rangeParams.fallbackResolutionMs);
    }, []);

    function callback({ type, data }) {
        if (type === 'init') {
            windowedDataRepo = chartService.getRepository();
            windowedDataRepo.setExpirationPolicy('interval', 10);
        } else if (type === 'data' && !chartPaused) {
            handleNewData(data);
        } else if (type === 'timestampAdvance' && !chartPaused) {
            // dont do this if chart is paused
            setTimestamp(data);
        }
    }

    function handleNewData(data) {
        Object.keys(data).forEach((tsid) => {
            if (!metadataMap.current[tsid]) {
                const metadata = dataProvider.current.getMetricMetaData(tsid);
                metadataMap.current[tsid] = metadata;
                seriesMetadataMap.current[tsid] = chartService.getSeriesMetadata(
                    chartModel,
                    metadata
                );
            }
        });
        windowedDataRepo.onDataReceived(data);
        clearTimeout(redrawDebounce);
        redrawDebounce = setTimeout(function () {
            updateDisplayedValues();
        }, 0);
    }

    async function updateDisplayedValues() {
        if (!windowedDataRepo) {
            return;
        }

        // don't do this if chart is paused
        // pass the data value to the grid here.
        const data = windowedDataRepo.getAll();
        plotKeys = getPlotKeys();

        await getRecentChartData(data);
        await updateTableChartColumns();
        sort();

        chartLoadedEvent(100);
    }

    const getPlotKeys = () => {
        const plotKeys = [];
        chartModel.sf_uiModel.allPlots.forEach((plot) => {
            if (!plot.transient && (plot.type === 'plot' || plot.type === 'ratio')) {
                if (plotKeys.indexOf(plot.name) === -1) {
                    plotKeys.push(plot.name);
                }
            }
        });
        return plotKeys;
    };

    function initializeGroupByDropdown(columns) {
        if (isGroupBySelected(chartModel)) {
            let selectedOption = chartModel.sf_uiModel.chartconfig.groupBy[0];
            if (columns.indexOf(selectedOption) < 0) {
                selectedOption = NONE_OPTION;
                onGroupByChanged(selectedOption);
            }
            setSelectedGroupByState(selectedOption);
        } else {
            setSelectedGroupByState(NONE_OPTION);
        }
    }

    function sort() {
        let key;
        let sortDirection;
        if (isSortBySelected()) {
            key = chartModel.sf_uiModel.chartconfig.sortPreference.substring(1);
            sortDirection =
                chartModel.sf_uiModel.chartconfig.sortPreference.charAt(0) === '+' ? ASC : DESC;
        } else if (isGroupBySelected(chartModel)) {
            key = chartModel.sf_uiModel.chartconfig.groupBy[0];
            sortDirection = DESC;
        } else {
            key = chartModel.sf_uiModel.allPlots[0].name;
            sortDirection = DESC;
        }
        sortPreference.column = key;
        sortPreference.ascending = sortDirection === ASC;
        if (isGroupBySelected(chartModel)) {
            gridGroupBy(key, sortDirection, collapsedRows.current);
        } else {
            gridSort(key, sortDirection, rows.current);
        }
    }

    function isSortBySelected() {
        return !!chartModel.sf_uiModel.chartconfig.sortPreference;
    }

    function gridGroupBy(secondaryCol, secondarySortDirection, items) {
        let sortOptions = {};
        if (plotKeys.includes(secondaryCol)) {
            //Override the sort option for plots to sort numerically
            sortOptions = {
                primary: {
                    field: secondaryCol,
                    type: 'number',
                },
                secondary: {
                    field: 'metric',
                    type: 'string',
                },
            };
        } else {
            sortOptions = chartService.getSortOption(secondaryCol, secondarySortDirection, true);
        }
        sortOptions.ascending = secondarySortDirection === ASC;
        const sortedPoints = chartService.sortTimesliceValues(items, sortOptions);
        populateGrid(sortedPoints);
    }

    function gridSort(col, direction, items) {
        let sortOptions = {};
        let prependList = [];
        let sortableList = [];
        if (plotKeys.includes(col)) {
            //Override the sort option for plots to sort numerically
            sortOptions = {
                primary: {
                    field: col,
                    type: 'number',
                },
                secondary: {
                    field: 'metric',
                    type: 'string',
                },
            };
            //filter out the values for the specific plot
            prependList = items.filter((point) => point.metric !== col);
            sortableList = items.filter((point) => point.metric === col);
        } else {
            sortOptions = chartService.getSortOption(col, direction, true);
            sortableList = [...items];
        }
        sortOptions.ascending = direction === ASC;
        const sortedPoints = chartService
            .sortTimesliceValues(sortableList, sortOptions)
            .concat(prependList);
        populateGrid(sortedPoints);
    }

    function getRecentChartData(data) {
        dataCache.current = data;
        const tsidToPlot = {};
        const latestTimeSlice = Object.entries(data)
            .map(([tsid, datapoints]) => {
                const plot = getPlotObject(tsid);
                if (!plot) {
                    console.log('metadata map: ' + JSON.stringify(metadataMap));
                }

                if (plot.invisible) {
                    return null;
                }
                tsidToPlot[tsid] = plot;
                const rawValue = datapoints[datapoints.length - 1].value;
                const formattedData = datapoints.map(({ ts, value }) => [ts, value]);
                const highT = null;
                const lowT = null;

                const rawValueFormatted = formattedData[formattedData.length - 1][1];
                const pointMetaData = {
                    ...seriesMetadataMap.current[tsid].raw,
                    raw: rawValueFormatted,
                };
                const metricName = seriesMetadataMap.current[tsid].metric;
                const origMetricName = metadataMap.current[tsid].sf_originatingMetric;
                const metricKey = `${metricName}|${origMetricName || ''}`;
                return {
                    tsid,
                    plot,
                    rawValue,
                    name,
                    metricKey,
                    metric: seriesMetadataMap.current[tsid].metric,
                    source: seriesMetadataMap.current[tsid].source,
                    datapoints: formattedData,
                    pointMetaData: pointMetaData,
                    raw: rawValueFormatted,
                    highT: highT,
                    lowT: lowT,
                };
            })
            .filter((e) => {
                return (
                    e !== null && (!hideMissingValues || (hideMissingValues && e.rawValue !== null))
                );
            });
        legendKeys.current = chartService.getLegendKeys(metadataMap.current, tsidToPlot);
        rows.current = getRows(latestTimeSlice, legendKeys.current);
        collapsedRows.current = collapseRows(rows.current);

        const groupByItems = getGroupableFields(legendKeys.current);
        setGroupByItems([NONE_OPTION].concat(groupByItems));
        initializeGroupByDropdown(groupByItems);
    }

    const getRows = (latestTimeSlice, keys) => {
        const result = [];
        latestTimeSlice.forEach((point) => {
            const legendRow = {};
            legendRow.groupKey = '';
            if (keys) {
                keys.forEach(function (key) {
                    if (systemDimensionKeys.indexOf(key) > -1) {
                        return;
                    }
                    const val = chartService.syntheticIdFilter(
                        metadataMap.current[point.tsid][key],
                        getPlotObject(point.tsid),
                        metadataMap.current[point.tsid]
                    );
                    if (key === 'sf_metric' && plotKeys.indexOf(val) > -1) {
                        legendRow[val] = point.rawValue;
                        return;
                    }
                    legendRow[key] = val;
                    if (key !== 'sf_originatingMetric') {
                        legendRow.groupKey += `${legendRow.groupKey ? ` | ${val}` : `${val}`}`;
                    }
                });
            }
            legendRow.tsid = point.tsid;
            legendRow.metricKey = point.metricKey;
            legendRow.metric = point.metric;
            const crossLinkContext = {};
            if (point.pointMetaData) {
                // Plot Dimensions Keys / Data-table column names
                const plotDimensionKeys = keys.filter((legend) => !ignoredKeys.includes(legend));
                // Respective dimension values
                plotDimensionKeys.forEach((key) => {
                    crossLinkContext[key] = point.pointMetaData[key];
                });
            }
            legendRow.crossLinkContext = crossLinkContext;
            result.push(legendRow);
        });
        return result;
    };

    const collapseRows = (rows) => {
        const map = new Map();
        for (const row of rows) {
            const values = map.get(row.groupKey) || [];
            values.push(row);
            map.set(row.groupKey, values);
        }
        const result = [];
        map.forEach((values) => {
            if (values.length > 1 && hasDifferentMetrics(values)) {
                result.push(Object.assign({}, ...values));
            } else {
                result.push(...values);
            }
        });
        return result;
    };

    const hasDifferentMetrics = (rows) => {
        const keysSet = new Set();
        rows.forEach((row) => {
            keysSet.add(row.metricKey);
        });
        return keysSet.size === rows.length;
    };

    const getGroupableFields = (fields) =>
        fields.filter(
            (field) =>
                (field.indexOf('sf_') !== 0 || SF_DIMENSIONS_TO_SHOW.includes(field)) &&
                field.indexOf('_') !== 0 &&
                !INTERNAL_PROPERTY_DENYLIST.includes(field)
        );

    function updateTableChartColumns() {
        colDefs = getTableChartColumns({
            legendKeys: legendKeys.current,
            systemDimensionKeys,
            chartModel,
            getColDef,
        });

        if (isGroupBySelected(chartModel)) {
            moveGroupByColumnToFirstPosition(chartModel, colDefs, (item) => {
                return item.key;
            });
        }
    }

    function getColDef(key, plot) {
        // Note: Need to include the value columns with prefix/suffix when
        // exporting data as CVS but hide them in the data table
        //
        // Note: For show/hide columns, we could not make use of the React
        // Data Grid's `hidden: <boolean>` column definition because it does
        // not truly hide the columns but instead show an empty area.
        // Instead, we set `isVisible: <boolean>` and filter these columns out
        // before passing it to the React Data Grid component

        const def = {
            key: key,
            frozen: false,
            draggable: inEditor && !plot,
            isVisible: !!plot
                ? !plot.invisible
                : chartService.isColumnVisible(key, getLegendColumns()),
            isPlot: !!plot ? true : false,
            formatter: <CellFormatter value />,
            plot: plot,
        };
        //sort preference and
        def.name = !!plot ? plot.name : CHART_CONSTANTS.KEY_ALIAS[key] || key;
        def.hasCrossLink = !def.isPlot;
        def.headerRenderer = (
            <SortDropDownMenu sortHandler={handleGridSort} column sortPreference={sortPreference} />
        );
        return def;
    }

    function getPlotObject(tsid) {
        return chartService.getPlotObject(
            metadataMap.current[tsid],
            chartModel,
            chartModel.$isOriginallyV2
        );
    }

    function populateGrid(data) {
        const tableChartData = data;
        updateTableChartColumns();

        setColumns((oldColumns) => {
            if (shouldUpdateColumns(oldColumns, colDefs)) {
                return _.cloneDeep(colDefs);
            }
            return oldColumns;
        });
        setTableData(tableChartData);
    }

    function getRowCount() {
        return tableData.length;
    }

    const handleGroupByChange = (selectedItem, isHidden) => {
        if (!selectedItem) {
            return;
        }
        setSelectedGroupByState(selectedItem);
        handleGridGroupBy(selectedItem);

        if (isHidden) {
            handleColVisibilityChange({
                key: selectedItem,
            });
        }
    };

    function onHeaderDrop(source, target) {
        //metadata Keys are the most currently ordered keys.
        const legendColumnsCopy = initializeLegendColumns();
        const columnsCopy = [...displayedColumns];

        if (isGroupBySelected(chartModel)) {
            moveGroupByColumnToFirstPosition(legendColumnsCopy, (item) => {
                return item.property;
            });
        }

        const legendSourceIndex = legendColumnsCopy.findIndex((i) => i.property === source);
        const legendTargetIndex = legendColumnsCopy.findIndex((i) => i.property === target);

        const columnSourceIndex = columnsCopy.findIndex((i) => i.key === source);
        const columnTargetIndex = columnsCopy.findIndex((i) => i.key === target);

        //Swap only if the source and target are a part of legend columns
        if (
            legendSourceIndex < 0 ||
            legendTargetIndex < 0 ||
            legendSourceIndex === legendTargetIndex
        ) {
            return;
        }
        //Update the legend column on the data model
        swap(legendColumnsCopy, legendSourceIndex, legendTargetIndex);
        updateLegendColumns(legendColumnsCopy);

        //Update the table grid
        swap(columnsCopy, columnSourceIndex, columnTargetIndex);
        setDisplayedColumns([]);
        setDisplayedColumns(columnsCopy);
    }

    function initializeLegendColumns() {
        const legendColumnsCopy = getLegendColumns();
        const currentLegendConfig = legendColumnsCopy;
        //add the missing entries
        legendKeys.current.forEach((key) => {
            let val = currentLegendConfig.find((val) => val.property === key);
            if (!val) {
                val = {
                    property: key,
                    enabled: true,
                };
                legendColumnsCopy.push(val);
            }
        });
        return legendColumnsCopy;
    }

    function swap(legendCols, sourceIdx, targetIdx) {
        const tmp = legendCols[sourceIdx];
        legendCols[sourceIdx] = legendCols[targetIdx];
        legendCols[targetIdx] = tmp;
    }

    const handleColVisibilityChange = (colDefinition) => {
        // Update displayed columns based on updates to the legend column configuration
        const newDisplayedColumns = [];

        //If the column is a plot update the plot visiblity
        if (colDefinition.isPlot) {
            const currentPlot = chartModel.sf_uiModel.allPlots.find(
                (plot) => plot.name === colDefinition.key
            );
            onPlotChange(currentPlot);
        } else {
            const newLegendColumns = _.cloneDeep(getLegendColumns());
            // Update the show / hide flag for the given column definition in the legendColumnConfig
            const columnFound = newLegendColumns.find(
                (elem) => elem.property === colDefinition.key
            );
            if (!columnFound) {
                newLegendColumns.push({
                    enabled: false,
                    property: colDefinition.key,
                });
            } else {
                columnFound.enabled = !columnFound.enabled;
            }
            updateLegendColumns(newLegendColumns);
        }

        // We want to loop over the columns which has the superset of all column
        // definitions
        // toggle the visibility for the selected column
        columns.forEach((col) => {
            if (col.key === colDefinition.key) {
                col.isVisible = !col.isVisible;
            }
            newDisplayedColumns.push(col);
        });

        setTimeout(() => {
            plotKeys = getPlotKeys();
            getRecentChartData(dataCache.current);
            sort();
        }, 100);

        // Clear out and then set to the new value to overcome the RDG quirk
        // Clear out and then set to the new value to overcome the RDG quirk
        // that requires an extra render cycle
        setColumns([]);
        setColumns(newDisplayedColumns);
    };

    const toggleTimestampDisplay = () => {
        const timestampHidden = !chartModel.sf_uiModel.chartconfig.hideTimestamp;
        setHideTimestamp(timestampHidden);
        onTimestampChanged(timestampHidden);
    };

    // We want to distinguish the displayed columns from column definitions
    // because some displayed columns could be hidden.
    useEffect(() => {
        const newDisplayColumns = columns.filter((newCol) => newCol.isVisible);
        setTimeout(() => {
            setDisplayedColumns([]);
            setDisplayedColumns(newDisplayColumns);
        });
    }, [columns]);

    return (
        <ThemeProvider colorScheme={themeKey}>
            <div
                className="sf-fill-extents flex-nav flex-col table-chart-container"
                ref={containerRef}
            >
                {!inEditor && (
                    <div ref={headerRef} className="single-value-chart-title flexstatic">
                        {!isPreview && (
                            <h5 className="chart-title-wrap">
                                <a
                                    className="chart-title-link"
                                    title={chartModel.sf_chart}
                                    href={openHref ? openHref : '#/chart/' + chartModel.sf_id}
                                >
                                    {chartModel.sf_chart ||
                                        chartModel.sf_detector ||
                                        'Untitled Chart'}
                                </a>
                                <ResolutionPill
                                    rollupMessage={chartRollupMessage}
                                    resolution={jobMessageSummary.primaryJobResolution}
                                    misalignedResolution={jobMessageSummary.misalignedResolution}
                                ></ResolutionPill>
                                <SamplingAndRenderingIcon
                                    jobMessageSummary={jobMessageSummary}
                                ></SamplingAndRenderingIcon>
                            </h5>
                        )}
                        <div
                            className="sf-ellipsis description-field"
                            title={chartModel.sf_description}
                        >
                            {activeAlerts ? (
                                <SingleLineAlertTicker
                                    alerts={activeAlerts}
                                ></SingleLineAlertTicker>
                            ) : (
                                <div>{chartModel.sf_description}</div>
                            )}
                        </div>
                    </div>
                )}
                {inEditor && (
                    <StyledDropdownSection ref={groupByRef}>
                        <GroupByDropdown
                            title="Group by"
                            options={groupByItems}
                            onChange={handleGroupByChange}
                            selected={selectedGroupByState}
                            legendColumns={legendColumns}
                            noneOption={NONE_OPTION}
                        />
                        <TableChartGearMenu
                            columns={columns}
                            legendColumns={legendColumns}
                            legendColumnsHandler={handleColVisibilityChange}
                            groupByKey={selectedGroupByState}
                            hideTimestamp={hideTimestamp}
                            toggleTimestampDisplay={toggleTimestampDisplay}
                            hidePlots={builderDisplayMode === 'fallback'}
                        />
                    </StyledDropdownSection>
                )}
                <div className={hideTimestampClass}>
                    <div className={alertClass}>
                        {displayedColumns.length > 0 && (
                            <DraggableContainer onHeaderDrop={onHeaderDrop}>
                                <ReactDataGrid
                                    columns={displayedColumns}
                                    rowGetter={(i) => tableData[i]}
                                    rowsCount={getRowCount()}
                                    headerRowHeight={HEADER_ROW_HEIGHT}
                                    enableCellAutoFocus={false}
                                    rowRenderer={RowRenderer}
                                    rowHeight={ROW_HEIGHT}
                                    minHeight={gridHeight}
                                />
                            </DraggableContainer>
                        )}
                    </div>
                </div>
                {!hideTimestamp && (
                    <div className="chart-timestamp flexstatic" ref={footerRef}>
                        {chartService.formatTime(timestamp, timestampFormat)}
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
}

TableGridChart.propTypes = {
    chartModel: PropTypes.object,
    themeKey: PropTypes.string.isRequired,
    openHref: PropTypes.string,
    isPreview: PropTypes.bool,
    jobMessageSummary: PropTypes.object,
    chartRollupMessage: PropTypes.string,
    hideMissingValues: PropTypes.bool,
    maximumPrecision: PropTypes.number,
    updateInterval: PropTypes.number,
    useKmg2: PropTypes.bool,
    onNewDataProvider: PropTypes.func,
    useExportToCsv: PropTypes.func,
    onSortChanged: PropTypes.func,
    onGroupByChanged: PropTypes.func,
    onLegendColumnConfigChanged: PropTypes.func,
    onPlotChange: PropTypes.func,
    onTimestampChanged: PropTypes.func,
    CHART_CONSTANTS: PropTypes.object,
    INTERNAL_PROPERTY_DENYLIST: PropTypes.array,
    chartLoadedEvent: PropTypes.any,
    alertState: PropTypes.any,
    inEditor: PropTypes.bool,
    chartHeight: PropTypes.number,
    builderDisplayMode: PropTypes.string,
};

const TableChartContainer = React.memo((props) => {
    return <TableGridChart {...props} />;
}, isChartPaused);

TableChartContainer.displayName = 'TableChartContainer';

export default TableChartContainer;
