import { useState, useEffect } from 'react';
import PrincipalDataRepository from './PrincipalDataRepository';
import AccessControlPrincipalService from './AccessControlPrincipalService';

export default function useAclUserContext(userData) {
    const aclPrincipalService = AccessControlPrincipalService.useInstance();
    const [userContext, setUserContext] = useState(createNewUserContext());

    useEffect(() => {
        if (
            userData.userId !== userContext.userId ||
            userData.orgId !== userContext.orgId ||
            userData.isAdmin !== userContext.isAdmin
        ) {
            setUserContext(createNewUserContext());
        }
    }, [userData]); // eslint-disable-line

    return userContext;

    function createNewUserContext() {
        return {
            userId: userData.userId,
            orgId: userData.orgId,
            isAdmin: userData.isAdmin,
            principalsDataRepository: new PrincipalDataRepository(
                aclPrincipalService,
                userData.userId,
                userData.orgId
            ),
        };
    }
}
