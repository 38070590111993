import templateUrl from './resolutionPill.tpl.html';

export const bindings = {
    resolution: '=',
    rollupMessage: '=',
    misalignedResolution: '=?',
};

export const resolutionPill = [
    'moment',
    function (moment) {
        return {
            restrict: 'E',
            scope: { ...bindings, tooltipText: '@?' },
            replace: true,
            templateUrl,
            link: function ($scope) {
                const suffixes = {
                    seconds: {
                        short: 's',
                        long: '-second',
                    },
                    minutes: {
                        short: 'm',
                        long: '-minute',
                    },
                    hours: {
                        short: 'h',
                        long: '-hour',
                    },
                    days: {
                        short: 'd',
                        long: '-day',
                    },
                };

                function getDisplayString(res, suffix) {
                    const m = moment.duration(res);
                    let resStr = '';

                    if (m.seconds()) {
                        resStr = m.seconds() + suffixes.seconds[suffix];
                    }
                    if (m.asMinutes() % 1 === 0) {
                        resStr = m.asMinutes() + suffixes.minutes[suffix];
                    }
                    if (m.asHours() % 1 === 0) {
                        resStr = m.asHours() + suffixes.hours[suffix];
                    }

                    if (m.asDays() % 1 === 0) {
                        resStr = m.asDays() + suffixes.days[suffix];
                    }
                    return resStr;
                }

                const baseTooltipText = $scope.tooltipText || 'Data displayed at {resolution}';

                $scope.$watchGroup(
                    ['resolution', 'rollupMessage', 'misalignedResolution'],
                    function () {
                        const ms = $scope.resolution;
                        $scope.displayStr = ms ? getDisplayString(ms, 'short') : null;
                        let resolutionStr = ms ? getDisplayString(ms, 'long') : 'n/a';
                        resolutionStr += ' resolution';
                        if ($scope.misalignedResolution) {
                            resolutionStr +=
                                ', which may not allow plotted values to align with calendar cycles specified in functions. You can zoom in or choose a finer Chart Resolution override';
                        }
                        let tooltipText = baseTooltipText.replace('{resolution}', resolutionStr);
                        if ($scope.rollupMessage) {
                            tooltipText += `<div class="sfx-label">(${$scope.rollupMessage})</div>`;
                        }
                        $scope.generatedTooltipText = tooltipText;
                    }
                );
            },
        };
    },
];
