import templateUrl from './disabledAlertFilterBar.tpl.html';

angular.module('signalview.alertsOverview').directive('disabledAlertFilterBar', [
    '$q',
    'urlOverridesService',
    'sourceFilterService',
    function ($q, urlOverridesService, sourceFilterService) {
        return {
            restrict: 'EA',
            replace: true,
            templateUrl,
            link: function ($scope) {
                $scope.noopPromise = function () {
                    return $q.when([]);
                };

                $scope.noop = angular.noop;

                const sourceOverride = urlOverridesService.getSourceOverride() || [];
                $scope.eventFilters = sourceFilterService.getSourceFilters(sourceOverride);
                $scope.grouping = urlOverridesService.getGroupBy() || [];
            },
        };
    },
]);
