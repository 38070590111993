export default `
This is the team landing page. Below, you can see the list of dashboard groups
that have been identified as relevant to this team, along with a summary of active
alerts from all detectors that are linked to this team.

When viewing a dashboard or detector, you can associate it with this team from the
Actions menu.  You can also use the team as a notification recipient for a detector.

You can also include [links](https://www.signalfx.com) in the description.
`;
