angular.module('signalview.detector').service('detectorVersionService', [
    '$log',
    function ($log) {
        function getModelVersion(detector) {
            if (detector.sf_modelVersion) {
                return detector.sf_modelVersion;
            }

            if (
                typeof detector.name !== 'undefined' ||
                typeof detector.programText !== 'undefined'
            ) {
                return 2;
            }

            if (detector.sf_uiModel) {
                return 1;
            }

            $log.warn('Could not be sure of detector version, returning 1.');

            return 1;
        }

        function getSignalFlowVersion(detector) {
            const modelVersion = getModelVersion(detector);
            if (modelVersion === 2) {
                return 2;
            } else if (modelVersion === 1) {
                if (detector.sf_signalflowVersion === 2) {
                    return 2;
                } else {
                    return 1;
                }
            } else {
                $log.error('Could not be sure of detector signalflow version.');
            }
        }

        function getInternalVersion(detector) {
            if (getModelVersion(detector) === 1) {
                if (getSignalFlowVersion(detector) === 2) {
                    return 1.5;
                }
                return 1;
            } else {
                return 2;
            }
        }

        return {
            getVersion: getModelVersion,
            getSignalFlowVersion: getSignalFlowVersion,
            getInternalVersion: getInternalVersion,
        };
    },
]);
