import templateUrl from './crossLinkDefinitionForms.tpl.html';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export default {
    templateUrl,
    bindings: {
        editable: '<', // (Bool) Is target editable?
        allowedTypes: '=', // What are the types from CROSS_LINK_TYPES, which this form should allow
        crossLinkToEdit: '<', // { propertyName, propertyValue } to edit by default
        context: '<?', // Cross link extended context
        dashboardObject: '<', // The context dashboard
        showGlobalLinks: '<', // (Bool) Include global links in this form?
        restrictTrigger: '<', // (Bool) Required:crossLinkToEdit, Restrict trigger to only be crossLinkToEdit
        exitGuard: '<', // Promise based callback. Takes back confirmation promise to discard unsaved changes.
    },
    controller: [
        '$scope',
        '$q',
        'confirmService',
        'crossLinkService',
        'CROSS_LINK_TYPES',
        'CROSS_LINK_EVENTS',
        'crossLinkUpdatableTargets',
        '$window',
        'currentUser',
        '_',
        'hasCapability',
        'featureEnabled',
        function (
            $scope,
            $q,
            confirmService,
            crossLinkService,
            CROSS_LINK_TYPES,
            CROSS_LINK_EVENTS,
            crossLinkUpdatableTargets,
            $window,
            currentUser,
            _,
            hasCapability,
            featureEnabled
        ) {
            const $ctrl = this;
            const sortingOrder = [
                'definition.propertyName',
                'definition.propertyValue',
                'type',
                'name',
            ];

            let targetsUpdateService;
            let unbindLocationWatcher, unbindModalCloseWatcher;

            $ctrl.$onInit = $onInit;
            $ctrl.getAvailableLinkLabels = getAvailableLinkLabels;
            $ctrl.onClone = onClone;
            $ctrl.addNewLink = addNewLink;
            $ctrl.onDelete = onDelete;
            $ctrl.onSave = onSave;
            $ctrl.setDefault = setDefault;

            function $onInit() {
                let contextualLinks;
                let orgLinks;

                if ($ctrl.dashboardObject) {
                    $ctrl.contextId = $ctrl.dashboardObject.id;
                }

                if ($ctrl.contextId) {
                    // contextId present, Searching for context is possible
                    if ($ctrl.restrictTrigger && $ctrl.crossLinkToEdit) {
                        contextualLinks = crossLinkService.searchByTrigger(
                            $ctrl.crossLinkToEdit.propertyName,
                            $ctrl.crossLinkToEdit.propertyValue,
                            $ctrl.contextId
                        );
                    } else {
                        contextualLinks = crossLinkService.searchByContext($ctrl.contextId);
                    }
                } else {
                    contextualLinks = $q.when({ results: [] });
                }

                if ($ctrl.restrictTrigger && $ctrl.crossLinkToEdit) {
                    // Get global links on trigger
                    orgLinks = crossLinkService.searchByTrigger(
                        $ctrl.crossLinkToEdit.propertyName,
                        $ctrl.crossLinkToEdit.propertyValue,
                        null
                    );
                } else {
                    // Get all global links
                    orgLinks = crossLinkService.getGlobalCrossLinks();
                }

                const isAdmin = currentUser.isAdmin();
                $ctrl.loading = true;

                const hasCreateGlobalDataLink = hasCapability(Capability.CREATE_GLOBAL_DATA_LINK);
                const hasUpdateGlobalDataLink = hasCapability(Capability.UPDATE_GLOBAL_DATA_LINK);
                const hasDeleteGlobalDataLink = hasCapability(Capability.DELETE_GLOBAL_DATA_LINK);
                const hasReadGlobalDataLink = hasCapability(Capability.READ_GLOBAL_DATA_LINK);
                const hasCreateLocalDataLink = hasCapability(Capability.CREATE_DASHBOARD_DATA_LINK);
                const hasUpdateLocalDataLink = hasCapability(Capability.UPDATE_DASHBOARD_DATA_LINK);
                const hasDeleteLocalDataLink = hasCapability(Capability.DELETE_DASHBOARD_DATA_LINK);
                $q.all({
                    contextualLinks,
                    orgLinks,
                    isAdmin,
                    hasCreateGlobalDataLink,
                    hasUpdateGlobalDataLink,
                    hasDeleteGlobalDataLink,
                    hasReadGlobalDataLink,
                    hasCreateLocalDataLink,
                    hasUpdateLocalDataLink,
                    hasDeleteLocalDataLink,
                })
                    .then(
                        ({
                            contextualLinks,
                            orgLinks,
                            isAdmin,
                            hasCreateGlobalDataLink,
                            hasUpdateGlobalDataLink,
                            hasDeleteGlobalDataLink,
                            hasReadGlobalDataLink,
                            hasCreateLocalDataLink,
                            hasUpdateLocalDataLink,
                            hasDeleteLocalDataLink,
                        }) => {
                            $ctrl.isAdmin = isAdmin;
                            const localLinks = contextualLinks.results;
                            const globalLinks = orgLinks.results;

                            let targetLinks = localLinks;
                            targetLinks = targetLinks.concat(globalLinks);

                            targetsUpdateService = new crossLinkUpdatableTargets(
                                targetLinks,
                                $ctrl.allowedTypes
                            );
                            targetsUpdateService.sortTargets(sortingOrder);

                            $ctrl.allTargets = targetsUpdateService.getTargets();
                            // if there were cross links to edit and there is not a specific trigger data link being opened
                            // ie. all org data links were loaded, check the cross link to edit to see if there is a propertyName
                            // then auto open the new data link for the user
                            if (
                                !$ctrl.restrictTrigger &&
                                $ctrl.crossLinkToEdit &&
                                $ctrl.crossLinkToEdit.propertyName
                            ) {
                                addNewLink();
                            }
                            const dashboardEditable = $ctrl.contextId && $ctrl.editable;
                            $ctrl.hasCreateDataLink = featureEnabled('newRbacExperience')
                                ? (hasCreateGlobalDataLink && !$ctrl.contextId) ||
                                  (dashboardEditable && hasCreateLocalDataLink)
                                : $ctrl.editable;
                            //Checking the capability, if RBAC is ON else return the editable flag
                            $ctrl.hasUpdateDataLink = featureEnabled('newRbacExperience')
                                ? (hasUpdateGlobalDataLink && !$ctrl.contextId) ||
                                  (dashboardEditable && hasUpdateLocalDataLink)
                                : $ctrl.editable;
                            //Checking the capability, if RBAC is ON else return true,
                            //Below is done to not apply the changes for the exisiting usecase where RBAC is off
                            $ctrl.shouldEditableFormFieldForRbac = featureEnabled(
                                'newRbacExperience'
                            )
                                ? (hasUpdateGlobalDataLink && !$ctrl.contextId) ||
                                  (dashboardEditable && hasUpdateLocalDataLink)
                                : true;
                            $ctrl.hasDeleteDataLink = featureEnabled('newRbacExperience')
                                ? (hasDeleteGlobalDataLink && !$ctrl.contextId) ||
                                  (dashboardEditable && hasDeleteLocalDataLink)
                                : $ctrl.editable;
                            $ctrl.showGlobalDataLinkOption =
                                hasCreateGlobalDataLink &&
                                hasUpdateGlobalDataLink &&
                                hasReadGlobalDataLink;
                        }
                    )
                    .finally(() => {
                        $ctrl.loading = false;
                    });

                unbindLocationWatcher = $scope.$on('$locationChangeStart', locationChangeHandler);
                unbindModalCloseWatcher = $scope.$on('modal.closing', closeModalHandler);
                if ($ctrl.exitGuard) {
                    $ctrl.exitGuard(confirmClose);
                }
            }

            function locationChangeHandler($event, next) {
                prepForModalClose($event).then(() => {
                    unregisterWatchers();
                    $window.location.href = next;
                });
            }

            function closeModalHandler($event, reason, isBeingClosed) {
                prepForModalClose($event).then(() => {
                    unregisterWatchers();
                    if (isBeingClosed) {
                        $scope.$emit('close modal');
                    } else {
                        $scope.$emit('dismiss modal');
                    }
                });
            }

            function prepForModalClose($event) {
                $event.preventDefault();
                return confirmClose();
            }

            function unregisterWatchers() {
                if (unbindLocationWatcher) {
                    unbindLocationWatcher();
                }
                if (unbindModalCloseWatcher) {
                    unbindModalCloseWatcher();
                }
            }

            function confirmClose() {
                let promise = null;
                if (
                    $scope.crossLinkFormsContainer &&
                    $scope.crossLinkFormsContainer.$$controls.some((form) => form.$dirty)
                ) {
                    promise = confirmService.confirm({
                        title: 'Abandon edits',
                        text: ['You have unsaved edits, are you sure you want to leave the page?'],
                        yesText: 'Leave',
                        noText: 'Cancel',
                    });
                } else {
                    promise = $q.when(true);
                }

                return $q.when(promise).then((answeredYes) => {
                    return answeredYes ? $q.when(true) : $q.reject(false);
                });
            }

            function areSameTriggers(trigger1, trigger2) {
                return (
                    trigger1 &&
                    trigger2 &&
                    (trigger1.propertyName || null) === (trigger2.propertyName || null) &&
                    (trigger1.propertyValue || null) === (trigger2.propertyValue || null)
                );
            }

            // Aggregates local and global link labels filtered on trigger
            function getAvailableLinkLabels(trigger) {
                const linkLabels = { locals: [], globals: [] };

                _.each(targetsUpdateService.links, (link) => {
                    if (areSameTriggers(link, trigger)) {
                        link.targets.forEach((target) => {
                            if (link.contextId) {
                                linkLabels.locals.push(target.name);
                            } else {
                                linkLabels.globals.push(target.name);
                            }
                        });
                    }
                });

                return linkLabels;
            }

            // Make a temporary clone of existing link
            // cloneId is attached to name to act as clone marker
            function onClone(source, cloneId) {
                const target = angular.copy(source.target);
                const { contextId, propertyName, propertyValue } = source.definition;

                target.name = (target.name || '') + ' clone (' + cloneId + ')';

                return targetsUpdateService.addNewUpdatableTarget(
                    target,
                    contextId,
                    propertyName,
                    propertyValue !== '*' ? propertyValue : null
                );
            }

            function addNewLink() {
                let propertyName, propertyValue;
                if ($ctrl.crossLinkToEdit) {
                    propertyName = $ctrl.crossLinkToEdit.propertyName;
                    propertyValue = $ctrl.crossLinkToEdit.propertyValue;
                }

                return targetsUpdateService.addNewUpdatableTarget(
                    { type: CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK },
                    $ctrl.contextId,
                    propertyName,
                    propertyValue
                );
            }

            function onDelete(target) {
                return targetsUpdateService.deleteTarget(target).finally(() => {
                    $scope.$applyAsync();
                    $scope.$broadcast(CROSS_LINK_EVENTS.CROSS_LINK_UPDATED);
                });
            }

            function onSave(target, updates) {
                document.getElementById('new-link-button').focus();
                return targetsUpdateService
                    .addOrUpdateLink(target, updates)
                    .finally(() => $scope.$broadcast(CROSS_LINK_EVENTS.CROSS_LINK_UPDATED));
            }

            function setDefault(target) {
                return targetsUpdateService.setDefault(target);
            }
        },
    ],
};
