angular
    .module('signalview.page')

    .service('pageDisplayTitle', [
        function () {
            return function (page) {
                if (
                    !(page && angular.isDefined(page.sf_page)) &&
                    !(page && angular.isDefined(page.name))
                ) {
                    return '';
                }
                return page.sf_email || page.email || page.sf_page || page.name || page.sf_service;
            };
        },
    ]);
