import templateUrl from './eventOverlay.tpl.html';
import { colorAccessibilityOptions } from '@splunk/olly-services/lib/services/ColorAccessibility/colorAccessibilityOptions';

angular.module('signalview.dashboard').component('eventOverlay', {
    templateUrl,
    bindings: {
        currentDashboard: '=',
        overlayEvents: '=',
        selectedEventOverlayDirty: '=',
        eventSidebarOverlayMode: '=',
        openEventsSidebar: '=',
    },
    controller: [
        '$scope',
        '$q',
        '$element',
        '$timeout',
        '_',
        'analyticsService',
        'colorAccessibilityService',
        'plotSignalSuggestionService',
        'murmurHash',
        'urlOverridesService',
        'routeParameterService',
        'sourceFilterService',
        'userAnalytics',
        'dashboardVariablesService',
        function (
            $scope,
            $q,
            $element,
            $timeout,
            _,
            analyticsService,
            colorAccessibilityService,
            plotSignalSuggestionService,
            murmurHash,
            urlOverridesService,
            routeParameterService,
            sourceFilterService,
            userAnalytics,
            dashboardVariablesService
        ) {
            const ctrl = this;

            ctrl.toggleEventOverlayDropdown = toggleEventOverlayDropdown;
            ctrl.getSuggestions = getSuggestions;
            ctrl.toggleSelection = toggleSelection;
            ctrl.isSelected = isSelected;
            ctrl.onItemSelected = onItemSelected;
            ctrl.clearClientState = clearClientState;
            ctrl.removeAndCommit = removeAndCommit;
            ctrl.showOverlaidEvents = showOverlaidEvents;
            ctrl.focusEventOverlayInput = focusEventOverlayInput;
            ctrl.$onInit = $onInit;
            ctrl.onEditBlur = onEditBlur;

            const EVENT_COLORS = colorAccessibilityOptions.default.plotColors;

            function toggleEventOverlayDropdown(toggleOn) {
                ctrl.dropdownOpen = toggleOn;

                if (
                    toggleOn &&
                    (!$scope.selectedEventOverlays || $scope.selectedEventOverlays.length === 0)
                ) {
                    $timeout(focusEventOverlayInput);
                }
            }

            function focusEventOverlayInput() {
                const input = $element.find('.typeahead-input')[0];
                if (input) {
                    input.focus();
                }
            }

            let separator;
            let showOverlayActionResult;
            let preDefinedEvents;

            function $onInit() {
                let selectedEventOverlayObjects =
                    urlOverridesService.getSelectedEventOverlays() || [];

                if (selectedEventOverlayObjects.length === 0 && ctrl.currentDashboard) {
                    urlOverridesService.setSelectedEventOverlays(
                        ctrl.currentDashboard.selectedEventOverlays
                    );
                    selectedEventOverlayObjects = ctrl.currentDashboard.selectedEventOverlays || [];
                }

                $scope.selectedEventOverlays = processEventOverlayObject(
                    selectedEventOverlayObjects
                );

                if ($scope.selectedEventOverlays && $scope.selectedEventOverlays.length) {
                    toggleEventOverlayDropdown(true);
                    ctrl.eventSidebarOverlayMode.showOverlaidEventsInPanel = true;
                    ctrl.eventSidebarOverlayMode.areEventsOverlaid = true;
                }

                separator = {
                    isSeparator: true,
                };

                showOverlayActionResult = {
                    action: commitOverlay, //This function is here so that this menu item acts like an action
                    value: 'Show events',
                    disabled: $scope.selectedEventOverlays.length === 0,
                };
            }

            const eventPlot = {
                type: 'event',
                seriesData: {},
            };
            function getSuggestions(userInput) {
                let result = [];
                if (userInput) {
                    return plotSignalSuggestionService
                        .getSignalSuggestions(userInput, false, [], eventPlot, 'event')
                        .then(function (rs) {
                            rs = rs.map(applyIconToResult);
                            rs.push(showOverlayActionResult);
                            return rs;
                        });
                } else {
                    if (ctrl.currentDashboard.eventOverlays) {
                        if (!userInput) {
                            result = angular.copy(preDefinedEvents);
                        }

                        result.push(separator);
                        result.push(showOverlayActionResult);
                    }
                    return $q.when(result);
                }
            }

            function removeAndCommit(result) {
                toggleSelection(result);
                showOverlaidEvents();

                if ($scope.selectedEventOverlays && $scope.selectedEventOverlays.length === 0) {
                    $scope.$emit('hide events panel');
                }
            }

            function toggleSelection(result) {
                const index = findIndex($scope.selectedEventOverlays, result);
                if (index !== -1) {
                    $scope.selectedEventOverlays.splice(index, 1);
                } else {
                    const eventIndex = findIndex(ctrl.currentDashboard.eventOverlays, result);
                    let eventOverlayToAdd;
                    if (eventIndex === -1) {
                        eventOverlayToAdd = getEventOverlayObject(result);
                    } else {
                        const eventOverlay = angular.copy(
                            ctrl.currentDashboard.eventOverlays[eventIndex]
                        );
                        eventOverlayToAdd = {
                            overlayId: eventOverlay.overlayId,
                        };
                    }
                    $scope.selectedEventOverlays.push(eventOverlayToAdd);
                }
            }

            function isSelected(result) {
                return findIndex($scope.selectedEventOverlays, result) !== -1;
            }

            function showOverlaidEvents() {
                $scope.eventOverlayPills = $scope.selectedEventOverlays
                    .map(function (eventOverlay) {
                        let overlayPill = angular.copy(eventOverlay);
                        if (eventOverlay.overlayId) {
                            const overlayIndex = findIndex(
                                ctrl.currentDashboard.eventOverlays,
                                eventOverlay
                            );
                            if (overlayIndex !== -1) {
                                overlayPill = angular.copy(
                                    ctrl.currentDashboard.eventOverlays[overlayIndex]
                                );
                            } else {
                                return null; // did not find the overlayId in event overlay
                            }
                        }
                        overlayPill.eventColor = colorAccessibilityService
                            .get()
                            .getPlotColorFromIndex(overlayPill.eventColorIndex);
                        return overlayPill;
                    })
                    .filter((eventOverlay) => eventOverlay);

                ctrl.selectedEventOverlayDirty = isDashboardDirty(
                    $scope.selectedEventOverlays,
                    ctrl.currentDashboard.selectedEventOverlays || []
                );

                const overlayParams = {};

                const eventPlotList = [];
                const eventQueryList = [];
                const eventOverlayColors = {};
                const eventLines = {};
                const eventLinesById = {};

                const sourceOverrideString = urlOverridesService.getSourceOverride() || [];
                const sourceOverrides = sourceFilterService.getSourceFilters(sourceOverrideString);
                const dashboardOverrides = sourceOverrides.concat(
                    dashboardVariablesService.getVariablesUrlOverrideAsModel() || []
                );
                $scope.selectedEventOverlays.forEach(function (eventOverlay) {
                    const seriesData = {};
                    if (eventOverlay.overlayId) {
                        eventOverlay = _.find(
                            ctrl.currentDashboard.eventOverlays,
                            (e) => e.overlayId === eventOverlay.overlayId
                        );
                    }
                    const eventSearchText = eventOverlay.eventSignal.eventSearchText;
                    const detectorId = eventOverlay.eventSignal.detectorId;
                    if (eventOverlay.eventSignal.eventType === 'eventTimeSeries') {
                        seriesData.eventQuery = eventSearchText;
                    } else if (eventOverlay.eventSignal.eventType === 'detectorEvents') {
                        if (detectorId) {
                            seriesData.detectorId = detectorId;
                            eventLinesById[detectorId] = eventOverlay.eventLine;
                        } else {
                            seriesData.detectorQuery = eventSearchText;
                        }
                    }

                    const eventPlot = {};
                    eventPlot.type = 'event';
                    eventPlot.seriesData = seriesData;

                    const explicitFilters = angular.copy(eventOverlay.sources);
                    eventPlot.queryItems = explicitFilters;
                    eventPlotList.push(eventPlot);

                    const eventQueryText = analyticsService.getEventQuery([], seriesData);
                    const filterSuffixQueryText = analyticsService.getSuffix(
                        explicitFilters,
                        dashboardOverrides
                    );
                    eventQueryList.push(eventQueryText + filterSuffixQueryText);

                    eventOverlayColors[eventSearchText] = colorAccessibilityService
                        .get()
                        .getPlotColorFromIndex(eventOverlay.eventColorIndex);
                    eventLines[eventSearchText] = eventOverlay.eventLine;
                });

                const eventQuery = eventQueryList.join(' OR ');

                overlayParams.eventPlots = eventPlotList;
                overlayParams.eventQuery = eventQuery;
                overlayParams.eventOverlayColors = eventOverlayColors;
                overlayParams.eventLines = eventLines;
                overlayParams.eventLinesById = eventLinesById;

                ctrl.overlayEvents(overlayParams);
                const selectedOverlayExists =
                    $scope.selectedEventOverlays && $scope.selectedEventOverlays.length !== 0;
                toggleEventOverlayDropdown(selectedOverlayExists);
                ctrl.eventSidebarOverlayMode.areEventsOverlaid = selectedOverlayExists;
                ctrl.eventSidebarOverlayMode.showOverlaidEventsInPanel = selectedOverlayExists;
                urlOverridesService.setSelectedEventOverlays($scope.selectedEventOverlays);
            }

            function onItemSelected(item) {
                if (!item.eventSignal) {
                    item = getEventOverlayObject(item);
                }

                if (!isSelected(item)) {
                    $scope.selectedEventOverlays.push(item);
                }
                ctrl.eventSidebarOverlayMode.showOverlaidEventsInPanel = true;
                ctrl.openEventsSidebar();

                showOverlaidEvents();
            }

            function onEditBlur() {
                if ($scope.selectedEventOverlays && $scope.selectedEventOverlays.length === 0) {
                    toggleEventOverlayDropdown(false);
                }
            }

            function clearClientState() {
                // No need to reset any states
            }

            $scope.$watch('selectedEventOverlays.length', function () {
                if ($scope.selectedEventOverlays.length === 0) {
                    showOverlayActionResult.disabled = true;
                } else {
                    showOverlayActionResult.disabled = false;
                }
            });

            $scope.$watch('$ctrl.currentDashboard.eventOverlays', function () {
                if (!ctrl.currentDashboard) return;

                preDefinedEvents = createSuggestions(ctrl.currentDashboard.eventOverlays);

                $scope.selectedEventOverlays = $scope.selectedEventOverlays
                    .map((eventOverlay) => {
                        const index = findIndex(ctrl.currentDashboard.eventOverlays, eventOverlay);
                        if (index === -1 && !eventOverlay.overlayId) {
                            return eventOverlay;
                        } else if (index !== -1) {
                            return ctrl.currentDashboard.eventOverlays[index];
                        } else {
                            return null;
                        }
                    })
                    .filter((eventOverlay) => eventOverlay);

                showOverlaidEvents();
            });

            const unregisterRouteWatch = routeParameterService.registerRouteWatch(
                'selectedEventOverlays',
                function () {
                    $scope.selectedEventOverlays = $scope.selectedEventOverlays || [];
                    const currentUrlSelectedEventOverlay = processEventOverlayObject(
                        urlOverridesService.getSelectedEventOverlays() || []
                    );
                    const currentUrlSelectedEventSignals = currentUrlSelectedEventOverlay.map(
                        (eventOverlay) => eventOverlay.eventSignal
                    );
                    const currentSelectedEventOverlaySignals = $scope.selectedEventOverlays.map(
                        (eventOverlay) => eventOverlay.eventSignal
                    );

                    const selectedEventOverlayAreSame =
                        currentUrlSelectedEventOverlay.length ===
                            $scope.selectedEventOverlays.length &&
                        _.every(currentUrlSelectedEventSignals, (eventSignal) => {
                            return _.some(
                                currentSelectedEventOverlaySignals,
                                (selectedEventSignal) =>
                                    selectedEventSignal.eventSearchText ===
                                        eventSignal.eventSearchText &&
                                    selectedEventSignal.eventType === eventSignal.eventType
                            );
                        });

                    if ($scope.selectedEventOverlays && $scope.selectedEventOverlays.length) {
                        toggleEventOverlayDropdown(true);
                    }

                    if (!selectedEventOverlayAreSame) {
                        $scope.selectedEventOverlays = currentUrlSelectedEventOverlay;
                        showOverlaidEvents();
                    }
                }
            );

            const unregisterRouteWatchForFilters = routeParameterService.registerRouteWatchGroup(
                ['variables[]', 'sources[]'],
                () => {
                    showOverlaidEvents();
                }
            );

            $scope.$on('$destroy', function () {
                unregisterRouteWatch();
                unregisterRouteWatchForFilters();
            });

            // helper methods

            function findIndex(eventOverlays, result) {
                // if the result is from values created on the fly
                if (result.type && result.value && !result.overlayId) {
                    result = {
                        eventSignal: {
                            eventSearchText: result.value,
                            eventType: result.type,
                        },
                        sources: [],
                    };
                }
                return _.findIndex(eventOverlays, function (e) {
                    // pre-defined event overlays have eventSignal.eventSearchText as their event name
                    // on the fly suggestions will have value as their event name from typeaheadDropdown
                    if (result.overlayId) {
                        return e.overlayId === result.overlayId;
                    }
                    return (
                        angular.equals(extractEventSignal(e), extractEventSignal(result)) ||
                        isEqualSignal(e, result)
                    );
                });
            }

            function createSuggestions(eventOverlays) {
                const result = [];
                if (eventOverlays) {
                    eventOverlays.forEach(function (eventOverlay) {
                        const resultEventOverlay = angular.copy(eventOverlay);

                        let eventLabel = eventOverlay.label;
                        if (!eventLabel && eventOverlay.eventSignal) {
                            eventLabel = eventOverlay.eventSignal.eventSearchText;
                        }
                        resultEventOverlay.value = eventLabel;
                        resultEventOverlay.type = eventOverlay.eventSignal.eventType;
                        applyIconToResult(resultEventOverlay);
                        result.push(resultEventOverlay);
                    });
                }

                return result;
            }

            function commitOverlay() {
                //committing happens the dropdown blurs
                //This function is here so that this menu item acts like an action
                $timeout(function () {
                    ctrl.eventSidebarOverlayMode.showOverlaidEventsInPanel = true;
                    ctrl.openEventsSidebar();
                });

                userAnalytics.event('dashboard', 'event-overlay-show-events');
            }

            function processEventOverlayObject(selectedEventOverlayObjects) {
                return selectedEventOverlayObjects
                    .map((result) => {
                        const index = findIndex(ctrl.currentDashboard.eventOverlays, result);

                        if (index !== -1) {
                            return angular.copy(ctrl.currentDashboard.eventOverlays[index]);
                        } else if (!result.overlayId) {
                            // no overlay id, it's manual overlay. If it has overlay id and it is now no longer found
                            // it must have been removed
                            const manualEventOverlay = getEventOverlayObject({
                                type: result.eventSignal.eventType,
                                value: result.eventSignal.eventSearchText,
                                detectorId: null,
                            });

                            return manualEventOverlay;
                        }
                    })
                    .filter((i) => i);
            }

            function getEventOverlayObject(result) {
                const colorIndex = Math.abs(murmurHash(result.value)) % EVENT_COLORS.length;
                return {
                    eventSignal: {
                        eventType: result.type || 'eventTimeSeries',
                        eventSearchText: result.value,
                        detectorId: result.detectorId,
                    },
                    sources: [],
                    label: result.value,
                    eventColorIndex: colorIndex,
                    eventColor: EVENT_COLORS[colorIndex],
                };
            }

            function isDashboardDirty(selectedEventOverlays, dashboardSelectedEventOverlay) {
                const selectedEventOverlayCompare = selectedEventOverlays.map(extractEventSignal);
                const dashboardEventOverlayCompare =
                    dashboardSelectedEventOverlay.map(extractEventSignal);

                return !angular.equals(selectedEventOverlayCompare, dashboardEventOverlayCompare);
            }

            function extractEventSignal(eventOverlay) {
                if (eventOverlay.overlayId) {
                    return {
                        overlayId: eventOverlay.overlayId,
                    };
                }
                return {
                    eventSignal: eventOverlay.eventSignal,
                    sources: (eventOverlay.sources || []).map((source) => {
                        return {
                            NOT: !!source.NOT,
                            property: source.property,
                            value: source.value,
                        };
                    }),
                };
            }

            function isEqualSignal(overlay1, overlay2) {
                // this is for legacy support before overlayId was introduced
                // we want to compare eventSearchText, eventType, and sources to match event overlays
                // remove after 6 months of announcing deprecation
                // written 5/15/2018
                const copyOverlay1 = _.extend({}, overlay1);
                const copyOverlay2 = _.extend({}, overlay2);
                if (copyOverlay1.overlayId) {
                    delete copyOverlay1.overlayId;
                }

                if (copyOverlay2.overlayId) {
                    delete copyOverlay2.overlayId;
                }

                return angular.equals(
                    extractEventSignal(copyOverlay1),
                    extractEventSignal(copyOverlay2)
                );
            }

            function applyIconToResult(signal) {
                if (signal.type === 'eventTimeSeries') {
                    signal.iconClass = 'icon-eventmarker';
                } else if (signal.type === 'detectorEvents') {
                    signal.iconClass = 'icon-alert';
                }

                return signal;
            }
        },
    ],
});
