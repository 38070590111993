export default [
    'dashboardVariablesService',
    'sourceFilterService',
    'timepickerUtils',
    'urlOverridesService',
    function (
        dashboardVariablesService,
        sourceFilterService,
        timepickerUtils,
        urlOverridesService
    ) {
        const mapValueToArray = (value) =>
            (angular.isArray(value) ? value : [value]).filter(Boolean);

        const getProfilingIndexParams = function (chart, filterAlias) {
            const urlSearchParams = new URLSearchParams({ profilingType: 'Memory' });

            // Retrieve time info from chart options
            const chartStartTime =
                chart && chart.options && chart.options.time && chart.options.time.range
                    ? timepickerUtils.msToRelative(chart.options.time.range)
                    : '-15m';

            // Retrieve time info from time picker or chart options
            const globalTimePicker = urlOverridesService.getGlobalTimePicker();
            const urlPicker =
                timepickerUtils.getURLParamStringForTimePicker(globalTimePicker) ||
                `startTime=${chartStartTime}`;

            // Retrieve tracing info from dashboard variables and overrides
            const variables = dashboardVariablesService.applyVariableOverridesToVariableModel(
                filterAlias || []
            );
            const overrides = sourceFilterService.getSourceFilters(
                urlOverridesService.getSourceOverride() || []
            );
            variables.concat(overrides).forEach(({ property, propertyValue, value }) => {
                const serializedValues = propertyValue
                    ? mapValueToArray(propertyValue)
                    : mapValueToArray(value);
                switch (property) {
                    case 'deployment_environment':
                    case 'sf_environment':
                        serializedValues.forEach((environment) =>
                            urlSearchParams.append('environments', encodeURIComponent(environment))
                        );
                        break;
                    case 'sf_service':
                    case 'service':
                        serializedValues.forEach((service) =>
                            urlSearchParams.append('service', encodeURIComponent(service))
                        );
                        break;
                    case 'host_name':
                        serializedValues.forEach((hostName) =>
                            urlSearchParams.append('hostName', encodeURIComponent(hostName))
                        );
                        break;
                    case 'container_id':
                        serializedValues.forEach((containerId) =>
                            urlSearchParams.append('containerId', encodeURIComponent(containerId))
                        );
                        break;
                    case 'process_pid':
                        serializedValues.forEach((processPid) =>
                            urlSearchParams.append('processPid', encodeURIComponent(processPid))
                        );
                        break;
                    default:
                        break;
                }
            });
            return [urlPicker, urlSearchParams.toString()].join('&');
        };

        return {
            getProfilingIndexParams,
        };
    },
];
