import usageReportTimestampFilter from './usageReportTimestampFilter';
import { react2angular } from 'react2angular';
import LogObserverUsageContainer from './LOSubscriptionUsage/LogObserverUsageContainer';
import OrgUsageExceedBanner from './OrgUsageExceedBanner';

angular
    .module('signalview.orgUsage', ['signalview.orgUsageChart', 'signalview.orgSubscription'])
    .component('logObserverUsageContainer', react2angular(LogObserverUsageContainer, ['editable']))
    .component('orgUsageExceedBanner', react2angular(OrgUsageExceedBanner))
    .filter('usageReportTimestamp', usageReportTimestampFilter);
