import superpowersTemplateUrl from '../../../legacy/common/debug/superpowers/superpowers.html';
import superpowersHost from './superpowersHost';

angular
    .module('signalview.superpowers', ['sfx.debug'])
    .component('superpowersHost', superpowersHost)
    .directive('superPowers', function () {
        return {
            restrict: 'AE',
            controller: 'Superpowers',
            templateUrl: superpowersTemplateUrl,
            suffixRequested: true,
        };
    });
