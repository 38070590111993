export default [
    '$q',
    '$rootScope',
    'favoriteCacheService',
    'currentUser',
    '$log',
    '_',
    function ($q, $rootScope, favoriteCacheService, currentUser, $log, _) {
        let favoriteDashboardsPromise = null;
        let favorites = {};
        const withConfigPattern =
            /^([a-zA-Z0-9\-_]{11})-([a-zA-Z0-9\-_]{11})-([a-zA-Z0-9\-_]{11})$/;

        // TODO(trevor): This should disappear after the full migration of dashboards and groups
        const noConfigPattern = /^([a-zA-Z0-9\-_]{11})$/;

        function initializingFavorite() {
            favoriteDashboardsPromise = null;
            favorites = {};
            getFavorite();
        }
        $rootScope.$on('current organization changed', initializingFavorite);
        initializingFavorite();

        function getNameIdentifyingProperties({ dashboard, page, configId }) {
            const properties = {
                sf_type: 'Dashboard',
                sf_dashboard: dashboard.sf_dashboard || dashboard.name,
                sf_id: dashboard.sf_id || dashboard.id,
            };
            if (page) {
                angular.extend(properties, {
                    sf_page: page.sf_page || page.name,
                    sf_email: page.sf_email || page.email,
                    sf_service: page.sf_service || page.service,
                    groupId: page.sf_id || page.id,
                });
            }
            if (configId) {
                angular.extend(properties, { configId });
            }

            return properties;
        }

        function add({ dashboardIn, page, configId }) {
            if (favorites) {
                const dashboard = angular.copy(dashboardIn);
                angular.extend(
                    dashboard,
                    getNameIdentifyingProperties({
                        dashboard,
                        page,
                        configId,
                    })
                );

                const favoriteKey = dashboard.favoriteKey || dashboard.id || dashboard.sf_id;
                favorites[favoriteKey] = true;
                favoriteCacheService.insertIntoCache(dashboard);
                $rootScope.$broadcast('dashboard favorite add', dashboard);
                saveFavorite();
            } else {
                $log.error('Trying to add favorite to failed favorite cache');
            }
        }

        function remove(dashboard) {
            if (!favorites) return;
            getAllPossibleKeys(dashboard)
                .filter(Boolean)
                .forEach((key) => {
                    delete favorites[key];
                    $rootScope.$broadcast('dashboard favorite remove', key);
                    favoriteCacheService.removeFromCache(key);
                });

            saveFavorite();
        }

        function saveFavorite() {
            currentUser.orgPreferences().then((prefs) => {
                prefs.sf_favoriteDashboards = favorites;
                return currentUser.updateOrgPreferences(prefs);
            });
        }

        function isFavorite(dashboard) {
            if (!favorites) false;
            return getAllPossibleKeys(dashboard).some((key) => !!key && !!favorites[key]);
        }

        function getAllPossibleKeys(dashboard) {
            return _.uniq([
                dashboard.favoriteKey,
                convertToLegacyFavorite(dashboard.favoriteKey || ''),
                dashboard.id,
                dashboard.sf_id,
            ]);
        }

        function convertToLegacyFavorite(key) {
            const withConfig = key.match(withConfigPattern);

            if (withConfig) {
                // Our key is of the new 3-id format, which will not pick up favorites created before the initial change in favorites
                // Here we extract the dashboardId from the key
                return withConfig[2];
            } else {
                // Our key is either of the expected format for built-in dashboards or already of the legacy format
                return key;
            }
        }

        // Returns a lookup object which stores booleans keyed by:
        //  - dashboard id if it is a user dashboard that has not been migrated to new format to uniquely identify it,
        //  - string name of the dashboard if it is a built-in dashboard,
        //  - {groupId}-{dashboardId}-{configId}
        function getFavorite() {
            if (!favoriteDashboardsPromise) {
                favoriteDashboardsPromise = currentUser
                    .orgPreferences()
                    .then((prefs) => {
                        favorites = prefs.sf_favoriteDashboards || {};
                        return favorites;
                    })
                    .catch(function () {
                        favoriteDashboardsPromise = null;
                        favorites = null;
                        $log.error('failed to fetch favorite');
                        return $q.reject('failed to fetch favorite');
                    });
            }
            return favoriteDashboardsPromise;
        }
        $rootScope.$on('migrated services initialized', getFavorite);

        // TODO(trevor): The favorites system needs to have the non-config path cleaned out
        function buildKeyMap(dashboardKeys) {
            return dashboardKeys.map((key) => {
                const noConfig = key.match(noConfigPattern);
                const withConfig = key.match(withConfigPattern);

                // Built-in favorites will not match either as it is still stored by it's name and not by
                // id or the new delimited format.
                if (!noConfig && !withConfig) {
                    return null;
                }

                if (noConfig) {
                    return {
                        dashboardId: noConfig[1],
                    };
                } else {
                    return {
                        groupId: withConfig[1],
                        dashboardId: withConfig[2],
                        configId: withConfig[3],
                    };
                }
            });
        }

        /**
         * Maps each favorite to id if it is of old format. Otherwise, maps the favorite to object with
         * dashboard, group, and config id.
         *
         * E.g. [ id1, { dashboardId: id2, {configId}, {groupId} } ]
         *
         * Specifically filters out built-in dashboards as they are stored and represented by name and
         * not id.
         */
        function getFavorites() {
            return getFavorite().then(function (favorites) {
                return buildKeyMap(Object.keys(favorites)).filter((favorite) => favorite);
            });
        }

        return {
            getFavorite,
            isFavorite,
            add,
            remove,
            convertToLegacyFavorite,
            getFavorites,
        };
    },
];
