import React, { FC, useCallback, useMemo } from 'react';
import { ThemeProvider, useLegacyThemeServiceKey } from '../../../common/theme/ThemeProvider';
import { react2angular } from 'react2angular';
import { AngularInjector } from '../../../common/AngularUtils';
import { DashboardVariables } from '@splunk/olly-imm/build/Dashboard/DashboardVariables/DashboardVariables';
import {
    Variable,
    CurrentTimeRangeCallback,
    OnPillEnter,
    SetVariableOverrideCallback,
    GetURLOverrideCallback,
    VariableSearchParams,
    setUserAnalyticsCallback,
} from '@splunk/olly-imm/build/Dashboard/dashboard.model';

type DashboardVariablesProps = {
    overrides: Variable[];
    filterAlias: Variable[];
    variableSearchParams: VariableSearchParams;
    charts: any;
    getCurrentTimeRange: CurrentTimeRangeCallback;
    onPillEnter: OnPillEnter;
};

const DashboardVariablesWrapper: FC<DashboardVariablesProps> = ({
    overrides,
    filterAlias,
    variableSearchParams,
    charts,
    getCurrentTimeRange,
    onPillEnter,
}) => {
    const themeKey = useLegacyThemeServiceKey();

    const dashboardVariablesService = AngularInjector.useInjectedClass('dashboardVariablesService');
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');
    const chartUtils = AngularInjector.useInjectedClass('chartUtils');
    const suggestAPIService = AngularInjector.useInjectedClass('suggestAPIService');

    const programs = useMemo(() => chartUtils.getChartSignalFlow(charts), [chartUtils, charts]);

    const setVariableOverride: SetVariableOverrideCallback = useCallback(
        (variable, overrideValues) =>
            dashboardVariablesService.setVariableOverride(
                variable.alias,
                variable.property,
                overrideValues,
                variable.applyIfExists
            ),
        [dashboardVariablesService]
    );

    const getURLOverrides: GetURLOverrideCallback = useCallback(
        () => dashboardVariablesService.getVariablesUrlOverrideAsModel(),
        [dashboardVariablesService]
    );

    const setUserAnalytics: setUserAnalyticsCallback = useCallback(
        (event, message) => userAnalytics.event(event, message),
        [userAnalytics]
    );

    const getSignalFlowSuggest = useCallback(
        (body) => suggestAPIService.getSignalFlowSuggest(body),
        [suggestAPIService]
    );

    return (
        <ThemeProvider colorScheme={themeKey}>
            <DashboardVariables
                overrides={overrides}
                filterAlias={filterAlias}
                variableSearchParams={variableSearchParams}
                programs={programs}
                onPillEnter={onPillEnter}
                getSignalFlowSuggest={getSignalFlowSuggest}
                setVariableOverride={setVariableOverride}
                getURLOverrides={getURLOverrides}
                setUserAnalytics={setUserAnalytics}
                getCurrentTimeRange={getCurrentTimeRange}
            />
        </ThemeProvider>
    );
};

export const dashboardVariables = react2angular(DashboardVariablesWrapper, [
    'overrides',
    'filterAlias',
    'variableSearchParams',
    'charts',
    'getCurrentTimeRange',
    'onPillEnter',
]);
