angular.module('signalview.catalog').service('catalogDataService', [
    function () {
        function isDashboard(params) {
            const filters = params.filters.filter((item) => item.indexOf('_') !== 0); // not counting any internal filters.
            return params.types === 'Dashboard' && filters.length === 0 && !params.partialInput;
        }

        return {
            sortResult: function (params) {
                return !isDashboard(params);
            },
            getAliases: function () {
                return {
                    plugin: 'collectd',
                    sf_metric: 'metrics',
                };
            },
            getHighPriorityFields: function () {
                return {
                    namespace: 400,
                    plugin: 300,
                    plugin_instance: 200,
                    sf_metric: 150,
                    InstanceId: 100,
                };
            },
        };
    },
]);
