angular.module('sfx.charting').directive('scatterPlot', [
    'd3',
    '_',
    function (d3, _) {
        return {
            restrict: 'E',
            scope: {
                data: '=',
                config: '=?',
                filterMap: '=?',
                highlightFilter: '=?',
                muteFilter: '=?',
                yAxisProperty: '@',
                xAxisProperty: '@',
                width: '@',
                height: '@',
            },
            link: function ($scope, $element, attrs) {
                if (!$scope.config) $scope.config = {};

                // Highlight the selected circles.
                function brushmove() {
                    redrawDots();
                }

                // If the brush is empty, select all circles.
                function brushend() {
                    const filterMap = $scope.filterMap;
                    const xAxisProperty = $scope.config.xAxisProperty;
                    const yAxisProperty = $scope.config.yAxisProperty;

                    if (brush.empty()) {
                        delete filterMap[xAxisProperty];
                        delete filterMap[yAxisProperty];
                    } else {
                        const extent = brush.extent();
                        if (!extent) return;

                        const xFilter = [extent[0][0], extent[1][0]];
                        const yFilter = [extent[0][1], extent[1][1]];

                        if (angular.equals(xFilter, x.domain())) {
                            delete filterMap[xAxisProperty];
                        } else {
                            filterMap[xAxisProperty] = xFilter;
                        }

                        if (angular.equals(yFilter, y.domain())) {
                            delete filterMap[yAxisProperty];
                        } else {
                            filterMap[yAxisProperty] = yFilter;
                        }
                    }

                    $scope.$apply();
                }

                const margin = { top: 20, right: 20, bottom: 40, left: 50 };
                let data = [];

                const element = $element[0];
                const svg = d3.select(element).append('svg');
                const svgGroup = svg
                    .append('g')
                    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                const mutedDots = svgGroup.append('g').attr('class', 'muted dots');
                const dots = svgGroup.append('g').attr('class', 'dots');
                const highlightedDots = svgGroup.append('g').attr('class', 'highlighted dots');

                const xAxisGroup = svgGroup.append('g').attr('class', 'x axis');
                const yAxisGroup = svgGroup.append('g').attr('class', 'y axis');
                const brushGroup = svgGroup.append('g').attr('class', 'brush');

                const yAxisLabel = yAxisGroup
                    .append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('y', -45)
                    .attr('x', -50)
                    .attr('dy', '.71em')
                    .style('text-anchor', 'middle');

                const xAxisLabel = xAxisGroup
                    .append('text')
                    .attr('x', 40)
                    .attr('y', 30)
                    .attr('dx', '.71em')
                    .style('text-anchor', 'middle');

                const x = d3.scale.linear();
                const y = d3.scale.linear();
                const xAxis = d3.svg.axis().orient('bottom');
                const yAxis = d3.svg.axis().orient('left');
                const brush = d3.svg.brush().on('brush', brushmove).on('brushend', brushend);

                function redrawDots() {
                    const xAxisProperty = $scope.config.xAxisProperty;
                    const yAxisProperty = $scope.config.yAxisProperty;
                    const extent = brush.extent();
                    const coloringFunction =
                        $scope.config.coloringFunction ||
                        function () {
                            return 'blue';
                        };
                    const isBrushEmpty = brush.empty();

                    function outOfExtents(d) {
                        return (
                            extent[0][0] > d[xAxisProperty] ||
                            d[xAxisProperty] > extent[1][0] ||
                            extent[0][1] > d[yAxisProperty] ||
                            d[yAxisProperty] > extent[1][1]
                        );
                    }

                    const filterKeys = Object.keys($scope.filterMap);
                    function isVisible(d) {
                        let match = true;

                        if (filterKeys.length) {
                            match = Object.keys($scope.filterMap).every(function (key) {
                                const extent = $scope.filterMap[key];
                                return extent[0] <= d[key] && d[key] <= extent[1];
                            });
                        }

                        if (!match) return false;

                        if (!isBrushEmpty) {
                            match = !outOfExtents(d);
                        }

                        return match;
                    }

                    const highlightFilter = $scope.highlightFilter,
                        muteFilter = $scope.muteFilter,
                        highlighted = [],
                        muted = [],
                        normal = [];

                    data.forEach(function (d) {
                        if (highlightFilter && highlightFilter(d)) {
                            highlighted.push(d);
                        } else if ((muteFilter && muteFilter(d)) || !isVisible(d)) {
                            muted.push(d);
                        } else {
                            normal.push(d);
                        }
                    });

                    function getId(d) {
                        return d.id;
                    }

                    // create new paths based off the id property for each datum
                    const mutedCircles = mutedDots.selectAll('circle').data(muted, getId);
                    mutedCircles.enter().append('circle');
                    mutedCircles.exit().remove();

                    const normalCircles = dots.selectAll('circle').data(normal, getId);
                    normalCircles.enter().append('circle');
                    normalCircles.exit().remove();

                    const highlightedCircles = highlightedDots
                        .selectAll('circle')
                        .data(highlighted, getId);
                    highlightedCircles.enter().append('circle');
                    highlightedCircles.exit().remove();

                    svg.selectAll('g.dots circle')
                        .attr('class', 'dot')
                        .attr('r', 3)
                        .attr('cx', function (d) {
                            return x(d[xAxisProperty]);
                        })
                        .attr('cy', function (d) {
                            return y(d[yAxisProperty]);
                        })
                        .style({
                            fill: coloringFunction,
                            stroke: '#444',
                        });
                    highlightedCircles.style({ fill: null, stroke: null });
                }

                function draw() {
                    if (!data.length) return;
                    const config = $scope.config;
                    const xAxisProperty = config.xAxisProperty;
                    const yAxisProperty = config.yAxisProperty;

                    yAxisLabel.text(yAxisProperty);
                    xAxisLabel.text(xAxisProperty);

                    const height = parseFloat(attrs.height, 10) - margin.top - margin.bottom,
                        width = parseFloat(attrs.width, 10) - margin.left - margin.right;

                    svg.attr('width', width + margin.left + margin.right).attr(
                        'height',
                        height + margin.top + margin.bottom
                    );

                    const xAxisAccessor = function (d) {
                        return d[xAxisProperty];
                    };
                    x.range([0, width]);
                    if (config.xAxisDomain) {
                        x.domain(config.xAxisDomain(data, xAxisAccessor));
                    } else {
                        x.domain(d3.extent(data, xAxisAccessor));
                    }

                    const yAxisAccessor = function (d) {
                        return d[yAxisProperty];
                    };
                    y.range([height, 0]);
                    if (config.yAxisDomain) {
                        y.domain(config.yAxisDomain(data, yAxisAccessor));
                    } else {
                        y.domain(d3.extent(data, yAxisAccessor));
                    }

                    xAxis.scale(x).ticks(3).tickFormat(d3.format('s'));
                    yAxis.scale(y).ticks(3).tickFormat(d3.format('s'));

                    xAxisGroup.attr('transform', 'translate(0,' + height + ')').call(xAxis);
                    yAxisGroup.call(yAxis);

                    brush.x(x).y(y);
                    brushGroup.call(brush);

                    redrawDots();
                }

                draw();

                // Prevent attempts to draw more than once a frame
                const throttledRedraw = _.throttle(draw, 16);

                attrs.$observe('width', function (value) {
                    if (!value && value !== 0) return;
                    throttledRedraw();
                });

                attrs.$observe('height', function (value) {
                    if (!value && value !== 0) return;
                    throttledRedraw();
                });

                $scope.$watch('data', function (_data) {
                    if (!_data) return;
                    data = _data;
                    throttledRedraw();
                });

                $scope.$on('update highlighted', function () {
                    redrawDots();
                    /**
        dots.selectAll('.dot').classed('muted', function(d) {
          Object.keys($scope.filterMap).forEach(function(key) {
            var extent = $scope.filterMap[key];
            return extent[0] <= d[key] && d[key] <= extent[1];
          });
        });**/
                });

                $scope.$watch(
                    'filterMap',
                    function (filterMap) {
                        if (!filterMap) return;
                        const xAxisProperty = $scope.config.xAxisProperty;
                        const yAxisProperty = $scope.config.yAxisProperty;

                        const xFilter = filterMap[xAxisProperty] || x.domain();
                        const yFilter = filterMap[yAxisProperty] || y.domain();

                        const extent = [
                            [xFilter[0], yFilter[0]],
                            [xFilter[1], yFilter[1]],
                        ];

                        if (
                            angular.equals(xFilter, x.domain()) &&
                            angular.equals(yFilter, y.domain())
                        ) {
                            brush.clear();
                        } else {
                            brush.extent(extent);
                        }

                        brush(brushGroup.transition());
                        brush.event(brushGroup.transition().delay(1000));
                    },
                    true
                );

                $scope.$watch('config', throttledRedraw, true);
            },
        };
    },
]);
