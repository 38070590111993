import React, { FC, useEffect, useRef } from 'react';
import * as d3 from 'd3';

type LOLineChartProps = {
    usage: { x: Date; y: number }[];
    quota: number;
    usagelabel: string;
    labelToClassName: string;
};

const LOLineChart: FC<LOLineChartProps> = ({ usage, quota, usagelabel, labelToClassName }) => {
    const containerRef = useRef<HTMLInputElement>(null);
    const planLimit = Math.max(usage[usage.length - 1].y, quota);

    const data = usage;

    useEffect(() => {
        const margin = { top: 30, right: 50, bottom: 30, left: 100 },
            width = containerRef!.current!.offsetWidth,
            height = 300,
            offsetTop = containerRef!.current!.offsetTop;

        // append the svg object to the body of the page
        const selector = '.' + labelToClassName;
        d3.select(selector).selectAll('*').remove();

        const svg = d3
            .select(selector)
            .append('svg')
            .attr(
                'viewBox',
                `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`
            )
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const now = new Date();
        const x = d3
            .scaleTime()
            .domain([
                new Date(now.getFullYear(), now.getMonth(), 1),
                new Date(now.getFullYear(), now.getMonth() + 1, 0),
            ])
            .range([0, width]);
        const xAxis = d3
            .axisBottom(x)
            .ticks(d3.timeDay.every(5))
            .tickFormat((d: any) => d3.timeFormat('%b %d')(d));
        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxis);

        // Add Y axis
        const y = d3.scaleLinear().domain([0, planLimit]).range([height, 0]);
        const yAxis = d3.axisLeft(y).ticks(6);
        svg.append('g').attr('class', 'y-axis').call(yAxis);

        // This allows to find the closest X index of the mouse:
        const bisect = d3.bisector(function (d: any) {
            return d.x;
        }).left;

        // Create the circle that travels along the curve of chart
        const focus = svg
            .append('g')
            .append('circle')
            .attr('stroke', 'black')
            .attr('r', 3.5)
            .attr('class', 'focus')
            .style('opacity', 0);

        // Create the text that travels along the curve of chart
        const focusText = d3
            .select(selector)
            .append('g')
            .append('text')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            .attr('text-anchor', 'left')
            .attr('alignment-baseline', 'middle')
            .style('color', '#839869')
            .style('font-size', '12px')
            .style('pointer-events', 'none');

        // Add the line
        svg.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', '#839869')
            .attr('stroke-width', 1.5)
            .attr(
                'd',
                d3
                    .line()
                    .x(function (d: any) {
                        return x(d.x);
                    })
                    .y(function (d: any) {
                        return y(d.y);
                    })
            );
        // What happens when the mouse move -> show the annotations at the right positions.
        const mouseover = (): void => {
            focus.style('opacity', 1);
            focusText.style('opacity', 1);
        };

        const mousemove = (): void => {
            // recover coordinate we need
            const x0 = x.invert(d3.mouse(d3.event.currentTarget)[0]);
            const i = bisect(data, x0, 1);
            const selectedData = data[i];
            if (selectedData) {
                focus.attr('cx', x(selectedData.x)).attr('cy', y(selectedData.y));
                focusText
                    .html(selectedData.x.toLocaleString() + ' ' + '(' + selectedData.y + ' GB)')
                    .style('left', x(selectedData.x) + 15 + 'px')
                    .style('top', y(selectedData.y) + offsetTop + 'px');
            }
        };
        const mouseout = (): void => {
            focus.style('opacity', 0);
            focusText.style('opacity', 0);
        };

        // Create a rect on top of the svg area: this rectangle recovers mouse position
        svg.append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseout', mouseout);

        // plan limit line
        svg.append('g')
            .attr('transform', 'translate(0, ' + y(quota) + ')')
            .append('line')
            .attr('x2', width)
            .style('stroke', '#EA1849')
            .style('stroke-width', '1.5px');

        svg.append('text')
            .attr('y', y(quota) - 3)
            .attr('x', 3)
            .attr('text-anchor', 'start')
            .style('fill', '#EA1849')
            .style('font-size', '12px')
            .text('Plan Limit: ' + d3.format(',')(quota) + 'GB');
    }, [data, labelToClassName, quota, planLimit]);

    return (
        <div ref={containerRef}>
            <div className="org-usage-dropdown-label">{usagelabel}</div>
            <div className={labelToClassName} />
        </div>
    );
};

export default LOLineChart;
