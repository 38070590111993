import { random } from 'lodash';

function VolatilityGenerator(min = 0, max = 100, volatility = 0.02) {
    this.min = min;
    this.max = max;
    this.volatility = volatility;
    this.value = random(min, max);
}

// Make this interface similar to a built in JS generator
VolatilityGenerator.prototype.next = function () {
    const random = Math.random();
    let percentageChange = 2 * this.volatility * random;
    if (percentageChange > this.volatility) {
        percentageChange -= 2 * this.volatility;
    }

    const valueChange = this.value * percentageChange;
    this.value = this.value + valueChange;
    return { value: this.value };
};

export default VolatilityGenerator;
