angular
    .module('signalview.input')
    /**
     * Usage:
     *   input that need to be auto resized.
     *
     *   Specify min-width and max-width CSS style to provide boundaries.
     */
    .directive('autoResizeInput', [
        '$timeout',
        function ($timeout) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attributes, ngModel) {
                    const mirrorModel = angular.element('<span class="input-mirror"></span>');
                    angular.element('.sf-ui').first().append(mirrorModel);

                    const stylesToSync = [
                        'fontFamily',
                        'fontSize',
                        'fontWeight',
                        'fontStyle',
                        'letterSpacing',
                        'textTransform',
                        'wordSpacing',
                        'textIndent',
                        'boxSizing',
                        'borderRightWidth',
                        'borderLeftWidth',
                        'borderLeftStyle',
                        'borderRightStyle',
                        'paddingLeft',
                        'paddingRight',
                        'marginLeft',
                        'marginRight',
                        'minWidth',
                        'maxWidth',
                    ];

                    const updateCSS = () =>
                        stylesToSync.forEach((prop) => mirrorModel.css(prop, element.css(prop)));
                    const updateText = () =>
                        mirrorModel.text(ngModel.$viewValue || element.attr('placeholder') || '');
                    const syncInputWidth = () =>
                        element.css('width', mirrorModel.innerWidth() + 10);

                    updateCSS();
                    $timeout(
                        () => {
                            updateText();
                            syncInputWidth();
                        },
                        0,
                        false
                    );

                    scope.$watch(
                        () => ngModel.$viewValue,
                        () => {
                            updateText();
                            syncInputWidth();
                        }
                    );

                    if (attributes.autoResizeWatchClass) {
                        scope.$watch(
                            () => element.attr('class').includes(attributes.autoResizeWatchClass),
                            () => {
                                updateCSS();
                                syncInputWidth();
                            }
                        );
                    }

                    // get rid of mirror element when we destroy this directive.
                    scope.$on('$destroy', () => mirrorModel.remove());
                },
            };
        },
    ]);
