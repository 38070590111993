export const SCALING_UNIT_TYPES = {
    // bits
    BIT: 'Bit',
    KILOBIT: 'Kilobit',
    MEGABIT: 'Megabit',
    GIGABIT: 'Gigabit',
    TERABIT: 'Terabit',
    PETABIT: 'Petabit',
    EXABIT: 'Exabit',
    ZETTABIT: 'Zettabit',
    YOTTABIT: 'Yottabit',

    // bytes
    BYTE: 'Byte',
    KIBIBYTE: 'Kibibyte',
    MEBIBYTE: 'Mebibyte',
    GIBIBYTE: 'Gibibyte',
    TEBIBYTE: 'Tebibyte',
    PEBIBYTE: 'Pebibyte',
    EXBIBYTE: 'Exbibyte',
    ZEBIBYTE: 'Zebibyte',
    YOBIBYTE: 'Yobibyte',

    // time
    NANOSECOND: 'Nanosecond',
    MICROSECOND: 'Microsecond',
    MILLISECOND: 'Millisecond',
    SECOND: 'Second',
    MINUTE: 'Minute',
    HOUR: 'Hour',
    DAY: 'Day',
    WEEK: 'Week',
};

export const SCALING_UNIT_DEFINITIONS = [
    {
        name: 'Bits',
        subTypes: [
            {
                name: SCALING_UNIT_TYPES.BIT,
                abbreviation: 'b',
                factor: 1,
            },
            {
                name: SCALING_UNIT_TYPES.KILOBIT,
                abbreviation: 'kb',
                factor: 10 ** 3,
            },
            {
                name: SCALING_UNIT_TYPES.MEGABIT,
                abbreviation: 'Mb',
                factor: 10 ** 6,
            },
            {
                name: SCALING_UNIT_TYPES.GIGABIT,
                abbreviation: 'Gb',
                factor: 10 ** 9,
            },
            {
                name: SCALING_UNIT_TYPES.TERABIT,
                abbreviation: 'Tb',
                factor: 10 ** 12,
            },
            {
                name: SCALING_UNIT_TYPES.PETABIT,
                abbreviation: 'Pb',
                factor: 10 ** 15,
            },
            {
                name: SCALING_UNIT_TYPES.EXABIT,
                abbreviation: 'Eb',
                factor: 10 ** 18,
            },
            {
                name: SCALING_UNIT_TYPES.ZETTABIT,
                abbreviation: 'Zb',
                factor: 10 ** 21,
            },
            {
                name: SCALING_UNIT_TYPES.YOTTABIT,
                abbreviation: 'Yb',
                factor: 10 ** 24,
            },
        ],
    },
    {
        name: 'Bytes',
        subTypes: [
            {
                name: SCALING_UNIT_TYPES.BYTE,
                abbreviation: 'B',
                factor: 1,
            },
            {
                name: SCALING_UNIT_TYPES.KIBIBYTE,
                abbreviation: 'KiB',
                factor: 1024,
            },
            {
                name: SCALING_UNIT_TYPES.MEBIBYTE,
                abbreviation: 'MiB',
                factor: 1024 ** 2,
            },
            {
                name: SCALING_UNIT_TYPES.GIBIBYTE,
                abbreviation: 'GiB',
                factor: 1024 ** 3,
            },
            {
                name: SCALING_UNIT_TYPES.TEBIBYTE,
                abbreviation: 'TiB',
                factor: 1024 ** 4,
            },
            {
                name: SCALING_UNIT_TYPES.PEBIBYTE,
                abbreviation: 'PiB',
                factor: 1024 ** 5,
            },
            {
                name: SCALING_UNIT_TYPES.EXBIBYTE,
                abbreviation: 'EiB',
                factor: 1024 ** 6,
            },
            {
                name: SCALING_UNIT_TYPES.ZEBIBYTE,
                abbreviation: 'ZiB',
                factor: 1024 ** 7,
            },
            {
                name: SCALING_UNIT_TYPES.YOBIBYTE,
                abbreviation: 'YiB',
                factor: 1024 ** 8,
            },
        ],
    },
    {
        name: 'Time',
        subTypes: [
            {
                name: SCALING_UNIT_TYPES.NANOSECOND,
                abbreviation: 'ns',
                factor: 0.000001,
            },
            {
                name: SCALING_UNIT_TYPES.MICROSECOND,
                abbreviation: 'μs',
                factor: 0.001,
            },
            {
                name: SCALING_UNIT_TYPES.MILLISECOND,
                abbreviation: 'ms',
                factor: 1,
            },
            {
                name: SCALING_UNIT_TYPES.SECOND,
                abbreviation: 's',
                factor: 1000,
            },
            {
                name: SCALING_UNIT_TYPES.MINUTE,
                abbreviation: 'm',
                factor: 1000 * 60,
            },
            {
                name: SCALING_UNIT_TYPES.HOUR,
                abbreviation: 'h',
                factor: 1000 * 60 * 60,
            },
            {
                name: SCALING_UNIT_TYPES.DAY,
                abbreviation: 'd',
                factor: 1000 * 60 * 60 * 24,
            },
            {
                name: SCALING_UNIT_TYPES.WEEK,
                abbreviation: 'w',
                factor: 1000 * 60 * 60 * 24 * 7,
            },
        ],
    },
];
