/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Button from '@splunk/react-ui/Button';
import styled from 'styled-components';
import Cross from '@splunk/react-icons/Cross';
import PropTypes from 'prop-types';
import { variables } from '@splunk/themes';

const StyledButton = styled(Button)`
    > span > span {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 0.5rem 0 0.25rem;
    }

    &:not([disabled]) > span > .selection {
        color: ${(props) => (props.grayOut ? variables.neutral400 : 'ihherit')};
    }
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
`;

const StyledCross = styled(Cross)`
    margin-right: -0.375rem;
    margin-left: -0.375rem;
    border: none;
    height: 1em;
    width: auto;
`;

const GroupByDropdownButton = React.forwardRef(
    ({ title, selected, disabled, onRemove, noneOption, ...rest }, ref) => (
        <Wrapper>
            <StyledButton
                appearance="toggle"
                disabled={disabled}
                ref={ref}
                isMenu
                grayOut={noneOption === selected}
                {...rest}
            >
                {title}
                {selected ? ':' : ''}
                <span className="selection">{selected}</span>
            </StyledButton>
            {onRemove && (
                <Button
                    appearance="toggle"
                    prepend
                    disabled={disabled}
                    onClick={onRemove}
                    icon={<StyledCross />}
                    aria-label="Remove"
                />
            )}
        </Wrapper>
    )
);

GroupByDropdownButton.propTypes = {
    title: PropTypes.string,
    selected: PropTypes.bool,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    noneOption: PropTypes.string,
};
GroupByDropdownButton.displayName = 'GroupByDropdownButton';

export default GroupByDropdownButton;
